import React from "react";
import translate from "../i18n/Translator";

import { Grid, Box, Typography, Divider, ListItemIcon , ListItem, ListItemText } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ReactHtmlParser from "html-react-parser";

interface CfdiCheckPdfMessageSectionProps {
    uuid: string;
    isCfdiTenant: boolean;
    onOpenCheckPdf(): any;
}

export default function CfdiCheckPdfMessageSection(props: CfdiCheckPdfMessageSectionProps) {

    const onOpenCheckPdf = () => {
        props.onOpenCheckPdf();
    } 

    return (
        <div style={{ "backgroundColor": "#fef4df", "borderRadius": "20px", "marginLeft": "1%", "paddingRight": "1%" }}>
            <Grid container >
                <Grid item xs={12}>
                    <Box pl={2} style={{ "marginTop": "2%" }}>
                        <Typography style={{ "opacity": "50%" }} variant="inherit" paragraph>
                                {translate("cfdis.documents.files.message_change_file")}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                <Divider/>
                    <Box pl={2} style={{ "marginTop": "2%", "marginBottom": "2%" }}>
                        <Typography variant="body2" component="h6">
                            {ReactHtmlParser(translate("cfdis.documents.files.uuid", {uuid: props.uuid}) as string)}
                        </Typography>
                    </Box>
                <Divider/>
                </Grid>
                { props.isCfdiTenant &&
                <Grid xs={12} container direction="row" alignItems="center" justify="flex-end">
                    <Box py={2}>
                        <ListItem button onClick={onOpenCheckPdf} dense>
                            <ListItemIcon>
                                    <CheckIcon fontSize="small" color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="inherit" color="primary">{ReactHtmlParser(translate("cfdis.documents.files.checked_correct") as string)}</Typography>} />
                        </ListItem>
                    </Box>
                </Grid>
                }
            </Grid>
        </div>
    );
}