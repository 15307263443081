import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { ConceptRelated, CfdiRelated } from "../../model/Document";
import translate from "../../i18n/Translator";
import GridDx, { NoFilterEditor } from "../../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

interface ConceptRow extends ConceptRelated {
    identifier: string;
}

export interface RelatedTabProps {
    cfdis?: CfdiRelated[];
    currency: string;
}

export default function RelatedTab(props: RelatedTabProps) {
    const [concepts] = useState<ConceptRow[]>(props.cfdis ? props.cfdis.flatMap(cfdi => cfdi.concepts.map(concept => {
        return {...concept, identifier: cfdi.identifier} as ConceptRow;
    })) : []);

    const columns = [
        {
            name: 'quantity',
            title: translate("cfdis.details.quantity") as string
        },
        {
            name: 'identifier',
            title: translate("cfdis.columns.serie_folio") as string
        },
        {
            name: 'description',
            title: translate("cfdis.details.description") as string
        },
        {
            name: 'total_amount',
            title: translate("cfdis.details.price", { "currency": props.currency }) as string
        },
    ];

    const [columnsFormat] = useState([
        { columnName: 'quantity' },
        { columnName: 'identifier', wordWrapEnabled: true },
        { columnName: 'description', wordWrapEnabled: true },
        { columnName: 'total_amount', align: 'right' },
    ]) as any;

    const textColumns = ['quantity', 'identifier', 'description'];

    const currencyColumns = ['total_amount'];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const filtersExclude = [
        'quantity',
        'identifier',
        'description',
        'total_amount'
    ];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    return (
        <Box py={2}>
            <Grid container alignItems="flex-start" justifyContent="space-between">
                {props.cfdis ? <Grid item xs={12}>
                    <Typography >{translate("cfdi_relate_po.modal_concepts.tab_other_invoices_label")}</Typography>
                </Grid> : <></> }
                <Grid item xs={12} style={{ marginLeft: "-15px"}}>
                    <GridDx
                            loading={false}
                            rows={concepts}
                            columns={columns ? columns : []}
                            columnsFormat={columnsFormat}
                            textColumns={textColumns}
                            amountCurrencyColumns={currencyColumns}
                            onClickRow={() => {}}
                            onClickedOptions={() => {}}
                            customFormatColumns={customPlugins}
                            heightTablePX={200}
                        />
                </Grid>
            </Grid>
        </Box>
    )
}