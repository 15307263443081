import React, { useContext, useEffect, useState } from 'react'
import Surface from '../components/Surface';
import { AppContext } from '../context/AppContext';
import translate from "../i18n/Translator";
import { Box, Button, Grid } from '@material-ui/core';
import ValidatedInput from '../components/ValidatedInput';
import { ErrorSnackbar, WarningSnackbar } from '../components/Snackbars';
import { TenantField, TenantSegment } from '../model/TenantFieldCatalog';
import { ExtendedFieldsTemplate, ExtendedFieldsTemplateSegment, ExtendedFieldsTemplateRequest, FieldsResponseTenant, ExtendedFieldsTemplateType } from '../model/ExtendedFieldsTemplate';
import { createExtendedFieldsTemplate, getExtendedFieldsTemplate, updateExtendedFieldsTemplate } from '../api/ExtendedFieldsTemplateAPI';
import { LibraryBooks } from '@material-ui/icons';
import TemplateAddFieldsPopup from './TemplateAddFieldsPopup';
import TemplateSettingsFields from './TemplateSettingsFields';
import { RequiredValidator } from '../components/Validators';
import { RouterParams } from '../router/RouterParams';
import { useHistory } from 'react-router-dom';
import Progress from '../components/Progress';


export default function ExtendedFieldsTemplatesCreateEdit({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const [status, setStatus] = useState<"loading" | "loaded">("loading");
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>();
    const [showFieldsPopUp, setShowFieldsPopUp] = useState<boolean>(false)
    const [template, setTemplate] = useState<ExtendedFieldsTemplate>({} as ExtendedFieldsTemplate);
    const [request, setRequest] = useState<ExtendedFieldsTemplateRequest>({
        template_name: "",
        fields: [],
        type: ExtendedFieldsTemplateType.PROJECT
    } as ExtendedFieldsTemplateRequest);
    const REQUIRED_VALIDATOR = new RequiredValidator();
    const [paramTemplateId, setParamTemplateId] = useState<string | undefined>(match.params.templateId);

    const load = () => {
        setStatus("loading");

        if (!!paramTemplateId) {
            getExtendedFieldsTemplate(context.session!.tenant!.id, paramTemplateId!)
                .then((response) => {
                    setTemplateAndRequest(response);
                    setStatus("loaded");
                }).catch((error) => {
                    setError(error.message);
                })
        } else {
            setStatus("loaded");
        }
    }

    useEffect(load, [context.session, paramTemplateId]);

    const setTemplateAndRequest = (response: ExtendedFieldsTemplate) => {
        let fields = getFieldsFromTemplate(response.fields_response);

        setRequest({
            template_name: response.name,
            fields: fields,
            type: ExtendedFieldsTemplateType.PROJECT
        })
        setTemplate(response);
    }

    const getFieldsFromTemplate = (fields: ExtendedFieldsTemplate['fields_response']): ExtendedFieldsTemplateSegment[] => {
        let response = [] as ExtendedFieldsTemplateSegment[];

        if (fields) {
            for (var key in fields) {
                response.push({
                    id: fields[key].id,
                    priority: Number(key)
                } as ExtendedFieldsTemplateSegment)
            }
        }

        return response;
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
    }

    const onChangeName = (name: string, value: string) => { setRequest({ ...request, [name]: value }) }

    const onCancel = () => { history.goBack(); }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true);

        if (!areValidFieldsPriorities()) {
            setSubmitting(false);
            return;
        }

        let requestApi = paramTemplateId ? updateExtendedFieldsTemplate(context.session!.tenant!.id, template!.id, request) :
            createExtendedFieldsTemplate(context.session!.tenant!.id, request);

        requestApi.then((response) => {
            setTemplate(response);
            history.goBack();
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    }

    const areValidFieldsPriorities = () => {
        if (request.fields!.length === 0) {
            setWarning(translate("extended_fields_templates.create_edit.validations.messages_invalid_not_fields_assigned") as string);
            return false;
        }

        let dupplied = [];
        const temp = [...request.fields!.map(key => key.priority)].sort();

        if (temp.find(item => item <= 0) !== undefined) {
            setWarning(translate("extended_fields_templates.create_edit.inputs.validations.fields.priority_cero") as string);
            return false;
        }
        for (let i = 0; i < temp.length; i++) {
            if (temp[i + 1] === temp[i]) {
                dupplied.push(temp[i]);
            }
        }
        if (dupplied.length > 0) {
            setWarning(translate("extended_fields_templates.create_edit.inputs.validations.fields.priority_dupplied") as string);
            return false;
        }

        return true;
    }

    const onOpenAddFields = () => { setShowFieldsPopUp(true); }

    const onAddFields = (fields: (TenantField | TenantSegment)[]) => {
        setRequestAndTemplate(
            fields as TenantField[],
            request.fields,
            template.fields_response
        );
        setShowFieldsPopUp(false);
    }

    const setRequestAndTemplate = (fields: (TenantField)[],
        requestFields: ExtendedFieldsTemplateSegment[] | undefined,
        templateFields: ExtendedFieldsTemplate['fields_response']) => {
        let temp = {} as FieldsResponseTenant['fields_response'];
        let tempRequest = requestFields || [];
        let i = tempRequest.length > 0 ? Math.max(...tempRequest.map(item => item.priority)) : tempRequest.length;
        fields.forEach(field => {
            tempRequest.push({
                id: field.id,
                priority: i + 1
            } as ExtendedFieldsTemplateSegment);
            i++;
        });
        setRequest({ ...request, fields: tempRequest });
        i = 0;
        if (templateFields) {
            Object.values(templateFields).forEach(field => {
                temp[i] = field
                i++;
            });
        }
        fields.forEach(field => {
            temp[i] = field
            i++;
        });
        setTemplate({ ...template, fields_response: temp });
    }

    const onDeleteField = (fieldId: string) => {
        setRequestAndTemplateDelete(fieldId, request.fields, template.fields_response);
    }

    const setRequestAndTemplateDelete = (fieldId: string,
        requestFields: ExtendedFieldsTemplateSegment[] | undefined,
        templateFields: ExtendedFieldsTemplate['fields_response']) => {
        let temp = {} as FieldsResponseTenant['fields_response'];
        let tempRequest: ExtendedFieldsTemplateSegment[] = requestFields ? requestFields.filter(field => field.id !== fieldId) : [];
        let i = 0;

        setRequest({ ...request, fields: tempRequest, });

        if (templateFields) {
            Object.values(templateFields).filter(field => JSON.parse(JSON.stringify(field)).id !== fieldId).forEach(field => {
                temp[i] = field
                i++;
            });
        }

        setTemplate({ ...template, fields_response: temp });
    };

    const getIdsFromRequest = () => {
        let response = [] as string[];

        if (request.fields) { response = request.fields.map(field => field.id); }

        return response;
    };

    const getFieldPriority = (fieldId: string) => {
        let priority = "";
        if (fieldId) {
            let tempRequest = request.fields || [];
            tempRequest.forEach(field => {
                if (field.id === fieldId) {
                    priority = `${field.priority}`;
                }
            });
        }
        return priority;
    }

    const onUpdatePriorityField = (value: number, fieldId: string) => {
        if (value !== undefined) {
            let tempRequest = request.fields || [];
            tempRequest.forEach(field => {
                if (field.id === fieldId) {
                    field.priority = value;
                }
            });
            setRequest({ ...request });
        }
    }

    if (status === "loading") {
        return (<Progress />);
    }

    return (
        <Surface
            title={paramTemplateId ?
                translate("extended_fields_templates.create_edit.edit.title") as string :
                translate("extended_fields_templates.create_edit.create.title") as string
            }
            subtitle={translate("extended_fields_templates.create_edit.subtitle") as string}
            icon={<LibraryBooks />}
            backButton
            onAction={() => { history.goBack() }}
            className="PaperPagination" >
            <form autoComplete="off" onSubmit={(e) => onSubmit(e)} >
                <Grid item xs={12}>
                    <Box px={1} pt={2}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={6}>
                                <ValidatedInput margin="dense" type="text" id="template_name" name="template_name"
                                    value={request.template_name} label={translate("extended_fields_templates.create_edit.inputs.template_name") as string}
                                    required={true} disabled={submitting}
                                    validator={REQUIRED_VALIDATOR}
                                    onValueChanged={onChangeName} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <TemplateSettingsFields
                    segments={template?.fields_response}
                    onAdd={() => onOpenAddFields()}
                    onDelete={(fieldId) => onDeleteField(fieldId)}
                    onUpdatePriority={onUpdatePriorityField}
                    onGetPriority={getFieldPriority}
                />
                <Grid item xs={12}>
                    <Box px={1} pt={2}>
                        <Grid container justify="flex-end" alignItems="flex-end" spacing={1}>
                            <Grid item xs="auto">
                                <Button onClick={onCancel} variant="outlined" color="primary" size="large" disabled={submitting}>
                                    {translate("buttons.close")}
                                </Button>
                            </Grid>

                            {context.isGrantedAny(["ExtendedFieldsTemplateUpdate", "ExtendedFieldsTemplateCreate"]) &&
                                <Grid item xs="auto">
                                    <Button
                                        type='submit'
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={submitting}>
                                        {translate("buttons.save")}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {showFieldsPopUp &&
                <TemplateAddFieldsPopup
                    tenantId={context.session!.tenant!.id}
                    onSave={onAddFields}
                    onClose={() => { setShowFieldsPopUp(false); }}
                    ids={getIdsFromRequest()}
                />
            }
        </Surface>
    )
}