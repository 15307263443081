import { Cfdi, StatusesCfdi } from '../model/Cfdi';
import translate from "../i18n/Translator";

export const getTranslateStatus = (cfdi: Cfdi, statusesCfdi?: StatusesCfdi) => {
    if (cfdi?.metadata?.custom_status) {
        return cfdi.metadata.custom_status;
    }

    if ("REFUND" === cfdi.type) {
        return translate(`cfdis.history.status.${cfdi?.metadata.status}`) as string;
    }

    return translateStatus(cfdi?.metadata.status, statusesCfdi);
};


export const translateStatus = (cfdiStatus: String, statusesCfdi?: StatusesCfdi) => {
    const status = statusesCfdi?.items.find(e => e.value === cfdiStatus);
    if (!!status && !!status?.title) {
        return status.title;
    }
    return translate(`cfdis.history.status.${cfdiStatus}`) as string;
};


export const tryTranslateCfdiStatus = (cfdiStatus: String) => {
    const status = getWorkFlowTranlateStatus()?.items.find(e => e.value === cfdiStatus);
    if (!!status && !!status?.title) {
        return status.title;
    }
    return translate(`cfdis.history.status.${cfdiStatus}`) as string;
};

export const getWorkFlowTranlateStatus = (): StatusesCfdi => {
    const items = localStorage.getItem("cfdi_status");
    if (items) {
        try {
            return JSON.parse(items);
        } catch (_) { }
    }
    return ({ items: [], total: 0 } as StatusesCfdi);
};