import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { CustomerShippingRequirement, CustomerShippingRequirementRequest, DocumentCombo, getDocumentName } from "../model/Customer";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput from "../components/ValidatedInput";
import { IntegerValidator, GTZeroValidator } from "../components/Validators";
import { EXPIRATION_UNITS } from "../model/Expiration";
import { listDocumentsByCustomer } from "../api/CustomerShippingRequirementApi";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";

interface CustomerShppingTemplateReqFormProps {
    tenantId: string;
    customerId: string;
    shippingRequirement?: CustomerShippingRequirement;
    shippingRequirements: CustomerShippingRequirement[];
    onCreate(request: CustomerShippingRequirementRequest): any;
    onUpdate(request: CustomerShippingRequirementRequest): any;
    onClose(): any;
}

export default function CustomerShppingTemplateReqForm(props: CustomerShppingTemplateReqFormProps) {
    const [request, setRequest] = useState<CustomerShippingRequirementRequest>({
        customer_id: props.customerId,
        initial_month: 0
    } as CustomerShippingRequirementRequest);
    const [submitting, setSubmitting] = useState(false);
    const intergerValidator = new IntegerValidator(true);
    const gtZeroValidator = new GTZeroValidator();
    const [warning, setWarning] = useState<string>();
    const [monthValues, setMonthValues] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [documents, setDocuments] = useState<DocumentCombo[]>([]);
    const [documentValues, setDocumentValues] = useState<string[]>([]);
    const [documentLabels, setDocumentLabels] = useState<string[]>([]);
    const [expirationUnitLabels] = useState(() => {
        return EXPIRATION_UNITS.map(
            (type) => translate(`expedients.fields.expirationUnit.${type}`) as string
        );
    });

    const [error, setError] = useState<string>();

    useEffect(() => {
        setSubmitting(true);
        let months = [] as string[];
        for (var i = 0; i < 12; i++) {
            months.push(`${i + 1}`);
        }
        setMonthValues(months);
        setMonthLabels(months.map((m) => translate(`months.${m}`) as string));

        if(props.shippingRequirement){
            setRequest({
                customer_id: props.customerId,
                document_id: props.shippingRequirement.document_id,
                description: props.shippingRequirement.description,
                unit: props.shippingRequirement.unit,
                quantity: props.shippingRequirement.quantity,
                day: props.shippingRequirement.day,
                initial_month: props.shippingRequirement.initial_month,
            } as CustomerShippingRequirementRequest);
        }

        listDocumentsByCustomer(props.tenantId).then(response => {
            let temp = response.items.filter(item => props.shippingRequirement || props.shippingRequirements.find(req => req.document_id === item.id) === undefined);
            setDocuments(temp);
            setDocumentValues(temp.map(item => item.id));
            setDocumentLabels(temp.map(item => getDocumentName(item.custom, item.value)));
        }).catch(error => {
            setError(error.message);
        }).finally(()=> setSubmitting(false));
    }, [props]);

    const onSubmit = () => {
        if(request.document_id === undefined || request.document_id === ""){
            setWarning(translate("customer_shipping_requirements.warnings.document_id") as string);
            return;
        }
        if(request.unit === undefined || request.unit === ""){
            setWarning(translate("customer_shipping_requirements.warnings.unit") as string);
            return;
        }
        if(request.quantity === undefined || request.quantity <= 0 || request.quantity > 12){
            setWarning(translate("customer_shipping_requirements.warnings.quantity") as string);
            return;
        }
        if(request.day === undefined || request.day <= 0 || request.day > 29){
            setWarning(translate("customer_shipping_requirements.warnings.day") as string);
            return;
        }
        if(Number(request.initial_month) === 0){
            setWarning(translate("customer_shipping_requirements.warnings.initial_month") as string);
            return;
        }
        setSubmitting(true);
        if(props.shippingRequirement){
            props.onUpdate(request);
            return;
        }
        props.onCreate(request);
        setSubmitting(false);
    };

    const hasChanged = (name: string, value: string) => {
        if(name === "document_id"){
            let tempDoc = documents.find(doc => value === doc.id);
            if (tempDoc) {
                setRequest({ ...request, [name]: value, custom: tempDoc.custom });
            }
        } else {
            setRequest({ ...request, [name]: value });
        }
    };

    return (
                <DialogPopup
                    open
                    title={translate("customer_shipping_requirements.add_button")}
                    onClose={props.onClose}
                    closeColor="default"
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    disable={submitting}
                    button={
                        <Button
                        onClick={onSubmit}
                        variant="outlined"
                        color="secondary"
                        disabled={submitting}
                        >
                            {translate("buttons.save")}
                        </Button>
                    }
                    >
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="document_id" name="document_id"
                                value={request.document_id}
                                label={translate("customer_shipping_requirements.document_id") as string}
                                required={true} disabled={submitting || props.shippingRequirement !== undefined}
                                margin="dense"
                                options={documentValues}
                                optionLabels={documentLabels}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="description" name="description"
                                value={request.description}
                                label={translate("digital_documents.description") as string}
                                required={false} disabled={submitting}
                                margin="dense" rows={4}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="unit"
                                name="unit"
                                value={request.unit}
                                label={translate("expedients.fields.expirationUnit.unit") as string}
                                required={true}
                                disabled={submitting}
                                margin="dense"
                                options={EXPIRATION_UNITS}
                                optionLabels={expirationUnitLabels}
                                onValueChanged={hasChanged}
                            />
                            <ValidatedInput
                                type="number"
                                id="quantity"
                                name="quantity"
                                value={request.quantity+""}
                                label={
                                    translate("expedients.fields.expirationUnit.quantity") as string
                                }
                                required={true}
                                disabled={submitting}
                                validators={[intergerValidator, gtZeroValidator]}
                                margin="dense"
                                onValueChanged={hasChanged}
                            />
                            <ValidatedInput
                                type="number"
                                id="day"
                                name="day"
                                value={request.day+""}
                                label={
                                    translate("expedients.fields.expirationUnit.day") as string
                                }
                                required={true}
                                disabled={submitting}
                                validators={[intergerValidator, gtZeroValidator]}
                                margin="dense"
                                onValueChanged={hasChanged}
                            />
                            <ValidatedInput
                                type="number"
                                id="initial_month"
                                name="initial_month"
                                value={request.initial_month+""}
                                label={
                                    translate("expedients.fields.expirationUnit.initial_month") as string
                                }
                                options={monthValues}
                                optionLabels={monthLabels}
                                required={true}
                                disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged}
                            />
                        </Grid>
                    </Grid>
                    <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
                    <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
                </DialogPopup>);
}