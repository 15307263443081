import { Customer } from "./Customer";

export interface Contract {
    id: string;
    tenant_id: string;
    customer_id: string;
    description: string;
    sisub: string;
    type: ContractContractType;
    document: string;
    icsoe: string;
    from: Date;
    to: Date;
    created_at: Date;
    customer?: Customer;
    date_undetermined: boolean;
    amount: number;
}

export interface Contracts {
    items: Contract[];
    total: number;
}

export interface ContractsQueryParams {
    search: string;
    startDate: string;
    endDate: string;
}

export interface ContractRequest {
    description: string;
    sisub: string;
    type: ContractContractType;
    document: string;
    icsoe: string;
    from: string;
    to: string;
    date_undetermined: boolean;
    amount: number;
}

export type ContractContractType = 'work' | 'time' | 'trial' | 'training';

export const CONTRACT_TYPES: ContractContractType[] = ['work', 'time', 'trial', 'training'];
