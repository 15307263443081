import React, { useState, useContext, useEffect } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { Button, Grid, Divider, IconButton, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import Gridable from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import ClassIcon from "@material-ui/icons/Class";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import { RouterParams } from "../router/RouterParams";
import { ProviderClassification, ProviderClassifications, ProviderClassificationsQueryFilter } from "../model/ProviderClassification";
import { listClassifications, deleteProviderClassification } from "../api/ProviderClassificationAPI";
import ProviderClassificationsMenu from "./ProviderClassificationsMenu";

export default function ProviderClassificationsView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ProviderClassifications>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [providerClassification, setProviderClassification] = useState<ProviderClassification>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const qs = queryString.parse(window.location.search);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const paramsFromQueryString = (): ProviderClassificationsQueryFilter => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as ProviderClassificationsQueryFilter;
    };
    const [params, setParams] = useState<ProviderClassificationsQueryFilter>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ProviderClassificationsQueryFilter>(paramsFromQueryString);
    const [processing, setProcessing] = useState(false);
    const [redirect, setRedirect] = useState<string>();

    const load = () => {
        let offset = getOffset(page, pageSize);
        history.push(window.location.pathname);
        if (match.params.success && isSuccess) {
            setSuccess(match.params.success);
            setIsSuccess(false);
        }
        listClassifications(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setProviderClassification(undefined);
    };

    const onClickedOptions = (classification: ProviderClassification) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setProviderClassification(classification);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const openConfirmDelete = () => {
        setOpenConfirmDialog(true);
        setAnchorEl(null);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDialog(false);
    };

    const onConfirmDelete = () => {
        setOpenConfirmDialog(false);
        setProcessing(true);
        if(!providerClassification) return;
        deleteProviderClassification(context.session!.tenant!.id, providerClassification.id).then((_) => {
            setSuccess(translate("provider_classifications.success_delete", { name: providerClassification.name }) as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setProcessing(false);
        });
    };

    const onClickedRow = (classification: ProviderClassification) => {
        if (!context.isGrantedAny(['ProviderClassificationsUpdate'])) {
            return;
        }
        setRedirect(`/provider-classifications/${classification.id}/edit`);
    };
    
    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Pagination title={translate("provider_classifications.title")} icon={<ClassIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("ProviderClassificationsCreate") ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Link to="/provider-classifications/new">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                <AddIcon />
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>) : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm={true} md={true} lg>
                        <ValidatedInput type="text" id="search" name="search" label={translate("buttons.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("provider_classifications.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("provider_classifications.columns.name") as string,
                        converter: (classification) => classification.name,
                        xs: 5,
                        md: 8,
                        lg: 9
                    },
                    {
                        title: translate("provider_classifications.columns.providers_counter") as string,
                        converter: (classification) => classification.providers_counter,
                        justify: "center",
                        xs: 4,
                        md: 2,
                        lg: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                        ),
                        converter: (classification) => (
                            <IconButton aria-label="options"
                                color="default"
                                size="small"
                                onClick={onClickedOptions(classification)} >
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: true,
                    }
                ]} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={processing} message={translate("provider_classifications.processing") as string} />
            {providerClassification && anchorEl && (
                <ProviderClassificationsMenu classification={providerClassification}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onDeleteClassification={openConfirmDelete}
                />
            )}
            {providerClassification && openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onConfirmDelete}
                    onClose={closeConfirmDelete}
                    title={translate("provider_classifications.confirm_delete.title") as string}
                    message={translate(`provider_classifications.confirm_delete.${ providerClassification.providers_counter > 0 ? 'in_use_message' : 'message' }`) as string} 
                    button={translate("buttons.accept") as string} />
            )}
            
        </Pagination>
    )

}
