import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";

import translate from "../i18n/Translator";
import { Widget } from "../model/Widget";
import { AppContext } from "../context/AppContext";
import AddIcon from '@material-ui/icons/Add';
import MyCfdisIcon from '@material-ui/icons/InsertDriveFileTwoTone';
import { DeleteIcon } from "../components/Icons";

interface WidgetMenuProps {
    tenantId: string;
    widget?: Widget;
    queryString?: string;
    anchor: HTMLElement | null;
    onClose(): any;
    onBillingWidget?(): void
    onContentWidget?(): void
    onDeleteWidget?(name: string): void
}

export default function WidgetMenu(props: WidgetMenuProps) {
    const context = useContext(AppContext);
    const menuItemOptions = () => {
        if (props.onContentWidget && props.onContentWidget) {
            return (
                <div>
                    <MenuItem button onClick={props.onContentWidget} dense>
                        <ListItemIcon>
                            <AddIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("widgets.content_widget")}</Typography>
                    </MenuItem>
                    <MenuItem button onClick={props.onBillingWidget} dense>
                        <ListItemIcon>
                            <MyCfdisIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("widgets.billing_data_widget")}</Typography>
                    </MenuItem>
                </div>
            );
        }

        return (
            <div>
                {context.isGranted("WidgetsUpdate") && "billing_data" !== props?.widget?.type &&
                    <MenuItem button component={Link} to={`/tenants/${props.tenantId}/widgets/${props?.widget?.id}/edit?${props.queryString}`} onClick={props.onClose} dense>
                        <ListItemIcon>
                            <EditIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                    </MenuItem>
                }

                {context.isGranted("WidgetsDelete") && 
                    <MenuItem button onClick={() =>{
                        if(props?.onDeleteWidget){
                            props?.onDeleteWidget(props?.widget?.name ?? "");
                        }
                    }}
                     dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("widgets.delete_widget_title")}</Typography>
                    </MenuItem>
                }
            </div>
        );

    }

    return (
        <Menu
            id="widgets-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {menuItemOptions()}
        </Menu>
    );

}