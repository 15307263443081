import { Fab, Divider, Grid, List, ListItem, ListItemText, Tab, Tabs, Typography, Chip, Box, Button } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { updateAdditionalInfoField } from "../api/RequisitionAPI";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { Requisition, TransitionRequest, RequisitionLinesCreateRequest, RequisitionLineUpdateRequest, RequisitionLine } from "../model/Requisition";
import { forwardRequisition, backwardRequisition } from "../api/RequisitionAPI";
import { Company } from "../model/Company";
import { PaymentCondition, TenantCurrenciesResponse } from "../model/TenantConfiguration";
import CustomBackdrop from "../components/CustomBackdrop";
import { AdditionalInfoField, AdditionalFieldRequest } from "../model/AdditionalInfoWorkflove";
import AdditionalFieldsSection from "../components/AdditionalFieldsSection";
import { formatDateToShow } from "../components/DateFormat";
import { HeaderRequisitionFormProps } from "./HeaderRequisitionForm";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import makeStyles from "@material-ui/core/styles/makeStyles";
import queryString from "query-string";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import RequisitionHistory from "./RequisitionHistory";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import RejectIcon from '@material-ui/icons/ThumbDown';
import CfdisToSendIcon from "@material-ui/icons/SendTwoTone";
import ToAuthorizeIcon from '@material-ui/icons/ThumbUp';
import AddIcon from "@material-ui/icons/AddTwoTone";
import ConfirmationPopup from "../components/ConfirmationPopup";
import RequisitionLinesGrid from "./RequisitionLinesGrid";
import CreateRequisitionLinePopup from "./CreateRequisitionLinePopup";
import { Budget, BudgetLine, BudgetsQueryParams } from "../model/Budget";
import EditRequisitionLine from "./EditRequisitionLine";
import NumberFormat from "react-number-format";
import OwnersTooltip from "../components/OwnersTooltip";

interface DetailsRequisitionFormProps extends HeaderRequisitionFormProps {
    paymentConditionIds: string[];
    paymentConditions: PaymentCondition[];
    paymentConditionLabels: string[];
    onUpdateAdditionalInfo(requisition: Requisition): any;
    onOpenEditRequisition(): void;
    onCreateLines(requestLines: RequisitionLinesCreateRequest): void;
    onUpdateLine(number: number, request: RequisitionLineUpdateRequest): void;
    onDeleteLine(number: number): void;
    tenantCurrencies: TenantCurrenciesResponse;
    budgets: Budget[];
}

interface RequisitionTab {
    name: string;
    title: string;
    actions?: string[];
}

export default function DetailsRequisitionForm(props: DetailsRequisitionFormProps) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [requisition, setRequisition] = useState<Requisition>();
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));
    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };
    const qs = queryString.parse(window.location.search);
    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };
    const classes = useStyles();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");
    const [tabs, setTabs] = useState<RequisitionTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);
    const [openConfirmSend, setOpenConfirmSend] = useState<boolean>(false);
    const [openConfirmReject, setOpenConfirmReject] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string | undefined>();
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [openConfirmEdit, setOpenConfirmEdit] = useState<boolean>(false);
    const [openCreateLine, setOpenCreateLine] = useState<boolean>(false);
    const [selectedLine, setSelectedLine] = useState<RequisitionLine>();
    const [changeAmount, setChangeAmount] = useState<number>(0);
    const referer = typeof qs["referer"] === "string" ? qs["referer"] as string : qs["referer"] !== undefined && qs["referer"] !== null ? qs["referer"][0] : undefined;

    const backTo = ((): string | undefined => {
        return `/requisitions/${referer ?? "to_send"}?${queryString.stringify(qs)}`;
    })();

    const allTabs = [
        {
            name: "details",
            title: translate("requisitions.overview.details.title" as string)
        },
        {
            name: "history",
            title: translate("requisitions.overview.history.title" as string)
        },
    ] as RequisitionTab[];

    useEffect(() => {
        setStatus("loading");
        let changeFound = props.tenantCurrencies.tenant_currencies.find(item => item.code === props.requisition?.currency);
        if (changeFound) {
            setChangeAmount(changeFound.budget_exchange_rate);
        }
        setRequisition(props.requisition);
        setTabs(allTabs);
        setStatus("loaded");
        setLoading(false);
    }, [props.requisition]);

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line 
    }, [qs.tab, tabs]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setValue(newValue);
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const onSaveField = (field: AdditionalInfoField) => {
        if (!field) return;
        setOpenBackdrop(true);
        updateAdditionalInfoField(context.session!.tenant!.id, props.requisition.id, {
            id: field.id,
            value: field.value,
            type: field.type.id,
        } as AdditionalFieldRequest).then(requisition => {
            props.onUpdateAdditionalInfo(requisition);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    };

    const tabContent = (index: number) => {
        if (!props.requisition) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return <DetailBudgetControl requisition={props.requisition} companies={props.companies} onSaveField={onSaveField} />;
            case "history":
                return <RequisitionHistory requisition={props.requisition} />;
            default:
                return <div></div>;
        }
    };

    const onCreateOC = () => {
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/requisitions/${requisition!.id}/createOC?` + queryString.stringify(qs));
    };

    const onOpenConfirmSend = () => {
        if (!requisition) return;
        setOpenConfirmSend(true);
    };

    const onOpenConfirmReject = () => {
        setOpenConfirmReject(true);
    };

    const onCancelReject = () => {
        setOpenConfirmReject(false);
    };

    const onCancelSend = () => {
        setOpenConfirmSend(false);
    };

    const onSend = () => {
        onSendWithReason("");
    };

    const onSendWithReason = (reason: string) => {
        setOpenConfirmSend(false);
        if (!requisition) return;
        setStatus("loading");
        forwardRequisition(context.session!.tenant!.id, requisition.id, { reason: reason } as TransitionRequest).then(requisition => {
            setRedirect(backTo);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onReject = (reason: string) => {
        setOpenConfirmReject(false);
        if (!requisition) return;
        setStatus("loading");
        backwardRequisition(context.session!.tenant!.id, requisition.id, { reason: reason } as TransitionRequest).then(requisition => {
            setRedirect(backTo);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onSaveLines = (budgetLines: BudgetLine[]) => {
        setOpenCreateLine(false);
        if (!requisition) return;
        let requestLines = { budget_lines: budgetLines, change_amount: changeAmount } as RequisitionLinesCreateRequest;
        props.onCreateLines(requestLines);
    };

    const onOpenCreateLine = () => {
        if (!changeAmount) {
            setWarning(translate("requisitions.overview.currency_exchange_rate_required", { currency: requisition?.currency }) as string);
            return;
        }
        setOpenCreateLine(true);
    };

    const onCloseCreateLine = () => {
        setOpenCreateLine(false);
    };

    const onOpenEditLine = (line: RequisitionLine) => {
        if (!requisition || requisition.status_group !== "TO_SEND") {
            return;
        }
        setSelectedLine(line);
        setOpenConfirmEdit(true);
    };

    const onCloseEditLine = () => {
        setSelectedLine(undefined);
        setOpenConfirmEdit(false);
    };

    const onConfirmUpdateLine = (number: number, request: RequisitionLineUpdateRequest) => {
        setSelectedLine(undefined);
        setOpenConfirmEdit(false);
        props.onUpdateLine(number, request);
    };

    const onOpenDeleteLine = (line: RequisitionLine) => {
        setOpenConfirmDelete(true);
        setSelectedLine(line);
    };

    const onCloseDeleteLine = () => {
        setOpenConfirmDelete(false);
        setSelectedLine(undefined);
    };

    const onConfirmDeleteLine = () => {
        props.onDeleteLine(selectedLine?.number || 0);
    };

    if (status === "loading" || !requisition) {
        return <Progress />;
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    const getStatusDescription = () => {
        if (!requisition) return "---";
        let statusFind = requisition.statuses.find(item => item.status === requisition.status);
        return statusFind ? statusFind.description : requisition.status;
    }

    const chipStatus = () => {
        if (!props.requisition) return "---";
        return <Grid className={classes.root}>
            <Typography variant="body1" component="h6">
                {props.typesRequisitions.find(item => item.id === requisition.requisition_type_id)?.name}
            </Typography>
            <Chip color="primary" label={getStatusDescription()} disabled variant="outlined" size="small" />
            {requisition.owners && requisition.owners.some(o => o.active) &&
                <Grid item className={classes.root}>
                    <OwnersTooltip owners={requisition.owners} />
                </Grid>
            }
        </Grid>
    }

    return (
        <Surface
            title={props.requisition.description}
            subtitle={chipStatus()} backButton
            backTo={backTo}
            icon={<MoreVertIcon />}
            className="FormSurface" titleActions={
                <Grid>
                    {referer !== "archive" &&
                        <Grid container alignItems="center" justify="flex-end" spacing={1}>
                            {context.isGranted("RequisitionsUpdate") && (props.requisition.status_group === 'TO_SEND' || props.requisition.status_group === 'TO_AUTHORIZE') && props.requisition.actions.edit &&
                                (<Grid item xs="auto">
                                    <Fab aria-label="add" color="primary" size="small" title={translate("buttons.edit") as string} onClick={props.onOpenEditRequisition} >
                                        <EditIcon />
                                    </Fab>
                                </Grid>)
                            }
                            {context.isGranted("RequisitionsUpdate") && (requisition.status_group === 'TO_AUTHORIZE' || requisition.status_group === 'PENDING_PURCHASE_ORDER') && requisition.actions.reject &&
                                (<Grid item xs="auto"> <Fab color="primary" size="small" title={translate("buttons.reject") as string} onClick={onOpenConfirmReject} >
                                    <RejectIcon />
                                </Fab> </Grid>)
                            }
                            {context.isGranted("RequisitionsUpdate") && (requisition.status_group === 'TO_SEND' || requisition.status_group === 'TO_AUTHORIZE') && (requisition.actions.send || requisition.actions.authorize) &&
                                (<Grid item xs="auto"> <Fab color="primary" size="small" title={translate(requisition.status_group === 'TO_SEND' ? "buttons.send" : "buttons.authorize_payment") as string} onClick={onOpenConfirmSend} >
                                    {requisition.status_group === 'TO_SEND' && <CfdisToSendIcon />}
                                    {requisition.status_group === 'TO_AUTHORIZE' && <ToAuthorizeIcon />}
                                </Fab> </Grid>)
                            }
                            {context.isGranted("RequisitionsUpdate") && requisition.status_group === 'PENDING_PURCHASE_ORDER' && requisition.actions.create_po &&
                                (<Grid item xs="auto"> <Fab aria-label="add" color="primary" size="small" title={translate("requisitions.overview.create_oc") as string} onClick={onCreateOC} >
                                    <ShopTwoIcon />
                                </Fab> </Grid>)
                            }
                        </Grid>}
                </Grid>
            }>
            <Tabs value={value} onChange={handleChange} aria-label={translate("requisitions.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: RequisitionTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: RequisitionTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Box pt={2}>
                        <Grid container alignItems="center" direction="row" justify="space-between" >
                            <Grid item xs="auto" justify="flex-start">
                                <Typography variant="subtitle1" component="h6">
                                    {translate("requisitions.overview.lines")}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto" justify="flex-end" alignItems="flex-end" alignContent="flex-end" >
                                {context.isGrantedAny(["RequisitionsUpdate"]) && requisition && requisition.status_group === "TO_SEND" ?
                                    <Button aria-label="add" color="primary" size="small" onClick={onOpenCreateLine} startIcon={(
                                        <AddIcon fontSize="small" />
                                    )}>
                                        <Typography variant="body2">{translate("buttons.add_upper")}</Typography>
                                    </Button>
                                    : undefined
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <RequisitionLinesGrid
                        lines={requisition.lines}
                        currency={requisition.currency}
                        modifyEnable={requisition.status_group === "TO_SEND"}
                        onOpenEditLine={onOpenEditLine}
                        onOpenDeleteLine={onOpenDeleteLine}
                        prorationHandling={requisition.requisition_type_proration_handling}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
                </Grid>
            </Grid>
            {openConfirmSend &&
                <ConfirmationPopup
                    title={translate(requisition.status_group === 'TO_SEND' ? "requisitions.overview.confirm_send.title" : "requisitions.overview.confirm_send.authorize_title") as string}
                    message={translate("requisitions.overview.confirm_send.message")}
                    button={translate(requisition.status_group === 'TO_SEND' ? "buttons.accept" : "buttons.authorize_payment") as string}
                    onClose={onCancelSend}
                    doAction={onSend}
                    addCommentary={false}
                    doActionCommentary={onSendWithReason}
                />
            }
            {openConfirmReject &&
                <ConfirmationPopup
                    title={translate("requisitions.overview.confirm_reject.title") as string}
                    message={translate("requisitions.overview.confirm_reject.message")}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelReject}
                    addCommentary={true}
                    doActionCommentary={onReject}
                />
            }
            {openConfirmDelete && selectedLine &&
                <ConfirmationPopup
                    title={translate("requisitions.lines.delte_line_title") as string}
                    message={translate("requisitions.lines.delete_line_message") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCloseDeleteLine}
                    doAction={onConfirmDeleteLine}
                />
            }
            {openCreateLine &&
                <CreateRequisitionLinePopup
                    tenantId={tenantId}
                    requisitionTypeId={requisition.requisition_type_id}
                    companyId={requisition.company_id}
                    currency={requisition.currency}
                    changeAmount={changeAmount}
                    onClose={onCloseCreateLine}
                    onSave={onSaveLines}
                />
            }
            {openConfirmEdit && selectedLine &&
                <EditRequisitionLine
                    tenantId={tenantId}
                    line={selectedLine}
                    onSubmit={onConfirmUpdateLine}
                    onClose={onCloseEditLine}
                    requisition={props.requisition}
                />

            }
        </Surface>
    );

    interface DetailBudgetControlProps {
        companies: Company[];
        requisition: Requisition;
        onSaveField: any;
    }

    function DetailBudgetControl(props: DetailBudgetControlProps) {

        const getCompanyName = () => {
            return props.companies.find(item => item.id === props.requisition.company_id)?.name;
        }

        return (
            <Grid xs={12}>
                <Grid container alignItems="flex-start" justify="space-between">
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{getCompanyName()}</strong>} secondary={translate("requisitions.overview.details.company")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{translate(`currency.${props.requisition.currency}`) as string}</strong>} secondary={translate("currency.title") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.provider_name}</strong>} secondary={translate("requisitions.overview.details.provider")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{formatDateToShow(new Date(props.requisition.created_at))}</strong>}
                                    secondary={translate("requisitions.overview.details.created_at")} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{<Typography variant="subtitle1">
                                    <NumberFormat value={props.requisition.total || 0.00} suffix={` ${props.requisition.currency}`} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                </Typography>}</strong>} secondary={translate("requisitions.columns.total") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{<Typography variant="subtitle1">
                                    <NumberFormat value={props.requisition.total_mxn || 0.00} suffix={` ${"MXN"}`} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                </Typography>}</strong>} secondary={translate("requisitions.columns.total_mxn") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <br /> <Divider /> <br />
                {props.requisition && props.requisition.fields_response && (
                    <AdditionalFieldsSection title={translate("requisitions.form.title_additional_info") as string} fields={props.requisition.fields_response} onSaveField={props.onSaveField} />
                )}
                <br /> <Divider /> <br />
                <Grid item xs={12}>

                </Grid>
            </Grid>
        );
    }
}
