import React from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AddIcon } from "../components/Icons";
import { Product } from "../workflove/Workflove";

interface NewShippingTemplateMenuProps {
    path: string;
    products: Product[];
    anchor: HTMLElement | null;
    onClose(): any;
}

function NewShippingTemplateMenu({ path, products, anchor, onClose }: NewShippingTemplateMenuProps) {
    return (
        <Menu
            id="new-shipping_template-menu"
            anchorEl={anchor}
            keepMounted
            onClose={onClose}
            open
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Fade}>
            {products.map((product) => (
                <MenuItem key={product} button component={Link} to={`${path}?product=${product}`} onClick={onClose} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate(`shipping_templates.products.${product}`)}</Typography>
                </MenuItem>
            ))}
        </Menu>
    )
}

export default NewShippingTemplateMenu;