import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent } from "@material-ui/core";

import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";
import { InputRef } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { ExecutePaymentFileRequest, PaymentOrderFile } from "../model/PaymentOrder";

interface ExecutePaymentFileProps {
    payment_file: PaymentOrderFile;
    onCompleted(field: ExecutePaymentFileRequest): any;
    onClose(): any;
}

export default function ExecutePaymentFile(props: ExecutePaymentFileProps) {
    const margin = "dense";
    const [request, setRequest] = useState<ExecutePaymentFileRequest>({} as ExecutePaymentFileRequest);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>();
    const [fileUrl, setFileUrl] = useState<string>();
    const [paymentDate, setPaymentDate] = useState<Date>();

    useEffect(() => {
        setRequest({
            payment_file_id: props.payment_file.id || "",
            payment_date: paymentDate || moment().format("DD/MM/YYYY"),
            url_file: fileUrl || "",
            name_file: fileName || ""
        } as ExecutePaymentFileRequest);
    }, [props.payment_file, paymentDate, fileUrl, fileName]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setDisabled(false);
        let parts = name.split(".");
        if (parts.length === 1) {
            setRequest({ ...request!, url_file: value, name_file: name });
            setFileUrl(value);
            setFileName(name);
        } else {
            let tmp = request as any;
            if (typeof tmp[parts[0]] !== "undefined") {
                tmp[parts[0]][parts[1]] = value;
            }
            setFileUrl(tmp);
            setRequest(tmp as ExecutePaymentFileRequest);
        }
    };

    const onClickFile = () => {
        setDisabled(true);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setRequest({ ...request!, payment_date: date })
            setPaymentDate(date);
        } else {
            let temp = request
            delete request!.payment_date;
            setRequest(temp);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.onCompleted(request);
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("payment_order.titles.execute_payment_file") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography variant="body1" paragraph>
                            {translate("payment_order.messages.confirm_execute", { "cfdis": props.payment_file.cfdis.length, "total": <NumberFormat value={props.payment_file.total} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" /> }) as string}
                        </Typography>
                        <DatePicker label={translate("payment_order.messages.date_label") as string}
                            name="schedule_date"
                            initial={moment().format("DD/MM/YYYY")}
                            onChange={onChangedDate}
                            required={true}
                            defaultIfMissing={false} clearable={false} autoOK disablePast={false} disableFuture={false} disabled={false} />

                        <UploaderInput
                            id="aux_file_url"
                            name="aux_file_url"
                            value={fileUrl}
                            label={translate("payment_order.messages.upload_label") as string}
                            required={false}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                            onClick={onClickFile}
                            tooltip={translate("expedients.fields.aux_file.tooltip") as string}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={false || disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={false || disabled}>
                        {translate("buttons.execute")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
