import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import AuxiliarDocumentsIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";

import { listAuxiliarDocuments, deleteAuxiliarDocument } from "../api/AuxiliarDocumentAPI";
import { AppContext } from "../context/AppContext";
import { AuxiliarDocument, AuxiliarDocuments, AuxiliarDocumentsQueryParams } from "../model/AuxiliarDocument";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import AuxiliarDocumentMenu from "./AuxiliarDocumentMenu";
import DateFormat from "../components/DateFormat";
import DownloadIcon from '@material-ui/icons/GetApp';

export default function AuxiliarDocumentsList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<AuxiliarDocuments>();
    const [workingParams, setWorkingParams] = useState<AuxiliarDocumentsQueryParams>({
        search: search,
    } as AuxiliarDocumentsQueryParams);

    const [auxiliarDocument, setAuxiliarDocument] = useState<AuxiliarDocument>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [isCreateGranted] = useState(context.isGranted("AuxiliarDocumentsCreate"));

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as AuxiliarDocumentsQueryParams;
        setWorkingParams(params);

        listAuxiliarDocuments(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (auxiliarDocument: AuxiliarDocument) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAuxiliarDocument(auxiliarDocument);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onDelete = () => {
        if (!auxiliarDocument) return;

        setAnchorEl(null);
        setPopup("delete");
    };

    const onConfirmDelete = () => {
        onClosePopup();
        if (!auxiliarDocument) return;

        setSubmitting(true);
        deleteAuxiliarDocument(tenantId,  auxiliarDocument.id).then(() => {
            setSuccess(
                translate("auxiliardocuments.delete.success", {id: auxiliarDocument.id}) as string
            );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };


    const onCloseOption = () => {
        setAnchorEl(null);
        setAuxiliarDocument(undefined);
    };

    return (
        <Pagination title={translate("auxiliardocuments.title")} icon={<AuxiliarDocumentsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Link to="/auxiliar-documents/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("auxiliardocuments.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("auxiliardocuments.empty") as string}
                columns={[
                    {
                        title: translate("auxiliardocuments.name") as string,
                        converter: (auxiliarDocument) => (
                            <Ellipsis text={ auxiliarDocument.name } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: 3,
                    },
                    {
                        title: translate("auxiliardocuments.role_ids") as string,
                        converter: (auxiliarDocument) => (
                            <Ellipsis text={ auxiliarDocument.roles.map(rol =>  {
                                if(rol.role_parent_id){
                                    return rol.description;
                                }
                                return translate(`users.roles.${rol.id}`) as string;
                            }).join(",") } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: 3,
                    },
                    {
                        title: translate("auxiliardocuments.updated_at") as string,
                        converter: (field) => <DateFormat date={field.updated_at} format="DD/MM/YYYY" />,
                        xs: 3,
                    },
                    {
                        title: "",
                        converter: (field) => <IconButton
                                                aria-label="options"
                                                color="primary"
                                                size="small"
                                                component="a"
                                                href={field.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                disabled={false}  >
                                                <DownloadIcon />
                                            </IconButton>,
                        xs: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (auxiliarDocument) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(auxiliarDocument)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        xs: "auto"
                    }
                ]} />
            { auxiliarDocument && anchorEl &&
                <AuxiliarDocumentMenu auxiliarDocument={ auxiliarDocument } anchor={anchorEl} onDelete={onDelete} onClose={onCloseOption} />
            }
            { auxiliarDocument && popup === "delete" && (
                <DialogPopup
                open
                title={translate("auxiliardocuments.delete.title")}
                onClose={onClosePopup}
                closeColor="default"
                disableEscapeKeyDown={submitting}
                disableBackdropClick={submitting}
                disable={submitting}
                button={
                    <Button
                    onClick={onConfirmDelete}
                    variant="outlined"
                    color="secondary"
                    disabled={submitting}
                    >
                        {translate("buttons.delete")}
                    </Button>
                }
                >
                    {translate("auxiliardocuments.delete.text", { id: auxiliarDocument.id })}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}