import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormControl } from "@material-ui/core";

export interface MultiselectValue {
  title: string;
  value: string;
}

interface MultiselectDropLitProps {
    limitTags?: number;
    title: string;  
    elementos: MultiselectValue[];
    value?: MultiselectValue[];
    placement?: "end" | "start" | "top" | "bottom";
    margin?: "normal" | "dense" | "none";
    variant?: "filled" | "outlined" | "standard";
    required?: boolean;
    onChanged(selected: string[]): void;
    disabled?: boolean;
}

export default function MultiselectDropList(props: MultiselectDropLitProps) {
 
    return (
      <FormControl margin={props.margin ?? "normal"} variant={props.variant ?? "outlined"} fullWidth required={props.required} > 
        <Autocomplete
          disabled={props.disabled}
          multiple
          size="small"
          limitTags={props.limitTags}
          options={props.elementos}
          getOptionLabel={(elemento) => elemento.title}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={props.title}  fullWidth />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              props.onChanged(newValue.map(obj => obj.value));
            } else{
              props.onChanged([]);
            }
          }}
          getOptionSelected={(opt, val) => opt.value === val.value}
          value={props.value}
        />
      </FormControl>
    );
  }
