import React, { useContext, useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Cfdi } from "../model/Cfdi";
import { NoCfdiPayableDocumentRequest } from "../model/NoCfdiPayableDocument";
import { create } from "../api/NoCfdiPayableDocumentApi";
import { getTenantCfdiFileUrl } from "../api/TenantCfdiApi";
import { AppContext } from "../context/AppContext";
import { getProviders } from "../api/ProviderAPI";
import { Providers, ProvidersQueryParams } from "../model/Provider";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { Workflow } from "../model/Workflow";
import { Company } from  "../model/Company";
import CfdiAsignWorkflowPopUp from "../cfdi/CfdiAsignWorkflowPopUp";
import { NumberValidator, GTZeroValidator } from "../components/Validators";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";

interface NewForeignPopupProps {
    cfdi?: Cfdi;
    onSuccess(payableDocument: Cfdi): any;
    onCancel(): any;
    provider_id?: string;
}

export default function NewForeignPopup(props: NewForeignPopupProps) {
    const context = useContext(AppContext);
    const margin = "dense";
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [providerLabels, setProviderLabels] = useState<string[]>([]);
    const [providerIds, setProviderIds] = useState<string[]>([]);
    const [providerWorkflows, setProviderWorkflows] = useState<Workflow[]>();
    const [providerCompanies, setProviderCompanies] = useState<Company[]>();
    const [openChangeWorkflow, setOpenChangeWorkflow] = useState<boolean>(false);
    const [cfdi, setCfdi] = useState<Cfdi>();
    const numberValidator = new NumberValidator(true);
    const gtZeroValidator = new GTZeroValidator();

    const paramsFromQueryString = (): ProvidersQueryParams => {
        return {
            "search": "",
            "not_located_status": "",
            "efos_status": "",
            "status": "ENABLED",
            "classification": "",
            "validator_user_id": "",
            "receptor": context.session && context.session.role && context.session.user && context.session?.role?.id === 'receptor_cfdi' ? context.session.user.id : "",
            "specific_rule": "",
            "workflow_id": "",
            "type": "FOREIGN"
        } as ProvidersQueryParams;
    };
    const [pdfUrl, setPdfUrl] = useState<string>();

    // eslint-disable-next-line
    const [params, setParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const [request, setRequest] = useState<NoCfdiPayableDocumentRequest>({
        date: new Date(),
        amount: 0.00,
        identifier_cfdi: props.cfdi ? props.cfdi!.id : "",
        file_name: "",
        type: "FOREIGN",
        tenant_side: !!!context.session!.provider
    } as NoCfdiPayableDocumentRequest);
    const [validations, setValidations] = useState({} as any);
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);

    const load = () => {
        if (props.cfdi && props.cfdi!.metadata.pdf_url) {
            setRequest({ ...request, url: props.cfdi!.metadata.pdf_url});
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi!.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
        getTenantCurrencies(context.session!.tenant!.id).then((resp) => {
            if (resp.tenant_currency_ids) {
                let listTemp = [] as AutocompleteProvider[];
                    resp.tenant_currency_ids.forEach((item) => {
                        let temp = { title:(item +" - "+ (translate("currency." + item) as string)), value: item  } as AutocompleteProvider;
                        listTemp.push(temp);
                        setCurrenciesAutocomplete(listTemp);
                    });
            }
        }).catch((error) => {
            setError(error.message);
        });
        if (props.provider_id) {
            let loadRequest = request;
            loadRequest.provider_id = props.provider_id
            setRequest(loadRequest);
        } else {
            getProviders(context.session!.tenant!.id, 0, 0, params).then((response) => {
                fillProvider(response);
            }).catch((error) => {
                setError(error.message);
            });
        }
    };

    useEffect(load, []);

    const fillProvider = (providers: Providers) => {
        let labels = [] as string[];
        let ids = [] as string[];
        if (props.cfdi && props.cfdi.type === "FOREIGN") {
            labels.push(providers.items.filter(c=> c.id === props.cfdi!.metadata.provider_id)[0].name);
            ids.push(providers.items.filter(c=> c.id === props.cfdi!.metadata.provider_id)[0].id);
            setRequest({ ...request, provider_id: ids[0], url: props.cfdi!.metadata.pdf_url});
        } else {
            providers.items.forEach(p => {
                labels.push(p.name);
                ids.push(p.id);
            });
        }
        
        setProviderLabels(labels);
        setProviderIds(ids);
    }

    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        if(name === "url"){
            let copyRequest = request;
            request.file_name = inputRef.original_name ? inputRef.original_name : "";
            setRequest(copyRequest);
        }
        setValidations(validations);
    };

    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
        if(inputRef){
            validations["currency"] = inputRef;
            setValidations(validations);
        }
    };

    const onChangedProvider = (name: string, value: string, inputRef: InputRef) => {
        let newRequest = request;
        newRequest.provider_id = value;
        setRequest(newRequest);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest({ ...request, date: date });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = () => {        
        onCreate("", "");
    };

    const onCreate = (workflowId: string, companyId: string) => {
        if (!isValid()) return;
        setSubmitting(true);
        create(context.session!.tenant!.id, { ...request, workflow_id: workflowId, company_id: companyId }).then((payableDocument: Cfdi) => {
            if (!payableDocument.metadata.company_id || ((payableDocument.metadata.type !== "CREDIT_NOTE" && payableDocument.metadata.type !== "PAYMENT_CFDI") && (!payableDocument.metadata.workflow_id || payableDocument.metadata.workflow_id === "") && payableDocument.metadata.provider_workflows && payableDocument.metadata.provider_workflows.length > 1)) {
                setProviderWorkflows(payableDocument.metadata.provider_workflows);
                setProviderCompanies(payableDocument.metadata.incident_companies);
                setCfdi(payableDocument);
                setOpenChangeWorkflow(true);
                setSubmitting(false);
                return;
            } else {
                props.onSuccess(payableDocument);
            }
        }).catch(error => {
            setError(error.message);
            setSubmitting(false);
        });
    }

    const onSelectedWorkflow = (workflowId : string, companyId : string, confirmChange : boolean) => {
        if(cfdi && !cfdi.metadata.company_id && !companyId){
            setWarning(translate("companies.warning") as string);
        }
        if ( cfdi && !cfdi.metadata.workflow_id && !workflowId) {
            setWarning(translate("workflows.cfdi_workflow.empty_selection") as string);
        } 

        setOpenChangeWorkflow(false);
        onCreate(workflowId, companyId);
    }

    const onCloseWorkflowSelect = () => {
        setOpenChangeWorkflow(false);
        if(cfdi && !cfdi.metadata.workflow_id){
            props.onCancel();
        }
        
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        <DialogPopup open title={translate("foreign.title")}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            maxWidth="sm"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancel}
            closeColor="default"
            button={
                <Button onClick={onSubmit} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.add")}
                </Button>
            }>
            <Grid container>
                {!props.provider_id &&
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="provider" name="provider"
                            label={translate("foreign.provider") as string}
                            value={request.provider_id}
                            required
                            margin={"dense"}
                            optionLabels={providerLabels}
                            options={providerIds}
                            disabled={props.cfdi !== undefined && props.cfdi.type === "FOREIGN" && props.cfdi.total_to_pay === undefined}
                            onValueChanged={onChangedProvider} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <DatePicker label={translate("foreign.date") as string} name="date"
                        onChange={onChangedDate} clearable={false} required disabled={submitting}
                        format="DD/MM/YYYY" initial={request.date ? moment(request.date).format("DD/MM/YYYY") : undefined} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="number_invoice" name="number_invoice" type="text"
                        label={translate("foreign.number_invoice") as string}
                        value={request.number_invoice} required disabled={submitting}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                    <TenantCurrencyAutocomplete currencies={currenciesAutocomplete} onChange={onFilterChanged} disabled={submitting}/>
                </Grid>
                <Grid item xs={6}>
                    <ValidatedInput id="amount" name="amount" type="number"
                        label={translate("foreign.amount", { "currency": request.currency }) as string}
                        value={request.amount + ""} required disabled={submitting}
                        margin={margin}
                        validators={[numberValidator , gtZeroValidator]}
                        onValueChanged={onChanged} />

                </Grid>
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput id="url" name="url"
                        label={translate("foreign.file") as string}
                        value={pdfUrl ? pdfUrl : request.url} required disabled={props.cfdi !== undefined}
                        margin={margin} path="cover" acceptExtension=".pdf"
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="comments" name="comments" type="text"
                        label={translate("foreign.comments") as string}
                        value={request.comments} disabled={submitting}
                        rows={3} maxRows={3}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
            </Grid>
            { openChangeWorkflow && (providerWorkflows || providerCompanies) && cfdi &&
                            <CfdiAsignWorkflowPopUp 
                            workflows={providerWorkflows || []}
                            companies={providerCompanies || []} 
                            isChangeWorkflow={false}
                            onCompleted={onSelectedWorkflow} 
                            cfdi={cfdi}
                            confirmChange={false} 
                            onClose={onCloseWorkflowSelect}/>
                        }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );
}