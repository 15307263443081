import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Divider, Button, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArchiveIcon from "@material-ui/icons/Archive";
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { exportCfdisSelected, exportAllCfdisRange, exportPaymentCfdis, exportAllPaymentCfdis, getTenantCfdis, exportCfdisExcelDateRange, deleteCfdi, cancelCfdi, listCfdiStatus, exportCfdisProratedExcel, getActorFieldsTypeCatalogByStage, exportCfdiFiles, exportValidationsPdfCfdis } from "../api/TenantCfdiApi";
import { getCfdis } from "../api/ProviderCfdiApi";
import { ActorStageFieldsResponse, Cfdi, Cfdis, CfdisQueryParams, FilterField, TypeCfdi, TypeCfdiWithoutCustomAgent } from "../model/Cfdi";
import { StatusInvoiceProvider, StatusInvoiceTenant, StatusCrediteNote, StatusPaymentCfdi, StatusCancelled } from "../model/Cfdi";
import { TenantCfdisRequest, ExportCfdisResponse, DeleteCfdiRequest } from "../model/TenantCfdi";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import CustomBackdrop from "../components/CustomBackdrop";
import ValidatedInput from "../components/ValidatedInput";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import TenantCfdiArchiveMenu from "./menus/TenantCfdiArchiveMenu";
import MultiselectDropList from "../components/MultiselectDropList";
import DateRange from "../components/DateRange";
import { MultiselectValue } from "../components/MultiselectDropList";
import CfdisMenuArchive from "./menus/TenantCfdisArchiveMenu";
import { CfdisListProps } from "../cfdi/CfdisList";
import CfdiDeletePopup from "../cfdis/popups/CfdiDeletePopup";
import RefreshIcon from '@material-ui/icons/RefreshTwoTone';
import ReactHtmlParser from "html-react-parser";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString, concatDates, stringToDate, stringToDateEnd } from "../components/DateFormat";
import Semaphore from "../components/Semaphore";
import { exportPaymentCfdiFiles } from "../api/TenantCfdiApi";
import { validateGenerateInMembership } from "../api/ProviderMembershipTaxStampApi";
import { isRoleOrParent } from "../model/Role";
import RequestClarification from "../clarifications/RequestClarification";
import DownloadRange from "../components/DownloadRange";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";
import FilterPopup from "../components/FilterPopup";
import DeleteCfdiPopup from "../cfdis/popups/DeleteCfdiPopup";

export default function ListCfdisArchive(props: CfdisListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const sDate = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status_group": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "type": typeof qs["type"] === "string" ? qs["type"] as string : "INVOICE",
            "status_list": typeof qs["status_list"] === "string" ? qs["status_list"] as string : "",
            "selected_ids": typeof qs["selected_ids"] === "string" ? qs["selected_ids"] as string : "",
            "start_date": typeof qs["start_date"] === "string" ? stringToDate(qs["start_date"]) : sDate,
            "end_date": typeof qs["end_date"] === "string" ? stringToDateEnd(qs["end_date"]) : new Date(),
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "issuer": typeof qs["issuer"] === "string" ? qs["issuer"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "send_at_start": typeof qs["send_at_start"] === "string" ? qs["send_at_start"] as string : "",
            "send_at_end": typeof qs["send_at_end"] === "string" ? qs["send_at_end"] as string : "",
            "payment_schedule_start": typeof qs["payment_schedule_start"] === "string" ? qs["payment_schedule_start"] as string : "",
            "payment_schedule_end": typeof qs["payment_schedule_end"] === "string" ? qs["payment_schedule_end"] as string : "",
            "payment_due_start": typeof qs["payment_due_start"] === "string" ? qs["payment_due_start"] as string : "",
            "payment_due_end": typeof qs["payment_due_end"] === "string" ? qs["payment_due_end"] as string : "",
            "payment_deadline_start": typeof qs["payment_deadline_start"] === "string" ? qs["payment_deadline_start"] as string : "",
            "payment_deadline_end": typeof qs["payment_deadline_end"] === "string" ? qs["payment_deadline_end"] as string : "",
            "authorized_at_start": typeof qs["authorized_at_start"] === "string" ? qs["authorized_at_start"] as string : "",
            "authorized_at_end": typeof qs["authorized_at_end"] === "string" ? qs["authorized_at_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
            "referer_view": "ARCHIVE",
            "filter_fields": typeof qs["filter_fields"] === "string" && qs["filter_fields"] ? JSON.parse(qs["filter_fields"] as string) : undefined,
            "provider_id": typeof qs["provider_id"] === "string" && qs["provider_id"] ? qs["provider_id"] as string : undefined
        } as CfdisQueryParams;
    };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>();
    const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [selectedCfdis, setSelectedCfdis] = useState<(number | string)[]>([]);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openConfirmDialogPayments, setOpenConfirmDialogPayments] = useState<boolean>(false);

    const isProvider = isRoleOrParent(context.session!.role, "provider");
    const isReceptor = isRoleOrParent(context.session!.role, "receptor_cfdi");
    const isSender = isRoleOrParent(context.session!.role, "sender_cfdi");

    const typesCfdi = context.session && context.session.provider && context.session.provider.type !== "CUSTOMS_AGENT" ? TypeCfdiWithoutCustomAgent : TypeCfdi.filter(type => type.value !== "REFUND");
    const [typeCfdi] = useState<string[]>(typesCfdi.map(type => type.value));
    const [typeNames] = useState<string[]>(typesCfdi.map(type => type.title));
    const [redirect, setRedirect] = useState<string>();
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);
    const [queryParam, setQueryParam] = useState<string>();
    const [openConfirmDialogDelete, setOpenConfirmDialogDelete] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [elementsStatus, setElementsStatus] = useState<MultiselectValue[]>();
    const [valuesStatus, setValuesStatus] = useState<MultiselectValue[]>([]);
    const [clarification, setClarification] = useState<boolean>(false);
    const [cfdisSelected, setCfdisSelected] = useState<Cfdi[]>([]);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openExportExcelSelected, setOpenExportExcelSelected] = useState<boolean>(false);
    const [openExportProratedExcel, setOpenExportProratedExcel] = useState<boolean>(false);
    const [fieldsCatalogType, setFieldsCatalogType] = useState<ActorStageFieldsResponse>();
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [openDownloadValidationsPdf, setOpenDownloadValidationsPdf] = useState<boolean>(false);
    const [openConfirmCancelSat, setOpenConfirmCancelSat] = useState<boolean>(false);

    const paramsToFilters = (paramsExt?: CfdisQueryParams) => {
        let temp = paramsExt ? paramsExt : params;
        return [
            { columnName: 'identifier', value: temp.identifier },
            { columnName: 'issuer', value: temp.issuer },
            { columnName: 'description', value: temp.description },
            { columnName: 'date', value: concatDates(temp.date_start, temp.date_end) },
            { columnName: 'send_at', value: concatDates(temp.send_at_start, temp.send_at_end) },
            { columnName: 'type', value: temp.type_filter },
            { columnName: 'payment_schedule', value: concatDates(temp.payment_schedule_start, temp.payment_schedule_end) },
            { columnName: 'payment_due', value: concatDates(temp.payment_due_start, temp.payment_due_end) },
            { columnName: 'payment_deadline', value: concatDates(temp.payment_deadline_start, temp.payment_deadline_end) },
            { columnName: 'authorized_at', value: concatDates(temp.authorized_at_start, temp.authorized_at_end) },
            { columnName: 'total', value: temp.total },
            { columnName: 'balance', value: temp.balance },
            { columnName: 'currency', value: temp.currency },
            { columnName: 'stage', value: temp.status_group },
            { columnName: 'company_name', value: params.company_name },

        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const promise = (params: CfdisQueryParams) => {
        if (isProvider) {
            return getCfdis(context.session!.tenant!.id, context.session!.provider!.id, "", pageSize, pageSize * page, params);
        } else {
            return getTenantCfdis(context.session!.tenant!.id, isReceptor ? context.session!.user.id : "", pageSize, pageSize * page, params);
        }
    }

    const load = () => {
        setStatus("loading");
        setOpenBackdrop(true);
        pushHistory();
        promise(validateParams()).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
            setOpenBackdrop(false);
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = {...params, ...workingParams};
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.identifier = filter.value;
                        break;
                    case "issuer":
                        temp.issuer = filter.value;
                        break;
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    case "send_at":
                        let datesSend = filter.value.split(" - ");
                        if (datesSend.length === 2) {
                            temp.send_at_start = formatDateString(datesSend[0]);
                            temp.send_at_end = formatDateString(datesSend[1]);
                        } else {
                            temp.send_at_start = "";
                            temp.send_at_end = "";
                        }
                        break;
                    case "payment_schedule":
                        let paymentSchedules = filter.value.split(" - ");
                        if (paymentSchedules.length === 2) {
                            temp.payment_schedule_start = formatDateString(paymentSchedules[0]);
                            temp.payment_schedule_end = formatDateString(paymentSchedules[1]);
                        } else {
                            temp.payment_schedule_start = "";
                            temp.payment_schedule_end = "";
                        }
                        break;
                    case "payment_due":
                        let paymentDues = filter.value.split(" - ");
                        if (paymentDues.length === 2) {
                            temp.payment_due_start = formatDateString(paymentDues[0]);
                            temp.payment_due_end = formatDateString(paymentDues[1]);
                        } else {
                            temp.payment_due_start = "";
                            temp.payment_due_end = "";
                        }
                        break;
                    case "payment_deadline":
                        let paymentDeadlines = filter.value.split(" - ");
                        if (paymentDeadlines.length === 2) {
                            temp.payment_deadline_start = formatDateString(paymentDeadlines[0]);
                            temp.payment_deadline_end = formatDateString(paymentDeadlines[1]);
                        } else {
                            temp.payment_deadline_start = "";
                            temp.payment_deadline_end = "";
                        }
                        break;
                    case "authorized_at":
                        let authorizedAtDates = filter.value.split(" - ");
                        if (authorizedAtDates.length === 2) {
                            temp.authorized_at_start = formatDateString(authorizedAtDates[0]);
                            temp.authorized_at_end = formatDateString(authorizedAtDates[1]);
                        } else {
                            temp.authorized_at_start = "";
                            temp.authorized_at_end = "";
                        }
                        break;
                    case "total":
                        temp.total = filter.value;
                        break;
                    case "balance":
                        temp.balance = filter.value;
                        break;
                    case "currency":
                        temp.currency = filter.value;
                        break;
                    case "stage":
                        temp.status_group = filter.value
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status_group"] = workingParams.status_group;
        qs["selected_ids"] = workingParams.selected_ids ? workingParams.selected_ids : "";
        qs["identifier"] = workingParams.identifier ? workingParams.identifier : "";
        qs["issuer"] = workingParams.issuer ? workingParams.issuer : "";
        qs["description"] = workingParams.description ? workingParams.description : "";
        qs["date_start"] = workingParams.date_start ? workingParams.date_start : "";
        qs["date_end"] = workingParams.date_end ? workingParams.date_end : "";
        qs["send_at_start"] = workingParams.send_at_start ? workingParams.send_at_start : "";
        qs["send_at_end"] = workingParams.send_at_end ? workingParams.send_at_end : "";
        qs["payment_schedule_start"] = workingParams.payment_schedule_start ? workingParams.payment_schedule_start : "";
        qs["payment_schedule_end"] = workingParams.payment_schedule_end ? workingParams.payment_schedule_end : "";
        qs["payment_due_start"] = workingParams.payment_due_start ? workingParams.payment_due_start : "";
        qs["payment_due_end"] = workingParams.payment_due_end ? workingParams.payment_due_end : "";
        qs["payment_deadline_start"] = workingParams.payment_deadline_start ? workingParams.payment_deadline_start : "";
        qs["payment_deadline_end"] = workingParams.payment_deadline_end ? workingParams.payment_deadline_end : "";
        qs["authorized_at_start"] = workingParams.authorized_at_start ? workingParams.authorized_at_start : "";
        qs["authorized_at_end"] = workingParams.authorized_at_end ? workingParams.authorized_at_end : "";
        qs["total"] = workingParams.total ? workingParams.total : "";
        qs["balance"] = workingParams.balance ? workingParams.balance : "";
        qs["currency"] = workingParams.currency ? workingParams.currency : "";
        qs["stage"] = workingParams.status_group;
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";
        qs["status_list"] = workingParams.status_list;
        qs["start_date"] = workingParams.start_date ? String(workingParams.start_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.start_date.getDate()).padStart(2, '0') + "-" + workingParams.start_date.getFullYear() : "";
        qs["end_date"] = workingParams.end_date ? String(workingParams.end_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.end_date.getDate()).padStart(2, '0') + "-" + workingParams.end_date.getFullYear() : "";
        qs["type"] = workingParams.type ? workingParams.type === "ALL" ? "" : workingParams.type : "";
        qs["filter_fields"] = workingParams.filter_fields ? JSON.stringify(workingParams.filter_fields) : "";
        qs["provider_id"] = workingParams.provider_id ? workingParams.provider_id : "";
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const validateParams = () => {
        let paramsTemp = { ...params } as CfdisQueryParams;
        let statusInvoiceForProvider = [...StatusInvoiceProvider, ...StatusInvoiceTenant, ...StatusCancelled];
        let statusAnyForProvider = [...StatusInvoiceProvider, ...StatusInvoiceTenant, ...StatusCrediteNote, ...StatusPaymentCfdi, ...StatusCancelled];
        let statusAnyForTenant = [...StatusInvoiceTenant, ...StatusCrediteNote, ...StatusPaymentCfdi, ...StatusCancelled];

        if (!isProvider && !paramsTemp.status_list) {
            switch (workingParams.type) {
                case 'INVOICE':
                    paramsTemp.status_list = (isProvider ? statusInvoiceForProvider : StatusInvoiceTenant).join(",");
                    break;
                case 'CREDIT_NOTE':
                    paramsTemp.status_list = ([...StatusCrediteNote]).join(",");
                    break;
                case 'PAYMENT_CFDI':
                    paramsTemp.status_list = ([...StatusPaymentCfdi]).join(",");
                    break;
                default:
                    paramsTemp.status_list = (isProvider ? statusAnyForProvider : statusAnyForTenant).join(",");
            }
        }

        if (paramsTemp.status_list && paramsTemp.status_list.split(",").find(status => status === "CANCELLED")) {
            paramsTemp.isCanceledInTenant = !isProvider;
        }

        if (paramsTemp.status_list.split(",").find(status => status === "CANCELLED_BY_SAT")) {
            let temp = paramsTemp.status_list.split(",").filter(status => status !== "CANCELLED_BY_SAT");
            paramsTemp.status_list = temp.join(",");
            paramsTemp.isCanceled = true;
            paramsTemp.isCanceledInTenant = !isProvider;
        }

        if ("FOREIGN" === workingParams.type) {
            paramsTemp.cfdiType = "INVOICE";
            paramsTemp.type = "FOREIGN";
        } else if ("FOREIGN_CREDIT_NOTE" === workingParams.type) {
            paramsTemp.cfdiType = "CREDIT_NOTE";
            paramsTemp.type = "FOREIGN_CREDIT_NOTE";
        } else if ("ADVANCE_PAYMENT" === workingParams.type) {
            paramsTemp.cfdiType = "INVOICE";
            paramsTemp.type = "ADVANCE_PAYMENT";
        } else if ("EXPENSES_ACCOUNT" !== paramsTemp.type && "HONORARIUM" !== paramsTemp.type && "ALL" !== workingParams.type) {
            paramsTemp.cfdiType = paramsTemp.type;
            paramsTemp.type = "CFDI"
        } else if ("ALL" === workingParams.type) {
            paramsTemp.type = "";
        }

        paramsTemp.provider_id = isProvider ? context.session!.provider!.id : (paramsTemp.provider_id ? paramsTemp.provider_id : "");
        paramsTemp.receptor = isReceptor ? context.session!.user.id : ""
        return { ...paramsTemp, archive: true };
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };
    
    const onClickFilter = () => {
        getActorFieldsTypeCatalogByStage(context.session!.tenant!.id).then((response) => {
            setFieldsCatalogType(response);
            if (response && response.fields && response.fields.length > 0) {
                setOpenFilterDialog(true);
            } else {
                setWarning(translate("cfdis.popup.filter_fields.not_filters_found") as string);
            }
        }).catch((error) => {
            setError(error.message);
        });
    }
    
    const onAppliedFilter = () => {
        pushHistory();
        setSelectedCfdis([]);
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string) => {
        setWorkingParams({ ...workingParams, [name]: value });
        if (name === "type")
            onTypeFilterChanged(value);
    };

    const onTypeFilterChanged = (value: string) => {
        setWorkingParams({ ...workingParams, type: value } as CfdisQueryParams);
        setValuesStatus([]);
        setElementsStatus([]);
        setSelectedCfdis([]);
        setStatus("loading");
        listCfdiStatus(context.session!.tenant!.id, value).then((response) => {
            setElementsStatus(response.items);
            setValuesStatus(response.items.filter(el => workingParams.status_list.split(",").indexOf(el.value) >= 0));
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onDownloadPaymentCfdis = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            exportPaymentCfdis(context.session!.tenant!.id, { cfdi_ids: selectedCfdis } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.total < 1) {
                    setWarning(translate("payment.archived.export.empty") as string);
                }
                else if (response.url) {
                    if (response.cfdis_without_payment_files !== undefined) {
                        setWarning(translate("cfdis.without_payments", { "comprobantes": response.cfdis_without_payment_files }) as string);
                    }
                    setExportResult(response);
                } else {
                    setSuccess(translate("cfdis.email_export") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onDownloadAllCfdis = (startDate: Date, endDate: Date) => {
        setOpenBackdrop(true);
        setOpenConfirmDialog(false);

        let paramsTemp = validateParams();
        paramsTemp.start_date = startDate;
        paramsTemp.end_date = endDate;
        exportAllCfdisRange(context.session!.tenant!.id, paramsTemp.receptor ? paramsTemp.receptor : "", paramsTemp).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                setExportResult(response);
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        });

    }

    const onDownloadAllPaymentCfdis = () => {
        setOpenBackdrop(true);
        setOpenConfirmDialogPayments(false);
        let paramsTemp = validateParams();
        paramsTemp.status_list = (StatusPaymentCfdi).join(",");
        exportAllPaymentCfdis(context.session!.tenant!.id, paramsTemp.provider_id, paramsTemp).then((response) => {
            setOpenBackdrop(false);
            if (response.total < 1) {
                setWarning(translate("payment.archived.export.empty") as string);
            }
            else if (response.url) {
                setExportResult(response);
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        });
    }

    const onOpenConfirmDialogPayments = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialogPayments(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }
    const onStatusChanged = (selected: string[]) => {
        setValuesStatus(elementsStatus ? elementsStatus.filter(el => selected.indexOf(el.value) >= 0) : []);
        if (selected) {
            setWorkingParams({ ...workingParams, status_list: selected.join(",") });
        } else {
            setWorkingParams({ ...workingParams, status_list: "" });
        }
    }

    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const onCloseConfirmDialogPayments = () => {
        setOpenConfirmDialogPayments(false);
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onClickedRow = (cfdi: Cfdi) => {
        pushHistory();
        setRedirect(`/cfdis/${cfdi.id}/details?referer=archive&${queryParam}`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onClickedClean = () => {
        let temp = {
            status_list: "",
            status_group: "",
            type: "INVOICE",
            start_date: sDate,
            end_date: new Date(),
            search: "",
            selected_ids: "", 
            filter_fields: undefined
        } as CfdisQueryParams;

        setParams(temp);
        setWorkingParams(temp);
        setFilters(paramsToFilters(temp));
    };

    const onChangeDateRange = (startDate: Date, endDate: Date) => {
        setWorkingParams({ ...workingParams, start_date: startDate, end_date: endDate });
    };

    const onOpenConfirmDialogExportExcel = () => {
        setGridAnchorEl(null);
        setOpenExportExcel(true);
    }

    const onClarification = () => {
        setGridAnchorEl(null);
        if (data && selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setCfdisSelected(data.items.filter(item => selectedCfdis.find(cfdiId => cfdiId === item.id)));
            setClarification(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_clarification") as string);
        }
    }

    const onDownloadFiles = (paramsTemp: CfdisQueryParams) => {
        exportCfdisExcelDateRange(context.session!.tenant!.id, paramsTemp).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenExportExcel(false);
        });
    }

    const onDownloadExcel = () => {
        setOpenBackdrop(true);
        setOpenConfirmDialogExportExcel(false);
        let paramsTemp = validateParams();
        paramsTemp.selected_ids = undefined;
        paramsTemp.receptor = "";
        onDownloadFiles(paramsTemp);
    }

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenConfirmDialogExportExcel(false);
        setOpenExportExcel(false);
        let paramsTemp = validateParams();
        paramsTemp.start_date = startDate;
        paramsTemp.end_date = endDate;
        paramsTemp.connector_id = connectorId;
        onDownloadFiles(paramsTemp);
    }

    const onDownloadSelectedToExcel = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            onDownloadWithConnectorSelected("");
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onDeleteComprobante = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        if (cfdi.metadata.cfdi_status_group === "SENDING" && (isProvider || isSender)) {
            setShowDeleteDialog(true);
        } else {
            setOpenConfirmDialogDelete(true);
        }
    };

    const onConfirmDeleteComprobante = (razon: string) => {
        setOpenConfirmDialogDelete(false);
        setStatus("loading");
        if (context.isGrantedAny(["AdminCFDIsDelete", "CxpCFDIsDelete", "ProviderCFDIsDelete"])) {
            deleteCfdi(context.session!.tenant!.id, cfdi!.id, { comments: razon } as DeleteCfdiRequest).then(() => {
                setStatus("loaded");
                load();
                setSuccess(translate("cfdis.pay_pending.actions.delete.success") as string);
            }).catch((error) => {
                setStatus("loaded");
                setError(error.message);
            });

        }
    };

    const onCloseConfirmDialogDelete = () => {
        setOpenConfirmDialogDelete(false);
    }

    const onError = (error: string) => {
        setError(error);
        setShowDeleteDialog(false);
    };

    const onCancel = () => {
        setShowDeleteDialog(false);
    };

    const onSuccessDelete = () => {
        setShowDeleteDialog(false);
        load();
        setSuccess(translate("cfdis.pay_pending.actions.delete.success") as string);
    }

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onConfirmCancel = () => {
        if (!cfdi) return;

        Promise.all([
            validateGenerateInMembership(cfdi!.id),
        ]).then(response => {
            let isGeneratedInMembership = response[0];
            if (cfdi.metadata.type === "PAYMENT_CFDI" && isGeneratedInMembership.generated_by_membership) {
                setOpenConfirmCancelSat(true);
            } else {
                onOpenCancelCfdi();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onCancelCfdi = () => {
        setOpenCancelConfirmDialog(false);
        if (!cfdi) return;
        setOpenBackdrop(true);
        cancelCfdi(context.session!.tenant!.id, cfdi.id).then((response) => {
            
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": cfdi.identifier, "cfdi_link": `/cfdis/${response.related_cfdi}/details?referer=archive&${queryParam}` }) as string
                ));
            } else {
            
            if(openConfirmCancelSat){
                setOpenConfirmCancelSat(false);
                setSuccess(translate("tax_stamp.cancel_sat_success") as string);
            } else {
                setSuccess(translate("tax_stamp.cancel_success") as string);
            }
                load();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadCfdisSelected = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selectedCfdis } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.url) {
                    window.open(response.url, "_blank")
                } else {
                    if (response.total > 0) {
                        setSuccess(translate("cfdis.email_export") as string);
                    } else {
                        setWarning(translate("cfdis.empty_export") as string);
                    }
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenConfirmDialogDownloadAllCfdis = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialog(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedCfdis(selected);
    };

    const onDownloadSinglePaymentCfdi = () => {
        if (!cfdi) {
            return;
        }
        exportPaymentCfdiFiles(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.total < 1) {
                setWarning(translate("payment.archived.export.empty") as string);
                return;
            }
            if (response.url) {
                setExportResult(response);
            }
        }).catch((error) => {
            setError(error.message);
        });
        setAnchorEl(null);
    }

    const onCloseClarification = () => {
        setOpenBackdrop(false);
        setClarification(false);
    };
    const onRequestClarification = (comment: string) => {
        setSuccess(comment);
        setOpenBackdrop(false);
        setClarification(false);
    };

    const onCloseExporter = () => {
        setOpenExportExcel(false);
        setOpenExportProratedExcel(false);
        setOpenDownloadValidationsPdf(false);
    };

    const onCloseExporterSelected = () => {
        setOpenExportExcelSelected(false);
    };

    const onDownloadWithConnectorSelected = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportExcelSelected(false);
        let n_params = validateParams();
        n_params.selected_ids = selectedCfdis.join(",");
        n_params.start_date = undefined;
        n_params.end_date = undefined;
        n_params.connector_id = connectorId;
        onDownloadFiles(n_params);
    }

    const onDownloadSelectedToProratedExcel = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setOpenExportExcelSelected(false);
            let exportParams = params;
            exportParams.selected_ids = selectedCfdis.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            exportProratedCfdiExcel(exportParams);

        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const exportProratedCfdiExcel = (params: CfdisQueryParams) => {
        exportCfdisProratedExcel(context.session!.tenant!.id, params).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenExportProratedExcel(false);
        });
    }

    const onOpenConfirmDialogExportProratedExcel = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenExportProratedExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadWithOutConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportProratedExcel(false);
        let exportParams = validateParams();
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        exportParams.connector_id = connectorId;
        exportProratedCfdiExcel(exportParams);
    }

    const onDownloadCfdiFiles = () => {
        setAnchorEl(null);
        setCfdi(undefined);
        setOpenBackdrop(true);
        if (!cfdi) return;
        exportCfdiFiles(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.url) {
                setExportResult(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadSelectedValidationsPdf = () => {
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
            setGridAnchorEl(null);
            let exportParams = params;
            exportParams.selected_ids = selectedCfdis.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        } else {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenConfirmDialogDownloadValidationsPdf = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenDownloadValidationsPdf(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadValidationsPdf = (paramsExport: CfdisQueryParams) => {
        exportValidationsPdfCfdis(context.session!.tenant!.id, paramsExport).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
            setGridAnchorEl(null);
        });
    }

    const onDownloadDateValidationsPdf = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenDownloadValidationsPdf(false);
        setGridAnchorEl(null);
        setAnchorEl(null);
        let exportParams = validateParams();
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        onDownloadValidationsPdf(exportParams);
    }

    const onDoFilter = (filterFields: FilterField[]) => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: filterFields }
        setWorkingParams(params);
        setParams(params);
    };

    const onCleanFilter = () => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: undefined }
        setWorkingParams(params);
        setParams(params);
    };

    return (
        <Surface title={props.title}
            subtitle={translate(isProvider ? "cfdis.group.title" : "cfdis.tenant_group.title") as string}
            icon={<ArchiveIcon />}
            className="PaperPagination"
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={true} xl={true}>
                    <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <ValidatedInput type="text" id="type" name="type" label={translate("cfdis.filters.type.title") as string}
                        margin="dense" disabled={false}
                        value={workingParams.type} onValueChanged={onFilterChanged}
                        options={typeCfdi}
                        optionLabels={typeNames}
                        omitEmpty />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                    <MultiselectDropList
                        title={translate("providers.filter_enabled") as string}
                        margin="dense"
                        elementos={elementsStatus ? elementsStatus : []}
                        onChanged={onStatusChanged}
                        value={valuesStatus} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
                    <DateRange
                        startDate={workingParams.start_date}
                        endDate={workingParams.end_date}
                        onChange={onChangeDateRange} />
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onClickFilter} variant={params.filter_fields ? "contained" : "outlined"} color="primary" size="medium">
                        { params.filter_fields ? translate("buttons.filters_applied") : translate("buttons.filter")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshIcon />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton color="default" size="small" onClick={onClickedClean} disabled={status === "loading"}>
                        <HighlightOffTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className="focaltec-small">
                <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items.map(item => {
                        return {
                            ...item,
                            stage:
                                <Semaphore cfdi={item} />
                        };
                    }) : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    columns={props.columns ? props.columns : []}
                    columnsFormat={props.columnsFormat}
                    clickRowColumns={props.clickRowColumns}
                    selectionIds={selectedCfdis}
                    onClickRow={onClickedRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    defaultExpandedGroups={props.defaultExpandedGroups}
                    amountCurrencyColumns={props.currencyColumns}
                    dateColumns={props.dateColumns}
                    leftColumns={props.leftColumns}
                    rightColumns={props.rightColumns}
                    textColumns={props.textColumns}
                    filters={filters}
                    grouping={props.grouping}
                    customFormatColumns={props.customPlugins}
                    setFiltersHandler={setFiltersHandler}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    setTableCell={props.tableCell}
                    setCheckCell={props.checkCell}
                />
            </div>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {cfdi && anchorEl && (
                <TenantCfdiArchiveMenu cfdi={cfdi} anchor={anchorEl}
                    referer={`archive&${queryParam}`}
                    isProvider={isProvider}
                    onCancel={onConfirmCancel}
                    onClose={onCloseOption}
                    onDownloadFiles={onDownloadCfdiFiles}
                    onDeleteComprobante={onDeleteComprobante}
                    onDownloadPaymentCfdi={onDownloadSinglePaymentCfdi} />
            )}
            {gridAnchorEl && (
                <CfdisMenuArchive anchor={gridAnchorEl}
                    onDownloadCfdisSelected={onDownloadCfdisSelected}
                    onOpenConfirmDialog={onOpenConfirmDialogDownloadAllCfdis}
                    onDownloadPaymentCfdis={onDownloadPaymentCfdis}
                    onOpenConfirmDialogPayments={onOpenConfirmDialogPayments}
                    onDownloadExcelSelected={onDownloadSelectedToExcel}
                    onDownloadExcel={onOpenConfirmDialogExportExcel}
                    onClarification={onClarification}
                    onClose={() => setGridAnchorEl(null)}
                    onDownloadProratedExcel={onOpenConfirmDialogExportProratedExcel}
                    onDownloadProratedExcelSelected={onDownloadSelectedToProratedExcel}
                    onDownloadValidationsPdf={onOpenConfirmDialogDownloadValidationsPdf}
                    onDownloadValidationsPdfSelected={onDownloadSelectedValidationsPdf}
                />
            )}
            {openConfirmDialog && (
                <DownloadRange
                    onClose={onCloseConfirmDialog}
                    onExportRange={onDownloadAllCfdis}
                    family="CFDIS"
                    maxDaySelected={31} />
            )}
            {openConfirmDialogPayments && (
                <CfdisConfirmPopup
                    doAction={onDownloadAllPaymentCfdis}
                    onClose={onCloseConfirmDialogPayments}
                    title={translate("cfdis.popup.confirm_dialog_payments.title") as string}
                    message={translate("cfdis.popup.confirm_dialog.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog.button") as string} />
            )}
            {openConfirmDialogExportExcel && (
                <CfdisConfirmPopup
                    doAction={onDownloadExcel}
                    onClose={() => setOpenConfirmDialogExportExcel(false)}
                    title={translate("cfdis.popup.confirm_dialog_excel.title") as string}
                    message={translate("cfdis.popup.confirm_dialog_excel.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog_excel.button") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description_archive") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {openConfirmDialogDelete && cfdi && (
                <DeleteCfdiPopup
                    cfdi={cfdi}
                    messageDefault={translate("cfdis.pay_pending.actions.delete.description", { "provider": cfdi.cfdi ? isProvider ? cfdi.cfdi.receptor.nombre : cfdi.cfdi.emisor.nombre : "" }) as string}
                    onConfirmDeleteComprobante={onConfirmDeleteComprobante}
                    onCloseConfirmDialogDelete={onCloseConfirmDialogDelete} />
            )}
            {showDeleteDialog && cfdi &&
                <CfdiDeletePopup cfdi={cfdi}
                    tenantId={context.session!.tenant!.id}
                    providerId={context.session!.provider!.id}
                    onCompleted={onSuccessDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            }
            {cfdi && openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("CREDIT_NOTE" === cfdi.metadata.type ? "cfdis.cancel_cfdi.title_cn" : "cfdis.cancel_cfdi.title") as string}
                    message={translate("CREDIT_NOTE" === cfdi.metadata.type ? "cfdis.cancel_cfdi.message_cn" : "cfdis.cancel_cfdi.message") as string}
                    secondary={translate("CREDIT_NOTE" === cfdi.metadata.type ? "cfdis.cancel_cfdi.secondary_cn" : "cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {clarification && context.session!.provider && (
                <RequestClarification
                    doAction={onRequestClarification}
                    onClose={onCloseClarification}
                    isCommentRequired={true}
                    cfdis={cfdisSelected}
                    providerId={context.session!.provider.id}
                    onError={(error) => setError(error)}
                />
            )}
            {(openExportExcel || openExportProratedExcel || openDownloadValidationsPdf) && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA_RANGE"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporter}
                    onExport={openExportExcel ? onDownloadWithConnector : openExportProratedExcel ? onDownloadWithOutConnector : onDownloadDateValidationsPdf}
                    maxDaySelected={31} />
            )}
            {openExportExcelSelected && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporterSelected}
                    onExport={onDownloadWithConnectorSelected} />
            )}
            {openFilterDialog && fieldsCatalogType && (
                <FilterPopup
                    filterFields={fieldsCatalogType}
                    tenantId={context.session!.tenant!.id}
                    appliedFilters={workingParams.filter_fields}
                    doAction={onDoFilter}
                    onCleanFilter={onCleanFilter}
                    onClose={() => setOpenFilterDialog(false)} />
            )}
            {openConfirmCancelSat && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenConfirmCancelSat(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.confirm_cancel_sat") as string}
                    button={translate("buttons.accept") as string} />
            )}
        </Surface>
    );

}