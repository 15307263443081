import React, { useContext, useState, useEffect } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { clearElementBankAccount, updateBankAccounts } from "../api/ProviderExpedientAPI";
import { ProviderExpedientBankAccount, ProviderExpedient, ProviderExpedientBankAccountRequest } from "../model/ProviderExpedient";
import { BankAccount } from "../model/BankAccount";
import { Entity } from "../model/Provider";
import ExpedientProviderSegments from "./ExpedientProviderSegments";
import ExpedientProviderBankAccountForm from "./ExpedientProviderBankAccountForm";
import { ProviderBankAccount } from "../providers/ProviderBankAccountCard";
import DialogPopup from "../components/DialogPopup";
import { AppContext } from "../context/AppContext";
import LaunchIcon from '@material-ui/icons/Launch';
import { getBankAccountCoverUrl } from "../api/ProviderExpedientAPI";
import translate from "../i18n/Translator";

interface ExpedientProviderBankAccountsProps {
    metadata: ProviderExpedient;
    onChange(metadata: ProviderExpedient): any;
    disabled: boolean;
    provider?: Entity
}

export default function ExpedientProviderBankAccounts(props: ExpedientProviderBankAccountsProps) {
    const context = useContext(AppContext);

    return (
        <ExpedientProviderSegments
            disabledEdition={props.disabled}
            metadata={props.metadata}
            name="bank_accounts"
            provider={props.provider}
            supplier={(metadata) => Object.values(metadata.bank_accounts) as ProviderExpedientBankAccount[]}
            onUpdated={(tenantId: string, providerId: string, current: ProviderExpedientBankAccount, value: BankAccount) => {
                let request = {
                    id: current.configuration.id,
                    value: value,
                } as ProviderExpedientBankAccountRequest;

                return updateBankAccounts(tenantId, providerId, request);
            }}
            clear={(tenantId: string, providerId: string, bankAccountId: string)=>{
                return clearElementBankAccount(tenantId, providerId, bankAccountId);
            }}
            onChange={props.onChange}
            buildForm={(current: ProviderExpedientBankAccount, onClose: () => any, onSubmitted: (value: BankAccount) => any) => (
                <ExpedientProviderBankAccountForm
                    title={current.configuration.name}
                    subtitle={current.configuration.description}
                    bankAccount={current.value}
                    comments={current.comments}
                    onClose={onClose}
                    onCompleted={onSubmitted}
                    disabled={false}
                    id={current.configuration.id}
                    tenantId={context.session!.tenant!.id}
                    providerId={props.provider ? props.provider.id : context.session!.provider!.id}
                />
            )}
            buildPopup={(current: ProviderExpedientBankAccount, onClose: () => any) => (
                <DialogPopup title={current.configuration.name} open onClose={onClose}>
                    <ProviderBankAccount
                        tenantId={context.session!.tenant!.id}
                        providerId={props.provider ? props.provider.id : context.session!.provider!.id}
                        configuration={current.configuration}
                        bank_account={current.value} />
                </DialogPopup>
            )}
            buildData={(current: ProviderExpedientBankAccount) => (
                current.value ?
                    bankAccountDetails(
                        current.value, 
                        context.session!.tenant!.id, 
                        props.provider ? props.provider.id : context.session!.provider!.id, 
                        current.configuration.id, 
                        current.value?.cover)
                    : undefined
            )}
        />
    );
}

interface DownloadFileButtonProps {
    tenantId: string;
    providerId: string;
    fieldId: string;
    value: string;
}

function DownloadFileButton(props: DownloadFileButtonProps) {
    const [url, setUrl] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (!props.value) {
            setDisabled(true);
            setUrl("");
            return;
        }
        if (props.value.startsWith("http")) {
            setUrl(props.value);
        } else {
            getBankAccountCoverUrl(props.tenantId, props.providerId, props.fieldId).then((url) => {
                setUrl(url);
                setDisabled(false);
            }).catch((error) => {
                setDisabled(true);
            });
        }
    }, [props.tenantId, props.providerId, props.fieldId, props.value]);

    return (
        <IconButton
            aria-label="options"
            color="default"
            size="small"
            component="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            disabled={disabled}
        >
            <LaunchIcon />
        </IconButton>
    );
}

export function bankAccountDetails(data: BankAccount, tenantId: string, providerId: string, fieldId: string, cover: string) {
    return (
        <Grid container alignItems="flex-start" >
            <Grid item xs={12}>
                <Typography variant="body2">
                    {data.owner ? data.owner : ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid>
                    {data.bank_name && data.subsidiary && data.currency && data.country ? 
                    <Typography variant="caption">
                        {`${data.bank_name} - ${data.subsidiary} - ${data.currency} - ${data.country}`}
                    </Typography>
                    : undefined} 
                    <DownloadFileButton
                    tenantId={tenantId}
                    providerId={providerId}
                    fieldId={fieldId}
                    value={cover} /></Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {data.subsidiary_number ? `${translate("bank_account.sub_num")}: ${data.subsidiary_number}`:""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {data.account ? `${translate("bank_account.account")}: ${data.account}`: ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {!!data.bank_account_type_external_id? `${translate("bank_account.bank_account_type")}: ${data.bank_account_type_external_id}`: ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {!!data.code? `${translate("bank_account.code")}: ${data.code}`: ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {!!data.aba_code? `${translate("bank_account.aba_code")}: ${data.aba_code}`: ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {!!data.cie_agreement? `${translate("bank_account.cie_agreement")}: ${data.cie_agreement}`: ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {!!data.spid_relation_type_external_id? `${translate("bank_account.spid_relation_type")}: ${data.spid_relation_type_external_id}`: ""}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    {data.clabe ? `${translate("bank_account.clabe")}: ${data.clabe}`: ""}
                </Typography>
            </Grid>
        </Grid>
    );
}