import { TenantField, TenantAddress, TenantContact, TenantFieldBankAccount, TenantSegment } from "./TenantFieldCatalog";
import { Address } from "./Address";
import { Contact } from "./Contact";
import { BankAccount } from "./BankAccount";

export interface ProviderExpedient {
    fields: Map<string, ProviderExpedientField>;
    addresses: Map<string, ProviderExpedientAddress>;
    contacts: Map<string, ProviderExpedientContact>;
    bank_accounts: Map<string, ProviderExpedientBankAccount>;
    last_review?: Date;
    approved?: Date;
    status?: "CREATED" | "SENT" | "REJECTED" | "ACCEPTED" | "EDITED" | "NO_EXPEDIENT";
    has_data: boolean;
    expired: boolean;
    payment_bank_accounts?: Map<string, ProviderExpedientPaymentBankAccount>;
}

export interface ProviderExpedientFieldRequest {
    field: TenantField;
    field_id: string;
    value: string;
    status?: string;
    contact?: Contact;
    address?: Address;
    comments?: string;
}

export interface ProviderExpedientSegment<V, C extends TenantSegment> {
    configuration: C;
    value?: V;
    status?: string;
    updated_at?: Date;
    accepted_at?: Date;
    comments?: string;
    field_external_id?: string;
    value_external_id?: string;
    history: ProviderExpedientFieldHistory[];
}

export interface ProviderExpedientField extends ProviderExpedientSegment<string, TenantField> {
    url?: string;
    history: ProviderExpedientFieldHistory[];
}

export interface ProviderExpedientFieldHistory {
    started_at: Date;
    ended_at: Date;
    field: ProviderExpedientField;
}

export interface ProviderExpedientAddress extends ProviderExpedientSegment<Address, TenantAddress> {
}

export interface ProviderExpedientContact extends ProviderExpedientSegment<Contact, TenantContact> {
}

export interface ProviderExpedientBankAccount extends ProviderExpedientSegment<BankAccount, TenantFieldBankAccount> {
}

export interface ProviderExpedientSegmentRequest<V> {
    id: string;
    value: V;
}

export interface ProviderExpedientFieldRequest extends ProviderExpedientSegmentRequest<string> {
}

export interface ProviderExpedientAddressRequest extends ProviderExpedientSegmentRequest<Address> {
}

export interface ProviderExpedientContactRequest extends ProviderExpedientSegmentRequest<Contact> {
}

export interface ProviderExpedientBankAccountRequest extends ProviderExpedientSegmentRequest<BankAccount> {
}

export interface ProviderExpedientStatusRequest {
    field_id: string;
    field_value: string;
    privado: boolean;
    status?: string;
    required: boolean;
    comments?: string;
}

export interface ProviderExpedientStatusesRequest {
    fields: ProviderExpedientStatusRequest[];
    contacts: ProviderExpedientStatusRequest[];
    addresses: ProviderExpedientStatusRequest[];
    bank_accounts: ProviderExpedientStatusRequest[];
}

export interface ProviderPaymentBankAccountRequest {
    currency: string,
    template_field_bank_account_id: string
}

export interface ProviderPaymentBankAccountsRequest {
    payment_bank_accounts: ProviderPaymentBankAccountRequest[]
}

export interface GetUrlFromHistoryExpedientRequest {
    from_history: string;
    value: string;
}

export interface ExpedientBadgetCounters {
    fields: number;
    contacts: number;
    addresses: number;
    bank_accounts: number;
}

export interface ProviderExpedientPaymentBankAccount {
    id: string,
    data: ProviderExpedientBankAccount
}

export function expedientBadgets(data: ProviderExpedient): ExpedientBadgetCounters {
    let missingAddresses = countMissingItems(data.addresses);
    let missingContacts = countMissingItems(data.contacts);
    let missingBankAccounts = countMissingItems(data.bank_accounts);
    let missingFields = countMissingItems(data.fields);

    return {
        "fields": missingFields,
        "contacts": missingContacts,
        "addresses": missingAddresses,
        "bank_accounts": missingBankAccounts
    } as ExpedientBadgetCounters;
}

const countMissingItems = (data: Map<string, ProviderExpedientSegment<any, any>>): number => {
    var values = Object.values(data) as ProviderExpedientSegment<any, any>[];
    return values.filter((item) => item.status === "NOT_ACCEPTED" || item.status === "EXPIRED" || (item.configuration.required && !item.value) || (item.configuration.required && item.status === "PENDING" && item.updated_at && Object.keys(item.value).length === 0 )).length;
};