import React from "react";
import { TenantFieldCatalog, TenantAddress } from "../model/TenantFieldCatalog";
import { updateCatalogAddresses } from "../api/TenantFieldAPI";
import ExpedientSettingsSegments from "./ExpedientSettingsSegments";
import { ExpedientTemplate } from "../model/ExpedientTemplate";

interface ExpedientSettingsAddressesProps {
    expedient?: TenantFieldCatalog;
    template?: ExpedientTemplate;
    view: "templates" | "fields";
    onAdd?(): any;
    onDelete?(id: string): any;
}

export default function ExpedientSettingsAddresses(props: ExpedientSettingsAddressesProps) {
    return (
        <ExpedientSettingsSegments
            view={props.view}
            expedient={props.expedient}
            segments={props.template ? props.template.addresses_response : undefined}
            onAdd={props.onAdd}
            onDelete={props.onDelete}
            name="addresses"
            supplier={(expedient) => expedient.addresses || []}
            onUpdate={(tenantId: string, request: TenantAddress) => updateCatalogAddresses(tenantId, request)}
        />
    );
}
