import React, { useState } from "react";
import translate from "../i18n/Translator";
import PaymentOrdersList from "./PaymentOrdersList";
import { CurrencyTotal, Statuses } from "../model/PaymentOrder";
import { RouterParams } from "../router/RouterParams";
import { DataTypeProvider, DataTypeProviderProps, TableSelection } from '@devexpress/dx-react-grid';
import { Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";


export default function PaymentOrderArchivedView({ match }: RouterParams) {

    const StatusFormatter = (value: any) => {
        return <>{translate(`payment_order.status.${value.value}`) as string}</>;
    };
    
    const StatusTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
    );

    const ArrayFormatter = (value: any) => {
        let val = value.value as CurrencyTotal[];
        return <>
            {val.map(item => (
                <Typography key={item.currency} variant="body2"  component="h6" align="center" style={{ "fontSize": "0.60rem" }} >
                    <NumberFormat value={item.total} 
                        prefix="$ " suffix={` ${item.currency}`}
                        decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                        displayType="text" />
                </Typography>
            ))}

        </>
    };
    
    const ArrayTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={ArrayFormatter} {...props} />
    );

    const NoFilterEditor = ( params: DataTypeProvider.ValueEditorProps ) => { return ( <></> ); };

  const [tableColumnExtensionsP] = useState([
    { columnName: 'operation_number', width: 250, wordWrapEnabled: true },
    { columnName: 'company_name', wordWrapEnabled: true, width: 150 },
    { columnName: 'schedule_date', width: 200 },
    { columnName: 'title', width: 570, wordWrapEnabled: true },
    { columnName: 'authorized_amounts', width: 220, wordWrapEnabled: true},
    { columnName: 'menu', align: 'right', width: 50},
  ]) as any;

  const defaultExpandedGroups = Statuses;

  const numberColumns = ['operation_number'];

  const clickRowColumns = ['operation_number'];

  const dateColumns = ['schedule_date'];

  const textColumns = ['title', 'company_name'];

  const grouping = [{ columnName: 'status' }];

  const statusPlugins = [<StatusTypeProvider for={['status']}/>, <ArrayTypeProvider for={['authorized_amounts']} editorComponent={NoFilterEditor}/>];

  const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'operation_number']) as any;

  const [rightColumns] = useState(['menu']) as any;

    return (
        <PaymentOrdersList
        columns={[
          {
              name: 'operation_number',
              title: translate("payment_order.columns.no_order") as string
          },
          {
              name: 'company_name',
              title: translate('payment_order.columns.company') as string
          },
          {
            name: 'title',
            title: translate('payment_order.columns.title') as string
          },
          {
              name: 'schedule_date',
              title: translate("payment_order.schedule_date") as string
          },
          {
              name: 'status',
              title: translate("payment_order.columns.status") as string
          },
          {
              name: 'authorized_amounts',
              title: translate("cfdis.columns.amount") as string
          },
          {
              name: 'menu',
              title: " "
          }
      ]}
      defaultExpandedGroups={defaultExpandedGroups}
      numberColumns={numberColumns}
      clickRowColumns={clickRowColumns}
      dateColumns={dateColumns}
      textColumns={textColumns}
      columnsFormat={tableColumnExtensionsP}
      leftColumns={leftColumns}
      rightColumns={rightColumns}
      statusPlugins={statusPlugins}
      grouping={grouping}
      title={translate("payment.archived.title") as string}
      success={match.params.success}
    />
    );

}