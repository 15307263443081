import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import { IconButton, Divider, Button, Fab } from "@material-ui/core";
import { ExpressionsIcon, MoreVertIcon, AddIcon } from "../components/Icons";

import { listExpressions, deleteExpression } from "../api/ExpressionAPI";
import { AppContext } from "../context/AppContext";
import { Expression, Expressions, ExpressionsQueryParams } from "../model/Expression";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import Ellipsis from "../components/Ellipsis";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import ExpresionMenu from "./ExpressionMenu";
import Gridable from "../components/Gridable";
import ExpressionFormPopup from "../expressions/ExpressionFormPopup";
import ExpressionTestPopup from './ExpressionTestPopup';

export default function ExpressionsList() {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Expressions>();

    const [expression, setExpression] = useState<Expression>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [isCreateGranted] = useState(context.isGranted("ExpressionsCreate"));
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openTestModal, setOpenTestModal] = useState(false);

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as ExpressionsQueryParams;

        listExpressions(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onClickedOptions = (expression: Expression) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setExpression(expression);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onDelete = () => {
        if (!expression) return;
        setAnchorEl(null);
        setPopup("delete");
    };

    const onConfirmDelete = () => {
        onClosePopup();
        if (!expression) return;

        setSubmitting(true);
        deleteExpression(tenantId, expression.id).then(() => {
            setSuccess(
                translate("expressions.delete.success", { id: expression.id }) as string
            );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };


    const onCloseOption = () => {
        setAnchorEl(null);
        setExpression(undefined);
    };

    const onEditItem = () => {
        setOpenEditModal(true);
        setAnchorEl(null);
    };

    const onTestItem = () => {
        setOpenTestModal(true);
        setAnchorEl(null);
    };

    return (
        <Pagination title={translate("expressions.title")} icon={<ExpressionsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (
                    <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={() => {
                        setOpenEditModal(true);
                    }}>
                        <AddIcon />
                    </Fab>) : undefined}>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("expressions.empty") as string}
                columns={[
                    {
                        title: translate("expressions.name") as string,
                        converter: (expression: Expression) => (
                            <Ellipsis text={expression.name} lenght={100} uppercased={false} />
                        ),
                        xs: false,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("expressions.expression") as string,
                        converter: (expression: Expression) => (
                            <Ellipsis text={expression.expression} lenght={100} uppercased={false} />
                        ),
                        xs: true
                    },
                    {
                        title: translate("expressions.error_message") as string,
                        converter: (expression: Expression) => (
                            <Ellipsis text={expression.error_message} lenght={100} uppercased={false} />
                        ),
                        xs: true
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (expression: Expression) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(expression)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        xs: "auto"
                    }
                ]} />
            {expression && anchorEl &&
                <ExpresionMenu expression={expression} anchor={anchorEl} onDelete={onDelete} onClose={onCloseOption} onEdit={onEditItem} onTest={onTestItem} />
            }
            {expression && popup === "delete" && (
                <DialogPopup
                    open
                    title={translate("expressions.delete.title")}
                    onClose={onClosePopup}
                    closeColor="default"
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="primary"
                            disabled={submitting}
                        >
                            {translate("buttons.accept")}
                        </Button>
                    }
                >
                    {translate("expressions.delete.text", { name: expression.name })}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
            {openEditModal && <ExpressionFormPopup expressionId={expression?.id ?? ""} submit={(data) => {
                setOpenEditModal(false);
                load();
            }} onClose={() => {
                setOpenEditModal(false);
                setExpression(undefined);
            }}></ExpressionFormPopup>}

            {openTestModal && <ExpressionTestPopup onClose={() => {
                setOpenTestModal(false);
                setExpression(undefined);
            }} expression={expression!} ></ExpressionTestPopup>}

        </Pagination>
    );
}