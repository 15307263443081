import React, { useState, useEffect } from "react";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import NumberFormat from 'react-number-format';
import CreditNotesCfdiMenu from "./CreditNotesCfdiMenu";

import { IconButton, Grid } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import { cancelCfdi } from "../api/TenantCfdiApi";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ReactHtmlParser from "html-react-parser";

export interface CfdiCreditNotesParams {
    tenantId: string;
    cfdi: Cfdi;
    onCancelCreditNote(creditNoteId: string): any;
}

export default function CfdiCreditNotes(props: CfdiCreditNotesParams) {
    const [status, setStatus] = useState<string>("loading");
    const [creditNoteId, setCreditNoteId] = useState<string>();
    const [creditNoteBuildId, setCreditNoteBuildId] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const isForeign = "FOREIGN" === props.cfdi.type;

    const load = () => {
        setStatus("loaded");
    };
    useEffect(load, []);

    const onClickedOptions = (note_Id: string, serie_Folio: string, anchorEl: null | HTMLElement) => {
        setAnchorEl(anchorEl);
        setCreditNoteId(note_Id);
        setCreditNoteBuildId(serie_Folio);
    };

    const onClose = () => {
        setAnchorEl(null);
        setCreditNoteId("");
        setCreditNoteBuildId("");
    };

    const onOpenCancelCfdi = () =>{
        setAnchorEl(null);
        setOpenCancelConfirmDialog(true);
    }

    const onCancelCfdi = () =>{
        setOpenCancelConfirmDialog(false);
        if(!creditNoteId) return;
        setOpenBackdrop(true);
        cancelCfdi(props.tenantId, creditNoteId).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', {"folio": creditNoteBuildId, "cfdi_link": `/cfdis/${response.related_cfdi}/details`}) as string
                ));
            } else {
                props.onCancelCreditNote(creditNoteId);
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <Grid item xs={12}>
            <Gridable
                items={ props.cfdi.metadata.credit_notes ? props.cfdi.metadata.credit_notes : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cfdis.credit_notes.empty") as string}
                columns={[
                    {
                        title: translate("cfdis.credit_notes.issue_date") as string,
                        converter: (note) => (<DateFormat date={note.issue_date} format="DD-MM-YYYY h:mm a" zone={true}/>),
                        xs: 2,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.credit_notes.folio") as string,
                        converter: (note) => note.serie_folio,
                        xs: false,
                        sm: 1,
                        md: 1,
                        xl: 1
                    },
                    {
                        title: translate("cfdis.credit_notes.description") as string,
                        converter: (note) => note.description,
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.credit_notes.previous_balance") as string,
                        converter: (note) => (<NumberFormat value={note.previous_balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />),
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.credit_notes.amount") as string,
                        converter: (note) => (<NumberFormat value={note.amount} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />),
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.credit_notes.new_balance") as string,
                        converter: (note) => (<NumberFormat value={note.new_balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />),
                        xs: isForeign ? 1 : "auto",
                        sm: isForeign ? 1 : 2,
                        md: isForeign ? 1 : 2,
                        xl: isForeign ? 1 : 2
                    },
                    {
                        title: translate("cfdis.credit_notes.currency") as string,
                        converter: (note) => (note.currency),
                        xs: isForeign ? "auto" : false,
                        sm: isForeign ? 1 : false,
                        md: isForeign ? 1 : false,
                        xl: isForeign ? 1 : false,
                    },
                    {
                        title: "",
                        converter: (note) => (<IconButton aria-label="options" color="default" size="small"
                            onClick={(event: React.MouseEvent<HTMLElement>) => onClickedOptions(note.credit_note_id, note.serie_folio, event.currentTarget)}>
                            <MoreVertIcon />
                        </IconButton>),
                        justify: "flex-end",
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    }
                ]} />
                {anchorEl && creditNoteId && creditNoteBuildId && (
        <CreditNotesCfdiMenu 
                creditNoteId={creditNoteId} 
                serieFolio={creditNoteBuildId} 
                anchor={anchorEl}
                isForeign={"FOREIGN" === props.cfdi.type} 
                referer={"detail-credit-note"} 
                onClose={onClose} 
                onCancel={onOpenCancelCfdi} 
                onAuthorize={undefined} /> )}
        {openCancelConfirmDialog && (
            <CfdisConfirmPopup
                doAction={onCancelCfdi}
                onClose={() => setOpenCancelConfirmDialog(false)}
                title={translate("cfdis.cancel_cfdi.title_cn") as string}
                message={translate("cfdis.cancel_cfdi.message_cn") as string}
                secondary={translate("cfdis.cancel_cfdi.secondary_cn") as string}
                button={translate("buttons.accept") as string}
            />
        )}
        <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
    </Grid>
    )
}