export interface Address {
    street: string;
    interior_number?: string;
    exterior_number: string;
    suburb: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    type?: string;
}

function isNotBlank(value?: string): boolean {
    if (!value) return false;
    return value.trim().length > 0;
}

export function toString(address?: Address) {
    if (address) {
        var parts = [];
        if (isNotBlank(address.street)) parts.push(address.street);
        if (isNotBlank(address.exterior_number)) parts.push(`Ext. ${address.exterior_number}`);
        if (isNotBlank(address.interior_number)) parts.push(`Int. ${address.interior_number}`);
        if (isNotBlank(address.suburb)) parts.push(`Col. ${address.suburb}`);
        if (isNotBlank(address.city)) parts.push(address.city);
        if (isNotBlank(address.state)) parts.push(address.state);
        if (isNotBlank(address.country)) parts.push(address.country);
        if (isNotBlank(address.zip_code)) parts.push(`CP. ${address.zip_code}`);
        return parts.join(", ");
    }
    return "---";
}
