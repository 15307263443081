import React, { useState, useEffect, useContext } from "react";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import { PaymentData, CfdisSelected, Payment } from "../model/Payment";
import CreatePaymentView from "../payments/CreatePaymentView";
import { getPayment } from "../api/PaymentApi";
import Progress from "../components/Progress";

export default function PaymentOverView({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);
    const [infoProvider, setInfoProvider] = useState<PaymentData>({} as PaymentData);
    const [cfdisSelected, setCfdisSelected] = useState<CfdisSelected>({} as CfdisSelected);
    const [payment, setPaymet] = useState<Payment>();

    const load = () => {
        setStatus("loading");
        if (context.isGranted("PaymentsRead")) {
            getPayment(context.session!.tenant!.id, match.params.idPayment, 20, 0).then((response) => {
                const provider = {
                    provider_id: response?.provider?.id??"",
                    provider_name: response?.provider_name??"",
                    currency: response.currency,
                    amount: response.total_amount,
                    payment_method: response.operation_type,
                } as PaymentData;

                setInfoProvider(provider);
                setCfdisSelected({ cfdis: response.list_cfdis } as CfdisSelected);
                setPaymet(response);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }

    useEffect(load, [match.params.idPayment]);

    const returnPendingCfdiPayments = (result: Payment) => { }
    
    const onCancel = () => { }

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    if (status === "loaded") {
        return (
            <CreatePaymentView next={returnPendingCfdiPayments} 
                onCancel={onCancel} 
                paymentValues={payment}
                infoProvider={infoProvider} 
                cfdisSelected={cfdisSelected} 
                readOnly={true}
                paginateCfdis={true}
            />
        );
    }

}
