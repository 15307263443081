import React from "react";
import { Grid, Checkbox } from "@material-ui/core";
import translate from "../i18n/Translator";
import Ellipsis from "../components/Ellipsis";
import { ExportableObject } from "../model/ExportableObject";

export interface ExportableObjectListCallbacks {
    handleCheck(exportableObject: ExportableObject): any;
    handleAllChecks(checked: boolean): any;
}

interface ExportableObjectNameColumnTitleProps {
    total: number;
    selected: number;
    callbacks: ExportableObjectListCallbacks;
}

export function ExportableObjectNameColumnTitle(props: ExportableObjectNameColumnTitleProps) {

    const handleAllCheckboxs = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        props.callbacks.handleAllChecks(checked);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs="auto" >
                    <Checkbox
                        id="purchase_orders_all"
                        name="purchase_orders_all"
                        checked={props.selected > 0 && props.selected === props.total}
                        indeterminate={props.selected > 0 && props.selected < props.total}
                        onChange={handleAllCheckboxs}
                        disabled={props.total === 0}
                        className="GridableCheck"
                    />
                </Grid>
                <Grid item xs>
                    <strong>{translate("purchase_orders.external_id")}</strong>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface ExportableObjectNameColumnValueProps {
    exportableObject: ExportableObject;
    checked: boolean;
    callbacks: ExportableObjectListCallbacks;
}

export function ExportableObjectNameColumnValue(props: ExportableObjectNameColumnValueProps) {

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        props.callbacks.handleCheck(props.exportableObject);
    };

    const onClickCheck = (exportableObject: ExportableObject) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs="auto" onClick={onClickCheck(props.exportableObject)}>
                    <Checkbox
                        id={props.exportableObject.id}
                        name={props.exportableObject.id}
                        value={props.exportableObject.id}
                        checked={props.checked}
                        onChange={handleSelection}
                        className="GridableCheck"
                    />
                </Grid>
                <Grid item xs>
                    <>
                        <Ellipsis text={props.exportableObject.external_id || props.exportableObject.id} lenght={100} uppercased={false} />
                        {props.exportableObject.document_number && (
                            <>
                                <br />
                                <Ellipsis text={translate("purchase_orders.doc_number_value", { "value": props.exportableObject.document_number }) as string}
                                    lenght={100} uppercased={false} secondary />
                            </>
                        )}
                        {props.exportableObject.reference && (
                            <>
                                <br />
                                <Ellipsis text={translate("purchase_orders.reference_value", { "value": props.exportableObject.reference }) as string}
                                    lenght={100} uppercased={false} secondary />
                            </>
                        )}
                    </>
                </Grid>
            </Grid>
        </Grid>
    );
}