import React from "react"
import { useHistory, Link } from "react-router-dom";
import { Paper, Card, CardHeader, Avatar, Divider, CardContent, CardActions, IconButton } from "@material-ui/core";
import BackIcon from '@material-ui/icons/ArrowBack';

interface SurfaceProps {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    icon: React.ReactNode;
    backButton?: boolean;
    backTo?: string;
    children: React.ReactNode;
    titleActions?: React.ReactNode;
    footerActions?: React.ReactNode;
    className?: string;
    avatarColor?: string;
    onAction?(): any;
}

export default function Surface(props: SurfaceProps) {
    const history = useHistory();

    const avatar = () => {
        if (props.backButton) {
            if (props.backTo) {
                return (
                    <IconButton size="medium" className="BackButton" component={Link} to={props.backTo}>
                        <BackIcon />
                    </IconButton>
                );
            }
            return (
                <IconButton size="medium" onClick={props.onAction ? props.onAction : history.goBack} className="BackButton">
                    <BackIcon />
                </IconButton>
            );
        }
        return (
            <Avatar variant="rounded" style={{ "backgroundColor": props.avatarColor || "#1a679f" }}>
                {props.icon}
            </Avatar>
        );
    };

    return (
        <Paper component={Card} className={props.className} variant="outlined" >
            <CardHeader className="SurfaceHeader"
                avatar={avatar()}
                title={props.title}
                subheader={props.subtitle}
                action={props.titleActions} />
            <Divider />
            <CardContent>
                {props.children}
            </CardContent>
            {props.footerActions &&
                <CardActions>
                    {props.footerActions}
                </CardActions>
            }
        </Paper>
    );
}
