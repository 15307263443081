import React, { useState } from "react";
import { Grid, Button, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { recoverPassword } from "../api/AuthAPI";
import { RecoverPasswordRequest } from "../model/RecoverPassword";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import ExternSurface from "../components/ExternSurface";

export default function RecoverPasswordForm() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [succcess, setSuccess] = useState("");
    const [request, setRequest] = useState<RecoverPasswordRequest>({
        email: "",
    } as RecoverPasswordRequest);
    const [validations, setValidations] = useState({
        "email": {
            valid: false
        } as InputRef,
    } as any);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });

        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setLoading(true);
        recoverPassword(request).then((response) => {
            if (response.content) {
                setSuccess(translate("auth.recover.success", { "email": request.email }) as string);
                setRequest({} as RecoverPasswordRequest);
                for (let field in validations) {
                    let ref = validations[field];
                    ref.blurer(false);
                }
            } else {
                setError(translate("auth.recover.error") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    return (
        <ExternSurface title={translate("auth.recover.title") as string}>
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="email" id="email" name="email"
                            value={request.email} label={translate("users.email") as string}
                            required={true} disabled={loading}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12} className="ExternButton">
                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={loading}>
                            {translate("buttons.recover")}
                        </Button>
                    </Grid>
                    {/* <Grid item xs="auto">
                        <Link component={RouterLink} to="/signup">
                            <small>{translate("buttons.signup")}</small>
                        </Link>
                        </Grid> */}
                    <Grid item xs={12}>
                        <Typography align="right">
                            <Link component={RouterLink} to="/signin">
                                <small>{translate("buttons.signin")}</small>
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={succcess} onClose={onClosedSnackbar} />
        </ExternSurface>
    );

}
