import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import { getTenantFields } from "../api/TenantFieldAPI";
import { TenantSegment, TenantField, TenantFieldsRequest } from "../model/TenantFieldCatalog";
import Gridable from "../components/Gridable";
import { CheckBoxCallbacks, CheckBoxColumnTitle, CheckBoxColumnValue } from "../components/GridItemCheckBox";
import Ellipsis from "../components/Ellipsis";

interface ExpedientTemplateAddFieldsPopupProps {
    tenantId: string;
    typeField: "field" | "contact" | "address" | "bank_account";
    ids?: string[]
    onSave(fields: (TenantField | TenantSegment)[]): any;
    onClose(): any;
}

export default function ExpedientTemplateAddFieldsPopup(props: ExpedientTemplateAddFieldsPopupProps) {
    const [error, setError] = useState<string>();
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<(TenantField | TenantSegment)[]>([]);
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        setStatus("loading");
        getTenantFields(props.tenantId, { ids: props.ids, type_field: props.typeField } as TenantFieldsRequest).then((response) => {
            setData(response.segments);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
        // eslint-disable-next-line
    }, [props.typeField]);

    const onSave = () => {
        let request = data.filter(d => selected.find(sel => d.id === sel));
        props.onSave(request);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelected(items);
    };

    const handleCheck = (field: TenantField | TenantSegment) => {
        let items = selected.length === 0 ? ([] as string[]) : selected.join(",").split(",");
        const index = selected.indexOf(field.id);
        if (index < 0) {
            items.push(field.id);
        } else {
            items.splice(index, 1);
        }
        setSelected(items);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <DialogPopup open
            title={translate("expedient_template.title_checks." + props.typeField) as string}
            onClose={props.onClose}
            button={(
                <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={selected.length === 0}>
                    {translate("buttons.save")}
                </Button>
            )}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            {data.length > 0 ? translate("expedient_template.popup_message." + props.typeField) :
                                translate("expedient_template.popup_message.empty")}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            {data.length > 0 &&
                                <Gridable
                                    items={data}
                                    loading={status === "loading"}
                                    error={status !== "loading" && status !== "loaded" ? status : undefined}
                                    empty={translate("cfdis.history.empty") as string}
                                    columns={[
                                        {
                                            title: (
                                                <CheckBoxColumnTitle
                                                    label_title={translate("expedients.fields.name") as string}
                                                    total={data.length}
                                                    selected={selected.length}
                                                    callbacks={{ handleAllChecks: handleAllChecks } as CheckBoxCallbacks<TenantField | TenantSegment>}
                                                    showChecks={true} />
                                            ),
                                            converter: (field) => (
                                                <CheckBoxColumnValue
                                                    item={field}
                                                    checked={selected.indexOf(field.id) >= 0}
                                                    showChecks={true}
                                                    value={field.name}
                                                    callbacks={{ handleCheck: handleCheck } as CheckBoxCallbacks<TenantField | TenantSegment>} />
                                            ),
                                            fullWidth: true,
                                            xs: true,
                                        },
                                        {
                                            title: translate("expedients.fields.type.title") as string,
                                            converter: (field) => (<Ellipsis text={props.typeField === "field" ? translate("expedients.fields.type." + JSON.parse(JSON.stringify(field)).type) as string : ""} lenght={100} uppercased={false} />),
                                            xs: props.typeField === "field",
                                            sm: props.typeField === "field",
                                            md: props.typeField === "field",
                                            xl: props.typeField === "field"
                                        },
                                        {
                                            title: translate("cfdis.columns.description") as string,
                                            converter: (field) => (<Ellipsis text={field.description || ""} uppercased={false} />),
                                            xs: true,
                                            sm: true,
                                            md: true,
                                            xl: true
                                        },
                                    ]} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}