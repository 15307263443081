import React, { useContext, useState } from "react";
import { Menu, ListItemIcon, Fade, Grid, ListItemText, List, ListItem, Icon, MenuItem, Typography } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import SmsFailedTwoToneIcon from '@material-ui/icons/SmsFailedTwoTone';
import ExportProrateCfdiMenu from "./ExportProrateCfdiMenu";
import DownloadValidationsPdfOption from "./DownloadValidationsPdfOption";

interface CfdisMenuArchiveProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onOpenConfirmDialog(): any;
    onDownloadPaymentCfdis(): any;
    onOpenConfirmDialogPayments(): any;
    onDownloadExcel(): any;
    onDownloadExcelSelected(): any;
    onDownloadCfdisSelected(): any;
    onClarification(): any;
    onDownloadProratedExcelSelected(): void;
    onDownloadProratedExcel(): void;
    onDownloadValidationsPdf(): void;
    onDownloadValidationsPdfSelected(): void;
}

export default function CfdisMenuArchive(props: CfdisMenuArchiveProps) {
    const context = useContext(AppContext);
    const [openDownloadCfdi, setOpenDownloadCfdi] = useState<boolean>(false);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openDownloadCfdiPayments, setOpenDownloadCfdiPayments] = useState<boolean>(false);

    const handleClickDownloadCfdi = () => {
        setOpenDownloadCfdi(!openDownloadCfdi);
    };

    const handleClickExportExcel = () => {
        setOpenExportExcel(!openExportExcel);
    };

    const handleClickDownloadCfdiPayments = () => {
        setOpenDownloadCfdiPayments(!openDownloadCfdiPayments);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickDownloadCfdi} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.download_cfdi")} />
                            {openDownloadCfdi ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openDownloadCfdi} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadCfdisSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onOpenConfirmDialog} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickDownloadCfdiPayments} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-alt" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.payment_cfdi")} />
                            {openDownloadCfdiPayments ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openDownloadCfdiPayments} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadPaymentCfdis} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected_cfdi_payments")} />
                                </ListItem>
                                <ListItem button onClick={props.onOpenConfirmDialogPayments} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.all_cfdi_payments_this_view")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.export_excel")} />
                            {openExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportExcel} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadExcelSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadExcel} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
            {context.isGrantedAny(["CfdiProratedReport"]) &&
                <ExportProrateCfdiMenu onDownloadProratedExcel={props.onDownloadProratedExcel} onDownloadProratedExcelSelected={props.onDownloadProratedExcelSelected}></ExportProrateCfdiMenu>
            }
            {context.isGrantedAny(["ProviderCFDIsRead"]) &&
                <MenuItem button onClick={props.onClarification} dense>
                    <ListItemIcon>
                        <SmsFailedTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.clarification")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsRead", "ProviderCFDIsRead", "ReceptorCFDIsRead",
            "SenderCFDIsRead", "CxpCFDIsRead", "CfdisIssuedRead", "TenantCfdisIssuedRead", "ProviderCfdisIssuedRead"]) &&
                <DownloadValidationsPdfOption
                    onDownload={props.onDownloadValidationsPdf}
                    onDownloadSelected={props.onDownloadValidationsPdfSelected}
                />
            }
        </Menu>
    );
}