import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { clearElementContact, updateContacts } from "../api/ProviderExpedientAPI";
import { Contact } from "../model/Contact";
import { Entity } from "../model/Provider";
import { ProviderExpedientContact, ProviderExpedient, ProviderExpedientContactRequest } from "../model/ProviderExpedient";
import ExpedientProviderContactForm from "./ExpedientProviderContactForm";
import ExpedientProviderSegments from "./ExpedientProviderSegments";
import { ProviderContact } from "../providers/ProviderContactCard";
import DialogPopup from "../components/DialogPopup";

interface ExpedientProviderContactsProps {
    metadata: ProviderExpedient;
    onChange(metadata: ProviderExpedient): any;
    disabled: boolean;
    provider?: Entity;
}

export default function ExpedientProviderContacts(props: ExpedientProviderContactsProps) {
    const contact_p1 = "";
    const contact_p2 = "";
    return (
        <ExpedientProviderSegments
            disabledEdition={props.disabled}
            metadata={props.metadata}
            name="contacts"
            provider={props.provider}
            supplier={(metadata) => Object.values(metadata.contacts) as ProviderExpedientContact[]}
            onUpdated={(tenantId: string, providerId: string, current: ProviderExpedientContact, value: Contact) => {
                let request = {
                    id: current.configuration.id,
                    value: value,
                } as ProviderExpedientContactRequest;
                return updateContacts(tenantId, providerId, request);
            }}
            clear={(tenantId: string, providerId: string, contactId: string)=>{
                return clearElementContact(tenantId, providerId, contactId);
            }}
            onChange={props.onChange}
            buildForm={(current: ProviderExpedientContact, onClose: () => any, onSubmitted: (value: Contact) => any) => (
                <ExpedientProviderContactForm
                    title={current.configuration.name}
                    subtitle={current.configuration.description}
                    contact={current.value}
                    comments={current.comments}
                    onClose={onClose}
                    onCompleted={onSubmitted}
                    disabled={props.disabled}
                />
            )}
            buildPopup={(current: ProviderExpedientContact, onClose: () => any) => (
                <DialogPopup title={current.configuration.name} open onClose={onClose}>
                    <ProviderContact configuration={current.configuration} contact={current.value} />
                </DialogPopup>
            )}
            buildData={(current: ProviderExpedientContact) => (
                 current.value ? 
                <Grid>
                    <Typography variant="body2">{
                        contact_p1.concat(
                            current.value.first_name ? current.value.first_name : "", " ", 
                            current.value.last_name ? current.value.last_name : "")
                        }
                    </Typography>
                    <Typography variant="caption">{
                        contact_p2.concat(
                            current.value.email ? current.value.email : "", " - ",
                            current.value.phone ? current.value.phone : "")
                        }
                    </Typography>
                </Grid>
                : undefined                
            )}
        />
    );
}