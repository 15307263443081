import { callAPI, HTTPMethod } from "./API";
import { AuxiliarDocuments, AuxiliarDocument, AuxiliarDocumentsQueryParams, AuxiliarDocumentRequest } from "../model/AuxiliarDocument";

const basePath = (tenantId: string) => `/tenants/${tenantId}/auxiliar-documents`;

export async function listAuxiliarDocuments(tenantId: string, page: number, offset: number, params: AuxiliarDocumentsQueryParams): Promise<AuxiliarDocuments> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            roleId: params.role_id || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createAuxiliarDocument(tenantId: string, request: AuxiliarDocumentRequest): Promise<AuxiliarDocument> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getAuxiliarDocument(tenantId: string, id: string): Promise<AuxiliarDocument> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateAuxiliarDocument(tenantId: string, id: string, request: AuxiliarDocumentRequest): Promise<AuxiliarDocument> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteAuxiliarDocument(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
