import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { PaymentOrderFile, ExecutePaymentFileRequest, ExportPaymentOrderFileResponse } from "../model/PaymentOrder";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CancelIcon from '@material-ui/icons/Cancel';
import { exportPaymentFile } from "../api/PaymentOrderApi";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import ExecutePaymentFilePopUp from "../payment_order/ExecutePaymentFilePopUp";
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

interface PaymentOrderFilesMenuProps {
    paymentOrderFile: PaymentOrderFile;
    paymentOrderId: string;
    onCancelPaymentFile(paymentOrderFileId: string): any;
    onExecute(field: ExecutePaymentFileRequest): any;
}

export default function PaymentOrderFilesMenu(props: PaymentOrderFilesMenuProps) {
    const context = useContext(AppContext);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openCancelPopUp, setCancelPopUp] = useState<boolean>(false);
    const [exportResult, setExportResult] = useState<ExportPaymentOrderFileResponse>();
    const [showFieldForm, setShowFieldForm] = useState<boolean>(false);
    const [fileUrl, setfileUrl] = useState<string>("");

    useEffect(() => {
        exportPaymentFile(context.session!.tenant!.id, props.paymentOrderId, props.paymentOrderFile.id).then((urlFile) => {
            setOpenBackdrop(false);
            setfileUrl(urlFile);
        }).catch((error) => {
            setfileUrl(error.message);
        });
    }, [context.session, props.paymentOrderFile.id, props.paymentOrderId]);

    const onCancel = () => {
        if (!props.paymentOrderFile) return;
        setCancelPopUp(false);
        props.onCancelPaymentFile(props.paymentOrderFile.id);
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const cancelPopUp = () => {
        setOpenBackdrop(true);
        setCancelPopUp(true);
    }

    const onClose = () => {
        setOpenBackdrop(false);
        setCancelPopUp(false);
        setShowFieldForm(false);
    }

    const onExecute = (field: ExecutePaymentFileRequest) => {
        if (!props.paymentOrderFile) return;
        setShowFieldForm(false);
        props.onExecute(field);
    }

    const onExecuteOn = () => {
        setShowFieldForm(true);
    }

    return (
        <Grid container justify="flex-end" direction="row" spacing={2}>
            {context.isGrantedAny(["PaymentOrdersTreasurerCreate"]) && fileUrl.startsWith("http") && props.paymentOrderFile.status !== "CANCELED" &&
                <Grid item xs="auto">
                    <IconButton aria-label="options" color="default" size="small" disabled={!fileUrl} onClick={() => window.open(fileUrl, "_blank")}>
                        <DownloadIcon fontSize="small" color="primary" />
                    </IconButton>
                </Grid>
            }
            {context.isGrantedAny(["PaymentOrdersTreasurerCreate"]) && props.paymentOrderFile.status !== "EXECUTED"
                && props.paymentOrderFile.status !== "CANCELED" &&
                <Grid item xs="auto">
                    <Tooltip title={translate("payment_order.messages.execute") as string}>
                        <IconButton color="default" size="small" onClick={onExecuteOn}>
                            <PlayCircleFilledIcon fontSize="small" color="primary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            {context.isGrantedAny(["PaymentOrdersTreasurerCreate"]) && props.paymentOrderFile.status !== "EXECUTED"
                && props.paymentOrderFile.status !== "CANCELED" &&
                <Grid item xs="auto">
                    <IconButton color="default" size="small" onClick={cancelPopUp}>
                        <CancelIcon fontSize="small" color="primary" />
                    </IconButton>
                </Grid>
            }
            {openBackdrop && openCancelPopUp &&
                <ConfirmationPopup
                    doAction={onCancel}
                    title={translate("payment_order.messages.cancel_payment_file") as string}
                    message={translate("payment_order.messages.message_cancel") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onClose} />
            }
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description_archive") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {showFieldForm && (
                <ExecutePaymentFilePopUp
                    payment_file={props.paymentOrderFile}
                    onCompleted={onExecute}
                    onClose={onClose}
                />
            )}
        </Grid>);
}
