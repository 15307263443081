import { callAPI, HTTPMethod } from "./API";
import { FocaltecAnnouncements, FocaltecAnnouncement, FocaltecAnnouncementsQueryParams, FocaltecAnnouncementRequest, FocaltecAnnouncementUpdateStatusRequest } from "../model/FocaltecAnnouncement";

const basePath = () => "/focaltec-announcements";

export async function listFocaltecAnnouncements(page: number, offset: number, params: FocaltecAnnouncementsQueryParams): Promise<FocaltecAnnouncements> {
    return await callAPI(`${basePath()}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createFocaltecAnnouncement(request: FocaltecAnnouncementRequest): Promise<FocaltecAnnouncement> {
    return await callAPI(`${basePath()}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getFocaltecAnnouncement(id: string): Promise<FocaltecAnnouncement> {
    return await callAPI(`${basePath()}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateFocaltecAnnouncement(id: string, request: FocaltecAnnouncementRequest): Promise<FocaltecAnnouncement> {
    return await callAPI(`${basePath()}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteFocaltecAnnouncement(id: string): Promise<any> {
    return await callAPI(`${basePath()}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function updateStatusFocaltecAnnouncement(id: string, request: FocaltecAnnouncementUpdateStatusRequest): Promise<FocaltecAnnouncement> {
    return await callAPI(`${basePath()}/${id}/update-status`, {
        method: HTTPMethod.PUT,
        body: request
    });
}
