import { Action } from "./Action";
import { Tenant } from "./Tenant";

export interface Role {
    id: string;
    name: string;
    editable: boolean;
    platform: boolean;
    provider: boolean;
    customer: boolean;
    created_at: Date;
    description?: string;
    role_parent_id?: string;
}

export interface Roles {
    items: Role[];
    total: number;
}

export interface RolesQueryParams {
    search: string;
    roleId: string;
    tenantId: string;
}

export interface RoleAdminRequest {
    actions: string[],
    actions_excluded: string[];
}

export interface RoleAdminOverview {
    name: string,
    public_id: string,
    actions: RoleActionExclusion[],
    actionsExcluded: RoleActionExclusion[];
}

export interface RolesActionExclusion {
    role: Role;
    tenant: Tenant;
    count_actions_from_role: number;
    action_exclusions: RoleActionExclusion[];
    actions: RoleActionExclusion[];
}

export interface RoleActionExclusion {
    action: Action;
    public_id: string;
    domain: string;
}

export function isRoleOrParent(role: Role, roleId: string) {
    if (role.role_parent_id) {
        return roleId === role.role_parent_id;
    }
    return role.id === roleId;
}

export const ROLES_WITHOUT_DASHBOARD = ["pdc_supervisor", "pdc_payroll_admin", "pdc_tax_consultant", "pdc_cxc", "pdc_treasurer", "pdc_cxc_accounting", "pdc_customer"];

export const ROLES_FOR_TEAMS = ["receptor_cfdi", "cxp", "project_manager", "project_participant", "owner", "supervisor", "treasurer", "validator"];

export function hasDashboard(roleId?: string) {
    if (!roleId) return false;

    return ROLES_WITHOUT_DASHBOARD.indexOf(roleId) === -1;
}