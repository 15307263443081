import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import translate from "../i18n/Translator";
import { CatalogValue } from "../model/Catalog";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import { AutocompleteProvider } from "../model/Provider";
import { Autocomplete } from "@material-ui/lab";

interface CatalogValueFormProps {
    value?: CatalogValue;
    providers: AutocompleteProvider[];
    onUpdated(value: CatalogValue): any;
    onCancel(): any;
}

export default function CatalogValueForm(props: CatalogValueFormProps) {
    const margin = "dense";
    const [defaultValue, setDefaultValue] = useState<AutocompleteProvider>();
    const [request, setRequest] = useState<CatalogValue>(() => {
        return props.value ?? {
            name: '',
            external_id: '',
            provider_id: '',
            active: true
        } as CatalogValue;
    });
    const [validations, setValidations] = useState({} as any);

    useEffect(() => {
        setDefaultValue(props.providers.find(el => el.value === props.value?.provider_id));
    }, [props.value, props.providers])

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        props.onUpdated(request);
    };

    return (
        <DialogPopup
            open
            title={translate("catalogs.values.title")}
            onClose={props.onCancel}
            closeColor="default"
            disableEscapeKeyDown
            disableBackdropClick
            closeText={translate("buttons.cancel") as string}
            onSubmit={onSubmit}
            button={
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {translate("buttons.update")}
                </Button>
            }
            buttonLeft={
                <Button
                    variant="outlined"
                    color="secondary"
                    style={{ visibility: "hidden" }}
                >
                    {translate("buttons.update")}
                </Button>
            }
        >
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="name" name="name"
                        value={request.name}
                        label={translate("catalogs.values.name") as string}
                        required
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="external_id" name="external_id"
                        value={request.external_id}
                        label={translate("catalogs.values.external_id") as string}
                        required
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <Box mt={1}>
                        <Autocomplete
                            size="small"
                            options={props.providers}
                            defaultValue={defaultValue}
                            getOptionLabel={(elemento) => elemento.title}
                            noOptionsText={translate("payments_cfdi.no_options_autocomplete") as string}
                            renderInput={(params) => (
                                <TextField {...params}
                                    variant="outlined"
                                    label={translate("app.provider") as string}
                                    fullWidth />
                            )}
                            onChange={(event, newValue) => {
                                setRequest({ ...request, provider_id: newValue?.value ?? "", provider_name: newValue?.title });
                            }}
                            getOptionSelected={(opt, val) => opt.value === val.value}
                        />
                    </Box>
                </Grid>
            </Grid>
        </DialogPopup>
    );
}