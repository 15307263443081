import { callAPI, HTTPMethod } from "./API";
import { TeamListParams, TeamsResponse as Teams, TeamRequest, Team } from "../model/Team";

export async function list(tenantId: string, page: number, offset: number, params: TeamListParams): Promise<Teams> {
    let query = {
        search: params.search || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;
    
    if(params.ids){
        query = {...query,
            ids: params.ids,
        };
    }

    return await callAPI(`/tenants/${tenantId}/teams`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function inactivate(tenantId: string, teamId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/teams/${teamId}/inactivate`, {
        method: HTTPMethod.PUT,
    });
}

export async function createTeam(tenantId: string, request: TeamRequest): Promise<Team> {
    return await callAPI(`/tenants/${tenantId}/teams`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getTeam(tenantId: string, teamId: string): Promise<Team> {
    return await callAPI(`/tenants/${tenantId}/teams/${teamId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateTeam(tenantId: string, teamId: string, request: TeamRequest): Promise<Team> {
    return await callAPI(`/tenants/${tenantId}/teams/${teamId}/update`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}