import React, { useState } from "react";
import { Menu, ListItemIcon, Fade, Grid, ListItem, ListItemText, List } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";

import translate from "../i18n/Translator";
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

interface DigitalDocumentsMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDownloadSelected(): any;
}

export default function DigitalDocumentsMenu(props: DigitalDocumentsMenuProps) {
    const [openDownload, setOpenDownload] = useState<boolean>(false);

    const handleClickDownloadCfdi = () => {
        setOpenDownload(!openDownload);
    };

    return (
        <Menu
            id="digital-documents-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickDownloadCfdi} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("digital_documents.download_selected")} />
                            {openDownload ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openDownload} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
        </Menu>
    );

}