import React from "react";
import { Grid, ListItemText, Typography, Divider, Box } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { Budget } from "../model/Budget";
import translate from "../i18n/Translator";

interface BudgetPerformanceAmountsProps {
    budget: Budget;
}

export default function BudgetPerformanceAmounts(props: BudgetPerformanceAmountsProps) {

    return (
        <Grid item xs={12} >
            <Grid container alignItems="center" justifyContent="space-evenly" direction="row" spacing={0}>
                <DataAmount
                    amount={props.budget!.init_amount || 0}
                    title={translate("budget.performance.inicial_amount") as string}
                    color="red"
                    shape="line_vertical"
                    icon={true} />
                <Divider orientation="vertical" flexItem variant="middle" />
                <DataAmount
                    amount={props.budget!.current_amount || 0}
                    title={translate("budget.performance.current_amount") as string}
                    color="blue"
                    shape="line_vertical"
                    icon={true} />
                <Divider orientation="vertical" flexItem variant="middle" />
                <DataAmount
                    amount={props.budget!.accrued_amount || 0}
                    title={translate("budget.performance.accrued_amount") as string}
                    color="#F69450"
                    shape="circle"
                    icon={true} />
                <Divider orientation="vertical" flexItem variant="middle" />
                <DataAmount
                    amount={props.budget!.pending_accrue_amount || (props.budget!.current_amount - props.budget!.accrued_amount)}
                    title={translate("budget.performance.to_accrued_amount") as string}
                    color="#FDE4D3"
                    shape="circle"
                    icon={true} />
                <Divider orientation="vertical" flexItem variant="middle" />
                <DataAmount
                    amount={props.budget!.exercised_amount || 0}
                    title={translate("budget.performance.exercised_amount") as string}
                    color="blue"
                    shape="circle"
                    icon={true} />
                <Divider orientation="vertical" flexItem variant="middle" />
                <DataAmount
                    amount={(props.budget!.current_amount - props.budget!.exercised_amount) || 0}
                    title={translate("budget.performance.to_exercised_amount") as string}
                    color="blue"
                    icon={false} />
            </Grid>
        </Grid>
    );
}

interface DataAmountProps {
    amount: number;
    title: string;
    color: string;
    shape?: string;
    icon: boolean;
}

export function DataAmount(props: DataAmountProps) {

    return (
        <Grid item xs={1}>
            <ListItemText
                primary={
                    <NumberFormat color={props.color} value={props.amount}
                        prefix="$" thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                }
                secondary={
                    <Grid container direction="row" alignItems="center" wrap="nowrap">
                        { props.icon && 
                            <Box pr={0.1}>
                                <Typography className={props.shape} style={{ backgroundColor: props.color }} />
                            </Box>
                        }
                        <Grid item xs={1}>
                            <Typography variant="caption" component="h6" style={{ marginLeft: "10px", whiteSpace: "nowrap" }}>{props.title}</Typography>
                        </Grid>
                    </Grid>
                } />
        </Grid>
    );
}