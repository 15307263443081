import React, { useContext, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';
import GetAppIcon from '@material-ui/icons/GetAppTwoTone';
import { Payment } from "../model/Payment";
import RejectIcon from '@material-ui/icons/ThumbDownTwoTone';
import CancelIcon from '@material-ui/icons/CancelTwoTone';

interface PaymentArchivedCfdiMenuProps {
    payment: Payment;
    anchor: HTMLElement | null;
    referer: string;
    onClose(): any;
    onDownload(): any;
    onDelete(): any;
    onCancelPaymentCfdi(): any;
    enableRejectPaymentOption? : boolean;
}

export default function PaymentArchivedCfdiMenu(props: PaymentArchivedCfdiMenuProps) {
    const context = useContext(AppContext);

    useEffect(() => {

    }, [context.session, props.payment.id]);

    return (
        <Menu
            id="cfdi-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["PaymentsRead"]) &&
                <MenuItem button component={Link} to={`/payment-details/${props.payment.id}/`} dense>
                    <ListItemIcon>
                        <DetailsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment.archived.show_details")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["PaymentsRead"]) && props.payment.status.endsWith("PAYMENT_CFDI_ATTACHED") &&
                <MenuItem button onClick={props.onDownload} dense>
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment.archived.download")}</Typography>
                </MenuItem>
            }

            {context.isGrantedAny(["CancelPaymentCfdi"]) && props.payment.status === "PAYMENT_CFDI_ATTACHED" &&
                <MenuItem button onClick={props.onCancelPaymentCfdi} dense>
                    <ListItemIcon>
                        <CancelIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit" >{translate("payment.archived.cancel_payment_cfdi")}</Typography>
                </MenuItem>
            }

            {context.isGrantedAny(["PaymentsDelete"]) && props.payment.status !== "INVALID" && props.payment.status !== "PAYMENT_CFDI_ATTACHED" && props.enableRejectPaymentOption &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <RejectIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit" >{translate("payment.add_comment.title_reject")}</Typography>
                </MenuItem>
            }

        </Menu>);
}
