import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { ErrorSnackbar } from "../components/Snackbars";
import { AppContext, MyWindow } from "../context/AppContext";
import { Integration } from "../model/Integration";
import Progress from "../components/Progress";
import translate from "../i18n/Translator";
import { Redirect } from "react-router-dom";
import { getCuratedIntegration } from "../api/IntegrationAPI";

function IntegrationContainer() {
    const context = useContext(AppContext);
    const [error, setError] = useState("");
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

    const [status, setStatus] = useState<string>('loading');

    var IntegrationApp: any = undefined;
    var myWidget: any = undefined;
  
    useEffect(() => {
        const integrationId = context.session?.integration!.id!;
        const element = document.getElementById(integrationId);
        if(element === null) {
            getCuratedIntegration(context.session!.tenant!.id).then(response => {
                loadScript(response);
            }).catch((error) => {
                setError(error.message);
            });
        }
    
        return () => {
            if(myWidget !== undefined) {
                myWidget.unmount();
                // eslint-disable-next-line
                IntegrationApp = undefined;
                dropScript();
            }
        }
    }, []);

    function loadScript(integration: Integration) {
        var scriptElement = document.createElement('script');
        scriptElement.src = `${integration.js_url}`;
        scriptElement.id = `${integration.id}`;
        scriptElement.addEventListener('load', () => {
            IntegrationApp = (window as MyWindow & typeof globalThis).IntegrationApp;
            if(IntegrationApp) {
                // El new recibe un any por lo que se puede mandar cualquier cosa en el objeto, 
                // lo único que si se debe de mandar siempre es el selector ya que sobre éste
                // se montará el componente
                myWidget = IntegrationApp.default.widget.new({ 
                    selector: "#integration-app", 
                    integrationId: integration.id, 
                    tenantId: context.session?.tenant?.id,
                    providerId: context.session?.provider?.id,
                    userId: context.session?.user.id,
                    userLanguage: context.session?.user.language,
                    actions: context.session?.actions,
                    onClick: (path: string) => {
                        setRedirectUrl(path)
                    }
                }); 
                myWidget.render();
                setStatus('loaded');
            } else {
                setStatus(translate("integrations.module_not_attached") as string);
            }
        });
        scriptElement.addEventListener('error', (error) => {
            console.log(error)
            setStatus(translate("integrations.error_loading_script") as string);
        });
        document.body.appendChild(scriptElement);
    }

    function dropScript() {
        const element = document.getElementById(context.session?.integration!.id!);
        if(element != null && element.parentNode != null) {
            element.parentNode.removeChild(element);
        }
    }

    if (!!redirectUrl) {
        return(
            <Redirect to={redirectUrl} />
        );
    }

    const onClosedSnackbar = () => {
        setError("");
    };

    return (
        <>
            <div id='integration-app'></div>
            {status === 'loading' &&
                <Progress />
            }
            {status !== 'loading' && status !== 'loaded' && 
                <Typography variant="body1" component="h5" color="error" align="center">
                    {status}
                </Typography>
            }
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </>
    );
}

export default IntegrationContainer