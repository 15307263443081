import { Address } from "./Address";
import { Entity as Provider } from "./Provider";
import { DocumentLine } from "./Document";
import { ExportableObject } from "./ExportableObject";
import { Metadata } from "./PurchaseOrder";


export interface WarehouseDelivery extends ExportableObject {
    status: string;
    date: Date;
    due_date: Date;
    comments: string;
    currency: string;
    exchange_rate: number;
    subtotal: number;
    total: number;
    lines: DocumentLine[];
    provider?: Provider;
    addresses?: Address[];
    metadata: Metadata[];
    vat_sum: number;
    withhold_tax_sum: number;
}

export interface WarehouseDeliveries {
    items: WarehouseDelivery[];
    total: number;
}

export interface WarehouseDeliveriesQueryParams {
    search: string;
    status: string;
}

export interface WarehouseDeliveryRequest {
    extern_id: string;
    status: string;
    date: Date;
    due_date: Date;
    reference?: string;
    comments?: string;
    currency: string;
    exchange_rate: number;
    total: number;
}

export const STATUSES = ['O', 'C', 'R', 'G'];

