import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";

import { BlockIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";

interface UserMenuProps {
    cfdi: Cfdi;
    anchor: HTMLElement | null;
    onCancel(): any;
    onClose(): any;
}

export default function UserMenu(props: UserMenuProps) {
    const context = useContext(AppContext);
    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {!context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "ProviderCFDIsUpdate", "CxpCFDIsUpdate"]) || 
                (props.cfdi.metadata.status === "CANCELLED") ? undefined :
                    <MenuItem button onClick={props.onCancel} dense>
                        <ListItemIcon>
                            <BlockIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.cancel_cfdi.title")}</Typography>
                    </MenuItem>
                }
        </Menu >
    );

}