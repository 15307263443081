import React, { useContext } from "react";
import { List, ListItemText, ListItem, ListItemIcon, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Filter1Icon from '@material-ui/icons/Filter1TwoTone';
import Filter2Icon from '@material-ui/icons/Filter2TwoTone';
import Filter3Icon from '@material-ui/icons/Filter3TwoTone';
import Filter4Icon from '@material-ui/icons/Filter4TwoTone';
import Filter5Icon from '@material-ui/icons/Filter5TwoTone';
import Filter6Icon from '@material-ui/icons/Filter6TwoTone';
import Filter7Icon from '@material-ui/icons/Filter7TwoTone';
import Filter8Icon from '@material-ui/icons/Filter8TwoTone';
import Filter9Icon from '@material-ui/icons/Filter9TwoTone';
import GetAppIcon from '@material-ui/icons/GetAppTwoTone';
import AddIcon from '@material-ui/icons/AddTwoTone';
import ChevronRightIcon from '@material-ui/icons/ChevronRightTwoTone';

import translate from "../i18n/Translator";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const icons = [
    <Filter1Icon color="inherit" />,
    <Filter2Icon color="inherit" />,
    <Filter3Icon color="inherit" />,
    <Filter4Icon color="inherit" />,
    <Filter5Icon color="inherit" />,
    <Filter6Icon color="inherit" />,
    <Filter7Icon color="inherit" />,
    <Filter8Icon color="inherit" />,
    <Filter9Icon color="inherit" />
];

interface ExposedGuideProps {
    hideTitle?: boolean;
    hideButtons?: boolean;
}

export function GuideProviders(props: ExposedGuideProps) {
    const context = useContext(AppContext);

    return (
        <Guide category="providers" {...props}
            icon={<PlaylistAddCheckIcon />}
            steps={[
                {
                    button: props.hideButtons ? undefined : (
                        <IconButton color="secondary" component="a" href="/plantilla-importacion-proveedores.zip" target="_blank" rel="noopener noreferrer">
                            <GetAppIcon />
                        </IconButton>
                    )
                },
                {
                    button: !props.hideButtons && context.isGranted("ProvidersCreate") ? (
                        <Link to="/providers">
                            <IconButton color="secondary">
                                <ChevronRightIcon />
                            </IconButton>
                        </Link>
                    ) : undefined
                },
                {
                    button: !props.hideButtons && context.isGranted("ProvidersCreate") ? (
                        <Link to="/providers/new">
                            <IconButton color="secondary">
                                <AddIcon />
                            </IconButton>
                        </Link>
                    ) : undefined
                }
            ]}
        />
    );
}

export function GuideEfos(props: ExposedGuideProps) {
    return (
        <Guide category="efos" {...props}
            icon={<PlaylistAddCheckIcon />}
            steps={[{}, {}, {}, {}]}
        />
    );
}

export function GuideAssignProviders(props: ExposedGuideProps) {
    return (
        <Guide category="assign_providers" {...props}
            icon={<PlaylistAddCheckIcon />}
            steps={[{}, {}]}
        />
    );
}

interface GuideStep {
    button?: React.ReactNode;
}

interface GuideProps {
    category: string;
    steps: GuideStep[];
    icon: React.ReactElement;
    hideTitle?: boolean;
}

function Guide(props: GuideProps) {
    return (
        <List>
            {props.hideTitle ? undefined : (
                <ListItem>
                    <ListItemText primary={translate(`tutorial.${props.category}.title`) as string} secondary={translate(`tutorial.${props.category}.subtitle`) as string} />
                </ListItem>
            )}
            {props.steps.map((value: GuideStep, index: number) => (
                <GuideItem key={index}
                    primary={translate(`tutorial.${props.category}.${index + 1}.title`) as string}
                    secondary={translate(`tutorial.${props.category}.${index + 1}.subtitle`) as string}
                    icon={icons[index]}
                    button={value.button} />
            ))}
        </List>
    );
}

interface GuideItemProps {
    primary: string;
    secondary: string;
    icon: React.ReactElement;
    button?: React.ReactNode;
}

function GuideItem(props: GuideItemProps) {
    return (
        <ListItem>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.primary} secondary={props.secondary} />
            {props.button && (
                <ListItemSecondaryAction>
                    {props.button}
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}