import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

interface AutocompleteGroupedProps {
    items: any[];
    propertyGroup: string;
    label: string;
    value?: any;
    titleProperty: string;
    onChange(selected: string): any;
}

export default function AutocompleteGrouped(props: AutocompleteGroupedProps) {
    const options = props.items.map((option) => {
        const firstLetter = option[props.propertyGroup].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
    
      return (
        <Autocomplete
          id="grouped-items"
          size="small"
          options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option[props.titleProperty]}
          style={{ height: 30 }}
          value={props.value || undefined}
          renderInput={(params) => <TextField {...params} label={props.label} variant="outlined"/>}
          onChange={(event, newValue) => {
            if (newValue) {
                props.onChange(newValue.id);
            } else {
                props.onChange("");
            }
        }}
        />
      );
}