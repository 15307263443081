import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Card, CardHeader, CardContent, Divider, Button } from "@material-ui/core";
import translate from "../i18n/Translator";
import { listPlans } from "../api/PlanTaxStampAPI";
import { PlansTaxStamp } from "../model/PlanTaxStamp";
import Progress from "../components/Progress";
import ReactHtmlParser from "html-react-parser";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";

export default function PlansProvider() {
    const [status, setStatus] = useState<string>("loading");
    const [plans, setPlans] = useState<PlansTaxStamp>();
    const [redirect, setRedirect] = useState<string>();

    const load = () => {
        listPlans().then(response => {
            setPlans(response);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    const selectPlan = (externalId: string) => {
        setRedirect(`/plan-tax-stamp/${externalId}/buy-plan`);
    };

    useEffect(load, []);

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }


    if (status === "loading") {
        return (
            <Progress />
        );
    }

    if (status !== "loaded") {
        return (
            <div>{status}</div>
        );
    }

    return (<>
    <Grid container spacing={4}>
            <Grid item xs={12}>
                <Grid container alignContent="center" alignItems="center" justifyContent="center">
                    <Grid item md={3} sm={false} xl={4}>
                    </Grid>
                    <Grid item md={6} sm={12} xl={4}>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="primary" style={{fontSize: "45px"}}><b>{translate("plan_tax_stamp.plans.title") as string}</b></Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="secondary" style={{fontSize: "50px"}}><b>{translate("plan_tax_stamp.plans.title_clic") as string}</b></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={false} xl={4}>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}> 
                <Grid container alignContent="center" alignItems="center" justifyContent="center">
                    <Grid item>
                        {translate("plan_tax_stamp.plans.text") as string}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}> 
                <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
                    {(plans?.items || [] ).map(plan => (
                        <Grid item md={4} sm={6} xs={12}>
                                <Card variant="outlined">
                                    <CardHeader subheader={
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Box pt={1}><strong>{plan.name}</strong></Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                                                <Grid item>
                                                    <Typography color="primary" style={{fontSize: "20px"}}><b>
                                                        <NumberFormat value={plan.price}
                                                            prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                            thousandSeparator="," displayType="text"
                                                        />
                                                    </b></Typography>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                } disableTypography />
                                    <Divider/>
                                    <CardContent>
                                        {ReactHtmlParser(plan.description)}
                                        <Box pb={2}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Grid container alignContent="center" justifyContent="center" alignItems="center">
                                                        <Grid item>
                                                        <Button onClick={() => selectPlan(plan.external_id)} variant="contained" color="primary" size="medium">
                                                            {translate("buttons.select")}
                                                        </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                    ))}
                </Grid>
            </Grid>
    </Grid>
    </>);
}