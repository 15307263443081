import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { listAnnouncements, deleteAnnouncement, changeStatusAnnouncement } from "../api/AnnouncementAPI";
import { Announcement, AnnouncementQueryParams, Announcements, STATUSES, ChangeStatusRequest } from "../model/Announcement";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString } from "../components/DateFormat";

import { FormControl, Select, Box, Grid, Fab } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";

import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from '@material-ui/icons/Notifications';
import AnnouncementCreateModal from "./AnnouncementCreateModal";
import AnnouncementMenu from "./AnnouncementMenu";
import ShowAnnouncementModal from "./ShowAnnouncementModal";
import ConfirmationPopup from "../components/ConfirmationPopup";
import AnnuncementSelectOptionPopUp from "./AnnuncementSelectOptionPopUp";
import { isRoleOrParent } from "../model/Role";

export default function ProviderAnnouncementsView() {
  const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): AnnouncementQueryParams => {
        return {
            "start_date": typeof qs["start_date"] === "string" ? qs["start_date"] as string : "",
            "end_date": typeof qs["end_date"] === "string" ? qs["end_date"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "title": typeof qs["title"] === "string" ? qs["title"] as string : "",
            "readed": typeof qs["readed"] === "string" ? qs["readed"] : undefined,
        } as AnnouncementQueryParams;
    };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Announcements>();
    const [params, setParams] = useState<AnnouncementQueryParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [openOptionModal, setOpenOptionModal] = useState<boolean>(false);
    const [openViewModal, setOpenViewModal] = useState<boolean>(false);
    const [openConfirmEdit, setOpenConfirmEdit] = useState<boolean>(false);
    const [openConfirmFinish, setOpenConfirmFinish] = useState<boolean>(false);
    const [announcement, setAnnouncement] = useState<Announcement>();
    const [optionSelected, setOptionSelected] = useState<"providers"|"all"|"class">();

    const isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");

    const paramsToFilters = (): Filter[] => {
        return [
            { columnName: 'start_date', value: params.start_date },
            { columnName: 'end_date', value: params.end_date },
            { columnName: 'status', value: params.status },
            { columnName: 'title', value: params.title },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
      { columnName: 'start_date', wordWrapEnabled: true },
      { columnName: 'title'},
      { columnName: 'readed', width: isProvider ? true : 0 },
      { columnName: 'providers', wordWrapEnabled: true, width: isProvider ? 0 : true },
      { columnName: 'end_date', wordWrapEnabled: true, width: isProvider ? 0 : true },
      { columnName: 'status', wordWrapEnabled: true },
      { columnName: 'menu', width: isProvider ? 0 : 50 },
    ]) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();
        let user = isProvider ? context.session!.user.id : "";
        listAnnouncements(context.session!.tenant!.id, user, pageSize, pageSize * page, params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if(filter.value !== undefined){
                switch(filter.columnName){
                    case "start_date": 
                        let datesS = filter.value.split(" - ");
                        if(datesS.length === 2) {
                            temp.start_date = formatDateString(datesS[0])+" - "+formatDateString(datesS[1]);
                        } else {
                            temp.start_date = "";
                        }
                        break;
                    case "end_date": 
                        let datesE = filter.value.split(" - ");
                        if(datesE.length === 2) {
                            temp.end_date = formatDateString(datesE[0])+" - "+formatDateString(datesE[1]);
                        } else {
                            temp.end_date = "";
                        }
                        break;
                    case "status": 
                        temp.status = filter.value;
                        break;
                    case "title": 
                        temp.title = filter.value;
                        break;
                    case "readed": 
                        temp.readed = filter.value !== "" ? filter.value === "true" : undefined;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["start_date"] = params.start_date || "";
        qs["end_date"] = params.end_date || "";
        qs["status"] = params.status || "";
        qs["title"] = params.title || "";
        qs["readed"] = params.readed !== undefined ? params.readed+"" : "";
        qs["page"] = "0";
        
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const getStatus = (announcement: Announcement) => {
        return translate(`providers.announcements.statuses.${announcement.status}`) as string;
    };

    const getReaded = (announcement: Announcement) => {
        return translate(`providers.announcements.readed.${announcement.readed}`) as string;
    };

    const columns = [
        {
            name: 'start_date',
            title: translate('providers.announcements.columns.start_date') as string
        },
        {
            name: 'title',
            title: translate('providers.announcements.columns.title') as string,
        },
        {
            name: 'providers',
            title: translate("providers.announcements.columns.providers") as string,
        },
        {
            name: 'end_date',
            title: translate('providers.announcements.columns.end_date') as string,
        },
        {
            name: 'status',
            title: translate("providers.announcements.columns.status") as string,
            getCellValue: (row: any) => getStatus(row)
        },
        {
            name: 'menu',
            title: " "
        },
    ];

    const columnsProvider = [
        {
            name: 'start_date',
            title: translate('providers.announcements.columns.start_date') as string
        },
        {
            name: 'title',
            title: translate('providers.announcements.columns.title') as string,
        },
        {
            name: 'readed',
            title: translate('providers.announcements.readed.title') as string,
            getCellValue: (row: any) => getReaded(row)
        },
        {
            name: 'status',
            title: translate("providers.announcements.columns.status") as string,
            getCellValue: (row: any) => getStatus(row)
        },
    ];

    const ProvidersTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                {value.row.all_providers ? 
                  translate("providers.announcements.inputs.all_providers")
                : translate("providers.announcements.number_providers", { "providers": value.row.providers.length})
                }
            </>;
        }} {...props} />
    );

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
            <FormControl >
                <Select native style={{
                fontSize: "10px"
            }}
                    labelId="is_default"
                    id="is_default"
                    value={params.value ? params.value : ''}
                    onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                    {STATUSES.map((value) => {
                        return <option key={value} value={value}>{translate("providers.announcements.statuses."+value)}</option>
                    })}
                </Select>
            </FormControl>
            </Box>
        );
    };

    const ReadedFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
            <FormControl fullWidth>
                <Select native style={{ fontSize: "10px" }}
                    labelId="readed"
                    id="readed"
                    value={params.value ? params.value : ''}
                    onChange={onChange} >
                    <option value={""}>{""}</option>
                    <option value={"true"}>{translate(`yes`) as string}</option>
                    <option value={"false"}>{translate(`no`) as string}</option>
                </Select>
            </FormControl>
            </Box>
        );
    };

    const textColumns = ['title'];

    const customPlugins = [
        <NormalTypeProvider for={['status']} editorComponent={StatusFilterEditor}/>,
        <ProvidersTypeProvider for={[isProvider ? '' : 'providers']} editorComponent={NoFilterEditor} />,
        <NormalTypeProvider for={[isProvider ? 'readed' : '']} editorComponent={ReadedFilterEditor} />,
    ];

    const dateColumns = ['start_date', isProvider ? '' : 'end_date'];

    const clickRowColumns = ['title'];

    const onCreateAnnouncement = () => {
        onCloseCreateModal();
        onCloseOption();
        setSuccess(translate("providers.announcements.success.create") as string);
        load();
    };

    const onSendAnnouncement = () => {
        onCloseCreateModal();
        onCloseOption();
        setSuccess(translate("providers.announcements.success.sent") as string);
        load();
    };

    const onClickedRow = (announcement: Announcement) => {
        if (!context.isGrantedAny(['AnnouncementAdminUpdate', 'AnnouncementProviderUpdate'])) {
            return;
        }

        pushHistory();
        setAnnouncement(announcement);
        if(announcement.status === "EXPIRED" || isProvider){
            onDetailAnnouncement();
        } else 
        
        if (!context.isGrantedAny(['AnnouncementAdminUpdate'])) {
            return;
        } else {
            if (announcement && announcement.status === "ACTIVE"){
                setOpenConfirmEdit(true);
            } else{
                setOpenCreateModal(true);
            }
        }
    };

    const onClickedOptions = (announcement: Announcement) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAnnouncement(announcement);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setAnnouncement(undefined);
    };

    const onCloseCreateModal = () => {
        setOpenCreateModal(false);
        setAnnouncement(undefined);
    };

    const onCloseOptionModal = () => {
        setOpenOptionModal(false);
        setAnnouncement(undefined);
    };

    const onDetailAnnouncement = () => {
        setAnchorEl(null);
        setOpenViewModal(true);
    };

    const onCloseDetail = () => {
        setAnnouncement(undefined);
        setOpenViewModal(false);
    };

    const onEditAnnouncement = () => {
        setAnchorEl(null);
        if(announcement && announcement.status === "ACTIVE"){
            setOpenConfirmEdit(true);
        } else{
            setOpenCreateModal(true);
        }
    };

    const onCancelEdit = () => {
        setAnnouncement(undefined);
        setOpenConfirmEdit(false);
    };

    const onConfirmEdit = () => {
        setOpenConfirmEdit(false);
        setOpenCreateModal(true);
    };

    const onFinishAnnouncement = () => {
        setAnchorEl(null);
        setOpenConfirmFinish(true);
        setOpenViewModal(false);
    };

    const onCancelFinish = () => {
        setAnnouncement(undefined);
        setOpenConfirmFinish(false);
    };

    const onConfirmFinishAnnouncement = () => {
        setOpenConfirmFinish(false);
        if(!announcement) return;
        setStatus("loading");
        changeStatusAnnouncement(context.session!.tenant!.id, announcement.id, { status: "EXPIRED" } as ChangeStatusRequest).then((response) => {
            load();
            setSuccess(translate("providers.announcements.success.finish") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
            setAnnouncement(undefined);
        });
    };

    const onDeleteAnnouncement = () => {
        if(!announcement) return;
        setStatus("loading");
        deleteAnnouncement(context.session!.tenant!.id, announcement.id ).then((_) => {
            setSuccess(translate("providers.announcements.success.delete") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
        onCloseOption();
    };

    const onSelectOption = (selected: string) => {
        setOpenOptionModal(false);
        if(!selected){
            return;
        }
        setOptionSelected(selected === "all" ? "all" : selected === "class" ? "class" : "providers");
        setOpenCreateModal(true);
    }

    return (
        <Surface title={translate("providers.announcements.title") as string} 
            icon={<NotificationsIcon />} 
            className="PaperPagination"
            titleActions={context.isGranted("AnnouncementAdminCreate") ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={() => {
                                setOpenOptionModal(true);
                                setAnnouncement(undefined);
                            }}>
                                <AddIcon />
                            </Fab>
                    </Grid>
                </Grid>) : undefined
            }>
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={isProvider ? columnsProvider : columns}
                columnsFormat={columnsFormat}
                dateColumns={dateColumns}
                textColumns={textColumns}
                filters={filters}
                customFormatColumns={customPlugins} 
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                onClickRow={onClickedRow}
                onClickedOptions={onClickedOptions}
                clickRowColumns={clickRowColumns}
                heightTablePX={400}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {openCreateModal && (
                <AnnouncementCreateModal 
                    tenantId={context.session!.tenant!.id}
                    announcementId={announcement ? announcement.id : undefined}
                    option={announcement ? 
                        announcement.find_providers_type === "ALL_PROVIDERS" ?  "all" :
                        announcement.find_providers_type === "BY_PROVIDER_IDS" ? "providers" : "class"
                        : optionSelected!}
                    onSave={onCreateAnnouncement}
                    onSend={onSendAnnouncement}
                    onBackToOptions={() => { setOpenCreateModal(false); setOpenOptionModal(true);}}
                    onClose={onCloseCreateModal}
                />
            )}
            {openOptionModal && (
                <AnnuncementSelectOptionPopUp
                    onSelectOption={onSelectOption}
                    onClose={onCloseOptionModal}
                />
            )}
            {announcement && anchorEl && (
                <AnnouncementMenu announcement={announcement} anchor={anchorEl}
                    onClose={onCloseOption}
                    onDetail={onDetailAnnouncement}
                    onEdit={onEditAnnouncement}
                    onFinish={onFinishAnnouncement}
                    onDelete={onDeleteAnnouncement}
                />
            )}
            {openViewModal && announcement && 
                <ShowAnnouncementModal announcement={announcement} 
                    onClose={onCloseDetail} isProvider={isProvider} onFinish={onFinishAnnouncement}
                />
            }
            {openConfirmEdit &&
                <ConfirmationPopup
                    title={translate("providers.announcements.confirm_edit.title") as string}
                    message={translate("providers.announcements.confirm_edit.message") as string}
                    secondary={translate("providers.announcements.confirm_edit.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelEdit}
                    doAction={onConfirmEdit}
                />
            }
            {openConfirmFinish &&
                <ConfirmationPopup
                    title={translate("providers.announcements.confirm_finish.title") as string}
                    message={translate("providers.announcements.confirm_finish.message") as string}
                    secondary={translate("providers.announcements.confirm_finish.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelFinish}
                    doAction={onConfirmFinishAnnouncement}
                />
            }
        </Surface>
    );
}
