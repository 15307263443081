import React, { useState, useEffect } from "react";
import { Button, Typography, Box, TextField, Grid, IconButton } from "@material-ui/core";
import Gridable from "../components/Gridable";

import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';

import translate from "../i18n/Translator";
import { ProviderClassification, ProviderClassificationsQueryFilter } from "../model/ProviderClassification";
import { listClassifications } from "../api/ProviderClassificationAPI";

interface SelectClassificationsPopUpProps {
    tenantId: string;
    classifications: string[];
    onWarning(message: string): any;
    onSuccess(selectedIds: string[], noProviders: number): any;
    onClose(): any;
}

export default function SelectClassificationsPopUp(props: SelectClassificationsPopUpProps) {
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ProviderClassification[]>([]);
    const [addedClassificacions, setAddedClassificacions] = useState<ProviderClassification[]>([]);
    const [classification, setClassificacion] = useState<ProviderClassification>();
    const [newclassification, setNewClassificacion] = useState<ProviderClassification>();

    useEffect(() => {
        listClassifications(props.tenantId, 0, 0, {search: ""} as ProviderClassificationsQueryFilter).then((response) => {
            setData(response.items);
            if(props.classifications){
                let items = [] as ProviderClassification[];
                response.items.forEach(item => {
                    if(props.classifications.includes(item.id)){
                        items.push(item);
                    }
                });                
                setAddedClassificacions(items);
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [props]);

    useEffect(() => {
        // eslint-disable-next-line
    }, [newclassification]);

    const onNext = () => {
        let noProviders = addedClassificacions.reduce((sum, item) => sum + item.providers_counter, 0);
        props.onSuccess(addedClassificacions.map(item => item.id), noProviders);
    }

    const onFilterChanged = (value: string, inputRef?: any) => {
        if (value) {
            let selected = data.find(item => item.id === value);
            if(selected){
                setClassificacion(selected);
            }
        }
    };

    const onAddItem = () => {
        if(classification && isDuplicate()){
            props.onWarning(translate("providers.announcements.warning.classificaion_duplied") as string);
            return;
        }
        if (classification) {
            let selected = data.find(item => item.id === classification.id);
            if(selected){
                addedClassificacions.push(selected);
                onNext();
                
            }
        }
    }

    const isDuplicate = () => {
        var isDuplicate = false;
        addedClassificacions.forEach((item) => {
            if (classification && item.id === classification.id) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    };

    const onRemoveItem = (item: string) => {
        let valueToDelete = addedClassificacions.find(v => v.id === item);
        if (valueToDelete) {
            let index = addedClassificacions.indexOf(valueToDelete);
            if (index > -1) {
                addedClassificacions.splice(index, 1);
                setNewClassificacion(newclassification && newclassification.id ===  valueToDelete.id ? undefined : valueToDelete);
            }
        }
    }

    if(status === "loading") {
        return <></>;
    }

    return (
            <Grid container spacing={1}>
                <Grid container xs={12} className="TableFilter" alignItems="center" direction="row" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="inherit">
                            {translate("providers.announcements.options.subtitle") as string}
                        </Typography>
                    </Grid>
                        <Grid container alignItems="center" direction="row" spacing={1}>
                            <Grid item xs={10}>
                                <Autocomplete
                                    size="small"
                                    options={data || []}
                                    value={classification}
                                    getOptionLabel={(elemento) => elemento.name}
                                    noOptionsText={translate("providers.announcements.empty_classifications") as string}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={translate("providers.announcements.classificatins_input") as string} fullWidth />
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            onFilterChanged(newValue.id);
                                        } else {
                                            onFilterChanged("");
                                        }
                                    }}
                                    getOptionSelected={(opt, val) => opt.id === val.id}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={onAddItem} variant="outlined" size="large" color="secondary">
                                    {translate("buttons.add")}
                                </Button>
                            </Grid>

                        </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <Box px={2}>
                        <Gridable
                            items={addedClassificacions}
                            loading={false}
                            empty={translate("cfdis.empty") as string}
                            columns={[
                                {
                                    title: translate("providers.classifications.title") as string,
                                    converter: (value) => value.name,
                                    fullWidth: true,
                                    xs: true
                                },
                                {
                                    title: translate("providers.classifications.no_providers") as string,
                                    converter: (value) => value.providers_counter,
                                    fullWidth: true,
                                    xs: 3,
                                    sm: 3,
                                    md: 3,
                                    lg: 3,
                                    xl: 3
                                },
                                {
                                    title: (
                                        <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    ),
                                    converter: (value) => (
                                        <IconButton
                                            aria-label="options"
                                            color="default"
                                            size="small"
                                            onClick={(event: React.MouseEvent<HTMLElement>) => onRemoveItem(value.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    ),
                                    fullWidth: true,
                                    xs: "auto",
                                    sm: "auto",
                                    md: "auto",
                                    lg: "auto",
                                    xl: "auto"
                                }
                            ]}
                        />
                    </Box>
                </Grid>
            </Grid>
    );
}
