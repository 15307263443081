import { callAPI, HTTPMethod } from "./API";
import { ProjectListParams, ProjectsResponse as Projects, ProjectRequest, Project } from "../model/Project";
import { ExportRequest, ExportResponse } from "../model/Connector";

export async function list(tenantId: string, page: number, offset: number, params: ProjectListParams): Promise<Projects> {

    let query = {
        search: params.search ? params.search : "",
        status: params.status ? params.status : "",
        userId: params.user_id ? params.user_id : "",
        key: params.key ? params.key : "",
        customer: params.customer ? params.customer : "",
        projectName: params.project_name ? params.project_name : "",
        projectType: params.project_type ? params.project_type : "",
        currentBudget: params.current_budget ? params.current_budget : "",
        budgetStatus: params.budget_status ? params.budget_status : "",
        pageSize: page.toString(),
        offset: offset.toString(),
        requisitionType: params.requisition_type ? params.requisition_type : "",
    } as Record<string, string>;

    if (params.start_date && params.end_date) {
        query = {
            ...query,
            startDate: params.start_date,
            endDate: params.end_date
        };
    }

    return await callAPI(`/tenants/${tenantId}/projects`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function finalize(tenantId: string, projectId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/projects/${projectId}/finalize`, {
        method: HTTPMethod.PUT,
    });
}

export async function createProject(tenantId: string, request: ProjectRequest): Promise<Project> {
    return await callAPI(`/tenants/${tenantId}/projects`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getProject(tenantId: string, projectId: string): Promise<Project> {
    return await callAPI(`/tenants/${tenantId}/projects/${projectId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateProject(tenantId: string, projectId: string, request: ProjectRequest): Promise<Project> {
    return await callAPI(`/tenants/${tenantId}/projects/${projectId}/update`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function exportSelectedProjects(tenantId: string, connectorId: string, ids: string[]): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/projects/export`, {
        method: HTTPMethod.PUT,
        body: {
            ids: ids,
            connector_id: connectorId,
        } as ExportRequest
    });
}

export async function updateStatusDraftToStart(tenantId: string, projectId: string): Promise<Project> {
    return await callAPI(`/tenants/${tenantId}/projects/${projectId}/release`, {
        method: HTTPMethod.PUT,
    });
}

export async function deleteValueExtendeField(tenantId: string, projectId: string, templateId: string, fieldId: string): Promise<Project> {
    return await callAPI(`/tenants/${tenantId}/projects/${projectId}/templates/${templateId}/fields/${fieldId}`, {
        method: HTTPMethod.DELETE,
    });
}

export async function updateValueExtendeField(tenantId: string, projectId: string, templateId: string, fieldId: string, value: string): Promise<Project> {
    return await callAPI(`/tenants/${tenantId}/projects/${projectId}/templates/${templateId}/fields/${fieldId}`,
        {
            method: HTTPMethod.PUT,
            body: {
                "value": value
            }
        });
}