import { callAPI, HTTPMethod } from "./API";
import { RequisitionsTypeCatalog, RequisitionTypeCatalogListParams, RequisitionTypeCatalog, RequisitionTypeMetadata } from "../model/RequisitionTypeCatalog";
import { UpdateWorkflowRequest } from "../workflove/Model";

const basePath = (tenantId: string) => {
    return `/tenants/${tenantId}/requisition-types`;
};

export async function listRequisitionTypeCatalog(tenantId: string, page: number, offset: number, params: RequisitionTypeCatalogListParams): Promise<RequisitionsTypeCatalog> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.GET,
        query: {
            search: params.search || "",
            purchaseOrderMode: params.purchase_order_mode || "",
            pageSize: page.toString(),
            offset: offset.toString(),
        },
    });
}

export async function metadata(tenantId: string): Promise<RequisitionTypeMetadata> {
    return await callAPI(`${basePath(tenantId)}/metadata`, {
        method: HTTPMethod.GET,
    });
}

export async function createRequisitionType(tenantId: string, request: RequisitionTypeCatalog): Promise<RequisitionTypeCatalog> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function updateRequisitionType(tenantId: string, requisitionTypeId: string, request: RequisitionTypeCatalog,): Promise<RequisitionTypeCatalog> {
    return await callAPI(`${basePath(tenantId)}/${requisitionTypeId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function getRequisitionType(tenantId: string, requisitionTypeId: string): Promise<RequisitionTypeCatalog> {
    return await callAPI(`${basePath(tenantId)}/${requisitionTypeId}`, {
        method: HTTPMethod.GET,
    });
}

export async function activeRequisitionType(tenantId: string, requisitionTypeId: string,): Promise<RequisitionTypeCatalog> {
    return await callAPI(`${basePath(tenantId)}/${requisitionTypeId}/active`, {
        method: HTTPMethod.PUT
    });
}

export async function updateRequisitionTypeTemplate(tenantId: string, requisitionTypeId: string, request: UpdateWorkflowRequest): Promise<RequisitionTypeCatalog> {
    return await callAPI(`${basePath(tenantId)}/${requisitionTypeId}/template`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}