import { callAPI, HTTPMethod } from "./API";
import { SpidRelationTypes, SpidRelationTypesParams } from "../model/SpidRelationType";

export async function list(tenantId: string, params: SpidRelationTypesParams, page: number, offset: number): Promise<SpidRelationTypes> {
    return await callAPI(`/tenants/${tenantId}/spid-relation-types`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}