import React, { useState, useContext } from "react";
import Dropzone from 'react-dropzone';
import { AppContext } from "../context/AppContext";
import { Redirect } from "react-router-dom";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import SendCfdiIcon from "@material-ui/icons/SendSharp";
import queryString from "query-string";
import translate, { translateParams } from "../i18n/Translator";
import { CfdiRequest, CfdiValidation, Cfdi, ReceptionDateValidationResponse, CfdisRequest } from "../model/Cfdi";
import { PaymentCfdiAttacment, AttachPaymentCfdis, PaymentListParams, Payment } from "../model/Payment";
import { createCfdi } from "../api/ProviderCfdiApi";
import { associateAdvancePayments, createAdminCfdi, restrictedToAssociateAdvancePayments, restrictedToSend, validateReceptionDate } from "../api/TenantCfdiApi";
import { callAttachPaymentCfdis, attachPaymentCfdi, getPayments } from "../api/PaymentApi";
import { createCfdi as createExpensesAccountCfdi, createHonorarium as createExpensesAccountHonorarium } from "../api/ExpensesAccountAPI";
import { createCfdi as createPurchaseOrderCfdi } from "../api/ProviderPurchaseOrderAPI";
import { createCfdi as createWarehouseDeliveryCfdi } from "../api/ProviderWarehouseDeliveryAPI";
import { createCfdi as createIssuedCfdi } from "../api/TenantSECfdiApi";
import { createCfdi as createProviderIssuedCfdi } from "../api/ProviderSECfdiApi";
import Surface from "../components/Surface";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar, WarningSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import { CfdiValidationsPopup } from "./popups/CfdiValidationsPopup";
import { CfdiPaymentValidationsPopup } from "./popups/CfdiPaymentValidationsPopup";
import { PaymentCfdiRequestReviewPopup } from "./popups/PaymentCfdiRequestReviewPopup";
import { AttachPaymentCfdisPopup } from "./popups/AttachPaymentCfdisPopup";
import { ProviderCfdiOverdueResponse } from "../model/ProviderCfdi";
import DialogPopup from "../components/DialogPopup";
import { useHistory } from "react-router-dom";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import SimpleSwitch from "../components/SimpleSwitch";
import CfdiAsignWorkflowPopUp from "../cfdi/CfdiAsignWorkflowPopUp";
import { ReceptionDateValidationType } from "../model/TenantConfiguration";
import { Workflow } from "../model/Workflow";
import { Company } from "../model/Company";
import ReactHtmlParser from "html-react-parser";
import { upload } from "../api/S3UploaderApi";
import { AssociateAdvancePayments } from "./popups/AssociateAdvancePayments";
import { AdvancePayment } from "../model/AdvancePayment";

export function ProviderCfdiForm() {
    return (
        <CfdiForm mode="provider" />
    );
}

export function ReceptorCfdiForm() {
    return (
        <CfdiForm mode="receptor" />
    );
}

export function AdminCfdiForm() {
    return (
        <CfdiForm mode="admin" />
    );
}

export function PaymentCfdiForm() {
    return (
        <CfdiForm mode="payment" />
    );
}

export function ExpensesAccountCfdiForm({ match }: RouterParams) {
    return (
        <CfdiForm mode="expenses-account" reference={match.params.expensesAccountId} />
    );
}

export function ExpensesAccountHonorariumCfdiForm({ match }: RouterParams) {
    return (
        <CfdiForm mode="honorarium" reference={match.params.expensesAccountId} />
    );
}

export function PurchaseOrderCfdiForm({ match }: RouterParams) {
    return (
        <CfdiForm mode="purchase-order" reference={match.params.purchaseOrderId} />
    );
}

export function WarehouseDeliveryCfdiForm({ match }: RouterParams) {
    return (
        <CfdiForm mode="warehouse-delivery" reference={match.params.warehouseDeliveryId} />
    );
}
interface CfdiFormProps {
    mode: "admin" | "receptor" | "payment" | "provider" | "expenses-account" | "honorarium" | "purchase-order" | "warehouse-delivery" | "cfdi-issued";
    reference?: string;
}

export function CfdiForm(props: CfdiFormProps) {
    const history = useHistory();
    const context = useContext(AppContext);
    const providerId = context.session?.provider?.id;
    const params = queryString.parse(window.location.search);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState("");
    const [success, setSuccess] = useState("");
    const [xmlFile, setXml] = useState<File>();
    const [pdfFile, setPdf] = useState<File>();
    const [paymentCfdiXmls, setPaymentCfdiXmls] = useState<File[] | undefined>(undefined);
    const [paymentCfdiPdfs, setPaymentCfdiPdfs] = useState<File[] | undefined>(undefined);
    const [pdfLocation, setPdfLocation] = useState<string>();
    const xmlLabel = props.mode === 'payment' ? translate("cfdis.upload_xmls") as string : translate("cfdis.upload_xml") as string;
    const [uploadXmlLabel, setXmlLabel] = useState<string>(xmlLabel);
    const pdfLabel = props.mode === 'payment' ? translate("cfdis.upload_pdfs") as string : translate("cfdis.upload_pdf") as string;
    const [uploadPdfLabel, setPdfLabel] = useState<string>(pdfLabel);
    const [cfdiValidations, setCfdiValidations] = useState<CfdiValidation[]>();
    const [cfdiData, setCfdiData] = useState<Cfdi>();
    const [cfdiPartiallyValid, setcfdiPartiallyValid] = useState<boolean>(false);
    const [paymentCfdiAttacment, setPaymentCfdiAttacment] = useState<PaymentCfdiAttacment>();
    const [showPaymentCfdiValidation, setShowPaymentCfdiValidation] = useState<boolean>(false);
    const [hasPaymentCfdiReview, setHasPaymentCfdiReview] = useState<boolean>(false);
    const [cfdiPaymentOverdue, setCfdiPaymentOverdue] = useState<ProviderCfdiOverdueResponse>();
    const [hasOverdue, setHasOverdue] = useState<boolean>(false);
    const [hasExpiredExpedient, setHasExpiredExpedient] = useState<boolean>(false);
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const [providerWorkflows, setProviderWorkflows] = useState<Workflow[]>();
    const [providerCompanies, setProviderCompanies] = useState<Company[]>();
    const [incompleteCfdi, setIncompleteCfdi] = useState<Cfdi>();
    const [receptionDateValidation, setReceptionDateValidation] = useState<ReceptionDateValidationResponse | undefined>(undefined);
    const [openConfirmValidateAmount, setOpenConfirmValidateAmount] = useState<boolean>(false);
    const [selectablePayments, setSelectablePayments] = useState<Payment[]>();
    const [attachPaymentCfdisResponse, setAttachPaymentCfdisResponse] = useState<AttachPaymentCfdis | undefined>()
    const [allways_to_review, setAllways_to_review] = useState<boolean>(false);
    const [errorPartiallity, setErrorPartiallity] = useState<string | JSX.Element | JSX.Element[]>();
    const [providerRepitedSe, setProviderRepitedSe] = useState<boolean>(false);
    const [onlySe, setOnlySe] = useState<boolean>(false);
    const [workflowId, setWorkflowId] = useState<string>("");
    const [companyId, setCompanyId] = useState<string>("");
    const [alreadyRestrictedVerify, setAlreadyRestrictedVerify] = useState<boolean>(false);
    const [openAssociateAdvancePayments, setOpenAssociateAdvancePayments] = useState<boolean>(false);
    const [helperToNextRedirect, setHelperToNextRedirect] = useState<string>();


    const submitPayment = (companyId: string) => {
        if (paymentCfdiXmls!.length === 1) {
            attachOnePaymentCfdi(paymentCfdiXmls![0], paymentCfdiPdfs![0], companyId);
        } else {
            attachManyPaymentCfdi(paymentCfdiXmls!, paymentCfdiPdfs!, companyId);
        }
    };

    const attachOnePaymentCfdi = (xmlParam: File, pdfParam: File, companyId: string) => {
        setSubmitting(true);
        const request = { xml: xmlParam, pdf: pdfParam, company_id: companyId, provider_id: providerId, only_se: providerRepitedSe ? onlySe : undefined } as CfdiRequest;
        attachPaymentCfdi(context.session!.tenant!.id, request).then((paymentCfdiAttacment) => {
            setProviderRepitedSe(false);
            if (paymentCfdiAttacment.payment_cfdi && paymentCfdiAttacment.payment_cfdi.metadata.company_id) {
                if (!paymentCfdiAttacment.valid_cfdi) {
                    let pay_cfdi = paymentCfdiAttacment.payment_cfdi as Cfdi;
                    setCfdiValidations(pay_cfdi.metadata.validations);
                    setCfdiData(pay_cfdi);
                } else {
                    setAllways_to_review(paymentCfdiAttacment.allways_to_review);
                    setPaymentCfdiAttacment(paymentCfdiAttacment);
                    setShowPaymentCfdiValidation(true);
                    setHasPaymentCfdiReview(paymentCfdiAttacment.has_payment_cfdi_review);
                }
            } else {
                setProviderCompanies(paymentCfdiAttacment.payment_cfdi.metadata.incident_companies);
                setIncompleteCfdi(paymentCfdiAttacment.payment_cfdi);
            }

        }).catch((error) => {
            if (error.code === 1333) {
                setProviderRepitedSe(true);
            } else {
                setError(error.message);
            }
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const attachManyPaymentCfdi = (xmlsParam: File[], pdfsParam: File[], companyId: string) => {
        const request = { xmls: xmlsParam, pdfs: pdfsParam, provider_id: providerId } as CfdisRequest;
        setSubmitting(true);
        callAttachPaymentCfdis(context.session!.tenant!.id, request).then((response) => {
            setAttachPaymentCfdisResponse(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const getSubmitPromiseAsProvider = (workflowId: string, companyId: string, validationOC: string) => {
        const request = { xml: xmlFile, pdf: pdfFile, workflow_id: workflowId, company_id: companyId, validation_oc: validationOC } as CfdiRequest;
        const tenantId = context.session!.tenant!.id;
        const providerId = context.session!.provider!.id;
        switch (props.mode) {
            case "provider":
                const providerRequest = { ...request, preload_params: params } as CfdiRequest;
                return createCfdi(tenantId, providerId, providerRequest);
            case "purchase-order":
                return createPurchaseOrderCfdi(tenantId, providerId, props.reference || "", request);
            case "warehouse-delivery":
                return createWarehouseDeliveryCfdi(tenantId, providerId, props.reference || "", request);
            default:
                throw Error(`Unexpected mode ${props.mode}`);
        }
    };

    const submitAsProvider = (workflowId: string, companyId: string, validationOC: string) => {
        setSubmitting(true);
        getSubmitPromiseAsProvider(workflowId, companyId, validationOC).then((cfdi) => {
            if (cfdi.cfdis_cancelled) {
                setCfdisCancelled(cfdi.cfdis_cancelled.cfdis_invalid);
                setSubmitting(false);
                return;
            }
            if (!cfdi.metadata.company_id || ((cfdi.metadata.type !== "CREDIT_NOTE" && cfdi.metadata.type !== "PAYMENT_CFDI") && (!cfdi.metadata.workflow_id || cfdi.metadata.workflow_id === "") && cfdi.metadata.provider_workflows && cfdi.metadata.provider_workflows.length > 1)) {
                setProviderWorkflows(cfdi.metadata.provider_workflows);
                setProviderCompanies(cfdi.metadata.incident_companies);
                setIncompleteCfdi(cfdi);
                setSubmitting(false);
                return;
            }
            if (cfdi.metadata.cfdi_valid) {
                if (!cfdi.metadata.non_base_valid) {
                    setcfdiPartiallyValid(true);
                    setCfdiData(cfdi);
                    setCfdiValidations(cfdi.metadata.validations);
                } else {
                    redirectAfterUpload(cfdi, "/provider/cfdis/to-send/success/", "to-send");
                }
            } else {
                setCfdiValidations(cfdi.metadata.validations);
                setCfdiData(cfdi);
            }
        }).catch((error) => {
            if (error.code === 6600 || error.code === 6601 || error.code === 6602) {
                setErrorPartiallity(ReactHtmlParser(error.message));
            } else if (error.code === 6605) {
                setWorkflowId(workflowId);
                setCompanyId(companyId);
                setOpenConfirmValidateAmount(true);
            } else if (error.code === 4604) {
                setError(error.message);
            } else {
                setError(ReactHtmlParser(error.message));
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const submitAsTenant = (workflowId: string, companyId: string) => {
        const request = { xml: xmlFile, pdf: pdfFile } as CfdiRequest;
        setSubmitting(true);
        restrictedToSend(context.session!.tenant!.id, request).then((response) => {
            setCfdiPaymentOverdue(response.cfdi_overdue);
            if (response.cfdi_overdue.block === true && workflowId === "" && !alreadyRestrictedVerify) {
                setHasOverdue(true);
                setSubmitting(false);
            } else if (response.expedient.block === true && !alreadyRestrictedVerify) {
                setHasExpiredExpedient(true);
                setSubmitting(false);
            } else {
                callCreateAdminCfdi(workflowId, companyId);
            }
            setAlreadyRestrictedVerify(true);
        }).catch((error) => {
            setSubmitting(false);
            setError(ReactHtmlParser(error.message));
            setCfdiPaymentOverdue({
                cfdi_payment_overdue: 0,
                max_payment_cfdi_overdue: 0
            } as ProviderCfdiOverdueResponse);
        })
    };

    const submitSePromise = (companyId: string) => {
        const request = { xml: xmlFile, pdf: pdfFile, company_id: companyId } as CfdiRequest;
        if (providerId) {
            return createProviderIssuedCfdi(context.session!.tenant!.id, providerId, request);
        }
        return createIssuedCfdi(context.session!.tenant!.id, request);
    };

    const submitCfdiIssued = (companyId: string) => {
        setSubmitting(true);
        submitSePromise(companyId).then((cfdi) => {
            if (cfdi.cfdis_cancelled) {
                setCfdisCancelled(cfdi.cfdis_cancelled.cfdis_invalid);
                return;
            }

            if (!cfdi.metadata.company_id && !cfdi.metadata.customer_id) {
                setProviderWorkflows(cfdi.metadata.provider_workflows);
                setProviderCompanies(cfdi.metadata.incident_companies);
                setIncompleteCfdi(cfdi);
                setSubmitting(false);
                return;
            }

            if (cfdi.metadata.cfdi_valid) {
                if (cfdi.metadata.non_base_valid) {
                    history.push(`/cfdis/se/${cfdi.id}/prepare`);
                } else {
                    setcfdiPartiallyValid(true);
                    setCfdiData(cfdi);
                    setCfdiValidations(cfdi.metadata.validations);
                }
            } else {
                setCfdiValidations(cfdi.metadata.validations);
                setCfdiData(cfdi);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const getExpensesAccountPromise = (honorarium: boolean, workflowId: string, companyId: string) => {
        const request = { xml: xmlFile, pdf: pdfFile, workflow_id: workflowId, company_id: companyId } as CfdiRequest;
        if (honorarium) {
            return createExpensesAccountHonorarium(context.session!.tenant!.id, context.session!.provider!.id, props.reference!, request)
        }
        return createExpensesAccountCfdi(context.session!.tenant!.id, context.session!.provider!.id, props.reference!, request);
    };

    const submitForExpensesAccount = (honorarium: boolean, workflowId: string, companyId: string) => {
        setSubmitting(true);
        getExpensesAccountPromise(honorarium, workflowId, companyId).then((cfdi) => {
            if (!cfdi.metadata.company_id || ((cfdi.metadata.type !== "CREDIT_NOTE" && cfdi.metadata.type !== "PAYMENT_CFDI") && (!cfdi.metadata.workflow_id || cfdi.metadata.workflow_id === "") && cfdi.metadata.provider_workflows && cfdi.metadata.provider_workflows.length > 1)) {
                setProviderWorkflows(cfdi.metadata.provider_workflows);
                setProviderCompanies(cfdi.metadata.incident_companies);
                setIncompleteCfdi(cfdi);
                setSubmitting(false);
                return;
            }
            if (cfdi.metadata.cfdi_valid) {
                if (cfdi.metadata.non_base_valid) {
                    setRedirect(`/cfdis/${cfdi.id}/details?referer=expense-account-${props.reference}`);
                } else {
                    setcfdiPartiallyValid(true);
                    setCfdiData(cfdi);
                    setCfdiValidations(cfdi.metadata.validations);
                }
            } else {
                setCfdiValidations(cfdi.metadata.validations);
                setCfdiData(cfdi);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        channelSubmit("", "");
    };

    const onSubmitWhenProviderSE = () => {
        channelSubmit("", "");
    };

    const channelSubmit = (workflowId: string, companyId: string) => {
        if (props.mode === "payment") {
            if (!paymentCfdiXmls && !paymentCfdiPdfs) {
                setWarning(translate("cfdis.xml_pdf_required") as string);
                return;
            } else if (!paymentCfdiXmls) {
                setWarning(translate("cfdis.xml_required") as string);
                return;
            } else if (!paymentCfdiPdfs) {
                setWarning(translate("cfdis.pdf_required") as string);
                return;
            } else if (paymentCfdiXmls.length !== paymentCfdiPdfs.length) {
                setWarning(translate('cfdis.xmls_pdfs_mismatch') as string)
                return;
            }
        } else {
            if (!xmlFile && !pdfFile) {
                setWarning(translate("cfdis.xml_pdf_required") as string);
                return;
            } else if (!xmlFile) {
                setWarning(translate("cfdis.xml_required") as string);
                return;
            } else if (!pdfFile) {
                setWarning(translate("cfdis.pdf_required") as string);
                return;
            }
        }
        setSubmitting(true);

        switch (props.mode) {
            case "payment":
                submitPayment(companyId);
                break;
            case "provider":
            case "purchase-order":
            case "warehouse-delivery":
                submitAsProvider(workflowId, companyId, "NOT_SPECIFIED");
                break;
            case "expenses-account":
                submitForExpensesAccount(false, workflowId, companyId);
                break;
            case "honorarium":
                submitForExpensesAccount(true, workflowId, companyId);
                break;
            case "cfdi-issued":
                submitCfdiIssued(companyId);
                break;
            default:
                submitAsTenant(workflowId, companyId);
        }
    }

    const onSelectedWorkflow = (workflowId: string, companyId: string, confirm: boolean) => {
        if (incompleteCfdi && !incompleteCfdi.metadata.workflow_id && !workflowId && props.mode !== "cfdi-issued") {
            setWarning(translate("workflows.cfdi_workflow.empty_selection") as string);
        }
        if (incompleteCfdi && !incompleteCfdi.metadata.company_id && !companyId) {
            setWarning(translate("companies.warning") as string);
        }

        setIncompleteCfdi(undefined);
        channelSubmit(workflowId, companyId);
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    const onCloseWarning = () => {
        setWarning("");
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    const onDropXmlZone = (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
            if (props.mode === "payment") {
                onDropPaymentCfdiXmls(acceptedFiles);
            } else {
                onDropCfdiXml(acceptedFiles[0]);
            }
        } else {
            setError(translate("cfdis.invalid_file", { "type": "xml" }) as string);
        }
    };

    const onDropPaymentCfdiXmls = (xmls: File[]) => {
        setPaymentCfdiXmls(xmls);
        if (xmls.length > 1) {
            setXmlLabel(`${xmls.length} ${translate('cfdis.files', { type: 'XML' }) as string}`);
        } else {
            if (paymentCfdiPdfs && paymentCfdiPdfs.length === 1) {
                validateXmlName(xmls[0], paymentCfdiPdfs[0]);
            }
            setXmlLabel(`${xmls[0].name} - ${xmls[0].size} bytes`);
        }
    }

    const validateXmlName = (xml: File, pdf?: File) => {
        if (pdf && xml.name.substring(0, xml.name.indexOf(".")) !== pdf.name.substring(0, pdf.name.indexOf("."))) {
            setWarning(translate("cfdis.file_names_not_equals") as string);
        }
    }

    const onDropCfdiXml = (xml: File) => {
        validateXmlName(xml, pdfFile);
        if (props.mode !== "cfdi-issued") {
            validateReceptionDate(context.session!.tenant!.id, xml).then((response) => {
                if (response.tenant_side && response.validation_type === ReceptionDateValidationType.IN_CALENDAR) {
                    return;
                }
                if (!response.valid) {
                    setReceptionDateValidation(response);
                }
            }).catch((errors) => {
                setError(errors.message);
            });
        }
        setXml(xml);
        setXmlLabel(`${xml.name} - ${xml.size} bytes`);
    }

    const onDropPdfZone = (acceptedFiles: File[]) => {
        setPdfLocation(undefined);
        if (acceptedFiles.length) {
            if (props.mode === "payment") {
                onDropPaymentCfdiPdfs(acceptedFiles);
            } else {
                onDropCfdiPdf(acceptedFiles[0]);
            }
        } else {
            setError(translate("cfdis.invalid_file", { "type": "pdf" }) as string);
        }
    }

    const onDropPaymentCfdiPdfs = (pdfs: File[]) => {
        setPaymentCfdiPdfs(pdfs);
        if (pdfs.length > 1) {
            setPdfLabel(`${pdfs.length} ${translate('cfdis.files', { type: 'PDF' }) as string}`);
        } else {
            if (paymentCfdiXmls && paymentCfdiXmls.length === 1) {
                validatePdfName(pdfs[0], paymentCfdiXmls[0]);
            }
            setPdfLabel(`${pdfs[0].name} - ${pdfs[0].size} bytes`);
            uploadPdfFile(pdfs[0]);
        }
    }

    const validatePdfName = (pdf: File, xml?: File) => {
        if (xml && pdf.name.substring(0, pdf.name.indexOf(".")) !== xml.name.substring(0, xml.name.indexOf("."))) {
            setWarning(translate("cfdis.file_names_not_equals") as string);
        }
    }

    const onDropCfdiPdf = (pdf: File) => {
        setPdf(pdf);
        setPdfLabel(`${pdf.name} - ${pdf.size} bytes`);
        validatePdfName(pdf, xmlFile);
        uploadPdfFile(pdf);
    }

    const uploadPdfFile = (file: File) => {
        upload(`${process.env.REACT_APP_AWS_S3_DIR}/temp/${new Date().getTime()}`, file, {
            onError: (error) => {
                setError(error);
            },
            onSuccessUpload: (location) => {
                setPdfLocation(location);
            },
            onFinally: () => { }
        }, true);
    }

    const onCloseCfdiValidationsPopup = () => {
        if (cfdiPartiallyValid) {
            switch (props.mode) {
                case "admin":
                    setRedirect(`/tenant/cfdis/owner-to-authorize`);
                    break;
                case "receptor":
                    setRedirect(`/tenant/cfdis/receptor-to-authorize`);
                    break;
                default:
                    redirectAfterUpload(cfdiData!, `/provider/cfdis/${cfdiData!.id}/details/`, "to-send");
            }
        } else {
            setCfdiValidations(undefined);
            setCfdiData(undefined);
        }
    }

    const onRequestPaymentCfdiReview = () => {
        let paymentsParams = {
            "search": "",
            "provider_id": paymentCfdiAttacment?.payment_cfdi.metadata.provider_id,
            "payment_status": "PENDING_PAYMENT_CFDI,REJECTED_PAYMENT_CFDI"
        } as PaymentListParams;
        getPayments(context.session!.tenant!.id, 0, 0, paymentsParams).then((response) => {
            if (response.items) {
                setSelectablePayments(response.items);
            } else {
                setWarning(translate("payment.upload.pending_payments_not_found") as string);
            }
        }).catch((errors) => {
            setError(errors.message);
        });
        setShowPaymentCfdiValidation(false);
    }

    const onCloseCfdiPaymentValidationsPopup = () => {
        if (paymentCfdiAttacment?.success_attach) {
            setRedirect(`/payment/pending-cfdi/success/${translate("payment.upload.success_attach", { "n": paymentCfdiAttacment.validated_payments.length }) as string}`);
        } else {
            setWarning(translate("payment.upload.fail_attach") as string)
            setPaymentCfdiAttacment(undefined);
            setShowPaymentCfdiValidation(false);
        }
    }

    const onCloseValidationCfdiPaymentOverdue = () => {
        setHasOverdue(false);
    };

    const onCloseValidationExpiredExpedient = () => {
        setHasExpiredExpedient(false);
        setAlreadyRestrictedVerify(false);
    };

    const callCreateAdminCfdi = (workflowId: string, companyId: string) => {
        setHasOverdue(false);
        setSubmitting(true);
        let workflowIdParam = !workflowId && typeof params["workflow_id"] === "string" ? params["workflow_id"] as string : workflowId;
        createAdminCfdi(context.session!.tenant!.id, { xml: xmlFile, pdf: pdfFile, workflow_id: workflowIdParam, company_id: companyId, preload_params: params } as CfdiRequest).then((cfdi) => {
            if (cfdi.cfdis_cancelled) {
                setCfdisCancelled(cfdi.cfdis_cancelled.cfdis_invalid);
                setSubmitting(false);
                return;
            }
            if (!cfdi.metadata.company_id || ((cfdi.metadata.type !== "CREDIT_NOTE" && cfdi.metadata.type !== "PAYMENT_CFDI") && (!cfdi.metadata.workflow_id || cfdi.metadata.workflow_id === "") && cfdi.metadata.provider_workflows && cfdi.metadata.provider_workflows.length > 1)) {
                setProviderWorkflows(cfdi.metadata.provider_workflows);
                setProviderCompanies(cfdi.metadata.incident_companies);
                setIncompleteCfdi(cfdi);
                setSubmitting(false);
                return;
            }
            if (cfdi.metadata.cfdi_valid) {
                if (cfdi.metadata.non_base_valid === false) {
                    setcfdiPartiallyValid(true);
                    setCfdiData(cfdi);
                    setCfdiValidations(cfdi.metadata.validations);
                } else {
                    if (props.mode === "admin") {
                        redirectAfterUpload(cfdi, "/tenant/cfdis/owner-to-authorize/message/", "to-authorize");
                    } else {
                        redirectAfterUpload(cfdi, "/tenant/cfdis/receptor-to-authorize/message/", "to-authorize");
                    }
                }
            } else {
                setCfdiValidations(cfdi.metadata.validations);
                setCfdiData(cfdi);
            }
        }).catch((error) => {
            if (error.code === 4604) {
                setError(error.message);
            } else {
                setError(ReactHtmlParser(error.message));
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const redirectAfterUpload = (cfdi: Cfdi, link: string, referer: string) => {

        if (cfdi.metadata.type === "CREDIT_NOTE") {
            if (cfdi.metadata.status === "APPLIED" || cfdi.metadata.status === "DELIVERED_ERP") {
                setRedirect(`${link}${translate("cfdis.credit_notes.success", { "comprobante": cfdi.metadata.cfdi_related_to_credit_note }) as string}`);
            } else {
                setRedirect(`${link}${translate("cfdis.credit_notes.failed") as string}`);
            }
            return;
        }

        const nextRedirec = `/cfdis/${cfdi.id}/details?referer=${referer}`;
        if ("CFDI" === cfdi.type && "INVOICE" === cfdi.metadata.type) {
            restrictedToAssociateAdvancePayments(cfdi.metadata.tenant_id, cfdi.id).then(() => {
                setCfdiData(cfdi);
                setOpenAssociateAdvancePayments(true);
                setHelperToNextRedirect(nextRedirec);
            }).catch(() => {
                setRedirect(nextRedirec);
            });
        } else {
            setRedirect(nextRedirec);
        }
    }

    const callCreateCfdiAdmin = () => {
        callCreateAdminCfdi("", "");
        setHasExpiredExpedient(false);
    }

    const buttonAcceptedPaymentOverdue = () => {
        return (<Button onClick={callCreateCfdiAdmin} variant="outlined" color="primary">
            {translate("buttons.accept") as string}
        </Button>)
    }

    const buttonAcceptedExpiredExpedient = () => {
        return (<Button onClick={callCreateCfdiAdmin} variant="outlined" color="primary">
            {translate("buttons.accept") as string}
        </Button>)
    }

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    const onCloseWorkflowSelect = () => {
        setProviderWorkflows(undefined);
        setIncompleteCfdi(undefined);
    };

    const onCloseReceptionDateValidation = () => {
        setXml(undefined);
        setXmlLabel(xmlLabel);
        setReceptionDateValidation(undefined);
    }

    const onReceptionDateValidationContinue = () => {
        setReceptionDateValidation(undefined);
    }

    const onCloseSendPaymentCfdiToReview = () => {
        setSelectablePayments(undefined);
        setPaymentCfdiAttacment(undefined);
    }

    const onSentPaymentCfdiToReview = () => {
        onCloseSendPaymentCfdiToReview();
        setRedirect(`/payment/pending-cfdi/success/${translate("payment.upload.success_sent_to_review") as string}`);
    }

    const onCloseAttachPaymentCfdisPopup = () => {
        setAttachPaymentCfdisResponse(undefined);
    }

    const onClosedError = () => {
        setErrorPartiallity(undefined);
    };

    const onChangeSE = () => {
        setOnlySe(onlySe === false ? true : false);
    };

    const onConfirmIgnoreValidateAmountsPO = () => {
        setOpenConfirmValidateAmount(false);
        setWorkflowId("");
        setCompanyId("");
        submitAsProvider(workflowId, companyId, "IGNORE");
    }

    const onCancelIgnoreValidateAmountsPO = () => {
        setOpenConfirmValidateAmount(false);
        setWorkflowId("");
        setCompanyId("");
    }

    const onAssociateAdvancePayments = (items: AdvancePayment[]) => {
        if (!!items && cfdiData) {
            associateAdvancePayments(cfdiData.metadata.tenant_id, cfdiData.id, { advance_payments: items })
                .then((response) => {
                    setCfdiData(response);
                    setRedirect(helperToNextRedirect);
                })
                .catch((error) => {
                    setError(error.message);
                    setRedirect(helperToNextRedirect);
                })
                .finally(() => setOpenAssociateAdvancePayments(false));
        }
    }
    const onCancelAssociateAdvancePayments = () => {
        setOpenAssociateAdvancePayments(false);
        setRedirect(helperToNextRedirect);
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("cfdis.new_cfdi")} icon={<SendCfdiIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center" spacing={3}>
                                <Grid item xs={12}>
                                    <Dropzone onDrop={onDropXmlZone} accept={["application/xml", ".xml"]} multiple={props.mode === 'payment' ? true : false} disabled={submitting}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} style={{ height: 50, borderWidth: 1, borderColor: "lightgray", borderStyle: "dashed", paddingLeft: 10 }}>
                                                <input {...getInputProps()} />
                                                <p>{uploadXmlLabel}</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Grid>
                                <Grid item xs={12}>
                                    <Dropzone onDrop={onDropPdfZone} accept={["application/pdf", ".pdf"]} multiple={props.mode === 'payment' ? true : false} disabled={submitting}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} style={{ height: 50, borderWidth: 1, borderColor: "lightgray", borderStyle: "dashed", paddingLeft: 10 }}>
                                                <input {...getInputProps()} />
                                                <p>{uploadPdfLabel}</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Grid>
                                {pdfLocation && (
                                    <Grid item xs="auto">
                                        <a href={pdfLocation} target="_blank" rel="noopener noreferrer">
                                            <small>{translate("buttons.view_document")}</small>
                                        </a>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} sm="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate("buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onCloseWarning} />

                        {cfdiValidations && cfdiData && (
                            <CfdiValidationsPopup data={cfdiValidations} cfdiPartiallyValid={cfdiPartiallyValid} cfdiData={cfdiData} onClose={onCloseCfdiValidationsPopup} />
                        )}
                        {showPaymentCfdiValidation && paymentCfdiAttacment && props.mode === "payment" && (
                            <CfdiPaymentValidationsPopup
                                hasPaymentCfdiReview={hasPaymentCfdiReview}
                                data={paymentCfdiAttacment}
                                onRequestPaymentCfdiReview={onRequestPaymentCfdiReview}
                                onClose={onCloseCfdiPaymentValidationsPopup} />
                        )}
                        {selectablePayments && paymentCfdiAttacment?.validated_payments && (
                            <PaymentCfdiRequestReviewPopup
                                xml={paymentCfdiXmls![0]}
                                pdf={paymentCfdiPdfs![0]}
                                paymentsInCfdi={paymentCfdiAttacment.validated_payments}
                                selectablePayments={selectablePayments}
                                onClose={onCloseSendPaymentCfdiToReview}
                                onSentToReview={onSentPaymentCfdiToReview} />
                        )}
                        <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />

                        {hasOverdue && (
                            <DialogPopup open
                                title={translate("cfdis.popup.validation_cfdi_payment_overdue.title") as string}
                                closeText={translate("buttons.cancel") as string}
                                onClose={onCloseValidationCfdiPaymentOverdue}
                                button={buttonAcceptedPaymentOverdue()}
                                closeColor="default" >
                                <Typography variant="body2">
                                    {translate("cfdis.popup.validation_cfdi_payment_overdue.description_tenant", { "overdue": cfdiPaymentOverdue ? cfdiPaymentOverdue.cfdi_payment_overdue : 0 }) as string}

                                </Typography>
                            </DialogPopup>
                        )}
                        {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} isCreditNote />
                            : undefined}
                        {receptionDateValidation &&
                            <ConfirmationPopup
                                title={translate("cfdis.popup.validation.types.RECEPTION_DATE") as string}
                                message={translate(`cfdis.popup.validation.codes.${receptionDateValidation.code}`, translateParams(receptionDateValidation.params) as any) as string}
                                secondary={receptionDateValidation.tenant_side ? translate("cfdis.popup.validation.tenant_upload_question") as string : undefined}
                                onClose={onCloseReceptionDateValidation}
                                button={receptionDateValidation.tenant_side ? translate("buttons.continue") as string : undefined}
                                doAction={receptionDateValidation.tenant_side ? onReceptionDateValidationContinue : undefined} />
                        }
                        {hasExpiredExpedient && (
                            <DialogPopup open
                                title={translate("cfdis.popup.validation_expedient.title") as string}
                                closeText={translate("buttons.cancel") as string}
                                onClose={onCloseValidationExpiredExpedient}
                                button={buttonAcceptedExpiredExpedient()}
                                closeColor="default" >
                                <Typography variant="body2">
                                    {translate("cfdis.popup.validation_expedient.description_tenant") as string}

                                </Typography>
                            </DialogPopup>
                        )}
                        {(providerWorkflows || providerCompanies) && incompleteCfdi &&
                            <CfdiAsignWorkflowPopUp
                                workflows={providerWorkflows || []}
                                companies={providerCompanies || []}
                                isChangeWorkflow={false}
                                optionalWorkflow={props.mode === "cfdi-issued"}
                                onCompleted={onSelectedWorkflow}
                                cfdi={incompleteCfdi}
                                confirmChange={false}
                                onClose={onCloseWorkflowSelect} />
                        }
                        {attachPaymentCfdisResponse &&
                            <AttachPaymentCfdisPopup
                                data={attachPaymentCfdisResponse}
                                onClose={onCloseAttachPaymentCfdisPopup} />
                        }
                        {errorPartiallity && (
                            <DialogPopup open
                                title={translate("purchase_orders.current_partiallity_message.title") as string}
                                closeText={translate("buttons.accept") as string}
                                onClose={onClosedError}
                                closeColor="default" >
                                <Typography variant="body2">
                                    {errorPartiallity}
                                </Typography>
                            </DialogPopup>
                        )}
                        {providerRepitedSe && (
                            <DialogPopup open
                                title={translate("providers.provider_se.se_title") as string}
                                button={
                                    <Button onClick={onSubmitWhenProviderSE} variant="contained" color="primary" size="medium" disabled={submitting}>
                                        {translate("buttons.accept") as string}
                                    </Button>}
                                closeText={translate("buttons.cancel") as string}
                                onClose={() => { setProviderRepitedSe(false) }}
                                closeColor="default" >
                                <Grid>
                                    <SimpleSwitch
                                        value="only_se"
                                        label={translate("providers.provider_se.message") as string}
                                        checked={onlySe}
                                        onChanged={onChangeSE}
                                        placement="end"
                                        disabled={false} />
                                </Grid>
                            </DialogPopup>
                        )}
                        {openConfirmValidateAmount &&
                            <ConfirmationPopup
                                title={translate("purchase_orders.confirm_validate_amount.title") as string}
                                message={translate("purchase_orders.confirm_validate_amount.message") as string}
                                secondary={translate("purchase_orders.confirm_validate_amount.secondary") as string}
                                onClose={onCancelIgnoreValidateAmountsPO}
                                button={translate("buttons.continue") as string}
                                doAction={onConfirmIgnoreValidateAmountsPO} />
                        }
                        {openAssociateAdvancePayments && cfdiData && (
                            <AssociateAdvancePayments data={cfdiData} onClose={onCancelAssociateAdvancePayments} onAccept={onAssociateAdvancePayments} />
                        )}
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );

}
