import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomersIcon from "@material-ui/icons/Apartment";
import AddIcon from "@material-ui/icons/Add";

import { listCustomers, exportCustomers, exportSelectedCustomers, importCustomers } from "../api/CustomerAPI";
import { AppContext } from "../context/AppContext";
import { Customer, Customers, CustomersQueryParams } from "../model/Customer";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import CustomerMenu from "./CustomerMenu";
import DateFormat from "../components/DateFormat";
import { Family } from "../model/Connector";
import { ExportableObjectListCallbacks, ExportableObjectNameColumnTitle, ExportableObjectNameColumnValue } from "../components/ExportableObjectColumns";
import ExportableComponent from "../exportable/ExportableComponent";

export default function CustomersList() {
    const context = useContext(AppContext);
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Customers>();
    const [workingParams, setWorkingParams] = useState<CustomersQueryParams>({
        search: search,
    } as CustomersQueryParams);

    const [customer, setCustomer] = useState<Customer>();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as CustomersQueryParams;
        setWorkingParams(params);

        listCustomers(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(load, [context.session, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (customer: Customer) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCustomer(customer);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCustomer(undefined);
    };

    const onClickRow = (customer: Customer) => {
        history.push(`/customers/${customer.id}/overview`);
    };

    const handleCheck = (customer: Customer) => {
        let items = selectedIds.length === 0 ? ([] as string[]) : selectedIds.join(",").split(",");
        const index = selectedIds.indexOf(customer.id);
        if (index < 0) {
            items.push(customer.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedIds(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.items.map(purchaseOrder => purchaseOrder.id);
        } else {
            items = [] as string[];
        }
        setSelectedIds(items);
    };

    const callbacks = {
        handleCheck: handleCheck,
        handleAllChecks: handleAllChecks
    } as ExportableObjectListCallbacks;

    return (
        <Pagination title={translate("customers.title")} icon={<CustomersIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["CustomersRead", "CustomersCreate"]) ?
                (
                    <Grid container justify="center" alignContent="center" alignItems="center" spacing={1}>
                        {context.isGranted("CustomersCreate") && (
                            <Grid item xs="auto">
                                <Link to="/customers/new">
                                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                        <AddIcon />
                                    </Fab>
                                </Link>
                            </Grid>
                        )}
                        <Grid item xs="auto">
                            <ExportableComponent
                                tenantId={context.session?.tenant?.id || ""}
                                family={Family.CUSTOMERS}
                                selectedIds={selectedIds}
                                total={data?.total}
                                createAction="CustomersCreate"
                                getAction="CustomersRead"
                                onImported={load}
                                getImportPromise={(tenantId, file) => importCustomers(tenantId, file)}
                                getExportSelectedPromise={(tenantId, connectorId) => exportSelectedCustomers(tenantId, connectorId, selectedIds)}
                                getExportWithParamsPromise={(tenantId, connectorId) => exportCustomers(tenantId, connectorId, workingParams)}
                            />
                        </Grid>
                    </Grid>
                ) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("customers.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("customers.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: (
                            <ExportableObjectNameColumnTitle
                                total={data?.items.length || 0}
                                selected={selectedIds.length}
                                callbacks={callbacks} />
                        ),
                        converter: (customer) => (
                            <ExportableObjectNameColumnValue
                                exportableObject={customer}
                                checked={selectedIds.indexOf(customer.id) >= 0}
                                callbacks={callbacks}
                            />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("customers.name") as string,
                        converter: (customer) => customer.name,
                        fullWidth: true,
                        xs: 5,
                        sm: 5,
                        md: 5,
                        lg: 5,
                        xl: 5
                    },
                    {
                        title: translate("customers.rfc") as string,
                        converter: (customer) => customer.rfc,
                        fullWidth: true,
                        xs: false,
                        sm: 3,
                        md: 3,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("customers.created_at") as string,
                        converter: (customer) => (
                            <DateFormat date={customer.created_at} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (customer) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(customer)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {customer && anchorEl &&
                <CustomerMenu
                    anchor={anchorEl}
                    customer={customer}
                    onClose={onCloseOption}
                />
            }
        </Pagination>
    );
}