import React, { useContext, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';
import { Payment } from "../model/Payment";
import RejectIcon from '@material-ui/icons/ThumbDown';
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';

interface PaymentPendingCfdiMenuProps {
    payment: Payment;
    anchor: HTMLElement | null;
    inner?: boolean;
    referer: string;
    onDelete(): any;
    onClose(): any;
    enableRejectPaymentOption? : boolean;
}

export default function PaymentPendingCfdiMenu(props: PaymentPendingCfdiMenuProps) {
    const context = useContext(AppContext);
    

    useEffect(() => {
       
    }, [context.session, props.payment.id]);

    return (
        <Menu
            id="cfdi-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["PaymentsUpdate"]) && !props.inner &&
                <MenuItem button component={Link} to={`/payment-details/${props.payment.id}/`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <DetailsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["PaymentsRead"]) && !props.inner && props.payment.status === "REJECTED_PAYMENT_CFDI" &&
                <MenuItem button component={Link} to={`/payment-review/${props.payment.payment_cfdi.id}/`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <RateReviewTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_rejected_details")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["PaymentsDelete"]) && !props.inner && props.enableRejectPaymentOption &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <RejectIcon fontSize="small" color="primary"/>
                    </ListItemIcon>
                    <Typography variant="inherit" >{translate("payment.add_comment.title_reject")}</Typography>
                </MenuItem>
            }
        </Menu>);
}