import { Catalog } from "../model/Catalog"

export interface Workflove {
    id: string
    tenant_id: string
    product_id: string
    name: string
    description?: string
    external_id?: string
    template_id?: string
    status: WorkfloveStatus
    transitions?: Transition[]
    created_at: Date
}

export interface StageActors {
    stage_id: string
    _stage_name?: string
    _stage_external_id?: string
    actors: ActorFields[]
}

export interface ActorFields {
    actor_id: string
    _actor_name?: string
    _checked?: boolean
    fields: FieldPermission[]
}

export interface FieldPermission {
    field_id: string
    _field_name?: string
    _field_description?: string
    read: boolean
    write: boolean
    required: boolean
}

export interface TransitionStage {
    stage_id: string
    status: string
}

export interface TransitionOrigin extends TransitionStage {
    root?: boolean
    expression?: string
}

export interface Transition {
    origin: TransitionOrigin
    forward?: TransitionRule
    backward?: TransitionRule
}

export interface TransitionRule {
    type: TransitionRuleType
    stage?: TransitionStage
    expressions?: TransitionExpression[]
}

export interface TransitionExpression {
    expression: string
    stage: TransitionStage
}

export type WorkfloveStatus = 'NEW' | 'AVAILABLE' | 'DISABLED'

export const WORKFLOVE_STATUS: WorkfloveStatus[] = [
    'NEW',
    'AVAILABLE',
    'DISABLED',
]

export type TransitionRuleType = 'SINGLE' | 'ADVANCED'

export const TRANSTION_TYPES: TransitionRuleType[] = ['SINGLE', 'ADVANCED']

export interface TemplateMetadata {
    types: FieldType[];
}

export interface FieldType {
    id: string;
    name?: string;
    external_id?: string;
    description?: string;
    custom: boolean;
}

export interface Template {
    id?: string;
    tenant_id: string;
    product_id: string;
    customer_id: string;
    name: string;
    description?: string;
    product?: string;
    fields: TemplateField[];
    created_at: Date;
}

export interface TemplateField {
    id: string;
    name?: string;
    external_id?: string;
    description?: string;
    extensions?: string;
    type: string;
    type_name?: string;
    by_each_payment?: boolean;
    url_value_default?: string;
    url_text_default?: string;  
    expression_id?: string;

}

export interface WorkflowTemplate {
    id: string;
    tenant_id: string;
    product_id: string;
    workflow_id: string;
    customer_id: string;
    template_id: string;
    external_id: string;
    stage_actors: StageActors[];
    created_at: Date;
    template?: Template;
    workflow?: Workflove;
}

export interface ActorFields {
    actor_id: string;
    fields: FieldPermission[];
}

export interface StageStatus {
    name: string;
    enabled: boolean;
}

export interface Stages {
    items: Stage[];
    total: number;
}

export interface Stage {
    id: string;
    tenant_id: string;
    product_id: string;
    name: string;
    external_id: string;
    order: number;
    available_for_template: boolean;
    statuses: StageStatus[];
    created_at: Date;
}

export interface Actors {
    items: Actor[];
    total: number;
}

export interface Actor {
    id: string;
    tenant_id: string;
    product_id: string;
    name: string;
    external_id: string;
    created_at: Date;
}

export interface UpdateWorkflowRequest {
    description?: string;
    external_id?: string;
    stage_actors: StageActors[];
}

export const calculateStageRoles = (
    stages: Stages,
    actors: Actors,
    workflowTemplate: WorkflowTemplate,
    catalogs: Catalog[],
): StageActors[] => {
    if (!workflowTemplate.template) return [];

    const transitions = workflowTemplate.workflow?.transitions ?? [];
    const availableStageIds =
        stages?.items
            .filter((el) => el.available_for_template)
            .map((el) => el.id) || []
    const stageIds = (
        transitions
            .map((el) => el.origin)
            .map((el) => el.stage_id) || []
    )
        .filter((v, i, a) => a.findIndex((t) => t === v) === i)
        .filter((el) => availableStageIds.indexOf(el) >= 0)

    const filtered =
        stages?.items.filter((stage) => {
            return stageIds.findIndex((el) => el === stage.id) >= 0
        }) || []

    return filtered.map((stage) => {
        const actorFields = actors?.items.map((actor) => {
            const fields = workflowTemplate.template?.fields.map((field) => {
                const stageActors = workflowTemplate.stage_actors?.filter((el) => el.stage_id === stage.id) ?? [];
                const current = stageActors.flatMap((el) => el.actors)
                    .filter((el) => el.actor_id === actor.id)
                    .flatMap((el) => el.fields)
                    .find((el) => el.field_id === field.id)
                const cat = catalogs.find(catalog => catalog.id === field.external_id);
                let name;
                if(cat){
                    name = cat.name;
                }
                if (current) {
                    return {
                        ...current,
                        _field_name: name ?? field.name,
                        _field_description: field.description,
                    }
                }

                return {
                    field_id: field.id || '-',
                    _field_name: name ?? field.name,
                    _field_description: field.description,
                    read: false,
                    write: false,
                    required: false,
                } as FieldPermission
            })

            let checked = false;
            if (fields && fields.length > 0) {
                checked = fields.filter(el => el.read && el.write && el.required).length === fields.length;
            }

            return {
                actor_id: actor.id,
                _actor_name: actor.name,
                _checked: checked,
                fields: fields || [],
            } as ActorFields
        })

        return {
            stage_id: stage.id,
            _stage_name: stage.name,
            _stage_external_id: stage.external_id,
            actors: actorFields || [],
        } as StageActors
    });
}
