import React, { useContext } from 'react';
import NumberFormat from "react-number-format";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box, Card, Divider, Grid, List, ListItem, ListSubheader, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import queryString from "query-string";

import translate from "../../i18n/Translator";
import { Entity } from "../../model/Provider";
import { isRoleOrParent } from "../../model/Role";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box_variant1: {
            fontSize: "0.875rem",
            color: "#363636",
        },
        box_variant2: {
            fontSize: "0.875rem",
            color: "#1f679f",
        },
        box_variant3: {
            fontSize: "0.875rem",
            color: "#f69450",
        },
        box_variant4: {
            fontSize: "0.875rem",
            color: "#42a047",
        },
        link: {
            color: "#3d94c5",
            fontSize: "0.7rem",
            textAlign: "left",
        }
    }),
);

interface ProviderAccountingBalanceProps {
    provider: Entity;
    card_mode: boolean;
}

export default function ProviderAccountingBalance(props: ProviderAccountingBalanceProps) {
    const context = useContext(AppContext);
    const isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const classes = useStyles();
    const accounting_balance = props.provider.accounting_balance;

    const common_params = {
        "start_date": String(new Date().getMonth() + 1).padStart(2, '0') + "-01-" + String(new Date().getFullYear() - 5),
        "end_date": String(new Date().getMonth() + 2).padStart(2, '0') + "-01-" + new Date().getFullYear(),
        "type": "ALL"
    } as any;

    const authorizing_totals_tenant_params = {
        ...common_params,
        "status_list": "IN_RECEPTION,REJECTED_BY_ADMIN,REJECTED,REJECTED_BY_CXP,IN_REVIEW,PENDING_PAYMENT,PAYMENT_POSTPONE,PAYMENT_REJECTED,DELIVERED_ERP",
        "search": props.provider.rfc,
        "provider_id": props.provider.id
    } as any;

    const paying_totals_tenant_params = {
        ...common_params,
        "status_list": "IN_PAYMENT_ORDER",
        "search": props.provider.rfc,
        "provider_id": props.provider.id
    } as any;

    const debt_totals_provider_params = {
        ...common_params,
        "status_list": "IN_RECEPTION,REJECTED_BY_ADMIN,REJECTED,REJECTED_BY_CXP,IN_REVIEW,PENDING_PAYMENT,PAYMENT_POSTPONE,PAYMENT_REJECTED,DELIVERED_ERP,IN_PAYMENT_ORDER",
    } as any;

    const debt_totals_tenant_params = {
        ...common_params,
        "status_list": "IN_RECEPTION,IN_REVIEW,REJECTED_BY_ADMIN,PENDING_PAYMENT,IN_PAYMENT_ORDER,DELIVERED_ERP",
        "search": props.provider.rfc,
        "provider_id": props.provider.id
    } as any;

    const payed_totals_tenant_params = {
        "payment_status": "NO_NEED_PAYMENT_CFDI,PENDING_PAYMENT_CFDI,IN_REVIEW_PAYMENT_CFDI,PAYMENT_CFDI_ATTACHED,DELIVERED_ERP,REJECTED_PAYMENT_CFDI",
        "search": props.provider.rfc,
        "provider_id": props.provider.id
    }

    function createContent() {
        return <Box px={2} pb={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                <Grid item lg={2}>
                    <AmountsBox category={translate("providers.accounting_balance.authorizing_totals") as string}
                        cfdis_number={accounting_balance.authorizing_totals_counter}
                        amounts={accounting_balance.authorizing_totals}
                        color={classes.box_variant3}
                        url={(isProvider ? '/provider/cfdis/sent?' : `/tenant/cfdis/archive-tenant?`) + `${queryString.stringify(authorizing_totals_tenant_params)}`} />
                </Grid>
                <Grid item lg={2}>
                    <AmountsBox category={translate("providers.accounting_balance.paying_totals") as string}
                        cfdis_number={accounting_balance.paying_totals_counter}
                        amounts={accounting_balance.paying_totals}
                        color={classes.box_variant2}
                        url={isProvider ? '/provider/cfdis/sent' : `/tenant/cfdis/archive-tenant?${queryString.stringify(paying_totals_tenant_params)}`} />
                </Grid>
                <Grid item lg={2}>
                    <AmountsBox category={translate("providers.accounting_balance.debt_totals") as string}
                        cfdis_number={accounting_balance.debt_totals_counter}
                        amounts={accounting_balance.debt_totals}
                        color={classes.box_variant1}
                        url={isProvider ? `/provider/cfdis/archive-provider?referer_view=ARCHIVE&${queryString.stringify(debt_totals_provider_params)}`
                            : `/tenant/cfdis/archive-tenant?${queryString.stringify(debt_totals_tenant_params)}`} />
                </Grid>
                <Divider orientation="vertical" flexItem variant="middle" />
                <Grid item lg={2}>
                    <AmountsBox category={translate("providers.accounting_balance.payed_totals") as string}
                        urlLabel={translate("providers.accounting_balance.see_payments") as string}
                        cfdis_number={accounting_balance.payed_totals_counter}
                        amounts={accounting_balance.payed_totals}
                        color={classes.box_variant4}
                        url={isProvider ? '/payment/archived' : `/payment/archived?${queryString.stringify(payed_totals_tenant_params)}`} />
                </Grid>
            </Grid>
        </Box>;
    }

    function getCompaniesAsigned() {
        return <Box px={2} pb={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                <Grid item xs={10}>
                    {props.provider.company_objects && props.provider.company_objects.map((c) => c.name).map((name: string, index: number) => (
                        <Typography variant="body2">{name}</Typography>
                    ))}
                </Grid>
            </Grid>
        </Box>;
    }

    if (props.card_mode) {
        return (
            <Card variant="outlined" >
                <ListSubheader>{translate("providers.companies.asigned_companies")}</ListSubheader>
                {getCompaniesAsigned()}
                <ListSubheader>{translate("providers.accounting_balance.title")}</ListSubheader>
                {createContent()}
            </Card>
        );
    } else {
        return createContent();
    }
}

interface AmountsBoxProps {
    cfdis_number: number;
    amounts: Map<String, number>;
    category: String;
    color: string;
    url: string;
    urlLabel?: string
}

function AmountsBox(props: AmountsBoxProps) {

    const classes = useStyles();

    return (
        <Box>
            <Grid container justify={"space-between"} alignItems="center">
                <Grid item xs={12}>
                    <Typography align="left" variant="inherit" color="textSecondary" noWrap={true}>{`${props.category} (${props.cfdis_number})`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link to={props.url}>
                        <Typography className={classes.link} noWrap={true}>{props.urlLabel ?? translate('buttons.view_invoices') as string}</Typography>
                    </Link>
                </Grid>
            </Grid>
            <List component="nav" dense disablePadding >
                {props.amounts ? Object.keys(props.amounts).map((key) => <>
                    <ListItem key={key} disableGutters>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Typography className={props.color} ><strong>{key}</strong></Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <Typography className={props.color} align="right" >
                                    <strong><NumberFormat value={(props.amounts as any)[key] as number}
                                        prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" /></strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                </>)
                    : undefined}
            </List>
        </Box>
    );
}