import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetAppTwoTone";
import PaymentIcon from "@material-ui/icons/PaymentTwoTone";
import WorkIcon from "@material-ui/icons/WorkTwoTone";
import VisibilityIcon from "@material-ui/icons/VisibilityTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Billing } from "../model/Billing";

interface BillingMenuProps {
    billing: Billing;
    anchor: HTMLElement | null;
    onClose(): any;
    onDownloadAttachment(): any;
    onAuthorize(): any;
    onView(): any;
}

export default function BillingMenu(props: BillingMenuProps) {
    const context = useContext(AppContext);
    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("BillingsRead") &&
                <MenuItem button onClick={props.onView} dense>
                    <ListItemIcon>
                        <VisibilityIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_billing")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TenantsBillingsCreate", "TenantsBillingsUpdate"]) && props.billing.status !== "DONE" &&
                <MenuItem button component={Link} to={`/billings/${props.billing.id}/manual`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <PaymentIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.pay")}</Typography>
                </MenuItem>
            }
            <MenuItem button onClick={props.onDownloadAttachment} dense disabled={!props.billing.attachment}>
                <ListItemIcon>
                    <GetAppIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.download")}</Typography>
            </MenuItem>
            {context.isGrantedAny(["BillingsUpdate"]) &&
                <MenuItem button onClick={props.onAuthorize} dense disabled={props.billing.status !== "REVIEW"}>
                    <ListItemIcon>
                        <PaymentIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.authorize_payment")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TenantsRead"]) && props.billing.tenant &&
                <MenuItem button component={Link} to={`/tenants?search=${props.billing.tenant.rfc}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <WorkIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_tenant")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}