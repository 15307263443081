import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Efos } from "../model/Efos";
import ProviderVerificationEfosResult from "./ProviderVerificationEfosResult"
import ProviderVerificationCff69Result from "./ProviderVerificationCff69Result";
import { BlackListValidations } from "../model/Cff69";

interface ProviderVerificationPopupProps {
    rfc: string;
    name?: string;
    efos?: Efos;
    validations?: BlackListValidations;
    onClose(): any;
}

export default function ProviderVerificationPopup(props: ProviderVerificationPopupProps) {
    return (
        <DialogPopup title={translate("providers.verification_result")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            <Grid container justify="space-between" alignContent="center">
                <Grid item xs={12}>
                    {props.name && (
                        <Typography variant="h6">{props.name}</Typography>
                    )}
                    <Typography variant={props.name ? "body2" : "h6"} paragraph>{props.rfc}</Typography>
                </Grid>
            </Grid>
            <Grid container justify="space-between" alignContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            {props.validations && (<ProviderVerificationCff69Result validations={props.validations} />)}
            {props.efos && props.validations && (
                <Grid container justify="space-between" alignContent="center" spacing={2}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            )}
            {props.efos && (<ProviderVerificationEfosResult efos={props.efos} />)}
        </DialogPopup>
    );
}
