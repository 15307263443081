import React, { useContext, useState } from "react";
import { Menu, ListItemIcon, Fade, Grid, ListItem, ListItemText, List } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import AddIcon from "@material-ui/icons/AddTwoTone";

interface ForeignPayableDocumentMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onForeign?(): any;
    onForeignCreditNote?(): any;
}

export default function ForeignPayableDocumentMenu(props: ForeignPayableDocumentMenuProps) {
    const context = useContext(AppContext);
    const [openForeignCfdis, setOpenForeignCfdis] = useState<boolean>(false);

    const handleClickForeigns = () => {
        setOpenForeignCfdis(!openForeignCfdis);
    };

    return (
        <Menu
            id="new-payable-document-foreign-menu"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Fade}>
            {context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate", "AdminCFDIsCreate", "ReceptorCFDIsCreate", "CxpCFDIsCreate"]) &&
                    <Grid item xs="auto" >
                <Grid >
                <Grid item xs="auto" >
                    <ListItem button onClick={handleClickForeigns} dense>
                        <ListItemIcon>
                            <AddIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("payable_documents.foreign")} />
                        {openForeignCfdis ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Grid>
                    <Collapse in={openForeignCfdis} timeout="auto" unmountOnExit>
                        <List component="div"  >
                            <ListItem button onClick={props.onForeign} dense >
                                <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                    <AddIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={translate("cfdis.history.cfdi_type.INVOICE")} />
                            </ListItem>
                            <ListItem button onClick={props.onForeignCreditNote} dense >
                                <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                    <AddIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={translate("cfdis.history.cfdi_type.CREDIT_NOTE")} />
                            </ListItem>
                        </List>
                    </Collapse>
                </Grid>
            </Grid>
            }
        </Menu>);
}
