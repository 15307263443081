import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DownloadXmlIcon from "@material-ui/icons/CodeTwoTone";
import DownloadPdfIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import ChangeReceptor from '@material-ui/icons/CompareArrowsTwoTone';

import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import DetailsIcon from "@material-ui/icons/VisibilityTwoTone";
import MonetizationIcon from '@material-ui/icons/MonetizationOnTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';

import { getTenantCfdiFileUrl } from "../../api/TenantCfdiApi";
import { getCoverUrl } from "../../api/ExpensesAccountAPI";
import { Cfdi, isAllowToDelete, isAllowChangeWorkflowTenant, isAllowSetAdditionalAmountTenant, isDisabledCancelTenant, CfdiStatus_PAYMENT_POSTPONE, CfdiStatus_PENDING_PAYMENT } from "../../model/Cfdi";
import BlockIcon from '@material-ui/icons/Block';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import AuthorizedIcon from "@material-ui/icons/LibraryAddCheckTwoTone";
import WorkIcon from '@material-ui/icons/WorkTwoTone';
import { isRoleOrParent } from "../../model/Role";
import { EditIcon } from "../../components/Icons";
import Receipt from "@material-ui/icons/Receipt";

interface TenantCfdiMenuProps {
    cfdi: Cfdi;
    anchor: HTMLElement | null;
    inner?: boolean;
    referer: string;
    isForeignByEmail?: boolean;
    onClose(): any;
    onChangeReceptor?(): any;
    onAdditionalAmount?(): any;
    onDeleteComprobante?(): any;
    onCompleteData?(): any;
    onCancel(): any;
    onPostpone?(): any;
    onReactivate?(): any;
    onChangeWorkflow?(): any;
    onDownloadFiles(): any;
    onDownloadValidationsPdf(): any;
    onEditAdvancePayment?(): any;
    onAssociateAdvancePayments?(): void;
    onUpdatePaymentDeadline?(): void;
}

export default function TenantCfdiMenu(props: TenantCfdiMenuProps) {
    const context = useContext(AppContext);
    const userId = context.session?.user?.id;
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [coverPdfUrl, setCoverPdfUrl] = useState<string>();
    const isValidator = isRoleOrParent(context.session!.role, "validator");
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");

    useEffect(() => {
        if (props.cfdi.metadata.xml_url) {
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "xml").then((urlFile) => {
                setXmlUrl(urlFile);
            }).catch((error) => {
                setXmlUrl(undefined);
            });
        }

        if (props.cfdi.metadata.pdf_url) {
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }

        if (props.cfdi.expenses_account && props.cfdi.expenses_account.cover_path) {
            getCoverUrl(context.session!.tenant!.id, props.cfdi.metadata.provider_id, props.cfdi.id).then((urlFile) => {
                setCoverPdfUrl(urlFile);
            }).catch((error) => {
                setCoverPdfUrl(undefined);
            });
        }
    }, [context.session, props.cfdi]);

    return (
        <Menu
            id="cfdi-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) && !props.inner && (
                props.isForeignByEmail ?
                    <MenuItem button onClick={props.onCompleteData!} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                    </MenuItem>
                    :
                    <MenuItem button component={Link} to={`/cfdis/${props.cfdi.id}/details?referer=${props.referer}`} onClick={props.onClose} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                    </MenuItem>)
            }
            {xmlUrl && context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={xmlUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                </MenuItem>
            }
            {pdfUrl && context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                <MenuItem button onClick={props.onDownloadFiles} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.download_cfdi")}</Typography>
                </MenuItem>
            }
            {isAllowSetAdditionalAmountTenant(props.cfdi, isValidator, props.onAdditionalAmount, isReaderOnly) &&
                <MenuItem button onClick={props.onAdditionalAmount} dense >
                    <ListItemIcon>
                        <MonetizationIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.add_additional_amount")}</Typography>
                </MenuItem>
            }
            {coverPdfUrl && context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={coverPdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("expenses_accounts.see_cover")}</Typography>
                </MenuItem>
            }
            {!isReaderOnly && props.onChangeReceptor !== undefined && context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <MenuItem button onClick={props.onChangeReceptor} dense >
                    <ListItemIcon>
                        <ChangeReceptor fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.reasign_receptor")}</Typography>
                </MenuItem>
            }
            {props?.onAssociateAdvancePayments && context.isGrantedAny(["AdvancePaymentAssociateCfdi"]) && "CFDI" === props.cfdi.type && ["SENDING", "IN_PROCESS"].includes(props.cfdi.metadata.cfdi_status_group)
                &&
                <MenuItem button onClick={props.onAssociateAdvancePayments} dense>
                    <ListItemIcon>
                        <Receipt fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("add_advance_payments.menu_option")}</Typography>
                </MenuItem>
            }
            {isDisabledCancelTenant(props.cfdi, !context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "ProviderCFDIsUpdate", "CxpCFDIsUpdate"]), isReaderOnly) ? undefined :
                <MenuItem button onClick={props.onCancel} dense>
                    <ListItemIcon>
                        <BlockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate(props.cfdi.metadata.type === "CREDIT_NOTE" ? "cfdis.cancel_cfdi.title_cn" : "cfdis.cancel_cfdi.title")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate"]) && props.onUpdatePaymentDeadline && (props.cfdi.metadata.cfdi_status_group == "IN_PROCESS" || props.cfdi.metadata.cfdi_status_group == "VALIDATION" || props.cfdi.metadata.cfdi_status_group == "RECEIVED") && props.cfdi.metadata.payment_info?.payment_deadline && 
                <MenuItem button onClick={props.onUpdatePaymentDeadline} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.update_payment_deadline.menu")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate"]) &&
                props.cfdi.metadata.status === CfdiStatus_PENDING_PAYMENT &&
                <MenuItem button onClick={props.onPostpone} dense>
                    <ListItemIcon>
                        <AccessTimeTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.postpone_payment.click_menu")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate"]) &&
                props.cfdi.metadata.status === CfdiStatus_PAYMENT_POSTPONE &&
                <MenuItem button onClick={props.onReactivate} dense>
                    <ListItemIcon>
                        <AuthorizedIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.postpone_payment.reactivate")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsDelete", "ReceptorCFDIsDelete", "CxpCFDIsDelete"])
                && isAllowToDelete(props.cfdi) && !(context.isGranted("ReceptorCFDIsDelete") && props.cfdi.metadata.status === CfdiStatus_PENDING_PAYMENT) &&
                <MenuItem button onClick={props.onDeleteComprobante} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.delete_cfdi")}</Typography>
                </MenuItem>
            }
            {props.inner && isAllowChangeWorkflowTenant(props.cfdi.metadata, userId) && context.isGrantedAny(["ProviderCFDIsUpdate", "AdminCFDIsUpdate", "ReceptorCFDIsUpdate"])
                && isAllowToDelete(props.cfdi) &&
                <MenuItem button onClick={props.onChangeWorkflow} dense>
                    <ListItemIcon>
                        <WorkIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("workflows.cfdi_workflow.change")}</Typography>
                </MenuItem>
            }
            {"FOREIGN_CREDIT_NOTE" !== props.cfdi.type && context.isGrantedAny(["AdminCFDIsRead", "ProviderCFDIsRead", "ReceptorCFDIsRead",
                "SenderCFDIsRead", "CxpCFDIsRead", "CfdisIssuedRead", "TenantCfdisIssuedRead", "ProviderCfdisIssuedRead"])
                && <MenuItem button onClick={props.onDownloadValidationsPdf} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.validations_pdf")}</Typography>
                </MenuItem>
            }
            {props.inner && props.cfdi.type === 'ADVANCE_PAYMENT' && props.cfdi.metadata.cfdi_status_group === "IN_PROCESS" && props.cfdi.metadata.user_uploader_id === context.session!.user.id && context.isGranted('AdvancePaymentUpdate') &&
                <MenuItem button onClick={props.onEditAdvancePayment} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.edit_advance_payment")}</Typography>
                </MenuItem>
            }
        </Menu>);
}
