import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";

import { getSignupConfirmation, confirmSignup } from "../api/AuthAPI";
import { SetupPasswordRequest } from "../model/SignUp";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ConfirmPasswordValidator } from "../components/Validators";
import { ErrorSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";
import GoHome from "../components/GoHome";
import ExternSurface from "../components/ExternSurface";

export default function ConfirmTenant({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [email, setEmail] = useState<string>();
    const [tenantName, setTenantName] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [request, setRequest] = useState<SetupPasswordRequest>({
        password: "",
        confirm_password: "",
    } as SetupPasswordRequest);
    const [validations, setValidations] = useState({
        "password": {
            valid: false
        } as InputRef,
        "confirm_password": {
            valid: false
        } as InputRef
    } as any);
    const confirmPasswordValidator = new ConfirmPasswordValidator(() => request.password);

    useEffect(() => {
        setStatus("loading");
        getSignupConfirmation(match.params.code).then((response) => {
            setEmail(response.user.email);
            setTenantName(response.name);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [match.params.code]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });

        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        confirmSignup(match.params.code, request).then((_) => {
            context.onSignedIn();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
    };

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loaded") {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface title={translate("auth.confirm.title") as string} description={translate("auth.confirm.text", { "tenant": tenantName }) as string}>
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <input type="hidden" id="email" name="email" value={email} />
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="password" id="password" name="password"
                            value={request.password} label={translate("users.password") as string}
                            required={true} disabled={submitting}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="password" id="confirm_password" name="confirm_password"
                            value={request.confirm_password} label={translate("users.confirm_password") as string}
                            validator={confirmPasswordValidator}
                            required={true} disabled={submitting}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12} className="ExternButton">
                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                            {translate("auth.confirm.button")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </ExternSurface>
    );

}