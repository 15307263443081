import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import { MoreVertIcon, AddIcon } from "../components/Icons";
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';

import { listCostCenters, deleteCostCenter } from "../api/CostCenterAPI";
import { AppContext } from "../context/AppContext";
import { CostCenter, CostCenters, CostCentersQueryParams } from "../model/CostCenter";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import CostCenterMenu from "./CostCenterMenu";

export default function CostCenterList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CostCenters>();
    const [workingParams, setWorkingParams] = useState<CostCentersQueryParams>({
        search: search,
    } as CostCentersQueryParams);

    const [costCenter, setCostCenter] = useState<CostCenter>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [isCreateGranted] = useState(context.isGranted("CostCenterCreate"));

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as CostCentersQueryParams;
        setWorkingParams(params);

        listCostCenters(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (costCenter: CostCenter) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCostCenter(costCenter);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onDelete = () => {
        if (!costCenter) return;

        setAnchorEl(null);
        setPopup("delete");
    };

    const onConfirmDelete = () => {
        onClosePopup();
        if (!costCenter) return;

        setSubmitting(true);
        deleteCostCenter(tenantId, costCenter.id).then(() => {
            setSuccess(
                translate("cost_centers.delete.success", { name: costCenter.name }) as string
            );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };


    const onCloseOption = () => {
        setAnchorEl(null);
        setCostCenter(undefined);
    };

    const onClickRow = (costCenter: CostCenter) => {
        history.push(`/cost-centers/${costCenter.id}/edit`);
    };

    return (
        <Pagination title={translate("cost_centers.title")} icon={<CenterFocusWeakIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Link to="/cost-centers/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justifyContent="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("cost_centers.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cost_centers.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("cost_centers.erp_id") as string,
                        converter: (costCenter: CostCenter) => costCenter.erp_id
                        ,
                        xs: true
                    },
                    {
                        title: translate("cost_centers.name") as string,
                        converter: (costCenter: CostCenter) => costCenter.name,
                        xs: false,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("cost_centers.category") as string,
                        converter: (costCenter: CostCenter) =>  costCenter.category,
                        xs: false,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (costCenter: CostCenter) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(costCenter)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        xs: "auto"
                    }
                ]} />
            {costCenter && anchorEl &&
                <CostCenterMenu costCenter={costCenter} anchor={anchorEl} onDelete={onDelete} onClose={onCloseOption} />
            }
            {costCenter && popup === "delete" && (
                <DialogPopup
                    open
                    title={translate("cost_centers.delete.title")}
                    onClose={onClosePopup}
                    closeColor="default"
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="secondary"
                            disabled={submitting}
                        >
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("cost_centers.delete.text", { name: costCenter.name })}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}