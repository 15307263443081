import React from "react";
import { Typography, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import { Cfdi } from "../model/Cfdi";
import Ellipsis from "../components/Ellipsis";
import NumberFormat from 'react-number-format';
import DialogPopup from "./DialogPopup";

interface CfdisCancelledPopupProps {
    isCreditNote?: boolean;
    cfdisInvalid: Cfdi[];
    onClose(): any;
}

export default function CfdisCancelledPopup(props: CfdisCancelledPopupProps) {
    const getType = (cfdi: Cfdi) => {
        const pdType = translate(`payable_documents.type.${cfdi.type}`) as string;
        if (cfdi.type !== "CFDI") {
            return pdType;
        }

        const cfdiType = translate(`cfdis.history.cfdi_type.${cfdi.metadata.type}`) as string;
        return `${pdType} | ${cfdiType}`;
    };
    
    return (
        <DialogPopup open
            title={translate("cfdis_cancelled.popup.title") as string}
            disableEscapeKeyDown={false}
            disableBackdropClick={false}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default" >
            <Typography variant="body2">
                {translate(props.isCreditNote ? "cfdis_cancelled.popup.text_credit_note" : "cfdis_cancelled.popup.text") as string}
            </Typography>
            <Box pt={1} pb={2}>
                <Typography variant="body2">
                    {translate("cfdis_cancelled.popup.secondary") as string}
                </Typography>
            </Box>

            <Gridable
                items={props.cfdisInvalid}
                loading={false}
                empty={translate("cfdis.empty") as string}
                columns={[
                    {
                        title: translate("cfdis.columns.folio_uuid") as string,
                        converter: (cfid) => cfid.identifier,
                        xs: true,
                        sm: true,
                        md: true,
                        xl: true
                    },
                    {
                        title: translate("cfdis.columns.type") as string,
                        converter: (cfdi) => getType(cfdi),
                        xs: true,
                        sm: true,
                        md: true,
                        xl: true
                    },
                    {
                        title: translate("cfdis.columns.provider") as string,
                        converter: (cfdi) => (
                            <Ellipsis text={cfdi.issuer.name} lenght={30} />
                        ),
                        xs: true,
                        sm: true,
                        md: true,
                        xl: true
                    },
                    {
                        title: translate("billing.amount") as string,
                        converter: (cfdi) => (
                            <Typography variant="body2" component="h6" align="right">
                                <NumberFormat value={cfdi.total} prefix="$ " suffix={` ${cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                            </Typography>
                        ),
                        justify: "flex-end",
                        xs: true,
                        sm: true,
                        md: true,
                        xl: true
                    },
                ]} />
                <Box pt={2}>
                    <Typography variant="body2">
                        {translate("cfdis_cancelled.popup.footer") as string}
                    </Typography>
                </Box>
        </DialogPopup>
    );

}