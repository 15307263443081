import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { SocialContributions, SocialContribution, SocialContributionsQueryParams, NewSocialContributionRequest, SocialContributionRequest, SocialContributionFileType,
    ToValidateSipareRequest } from "../model/SocialContribution";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/contributions/social`;

export async function listSocialContributions(tenantId: string, providerId: string, page: number, offset: number, params: SocialContributionsQueryParams): Promise<SocialContributions> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            month: params.month?.toString() || "",
            year: params.year?.toString() || "",
            status: params.status || "",
            employerNumber: params.employerNumber || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createSocialContribution(tenantId: string, providerId: string, request: NewSocialContributionRequest): Promise<SocialContribution> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getSocialContribution(tenantId: string, providerId: string, id: string): Promise<SocialContribution> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function getSocialContributionFileUrl(tenantId: string, providerId: string, id: string, fileType: SocialContributionFileType): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, providerId)}/${id}/${fileType}`);
}

export async function getSocialContributionPdfUrl(tenantId: string, providerId: string, id: string, fileType: SocialContributionFileType): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, providerId)}/${id}/${fileType}.pdf`);
}

export async function updateSocialContribution(tenantId: string, providerId: string, id: string, request: SocialContributionRequest): Promise<SocialContribution> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteSocialContribution(tenantId: string, providerId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function createSocialContributionToValidateSipare(tenantId: string, providerId: string, request: ToValidateSipareRequest): Promise<SocialContribution> {
    return await callAPI(`${basePath(tenantId, providerId)}/send-to-validate-sipare`, {
        method: HTTPMethod.POST,
        body: request
    });
}