import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import ValidatedInput, { InputRef } from "./ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "./DialogPopup";
import { WarningSnackbar } from "./Snackbars";


interface AuthorizeRejectPopupProps {
    param: "send-to-verify" | "reject" | "reject-review" | "verify" | "reject-payment-order" | "authorize-cfdi-payment-review" | "reject-cfdi-payment-review";
    onClose(): any;
    doAction(comment: string): any;
    isCommentRequired: boolean;
}

export default function AuthorizeRejectPopup(props: AuthorizeRejectPopupProps) {
    const [request, setRequest] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(props.isCommentRequired);
    const [warning, setWarning] = useState<string>();

    const onSubmit = () => {
        if (!request) {
            props.doAction("");
            return;
        }
        props.doAction(request);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
        if(props.isCommentRequired && (!request || request === "")){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
    };

    const getTitle = (param : string) => {
        if("authorize-cfdi-payment-review" === param){
            return translate("payment.payment_cfdi_in_review.authorize_cfdi_payment") as string;
        }
        if("reject-cfdi-payment-review" === param){
            return translate("payment.payment_cfdi_in_review.reject_cfdi_payment") as string;
        }
        if("send-to-verify" === param || props.param === "verify") {
            return translate("cfdis.add_comment.title.authorize") as string;
        }
        if("reject-payment-order" === param){
            return translate("payment_order.reject_title") as string;
        }
        return translate("cfdis.add_comment.title.reject") as string;
    }

    const getConfirmText = (param : string) => {
        if("authorize-cfdi-payment-review" === param){
            return translate("payment.payment_cfdi_in_review.question_authorize_cfdi_payment") as string;
        }
        if("reject-cfdi-payment-review" === param){
            return translate("payment.payment_cfdi_in_review.question_reject_cfdi_payment") as string;
        }
        if("send-to-verify" === param || props.param === "verify") {
            return translate("cfdis.add_comment.text.authorize") as string;
        }
        if("reject-payment-order" === param){
            return translate("payment_order.reject") as string;
        }
        return translate("cfdis.add_comment.text.reject") as string;
    }

    const getButtonText = (param : string) => {
        if("send-to-verify" === param || "verify" === props.param || "authorize-cfdi-payment-review" === param ) {
            return translate("cfdis.add_comment.button.authorize") as string;
        }
        return translate("cfdis.add_comment.button.reject") as string;
    }

    return (
        <DialogPopup open
            title={getTitle(props.param)}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={disabled}>
                    {getButtonText(props.param)}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {getConfirmText(props.param)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="comment" name="comment"
                            label={translate(props.param === "send-to-verify" || props.param === "verify" ? "cfdis.add_comment.input.authorize" : "cfdis.add_comment.input.reject") as string}
                            value={request}
                            rows={5}
                            maxLength={500}
                            required={props.param === "reject" || props.param === "reject-review"}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}

