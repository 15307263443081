import { Box, Card, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AutoTransporte, DerechosPaso, ContenedorFerroviario, Carro, CantidadTransporta, ContenedorMaritimo, Mercancia, Mercancias, TransporteMaritimo, TransporteAereo, TransporteFerroviario, Remolque, Pedimento } from "../../model/ComplementoCartaPorte20";
import translate from "../../i18n/Translator";
import GridDx, { NoFilterEditor } from "../../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import Gridable from "../../components/Gridable";


interface MercanciasCardProps {
    data: Mercancias;
}

export default function MercanciasCard(props: MercanciasCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.mercancias.title")}</strong></Typography>
            <Grid container justify="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Card variant="outlined" >
                        <MercanciasHead data={props.data} />
                        <MercanciasGrid data={props.data.mercancias} />
                    </Card><br />
                    <AutotransporteFederal data={props.data.autotransporte_federal} />
                    <Transportemaritimo data={props.data.transporte_maritimo} />
                    <Transporteaereo data={props.data.transporte_aereo} />
                    <Transporteferroviario data={props.data.transporte_ferroviario} />
                </Grid>
            </Grid>
        </Box>
    );
}

interface MercanciasHeadProps {
    data: Mercancias;
}

function MercanciasHead(props: MercanciasHeadProps) {

    const headerData = ["peso_bruto_total", "numero_total_mercancias", "unidad_peso", "cargo_por_tasacion", "peso_neto_total"];

    return (
        <>
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.mercancia.title")}</Typography><br/>
                <Grid container justify="flex-start" >
                    {headerData.map((property) => {
                        return <>
                            {(props.data as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                </Grid>
            </Box>
        </>
    );
}

interface MercanciasGridProps {
    data: Mercancia[]
}

function MercanciasGrid(props: MercanciasGridProps) {

    const [columnsFormat] = useState([
        { columnName: 'bienes_transportados', wordWrapEnabled: true, width: 100 },
        { columnName: 'clave_stcc', wordWrapEnabled: true, width: 100 },
        { columnName: 'descripcion', width: 100 },
        { columnName: 'cantidad', width: 100 },
        { columnName: 'clave_unidad', wordWrapEnabled: true, width: 100 },
        { columnName: 'unidad', wordWrapEnabled: true, width: 100 },
        { columnName: 'dimensiones', wordWrapEnabled: true, width: 100 },
        { columnName: 'material_peligroso', wordWrapEnabled: true, width: 100 },
        { columnName: 'clave_material_peligroso', wordWrapEnabled: true, width: 100 },
        { columnName: 'embalaje', wordWrapEnabled: true, width: 100 },
        { columnName: 'descripcion_embalaje', wordWrapEnabled: true, width: 100 },
        { columnName: 'peso_en_kg', wordWrapEnabled: true, width: 100 },
        { columnName: 'valor_mercancia', align: 'right', wordWrapEnabled: true, width: 100 },
        { columnName: 'moneda', width: 100 },
        { columnName: 'fraccion_arancelaria', wordWrapEnabled: true, width: 100 },
        { columnName: 'uuid_comercio_exterior', wordWrapEnabled: true, width: 100 },
    ]);

    const columns = [
        {
            name: 'bienes_transportados',
            title: translate('cfdis.carta_porte.mercancias.mercancia.bienes_transportados') as string
        },
        {
            name: 'clave_stcc',
            title: translate('cfdis.carta_porte.mercancias.mercancia.clave_stcc') as string
        },
        {
            name: 'descripcion',
            title: translate('cfdis.carta_porte.mercancias.mercancia.descripcion') as string
        },
        {
            name: 'cantidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.cantidad') as string
        },
        {
            name: 'clave_unidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.clave_unidad') as string
        },
        {
            name: 'unidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.unidad') as string
        },
        {
            name: 'dimensiones',
            title: translate('cfdis.carta_porte.mercancias.mercancia.dimensiones') as string
        },
        {
            name: 'material_peligroso',
            title: translate('cfdis.carta_porte.mercancias.mercancia.material_peligroso') as string
        },
        {
            name: 'clave_material_peligroso',
            title: translate('cfdis.carta_porte.mercancias.mercancia.clave_material_peligroso') as string
        },
        {
            name: 'embalaje',
            title: translate('cfdis.carta_porte.mercancias.mercancia.embalaje') as string
        },
        {
            name: 'descripcion_embalaje',
            title: translate('cfdis.carta_porte.mercancias.mercancia.descripcion_embalaje') as string
        },
        {
            name: 'peso_en_kg',
            title: translate('cfdis.carta_porte.mercancias.mercancia.peso_en_kg') as string
        },
        {
            name: 'valor_mercancia',
            title: translate('cfdis.carta_porte.mercancias.mercancia.valor_mercancia') as string
        },
        {
            name: 'moneda',
            title: translate('cfdis.carta_porte.mercancias.mercancia.moneda') as string
        },
        {
            name: 'fraccion_arancelaria',
            title: translate('cfdis.carta_porte.mercancias.mercancia.fraccion_arancelaria') as string
        },
        {
            name: 'uuid_comercio_exterior',
            title: translate('cfdis.carta_porte.mercancias.mercancia.uuid_comercio_exterior') as string
        }
    ];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );


    const currencyColumns = ['valor_mercancia'];

    const quantityColumns = ['cantidad', 'peso_en_kg'];

    const filtersExclude = [
        'bienes_transportados',
        'clave_stcc',
        'descripcion',
        'cantidad',
        'clave_unidad',
        'unidad',
        'dimensiones',
        'material_peligroso',
        'clave_material_peligroso',
        'embalaje',
        'descripcion_embalaje',
        'peso_en_kg',
        'valor_mercancia',
        'moneda',
        'fraccion_arancelaria',
        'uuid_comercio_exterior',
        'cantidad_transporta',
        'detalle_mercancia',
    ];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    const [leftColumns] = useState(['bienes_transportados', 'clave_stcc']) as any;

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
            <Box>
            <Typography ><strong>{translate("cfdis.carta_porte.mercancias.mercancia.detalle.title")}</strong></Typography>
            <br />
            <Grid container justify="flex-start" spacing={2}>
                {params.row.pedimentos &&
                    <Grid xs={6}>
                        <Gridable
                            items={params.row.pedimentos}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.cantidad") as string,
                                    converter: (ct: Pedimento) => ct.pedimento,
                                    xs: 3,
                                }
                            ]}
                        />
                    </Grid>
                }
                {params.row.detalle_mercancia &&
                    <Grid item xs={12}>
                        <Grid xs={6}>
                            <Grid container>
                                <Grid xs={2}>
                                    {params.row.detalle_mercancia.unidad_peso}<br />
                                    {translate("cfdis.carta_porte.mercancias.mercancia.detalle.unit")}
                                </Grid>
                                <Grid xs={10}>
                                    <Grid container>
                                        <Grid xs={3}>
                                            {params.row.detalle_mercancia.peso_bruto}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_bruto")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.detalle_mercancia.peso_neto}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_neto")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.detalle_mercancia.peso_tara}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_tara")}
                                        </Grid>
                                        {params.row.detalle_mercancia.numero_piezas &&
                                            <Grid xs={3}>
                                                {params.row.detalle_mercancia.numero_piezas}<br />
                                                {translate("cfdis.carta_porte.mercancias.mercancia.detalle.numero_piezas")}
                                            </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                {params.row.guias_identificacion &&
                    <Grid item xs={12}>
                        <Grid xs={6}>
                            <Grid container>
                                <Grid xs={10}>
                                    <Grid container>
                                        <Grid xs={3}>
                                            {params.row.guias_identificacion.numero_guia_identificacion}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.numero_guia_identificacion")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.guias_identificacion.descripcion_guia_identificacion}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.descripcion_guia_identificacion")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.guias_identificacion.peso_guia_identificacion}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_guia_identificacion")}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                {params.row.cantidad_transporta &&
                    <Grid xs={6}>
                        <Gridable
                            items={params.row.cantidad_transporta}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.cantidad") as string,
                                    converter: (ct: CantidadTransporta) => ct.cantidad,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.id_origen") as string,
                                    converter: (ct: CantidadTransporta) => ct.id_origen,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.id_destino") as string,
                                    converter: (ct: CantidadTransporta) => ct.id_destino,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.clave_transporte") as string,
                                    converter: (ct: CantidadTransporta) => ct.clave_transporte,
                                    xs: 3,
                                }
                            ]}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    );

    return (
        <GridDx
            loading={false}
            rows={props.data}
            columns={columns}
            columnsFormat={columnsFormat}
            onClickRow={() => { }}
            amountCurrencyColumns={currencyColumns}
            leftColumns={leftColumns}
            quantityColumns={quantityColumns}
            customFormatColumns={customPlugins}
            rowDetailComponent={rowDetailComponent}
            noUseId
        />
    );
}

export interface AutotransporteFederalProps {
    data?: AutoTransporte;
}

export function AutotransporteFederal(props: AutotransporteFederalProps) {

    const generalData = ["permiso_sct", "numero_permiso_sct"];

    const identificacionVehicular = ["configuracion_vehicular", "peso_bruto_vehicular", "placa_vehiculo_motor", "anio_modelo_vehiculo_motor"];

    const seguros = ["aseguradora_resp_civil", "poliza_resp_civil", "asegura_medio_ambiente", "poliza_medio_ambiente", "asegura_carga", "poliza_carga", "prima_seguro"];

    return props.data ? <>
        <Card variant="outlined" >
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.autotransporte_federal.title")}</Typography><br/>
                <Grid container justify="flex-start" >
                    {generalData.map((property) => {
                        return <>
                            {(props.data as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.autotransporte_federal.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                    {identificacionVehicular.map((property) => {
                        return <>
                            {props.data && props.data.identificacion_vehicular && (props.data.identificacion_vehicular as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.autotransporte_federal.identificacion_vehicular.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data.identificacion_vehicular as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                    {seguros.map((property) => {
                        return <>
                            {props.data && props.data.seguros && (props.data.seguros as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.autotransporte_federal.seguros.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data.seguros as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                </Grid>
                {props.data.remolques && props.data.remolques.remolques && 
                    <Grid ><br/>
                        <Gridable
                            items={props.data.remolques.remolques}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.autotransporte_federal.remolques.sutipo") as string,
                                    converter: (ct : Remolque) => ct.subtipo,
                                    xs: 6,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.autotransporte_federal.remolques.placa") as string,
                                    converter: (ct: Remolque) => ct.placa,
                                    xs: 6,
                                },
                            ]}
                        />
                    </Grid>
                }
            </Box>
        </Card><br /></>
        : <></>;
}

interface TransporteMaritimoProps {
    data?: TransporteMaritimo;
}

function Transportemaritimo(props: TransporteMaritimoProps) {

    const headerData = ["permiso_sct", "numero_permiso_sct", "nombre_aseguradora", "numero_poliza_seguro", "tipo_embarcacion",
        "matricula",
        "numero_omi",
        "anio_embarcacion",
        "nombre_embarcacion",
        "nacionalidad_embarcacion",
        "unidades_arqueo_bruto",
        "tipo_carga",
        "numero_certificado_itc",
        "eslora",
        "manga",
        "calado",
        "linea_naviera",
        "nombre_agente_naviero",
        "numero_autorizacion_naviero",
        "numero_viaje",
        "numero_conocimiento_embarque"
    ];

    return props.data ? <>
        <Card variant="outlined" >
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.transporte_maritimo.title")}</Typography><br/>
                <Grid container justify="flex-start">
                    {headerData.map((property) => {
                        return <>
                            {(props.data as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.transporte_maritimo.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                    {props.data.contenedores && props.data.contenedores.length > 0 &&
                        <Grid item xs={12} >
                            <Box pt={2}>
                                <Gridable
                                    items={props.data.contenedores}
                                    loading={false}
                                    empty={translate("reports.empty") as string}
                                    columns={[
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.matricula") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.matricula,
                                            xs: 4,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.tipo") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.tipo,
                                            xs: 4,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.numero_precinto") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.numero_precinto,
                                            xs: 4,
                                        },
                                    ]}
                                />
                            </Box>
                        </Grid>
                    }
                </Grid>

            </Box>
        </Card><br /></>
        : <></>;
}

export interface TransporteAereoProps {
    data?: TransporteAereo;
}

export function Transporteaereo(props: TransporteAereoProps) {

    const headerData = [
        "permiso_sct",
        "numero_permiso_sct",
        "matricula_aeronave",
        "nombre_aseguradora",
        "numero_poliza_seguro",
        "numero_guia",
        "lugar_contrato",
        "rfc_transportista",
        "codigo_transportista",
        "id_tributario_transportista",
        "residencia_fiscal_transportista",
        "nombre_transportista",
        "rfc_embarcador",
        "id_tributario_embarcador",
        "residencia_fiscal_embarcador",
        "nombre_embarcador"
    ];

    return props.data ? <>
    <Card variant="outlined" >
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.transporte_aereo.title")}</Typography><br/>
                <Grid container justify="flex-start" >
                    {headerData.map((property) => {
                        return <>
                            {(props.data as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.transporte_aereo.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                </Grid>
            </Box>
        </Card><br /></>
        : <></>;
}

export interface TransporteFerroviarioProps {
    data?: TransporteFerroviario;
}

export function Transporteferroviario(props: TransporteFerroviarioProps) {

    const headerData = [
        "tipo_servicio",
        "nombre_aseguradora",
        "numero_poliza_serguro",
        "concesionario"
    ];

    return props.data ? <>
    <Card variant="outlined" >
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.transporte_ferroviario.title")}</Typography><br/>
                <Grid container justify="flex-start" >
                    {headerData.map((property) => {
                        return <>
                            {(props.data as any)[property] &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.transporte_ferroviario.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                </Grid>
                {props.data.derechos_paso && props.data.derechos_paso.length > 0 &&
                    <Grid > <br /><Typography ><strong>{translate('cfdis.carta_porte.mercancias.transporte_ferroviario.derechos_paso.title')}</strong></Typography >
                        <Gridable
                            items={props.data.derechos_paso}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_ferroviario.derechos_paso.tipo") as string,
                                    converter: (ct: DerechosPaso) => ct.tipo,
                                    xs: 6,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_ferroviario.derechos_paso.kilometraje_pagado") as string,
                                    converter: (ct: DerechosPaso) => ct.kilometraje_pagado,
                                    xs: 6,
                                },
                            ]}
                        />
                    </Grid>
                }
                {props.data.carros && props.data.carros.length > 0 &&
                    <CarrosFerroviarios data={props.data.carros} />
                }
            </Box>
        </Card><br /></>
        : <></>;
}

interface CarrosFerroviariosProps {
    data: Carro[]
}

function CarrosFerroviarios(props: CarrosFerroviariosProps) {

    const [columnsFormat] = useState([
        { columnName: 'tipo', wordWrapEnabled: true },
        { columnName: 'matricula', wordWrapEnabled: true },
        { columnName: 'guia', wordWrapEnabled: true },
        { columnName: 'toneladas_netas', wordWrapEnabled: true },
    ]);

    const columns = [
        {
            name: 'tipo',
            title: translate('cfdis.carta_porte.mercancias.transporte_ferroviario.carros.tipo') as string
        },
        {
            name: 'matricula',
            title: translate('cfdis.carta_porte.mercancias.transporte_ferroviario.carros.matricula') as string
        },
        {
            name: 'guia',
            title: translate('cfdis.carta_porte.mercancias.transporte_ferroviario.carros.guia') as string
        },
        {
            name: 'toneladas_netas',
            title: translate('cfdis.carta_porte.mercancias.transporte_ferroviario.carros.toneladas_netas') as string
        },
    ];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const quantityColumns = ['toneladas_netas'];

    const filtersExclude = [
        'tipo',
        'matricula',
        'guia',
        'toneladas_netas',
        'contenedor',
    ];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
        <>{params.row.contenedor && 
            <Box >
                <strong>{translate("cfdis.carta_porte.mercancias.transporte_ferroviario.carros.contenedor.title")}</strong><br/>
                <br/>
                    <Grid xs={12} >
                        <Gridable
                            items={params.row.contenedor}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_ferroviario.carros.contenedor.tipo") as string,
                                    converter: (ct : ContenedorFerroviario) => ct.tipo,
                                    xs: 4,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_ferroviario.carros.contenedor.peso_contenedor_vacio") as string,
                                    converter: (ct : ContenedorFerroviario) => ct.peso_contenedor_vacio,
                                    xs: 4,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_ferroviario.carros.contenedor.peso_neto_mercancia") as string,
                                    converter: (ct : ContenedorFerroviario) => ct.peso_neto_mercancia,
                                    xs: 4,
                                }
                            ]}
                        />
                    </Grid>
            </Box>
        }</>
    );

    return (<><br /><Typography ><strong>{translate('cfdis.carta_porte.mercancias.transporte_ferroviario.carros.title')}</strong></Typography >
        <GridDx
            loading={false}
            rows={props.data}
            columns={columns}
            columnsFormat={columnsFormat}
            onClickRow={() => { }}
            quantityColumns={quantityColumns}
            customFormatColumns={customPlugins}
            rowDetailComponent={rowDetailComponent}
            noUseId
        /></>
    );
}