import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import { ProjectHistoryRecord } from "../model/Project";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";

export interface ProjectHistoryParams {
    history?: ProjectHistoryRecord[];
}

export default function ProjectHistoryTab(params: ProjectHistoryParams) {

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ProjectHistoryRecord[]>();

    const load = () => {
        setStatus("loading");
        if(params.history){
            setData(fillHistory(params.history));
        }
        setStatus("loaded");
    };

    useEffect(load, [params]);

    const fillHistory = (historyProps: ProjectHistoryRecord[]) => {
        let history = [] as ProjectHistoryRecord[];
        historyProps.forEach((record) => {
            if("GENERAL" === record.history_type){
                history.push(record);
            }
        });
        return history.reverse();
    };

    return (
        <Gridable
            items={data ? data : []}
            loading={status === "loading"}
            error={status !== "loading" && status !== "loaded" ? status : undefined}
            empty={translate("cfdis.history.empty") as string}
            columns={[
                {
                    title: translate("requisitions.projects.history.updated_at") as string,
                    converter: (history) => (<DateFormat date={history.updated_date} format="DD-MM-YYYY h:mm a" zone={true} />),
                    xs: 3,
                    sm: 3,
                    md: 3,
                    xl: 3
                },
                {
                    title: translate("requisitions.projects.history.previous_status") as string,
                    converter: (history) => (
                        <Ellipsis 
                            lenght={100} uppercased={false}
                            text={history.previus_status ? translate("requisitions.projects.status." + history.previus_status) as string : ""}  
                        />),
                    xs: false,
                    sm: false,
                    md: 2,
                    xl: 2
                },
                {
                    title: translate("requisitions.projects.history.user") as string,
                    converter: (history) => (<Ellipsis text={history.user_name ? history.user_name : "---"} uppercased={false} />),
                    xs: 3,
                    sm: 3,
                    md: 2,
                    xl: 2
                },
                { 
                    title: translate("requisitions.projects.history.status") as string,
                    converter: (history) => (
                        <Ellipsis  
                            lenght={100} uppercased={false}
                            text={history.status ? translate("requisitions.projects.status." + history.status) as string : ""}  
                        />),
                    xs: 3,
                    sm: 3,
                    md: 2,
                    xl: 2
                },
                {
                    title: translate("requisitions.projects.history.comments") as string,
                    converter: (history) => (history.comments !== undefined ?
                        <div style={{ "overflowY": "scroll" }} className="content-container" >{history.comments} </div> : undefined),
                    xs: 3,
                    sm: 3,
                    md: 3,
                    xl: 3
                },
            ]} />
    )
}