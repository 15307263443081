import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';

import { createCostCenter, getCostCenter, updateCostCenter } from "../api/CostCenterAPI";
import translate from "../i18n/Translator";
import { CostCenter, CostCenterRequest } from "../model/CostCenter";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";

export default function CostCenterForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const costCenterId = match.params.costCenterId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<CostCenterRequest>({} as CostCenterRequest);
    const [validations, setValidations] = useState({} as any);

    const submitPromise = (): Promise<CostCenter> => {
        if (costCenterId) {
            return updateCostCenter(tenantId, costCenterId, request);
        }
        return createCostCenter(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (costCenterId) {
            getCostCenter(tenantId, costCenterId).then((costCenter) => {
                setRequest({
                    erp_id: costCenter.erp_id,
                    name: costCenter.name,
                    category: costCenter.category,
                } as CostCenterRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    }, [tenantId, costCenterId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };


    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((costCenter) => {
            history.push("/cost-centers");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(costCenterId ? "cost_centers.edit" : "cost_centers.new")} icon={<CenterFocusWeakIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="erp_id" name="erp_id"
                                        value={ request.erp_id }
                                        label={translate("cost_centers.erp_id") as string}
                                        required={ false } disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={ request.name }
                                        label={translate("cost_centers.name") as string}
                                        required={ true } disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="category" name="category"

                                        value={ request.category ? request.category : "" }
                                        label={translate("cost_centers.category") as string}
                                        required={ false } disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(costCenterId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}