import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import { Typography, Tabs, Tab, Grid, Chip, Fab, Button } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from '@material-ui/icons/Stop';
import OptionsIcon from '@material-ui/icons/MoreVert';
import PaymentIcon from '@material-ui/icons/Payment';
import queryString from "query-string";
import translate from "../i18n/Translator";
import BudgetMenu from "./BudgetMenu";

import ConfirmPopup from "../components/ConfirmationPopup";
import Surface from "../components/Surface";
import Progress from "../components/Progress";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import DetailsTab from "./DetailsTab";
import PerformanceTab from "./PerformanceTab"

import { getBudget, updateExecutionStatus, downloadExportBudgetPerformance } from "../api/BudgetApi";
import { Budget } from "../model/Budget";
import BudgetHistoryTab from './BudgetHistoryTab';

import SendCfdiIcon from '@material-ui/icons/SendSharp';
import ConfirmationPopup from "../components/ConfirmationPopup";
import ReactHtmlParser from 'html-react-parser';
import DialogPopup from '../components/DialogPopup';

interface BudgetTab {
    name: string;
    title: string;
    actions?: string[];
}

export default function BudgetOverview({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const budgetId = match.params.budgetId;
    const [openModal, setOpenModal] = useState<string | undefined>(undefined);
    const [submiting, setSubmiting] = useState<boolean>(false);

    const [status, setStatus] = useState<string>("loading");
    const qs = queryString.parse(window.location.search);
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();

    const [budget, setBudget] = useState<Budget>();
    const [redirect, setRedirect] = useState<string | undefined>();
    const [openCloseBudgetDialog, setOpenCloseBudgetDialog] = useState<boolean>(false);
    const [openCloseSuccessDialog, setOpenCloseSuccessDialog] = useState<boolean>(false);
    const isReaderOnly = context.isGranted("BudgetDetailReadOnly");

    const allTabs = [
        {
            name: "details",
            title: translate("budget.overview.tabs.details" as string)
        },
        {
            name: "performance",
            title: translate("budget.overview.tabs.performance" as string)
        },
        {
            name: "history",
            title: translate("budget.overview.tabs.history" as string)
        }
    ] as BudgetTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [tabs, setTabs] = useState<BudgetTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);

    const load = (successMessage?: string) => {
        setStatus("loading");
        getBudget(tenantId, budgetId).then((response) => {
            setBudget(response);
            if ("PLANNING" === response.execution_status) {
                setTabs(allTabs.filter(t => !("history" === t.name.toLowerCase() || "performance" === t.name.toLowerCase())));
            } else {
                setTabs(allTabs);
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            if (successMessage) {
                setSuccess(successMessage);
            }
        });
    };

    useEffect(load, [tenantId, budgetId]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line
    }, [qs.tab, tabs]);

    const reloadOnlyData = () => {
        setStatus("loading");
        getBudget(tenantId, budgetId).then((response) => {
            setBudget(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const tabContent = (index: number) => {
        if (!budget) return;

        if (!context.session || !context.session.tenant) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return (
                    <DetailsTab tenantId={context.session.tenant.id} budget={budget}
                        isReaderOnly={isReaderOnly} refresh={load} />
                );
            case "performance":
                
                return (
                    <PerformanceTab tenantId={context.session.tenant.id} budget={budget}
                        isReaderOnly={context.isGranted("BudgetsExecuteReadAll")} reload={reloadOnlyData}/>
                );
            case "history":
                return (
                    <BudgetHistoryTab tenantId={context.session.tenant.id} budget={budget} />
                );
            default:
                return <div></div>;
        }
    };

    const onCloseBudget = () => {
        updateExecutionStatus(tenantId, budgetId).then((response) => {
            setBudget(response);
            setOpenCloseBudgetDialog(false);
            setOpenCloseSuccessDialog(true);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onExportPerformanceExcel = () => {
        downloadExportBudgetPerformance(tenantId, budgetId).then((response) => {
            setAnchorEl(null);
            window.open(response.url, "_blank");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onCloseSuccessCloseBudget = () => {
        setOpenCloseSuccessDialog(false);
        load();
    };

    const onSendBudget = () => {
        if (budget && budget?.budget_lines) {
            if (budget.budget_lines.find(l => !!l.team_id && l.initial_amount > 0)) {
                setOpenModal("confirm");
            } else {
                setOpenModal("error");
            }
        }
    };
    const onConfirm = () => {
        setSubmiting(true);
        updateExecutionStatus(tenantId, budgetId).then((budget) => {
            setBudget(budget);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmiting(false);
            setOpenModal(undefined);
        });
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !budget) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    const chipStatus = () => {
        return <Grid className={classes.root}>
            <Typography variant="body1" component="h6">
                {budget.company_name}
            </Typography>
            <Chip color="primary" label={translate(`budget.lists.execution_status.${budget.execution_status}`) as string}
                disabled variant="outlined" size="small" />
            {["IN_EXECUTION", "CLOSED"].includes(budget.execution_status) &&
                <Chip style={budget.variation_status === "UP_BUDGET" ?
                    { color: "red", borderColor: "red" } : { color: "#49A2DA", borderColor: "#49A2DA" }}
                    label={translate(`budget.lists.variation_status.${budget.variation_status}`) as string}
                    disabled variant="outlined" size="small" />
            }
        </Grid>
    }

    const onClickedOptions = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Surface title={budget.name} subtitle={chipStatus()} backButton icon={<PaymentIcon />}
            className="PaperPagination PaperTabContainer"
            titleActions={(
                <Grid container justify="flex-end" spacing={1}>
                    {!isReaderOnly && "PLANNING" === budget.execution_status && (
                        <Fab color="primary" size="small" title={translate("budget.to_send.tooltip") as string} onClick={onSendBudget} >
                            <SendCfdiIcon />
                        </Fab>
                    )
                    }
                    {!isReaderOnly && budget.execution_status === "IN_EXECUTION" && context.isGranted("BudgetClose") &&
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" onClick={() => setOpenCloseBudgetDialog(true)} title={translate("budget.update_status_execution.close_title") as string}>
                                <CloseIcon />
                            </Fab>
                        </Grid>
                    }
                    {budget.execution_status !== "PLANNING" && context.isGranted("BudgetPerformanceExport") &&
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" onClick={onClickedOptions} title={translate("budget.performance.export_excel") as string}>
                                <OptionsIcon />
                            </Fab>
                        </Grid>
                    }
                </Grid>
            )}>
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: BudgetTab, index: number) => (
                    <Tab disabled={budget.execution_status === "PLANNING" && tab.name !== "details"} key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: BudgetTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={() => setSuccess("")} />
            <WarningSnackbar message={warning} onClose={() => setWarning("")} />
            <ErrorSnackbar message={error} onClose={() => setError("")} />
            {openCloseBudgetDialog && (
                <ConfirmPopup
                    doAction={onCloseBudget}
                    onClose={() => setOpenCloseBudgetDialog(false)}
                    title={translate("budget.update_status_execution.close_title") as string}
                    message={translate("budget.update_status_execution.close_message", { name: budget.name, pending_accrue: (budget.pending_accrue_amount || 0.00) }) as string}
                    secondary={translate("budget.update_status_execution.close_secondary_message") as string}
                    button={translate("buttons.accept") as string} />
            )}
            {openCloseSuccessDialog && (
                <ConfirmPopup
                    onClose={onCloseSuccessCloseBudget}
                    closeText={translate("buttons.close") as string}
                    title={translate("budget.update_status_execution.close_success_title") as string}
                    message={translate("budget.update_status_execution.close_success_message_primary", { name: budget.name }) as string}
                    secondary={translate("budget.update_status_execution.close_success_message_secondary") as string} />
            )}
            {budget && anchorEl &&
                <BudgetMenu
                    budget={budget}
                    isDeleteGranted={false}
                    isUpdateGranted={false}
                    isPerformanceExportExcelGranted={context.isGranted("BudgetPerformanceExport")}
                    anchor={anchorEl}
                    onPerformanceExportExcel={onExportPerformanceExcel}
                    onClose={() => setAnchorEl(null)} />
            }
            {openModal && "error" === openModal && (
                <DialogPopup open maxWidth="md"
                    title={translate("budget.to_send.erro_to_send.title") as string}
                    closeText={translate("buttons.close") as string}
                    onClose={() => setOpenModal(undefined)}
                    closeColor="primary"
                    buttonLeft={
                        <Button onClick={() => { }} variant={"text"} color={"primary"} >
                            {translate("connectors.template_downloader.title") as string}
                        </Button>
                    }
                >
                    {ReactHtmlParser(
                        translate("budget.to_send.erro_to_send.message") as string
                    )}
                </DialogPopup>
            )}
            {openModal && "confirm" === openModal && (
                <ConfirmationPopup
                    onClose={() => setOpenModal(undefined)}
                    submitting={submiting}
                    doAction={onConfirm}
                    title={translate("budget.to_send.confirm.title") as string}
                    message={(translate("budget.to_send.confirm.message", { budget_name: budget.name }) as string)}
                    button={translate("buttons.accept") as string} />
            )}
        </Surface>
    );
}


