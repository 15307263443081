import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import DialogPopup from "../components/DialogPopup";
import { Button, Grid, Typography, TextField } from '@material-ui/core';
import translate from "../i18n/Translator";
import { CloneCalendarRequest, CloneCalendarResponse } from '../model/Dashboard';
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { cloneCalendar } from "../api/DashboardAPI";
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface CloneCalendarFormProps {
    calendarId: string;
    fromOptions: string[];
    onSubmit(response: CloneCalendarResponse): void;
    onClose(): void;
}

export default function CloneCalendarFormPopop(props: CloneCalendarFormProps) {
    const currentYear = new Date().getFullYear();

    const options = (plusYear: number): string[] => {
        const options: string[] = [];
        let current = currentYear;
        for (let index = 0; index < plusYear; index++) {
            const option = current++;
            options.push(`${option}`);
        }
        return options.reverse();
    }

    const [toYears] = useState<string[]>(options(10))
    const tenantId = useContext(AppContext).session!.tenant!.id;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [request, setResquest] = useState<CloneCalendarRequest>({ end_year: currentYear, start_year: currentYear } as CloneCalendarRequest);

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined)
    };

    const clone = () => {
        if (request.end_year === request.start_year) {
            setError(translate("calendar_events.year_validation") as string);
            return;
        }
        setSubmitting(true);

        cloneCalendar(tenantId, props.calendarId, request)
            .then((response) => {
                props.onSubmit(response);
            })
            .catch((e) => {
                setError(e.message);
            }).finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <DialogPopup open
            title={translate("calendar_events.clone_title")}
            button={
                <Button onClick={clone} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.execute")}
                </Button>
            }
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default">

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        {translate(`calendar_events.clone_label`) as string}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        key={"startYear"}
                        size="small"
                        value={`${request.start_year}` ?? undefined}
                        options={props.fromOptions}
                        getOptionLabel={(elemento) => elemento}
                        noOptionsText={translate("calendar_events.year_of_origin") as string}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label={translate("calendar_events.year_of_origin") as string} fullWidth />
                        )}
                        onChange={(event, newValue) => {
                            setResquest({ ...request, start_year: !!newValue ? Number(newValue) : currentYear });
                        }}
                        getOptionSelected={(opt, val) => opt === val}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        key={"endYear"}
                        size="small"
                        value={`${request.end_year}` ?? undefined}
                        options={toYears}
                        getOptionLabel={(elemento) => elemento}
                        noOptionsText={translate("calendar_events.destination_year") as string}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label={translate("calendar_events.destination_year") as string} fullWidth />
                        )}
                        onChange={(event, newValue) => {
                            setResquest({ ...request, end_year: !!newValue ? Number(newValue) : currentYear });
                        }}
                        getOptionSelected={(opt, val) => opt === val}
                    />
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />

        </DialogPopup >
    );
}
