import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton, Button, Icon } from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';

import { getFieldUrlSpecific, updateField, deleteField } from "../api/ProviderExpedientAPI";
import { ProviderExpedient, ProviderExpedientFieldRequest, ProviderExpedientField } from "../model/ProviderExpedient";
import ExpedientProviderFieldForm from "./ExpedientProviderFieldForm";
import ExpedientProviderSegments from "./ExpedientProviderSegments";
import ProviderFieldCard from "../providers/ProviderFieldCard";
import { AppContext } from "../context/AppContext";
import DateFormat from "../components/DateFormat";
import { Entity } from "../model/Provider";
import ProviderExpedientFieldPopup from "../providers/ProviderExpedientFieldHistoryPopup";
import { ProviderExpedientFieldHistory, GetUrlFromHistoryExpedientRequest } from "../model/ProviderExpedient";
import RestoreIcon from '@material-ui/icons/Restore';
import translate from "../i18n/Translator";

interface ExpedientProviderFieldsProps {
    metadata: ProviderExpedient;
    onChange(metadata: ProviderExpedient): any;
    disabled: boolean;
    provider?: Entity;
}

export default function ExpedientProviderFields(props: ExpedientProviderFieldsProps) {
    const context = useContext(AppContext);
    const [openHistoryPopup, setOpenHistoryPopup] = useState<boolean>(false);
    const [history, setHistory] = useState<ProviderExpedientFieldHistory[]>([]);
    const [fieldName, setFieldName] = useState<string>("");

    const buildData = (current: ProviderExpedientField) => {
        let history = getHistory(current);
        if (current.configuration.type === "FILE" && !!current.value) {
            return <><DownloadFileButton
                tenantId={context.session!.tenant!.id}
                providerId={props.provider ? props.provider.id : context.session!.provider!.id}
                fieldId={current.configuration.id}
                value={current.value!} isHistory={false} /> {history}</>
        }
        if (current.configuration.type === "FILE" && !current.value) {
            return <><Icon color="disabled"> <LaunchIcon /></Icon>{history}</>;
        }

        if (current.configuration.type === "DATE" && !!current.value) {
            let temp = current.value.split("-");
            let tempValue = temp[2] + "-" + temp[0] + "-" + (Number(temp[1]) + 1);
            return <DateFormat date={new Date(tempValue)} format="L" />
        }

        if (!!current.value) {
            return <>{current.value}</>
        }
        return <>{"-"}</>
    }

    const getHistory = (current: ProviderExpedientField) => {
        if (current.configuration.expires_at !== undefined) {
            return <Button aria-label="options"
                color="primary"
                size="small"
                onClick={() => onOpenHistoryPopup(current)}
                startIcon={<RestoreIcon />}
            >
                {translate(`providers.expedient_history.view_history`)}
            </Button>
        }
        return <></>;
    }

    const onOpenHistoryPopup = (current: ProviderExpedientField) => {
        let history = current.history.map(h => {
            h.field.configuration = current.configuration;
            return h;
        });
        setHistory(history);
        setFieldName(current.configuration.name);
        setOpenHistoryPopup(true);
    }

    const onCloseHistoryPopup = () => {
        setHistory([]);
        setOpenHistoryPopup(false);
    }

    return (
        <Grid >
            <ExpedientProviderSegments
                disabledEdition={props.disabled}
                metadata={props.metadata}
                name="fields"
                provider={props.provider}
                supplier={(metadata) => Object.values(metadata.fields) as ProviderExpedientField[]}
                onUpdated={(tenantId: string, providerId: string, current: ProviderExpedientField, value: string) => {
                    let request = {
                        id: current.configuration.id,
                        value: value,
                    } as ProviderExpedientFieldRequest;
                    return updateField(tenantId, providerId, request);
                }}
                onDeleted={(tenantId: string, providerId: string, current: ProviderExpedientField) => {
                    let request = {
                        id: current.configuration.id,
                        value: "",
                    } as ProviderExpedientFieldRequest;
                    return deleteField(tenantId, providerId, request);
                }}
                onChange={props.onChange}
                buildForm={(current: ProviderExpedientField, onClose: () => any, onSubmitted: (value: string) => any, onDeleted: () => any) => {
                    return (
                        <ExpedientProviderFieldForm
                            field={current}
                            title={current.configuration.name}
                            subtitle={current.configuration.description}
                            value={current.value}
                            valueExternalId={current.value_external_id}
                            comments={current.comments}
                            onClose={onClose}
                            onCompleted={onSubmitted}
                            onDeleted={onDeleted}
                            disabled={props.disabled}
                            provider={props.provider}
                        />
                    );
                }}
                buildPopup={(current: ProviderExpedientField, onClose: () => any) => (
                    <ProviderFieldCard
                        tenantId={context.session!.tenant!.id}
                        providerId={props.provider ? props.provider.id : context.session!.provider!.id}
                        configuration={current.configuration}
                        field={current}
                        onClose={onClose} />
                )}
                buildData={buildData}
            />
            {openHistoryPopup && history &&
                <ProviderExpedientFieldPopup
                    onClose={onCloseHistoryPopup}
                    tenantId={context.session!.tenant!.id}
                    providerId={props.provider ? props.provider.id : ""}
                    history={history}
                    fieldName={fieldName}
                />
            }
        </Grid>
    );
}

interface DownloadFileButtonProps {
    tenantId: string;
    providerId: string;
    fieldId: string;
    value: string;
    isHistory: boolean;
}

export function DownloadFileButton(props: DownloadFileButtonProps) {
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (props.value.startsWith("http")) {
            setUrl(props.value);
        } else {
            getFieldUrlSpecific(props.tenantId, props.providerId, props.fieldId, { from_history: props.isHistory ? "true" : "false", value: props.value } as GetUrlFromHistoryExpedientRequest).then((url) => {
                setUrl(url);
            }).catch((error) => {
            });
        }
    }, [props]);

    return (
        <IconButton
            aria-label="options"
            color="default"
            size="small"
            component="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            disabled={!url}
        >
            <LaunchIcon />
        </IconButton>
    );
}
