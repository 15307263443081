import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import translate from "../i18n/Translator";
import { BankAccountType, BankAccountTypesParams } from "../model/BankAccountType";
import { TenantBankAccountTypesRequest, TenantBankAccountTypes } from "../model/TenantBankAccountType";
import DialogPopup from "../components/DialogPopup";
import { CheckBoxCallbacks, CheckBoxColumnTitle, CheckBoxColumnValue } from "../components/GridItemCheckBox";
import Ellipsis from "../components/Ellipsis";
import Gridable from "../components/Gridable";

import { assign } from "../api/TenantBankAccountTypeAPI";
import { ErrorSnackbar } from "../components/Snackbars";
import { list } from "../api/BankAccountTypeAPI";

interface TenantBankAccountTypesFormProps {
    tenantId: string;
    tenantName: string;
    activeTypes: BankAccountType[];
    onClose(): void;
}

export default function TenantBankAccountTypesForm(props: TenantBankAccountTypesFormProps) {
    const [data, setData] = useState<BankAccountType[]>([]);
    const [selected, setSelected] = useState<string[]>([]);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");

    const submitPromise = (): Promise<TenantBankAccountTypes> => {
        return assign(props.tenantId, { bank_account_type_ids: selected } as TenantBankAccountTypesRequest);
    };

    useEffect(() => {
        setStatus("loading");
        list(props.tenantId, { search: "" } as BankAccountTypesParams, 0, 0).then((response) => {
            let externalIdsAlreadyIn = props.activeTypes.map(c => c.external_id);
            let items = response.items ? response.items.filter(item => !externalIdsAlreadyIn.includes(item.external_id)) : [];
            setData(items);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [props]);

    const onSubmit = () => {
        if (selected.length < 1) {
            setError(translate("bank_account_type.tenant_bank_account_type.add_pop_up.empty_selection") as string);
            return;
        }

        setSubmitting(true);
        submitPromise().then((_) => {
            props.onClose();
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const handleCheck = (field: BankAccountType) => {
        let items = selected.length === 0 ? ([] as string[]) : selected.join(",").split(",");
        const index = selected.indexOf(field.id);
        if (index < 0) {
            items.push(field.id);
        } else {
            items.splice(index, 1);
        }
        setSelected(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelected(items);
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <DialogPopup open
            title={translate("bank_account_type.tenant_bank_account_type.add_pop_up.top_title") as string}
            subTitle={props.tenantName ?? "--"}
            onClose={props.onClose}
            button={(
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={selected.length === 0}>
                    {translate("buttons.save")}
                </Button>
            )}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            {translate("bank_account_type.tenant_bank_account_type.add_pop_up.empty_selection")}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            <Gridable
                                items={data}
                                loading={status === "loading"}
                                error={status !== "loading" && status !== "loaded" ? status : undefined}
                                empty={translate("bank_account_type.tenant_bank_account_type.empty") as string}
                                columns={[
                                    {
                                        title: (
                                            <CheckBoxColumnTitle
                                                label_title={translate("expedients.fields.name") as string}
                                                total={data.length}
                                                selected={selected.length}
                                                callbacks={{ handleAllChecks: handleAllChecks } as CheckBoxCallbacks<BankAccountType>}
                                                showChecks={true} />
                                        ),
                                        converter: (field) => (
                                            <CheckBoxColumnValue
                                                item={field}
                                                checked={selected.indexOf(field.id) >= 0}
                                                showChecks={true}
                                                value={field.type_account}
                                                callbacks={{ handleCheck: handleCheck } as CheckBoxCallbacks<BankAccountType>} />
                                        ),
                                        fullWidth: true,
                                        xs: true,
                                    },
                                    {
                                        title: translate("expedients.fields.type.title") as string,
                                        converter: (field) => (<Ellipsis text={field.external_id} lenght={15} uppercased={false} />),
                                        xs: true,
                                        sm: true,
                                        md: true,
                                        xl: true
                                    },
                                    {
                                        title: translate("cfdis.columns.description") as string,
                                        converter: (field) => (<Ellipsis text={field.description || ""} uppercased={false} />),
                                        xs: true,
                                        sm: true,
                                        md: true,
                                        xl: true
                                    },
                                ]} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}