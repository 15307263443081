import { TenantField } from "./TenantFieldCatalog"


export interface ExtendedFieldsTemplate {
    id: string;
    name: string;
    created_at: Date;
    last_update: Date;
    active: boolean;
    fields_response?: FieldsResponseTenant['fields_response'];
    tenant_id: string;
    type: ExtendedFieldsTemplateType;
}

export interface ExtendedFieldsTemplates {
    items: ExtendedFieldsTemplate[];
    total: number;
}

export interface FieldsResponseTenant {
    fields_response: { [key: number]: TenantField };
}

export interface ExtendedFieldsTemplatesParams {
    name?: string;
    type?: ExtendedFieldsTemplateType;
}

export interface ExtendedFieldsTemplateRequest {
    template_name: string;
    fields?: ExtendedFieldsTemplateSegment[];
    type: ExtendedFieldsTemplateType;
}

export interface ExtendedFieldsTemplateSegment {
    id: string;
    priority: number;
}

export enum ExtendedFieldsTemplateType { PROJECT = "PROJECT" }
