import React, { useContext, useState } from "react";

import translate from "../i18n/Translator";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { cancelPurchaseOrder } from "../api/PurchaseOrderAPI"
import { AppContext } from "../context/AppContext";

interface PurchaseOrderCancelPopupProps {
    purchaseOrder: PurchaseOrder;
    onClose(): any;
    onCancel(purchaseOrder: PurchaseOrder): any;
    onError(error: string): any;
}

export default function PurchaseOrderCancelPopup(props: PurchaseOrderCancelPopupProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);

    const onCancel = () => {
        setSubmitting(true);
        cancelPurchaseOrder(context.session!.tenant!.id, props.purchaseOrder.id).then(response => {
            props.onCancel(response);
        }).catch(error => {
            props.onError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <ConfirmationPopup
            title={translate("purchase_orders.cancel.title") as string}
            message={translate("purchase_orders.cancel.text") as string}
            secondary={translate("purchase_orders.cancel.secondary") as string}
            onClose={props.onClose}
            button={translate("buttons.confirm") as string}
            doAction={onCancel}
            color="secondary"
            submitting={submitting} />
    );
}