import { Cfdi } from "./Cfdi";
import { PlanTaxStamp } from "./PlanTaxStamp"

export interface ProviderReference {
    reference: string
}

export interface BuyPlanResponse {
    provider_membership_tax_stamp: ProviderMembershipTaxStamp;
}

export interface ProviderMembershipTaxStamp {
    id: string;
    provider_name: string;
    provider_rfc: string;
    phone: number;
    name: string;
    email: string;
    plan: PlanTaxStamp;
    url_secure_voucher: string;
    status: string;
    payment_cfdis?: Cfdi[];
    stamps_number: number;
    stamps_generated: number;
    expiration_date: Date;
    activation_date: Date;
}

export interface ProviderMembershipsTaxStamp {
    items: ProviderMembershipTaxStamp[];
    total: number;
}

export interface ProviderMembershipTaxStampParams {
    provider_name: string;
    status: string;
    name: string;
    reference: string;
}

export const MEMBERSHIP_STATUSES = ['TO_VERIFY', 'ACTIVE', 'EXPIRED', 'OUT_OF_STOCK'];

export interface ProviderCfdiGeneratedInMembershipResponse {
    generated_by_membership: boolean;
}