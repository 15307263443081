import React, { useState } from "react";
import DialogPopup from "../../components/DialogPopup";
import { PaymentCfdiAttacment } from "../../model/Payment";
import translate from "../../i18n/Translator";
import { Typography, Button } from "@material-ui/core";
import Gridable from "../../components/Gridable";
import NumberFormat from 'react-number-format';
import DateFormat from "../../components/DateFormat";
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';
import InvalidIcon from '@material-ui/icons/ErrorTwoTone';
import Tooltip from "@material-ui/core/Tooltip";

interface CfdiValidationPopupProps {
    data: PaymentCfdiAttacment;
    hasPaymentCfdiReview: boolean;
    onClose(): any;
    onRequestPaymentCfdiReview(): any;
}

export function CfdiPaymentValidationsPopup(props: CfdiValidationPopupProps) {
    const [status] = useState<string>("loaded");

    const requestReviewButton = (
        <Button onClick={props.onRequestPaymentCfdiReview} variant="contained" color="primary" size="medium" >
            {translate("payment.upload.button.request_review")}
        </Button>
    );

    return (
        <DialogPopup title={translate("payment.upload.title") as string} open
            onClose={props.onClose}
            button={!props.data.success_attach && props.hasPaymentCfdiReview ? requestReviewButton : undefined}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            closeColor="primary"
            closeText={translate(props.data.success_attach ? "payment.upload.button.finish" : "payment.upload.button.close") as string}>
            {props.data.success_attach &&
                <Typography variant="body2" align="left">{translate("payment.upload.success") as string}</Typography>}
            {!props.data.success_attach &&
                <Typography variant="body2" align="left" color="error">{translate("payment.upload.error") as string}</Typography>}
            {!props.data.success_attach && <br />}
            {!props.data.success_attach &&
                <Typography variant="body2" align="left">
                    {translate("payment.upload.error_description") as string}
                    <a href={"https://ayuda.focaltec.com/support/solutions/articles/43000626141"} target="_blank" rel="noopener noreferrer">
                        {translate("payment.upload.do_clic")}
                    </a>
                </Typography>}
            <br />
            {props.data.validated_payments && props.data.validated_payments.length > 0 ? <Gridable
                items={props.data.validated_payments}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                columns={[
                    {
                        title: translate("payment.upload.date") as string,
                        converter: (p) => (<DateFormat date={p.pago.date} format="DD-MM-YYYY" zone={false} />),
                        justify: "center",
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("payment.upload.invoices") as string,
                        converter: (p) => (p.pago.documents) as string,
                        xs: 4,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        xl: 4
                    },
                    {
                        title: translate("payment.upload.amount") as string,
                        converter: (p) => <NumberFormat value={p.pago.amount} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />,
                        justify: "flex-end",
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: "",
                        converter: (p) => p.valid ?
                            <ValidIcon style={{ "color": "#82CA9D" }} /> :
                            <Tooltip title={p.validation_message}>
                                <InvalidIcon style={{ "color": "#c33149", }} />
                            </Tooltip>,
                        justify: "center",
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    }
                ]} /> : <></>
            }
        </DialogPopup>);
}

