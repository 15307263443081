import React from "react";
import { TenantFieldCatalog, TenantFieldBankAccount } from "../model/TenantFieldCatalog";
import { updateBankAccounts } from "../api/TenantFieldAPI";
import ExpedientSettingsSegments from "./ExpedientSettingsSegments";
import { ExpedientTemplate } from "../model/ExpedientTemplate";

interface ExpedientSettingsBankAccountsProps {
    expedient?: TenantFieldCatalog;
    template?: ExpedientTemplate;
    view: "templates" | "fields";
    onAdd?(): any;
    onDelete?(id: string): any;
}

export default function ExpedientSettingsBankAccounts(props: ExpedientSettingsBankAccountsProps) {
    return (
        <ExpedientSettingsSegments
            view={props.view}
            expedient={props.expedient}
            segments={props.template ? props.template.bank_accounts_response : undefined}
            onAdd={props.onAdd}
            onDelete={props.onDelete}
            name="bank_accounts"
            supplier={(expedient) => expedient.bank_accounts || []}
            onUpdate={(tenantId: string, request: TenantFieldBankAccount) => updateBankAccounts(tenantId, request)}
        />
    );
}
