import { SatContributions, SatContribution } from "../model/SatContribution";
import moment, { Moment } from "moment";
import traslate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat"
import { DeclarationFrequency } from "../model/Provider";
import { MONTHS_BEFORE_IN_PERIOD } from "../model/Contribution";

export interface SatContributionParams {
    keyMapper(item: SatContribution): string;
    initialMomentModifier(m: Moment): Moment;
    momentKeyMapper(m: Moment): string;
    labelBuilder(m: Moment): string;
    momentModifier(m: Moment): Moment;
}

export interface SatContributionLists {
    keys: string[];
    labels: string[];
}

const SAT_CONTRIBUTION_MONTHLY_PARAMS = {
    keyMapper: (item) => `${item.year}-${item.month}`,
    initialMomentModifier: (m) => m,
    momentKeyMapper: (m) => `${m.year()}-${m.month() + 1}`,
    labelBuilder: (m) => formatDate(m.toDate(), "MMMM YYYY"),
    momentModifier: (m) => m.subtract(1, "month")
} as SatContributionParams;

const SAT_CONTRIBUTION_BIMONTHLY_PARAMS = {
    keyMapper: (item) => `${item.year}-${item.bimester}`,
    initialMomentModifier: (m) => {
        if (m.month() % 2 !== 0) {
            return m.subtract(1, "month");
        }
        return m;
    },
    momentKeyMapper: (m) => {
        const bimester = getBimester(m.month());
        return `${m.year()}-${bimester}`;
    },
    labelBuilder: (m) => {
        const bimester = getBimester(m.month());
        return getBimesterLabel(bimester, m.year());
    },
    momentModifier: (m) => m.subtract(2, "month")
} as SatContributionParams;

const getBimester = (month: number) => {
    return Math.floor(month / 2) + 1
}

const getBimesterLabel = (bimester: number, year: number): string => {
    return `${traslate(`sat_contributions.bimester.${bimester}`)} ${year}`
}

const fill = (response: SatContributions, { keyMapper, initialMomentModifier, momentKeyMapper, labelBuilder, momentModifier }: SatContributionParams): SatContributionLists => {
    const existing = response.items.map(item => keyMapper(item));
    const today = moment();
    let m = initialMomentModifier(moment({
        year: today.year(),
        month: today.month(),
        day: 1,
    }));

    const dateKeys = [];
    const dateLabels = [];

    for (let i = 0; i < response.months_before_in_period; i++) {
        const key = momentKeyMapper(m);
        if (existing.indexOf(key) === -1) {
            dateKeys.push(key);
            dateLabels.push(labelBuilder(m));
        }
        m = momentModifier(m)
    }

    return {
        keys: dateKeys,
        labels: dateLabels
    };
}

export const fillSatContributionList = (response: SatContributions, frequency: DeclarationFrequency): SatContributionLists => {
    if (frequency === "MONTHLY") {
        return fill(response, SAT_CONTRIBUTION_MONTHLY_PARAMS);
    }
    return fill(response, SAT_CONTRIBUTION_BIMONTHLY_PARAMS);
}

export const getSatContributionPeriod = (satContribution: SatContribution, ignoreBimester?: boolean): string => {
    if (!satContribution) return "---";

    if (satContribution.bimester && !ignoreBimester) {
        return getBimesterLabel(satContribution.bimester, satContribution.year);
    }

    const m = moment({
        year: satContribution.year,
        month: satContribution.month - 1, // este valor viene de base de datos [1-12] y moment usa el rango [0-11]
        day: 1,
    })
    return formatDate(m.toDate(), "MMMM YYYY")
}

export const getSatContributionMonthly = (year: number, month: number): string => {
    const m = moment({
        year: year,
        month: month - 1, // este valor viene de base de datos [1-12] y moment usa el rango [0-11]
        day: 1,
    })
    return formatDate(m.toDate(), "MMMM YYYY")
}


