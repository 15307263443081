import { callAPI, HTTPMethod } from "./API";
import { Widgets, Widget, WidgetsQueryParams, WidgetRequest } from "../model/Widget";

const basePath = (tenantId: string) => `/tenants/${tenantId}/widgets`;

export async function listWidgets(tenantId: string, page: number, offset: number, params: WidgetsQueryParams): Promise<Widgets> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createWidget(tenantId: string, request: WidgetRequest): Promise<Widget> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getWidget(tenantId: string, id: string): Promise<Widget> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateWidget(tenantId: string, id: string, request: WidgetRequest): Promise<Widget> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteWidget(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
