import React, { useState, useEffect, useContext } from "react";
import { Button, FormControl, FormControlLabel, Grid, TextField, RadioGroup, Radio, Typography } from "@material-ui/core";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { ProviderLedgerAccountRequest, ProviderLedgerAccount } from "../model/Provider";
import { getCostCenters, getLedgerAccounts } from "../api/TenantLedgerAPI";
import { LedgerAccount } from "../model/LedgerAccountAndCostCenter";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import Progress from "../components/Progress";

interface ProviderAddRelationCecoPopupProps {
    editItem?: ProviderLedgerAccount;
    first: boolean;
    ledgerAccounts: ProviderLedgerAccount[];
    onSave(request: ProviderLedgerAccountRequest, oldAccount: string | undefined): any;
    onClose(): any;
    onCancel(): any;
}

export default function ProviderAddRelationCecoPopup(props: ProviderAddRelationCecoPopupProps) {
    const context = useContext(AppContext);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [status, setStatus] = useState<string>("loading");
    const [oldAccount, setOldAccount] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<ProviderLedgerAccountRequest>({ use_all_cost_centers: true } as ProviderLedgerAccountRequest);
    const [costCenters, setCostCenters] = useState<MultiselectValue[]>([]);
    const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[]>([]);
    const [radio, setRadio] = useState<string>("all");
    const isEdit = !!props.editItem;

    useEffect(() => {
        setStatus("loading");
        setOldAccount(undefined);
        if (props.editItem) {
            setRequest({
                id: props.editItem.public_id,
                use_all_cost_centers: props.editItem.use_all_cost_centers,
                cost_centers: props.editItem.cost_centers_response ? props.editItem.cost_centers_response.map((cc) => cc.public_id) : undefined
            } as ProviderLedgerAccountRequest);
            setRadio(props.editItem.use_all_cost_centers ? "all" : "select");
            setOldAccount(props.editItem.public_id);
        }
        getLedgerAccounts(context.session!.tenant!.id, "", 0, 0).then((response) => {
            setLedgerAccounts(response.items);
            getCostCenters(context.session!.tenant!.id, "", 0, 0).then((response) => {
                let listTemp = [] as MultiselectValue[];
                response.items.forEach(costCenter => {
                    listTemp.push({ title: costCenter.name, value: costCenter.id } as MultiselectValue);
                });
                setCostCenters(listTemp);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }).catch((error) => {
            setStatus(error.message);
        });

    }, [context, props.editItem]);

    const onChangedLedgerAccount = (ledgerAccount: string) => {
        setRequest({ ...request, id: ledgerAccount });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const validateForm = () => {
        let ledgerTemp = props.ledgerAccounts.find(ledger => ledger.public_id === request.id);
        if ((!isEdit && ledgerTemp !== undefined) || (isEdit && ledgerTemp && props.editItem?.public_id !== ledgerTemp.public_id)) {
            return translate("providers.ledger-accounts.warning_ledger", { "nombre": ledgerTemp.name }) as string;
        }
        if (!request.use_all_cost_centers) {
            if (request.cost_centers.length === 0) {
                return translate("providers.ledger-accounts.warning_cecos") as string;
            }
            return "";
        }
        return "all";

    };

    const onSave = () => {
        setSubmitting(true);
        let message = validateForm();
        if (message === "") {
            props.onSave(request, oldAccount);
        } else if (message === "all") {
            props.onSave({ ...request, cost_centers: [] }, oldAccount);
        } else {
            setSubmitting(false);
            setWarning(message);
        }
    }

    const onChangedCeco = (selected: string[]) => {
        if (!selected) { }
        setRequest({ ...request, cost_centers: selected });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadio((event.target as HTMLInputElement).value);
        setRequest({ ...request, use_all_cost_centers: (event.target as HTMLInputElement).value === "all" });
    };

    return (
        <DialogPopup open
            title={translate("providers.ledger-accounts.add_title")}
            onClose={props.first ? props.onCancel : props.onClose}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            button={(
                <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={submitting || !request.id
                    || (radio === "select" && (!request.cost_centers || !request.cost_centers.length))}>
                    {translate("buttons.save")}
                </Button>
            )}>
            {status === "loading" && (
                <Progress />
            )}
            {status !== "loading" && status !== "loaded" && (
                <Typography variant="body2" component="h5" color="error" align="center">
                    {status}
                </Typography>
            )}
            {status === "loaded" && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" alignContent="center">
                            <Grid item xs={12}>
                                <Autocomplete
                                    size="small"
                                    options={ledgerAccounts}
                                    getOptionLabel={(elemento) => elemento.name}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={translate("providers.ledger-accounts.ledger_account") as string} fullWidth />
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            onChangedLedgerAccount(newValue.id);
                                        } else {
                                            onChangedLedgerAccount("");
                                        }
                                    }}
                                    value={isEdit ? ledgerAccounts.find(la => la.id === request.id) : undefined}
                                    getOptionSelected={(opt, val) => opt?.id === val?.id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="cecos" name="cecos1" value={radio} onChange={handleChange}>
                                        <FormControlLabel value="all" control={<Radio />} label={translate("providers.ledger-accounts.all_cecos") as string} disabled={request.id === undefined || request.id === ""} />
                                        <FormControlLabel value="select" control={<Radio />} label={translate("providers.ledger-accounts.some_cecos") as string} disabled={request.id === undefined || request.id === ""} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <MultiselectDropList
                                    disabled={radio === "all"}
                                    title={translate("providers.ledger-accounts.cost_centers") as string}
                                    margin="dense"
                                    elementos={costCenters}
                                    value={isEdit ? costCenters.filter(cc => request.cost_centers.find(rcc => rcc === cc.value)) : undefined}
                                    onChanged={onChangedCeco} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}