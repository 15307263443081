import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import translate from "../i18n/Translator";
import { Box, Button, Divider, Grid, ListItemText, Typography } from "@material-ui/core";
import { AddIcon, VerifiedUserIcon } from "../components/Icons";
import { TaxStampMetadata } from "../model/StampTaxMetadata";
import { getStampTaxCert } from "../api/TaxStampMetadataApi";
import { ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import DateFormat from "../components/DateFormat";
import UpdateCertificateModal from "./ConfigureCertificateModals/UpdateCertificateModal";
import UpdateCfdiDataModal from "./ConfigureCertificateModals/UpdateCfdiDataModal";

export default function ConfigureCertificate(){
    const context = useContext(AppContext);
    const [taxStampMetadata, setTaxStampMetadata] = useState<TaxStampMetadata>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const load = () => {
        setSubmitting(true);
        getStampTaxCert(context.session?.provider?.rfc || "").then(response => {
            setTaxStampMetadata(response);
        }).catch(error => {
            console.log(error.message);
        }).finally(() => setSubmitting(false));
    };
    
    useEffect(load, [context]);

    return (<Surface 
            title={translate("tax_stamp.title") as string} 
            icon={<VerifiedUserIcon/>}
            className="PaperPagination">
                <PrintCertificateData
                    setTaxStampMetadata={setTaxStampMetadata}
                    submitting={submitting}
                    taxStampMetadata={taxStampMetadata}
                />
        </Surface>);
}

export interface PrintCertificateDataProps{
    taxStampMetadata?: TaxStampMetadata;
    submitting: boolean;
    setTaxStampMetadata(response: TaxStampMetadata) : any;
}

export function PrintCertificateData(props: PrintCertificateDataProps) {
    const [error, setError] = useState<string>();
    const [openCertModal, setOpenCertModal] = useState<boolean>(false);
    const [openCfdiDataModal, setOpenCfdiDataModal] = useState<boolean>(false);
    const context = useContext(AppContext);

    const onOpenCertModal = () => {
        setOpenCertModal(true);
    };

    const onOpenCfdiDataModal = () => {
        setOpenCfdiDataModal(true);
    };
    
    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const onCloseCertModal = () => {
        setOpenCertModal(false);
    };

    const onCloseCfdiDataModal = () => {
        setOpenCfdiDataModal(false);
    };

    const onUpdateCertificate = (response: TaxStampMetadata) => {
        setOpenCertModal(false);
        props.setTaxStampMetadata(response);
        if(context.session && context.session.provider){
            context.session.provider.status_membership_tax_stamp = response.status_membership_tax_stamp;
        }
    };

    const onUpdateCfdiData = (response: TaxStampMetadata) => {
        setOpenCfdiDataModal(false);
        props.setTaxStampMetadata(response);
        if(context.session && context.session.provider){
            context.session.provider.status_membership_tax_stamp = response.status_membership_tax_stamp;
        }
    };

    return (
        <Box px={2} py={2}>
        <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography><b>{translate("tax_stamp.cert_title")}</b></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                                <Grid item>
                                    <Button onClick={onOpenCertModal} variant="text" color="primary" startIcon={<AddIcon/>}>
                                        {translate(!!props.taxStampMetadata ? "tax_stamp.update_cert" : "tax_stamp.add_cert")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{translate("tax_stamp.cert_text")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.cer_filename ? 
                                            props.taxStampMetadata?.cer_filename
                                            : "-"}
                                        secondary={translate("tax_stamp.cer")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.key_filename ? 
                                            props.taxStampMetadata?.key_filename
                                            : "-"}
                                        secondary={translate("tax_stamp.key")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.serial_number ? 
                                            props.taxStampMetadata?.serial_number
                                            : "-"}
                                        secondary={translate("tax_stamp.serial")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.cer_not_before ? 
                                            translate("tax_stamp.due_date_text", { 
                                                "date_before": <DateFormat date={props.taxStampMetadata?.cer_not_before} format="L" />, 
                                                "date_after": <DateFormat date={props.taxStampMetadata?.cer_not_after} format="L" />})
                                            : "-"}
                                        secondary={translate("tax_stamp.due_date")} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/><Divider/><br/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography><b>{translate("tax_stamp.cfdi_data_title")}</b></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                                <Grid item>
                                    <Button onClick={onOpenCfdiDataModal} variant="text" color="primary" startIcon={<AddIcon/>}>
                                        {translate("tax_stamp.update_cfdi_data")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{translate("tax_stamp.cfdi_data_text")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.serie_rep ? 
                                            props.taxStampMetadata?.serie_rep
                                            : "-"}
                                        secondary={translate("tax_stamp.rep")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.initial_folio ? 
                                            props.taxStampMetadata?.initial_folio
                                            : "-"}
                                        secondary={translate("tax_stamp.rep_serial")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.logo_url ? 
                                            <Grid container style={{ "overflow": "scroll", height: "60px", width: "155px"}}>
                                                <img src={props.taxStampMetadata.logo_url} alt="new" />
                                            </Grid>
                                            : <></>}
                                        secondary={translate("tax_stamp.logo")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={props.taxStampMetadata?.postal_code ? 
                                            props.taxStampMetadata?.postal_code
                                            : "-"}
                                        secondary={translate("tax_stamp.address")} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {openCertModal &&
                    <UpdateCertificateModal 
                        onClose={onCloseCertModal}
                        onUpdateCertificate={onUpdateCertificate}
                        isUpdate={!!props.taxStampMetadata} />
                }
                {openCfdiDataModal &&
                    <UpdateCfdiDataModal onClose={onCloseCfdiDataModal} 
                        onUpdateCfdiData={onUpdateCfdiData} 
                        disableEditFolio={props.taxStampMetadata?.disableEditFolio || false} 
                        taxStampMetadata={props.taxStampMetadata}/>
                }
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <CustomBackdrop open={props.submitting} message={translate("cfdis.processing") as string} />
            </Grid></Box>
    );
}