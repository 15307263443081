import React, { useState, useEffect, useContext, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { Grid, Divider, Button, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArchiveIcon from "@material-ui/icons/Archive";
import OpenIcon from "@material-ui/icons/FolderOpen";

import { listWarehouseDeliveries, importWarehouseDeliveries, exportWarehouseDeliveries, exportSelectedWarehouseDeliveries } from "../api/WarehouseDeliveryAPI";
import { listWarehouseDeliveries as listProviderWarehouseDeliveries } from "../api/ProviderWarehouseDeliveryAPI";
import { AppContext } from "../context/AppContext";
import { WarehouseDeliveries, WarehouseDeliveriesQueryParams, STATUSES as WarehouseDeliveryStatuses, WarehouseDelivery } from "../model/WarehouseDelivery";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable, { GridableColumn } from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import NumberFormat from "react-number-format";
import WarehouseDeliveryMenu from "./WarehouseDeliveryMenu";

import { Family } from "../model/Connector";
import { ExportableObjectListCallbacks, ExportableObjectNameColumnTitle, ExportableObjectNameColumnValue } from "../components/ExportableObjectColumns";
import ExportableComponent from "../exportable/ExportableComponent";

export function OpenWarehouseDeliveriesList() {
    return (
        <WarehouseDeliveriesList status="O" icon={<OpenIcon />} />
    );
}

export function ArchiveWarehouseDeliveriesList() {
    return (
        <WarehouseDeliveriesList icon={<ArchiveIcon />} />
    );
}

interface WarehouseDeliveriesListProps {
    status?: "O";
    icon: ReactNode;
}

function WarehouseDeliveriesList(props: WarehouseDeliveriesListProps) {
    const context = useContext(AppContext);
    const providerId = context.session!.provider?.id;
    const warehouseDeliveryLabels = WarehouseDeliveryStatuses.map(status => translate(`warehouse_deliveries.status.${status}`) as string);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): WarehouseDeliveriesQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status": props.status || (typeof qs["status"] === "string" ? qs["status"] as string : ""),
        } as WarehouseDeliveriesQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<WarehouseDeliveries>();
    const [params, setParams] = useState<WarehouseDeliveriesQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<WarehouseDeliveriesQueryParams>(paramsFromQueryString);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const [warehouseDelivery, setWarehouseDelivery] = useState<WarehouseDelivery>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const getPromise = (): Promise<WarehouseDeliveries> => {
        const offset = getOffset(page, pageSize);
        if (providerId) {
            return listProviderWarehouseDeliveries(context.session!.tenant!.id, providerId, pageSize, offset, params);
        }
        return listWarehouseDeliveries(context.session!.tenant!.id, pageSize, offset, params);
    };

    const load = () => {
        getPromise().then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";
        if (props.status) {
            delete qs["status"];
        } else {
            qs["status"] = workingParams.status === "" || workingParams.status === "---" ? "" : workingParams.status;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setParams(workingParams);
        if (props.status) {
            setParams({ ...workingParams, status: props.status });
        }
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onStatusChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, status: (value === "" || value === "---" ? "" : value) });
    };

    const onClickedOptions = (warehouseDelivery: WarehouseDelivery) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWarehouseDelivery(warehouseDelivery);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setWarehouseDelivery(undefined);
    };

    const onClicked = (warehouseDelivery: WarehouseDelivery) => {
        history.push(`/warehouse-deliveries/${warehouseDelivery.id}`);
    };

    const handleCheck = (warehouseDelivery: WarehouseDelivery) => {
        let items = selectedIds.length === 0 ? ([] as string[]) : selectedIds.join(",").split(",");
        const index = selectedIds.indexOf(warehouseDelivery.id);
        if (index < 0) {
            items.push(warehouseDelivery.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedIds(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.items.map(warehouseDelivery => warehouseDelivery.id);
        } else {
            items = [] as string[];
        }
        setSelectedIds(items);
    };

    const callbacks = {
        handleCheck: handleCheck,
        handleAllChecks: handleAllChecks
    } as ExportableObjectListCallbacks;

    const getColumns = () => {
        const columns = [
            {
                title: (
                    <ExportableObjectNameColumnTitle
                        total={data?.items.length || 0}
                        selected={selectedIds.length}
                        callbacks={callbacks} />
                ),
                converter: (warehouseDelivery) => (
                    <ExportableObjectNameColumnValue
                        exportableObject={warehouseDelivery}
                        checked={selectedIds.indexOf(warehouseDelivery.id) >= 0}
                        callbacks={callbacks}
                    />
                ),
                fullWidth: true,
                xs: true
            },
            {
                title: translate("warehouse_deliveries.short_document_number") as string,
                converter: (warehouseDelivery) => warehouseDelivery.document_number || "---",
                fullWidth: true,
                xs: false,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1
            },
            providerId ? undefined : {
                title: translate("warehouse_deliveries.provider") as string,
                converter: (warehouseDelivery) => (
                    <Ellipsis text={warehouseDelivery.provider?.name || "---"} lenght={100} uppercased={false} />
                ),
                fullWidth: true,
                xs: 3,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("warehouse_deliveries.total")}</strong>
                    </Typography>
                ),
                converter: (warehouseDelivery) => (
                    <Typography variant="body2" component="h6" align="right">
                        <NumberFormat value={warehouseDelivery.total} prefix="$ " suffix={` ${warehouseDelivery.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                    </Typography>
                ),
                fullWidth: true,
                xs: providerId ? 5 : 4,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: translate("warehouse_deliveries.date") as string,
                converter: (warehouseDelivery) => (
                    <DateFormat date={warehouseDelivery.date} format="YYYY-MM-DD" />
                ),
                fullWidth: true,
                xs: false,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: translate("warehouse_deliveries.due_date") as string,
                converter: (warehouseDelivery) => (
                    <DateFormat date={warehouseDelivery.date} format="YYYY-MM-DD" />
                ),
                fullWidth: true,
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                xl: 2
            },
            {
                title: translate("warehouse_deliveries.status.title") as string,
                converter: (warehouseDelivery) => translate(`warehouse_deliveries.status.${warehouseDelivery.status}`),
                fullWidth: true,
                xs: false,
                sm: 2,
                md: 2,
                lg: 1,
                xl: 1
            },
            providerId ? {
                title: (
                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                        <MoreVertIcon />
                    </IconButton>
                ),
                converter: (workflow) => (
                    <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(workflow)}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: "auto"
            } : undefined
        ] as GridableColumn<WarehouseDelivery>[];
        return columns.filter(c => !!c);
    };

    return (
        <Pagination title={translate("warehouse_deliveries.title")} icon={props.icon}
            subtitle={translate(props.status ? "warehouse_deliveries.open" : "warehouse_deliveries.archive")}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("WarehouseDeliveriesCreate") ? (
                <ExportableComponent
                    tenantId={context.session?.tenant?.id || ""}
                    family={Family.WAREHOUSE_DELIVERIES}
                    selectedIds={selectedIds}
                    total={data?.total}
                    createAction="WarehouseDeliveriesCreate"
                    getAction="WarehouseDeliveriesRead"
                    onImported={load}
                    getImportPromise={(tenantId, file) => importWarehouseDeliveries(tenantId, file)}
                    getExportSelectedPromise={(tenantId, connectorId) => exportSelectedWarehouseDeliveries(tenantId, connectorId, selectedIds)}
                    getExportWithParamsPromise={(tenantId, connectorId) => exportWarehouseDeliveries(tenantId, connectorId, workingParams)}
                />
            ) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("warehouse_deliveries.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    {!props.status && (
                        <Grid item xs="auto">
                            <ValidatedInput type="text" id="status" name="status" label={translate("warehouse_deliveries.status.title") as string}
                                options={WarehouseDeliveryStatuses} optionLabels={warehouseDeliveryLabels}
                                margin="dense" disabled={false}
                                value={workingParams.status} onValueChanged={onStatusChanged} />
                        </Grid>
                    )}
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("warehouse_deliveries.empty") as string}
                columns={getColumns()}
                onClick={onClicked} />
            {warehouseDelivery && anchorEl &&
                <WarehouseDeliveryMenu warehouseDelivery={warehouseDelivery}
                    anchor={anchorEl}
                    onClose={onCloseOption} />
            }
        </Pagination>
    );
}
