import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";

import translate from "../i18n/Translator";
import { enable2FA, confirm2FA, update2FA } from "../api/MeAPI";
import { User, DoubleFactorAuthenticationRequest } from "../model/User";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Code2FAValidator } from "../components/Validators";
import { disable2fa } from '../api/UserAPI';

export interface User2FAProps {
    user: User;
    onUpdated(user: User): any;
    isPlaformAdministrator: boolean;
}

export default function User2FA(props: User2FAProps) {
    const margin = "dense";
    const code2FAValidator = new Code2FAValidator();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [request, setRequest] = useState<DoubleFactorAuthenticationRequest>();
    const [validations, setValidations] = useState({
        "code": {
            valid: false
        } as InputRef
    } as any);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onEnable2FA = () => {
        setSubmitting(true);
        enable2FA().then((user) => {
            setSuccess(translate("users.security.enabling_2fa") as string);
            cleanForm(user);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onConfirm2FA = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        confirm2FA(request!).then((user) => {
            setSuccess(translate("users.security.confirmed_2fa") as string);
            cleanForm(user);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const onDisable2FA = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (props?.isPlaformAdministrator) {
            setSubmitting(true);
            disable2fa(props.user.id).then((user) => {
                setSuccess(translate("users.security.user_disabled_2fa", { "user": props.user.name }) as string);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {

            if (!isValid()) {
                return;
            }

            setSubmitting(true);
            update2FA(request!).then((user) => {
                setSuccess(translate("users.security.disabled_2fa") as string);
                cleanForm(user);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }

    const cancel2FA = () => {
        setSubmitting(false);
        update2FA().then((user) => {
            setSuccess(translate("users.security.disabled_2fa") as string);
            cleanForm(user);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const cleanForm = (user: User) => {
        setRequest({} as DoubleFactorAuthenticationRequest);
        props.onUpdated(user);
    };

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    return (
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="center">
            {!props.isPlaformAdministrator && props.user.status_2fa === "DISABLED" &&
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                {translate("users.security.2fa_status.DISABLED")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="ExternButton">
                            <Button variant="contained" color="primary" fullWidth size="large" disabled={submitting} onClick={onEnable2FA}>
                                {translate("users.security.buttons.enable_2fa")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {!props.isPlaformAdministrator && props.user.status_2fa === "NOT_VERIFIED" &&
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="flex-start" alignContent="center" justify="center">
                        <Grid item xs="auto">
                            <img src={props.user.qr_url_2fa!} alt="2FA QR"></img>
                        </Grid>
                        <Grid item xs={12} md>
                            <Typography variant="caption">
                                {translate("users.security.2fa_status.NOT_VERIFIED", { "secret": props.user.secret_2fa })}
                            </Typography>
                            <form autoComplete="off" noValidate onSubmit={onConfirm2FA} >
                                <Grid container alignItems="center" alignContent="center" justify="center">
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="code" name="code" value={request?.code}
                                            label={translate("users.2fa_code") as string} maxLength={6}
                                            onValueChanged={hasChanged} disabled={false}
                                            required validator={code2FAValidator}
                                            margin={margin} />
                                    </Grid>
                                    <Grid item xs={12} className="ExternButton">
                                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                                            {translate("users.security.buttons.enable_2fa")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="button" variant="outlined" color="primary" fullWidth size="large" disabled={submitting} onClick={cancel2FA}>
                                            {translate("users.security.buttons.disable_2fa")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {props.user.status_2fa === "ENABLED" &&
                <Grid item xs={12}>
                    <form autoComplete="off" noValidate onSubmit={onDisable2FA} >
                        <Grid container alignItems="center" alignContent="center" justify="center">
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    {translate(props?.isPlaformAdministrator ? "users.security.user_disbale_2fa_status" : "users.security.2fa_status.ENABLED")}
                                </Typography>
                            </Grid>
                            {!props.isPlaformAdministrator && <Grid item xs={12}>
                                <ValidatedInput type="text" id="code" name="code" value={request?.code}
                                    label={translate("users.2fa_code") as string} maxLength={6}
                                    onValueChanged={hasChanged} disabled={false}
                                    required validator={code2FAValidator}
                                    margin={margin} />
                            </Grid>}
                            <Grid item xs={12} className="ExternButton">
                                <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                                    {translate("users.security.buttons.disable_2fa")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            }
            {props?.isPlaformAdministrator && props.user.status_2fa === "DISABLED" &&
                <Typography variant="caption">
                    {translate("users.security.user_disbale_2fa")}
                </Typography>}

            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Grid>
    );
}