import React, { useState, useEffect, useContext, ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Typography } from "@material-ui/core";
import { CustomerDocumentLogsIcon, SendIcon, DownloadIcon } from "../components/Icons";

import { listPendingToSendCustomerDocumentLogs, createCustomerDocumentLog } from "../api/CustomerDocumentLogAPI";
import { AppContext } from "../context/AppContext";
import { CustomerDocumentLog, CustomerDocumentLogs, CustomerDocumentLogsQueryParams, CustomerDocumentLogRequest } from "../model/CustomerDocumentLog";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import DateFormat from "../components/DateFormat";
import { greenDot, redDot, orangeDot } from "../contributions/ContributionsTable";
import Tooltip from "@material-ui/core/Tooltip";
import { getDocumentName } from "../model/Customer";

export default function CustomerDocumentLogsList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const [request, setRequest] = useState<CustomerDocumentLogRequest>({} as CustomerDocumentLogRequest);

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CustomerDocumentLogs>();
    const [workingParams, setWorkingParams] = useState<CustomerDocumentLogsQueryParams>({
        search: search,
    } as CustomerDocumentLogsQueryParams);

    const [customerDocumentLog, setCustomerDocumentLog] = useState<CustomerDocumentLog>();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || ""
        } as CustomerDocumentLogsQueryParams;
        setWorkingParams(params);

        listPendingToSendCustomerDocumentLogs(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClosePopup = () => {
        onCloseOption();
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseOption = () => {
        setCustomerDocumentLog(undefined);
    };

    const onClickRow = (customerDocumentLog: CustomerDocumentLog) => {
        // history.push(`/customer-document-log/${ customerDocumentLog.id }/edit`);
    };

    function getIcon(customerDocumentLog: CustomerDocumentLog): ReactNode {
        let content: ReactNode;

        if(customerDocumentLog.status === "PREPARING"){
            content = redDot;
        } else if (customerDocumentLog.status === "TO_SEND_IN_TIME") {
            content = greenDot;
        } else if (customerDocumentLog.status === "TO_SEND_OVERDUE") {
            content = orangeDot;
        }
    
        return (
            <Typography component="h6" variant="body2" align="center">
                {content}
            </Typography>
        )
    }

    const onSend = (customerDocumentLog: CustomerDocumentLog) => {
        if(customerDocumentLog.status !== "PREPARING"){
            setCustomerDocumentLog(customerDocumentLog);
            setRequest({
                id_custom_shipping_requirement: customerDocumentLog.id_custom_shipping_requirement,
                status: customerDocumentLog.status,
                comments: customerDocumentLog.comments,
                due_date: customerDocumentLog.due_date,
                url: customerDocumentLog.url
            } as CustomerDocumentLogRequest);
        }
    };

    const onConfirmSend = () => {
        setSubmitting(true);
        createCustomerDocumentLog(tenantId, request).then((customerDocumentLog) => {
            setSuccess(translate("customer_document_logs.success_send") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setCustomerDocumentLog(undefined);
        });
    };

    const hasChanged = (name: string, value: string ) => {
        setRequest({ ...request, [name]: value });
    };

    return (
        <Pagination title={translate("customer_document_logs.title")} icon={<CustomerDocumentLogsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} >
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("customer_document_logs.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("customer_document_logs.empty") as string}
                onClick={ onClickRow }
                columns={[
                    {
                        title: "",
                        converter: (customerDocumentLog) => getIcon(customerDocumentLog),
                        xs: 1,
                    },
                    {
                        title: translate("customer_document_logs.document_name") as string,
                        converter: (customerDocumentLog) => getDocumentName( customerDocumentLog.custom, customerDocumentLog.document_name),
                        xs: 3,
                    },{
                        title: translate("customer_document_logs.client_name") as string,
                        converter: (customerDocumentLog) => (
                            customerDocumentLog.customer_name
                        ),
                        xs: 4,
                    },
                    {
                        title: translate("customer_document_logs.due_date") as string,
                        converter: (customerDocumentLog) => <DateFormat date={customerDocumentLog.due_date} format="DD/MM/YYYY" />,
                        xs: 3,
                    },
                    {
                        title: "",
                        converter: (customerDocumentLog) => (
                            <IconButton aria-label="options" color={customerDocumentLog.status === "PREPARING" ? "default" : "primary"} size="small" onClick={() => onSend(customerDocumentLog)} >    
                                <Tooltip title={customerDocumentLog.status === "PREPARING" ? translate("customer_document_logs.tooltip") as string : ""}>
                                        <SendIcon />
                                </Tooltip>
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            { customerDocumentLog && (
                <DialogPopup
                open
                title={getDocumentName( customerDocumentLog.custom, customerDocumentLog.document_name)}
                onClose={onClosePopup}
                closeColor="default"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                disable={submitting}
                maxWidth="md"
                button={
                    <Button
                    onClick={onConfirmSend}
                    variant="outlined"
                    color="secondary"
                    disabled={submitting}
                    >
                        {translate("customer_document_logs.send")}
                    </Button>
                }>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="comments" name="comments"
                                value={ request.comments }
                                label={translate("customer_document_logs.comments") as string}
                                required={ false } disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged}
                                rows={5}
                                maxRows={5} />
                        </Grid>
                            <Grid item xs={12}>
                                <IconButton aria-label="add" color="primary" size="small" href={customerDocumentLog.url} target="_blank" rel="noopener noreferrer">
                                    <DownloadIcon /><Typography variant="body2">{translate("buttons.download")}</Typography>
                                </IconButton>
                            </Grid>
                    </Grid>
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}