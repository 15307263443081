import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Payment } from "../model/Payment";
import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import NumberFormat from 'react-number-format';
import { GridableColumn } from "../components/Gridable";
import PendingPaymentListDashboard from "./PendingPaymentListDashboard";
import ErrorIcon from '@material-ui/icons/Error';
import ReactHtmlParser from "html-react-parser"; 

export default function PaymentsPendingSection(){

    const getColumns = (payments: Payment[]) => {
        let all = [
            {
                title: translate("providers.pending_payments.payment_folio") as string,
                converter: (payment) => payment.operation_number,
                justify: "center",
                xs: 2,
                sm: 2,
                md: 2 ,
                lg: 2 ,
                xl: 2 
            },
            {
                title: translate("payment.columns.date") as string,
                converter: (payment) => <DateFormat date={payment.payment_date} format="L"/>,
                justify: "center",
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3
            },
            {
                title: translate("providers.pending_payments.amount_paid") as string,
                converter: (payment) => (
                    <Grid container justify="space-evenly" >
                        <Grid item xs={12} ><NumberFormat value={payment.total_amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" /></Grid>
                        <Grid item ><Ellipsis text={payment.currency ? payment.currency : ""} lenght={10} secondary /></Grid>
                    </Grid>
                ),
                justify: "center",
                xs: 2,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3
            },
            {
                title: ReactHtmlParser (translate("providers.pending_payments.dead_line_bold") as string),
                converter: (payment) => payment.payment_cfdi_expired ? <DateFormat date={ payment.deadline_payment_cfdi} format="L" /> : <b><DateFormat  date={ payment.deadline_payment_cfdi} format="L" /></b>,
                justify: "center",
                xs: 2,
                sm: 3,
                md: "auto",
                lg: "auto",
                xl: "auto"
            },{
                title: (
                    <IconButton color="default" size="small" style={{ visibility: "hidden" }}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                converter: (payment) => 
                    payment.payment_cfdi_expired ? 
                    <ErrorIcon style={{ "color": "#C33149" }}/> : undefined,
                justify: "center",
                xs: "auto",
                sm: "auto",
                md: "auto",
                lg: 2 ,
                xl: 1 
            }
        ] as GridableColumn<Payment>[];
        return all.filter((column) => column !== null);
    };
    return (
        <PendingPaymentListDashboard getColumns={getColumns} title={translate("payment.pending_cfdi.title") as string}
            success={"true"}
        />
    );
}