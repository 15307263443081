import translate from "../i18n/Translator";

export interface InputValidator {
    isValid(value: string): boolean;
    getMessage(): string;
}

export class RegexValidator implements InputValidator {
    regexp: RegExp;
    message: string;

    constructor(regexp: RegExp, message: string) {
        this.regexp = regexp;
        this.message = message;
    };

    isValid = (value: string) => !value || this.regexp.test(value);
    getMessage = () => translate(this.message) as string;
}

export class EmailValidator extends RegexValidator {
    constructor() {
        //eslint-disable-next-line
        super(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "validations.email");
    };
}

export class EmailsListValidator extends EmailValidator {
    isValid = (value: string) => {
        if (!value) {
            return true;
        }
        if(value.endsWith(",")){
            return false;
        }else{
            let emails = value.split(",");
            return emails.filter(el => !this.regexp.test(el)).length == 0 ;
        }
    };
    getMessage = () => translate("validations.emails_list") as string;
}

export class PhoneValidator extends RegexValidator {
    constructor() {
        super(/^\d{10}$/, "validations.phone");
    };

    isValid = (value: string) => {
        let fixed = (value || "").replace(/\s|\(|\)|-/g, "");
        return !fixed || this.regexp.test(fixed);
    };
}

export class PostalCodeValidator extends RegexValidator {
    constructor() {
        super(/^\d{5}$/, "validations.postal_code");
    };
}

export class RfcValidator extends RegexValidator {
    constructor() {
        super(/^([A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A])$/i, "validations.rfc");
    };
}

export class NumberValidator extends RegexValidator {
    constructor(onlyPositive?: boolean) {
        //eslint-disable-next-line
        super(onlyPositive ? /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/ : /^\-?\d+(\.\d{1,2})?$/, onlyPositive ? "validations.number_positive" : "validations.number");
    };
}

export class IntegerValidator extends RegexValidator {
    constructor(onlyPositive?: boolean) {
        //eslint-disable-next-line 
        super(onlyPositive ? /^[0-9]+$/ : /^\-?\d+$/, onlyPositive ? "validations.integer_positive" : "validations.integer");
    }
}

export class URLValidator extends RegexValidator {
    constructor() {
        //eslint-disable-next-line
        super(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gi, "validations.url");
    };
}

export class LTEValidator implements InputValidator {
    limit: number;

    constructor(limit: number) {
        this.limit = limit;
    };

    isValid(value: string): boolean {
        if (!value) return true;
        var n = parseFloat(value);
        return !isNaN(n) && n <= this.limit;
    };

    getMessage = () => translate("validations.lte", { "number": this.limit }) as string;
}

export class GTZeroValidator implements InputValidator {
    message?: string;

    constructor(message?: string) {
        this.message = message;
    };

    isValid = function (value: string): boolean {
        if (!value) return true;
        var n = parseFloat(value);
        return !isNaN(n) && n > 0;
    };
    getMessage = () => this.message ?? translate("validations.gt", { "number": 0 }) as string;
}

export class GTEValidator implements InputValidator {
    reference: number;

    constructor(reference: number) {
        this.reference = reference;
    };

    isValid = (value: string) => {
        if (!value) return true;
        var n = parseFloat(value);
        return !isNaN(n) && n >= this.reference;
    };

    getMessage = () => translate("validations.gte", { "number": this.reference }) as string;
}

export class DecimalsValidator implements InputValidator {
    maxDecimal: number;

    constructor(maxDecimal: number) {
        this.maxDecimal = maxDecimal;
    };

    isValid = (value: string) => {
        if (!value) return true;
        if(this.maxDecimal > 0 && value.split(".")[1] !== undefined &&  value.split(".")[1].length > this.maxDecimal){
            return false;
        }
        return true;
    };

    getMessage = () => translate("validations.number_decimals", { "decimals": this.maxDecimal }) as string;;
}

export class GTEZeroValidator extends GTEValidator {
    constructor() {
        super(0);
    }
}

export class RequiredValidator implements InputValidator {
    isValid = (value: string) => !!value;
    getMessage = () => translate("validations.required") as string;
}

export class RemoveSpacesValidator extends RegexValidator {
    constructor() { 
        super(/^\S+$/, "validations.no_spaces");
    };
}

export class PasswordValidator implements InputValidator {
    isValid = (value: string) => !!value && value.length >= 8;
    getMessage = () => translate("validations.password") as string;
}

export class EqualsToValidator implements InputValidator {
    supplier: () => string;
    message: string;

    constructor(supplier: () => string, message: string) {
        this.supplier = supplier;
        this.message = message;
    };

    isValid(value: string): boolean {
        return this.supplier() === value;
    };

    getMessage = () => this.message;
}

export class ConfirmPasswordValidator extends EqualsToValidator {
    constructor(supplier: () => string) {
        super(supplier, translate("validations.confirm_password") as string)
    };
}

export class Code2FAValidator extends RegexValidator {
    constructor() {
        //eslint-disable-next-line
        super(/^\d{6}$/, "validations.code_2fa");
    };
}

export class CLABEValidator implements InputValidator {

    isValid = (value: string) => {
        if (!value) return true;

        if (/\s/g.test(value)){
            return false;
        }

        let m = [3, 7, 1]
        var s = 0;
        for (var i = 0; i < 17; i++) {
            var v = parseInt(value.charAt(i));
            if (isNaN(v)) {
                return false;
            }
            s += ((v * m[i % 3]) % 10);
        }

        let dc = 10 - (s % 10), l = parseInt(value[17]);
        return (!isNaN(l) && ((dc % 10) === l));
    };

    getMessage = () => translate("validations.clabe") as string;

}

export class PercentageValidator implements InputValidator {

    isValid = (value: string) => {
        if (!value) return true;

        return Number.parseFloat(value) > 0 && Number.parseFloat(value) <= 100;
    };

    getMessage = () => translate("validations.percentage") as string;

}

export const phoneFormat = (value: string): string => {
    let fixed = (value || "").replace(/\s|\(|\)|-/g, "");
    if (fixed.startsWith("55") || fixed.startsWith("33") || fixed.startsWith("55")) {
        return "(##) ####-####";
    }
    return "(###) ###-####";
};