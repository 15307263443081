import React from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon, DeleteIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { Budget } from "../model/Budget";

interface BudgetMenuProps {
    budget: Budget;
    isUpdateGranted: boolean;
    isDeleteGranted: boolean;
    isPerformanceExportExcelGranted: boolean;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete?(): any; 
    onPerformanceExportExcel?(): any;
}

export default function BudgetMenu(props: BudgetMenuProps) {
 
    return (
        <Menu
            id="budgets-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {props.isUpdateGranted && (
                <MenuItem button component={Link} to={`/budgets/${props.budget.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            {props.isDeleteGranted && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.delete")}
                    </Typography>
                </MenuItem>
            )}
            {props.isPerformanceExportExcelGranted && (
                <MenuItem button onClick={props.onPerformanceExportExcel} dense>
                    <Typography variant="inherit">
                        {translate("budget.performance.export_excel")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}