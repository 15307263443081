import React, { useState, useEffect, useContext, useRef } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { getTenantCfdis, exportPendingPaymentCfdis, exportAllCfdis, postponePayment, getActorFieldsTypeCatalogByStage, exportValidationsPdfCfdis } from "../api/TenantCfdiApi";
import { deleteCfdi, exportCfdisExcel, cancelCfdi, exportCfdisSelected, exportForErpAll, exportForErpSelected } from "../api/TenantCfdiApi";
import { DeleteCfdiRequest, TenantCfdisRequest, CfdiPostponeRequest, ExportCfdisResponse } from "../model/TenantCfdi";
import { importPayments } from "../api/PaymentApi";
import { Grid, Divider, Button, Fab, IconButton, Hidden } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { ActorStageFieldsResponse, Cfdi, Cfdis, CfdisQueryParams, FilterField, FilterPendingPayments } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import AuthorizedIcon from '@material-ui/icons/LibraryAddCheckTwoTone';
import TenantCfdiMenuPendingPayment from "../cfdis/menus/TenantCfdiMenuPendingPayment";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import { CfdisListProps } from "../cfdi/CfdisList";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CfdisMenu from "../cfdis/menus/TenantCfdisMenu";
import PaymentIcon from '@material-ui/icons/Payment';
import PaymentInfoPopup from "./PaymentInfoPopup";
import { PaymentData, CfdisSelected, ImportPaymentSummary } from "../model/Payment";
import FilterPopup from "../components/FilterPopup";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import PublishIcon from "@material-ui/icons/Publish";
import ImportPaymentResumePopup from "./ImportPaymentResumePopup";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import ReactHtmlParser from "html-react-parser";
import { Filter } from "@devexpress/dx-react-grid";
import { formatDateString, concatDates, stringToDate, stringToDateEnd } from "../components/DateFormat";
import GridDx from "../components/GridDx";
import Surface from "../components/Surface";
import PostponePaymentPopup from "./PostponePaymentPopup";
import moment from "moment";
import DownloadRange from "../components/DownloadRange";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";
import DeleteCfdiPopup from "../cfdis/popups/DeleteCfdiPopup";

interface PendingPaymentListCfdisProps extends CfdisListProps {
    successMessage?: string | JSX.Element | JSX.Element[];
    next(info: PaymentData): any;
    goToCreatePaymentOrder(cfdis: CfdisSelected): any;
}

export default function PendingPaymentListCfdis(props: PendingPaymentListCfdisProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status_list": typeof qs["status_list"] === "string" ? qs["status_list"] as string : 'PENDING_PAYMENT,PAYMENT_REJECTED',
            "start_date": typeof qs["start_date"] === "string" && qs["start_date"] !== "" && qs["start_date"] !== "NaN-NaN-NaN" ? stringToDate(qs["start_date"]) : undefined,
            "end_date": typeof qs["end_date"] === "string" ? stringToDateEnd(qs["end_date"]) : new Date(),
            "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : "",
            "selected_ids": typeof qs["selected_ids"] === "string" ? qs["selected_ids"] as string : "",
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "issuer": typeof qs["issuer"] === "string" ? qs["issuer"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "send_at_start": typeof qs["send_at_start"] === "string" ? qs["send_at_start"] as string : "",
            "send_at_end": typeof qs["send_at_end"] === "string" ? qs["send_at_end"] as string : "",
            "type_filter": typeof qs["type_filter"] === "string" ? qs["type_filter"] as string : "",
            "payment_deadline_start": typeof qs["payment_deadline_start"] === "string" ? qs["payment_deadline_start"] as string : "",
            "payment_deadline_end": typeof qs["payment_deadline_end"] === "string" ? qs["payment_deadline_end"] as string : "",
            "payment_due_start": typeof qs["payment_due_start"] === "string" ? qs["payment_due_start"] as string : "",
            "payment_due_end": typeof qs["payment_due_end"] === "string" ? qs["payment_due_end"] as string : "",
            "authorized_at_start": typeof qs["authorized_at_start"] === "string" ? qs["authorized_at_start"] as string : "",
            "authorized_at_end": typeof qs["authorized_at_end"] === "string" ? qs["authorized_at_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
            "referer_view": "PENDING_PAYMENT",
            "filter_fields": typeof qs["filter_fields"] === "string" && qs["filter_fields"] ? JSON.parse(qs["filter_fields"] as string) : undefined,
        } as CfdisQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>({ items: [] as Cfdi[] } as Cfdis);
    const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);

    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openConfirmDialogDwnAllCfdis, setOpenConfirmDialogDwnAllCfdis] = useState<boolean>(false);
    const [openConfirmDialogRangeErpCfdis, setOpenConfirmDialogRangeErpCfdis] = useState<boolean>(false);
    const [openConfirmDialogErpCfdis, setOpenConfirmDialogErpCfdis] = useState<boolean>(false);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const fileInput = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState(false);
    const [importPaymentSummary, setImportPaymentSummary] = useState<ImportPaymentSummary>();
    const [openConfirmDialogDelete, setOpenConfirmDialogDelete] = useState<boolean>(false);
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [userId] = useState<string>(context.isGranted("ReceptorCFDIsRead") ? context.session!.user.id : "");
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const [postpone, setPostpone] = useState<boolean>(false);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openExportExcelSelected, setOpenExportExcelSelected] = useState<boolean>(false);
    const [fieldsCatalogType, setFieldsCatalogType] = useState<ActorStageFieldsResponse>();
    const [openDownloadValidationsPdf, setOpenDownloadValidationsPdf] = useState<boolean>(false);

    const paramsToFilters = () => {
        return [
            { columnName: 'identifier', value: params.identifier },
            { columnName: 'issuer', value: params.issuer },
            { columnName: 'description', value: params.description },
            { columnName: 'send_at', value: concatDates(params.send_at_start, params.send_at_end) },
            { columnName: 'type', value: params.type_filter },
            { columnName: 'payment_deadline', value: concatDates(params.payment_deadline_start, params.payment_deadline_end) },
            { columnName: 'payment_due', value: concatDates(params.payment_due_start, params.payment_due_end) },
            { columnName: 'authorized_at', value: concatDates(params.authorized_at_start, params.authorized_at_end) },
            { columnName: 'total', value: params.total },
            { columnName: 'balance', value: params.balance },
            { columnName: 'currency', value: params.currency },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());


    const load = () => {
        setStatus("loading");
        pushHistory();
        if (props.successMessage && isSuccess) {
            setSuccess(props.successMessage);
            setIsSuccess(false);
        }

        getTenantCfdis(context.session!.tenant!.id, userId, pageSize, pageSize * page, params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
        let success_pospone = typeof qs["success_postpone"] === "string" ? qs["success_postpone"] as string : "";
        if (success_pospone.length > 0) {
            setSuccess(ReactHtmlParser(translate("cfdis.popup.postpone_payment.success",
                { "date": success_pospone, "link": `/tenant/cfdis/postpone` }) as string));
        }

    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.identifier = filter.value;
                        break;
                    case "issuer":
                        temp.issuer = filter.value;
                        break;
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    case "send_at":
                        let datesSend = filter.value.split(" - ");
                        if (datesSend.length === 2) {
                            temp.send_at_start = formatDateString(datesSend[0]);
                            temp.send_at_end = formatDateString(datesSend[1]);
                        } else {
                            temp.send_at_start = "";
                            temp.send_at_end = "";
                        }
                        break;
                    case "type":
                        temp.type_filter = filter.value;
                        break;
                    case "payment_deadline":
                        let paymentDeadlines = filter.value.split(" - ");
                        if (paymentDeadlines.length === 2) {
                            temp.payment_deadline_start = formatDateString(paymentDeadlines[0]);
                            temp.payment_deadline_end = formatDateString(paymentDeadlines[1]);
                        } else {
                            temp.payment_deadline_start = "";
                            temp.payment_deadline_end = "";
                        }
                        break;
                    case "payment_due":
                        let paymentDues = filter.value.split(" - ");
                        if (paymentDues.length === 2) {
                            temp.payment_due_start = formatDateString(paymentDues[0]);
                            temp.payment_due_end = formatDateString(paymentDues[1]);
                        } else {
                            temp.payment_due_start = "";
                            temp.payment_due_end = "";
                        }
                        break;
                    case "authorized_at":
                        let authorizedAtDates = filter.value.split(" - ");
                        if (authorizedAtDates.length === 2) {
                            temp.authorized_at_start = formatDateString(authorizedAtDates[0]);
                            temp.authorized_at_end = formatDateString(authorizedAtDates[1]);
                        } else {
                            temp.authorized_at_start = "";
                            temp.authorized_at_end = "";
                        }
                        break;
                    case "total":
                        temp.total = filter.value;
                        break;
                    case "balance":
                        temp.balance = filter.value;
                        break;
                    case "currency":
                        temp.currency = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status_list"] = workingParams.status_list;
        qs["selected_ids"] = workingParams.selected_ids ? workingParams.selected_ids : "";
        qs["receptor"] = workingParams.receptor ? workingParams.receptor : "";
        qs["identifier"] = workingParams.identifier ? workingParams.identifier : "";
        qs["issuer"] = workingParams.issuer ? workingParams.issuer : "";
        qs["description"] = workingParams.description ? workingParams.description : "";
        qs["send_at_start"] = workingParams.send_at_start ? workingParams.send_at_start : "";
        qs["send_at_end"] = workingParams.send_at_end ? workingParams.send_at_end : "";
        qs["type_filter"] = workingParams.type_filter ? workingParams.type_filter : "";
        qs["payment_deadline_start"] = workingParams.payment_deadline_start ? workingParams.payment_deadline_start : "";
        qs["payment_deadline_end"] = workingParams.payment_deadline_end ? workingParams.payment_deadline_end : "";
        qs["payment_due_start"] = workingParams.payment_due_start ? workingParams.payment_due_start : "";
        qs["payment_due_end"] = workingParams.payment_due_end ? workingParams.payment_due_end : "";
        qs["authorized_at_start"] = workingParams.authorized_at_start ? workingParams.authorized_at_start : "";
        qs["authorized_at_end"] = workingParams.authorized_at_end ? workingParams.authorized_at_end : "";
        qs["total"] = workingParams.total ? workingParams.total : "";
        qs["balance"] = workingParams.balance ? workingParams.balance : "";
        qs["currency"] = workingParams.currency ? workingParams.currency : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";

        qs["start_date"] = workingParams.start_date ? String(workingParams.start_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.start_date.getDate()).padStart(2, '0') + "-" + workingParams.start_date.getFullYear() : "";
        qs["end_date"] = workingParams.end_date ? String(workingParams.end_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.end_date.getDate()).padStart(2, '0') + "-" + workingParams.end_date.getFullYear() : "";
        qs["provider"] = workingParams.provider;
        qs["filter_fields"] = workingParams.filter_fields ? JSON.stringify(workingParams.filter_fields) : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onAppliedFilter = () => {
        pushHistory();
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onDoFilter = (filterFields: FilterField[]) => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: filterFields }
        setWorkingParams(params);
        setParams(params);
    };

    const onCleanFilter = () => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: undefined }
        setWorkingParams(params);
        setParams(params);
    };

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onClickFilter = () => {
        getActorFieldsTypeCatalogByStage(context.session!.tenant!.id).then((response) => {
            setFieldsCatalogType(response);
            if (response && response.fields && response.fields.length > 0) {
                setOpenFilterDialog(true);
            } else {
                setWarning(translate("cfdis.popup.filter_fields.not_filters_found") as string);
            }
        }).catch((error) => {
            setError(error.message);
        });
    }

    const onClickedClean = () => {
        const params = { ...workingParams, filter_fields: undefined, search:""}
        setWorkingParams(params);
        setParams(params);
    }

    const onDownloadAllCfdis = (startDate: Date, endDate: Date) => {
        setGridAnchorEl(null);
        setOpenBackdrop(true);
        setOpenConfirmDialogDwnAllCfdis(false);

        let paramsTemp = params;
        paramsTemp.start_date = startDate;
        paramsTemp.end_date = endDate;
        exportAllCfdis(context.session!.tenant!.id, userId, paramsTemp).then((response) => {
            showExportResults(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            load();
        });
    }

    const goToSelectCfdis = (request: PaymentData) => {
        setOpenPaymentDialog(false);
        props.next(request);
    }

    const onOpenConfirmDialogDwnAllCfdis = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialogDwnAllCfdis(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const dowdloadCfdiSelected = () => {
        setGridAnchorEl(null);
        if (selected?.length) {
            setOpenBackdrop(true);
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selected } as TenantCfdisRequest).then((response) => {
                showExportResults(response);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
                load();
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenPaymentDialog = () => {
        setOpenPaymentDialog(true);
    }

    const goToCreatePaymentOrder = () => {
        let cfdisSelected = { cfdis: data.items.filter(cfdi => selected.find(cfdiSelected => cfdi.id === cfdiSelected)) } as CfdisSelected;
        let cfdisCancelled = cfdisSelected.cfdis.filter(cfdi => cfdi.metadata.cancelled_by_sat);
        let cfdisCompanies = [] as string[];
        cfdisSelected.cfdis.forEach(c => {
            if (cfdisCompanies.length > 0) {
                let t = cfdisCompanies.filter(cc => cc === c.metadata.company_id);
                if (t === undefined || t.length === 0) {
                    cfdisCompanies.push(c.metadata.company_id);
                }
            } else {
                cfdisCompanies.push(c.metadata.company_id);
            }
        });
        if (cfdisCancelled.length > 0) {
            setCfdisCancelled(cfdisCancelled);
            return;
        }
        if (cfdisCompanies.length > 1) {
            setWarning(translate("payment_order.warning_several_companies_selectec") as string);
            return;
        }
        props.goToCreatePaymentOrder(cfdisSelected);
    }

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onExportCfdis = () => {
        setGridAnchorEl(null);
        setOpenBackdrop(true);

        exportPendingPaymentCfdis(context.session!.tenant!.id, params).then((response) => {
            showExportResults(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            load();
        });
    }

    const onExportForErp = () => {
        setGridAnchorEl(null);
        if (selected?.length) {
            setOpenConfirmDialogErpCfdis(true);
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    };

    const onExportForErpAll = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenConfirmDialogRangeErpCfdis(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    };

    const confirmExportForErp = () => {
        setOpenConfirmDialogErpCfdis(false);
        setOpenBackdrop(true);

        exportForErpSelected(context.session!.tenant!.id, selected as string[]).then((response) => {
            setOpenBackdrop(false);
            showExportResults(response);
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        }).finally(() => {
            load();
        });
    };

    const confirmExportForErpRange = (startDate: Date, endDate: Date) => {
        setOpenConfirmDialogRangeErpCfdis(false);
        setOpenBackdrop(true);
        let paramsTemp = params;
        paramsTemp.start_date = startDate;
        paramsTemp.end_date = endDate;

        exportForErpAll(context.session!.tenant!.id, userId, paramsTemp).then((response) => {
            setOpenBackdrop(false);
            showExportResults(response);
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        }).finally(() => {
            params.start_date = undefined;
            load();
        });

    };

    const showExportResults = (response: ExportCfdisResponse) => {
        if (response.url) {
            downloadURI(response.url);
            setSelected([]);
        } else if (response.total) {
            setSuccess(translate("cfdis.email_export", { "total": response.total }) as string);
            setSelected([]);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    function downloadURI(uri: string) {
        let link = document.createElement("a");
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const openFileImportPayments = () => {
        fileInput.current!.click();
        setGridAnchorEl(null);
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        setOpenBackdrop(true);
        setUploading(true);

        importPayments(context.session!.tenant!.id, event.target.files[0]).then((response) => {
            setSuccess(translate("payment.import.import_s3_success") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setUploading(false);
            setOpenBackdrop(false);
            fileInput.current!.value = "";
        });
    }

    const onCloseImportDialog = () => {
        setImportPaymentSummary(undefined);
        setGridAnchorEl(null);
        fileInput.current!.value = "";
    };

    const onDeleteComprobante = () => {
        setAnchorEl(null);
        setOpenConfirmDialogDelete(true);
    };

    const onConfirmDeleteComprobante = (razon: string) => {
        setOpenConfirmDialogDelete(false);
        setStatus("loading");
        if (context.isGrantedAny(["AdminCFDIsDelete", "CxpCFDIsDelete"])) {
            deleteCfdi(context.session!.tenant!.id, cfdi!.id, { comments: razon } as DeleteCfdiRequest).then(() => {
                setStatus("loaded");
                load();
                setSuccess(translate("cfdis.pay_pending.actions.delete.success") as string);
            }).catch((error) => {
                setStatus("loaded");
                setError(error.message);
            });

        }
    };

    const onCloseConfirmDialogDelete = () => {
        setOpenConfirmDialogDelete(false);
    }

    const onClickedRow = (cfdi: Cfdi) => {
        pushHistory();
        setRedirect(`/cfdis/${cfdi.id}/details?referer=pending-payment&${queryParam}`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onOpenConfirmDialogExportExcel = () => {
        setGridAnchorEl(null);
        setOpenExportExcel(true);
    }

    const onDownloadFiles = (n_params: CfdisQueryParams) => {
        setOpenBackdrop(true);
        exportCfdisExcel(context.session!.tenant!.id, n_params).then((response) => {
            showExportResults(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenExportExcel(false);
            load();
        });
    }

    const onDownloadExcel = () => {
        setOpenConfirmDialogExportExcel(false);
        let n_params = params;
        n_params.selected_ids = undefined;
        onDownloadFiles(n_params);
    }

    const onDownloadSelectedToExcel = () => {
        setGridAnchorEl(null);
        if (selected && selected.length > 0) {
            onDownloadWithConnectorSelected("");
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onCancelCfdi = () => {
        setOpenCancelConfirmDialog(false);
        if (!cfdi) return;
        setOpenBackdrop(true);
        cancelCfdi(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": cfdi.identifier, "cfdi_link": `/cfdis/${response.related_cfdi}/details?referer=pending-payment&${queryParam}` }) as string
                ));
            } else {
                load();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelected(selected);
    };

    const onPostpone = () => {
        setAnchorEl(null);
        setPostpone(true);
    };

    const onCancelledPostpone = () => {
        setPostpone(false);
    };

    const onConfirmPostpone = (datePostpone: Date) => {
        if (!cfdi) return;

        setPostpone(false);
        setStatus("loading");
        let cfdisIds = [] as string[];
        cfdisIds.push(cfdi.id);
        let request = {
            date_postpone: datePostpone,
            cfdi_ids: cfdisIds
        } as CfdiPostponeRequest;

        postponePayment(context.session!.tenant!.id, request).then(() => {
            setSuccess(ReactHtmlParser(translate("cfdis.popup.postpone_payment.success",
                { "date": moment(datePostpone).format("DD/MM/YYYY"), "link": `/tenant/cfdis/postpone` }) as string));
        }).catch((error) => {
            setError(translate("cfdis.popup.postpone_payment.error") as string);
        }).finally(() => {
            setStatus("loaded");
            load();
        });

    }

    const onCloseExporter = () => {
        setOpenExportExcel(false);
        setOpenDownloadValidationsPdf(false)
    };

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenConfirmDialogExportExcel(false);
        setOpenExportExcel(false);
        let n_params = params;
        n_params.selected_ids = undefined;
        n_params.start_date = startDate;
        n_params.end_date = endDate;
        n_params.connector_id = connectorId;
        onDownloadFiles(n_params);
    }

    const onCloseExporterSelected = () => {
        setOpenExportExcelSelected(false);
        setOpenDownloadValidationsPdf(false);
    };

    const onDownloadWithConnectorSelected = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportExcelSelected(false);
        let n_params = params;
        n_params.selected_ids = selected.join(",");
        n_params.start_date = undefined;
        n_params.end_date = undefined;

        if (connectorId) {
            n_params.connector_id = connectorId;
        }

        onDownloadFiles(n_params);
    }

    const onDownloadSelectedValidationsPdf = () => {
        if (selected && selected.length > 0) {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            let exportParams = params;
            exportParams.selected_ids = selected.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        } else {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenConfirmDialogDownloadValidationsPdf = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenDownloadValidationsPdf(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadValidationsPdf = (paramsExport: CfdisQueryParams) => {
        exportValidationsPdfCfdis(context.session!.tenant!.id, paramsExport).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
        });
    }

    const onDownloadDateValidationsPdf = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setGridAnchorEl(null);
        setAnchorEl(null);
        setOpenBackdrop(true);
        setOpenDownloadValidationsPdf(false);
        let exportParams = {...workingParams};
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        onDownloadValidationsPdf(exportParams);
    }

    return (
        <Surface title={props.title}
            icon={<AuthorizedIcon />}
            className="PaperPagination"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["PaymentOrdersCxpCreate"]) ?
                            (<Grid item xs="auto">
                                {selected.length > 0 ?
                                    <Fab color="primary" size="small" title={translate("payment_order.view.create") as string} onClick={goToCreatePaymentOrder} disabled={status === "loading"} >
                                        <AddToPhotosIcon />
                                    </Fab> :
                                    <Fab color="default" size="small" title={translate("payment_order.view.create") as string} onClick={() => { setWarning(translate("payment_order.warning_cfdi") as string) }} >
                                        <AddToPhotosIcon />
                                    </Fab>
                                }
                            </Grid>) : (context.isGranted("PaymentsCreate") && !context.isGranted("PurchaseOrdersCreate")) ?
                                (<Grid item xs="auto">
                                    <Fab color="primary" size="small" title={translate("cfdis.create_payment") as string} onClick={onOpenPaymentDialog} disabled={status === "loading"} >
                                        <PaymentIcon />
                                    </Fab>
                                </Grid>) : undefined}
                        {context.isGrantedAny(["PaymentsCreate"]) ?
                            <Grid item xs="auto" >
                                <Fab color="secondary" size="small" title={translate("cfdis.import_payments") as string} onClick={openFileImportPayments} disabled={status === "loading"} >
                                    <PublishIcon />
                                </Fab>
                            </Grid> : undefined}

                        <Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm>
                    <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onClickFilter} variant={params.filter_fields ? "contained" : "outlined"} color="primary" size="medium">
                        {params.filter_fields? translate("buttons.filters_applied") : translate("buttons.filter")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton color="default" size="small" onClick={onClickedClean} disabled={status === "loading"}>
                        <HighlightOffTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className="focaltec-small">
                <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    columns={props.columns ? props.columns : []}
                    columnsFormat={props.columnsFormat}
                    selectionIds={selected}
                    clickRowColumns={props.clickRowColumns}
                    onClickRow={onClickedRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    defaultExpandedGroups={props.defaultExpandedGroups}
                    amountCurrencyColumns={props.currencyColumns}
                    dateColumns={props.dateColumns}
                    leftColumns={props.leftColumns}
                    textColumns={props.textColumns}
                    rightColumns={props.rightColumns}
                    filters={filters}
                    grouping={props.grouping}
                    customFormatColumns={props.customPlugins}
                    setFiltersHandler={setFiltersHandler}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    getAll={true}
                    setTableCell={props.tableCell}
                    setCheckCell={props.checkCell}
                />
            </div>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {cfdi && anchorEl && (
                <TenantCfdiMenuPendingPayment
                    cfdi={cfdi}
                    anchor={anchorEl}
                    referer={`pending-payment&${queryParam}`}
                    onCancel={onOpenCancelCfdi}
                    onClose={onCloseOption}
                    onDeleteComprobante={onDeleteComprobante}
                    onPostpone={onPostpone} />
            )}
            {openConfirmDialogDwnAllCfdis && (
                <DownloadRange
                    onClose={() => setOpenConfirmDialogDwnAllCfdis(false)}
                    onExportRange={onDownloadAllCfdis}
                    family="CFDIS"
                    maxDaySelected={31} />
            )}
            {openConfirmDialogRangeErpCfdis && (
                <DownloadRange
                    onExportRange={confirmExportForErpRange}
                    onClose={() => setOpenConfirmDialogRangeErpCfdis(false)}
                    message={translate("cfdis.confirm_export_erp.message_range") as string}
                    family="CFDIS"
                    maxDaySelected={31}
                />
            )}
            {openConfirmDialogErpCfdis && (
                <CfdisConfirmPopup
                    doAction={confirmExportForErp}
                    onClose={() => setOpenConfirmDialogErpCfdis(false)}
                    title={translate("cfdis.confirm_export_erp.title") as string}
                    message={translate("cfdis.confirm_export_erp.message", { "N": selected.length }) as string}
                    button={translate("buttons.confirm") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {gridAnchorEl && (
                <CfdisMenu anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onDownloadAll={onOpenConfirmDialogDwnAllCfdis}
                    onDownloadSelected={dowdloadCfdiSelected}
                    onDownloadExcelSelected={onDownloadSelectedToExcel}
                    onDownloadExcel={onOpenConfirmDialogExportExcel}
                    onExportCfdis={onExportCfdis}
                    onExportForErp={onExportForErp}
                    onExportForErpAll={onExportForErpAll}
                    onDownloadValidationsPdf={onOpenConfirmDialogDownloadValidationsPdf}
                    onDownloadValidationsPdfSelected={onDownloadSelectedValidationsPdf}
                />
            )}
            {openPaymentDialog && (
                <PaymentInfoPopup
                    tenantId={context.session!.tenant!.id}
                    doAction={goToSelectCfdis}
                    onClose={() => setOpenPaymentDialog(false)} />
            )}
            {openFilterDialog && fieldsCatalogType && (
                <FilterPopup
                    filterFields={fieldsCatalogType}
                    tenantId={context.session!.tenant!.id}
                    appliedFilters={workingParams.filter_fields}
                    doAction={onDoFilter}
                    onCleanFilter={onCleanFilter}
                    onClose={() => setOpenFilterDialog(false)} />
            )}

            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".csv, .xlsx, .xls, .xml, .json, .txt, .tsv" />
            <CustomBackdrop open={uploading} message={translate("providers.importing") as string} />

            {importPaymentSummary && (
                <ImportPaymentResumePopup summaryImport={importPaymentSummary} onClose={onCloseImportDialog} />
            )}

            {openConfirmDialogDelete && cfdi && (
                <DeleteCfdiPopup
                    cfdi={cfdi}
                    messageDefault={translate("cfdis.pay_pending.actions.delete.description", { "provider": cfdi ? cfdi.cfdi?.emisor.nombre : "" }) as string}
                    onConfirmDeleteComprobante={onConfirmDeleteComprobante}
                    onCloseConfirmDialogDelete={onCloseConfirmDialogDelete} />
            )}
            {openConfirmDialogExportExcel && (
                <CfdisConfirmPopup
                    doAction={onDownloadExcel}
                    onClose={() => setOpenConfirmDialogExportExcel(false)}
                    title={translate("cfdis.popup.confirm_dialog_excel.title") as string}
                    message={translate("cfdis.popup.confirm_dialog_excel.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog_excel.button") as string} />
            )}
            {openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.message") as string}
                    secondary={translate("cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {cfdisCancelled ?
                <CfdisCancelledPopup
                    onClose={onCloseCfdisCancelled}
                    cfdisInvalid={cfdisCancelled} />
                : undefined}
            {postpone && cfdi &&
                <PostponePaymentPopup
                    id={cfdi.id}
                    onConfirm={onConfirmPostpone}
                    onCancelled={onCancelledPostpone} />
            }
            {(openExportExcel || openDownloadValidationsPdf) && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA_RANGE"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporter}
                    onExport={openExportExcel ? onDownloadWithConnector : onDownloadDateValidationsPdf}
                    maxDaySelected={31} />
            )}
            {openExportExcelSelected && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporterSelected}
                    onExport={onDownloadWithConnectorSelected} />
            )}
        </Surface>);
}
