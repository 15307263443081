import React, { useState, useEffect, useContext } from "react";
import { Grid, Fab } from "@material-ui/core";
import translate from "../i18n/Translator";
import { ExpedientTemplate, ExpedientTemplateParams, ExpedientTemplates, ExpedientDisabledTemplateRequest } from "../model/ExpedientTemplate";
import Surface from "../components/Surface";
import { listExpedientTemplates, disabledTemplate } from "../api/ExpedientTemplateAPI";
import { AppContext } from "../context/AppContext";
import GridDx from "../components/GridDx";
import BallotIcon from '@material-ui/icons/BallotTwoTone';
import { initialPageZero, initialPageSize } from "../components/Pagination";
import queryString from "query-string";
import { formatDateString, concatDates} from "../components/DateFormat";
import { Filter, DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { useHistory } from "react-router-dom";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpedientTemplateMenu from "./ExpedientTemplateMenu";
import AddIcon from "@material-ui/icons/Add";
import SelectTemplateReplacePopup from "./SelectTemplateReplacePopup";
import ExpedientTemplatesCreateEdit from "./ExpedientTemplatesCreateEdit";
import { getExpedientTemplate } from "../api/ExpedientTemplateAPI";

export default function ExpedientTemplatesView() {
    const context = useContext(AppContext);
    const qs = queryString.parse(window.location.search);
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [data, setData] = useState<ExpedientTemplates>();
    const [template, setTemplate] = useState<ExpedientTemplate>();
    const [providerNum, setProviderNum] = useState<number>(0);
    const [openSelectTemplate, setOpenSelectTemplate] = useState<boolean>(false);
    const [params, setParams] = useState<ExpedientTemplateParams>({
        "name": typeof qs["name"] === "string" ? qs["name"] as string : "",
        "created_start_date": typeof qs["created_start_date"] === "string" ? qs["created_start_date"] as string : "",
        "created_end_date": typeof qs["created_end_date"] === "string" ? qs["created_end_date"] as string : "",
        "updated_start_date": typeof qs["updated_start_date"] === "string" ? qs["updated_start_date"] as string : "",
        "updated_end_date": typeof qs["updated_end_date"] === "string" ? qs["updated_end_date"] as string : "",
        "is_default": typeof qs["is_default"] === "string" ? qs["is_default"] : undefined,
    } as ExpedientTemplateParams);

    const [view, setView] = useState<"list" | "update" | "create">("list"); 

    const paramsToFilters = () => {
        return [
            {columnName: "name", value: params.name},
            {columnName: "is_default", value: params.is_default},
            {columnName: "last_update", value: concatDates(params.updated_start_date, params.updated_end_date)},
            {columnName: "created_at", value: concatDates(params.updated_start_date, params.updated_end_date)},
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        listExpedientTemplates(context.session!.tenant!.id, params, page, pageSize * page).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if(filter.value !== undefined){
                switch(filter.columnName){
                    case "name": 
                        temp.name = filter.value;
                        break;
                    case "last_update":
                        let updatedDates = filter.value.split(" - ");
                        if(updatedDates.length === 2) {
                            temp.updated_start_date = formatDateString(updatedDates[0]);
                            temp.updated_end_date = formatDateString(updatedDates[1]);
                        } else {
                            temp.updated_start_date = "";
                            temp.updated_end_date = "";
                        }
                        break;
                    case "created_at":
                        let createdDates = filter.value.split(" - ");
                        if(createdDates.length === 2) {
                            temp.created_start_date = formatDateString(createdDates[0]);
                            temp.created_end_date = formatDateString(createdDates[1]);
                        } else {
                            temp.created_start_date = "";
                            temp.created_end_date = "";
                        }
                        break;
                    case "is_default":
                        temp.is_default = filter.value !== "" ? filter.value === "true" : undefined;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
        pushHistory();
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["name"] = params.name ? params.name : "";
        qs["is_default"] = params.is_default !== undefined ? params.is_default+"" : "";
        qs["created_start_date"] = params.created_start_date ? params.created_start_date : "";
        qs["created_end_date"] = params.created_end_date ? params.created_end_date : "";
        qs["updated_start_date"] = params.updated_start_date ? params.updated_start_date : "";
        qs["updated_end_date"] = params.updated_end_date ? params.updated_end_date : "";
        qs["page"] = "0";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedOptions = (template: ExpedientTemplate) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setTemplate(template);
    };

    const onClickedRow = (template: ExpedientTemplate) => {
        pushHistory();
        getDetailExpedientTemplate("update", template.id);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setTemplate(undefined);
    };

    const onReturnFormUpdateEdit = () => {
        setView("list");
        setTemplate(undefined);
        load();
    };

    const getDetailExpedientTemplate = (view: "list" | "update" | "create", templateId: string) => {
        setStatus("loading");
        getExpedientTemplate(context.session!.tenant!.id, templateId).then((response) => {
            setTemplate(response);
            setView(view);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onDisabled = (template: ExpedientTemplate) => {
        setAnchorEl(null);
        if(template.is_default){
            setWarning(translate("expedient_template.disabled_template.default_message") as string);
            return;
        }
        setStatus("loading");
        disabledTemplate(context.session!.tenant!.id, template.id, {force_disabled: false } as ExpedientDisabledTemplateRequest).then((response) => {
            if(response.num_providers && response.num_providers > 0){
                setProviderNum(response.num_providers);
                setOpenSelectTemplate(true);
            } else {
                successDisabledTemplate();
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onSelectTemplateReplace = (request: string) => {
        if(!template) {
            return;
        }
        setOpenSelectTemplate(false);
        setStatus("loading");
        disabledTemplate(context.session!.tenant!.id, template.id, 
            { force_disabled: true, template_for_replace: request } as ExpedientDisabledTemplateRequest).then((response) => {
            successDisabledTemplate();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setTemplate(undefined);
            setStatus("loaded");
        });
    }

    const successDisabledTemplate = () => {
        setTemplate(undefined);
        load();
        setSuccess(translate("expedient_template.disabled_template.success") as string);
    }

    const onCloseSelectTemplate = () => {
        setOpenSelectTemplate(false);
        setTemplate(undefined);
    }

    const onEdit = (template: ExpedientTemplate) => {
        setAnchorEl(null);
        pushHistory();
        getDetailExpedientTemplate("update", template.id);
    };

    const onCreateTemplate = () => {
        pushHistory();
        setView("create");
    };

    const DefaultTemplateFormatter = (value: any) => {
        return <>{value.value === true ? translate(`yes`) as string : translate(`no`) as string}</>;
    };
    
    const DefaultTemplateTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={DefaultTemplateFormatter} {...props} />
    );

    const DefaultFilterEditor = ( params: DataTypeProvider.ValueEditorProps ) => {
        const onChange = (event : any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <FormControl fullWidth>
                <Select native style={{ fontSize: "10px" }}
                    labelId="is_default"
                    id="is_default"
                    value={params.value ? params.value : ''}
                    onChange={onChange} >
                    <option value={""}>{""}</option>
                    <option value={"true"}>{translate(`yes`) as string}</option>
                    <option value={"false"}>{translate(`no`) as string}</option>
                </Select>
            </FormControl>
        );
    };

    const [columnsFormat] = useState([
      { columnName: 'name', wordWrapEnabled: true },
      { columnName: 'created_at' },
      { columnName: 'last_update'},
      { columnName: 'is_default', width: 150},
      { columnName: 'menu', align: 'right', width: 50},
    ]) as any;

    const dateColumns = ['last_update', 'created_at'];

    const textColumns = ['name'];

    const clickRowColumns = ['name'];

    const customPlugins = [<DefaultTemplateTypeProvider for={['is_default']} editorComponent={DefaultFilterEditor}/>];

    const columns = [
        {
            name: 'name',
            title: translate("expedient_template.columns.name") as string
        },
        {
            name: 'created_at',
            title: translate("specific_reception_rule.columns.created_at") as string
        },
        {
            name: 'last_update',
            title: translate('specific_reception_rule.columns.updated_at') as string
            },
        {
            name: 'is_default',
            title: translate("expedient_template.columns.is_default") as string
        },
        {
            name: 'menu',
            title: " "
        }
    ];

    if(view === "update" || view === "create"){
       return (<ExpedientTemplatesCreateEdit edit={view === "update"} expedientTemplate={template} onReturn={onReturnFormUpdateEdit} />); 
    }

    return (
        <Surface
            title={translate("expedient_template.title_grid") as string}
            subtitle={translate("expedients.subtitle") as string}
            icon={<BallotIcon />}
            className="PaperPagination" titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {context.isGrantedAny(["ExpedientTemplateCreate"]) ?
                        (<Grid item xs="auto">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}
                                onClick={onCreateTemplate} disabled={status === "loading"}>
                                <AddIcon />
                            </Fab>
                        </Grid>) : undefined}
                </Grid>
            }>
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={columns}
                onClickRow={onClickedRow}
                clickRowColumns={clickRowColumns}
                dateColumns={dateColumns}
                columnsFormat={columnsFormat}
                textColumns={textColumns}
                page={page}
                pageSize={pageSize} 
                totalRows={data ? data.total : 0}
                filters={filters}
                customFormatColumns={customPlugins}
                setFiltersHandler={setFiltersHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            {template && anchorEl && context.isGrantedAny(["ExpedientTemplateUpdate"]) && (
                <ExpedientTemplateMenu
                    template={template} onEdit={onEdit} onDisabled={onDisabled} anchor={anchorEl}
                    onClose={onCloseOption} />
            )}
            {openSelectTemplate && template && 
                <SelectTemplateReplacePopup 
                    tenantId={context.session!.tenant!.id} 
                    onClose={onCloseSelectTemplate} 
                    onSave={onSelectTemplateReplace}
                    providers_num={providerNum}
                    templateId={template.id}
                />
            }
        </Surface>
    );
}
