import { callAPI, HTTPMethod } from "./API";
import { BankAccountTypes, BankAccountTypesParams } from "../model/BankAccountType";

export async function list(tenantId: string, params: BankAccountTypesParams, page: number, offset: number): Promise<BankAccountTypes> {
    return await callAPI(`/tenants/${tenantId}/bank-account-types`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}