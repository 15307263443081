import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { ConceptoTab } from "../../model/Cfdi";
import translate from "../../i18n/Translator";
import GridDx, { NoFilterEditor } from "../../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

export interface InvoicesTabProps {
    concepts: ConceptoTab[];
    currency: string;
    selected: string[];
    onCheck(selected: string[]): any;
}

export default function InvoicesTab(props: InvoicesTabProps) {

    const [selected, setSelected] = useState<string[]>(props.selected);

    const setSelectionHandler = (selected: string[]) => {
        setSelected(selected);
        props.onCheck(selected);
    };

    const columns = [
        {
            name: 'cantidad',
            title: translate("cfdis.details.quantity") as string
        },
        {
            name: 'description',
            title: translate("cfdis.details.description") as string
        },
        {
            name: 'importe',
            title: translate("cfdis.details.price", { "currency": props.currency }) as string,
            getCellValue: (row: any) => row.descuento ? row.importe - row.descuento : row.importe
        },
    ];

    const [columnsFormat] = useState([
        { columnName: 'cantidad', width: 100 },
        { columnName: 'description', wordWrapEnabled: true },
        { columnName: 'importe', align: 'right' },
    ]) as any;

    const textColumns = ['cantidad', 'description'];

    const currencyColumns = ['importe'];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const filtersExclude = [
        'cantidad',
        'description',
        'importe'
    ];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    return (
        <Box py={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography >{translate("cfdi_relate_po.modal_concepts.tab_current_label")}</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginLeft: "-15px"}}>
                    <GridDx
                        loading={false}
                        rows={props.concepts}
                        columns={columns ? columns : []}
                        columnsFormat={columnsFormat}
                        textColumns={textColumns}
                        amountCurrencyColumns={currencyColumns}
                        onClickRow={() => {}}
                        onClickedOptions={() => {}}
                        selectionIds={selected}
                        setSelectionHandler={setSelectionHandler}
                        customFormatColumns={customPlugins}
                        heightTablePX={200}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}