import React from "react";
import moment from "moment";
import moment_tz from "moment-timezone";
import "moment/locale/es";

interface DateFormatProps {
    date?: Date
    format?: string
    zone?: boolean
}

export default function DateFormat(props: DateFormatProps) {
    return (
        <span>{formatDate(props.date, props.format, props.zone)}</span>
    );
}

export function formatDate(date?: Date, format?: string, zone?:boolean) {
    moment.locale("es");

    if (date) {
        return moment(date).format(format || "llll") + (zone ? moment_tz().tz(moment_tz.tz.guess(true)).format(' z').replace(/0|:/g, "") : "");
    }
    return "---"
}


export const formatDateString = (date: string) => {
    let sDate = date.split("/");
    return sDate[1] + "/" + sDate[0] + "/" + sDate[2];
  }
  
  export const concatDates = (startDate: string | undefined, endDate: string | undefined) => {
    if (startDate && endDate) {
      return formatDateString(startDate) + " - " + formatDateString(endDate);
    }
    return "";
  }
  
  export const formatDatesToString = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (startDate && endDate) {
      return formatDateString(formatDateToString(startDate)) + " - " + formatDateString(formatDateToString(endDate));
    }
    return "";
  }
  
  export const formatDateToString = (dat: Date) => {
    if(dat === null ||  dat === undefined){
      return "---";
    }
    let date = new Date(dat);
    return String(date.getMonth() + 1).padStart(2, '0') + "/" + String(date.getDate()).padStart(2, '0') + "/" + date.getFullYear();
  }
  
  export const formatDateToShow = (date: Date) => {
    return String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, '0') + "/" + date.getFullYear();
  }
  // Format dd/mm/yyyy HH:mm:ss
  export const formatDateTimeToString = (dat: Date) => {
    let date = new Date(dat);
    return String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, '0') + "/" + date.getFullYear() + " " + date.getHours() + ":" + String(date.getMinutes()).padStart(2, '0') + ":" + String(date.getSeconds()).padStart(2, '0');
  }

  // Format yyyy/MM/dd
  export const formatToString = (dat: string) => {
    let sDate = dat.split("/");
    return sDate[2] + "-" + sDate[0] + "-" + sDate[1];
  }
  
  // Format mm-dd-yyyy
  export const stringToDate = (date: string) => {
    let dateSplit = date.split("-");
    return new Date(Number(dateSplit[2]), Number(dateSplit[0]) - 1, Number(dateSplit[1]));
  }
  
  // Format mm-dd-yyyy
  export const stringToDateEnd = (date: string) => {
    let dateSplit = date.split("-");
    return new Date(Number(dateSplit[2]), Number(dateSplit[0]) - 1, Number(dateSplit[1]), 23, 59, 59, 999);
  }
