import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, IconButton, Divider, Button } from "@material-ui/core";
import { LockOpenIcon, MoreVertIcon } from "../components/Icons";

import { listRoles } from "../api/RoleAdminAPI";
import { Role, Roles, RolesQueryParams } from "../model/Role";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import RolesAdminMenu from "./RolesAdminMenu";
import { Redirect } from "react-router-dom";

export default function RolesAdmin({ match }: RouterParams) {
    const history = useHistory();
    const tenantId = match.params.tenantId;
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<string | undefined>();

    const query = new URLSearchParams(useLocation().search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Roles>();
    const paramsFromQueryString = (): RolesQueryParams => {
        return {
            search: query.get("search") || "",
            tenantId: tenantId 
        } as RolesQueryParams;
    };
    const [workingParams, setWorkingParams] = useState<RolesQueryParams>(paramsFromQueryString);
    const [params, setParams] = useState<RolesQueryParams>(paramsFromQueryString);

    const [role, setRole] = useState<Role>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    
    const load = () => {
        const offset = getOffset(page, pageSize);
        pushHistory();
        if (match.params.success && isSuccess) {
            setSuccess(match.params.success);
            setIsSuccess(false);
        }
        listRoles(pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        pushHistory();
        setParams(workingParams);
    };

    const pushHistory = () => {
        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (role: Role) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setRole(role);
    };

    const onCloseMenu = () => {
        setAnchorEl(null);
        setRole(undefined);
    };

    const onEdit = () => {
        if (!role) return;

        setAnchorEl(null);
        setRedirect(`/tenants-admin/${tenantId}/actions-admin/${role.id}/edit`);
    };
    
    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickRow = (role: Role) => {
        setRedirect(`/tenants-admin/${tenantId}/actions-admin/${role.id}/edit`);
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Pagination title={translate("roles_admin.title")} icon={<LockOpenIcon />}
            page={page} pageSize={pageSize} count={data && data.items ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justifyContent="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("providers.filter_search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data && data.items ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("roles_admin.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("roles_admin.name") as string,
                        converter: (role) => (
                            <Ellipsis text={ role.description ? role.description : translate(`users.roles.${role.id}`) as string } lenght={ 100 } uppercased={ false } /> 
                        ),
                        xs: true
                    },
                    {
                        title: translate("roles_admin.id") as string,
                        converter: (role) => (
                            <Ellipsis text={ role.id } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: true
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (role) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(role)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        xs: "auto"
                    }
                ]} />
                {role && anchorEl && 
                    <RolesAdminMenu 
                        anchor={anchorEl}
                        onClose={onCloseMenu}
                        onEdit={onEdit}
                        role={role}
                    />
                }
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
        </Pagination>
    );
}