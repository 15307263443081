import React, { useState, useEffect } from "react";
import { Grid, Box, FormLabel, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import translate from "../i18n/Translator";
import { ServiceProduct } from "../model/ServiceProduct";
import ProductsServices from "./ProductsServices";

interface ProductServicesTabProps {
    selected: ServiceProduct[];
    acceptAll: boolean;
    updateRadio(accept: string, isUses: boolean): any;
    update(selected: ServiceProduct[], accept: string): any;
}

export default function ProductsServicesTab(props: ProductServicesTabProps) {
    const [selected, setSelected] = useState<ServiceProduct[]>([]);
    const [value, setValue] = useState("all");

    useEffect(() => {
        setSelected(props.selected);
        setValue(props.acceptAll ? "all" : "only");
    }, [props.selected, props.acceptAll]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        props.updateRadio((event.target as HTMLInputElement).value, false);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={2}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{translate(`specific_reception_rule.service_product.grid_title`)}</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            <FormControlLabel value="all" control={<Radio />} label={translate(`specific_reception_rule.service_product.accept_all`)} />
                            <FormControlLabel value="only" control={<Radio />} label={translate(`specific_reception_rule.service_product.only_selected`)} />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box px={2}>
                    <ProductsServices
                        selected={selected}
                        disabled={value === "all"}
                        showTable={true}
                        placeholder={translate("specific_reception_rule.service_product.input") as string}
                        update={(selected: ServiceProduct[]) => props.update(selected, value)} />
                </Box>
            </Grid>
        </Grid>
    );
}