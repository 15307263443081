import { callAPI, HTTPMethod } from "./API";
import { Workflows, Workflow, WorkflowMetadata, WorkflowsQueryParams, WorkflowRequest, UpdateWorkflowPluginsRequest, WorkflowUsedProviders, WorkflowDeleteRequest } from "../model/Workflow";
import { UpdateWorkflowRequest } from "../workflove/Model";
import { Product } from "../workflove/Workflove";

const basePath = (tenantId: string) => `/tenants/${tenantId}/workflows`;

export async function listWorkflows(tenantId: string, page: number, offset: number, params: WorkflowsQueryParams): Promise<Workflows> {
    const query = {
        search: params.search,
        pageSize: page.toString(),
        offset: offset.toString()
    } as Record<string, string>;

    if (params.status) {
        query["status"] = params.status;
    }

    if (params.product) {
        query["product"] = params.product;
    }

    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function metadata(tenantId: string, product: Product): Promise<WorkflowMetadata> {
    return await callAPI(`${basePath(tenantId)}/metadata`, {
        method: HTTPMethod.GET,
        query: {
            product: product,
        }
    });
}

export async function createWorkflow(tenantId: string, request: WorkflowRequest): Promise<Workflow> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getWorkflow(tenantId: string, id: string): Promise<Workflow> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateWorkflow(tenantId: string, id: string, request: UpdateWorkflowRequest): Promise<Workflow> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteWorkflow(tenantId: string, request: WorkflowDeleteRequest): Promise<Workflow> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.DELETE,
        body: request
    });
}

export async function updateDefault(tenantId: string, id: string): Promise<Workflow> {
    return await callAPI(`${basePath(tenantId)}/${id}/default`, {
        method: HTTPMethod.PUT
    });
}

export async function updatePlugins(tenantId: string, id: string, request: UpdateWorkflowPluginsRequest): Promise<Workflow> {
    return await callAPI(`${basePath(tenantId)}/${id}/plugins`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function validateProviders(tenantId: string, id: string): Promise<WorkflowUsedProviders> {
    return await callAPI(`${basePath(tenantId)}/${id}/validate-providers`, {
        method: HTTPMethod.GET
    });
}
