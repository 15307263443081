import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import VerifyProviderIcon from "@material-ui/icons/VerifiedUserTwoTone";
import PersonAddIcon from '@material-ui/icons/PersonAddTwoTone';
import GroupIcon from "@material-ui/icons/GroupTwoTone";
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Entity } from "../model/Provider";
import NotLocatedIcon from '@material-ui/icons/NotListedLocation';

interface ProviderOverviewMenuProps {
    provider: Entity;
    anchor: HTMLElement | null;
    onVerifyEfos(): any;
    onVerifyCff69(): any;
    onClose(): any;
    onAssignValidator():any;
    onSelectReceptor(): any;
    isRestrictAssignReceptors: boolean;
}

export default function ProviderOverviewMenu(props: ProviderOverviewMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("EfosUpdate") &&
                <MenuItem button onClick={props.onVerifyEfos} dense>
                    <ListItemIcon>
                        <VerifyProviderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.verify_efos")}</Typography>
                </MenuItem>
            }
            {context.isGranted("Cff69Update") &&
                <MenuItem button onClick={props.onVerifyCff69} dense>
                    <ListItemIcon>
                        <NotLocatedIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.verify_69_cff")}</Typography>
                </MenuItem>
            }
            {context.isGranted("OwnerProvidersUsersRead") &&
                <MenuItem button component={Link} to={`/providers/${props.provider.id}/users`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <GroupIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantFieldsUpdate") &&
                <MenuItem button onClick={props.onAssignValidator} dense>
                    <ListItemIcon>
                        <PersonAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.assign_validator")}</Typography>
                </MenuItem>
            }
            {context.isGranted("AdminCFDIsUpdate") && !props.isRestrictAssignReceptors &&
                <MenuItem button onClick={props.onSelectReceptor} dense>
                    <ListItemIcon>
                        <GroupAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_receptor")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 