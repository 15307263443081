import { callAPI, HTTPMethod } from "./API";
import { WarehouseDeliveries, WarehouseDelivery, WarehouseDeliveriesQueryParams } from "../model/WarehouseDelivery";
import { DocumentLines } from "../model/Document";
import { Cfdi, CfdiRequest } from "../model/Cfdi";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/warehouse-deliveries`;

export async function listWarehouseDeliveries(tenantId: string, providerId: string, page: number, offset: number, params: WarehouseDeliveriesQueryParams): Promise<WarehouseDeliveries> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function getWarehouseDelivery(tenantId: string, providerId: string, id: string): Promise<WarehouseDelivery> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function createCfdi(tenantId: string, providerId: string, id: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("workflowId", request.workflow_id ? request.workflow_id : "");
    data.append("companyId", request.company_id ? request.company_id : "");

    return await callAPI(`${basePath(tenantId, providerId)}/${id}/cfdi`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getLines(tenantId: string, providerId: string, cfdiId: string): Promise<DocumentLines> {
    return await callAPI(`${basePath(tenantId, providerId)}/lines`, {
        method: HTTPMethod.GET,
        query: {
            cfdiId: cfdiId
        }
    });
}