import React, { useContext, useState, useEffect } from "react";

import { IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";

import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import Progress from "../components/Progress";
import NumberFormat from "react-number-format";

import { Budget, BudgetExecutionStatus as Statuses } from "../model/Budget";
import { GridableColumn } from "../components/Gridable";
import Budgets from "./BudgetLists";

export interface BudgetListCallbacks {
    onClickedOptions(budget: Budget): any;
}

export default function BudgetsView({ match }: RouterParams) {

    const context = useContext(AppContext);
    const [view, setView] = useState<"PLANNING" | "EXECUTE"| "ARCHIVE">();
    const [statuses, setStatuses] = useState<string[]>();

    const load = () => {
        let href = window.location.pathname;

        if (href.includes('planning')) {
            setView('PLANNING');
            setStatuses([Statuses[0]]);
        } else if (href.includes('execute')) {
            setView('EXECUTE');
            setStatuses([Statuses[1]]);
        } else {
            setView('ARCHIVE');
            setStatuses(Statuses);
        }
    }

    useEffect(load, [context.session]);

    const getColumns = (budgets: Budget[], callbacks: BudgetListCallbacks) => {
        let all = [
            {
                title: translate("budget.lists.columns.name") as string,
                converter: (budget) => budget.name,
                fullWidth: true,
                xs: true,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
            },
            {
                title: translate("budget.lists.columns.company") as string,
                converter: (budget) => budget.company_name,
                xs: false,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: translate("budget.lists.columns.init_date") as string,
                converter: (budget) => (<DateFormat date={budget.init_date} format="DD/MM/YYYY" />),
                fullWidth: true,
                xs: view === "ARCHIVE" ? 1 : 2,
                sm: view === "ARCHIVE" ? 1 : 2,
                md: view === "ARCHIVE" ? 1 : 2,
                lg: view === "ARCHIVE" ? 1 : 2,
                xl: view === "ARCHIVE" ? 1 : 2
            },
            {
                title: translate("budget.lists.columns.end_date") as string,
                converter: (budget) => (<DateFormat date={budget.end_date} format="DD/MM/YYYY" />),
                fullWidth: true,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1
            },
            {
                title: translate("budget.lists.columns.current_amount") as string,
                converter: (budget) => (<Typography variant="body2" component="h6" align="right">
                <NumberFormat value={budget.current_amount} prefix="$ " suffix={` ${budget.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
            </Typography>),
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: translate("budget.lists.columns.update_at") as string,
                converter: (budget) => (<DateFormat date={budget.updated_date} format="DD/MM/YYYY" />),
                xs: false,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 1
            },
            {
                title: translate("budget.lists.columns.status") as string,
                converter: (budget) => (translate("budget.lists.execution_status."+ budget.execution_status) as string) ,
                fullWidth: true,
                xs: view === "ARCHIVE" ? 1 : false,
                sm: view === "ARCHIVE" ? 1 : false,
                md: view === "ARCHIVE" ? 1 : false,
                lg: view === "ARCHIVE" ? 1 : false,
                xl: view === "ARCHIVE" ? 1 : false
            },
            {
                title: (
                    ""
                ),
                converter: (budget) => (
                    <IconButton aria-label="options"
                        color="default"
                        size="small"
                        onClick={callbacks.onClickedOptions(budget)}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: view === "PLANNING" ? "auto" : false,
                sm: view === "PLANNING" ? "auto" : false,
                md: view === "PLANNING" ? "auto" : false,
                lg: view === "PLANNING" ? "auto" : false,
                xl: view === "PLANNING" ? "auto" : false
            }
        ] as GridableColumn<Budget>[];

        return all.filter((column) => column !== null);
    };

    if (!statuses || !view) {
        return <Progress />;
    }

    return (
        <Budgets getColumns={getColumns} statuses={statuses} view={view}/>
    );

}