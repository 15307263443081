import React from "react";
import { Grid, Typography, Card, Box } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import { XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, BarChart, Bar, Legend, TooltipPayload } from "recharts";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { TenantsSummaryResponse } from "../model/Dashboard";
import Surface from "../components/Surface";
import moment from "moment";

interface TenantsDashboardProps {
    tenants: Map<string, TenantsSummaryResponse>;
    summary: TenantsSummaryResponse;
}

export default function TenantsDashboard(props: TenantsDashboardProps) {
    let summary = props.tenants as any;
    let data = [] as object[];
    for (let key in summary) {
        let item = summary[key] as TenantsSummaryResponse;
        data.push({
            label: moment(key, "YYYYMM").format("MMM 'YY"),
            active: item.active,
            inactive: item.inactive,
        });
    }

    const tooltipFormatter = (value: string | number | Array<string | number>, name: string, entry: TooltipPayload, index: number) => {
        if (typeof value === "string" || typeof value === "number") {
            return [<NumberFormat value={value} thousandSeparator="," displayType="text" />, translate(`tenants.status.${name}`)];
        }
        return [value, translate(`tenants.status.${name}`)];
    }

    return (
        <Surface title={translate("tenants.title")} icon={<WorkIcon />}>
            <Grid container justify="space-evenly" alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={7} lg={8}>
                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="label" />
                                <YAxis />
                                <Tooltip formatter={tooltipFormatter} />
                                <Legend formatter={(status) => translate(`tenants.status.${status}`) as string} />
                                <Bar dataKey="active" fill="#8884d8" />
                                <Bar dataKey="inactive" fill="#83a6ed" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <Card variant="outlined">
                        <Box py={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="button" component="h6" align="center">{translate("tenants.title")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="caption" component="h6" align="center">
                                                {translate("tenants.status.active")}
                                            </Typography>
                                            <Typography variant="body2" component="h6" align="center">
                                                <NumberFormat value={props.summary.active} thousandSeparator="," displayType="text" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="caption" component="h6" align="center">
                                                {translate("tenants.status.inactive")}
                                            </Typography>
                                            <Typography variant="body2" component="h6" align="center">
                                                <NumberFormat value={props.summary.inactive} thousandSeparator="," displayType="text" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="caption" component="h6" align="center">
                                                {translate("total")}
                                            </Typography>
                                            <Typography variant="body2" component="h6" align="center">
                                                <strong>
                                                    <NumberFormat value={props.summary.total} thousandSeparator="," displayType="text" />
                                                </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Surface>
    );
}