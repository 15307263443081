import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, Divider, List, ListItem, ListItemText } from "@material-ui/core";

import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Cfdi } from "../model/Cfdi";
import { Workflow } from "../model/Workflow";
import { Company } from "../model/Company";
import { WarningSnackbar } from "../components/Snackbars";

interface CfdiAsignWorkflowPopUpProps {
    workflows: Workflow[];
    companies: Company[];
    isChangeWorkflow: boolean;
    optionalWorkflow?: boolean;
    cfdi: Cfdi;
    confirmChange: boolean;
    onCompleted(workflowId: string, companyId: string, confirmChange: boolean): any;
    onClose(): any;
}

export default function CfdiAsignWorkflowPopUp(props: CfdiAsignWorkflowPopUpProps) {
    const [workflowId, setWorkflowId] = useState<string>();
    const [workflowIds, setWorkflowIds] = useState<string[]>([]);
    const [workflowNames, setWorkflowNames] = useState<string[]>([]);
    const [description, setDescription] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [emitterName, setEmitterName] = useState<string>();
    const [receptorName, setReceptorName] = useState<string>();
    const [companyId, setCompanyId] = useState<string>();
    const [companyIds, setCompanyIds] = useState<string[]>([]);
    const [companyNames, setCompanyNames] = useState<string[]>([]);
    
    useEffect(() => {
        let workflows = props.workflows;
        if (workflows && workflows.length > 0 && props.cfdi.metadata.workflow_id) {
            workflows = workflows.filter(c => c.id !== props.cfdi.metadata.workflow_id);
        }
        setWorkflowIds(workflows.map((workflow) => workflow.id));
        setWorkflowNames(workflows.map((workflow) => workflow.name));
        let companies = props.companies;
        if (companies && companies.length > 0 && props.cfdi.metadata.company_id) {
            companies = companies.filter(c => c.id !== props.cfdi.metadata.company_id);
        }
        setCompanyIds(companies.map((company) => company.id));
        setCompanyNames(companies.map((company) => company.name));

        setEmitterName(props.cfdi.cfdi ? props.cfdi.cfdi.emisor.nombre : props.cfdi.metadata.provider_name);
        setReceptorName(props.cfdi.cfdi ? props.cfdi.cfdi.receptor.nombre : props.cfdi.metadata.tenant_name);
    }, [props]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!props.cfdi.metadata.company_id && (!companyId || companyId === "")) {
            setWarning(translate("companies.warning") as string);
            return;
        }

        if (!props.optionalWorkflow && !props.cfdi.metadata.workflow_id && ((!workflowId || workflowId === "") || workflowId === props.cfdi.metadata.workflow_id)) {
            setWarning(translate("workflows.cfdi_workflow.empty_selection") as string);
            return;
        }
        props.onCompleted(workflowId!, companyId!, props.confirmChange);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        let workflow = props.workflows.find(element => element.id === value);
        if (workflow) {
            setDescription(workflow.description || "");
            setWorkflowId(workflow.id);
        } else {
            setWorkflowId(undefined);
        }
    };

    const hasChangedCompany = (name: string, value: string, inputRef: InputRef) => {
        let company = props.companies.find(element => element.id === value);
        if (company) {
            setCompanyId(company!.id);
        }
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {props.isChangeWorkflow || !props.cfdi.metadata.workflow_id ?
                            translate("workflows.cfdi_workflow.title_select") as string : ""}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        {props.isChangeWorkflow && (!workflowIds || workflowIds.length === 0) ? translate("workflows.cfdi_workflow.empty_list") as string : ""}
                        {!props.optionalWorkflow && ((props.isChangeWorkflow && (workflowIds && workflowIds.length > 0)) || !props.cfdi.metadata.workflow_id) && props.cfdi.metadata.type !== "PAYMENT_CFDI" && (
                            <Grid container>
                                <Grid item xs={12} style={{ "marginBottom": "3%" }}>
                                    <Grid container alignItems="center" alignContent="center">
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="primary">
                                                {translate("cfdis.details.emitter") as string}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List component="nav" dense>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={emitterName}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" alignContent="center">
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="primary">
                                                {translate("cfdis.details.receiver") as string}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List component="nav" dense>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={receptorName}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text" id="workflow_id" name="workflow_id"
                                        label={translate("workflows.single") as string}
                                        value={workflowId}
                                        required disabled={false}
                                        margin={"dense"}
                                        onValueChanged={hasChanged}
                                        options={workflowIds} 
                                        optionLabels={workflowNames} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{ fontStyle: "italic", marginTop: "5px" }} variant="body1" color="inherit">
                                        {description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {!props.cfdi.metadata.company_id &&
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography style={{ marginTop: "10px" }} variant="body1" color="inherit">
                                        {translate("companies.many")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text" id="company_id" name="company_id"
                                        label={translate("companies.single") as string}
                                        value={props.cfdi.metadata.company_id}
                                        required disabled={false}
                                        margin={"dense"}
                                        onValueChanged={hasChangedCompany}
                                        options={companyIds} optionLabels={companyNames} />
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={(!workflowId || props.cfdi?.metadata?.workflow_id === workflowId) && !(companyIds.length > 1 && companyId)}>
                        {translate("buttons.accept")}
                    </Button>
                </DialogActions>
            </form>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Dialog>
    );
}
