import { callAPI, HTTPMethod } from "./API";
import { SpecializedServiceDocuments, SpecializedServiceDocument, SpecializedServiceDocumentsQueryParams, SpecializedServiceDocumentRequest } from "../model/SpecializedServiceDocument";

const basePath = (tenantId: string) => `/tenants/${tenantId}/specialized-service-document`;

export async function listSpecializedServiceDocuments(tenantId: string, page: number, offset: number, params: SpecializedServiceDocumentsQueryParams): Promise<SpecializedServiceDocuments> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createSpecializedServiceDocument(tenantId: string, request: SpecializedServiceDocumentRequest): Promise<SpecializedServiceDocument> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getSpecializedServiceDocument(tenantId: string, id: string): Promise<SpecializedServiceDocument> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateSpecializedServiceDocument(tenantId: string, id: string, request: SpecializedServiceDocumentRequest): Promise<SpecializedServiceDocument> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteSpecializedServiceDocument(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
