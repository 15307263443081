import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { CfdisQueryParams, Cfdis, Cfdi, CfdiRequest, ProviderCfdiParams } from "../model/Cfdi";
import { SendCfdisRequest, SendCfdisResponse, DeleteCfdisRequest, DeleteCfdisResponse, ProviderCfdiOverdueResponse, ProviderAllowedToSendResponse } from "../model/ProviderCfdi";
import { ShippingFieldRequest } from "../model/ProviderShippingTemplate"
import { ExtendedFields } from "../model/ExtendedField";

const getBaseUrl = (tenantId: string, providerId: string) => {
    return `/tenants/${tenantId}/providers/${providerId}/cfdis`;
};

const providerCfdiParams = (userUploadId: string, pageSize: number, offset: number, params: CfdisQueryParams) => {
    let result = {
        userUploaderId: userUploadId ? userUploadId : null,
        search: params.search ? params.search : null,
        statusGroup: params.status_group ? params.status_group : null,
        statusList: params.status_list && params.status_list.length > 0 ? params.status_list.split(",") : null,
        type: params.type ? params.type : null,
        cfdiType: params.cfdiType ? params.cfdiType : null,
        pageSize: pageSize,
        offset: offset,
        isCanceledInTenant: params.isCanceledInTenant ? params.isCanceledInTenant : false,
        isCanceled: params.isCanceled,
        currency: params.currency ? params.currency : null,
        identifier: params.identifier ? params.identifier : null,
        issuer: params.issuer ? params.issuer : null,
        description: params.description ? params.description : null,
        typeFilter: params.type_filter ? params.type_filter : null,
        total: params.total ? params.total : null,
        balance: params.balance ? params.balance : null,
        company: params.company_name ? params.company_name : null,
        selectedIds: params.selected_ids && params.selected_ids.length > 0 ? params.selected_ids.split(",") : null,
        archive: params.archive,
        filterFields: params.filter_fields ? params.filter_fields : null,
        statusGroups: params.status_groups && params.status_groups.length > 0 ? params.status_groups.split(",") : null,
    } as ProviderCfdiParams;

    if (params.send_at_start && params.send_at_end) {
        result = {
            ...result,
            sendAtStart: new Date(params.send_at_start),
            sendAtEnd: new Date(params.send_at_end),
        };
    }

    if (params.authorized_at_start && params.authorized_at_end) {
        result = {
            ...result,
            authorizedAtStart: new Date(params.authorized_at_start),
            authorizedAtEnd: new Date(params.authorized_at_end),
        };
    }

    if (params.payment_due_start && params.payment_due_end) {
        result = {
            ...result,
            paymentDueStart: new Date(params.payment_due_start),
            paymentDueEnd: new Date(params.payment_due_end),
        };
    }

    if (params.date_start && params.date_end) {
        result = {
            ...result,
            startDate: new Date(params.date_start),
            endDate: new Date(params.date_end),
        };
    }

    if (params.start_date && params.end_date) {
        result = {
            ...result,
            startDate: new Date(params.start_date),
            endDate: new Date(params.end_date),
        };
    }

    if (params.payment_schedule_start && params.payment_schedule_end) {
        result = {
            ...result,
            paymentScheduleStart: new Date(params.payment_schedule_start),
            paymentScheduleEnd: new Date(params.payment_schedule_end),
        };
    }

    return result;
}


export async function getCfdis(tenantId: string, providerId: string, userUploadId: string, pageSize: number, offset: number, params: CfdisQueryParams): Promise<Cfdis> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}`, {
        method: HTTPMethod.POST,
        body: providerCfdiParams(userUploadId, pageSize, offset, params)
    });
}


export async function createCfdi(tenantId: string, providerId: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    let params = JSON.stringify({
        "workflow_id": request.workflow_id ? request.workflow_id : null,
        "company_id": request.company_id ? request.company_id : null,
        "preload_params": request.preload_params ? request.preload_params : null
    });
    data.append("params", new Blob([params], { type: "application/json" }));

    return await callAPI(getBaseUrl(tenantId, providerId), {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getCfdiFileUrl(tenantId: string, providerId: string, cfdiId: string, type: string): Promise<string> {
    return getAuthenticatedGetUrl(`${getBaseUrl(tenantId, providerId)}/${cfdiId}/file`,
        {
            query: {
                format: type
            }
        }
    );
}

export async function sendCfdis(tenantId: string, providerId: string, request: SendCfdisRequest): Promise<SendCfdisResponse> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/send`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCfdi(tenantId: string, providerId: string, cfdiId: string): Promise<void> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${cfdiId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function deleteCfdis(tenantId: string, providerId: string, request: DeleteCfdisRequest): Promise<DeleteCfdisResponse> {
    return await callAPI(getBaseUrl(tenantId, providerId), {
        method: HTTPMethod.DELETE,
        body: request
    });
}

export async function updateShippingData(tenantId: string, providerId: string, cfdiId: string, request: ShippingFieldRequest): Promise<ExtendedFields> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${cfdiId}/shipping-data`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteFileShippingData(tenantId: string, providerId: string, cfdiId: string, request: ShippingFieldRequest): Promise<ExtendedFields> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${cfdiId}/delete-file`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getCfdisPaymentOverdue(tenantId: string, providerId: string): Promise<ProviderCfdiOverdueResponse> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/payment-overdue`, {
        method: HTTPMethod.GET,
    });
}

export async function getAllowedToSend(tenantId: string, providerId: string): Promise<ProviderAllowedToSendResponse> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/allowed-to-send`, {
        method: HTTPMethod.GET,
    });
}

export async function listGeneratedPaymentCfdisByRfc(tenantId: string, providerId: string, providerRfc: string, pageSize: number, offset: number): Promise<Cfdis> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${providerRfc}/list-generated-payment-cfdis`, {
        method: HTTPMethod.GET,
        query: {
            page_size: pageSize.toString(),
            offset: offset.toString(),
        }
    });
}