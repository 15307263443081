import React, { useState, useEffect } from "react";
import DialogPopup from "../../components/DialogPopup";
import { CfdiValidation, Cfdi } from "../../model/Cfdi";
import translate, { translateParams } from "../../i18n/Translator";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Collapse, Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import InvalidIcon from '@material-ui/icons/CancelTwoTone';
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';
import WarningIcon from "@material-ui/icons/WarningTwoTone";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ReactHtmlParser from 'html-react-parser';

export interface CfdiValidationPopupValues {
    data: CfdiValidation[];
    cfdiData: Cfdi;
    cfdiPartiallyValid?: boolean;
}

interface CfdiValidationPopupProps extends CfdiValidationPopupValues {
    onClose(): any;
}

const style = makeStyles(theme => ({
    expand: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

export function CfdiValidationsPopup(props: CfdiValidationPopupProps) {
    return (
        <DialogPopup title={translate("cfdis.popup.validation.title")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            <Typography variant="body2" align="left">
                {translate(props.cfdiPartiallyValid ? "cfdis.popup.validation.cfdi_saved_not_all_validations" : "cfdis.popup.validation.cfdi_not_saved")}
            </Typography>
            <List dense>
                <CfdiValidationListItems data={props.data} cfdi={props.cfdiData} cfdiPartiallyValid={props.cfdiPartiallyValid} />
            </List>
        </DialogPopup>);
}

type Actor = "emisor" | "receptor";

interface CfdisValidationPopupProps {
    items: CfdiValidationPopupValues[];
    onFailureActor: Actor;
    onClose(): any;
}

export function CfdisValidationsPopup(props: CfdisValidationPopupProps) {
    const [index, setIndex] = useState(0);

    const getContent = (index: number) => {
        const item = props.items[index];
        const name = props.onFailureActor === "receptor" ? item.cfdiData.cfdi?.receptor.nombre : item.cfdiData.cfdi?.emisor.nombre;

        return (
            <>
                {(item.cfdiData.metadata.xml_file_name || name) && (
                    <Box pb={3}>
                        {name && (
                            <Typography variant="subtitle2" align="left" color="textPrimary" paragraph={item.cfdiData.metadata.xml_file_name === undefined}>
                                <strong>{name}</strong>
                            </Typography>
                        )}
                        {item.cfdiData.metadata.xml_file_name && (
                            <Typography variant="subtitle2" align="left" color="textSecondary" paragraph>
                                <strong>{item.cfdiData.metadata.xml_file_name}</strong>
                            </Typography>
                        )}
                        <Divider />
                    </Box>
                )}
                <Typography variant="body2" align="left">
                    {translate(item.cfdiPartiallyValid ? "cfdis.popup.validation.cfdi_saved_not_all_validations" : "cfdis.popup.validation.cfdi_not_saved")}
                </Typography>
                <List dense>
                    <CfdiValidationListItems data={item.data}
                        cfdi={item.cfdiData}
                        cfdiPartiallyValid={item.cfdiPartiallyValid} />
                </List>
            </>
        );
    };

    return (
        <DialogPopup title={translate("cfdis.popup.validation.title")}
            buttonSecondLeft={props.items.length === 1 ? undefined : (
                <Button variant="outlined" color="inherit" onClick={() => setIndex(index + 1)} disabled={index === props.items.length - 1}>
                    {translate("buttons.sig")}
                </Button>
            )}
            buttonLeft={props.items.length === 1 ? undefined : (
                <Button variant="outlined" color="inherit" onClick={() => setIndex(index - 1)} disabled={index === 0}>
                    {translate("buttons.prev")}
                </Button>
            )}
            open
            onClose={props.onClose}
            disableBackdropClick
            disableEscapeKeyDown>
            {getContent(index)}
        </DialogPopup>
    );
}

interface CfdiValidationListItemsProps {
    data: CfdiValidation[];
    cfdi: Cfdi;
    cfdiPartiallyValid?: Boolean;
}

export function CfdiValidationListItems(props: CfdiValidationListItemsProps) {
    const [data, setData] = useState<any>({} as any);
    const [cfdi, setCfdi] = useState<any>({} as any);
    const [cfdiPartiallyValid, setcfdiPartiallyValid] = useState<boolean>(false);

    const load = () => {
        let tmp = {} as any;
        props.data.forEach(element => {
            tmp[element.group] = [];
        });

        props.data.forEach(validation => {
            (tmp[validation.group] as CfdiValidation[]).push(validation);
        });

        setData(tmp);
        setCfdi(props.cfdi);
        if (props.cfdiPartiallyValid) {
            setcfdiPartiallyValid(true);
        }
    };

    useEffect(load, [props]);

    return (
        <List dense>
            {Object.keys(data).map((group: string) => (
                <ValidationListItem key={group} data={data[group]} group={group} cfdi={cfdi} cfdiPartiallyValid={cfdiPartiallyValid} open={(data[group] as CfdiValidation[]).find(v => !v.valid) !== undefined} />
            ))}
        </List>
    );
}

interface ValidationListItemProps {
    data: CfdiValidation[];
    cfdiPartiallyValid?: Boolean;
    group: string;
    cfdi: Cfdi;
    open: boolean;
}

function ValidationListItem(props: ValidationListItemProps) {
    const classes = style();
    const [open, setOpen] = useState<boolean>(props.open);

    const icon = (validation: CfdiValidation) => {
        if (validation.valid) {
            return (<ValidIcon style={{ "color": "#82CA9D" }} />);
        } else if (props.cfdiPartiallyValid) {
            return (<WarningIcon style={{ "color": "#f5812f" }} />);
        }
        return (<InvalidIcon style={{ "color": "#C33149" }} />);
    };

    const secondaryText = (validation: CfdiValidation, params: any, mapParams: any): JSX.Element | JSX.Element[] => {
        if (validation.type === "PLUGINS" && validation.message) {
            return ReactHtmlParser(validation.message);
        }

        let text = translate(`cfdis.popup.validation.codes.${validation.code}`, { ...params, ...mapParams } as any);
        if(Array.isArray(text)){
            return text;
        }else{
            return  ReactHtmlParser(text as string);
        }
    };

    const getTextSubstitution = (mapParams: any, validation: CfdiValidation, params: any) => {
        return <Typography variant="body2" color="textSecondary" align="left">
            {translate(`cfdis.popup.validation.codes.${validation.code}`, { ...params, ...mapParams } as any) as string}
            <a href={"/cfdis/" + mapParams["CFDI_LINK"] + "/details"}>
                {translate(`cfdis.popup.validation.cancel_first_linked`)}
            </a>
            {translate(`cfdis.popup.validation.cancel_first_message`)}
        </Typography>
    }

    return (
        <div>
            <ListItem key={props.group} button onClick={() => setOpen(!open)}>
                <ListItemText primary={translate(`cfdis.popup.validation.types_group.${props.group}`)} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.expand}>
                <List dense className="DrawerList">
                    {props.data.map((validation) => {
                        const params = {
                            "tipo": props.cfdi.cfdi?.tipo_comprobante || "---", "metodo_pago": props.cfdi.cfdi?.metodo_pago || "---",
                            "forma_pago": props.cfdi.cfdi?.forma_pago || "---", "uso_cfdi": props.cfdi.cfdi?.receptor.uso_cfdi || "---",
                            "products": props.cfdi.metadata.product_keys, "field_required": props.cfdi.metadata.required_fields
                        };
                        const mapParams = translateParams(validation.params);

                        if (validation.code === "ADVANCE_NOT_APPLY") {
                            return null;
                        }

                        return (
                            <ListItem key={`${validation.type} - ${Math.floor(Math.random() * 100)}`} button >
                                <ListItemIcon>
                                    {icon(validation)}
                                </ListItemIcon>
                                <ListItemText primary={translate(`cfdis.popup.validation.types.${validation.type}`)}
                                    secondary={validation.code === "SUBSTITUTION_LINKED_CFDIS_BAD_STATUS" ? getTextSubstitution(mapParams, validation, params) : secondaryText(validation, params, mapParams)} />
                                {validation.code === "ADVANCE_CFDI_RELATED_CANCELLED" && (
                                    <a href={`/cfdis/${mapParams["CFDI_ID"]}/details`}>
                                        {translate(`cfdis.popup.validation.view_first`)}
                                    </a>
                                )}
                            </ListItem>
                        )
                    })}
                </List>
            </Collapse>
        </div>
    );
}