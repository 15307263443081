import React from "react";
import { Box, Card, Grid, List, ListItem, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Customer } from "../model/Customer";
import DateFormat from "../components/DateFormat";

interface CustomerCardProps {
    customer: Customer;
}

export default function CustomerCard(props: CustomerCardProps) {
    const getAddress = () => {
        let address = "";
        if(props.customer.address_street){
            address += props.customer.address_street+", ";
        }
        if(props.customer.address_number_ext){
            address += props.customer.address_number_ext+", ";
        }
        if(props.customer.address_number_int){
            address += props.customer.address_number_int+", ";
        }
        if(props.customer.address_between_street && props.customer.address_between_street_after){
            address += translate("customers.between_streets", {"before": props.customer.address_between_street, "after": props.customer.address_between_street_after });
        } else if(props.customer.address_between_street){
            address += translate("customers.between_street", {"before": props.customer.address_between_street });
        }
        if(props.customer.address_zip_code){
            address += props.customer.address_zip_code+", ";
        }
        if(props.customer.address_municipality){
            address += props.customer.address_municipality+", ";
        }
        if(props.customer.address_state){
            address += props.customer.address_state+", ";
        }
        return address.substring(0, address.length-2);
    };

    const getContact = () => {
        let contact = "";
        if(props.customer.contact_email){
            contact += translate("customers.email_label", {"email": props.customer.contact_email });
        }
        if(props.customer.contact_phone){
            contact += translate("customers.phone_label", {"phone": props.customer.contact_phone, "ext": props.customer.contact_extension });
        }
        return contact.substring(0, contact.length-2);
    };
    return (
        <Box pt={2} px={2}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                <Card variant="outlined" >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <List dense disablePadding>
                                <ListSubheader>{translate("customers.details")}</ListSubheader>
                                <ListItem>
                                    <ListItemText primary={props.customer.name} secondary={translate("customers.name")} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={props.customer.rfc} secondary={translate("customers.rfc")} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={props.customer.external_id} secondary={translate("customers.external_id")} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={(
                                        <DateFormat date={props.customer.created_at} format="DD/MM/YYYY" />
                                    )} secondary={translate("customers.created_at")} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card variant="outlined" >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <List dense disablePadding>
                                <ListSubheader>{translate("customers.address_contact_title")}</ListSubheader>
                                <ListItem>
                                    <ListItemText primary={getAddress()} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={getContact()} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined" >
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                        <ListSubheader>{translate("expedients.addresses.types.SHIPPING")}</ListSubheader>
                            {props.customer.cfdi_sending_mode && (
                                <List dense disablePadding>
                                    <ListSubheader>
                                        {translate("customers.cfdi_sending_mode")}: {translate(`customers.modes.${props.customer.cfdi_sending_mode}`)}
                                    </ListSubheader>
                                    {props.customer.cfdi_recipients && (
                                        <ListItem>
                                            <ListItemText primary={translate("customers.cfdi_recipients")} secondaryTypographyProps={{
                                                component: "div"
                                            }} secondary={(
                                                <>
                                                    {props.customer.cfdi_recipients.split(",").map(recipient => (
                                                        <Typography key={recipient} variant="caption" component="h6">
                                                            {recipient}
                                                        </Typography>
                                                    ))}
                                                </>
                                            )} />
                                        </ListItem>
                                    )}
                                    {props.customer.cfdi_notes && (
                                        <ListItem>
                                            <ListItemText primary={translate("customers.cfdi_notes")} secondary={props.customer.cfdi_notes} />
                                        </ListItem>
                                    )}
                                </List>
                            )}
                            {props.customer.document_sending_mode && (
                                <List dense disablePadding>
                                    <ListSubheader>
                                        {translate("customers.document_sending_mode")}: {translate(`customers.modes.${props.customer.document_sending_mode}`)}
                                    </ListSubheader>
                                    {props.customer.document_recipients && (
                                        <ListItem>
                                            <ListItemText primary={translate("customers.document_recipients")} secondaryTypographyProps={{
                                                component: "div"
                                            }} secondary={(
                                                <>
                                                    {props.customer.document_recipients.split(",").map(recipient => (
                                                        <Typography key={recipient} variant="caption" component="h6">
                                                            {recipient}
                                                        </Typography>
                                                    ))}
                                                </>
                                            )} />
                                        </ListItem>
                                    )}
                                    {props.customer.document_notes && (
                                        <ListItem>
                                            <ListItemText primary={translate("customers.document_notes")} secondary={props.customer.document_notes} />
                                        </ListItem>
                                    )}
                                </List>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            </Grid>
        </Box>
    );
}