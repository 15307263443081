import React, { useState } from "react";
import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import { Payment } from "../model/Payment";
import { useHistory } from "react-router-dom";
import GridDx from "../components/GridDx";
import { NoFilterEditor } from "../components/GridDx";
import { DataTypeProviderProps, DataTypeProvider, Sorting } from "@devexpress/dx-react-grid";
import { TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import Grid from "@material-ui/core/Grid";
import { ExtendedField } from "../model/ExtendedField";
import FieldItemTemplate from "../components/FieldItems/FieldItemTemplate";
import { Box } from "@material-ui/core";

export interface CfdiPaymentsTabParams {
    payments?: Payment[];
}

export default function CfdiPaymentsTab(params: CfdiPaymentsTabParams) {
    const history = useHistory();
    const [data,] = useState<Payment[]>(params.payments ?? []);

    const [columnsFormat] = useState([
        { columnName: 'operation_number', width: 150 },
        { columnName: 'payment_date', align: 'center', width: 200 },
        { columnName: 'total_amount', align: 'right', width: 200 },
        { columnName: 'currency', width: 100 },
        { columnName: 'status', width: 200 }
    ]);

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const onClickedRow = (payment: Payment) => {
        history.push(`/payment-details/${payment.id}`);
    };

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
        params.row.payment_process_history ?
            params.row.payment_process_history.map((process: ExtendedField) => (
                <Box pl={5}>
                    <Grid container spacing={1} style={{
                        fontSize: "14px",
                    }}>
                        <Grid item xs={2}>
                            <strong>{process.name}</strong>
                        </Grid>
                        <Grid item xs={10}>
                            <FieldItemTemplate field={process} value={process.value} />
                        </Grid>
                    </Grid>
                </Box>
            ))
            : <></>
    );

    const getStatus = (payment: Payment) => {
        return translate(`payment.status.${payment.status}`) as string;
    };

    const getPaymentDate = (payment: Payment) => {
        return (<DateFormat date={payment.payment_date} format="DD-MM-YYYY h:mm a" zone={true} />);
    }

    const columns = [
        {
            name: 'operation_number',
            title: translate("payment.columns.number") as string,
        },
        {
            name: 'payment_date',
            title: translate("payment.columns.date") as string,
            getCellValue: (row: any) => getPaymentDate(row)
        },
        {
            name: 'total_amount',
            title: translate("payment.columns.total") as string,
        },
        {
            name: 'currency',
            title: translate("currency.title") as string,
        },
        {
            name: 'status',
            title: translate("payment.columns.status_cfdi") as string,
            getCellValue: (row: any) => getStatus(row)
        },
    ];

    const currencyColumns = ['total_amount'];

    const clickRowColumns = ['operation_number'];

    const textColumns = ['operation_number'];

    const filtersExclude = ['operation_number', 'payment_date', 'total_amount', 'currency', 'status'];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />
    ];

    const sorting = [{ columnName: 'payment_date', direction: 'asc' } as Sorting];

    return (
        <GridDx
            loading={false}
            rows={data || []}
            columns={columns}
            columnsFormat={columnsFormat}
            clickRowColumns={clickRowColumns}
            onClickRow={onClickedRow}
            amountCurrencyColumns={currencyColumns}
            textColumns={textColumns}
            customFormatColumns={customPlugins}
            sorting={sorting}
            rowDetailComponent={rowDetailComponent}
            heightTablePX={420}
        />
    )
}