import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import TermsConditionsIcon from '@material-ui/icons/Policy';
import { ProviderTermsConditions as Report, ProviderTermCondition } from "../model/Provider";
import { getReportTermsConditions } from "../api/ReportsAPI";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import {  ProviderTermConditionParams } from "../model/Reports";
import { formatDateString, formatDateToString } from "../components/DateFormat";
import { Grid, IconButton } from "@material-ui/core";
import { NoFilterEditor } from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';

export default function ReportTermsConditionsProvider() {
  const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const sDate = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
    const paramsFromQueryString = (): ProviderTermConditionParams => {
        return {
          "provider_name": typeof qs["provider_name"] === "string" ? qs["provider_name"] as string : "",
          "user_name": typeof qs["user_name"] === "string" ? qs["user_name"] as string : "",
          "start_date": typeof qs["start_date"] === "string" ? qs["start_date"] as string : formatDateToString(sDate)+" - "+formatDateToString(new Date()),
          "end_date": typeof qs["end_date"] === "string" ? qs["end_date"] as string : ""
        } as ProviderTermConditionParams;
      };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Report>();
    const [params, setParams] = useState<ProviderTermConditionParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);
    const paramsToFilters = (): Filter[] => {
        let temp = params.start_date.split(" - ")
        return [
          { columnName: 'provider_name', value: params.provider_name },
          { columnName: 'user_name', value: params.user_name },
          { columnName: 'date_accepted', value: formatDateString(temp[0])+" - "+formatDateString(temp[1]) },
        ] as Filter[];
      };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
      { columnName: 'date_accepted', wordWrapEnabled: true, width: 200 },
      { columnName: 'provider_name', width: 350 },
      { columnName: 'user_name', width: 200 },
      { columnName: 'ip', wordWrapEnabled: true, width: 150 },
    ]) as any;

    const [leftColumns] = useState(['ip', 'provider_name', 'user_name']) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();
        getReportTermsConditions(context.session!.tenant!.id, pageSize, page, params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "provider_name":
                        temp.provider_name = filter.value;
                        break;
                    case "user_name":
                        temp.user_name = filter.value;
                        break;
                    case "date_accepted":
                        let datesS = filter.value.split(" - ");
                        if(datesS.length === 2) {
                            temp.start_date = formatDateString(datesS[0]);
                            temp.end_date = formatDateString(datesS[1]);
                        } else {
                            temp.start_date = "";
                            temp.end_date = "";
                        }
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["provider_name"] = params.provider_name || "";
        qs["user_name"] = params.user_name || "";
        qs["date_accepted"] = params.start_date || "";
        qs["page"] = "0";
        
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onDownloadExcel = () => {
        
    }

    const getUserName = (item: ProviderTermCondition) => {
        return item.user_name ? item.user_name : "---";
    };

    const filtersExclude = ['ip'];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} /> 
    ];


  const columns = [
      {
          name: 'date_accepted',
          title: translate("reports.terms_conditions.date_accepted") as string,
      },
      {
          name: 'provider_name',
          title: translate('reports.terms_conditions.provider_name') as string
      },
      {
          name: 'user_name',
          title: translate('reports.terms_conditions.user_name') as string,
          getCellValue: (row: any) => getUserName(row)
      },
      {
          name: 'ip',
          title: translate('reports.terms_conditions.ip') as string,
      },
  ];

const textColumns = ['provider_name', 'user_name', 'ip'];

const dateColumns = ['date_accepted'];

    return (
        <Surface title={translate("reports.terms_conditions.title") as string} 
            icon={<TermsConditionsIcon />} 
            className="PaperPagination"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        <Grid item xs="auto">
                            <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                                <RefreshTwoToneIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
        }>
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns ? columns : []}
                columnsFormat={columnsFormat}
                onClickRow={() => {}}
                onClickedOptions={() => {}}
                dateColumns={dateColumns}
                leftColumns={leftColumns}
                textColumns={textColumns}
                filters={filters}
                customFormatColumns={customPlugins} 
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                excelFile={translate("reports.terms_conditions.excel_file") as string}
                heightTablePX={450}
                noUseId={true}
                showTimeInDates={true}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {openConfirmDialogExportExcel && (
                <CfdisConfirmPopup
                    doAction={onDownloadExcel}
                    onClose={() => setOpenConfirmDialogExportExcel(false)}
                    title={translate("cfdis.popup.confirm_dialog_excel.title") as string}
                    message={translate("cfdis.popup.confirm_dialog_excel.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog_excel.button") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description_archive") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
        </Surface>
    );
}
