import { callAPI, HTTPMethod } from "./API";
import { Users, UsersQueryParamsReceptors, UserRequest, User, MetadataResponse, UserUnlockRequest, UserDeleteResponse } from "../model/User";
import { ImportUsersSummary } from "../model/Provider";

export async function getUsers(tenantId: string, page: number, offset: number, params: UsersQueryParamsReceptors): Promise<Users> {
    return await callAPI(`/tenants/${tenantId}/users`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search || "",
            roleList: params.role_list || "",
            pageSize: page.toString(),
            offset: offset.toString(),
            status: params.status || "",
            referenceView: params.reference_view || "",
        }
    });
}
export async function getUsersExpedientValidators(tenantId: string, page: number, offset: number, params: UsersQueryParamsReceptors): Promise<Users> {
    return await callAPI(`/tenants/${tenantId}/users/expedient-validators`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search || "",
            roleList: params.role_list || "",
            pageSize: page.toString(),
            offset: offset.toString(),
            status: params.status || "",
            referenceView: params.reference_view || "",
        }
    });
}

export async function getMetadata(tenantId: string): Promise<MetadataResponse> {
    return await callAPI(`/tenants/${tenantId}/users/metadata`, {
        method: HTTPMethod.GET
    });
}

export async function createUser(tenantId: string, request: UserRequest): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/users`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getUser(tenantId: string, userId: string, includeMetadata?: boolean): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}`, {
        method: HTTPMethod.GET,
        query: {
            "include_metadata": `${!!includeMetadata}`,
        }
    });
}

export async function updateUser(tenantId: string, userId: string, request: UserRequest): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteUser(tenantId: string, userId: string): Promise<UserDeleteResponse> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function blockUser(tenantId: string, userId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/block`, {
        method: HTTPMethod.PUT,
    });
}

export async function unblockUser(tenantId: string, userId: string, request: UserUnlockRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/block`, {
        method: HTTPMethod.DELETE,
        body: request,
    });
}

export async function resendSubscribeEmail(tenantId: string, userId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/resend`, {
        method: HTTPMethod.PUT,
    });
}

export async function blockUserProvider(tenantId: string, providerId: string, userId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/block-user-provider/${providerId}`, {
        method: HTTPMethod.PUT,
    });
}

export async function unblockUserProvider(tenantId: string, providerId: string, userId: string, request: UserUnlockRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/block-user-provider/${providerId}`, {
        method: HTTPMethod.DELETE,
        body: request,
    });
}

export async function importUsers(tenantId: string, file: File): Promise<ImportUsersSummary> {
    let data = new FormData();
    data.append("excel", file as Blob, file.name);

    return await callAPI(`/tenants/${tenantId}/users/import-users`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}