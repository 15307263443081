import React from "react";
import FreshChat from './FreshChat.js';


interface ChatProps {
    user_id: string;
    user_email: string;
    user_name: string;
    user_phone?: string;
}

export default function Chat(props: ChatProps) {

    return (
        <FreshChat 
            {...props}
            user_phone={props.user_phone ?? ""}
        />
    );
}

