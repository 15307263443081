import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ShippingTemplatesIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import { emptyPromise } from "../api/API";
import { getShippingTemplate, updateShippingTemplate, createShippingTemplate } from "../api/ShippingTemplateAPI";
import { listCatalogs } from "../api/CatalogAPI";
import { ShippingTemplateRequest, ShippingTemplate } from "../model/ShippingTemplate";
import { Catalog, Catalogs } from "../model/Catalog";
import { Template } from "../workflove/Model";
import TemplateForm from "../workflove/TemplateForm";
import { Product } from "../workflove/Workflove";

export default function ShippingTemplateForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const shippingTemplateId = match.params.shippingTemplateId;
    const isEdit = !!match.params.shippingTemplateId;

    const query = new URLSearchParams(useLocation().search)
    const [product, setProduct] = useState<Product>(() => {
        return query.get('product') ? query.get('product') as Product : 'PAYABLE_DOCUMENTS';
    });

    const [status, setStatus] = useState<string>("loading");
    const [catalogs, setCatalogs] = useState<Catalog[]>([]);
    const [workfloveId, setWorkfloveId] = useState<string>();
    const [byEachPayment, setByEachPayment] = useState<string[]>();

    const promiseCatalogs = useCallback((): Promise<Catalogs> => {
        return listCatalogs(tenantId, 0, 0, { search: "" });
    }, [tenantId]);

    const promiseGet = useCallback((): Promise<ShippingTemplate | undefined> => {
        if (shippingTemplateId) {
            return getShippingTemplate(tenantId, shippingTemplateId);
        }
        return emptyPromise();
    }, [tenantId, shippingTemplateId]);

    const updatePromise = (template: Template): Promise<ShippingTemplate> => {
        const request = {
            name: template.name,
            description: template.description,
            fields: template.fields,
            product: template.product ?? "",
            by_each_payment: template.fields.filter(el => !!el.by_each_payment).map(el => el.id),
        } as ShippingTemplateRequest;

        if (isEdit) {
            return updateShippingTemplate(tenantId, shippingTemplateId, request);
        }
        return createShippingTemplate(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        Promise.all([
            promiseCatalogs(),
            promiseGet(),
        ]).then(([catalogs, shippingTemplate]) => {
            setWorkfloveId(shippingTemplate?.workflove_id);
            setByEachPayment(shippingTemplate?.by_each_payment);
            setCatalogs(catalogs.items);

            if (shippingTemplate && shippingTemplate.product) {
                setProduct(shippingTemplate.product);
            }

            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [promiseCatalogs, promiseGet]);

    const onCompleted = (shippingTemplate: ShippingTemplate, template: Template) => {
        history.push("/shipping-templates");
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface
            title={translate(isEdit ? "shipping_templates.edit" : "shipping_templates.new")}
            subtitle={translate(`shipping_templates.products.${product}`)}
            icon={<ShippingTemplatesIcon />}
            className="FormSurface">
            <TemplateForm
                product={product}
                catalogs={catalogs}
                templateId={workfloveId}
                byEachPayment={byEachPayment ?? []}
                shippingTemplateId={shippingTemplateId}
                getPromise={updatePromise}
                onCompleted={onCompleted} />
        </Surface>
    );
}