import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import DateFormat from "../components/DateFormat";
import { PaymentOrderFile } from "../model/PaymentOrder";
import translate from "../i18n/Translator";
import PaymentOrderFilesMenu from "./PaymentOrderFilesMenu";
import { PaymentOrder, ExecutePaymentFileRequest } from "../model/PaymentOrder";
import NumberFormat from 'react-number-format';
import Gridable from "../components/Gridable";

export interface PaymentOrderFileListCallbacks {
    onClickedOptions(paymentOrderFileList: PaymentOrderFile, anchorEl: null | HTMLElement): any;
}

interface PaymentOrderFilesSectionProps {
    paymentOrder: PaymentOrder;
    onCancelPaymentFile(paymentOrderFileId: string): any;
    onExecute(field: ExecutePaymentFileRequest): any;
}

export default function PaymentOrderFilesSection(props: PaymentOrderFilesSectionProps) {

    if (props.paymentOrder.payment_files === undefined || props.paymentOrder.payment_files.length <= 0) {
        return <div></div>;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box pl={2}>
                    <Typography variant="body2" component="h6" paragraph>
                        <strong>
                            {translate("payment_order.payment_files_subtitle")}
                        </strong>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={props.paymentOrder.payment_files ? props.paymentOrder.payment_files : []}
                    loading={false}
                    empty={translate("cfdis.empty") as string}
                    columns={[
                        {
                            title: translate("payment_order.columns.download_date"),
                            converter: (paymentOrderFile) => (
                                <DateFormat date={paymentOrderFile.date} format="L" />
                            ),
                            fullWidth: true,
                            xs: false,
                            sm: 2,
                            md: 2,
                            lg: 3,
                            xl: 3,
                        }, {
                            title: translate("payment_order.columns.cfdis_included") as string,
                            converter: (paymentOrderFile) => paymentOrderFile.cfdis ? paymentOrderFile.cfdis.length : undefined,
                            fullWidth: true,
                            xs: false,
                            sm: 3,
                            md: 3,
                            lg: 3,
                            xl: 3,
                        }, {
                            title: translate("payment_order.columns.total") as string,
                            converter: (paymentOrderFile) => (
                                <Grid container justify="space-evenly" >
                                    <Grid item xs={12}>
                                        <NumberFormat value={paymentOrderFile.total} 
                                            prefix="$ " decimalScale={2} 
                                            fixedDecimalScale={true} thousandSeparator=","
                                            displayType="text" />
                                    </Grid>
                                </Grid>),
                            fullWidth: true,
                            xs: 4,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        }, {
                            title: translate("payment_order.columns.status") as string,
                            converter: (paymentOrderFile) => translate(`payment_order.status.${paymentOrderFile.status}`) as string,
                            fullWidth: true,
                            xs: 3,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        }, {
                            title: (
                                <Typography variant="body2" component="h6" align="right">
                                    <strong>
                                        {translate("payment_order.columns.actions")}
                                    </strong>
                                </Typography>
                            ),
                            converter: (paymentOrderFile) => paymentOrderFile.status === "CANCELED" ? (
                                <Typography variant="body2" component="h6" align="right">
                                    ---
                                </Typography>
                            ) : (
                                <PaymentOrderFilesMenu paymentOrderFile={paymentOrderFile}
                                    paymentOrderId={props.paymentOrder.id}
                                    onExecute={props.onExecute}
                                    onCancelPaymentFile={props.onCancelPaymentFile} />
                            ),
                            fullWidth: true,
                            xs: 5,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2,
                        }
                    ]}
                />
            </Grid>
        </Grid>
    );
}