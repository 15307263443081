import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";

import translate from "../i18n/Translator";
import { SatContribution, SatContributionFileType } from "../model/SatContribution";
import { AppContext } from "../context/AppContext";
import { getSatContributionFileUrl } from "../api/SatContributionAPI";
import { getSatContributionFileUrl as getProviderSatContributionFileUrl } from "../api/ProviderSatContributionAPI";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';

interface SatTaxMenuProps {
    satContribution: SatContribution;
    anchor: HTMLElement | null;
    onDelete(): any;
    onDeleteComplementary(): any;
    onClose(): any;
}

export default function SatTaxMenu(props: SatTaxMenuProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    console.log(props.satContribution);
    const canDelete = ( props.satContribution.bank_voucher === undefined || props.satContribution.refuse_reason_bank_voucher) &&
                        props.satContribution.complementary_payment === undefined &&
                        props.satContribution.complementary_bank_voucher === undefined;
    const canDeleteComplementary = props.satContribution.complementary_payment !== undefined &&
                        (props.satContribution.complementary_bank_voucher === undefined || props.satContribution.complementary_refuse_reason_bank_voucher) &&
                        props.satContribution.status !== "COMPLETED";
    const [bankVoucher, setBankVoucher] = useState<string>();
    const [payment, setPayment] = useState<string>();
    const [complementaryBankVoucher, setComplementaryBankVoucher] = useState<string>();
    const [complementaryPayment, setComplementaryPayment] = useState<string>();

    const promiseGetUrl = (fileType: SatContributionFileType) => {
        if (providerId) {
            return getProviderSatContributionFileUrl(tenantId, providerId, props.satContribution.id, fileType);
        }
        return getSatContributionFileUrl(tenantId, props.satContribution.id, fileType);
    };

    const load = () => {
        Promise.all([
            promiseGetUrl("payment"),
            promiseGetUrl("bank_voucher"),
            promiseGetUrl("complementary_payment"),
            promiseGetUrl("complementary_bank_voucher"),
        ]).then(urls => {
            setPayment(urls[0]);
            setBankVoucher(props.satContribution.bank_voucher ? urls[1] : undefined);
            setComplementaryPayment(props.satContribution.complementary_payment ? urls[2] : undefined);
            setComplementaryBankVoucher(props.satContribution.complementary_bank_voucher ? urls[3] : undefined);
        }).catch(error => {
            console.error(error);
        });
    };

    useEffect(load, [tenantId, providerId, props.satContribution]);

    return (
        <Menu
            id="contributions/sat-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["SatContributionsUpdate", "ProviderSatContributionsUpdate"]) 
                && props.satContribution.status !== "COMPLETED" 
                && props.satContribution.status !== "TO_VALIDATE"
                && props.satContribution.status !== "REJECTED" &&
                <MenuItem button component={Link} to={`/contributions/sat/${props.satContribution.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            <MenuItem button onClick={props.onClose} dense component="a" href={payment || "#"} target="_blank" rel="noopener noreferrer" disabled={!payment}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("sat_contributions.payment")}</Typography>
            </MenuItem>
            <MenuItem button onClick={props.onClose} dense component="a" href={bankVoucher || "#"} target="_blank" rel="noopener noreferrer" disabled={!bankVoucher}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("sat_contributions.bank_voucher")}</Typography>
            </MenuItem>
            {props.satContribution.needs_complementary && (
                <MenuItem button onClick={props.onClose} dense component="a" href={complementaryPayment || "#"} target="_blank" rel="noopener noreferrer" disabled={!complementaryPayment}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.complementary_payment")}</Typography>
                </MenuItem>
            )}
            {props.satContribution.needs_complementary && (
                <MenuItem button onClick={props.onClose} dense component="a" href={complementaryBankVoucher || "#"} target="_blank" rel="noopener noreferrer" disabled={!complementaryBankVoucher}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.complementary_bank_voucher")}</Typography>
                </MenuItem>
            )}
            {context.isGrantedAny(["SatContributionsDelete", "ProviderSatContributionsDelete"]) && canDelete &&
                <MenuItem button onClick={props.onDelete} dense>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.delete")}</Typography>
            </MenuItem>
            }
            {context.isGrantedAny(["SatContributionsDelete", "ProviderSatContributionsDelete"]) && canDeleteComplementary &&
                <MenuItem button onClick={props.onDeleteComplementary} dense>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.delete")}</Typography>
            </MenuItem>
            }
        </Menu>
    );

}