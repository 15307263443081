import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel, Divider, IconButton } from "@material-ui/core";
import FocaltecAnnouncementsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';

import { createFocaltecAnnouncement, getFocaltecAnnouncement, updateFocaltecAnnouncement } from "../api/FocaltecAnnouncementAPI";
import translate from "../i18n/Translator";
import { FocaltecAnnouncement, FocaltecAnnouncementRequest, TYPES, USERS_TYPES } from "../model/FocaltecAnnouncement";
import FocaltecAnnouncementSelectRecipientsPopUp from "./FocaltecAnnouncementSelectRecipientsPopUp";
import { getTenants } from "../api/TenantAPI";
import { Tenant, TenantsQueryParams } from "../model/Tenant";

import Gridable from "../components/Gridable";
import DateRange from "../components/DateRange";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";

export default function FocaltecAnnouncementForm({ match }: RouterParams) {
    const history = useHistory();
    const focaltecAnnouncementId = match.params.focaltecAnnouncementId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const typeLabels = TYPES.map((code) => translate(`focaltec_announcements.types.${code}`) as string);
    const usersTypesLabels = USERS_TYPES.map((code) => translate(`focaltec_announcements.users_types.${code}`) as string);
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [tenantsInAnnouncement, setTenantsInAnnouncement] = useState<Tenant[]>([]);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const today = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate());
    const [request, setRequest] = useState<FocaltecAnnouncementRequest>({
                    permanent: false,
                    start_date: new Date(),
                    end_date: new Date(today.getFullYear(), today.getMonth()+1, today.getDate()),
    } as FocaltecAnnouncementRequest);
    const [validations, setValidations] = useState({} as any);
    const [openRecipients, setOpenRecipients] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>();

    const submitPromise = (): Promise<FocaltecAnnouncement> => {
        let fullRequest = { ...request, send_all_tenants: "ALL" === request.user_type, tenant_ids: tenantsInAnnouncement.map(c => c.id) } as FocaltecAnnouncementRequest;

        if (focaltecAnnouncementId) {
            return updateFocaltecAnnouncement(focaltecAnnouncementId, fullRequest);
        }
        return createFocaltecAnnouncement(fullRequest);
    };

    const fillTenants = (tenantIdsInAnnouncement: string[]) => {
        getTenants(0, 0, { search: ""} as TenantsQueryParams).then((response) => {
            let items = response.items;
            setTenants(items);
            if(tenantIdsInAnnouncement && tenantIdsInAnnouncement.length > 0){
                setTenantsInAnnouncement(items.filter(c => tenantIdsInAnnouncement.includes(c.id)));
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(() => {
        setStatus("loading");
        if (focaltecAnnouncementId) {
            getFocaltecAnnouncement(focaltecAnnouncementId).then((focaltecAnnouncement) => {
                setRequest({
                    type: focaltecAnnouncement.type,
                    permanent: focaltecAnnouncement.permanent,
                    message: focaltecAnnouncement.message,
                    start_date: focaltecAnnouncement.start_date,
                    end_date: focaltecAnnouncement.end_date,
                    user_type: focaltecAnnouncement.user_type,
                    tenant_ids: focaltecAnnouncement.tenant_ids,
                    send_all_tenants: focaltecAnnouncement.send_all_tenants,
                } as FocaltecAnnouncementRequest);
                fillTenants(focaltecAnnouncement.tenant_ids);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            fillTenants([]);
            setStatus("loaded");
        }
    }, [focaltecAnnouncementId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        if("ALL" !== request.user_type && (!tenantsInAnnouncement || tenantsInAnnouncement.length < 1)){
                setWarning(translate("focaltec_announcements.empty_tenants") as string);
                return;
        }

        setSubmitting(true);
        submitPromise().then((focaltecAnnouncement) => {
            history.push("/focaltec-announcements");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const newDate = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        return date;
    }

    const maskStartDate = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const onChangeDateRange = (startDate: Date, dateTo: Date) => {
        if(startDate < today){
            setWarning(translate("providers.announcements.warning.dates_today") as string);
            return;
        }
        if(request.end_date < request.start_date){
            setWarning(translate("providers.announcements.warning.dates") as string);
            return;
        }
        setRequest({
            ...request, start_date: maskStartDate(startDate),
            end_date: maskStartDate(dateTo)
        });
    };

    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === "true") {
            setRequest({ ...request, permanent: true });
        } else {
            setRequest({ ...request, permanent: false });
        }
    };

    const onOpenSelectRecipients = () => {
        setOpenRecipients(true);
    };

    const updateRecipients = (tenantsSelected: Tenant[]) => {
        setTenantsInAnnouncement(tenantsInAnnouncement.concat(tenantsSelected));
        setOpenRecipients(false);
    }

    const onRemoveTenant = (tenantId: string) => {
        tenantsInAnnouncement.
        filter(c => c.id === tenantId).
        forEach(obsolete => tenantsInAnnouncement.
        splice(tenantsInAnnouncement.indexOf(obsolete), 1));
        setRequest({ ...request});
    }

    const onCloseSelectRecipients = () => {
        setOpenRecipients(false);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Surface title={translate(focaltecAnnouncementId ? "focaltec_announcements.edit" : "focaltec_announcements.new.title")} icon={<FocaltecAnnouncementsIcon />} backButton className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                        <Grid container justifyContent="center" >
                                            <Grid item xs={6}>
                                                <ValidatedInput type="text" id="type" name="type"
                                                    value={request.type}
                                                    label={translate("focaltec_announcements.type") as string}
                                                    required={true} disabled={submitting}
                                                    options={TYPES} optionLabels={typeLabels}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                        <Grid item xs={6} >
                                            <Box pl={2} pt={1} pr={1}>
                                                <Grid container justify="flex-start" justifyContent="space-between" alignItems="center">
                                                    <Grid item >
                                                        <Box pr={5}>
                                                            <FormLabel component="legend">{translate("focaltec_announcements.percistence") as string}</FormLabel>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box pr={1}>
                                                            <FormControl >
                                                                <RadioGroup row aria-label="is_permanent" name="is_permanent" value={request.permanent+""} onChange={onChangeRadio} >
                                                                    <FormControlLabel value="false" control={<Radio />} label={translate("focaltec_announcements.can_close") as string} />
                                                                    <FormControlLabel value="true" control={<Radio />} label={translate("focaltec_announcements.permanent") as string} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                        <Grid container justifyContent="flex-start" >
                                            <ValidatedInput type="text" id="message" name="message"
                                                value={request.message}
                                                label={translate("focaltec_announcements.message") as string}
                                                required={true} disabled={submitting}
                                                margin={margin}
                                                onValueChanged={hasChanged} />
                                        </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                        <Grid container justifyContent="center" spacing={1} >
                                            <Grid item xs={6}>
                                                <DateRange
                                                    title={translate("calendar_events.form.dates") as string}
                                                    startDate={request?.start_date ? new Date(request?.start_date) : newDate()}
                                                    endDate={request?.end_date ? new Date(request?.end_date) : newDate()}
                                                    onChange={onChangeDateRange}
                                                    key={"date-range"}
                                                    menuItemsCustom={["today", "custom"]}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ValidatedInput type="text" id="user_type" name="user_type"
                                                    value={request.user_type}
                                                    label={translate("focaltec_announcements.user_type") as string}
                                                    options={USERS_TYPES} optionLabels={usersTypesLabels}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box pt={2}>
                                        <Divider />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start">
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">
                                                    {translate("focaltec_announcements.recipients") as string}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container justifyContent="flex-start" direction="row-reverse">
                                                    <Grid item xs={6} md="auto">
                                                        <Button onClick={onOpenSelectRecipients} disabled={"ALL" === request.user_type} variant="text" size="large" color="secondary">
                                                            {translate("buttons.add")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px" }}>
                                    <Box px={2}>
                                        <Gridable
                                            items={tenantsInAnnouncement}
                                            loading={false}
                                            empty={translate("focaltec_announcements.empty") as string}
                                            columns={[
                                                {
                                                    title: translate("focaltec_announcements.tenants") as string,
                                                    converter: (value) => value.name,
                                                    fullWidth: true,
                                                    xs: true
                                                },
                                                {
                                                    title: (
                                                        <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    ),
                                                    converter: (value) => (
                                                        <IconButton
                                                            aria-label="options"
                                                            color="default"
                                                            size="small"
                                                            onClick={() => onRemoveTenant(value.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    ),
                                                    fullWidth: true,
                                                    xs: "auto",
                                                    sm: "auto",
                                                    md: "auto",
                                                    lg: "auto",
                                                    xl: "auto"
                                                }
                                            ]}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(focaltecAnnouncementId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                        {openRecipients &&
                            <FocaltecAnnouncementSelectRecipientsPopUp
                                tenants={tenants ? tenants.filter(c => !tenantsInAnnouncement.map(t => t.id).includes(c.id)) : []}
                                onClose={onCloseSelectRecipients}
                                doAction={updateRecipients} />
                        }
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}