import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import GridDx from "../components/GridDx";
import Progress from "../components/Progress";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Grid, Typography } from "@material-ui/core";
import { Filter } from '@devexpress/dx-react-grid';

import { listBudgetLines } from "../api/BudgetApi";
import { Team } from "../model/Team";
import { BudgetSimpleLine, BudgetSimpleLines, BudgetLinesListParams as LineParams } from "../model/Budget";

export interface BudgetLinesListParams {
    teams?: Team[];
    budgetId: string;
    tenantId: string;
    disableEditLine: boolean;
    onClickRow(id: string): any;
}

export default function BudgetLinesList(params: BudgetLinesListParams) {
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<BudgetSimpleLines>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const [paramsSearch, setParams] = useState<LineParams>({} as LineParams);
    const [teams, setTeams] = useState<Team[]>();
    const paramsToFilters = () => {
        return [
            { columnName: "external_id", value: paramsSearch.external_id },
            { columnName: "name", value: paramsSearch.name },
            { columnName: "ledger_account", value: paramsSearch.ledger_account },
            { columnName: "team", value: paramsSearch.team_name },
            { columnName: "initial_amount", value: paramsSearch.initial_amount },
            { columnName: "current_amount", value: paramsSearch.current_amount },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const [columnsFormat] = useState([
        { columnName: 'external_id', width: 250 },
        { columnName: 'name', width: 250 },
        { columnName: 'ledger_account', width: 250 },
        { columnName: 'team', width: 250 },
        { columnName: 'initial_amount', width: 250 }
    ]);

    const load = () => {
        setStatus("loading");
        listBudgetLines(params.tenantId, params.budgetId, pageSize, pageSize * page, paramsSearch).then((response) => {
            setData(response);
            setTeams(params.teams);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = paramsSearch;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                temp = { ...temp, [filter.columnName === "team" ? "team_name" : filter.columnName]: filter.value };
            }
        });
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const getTeam = (line: BudgetSimpleLine) => {
        if (line.team_name) {
            return line.team_name;
        }
        if (!teams || teams.length < 1) {
            return "---";
        }
        let team = teams!.find(item => line.team_id && line.team_id === item.id);
        if (!team) {
            return "---";
        }
        return team.name as string;
    };

    useEffect(load, [page, pageSize, paramsSearch, filters]);

    const columns = [
        {
            name: 'external_id',
            title: translate("budget.lists.columns.external_id") as string,
        },
        {
            name: 'name',
            title: translate("budget.lines.edit.name") as string,
        },
        {
            name: 'ledger_account',
            title: translate("budget.lines.edit.ledger_account_full") as string,
        },
        {
            name: 'current_amount',
            title: translate("budget.lines.edit.current_budget_amount") as string,
        },
        {
            name: 'team',
            title: translate("budget.lines.edit.team") as string,
            getCellValue: (row: any) => getTeam(row)
        },
        {
            name: 'initial_amount',
            title: translate("budget.lines.edit.inicial_amount") as string,
        },
    ];

    const currencyColumns = ['initial_amount', 'current_amount'];

    const textColumns = ['external_id', 'name', 'ledger_account', 'team', 'initial_amount'];

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !data) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onClickRow = (line: BudgetSimpleLine) => {
        if(!line || params.disableEditLine){
            return;
        }
        params.onClickRow(line.external_id);
    };

    return (
        <Grid>
            <GridDx
                loading={false}
                rows={data.items || []}
                columns={columns}
                noUseId={true}
                onClickRow={onClickRow}
                clickRowColumns={['external_id', 'name', 'ledger_account', 'team', 'initial_amount', 'current_amount']}
                columnsFormat={columnsFormat}
                amountCurrencyColumns={currencyColumns}
                textColumns={textColumns}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                filters={filters}
                setFiltersHandler={setFiltersHandler}
                heightTablePX={420}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
            />
        </Grid>

    )
}