import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { ExportIcon, SyncIcon } from "../components/Icons";
import translate from "../i18n/Translator";
import { Budget } from "../model/Budget";

interface BudgetLinesMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    budget: Budget;
    onDownloadTemplate(): any;
    onExportCurrentBudget(): any;
    onSyncBudgetLines(): any;
}

export default function BudgetLinesMenu(props: BudgetLinesMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="budget-line-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {props.budget.execution_status === "PLANNING" && context.isGrantedAny(["EditBudgetLines"]) &&
                <MenuItem button onClick={props.onDownloadTemplate!} dense>
                    <ListItemIcon>
                        <ExportIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("budget.lines.menu.download_template")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["ExportCurrentBudget"]) &&
                <MenuItem button onClick={props.onExportCurrentBudget!} dense>
                    <ListItemIcon>
                        <ExportIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("budget.lines.menu.export_current_budget")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["SyncBudgetLines"]) && ["PLANNING", "IN_EXECUTION"].indexOf(props.budget.execution_status) > -1 &&
                <MenuItem button onClick={props.onSyncBudgetLines} dense>
                    <ListItemIcon>
                        <SyncIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("budget.lines.menu.sync_budget_template_lines")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
}