import React, { useState, useEffect, useContext, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { Grid, Divider, Button, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArchiveIcon from "@material-ui/icons/Archive";
import OpenIcon from "@material-ui/icons/FolderOpen";

import { listPurchaseOrders, importPurchaseOrders, exportPurchaseOrders, exportSelectedPurchaseOrders, downloadFilesSelected, downloadAllFiles } from "../api/PurchaseOrderAPI";
import { listPurchaseOrders as listProviderPurchaseOrders } from "../api/ProviderPurchaseOrderAPI";
import { AppContext } from "../context/AppContext";
import { PurchaseOrder, PurchaseOrders, PurchaseOrdersQueryParams, STATUSES as PurchaseOrderStatuses, ACCEPTANCE_STATUSES as AcceptanceValues } from "../model/PurchaseOrder";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable, { GridableColumn } from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import NumberFormat from "react-number-format";
import PurchaseOrderMenu from "./PurchaseOrderMenu";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import PurchaseOrderAcceptPopup from "./PurchaseOrderAcceptPopup";
import PurchaseOrderRefusePopup from "./PurchaseOrderRefusePopup";
import PurchaseOrderCancelPopup from "./PurchaseOrderCancelPopup";
import PurchaseOrderFinalizePopup from "./PurchaseOrderFinalizePopup";
import { Family } from "../model/Connector";
import { ExportableObjectListCallbacks, ExportableObjectNameColumnTitle, ExportableObjectNameColumnValue } from "../components/ExportableObjectColumns";
import ExportableComponent from "../exportable/ExportableComponent";
import { listCompanies } from "../api/CompanyAPI";
import { CompaniesQueryParams, Company } from "../model/Company";
import Progress from "../components/Progress";
import { get } from "../api/TenantConfigurationApi";

export function OpenPurchaseOrdersList() {
    return (
        <PurchaseOrdersList status="O" icon={<OpenIcon />} />
    );
}

export function ArchivePurchaseOrdersList() {
    return (
        <PurchaseOrdersList icon={<ArchiveIcon />} />
    );
}

interface PurchaseOrdersListProps {
    status?: "O";
    icon: ReactNode;
}

function PurchaseOrdersList(props: PurchaseOrdersListProps) {
    const context = useContext(AppContext);
    const providerId = context.session!.provider?.id;
    const purchaseOrderLabels = PurchaseOrderStatuses.map(status => translate(`purchase_orders.status.${status}`) as string);
    const acceptanceLabels = AcceptanceValues.map(status => translate(`purchase_orders.acceptance.${status}`) as string);

    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): PurchaseOrdersQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status": props.status || (typeof qs["status"] === "string" ? qs["status"] as string : ""),
            "acceptance_status": typeof qs["acceptance_status"] === "string" ? qs["acceptance_status"] as string : undefined,
        } as PurchaseOrdersQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<PurchaseOrders>();
    const [params, setParams] = useState<PurchaseOrdersQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<PurchaseOrdersQueryParams>(paramsFromQueryString);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [dialog, setDialog] = useState<"accept" | "refuse" | "cancel" | "finalize">();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [allowCancelClosePO, setAllowCancelCloseOP] = useState<boolean>(false);
    const getPromise = (): Promise<PurchaseOrders> => {
        const offset = getOffset(page, pageSize);
        if (providerId) {
            return listProviderPurchaseOrders(context.session!.tenant!.id, providerId, pageSize, offset, params);
        }
        return listPurchaseOrders(context.session!.tenant!.id, pageSize, offset, params);
    };

    const load = () => {
        setStatus("loading");
        Promise.all([
            getPromise(),
            listCompanies(context.session!.tenant!.id, 0, 0, {search: ""} as CompaniesQueryParams), 
            get(context.session!.tenant!.id)
        ]).then(([purchaseOrders, companies, configuration]) => {
            setData(purchaseOrders);
            if (companies.total > 0) {
                setCompanies(companies.items);
            }
            setAllowCancelCloseOP(configuration.purchase_order_configuration.allow_cancel_close_purchase_orders_manually);
        }).catch(error => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["acceptance_status"] = workingParams.acceptance_status ? workingParams.acceptance_status : "";
        qs["page"] = "1";

        if (props.status) {
            delete qs["status"];
        } else {
            qs["status"] = workingParams.status === "" || workingParams.status === "---" ? "" : workingParams.status;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setParams(workingParams);
        if (props.status) {
            setParams({ ...workingParams, status: props.status });
        }
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onStatusChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: (value === "" || value === "---" ? "" : value) });
    };

    const onClickedOptions = (purchaseOrder: PurchaseOrder) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPurchaseOrder(purchaseOrder);
    };

    const onAccept = () => {
        if (!purchaseOrder) return;
        setDialog("accept");
        setAnchorEl(null);
    };

    const onAccepted = (response: PurchaseOrder) => {
        if (!purchaseOrder) return;
        setPurchaseOrder(response);
        closeDialog();
        setSuccess(translate("purchase_orders.accept.success") as string);
        load();
    };

    const onReject = () => {
        if (!purchaseOrder) return;
        setDialog("refuse");
        setAnchorEl(null);
    };

    const onRefused = (response: PurchaseOrder) => {
        setPurchaseOrder(response);
        closeDialog();
        setSuccess(translate("purchase_orders.refuse.success") as string);
        load();
    };

    const onCancel = () => {
        if (!purchaseOrder) return;
        if(!validatePurchaseOrderStatus("cancel",purchaseOrder)) return;
        if(!validatePurchaseOrderCfdisRelated(purchaseOrder)) {
            setError(translate("purchase_orders.cancel.error_cfdis_related") as string);
            return;
        }
        setDialog("cancel");
        setAnchorEl(null);
    };

    const onFinalize = () => {
        if (!purchaseOrder) return;
        if(!validatePurchaseOrderStatus("close",purchaseOrder)) return;
        setDialog("finalize");
        setAnchorEl(null);
    };

    const validatePurchaseOrderStatus = (operation: string, purchaseOrder: PurchaseOrder) : boolean => {
        if(purchaseOrder.status == "CANCELLED" || purchaseOrder.status == "CLOSED") {
            setError(translate(`purchase_order.${operation}.error_status`) as string);
            return false;
        }
        return true;
    };

    const validatePurchaseOrderCfdisRelated = (purchaseOrder: PurchaseOrder) : boolean => {
        if(purchaseOrder.cfdis != undefined && purchaseOrder.cfdis != null && 
            purchaseOrder.cfdis.length > 0) {
                return false;
        } else if (purchaseOrder.lines.length > 0) {
            return purchaseOrder.lines.filter(line => line.cfdis != undefined && line.cfdis != null && line.cfdis.length > 0).length == 0;
        }
        return true;
    };

    const onConfirmCancel = (response: PurchaseOrder) => {
        setPurchaseOrder(response);
        closeDialog();
        setSuccess(translate("purchase_orders.cancel.success") as string);
        load();
    };

    const onConfirmFinalize = (response: PurchaseOrder) => {
        setPurchaseOrder(response);
        closeDialog();
        setSuccess(translate("purchase_orders.finalize_success") as string);
        load();
    };
    const closeDialog = () => {
        setDialog(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPurchaseOrder(undefined);
    };

    const onClicked = (purchaseOrder: PurchaseOrder) => {
        history.push(`/purchase-orders/${purchaseOrder.id}`);
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const handleCheck = (purchaseOrder: PurchaseOrder) => {
        let items = selectedIds.length === 0 ? ([] as string[]) : selectedIds.join(",").split(",");
        const index = selectedIds.indexOf(purchaseOrder.id);
        if (index < 0) {
            items.push(purchaseOrder.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedIds(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.items.map(purchaseOrder => purchaseOrder.id);
        } else {
            items = [] as string[];
        }
        setSelectedIds(items);
    };

    const callbacks = {
        handleCheck: handleCheck,
        handleAllChecks: handleAllChecks
    } as ExportableObjectListCallbacks;

    const onExportPurchaseOrders = (tenantId: string, connectorId: string, startDate?: Date | undefined, endDate?: Date | undefined) => {
        let params = workingParams;
        params.start_date = startDate;
        params.end_date = endDate;
        return exportPurchaseOrders(tenantId, connectorId, params );
    }

    const onDownloadFilesSelected = (tenantId: string) => {
        return downloadFilesSelected(tenantId, selectedIds);
    }

    const onDownloadAllFiles = (tenantId: string, startDate?: Date | undefined, endDate?: Date | undefined) => {
        let params = workingParams;
        params.start_date = startDate;
        params.end_date = endDate;
        return downloadAllFiles(tenantId, params);
    }

    const getCompanyName = (purchaseOrder: PurchaseOrder) => {
        let company = companies.find(item => purchaseOrder.company_id && item.id === purchaseOrder.company_id);
        if (company) {
            return company.name;
        } 
        return "---";
    }

    const getColumns = () => {
        const columns = [
            {
                title: (
                    <ExportableObjectNameColumnTitle
                        total={data?.items.length || 0}
                        selected={selectedIds.length}
                        callbacks={callbacks} />
                ),
                converter: (purchaseOrder) => (
                    <ExportableObjectNameColumnValue
                        exportableObject={purchaseOrder}
                        checked={selectedIds.indexOf(purchaseOrder.id) >= 0}
                        callbacks={callbacks}
                    />
                ),
                fullWidth: true,
                xs: true
            },
            {
                title: translate("purchase_orders.short_document_number") as string,
                converter: (purchaseOrder) => purchaseOrder.document_number || "---",
                fullWidth: true,
                xs: false,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1
            },
            providerId ? undefined : {
                title: translate("purchase_orders.provider") as string,
                converter: (purchaseOrder) => (
                    <Ellipsis text={purchaseOrder.provider?.name || "---"} lenght={100} uppercased={false} />
                ),
                fullWidth: true,
                xs: 3,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: translate("purchase_orders.company") as string,
                converter: (purchaseOrder) => (
                    <Ellipsis text={getCompanyName(purchaseOrder)} lenght={100} uppercased={false} />
                ),
                fullWidth: true,
                xs: 2
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("purchase_orders.total")}</strong>
                    </Typography>
                ),
                converter: (purchaseOrder) => (
                    <Typography variant="body2" component="h6" align="right">
                        <NumberFormat value={purchaseOrder.total} prefix="$ " suffix={` ${purchaseOrder.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                    </Typography>
                ),
                fullWidth: true,
                xs: providerId ? 5 : 4,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            },
            {
                title: translate("purchase_orders.date") as string,
                converter: (purchaseOrder) => (
                    <DateFormat date={purchaseOrder.date} format="YYYY-MM-DD" />
                ),
                fullWidth: true,
                xs: false,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1
            },
            {
                title: translate("purchase_orders.due_date") as string,
                converter: (purchaseOrder) => (
                    <DateFormat date={purchaseOrder.date} format="YYYY-MM-DD" />
                ),
                fullWidth: true,
                xs: false,
                sm: false,
                md: false,
                lg: 1,
                xl: 1
            },
            {
                title: translate("purchase_orders.status.title") as string,
                converter: (purchaseOrder) => (
                    <div>
                        <Typography variant="body2" component="h6">
                            {translate(`purchase_orders.status.${purchaseOrder.status}`)}
                        </Typography>
                        <Ellipsis
                            text={purchaseOrder.acceptance_status ? translate(`purchase_orders.acceptance.${purchaseOrder.acceptance_status}`) as string : "---"}
                            lenght={0} secondary uppercased={false} />
                    </div>
                ),
                fullWidth: true,
                xs: false,
                sm: 2,
                md: 2,
                lg: 1,
                xl: 1
            },
            {
                title: (
                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                        <MoreVertIcon />
                    </IconButton>
                ),
                converter: (workflow) => (
                    <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(workflow)}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: "auto"
            }
        ] as GridableColumn<PurchaseOrder>[];

        return columns.filter(c => !!c);
    };

    return (
        <Pagination title={translate("purchase_orders.title")} icon={props.icon}
            subtitle={translate(props.status ? "purchase_orders.open" : "purchase_orders.archive")}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("PurchaseOrdersCreate") ? (
                <ExportableComponent
                    tenantId={context.session?.tenant?.id || ""}
                    family={Family.PURCHASE_ORDERS}
                    selectedIds={selectedIds}
                    total={data?.total}
                    createAction="PurchaseOrdersCreate"
                    getAction="PurchaseOrdersRead"
                    onImported={load}
                    getImportPromise={(tenantId, file) => importPurchaseOrders(tenantId, file)}
                    getExportSelectedPromise={(tenantId, connectorId) => exportSelectedPurchaseOrders(tenantId, connectorId, selectedIds)}
                    getExportWithParamsPromise={onExportPurchaseOrders}
                    showExportFiles={true}
                    getDownloadFilesSelectedPromise={onDownloadFilesSelected}
                    getDownloadFilesWithParamsPromise={onDownloadAllFiles}
                />
            ) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("purchase_orders.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    {!props.status && (
                        <Grid item xs="auto">
                            <ValidatedInput type="text" id="status" name="status" label={translate("purchase_orders.status.title") as string}
                                options={PurchaseOrderStatuses} optionLabels={purchaseOrderLabels}
                                margin="dense" disabled={false}
                                value={workingParams.status} onValueChanged={onStatusChanged} />
                        </Grid>
                    )}
                    <Grid item xs="auto">
                        <ValidatedInput type="text" id="acceptance_status" name="acceptance_status" label={translate("purchase_orders.acceptance.title") as string}
                            options={AcceptanceValues} optionLabels={acceptanceLabels}
                            margin="dense" disabled={false}
                            value={workingParams.acceptance_status} onValueChanged={onStatusChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("purchase_orders.empty") as string}
                columns={getColumns()}
                onClick={onClicked} />
            {purchaseOrder && anchorEl &&
                <PurchaseOrderMenu purchaseOrder={purchaseOrder}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onAccept={onAccept}
                    onReject={onReject}
                    onCancel={onCancel}
                    onFinalize={onFinalize}
                    allowCancelClosePOManually={allowCancelClosePO}
                    restrictUploadCfdi={data ? data.restrict_upload_cfdi : false} />
            }
            {purchaseOrder && dialog === "accept" && (
                <PurchaseOrderAcceptPopup
                    purchaseOrder={purchaseOrder}
                    onClose={closeDialog}
                    onAccepted={onAccepted}
                    onError={setError} />
            )}
            {purchaseOrder && dialog === "refuse" && (
                <PurchaseOrderRefusePopup
                    purchaseOrder={purchaseOrder}
                    onClose={closeDialog}
                    onRefused={onRefused}
                    onError={setError} />
            )}
            {purchaseOrder && dialog === "cancel" && (
                <PurchaseOrderCancelPopup
                    purchaseOrder={purchaseOrder}
                    onClose={closeDialog}
                    onCancel={onConfirmCancel}
                    onError={setError} />
            )}
            {purchaseOrder && dialog === "finalize" && (
                <PurchaseOrderFinalizePopup
                    purchaseOrder={purchaseOrder}
                    onClose={closeDialog}
                    onFinalize={onConfirmFinalize}
                    onError={setError} />
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </Pagination>
    );
}

