import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput from "../components/ValidatedInput";
import { ActionsQueryParams, Action, Actions } from "../model/Action";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { CheckBoxCallbacks, CheckBoxColumnTitle, CheckBoxColumnValue } from "../components/GridItemCheckBox";
import { RolesQueryParams } from "../model/Role";
import { listRoles } from "../api/RoleAdminAPI";
import { listActionCategories } from "../api/ActionAPI";
import { ErrorSnackbar } from "../components/Snackbars";
import { listActions } from "../api/ActionAPI";
import CustomBackdrop from "../components/CustomBackdrop";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";

interface AddActionPopupProps {
    title: string;
    message: string;
    type: "add" | "exclude";
    roleId: string;
    tenantId: string; 
    actionIds: string[]
    onAddActions(actions: Action[]): any;
    onClose(): any;
}

export default function AddActionPopup(props: AddActionPopupProps) {
    const isAddType = props.type === "add";
    const [params, setParams] = useState<ActionsQueryParams>({
        actionsExclude: props.actionIds,
        rol: "",
        search: "",
        category: ""
    } as ActionsQueryParams);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [roles, setRoles] = useState<string[]>([]);
    const [rolLabels, setRolLabels] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>();
    const [categoryLabels, setCategoryLabels] = useState<string[]>();
    const [actions, setActions] = useState<Actions>();
    const [selected, setSelected] = useState<string[]>([]);
    const [error, setError] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    useEffect(() => {
        if(isAddType){
            setOpenBackdrop(true);
            Promise.all([
                listRoles(0, 0, { roleId: props.roleId, search: "", tenantId: props.tenantId } as RolesQueryParams), 
                listActionCategories(0, 0)
            ]).then((responses) => {
                setRoles(responses[0].items.map(item => item.id));
                setRolLabels(responses[0].items.map(item => item.description ? item.description : translate(`users.roles.${item.id}`) as string)); 
                setCategories(responses[1].items.map(item => item.category));
                setCategoryLabels(responses[1].items.map(item => item.category_label));
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        } else {
            setOpenBackdrop(true);
            Promise.all([
                listActionCategories(0, 0)
            ]).then((responses) => {
                setCategories(responses[0].items.map(item => item.category));
                setCategoryLabels(responses[0].items.map(item => item.category_label));
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        }
    }, []);

    const onSearch = () => {
        setPage(1);
        setPageSize(20);
        onSearchWithPages(1, 20);
    }

    const onSearchWithPages = (page: number, pageSize: number) => {
        setSubmitting(true);
        setOpenBackdrop(true);
        let tempParams = params;
        if(!isAddType){
            tempParams.rol = props.roleId;
        }
        let offset = getOffset(page, pageSize);
        listActions(pageSize, offset, tempParams).then((response) => {
            setActions(response);
            setSelected(response.items.filter(item => props.actionIds.findIndex(actionId => item.public_id === actionId) >= 0).map(item => item.public_id));
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setSubmitting(false);
        });
    }

    const onChange = (name: string, value: string) => {
        let fixedValue = value === "---" ? "" : value;
        setParams({...params, [name]: fixedValue});
    }

    const onAddActions = () => {
        if(!actions) return;
        let actionsToAdd = actions.items.filter(action => selected.find(sel => sel === action.public_id));
        props.onAddActions(actionsToAdd);
    }

    const buttonAdd = () => {
        return (
            <Button onClick={onAddActions} variant="contained" color="primary">
                {translate("buttons.add") as string}
            </Button>
        );
    }

    const handleAllChecks = (checked: boolean) => {
        if(!actions) return;
        var items;
        if (checked) {
            items = actions.items!.map(provider => provider.public_id);
        } else {
            items = [] as string[];
        }
        setSelected(items);
    };

    const handleCheck = (field: Action) => {
        let items = selected.length === 0 ? ([] as string[]) : selected.join(",").split(",");
        const index = selected.indexOf(field.public_id);
        if (index < 0) {
            items.push(field.public_id);
        } else {
            items.splice(index, 1);
        }
        setSelected(items);
    };

    const onClosedSnackbars = () => {
        setError(undefined);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        onSearchWithPages(page, pageSize);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        onSearchWithPages(page, pageSize);
    };

    return (
        <DialogPopup open
            title={props.title}
            onClose={props.onClose}
            closeColor="default"
            button={buttonAdd()}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            maxWidth="lg">
            <Grid container spacing={2} >
                <Grid item xs={12}>                
                    <Typography variant="body2">
                        {props.message}
                    </Typography>
                </Grid>
                {isAddType &&
                    <Grid item xs={3}>
                        <ValidatedInput type="text" id="rol" name="rol"
                            value={params.rol} label={translate("users.roles.title") as string}
                            required={true} disabled={submitting}
                            options={roles} optionLabels={rolLabels}
                            margin={"dense"}
                            onValueChanged={onChange} />
                    </Grid>
                }
                <Grid item xs={isAddType ? 3 : 4}>
                    <ValidatedInput type="text" id="category" name="category"
                        value={params.category} label={translate("roles_admin.actions.category") as string}
                        required={true} disabled={submitting}
                        options={categories} optionLabels={categoryLabels}
                        margin={"dense"}
                        onValueChanged={onChange} />
                </Grid>
                <Grid item xs={isAddType ? 4 : 6}>
                    <ValidatedInput type="text" id="search" name="search" label={translate("roles_admin.actions.search") as string}
                        margin="dense" disabled={false}
                        value={params.search} onValueChanged={onChange} />
                </Grid>
                <Grid item xs={2}>
                    <Box pt={1}>
                        <Button variant="outlined" color="secondary" size="medium" onClick={onSearch}>
                            {translate("buttons.search")}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                <Pagination title={undefined} icon={undefined} variant="embeded"
                    page={page} pageSize={pageSize} count={actions ? actions.items.length : 0} total={actions ? actions.total : 0}
                    onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
                <Gridable
                    items={actions ? actions.items : []}
                    loading={false}
                    error={undefined}
                    empty={translate("roles_admin.actions.empty_modal") as string}
                    columns={[
                        {
                            title: (
                                <CheckBoxColumnTitle
                                    label_title={translate("roles_admin.actions.identifier") as string}
                                    total={actions ? actions.items.length : 0}
                                    selected={selected.length}
                                    callbacks={{ handleAllChecks: handleAllChecks } as CheckBoxCallbacks<Action>}
                                    showChecks={true} />
                            ),
                            converter: (action) => (
                                <CheckBoxColumnValue
                                    item={action}
                                    checked={selected.indexOf(action.public_id) >= 0}
                                    showChecks={true}
                                    value={action.public_id}
                                    disabled={props.actionIds.findIndex(actionId => action.public_id === actionId) >= 0}
                                    callbacks={{ handleCheck: handleCheck } as CheckBoxCallbacks<Action>} />
                            ),
                            fullWidth: true,
                            xs: true,
                        },
                        {
                            title: translate("roles_admin.actions.category") as string,
                            converter: (action) => (
                                <Ellipsis text={ action.category_label || "" } lenght={ 100 } uppercased={ false } />
                            ),
                            xs: true
                        },
                        {
                            title: translate("roles_admin.actions.description") as string,
                            converter: (action) => (
                                <Ellipsis text={ action.description || "" } lenght={ 100 } uppercased={ false } />
                            ),
                            xs: true
                        }
                    ]}  />
                    </Pagination> 
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </DialogPopup>
    );
}

