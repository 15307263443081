import React, { useState, useEffect } from "react";
import { RouterParams } from "../router/RouterParams";
import { Box, Grid, Typography, Card, CardHeader, CardContent, Button, Divider } from "@material-ui/core";
import translate from "../i18n/Translator";
import Progress from "../components/Progress";
import ReactHtmlParser from "html-react-parser";
import NumberFormat from "react-number-format";
import { HourglassEmptyIcon, ValidIcon } from "../components/Icons";
import { ProviderMembershipTaxStamp } from "../model/ProviderMembershipTaxStamp";
import { getProviderMembershipTaxStamp } from "../api/ProviderMembershipTaxStampApi";

export interface InfoPaymentInReview {
  providerMembershipTaxStampId: string;
}

export default function InfoPaymentPlanProvider({ match }: RouterParams) {
  return InfoPayment(match.params.providerMembershipTaxStampId, false);
}

export function InfoPaymentPlanProviderInReview({ match }: RouterParams) {
  return InfoPayment(match.params.providerMembershipTaxStampId, true);
}

export function InfoPaymentPlanProviderInReviewWithProps(props: InfoPaymentInReview) {
  return InfoPayment(props.providerMembershipTaxStampId, true);
}

function InfoPayment(providerMembershipTaxStampId: string, inReview: boolean) {
  const [status, setStatus] = useState<string>("loading");
  const [buyPlanResponse, setBuyPlanResponse] = useState<ProviderMembershipTaxStamp>({ } as ProviderMembershipTaxStamp);
  const load = () => {
    getProviderMembershipTaxStamp(providerMembershipTaxStampId).then(response => {
      setBuyPlanResponse(response);
          setStatus("loaded");
      }).catch(error => {
          setStatus(error.message);
      });
  };

  useEffect(load, []);

  if (status === "loading") {
      return (
          <Progress />
      );
  }

  if (status !== "loaded") {
      return (
          <div>{status}</div>
      );
  }

    return (<>
    <Grid container spacing={4}>
            <Grid item xs={12}>
                <Grid container alignContent="center" alignItems="center" justifyContent="center">
                    <Grid item md={4} xs={1} xl={4}>
                    </Grid>
                    <Grid item md={4} xs={10} xl={3}>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="primary" style={{fontSize: "30px"}}><b>{translate(inReview ? "plan_tax_stamp.buy_plan.in_review_title" : "plan_tax_stamp.plans.title") as string}</b></Typography>
                            </Grid>
                        </Grid>
                        {!inReview && <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="secondary" style={{fontSize: "35px"}}><b>{translate("plan_tax_stamp.plans.title_clic") as string}</b></Typography>
                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid item md={4} xs={1} xl={5}>
                    </Grid>
                </Grid>
            </Grid>
    </Grid><br/>
    <Grid container alignContent="center" justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardHeader subheader={
                    <Grid container>
                        <Grid item xs={1}>
                          {inReview ? <HourglassEmptyIcon color="secondary"/> : <ValidIcon style={{ "color": "#82CA9D" }}/>}
                        </Grid>
                        <Grid item xs={11}>
                            <Typography><b>{translate(inReview ? "plan_tax_stamp.buy_plan.in_review_title_card" : "plan_tax_stamp.buy_plan.tanks")}</b></Typography>
                        </Grid>
                    </Grid>
                } disableTypography />
                <Divider/>
              <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box>
                        <Typography><b>{translate("plan_tax_stamp.buy_plan.plan_info") as string}</b></Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pl={2} py={1}>
                        {buyPlanResponse.plan.name} - <NumberFormat value={buyPlanResponse.plan.price}
                          prefix="$ " decimalScale={2} fixedDecimalScale={true}
                          thousandSeparator="," displayType="text"
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box pl={2} py={1}>
                        {ReactHtmlParser(buyPlanResponse.plan.description)}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography><b>{translate("plan_tax_stamp.buy_plan.concact_info") as string}</b></Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pl={2} py={1}>
                        {buyPlanResponse.provider_name}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pl={2} py={1}>
                        {buyPlanResponse.name}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pl={2} py={1}>
                        {buyPlanResponse.phone}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pl={2} py={1}>
                        {buyPlanResponse.email}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pl={2} py={1}>
                        <Button
                          variant="text"
                          color="primary"
                          type="button"
                          component="a" href={buyPlanResponse.url_secure_voucher} target="_blank" rel="noopener noreferrer"
                        >
                          {translate("plan_tax_stamp.buy_plan.view_voucher") as string}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
              </CardContent>
              <br/>
            </Card>
          </Grid>
    </Grid>
    <br/>
    <Grid container spacing={4}>
      <Grid item xs={12}>
          <Grid container alignContent="center" alignItems="center" justifyContent="center">
              <Grid item md={4} xs={1} xl={4}>
              </Grid>
              <Grid item md={4} xs={10} xl={3}>
                  <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                      <Grid item>
                          <Typography>{translate("plan_tax_stamp.buy_plan.24_label") as string}</Typography>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item md={4} xs={1} xl={5}>
              </Grid>
          </Grid>
      </Grid>
    </Grid>
    </>);
}