import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, Divider, Button, Box } from "@material-ui/core";
import ListIcon from "@material-ui/icons/ListAlt";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { getDashboardBlackList } from "../api/MeAPI";
import { quickVerification } from "../api/ProviderAPI";
import { DashboardBlackListResponse } from "../model/Dashboard";
import { QuickVerificationRequest, QuickVerificationResponse } from "../model/Provider";
import Progress from "../components/Progress";
import { formatDate } from "../components/DateFormat";
import NumberFormat from "react-number-format";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { RfcValidator } from "../components/Validators";
import { AppContext } from "../context/AppContext";
import { ErrorSnackbar } from "../components/Snackbars";
import ProviderVerificationPopup from "../providers/ProviderVerificationPopup";

export default function BlackListWidget() {
    const context = useContext(AppContext);
    const rfcValidator = new RfcValidator();
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<DashboardBlackListResponse>();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<QuickVerificationRequest>({
        rfc: ""
    } as QuickVerificationRequest);
    const [verification, setVerification] = useState<QuickVerificationResponse>();
    const [validations, setValidations] = useState({
        "rfc": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        setStatus("loading");
        getDashboardBlackList().then((response) => {
            delete (response.efos as any)["LIMPIO"];
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        })
    }, []);

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onValueChanged = (name: string, value: string, inputRef: any) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) return;

        setSubmitting(true);
        quickVerification(context.session!.tenant!.id, request).then((result) => {
            setVerification(result);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const closeVerificationPopup = () => {
        setVerification(undefined);
    };

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded" || data === undefined) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface
            title={translate("providers.quick_search.title") as string}
            subtitle={translate("providers.quick_search.subtitle") as string}
            icon={<ListIcon />}>
            <Grid container direction="row-reverse" spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={11} xl={9}>
                            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                                <Grid container alignItems="flex-start" alignContent="flex-start" justify="flex-end" spacing={1}>
                                    <Grid item xs={12} sm>
                                        <ValidatedInput type="text" id="rfc" name="rfc" label={translate("providers.rfc") as string}
                                            margin="dense" disabled={false} required
                                            value={request.rfc || ""} onValueChanged={onValueChanged}
                                            validator={rfcValidator} />
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Box pt={1}>
                                            <Button type="submit" variant="contained" color="primary" size="medium" disabled={submitting}>
                                                {translate("buttons.verify")}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="button" component="h6">
                        {translate("cff_69_files.title")}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="h6">
                        {translate("efo_files.last_list", { "date": formatDate(data.last_update_not_located, "ll") })}
                    </Typography>
                    <Divider />
                    <Typography variant="h5" component="h5">
                        <NumberFormat value={data.not_located} thousandSeparator displayType="text" />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="button" component="h6">
                        {translate("efo_files.title")}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="h6">
                        {translate("efo_files.last_list", { "date": formatDate(data.last_update_efos, "ll") })}
                    </Typography>
                    <Divider />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="h5">
                                <NumberFormat value={(data.efos as any)["PRESUNTO"]} thousandSeparator displayType="text" />
                            </Typography>
                            <Typography variant="caption" color="textSecondary" component="h6">
                                {translate("efo.status.PRESUNTO")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="h5">
                                <NumberFormat value={(data.efos as any)["DEFINITIVO"]} thousandSeparator displayType="text" />
                            </Typography>
                            <Typography variant="caption" color="textSecondary" component="h6">
                                {translate("efo.status.DEFINITIVO")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {verification && (
                <ProviderVerificationPopup
                    rfc={request.rfc.toUpperCase()}
                    efos={verification.efos}
                    validations={verification.black_list_validations}
                    onClose={closeVerificationPopup} />
            )}
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Surface>
    );
}