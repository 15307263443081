export interface WebhookEvent {
    id: string;
    tenant_id: string;
    event: string;
    event_date: Date;
    entity: string;
    entity_id: string;
    webhook_id: string;
    event_tries: EventTry;
}

export interface WebhookEvents {
    items: WebhookEvent[];
    total: number;
}

export interface WebhookEventsQueryParams {
    event: string;
    event_start_date: string;
    event_end_date: string;
}

export interface WebhookEventTriesQueryParams {
    search: string;
}

export interface EventTryResponse {
    event_try: EventTry;
}

export interface EventTry {
    try_id: string;
    try_no: number;
    try_date: Date;
    http_code: number;
    message: string;
}

export interface WebhookEventTries {
    items: EventTryResponse[];
    total: number;
}

export const EVENTS = 
["provider_created", 
"provider_status_changed", 
"provider_updated",
"provider_digital_expedient_status_changed",
"cfdi_status_changed",
"payment_order_created",
"payment_order_status_changed",
"payment_order_deleted",
"requisition_status_changed",
"requisition_delivery_retry",
"credit_note_attached",
"credit_note_cancelled"];
