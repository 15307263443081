import React, { useContext, useEffect, useState } from "react";
import { IconButton, Button, Paper, TableCell } from "@material-ui/core";
import { Column } from "@devexpress/dx-react-grid";
import { Grid as GridDx, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

import translate from "../i18n/Translator";
import { EmployeeCfdi } from "../model/CfdiSe";
import { getPayrollXmlUrl } from "../api/PayrollAPI";
import { getPayrollXmlUrl as getPayrollProviderXmlUrl } from "../api/ProviderPayrollAPI";
import DateFormat from "../components/DateFormat";
import { AppContext } from "../context/AppContext";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CfdiIssuedPayrollMenu from "./CfdiIssuedPayrollMenu";

interface CfdiIssuedPayrollTableProps {
    invoices: EmployeeCfdi[];
}

const NoDataCell = (props: Table.NoDataCellProps) => (
    <TableCell colSpan={props.colSpan} align="center">
        {props.getMessage("noData")}
    </TableCell>
);

export default function CfdiIssuedPayrollTable(props: CfdiIssuedPayrollTableProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;

    const [columns, setColumns] = useState<Column[]>([]);
    const [items, setItems] = useState<EmployeeCfdi[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [employeeCfdi, setEmployeeCfdi] = useState<EmployeeCfdi>();

    const onClickedOptions = (employeeCfdi: EmployeeCfdi) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setEmployeeCfdi(employeeCfdi);
    };
    
    const DownloadButton = (employeeCfdi: EmployeeCfdi) => (
        <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(employeeCfdi)}>
            <MoreVertIcon />
        </IconButton>
    );

    const onCloseMenu = () => {
        setAnchorEl(null);
        setEmployeeCfdi(undefined);
    };

    useEffect(() => {
        const defaultColumns = [
            {
                name: "date",
                title: translate("cfdis_se.date") as string,
                getCellValue: (row: any, columnName: string) => (
                    <DateFormat date={row.date} format="DD/MM/YYYY" />
                )
            },
            {
                name: "from",
                title: translate("contracts.from") as string,
                getCellValue: (row: any, columnName: string) => (
                    <DateFormat date={row.from} format="DD/MM/YYYY" />
                )
            },
            {
                name: "to",
                title: translate("contracts.to") as string,
                getCellValue: (row: any, columnName: string) => (
                    <DateFormat date={row.to} format="DD/MM/YYYY" />
                )
            },
            {
                name: "identifier",
                title: translate("payrolls.identifier") as string,
                getCellValue: (row: any, columnName: string) => row.identifier
            }
        ];

            defaultColumns.push({
                name: "files",
                title: " ",
                getCellValue: (row: any, columnName: string) => DownloadButton(row),
            });

            const promises = props.invoices.map(el => {
                if(providerId){
                    return getPayrollProviderXmlUrl(tenantId, providerId, el.cfdi_id);
                }
                return getPayrollXmlUrl(tenantId, el.cfdi_id);
            });
            Promise.all(promises).then(responses => {
                setItems(props.invoices.map((invoice, index) => {
                    invoice.url = responses[index];
                    return invoice;
                }));
            }).catch(error => {
                console.error(error);
                setItems(props.invoices);
            }).finally(() => {
                setColumns(defaultColumns);
            });
    }, [tenantId, props.invoices]);

    return (
        <Paper variant="outlined">
            <GridDx
                rows={items}
                columns={columns}
            >
                <Table messages={{
                    noData: translate("cfdis_se.docs.no_invoices") as string
                }} noDataCellComponent={NoDataCell} />
                <TableHeaderRow />
            </GridDx>
            {anchorEl && employeeCfdi && (
                <CfdiIssuedPayrollMenu tenantId={tenantId} anchor={anchorEl} providerId={providerId} onClose={onCloseMenu} employeeCfdi={employeeCfdi}/>
            )}
        </Paper>
    );

}