import { callAPI, HTTPMethod } from "./API";
import { Cff69ListFile, Cff69ListFiles } from "../model/Cff69File";

export async function getCff69ListFiles(page: number, offset: number): Promise<Cff69ListFiles> {
    return await callAPI("/cff-69-files", {
        method: HTTPMethod.GET,
        query: {
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function updateCff69ListFiles(): Promise<Cff69ListFile> {
    return await callAPI("/cff-69-files", {
        method: HTTPMethod.PUT,
    });
}
