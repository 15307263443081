import React, { useState } from "react";
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { Button, Box, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";


export default function SketcjColorPicker(props) {
    const params = props?.props;

    const [state, setState] = useState({
        displayColorPicker: false,
        color: params?.color ?? "#FDFDFD",
    });

    const [currentColor, setCurrentColor] = useState(state?.color ?? "");

    const handleClick = () => {
        setState({ ...state, displayColorPicker: !state.displayColorPicker });
    };

    const handleClose = () => {
        setState({ ...state, displayColorPicker: false, color: currentColor });
        if (params.onSelectColor !== undefined) {
            params.onSelectColor(currentColor);
        }
    };

    const handleChange = (color) => {
        setCurrentColor(color.hex)
    };

    const onCancel = () => {
        setState({ ...state, displayColorPicker: false });
    };

    const styles = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: state?.color,
            },
            swatch: {
                "padding": '3px',
                "background": '#fff',
                "borderRadius": '1px',
                "boxShadow": '0 0 0 1px rgba(0,0,0,.1)',
                "display": 'inline-block',
                "cursor": 'pointer',
            },
            popover: {
                "zIndex": '2',
            },
            cover: {
                "position": 'fixed',
                "top": '0px',
                "right": '0px',
                "bottom": '0px',
                "left": '0px',
            },
        }
    });

    return (
        <div>
            <div style={styles?.swatch} onClick={handleClick}>
                <div style={styles?.color} />
            </div>
            {state.displayColorPicker ? <div style={styles.popover}>
                <SketchPicker color={currentColor} onChange={handleChange} />
                <Box pt={1} style={{ width: "220px" }}>
                    <Grid container justify={props.button ? (props.buttonLeft ? "flex-end" : "space-between") : "flex-end"} spacing={1}>
                        <Grid item xs="auto">
                            <Button onClick={onCancel} variant={props.variant ?? "outlined"} color={props.closeColor ?? "primary"} >
                                {translate("buttons.close")}
                            </Button>
                        </Grid>

                        <Grid item xs="auto">
                            <Button onClick={handleClose} variant="contained" color="primary" >
                                {translate("buttons.accept")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div> : null}
        </div>
    )
}


