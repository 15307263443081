import React, { useState, useEffect } from "react";

import { getTenantIntent } from "../api/AuthAPI";
import { TenantIntentResponse } from "../model/TenantIntent";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import GoHome from "../components/GoHome";
import { Grid, Typography } from "@material-ui/core";

export default function SignUpMessage({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<TenantIntentResponse>();

    useEffect(() => {
        setStatus("loading");
        getTenantIntent(match.params.code).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [match.params.code]);

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loaded") {
        return <GoHome message={status} />
    }

    return (
        <GoHome title={translate("auth.tenant.title", { "name": data!.user.first_name }) as string} showTitle>
            <Grid item xs={12} className="ExternButton">
                <Typography variant="body1" align="center" paragraph>
                    {translate("auth.tenant.text1", { "email": data!.user.email, "tenant": data!.name })}
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    {translate("auth.tenant.text2", { "email": data!.user.email, "tenant": data!.name })}
                </Typography>
                <Typography variant="body2" align="center" color="textSecondary">
                    {translate("auth.tenant.text3", { "email": data!.user.email, "tenant": data!.name })}
                </Typography>
            </Grid>
        </GoHome>
    );

}