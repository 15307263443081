import React, { useState, useEffect, useContext } from "react";
import { createPayment, uploadOperationFile } from "../api/PaymentApi";
import { listBankAccounts } from "../api/TenantBankAccountAPI";
import { getExchangeRates } from "../api/PaymentOrderApi";
import { BankAccountsQueryFilter, TenantBankAccounts, TenantBankAccount } from "../model/BankAccount";
import { Grid, Box, Button, Typography, Hidden, Tooltip } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import Gridable, { GridableColumn } from "../components/Gridable";
import { Payment, CfdisSelected, PaymentData, CfdiPayment, PaymentRequest, PaymentOrderCfdi, PaymentOperationFileRequest } from "../model/Payment";
import { get as getTenantConfiguration } from "../api/TenantConfigurationApi";
import { TenantConfiguration } from "../model/TenantConfiguration";
import Surface from "../components/Surface";
import PaymentIcon from '@material-ui/icons/Payment';
import NumberFormat from 'react-number-format';
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { OPERATION_TYPES } from "../catalogs/OperationTypes";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";
import InvalidIcon from '@material-ui/icons/CancelTwoTone';
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmationPopup from "../components/ConfirmationPopup";
import UploaderInput from "../components/UploaderInput";
import { CfdiDescription, CfdiAmounts } from "../cfdi/CfdiComponents";
import { GTZeroValidator, LTEValidator } from "../components/Validators";
import { ExchangeRates, ExchangeRate } from "../model/PaymentOrder";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import { Cfdi } from "../model/Cfdi";
import { Redirect, useHistory } from "react-router-dom";
import { isRoleOrParent } from "../model/Role";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { getPayment } from "../api/PaymentApi";
import queryString from "query-string";
import CreatePaymentCfdiModal from "../payment/CreatePaymentCfdiModal";
import PaymentCfdis from "./GridPaymentCfdis";
import GridPaymentCfdis from "./GridPaymentCfdis";

interface CreatePaymentViewProps {
    readOnly: boolean;
    paymentValues?: Payment;
    cfdisSelected: CfdisSelected;
    infoProvider: PaymentData;
    redirect?: string;
    next(request: Payment): any;
    onCancel(): any;
    fromPaymentOrder?: boolean;
    paymentOrderId?: string;
    paginateCfdis?: boolean;
}

export default function CreatePaymentView(props: CreatePaymentViewProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const currencies = props.cfdisSelected.cfdis.map(cfdi => cfdi.payment_currency || cfdi.currency).filter(curr => !!curr);
    const currency = currencies.length === 0 ? props.infoProvider!.currency : currencies[0];
    const operationTypesLabels = OPERATION_TYPES.map((type) => translate(`payments_cfdi.operation-types.${type}`) as string);
    const gtZeroValidator = new GTZeroValidator();

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CfdiPayment[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [total, setTotal] = useState<number>(0);
    const [request, setRequest] = useState<Payment>(props.paymentValues ? props.paymentValues : {} as Payment);
    const [bankAccount, setBankAccount] = useState<string[]>([]);
    const [bankAccountLabels, setBankAccountLabels] = useState<string[]>([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [fromPaymentOrder, setFromPaymentOrder] = useState(false);
    const [showExchangeRate, setShowExchangeRate] = useState(false); // solo si hay facturas de diferentes monedas
    const [useExchangeRate, setUseExchangeRate] = useState(true); // TODO leer de los configs del tenant
    const [validations, setValidations] = useState({} as any);
    const [validators, setValidators] = useState({} as any);
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const disableOperationFile = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi") || isRoleOrParent(context.session!.role, "reader_only");
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [containAdvancePayment, setContainAdvancePayment] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    const isCustomAgent = context.session?.provider?.type === "CUSTOMS_AGENT";
    const statusProviderTaxStamp = context.session?.provider?.status_membership_tax_stamp || "";
    const [openGeneratePaymentCfdiModal, setOpenGeneratePaymentCfdiModal] = useState<boolean>(false);
    const [openOutOfStockModal, setOpenOutOfStockModal] = useState<boolean>(false);
    const [openExpiredModal, setOpenExpiredModal] = useState<boolean>(false);
    const [paymentCfdiId, setPaymentCfdiId] = useState<string>();

    const getBankAccountsPromise = (): Promise<TenantBankAccounts> => {
        if (props.readOnly && props.paymentValues) {
            return Promise.resolve({
                items: [
                    {
                        id: props.paymentValues.bank_account_id,
                        name: props.paymentValues.bank_account_name,
                    } as TenantBankAccount,
                ]
            } as TenantBankAccounts);
        }

        const accountsRequest = { search: "", currency: currency } as BankAccountsQueryFilter;
        return listBankAccounts(context.session!.tenant!.id, 0, 0, accountsRequest);
    };

    const getExchangeRatePromise = (showExchangeRate: boolean): Promise<ExchangeRates> => {
        if (showExchangeRate) {
            let date = "";
            if (props.paymentValues && props.paymentValues.payment_date) {
                date = moment(props.paymentValues.payment_date).format("YYYY-MM-DD");
            }
            return getExchangeRates(context.session!.tenant!.id, date);
        }

        return Promise.resolve({
            dollar: { value: 1 } as ExchangeRate,
            euro: { value: 1 } as ExchangeRate,
        } as ExchangeRates);
    };

    const getConfiguration = (response: TenantConfiguration, propertyName: string) => {
        return response.configurations.filter(c => c.property_name === propertyName)[0];
    }

    const initialize = () => {
        setStatus("loading");

        const itemsWithDifferentCurrency = props.cfdisSelected.cfdis.filter(cfdi => cfdi.payment_currency && cfdi.currency !== cfdi.payment_currency);
        const showExchangeRate = itemsWithDifferentCurrency.length > 0;

        const accountsPromise = getBankAccountsPromise();
        const exchangeRatePromise = getExchangeRatePromise(showExchangeRate);
        const configurationsPromise = getTenantConfiguration(context.session!.tenant!.id);

        Promise.all([accountsPromise, exchangeRatePromise, configurationsPromise]).then(responses => {
            const accountsResponse = responses[0];
            const configurationsResponse = responses[2];
            let items = [];

            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (props.paymentValues && props.paymentValues.cfdis) {
                // obtenemos el monto configurado en base de datos
                items = getDataFromResponse(props.cfdisSelected, props.paymentValues);
                setRequest({
                    ...request,
                    payment_date: props.paymentValues.payment_date || today,
                });
            } else {
                items = props.cfdisSelected.cfdis.map((cfdi) => {
                    return { ...cfdi, amount: cfdi.total } as CfdiPayment;
                });
                setRequest({ ...request, payment_date: today });
            }

            let advancePayment = props.cfdisSelected.cfdis.find(c => c.type === "ADVANCE_PAYMENT");
            setContainAdvancePayment(advancePayment !== undefined);

            setBankAccount(accountsResponse.items.map((item) => item.id));
            setBankAccountLabels(accountsResponse.items.map((item) => item.name));

            setShowExchangeRate(showExchangeRate);
            setFromPaymentOrder(!!props.fromPaymentOrder);

            setUseExchangeRate(!!(getConfiguration(configurationsResponse, "use_exchange_rate")?.active));
            setTotal(items.reduce((sum, cfdi) => sum + cfdi.amount, 0));
            setData(items);
            if(props.paymentValues && props.paymentValues.payment_cfdi && props.paymentValues.payment_cfdi!.id){
                setPaymentCfdiId(props.paymentValues.payment_cfdi.id);
            }
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(initialize, [props]);

    const loadCfdis = () => {
        if (props.paginateCfdis) {
            setOpenBackdrop(true);
            getPayment(context.session!.tenant!.id, props.paymentValues!.id, pageSize, pageSize * page).then((response) => {
                let cfdisSelected = { cfdis: response.list_cfdis } as CfdisSelected;
                setData(getDataFromResponse(cfdisSelected, response));
            }).catch((error) => {
                setStatus(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        }
    }

    useEffect(loadCfdis, [page, pageSize]);

    const getDataFromResponse = (cfdisSelected: CfdisSelected, paymentValues: Payment) => {
        let items = cfdisSelected.cfdis.map((cfdi) => {
            let paymentCfdi = paymentValues!.cfdis.find(paymentCfdi => paymentCfdi.id === cfdi.id);
            if (paymentCfdi) {
                return {
                    ...cfdi,
                    payment_amount: paymentCfdi.payment_amount,
                    amount: paymentCfdi.amount,
                    exchange_rate: paymentCfdi.exchange_rate,
                    payment_currency: paymentValues.currency,
                } as CfdiPayment;
            }
            return {
                ...cfdi
            } as CfdiPayment;
        });
        return items;
    }

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPage(0);
        setPageSize(pageSize);
    };

    const isComboValid = (value: string) => {
        if (value !== undefined && value !== "" && value !== "---") {
            return true;
        } else {
            return false;
        }
    }

    const isInputValid = (value: string, operationType: string) => {
        if (operationType === OPERATION_TYPES[2] || (value !== undefined && value !== "")) {
            return true;
        } else {
            return false;
        }
    }

    const onConfirm = () => {
        setConfirmDialog(false);
        const cfdis = data.map(cfdi => {
            return {
                cfdi: cfdi.id,
                amount: cfdi.amount,
                provider_id: cfdi.metadata.provider_id,
                payment_amount: cfdi.payment_amount,
                payment_currency: cfdi.payment_currency ?? currency,
                currency: cfdi.currency ?? currency,
                exchange_rate: cfdi.exchange_rate,
                status: "PENDING",
                reference_exchange_rate: cfdi.reference_exchange_rate
            } as PaymentOrderCfdi;
        });

        let totalAmount;
        if (props.fromPaymentOrder) {
            totalAmount = data.map(cfdi => cfdi.payment_amount).reduce((prev, cur) => prev + cur);
        } else {
            totalAmount = props.infoProvider.amount;
        }

        const paymentRequest = {
            provider_id: props.infoProvider.provider_id,
            total_amount: totalAmount,
            currency: currency,
            payment_method: props.infoProvider.payment_method,
            cfdis: cfdis,

            bank_account_id: request.bank_account_id,
            payment_date: request.payment_date,
            operation_type: request.operation_type,
            reference: request.reference,
            comments: request.comments,
            operation_file_url: request.operation_file_url,

            payment_order_id: props.paymentOrderId,
            from_payment_order: fromPaymentOrder,
        } as PaymentRequest;
        setSubmitting(true);
        createPayment(context.session!.tenant!.id, paymentRequest).then((response) => {

            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                return;
            }
            props.next(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    const openConfirmDialog = () => {
        if (!isValid()) return;

        if (isComboValid(request.bank_account_id) && isComboValid(request.operation_type) && isInputValid(request.reference, request.operation_type) && request.payment_date !== null) {
            setConfirmDialog(true);
        } else {
            setWarning(translate("payments_cfdi.warning") as string);
        }
    };

    const cancelConfirmDialog = () => {
        setConfirmDialog(false);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        if (props.readOnly && 'operation_file_url' === name && props.paymentValues && value && value.includes('focaltec-pdp-temp')) {
            uploadOperationFile(context.session!.tenant!.id, props.paymentValues.id, { file_url: value } as PaymentOperationFileRequest).then(() => {
                setSuccess(translate("payments_cfdi.operation_file_updated") as string);
            }).catch((error) => {
                setError(error.message);
            });
        }
        setRequest({ ...request, [name]: value });

        validations[name] = inputRef;
        setValidations(validations);
    };

    const calculate = (items: CfdiPayment[], exchangeRate: number) => {
        const cfdis = items.map(item => {
            if (item.payment_amount) {
                item.payment_amount = +(item.payment_amount).toFixed(2);
                validators[item.id] = new LTEValidator(item.payment_amount);
            } else if (item.payment_currency && item.currency !== item.payment_currency) {
                item.payment_amount = +(item.amount * exchangeRate).toFixed(2);
                validators[item.id] = new LTEValidator(item.payment_amount);
            } else {
                validators[item.id] = new LTEValidator(+item.amount.toFixed(2));
            }
            return item;
        });

        setData(cfdis);
        setValidators(validators);
    };

    const hasChangedExchangeRate = (name: string, value: string | number, inputRef: InputRef) => {
        const exchangeRate = +(value || "1");
        setRequest({ ...request });

        validations[name] = inputRef;
        setValidations(validations);

        if (exchangeRate) {
            calculate(data, exchangeRate);
        }
    };

    const hasChangedAmount = (name: string, value: number, inputRef: InputRef) => {
        const n = +(value || 0);
        const items = data.map(cfdi => {
            if (cfdi.id === name) {
                cfdi.payment_amount = n;
            }
            return cfdi;
        })

        setTotal(items.reduce((sum, cfdi) => sum + cfdi.payment_amount, 0));
        setData(items);

        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setRequest({ ...request, payment_date: date })
        }
    };

    const AmountTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <NumberFormat value={value.row.amount}
                prefix="$ " suffix={` ${value.row.currency}`}
                decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                displayType="text" />;
        }} {...props} />
    );

    const PaymentAmountTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <NumberFormat value={value.row.payment_amount}
                prefix="$ " suffix={` ${value.row.payment_currency ?? value.row.currency}`}
                decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                displayType="text" />;
        }} {...props} />
    );

    const ComprobanteTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <CfdiDescription cfdi={value.row} onClickRow={onClickedRowId} onClickAdvancePayment={(id) => onClickedRowId(id, true)} />;
        }} {...props} />
    );

    const TotalsTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <CfdiAmounts cfdi={value.row} showBalance />;
        }} {...props} />
    );

    const customPlugins = [
        <AmountTypeProvider for={['amount']} editorComponent={NoFilterEditor} />,
        <PaymentAmountTypeProvider for={['payment_amount']} editorComponent={NoFilterEditor} />,
        <ComprobanteTypeProvider for={['comprobante']} editorComponent={NoFilterEditor} />,
        <TotalsTypeProvider for={['totals']} editorComponent={NoFilterEditor} />,
    ];

    const currencyColumns = ['amount', 'payment_amount'];

    const [columnsFormat] = useState([
        { columnName: 'comprobante' },
        { columnName: 'totals' },
        { columnName: 'amount' },
        { columnName: 'payment_amount' }
    ]) as any;

    const columns = [
        {
            name: 'comprobante',
            title: translate("cfdis.columns.comprobante") as string,
        },
        {
            name: 'totals',
            title: translate("cfdis.columns.totals") as string,
        },
        {
            name: 'amount',
            title: translate("payments_cfdi.amount_authorized") as string
        },
        {
            name: 'payment_amount',
            title: translate("payments_cfdi.amount_paid") as string
        }
    ];

    const onClickedRow = (cfdi: CfdiPayment) => {
        onClickedRowId(cfdi.id);
    };

    const onGenerate = () => {
        let providerMembershipTaxStampId = context.session?.provider?.membership_tax_stamp_id;
        switch (statusProviderTaxStamp) {
            case "TO_VERIFY_MEMBERSHIP":
                window.open(`/plan-tax-stamp/${providerMembershipTaxStampId}/in-review`, "_blank");
                break;
            case "PENDING_MANIFEST":
                window.open(`/tax-stamp/manifest`, "_blank");
                break;
            case "PENDING_CERTIFICATE":
                window.open(`/tax-stamp/certificate`, "_blank");
                break;
            case "ACTIVE":
                setRequest({ ...request, operation_name: translate(`payments_cfdi.operation-types.${request.operation_type}`) as string });
                if (!request.list_cfdis.find(cfdi => cfdi.cfdi && cfdi.cfdi.version === "4.0")) {
                    setWarning(translate("errors.codes.9508") as string);
                } else {
                    setOpenGeneratePaymentCfdiModal(true);
                }
                break;
            case "OUT_OF_STOCK":
                setOpenOutOfStockModal(true);
                break;
            case "EXPIRED":
                setOpenExpiredModal(true);
                break;
            case "PENDING_MEMBERSHIP":
            default:
                window.open(`/plan-tax-stamp/plans`, "_blank");
                break;
        }
    };

    const onConfirmBuyPlan = () => {
        setRedirect(`/plan-tax-stamp/plans`);
    };

    const onClickedRowId = (id: string, isAdvancePayment?: boolean) => {
        history.push(window.location.pathname);
        const origin = typeof qs["origin"] === "string" ? qs["origin"] as string : ""
        setRedirect(`/cfdis/${id}/details${isAdvancePayment
            ? `?tab=advance_payments&referer=${encodeURI(`/payment-details/${props.paymentValues!.id}`)}&origin=${encodeURI(origin)}` : ''}`);
    };

    const onSuccessGeneratePaymentCfdi = (paymentCfdiId: string) => {
        setSuccess(translate("generate_payment_cfdi.success_generate") as string);
        setOpenGeneratePaymentCfdiModal(false);
        setRequest({ ...request, status: "PAYMENT_CFDI_ATTACHED" });
        setPaymentCfdiId(paymentCfdiId);
    };

    const getBackto = () => {
        if (!!props.redirect) {
            return props.redirect;
        }
        const origin = typeof qs["origin"] === "string" ? qs["origin"] as string : ""
        return !!origin ? decodeURI(origin) : "";
    }

    const getGridPaymentCfdis = (paginateCfdis: boolean | undefined) => {
        return (<GridPaymentCfdis
            data={data}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            onClickedRow={onClickedRow}
            onClickedRowId={onClickedRowId}
            openBackdrop={openBackdrop}
            page={page}
            pageSize={pageSize}
            paginateCfdis={paginateCfdis || false}
            readOnly={props.readOnly}
            totalRows={request.count_cfdis}
            hasChangedAmount={hasChangedAmount}
            infoProvider={props.infoProvider}
            useExchangeRate={useExchangeRate}
            validators={validators}
            paymentValues={props.paymentValues}
        />);
    }

    const onClickedDetailPaymentCfdi = () => {
        history.push(window.location.pathname);
        const origin = typeof qs["origin"] === "string" ? qs["origin"] as string : ""
        setRedirect(`/cfdis/${paymentCfdiId || props.paymentValues!.payment_cfdi!.id}/details${`?referer=${encodeURI(`/payment-details/${props.paymentValues!.id}`)}&origin=${encodeURI(origin)}` }`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    if (status === "loading") {
        return (
            <Progress />
        );
    }

    if (status !== "loaded") {
        return (
            <div>{status}</div>
        );
    }


    return (<>
        <Surface title={translate("payments_cfdi.title_create_payment") as string} backButton={props.readOnly} backTo={getBackto()}
            subtitle={props.infoProvider.provider_name} icon={<PaymentIcon />} className="PaperPagination PaperTabContainer"
            titleActions={fromPaymentOrder ? undefined : (
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs>
                                <Box pr={1}>
                                    <Typography variant="h6" color="secondary">
                                        <b>{translate("payments_cfdi.total_paid") as string}</b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" color="secondary">
                                    <b><NumberFormat value={props.infoProvider.amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator="," /></b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}>

            <Grid container alignItems="flex-start" justifyContent="space-between" wrap="wrap">
                <Box p={2}>
                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <ValidatedInput type="text" id="bank_account_id" name="bank_account_id" disabled={props.readOnly}
                                value={request.bank_account_id} label={translate("payments_cfdi.input.bank_account") as string}
                                required={true}
                                margin="dense"
                                onValueChanged={hasChanged}
                                options={bankAccount} optionLabels={bankAccountLabels} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker label={translate("payments_cfdi.input.payment_date") as string}
                                name="payment_date"
                                initial={request.payment_date ? moment(request.payment_date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}
                                onChange={props.readOnly ? () => { } : onChangedDate}
                                defaultIfMissing={false} clearable={false} autoOK disablePast={false} disableFuture={false} disabled={props.readOnly} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ValidatedInput type="text" id="operation_type" name="operation_type" disabled={props.readOnly}
                                value={request.operation_type} label={translate("payments_cfdi.input.operation_type") as string}
                                required={true}
                                margin="dense"
                                onValueChanged={hasChanged}
                                options={OPERATION_TYPES} optionLabels={operationTypesLabels} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ValidatedInput type="text" id="reference" name="reference" disabled={props.readOnly}
                                value={request.reference} label={translate(`payments_cfdi.input.reference.${request.operation_type}`) as string}
                                required={request.operation_type === OPERATION_TYPES[2] ? false : true}
                                margin="dense"
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <UploaderInput
                                id="operation_file_url"
                                name="operation_file_url"
                                value={request.operation_file_url}
                                label={translate("payments_cfdi.input.operation_file_url") as string}
                                required={false}
                                disabled={disableOperationFile}
                                margin="dense"
                                acceptExtension=".pdf,.jpeg,.png"
                                onValueChanged={hasChanged}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ValidatedInput disabled={props.readOnly}
                                type="textarea"
                                id="comments"
                                name="comments"
                                value={request.comments}
                                label={translate("payments_cfdi.input.comments") as string}
                                required={false}
                                margin="dense"
                                onValueChanged={hasChanged}
                                maxRows={10}
                                rows={1}
                            />
                        </Grid>
                        {props.readOnly && request.external_id && <Grid item xs={12} sm={6}>
                            <ValidatedInput type="text" id="externalId" name="externalId"
                                value={request.external_id} label={translate(`payments_cfdi.input.external_id`) as string}
                                margin="dense" disabled />
                        </Grid>}
                        {(!showExchangeRate || !useExchangeRate) &&
                            <Grid item xs={12} sm={6}>
                                <Hidden xsUp={!showExchangeRate || !useExchangeRate} implementation="css">
                                    <ValidatedInput type="text" id="exchange_rate" name="exchange_rate"
                                        value={"1"} label={translate("payments_cfdi.input.exchange_rate") as string}
                                        required={showExchangeRate && useExchangeRate}
                                        margin="dense"
                                        disabled={props.readOnly}
                                        validator={gtZeroValidator}
                                        onValueChanged={hasChangedExchangeRate} />
                                </Hidden>
                            </Grid>
                        }

                    </Grid>
                </Box>
                <Grid item xs={12}>
                    {getGridPaymentCfdis(props.paginateCfdis)}
                </Grid>
                <Grid item xs={12}>
                    <Box pb={2}>
                        <Grid container alignItems="flex-end" justifyContent="space-between">
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                                    {props.readOnly ? undefined :
                                        <Grid item xs={7} sm={7}>
                                            <Box pb={2} pr={2}>
                                                <Grid container justifyContent="flex-end" alignContent="center" alignItems="center">
                                                    <Grid item xs="auto">
                                                        <Grid style={{
                                                            position: "relative",
                                                            height: 35,
                                                            width: 70
                                                        }}>
                                                            <span style={{ position: "absolute", top: "25%" }}>
                                                                {translate("payments_cfdi.total")}
                                                            </span>
                                                            {props.fromPaymentOrder === true || (Math.round((props.infoProvider.amount - total) * 100) === 0) ? (
                                                                <ValidIcon style={{
                                                                    "color": "#82CA9D",
                                                                    position: "absolute",
                                                                    top: "15%",
                                                                    left: "65%"
                                                                }} />
                                                            ) :
                                                                <Tooltip title={translate("payments_cfdi.tooltip_total") as string}>
                                                                    <InvalidIcon style={{
                                                                        "color": "#C33149",
                                                                        position: "absolute",
                                                                        top: "15%",
                                                                        left: "65%"
                                                                    }} />
                                                                </Tooltip>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Typography variant="body2" component="h6" align="right">
                                                            <NumberFormat value={total}
                                                                prefix="$ " suffix={` ${currency}`}
                                                                decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                                                                displayType="text" />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>}

                                    {(context.isGranted("ProviderComplementaryPaymentCreate") || (context.session?.provider?.status_membership_tax_stamp !== undefined && context.session?.provider?.status_membership_tax_stamp !== "PENDING_MEMBERSHIP")) &&
                                        !isCustomAgent && request.status !== "PAYMENT_CFDI_ATTACHED" &&
                                        <Grid item xs={3} sm={3} >
                                            <Box pb={2} pr={3}>
                                                <Grid container justifyContent="flex-end" >
                                                    <Grid item xs={12} md="auto">
                                                        <Button onClick={onGenerate} variant="contained" color={statusProviderTaxStamp === "ACTIVE" ? "primary" : "secondary"} size={"medium"} disabled={submitting}>
                                                            {translate("payments_cfdi.tax_stamp.button_generate")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                    { request.status === "PAYMENT_CFDI_ATTACHED" &&
                                        <Grid item xs={3} sm={3} >
                                            <Box pb={2} pr={3}>
                                                <Grid container justifyContent="flex-end" >
                                                    <Grid item xs={12} md="auto">
                                                        <Button onClick={onClickedDetailPaymentCfdi} variant="contained" color={"primary"} size={"medium"} disabled={submitting}>
                                                            {translate("payments_cfdi.tax_stamp.button_consult")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>


            {props.readOnly ? undefined :
                <Grid item xs={12}>
                    <Box pt={2} px={2}>
                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                            <Grid item xs="auto">
                                <Button variant="contained" color="primary" size="large" onClick={openConfirmDialog}
                                    disabled={fromPaymentOrder === false && Math.round((props.infoProvider.amount - total) * 100) !== 0} >
                                    {translate("buttons.registry")}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button variant="text" color="primary" size="large" onClick={props.onCancel}>
                                    {translate("buttons.cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            }
            {confirmDialog && (
                <ConfirmationPopup
                    title={translate("payments_cfdi.confirm.title") as string}
                    message={translate("payments_cfdi.confirm.text") as string}
                    secondary={translate("payments_cfdi.confirm.secondary_text") as string}
                    button={translate("buttons.accept") as string}
                    onClose={cancelConfirmDialog}
                    doAction={onConfirm} />
            )}
            {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} />
                : undefined}
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Surface>

        {openGeneratePaymentCfdiModal &&
            <CreatePaymentCfdiModal
                onClose={() => setOpenGeneratePaymentCfdiModal(false)}
                onSuccess={onSuccessGeneratePaymentCfdi}
                payment={request}
                onRedirectBuyPlan={() => setRedirect(`/plan-tax-stamp/plans`)}
                gridPaymentCfdis={getGridPaymentCfdis(false)}
            />
        }
        {openOutOfStockModal && (
            <ConfirmationPopup
                title={translate("payments_cfdi.tax_stamp.alert_out_of_stock.title") as string}
                message={translate("payments_cfdi.tax_stamp.alert_out_of_stock.message") as string}
                button={translate("buttons.buy") as string}
                onClose={() => { setOpenOutOfStockModal(false) }}
                doAction={onConfirmBuyPlan} />
        )}
        {openExpiredModal && (
            <ConfirmationPopup
                title={translate("payments_cfdi.confirm.title") as string}
                message={translate("payments_cfdi.tax_stamp.alert_expired.message", { "expired_date": context.session?.provider?.membership_tax_stamp_expired_date }) as string}
                button={translate("buttons.buy") as string}
                onClose={() => { setOpenExpiredModal(false) }}
                doAction={onConfirmBuyPlan} />
        )}
    </>);
}
