import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { Cfdi, Cfdis } from "../model/Cfdi";
import { CfdisQueryParams, CommentRequest } from "../model/CfdiSe";
import { CfdiHistories } from "../model/CfdiHistory";

const basePath = (tenantId: string, customerId: string) => `/tenants/${tenantId}/customers/${customerId}/cfdis`;

export async function list(tenantId: string, customerId: string, pageSize: number, offset: number, params: CfdisQueryParams): Promise<Cfdis> {
    let query = {
        identifier: params.identifier || "",
        customer: params.customer || "",
        provider: params.provider || "",
        statuses: params.statuses.join(","),
        date: params.date || "",
        from: params.from || "",
        to: params.to || "",
        pageSize: pageSize.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    return await callAPI(basePath(tenantId, customerId), {
        method: HTTPMethod.GET,
        query: query
    });
}

export async function get(tenantId: string, customerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, customerId)}/${cfdiId}`, {
        method: HTTPMethod.GET,
    });
}

export async function getHistory(tenantId: string, customerId: string, cfdiId: string, pageSize: number, offset: number): Promise<CfdiHistories> {
    return await callAPI(`${basePath(tenantId, customerId)}/${cfdiId}/history`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: pageSize.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getDocumentsUrl(tenantId: string, customerId: string, cfdiId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, customerId)}/${cfdiId}/documents`);
}

export async function reject(tenantId: string, customerId: string, cfdiId: string, request: CommentRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, customerId)}/${cfdiId}/reject`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function accept(tenantId: string, customerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, customerId)}/${cfdiId}/accept`, {
        method: HTTPMethod.PUT,
    });
}