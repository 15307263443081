import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";

import translate from "../i18n/Translator";
import { Company } from "../model/Company";
import { AppContext } from "../context/AppContext";

interface CompanyMenuProps {
    company: Company;
    tenantId: string;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function CompanyMenu(props: CompanyMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="companies-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["CompaniesUpdate", "TenantsCompaniesUpdate"]) &&
                <MenuItem button component={Link} to={`${context.isGranted("CompaniesUpdate") ? `/tenants/${props.tenantId}` : ""}/companies/${props.company.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}