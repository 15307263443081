import React, { useContext, useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Cfdi } from "../model/Cfdi";
import { ExpensesAccountRequest } from "../model/ExpensesAccount";
import { create } from "../api/ExpensesAccountAPI";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { AppContext } from "../context/AppContext";
import { Workflow } from "../model/Workflow";
import { Company } from "../model/Company";
import CfdiAsignWorkflowPopUp from "../cfdi/CfdiAsignWorkflowPopUp";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";

interface NewExpensesAccountPopupProps {
    honorarium: boolean;
    onSuccess(payableDocument: Cfdi): any;
    onCancel(): any;
}

export default function NewExpensesAccountPopup(props: NewExpensesAccountPopupProps) {
    const context = useContext(AppContext);
    const margin = "dense";
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<ExpensesAccountRequest>({
        honorarium: props.honorarium,
        date: new Date()
    } as ExpensesAccountRequest);
    const [validations, setValidations] = useState({} as any);
    const [providerWorkflows, setProviderWorkflows] = useState<Workflow[]>();
    const [providerCompanies, setProviderCompanies] = useState<Company[]>();
    const [openChangeWorkflow, setOpenChangeWorkflow] = useState<boolean>(false);
    const [cfdi, setCfdi] = useState<Cfdi>();

    const load = () => {
        getTenantCurrencies(context.session!.tenant!.id).then((resp) => {
            if (resp.tenant_currency_ids) {
                let listTemp = [] as AutocompleteProvider[];
                    resp.tenant_currency_ids.forEach((item) => {
                        let temp = { title:(item +" - "+ (translate("currency." + item) as string)), value: item  } as AutocompleteProvider;
                        listTemp.push(temp);
                        setCurrenciesAutocomplete(listTemp);
                    });
            }
        }).catch((error) => {
            setError(error.message);
        });
    };

    useEffect(load, []);

    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
        if(inputRef){
            validations["currency"] = inputRef;
            setValidations(validations);
        }
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest({ ...request, date: date });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = () => {
        onCreate("", "");
    };

    const onCreate = (workflowId : string, companyId :  string) => {
        if (!isValid()) return;

        setSubmitting(true);
        create(context.session!.tenant!.id, context.session!.provider!.id, { ...request, workflow_id: workflowId, company_id: companyId }).then((payableDocument: Cfdi) => {
            if ( !payableDocument.metadata.company_id || ((payableDocument.metadata.type !== "CREDIT_NOTE" && payableDocument.metadata.type !== "PAYMENT_CFDI") && (!payableDocument.metadata.workflow_id || payableDocument.metadata.workflow_id === "") && payableDocument.metadata.provider_workflows && payableDocument.metadata.provider_workflows.length > 1)) {
                setProviderWorkflows(payableDocument.metadata.provider_workflows);
                setProviderCompanies(payableDocument.metadata.incident_companies);
                setCfdi(payableDocument);
                setOpenChangeWorkflow(true);
                setSubmitting(false);
                return;
            } else {
                props.onSuccess(payableDocument);
            }
        }).catch(error => {
            setError(error.message);
            setSubmitting(false);
        });
    }

    const onSelectedWorkflow = (workflowId: string, companyId: string, confirmChange: boolean) => {
        if (cfdi && !cfdi.metadata.workflow_id && !workflowId) {
            setWarning(translate("workflows.cfdi_workflow.empty_selection") as string);
        }
        if (cfdi && !cfdi.metadata.company_id && !companyId) {
            setWarning(translate("companies.warning") as string);
        }
        setOpenChangeWorkflow(false);
        onCreate(workflowId, companyId);
    }

    const onCloseWorkflowSelect = () => {
        setOpenChangeWorkflow(false);
        if(cfdi && !cfdi.metadata.workflow_id){
            props.onCancel();
        }
        
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        <DialogPopup open title={translate(props.honorarium ? "payable_documents.honorarium" : "payable_documents.expenses_account")}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            maxWidth="sm"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancel}
            closeColor="default"
            button={
                <Button onClick={onSubmit} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.add")}
                </Button>
            }>
            <Grid container>
                <Grid item xs={12}>
                    <DatePicker label={translate("expenses_accounts.date") as string} name="date"
                        onChange={onChangedDate} clearable={false} disabled={submitting}
                        format="YYYY-MM-DD" initial={request.date ? moment(request.date).format("YYYY-MM-DD") : undefined} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="identifier" name="identifier" type="text"
                        label={translate("expenses_accounts.identifier") as string}
                        value={request.identifier} required disabled={submitting}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="description" name="description" type="text"
                        label={translate("expenses_accounts.description") as string}
                        value={request.description} required disabled={submitting}
                        rows={3} maxRows={3}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <TenantCurrencyAutocomplete currencies={currenciesAutocomplete} onChange={onFilterChanged} disabled={submitting}/>
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput id="url" name="url"
                        label={translate("expenses_accounts.url") as string}
                        value={request.url} required={!props.honorarium} disabled={submitting}
                        margin={margin} path="cover" acceptExtension=".pdf"
                        onValueChanged={onChanged} />
                </Grid>
            </Grid>
            { openChangeWorkflow && (providerWorkflows || providerCompanies) && cfdi &&
                            <CfdiAsignWorkflowPopUp 
                            workflows={providerWorkflows || []}
                            companies={providerCompanies || []}
                            isChangeWorkflow={false}
                            onCompleted={onSelectedWorkflow} 
                            cfdi={cfdi}
                            confirmChange={false} 
                            onClose={onCloseWorkflowSelect}/>
                        }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );
}