import T from "i18n-react";
import TextsEs from "./es.json";
import TextsEn from "./en.json";

export function initTranslator(language:string) {
    switch(language){
        case "en" : 
            T.setTexts(TextsEn);
        break;
        case "es":
            T.setTexts(TextsEs);
        break;
        default:
            T.setTexts(TextsEs);
            break;

    }
    
}

export default function translator(key: string, options?: any) {
    return T.translate(key, options);
}

export function translateParams(params: any) {
    let result = {} as any;
    if(params){
        Object.keys(params).forEach(key => {
            let translated = translator(`params.${params[key]}`) as string;
            result = {...result, [key]: translated !== `params.${params[key]}` ?  translated : params[key]}
        })
    }
    return result;
}