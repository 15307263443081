import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardHeader, Grid, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoTwoTone";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { SpecializedServicesEmployee } from "../model/CfdiSe";
import CfdiIssuesSendingInfo from "./CfdiIssuesSendingInfo";
import CfdiIssuedPayrollsTable from "./CfdiIssuedPayrollsTable";
import { AppContext } from "../context/AppContext";
import DateFormat from "../components/DateFormat";
import { Contribution } from "../model/Contribution";
import ContributionsTable from "../contributions/ContributionsTable";
import FieldsDocsTable from "../contributions/ContributionsDocsTable";

interface CfdiIssuedDocsProps {
    cfdi: Cfdi;
    uploadSipare: boolean;
}

export default function CfdiIssuedDocs(props: CfdiIssuedDocsProps) {
    const context = useContext(AppContext);
    const [showSendingInfo] = useState(context.isGrantedAny(["CfdisIssuedRead", "CustomerCfdisIssuedRead"]));
    const [contributions, setContributions] = useState<Contribution[]>();

    useEffect(() => {
        setContributions(props.cfdi.metadata.specialized_services?.contributions || []);
    }, [props.cfdi]);

    const onUpdatedContributions = (contributions: Contribution[]) => {
        setContributions(contributions);
    };

    return (
        <Box pt={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                {props.cfdi.metadata.status === "SE_REJECTED_DOCS" && props.cfdi.metadata.specialized_services?.reject_comments && (
                    <Grid item xs={12}>
                        <Card variant="outlined" color="#ff0000">
                            <CardHeader title={(
                                <Grid container justify="flex-start" alignContent="center" alignItems="center" spacing={3}>
                                    <Grid item xs="auto">
                                        <InfoIcon color="secondary" />
                                    </Grid>
                                    <Grid item xs>
                                        {props.cfdi.metadata.specialized_services.reject_comments}
                                    </Grid>
                                </Grid>
                            )} disableTypography />
                        </Card>
                    </Grid>
                )}
                {showSendingInfo && (
                    <Grid item xs={12}>
                        <CfdiIssuesSendingInfo cfdi={props.cfdi} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary" paragraph>
                        <strong>{translate("cfdis_se.service_period")}</strong>
                    </Typography>
                    <Box mb={2}>
                        <Card variant="outlined">
                            <CardHeader disableTypography title={(
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs="auto">
                                                <Typography variant="subtitle2">
                                                    {translate("contracts.from")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <DateFormat date={props.cfdi.metadata.specialized_services?.from} format="DD/MM/YYYY" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs="auto">
                                                <Typography variant="subtitle2">
                                                    {translate("contracts.to")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <DateFormat date={props.cfdi.metadata.specialized_services?.to} format="DD/MM/YYYY" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )} />
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary" paragraph>
                        <strong>{translate("cfdis_se.docs.employees")}</strong>
                    </Typography>
                    <Box mx={-2} mb={2}>
                        <CfdiIssuedPayrollsTable employees={props.cfdi.metadata.specialized_services?.employees || [] as SpecializedServicesEmployee[]} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary" paragraph>
                        <strong>{translate("contributions.title")}</strong>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mx={-2}>
                                <ContributionsTable
                                    uploadSipare={props.uploadSipare}
                                    contributions={contributions || []}
                                    onUpdated={onUpdatedContributions}
                                    status="loaded"
                                    avoidGrouping />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {props.cfdi.metadata.specialized_services &&
                 props.cfdi.metadata.specialized_services.fields && 
                 props.cfdi.metadata.specialized_services.fields.length > 0 &&
                 <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary" paragraph>
                        <strong>{translate("contributions.docs.docs_exp")}</strong>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mx={-2}>
                                <FieldsDocsTable
                                    fields={props.cfdi.metadata.specialized_services ? props.cfdi.metadata.specialized_services.fields : []}
                                    tenantId={props.cfdi.metadata.tenant_id}
                                    providerId={props.cfdi.metadata.provider_id || ""}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                }
                
            </Grid>
        </Box>
    );

}