import { callAPI, HTTPMethod } from "./API";
import { NoCfdiPayableDocumentRequest, ForeignCreditNoteRequest } from "../model/NoCfdiPayableDocument";
import { Cfdi } from "../model/Cfdi";

const getBaseUrl = (tenantId: string, providerId: string) => {
    return `/tenants/${tenantId}/providers/${providerId}/no-cfdi`;
};

export async function create(tenantId: string, request: NoCfdiPayableDocumentRequest): Promise<Cfdi> {
    return await callAPI(getBaseUrl(tenantId, request.provider_id), {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function createForeignCreditNote(tenantId: string, request: ForeignCreditNoteRequest): Promise<Cfdi> {
    return await callAPI(`${getBaseUrl(tenantId, request.provider_id)}/foreign-credit-note`, {
        method: HTTPMethod.POST,
        body: request
    });
}