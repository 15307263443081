import React, { useState, useContext, useEffect } from "react";
import { Button, IconButton, Typography, Grid, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import { TenantFieldCatalog, TenantSegmentsRequest, TenantSegment } from "../model/TenantFieldCatalog";
import { AppContext } from "../context/AppContext";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import ExpedientSegmentForm from "./ExpedientSegmentForm";
import DeleteIcon from "@material-ui/icons/Delete";

interface ExpedientSettingsSegmentsProps<T extends TenantSegment> {
    expedient?: TenantFieldCatalog;
    segments?: any;
    view: "templates" | "fields";
    supplier(expedient: TenantFieldCatalog): T[];
    name: string;
    onUpdate(tenantId: string, item: T): Promise<TenantFieldCatalog>;
    onAdd?(): any;
    onDelete?(id: string): any;
}

export default function ExpedientSettingsSegments<T extends TenantSegment>(props: ExpedientSettingsSegmentsProps<T>) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);
    const [currentSegment, setCurrentSegment] = useState<T>();
    const [currentIndex, setCurrentIndex] = useState<number>();
    const [showForm, setShowForm] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [data, setData] = useState<TenantSegmentsRequest<T>>({} as TenantSegmentsRequest<T>);

    useEffect(() => {
        if (props.view === "fields") {
            setData({
                segments: props.expedient ? props.supplier(props.expedient) : undefined
            } as TenantSegmentsRequest<T>);
        } else if (props.view === "templates" && props.segments) {
            let temp = [] as any;
            Object.keys(props.segments).sort().forEach(key => {
                temp.push(props.segments[key]);
            });
            setData({ segments: temp } as TenantSegmentsRequest<T>)
        }
    }, [props]);

    const edit = (segment: T, index: number) => () => {
        setCurrentSegment(segment);
        setCurrentIndex(index);
        setShowForm(true);
    };

    const onDelete = (fieldId: string) => () => {
        if (props.onDelete) {
            props.onDelete(fieldId);
        }
    };

    const add = () => {
        setCurrentSegment(undefined);
        setCurrentIndex(undefined);
        setShowForm(true);
    };

    const cancel = () => {
        setCurrentSegment(undefined);
        setCurrentIndex(undefined);
        setShowForm(false);
    };

    const onUpdated = (segment: T, index?: number) => {
        setSubmitting(true);
        props.onUpdate(context.session!.tenant!.id, segment)
            .then((expedient) => {
                setData({ ...data, segments: props.supplier(expedient) });
                setSubmitting(false);
                cancel();
                setSuccess(translate(`expedients.${props.name}.success`) as string);
            })
            .catch((error) => {
                setError(error.message);
                setSubmitting(false);
            });
    };

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={2}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle1">{translate(`expedients.${props.name}.title`)}</Typography>
                        </Grid>
                        {(context.isGranted("TenantFieldsUpdate") && context.isGranted("UserGroupRead"))
                            && (
                                <Grid item>
                                    <Button aria-label="add" variant="text" color="secondary" size="small" startIcon={<AddIcon />} onClick={props.view === "fields" ? add : props.onAdd}>
                                        {translate(`expedients.${props.name}.button`)}
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={data.segments ? data.segments : []}
                    loading={false}
                    empty={translate(`expedients.${props.name}.empty`) as string}
                    columns={[
                        {
                            title: translate("expedients.segments.name") as string,
                            converter: (segment) => (
                                <div>
                                    <Ellipsis text={segment.name} lenght={0} uppercased={false} />
                                    <br />
                                    <Ellipsis text={translate(segment.required ? "expedients.required" : "expedients.optional") as string} lenght={0} uppercased={false} secondary />
                                </div>
                            ),
                            xs: 9,
                            sm: 6,
                            md: 6,
                            lg: 6,
                        },
                        {
                            title: translate("expedients.segments.description") as string,
                            converter: (segment) => segment.description || "---",
                            xs: false,
                            sm: 4,
                            md: 4,
                            lg: 5,
                        },
                        {
                            title: "",
                            converter: (segment: T, index: number) => (
                                props.view === "templates" ? <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onDelete(segment.id)}
                                >
                                    <DeleteIcon fontSize="small" color="inherit" />
                                </IconButton> :

                                    context.isGranted("UserGroupRead") ?
                                        <IconButton
                                            aria-label="options"
                                            color="default"
                                            size="small"
                                            onClick={edit(segment, index)}
                                        >
                                            <EditIcon />
                                        </IconButton> : <></>
                            ),
                            justify: "flex-end",
                            xs: 3,
                            sm: 2,
                            md: 2,
                            lg: 1,
                        },
                    ]}
                />
                {showForm && (
                    <ExpedientSegmentForm
                        current={currentSegment}
                        index={currentIndex}
                        name={props.name}
                        onCompleted={onUpdated}
                        onClose={cancel}
                        disabled={submitting}
                        onError={error => setError(error)}
                    />
                )}
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            </Grid>
        </Grid>
    );
}
