import React, { useState, useContext } from "react";
import Surface from "../components/Surface";
import { Grid, Typography, IconButton } from "@material-ui/core";
import translate from "../i18n/Translator";
import { WidgetMenuResponse } from "../model/Dashboard";
import { Redirect } from "react-router-dom";
import { AppContext } from "../context/AppContext";


import {
    ExpedientIcon,
    ArrowIcon,
    MyCfdisIcon,
    NoteAddIcon,
    PaymentIcon,
    MonetizationOnIcon,
    ClarificationIcon,
} from "../components/Icons";

interface WidgetMenuProps {
    widgets: WidgetMenuResponse[];
    type: string;
}

export default function WidgetMenu(props: WidgetMenuProps) {
    const [redirect, setRedirect] = useState<string>();
    const context = useContext(AppContext);
    const rol = context.session!.role.role_parent_id ? context.session!.role.role_parent_id : context.session!.role.id;

    let widgets = props.widgets as any;
    let data = [] as object[]

    for (let key in widgets) {
        let item = widgets[key] as WidgetMenuResponse;
        let route = getRoute(item.view, rol) as WidgetMenuRoute;
        data.push({
            title: route.title,
            total: item.total,
            route: route.route
        });
    }

    const onClickedGo = (route: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if ("" !== route) {
            setRedirect(route);
        }
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    if (props.type === "CXP") {
        return (
            <Surface title={translate(`widget_menu.title.${props.type}`)} icon={<WidgetMenuIcon type={props.type} />}
                titleActions={<ButtonRight disabled={widgets.length > 0 ? false : true} />}>
                {data.map((item: any, index: number) => (
                    <Grid key={index} item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={2} md={2} lg={2} xl={2} className="widgetMenuNumber">
                                <Typography align="center" color="secondary">{item.total}</Typography>
                            </Grid>
                            <Grid item xs={9} md={9} lg={9} xl={9}>
                                <Typography variant="body2" component="h6">{translate(item.title)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Surface>
        );
    }

    return (
        <Surface title={translate(`widget_menu.title.${props.type}`)} icon={<WidgetMenuIcon type={props.type} />}>
            {data.map((item: any, index: number) => (
                <Grid key={index} item xs={12} style={{ marginBottom: "6px" }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={3} md={3} lg={3} xl={2} className="widgetMenuNumber">
                            <Typography align="center" color="secondary">{item.total}</Typography>
                        </Grid>
                        <Grid item xs={7} md={7} lg={7} xl={8}>
                            <Typography variant="body2" component="h6">{translate(item.title)}</Typography>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2} xl={2}>
                            <Grid item xs="auto" style={{ marginLeft: "10px" }}>
                                <IconButton size="small" disabled={item.total > 0 ? false : true} onClick={onClickedGo(item.total > 0 ? item.route : "")}>
                                    <ArrowIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Surface>
    );
}

interface IconProps {
    type: string;
}

export function WidgetMenuIcon(props: IconProps) {
    switch (props.type) {
        case "CFDI":
            return (<MyCfdisIcon />);
        case "PAYMENT_ORDER":
            return (<PaymentIcon />);
        case "PAYMENTS":
            return (<MonetizationOnIcon />);
        case "CXP":
            return (<NoteAddIcon />);
        case "CLARIFICATION":
            return (<ClarificationIcon />);
        case "EXPEDIENT":
            return (<ExpedientIcon />);
        default:
            return (<MyCfdisIcon />);
    }
}

export function getRoute(view: string, rol: string) {
    switch (view) {
        case "TO_AUTHORIZE":
            return {
                route: rol === "receptor_cfdi" ? "/tenant/cfdis/receptor-to-authorize" : "/tenant/cfdis/owner-to-authorize",
                title: "cfdis.to_authorize.title"
            } as WidgetMenuRoute;
        case "PENDING_VERIFICATION":
            return {
                route: "/tenant/cfdis/owner-to-verify",
                title: "cfdis.to_verify.title"
            } as WidgetMenuRoute;
        case "PENDING_PAYMENT":
            return {
                route: rol === "receptor_cfdi" ? "/tenant/cfdis/pending-payment-by-receptor" : "/tenant/cfdis/pending-payment-owner",
                title: "cfdis.pay_pending.title"
            } as WidgetMenuRoute;
        case "TO_SEND":
            return {
                route: "/provider/cfdis/to-send",
                title: "cfdis.to_send.title"
            } as WidgetMenuRoute;
        case "IN_PROGRESS":
            return {
                route: "/provider/cfdis/sent",
                title: "cfdis.sent.title"
            } as WidgetMenuRoute;
        case "PAYMENT_PENDING_CFDI":
            return {
                route: "/payment/pending-cfdi",
                title: "payment.pending_cfdi"
            } as WidgetMenuRoute;
        case "PENDING_PAYMENT_ORDERS":
            return {
                route: "/payment-order/pending",
                title: "payment_order.pending_payment_orders"
            } as WidgetMenuRoute;
        case "PAYMENT_ORDERS_IN_EXECUTION":
            return {
                route: "/payment-order/execute",
                title: "payment_order.execution_payment_orders"
            } as WidgetMenuRoute;
        case "EXPIRED_CFDIS":
            return {
                route: "/reports/cxp",
                title: "widget_menu.subtitle.expired_CFDIs"
            } as WidgetMenuRoute;
        case "CORRECT_CFDI":
            return {
                route: "/reports/cxp",
                title: "widget_menu.subtitle.correct_CFDIs"
            } as WidgetMenuRoute;
        case "MESSAGES_CLARIFICATIONS":
            return {
                route: "/clarifications",
                title: "clarifications.pending_resolve"
            } as WidgetMenuRoute;
        case "EXPEDIENT_PENDING":
            return {
                route: "/providers/expedients?classification=&page=1&search=&status_expedient=PENDING&validator_user_id=",
                title: "widget_menu.subtitle.expedient_pending"
            } as WidgetMenuRoute;
        case "EXPEDIENT_ACCEPTED":
            return {
                route: "/providers/expedients?classification=&page=1&search=&status_expedient=ACCEPTED&validator_user_id=",
                title: "widget_menu.subtitle.expedient_accepted"
            } as WidgetMenuRoute;
        default:
            return {
                route: "",
                title: ""
            } as WidgetMenuRoute;
    }
}

export interface WidgetMenuRoute {
    title: string;
    route: string;
}

interface ButtonRightProps {
    disabled: boolean;
}

export function ButtonRight(props: ButtonRightProps) {
    const [redirect, setRedirect] = useState<string>();

    const onClickedGo = (route: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if ("" !== route) {
            setRedirect(route);
        }
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    let disabledP = props.disabled as boolean;
    return (
        <Grid item xs="auto">
            <IconButton size="small" disabled={disabledP} onClick={onClickedGo("/reports/cxp")} style={{ marginTop: "7px" }}>
                <ArrowIcon />
            </IconButton>
        </Grid>
    )
}