import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar } from "../components/Snackbars";
import { authorizePayment } from "../api/BillingAPI";
import { BillingAuthorizationRequest } from "../model/Billing";

interface PaymentAuthorizationFormPopupProps {
    billingId: string;
    onClose(): any;
    onSuccess(): any;
}

export default function PaymentAuthorizationFormPopup(props: PaymentAuthorizationFormPopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<BillingAuthorizationRequest>({
        authorization: ""
    } as BillingAuthorizationRequest);
    const [error, setError] = useState<string>();

    const onSubmit = () => {
        setSubmitting(true);
        authorizePayment(props.billingId, request).then((response) => {
            props.onSuccess();
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    return (
        <DialogPopup open
            title={translate("billing.authorize_title")}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={submitting}>
                    {translate("buttons.authorize_payment")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" paragraph>
                            {translate("billing.authorize_description")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="authorization" name="authorization"
                            label={translate("billing.authorize_input") as string}
                            value={request.authorization}
                            disabled={submitting}
                            margin={"dense"}
                            onValueChanged={hasChanged} />
                    </Grid>
                </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}

