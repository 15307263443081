import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { DigitalDocuments, DigitalDocument, DigitalDocumentsQueryParams, DigitalDocumentRequest, ExportDocumentsRequest } from "../model/DigitalDocument";

const basePath = (tenantId: string) => `/tenants/${tenantId}/digital-documents`;

export async function listDigitalDocuments(tenantId: string, page: number, offset: number, params: DigitalDocumentsQueryParams): Promise<DigitalDocuments> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createDigitalDocument(tenantId: string, request: DigitalDocumentRequest): Promise<DigitalDocument> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getDigitalDocument(tenantId: string, id: string): Promise<DigitalDocument> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateDigitalDocument(tenantId: string, id: string, request: DigitalDocumentRequest): Promise<DigitalDocument> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteDigitalDocument(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getFileUrl(tenantId: string, id: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId)}/${id}/file`);
}

export async function exportDocumentsSelected(tenantId: string, request: ExportDocumentsRequest): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/export-selected`, {
        method: HTTPMethod.PUT,
        body: request
    });
}