import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Grid, Box, ListItemText, Typography, ListItem, Divider, Button, MenuItem } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { formatDate } from "../components/DateFormat";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { ErrorSnackbar } from "../components/Snackbars";
import { DataTypeProviderProps, DataTypeProvider, SummaryState, SortingState, IntegratedSorting, GroupingState, IntegratedGrouping, IntegratedSummary, RowDetailState } from "@devexpress/dx-react-grid";
import { Redirect } from "react-router-dom";
import { TableRowDetail, Grid as GridDx, Table, TableHeaderRow, TableGroupRow, TableSummaryRow } from "@devexpress/dx-react-grid-material-ui";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import ConfirmationPopup from "../components/ConfirmationPopup";
import ProjectDocuments from "./ProjectDocuments";

import { isRoleOrParent } from "../model/Role";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { Cfdi } from "../model/Cfdi";
import { Project } from "../model/Project";
import BudgetRangeBar from "./BudgetRangeBar";
import CfdiPurchaseOrderMenu from "../cfdis/menus/CfdiDownloadPdf";
import { ExtendedFieldsTemplate, ExtendedFieldsTemplateSegment } from "../model/ExtendedFieldsTemplate";
import { TenantFieldCatalog } from "../model/TenantFieldCatalog";
import { finalize } from "../api/ProjectAPI";

export interface DetailsTabParams {
    tenantId: string;
    project: Project;
    tenantFieldCatalog?: TenantFieldCatalog;
    extendedFieldsTemplate?: ExtendedFieldsTemplate;
    isReaderOnly: boolean;
    onSetProject(project: Project): void;
}

export default function DetailsTab(props: DetailsTabParams) {
    const context = useContext(AppContext);
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>();
    const [openFinalize, setOpenFinalize] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [finalizeMessage, setFinalizeMessage] = useState<string>();
    const [redirect, setRedirect] = useState<string>();
    const qs = queryString.parse(window.location.search);
    const [status, setStatus] = useState<string>("loading");
    const isOwner = isRoleOrParent(context.session!.role, "owner");
    const isOwnerProject = context.session!.user.id === props.project.issuer_id;
    const history = useHistory();

    const tableColumnExtensions = [
        { columnName: 'hidden', width: 0, hidden: true },
        { columnName: 'date', width: 180 },
        { columnName: 'external_id', align: 'center', width: 130 },
        { columnName: 'total', align: 'right', width: 150 },
        { columnName: 'accrued', align: 'right', width: 130 },
        { columnName: 'to_acrue', align: 'right', width: 130 },
        { columnName: 'no_cfdis', align: 'center', width: 130 },
        { columnName: 'status', width: 130 },
    ] as any;

    const grouping = [{ columnName: 'hidden' }];

    const load = () => {
        setStatus("loading");
        setPurchaseOrders(props.project.purchase_orders);
        setStatus("loaded");
    };

    useEffect(load, [props]);

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const onClickedRowPurchaseOrder = (pucharseOrder: PurchaseOrder) => {
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/purchase-orders/${pucharseOrder.id}`);
    };

    const onClickedRowCfdi = (cfdi: Cfdi) => {
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/cfdis/${cfdi.id}/details`);
    };

    const onConfirmFinalize = () => {
        setFinalizeMessage("");
        setOpenFinalize(false);
        onFinalizeProject(props.project.id, translate(`requisitions.projects.finalize_pop_up.finalize_message`) as string);
    };

    const onFinalizeProject = (projectId: string, message?: string) => {
        finalize(context.session!.tenant!.id, projectId).then((response) => {
            setRedirect(`/projects/in_execution/success/${message}?` + queryString.stringify(qs));
        }).catch((error) => {
            setError(error.message);
        });
    }

    const validateProjectTemplateFields = () => {
        if (!props.project.template) {
            return true;
        } else {
            let projectTemplateFields = props.project.template.fields;
            let extendedFields = props.extendedFieldsTemplate?.fields_response ?? [];
            let extendedFieldsTemplateSegment = [] as ExtendedFieldsTemplateSegment[];

            for (var key in extendedFields) {
                extendedFieldsTemplateSegment.push({
                    id: extendedFields[key].id,
                    priority: Number(key)
                } as ExtendedFieldsTemplateSegment)
            }

            let errors: string[] = [];
            extendedFieldsTemplateSegment.forEach(fieldTemplate => {
                const tenantField = props.tenantFieldCatalog?.fields?.find(v => v.id === fieldTemplate.id);
                const currentField = projectTemplateFields.find(v => v.field.id === fieldTemplate.id);
                if (tenantField?.required) {
                    if (currentField) {
                        if (!currentField.value) {
                            errors.push(tenantField.name as string);
                        }
                    } else {
                        errors.push(tenantField.name as string);
                    }
                }
            });

            if (errors.length > 0) {
                setError(translate("requisitions.projects.start_project.reject_start_project", { "fields": errors.join(", ") }) as string);
                return false;
            } else {
                return true;
            }
        }
    };

    const onOpenFinalize = () => {
        if(!validateProjectTemplateFields()){
            return;
        }
        setOpenFinalize(true);
        let message = "";
        if (!props.project.purchase_order_ids) {
            message = translate(`requisitions.projects.finalize_pop_up.message_without_purchase_orders`) as string;
        } else if (props.project.assigned_budget && props.project.accrued_budget && props.project.current_budget === props.project.assigned_budget && (props.project.current_budget === props.project.accrued_budget || props.project.budget_to_assign === 0)) {
            message = translate(`requisitions.projects.finalize_pop_up.message_accrued_budget`, { "name": props.project.name }) as string;
        } else {
            let amount = Intl.NumberFormat().format(props.project.budget_to_assign !== undefined ? props.project.budget_to_assign : props.project.current_budget);
            message = translate(`requisitions.projects.finalize_pop_up.message_pending_assign_budget`, { "pending": amount }) as string;
        }
        setFinalizeMessage(message);
    };

    const getStatus = (purchaseOrder: PurchaseOrder) => {
        return translate(`purchase_orders.status.${purchaseOrder.status}`) as string;
    };

    const getHidden = (purchaseOrder: PurchaseOrder) => {
        return "   " as string;
    };

    const getAccruedAmount = (purchaseOrder: PurchaseOrder) => {
        let sumCfdis = 0;
        if (purchaseOrder.cfdis) {
            purchaseOrder.cfdis.forEach((item) => {
                sumCfdis = sumCfdis + item.metadata.subtotal_base;
            });
        }
        return sumCfdis as number;
    };

    const getAmountToAccrue = (purchaseOrder: PurchaseOrder) => {
        let totalAmount = purchaseOrder.total;
        let sumCfdis = 0;
        if (purchaseOrder.cfdis) {
            purchaseOrder.cfdis.forEach((item) => {
                sumCfdis = sumCfdis + item.metadata.subtotal_base;
            });
        }

        return (totalAmount - sumCfdis) as number;
    };

    const getNoCfdis = (purchaseOrder: PurchaseOrder) => {
        return purchaseOrder.cfdis ? purchaseOrder.cfdis.length : 0;
    };

    const getDate = (purchaseOrder: PurchaseOrder) => {
        return (<DateFormat date={purchaseOrder.date} format="DD-MM-YYYY h:mm a" zone={true} />);
    }

    const getPurchaseId = (purchaseOrder: PurchaseOrder) => {
        return (<MenuItem button onClick={() => onClickedRowPurchaseOrder(purchaseOrder)} dense>
            <Typography variant="body2" color={"primary"}>
                {purchaseOrder.external_id}
            </Typography>
        </MenuItem>);
    }

    const columns = [
        {
            name: 'date',
            title: translate("requisitions.projects.columns.date") as string,
            getCellValue: (row: any) => getDate(row)
        },
        {
            name: 'external_id',
            title: translate("requisitions.projects.columns.order_id") as string,
            getCellValue: (row: any) => getPurchaseId(row)
        },
        {
            name: 'total',
            title: translate("requisitions.projects.columns.assigned_amount") as string,
        },
        {
            name: 'accrued',
            title: translate("requisitions.projects.columns.accrued") as string,
            getCellValue: (row: any) => getAccruedAmount(row)
        },
        {
            name: 'to_acrue',
            title: translate("requisitions.projects.columns.to_accrue") as string,
            getCellValue: (row: any) => getAmountToAccrue(row)
        },
        {
            name: 'no_cfdis',
            title: translate("requisitions.projects.columns.no_cfdis") as string,
            getCellValue: (row: any) => getNoCfdis(row)
        },
        {
            name: 'status',
            title: translate("requisitions.projects.columns.status") as string,
            getCellValue: (row: any) => getStatus(row)
        },
        {
            name: 'hidden',
            title: translate("purchase_orders.title") as string,
            getCellValue: (row: any) => getHidden(row)
        }
    ];

    const currencyColumns = ['total', 'accrued', 'to_acrue'];

    const groupSummaryItems = [
        { columnName: 'no_cfdis', type: 'sum' },
        { columnName: 'total', type: 'sum', showInGroupFooter: true, alignByColumn: true },
        { columnName: 'accrued', type: 'sum', showInGroupFooter: true, alignByColumn: true },
        { columnName: 'to_acrue', type: 'sum', showInGroupFooter: true, alignByColumn: true },
    ];

    const CurrencyTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => (
            <NumberFormat value={value.value} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
        )} {...props} />
    );

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
        params.row.cfdis ?
            <Box pl={5} px={5}>
                <Grid item xs={12} direction="row">
                    <ListItem>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography variant="body2">
                                    <strong>{translate("requisitions.projects.cfdis_purchase_order")}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>

                </Grid>
                <Gridable
                    items={params.row.cfdis ? params.row.cfdis as Cfdi[] : []}
                    loading={status === "loading"}
                    error={status !== "loading" && status !== "loaded" ? status : undefined}
                    empty={translate("cfdis.history.empty") as string}
                    columns={[
                        {
                            title: translate("cfdis.columns.date") as string,
                            converter: (cfdi) => (<DateFormat date={cfdi.date} format="DD-MM-YYYY h:mm a" zone={true} />),
                            xs: 3,
                            sm: 3,
                            md: 3,
                            xl: 3
                        },
                        {
                            title: translate("cfdis.columns.serie_folio") as string,
                            converter: (cfdi) => (
                                <MenuItem button onClick={() => onClickedRowCfdi(cfdi)} dense>
                                    <Typography variant="body2" color={"primary"}>
                                        {cfdi.identifier}
                                    </Typography>
                                </MenuItem>
                            ),
                            xs: 3,
                            sm: 3,
                            md: 3,
                            xl: 3
                        },
                        {
                            title: translate("cfdis.columns.amount_single") as string,
                            converter: (cfdi) => (<NumberFormat value={cfdi.metadata.subtotal_base} prefix="$" decimalScale={2} displayType="text" />
                            ),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            xl: 2
                        },
                        {
                            title: translate("cfdis.columns.status") as string,
                            converter: (cfdi) => (
                                <Ellipsis
                                    lenght={100} uppercased={false}
                                    text={cfdi.metadata.status ? translate("cfdis.history.status." + cfdi.metadata.status) as string : ""}
                                />),
                            xs: 3,
                            sm: 3,
                            md: 2,
                            xl: 2
                        },
                        {
                            title: translate("requisitions.projects.archive") as string,
                            converter: (cfdi) => (
                                <Grid item xs="auto">
                                    <CfdiPurchaseOrderMenu cfdi={cfdi} />
                                </Grid>
                            ),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            xl: 2
                        },
                    ]} />
            </Box>
            : <></>
    );

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <div>
            <Grid>
                <Box px={2} py={2}>
                    <Grid container justify="space-between" alignItems="center" >
                        <Grid item xs={12}>
                            <Grid container alignItems="center" direction="row" spacing={3}>
                                <Grid item xs={4}>
                                    <ListItemText primary={formatDate(props.project.created_at, "ll")} secondary={translate("requisitions.projects.columns.created_at")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={translate("requisitions.projects.types." + props.project.type)} secondary={translate("requisitions.projects.columns.project_type")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={props.project.customer ? props.project.customer : translate("requisitions.projects.columns.undefined")} secondary={translate("requisitions.projects.columns.customer")} />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" direction="row" spacing={3}>
                                <Grid item xs={4}>
                                    <ListItemText primary={formatDate(props.project.updated_at, "ll")} secondary={translate("requisitions.projects.columns.update_date")} />
                                </Grid>
                                <Grid item xs={4}>

                                    <ListItemText primary={
                                        <NumberFormat color="blue" value={props.project.initial_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    } secondary={translate("requisitions.projects.columns.original_budget")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={props.project.ended_at ? formatDate(props.project.ended_at, "ll") : "---"} secondary={translate("requisitions.projects.columns.ended_date")} />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
                <Grid container xs={12} style={{ backgroundColor: "#f5f5f5", borderTop: "1px solid  rgba(0, 0, 0, 0.12)" }}>
                    <Grid item xs={12} direction="row">
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="body2">
                                        <strong>{translate("requisitions.projects.details.current_situation")}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>

                    </Grid>
                    <Grid item xs={12}>
                        <BudgetRangeBar project={props.project} fromDashboard={false} />
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container alignItems="center" justify="flex-end" direction="row" spacing={1}>
                            <Grid item xs={2}>
                                <ListItemText primary={
                                    <NumberFormat color="blue" value={props.project.current_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                } secondary={translate("requisitions.projects.columns.current_budget")} />
                            </Grid>
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <Grid item xs={2}>
                                <ListItemText primary={
                                    <NumberFormat color="blue" value={props.project.budget_to_assign || props.project.current_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                } secondary={translate("requisitions.projects.columns.to_assign")} />
                            </Grid>
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <Grid item xs={2}>
                                <ListItemText primary={
                                    <NumberFormat color="blue" value={props.project.assigned_budget || 0} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                } secondary={translate("requisitions.projects.columns.assigned_amount")} />
                            </Grid>
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <Grid item xs={2}>
                                <ListItemText primary={
                                    <NumberFormat color="blue" value={props.project.accrued_budget || 0} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                } secondary={translate("requisitions.projects.columns.accrued")} />
                            </Grid>
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <Grid item xs={2}>
                                <ListItemText primary={
                                    <NumberFormat color="blue" value={props.project.budget_to_accrue !== undefined ? props.project.budget_to_accrue : props.project.assigned_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                } secondary={translate("requisitions.projects.columns.to_accrue")} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center" >
                    <ProjectDocuments
                        project={props.project}
                        onSetProject={props.onSetProject}
                        onChangeTemplate={() => { }}
                    />
                </Grid>
                <Grid style={{ marginTop: "3%", borderTop: "1px solid  rgba(0, 0, 0, 0.12)" }}>
                    <Grid item xs={12} direction="row">
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="body2">
                                        <strong>{translate("requisitions.projects.details.purchase_order.title")}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>

                    </Grid>
                    <GridDx
                        rows={purchaseOrders || []}
                        columns={columns} >
                        <CurrencyTypeProvider for={currencyColumns} />
                        <SortingState />
                        <IntegratedSorting />
                        <GroupingState grouping={grouping} />
                        <SummaryState groupItems={groupSummaryItems} />
                        <IntegratedGrouping />
                        <IntegratedSummary />
                        <RowDetailState />
                        <Table columnExtensions={tableColumnExtensions} messages={{ noData: translate("reports.empty") as string }} />
                        <TableHeaderRow showSortingControls messages={{ sortingHint: translate('buttons.sort') as string }} />
                        <TableRowDetail contentComponent={rowDetailComponent} />
                        <TableGroupRow showColumnsWhenGrouped messages={{ sum: translate("total") as string }} />
                        <TableSummaryRow messages={{ sum: translate("total") as string }} />
                    </GridDx>
                </Grid>
                {(isOwnerProject || isOwner) && props.project.status !== "FINALIZED" && <Grid item xs={12}>
                    <Box pt={4} pr={2} pl={2}>
                        <Grid container justify="space-between" spacing={1} >
                            <Grid item xs={12} md="auto">
                                <Button variant="outlined" color="primary" size="large" onClick={onOpenFinalize}>
                                    {translate("requisitions.projects.finalize_pop_up.title")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                }
                {openFinalize && finalizeMessage && (isOwnerProject || isOwner) && (
                    <ConfirmationPopup title={translate("requisitions.projects.finalize_pop_up.title") as string}
                        message={finalizeMessage}
                        button={translate("buttons.accept") as string}
                        onClose={() => setOpenFinalize(false)}
                        doAction={onConfirmFinalize} />
                )}
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            </Grid>
        </div>)
}