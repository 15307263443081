import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, Box } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Progress from "../components/Progress";

import { BankAccount, fieldsBankAccount, fieldsBankAccountForeign } from "../model/BankAccount";
import { CONTRIES } from "../catalogs/Countries";
import UploaderInput from "../components/UploaderInput";
import { IntegerValidator, CLABEValidator } from "../components/Validators";
import Alert from "../components/Alert";
import { getBanks } from "../api/BankCatalogApi";
import { getBankAccountCoverUrl } from "../api/ProviderExpedientAPI";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { MultiselectValue } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { list as listBankAccountTypes } from "../api/TenantBankAccountTypeAPI";
import { list as listSpidRelationTypes } from "../api/SpidRelationTypeAPI";
import { SpidRelationType, SpidRelationTypesParams } from "../model/SpidRelationType";
import { TenantBankAccountType } from "../model/TenantBankAccountType";

const TEXT_ONLY_NUMBER_VALIDATOR = new IntegerValidator(true);
const CLABE_VALIDATOR = new CLABEValidator();

interface ExpedientProviderBankAccountFormProps {
    title: string;
    subtitle?: string;
    bankAccount?: BankAccount;
    comments?: string;
    onCompleted(bankAccount: BankAccount): any;
    onClose(): any;
    disabled: boolean;
    id: string;
    tenantId: string;
    providerId: string;
}

export default function ExpedientProviderBankAccountForm(props: ExpedientProviderBankAccountFormProps) {
    const context = useContext(AppContext);
    const isEdit = props.bankAccount && Object.keys(props.bankAccount!).length > 0;
    const margin = "dense";
    const countryLabels = CONTRIES.map((code) => translate(`countries.${code}`) as string);
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<MultiselectValue[]>([]);
    const [status, setStatus] = useState<string>("loading");
    const [request, setRequest] = useState<BankAccount>({
        owner: props.bankAccount?.owner || "",
        bank: props.bankAccount?.bank || "",
        bank_name: props.bankAccount?.bank_name || "",
        subsidiary: props.bankAccount?.subsidiary || "",
        subsidiary_number: props.bankAccount?.subsidiary_number || "",
        account: props.bankAccount?.account || "",
        clabe: props.bankAccount?.clabe || "",
        currency: props.bankAccount?.currency || "",
        cover: props.bankAccount?.cover || "",
        code: props.bankAccount?.code || "",
        city: props.bankAccount?.city || "",
        state: props.bankAccount?.state || "",
        country: props.bankAccount?.country || "",
        bank_account_type_id: props.bankAccount?.bank_account_type_id || "",
        bank_account_type_external_id: props.bankAccount?.bank_account_type_external_id || "",
        cie_agreement: props.bankAccount?.cie_agreement || "",
        aba_code: props.bankAccount?.aba_code || "",
        spid_relation_type_id: props.bankAccount?.spid_relation_type_id || "",
        spid_relation_type_external_id: props.bankAccount?.spid_relation_type_external_id || "",
        reference: props.bankAccount?.reference || ""
    } as BankAccount);
    const [bankLabels, setBankLabels] = useState<string[]>([]);
    const [bankIds, setBankIds] = useState<string[]>([]);

    const [bankAccountTypes, setBankAccountTypes] = useState<TenantBankAccountType[]>([]);
    const [bankAccountTypesLabels, setBankAccountTypesLabels] = useState<string[]>([]);
    const [bankAccountTypesIds, setBankAccountTypesIds] = useState<string[]>([]);
    const [bankBbvaId, setBankBbvaId] = useState<string>("");
    const [bankAccountTypeSelected, setBankAccountTypeSelected] = useState<TenantBankAccountType>({} as TenantBankAccountType);

    const [spidRelationTypes, setSpidRelationTypes] = useState<SpidRelationType[]>([]);
    const [spidRelationTypesLabels, setSpidRelationTypesLabels] = useState<string[]>([]);
    const [spidRelationTypesIds, setSpidRelationTypesIds] = useState<string[]>([]);

    const [requiredFieldNames, setRequiredFieldNames] = useState<string[]>([]);
    const [visibleFieldNames, setVisibleFieldNames] = useState<string[]>([]);

    const isNotProviderForeign = context.session!.provider ? context.session!.provider.type !== "FOREIGN" : true;
    const [url, setUrl] = useState<string>();
    const [isForeign, setIsForeign] = useState<boolean>(!!props.bankAccount && "MEX" !== props.bankAccount.country);
    const [isCurrencyDisabled, setIsCurrencyDisabled] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    const [validations, setValidations] = useState({} as any);

    useEffect(() => {
        setStatus("loading");
        let current = props.bankAccount?.cover;
        if (current) {
            if (current.startsWith("http")) {
                setUrl(current);
            } else {
                getBankAccountCoverUrl(props.tenantId, props.providerId, props.id).then((url) => {
                    setUrl(url);
                }).catch((error) => {
                    setUrl(current);
                });
            }
        }
        Promise.all([
            getBanks(""),
            getTenantCurrencies(props.tenantId),
            listBankAccountTypes(props.tenantId),
            listSpidRelationTypes(props.tenantId, { search: "" } as SpidRelationTypesParams, 0, 0)
        ]).then(responses => {
            const banksResponse = responses[0];
            setBankLabels(banksResponse.items.map((bank) => bank.name));
            setBankIds(banksResponse.items.map((bank) => bank.id));
            let bbvaBank = banksResponse.items.find((bank) => bank.name.toUpperCase().includes("BBVA") || bank.name.toLowerCase().includes("bancomer"));
            setBankBbvaId(bbvaBank ? bbvaBank.id : "");
            const respTC = responses[1];
            if (respTC.tenant_currency_ids) {
                let listTemp = [] as MultiselectValue[];
                if (respTC.tenant_currency_ids) {
                    respTC.tenant_currency_ids.forEach((item) => {
                        let temp = { title: (item + " - " + (translate("currency." + item) as string)), value: item } as MultiselectValue;
                        listTemp.push(temp);
                    });
                    setCurrenciesAutocomplete(listTemp);
                }
            }
            const respBAT = responses[2];
            if (respBAT.total > 0) {
                setBankAccountTypes(respBAT.items);
                setBankAccountTypesIds(getBankAccountTypeIds("MEX" !== request.country));
                setBankAccountTypesLabels(getBankAccountTypeLabels("MEX" !== request.country));
                setRequiredFieldNames(getRequiredFields(respBAT.items, request.bank_account_type_id));
                setVisibleFieldNames(getVisibleFields(respBAT.items, request.bank_account_type_id));
                setCurrency(respBAT.items, request.country);
            }

            const respSPID = responses[3];
            if (respSPID.total > 0) {
                setSpidRelationTypes(respSPID.items);
                setSpidRelationTypesLabels(respSPID.items.map(c => c.name));
                setSpidRelationTypesIds(respSPID.items.map(c => c.id));
            }

        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }, [props.bankAccount, props.id, props.providerId, props.tenantId]);

    const setCurrency = (bankAccountTypes: TenantBankAccountType[], country: string) => {
        let bankAccountType = bankAccountTypes.find(c => c.id === request.bank_account_type_id);
        if (bankAccountType && "MEX" === country && (bankAccountType.external_id === "SPEI")) {
            setIsCurrencyDisabled(true);
            request.currency = "MXN";
        } else if (bankAccountType && "USA" === country && bankAccountType.external_id === "SPID") {
            setIsCurrencyDisabled(true);
            request.currency = "USD";
        }
    }

    const getRequiredFields = (bankAccountTypeItems: TenantBankAccountType[], bankAccountTypeId: string) => {
        let bankAccountType = bankAccountTypeItems.find(c => c.id === bankAccountTypeId);
        let bankAccountExternalId = bankAccountType?.external_id;

        let requiredFields = [] as string[];

        if (!isForeign) {
            fieldsBankAccount.forEach(field => {
                if (bankAccountExternalId && field.requiredOn.includes(bankAccountExternalId)) {
                    requiredFields.push(field.name);
                }
            });
        } else {
            fieldsBankAccountForeign.forEach(field => {
                if (bankAccountExternalId && field.requiredOn.includes(bankAccountExternalId)) {
                    requiredFields.push(field.name);
                }
            });
        }
        return requiredFields;
    }

    const getVisibleFields = (bankAccountTypeItems: TenantBankAccountType[], bankAccountTypeId: string) => {
        let bankAccountType = bankAccountTypeItems.find(c => c.id === bankAccountTypeId);
        let bankAccountExternalId = bankAccountType?.external_id;

        let requiredFields = [] as string[];

        if (!isForeign) {
            fieldsBankAccount.forEach(field => {
                if (bankAccountExternalId && field.visibleOn.includes(bankAccountExternalId)) {
                    requiredFields.push(field.name);
                }
            });
        } else {
            fieldsBankAccountForeign.forEach(field => {
                if (bankAccountExternalId && field.visibleOn.includes(bankAccountExternalId)) {
                    requiredFields.push(field.name);
                }
            });
        }
        return requiredFields;
    }

    const isValid = (validations: any) => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            let isBankInvalid = (field === "bank" && (request.bank === "---" || request.bank == "") && requiredFieldNames.includes("bank"));
            let isSpidTypeInvalid = (field === "spid_relation_type_id" && request.spid_relation_type_id === "---" && requiredFieldNames.includes("spid_relation_type_id"));
            if (!ref.valid || isBankInvalid || isSpidTypeInvalid) {
                ref.blurer(true);
                valid = false;
                if(isBankInvalid){
                    setWarning(translate("bank_account.warning_bank") as string);
                }
                if(isSpidTypeInvalid){
                    setWarning(translate("bank_account.warning_spid") as string);
                }
            }
        }
        return valid;
    };

    const isValidCode = () => {
        if (!request.code) {
            setError(translate("bank_account.missing_swift_code") as string);
            return false;
        }

        let code = request.code.trim();
        let regExp = new RegExp('^[A-Za-z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$');
        if (regExp.test(code)) {
            return true;
        }

        setError(translate("bank_account.invalid_swift_code") as string);
        return false;
    }
    const clearBankOnCountryChange = (country : string) => {
        setRequest((request) => {
            return { ...request, ["bank"]: "MEX" !== country ? "" : "---" };
        });
    }

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest((request) => {
            return { ...request, [name]: value };
        });
        if ("country" === name && value) {
            setBankAccountTypesIds(getBankAccountTypeIds("MEX" !== value));
            setBankAccountTypesLabels(getBankAccountTypeLabels("MEX" !== value));
            setIsForeign("MEX" !== value);
            clearBankOnCountryChange(value);
        }
        if ("bank_account_type_id" === name && value) {
            let bankType = bankAccountTypes.find(c => c.id === value);
            if (bankType) {
                setBankAccountTypeSelected(bankType);
                if ("CIE" === bankType.external_id) {
                    setRequest((request) => {
                        return { ...request, "bank": bankBbvaId };
                    });
                }
                if("CLABE" === bankType.external_id){
                    setRequest((request) => {
                        return { ...request, currency: "MXN" };
                    });
                }
                if("SPID" === bankType.external_id){
                    setRequest((request) => {
                        return { ...request, currency: "USD" };
                    });
                }
                if ("CIE" !== bankType.external_id) {
                    setRequest((request) => {
                        return { ...request, "bank": "---" };
                    });
                }
            }
            setRequiredFieldNames(getRequiredFields(bankAccountTypes, value));
            setVisibleFieldNames(getVisibleFields(bankAccountTypes, value));
        }
        if ("country" === name && value) {
            setCurrency(bankAccountTypes, value);
        }
        validations[name] = inputRef;
        setValidations(validations);
    };

    const resetValues = (type: string) => {
        let clearRequest = { ...request } as BankAccount;
        if(type !== "FULL"){
            clearRequest = { ...clearRequest, owner: "", bank_name: "", city: "", state: "" } as BankAccount;
        }
        if(type !== "SPID"){
            clearRequest = { ...clearRequest, spid_relation_type_id: "", spid_relation_type_external_id: "" } as BankAccount;
        }
        if(type !== "ABA"){
            clearRequest = { ...clearRequest, aba_code: "" } as BankAccount;
        }
        if(type !== "CIE"){
            clearRequest = { ...clearRequest, cie_agreement: "" } as BankAccount;
        }
        if((type !== "FULL" && type !== "CLABE") || (type === "FULL" && isForeign)){
            clearRequest = { ...clearRequest, subsidiary: "", subsidiary_number: "" } as BankAccount;
        }
        if(type === "SWIFT" || type === "ABA"){
            clearRequest = { ...clearRequest, bank: "" } as BankAccount;
        }
        if(type === "CIE"){
            clearRequest = { ...clearRequest, account: "", code: "" } as BankAccount;
        }
        if((type === "FULL" && !isForeign) || type === "ABA" || type === "SPID" || type === "CLABE"){
            clearRequest = { ...clearRequest, code: "" } as BankAccount;
        }
        if((type === "FULL" && isForeign) || type === "CIE" || type === "SWIFT" || type === "ABA"){
            clearRequest = { ...clearRequest, clabe: "" } as BankAccount;
        }

        return clearRequest;
    }

    const onCurrencyChanged = (name: string, value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let tempRequest = resetValues(bankAccountTypes.find(c => c.id === request.bank_account_type_id)!.external_id!);
        let validationsOnSubmit = { ...validations } as any;
        
        if (tempRequest.spid_relation_type_id) {
            let spidType = spidRelationTypes.find(c => c.id === tempRequest.spid_relation_type_id)
            tempRequest.spid_relation_type_external_id = spidType ? spidType.external_id : "";
        }
        if (tempRequest.bank_account_type_id) {
            let bankAccountType = bankAccountTypes.find(c => c.id === tempRequest.bank_account_type_id)
            tempRequest.bank_account_type_external_id = bankAccountType ? bankAccountType.external_id : "";
        }
        if (isForeign) {
            if (tempRequest.code && tempRequest.code !== "" && !isValidCode()) {
                return;
            }
            delete validationsOnSubmit[`subsidiary`];
            delete validationsOnSubmit[`subsidiary_number`];
            delete validationsOnSubmit[`clabe`];
            delete validationsOnSubmit[`cover`];
            tempRequest = {
                ...tempRequest,
                foreign: true,
                subsidiary: "",
                subsidiary_number: "",
                clabe: ""
            } as BankAccount;
        } else {
            delete validationsOnSubmit[`code`];
            delete validationsOnSubmit[`city`];
            delete validationsOnSubmit[`state`];
            tempRequest = {
                ...tempRequest,
                foreign: false,
                code: "",
                city: "",
                state: ""
            } as BankAccount;
        }

        if (!tempRequest.currency) {
            setWarning(translate("validations.currency_required") as string);
            return;
        }

        if (!isValid(validationsOnSubmit)) {
            return;
        }

        props.onCompleted(tempRequest);
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    const getBankAccountTypeIds = (foreign: boolean) => {
        let bankAccountTypesFiltered = getBankAccountTypes(foreign);
        return bankAccountTypesFiltered.map(c => c.id);
    }

    const getBankAccountTypeLabels = (foreign: boolean) => {
        let bankAccountTypesFiltered = getBankAccountTypes(foreign);
        return bankAccountTypesFiltered.map(c => c.type_account);
    }

    const getBankAccountTypes = (foreign: boolean) => {
        let accountTypesForeign = ["SWIFT", "ABA"];
        if (!foreign) {
            return bankAccountTypes.filter(c => !accountTypesForeign.includes(c.external_id));
        }
        return bankAccountTypes.filter(c => accountTypesForeign.includes(c.external_id) || "FULL" === c.external_id);
    }

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick={props.disabled}
            disableEscapeKeyDown={props.disabled}
            onClose={props.onClose}
            aria-labelledby="address-form-dialog-title"
            aria-describedby="address-form-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="address-form-dialog-title" disableTypography style={{ "lineHeight": "1px" }}>
                    <Typography variant="subtitle1">{props.title}</Typography>
                    <br />
                    <Typography variant="caption">{props.subtitle || translate("bank_account.title") as string}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {props.comments && (
                        <Alert variant="warning" title={props.comments} />
                    )}
                    {status !== "loading" && status !== "loaded" && (
                        status
                    )}
                    {status === "loading" && (
                        <Progress />
                    )}
                    {status === "loaded" && (
                        <Grid item xs={12}>
                            {visibleFieldNames.includes("owner") &&
                                <ValidatedInput
                                    type="text"
                                    id="owner"
                                    name="owner"
                                    value={request.owner}
                                    label={translate("bank_account.owner") as string}
                                    required={requiredFieldNames.includes("owner")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            <CountryInput
                                country={request.country}
                                countryLabels={countryLabels}
                                disabled={props.disabled}
                                hasChanged={hasChanged}
                            />
                            <ValidatedInput
                                type="text"
                                id="bank_account_type_id"
                                name="bank_account_type_id"
                                value={request.bank_account_type_id}
                                label={translate("bank_account_type.tenant_bank_account_type.title_single") as string}
                                options={bankAccountTypesIds}
                                optionLabels={bankAccountTypesLabels}
                                required={true}
                                disabled={props.disabled}
                                margin={margin}
                                onValueChanged={hasChanged}
                            />
                            {!isForeign && visibleFieldNames.includes("bank") &&
                                <ValidatedInput
                                    type="text"
                                    id="bank"
                                    name="bank"
                                    value={request.bank}
                                    label={translate("bank_account.bank") as string}
                                    options={bankIds}
                                    optionLabels={bankLabels}
                                    required={requiredFieldNames.includes("bank")}
                                    disabled={props.disabled || ["CIE"].includes(bankAccountTypeSelected.external_id)}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {!isForeign && visibleFieldNames.includes("subsidiary") &&
                                <ValidatedInput
                                    type="text"
                                    id="subsidiary"
                                    name="subsidiary"
                                    value={request.subsidiary || ""}
                                    label={translate("bank_account.subsidiary") as string}
                                    required={requiredFieldNames.includes("subsidiary")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {!isForeign && visibleFieldNames.includes("subsidiary_number") &&
                                <ValidatedInput
                                    type="text"
                                    id="subsidiary_number"
                                    name="subsidiary_number"
                                    value={request.subsidiary_number}
                                    label={translate("bank_account.subsidiary_number") as string}
                                    required={requiredFieldNames.includes("subsidiary_number")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                    validator={TEXT_ONLY_NUMBER_VALIDATOR}
                                />
                            }
                            {visibleFieldNames.includes("account") &&
                                <ValidatedInput
                                    type="text"
                                    id="account"
                                    name="account"
                                    value={request.account}
                                    label={translate("bank_account.account") as string}
                                    required={requiredFieldNames.includes("account")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    validator={TEXT_ONLY_NUMBER_VALIDATOR}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {!isForeign && visibleFieldNames.includes("clabe") &&
                                <ValidatedInput
                                    type="text"
                                    id="clabe"
                                    name="clabe"
                                    value={request.clabe}
                                    label={translate("bank_account.clabe") as string}
                                    required={isNotProviderForeign && requiredFieldNames.includes("clabe")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    validator={CLABE_VALIDATOR}
                                    onValueChanged={hasChanged}
                                />}
                            <Box py={1}>
                                <ValidatedInput
                                    type="text"
                                    id="currency"
                                    name="currency"
                                    label={translate("currencies.select_currency") as string}
                                    required={true}
                                    disabled={props.disabled || isEdit || isCurrencyDisabled || ["CLABE", "SPID"].includes(bankAccountTypeSelected.external_id)}
                                    margin={margin}
                                    onValueChanged={onCurrencyChanged}
                                    autocompleteOptions={currenciesAutocomplete}
                                    autocompleteValue={currenciesAutocomplete.find(c => c.value === request?.currency)}
                                    autocompleteKey={request.currency}
                                    value={request.currency}
                                    getId={(el) => el?.value}
                                    getLabel={(el) => el?.title} />
                            </Box>
                            {isForeign && visibleFieldNames.includes("bank_name") &&
                                <ValidatedInput
                                    type="text"
                                    id="bank_name"
                                    name="bank"
                                    value={request.bank}
                                    label={translate("bank_account.bank") as string}
                                    required={requiredFieldNames.includes("bank_name")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {isForeign && visibleFieldNames.includes("city") &&
                                <ValidatedInput
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={request.city}
                                    label={translate("bank_account.city") as string}
                                    required={requiredFieldNames.includes("city")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {isForeign && visibleFieldNames.includes("state") &&
                                <ValidatedInput
                                    type="text"
                                    id="state"
                                    name="state"
                                    value={request.state}
                                    label={translate("bank_account.state") as string}
                                    required={requiredFieldNames.includes("state")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {isForeign && visibleFieldNames.includes("code") &&
                                <ValidatedInput
                                    type="text"
                                    id="code"
                                    name="code"
                                    value={request.code}
                                    label={translate("bank_account.code") as string}
                                    required={requiredFieldNames.includes("code")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {visibleFieldNames.includes("aba_code") &&
                                <ValidatedInput
                                    type="text"
                                    id="aba_code"
                                    name="aba_code"
                                    value={request.aba_code}
                                    label={translate("bank_account.aba_code") as string}
                                    required={requiredFieldNames.includes("aba_code")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {visibleFieldNames.includes("cie_agreement") &&
                                <ValidatedInput
                                    type="text"
                                    id="cie_agreement"
                                    name="cie_agreement"
                                    value={request.cie_agreement}
                                    label={translate("bank_account.cie_agreement") as string}
                                    required={requiredFieldNames.includes("cie_agreement")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {visibleFieldNames.includes("spid_relation_type_id") &&
                                <ValidatedInput
                                    type="text"
                                    id="spid_relation_type_id"
                                    name="spid_relation_type_id"
                                    value={request.spid_relation_type_id}
                                    label={translate("bank_account.spid_relation_type") as string}
                                    options={spidRelationTypesIds}
                                    optionLabels={spidRelationTypesLabels}
                                    required={requiredFieldNames.includes("spid_relation_type_id")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {visibleFieldNames.includes("reference") &&
                                <ValidatedInput
                                    type="text"
                                    id="reference"
                                    name="reference"
                                    value={request.reference}
                                    label={translate("bank_account.reference") as string}
                                    required={requiredFieldNames.includes("reference")}
                                    disabled={props.disabled}
                                    margin={margin}
                                    onValueChanged={hasChanged}
                                />
                            }
                            {visibleFieldNames.includes("cover") &&
                                <Grid>
                                    <UploaderInput
                                        id="cover"
                                        name="cover"
                                        path="caratula"
                                        value={url}
                                        label={translate("bank_account.cover") as string}
                                        required={(isForeign ? false : true) && requiredFieldNames.includes("cover")}
                                        disabled={props.disabled}
                                        margin={margin}
                                        onValueChanged={hasChanged}
                                        acceptExtension={".pdf"}
                                    />
                                </Grid>
                            }
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={props.disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={props.disabled}>
                        {translate(isEdit ? "buttons.update" : "buttons.add")}
                    </Button>
                </DialogActions>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />

        </Dialog>
    );
}

interface CountryInputProps {
    country: string;
    countryLabels: string[];
    disabled: boolean;
    hasChanged(name: string, value: string, inputRef: InputRef): any;
}

export function CountryInput(props: CountryInputProps) {
    return (
        <>
            <ValidatedInput
                type="text"
                id="country"
                name="country"
                value={props.country}
                label={translate("bank_account.country") as string}
                options={CONTRIES}
                optionLabels={props.countryLabels}
                required={true}
                disabled={props.disabled}
                margin={"dense"}
                onValueChanged={props.hasChanged}
            />
        </>
    );
}