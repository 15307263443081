import React, { useState, useRef } from "react";

import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ConnectorObjectExporter, { ExportMode } from "../connectors/ConnectorObjectExporter";
import { ExportResponse, Family } from "../model/Connector";
import ExportPopup from "../components/DownloadExportationPopup";
import ConnectorTemplateDownloader from "../connectors/ConnectorTemplateDownloader";
import CustomBackdrop from "../components/CustomBackdrop";
import ImportResumePopup from "./ImportResumePopup";
import { ImportResponse } from "../model/ExportableObject";
import ExportMenu from "./ExportMenu";

interface ExportableComponentProps {
    tenantId: string;
    family: Family;
    selectedIds: string[];
    total?: number;
    createAction: string;
    getAction: string;
    showExportFiles?: boolean;
    getImportPromise(tenantId: string, file: File): Promise<ImportResponse>;
    getExportWithParamsPromise(tenantId: string, connectorId: string,  startDate?: Date, endDate?: Date): Promise<ExportResponse>
    getExportSelectedPromise(tenantId: string, connectorId: string): Promise<ExportResponse>
    getDownloadFilesWithParamsPromise?(tenantId: string,  startDate?: Date, endDate?: Date): Promise<ExportResponse>
    getDownloadFilesSelectedPromise?(tenantId: string): Promise<ExportResponse>;
    onImported(): any;
}

function ExportableComponent(props: ExportableComponentProps) {
    const [gridAnchorEl, setGridAnchorEl] = useState<HTMLElement | null>();
    const fileInput = useRef<HTMLInputElement>(null);
    const [downloadTemplate, setDownloadTemplate] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [importSummary, setImportSummary] = useState<ImportResponse>();
    const [exportMode, setExportMode] = useState<ExportMode>();
    const [exportResult, setExportResult] = useState<ExportResponse>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const openFileImport = () => {
        setGridAnchorEl(null);
        fileInput.current!.click();
    }

    const onDownloadTemplate = () => {
        setGridAnchorEl(null);
        setDownloadTemplate(true);
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setUploading(true);
        props.getImportPromise(props.tenantId, event.target.files[0]).then((response) => {
            setImportSummary(response);
            props.onImported();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setUploading(false);
            fileInput.current!.value = "";
        });
    }

    const onExportWithParams = () => {
        setGridAnchorEl(null);
        if (props.total) {
            setExportMode('PARAMS');
        } else {
            setWarning(translate("exportable.empty") as string);
        }
    }

    const onExportSelected = () => {
        setGridAnchorEl(null);
        if (props.selectedIds.length) {
            setExportMode('SELECTED');
        } else {
            setWarning(translate("exportable.no_selection") as string);
        }
    }

    const onDownloadFilesWithParams = () => {
        setGridAnchorEl(null);
        if (props.total) {
            setExportMode('ONLY_DATES');
        } else {
            setWarning(translate("exportable.empty") as string);
        }
    }

    const downloadAllFilesByDates = (connectorId: string, starDate?: Date, endDate?: Date) => {
        if (!exportMode) return;
        if(props.getDownloadFilesWithParamsPromise){
            props.getDownloadFilesWithParamsPromise(props.tenantId, starDate, endDate).then((response) => {
                if (response.url) {
                    setExportResult(response);
                } else if (response.total) {
                    setSuccess(translate("exportable.email", { "total": response.total }) as string);
                } else {
                    setWarning(translate("exportable.empty") as string);
                }
            }).catch((error) => {
                setError(error.message);
            }).finally(onCloseExporter);
        
        }
    }

    const onDownloadFilesSelected = () => {
        setGridAnchorEl(null);
        if (props.selectedIds.length) {
            if(props.getDownloadFilesSelectedPromise){
                let promise = props.getDownloadFilesSelectedPromise(props.tenantId);
                promise.then((response) => {
                    if (response.url) {
                        setExportResult(response);
                    } else if (response.total) {
                        setSuccess(translate("exportable.email", { "total": response.total }) as string);
                    } else {
                        setWarning(translate("exportable.empty") as string);
                    }
                }).catch((error) => {
                    setError(error.message);
                }).finally(onCloseExporter);
            }
        } else {
            setWarning(translate("exportable.no_selection") as string);
        }
    }

    const downloadWithConnector = (connectorId: string, starDate?: Date, endDate?: Date) => {
        if (!exportMode) return;

        let promise: Promise<ExportResponse>;
        if (exportMode === 'PARAMS') {
            promise = props.getExportWithParamsPromise(props.tenantId, connectorId, starDate, endDate);
        } else {
            promise = props.getExportSelectedPromise(props.tenantId, connectorId);
        }

        promise.then((response) => {
            if (response.url) {
                setExportResult(response);
            } else if (response.total) {
                setSuccess(translate("exportable.email", { "total": response.total }) as string);
            } else {
                setWarning(translate("exportable.empty") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(onCloseExporter);
    }

    const onCloseExporter = () => {
        setExportMode(undefined);
    };

    const onCompleted = () => {
        setImportSummary(undefined)
        fileInput.current!.value = "";
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
        setWarning(undefined);
    };

    return (
        <>
            <IconButton color="default" size="small" onClick={onClickedMore}>
                <MoreVertIcon />
            </IconButton>
            <input type="file"
                onChange={handleUpload}
                ref={fileInput}
                style={{ display: "none" }}
                accept=".csv, .xlsx, .xls, .xml, .json, .txt, .tsv"
            />
            {gridAnchorEl && (
                <ExportMenu
                    createAction={props.createAction}
                    getAction={props.getAction}
                    anchor={gridAnchorEl}
                    onImport={openFileImport}
                    onDownloadTemplate={onDownloadTemplate}
                    onExportSelected={onExportSelected}
                    onExportWithParams={onExportWithParams}
                    onDownloadFilesSelected={onDownloadFilesSelected}
                    onDownloadFilesWithParams={onDownloadFilesWithParams}
                    showExportFiles={props.showExportFiles}
                    onClose={() => setGridAnchorEl(null)} />
            )}
            
            {exportMode && exportMode === 'PARAMS' &&(
                <ConnectorObjectExporter
                    tenantId={props.tenantId}
                    family={props.family}
                    type="DATA_RANGE"
                    onExport={downloadWithConnector}
                    onClose={onCloseExporter}
                    maxDaySelected={31}
                />
            )}
            {exportMode && exportMode === 'SELECTED' && (
                <ConnectorObjectExporter
                    tenantId={props.tenantId}
                    family={props.family}
                    onExport={downloadWithConnector}
                    onClose={onCloseExporter}
                />
            )}
            {exportMode && exportMode === 'ONLY_DATES' && (
                <ConnectorObjectExporter
                    tenantId={props.tenantId}
                    family={props.family}
                    type="DATA_RANGE"
                    onExport={downloadAllFilesByDates}
                    onClose={onCloseExporter}
                    onlyDates={true}
                />
            )}
            {downloadTemplate && (
                <ConnectorTemplateDownloader
                    tenantId={props.tenantId}
                    family={props.family}
                    onClose={() => setDownloadTemplate(false)} />
            )}
            {exportResult && exportResult.url && (
                <ExportPopup
                    title={translate("exportable.title") as string}
                    message={translate("exportable.found", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={() => setExportResult(undefined)} />
            )}
            {importSummary && (
                <ImportResumePopup
                    summary={importSummary}
                    onClose={onCompleted}
                />
            )}
            {uploading && (
                <CustomBackdrop
                    open={uploading}
                    message={translate("importable.importing") as string}
                />
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </>
    );
}

export default ExportableComponent