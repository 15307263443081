import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory, Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import translate, { translateParams } from "../i18n/Translator";
import { Grid, IconButton, Divider, Button, Fab, Hidden, Typography } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import DialogPopup from "../components/DialogPopup";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import NewForeignCreditNotePop from "../foreigns/NewForeignCreditNotePopup";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendCfdiIcon from "@material-ui/icons/SendSharp";
import CfdisToSendIcon from "@material-ui/icons/SendTwoTone";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";

import { getCfdis, sendCfdis, getAllowedToSend } from "../api/ProviderCfdiApi";
import { exportCfdisSelected, exportAllCfdis, cancelCfdi, exportCfdiFiles, getActorFieldsTypeCatalogByStage, exportValidationsPdfCfdis } from "../api/TenantCfdiApi";
import { Cfdi, Cfdis, CfdisQueryParams, CfdiValidation, ReceptionDateValidationResponse, ActorStageFieldsResponse, FilterField, StatusGroup_SENDING } from "../model/Cfdi";
import { TenantCfdisRequest, ExportCfdisResponse } from "../model/TenantCfdi";
import { SendCfdisRequest, SendCfdisResponse, ProviderCfdiOverdueResponse, ProviderAllowedToSendResponse, ProviderExpedienteValidationResponse, CurrentPartiallityData } from "../model/ProviderCfdi";
import { CfdisListProps } from "../cfdi/CfdisList";
import ProviderCfdisMenu from "./menus/ProviderCfdisMenu";
import ProviderCfdiMenu from "./menus/ProviderCfdiMenu";
import CfdiDeletePopup from "./popups/CfdiDeletePopup";
import CfdisDeletePopup from "./popups/CfdisDeletePopup";
import ProviderVerificationTemplateResult from "./popups/ProviderVerificationTemplateResult"
import ProviderVerificationOneCfdiResult from "./popups/ProviderVerificationOneCfdiResult"
import NewPayableDocumentMenu from "../payable_documents/NewPayableDocumentMenu";
import NewExpensesAccountPopup from "../expenses_accounts/NewExpensesAccountPopup";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import NewForeignPopup from "../foreigns/NewForeignPopup";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ReactHtmlParser from "html-react-parser";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString, concatDates } from "../components/DateFormat";
import GridDx from "../components/GridDx";
import RequestClarification from "../clarifications/RequestClarification";
import { isRoleOrParent } from "../model/Role";
import FilterPopup from "../components/FilterPopup";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";
import { get as getTenantConfiguration } from "../api/TenantConfigurationApi";
import AdvancePaymentPopup from "../advancePayments/AdvancePaymentPopup";
import ForeignPayableDocumentMenu from "../payable_documents/ForeignPayableDocumentMenu";

export default function ListCfdisToSend(props: CfdisListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status_group": typeof qs["status_group"] === "string" ? qs["status_group"] as string : StatusGroup_SENDING,
            "selected_ids": typeof qs["selected_ids"] === "string" ? qs["selected_ids"] as string : "",
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "date_start": typeof qs["date_start"] === "string" ? qs["date_start"] as string : "",
            "date_end": typeof qs["date_end"] === "string" ? qs["date_end"] as string : "",
            "send_at_start": typeof qs["send_at_start"] === "string" ? qs["send_at_start"] as string : "",
            "send_at_end": typeof qs["send_at_end"] === "string" ? qs["send_at_end"] as string : "",
            "type_filter": typeof qs["type_filter"] === "string" ? qs["type_filter"] as string : "",
            "authorized_at_start": typeof qs["authorized_at_start"] === "string" ? qs["authorized_at_start"] as string : "",
            "authorized_at_end": typeof qs["authorized_at_end"] === "string" ? qs["authorized_at_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
            "referer_view": "TO_SEND",
            "filter_fields": typeof qs["filter_fields"] === "string" && qs["filter_fields"] ? JSON.parse(qs["filter_fields"] as string) : undefined,
        } as CfdisQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>();
    const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);

    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [selectedCfdis, setSelectedCfdis] = useState<(number | string)[]>([]);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [dialog, setDialog] = useState<"delete" | "multi_delete" | "expenses_account" | "honorarium">();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [verificationTemplateResult, setVerificationTemplateResult] = useState<SendCfdisResponse>();
    const [cfdiValidations, setCfdiValidations] = useState<CfdiValidation[]>();
    const [cfdiData, setCfdiData] = useState<Cfdi>();
    const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [receptionDateValidation, setReceptionDateValidation] = useState<ReceptionDateValidationResponse | undefined>(undefined);
    const [addForeignAnchorEl, setAddForeignAnchorEl] = useState<boolean>(false);
    const [exedientValidation, setExedientValidation] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [clarification, setClarification] = useState<boolean>(false);
    const [cfdisSelected, setCfdisSelected] = useState<Cfdi[]>([]);
    const [partiallityData, setPartiallityData] = useState<CurrentPartiallityData>();
    const [openPartiallityDialog, setOpenPartiallityDialog] = useState<boolean>(false);
    const isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const [fieldsCatalogType, setFieldsCatalogType] = useState<ActorStageFieldsResponse>();
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [openDownloadValidationsPdf, setOpenDownloadValidationsPdf] = useState<boolean>(false);
    const [hasAdvancePayments, setHasAdvancePayments] = useState<boolean>(false);
    const providerType = context.session!.provider!.type || "REGULAR";
    const [advancePaymentsModal, setAdvancePaymentsModal] = useState<boolean>(false);
    const [openForeingCreditNote, setOpenForeingCreditNote] = useState<boolean>(false);
    const [addAnchorElForeigns, setAddAnchorElForeigns] = useState<null | HTMLElement>(null);

    const paramsToFilters = () => {
        return [
            { columnName: 'identifier', value: params.identifier },
            { columnName: 'description', value: params.description },
            { columnName: 'date', value: concatDates(params.date_start, params.date_end) },
            { columnName: 'send_at', value: concatDates(params.send_at_start, params.send_at_end) },
            { columnName: 'type', value: params.type_filter },
            { columnName: 'authorized_at', value: concatDates(params.authorized_at_start, params.authorized_at_end) },
            { columnName: 'total', value: params.total },
            { columnName: 'balance', value: params.balance },
            { columnName: 'currency', value: params.currency },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const [allowedToSend, setAllowedToSend] = useState<ProviderAllowedToSendResponse>({
        expedient: {
            witoutExpedient: false,
            expiredExpedient: false,
            block: false
        } as ProviderExpedienteValidationResponse,
        reception_date: {
            valid: false,
            tenant_side: false
        } as ReceptionDateValidationResponse,
        cfdi_overdue: {
            cfdi_payment_overdue: 0,
            payments_with_pending_cfdi: 0,
            max_payment_cfdi_overdue: 0,
            block: false
        } as ProviderCfdiOverdueResponse
    } as ProviderAllowedToSendResponse);
    const [hasOverdue, setHasOverdue] = useState<boolean>(false);

    const load = () => {
        setStatus("loading");
        pushHistory();
        if (props.success && isSuccess) {
            setSuccess(props.success);
            setIsSuccess(false);
        }
        getCfdis(context.session!.tenant!.id, context.session!.provider!.id,
            context.isGranted("SenderCFDIsRead") ? context.session!.user.id : "",
            pageSize, pageSize * page, params).then((response) => {
                setData(response);
                setSelectedCfdis([]);
                getAllowedToSend(context.session!.tenant!.id, context.session!.provider!.id).then((response) => {
                    setAllowedToSend(response);
                }).catch((error) => {
                    setError(error.message);
                });
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setStatus("loaded");
            });

        getTenantConfiguration(context.session!.tenant!.id).then((response) => {
            setHasAdvancePayments(response.has_advance_payment);
        }).catch((error) => {
            setError(error.message);
        });

    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.identifier = filter.value;
                        break;
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    case "date":
                        let dates = filter.value.split(" - ");
                        if (dates.length === 2) {
                            temp.date_start = formatDateString(dates[0]);
                            temp.date_end = formatDateString(dates[1]);
                        } else {
                            temp.date_start = "";
                            temp.date_end = "";
                        }
                        break;
                    case "send_at":
                        let datesSend = filter.value.split(" - ");
                        if (datesSend.length === 2) {
                            temp.send_at_start = formatDateString(datesSend[0]);
                            temp.send_at_end = formatDateString(datesSend[1]);
                        } else {
                            temp.send_at_start = "";
                            temp.send_at_end = "";
                        }
                        break;
                    case "type":
                        temp.type_filter = filter.value;
                        break;
                    case "authorized_at":
                        let authorizedAtDates = filter.value.split(" - ");
                        if (authorizedAtDates.length === 2) {
                            temp.authorized_at_start = formatDateString(authorizedAtDates[0]);
                            temp.authorized_at_end = formatDateString(authorizedAtDates[1]);
                        } else {
                            temp.authorized_at_start = "";
                            temp.authorized_at_end = "";
                        }
                        break;
                    case "total":
                        temp.total = filter.value;
                        break;
                    case "balance":
                        temp.balance = filter.value;
                        break;
                    case "currency":
                        temp.currency = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status_group"] = workingParams.status_group;
        qs["selected_ids"] = workingParams.selected_ids ? workingParams.selected_ids : "";
        qs["receptor"] = workingParams.receptor ? workingParams.receptor : "";
        qs["identifier"] = workingParams.identifier ? workingParams.identifier : "";
        qs["description"] = workingParams.description ? workingParams.description : "";
        qs["date_start"] = workingParams.date_start ? workingParams.date_start : "";
        qs["date_end"] = workingParams.date_end ? workingParams.date_end : "";
        qs["send_at_start"] = workingParams.send_at_start ? workingParams.send_at_start : "";
        qs["send_at_end"] = workingParams.send_at_end ? workingParams.send_at_end : "";
        qs["type_filter"] = workingParams.type_filter ? workingParams.type_filter : "";
        qs["authorized_at_start"] = workingParams.authorized_at_start ? workingParams.authorized_at_start : "";
        qs["authorized_at_end"] = workingParams.authorized_at_end ? workingParams.authorized_at_end : "";
        qs["total"] = workingParams.total ? workingParams.total : "";
        qs["balance"] = workingParams.balance ? workingParams.balance : "";
        qs["currency"] = workingParams.currency ? workingParams.currency : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";
        qs["filter_fields"] = workingParams.filter_fields ? JSON.stringify(workingParams.filter_fields) : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onAppliedFilter = () => {
        pushHistory();
        if (selectedCfdis.length > 0) {
            setSelectedCfdis([]);
        }
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickFilter = () => {
        getActorFieldsTypeCatalogByStage(context.session!.tenant!.id).then((response) => {
            setFieldsCatalogType(response);
            if (response && response.fields && response.fields.length > 0) {
                setOpenFilterDialog(true);
            } else {
                setWarning(translate("cfdis.popup.filter_fields.not_filters_found") as string);
            }
        }).catch((error) => {
            setError(error.message);
        });
    }

    const onClickedClean = () => {
        const params = { ...workingParams, filter_fields: undefined, search: "" }
        setWorkingParams(params);
        setParams(params);
    }

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onSend = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        if (allowedToSend.expedient.block) {
            setExedientValidation(true);
            return;
        }
        if (allowedToSend.cfdi_overdue.block) {
            setHasOverdue(true);
            return;
        }

        setOpenBackdrop(true);
        sendCfdis(context.session!.tenant!.id, context.session!.provider!.id, { cfdi_ids: [cfdi.id], status_group_from: 'SENDING' } as SendCfdisRequest).then((response) => {
            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                return;
            }
            load();
            let cfdiSent = response.cfdis.find(element => element.id === cfdi.id);
            if (cfdiSent) {
                if (response.total_sent === 1) {
                    if (response.partiallity_data && isProvider) {
                        setPartiallityData(response.partiallity_data);
                        setOpenPartiallityDialog(true);
                    }
                    setSuccess(translate("cfdis.to_send.messages.success_sending", { "id": cfdiSent.identifier }) as string);
                } else if (cfdiSent.error) {
                    setError(cfdiSent.error);
                } else {
                    setCfdiData(cfdiSent);
                    setCfdiValidations(cfdiSent.metadata.validations);
                }
            } else {
                setError(translate("cfdis.to_send.messages.sending_failed") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onSendCfdis = () => {
        if (allowedToSend.expedient.block) {
            setExedientValidation(true);
            return;
        }
        if (allowedToSend.cfdi_overdue.block) {
            setHasOverdue(true);
            return;
        }
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            sendCfdis(context.session!.tenant!.id, context.session!.provider!.id, { status_group_from: 'SENDING', cfdi_ids: selectedCfdis } as SendCfdisRequest).then((response) => {
                if (response.cfdis_cancelled) {
                    setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                    return;
                }
                load();
                if (selectedCfdis.length > 1) {
                    if (selectedCfdis.length === response.total_sent) {
                        setSuccess(translate("cfdis.to_send.messages.success_sendings", { "total": response.total_sent }) as string);
                    } else {
                        setVerificationTemplateResult(response);
                    }
                } else {
                    let cfdiSent = response.cfdis.find(element => element.id === selectedCfdis[0]);
                    if (cfdiSent) {
                        if (response.total_sent === 1) {
                            if (response.partiallity_data) {
                                setPartiallityData(response.partiallity_data);
                                setOpenPartiallityDialog(true);
                            }
                            setSuccess(translate("cfdis.to_send.messages.success_sending", { "id": cfdiSent.identifier }) as string);
                        } else if (cfdiSent.error) {
                            setError(cfdiSent.error);
                        } else {
                            setCfdiData(cfdiSent);
                            setCfdiValidations(cfdiSent.metadata.validations);
                        }
                    } else {
                        setError(translate("cfdis.to_send.messages.sending_failed") as string);
                    }
                }
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const showDeleteDialog = () => {
        if (!cfdi) return;
        setAnchorEl(null);
        setDialog("delete");
    };

    const onSuccessDelete = () => {
        if (!cfdi) return;
        load();
        setSuccess(translate("cfdis.success_deleting", { "id": cfdi.identifier }) as string);
        setCfdi(undefined);
        setDialog(undefined);
    }

    const showMultiDeleteDialog = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setDialog("multi_delete");
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onSuccessMultiDelete = (total_deleted: number) => {
        setSuccess(translate("cfdis.success_deletings", { "total": total_deleted }) as string);
        setDialog(undefined);
        load();
    }

    const onDownloadCfdis = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selectedCfdis } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.url) {
                    setExportResult(response);
                } else {
                    if (response.total > 0) {
                        setSuccess(translate("cfdis.email_export") as string);
                    } else {
                        setWarning(translate("cfdis.empty_export") as string);
                    }
                }
            }).catch((error) => {
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onDownloadAllCfdis = () => {
        setOpenBackdrop(true);
        setOpenConfirmDialog(false);
        let user = "";
        if (context.isGranted("SenderCFDIsRead")) {
            user = context.session!.user.id;
        }
        let p = params;
        p.provider_id = context.session!.provider!.id;
        exportAllCfdis(context.session!.tenant!.id, user, p).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                setExportResult(response);
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onOpenConfirmDialog = () => {
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialog(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
        setGridAnchorEl(null);
    }
    const onClarification = () => {
        setGridAnchorEl(null);
        if (data && selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setCfdisSelected(data.items.filter(item => selectedCfdis.find(cfdiId => cfdiId === item.id)));
            setClarification(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_clarification") as string);
        }
    }

    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const onError = (error: string) => {
        setError(error);
        setCfdi(undefined);
        setDialog(undefined);
    };

    const onCancel = () => {
        setCfdi(undefined);
        setDialog(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onValidationTemplateResult = () => {
        setVerificationTemplateResult(undefined);
    }

    const onValidationOneCfdiResult = () => {
        setCfdiValidations(undefined);
        setCfdiData(undefined);
    }

    const onAddCfdi = (event: React.MouseEvent<HTMLElement>) => {
        let currentEvent = event.currentTarget;
        if (allowedToSend.expedient.block) {
            setExedientValidation(true);
            return;
        }
        if (allowedToSend.cfdi_overdue.block) {
            setHasOverdue(true);
            return;
        }
        if (!allowedToSend.reception_date.valid) {
            setReceptionDateValidation(allowedToSend.reception_date);
            return;
        }
        openNewCfdiForm(currentEvent);
    }

    const openNewCfdiForm = (currentEvent: HTMLElement) => {
        if ((providerType === "CUSTOMS_AGENT") || (hasAdvancePayments && context.session!.provider!.make_advance_payments && context.session!.provider!.allow_make_advance_payments)) {
            setAddAnchorEl(currentEvent);
        } else if (providerType === "FOREIGN") {
            setAddAnchorEl(currentEvent);
        } else {
            setRedirect("/providerCfdis/new");
        }
    }

    const onNewExpesesAccount = (honorarium: boolean) => {
        setAddAnchorEl(null);
        setDialog(honorarium ? "honorarium" : "expenses_account");
    };

    const onAdvancePayments = () => {
        setAddAnchorEl(null);
        setAdvancePaymentsModal(true);
    };

    const onAddedExpensesAccount = (expensesAccount: Cfdi) => {
        if (expensesAccount.type === "HONORARIUM") {
            setRedirect(`/expenses-accounts/${expensesAccount.id}/honorarium`);
        } else {
            setRedirect(`/cfdis/${expensesAccount.id}/details?referer=to-send`);
        }
    };

    const onCancelAdding = () => {
        setAddAnchorEl(null);
        setAddAnchorElForeigns(null);
        setDialog(undefined);
    };

    const onCloseValidationCfdiPaymentOverdue = () => {
        setHasOverdue(false);
    };

    const onClosePartiallityDialog = () => {
        setOpenPartiallityDialog(false);
        setPartiallityData(undefined);
    };

    const onCloseExedientValidation = () => {
        setExedientValidation(false);
    };

    const onCloseClarification = () => {
        setOpenBackdrop(false);
        setClarification(false);
    };
    const onRequestClarification = (comment: string) => {
        setOpenBackdrop(false);
        setClarification(false);
        setSuccess(comment);
    };

    const onClickedRow = (cfdi: Cfdi) => {
        pushHistory();
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-send&${queryParam}`);
    };

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onCancelCfdi = () => {
        if (!cfdi) return;
        setOpenCancelConfirmDialog(false);
        setOpenBackdrop(true);
        cancelCfdi(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": cfdi.identifier, "cfdi_link": `/cfdis/${response.related_cfdi}/details?referer=to-send&${queryParam}` }) as string
                ));
            } else {
                load();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onCloseReceptionDateValidation = () => {
        setReceptionDateValidation(undefined);
    }

    const onCancelAddingForeign = () => {
        setAddForeignAnchorEl(false);
    }

    const onCancelAdvancePayment = () => {
        setAdvancePaymentsModal(false);
    }

    const onAddedForeign = (cfdi: Cfdi) => {
        setAddForeignAnchorEl(false);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-send&${queryParam}`);
    }

    const onAddAdvancePaymentSuccess = (cfdi: Cfdi) => {
        setAdvancePaymentsModal(false);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-send&${queryParam}`);
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedCfdis(selected);
    };

    const onDownloadCfdiFiles = () => {
        setAnchorEl(null);
        setCfdi(undefined);
        setOpenBackdrop(true);
        if (!cfdi) return;
        exportCfdiFiles(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.url) {
                setExportResult(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadSelectedValidationsPdf = () => {
        if (selectedCfdis && selectedCfdis.length > 0) {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            let exportParams = params;
            exportParams.selected_ids = selectedCfdis.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        } else {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenConfirmDialogDownloadValidationsPdf = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenDownloadValidationsPdf(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadValidationsPdf = (paramsExport: CfdisQueryParams) => {
        exportValidationsPdfCfdis(context.session!.tenant!.id, paramsExport).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
        });
    }

    const onDownloadDateValidationsPdf = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setGridAnchorEl(null);
        setAnchorEl(null);
        setOpenBackdrop(true);
        setOpenDownloadValidationsPdf(false);
        let exportParams = { ...workingParams };
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        onDownloadValidationsPdf(exportParams);
    }

    const onCloseExporter = () => {
        setOpenDownloadValidationsPdf(false);
    };

    const onDoFilter = (filterFields: FilterField[]) => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: filterFields }
        setWorkingParams(params);
        setParams(params);
    };

    const onCleanFilter = () => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: undefined }
        setWorkingParams(params);
        setParams(params);
    };

    const onDownloadWithOutConnector = () => {
        if (!!cfdi) {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            let exportParams = params;
            exportParams.selected_ids = cfdi.id;
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        } else {
            setGridAnchorEl(null);
            setAnchorEl(null);
        }
    }

    const onForeignCreditNote = () => {
        setAddAnchorEl(null);
        setOpenForeingCreditNote(true);
    };

    const onCancelAddForeignCreditnote = () => {
        setOpenForeingCreditNote(false);
    }

    const onAddForeignCreditNote = (cfdi: Cfdi) => {
        setOpenForeingCreditNote(false);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-send&${queryParam}`);
    }

    return (
        <Surface title={props.title}
            subtitle={translate("cfdis.group.title") as string}
            icon={<CfdisToSendIcon />}
            className="PaperPagination"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate"]) ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("buttons.add") as string}
                                    onClick={onAddCfdi} disabled={status === "loading"}>
                                    <AddIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {context.isGrantedAny(["ProviderCFDIsUpdate", "SenderCFDIsUpdate"]) ?
                            (<Hidden xsDown implementation="css">
                                <Grid item xs="auto">
                                    <Fab color="secondary" size="small" title={translate("cfdis.to_send.actions.send") as string} onClick={onSendCfdis} disabled={status === "loading"} >
                                        <SendCfdiIcon />
                                    </Fab>
                                </Grid>
                            </Hidden>) : undefined}
                        <Hidden xsDown>
                            <Grid item xs="auto">
                                <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>

                    </Grid>
                </Grid>
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm>
                    <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onClickFilter} variant={params.filter_fields ? "contained" : "outlined"} color="primary" size="medium">
                        {params.filter_fields ? translate("buttons.filters_applied") : translate("buttons.filter")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton color="default" size="small" onClick={onClickedClean} disabled={status === "loading"}>
                        <HighlightOffTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className="focaltec-small">
                <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    columns={props.columns ? props.columns : []}
                    columnsFormat={props.columnsFormat}
                    clickRowColumns={props.clickRowColumns}
                    selectionIds={selectedCfdis}
                    onClickRow={onClickedRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    defaultExpandedGroups={props.defaultExpandedGroups}
                    amountCurrencyColumns={props.currencyColumns}
                    dateColumns={props.dateColumns}
                    leftColumns={props.leftColumns}
                    rightColumns={props.rightColumns}
                    textColumns={props.textColumns}
                    filters={filters}
                    grouping={props.grouping}
                    customFormatColumns={props.customPlugins}
                    setFiltersHandler={setFiltersHandler}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    setTableCell={props.tableCell}
                    setCheckCell={props.checkCell}
                />
            </div>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />

            {cfdi && anchorEl && (
                <ProviderCfdiMenu onDownloadFiles={onDownloadCfdiFiles} cfdi={cfdi} anchor={anchorEl} referer={`to-send&${queryParam}`}
                    onClose={onCloseOption} onSend={onSend} onDelete={showDeleteDialog}
                    onCancel={onOpenCancelCfdi} onDownloadValidationsPdf={onDownloadWithOutConnector} />
            )}

            {gridAnchorEl && (
                <ProviderCfdisMenu anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onDelete={showMultiDeleteDialog}
                    onDownload={onDownloadCfdis}
                    onDownloadAll={onOpenConfirmDialog}
                    onClarification={onClarification}
                    onDownloadValidationsPdf={onOpenConfirmDialogDownloadValidationsPdf}
                    onDownloadValidationsPdfSelected={onDownloadSelectedValidationsPdf}
                    origin="to-send" />
            )}
            {context.session && context.session.tenant && context.session.provider &&
                cfdi && dialog === "delete" &&
                <CfdiDeletePopup cfdi={cfdi}
                    tenantId={context.session.tenant.id}
                    providerId={context.session.provider.id}
                    onCompleted={onSuccessDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            }
            {context.session && context.session.tenant && context.session.provider &&
                selectedCfdis && dialog === "multi_delete" &&
                <CfdisDeletePopup cfdiIds={selectedCfdis.map(cfdi => cfdi.toString())}
                    tenantId={context.session.tenant.id}
                    providerId={context.session.provider.id}
                    onCompleted={onSuccessMultiDelete}
                    onError={onError}
                    onCancelled={onCancel} />
            }
            {openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onDownloadAllCfdis}
                    onClose={onCloseConfirmDialog}
                    title={translate("cfdis.popup.confirm_dialog.title") as string}
                    message={translate("cfdis.popup.confirm_dialog.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog.button") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {verificationTemplateResult &&
                <ProviderVerificationTemplateResult response={verificationTemplateResult} onClose={onValidationTemplateResult} />
            }
            {(cfdiValidations?.find(v => !v.valid) !== undefined) && cfdiData && (
                <ProviderVerificationOneCfdiResult data={cfdiValidations} cfdi={cfdiData} onClose={onValidationOneCfdiResult} />)
            }
            {addAnchorEl && (
                <NewPayableDocumentMenu anchor={addAnchorEl}
                    isProviderSide={true}
                    isProviderForeign={"FOREIGN" === providerType}
                    onClose={onCancelAdding}
                    onForeign={ () => {setAddForeignAnchorEl(true);}}
                    onForeignCreditNote={onForeignCreditNote}
                    onAdvancePayments={hasAdvancePayments && context.session!.provider!.make_advance_payments && context.session!.provider!.allow_make_advance_payments ? onAdvancePayments : undefined}
                    onNewExpesesAccount={(providerType === "CUSTOMS_AGENT") ? onNewExpesesAccount : undefined} />
            )}
            {(dialog === "expenses_account" || dialog === "honorarium") && (
                <NewExpensesAccountPopup honorarium={dialog === "honorarium"} onCancel={onCancelAdding} onSuccess={onAddedExpensesAccount} />
            )}

            {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} />
                : undefined}
            {openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.message") as string}
                    secondary={translate("cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}

            {receptionDateValidation && receptionDateValidation.code &&
                <CfdisConfirmPopup
                    title={translate("cfdis.popup.validation.types.RECEPTION_DATE") as string}
                    message={translate(`cfdis.popup.validation.codes.${receptionDateValidation.code}`, translateParams(receptionDateValidation.params) as any) as string}
                    onClose={onCloseReceptionDateValidation} />
            }

            {hasOverdue && (
                <DialogPopup open
                    title={translate("cfdis.popup.validation_cfdi_payment_overdue.title") as string}
                    closeText={translate("buttons.accept") as string}
                    onClose={onCloseValidationCfdiPaymentOverdue}
                    closeColor="default" >
                    <Typography variant="body2">
                        {translate("cfdis.popup.validation_cfdi_payment_overdue.description", { "overdue": allowedToSend.cfdi_overdue ? allowedToSend.cfdi_overdue.cfdi_payment_overdue : 0 }) as string}
                        <Link to={"/payment/pending-cfdi"}> aquí.</Link>
                    </Typography>
                </DialogPopup>
            )}

            {exedientValidation &&
                <DialogPopup open
                    title={translate("cfdis.popup.validation_expedient.title") as string}
                    onClose={onCloseExedientValidation}
                    closeText={translate("buttons.accept") as string}
                    closeColor="default" >
                    <Typography variant="body2">
                        {translate("cfdis.popup.validation_expedient.description") as string}
                    </Typography>
                </DialogPopup>
            }
            {addAnchorElForeigns && (
                <ForeignPayableDocumentMenu anchor={addAnchorElForeigns}
                    onClose={onCancelAdding}
                    onForeign={ () => {setAddForeignAnchorEl(true);}}
                    onForeignCreditNote={onForeignCreditNote} />
            )}
            {addForeignAnchorEl && (
                <NewForeignPopup onCancel={onCancelAddingForeign} onSuccess={onAddedForeign} provider_id={context.session!.provider!.id} />
            )
            }
            {openForeingCreditNote && (
                <NewForeignCreditNotePop onCancel={onCancelAddForeignCreditnote} onSuccess={onAddForeignCreditNote} cfdi={cfdi} provider_id={context.session!.provider!.id}/>
            )}
            {advancePaymentsModal &&
                <AdvancePaymentPopup onSuccess={onAddAdvancePaymentSuccess} onCancel={onCancelAdvancePayment} provider_id={context.session!.provider!.id} />
            }
            {clarification && context.session!.provider && (
                <RequestClarification
                    doAction={onRequestClarification}
                    onClose={onCloseClarification}
                    isCommentRequired={true}
                    cfdis={cfdisSelected}
                    providerId={context.session!.provider.id}
                    onError={(error) => setError(error)}
                />
            )}

            {partiallityData && openPartiallityDialog && (
                <DialogPopup open
                    title={translate("purchase_orders.current_partiallity_message.sending_title") as string}
                    closeText={translate("buttons.accept") as string}
                    onClose={onClosePartiallityDialog}
                    closeColor="default" >
                    <Typography variant="body2">
                        {translate("purchase_orders.current_partiallity_message.message", { "oc_number": partiallityData.purchase_order_number, "partiallity_number": partiallityData.partiallity_number, "percentaje": partiallityData.percentaje, "amount": partiallityData.amount, "currency": partiallityData.currency, "condition": partiallityData.payment_condition }) as string}
                        {ReactHtmlParser(translate("purchase_orders.current_partiallity_message.footer", { "url": partiallityData.url }) as string)}
                    </Typography>
                </DialogPopup>
            )}
            {openFilterDialog && fieldsCatalogType && (
                <FilterPopup
                    filterFields={fieldsCatalogType}
                    tenantId={context.session!.tenant!.id}
                    appliedFilters={workingParams.filter_fields}
                    doAction={onDoFilter}
                    onCleanFilter={onCleanFilter}
                    onClose={() => setOpenFilterDialog(false)} />
            )}
            {openDownloadValidationsPdf && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA_RANGE"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporter}
                    onExport={onDownloadDateValidationsPdf}
                    maxDaySelected={31} />
            )}
        </Surface >
    );
}

