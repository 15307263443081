import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import translate from "../../i18n/Translator";

import { ExtendedField } from "../../model/ExtendedField";
import { formatDate } from "../../components/DateFormat";

interface FieldItemTemplateProps {
    field: ExtendedField;
    value?: string;
}

function FieldItemTemplate({ field, value }: FieldItemTemplateProps) {
    if (field.type === "FILE") {
        return (
            <IconButton aria-label="add" color="primary" size="small" href={value || ""} target="_blank" rel="noopener noreferrer">
                <Typography variant="body2">
                    {translate("buttons.view_document")}
                </Typography>
            </IconButton>
        );
    }

    if (value && field.type === "DATE") {
        return (
            <>{formatDate(new Date(value), "DD/MM/YYYY")}</>
        );
    }

    if (field.type === "CATALOG") {
        const valueText = value;
        const valueCatalog = field.values_catalog.filter(value => value.id + "" === valueText);
        return (
            <>
                {valueCatalog[0].name} - {valueCatalog[0].external_id}
            </>
        );
    }

    return (
        <>{value}</>
    );
}

export default FieldItemTemplate