import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon, DeleteIcon, WebhookEventIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { Webhook } from "../model/Webhooks";
import { AppContext } from "../context/AppContext";

interface WebhookMenuProps {
    webhook: Webhook;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
}

export default function WebhooksMenu(props: WebhookMenuProps) {
    const context = useContext(AppContext);
    const [isUpdateGranted] = useState(context.isGranted("WebhooksUpdate"));
    const [isDeleteGranted] = useState(context.isGranted("WebhooksDelete"));

    return (
        <Menu
            id="webhooks-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button component={Link} to={`/webhooks/${props.webhook.id}/${props.webhook.title}/events`} onClick={props.onClose} dense>
                <ListItemIcon>
                    <WebhookEventIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("webhooks.events.see_events")}</Typography>
            </MenuItem>
            {isUpdateGranted && (
                <MenuItem button component={Link} to={`/webhooks/${props.webhook.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            {isDeleteGranted && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.delete")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}