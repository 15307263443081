import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import AddIcon from "@material-ui/icons/AddTwoTone";
import { PurchaseOrderMode } from "../model/RequisitionTypeCatalog";

interface NewRequisitionMenuProps {
    anchor: HTMLElement | null;
    onClose(): void;
    onNewRequisition(mode: PurchaseOrderMode): any;
}

export default function NewRequisitionMenu(props: NewRequisitionMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="new-requisition-menu"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Fade}>
            {context.isGrantedAny(["RequisitionsCreate"]) &&
                <MenuItem button onClick={() => props.onNewRequisition(PurchaseOrderMode.QUOTATION_BY_PROVIDER)} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("requisitions.quotation_by_provider")}</Typography>
                </MenuItem>
            }
            {context.isGranted("RequisitionsCreate") && (context.isGranted("BudgetsCreate") || context.isGranted("BudgetDetailReadOnly")) &&
                <MenuItem button onClick={() => props.onNewRequisition(PurchaseOrderMode.BUDGET_CONTROL)} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("requisitions.budget_control")}</Typography>
                </MenuItem>
            }
        </Menu>);
}
