import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Gridable from "../components/Gridable";

import translate from "../i18n/Translator";
import { RetentionRule } from "../model/ReceptionRule";
import { ServiceProduct } from "../model/ServiceProduct";

interface RetentionsListSectionProps {
    retentions?: RetentionRule[];
    serviceProducts: ServiceProduct[];
}

export default function RetentionsListSection(props: RetentionsListSectionProps) {
    const [retentions, setRetentions] = useState<RetentionRule[]>([]);

    const getDescriptionRetention = (item: RetentionRule, type?: string) => {
        if (!item || !item.retentions) {
            return "---";
        }
        let ivaRetention = item.retentions[0].retention_iva;
        let isrRetention = item.retentions[0].retention_isr;
        if (!type) {
            return `${item.name} - Ret ${isrRetention || 0}% ISR, Ret ${ivaRetention || 0}% IVA`;
        } else if ("ISR" === type) {
            return `${isrRetention || 0}%`;
        } else {
            return `${ivaRetention || 0}%`;
        }
    }

    useEffect(() => {
        let retentions = props.retentions;
        setRetentions(retentions!);
    }, [props]);

    const getDescription = (item: RetentionRule) => {
        if (!item || !props.serviceProducts) {
            return "---";
        }
        let key = item.retentions[0].product_service_key;
        if (!key) {
            return "---";
        }
        let servProd = props.serviceProducts.find(c => c.code === key);
        return servProd ? servProd.description : "---";
    }

    return (
        <Grid item xs={12}>
            <div style={{}}>
                <Gridable
                    items={retentions ? retentions : []}
                    loading={false}
                    empty={translate("specific_reception_rule.service_product.empty") as string}
                    columns={[
                        {
                            title: translate("reception_rule.retention_validation.service_product") as string,
                            converter: (retention) => retention.apply_all_product_services ? translate("reception_rule.retention_validation.all_service_product") as string : getDescription(retention),
                            xs: 4,
                        },
                        {
                            title: translate("reception_rule.retention_validation.isr_retention") as string,
                            converter: (retention) => getDescriptionRetention(retention, "ISR"),
                            xs: 3,
                        },
                        {
                            title: translate("reception_rule.retention_validation.iva_retention") as string,
                            converter: (retention) => getDescriptionRetention(retention, "IVA"),
                            xs: 3,
                        },
                    ]}
                />
            </div>
        </Grid>
    );
}
