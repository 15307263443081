import React, { useState, useEffect } from "react";
import { Grid, Button, IconButton, Typography, Box } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { ExtendedField, ExtendedFields } from "../../model/ExtendedField";
import { EditSingleIcon, DeleteIconOneTone, BackspaceIcon, InReviewIcon as VisibilityIcon } from "../Icons";
import { formatDate } from "../DateFormat";
import { getShippingFileUrl, getUrlFieldShippingTemplate } from "../../api/TenantCfdiApi";


interface FieldDataProps {
    field: ExtendedField;
    tenantId: string;
    cfdiId: string;
    onlyRead: boolean;
    canDelete: boolean;
    onEditField(field: ExtendedField): any;
    onDeleteField(field: ExtendedField): any;
    onShowPaymentHistory(field: ExtendedField): any;
}

export default function FieldData(props: FieldDataProps) {
    const [value, setValue] = useState<string>(props.field.value || "");
    const [urlText, setUrlText] = useState<string>("");
    const [maskDate] = useState<string>('DD/MM/YYYY');

    const formatteDate = (value: string) => {
        if (!value)
            return "";
        var date = new Date(value);
        return formatDate(date, maskDate);
    }

    const getTitleCatalog = (field: ExtendedField) => {
        const valueText = field.value;
        const valueCatalog = (field.values_catalog || []).filter(el => `${el.id}` === valueText);
        const first = valueCatalog[0];
        return first ? `${first.name} - ${first.external_id}` : valueText;
    };

    useEffect(() => {
        setUrlText(translate("buttons.view_document") as string);
        if (props.field.value && props.field.type === "FILE" && !props.field.value.startsWith("http")) {
            getShippingFileUrl(props.tenantId, props.cfdiId, props.field.id).then((url) => {
                setValue(url);
                
            }).catch((error) => {
            });
        } else if(props.field.value && props.field.type === "URL"){
            getUrlFieldShippingTemplate(props.tenantId, props.cfdiId, props.field.id).then((urlField) => {
                setValue(urlField.url);
                setUrlText(urlField.text);
            }).catch((error) => {
            });
        } else {
            setValue(props.field.value || "");
        }
    }, [props]);

    if (props.field.value) {
        if (props.field.type === "FILE" || props.field.type === "URL") {
            return (
                <div>
                    <Grid container
                        direction="row"
                        justify ="flex-start"
                        alignItems="flex-start">
                        {!props.onlyRead && (
                            <Grid>
                                <IconButton aria-label="add" color="primary" size="small" onClick={() => props.onEditField(props.field)}>
                                    <EditSingleIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                        <IconButton aria-label="add" color="primary" size="small" href={value} target="_blank" rel="noopener noreferrer">
                            <Typography variant="body2">{urlText}</Typography>
                        </IconButton>
                        {!props.onlyRead && (
                            <Grid>
                                <IconButton aria-label="delete" color="default" size="small" onClick={() => props.onDeleteField(props.field)}>
                                    <DeleteIconOneTone fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                        {props.field.by_each_payment && (
                            <Grid>
                                <IconButton aria-label="delete" color="primary" size="small" onClick={() => props.onShowPaymentHistory(props.field)}>
                                    <BackspaceIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>

                </div>
            );
        }

        return (
            <div>
                <Grid container
                    direction="row"
                    justifyContent ="flex-start"
                    alignItems="flex-start">
                    {!props.onlyRead &&
                        <IconButton aria-label="add" color="primary" size="small" onClick={() => props.onEditField(props.field)}>
                            <EditSingleIcon fontSize="small" />
                        </IconButton>
                    }
                    {props.field.read_only && props.field.use_custom_purchase_order_component && (
                        <IconButton aria-label="add" color="primary" size="small" onClick={() => props.onEditField(props.field)}>
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    )}
                    {props.field.type === "DATE" ? formatteDate(props.field.value) : props.field.type === "CATALOG" ? getTitleCatalog(props.field) : props.field.value}
                    {!props.onlyRead && props.canDelete &&
                        <IconButton aria-label="delete" color="default" size="small" onClick={() => props.onDeleteField(props.field)}>
                            <DeleteIconOneTone fontSize="small" />
                        </IconButton>
                    }
                    {props.field.by_each_payment && (
                        <Grid>
                            <IconButton aria-label="delete" color="primary" size="small" onClick={() => props.onShowPaymentHistory(props.field)}>
                                <BackspaceIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }

    if (!props.onlyRead) {
        return (
            <div>
                <Grid container
                    direction="row"
                    justify ="flex-start"
                    alignItems="flex-start">
                    <Button aria-label="add" color="secondary" size="small" onClick={() => props.onEditField(props.field)} startIcon={<EditSingleIcon fontSize="small" />}>
                        <Typography variant="body2">{translate("cfdis.template.add")}</Typography>
                    </Button>
                    {props.field.by_each_payment && (
                        <Grid>
                            <IconButton aria-label="delete" color="primary" size="small" onClick={() => props.onShowPaymentHistory(props.field)}>
                                <BackspaceIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </div>
        );

    }

    return <div> </div>;
}