import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Card, CardHeader, CardContent, Box, Typography, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import Row from "../components/Row";
import Alert from "../components/Alert";
import { AppContext } from "../context/AppContext";
import { getTenantCfdiFileUrl } from "../api/TenantCfdiApi";
import { getCfdiFileUrl } from "../api/ProviderCfdiApi";
import { Honorarium } from "../model/ExpensesAccount";

interface ExpensesAccountHonorariumProps {
    payableDocument: Cfdi;
}

export default function ExpensesAccountHonorarium(props: ExpensesAccountHonorariumProps) {

    if (!props.payableDocument.expenses_account) {
        return (<></>);
    }

    return (
        <Card variant="outlined">
            <CardHeader subheader={(
                <strong>{translate("honorarium.title")}</strong>
            )} disableTypography />
            <CardContent>
                <Box px={2}>
                    <Grid item xs={12}>
                        {props.payableDocument.expenses_account.honorarium && (
                            <HonorariumDetail currency={props.payableDocument.currency} honorarium={props.payableDocument.expenses_account.honorarium} />
                        )}
                        {!props.payableDocument.expenses_account.honorarium && (
                            <Grid container spacing={1}>
                                <Alert variant="info" title={translate("honorarium.missing.title")} subheader={translate("honorarium.missing.subtitle")} plain margin={0} button={(
                                    <Box pt={3}>
                                        <Button variant="outlined" size="small" color="primary" component={Link} to={`/expenses-accounts/${props.payableDocument.id}/honorarium`}>
                                            {translate("honorarium.missing.button")}
                                        </Button>
                                    </Box>
                                )} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}

interface HonorariumDetailProps {
    currency: string;
    honorarium: Honorarium;
}

function HonorariumDetail(props: HonorariumDetailProps) {
    const context = useContext(AppContext);
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [xmlUrl, setXmlUrl] = useState<string>();

    const getPromise = (format: "pdf" | "xml") => {
        const providerId = context.session?.provider?.id;
        if (providerId) {
            return getCfdiFileUrl(context.session!.tenant!.id, providerId, props.honorarium.cfdi_id, format)
        }
        return getTenantCfdiFileUrl(context.session!.tenant!.id, props.honorarium.cfdi_id, format);
    };

    useEffect(() => {
        getPromise("xml").then((url) => {
            setXmlUrl(url);
        }).catch((error) => {
        });

        getPromise("pdf").then((url) => {
            setPdfUrl(url);
        }).catch((error) => {
        });
        // eslint-disable-next-line
    }, [context.session, props.honorarium]);

    return (
        <Grid container spacing={1}>
            <Row title={translate("cfdis.details.serie_folio") as string} content={props.honorarium.identifier} />
            <Row title={translate("expenses_accounts.description") as string} content={props.honorarium.description} />
            {xmlUrl && (
                <Row title={translate("cfdis.xml") as string} content={(
                    <a href={xmlUrl} target="_blank" rel="noopener noreferrer">
                        {translate("cfdis.show_xml")}
                    </a>
                )} />
            )}
            {pdfUrl && (
                <Row title={translate("cfdis.pdf") as string} content={(
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                        {translate("cfdis.show_pdf")}
                    </a>
                )} />
            )}
            {props.honorarium.customs_paperwork && (
                <Row title={translate("honorarium.customs_paperwork") as string} content={(
                    <Typography variant="button" color="primary">
                        <NumberFormat value={props.honorarium.customs_paperwork} prefix={`${props.currency} $`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                    </Typography>
                )} />
            )}
            <Row title={translate("honorarium.total") as string} content={(
                <Typography variant="button" color="primary">
                    <NumberFormat value={props.honorarium.total} prefix={`${props.currency} $`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                </Typography>
            )} />
        </Grid>
    );
}