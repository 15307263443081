import React, { useEffect, useState, useContext } from "react";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

import OwnerDashboard from "./OwnerDashboard";
import ValidatorDashboard from "./ValidatorDashboard";
import AdminDashboard from "./AdminDashboard";
import ProviderDashboard from "./ProviderDashboard";
import CalendarWidget from "./CalendarDashboard";
import Widgets from "./Widgets";
import { getPasswordExpirationTime } from "../api/MeAPI";

import { Grid, Typography, Box, Link } from '@material-ui/core';
import { PasswordExpirationTime } from "../model/PasswordExpirationTime";
import Progress from "../components/Progress";
import { Link as RouterLink } from "react-router-dom";

export default function Dashboard() {

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<PasswordExpirationTime>();

    const context = useContext(AppContext);
    let rol = context.session!.role.id;
    let rol_parent = context.session!.role.role_parent_id ? context.session!.role.role_parent_id : context.session!.role.id;
    const load = () => {
        getPasswordExpirationTime().then((response) => {
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => setStatus("loaded"));
    }

    useEffect(load, [context.session]);

    if (status === "loading") {
        return (<Progress />);
    }

    if (data && undefined !== data.content) {
        return (
            <Grid container
                direction="column"
                justify="flex-start"
                alignItems="flex-start">

                <Grid container alignItems="center" justify="space-between" spacing={2}>
                    <Grid item alignItems="center" >
                        <Grid container direction="row" justify="flex-start" alignItems="flex-end" >
                            <Grid item xs="auto" md="auto" lg="auto" xl="auto" container direction="row" justify="flex-start" alignItems="flex-end" spacing={1}>
                                <Box mr={1} mb={1}>
                                    <Typography align="center" color="error" >
                                        {data.content === 0 ? translate("dashboard.password_expiration") : translate("dashboard.password_expiration_time", { "days": data.content })}
                                    </Typography>
                                </Box>

                                <Box py={0} my={0} mb={1}>
                                    <Typography align="center" >
                                        <div>
                                            {translate("dashboard.password_expiration_time_suggestion_part1")}
                                            <Link component={RouterLink} to="/security" >
                                                {translate("dashboard.password_expiration_time_suggestion_part2")}
                                            </Link>
                                            {translate("dashboard.password_expiration_time_suggestion_part3")}
                                        </div>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    {getDashboardByRol(rol, rol_parent)}
                </Grid>
            </Grid>)
            ;
    }

    return getDashboardByRol(rol, rol_parent);
}

function getDashboardByRol(rol: any, rol_parent: any) {
    let rolDash = rol;
    if(rol_parent !== rol && rol !== "armor_purchasing"){
        rolDash = rol_parent;
    }
    
    switch (rolDash) {
        case "owner":
        case "reader_only":
        case "owner_repse":
            return <OwnerDashboard />;
        case "validator":
        case "validator_all":
        case "armor_purchasing":
            return <ValidatorDashboard />;
        case "admin":
            return <AdminDashboard />;
        case "provider":
        case "sender_cfdi":
            return <ProviderDashboard />;
        case "cxp":
        case "treasurer":
        case "receptor_cfdi":
        case "supervisor":
        case "project_participant":
        case "project_manager":
            return <CalendarAndWidgets />;
        default:
            return <div>{translate("dashboard.title")}</div>;
    }
}
export function WidgetsMenu() {
    return (
        <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
                <Widgets />
            </Grid>
        </Grid>
    )
}

export function CalendarAndWidgets() {
    const context = useContext(AppContext);
    return (
        <Grid container justify="center" spacing={3}>
            {context.isGrantedAny(["CalendarRead"]) && (
                <Grid item xs={12}>
                    <CalendarWidget />
                </Grid>
            )}
            <Grid item xs={12}>
                <Widgets />
            </Grid>
        </Grid>
    )
}