import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { green, grey } from "@material-ui/core/colors";
import OkIcon from "@material-ui/icons/CheckCircleTwoTone";
import ErrorIcon from "@material-ui/icons/HighlightOffTwoTone";
import InProgressIcon from "@material-ui/icons/TimelapseTwoTone";

import { IntegrationLog } from "../model/IntegrationLog";
import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import { getCfdi } from "../api/TenantCfdiApi";
import { resendToErp } from "../api/IntegrationLogAPI";
import { Cfdi } from "../model/Cfdi";
import Alert from "../components/Alert";
import { SuccessSnackbar } from "../components/Snackbars";

const DetailsDialogContent = withStyles((theme: Theme) => ({
    root: {
        minWidth: "280px",
        [theme.breakpoints.up("sm")]: {
            minWidth: "420px",
        },
        [theme.breakpoints.up("lg")]: {
            minWidth: "600px",
        },
    },
}))(DialogContent);

const Code = withStyles((theme: Theme) => ({
    root: {
        fontFamily: "monospace",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.caption.fontSize,
        backgroundColor: grey[50],
        padding: theme.spacing(2),
        width: "100%",
        borderRadius: theme.shape.borderRadius,
    },
}))(Grid);

interface IntegrationLogDetailsProps {
    integrationLog: IntegrationLog;
    onUpdated(integrationLog?: IntegrationLog, error?: string): any;
    onClose(): any;
}

export default function IntegrationLogDetails(props: IntegrationLogDetailsProps) {
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState<string>();

    useEffect(() => {
        const { tenant_id, payable_document_id } = props.integrationLog;
        if(props.integrationLog.type === 'invoice'){
            getCfdi(tenant_id || "-", payable_document_id).then(cfdi => {
                setCfdi(cfdi);
            }).catch(error => {
                console.error(error);
            });
        }
    }, [props.integrationLog]);

    const isRetryable = () => {
        if(!props.integrationLog.retry_data){
            return isCfdiRetryable();
        }
        if(props.integrationLog.retryable && props.integrationLog.type === 'invoice'){
            return isCfdiRetryable();
        }
        return props.integrationLog.retryable;
    }

    const isCfdiRetryable = () => {
        return cfdi && cfdi.metadata.status !== "DELIVERED_ERP";
    }

    const onUpdate = () => {
        const { tenant_id, id } = props.integrationLog;

        setSubmitting(true);
        resendToErp(tenant_id || "-", id).then(response => {
            setSubmitting(false);
            setSuccess(translate("integration_logs.success_resend") as string);
        }).catch(error => {
            setSubmitting(false);
            props.onUpdated(undefined, error.message);
        });
    }

    const onCloseSnackbars = () => {
        setSuccess(undefined);
    };

    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <Grid container alignContent="center" alignItems="center" justify="flex-start" spacing={1}>
                    <Grid item xs="auto">
                        {(props.integrationLog.status === "ok" || props.integrationLog.status === "not_required") && (
                            <OkIcon style={{ "color": green[500] }} />
                        )}
                        {props.integrationLog.status === "error" && (
                            <ErrorIcon color="error" />
                        )}
                        {props.integrationLog.status === "in_progress" && (
                            <InProgressIcon color="disabled" />
                        )}
                    </Grid>
                    <Grid item xs>
                        <Typography component="h6" variant="subtitle1">
                            {props.integrationLog.file || props.integrationLog.id || "---"}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DetailsDialogContent dividers>
                <List dense disablePadding>
                    {props.integrationLog.status === "error" && cfdi?.metadata.status === "DELIVERED_ERP" && (
                        <ListItem disableGutters>
                            <ListItemText primary={(
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Alert variant="success" title={translate("integration_logs.already_reported")} plain />
                                    </Grid>
                                </Grid>
                            )} />
                        </ListItem>
                    )}
                    <ListItem disableGutters>
                        <ListItemText primary={translate("integration_logs.user")}
                            secondary={props.integrationLog.user ? `${props.integrationLog.user.first_name} ${props.integrationLog.user.last_name}` : translate("integration_logs.auto")} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={translate("integration_logs.created_at")}
                            secondary={(
                                <DateFormat date={props.integrationLog.created_at} format="DD-MM-YYYY h:mm:ss" zone />
                            )} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={translate("integration_logs.completed_at")}
                            secondary={(
                                <DateFormat date={props.integrationLog.completed_at} format="DD-MM-YYYY h:mm:ss" zone />
                            )} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={translate("integration_logs.type.title")}
                            secondary={translate(`integration_logs.type.${props.integrationLog.type}`)} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={translate("reports.transaction_log.columns.document_external_id")}
                            secondary={props.integrationLog.payable_document_external_id || "-"} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={translate("reports.transaction_log.columns.document_id")}
                            secondary={props.integrationLog.payable_document_id} />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={translate("integration_logs.source.title")}
                            secondary={translate(`integration_logs.source.${props.integrationLog.source}`)} />
                    </ListItem>
                    {props.integrationLog.plugin && (
                        <ListItem disableGutters>
                            <ListItemText primary={translate("integration_logs.plugin")}
                                secondary={props.integrationLog.plugin} />
                        </ListItem>
                    )}
                    {props.integrationLog.ip && (
                        <ListItem disableGutters>
                            <ListItemText primary={translate("integration_logs.ip")}
                                secondary={props.integrationLog.ip} />
                        </ListItem>
                    )}
                </List>
                {props.integrationLog.request && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {translate("integration_logs.request")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Code>{props.integrationLog.request}</Code>
                        </Grid>
                    </Grid>
                )}
                {props.integrationLog.response && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {translate("integration_logs.response")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Code>{props.integrationLog.response}</Code>
                        </Grid>
                    </Grid>
                )}
                {props.integrationLog.stacktrace && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {translate("integration_logs.stacktrace")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Code>{props.integrationLog.stacktrace}</Code>
                        </Grid>
                    </Grid>
                )}
            </DetailsDialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2}>
                    <Grid item xs="auto">
                        <Button onClick={props.onClose} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.close")}
                        </Button>
                    </Grid>
                    {props.integrationLog.status === "error" && (
                        <Grid item xs="auto">
                            <Button onClick={onUpdate} variant="contained" color="primary" disabled={submitting || !isRetryable()}>
                                {translate("buttons.retry")}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Dialog>
    );
}