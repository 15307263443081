import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";

import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "./ValidatedInput";
import DynamicIcon from "./DynamicIcon";
import { LinkIcon } from "./Icons";

interface IconSelectorProps {
    icon?: string
    onValueChanged(name: string, value: string | number, inputRef: InputRef): void;
}

function IconSelector({ icon, onValueChanged }: IconSelectorProps) {
    const [icons] = useState(() => {
        return Object.keys(MuiIcons).filter(el => el.endsWith("TwoTone"));
    })

    return (
        <Grid container justify="space-between" alignItems="center" alignContent="center">
            <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center" alignContent="center" spacing={2}>
                    <Grid item xs>
                        <ValidatedInput
                            type="text"
                            id="icon"
                            name="icon"
                            value={icon}
                            label={translate("integrations.routes.icon") as string}
                            options={icons}
                            required
                            margin="dense"
                            onValueChanged={onValueChanged} />
                    </Grid>
                    {icon && (
                        <Grid item xs="auto">
                            <DynamicIcon icon={icon} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    component="a"
                    href="https://mui.com/material-ui/material-icons/?theme=Two+tone"
                    target="_blank"
                    endIcon={(<LinkIcon
                    />)}>
                    {translate("integrations.routes.icons_link")}
                </Button>
            </Grid>
        </Grid>
    )
}

export default IconSelector