import React, { useEffect, useState } from "react";
import { IconButton, Box } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import { Employee } from "../model/Employee";
import DateFormat from "../components/DateFormat";
import { listPayrolls, deletePayroll } from "../api/PayrollAPI";
import { listPayrolls as listProviderPayrolls, deletePayroll as deleteProviderPayroll } from "../api/ProviderPayrollAPI";
import { PayrollsQueryParams } from "../model/Payroll";
import { Cfdi, Cfdis } from "../model/Cfdi";
import PayrollMenu from "../payrolls/PayrollMenu";
import { ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import { SuccessSnackbar } from "../components/Snackbars";

interface EmployeePayrollsProps {
    tenantId: string;
    providerId?: string;
    employee: Employee;
}

export default function EmployeePayrolls(props: EmployeePayrollsProps) {
    const [status, setStatus] = useState("loading");
    const [data, setData] = useState<Cfdis>();
    const [payroll, setPayroll] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [error, setError] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>();

    const promiseGet = () => {
        const params = { employeeId: props.employee.id } as PayrollsQueryParams;
        if (props.providerId) {
            return listProviderPayrolls(props.tenantId, props.providerId, 0, 0, params);
        }
        return listPayrolls(props.tenantId, 0, 0, params);
    };

    const load = () => {
        setStatus("loading");
        promiseGet().then(response => {
            setData(response);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [props.tenantId, props.providerId, props.employee]);

    const onClickedOptions = (payroll: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPayroll(payroll);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPayroll(undefined);
    };

    const promiseDelete = (payrollId : string) => {
        if (props.providerId) {
            return deleteProviderPayroll(props.tenantId, props.providerId, payrollId);
        }
        return deletePayroll(props.tenantId, payrollId);
    };

    const onDelete = () => {
        setAnchorEl(null);
        if(!payroll) return;
        setSubmitting(true);
        promiseDelete(payroll.id).then((_) => {
            setSuccess(translate("payrolls.sucess_delete") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setPayroll(undefined);
        });;
    };

    return (
        <Box pt={2} >
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("payrolls.empty") as string}
                columns={[
                    {
                        title: translate("payrolls.date") as string,
                        converter: (payroll) => (
                            <DateFormat date={payroll.date} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("payrolls.identifier") as string,
                        converter: (payroll) => payroll.identifier,
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("payrolls.from") as string,
                        converter: (payroll) => (
                            <DateFormat date={payroll.metadata?.from} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("payrolls.to") as string,
                        converter: (payroll) => (
                            <DateFormat date={payroll.metadata?.to} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (payroll) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(payroll)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {payroll && anchorEl &&
                <PayrollMenu payroll={payroll} anchor={anchorEl} onClose={onCloseOption} onDelete={onDelete} />
            }
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <SuccessSnackbar message={success} onClose={() => setError(undefined)} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
        </Box>
    );
}