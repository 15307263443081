import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tab, Grid, AppBar, Button, Box } from "@material-ui/core";
import Progress from "../components/Progress";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";

import CfdiUsesTab from "./CfdiUsesTab";
import ProductsServicesTab from "./ProductsServicesTab";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { SpecificReceptionRuleType, SpecificReceptionRule } from "../model/SpecificReceptionRule";
import { ServiceProduct } from "../model/ServiceProduct";
import { ReceptionRuleUsesRequest } from "../model/ReceptionRule";
import ReceptionRulesPaymentsTab from "../reception_rules/ReceptionRulesPaymentsTab";

export interface SpecificReceptionRuleEditTabsProps {
    name: string;
    specifiReceptionRule: SpecificReceptionRule;
    specificReceptionRuleType: SpecificReceptionRuleType;
    onClose(to : string, success: string): any;
    onAction(info: SpecificReceptionRule): any;
}

export default function SpecificReceptionRuleEditTabs(props: SpecificReceptionRuleEditTabsProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");

    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();

    const [acceptAllServicesProducts, setAcceptAllServicesProducts] = useState<boolean>(false);
    const [acceptAllUses, setAcceptAllUses] = useState<boolean>(false);
    const [serviceProductValues, setServiceProductValues] = useState<ServiceProduct[]>([]);
    const [dataUsesCfdi, setDataUsesCfdi] = useState<string[]>([]);
    const [dataPaymentsMethodCfdi, setDataPaymentsMethodCfdi] = useState<Map<string, string[]>>({} as Map<string, string[]>);
    const [dataType, setDataType] = useState<SpecificReceptionRuleType>();
    const [value, setValue] = React.useState(0);

    const load = () => {
        setStatus("loading");
        setDataUsesCfdi(props.specificReceptionRuleType.uses);
        setDataPaymentsMethodCfdi(props.specificReceptionRuleType.payments);
        setServiceProductValues(props.specificReceptionRuleType.service_products);
        setDataType(props.specificReceptionRuleType);
        setAcceptAllServicesProducts(props.specificReceptionRuleType.use_all_service_products);
        setAcceptAllUses(props.specificReceptionRuleType.use_all_uses);
        setStatus("loaded");
    };

    useEffect(load, [context.session, props]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {

    }

    const addDataInType = () => {
        if (!acceptAllServicesProducts && (!serviceProductValues || serviceProductValues.length <= 0)) {
            setWarning(translate(`specific_reception_rule.service_product.warning_empty`) as string);
        } else if (!acceptAllUses && (!dataUsesCfdi || dataUsesCfdi.length <= 0)) {
            setWarning(translate(`specific_reception_rule.cfdi_uses_empty_selection`) as string);
        } else {
            let specificRule = props.specifiReceptionRule;
            specificRule.name = props.name;
            specificRule.specific_reception_rules_types.forEach((type) => {
                if (type && dataType && type.sat_cfdi_type === dataType.sat_cfdi_type) {
                    type.use_all_service_products = acceptAllServicesProducts;
                    type.use_all_uses = acceptAllUses;
                    type.uses = dataUsesCfdi;
                    type.service_products = serviceProductValues;
                    type.payments = dataPaymentsMethodCfdi;
                }
            });
            props.onAction(specificRule);
        }  
    }

    const updateUseCfdiAction = (request: ReceptionRuleUsesRequest, accept : string) => {
        setAcceptAllUses(accept === "all" ? true : false);
        setDataUsesCfdi(request.uses);  
    };

    const updatePaymentsAction = (payments: Map<string, string[]>) => {
        setDataPaymentsMethodCfdi(payments);
    };

    const updateRadio = (accept : string, isUses : boolean) => {
        if(isUses){
            setAcceptAllUses(accept === "all" ? true : false);
        }else{
            setAcceptAllServicesProducts(accept === "all" ? true : false);
        }
    }

    const updateProductsServices = (request: ServiceProduct[], accept : string) => {
        setServiceProductValues(request);
        setAcceptAllServicesProducts(accept === "all" ? true : false);
    };

    const onClose = () => {
        props.onClose("create-validation", "");
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
        setSuccess(undefined);
    };

    return (
        <Surface backButton onAction={onClose} title={translate("reception_rule.voucher_type." + dataType!.sat_cfdi_type) as string} subtitle={translate("reception_rule.title_edition") as string}
            icon={<PlaylistAddCheckIcon />} className="PaperPagination PaperTabContainer">
            <AppBar position="static">
                    <Tabs style={{ "backgroundColor": "white" }} textColor="primary" indicatorColor="primary" value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label={translate(`specific_reception_rule.service_product.title`)} {...a11yProps(0)} />
                        <Tab label={translate(`reception_rule.use_cfdi.grid_title`)} {...a11yProps(1)} />
                        { props.specificReceptionRuleType.sat_cfdi_type === 'I' && 
                            (<Tab label={translate(`reception_rule.payments_method.grid_title`)} {...a11yProps(2)} />)
                        }
                    </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ProductsServicesTab selected={serviceProductValues ? serviceProductValues : []} acceptAll={acceptAllServicesProducts} updateRadio={updateRadio} update={updateProductsServices} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CfdiUsesTab selected={dataUsesCfdi ? dataUsesCfdi : []} acceptAll={acceptAllUses} updateRadio={updateRadio} update={updateUseCfdiAction} />
            </TabPanel>
            {props.specificReceptionRuleType.sat_cfdi_type === 'I' &&
                (<TabPanel value={value} index={2}>
                    <ReceptionRulesPaymentsTab selectedMethods={dataPaymentsMethodCfdi ? dataPaymentsMethodCfdi :{} as Map<string, string[]> }  update={updatePaymentsAction} />
                </TabPanel>)
            }
            {context.isGrantedAny(["SpecificReceptionRuleUpdate", "SpecificReceptionRuleCreate"]) && (
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item xs="auto">
                            <Box mr={3} mt={3}>
                                <Button size="medium" color="primary" onClick={onClose}>
                                    {translate(`specific_reception_rule.cancel_button`)}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs="auto">
                            <Box mr={3} mt={3}>
                                <Button size="medium" variant="outlined" color="primary" onClick={addDataInType}>
                                    {translate(`specific_reception_rule.accept_button`)}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
}