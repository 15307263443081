import React, { ReactNode, useContext, useState } from "react";
import { Grid, Box, Card } from "@material-ui/core";
import { Cfdi } from "../model/Cfdi";

import { ProrateCfdi } from "../model/Prorate";
import { AuthorizedAmountResponse } from "../model/Cfdi"
import { AppContext } from "../context/AppContext";
import PayableDocumentTotals from "../payable_documents/PayableDocumentTotals";
import CfdiConceptos from "./CfdiConceptos";
import CfdiGenerals from "./CfdiGenerals";
import CfdiStamp from "./CfdiStamp";
import translate from "../i18n/Translator";

import PayableDocumentIssuerWidget from "../payable_documents/PayableDocumentIssuerWidget";
import PayableDocumentShippingData from "../payable_documents/PayableDocumentShippingData";
import PayableDocumentProrate from "../payable_documents/PayableDocumentProrate";
import ExpensesAccountDetails from "../expenses_accounts/ExpensesAccountDetails";
import ExpensesAccountHonorarium from "../expenses_accounts/ExpensesAccountHonorarium";
import ExpensesAccountDocuments from "../expenses_accounts/ExpensesAccountDocuments";
import { ExtendedFields } from "../model/ExtendedField";
import { Workflow } from "../model/Workflow";
import AdditionalFieldsSection from "../components/AdditionalFieldsSection";
import { AdditionalFieldRequest, AdditionalInfoField } from "../model/AdditionalInfoWorkflove";
import { updateAdditionalField } from "../api/RefundPayableDocumentApi";
import { ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import ReactHtmlParser from "html-react-parser";
import AdvancePaymentInfo from "../advancePayments/AdvancePaymentInfo";
import ForeignCreditNoteInfo from "../foreigns/ForeignCreditNoteInfo";

export interface CfdiDetailsParams {
    tenantId: string;
    cfdi: Cfdi;
    hideWorkflow?: boolean;
    header?: ReactNode;
    qs: string;
    referer?: string;
    onChangeTotalAuthorize(response: AuthorizedAmountResponse): any;
    onChangeTemplate(extendedFields: ExtendedFields): any;
    onChangeProrate(prorate: ProrateCfdi[]): any;
    onUpdated(payableDocument: Cfdi): any;
    onUpdatedTotalAuthorized(): any;
    onDeleteAdditionalAmount(): any;
}

export default function CfdiDetails(props: CfdiDetailsParams) {
    const context = useContext(AppContext);
    const [workflow] = useState<Workflow | undefined>(() => {
        if (props.cfdi.metadata.provider_workflows && props.cfdi.metadata.workflow_id) {
            return props.cfdi.metadata.provider_workflows.find(c => c.id === props.cfdi.metadata.workflow_id);
        }
    });
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();

    const onSaveAdditionalField = (field: AdditionalInfoField) => {
        if(!field) return;
        setOpenBackdrop(true);
        updateAdditionalField(context.session!.tenant!.id, props.cfdi.id, {
            id: field.id,
            value: field.value,
            type: field.type.id
        } as AdditionalFieldRequest).then(refund => {
            props.onUpdated(refund);
        }).catch(error => {
            setError(ReactHtmlParser(error.message));
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
    };

    return (
        <Box py={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between">
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {props.header}
                        {props.cfdi.metadata.type !== "CREDIT_NOTE" && props.cfdi.type !== "REFUND" &&
                            <PayableDocumentShippingData
                                payableDocument={props.cfdi}
                                workflow={workflow}
                                qs={props.qs}
                                onChangeTemplate={props.onChangeTemplate} />
                        }
                        {props.cfdi.type === "REFUND" && props.cfdi.metadata.fields_response && props.cfdi.metadata.fields_response.length > 0 &&
                            <Grid item xs={12}>
                                <Card variant="outlined" >
                                    <Box p={2}>
                                        <AdditionalFieldsSection 
                                            title={translate("refunds.additional_data_title") as string} 
                                            fields={props.cfdi.metadata.fields_response} 
                                            onSaveField={onSaveAdditionalField} />
                                    </Box>
                                </Card>
                            </Grid>
                        }
                        {context.isGranted("AuthorizedAmountCfdiUpdate") && props.cfdi.metadata.cfdi_proration &&
                            props.cfdi.metadata.cfdi_proration.provider_need_ledger_assignment &&
                            props.cfdi.metadata.type !== "CREDIT_NOTE" &&
                            <PayableDocumentProrate payableDocument={props.cfdi} onChangeProrate={props.onChangeProrate} />
                        }
                        {props.cfdi.expenses_account && (
                            <Grid item xs={12} lg={props.cfdi.type === "HONORARIUM" ? 7 : 12}>
                                <ExpensesAccountDetails payableDocument={props.cfdi} />
                            </Grid>
                        )}
                        {props.cfdi.expenses_account && props.cfdi.type === "HONORARIUM" && (
                            <Grid item xs={12} lg={5}>
                                <ExpensesAccountHonorarium payableDocument={props.cfdi} />
                            </Grid>
                        )}
                        {props.cfdi.expenses_account && (
                            <Grid item xs={12}>
                                <ExpensesAccountDocuments payableDocument={props.cfdi}
                                    documents={props.cfdi.expenses_account.documents}
                                    onUpdated={props.onUpdated} />
                            </Grid>
                        )}
                        {props.cfdi.type === "ADVANCE_PAYMENT" &&
                            <Grid item xs={12}>
                                <AdvancePaymentInfo advancePayment={props.cfdi} />
                            </Grid>
                        }
                        {"FOREIGN_CREDIT_NOTE" === props.cfdi.type &&
                            <Grid item xs={12}>
                                <ForeignCreditNoteInfo creditNote={props.cfdi} />
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <PayableDocumentIssuerWidget issuer={props.cfdi.issuer} receptor={props.cfdi.cfdi?.receptor} tax_id={props.cfdi.provider_tax_id} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PayableDocumentTotals cfdi={props.cfdi} onOpenUpdateAuthorizedAmount={props.onUpdatedTotalAuthorized} onDeleteAdditionalAmount={props.onDeleteAdditionalAmount} referer= {props.referer}/>
                        </Grid>
                        {props.cfdi.cfdi && (
                            <Grid item xs={12} sm={12} md={12}>
                                <CfdiConceptos cfdiId={props.cfdi.id} moneda={props.cfdi.currency} />
                            </Grid>
                        )}
                        {props.cfdi.cfdi && (
                            <Grid item xs={12} md={6}>
                                <CfdiGenerals cfdi={props.cfdi.cfdi} />
                            </Grid>
                        )}
                        {props.cfdi.cfdi && (
                            <Grid item xs={12} md={6}>
                                <CfdiStamp cfdi={props.cfdi.cfdi} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Box>
    )
}