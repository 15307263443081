import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import LabelIcon from '@material-ui/icons/Label';
import translate from "../i18n/Translator";
import { Entity as Provider} from "../model/Provider";
import { AppContext } from "../context/AppContext";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import GroupIcon from "@material-ui/icons/GroupTwoTone";

interface ProviderManagementMenuProps {
    provider: Provider;
    anchor: HTMLElement | null;
    onClose(): any;
    onSortProvider(): any;
    onDeleteProvider(): any;
    backTo?: string;
    entityType: "PROVIDER" | "VENDOR";
}

export default function ProviderManagementMenu(props: ProviderManagementMenuProps) {
    const context = useContext(AppContext);
    const isProvider = props.entityType === "PROVIDER";

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["ProvidersUpdate", "VendorsUpdate"]) &&
                <MenuItem button component={Link} to={`/`+(isProvider ? `providers` : `vendors`)+`/${props.provider.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["ProvidersCreate", "VendorsCreate"]) &&
                <MenuItem button component={Link} to={`/`+(isProvider ? `providers` : `vendors/detail`)+`/${props.provider.id}?referer=base&tab=info&${props.backTo}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <InfoTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.info")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ProvidersUpdate") && isProvider &&
                <MenuItem button onClick={props.onSortProvider} dense>
                    <ListItemIcon>
                        <LabelIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.sort_provider")}</Typography>
                </MenuItem>
            }
            {context.isGranted("OwnerProvidersUsersRead") && isProvider &&
                <MenuItem button component={Link} to={`/providers/${props.provider.id}/users`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <GroupIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ProvidersDelete") && isProvider &&
                <MenuItem button onClick={props.onDeleteProvider} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error" >{translate("providers.ledger-accounts.delete_popup")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 