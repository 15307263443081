import React from "react";
import { ListItemIcon, ListItemSecondaryAction, Typography, List, ListItem, ListItemText, Button } from "@material-ui/core";
import FoundIcon from "@material-ui/icons/SpellcheckTwoTone";
import CreatedIcon from "@material-ui/icons/PersonAddTwoTone";
import CancelIcon from "@material-ui/icons/CancelTwoTone";
import DuplicatedIcon from "@material-ui/icons/Brightness1TwoTone";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { ImportUsersSummary } from "../model/Provider";
import DialogPopup from "../components/DialogPopup";

interface ImportUsersResumePopupProps {
    summaryImport: ImportUsersSummary;
    onClose(): any;
}

export default function ImportResumePopup(props: ImportUsersResumePopupProps) {
    let values = {
        "found": props.summaryImport.found,
        "created": props.summaryImport.created,
        "duplicated": props.summaryImport.duplicated,
        "fail": props.summaryImport.failed,
    } as any;

    return (
        <DialogPopup
            title={translate("providers.import_users.resume_title")}
            open
            onClose={props.onClose}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            maxWidth='xs'
            fullWidth
            buttonLeft={props.summaryImport.template_with_errors ? (
                <Button component="a" href={props.summaryImport.template_with_errors} target="_blank" rel="noopener noreferrer"
                    variant="outlined" color="primary" size="medium">
                    {translate("buttons.download_erros_template") as string}
                </Button>
            ) : undefined}>
            <List component="nav">
                {Object.keys(values).map((status: string) => (
                    <StatusListItem key={status} status={status} value={values[status] as number} />
                ))}
            </List>
        </DialogPopup>
    );
}

interface StatusListItemProps {
    status: string;
    value: number;
    fontSize?: "small" | "large";
}

function StatusListItem(props: StatusListItemProps) {
    let icon;
    switch (props.status) {
        case "found":
            icon = <FoundIcon fontSize={props.fontSize || "default"} />
            break;
        case "created":
            icon = <CreatedIcon fontSize={props.fontSize || "default"} />
            break;
        case "duplicated":
            icon = <DuplicatedIcon fontSize={props.fontSize || "default"} />
            break;
        default:
            icon = <CancelIcon fontSize={props.fontSize || "default"} />
            break;
    }

    return (
        <ListItem>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={translate(`providers.import_users.${props.status}`)} />
            <ListItemSecondaryAction>
                <Typography variant="h6">
                    <NumberFormat value={props.value} displayType="text" />
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
}