import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { PayrollsQueryParams, PayrollRequest } from "../model/Payroll";
import { Cfdis, Cfdi } from "../model/Cfdi";
import { ExportPayrollsResponse } from "../model/Payroll";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/payrolls`;

const parsePayrollQuery = (userId: string, params: PayrollsQueryParams) => {
    let query = { }as Record<string, string>;

    if(params.startDate && params.endDate){
        query = {...query,
            startDate: params.startDate+"",
            endDate: params.endDate+"",
        };
    }
    return query;
}

export async function listPayrolls(tenantId: string, providerId: string, page: number, offset: number, params: PayrollsQueryParams): Promise<Cfdis> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search || "",
            employeeId: params.employeeId || "",
            date: params.date || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createPayroll(tenantId: string, providerId: string, request: PayrollRequest): Promise<Cfdis> {
    const data = new FormData();
    data.append("file", request.file as Blob, request.file.name);

    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getPayroll(tenantId: string, providerId: string, id: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updatePayroll(tenantId: string, providerId: string, id: string, request: PayrollRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deletePayroll(tenantId: string, providerId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getPayrollXmlUrl(tenantId: string, providerId: string, id: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, providerId)}/${id}/xml`);
}

export async function getPayrollPdfUrl(tenantId: string, providerId: string, id: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, providerId)}/${id}/pdf`);
}

const parsePayrollsExcel = (params: PayrollsQueryParams) => {
    return parsePayrollQuery("", params);
}

export async function exportPayrollsExcel(tenantId: string, providerId: string, params: PayrollsQueryParams): Promise<ExportPayrollsResponse> {
    return await callAPI(`${basePath(tenantId, providerId)}/export-payrolls-excel`, {
        method: HTTPMethod.GET,
        query: parsePayrollsExcel(params),
    });
}