import React, { useState, useEffect } from "react";
import { FormControl, FormGroup, FormControlLabel, FormLabel, Checkbox, FormHelperText, Typography, Box } from "@material-ui/core";

import translate from "../i18n/Translator";
import { InputRef } from "./ValidatedInput";

interface GroupCheckboxProps {
    title: string;
    values: string[];
    name?: string;
    labels: string[];
    checkeds: boolean[];
    row: boolean;
    required?: boolean;
    disabled?: boolean[];
    helper?: string;
    inputRef: InputRef;
    onChanged(name: string, value: string, checked: boolean): void;
}

export default function GroupCheckbox(config: GroupCheckboxProps) {
    const [blured, setBlured] = useState(false);
    const [count, setCount] = useState<number>(0);
    const [validation, setValidation] = useState<InputRef>();

    const handleChange = (index: number, name: string) => (event: React.ChangeEvent<{}>, checked: boolean) => {
        if (!blured) {
            setBlured(true);
        }
        if (checked) {
            setCount(count + 1);
        } else {
            setCount(count - 1);
        }

        if (validation) {
            validation.valid = !config.required || count > 0;
            setValidation(validation);
        }

        if (config.name) {
            config.onChanged(config.name, config.values[index], checked);
        } else {
            config.onChanged(name, config.values[index], checked);
        }
    };

    useEffect(() => {
        setCount(
            (function () {
                return config.checkeds.filter(el => el).length;
            })()
        );
        // eslint-disable-next-line
    }, [config.values, config.required, config.disabled]);

    useEffect(() => {
        if (config.inputRef) {
            config.inputRef.blurer = setBlured;
            config.inputRef.valid = !config.required || count > 0;

            setValidation(config.inputRef);
        }
    }, [config.inputRef, config.required, count]);

    return (
        <Box mt={2}>
            <FormControl
                required={config.required}
                error={blured && (validation && !validation.valid)}
                component="fieldset"
                focused={false}
            >
                <FormLabel component="legend">{config.title}</FormLabel>
                <FormGroup row={config.row}>
                    {config.values.map((value, index) => (
                        <FormControlLabel
                            key={value}
                            control={
                                <Checkbox
                                    checked={config.checkeds[index]}
                                    onChange={handleChange(index, value)}
                                    value={value}
                                />
                            }
                            label={config.labels[index]}
                            disabled={config.disabled === undefined ? false : config.disabled[index]}
                        />
                    ))}
                    {config.values.length === 0 && (
                        <Typography variant="overline" component="span">
                            {translate("no_values")}
                        </Typography>
                    )}
                </FormGroup>
                {blured && (validation && !validation.valid) && config.helper && (
                    <FormHelperText>{config.helper}</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}
