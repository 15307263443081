import { callAPI, HTTPMethod } from "./API";
import { DocsPendingQueryParams, DocsPending } from "../model/SEDocsPending";
import { ExportResponse } from "../model/Connector";

export async function list(tenantId: string, page: number, offset: number, params: DocsPendingQueryParams): Promise<DocsPending> {
    let query = {
        provider: params.provider ? params.provider : "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;
    
    return await callAPI(`/tenants/${tenantId}/docs-pending-by-provider`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function exportDocs(tenantId: string, params: DocsPendingQueryParams): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/docs-pending-by-provider/export`, {
        method: HTTPMethod.GET,
        query: {
            provider: params.provider
        }
    });
}