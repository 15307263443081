
export interface FocaltecAnnouncement {
    id: string;
    type: string;
    permanent: boolean;
    message: string;
    start_date: Date;
    end_date: Date;
    user_type: string;
    tenant_ids: string[];
    tenants_names: string[];
    send_all_tenants: boolean;
    notify_providers: boolean;
    status: string;
}

export interface FocaltecAnnouncements {
    items: FocaltecAnnouncement[];
    total: number;
}

export interface FocaltecAnnouncementsQueryParams {
    search: string;
}

export interface FocaltecAnnouncementRequest {
    type: string;
    permanent: boolean;
    message: string;
    start_date: Date;
    end_date: Date;
    user_type: string;
    tenant_ids: string[];
    send_all_tenants: boolean;
}

export interface FocaltecAnnouncementUpdateStatusRequest {
    status: string;
    end_date: Date;
}

export const TYPES = ["DANGER", "WARNING", "INFO", "SUCCESS"];

export const USERS_TYPES = ["ALL", "PROVIDERS", "TENANTS", "TENANT_PROVIDERS"];
