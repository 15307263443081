import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import AssignmentIcon from "@material-ui/icons/AssignmentTurnedInTwoTone";

import translate from "../i18n/Translator";
import { Plugin } from "../model/Plugin";
import { AppContext } from "../context/AppContext";
import { TenantConfiguration } from "../model/TenantConfiguration";

interface PluginMenuProps {
    plugin: Plugin;
    tenantConfiguration?: TenantConfiguration;
    tenantId: string;
    anchor: HTMLElement | null;
    onClose(): any;
    onAssignReceivePlugin(): any;
    onRemoveReceivePlugin(): any;
    onAssignAfterReceivePlugin(): any;
    onRemoveAfterReceivePlugin(): any;
}

export default function PluginMenu(props: PluginMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="plugins-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("PluginsUpdate") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenantId}/plugins/${props.plugin.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGranted("PluginsUpdate") && props.plugin.step === "RECEIVE" && props.tenantConfiguration?.receive_plugin_id && props.plugin.id === props.tenantConfiguration?.receive_plugin_id &&
                <MenuItem button onClick={props.onRemoveReceivePlugin} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("plugins.remove_receive")}</Typography>
                </MenuItem>
            }
            {context.isGranted("PluginsUpdate") && props.plugin.step === "RECEIVE" && (!props.tenantConfiguration?.receive_plugin_id || props.plugin.id !== props.tenantConfiguration?.receive_plugin_id) &&
                <MenuItem button onClick={props.onAssignReceivePlugin} dense>
                    <ListItemIcon>
                        <AssignmentIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("plugins.assign_receive")}</Typography>
                </MenuItem>
            }
            {context.isGranted("PluginsUpdate") && props.plugin.step === "AFTER_RECEIVE" && props.tenantConfiguration?.after_receive_plugin_id && props.plugin.id === props.tenantConfiguration?.after_receive_plugin_id &&
                <MenuItem button onClick={props.onRemoveAfterReceivePlugin} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("plugins.remove_after_receive")}</Typography>
                </MenuItem>
            }
            {context.isGranted("PluginsUpdate") && props.plugin.step === "AFTER_RECEIVE" && (!props.tenantConfiguration?.after_receive_plugin_id || props.plugin.id !== props.tenantConfiguration?.after_receive_plugin_id) &&
                <MenuItem button onClick={props.onAssignAfterReceivePlugin} dense>
                    <ListItemIcon>
                        <AssignmentIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("plugins.assign_after_receive")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}