import { callAPI, HTTPMethod } from "./API";
import { ServiceProducts } from "../model/ServiceProduct";

export async function getServiceProducts(page: number, offset: number, search: string): Promise<ServiceProducts> {
    return await callAPI(`/service-product-catalog`, {
        method: HTTPMethod.GET,
        query: {
            search: search,
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}