import React, { useState } from "react";
import { Grid, Button, Hidden } from "@material-ui/core";

import translate from "../i18n/Translator";
import { TemplateField } from "../workflove/Model";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import SimpleSwitch from "../components/SimpleSwitch";
import DialogPopup from "../components/DialogPopup";
import { WarningSnackbar } from "../components/Snackbars";
import { Product } from "./Workflove";
import { Expressions, Expression } from '../model/Expression';

export interface TemplateFieldFormProps {
    product: Product;
    current?: TemplateField;
    typeIds: string[];
    typeLabels: string[];
    typeCatalogId?: string;
    typePurchaseOrderId?: string;
    typeWarehouseDeliveryId?: string;
    typeUrlId?: string;
    catalogIds: string[];
    catalogNames: string[];
    byEachPayment?: boolean;
    expressionsData: Expressions;
    shippingTemplateId?: string;
    onUpdated(field: TemplateField): any;
    onClose(): any;
}

function TemplateFieldForm(props: TemplateFieldFormProps) {
    const margin = "dense";
    const [warning, setWarning] = useState<string>();

    const [validations, setValidations] = useState({} as any);
    const [request, setRequest] = useState<TemplateField>(() => {
        let req = props.current ?? {} as TemplateField;
        if (!!req.expression_id) {
            return req;
        }

        if (!!props.shippingTemplateId) {
            let expression = props.expressionsData.items.find(e => {
                let fields = e.fields ?? []
                if (!!fields) {
                    return (fields.find(f => f.field_id === req.id && f.shipping_template_id === props.shippingTemplateId)) != undefined;
                }
                return false;
            });
            if (expression) {
                req.expression_id = expression.id
            }
        }
        return req;
    });

    const autocompleteDefaultValue = { "id": "", "name": translate("expressions.unassigned") as string } as Expression

    const expressionSupportedTypes = ["TEXT", "NUMBER", "TEXTAREA"];

    const hasChangedType = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, type: value, external_id: "", name: "", description: "", expression_id: expressionSupportedTypes.includes(value) ? request.expression_id : undefined });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedCatalog = (name: string, value: string, inputRef: InputRef) => {
        const index = props.catalogIds.findIndex(el => el === value);
        if (index >= 0) {
            setRequest({ ...request, external_id: value, name: props.catalogNames[index] });
        } else {
            setRequest({ ...request, external_id: undefined, name: undefined });
        }

        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (request.external_id && (request.external_id === "PAYMENT_REFERENCE" || request.external_id === "payment_reference") &&
            ((request.url_value_default && request.url_value_default !== "") || request.type === "FILE" || request.type === "TEXTAREA")) {
            setWarning(translate("shipping_templates.invalid_payment_reference") as string);
            return;
        }
        if (!isValid(validations)) {
            return;
        }
        props.onUpdated(request);
    };

    const onSwitchRequestEachPayment = (name: string, checked: boolean) => {
        setRequest({ ...request, by_each_payment: checked });
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    return (
        <DialogPopup
            open
            title={translate(`shipping_templates.fields.${request.id ? "edit" : "add"}`) as string}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            onClose={props.onClose}
            closeText={translate("buttons.cancel") as string}
            onSubmit={onSubmit}
            button={(
                <Button type="submit" variant="contained" color="primary" size="medium">
                    {translate(request.id ? "buttons.update" : "buttons.add")}
                </Button>
            )}
            buttonLeft={(
                <Button variant="contained" color="primary" size="medium" style={{ visibility: "hidden" }}>
                    {translate("buttons.close")}
                </Button>
            )}>
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="type"
                        name="type"
                        value={request.type}
                        label={translate("shipping_templates.type") as string}
                        required={true}
                        disabled={false}
                        margin={margin}
                        options={props.typeIds}
                        optionLabels={props.typeLabels}
                        onValueChanged={hasChangedType}
                    />
                </Grid>
                {request.type === props.typeCatalogId && (
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="external_id"
                            name="external_id"
                            value={request.external_id}
                            label={translate("catalogs.single") as string}
                            options={props.catalogIds}
                            optionLabels={props.catalogNames}
                            disabled={request.type !== props.typeCatalogId}
                            required={request.type === props.typeCatalogId}
                            margin={margin}
                            onValueChanged={hasChangedCatalog}
                        />
                    </Grid>
                )}
                {request.type !== props.typeCatalogId && (
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="external_id"
                            name="external_id"
                            value={request.external_id}
                            label={translate("shipping_templates.external_id") as string}
                            disabled={request.type === props.typeCatalogId}
                            required={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Hidden xsUp={request.type === props.typePurchaseOrderId || request.type === props.typeWarehouseDeliveryId || request.type === props.typeCatalogId} implementation="css">
                        <ValidatedInput
                            type="text"
                            id="name"
                            name="name"
                            value={request.name}
                            label={translate("shipping_templates.name_field") as string}
                            required={request.type !== props.typePurchaseOrderId && request.type !== props.typeWarehouseDeliveryId && request.type !== props.typeCatalogId}
                            disabled={request.type === props.typePurchaseOrderId || request.type === props.typeWarehouseDeliveryId || request.type === props.typeCatalogId}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="description"
                        name="description"
                        value={request.description}
                        label={translate("shipping_templates.description") as string}
                        margin={margin}
                        maxRows={4}
                        onValueChanged={hasChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Hidden xsUp={request.type !== "FILE"} implementation="css">
                        <ValidatedInput
                            type="text"
                            id="extensions"
                            name="extensions"
                            value={request.extensions}
                            label={translate("shipping_templates.extensions") as string}
                            disabled={request.type !== "FILE"}
                            required={request.type === "FILE"}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    <Hidden xsUp={request.type !== props.typeUrlId} implementation="css">
                        <ValidatedInput
                            type="text"
                            id="url_value_default"
                            name="url_value_default"
                            value={request.url_value_default}
                            label={translate("shipping_templates.url_value_default") as string}
                            disabled={request.type !== props.typeUrlId}
                            required={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    <Hidden xsUp={request.type !== props.typeUrlId} implementation="css">
                        <ValidatedInput
                            type="text"
                            id="url_text_default"
                            name="url_text_default"
                            value={request.url_text_default}
                            label={translate("shipping_templates.url_text_default") as string}
                            disabled={request.type !== props.typeUrlId}
                            required={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                    </Hidden>
                </Grid>
                {props.product === "PAYABLE_DOCUMENTS" && (
                    <Grid item xs={12}>
                        <SimpleSwitch
                            value="by_each_payment"
                            label={translate("shipping_templates.by_each_payment.switch") as string}
                            checked={request.by_each_payment ?? false}
                            onChanged={onSwitchRequestEachPayment}
                            placement="end"
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id={request.expression_id ?? "expression_id"}
                        name="expression_id"
                        label={translate("shipping_templates.expressions") as string}
                        value={request.expression_id}
                        margin={"dense"}
                        autocompleteOptions={expressionSupportedTypes.includes(request.type) ? props.expressionsData.items : []}
                        autocompleteValue={props.expressionsData.items.find(item => item.id === request.expression_id)}
                        getId={item => item?.id}
                        getLabel={item => item ? item.name : ""}
                        disabled={!expressionSupportedTypes.includes(request.type)}
                        autocompleteKey={request.expression_id}
                        autocompleteDefaultValue={autocompleteDefaultValue}
                        onValueChanged={hasChanged} />
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}

export default TemplateFieldForm