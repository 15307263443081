import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { Box, Grid, Typography } from "@material-ui/core";
import { getStampTaxCert } from "../api/TaxStampMetadataApi";
import { ErrorSnackbar } from "../components/Snackbars";
import ListAltIcon from '@material-ui/icons/ListAlt';
import DateFormat from "../components/DateFormat";
import { listGeneratedPaymentCfdisByRfc } from "../api/ProviderCfdiApi";
import NumberFormat from "react-number-format";
import Gridable from "../components/Gridable";
import { Cfdi, Cfdis } from "../model/Cfdi";
import Progress from "../components/Progress";
import { Redirect } from "react-router-dom";
import Pagination, { initialPage, initialPageSizeOrElse, getOffset } from "../components/Pagination";
import { formatDateString, formatDateTimeToString } from "../components/DateFormat";
import { MultiselectValue } from "../components/MultiselectDropList";

export interface ProviderMembershipPaymentCfdisArchiveProps {
    providerRfc: string;
    providerId: string;
    elementsStatus: MultiselectValue[];
}

export default function ProviderMembershipPaymentCfdisArchiveView(props: ProviderMembershipPaymentCfdisArchiveProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [redirect, setRedirect] = useState<string>();
    const [data, setData] = useState<Cfdis>();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(initialPageSizeOrElse(20));

    const load = () => {
        setStatus("loading");
        let offset = getOffset(page, pageSize);
        listGeneratedPaymentCfdisByRfc(context.session!.tenant!.id, props.providerId, props.providerRfc, pageSize, offset).then(response => {
            setData(response);
        }).catch(error => {
            setError(error.message);
        }).finally(() => setStatus("loaded"));
    };

    useEffect(load, [context.session, page, pageSize]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };
    if (status === "loading") {
        return <Progress />;
    }

    const onClickRow = (cfdi: Cfdi) => {
        setRedirect(`/cfdis/${cfdi.id}/details?referer=tax_stamp`);
    };

    const getStatusDescription = (cfdi: Cfdi) => {
        let status = props.elementsStatus.find(item => item.value === cfdi.metadata.status);
        return status?.title;
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Box px={2} py={2}>
            <Pagination variant={"embeded"} title={translate("status_membership.payment_cfdis") as string} icon={<ListAltIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography><strong>{translate("status_membership.payment_cfdis")}</strong></Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Gridable
                        items={data?.items ? data?.items : []}
                        loading={status === "loading"}
                        empty={translate("status_membership.empty_cfdis") as string}
                        onClick={onClickRow}
                        columns={[
                            {
                                title: translate("status_membership.date") as string,
                                converter: (cfdi) => (formatDateTimeToString(cfdi.cfdi!.fecha_emision)),
                                xs: true
                            },
                            {
                                title: translate("status_membership.serie_folio") as string,
                                converter: (cfdi) => cfdi.identifier,
                                xs: true
                            },
                            {
                                title: translate("status_membership.receptor") as string,
                                converter: (cfdi) => cfdi.cfdi?.receptor.nombre, 
                                xs: true
                            },
                            {
                                title: translate("status_membership.uuid") as string,
                                converter: (cfdi) => cfdi.cfdi?.timbre.uuid,
                                xs: true
                            },
                            {
                                title: translate("status_membership.amount") as string,
                                converter: (cfdi) => (<NumberFormat value={cfdi.cfdi?.complementos.pagos20.pagos[0].monto}
                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                    thousandSeparator="," displayType="text"
                                />), 
                                justify: "flex-end",
                                xs: true
                            },
                            {
                                title: translate("currency.title") as string,
                                converter: (cfdi) => cfdi.cfdi?.complementos.pagos20.pagos[0].moneda,
                                justify: "flex-end",
                                xs: true
                            },
                            {
                                title: translate("status_membership.status") as string,
                                converter: (cfdi) => getStatusDescription(cfdi),
                                xs: true
                            },
                        ]} />

                </Grid>
            </Grid>
            </Pagination>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Box>
    );
}


