import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Clarification, ClarificationUpdateStatusRequest } from '../model/Clarification';
import { Grid, Box, Fab, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import FeedbackIcon from '@material-ui/icons/Feedback';
import { RouterParams } from "../router/RouterParams";
import { getClarification, updateStatus } from "../api/ClarificationApi";
import Progress from "../components/Progress";
import ChatClarification from "./ChatClarification";
import { SuccessSnackbar } from "../components/Snackbars";
import ClarificationPayableDocumentsDetailSection from "./ClarificationPayableDocumentsDetailSection";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LaunchIcon from '@material-ui/icons/Launch';
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function ClarificationDetail({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);
    const [clarification, setClarification] = useState<Clarification>({} as Clarification);
    const [success, setSuccess] = useState<string>();
    const [openConfirmSolved, setOpenConfirmSolved] = useState<boolean>(false);

    const onSendMessage = (response: Clarification) => {
        setClarification(response);
    }

    const onSolved = () => {
        setOpenConfirmSolved(true);
    }

    const onUnSolved = () => {
        setStatus("loading");
        updateStatus(context.session!.tenant!.id, match.params.clarificationId, {
            user_id: context.session!.user!.id,
            status: "UNSOLVED"
        } as ClarificationUpdateStatusRequest).then((response) => {
            setSuccess(translate("clarifications.details.reopen", { "number": clarification.number}) as string);
            setClarification(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const onCancelSolved = () => {
        setOpenConfirmSolved(false);
    }

    const onConfirmSolved = () => {
        setOpenConfirmSolved(false);
        setStatus("loading");
        updateStatus(context.session!.tenant!.id, match.params.clarificationId, {
            user_id: context.session!.user!.id,
            status: "RESOLVED"
        } as ClarificationUpdateStatusRequest).then((response) => {
            setSuccess(translate("clarifications.details.solved", { "number": clarification.number}) as string);
            setClarification(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        
    }

    const load = () => {
        setStatus("loading");
        getClarification(context.session!.tenant!.id, match.params.clarificationId).then((response) => {
            setClarification(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(load, [match.params.clarificationId] );

    return (
        <Surface title={translate(`clarifications.details.title.${clarification.status}`) as string} 
            backButton icon={<FeedbackIcon />} className="PaperPagination PaperTabContainer"
        titleActions={<Grid >
            <Grid container alignItems="center" justify="flex-end" spacing={1}>
                { clarification.status === "UNSOLVED" &&
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("clarifications.details.on_solved") as string} onClick={onSolved} >
                            <DoneAllIcon />
                        </Fab>
                    </Grid>}
                { clarification.status === "RESOLVED" &&
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("clarifications.details.on_unsolved") as string} onClick={onUnSolved} >
                            <LaunchIcon />
                        </Fab>
                    </Grid>}
            </Grid>
        </Grid>}>
            { status === "loading" ? <Progress /> : status !== "loaded" ? 
            <Typography variant="body1" component="h5" color="error" align="center"> {status} </Typography> : undefined }
            { status === "loaded" &&
                <Grid container>
                    <Grid item xs={6}>
                        <Box px={2} py={2}>
                            <ChatClarification clarification={clarification} xs={12} onUpdate={load} onSendMessage={onSendMessage} />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box px={2} py={2}>
                            <Grid container >
                                <Grid item>
                                    <ClarificationPayableDocumentsDetailSection cfdis={clarification.cfdis} payments={clarification.payments} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            }
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
            { openConfirmSolved && <ConfirmationPopup
                    title={translate("clarifications.details.on_solved") as string}
                    message={translate("clarifications.details.confirm_solved.message") as string}
                    secondary={translate("clarifications.details.confirm_solved.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelSolved}
                    doAction={onConfirmSolved}
                /> }
        </Surface>
    );
}

