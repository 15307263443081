import React, { useState, useEffect, useContext } from "react";
import { getTenantCfdisByProvider } from "../api/TenantCfdiApi";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Cfdi } from "../model/Cfdi";
import translate from "../i18n/Translator";
import Gridable, { GridableColumn } from "../components/Gridable";
import { CfdisSelected, PaymentData, CfdisByProviderParams, CfdiPayment } from "../model/Payment";
import Surface from "../components/Surface";
import PaymentIcon from '@material-ui/icons/Payment';
import DateFormat from "../components/DateFormat";
import NumberFormat from 'react-number-format';
import { CfdisListCallbacks, CfdiNameColumnTitle, CfdiNameColumnValue } from "../cfdi/CfdisList";
import { AppContext } from "../context/AppContext";
import { CfdiDescription, CfdiAmounts } from "../cfdi/CfdiComponents";

interface SelectCfdisToPayViewProps {
    infoProvider: PaymentData;
    next(request: CfdisSelected): any;
    onCancel(): any;
    cfdisSelected?: CfdisSelected;
    fromPaymentOrder?: boolean;
}

export default function SelectCfdisToPayView(props: SelectCfdisToPayViewProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CfdiPayment[]>([]);
    const [selectedCfdis, setSelectedCfdis] = useState<string[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const load = () => {
        setStatus("loaded");
        if (props.fromPaymentOrder != null && props.fromPaymentOrder && props.cfdisSelected != null) {
            setData(props.cfdisSelected.cfdis);
            setSelectedCfdis([]);
            setStatus("loaded");
        } else {
            getTenantCfdisByProvider(context.session!.tenant!.id, props.infoProvider.provider_id, { currency: props.infoProvider.currency, payment_method: props.infoProvider.payment_method } as CfdisByProviderParams).then((response) => {
                setData(response.items.map(item => item as CfdiPayment));
                setSelectedCfdis([]);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }

    useEffect(load, [props]);

    const onConfirm = () => {
        if (selectedCfdis.length > 0) {
            let selection = { cfdis: [] } as CfdisSelected;
            selectedCfdis.forEach((cfdiId) => {
                let cfdiTemp = data.find((cfdi) => cfdi.id === cfdiId);
                if (cfdiTemp) {
                    selection.cfdis.push({ ...cfdiTemp, amount: cfdiTemp.amount } as CfdiPayment);
                }
            });
            let totalBalance = selection.cfdis.reduce((sum, cfdi) => sum + cfdi.metadata.balance, 0);
            if (Math.round(totalBalance * 100) < Math.round(props.infoProvider.amount * 100)) {
                setWarning(translate("payments_cfdi.warning_amount_balance_incorrect") as string);
            } else {
                props.next(selection);
            }
        } else {
            setWarning(translate("payments_cfdi.warning_empty_list") as string);
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const handleCheck = (cfdi: Cfdi) => {
        let items = selectedCfdis.length === 0 ? ([] as string[]) : selectedCfdis.join(",").split(",");
        const index = selectedCfdis.indexOf(cfdi.id);
        if (index < 0) {
            items.push(cfdi.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedCfdis(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelectedCfdis(items);
    };

    const onClickedOptions = (cfdi: Cfdi) => {};

    function callbacks(): CfdisListCallbacks {
        return {
            handleCheck: handleCheck,
            handleAllChecks: handleAllChecks,
            onClickedOptions: onClickedOptions
        };
    }

    const getColumns = () => {
        const columns = [
            {
                title: (
                    <CfdiNameColumnTitle
                        total={data.length}
                        selected={selectedCfdis.length}
                        callbacks={callbacks()}
                        showChecks={true} />
                ),
                converter: (cfdi) => (
                    <CfdiNameColumnValue
                        cfdi={cfdi}
                        checked={selectedCfdis.indexOf(cfdi.id) >= 0}
                        callbacks={callbacks()}
                        showChecks={true} />
                ),
                fullWidth: true,
                xs: "auto",
                sm: "auto",
                md: "auto",
                lg: "auto",
                xl: "auto",
            },
            {
                title: translate("payments_cfdi.input.payment_date") as string,
                converter: (cfdi) => (
                    <DateFormat date={cfdi.date} format="DD/MM/YYYY" />
                ),
                fullWidth: true,
                xs: false,
                sm: true,
                md: true,
                lg: 2,
                xl: 1
            },
            {
                title: translate("cfdis.columns.comprobante") as string,
                converter: (cfdi) => (
                    <CfdiDescription cfdi={cfdi} />
                ),
                fullWidth: true,
                xs: true,
                sm: 3,
                md: 3,
                lg: true,
                xl: true
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("cfdis.columns.totals")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <CfdiAmounts cfdi={cfdi} showBalance />
                ),
                fullWidth: true,
                xs: false,
                sm: 3,
                md: 3,
                lg: 2,
                xl: 2
            },
            props.fromPaymentOrder ? {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("payments_cfdi.amount_payment_currency")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <Typography variant="body2" component="h6" align="right">
                        <NumberFormat value={cfdi.payment_amount} prefix="$ " suffix={` ${cfdi.payment_currency}`} decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                    </Typography>
                ),
                fullWidth: true,
                xs: 3,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
            } : undefined
        ] as GridableColumn<CfdiPayment>[];
        return columns.filter(col => !!col);
    };

    return (
        <Surface title={translate("payments_cfdi.title_select_payments") as string} subtitle={props.infoProvider.provider_name} icon={<PaymentIcon />} className="PaperPagination PaperTabContainer"
            titleActions={(props.fromPaymentOrder == null || props.fromPaymentOrder === false) ? (
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs>
                                <Box pr={1}>
                                    <Typography variant="h6" color="secondary">
                                        <b>{translate("payments_cfdi.total_paid") as string}</b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" color="secondary">
                                    <b><NumberFormat value={props.infoProvider.amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator="," /></b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : undefined}>
            <Gridable
                items={data}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("payments_cfdi.empty_list") as string}
                columns={getColumns()} />
            <Box pt={2} px={2}>
                <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                    <Grid item xs="auto">
                        <Button variant="contained" color="primary" size="large" onClick={onConfirm} >
                            {translate("buttons.next")}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button variant="text" color="primary" size="large" onClick={props.onCancel}>
                            {translate("buttons.cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Surface>
    );
}