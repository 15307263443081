import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, IconButton, Divider, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ZipDownloadsIcon from "@material-ui/icons/CloudDownloadTwoTone";

import { listZipDownloads, deleteZipDownload } from "../api/ZipDownloadAPI";
import { AppContext } from "../context/AppContext";
import { ZipDownload, ZipDownloads, ZipDownloadsQueryParams } from "../model/ZipDownload";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import ZipDownloadMenu from "./ZipDownloadMenu";
import DateFormat from "../components/DateFormat";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";

export default function ZipDownloadsList() {
    const context = useContext(AppContext);
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ZipDownloads>();
    const [workingParams, setWorkingParams] = useState<ZipDownloadsQueryParams>({
        search: search,
    } as ZipDownloadsQueryParams);

    const [zipDownload, setZipDownload] = useState<ZipDownload>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [dialog, setDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as ZipDownloadsQueryParams;
        setWorkingParams(params);

        listZipDownloads(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (zipDownload: ZipDownload) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setZipDownload(zipDownload);
    };

    const onDelete = () => {
        setAnchorEl(null);
        if (!zipDownload) return;
        setDialog(true);
    };

    const confirmDelete = () => {
        if (!zipDownload) return;

        setDialog(false);
        setDeleting(true);
        deleteZipDownload(context.session!.tenant!.id, zipDownload.id).then(() => {
            setSuccess(translate("zip_downloads.delete.success", { name: zipDownload.name }) as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setDeleting(false);
            setZipDownload(undefined);
        })
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setZipDownload(undefined);
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    return (
        <Pagination title={translate("zip_downloads.title")} icon={<ZipDownloadsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("zip_downloads.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("zip_downloads.empty") as string}
                columns={[
                    {
                        title: translate("zip_downloads.file_name") as string,
                        converter: (zipDownload) => (
                            <Ellipsis text={zipDownload.name} lenght={100} uppercased={false} />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("zip_downloads.user") as string,
                        converter: (zipDownload) => (
                            <Ellipsis text={zipDownload.user ? `${zipDownload.user.first_name} ${zipDownload.user.last_name}` : "---"} lenght={100} uppercased={false} />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("zip_downloads.created_at") as string,
                        converter: (zipDownload) => (
                            <DateFormat date={zipDownload.created_at} format="YYYY-MM-DD HH:mm:ss" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (zipDownload) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(zipDownload)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {zipDownload && anchorEl &&
                <ZipDownloadMenu zipDownload={zipDownload}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onDelete={onDelete} />
            }
            {dialog && zipDownload && (
                <ConfirmationPopup title={translate("zip_downloads.delete.title") as string}
                    message={translate("zip_downloads.delete.text", { name: zipDownload.name })}
                    onClose={() => setDialog(false)}
                    doAction={confirmDelete}
                    submitting={deleting}
                    color="secondary"
                    button={translate("buttons.delete") as string} />
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={deleting} />
        </Pagination>
    );
}