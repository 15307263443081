import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ExpedientTemplate } from "../model/ExpedientTemplate";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentationTwoTone';
import { Entity } from "draft-js";

interface ProviderSpecialExpedientMenuProps {
    provider: Entity;
    anchor: HTMLElement | null;
    onClose(): any;
    onValidate(provider: Entity): any;
}

export default function ProviderSpecialExpedientMenu(props: ProviderSpecialExpedientMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-special-expedient-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["SpecialExpedientUpdate"]) &&
                <MenuItem button onClick={()=> props.onValidate(props.provider)} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.expedient_review.review")}</Typography>
                </MenuItem>
            }
        </Menu>);
}