import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import queryString from "query-string";
import translate from "../i18n/Translator";

import { getValidatorDashboard } from "../api/MeAPI";
import { exportCfdiFiles } from "../api/TenantCfdiApi"
import Progress from "../components/Progress";
import ProvidersDashboard from "./ProvidersDashboard";
import { ValidatorDashboardResponse } from "../model/Dashboard";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import BlackListWidget from "./BlackListWidget";
import { AppContext } from "../context/AppContext";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomWidgets from "./CustomWidgets";
import Widgets from "./Widgets";

export default function ValidatorDashboard() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ValidatorDashboardResponse>();
    const qs = queryString.parse(window.location.search);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();

    const load = () => {
        setStatus("loading");
        getValidatorDashboard().then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });

        let download = typeof qs["download"] === "string" ? qs["download"] as string : "";
        if (download !== "") {
            exportCfdiFiles(context.session!.tenant!.id, download).then((response) => {
                if (response.url) {
                    setExportResult(response);
                }
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }
    useEffect(load, [context.session]);

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded" || data === undefined) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid container justify="center" spacing={3}>
            {data.widgets && data.widgets?.items?.filter(e => "billing_data" !== e.type)?.length > 0 && (
                <Grid item xs={12}>
                    <CustomWidgets tenant={context.session!.tenant!} widgets={data.widgets.items} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Widgets />
            </Grid>
            <Grid item xs={12}>
                <BlackListWidget />
            </Grid>
            <Grid item xs={12}>
                <ProvidersDashboard summary={data.providers} />
            </Grid>
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("dashboard.download.title") as string}
                    message={translate("dashboard.download.message") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )
            }
        </Grid>
    );
}
