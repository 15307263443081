import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { list } from "../api/SystemlogApi";
import { SystemLog, SystemLogQueryParams, SystemLogList, Groups, EventsOwner, Statuses } from "../model/SystemLog";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString, formatDateToString, concatDates } from "../components/DateFormat";
import { FormControl, Select, Box, Grid, IconButton } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import Brightness1Icon from '@material-ui/icons/Brightness1';

export default function ReportSystemLogView() {
  const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const sDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
    const paramsFromQueryString = (): SystemLogQueryParams => {
        return {
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "started_at": typeof qs["started_at"] === "string" ? qs["started_at"] as string : formatDateToString(sDate)+" - "+formatDateToString(new Date()),
            "ended_at": typeof qs["ended_at"] === "string" ? qs["ended_at"] as string : "",
            "event_group": typeof qs["event_group"] === "string" ? qs["event_group"] as string : "",
            "event": typeof qs["event"] === "string" ? qs["event"] as string : "",
            "ip": typeof qs["ip"] === "string" ? qs["ip"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "user_email": typeof qs["user_email"] === "string" ? qs["user_email"] as string : "",
            "provider_name": typeof qs["provider_name"] === "string" ? qs["provider_name"] as string : "",
            "document_name": typeof qs["document_name"] === "string" ? qs["document_name"] as string : "",
            "document_number": typeof qs["document_number"] === "string" ? qs["document_number"] as string : "",
        } as SystemLogQueryParams;
    };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<SystemLogList>();
    const [params, setParams] = useState<SystemLogQueryParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);
    const paramsToFilters = (): Filter[] => {
        let started_at_temp = params.started_at.split(" - ");
        let ended_at_temp = params.ended_at.split(" - ");
        return [
            { columnName: 'identifier', value: params.identifier },
            { columnName: 'started_at', value: concatDates(started_at_temp[0], started_at_temp[1]) },
            { columnName: 'ended_at', value: concatDates(ended_at_temp[0], ended_at_temp[1]) },
            { columnName: 'event_group', value: params.event_group },
            { columnName: 'event', value: params.event },
            { columnName: 'ip', value: params.ip },
            { columnName: 'status', value: params.status },
            { columnName: 'user_email', value: params.user_email },
            { columnName: 'provider_name', value: params.provider_name },
            { columnName: 'document_name', value: params.document_name },
            { columnName: 'document_number', value: params.document_number },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
      { columnName: 'identifier', wordWrapEnabled: true, width: 80 },
      { columnName: 'user_email', width: 100 },
      { columnName: 'provider_name', width: 100 },
      { columnName: 'event_group', wordWrapEnabled: true, width: 100 },
      { columnName: 'event', wordWrapEnabled: true, width: 100 },
      { columnName: 'status', wordWrapEnabled: true, width: 80 },
      { columnName: 'started_at', wordWrapEnabled: true, width: 150 },
      { columnName: 'ended_at', wordWrapEnabled: true, width: 150 },
      { columnName: 'document_number', wordWrapEnabled: true, width: 100 },
      { columnName: 'document_name', wordWrapEnabled: true, width: 100 },
      { columnName: 'ip', wordWrapEnabled: true, width: 100 },
      { columnName: 'data_sent', wordWrapEnabled: true, width: 150 },
      { columnName: 'comments', wordWrapEnabled: true, width: 200 },
    ]) as any;

    const [leftColumns] = useState(['identifier', 'provider_name', 'user_email', 'event_group', 'event', 'status']) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();
        list(context.session!.tenant!.id, pageSize, pageSize * page, params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if(filter.value !== undefined){
                switch(filter.columnName){
                    case "identifier": 
                        temp.identifier = filter.value;
                        break;
                    case "started_at": 
                        let datesS = filter.value.split(" - ");
                        if(datesS.length === 2) {
                            temp.started_at = formatDateString(datesS[0])+" - "+formatDateString(datesS[1]);
                        } else {
                            temp.started_at = "";
                        }
                        break;
                    case "ended_at": 
                        let datesE = filter.value.split(" - ");
                        if(datesE.length === 2) {
                            temp.ended_at = formatDateString(datesE[0])+" - "+formatDateString(datesE[1]);
                        } else {
                            temp.ended_at = "";
                        }
                        break;
                    case "event_group": 
                        temp.event_group = filter.value;
                        break;
                    case "event": 
                        temp.event = filter.value;
                        break;
                    case "ip": 
                        temp.ip = filter.value;
                        break;
                    case "status": 
                        temp.status = filter.value;
                        break;
                    case "user_email": 
                        temp.user_email = filter.value;
                        break;
                    case "provider_name": 
                        temp.provider_name = filter.value;
                        break;
                    case "document_name": 
                        temp.document_name = filter.value;
                        break;
                    case "document_number": 
                        temp.document_number = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["identifier"] = params.identifier || "";
        qs["started_at"] = params.started_at || "";
        qs["ended_at"] = params.ended_at || "";
        qs["event_group"] = params.event_group || "";
        qs["event"] = params.event || "";
        qs["ip"] = params.ip || "";
        qs["status"] = params.status || "";
        qs["user_email"] = params.user_email || "";
        qs["provider_name"] = params.provider_name || "";
        qs["document_name"] = params.document_name || "";
        qs["document_number"] = params.document_number || "";
        qs["page"] = "0";
        
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onDownloadExcel = () => {
        
    }

    const getGroup = (systemLog: SystemLog) => {
      return translate(`reports.system_log.groups.${systemLog.event_group}`) as string;
    };
  
    const getEvent = (systemLog: SystemLog) => {
      return translate(`reports.system_log.events.${systemLog.event}`) as string;
    };

    const getComments = (systemLog: SystemLog) => {
        if(systemLog.event === "EMAIL_SENDED"){
            if(systemLog.comments && systemLog.comments.includes("|")){
                let comment = systemLog.comments.split("|");
                let notification = translate("notification_type."+comment[0]) as string;
                return notification.concat(" ", comment[1]);
            }
            return translate("notification_type."+systemLog.comments) as string;
        }
      return systemLog.comments;
    };

  const columns = [
      {
          name: 'identifier',
          title: translate('reports.system_log.columns.identifier') as string
      },
      {
          name: 'user_email',
          title: translate('reports.system_log.columns.user') as string,
      },
      {
          name: 'provider_name',
          title: translate("reports.system_log.columns.provider") as string,
      },
      {
          name: 'event_group',
          title: translate('reports.system_log.columns.event_group') as string,
          getCellValue: (row: any) => getGroup(row)
      },
      {
          name: 'event',
          title: translate("reports.system_log.columns.event") as string,
          getCellValue: (row: any) => getEvent(row)
      },
      {
          name: 'status',
          title: translate("reports.system_log.columns.status") as string,
      },
      {
          name: 'started_at',
          title: translate("reports.system_log.columns.started_at") as string,
      },
      {
          name: 'ended_at',
          title: translate('reports.system_log.columns.ended_at') as string,
      },
      {
          name: 'document_number',
          title: translate('reports.system_log.columns.document_number') as string,
      },
      {
          name: 'document_name',
          title: translate("reports.system_log.columns.document_name") as string,
      },
      {
          name: 'ip',
          title: translate("reports.system_log.columns.ip") as string,
      },
      {
          name: 'data_sent',
          title: translate("reports.system_log.columns.data_sent") as string,
      },
      {
        name: 'comments',
        title: translate("reports.system_log.columns.comments") as string,
      },
  ];

  const StatusTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>
            {value.row.status === "OK" ? 
                <Brightness1Icon style={{ color:"#32CD32", "width": 10, "height": 10 }}/> 
            : value.row.status === "ERROR" ? 
                <Brightness1Icon style={{ color:"#FF0000", "width": 10, "height": 10 }} /> 
            :   <Brightness1Icon style={{ color:"#FFFF00", "width": 10, "height": 10 }} />
            }
            
        </>;
    }} {...props} />
);

const DataSentTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>
            {isFile(value.row.event) && !!value.row?.data_sent ? 
                <a href={value.row.data_sent} target="_blank" rel="noopener noreferrer">
                    <small>{translate("buttons.view_document")}</small>
                </a>
            : value.row.data_sent
            }
        </>;
    }} {...props} />
);

const UserEmailTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>
            <Tooltip title={value.row.user_email}>
                <div>{value.row.user_email && value.row.user_email.length > 15 ? 
                    `${value.row.user_email.substring(0, Math.min(value.row.user_email.length, 15) - 3)}...` 
                    : value.row.user_email} </div>
            </Tooltip>
            </>;
    }} {...props} />
);

const ProviderNameTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>{value.row.provider_name ? 
            <Tooltip title={value.row.provider_name}>
                <div>{value.row.provider_name.length > 15 ? 
                    `${value.row.provider_name.substring(0, Math.min(value.row.provider_name.length, 15) - 3)}...` 
                    : value.row.provider_name} </div>
            </Tooltip>
            : undefined }</>;
    }} {...props} />
);

const CommentsProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>{value.row.comments ? 
            <Tooltip title={value.row.comments}>
                <div>{getComments(value.row)}</div>
            </Tooltip>
            : undefined }</>;
    }} {...props} />
);

const NoDocProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>{value.row.document_number ? 
            <Tooltip title={value.row.document_number}>
                <div>{value.row.document_number}</div>
            </Tooltip>
            : undefined }</>;
    }} {...props} />
);

const DocIdProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
        return <>{value.row.document_name ? 
            <Tooltip title={value.row.document_name}>
                <div>{value.row.document_name}</div>
            </Tooltip>
            : undefined }</>;
    }} {...props} />
);

const isFile = (event: string) => {
    return event === "UPLOAD_CFDI_FAIL"
        || event === "UPLOAD_CFDI_EMAIL_FAIL"
        || event === "PAYMENT_CFDI_SENDED_TO_REVIEW"
        || event === "CFDI_DELETED"
        || event === "CFDIS_DELETED";
};

const NormalTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider {...props} />
);

const GroupFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
    const onChange = (event: any) => {
        const { value: targetValue } = event.target;
        if (targetValue.trim() === '') {
            params.onValueChange("");
            return;
        }
        params.onValueChange(targetValue);
    };

    return (
        <Box px={1}>
        <FormControl >
            <Select native style={{
            fontSize: "10px"
        }}
                labelId="is_default"
                id="is_default"
                value={params.value ? params.value : ''}
                onChange={onChange} >
                    <option key={"ALL"} value={""}>{""}</option>
                {Groups.map((value) => {
                    return <option key={value} value={value}>{translate("reports.system_log.groups."+value)}</option>
                })}
            </Select>
        </FormControl>
        </Box>
    );
};

const EventFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
  const onChange = (event: any) => {
      const { value: targetValue } = event.target;
      if (targetValue.trim() === '') {
          params.onValueChange("");
          return;
      }
      params.onValueChange(targetValue);
  };

  return (
      <Box px={1}>
      <FormControl >
          <Select native style={{
          fontSize: "10px"
      }}
              labelId="is_default"
              id="is_default"
              value={params.value ? params.value : ''}
              onChange={onChange} >
                  <option key={"ALL"} value={""}>{""}</option>
              {EventsOwner.map((value) => {
                  return <option key={value} value={value}>{translate("reports.system_log.events."+value)}</option>
              })}
          </Select>
      </FormControl>
      </Box>
  );
};

const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
  const onChange = (event: any) => {
      const { value: targetValue } = event.target;
      if (targetValue.trim() === '') {
          params.onValueChange("");
          return;
      }
      params.onValueChange(targetValue);
  };

  return (
      <Box px={1}>
      <FormControl >
          <Select native style={{
          fontSize: "10px"
      }}
              labelId="is_default"
              id="is_default"
              value={params.value ? params.value : ''}
              onChange={onChange} >
                  <option key={"ALL"} value={""}>{""}</option>
              {Statuses.map((value) => {
                  return <option key={value} value={value}>{translate("reports.system_log.statuses."+value)}</option>
              })}
          </Select>
      </FormControl>
      </Box>
  );
};

const textColumns = ['identifier', 'user_email', 'ip', 'provider_name', 'document_number', 'document_name'];

const filtersExclude = ['comments'];

const customPlugins = [
    <StatusTypeProvider for={['status']} editorComponent={StatusFilterEditor}/>,
    <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    <NormalTypeProvider for={['event']} editorComponent={EventFilterEditor} />,
    <NormalTypeProvider for={['event_group']} editorComponent={GroupFilterEditor} />,
    <DataSentTypeProvider for={['data_sent']} editorComponent={NoFilterEditor} />,
    <UserEmailTypeProvider for={['user_email']} />,
    <ProviderNameTypeProvider for={['provider_name']} />,
    <CommentsProvider for={['comments']} />,
    <DocIdProvider for={['document_name']} />,
    <NoDocProvider for={['document_number']} />,
];

const dateColumns = ['started_at', 'ended_at'];

    return (
        <Surface title={translate("reports.system_log.title") as string} 
        className="PaperPagination"
        icon={<AssignmentIcon />} 
        titleActions={
            <Grid >
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                            <RefreshTwoToneIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        }>
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns ? columns : []}
                columnsFormat={columnsFormat}
                onClickRow={() => {}}
                onClickedOptions={() => {}}
                dateColumns={dateColumns}
                leftColumns={leftColumns}
                textColumns={textColumns}
                filters={filters}
                customFormatColumns={customPlugins} 
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                excelFile={translate("reports.system_log.excel_file") as string}
                heightTablePX={450}
                noUseId={true}
                showTimeInDates={true}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {openConfirmDialogExportExcel && (
                <CfdisConfirmPopup
                    doAction={onDownloadExcel}
                    onClose={() => setOpenConfirmDialogExportExcel(false)}
                    title={translate("cfdis.popup.confirm_dialog_excel.title") as string}
                    message={translate("cfdis.popup.confirm_dialog_excel.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog_excel.button") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description_archive") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
        </Surface>
    );
}
