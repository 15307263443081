import React from "react";
import DashboardIcon from "@material-ui/icons/DashboardTwoTone";
import GroupIcon from "@material-ui/icons/GroupTwoTone";
import AccountCircleIcon from "@material-ui/icons/AccountCircleTwoTone";
import SecurityIcon from "@material-ui/icons/SecurityTwoTone";
import EmailIcon from "@material-ui/icons/EmailTwoTone";
import WorkIcon from "@material-ui/icons/WorkTwoTone";
import ProvidersIcon from "@material-ui/icons/BusinessTwoTone";
import SettingsIcon from "@material-ui/icons/SettingsTwoTone";
import ExpedientIcon from "@material-ui/icons/AllInboxTwoTone";
import EfosFilesIcon from "@material-ui/icons/DescriptionTwoTone";
import Cff69FilesIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import BillingIcon from "@material-ui/icons/ReceiptTwoTone";
import ProviderEfosIcon from "@material-ui/icons/LibraryAddCheckTwoTone";
import ProviderBaseIcon from "@material-ui/icons/BallotTwoTone";
import CfdisToSendIcon from "@material-ui/icons/SendTwoTone";
import MyCfdisIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import CfdisInProcessIcon from "@material-ui/icons/FindInPageTwoTone";
import ToAuthorizeIcon from "@material-ui/icons/GavelTwoTone";
import AuthorizedIcon from "@material-ui/icons/LibraryAddCheckTwoTone";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheckTwoTone";
import PostAddIcon from '@material-ui/icons/PostAddTwoTone';
import ReceiptIcon from "@material-ui/icons/ReceiptTwoTone";
import ArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUserTwoTone';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnTwoTone';
import LocalAtmIcon from '@material-ui/icons/LocalAtmTwoTone';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import PaymentIcon from '@material-ui/icons/PaymentTwoTone';
import PaymentOrderPendingIcon from '@material-ui/icons/AddToPhotosTwoTone';
import PurchaseOrdersIcon from "@material-ui/icons/ShopTwoTwoTone";
import WarehouseDeliveriesIcon from "@material-ui/icons/LocalShippingTwoTone";
import VpnKeyIcon from "@material-ui/icons/VpnKeyTwoTone";
import ListAltIcon from '@material-ui/icons/ListAltTwoTone';
import ErpFormIcon from "@material-ui/icons/SettingsTwoTone";
import OpenIcon from "@material-ui/icons/FolderOpenTwoTone";
import ConnectorIcon from "@material-ui/icons/SettingsInputHdmiTwoTone";
import PluginIcon from "@material-ui/icons/AccountTreeTwoTone";
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import AlarmTwoToneIcon from '@material-ui/icons/AlarmTwoTone';
import MessageIcon from '@material-ui/icons/EmailTwoTone';
import ToSendIcon from "@material-ui/icons/SendTwoTone";
import NotificationGroupsIcon from "@material-ui/icons/AllInbox";
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import ViewList from '@material-ui/icons/ViewList';
import ExpenseAccounIcon from '@material-ui/icons/MenuBook';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import TenantAccountTypeIcon from '@material-ui/icons/AccountTree';

import Dashboard from "../dashboard/Dashboard";
import Users from "../users/Users";
import UserProfile from "../users/UserProfile";
import UserSecurity from "../users/UserSecurity";
import UserForm from "../users/UserForm";
import VerifyEmail from "../auth/VerifyEmail";
import Tenants from "../tenants/Tenants";
import TenantForm from "../tenants/TenantForm";
import ProvidersManagementView from "../providers/ProvidersManagementView";
import ProvidersControlReceptionView from "../providers/ProvidersControlReceptionView";
import ProvidersEfosView from "../providers/ProvidersEfosView";
import ProvidersExpedientsView from "../providers/ProvidersExpedientsView";
import ExpedientSettingsForm from "../expedients/ExpedientSettings";
import ExpedientTemplates from "../expedient_template/ExpedientTemplates";
import ExpedientTemplatesCreateEdit from "../expedient_template/ExpedientTemplatesCreateEdit";
import ProviderForm from "../providers/ProviderForm";
import ProviderOverview from "../providers/ProviderOverview";
import EfoFiles from "../efoFiles/EfoFiles";
import Cff69FilesList from "../cff69Files/Cff69FilesList";
import ExpedientProvider from "../expedients/ExpedientProvider";
import TenantBankAccountTypesView from "../tenantbankaccounttypes/TenantBankAccountTypesView";

import BudgetsView from "../budgets/BudgetsView";
import BudgetForm from "../budgets/BudgetForm";
import BudgetOverview from "../budgets/BudgetOverview";
import BudgetsPlanningIcon from '@material-ui/icons/ListAltOutlined'
import BudgetsExecuteIcon from '@material-ui/icons/ArrowRightAltOutlined';
import BudgetsArchiveIcon from '@material-ui/icons/ArchiveOutlined';

import PaymentForm from "../billing/PaymentForm";
import PaymentManualForm from "../billing/PaymentManualForm";
import Billings from "../billing/Billings";
import { AdminCfdiForm, PaymentCfdiForm, ProviderCfdiForm, ReceptorCfdiForm, ExpensesAccountCfdiForm, ExpensesAccountHonorariumCfdiForm, PurchaseOrderCfdiForm, WarehouseDeliveryCfdiForm } from "../cfdis/CfdiForm";
import ShippingTemplates from "../shipping_templates/ShippingTemplates";
import ShippingTemplateForm from "../shipping_templates/ShippingTemplateForm";
import CfdiOverview from "../cfdi/CfdiOverview";
import ReceptionRuleForm from "../reception_rules/ReceptionRuleForm";
import ReceptionRuleEditTabs from "../reception_rules/ReceptionRuleEditTabs";
import ReceptionSpecificRuleForm from "../specific_reception_rules/SpecificReceptionRuleForm";
import RceptionSpecificRuleList from "../specific_reception_rules/ListSpecificReceptionRules";
import ReceptionSpecificRuleEditTabs from "../specific_reception_rules/SpecificReceptionRuleEditTabs";
import PaymentsPendingCfdiView from "../payment/PaymentsPendingCfdiView";
import PaymentsArchivedView from "../payment/PaymentsArchivedView";
import PaymentOrderArchivedView from "../payment_order/PaymentOrderArchivedView";
import PaymentsPendingReview from "../payment/PaymentsPendingReview";
import PaymentsPendingReviewOverView from "../payment/PaymentsPendingReviewOverView";
import PaymentOverView from "../payment/PaymentOverView";
import PaymentOrderDetails from "../payment_order/PaymentOrderDetails";
import BankAccountsAdminView from "../bankAccounts/BankAccountsAdminView";
import BankAccountsAdminForm from "../bankAccounts/BankAccountsAdminForm";
import CfdisView from "../cfdis/CfdisView";
import ProviderAnnouncementsView from "../Announcement/ProviderAnnouncementsView";
import VendorsView from "../vendors/VendorsView";
import CurrenciesList from "../currencies/Currencies";

import { OpenPurchaseOrdersList, ArchivePurchaseOrdersList } from "../purchase_orders/PurchaseOrders";
import PurchaseOrderView from "../purchase_orders/PurchaseOrderView";
import { OpenWarehouseDeliveriesList, ArchiveWarehouseDeliveriesList } from "../warehouse_deliveries/WarehouseDeliveries";
import WarehouseDeliveryView from "../warehouse_deliveries/WarehouseDeliveryView";
import KeyPairs from "../keypairs/KeyPairs";
import KeyPairForm from "../keypairs/KeyPairForm";
import TenantConfigurationSAPForm from "../tenant_configurations/TenantConfigurationSAPForm";
import TenantConfigurationsForm from "../tenant_configurations/TenantConfigurationsForm";

import Workflows from "../workflows/Workflows";
import WorkflowForm from "../workflows/WorkflowForm";
import WorkflowEditForm from "../workflows/WorkflowEditForm";
import AssessmentIcon from '@material-ui/icons/AssessmentTwoTone';
import NoteAddIcon from '@material-ui/icons/NoteAddTwoTone';
import ClarificationIcon from '@material-ui/icons/ContactMailTwoTone';

import ReportCxPView from "../reports/ReportCxPView";
import ReportSystemLogView from "../reports/ReportSystemLogView";
import ReportIcsoeSisubView from "../reports/ReportIcsoeSisub";
import SEPendingDocsByProvider from "../reports/SEPendingDocsByProvider";
import ReportLatePaymentCfdis from "../reports/ReportLatePaymentCfdis";
import ReportTermsConditions from "../reports/ReportTermsConditionsProvider";
import ReportDueInvoices from "../reports/ReportDueInvoices";
import ReportAdvancePayment from "../reports/ReportAdvancePayment";
import AdvancePaymentIcon from '@material-ui/icons/ReceiptTwoTone';

import ProviderClassificationsIcon from '@material-ui/icons/ClassTwoTone';
import ProviderClassificationsView from "../provider_classifications/ProviderClassificationsView";
import ProviderClassificationForm from "../provider_classifications/ProviderClassificationForm"

import ProviderExpedientHistoryDetail from "../providers/ProviderExpedientHistoryDetail";

import CustomReportsView from "../reports/CustomReportsView";

import Connectors from "../connectors/Connectors";
import ConnectorForm from "../connectors/ConnectorForm";
import BallotIcon from "@material-ui/icons/BallotTwoTone";

import AssignmentIcon from '@material-ui/icons/Assignment';
import Plugins from "../plugins/Plugins";
import PluginForm from "../plugins/PluginForm";

import WidgetIcon from "@material-ui/icons/WidgetsTwoTone";
import Widgets from "../widgets/Widgets";
import WidgetsForm from "../widgets/WidgetForm";

import IntegrationLogIcon from '@material-ui/icons/RestorePageTwoTone';
import IntegrationLogs from '../integration_logs/IntegrationLogView';
import NotificationsIcon from '@material-ui/icons/Notifications';

import ZipUploads from "../zip_uploads/ZipUploads";
import ZipUploadsIcon from "@material-ui/icons/CloudUploadTwoTone";

import ZipDownloads from "../zip_downloads/ZipDownloads";
import ZipDownloadsIcon from "@material-ui/icons/CloudDownloadTwoTone";

import TenantModulesForm from "../tenant_modules/TenantModulesForm";
import TenantModulesIcon from "@material-ui/icons/ViewCompactTwoTone";

import PayrollIcon from "@material-ui/icons/AccountBoxTwoTone";
import PayrollArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import PayrollUploaderIcon from "@material-ui/icons/CloudUploadTwoTone";
import { ArchivePayroll } from "../payrolls/Payrolls";
import PayrollUploader from "../payrolls/PayrollUploader";

import EmployeeIcon from "@material-ui/icons/PeopleAltTwoTone";
import EmployeeGroupsIcon from '@material-ui/icons/GroupWorkTwoTone';
import Employees from "../employees/Employees";
import EmployeeForm from "../employees/EmployeeForm";
import EmployeeOverview from "../employees/EmployeeOverview";

import ContributionsIcon from "@material-ui/icons/ReceiptTwoTone";
import ContributionsSummaryIcon from "@material-ui/icons/AssignmentTurnedInTwoTone";
import ContributionsPendingIcon from "@material-ui/icons/SendTwoTone";
import ContributionsArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import { AllContributions, PendingContributions, CompletedContributions } from "../contributions/Contributions";

import SocialContributionIcon from "@material-ui/icons/HomeWorkTwoTone";
import SocialContributions from "../social_contributions/SocialContributions";
import { ContributionsToValidateTenant } from "../contributions/ContributionsToValidate";
import NewSocialContributionForm from "../social_contributions/NewSocialContributionForm";
import SocialContributionForm from "../social_contributions/SocialContributionForm";

import SatTaxIcon from "@material-ui/icons/AccountBalanceTwoTone";
import SatContributions from "../sat_contributions/SatContributions";
import NewSatTaxForm from "../sat_contributions/NewSatContributionForm";
import SatTaxForm from "../sat_contributions/SatContributionForm";

import EmployerNumberIcon from "@material-ui/icons/SettingsInputSvideoTwoTone";
import EmployerNumberForm from "../configurations/EmployerNumberForm";
import SpecializedServiceDocuments from "../specializedservicedocument/SpecializedServiceDocuments";

import BankIcon from "@material-ui/icons/AccountBalance";
import BankForm from "../configurations/BankForm";

import CustomersIcon from "@material-ui/icons/ApartmentTwoTone";
import Customers from "../customers/Customers";
import CustomerForm from "../customers/CustomerForm";
import CustomerOverview from "../customers/CustomerOverview";
import ContractIcon from "@material-ui/icons/DescriptionTwoTone";
import ContractForm from "../contracts/ContractForm";

import CompaniesIcon from "@material-ui/icons/BusinessCenterTwoTone";
import Companies from "../companies/Companies";
import CompanyForm from "../companies/CompanyForm";

import CustomReports from "../custom_reports/CustomReports";

import TeamIcon from "@material-ui/icons/SupervisedUserCircleTwoTone";
import Teams from "../teams/Teams";
import TeamDetails from "../teams/TeamDetails";

import ProjectsView from "../projects/ProjectsListView";
import ProjectDetails from "../project/ProjectDetails";
import ProjectIcon from "@material-ui/icons/GroupWorkTwoTone";
import ProjectToStartIcon from "@material-ui/icons/AddToPhotosTwoTone";
import ProjectExecutionIcon from "@material-ui/icons/AccountTreeTwoTone";

import PaymentConditionsView from "../payment_conditions/PaymentConditionsView";
import PaymentConditionForm from "../payment_conditions/PaymentConditionForm";

import SECfdiToSendIcon from "@material-ui/icons/SendTwoTone";
import SECfdiPendingDocsIcon from "@material-ui/icons/RestorePageTwoTone";
import SECfdiArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import CfdiIssuedForm from "../cfdis_se/CfdiIssuedForm";
import CfdiIssuedPrepareForm from "../cfdis_se/CfdiIssuedPrepareForm";
import {
    CfdisIssuedArchivedView, CfdisIssuedPendingDocsView, CfdisIssuedToSendView,
    CfdisIssuedToPrepareView, CfdisIssuedArchivedCustomerView, CfdisIssuedArchivedTenantView,
    CfdisIssuedToPrepareAndSendView, CfdisIssuedValidatingView
} from "../cfdis_se/CfdisIssuedView";
import CfdiIssuedOverview from "../cfdis_se/CfdiIssuedOverview";
import ReportExpensesAccounts from "../reports/ReportExpensesAccounts";
import ClarificationList from "../clarifications/ClarificationList";
import ClarificationDetail from "../clarifications/ClarificationDetail";
import DigitalDocumentIcon from "@material-ui/icons/AllInboxTwoTone";
import DigitalDocumentForm from "../digital_documents/DigitalDocumentForm";
import DigitalDocuments from "../digital_documents/DigitalDocuments";
import RequisitionsView from "../Requisitions/RequisitionsView";
import TermsConditionsIcon from '@material-ui/icons/Policy';
import RequisitionForm from "../Requisitions/RequisitionForm";
import PurchaseOrderForm from "../Requisitions/PurchaseOrderForm";
import RequisitionOverview from "../Requisitions/RequisitionOverview";
import PresentToAllIcon from '@material-ui/icons/PresentToAllTwoTone';
import FindInPageIcon from '@material-ui/icons/FindInPageTwoTone';
import RefundsView from "../Refunds/RefundsView";
import BusinessIcon from '@material-ui/icons/Business';
import CurrencyIcon from '@material-ui/icons/MonetizationOnTwoTone';
import VendorsIcon from "@material-ui/icons/SupervisedUserCircleTwoTone";

import VpnKeyTwoTone from '@material-ui/icons/VpnKeyTwoTone';
import TenantSecurityForm from '../tenant_security/TenantSecurityForm';

import AuxiliarDocumentsIcon from "@material-ui/icons/FileCopyTwoTone";
import AuxiliarDocuments from "../auxiliardocuments/AuxiliarDocuments";
import AuxiliarDocumentForm from "../auxiliardocuments/AuxiliarDocumentForm";
import AuxiliarDocumentsPolitics from "../auxiliardocuments/AuxiliarDocumentsPolitics";
import StorageTwoToneIcon from '@material-ui/icons/StorageTwoTone';
import RequisitionsTypesView from "../Requisitions/RequisitionsTypesView";
import ReqisitionTypeForm from "../Requisitions/ReqisitionTypeForm";
import RequisitionTypeTemplateForm from "../Requisitions/RequisitionTypeTemplateForm";
import { SpecializedServiceDocumentsIcon, CustomerDocumentLogsIcon, ProrationTemplatesIcon, BudgetLinesTemplatesIcon } from "../components/Icons";
import { ShippingTemplatesIcon, WorkflowsIcon, CatalogsIcon, TenantProvidersUsers, DiotsIcon, DueInvoices, IntegrationsIcon } from "../components/Icons";
import Catalogs from "../catalogs/Catalogs";
import CatalogForm from "../catalogs/CatalogForm";
import Diots from "../diots/Diots";
import CustomerDocumentLogsList from "../customerdocumentlogs/CustomerDocumentLogPendingToSend";
import CalendarManagementView from '../calendarmanagement/CalendarManagementView';
import CalendarIcon from '@material-ui/icons/DateRangeTwoTone';
import IntegrationForm from "../integrations/IntegrationForm";
import NotificationGroups from "../notification_groups/NotificationGroups";
import NotificationGroupsForm from "../notification_groups/NotificationGroupsForm";
import POTenantConfigurationForm from "../purchase_orders/tenants_admin/POTenantConfigurationForm";

import FocaltecAnnouncementsList from "../focaltecannouncements/FocaltecAnnouncements";
import FocaltecAnnouncementsIcon from '@material-ui/icons/NotificationsTwoTone';
import FocaltecAnnouncementForm from "../focaltecannouncements/FocaltecAnnouncementForm";

import { LibraryBooks, SupervisorAccount } from "@material-ui/icons";
import ExtendedFieldsTemplatesCreateEdit from "../templates/ExtendedFieldsTemplatesCreateEdit";
import ExtendedFieldsTemplateList from "../templates/ExtendedFieldsTemplatesList";
import { EmojiPeopleIcon } from "../components/Icons";
import EmployeeVisitsList from "../employee_visits/EmployeeVisits";
import EmployeeVisitForm from "../employee_visits/EmployeeVisitForm";
import WebhooksList from '../webhooks/Webhooks';
import WebhookEventList from '../webhooks/WebhookEventsView';
import WebhookEventTries from '../webhooks/WebhookEventTriesView';
import WebhookIcon from '@material-ui/icons/SettingsInputAntenna';
import WebhooksForm from '../webhooks/WebhookForm';
import RolesAdmin from '../rolesadmin/RolesAdmin';
import RoleAdminForm from '../rolesadmin/RoleAdminForm';
import RelateLinesPoConceptsCfdi from "../cfdi/PurchaseOrder/RelateLinesPoConceptsCfdi";
import CostCenterList from "../costcenters/CostCenterList";
import CostCenterForm from "../costcenters/CostCenterForm";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CustomReportForm from "../customreports/CustomReportForm";
import { InfoPaymentPlanProviderInReview as PlanTaxStampInReview } from "../PlanTaxStamp/InfoPaymentPlanProvider";
import PlansProvider from "../PlanTaxStamp/PlansProvider";
import BuyPlanProvider from "../PlanTaxStamp/BuyPlanProvider";
import InfoPaymentPlanProvider from "../PlanTaxStamp/InfoPaymentPlanProvider";
import TaxStampMemberships from "../PlanTaxStamp/TaxStampMemberships";
import TaxStamp from "../PlanTaxStamp/TaxStamp";
import SignManifest from "../PlanTaxStamp/SignManifest";
import ConfigureCertificate from "../PlanTaxStamp/ConfigureCertificate";
import ProrationTemplateForm from "../prorationtemplates/ProrationTemplateForm";
import ProrationTemplatesList from "../prorationtemplates/ProrationTemplatesList";
import BudgetLinesTemplateList from "../budgetLinesTemplates/BudgetLinesTemplateList";
import BudgetLinesTemplateForm from "../budgetLinesTemplates/BudgetLinesTemplateForm";
import Expressions from '../expressions/Expressions';
import { ExpressionsIcon} from "../components/Icons";
import UserGroups from "../user_groups/UserGroups";
import UserGroupForm from "../user_groups/UserGroupForm";
import ProvidersSpecialExpedientsView from "../providers/ProvidersSpecialExpedientsView";

export const routes: RouteGenericItem[] = [
    {
        title: "dashboard.title",
        path: "/",
        icon: <DashboardIcon />,
        component: Dashboard,
        exact: true,
    },
    {
        title: "users.profile.title",
        path: "/profile",
        icon: <AccountCircleIcon />,
        component: UserProfile,
        hidden: true,
    },
    {
        title: "auth.verify_email.title",
        path: "/verify-email/:code",
        icon: <EmailIcon />,
        component: VerifyEmail,
        hidden: true,
    },
    {
        title: "users.security.title",
        path: "/security/provider-user/:tenantId/:providerId/:userId",
        icon: <SecurityIcon />,
        component: UserSecurity,
        hidden: true,
    },
    {
        title: "users.security.title",
        path: "/security",
        icon: <SecurityIcon />,
        component: UserSecurity,
        hidden: true,
    },
    {
        title: "users.title",
        path: "/providers/:providerId/users/new",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["OwnerProvidersUsersCreate", "ProviderUsersCreate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/providers/:providerId/users/:userId/edit",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["OwnerProvidersUsersUpdate", "ProviderUsersUpdate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/tenant/:tenantId/providers/:providerId/users/:userId/edit",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["TechnicalSupportRead"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/providers/:providerId/users",
        icon: <GroupIcon />,
        component: Users,
        grants: ["OwnerProvidersUsersRead", "ProviderUsersRead"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/customers/:customerId/users/new",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["OwnerCustomersUsersCreate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/customers/:customerId/users/:userId/edit",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["OwnerCustomersUsersUpdate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/customers/:customerId/users",
        icon: <GroupIcon />,
        component: Users,
        grants: ["OwnerCustomersUsersRead"],
        hidden: true,
    },

    {
        title: "requisitions.types.form.title",
        path: "/requisitions/types/new",
        icon: <StorageTwoToneIcon />,
        component: ReqisitionTypeForm,
        grants: ["RequisitionsCreate"],
        hidden: true,
    },
    {
        title: "requisitions.types.form.title",
        path: "/requisitions/types/:requisitionTypeId/edit",
        icon: <StorageTwoToneIcon />,
        component: ReqisitionTypeForm,
        grants: ["RequisitionsUpdate"],
        hidden: true,
    },
    {
        title: "requisitions.types.form.title",
        path: "/requisitions/types/:requisitionTypeId/template",
        icon: <StorageTwoToneIcon />,
        component: RequisitionTypeTemplateForm,
        grants: ["RequisitionsUpdate"],
        hidden: true,
    },

    {
        title: "requisitions.title",
        path: "/requisitions/:mode/new",
        icon: <PurchaseOrdersIcon />,
        component: RequisitionForm,
        grants: ["RequisitionsCreate"],
        hidden: true
    },
    {
        title: "requisitions.title",
        path: "/requisitions/:requisitionId/edit",
        icon: <PurchaseOrdersIcon />,
        component: RequisitionForm,
        grants: ["RequisitionsUpdate"],
        hidden: true
    },
    {
        title: "requisitions.title",
        path: "/requisitions/:requisitionId/:mode/edit",
        icon: <PurchaseOrdersIcon />,
        component: RequisitionForm,
        grants: ["RequisitionsUpdate"],
        hidden: true
    },
    {
        title: "requisitions.title",
        path: "/requisitions/:requisitionId/createOC",
        icon: <PurchaseOrdersIcon />,
        component: PurchaseOrderForm,
        grants: ["RequisitionsUpdate"],
        hidden: true
    },
    {
        title: "requisitions.title",
        path: "/requisitions/:requisitionId/details",
        icon: <PurchaseOrdersIcon />,
        component: RequisitionOverview,
        grants: ["RequisitionsRead"],
        hidden: true
    },
    {
        title: "cfdis.to_authorize.title",
        path: "/requisitions/to_send/message/:success",
        icon: <CfdisToSendIcon />,
        component: RequisitionsView,
        grants: ["RequisitionsRead"],
        hidden: true,
    },
    {
        title: "requisitions.form.quotation.payment_condition_id",
        path: "/payment-conditions/create/:noPartiallities",
        icon: <BankIcon />,
        component: PaymentConditionForm,
        grants: ["PaymentConditionsCreate"],
        hidden: true,
    },
    {
        title: "requisitions.form.quotation.payment_condition_id",
        path: "/payment-conditions/:success",
        icon: <BankIcon />,
        component: PaymentConditionsView,
        grants: ["PaymentConditionsRead"],
        hidden: true,
    },
    {
        title: "requisitions.form.quotation.payment_condition_id",
        path: "/payment-condition/edit/:paymentConditionId",
        icon: <BankIcon />,
        component: PaymentConditionForm,
        grants: ["PaymentConditionsUpdate"],
        hidden: true,
    },

    {
        title: "requisitions.title",
        icon: <ListAltIcon />,
        grants: ["RequisitionsRead", "RequisitionAdminRead", "RequisitionDeliveredErpRead"],
        items: [
            {
                title: "requisitions.to_send.title_routes",
                path: "/requisitions/to_send",
                icon: <CfdisToSendIcon />,
                component: RequisitionsView
            },
            {
                title: "requisitions.to_authorize.title_routes",
                path: "/requisitions/to_authorize",
                icon: <ToAuthorizeIcon />,
                component: RequisitionsView,
            },
            {
                title: "requisitions.authorized.title_routes",
                path: "/requisitions/authorized",
                icon: <PlaylistAddCheckIcon />,
                component: RequisitionsView,
            },
            {
                title: "requisitions.delivered_erp.title_routes",
                path: "/requisitions/delivered_erp",
                icon: <PresentToAllIcon />,
                component: RequisitionsView,
                grants: ["RequisitionDeliveredErpRead"],
            },
            {
                title: "requisitions.archive.title_routes",
                path: "/requisitions/archive",
                icon: <ArchiveIcon />,
                component: RequisitionsView
            },
            {
                title: "requisitions.types.title",
                path: "/requisitions/types",
                icon: <StorageTwoToneIcon />,
                component: RequisitionsTypesView,
                grants: ["RequisitionAdminRead"],
            },
            {
                title: "requisitions.form.quotation.payment_condition_id",
                path: "/payment-conditions",
                icon: <BankIcon />,
                component: PaymentConditionsView,
                grants: ["PaymentConditionsRead"],
            },
        ]
    },
    {
        title: "cfdi.new_cfdi",
        path: "/purchase-orders/:purchaseOrderId/cfdi",
        icon: <CfdisToSendIcon />,
        component: PurchaseOrderCfdiForm,
        grants: ["ProviderPurchaseOrdersUpdate"],
        hidden: true
    },
    "integration",
    {
        title: "purchase_orders.title",
        icon: <PurchaseOrdersIcon />,
        grants: ["PurchaseOrdersRead", "ProviderPurchaseOrdersRead"],
        items: [
            {
                title: "purchase_orders.open",
                path: "/purchase-orders/open",
                icon: <OpenIcon />,
                component: OpenPurchaseOrdersList
            },
            {
                title: "purchase_orders.single",
                path: "/purchase-orders/:purchaseOrderId",
                icon: <PurchaseOrdersIcon />,
                component: PurchaseOrderView,
                hidden: true
            },
            {
                title: "purchase_orders.archive",
                path: "/purchase-orders",
                icon: <ArchiveIcon />,
                component: ArchivePurchaseOrdersList
            },
        ]
    },
    {
        title: "cfdi.new_cfdi",
        path: "/warehouse-deliveries/:warehouseDeliveryId/cfdi",
        icon: <CfdisToSendIcon />,
        component: WarehouseDeliveryCfdiForm,
        grants: ["ProviderWarehouseDeliveriesUpdate"],
        hidden: true
    },
    {
        title: "warehouse_deliveries.title",
        icon: <WarehouseDeliveriesIcon />,
        grants: ["WarehouseDeliveriesRead", "ProviderWarehouseDeliveriesRead"],
        items: [
            {
                title: "warehouse_deliveries.open",
                path: "/warehouse-deliveries/open",
                icon: <OpenIcon />,
                component: OpenWarehouseDeliveriesList
            },
            {
                title: "warehouse_deliveries.single",
                path: "/warehouse-deliveries/:warehouseDeliveryId",
                icon: <WarehouseDeliveriesIcon />,
                component: WarehouseDeliveryView,
                hidden: true
            },
            {
                title: "warehouse_deliveries.archive",
                path: "/warehouse-deliveries",
                icon: <ArchiveIcon />,
                component: ArchiveWarehouseDeliveriesList
            },
        ]
    },
    {
        title: "cfdis.to_authorize.title",
        path: "/tenant/cfdis/receptor-to-authorize/message/:success",
        icon: <ToAuthorizeIcon />,
        component: CfdisView,
        grants: ["ReceptorCFDIsRead"],
        hidden: true,
    },
    {
        title: "cfdis.to_authorize.title",
        path: "/tenant/cfdis/owner-to-authorize/message/:success",
        icon: <ToAuthorizeIcon />,
        component: CfdisView,
        grants: ["AdminCFDIsRead"],
        hidden: true,
    },
    {
        title: "cfdis.to_authorize.title",
        path: "/tenant/cfdis/owner-to-verify/success/:success",
        icon: <ToAuthorizeIcon />,
        component: CfdisView,
        grants: ["AdminCFDIsRead"],
        hidden: true,
    },
    {
        title: "cfdis.tenant_group.title",
        path: "",
        icon: <MyCfdisIcon />,
        grants: ["ReceptorCFDIsRead"],
        items: [
            {
                title: "cfdis.to_authorize.title",
                path: "/tenant/cfdis/receptor-to-authorize",
                icon: <ToAuthorizeIcon />,
                component: CfdisView,
                grants: ["ReceptorCFDIsRead"],
            },
            {
                title: "cfdis.to_verify.title",
                path: "/tenant/cfdis/owner-to-verify",
                icon: <VerifiedUserIcon />,
                component: CfdisView,
                grants: ["ReceptorSecondAuthorizerRead"],
            },
            {
                title: "cfdis.pay_pending.title",
                path: "/tenant/cfdis/pending-payment-by-receptor",
                icon: <AuthorizedIcon />,
                component: CfdisView,
                grants: ["ReceptorCFDIsRead"],
            },
            {
                title: "cfdis.all_cfdis.title",
                path: "/tenant/cfdis/archive-tenant",
                icon: <ArchiveIcon />,
                component: CfdisView,
                grants: ["ReceptorCFDIsRead"],
            },
        ]
    },
    {
        title: "cfdis.tenant_group.title",
        path: "",
        icon: <MyCfdisIcon />,
        grants: ["CfdisToAuthorize",
            "CfdisToVerify",
            "CfdisPayPending",
            "CfdisDeliveredErp",
            "CfdisPostponePayment",
            "CfdisArchive"],
        items: [
            {
                title: "cfdis.to_authorize.title",
                path: "/tenant/cfdis/owner-to-authorize",
                icon: <ToAuthorizeIcon />,
                component: CfdisView,
                grants: ["CfdisToAuthorize"],
            },
            {
                title: "cfdis.to_verify.title",
                path: "/tenant/cfdis/owner-to-verify",
                icon: <VerifiedUserIcon />,
                component: CfdisView,
                grants: ["CfdisToVerify"],
            },
            {
                title: "cfdis.pay_pending.title",
                path: "/tenant/cfdis/pending-payment-owner",
                icon: <AuthorizedIcon />,
                component: CfdisView,
                grants: ["CfdisPayPending"],
            },
            {
                title: "cfdis.delivered_erp.title",
                path: "/tenant/cfdis/delivered",
                icon: <AssignmentTurnedInIcon />,
                component: CfdisView,
                grants: ["CfdisDeliveredErp"],
            },
            {
                title: "cfdis.postpone_payment.title",
                path: "/tenant/cfdis/postpone",
                icon: <AccessTimeTwoToneIcon />,
                component: CfdisView,
                grants: ["CfdisPostponePayment"],
            },
            {
                title: "cfdis.all_cfdis.title",
                path: "/tenant/cfdis/archive-tenant",
                icon: <ArchiveIcon />,
                component: CfdisView,
                grants: ["CfdisArchive"],
            }
        ]
    },
    {
        title: "cfdis.to_send.title",
        path: "/provider/cfdis/to-send/success/:success",
        icon: <CfdisToSendIcon />,
        component: CfdisView,
        grants: ["ProviderCFDIsRead", "SenderCFDIsRead"],
        hidden: true,
    },
    {
        title: "cfdis.group.title",
        path: "",
        icon: <MyCfdisIcon />,
        grants: ["ProviderCFDIsRead", "SenderCFDIsRead"],
        items: [
            {
                title: "cfdis.to_send.title",
                path: "/provider/cfdis/to-send",
                icon: <CfdisToSendIcon />,
                component: CfdisView,
                grants: ["ProviderCFDIsRead", "SenderCFDIsRead"],
            },
            {
                title: "cfdis.sent.title",
                path: "/provider/cfdis/sent",
                icon: <CfdisInProcessIcon />,
                component: CfdisView,
                grants: ["ProviderCFDIsRead", "SenderCFDIsRead"],
            },
            {
                title: "cfdis.all_cfdis.title",
                path: "/provider/cfdis/archive-provider",
                icon: <ArchiveIcon />,
                component: CfdisView,
                grants: ["ProviderCFDIsRead"],
            }
        ]

    },
    {
        title: "cfdis_se.title_sh",
        icon: <MyCfdisIcon />,
        grants: ["CfdisPDPSEIssuedRead"],
        items: [
            {
                title: "cfdis_se.prepare",
                path: "/cfdis/se/:cfdiId/prepare",
                icon: <MyCfdisIcon />,
                component: CfdiIssuedPrepareForm,
                grants: ["CfdisIssuedCreate", "CfdisIssuedUpdate", "ProviderCfdisIssuedCreate"],
                hidden: true,
            },
            {
                title: "cfdis_se.details",
                path: "/cfdis/se/:cfdiId/details",
                icon: <MyCfdisIcon />,
                component: CfdiIssuedOverview,
                grants: ["CfdisIssuedRead", "CustomerCfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
                hidden: true,
            },
            {
                title: "cfdis_se.new",
                path: "/cfdis/se/new",
                icon: <MyCfdisIcon />,
                component: CfdiIssuedForm,
                grants: ["CfdisIssuedCreate", "ProviderCfdisIssuedCreate"],
                hidden: true,
            },
            {
                title: "cfdis_se.to_prepare",
                path: "/cfdis/se/to-prepare",
                icon: <PresentToAllIcon />,
                component: CfdisIssuedToPrepareAndSendView,
                grants: ["CfdisIssuedRead", "ProviderCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.pending_docs",
                path: "/cfdis/se/pending-docs/success/:success",
                icon: <SECfdiPendingDocsIcon />,
                component: CfdisIssuedPendingDocsView,
                grants: ["CfdisIssuedRead", "CustomerCfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
                hidden: true,
            },
            {
                title: "cfdis_se.pending_docs",
                path: "/cfdis/se/pending-docs",
                icon: <SECfdiPendingDocsIcon />,
                component: CfdisIssuedPendingDocsView,
                grants: ["CfdisIssuedRead", "CustomerCfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.validating_docs",
                path: "/cfdis/se/validating-docs",
                icon: <FindInPageIcon />,
                component: CfdisIssuedValidatingView,
                grants: ["TenantCfdisIssuedRead", "ProviderCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.archive",
                path: "/cfdis/se/archive",
                icon: <SECfdiArchiveIcon />,
                component: CfdisIssuedArchivedView,
                grants: ["CfdisIssuedRead", "ProviderCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.archive",
                path: "/cfdis/se/archive",
                icon: <SECfdiArchiveIcon />,
                component: CfdisIssuedArchivedTenantView,
                grants: ["TenantCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.archive",
                path: "/cfdis/se/archive",
                icon: <SECfdiArchiveIcon />,
                component: CfdisIssuedArchivedCustomerView,
                grants: ["CustomerCfdisIssuedRead"],
            },
        ]
    },
    {
        title: "refunds.title",
        path: "/refunds/to_send/message/:success",
        icon: <ToSendIcon />,
        component: RefundsView,
        grants: ["RefundsRead", "RefundsCxcRead"],
        hidden: true,
    },
    {
        title: "refunds.title",
        path: "/refunds/to_authorize/success/:success",
        icon: <ToSendIcon />,
        component: RefundsView,
        grants: ["RefundsRead", "RefundsCxcRead"],
        hidden: true,
    },
    {
        title: "refunds.title",
        path: "/refunds/archive/success/:success",
        icon: <ToSendIcon />,
        component: RefundsView,
        grants: ["RefundsRead", "RefundsCxcRead"],
        hidden: true,
    },
    {
        title: "providers.title",
        path: "/vendors/detail/:providerId",
        icon: <ProvidersIcon />,
        component: ProviderOverview,
        grants: ["VendorsRead"],
        hidden: true
    },
    {
        title: "providers.title",
        path: "/vendors/:providerId/success/:success",
        icon: <ProvidersIcon />,
        component: ProviderOverview,
        grants: ["VendorsRead"],
        hidden: true
    },
    {
        title: "providers.title",
        path: "/vendors/:providerId/edit",
        icon: <ProvidersIcon />,
        component: ProviderForm,
        grants: ["VendorsUpdate"],
        hidden: true
    },
    {
        title: "providers.title",
        path: "/vendors/new",
        icon: <ProvidersIcon />,
        component: ProviderForm,
        grants: ["VendorsCreate"],
        hidden: true
    },
    {
        title: "refunds.title",
        icon: <BusinessIcon />,
        grants: ["RefundsRead", "RefundsCxcRead", "RefundsReceptorRead"],
        items: [
            {
                title: "refunds.to_send.title_routes",
                path: "/refunds/to_send",
                icon: <ToSendIcon />,
                grants: ["RefundsRead", "RefundsCxcRead"],
                component: RefundsView
            },
            {
                title: "refunds.to_authorize.title_routes",
                path: "/refunds/to_authorize",
                icon: <ToAuthorizeIcon />,
                grants: ["RefundsRead", "RefundsReceptorRead"],
                component: RefundsView,
            },
            {
                title: "refunds.archive.title_routes",
                path: "/refunds/archive",
                icon: <ArchiveIcon />,
                grants: ["RefundsRead", "RefundsCxcRead", "RefundsReceptorRead"],
                component: RefundsView
            },
            {
                title: "providers.vendors.title",
                path: "/vendors",
                icon: <VendorsIcon />,
                component: VendorsView,
                grants: ["VendorsRead"],
            },
        ]
    },
    {
        title: "payment_order.pending_payment_orders",
        path: "/payment-order/:paymentOrderId/read/:backTo",
        icon: <AuthorizedIcon />,
        component: PaymentOrderDetails,
        grants: ["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"],
        hidden: true
    },
    {
        title: "payment_order.pending_payment_orders",
        path: "/payment-order/:paymentOrderId/read",
        icon: <AuthorizedIcon />,
        component: PaymentOrderDetails,
        grants: ["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"],
        hidden: true
    },
    {
        title: "payment_order.pending_payment_orders",
        path: "/payment-order/:paymentOrderId/:edit/:backTo",
        icon: <AuthorizedIcon />,
        component: PaymentOrderDetails,
        grants: ["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"],
        hidden: true
    },
    {
        title: "payment_order.title",
        path: "",
        icon: <PaymentIcon />,
        grants: ["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"],
        items: [
            {
                title: "payment_order.pending_payment_orders",
                path: "/payment-order/pending",
                icon: <PaymentOrderPendingIcon />,
                component: PaymentOrderArchivedView,
                grants: ["PaymentOrdersCxpRead"],
            },
            {
                title: "payment_order.execution_payment_orders",
                path: "/payment-order/execute",
                icon: < AssignmentTurnedInIcon />,
                component: PaymentOrderArchivedView,
                grants: ["PaymentOrdersTreasurerRead"],
            },
            {
                title: "payment_order.archive_payment_orders",
                path: "/payment-order/archive",
                icon: <ArchiveIcon />,
                component: PaymentOrderArchivedView,
                grants: ["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"],
            },
        ]
    },
    {
        title: "payment.pending_cfdi.title",
        path: "/payment/pending-cfdi/success/:success",
        icon: <LocalAtmIcon />,
        component: PaymentsPendingCfdiView,
        grants: ["PaymentsRead", "PaymentsAdminRead"],
        hidden: true,
    },
    {
        title: "payment.title",
        path: "",
        icon: <MonetizationOnIcon />,
        grants: ["PaymentsRead", "PaymentsAdminRead"],
        items: [
            {
                title: "payment.pending_cfdi.title",
                path: "/payment/pending-cfdi",
                icon: <LocalAtmIcon />,
                component: PaymentsPendingCfdiView,
                grants: ["PaymentsRead", "PaymentsAdminRead"],
            },
            {
                title: "payment.payment_cfdi_in_review.title",
                path: "/payment/for-review",
                icon: <ToAuthorizeIcon />,
                component: PaymentsPendingReview,
                grants: ["PaymentsAdminRead"],
            },
            {
                title: "payment.archived.title",
                path: "/payment/archived",
                icon: <ArchiveIcon />,
                component: PaymentsArchivedView,
                grants: ["PaymentsAdminRead"],
            },
            {
                title: "cfdi.new_cfdi",
                path: "/payment-cfdi/new",
                icon: <CfdisToSendIcon />,
                component: PaymentCfdiForm,
                grants: ["PaymentsUpdate"],
                hidden: true,
            },
            {
                title: "cfdi.new_cfdi",
                path: "/payment-details/:idPayment",
                icon: <CfdisToSendIcon />,
                component: PaymentOverView,
                grants: ["PaymentsRead", "PaymentsAdminRead"],
                hidden: true,
            },
            {
                title: "payment.payment_cfdi_in_review.title_detail",
                path: "/payment-review/:idCfdi",
                icon: <ToAuthorizeIcon />,
                component: PaymentsPendingReviewOverView,
                grants: ["PaymentsRead", "PaymentsAdminRead"],
                hidden: true,
            }
        ]
    },
    {
        title: "tax_stamp.title",
        path: "/tax-stamp",
        icon: <ProvidersIcon />,
        component: TaxStamp,
        grants: ["ProviderTaxStampCsdCreate"]
    },
    {
        title: "providers.title",
        path: "/providers/:providerId/edit",
        icon: <ProvidersIcon />,
        component: ProviderForm,
        grants: ["ProvidersUpdate", "VendorsUpdate"],
        hidden: true
    },
    {
        title: "providers.title",
        path: "/providers/new",
        icon: <ProvidersIcon />,
        component: ProviderForm,
        grants: ["ProvidersCreate", "VendorsCreate"],
        hidden: true
    },
    {
        title: "providers.title",
        path: "",
        icon: <ProvidersIcon />,
        grants: ["ProvidersRead", "EfosRead", "ValidationExpedientRead", "SpecialExpedientUpdate", "ValidationExpedientUpdate"],
        items: [
            {
                title: "providers.expedient_history.title",
                path: "/providers/:providerId/history-expedient/:historyExpedientId",
                icon: <ExpedientIcon />,
                component: ProviderExpedientHistoryDetail,
                grants: ["ValidationExpedientRead", "ValidationExpedientUpdate"],
                hidden: true
            },
            {
                title: "providers.views.efos",
                path: "/providers/efos",
                icon: <ProviderEfosIcon />,
                component: ProvidersEfosView,
                grants: ["EfosRead"],
            },
            {
                title: "providers.views.expedient_review",
                path: "/providers/expedients/review",
                icon: <SupervisorAccount />,
                component: ProvidersSpecialExpedientsView,
                grants: ["SpecialExpedientUpdate"],
            },  
            {
                title: "providers.views.expedients",
                path: "/providers/expedients",
                icon: <ExpedientIcon />,
                component: ProvidersExpedientsView,
                grants: ["ValidationExpedientRead", "ValidationExpedientUpdate"],
            },
            {
                title: "providers.views.control_reception",
                path: "/providers/control",
                icon: <ReceiptIcon />,
                component: ProvidersControlReceptionView,
                grants: ["ProvidersRead"],
            },
            {
                title: "providers.title",
                path: "/providers/:providerId",
                icon: <ProvidersIcon />,
                component: ProviderOverview,
                grants: ["ProvidersRead", "VendorsRead", "ValidationExpedientUpdate"],
                hidden: true
            },
            {
                title: "providers.views.base",
                path: "/providers",
                icon: <ProviderBaseIcon />,
                component: ProvidersManagementView,
                grants: ["ProvidersRead"],
            },
            {
                title: "providers.announcements.title",
                path: "/providers-admin/announcements",
                icon: <NotificationsIcon />,
                component: ProviderAnnouncementsView,
                grants: ["AnnouncementAdminRead"],
            },
        ]
    },
    {
        title: "budget.lists.titles.BUDGETS",
        path: "",
        icon: <BudgetsPlanningIcon />,
        grants: ["BudgetsPlanningRead", "BudgetsToExecuteRead", "BudgetsToExecuteAllRead", "BudgetsArchiveRead", "BudgetLinesTemplateRead"],
        items: [
            {
                title: "budget.lists.titles_options.PLANNING",
                path: "/budgets/planning",
                icon: <BudgetsPlanningIcon />,
                component: BudgetsView,
                grants: ["BudgetsPlanningRead"],
            },
            {
                title: "budget.lists.titles_options.EXECUTE",
                path: "/budgets/execute",
                icon: <BudgetsExecuteIcon />,
                component: BudgetsView,
                grants: ["BudgetsToExecuteRead", "BudgetsToExecuteAllRead"],
            },
            {
                title: "budget.lists.titles_options.ARCHIVE",
                path: "/budgets/archive",
                icon: <BudgetsArchiveIcon />,
                component: BudgetsView,
                grants: ["BudgetsArchiveRead"],
            },
            {
                title: "budget.title",
                path: "/budgets/new",
                icon: <BudgetsPlanningIcon />,
                component: BudgetForm,
                grants: ["BudgetsCreate"],
                hidden: true,
            },
            {
                title: "budget.title",
                path: "/budgets/:budgetId/edit",
                icon: <BudgetsPlanningIcon />,
                component: BudgetForm,
                grants: ["BudgetsUpdate"],
                hidden: true,
            },
            {
                title: "budgets.title",
                path: "/budgets/:budgetId/detail",
                icon: <BudgetsPlanningIcon />,
                component: BudgetOverview,
                grants: ["BudgetsRead", "BudgetDetailReadOnly"],
                hidden: true
            },
            {
                title: "budget_lines_templates.edit",
                path: "/budgets/template-lines/:budgetLinesTemplateId/edit",
                icon: <BudgetLinesTemplatesIcon />,
                component: BudgetLinesTemplateForm,
                grants: ["BudgetLinesTemplateUpdate"],
                hidden: true
            },
            {
                title: "budget_lines_templates.new",
                path: "/budgets/template-lines/new",
                icon: <BudgetLinesTemplatesIcon />,
                component: BudgetLinesTemplateForm,
                grants: ["BudgetLinesTemplateCreate"],
                hidden: true
            },
            {
                title: "budget_lines_templates.title",
                path: "/budgets/template-lines",
                icon: <BudgetLinesTemplatesIcon />,
                component: BudgetLinesTemplateList,
                grants: ["BudgetLinesTemplateRead"],
            },
        ]
    },
    {
        title: "tenant_configurations.title",
        path: "/tenants/:tenantId/configurations",
        icon: <SettingsIcon />,
        component: TenantConfigurationsForm,
        grants: ["TenantsRead"],
        hidden: true,
    },
    {
        title: "purchase_orders.title",
        path: "/tenants/:tenantId/purchase-orders-config",
        icon: <SettingsIcon />,
        component: POTenantConfigurationForm,
        grants: ["PurchaseOrdersTenantConfigRead"],
        hidden: true,
    },
    {
        title: "connectors.title",
        path: "/tenants/:tenantId/connectors/new",
        icon: <ConnectorIcon />,
        component: ConnectorForm,
        grants: ["ConnectorsCreate"],
        hidden: true,
    },
    {
        title: "connectors.title",
        path: "/tenants/:tenantId/connectors/:connectorId/edit",
        icon: <ConnectorIcon />,
        component: ConnectorForm,
        grants: ["ConnectorsUpdate"],
        hidden: true,
    },
    {
        title: "connectors.title",
        path: "/tenants/:tenantId/connectors",
        icon: <ConnectorIcon />,
        component: Connectors,
        grants: ["ConnectorsRead"],
        hidden: true,
    },
    {
        title: "widgets.edit",
        path: "/tenants/:tenantId/widgets/:widgetId/edit",
        icon: <WidgetIcon />,
        component: WidgetsForm,
        hidden: true
    },
    {
        title: "widgets.new",
        path: "/tenants/:tenantId/widgets/new",
        icon: <WidgetIcon />,
        component: WidgetsForm,
        hidden: true
    },
    {
        title: "widgets.title",
        path: "/tenants/:tenantId/widgets",
        icon: <WidgetIcon />,
        component: Widgets,
        hidden: true
    },
    {
        title: "plugins.edit",
        path: "/tenants/:tenantId/plugins/:pluginId/edit",
        icon: <PluginIcon />,
        component: PluginForm,
        grants: ["PluginsCreate", "PluginsUpdate"],
        hidden: true
    },
    {
        title: "plugins.new",
        path: "/tenants/:tenantId/plugins/new",
        icon: <PluginIcon />,
        component: PluginForm,
        grants: ["PluginsCreate", "PluginsUpdate"],
        hidden: true
    },
    {
        title: "plugins.title",
        path: "/tenants/:tenantId/plugins",
        icon: <PluginIcon />,
        component: Plugins,
        grants: ["PluginsRead"],
        hidden: true
    },
    {
        title: "companies.edit",
        path: "/tenants/:tenantId/companies/:companyId/edit",
        icon: <CompaniesIcon />,
        component: CompanyForm,
        grants: ["CompaniesUpdate"],
        hidden: true,
    },
    {
        title: "companies.new",
        path: "/tenants/:tenantId/companies/new",
        icon: <CompaniesIcon />,
        component: CompanyForm,
        grants: ["CompaniesCreate"],
        hidden: true,
    },
    {
        title: "companies.title",
        path: "/tenants/:tenantId/companies",
        icon: <CompaniesIcon />,
        component: Companies,
        grants: ["CompaniesRead"],
        hidden: true,
    },
    {
        title: "bank_account_type.tenant_bank_account_type.title",
        path: "/tenants/:tenantId/:tenantName/tenant-bank-account-types",
        icon: <TenantAccountTypeIcon />,
        component: TenantBankAccountTypesView,
        grants: ["TenantBankAccountTypesRead"],
        hidden: true,
    },
    {
        title: "custom_report.titles.edit",
        path: "/tenants/:tenantId/customreports/:customReportId/edit",
        icon: <PlaylistAddIcon />,
        component: CustomReportForm,
        grants: ["CustomReportUpdate"],
        hidden: true,
    },
    {
        title: "custom_report.titles.new",
        path: "/tenants/:tenantId/customreports/new",
        icon: <PlaylistAddIcon />,
        component: CustomReportForm,
        grants: ["CustomReportCreate"],
        hidden: true,
    },
    {
        title: "custom_report.title",
        path: "/tenants/:tenantId/customreports/:success",
        component: CustomReports,
        grants: ["CustomReportRead"],
        hidden: true,
    },
    {
        title: "custom_report.title",
        path: "/tenants/:tenantId/customreports",
        component: CustomReports,
        grants: ["CustomReportRead"],
        hidden: true,
    },
    {
        title: "sap.settings.title",
        path: "/tenants/:tenantId/erp",
        icon: <ErpFormIcon />,
        component: TenantConfigurationSAPForm,
        grants: ["TenantConfigurationsSAPUpdate"],
        hidden: true
    },
    {
        title: "integrations.title",
        path: "/tenants/:tenantId/integrations",
        icon: <IntegrationsIcon />,
        component: IntegrationForm,
        grants: ["IntegrationsRead"],
        hidden: true
    },
    {
        title: "tenant_modules.title",
        path: "/tenants/:tenantId/modules",
        icon: <TenantModulesIcon />,
        component: TenantModulesForm,
        grants: ["TenantModulesRead"],
        hidden: true
    },
    {
        title: "tenant_security.title",
        path: "/tenants/:tenantId/security",
        icon: <VpnKeyTwoTone />,
        component: TenantSecurityForm,
        grants: ["SecurityConfigRead"],
        hidden: true
    },
    {
        title: "tenants.new_tenant",
        path: "/tenants/:tenantId/edit",
        icon: <WorkIcon />,
        component: TenantForm,
        grants: ["TenantsUpdate"],
        hidden: true,
    },
    {
        title: "tenants.new_tenant",
        path: "/tenants/new",
        icon: <WorkIcon />,
        component: TenantForm,
        grants: ["TenantsCreate"],
        hidden: true,
    },
    {
        title: "tenants.title",
        path: "/tenants/:success",
        icon: <WorkIcon />,
        component: Tenants,
        grants: ["TenantsRead"],
        hidden: true,
    },
    {
        title: "tenants.title",
        path: "/tenants",
        icon: <WorkIcon />,
        component: Tenants,
        grants: ["TenantsRead"],
    },
    {
        title: "provider_membership_tax_stamp.title",
        path: "/provider-membership-tax-stamp",
        icon: <AddShoppingCartIcon />,
        component: TaxStampMemberships,
        grants: ["PackagesTaxStampRead"],
    },
    {
        title: "users.title",
        path: "/users/new",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["UsersCreate", "CustomerUsersCreate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/users/:userId/edit",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["UsersUpdate", "CustomerUsersUpdate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/tenants-admin/:tenantId/users/new",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["UsersCreate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/tenants-admin/:tenantId/users/:userId/edit",
        icon: <GroupIcon />,
        component: UserForm,
        grants: ["UsersUpdate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/tenants-admin/:tenantId/users",
        icon: <GroupIcon />,
        component: Users,
        grants: ["UsersUpdate"],
        hidden: true,
    },
    {
        title: "users.title",
        path: "/users",
        icon: <GroupIcon />,
        component: Users,
        grants: ["UsersRead", "CustomerUsersRead"]
    },
    {
        title: "focaltec_announcements.title",
        path: "/focaltec-announcements/new",
        icon: <FocaltecAnnouncementsIcon />,
        component: FocaltecAnnouncementForm,
        grants: ["FocaltecAnnouncementsRead", "FocaltecAnnouncementsCreate"],
        hidden: true,
    },
    {
        title: "focaltec_announcements.title",
        path: "/focaltec-announcements/:focaltecAnnouncementId/edit",
        icon: <FocaltecAnnouncementsIcon />,
        component: FocaltecAnnouncementForm,
        grants: ["FocaltecAnnouncementsRead", "FocaltecAnnouncementsUpdate"],
        hidden: true,
    },
    {
        title: "focaltec_announcements.title",
        path: "/focaltec-announcements",
        icon: <FocaltecAnnouncementsIcon />,
        component: FocaltecAnnouncementsList,
        grants: ["FocaltecAnnouncementsRead"],
    },
    {
        title: "expedients.single",
        path: "/expedients/overview",
        icon: <ExpedientIcon />,
        component: ExpedientProvider,
        grants: ["ProvidersSelfUpdate"]
    },
    {
        title: "providers.expedient_history.title",
        path: "/history-expedient/:historyExpedientId",
        icon: <ExpedientIcon />,
        component: ProviderExpedientHistoryDetail,
        grants: ["ProvidersSelfUpdate"],
        hidden: true
    },
    {
        title: "users.title",
        path: "/providers/users",
        icon: <GroupIcon />,
        component: Users,
        grants: ["ProviderUsersRead"],
    },
    {
        title: "users.tenant_provider.title",
        path: "/tenants-admin/:tenantId/providers-users",
        icon: <TenantProvidersUsers />,
        component: Users,
        grants: ["TechnicalSupportUpdate"],
        hidden: true,
    },
    {
        title: "actions_admin.title",
        path: "/tenants-admin/:tenantId/actions-admin/:roleId/edit",
        icon: <TenantProvidersUsers />,
        component: RoleAdminForm,
        grants: ["ActionsAdminUpdate"],
        hidden: true,
    },
    {
        title: "actions_admin.title",
        path: "/tenants-admin/:tenantId/actions-admin/:success",
        icon: <TenantProvidersUsers />,
        component: RolesAdmin,
        grants: ["ActionsAdminRead"],
        hidden: true,
    },
    {
        title: "actions_admin.title",
        path: "/tenants-admin/:tenantId/actions-admin",
        icon: <TenantProvidersUsers />,
        component: RolesAdmin,
        grants: ["ActionsAdminRead"],
        hidden: true,
    },
    {
        title: "providers.announcements.title",
        path: "/announcements",
        icon: <NotificationsIcon />,
        component: ProviderAnnouncementsView,
        grants: ["AnnouncementProviderRead"]
    },
    {
        title: "efo_files.title",
        path: "/efo-files",
        icon: <EfosFilesIcon />,
        component: EfoFiles,
        grants: ["EfosFileRead"],
    },
    {
        title: "cff_69_files.title",
        path: "/cff-69-files",
        icon: <Cff69FilesIcon />,
        component: Cff69FilesList,
        grants: ["Cff69ListFileRead"],
    },
    {
        title: "subscription.title",
        path: "/subscribe",
        icon: <BillingIcon />,
        component: PaymentForm,
        grants: ["TenantsBillingsCreate", "TenantsBillingsUpdate"],
        hidden: true
    },
    {
        title: "specific_reception_rule.title",
        path: "/reception-specific-rules/:specificRuleId/edit/:type/type",
        icon: <PostAddIcon />,
        component: ReceptionSpecificRuleEditTabs,
        grants: ["SpecificReceptionRuleCreate"],
        hidden: true
    },
    {
        title: "specific_reception_rule.title",
        path: "/reception-specific-rules/new",
        icon: <PostAddIcon />,
        component: ReceptionSpecificRuleForm,
        grants: ["SpecificReceptionRuleCreate"],
        hidden: true
    },
    {
        title: "bank_account.title_menu",
        path: "/bank-accounts/new",
        icon: <ListAltIcon />,
        component: BankAccountsAdminForm,
        grants: ["BankAccountAdminUpdate"],
        hidden: true
    },
    {
        title: "bank_account.title_menu",
        path: "/bank-accounts/:bankAccountId/edit",
        icon: <ListAltIcon />,
        component: BankAccountsAdminForm,
        grants: ["BankAccountAdminUpdate"],
        hidden: true
    },
    {
        title: "bank_account.title_menu",
        path: "/bank-accounts/:success",
        icon: <ListAltIcon />,
        component: BankAccountsAdminView,
        grants: ["BankAccountAdminRead"],
        hidden: true
    },
    {
        title: "cfdis_se.title_menu",
        icon: <MyCfdisIcon />,
        grants: ["CfdisOnlySEIssuedRead"],
        items: [
            {
                title: "cfdis_se.prepare",
                path: "/cfdis/se/:cfdiId/prepare",
                icon: <MyCfdisIcon />,
                component: CfdiIssuedPrepareForm,
                grants: ["CfdisIssuedCreate", "CfdisIssuedUpdate", "ProviderCfdisIssuedCreate", "ProviderCfdisIssuedUpdaate"],
                hidden: true,
            },
            {
                title: "cfdis_se.details",
                path: "/cfdis/se/:cfdiId/details",
                icon: <MyCfdisIcon />,
                component: CfdiIssuedOverview,
                grants: ["CfdisIssuedRead", "CustomerCfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
                hidden: true,
            },
            {
                title: "cfdis_se.new",
                path: "/cfdis/se/new",
                icon: <MyCfdisIcon />,
                component: CfdiIssuedForm,
                grants: ["CfdisIssuedCreate", "ProviderCfdisIssuedCreate"],
                hidden: true,
            },
            {
                title: "cfdis_se.to_prepare",
                path: "/cfdis/se/to-prepare",
                icon: <PresentToAllIcon />,
                component: CfdisIssuedToPrepareView,
                grants: ["CfdisIssuedRead", "ProviderCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.to_send",
                path: "/cfdis/se/to-send",
                icon: <SECfdiToSendIcon />,
                component: CfdisIssuedToSendView,
                grants: ["CfdisIssuedRead", "ProviderCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.pending_docs",
                path: "/cfdis/se/pending-docs/success/:success",
                icon: <SECfdiPendingDocsIcon />,
                component: CfdisIssuedPendingDocsView,
                grants: ["CfdisIssuedRead", "CustomerCfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
                hidden: true,
            },
            {
                title: "cfdis_se.pending_docs",
                path: "/cfdis/se/pending-docs",
                icon: <SECfdiPendingDocsIcon />,
                component: CfdisIssuedPendingDocsView,
                grants: ["CfdisIssuedRead", "CustomerCfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.validating_docs",
                path: "/cfdis/se/validating-docs",
                icon: <FindInPageIcon />,
                component: CfdisIssuedValidatingView,
                grants: ["ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.archive",
                path: "/cfdis/se/archive",
                icon: <SECfdiArchiveIcon />,
                component: CfdisIssuedArchivedView,
                grants: ["CfdisIssuedRead", "ProviderCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.archive",
                path: "/cfdis/se/archive",
                icon: <SECfdiArchiveIcon />,
                component: CfdisIssuedArchivedTenantView,
                grants: ["TenantCfdisIssuedRead"],
            },
            {
                title: "cfdis_se.archive",
                path: "/cfdis/se/archive",
                icon: <SECfdiArchiveIcon />,
                component: CfdisIssuedArchivedCustomerView,
                grants: ["CustomerCfdisIssuedRead"],
            },
        ]
    },
    {
        title: "requisitions.projects.to_start",
        path: "/projects/to-start/success/:success",
        icon: <ProjectToStartIcon />,
        component: ProjectsView,
        grants: ["ProjectsAdminRead"],
        hidden: true,
    },
    {
        title: "requisitions.projects.in_execution",
        path: "/projects/in_execution/success/:success",
        icon: <ProjectExecutionIcon />,
        component: ProjectsView,
        grants: ["ProjectsAdminRead"],
        hidden: true,
    },
    {
        title: "requisitions.projects.title",
        path: "",
        icon: <ProjectIcon />,
        grants: ["ProjectsRead", "ProjectsCreate", "ProjectsUpdate", "ProjectsDelete"],
        items: [
            {
                title: "requisitions.projects.create_title",
                path: "/projects/create",
                icon: <ProjectIcon />,
                component: ProjectDetails,
                grants: ["ProjectsCreate"],
                hidden: true
            },
            {
                title: "requisitions.projects.edit_title",
                path: "/projects/edit/:projectId",
                icon: <ProjectIcon />,
                component: ProjectDetails,
                grants: ["ProjectsAdminUpdate"],
                hidden: true
            },
            {
                title: "requisitions.projects.detail_title",
                path: "/projects/detail/:projectId",
                icon: <ProjectIcon />,
                component: ProjectDetails,
                grants: ["ProjectsRead"],
                hidden: true
            },
            {
                title: "extended_fields_templates.title_menu",
                path: "/projects/templates/create",
                icon: <LibraryBooks />,
                component: ExtendedFieldsTemplatesCreateEdit,
                hidden: true,
            },
            {
                title: "extended_fields_templates.title_menu",
                path: "/projects/templates/:templateId/edit",
                icon: <LibraryBooks />,
                component: ExtendedFieldsTemplatesCreateEdit,
                hidden: true,
            },
            {
                title: "requisitions.projects.to_start",
                path: "/projects/to-start",
                icon: <ProjectToStartIcon />,
                component: ProjectsView,
                grants: ["ProjectsAdminRead"],
            },
            {
                title: "requisitions.projects.in_execution",
                path: "/projects/in_execution",
                icon: <ProjectExecutionIcon />,
                component: ProjectsView
            },
            {
                title: "requisitions.projects.archive",
                path: "/projects/archive",
                icon: <ArchiveIcon />,
                component: ProjectsView
            },
            {
                title: "extended_fields_templates.title_menu",
                path: "/projects/templates",
                icon: <LibraryBooks />,
                component: ExtendedFieldsTemplateList,
                grants: ["ExtendedFieldsTemplateRead"],
            }
        ]
    },
    {
        title: "contracts.new",
        path: "/customers/:customerId/contracts/new",
        icon: <ContractIcon />,
        component: ContractForm,
        grants: ["ContractsCreate"],
        hidden: true,
    },
    {
        title: "customers.edit",
        path: "/customers/:customerId/contracts/:contractId/edit",
        icon: <ContractIcon />,
        component: ContractForm,
        grants: ["ContractsUpdate"],
        hidden: true,
    },
    {
        title: "customers.new",
        path: "/customers/new",
        icon: <CustomersIcon />,
        component: CustomerForm,
        grants: ["CustomersCreate"],
        hidden: true,
    },
    {
        title: "customers.edit",
        path: "/customers/:customerId/edit",
        icon: <CustomersIcon />,
        component: CustomerForm,
        grants: ["CustomersUpdate"],
        hidden: true,
    },
    {
        title: "customers.edit",
        path: "/customers/:customerId/overview",
        icon: <CustomersIcon />,
        component: CustomerOverview,
        grants: ["CustomersRead"],
        hidden: true,
    },
    {
        title: "customers.title",
        path: "/customers",
        icon: <CustomersIcon />,
        component: Customers,
        grants: ["CustomersRead"],
    },
    {
        title: "customer_document_logs.title_short",
        path: "/customerdocumentlogs",
        icon: <CustomerDocumentLogsIcon />,
        component: CustomerDocumentLogsList,
        grants: ["CustomerDocumentLogsRead"],
    },
    {
        title: "digital_documents.edit",
        path: "/digital-documents/:digitalDocumentId/edit",
        icon: <DigitalDocumentIcon />,
        component: DigitalDocumentForm,
        grants: ["DigitalDocumentsUpdate"],
        hidden: true
    },
    {
        title: "digital_documents.new",
        path: "/digital-documents/new",
        icon: <DigitalDocumentIcon />,
        component: DigitalDocumentForm,
        grants: ["DigitalDocumentsCreate"],
        hidden: true
    },
    {
        title: "digital_documents.title",
        path: "/digital-documents",
        icon: <DigitalDocumentIcon />,
        component: DigitalDocuments,
        grants: ["DigitalDocumentsRead"]
    },
    {
        title: "payrolls.title",
        path: "",
        icon: <PayrollIcon />,
        grants: ["EmployeesRead", "PayrollsRead", "ProviderEmployeesRead", "ProviderPayrollsRead"],
        items: [
            {
                title: "employees.title",
                path: "/employees/new",
                icon: <EmployeeIcon />,
                component: EmployeeForm,
                grants: ["EmployeesCreate", "ProviderEmployeesCreate"],
                hidden: true,
            },
            {
                title: "employees.title",
                path: "/employees/:employeeId/edit",
                icon: <EmployeeIcon />,
                component: EmployeeForm,
                grants: ["EmployeesUpdate", "ProviderEmployeesUpdate"],
                hidden: true,
            },
            {
                title: "employees.title",
                path: "/employees/:employeeId/overview",
                icon: <EmployeeIcon />,
                component: EmployeeOverview,
                grants: ["EmployeesRead", "ProviderEmployeesRead"],
                hidden: true,
            },
            {
                title: "employees.title",
                path: "/employees",
                icon: <EmployeeIcon />,
                component: Employees,
                grants: ["EmployeesRead", "ProviderEmployeesRead"],
            },
            {
                title: "employee_visits.title",
                path: "/employee-visits/new",
                icon: <EmojiPeopleIcon />,
                component: EmployeeVisitForm,
                grants: ["EmployeeVisitCreate"],
                hidden: true,
            },
            {
                title: "employee-visits.title",
                path: "/employee-visits/:employeeVisitId/edit",
                icon: <EmployeeIcon />,
                component: EmployeeVisitForm,
                grants: ["EmployeeVisitUpdate"],
                hidden: true,
            },
            {
                title: "employee_visits.title",
                path: "/employee-visits",
                icon: <EmojiPeopleIcon />,
                component: EmployeeVisitsList,
                grants: ["EmployeeVisitRead"],
            },
            {
                title: "payrolls.upload",
                path: "/payrolls/new",
                icon: <PayrollUploaderIcon />,
                component: PayrollUploader,
                grants: ["PayrollsCreate", "ProviderPayrollsCreate"],
                hidden: true,
            },
            {
                title: "payrolls.archive",
                path: "/payrolls",
                icon: <PayrollArchiveIcon />,
                component: ArchivePayroll,
                grants: ["PayrollsRead", "ProviderPayrollsRead"],
            },
        ]
    },
    {
        title: "contributions.tenant.title",
        path: "",
        icon: <ContributionsSummaryIcon />,
        grants: ["TenantContributionsRead"],
        items: [
            {
                title: "contributions.tenant.pending",
                path: "/contributions/pending",
                icon: <ContributionsPendingIcon />,
                component: PendingContributions
            },
            {
                title: "contributions.to_validate.title",
                path: "/contributions/to-validate",
                icon: <SocialContributionIcon />,
                component: ContributionsToValidateTenant,
            },
            {
                title: "contributions.tenant.archive",
                path: "/contributions/archive",
                icon: <ContributionsArchiveIcon />,
                component: CompletedContributions
            }
        ]
    },
    {
        title: "contributions.title",
        path: "",
        icon: <ContributionsIcon />,
        grants: ["SocialContributionsRead", "SatContributionsRead", "ProviderSocialContributionsRead", "ProviderSatContributionsRead"],
        items: [
            {
                title: "social_contributions.title",
                path: "/contributions/social/new",
                icon: <SocialContributionIcon />,
                component: NewSocialContributionForm,
                grants: ["SocialContributionsCreate", "ProviderSocialContributionsCreate"],
                hidden: true,
            },
            {
                title: "social_contributions.title",
                path: "/contributions/social/:socialContributionId/edit",
                icon: <SocialContributionIcon />,
                component: SocialContributionForm,
                grants: ["SocialContributionsUpdate", "ProviderSocialContributionsUpdate"],
                hidden: true,
            },
            {
                title: "social_contributions.title",
                path: "/contributions/social",
                icon: <SocialContributionIcon />,
                component: SocialContributions,
                grants: ["SocialContributionsRead", "ProviderSocialContributionsRead"],
            },
            {
                title: "sat_contributions.title",
                path: "/contributions/sat/new",
                icon: <SatTaxIcon />,
                component: NewSatTaxForm,
                grants: ["SatContributionsCreate", "ProviderSatContributionsCreate"],
                hidden: true,
            },
            {
                title: "sat_contributions.title",
                path: "/contributions/sat/:satContributionId/edit",
                icon: <SatTaxIcon />,
                component: SatTaxForm,
                grants: ["SatContributionsUpdate", "ProviderSatContributionsUpdate"],
                hidden: true,
            },
            {
                title: "sat_contributions.title",
                path: "/contributions/sat",
                icon: <SatTaxIcon />,
                component: SatContributions,
                grants: ["SatContributionsRead", "ProviderSatContributionsRead"],
            },
            {
                title: "contributions.summary",
                path: "/contributions",
                icon: <ContributionsSummaryIcon />,
                component: AllContributions,
            },
        ]
    },
    {
        title: "reports.title",
        path: "",
        icon: <AssessmentIcon />,
        grants: ["ReportCxPRead", "ReportLatePaymentCfdiRead", "SystemLogRead", "TenantCfdisIssuedRead", "DueInvoicesReportRead"],
        items: [
            {
                title: "reports.cxp.title",
                path: "/reports/cxp",
                icon: <NoteAddIcon />,
                component: ReportCxPView,
                grants: ["ReportCxPRead"],
            },
            {
                title: "reports.due_invoices.title",
                path: "/reports/due-invoices",
                icon: <DueInvoices />,
                component: ReportDueInvoices,
                grants: ["DueInvoicesReportRead"],
            },
            {
                title: "reports.advance_payment_report.title",
                path: "/reports/advance-payments",
                icon: <AdvancePaymentIcon />,
                component: ReportAdvancePayment,
                grants: ["AdvancePaymentsReportRead"],
            },
            {
                title: "reports.late_cfdi_payments.title",
                path: "/reports/late-cfdis-payment",
                icon: <AlarmTwoToneIcon />,
                component: ReportLatePaymentCfdis,
                grants: ["ReportLatePaymentCfdiRead"],
            },
            {
                title: "reports.diots.title",
                path: "/reports/diots",
                icon: <DiotsIcon />,
                component: Diots,
                grants: ["DiotsRead"]
            },
            {
                title: "reports.custom_reports.title",
                path: "/reports/custom_reports",
                icon: <ViewList />,
                component: CustomReportsView,
                grants: ["CustomReportRead"],
            },
            {
                title: "reports.system_log.title",
                path: "/reports/system_log",
                icon: <AssignmentIcon />,
                component: ReportSystemLogView,
                grants: ["SystemLogRead"],
            },
            {
                title: "reports.terms_conditions.title",
                path: "/reports/terms-conditions-report",
                icon: <TermsConditionsIcon />,
                component: ReportTermsConditions,
                grants: ["ReportTermsConditionsRead"],
            },
            {
                title: "reports.icsoe_sisub.title",
                path: "/reports/icsoe_sisub",
                icon: <AssignmentIcon />,
                component: ReportIcsoeSisubView,
                grants: ["CustomersRead"],
            },
            {
                title: "reports.se_pending_docs_provider.title",
                path: "/reports/se-pending-docs-provider",
                icon: <FindInPageIcon />,
                component: SEPendingDocsByProvider,
                grants: ["TenantCfdisIssuedRead"],
            },
            {
                title: "reports.expenses_accounts",
                path: "/reports/expenses-accounts",
                icon: <ExpenseAccounIcon />,
                component: ReportExpensesAccounts,
                grants: ["ReportExpensesAccountRead"],
            }
        ]
    },
    {
        title: "clarifications.title",
        path: "",
        icon: <MessageIcon />,
        grants: ["ClarificationRead"],
        items: [
            {
                title: "clarifications.subtitle",
                path: "/clarifications/:clarificationId",
                icon: <ClarificationIcon />,
                component: ClarificationDetail,
                grants: ["ClarificationRead"],
                hidden: true,
            },
            {
                title: "clarifications.title_section_messages",
                path: "/clarifications",
                icon: <ClarificationIcon />,
                component: ClarificationList,
                grants: ["ClarificationRead"],
            }
        ]
    },
    {
        title: "provider_classifications.title",
        path: "/provider-classifications/new",
        icon: <ProviderClassificationsIcon />,
        component: ProviderClassificationForm,
        grants: ["ProviderClassificationsCreate"],
        hidden: true
    },
    {
        title: "provider_classifications.title",
        path: "/provider-classifications/:providerClassificationId/edit",
        icon: <ProviderClassificationsIcon />,
        component: ProviderClassificationForm,
        grants: ["ProviderClassificationsUpdate"],
        hidden: true
    },
    {
        title: "provider_classifications.title",
        path: "/provider-classifications/:success",
        icon: <ProviderClassificationsIcon />,
        component: ProviderClassificationsView,
        grants: ["ProviderClassificationsRead"],
        hidden: true
    },
    {
        title: "requisitions.teams.title",
        path: "/list-teams/success/:success",
        icon: <TeamIcon />,
        component: Teams,
        grants: ["TeamsUpdate"],
        hidden: true,
    },
    {
        title: "auxiliardocuments.title",
        path: "/auxiliar-documents/politics",
        icon: <AuxiliarDocumentsIcon />,
        component: AuxiliarDocumentsPolitics,
        hidden: true
    },
    {
        title: "settings.title",
        path: "",
        icon: <SettingsIcon />,
        grants: ["TenantFieldsRead", "ReceptionRuleRead", "SpecificReceptionRuleRead", "BankAccountAdminRead", "ProviderClassificationsRead", "ExpedientTemplateRead", "ShippingTemplateRead", "WorkflowsRead", "TeamsUpdate", "EmployerNumbersUpdate", "ProviderConfigurationsUpdate", "AuxiliarDocumentsRead", "NotificationGroupsRead", "NotificationGroupsCreate", "NotificationGroupsUpdate", "NotificationGroupsDelete", "UserGroupRead"],
        items: [
            {
                title: "users.title",
                path: "/tenants/:tenantId/users/new",
                icon: <GroupIcon />,
                component: UserForm,
                grants: ["TenantsUsersCreate"],
                hidden: true,
            },
            {
                title: "users.title",
                path: "/tenants/:tenantId/users/:userId/edit",
                icon: <GroupIcon />,
                component: UserForm,
                grants: ["TenantsUsersUpdate"],
                hidden: true,
            },
            {
                title: "users.title",
                path: "/tenants/users",
                icon: <GroupIcon />,
                component: Users,
                grants: ["TenantsUsersRead", "BillingsRead"],
            },
            {
                title: "user_groups.title",
                path: "/tenants/:tenantId/user-groups/new",
                icon: <SupervisorAccount />,
                component: UserGroupForm,
                grants: ["UserGroupCreate"],
                hidden: true,
            },
            {
                title: "user_groups.title",
                path: "/tenants/:tenantId/user-groups/:userGroupId/edit",
                icon: <SupervisorAccount />,
                component: UserGroupForm,
                grants: ["UserGroupUpdate"],
                hidden: true,
            },
            {
                title: "user_groups.title",
                path: "/tenants/user-groups",
                icon: <SupervisorAccount />,
                component: UserGroups,
                grants: ["UserGroupsRead", "UserGroupCreate", "UserGroupUpdate", "UserGroupDelete"],
            },
            {
                title: "notification_groups.title",
                path: "/tenants/:tenantId/notification-groups/new",
                icon: <NotificationGroupsIcon />,
                component: NotificationGroupsForm,
                grants: ["NotificationGroupsCreate"],
                hidden: true,
            },
            {
                title: "notification_groups.title",
                path: "/tenants/:tenantId/notification-groups/:notificationGroupId/edit",
                icon: <NotificationGroupsIcon />,
                component: NotificationGroupsForm,
                grants: ["NotificationGroupsUpdate"],
                hidden: true,
            },
            {
                title: "notification_groups.title",
                path: "/tenants/notification-groups",
                icon: <NotificationGroupsIcon />,
                component: NotificationGroups,
                grants: ["NotificationGroupsRead"],
            },
            {
                title: "companies.edit",
                path: "/companies/:companyId/edit",
                icon: <CompaniesIcon />,
                component: CompanyForm,
                grants: ["TenantsCompaniesUpdate"],
                hidden: true,
            },
            {
                title: "companies.new",
                path: "/companies/new",
                icon: <CompaniesIcon />,
                component: CompanyForm,
                grants: ["TenantsCompaniesCreate"],
                hidden: true,
            },
            {
                title: "companies.title",
                path: "/companies",
                icon: <CompaniesIcon />,
                component: Companies,
                grants: ["TenantsCompaniesRead"],
            },
            {
                title: "bank_account.title_menu",
                path: "/bank-accounts",
                icon: <ListAltIcon />,
                component: BankAccountsAdminView,
                grants: ["BankAccountAdminRead"]
            },
            {
                title: "expedients.title",
                path: "/expedients",
                icon: <ExpedientIcon />,
                component: ExpedientSettingsForm,
                grants: ["TenantFieldsRead"],
            },
            {
                title: "expedient_template.title",
                path: "/expedient-templates/create",
                icon: <BallotIcon />,
                component: ExpedientTemplatesCreateEdit,
                grants: ["ExpedientTemplateRead"],
                hidden: true
            },
            {
                title: "expedient_template.title",
                path: "/expedient-templates/:templateId",
                icon: <BallotIcon />,
                component: ExpedientTemplatesCreateEdit,
                grants: ["ExpedientTemplateRead"],
                hidden: true
            },
            {
                title: "expedient_template.title",
                path: "/expedient-templates",
                icon: <BallotIcon />,
                component: ExpedientTemplates,
                grants: ["ExpedientTemplateRead"],
            },
            {
                title: "reception_rule.title_edition",
                path: "/reception-rules/:ruleId/edit/:type/type",
                icon: <PlaylistAddCheckIcon />,
                component: ReceptionRuleEditTabs,
                grants: ["ReceptionRuleRead"],
                hidden: true
            }, {
                title: "provider_classifications.title",
                path: "/provider-classifications",
                icon: <ProviderClassificationsIcon />,
                component: ProviderClassificationsView,
                grants: ["ProviderClassificationsRead"],
            },
            {
                title: "reception_rule.title",
                path: "/reception-rules",
                icon: <PlaylistAddCheckIcon />,
                component: ReceptionRuleForm,
                grants: ["ReceptionRuleRead"]
            },
            {
                title: "specific_reception_rule.title",
                path: "/reception-specific-rules",
                icon: <PostAddIcon />,
                component: RceptionSpecificRuleList,
                grants: ["SpecificReceptionRuleRead"]
            },
            {
                title: "catalogs.edit",
                path: "/catalogs/:catalogId/edit",
                icon: <CatalogsIcon />,
                component: CatalogForm,
                grants: ["CatalogsUpdate"],
                hidden: true
            },
            {
                title: "catalogs.new",
                path: "/catalogs/new",
                icon: <CatalogsIcon />,
                component: CatalogForm,
                grants: ["CatalogsCreate"],
                hidden: true
            },
            {
                title: "catalogs.title",
                path: "/catalogs",
                icon: <CatalogsIcon />,
                component: Catalogs,
                grants: ["CatalogsRead"]
            },
            {
                title: "shipping_templates.edit",
                path: "/shipping-templates/:shippingTemplateId/edit",
                icon: <ShippingTemplatesIcon />,
                component: ShippingTemplateForm,
                grants: ["ShippingTemplateCreate", "ShippingTemplateUpdate"],
                hidden: true
            },
            {
                title: "shipping_templates.new",
                path: "/shipping-templates/new",
                icon: <ShippingTemplatesIcon />,
                component: ShippingTemplateForm,
                grants: ["ShippingTemplateCreate", "ShippingTemplateUpdate"],
                hidden: true
            },
            {
                title: "shipping_templates.title",
                path: "/shipping-templates",
                icon: <ShippingTemplatesIcon />,
                component: ShippingTemplates,
                grants: ["ShippingTemplateRead"]
            },
            {
                title: "auxiliardocuments.title",
                path: "/auxiliar-documents/new",
                icon: <AuxiliarDocumentsIcon />,
                component: AuxiliarDocumentForm,
                grants: ["AuxiliarDocumentsCreate"],
                hidden: true
            },
            {
                title: "auxiliardocuments.edit",
                path: "/auxiliar-documents/:auxiliarDocumentId/edit",
                icon: <AuxiliarDocumentsIcon />,
                component: AuxiliarDocumentForm,
                grants: ["AuxiliarDocumentsUpdate"],
                hidden: true
            },
            {
                title: "auxiliardocuments.title",
                path: "/auxiliar-documents",
                icon: <AuxiliarDocumentsIcon />,
                component: AuxiliarDocuments,
                grants: ["AuxiliarDocumentsRead"],
            },
            {
                title: "workflows.edit",
                path: "/workflows/:workflowId/edit",
                icon: <WorkflowsIcon />,
                component: WorkflowEditForm,
                grants: ["WorkflowsCreate", "WorkflowsUpdate"],
                hidden: true
            },
            {
                title: "workflows.new",
                path: "/workflows/new",
                icon: <WorkflowsIcon />,
                component: WorkflowForm,
                grants: ["WorkflowsCreate", "WorkflowsUpdate"],
                hidden: true
            },
            {
                title: "workflows.title",
                path: "/workflows",
                icon: <WorkflowsIcon />,
                component: Workflows,
                grants: ["WorkflowsRead"]
            },
            {
                title: "requisitions.teams.create_title",
                path: "/teams/create",
                icon: <TeamIcon />,
                component: TeamDetails,
                grants: ["TeamsCreate"],
                hidden: true
            },
            {
                title: "requisitions.teams.edit_title",
                path: "/teams/:teamId/:edit",
                icon: <TeamIcon />,
                component: TeamDetails,
                grants: ["TeamsUpdate"],
                hidden: true
            },
            {
                title: "requisitions.teams.detail_title",
                path: "/teams/:teamId",
                icon: <TeamIcon />,
                component: TeamDetails,
                grants: ["TeamsRead"],
                hidden: true
            },
            {
                title: "requisitions.teams.title",
                path: "/list-teams",
                icon: <TeamIcon />,
                component: Teams,
                grants: ["TeamsUpdate"],
            }, {
                title: "requisitions.projects.to_start",
                path: "/projects/to-start/success/:success",
                icon: <ProjectToStartIcon />,
                component: ProjectsView,
                grants: ["ProjectsAdminRead"],
                hidden: true,
            },
            {
                title: "currencies.title",
                path: "/currencies",
                icon: <CurrencyIcon />,
                component: CurrenciesList,
                grants: ["TenantConfigurationsUpdate"],
            },
            {
                title: "billing.title",
                path: "/billings/:billingId/manual",
                icon: <BillingIcon />,
                component: PaymentManualForm,
                grants: ["TenantsBillingsUpdate"],
                hidden: true
            },
            {
                title: "billing.title",
                path: "/billings",
                icon: <BillingIcon />,
                component: Billings,
                grants: ["TenantsBillingsRead", "BillingsRead"],
                hidden: true
            },
            {
                title: "bank_parsers.title",
                path: "/configurations/bank",
                icon: <BankIcon />,
                component: BankForm,
                grants: ["EmployerNumbersUpdate", "ProviderConfigurationsUpdate"],
            },
            {
                title: "employer.title",
                path: "/configurations/employer-numbers",
                icon: <EmployerNumberIcon />,
                component: EmployerNumberForm,
                grants: ["EmployerNumbersUpdate", "ProviderConfigurationsUpdate"],
            },
            {
                title: "specializedservicedocument.title",
                path: "/configurations/specialized-service-documents",
                icon: <SpecializedServiceDocumentsIcon />,
                component: SpecializedServiceDocuments,
                grants: ["SpecializedServiceDocumentsRead"],
            },
            {
                title: "calendar_events.title",
                path: "/configurations/calendar-events",
                icon: <CalendarIcon />,
                component: CalendarManagementView,
                grants: ["CalendarEventRead"],
            },
            {
                title: "prorationtemplates.edit",
                path: "/proration-templates/:prorationTemplateId/edit",
                icon: <ProrationTemplatesIcon />,
                component: ProrationTemplateForm,
                grants: ["ProrationTemplatesUpdate"],
                hidden: true
            },
            {
                title: "prorationtemplates.new",
                path: "/proration-templates/new",
                icon: <ProrationTemplatesIcon />,
                component: ProrationTemplateForm,
                grants: ["ProrationTemplatesCreate"],
                hidden: true
            },
            {
                title: "prorationtemplates.title",
                path: "/proration-templates",
                icon: <ProrationTemplatesIcon />,
                component: ProrationTemplatesList,
                grants: ["ProrationTemplatesRead"],
            },
            {
                title: "cost_centers.edit",
                path: "/cost-centers/:costCenterId/edit",
                icon: <CenterFocusWeakIcon />,
                component: CostCenterForm,
                grants: ["CostCenterUpdate"],
                hidden: true
            },
            {
                title: "cost_centers.new",
                path: "/cost-centers/new",
                icon: <CenterFocusWeakIcon />,
                component: CostCenterForm,
                grants: ["CostCenterCreate"],
                hidden: true
            },
            {
                title: "cost_centers.title",
                path: "/cost-centers",
                icon: <CenterFocusWeakIcon />,
                component: CostCenterList,
                grants: ["CostCenterRead"],
            },
            {
                title: "expressions.title",
                path: "/expressions",
                icon: <ExpressionsIcon />,
                component: Expressions,
                grants: ["ExpressionsRead"],
            }
        ]
    },
    {
        title: "integrations.title",
        path: "",
        icon: <IntegrationsIcon />,
        grants: ["KeyPairsRead", "IntegrationLogsRead", "WebhooksRead"],
        items: [
            {
                title: "keypairs.title",
                path: "/keypairs/new",
                icon: <VpnKeyIcon />,
                component: KeyPairForm,
                grants: ["KeyPairsCreate"],
                hidden: true
            },
            {
                title: "keypairs.title",
                path: "/keypairs/:keyPairId",
                icon: <VpnKeyIcon />,
                component: KeyPairForm,
                grants: ["KeyPairsUpdate"],
                hidden: true
            },
            {
                title: "keypairs.title",
                path: "/keypairs",
                icon: <VpnKeyIcon />,
                component: KeyPairs,
                grants: ["KeyPairsRead"],
            },
            {
                title: "webhooks.title",
                hidden: true,
                path: "/webhooks/new",
                icon: <WebhookIcon />,
                component: WebhooksForm,
                grants: ["WebhooksCreate"]
            },
            {
                title: "webhooks.title",
                hidden: true,
                path: "/webhooks/:webhookId/edit",
                icon: <WebhookIcon />,
                component: WebhooksForm,
                grants: ["WebhooksUpdate"]
            },
            {
                title: "webhook_events",
                path: "/webhooks/:webhookId/:webhookName/events",
                hidden: true,
                icon: <WebhookIcon />,
                component: WebhookEventList,
                grants: ["WebhooksRead"]
            },
            {
                title: "webhooks",
                path: "/webhooks",
                icon: <WebhookIcon />,
                component: WebhooksList,
                grants: ["WebhooksRead"]
            },
            {
                title: "webhook_event_tries",
                path: "/:eventId/webhook-event-tries",
                hidden: true,
                icon: <WebhookIcon />,
                component: WebhookEventTries,
                grants: ["WebhooksRead"]
            },
            {
                title: "integration_logs.title",
                path: "/zip-uploads/:zipUploadId/integration-logs",
                icon: <IntegrationLogIcon />,
                component: IntegrationLogs,
                grants: ["IntegrationLogsRead"],
                hidden: true,
            },
            {
                title: "zip_uploads.title",
                path: "/zip-uploads",
                icon: <ZipUploadsIcon />,
                component: ZipUploads,
                grants: ["ZipUploadsRead"]
            },
            {
                title: "zip_downloads.title",
                path: "/zip-downloads",
                icon: <ZipDownloadsIcon />,
                component: ZipDownloads,
                grants: ["ZipDownloadsRead"]
            },
            {
                title: "integration_logs.title",
                path: "/integration-logs",
                icon: <IntegrationLogIcon />,
                component: IntegrationLogs,
                grants: ["IntegrationLogsRead"]
            }
        ]
    },
    {
        title: "cfdis.show_details",
        path: "/cfdis/:cfdiId/details_related",
        icon: <CfdisToSendIcon />,
        component: CfdiOverview,
        grants: ["AdminCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead"],
        hidden: true,
    },
    {
        title: "cfdis.show_details",
        path: "/cfdis/:cfdiId/details",
        icon: <CfdisToSendIcon />,
        component: CfdiOverview,
        grants: ["AdminCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead", "RefundsRead", "RefundsCxcRead"],
        hidden: true,
    },
    {
        title: "cfdis.show_details",
        path: "/cfdis/:cfdiId/purchase-order",
        icon: <CfdisToSendIcon />,
        component: RelateLinesPoConceptsCfdi,
        grants: ["AdminCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead", "RefundsRead", "RefundsCxcRead"],
        hidden: true,
    },
    {
        title: "cfdi.new_cfdi",
        path: "/providerCfdis/new",
        icon: <CfdisToSendIcon />,
        component: ProviderCfdiForm,
        grants: ["ProviderCFDIsCreate", "SenderCFDIsCreate"],
        hidden: true,
    },
    {
        title: "cfdi.new_cfdi",
        path: "/owner-cfdis/new",
        icon: <CfdisToSendIcon />,
        component: AdminCfdiForm,
        grants: ["AdminCFDIsCreate", "CxpCFDIsCreate", "AddCfdi"],
        hidden: true,
    },
    {
        title: "cfdi.new_cfdi",
        path: "/receptorCfdis/new",
        icon: <CfdisToSendIcon />,
        component: ReceptorCfdiForm,
        grants: ["ReceptorCFDIsCreate"],
        hidden: true,
    },
    {
        title: "cfdi.new_cfdi",
        path: "/expenses-accounts/:expensesAccountId/cfdi",
        icon: <CfdisToSendIcon />,
        component: ExpensesAccountCfdiForm,
        grants: ["ProviderCFDIsCreate", "SenderCFDIsCreate"],
        hidden: true
    },
    {
        title: "cfdi.new_cfdi",
        path: "/expenses-accounts/:expensesAccountId/honorarium",
        icon: <CfdisToSendIcon />,
        component: ExpensesAccountHonorariumCfdiForm,
        grants: ["ProviderCFDIsCreate", "SenderCFDIsCreate"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.in_review",
        path: "/plan-tax-stamp/:providerMembershipTaxStampId/in-review",
        icon: <CfdisToSendIcon />,
        component: PlanTaxStampInReview,
        grants: ["PlanTaxStampRead"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.plans",
        path: "/plan-tax-stamp/plans",
        icon: <CfdisToSendIcon />,
        component: PlansProvider,
        grants: ["ProviderPackagesTaxStampCreate"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.plans",
        path: "/plan-tax-stamp/:externalId/buy-plan",
        icon: <CfdisToSendIcon />,
        component: BuyPlanProvider,
        grants: ["ProviderPackagesTaxStampCreate"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.plans",
        path: "/plan-tax-stamp/certificate",
        icon: <VerifiedUserIcon />,
        component: ConfigureCertificate,
        grants: ["ProviderTaxStampCsdCreate"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.plans",
        path: "/plan-tax-stamp/:providerMembershipTaxStampId/info-payment",
        icon: <CfdisToSendIcon />,
        component: InfoPaymentPlanProvider,
        grants: ["ProviderPackagesTaxStampCreate"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.plans",
        path: "/tax-stamp/manifest",
        icon: <VerifiedUserIcon />,
        component: SignManifest,
        grants: ["ProviderSignManifest"],
        hidden: true
    },
    {
        title: "plan_tax_stamp.plans",
        path: "/tax-stamp/certificate",
        icon: <VerifiedUserIcon />,
        component: ConfigureCertificate,
        grants: ["ProviderTaxStampCsdRead"],
        hidden: true
    }
] as RouteGenericItem[];

export type RouteGenericItem = RouteItem | "integration";

export interface RouteItem {
    title: string;
    titleRaw?: string;
    path: string;
    icon: React.ReactElement;
    component?: React.FunctionComponent;
    exact?: boolean;
    grants?: string[];
    exclude?: string[];
    items?: RouteItem[];
    hidden?: boolean;
}
