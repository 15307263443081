import { callAPI, HTTPMethod } from "./API";
import { Banks } from "../model/Bank";

export async function getBanks(search: string): Promise<Banks> {
    return await callAPI(`/bank-account`, {
        method: HTTPMethod.GET,
        query: {
            search: search
        }
    });
}