import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { TenantBankAccount } from "../model/BankAccount";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";

interface BankAccountManagementMenuProps {
    account: TenantBankAccount;
    anchor: HTMLElement | null;
    onClose(): any;
    onDeleteAccount(): any;
}

export default function BankAccountManagementMenu(props: BankAccountManagementMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("BankAccountAdminUpdate") &&
                <MenuItem button component={Link} to={`/bank-accounts/${props.account.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            <Divider />
            {context.isGranted("BankAccountAdminDelete") &&
                <MenuItem button onClick={props.onDeleteAccount} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 