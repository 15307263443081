import React, { useState, useContext, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getSession, updateSession } from "../api/MeAPI"
import { logout } from "../api/AuthAPI"
import ExternApp from "./ExternApp";
import InternApp from "./InternApp";
import { AppContext } from "./AppContext";
import { SessionBody, Session } from '../model/Session';
import { listCfdiStatus } from "../api/TenantCfdiApi";
import { StatusesCfdi } from "../model/Cfdi";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Route, useHistory } from "react-router-dom";
import { LoginCallback, Security } from "@okta/okta-react";


const useStyles = makeStyles(theme => ({
    box: {
        display: "flex",
        justifyContent: "center",
    },
    progress: {
        margin: theme.spacing(4),
    },
}));

export default function SessionHolder() {
    const context = useContext(AppContext);
    const classes = useStyles();
    const [status, setStatus] = useState<"unknown" | "out" | "in" | "switching">("unknown");
    const [completed, setCompleted] = useState<number>(0);
    const [error, setError] = useState<string>();
    const [oktaAuth, setOktaAuth] = useState<OktaAuth>();
    const history = useHistory();

    const cfdi_status = "cfdi_status";

    context.removeSession = () => {
        setStatus("switching");
        logout().then(() => {
            setStatus("out");
            context.setSession(undefined);
            document.getElementById('fc_frame')?.remove(); //Para remover chat
            document.getElementById('fc_push_frame')?.remove(); //Para remover chat
            removeCfdiStatus();
        }).catch((error) => {
            setError(error.message);
            setStatus("in");
        });
    };

    context.onSignedIn = () => {
        setCompleted(new Date().getTime());
        window.location.reload(); // Para que cargue el chat
    };

    context.onChangedTenant = (body: SessionBody) => {
        setStatus("switching");
        updateSession(body).then((session) => {
            window.location.href = "/";
            loadCfdiStatus(session);
        }).catch((error) => {
            setError(error.message);
            setStatus("in");
        });
    };

    const loadCfdiStatus = (session: Session) => {
        if(!!session.tenant?.id){
            listCfdiStatus(session!.tenant!.id, "ALL").then((response) => {
                localStorage.setItem(cfdi_status, JSON.stringify(response));
            }).catch((error) => {
                localStorage.setItem(cfdi_status, JSON.stringify({ items: [], total: 0 } as StatusesCfdi));
            })
        }
    }

    const removeCfdiStatus = () => {
        localStorage.removeItem(cfdi_status);
    }

    useEffect(() => {
        getSession().then(session => {
            if (session.sso_config) {
                const authClient = new OktaAuth({
                    issuer: session.sso_config.access_token_issuer_url,
                    clientId: session.sso_config.client_id,
                    redirectUri: session.sso_config.redirect_url
                });
                setOktaAuth(authClient);
            }

            context.setSession(session);
            setStatus("in");
            loadCfdiStatus(session);
        }).catch((_) => {
            context.setSession(undefined);
            setStatus("out");
        });
        // eslint-disable-next-line
    }, [completed]);

    if (status === "unknown") {
        return (
            <div className={classes.box}>
                <CircularProgress className={classes.progress} />
            </div>
        );
    } else if (status === "out") {
        return <ExternApp />;
    }

    const onSignOut = () => {
        if(oktaAuth) {
          oktaAuth.signOut()
          .then(() => {
            context.removeSession();
          }).catch((e) => {
            console.error(e);
          });
        } else {
          context.removeSession();
        }
      };

      const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri || '/oauth-login', window.location.origin));
      };

      if(oktaAuth) {
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <InternApp backdrop={status === "switching"} error={error} onCleanError={() => setError(undefined)}
              onSignOut={onSignOut}/>;
            <Route path="/oauth-login/callback" component={LoginCallback} />
        </Security>
      }


    return <InternApp backdrop={status === "switching"} error={error} onCleanError={() => setError(undefined)} onSignOut={onSignOut} />;
}
