import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import translate from "../i18n/Translator";

import { Grid, IconButton, Typography, NativeSelect, InputBase, Box } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Surface from "./Surface";

interface PaginationProps {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    icon: React.ReactNode;
    children: React.ReactNode;
    action?: React.ReactNode;
    page: number;
    pageSize: number;
    count: number;
    total: number;
    variant?: "section" | "embeded";
    backButton?: boolean;
    backTo?: string;
    onChangedPage(page: number): void;
    onChangedPageSize(page: number, pageSize: number): void;
}

const useStyles = makeStyles(theme => ({
    actions: {
        padding: "0 16px"
    },
    text: {
        fontSize: "0.875rem",
        fontWeight: 500
    }
}));

export default function Pagination(props: PaginationProps) {
    const classes = useStyles();
    const history = useHistory();
    const from = props.pageSize * (props.page - 1) + (props.count === 0 ? 0 : 1);
    const to = props.pageSize * (props.page - 1) + props.count;
    const sizes = [20, 50, 100];
    const qs = queryString.parse(window.location.search);

    const changedPageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
        qs["page"] = "1";
        qs["page_size"] = event.target.value;
        updateRoute();
        props.onChangedPageSize(1, parseInt(event.target.value));
    };

    const nextPage = () => {
        qs["page"] = (props.page + 1).toString();
        updateRoute();
        props.onChangedPage(props.page + 1);
    };

    const previousPage = () => {
        qs["page"] = (props.page - 1).toString();
        updateRoute();
        props.onChangedPage(props.page - 1);
    };

    const updateRoute = () => {
        if (props.variant !== "embeded") {
            let url = window.location.pathname + "?" + queryString.stringify(qs);
            history.push(url);
        }
    };

    const footerActions = () => {
        return (
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs="auto">
                    <NativeSelect onChange={changedPageSize} value={props.pageSize} className={classes.text} input={<InputBase />}>
                        {sizes.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>{pageSize}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs="auto">
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid item xs="auto">
                            <Typography component="span" variant="body2" className={classes.text}>
                                {translate("pagination.info", { "from": from, "to": to, "total": props.total })}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <IconButton aria-label="Previous page" onClick={previousPage} disabled={props.page === 1}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <IconButton aria-label="Next page" onClick={nextPage} disabled={props.count === 0 || props.count < props.pageSize}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    if (props.variant === "embeded") {
        return (
            <Grid container className="PaperPagination">
                <Grid item xs={12}>
                    {props.children}
                </Grid>
                <Grid item xs={12}>
                    <Box pl={2}>
                        {footerActions()}
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Surface title={props.title} 
            subtitle={props.subtitle} 
            icon={props.icon} 
            titleActions={props.action} 
            footerActions={footerActions()} 
            backButton={props.backButton}
            backTo={props.backTo}
            className="PaperPagination">
            {props.children}
        </Surface>
    );
}

export const initialPagination = (callback: (page: number, pageSize: number) => any) => {
    const qs = queryString.parse(window.location.search);
    let page = typeof qs["page"] === "string" ? parseInt(qs["page"]) : 1;
    let pageSize = typeof qs["page_size"] === "string" ? parseInt(qs["page_size"]) : 1;
    callback(page, pageSize);
};

export const initialPage = (query?: URLSearchParams): number => {
    if (query) {
        const value = parseInt(query.get('page') || '')
        return isNaN(value) ? 1 : value
    }

    const qs = queryString.parse(window.location.search);
    return typeof qs["page"] === "string" ? parseInt(qs["page"]) : 1;
}

// TODO cambiar al nombre original 'initialPage' al terminar de reemplazar el componente
export const initialPageZero = (): number => {
    const qs = queryString.parse(window.location.search);
    return typeof qs["page"] === "string" ? parseInt(qs["page"]) : 0;
}

export const initialPageSize = (query?: URLSearchParams): number => {
    if (query) {
        const value = parseInt(query.get('pageSize') || '')
        return isNaN(value) ? 20 : value
    }

    const qs = queryString.parse(window.location.search);
    return typeof qs["page_size"] === "string" ? parseInt(qs["page_size"]) : 20;
}

export const initialPageSizeOrElse = (pageSizeDefault: number, query?: URLSearchParams): number => {
    if (query) {
        const value = parseInt(query.get('pageSize') || '')
        return isNaN(value) ? pageSizeDefault : value
    }

    const qs = queryString.parse(window.location.search);
    return typeof qs["page_size"] === "string" ? parseInt(qs["page_size"]) : pageSizeDefault;
}

export const getOffset = (page: number, pageSize: number): number => {
    return (page - 1) * pageSize;
}