import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";

import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";
import { CfdiConceptos as CfdiConceptosResponse } from "../model/Cfdi";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import Pagination, { initialPage, initialPageSizeOrElse, getOffset } from "../components/Pagination";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { listCfdiConceptos } from "../api/TenantCfdiApi";

interface CfdiConceptosProps {
    cfdiId: string;
    moneda: string;
}

export default function CfdiConceptos(props: CfdiConceptosProps) {

    const context = useContext(AppContext);
    const [concepts, setConcepts] = useState<CfdiConceptosResponse>();
    const [status, setStatus] = useState<string>("loading");
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(initialPageSizeOrElse(100));

    const load = () => {
        setStatus("loading");
        let offset = getOffset(page, pageSize);
        listCfdiConceptos(context.session!.tenant!.id, props.cfdiId, pageSize, offset).then((response) => {
            setConcepts(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setConcepts(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setConcepts(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    return (
        <Pagination variant={"embeded"} title={translate("requisitions.types.title") as string} icon={<ListAltIcon />}
            page={page} pageSize={pageSize} count={concepts ? concepts.items.length : 0} total={concepts ? concepts.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                    <Gridable
                        items={concepts?.items || []}
                        loading={false}
                        empty={translate("cfdis.details.concept_empty") as string}
                        columns={[
                            {
                                title: translate("cfdis.details.quantity") as string,
                                converter: (concept) => (<Ellipsis text={concept.cantidad + ""} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.sat_key") as string,
                                converter: (concept) => (<Ellipsis text={concept.clave_producto_servicio} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.unit") as string,
                                converter: (concept) => (<Ellipsis text={concept.unidad || ""} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.description") as string,
                                converter: (concept) => (<Ellipsis text={concept.description} lenght={100} uppercased={false} />),
                                xs: true,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.unit_price", { "currency": props.moneda }) as string,
                                converter: (concept) => (<NumberFormat value={concept.valor_unitario} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />),
                                xs: false,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.price", { "currency": props.moneda }) as string,
                                converter: (concept) => (<NumberFormat value={concept.importe} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />),
                                xs: true,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                        ]} />
            </Grid>
        </Grid>
        </Pagination>
    );
}