import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Hidden } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/PublishTwoTone";
import AddUsersIcon from '@material-ui/icons/GroupAddTwoTone';
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheckTwoTone";
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import GetAppIcon from "@material-ui/icons/GetAppTwoTone";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottomTwoTone';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface ProvidersEfosMenuProps {
    anchor: HTMLElement | null;
    totalItems?: number;
    onClose(): any;
    onImportProviders(): any;
    onDownloadTemplate(): any;
    onImportUsers(): any;
    onVerifyEfoAll(): any;
    onCff69All(): any;
}

export default function ProvidersEfosMenu(props: ProvidersEfosMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
            autoFocus={false}
            variant="selectedMenu"
        >
            {context.isGranted("ProvidersCreate") &&
                <div>
                    <Hidden implementation="css" smUp>
                        <MenuItem button onClick={props.onImportProviders} dense>
                            <ListItemIcon>
                                <PublishIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <Typography variant="inherit">{translate("providers.import.button")}</Typography>
                        </MenuItem>
                    </Hidden>
                </div>
            }
            {context.isGranted("OwnerProvidersUsersCreate") &&
                <div>
                    <Hidden implementation="css" smUp>
                        <MenuItem button onClick={props.onImportUsers} dense>
                            <ListItemIcon>
                                <AddUsersIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <Typography variant="inherit">{translate("providers.import_users.button")}</Typography>
                        </MenuItem>
                    </Hidden>
                </div>
            }
            {context.isGranted("ProvidersCreate") &&
                <MenuItem button onClick={props.onDownloadTemplate} dense>
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.import.download")}</Typography>
                </MenuItem>
            }
            {context.isGranted("OwnerProvidersUsersCreate") &&
                <MenuItem button onClick={props.onClose} dense component="a" href="/plantilla-importacion-usuarios-proveedor.zip" target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <VerticalAlignBottomIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.import_users.download")}</Typography>
                </MenuItem>
            }
            {context.isGranted("EfosUpdate") &&
                <MenuItem button onClick={props.onVerifyEfoAll} dense>
                    <ListItemIcon>
                        <PlaylistAddCheckIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.verify_all_efos")}</Typography>
                </MenuItem>
            }
            {context.isGranted("Cff69Update") &&
                <MenuItem button onClick={props.onCff69All} dense>
                    <ListItemIcon>
                        <AssignmentTurnedInTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.verify_all_cff_69")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

} 