import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Tenant, TenantsQueryParams, Tenants } from "../model/Tenant";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import GridDx from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { Column, Filter } from '@devexpress/dx-react-grid';
import { WarningSnackbar } from "../components/Snackbars";

interface FocaltecAnnouncementSelectRecipientsPopUpProps {
    tenants: Tenant[];
    onClose(): any;
    doAction(tenantsSelected: Tenant[]): any;
}

export default function FocaltecAnnouncementSelectRecipientsPopUp(props: FocaltecAnnouncementSelectRecipientsPopUpProps) {

    const paramsToFilters = () => {
        return [
            { columnName: "issuer.name", value: params?.search } as Filter,
        ] as Filter[];
    };

    const paramsFromQueryString = (): TenantsQueryParams => {
        return {
            search: "",
        } as TenantsQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Tenants>();
    const [selected, setSelected] = useState<string[]>([]);
    const [params] = useState<TenantsQueryParams>(paramsFromQueryString);
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());
    const [dataHandler, setDataHandler] = useState<Tenant[]>([]);
    const [warning, setWarning] = useState("");

    function loadCfdis() {
        let items = params.search && params.search !== "" ?
            props.tenants.filter(c => c.name.search(new RegExp(params.search, "i")) > -1)
            : props.tenants;
            
            setData({ items: items, total: items.length } as Tenants);
        for (const item of (items ?? [])) {
            const finded = dataHandler.find(el => el.id === item.id);
            if (!finded) {
                dataHandler.push(item);
            }
        }
        setStatus("loaded");
    }

    useEffect(() => {
        loadCfdis();
    // eslint-disable-next-line
    }, [props]);


    const onConfirm = () => {
        if(!selected || selected.length < 1){
            setWarning(translate("focaltec_announcements.empty_selected") as string);
            return;
        }
        props.doAction(dataHandler?.filter(item => selected.find(itemsSelected => item.id === itemsSelected)));
    };

    const onClickedOptions = (tenant: Tenant) => { };

    const setSelectionHandler = (selected: (string)[]) => {
        setSelected(selected);
    };

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "issuer.name":
                        params.search = filter.value;
                        break;
                    
                    default: break;
                }
            }
        });
        setSelected([]);
        loadCfdis();
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        if (value) {
            params.search = value;
            setSelected([]);
            loadCfdis();
        }
    };

    const onClosedSnackbar = () => {
        setWarning("");
    };

    const [tableColumnExtensionsP] = useState([
        { columnName: 'issuer.name', width: 500, wordWrapEnabled: true },
    ]) as any;

    const columns = [
        {
            name: 'issuer.name',
            title: translate("focaltec_announcements.columns.tenants") as string,
            getCellValue: (item: any) => item.name ?? '-'
        },
    ] as Column[];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={['issuer.name']} editorComponent={NoFilterEditor} />,

    ];

    return (
        <DialogPopup open
            title={translate("focaltec_announcements.select_recipients") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            maxWidth="lg"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={
                <Box pl={1}>
                    <Button onClick={onConfirm} variant="outlined" color="primary" >
                        {translate("buttons.add") as string}
                    </Button>
                </Box>
            }>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start" >
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {translate("focaltec_announcements.select_message") as string}
                            </Typography>
                        </Grid>
                        <ValidatedInput type="text" id="search" name="search"
                            value={params.search}
                            label={translate("focaltec_announcements.fast_search") as string}
                            required={true} disabled={false}
                            margin={"dense"}
                            onValueChanged={hasChanged} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <GridDx
                                loading={status === "loading"}
                                rows={data ? data.items : []}
                                columns={columns}
                                defaultExpandedGroups={[]}
                                onClickRow={(v) => { }}
                                clickRowColumns={[]}
                                amountCurrencyColumns={[]}
                                quantityColumns={[]}
                                dateColumns={[]}
                                columnsFormat={tableColumnExtensionsP}
                                leftColumns={[]}
                                textColumns={['issuer.name']}
                                rightColumns={[]}
                                grouping={[]}
                                totalRows={data ? data.total : 0}
                                selectionIds={selected}
                                sorting={[]}
                                filters={filters}
                                customFormatColumns={customPlugins}
                                setFiltersHandler={setFiltersHandler}
                                setSelectionHandler={setSelectionHandler}
                                onClickedOptions={onClickedOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}
