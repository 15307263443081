import { Expiration } from "./Expiration";

export interface TenantFieldCatalog {
  fields?: TenantField[];
  addresses?: TenantAddress[];
  contacts?: TenantContact[];
  bank_accounts?: TenantFieldBankAccount[];
}

export interface TenantSegmentsRequest<T extends TenantSegment> {
  segments: T[];
}

export interface TenantSegment {
  id: string;
  name: string;
  description?: string;
  required: boolean;
  privado?: boolean;
  expires_at?: Date;
  catalog_id?: string;
  user_group_id?: string;
}

export interface TenantFieldsRequest {
  ids: string[];
  type_field: string;
}

export interface TenantFields {
  segments: TenantSegment[];
}

export interface TenantField extends TenantSegment {
  type: 'TEXT' | 'NUMBER' | 'TEXTAREA' | 'FILE' | 'DATE' | 'CATALOG';
  expiration?: Expiration;
  extensions?: string;
  aux_file_name: string;
  aux_file_url: string;
  privado: boolean;
  use_in_cfdi: boolean;
  external_id: string;
}

export interface TenantAddress extends TenantSegment {
}

export interface TenantContact extends TenantSegment {
}

export interface TenantFieldBankAccount extends TenantSegment {
}

export const FieldTypes = ['TEXT', 'NUMBER', 'TEXTAREA', 'FILE', 'DATE', 'CATALOG'];

export const FieldStatus = ["ACCEPTED", "NOT_ACCEPTED", "EXPIRED"];
