import { UserGroup, UserGroupQueryParams, UserGroupRequest, UserGroups } from "../model/UserGroup";
import { HTTPMethod, callAPI } from "./API";

const basePath = (tenantId: string) => `/tenants/${tenantId}/user-group`;

export async function getUserGroups(tenantId: string, page: number, offset: number, params: UserGroupQueryParams): Promise<UserGroups> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function getUserGroup(tenantId: string, userGroupId: string): Promise<UserGroup> {
    return await callAPI(`${basePath(tenantId)}/${userGroupId}`, {
        method: HTTPMethod.GET
    });
}

export async function createGroup(tenantId: string, request: UserGroupRequest): Promise<UserGroup> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function updateGroup(tenantId: string, groupId: string, request: UserGroupRequest): Promise<UserGroup> {
    return await callAPI(`${basePath(tenantId)}/${groupId}/update`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteGroup(tenantId: string, groupId: string): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/${groupId}`, {
        method: HTTPMethod.DELETE,
    });
}

export async function getUserGroupsByMember(tenantId: string, userId: string): Promise<UserGroups> {
    return await callAPI(`${basePath(tenantId)}/by-member/${userId}`, {
        method: HTTPMethod.GET
    });
}
