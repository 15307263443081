import React, { useState } from "react";
import { Box, Button, Grid, IconButton } from '@material-ui/core';
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import ValidatedInput from "../components/ValidatedInput";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { TaxEquivalence, TaxEquivalenceRequest } from "../model/TenantConfiguration";
import CustomBackdrop from "../components/CustomBackdrop";
import Gridable from "../components/Gridable";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import { AttachmentIcon } from "../components/Icons";
import { addTaxEquivalences } from "../api/TenantConfigurationApi";
interface TenantConfigurationSAPTaxEquivalenceFormProps {
    taxEquivalences: TaxEquivalence[];
    tenantId: string;
}
export default function TenantConfigurationSAPTaxEquivalenceForm(props: TenantConfigurationSAPTaxEquivalenceFormProps) {
    const [taxEquivalences, setTaxEquivalences] = useState<TaxEquivalence[]>(props.taxEquivalences ?? []);
    const [taxItem, setTaxItem] = useState<TaxEquivalence>({} as TaxEquivalence);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [submitting, setSubmitting] = useState(false);
    const taxes = ["IVA", "IEPS", "ISR"];
    const [request, setRequest] = useState<TaxEquivalenceRequest>();
    const onSubmit = () => {
        if (taxItem && !!taxItem.tax && !!taxItem.equivalence) {
            const tax = taxEquivalences.find(e => e.equivalence === taxItem.equivalence && e.tax === taxItem.tax);
            if (tax) {
                setError(translate("sap_tax_equivalence.item_already_exist") as string);
                return;
            }
            const items = [...taxEquivalences, taxItem]
            setTaxItem({} as TaxEquivalence);
            setRequest({
                tax_equivalences: items
            });
            setTaxEquivalences(items);
            return;
        }
        setError(translate("sap_tax_equivalence.field_requied") as string);
    };
    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };
    const onClickedDelete = (value: TaxEquivalence) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const newItems = taxEquivalences.filter(e => !(e.equivalence === value.equivalence && e.tax === value.tax));
        setTaxEquivalences(newItems);
        setRequest({
            tax_equivalences: newItems
        });
    };
    const onUpdate = () => {
        if (!!request?.tax_equivalences) {
            setSubmitting(true);
            addTaxEquivalences(props.tenantId, request).then(
                () => {
                    setSuccess(translate("sap_tax_equivalence.success") as string);
                    setSubmitting(false);
                }
            ).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
            return;
        } else {
            setError(translate("sap_tax_equivalence.empty_items") as string);
        }
    }
    const onValueChanged = (name: string, value: string) => {
        setTaxItem({ ...taxItem, [name]: value });
    };
    return (
        <Surface title={translate("sap_tax_equivalence.title")} icon={<AttachmentIcon />}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <ValidatedInput
                                type="text"
                                id="tax"
                                name="tax"
                                label={translate("sap_tax_equivalence.tax") as string}
                                required={false}
                                disabled={submitting}
                                onValueChanged={onValueChanged}
                                autocompleteOptions={taxes}
                                autocompleteValue={taxItem?.tax}
                                autocompleteKey={taxItem?.tax}
                                getId={(el) => el}
                                getLabel={(el) => el} />
                        </Grid>
                        <Grid item xs>
                            <ValidatedInput
                                type="text"
                                id="equivalence"
                                name="equivalence"
                                label={translate("sap_tax_equivalence.equivalence") as string}
                                required={false}
                                disabled={submitting}
                                margin={"dense"}
                                onValueChanged={onValueChanged}
                                value={taxItem?.equivalence}
                            />
                        </Grid>
                        <Grid item xs="auto" >
                            <Button size="medium" variant="outlined" color="secondary" onClick={onSubmit}>
                                {translate("buttons.add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Gridable
                        items={taxEquivalences}
                        loading={false}
                        empty={translate("sap_tax_equivalence.empty") as string}
                        columns={[
                            {
                                title: translate("sap_tax_equivalence.tax") as string,
                                converter: (value: any) => value?.tax ?? "",
                                fullWidth: true,
                                xs: true
                            },
                            {
                                title: translate("sap_tax_equivalence.equivalence") as string,
                                converter: (value: any) => value?.equivalence ?? "",
                                fullWidth: true,
                                xs: true
                            },
                            {
                                title: (
                                    <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                        <DeleteIcon />
                                    </IconButton>
                                ),
                                converter: (value) => (
                                    <IconButton
                                        aria-label="options"
                                        color="default"
                                        size="small"
                                        onClick={onClickedDelete(value)}>
                                        <DeleteIcon />
                                    </IconButton>
                                ),
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box pt={2}>
                        <Grid container justify="flex-end" spacing={1}>
                            <Grid item xs={12} md="auto">
                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting} onClick={onUpdate}>
                                    {translate("buttons.update")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <CustomBackdrop open={submitting} />
        </Surface>
    );
}