import { Cfdi, CfdisCancelled } from "../model/Cfdi";
import { CfdiHistories } from "../model/CfdiHistory";
import { ExtendedField } from "./ExtendedField";
import { MultiselectValue } from "../components/MultiselectDropList";

export const Statuses = ["PENDING_PAYMENT_CFDI", "NO_NEED_PAYMENT_CFDI", "PAYMENT_CFDI_ATTACHED", "INVALID", "IN_REVIEW_PAYMENT_CFDI", "REJECTED_PAYMENT_CFDI"];

export const StatusesNames = [
    { title: "Pendiente Complemento de pago", value: "PENDING_PAYMENT_CFDI" },
    { title: "Complemento de pago no necesario", value: "NO_NEED_PAYMENT_CFDI" },
    { title: "Complemento de pago registrado", value: "PAYMENT_CFDI_ATTACHED" },
    { title: "Pago no validado por proveedor", value: "INVALID" },
    { title: "Complemento de pago en revisión", value: "IN_REVIEW_PAYMENT_CFDI" },
    { title: "Complemento de pago rechazado", value: "REJECTED_PAYMENT_CFDI" },
    { title: "Entregado ERP", value: "DELIVERED_ERP" }];

export interface PaymentInfo {
    cfdi_payment_status: string;
    payments: Payments[];
}

export interface Payment {
    id: string,
    external_id: string,
    tenant_id: string;
    provider_id: string;
    provider: PaymentProviderInfo;
    total_amount: number;
    payment_date?: Date;
    deadline_payment_cfdi: Date;
    bank_account_id: string;
    operation_type: string;
    operation_name: string;
    operation_number: string;
    comments: string;
    user_id: string;
    status: string;
    payment_cfdi_id: string;
    payment_cfdi: PaymentCfdiInfo;
    reference: string;
    provider_name: string;
    payment_cfdi_expired: boolean;
    comprobantes: string;
    list_cfdis: Cfdi[];
    currency: string;
    cfdis: PaymentCfdi[];
    operation_file_url: string;
    bank_account_name: string;
    cfdis_cancelled?: CfdisCancelled;
    payment_process_history: ExtendedField[];
    company_id: string;
    company: PaymentCompanyInfo;
    company_name?: string;
    custom_status: string;
    count_cfdis: number;
}

export interface PaymentOrderCfdi {
    cfdi: string;
    amount: number;
    payment_amount: number;
    payment_currency: string;
    provider_id: string;
    status: string;
    currency?: string;
    reference_exchange_rate: number; // referencia de tipo de cambio del comprobante
}

export interface PaymentCfdi extends PaymentCfdiInfo {
    cfdi: string;
    amount: number;
    payment_amount: number;
    currency: string;
    exchange_rate: number;
}

export interface PaymentListParams {
    search: string;
    payment_status?: string;
    provider_id?: string;
    payments_ids?: string;
    operation_number?: string;
    provider_name?: string;
    comprobantes?: string;
    total_amount?: string;
    payment_start_date?: string;
    payment_end_date?: string;
    start_deadline_payment_cfdi?: string;
    end_deadline_payment_cfdi?: string;
    company_name?: string;
}

export interface CfdiReviewListParams {
    search: string;
    payments: string;
    name_provider: string;
    provider_id: string;
    name_company?: string;
}

export interface Payments {
    items: Payment[];
    total: number;
    custom_template?: boolean;
}

export interface PaymentDeleteRequest {
    comments: string;
}

export interface PaymentData {
    provider_id: string;
    provider_name: string;
    currency: string;
    amount: number;
    payment_method: string;
}

export interface CfdisSelected {
    cfdis: CfdiPayment[];
}

export interface CurrencyAndPaymentMethods {
    currency_payment_methods: CurrencyAndPaymentMethod[];
}

export interface CurrencyAndPaymentMethod {
    currency: string;
    payment_methods: string[];
}

export interface CfdiPayment extends Cfdi {
    amount: number;
    payment_amount: number;
    payment_currency: string;
    exchange_rate?: number;
    reference_exchange_rate: number; // referencia de tipo de cambio del comprobante
}

export interface PaymentRequest {
    currency: string;
    payment_method: string;
    cfdis: PaymentOrderCfdi[];
    payment_order_id: string;
    from_payment_order: boolean;
    provider_id: string;
    total_amount: number;
    bank_account_id: string;
    payment_date: Date;
    operation_type: string;
    reference: string;
    comments: string;
    operation_file_url: string;
    exchange_rate?: number;
}

export interface PayAllPaymentOrderRequest {
    total_amount: number;
    payment_form?: string;
    cfdis: PaymentOrderCfdi[];
    bank_account_id: string;
    payment_date?: Date;
    operation_type: string;
    comments?: string;
    payment_order_id: string;
    operation_file_url?: string;
    reference?: string;
    currency: string;
}

export interface ShortPayment {
    date: Date;
    documents: string;
    amount: number;
    currency: string;
}

export interface ValidatedPayment {
    valid: boolean;
    pago: ShortPayment;
    validation_message: string;
}

export interface PaymentCfdiAttacment {
    valid_cfdi: boolean;
    success_attach: boolean;
    allways_to_review: boolean;
    payment_cfdi: Cfdi;
    validated_payments: ValidatedPayment[];
    has_payment_cfdi_review: boolean;
}

export interface AttachPaymentCfdis {
    processed: number;
    validations: PaymentCfdiValidation[];
}

export interface PaymentCfdiValidation {
    name: string;
    code: string;
}

export interface CfdisByProviderParams {
    currency: string;
    payment_method: string;
    cfdi_type: string;
}

export interface ImportPaymentSummary {

    created: number;
    duplicated: number;
    failed: number;
    rejected: string[];
    doubles: string[];
    added: string[];
}

export interface PaymentCfdiReviewCfdis {
    amount: number;
    payed_amount: number;
    uuid: string;
    currency: string;
    currency_payed: string;
    cfdiId?: string;
}

export interface CfdiPaymentReview {
    id: string;
    payments: string;
    name_provider: string;
    amount_payments: Map<string, number>;
    amount_cfdi: Map<string, number>;
    comprobantes: string;
    payment_date: string;
    name_company: string;
}

export interface CfdisPaymentsReview {
    items: CfdiPaymentReview[];
    total: number;
}

export interface CfdiPaymentDetail {
    cfdi: Cfdi;
    payments: Payments;
    cfdi_history: CfdiHistories;
}

export interface PaymentCfdiReviewActionRequest {
    cfdi_id: string;
    authorize: boolean;
}

export interface StatusesPayment {
    items: MultiselectValue[];
    total: number;
}

export interface PaymentOperationFileRequest {
    file_url: string;
}

export interface BlockedDeleteResponse {
    content: boolean;
}

export interface PaymentProviderInfo {
    id: string;
    external_id: string;
    name: string;
    rfc: string;
}

export interface PaymentCompanyInfo {
    id: string;
    external_id: string;
    name: string;
    rfc: string;
}

export interface PaymentCfdiInfo {
    id: string;
    serie: string;
    folio: string;
    identificador: string;
    uuid: string;
    total: number;
}