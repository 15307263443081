import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";

import { listEmployeeVisits, deleteEmployeeVisit, sendExecutionEmployeeVisit, getEmployeeVisitReport } from "../api/EmployeeVisitAPI";
import { AppContext } from "../context/AppContext";
import { EmployeeVisits, EmployeeVisitQueryParams, EmployeeVisit, SendExecutionRequest, StatusesFilterVisit } from "../model/EmployeeVisit";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import ServiceVisitsMenu from "./EmployeeVisitsMenu";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { EmojiPeopleIcon } from "../components/Icons";
import DateFormat from "../components/DateFormat";
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function EmployeeVisitsList() {
    const context = useContext(AppContext);
    const history = useHistory();
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id || "";

    const query = new URLSearchParams(useLocation().search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<EmployeeVisits>();
    const [workingParams, setWorkingParams] = useState<EmployeeVisitQueryParams>({
        statuses: ["TO_SEND", "IN_EXECUTION"],
    } as EmployeeVisitQueryParams);

    const [employeeVisit, setEmployeeVisit] = useState<EmployeeVisit>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [openConfirmSendExecution, setOpenConfirmSendExecution] = useState<boolean>(false);
    const optionLabels = StatusesFilterVisit.map(statusFilter => translate("employee_visits.status_filter."+statusFilter) as string);
    const [statusFilter, setStatusFilter] = useState<string>("ACTIVE");

    const load = () => {
        const offset = getOffset(page, pageSize);
        setStatus("loading");
        listEmployeeVisits(tenantId, providerId, pageSize, offset, workingParams).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }

    useEffect(load, [tenantId, providerId, page, pageSize, workingParams]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let statuses = [] as string[];
        switch (statusFilter){
            case "ACTIVE":
                statuses = ["TO_SEND", "IN_EXECUTION"];
                break;
            case "FINALIZED":
                statuses = ["FINALIZED"];
                break;
            case "ALL":
                break;
        }
        setWorkingParams({ ...workingParams, statuses: statuses });
        query.set("statuses", statuses.join(","));
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setStatusFilter(value);
    };

    const onClickedOptions = (employeeVisit: EmployeeVisit) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setEmployeeVisit(employeeVisit);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setEmployeeVisit(undefined);
    };

    const onClickRow = (employeeVisit: EmployeeVisit) => {
        if(employeeVisit.status !== "FINALIZED"){
            history.push(`/employee-visits/${employeeVisit.id}/edit`);
        }
    };

    const onDownloadList = () => {
        setAnchorEl(null);
        if(!employeeVisit) return;
        setProcessing(true);
        getEmployeeVisitReport(tenantId, providerId, employeeVisit.id).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setProcessing(false);
        });
    };

    const onDelete = () => {
        setAnchorEl(null);
        if(!employeeVisit) return;
        setOpenConfirmDelete(true);
    };

    const onCancelDelete = () => {
        setEmployeeVisit(undefined);
        setOpenConfirmDelete(false);
    };

    const onConfirmDelete = () => {
        if(!employeeVisit) return;
        setOpenConfirmDelete(false);
        setProcessing(true);
        deleteEmployeeVisit(tenantId, providerId, employeeVisit.id).then((_) => {
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setEmployeeVisit(undefined);
            setProcessing(false);
        });
    };

    const onSendExecution = () => {
        setAnchorEl(null);
        if(!employeeVisit) return;
        setOpenConfirmSendExecution(true);
    };

    const onCancelSendExecution = () => {
        setEmployeeVisit(undefined);
        setOpenConfirmSendExecution(false);
    };

    const onConfirmSendExecution = (comment: string) => {
        if(!employeeVisit) return;
        setOpenConfirmSendExecution(false);
        setProcessing(true);
        sendExecutionEmployeeVisit(tenantId, providerId, employeeVisit.id, { comment: comment } as SendExecutionRequest).then((_) => {
            load();
            setSuccess(translate("employee_visits.success_execution") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setEmployeeVisit(undefined);
            setProcessing(false);
        });
    };

    return (
        <Pagination title={translate("employee_visits.title")} icon={<EmojiPeopleIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["EmployeeVisitCreate"]) ?
                (<Link to="/employee-visits/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={9}>
                        
                    </Grid>
                    <Grid item xs={2}>
                        <ValidatedInput type="text" id="search" name="search" label={translate("employees.filter") as string}
                            margin="dense" disabled={false}
                            options={StatusesFilterVisit}
                            optionLabels={optionLabels}
                            value={statusFilter} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("employee_visits.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("employee_visits.columns.start_date") as string,
                        converter: (employeeVisit) => <DateFormat date={employeeVisit.start_date} format="DD/MM/YYYY" />,
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("employee_visits.columns.end_date") as string,
                        converter: (employeeVisit) => <DateFormat date={employeeVisit.end_date} format="DD/MM/YYYY" />,
                        fullWidth: true,
                        xs: 4,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 4
                    },
                    {
                        title: translate("employee_visits.columns.description") as string,
                        converter: (employeeVisit) => employeeVisit.description,
                        fullWidth: true,
                        xs: false,
                        sm: 3,
                        md: 3,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("employee_visits.columns.supervisor") as string,
                        converter: (employeeVisit) => employeeVisit.supervisor_name,
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("employee_visits.columns.status.title") as string,
                        converter: (employeeVisit) => translate(`employee_visits.columns.status.${employeeVisit.status}`) as string,
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (employeeVisit) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(employeeVisit)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {employeeVisit && anchorEl &&
                <ServiceVisitsMenu employeeVisit={employeeVisit} anchor={anchorEl} onClose={onCloseOption} onDelete={onDelete} onSendExecution={onSendExecution} onDownloadList={onDownloadList}/>
            }
            {openConfirmDelete &&
                <ConfirmationPopup
                    title={translate("employee_visits.delete_modal.title") as string}
                    message={translate("employee_visits.delete_modal.message") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDelete}
                    doAction={onConfirmDelete}
                />
            }
            {openConfirmSendExecution &&
                <ConfirmationPopup
                    title={translate("employee_visits.send_execution_modal.title") as string}
                    message={translate("employee_visits.send_execution_modal.message") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelSendExecution}
                    doActionCommentary={onConfirmSendExecution}
                    addCommentary={true}
                />
            }
            <CustomBackdrop open={processing} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <SuccessSnackbar message={success} onClose={() => setError(undefined)} />
        </Pagination>
    );
}