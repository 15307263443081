import React, { useState, useEffect, useContext } from "react";
import NoteAddIcon from '@material-ui/icons/NoteAddTwoTone';
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import { ReportCxP as Report, ReportParams } from "../model/Reports";
import { Grid, Fab, Button, Icon } from "@material-ui/core";
import ValidatedInput from "../components/ValidatedInput";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { getReportCxp, exportReportCxp } from "../api/ReportsAPI";
import Progress from "../components/Progress";
import GoHome from "../components/GoHome";
import { ExportProviders } from "../model/Provider";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import { Grid as GridDx, Table, TableHeaderRow, TableGroupRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, DataTypeProvider, DataTypeProviderProps, SummaryState, GroupingState, IntegratedGrouping, IntegratedSummary } from '@devexpress/dx-react-grid';
import NumberFormat from "react-number-format";


export default function ReportCxPView() {
  const context = useContext(AppContext);
  const history = useHistory();
  const [status, setStatus] = useState<string>("loading");
  const [report, setReport] = useState<Report>();
  const [exportResult, setExportResult] = useState<ExportProviders>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const qs = queryString.parse(window.location.search);
  const paramsFromQueryString = (): ReportParams => {
    return {
      "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
    } as ReportParams;
  };
  const [params, setParams] = useState<ReportParams>(paramsFromQueryString);
  const [workingParams, setWorkingParams] = useState<ReportParams>(paramsFromQueryString);

  useEffect(() => {
    setStatus("loading");
    getReportCxp(context.session!.tenant!.id, params).then(response => {
      setReport(response);
      setStatus("loaded");
    }).catch(error => {
      setStatus(error.message);
    });
  }, [context.session, params]);

  const onExport = () => {
    exportReportCxp(context.session!.tenant!.id).then(response => {
      if (response.url) {
        setExportResult(response);
      } else {
        setSuccess(translate("providers.email_export", { "total": response.total }) as string);
      }
    }).catch(error => {
      setError(error.message);
    });
  };

  const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let qs = queryString.parse(window.location.search);
    qs["search"] = workingParams.search;
    qs["page"] = "1";

    let url = window.location.pathname + "?" + queryString.stringify(qs);
    history.push(url);
    setParams(workingParams);
  };

  const onCloseSnackbars = () => {
    setError(undefined);
    setSuccess(undefined);
  };

  const onFilterChanged = (name: string, value: string) => {
    setWorkingParams({ ...workingParams, [name]: value });
  };

  const onCloseDownloadProviders = () => {
    setExportResult(undefined);
  }

  const CurrencyFormatter = (value: any) => (
    <NumberFormat value={value.value} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
  );

  const CurrencyTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
  );

  const tableColumnExtensions = [
    { columnName: 'provider', width: '20%' },
    { columnName: 'status', width: '7%' },
    { columnName: 'total_cfdis', align: 'right' },
    { columnName: 'corriente', align: 'right' },
    { columnName: 'thirty_days', align: 'right' },
    { columnName: 'sixty_days', align: 'right' },
    { columnName: 'more_sixty_days', align: 'right' },
    { columnName: 'total', align: 'right' },
  ] as any;

  const currencyColumns = ['corriente', 'thirty_days', 'sixty_days', 'more_sixty_days', 'total'];

  const grouping = [{ columnName: 'currency' }];

  const groupSummaryItems = [
    { columnName: 'provider', type: 'count' },
    { columnName: 'total_cfdis', type: 'sum', showInGroupFooter: false, alignByColumn: true },
    { columnName: 'corriente', type: 'sum', showInGroupFooter: false, alignByColumn: true },
    { columnName: 'thirty_days', type: 'sum', showInGroupFooter: false, alignByColumn: true },
    { columnName: 'sixty_days', type: 'sum', showInGroupFooter: false, alignByColumn: true },
    { columnName: 'more_sixty_days', type: 'sum', showInGroupFooter: false, alignByColumn: true },
    { columnName: 'total', type: 'sum', showInGroupFooter: false, alignByColumn: true },
  ];

  if (status === "loading") {
    return (<Progress />);
  }

  if (status !== "loaded" || !report) {
    return <GoHome message={status} />
  }

  return (
    <Surface
      title={translate("reports.cxp.title") as string}
      subtitle={translate("reports.cxp.subtitle") as string}
      icon={<NoteAddIcon />}
      className="PaperPagination" titleActions={
        <Grid container alignItems="center" justify="flex-end" spacing={1}>
          {report.items.length > 0 && 
            <Grid item xs="auto">
              <Fab color="primary" size="small" title={translate("reports.cxp.export") as string} onClick={onExport} >
                <Icon className="fas fa-file-excel" fontSize="small" />
              </Fab>
            </Grid>
          }
        </Grid>
      }>
      <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
        <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
          <Grid item xs={12} sm>
            <ValidatedInput type="text" id="search" name="search" label={translate("reports.cxp.search") as string}
              margin="dense" disabled={false}
              value={workingParams.search} onValueChanged={onFilterChanged} />
          </Grid>
          <Grid item xs="auto">
            <Button type="submit" variant="outlined" color="secondary" size="medium">
              {translate("buttons.search")}
            </Button>
          </Grid>
        </Grid>
      </form>

      <GridDx
        rows={report.items}
        columns={[
          {
            name: 'provider',
            title: translate('payments_cfdi.provider') as string
          },
          {
            name: 'status',
            title: translate('providers.status.title') as string
          },
          {
            name: 'total_cfdis',
            title: translate('reports.cxp.columns.total_cfdis') as string
          },
          {
            name: 'corriente',
            title: translate('reports.cxp.columns.corriente') as string
          },
          {
            name: 'thirty_days',
            title: translate('reports.cxp.columns.thirty_days') as string
          },
          {
            name: 'sixty_days',
            title: translate("reports.cxp.columns.sixty_days") as string
          },
          {
            name: 'more_sixty_days',
            title: translate("reports.cxp.columns.more_sixty_days") as string
          },
          {
            name: 'total',
            title: translate("total") as string
          },
          {
            name: 'currency',
            title: translate("currency.title") as string
          }
        ]} >

        <CurrencyTypeProvider for={currencyColumns} />
        <SortingState />
        <IntegratedSorting />
        <GroupingState grouping={grouping} />
        <SummaryState groupItems={groupSummaryItems} />
        <IntegratedGrouping />
        <IntegratedSummary />
        <Table columnExtensions={tableColumnExtensions} messages={{noData: translate("reports.empty") as string}} />
        <TableHeaderRow showSortingControls messages={{ sortingHint: translate('buttons.sort') as string }} />
        <TableGroupRow showColumnsWhenGrouped messages={{ sum: translate("total") as string }} />
        <TableSummaryRow messages={{ count: translate("provider_classifications.columns.providers_counter") as string }} />
      </GridDx>

      {exportResult && exportResult.url && (
        <DownloadExportationPopup title={translate("providers.download_providers") as string} message={translate("providers.providers_found", { "total": exportResult.total }) as string} url={exportResult.url} onClose={onCloseDownloadProviders} />
      )}
      <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </Surface>
  );
}
