import React, { useState } from "react";
import translate from "../i18n/Translator";
import { Stepper, Step, StepLabel, Hidden } from "@material-ui/core";
import Surface from "../components/Surface";
import { ManifestContract, RequestSignManifest } from "../model/ManifestTaxStamp";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import GetContractForm from "./SignManifestSteppers/GetContractForm";
import AcceptContract from "./SignManifestSteppers/AcceptContract";
import UploadFiel from "./SignManifestSteppers/UploadFiel";
import DownloadPdfs from "./SignManifestSteppers/DownloadPdfs";
import { VerifiedUserIcon } from "../components/Icons";
import { Redirect } from "react-router-dom";

function getSteps() {
    return [translate("tax_stamp.sign_manifest.manifest_title") as string,
    translate("tax_stamp.sign_manifest.contract_title") as string,
    translate("tax_stamp.sign_manifest.sign_title") as string,
    translate("tax_stamp.sign_manifest.docs_title") as string];
};

export default function SignManifest(){
    const [activeStep, setActiveStep] = React.useState(0);
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [manifestContract, setManifestContract] = useState<ManifestContract>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [privacyUrl, setPrivacyUrl] = useState<string>("");
    const [contractUrl, setContractUrl] = useState<string>("");
    const [redirect, setRedirect] = useState<string>();
    const [request, setRequest] = useState<RequestSignManifest>({
        provider_name: "",
        provider_rfc: "",
        email: "", 
        address: ""
    });

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    
    return <Surface title={translate("tax_stamp.sign_manifest.title") as string} subtitle={translate("tax_stamp.title") as string} icon={<VerifiedUserIcon/>} backButton={!(activeStep === 3)}>
            <Stepper activeStep={activeStep} orientation="horizontal">
                {getSteps().map((label: string) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Hidden xsUp={!(activeStep === 0)} implementation="css">
                <GetContractForm 
                    setActiveStep={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                    setError={error => setError(error)}
                    setManifestContract={response => setManifestContract(response)}
                    setSubmitting={submitting => setSubmitting(submitting)}
                    setRequest={request => setRequest(request)}
                />
            </Hidden>
            <Hidden xsUp={!(activeStep === 1)} implementation="css">
                <AcceptContract 
                    setActiveStep={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                    setError={error => setError(error)}
                    setSubmitting={submitting => setSubmitting(submitting)} 
                    handleBack={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)} 
                    provider_rfc={request.provider_rfc} 
                    provider_name={request.provider_name} 
                    manifestContract={manifestContract}
                />
            </Hidden>
            <Hidden xsUp={!(activeStep === 2)} implementation="css">
                <UploadFiel 
                    setActiveStep={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                    setError={error => setError(error)}
                    setSubmitting={submitting => setSubmitting(submitting)}
                    handleBack={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)} 
                    setContractUrl={(url) => setContractUrl(url)} 
                    setPrivacyUrl={(url) => setPrivacyUrl(url) }
                    data={request}                    
                />
            </Hidden>
            <Hidden xsUp={!(activeStep === 3)} implementation="css">
                <DownloadPdfs
                    handleDone={() => setRedirect("/plan-tax-stamp/certificate")}
                    contractUrl={contractUrl} 
                    privacyUrl={privacyUrl}
                />
            </Hidden>
        <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
    </Surface>;
}