import { Project } from "../model/Project";

export interface Team {
    id: string;
    tenant_id: string;
    name: string;
    external_id: string;
    active: boolean;
    issuer_id: string;
    members: TeamMember[];
    create_at: Date;
    projects: Project[];
}

export interface TeamMember {
    user_id: string;
    name: string;
    role: string;
}

export interface TeamRequest {
    team_name: string;
    members: TeamMember[];
    external_id: string;
}

export interface TeamsResponse {
    items: Team[];
    total: number;
}

export interface TeamListParams {
    search: string;
    ids: string;
}

export const roles = ["REQUESTER", "AUTHORIZER", "PURCHASER"];

export const directorRole = "DIRECTOR";