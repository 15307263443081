import { callAPI, HTTPMethod } from "./API";
import { Tenants, Tenant, TenantsQueryParams, TenantRequest, SearchByRfc, TenantLogoResponse } from "../model/Tenant";
import { SignUpMetadataResponse } from "../model/SignUp";

export async function getTenants(page: number, offset: number, params: TenantsQueryParams): Promise<Tenants> {
    return await callAPI("/tenants", {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createTenant(request: TenantRequest): Promise<Tenant> {
    return await callAPI("/tenants", {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getLogoUrl(subdomain:string): Promise<TenantLogoResponse> {
    return await callAPI("/tenants/logo", {
        method: HTTPMethod.GET,
        query: {
            subdomain: subdomain
        }
    })
}

export async function getMetadata(): Promise<SignUpMetadataResponse> {
    return await callAPI("/tenants/metadata", {
        method: HTTPMethod.GET,
    });
}

export async function getTenant(tenantId: string): Promise<Tenant> {
    return await callAPI(`/tenants/${tenantId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateTenant(tenantId: string, request: TenantRequest): Promise<Tenant> {
    return await callAPI(`/tenants/${tenantId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function getTenantsByRfc(request: SearchByRfc): Promise<Tenants> {
    return await callAPI("/tenants/list-by-rfc", {
        method: HTTPMethod.POST,
        body: request
    });
}