import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "yellow"
        }
    }),
);

interface CustomBackdropProps {
    open: boolean;
    message?: string;
    children?: React.ReactNode;
}

export default function CustomBackdrop(props: CustomBackdropProps) {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={props.open} >
            <Grid item xs={12}>
                {props.children && (props.children)}
                {!props.children && (
                    <Grid container justify="center">
                        <Grid item xs="auto">
                            <Box pb={2}>
                                <CircularProgress color="inherit" />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {props.message && (
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Typography variant="body1" component="h6" align="center">
                                {props.message}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Backdrop>
    );
}