import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { WorkflowsIcon } from "../components/Icons";

import { createWorkflow, metadata } from "../api/WorkflowAPI";
import translate from "../i18n/Translator";
import { WorkflowRequest } from "../model/Workflow";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { Product } from "../workflove/Workflove";

export default function WorkflowForm() {
    const history = useHistory();
    const context = useContext(AppContext);
    const margin = "dense";

    const query = new URLSearchParams(useLocation().search)
    const [product] = useState<Product>(() => {
        return query.get('product') ? query.get('product') as Product : 'PAYABLE_DOCUMENTS';
    });

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [shippintTemplateIds, setShippintTemplateIds] = useState<string[]>([]);
    const [shippintTemplateNames, setShippintTemplateNames] = useState<string[]>([]);
    const [workfloveIds, setWorkfloveIds] = useState<string[]>([]);
    const [workfloveNames, setWorkfloveNames] = useState<string[]>([]);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<WorkflowRequest>({
        product: product,
    } as WorkflowRequest);
    const [validations, setValidations] = useState({} as any);

    useEffect(() => {
        setStatus("loading");
        metadata(context.session!.tenant!.id, product).then(response => {
            setShippintTemplateIds(response.shipping_templates.map(st => st.id));
            setShippintTemplateNames(response.shipping_templates.map(st => st.name));
            setWorkfloveIds(response.workfloves.map(el => el.id));
            setWorkfloveNames(response.workfloves.map(el => el.name));
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, []);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        createWorkflow(context.session!.tenant!.id, request).then((workflow) => {
            if (workflow.status === "AVAILABLE") {
                history.push("/workflows");
            } else {
                history.push(`/workflows/${workflow.id}/edit`);
            }
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={6} xl={5}>
                    <Surface title={translate("workflows.new")} icon={<WorkflowsIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("workflows.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="external_id" name="external_id"
                                        value={request.external_id}
                                        label={translate("workflows.external_id") as string}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="workflove_id" name="workflove_id"
                                        value={request.workflove_id}
                                        label={translate("workflows.workflove") as string}
                                        options={workfloveIds} optionLabels={workfloveNames}
                                        disabled={submitting}
                                        margin={margin} required
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="shipping_template_id" name="shipping_template_id"
                                        value={request.shipping_template_id}
                                        label={translate("workflows.shipping_template") as string}
                                        options={shippintTemplateIds} optionLabels={shippintTemplateNames}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="description" name="description"
                                        value={request.description}
                                        label={translate("workflows.description") as string}
                                        rows={5}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate("buttons.continue")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} component={Link} to="/workflows">
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}