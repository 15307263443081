import React, { useState, useEffect, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import PaymentIcon from '@material-ui/icons/Payment';

import { getBillingMetadata } from "../api/TenantsBillingAPI";
import { BillingMetadata } from "../model/Billing";
import translate from "../i18n/Translator";
import Progress from "../components/Progress";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import OpenpayCardForm from "../openpay/OpenpayCardForm";
import { PlanDetail } from "./PlanPicker";

export default function PaymentForm() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<BillingMetadata>();
    const [frequency, setFrequency] = useState<string>("");
    const [cost, setCost] = useState<string>("");

    const load = () => {
        setStatus("loading");
        getBillingMetadata(context.session!.tenant!.id).then((response) => {
            setData(response);
            setFrequency(translate(`frequency.${response.plan.frequency}`) as string);
            setCost(response.plan.cost.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2 }))
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, []);

    const onSubscribed = () => {
        window.location.href = "/";
    };

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loaded" || !data) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        )
    }

    if (!data.gateway.enabled) {
        return (
            <Redirect to={data.billing ? `/billings/${data.billing.id}/manual` : "/billings"} />
        )
    }

    return (
        <Surface
            title={translate("subscription.title") as string}
            icon={<PaymentIcon />}>
            <Grid container justify="space-evenly" spacing={4}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {translate("subscription.explain", { "plan": data.plan.name, "frequency": frequency, "cost": cost })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <OpenpayCardForm tos={translate("subscription.check") as string} tenantId={context.session!.tenant!.id} gateway={data.gateway} onSuccess={onSubscribed} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={4} xl={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <PlanDetail tenantId={context.session!.tenant!.id}
                                plan={data.plan}
                                plans={data.plans}
                                upgradable={data.upgradable} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {translate("payments.manual")}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            {data.billing && (
                                <Button variant="text" size="small" color="primary" component={Link} to={`/billings/${data.billing.id}/manual`}>
                                    {translate("buttons.register_transfer")}
                                </Button>
                            )}
                            {!data.billing && (
                                <Button variant="text" size="small" color="primary" component={Link} to="/billings">
                                    {translate("buttons.go_to_billing")}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Surface>
    );
}
