import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, ListItemText, ListItemIcon, RadioGroup, Radio } from "@material-ui/core";
import { listConnectors } from "../api/ConnectorAPI";
import translate from "../i18n/Translator";
import { Connectors, Family, Direction, ConnectorsQueryParams, Connector } from "../model/Connector";
import Progress from "../components/Progress";
import Error from "../components/Error";

interface ConnectorSelectorProps {
    tenantId: string;
    family: Family;
    direction: Direction;
    disableCustom?: boolean;
    includeDefault?: boolean;
    onChangedConnector(connectorId: Connector): any;
    legacy?: "XLSX";
    onlySelectable?: boolean;
}

export default function ConnectorSelector(props: ConnectorSelectorProps) {
    const [status, setStatus] = useState<string>("loading");
    const [connectors, setConnectors] = useState<Connectors>();
    const { tenantId, family, direction, includeDefault } = props;

    useEffect(() => {
        setStatus("loading");
        const params = {
            search: "",
            family: family,
            direction: direction,
            includeDefault: includeDefault ?? true,
            availableForZip: false,
        } as ConnectorsQueryParams;

        listConnectors(tenantId, 0, 0, params).then(response => {
            setConnectors(response);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    }, [tenantId, family, direction, includeDefault]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        let connector = (connectors?.items || []).find(el => el.id === value);
        if (connector) {
            props.onChangedConnector(connector);
        }
    };

    const isDisabled = (connector: Connector): boolean => {
        if (props.onlySelectable) return false;

        return !!props.disableCustom && !!connector.tenant_id;
    };

    return ( connectors && connectors.total > 0 ?
        <Grid container>
            {status === "loading" && (
                <Progress />
            )}
            {status !== "loading" && status !== "loaded" && (
                <Error text={status} />
            )}
            {status === "loaded" && (
                <Grid item xs={12}>
                    <RadioGroup name="format" onChange={onChange} >
                        <List dense>
                            {(connectors?.items || []).map(connector => (
                                <ListItem key={connector.id} dense disableGutters>
                                    <ListItemIcon>
                                        <Radio value={connector.id} disabled={isDisabled(connector)} color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={connector.name ? (`${connector.name}${connector.tenant_id ? " *" : ""}`) : connector.format}
                                        secondary={isDisabled(connector) ? translate("connectors.disabled_custom") : connector.extensions?.join(", ") || translate(`connectors.formats.${connector.format}`)} />
                                </ListItem>
                            ))}
                            {(!connectors || connectors.items.length === 0) && props.legacy && (
                                <ListItem value=" " dense disableGutters>
                                    <ListItemIcon>
                                        <Radio value="" color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={props.legacy}
                                        secondary={translate(`connectors.formats.${props.legacy}`)} />
                                </ListItem>
                            )}
                            {(!connectors || connectors.items.length === 0) && !props.legacy && (
                                <ListItem dense disableGutters>
                                    <ListItemText
                                        primary={translate("connectors.empty_single")} />
                                </ListItem>
                            )}
                        </List>
                    </RadioGroup>
                </Grid>
            )}
        </Grid> : <></>
    );
}