import React from "react";

import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { InputLabel } from "@material-ui/core";
import { Extension } from '@codemirror/state';
import translator from "../i18n/Translator";
import { javascript } from "@codemirror/lang-javascript";

interface CodeInputConfig {
    textLabel: string;
    value?: string;
    placeHolder?: string;
    height?: string;
    maxHeight?: string;
    onChange?(value : string): void;
    isEditable?: boolean;
    extensions?: Extension[];
}

const commonProperties = (config : CodeInputConfig) : ReactCodeMirrorProps  => {
    return {
        theme : 'light',
        maxHeight: config.maxHeight === undefined ? "200px" : config.maxHeight,
        height : config.height === undefined ? '150px' : config.height,
        placeholder: config.placeHolder === undefined ? translator("code_input.place_holder") as string : config.placeHolder,
        extensions : [javascript({ jsx: false })]
    }
}


export default function CodeInput(config : CodeInputConfig) {

    const validate = (value : string) => {
        if(typeof config.onChange === "function"){
            config.onChange(value);
        }        
    }

    return (
        <InputLabel>
            {config.textLabel}
            <CodeMirror 
                    value={config.value}
                    onChange={(value, view) => {
                        validate(value)
                    }}
                    editable={config.isEditable}
                    {...commonProperties(config)}
            /> 
        </InputLabel>
    );
}