import React, { useState, useEffect, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";

import { create, get, update } from "../api/PaymentConditionsAPI";
import translate from "../i18n/Translator";
import { PaymentCondition, PaymentConditionRequest, Installment } from "../model/TenantConfiguration";
import BankIcon from "@material-ui/icons/AccountBalanceTwoTone";

import ConfirmationPopup from "../components/ConfirmationPopup";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import Gridable from "../components/Gridable";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { GTZeroValidator } from "../components/Validators";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import ReactHtmlParser from "html-react-parser";

export default function PaymentConditionForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const isEdit = !!match.params.paymentConditionId;
    const gtZeroValidator = new GTZeroValidator(translate("requisitions.form.quotation.payment_condition.form.percentages_gt_zero") as string);

    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<PaymentConditionRequest>({} as PaymentConditionRequest);
    const [actualPercentaje, setActualPercentaje] = useState<number>(0.00);

    const [isEditInstallments, setIsEditInstallments] = useState<boolean>(false);
    const [onConfirmChanges, setOnConfirmChanges] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();

    const [validations, setValidations] = useState({
        "name": {
            valid: false
        } as InputRef,
    } as any);

    const submitPromise = (): Promise<PaymentCondition> => {
        if (isEdit && match.params.paymentConditionId) {
            return update(tenantId, match.params.paymentConditionId, request);
        }
        return create(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            get(tenantId, match.params.paymentConditionId).then((response) => {
                let tempRequest = {
                    id: response.id,
                    name: response.name,
                    status: response.status,
                    installments: response.installments,
                    created_at: response.created_at
                } as PaymentConditionRequest;
                setRequest(tempRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            let number = match.params.noPartiallities ? match.params.noPartiallities : 0;
            let installments = [] as Installment[];
            for (var i = 0; i < number; i++) {
                let item = { payment_number: (i+1) + "", percentage: 0 } as Installment;
                installments.push(item);
            }
            let tempRequest = {
                installments: installments,
                status: "ACTIVE",
            } as PaymentConditionRequest;
            setRequest(tempRequest);
            setStatus("loaded");
        }
    }, [tenantId, isEdit, match.params.paymentConditionId, match.params.noPartiallities]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedInstallment = (name: string, value: string, inputRef: InputRef) => {
        let installments = request.installments;
        installments.forEach(item => {
            if(name && name === item.payment_number){
                if(isEdit && value && item.percentage !== Number.parseFloat(value)){
                    setIsEditInstallments(true);
                }
                item.percentage = Number.parseFloat(value);
            }
        });
        setRequest({ ...request, name: request.name });
        let percentajeTotal = request.installments.reduce((sum, item) => sum + item.percentage, 0);
        setActualPercentaje(percentajeTotal);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) return;
        
        if (actualPercentaje !== 100) {
            setWarning(translate("requisitions.form.quotation.payment_condition.form.incomplete_percentaje") as string);
            return;
        }
        if (isEditInstallments){
            setOnConfirmChanges(true);
            return;
        }
        doSubmit();
    };

    const onCloseConfirmChanges = () => {
        setOnConfirmChanges(false);
    }

    const doSubmit = () => {
        setSubmitting(true);
        submitPromise().then((company) => {
            let success = translate("requisitions.form.quotation.payment_condition.form.success_" +  (isEdit ? "edit" : "save") ) as string;
            setRedirect(`/payment-conditions/${success}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    }

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("requisitions.form.quotation.payment_condition.form." + (isEdit ? "edit_title" : "create_title"))} icon={<BankIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("companies.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>

                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            <Gridable
                                                items={request.installments ? request.installments : []}
                                                loading={false}
                                                empty={translate("tenant_configurations.empty_category") as string}
                                                columns={[
                                                    {
                                                        title: translate("requisitions.form.quotation.payment_condition.partiality") as string,
                                                        converter: (field) => (
                                                            <Grid >
                                                                {field.payment_number}
                                                            </Grid>
                                                        ),
                                                        xs: 9,
                                                        sm: 9,
                                                        md: 9,
                                                        lg: 9,
                                                        xl: 9
                                                    },
                                                    {
                                                        title: translate("requisitions.form.quotation.payment_condition.percentage") as string,
                                                        converter: (field) => (
                                                            <Grid >
                                                                <ValidatedInput
                                                                    type="number"
                                                                    id={field.payment_number}
                                                                    name={field.payment_number}
                                                                    value={field.percentage + "" || "0.00"}
                                                                    isValueNumber={true}
                                                                    validators={[gtZeroValidator]}
                                                                    label={""}
                                                                    required={true}
                                                                    margin="dense"
                                                                    onValueChanged={hasChangedInstallment}
                                                                />
                                                            </Grid>
                                                        ),
                                                        xs: 3,
                                                        sm: 3,
                                                        md: 3,
                                                        lg: 3,
                                                        xl: 3
                                                    },
                                                ]}
                                            />
                                            <Grid container xs={12}>
                                                <Grid item xs={10}>
                                                    <Box py={1}>
                                                        <Typography variant="body2">
                                                            {ReactHtmlParser(translate("requisitions.form.quotation.payment_condition.form.total_percentaje") as string)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Box py={1}>
                                                        <Grid style={{color: actualPercentaje && actualPercentaje === 100 ? "#006600" : "#C33149", fontWeight: "bold"}}>
                                                        <Typography variant="body2">
                                                            {ReactHtmlParser(actualPercentaje+"%")}
                                                        </Typography>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        { isEditInstallments && onConfirmChanges && (
                            <ConfirmationPopup
                                doAction={doSubmit}
                                onClose={onCloseConfirmChanges}
                                title={translate("requisitions.form.quotation.payment_condition.form.edit_title") as string}
                                message={translate("requisitions.form.quotation.payment_condition.form.warning_edit") as string}
                                button={translate("buttons.accept") as string}
                            />
                        )}
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}