import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import ArchiveIcon from '@material-ui/icons/Archive';
import translate from "../i18n/Translator";
import { TenantConfiguration, ConfigurationCatalogCategory as Category } from "../model/TenantConfiguration";
import Surface from "../components/Surface";
import { getByAdmin as getTenantConfiguration } from "../api/TenantConfigurationApi";
import TenantConfigurationsFields from "./TenantConfigurationsFields";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

export default function TenantConfigurationsForm({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const [configuration, setConfiguration] = useState<TenantConfiguration>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const load = () => {
        setStatus("loading");
        getTenantConfiguration(match.params.tenantId)
            .then((response) => {
                setStatus("loaded");
                setConfiguration(response);
            })
            .catch((error) => {
                setStatus(error.message);
            });
    };

    useEffect(load, [match.params.tenantId]);

    const onUpdateConfiguration = (configuration: TenantConfiguration, desactivate: boolean) => {
        setStatus("loading");
        if (configuration) {
            setConfiguration(configuration);
            if (desactivate) {
                setSuccess(translate("tenant_configurations.pop_up.success_desactivate") as string);
            } else {
                setSuccess(translate("tenant_configurations.pop_up.success_update") as string);
            }
            setStatus("loaded");
        }
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !configuration) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface
            title={(translate("tenant_configurations.title") as string)}
            subtitle={configuration.tenant_name}
            icon={<ArchiveIcon />}
            backButton>
            {Category.map((cat: string, index: number) => (
                <TenantConfigurationsFields
                    key={cat}
                    tenantId={match.params.tenantId}
                    fields={configuration.configurations}
                    category={cat}
                    catalog={configuration.catalog !== undefined ? configuration.catalog.filter(c => c.category === cat) : []}
                    onUpdateConfiguration={onUpdateConfiguration}
                />
            ))}
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Surface>
    );
}
