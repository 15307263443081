import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { ExpensesAccountRequest, ExpensesAccountDocumentRequest } from "../model/ExpensesAccount";
import { Cfdi, CfdiRequest } from "../model/Cfdi";

const getBaseUrl = (tenantId: string, providerId: string) => {
    return `/tenants/${tenantId}/providers/${providerId}/expenses-accounts`;
};

export async function create(tenantId: string, providerId: string, request: ExpensesAccountRequest): Promise<Cfdi> {
    return await callAPI(getBaseUrl(tenantId, providerId), {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getCoverUrl(tenantId: string, providerId: string, expensesAccountId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${getBaseUrl(tenantId, providerId)}/${expensesAccountId}/cover`);
}

export async function createCfdi(tenantId: string, providerId: string, expensesAccountId: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("workflowId", request.workflow_id ? request.workflow_id : "");
    data.append("companyId", request.company_id ? request.company_id : "");

    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${expensesAccountId}/cfdi`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function createHonorarium(tenantId: string, providerId: string, expensesAccountId: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("workflowId", request.workflow_id ? request.workflow_id : "");
    data.append("companyId", request.company_id ? request.company_id : "");

    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${expensesAccountId}/honorarium`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function addDocument(tenantId: string, providerId: string, expensesAccountId: string, request: ExpensesAccountDocumentRequest): Promise<Cfdi> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${expensesAccountId}/documents`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function removeDocument(tenantId: string, providerId: string, expensesAccountId: string, documentId: string): Promise<Cfdi> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/${expensesAccountId}/documents/${documentId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getDocumentUrl(tenantId: string, providerId: string, expensesAccountId: string, documentId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${getBaseUrl(tenantId, providerId)}/${expensesAccountId}/documents/${documentId}/file`);
}