import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ShippingTemplate } from "../model/ShippingTemplate";
import { EditIcon, DeleteIcon } from "../components/Icons";

interface ShippingMenuProps {
    anchor: HTMLElement | null;
    tenantId: string;
    shippingTemplate: ShippingTemplate;
    onClose(): any;
    onEdit?(): any;
    onDelete?(): any;
}

export default function ShippingMenu(props: ShippingMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="shipping-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {props.onEdit && context.isGrantedAny(["ShippingTemplateUpdate"]) &&
                <MenuItem component={Link} to={`/shipping-templates/${props.shippingTemplate.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {props.onDelete && context.isGrantedAny(["ShippingTemplateDelete"]) &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
}
