import { callAPI, HTTPMethod } from "./API";
import { ProviderReference, BuyPlanResponse, ProviderMembershipTaxStamp, ProviderMembershipTaxStampParams, ProviderMembershipsTaxStamp, ProviderCfdiGeneratedInMembershipResponse } from "../model/ProviderMembershipTaxStamp";
import { ContractRequest } from "../model/PlanTaxStamp";
import { ManifestContract, ManifestSign, RequestSignManifest, RequestSignManifestCert } from "../model/ManifestTaxStamp";
import { ExportResponse } from "../model/Connector";

export async function list(params: ProviderMembershipTaxStampParams): Promise<ProviderMembershipsTaxStamp> {
    return await callAPI(`/provider-membership-tax-stamp`, {
        method: HTTPMethod.GET,
        query: {
            provider: params.provider_name || "",
            reference: params.reference || "",
            name: params.name || "",
            status: params.status || ""
        }
    });
}

export async function getReference(rfc: string, planExternalId: string): Promise<ProviderReference> {
    return await callAPI(`/provider-membership-tax-stamp/reference`, {
        method: HTTPMethod.GET,
        query: {
            providerRfc: rfc,
            planExternalId: planExternalId
        }
    });
}

export async function buyPlan(request: ContractRequest): Promise<BuyPlanResponse> {
    return await callAPI(`/provider-membership-tax-stamp/buy-plan`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getProviderMembershipTaxStamp(providerMembershipTaxStampId: string): Promise<ProviderMembershipTaxStamp> {
    return await callAPI(`/provider-membership-tax-stamp/${providerMembershipTaxStampId}`, {
        method: HTTPMethod.GET,
    });
}

export async function approve(membershipId: string): Promise<BuyPlanResponse> {
    return await callAPI(`/provider-membership-tax-stamp/${membershipId}/approve`, {
        method: HTTPMethod.PUT
    });
}

export async function disapprove(membershipId: string): Promise<BuyPlanResponse> {
    return await callAPI(`/provider-membership-tax-stamp/${membershipId}/disapprove`, {
        method: HTTPMethod.PUT
    });
}

export async function getContractManifest(request: RequestSignManifest): Promise<ManifestContract> {
    return await callAPI(`/provider-membership-tax-stamp/get-contract-manifest`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function signContractManifest(request: RequestSignManifestCert): Promise<ManifestSign> {
    return await callAPI(`/provider-membership-tax-stamp/sign-contract-manifest`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function acceptContractManifest(): Promise<void> {
    return await callAPI(`/provider-membership-tax-stamp/accept-contract-manifest`, {
        method: HTTPMethod.POST,
    });
}

export async function exportExcelPaymentCfdis(providerMembershipTaxStampId: string): Promise<ExportResponse> {
    return await callAPI(`/provider-membership-tax-stamp/${providerMembershipTaxStampId}/excel-payment-cfdis`, {
        method: HTTPMethod.GET,
    });
}

export async function validateGenerateInMembership(paymentCfdiId: string): Promise<ProviderCfdiGeneratedInMembershipResponse> {
    return await callAPI(`/provider-membership-tax-stamp/${paymentCfdiId}/validate-generation-payment-cfdi`, {
        method: HTTPMethod.GET,
    });
}