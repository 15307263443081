import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { Contracts, Contract, ContractsQueryParams, ContractRequest } from "../model/Contract";

const basePath = (tenantId: string, customerId: string) => `/tenants/${tenantId}/customers/${customerId}/contracts`;

const buildParams = (page: number, offset: number, params: ContractsQueryParams) => {
    let query = {
        search: params.search,
        pageSize: page.toString(),
        offset: offset.toString()
    } as Record<string, string>;
    if(params.startDate && params.endDate){
        query =  {
            ...query,
            startDate: params.startDate,
            endDate: params.endDate
        } as Record<string, string>;
    }
    return query
};

export async function listContracts(tenantId: string, customerId: string, page: number, offset: number, params: ContractsQueryParams): Promise<Contracts> {
    return await callAPI(`${basePath(tenantId, customerId)}`, {
        method: HTTPMethod.GET,
        query: buildParams(page, offset, params)
    });
}

export async function createContract(tenantId: string, customerId: string, request: ContractRequest): Promise<Contract> {
    return await callAPI(`${basePath(tenantId, customerId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getContract(tenantId: string, customerId: string, id: string): Promise<Contract> {
    return await callAPI(`${basePath(tenantId, customerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateContract(tenantId: string, customerId: string, id: string, request: ContractRequest): Promise<Contract> {
    return await callAPI(`${basePath(tenantId, customerId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteContract(tenantId: string, customerId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId, customerId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getDocumentUrl(tenantId: string, customerId: string, id: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, customerId)}/${id}/document`);
}