import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PayrollArchiveIcon from "@material-ui/icons/Archive";
import AddIcon from "@material-ui/icons/Add";

import { listPayrolls, deletePayroll } from "../api/PayrollAPI";
import { listPayrolls as listProviderPayrolls, deletePayroll as deleteProviderPayroll, exportPayrollsExcel } from "../api/ProviderPayrollAPI";
import { AppContext } from "../context/AppContext";
import { PayrollsQueryParams } from "../model/Payroll";
import { Cfdi, Cfdis } from "../model/Cfdi";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import {stringToDateEnd, stringToDate} from "../components/DateFormat";
import DateRangeInput from "../components/DateRangeInput";
import PayrollMenu from "./PayrollMenu";
import { RouterParams } from "../router/RouterParams";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import { SuccessSnackbar } from "../components/Snackbars";

import PayrollsMenu from "./menus/ProviderPayrollsMenu";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";

export function ArchivePayroll() {
    return (
        <PayrollsList type="archive" />
    )
}

export function EmployeePayroll({ match }: RouterParams) {
    return (
        <PayrollsList type="archive" employeeId={match.params.employeeId} />
    )
}

type PayrollListType = "archive" | "employee";

interface PayrollsListProps {
    type: PayrollListType;
    employeeId?: string;
}

function PayrollsList(props: PayrollsListProps) {
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): PayrollsQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "start_date": typeof qs["start_date"] === "string" && qs["start_date"] !== "" && qs["start_date"] !== "NaN-NaN-NaN" ? stringToDate(qs["start_date"]) : undefined,
            "end_date": typeof qs["end_date"] === "string" ? stringToDateEnd(qs["end_date"]) : new Date(),
            "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : "",
        } as PayrollsQueryParams;
    };
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");
    const date = query.get("date");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>();
    const [params] = useState<PayrollsQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<PayrollsQueryParams>({
        search: search,
        date: date,
    } as PayrollsQueryParams);

    const [payroll, setPayroll] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

    const promiseList = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
            date: date || "",
        } as PayrollsQueryParams;

        if (props.type === "employee" && props.employeeId) {
            params.employeeId = props.employeeId;
        }

        setWorkingParams(params);
        if (providerId) {
            return listProviderPayrolls(tenantId, providerId, pageSize, offset, params);
        }
        return listPayrolls(tenantId, pageSize, offset, params);
    };

    const load = () => {
        promiseList().then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search, date, props]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");

        if (workingParams.date) {
            query.set("date", workingParams.date);
        } else {
            query.delete("date");
        }

        history.push(`${window.location.pathname}?${query.toString()}`);
        setPage(1);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (payroll: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPayroll(payroll);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPayroll(undefined);
    };

    const onChangedDateRange = (name: string) => (from?: Date, to?: Date, formated?: string) => {
        setWorkingParams({ ...workingParams, [name]: formated });
    };

    const promiseDelete = (payrollId : string) => {
        if (providerId) {
            return deleteProviderPayroll(tenantId, providerId, payrollId);
        }
        return deletePayroll(tenantId, payrollId);
    };

    const onDelete = () => {
        setAnchorEl(null);
        if(!payroll) return;
        setSubmitting(true);
        promiseDelete(payroll.id).then((_) => {
            setSuccess(translate("payrolls.sucess_delete") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setPayroll(undefined);
        });
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onOpenConfirmDialogExportExcel = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenExportExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onCloseExporter = () => {
        setOpenExportExcel(false);
    };

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenExportExcel(false);
        let nParams = params;
        nParams.startDate = startDate;
        nParams.endDate = endDate;
        nParams.connectorId = connectorId;
        onDownloadFiles(nParams);
    }

    const onDownloadFiles = (n_params: PayrollsQueryParams) => {
        setOpenBackdrop(true);
        exportPayrollsExcel(context.session!.tenant!.id, context.session!.provider!.id, n_params).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    return (
        <Pagination title={translate(props.type === "employee" ? "payrolls.title" : "payrolls.archive")} icon={<PayrollArchiveIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} 
            action={context.isGrantedAny(["PayrollsCreate", "ProviderPayrollsCreate"]) ?
                (<>
                <Link to="/payrolls/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>
                <IconButton color="default" size="small" onClick={onClickedMore}>
                    <MoreVertIcon />
                </IconButton>
                </>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("payrolls.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs sm={5} lg={4} xl={3}>
                        <DateRangeInput id="date"
                            label={translate("payrolls.date") as string}
                            value={workingParams.date}
                            onChange={onChangedDateRange("date")}
                            clearable />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("payrolls.empty") as string}
                columns={[
                    {
                        title: translate("payrolls.date") as string,
                        converter: (payroll) => (
                            <DateFormat date={payroll.date} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("payrolls.identifier") as string,
                        converter: (payroll) => payroll.identifier,
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("employees.single") as string,
                        converter: (payroll) => (
                            <Ellipsis text={payroll.employee?.name || "---"} lenght={100} />
                        ),
                        fullWidth: true,
                        xs: 5,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("payrolls.from") as string,
                        converter: (payroll) => (
                            <DateFormat date={payroll.metadata?.from} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("payrolls.to") as string,
                        converter: (payroll) => (
                            <DateFormat date={payroll.metadata?.to} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (payroll) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(payroll)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {payroll && anchorEl &&
                <PayrollMenu payroll={payroll} anchor={anchorEl} onClose={onCloseOption} onDelete={onDelete} />
            }
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <SuccessSnackbar message={success} onClose={() => setError(undefined)} />
            <WarningSnackbar message={warning} onClose={() => setError(undefined)} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {gridAnchorEl && (
                <PayrollsMenu anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onDownloadExcel={onOpenConfirmDialogExportExcel}
                />
            )}
            {openExportExcel && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.PAYROLLS}
                    type="DATA_RANGE"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporter}
                    onExport={onDownloadWithConnector}
                    maxDaySelected={31} 
                    noValidateRangeDate={true}
                    onlyDates={true}
                    />
            )}
        </Pagination>
    );
}