import { Grid, GridSize, TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SimpleSwitch from "../components/SimpleSwitch";
import ValidatedInput from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import { RequisitionRequest } from "../model/Requisition";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Project } from "../model/Project";
import { Company } from "../model/Company";
import { WarningSnackbar } from "../components/Snackbars";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";

export interface AutocompleteItem {
    title: string;
    value: string;
}

interface RequisitionInputsHeaderProps {
    submitting: boolean;
    request: RequisitionRequest;
    types: string[];
    typeLabels: string[];
    providers: AutocompleteItem[];
    providerSelected: AutocompleteItem;
    projects: Project[];
    projectSelected: Project;
    isBudgetControl: boolean;
    isEdit?: boolean;
    companies: Company[];
    hasChanged(name: string, value: string): any;
    hasChangedCurrency(name: string, inputRef?: any): any;
    currencies: AutocompleteProvider[];
    onSwitchInProject(): any;
    setError(error: string): any;
    xs: boolean | GridSize | undefined;
    view: "header" | "detail";
}

export default function RequisitionInputsHeader(props: RequisitionInputsHeaderProps) {
    const [providerSelected, setProviderSelected] = useState<AutocompleteItem>(props.providerSelected);
    const [projectSelected, setProjectSelected] = useState<Project>(props.projectSelected);
    const [companyIds, setCompanyIds] = useState<string[]>([]);
    const [companyNames, setCompanyNames] = useState<string[]>([]);
    const [redirect, setRedirect] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    const [inProject, setInProject] = useState<boolean>(props.request.in_project);
    const isBudgetEditDisable = props.isBudgetControl && props.isEdit === true;

    const load = () => {
        if (props.isBudgetControl && props.companies) {
                setCompanyIds(props.companies.map((company) => company.id));
                setCompanyNames(props.companies.map((company) => company.name));
        }  
    };

    useEffect(load, []);

    const onSwitchInProject = () => {
        if (inProject) {
            props.hasChangedCurrency("", "currency")
        } else {
            props.hasChanged("project_id", "");
        }
        setInProject(!inProject);
        props.onSwitchInProject();
    };

    const onFilterChanged = (value: string) => {
        props.providers.forEach((provider) => {
            if (provider.value === value) {
                setProviderSelected(provider);
            }
        });
        if (value !== undefined) {
            props.hasChanged("provider_id", value)
        }
    };

    const onFilterProjectChanged = (name: string, value: string, inputRef?: any) => {
        props.projects.forEach((project) => {
            if (value !== undefined && project.id === value) {
                setProjectSelected(project);
                props.hasChanged(name, value)
            }
        });
    };

    const onFilterCurrencyChanged = (value: string, inputRef?: any) => {
        if (value) {
            props.hasChangedCurrency(value, "currency")
        }
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Grid container justify="space-between" alignItems="center" spacing={1}>
            <Grid item xs={props.xs}>
                <ValidatedInput type="number" id="requisition_type_id" name="requisition_type_id"
                    value={props.request.requisition_type_id} label={translate("requisitions.form.type") as string}
                    required={true} disabled={props.submitting || props.view === "detail" || isBudgetEditDisable}
                    options={props.types} optionLabels={props.typeLabels}
                    margin="dense"
                    onValueChanged={props.hasChanged} />
            </Grid>
            {props.isBudgetControl &&
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text" id="company_id" name="company_id"
                        label={translate("reports.diots.filter.company") as string}
                        value={props.request.company_id}
                        required={props.isBudgetControl}
                        disabled={props.request.id !== undefined || isBudgetEditDisable}
                        margin={"dense"}
                        onValueChanged={props.hasChanged}
                        options={companyIds} optionLabels={companyNames} />
                </Grid>
            }
            {!props.isBudgetControl &&
                <Grid item xs={props.xs}>
                    <Grid container >
                        <Grid xs={6}>
                            <SimpleSwitch
                                value="active"
                                label={translate("requisitions.form.enabled") as string}
                                small_label={true}
                                checked={inProject}
                                onChanged={onSwitchInProject}
                                placement="start"
                                color="primary"
                                disabled={props.submitting || props.view === "detail"} />
                        </Grid>
                        <Grid xs={6}>
                            {inProject &&
                                <ValidatedInput type="text" id="project_id" name="project_id"
                                    value={props.request.project_id}
                                    label={translate("requisitions.form.project") as string}
                                    required={true} disabled={props.submitting || !props.request.in_project || props.view === "detail"}
                                    margin={"dense"}
                                    onValueChanged={onFilterProjectChanged}
                                    autocompleteOptions={props.projects}
                                    autocompleteValue={projectSelected}
                                    getId={(el) => el?.id}
                                    getLabel={(el) => (`${el.name} - ${el.key}`) as string} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            {(!inProject || props.view === "detail") &&
                <Grid item xs={props.xs}>
                    <TenantCurrencyAutocomplete
                        currencies={props.currencies}
                        onChange={onFilterCurrencyChanged}
                        disabled={props.submitting || props.request.in_project || props.view === "detail" || isBudgetEditDisable}
                        value={props?.currencies.find(v => v.value === props.request?.currency)}
                    />
                </Grid>}

            <Grid item xs={props.xs} >
                <Autocomplete
                    size="small"
                    options={props.providers}
                    getOptionLabel={(elemento) => elemento.title}
                    noOptionsText={translate("payments_cfdi.no_options_autocomplete") as string}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={translate("requisitions.form.provider") as string} fullWidth />
                    )}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            onFilterChanged(newValue.value);
                        } else {
                            onFilterChanged("");
                        }
                    }}
                    getOptionSelected={(opt, val) => opt.value === val.value}
                    value={providerSelected}
                />
            </Grid>
            <Grid item xs={props.xs}>
                <ValidatedInput type="text" id="description" name="description"
                    value={props.request.description} label={translate("requisitions.form.description") as string}
                    disabled={props.submitting}
                    margin="dense"
                    onValueChanged={props.hasChanged} />
            </Grid>
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
        </Grid>
    );
}
