import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Typography } from "@material-ui/core";
import { SpecializedServiceDocumentsIcon, MoreVertIcon } from "../components/Icons";

import { listSpecializedServiceDocuments, updateSpecializedServiceDocument } from "../api/SpecializedServiceDocumentAPI";
import { AppContext } from "../context/AppContext";
import { SpecializedServiceDocument, SpecializedServiceDocuments, SpecializedServiceDocumentsQueryParams, SpecializedServiceDocumentRequest } from "../model/SpecializedServiceDocument";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import SpecializedServiceDocumentMenu from "./SpecializedServiceDocumentMenu";

export default function SpecializedServiceDocumentsList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<SpecializedServiceDocuments>();
    const [workingParams, setWorkingParams] = useState<SpecializedServiceDocumentsQueryParams>({
        search: search,
    } as SpecializedServiceDocumentsQueryParams);

    const [specializedServiceDocument, setSpecializedServiceDocument] = useState<SpecializedServiceDocument>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"update">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [expirationDay, setExpirationDay] = useState<number>(0);
    
    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as SpecializedServiceDocumentsQueryParams;
        setWorkingParams(params);

        listSpecializedServiceDocuments(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (specializedServiceDocument: SpecializedServiceDocument) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSpecializedServiceDocument(specializedServiceDocument);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onUpdate = () => {
        if (!specializedServiceDocument) return;

        setAnchorEl(null);
        setExpirationDay(specializedServiceDocument.expiration_day);
        setPopup("update");
    };

    const onConfirmUpdate = () => {
        onClosePopup();
        if (!specializedServiceDocument) return;

        setSubmitting(true);
        let request = {
            expiration_day: expirationDay
        } as SpecializedServiceDocumentRequest;
        updateSpecializedServiceDocument(tenantId,  specializedServiceDocument.id, request).then(() => {
            setSuccess(
                translate("specializedservicedocument.update_success", {id: specializedServiceDocument.id}) as string
            );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };


    const onCloseOption = () => {
        setAnchorEl(null);
        setSpecializedServiceDocument(undefined);
    };

    const hasChanged = (name: string, value: string) => {
        setExpirationDay(parseInt(value));
    };

    return (
        <Pagination title={translate("specializedservicedocument.title")} icon={<SpecializedServiceDocumentsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("specializedservicedocument.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("specializedservicedocument.empty") as string}
                columns={[
                    {
                        title: translate("specializedservicedocument.name") as string,
                        converter: (specializedServiceDocument) => (
                            <Ellipsis text={ translate(`contributions.to_validate.types.${specializedServiceDocument.name}`) as string } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: true,
                    },
                    {
                        title: translate("specializedservicedocument.expiration_day") as string,
                        converter: (specializedServiceDocument) => (
                            <Ellipsis text={ specializedServiceDocument.expiration_day+"" } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: true,
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (specializedServiceDocument) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(specializedServiceDocument)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        xs: "auto"
                    }
                ]} />
            { specializedServiceDocument && anchorEl &&
                <SpecializedServiceDocumentMenu specializedServiceDocument={ specializedServiceDocument } anchor={anchorEl} onUpdate={onUpdate} onClose={onCloseOption} />
            }
            { specializedServiceDocument && popup === "update" && (
                <DialogPopup
                open
                title={translate("specializedservicedocument.modal_expiration_day.title")}
                onClose={onClosePopup}
                closeColor="default"
                disableEscapeKeyDown={submitting}
                disableBackdropClick={submitting}
                disable={submitting}
                button={
                    <Button
                    onClick={onConfirmUpdate}
                    variant="outlined"
                    color="secondary"
                    disabled={submitting}
                    >
                        {translate("buttons.save")}
                    </Button>
                }
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                { translate(`contributions.to_validate.types.${specializedServiceDocument.name}`) }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="number" id="expiration_day" name="expiration_day"
                                value={ expirationDay+"" }
                                label={translate("specializedservicedocument.modal_expiration_day.input") as string}
                                required={ true } disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged} />
                        </Grid>
                    </Grid>
                    
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}