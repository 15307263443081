import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import BankIcon from "@material-ui/icons/AccountBalance";

import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import Progress from "../components/Progress";
import Error from "../components/Error";
import { BankParserRequest } from "../model/Bank";
import { get, updateBank } from "../api/TenantConfigurationApi";
import { get as providerGet, updateBank as providerUpdateBank } from "../api/ProviderConfigurationApi";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";

function BankForm() {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;

    const [status, setStatus] = useState("loading");
    const [submitting, setSubmitting] = useState(false);
    const [request, setRequest] = useState({} as BankParserRequest);
    const [banks, setBanks] = useState<string[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (providerId) {
            providerGet(tenantId, providerId).then(response => {
                setRequest(r => {
                    return { ...r, bank: response.bank };
                });
                setBanks(response.banks);
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        } else {
            get(tenantId).then(response => {
                const configurations = response.configurations?.filter(cfg => cfg.property_name === "cfdi_se_bank");
                const configuration = configurations[0];
                if (configuration && configuration.value) {
                    setRequest(r => {
                        return { ...r, bank: configuration.value };
                    });
                }
                setBanks(response.banks);
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        }
    }, [tenantId, providerId]);

    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const promiseSubmit = () => {
        if (providerId) {
            return providerUpdateBank(tenantId, providerId, request);
        }
        return updateBank(tenantId, request);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true);

        promiseSubmit().then(() => {
            setSuccess(translate("bank_parsers.updated") as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return (
            <Progress />
        )
    }

    if (status !== "loaded") {
        return (
            <Error text={status} />
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("bank_parsers.title")} subtitle={translate("bank_parsers.subtitle")} icon={<BankIcon />}>
                        <Grid container>
                            <Grid item xs={12}>
                                <form autoComplete="off" noValidate onSubmit={onSubmit}>
                                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                                        <Grid item xs={12}>
                                            <ValidatedInput type="text" id="bank" name="bank" label={translate("bank_parsers.title") as string}
                                                margin="dense" options={banks || []}
                                                maxLength={12} required disabled={submitting}
                                                value={request.bank} onValueChanged={onChanged} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Button type="submit" variant="outlined" color="primary" size="medium" disabled={submitting}>
                                                {translate("buttons.update")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} />
        </Grid>
    );
}

export default BankForm;