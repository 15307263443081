import { callAPI, getAuthenticatedGetUrl, HTTPMethod } from "./API";
import { Connectors, Connector, ConnectorsQueryParams, ConnectorRequest, SecureUrlResponse } from "../model/Connector";

const basePath = (tenantId: string) => `/tenants/${tenantId}/connectors`;

export async function listConnectors(tenantId: string, page: number, offset: number, params: ConnectorsQueryParams): Promise<Connectors> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            family: params.family || "",
            direction: params.direction || "",
            includeDefault: `${!!params.includeDefault}`,
            availableForZip: params.availableForZip !== undefined ? params.availableForZip + "" : "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createConnector(tenantId: string, request: ConnectorRequest): Promise<Connector> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getConnector(tenantId: string, id: string): Promise<Connector> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateConnector(tenantId: string, id: string, request: ConnectorRequest): Promise<Connector> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteConnector(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getTransformerUrl(tenantId: string, id: string, value?: string): Promise<string> {
    if (!value) return "";
    return getAuthenticatedGetUrl(`${basePath(tenantId)}/${id}/transformer`);
}
