import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CostCenter, CostCentersQueryParams } from "../model/CostCenter";
import { listCostCenters } from "../api/CostCenterAPI";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { CheckBoxCallbacks, CheckBoxColumnTitle, CheckBoxColumnValue } from "../components/GridItemCheckBox";
import { WarningSnackbar } from "../components/Snackbars";

interface CostCenterSelectionProps {
    tenantId: string;
    selectedIds: string[];
    onSelect(costCenters: CostCenter[]): any;
    onClose(): any;
}

export default function CostCenterSelectionPopup(props: CostCenterSelectionProps) {

    const [search, setSearch] = useState<string>();
    const [workingParams, setWorkingParams] = useState<CostCentersQueryParams>({
        search: "",
    } as CostCentersQueryParams);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("loading");
    const [warning, setWarning] = useState("");

    useEffect(() => {

        listCostCenters(props.tenantId, 0, 0, workingParams).then((response) => {
            if (props.selectedIds.length > 0) {
                setCostCenters(response.items.filter(cc => !props.selectedIds.includes(cc.id)));
            } else {
                setCostCenters(response.items);
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });

        // eslint-disable-next-line
    }, [props, search]);

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSearch(workingParams.search);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onConfirm = () => {
        if (selected.length <= 0) {
            setWarning(translate("prorationtemplates.cost_centers_empty_selection") as string);
            return;
        }
        let request = costCenters.filter(cc => selected.find(sel => cc.id === sel));
        props.onSelect(request);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = costCenters.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelected(items);
    };

    const handleCheck = (costCenter: CostCenter) => {
        let items = selected.length === 0 ? ([] as string[]) : selected.join(",").split(",");
        const index = selected.indexOf(costCenter.id);
        if (index < 0) {
            items.push(costCenter.id);
        } else {
            items.splice(index, 1);
        }
        setSelected(items);
    };


    return (
        <DialogPopup open
            title={translate("prorationtemplates.add_cost_centers_title") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            maxWidth="lg"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={
                <Box pl={1}>
                    <Button onClick={onConfirm} variant="outlined" color="primary" disabled={costCenters.length === 0} >
                        {translate("buttons.add") as string}
                    </Button>
                </Box>
            }>
            <Grid container className="PaperPagination">
                <Grid item xs={12}>
                    <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                        <Grid container alignItems="center" justifyContent="flex-end" className="TableFilter" spacing={1}>
                            <Grid item xs={12} sm>
                                <ValidatedInput type="text" id="search" name="search" label={translate("cost_centers.filter") as string}
                                    margin="dense" disabled={false}
                                    value={workingParams.search} onValueChanged={onFilterChanged} />
                            </Grid>
                            <Grid item xs="auto">
                                <Button type="submit" variant="outlined" color="secondary" size="medium">
                                    {translate("buttons.search")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <Gridable
                        items={costCenters}
                        loading={status === "loading"}
                        error={status !== "loading" && status !== "loaded" ? status : undefined}
                        empty={translate("prorationtemplates.cost_centers_empty_list") as string}
                        columns={[
                            {
                                title: (
                                    <CheckBoxColumnTitle
                                        label_title={translate("cost_centers.erp_id") as string}
                                        total={costCenters.length}
                                        selected={selected.length}
                                        callbacks={{ handleAllChecks: handleAllChecks } as CheckBoxCallbacks<CostCenter>}
                                        showChecks={true} />
                                ),
                                converter: (costCenter: CostCenter) => (
                                    <CheckBoxColumnValue
                                        item={costCenter}
                                        checked={selected.indexOf(costCenter.id) >= 0}
                                        showChecks={true}
                                        value={costCenter.erp_id}
                                        callbacks={{ handleCheck: handleCheck } as CheckBoxCallbacks<CostCenter>} />
                                ),
                                fullWidth: true,
                                xs: 4
                            },
                            {
                                title: translate("cost_centers.name") as string,
                                converter: (costCenter: CostCenter) => costCenter.name,
                                xs: 6
                            },
                            {
                                title: translate("cost_centers.category") as string,
                                converter: (costCenter: CostCenter) => costCenter.category,
                                xs: 2
                            }
                        ]} />
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={() => setWarning("")} />
        </DialogPopup>
    );

}