import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Hidden, Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import AddUsersIcon from '@material-ui/icons/GroupAddTwoTone';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottomTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ErrorSnackbar } from "../components/Snackbars";
import ExportUsersIcon from '@material-ui/icons/AssignmentIndTwoTone';

interface UsersGeneralMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onImportUsers(): any;
}

export default function UsersGeneralMenu(props: UsersGeneralMenuProps) {
    const context = useContext(AppContext);
    const [error, setError] = useState<string>();
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [openExportUsers, setOpenExportUsers] = useState<boolean>(false);

    const handleClickExportUsers = () => {
        setOpenExportUsers(!openExportUsers);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <Menu
            id="tenant-users-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
            autoFocus={false}
            variant="selectedMenu"
            >
            {context.isGrantedAny(["TenantsUsersCreate", "UsersCreate"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href="/plantilla-importacion-usuarios.xlsx" target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <VerticalAlignBottomIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.import_users.download_basic")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TenantsUsersCreate", "UsersCreate"]) &&
                <MenuItem button onClick={props.onImportUsers} dense>
                    <ListItemIcon>
                        <AddUsersIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.import_users.button")}</Typography>
                </MenuItem>
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Menu>
    );

}