import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import { getAdminDashboard } from "../api/MeAPI";
import Progress from "../components/Progress";
import TenantsDashboard from "./TenantsDashboard";
import { AdminDashboardResponse } from "../model/Dashboard";
import { getMetadata } from "../api/UserAPI";
import { Role } from "../model/Role";

export default function AdminDashboard() {
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<AdminDashboardResponse>();
    const [roles, setRoles] = useState<Role[]>();
    
    useEffect(() => {
        setStatus("loading");
        getAdminDashboard().then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });
        getMetadata().then((response) => {
            setRoles(response.roles);
        }).catch((error) => {
            setStatus(error.message);
        });

    }, []);

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded" || data === undefined || roles === undefined) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
                <TenantsDashboard tenants={data.tenants} summary={data.tenants_summary} />
            </Grid>
        </Grid>
    );
}
