import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Typography, ListSubheader, Divider, Box, Checkbox, Button } from "@material-ui/core";
import translate from "../i18n/Translator";
import { PasswordConfigRequest, SecurityConfigRequest, SingleSingOnConfigRequest, UsersAuthenticationModes } from "../model/TenantConfiguration";
import Surface from "../components/Surface";
import { getSecurityConfig, saveSecurityConfig } from "../api/TenantConfigurationApi";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";

import VpnKeyTwoTone from '@material-ui/icons/VpnKeyTwoTone';
import SimpleSwitch from "../components/SimpleSwitch";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { GTZeroValidator } from "../components/Validators";
import { useHistory } from 'react-router';
import { RolesQueryParams } from "../model/Role";
import { listRoles } from "../api/RoleAdminAPI";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";

export default function TenantSecurityForm({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [passwordConfigRequest, setPasswordConfigRequest] = useState<PasswordConfigRequest>({} as PasswordConfigRequest);
    const [ssoConfigRequest, setSsoConfigRequest] = useState<SingleSingOnConfigRequest>({} as SingleSingOnConfigRequest);
    const [securityConfigRequest, setSecurityConfigRequest] = useState<SecurityConfigRequest>({} as SecurityConfigRequest)
    const [redirect, setRedirect] = useState<string>();
    const gTZeroValidator = new GTZeroValidator();
    const history = useHistory();
    const [authenticationMode, setAuthenticationMode] = useState<string>(UsersAuthenticationModes.PDP_USERS_WITH_EXPIRATION_PASS);
    const [authModes] = useState<string[]>(Object.keys(UsersAuthenticationModes));
    const [authModesLabels] = useState<string[]>(authModes.map(m => translate(`tenant_security.auth_modes.${m}`) as string));
    const [rolesItems, setRolesItems] = useState<MultiselectValue[]>();
    const [rolesValues, setRolesValues] = useState<MultiselectValue[]>([]);

    const [passConfigValidations, setPassConfigValidations] = useState({
        "pass_expiration_days": {
            valid: false
        } as InputRef,
        "reminder_before_pass_expiration": {
            valid: false
        } as InputRef,
        "days_before_expiration_to_notify_daily": {
            valid: false
        } as InputRef
    } as any);

    const [ssoConfigValidations, setSsoConfigValidations] = useState({
        "access_token_issuer_url": {
            valid: false
        } as InputRef,
        "redirect_url": {
            valid: false
        } as InputRef,
        "access_token_audience": {
            valid: false
        } as InputRef,
        "profile_endpoint_url": {
            valid: false
        } as InputRef,
        "client_id": {
            valid: false
        } as InputRef,
        "role_attribute_in_profile": {
            valid: false
        } as InputRef,
    } as any);

    const load = () => {
        setStatus("loading");
        Promise.all([
            getSecurityConfig(match.params.tenantId),
            listRoles(0, 0, { tenantId: match.params.tenantId } as RolesQueryParams)
        ]).then(responses => {
            const securityResponse = responses[0];
            const rolesResponse = responses[1];
            let rItems = rolesResponse.items.map(r => { 
                return {value: r.id, title: r.description ?? translate(`users.roles.${r.id}`) as string} as MultiselectValue
            });
            setRolesItems(rItems);

            if (securityResponse.password_config_response || securityResponse.sso_config_response) {
                setSecurityConfigRequest({...securityConfigRequest, is_active: true});
            }

            if (securityResponse.password_config_response) {
                setAuthenticationMode(UsersAuthenticationModes.PDP_USERS_WITH_EXPIRATION_PASS);
                let passConfig = securityResponse.password_config_response;
                setPasswordConfigRequest({
                    pass_expiration_days: passConfig.pass_expiration_days,
                    reminder_before_pass_expiration: passConfig.reminder_before_pass_expiration,
                    notify_on_pass_expiration: passConfig.notify_on_pass_expiration,
                    notify_daily_before_pass_expiration: passConfig.notify_daily_before_pass_expiration,
                    days_before_expiration_to_notify_daily: passConfig.days_before_expiration_to_notify_daily
                } as PasswordConfigRequest);
            }

            if (securityResponse.sso_config_response) {
                setAuthenticationMode(UsersAuthenticationModes.SSO_OKTA);
                let ssoConfig = securityResponse.sso_config_response;
                setSsoConfigRequest({
                    access_token_issuer_url: ssoConfig.access_token_issuer_url,
                    redirect_url: ssoConfig.redirect_url,
                    access_token_audience: ssoConfig.access_token_audience,
                    profile_endpoint_url: ssoConfig.profile_endpoint_url,
                    client_id: ssoConfig.client_id,
                    role_attribute_in_profile: ssoConfig.role_attribute_in_profile,
                    allowed_roles: ssoConfig.allowed_roles,
                    authentication_provider: ssoConfig.authentication_provider,
                } as SingleSingOnConfigRequest);
                
                if (ssoConfig.allowed_roles) {
                    setRolesValues(rItems.filter(i => ssoConfig.allowed_roles.includes(i.value)));
                }
            }
        }).catch(error => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [match.params.tenantId]);

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const onSwitchActive = () => {
        setSecurityConfigRequest({ ...securityConfigRequest, is_active: !securityConfigRequest.is_active});
    };

    const isPassConfigValid = () => {
        var valid = true;
        for (let field in passConfigValidations) {
            let ref = passConfigValidations[field];
            if (!ref.valid) {
                if ("days_before_expiration_to_notify_daily" === field && !passwordConfigRequest.notify_daily_before_pass_expiration) {
                    ref.blurer(false);
                } else {
                    ref.blurer(true);
                    valid = false;
                }
            }
        }
        return valid;
    }

    const isSsoConfigValid = () => {
        var valid = true;
        for (let field in ssoConfigValidations) {
            let ref = ssoConfigValidations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let request = securityConfigRequest;
        delete request['single_sing_on_config_request'];
        delete request['password_config_request'];

        if (request.is_active && authenticationMode === UsersAuthenticationModes.PDP_USERS_WITH_EXPIRATION_PASS) {
            if (!isPassConfigValid()) {
                return;
            }
    
            if (passwordConfigRequest.reminder_before_pass_expiration > passwordConfigRequest.pass_expiration_days) {
                setWarning(translate('tenant_security.days_reminding_warning') as string);
                return;
            }
    
            if (passwordConfigRequest.days_before_expiration_to_notify_daily > passwordConfigRequest.pass_expiration_days) {
                setWarning(translate('tenant_security.days_email_reminding_warning') as string);
                return;
            }
            request.password_config_request = passwordConfigRequest;
        }

        if (request.is_active && authenticationMode === UsersAuthenticationModes.SSO_OKTA) {
            if (!isSsoConfigValid()) {
                return;
            }
            if (!ssoConfigRequest.allowed_roles || ssoConfigRequest.allowed_roles.length <= 0) {
                setWarning(translate('tenant_security.sso_allowed_roles_empty') as string);
                return;
            }
            ssoConfigRequest.authentication_provider = 'OKTA';
            request.single_sing_on_config_request = ssoConfigRequest;
        }

        setSubmitting(true);
        saveSecurityConfig(match.params.tenantId, request).then(() => {
            setRedirect(`/tenants/${translate("tenant_security.save_success_message") as string}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    }

    const hasPassConfigChanged = (name: string, value: string, inputRef: InputRef) => {
        setPasswordConfigRequest({ ...passwordConfigRequest, [name]: parseInt(value) });

        passConfigValidations[name] = inputRef;
        setPassConfigValidations(passConfigValidations);
    }

    const hasSsoConfigChanged = (name: string, value: string, inputRef: InputRef) => {
        setSsoConfigRequest({...ssoConfigRequest, [name]: value});
        ssoConfigValidations[name] = inputRef;
        setSsoConfigValidations(ssoConfigValidations);
    }

    const onCheckPassExpiration = () => {
        setPasswordConfigRequest({ ...passwordConfigRequest, notify_on_pass_expiration: !passwordConfigRequest.notify_on_pass_expiration });
    }

    const onCheckReminder = () => {
        setPasswordConfigRequest({
            ...passwordConfigRequest,
            notify_daily_before_pass_expiration: !passwordConfigRequest.notify_daily_before_pass_expiration,
        });
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onChangeAuthMode = (name: string, value: string, inputRef: InputRef) => {
        setAuthenticationMode(value);
    };

    const onRolesAllowedChanged = (selected: string[]) => {
        setRolesValues(rolesItems ? rolesItems.filter(i => selected.includes(i.value)) : []);
        setSsoConfigRequest({ ...ssoConfigRequest, allowed_roles: selected });
    }

    const passwordConfigForm = () => {
        return (<>
            <Grid item xs={12}>
                <ListSubheader disableGutters>
                    {translate(`tenant_security.passwords_expiration_title`) as string}
                </ListSubheader>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="flex-start" spacing={1}>
                    <Grid item>
                        <Typography variant="body1">{translate("tenant_security.password_expiration_label")}</Typography>
                    </Grid>
                    <Grid item style={{ "width": "200px" }}>
                        <ValidatedInput type="number" id="pass_expiration_days" name="pass_expiration_days"
                            value={passwordConfigRequest.pass_expiration_days + ""} label={" "} margin="dense"
                            required={true}
                            onValueChanged={hasPassConfigChanged}
                            validator={gTZeroValidator}
                            disabled={!securityConfigRequest.is_active || submitting}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{translate("tenant_security.days")}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box py={1}>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ListSubheader disableGutters>
                    {translate(`tenant_security.reminder_title`) as string}
                </ListSubheader>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="flex-start" spacing={1}>
                    <Grid item style={{ "width": "200px" }}>
                        <ValidatedInput type="number" id="reminder_before_pass_expiration" name="reminder_before_pass_expiration"
                            value={passwordConfigRequest.reminder_before_pass_expiration + ""} label={" "} margin="dense"
                            required={true}
                            onValueChanged={hasPassConfigChanged}
                            validator={gTZeroValidator}
                            disabled={!securityConfigRequest.is_active || submitting} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{translate("tenant_security.reminder_label")}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box py={1} />
            </Grid>
            <Grid item xs={12}>
                <ListSubheader disableGutters>
                    {translate(`tenant_security.notifications_title`) as string}
                </ListSubheader>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="flex-start" spacing={1}>
                    <Grid item style={{ "width": "40px" }}>
                        <Checkbox
                            checked={passwordConfigRequest.notify_on_pass_expiration}
                            onChange={onCheckPassExpiration}
                            value={passwordConfigRequest.notify_on_pass_expiration}
                            disabled={!securityConfigRequest.is_active || submitting}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{translate("tenant_security.send_expiration_mail")}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="flex-start" spacing={1}>
                    <Grid item style={{ "width": "40px" }}>
                        <Checkbox
                            checked={passwordConfigRequest.notify_daily_before_pass_expiration}
                            onChange={onCheckReminder}
                            value={passwordConfigRequest.notify_daily_before_pass_expiration}
                            disabled={!securityConfigRequest.is_active || submitting}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{translate("tenant_security.send_reminder_mail")}</Typography>
                    </Grid>
                    <Grid item style={{ "width": "200px" }}>
                        <ValidatedInput type="number" id="days_before_expiration_to_notify_daily" name="days_before_expiration_to_notify_daily"
                            value={passwordConfigRequest.notify_daily_before_pass_expiration ? passwordConfigRequest.days_before_expiration_to_notify_daily + "" : undefined} label={""} margin="dense"
                            required={passwordConfigRequest.notify_daily_before_pass_expiration}
                            onValueChanged={hasPassConfigChanged}
                            validator={gTZeroValidator}
                            disabled={!securityConfigRequest.is_active || !passwordConfigRequest.notify_daily_before_pass_expiration} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{translate("tenant_security.days_before_expire")}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>);
    }

    const ssoConfigForm = () => {
        return (<>
            <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <ListSubheader disableGutters>
                            {translate(`tenant_security.sso_config_title`) as string}
                        </ListSubheader>
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="access_token_issuer_url" name="access_token_issuer_url"
                            value={ssoConfigRequest.access_token_issuer_url} label={translate("tenant_security.sso_access_token_issuer_url") as string}
                            disabled={!securityConfigRequest.is_active || submitting} margin="dense" required
                            onValueChanged={hasSsoConfigChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="redirect_url" name="redirect_url"
                            value={ssoConfigRequest.redirect_url} label={translate("tenant_security.sso_redirect_url") as string}
                            disabled={!securityConfigRequest.is_active || submitting} margin="dense" required
                            onValueChanged={hasSsoConfigChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="access_token_audience" name="access_token_audience"
                            value={ssoConfigRequest.access_token_audience} label={translate("tenant_security.sso_access_token_audience") as string}
                            disabled={!securityConfigRequest.is_active || submitting} margin="dense" required
                            onValueChanged={hasSsoConfigChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="profile_endpoint_url" name="profile_endpoint_url"
                            value={ssoConfigRequest.profile_endpoint_url} label={translate("tenant_security.sso_profile_endpoint_url") as string}
                            disabled={!securityConfigRequest.is_active || submitting} margin="dense" required
                            onValueChanged={hasSsoConfigChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="client_id" name="client_id"
                            value={ssoConfigRequest.client_id} label={translate("tenant_security.sso_client_id") as string}
                            disabled={!securityConfigRequest.is_active || submitting} margin="dense" required
                            onValueChanged={hasSsoConfigChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="role_attribute_in_profile" name="role_attribute_in_profile"
                            value={ssoConfigRequest.role_attribute_in_profile} label={translate("tenant_security.sso_role_attribute_in_profile") as string}
                            disabled={!securityConfigRequest.is_active || submitting} margin="dense" required
                            onValueChanged={hasSsoConfigChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiselectDropList
                            title={translate("tenant_security.sso_allowed_roles") as string}
                            margin="dense"
                            elementos={rolesItems ?? []}
                            onChanged={onRolesAllowedChanged}
                            value={rolesValues}
                            required
                            disabled={!securityConfigRequest.is_active || submitting} />
                    </Grid>
                </Grid>
            </Grid>
        </>);
    }

    return (
        <Surface
            title={(translate("tenant_security.title") as string)}
            icon={<VpnKeyTwoTone />} titleActions={
                <SimpleSwitch
                    value="active"
                    label={securityConfigRequest.is_active ? translate("tenant_security.active") as string : translate("tenant_security.inactive") as string}
                    checked={securityConfigRequest.is_active}
                    onChanged={onSwitchActive}
                    placement="end"
                    color="primary"
                    disabled={submitting} />
            } >
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ListSubheader disableGutters>
                            {translate("tenant_security.users_and_passwords_handling") as string}
                        </ListSubheader>
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput
                            type="text"
                            id="authentication_mode"
                            name="authentication_mode"
                            value={authenticationMode}
                            label={""}
                            disabled={!securityConfigRequest.is_active || submitting}
                            options={authModes}
                            optionLabels={authModesLabels}
                            margin={"dense"}
                            omitEmpty
                            onValueChanged={onChangeAuthMode} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={3}>
                            <Divider />
                        </Box>
                    </Grid>
                    {authenticationMode === UsersAuthenticationModes.PDP_USERS_WITH_EXPIRATION_PASS && passwordConfigForm()}
                    {authenticationMode === UsersAuthenticationModes.SSO_OKTA && ssoConfigForm()}
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                        <Grid item xs={12} md="auto">
                                            <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                {translate("buttons.save")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md="auto">
                                            <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack} >
                                                {translate("buttons.cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Surface>
    );
}
