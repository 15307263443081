import React from "react";
import LimpioIcon from "@material-ui/icons/BeenhereTwoTone";
import PresuntoIcon from "@material-ui/icons/ReportTwoTone";
import DefinitivoIcon from "@material-ui/icons/HighlightOffTwoTone";
import UndefinedIcon from "@material-ui/icons/ContactSupportTwoTone";
import DesvirtuadoIcon from "@material-ui/icons/AssignmentLateTwoTone";
import SentenciaFavorableIcon from "@material-ui/icons/AssignmentTurnedInTwoTone";
import NotLocatedIcon from "@material-ui/icons/NotListedLocationTwoTone";
import EnabledIcon from "@material-ui/icons/CheckCircleTwoTone";
import AnnouncementIcon from '@material-ui/icons/AnnouncementTwoTone';
import CancelIcon from '@material-ui/icons/CancelTwoTone';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumberTwoTone';
import GavelIcon from '@material-ui/icons/GavelTwoTone';

interface ProviderIconProps {
    status: string;
    fontSize?: "default" | "inherit" | "small" | "large";
    noColor?: boolean;
}

export const COLORS = {
    "DEFINITIVO": "#C33149",
    "PRESUNTO": "#A4DE6C",
    "DESVIRTUADO": "#83A6ED",
    "SENTENCIA_FAVORABLE": "#8DD1E1",
    "LIMPIO": "#82CA9D"
} as any;

export const COLORS_NOT_LOCATED = {
    "LIMPIO": "#82CA9D",
    "NO_LOCALIZADO": "#C33149",
    "CANCELADO": "#A4DE6C",
    "EXIGIBLE": "#83A6ED",
    "FIRME": "#8DD1E1",
    "SENTENCIADO": "#2987E8"
} as any;

export const COLORS_STATUS = {
    "ENABLED": "#82CA9D",
    "DISABLED": "#aaaaaa",
} as any;

export function ProviderEfoIcon(props: ProviderIconProps) {
    let size = props.fontSize || "default";
    let color = (props.noColor ? undefined : COLORS[props.status]) as string | undefined;

    switch (props.status) {
        case "DEFINITIVO":
            return (<DefinitivoIcon fontSize={size} style={{ "color": color }} />);
        case "PRESUNTO":
            return (<PresuntoIcon fontSize={size} style={{ "color": color }} />);
        case "DESVIRTUADO":
            return (<DesvirtuadoIcon fontSize={size} style={{ "color": color }} />);
        case "SENTENCIA_FAVORABLE":
            return (<SentenciaFavorableIcon fontSize={size} style={{ "color": color }} />);
        case "LIMPIO":
            return (<LimpioIcon fontSize={size} style={{ "color": color }} />);
        default:
            return (<UndefinedIcon fontSize={size} style={{ "color": color }} />);
    }
}

export function Provider69CffIcon(props: ProviderIconProps) {
    let size = props.fontSize || "default";
    let color = (props.noColor ? undefined : COLORS_NOT_LOCATED[props.status]) as string | undefined;
    switch (props.status) {
        case "LIMPIO":
            return (<LimpioIcon fontSize={size} style={{ "color": color }} />);
        case "NO_LOCALIZADO":
            return (<NotLocatedIcon fontSize={size} style={{ "color": color }} />);
        case "CANCELADO":
            return (<CancelIcon fontSize={size} style={{ "color": color }} />);
        case "EXIGIBLE":
            return (<AnnouncementIcon fontSize={size} style={{ "color": color }} />);
        case "FIRME":
            return (<ConfirmationNumberIcon fontSize={size} style={{ "color": color }} />);
        case "SENTENCIADO":
            return (<GavelIcon fontSize={size} style={{ "color": color }} />);
        default:
            return (<UndefinedIcon fontSize={size} style={{ "color": color }} />);
    }
}

export function ProviderStatusIcon(props: ProviderIconProps) {
    let size = props.fontSize || "default";
    let color = (props.noColor ? undefined : COLORS_STATUS[props.status]) as string | undefined;
    switch (props.status) {
        case "ENABLED":
            return (<EnabledIcon fontSize={size} style={{ "color": color }} />);
        case "DISABLED":
            return (<DefinitivoIcon fontSize={size} style={{ "color": color }} />);
        default:
            return (<UndefinedIcon fontSize={size} style={{ "color": color }} />);
    }
}