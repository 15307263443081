import { User } from "./User";

export interface ZipUpload {
    id: string;
    tenant_id: string;
    user_id: string;
    file_name: string;
    found: number;
    success: number;
    failed: number;
    created_at: Date;
    user?: User;
}

export interface ZipUploads {
    items: ZipUpload[];
    total: number;
}

export interface ZipUploadsQueryParams {
    search: string;
}

export interface ZipUploadRequest {
    tenant_id: string;
    user_id: string;
    file_name: string;
    found: number;
    success: number;
    failed: number;
    created_at: Date;
}

export type ZipInterpreter = "API" | "CONNECTORS";

export const INTERPRETERS: ZipInterpreter[] = ["API", "CONNECTORS"];