import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory, Link } from "react-router-dom";

import { Grid, Divider, Fab, IconButton } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { Column, Grouping } from "@devexpress/dx-react-grid";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomBackdrop from "../components/CustomBackdrop";

import { list as getProjects, exportSelectedProjects } from "../api/ProjectAPI";
import { Project, ProjectListParams, ProjectsResponse } from "../model/Project";
import ProjectToStartIcon from "@material-ui/icons/AddToPhotos";
import ProjectExecutionIcon from "@material-ui/icons/AccountTree";

import { Filter } from '@devexpress/dx-react-grid';
import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { formatDateString, concatDates } from "../components/DateFormat";
import { isRoleOrParent } from "../model/Role";
import ProjectsManagementMenu from "./ProjectsManagementMenu";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { ExportResponse, Family } from "../model/Connector";
import ExportPopup from "../components/DownloadExportationPopup";

export interface ProjectGridProps {
    title: string;
    success?: string;
    columns: Column[];
    clickRowColumns?: string[];
    defaultExpandedGroups?: string[];
    currencyColumns?: string[];
    numberColumns?: string[];
    dateColumns?: string[];
    textColumns?: string[];
    columnsFormat: any[];
    leftColumns?: any[];
    rightColumns?: any[];
    grouping?: Grouping[];
    statusPlugins?: JSX.Element[];
    userId?: string;
    show_create: boolean;
    statuses: string[];
}

export type ExportMode = "SELECTED";

export default function ListProjects(props: ProjectGridProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ProjectListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "budget_status": typeof qs["budget_status"] === "string" ? qs["budget_status"] as string : "",
            "project_name": typeof qs["project_name"] === "string" ? qs["project_name"] as string : "",
            "project_type": typeof qs["project_type"] === "string" ? qs["project_type"] as string : "",
            "customer": typeof qs["customer"] === "string" ? qs["customer"] as string : "",
        } as ProjectListParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ProjectsResponse>();
    const [params, setParams] = useState<ProjectListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ProjectListParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const isOwner = isRoleOrParent(context.session!.role, "owner") || isRoleOrParent(context.session!.role, "project_manager");

    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const [selection, setSelection] = useState<string[]>([]);
    const paramsToFilters = () => {
        return [
            { columnName: "key", value: params.key },
            { columnName: "created_at", value: concatDates(params.start_date, params.end_date) },
            { columnName: "name", value: params.project_name },
            { columnName: "type", value: params.project_type },
            { columnName: "customer", value: params.customer },
            { columnName: 'current_budget', value: params.current_budget },
            { columnName: 'vs_presup', value: params.budget_status },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());
    const [exportMode, setExportMode] = useState<ExportMode>();
    const [exportResult, setExportResult] = useState<ExportResponse>();

    const load = () => {
        params.status = props.statuses.join(",");
        setStatus("loading");
        setSelection([]);
        if (props.success && isSuccess) {
            setSuccess(props.success);
            setIsSuccess(false);
        }
        if (context.isGranted("ProjectsRead")) {
            getProjects(context.session!.tenant!.id, pageSize, pageSize * page, { ...params, user_id: props.userId, role_team: props.userId ? "AUTHORIZER" : "" } as ProjectListParams).then((response) => {
                setOpenBackdrop(false);
                setData(response);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "key":
                        temp.key = filter.value;
                        break;
                    case "created_at":
                        let paymentDates = filter.value.split(" - ");
                        if (paymentDates.length === 2) {
                            temp.start_date = formatDateString(paymentDates[0]);
                            temp.end_date = formatDateString(paymentDates[1]);
                        } else {
                            temp.start_date = "";
                            temp.end_date = "";
                        }
                        break;
                    case "name":
                        temp.project_name = filter.value;
                        break;
                    case "type":
                        temp.project_type = filter.value;
                        break;
                    case "customer":
                        temp.customer = filter.value;
                        break;
                    case "current_budget":
                        temp.current_budget = filter.value;
                        break;
                    case "vs_presup":
                        temp.budget_status = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
        pushHistory();
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status"] = workingParams.status ? workingParams.status : "";
        qs["key"] = workingParams.key ? workingParams.key : "";
        qs["name"] = workingParams.project_name ? workingParams.project_name : "";
        qs["type"] = workingParams.project_type ? workingParams.project_type : "";
        qs["customer"] = workingParams.customer ? workingParams.customer : "";
        qs["start_date"] = workingParams.start_date ? workingParams.start_date : "";
        qs["end_date"] = workingParams.end_date ? workingParams.end_date : "";
        qs["page"] = "0";
        qs["current_budget"] = workingParams.current_budget ? workingParams.current_budget : "";
        qs["budget_status"] = workingParams.budget_status ? workingParams.budget_status : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const setSelectionHandler = (selected: string[]) => {
        setSelection(selected);
    };

    const onClickedOptions = (project: Project) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedRow = (project: Project) => {
        pushHistory();
        setRedirect(`/projects/detail/${project.id}/`);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onExportSelected = () => {
        setGridAnchorEl(null);
        if (selection.length) {
            setExportMode('SELECTED');
        } else {
            setWarning(translate("requisitions.projects.sub_menu.empty_selection") as string);
        }
    }

    const onCloseExporter = () => {
        setExportMode(undefined);
    };

    const downloadProjectsWithConnector = (connectorId: string) => {
        if (!exportMode) return;

        let promise: Promise<ExportResponse>;
        promise = exportSelectedProjects(context.session!.tenant!.id, connectorId, selection);
        promise.then((response) => {
            if (response.url) {
                setExportResult(response);
            } else if (response.total) {
                setSuccess(translate("requisitions.projects.sub_menu.email_export", { "total": response.total }) as string);
            } else {
                setWarning(translate("requisitions.projects.sub_menu.empty_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(
            onCloseExporter
            );
    }

    const onCloseDownloadProjects = () => {
        setExportResult(undefined);
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Surface title={props.title}
            icon={props.show_create ? <ProjectToStartIcon /> : props.title.includes("Archivo") ? <ArchiveIcon /> : <ProjectExecutionIcon />}
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {context.isGranted("ProjectsCreate") && props.show_create ?
                        (<Grid item xs="auto">
                            <Link to={`/projects/create`}>
                                <Fab color="primary" size="small" title={translate("requisitions.projects.new_project.create_project") as string} disabled={status === "loading"}>
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </Grid>) : undefined}
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            }
            className="PaperPagination">
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={props.columns ? props.columns : []}
                defaultExpandedGroups={props.defaultExpandedGroups}
                onClickRow={onClickedRow}
                clickRowColumns={props.clickRowColumns}
                amountCurrencyColumns={props.currencyColumns}
                quantityColumns={props.numberColumns}
                dateColumns={props.dateColumns}
                columnsFormat={props.columnsFormat}
                leftColumns={props.leftColumns}
                textColumns={props.textColumns}
                rightColumns={props.rightColumns}
                grouping={props.grouping}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                selectionIds={selection}
                filters={filters}
                customFormatColumns={props.statusPlugins}
                setFiltersHandler={setFiltersHandler}
                setSelectionHandler={setSelectionHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {gridAnchorEl && (
                <ProjectsManagementMenu
                    anchor={gridAnchorEl}
                    totalItems={data?.total}
                    onClose={() => setGridAnchorEl(null)}
                    onExportSelected={onExportSelected}
                />
            )}
            {exportMode && (
                <ConnectorObjectExporter tenantId={context.session!.tenant!.id}
                    family={Family.PROJECTS}
                    type={"DATA"}
                    onExport={downloadProjectsWithConnector}
                    onClose={onCloseExporter}
                />
            )}
            {exportResult && exportResult.url && (
                <ExportPopup title={translate("requisitions.projects.sub_menu.export_projects") as string}
                    message={translate("requisitions.projects.sub_menu.projects_found", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadProjects} />
            )}
        </Surface>

    );
}
