import { PurchaseOrder } from "../model/PurchaseOrder";
import { CatalogValue } from "./Catalog";

export interface Project {
    id: string;
    tenant_id: string;
    name: string;
    key: string;
    type: string;
    customer: string;
    initial_budget: number;
    current_budget: number;
    assigned_budget: number;
    budget_to_assign: number;
    accrued_budget: number;
    budget_to_accrue: number;
    currency: string;
    status: string;
    requisition_configurations: RequisitionConfiguration[];
    issuer_id: string;
    created_at: Date;
    updated_at: Date;
    ended_at: Date;
    purchase_order_ids: string[];
    history: ProjectHistoryRecord[];
    purchase_orders: PurchaseOrder[];
    budget_in_dollars: number;
    exchange_rate_date: Date;
    notification_group_id: string;
    template?: Template;
    values_catalog: Record<string, CatalogValue[]>;
}

export interface RequisitionConfiguration {
    id: string;
    team_id: string;
    requisition_type: string;
}

export interface MemberTeamHistory {
    team_name: string;
    member_name: string;
    role_team: string;
}

export interface ProjectHistoryRecord {
    user_name: string;
    user_id: string;
    status: string;
    previus_status: string;
    initial_budget: number;
    current_budget: number;
    assigned_budget: number;
    budget_to_assign: number;
    accrued_budget: number;
    budget_to_accrue: number;
    updated_date: Date;
    history_type: string;
    comments: string;
    vs_presup: string;
}

export interface ProjectRequest {
    project_name: string;
    key: string;
    type: string;
    customer: string;
    currency: string;
    initial_budget: number;
    current_budget: number;
    user_executor_id: string;
    comments: string;
    status: string;
    requisition_configurations: RequisitionConfiguration[];
    notification_group_id?: string;
    template_id?: string;
}

export interface ProjectsResponse {
    items: Project[];
    total: number;
}

export interface ProjectListParams {
    search: string;
    key: string;
    start_date: string;
    end_date: string;
    project_name: string;
    project_type: string;
    customer: string;
    status: string;
    current_budget: string;
    budget_status: string;
    user_id: string;
    role_team: string;
    requisition_type: string;
}

export interface Template {
    id: string;
    name: string;
    type: string;
    fields: Fields[];
}

export interface Fields {
    field: Field;
    value?: string;
}

export interface Field {
    aux_file_url: string,
    description: string,
    external_id: string,
    id: string,
    name: string,
    privado: boolean,
    required: boolean,
    type: string,
    use_in_cfdi: boolean;
    catalog_id:string;
}

export const roles = ["REQUESTER", "AUTHORIZER", "PURCHASER"];

export const Statuses = ["DRAFT", "TO_START", "IN_PROGRESS", "FINALIZED"];

export const ProjectTypes = ["INTERN", "CUSTOMER"];

export const StatusBuget = ["UP", "DOWN", "EQUAL"];