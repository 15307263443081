import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import IntegrationLogIcon from "@material-ui/icons/RestorePageTwoTone";

import translate from "../i18n/Translator";
import { ZipUpload } from "../model/ZipUpload";
import { AppContext } from "../context/AppContext";

interface ZipUploadMenuProps {
    zipUpload: ZipUpload;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function ZipUploadMenu(props: ZipUploadMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="zip-uploads-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("IntegrationLogsRead") &&
                <MenuItem button dense component={Link} to={`/zip-uploads/${props.zipUpload.id}/integration-logs`}>
                    <ListItemIcon>
                        <IntegrationLogIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("integration_logs.title")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}