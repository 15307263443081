import React, { useContext, useEffect, useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";

import ValidatedInput from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import { AppContext } from "./AppContext";
import { Access, Session, SessionBody } from "../model/Session";

export default function TenantSwitch() {
    const context = useContext(AppContext);
    const accessToString = (access: Access) => {
        return JSON.stringify({
            tenant_id: access.tenant_id || "",
            provider_id: access.provider_id || "",
            customer_id: access.customer_id || "",
        } as SessionBody);
    };

    const sessionToBody = (session?: Session) => {
        return {
            tenant_id: session?.tenant?.id || "",
            provider_id: session?.provider?.id || "",
            customer_id: session?.customer?.id || "",
        } as SessionBody;
    };
    
    const buildWorkspaceName = (access : Access) => {
        return access.tenant_id == "" ? translate("app.title") as string: [access.mode, access.name, access.provider_name || access.customer_name].filter(i => !!i).join(" - ");
    }

    const sessionToString = (session?: Session) => {
        return JSON.stringify(sessionToBody(session));
    };

    let id = sessionToString(context.session);

    const [accesses, setAccesses] = useState<Access[]>();
    const [currentAccess, setCurrentAccess] = useState<Access>();
    const changeTenant = (_name: string, value: string, _: any) => {
        const current = sessionToString(context.session);
        if (current === value || value === "") {
            return;
        }

        const body = value ? JSON.parse(value) as SessionBody : sessionToBody();
        context.onChangedTenant(body);
    };

    useEffect(() => {
        if (context.session && context.session.accesses) {
            setAccesses(context.session.accesses);
            setCurrentAccess(context.session.accesses.find(el => accessToString(el) == id));
        }
    }, [context.session]);

    return (
        <Box pt={3} pb={1}>
            {context.session && context.session.user_impersonalizer &&
                <Grid container justify="center">
                    <Grid item xs={10}>
                        <Typography variant="caption" component="h6">
                            <b>{translate("users.impersonalize.support") as string}</b>
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Grid container justify="center">
                <Grid item xs={10}>
                        <ValidatedInput margin="dense" type="text" id="tenant_picker" name="tenant_picker" label={translate("app.switch") as string}
                        value={id} onValueChanged={changeTenant}
                        variant="standard"
                        autocompleteOptions={accesses} autocompleteValue={currentAccess}
                        getId={accessToString}
                        getLabel={buildWorkspaceName}
                        />
                </Grid>
            </Grid>
        </Box>
    );
}