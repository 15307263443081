import React from "react";
import { Typography, Button } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "./DialogPopup";

interface DownloadExportationProps {
    title: string;
    message: string;
    url: string;
    onClose(): any;
}

export default function DownloadExportationPopup(props: DownloadExportationProps) {
    const buttonDownload = () => {
        return (
            <Button component="a" href={props.url} target="_blank" rel="noopener noreferrer" onClick={props.onClose} variant="outlined" color="primary">
                {translate("buttons.download") as string}
            </Button>
        );
    }

    return (
        <DialogPopup open
            title={props.title}
            onClose={props.onClose}
            closeColor="default"
            button={buttonDownload()}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}>
            <Typography variant="body2">
                {props.message}
            </Typography>
        </DialogPopup>
    );
}

