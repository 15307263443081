import React, { useContext, useState } from "react";
import { Menu, ListItemIcon, Fade, ListItem, ListItemText, Collapse, List } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import GetAppIcon from "@material-ui/icons/GetAppTwoTone";
import PublishIcon from "@material-ui/icons/PublishTwoTone";
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';

export interface BaseExportMenuProps {
    anchor: HTMLElement | null;
    showExportFiles?: boolean;
    onClose(): any;
    onExportWithParams(): any;
    onExportSelected(): any;
    onImport(): any;
    onDownloadTemplate(): any;
    onDownloadFilesSelected?(): any;
    onDownloadFilesWithParams?(): any;
}

interface ExportMenuProps extends BaseExportMenuProps {
    createAction: string
    getAction: string
}

export default function ExportMenu(props: ExportMenuProps) {
    const context = useContext(AppContext);
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [openExportFiles, setOpenExportFiles] = useState<boolean>(false);

    const handleClickExport = () => {
        setOpenExport(!openExport);
    };

    const handleClickExportFiles = () => {
        setOpenExportFiles(!openExportFiles);
    };

    return (
        <Menu id={`export-menu-item`}
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {context.isGranted(props.createAction) &&
                <ListItem button onClick={props.onDownloadTemplate} dense>
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("importable.template")} />
                </ListItem>
            }
            {context.isGranted(props.createAction) &&
                <ListItem button onClick={props.onImport} dense>
                    <ListItemIcon>
                        <PublishIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("importable.file")} />
                </ListItem>
            }
            {props.showExportFiles && props.onDownloadFilesSelected && props.onDownloadFilesWithParams && <>
                {context.isGranted(props.getAction) && (
                    <ListItem button onClick={handleClickExportFiles} dense>
                        <ListItemIcon>
                            <DownloadIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("exportable.download_files")} />
                        {openExportFiles ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                )}
                {context.isGranted(props.getAction) && (
                    <Collapse in={openExportFiles} timeout="auto" unmountOnExit>
                        <List component="div">
                            <ListItem button onClick={props.onDownloadFilesSelected} dense >
                                <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                    <Selected fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={translate("exportable.selected")} />
                            </ListItem>
                            <ListItem button onClick={props.onDownloadFilesWithParams} dense >
                                <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                    <DownloadList fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={translate("exportable.by_date_ranges")} />
                            </ListItem>
                        </List>
                    </Collapse>
                )}
            </>}
            {context.isGranted(props.getAction) && (
                <ListItem button onClick={handleClickExport} dense>
                    <ListItemIcon>
                        <ImportExportIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("exportable.export")} />
                    {openExport ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
            {context.isGranted(props.getAction) && (
                <Collapse in={openExport} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem button onClick={props.onExportSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("exportable.selected")} />
                        </ListItem>
                        <ListItem button onClick={props.onExportWithParams} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("exportable.by_date_ranges")} />
                        </ListItem>
                    </List>
                </Collapse>
            )}
        </Menu>
    );
}
