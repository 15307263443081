import React, { useState, useEffect } from "react";
import { Select } from '@material-ui/core';
import GridDx from "../components/GridDx";
import { Budget, LineBudgetParams, BudgetLine, BudgetLinesPerformance as lineas, BudgetVariationStatus } from "../model/Budget";
import translate from "../i18n/Translator";
import { Filter, DataTypeProviderProps, DataTypeProvider } from '@devexpress/dx-react-grid';
import { listBudgetLinesPerformance } from "../api/BudgetApi";
import { initialPageZero, initialPageSize } from '../components/Pagination';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

interface BudgetLinesPerformanceProps {
    budget: Budget;
    onClickRow(id: string): any;
}

export default function BudgetLinesPerformance(props: BudgetLinesPerformanceProps) {
    const [status, setStatus] = useState<string>("loading");
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [data, setData] = useState<lineas | undefined>();

    const paramsFromQueryString = (): LineBudgetParams => {
        return {} as LineBudgetParams;
    };
    const [params, setParams] = useState<LineBudgetParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<LineBudgetParams>(paramsFromQueryString);

    useEffect(() => {
        setStatus("loading");

        listBudgetLinesPerformance(props.budget.tenant_id, props.budget.id, pageSize, pageSize * page,
            {
                current_amount: workingParams.current,
                external_id: workingParams.external_id,
                initial_amount: workingParams.initial,
                name: workingParams.name,
                team_name: workingParams.team,
                accrued: workingParams.accrued,
                description: workingParams.description,
                exercised: workingParams.exercised,
                to_accrued: workingParams.to_accrued,
                to_exercised: workingParams.to_exercised,
                variation: workingParams.variation,
            }
        ).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [params, page, pageSize, workingParams]);


    const [columnsFormat] = useState([
        { columnName: 'external_id', wordWrapEnabled: true, width: 150 },
        { columnName: 'name', wordWrapEnabled: true, width: 350 },
        { columnName: 'initial', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'variation', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'current', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'accrued', align: 'right', wordWrapEnabled: true, width: 140 },
        { columnName: 'exercised', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'to_accrued', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'to_exercised', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'team', wordWrapEnabled: true, width: 300 },
    ]);

    const columns = [
        {
            name: 'external_id',
            title: translate('budget.performance.external_id') as string,
            getCellValue: (row: any) => row.external_id
        },
        {
            name: 'name',
            title: translate('budget.performance.name') as string,
            getCellValue: (row: any) => row.description
        },
        {
            name: 'initial',
            title: translate("budget.performance.inicial_amount") as string,
            getCellValue: (row: any) => row.initial_amount
        },
        {
            name: 'variation',
            title: translate("budget.performance.variation") as string,
            getCellValue: (row: any) => row.variation_amount
        },
        {
            name: 'current',
            title: translate("budget.performance.current_amount") as string,
            getCellValue: (row: any) => row.current_amount
        },
        {
            name: 'accrued',
            title: translate("budget.performance.accrued_amount") as string,
            getCellValue: (row: any) => row.accrued_amount
        },
        {
            name: 'exercised',
            title: translate("budget.performance.exercised_amount") as string,
            getCellValue: (row: any) => row.exercised_amount
        },
        {
            name: 'to_accrued',
            title: translate("budget.performance.to_accrued_amount") as string,
            getCellValue: (row: any) => row.pending_accrue_amount
        },
        {
            name: 'to_exercised',
            title: translate("budget.performance.to_exercised_amount") as string,
            getCellValue: (row: any) => row.pending_exercise_amount
        },
        {
            name: 'team',
            title: translate("budget.performance.team") as string,
            getCellValue: (row: any) => getTeam(row)
        },
    ];

    const getTeam = (line: BudgetLine) => {
        if (line.team_name) {
            return line.team_name;
        }
        return (props?.budget?.teams ?? []).find(item => line.team_id && line.team_id === item.id)?.name ?? "--";
    };

    const currencyColumns = ['initial', 'current', 'accrued', 'exercised', 'to_accrued', 'to_exercised', 'variation'];

    const rightColumns = ['external_id', 'name'];

    const textColumns = ['external_id', 'name', 'team'];

    const paramsToFilters = () => {
        return [
            { columnName: 'external_id', value: params.external_id },
            { columnName: 'name', value: params.name },
            { columnName: 'initial', value: params.initial },
            { columnName: 'current', value: params.current },
            { columnName: 'accrued', value: params.accrued },
            { columnName: 'exercised', value: params.exercised },
            { columnName: 'to_accrued', value: params.to_accrued },
            { columnName: 'to_exercised', value: params.to_exercised },
            { columnName: 'variation', value: params.variation },
            { columnName: 'team', value: params.team },
        ] as Filter[];
    };

    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };
    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                temp = { ...temp, [filter.columnName]: filter.value };
            }
        });
        setWorkingParams(temp);
        setParams(temp);
    };

    const onClickRow = (line: BudgetLine) => {
        if (!line) {
            return;
        }
        props.onClickRow(line.external_id);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );


    const VariationFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {BudgetVariationStatus.map((value) => {
                            return <option key={value} value={value}>{translate(`budget.lists.variation_status.${value}`) as string}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const customPlugins = [
        <NormalTypeProvider for={['variation']} editorComponent={VariationFilterEditor} />,
    ];

    return (
        <GridDx
            loading={status === "loading"}
            emptyMessage={""}
            noUseId={true}
            rows={data?.items ?? []}
            columns={columns ? columns : []}
            columnsFormat={columnsFormat}
            onClickRow={onClickRow}
            clickRowColumns={['external_id', 'name', 'description']}
            amountCurrencyColumns={currencyColumns}
            dateColumns={[]}
            leftColumns={[]}
            rightColumns={rightColumns}
            textColumns={textColumns}
            filters={filters}
            setFiltersHandler={setFiltersHandler}
            page={page}
            pageSize={pageSize}
            totalRows={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            customFormatColumns={customPlugins}
        />
    );
}