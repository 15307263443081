import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";

import translate from "../i18n/Translator";
import { Role } from "../model/Role";
import { AppContext } from "../context/AppContext";
import { EditIcon } from "../components/Icons";

interface RolesAdminMenuProps {
    role: Role;
    anchor: HTMLElement | null;
    onClose(): any;
    onEdit(): any;
}

export default function RolesAdminMenu(props: RolesAdminMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="employee_groups-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
                {context.isGrantedAny(["ActionsAdminUpdate"]) &&
                    <MenuItem button onClick={props.onEdit} dense>
                        <ListItemIcon>
                            <EditIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                    </MenuItem>
                }
        </Menu>
    );

}