import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton, Divider } from "@material-ui/core";
import SatTaxIcon from "@material-ui/icons/AccountBalance";

import { getSatContribution, updateSatContribution, getSatContributionFileUrl } from "../api/SatContributionAPI";
import { getSatContribution as getProviderSatContribution, updateSatContribution as updateProviderSatContribution, getSatContributionFileUrl as getProviderSatContributionFileUrl } from "../api/ProviderSatContributionAPI";
import translate from "../i18n/Translator";
import { SatContribution, SatContributionRequest, SatContributionFileType } from "../model/SatContribution";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import { InputRef, isValid } from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import UploaderInput from "../components/UploaderInput";
import { getSatContributionPeriod } from "./SatContributionUtil";
import ConfirmationPopup from "../components/ConfirmationPopup";
import ToValidatePaymentForm from "./ToValidatePaymentForm";

export default function SatTaxForm({ match }: RouterParams) {
    const [view, setView] = useState<"new" | "validate-payment">("new");
    const [url, setUrl] = useState<string>("");
    const [satContribution, setSatContribution] = useState<SatContribution>();
    const onValidatePayment = (url: string, contribution : SatContribution) => {
        setUrl(url);
        setSatContribution(contribution);
        setView("validate-payment");
    };
    if(view === "validate-payment"){
        return <ToValidatePaymentForm urlPdf={url} complementary={true} satContribution={satContribution}/>
    }
    return <SatContributionForm onValidatePayment={onValidatePayment} contributionId={match.params.satContributionId}/>;
}

export interface SatContributionFormParams {
    onValidatePayment(url: string, contribution : SatContribution): any;
    contributionId: string;
}

function SatContributionForm(props: SatContributionFormParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string>();
    const [subtitle, setSubtitle] = useState<string>();
    const [request, setRequest] = useState<SatContributionRequest>({} as SatContributionRequest);
    const [validations, setValidations] = useState({} as any);
    const [satContribution, setSatContribution] = useState<SatContribution>();
    const [payment, setPayment] = useState<string>();
    const [complementaryPayment, setComplementaryPayment] = useState<string>();
    const [paymentVoucher, setPaymentVoucher] = useState<string>();
    const [complementaryPaymentVoucher, setComplementaryPaymentVoucher] = useState<string>();
    const [openValidateDialog, setOpenValidateDialog] = useState<boolean>();
    const [openValidatePdfDialog, setOpenValidatePdfDialog] = useState<string>();
    const [isRejectedVoucher, setIsRejectedVoucher] = useState<boolean>();
    const [isRejectedComplementaryVoucher, setIsRejectedComplementaryVoucher] = useState<boolean>();
    const [hasCaptureLine, setHasCaptureLine] = useState<boolean>(false);
    const [hasCaptureLineCompl, setHasCaptureLineCompl] = useState<boolean>(false);
    const [openValidatePaymentDialog, setOpenValidatePaymentDialog] = useState<string>();

    const promiseGet = () => {
        if (providerId) {
            return getProviderSatContribution(tenantId, providerId, props.contributionId);
        }
        return getSatContribution(tenantId, props.contributionId);
    };

    const promiseGetUrl = (fileType: SatContributionFileType) => {
        if (providerId) {
            return getProviderSatContributionFileUrl(tenantId, providerId, props.contributionId, fileType);
        }
        return getSatContributionFileUrl(tenantId, props.contributionId, fileType);
    };

    const load = () => {
        setStatus("loading");
        promiseGet().then((satContrib) => {
            setSubtitle(getSatContributionPeriod(satContrib));
            setSatContribution(satContrib);
            setHasCaptureLine(satContrib.capture_line !== undefined && satContrib.capture_line !== "");
            setHasCaptureLineCompl(satContrib.complementary_capture_line !== undefined && satContrib.complementary_capture_line !== "");
            setDisabled(satContrib.status === "COMPLETED");

            Promise.all([
                promiseGetUrl("payment"),
                promiseGetUrl("bank_voucher"),
                promiseGetUrl("complementary_payment"),
                promiseGetUrl("complementary_bank_voucher"),
            ]).then(urls => {
                setPayment(satContrib.payment ? urls[0] : undefined);
                setComplementaryPayment(satContrib.complementary_payment ? urls[2] : undefined);
                setPaymentVoucher(satContrib.bank_voucher ? urls[1] : undefined);
                setComplementaryPaymentVoucher(satContrib.complementary_bank_voucher ? urls[3] : undefined);
                
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setStatus("loaded");
                setIsRejectedVoucher(satContrib !== undefined && satContrib.refuse_reason_bank_voucher !== undefined);
                setIsRejectedComplementaryVoucher(satContrib !== undefined && satContrib.complementary_refuse_reason_bank_voucher !== undefined);
            });
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, props.contributionId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const promiseUpdate = (request : SatContributionRequest) => {
        if (providerId) {
            return updateProviderSatContribution(tenantId, providerId, props.contributionId, request);
        }
        return updateSatContribution(tenantId, props.contributionId, request);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        promiseUpdate(request).then((satContribution) => {
            if(satContribution.type && satContribution.error_code){
                setOpenValidatePaymentDialog(translate("errors.codes."+satContribution.error_code) as string);
                return;
            }
            history.push("/contributions/sat");
        }).catch((error) => {
            if(request.complementary_bank_voucher){
                if (request.complementary_bank_voucher.endsWith(".pdf")) {
                    setOpenValidatePdfDialog(error.message);
                } else {
                    setOpenValidateDialog(true);
                }
            } else if (request.bank_voucher) {
                if (request.bank_voucher.endsWith(".pdf")) {
                    setOpenValidatePdfDialog(error.message);
                } else {
                    setOpenValidateDialog(true);
                }
            } else {
                setError(error.message);
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCancelValidatePayment = () => {
        setOpenValidatePaymentDialog(undefined);
    };

    const onAcceptValidatePayment = () => {
        setOpenValidatePaymentDialog(undefined);
        setSubmitting(true);

        if(request.complementary_payment && satContribution){
            props.onValidatePayment(request.complementary_payment, satContribution);
        }
    };

    const onCancelValidate = () => {
        setOpenValidateDialog(false);
    };

    const onCancelValidatePdf = () => {
        setOpenValidatePdfDialog(undefined);
    };

    const onAcceptValidate = () => {
        setOpenValidateDialog(false);
        setSubmitting(true);
        promiseUpdate({ ...request, "force_voucher": true }).then((satContribution) => {
            history.push("/contributions/sat");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });

    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("sat_contributions.edit")}
                        subtitle={subtitle}
                        icon={<SatTaxIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography><strong>{translate("sat_contributions.receipt_title")}</strong></Typography>
                                    <br/>
                                </Grid>
                                <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Box pl={2}>
                                                    <Typography variant="body2">{translate("sat_contributions.payment_short")}</Typography>
                                                </Box>
                                            </Grid> 
                                            <Grid item xs={6}>
                                                <IconButton aria-label="add" color="primary" size="small" href={payment || ""} target="_blank" rel="noopener noreferrer">
                                                    <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                                </IconButton>
                                            </Grid>
                                            {complementaryPayment && <>
                                                <Grid item xs={6}>
                                                    <Box pl={2}>
                                                        <Typography variant="body2">{translate("sat_contributions.complementary_payment")}</Typography>
                                                    </Box>
                                                </Grid> 
                                                <Grid item xs={6}>
                                                    <IconButton aria-label="add" color="primary" size="small" href={complementaryPayment} target="_blank" rel="noopener noreferrer">
                                                        <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                                    </IconButton>
                                                </Grid>
                                            </>}
                                            {!complementaryPayment && satContribution?.needs_complementary && (
                                                    <UploaderInput id="complementary_payment" name="complementary_payment"
                                                        value={request.complementary_payment}
                                                        label={translate("sat_contributions.complementary_payment") as string}
                                                        required={false} disabled={submitting || disabled}
                                                        margin={margin}
                                                        acceptExtension=".pdf" path={`tenants/${tenantId}/sat_complementary_payment`}
                                                        newFileName={satContribution.year+"-"+satContribution.month+"_SATDecProv_02"}
                                                        onValueChanged={hasChanged} />
                                            )}
                                        </Grid>
                                    <br/>
                                    <Divider />
                                    <br/>
                                </Grid>
                                {(hasCaptureLine || hasCaptureLineCompl) && 
                                    <Grid item xs={12}>
                                        <Typography><strong>{translate("sat_contributions.voucher_title")}</strong></Typography>
                                        <br/>
                                    </Grid>
                                }
                                {hasCaptureLine && <>
                                    {paymentVoucher && !isRejectedVoucher && <>
                                        <Grid item xs={6}>
                                            <Box pl={2}>
                                                <Typography variant="body2">{translate("sat_contributions.bank_voucher")}</Typography>
                                            </Box>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <IconButton aria-label="add" color="primary" size="small" href={paymentVoucher} target="_blank" rel="noopener noreferrer">
                                                <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                            </IconButton>
                                        </Grid>
                                    </>}
                                    {(!paymentVoucher || isRejectedVoucher ) && <>
                                        <UploaderInput id="bank_voucher" name="bank_voucher"
                                            value={request.bank_voucher}
                                            label={translate("sat_contributions.bank_voucher") as string}
                                            required={false} disabled={submitting || disabled || satContribution?.capture_line === undefined}
                                            margin={margin}
                                            acceptExtension=".pdf,.jpg,.png" path={`tenants/${tenantId}/sat_bank_voucher`}
                                            newFileName={satContribution?.year+"-"+satContribution?.month+"_SAT_CompBanco_DecProv_01"}
                                            onValueChanged={hasChanged} />
                                    </>}
                                </>}
                                {hasCaptureLineCompl  && <>
                                    {complementaryPaymentVoucher && !isRejectedComplementaryVoucher && <>
                                        <Grid item xs={6}>
                                            <Box pl={2}>
                                                <Typography variant="body2">{translate("sat_contributions.complementary_bank_voucher")}</Typography>
                                            </Box>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <IconButton aria-label="add" color="primary" size="small" href={complementaryPaymentVoucher} target="_blank" rel="noopener noreferrer">
                                                <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                            </IconButton>
                                        </Grid>
                                    </>}
                                    {((!complementaryPaymentVoucher && satContribution?.needs_complementary && satContribution?.complementary_capture_line && satContribution?.valid_complementary_payment) || ( satContribution?.valid_complementary_payment && isRejectedComplementaryVoucher))  && (
                                            <UploaderInput id="complementary_bank_voucher" name="complementary_bank_voucher"
                                                        value={request.complementary_bank_voucher }
                                                        label={translate("sat_contributions.complementary_bank_voucher") as string}
                                                        required={false} disabled={submitting || disabled}
                                                        margin={margin}
                                                        acceptExtension=".pdf,.jpg,.png" path={`tenants/${tenantId}/sat_complementary_bank_voucher`}
                                                        newFileName={satContribution?.year+"-"+satContribution?.month+"_SAT_CompBanco_DecProv_02"}
                                                        onValueChanged={hasChanged} />
                                    )}
                                </>}
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting || disabled}>
                                                    {translate("buttons.update")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
            {request && openValidateDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.title") as string}
                    message={translate("confirm_contribution.voucher.message") as string}
                    secondary={translate("confirm_contribution.voucher.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidate}
                    doAction={onAcceptValidate}
                />
            }
            {request && openValidatePdfDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.pdf.title") as string}
                    message={translate("confirm_contribution.voucher.pdf.message", { "message": openValidatePdfDialog}) as string}
                    secondary={translate("confirm_contribution.voucher.pdf.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidatePdf}
                    doAction={onAcceptValidate}
                />
            }
            {request && openValidatePaymentDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.payment.title") as string}
                    message={translate("confirm_contribution.payment.message", {"error": openValidatePaymentDialog}) as string}
                    secondary={translate("confirm_contribution.payment.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidatePayment}
                    doAction={onAcceptValidatePayment}
                />
            }
        </Grid>
    );
}