import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Box, Fab, Grid, IconButton, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SECfdiToSendIcon from "@material-ui/icons/SendTwoTone";
import SECfdiPendingDocsIcon from "@material-ui/icons/RestorePageTwoTone";
import SECfdiArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import GridDx, { NoFilterEditor } from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps, Filter } from "@devexpress/dx-react-grid";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Cfdi, Cfdis } from "../model/Cfdi";
import { CfdisQueryParams } from "../model/CfdiSe";
import { list, deleteCfdi, exportAllCfdisRange, exportCfdisExcel, exportCfdisSelected } from "../api/TenantSECfdiApi";
import { list as customerList } from "../api/CustomerSECfdiApi";
import { list as providerList, deleteCfdi as deleteProviderCfdi } from "../api/ProviderSECfdiApi";
import { AppContext } from "../context/AppContext";
import CfdiIssuedMenu from "./CfdiIssuedMenu";
import DialogPopup from "../components/DialogPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import PresentToAllIcon from '@material-ui/icons/PresentToAllTwoTone';
import FindInPageIcon from '@material-ui/icons/FindInPageTwoTone';
import CfdisIssuedArchiveMenu from "./CfdisIssuedArchiveMenu";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { TenantCfdisRequest, ExportCfdisResponse } from "../model/TenantCfdi";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";
import { RouterParams } from "../router/RouterParams";

type SECfdiStatus = "SE_PREPARING" | "SE_TO_SEND" | "SE_PENDING_DOCS" | "SE_VALIDATING_DOCS" | "SE_REJECTED_DOCS" | "SE_DONE";

interface CfdisIssuedViewProps {
    title: string;
    icon: ReactNode;
    statuses: SECfdiStatus[];
    withoutGrouping?: boolean;
    success?: string;
}

export function CfdisIssuedToSendView() {
    return (
        <CfdisIssuedView title="cfdis_se.to_send" statuses={["SE_TO_SEND", "SE_REJECTED_DOCS"]} icon={<SECfdiToSendIcon />} withoutGrouping={true}/>
    );
}

export function CfdisIssuedToPrepareAndSendView() {
    return (
        <CfdisIssuedView title="cfdis_se.to_prepare" statuses={["SE_PREPARING", "SE_TO_SEND"]} icon={<PresentToAllIcon />} />
    );
}

export function CfdisIssuedToPrepareView() {
    return (
        <CfdisIssuedView title="cfdis_se.to_prepare" statuses={["SE_PREPARING"]} icon={<PresentToAllIcon />} withoutGrouping={true}/>
    );
}

export function CfdisIssuedPendingDocsView({ match }: RouterParams) {
    return (
        <CfdisIssuedView title="cfdis_se.pending_docs" statuses={["SE_PENDING_DOCS", "SE_REJECTED_DOCS"]} icon={<SECfdiPendingDocsIcon />} withoutGrouping={true} success={match.params.success}/>
    );
}

export function CfdisIssuedValidatingView() {
    return (
        <CfdisIssuedView title="cfdis_se.validating_docs" statuses={["SE_VALIDATING_DOCS"]} icon={<FindInPageIcon />} withoutGrouping={true} />
    );
}

export function CfdisIssuedArchivedView() {
    return (
        <CfdisIssuedView title="cfdis_se.archive" statuses={[]} icon={<SECfdiArchiveIcon />} />
    );
}

export function CfdisIssuedArchivedCustomerView() {
    return (
        <CfdisIssuedView title="cfdis_se.archive" statuses={["SE_PENDING_DOCS", "SE_VALIDATING_DOCS", "SE_REJECTED_DOCS", "SE_DONE"]} icon={<SECfdiArchiveIcon />} />
    );
}

export function CfdisIssuedArchivedTenantView() {
    return (
        <CfdisIssuedView title="cfdis_se.archive" statuses={["SE_PENDING_DOCS", "SE_VALIDATING_DOCS", "SE_REJECTED_DOCS", "SE_DONE"]} icon={<SECfdiArchiveIcon />} />
    );
}

function CfdisIssuedView(props: CfdisIssuedViewProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const customerId = context.session?.customer?.id;
    const providerId = context.session?.provider?.id;
    const showProvider = context.isGrantedAny(["CustomerCfdisIssuedRead", "TenantCfdisIssuedRead"])
    const variableColumn = showProvider ? "provider" : "customer";
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [dialog, setDialog] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [defaultExpandedGroups] = useState<string[]>(() => {
        return props.statuses.map(el => translate(`cfdis_se.status.${el}`) as string);
    });
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedCfdis, setSelectedCfdis] = useState<(number | string)[]>([]);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);

    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            identifier: query.get("identifier") || "",
            receptor: query.get("receptor") || "",
            customer: query.get("customer") || "",
            provider: query.get("provider") || "",
            date: query.get("date") || "",
            from: query.get("from") || "",
            to: query.get("to") || "",
            due_date_complete: query.get("due_date_complete") || "",
        } as CfdisQueryParams;
    };

    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const paramsToFilters = (paramsExt?: CfdisQueryParams) => {
        const temp = paramsExt ? paramsExt : workingParams;
        return [
            { columnName: "identifier", value: temp.identifier },
            { columnName: "receptor", value: temp.receptor },
            { columnName: "customer", value: temp.customer },
            { columnName: "provider", value: temp.provider },
            { columnName: "date", value: temp.date },
            { columnName: "from", value: temp.from },
            { columnName: "to", value: temp.to },
            { columnName: "due_date_complete", value: temp.due_date_complete }
        ] as Filter[];
    };

    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const promiseGet = () => {
        const params = {
            statuses: props.statuses,
            identifier: workingParams?.identifier,
            receptor: workingParams?.receptor,
            customer: workingParams?.customer,
            provider: workingParams?.provider,
            date: workingParams?.date,
            from: workingParams?.from,
            to: workingParams?.to,
            due_date_complete: workingParams?.due_date_complete,
        } as CfdisQueryParams;

        const offset = pageSize * page;
        if (customerId) {
            return customerList(tenantId, customerId, pageSize, offset, params)
        }
        if (providerId) {
            return providerList(tenantId, providerId, pageSize, offset, params)
        }
        return list(tenantId, pageSize, offset, params);
    };

    const load = () => {
        if(props.success){
            setSuccess(props.success);
        }
        promiseGet().then(response => {
            setData(response);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, props.statuses, page, pageSize, workingParams, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = workingParams;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.identifier = filter.value;
                        break;
                    case "receptor":
                        temp.receptor = filter.value;
                        break;
                    case "customer":
                        temp.customer = filter.value;
                        break;
                    case "provider":
                        temp.provider = filter.value;
                        break;
                    case "date":
                        const dates = filter.value.split(" - ");
                        if (dates.length === 2) {
                            temp.date = filter.value;
                        } else {
                            temp.date = "";
                        }
                        break;
                    case "from":
                        const from = filter.value.split(" - ");
                        if (from.length === 2) {
                            temp.from = filter.value;
                        } else {
                            temp.from = "";
                        }
                        break;
                    case "to":
                        const to = filter.value.split(" - ");
                        if (to.length === 2) {
                            temp.to = filter.value;
                        } else {
                            temp.to = "";
                        }
                        break;
                    case "due_date_complete":
                        const due_date_complete = filter.value.split(" - ");
                        if (due_date_complete.length === 2) {
                            temp.due_date_complete = filter.value;
                        } else {
                            temp.due_date_complete = "";
                        }
                        break;
                    default:
                }
            }
        });

        setWorkingParams(temp);

        if (temp.identifier) {
            query.set("identifier", temp.identifier);
        } else {
            query.delete("identifier");
        }
        if (temp.receptor) {
            query.set("receptor", temp.receptor);
        } else {
            query.delete("receptor");
        }
        if (temp.customer) {
            query.set("customer", temp.customer);
        } else {
            query.delete("customer");
        }
        if (temp.provider) {
            query.set("provider", temp.provider);
        } else {
            query.delete("provider");
        }
        if (temp.date) {
            query.set("date", temp.date);
        } else {
            query.delete("date");
        }
        if (temp.from) {
            query.set("from", temp.from);
        } else {
            query.delete("from");
        }
        if (temp.to) {
            query.set("to", temp.to);
        } else {
            query.delete("to");
        }
        if (temp.due_date_complete) {
            query.set("due_date_complete", temp.due_date_complete);
        } else {
            query.delete("due_date_complete");
        }

        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPage(0);
        setPageSize(pageSize);
    };

    const getStatus = (cfdi: Cfdi) => {
        return translate(`cfdis_se.status.${cfdi.metadata.status}`) as string;
    };

    const [columns] = useState([
        {
            name: "identifier",
            title: translate("cfdis.columns.serie_folio") as string
        },
        {
            name: "receptor",
            title: translate(`cfdis_se.receptor`) as string,
            getCellValue: (row: any) => row.metadata.company_name
        },
        {
            name: variableColumn,
            title: translate(`cfdis_se.${variableColumn}`) as string,
            getCellValue: (row: any) => showProvider ? row.cfdi.emisor.nombre : row.cfdi.receptor.nombre
        },
        {
            name: "status",
            title: translate("cfdis_se.status.title") as string,
            getCellValue: (row: any) => getStatus(row)
        }, 
        {
            name: "due_date_complete",
            title: translate("cfdis_se.due_date_complete") as string,
            getCellValue: (row: any) => row.metadata.specialized_services?.due_date_complete
        },
        {
            name: "date",
            title: translate("cfdis.columns.emission_date_small") as string,
        },
        {
            name: "from",
            title: translate("contracts.from") as string,
            getCellValue: (row: any) => row.metadata.specialized_services?.from
        },
        {
            name: "to",
            title: translate("contracts.to") as string,
            getCellValue: (row: any) => row.metadata.specialized_services?.to
        },
        {
            name: "total",
            title: translate("cfdis.columns.amounts_small") as string,
        },
        {
            name: "actions",
            title: " ",
            getCellValue: (row: any, columnName: string) => (
                <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(row)}>
                    <MoreVertIcon />
                </IconButton>
            )
        }
    ]);

    const [columnsFormat] = useState([
        { columnName: "identifier", wordWrapEnabled: true, width: 200 },
        { columnName: "receptor", wordWrapEnabled: true, width: showProvider ? 200 : 0},
        { columnName: variableColumn, wordWrapEnabled: 150 },
        { columnName: "status", wordWrapEnabled: true, width: props.statuses.length > 1 ? 100 : 0 },
        { columnName: "overdue", align: "center", width: 20 },
        { columnName: "due_date_complete", align: "center", wordWrapEnabled: true, width: 150 },
        { columnName: "date", align: "center", wordWrapEnabled: true, width: 150 },
        { columnName: "from", align: "center", wordWrapEnabled: true, width: 150 },
        { columnName: "to", align: "center", wordWrapEnabled: true, width: 150 },
        { columnName: "total", align: "right", wordWrapEnabled: true, width: 200 },
        { columnName: "actions", align: "right", width: 50 },
    ]);

    const [filtersExceptions] = useState(() => {
        const defaultColumns = ["total", "status", "actions", "overdue"];
        if (providerId || customerId) {
            defaultColumns.push(variableColumn);
        }
        return defaultColumns;
    });

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const [customPlugins] = useState([
        <NormalTypeProvider for={filtersExceptions} editorComponent={NoFilterEditor} />,
    ]);

    const [clickRowColumns] = useState(["identifier"]);
    const [leftColumns] = useState(["identifier", variableColumn, showProvider ? "receptor" : undefined]);
    const [rightColumns] = useState(["total", "actions"]);
    const [dateColumns] = useState(["date", "from", "to", "due_date_complete"]);
    const [textColumns] = useState(["identifier", variableColumn, "receptor"]);
    const [currencyColumns] = useState(["total"]);
    const [grouping] = useState([{ columnName: "status" }]);

    const onClickedRow = (cfdi: Cfdi) => {
        if (cfdi.metadata.status === "SE_PREPARING") {
            history.push(`/cfdis/se/${cfdi.id}/prepare`);
        } else {
            history.push(`/cfdis/se/${cfdi.id}/details`);
        }
    };

    const promiseDelete = (cfdiId: string) => {
        if (providerId) {
            return deleteProviderCfdi(tenantId, providerId, cfdiId);
        }
        return deleteCfdi(tenantId, cfdiId);
    };

    const onDelete = () => {
        setDialog("delete");
        setAnchorEl(null);
    };

    const confirmDelete = () => {
        setDialog(undefined);

        if (!cfdi) return;

        setSubmitting(true);
        promiseDelete(cfdi.id).then(() => {
            load();
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setCfdi(undefined);
        });
    };

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onDownloadCfdisSelected = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setStatus("loading");
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selectedCfdis } as TenantCfdisRequest).then((response) => {
                setStatus("loaded");
                if (response.url) {
                    window.open(response.url, "_blank")
                } else {
                    if (response.total > 0) {
                        setSuccess(translate("cfdis.email_export") as string);
                    } else {
                        setWarning(translate("cfdis.empty_export") as string);
                    }
                }
            }).catch((error) => {
                setStatus("loaded");
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    };
    const onOpenConfirmDialogDownloadAllCfdis = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialog(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    };

    const onOpenConfirmDialogExportExcel = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialogExportExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedCfdis(selected);
    };

    const onDownloadAllCfdis = () => {
        setStatus("loading");
        setOpenConfirmDialog(false);

        const params = {
            statuses: props.statuses,
            identifier: workingParams?.identifier,
            receptor: workingParams?.receptor,
            customer: workingParams?.customer,
            provider: workingParams?.provider,
            date: workingParams?.date,
            from: workingParams?.from,
            to: workingParams?.to,
        } as CfdisQueryParams;
        exportAllCfdisRange(context.session!.tenant!.id, params).then((response) => {
            setStatus("loaded");
            if (response.url) {
                setExportResult(response);
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setStatus("loaded");
            setError(error.message);
        });

    }

    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onDownloadSelectedToExcel = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            let n_params = workingParams;
            n_params.selected_ids = selectedCfdis.join(",");
            setOpenConfirmDialogExportExcel(true);
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onDownloadFiles = (paramsTemp: CfdisQueryParams) => {
        setStatus("loading");
        const params = {
            statuses: props.statuses,
            identifier: paramsTemp?.identifier,
            receptor: paramsTemp?.receptor,
            customer: paramsTemp?.customer,
            provider: paramsTemp?.provider,
            date: paramsTemp?.date,
            from: paramsTemp?.from,
            to: paramsTemp?.to,
            selected_ids: paramsTemp.selected_ids || undefined,
            connector_id: paramsTemp.connector_id || undefined
        } as CfdisQueryParams;
        exportCfdisExcel(context.session!.tenant!.id, params).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    const onCloseExporter = () => {
        setOpenConfirmDialogExportExcel(false);
    };

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setStatus("loaded");
        setOpenConfirmDialogExportExcel(false);
        workingParams.selected_ids = selectedCfdis.join(",");
        workingParams.connector_id = connectorId;
        onDownloadFiles(workingParams);
    }

    return (
        <Surface title={translate(props.title)} 
            subtitle={translate("cfdis_se.title")} 
            icon={props.icon} 
            titleActions={<Grid container alignItems="center" justify="flex-end" spacing={1}>
                {(context.isGrantedAny(["CfdisIssuedCreate", "ProviderCfdisIssuedCreate"]) && !context.isGranted("ProviderCFDIsRead")) ? (
                <Link to="/cfdis/se/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>
                ) : undefined}
                {props.title === "cfdis_se.archive" &&
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                }
            </Grid>}>
            <Grid container>
                <Grid item xs={12}>
                    <Box mx={-4}>
                        <GridDx
                            loading={status === "loading"}
                            rows={data?.items || []}
                            totalRows={data?.total || 0}
                            page={page}
                            pageSize={pageSize}
                            columns={columns}
                            columnsFormat={columnsFormat}
                            clickRowColumns={clickRowColumns}
                            leftColumns={leftColumns}
                            rightColumns={rightColumns}
                            amountCurrencyColumns={currencyColumns}
                            textColumns={textColumns}
                            dateColumns={dateColumns}
                            filters={filters}
                            grouping={props.withoutGrouping ? undefined : grouping}
                            onClickRow={onClickedRow}
                            defaultExpandedGroups={defaultExpandedGroups}
                            customFormatColumns={customPlugins}
                            setFiltersHandler={setFiltersHandler}
                            onChangedPage={onChangedPage}
                            onChangedPageSize={onChangedPageSize}
                            noUseId={false}
                            selectionIds={selectedCfdis}
                            setSelectionHandler={setSelectionHandler} />
                    </Box>
                </Grid>
            </Grid>
            {dialog === "delete" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.delete.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={confirmDelete} variant="outlined" color="secondary" disabled={submitting}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.delete.text")}
                </DialogPopup>
            )}
            {cfdi && anchorEl &&
                <CfdiIssuedMenu 
                    cfdi={cfdi} 
                    anchor={anchorEl} 
                    onDelete={onDelete} 
                    onClose={onCloseOption}
                    isProvider={providerId !== undefined}
                    inDetails={false}
                />
            }
            {gridAnchorEl && (
                <CfdisIssuedArchiveMenu anchor={gridAnchorEl}
                    onDownloadCfdisSelected={onDownloadCfdisSelected}
                    onDownloadCfdis={onOpenConfirmDialogDownloadAllCfdis}
                    onDownloadExcelSelected={onDownloadSelectedToExcel}
                    onDownloadExcel={onOpenConfirmDialogExportExcel}
                    onClose={() => setGridAnchorEl(null)}
                />
            )}
            {openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onDownloadAllCfdis}
                    onClose={onCloseConfirmDialog}
                    title={translate("cfdis.popup.confirm_dialog.title") as string}
                    message={translate("cfdis.popup.confirm_dialog.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog.button") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description_archive") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {openConfirmDialogExportExcel && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type={"DATA"}
                    onClose={onCloseExporter}
                    onExport={onDownloadWithConnector}
                    maxDaySelected={31} />
            )}
            
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} />
        </Surface>
    );
}