import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import { BudgetLine } from "../model/BudgetLinesTemplate";
import { WarningSnackbar } from "../components/Snackbars";

interface BudgetLinePopupProps {
    existing_ids: string[];
    popupMode: string;
    budgetLine?: BudgetLine;
    onSubmitPopup(value: BudgetLine): any;
    onCancel(): any;
}

export default function BudgetLinePopup(props: BudgetLinePopupProps) {
    const margin = "dense";
    const [request, setRequest] = useState<BudgetLine>(() => {
        return props.budgetLine ?? {
            external_id: '',
            external_type: '',
            description: '',
            ledger_account: ''
        } as BudgetLine;
    });
    const [validations, setValidations] = useState({} as any);
    const [warning, setWarning] = useState<string>();

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        if (props.popupMode === "add" && props.existing_ids.includes(request.external_id)) {
            setWarning(translate("budget_lines_templates.line_duplicated_msg") as string);
            return;
        }

        props.onSubmitPopup(request);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    return (
        <DialogPopup
            open
            title={translate(`budget_lines_templates.line_popup.${props.popupMode}`)}
            onClose={props.onCancel}
            closeColor="default"
            disableEscapeKeyDown
            disableBackdropClick
            closeText={translate("buttons.cancel") as string}
            onSubmit={onSubmit}
            button={
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {translate(`buttons.${props.popupMode}`)}
                </Button>
            }
            buttonLeft={
                <Button
                    variant="outlined"
                    color="secondary"
                    style={{ visibility: "hidden" }}
                >
                    {translate("buttons.update")}
                </Button>
            }
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="external_id" name="external_id"
                        value={request.external_id}
                        label={translate("budget_lines_templates.external_id") as string}
                        required
                        disabled={props.popupMode === "edit"}
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="external_type" name="external_type"
                        value={request.external_type}
                        label={translate("budget_lines_templates.external_type") as string}
                        required
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="description" name="description"
                        value={request.description}
                        label={translate("budget_lines_templates.description") as string}
                        required
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="ledger_account" name="ledger_account"
                        value={request.ledger_account}
                        label={translate("budget_lines_templates.ledger_account") as string}
                        required
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}