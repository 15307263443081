import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon, DeleteIcon } from "../components/Icons";
import ActivateIcon from '@material-ui/icons/ToggleOn';
import InativateIcon from '@material-ui/icons/ToggleOff';

import translate from "../i18n/Translator";
import { FocaltecAnnouncement } from "../model/FocaltecAnnouncement";
import { AppContext } from "../context/AppContext";

interface FocaltecAnnouncementMenuProps {
    focaltecAnnouncement: FocaltecAnnouncement;
    anchor: HTMLElement | null;
    onClose(): any;
    onUpdateStatus(status: string): any;
}

export default function FocaltecAnnouncementMenu(props: FocaltecAnnouncementMenuProps) {
    const context = useContext(AppContext);
    const [isUpdateGranted] = useState(context.isGranted("FocaltecAnnouncementsUpdate"));

    return (
        <Menu
            id="focaltec-announcements-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem button component={Link} to={`/focaltec-announcements/${props.focaltecAnnouncement.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            {isUpdateGranted && props.focaltecAnnouncement.status === "ACTIVE" && (
                <MenuItem button onClick={() => {props.onUpdateStatus("INACTIVE")}} dense>
                    <ListItemIcon>
                        <InativateIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.desactivate")}
                    </Typography>
                </MenuItem>
            )}
            {isUpdateGranted && props.focaltecAnnouncement.status === "INACTIVE" && (
                <MenuItem button onClick={() => {props.onUpdateStatus("ACTIVE")}} dense>
                    <ListItemIcon>
                        <ActivateIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.activate")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}