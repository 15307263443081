import React from "react";
import { CopyBlock } from 'react-code-blocks';

export default function MyCodeComponent(props) {
  return (
    <CopyBlock
      text={props.code}
      language={props.language}
      showLineNumbers={props.showLineNumbers}
      wrapLines
    />
  );
}
