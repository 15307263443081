import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { updateAdditionalInfoField } from "../api/RequisitionAPI";
import DatePicker from "../components/DatePicker";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import UploaderInput from "../components/UploaderInput";
import ValidatedInput from "../components/ValidatedInput";
import { GTZeroValidator } from "../components/Validators";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { QuotationRequest, Requisition, RequisitionRequest, PaymentPercentageRequest } from "../model/Requisition";
import Gridable from "../components/Gridable";
import { PaymentCondition } from "../model/TenantConfiguration";
import NumberFormat from "react-number-format";
import PercentagesPopup from "./PercentagesPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import { AdditionalInfoField, AdditionalFieldRequest } from "../model/AdditionalInfoWorkflove";
import AdditionalFieldsSection from "../components/AdditionalFieldsSection";
import InputsHeader from "./RequisitionInputsHeader";
import { HeaderRequisitionFormProps } from "./HeaderRequisitionForm";

interface DetailsRequisitionFormProps extends HeaderRequisitionFormProps {
    paymentConditionIds: string[];
    paymentConditions: PaymentCondition[];
    paymentConditionLabels: string[];
    onUpdateAdditionalInfo(requisition: Requisition): any;
    onValidQuotation(request: RequisitionRequest): any;
}

export default function DetailsRequisitionForm(props: DetailsRequisitionFormProps) {
    const context = useContext(AppContext);
    const gTZeroValidator = new GTZeroValidator();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [blockSafe, setBlockSafe] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [request, setRequest] = useState<RequisitionRequest>({} as RequisitionRequest);
    const [quotation, setQuotation] = useState<QuotationRequest>({
        number: "",
        date: new Date(),
        amount: 0,
        due_date: new Date(),
        file_url: "",
        payment_condition_id: "",
        payments_percentage: []
    } as QuotationRequest);
    const [installmentRequests, setInstallmentRequests] = useState<PaymentPercentageRequest[]>([]);
    const [openPercentages, setOpenPercentages] = useState<boolean>(false);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");

    useEffect(() => {
        setRequest({
            provider_id: props.requisition.provider_id,
            requisition_type_id: props.requisition.requisition_type_id,
            description: props.requisition.description,
            in_project: props.requisition.in_project,
            project_id: props.requisition.project_id,
            currency: props.requisition.currency
        } as RequisitionRequest);
        if (props.requisition.quotation) {
            setQuotation({
                number: props.requisition.quotation.number || "",
                date: props.requisition.quotation.date || new Date(),
                amount: props.requisition.quotation.amount || 0,
                due_date: props.requisition.quotation.due_date || new Date(),
                file_url: props.requisition.quotation.file_url || "",
                payment_condition_id: props.requisition.quotation.payment_condition_id || ""
            } as QuotationRequest);
            if (props.requisition.quotation.payments_percentage) {
                setInstallmentRequests(props.requisition.quotation.payments_percentage.map(pp => {
                    return {
                        payment_number: pp.payment_number,
                        percentage: pp.percentage,
                        payment_conditions: pp.payment_conditions
                    } as PaymentPercentageRequest
                }));
            }
        }
        setStatus("loaded");
        setLoading(false);
    }, [props]);

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const hasChanged = (name: string, value: string) => {
        const fixedValue = value === "---" ? "" : value;
        setRequest({ ...request, [name]: fixedValue });
    };

    const onCurrencyChanged = (value: string, inputRef?: any) => {
        setRequest({ ...request, currency: value });
    };

    const hasChangedQuotation = (name: string, value: string) => {
        const fixedValue = value === "---" ? "" : value;

        if (name === "payment_condition_id" && fixedValue !== "") {
            let temp = props.paymentConditions.find(pc => pc.id === fixedValue) || {} as PaymentCondition;
            let requestTemp = temp.installments.map(i => {
                return {
                    payment_number: i.payment_number,
                    percentage: i.percentage,
                    amount: i.percentage / 100 * quotation.amount,
                    payment_conditions: ""
                } as PaymentPercentageRequest;
            });
            if (!blockSafe) {
                setInstallmentRequests(requestTemp);
            } else {
                setBlockSafe(false)
            }

        }
        setQuotation({ ...quotation, [name]: fixedValue });
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setQuotation({ ...quotation, [name]: date })
        }
    };

    const onSwitchInProject = () => {
        setRequest({ ...request, in_project: !request.in_project });
    };

    const onSave = () => {
        setSubmitting(true);
        let temp = { ...request };
        let tempQ = { ...quotation };
        tempQ.payments_percentage = installmentRequests;
        temp.quotation = tempQ;

        if (temp.quotation && props.requisition.project_amount < temp.quotation.amount) {
            setWarning(translate("requisitions.form.warning_amounts",
                {
                    "projectName": props.requisition.project_name,
                    "projectAmount": props.requisition.project_amount.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2 })
                }) as string);
            setSubmitting(false);
            return;
        }

        let validHeaders = props.onValidHeaders(temp, props.isBudgetControl);
        let validQuotation = props.onValidQuotation(temp);
        if (validHeaders && validHeaders !== "") {
            setWarning(translate(validHeaders) as string);
            setSubmitting(false);
            return;
        }

        if (validQuotation && validQuotation !== "") {
            setWarning(translate(validQuotation) as string);
            setSubmitting(false);
            return;
        }

        props.onSave(temp);
    };

    const onOpenEditPercentages = () => {
        setOpenPercentages(true);
    };

    const hasChangedInstallmentCondition = (name: string, value: string) => {
        if (!installmentRequests) return;
        let tempPP = installmentRequests;
        tempPP.filter(ir => ir.payment_number === name).forEach(ir =>
            ir.payment_conditions = value
        );
        setInstallmentRequests(tempPP);
    };

    const onSaveField = (field: AdditionalInfoField) => {
        if (!field) return;
        setOpenBackdrop(true);
        updateAdditionalInfoField(context.session!.tenant!.id, props.requisition.id, {
            id: field.id,
            value: field.value,
            type: field.type.id,
        } as AdditionalFieldRequest).then(requisition => {
            props.onUpdateAdditionalInfo(requisition);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    };

    const onSavePercentages = (installmentRequests: PaymentPercentageRequest[]) => {
        setLoading(true);
        setInstallmentRequests(installmentRequests);
        setOpenPercentages(false);
        setLoading(false);
    };

    if (status === "loading") {
        return <Progress />;
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Surface title={translate(props.isEdit ? "requisitions.form.edit_requisition" : "requisitions.form.new_requisition")} icon={<WorkIcon />}
                        className="FormSurface">
                        <InputsHeader
                            submitting={submitting}
                            request={request}
                            types={props.types}
                            typeLabels={props.typeLabels}
                            projects={props.projects}
                            projectSelected={props.projectSelected}
                            providers={props.providers}
                            providerSelected={props.providerSelected}
                            hasChanged={hasChanged}
                            currencies={props.currencies}
                            companies={props.companies}
                            isBudgetControl={props.isBudgetControl}
                            hasChangedCurrency={onCurrencyChanged}
                            onSwitchInProject={onSwitchInProject}
                            xs={6}
                            setError={(error) => setError(error)}
                            view="detail"
                        />
                        <br /> <Divider /> <br />
                        {props.requisition.requisition_type_agreement === "QUOTATION" && <Grid container justify="space-between" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Typography><strong>{translate("requisitions.form.quotation.title")}</strong></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="number" name="number"
                                    value={quotation.number} label={translate("requisitions.form.quotation.number") as string}
                                    required={true} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChangedQuotation} />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker label={translate("requisitions.form.quotation.date") as string}
                                    name="date"
                                    initial={quotation.date ? moment(quotation.date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}
                                    onChange={onChangedDate}
                                    defaultIfMissing={false} clearable={false} autoOK disablePast={false} disableFuture={false} disabled={submitting} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="number" id="amount" name="amount"
                                    value={quotation.amount + ""}
                                    label={translate("requisitions.form.quotation.amount") as string}
                                    required
                                    margin="dense"
                                    disabled={submitting}
                                    validators={[gTZeroValidator]}
                                    onValueChanged={hasChangedQuotation} />
                            </Grid>
                            <Grid item xs={6}>
                                <UploaderInput
                                    id="file_url"
                                    name="file_url"
                                    value={quotation.file_url}
                                    label={translate("requisitions.form.quotation.file_url") as string}
                                    required
                                    disabled={submitting}
                                    margin="dense"
                                    acceptExtension=".pdf,.doc,.docx"
                                    onValueChanged={hasChangedQuotation}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker label={translate("requisitions.form.quotation.due_date") as string}
                                    name="due_date"
                                    initial={quotation.due_date ? moment(quotation.due_date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}
                                    onChange={onChangedDate}
                                    defaultIfMissing={false} clearable={false} autoOK disablePast={false} disableFuture={false} disabled={submitting} />
                            </Grid>
                            <Grid item xs={6} >
                                <ValidatedInput type="text" id="payment_condition_id" name="payment_condition_id"
                                    value={quotation.payment_condition_id}
                                    label={translate("requisitions.form.quotation.payment_condition_id") as string}
                                    required
                                    margin="dense"
                                    disabled={submitting || quotation.amount <= 0}
                                    options={props.paymentConditionIds} optionLabels={props.paymentConditionLabels}
                                    onValueChanged={hasChangedQuotation} />
                            </Grid>
                            <Grid item xs={12}>
                                <Gridable
                                    items={installmentRequests}
                                    loading={loading}
                                    empty={translate("requisitions.form.quotation.payment_condition.empty") as string}
                                    columns={[
                                        {
                                            title: translate("requisitions.form.quotation.payment_condition.number") as string,
                                            converter: (installment) => installment.payment_number,
                                            xs: 2
                                        },
                                        {
                                            title: translate("requisitions.form.quotation.payment_condition.percentage") as string,
                                            converter: (installment) => (
                                                <Button aria-label="add" color="primary" size="small" onClick={onOpenEditPercentages} >
                                                    <NumberFormat value={installment.percentage} suffix="%" displayType="text" />
                                                </Button>
                                            ),
                                            xs: 2
                                        },
                                        {
                                            title: translate("requisitions.form.quotation.payment_condition.amount") as string,
                                            converter: (installment) => (
                                                <NumberFormat value={quotation.amount * installment.percentage / 100} prefix="$" decimalScale={2} displayType="text" thousandSeparator="," fixedDecimalScale={true} />
                                            ),
                                            xs: 2
                                        },
                                        {
                                            title: translate("requisitions.form.quotation.payment_condition.condition") as string,
                                            converter: (installment) =>
                                                <Grid container style={{ minWidth: (window.screen.width / 2) - 250 + "px" }}>
                                                    <Grid item xs={12}>
                                                        <ValidatedInput maxLength={1000} type="text" id="condition" name={installment.payment_number}
                                                            value={installment.payment_conditions} label={""}
                                                            required={false} disabled={submitting}
                                                            margin="dense"
                                                            onValueChanged={hasChangedInstallmentCondition} />
                                                    </Grid>
                                                </Grid>,
                                            xs: 6
                                        },
                                    ]} />
                            </Grid>
                        </Grid>}
                        <br /> <Divider /> <br />
                        {props.requisition && props.requisition.fields_response && (
                            <AdditionalFieldsSection title={translate("requisitions.form.title_additional_info") as string} fields={props.requisition.fields_response} onSaveField={onSaveField} />
                        )}

                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                    <Grid item xs={12} md="auto">
                                        <Button type="button" variant="contained" color="primary" size="large" disabled={submitting} onClick={onSave}>
                                            {translate(props.isEdit ? "buttons.update" : "buttons.add")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md="auto">
                                        <Button variant="text" color="primary" size="large" disabled={submitting} onClick={props.onBack}>
                                            {translate("buttons.cancel")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {openPercentages && installmentRequests && <PercentagesPopup
                installmentRequests={installmentRequests}
                amountTotal={quotation.amount + ""}
                onSave={onSavePercentages}
                onClose={() => {
                    setOpenPercentages(false);
                    setLoading(false);
                }}
            />}
        </Grid>
    );
}
