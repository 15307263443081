import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, Box, Divider, Tooltip, } from '@material-ui/core';
import translate from "../../i18n/Translator";
import { Cfdi } from '../../model/Cfdi';
import { AdvancePayments, AdvancePayment } from '../../model/AdvancePayment';
import GridDx, { NoFilterEditor } from '../../components/GridDx';
import { initialPageZero, initialPageSize } from '../../components/Pagination';
import { DataTypeProviderProps, DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import { ErrorSnackbar, WarningSnackbar } from "../../components/Snackbars";
import { getAdvancePaymensPaid } from "../../api/TenantCfdiApi";

interface AssociateAdvancePaymentsProps {
    data: Cfdi;
    onClose(): any;
    onAccept(item: AdvancePayment[]): void;
}

export function AssociateAdvancePayments(props: AssociateAdvancePaymentsProps) {

    const [advancePayments, setAdvancePayments] = useState<AdvancePayments>();
    const [selectedCfdis, setSelectedCfdis] = useState<(number | string)[]>([]);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectds, setSelectds] = useState<AdvancePayment[]>([]);
    const [warning, setWarning] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>();

    const onInit = () => {
        getAdvancePaymensPaid(props.data.metadata.tenant_id, props.data.metadata.provider_id, props.data.currency, props.data.metadata.company_id, pageSize, pageSize * page,).then((data) => {
            setAdvancePayments(data);
        }).catch((e) => {
            setError(e.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(onInit, [props, page, pageSize]);

    const onAccept = () => {
        if (getResult() < 0) {
            setWarning(translate("add_advance_payments.warnig_message") as string);
            return;
        }
        //TODO invoke associate cfdi
        props.onAccept(selectds ?? []);
    }

    const columns = [
        {
            name: 'identifier',
            title: translate('add_advance_payments.columns.identifier') as string,
            getCellValue: (item: any) => item.identifier
        },
        {
            name: 'company_name',
            title: translate('add_advance_payments.columns.provider') as string,
            getCellValue: (item: any) => item?.isser?.name ?? ""
        },
        {
            name: 'description',
            title: translate("add_advance_payments.columns.description") as string,
            getCellValue: (item: any) => item.description
        },
        {
            name: 'date',
            title: translate('add_advance_payments.columns.date') as string,
            getCellValue: (item: any) => item.date
        },
        {
            name: 'total',
            title: translate("add_advance_payments.columns.amount") as string,
            getCellValue: (item: any) => item.total
        },
        {
            name: 'currency',
            title: translate('add_advance_payments.columns.currency') as string,
            getCellValue: (item: any) => item.currency
        },
        {
            name: 'payment_amount',
            title: translate("add_advance_payments.columns.paid") as string,
            getCellValue: (item: any) => item.payment_amount
        },
        {
            name: 'payment_currency',
            title: translate('add_advance_payments.columns.payment_currency') as string,
            getCellValue: (item: any) => item.payment_currency
        },
    ];

    const [columnsFormat] = useState([
        { columnName: 'identifier', wordWrapEnabled: true, width: 150 },
        { columnName: 'company_name', wordWrapEnabled: true, width: 150 },
        { columnName: 'description' },
        { columnName: 'date', wordWrapEnabled: true, width: 130 },
        { columnName: 'total', align: 'right', wordWrapEnabled: true, width: 100 },
        { columnName: 'currency', align: 'left', wordWrapEnabled: true, width: 100 },
        { columnName: 'payment_amount', align: 'right', wordWrapEnabled: true, width: 110 },
        { columnName: 'payment_currency', wordWrapEnabled: true, width: 100 },
    ]);

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedCfdis(selected);
        let selectds: AdvancePayment[] = [];
        selected.forEach(el => {
            const item = (advancePayments?.items ?? []).find(item => item.id === el);
            if (item) {
                selectds.push(item);
            }
        });
        setSelectds(selectds);

    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPluginsFiltersExlude = [
        <NormalTypeProvider for={['identifier', 'company_name', 'description', 'date', 'total', 'currency', 'payment_currency', 'payment_amount']} editorComponent={NoFilterEditor} />
    ];

    const getTotaSumAdavancePayments = () => {
        const currency = props.data.currency;
        return selectds?.map(el => {
            if (currency === el.payment_currency) {
                return el.payment_amount
            }
            if (currency === el.currency) {
                return el.total
            }
            return 0.0;
        }).reduce((a, b) => a + b, 0) ?? 0.0;
    }

    const getResult = () => {
        return props.data.metadata.balance - getTotaSumAdavancePayments();
    }
    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >

            <DialogTitle id="alert-dialog-title" disableTypography>
                <Typography variant="subtitle1">
                    {translate("add_advance_payments.title") as string}
                </Typography>
            </DialogTitle>

            <DialogContent style={{ padding: '0px' }} >
                <Box my={2} px={4} >
                    <Typography variant="body1" color="textSecondary" align="left" >
                        {translate("add_advance_payments.header")}
                    </Typography>
                    <Typography variant="body1" align="left">
                        {translate("add_advance_payments.sub_header")}
                    </Typography>
                </Box>
                <div style={{ marginLeft: '-12px', marginRight: '-12px' }}>
                    <GridDx
                        loading={loading}
                        rows={advancePayments?.items ?? []}
                        page={page}
                        pageSize={pageSize}
                        totalRows={advancePayments ? advancePayments.total : 0}
                        columns={columns}
                        lineIdentifier={"id"}
                        columnsFormat={columnsFormat}
                        selectionIds={selectedCfdis}
                        clickRowColumns={[]}
                        onClickRow={() => { }}
                        setSelectionHandler={setSelectionHandler}
                        defaultExpandedGroups={[]}
                        amountCurrencyColumns={['payment_amount', 'total']}
                        dateColumns={['date']}
                        leftColumns={[]}
                        rightColumns={[]}
                        textColumns={['identifier', 'company_name', 'description', 'currency', 'payment_currency']}
                        customFormatColumns={customPluginsFiltersExlude}
                        onChangedPage={onChangedPage}
                        onChangedPageSize={onChangedPageSize}
                    />
                </div>

            </DialogContent>

            <DialogActions>
                <Grid container spacing={2} justify="flex-end" style={{ paddingRight: '1rem' }}>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>

                    <Grid item xs={10} style={{ padding: '0px' }}>
                        <Box mr={5}>
                            <Typography color="primary" variant="subtitle1" align="right" >
                                {translate("add_advance_payments.sum_of_selected") as string}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item style={{ padding: '0px' }} >
                        <Typography color="primary" variant="subtitle1" >
                            <NumberFormat value={getTotaSumAdavancePayments()}
                                prefix="$ " suffix={' ' + (props?.data?.currency ?? 'MXN')} decimalScale={2} fixedDecimalScale={true}
                                thousandSeparator="," displayType="text"
                            />
                        </Typography>
                    </Grid>


                    <Grid item xs={10} style={{ padding: '0px' }}>
                        <Box mr={5}>
                            <Typography color="textSecondary" variant="subtitle1" align="right" >
                                {translate("add_advance_payments.cfdi_balance") as string}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item style={{ padding: '0px' }} >
                        <Typography color="textSecondary" variant="subtitle1" >
                            <NumberFormat value={props?.data?.metadata?.balance ?? 0.0}
                                prefix="$ " suffix={' ' + (props?.data?.currency ?? 'MXN')} decimalScale={2} fixedDecimalScale={true}
                                thousandSeparator="," displayType="text"
                            />
                        </Typography>
                    </Grid>


                    <Grid item xs={10} style={{ padding: '0px' }}>
                        <Box mr={5}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" >
                                <Grid item xs >
                                    <Typography color="secondary" variant="subtitle1" align="right" >
                                        {translate("add_advance_payments.resulting_balance") as string}
                                    </Typography>          </Grid>
                                {(getResult() < 0)
                                    && (<Grid item >
                                        <Tooltip title={translate("add_advance_payments.warnig_message") as string} placement="left-end">
                                            <WarningIcon style={{ "color": "#f5812f" }} />
                                        </Tooltip>
                                    </Grid>)
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item style={{ padding: '0px' }} >
                        <Typography color="secondary" variant="subtitle1" style={{ padding: '0px' }} align="right">
                            <NumberFormat value={(props.data.metadata.balance - getTotaSumAdavancePayments())}
                                prefix="$ " suffix={' ' + (props?.data?.currency ?? 'MXN')} decimalScale={2} fixedDecimalScale={true}
                                thousandSeparator="," displayType="text"
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Divider style={{ margin: '10px' }} />
                    </Grid>

                    <Grid item xs={12} style={{ padding: '10px', flex: 'inherit' }}>
                        <Button onClick={props.onClose} variant="text" color="primary" size="medium" >
                            {translate("buttons.ommit")}
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button onClick={onAccept} variant="contained" color="primary" size="medium" >
                            {translate("buttons.add")}
                        </Button>
                    </Grid>

                </Grid>

                <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
                <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            </DialogActions>
        </Dialog >
    );
}
