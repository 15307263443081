import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { getLogoUrl } from "../api/TenantAPI";

interface ExternSurfaceProps {
    title: string;
    description?: string;
    children: React.ReactNode;
    showTitle?: boolean;
    sm?: true | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: true | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    lg?: true | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xl?: true | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export default function ExternSurface(props: ExternSurfaceProps) {

    const [logo, setLogo] = useState<string | undefined>(undefined);
    const hostname = window.location.hostname;

    useEffect(() => {
        let subdomain = hostname.split(".")[0];
        if ('app' === subdomain || 'app-dev' === subdomain || 'app-stg' === subdomain || 'app-sandbox' === subdomain || 'localhost' === subdomain) {
            return;
        }
        getLogoUrl(subdomain).then((response) => {
            setLogo(response.content);
        }).catch((error) => {
            setLogo(undefined);
        });
    }, [hostname]);

    return (
        <Grid item xs={12} sm={props.sm || 10} md={props.md || 6} lg={props.lg || 4} xl={props.xl || 3}>
            <Box my={4}>
                <Grid container justify="center" alignItems="center">

                    <Grid item xs={12}>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            {logo && (
                                <Grid item xs={12} sm={6}>
                                    <Grid container justify="center" alignItems="center">
                                        <img src={logo} alt="logo" className="ExternLogo" />
                                    </Grid>
                                </Grid> )
                            }
                            <Grid item xs={12} sm={logo ? 6 : 12}>
                                <Grid container justify="center" alignItems="center">
                                    <img src="/logo_small.png" alt="logo" className="ExternLogo" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12}>
                                {props.showTitle && props.title && (
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" align="center">
                                            {props.title}
                                        </Typography>
                                    </Box>
                                )}
                                {props.description && (
                                    <Typography component="p" variant="body1" align="center">
                                        {props.description}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11} lg={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}