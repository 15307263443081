import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Button, Typography, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/BorderColor";
import Progress from "../components/Progress";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import Gridable from "../components/Gridable";

import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { SpecificReceptionRuleType, SpecificReceptionRule, SpecificReceptionRuleCreateRequest } from "../model/SpecificReceptionRule";
import { createSpecificRule } from "../api/SpecificReceptionRuleAPI";

export interface SpecificReceptionRuleFormProps {
    name: string;
    specifiReceptionRule: SpecificReceptionRule;
    onClose(to : string, success: string): any;
    onAction(specificReceptionRule: SpecificReceptionRule, specificReceptionRuleType: SpecificReceptionRuleType, name: string): any;
}

export default function SpecificReceptionRuleForm(props: SpecificReceptionRuleFormProps) {
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);

    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();

    const [ruleNameValue, setRuleNameValue] = useState<string>("");
    const [request, setRequest] = useState<string>("");
    const [data, setData] = useState<SpecificReceptionRule>({} as SpecificReceptionRule);

    const load = () => {
        setStatus("loading");
        setData(props.specifiReceptionRule);
        setRuleNameValue(props.name);
        setStatus("loaded");
    };

    useEffect(load, [context.session, props]);

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {

    }

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
        setSuccess(undefined);
    };

    const onOpenPaymentDialog = (specificReceptionRuleType : SpecificReceptionRuleType) => {
        props.onAction(props.specifiReceptionRule, specificReceptionRuleType, request);
    }

    const onCreate = () => {
        if (request && request !== "") {
            createSpecificRule(context.session!.tenant!.id, { name: request, specific_reception_rules_types: data.specific_reception_rules_types, specific_rule_id: data.id } as SpecificReceptionRuleCreateRequest).then((response) => {
                props.onClose("principal", translate("specific_reception_rule.success_save") as string);
            }).catch((error) => {
                setError(error);
            });
        }else{
            setWarning(translate("specific_reception_rule.name_required") as string);
        }
    }

    const onClose = () => {
        props.onClose("principal", "");
    }

    const isNoRestrictions = (rule: SpecificReceptionRuleType): boolean => {
        return rule.use_all_uses 
            && rule.use_all_service_products 
            && (rule.sat_cfdi_type !== 'I' || 
                (rule.payments 
                    && Object.keys(rule.payments).length === 2 
                    && (rule.payments as any)['PPD']?.length > 0 
                    && (rule.payments as any)['PUE']?.length === 21));
    }

    return (
        <Surface backButton onAction={onClose} title={translate("specific_reception_rule.title") as string} subtitle={translate("specific_reception_rule.subtitle") as string}
            icon={<PlaylistAddCheckIcon />} className="PaperPagination PaperTabContainer" >
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={11} md={11} lg={11} xl={11}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <Box mt={4} mb={1} >
                                        <Typography variant="subtitle1">{translate(`specific_reception_rule.general.label_name`)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={ruleNameValue} label={translate("specific_reception_rule.general.input_name") as string}
                                        required={true}
                                        margin={"dense"}
                                        onValueChanged={hasChanged} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Box mt={4} mb={1} >
                                <Grid>
                                    <Gridable
                                        items={data ? data.specific_reception_rules_types : []}
                                        loading={status === "loading"}
                                        error={status !== "loading" && status !== "loaded" ? status : undefined}
                                        empty={translate("shipping_templates.empty") as string}
                                        columns={[
                                            {
                                                title: translate("reception_rule.key") as string,
                                                converter: (ruleType) => ruleType.sat_cfdi_type,
                                                xs: true,
                                                md: 2,
                                                lg: 1
                                            },
                                            {
                                                title: translate("reception_rule.description") as string,
                                                converter: (ruleType) => translate("reception_rule.voucher_type." + ruleType.sat_cfdi_type) as string,
                                                md: false,
                                                lg: 2,
                                            },
                                            {
                                                title: translate("specific_reception_rule.columns.configuration.title") as string,
                                                converter: (ruleType) => isNoRestrictions(ruleType) ?
                                                    <Grid>
                                                        <Typography style={{ "opacity": "30%" }} variant="inherit">
                                                            {translate("specific_reception_rule.columns.configuration.without")}
                                                        </Typography>
                                                    </Grid>
                                                    : translate("specific_reception_rule.columns.configuration.rules_applied"),
                                                fullWidth: true,
                                                md: false,
                                                lg: 2,
                                            },
                                            {
                                                title: translate("specific_reception_rule.service_product.title") as string,
                                                converter: (ruleType) =>
                                                    <Grid>
                                                        <Typography style={isNoRestrictions(ruleType) ? { "opacity": "30%" } : {}} variant="inherit">
                                                            {translate("specific_reception_rule.service_product.description", { "total": ruleType.service_products && ruleType.service_products.length > 0 ? ruleType.service_products.length : translate("specific_reception_rule.every") })}
                                                        </Typography>
                                                    </Grid>,
                                                xs: 8,
                                                sm: 3,
                                                lg: 2,
                                            },
                                            {
                                                title: translate("reception_rule.use_cfdi.title") as string,
                                                converter: (ruleType) =>
                                                    <Grid>
                                                        <Typography style={isNoRestrictions(ruleType) ? { "opacity": "30%" } : {}} variant="inherit">
                                                            {translate("reception_rule.use_cfdi.description_specific", { "total": ruleType.uses && ruleType.uses.length > 0 ? ruleType.uses.length : translate("specific_reception_rule.every") })}
                                                        </Typography>
                                                    </Grid>,
                                                xs: false,
                                                sm: 3,
                                                md: 2,
                                            },
                                            {
                                                title: translate("reception_rule.payments_method.title") as string,
                                                converter: (ruleType) =>
                                                    <Grid>
                                                        <Typography style={isNoRestrictions(ruleType) ? { "opacity": "30%" } : {}} variant="inherit">
                                                            { ruleType.sat_cfdi_type === "I" ? translate("reception_rule.payments_method.description", { "total": ruleType.payments ? Object.keys(ruleType.payments).length : 0 }) : "---"}
                                                        </Typography>
                                                    </Grid>,
                                                xs: false,
                                                sm: 3,
                                                lg: 2,
                                            },
                                            {
                                                title: (
                                                    <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                ),
                                                converter: (ruleType) => (
                                                    <IconButton aria-label="options" color="default" size="small" onClick={(event: React.MouseEvent<HTMLElement>) =>onOpenPaymentDialog(ruleType)}>
                                                        <EditIcon color="primary" />
                                                    </IconButton>
                                                ),
                                                justify: "flex-end",
                                                xs: "auto"
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {context.isGrantedAny(["SpecificReceptionRuleUpdate", "SpecificReceptionRuleCreate"]) && (
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item xs="auto">
                            <Box mr={3} mt={3}>
                                <Button size="medium" color="primary" onClick={onClose}>
                                    {translate(`specific_reception_rule.cancel_button`)}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs="auto">
                            <Box mr={3} mt={3}>
                                <Button size="medium" variant="outlined" color="primary" onClick={onCreate}>
                                    {translate(`reception_rule.add_button`)}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );
}