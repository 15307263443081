import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography, Button } from "@material-ui/core";
import Gridable from "../components/Gridable";
import { ServiceProduct } from "../model/ServiceProduct";
import translate from "../i18n/Translator";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import { RetentionRule } from "../model/ReceptionRule"; 
import { TaxRegimen } from "../model/TaxRegimen";

interface ReceptionRuleRetentionRulesProps {
    retentionsRulesDefault: RetentionRule[];
    serviceProducts: ServiceProduct[];
    receptionRules: RetentionRule[];
    taxRegimens: TaxRegimen[];
    onClickRowEdit(retention?: RetentionRule): any;
    onCreateRetention(event: React.MouseEvent<HTMLElement>): any; 
    onClickedOptions(item: RetentionRule, anchorEl: null | HTMLElement): any;
}

export default function ReceptionRuleRetentionRules(props: ReceptionRuleRetentionRulesProps) {
    const [rules, setRules] = useState<RetentionRule[]>([]);

    useEffect(() => {
        let retentions = props.receptionRules || [];
        setRules(retentions);
        // eslint-disable-next-line
    }, [props]);

    const getTaxDescription = (retentionRule: RetentionRule) => {
        if (!props.taxRegimens) {
            return "";
        }
        let taxRegimen = props.taxRegimens.find(item => item.id === retentionRule.tax_regimen_id);
        return taxRegimen ? taxRegimen.description : "---";
    }

   
    const selectCreateRetention = (event: React.MouseEvent<HTMLElement>) => {
        props.onCreateRetention(event);
    }

    const onClickedOptions = (item: RetentionRule, event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if(!item){
            return;
        }
        props.onClickedOptions(item, event.currentTarget);
    };

    const onClickRow = (item: RetentionRule) => {
        props.onClickRowEdit(item);
    };

    return (
        <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Box px={2} mt={4}>
                                        <Typography variant="body1" paragraph>
                                        <strong>{translate("reception_rule.retention_validation.subtitle")}</strong>
                                        </Typography>
                                    </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box px={2} mt={4}>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Button aria-label="add" variant="text" color="secondary" size="small" startIcon={<AddIcon />} onClick={selectCreateRetention}>
                                                {translate("reception_rule.retention_validation.add_retention")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                        <Grid item xs={12}>
                            <div style={{}}>
                                <Gridable
                                    items={rules || []}
                                    loading={false}
                                    onClick={onClickRow}
                                    empty={translate("specific_reception_rule.service_product.empty") as string}
                                    columns={[
                                        {
                                            title: translate("reception_rule.retention_validation.tax_regimen") as string,
                                            converter: (retention) => getTaxDescription(retention),
                                            xs: 4,
                                        },
                                        {
                                            title: translate("reception_rule.retention_validation.name") as string,
                                            converter: (retention) => retention.name,
                                            xs: 3,
                                        },
                                        {
                                            title: translate("reception_rule.retention_validation.applied") as string,
                                            converter: (retention) => translate("reception_rule.retention_validation." + (retention.apply_all_product_services === true ? "yes" : "no")) as string,
                                            xs: 3,
                                        },
                                        {
                                            title: translate("reception_rule.retention_validation.status") as string,
                                            converter: (retention) => translate("reception_rule.retention_validation." + (retention.active === true ? "active" : "inactive")) as string,
                                            xs: true
                                        },
                                        {
                                            title: "",
                                            converter: (rule) => (
                                                <IconButton aria-label="options" color="default" size="small" onClick={(event: React.MouseEvent<HTMLElement>) => onClickedOptions(rule, event)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            ),
                                            justify: "flex-end",
                                            xs: true,
                                            sm: true,
                                            md: true,
                                            lg: true,
                                            xl: true
                                        }
                                    ]}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    );

}