import React, { useState, useEffect, useContext } from "react";
import { DueInvoices } from "../components/Icons";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { Redirect, useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Grid, Fab, Icon, Button, Divider } from "@material-ui/core";
import queryString from "query-string";
import { getTenantCfdis, exportCfdisExcelDateRange } from "../api/TenantCfdiApi";
import Progress from "../components/Progress";
import { ExportProviders } from "../model/Provider";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { Filter, TableSelection } from "@devexpress/dx-react-grid";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import DateRange from "../components/DateRange";
import { CfdisQueryParams, Cfdis, Cfdi, StatusesCfdi } from "../model/Cfdi";
import { formatDateToString, stringToDate, stringToDateEnd } from "../components/DateFormat";
import Semaphore from "../components/Semaphore";
import { getTranslateStatus } from "../cfdi/CfdiTranslateStatusUtils";

export default function ReportDueInvoices() {
  const context = useContext(AppContext);
  const history = useHistory();
  const [status, setStatus] = useState<string>("loading");
  const [report, setReport] = useState<Cfdis>();
  const [exportResult, setExportResult] = useState<ExportProviders>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const qs = queryString.parse(window.location.search);
  const [redirect, setRedirect] = useState<string>();
  const sDate = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
  const paramsFromQueryString = (): CfdisQueryParams => {
    return {
      "status_list": typeof qs["status_list"] === "string" ? qs["status_list"] as string : 'PENDING_PAYMENT,PAYMENT_REJECTED,PAYMENT_POSTPONE,IN_PAYMENT_ORDER,SEND_TO_EXECUTE',
      "status_groups": "IN_PROCESS,VALIDATION",
      "start_date": typeof qs["start_date"] === "string" ? stringToDate(qs["start_date"]) : sDate,
      "end_date": typeof qs["end_date"] === "string" ? stringToDateEnd(qs["end_date"]) : new Date(),
      "is_due": true,
      "referer_view": "DUE_INVOICES_REPORT",
      "archive": true,
    } as CfdisQueryParams;
  };
  const [queryParam, setQueryParam] = useState<string>();
  const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
  const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);
  const paramsToFilters = (): Filter[] => {
    return [

    ] as Filter[];
  };
  const [filters, setFilters] = useState<Filter[]>(paramsToFilters);
  const [statusesCfdi, setStatusesCfdi] = useState<StatusesCfdi>();

  const load = () => {
    setStatus("loading");
    pushHistory();
    getTenantCfdis(context.session!.tenant!.id, "", 0, 0, params).then(response => {
      if (response && response.items)
        setReport(response);
    }).catch(error => {
      setStatus(error.message);
    }).finally(() => {
      setStatus("loaded");
    });
  }

  useEffect(load, [context.session, params]);

  useEffect(() => {
    const items = localStorage.getItem("cfdi_status");
    if (items) {
      setStatusesCfdi(JSON.parse(items));
    } else {
      setStatusesCfdi({ items: [], total: 0 } as StatusesCfdi);
    }
  }, [context.session]);

  const onExport = () => {
    let paramsExport = params;
    paramsExport.search = "";
    exportCfdisExcelDateRange(context.session!.tenant!.id, paramsExport).then(response => {
      if (response.url) {
        setExportResult(response);
      } else {
        setSuccess(translate("cfdis.email_export") as string);
      }
    }).catch(error => {
      setError(error.message);
    });
  };

  const setFiltersHandler = (filters: Filter[]) => {
    setFilters(filters);
    setParamsFromfilters(filters);
  };

  const setParamsFromfilters = (filters: Filter[]) => {
    let temp = params;
    filters.forEach(filter => {
      if (filter.value !== undefined) {
        switch (filter.columnName) {
          default: break;
        }
      }
    });
    setWorkingParams(temp);
  };

  const onCloseSnackbars = () => {
    setError(undefined);
    setSuccess(undefined);
  };

  const onCloseDownloadProviders = () => {
    setExportResult(undefined);
  }

  const tableColumnExtensions = [
    { columnName: 'folio', wordWrapEnabled: true, width: 100, align: 'left' },
    { columnName: 'company', wordWrapEnabled: true, width: 150, align: 'left' },
    { columnName: 'provider', wordWrapEnabled: true, width: 150, align: 'left' },
    { columnName: 'description', width: 180, align: 'left' },
    { columnName: 'balance', wordWrapEnabled: true, width: 110, align: 'right' },
    { columnName: 'currency', wordWrapEnabled: true, width: 70, align: 'left' },
    { columnName: 'stage', wordWrapEnabled: true, width: 80 },
    { columnName: 'emisor', wordWrapEnabled: true, width: 120 },
    { columnName: 'receiver', wordWrapEnabled: true, width: 120 },
    { columnName: 'authorization_date', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'authorizer', wordWrapEnabled: true, width: 120 },
    { columnName: 'schedule_date', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'deadline', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'due', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'amount', align: 'right', wordWrapEnabled: true, width: 110 },
    { columnName: 'total_payed', align: 'right', wordWrapEnabled: true, width: 110 },
    { columnName: 'last_date_payment', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'status', wordWrapEnabled: true, width: 100, align: 'center' },
  ] as any;

  const currencyColumns = ['amount', 'balance', 'total_payed'];

  const dateColumns = ['authorization_date', 'last_date_payment', 'deadline', 'due'];

  const filtersExclude = ['due', 'folio', 'company', 'provider', 'description', 'balance', 'currency', 'stage', 'emisor', 'receiver', 'authorization_date', 'authorizer', 'schedule_date', 'deadline', 'amount', 'total_payed', 'last_date_payment', 'status'];

  const textColumns = ['company', 'provider', 'description', 'currency', 'emisor', 'receiver', 'authorizer', 'status'];

  const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'folio', 'company', 'provider']) as any;

  const clickRowColumns = ['folio'];

  const NormalTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider {...props} />);

  const customPlugins = [<NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />];

  const onClickedRow = (cfdi: Cfdi) => {
    pushHistory();
    setRedirect(`/cfdis/${cfdi.id}/details?referer=report-due&${queryParam}`);
  };

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  const onChangeDateRange = (startDate: Date, endDate: Date) => {
    setWorkingParams({ ...workingParams, start_date: startDate, end_date: endDate });
  };

  const onAppliedFilter = () => {
    pushHistory();
    setParams(workingParams);
  };

  const pushHistory = () => {
    let qs = queryString.parse(window.location.search);
    qs["start_date"] = workingParams.start_date ? String(workingParams.start_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.start_date.getDate()).padStart(2, '0') + "-" + workingParams.start_date.getFullYear() : "";
    qs["end_date"] = workingParams.end_date ? String(workingParams.end_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.end_date.getDate()).padStart(2, '0') + "-" + workingParams.end_date.getFullYear() : "";
    qs["is_due"] = "true";
    qs["archive"] = "true";

    let url = window.location.pathname + "?" + queryString.stringify(qs);
    setQueryParam(queryString.stringify(qs));
    history.push(url);
  };

  const getStatus = (cfdi: Cfdi) => {
    if (!("REFUND" === cfdi.type)) {
      return getTranslateStatus(cfdi, statusesCfdi);
    }

    if (cfdi.metadata.custom_status) {
      return cfdi.metadata.custom_status;
    }

    return translate(`cfdis.history.status.${cfdi.metadata.status}`) as string;
  };

  if (status === "loading") {
    return (<Progress />);
  }

  return (
    <Surface
      title={translate("reports.due_invoices.title") as string}
      subtitle={translate("reports.due_invoices.subtitle") as string}
      icon={<DueInvoices />}
      className="PaperPagination" titleActions={
        <Grid container alignItems="center" justify="flex-end" spacing={1}>
          {context.isGrantedAny(["DueInvoicesReportExport"]) && report && report.items.length > 0 &&
            <Grid item xs="auto">
              <Fab color="primary" size="small" title={translate("reports.due_invoices.export") as string} onClick={onExport} >
                <Icon className="fas fa-file-excel" fontSize="small" />
              </Fab>
            </Grid>}
        </Grid>
      }>

      <form autoComplete="off" noValidate >
        <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
            <DateRange
              startDate={workingParams.start_date}
              endDate={workingParams.end_date}
              onChange={onChangeDateRange} />
          </Grid>
          <Grid item xs="auto">
            <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
              {translate("buttons.search")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Divider />
      <div className="focaltec-small">
        <GridDx
          columns={[
            {
              name: 'folio',
              title: translate('reports.due_invoices.columns.folio') as string,
              getCellValue: (row: any) => row.identifier
            },
            {
              name: 'company',
              title: translate('reports.due_invoices.columns.company') as string,
              getCellValue: (row: any) => row.metadata.company_name
            },
            {
              name: 'provider',
              title: translate('reports.due_invoices.columns.provider') as string,
              getCellValue: (row: any) => row.issuer.name
            },
            {
              name: 'description',
              title: translate('reports.due_invoices.columns.description') as string
            },
            {
              name: 'balance',
              title: translate('reports.due_invoices.columns.balance') as string,
              getCellValue: (row: any) => row.metadata.balance
            },
            {
              name: 'currency',
              title: translate("reports.due_invoices.columns.currency") as string
            },
            {
              name: 'stage',
              title: translate("reports.due_invoices.columns.stage") as string
            },
            {
              name: 'emisor',
              title: translate("reports.due_invoices.columns.emisor") as string,
              getCellValue: (row: any) => row.cfdi?.emisor.nombre
            },
            {
              name: 'receiver',
              title: translate("reports.due_invoices.columns.receiver") as string,
              getCellValue: (row: any) => row.cfdi?.receptor.nombre
            },
            {
              name: 'authorization_date',
              title: translate("reports.due_invoices.columns.authorization_date") as string,
              getCellValue: (row: any) => formatDateToString(row.metadata.authorized_at)
            },
            {
              name: 'authorizer',
              title: translate("reports.due_invoices.columns.authorizer") as string,
              getCellValue: (row: any) => row.metadata.authorizer_name
            },
            {
              name: 'schedule_date',
              title: translate("reports.due_invoices.columns.schedule_date") as string,
              getCellValue: (row: any) => formatDateToString(row.metadata.payment_info?.payment_schedule)
            },
            {
              name: 'deadline',
              title: translate("reports.due_invoices.columns.deadline") as string,
              getCellValue: (row: any) => formatDateToString(row.metadata.payment_info?.payment_deadline)
            },
            {
              name: 'due',
              title: translate("reports.due_invoices.columns.due") as string,
              getCellValue: (row: any) => formatDateToString(row.metadata.payment_info?.payment_due)
            },
            {
              name: 'amount',
              title: translate("reports.due_invoices.columns.amount") as string,
              getCellValue: (row: any) => row.total
            },
            {
              name: 'total_payed',
              title: translate("reports.due_invoices.columns.total_payed") as string,
              getCellValue: (row: any) => row.total - row.metadata.balance
            },
            {
              name: 'last_date_payment',
              title: translate("reports.due_invoices.columns.last_date_payment") as string,
              getCellValue: (row: any) => row.last_payment
            },
            {
              name: 'status',
              title: translate("reports.due_invoices.columns.status") as string,
              getCellValue: (row: any) => getStatus(row)
            }
          ]}
          loading={status === "loading"}
          rows={report ? report.items.map(item => {
            return {
              ...item,
              stage:
                <Semaphore cfdi={item} />
            };
          }) : []}
          amountCurrencyColumns={currencyColumns}
          dateColumns={dateColumns}
          columnsFormat={tableColumnExtensions}
          textColumns={textColumns}
          clickRowColumns={clickRowColumns}
          filters={filters}
          setFiltersHandler={setFiltersHandler}
          onClickRow={onClickedRow}
          leftColumns={leftColumns}
          customFormatColumns={customPlugins}
        />
      </div>
      {exportResult && exportResult.url && (
        <DownloadExportationPopup
          title={translate("providers.pending_payments.export.title") as string}
          message={translate("providers.pending_payments.export.message", { "total": exportResult.total }) as string}
          url={exportResult.url}
          onClose={onCloseDownloadProviders} />
      )}
      <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </Surface>
  );
}
