import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import VisibilityIcon from "@material-ui/icons/VisibilityTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";

import translate from "../i18n/Translator";
import { KeyPair } from "../model/KeyPair";
import { AppContext } from "../context/AppContext";

interface KeyPairMenuProps {
    keyPair: KeyPair;
    anchor: HTMLElement | null;
    onClose(): any;
    onDetails(): any;
    onDelete(): any;
}

export default function KeyPairMenu(props: KeyPairMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("KeyPairsUpdate") &&
                <MenuItem button component={Link} to={`/keypairs/${props.keyPair.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGranted("KeyPairsRead") &&
                <MenuItem button onClick={props.onDetails} dense>
                    <ListItemIcon>
                        <VisibilityIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see")}</Typography>
                </MenuItem>
            }
            {context.isGranted("KeyPairsDelete") &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}