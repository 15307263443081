import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Box, Button } from "@material-ui/core";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import CustomBackdrop from "../components/CustomBackdrop";
import { SuccessSnackbar, WarningSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { createPayroll } from "../api/PayrollAPI";
import { createPayroll as createProviderPayroll } from "../api/ProviderPayrollAPI";
import { PayrollRequest } from "../model/Payroll";
import { AppContext } from "../context/AppContext";
import { CfdisValidationsPopup, CfdiValidationPopupValues } from "../cfdis/popups/CfdiValidationsPopup";

export default function PayrollUploader() {
    const context = useContext(AppContext);
    const history = useHistory();
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;

    const [submitting, setSubmitting] = useState(false);
    const [xmlFile, setXml] = useState<File>();
    const [xmlLabel, setXmlLabel] = useState<string>();
    const [results, setResults] = useState<CfdiValidationPopupValues[]>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();

    const onDropXmlZone = (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
            const xml = acceptedFiles[0];
            setXml(xml);
            setXmlLabel(`${xml.name} - ${xml.size} bytes`);
        } else {
            setError(translate("payrolls.invalid_file") as string);
        }
    };

    const submitPromise = () => {
        // createProviderPayroll
        const request = { file: xmlFile } as PayrollRequest;
        if (providerId) {
            return createProviderPayroll(tenantId, providerId, request);
        }
        return createPayroll(tenantId, request);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true);
        submitPromise().then((response) => {
            const errored = response.items.filter(cfdi => !cfdi.metadata.cfdi_valid || !cfdi.metadata.non_base_valid).map(cfdi => {
                return {
                    cfdiData: cfdi,
                    data: cfdi.metadata.validations,
                    cfdiPartiallyValid: !cfdi.metadata.non_base_valid
                } as CfdiValidationPopupValues;
            });

            if (errored.length) {
                // hay al menos uno inválido
                if (errored.length !== response.total) {
                    // hay al menos uno válido
                    setWarning(translate("payrolls.partial_success", { valid: (response.total - errored.length), amount: response.total }) as string);
                }
                setResults(errored);
            } else if (response.total === 1) {
                setSuccess(translate("payrolls.success_single", { name: xmlFile?.name }) as string);
            } else {
                setSuccess(translate("payrolls.success", { amount: response.total }) as string);
            }
            setXml(undefined);
            setXmlLabel(undefined);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseCfdiValidationsPopup = () => {
        setResults(undefined);
    }

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setWarning(undefined)
        setError(undefined);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("payrolls.new_file")} icon={<UploadIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center" spacing={3}>
                                <Grid item xs={12}>
                                    <Dropzone onDrop={onDropXmlZone} accept={[".xml", ".zip"]} disabled={submitting}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} style={{ height: 50, borderWidth: 1, borderColor: "lightgray", borderStyle: "dashed", paddingLeft: 10 }}>
                                                <input {...getInputProps()} />
                                                <Box pt={1.5}>
                                                    {xmlLabel || translate("payrolls.upload")}
                                                </Box>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} sm="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate("buttons.upload")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        {results && (
                            <CfdisValidationsPopup items={results} onFailureActor="receptor" onClose={onCloseCfdiValidationsPopup} />
                        )}
                        <CustomBackdrop open={submitting} message={translate("payrolls.processing") as string} />
                        <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );

}