import React from "react";
import { ListItemIcon, ListItemSecondaryAction, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelTwoTone"; // Failed
import ActiveIcon from "@material-ui/icons/CheckCircleTwoTone";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DynamicFeedTwoToneIcon from '@material-ui/icons/DynamicFeedTwoTone';
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { ImportPaymentSummary } from "../model/Payment";
import DialogPopup from "../components/DialogPopup";

interface ImportResumePopupProps {
    summaryImport: ImportPaymentSummary;
    onClose(): any;
}

export default function ImportPaymentResumePopup(props: ImportResumePopupProps) {
    let values = {
        "created": props.summaryImport.created,
        "duplicated": props.summaryImport.duplicated,
        "fail": props.summaryImport.failed,
    } as any;

    let listado = {
        "created" : props.summaryImport.added,
        "duplicated": props.summaryImport.doubles,
        "fail" : props.summaryImport.rejected 
    }as any;

    return (
        <DialogPopup title={translate("providers.pending_payments.import.import_payment")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            
                {Object.keys(values).map((status: string) => (
                    <StatusListItem key={status} status={status} value={values[status] as number} listado={listado[status] as string[]} />
                ))}
            
        </DialogPopup>
    );
}

interface StatusListItemProps {
    status: string;
    value: number;
    fontSize?: "small" | "large";
    listado: string[];
}

function StatusListItem(props: StatusListItemProps) {
    var icon;
    switch (props.status) {
        case "created":
            icon = <ActiveIcon fontSize={props.fontSize || "default"}  style={{ "color": "#82CA9D" }} />
            break;
        case "duplicated":
            icon = <DynamicFeedTwoToneIcon fontSize={props.fontSize || "default"} color="secondary"/>
            break;
        case "fail":
            icon = <CancelIcon fontSize={props.fontSize || "default"} style={{ "color": "#C33149" }} />
            break;
        default:
            icon = <CancelIcon fontSize={props.fontSize || "default"} />
            break;
    }

    if (props.status === "exceeded" && props.value <= 0) {
        return <></>;
    }

    return (
        <List component="nav">
        <ListItem>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={translate(`providers.pending_payments.import.${props.status}`)} />
            <ListItemSecondaryAction>
                <Typography variant="h6">
                    <NumberFormat value={props.value} displayType="text" />
                </Typography>
                
            </ListItemSecondaryAction>
                      
        </ListItem>
        {props.listado &&
            <ListItem>{translate(`providers.pending_payments.import.${props.status}_clearing_doc`)} </ListItem>
        }
        {props.listado &&
            props.listado.map((str) => <ListItem><FiberManualRecordIcon style={{ fontSize: 7 }}/> {str}</ListItem>)}
        </List>
    );
}