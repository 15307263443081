import { callAPI, HTTPMethod } from './API';
import { NotificationGroupsQueryParams, NotificationGroups, NotificationGroup, NotificationGroupRequest } from "../model/NotificationGroup";

const basePath = (tenantId: string) => `/tenants/${tenantId}/notifications-groups`;

export async function notificationGroupList(tenantId: string, page: number, offset: number, params: NotificationGroupsQueryParams): Promise<NotificationGroups> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createNotificationGroup(tenantId: string, request: NotificationGroupRequest): Promise<NotificationGroup> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function updateNotificationGroup(tenantId: string, notificationGroupId: string, request: NotificationGroupRequest): Promise<NotificationGroup> {
    return await callAPI(`${basePath(tenantId)}/${notificationGroupId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getNotificationGroup(tenantId: string, notificationGroupId: string):Promise<NotificationGroup>{
    return await callAPI(`${basePath(tenantId)}/${notificationGroupId}`, {
        method: HTTPMethod.GET
    });
}

export async function deleteNotificationGroup(tenantId: string, notificationGroupId: string):Promise<void> {
    return await callAPI(`${basePath(tenantId)}/${notificationGroupId}`, {
        method: HTTPMethod.DELETE
    });
}