import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, Select } from "@material-ui/core";
import translate from "../i18n/Translator";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Surface from "../components/Surface";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { 
    MEMBERSHIP_STATUSES, 
    ProviderMembershipTaxStamp, 
    ProviderMembershipTaxStampParams, 
    ProviderMembershipsTaxStamp } from "../model/ProviderMembershipTaxStamp";
import { DataTypeProvider, DataTypeProviderProps, Filter } from "@devexpress/dx-react-grid";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import queryString from "query-string";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import ProviderMembershipMenu from "./ProviderMembershipMenu";
import { list, approve, disapprove } from "../api/ProviderMembershipTaxStampApi";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { useHistory } from "react-router-dom";
import CustomBackdrop from "../components/CustomBackdrop";

export default function TaxStampMemberships() {
    const [status, setStatus] = useState<string>("loading");
    const history = useHistory();
    const [data, setData] = useState<ProviderMembershipsTaxStamp>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [membership, setMembership] = useState<ProviderMembershipTaxStamp>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openConfirmApprove, setOpenConfirmApprove] = useState<boolean>(false);
    const [openConfirmDisapprove, setOpenConfirmDisapprove] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const paramsFromQueryString = (): ProviderMembershipTaxStampParams => {
        return {
            "provider_name": typeof qs["provider_name"] === "string" ? qs["provider_name"] as string : "",
            "name": typeof qs["name"] === "string" ? qs["name"] as string : "",
            "reference": typeof qs["reference"] === "string" ? qs["reference"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "TO_VERIFY"
        } as ProviderMembershipTaxStampParams;
    };
    const [params, setParams] = useState<ProviderMembershipTaxStampParams>(paramsFromQueryString);

    const paramsToFilters = (): Filter[] => {
        return [
            { columnName: 'provider_name', value: params.provider_name },
            { columnName: 'name', value: params.name },
            { columnName: 'reference', value: params.reference },
            { columnName: 'status', value: params.status }
        ] as Filter[];
    };
    
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
        { columnName: 'contract_date', wordWrapEnabled: true },
        { columnName: 'provider_name', wordWrapEnabled: true},
        { columnName: 'reference', wordWrapEnabled: true },
        { columnName: 'name', wordWrapEnabled: true },
        { columnName: 'email', wordWrapEnabled: true },
        { columnName: 'phone', wordWrapEnabled: true },
        { columnName: 'plan', wordWrapEnabled: true },
        { columnName: 'voucher', wordWrapEnabled: true },
        { columnName: 'status', wordWrapEnabled: true },
        { columnName: 'menu' },
      ]) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();
        list(params).then(response => {
            setData(response);
            setStatus("loaded");
        }).catch( error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [page, pageSize, params, filters]);

    const getStatus = (membership: ProviderMembershipTaxStamp) => {
        return translate(`provider_membership_tax_stamp.status.${membership.status}`) as string;
    };

    const getPlan = (membership: ProviderMembershipTaxStamp) => {
        return membership.plan.name;
    };

    const columns = [
      {
          name: 'contract_date',
          title: translate('provider_membership_tax_stamp.columns.contract_date') as string
      },
      {
          name: 'provider_name',
          title: translate('provider_membership_tax_stamp.columns.provider_name') as string,
      },
      {
          name: 'reference',
          title: translate('provider_membership_tax_stamp.columns.reference') as string,
      },
      {
          name: 'name',
          title: translate('provider_membership_tax_stamp.columns.name') as string,
      },
      {
          name: 'email',
          title: translate('provider_membership_tax_stamp.columns.email') as string,
      },
      {
        name: 'phone',
        title: translate('provider_membership_tax_stamp.columns.phone') as string,
      },
      {
        name: 'plan',
        title: translate('provider_membership_tax_stamp.columns.plan') as string,
        getCellValue: (row: any) => getPlan(row)
      },
      {
        name: 'voucher',
        title: translate('provider_membership_tax_stamp.columns.voucher') as string,
      },
      {
          name: 'status',
          title: translate("provider_membership_tax_stamp.columns.status") as string,
          getCellValue: (row: any) => getStatus(row)
      },
      {
          name: 'menu',
          title: " "
      },
    ];

    

    const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
            <FormControl >
                <Select native style={{
                fontSize: "10px"
            }}
                    labelId="is_default"
                    id="is_default"
                    value={params.value || ''}
                    onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                    {MEMBERSHIP_STATUSES.map((value) => {
                        return <option key={value} value={value}>{translate(`provider_membership_tax_stamp.status.${value}`)}</option>
                    })}
                </Select>
            </FormControl>
            </Box>
        );
    };
    
    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );
    
    const ProvidersTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                <Button
                  variant="text"
                  color="primary"
                  type="button"
                  component="a" href={value.row.url_secure_voucher} target="_blank" rel="noopener noreferrer"
                >
                  {translate("plan_tax_stamp.buy_plan.view_voucher") as string}
                </Button>
            </>;
        }} {...props} />
    );

    const filtersExclude = ['contract_date', 'email', 'phone', 'plan'];

    const customPlugins = [
        <NormalTypeProvider for={['status']} editorComponent={StatusFilterEditor}/>,
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
        <ProvidersTypeProvider for={['voucher']} editorComponent={NoFilterEditor} />
    ];

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if(filter.value !== undefined){
                switch(filter.columnName){
                    case "provider_name": 
                        temp.provider_name = filter.value;
                        break;
                    case "name": 
                        temp.name = filter.value;
                        break;
                    case "reference": 
                        temp.reference = filter.value;
                        break;
                    case "status": 
                        temp.status = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const onClickedOptions = (membership: ProviderMembershipTaxStamp) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if(membership.status === "TO_VERIFY" || membership.status === "ACTIVE"){
            setAnchorEl(event.currentTarget);
            setMembership(membership);
        }
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setMembership(undefined);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["provider_name"] = params.provider_name || "";
        qs["status"] = params.status || "";
        qs["name"] = params.name || "";
        qs["reference"] = params.reference || "";
        qs["page"] = "0";
        
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onApprove = () => {
        setAnchorEl(null);
        setOpenConfirmApprove(true);
    };

    const onDisapprove = () => {
        setAnchorEl(null);
        setOpenConfirmDisapprove(true);
    };

    const onConfirmApprove = () => {
        if (!membership) return;
        setOpenBackdrop(true);
        setOpenConfirmApprove(false);
        approve(membership.id).then((_) => {
            setSuccess(translate("provider_membership_tax_stamp.success_approve") as string);
            load();
        }).catch( error => {
            setError(error.message);
        }).finally(() => {
            setMembership(undefined);
            setOpenBackdrop(false);
        });
    };

    const onCancelApprove = () => {
        setMembership(undefined);
        setOpenConfirmApprove(false);
    };

    const onConfirmDisapprove = () => {
        if (!membership) return;
        setOpenBackdrop(true);
        setOpenConfirmDisapprove(false);
        disapprove(membership.id).then((_) => {
            setSuccess(translate("provider_membership_tax_stamp.success_disapprove") as string);
            load();
        }).catch( error => {
            setError(error.message);
        }).finally(() => {
            setMembership(undefined);
            setOpenBackdrop(false);
        });
    };

    const onCancelDisapprove = () => {
        setMembership(undefined);
        setOpenConfirmApprove(false);
    };

    return (<>
    <Surface title={translate("provider_membership_tax_stamp.title") as string} 
            icon={<AddShoppingCartIcon />} 
            className="PaperPagination">
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns}
                columnsFormat={columnsFormat}
                dateColumns={['contract_date']}
                textColumns={["provider_name", "reference", "name", "email", "phone", "plan"]}
                filters={filters}
                customFormatColumns={customPlugins} 
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                onClickRow={() => {}}
                onClickedOptions={onClickedOptions}
                heightTablePX={400}
                noUseId={true}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {membership && anchorEl && (
                <ProviderMembershipMenu providerMembership={membership} anchor={anchorEl}
                    onClose={onCloseOption}
                    onApprove={onApprove}
                    onDisapprove={onDisapprove}
                />
            )}
            {membership && openConfirmApprove && (
                <ConfirmationPopup
                    title={translate("provider_membership_tax_stamp.confirm_approve.title") as string}
                    message={translate("provider_membership_tax_stamp.confirm_approve.message", 
                        { "plan": membership.plan.name, "nombre": membership.provider_name}) as string}
                    secondary={translate("provider_membership_tax_stamp.confirm_approve.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelApprove}
                    doAction={onConfirmApprove}
                />
            )}
            {membership && openConfirmDisapprove && (
                <ConfirmationPopup
                    title={translate("provider_membership_tax_stamp.confirm_disapprove.title") as string}
                    message={translate("provider_membership_tax_stamp.confirm_disapprove.message", 
                        { "plan": membership.plan.name, "nombre": membership.provider_name}) as string}
                    secondary={translate("provider_membership_tax_stamp.confirm_disapprove.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDisapprove}
                    doAction={onConfirmDisapprove}
                />
            )}
        </Surface>
    </>);
}