import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Grid, Button, Typography } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { list as listTeams } from "../api/TeamAPI";
import { Team, TeamListParams } from "../model/Team";
import BudgetLineLabels from "./BudgetLineLabels"
import { ErrorSnackbar } from "../components/Snackbars";
import { BudgetLine } from "../model/Budget";
import { GTZeroValidator, NumberValidator } from "../components/Validators";

export interface AutocompleteItem {
    title: string;
    value: string;
}

interface EditBudgetLinesProps {
    tenantId: string;
    budgetId: string;
    onExecute: boolean;
    line: BudgetLine;
    onClose(): any;
    onSubmit(line: BudgetLine): any;
}

export default function EditBudgetLines(props: EditBudgetLinesProps) {
    const [teams, setTeams] = useState<Team[]>([]);
    const [teamSelect, setTeamSelected] = useState<Team>({} as Team);
    const [onExecute, setOnExecute] = useState<boolean>(true);
    const [disableSave, setDisabledSave] = useState<boolean>(false)
    const [error, setError] = useState<string>();
    const initLine = {
        team_id: "",
        team_name: "",
        initial_amount: 0,
        variation_amount: 0,
        current_amount: 0,
        accrued_amount: 0,
        exercised_amount: 0,
        pending_accrue_amount: 0,
        pending_exercise_amount: 0,
        variation_status: "IN_BUDGET",
        external_id: "",
        external_type: "",
        description: "",
        ledger_account: ""
    } as BudgetLine;

    const [currentLine, setCurrentLine] = useState<BudgetLine>(initLine);
    const [validations, setValidations] = useState({
        "teams": {
            valid: false
        } as InputRef,
        "amount": {
            valid: false
        } as InputRef
    } as any);
    const numberValidator = new NumberValidator(true);
    const gtZeroValidator = new GTZeroValidator();

    useEffect(() => {
        if (!props.line) {
            return;
        }
        setCurrentLine({ ...props.line });
        if (props.line.team_id) {
            setTeamSelected({
                id: props.line.team_id,
                name: props.line.team_name
            } as Team);
        }
        listTeams(props.tenantId, 0, 0, {
            search: ""
        } as TeamListParams).then((response) => {
            setTeams(response.items);

        }).catch((error) => {

        });
        setOnExecute(props.onExecute);

    }, [props.tenantId, props.budgetId, props.line]);


    const onFilterChanged = (name: string, value: string, inputRef: InputRef) => {
        teams.forEach((t) => {
            if (t.id === value) {
                setTeamSelected(t);
                let line = currentLine;
                line.team_id = t.id;
                line.team_name = t.name;
                setCurrentLine(line);
            }
        });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChanged = (name: string, value: number, inputRef: InputRef) => {
        let line = currentLine;

        if (onExecute) {
            line.variation_amount = value;
            if (props.line?.team_id) {
                setDisabledSave(false);
            } else {
                setDisabledSave(!!line?.team_id && value === 0);
            }
        } else {
            line.initial_amount = value;
            setDisabledSave(value === 0);
        }
        setCurrentLine(line);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = () => {
        if (!isValid()) {
            return;
        }
        if (onExecute && currentLine.variation_amount < 0) {
            let max_reduction = (currentLine.current_amount - (currentLine.accrued_amount ?? 0));
            if (max_reduction < (currentLine.variation_amount * (-1))) {
                setError(translate("budget.lines.edit.error_max_reduction", { "max_reduction": max_reduction }) as string);
                return;
            }
        }
        props.onSubmit(currentLine);
    }

    const getValueEdit = () => {
        if (onExecute) {
            return "";
        }
        if (currentLine.initial_amount != undefined) {
            return currentLine.initial_amount.toString();
        }
        return "";
    }
    
    const onCancel = () => {
        props.onClose();
        setCurrentLine(initLine);
    }

    return (
        <DialogPopup
            open
            title={translate(`budget.lines.edit.${onExecute ? 'title_in_execution' : 'title_planing'}`) as string}
            fullWidth={true}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium"
                    disabled={disableSave}>
                    {translate("buttons.save")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="primary"
            onClose={onCancel}>
            <BudgetLineLabels currentLine={currentLine} onExecute={onExecute} />
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <ValidatedInput
                        type="text"
                        id={"teams"}
                        name={"teams"}
                        value={teams?.find(e => e.id === teamSelect.id)?.id}
                        label={translate("budget.lines.edit.responsible_team") as string}
                        required={true}
                        margin="dense"
                        onValueChanged={onFilterChanged}
                        emptyOption={translate("catalogs.values.empty") as string}
                        autocompleteOptions={teams ?? []}
                        autocompleteValue={teams.find(e => e.id === teamSelect.id) ?? undefined}
                        autocompleteKey={teams.find(e => e.id === teamSelect.id)?.id ?? undefined}
                        getId={(el) => el?.id}
                        getLabel={(el) => el?.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="number"
                        id={"amount"}
                        name={"amount"}
                        value={getValueEdit()}
                        validators={onExecute ? [] : [numberValidator, gtZeroValidator]}
                        label={translate(`budget.lines.edit.${onExecute ? "update_amount" : "inicial_amount"}`) as string}
                        required={true}
                        margin="dense"
                        onValueChanged={(name: string, value: string, inputRef: InputRef) => {
                            validations[name] = inputRef;
                            if (value) {
                                hasChanged(name, Number(value), inputRef);
                            }
                        }}
                    />
                {onExecute &&
                    <Typography color="textSecondary" variant="caption">
                        {translate("budget.lines.edit.note") as string}
                    </Typography>
                }
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );
}