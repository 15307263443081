import { callAPI, HTTPMethod } from "./API";
import { EmployeeVisits, EmployeeVisit, EmployeeVisitQueryParams, EmployeeVisitRequest, SendExecutionRequest } from "../model/EmployeeVisit";
import { ExportResponse } from "../model/Connector";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/employee-visits`;

export async function listEmployeeVisits(tenantId: string, providerId: string, page: number, offset: number, params: EmployeeVisitQueryParams): Promise<EmployeeVisits> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            statuses: params.statuses.join(","),
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createEmployeeVisit(tenantId: string, providerId: string, request: EmployeeVisitRequest): Promise<EmployeeVisit> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getEmployeeVisit(tenantId: string, providerId: string, id: string): Promise<EmployeeVisit> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateEmployeeVisit(tenantId: string, providerId: string, id: string, request: EmployeeVisitRequest): Promise<EmployeeVisit> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteEmployeeVisit(tenantId: string, providerId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function sendExecutionEmployeeVisit(tenantId: string, providerId: string, id: string, request: SendExecutionRequest): Promise<any> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}/execution`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getEmployeeVisitReport(tenantId: string, providerId: string, id: string): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}/export-pdf`, {
        method: HTTPMethod.GET
    });
}
