import { callAPI, HTTPMethod } from "./API";
import { Requisition, Requisitions, RequisitionsQueryFilter, RequisitionRequest, TransitionRequest, RequisitionLinesCreateRequest, RequisitionLineUpdateRequest, RequisitionLineDeleteRequest, RequisitionBulkApproveRequest, RequisitionBulkApproveRequestWithParams, RequisitionsQueryFilterPage, RequisitionBulkApproveResponse } from "../model/Requisition";
import { PurchaseOrderRequisitionRequest, PurchaseOrder } from "../model/PurchaseOrder";
import { AdditionalFieldRequest } from "../model/AdditionalInfoWorkflove";
import { TotalAuthorizeAmountResponse } from "../model/Cfdi";

export async function listRequisitions(tenantId: string, page: number, offset: number, params: RequisitionsQueryFilter): Promise<Requisitions> {
    return await callAPI(`/tenants/${tenantId}/requisitions`, {
        method: HTTPMethod.GET,
        query: getParams(page, offset, params)
    });
}

export async function createRequisition(tenantId: string, request: RequisitionRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getRequisition(tenantId: string, requisitionId: string): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateRequisition(tenantId: string, requisitionId: string, request: RequisitionRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateAdditionalInfoField(tenantId: string, requisitionId: string, request: AdditionalFieldRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/additional-info-field`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteRequision(tenantId: string, requisitionId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function createPurchaseOrder(tenantId: string, requisitionId: string, request: PurchaseOrderRequisitionRequest): Promise<PurchaseOrder> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/create-po`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function forwardRequisition(tenantId: string, requisitionId: string, request: TransitionRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/forward`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function backwardRequisition(tenantId: string, requisitionId: string, request: TransitionRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/backward`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function createLines(tenantId: string, requisitionId: string, request: RequisitionLinesCreateRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/lines`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function updateLine(tenantId: string, requisitionId: string, lineNumber: string, request: RequisitionLineUpdateRequest): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/lines/${lineNumber}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteLine(tenantId: string, requisitionId: string, lineNumber: string): Promise<Requisition> {
    return await callAPI(`/tenants/${tenantId}/requisitions/${requisitionId}/lines/${lineNumber}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getTotalRequsitions(tenantId: string, params: RequisitionsQueryFilter): Promise<TotalAuthorizeAmountResponse> { 
    return await callAPI(`/tenants/${tenantId}/requisitions/total-authorized`, {
        method: HTTPMethod.POST,
        body: getParams(0, 0, params)
    });
}

export async function approveSelected(tenantId: string, request: RequisitionBulkApproveRequest): Promise<RequisitionBulkApproveResponse> {
    return await callAPI(`/tenants/${tenantId}/requisitions/approve-selected`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function approveListed(tenantId: string, request: RequisitionBulkApproveRequest, params: RequisitionsQueryFilterPage): Promise<RequisitionBulkApproveResponse> {
    return await callAPI(`/tenants/${tenantId}/requisitions/approve-listed`, {
        method: HTTPMethod.PUT,
        body: {...request, params: params } as RequisitionBulkApproveRequestWithParams
    });
}

const getParams = (page: number, offset: number, params: RequisitionsQueryFilter) => {
    let query = {
        operationNumber: params.operationNumber || "",
        description: params.description || "",
        providerName: params.providerName || "",
        company: params.company || "",
        projectName: params.projectName || "",
        requisitionTypeName: params.requisitionTypeName || "",
        statusGroup: params.statusGroup || "",
        status: params.status || "",
        view: params.view || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    }as Record<string, string>;

    if(params.startDate && params.endDate){
        query = {...query,
            startDate: params.startDate,
            endDate: params.endDate,
        };
    }

    return query;
}