import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DigitalDocumentIcon from "@material-ui/icons/AllInbox";
import AddIcon from "@material-ui/icons/Add";

import { listDigitalDocuments, deleteDigitalDocument, exportDocumentsSelected } from "../api/DigitalDocumentAPI";
import { AppContext } from "../context/AppContext";
import { DigitalDocument, DigitalDocuments, DigitalDocumentsQueryParams, ExportDocumentsRequest } from "../model/DigitalDocument";

import translate from "../i18n/Translator";
import Pagination, { initialPageZero, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Ellipsis from "../components/Ellipsis";
import DigitalDocumentMenu from "./DigitalDocumentMenu";
import DateFormat from "../components/DateFormat";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import DialogPopup from "../components/DialogPopup";
import { StatusIcon } from '../cfdis/popups/AttachPaymentCfdisPopup';
import GridDx from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import DigitalDocumentsMenu from "./DigitalDocumentsMenu";

export default function DigitalDocumentsList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<DigitalDocuments>();
    const [workingParams, setWorkingParams] = useState<DigitalDocumentsQueryParams>({
        search: search,
    } as DigitalDocumentsQueryParams);

    const [digitalDocument, setDigitalDocument] = useState<DigitalDocument>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [isCreateGranted] = useState(context.isGranted("DigitalDocumentsCreate") && context.session!.role.id === 'owner_issuer');
    const [dialog, setDialog] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as DigitalDocumentsQueryParams;
        setWorkingParams(params);

        listDigitalDocuments(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onDelete = () => {
        setDialog("delete");
        setAnchorEl(null);
    };

    const confirmDelete = () => {
        setDialog(undefined);

        if (!digitalDocument) return;

        setSubmitting(true);
        deleteDigitalDocument(tenantId, digitalDocument.id).then(() => {
            load();
            setSuccess(translate("digital_documents.delete.success", { name: digitalDocument.name }) as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setDigitalDocument(undefined);
        });
    };

    const onClickedOptions = (digitalDocument: DigitalDocument) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setDigitalDocument(digitalDocument);
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setDigitalDocument(undefined);
    };

    const onClickRow = (digitalDocument: DigitalDocument) => {
        let isUpdateGranted = context.session!.role.id === 'owner_issuer' ? 
        context.isGranted("DigitalDocumentsUpdate") : 
        context.isGranted("DigitalDocumentsUpdate") && digitalDocument.expiration;
        if(isUpdateGranted){
            history.push(`/digital-documents/${digitalDocument.id}/edit`);
        }
    };

    const getStatus = (digitalDocument: DigitalDocument) => {
        return translate(`digital_documents.status.${digitalDocument.status}`) as string;
    };

    const columns = [
        {
            name: 'icon',
            title: " "
        },
        {
            name: 'name',
            title: translate("digital_documents.name") as string,
        },
        {
            name: 'description',
            title: translate("digital_documents.description") as string,
        },
        {
            name: 'expires_at',
            title: translate("digital_documents.expires_at") as string,
        },
        {
            name: 'status',
            title: translate("digital_documents.status.title") as string,
            getCellValue: (row: any) => getStatus(row)
        },
        {
            name: 'menu',
            title: " "
        },
    ];

    const [columnsFormat] = useState([
        { columnName: 'icon', width: 75 },
        { columnName: 'name', wordWrapEnabled: true, width: 250},
        { columnName: 'description', wordWrapEnabled: true, width: 350 },
        { columnName: 'expires_at' },
        { columnName: 'status' },
        { columnName: 'menu' },
      ]) as any;

      const dateColumns = ['expires_at'];

      const textColumns = ['name', 'description', 'status'];

      const setSelectionHandler = (selected: (number | string)[]) => {
        setSelected(selected);
    };

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const IconTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <Grid item xs={12} >
            <StatusIcon valid={!value.row.expires_at || value.row.expires_at > new Date()} />
        </Grid>;
        }} {...props} />
    );

    const NameTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <Grid item xs={12} style={{ "color": value.row.expires_at && value.row.expires_at < new Date() ? "#f44336" : "" }}>
            <Ellipsis text={value.row.name} lenght={100} uppercased={false} />
        </Grid>;
        }} {...props} />
    );

    const DateTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <Grid item xs={12} style={{ "color": value.row.expires_at && value.row.expires_at < new Date() ? "#f44336" : "" }}>
            <DateFormat date={value.row.expires_at} format="DD/MM/YYYY HH:mm" />
        </Grid>;
        }} {...props} />
    );
    
    const customPlugins = [
        <NormalTypeProvider for={['description', 'status']} editorComponent={NoFilterEditor}/>,
        <IconTypeProvider for={['icon']} editorComponent={NoFilterEditor} />,
        <NameTypeProvider for={['name']} editorComponent={NoFilterEditor} />,
        <DateTypeProvider for={['expires_at']} editorComponent={NoFilterEditor} />,
    ];

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onDownloadSelected = () => {
        setGridAnchorEl(null);
        if (selected && selected.length > 0) {
            setOpenBackdrop(true);
            exportDocumentsSelected(context.session!.tenant!.id, { document_ids: selected } as ExportDocumentsRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.url) {
                    window.open(response.url, "_blank")
                } else {
                    setWarning(translate("digital_documents.empty_export") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });
        } else {
            setWarning(translate("digital_documents.empty_selection") as string);
        }
    }

    return (
        <Pagination title={translate("digital_documents.title")} icon={<DigitalDocumentIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Link to="/digital-documents/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                    
                </Grid>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("digital_documents.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    columns={columns ? columns : []}
                    columnsFormat={columnsFormat}
                    selectionIds={selected}
                    onClickRow={onClickRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    dateColumns={dateColumns}
                    textColumns={textColumns}
                    customFormatColumns={customPlugins}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    showTimeInDates={true}
                />
            {digitalDocument && anchorEl &&
                <DigitalDocumentMenu digitalDocument={digitalDocument}
                    anchor={anchorEl}
                    onDelete={onDelete}
                    onClose={onCloseOption}
                />
            }
            {digitalDocument && dialog === "delete" && (
                <DialogPopup open maxWidth="md"
                    title={translate("digital_documents.delete.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={confirmDelete} variant="outlined" color="secondary" disabled={submitting}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("digital_documents.delete.text", {name: digitalDocument.name})}
                </DialogPopup>
            )}
            {gridAnchorEl && (
                <DigitalDocumentsMenu anchor={gridAnchorEl}
                    onDownloadSelected={onDownloadSelected}
                    onClose={() => setGridAnchorEl(null)}
                />
            )}
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting || openBackdrop} message={translate("cfdis.processing") as string}/>
        </Pagination>
    );
}