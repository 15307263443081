import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { getSignin2FA, completeSignin2FA } from "../api/AuthAPI";
import { User, DoubleFactorAuthenticationRequest } from "../model/User";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import { Code2FAValidator } from "../components/Validators";
import Progress from "../components/Progress";
import GoHome from "../components/GoHome";
import ExternSurface from "../components/ExternSurface";

export default function SignIn2FAForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const code2FAValidator = new Code2FAValidator();
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [user, setUser] = useState<User>();
    const [request, setRequest] = useState<DoubleFactorAuthenticationRequest>({
        "code": "",
    } as DoubleFactorAuthenticationRequest);
    const [validations, setValidations] = useState({
        "code": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        setStatus("loading");
        getSignin2FA(match.params.code).then((response) => {
            setUser(response.user);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [match.params.code]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });

        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        completeSignin2FA(match.params.code, request).then((_) => {
            context.onSignedIn();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
    };

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loaded") {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface title={translate("auth.2fa.title", { "name": user!.first_name }) as string} description={translate("auth.2fa.text") as string}>
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="code" name="code" value={request?.code}
                            label={translate("users.2fa_code") as string} maxLength={6}
                            onValueChanged={hasChanged} disabled={false}
                            validator={code2FAValidator} />
                    </Grid>
                    <Grid item xs={12} className="ExternButton">
                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                            {translate("buttons.signin")}
                        </Button>
                    </Grid>
                    {/* <Grid item xs="auto">
                        <Link component={RouterLink} to="/signup">
                            <small>{translate("buttons.signup")}</small>
                        </Link>
                        </Grid> */}
                    <Grid item xs={12}>
                        <Typography align="right">
                            <Link component={RouterLink} to="/recover-password">
                                <small>{translate("buttons.recover")}</small>
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </ExternSurface>
    );

}
