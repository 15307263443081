import { callAPI, HTTPMethod } from "./API";
import { Contributions, ContributionsQueryParams, ContributionsToValidate } from "../model/Contribution";
import { ExportCfdisResponse } from "../model/TenantCfdi";

const basePath = (tenantId: string) => `/tenants/${tenantId}/contributions`;

export async function listContributions(tenantId: string, page: number, offset: number, params: ContributionsQueryParams): Promise<Contributions> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            month: params.month?.toString() || "",
            year: params.year?.toString() || "",
            status: params.status || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function listContributionsToValidate(tenantId: string, page: number, offset: number): Promise<ContributionsToValidate> {
    return await callAPI(`${basePath(tenantId)}/to-validate`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function exportExcel(tenantId: string, params: ContributionsQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`${basePath(tenantId)}/export-excel`, {
        method: HTTPMethod.GET,
        query: {
            month: params.month?.toString() || "",
            year: params.year?.toString() || "",
            status: params.status || "",
            pageSize: "0",
            offset: "0"
        }
    });
}
