import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useHistory, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WorkIcon from "@material-ui/icons/Work";
import AddIcon from "@material-ui/icons/Add";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { getTenants } from "../api/TenantAPI";
import { Tenant, Tenants, TenantsQueryParams } from "../model/Tenant";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import ValidatedInput from "../components/ValidatedInput";
import TenantMenu from "./TenantMenu";
import TenantsMenu from "./TenantsMenu";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DeleteCfdisPopup from "./DeleteCfdisPopup";
import DeleteCfdiByUuidPopup from "./DeleteCfdiByUuidPopup";
import BackwardByUuidPopUp from "./BackwardPendingPaymentByUuidPopup";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DialogPopup from "../components/DialogPopup";
import { revalidateSAT } from "../api/SupportAPI";
import CustomBackdrop from "../components/CustomBackdrop";
import { RouterParams } from "../router/RouterParams";

export default function TenantsList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): TenantsQueryParams => {
        return {
            "search": typeof qs["search_tenant"] === "string" ? qs["search_tenant"] as string : ""
        } as TenantsQueryParams;
    };

    qs["search_connector"] = undefined;
    qs["search_widget"] = undefined;

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Tenants>();
    const [params, setParams] = useState<TenantsQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<TenantsQueryParams>(paramsFromQueryString);

    const [tenant, setTenant] = useState<Tenant>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openDeleteCfdis, setOpenDeleteCfdis] = useState<boolean>(false);
    const [openDeleteCfdiByUuid, setOpenDeleteCfdiByUuid] = useState<boolean>(false);
    const [openBackwardPendingPayment, setOpenBackwardPendingPayment] = useState<boolean>(false);

    const [openConfirmRevalidate, setOpenConfirmRevalidate] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (match.params.success) {
            setSuccess(match.params.success);
        }
        let offset = getOffset(page, pageSize);
        getTenants(pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [context.session, page, pageSize, params, match]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search_tenant"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (tenant: Tenant) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setTenant(tenant);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setTenant(undefined);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onOpenDeleteCfdis = () => {
        setGridAnchorEl(null);
        setOpenDeleteCfdis(true);
    };

    const onOpenDeleteCfdiByUuid = () => {
        setGridAnchorEl(null);
        setOpenDeleteCfdiByUuid(true);
    };

    const onSuccessDeleteCfdis = (success : string) => {
        setOpenDeleteCfdis(false);
        setOpenDeleteCfdiByUuid(false)
        setSuccess(success);
    };

    const onOpenBackwardByUuid = () => {
        setGridAnchorEl(null);
        setOpenBackwardPendingPayment(true);
    };

    const onSuccessBackward = (success : string) => {
        setOpenBackwardPendingPayment(false)
        setSuccess(success);
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onOpenRevalidateSAT = () => {
        setGridAnchorEl(null);
        setOpenConfirmRevalidate(true);
    }

    const onRevalidateSAT = () => {
        setStatus("loading");
        revalidateSAT().then(r => {
            setSuccess(translate("tenants.revalidate_sat.success", {total: r.total}) as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
            setOpenConfirmRevalidate(false);
        });
    }

    return (
        <Pagination title={translate("tenants")} icon={<WorkIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Link to="/tenants/new">
                        <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                            <AddIcon />
                        </Fab>
                    </Link>
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("tenants.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("tenants.empty") as string}
                columns={[
                    {
                        title: translate("tenants.name") as string,
                        converter: (tenant) => (
                            <div>
                                <Ellipsis text={tenant.name} lenght={100} /><br />
                                <Ellipsis text={tenant.rfc} lenght={100} secondary />
                            </div>
                        ),
                        xs: 9,
                        sm: 7,
                        lg: 5,
                        xl: 5
                    },
                    {
                        title: translate("tenants.license.title") as string,
                        converter: (tenant) => translate(`tenants.license.status.${tenant.license_status}`),
                        xs: false,
                        sm: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("tenants.active.title") as string,
                        converter: (tenant) => translate(`tenants.active.${tenant.active}`),
                        xs: false,
                        sm: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("tenants.created") as string,
                        converter: (tenant) => <DateFormat date={tenant.created_at} format="L" />,
                        md: false,
                        lg: 2
                    },
                    {
                        title: "",
                        converter: (tenant) => (<IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(tenant)}>
                            <MoreVertIcon />
                        </IconButton>),
                        justify: "flex-end",
                        xs: 3,
                        sm: 1
                    }
                ]} />
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <CustomBackdrop open={status === "loading"} message={translate("cfdis.processing") as string} />
            {tenant && anchorEl &&
                <TenantMenu tenant={tenant} anchor={anchorEl} onClose={onCloseOption} />
            }
            {gridAnchorEl && (
                <TenantsMenu onDeleteCfdis={onOpenDeleteCfdis} onDeleteCfdiByUuid={onOpenDeleteCfdiByUuid} onBackwardPendingPayment={onOpenBackwardByUuid} anchor={gridAnchorEl} onClose={() => setGridAnchorEl(null)} onRevalidateSAT={onOpenRevalidateSAT} />
            )}
            {openDeleteCfdis && (
                <DeleteCfdisPopup onClose={() => setOpenDeleteCfdis(false)} onSuccess={onSuccessDeleteCfdis}/>
            )}
            { openDeleteCfdiByUuid && (
                <DeleteCfdiByUuidPopup onClose={() => setOpenDeleteCfdiByUuid(false)} onSuccess={onSuccessDeleteCfdis}/>
            )
            }
            { openBackwardPendingPayment && (
                <BackwardByUuidPopUp onClose={() => setOpenBackwardPendingPayment(false)} onSuccess={onSuccessBackward}/>
            )
            }
            {openConfirmRevalidate && (
                <DialogPopup open maxWidth="sm"
                    title={translate("tenants.revalidate_sat.title") as string}
                    disableEscapeKeyDown={false}
                    disableBackdropClick={false}
                    disable={false}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setOpenConfirmRevalidate(false)}
                    closeColor="default"
                    button={
                        <Button onClick={onRevalidateSAT} variant="outlined" color="secondary" disabled={status === "loading"}>
                            {translate("buttons.continue")}
                        </Button>
                    }
                >
                    {translate("tenants.revalidate_sat.text")}
                </DialogPopup>
            )}
        </Pagination>
    );
}
