import React, { useEffect, useState } from "react";
import { RouterParams } from "../router/RouterParams";
import { Button, Checkbox, Grid } from "@material-ui/core";
import TenantModulesIcon from "@material-ui/icons/ViewCompactTwoTone";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import Gridable from "../components/Gridable";
import CustomBackdrop from "../components/CustomBackdrop";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { TenantModulesRequest, TenantModulesResponse } from "../model/TenantModule";
import { get, update } from "../api/TenantModuleApi";

export default function TenantModulesForm({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<TenantModulesResponse>();
    const [selected, setSelected] = useState<string[]>([]);
    const [allChecked, setAllChecked] = useState(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    const parseData = (response: TenantModulesResponse) => {
        response.modules.forEach((module) => {
            module.selected = response.enabled_modules.indexOf(module.id) >= 0;
        });

        const notSelected = response.modules.filter(el => !el.selected);
        setAllChecked(notSelected.length === 0);
        setSelected(response.enabled_modules);
        setData(response);
    };

    useEffect(() => {
        setStatus("loading");
        get(match.params.tenantId).then((response) => {
            parseData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [match.params.tenantId]);

    const checkAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!data) return;

        const newModules = data.modules.map(module => {
            module.selected = checked;
            return module;
        });

        setAllChecked(checked);
        setData({ ...data, modules: newModules });
        setSelected(newModules.filter(el => el.selected).map(el => el.id));
    };

    const checkSingle = (index: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!data) return;

        const thisModule = data.modules[index];
        thisModule.selected = checked;

        const newModules = data.modules.map((el, elIndex) => {
            if (elIndex === index) {
                return thisModule;
            }
            if (el.id === "BASE") {
                el.selected = true;
            }
            return el;
        });
        setData({ ...data, modules: newModules });

        const selectedModuled = newModules.filter(el => el.selected);
        setAllChecked(selectedModuled.length === newModules.length);
        setSelected(selectedModuled.map(el => el.id));
    };

    const onUpdate = () => {
        if (!data) return;

        setLoading(true);
        update(match.params.tenantId, { modules: selected } as TenantModulesRequest).then((response) => {
            parseData(response);
            setSuccess(translate("tenant_modules.success", { tenant: data.tenant.name, count: selected.length }) as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    return (
        <Surface title={translate("tenant_modules.title")} subtitle={data?.tenant.name} icon={<TenantModulesIcon />} backButton>
            <Grid container justify="flex-end" spacing={3}>
                <Grid item xs={12}>
                    <Gridable items={data?.modules || []}
                        loading={status === "loading"} error={status !== "loading" && status !== "loaded" ? error : undefined}
                        empty={translate("tenant_modules.empty") as string}
                        columns={[
                            {
                                title: (
                                    <Checkbox checked={allChecked}
                                        indeterminate={selected.length > 0 && selected.length !== data?.modules.length}
                                        onChange={checkAll}
                                        disabled={loading} />
                                ),
                                converter: (module, index) => (
                                    <Checkbox checked={module.selected}
                                        onChange={checkSingle(index)}
                                        disabled={module.id === "BASE" || loading} />
                                ),
                                fullWidth: true,
                                xs: "auto",
                            },
                            {
                                title: translate("tenant_modules.name"),
                                converter: (module) => module.name,
                                fullWidth: true,
                                xs: 3,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3,
                            },
                            {
                                title: translate("tenant_modules.description"),
                                converter: (module) => module.description,
                                fullWidth: true,
                                xs: true,
                                sm: true,
                                md: true,
                                lg: true,
                                xl: true,
                            }
                        ]} />
                </Grid>
                <Grid item xs={12} md="auto">
                    <Button variant="contained" size="large" color="primary" onClick={onUpdate} disabled={loading}>
                        {translate("buttons.update")}
                    </Button>
                </Grid>
            </Grid>
            <CustomBackdrop open={loading} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );

}