import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, IconButton, Typography, Divider } from "@material-ui/core";
import { Cfdi, CfdiStatus_CANCELLED } from '../model/Cfdi';
import { Redirect, useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import Gridable, { GridableColumn } from "../components/Gridable";
import { PaymentOrderCfdiItem } from "../model/PaymentOrder";
import NumberFormat from "react-number-format";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DeleteIcon from "@material-ui/icons/Delete";
import ValidIcon from "@material-ui/icons/CheckCircleTwoTone";
import PaymentTwoToneIcon from "@material-ui/icons/PaymentTwoTone";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import EditIcon from "@material-ui/icons/Edit";
import { CfdiDescription, CfdiAmounts } from "../cfdi/CfdiComponents";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaymentOrderCfdiMenu from "./PaymentOrderCfdiMenu";
import { ProviderFromCfdi } from "../providers/Providers";

interface PaymentOrderCfdiListProps {
    data: PaymentOrderCfdiItem[];
    edit: boolean;
    comments: string;
    status_order: string;
    date?: Date;
    paymentOrderId?: string;
    isReaderOnly: boolean;
    hasChangedAmount(name: string, value: number, inputRef: InputRef): any;
    deleteCfdi(cfdiId: string): any;
    payCfdi(cfdiId: string): any;
    editCfdi(cfdiId: string): any;
    onCancelCfdi(cfdiId: string): any;
    hasChanged(name: string, value: string | number, inputRef: InputRef): any;
}

interface PaymentCurrency {
    currency: string;
    total: number;
}

export default function PaymentOrderCfdiList(props: PaymentOrderCfdiListProps) {
    const context = useContext(AppContext);
    const [groups, setGroups] = useState<PaymentCurrency[]>([]);
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const [redirect, setRedirect] = useState<string>();
    const history = useHistory();
    const [cfdiId, setCfdiId] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const load = () => {
        let grouping = {} as any;
        props.data.forEach(item => {
            if (!grouping[item.payment_currency]) {
                grouping[item.payment_currency] = [];
            }
            grouping[item.payment_currency].push(item);
        });

        const groups = Object.keys(grouping).map(currency => {
            const items = grouping[currency] as PaymentOrderCfdiItem[];
            return {
                currency: currency,
                total: items.reduce((sum, cfdi) => sum + Number(cfdi.payment_amount), 0),
            } as PaymentCurrency;
        }).sort((a, b) => {
            if (a.currency === b.currency) {
                return 0;
            } else if (a.currency < b.currency) {
                return -1;
            }
            return 1;
        });

        setGroups(groups);
    }

    useEffect(load, [props]);

    const validateCfdiCancelled = (cfdi: PaymentOrderCfdiItem) => {
        return cfdi && cfdi.metadata && cfdi.metadata.cancelled_by_sat;
    };

    const payCfdi = (cfdi: PaymentOrderCfdiItem) => {
        if (cfdi && cfdi.metadata && cfdi.metadata.cancelled_by_sat) {
            setCfdisCancelled([cfdi]);
        } else {
            props.payCfdi(cfdi.id);
        }
    };

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    const onClickedRow = (cfdiId: string) => {
        if (props.paymentOrderId) {
            let url = window.location.pathname;
            history.push(url);
            setRedirect(`/cfdis/${cfdiId}/details?referer=payment-order`);
        }
    };

    const onClickedRowId = (id: string, isAdvancePayment?: boolean) => {
        history.push(window.location.pathname);
        setRedirect(`/cfdis/${id}/details${isAdvancePayment ? '?tab=advance_payments&referer=payment-order' : ''}`);
    };

    const onClickCheck = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onClickedOptions = (cfdiId: string) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setCfdiId(cfdiId);
    };

    const onCancelCfdi = () => {
        if (!cfdiId) return;
        props.onCancelCfdi(cfdiId);
        setAnchorEl(null);
        setCfdiId(undefined);
    };

    const getColumns = () => {
        const columns = [
            {
                title: translate("cfdis.columns.comprobante") as string,
                converter: (cfdi) => (
                    <CfdiDescription cfdi={cfdi} onClickRow={onClickedRow} onClickAdvancePayment={(id) => onClickedRowId(id, true)} />
                ),
                fullWidth: true,
                xs: 6,
                sm: 5,
                md: 4,
                lg: 3,
                xl: 3,
            },
            {
                title: translate("payment.columns.provider") as string,
                converter: (cfdi) => (
                    <ProviderFromCfdi cfdi={cfdi} />
                ),
                fullWidth: true,
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                lx: 2,
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("cfdis.columns.totals")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <CfdiAmounts cfdi={cfdi} showBalance />
                ),
                fullWidth: true,
                xs: false,
                sm: false,
                md: 3,
                lg: 2
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("payments_cfdi.amount_authorized")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <Typography variant="body2" component="h6" align="right">
                        <NumberFormat value={cfdi.amount ? cfdi.amount : 0}
                            prefix="$ " suffix={` ${cfdi.currency}`}
                            decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                            displayType="text" />
                    </Typography>
                ),
                fullWidth: true,
                justify: "flex-end",
                xs: false,
                sm: 3,
                md: 2,
                lg: 2
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("payments_cfdi.amount_payment_currency")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <Typography variant="body2" component="h6" align="right">
                        <NumberFormat value={cfdi.payment_amount}
                            prefix="$ " suffix={` ${cfdi.payment_currency}`}
                            decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                            displayType="text" />
                    </Typography>
                ),
                fullWidth: true,
                xs: true,
                sm: 3,
                md: 2,
                lg: 2
            },
            {
                title: (
                    <>
                        {props.edit && (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <PaymentTwoToneIcon />
                            </IconButton>
                        )}
                        <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                            <PaymentTwoToneIcon />
                        </IconButton>
                    </>
                ),
                converter: (cfdi) => (
                    <>
                        <Grid item xs="auto" onClick={onClickCheck(cfdi)}>
                            {props.edit && cfdi.status !== "TO_EXECUTE_BANK" && cfdi.status !== "PAID" && (
                                <IconButton size="small" onClick={() => props.editCfdi(cfdi.id)} disabled={!props.edit || !props.date}>
                                    <EditIcon />
                                </IconButton>
                            )}
                            {!props.isReaderOnly && context.isGrantedAny(["BankAccountAdminRead", "PaymentOrdersCxpDelete"]) &&
                                (<ButtonPaymentOrderCfdi edit={props.edit}
                                    status={cfdi.status}
                                    cfdiId={cfdi.id}
                                    deleteCfdi={props.deleteCfdi}
                                    payCfdi={() => payCfdi(cfdi)}
                                    totalCfdis={props.data.length}
                                    status_order={props.status_order} />)}
                            {(props.isReaderOnly || props.edit || cfdi.metadata.status === CfdiStatus_CANCELLED || cfdi.status === "PAID" || cfdi.status === "TO_EXECUTE_BANK") ? undefined :
                                <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(cfdi.id)}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        </Grid>

                    </>
                ),
                fullWidth: true,
                justify: "flex-end",
                xs: 2,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1
            }
        ] as GridableColumn<PaymentOrderCfdiItem>[];
        return columns.filter(col => !!col);
    };

    return (
        <>
            <Gridable
                items={props.data}
                loading={false}
                background={validateCfdiCancelled}
                empty={translate("cfdis.history.empty") as string}
                columns={getColumns()}
            />
            <Box p={2} >
                <Grid container spacing={3} justify="space-between">
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ValidatedInput disabled={!props.edit}
                                    type="textarea"
                                    id="comments"
                                    name="comments"
                                    value={props.comments}
                                    label={translate("payments_cfdi.input.comments") as string}
                                    required={false}
                                    margin="dense"
                                    onValueChanged={props.hasChanged}
                                    maxRows={10}
                                    rows={2}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        {groups.map((group, index) => (
                            <Grid container spacing={4} key={`group-${index}`}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6} md={8}>
                                            <Typography variant="subtitle1" component="h6" align="right">
                                                {translate("payment_order.total", { "currency": group.currency })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <Typography variant="subtitle1" component="h6" align="right">
                                                <strong>
                                                    <NumberFormat value={group.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                                </strong>
                                            </Typography>
                                        </Grid>
                                        {groups.length > (index + 1) && (
                                            <Grid item xs={12}>
                                                <Box mt={2}>
                                                    <Divider />
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
            {cfdiId && anchorEl && (
                <PaymentOrderCfdiMenu anchor={anchorEl}
                    onCancelCfdi={onCancelCfdi}
                    onClose={() => { setAnchorEl(null); setCfdiId(undefined); }}
                />
            )}
            {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} />
                : undefined}
        </>
    );
}

interface ButtonPaymentOrderCfdiProps {
    edit: boolean;
    status: string;
    cfdiId: string;
    totalCfdis: number;
    status_order: string;
    deleteCfdi(cfdiId: string): any;
    payCfdi(cfdiId: string): any;
}

function ButtonPaymentOrderCfdi(props: ButtonPaymentOrderCfdiProps) {

    if ((!props.status || props.status === "PENDING") && props.edit) {
        return (
            <IconButton aria-label="options"
                color="default"
                size="small"
                disabled={props.totalCfdis <= 1}
                onClick={() => props.deleteCfdi(props.cfdiId)}>
                <DeleteIcon />
            </IconButton>
        );
    }

    if (props.status === "PENDING" && (props.status_order === "PAYMENT_IN_PROCESS_TO_EXECUTE" || props.status_order === "PARTIALLY_PAID")) {
        return (
            <IconButton aria-label="options"
                color="primary"
                size="small"
                onClick={() => props.payCfdi(props.cfdiId)}>
                <PaymentTwoToneIcon />
            </IconButton>
        );
    }

    if (props.status === "PAID") {
        return (
            <ValidIcon style={{ "color": "#82CA9D" }} />
        );
    }

    if (props.status === "TO_EXECUTE_BANK") {
        return (
            <LibraryBooksTwoToneIcon style={{ "color": "#F5812F" }} />
        );
    }

    return (
        <div></div>
    );

}
