import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Button, Typography, Box, TextField } from "@material-ui/core";
import translate from "../i18n/Translator";
import { BankAccountRequest, TenantBankAccount } from "../model/BankAccount";
import { getBankAccount, createBankAccount, updateBankAccount } from "../api/TenantBankAccountAPI";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Surface from "../components/Surface";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import { CLABEValidator } from "../components/Validators";
import { getBanks } from "../api/BankCatalogApi";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Bank } from "../model/Bank";
import { useHistory } from "react-router-dom";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { CONTRIES } from "../catalogs/Countries";

export default function BankAccountsAdminForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const isEdit = !!match.params.bankAccountId;
    const margin = "dense";
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bank, setBank] = useState<Bank>();
    const CLABE_VALIDATOR = new CLABEValidator();
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [inUse, setInUse] = useState<boolean>();
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);
    const countryLabels = CONTRIES.map((code) => translate(`countries.${code}`) as string);
    const [isForeign, setIsForeign] = useState<boolean>(false);

    const [request, setRequest] = useState<BankAccountRequest>({
        country: "",
        name: "",
        account_number: "",
        currency: "",
        clabe: "",
        bank_id: "",
        bank_name: "",
        external_id: ""
    } as BankAccountRequest);

    const [validations, setValidations] = useState({
        country: {
            valid: false,
        } as InputRef,
        name: {
            valid: false,
        } as InputRef,
        account_number: {
            valid: false,
        } as InputRef,
        currency: {
            valid: false,
        } as InputRef,
    } as any);

    const [nationalValidations, setNationalValidations] = useState({
        clabe: {
            valid: false,
        } as InputRef,
        bank_id: {
            valid: false,
        } as InputRef,
    } as any);

    const [foreignValidations, setForeignValidations] = useState({
        bank_name: {
            valid: false,
        } as InputRef,
    } as any);

    const submitPromise = (): Promise<TenantBankAccount> => {
        if (isEdit && match.params.bankAccountId) {
            return updateBankAccount(context.session!.tenant!.id, match.params.bankAccountId, request);
        }
        return createBankAccount(context.session!.tenant!.id, request);
    };

    useEffect(() => {
        setStatus("loading");

        Promise.all([
            getBanks(""),
            getTenantCurrencies(context.session!.tenant!.id)
        ]).then(([banks, tenantCurrencies]) => {
            setBanks(banks.items);

            if (tenantCurrencies.tenant_currency_ids) {
                let listTemp = [] as AutocompleteProvider[];
                if (tenantCurrencies.tenant_currency_ids) {
                    tenantCurrencies.tenant_currency_ids.forEach((item) => {
                        let temp = { title: (item + " - " + (translate("currency." + item) as string)), value: item } as AutocompleteProvider;
                        listTemp.push(temp);
                    });
                }
                setCurrenciesAutocomplete(listTemp);
            }

            if (isEdit) {
                getBankAccount(context.session!.tenant!.id, match.params.bankAccountId).then((response) => {
                    setRequest({
                        country: response.country || "MEX",
                        name: response.name,
                        account_number: response.account_number,
                        currency: response.currency,
                        clabe: response.clabe,
                        bank_id: response.bank.id,
                        bank_name: response.bank_name,
                        external_id: response.external_id,
                    } as BankAccountRequest);
                    setIsForeign("MEX" !== response.country);
                    setInUse(response.in_use);
                    setBank(response.bank);
                    setStatus("loaded");

                }).catch((error) => {
                    setStatus(error.message);
                });
            } else {
                setStatus("loaded");
            }
        });

    }, [isEdit, match.params.providerId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        if ("country" === name && value) {
            setIsForeign("MEX" !== value);
        }

        setRequest((request) => {
            return { ...request, [name]: value };
        });

        if (!["clabe", "bank_id", "bank_name"].includes(name)) {
            validations[name] = inputRef;
            setValidations(validations);
        }
        if (["clabe", "bank_id"].includes(name)) {
            nationalValidations[name] = inputRef;
            setNationalValidations(nationalValidations);
        }
        if ("bank_name" === name) {
            foreignValidations[name] = inputRef;
            setForeignValidations(foreignValidations);
        }

    };

    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
        if (value) {
            validations["currency"] = {
                valid: !!value,
            };
            setValidations(validations);
        }
    };

    const isValid = (itemValidations: any) => {
        var valid = true;
        for (let field in itemValidations) {
            let ref = itemValidations[field];
            if (!ref?.valid && "currency" !== field && "bank_id" !== field) {
                if (ref?.blurer) {
                    ref.blurer(true);
                }
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            isValid(isForeign ? foreignValidations : nationalValidations);
            setError(translate("bank_account.warning_missing_data") as string);
            return;
        }
        if (!isValid(isForeign ? foreignValidations : nationalValidations)) {
            setError(translate("bank_account.warning_missing_data") as string);
            return;
        }
        if (!request.currency) {
            setWarning(translate("currencies.currency_required") as string);
            return
        }
        if (!isForeign && (request.bank_id === "" || request.bank_id === undefined)) {
            setWarning(translate("bank_account.warning_bank") as string);
            return;
        }

        if (!isForeign) {
            request.bank_name = "";
        } else {
            request.clabe = "";
            request.bank_id = "";
        }
        setSubmitting(true);
        submitPromise().then((_) => {
            let success = translate("bank_account.success_create") as string
            setRedirect(`/bank-accounts/${success}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const hasChangedAutocomplete = (selected: string) => {
        setRequest({ ...request, bank_id: selected });
        nationalValidations["bank_id"] = {
            valid: !!selected,
        };
        setValidations(nationalValidations);
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "bank_account.edit_title" : "bank_account.new_title")} icon={<ListAltIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={request.country}
                                        label={translate("address.country") as string}
                                        options={CONTRIES}
                                        optionLabels={countryLabels}
                                        required={true}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <TenantCurrencyAutocomplete
                                            value={currenciesAutocomplete?.find(v => v.value === request?.currency)}
                                            currencies={currenciesAutocomplete}
                                            onChange={onFilterChanged}
                                            disabled={submitting}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name} label={translate("bank_account.columns.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {!isForeign &&
                                    <Grid item xs={12}>
                                        <Box pb={1}>
                                            <Autocomplete
                                                size="small"
                                                options={banks}
                                                value={bank}
                                                disabled={submitting || inUse}
                                                getOptionLabel={(elemento) => elemento.name}
                                                noOptionsText={translate("bank_account.no_options_autocomplete") as string}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" label={translate("bank_account.bank") as string} />
                                                )}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        hasChangedAutocomplete(newValue.id);
                                                    } else {
                                                        hasChangedAutocomplete("");
                                                    }
                                                }}
                                                getOptionSelected={(opt, val) => opt.id === val.id}
                                            />
                                        </Box>
                                    </Grid>
                                }
                                {isForeign &&
                                    <Grid item xs={12}>
                                        <ValidatedInput
                                            type="text"
                                            id="bank_name"
                                            name="bank_name"
                                            value={request.bank_name}
                                            label={translate("bank_account.bank") as string}
                                            required={isForeign}
                                            disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="external_id" name="external_id"
                                        value={request.external_id} label={translate("bank_account.external_id") as string}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {!isForeign &&
                                    <Grid item xs={12}>
                                        <ValidatedInput
                                            type="text"
                                            id="clabe"
                                            name="clabe"
                                            value={request.clabe}
                                            label={translate("bank_account.columns.clabe") as string}
                                            required={true}
                                            disabled={submitting}
                                            margin={margin}
                                            validator={CLABE_VALIDATOR}
                                            onValueChanged={hasChanged} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="account_number" name="account_number"
                                        value={request.account_number} label={translate("bank_account.columns.no_cuenta") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                        <Grid item xs={12} md="auto">
                                            <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                {translate(isEdit ? "buttons.update" : "buttons.add")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md="auto">
                                            <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                {translate("buttons.cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}