import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";
import EmployeeIcon from "@material-ui/icons/PersonTwoTone";
import { getPayrollXmlUrl } from "../api/PayrollAPI";
import { getPayrollXmlUrl as getProviderPayrollXmlUrl } from "../api/ProviderPayrollAPI";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";

interface PayrollMenuProps {
    payroll: Cfdi;
    anchor: HTMLElement | null;
    onDelete(): any;
    onClose(): any;
}

export default function PayrollMenu(props: PayrollMenuProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const [url, setUrl] = useState<string>();

    const promiseGet = () => {
        if (providerId) {
            return getProviderPayrollXmlUrl(tenantId, providerId, props.payroll.id);
        }
        return getPayrollXmlUrl(tenantId, props.payroll.id);
    };

    const load = () => {
        if (props.payroll.metadata.xml_url) {
            promiseGet().then(url => {
                setUrl(url);
            }).catch(error => {
                console.error(error);
            });
        } else {
            setUrl(undefined);
        }
    };

    useEffect(load, [tenantId, providerId, props.payroll]);

    return (
        <Menu
            id="payrolls-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["EmployeesRead", "ProviderEmployeesRead"]) && props.payroll.employee?.id && (
                <MenuItem button component={Link} to={`/employees/${props.payroll.employee!.id}/overview`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EmployeeIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("employees.single")}</Typography>
                </MenuItem>
            )}
            <MenuItem button onClick={props.onClose} dense component="a" href={url || "#"} target="_blank" rel="noopener noreferrer" disabled={!url}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
            </MenuItem>
            {context.isGrantedAny(["EmployeesDelete", "ProviderEmployeesDelete"]) &&
                    <MenuItem button onClick={props.onDelete} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                    </MenuItem>
                }
        </Menu>
    );

}