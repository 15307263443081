import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { WarehouseDeliveries, WarehouseDelivery, WarehouseDeliveriesQueryParams, WarehouseDeliveryRequest } from "../model/WarehouseDelivery";
import { ExportRequest, ExportResponse } from "../model/Connector";
import { ImportResponse } from "../model/ExportableObject";

const basePath = (tenantId: string) => `/tenants/${tenantId}/warehouse-deliveries`;

export async function listWarehouseDeliveries(tenantId: string, page: number, offset: number, params: WarehouseDeliveriesQueryParams): Promise<WarehouseDeliveries> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createWarehouseDelivery(tenantId: string, request: WarehouseDeliveryRequest): Promise<WarehouseDelivery> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getWarehouseDelivery(tenantId: string, id: string): Promise<WarehouseDelivery> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateWarehouseDelivery(tenantId: string, id: string, request: WarehouseDeliveryRequest): Promise<WarehouseDelivery> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteWarehouseDelivery(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function importWarehouseDeliveries(tenantId: string, file: File): Promise<ImportResponse> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    return await callAPI(`${basePath(tenantId)}/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function downloadImportTemplate(tenantId: string, connectorId: string): Promise<string> {
    return await getAuthenticatedGetUrl(`${basePath(tenantId)}/import`, {
        query: {
            connectorId: connectorId,
        },
    });
}


export async function exportWarehouseDeliveries(tenantId: string, connectorId: string, params: WarehouseDeliveriesQueryParams): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            connectorId: connectorId,
        }
    });
}

export async function exportSelectedWarehouseDeliveries(tenantId: string, connectorId: string, ids: string[]): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.PUT,
        body: {
            ids: ids,
            connector_id: connectorId,
        } as ExportRequest
    });
}

export async function listAllForPurchaseOrder(tenantId: string, ids: string[]): Promise<WarehouseDeliveries> {
    return await callAPI(`${basePath(tenantId)}/listAllForPurchaseOrder`, {
        method: HTTPMethod.POST,
        body: ids
    });
}