import React, { useState, useEffect } from "react";
import DialogPopup from "../components/DialogPopup";
import { AnnouncementRequest } from "../model/Announcement";
import translate from "../i18n/Translator";
import { Button, IconButton, Grid, Typography, Card, ListItemText, Box } from "@material-ui/core";
import Editor from '../components/Editor';
import { Providers, ProvidersQueryParams } from "../model/Provider";
import { getProviders } from "../api/ProviderAPI";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import GridDx from "../components/GridDx";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import CustomBackdrop from "../components/CustomBackdrop";
import { createAnnouncement, getAnnouncement, updateAnnouncement } from "../api/AnnouncementAPI";
import { Filter } from "@devexpress/dx-react-grid";
import { RawDraftContentState } from 'draft-js';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ConfirmationPopup from "../components/ConfirmationPopup";
import SelectClassificationsPopUp from "./SelectClassificationsPopUp";
import { formatDateToShow } from "../components/DateFormat";

interface AnnouncementCreateModalProps {
    tenantId: string;
    announcementId?: string;
    option: "providers" | "all" | "class";
    onSave(): any;
    onSend(): any;
    onBackToOptions(): any;
    onClose(): any;
}

export default function AnnouncementCreateModal(props: AnnouncementCreateModalProps) {
    const [view, setView] = useState<"providers" | "body" | "preview" | "class" | "all">(props.announcementId ? "body" : props.option);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [selectedProviders, setSelectedProviders] = useState<(number | string)[]>([]);
    const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);
    const [providersClassifications, setProvidersClassifications] = useState<number>(0);
    const [request, setRequest] = useState<AnnouncementRequest>();
    const [openConfirmSend, setOpenConfirmSend] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    const load = () => {
        setStatus("loading");
        if (props.announcementId) {
            getAnnouncement(props.tenantId, props.announcementId).then((response) => {
                setRequest({
                    start_date: response.start_date,
                    end_date: response.end_date,
                    status: response.status,
                    title: response.title,
                    body: response.body,
                    all_providers: response.all_providers,
                    providers_find_type: response.find_providers_type ? response.find_providers_type :
                        props.option === "class" ? "BY_CLASSIFICATION_IDS" : props.option === "providers" ? "BY_PROVIDER_IDS" : "ALL_PROVIDERS",
                } as AnnouncementRequest);
                setSelectedProviders(response.providers || []);
                setProvidersClassifications(response.providers?.length);
                setSelectedClassifications(response.provider_classification_ids || []);
                setIsActive(response.status === "ACTIVE");
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
        } else {
            setRequest({
                start_date: new Date(),
                end_date: new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()),
                status: "CREATED",
                title: "",
                body: "",
                all_providers: props.option === "all",
                providers_find_type: props.option === "class" ? "BY_CLASSIFICATION_IDS" : props.option === "providers" ? "BY_PROVIDER_IDS" : "ALL_PROVIDERS",
            } as AnnouncementRequest);
            setStatus("loaded");
        }
    };

    useEffect(load, [props]);

    const onSwitchAllProviders = () => {
        if (!request) return;
        setRequest({ ...request, all_providers: !request.all_providers })
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (request && date) {
            setRequest({ ...request, [name]: date });
        }
    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        if (!request) return;
        setRequest({ ...request, [name]: value });
    };

    const onChangeEditor = (contentState: RawDraftContentState) => {
        if (!request) return;
        setRequest({ ...request, body: JSON.stringify(contentState, null, 4) });
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedProviders(selected);
    };

    const setSelectionClassificationsHandler = (selected: string[], noProviders: number) => {
        setProvidersClassifications(noProviders);
        setSelectedClassifications(selected);
    };

    const next = () => {
        if (!request) return;
        if (request.all_providers || (request.providers_find_type === "BY_PROVIDER_IDS" && selectedProviders && selectedProviders.length > 0) ||
            (request.providers_find_type === "BY_CLASSIFICATION_IDS" && selectedClassifications && selectedClassifications.length > 0)) {
            setView("body");
        } else {
            if (request.providers_find_type === "BY_CLASSIFICATION_IDS") {
                setWarning(translate("providers.announcements.warning.classifications") as string);
            } else {
                setWarning(translate("providers.announcements.warning.providers") as string);
            }
        }

    };

    const onSave = () => {
        if (!request) return;
        if (validations(request, selectedProviders, selectedClassifications)) {
            if (!request.all_providers) {
                request.provider_ids = selectedProviders.map(id => id as string);
                request.classification_ids = selectedClassifications;
            }
            request.status = "CREATED";
            setSubmitting(true);
            createAnnouncement(props.tenantId, request).then((response) => {
                props.onSave();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    const onUpdate = () => {
        if (!request || !props.announcementId) return;

        if (validations(request, selectedProviders, selectedClassifications)) {
            if (!request.all_providers) {
                request.provider_ids = selectedProviders.map(id => id as string);
                request.classification_ids = selectedClassifications;
            }
            request.status = "CREATED";
            setSubmitting(true);
            updateAnnouncement(props.tenantId, props.announcementId, request).then((response) => {
                props.onSave();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    const onSend = () => {
        if (!request) return;
        if (validations(request, selectedProviders, selectedClassifications)) {
            setOpenConfirmSend(true);
        }
    };

    const onConfirmSend = () => {
        setOpenConfirmSend(false);
        if (!request) return;
        if (!request.all_providers) {
            request.provider_ids = selectedProviders.map(id => id as string);
            request.classification_ids = selectedClassifications;
        }
        request.status = "ACTIVE";
        setSubmitting(true);
        if (props.announcementId) {
            updateAnnouncement(props.tenantId, props.announcementId, request).then((response) => {
                props.onSend();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            createAnnouncement(props.tenantId, request).then((response) => {
                props.onSend();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }

    };

    const onCancelSend = () => {
        setOpenConfirmSend(false);
    };

    const validations = (request: AnnouncementRequest, selectedProviders: (string | number)[], classifications: string[]) => {
        if (request.title === "") {
            setWarning(translate("providers.announcements.warning.title") as string);
            return false;
        }
        if (request.body === "") {
            setWarning(translate("providers.announcements.warning.body") as string);
            return false;
        }
        if (request.start_date < today) {
            setWarning(translate("providers.announcements.warning.dates_today") as string);
            return false;
        }
        if (request.end_date < request.start_date) {
            setWarning(translate("providers.announcements.warning.dates") as string);
            return false;
        }
        return request.end_date && request.start_date &&
            (request.all_providers
                || (request.providers_find_type === "BY_PROVIDER_IDS" && selectedProviders.length > 0)
                || (request.providers_find_type === "BY_CLASSIFICATION_IDS" && classifications.length > 0));
    };

    const goToProviders = () => {
        if (view !== "body" && request && (request.title || request.body)) {
            setView("body");
        } else {
            setView(request?.providers_find_type === "BY_PROVIDER_IDS" ? "providers" : "class");
        }
    };

    const goToPreview = () => {
        if (!request) return;
        if (validations(request, selectedProviders, selectedClassifications)) {
            setView("preview");
        }
    };

    const onWarningClassification = (message: string) => {
        setWarning(message);
    }

    const onBackTo = () => {
        if (view !== "body" && request && (request.title || request.body)) {
            setView("body");
        } else {
            props.onBackToOptions();
        }
    }

    if (status === "loading") {
        return <></>;
    }

    return (
        <DialogPopup title={translate(props.announcementId ? "providers.announcements.edit" : "providers.announcements.new", { variant: `/ ${(view === "providers" ? translate("providers.announcements.options.specific_providers") : view === "class" ? translate("providers.announcements.options.by_classifications") : translate("providers.announcements.options.all_providers"))}` })} open
            onClose={(view !== "body") || !props.announcementId ? onBackTo : props.onClose} closeText={(view === "body" || view === "preview") && props.announcementId ? translate("providers.announcements.close_without_save") as string : translate("buttons.prev") as string} maxWidth={view === "preview" ? undefined : "md"}
            button={
                <Grid container spacing={view === "providers" && request ? 0 : 1}>
                    {view === "providers" || view === "class" ?
                        <Grid item >
                            <Button onClick={next} variant="contained" color="primary" size="medium" disabled={submitting}>
                                {translate(props.announcementId ? "providers.announcements.go_to_body" : "buttons.sig")}
                            </Button>
                        </Grid>
                        : view === "body" || view === "all" ?
                            <>
                                {!isActive &&
                                    <Grid item >
                                        <Button onClick={props.announcementId ? onUpdate : onSave} variant="contained" color="primary" size="medium" disabled={submitting}>
                                            {translate("buttons.save")}
                                        </Button>
                                    </Grid>
                                }
                                <Grid item >
                                    <Button onClick={goToPreview} variant="contained" color="primary" size="medium" disabled={submitting}>
                                        {translate("providers.announcements.prepare_send")}
                                    </Button>
                                </Grid>
                            </>
                            : view === "preview" ?
                                <Grid item >
                                    <Button onClick={onSend} variant="contained" color="primary" size="medium" disabled={submitting}>
                                        {translate("buttons.send")}
                                    </Button>
                                </Grid>
                                : undefined}
                </Grid>
            }
        >
            {view === "providers" && request ?
                <SelectProviders request={request} tenantId={props.tenantId} onSwitch={onSwitchAllProviders}
                    handleChecks={setSelectionHandler} selectedCfdis={selectedProviders} />
                : (view === "body" || view === "all") && request ?
                    <AnnouncementForm onChange={hasChanged} onChangedDate={onChangedDate}
                        onChangeEditor={onChangeEditor} submitting={submitting}
                        request={request} num_providers={request.providers_find_type === "BY_PROVIDER_IDS" ? selectedProviders.length : providersClassifications}
                        num_classifications={selectedClassifications.length} onReturnProviders={goToProviders}
                    />
                    : view === "preview" && request ?
                        <AnnouncementPreview
                            request={request} num_providers={request.providers_find_type === "BY_PROVIDER_IDS" ? selectedProviders.length : providersClassifications}
                            num_classifications={selectedClassifications.length} onReturnProviders={goToProviders}
                        />
                        : view === "class" && request &&
                        <SelectClassificationsPopUp
                            tenantId={props.tenantId}
                            classifications={selectedClassifications}
                            onSuccess={setSelectionClassificationsHandler}
                            onWarning={onWarningClassification}
                            onClose={props.announcementId ? goToProviders : onBackTo}
                        />
            }
            {request && openConfirmSend &&
                <ConfirmationPopup
                    title={translate("providers.announcements.confirm_send.title") as string}
                    message={translate(request.all_providers ? "providers.announcements.confirm_send.message_all" :
                        "providers.announcements.confirm_send.message",
                        { "providers": selectedProviders.length > 0 ? selectedProviders.length : selectedClassifications.length, "end_date": formatDateToShow(new Date(request.end_date)) }) as string}
                    secondary={translate("providers.announcements.confirm_send.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelSend}
                    doAction={onConfirmSend}
                />
            }
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
        </DialogPopup>);
}

interface SelectProvidersProps {
    tenantId: string;
    request: AnnouncementRequest;
    selectedCfdis: (number | string)[];
    onSwitch(): any;
    handleChecks(selected: (number | string)[]): any;
}

function SelectProviders(props: SelectProvidersProps) {
    const [providers, setProviders] = useState<Providers>({} as Providers);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [error, setError] = useState<string>();
    const [params, setParams] = useState<ProvidersQueryParams>({ classification: "", search: "", validator_user_id: "", status: "ENABLED", } as ProvidersQueryParams);

    const paramsToFilters = (): Filter[] => {
        return [
            { columnName: 'name', value: params.search },
        ] as Filter[];
    };

    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const load = () => {
        getProviders(props.tenantId, pageSize, pageSize * page, params).then((response) => {
            setProviders(response);
        }).catch((error) => {
            setError(error.message);
        });
    };

    useEffect(load, [props, page, pageSize, filters]);

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "name":
                        temp.search = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const [columnsFormat] = useState([
        { columnName: 'name', wordWrapEnabled: true },
    ]) as any;

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const columns = [
        {
            name: 'name',
            title: translate('providers.name') as string
        },
    ];

    const textColumns = ['name'];

    return (
        <Grid>
            <Grid item xs={12}>
                <GridDx
                    loading={false}
                    rows={providers.items ? providers.items : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={providers.total ? providers.total : 0}
                    columns={columns ? columns : []}
                    columnsFormat={columnsFormat}
                    textColumns={textColumns}
                    onClickRow={() => { }}
                    onClickedOptions={() => { }}
                    filters={filters}
                    selectionIds={props.selectedCfdis}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    setFiltersHandler={setFiltersHandler}
                    setSelectionHandler={props.handleChecks}
                    heightTablePX={500}
                />
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Grid>
    );
}

interface AnnouncementFormProps {
    request: AnnouncementRequest;
    num_providers: number;
    num_classifications: number;
    submitting: boolean;
    onChange(name: string, value: string | number, inputRef: InputRef): any;
    onChangedDate(name: string, raw?: string, date?: Date): any;
    onChangeEditor(contentState: RawDraftContentState): any;
    onReturnProviders(): any;
}

function AnnouncementForm(props: AnnouncementFormProps) {
    return (
        <Grid container spacing={1}>
            {!props.request.all_providers ?
                <Grid item xs={12}>
                    <IconButton aria-label="options" color="primary" size="small"
                        onClick={props.onReturnProviders} >
                        <Typography variant="body2">
                            {translate(props.request.all_providers ? "providers.announcements.providers_all"
                                : (props.request.providers_find_type === "BY_PROVIDER_IDS" ? "providers.announcements.providers_selected" : "providers.announcements.classifications_selected"), { "providers": props.num_providers, "categories": props.num_classifications })}
                        </Typography>
                        <ArrowForwardIcon style={{ "width": "15px", "height": "15px", "padding": "0px" }} />
                    </IconButton>
                </Grid>
                : undefined
            }
            <Grid item xs={6}>
                <DatePicker label={translate("providers.announcements.columns.start_date") as string}
                    name="start_date"
                    initial={props.request.start_date ? moment(props.request.start_date).format("DD/MM/YYYY")
                        : moment().format("DD/MM/YYYY")}
                    onChange={props.onChangedDate}
                    required={true}
                    defaultIfMissing={false} clearable={false} autoOK disablePast={false}
                    disableFuture={false} disabled={props.submitting} />
            </Grid>
            <Grid item xs={6}>
                <DatePicker label={translate("providers.announcements.columns.end_date") as string}
                    name="end_date"
                    initial={props.request.end_date ? moment(props.request.end_date).format("DD/MM/YYYY")
                        : moment().format("DD/MM/YYYY")}
                    onChange={props.onChangedDate}
                    required={true}
                    defaultIfMissing={false} clearable={false} autoOK disablePast={false}
                    disableFuture={false} disabled={props.submitting} />
            </Grid>
            <Grid item xs={12}>
                <ValidatedInput type="text" id="title" name="title"
                    value={props.request.title} label={translate("providers.announcements.columns.title") as string}
                    required={true} disabled={props.submitting}
                    margin="dense"
                    onValueChanged={props.onChange} />
            </Grid>
            <Grid item xs={12} style={{ border: "1px solid #e0e0e0" }}>
                <Editor value={props.request.body} onChange={props.onChangeEditor} />
            </Grid>
        </Grid>
    );
}

interface AnnouncementPreviewProps {
    request: AnnouncementRequest;
    num_providers: number;
    num_classifications: number;
    onReturnProviders(): any;
}

function AnnouncementPreview(props: AnnouncementPreviewProps) {
    return (
        <Grid container spacing={1} >
            <Grid item xs={12}>
                <Typography variant="body2">
                    {translate(props.request.all_providers ? "providers.announcements.preview.providers_all"
                        : "providers.announcements.preview.providers_selected", { "providers": props.num_providers, "categories": props.num_classifications })}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <IconButton aria-label="options" color="primary" size="small"
                    onClick={props.onReturnProviders} >
                    <Typography variant="body2">
                        {translate(props.request.all_providers ? "providers.announcements.preview.providers_all_link"
                            : "providers.announcements.preview.providers_selected_link", { "providers": props.num_providers })}
                    </Typography>
                </IconButton>
            </Grid>
            <Grid item xs={6}>
                <ListItemText secondary={
                    translate("providers.announcements.center.dates",
                        {
                            "end_date": formatDateToShow(new Date(props.request.end_date)),
                            "start_date": formatDateToShow(new Date(props.request.start_date))
                        }) as string
                } />
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined" >
                    <Box px={1} >
                        <ListItemText primary={props.request.title} />
                    </Box>
                </Card>
                <Card variant="outlined" >
                    <Box px={1} >
                        <Editor readOnly={true} value={props.request.body} />
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12}>

            </Grid>
        </Grid>
    );
}