import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import DateFormat from "../components/DateFormat";
import { listProviderExpedientsHistory, getFilesExpedient } from "../api/ProviderExpedientHistoryAPI";
import { ProviderExpedientsHistory, ProviderExpedientHistoryParams } from "../model/ProviderExpedientHistory";
import Ellipsis from "../components/Ellipsis";
import { Grid, Button, Divider, Typography } from "@material-ui/core";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { initialPageSize, initialPageZero } from "../components/Pagination";
import { DataTypeProviderProps, DataTypeProvider } from '@devexpress/dx-react-grid';
import { DownloadIcon } from "../components/Icons";
import DateRange from "../components/DateRange";
import { ErrorSnackbar } from "../components/Snackbars";

interface ProviderExpedientHistoryPopupProps {
    tenantId: string;
    providerId: string;
    onSelectExpedient(id: string): any;
    onClose(): any;
}

export default function ProviderExpedientHistoryPopup(props: ProviderExpedientHistoryPopupProps) {
    const [status, setStatus] = useState<string>("loading");
    const [historyExpedients, setHistoryExpedients] = useState<ProviderExpedientsHistory>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [workingParams, setWorkingParams] = useState<ProviderExpedientHistoryParams>({
        "start_date": new Date(),
        "end_date": new Date(),
    });
    const [error, setError] = useState<string>();

    

    const getHistoryExpedient = (params: ProviderExpedientHistoryParams, pag: number) =>{
        listProviderExpedientsHistory(props.tenantId, props.providerId, params, pageSize, pag).then((response) => {
            setHistoryExpedients(response);            
        }).catch((error) => {
            setStatus(error.message);
        });
        setStatus("loaded");
    }

    useEffect(() => {
        setStatus("loading");
        getHistoryExpedient({} as ProviderExpedientHistoryParams, page);
        // eslint-disable-next-line
    }, [props]);

    const [tableColumnExtensionsP] = useState([
        { columnName: 'created_at', width: 180, wordWrapEnabled: true },
        { columnName: 'event', width: 200, wordWrapEnabled: true },
        { columnName: 'previous_status', width: 100, wordWrapEnabled: true },
        { columnName: 'user.name', align: 'right' },
        { columnName: 'status', align: 'right' },
        { columnName: 'download', align: 'center' },

    ]) as any;

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPluginsFiltersExlude = [
        <NormalTypeProvider for={['created_at', 'event', 'previous_status', 'user.name', 'status', 'download']} editorComponent={NoFilterEditor} />
    ];

    const downloadFiles = (historyId: string) => {
        getFilesExpedient(props.tenantId, props.providerId, historyId).then(response => {
            window.open(response.url, "_blank");
         }).catch((error) => {            
             setError(translate(`expedients.not_files_in_expedient`) as string);
         });
    };


    const columns = [
        {
            name: 'created_at',
            title: translate("providers.expedient_history.created_at") as string,
            getCellValue: (history: any) => (<>
                <Button variant="text" size="small" color="primary" onClick={() => props.onSelectExpedient(history.id)}>
                    <DateFormat date={history.created_at} format="DD-MM-YYYY h:mm a" />
                </Button>
            </>)
        },
        {
            name: 'event',
            title: translate("providers.expedient_history.event_title") as string,
            getCellValue: (history: any) => (<Ellipsis text={translate("providers.expedient_history.event." + history.event) as string} lenght={100} uppercased={false} />)
        },
        {
            name: 'previous_status',
            title: translate("providers.expedient_history.previous_status") as string,
            getCellValue: (history: any) => (<Ellipsis text={history.previous_status ? translate("expedients.status_metadata_short." + history.previous_status) as string : "---"} uppercased={false} />)
        },
        {
            name: 'user.name',
            title: translate("users.impersonalize.modal.user") as string,
            getCellValue: (history: any) => (<Ellipsis text={history.user ? history.user.name : ""} uppercased={false} />)
        },
        {
            name: 'status',
            title: translate("providers.expedient_history.status") as string,
            getCellValue: (history: any) => (<Ellipsis text={history.status ? translate("expedients.status_metadata_short." + history.status) as string : "---"} uppercased={false} />)
        },
        {
            name: 'download',
            title: translate("expedients.download") as string,
            getCellValue: (history: any) => (<>
                <Button
                    color="primary" variant="text"
                    onClick={() =>downloadFiles(history.id)}
                    startIcon={<DownloadIcon />}>
                </Button>
            </>)
        }
    ];


    const onChangedPage = (page: number) => {
        setPage(page);
        setHistoryExpedients(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setHistoryExpedients(undefined);
    };

    const onChangeDateRange = (startDate: Date, endDate: Date) => {
        setWorkingParams({ ...workingParams, start_date: startDate, end_date: endDate });
    };

    const onAppliedFilter = () => {
        getHistoryExpedient(workingParams, 0);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };


    return (
        <DialogPopup
            open={true}
            title={translate("providers.expedient_history.title") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            disable={false}
            closeText={translate("buttons.close") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth={"md"}
        >
            <Grid container alignItems="center" justify="flex-start" spacing={1}>
                <Grid item xs={5}>
                    <Typography color="primary"><strong><Ellipsis text={translate(`expedients.single`) as string} uppercased={true} /></strong></Typography>
                </Grid>
                <Grid item xs={7}>
                    <Grid container alignItems="center" justify="flex-end" spacing={1} style={{ "paddingRight": 20 }}>
                        <Grid item xs={10}>
                            <DateRange
                                startDate={workingParams.start_date}
                                endDate={workingParams.end_date}
                                onChange={onChangeDateRange} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                                {translate("buttons.search")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                <Grid item xs={12} style={{ "padding": 0 }}>
                    <GridDx
                        loading={status === "loading"}
                        rows={historyExpedients ? historyExpedients.items : []}
                        columns={columns}
                        onClickRow={(v) => { }}
                        columnsFormat={tableColumnExtensionsP}
                        textColumns={['created_at', 'event', 'previous_status', 'user.name', 'status']}
                        page={page}
                        pageSize={pageSize}
                        totalRows={historyExpedients ? historyExpedients.items.length : 0}
                        customFormatColumns={customPluginsFiltersExlude}
                        onChangedPage={onChangedPage}
                        onChangedPageSize={onChangedPageSize}
                        heightTablePX={450}
                    />
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}