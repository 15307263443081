import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import DialogPopup from "../components/DialogPopup";
import { Button, Grid, Typography, Divider, Box } from '@material-ui/core';
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from '../components/ValidatedInput';
import { CalendarEvent, CalendarEventRequest } from '../model/Dashboard';
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import DateRange from "../components/DateRange";
import SketcjColorPicker from "../components/SketchColorPicker";
import Progress from "../components/Progress";
import { getCalendarEvent, saveCalendarEvent, updateCalendarEvent } from "../api/DashboardAPI";
import DatePicker from "../components/DatePicker";
import moment from "moment";


export interface CalendarEventProps {
    event?: CalendarEvent;
    calendarId: string;
    onSubmit(request: any): void;
    onClose(): void;
    currentYear: string;
}

export default function CalendarEventForm(props: CalendarEventProps) {
    const supportedEvent = ["PAYMENT_PROVIDER", "END_RECEPTION_INVOICES_MONTH", "PUE_INVOINCES_LIMIT_MONTH", "CUSTOM"];
    const tenantId = useContext(AppContext).session!.tenant!.id;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [typeOptions, setTypeOptions] = useState<string[]>([]);
    const [typeLabels, setTypeLabels] = useState<string[]>();
    const [request, setResquest] = useState<CalendarEventRequest>({} as CalendarEventRequest);
    const [status, setStatus] = useState<string>("loading");

    const hasChangedType = (name: string, value: string, inputRef: InputRef) => {
        if (!value || "---" === value) {
            setResquest({ ...request, type: "" });
            return;
        }
        setResquest({ ...request, type: value });
    };

    const hasChangedLabel = (name: string, value: string, inputRef: InputRef) => {
        if (!value) {
            return;
        }
        setResquest({ ...request, label: value });
    };

    const onChangeDateRange = (startDate: Date, dateTo?: Date) => {
        setResquest({
            ...request, date: maskStartDate(startDate),
            end_date: dateTo ? maskStartDate(dateTo) : undefined
        });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const newDate = () => {
        const date = new Date();
        const year = props.currentYear ? Number(props.currentYear) : date.getFullYear();
        date.setFullYear(year, date.getMonth(), date.getDate());
        return date;
    }

    const onInit = () => {
        setTypeLabels(supportedEvent.map((e) => {
            return (translate(`calendar_events.${e}.label`) as string);
        }));
        setTypeOptions(supportedEvent);
        if (props?.event) {
            getCalendarEvent(tenantId, props.event.id).then((response) => {
                response.date = maskStartDate(new Date(response?.date));
                response.end_date = response?.end_date ? maskStartDate(new Date(response?.end_date)) : undefined;
                setResquest(response);
            }).catch((e) => {
                setError(e.message);
            }).finally(() => {
                setStatus("loaded");
            })
        } else {
            setResquest({ date: newDate(), end_date: newDate() } as CalendarEventRequest);
            setStatus("loaded");
        }
    }

    useEffect(onInit, [props]);

    const upsert = () => {

        if (!request.label) {
            setWarning(translate("calendar_events.label_required") as string);
            return;
        }

        if (!request.type) {
            setWarning(translate("calendar_events.type_required") as string);
            return;
        }

        if (!request.color) {
            setWarning(translate("calendar_events.color_required") as string);
            return;
        }

        setSubmitting(true);
        const promise = (props?.event) ? updateCalendarEvent(tenantId, props?.event.id, request)
            : saveCalendarEvent(tenantId, props?.calendarId, request);

        promise.then((response) => {
            props.onSubmit(response);
        }).catch((e) => {
            let message;
            if ("3504" === e.code+"" ) {
                message = translate(`errors.codes.${e.code}`,
                    {
                        "0": translate(`calendar_events.${request.type}.label`),
                        "1": moment(request.date).format("DD/MM/YYYY")
                    }) as string;
            }
            if ("3505" === e.code+"") {
                message = translate(`errors.codes.${e.code}`,
                    {
                        "0": translate(`calendar_events.${request.type}.label`),
                        "1": moment(request.date).format("MMMM")
                    }) as string;
            }
            if (message) {
                setWarning(message);
                return 
            }
            setError(e.message);
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const maskStartDate = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    return (
        <DialogPopup open
            title={translate(props?.event ? "calendar_events.form.edit_event" : "calendar_events.form.new")}
            button={
                <Button onClick={upsert} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.save")}
                </Button>
            }
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default">
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && (
                <Grid container>
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="type" name="type"
                            value={request.type}
                            label={translate("calendar_events.form.event_type") as string}
                            required={true}
                            disabled={submitting}
                            options={typeOptions}
                            optionLabels={typeLabels}
                            margin={"dense"}
                            onValueChanged={hasChangedType}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {"CUSTOM" !== request?.type ?
                            <DatePicker label={translate("calendar_events.form.date") as string}
                                name="date"
                                initial={request.date ? moment(request.date).format("DD/MM/YYYY")
                                    : moment().format("DD/MM/YYYY")}
                                onChange={(name: string, raw: string, date: Date, inputRef?: InputRef | undefined) => {
                                    if (date) {
                                        onChangeDateRange(date, undefined);
                                    }
                                }}
                                required={true}
                                defaultIfMissing={false} clearable={false} autoOK disablePast={false}
                                disableFuture={false} disabled={submitting} />
                            : <DateRange
                                title={translate("calendar_events.form.dates") as string}
                                startDate={request?.date ? new Date(request?.date) : newDate()}
                                endDate={request?.end_date ? new Date(request?.end_date) : newDate()}
                                onChange={onChangeDateRange}
                                key={"date-range"}
                            />}
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            onValueChanged={hasChangedLabel}
                            type="text" id="label" name="label"
                            label={translate("calendar_events.form.description") as string}
                            value={request?.label}
                            required={true}
                            margin={"dense"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" color="secondary" paragraph>
                            {(!!request?.type) ? translate(`calendar_events.${request?.type}.description`) as string : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={2}>
                            <Divider />
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={1}>
                            <Grid item >
                                <Typography variant="body2" paragraph>
                                    {translate("calendar_events.form.color_identifier") as string}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SketcjColorPicker props={{
                                    color: request?.color,
                                    onSelectColor: (color: any) => {
                                        setResquest({ ...request, color: color ?? "" });
                                    }
                                }} ></SketcjColorPicker>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>)}
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />

        </DialogPopup >
    );
}
