import React, { useContext, useState } from "react";
import { Menu, ListItemIcon, Fade, Grid, ListItemText, List, ListItem, Icon } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import DownloadListWithProrrate from '@material-ui/icons/TocTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import ExportProrateCfdiMenu from "./ExportProrateCfdiMenu";
import DownloadValidationsPdfOption from "./DownloadValidationsPdfOption";

interface CfdisMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDownloadSelected(): any;
    onDownloadAll(): any;
    onDownloadExcelSelected(): any;
    onDownloadExcel(): any;
    onExportCfdis(): any;
    onExportForErp?(): any;
    onExportForErpAll?(): any;
    onDownloadProratedExcelSelected?(): void;
    onDownloadProratedExcel?(): void;
    onDownloadValidationsPdf?(): void;
    onDownloadValidationsPdfSelected?(): void;
}

export default function CfdisMenu(props: CfdisMenuProps) {
    const context = useContext(AppContext);

    const [openDownloadCfdi, setOpenDownloadCfdi] = useState<boolean>(false);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openExportZip, setOpenExportZip] = useState<boolean>(false);

    const handleClickDownloadCfdi = () => {
        setOpenDownloadCfdi(!openDownloadCfdi);
    };

    const handleClickExportExcel = () => {
        setOpenExportExcel(!openExportExcel);
    };

    const handleClickExportZip = () => {
        setOpenExportZip(!openExportZip);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickDownloadCfdi} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.download_cfdi")} />
                            {openDownloadCfdi ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openDownloadCfdi} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadAll} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
            {context.isGranted("ZipDaarRead") && (props.onExportForErp || props.onExportForErpAll) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportZip} dense>
                            <ListItemIcon>
                                <ImportExportIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.export_for_erp")} />
                            {openExportZip ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportZip} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                {props.onExportForErp && (
                                    <ListItem button onClick={props.onExportForErp} dense >
                                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                            <Selected fontSize="small" color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={translate("cfdis.menus.selected")} />
                                    </ListItem>
                                )}
                                {props.onExportForErpAll && (
                                    <ListItem button onClick={props.onExportForErpAll} dense >
                                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                            <DownloadList fontSize="small" color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto">
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.export_excel")} />
                            {openExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportExcel} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadExcelSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadExcel} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                                {context.session && context.session.tenant && context.session.tenant.uses_allocation ?
                                    <ListItem button onClick={props.onExportCfdis} dense >
                                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                            <DownloadListWithProrrate fontSize="small" color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={translate("cfdis.menus.all_this_view_proration")} />
                                    </ListItem>
                                    : undefined}
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }

            {(context.isGrantedAny(["CfdiProratedReport"]) && props.onDownloadProratedExcel && props.onDownloadProratedExcelSelected) &&
                <ExportProrateCfdiMenu onDownloadProratedExcel={props.onDownloadProratedExcel} onDownloadProratedExcelSelected={props.onDownloadProratedExcelSelected}></ExportProrateCfdiMenu>
            }

            {context.isGrantedAny(["AdminCFDIsRead", "ProviderCFDIsRead", "ReceptorCFDIsRead",
                "SenderCFDIsRead", "CxpCFDIsRead", "CfdisIssuedRead", "TenantCfdisIssuedRead", "ProviderCfdisIssuedRead"]) &&
                <DownloadValidationsPdfOption
                    onDownload={props.onDownloadValidationsPdf}
                    onDownloadSelected={props.onDownloadValidationsPdfSelected}
                />
            }
        </Menu>
    );
}