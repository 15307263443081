import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import EmployerNumberIcon from "@material-ui/icons/SettingsInputSvideo";
import DeleteIcon from "@material-ui/icons/Delete";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { get, updateEmployer } from "../api/TenantConfigurationApi";
import { get as providerGet, updateEmployer as providerUpdateEmployer } from "../api/ProviderConfigurationApi";
import { EmployerConfiguration, UpdateEmployerRequest } from "../model/Employer";
import Gridable from "../components/Gridable";
import ValidatedInput, { isValid, InputRef } from "../components/ValidatedInput";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";

interface NumberRequest {
    value: string;
}

export default function EmployerNumberForm() {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;

    const [status, setStatus] = useState("loading");
    const [submitting, setSubmitting] = useState(false);
    const [request, setRequest] = useState<UpdateEmployerRequest>({ employer_numbers: [] } as UpdateEmployerRequest);
    const [formRequest, setFormRequest] = useState<NumberRequest>({} as NumberRequest);
    const [validations, setValidations] = useState({} as any);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    const promiseGet = (): Promise<EmployerConfiguration> => {
        if (providerId) {
            return providerGet(tenantId, providerId);
        }
        return get(tenantId);
    };

    const load = () => {
        setStatus("loading");
        promiseGet().then(response => {
            setRequest(req => {
                return { ...req, employer_numbers: response.employer_numbers || [] as string[] };
            });
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId]);

    const onFilterChanged = (name: string, value: string, inputRef: InputRef) => {
        setFormRequest({ ...formRequest, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onDelete = (index: number) => {
        const values = request.employer_numbers.filter((el, i) => i !== index);
        setRequest({ ...request, employer_numbers: values });
    };

    const onAdd = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        const values = request.employer_numbers.map(el => el);
        if (values.indexOf(formRequest.value) >= 0) {
            setError(translate("employer.duplicated") as string);
            return;
        }

        values.push(formRequest.value);

        setRequest({ ...request, employer_numbers: values });
        setFormRequest({ ...formRequest, value: "" });

        Object.values(validations).map(el => el as InputRef).forEach(ref => ref.blurer(false));
    };

    const promiseUpdate = () => {
        if (providerId) {
            return providerUpdateEmployer(tenantId, providerId, request);
        }
        return updateEmployer(tenantId, request);
    };

    const onUpdate = () => {
        setSubmitting(true);
        promiseUpdate().then(response => {
            setSuccess(translate("employer.updated") as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("employer.title")} icon={<EmployerNumberIcon />} className="FormSurface">
                        <Grid container>
                            <Grid item xs={12}>
                                <Box pb={1} mt={-2}>
                                    <form autoComplete="off" noValidate onSubmit={onAdd}>
                                        <Grid container alignItems="center" justify="flex-end" spacing={1}>
                                            <Grid item xs sm={4} lg={5}>
                                                <ValidatedInput type="text" id="value" name="value" label={translate("employer.number") as string}
                                                    margin="dense" required disabled={submitting}
                                                    maxLength={12}
                                                    value={formRequest.value} onValueChanged={onFilterChanged} />
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button type="submit" variant="outlined" color="primary" size="medium" disabled={submitting}>
                                                    {translate("buttons.add")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mx={-2}>
                                    <Gridable items={request.employer_numbers}
                                        loading={status === "loading"}
                                        error={status === "loading" || status === "loaded" ? undefined : status}
                                        empty={translate("employer.empty") as string}
                                        columns={[
                                            {
                                                title: translate("employer.title"),
                                                converter: (n) => n,
                                                fullWidth: true,
                                                xs: true,
                                            },
                                            {
                                                title: (
                                                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                ),
                                                converter: (customer, index) => (
                                                    <IconButton aria-label="options" color="default" size="small" onClick={() => onDelete(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                ),
                                                fullWidth: true,
                                                justify: "flex-end",
                                                xs: "auto"
                                            }
                                        ]} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                        <Grid item xs={12} md="auto">
                                            <Button variant="contained" color="primary" size="large" onClick={onUpdate} disabled={submitting}>
                                                {translate("buttons.update")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Grid>
    );
}