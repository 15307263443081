import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import InvoiceIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";
import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import { getTenantCfdiFileUrl } from "../api/TenantCfdiApi";
import { getCfdiFileUrl } from "../api/ProviderCfdiApi";

interface CfdiIssuedMenuProps {
    cfdi: Cfdi;
    anchor: HTMLElement | null;
    isProvider: boolean;
    inDetails: boolean
    onDelete(): any;
    onClose(): any;
}

export default function CfdiIssuedMenu(props: CfdiIssuedMenuProps) {
    const context = useContext(AppContext);
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();

    useEffect(() => {
        if (props.isProvider) {
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "xml").then((urlFileProvider) => {
                setXmlUrl(urlFileProvider);
            }).catch((error) => {
                setXmlUrl(undefined);
            });
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "pdf").then((urlFileProvider) => {
                setPdfUrl(urlFileProvider);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        } else {
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "xml").then((urlFile) => {
                setXmlUrl(urlFile);
            }).catch((error) => {
                setXmlUrl(undefined);
            });
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
    }, [context.session, props.cfdi, props.isProvider]);

    return (
        <Menu
            id="contributions"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["CfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={xmlUrl || ""} disabled={!xmlUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CfdisIssuedRead", "ProviderCfdisIssuedRead", "TenantCfdisIssuedRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={pdfUrl || ""} disabled={!pdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                </MenuItem>
            }
            {!props.inDetails && props.cfdi.metadata.status !== "SE_PREPARING" && (
                <MenuItem button component={Link} to={`/cfdis/se/${props.cfdi.id}/details`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <InfoIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_details")}</Typography>
                </MenuItem>
            )}
            {context.isGrantedAny(["CfdisIssuedCreate", "ProviderCfdisIssuedCreate"]) && props.cfdi.metadata.status === "SE_PREPARING" && (
                <MenuItem button component={Link} to={`/cfdis/se/${props.cfdi.id}/prepare`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <InvoiceIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis_se.prepare_button")}</Typography>
                </MenuItem>
            )}
            {context.isGrantedAny(["CfdisIssuedDelete", "ProviderCfdisIssuedDelete", "TenantCfdisIssuedDelete"]) && (props.cfdi.metadata.status === "SE_PREPARING" || props.cfdi.metadata.status === "SE_TO_SEND") && (
                <MenuItem button onClick={props.onDelete} disabled={props.cfdi.source_id !== undefined} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            )}
        </Menu>
    );

}