import React from "react";
import { Divider, Grid, Typography, IconButton, Button } from "@material-ui/core";
import { Cfdi } from "../model/Cfdi";
import NumberFormat from "react-number-format";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from "@material-ui/core/Tooltip";
import translate from "../i18n/Translator";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

interface CfdiDescriptionProps {
    cfdi: Cfdi;
    onClickRow?(id: string): any;
    onClickAdvancePayment?(advancePaymentId: string): void;
}

export function CfdiDescription(props: CfdiDescriptionProps) {
    return (
        <Grid container>
            <Grid item xs={12}>
                {props.onClickRow ?
                    <Button color="default" size="small" onClick={() => props.onClickRow ? props.onClickRow(props.cfdi.id) : undefined}>
                        {props.cfdi.identifier}
                    </Button>
                    :
                    <strong>{props.cfdi.identifier}</strong>
                }

                {props.cfdi.metadata.cancelled_by_sat ?
                    <IconButton style={{ color: "#C33149" }} >
                        <Tooltip title={translate("cfdis_cancelled.tooltip") as string}>
                            <HelpOutlineIcon />
                        </Tooltip>
                    </IconButton> : undefined}

                {props?.onClickAdvancePayment && (props.cfdi?.metadata?.payment_info?.advance_payments && props.cfdi?.metadata?.payment_info?.advance_payments?.length > 0) ?
                    <IconButton style={{ color: "#f59450" }} onClick={() => {
                        if (props?.onClickAdvancePayment) {
                            props!.onClickAdvancePayment(props.cfdi?.id);
                        }
                    }}>
                        <Tooltip title={translate("add_advance_payments.advance_paymet_related") as string}>
                            <LocalAtmIcon />
                        </Tooltip>
                    </IconButton> : undefined}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" component="h6" color="textSecondary">
                    {props.cfdi.description}
                </Typography>
                <Typography variant="caption" component="h6" color="textSecondary">
                    {props.cfdi.cfdi?.timbre.uuid || " -- "}
                </Typography>
            </Grid>
        </Grid>
    );
}

interface CfdiAmountsProps {
    cfdi: Cfdi;
    showBalance?: boolean;
}

export function CfdiAmounts(props: CfdiAmountsProps) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" component="h6" align="right">
                    <strong>
                        <NumberFormat value={props.cfdi.total}
                            prefix="$ " suffix={` ${props.cfdi.currency}`}
                            decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                            displayType="text" />
                    </strong>
                </Typography>
                <Divider variant="fullWidth" />
            </Grid>
            {props.showBalance && (
                <Grid item xs={12}>
                    <Typography variant="body2" component="h6" align="right" color="textSecondary">
                        S - <NumberFormat value={props.cfdi.metadata.balance}
                            prefix="$ " suffix={` ${props.cfdi.currency}`}
                            decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                            displayType="text" />
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography variant="body2" component="h6" align="right" color="textSecondary">
                    P - <NumberFormat value={props.cfdi.total - props.cfdi.metadata.balance}
                        prefix="$ " suffix={` ${props.cfdi.currency}`}
                        decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                        displayType="text" />
                </Typography>
            </Grid>
        </Grid>
    );
}