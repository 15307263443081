import React from "react";
import { Session, SessionBody, UserSecurity } from "../model/Session"

export const AppContext = React.createContext<AppContextData>({
    session: undefined,
    setSession: (session) => { },
    removeSession: () => { },
    onSignedIn: () => { },
    onChangedTenant: (body: SessionBody) => { },
    isGranted: (action: string) => { return false },
    isGrantedAny: (actions: string[]) => { return false }
});

export interface AppContextData {
    session?: Session;
    userSecurity?: UserSecurity;
    setSession(session?: Session): void;
    removeSession(): void;
    onSignedIn(): void;
    onChangedTenant(body: SessionBody): void;
    isGranted(action: string): boolean;
    isGrantedAny(actions: string[]): boolean;
};

export interface MyWindow extends Window {
    IntegrationApp: any;
}
