import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { ShippingTemplatesIcon } from "../components/Icons";

import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import OffIcon from "@material-ui/icons/ToggleOffTwoTone";
import OnIcon from "@material-ui/icons/ToggleOn";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { RequisitionTypeCatalog } from "../model/RequisitionTypeCatalog";

interface RequisitionTypeMenuProps {
    anchor: HTMLElement | null;
    requisitionType: RequisitionTypeCatalog;
    onClose(): any;
    onDelete(): any;
}

export default function RequisitionTypeMenu(props: RequisitionTypeMenuProps) {
    const context = useContext(AppContext);
    const isGrantedUpdate = context.isGranted("RequisitionsUpdate");
    const isGrantedDelete = context.isGranted("RequisitionsDelete");

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {isGrantedUpdate &&
                <MenuItem button component={Link} to={`/requisitions/types/${props.requisitionType.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {isGrantedUpdate && props.requisitionType.shipping_template_id &&
                <MenuItem button component={Link} to={`/requisitions/types/${props.requisitionType.id}/template`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <ShippingTemplatesIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("requisitions.types.template")}</Typography>
                </MenuItem>
            }
            {isGrantedDelete &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        {props.requisitionType.active ? <OffIcon fontSize="small" color="primary" /> : <OnIcon fontSize="small" color="primary" />}
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate(`requisitions.types.${props.requisitionType.active ? "desactivate.desactivate_option" : "activate.activate_option"}`)}
                    </Typography>
                </MenuItem>
            }
        </Menu>
    );
}