
import { Plan } from "./Plan";
import { Tenant } from "./Tenant";
import { User } from "./User";

export interface Billing {
    id: string;
    amount: number;
    status: string;
    transaction_id?: string;
    authorization?: string;
    attachment?: string;
    starts: Date;
    ends: Date;
    plan: Plan;
    tenant?: Tenant;
    owner?: User;
    gateway_enabled: boolean;
}

export interface Billings {
    items: Billing[];
    total: number;
}

export interface BillingMetadata {
    upgradable: boolean;
    billing?: Billing;
    plan: Plan;
    plans: Plan[];
    gateway: GatewayConfigurationResponse;
}

export interface GatewayConfigurationResponse {
    merchant_id: string;
    public_key: string;
    sandbox: boolean;
    enabled: boolean;
}

export interface BillingSubscriptionRequest {
    token_id: string;
}

export interface BillingManualPaymentRequest {
    url: string;
}

export interface BillingAuthorizationRequest {
    authorization: string;
}

export interface BillingUpgradeRequest {
    plan_id: string;
    billing_id?: string;
}

export interface BillingsQueryParams {
    search: string;
    status: string;
}

export const Statuses = ["EMPTY", "DONE", "REVIEW", "FAILED"];