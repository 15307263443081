import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { updateBasicProfile } from "../api/MeAPI";
import { User, BasicProfileRequest, MetadataResponse } from "../model/User";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { PhoneValidator, phoneFormat } from "../components/Validators";
import { getMetadata as getTenantMetadata } from "../api/TenantUserAPI";
import { getMetadata as getProviderMetadata } from "../api/ProviderUserAPI";
import { getMetadata as getCustomerMetadata } from "../api/CustomerUserAPI";
import { getMetadata as getAdminMetadata } from "../api/UserAPI";

export interface UserBasicProfileFormProps {
    user: User;
    onUpdated(user: User): any;
}

export default function UserBasicProfileForm(props: UserBasicProfileFormProps) {
    const context = useContext(AppContext);
    const margin = "dense";
    const phoneValidator = new PhoneValidator();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [format, setFormat] = useState("");
    const [request, setRequest] = useState<BasicProfileRequest>(() => {
        return {
            "first_name": "",
            "last_name": "",
            "phone": "",
            "language": "",
        } as BasicProfileRequest;
    });
    const [validations, setValidations] = useState({
        "first_name": {
            valid: false
        } as InputRef,
        "last_name": {
            valid: false
        } as InputRef,
        "phone": {
            valid: false
        } as InputRef
    } as any);
    const [languageIds, setLanguageIds] = useState<string[]>([]);
    const [languageNames, setLanguageNames] = useState<string[]>([]);

    const setMetadata = (metadata?: MetadataResponse) => {
        if (!metadata) return;
        setLanguageIds(metadata.languages.map((language) => language.id));
        setLanguageNames(metadata.languages.map((language) => language.description));
    }

    const metadataPromise = () => {
        const tenantId = context.session?.tenant?.id;
        if (tenantId) {
            if (context.session?.provider) {
                return getProviderMetadata(tenantId, context.session?.provider.id);
            }
            if (context.session?.customer) {
                return getCustomerMetadata(tenantId, context.session?.customer.id);
            }
            return getTenantMetadata(tenantId);
        }
        return getAdminMetadata();
    };

    useEffect(() => {
        metadataPromise().then((metadata) => {
            setMetadata(metadata);
        }).catch((error) => {
            setError(error.message);
        });

        setRequest({
            "first_name": props.user.first_name,
            "last_name": props.user.last_name,
            "phone": props.user.phone,
            "language": props.user.language ? props.user.language : "es",
        } as BasicProfileRequest);
        // eslint-disable-next-line
    }, [props.user]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedLaguage = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        setValidations(validations);
    };

    const hasChangedPhone = (name: string, value: string, inputRef: InputRef) => {
        hasChanged(name, value, inputRef);
        setFormat(phoneFormat(value));
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        if(request.language === '---'){
            setError(translate("users.language.error") as string);
            return;
        }

        setSubmitting(true);
        updateBasicProfile(request).then((user) => {
            setSuccess(translate("users.profile.updated_basic") as string);
            props.onUpdated(user);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit} >
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="first_name" name="first_name"
                        label={translate("users.first_name") as string} value={request.first_name}
                        onValueChanged={hasChanged} disabled={submitting} required
                        margin={margin} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="last_name" name="last_name"
                        label={translate("users.last_name") as string} value={request.last_name}
                        onValueChanged={hasChanged} disabled={submitting} required
                        margin={margin} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="phone" name="phone"
                        label={translate("users.phone") as string} value={request.phone}
                        onValueChanged={hasChangedPhone} disabled={submitting}
                        validator={phoneValidator}
                        format={format} mask=" "
                        margin={margin} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="language" name="language"
                        label={translate("users.language.title") as string}
                        value={request.language} onValueChanged={hasChangedLaguage}
                        required disabled={submitting}
                        margin={margin}
                        options={languageIds} optionLabels={languageNames}
                    />
                </Grid>
                <Grid item xs={12} className="ExternButton">
                    <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                        {translate("buttons.update")}
                    </Button>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </form>
    );
}