import { Grid, IconButton, Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useContext, useEffect, useState } from "react";
import Ellipsis from "../components/Ellipsis";
import Gridable, { GridableColumn } from "../components/Gridable";
import DialogPopup from "../components/DialogPopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { TenantSegment } from "../model/TenantFieldCatalog";
import { ProviderExpedient, ProviderExpedientBankAccount, ProviderExpedientSegment } from "../model/ProviderExpedient";
import { getIcon, getColor } from "./ExpedientSegmentStatus";
import DateFormat from "../components/DateFormat";
import { Entity } from "../model/Provider";
import { BankAccount } from "../model/BankAccount";

interface ExpedientProviderContactsProps<C extends TenantSegment, V, T extends ProviderExpedientSegment<V, C>> {
    metadata: ProviderExpedient;
    name: string;
    supplier(metadata: ProviderExpedient): T[];
    onUpdated(tenantId: string, providerId: string, current: T, value: V): Promise<ProviderExpedient>;
    onDeleted?(tenantId: string, providerId: string, current: T): Promise<ProviderExpedient>;
    onChange(metadata: ProviderExpedient): any;
    buildForm(current: T, onClose: () => any, onSubmitted: (value: V) => any, onDoDelete: () => any): React.ReactNode;
    buildPopup(current: T, onClose: () => any): React.ReactNode;
    buildData?(current: T): React.ReactNode;
    clear?(tenantId: string, providerId: string, elementId: string): Promise<ProviderExpedient>;
    disabledEdition: boolean;
    provider?: Entity;
}

export default function ExpedientProviderSegments<C extends TenantSegment, V, T extends ProviderExpedientSegment<V, C>>(props: ExpedientProviderContactsProps<C, V, T>) {
    const context = useContext(AppContext);
    const [data, setData] = useState<T[]>();
    const [current, setCurrent] = useState<T>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [editing, setEditing] = useState(false);
    const [readyDelete, setReadyDelete] = useState(false);
    const [openClearElement, setOpenClearElement] = useState(false);
    const prepare = (metadata: ProviderExpedient) => {
        let items = props.supplier(metadata);
        setData(items);

        props.onChange(metadata);
    };

    // eslint-disable-next-line
    useEffect(() => prepare(props.metadata), [props.metadata]);

    const modifyItem = (item: T) => {
        setCurrent(item);
        setEditing(true);
    };

    const confirmClearElement = () => {
        if(current){
            props.clear!(context.session!.tenant!.id, props.provider ? props.provider.id : context.session!.provider!.id, current!.configuration.id).then((response) => {
                prepare(response);
            }).catch((error)=>{
                if(error.code === 1616 ){
                    setError(translate(`errors.codes.${error.code}`, {"currency": (current.value as BankAccount).currency}) as string);
                } else {
                    setError(error.message);
                }
            }).finally(() => {
                setOpenClearElement(false);
                setCurrent(undefined);
            });
        }
    }

    const clearElement = (item: T) => {
        setCurrent(item);
        setOpenClearElement(true);
    }

    const onSubmitted = (value: V) => {
        if (!current) return;

        props.onUpdated(context.session!.tenant!.id, props.provider ? props.provider.id : context.session!.provider!.id, current, value).then((response) => {
            setSuccess(translate(`expedients.${props.name}.success_provider`) as string);
            prepare(response);
            setCurrent(undefined);
        }).catch((error) => {
            setError(error.message);
        })
    };

    const onDoDelete = () => {
        setReadyDelete(true);
    };

    const onCloseDelete = () => {
        setReadyDelete(false);
    };

    const onDeleted = () => {
        if (!current) return;

        if (props.onDeleted) {
            let providerId = context.session!.provider ? context.session!.provider!.id : props.provider ? props.provider.id : "";
            props.onDeleted(context.session!.tenant!.id, providerId, current).then((response) => {
                setSuccess(translate("shipping_templates.popup.delete_file.success_delete_file") as string);
                prepare(response);
                setReadyDelete(false);
                setCurrent(undefined);
            }).catch((error) => {
                setError(error.message);
            })
        }

    };

    const onClose = () => {
        setCurrent(undefined);
        setEditing(false);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const getColumns = (): GridableColumn<T>[] => {
        const columns = [
            {
                title: translate("expedients.segments.name") as string,
                converter: (item) => (
                    <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                        <Grid item xs="auto">
                            {getIcon(item.status, item.configuration.required, item.value)}
                        </Grid>
                        <Grid item xs style={{ color: getColor(item.status) }}>
                            <Ellipsis text={item.configuration.name} lenght={0} uppercased={false} />
                            <br />
                            <Ellipsis text={item.configuration.description ?? ""}
                                lenght={0} uppercased={false} secondary useInheritColor={true} />
                        </Grid>
                    </Grid>
                ),
                xs: 8,
                sm: 5,
                md: 3,
                lg: 3,
                xl: 3,
                fullWidth: true
            },
            {
                title: translate("expedients.segments.data") as string,
                converter: (item: T, index: number) => props.buildData ? props.buildData(item) : undefined,
                justify: "flex-start",
                xs: true,
                sm: 4,
                md: 2,
                lg: 2,
                xl: 2,
            },
            {
                title: translate("expedients.segments.comments") as string,
                converter: (item) => <span style={{ color: getColor(item.status) }}> {(item?.comments ?? "--")} </span>, xs: false,
                sm: 4,
                md: 2,
                lg: 2,
                xl: 3,
            },
            {
                title: translate("expedients.segments.updated") as string,
                converter: (item) => <span style={{ color: getColor(item.status) }}> {item.updated_at ? (<DateFormat date={item.updated_at} format="lll" />) : "---"} </span>,
                xs: false,
                sm: false,
                md: false,
                lg: true,
                xl: 1
            },
            {
                title: translate("expedients.segments.accepted") as string,
                converter: (item) => <span style={{ color: getColor(item.status) }}> {item.accepted_at ? (<DateFormat date={item.accepted_at} format="lll" />) : "---"} </span>,
                xs: false,
                sm: false,
                md: 2,
                lg: true,
                xl: 1
            },
            {
                title: translate("expedients.segments.expired") as string,
                converter: (item) => <span style={{ color: getColor(item.status) }}> {item.configuration.expires_at ? (<DateFormat date={item.configuration.expires_at} format="lll" />) : "---"} </span>,
                xs: false,
                sm: false,
                md: 2,
                lg: true,
                xl: 1
            },
            {
                title: "",
                converter: (item: T, index: number) => (
                    (!props.disabledEdition && (context.isGranted('ExpedientValidateAllFields') || !item.configuration.user_group_id || context.session!.provider)) ?
                        <Grid container alignItems="center">
                            <Grid item>
                                <IconButton aria-label="options" color="default"
                                    size="small" onClick={() => modifyItem(item)}
                                    disabled={props.disabledEdition && !item.value}>
                                    {item.value ? <EditIcon /> : <AddIcon />}
                                </IconButton>
                            </Grid>
                            {props.name != "fields" && (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={() => clearElement(item)}
                                    disabled={!item.value || Object.keys(item.value).length==0}>
                                        <ClearIcon/>
                                </IconButton>
                            )}
                        </Grid>
                        : undefined
                ),
                justify: "flex-start",
                xs: !props.disabledEdition ? true : false,
                sm: !props.disabledEdition ? true : false,
                md: !props.disabledEdition ? true : false,
                lg: !props.disabledEdition ? true : false,
            },
        ] as GridableColumn<T>[];

        return columns.filter(col => col != null);
    };

    return (
        <div className="PaperPagination">
            <Gridable
                items={data || []}
                loading={false}
                empty={translate(`expedients.${props.name}.empty`) as string}
                columns={getColumns()}
            />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            {current && editing && !openClearElement && (props.buildForm(current, onClose, onSubmitted, onDoDelete))}
            {current && !editing && !openClearElement && (props.buildPopup(current, onClose))}
            {current && editing && readyDelete &&
                <DialogPopup open
                    title={translate("shipping_templates.popup.delete_file.title_expedient") as string}
                    disable={false}
                    closeText={translate("buttons.cancel") as string}
                    onClose={onCloseDelete}
                    closeColor="default"
                    button={
                        <Button onClick={onDeleted} variant="outlined" color="primary" disabled={false}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    <Typography variant="body2">
                        {translate("shipping_templates.popup.delete_file.description_expedient")}
                    </Typography>
                </DialogPopup>
            }
            {openClearElement && (
                        <ConfirmationPopup
                            doAction={confirmClearElement}
                            onClose={() => {setOpenClearElement(false); setCurrent(undefined)}}
                            title={translate("expedients.clear_element.title") as string}
                            message={translate("expedients.clear_element.message", {"field_name":current!.configuration.name}) as string}
                            button={translate("buttons.accept") as string}
                        />
            )}
        </div>
    );

}