import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";

interface DialogPopupProps {
    title: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    children: React.ReactNode;
    open: boolean;
    onClose(): any;
    closeText?: string;
    closeColor?: "primary" | "secondary" | "inherit" | "default";
    variant?: "contained" | "outlined" | "text";
    button?: React.ReactNode;
    buttonLeft?: React.ReactNode;
    buttonSecondLeft?: React.ReactNode;
    disable?: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    fullWidth?: boolean;
    onSubmit?(event: React.FormEvent<HTMLFormElement>): any;
    footerItem?: React.ReactNode;
}

export default function DialogPopup(props: DialogPopupProps) {

    const actions = (
        <DialogActions>
            {props.buttonLeft && (
                <Grid item xs={props.button ? 4 : 8}>
                    <Grid container justify="flex-start" spacing={1}>
                        <Grid item xs="auto">
                            {props.buttonLeft}
                        </Grid>
                        {props.buttonSecondLeft && (
                            <Grid item xs="auto">
                                {props.buttonSecondLeft}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={props.buttonLeft ? 8 : 12}>
                <Box px={1}>
                    <Grid container justify={props.button ? (props.buttonLeft ? "flex-end" : "space-between") : "flex-end"} spacing={1}>
                        <Grid item xs="auto">
                            <Button onClick={props.onClose} variant={props.variant ?? "outlined"} color={props.closeColor ?? "primary"} disabled={props.disable}>
                                {props.closeText ? props.closeText : translate("buttons.close") as string}
                            </Button>
                        </Grid>
                        {props.button && (
                            <Grid item xs="auto">
                                {props.button}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Grid>
        </DialogActions>
    );

    return (
        <Dialog
            open={props.open}
            maxWidth={props.maxWidth || "sm"}
            disableBackdropClick={props.disableBackdropClick ?? true}
            disableEscapeKeyDown={props.disableEscapeKeyDown}
            onClose={props.onClose}
            fullWidth={props.fullWidth}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" disableTypography>
                <Typography variant="subtitle1">{props.title}</Typography>
                {props?.subTitle && (
                    <>
                        {props.subTitle}
                    </>
                )}
            </DialogTitle>
            {props.onSubmit && (
                <form autoComplete="off" noValidate onSubmit={props.onSubmit} >
                    <DialogContent className="DialogPopup" dividers>
                        {props.children}
                    </DialogContent>
                    {actions}
                </form>
            )}
            {!props.onSubmit && (
                <>
                    <DialogContent className="DialogPopup" dividers>
                        {props.children}
                    </DialogContent>
                    {props.footerItem !== undefined &&
                        <>
                            {props.footerItem}
                        </>
                    }
                    {actions}
                </>
            )}
        </Dialog>
    );
}