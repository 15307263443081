import React, { useContext } from "react";
import { List, ListItem, ListItemText, Card, Typography, Grid } from "@material-ui/core";
import NumberFormat from "react-number-format";
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/RemoveCircleTwoTone';

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Cfdi, CfdiMetadata, CfdiRaw, CfdiStatus_PENDING_PAYMENT, CfdiStatus_IN_PAYMENT_ORDER, CfdiStatus_PAID, CfdiStatus_CANCELLED, StatusGroup_VALIDATION, StatusGroup_IN_PROCESS } from '../model/Cfdi';
import { ExpensesAccount, Honorarium } from "../model/ExpensesAccount";

interface PayableDocumentTotalsProps {
    cfdi: Cfdi;
    referer?: string;
    onOpenUpdateAuthorizedAmount(): any;
    onDeleteAdditionalAmount(): any;
}

export default function PayableDocumentTotals(props: PayableDocumentTotalsProps) {
    const context = useContext(AppContext);

    return (
        <Card variant="outlined" >
            <List component="nav" dense >
                {context.isGranted("CFDIProrationUpdate") && props.cfdi.metadata.type === "INVOICE" && props.cfdi.metadata.cfdi_proration &&
                    props.cfdi.metadata.cfdi_proration.provider_need_ledger_assignment &&
                    <ListItem>
                        <ListItemText primary={translate("cfdis.details.subtotal_base")} />
                        <NumberFormat value={props.cfdi.metadata.subtotal_base} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                    </ListItem>
                }
                {props.cfdi.cfdi && (
                    <CfdiTotals cfdi={props.cfdi.cfdi} cfdiMetadata={props.cfdi.metadata} total_to_pay={props.cfdi.total}
                        cfdiId={props.cfdi.id} onOpenUpdateAuthorizedAmount={props.onOpenUpdateAuthorizedAmount} onOpenDeleteAdditionalAmount={props.onDeleteAdditionalAmount}
                        showTotalAuthorized={(context.isGrantedAny(["AuthorizedAmountCfdiUpdate", "AuthorizedAmountCfdiRead"])) &&
                            (props.cfdi.metadata.cfdi_status_group === StatusGroup_IN_PROCESS || props.cfdi.metadata.cfdi_status_group === StatusGroup_VALIDATION 
                                || props.cfdi.metadata.status === CfdiStatus_PENDING_PAYMENT )}
                        showDeleteAdditionalAmount={(props.cfdi.metadata.additional_amount && props.cfdi.metadata.type === "INVOICE" && props.cfdi.metadata.status !== CfdiStatus_IN_PAYMENT_ORDER && props.cfdi.metadata.status !== CfdiStatus_PAID
                            && props.cfdi.metadata.status !== CfdiStatus_CANCELLED && !props.cfdi.metadata.partially_payed)} enableUpdateAuthorized={context.isGranted("AuthorizedAmountCfdiUpdate") && props.referer !== "archive"}/>
                )}
                {props.cfdi.expenses_account && !props.cfdi.expenses_account.honorarium && (
                    <ListItem>
                        <ListItemText primary={translate("honorarium.total_expenses")} />
                        <NumberFormat value={props.cfdi.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                    </ListItem>
                )}
                {props.cfdi.expenses_account && props.cfdi.expenses_account.honorarium && (
                    <HonorariumTotals payableDocument={props.cfdi}
                        expensesAccount={props.cfdi.expenses_account}
                        honorarium={props.cfdi.expenses_account.honorarium} />
                )}
                {("FOREIGN" === props.cfdi.type || "FOREIGN_CREDIT_NOTE" === props.cfdi.type|| "REFUND" === props.cfdi.type || "ADVANCE_PAYMENT" === props.cfdi.type) && (
                    <NotCfdiTotals
                        total={props.cfdi.total} cfdiMetadata={props.cfdi.metadata} total_to_pay={props.cfdi.total}
                        cfdiId={props.cfdi.id} currency={props.cfdi.currency}
                        onOpenUpdateAuthorizedAmount={props.onOpenUpdateAuthorizedAmount} onOpenDeleteAdditionalAmount={props.onDeleteAdditionalAmount}
                        showTotalAuthorized={(context.isGrantedAny(["AuthorizedAmountCfdiUpdate", "AuthorizedAmountCfdiRead"]))
                            && (props.cfdi.metadata.cfdi_status_group === StatusGroup_IN_PROCESS
                                || props.cfdi.metadata.cfdi_status_group === StatusGroup_VALIDATION
                                || props.cfdi.metadata.status === CfdiStatus_PENDING_PAYMENT)
                        }
                        showDeleteAdditionalAmount={(props.cfdi.metadata.additional_amount && props.cfdi.metadata.type === "INVOICE" && props.cfdi.metadata.status !== CfdiStatus_IN_PAYMENT_ORDER && props.cfdi.metadata.status !== CfdiStatus_PAID
                            && props.cfdi.metadata.status !== CfdiStatus_CANCELLED && !props.cfdi.metadata.partially_payed)} enableUpdateAuthorized={context.isGranted("AuthorizedAmountCfdiUpdate") && props.referer !== "archive"} />)
                }
            </List>
        </Card>
    );
}

interface CfdiTotalsProps {
    cfdiId: string;
    cfdi: CfdiRaw;
    cfdiMetadata: CfdiMetadata;
    total_to_pay: number;
    onOpenUpdateAuthorizedAmount(): any;
    onOpenDeleteAdditionalAmount(): any;
    showTotalAuthorized?: boolean;
    showDeleteAdditionalAmount?: boolean;
    enableUpdateAuthorized? :boolean;
}

function CfdiTotals(props: CfdiTotalsProps) {

    return (
        <>
            <ListItem>
                <ListItemText primary={translate("cfdis.details.subtotal")} />
                <NumberFormat value={props.cfdi.subtotal} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            {props.cfdi.impuestos && props.cfdi.impuestos.total_impuestos_retenidos ? (
                <ListItem>
                    <ListItemText primary={translate("cfdis.details.iva_retentions")} />
                    <NumberFormat value={props.cfdi.impuestos.total_impuestos_retenidos} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                </ListItem>
            ) : undefined}
            {props.cfdi.impuestos && props.cfdi.impuestos.total_impuestos_trasladados ? (
                <ListItem>
                    <ListItemText primary={translate("cfdis.details.iva_translated")} />
                    <NumberFormat value={props.cfdi.impuestos.total_impuestos_trasladados} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                </ListItem>
            ) : undefined}
            <ListItem>
                <ListItemText primary={translate("cfdis.details.total")} />
                <NumberFormat value={props.cfdi.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            {props.showDeleteAdditionalAmount && props.showDeleteAdditionalAmount === true &&
                <Grid item >
                    <div>
                        <ListItem button onClick={props.onOpenDeleteAdditionalAmount} dense>
                            <RemoveIcon style={{ "color": "#FF0000", "width": 20, "height": 20, "marginRight": "7px" }} />
                            <ListItemText primary={props.cfdiMetadata.additional_amount.name} />
                            <NumberFormat color="blue" value={props.cfdiMetadata.additional_amount.amount} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                        </ListItem>
                    </div>
                    <ListItem>
                        <ListItemText primary={translate("cfdis.additional_amount.amount_to_pay")} />
                        <NumberFormat value={props.total_to_pay} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                    </ListItem>
                </Grid>}
            <ListItem>
                <ListItemText primary={translate("cfdis.details.payed")} />
                <NumberFormat value={props.total_to_pay - props.cfdiMetadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            <ListItem>
                <ListItemText primary={translate("cfdis.details.saldo")} />
                <NumberFormat value={props.cfdiMetadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            {props.showTotalAuthorized && props.showTotalAuthorized === true &&
                <Grid item style={{ "color": "#3E82F7" }}>
                    <div>
                        <ListItem button onClick={props.onOpenUpdateAuthorizedAmount} disabled={!props.enableUpdateAuthorized} dense>
                            <ListItemText primary={translate("cfdis.details.total_authorized")} />
                            <EditIcon style={{ "color": "#3E82F7", "width": 16, "height": 16 }} />
                            <NumberFormat color="blue" value={props.cfdiMetadata && props.cfdiMetadata.authorized_amount ? props.cfdiMetadata.authorized_amount.total_authorized : props.cfdiMetadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                        </ListItem>
                    </div>
                </Grid>}
            <ListItem>
                <ListItemText primary={translate("currency.title")} />
                <ListItemText primary={(
                    <Typography variant="body2" align="right">
                        {props.cfdi.moneda}
                    </Typography>
                )} />
            </ListItem>
        </>
    );
}

interface HonorariumTotalsProps {
    payableDocument: Cfdi;
    expensesAccount: ExpensesAccount;
    honorarium: Honorarium;
}

function HonorariumTotals(props: HonorariumTotalsProps) {
    return (
        <>
            <ListItem>
                <ListItemText primary={translate("honorarium.subtotal")} />
                <NumberFormat value={props.honorarium.subtotal} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            <ListItem>
                <ListItemText primary={translate("honorarium.detained_taxes")} />
                <NumberFormat value={props.honorarium.detained_taxes} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            <ListItem>
                <ListItemText primary={translate("honorarium.transferred_taxes")} />
                <NumberFormat value={props.honorarium.transferred_taxes} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            {props.honorarium.customs_paperwork && (
                <>
                    <ListItem>
                        <ListItemText primary={(
                            <Typography variant="body2" color="primary">
                                <strong>{translate("expenses_accounts.total")}</strong>
                            </Typography>
                        )} />
                        <Typography variant="body2" color="primary">
                            <strong>
                                <NumberFormat value={props.payableDocument.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </strong>
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={(
                            <Typography variant="body2" color="secondary">
                                <strong>{translate("honorarium.balance")}</strong>
                            </Typography>
                        )} />
                        <Typography variant="body2" color="secondary">
                            <strong>
                                <NumberFormat value={props.payableDocument.metadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </strong>
                        </Typography>
                    </ListItem>
                </>
            )}
            {!props.honorarium.customs_paperwork && (
                <>
                    <ListItem>
                        <ListItemText primary={(
                            <Typography variant="body2" color="primary">
                                <strong>{translate("honorarium.total")}</strong>
                            </Typography>
                        )} />
                        <Typography variant="body2" color="primary">
                            <strong>
                                <NumberFormat value={props.honorarium.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </strong>
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={(
                            <Typography variant="body2" color="primary">
                                <strong>{translate("honorarium.total_expenses")}</strong>
                            </Typography>
                        )} />
                        <Typography variant="body2" color="primary">
                            <strong>
                                <NumberFormat value={props.expensesAccount.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </strong>
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={(
                            <Typography variant="body2" color="secondary">
                                <strong>{translate("honorarium.balance")}</strong>
                            </Typography>
                        )} />
                        <Typography variant="body2" color="secondary">
                            <strong>
                                <NumberFormat value={props.payableDocument.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </strong>
                        </Typography>
                    </ListItem>
                </>
            )}
        </>
    );
}

interface NotCfdiTotalsProps {
    cfdiId: string;
    total: number;
    cfdiMetadata: CfdiMetadata;
    total_to_pay: number;
    onOpenUpdateAuthorizedAmount(): any;
    onOpenDeleteAdditionalAmount(): any;
    showTotalAuthorized?: boolean;
    showDeleteAdditionalAmount?: boolean;
    enableUpdateAuthorized? :boolean;
    currency: string;
}

function NotCfdiTotals(props: NotCfdiTotalsProps) {

    if("CREDIT_NOTE" === props.cfdiMetadata.type){
        return (
            <>
                <ListItem>
                    <ListItemText primary={translate("cfdis.details.total")} />
                    <NumberFormat value={props.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                </ListItem>
                <ListItem>
                    <ListItemText primary={translate("currency.title")} />
                    <ListItemText primary={(
                        <Typography variant="body2" align="right">
                            {props.currency}
                        </Typography>
                    )} />
                </ListItem>
            </>
        );
    }

    return (
        <>
            <ListItem>
                <ListItemText primary={translate("cfdis.details.total")} />
                <NumberFormat value={props.total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            {props.showDeleteAdditionalAmount && props.showDeleteAdditionalAmount === true &&
                <Grid item >
                    <div>
                        <ListItem button onClick={props.onOpenDeleteAdditionalAmount} dense>
                            <RemoveIcon style={{ "color": "#FF0000", "width": 20, "height": 20, "marginRight": "7px" }} />
                            <ListItemText primary={props.cfdiMetadata.additional_amount.name} />
                            <NumberFormat color="blue" value={props.cfdiMetadata.additional_amount.amount} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                        </ListItem>
                    </div>
                    <ListItem>
                        <ListItemText primary={translate("cfdis.additional_amount.amount_to_pay")} />
                        <NumberFormat value={props.total_to_pay} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                    </ListItem>
                </Grid>}
            <ListItem>
                <ListItemText primary={translate("cfdis.details.payed")} />
                <NumberFormat value={props.total_to_pay - props.cfdiMetadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            <ListItem>
                <ListItemText primary={translate("cfdis.details.saldo")} />
                <NumberFormat value={props.cfdiMetadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
            </ListItem>
            {props.showTotalAuthorized && props.showTotalAuthorized === true &&
                <Grid item style={{ "color": "#3E82F7" }}>
                    <div>
                        <ListItem button onClick={props.onOpenUpdateAuthorizedAmount} disabled={!props.enableUpdateAuthorized} dense>
                            <ListItemText primary={translate("cfdis.details.total_authorized")} />
                            <EditIcon style={{ "color": "#3E82F7", "width": 16, "height": 16 }} />
                            <NumberFormat color="blue" value={props.cfdiMetadata && props.cfdiMetadata.authorized_amount ? props.cfdiMetadata.authorized_amount.total_authorized : props.cfdiMetadata.balance} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                        </ListItem>
                    </div>
                </Grid>
            }
            <ListItem>
                <ListItemText primary={translate("currency.title")} />
                <ListItemText primary={(
                    <Typography variant="body2" align="right">
                        {props.currency}
                    </Typography>
                )} />
            </ListItem>
        </>
    );
}