import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItem, ListItemIcon, Fade, Grid, ListItemText, List, Icon } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";

import ChangeReceptor from '@material-ui/icons/CompareArrowsTwoTone';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import ExportProrateCfdiMenu from "./ExportProrateCfdiMenu";
import DownloadValidationsPdfOption from "./DownloadValidationsPdfOption";
import ToAuthorizeIcon from '@material-ui/icons/ThumbUp';

interface CfdisToAuthorizedMenuProps {
    anchor: HTMLElement | null;
    isReaderOnly: boolean;
    allowAuthorize: boolean;
    onClose(): any;
    onDownloadAll(): any;
    onDownloadSelected(): any;
    onChangeReceptor(): any;
    onDownloadExcelSelected(): any;
    onDownloadExcel(): any;
    onDownloadProratedExcelSelected(): void;
    onDownloadProratedExcel(): void;
    onDownloadValidationsPdf(): void;
    onDownloadValidationsPdfSelected(): void;
    onAuthorizeSelected(): any;
    onAuthorizeListed(): any;
}

export default function CfdisToAuthorizedMenu(props: CfdisToAuthorizedMenuProps) {
    const context = useContext(AppContext);
    const [openDownloadCfdi, setOpenDownloadCfdi] = useState<boolean>(false);
    const [openAuthorizeCfdis, setOpenAuthorizeCfdis] = useState<boolean>(false);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);

    const handleClickDownloadCfdi = () => {
        setOpenDownloadCfdi(!openDownloadCfdi);
    };

    const handleClickAuthroizeCfdis = () => {
        setOpenAuthorizeCfdis(!openAuthorizeCfdis);
    };

    const handleClickExportExcel = () => {
        setOpenExportExcel(!openExportExcel);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate", "RefundsUpdate", "RefundsCxcUpdate"]) && 
                props.allowAuthorize &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickAuthroizeCfdis} dense>
                            <ListItemIcon>
                                <ToAuthorizeIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.to_authorize.actions.authorize")} />
                            {openAuthorizeCfdis ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openAuthorizeCfdis} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onAuthorizeSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onAuthorizeListed} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.actual_list")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickDownloadCfdi} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.download_cfdi")} />
                            {openDownloadCfdi ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openDownloadCfdi} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadAll} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }

            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.export_excel")} />
                            {openExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportExcel} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadExcelSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadExcel} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }

            {context.isGrantedAny(["CfdiProratedReport"]) &&
                <ExportProrateCfdiMenu onDownloadProratedExcel={props.onDownloadProratedExcel} onDownloadProratedExcelSelected={props.onDownloadProratedExcelSelected}></ExportProrateCfdiMenu>
            }

            {!props.isReaderOnly && context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                <MenuItem button onClick={props.onChangeReceptor} dense>
                    <ListItemIcon>
                        <ChangeReceptor fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.reasign_receptor")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsRead", "ProviderCFDIsRead", "ReceptorCFDIsRead",
            "SenderCFDIsRead", "CxpCFDIsRead", "CfdisIssuedRead", "TenantCfdisIssuedRead", "ProviderCfdisIssuedRead"]) &&
                <DownloadValidationsPdfOption
                    onDownload={props.onDownloadValidationsPdf}
                    onDownloadSelected={props.onDownloadValidationsPdfSelected}
                />
            }
        </Menu>
    );
}