import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import Progress from "../components/Progress";
import ValidatedInput from "../components/ValidatedInput";
import { WarningSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { backwardErpToPendingPayment } from "../api/SupportAPI";
import { CfdiBackwardPendingPaymentRequest } from "../api/Support";

interface BackwardPendingPaymentByUuidPopupProps {
    onClose(): any;
    onSuccess(success: string): any;
}

export default function BackwardPendingPaymentByUuidPopup(props: BackwardPendingPaymentByUuidPopupProps) {
    const [request, setRequest] = useState<CfdiBackwardPendingPaymentRequest>({} as CfdiBackwardPendingPaymentRequest);
    const [status, setStatus] = useState<string>("loading");
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setStatus("loaded");
    }, [props]);

    const onAccept = () => {
        setSubmitting(true);
        backwardErpToPendingPayment(request).then(() => {
            props.onSuccess(translate("tenants.backward_pending_payment.success_uuid", {"uuid": request.uuid}) as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const hasChanged = (name: string, value: string) => {
        setSubmitting(false);
        setRequest({ ...request, [name]: value });
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setError(undefined);
    };

    return (
        <DialogPopup open
            title={translate("tenants.backward_pending_payment.title") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onAccept} 
                variant="outlined" 
                color="primary" 
                disabled={(!request.uuid || /^\s*$/.test(request.uuid)) || (!request.comments || /^\s*$/.test(request.comments)) || submitting}>
                {translate("buttons.accept") as string}
                </Button>
            }>
            {status !== "loading" && status !== "loaded" && (
                status
            )}
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {translate("tenants.backward_pending_payment.note_info") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <ValidatedInput
                            type="text" id="uuid" name="uuid"
                            label={translate("tenants.backward_pending_payment.uuid") as string}
                            value={request.uuid}
                            required={true}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="comments" name="comments"
                            label={translate("tenants.backward_pending_payment.justify") as string}
                            value={request.comments}
                            rows={5}
                            required={true}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>

                </Grid>
            )}
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );

}