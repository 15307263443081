import { Tenant } from "./Tenant";

export interface CustomReportRequest {
    name: string;
    data_query: string;
    count_query: string;
    export_available: boolean;
    validated_at : Date;
    columns: CustomReportColumn[];
}

export interface CustomReport {
    id: string;
    tenant_id: string;
    name: string;
    columns: CustomReportColumn[];
    columns_names: string;
    exportable: boolean;
    created_at: Date;
    validated_at : Date;
    status: string;
    query: string;
    query_total:string;
}

export interface CustomReports {
    items: CustomReport[];
    total: number;
    tenant: Tenant;
}

export interface CustomReportsQueryParams {
    search: string;
}

export interface CustomReportsListParams {
    name: string;
    columns: string;
    exportable: string;
    created_at_init: string;
    created_at_end: string;
    status: string;
}

export interface CustomReportDataQueryParams {
    start_date: Date;
    end_date: Date;
    report: string;
}

export const Statuses = ["ACTIVE",  "INACTIVE"];

export enum FormatsCustomColumn {
    NONE = "Ninguno",
    CURRENCY = "Moneda",
    MEXICO_DATE = "Fecha México (dd/mm/yyyy)",
    USA_DATE = "Fecha USA (mm/dd/yyyy)",
    LONG_DATE = "Fecha Larga (dd/mm/yyyy hh:mm:ss)"
}

export interface CustomReportColumn {
    attribute: string;
    label: string;
    format: string;
    visible: boolean;
}

export interface CustomReportDataQueryRequest {
    page_size: number;
    offset: number;
    start_date: Date;
    end_date: Date;
}

export interface CustomReportRows {
    rows: any[];
    total: number;
}
