import React, { useState } from "react";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import translate from "../i18n/Translator";
import Gridable, { GridableColumn } from "../components/Gridable";
import { Payment, PaymentData, CfdiPayment } from "../model/Payment";
import NumberFormat from 'react-number-format';
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import InfoIcon from '@material-ui/icons/Info';
import { CfdiDescription, CfdiAmounts } from "../cfdi/CfdiComponents";
import { GTZeroValidator } from "../components/Validators";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

export interface GridPaymentCfdisProps {
    paginateCfdis: boolean;
    openBackdrop: boolean;
    data: CfdiPayment[];
    page: number;
    pageSize: number;
    totalRows: number;
    readOnly: boolean;
    paymentValues?: Payment;
    infoProvider: PaymentData;
    validators: any;
    useExchangeRate: boolean;
    hasChangedAmount(name: string, value: number, inputRef: InputRef): any;
    onChangedPage(page: number): any;
    onChangedPageSize(pageSize: number): any;
    onClickedRow(cfdi: CfdiPayment): any;
    onClickedRowId(id: string, isAdvancePayment?: boolean): any;
}

export default function GridPaymentCfdis(props: GridPaymentCfdisProps) {
    const gtZeroValidator = new GTZeroValidator();

    const AmountTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <NumberFormat value={value.row.amount}
                prefix="$ " suffix={` ${value.row.currency}`}
                decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                displayType="text" />;
        }} {...props} />
    );

    const PaymentAmountTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <NumberFormat value={value.row.payment_amount}
                prefix="$ " suffix={` ${value.row.payment_currency ?? value.row.currency}`}
                decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                displayType="text" />;
        }} {...props} />
    );

    const ComprobanteTypeProvider = (params: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <CfdiDescription cfdi={value.row} onClickRow={props.onClickedRowId} onClickAdvancePayment={(id) => props.onClickedRowId(id, true)} />;
        }} {...params} />
    );

    const TotalsTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <CfdiAmounts cfdi={value.row} showBalance />;
        }} {...props} />
    );

    const customPlugins = [
        <AmountTypeProvider for={['amount']} editorComponent={NoFilterEditor} />,
        <PaymentAmountTypeProvider for={['payment_amount']} editorComponent={NoFilterEditor} />,
        <ComprobanteTypeProvider for={['comprobante']} editorComponent={NoFilterEditor} />,
        <TotalsTypeProvider for={['totals']} editorComponent={NoFilterEditor} />,
    ];

    const currencyColumns = ['amount', 'payment_amount'];

    const [columnsFormat] = useState([
        { columnName: 'comprobante' },
        { columnName: 'totals' },
        { columnName: 'amount' },
        { columnName: 'payment_amount' }
    ]) as any;

    const columns = [
        {
            name: 'comprobante',
            title: translate("cfdis.columns.comprobante") as string,
        },
        {
            name: 'totals',
            title: translate("cfdis.columns.totals") as string,
        },
        {
            name: 'amount',
            title: translate("payments_cfdi.amount_authorized") as string
        },
        {
            name: 'payment_amount',
            title: translate("payments_cfdi.amount_paid") as string
        }
    ];

    
    const getTotalToPaid = (cfdi: CfdiPayment) => {
        if (cfdi.payment_amount) {
            return `${cfdi.payment_amount || ""}`;
        }
        if (cfdi.cfdi && cfdi.cfdi.metodo_pago === "PUE") {
            return `${cfdi.metadata.balance || ""}`;
        }

        return `${(cfdi.total - cfdi.metadata.balance) || ""}`;
    };

    const getColumns = (): GridableColumn<CfdiPayment>[] => {
        const columns = [
            {
                title: translate("cfdis.columns.comprobante") as string,
                converter: (cfdi) => (
                    <CfdiDescription cfdi={cfdi} onClickAdvancePayment={(id) => props.onClickedRowId(id, true)} />
                ),
                fullWidth: true,
                xs: 6,
                sm: true,
                md: true,
                lg: true,
                xl: true,
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>
                            {translate("cfdis.columns.totals")}
                        </strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <CfdiAmounts cfdi={cfdi} showBalance />
                ),
                fullWidth: true,
                xs: false,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
            },
            props.paymentValues && props.paymentValues.cfdis ? {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>
                            {translate("payments_cfdi.amount_authorized")}
                        </strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <Typography variant="body2" component="h6" align="right">
                        <NumberFormat value={cfdi.amount}
                            prefix="$ " suffix={` ${cfdi.currency}`}
                            decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                            displayType="text" />
                    </Typography>
                ),
                fullWidth: true,
                justify: "flex-end",
                xs: 5,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
            } : undefined,
            {
                title: translate("payments_cfdi.amount_paid") as string,
                converter: (cfdi, index) => props.readOnly ?
                    <Grid container justify="space-evenly" >
                        <Grid item xs={12}>
                            <NumberFormat value={cfdi.payment_amount}
                                prefix="$ " suffix={` ${props.infoProvider.currency}`}
                                decimalScale={2} fixedDecimalScale={true}
                                displayType="text" />
                        </Grid>
                    </Grid> :
                    <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                        <Grid item xs={10}>
                            <ValidatedInput
                                type="text"
                                id={cfdi.id}
                                name={cfdi.id}
                                value={getTotalToPaid(cfdi)}
                                label=""
                                required
                                margin={"dense"}
                                disabled={(cfdi.cfdi && cfdi.cfdi.metodo_pago === "PUE") || cfdi.type === "ADVANCE_PAYMENT" || (props.useExchangeRate && cfdi.currency !== cfdi.payment_currency)}
                                validators={props.validators[cfdi.id] && cfdi.currency === cfdi.payment_currency ? [gtZeroValidator, props.validators[cfdi.id]] : [gtZeroValidator]}
                                onValueChanged={props.hasChangedAmount}
                            />
                        </Grid>
                        {"ADVANCE_PAYMENT" === cfdi?.type || (cfdi.cfdi && cfdi.cfdi.metodo_pago === "PUE") &&
                            <Grid item xs={2}>
                                <Tooltip title={translate("cfdis.total_authorize.invalid_for_partiallity") as string}>
                                    <InfoIcon style={{ opacity: "60%" }} />
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>,
                fullWidth: true,
                justify: "flex-end",
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3,
                xl: 3,
            },
        ] as GridableColumn<CfdiPayment>[];
        return columns.filter(col => !!col);
    };

    if(props.paginateCfdis){
        return (<GridDx
            noUseId={true}
            loading={props.openBackdrop}
            rows={props.data}
            page={props.page}
            pageSize={props.pageSize}
            totalRows={props.totalRows}
            columns={columns ? columns : []}
            columnsFormat={columnsFormat}
            amountCurrencyColumns={currencyColumns}
            onClickRow={() => { }}
            onClickedOptions={() => { }}
            onChangedPage={props.onChangedPage}
            onChangedPageSize={props.onChangedPageSize}
            customFormatColumns={customPlugins}
            heightTablePX={320}
        />);

    }
    return( <Gridable
            onClick={props.readOnly ? props.onClickedRow : undefined}
            items={props.data}
            loading={false}
            empty={translate("cfdis.history.empty") as string}
            columns={getColumns()} />);
    
}