import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { ShippingTemplateQueryParams, ShippingTemplate, ShippingTemplates } from "../model/ShippingTemplate";
import AddIcon from "@material-ui/icons/Add";
import { ShippingTemplatesIcon } from "../components/Icons";
import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import ShippingMenu from "./ShippingTemplateMenu";
import { ShippingTemplateListCallbacks } from "./ShippingTemplateList";
import { listShippingTemplates } from "../api/ShippingTemplateAPI";
import ShippingDeletePopup from "./ShippingDeletePopup";
import { Product, PRODUCTS } from "../workflove/Workflove";
import NewShippingTemplateMenu from "./NewShippingTemplateMenu";

export default function ShippingTemplatesList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ShippingTemplateQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as ShippingTemplateQueryParams;
    };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ShippingTemplates>();
    const [params, setParams] = useState<ShippingTemplateQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ShippingTemplateQueryParams>(paramsFromQueryString);

    const [shippingTemplate, setShippingTemplate] = useState<ShippingTemplate>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [dialog, setDialog] = useState<"delete" | "multi_delete" | undefined>();
    const [products, setProducts] = useState<Product[]>([]);

    const openProductPicker = (event: React.MouseEvent<HTMLElement>) => {
        const enabledProducts = PRODUCTS.filter(el => {
            if (el === "PAYABLE_DOCUMENTS") {
                return context.isGranted("ShippingTemplateCreate");
            }
            if (el === "REQUISITIONS") {
                return context.isGranted("RequisitionsCreate");
            }
            if (el === "REFUNDS") {
                return context.isGranted("RefundsCreate");
            }
            return false;
        })

        if (enabledProducts.length === 1) {
            history.push(`/shipping-templates/new?product=${enabledProducts[0]}`);
        } else {
            setProducts(enabledProducts);
            setAddAnchorEl(event.currentTarget);
        }
    }

    const onCancelAdding = () => {
        setProducts([]);
        setAddAnchorEl(null);
    };

    const load = () => {
        let offset = getOffset(page, pageSize);
        setStatus("loaded");
        if (match.params.success) {
            setSuccess(match.params.success);
        }
        listShippingTemplates(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (shippingTemplate: ShippingTemplate, anchorEl: null | HTMLElement) => {
        setAnchorEl(anchorEl);
        setShippingTemplate(shippingTemplate);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setShippingTemplate(undefined);
    };

    const showDeleteDialog = () => {
        if (!shippingTemplate) return;
        setAnchorEl(null);
        setDialog("delete");
    };

    const onSuccessDelete = () => {
        if (!shippingTemplate) return;
        setSuccess(translate("shipping_templates.deleted") as string);
        setShippingTemplate(undefined);
        setDialog(undefined);
        load();
    }

    const onError = (error: string) => {
        setError(error);
        setShippingTemplate(undefined);
        setDialog(undefined);
    };

    const onCancel = () => {
        setShippingTemplate(undefined);
        setDialog(undefined);
    };

    const onEditOption = () => {
        setAnchorEl(null);
        setShippingTemplate(shippingTemplate);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const handleCheck = (shippingTemplate: ShippingTemplate) => {
    };

    const handleAllChecks = (checked: boolean) => {
    };

    function callbacks(): ShippingTemplateListCallbacks {
        return {
            handleCheck: handleCheck,
            handleAllChecks: handleAllChecks,
            onClickedOptions: onClickedOptions
        };
    }

    const onClickedRow = (shippingTemplate: ShippingTemplate) => {
        history.push(`/shipping-templates/${shippingTemplate.id}/edit`);
    };

    const onClicked = (shippingTemplate: ShippingTemplate) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        callbacks().onClickedOptions(shippingTemplate, event.currentTarget);
    };

    return (
        <Pagination
            title={translate("shipping_templates.title") as string}
            subtitle={translate("shipping_templates.subtitle") as string}
            icon={<ShippingTemplatesIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            action={context.isGrantedAny(["ShippingTemplateCreate", "ShippingTemplateUpdate"]) ? (
                <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={openProductPicker} disabled={status === "loading"}>
                    <AddIcon />
                </Fab>
            ) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("shipping_templates.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("shipping_templates.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("shipping_templates.name") as string,
                        converter: (shippingTemplate) => shippingTemplate.name,
                        xs: true
                    },
                    {
                        title: translate("shipping_templates.products.title") as string,
                        converter: (shippingTemplate) => translate(`shipping_templates.products.${shippingTemplate.product}`),
                        xs: 4,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("shipping_templates.created_at") as string,
                        converter: (shippingTemplate) => (
                            <DateFormat date={shippingTemplate.created_at} format="L" />
                        ),
                        xs: false,
                        sm: false,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: (
                            <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (shippingTemplate) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClicked(shippingTemplate)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]}
            />
            {shippingTemplate && anchorEl && (
                <ShippingMenu anchor={anchorEl}
                    tenantId={context.session!.tenant!.id}
                    shippingTemplate={shippingTemplate}
                    onEdit={onEditOption}
                    onClose={onCloseOption}
                    onDelete={showDeleteDialog} />
            )}
            {shippingTemplate && dialog === "delete" &&
                <ShippingDeletePopup
                    tenantId={context.session!.tenant!.id}
                    shippingTemplate={shippingTemplate}
                    onCompleted={onSuccessDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            }
            {addAnchorEl && products.length > 1 && (
                <NewShippingTemplateMenu
                    path="/shipping-templates/new"
                    products={products}
                    anchor={addAnchorEl}
                    onClose={onCancelAdding} />
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Pagination>
    );
}