import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Grid, List, ListItem, ListItemText, Card, ListSubheader, Box, Button, IconButton, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Requisition } from "../model/Requisition";
import { formatDateToShow } from "../components/DateFormat";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { AdditionalInfoField } from "../model/AdditionalInfoWorkflove";

interface RequisitionDetailProps {
    requisition: Requisition;
    statusDescription: string;
}

export default function RequisitionDetail(props: RequisitionDetailProps) {
    return (
        <Box py={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                <Grid item xs={12}>
                    <GeneralInfoCard requisition={props.requisition} statusDescription={props.statusDescription}/>
                </Grid>
                {props.requisition.fields_response && props.requisition.fields_response.length > 0 && (
                    <Grid item xs={12}>
                        <AdditionalInfoCard requisition={props.requisition} />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

interface GeneralInfoProps {
    requisition: Requisition;
    statusDescription?: string;
}

function GeneralInfoCard(props: GeneralInfoProps) {
    return (
        <Card variant="outlined" >
            <Box px={2} pb={2}>
                <Grid container alignItems="flex-start" justify="space-between">
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.requisition_type_name}</strong>} secondary={translate("requisitions.overview.details.types")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{formatDateToShow(new Date(props.requisition.created_at))}</strong>}
                                    secondary={translate("requisitions.overview.details.created_at")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.user_author_name}</strong>} secondary={translate("requisitions.overview.details.user_author")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.statusDescription}</strong>} secondary={translate("requisitions.overview.details.status")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.purchase_order_external_id || "-"}</strong>} secondary={translate("purchase_orders.single") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={props.requisition.in_project ? 
                                    <Button variant="text" size="small" color="primary" 
                                    component={Link} to={`/projects/detail/${props.requisition.project_id}/`} >
                                        {props.requisition.project_name}
                                        <ArrowForwardIcon style={{ "width": "15px", "height": "15px", "padding": "0px"}} />
                                    </Button> : <strong>{"-"}</strong>} secondary={translate("requisitions.overview.details.project")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.provider_name}</strong>} secondary={translate("requisitions.overview.details.provider")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.user_authorizer_name ? props.requisition.user_authorizer_name : "-"}</strong>} secondary={translate("requisitions.overview.details.user_authorizer")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{translate(`currency.${props.requisition.currency}`) as string}</strong>} secondary={translate("currency.title") as string} /> 
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{<Typography variant="subtitle1">
                                    <NumberFormat value={props.requisition.total || 0.00} suffix={` ${props.requisition.currency}`} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                </Typography>}</strong>} secondary={translate("requisitions.columns.total") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

function AdditionalInfoCard(props: GeneralInfoProps) {

    function getCatalogValue(field: AdditionalInfoField): React.ReactNode {
        return field.values_catalog?.find(vc => vc.id === field.value)?.name;
    }

    return (
        <Card variant="outlined" >
            <ListSubheader>{translate("requisitions.overview.additional_info")}</ListSubheader>
            <Box px={2} pb={2}>
                <Grid container alignItems="flex-start" justify="space-between">
                    {props.requisition.fields_response.map(field => (
                        <Grid key={field.id} item lg={6}>
                            {(field.type.id === 'TEXT'  || field.type.id === 'TEXTAREA' || field.type.id === 'NUMBER')  && 
                                <List dense disablePadding>
                                    <ListItemText primary={field.value} secondary={field.name} />
                                </List>
                            }
                            {field.type.id === 'DATE' &&
                                <List dense disablePadding>
                                    <ListItemText primary={field.value ? formatDateToShow(new Date(parseFloat(field.value))) : ""} secondary={field.name} />
                                </List>
                            }
                            {field.type.id === 'FILE' && 
                                <List dense disablePadding>
                                    <ListItemText primary={field.value ?
                                        <IconButton aria-label="add" color="primary" size="small" href={field.value} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                        </IconButton> : <></>
                                    } secondary={field.name} />
                                </List>
                            }
                            {(field.type.external_id === 'CATALOG')  && 
                                <List dense disablePadding>
                                    <ListItemText primary={getCatalogValue(field)} secondary={field.name} />
                                </List>
                            }
                        </Grid>
                    ))}
                    
                </Grid>
            </Box>
        </Card>
    );
}