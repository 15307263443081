import React, { useState} from 'react';
import DateRange from "./DateRange";
import translate from "../i18n/Translator";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid } from "@material-ui/core";
import { WarningSnackbar } from "../components/Snackbars";

interface DownloadRangeProps {
    family: string;
    onExportRange(startDate: Date, endDate: Date): any;
    onClose(): any;
    maxDaySelected?: number;
    title?: string;
    message?: string;
}

export default function DownloadRange(props: DownloadRangeProps) {
    const [sDate, setSDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()));
    const [eDate, setEDate] = useState<Date>(new Date());
    const menuItems = ["this_week", "this_month", "last_month", "custom"];
    const [warning, setWarning] = useState<string>();

    const onChangeDateRange = (startDate: Date, endDate: Date) => {
        let difference = endDate.getTime() - startDate.getTime();
        let daysBetween = Math.round(difference / (1000 * 60 * 60 * 24));
        if(daysBetween && props.maxDaySelected && daysBetween > props.maxDaySelected){
            setWarning(translate(`connectors.download_by_range.range_limit`, { "dias": props.maxDaySelected + "" }) as string);
            return;
        } else{
            setSDate(startDate);
            setEDate(endDate);
        }
    };

    const getTitle = () =>{
        if(props.title){
            return props.title;
        }else{
            return translate(`connectors.download_by_range.title`) as string;
        }
    }

    const getMessage = () =>{
        return props?.message ?? "";
    }
    
    return (
        <Dialog open maxWidth="sm"
            onClose={props.onClose}
            aria-labelledby="templ-downloader-title"
            aria-describedby="templ-downloader-description">
            <DialogTitle id="templ-downloader-title" disableTypography>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{getTitle()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{translate(`connectors.family.${props.family}`) as string}</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="DialogPopup" dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{translate(`connectors.download_by_range.range`) as string}</Typography>
                        <Grid item xs={12}>
                            <DateRange
                                startDate={sDate}
                                endDate={eDate}
                                onChange={onChangeDateRange}
                                menuItemsCustom={menuItems} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2">{getMessage()}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item xs={12}>
                    <Box px={1}>
                        <Grid container justify="flex-end" spacing={1}>
                            <Grid item xs="auto">
                                <Button onClick={props.onClose} variant="outlined" color="primary">
                                    {translate("buttons.close")}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button onClick={() => props.onExportRange(sDate, eDate)} variant="contained" color="primary" disabled={false}>
                                    {translate("buttons.export")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </DialogActions>
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
        </Dialog>
    );
}