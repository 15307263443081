import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Divider, IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GetAppIcon from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from '@material-ui/icons/PhoneForwarded';

import moment from "moment";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { Billing } from "../model/Billing";
import DialogPopup from "../components/DialogPopup";
import { phoneFormat } from "../components/Validators";

interface BillingDetailPopup {
    billing: Billing;
    onDownloadAttachment(): any;
    onClose(): any;
}

export default function BillingDetailPopup(props: BillingDetailPopup) {
    return (
        <DialogPopup open title={(
            <Grid container justify="space-between">
                <Grid item xs>
                    {translate("billing.details.title", { "id": props.billing.id })}
                </Grid>
                <Grid item xs="auto">
                    <strong>{translate(`billing.status.${props.billing.status}`)}</strong>
                </Grid>
            </Grid>
        )} maxWidth="sm" onClose={props.onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignContent="center" alignItems="center">
                        <Grid item xs>
                            <Typography variant="caption" color="textSecondary">
                                {translate("tenants.single")}
                            </Typography>
                            <Typography variant="button" component="h6">{props.billing.tenant!.name}</Typography>
                            <Typography variant="body2" paragraph>{props.billing.tenant!.rfc}</Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <IconButton color="primary" size="medium" component={Link} to={`/tenants?search=${props.billing.tenant!.rfc}`}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                {props.billing.owner && (
                    <Grid item xs={12}>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item xs>
                                <Typography variant="caption" color="textSecondary">
                                    {translate("billing.details.owner")}
                                </Typography>
                                <Typography variant="button" component="h6">{props.billing.owner.name}</Typography>
                                <Typography variant="body2" component="h6" paragraph={!props.billing.owner.phone}>{props.billing.owner.email}</Typography>
                                {props.billing.owner.phone && (
                                    <Typography variant="body2" component="h6" paragraph>
                                        <NumberFormat value={props.billing.owner.phone} format={phoneFormat(props.billing.owner.phone)} displayType="text" />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs="auto">
                                <IconButton color="primary" size="medium" component="a" href={`mailto:${props.billing.owner.email}`}>
                                    <EmailIcon />
                                </IconButton>
                                {props.billing.owner.phone && (
                                    <IconButton color="primary" size="medium" component="a" href={`tel:${props.billing.owner.phone}`}>
                                        <PhoneIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container alignContent="center" alignItems="center">
                        <Grid item xs>
                            <Typography variant="caption" color="textSecondary">
                                {translate("billing.title")}
                            </Typography>
                            <Typography variant="button" component="h6">
                                {props.billing.plan.name} - <NumberFormat value={props.billing.amount} prefix="$" decimalScale={2} displayType="text" />
                            </Typography>
                            <Typography variant="body2" component="h6" paragraph={!!props.billing.authorization}>
                                {moment(props.billing.starts).format("ll")} - {moment(props.billing.ends).format("ll")}
                            </Typography>
                            {props.billing.authorization && (
                                <>
                                    <Typography variant="caption" component="h6">
                                        {translate("billing.authorization")} - {props.billing.authorization}
                                    </Typography>
                                    {props.billing.transaction_id && (
                                        <Typography variant="caption" component="h6" color="textSecondary">
                                            {props.billing.transaction_id}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Grid>
                        {props.billing.attachment && (
                            <Grid item xs="auto">
                                <IconButton color="primary" size="medium" onClick={props.onDownloadAttachment}>
                                    <GetAppIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </DialogPopup>
    );
}