import React from "react";
import { MenuItem, Fade, Menu, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";

interface CreateRetentionMenuProps {
    onOpenDefault(): any;
    onOpenNew(): any;
    onClose(): any;
    anchor: HTMLElement | null;
}

export default function CreateRetentionMenu(props: CreateRetentionMenuProps) {

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            open
            onClose={props.onClose}
            TransitionComponent={Fade}>
            <MenuItem button onClick={props.onOpenDefault} dense>
                <Typography variant="inherit">{translate("reception_rule.retention_validation.default_retention")}</Typography>
            </MenuItem>

            <MenuItem button onClick={props.onOpenNew} dense>
                <Typography variant="inherit">{translate("reception_rule.retention_validation.new_retention")}</Typography>
            </MenuItem>
        </Menu>);
}
