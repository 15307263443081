import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale';
import { Box, Button, Grid, Typography, Menu, MenuItem, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat";
import ValidatedInput from "../components/ValidatedInput";

interface SelectionRate {
  startDate: Date,
  endDate: Date,
  key: string,
}

interface DateRangesProps {
  onChange(startDate: Date, endDate: Date): any;
  startDate?: Date;
  endDate?: Date;
  menuItemsCustom?: string[];
  title?: string
}

export default function DateRanges(props: DateRangesProps) {
  const menuItems = props.menuItemsCustom !== undefined ? props.menuItemsCustom : ["today", "yesterday", "this_week", "last_week", "this_month", "last_month", "custom"];
  const [selected, setSelected] = useState<"" | "today" | "yesterday" | "this_week" |
    "last_week" | "this_month" | "last_month" | "custom">();

  const maskStartDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  const maskEndDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }

  const [selectionRate, setSelectionRate] = useState<SelectionRate>({
    startDate: maskStartDate(props.startDate ? props.startDate : new Date()),
    endDate: maskEndDate(props.endDate ? props.endDate : new Date()),
    key: 'selection',
  });
  const [open, setOpen] = useState<boolean>(false);
  const [maskDate] = useState<string>('MMMM D, YYYY');
  const [dateRanges, setDateRanges] = useState<string>();
  const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
  const [options, setOptions] = useState<string[]>(menuItems);
  const [optionLabels, setOptionLabels] = useState<string[]>(options.map(option => translate("date_ranges." + option) as string));



  const todayMilis = (1000 * 60 * 60 * 24);
  const today = maskStartDate(new Date());
  const yesterday = maskStartDate(new Date(today.getTime() - todayMilis));
  const thisMonthStart = maskStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
  const thisMonthEnd = maskStartDate(new Date(new Date(today.getFullYear(), today.getMonth() + 1, 1).getTime() - todayMilis));
  const lastMonthStart = maskStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
  const lastMonthEnd = maskStartDate(new Date(new Date(today.getFullYear(), today.getMonth(), 1).getTime() - todayMilis));
  const thisWeekStart = maskStartDate(new Date(new Date().setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -7 : 0))));
  const thisWeekEnd = maskStartDate(new Date(thisWeekStart.getTime() + (todayMilis * 6)));
  const lastWeekStart = maskStartDate(new Date(thisWeekStart.getTime() - (todayMilis * 7)));
  const lastWeekEnd = maskStartDate(new Date(lastWeekStart.getTime() + (todayMilis * 6)));

  useEffect(() => {
    setSelectionRate({ ...selectionRate, startDate: maskStartDate(props.startDate ? props.startDate : new Date()), endDate: maskEndDate(props.endDate ? props.endDate : new Date()) });
    setDateRanges(formatDate(props.startDate ? props.startDate : new Date(), maskDate) + " - " + formatDate(props.endDate ? props.endDate : new Date(), maskDate));
    // eslint-disable-next-line
  }, [props]);

  const onAccept = (startDate: Date, endDate: Date) => {
    setDateRanges(formatDate(startDate, maskDate) + " - " + formatDate(endDate, maskDate));
    setOpen(false);
  }

  const saveEvent = (event: React.MouseEvent<HTMLElement>) => {
    setGridAnchorEl(event.currentTarget);
  };

  const onClose = () => { };

  const onChange = (ranges: any) => {
    setSelectionRate({ ...selectionRate, startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
    verifyMenuValues(maskStartDate(ranges.selection.startDate), maskStartDate(ranges.selection.endDate));
  }

  const onChangeRange = (name: string, value: "today" | "yesterday" | "this_week" |
    "last_week" | "this_month" | "last_month" | "custom") => {
    if (value === "custom") {
      selectRange("today");
      setOpen(true);
    } else if (value !== dateRanges) {
      selectRange(value);
      setOptions(menuItems);
      setOptionLabels(menuItems.map(option => translate("date_ranges." + option) as string));
    } else if (value === dateRanges) {
      props.onChange(maskStartDate(selectionRate.startDate), maskEndDate(selectionRate.endDate));
    }

  }

  const verifyMenuValues = (startDate: Date, endDate: Date) => {
    if (today.getTime() === startDate.getTime() && today.getTime() === endDate.getTime()) {
      setSelected("today");
    } else if (yesterday.getTime() === startDate.getTime() && yesterday.getTime() === endDate.getTime()) {
      setSelected("yesterday");
    } else if (thisWeekStart.getTime() === startDate.getTime() && thisWeekEnd.getTime() === endDate.getTime()) {
      setSelected("this_week");
    } else if (lastWeekStart.getTime() === startDate.getTime() && lastWeekEnd.getTime() === endDate.getTime()) {
      setSelected("last_week");
    } else if (thisMonthStart.getTime() === startDate.getTime() && thisMonthEnd.getTime() === endDate.getTime()) {
      setSelected("this_month");
    } else if (lastMonthStart.getTime() === startDate.getTime() && lastMonthEnd.getTime() === endDate.getTime()) {
      setSelected("last_month");
    } else {
      setSelected("");
    }
  }

  const selectRange = (selected: "today" | "yesterday" | "this_week" |
    "last_week" | "this_month" | "last_month" | "custom") => {
    setSelected(selected);
    switch (selected) {
      case "today":
        setSelectionRate({ ...selectionRate, startDate: today, endDate: today });
        setDateRanges(formatDate(today, maskDate) + " - " + formatDate(today, maskDate));
        break;
      case "yesterday":
        setSelectionRate({ ...selectionRate, startDate: yesterday, endDate: yesterday });
        setDateRanges(formatDate(yesterday, maskDate) + " - " + formatDate(yesterday, maskDate));
        break;
      case "this_week":
        setSelectionRate({ ...selectionRate, startDate: thisWeekStart, endDate: thisWeekEnd });
        setDateRanges(formatDate(thisWeekStart, maskDate) + " - " + formatDate(thisWeekEnd, maskDate));
        break;
      case "last_week":
        setSelectionRate({ ...selectionRate, startDate: lastWeekStart, endDate: lastWeekEnd });
        setDateRanges(formatDate(lastWeekStart, maskDate) + " - " + formatDate(lastWeekEnd, maskDate));
        break;
      case "this_month":
        setSelectionRate({ ...selectionRate, startDate: thisMonthStart, endDate: thisMonthEnd });
        setDateRanges(formatDate(thisMonthStart, maskDate) + " - " + formatDate(thisMonthEnd, maskDate));
        break;
      case "last_month":
        setSelectionRate({ ...selectionRate, startDate: lastMonthStart, endDate: lastMonthEnd });
        setDateRanges(formatDate(lastMonthStart, maskDate) + " - " + formatDate(lastMonthEnd, maskDate));
        break;
      default:
        break;
    }

  }

  return (
    <div>
      <Grid onClick={saveEvent}>
        <ValidatedInput
          type="text" id="date_range" name="date_range"
          label={props?.title ?? translate("date_ranges.title") as string}
          value={dateRanges}
          options={options}
          optionLabels={optionLabels}
          emptyOption={dateRanges}
          margin={"dense"}
          onValueChanged={onChangeRange} />
      </Grid>
      {open && gridAnchorEl ?
        <Menu
          id="date-range-item"
          anchorEl={gridAnchorEl}
          keepMounted
          onClose={onClose}
          open
          TransitionComponent={Fade}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              {menuItems.map(item => {
                if (item === "today" || item === "yesterday" ||
                  item === "this_week" || item === "last_week" ||
                  item === "this_month" || item === "last_month") {
                  return (<MenuItem onClick={() => selectRange(item)} dense>
                    <Typography variant="inherit" color={selected === item ? "primary" : "inherit"} >
                      {translate("date_ranges." + item) as string}
                    </Typography>
                  </MenuItem>);
                } else {
                  return (<div></div>);
                }
              })}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box pl={1} pt={1}>
                <DateRange
                  ranges={[selectionRate]}
                  onChange={onChange}
                  locale={es}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={4}>
                <Grid container justify={"space-between"}>
                  <Grid item xs="auto">
                  </Grid>
                  <Grid item xs="auto">
                    <Button onClick={() => onAccept(selectionRate.startDate, selectionRate.endDate)} variant="outlined" color="primary" >
                      {translate("buttons.accept") as string}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Menu> : undefined
      }
    </div>
  );
}

interface DateRangesStringValueProps {
  value: string;
  limitMonths?: number;
  onChange(event: any): any;
  onShowRangeError?(): any;
}

export function DateRangesStringValue(props: DateRangesStringValueProps) {

  const [dateRanges, setDateRanges] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

  const [selectionRate, setSelectionRate] = useState<SelectionRate>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    const rangesArray = props.value.toString().split(" - ");
    if (rangesArray.length === 2) {
      let temp = rangesArray[0].split("/");
      rangesArray[0] = temp[1] + "/" + temp[0] + "/" + temp[2];
      temp = rangesArray[1].split("/");
      rangesArray[1] = temp[1] + "/" + temp[0] + "/" + temp[2];
      if(props.limitMonths && props.onShowRangeError && new Date(rangesArray[1]).getMonth() - new Date(rangesArray[0]).getMonth() > props.limitMonths ){
        props.onShowRangeError();
        return;
      } else {
        setSelectionRate({
        ...selectionRate,
        startDate: new Date(rangesArray[0]),
        endDate: new Date(new Date(rangesArray[1]).getFullYear(), new Date(rangesArray[1]).getMonth(), new Date(rangesArray[1]).getDate(), 23, 59, 59, 999)
      });
        setDateRanges(props.value);
      }
    } else {
      setDateRanges(translate("buttons.filter") as string);
    }
    // eslint-disable-next-line
  }, [props]);

  const onAccept = (startDate: Date, endDate: Date) => {
    const sDate = String(startDate.getDate()).padStart(2, '0') + "/" + String(startDate.getMonth() + 1).padStart(2, '0') + "/" + startDate.getFullYear();
    const eDate = String(endDate.getDate()).padStart(2, '0') + "/" + String(endDate.getMonth() + 1).padStart(2, '0') + "/" + endDate.getFullYear();
    if(props.limitMonths && props.onShowRangeError && endDate.getMonth() - startDate.getMonth() > props.limitMonths ){
      props.onShowRangeError();
      return;
    }
    setOpen(false);
    props.onChange({ target: { value: sDate + " - " + eDate } } as any);
  }

  const onChange = (ranges: any) => {
    setSelectionRate({ ...selectionRate, startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
  }

  const onClean = () => {
    props.onChange({ target: { value: "" } } as any);
    onClose();
  }

  const onClose = () => {
    setOpen(false);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button onClick={(event: React.MouseEvent<HTMLElement>) => {
          setGridAnchorEl(event.currentTarget);
          setOpen(true);
        }} variant="text" color="inherit" fullWidth style={{ "fontSize": "10px" }}>
          {dateRanges}
        </Button>
      </Grid>
      {open && gridAnchorEl ?
        <Grid item xs={12}>
          <Menu
            id="date-range-item"
            anchorEl={gridAnchorEl}
            keepMounted
            onClose={() => { }}
            open
            TransitionComponent={Fade}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box px={1} py={1}>
                  <DateRange
                    ranges={[selectionRate]}
                    onChange={onChange}
                    locale={es}
                  />
                  <Grid container justify={"flex-end"} spacing={1}>
                    <Grid item>
                      <Button onClick={props.value ? onClean : onClose} variant="outlined" color="primary" >
                        {translate(props.value ? "buttons.clear" : "buttons.close") as string}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={() => onAccept(selectionRate.startDate, selectionRate.endDate)} variant="outlined" color="primary" >
                        {translate("buttons.accept") as string}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Menu>
        </Grid> : undefined
      }
    </Grid>

  );
}

