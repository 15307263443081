import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Box, Divider, Grid, IconButton } from "@material-ui/core";
import { Address, toString as addressToString } from "../model/Address";
import { TenantSegment } from "../model/TenantFieldCatalog";
import translate from "../i18n/Translator";
import EditIcon from "@material-ui/icons/Edit";

interface ProviderAddressConf {
    address?: Address;
}

interface ProviderAddressCardConf extends ProviderAddressConf {
    status?: string;
    configuration: TenantSegment;
    edit?: boolean;
    onEdit?(id: string): any;
}

export default function ProviderAddressCard(props: ProviderAddressCardConf) {
    const onAdd = () => {
        if (props.onEdit) {
            props.onEdit(props.configuration.id);
        }
    };
    return (
        <Card className="ProviderMetadataSegmentCard">
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs="auto">
                    <Typography color="primary" variant="body1">
                        {props.configuration.name}
                    </Typography>
                </Grid>
                {props.edit ?
                    <Grid item xs={1}>
                        <IconButton aria-label="options" color="default" size="small" onClick={onAdd} >
                            <EditIcon />
                        </IconButton>
                    </Grid> : undefined}
            </Grid>
            {props.configuration.description && (
                <Typography color="textSecondary" variant="caption" component="h6">
                    {props.configuration.description}
                </Typography>
            )}
            <Typography color="textSecondary" variant="caption" component="h6">
                {translate(props.configuration.required ? "expedients.required" : "expedients.optional")}
            </Typography>
            <Box py={1}>
                <Divider />
            </Box>
            <ProviderAddress {...props} />
        </Card>
    );
}



export function ProviderAddress(props: ProviderAddressConf) {
    if (!props.address) {
        return (
            <Box>
                <Typography color="textSecondary" variant="caption" component="h6">
                    {translate("expedients.not_provided") as string}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Typography color="textSecondary" variant="body2">
                {addressToString(props.address)}
            </Typography>
        </Box>
    );
}