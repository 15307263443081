import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Divider } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { TenantBankAccountType } from "../model/TenantBankAccountType";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";

interface TenantBankAccountTypeMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDeleteAccount(): any;
}

export default function TenantBankAccountTypeMenu(props: TenantBankAccountTypeMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <Divider />
            {context.isGranted("TenantBankAccountTypesDelete") &&
                <MenuItem button onClick={props.onDeleteAccount} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 