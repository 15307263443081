import React from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Employee } from "../model/Employee";
import DialogPopup from "../components/DialogPopup";

interface EmployeeGroupResultsImportProps {
    url: string;
    employees: Employee[];
    onClose(): any;
}

export default function EmployeeGroupResultsImport(props: EmployeeGroupResultsImportProps) {
    const onDownload = () => {
        window.open(props.url, "_blank")
        props.onClose();
    };

    return (
        <DialogPopup open
            title={translate("employeegroups.import-results.title") as string}
            disableEscapeKeyDown={false}
            disableBackdropClick={false}
            disable={false}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={
                <Button onClick={onDownload} variant="outlined" color={"primary"} disabled={false}>
                    {translate("buttons.download") as string}
                </Button>
            } >
            <Typography variant="body2">
                {translate("employeegroups.import-results.employees", {"employees": props.employees.length}) as string}
            </Typography>

        </DialogPopup>
    );

}