import React, { RefObject, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import { Typography, MenuItem, MenuList, ListItemIcon } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/PersonTwoTone";
import SecurityIcon from "@material-ui/icons/SecurityTwoTone";
import ExitToAppIcon from "@material-ui/icons/ExitToAppTwoTone";
import translate from "../i18n/Translator";
import PopperMenu from "../components/PopperMenu";
import AuxiliarDocumentsIcon from "@material-ui/icons/FileCopy";

interface AccountMenuProps {
    anchorRef: RefObject<HTMLButtonElement>;
    onClose(): any;
    onSignOut(): any;
}

export default function AccountMenu(props: AccountMenuProps) {
    const context = useContext(AppContext);

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Tab" || event.key === "Escape") {
            event.preventDefault();
            props.onClose();
        }
    }

    return (<>
        <PopperMenu {...props}>
            <MenuList autoFocusItem id="account-menu" onKeyDown={handleListKeyDown}>
                {!context.session?.sso_config && <MenuItem button component={Link} to="/profile" onClick={props.onClose}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.profile.title")}</Typography>
                </MenuItem>}
                {context.session?.has_aux_docs &&
                    <MenuItem button component={Link} to="/auxiliar-documents/politics" onClick={props.onClose}>
                        <ListItemIcon>
                            <AuxiliarDocumentsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("auxiliardocuments.title")}</Typography>
                    </MenuItem>
                }
                {!context.session?.sso_config && <MenuItem button component={Link} to="/security" onClick={props.onClose}>
                    <ListItemIcon>
                        <SecurityIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.security.title")}</Typography>
                </MenuItem>}
                <MenuItem onClick={props.onSignOut}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.signout")}</Typography>
                </MenuItem>
            </MenuList>
        </PopperMenu>
        </>
    );
}