import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon, DeleteIcon, ExportIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { Catalog } from "../model/Catalog";
import { AppContext } from "../context/AppContext";

interface CatalogMenuProps {
    catalog: Catalog;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
    onExport(): any;
}

export default function CatalogMenu(props: CatalogMenuProps) {
    const context = useContext(AppContext);
    const [isUpdateGranted] = useState(context.isGranted("CatalogsUpdate"));
    const [isDeleteGranted] = useState(context.isGranted("CatalogsDelete"));

    return (
        <Menu
            id="catalogs-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem button component={Link} to={`/catalogs/${props.catalog.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            <MenuItem button onClick={props.onExport} dense>
                <ListItemIcon>
                    <ExportIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {translate("buttons.export")}
                </Typography>
            </MenuItem>
            {isDeleteGranted && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.delete")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}