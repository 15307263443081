import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Progress from "../components/Progress";
import Error from "../components/Error";
import { TenantConfiguration } from "../model/TenantConfiguration";
import { get } from "../api/TenantConfigurationApi";
import { getKeyPairs } from "../api/KeyPairAPI";
import { listWorkflows } from "../api/WorkflowAPI";
import { KeyPairQueryParams, KeyPairs } from "../model/KeyPair";
import { Workflows, WorkflowsQueryParams } from "../model/Workflow";
import { RouterParams } from "../router/RouterParams";
import TenantConfigurationSendPluginForm from "./TenantConfigurationSendPluginForm";
import TenantConfigurationWorkflowsForm from "./TenantConfigurationWorkflowsForm";
import TenantConfigurationZipInterpreterForm from "./TenantConfigurationZipInterpreterForm";
import { listConnectors } from "../api/ConnectorAPI";
import { ConnectorsQueryParams } from "../model/Connector";
import TenantConfigurationSAPTaxEquivalenceForm from "./TenantConfigurationSAPTaxEquivalence";

export default function TenantConfigurationSAPForm({ match }: RouterParams) {
    const [status, setStatus] = useState<string>("loading");
    const [tenantConfiguration, setTenantConfiguration] = useState<TenantConfiguration>();
    const [keyPairs, setKeyPairs] = useState<KeyPairs>();
    const [workflows, setWorkflows] = useState<Workflows>();
    const [connectorIds, setConnectorIds] = useState<string[]>();
    const [connectorLabels, setConnectorLabels] = useState<string[]>();

    useEffect(() => {
        const getConfiguration = get(match.params.tenantId);
        const keyPairsPromise = getKeyPairs(match.params.tenantId, 0, 0, { search: "" } as KeyPairQueryParams);
        const workflowsPromise = listWorkflows(match.params.tenantId, 0, 0, { search: "" } as WorkflowsQueryParams);
        const connnectorsPromise = listConnectors(match.params.tenantId, 0, 0, {
            search: "",
            direction: "OUTPUT",
            family: "CFDIS",
            includeDefault: false,
            availableForZip: true,
        } as ConnectorsQueryParams);

        Promise.all([getConfiguration, keyPairsPromise, workflowsPromise, connnectorsPromise])
            .then(([configuration, keyPairs, workflows, connectors]) => {
                setTenantConfiguration(configuration);
                setKeyPairs(keyPairs);
                setWorkflows(workflows);

                setConnectorIds(connectors.items.map(el => el.id));
                setConnectorLabels(connectors.items.map(el => el.name ?? `${el.format}`));

                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
    }, [match.params.tenantId]);

    if (status === "loading") {
        return <Progress />
    }

    if (status !== "loaded") {
        return <Error text={status} />
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        <Grid item xs={12}>
                            <TenantConfigurationSendPluginForm tenantId={match.params.tenantId}
                                erp={tenantConfiguration?.erp}
                                keyPairs={keyPairs!} />
                        </Grid>
                        <Grid item xs={12}>
                            <TenantConfigurationWorkflowsForm tenantId={match.params.tenantId}
                                erp={tenantConfiguration?.erp}
                                workflows={workflows!} />
                        </Grid>
                        <Grid item xs={12}>
                            <TenantConfigurationSAPTaxEquivalenceForm tenantId={match.params.tenantId}
                                taxEquivalences={tenantConfiguration?.tax_equivalences ?? []} />
                        </Grid>
                        <Grid item xs={12}>
                            <TenantConfigurationZipInterpreterForm tenantId={match.params.tenantId}
                                definition={tenantConfiguration?.erp?.import_definition}
                                connectorIds={connectorIds ?? []}
                                connectorLabels={connectorLabels ?? []} />
                        </Grid>
                        <Grid item xs={12}>
                            <TenantConfigurationZipInterpreterForm tenantId={match.params.tenantId}
                                definition={tenantConfiguration?.erp?.export_definition}
                                connectorIds={connectorIds ?? []}
                                connectorLabels={connectorLabels ?? []}
                                export />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}