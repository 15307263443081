import { callAPI, HTTPMethod } from './API';
import { TenantFieldCatalog, TenantField, TenantAddress, TenantContact, TenantFieldBankAccount, TenantFieldsRequest, TenantFields } from '../model/TenantFieldCatalog';

export async function getTenantFieldsCatalog(tenantId: string): Promise<TenantFieldCatalog> {
  return await callAPI(`/tenants/${tenantId}/tenant-fields`, {
    method: HTTPMethod.GET,
  });
}

export async function getTenantFields(tenantId: string, request: TenantFieldsRequest): Promise<TenantFields> {
  return await callAPI(`/tenants/${tenantId}/tenant-fields`, {
    method: HTTPMethod.POST,
    body: request,
  });
}

export async function updateCatalogFields(
  tenantId: string,
  request: TenantField
): Promise<TenantFieldCatalog> {
  return await callAPI(`/tenants/${tenantId}/tenant-fields/fields`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}

export async function updateCatalogAddresses(tenantId: string, request: TenantAddress): Promise<TenantFieldCatalog> {
  return await callAPI(`/tenants/${tenantId}/tenant-fields/addresses`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}

export async function updateCatalogContacts(tenantId: string, request: TenantContact): Promise<TenantFieldCatalog> {
  return await callAPI(`/tenants/${tenantId}/tenant-fields/contacts`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}

export async function updateBankAccounts(tenantId: string, request: TenantFieldBankAccount): Promise<TenantFieldCatalog> {
  return await callAPI(`/tenants/${tenantId}/tenant-fields/bank-accounts`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}
