import React, { useState, useEffect, useContext } from "react";
import PaymentOrderOverview from "./PaymentOrderOverview";
import CreateUpdatePaymentOrderView from "./CreateUpdatePaymentOrderView";
import { PaymentOrder, ChangeStatusRequest } from "../model/PaymentOrder";
import { RouterParams } from "../router/RouterParams";
import { Redirect } from "react-router-dom";
import Progress from "../components/Progress";
import { Typography } from "@material-ui/core";
import {  get, changeStatus } from "../api/PaymentOrderApi";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import PendingPaymentSelectCfdis from "../payments/SelectCfdisToPayView";
import { CfdisSelected, PaymentData, Payment} from "../model/Payment";
import CreatePaymentView from "../payments/CreatePaymentView";

export default function PaymentOrderDetails({match}: RouterParams) {
    const [view, setView] = useState<string>("details");
    const [paymentOrder, setPaymentOrder] = useState<PaymentOrder>();
    const [viewPaymentOrder, setViewPaymentOrder] = useState<"create" | "update">("create");
    const [redirect, setRedirect] = useState<string | undefined>();
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);
    const [cfdisSelected, setCfdisSelected] = useState<CfdisSelected>({} as CfdisSelected);
    const [infoProvider, setInfoProvider] = useState<PaymentData>({} as PaymentData);
    const [fromPaymentOrder, setFromPaymentOrder] = useState<boolean>(false);
    const [paymentOrderId, setPaymentOrderId] = useState<string>("");

    const load = () => {
        get(context.session!.tenant!.id, match.params.paymentOrderId).then((response) => {
            setPaymentOrder(response);
            if(match.params.edit){
                setView("edit");
                setViewPaymentOrder("update");
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        
    };

    useEffect(load, [match.params]);

    const onEditPaymentOrder = (paymentOrder: PaymentOrder) => {
        setView("edit");
        setViewPaymentOrder("update");
        setPaymentOrder(paymentOrder);
    }

    const onCancel = () => {
        if(match.params.edit){
            setRedirect(`/payment-order/`+match.params.backTo);
        }else{
            setViewPaymentOrder("create");
            setView("details");
        }
       
    }

    const onCancelSelectCfdis = () => {
        setCfdisSelected({} as CfdisSelected);
        setInfoProvider({} as PaymentData);
        if(viewPaymentOrder === "update"){
            setView("edit");
        }else{
            setViewPaymentOrder("create");
            setView("details");
        }
    }

    const savePaymentOrder = (result: PaymentOrder) => {
        setPaymentOrder(undefined);
        let backTo = match.params.backTo.split("._.");
        setRedirect(`/payment-order/${backTo[0]}?success=${translate("payment_order.success_update", {"op": result.operation_number})}`);
    }

    const onCloseDetails = () => {
        setRedirect(`/payment-order/`+match.params.backTo.replace("._.", "?"));
    }

    const sendToPendingExecute = (status: string) => {
        changeStatus(context.session!.tenant!.id, match.params.paymentOrderId, { status: status } as ChangeStatusRequest).then((response) => {
            let backTo = match.params.backTo.split("._.");
            setRedirect(`/payment-order/${backTo[0]}?success=${translate("payment_order.success_update", {"op": response.operation_number})}`);
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const goToCreatePayment = (info: CfdisSelected) => {
        setCfdisSelected(info);
        setView("create-payment");
    }

    const savePayment = (result: Payment) => {
        get(context.session!.tenant!.id, match.params.paymentOrderId).then((response) => {
            setPaymentOrder(response);
            if(match.params.edit){
                setView("edit");
                setViewPaymentOrder("update");
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setView("details"); 
            setViewPaymentOrder("create");
            setInfoProvider({} as PaymentData);
            setCfdisSelected({} as CfdisSelected);
        });
    }

    const payCfdi = (paySelected: CfdisSelected, paymentOrderId: string, paymentMethod: string) => {
        let info_Provider = {} as PaymentData;
        info_Provider.provider_id =paySelected.cfdis[0].metadata.provider_id;
        info_Provider.provider_name= paySelected.cfdis[0].cfdi != null ? paySelected.cfdis[0].cfdi.emisor.nombre : "";
        info_Provider.currency = paySelected.cfdis[0].currency;
        info_Provider.amount = 0;
        info_Provider.payment_method = paymentMethod;
        setInfoProvider(info_Provider);
        setPaymentOrderId(paymentOrderId);
        setFromPaymentOrder(true);
        setCfdisSelected(paySelected);
        if(paySelected.cfdis.length > 1){
            setView("select-cfdis");
        }else{
            setView("create-payment");
        }
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !paymentOrder) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    switch (view) {
        case "details": 
            return ( <PaymentOrderOverview paymentOrder={paymentOrder} onEditPaymentOrder={onEditPaymentOrder} onPaidCfdi={payCfdi}
                onClose={onCloseDetails} sendToPendingExecute={sendToPendingExecute} /> );
        case "edit": 
            return ( <CreateUpdatePaymentOrderView  next={savePaymentOrder} onCancel={onCancel} view={viewPaymentOrder} paymentOrder={paymentOrder} payCfdi={payCfdi} /> );
        case "select-cfdis": 
            return ( <PendingPaymentSelectCfdis onCancel={onCancelSelectCfdis} next={goToCreatePayment} infoProvider={infoProvider} fromPaymentOrder={fromPaymentOrder} cfdisSelected={cfdisSelected} /> );
        case "create-payment": 
            return ( <CreatePaymentView  next={savePayment} onCancel={onCancel} infoProvider={infoProvider} cfdisSelected={cfdisSelected} readOnly={false} fromPaymentOrder={fromPaymentOrder} paymentOrderId={paymentOrderId} /> );
        default:
            return ( <PaymentOrderOverview paymentOrder={paymentOrder} onEditPaymentOrder={onEditPaymentOrder} onPaidCfdi={payCfdi}
                 onClose={onCloseDetails} sendToPendingExecute={sendToPendingExecute} /> );
    }
    
}