import { callAPI, HTTPMethod } from "./API";
import { TenantBankAccountType, TenantBankAccountTypes, TenantBankAccountTypesRequest } from "../model/TenantBankAccountType";

export async function list(tenantId: string): Promise<TenantBankAccountTypes> {
    return await callAPI(`/tenants/${tenantId}/tenant-bank-account-types`, {
        method: HTTPMethod.GET
    });
}

export async function assign(tenantId: string, request: TenantBankAccountTypesRequest): Promise<TenantBankAccountTypes> {
    return await callAPI(`/tenants/${tenantId}/tenant-bank-account-types`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function prevalidateDelete(tenantId: string, bankAccountTypeId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/tenant-bank-account-types/${bankAccountTypeId}/prevalidate-delete`, {
        method: HTTPMethod.POST
    });
}

export async function deleteTenantBankAccountType(tenantId: string, bankAccountTypeId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/tenant-bank-account-types/${bankAccountTypeId}`, {
        method: HTTPMethod.DELETE
    });
}