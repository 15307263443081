import React, { useContext }  from "react";
import { AppContext } from "../context/AppContext";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import translate from "../i18n/Translator";
import { Entity as Provider, getCompanyNames } from "../model/Provider";
import { GridableColumn } from "../components/Gridable";
import { ProviderListCallbacks, ProviderNameColumnTitle, ProviderNameColumnValue } from "./Providers";
import ProvidersReceptionList from "./ProvidersReceptionList";

export default function ProvidersControlReceptionView() {
    const context = useContext(AppContext);
    const hasSecondAuthorizator = context.isGrantedAny(["CfdisSecondAuthorizationRead"]);

    const receptors = (provider: Provider) => {
        if(!provider.receptor_users || provider.receptor_users.length === 0) return "---";
        return provider.receptor_users.map((user) => (user.name)).join(",");
    };

    const secondAuthorizators = (provider: Provider) => {
        if(!provider.second_authorizator_users || provider.second_authorizator_users.length === 0) return "---";
        return provider.second_authorizator_users.map((user) => (user.name)).join(",");
    };

    const classifications = (provider: Provider) => {
        if (provider.provider_classifications?.length) {
            return provider.provider_classifications.filter(c => !!c).map((c) => c.name).join(", ")
        }
        return "---";
    };

    const getColumns = (providers: Provider[], showChecks: boolean, selected: string[], callbacks: ProviderListCallbacks) => {
        let all = [
            {
                title: (
                    <ProviderNameColumnTitle
                        total={providers.length}
                        selected={selected.length}
                        showChecks={showChecks}
                        callbacks={callbacks} />
                ),
                converter: (provider) => (
                    <ProviderNameColumnValue
                        provider={provider}
                        checked={selected.indexOf(provider.id) >= 0}
                        showChecks={showChecks}
                        callbacks={callbacks} />
                ),
                fullWidth: true,
                xs: true,
            },
            {
                title: translate("providers.companies.title") as string,
                converter: (provider) => getCompanyNames || "---",
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                xl: 2
            },
            {
                title: translate("providers.receptor_column") as string,
                converter: receptors,
                xs: false,
                sm: 4,
                md: 4,
                lg: hasSecondAuthorizator ? 1 : 2,
                xl: hasSecondAuthorizator ? 1 : 2
            },
            {
                title: translate("providers.second_authorizator.column") as string,
                converter: secondAuthorizators,
                xs: false,
                lg: 1,
                xl: 1
            },
            {
                title: translate("workflows.title") as string,
                converter: (provider) => provider.workflows_response && provider.workflows_response.length > 0 ? provider.workflows_response.map((wf) => wf.name).join(', ') : "---",
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                xl: 2
            },
            {
                title: translate("providers.specific_rule") as string,
                converter: (provider) => provider.specific_rule?.name || "---",
                xs: false,
                sm: false,
                md: false,
                lg: 1,
                xl: 1
            },
            {
                title: translate("providers.classifications.title") as string,
                converter: classifications,
                xs: false,
                sm: false,
                md: false,
                lg: 1,
                xl: 1
            },
            {
                title: (
                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                ),
                converter: (provider) => (
                    <IconButton aria-label="options"
                        color="default"
                        size="small"
                        onClick={callbacks.onClickedOptions(provider)}
                        disabled={selected.length > 0}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: "auto"
            }
        ] as GridableColumn<Provider>[];

        if(hasSecondAuthorizator){
            return all.filter((column) => column !== null);
        } else {
            return all.filter((column) => column !== null && column.title !== translate("providers.second_authorizator.column") as string);
        }
    };

    return (
        <ProvidersReceptionList getColumns={getColumns} subtitle={translate("providers.views.control_reception") as string} />
    );

}
