import React, { useContext, useEffect} from "react";
import { useOktaAuth } from "@okta/okta-react";

import { oauthSignin } from "../api/AuthAPI";
import { AppContext } from "../context/AppContext";
import { OauthSignInRequest } from "../model/SignIn";

interface OAuthRedirectLoginParams {
    tenantId: string;
}

export default function OAuthRedirectLogin(params: OAuthRedirectLoginParams) {

    const context = useContext(AppContext);
    const tenantId = params.tenantId;

    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }
        
        if (!authState.isAuthenticated) {
            oktaAuth.signInWithRedirect();
            return;
        } 
        
        if(!authState.accessToken) {
            return;
        }

        oauthSignin({
            access_token: authState.accessToken?.accessToken,
            tenant_id: tenantId
        } as OauthSignInRequest).then((response) => {
            //context.onSignedIn();
            window.location.href = '/';
        }).catch((error) => {
            console.log(error.message);
        });
        
      }, [authState, oktaAuth]); 

    if(!authState) {
        return <div>Loading...</div>;
    }
    
    if(!authState.isAuthenticated) {
        return <div>Redirecting to OAuth authentication...</div>;
    }

    return <div>Logged in... starting session</div>;

}
