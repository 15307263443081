import React, { useCallback, useEffect, useRef, useState } from "react";
import translate from "../i18n/Translator";
import { Grid as Gridable, Table, TableHeaderRow, Toolbar, VirtualTable, ExportPanel, TableGroupRow, TableFilterRow, TableFixedColumns, PagingPanel, TableSelection, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, DataTypeProvider, DataTypeProviderProps, SelectionState, IntegratedSelection, GroupingState, IntegratedGrouping, FilteringState, PagingState, CustomPaging, Filter, Column, Grouping, RowDetailState, Sorting } from '@devexpress/dx-react-grid';
import NumberFormat from "react-number-format";
import DateFormat from "./DateFormat";
import InputForFilter from "../components/InputForFilter";
import { Grid, Box, IconButton, Button, MenuItem, ListItemIcon, Typography, Checkbox } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomBackdrop from "../components/CustomBackdrop";
import { GridExporter } from '@devexpress/dx-react-grid-export';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import { Cell } from 'exceljs';
import { Column as Column_2 } from '@devexpress/dx-react-grid/dist/dx-react-grid';
import FileSaver from "file-saver";

export interface GridDxProps<T> {
    rows: T[];
    lineIdentifier?: string;
    secondIdentifier?: string;
    columns: Column[];
    heightTablePX?: number;
    clickRowColumns?: string[];
    defaultExpandedGroups?: string[];
    amountCurrencyColumns?: string[];
    quantityColumns?: string[];
    dateColumns?: string[];
    textColumns?: string[];
    columnsFormat: any[];
    sorting?: Sorting[];
    leftColumns?: any[];
    rightColumns?: any[];
    emptyMessage?: string;
    grouping?: Grouping[];
    page?: number;
    pageSize?: number;
    totalRows?: number;
    selectionIds?: (number | string)[];
    customFormatColumns?: JSX.Element[];
    filters?: Filter[];
    loading: boolean;
    noUseId?: boolean;
    excelFile?: string;
    showTimeInDates?: boolean;
    showSecondsInDate?: boolean;
    getAll?: boolean;
    limitRangeMonths?: number;
    elementInLineMenu?: React.ComponentType<ToggleButtonProps>;
    onClickRow(item: T): any;
    onClickedOptions?(item: T): any;
    setFiltersHandler?(filters : Filter[]): any;
    setSelectionHandler?(selected: (number | string)[]): any;
    onChangedPage?(page: number): any;
    onChangedPageSize?(pageSize: number): any;
    rowDetailComponent?(props: TableRowDetail.ContentProps): any;
    onShowRangeError?(): any;
    setTableCell?(props: any): any;
    setCheckCell?(props: any, header: boolean): any;
    menuFixed?(item: T): any;
}

export default function GridDx<T>(props: GridDxProps<T>) {

    const [rows, setRows] = useState<any[]>();
    const getPageSizes = () =>{
        if(props.getAll){
            return [20, 50, 100,  0];
        }else{
            return [20, 50, 100]
        }
    }
    const load = () => {
        setRows(props.rows.map(item => {
            return {
                ...item,
                menu: props.menuFixed ? props.menuFixed(item) : 
                    <IconButton aria-label="options" color="default" size="small" 
                        onClick={props.onClickedOptions ? props.onClickedOptions(item) : undefined}>
                        <MoreVertIcon />
                    </IconButton>
            };
            
        }));
    }

    useEffect(load, [props]);
    
    const setFiltersHandler = (filters : Filter[]) => {
        if(props.setFiltersHandler && validateFilters(filters)) {
            props.setFiltersHandler(filters);
        }
        
    };

    const validateFilters = (filtersFromGrid : Filter[]) => {
        if(props.filters && filtersFromGrid.length !== props.filters.length){
            props.filters.forEach(filter => {
                if(!filtersFromGrid.find(filterFromGrid => filter.columnName === filterFromGrid.columnName)){
                    filtersFromGrid.push({ columnName: filter.columnName, value: ""});
                }
            });
        }
        return props.filters && filtersFromGrid.length === props.filters.length;
    };

    const CurrencyTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => (
            <NumberFormat value={value.value} prefix="$" decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
        )} {...props} />
    );

    const QuantityTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const TextTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const DateTypeProvider = (params: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => (
            <DateFormat date={value.value} format={props.showTimeInDates ? "DD-MM-YYYY h:mm a" : props.showSecondsInDate ? "YYYY-MM-DD HH:mm:ss" : "L"} zone={props.showTimeInDates} />
        )} {...params} />
    );

    const MenuTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const getRowId = (row: any) => props.lineIdentifier && props.secondIdentifier ? `${row[props.lineIdentifier]}|${row[props.secondIdentifier]}` : (props.lineIdentifier ? row[props.lineIdentifier] : row.id);

    const FilterEditor = ( params: DataTypeProvider.ValueEditorProps ) => {
        const onChange = (event : any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() !== params.value) {
                params.onValueChange(targetValue);
                return;
            }
        };
        if( ( props.quantityColumns && props.quantityColumns.find(column => column === params.column.name) ) ||
            ( props.amountCurrencyColumns && props.amountCurrencyColumns.find(column => column === params.column.name) ) ){
                return (
                    <Box pl={1} pr={2}>
                        <InputForFilter
                            type="number"
                            value={params.value ? params.value : ''}
                            onChange={onChange}
                        />
                    </Box>
                );   
        } else if ( props.dateColumns && props.dateColumns.find(column => column === params.column.name) ){
            return (
                    <InputForFilter
                            type="date"
                            value={params.value ? params.value : ''}
                            limitMonths={props.limitRangeMonths}
                            onShowRangeError={props.onShowRangeError}
                            onChange={onChange}
                        />
            );
        }
        return (
            <Box pl={1} pr={2}>
                <InputForFilter
                    type="text"
                    value={params.value ? params.value : ''}
                    onChange={onChange}
                />
            </Box>
        );
    };

    const ClicRowTypeProvider = (params: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return (<Button color="default" size="small" onClick={() => props.onClickRow(value.row)}>
            {value.value}
            </Button> );
        }} {...params} />
    );

    const exporterRef = useRef<any>(null);

    const startExport = useCallback(() => {
        if(exporterRef){
            exporterRef.current.exportGrid();
        }
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), props.excelFile+'.xlsx');
        });
    };

    const customizeCell = (cell: Cell, row: any, column: Column_2) => {
        if( props.dateColumns && props.dateColumns.find(col => col === column.name) && !props.showTimeInDates && !props.showSecondsInDate){
            let temp = new Date(row[column.name]);
            cell.value = String(temp.getDate()).padStart(2, '0') + "/" +  String(temp.getMonth() + 1).padStart(2, '0') + "/" + temp.getFullYear();
        }

        if( props.dateColumns && props.dateColumns.find(col => col === column.name) && props.showTimeInDates && !props.showSecondsInDate){
            let temp = new Date(row[column.name]);
            cell.value = String(temp.getDate()).padStart(2, '0') + "/" +  String(temp.getMonth() + 1).padStart(2, '0') + "/" + temp.getFullYear()+" "+temp.getHours()+":"+temp.getMinutes()+":"+temp.getSeconds();
        }

        if( props.amountCurrencyColumns && props.amountCurrencyColumns.find(col => col === column.name)){
            let temp = Number(row[column.name]);
            cell.model.style.alignment = { horizontal: "right"};
            cell.value = temp.toLocaleString();
        }

        if(String(row[column.name]).startsWith("http")){
            cell.model.hyperlink = row[column.name];
            cell.model.type = 5;
            cell.model.text = translate("buttons.download") as string;
            cell.model.style.font = { color: { argb: "0000FF"}};
        }
    };

    const styles = {
        banking: {
          backgroundColor: '#f5f5f5',
        },
        health: {
          backgroundColor: '#a2e2a4',
        },
        telecom: {
          backgroundColor: '#b3e5fc',
        },
        energy: {
          backgroundColor: '#ffcdd2',
        },
        insurance: {
          backgroundColor: '#f0f4c3',
        },
      };

      const setCheckCell = (restProps: any, header: boolean) => {
        return props.setCheckCell ? props.setCheckCell(restProps, header) : {};
      };

    return (
            <Grid item xs={12} >
                <Box px={2} display="flex" alignItems="center" justifyContent={"flex-start"} className="GridableCell">
                    <Box overflow={"auto hidden"} >
                        <Gridable
                            rows={rows ? rows : []}
                            columns={props.columns}
                            getRowId={props.noUseId ? undefined : getRowId}>

                            <MenuTypeProvider for={["menu", "icons"]} editorComponent={NoFilterEditor}/>

                            {props.clickRowColumns && <ClicRowTypeProvider for={props.clickRowColumns}/>}
                            {props.amountCurrencyColumns && <CurrencyTypeProvider for={props.amountCurrencyColumns} editorComponent={FilterEditor}/>}
                            {props.quantityColumns && <QuantityTypeProvider for={props.quantityColumns} editorComponent={FilterEditor} />}
                            {props.dateColumns && <DateTypeProvider for={props.dateColumns} editorComponent={FilterEditor} />}
                            {props.textColumns && <TextTypeProvider for={props.textColumns} editorComponent={FilterEditor} />}
                            {props.customFormatColumns ? props.customFormatColumns.map(plugin => plugin): undefined}

                            <SortingState sorting={props.sorting} />
                            <IntegratedSorting />

                            {props.selectionIds && 
                                <SelectionState selection={props.selectionIds} onSelectionChange={props.setSelectionHandler} /> 
                            }
                            {props.selectionIds && <IntegratedSelection /> }

                            <GroupingState grouping={props.grouping} defaultExpandedGroups={props.defaultExpandedGroups}/>
                            <IntegratedGrouping />

                            <RowDetailState />
                            

                            <FilteringState
                                filters={props.filters}
                                onFiltersChange={setFiltersHandler}
                            />
                            {props.onChangedPage ? 
                                <PagingState
                                currentPage={props.page}
                                onCurrentPageChange={props.onChangedPage}
                                onPageSizeChange={props.onChangedPageSize}
                                pageSize={props.pageSize} />
                            : undefined}
                            
                            {props.onChangedPage ? 
                                <CustomPaging totalCount={props.totalRows} />
                            : undefined}
                            {props.setTableCell ?
                                <VirtualTable
                                    cellComponent={props.setTableCell}
                                    height={props.heightTablePX ? props.heightTablePX : "calc(100vh - 335px)"}
                                    columnExtensions={props.columnsFormat}
                                    messages={{noData: props.emptyMessage ? props.emptyMessage : translate("reports.empty") as string}}  />
                            : 
                                <VirtualTable
                                    height={props.heightTablePX ? props.heightTablePX : "calc(100vh - 335px)"}
                                    columnExtensions={props.columnsFormat}
                                    messages={{noData: props.emptyMessage ? props.emptyMessage : translate("reports.empty") as string}}  />
                            }
                            <TableHeaderRow 
                                showSortingControls messages={{ sortingHint: translate('buttons.sort') as string }} />
                            {props.rowDetailComponent ? <TableRowDetail contentComponent={props.rowDetailComponent} /> : undefined}
                            {props.selectionIds && props.setCheckCell ? 
                                <TableSelection showSelectAll selectionColumnWidth={40} 
                                    cellComponent={(props: any) =>setCheckCell(props, false)} 
                                    headerCellComponent={(props: any) => setCheckCell(props, true)} />
                            : props.selectionIds &&
                                <TableSelection showSelectAll selectionColumnWidth={40} />
                            }
                            <TableGroupRow />
                            <TableFilterRow />
                            <TableFixedColumns leftColumns={props.leftColumns} rightColumns={props.rightColumns}/>
                            {props.onChangedPage ?
                                <PagingPanel 
                                    pageSizes={getPageSizes()}                                     
                                    messages={{ 
                                        rowsPerPage: translate('pagination.rows_per_page') as string, 
                                        showAll: translate('pagination.all') as string, 
                                        info: (parameters: {from:number,to:number,count:number}) => 
                                            translate('pagination.info', {"from": parameters.from, "to": parameters.to, "total": parameters.count}) as string 
                                    }}
                                />
                            : undefined}
                            {props.excelFile && <Toolbar />}
                            {props.excelFile && 
                                <ExportPanel 
                                    startExport={startExport}
                                    toggleButtonComponent={props.elementInLineMenu ? props.elementInLineMenu : ToggleButton}
                                    messages={{exportAll: translate("reports.cxp.export") as string}}
                                    menuItemComponent={ItemMenu}
                                />
                            }
                        </Gridable>
                        <GridExporter
                            ref={exporterRef}
                            rows={rows ? rows : []}
                            columns={props.columns}
                            onSave={onSave}
                            customizeCell={customizeCell}
                        />
                        <CustomBackdrop open={props.loading} message={translate("cfdis.processing") as string} />
                    </Box>
                </Box>
            </Grid>
    );
}

export const NoFilterEditor = ( params: DataTypeProvider.ValueEditorProps ) => { return ( <></> ); };

interface ToggleButtonProps {
    onToggle(): any,
    getMessage(key: string): string,
    buttonRef(ref: any): any,
}

const ToggleButton = (props: ToggleButtonProps) => (
    <IconButton
        onClick={props.onToggle}
        ref={props.buttonRef}>
        <MoreVertIcon />
    </IconButton>
);

interface ItemMenuProps {
    onClick(): any,
    text: string,
}

const ItemMenu = (props: ItemMenuProps) => (
    <MenuItem onClick={props.onClick} dense>
        <ListItemIcon>
            <DownloadList fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography variant="inherit">{props.text}</Typography>
    </MenuItem>
);