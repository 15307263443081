import React, { useState, useEffect, useContext } from "react";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ClarificationPayableDocumentDetail } from "../model/Clarification";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { Payment as PaymentResponse, PaymentCfdi } from "../model/Payment";
import { Cfdi as CfdiResponse } from "../model/Cfdi";
import Progress from "../components/Progress";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { Grid as GridDx, Table, TableHeaderRow, TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, DataTypeProvider, DataTypeProviderProps, SummaryState, GroupingState, IntegratedGrouping, IntegratedSummary } from '@devexpress/dx-react-grid';
import NumberFormat from "react-number-format";
import DateFormat from "../components/DateFormat";

interface ClarificationPayableDocumentsDetailSectionProps {
  cfdis?: CfdiResponse[];
  payments?: PaymentResponse[];
}

export default function ClarificationPayableDocumentsDetailSection(props : ClarificationPayableDocumentsDetailSectionProps) {
  const context = useContext(AppContext);
  const [status, setStatus] = useState<string>("loading");
  const [dataCfdis, setDataCfdis] = useState<ClarificationPayableDocumentDetail[]>();
  const [dataPayments, setDataPayments] = useState<ClarificationPayableDocumentDetail[]>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();

  const load = () => {
    setStatus("loading");
    if(props.cfdis){
      setDataCfdis(tranformCfdisResponse(props.cfdis));
    } else if (props.payments) {
      setDataPayments(tranformPaymentsResponse(props.payments));
    }
    setStatus("loaded");
}

  useEffect(load, [context.session, props]);

  const onCloseSnackbars = () => {
    setError(undefined);
    setSuccess(undefined);
  };

  const tranformCfdisResponse = (cfdis: CfdiResponse[], payment?: PaymentResponse) => {
    let cfdisDetail = [] as ClarificationPayableDocumentDetail[];
    cfdis.forEach((cfdi) => {
      let item = {
        cfdi_id : cfdi.id, 
        folio : cfdi.identifier,
        date : cfdi.date,
        total : cfdi.total
      } as ClarificationPayableDocumentDetail;
      if(payment){
        item.operation_number = payment.operation_number;
        item.date = payment.payment_date || payment.deadline_payment_cfdi;
        let paymentCfdi = matchPaymentCfdi(payment.cfdis, cfdi.id);
        if(paymentCfdi) {
          item.total = paymentCfdi.amount;
        }
      }
      cfdisDetail.push(item);
    });
    return cfdisDetail;
  };

  const matchPaymentCfdi = (paymentCfdis : PaymentCfdi[], cfdiId: string) => {
    return paymentCfdis.find(c => c.id === cfdiId);
  };

  const tranformPaymentsResponse = (payments : PaymentResponse[]) => {
    let paymentsDetail = [] as ClarificationPayableDocumentDetail[];
    payments.forEach((payment) => {
      if(payment.list_cfdis){
        paymentsDetail.push.apply(paymentsDetail, tranformCfdisResponse(payment.list_cfdis, payment));
      }
    });
    return paymentsDetail;
  };

  if (status === "loading") {
    return (<Progress />);
  }

  if (status !== "loaded" || (!dataCfdis && !dataPayments)) {
    return (
      <Typography variant="body1" component="h5" color="error" align="center">
          {status}
      </Typography>
  );
  }

  return (
    <Grid>
      {dataCfdis && <DetailPayableDocuments items={dataCfdis}/>}
      {dataPayments && <DetailPayment items={dataPayments}/>}
      <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
      </Grid>
  );
}

interface DetailPaymentProps {
  items: ClarificationPayableDocumentDetail[];
}

export function DetailPayment(props : DetailPaymentProps) {

  const CurrencyFormatter = (value: any) => (
    <NumberFormat value={value.value} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
  );

  const CurrencyTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
  );

  const DateFormatter = (value: any) => (
    <DateFormat date={value.value} format="DD-MM-YYYY" zone={false} />
  );

  const DateTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  const tableColumnExtensions = [
    { columnName: 'folio', width: '20%' },
    { columnName: 'date', width: '20%' },
    { columnName: 'total', align: 'right' },
  ] as any;

  const currencyColumns = ['total'];

  const dateColumns = ['date'];

  const grouping = [{ columnName: 'operation_number' }];

  const groupSummaryItems = [
    { columnName: 'folio', type: 'count' },
    { columnName: 'total', type: 'sum', showInGroupFooter: false, alignByColumn: true },
  ];

  return (
    <Grid>
      <GridDx
        rows={props.items}
        columns={[
          {
            name: 'operation_number',
            title: translate('cfdis.aclaration_payable_documents.columns.operation_number') as string
          },
          {
            name: 'folio',
            title: translate('cfdis.aclaration_payable_documents.columns.invoice') as string
          },
          {
            name: 'date',
            title: translate('cfdis.aclaration_payable_documents.columns.date') as string
          },
          {
            name: 'total',
            title: translate("cfdis.aclaration_payable_documents.columns.total_payed") as string
          }
        ]}>

        <CurrencyTypeProvider for={currencyColumns} />
        <DateTypeProvider for={dateColumns} />
        <SortingState />
        <IntegratedSorting />
        <GroupingState grouping={grouping} />
        <SummaryState groupItems={groupSummaryItems} />
        <IntegratedGrouping />
        <IntegratedSummary />
        <Table columnExtensions={tableColumnExtensions} messages={{noData: translate("reports.empty") as string}} />
        <TableHeaderRow showSortingControls messages={{ sortingHint: translate('buttons.sort') as string }} />
        <TableGroupRow showColumnsWhenGrouped messages={{ sum: translate("total") as string }} />
      </GridDx>
      </Grid>
  );
}

interface DetailPayableDocumentsProps {
  items: ClarificationPayableDocumentDetail[];
}

export function DetailPayableDocuments(props : DetailPayableDocumentsProps) {

  const CurrencyFormatter = (value: any) => (
    <NumberFormat value={value.value} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
  );

  const CurrencyTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
  );

  const DateFormatter = (value: any) => (
    <DateFormat date={value.value} format="DD-MM-YYYY" zone={false} />
  );

  const DateTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  const TooltipTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={TooltipFormatter} {...props} />
  );

  const TooltipFormatter = (value: any) => (
    <Tooltip title={value.value}>
      <div> {value.value} </div>
    </Tooltip>
  );

  const tableColumnExtensions = [
    { columnName: 'date', width: '20%'  },
    { columnName: 'folio'},
    { columnName: 'total', align: 'right', width: '20%'  },
  ] as any;

  const currencyColumns = ['total'];

  const dateColumns = ['date'];

  const tooltipColumns = ['folio'];

  return (
    <Grid>
      <GridDx
        rows={props.items}
        columns={[
          {
            name: 'date',
            title: translate('cfdis.aclaration_payable_documents.columns.date') as string
          },
          {
            name: 'folio',
            title: translate('cfdis.aclaration_payable_documents.columns.invoice') as string
          },
          {
            name: 'total',
            title: translate("cfdis.aclaration_payable_documents.columns.total") as string
          }
        ]}>

        <CurrencyTypeProvider for={currencyColumns} />
        <DateTypeProvider for={dateColumns} />
        <TooltipTypeProvider for={tooltipColumns} />
        <SortingState />
        <IntegratedSorting />
        <Table columnExtensions={tableColumnExtensions} messages={{noData: translate("reports.empty") as string}} />
        <TableHeaderRow showSortingControls messages={{ sortingHint: translate('buttons.sort') as string }} />
      </GridDx>
      </Grid>
  );
}