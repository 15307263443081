import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { getPayments, exportAllCfdisPayment, exportCfdisPayment, deletePayment, exportExcelPayments, listPaymentStatus, isBlockedDelete, cancelPaymentCfdi } from "../api/PaymentApi";
import {get as getConfiguration} from "../api/TenantConfigurationApi";
import { Grid, Divider, Button, IconButton } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Payment, Payments, PaymentListParams, PaymentDeleteRequest } from "../model/Payment";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import ArchiveIcon from "@material-ui/icons/Archive";
import PaymentArchivedCfdiMenu from "./PaymentArchivedCfdiMenu";
import { PaymentGridProps } from "./PaymentList";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { validateGenerateInMembership } from "../api/ProviderMembershipTaxStampApi";
import { TenantCfdisRequest, ExportCfdisResponse } from "../model/TenantCfdi";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import RejectPaymentPopup from "./RejectPaymentPopup";
import PaymentsGlobalMenu from "./PaymentsGlobalMenu";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import { Filter } from '@devexpress/dx-react-grid';

import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { formatDateString, concatDates } from "../components/DateFormat";
import RequestClarification from "../clarifications/RequestClarification";
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function ListPaymentsArchived(props: PaymentGridProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): PaymentListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "payment_status": typeof qs["payment_status"] === "string" ? qs["payment_status"] as string : "",
            "provider_id": typeof qs["provider_id"] === "string" ? qs["provider_id"] as string : "",
            "operation_number": typeof qs["operation_number"] === "string" ? qs["operation_number"] as string : "",
            "provider_name": typeof qs["provider_name"] === "string" ? qs["provider_name"] as string : "",
            "comprobantes": typeof qs["comprobantes"] === "string" ? qs["comprobantes"] as string : "",
            "total_amount": typeof qs["total_amount"] === "string" ? qs["total_amount"] as string : "",
            "payment_start_date": typeof qs["payment_start_date"] === "string" ? qs["payment_start_date"] as string : "",
            "payment_end_date": typeof qs["payment_end_date"] === "string" ? qs["payment_end_date"] as string : "",
            "start_deadline_payment_cfdi": typeof qs["start_deadline_payment_cfdi"] === "string" ? qs["start_deadline_payment_cfdi"] as string : "",
            "end_deadline_payment_cfdi": typeof qs["end_deadline_payment_cfdi"] === "string" ? qs["end_deadline_payment_cfdi"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
        } as PaymentListParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Payments>();
    const [params, setParams] = useState<PaymentListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<PaymentListParams>(paramsFromQueryString);

    const [payment, setPayment] = useState<Payment>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openConfirmCancelationDialog, setOpenConfirmCancelationDialog] = useState<boolean>(false);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openConfirmRejected, setOpenConfirmRejected] = useState<boolean>();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<string>();
    const [values, setValues] = useState<MultiselectValue[]>([]);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const [selection, setSelection] = useState<(number | string)[]>([]);

    const [status_Names, setStatus_Names] = useState<MultiselectValue[]>();
    const [clarification, setClarification] = useState<boolean>(false);
    const [paymentsSelected, setPaymentsSelected] = useState<Payment[]>([]);
    const [enableRejectPayment, setEnableRejectPayment] = useState<boolean>(false);
    const [openConfirmCancelSat, setOpenConfirmCancelSat] = useState<boolean>(false);

    const paramsToFilters = () => {
        return [
            { columnName: "operation_number", value: params.operation_number },
            { columnName: "provider_name", value: params.provider_name },
            { columnName: "comprobantes", value: params.comprobantes },
            { columnName: "total_amount", value: params.total_amount },
            { columnName: "payment_date", value: concatDates(params.payment_start_date, params.payment_end_date) },
            { columnName: "deadline_payment_cfdi", value: concatDates(params.start_deadline_payment_cfdi, params.end_deadline_payment_cfdi) },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        let paymentStatus = workingParams.payment_status ? workingParams.payment_status.split(",") : [];
        listPaymentStatus(context.session!.tenant!.id).then((response) => {
            setStatus_Names(response.items);
            setValues(response.items.filter(el => {
                if (workingParams.payment_status) {
                    return paymentStatus.find(status => el.value === status.trim());
                }
                return null;
            }));
        });

        if (props.success && isSuccess) {
            setSuccess(props.success);
            setIsSuccess(false);
        }
        if (context.isGranted("PaymentsRead")) {
            getPayments(context.session!.tenant!.id, pageSize, pageSize * page, params).then((response) => {
                setData(response);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }

        getConfiguration(context.session!.tenant!.id).then((response) => {
            let disableRejectPaymentConfig = context.session!.provider ? response.configurations.find(config => config.property_name === 'disable_reject_payment_provider')!.value : response.configurations.find(config => config.property_name == 'disable_reject_payment_tenant_users')!.value;
            
            setEnableRejectPayment(!response.has_budgets && disableRejectPaymentConfig == 'false');
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "operation_number":
                        temp.operation_number = filter.value;
                        break;
                    case "payment_date":
                        let paymentDates = filter.value.split(" - ");
                        if (paymentDates.length === 2) {
                            temp.payment_start_date = formatDateString(paymentDates[0]);
                            temp.payment_end_date = formatDateString(paymentDates[1]);
                        } else {
                            temp.payment_start_date = "";
                            temp.payment_end_date = "";
                        }
                        break;
                    case "provider_name":
                        temp.provider_name = filter.value;
                        break;
                    case "comprobantes":
                        temp.comprobantes = filter.value;
                        break;
                    case "deadline_payment_cfdi":
                        let deadlinesPayment = filter.value.split(" - ");
                        if (deadlinesPayment.length === 2) {
                            temp.start_deadline_payment_cfdi = formatDateString(deadlinesPayment[0]);
                            temp.end_deadline_payment_cfdi = formatDateString(deadlinesPayment[1]);
                        } else {
                            temp.start_deadline_payment_cfdi = "";
                            temp.end_deadline_payment_cfdi = "";
                        }
                        break;
                    case "total_amount":
                        temp.total_amount = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
        pushHistory();
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["payment_status"] = workingParams.payment_status ? workingParams.payment_status : "";
        qs["operation_number"] = workingParams.operation_number ? workingParams.operation_number : "";
        qs["provider_name"] = workingParams.provider_name ? workingParams.provider_name : "";
        qs["comprobantes"] = workingParams.comprobantes ? workingParams.comprobantes : "";
        qs["total_amount"] = workingParams.total_amount ? workingParams.total_amount : "";
        qs["payment_start_date"] = workingParams.payment_start_date ? workingParams.payment_start_date : "";
        qs["payment_end_date"] = workingParams.payment_end_date ? workingParams.payment_end_date : "";
        qs["start_deadline_payment_cfdi"] = workingParams.start_deadline_payment_cfdi ? workingParams.start_deadline_payment_cfdi : "";
        qs["end_deadline_payment_cfdi"] = workingParams.end_deadline_payment_cfdi ? workingParams.end_deadline_payment_cfdi : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onAppliedFilter = () => {
        pushHistory();
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onStatusChanged = (selected: string[]) => {
        var toString = selected.join(", ");
        setWorkingParams({ ...workingParams, payment_status: toString });
        if (status_Names)
            setValues(status_Names.filter(el => selected.indexOf(el.value) >= 0));
    };

    const onClickedOptions = (payment: Payment) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPayment(payment);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPayment(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelection(selected);
    };

    const onDownloadAllCfdis = () => {
        setOpenBackdrop(true);
        setOpenConfirmDialog(false);
        exportAllCfdisPayment(context.session!.tenant!.id, context.session!.user.id, params).then((response) => {
            setOpenBackdrop(false);

            if (response.url && response.total > 0) {
                setExportResult(response);
            } else {
                setWarning(translate("payment.archived.export.empty") as string);
            }
        }).catch((error) => {
            setError(error.message);
        });
    }
    const onExportCfdisSelectd = () => {
        setGridAnchorEl(null);
        if (selection && selection.length > 0) {
            setOpenBackdrop(true);
            exportCfdisPayment(context.session!.tenant!.id, { cfdi_ids: selection } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.url && response.total > 0) {
                    setExportResult(response);
                } else {
                    setWarning(translate("payment.archived.export.empty") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(true);
                setError(error.message);
            });
        } else {
            setWarning(translate("payment.archived.empty_selection") as string);
        }
    }

    const onExportCfdisInView = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialog(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onClarification = () => {
        setGridAnchorEl(null);
        if (data && selection && selection.length > 0) {
            setOpenBackdrop(true);
            setPaymentsSelected(data.items.filter(item => selection.find(id => id === item.id)));
            setClarification(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_clarification") as string);
        }
    }

    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }
    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onDownload = () => {
        if (payment) {
            let items = [] as string[];
            items.push(payment.id);
            setOpenBackdrop(true);
            exportCfdisPayment(context.session!.tenant!.id, { cfdi_ids: items } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                setAnchorEl(null);
                setPayment(undefined);

                if (response.url) {
                    setExportResult(response);
                } else {
                    setSuccess(translate("cfdis.email_export") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
                setStatus("loaded");
            });
        }
    }

    const onOpenConfirmRejected = () => {
        setAnchorEl(null);
        isBlockedDelete(context.session!.tenant!.id, payment!.id).then((response) => {
            if (response.content) {
                setError(translate("payment.pending_cfdi.blocked_delete") as string);
            } else {
                setOpenConfirmRejected(true);
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onOpenCancelPaymentCfdi = () => {
        Promise.all([
            validateGenerateInMembership(payment!.payment_cfdi.id),
        ]).then(response => {
            let isGeneratedInMembership = response[0];
            if (isGeneratedInMembership.generated_by_membership) {
                setOpenConfirmCancelSat(true);
            } else {
                onCancelPaymentCfdi();
            }
        }).catch(error => {
            setError(error.message);
        });
    }

    const onCancelPaymentCfdi = () => {
        setAnchorEl(null);
        setOpenConfirmCancelationDialog(true);
    }

    const onCloseCancelConfirmation = () => {
        setAnchorEl(null);
        setOpenConfirmCancelationDialog(false);
    }

    const callPaymentCfdiCancelation = () => {
        setOpenConfirmCancelationDialog(false);
        setOpenConfirmCancelSat(false);
        setStatus("loading");
        cancelPaymentCfdi(context.session!.tenant!.id, payment!.id).then((_) => {
            setWarning(translate("payment.archived.success_payment_cfdi_cancellation") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    const onCloseConfirmRejected = () => {
        setAnchorEl(null);
        setOpenConfirmRejected(false);
    };

    const onDelete = (comment: string) => {
        setOpenBackdrop(true);
        setOpenConfirmRejected(false);
        deletePayment(context.session!.tenant!.id, payment!.id, { comments: comment } as PaymentDeleteRequest).then((_) => {
            setOpenBackdrop(false);
            setSuccess(translate("payment.success_delete", { "payment": payment!.operation_number }) as string);
            load();
        }).catch((error) => {
            setOpenBackdrop(false);
            setStatus(error.message);
            setError(error.message);
        }).finally(() => {
            setPayment(undefined);
        });

    };

    const onClickedRow = (payment: Payment) => {
        pushHistory();
        setRedirect(`/payment-details/${payment.id}/?origin=${window.location.pathname}`);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onExportExcelSelectd = () => {
        setGridAnchorEl(null);
        if (selection && selection.length > 0) {
            setOpenBackdrop(true);
            let paramSelect = {
                "payments_ids": selection.join(","),
            } as PaymentListParams;

            exportExcelPayments(context.session!.tenant!.id, context.session!.user.id, paramSelect).then((response) => {
                setOpenBackdrop(false);
                if (response.url && response.total > 0) {
                    window.open(response.url, "_blank")
                } else if (response.total > 0) {
                    setSuccess(translate("cfdis.email_export") as string);
                } else {
                    setWarning(translate("payment.archived.export.empty") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });

        } else {
            setWarning(translate("payment.archived.empty_selection") as string);
        }
    }

    const onExportExcelInView = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenBackdrop(true);
            exportExcelPayments(context.session!.tenant!.id, context.session!.user.id, params).then((response) => {
                setOpenBackdrop(false);
                if (response.url && response.total > 0) {
                    window.open(response.url, "_blank")
                } else if (response.total > 0) {
                    setSuccess(translate("cfdis.email_export") as string);
                } else {
                    setWarning(translate("payment.archived.export.empty") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onCloseClarification = () => {
        setOpenBackdrop(false);
        setClarification(false);
    };

    const onRequestClarification = (comment: string) => {
        setOpenBackdrop(false);
        setClarification(false);
        setSuccess(comment);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Surface title={props.title}
            icon={<ArchiveIcon />}
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {context.isGranted("PaymentsAdminRead") ?
                        <Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                        : undefined}
                </Grid>
            }
            className="PaperPagination">
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={true} >
                    <ValidatedInput type="text" id="search" name="search" label={translate("payment.pending_cfdi.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                <Grid item xs={5} lg={3} >
                    <MultiselectDropList
                        title={translate("payment.archived.status") as string}
                        margin="dense"
                        elementos={status_Names ? status_Names : []}
                        onChanged={onStatusChanged}
                        value={values} />
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={props.columns ? props.columns : []}
                defaultExpandedGroups={props.defaultExpandedGroups}
                onClickRow={onClickedRow}
                clickRowColumns={props.clickRowColumns}
                amountCurrencyColumns={props.currencyColumns}
                quantityColumns={props.numberColumns}
                dateColumns={props.dateColumns}
                columnsFormat={props.columnsFormat}
                leftColumns={props.leftColumns}
                rightColumns={props.rightColumns}
                grouping={props.grouping}
                textColumns={props.textColumns}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                selectionIds={selection}
                filters={filters}
                customFormatColumns={props.statusPlugins}
                setFiltersHandler={setFiltersHandler}
                setSelectionHandler={setSelectionHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {payment && anchorEl && (
                <PaymentArchivedCfdiMenu
                    payment={payment} anchor={anchorEl} referer={"to-verify"}
                    onClose={onCloseOption} onDownload={onDownload} onDelete={onOpenConfirmRejected} onCancelPaymentCfdi={onOpenCancelPaymentCfdi} enableRejectPaymentOption={enableRejectPayment}/>
            )}

            {openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onDownloadAllCfdis}
                    onClose={onCloseConfirmDialog}
                    title={translate("payment.archived.confirm_dialog.title") as string}
                    message={translate("payment.archived.confirm_dialog.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("payment.archived.confirm_dialog.button") as string} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("payment.archived.export.title") as string}
                    message={translate("payment.archived.export.description", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {openConfirmRejected && (
                <RejectPaymentPopup onClose={onCloseConfirmRejected} doAction={onDelete} name={context.session!.provider ? context.session!.tenant!.name : payment!.provider_name} />
            )}
            {openConfirmCancelationDialog && (
                <ConfirmationPopup button={translate("buttons.accept") as string} title={translate("payment.archived.cancel_payment_cfdi") as string} message={translate("payment.archived.confirm_cancel_payment_cfdi_message") as string} onClose={onCloseCancelConfirmation} doAction={callPaymentCfdiCancelation} submitting={status === "loading"} />
            )}
            {gridAnchorEl && (
                <PaymentsGlobalMenu
                    anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    reference="archive"
                    onExportExcelSelectd={onExportExcelSelectd}
                    onExportExcelInView={onExportExcelInView}
                    onExportCfdisSelectd={onExportCfdisSelectd}
                    onExportCfdisInView={onExportCfdisInView}
                    onClarification={onClarification}
                />
            )}
            {clarification && context.session!.provider && (
                <RequestClarification
                    doAction={onRequestClarification}
                    onClose={onCloseClarification}
                    isCommentRequired={true}
                    payments={paymentsSelected}
                    providerId={context.session!.provider.id}
                    onError={(error) => setError(error)}
                />
            )}
            {openConfirmCancelSat && (
                <CfdisConfirmPopup
                    doAction={callPaymentCfdiCancelation}
                    onClose={() => setOpenConfirmCancelSat(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.confirm_cancel_sat") as string}
                    button={translate("buttons.accept") as string} />
            )}
        </Surface>
    );
}