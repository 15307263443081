import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { listIntegrationLogs } from "../api/IntegrationLogAPI";
import { updateContactErp } from "../api/TenantConfigurationApi";
import { TenantConfigurationContactErpRequest } from "../model/TenantConfiguration";
import { IntegrationLog, IntegrationLogs, IntegrationLogsQueryParams, STATUSES as Statuses, DOCUMENT_TYPES as DocumentTypes, DOCUMENT_SOURCES as DocumentSources, ErpContact } from "../model/IntegrationLog";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import UpdateContactErpPopup from "./UpdateContactErpPopup";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString } from "../components/DateFormat";
import IntegrationLogDetails from "./IntegrationLogDetails";

import { Grid, Divider, FormControl, Select, Box, Typography, ListItem, ListItemText, ListItemIcon, MenuItem } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";

import Brightness1Icon from '@material-ui/icons/Brightness1';
import EditIcon from '@material-ui/icons/BorderColorTwoTone';
import IntegrationLogIcon from '@material-ui/icons/RestorePageTwoTone';
import { RouterParams } from "../router/RouterParams";

export default function IntegrationLogView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): IntegrationLogsQueryParams => {
        return {
            "id": typeof qs["id"] === "string" ? qs["id"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "provider_name": typeof qs["provider_name"] === "string" ? qs["provider_name"] as string : "",
            "user_email": typeof qs["user_email"] === "string" ? qs["user_email"] as string : "",
            "created_at": typeof qs["created_at"] === "string" ? qs["created_at"] as string : "",
            "completed_at": typeof qs["completed_at"] === "string" ? qs["completed_at"] as string : "",
            "type": typeof qs["type"] === "string" ? qs["type"] as string : "",
            "payable_document_id": typeof qs["payable_document_id"] === "string" ? qs["payable_document_id"] as string : "",
            "payable_document_external_id": typeof qs["payable_document_external_id"] === "string" ? qs["payable_document_external_id"] as string : "",
            "source": typeof qs["source"] === "string" ? qs["source"] as string : "",
            "plugin": typeof qs["plugin"] === "string" ? qs["plugin"] as string : "",
            "ip": typeof qs["ip"] === "string" ? qs["ip"] as string : "",
            "request": typeof qs["request"] === "string" ? qs["request"] as string : "",
            "response": typeof qs["response"] === "string" ? qs["response"] as string : "",
            "provider_id": typeof qs["provider_id"] === "string" ? qs["provider_id"] as string : "",
            "user_id": typeof qs["user_id"] === "string" ? qs["user_id"] as string : "",
        } as IntegrationLogsQueryParams;
    };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<IntegrationLogs>();
    const [params, setParams] = useState<IntegrationLogsQueryParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [contactErp, setContactErp] = useState<ErpContact>();
    const [openEditContact, setOpenEditContact] = useState<boolean>(false);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    const [integrationLog, setIntegrationLog] = useState<IntegrationLog>();
    const [showDetails, setShowDetails] = useState(false);

    const paramsToFilters = (): Filter[] => {
        return [
            { columnName: 'id', value: params.id },
            { columnName: 'user_email', value: params.user_email },
            { columnName: 'status', value: params.status },
            { columnName: 'provider_name', value: params.provider_name },
            { columnName: 'created_at', value: params.created_at },
            { columnName: 'completed_at', value: params.completed_at },
            { columnName: 'type', value: params.type },
            { columnName: 'payable_document_id', value: params.payable_document_id },
            { columnName: 'payable_document_external_id', value: params.payable_document_external_id },
            { columnName: 'source', value: params.source },
            { columnName: 'plugin', value: params.plugin },
            { columnName: 'ip', value: params.ip },
            { columnName: 'request', value: params.request },
            { columnName: 'response', value: params.response },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
        { columnName: 'id', width: 250 },
        { columnName: 'user_email', width: 250 },
        { columnName: 'status', wordWrapEnabled: true, width: 100 },
        { columnName: 'provider_name', wordWrapEnabled: true, width: 150 },
        { columnName: 'created_at', wordWrapEnabled: true },
        { columnName: 'completed_at', wordWrapEnabled: true},
        { columnName: 'type', wordWrapEnabled: true },
        { columnName: 'payable_document_id', width: 250 },
        { columnName: 'payable_document_external_id', width: 250 },
        { columnName: 'source', wordWrapEnabled: true},
        { columnName: 'plugin', wordWrapEnabled: true },
        { columnName: 'ip', wordWrapEnabled: true },
        { columnName: 'request', wordWrapEnabled: true },
        { columnName: 'response', wordWrapEnabled: true },
    ]) as any;

    const [leftColumns] = useState(['id', 'user_email', 'provider_name', 'status']) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();

        const reqParams = match.params.zipUploadId ? { ...params, zipUploadId: match.params.zipUploadId } : params;
        listIntegrationLogs(context.session!.tenant!.id, pageSize, pageSize * page, reqParams).then((response) => {
            setData(response);
            setContactErp(response.erp_contact ? response.erp_contact : { contact_name: "", email: "" } as ErpContact);
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters, match.params.zipUploadId]);

    const onUpdateContactErp = (contact: TenantConfigurationContactErpRequest) => {
        setOpenEditContact(false);
        updateContactErp(context.session!.tenant!.id, contact).then((response) => {
            setSuccess(translate("reports.transaction_log.edit_contact.success") as string);
            load();
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "id":
                        temp.id = filter.value;
                        break;
                    case "created_at":
                        let datesS = filter.value.split(" - ");
                        if (datesS.length === 2) {
                            temp.created_at = formatDateString(datesS[0]) + " - " + formatDateString(datesS[1]);
                        } else {
                            temp.created_at = "";
                        }
                        break;
                    case "completed_at":
                        let datesE = filter.value.split(" - ");
                        if (datesE.length === 2) {
                            temp.completed_at = formatDateString(datesE[0]) + " - " + formatDateString(datesE[1]);
                        } else {
                            temp.completed_at = "";
                        }
                        break;
                    case "user_email":
                        temp.user_email = filter.value;
                        break;
                    case "status":
                        temp.status = filter.value;
                        break;
                    case "provider_name":
                        temp.provider_name = filter.value;
                        break;
                    case "type":
                        temp.type = filter.value;
                        break;
                    case "payable_document_id":
                        temp.payable_document_id = filter.value;
                        break;
                    case "payable_document_external_id":
                        temp.payable_document_external_id = filter.value;
                        break;
                    case "ip":
                        temp.ip = filter.value;
                        break;
                    case "source":
                        temp.source = filter.value;
                        break;
                    case "plugin":
                        temp.plugin = filter.value;
                        break;
                    case "request":
                        temp.request = filter.value;
                        break;
                    case "response":
                        temp.response = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["id"] = params.id || "";
        qs["user_email"] = params.user_email || "";
        qs["status"] = params.status || "";
        qs["provider_name"] = params.provider_name || "";
        qs["created_at"] = params.created_at || "";
        qs["completed_at"] = params.completed_at || "";
        qs["type"] = params.type || "";
        qs["payable_document_id"] = params.payable_document_id || "";
        qs["payable_document_external_id"] = params.payable_document_external_id || "";
        qs["source"] = params.source || "";
        qs["plugin"] = params.plugin || "";
        qs["ip"] = params.ip || "";
        qs["request"] = params.request || "";
        qs["response"] = params.response || "";
        qs["page"] = "0";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const getProviderName = (log: IntegrationLog) => {
        return (
            <Typography title={log.provider ? log.provider.name : ""} variant="inherit">
                {log.provider ? log.provider.name : ""}
            </Typography>
        );
    };

    const getUserEmail = (log: IntegrationLog) => {
        return (
            <Typography title={log.user ? log.user.email : ""} variant="inherit">
                {log.user ? log.user.email : ""}
            </Typography>
        );
    };

    const getDocumentType = (log: IntegrationLog) => {
        if (log.type) {
            return (
                <Typography variant="inherit">
                    {translate("reports.transaction_log.document_type." + log.type) as string}
                </Typography>
            );
        }
        return (<></>);
    };

    const getTransactionType = (log: IntegrationLog) => {
        return (
            <Typography variant="inherit">
                {translate("reports.transaction_log.transaction_type." + log.source) as string}
            </Typography>
        );
    };

    const getId = (log: IntegrationLog, isDocumentId: boolean) => {
        const value = isDocumentId ? log.payable_document_id : log.id;
        return (
            <Typography title={value} variant="inherit">
                {value}
            </Typography>
        );
    };

    const getExternalId = (log: IntegrationLog) => {
        const value = log.payable_document_external_id || "-";
        return (
            <Typography title={value} variant="inherit">
                {value}
            </Typography>
        );
    };

    const clickRowColumns = ['id'];

    const columns = [
        {
            name: 'id',
            title: translate('reports.transaction_log.columns.id_transaction') as string,
            getCellValue: (row: any) => getId(row, false)
        },
        {
            name: 'user_email',
            title: translate('reports.transaction_log.columns.call_user') as string,
            getCellValue: (row: any) => getUserEmail(row)
        },
        {
            name: 'provider_name',
            title: translate("reports.transaction_log.columns.provider") as string,
            getCellValue: (row: any) => getProviderName(row)
        },
        {
            name: 'status',
            title: translate('reports.transaction_log.columns.status') as string,
        },
        {
            name: 'created_at',
            title: translate("reports.transaction_log.columns.create_at") as string,
        },
        {
            name: 'completed_at',
            title: translate("reports.transaction_log.columns.end_at") as string,
        },
        {
            name: 'type',
            title: translate("reports.transaction_log.columns.document_type") as string,
            getCellValue: (row: any) => getDocumentType(row)
        },
        {
            name: 'payable_document_id',
            title: translate('reports.transaction_log.columns.document_id') as string,
            getCellValue: (row: any) => getId(row, true)
        },
        {
            name: 'payable_document_external_id',
            title: translate('reports.transaction_log.columns.document_external_id') as string,
            getCellValue: (row: any) => getExternalId(row)
        },
        {
            name: 'source',
            title: translate('reports.transaction_log.columns.transaction_type') as string,
            getCellValue: (row: any) => getTransactionType(row)
        },
        {
            name: 'plugin',
            title: translate("reports.transaction_log.columns.plugin") as string,
        },
        {
            name: 'ip',
            title: translate("reports.transaction_log.columns.ip") as string,
        },
    ];

    const StatusTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                {(value.row.status === "ok" || value.row.status === "not_required") ?
                    <Brightness1Icon style={{ color: "#32CD32", "width": 10, "height": 10 }} />
                    : value.row.status === "error" ?
                        <Brightness1Icon style={{ color: "#FF0000", "width": 10, "height": 10 }} />
                        : <Brightness1Icon style={{ color: "#FFFF00", "width": 10, "height": 10 }} />
                }

            </>;
        }} {...props} />
    );

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {Statuses.map((value) => {
                            return <option key={value} value={value}>{translate("reports.transaction_log.statuses." + value)}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const TypeFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="type"
                        id="type"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {DocumentTypes.map((value) => {
                            return <option key={value} value={value}>{translate(`reports.transaction_log.document_type.${value}`)}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const SourceFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="source"
                        id="source"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {DocumentSources.map((value) => {
                            return <option key={value} value={value}>{translate("reports.transaction_log.transaction_type." + value)}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const onOpenEditContact = () => {
        setOpenEditContact(true);
    }

    const onCloSeEditContact = () => {
        setOpenEditContact(false);
    }

    const textColumns = ['id', 'type', 'payable_document_id', 'payable_document_external_id', 'source', 'plugin', 'ip', 'request', 'response', 'provider_name', 'user_email'];

    const filtersExclude = ['request', 'response'];

    const customPlugins = [
        <StatusTypeProvider for={['status']} editorComponent={StatusFilterEditor} />,
        <NormalTypeProvider for={['type']} editorComponent={TypeFilterEditor} />,
        <NormalTypeProvider for={['source']} editorComponent={SourceFilterEditor} />,
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    const dateColumns = ['created_at', 'completed_at'];

    const handleClick = (integrationLog: IntegrationLog) => {
        setIntegrationLog(integrationLog);
        setShowDetails(true);
    };

    const onCloseDetails = () => {
        setIntegrationLog(undefined);
        setShowDetails(false);
    };

    const onUpdated = (integrationLog?: IntegrationLog, error?: string) => {
        if (integrationLog) {
            setIntegrationLog(integrationLog);
            setShowDetails(true);
        } else if (error) {
            setError(error);
        }
    };

    return (
        <Surface title={translate("reports.transaction_log.title") as string} 
            icon={<IntegrationLogIcon />} 
            className="PaperPagination" >
            <Grid container alignItems="center" justify="flex-end" >
                <Grid item xs={12} sm={12} md={9} xl={8} justify="flex-end">
                    <ListItem>
                        <Grid container direction="row" alignItems="center" justify="space-evenly" >
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Typography variant="body1">
                                    {translate("reports.transaction_log.contacts.contact_pdp") as string}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ListItemText primary="Soporte PDP" secondary={"soporte@focaltec.com"} />
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Typography variant="body1" component="h5">
                                    {translate("reports.transaction_log.contacts.contact_erp") as string}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ListItemText primary={contactErp ? contactErp.contact_name : "---"} secondary={contactErp ? contactErp.email : "---"} />
                            </Grid>
                            <Grid item>
                                <MenuItem button onClick={onOpenEditContact} dense>
                                    <ListItemIcon>
                                        <EditIcon fontSize="large" color="primary" />
                                    </ListItemIcon>
                                </MenuItem>
                            </Grid>
                        </Grid>
                    </ListItem>
                </Grid>
            </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns ? columns : []}
                columnsFormat={columnsFormat}
                onClickRow={handleClick}
                clickRowColumns={clickRowColumns}
                onClickedOptions={() => { }}
                dateColumns={dateColumns}
                leftColumns={leftColumns}
                textColumns={textColumns}
                filters={filters}
                customFormatColumns={customPlugins}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                heightTablePX={450}
                noUseId={true}
                showTimeInDates={true}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {openEditContact && contactErp && (
                <UpdateContactErpPopup
                    contact={contactErp}
                    onSuccess={onUpdateContactErp}
                    onClose={onCloSeEditContact} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description_archive") as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {integrationLog && showDetails && (
                <IntegrationLogDetails
                    integrationLog={integrationLog}
                    onClose={onCloseDetails}
                    onUpdated={onUpdated}
                />
            )}
        </Surface>
    );
}
