import React from "react";
import { MenuItem, Fade, Menu, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";

interface RetentionMenuProps {
    onEdit(): any;
    onUpdateStatus(): any;
    onDelete(): any;
    onClose(): any;
    active: boolean;
    anchor: HTMLElement | null;
}

export default function RetentionMenu(props: RetentionMenuProps) {

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            open onClose={props.onClose}
            TransitionComponent={Fade}>
            <MenuItem button onClick={props.onEdit} dense>
                <Typography variant="inherit">{translate("reception_rule.retention_validation.menu.edit")}</Typography>
            </MenuItem>
            <MenuItem button onClick={props.onUpdateStatus} dense>
                <Typography variant="inherit">{translate("reception_rule.retention_validation.menu." + (props.active === true ? "inactivate" : "activate"))}</Typography>
            </MenuItem>
            <MenuItem button onClick={props.onDelete} dense>
                <Typography variant="inherit">{translate("reception_rule.retention_validation.menu.delete")}</Typography>
            </MenuItem>
        </Menu>);
}
