import React, { useState, useContext } from "react";
import DialogPopup from "../../components/DialogPopup";
import { Payment, ValidatedPayment } from "../../model/Payment";
import translate from "../../i18n/Translator";
import { Typography, Button } from "@material-ui/core";
import Gridable from "../../components/Gridable";
import NumberFormat from 'react-number-format';
import DateFormat from "../../components/DateFormat";
import { CheckBoxCallbacks, CheckBoxColumnTitle, CheckBoxColumnValue } from "../../components/GridItemCheckBox";
import { sendPaymentCfdiToReview } from "../../api/PaymentApi";
import { AppContext } from "../../context/AppContext";
import { WarningSnackbar, ErrorSnackbar } from "../../components/Snackbars";

interface PaymentCfdiRequestReviewProps {
    xml: File;
    pdf: File;
    paymentsInCfdi: ValidatedPayment[];
    selectablePayments: Payment[];
    onClose(): any;
    onSentToReview(): any;
}

export function PaymentCfdiRequestReviewPopup(props: PaymentCfdiRequestReviewProps) {
    const context = useContext(AppContext);
    const [warning, setWarning] = useState("");
    const [error, setError] = useState("");
    const [selectedPayments, setSelectedPayments] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const sendToReview = () => {
        if (selectedPayments.length === 0) {
            setWarning(translate("payment.upload.empty_selected_payments") as string);
            return;
        }
        setSubmitting(true);
        sendPaymentCfdiToReview(context.session!.tenant!.id, props.xml, props.pdf, selectedPayments.join(",")).then((_) => {
            props.onSentToReview();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const submmitButton = (
        <Button onClick={sendToReview} variant="contained" color="primary" size="medium" disabled={submitting} >
            {translate("payment.upload.button.send_to_review")}
        </Button>
    );

    const handleCheck = (payment: Payment) => {
        let items = selectedPayments.length === 0 ? ([] as string[]) : selectedPayments.join(",").split(",");
        const index = selectedPayments.indexOf(payment.id);
        if (index < 0) {
            items.push(payment.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedPayments(items);
    };

    const paymentCfdiItemsGrid = <Gridable
        items={props.paymentsInCfdi ? props.paymentsInCfdi : []}
        loading={false}
        columns={[
            {
                title: translate("payment.upload.date") as string,
                converter: (p) => (<DateFormat date={p.pago.date} format="DD-MM-YYYY" zone={false} />),
                justify: "center",
                xs: 3
            },
            {
                title: translate("payment.upload.invoices") as string,
                converter: (p) => (p.pago.documents) as string,
                xs: 5
            },
            {
                title: translate("payment.upload.amount") as string,
                converter: (p) => <NumberFormat value={p.pago.amount} prefix="$ " thousandSeparator="," suffix={` ${p.pago.currency}`} decimalScale={2} fixedDecimalScale={true} displayType="text" />,
                justify: "flex-end",
                xs: 4
            }
        ]} />;

    const paymentsPendingCfdi = <Gridable
        items={props.selectablePayments ? props.selectablePayments : []}
        loading={false}
        columns={[
            {
                title: (
                    <CheckBoxColumnTitle
                        label_title={translate("payment.columns.number") as string}
                        total={props.selectablePayments.length}
                        selected={selectedPayments.length}
                        callbacks={{} as CheckBoxCallbacks<Payment>}
                        showChecks={false} />
                ),
                converter: (payment) => (
                    <CheckBoxColumnValue
                        item={payment}
                        checked={selectedPayments.indexOf(payment.id) >= 0}
                        showChecks={true}
                        value={payment.operation_number}
                        callbacks={{ handleCheck: handleCheck } as CheckBoxCallbacks<Payment>} />
                ),
                fullWidth: true,
                xs: 4,
            },
            {
                title: translate("payment.upload.date") as string,
                converter: (p) => <DateFormat date={p.payment_date} format="DD-MM-YYYY" zone={false} />,
                justify: "center",
                xs: 4,
            },
            {
                title: translate("payment.upload.amount") as string,
                converter: (p) => <NumberFormat value={p.total_amount} prefix="$ " thousandSeparator="," suffix={` ${p.currency}`} decimalScale={2} fixedDecimalScale={true} displayType="text" />,
                justify: "flex-end",
                xs: 4,
            }
        ]} />;

    const onCloseSnackbar = () => {
        setWarning("");
        setError("");
    };

    return (
        <>
            <DialogPopup title={translate("payment.upload.request_review_title") as string} open
                onClose={props.onClose}
                button={submmitButton}
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
                disable={submitting}
                closeColor="primary"
                closeText={translate("payment.upload.button.close") as string}>
                <Typography variant="body1" align="left">{translate("payment.upload.payment_cfdi_data") as string}</Typography>
                <br />
                {paymentCfdiItemsGrid}
                <br />
                <br />
                <Typography variant="body1" align="left">{translate("payment.upload.select_payments_label") as string}</Typography>
                <br />
                {paymentsPendingCfdi}
            </DialogPopup>
            <WarningSnackbar message={warning} onClose={onCloseSnackbar} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbar} />
        </>
    );
}

