import { Actors, Stages, Template, TemplateMetadata, WorkflowTemplate } from './Model';

export type Product = 'REQUISITIONS' | 'PAYABLE_DOCUMENTS' | 'REFUNDS';

export const PRODUCTS: Product[] = ['PAYABLE_DOCUMENTS', 'REQUISITIONS', 'REFUNDS']

type Method = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';

interface ErrorWorkflove extends Error {
    code: string;
}

interface APIQueryString {
    query?: Record<string, string>;
}

interface APIParameters extends APIQueryString {
    method: Method;
    body?: object | FormData;
    multipart?: boolean;
}

const getProductId = (product: Product): string => {
    return process.env[`REACT_APP_WORKFLOVE_${product}`] || 'x__';
};

const getBaseUrl = (product: Product) => {
    const productId = getProductId(product);
    return `${process.env.REACT_APP_WORKFLOVE_API_URL}/api/1.0/tenants/${process.env.REACT_APP_WORKFLOVE_TENANT_ID}/products/${productId}`;
}

const encodeGetParams = (p: object): string => {
    return Object.entries(p).map(kv => kv.map(encodeURIComponent).join('=')).join('&');
}

async function callAPI<T>(url: string, parameters: APIParameters): Promise<T> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('WorkfloveTenantKey', process.env.REACT_APP_WORKFLOVE_API_KEY ?? '');

    const requestOptions: RequestInit = {
        method: parameters.method,
        headers: headers,
        credentials: 'include',
        mode: 'cors',
    };

    if (parameters.body) {
        if (parameters.multipart && parameters.body as FormData) {
            requestOptions.body = parameters.body as FormData;
        } else {
            requestOptions.body = JSON.stringify(parameters.body);
        }
    }

    let fullUrl;
    if (parameters.query) {
        fullUrl = `${url}?${encodeGetParams(parameters.query)}`;
    } else {
        fullUrl = url;
    }

    const response = await fetch(fullUrl, requestOptions);
    if (response.ok) {
        return await response.json();
    }

    const errorResponse = await response.json();
    if (errorResponse) {
        const error = {} as ErrorWorkflove;
        error.code = errorResponse.code;
        error.message = errorResponse.description;
        throw error;
    }
    throw new Error(await response.text());
}

const getTemplateMetadata = async (product: Product): Promise<TemplateMetadata> => {
    return await callAPI(`${getBaseUrl(product)}/templates/metadata`, {
        method: 'GET',
    });
}

const getTemplate = async (product: Product, templateId: string): Promise<Template> => {
    return await callAPI(`${getBaseUrl(product)}/templates/${templateId}`, {
        method: 'GET',
    });
}

const getWorkflowTemplate = async (product: Product, workflowId: string, customerId: string, templateId: string, externalId: string): Promise<WorkflowTemplate> => {
    return await callAPI(`${getBaseUrl(product)}/workflows/${workflowId}/template`, {
        method: 'GET',
        query: {
            customerExternalId: customerId,
            templateId: templateId,
            externalId: externalId,
        },
    });
}

const getStages = async (product: Product): Promise<Stages> => {
    return await callAPI(`${getBaseUrl(product)}/stages`, {
        method: 'GET',
    });
}

const getActors = async (product: Product): Promise<Actors> => {
    return await callAPI(`${getBaseUrl(product)}/actors`, {
        method: 'GET',
    });
}

export {
    getTemplateMetadata,
    getTemplate,
    getWorkflowTemplate,
    getStages,
    getActors,
}
