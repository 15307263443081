import React, { useState, useEffect, SyntheticEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";

import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";

import { amber, green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
}));

const VariantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

interface Props {
    message?: string | JSX.Element | JSX.Element[];
    onClose?: () => void;
}

interface GenericProps extends Props {
    variant: keyof typeof VariantIcon;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
}

function GenericSnackbar(props: GenericProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { message, variant, onClose, horizontal, vertical, ...other } = props;
    const Icon = VariantIcon[variant];

    const handleClose = (_event?: SyntheticEvent, _reason?: string) => {
        if (typeof onClose === "function") {
            onClose();
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(!!message);
    }, [message]);

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{
                vertical: vertical ?? "bottom",
                horizontal: horizontal ?? "right",
            }}>
            <SnackbarContent
                className={clsx(classes[variant])}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        </Snackbar>
    );
}

export function ErrorSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="error"
            message={props.message}
            onClose={props.onClose}
        />
    );
};

export function SuccessSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="success"
            message={props.message}
            onClose={props.onClose}
        />
    );
};

export function WarningSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="warning"
            message={props.message}
            onClose={props.onClose}
        />
    );
};

export function InfoSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="info"
            message={props.message}
            onClose={props.onClose}
            vertical="bottom"
            horizontal="left"
        />
    );
};
