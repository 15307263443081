import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import ValidatedInput from "../components/ValidatedInput";
import { listCustomReports, executeDataQuery, exportExcel } from "../api/CustomReportAPI";
import { CustomReports, CustomReportsListParams, CustomReport, CustomReportDataQueryParams, CustomReportDataQueryRequest, CustomReportRows } from "../model/CustomReport";
import { formatDateToString } from "../components/DateFormat";
import { Button } from "@material-ui/core";
import DateRange from "../components/DateRange";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { NoFilterEditor } from "../components/GridDx";
import { DataTypeProviderProps, DataTypeProvider, Sorting } from "@devexpress/dx-react-grid";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import Progress from "../components/Progress";
import DateFormat from "../components/DateFormat";
import { stringToDate, stringToDateEnd } from "../components/DateFormat";
import NumberFormat from "react-number-format";

import queryString from "query-string";
import { Grid, Fab, Divider } from "@material-ui/core";
import ViewList from '@material-ui/icons/ViewList';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';

export default function CustomReportsView() {
    
    const context = useContext(AppContext);
    const history = useHistory(); 
    const qs = queryString.parse(window.location.search);
    const sDate = new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate());
    const reports: string[] = [];
    
    const paramsFromQueryString = (): CustomReportDataQueryParams => {
        return {
            "start_date": typeof qs["start_date"] === "string" ? stringToDate(qs["start_date"]) : sDate,
            "end_date": typeof qs["end_date"] === "string" ? stringToDateEnd(qs["end_date"]) : new Date(),
            "report": typeof qs["report"] === "string" ? qs["report"] as string : "",
        } as CustomReportDataQueryParams;
    };

    const dataReportsInit = () : CustomReportRows => {
        return {
            rows: [],
            total: 0
        } as CustomReportRows;
    }

    const startDateInit = (): Date => {
        return new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate());
    };

    const initError = (): string => {
        const qs = queryString.parse(window.location.search);
        return typeof qs["error"] === "string" ? translate("reports.custom_reports.custom_report_error_config") as string : "";
    }

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loaded");
    const [data, setData] = useState<CustomReports>();  
    const [params, setParams] = useState<CustomReportDataQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CustomReportDataQueryParams>(paramsFromQueryString);
    const [reportsName, setReportsName] = useState<string[]>([]);
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>(initError);
    const [report, setReport] = useState<CustomReport>();
    const [reportInCombo, setReportInCombo] = useState<string>("");
    const [disbleDownland, setDisbleDownland] = useState<boolean>(true);
    const [columnsDx, setColumnsDx] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [dataReports, setDataReports] = useState<CustomReportRows>(dataReportsInit);
    const [startDate, setStartDate] = useState<Date>(startDateInit());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [progress, setProgress] = useState<boolean>(false);
    const [filtersExclude, setFiltersExclude] = useState<string[]>([]);

    const load = () => {
        setStatus("loading");
        pushHistory();
        const paramsList = {
            status: "ACTIVE"
        } as CustomReportsListParams;
        listCustomReports(context.session!.tenant!.id, 0, 0, paramsList)
        .then((response) => {  
            response.items.forEach((element) => {                            
                reports.push(element.name);
            })       
            setData(response);
            setReportsName(reports);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
            setData(undefined);
        });
    }

    const onClickSearch = () => {
        setStatus("loading");
        pushHistory();
        if(!report){
            setStatus("loaded");
            setError(translate("reports.custom_reports.required_selected_report") as string);
            return;
        }
        if(report.name != reportInCombo){
            setPage(0);
        }
        getDataQuery(report);
        setReportInCombo(report?.name);
    }

    const getDataQuery = (report: CustomReport) => {
        const request: CustomReportDataQueryRequest = {
            page_size: pageSize,
            offset: pageSize * page,
            start_date: startDate,
            end_date: endDate
        };
        executeDataQuery(context.session!.tenant!.id, report?.id, request)
        .then((response) => {            
            setItems([]);
            setColumnsDx([]);
            setFiltersExclude([]);
            setDisbleDownland(true);
            let n = 0;
            let columns: any[] = [];
            let filters: string[] = [];
            response.rows.forEach((e) => {
                if(!e.id) { e.id = n ++; }
            });
            setDataReports(response);
            setItems(response.rows);    
            report?.columns.forEach((element) => {   
                if(element.visible){
                    let col = {
                        name: element.attribute,
                        title: element.label as string,
                        getCellValue: (row: any) => getCellValue(row, element.format, element.attribute) 
                    };
                    columns.push(col);
                    filters.push(element.attribute);
                }
            });
            try {
                setColumnsDx(columns);
            } catch (error) {
                let qs = queryString.parse(window.location.search);
                qs["error"] = "custom_report_error_config";
                let url = window.location.pathname + "?" + queryString.stringify(qs);
                window.location.href = url;
            }
            setFiltersExclude(filters);
            setDisbleDownland(isNotExportable(response.rows, report));
            setStatus("loaded");
        }).catch((error) => {
            setError(error.message);
            setStatus("loaded");
        })
    }

    const isNotExportable = (rows: any[], report: CustomReport ) => {
        return !(rows.length > 0 && report.exportable);
    }

    const loadGrid = () => {
        setStatus("loading");
        if(report){
            getDataQuery(report);
        }
    }

    useEffect(load, [context.session]);
    useEffect(loadGrid, [page, pageSize]);

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["report"] = workingParams.report;
        qs["start_date"] = formatDateToString(workingParams.start_date) || "";
        qs["end_date"] = formatDateToString(workingParams.end_date) || "";
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    }

    const getCellValue = (row: any, format:string, attribute: string) => {
        let value = undefined;
        if(format === 'NONE'){
            value = row[attribute];
        } else if(format === 'MEXICO_DATE'){
            value = <DateFormat date={row[attribute]} format="DD/MM/yyyy" />;
        } else if(format === 'CURRENCY'){
            value = <NumberFormat value={row[attribute]} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />;
        } else if(format === 'USA_DATE'){
            value = <DateFormat date={row[attribute]} format="MM/DD/yyyy" />;
        } else if(format === 'LONG_DATE'){
            value = <DateFormat date={row[attribute]} format="MM/DD/yyyy HH:mm:ss" />;
        } else {
            value = row[attribute];
        }
        return value;
    }

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPage(0);
        setPageSize(pageSize);
    };
    
    const onFilterChanged = (name: string, value: string) => {
        const reportItem = data?.items.find((element) => element.name === value);
        setReport(reportItem);
        setParams({ ...params, ['report']: value });
        setWorkingParams({ ...workingParams, ['report']: value });
        pushHistory();
    }

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setWarning(undefined)
        setError("");
    };

    const onChangeDateRange = (startDate: Date, endDate: Date) => {
        let monhts = endDate.getMonth() - startDate.getMonth();
        if(monhts > 3){
            setError(translate("reports.custom_reports.only_allowed_three_months_ago") as string);
            setStartDate(startDateInit());
            setEndDate(new Date());
            return;
        }
        setStartDate(startDate);
        setEndDate(endDate);
        setParams({ ...params, ['start_date']: startDate, ['end_date']: endDate });
        setWorkingParams({ ...workingParams, ['start_date']: startDate, ['end_date']: endDate });
    };

    const onClickDownlandCustomReport = () => {
        setProgress(true);
        if(!report){
            setProgress(false);
            setError(translate("reports.custom_reports.required_selected_report") as string);
            return;
        }
        if(!startDate && !endDate){
            setProgress(false);
            setError(translate("reports.custom_reports.required_start_date_end_date") as string);
            return;
        }

        exportExcel(context.session!.tenant!.id, report.id, {start_date: startDate, end_date: endDate} as CustomReportDataQueryRequest)
        .then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else if (response.total > 0) {
                setSuccess(translate("reports.custom_reports.email_export", { "total": response.total }) as string);
            } else {
                setWarning(translate("reports.custom_reports.empty_export") as string);
            }
            setProgress(false);
        })
        .catch((error) => {
            setError(error.message);
            setProgress(false);
        })
    }

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />
    ];

    if (progress) {
        return (<Progress />);
    }

    return (
        <Surface title={translate("custom_reports.title") as string} 
            subtitle = { context.session?.tenant?.name }
            icon={<ViewList />}
            className="PaperPagination"
            titleActions = {
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        <Grid item xs="auto">
                            <Fab onClick={onClickDownlandCustomReport} color="primary" size="small" title={translate("buttons.download") as string} disabled={disbleDownland}>
                                <DownloadIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
            }>
            <form autoComplete="off" noValidate onSubmit={() => [] }>
                { reportsName.length != 0 &&
                    <Grid container alignItems="center" justify="flex-start" className="TableFilter" spacing={1}>
                        <Grid item xs={3}>
                            <ValidatedInput 
                                type="text" 
                                id="report" 
                                name="report" 
                                label={translate("reports.custom_reports.title") as string}
                                options={reportsName} 
                                optionLabels={reportsName}
                                margin="dense" 
                                disabled={false}
                                value={ report?.name } 
                                onValueChanged={onFilterChanged}/>
                        </Grid>
                        <Grid item xs={3} sm={6} md={6} lg={3} xl={3} >
                            <DateRange
                            startDate={ startDate }
                            endDate={ endDate }
                            onChange={onChangeDateRange} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button onClick={onClickSearch} variant="contained" color="secondary" size="large">
                                {translate("buttons.search")}
                            </Button>
                        </Grid>
                    </Grid>       
                }
            </form>
            <Divider />
            {
                items &&
                <GridDx
                    loading={status === "loading"}
                    rows={items || []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={dataReports.total}
                    columns={columnsDx || []}
                    columnsFormat={[]}
                    clickRowColumns={[]}
                    onClickRow={() => []}
                    amountCurrencyColumns={[]}
                    textColumns={[]}
                    customFormatColumns={customPlugins}
                    sorting={[]}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            </Surface>
    );
}