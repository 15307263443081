import React, { useState, useEffect, useContext } from "react";
import { list as listProjects } from "../api/ProjectAPI";

import { Grid, Divider, ListItem, Typography, IconButton } from "@material-ui/core";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import ArrowIcon from "@material-ui/icons/ArrowForward";
import { Redirect } from "react-router-dom";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import BudgetRangeBar from '../project/BudgetRangeBar';
import { AppContext } from "../context/AppContext";
import { Project, ProjectListParams, Statuses } from "../model/Project";
import { isRoleOrParent } from "../model/Role";
import ProjectIcon from "@material-ui/icons/GroupWork";

export default function ProjectsRangeBudgetWidget() {
    const context = useContext(AppContext);
    const [redirect, setRedirect] = useState<string>();

    const [projects, setProjects] = useState<Project[]>();
    const isOwner = isRoleOrParent(context.session!.role, "owner");
    const userId = isOwner ? "" : context.session?.user.id;

    const load = () => {
        let statusActives = Statuses[2];
        listProjects(context.session!.tenant!.id, 0, 0, { status: statusActives, user_id: userId, role_team: userId  ? "AUTHORIZER" : ""} as ProjectListParams).then((response) => {
            if(response && response.items.length > 0){
                setProjects(response.items);
            }
        }).catch((error) => {
        });
    }

    useEffect(load, []);

    const onClickedGo = (route: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if("" !== route){
            setRedirect(route);
        }
    };
    
    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    if(projects){
        return (
            <Surface title={translate("requisitions.projects.active_projects")} icon={<ProjectIcon />} titleActions={
                <Grid item xs="auto">
                    <Grid>
                        <IconButton size="small" onClick={onClickedGo(`/projects/in_execution`)} disabled={!projects} style={{marginTop: "7px"}}>
                        <Typography variant="h6" color={"primary"}>
                            <strong>{projects ?  projects.length : 0}</strong>
                        </Typography>
                        <ArrowIcon />
                        </IconButton>
                    </Grid>
        </Grid> 
            }>
                <Grid container justify="space-evenly" alignItems="flex-start">
                {projects.map((item) => (
                    <Grid xs={12}>
                        <Grid item xs={12} direction="row">
                        <ListItem>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="body2" color={"primary"}>
                                        <strong>{item.name}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        </Grid>
                        <BudgetRangeBar project={item} fromDashboard={true}/>
                        <br></br>
                        <br></br>
                        <Divider/>
                    </Grid>
                ))}
                <Grid item xs={12}>
                <br></br>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Brightness1Icon style={{ color:"#1F679F", "width": 13, "height": 13 }}/> 
                            <Typography variant="inherit">
                                        {translate(`requisitions.projects.columns.accrued`) as string}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Brightness1Icon style={{ color:"#F69450", "width": 13, "height": 13 }}/> 
                            <Typography variant="inherit">
                                        {translate(`requisitions.projects.columns.to_accrue`) as string}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Brightness1Icon style={{ color:"#ffcc50", "width": 13, "height": 13, borderColor:"black" }}/> 
                            <Typography variant="inherit">
                                        {translate(`requisitions.projects.columns.to_assign`) as string}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                </Grid>
            </Surface>
        );
    }
    return (<div></div>);
    
}
