import { Tenant } from "./Tenant";

export interface Connector {
    id: string;
    tenant_id?: string;
    family: Family;
    direction: Direction;
    format: Format;
    fields?: ConnectorField[];
    transformer?: string;
    plugin?: string;
    extensions?: string[];
    name?: string;
    lambda?: string;
    available_for_zip: boolean;
    created_at: Date;
    remote_directory?: string;
}

export interface Connectors {
    items: Connector[];
    total: number;
    tenant: Tenant;
}

export interface ConnectorsQueryParams {
    search: string;
    family?: Family;
    direction?: Direction;
    includeDefault?: boolean;
    availableForZip?: boolean;
}

export interface ConnectorRequest {
    family: Family;
    direction: Direction;
    format: Format;
    fields?: ConnectorField[];
    transformer?: string;
    plugin?: string;
    lambda?: string;
    extensions?: string;
    name?: string;
    available_for_zip?: boolean;
    remote_directory?: string;
}

export interface ExportResponse {
    url?: string;
    total: number;
}

export interface ExportRequest {
    connector_id: string;
    ids: string[];
}

export enum Direction {
    INPUT = "INPUT",
    OUTPUT = "OUTPUT",
}

export interface ConnectorField {
    field: string;
    index?: number;
    required?: boolean;
}

export const DIRECTIONS = ["INPUT", "OUTPUT"];

export enum Family {
    PROVIDERS = "PROVIDERS",
    PURCHASE_ORDERS = "PURCHASE_ORDERS",
    WAREHOUSE_DELIVERIES = "WAREHOUSE_DELIVERIES",
    PAYMENT_ORDERS = "PAYMENT_ORDERS",
    PAYMENTS = "PAYMENTS",
    CUSTOMERS = "CUSTOMERS",
    VENDORS = "VENDORS",
    REFUNDS = "REFUNDS",
    CFDIS = "CFDIS",
    DIOTS = "DIOTS",
    CATALOGS = "CATALOGS",
    PAYROLLS = "PAYROLLS",
    PROJECTS ="PROJECTS",
    BUDGETS = "BUDGETS",
    PROVIDERS_EXPEDIENT = "PROVIDERS_EXPEDIENT",

}

export const FAMILIES = ["PROVIDERS", "PURCHASE_ORDERS", "WAREHOUSE_DELIVERIES", "PAYMENTS", "CUSTOMERS", "VENDORS", "REFUNDS", "CFDIS", "PAYROLLS", "PROJECTS", "PROVIDERS_EXPEDIENT"];

export enum Format {
    CSV = "CSV",
    XLSX = "XLSX",
    XLS = "XLS",
    XML = "XML",
    JSON = "JSON",
    TXT = "TXT",
    PLUGIN = "PLUGIN",
    LAMBDA = "LAMBDA",
    AWS_S3 = "AWS_S3",
}

export const FORMATS_NO_PLUGIN = ["CSV", "JSON", "TXT", "XLS", "XLSX", "XML"];

export const FORMATS = FORMATS_NO_PLUGIN.concat(["PLUGIN"]);

export const getFormats = (family?: Family, direction?: Direction): string[] => {
    if (!family || !direction) {
        return [];
    };
    switch (family) {
        case Family.PAYMENTS:
            if (Direction.INPUT === direction) {
                return FORMATS.concat("AWS_S3");
            }
            return FORMATS_NO_PLUGIN.concat("LAMBDA")
        default:
            if (Direction.INPUT === direction) {
                return FORMATS_NO_PLUGIN
            }
            return FORMATS_NO_PLUGIN.concat("LAMBDA")
    }
}


export interface SecureUrlResponse {
    url: string;
    url_preview?: string;
}
