import React, { useState } from "react";
import { Grid, Typography, ListItem, IconButton } from "@material-ui/core";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis, Tooltip, TooltipPayload
} from "recharts";
import { Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";

import { Project } from "../model/Project";
import Arrow from '@material-ui/icons/Forward';
import ArrowIcon from "@material-ui/icons/ArrowForward";

interface BudgetRangeBarProps {
    project: Project;
    fromDashboard: boolean;
}

export default function BudgetRangeBar(props: BudgetRangeBarProps) {
    const [redirect, setRedirect] = useState<string>();
    const history = useHistory();

    const onClickedGo = () => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        let qs = queryString.parse(window.location.search);
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/projects/detail/${props.project.id}/`);
    };

    const data = [
        { name: props.project.name, current: props.project.current_budget, accrued: props.project.accrued_budget || 0 as number, initial: props.project.initial_budget, toaccrue: props.project.budget_to_accrue !== undefined ? props.project.budget_to_accrue : props.project.assigned_budget, toassign: props.project.budget_to_assign }
    ];

    const renderTargetTick = (params: any) => {
        const { x, y, width, height, index, fill } = params;

        return (
            <line
                key={`target-${index}`}
                x1={x + width}
                x2={x + width}
                y1={y }
                y2={y + height}
                stroke={fill}
            />
        );
    };

    const tooltipFormatter = (value: string | number | Array<string | number>, name: string, entry: TooltipPayload, index: number) => {

        if(typeof value === "string" || typeof value === "number"){
            if (name === "toaccrue") {
                return [<Typography variant="inherit" style={{ color: "#202124"  }}>
                {translate(`requisitions.projects.columns.to_accrue`) + " : " as string}
                <NumberFormat value={value} thousandSeparator="," prefix="$" decimalScale={2} displayType="text" /></Typography>, <Typography variant={"inherit"}> {translate(`requisitions.projects.columns.to_accrue`) as string}</Typography>];
            } else if (name === "toassign"){
                return [<Typography variant="inherit" style={{ color: "#202124"  }}>
                    {translate(`requisitions.projects.columns.to_assign`) + " : " as string}
                    <NumberFormat value={value} thousandSeparator="," prefix="$" decimalScale={2} displayType="text" /></Typography>, <Typography variant={"inherit"}>{translate(`requisitions.projects.columns.to_assign`) as string}</Typography>];
            } else if (name === "accrued"){
                return [<Typography variant="inherit" style={{ color: "#202124"  }}>
                {translate(`requisitions.projects.columns.accrued`) + " : " as string}
                <NumberFormat value={value} thousandSeparator="," prefix="$" decimalScale={2} displayType="text" /></Typography>, <Typography variant={"inherit"}>{translate(`requisitions.projects.columns.accrued`) as string}</Typography>];
            } else if (name === "initial") {
                return [<Typography variant="inherit" style={{ color: "#202124"  }}>
                {translate(`requisitions.projects.columns.original_budget`) + " : " as string}
                <NumberFormat value={value} thousandSeparator="," prefix="$" decimalScale={2} displayType="text" /></Typography>, <Typography variant={"inherit"}>{translate(`requisitions.projects.columns.original_budget`)as string}</Typography>];
            } else {
                return [<Typography variant="inherit" style={{ color: "#202124"  }}>
                {translate(`requisitions.projects.columns.current_budget`) + " : " as string}
                <NumberFormat value={value} thousandSeparator="," prefix="$" decimalScale={2} displayType="text" /></Typography>, <Typography variant={"inherit"}>{translate(`requisitions.projects.columns.current_budget`) as string}</Typography>];
            }
        }
    }

    const VsBudget = () => {
        let valueTransform = props.project;
        let equal = valueTransform.assigned_budget && valueTransform.current_budget === valueTransform.initial_budget && valueTransform.assigned_budget === valueTransform.initial_budget;
        let down = valueTransform.current_budget === valueTransform.initial_budget && (!valueTransform.assigned_budget || valueTransform.assigned_budget < valueTransform.current_budget);
        return <Grid>
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item>
                        {equal ? <Arrow style={{ color: "blue", "width": 20, "height": 20 }} /> :
                            down ? <Arrow style={{ color: "green", "width": 20, "height": 20, "transform": "rotate(90deg)" }} /> :
                                <Arrow style={{ color: "red", "width": 20, "height": 20, "transform": "rotate(-90deg)" }} />}
                    </Grid>
                    <Grid item>
                        <Typography variant="inherit">
                            {equal ? translate("requisitions.projects.status_budget.EQUAL") as string : down ? translate("requisitions.projects.status_budget.DOWN") as string
                                : translate("requisitions.projects.status_budget.UP") as string}
                        </Typography>
                    {props.fromDashboard && 
                        <IconButton size="small" onClick={onClickedGo()}>
                            <ArrowIcon />
                        </IconButton>
                    }
                    </Grid>
                </Grid>
            </ListItem>
        </Grid>
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Grid container xs={12} direction="row" justify="center" >
            <Grid item xs={3} style={{marginRight:"-7%"}}>
                <ListItem>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="inherit" style={{ "opacity": 0.5 }}>
                                { props.fromDashboard ? translate("requisitions.projects.columns.current_budget") :
                                translate("requisitions.projects.columns.current_budget_short")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <NumberFormat value={props.project.current_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItem>
            </Grid>
            <Grid item xs={props.fromDashboard ? 5 : 6}>
                <BarChart
                    width={props.fromDashboard ? 550 : 650}
                    height={80}
                    data={data}
                    layout="vertical"
                >
                    <XAxis type="number" tick={false} tickLine={false} axisLine={false} />
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" scale="band" type="category"/>
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" yAxisId="back" hide scale="band" type="category"/>
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" yAxisId="target" hide scale="band" type="category"/>
                    <Tooltip formatter={tooltipFormatter} />
                    <Bar
                    className="monotone"
                    yAxisId="back"
                    fill={"#FDE4D3"}
                    barSize={30}
                    radius={5}
                    dataKey="current"
                     />
                    <Bar 
                        className="monotone" 
                        stackId="a" 
                        dataKey="accrued" 
                        fill="#1F679F" 
                        barSize={20} //Accrued
                    /> 
                    <Bar   
                        className="monotone" 
                        stackId="a" 
                        dataKey="toaccrue" 
                        fill="#F69450" 
                        barSize={20} //To accrue
                    />
 
                    <Bar
                        className="monotone"
                        dataKey="toassign"
                        stackId="a"
                        fill={"#ffcc50"} //ToAssign
                        barSize={20}
                        />
                    <Bar
                        className="monotone"
                        dataKey="initial"
                        yAxisId="target"
                        fill="red" //Original
                        barSize={20}
                        shape={renderTargetTick}
                        />

                </BarChart>
            </Grid>
            <Grid item xs={props.fromDashboard ? 4 : 3} style={{marginLeft:"7%"}}>
                <ListItem>
                        <VsBudget/>
                </ListItem>

            </Grid>
        </Grid>
    );

}