import React, { useState, useEffect } from "react";
import { Editor as EditorComponent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RawDraftContentState } from 'draft-js';

interface EditorProps {
    onChange?(contentState: RawDraftContentState): any;
    value: string;
    readOnly?: boolean;
}

export default function Editor(props: EditorProps) {
    const [contentState, setContentState] = useState<RawDraftContentState>();
    const [loading, setLoading] = useState<boolean>(true);

    const load = () => {
       if(props.value !== ""){
        setContentState(JSON.parse(props.value));
       }
       setLoading(false)
    };

    useEffect(load, [props]);

    if(loading) {
        return <></>;
    }

    return (
        <EditorComponent
            initialContentState={contentState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onContentStateChange={props.onChange}
            readOnly={props.readOnly}
            toolbarHidden={props.readOnly}
        />
    );
}