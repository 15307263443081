import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { Cfdi, CfdiRequest, Cfdis } from "../model/Cfdi";
import { PrepareCfdiRequest, CommentRequest, CfdisQueryParams } from "../model/CfdiSe";
import { CfdiHistories } from "../model/CfdiHistory";
import { ProviderCfdiPendingDocOverdueResponse } from "../model/ProviderCfdi";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/se/cfdis`;

export async function list(tenantId: string, providerId: string, pageSize: number, offset: number, params: CfdisQueryParams): Promise<Cfdis> {
    let query = {
        identifier: params.identifier || "",
        customer: params.customer || "",
        provider: params.provider || "",
        receptor: params.receptor || "",
        statuses: params.statuses.join(","),
        date: params.date || "",
        from: params.from || "",
        to: params.to || "",
        dueDateComplete: params.due_date_complete || "",
        pageSize: pageSize.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    return await callAPI(basePath(tenantId, providerId), {
        method: HTTPMethod.GET,
        query: query
    });
}

export async function createCfdi(tenantId: string, providerId: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("companyId", request.company_id || "");

    return await callAPI(basePath(tenantId, providerId), {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function get(tenantId: string, providerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}`, {
        method: HTTPMethod.GET,
    });
}

export async function deleteCfdi(tenantId: string, providerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}`, {
        method: HTTPMethod.DELETE,
    });
}

export async function prepare(tenantId: string, providerId: string, cfdiId: string, request: PrepareCfdiRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}/prepare`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function editPrepare(tenantId: string, providerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}/prepare`, {
        method: HTTPMethod.PATCH,
    });
}

export async function send(tenantId: string, providerId: string, cfdiId: string, request: CommentRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}/send`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getHistory(tenantId: string, providerId: string, cfdiId: string, pageSize: number, offset: number): Promise<CfdiHistories> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}/history`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: pageSize.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getDocumentsUrl(tenantId: string, providerId: string, cfdiId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, providerId)}/${cfdiId}/documents`);
}

export async function resend(tenantId: string, providerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}/resend`, {
        method: HTTPMethod.PUT,
    });
}

export async function sendDocuments(tenantId: string, providerId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId, providerId)}/${cfdiId}/send-documents`, {
        method: HTTPMethod.PUT,
    });
}

export async function getCfdisPendingDocOverdue(tenantId: string, providerId: string): Promise<ProviderCfdiPendingDocOverdueResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/se/cfdis/pending-doc-overdue`, {
        method: HTTPMethod.GET,
    });
}