import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { RouterParams } from "../router/RouterParams";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { UserGroup, UserGroupQueryParams, UserGroups as UserGroupsList} from "../model/UserGroup";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { SupervisorAccount } from "@material-ui/icons";
import translate from "../i18n/Translator";
import { Button, Divider, Fab, Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import { deleteGroup, getUserGroups } from "../api/UserGroupAPI";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { MoreVertIcon } from "../components/Icons";
import UserGroupMenu from "./UserGroupMenu";
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function UserGroups({ match }: RouterParams){
    const context = useContext(AppContext);
    const history = useHistory();
    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [data, setData] = useState<UserGroupsList>();
    const [tenantId] = useState<string>(context.session?.tenant?.id ?? match.params.tenantId);
    const [redirect, setRedirect] = useState<string>();
    const [userGroup, setUserGroup] = useState<UserGroup>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const paramsFromQueryString = (): UserGroupQueryParams => {
        let qs = queryString.parse(window.location.search);
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as UserGroupQueryParams;
    };
    const [params, setParams] = useState<UserGroupQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<UserGroupQueryParams>(paramsFromQueryString);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const load = () => {
        setStatus('loading');
        getUserGroups(context.session!.tenant!.id, pageSize, getOffset(page, pageSize),params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const [status, setStatus] = useState<string>("loading");

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        pushHistory();
        setPage(1);
        setParams(workingParams);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        
        history.push(buildHistoryUrl(qs));
    };

    const buildHistoryUrl = (qs : any) => {
        return `${window.location.pathname}?${queryString.stringify(qs)}`;
    }

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedRow = (userGroup: UserGroup) => {
        pushHistory();
        setRedirect(getUrl(userGroup.id));
        setUserGroup(undefined);
    };

    const getUrl = (userGroupId: string) => {
        return `/tenants/${context.session!.tenant!.id}/user-groups/${userGroupId}/edit`;
    }

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickedOptions = (userGroup: UserGroup) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setUserGroup(userGroup);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setUserGroup(undefined);
    };

    const showDeletePopup = () => {
        if(userGroup && userGroup.usages && userGroup.usages.length > 0) {
            setError(translate('user_groups.delete.with_usages', {"usages": userGroup.usages.map(usage => usage.name).join(", ")}) as string);
            return;
        }
        setAnchorEl(null);
        setDeletePopup(true);
    };

    const onDeleteUserGroup = () => {
        deleteGroup(context.session!.tenant!.id, userGroup!.id).then(() => {
            setSuccess(translate('user_groups.delete.success') as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setDeletePopup(false);
            setUserGroup(undefined);
            load();
        });
    }

    const onCloseDeletePopup = () => {
        setDeletePopup(false);
        setUserGroup(undefined);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Pagination title={translate("user_groups.title")} icon={<SupervisorAccount />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["UserGroupCreate"]) ?
                (<Link to={`/tenants/${tenantId}/user-groups/new`}>
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
            </Link>) : undefined}>
                <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                    <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                        <Grid item xs={12} sm>
                            <ValidatedInput type="text" id="search" name="search" label={translate("user_groups.filter") as string}
                                margin="dense" disabled={false}
                                value={workingParams.search} onValueChanged={onFilterChanged} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button type="submit" variant="outlined" color="secondary" size="medium">
                                {translate("buttons.search")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Divider />
                <Gridable
                items={data && data.items ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("users.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("user_groups.name") as string,
                        converter: (userGroup) => (
                            <div>
                                <Ellipsis text={`${userGroup.name}`} lenght={0} uppercased={false} />
                            </div>
                        ),
                        justify: "flex-start",
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 8,
                        xl: 8
                    },
                    {
                        title: translate("user_groups.members") as string,
                        converter: (userGroup) => userGroup.users ? userGroup.users.length : 0,
                        justify: "center",
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("user_groups.created") as string,
                        converter: (userGroup) => (
                            <DateFormat date={userGroup?.created_at} format="DD-MM-YYYY h:mm a" zone={true} />
                        ),
                        justify: "flex-start",
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: "",
                        converter: (user) => (
                                <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(user)}>
                                    <MoreVertIcon />
                                </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto",
                        md: 1,
                        lg: "auto",
                        xl: "auto",
                    }
                ]} />
                {userGroup && anchorEl &&
                <UserGroupMenu
                    tenantId={tenantId}
                    url={getUrl(userGroup.id)}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onDelete={showDeletePopup} />
                }
                {userGroup && deletePopup && (
                <ConfirmationPopup
                    doAction={onDeleteUserGroup}
                    onClose={onCloseDeletePopup}
                    title={translate("user_groups.delete.title") as string}
                    message={translate("user_groups.delete.message", {"name":userGroup.name}) as string}
                    button={translate("buttons.accept") as string}
                />
            )}
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Pagination>
    );
}