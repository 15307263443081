import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import ExpedientIcon from '@material-ui/icons/AllInboxTwoTone';
import ContactsIcon from '@material-ui/icons/ContactsTwoTone';
import SwapHorizIcon from '@material-ui/icons/SwapHorizTwoTone';
import translate from "../i18n/Translator";
import { Entity } from "../model/Provider";
import { AppContext } from "../context/AppContext";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

interface ProviderExpedientMenuProps {
    provider: Entity;
    anchor: HTMLElement | null;
    onClose(): any;
    onAssignValidator(): any;
    onOpenChangeTemplateExpedient(): any;
    backTo?: string;
}

export default function ProviderExpedientMenu(props: ProviderExpedientMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["TenantFieldsRead", "ValidationExpedientRead"]) &&
                <MenuItem button component={Link} to={`/providers/${props.provider.id}?referer=expedients&tab=contacts&${props.backTo}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <ContactsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.contacts")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TenantFieldsRead", "ValidationExpedientRead"]) &&
                <MenuItem button component={Link} to={`/providers/${props.provider.id}?referer=expedients&tab=validation&${props.backTo}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <ExpedientIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.validate.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantFieldsUpdate") &&
                <MenuItem button onClick={props.onAssignValidator} dense>
                    <ListItemIcon>
                        <PersonAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.assign_validator")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantFieldsUpdate") &&
                <MenuItem button onClick={props.onOpenChangeTemplateExpedient} dense>
                    <ListItemIcon>
                        <SwapHorizIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.template_expedient.description")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 