import React, { useEffect } from "react";
import { Grid, Box } from "@material-ui/core";

interface OpenpayTokenProps {
    merchantId: string;
    publicKey: string;
    sandbox: boolean;
    onLoaded(deviceId?: string): any;
}

export const getOpenpay = (): Openpay => {
    return (window as OpenpayWindow & typeof globalThis).OpenPay;
};

export default function OpenpayToken(props: OpenpayTokenProps) {

    const onLoadOpenpayAf = () => {
        let openpay = getOpenpay();
        openpay.setId(props.merchantId);
        openpay.setApiKey(props.publicKey);
        openpay.setSandboxMode(props.sandbox);

        props.onLoaded(openpay.deviceData?.setup());
    };

    const onLoadOpenpay = (first: HTMLScriptElement) => () => {
        let jsAf = document.createElement("script");
        jsAf.async = true;
        jsAf.src = "https://js.openpay.mx/openpay-data.v1.min.js";
        jsAf.onload = onLoadOpenpayAf;
        first.parentNode?.insertBefore(jsAf, first);
    };

    const loadScripts = () => {
        let first = document.getElementsByTagName("script")[0];
        let js = document.createElement("script");
        js.async = true;
        js.src = "https://js.openpay.mx/openpay.v1.min.js";
        js.onload = onLoadOpenpay(first);
        first.parentNode?.insertBefore(js, first);
    };

    useEffect(loadScripts, []);

    return (
        <div style={{ "display": "none" }}></div>
    );
}

export function OpenpayBadges() {
    return (
        <Grid item xs={12}>
            <Box py={2}>
                <Grid container justify="center" alignContent="center" alignItems="center">
                    <Grid item xs="auto">
                        <img src="/openpay_color.png" alt="Openpay" className="LogoOpenpay" />
                    </Grid>
                    <Grid item xs>
                        <Grid container justify="flex-end" alignContent="center" alignItems="center" spacing={2}>
                            <Grid item xs="auto">
                                <img src="/visa.png" alt="Visa" className="Visa" />
                            </Grid>
                            <Grid item xs="auto">
                                <img src="/masterCard.png" alt="MasterCard" className="MasterCard" />
                            </Grid>
                            <Grid item xs="auto">
                                <img src="/americanExpress.png" alt="AmericanExpress" className="AmericanExpress" />
                            </Grid>
                            <Grid item xs="auto">
                                <img src="/carnet.png" alt="Carnet" className="Carnet" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

export interface Openpay {
    setId(id: string): any;
    setApiKey(apiKey: string): any;
    setSandboxMode(flag: boolean): any;
    getSandboxMode(): boolean;
    deviceData?: OpenpayDeviceData;
    token?: OpenpayTokens;
    card?: OpenpayCards;
}

export interface OpenpayDeviceData {
    setup(): string;
}

export interface OpenpayTokens {
    create(request: TokenRequest, onSuccess: (data: TokenResponse) => any, onError: (data: OpenpayErrorResponse) => any): any;
}

export interface OpenpayCards {
    validateCardNumber(card: string): boolean;
    validateCVC(cvv: string, card?: string): boolean;
    validateExpiry(month: number, year: number): boolean;
}

export interface TokenRequest {
    holder_name: string;
    card_number: string;
    cvv2: string;
    expiration_month: number;
    expiration_year: number;
}

export interface TokenDataResponse {
    id: string;
}

export interface TokenResponse {
    data: TokenDataResponse;
}

export interface OpenpayErrorResponse {
    data: OpenpayError;
}

export interface OpenpayError {
    category: string;
    description: string;
    error_code: number;
    http_code: number;
    request_id: string;
}

interface OpenpayWindow extends Window {
    OpenPay: Openpay;
}