import { callAPI, HTTPMethod } from "./API";
import { LedgerAccounts, CostCenters } from "../model/LedgerAccountAndCostCenter";

export async function getCostCenters(tenantId: string, search: string, pageSize: number, offset: number): Promise<CostCenters> {
    return await callAPI(`/tenants/${tenantId}/ledger/cost-centers`, {
        method: HTTPMethod.GET,
        query: {
            search: search,
            pageSize: pageSize.toString(),
            offset: offset.toString()
        }
    });
}

export async function getLedgerAccounts(tenantId: string, search: string, pageSize: number, offset: number): Promise<LedgerAccounts> {
    return await callAPI(`/tenants/${tenantId}/ledger/ledger-accounts`, {
        method: HTTPMethod.GET,
        query: {
            search: search,
            pageSize: pageSize.toString(),
            offset: offset.toString()
        }
    });
}
