import React, { ReactNode } from "react";
import { Grid, Checkbox } from '@material-ui/core';
import Identifiable from "../model/Identifiable";

export interface CheckBoxCallbacks<T> {
    handleCheck(item: T): any;
    handleAllChecks(checked: boolean): any;
    onClickedOptions(item: T): any;
}

interface CheckBoxColumnTitleProps<T> {
    total: number;
    selected: number;
    showChecks: boolean;
    callbacks: CheckBoxCallbacks<T>;
    label_title: string;
}

export function CheckBoxColumnTitle<T>(props: CheckBoxColumnTitleProps<T>) {

    const handleAllCheckboxs = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        props.callbacks.handleAllChecks(checked);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto" >
                        <Checkbox
                            id="items_all"
                            name="items_all"
                            checked={props.selected > 0 && props.selected === props.total}
                            indeterminate={props.selected > 0 && props.selected < props.total}
                            onChange={handleAllCheckboxs}
                            disabled={props.total === 0}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                <Grid item xs>
                    <strong>{props.label_title}</strong>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface CheckBoxColumnValueProps<T extends Identifiable> {
    item: T;
    checked: boolean;
    showChecks: boolean;
    callbacks: CheckBoxCallbacks<T>;
    value: string | ReactNode;
    disabled?: boolean;
}

export function CheckBoxColumnValue<T extends Identifiable>(props: CheckBoxColumnValueProps<T>) {

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.callbacks.handleCheck(props.item);
    };
    const onClickCheck = () => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto" onClick={onClickCheck()}>
                        <Checkbox
                            id={props.item.id}
                            name={props.item.id}
                            value={props.item.id}
                            checked={props.checked}
                            onChange={handleSelection}
                            className="GridableCheck"
                            disabled={props.disabled}
                        />
                    </Grid>
                )}

                <Grid item xs style={{ hyphens: "auto" }}>
                    {props.value}
                </Grid>
            </Grid>

        </Grid >
    );
}