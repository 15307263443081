import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import translate from "../i18n/Translator";
import { Grid, Fab, Icon, Box, FormControl, Select, Button } from "@material-ui/core";
import ExpenseAccounIcon from '@material-ui/icons/MenuBookTwoTone';
import queryString from "query-string";
import { formatDateString, concatDates } from "../components/DateFormat";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import ValidatedInput from "../components/ValidatedInput";
import Progress from "../components/Progress";
import Surface from "../components/Surface";
import { Filter, TableSelection } from "@devexpress/dx-react-grid";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { AppContext } from "../context/AppContext";
import { ExpenseAccount, ExpensesAccounts, Cfdi, TypeExpensesAccount } from "../model/Cfdi";
import { ExpensesAccountQueryParams as ReportExpensesAccountParams } from "../model/TenantCfdi";
import { ExportProviders } from "../model/Provider";
import { listExpensesAccounts, exportExpensesAccountExcel } from "../api/TenantCfdiApi";

export default function ReportExpensesAccounts() {
  const history = useHistory();

  const context = useContext(AppContext);
  const [status, setStatus] = useState<string>("loading");
  const [report, setReport] = useState<ExpensesAccounts>();
  const [exportResult, setExportResult] = useState<ExportProviders>();
  const [error, setError] = useState<string>();
  const [warning, setWarning] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const [page, setPage] = useState<number>(initialPageZero);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const qs = queryString.parse(window.location.search);
  const [queryParam, setQueryParam] = useState<string>();
  const paramsFromQueryString = (): ReportExpensesAccountParams => {
    return {
      "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
      "start_date": typeof qs["start_date"] === "string" ? qs["start_date"] as string : "",
      "end_date": typeof qs["end_date"] === "string" ? qs["end_date"] as string : "",
      "company": typeof qs["company"] === "string" ? qs["company"] as string : "",
      "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : "",
      "provider_external_id": typeof qs["provider_external_id"] === "string" ? qs["provider_external_id"] as string : "",
      "expense_account_type": typeof qs["expense_account_type"] === "string" ? qs["expense_account_type"] as string : "",
      "folio": typeof qs["folio"] === "string" ? qs["folio"] as string : ""
    } as ReportExpensesAccountParams;
  };
  const [params, setParams] = useState<ReportExpensesAccountParams>(paramsFromQueryString);
  const [workingParams, setWorkingParams] = useState<ReportExpensesAccountParams>(paramsFromQueryString);
  const paramsToFilters = () => {
    return [
      { columnName: 'date', value: concatDates(params.start_date, params.end_date) },
      { columnName: 'company', value: params.company },
      { columnName: 'issuer', value: params.provider },
      { columnName: 'provider_external_id', value: params.provider_external_id },
      { columnName: 'type', value: params.expense_account_type },
      { columnName: 'folio', value: params.folio },
    ] as Filter[];
  };
  const [filters, setFilters] = useState<Filter[]>(paramsToFilters);
  const [redirect, setRedirect] = useState<string>();

  useEffect(() => {
    setStatus("loading");
    listExpensesAccounts(context.session!.tenant!.id, pageSize, pageSize * page, params).then(response => {
      if (response && response.items)
        setReport(response);
    }).catch(error => {
      setStatus(error.message);
    }).finally(() => {
      setStatus("loaded");
    });
  }, [context.session, params, page, pageSize, filters]);

  const setFiltersHandler = (filters: Filter[]) => {
    setFilters(filters);
    setParamsFromfilters(filters);
  };

  const setParamsFromfilters = (filters: Filter[]) => {
    let temp = params;
    filters.forEach(filter => {
      if (filter.value !== undefined) {
        switch (filter.columnName) {
          case "date":
            let dateAt = filter.value.split(" - ");
            if (dateAt.length === 2) {
              
                temp.start_date = formatDateString(dateAt[0]);
                temp.end_date = formatDateString(dateAt[1]);
              
            } else {
              temp.start_date = "";
              temp.end_date = "";
            }
            break;
          case "company":
            temp.company = filter.value;
            break;
          case "issuer":
            temp.provider = filter.value;
            break;
          case "provider_external_id":
            temp.provider_external_id = filter.value;
            break;
          case "folio":
            temp.folio = filter.value;
            break;
          case "type":
            temp.expense_account_type = filter.value;
            break;
          default: break;
        }
      }
    });
    setWorkingParams(temp);
    setParams(temp);
  };

  const onAppliedFilter = () => {
    setParams(workingParams);
  };

  const onCloseSnackbars = () => {
    setError(undefined);
    setSuccess(undefined);
    setWarning(undefined);
  };

  const onCloseDownloadExcel = () => {
    setExportResult(undefined);
  }

  const onChangedPage = (page: number) => {
    setPage(page);
  };

  const onChangedPageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const tableColumnExtensions = [
    { columnName: 'date', wordWrapEnabled: true, width: '200', align: 'center' },
    { columnName: 'company', wordWrapEnabled: true, width: '200', align: 'left' },
    { columnName: 'issuer', wordWrapEnabled: true, width: '250', align: 'left' },
    { columnName: 'provider_external_id', wordWrapEnabled: true, width: '150', align: 'left' },
    { columnName: 'type', wordWrapEnabled: true, width: '150', align: 'left' },
    { columnName: 'folio', wordWrapEnabled: true, width: '150', align: 'left' },
    { columnName: 'description', wordWrapEnabled: true, width: '200', align: 'center' },
    { columnName: 'balance', wordWrapEnabled: true, width: '150', align: 'right' },
    { columnName: 'payed', wordWrapEnabled: true, width: '150', align: 'right' },
    { columnName: 'total', wordWrapEnabled: true, width: '150', align: 'right' },
    { columnName: 'status', wordWrapEnabled: true, width: '200', align: 'center' },
  ] as any;

  const pushHistory = () => {
    let qs = queryString.parse(window.location.search);
    qs["search"] = workingParams.search;
    qs["company"] = workingParams.company;
    qs["issuer"] = workingParams.provider ? workingParams.provider : "";
    qs["provider_external_id"] = workingParams.provider_external_id ? workingParams.provider_external_id : "";
    qs["type"] = workingParams.expense_account_type ? workingParams.expense_account_type : "";
    qs["folio"] = workingParams.folio ? workingParams.folio : "";
    qs["start_date"] = workingParams.start_date ? workingParams.start_date : "";
    qs["end_date"] = workingParams.end_date ? workingParams.end_date : "";
    qs["page"] = "0";

    let url = window.location.pathname + "?" + queryString.stringify(qs);
    setQueryParam(queryString.stringify(qs));
    history.push(url);
  };

  const clickRowColumns = ['folio'];

  const currencyColumns = ['balance', 'payed', 'total'];

  const dateColumns = ['date'];

  const filtersExclude = ['description', 'balance', 'payed', 'total', 'status'];

  const textColumns = ['company', 'issuer', 'type', 'description', 'folio', 'provider_external_id'];

  const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'issuer']) as any;

  const TypeFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
    const onChange = (event: any) => {
      const { value: targetValue } = event.target;
      if (targetValue.trim() === '') {
        params.onValueChange("");
        return;
      }
      params.onValueChange(targetValue);
    };

    return (
      <Box px={1}>
        <FormControl >
          <Select native style={{
            fontSize: "10px"
          }}
            labelId="is_default"
            id="is_default"
            value={params.value ? params.value : ''}
            onChange={onChange} >
            {TypeExpensesAccount.map((value) => {
              if (value.value === "ALL") {
                return <option key={value.value} value={""}>{""}</option>
              }
              return <option key={value.value} value={value.value}>{value.title}</option>
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const InvoiceTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={(value: any) => {
      return <>{translate(`payable_documents.type.${value.row.type}`) as string}</>;
    }} {...props} />
  );

  const NormalTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider {...props} />);
  const customPlugins = [
    <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    <InvoiceTypeProvider for={['type']} editorComponent={TypeFilterEditor} />,
  ];

  const onClickedRow = (cfdi: ExpenseAccount) => {
    pushHistory();
    setRedirect(`/cfdis/${cfdi.id}/details?referer=expenses-accounts&${queryParam}`);
  };

  const getStatus = (cfdi: ExpenseAccount) => {
    if (cfdi.metadata.custom_status) {
      return cfdi.metadata.custom_status;
    }
    return translate(`cfdis.history.status.${cfdi.metadata.status}`) as string;
  };

  const onFilterChanged = (name: string, value: string) => {
    setWorkingParams({ ...workingParams, [name]: value });
  };

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  if (status === "loading") {
    return (<Progress />);
  }

  const exportExcelExpensesAccount = () => {
    exportExpensesAccountExcel(context.session!.tenant!.id, 0, 0, params).then((response) => {
      if (response.url) {
        setExportResult(response);
      } else {
        setSuccess(translate("providers.email_export", { "total": response.total }) as string);
      }
    }).catch((error) => {
      setError(error.message);
    });
  }

  const showErrorRange = () => {
    setWarning(translate("reports.custom_reports.only_allowed_three_months_ago") as string);
  }

  return (
    <Surface
      title={translate("reports.expenses_accounts.title") as string}
      subtitle={translate("reports.expenses_accounts.expenses_account") as string}
      icon={<ExpenseAccounIcon />}
      className="PaperPagination" titleActions={
        <Grid container alignItems="center" justify="flex-end" spacing={1}>
          {report && report.items.length > 0 &&
            <Grid item xs="auto">
              <Fab color="primary" size="small" title={translate("reports.late_cfdi_payments.export") as string} onClick={exportExcelExpensesAccount} >
                <Icon className="fas fa-file-excel" fontSize="small" />
              </Fab>
            </Grid>}
        </Grid>
      }>
      <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
        <Grid item xs={12} sm>
          <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter_expenses_account") as string}
            margin="dense" disabled={false}
            value={workingParams.search} onValueChanged={onFilterChanged} />
        </Grid>
        <Grid item xs="auto">
          <Button variant="outlined" color="secondary" size="medium" onClick={onAppliedFilter} >
            {translate("buttons.search")}
          </Button>
        </Grid>
      </Grid>
      {report ?
        <GridDx
          columns={[
            {
              name: 'date',
              title: translate('reports.expenses_accounts.columns.date') as string
            },
            {
              name: 'company',
              title: translate('reports.expenses_accounts.columns.company') as string,
              getCellValue: (row: any) => row.metadata.company_name
            },
            {
              name: 'issuer',
              title: translate('reports.expenses_accounts.columns.issuer') as string,
              getCellValue: (row: any) => row.issuer.name
            },
            {
              name: 'provider_external_id',
              title: translate('reports.expenses_accounts.columns.external_provider_id') as string,
              getCellValue: (row: any) => row.metadata.provider_external_id || "---"
            },
            {
              name: 'type',
              title: translate('reports.expenses_accounts.columns.type') as string
            },
            {
              name: 'folio',
              title: translate("reports.expenses_accounts.columns.folio") as string,
              getCellValue: (row: any) => row.identifier
            },
            {
              name: 'description',
              title: translate("reports.expenses_accounts.columns.description") as string
            },
            {
              name: 'balance',
              title: translate("reports.expenses_accounts.columns.balance") as string,
              getCellValue: (row: any) => row.metadata.balance
            },
            {
              name: 'payed',
              title: translate("reports.expenses_accounts.columns.payed") as string,
              getCellValue: (row: any) => row.total - row.metadata.balance
            },
            {
              name: 'total',
              title: translate("reports.expenses_accounts.columns.total") as string
            },
            {
              name: 'status',
              title: translate("reports.expenses_accounts.columns.status") as string,
              getCellValue: (row: any) => getStatus(row)
            }
          ]}
          loading={status === "loading"}
          rows={report ? report.items : []}
          page={page}
          pageSize={pageSize}
          totalRows={report ? report.total : 0}
          amountCurrencyColumns={currencyColumns}
          dateColumns={dateColumns}
          columnsFormat={tableColumnExtensions}
          textColumns={textColumns}
          filters={filters}
          setFiltersHandler={setFiltersHandler}
          clickRowColumns={clickRowColumns}
          onClickRow={onClickedRow}
          leftColumns={leftColumns}
          customFormatColumns={customPlugins}
          onChangedPage={onChangedPage}
          onChangedPageSize={onChangedPageSize}
          onShowRangeError={showErrorRange}
          limitRangeMonths={3}
        />
        : undefined}
      {exportResult && exportResult.url && (
        <DownloadExportationPopup title={translate("reports.expenses_accounts.export_title") as string} message={translate("reports.expenses_accounts.export_message", { "total": exportResult.total }) as string} url={exportResult.url} onClose={onCloseDownloadExcel} />
      )}
      <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
      <WarningSnackbar message={warning} onClose={onCloseSnackbars}/>
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </Surface>
  );
}
