import React, { useState, useContext, useEffect, useRef } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { Button, Grid, Divider, IconButton, Fab, Hidden } from "@material-ui/core";
import ProvidersIcon from "@material-ui/icons/Business";
import VendorsIcon from "@material-ui/icons/SupervisedUserCircle";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PublishIcon from "@material-ui/icons/Publish";
import AddUsersIcon from '@material-ui/icons/GroupAdd';
import translate from "../i18n/Translator";
import { getProviders, importProviders, importUsers, exportProviders, exportSelectedProviders, deleteProvider, exportUsersProviders } from "../api/ProviderAPI";
import { Providers, Entity as Provider, ProvidersQueryParams, ImportSummary, ProviderStatuses, ImportUsersSummary } from "../model/Provider";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import ProviderManagementMenu from "./ProviderManagementMenu";
import ImportResumePopup from "./ImportResumePopup";
import ImportUsersResumePopup from "./ImportUsersResumePopup";
import ProviderClassificationsPopup from "./ProviderClassificationsPopup";
import ProvidersManagementMenu from "./ProvidersManagementMenu";
import Gridable, { GridableColumn } from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import ExportPopup from "../components/DownloadExportationPopup";
import translator from "../i18n/Translator";
import CustomBackdrop from "../components/CustomBackdrop";
import { ProviderListCallbacks } from "./Providers";
import ConfirmationPopup from "../components/ConfirmationPopup";
import ConnectorTemplateDownloader from "../connectors/ConnectorTemplateDownloader";
import { ExportResponse, Family } from "../model/Connector";
import ConnectorObjectExporter, { ExportMode } from "../connectors/ConnectorObjectExporter";

interface ProvidersManagementListProps {
    subtitle?: string;
    entityType: "PROVIDER" | "VENDOR";
    getColumns(providers: Provider[], showChecks: boolean, selected: string[], callbacks: ProviderListCallbacks): GridableColumn<Provider>[];
}

export default function ProvidersManagementList(props: ProvidersManagementListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const showChecks = context.isGranted("ProvidersUpdate");
    const providerStatusLabels = ProviderStatuses.map((value: string) => translate(`providers.status.${value}`) as string);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Providers>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [exportResult, setExportResult] = useState<ExportResponse>();
    const [exportResultUsers, setExportResultUsers] = useState<ExportResponse>();

    const [provider, setProvider] = useState<Provider>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [redirect, setRedirect] = useState<string>();
    const isProvider = props.entityType === "PROVIDER";

    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ProvidersQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "not_located_status": "",
            "efos_status": "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "classification": typeof qs["classification"] === "string" ? qs["classification"] as string : "",
            "validator_user_id": "",
            "receptor": "",
            "specific_rule": "",
            "workflow_id": "",
            "selected_ids": "",
            "type": props.entityType === "PROVIDER" ? "" : "VENDOR",
        } as ProvidersQueryParams;
    };
    const [params, setParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const fileInput = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState(false);
    const [importSummary, setImportSummary] = useState<ImportSummary>();
    const [importUsersSummary, setImportUsersSummary] = useState<ImportUsersSummary>();
    const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<Provider[]>();
    const [classificationIds, setClassificationIds] = useState<string[]>([]);
    const [classificationNames, setClassificationNames] = useState<string[]>([]);
    const [importType, setImportType] = useState<string>("import_providers" || "import_users");
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [queryParam, setQueryParam] = useState<string>();
    const [downloadTemplate, setDownloadTemplate] = useState(false);
    const [exportMode, setExportMode] = useState<ExportMode>();

    const onExportWithParams = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setExportMode('PARAMS');
        } else {
            setWarning(translate("providers.empty_export") as string);
        }
    }

    const onExportSelected = () => {
        setGridAnchorEl(null);
        if (selectedProviderIds.length) {
            setExportMode('SELECTED');
        } else {
            setWarning(translate("providers.no_providers") as string);
        }
    }

    const onExportUsersWithParams = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            exportUsersProviders(context.session!.tenant!.id, params).then((response) => {
                if (response.url) {
                    window.open(response.url, "_blank")
                } else if (response.total > 0) {
                    setSuccess(translate("providers.email_export", { "total": response.total }) as string);
                } else {
                    setWarning(translate("providers.empty_export") as string);
                }
            }).catch((error) => {
                setError(error.message);
            }).finally(onCloseExporter);
        } else {
        }
    }
    
    const onExportUsersSelected = () => {
        setGridAnchorEl(null);
        if (selectedProviderIds.length) {
            exportUsersProviders(context.session!.tenant!.id, {...params, selected_ids: selectedProviderIds.toString()}).then((response) => {
                if (response.url) {
                    window.open(response.url, "_blank")
                } else if (response.total > 0) {
                    setSuccess(translate("providers.email_export", { "total": response.total }) as string);
                } else {
                    setWarning(translate("providers.empty_export") as string);
                }
            }).catch((error) => {
                setError(error.message);
            }).finally(onCloseExporter);
            
        } else {
            setWarning(translate("providers.no_providers") as string);
        }
    }

    const downloadProvidersWithConnector = (connectorId: string) => {
        if (!exportMode) return;

        let promise: Promise<ExportResponse>;
        if (exportMode === 'PARAMS') {
            promise = exportProviders(context.session!.tenant!.id, connectorId, params, isProvider);
        } else {
            promise = exportSelectedProviders(context.session!.tenant!.id, connectorId, selectedProviderIds, isProvider);
        }  

        promise.then((response) => {
            if (response.url) {
                setExportResult(response);
            } else if (response.total) {
                setSuccess(translate("providers.email_export", { "total": response.total }) as string);
            } else {
                setWarning(translate("providers.empty_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(onCloseExporter);
    }

    const onCloseExporter = () => {
        setExportMode(undefined);
    };

    const onDownloadTemplate = () => {
        setGridAnchorEl(null);
        setDownloadTemplate(true);
    };

    const openClassificationProviders = () => {
        setGridAnchorEl(null);
        if (!data) return;

        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            let selected = data.items.filter(provider => selectedProviderIds.indexOf(provider.id) >= 0);
            setSelectedProviders(selected);
        }
    }

    const openClassificationOneProvider = () => {
        setAnchorEl(null);
        if (!provider) return;

        setSelectedProviders([provider]);
    }

    const onCloseDownloadProviders = () => {
        setExportResult(undefined);
    }

    const onCloseDownloadProvidersUsers = () => {
        setExportResultUsers(undefined);
    }

    const load = () => {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        setSelectedProviderIds([]);
        pushHistory();
        getProviders(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setClassificationIds(response.classifications ? response.classifications.map((classification) => classification.id) : []);
            setClassificationNames(response.classifications ? response.classifications.map((classification) => classification.name) : []);
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status"] = workingParams.status;
        qs["classification"] = workingParams.classification;
        qs["page"] = "1";
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    }
    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        pushHistory();
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setProvider(undefined);
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setUploading(true);
        if (importType === "import_providers") {
            importProviders(context.session!.tenant!.id, event.target.files[0]).then((response) => {
                setImportSummary(response);
                load();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setUploading(false);
                fileInput.current!.value = "";
            });
        }

        if (importType === "import_users") {
            importUsers(context.session!.tenant!.id, event.target.files[0]).then((response) => {
                setImportUsersSummary(response);
                load();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setUploading(false);
                fileInput.current!.value = "";
            });
        }
    }

    const openFileImportProviders = () => {
        fileInput.current!.click();
        setImportType("import_providers");
        setGridAnchorEl(null);
    }

    const openFileImportUsers = () => {
        fileInput.current!.click();
        setImportType("import_users");
        setGridAnchorEl(null);
    }

    const onCloseImportDialog = () => {
        setImportSummary(undefined);
        setImportUsersSummary(undefined);
        setGridAnchorEl(null);
        fileInput.current!.value = "";
    };

    const onCloseClassification = () => {
        setGridAnchorEl(null);
        setSelectedProviders(undefined);
    };

    const onSuccessClassificationProvider = (updated: number) => {
        setGridAnchorEl(null);
        setSelectedProviders(undefined);
        setSuccess(translator("providers.classifications.success", { "updated": updated }) as string);
        load();
    };

    const onClickedOptions = (provider: Provider) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setProvider(provider);
    };

    const handleCheck = (provider: Provider) => {
        let items = selectedProviderIds.length === 0 ? ([] as string[]) : selectedProviderIds.join(",").split(",");
        const index = selectedProviderIds.indexOf(provider.id);
        if (index < 0) {
            items.push(provider.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedProviderIds(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.items.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelectedProviderIds(items);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedRow = (provider: Provider) => {
        pushHistory();
        if(isProvider){
            setRedirect(`/providers/${provider.id}?referer=base&tab=info&${queryParam}`);
        } else {
            setRedirect(`/vendors/detail/${provider.id}?referer=base&tab=info&${queryParam}`);
        }
    };

    const cancelConfirmDialog = () => {
        setShowDeleteDialog(false);
        setProvider(undefined);
    };

    const openConfirmDialog = () => {
        setShowDeleteDialog(true);
        setAnchorEl(null);
    };

    const onConfirmDeleteProvider = () => {
        if (!provider) return;
        deleteProvider(context.session!.tenant!.id, provider.id).then((_) => {
            setShowDeleteDialog(false);
            setProvider(undefined);
            load();
            setSuccess(translate("providers.success_delete") as string);
        }).catch((error) => {
            setError(error.message);
        });
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Pagination title={isProvider ? translate("providers.title") : translate("providers.vendors.title")} icon={isProvider ? <ProvidersIcon /> : <VendorsIcon />}
            subtitle={isProvider ? props.subtitle : undefined}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["ProvidersCreate", "VendorsCreate"]) ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Link to= {isProvider ? "/providers/new" : "/vendors/new"}>
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                <AddIcon />
                            </Fab>
                        </Link>
                    </Grid>
                    { isProvider && 
                    <Grid item xs="auto">
                        <Hidden xsDown implementation="css">
                            <Fab color="secondary" size="small" title={translate("providers.import.button") as string} onClick={openFileImportProviders}>
                                <PublishIcon />
                            </Fab>
                        </Hidden>
                    </Grid>
                    }
                    {context.isGranted("OwnerProvidersUsersCreate") && isProvider?
                        <Grid item xs="auto">
                            <Hidden xsDown implementation="css">
                                <Fab color="secondary" size="small" title={translate("providers.import_users.button") as string} onClick={openFileImportUsers}>
                                    <AddUsersIcon />
                                </Fab>
                            </Hidden>
                        </Grid> : undefined}
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore} disabled={uploading}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>) : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm={true} md={true} lg>
                        <ValidatedInput type="text" id="search" name="search" label={translate("providers.filter_search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    { isProvider &&
                    <Grid item xs={12} sm={2} md={2} lg="auto">
                        <ValidatedInput type="text" id="status" name="status" label={translate("providers.status.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.status} onValueChanged={onFilterChanged}
                            options={ProviderStatuses}
                            optionLabels={providerStatusLabels}
                            emptyOption={translate("any") as string} />
                    </Grid>}
                    { isProvider &&
                    <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
                        <ValidatedInput type="text" id="classification" name="classification" label={translate("providers.classifications.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.classification} onValueChanged={onFilterChanged}
                            options={classificationIds}
                            optionLabels={classificationNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    }
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("providers.empty") as string}
                onClick={onClickedRow}
                columns={props.getColumns(data ? data.items : [], showChecks, selectedProviderIds, {
                    onClickedOptions: onClickedOptions,
                    handleCheck: handleCheck,
                    handleAllChecks: handleAllChecks
                })} />
            {downloadTemplate && (
                <ConnectorTemplateDownloader tenantId={context.session?.tenant?.id || ""}
                    family={Family.PROVIDERS}
                    onClose={() => setDownloadTemplate(false)} />
            )}
            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".csv, .xlsx, .xls, .xml, .json, .txt, .tsv" />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {provider && anchorEl && (
                <ProviderManagementMenu
                    provider={provider}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onSortProvider={openClassificationOneProvider}
                    onDeleteProvider={openConfirmDialog}
                    backTo={queryParam}
                    entityType={props.entityType}
                />
            )}
            {importSummary && (
                <ImportResumePopup summaryImport={importSummary} onClose={onCloseImportDialog} />
            )}
            {importUsersSummary && (
                <ImportUsersResumePopup summaryImport={importUsersSummary} onClose={onCloseImportDialog} />
            )}
            {exportResult && exportResult.url && (
                <ExportPopup title={translate("providers." + (isProvider ? "download_providers" : "download_vendors")) as string} 
                    message={translate("providers." + (isProvider ? "providers_found" : "vendors_found"), { "total": exportResult.total }) as string} 
                    url={exportResult.url} 
                    onClose={onCloseDownloadProviders} />
            )}
            {exportResultUsers && exportResultUsers.url && (
                <ExportPopup title={translate("providers.download_providers") as string} 
                    message={translate("providers.providers_found", { "total": exportResultUsers.total }) as string} 
                    url={exportResultUsers.url} 
                    onClose={onCloseDownloadProvidersUsers} />
            )}
            {selectedProviders && (
                <ProviderClassificationsPopup
                    tenantId={context.session!.tenant!.id}
                    providers={selectedProviders}
                    classifications={data?.classifications}
                    onSuccess={onSuccessClassificationProvider}
                    onClose={onCloseClassification} />
            )}
            {gridAnchorEl && (
                <ProvidersManagementMenu anchor={gridAnchorEl}
                    totalItems={data?.total}
                    onClose={() => setGridAnchorEl(null)}
                    onImportProviders={openFileImportProviders}
                    onImportUsers={openFileImportUsers}
                    onExportWithParams={onExportWithParams}
                    onExportSelected={onExportSelected}
                    onExportUsersSelected={onExportUsersSelected}
                    onExportUsersWithParams={onExportUsersWithParams}
                    onDownloadTemplate={onDownloadTemplate}
                    onSortProvider={openClassificationProviders}
                    entityType={isProvider ? "PROVIDER" : "VENDOR"}
                />
            )}
            {exportMode && (
                <ConnectorObjectExporter tenantId={context.session!.tenant!.id} 
                    family={isProvider ? Family.PROVIDERS : Family.VENDORS}
                    type= {"DATA"}
                    onExport={downloadProvidersWithConnector} 
                    onClose={onCloseExporter}
                 />
            )}
            {showDeleteDialog &&
                <ConfirmationPopup title={translate("providers.delete_popup.title") as string}
                    message={translate("providers.delete_popup.message", { "provider": provider!.name }) as string}
                    button={translate("buttons.accept") as string}
                    onClose={cancelConfirmDialog}
                    doAction={onConfirmDeleteProvider} />
            }
            <CustomBackdrop open={uploading} message={translate("providers.importing") as string} />
        </Pagination>
    )

}