import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Typography, Grid, Button, Box, Card, CardActionArea, CardContent } from "@material-ui/core";
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { getCfdisPaymentOverdue } from "../api/ProviderCfdiApi";
import { ProviderCfdiOverdueResponse } from "../model/ProviderCfdi";
import PaymentsPendingSection from "../payment/PaymentsPendingSection";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import ErrorIcon from '@material-ui/icons/Error';

export default function PendingPaymentsListWidget() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [cfdiPaymentOverdue, setCfdiPaymentOverdue] = useState<ProviderCfdiOverdueResponse>({
        cfdi_payment_overdue: 0,
        max_payment_cfdi_overdue: 0
    } as ProviderCfdiOverdueResponse);

    const useStyles = makeStyles({
        root: {
            width: "95%",
        },
        numbers: {

        },
    });

    const classes = useStyles();

    useEffect(() => {
        setStatus("loading");
        getCfdisPaymentOverdue(context.session!.tenant!.id, context.session!.provider!.id).then((response) => {
            setCfdiPaymentOverdue(response);
            setStatus("loaded");
        }).catch((error) => {
            setCfdiPaymentOverdue({
                cfdi_payment_overdue: 0,
                max_payment_cfdi_overdue: 0
            } as ProviderCfdiOverdueResponse);
        });
    }, [context.session]);

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface
            title={translate("providers.pending_payments.title") as string}
            subtitle={translate("providers.pending_payments.subtitle") as string}
            icon={<TimelapseIcon />}>
            <Grid container direction="row-reverse" spacing={2}>
                <Grid item xs={12} lg={4}>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} lg={11} xl={9}>
                            <form autoComplete="off" noValidate>
                                <Grid container direction="row" alignItems="flex-start" alignContent="flex-start" justify="flex-end" spacing={1}>
                                    <Grid item xs={12} sm>
                                        <Card className={classes.root}>
                                            <CardActionArea disabled={true}>
                                                <CardContent>
                                                    <Typography style={cfdiPaymentOverdue.cfdi_payment_overdue > 0 ? { "color": "#C33149" } : { "color": "#2987e8" }} align="center" gutterBottom variant="h4" component="h2">
                                                        {cfdiPaymentOverdue.cfdi_payment_overdue}
                                                    </Typography>
                                                    <Typography align="center" variant="body2" color="textSecondary" component="p">
                                                        {translate("providers.pending_payments.cards.expired_payment") as string}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm>
                                        <Card className={classes.root} >
                                            <CardActionArea disabled={true}>
                                                <CardContent>
                                                    <Typography style={{ "color": "#2987e8" }} align="center" gutterBottom variant="h4" component="h2">
                                                        {cfdiPaymentOverdue.payments_with_pending_cfdi}
                                                    </Typography>
                                                    <Typography align="center" variant="body2" color="textSecondary" component="p">
                                                        {translate("providers.pending_payments.cards.pending_payment") as string}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Box pt={2}>
                                        <Button component={Link} to={`/payment/pending-cfdi`} style={{ "width": '98%', "marginBottom":"3%" }} type="submit" variant="contained" color="primary">
                                            {translate("buttons.payments_link")}
                                        </Button>
                                        {cfdiPaymentOverdue.cfdi_payment_overdue > 0 && cfdiPaymentOverdue.block ?
                                            <Grid container direction="row" alignItems="center" alignContent="center" spacing={1}>
                                                <Typography style={{ "color": "#C33149" }} align="left" variant="body2" color="textSecondary">
                                                    <ErrorIcon style={{ "color": "#C33149" }} />{translate("providers.pending_payments.with_expired_payments") as string}
                                                </Typography>
                                            </Grid>
                                            : undefined
                                        }
                                    </Box>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} sm={12} lg={8}>
                    <PaymentsPendingSection />
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Surface>
    );
}