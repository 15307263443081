import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Typography, Grid, Button, Box, Card, CardActionArea, CardContent } from "@material-ui/core";
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { getCfdisPendingDocOverdue } from "../api/ProviderSECfdiApi";
import { ProviderCfdiPendingDocOverdueResponse } from "../model/ProviderCfdi";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import ErrorIcon from '@material-ui/icons/Error';

export default function CfdisPendingDocWidget() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [cfdiPendingDocOverdue, setCfdiPendingDocOverdue] = useState<ProviderCfdiPendingDocOverdueResponse>({
        cfdi_pending_doc_overdue: 0,
        max_cfdi_pending_doc_overdue: 0
    } as ProviderCfdiPendingDocOverdueResponse);

    const useStyles = makeStyles({
        root: {
            width: "95%",
        },
        numbers: {

        },
    });

    const classes = useStyles();

    useEffect(() => {
        setStatus("loading");
        getCfdisPendingDocOverdue(context.session!.tenant!.id, context.session!.provider!.id).then((response) => {
            setCfdiPendingDocOverdue(response);
            setStatus("loaded");
        }).catch((error) => {
            setCfdiPendingDocOverdue({
                cfdi_pending_doc_overdue: 0,
                max_cfdi_pending_doc_overdue: 0
            } as ProviderCfdiPendingDocOverdueResponse);
        });
    }, [context.session]);

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface
            title={translate("providers.cfdi_pending_doc.title") as string}
            subtitle={translate("providers.cfdi_pending_doc.subtitle") as string}
            icon={<TimelapseIcon />}>
            <Grid container direction="row-reverse" spacing={2}>
                <Grid item xs={12} >
                    <Grid container justify="flex-start">
                        <Grid item xs={12}>
                            <form autoComplete="off" noValidate>
                                <Grid container direction="row" alignItems="flex-start" alignContent="flex-start" justify="flex-start" spacing={1}>
                                    <Grid item xs={6} sm>
                                        <Card className={classes.root}>
                                            <CardActionArea disabled={true}>
                                                <CardContent>
                                                    <Typography style={cfdiPendingDocOverdue.cfdi_pending_doc_overdue > 0 ? { "color": "#C33149" } : { "color": "#2987e8" }} align="center" gutterBottom variant="h4" component="h2">
                                                        {cfdiPendingDocOverdue.cfdi_pending_doc_overdue}
                                                    </Typography>
                                                    <Typography align="center" variant="body2" color="textSecondary" component="p">
                                                        {translate("providers.cfdi_pending_doc.cards.expired") as string}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} sm>
                                        <Card className={classes.root} >
                                            <CardActionArea disabled={true}>
                                                <CardContent>
                                                    <Typography style={{ "color": "#2987e8" }} align="center" gutterBottom variant="h4" component="h2">
                                                        {cfdiPendingDocOverdue.cfdis_with_pending_doc}
                                                    </Typography>
                                                    <Typography align="center" variant="body2" color="textSecondary" component="p">
                                                        {translate("providers.cfdi_pending_doc.cards.pending") as string}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Box py={2} pl={1}>
                                        <Grid container direction="row" alignItems="center" alignContent="center" spacing={2}>
                                            <Grid item xs={12} >
                                                <Button component={Link} to={`/cfdis/se/pending-docs`}  type="submit" variant="contained" color="primary">
                                                    {translate("providers.cfdi_pending_doc.button_link")}
                                                </Button>
                                            </Grid>
                                            {cfdiPendingDocOverdue.cfdi_pending_doc_overdue > 0 && cfdiPendingDocOverdue.block ?
                                                <Grid item xs={12}>
                                                    <Typography style={{ "color": "#C33149" }} align="left" variant="body2" color="textSecondary">
                                                        <ErrorIcon style={{ "color": "#C33149" }} />{translate("providers.cfdi_pending_doc.with_expired") as string}
                                                    </Typography>
                                                </Grid>
                                            : undefined }
                                        </Grid>
                                    </Box>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Surface>
    );
}