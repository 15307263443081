import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { TenantProvidersUsers, Users, EditTwoIcon, ConnectorIcon, SettingsIcon, WidgetIcon, PluginIcon, TenantModulesIcon, CompaniesIcon, SecurityIcon, IntegrationsIcon, PlaylistAdd } from "../components/Icons";
import translate from "../i18n/Translator";
import { Tenant } from "../model/Tenant";
import { AppContext } from "../context/AppContext";
import { LockOpenIcon } from "../components/Icons";
import BankAccountTypeIcon from '@material-ui/icons/AccountTree';

import PurchaseOrdersIcon from "@material-ui/icons/ShopTwoTwoTone";

interface TenantMenuProps {
    tenant: Tenant;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function TenantMenu(props: TenantMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("TenantsUpdate") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditTwoIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["UsersRead", "BillingsRead"]) &&
                <MenuItem button component={Link} to={`/tenants-admin/${props.tenant.id}/users`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <Users fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ActionsAdminRead") &&
                <MenuItem button component={Link} to={`/tenants-admin/${props.tenant.id}/actions-admin`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <LockOpenIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("roles_admin.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TechnicalSupportRead") &&
                <MenuItem button component={Link} to={`/tenants-admin/${props.tenant.id}/providers-users`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <TenantProvidersUsers fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.tenant_provider.title")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CompaniesRead"]) &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/companies`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <CompaniesIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("companies.title")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TenantBankAccountTypesRead","TenantBankAccountTypesList"]) &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/${props.tenant.name}/tenant-bank-account-types`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <BankAccountTypeIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("bank_account_type.tenant_bank_account_type.title")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CustomReportRead"]) && 
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/customreports`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <PlaylistAdd fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("custom_reports.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ConnectorsRead") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/connectors`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <ConnectorIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("connectors.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("WidgetsRead") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/widgets`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <WidgetIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("widgets.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantConfigurationsSAPUpdate") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/erp`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sap.settings.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("PluginsRead") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/plugins`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <PluginIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("plugins.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("IntegrationsRead") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/integrations`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <IntegrationsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("integrations.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantModulesRead") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/modules`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <TenantModulesIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("tenant_modules.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("SecurityConfigRead") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/security`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <SecurityIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("tenant_security.menu_option")}</Typography>
                </MenuItem>
            }
            {props.tenant.has_purchase_orders &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/purchase-orders-config`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <PurchaseOrdersIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("purchase_orders.title")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantsUpdate") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenant.id}/configurations`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("tenant_configurations.title")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}