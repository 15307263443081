import { callAPI, HTTPMethod } from "./API";
import { ReportCxP, ReportParams, ReportLatePaymentCfdis, ReportLatePaymentCfdisParams, ProviderTermConditionParams } from "../model/Reports";
import { ExportProviders, ProviderTermsConditions } from "../model/Provider";

const baseUrl = (tenantId: string): string => {
    return `/tenants/${tenantId}/reports`;
}

export async function getReportCxp(tenantId: string, params: ReportParams): Promise<ReportCxP> {
    return await callAPI(`${baseUrl(tenantId)}/report-cxp`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search
        }
    });
}

export async function exportReportCxp(tenantId: string): Promise<ExportProviders> {
    return await callAPI(`${baseUrl(tenantId)}/export-cxp`, {
        method: HTTPMethod.GET
    });
}

export async function getReportLatePaymentCfdis(tenantId: string, params: ReportLatePaymentCfdisParams): Promise<ReportLatePaymentCfdis> {
    let query = {
        name_provider: params.name_provider,
            rfc_provider: params.rfc_provider,
            external: params.external,
            no_late: params.no_late,
            amount: params.amount,
            currency: params.currency,
    } as Record<string, string>;
    
    return await callAPI(`${baseUrl(tenantId)}/report-late-payment-cfdis`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function exportReportLatePaymentCfdi(tenantId: string): Promise<ExportProviders> {
    return await callAPI(`${baseUrl(tenantId)}/export-late-payment-cfdi`, {
        method: HTTPMethod.GET
    });
}

export async function getReportTermsConditions(tenantId: string, pageSize: number, offset: number, params: ProviderTermConditionParams): Promise<ProviderTermsConditions> {
    let query = {
        providerName: params.provider_name || "",
        userName: params.user_name || "",
        pageSize: pageSize.toString(),
        offset: (offset+1).toString(),
    } as Record<string, string>;

    if(params.start_date && params.end_date){
        query = {...query,
            startDate: params.start_date+"",
            endDate: params.end_date+""
        };
    }
    
    return await callAPI(`${baseUrl(tenantId)}/terms-conditions-report`, {
        method: HTTPMethod.GET,
        query: query,
    });
}
