import React from "react";
import translate from "../i18n/Translator";
import Typography from "@material-ui/core/Typography";
import { Grid, Divider, Box } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { BudgetLine } from "../model/Budget";

interface BudgetLineLabelsProps {
    currentLine: BudgetLine;
    onExecute: boolean;

}

export default function BudgetLineLabels(props: BudgetLineLabelsProps) {
    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={4} >
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("budget.lines.edit.id") as string}
                    </Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography color="textSecondary" variant="caption">
                        {props.currentLine.external_id}
                    </Typography>
                </Grid>
                <Grid item xs={4} >
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("budget.lines.edit.type") as string}
                    </Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography color="textSecondary" variant="caption">
                        {props.currentLine.external_type}
                    </Typography>
                </Grid>
                <Grid item xs={4} >
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("budget.lines.edit.name") as string}
                    </Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography color="textSecondary" variant="caption">
                        {props.currentLine.description}
                    </Typography>
                </Grid>
                <Grid item xs={4} >
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("budget.lines.edit.ledge_account") as string}
                    </Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography color="textSecondary" variant="caption">
                        {props.currentLine.ledger_account}
                    </Typography>
                </Grid>
            </Grid>
            {props.onExecute &&
                <Grid container spacing={2} justify="flex-end">
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="textSecondary" variant="subtitle2" >
                            {translate("budget.lines.edit.current_amount") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography color="textSecondary" variant="body2" >
                            <NumberFormat value={props.currentLine.current_amount}
                                prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                thousandSeparator="," displayType="text"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("budget.lines.edit.accrued_amount") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography color="textSecondary" variant="body2" >
                            <NumberFormat value={props.currentLine.accrued_amount ?? 0}
                                prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("budget.lines.edit.max_reduction") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Typography color="textSecondary" variant="body2" >
                            <NumberFormat value={props.currentLine.current_amount - (props.currentLine.accrued_amount ?? 0)}
                                prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Box minHeight={40} >  </Box>
        </>
    );
}