import { callAPI, HTTPMethod } from "./API";
import { Refund, RefundRequest, Refunds, RefundsQueryFilter, TransitionRequest } from "../model/Refund";
import { AdditionalFieldRequest } from "../model/AdditionalInfoWorkflove";
import { ExportResponse } from '../model/Connector';

const getBaseUrl = (tenantId: string) => {
    return `/tenants/${tenantId}/refunds`;
};

export async function listRefunds(tenantId: string, page: number, offset: number, params: RefundsQueryFilter): Promise<Refunds> {
    let query = {
        noDev: params.no_dev || "",
        description: params.description || "",
        vendor: params.vendor || "",
        requester: params.requester || "",
        total: params.total || "",
        paid: params.paid || "",
        balance: params.balance || "",
        currency: params.currency || "",
        status: params.status || "",
        statusGroup: params.status_group || "",
        pageSize: page.toString(),
        offset: offset.toString(),
        order: params?.order 
    } as Record<string, string>;

    if (params.credit_balance_init && params.credit_balance_end) {
        query = {
            ...query,
            creditBalanceInit: params.credit_balance_init,
            creditBalanceEnd: params.credit_balance_end,
        };
    }

    if (params.reception_date_init && params.reception_date_end) {
        query = {
            ...query,
            receptionDateInit: params.reception_date_init,
            receptionDateEnd: params.reception_date_end,
        };
    }

    return await callAPI(getBaseUrl(tenantId), {
        method: HTTPMethod.GET,
        query: query
    });
}

export async function create(tenantId: string, request: RefundRequest): Promise<Refund> {
    return await callAPI(getBaseUrl(tenantId), {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function update(tenantId: string, refundId: string, request: RefundRequest): Promise<Refund> {
    return await callAPI(`${getBaseUrl(tenantId)}/${refundId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteRefund(tenantId: string, refundId: string): Promise<void> {
    return await callAPI(`${getBaseUrl(tenantId)}/${refundId}/delete`, {
        method: HTTPMethod.DELETE
    });
}

export async function forwardRefund(tenantId: string, refundId: string, request: TransitionRequest): Promise<Refund> {
    return await callAPI(`${getBaseUrl(tenantId)}/${refundId}/forward`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function backwardRefund(tenantId: string, refundId: string, request: TransitionRequest): Promise<Refund> {
    return await callAPI(`${getBaseUrl(tenantId)}/${refundId}/backward`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function cancelRefund(tenantId: string, refundId: string, request: TransitionRequest): Promise<void> {
    return await callAPI(`${getBaseUrl(tenantId)}/${refundId}/cancel`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateAdditionalField(tenantId: string, refundId: string, request: AdditionalFieldRequest): Promise<Refund> {
    return await callAPI(`${getBaseUrl(tenantId)}/${refundId}/additional-field`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function exportRefunds(tenantId: string, page: number, offset: number, params: RefundsQueryFilter, connectorId: string): Promise<ExportResponse> {
    let query = {};
    if (params?.ids) {
        query = {
            ids: params.ids,
            connectorId: connectorId
        };
    } else {
        query = {
            noDev: params.no_dev || "",
            vendor: params.vendor || "",
            requester: params.requester || "",
            total: params.total || "",
            paid: params.paid || "",
            balance: params.balance || "",
            currency: params.currency || "",
            status: params.status || "",
            statusGroup: params.status_group || "",
            pageSize: page.toString(),
            offset: offset.toString(),
            connectorId: connectorId
        } as Record<string, string>;
    }

    if (params.credit_balance_init && params.credit_balance_end) {
        query = {
            ...query,
            creditBalanceInit: params.credit_balance_init,
            creditBalanceEnd: params.credit_balance_end,
        };
    }

    if (params.reception_date_init && params.reception_date_end) {
        query = {
            ...query,
            receptionDateInit: params.reception_date_init,
            receptionDateEnd: params.reception_date_end,
        };
    }

    return await callAPI(getBaseUrl(tenantId) + '/export-refunds', {
        method: HTTPMethod.GET,
        query
    });
}