import React, { useEffect, useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import UserBasicProfileForm from "./UserBasicProfileForm";
import UserEmailForm from "./UserEmailForm";
import { me } from "../api/MeAPI";
import { User } from "../model/User";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";
import Error from "../components/Error";

export default function UserProfile() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<"loading" | "loaded" | any>("loading");
    const [user, setUser] = useState<User>();

    useEffect(() => {
        setStatus("loading");
        me().then((user) => {
            if (context.session) {
                context.session!.user = user;
            }
            setUser(user);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, [context.session])

    const onUpdated = (user: User) => {
        if (context.session) {
            context.session!.user = user;
        }
        setUser(user);
    };

    if (status === "loading") {
        return <Progress />
    } else if (status === "loaded") {
        return (
            <Grid container alignItems="center" justify="center" alignContent="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("users.profile.basic") as string} icon={<AccountCircleIcon />} backTo="/" backButton>
                        <Grid container alignItems="center" justify="center" alignContent="center">
                            <Grid item xs={12}>
                                <UserBasicProfileForm user={user!} onUpdated={onUpdated} />
                            </Grid>
                        </Grid>
                    </Surface>
                    <br></br>
                    <br></br>
                    <Surface title={translate("users.profile.email") as string} icon={<EmailIcon />}>
                        <Grid container alignItems="center" justify="center" alignContent="center">
                            <Grid item xs={12}>
                                <UserEmailForm user={user!} onUpdated={onUpdated} />
                            </Grid>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
        );
    } else {
        return <Error text={status} />
    }

}