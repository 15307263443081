
export interface Announcement {
    id: string,
    tenant_id: string;
    start_date: Date;
    end_date: Date;
    status: string;
    title: string;
    body: string;
    all_providers: boolean;
    find_providers_type: string;
    providers: string[];
    provider_classification_ids: string[];
    readed: boolean;
}

export interface AnnouncementRequest {
    start_date: Date;
    end_date: Date;
    status: string;
    title: string;
    body: string;
    all_providers: boolean;
    provider_ids: string[];
    classification_ids: string[];
    providers_find_type: string;
}

export interface AnnouncementQueryParams {
    start_date?: string;
    end_date?: string;
    status?: string;
    title?: string;
    readed?: boolean;
}

export interface Announcements {
    items: Announcement[];
    total: number;
}

export interface ChangeStatusRequest {
    status: string;
}

export let STATUSES = [ 'CREATED', 'ACTIVE', 'EXPIRED' ];