import React, { useState, useEffect, useCallback } from "react";
import { Divider, Button, Checkbox, FormControl, FormGroup, FormControlLabel, Grid, Typography } from "@material-ui/core";
import ValidatedInput from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar } from "../components/Snackbars";
import { Entity as Provider } from "../model/Provider";
import { User, UsersQueryParamsReceptors } from "../model/User";
import { getUsers } from "../api/TenantUserAPI";

import translate from "../i18n/Translator";
import { updateUsersReceptor, updateUsersSecondAuthorizator } from "../api/ProviderAPI";
import { ProviderUpdateClassificationsRequest } from "../model/Provider";
import Progress from "../components/Progress";

interface ProviderReceptorsPopupProps {
    tenantId: string;
    providers: Provider[];
    isSecondAuthorizator: boolean;
    onSuccess(update: number): any;
    onClose(): any;
}

export default function ProviderReceptorsPopup(props: ProviderReceptorsPopupProps) {
    const [error, setError] = useState<string>();
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [newUser, setNewUser] = useState<string>();
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState<any>({} as any);
    const [request, setRequest] = useState<ProviderUpdateClassificationsRequest>({} as ProviderUpdateClassificationsRequest);
    const [users, setUsers] = useState<User[]>([]);
    const [usersInput, setUsersInput] = useState<User[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [userNames, setUserNames] = useState<string[]>([]);

    const getUsersFromProvider = useCallback((provider : Provider) => {
        if(props.isSecondAuthorizator){
            return provider.second_authorizator_users;
        }
        return provider.receptor_users;
    }, [props.isSecondAuthorizator])

    useEffect(() => {
        setStatus("loading");

        let tmp = {} as any;
        let userNamesTemp = [] as User[];
        props.providers.filter(provider => getUsersFromProvider(provider) !== undefined ).forEach((provider) => {
            getUsersFromProvider(provider)!.forEach((user) => {
                if (!tmp[user.id]) {
                    tmp[user.id] = [];
                }
                (tmp[user.id] as string[]).push(provider.id);
    
                userNamesTemp.push(user);
            });
        });

        setUsers(userNamesTemp);
        setData(tmp);
        setRequest({
            append: [],
            remove: [],
            providers: props.providers.map(provider => provider.id)
        } as ProviderUpdateClassificationsRequest);
        
        let roleList = ["receptor_cfdi", "cxp"].join(",");
        getUsers(props.tenantId, 0, 0, {
            role_list: roleList,
            search: ""
        } as UsersQueryParamsReceptors).then((response) => {
            let items = response.items.filter(user => {
                let users =  props.providers.flatMap(provider => props.isSecondAuthorizator ? provider.receptor_users : provider.second_authorizator_users);
                let index = users.findIndex(receptor => receptor && user.id === receptor.id);
                if(index >= 0){
                    return false;
                }
                return true;
            })

            setUsersInput(items);
            setUserIds(items.map((user) => user.id));
            setUserNames(items.map((user) => user.name));
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [props.providers, props.tenantId, props.isSecondAuthorizator, getUsersFromProvider]);

    const onChanged = (idUser: string, checked: boolean) => {
        if (checked) {
            request.append.push(idUser);

            let i = request.remove.findIndex(item => item === idUser);
            if (i >= 0) {
                request.remove.splice(i, 1);
            }

            (data as any)[idUser] = request.providers;
        } else {
            request.remove.push(idUser);
            let i = request.append.findIndex(item => item === idUser);
            if (i >= 0) {
                request.append.splice(i, 1);
            }
            (data as any)[idUser] = [];
        }

        setRequest(request);
        setData(data);
        setDisabled(false);
    };

    const addUser = () => {
        let usersTemp = users;
        if (!newUser || newUser === "" || newUser === "---" ) return;
        if (!data[newUser]) {
            data[newUser] = [];
            onChanged(newUser, true);
            setDisabled(false);
            let user = usersInput.find(user => user.id === newUser);
            usersTemp.push({id: newUser, name: user ? user.name : ""} as User)
            setUsers(usersTemp);
        };
        setNewUser("---");
    };

    const promiseAddUsers = () => {
        if(props.isSecondAuthorizator){
            return updateUsersSecondAuthorizator(props.tenantId, request);
        } else {
            return updateUsersReceptor(props.tenantId, request);
        }
    };

    const onAddUsers = () => {
        promiseAddUsers().then((response) => {
            props.onSuccess(response.updated);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const hasChanged = (name: string, value: string) => {
        setNewUser(value);
    };

    return (
        <DialogPopup open
            title={translate(props.isSecondAuthorizator ? "providers.second_authorizator.title" : "providers.receptor.title")}
            onClose={props.onClose}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            button={(
                <Button onClick={onAddUsers} variant="contained" color="primary" size="medium" disabled={disabled || submitting}>
                    {translate("buttons.confirm")}
                </Button>
            )}>

            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && userIds.length > 0 && (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            <ValidatedInput
                                type="text" id="newClassification" name="newClassification"
                                label={translate(props.isSecondAuthorizator ? "providers.second_authorizator.new" : "providers.receptor.new") as string}
                                value={newUser}
                                required={false} disabled={submitting}
                                margin="dense"
                                options={userIds}
                                optionLabels={userNames}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button size="small" variant="text" color="primary" onClick={addUser}>
                                {translate("buttons.add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {Object.keys(data).length > 0 && (
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {Object.keys(data).map((idUser: any) => (
                                    <ReceptorCheck key={idUser}
                                        idUser={idUser}
                                        users={users}
                                        selected={data[idUser] as string[]}
                                        all={props.providers}
                                        onChange={onChanged} />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
                {Object.keys(data).length > 0 && (
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                )}
            </Grid>
            )}
            {status === "loaded" && userIds.length === 0 && (
                <Typography variant="body2">
                    {translate(props.isSecondAuthorizator ? "providers.second_authorizator.list_empty" : "providers.receptor.list_empty")}
                </Typography>
            )}
            {status !== "loading" && status !== "loaded" && (
                <Typography variant="body2" component="h5" color="error" align="center">
                    {status}
                </Typography>
            )}
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}

interface ReceptorCheckProps {
    idUser: string;
    users: User[];
    selected: string[];
    all: any[];
    onChange(idUser: string, checked: boolean): any;
}

function ReceptorCheck(props: ReceptorCheckProps) {
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    useEffect(() => {
        setChecked(props.selected.length !== 0 && props.selected.length === props.all.length);
        setIndeterminate(props.selected.length !== 0 && props.selected.length !== props.all.length);
    }, [props.idUser, props.selected, props.all]);

    const handleSelection = (event: React.ChangeEvent<{}>, checked: boolean) => {
        props.onChange(props.idUser, checked);
        setChecked(checked);
        setIndeterminate(false);
    };

    const nameUser = () => {
        if(props.users === undefined) return "";
        let user = props.users.find(user => ""+user.id+"" === props.idUser);
        return user ? user.name : "";
    };

    return (
        <FormControlLabel
            control={(
                <Checkbox
                    id={props.idUser}
                    name={props.idUser}
                    value={props.idUser}
                    onChange={handleSelection}
                    indeterminate={indeterminate}
                    checked={checked} />
            )}
            label={nameUser()}
        />
    );
}