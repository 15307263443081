import React from "react";
import { Avatar, Card, CardHeader, Chip, Grid } from "@material-ui/core";
import { VerificationListProps, InternVerificationList } from "../providers/AllProvidersVerificationPopup";
import { WithoutExpedienteIcon, EditSingleIcon, InReviewIcon, CheckIcon, RejectedIcon, ExpiredIcon, ReadyIcon, UndefinedIcon } from '../components/Icons'

import translate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat";

export const COLORS = {
    "ACCEPTED": "#339900",
    "REJECTED": "#dc3545",
    "SENT": "#007bff",
    "READY": "#83A6ED",
    "TO_SEND": "#aaaaaa",
    "MISSING": "#aaaaaa",
    "NO_EXPEDIENT": "#6c757d",
    "EXPIRED": "#F44336",
    "EDITED": "#343a40",
} as any;

export function getColor(status: string) {
    return COLORS[status] as string;
}

interface ProviderExpedientIconProps {
    status: string;
    size?: "default" | "inherit" | "large" | "small";
    color?: boolean;
}

export function ProviderExpedientIcon(props: ProviderExpedientIconProps) {
    switch (props.status) {
        case "ACCEPTED":
            return (<CheckIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "REJECTED":
            return (<RejectedIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "SENT":
            return (<InReviewIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "READY":
            return (<ReadyIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "NO_EXPEDIENT":
            return (<WithoutExpedienteIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "EXPIRED":
            return (<ExpiredIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "EDITED":
            return (<EditSingleIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
        case "TO_SEND":
        default:
            return (<UndefinedIcon fontSize={props.size} style={props.color ? { "color": getColor(props.status) } : undefined} />);
    }
}

export function ProviderExpedientAvatar(props: ProviderExpedientIconProps) {
    return (
        <Avatar style={{ "backgroundColor": getColor(props.status) }}>
            <ProviderExpedientIcon {...props} />
        </Avatar>
    );
}

interface ProviderExpedientStatusHeaderProps extends ProviderExpedientIconProps {
    approved?: Date;
    lastReview?: Date;
    short?: boolean;
    button?: React.ReactNode;
    expired_at?: Date;
    dashboard?: boolean;
    expired?: boolean;
}

export function ProviderExpedientStatusHeader(props: ProviderExpedientStatusHeaderProps) {

    const getTitle = () => {
        let label = props.short ? "status_metadata_short" : "status_metadata";
        let statusLabel = props.expired ? "EXPIRED" : props.status;
        return translate(`expedients.${label}.${statusLabel}`);
    }
    const getSubheader = () => {
        if (props.dashboard && props.expired) {
            return translate(`expedients.description_metadata.EXPIRED`);
        } else if (!props.dashboard && props.expired) {
            return (props.expired_at ? formatDate(props.expired_at, "ll") : "");
        } else {
            return (formatDate(props.lastReview, "lll"));
        }
    }
    return (
        <Card elevation={2}>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <CardHeader
                        avatar={
                            <ProviderExpedientAvatar status={props.expired ? "EXPIRED" :props.status} />
                        }
                        title={getTitle()}
                        subheader={<>
                            {props.expired ?
                                <span style={{ color: getColor("EXPIRED") }} >{getSubheader()}</span> : <span>{getSubheader()}</span>
                            }
                        </>}
                        action={props.button}>
                    </CardHeader>
                </Grid>
            </Grid>
        </Card >
    );
}

export function ProviderExpedientStatusChip(props: ProviderExpedientIconProps) {
    return (
        <Chip size="small" variant="default" color="primary"
            label={translate(`expedients.status_metadata.${props.status}`) as string}
            icon={
                <ProviderExpedientIcon {...props} size="small" />
            }
            style={{ "backgroundColor": getColor(props.status) }}
        />
    );
}

export function ExpedientStatusList(props: VerificationListProps) {
    let values = {
        "ACCEPTED": props.values["ACCEPTED"] || 0,
        "REJECTED": props.values["REJECTED"] || 0,
        "SENT": props.values["SENT"] || 0,
        "NO_EXPEDIENT": props.values["NO_EXPEDIENT"] || 0,
        "EXPIRED": props.values["EXPIRED"] || 0,
        "EDITED": props.values["EDITED"] || 0
    } as any;

    return (
        <InternVerificationList {...props} headerText={translate("expedients.title") as string}
            text={(status) => translate(`expedients.status_metadata_short.${status}`) as string}
            icon={(status) => <ProviderExpedientIcon status={status} color />}
            values={values} />
    );
}