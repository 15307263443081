import React from "react";
import { TenantFieldCatalog, TenantContact } from "../model/TenantFieldCatalog";
import { updateCatalogContacts } from "../api/TenantFieldAPI";
import ExpedientSettingsSegments from "./ExpedientSettingsSegments";
import { ExpedientTemplate } from "../model/ExpedientTemplate";

interface ExpedientSettingsContactsProps {
    expedient?: TenantFieldCatalog;
    template?: ExpedientTemplate;
    view: "templates" | "fields";
    onAdd?(): any;
    onDelete?(id: string): any;
}

export default function ExpedientSettingsContacts(props: ExpedientSettingsContactsProps) {
    return (
        <ExpedientSettingsSegments
            view={props.view}
            expedient={props.expedient}
            segments={props.template ? props.template.contacts_response : undefined}
            onAdd={props.onAdd}
            onDelete={props.onDelete}
            name="contacts"
            supplier={(expedient) => expedient.contacts || []}
            onUpdate={(tenantId: string, request: TenantContact) => updateCatalogContacts(tenantId, request)}
        />
    );
}
