import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { getTenantCfdis, updateCfdisReceptor, exportCfdisExcel, deleteCfdi, cancelCfdi, validateReceptionDate, exportCfdisProratedExcel, getActorFieldsTypeCatalogByStage, exportValidationsPdfCfdis } from "../api/TenantCfdiApi";
import { Grid, Divider, Button, Fab, IconButton } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Cfdi, Cfdis, CfdisQueryParams, ReceptionDateValidationResponse, ActorStageFieldsResponse, FilterField, TotalAuthorizeAmountResponse, StatusGroup_IN_PROCESS } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";
import translate, { translateParams } from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import ToAuthorizeIcon from '@material-ui/icons/Gavel';
import TenantCfdiMenu from "./menus/TenantCfdiMenu";
import CfdisToAuthorizedMenu from "./menus/TenantCfdisToAuthorizedMenu";
import { exportCfdisSelected, exportAllCfdis, exportCfdiFiles, approve, approveListed, getTotalAuthorizedCfdis } from "../api/TenantCfdiApi";
import { getCountProvidersByTypes } from "../api/ProviderAPI";
import { TenantCfdisRequest, ExportCfdisResponse, ChangeReceptorCfdisRequest, DeleteCfdiRequest, WorkflowRoutingRequest, WorkflowApproveResponse, WorkflowPayableDocumentResponse, getTotalToAuthorize } from "../model/TenantCfdi";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import { CfdisListProps } from "../cfdi/CfdisList";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { getUsers } from "../api/TenantUserAPI";
import { UsersQueryParamsReceptors } from "../model/User";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CfdiChangeReceptorPopUp from "../cfdi/CfdiChangeReceptorPopUp";
import { AutocompleteProvider, ProvidersByTypeResponse } from "../model/Provider";
import NewPayableDocumentMenu from "../payable_documents/NewPayableDocumentMenu";
import NewForeignPopup from "../foreigns/NewForeignPopup";
import NewForeignCreditNotePop from "../foreigns/NewForeignCreditNotePopup";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import ReactHtmlParser from "html-react-parser";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString, concatDates } from "../components/DateFormat";
import { isRoleOrParent } from "../model/Role";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";
import DownloadRange from "../components/DownloadRange";
import FilterPopup from "../components/FilterPopup";
import DeleteCfdiPopup from "../cfdis/popups/DeleteCfdiPopup";
import ShowResultsBulkAuthModal from "./popups/ShowResultsBulkAuthModal";
import { get as getTenantConfiguration } from "../api/TenantConfigurationApi";
import AdvancePaymentPopup from "../advancePayments/AdvancePaymentPopup";

export default function ListCfdisToAuthorize(props: CfdisListProps) {
    const STAGE_IN_PROCESS = StatusGroup_IN_PROCESS;
    const context = useContext(AppContext);
    const history = useHistory();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [openConfirmDialogDownloadAllCfdi, setOpenConfirmDialogDownloadAllCfdi] = useState<boolean>(false);
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const qs = queryString.parse(window.location.search);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status_group": typeof qs["status_group"] === "string" ? qs["status_group"] as string : StatusGroup_IN_PROCESS,
            "selected_ids": typeof qs["selected_ids"] === "string" ? qs["selected_ids"] as string : "",
            "receptor": typeof qs["receptor"] === "string" ? qs["receptor"] as string : "",
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "issuer": typeof qs["issuer"] === "string" ? qs["issuer"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "date_start": typeof qs["date_start"] === "string" ? qs["date_start"] as string : "",
            "date_end": typeof qs["date_end"] === "string" ? qs["date_end"] as string : "",
            "send_at_start": typeof qs["send_at_start"] === "string" ? qs["send_at_start"] as string : "",
            "send_at_end": typeof qs["send_at_end"] === "string" ? qs["send_at_end"] as string : "",
            "type_filter": typeof qs["type_filter"] === "string" ? qs["type_filter"] as string : "",
            "payment_deadline_start": typeof qs["payment_deadline_start"] === "string" ? qs["payment_deadline_start"] as string : "",
            "payment_deadline_end": typeof qs["payment_deadline_end"] === "string" ? qs["payment_deadline_end"] as string : "",
            "authorized_at_start": typeof qs["authorized_at_start"] === "string" ? qs["authorized_at_start"] as string : "",
            "authorized_at_end": typeof qs["authorized_at_end"] === "string" ? qs["authorized_at_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
            "referer_view": "TO_AUTHORIZE",
            "type_list": typeof qs["type_list"] === "string" ? qs["type_list"] as string : "",
            "filter_fields": typeof qs["filter_fields"] === "string" && qs["filter_fields"] ? JSON.parse(qs["filter_fields"] as string) : undefined,
        } as CfdisQueryParams;
    };
    const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [selectedCfdis, setSelectedCfdis] = useState<(number | string)[]>([]);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [receptorIds, setReceptorIds] = useState<string[]>([]);
    const [receptorNames, setReceptorNames] = useState<string[]>([]);
    const [openChangeReceptor, setOpenChangeReceptor] = useState<boolean>(false);
    const [receptorsAutocomplete, setReceptorsAutocomplete] = useState<AutocompleteProvider[]>([]);
    const isAdmin = isRoleOrParent(context.session!.role, "owner") || isRoleOrParent(context.session!.role, "supervisor");
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);
    const [openConfirmDialogDelete, setOpenConfirmDialogDelete] = useState<boolean>(false);
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [receptionDateValidation, setReceptionDateValidation] = useState<ReceptionDateValidationResponse | undefined>(undefined);
    const [url, setUrl] = useState<string>();
    const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
    const [foreingPop, setForeingPop] = useState<boolean>(false);
    const [openForeingCreditNote, setOpenForeingCreditNote] = useState<boolean>(false);
    const [advancePaymentsModal, setAdvancePaymentsModal] = useState<boolean>(false);
    const [counterTypeProviders, setCounterTypeProviders] = useState<ProvidersByTypeResponse>();
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openExportExcelSelected, setOpenExportExcelSelected] = useState<boolean>(false);
    const [openExportProratedExcel, setOpenExportProratedExcel] = useState<boolean>(false);
    const [openDownloadValidationsPdf, setOpenDownloadValidationsPdf] = useState<boolean>(false);
    const [openConfirmAutorize, setOpenConfirmAuthorize] = useState<boolean>(false);
    const [authroizeSelected, setAuthorizeSelected] = useState<boolean>(false);
    const [totalAmountToAuthorize, setTotalAmountToAuthorize] = useState<string>("");
    const [totalToAuthorize, setTotalToAuthorize] = useState<number>(0);
    const [openResultsBulkAuth, setOpenResultsBulkAuth] = useState<boolean>(false);

    const [cfdisFailed, setCfdisFailed] = useState<WorkflowPayableDocumentResponse[]>([]);
    const [totalAuthorized, setTotalAuthorized] = useState<string>("");
    const [totalFailed, setTotalFailed] = useState<string>("");
    const [authorized, setAuthorized] = useState<number>(0);
    const [failed, setFailed] = useState<number>(0);

    const [fieldsCatalogType, setFieldsCatalogType] = useState<ActorStageFieldsResponse>();
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [hasAdvancePayments, setHasAdvancePayments] = useState<boolean>(false);
    const [allowBulkAuthorize, setAllowBulkAuthorize] = useState<boolean>(false);

    const paramsToFilters = () => {
        return [
            { columnName: 'identifier', value: params.identifier },
            { columnName: 'issuer', value: params.issuer },
            { columnName: 'description', value: params.description },
            { columnName: 'date', value: concatDates(params.date_start, params.date_end) },
            { columnName: 'send_at', value: concatDates(params.send_at_start, params.send_at_end) },
            { columnName: 'type', value: params.type_filter },
            { columnName: 'payment_deadline', value: concatDates(params.payment_deadline_start, params.payment_deadline_end) },
            { columnName: 'authorized_at', value: concatDates(params.authorized_at_start, params.authorized_at_end) },
            { columnName: 'total', value: params.total },
            { columnName: 'balance', value: params.balance },
            { columnName: 'currency', value: params.currency },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        pushHistory();
        if (props.success && isSuccess) {
            setSuccess(props.success);
            setIsSuccess(false);
        }
        if (context.isGranted("ReceptorCFDIsRead")) {
            getTenantCfdis(context.session!.tenant!.id, context.session!.user.id, pageSize, pageSize * page, params).then((response) => {
                setStatus("loaded");
                fillReceptors();
                setData(response);
                setSelectedCfdis([]);
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            getTenantCfdis(context.session!.tenant!.id, isAdmin && !params.receptor ? "" : params.receptor!, pageSize, pageSize * page, params).then((response) => {
                setStatus("loaded");
                setData(response);
                setSelectedCfdis([]);
                if (context.isGranted("AdminCFDIsRead")) {
                    fillReceptors();
                }
            }).catch((error) => {
                setStatus(error.message);
            });
        }
        getCountProvidersByTypes(context.session!.tenant!.id).then((response) => {
            setCounterTypeProviders(response);
        }).catch((error) => {
            setError(error.message);
        });

        getTenantConfiguration(context.session!.tenant!.id).then((response) => {
            setHasAdvancePayments(response.has_advance_payment);
            let allowBulkAuthorize = response.configurations.find(config => config.property_name === 'allow_bulk_authorizations');
            setAllowBulkAuthorize(allowBulkAuthorize ? allowBulkAuthorize.value === 'true' : false);
        }).catch((error) => {
            setError(error.message);
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const fillReceptors = () => {
        getUsers(context.session!.tenant!.id, 0, 0, {
            role_list: ["receptor_cfdi", "cxp"].join(","),
            search: ""
        } as UsersQueryParamsReceptors).then((response) => {
            let listTemp = [] as AutocompleteProvider[];
            response.items.forEach((receptor) => {
                let temp = { title: receptor.name, value: receptor.id } as AutocompleteProvider;
                listTemp.push(temp);
            });
            setReceptorsAutocomplete(listTemp);
            let receptorsIds = response.items.map((user) => user.id);
            setReceptorIds(receptorsIds);
            let receptorsNames = response.items.map((user) => user.name);
            setReceptorNames(receptorsNames);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.identifier = filter.value;
                        break;
                    case "issuer":
                        temp.issuer = filter.value;
                        break;
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    case "date":
                        let dates = filter.value.split(" - ");
                        if (dates.length === 2) {
                            temp.date_start = formatDateString(dates[0]);
                            temp.date_end = formatDateString(dates[1]);
                        } else {
                            temp.date_start = "";
                            temp.date_end = "";
                        }
                        break;
                    case "send_at":
                        let datesSend = filter.value.split(" - ");
                        if (datesSend.length === 2) {
                            temp.send_at_start = formatDateString(datesSend[0]);
                            temp.send_at_end = formatDateString(datesSend[1]);
                        } else {
                            temp.send_at_start = "";
                            temp.send_at_end = "";
                        }
                        break;
                    case "type":
                        temp.type_filter = filter.value;
                        temp.type_list = "";
                        break;
                    case "payment_deadline":
                        let paymentDeadlines = filter.value.split(" - ");
                        if (paymentDeadlines.length === 2) {
                            temp.payment_deadline_start = formatDateString(paymentDeadlines[0]);
                            temp.payment_deadline_end = formatDateString(paymentDeadlines[1]);
                        } else {
                            temp.payment_deadline_start = "";
                            temp.payment_deadline_end = "";
                        }
                        break;
                    case "authorized_at":
                        let authorizedAtDates = filter.value.split(" - ");
                        if (authorizedAtDates.length === 2) {
                            temp.authorized_at_start = formatDateString(authorizedAtDates[0]);
                            temp.authorized_at_end = formatDateString(authorizedAtDates[1]);
                        } else {
                            temp.authorized_at_start = "";
                            temp.authorized_at_end = "";
                        }
                        break;
                    case "total":
                        temp.total = filter.value;
                        break;
                    case "balance":
                        temp.balance = filter.value;
                        break;
                    case "currency":
                        temp.currency = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status_group"] = workingParams.status_group;
        qs["selected_ids"] = workingParams.selected_ids ? workingParams.selected_ids : "";
        qs["receptor"] = workingParams.receptor ? workingParams.receptor : "";
        qs["identifier"] = workingParams.identifier ? workingParams.identifier : "";
        qs["issuer"] = workingParams.issuer ? workingParams.issuer : "";
        qs["description"] = workingParams.description ? workingParams.description : "";
        qs["date_start"] = workingParams.date_start ? workingParams.date_start : "";
        qs["date_end"] = workingParams.date_end ? workingParams.date_end : "";
        qs["send_at_start"] = workingParams.send_at_start ? workingParams.send_at_start : "";
        qs["send_at_end"] = workingParams.send_at_end ? workingParams.send_at_end : "";
        qs["type_filter"] = workingParams.type_filter ? workingParams.type_filter : "";
        qs["type_list"] = workingParams.type_list ? workingParams.type_list : "";
        qs["payment_deadline_start"] = workingParams.payment_deadline_start ? workingParams.payment_deadline_start : "";
        qs["payment_deadline_end"] = workingParams.payment_deadline_end ? workingParams.payment_deadline_end : "";
        qs["authorized_at_start"] = workingParams.authorized_at_start ? workingParams.authorized_at_start : "";
        qs["authorized_at_end"] = workingParams.authorized_at_end ? workingParams.authorized_at_end : "";
        qs["total"] = workingParams.total ? workingParams.total : "";
        qs["balance"] = workingParams.balance ? workingParams.balance : "";
        qs["currency"] = workingParams.currency ? workingParams.currency : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";
        qs["filter_fields"] = workingParams.filter_fields ? JSON.stringify(workingParams.filter_fields) : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onAppliedFilter = () => {
        pushHistory();
        if (selectedCfdis.length > 0) {
            setSelectedCfdis([]);
        }
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onClickFilter = () => {
        getActorFieldsTypeCatalogByStage(context.session!.tenant!.id, STAGE_IN_PROCESS).then((response) => {
            setFieldsCatalogType(response);
            if (response && response.fields && response.fields.length > 0) {
                setOpenFilterDialog(true);
            } else {
                setWarning(translate("cfdis.popup.filter_fields.not_filters_found") as string);
            }
        }).catch((error) => {
            setError(error.message);
        });
    }

    const onClickedClean = () => {
        const params = { ...workingParams, filter_fields: undefined, search: "" }
        setWorkingParams(params);
        setParams(params);
    }

    const onCloseOption = () => {
        setAnchorEl(null);
        setGridAnchorEl(null);
        setCfdi(undefined);
        setOpenChangeReceptor(false);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onchangeReceptor = (newReceptorId: string) => {
        if ((selectedCfdis && selectedCfdis.length > 0) || (cfdi && cfdi!.id)) {
            updateCfdisReceptor(context.session!.tenant!.id, { cfdi_ids: cfdi && cfdi!.id ? [cfdi!.id] as string[] : selectedCfdis, new_receptor_id: newReceptorId } as ChangeReceptorCfdisRequest).then((response) => {
                load();
                if (cfdi && cfdi!.id)
                    setSuccess(translate("cfdis.change_receptor_cfdi.success_single", { "receptor": response.new_receptor_name }) as string);
                else
                    setSuccess(translate("cfdis.change_receptor_cfdi.success_many", { "total": response.total_sent, "receptor": response.new_receptor_name }) as string);
            }).catch((error) => {
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
        setGridAnchorEl(null);
        setOpenChangeReceptor(false);
    }

    const onOpenChangeReceptor = () => {
        if ((selectedCfdis && selectedCfdis.length > 0) || (cfdi && cfdi!.id)) {
            if (cfdi && cfdi!.id && cfdi.metadata.receptors_names) {
                setReceptorsAutocomplete(receptorsAutocomplete.filter(value => value.title !== cfdi.metadata.receptors_names));
            }
            setOpenChangeReceptor(true);
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
        setGridAnchorEl(null);
        setAnchorEl(null);
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const downloadCfdisSelected = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selectedCfdis } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.url) {
                    window.open(response.url, "_blank")
                } else {
                    if (response.total > 0) {
                        setSuccess(translate("cfdis.email_export") as string);
                    } else {
                        setWarning(translate("cfdis.empty_export") as string);
                    }
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const openConfirmAuthorizeCfdisSelected = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setTotalToAuthorize(selectedCfdis.length);
            setTotalAmountToAuthorize(getTotalToAuthorize(data?.items
                .filter(item => selectedCfdis.findIndex(selected => item.id === selected) >= 0) || []));
            setAuthorizeSelected(true);
            setOpenConfirmAuthorize(true);
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const getTotalToAuthorizeListed = (response: TotalAuthorizeAmountResponse) => {
        let amountsByCurrencyStr = response.items.map(amountByCurrency => {
            return `$ ${amountByCurrency.total_amount} ${amountByCurrency.currency}`;
        });
        if (amountsByCurrencyStr.length > 0) {
            return amountsByCurrencyStr.join(" + ");
        }
        return "$ 0";
    }

    const onAuthorizeListed = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenBackdrop(true);
            getTotalAuthorizedCfdis(context.session!.tenant!.id, params).then(response => {
                setTotalToAuthorize(data?.total);
                setTotalAmountToAuthorize(getTotalToAuthorizeListed(response));
                setOpenConfirmAuthorize(true);
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        } else {
            setWarning(translate("cfdis.no_cfdis_to_authorize") as string);
        }
    }

    const authorizeCfdisSelected = (comment: string) => {
        setOpenBackdrop(true);
        setAuthorizeSelected(false);
        setOpenConfirmAuthorize(false);
        const request = { ids: selectedCfdis, comments: comment, stage_from: StatusGroup_IN_PROCESS } as WorkflowRoutingRequest;
        approve(context.session!.tenant!.id, request).then((response) => {
            openModalResults(response);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const authorizeCfdisListed = (comment: string) => {
        setOpenBackdrop(true);
        setOpenConfirmAuthorize(false);
        const request = { comments: comment, stage_from: StatusGroup_IN_PROCESS } as WorkflowRoutingRequest;
        approveListed(context.session!.tenant!.id, request, params).then((response) => {
            openModalResults(response);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const openModalResults = (response: WorkflowApproveResponse) => {
        if (response.queued) {
            setSuccess(translate("cfdis.to_authorize.messages.success_bulk") as string);
            return;
        }
        let cfdisAuthorized = response.items.filter(item => !item.error || item.error === null).map(item => item.payable_document)
        let authorizedAmountStr = getTotalToAuthorize(cfdisAuthorized);
        let cfdisFailed = response.items.filter(item => item.error);
        let failedAmountStr = getTotalToAuthorize(cfdisFailed.map(item => item.payable_document));
        setCfdisFailed(cfdisFailed);
        setTotalAuthorized(authorizedAmountStr);
        setTotalFailed(failedAmountStr);
        setAuthorized(cfdisAuthorized.length);
        setFailed(cfdisFailed.length);

        setOpenResultsBulkAuth(true);
    };

    const onDownloadAllCfdis = (startDate: Date, endDate: Date) => {
        setOpenBackdrop(true);
        setOpenConfirmDialogDownloadAllCfdi(false);
        let user = "";
        if (context.isGranted("ReceptorCFDIsRead")) {
            user = context.session!.user.id;
        }
        let exportParams = params;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        exportAllCfdis(context.session!.tenant!.id, user, exportParams).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                if (response.total > 0) {
                    setSuccess(translate("cfdis.email_export") as string);
                } else {
                    setWarning(translate("cfdis.empty_export") as string);
                }
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportExcel(false);
        let n_params = params;
        n_params.selected_ids = undefined;
        n_params.start_date = startDate;
        n_params.end_date = endDate;
        n_params.connector_id = connectorId;
        onDownloadFiles(n_params);
    }

    const onDownloadWithConnectorSelected = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportExcelSelected(false);
        let n_params = params;
        n_params.selected_ids = selectedCfdis.join(",");
        n_params.start_date = undefined;
        n_params.end_date = undefined;
        n_params.connector_id = connectorId;
        onDownloadFiles(n_params);
    }

    const onOpenConfirmDialogDownloadAllCfdi = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialogDownloadAllCfdi(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onClickedRow = (cfdi: Cfdi) => {
        pushHistory();
        if (cfdi.metadata.from_reception_email && !cfdi.total) {
            setCfdi(cfdi);
            onForeign();
        } else {
            setRedirect(`/cfdis/${cfdi.id}/details?referer=to-authorize&${queryParam}`);
        }
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onCloseConfirmDialogExportExcel = () => {
        setOpenConfirmDialogExportExcel(false);
    }

    const onDownloadSelectedToExcel = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            onDownloadWithConnectorSelected("");
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onDownloadExcel = () => {
        setOpenBackdrop(true);
        setOpenConfirmDialogExportExcel(false);
        let n_params = params;
        n_params.selected_ids = undefined;
        n_params.status_group = StatusGroup_IN_PROCESS;
        if (context.isGranted("ReceptorCFDIsRead")) {
            n_params.receptor = context.session!.user.id;
        }
        onDownloadFiles(n_params);
    }

    const onDownloadFiles = (n_params: CfdisQueryParams) => {
        exportCfdisExcel(context.session!.tenant!.id, n_params).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenExportExcel(false);
        });
    }

    const onOpenConfirmDialogExportExcel = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenExportExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDeleteComprobante = () => {
        setAnchorEl(null)
        setOpenConfirmDialogDelete(true);
    };

    const onConfirmDeleteComprobante = (razon: string) => {
        setOpenConfirmDialogDelete(false);
        setStatus("loading");
        deleteCfdi(context.session!.tenant!.id, cfdi!.id, { comments: razon } as DeleteCfdiRequest).then(() => {
            setStatus("loaded");
            load();
            setSuccess(translate("cfdis.pay_pending.actions.delete.success") as string);
        }).catch((error) => {
            setStatus("loaded");
            setError(error.message);
        });
    };

    const onCloseConfirmDialogDelete = () => {
        setOpenConfirmDialogDelete(false);
    }

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onCancelCfdi = () => {
        setOpenCancelConfirmDialog(false);
        if (!cfdi) return;
        setOpenBackdrop(true);
        cancelCfdi(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": cfdi.identifier, "cfdi_link": `/cfdis/${response.related_cfdi}/details?referer=to-authorize&${queryParam}` }) as string
                ));
            } else {
                load();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onCloseReceptionDateValidation = () => {
        setReceptionDateValidation(undefined);
    }

    const onReceptionDateValidationContinue = () => {
        setReceptionDateValidation(undefined);
        setRedirect(url);
    }

    const onClickAddCfdi = (event: React.MouseEvent<HTMLElement>) => {
        if ((counterTypeProviders && counterTypeProviders.total_foreign > 0) || hasAdvancePayments) {
            setAddAnchorEl(event.currentTarget);
        } else {
            if (context.isGrantedAny(["AdminCFDIsCreate", "CxpCFDIsCreate", "AddCfdi"])) {
                onAddCfdi("/owner-cfdis/new");
            } else if (context.isGrantedAny(["ReceptorCFDIsCreate"])) {
                onAddCfdi("/receptorCfdis/new")
            }
        }
    };

    const onAddCfdi = (url: string) => {
        setUrl(url);
        validateReceptionDate(context.session!.tenant!.id).then((response) => {
            if (!response.valid) {
                setReceptionDateValidation(response);
            } else {
                setRedirect(url);
            }
        }).catch((errors) => {
            setError(errors.message);
        })
    }

    const onCancelAdding = () => {
        setAddAnchorEl(null);
    };

    const onForeign = () => {
        setAddAnchorEl(null);
        setForeingPop(true);
    };

    const onForeignCreditNote = () => {
        setAddAnchorEl(null);
        setOpenForeingCreditNote(true);
    };

    const onAdvancePayments = () => {
        setAddAnchorEl(null);
        setAdvancePaymentsModal(true);
    };

    const onCancelAdvancePayment = () => {
        setAdvancePaymentsModal(false);
    }

    const onCancelAddingForeign = () => {
        setForeingPop(false);
    }

    const onCancelAddForeignCreditnote = () => {
        setOpenForeingCreditNote(false);
    }

    const onAddedForeign = (cfdi: Cfdi) => {
        setForeingPop(false);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-authorize&${queryParam}`);
    }

    const onAddForeignCreditNote = (cfdi: Cfdi) => {
        setOpenForeingCreditNote(false);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-authorize&${queryParam}`);
    }

    const onAddAdvancePaymentSuccess = (cfdi: Cfdi) => {
        setAdvancePaymentsModal(false);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=to-authorize&${queryParam}`);
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedCfdis(selected);
    };

    const onCloseExporter = () => {
        setOpenExportExcel(false);
        setOpenExportProratedExcel(false);
        setOpenDownloadValidationsPdf(false);
    };

    const onCloseExporterSelected = () => {
        setOpenExportExcelSelected(false);
    };

    const onDownloadSelectedToProratedExcel = () => {
        setGridAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setOpenExportExcelSelected(false);
            let exportParams = params;
            exportParams.selected_ids = selectedCfdis.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            exportProratedCfdiExcel(exportParams);

        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onDownloadSelectedValidationsPdf = () => {
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
            setGridAnchorEl(null);
            let exportParams = params;
            exportParams.selected_ids = selectedCfdis.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
            setAnchorEl(null);
            setGridAnchorEl(null);
        }
    }

    const onOpenConfirmDialogDownloadValidationsPdf = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenDownloadValidationsPdf(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadValidationsPdf = (paramsExport: CfdisQueryParams) => {
        exportValidationsPdfCfdis(context.session!.tenant!.id, paramsExport).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
        });
    }

    const onDownloadDateValidationsPdf = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenDownloadValidationsPdf(false);
        setAnchorEl(null);
        setGridAnchorEl(null);
        let exportParams = { ...workingParams };
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        onDownloadValidationsPdf(exportParams);
    }

    const exportProratedCfdiExcel = (params: CfdisQueryParams) => {
        exportCfdisProratedExcel(context.session!.tenant!.id, params).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenDownloadValidationsPdf(false);
        });

    }

    const onOpenConfirmDialogExportProratedExcel = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenExportProratedExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadWithOutConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportProratedExcel(false);
        setAnchorEl(null);
        setGridAnchorEl(null);
        let exportParams = params;
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        exportParams.connector_id = connectorId;
        exportProratedCfdiExcel(exportParams);
    }

    const onDownloadSingleValidationsPdf = () => {
        setAnchorEl(null);
        setGridAnchorEl(null);
        if (!!cfdi) {
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            let exportParams = params;
            exportParams.selected_ids = cfdi.id
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        }
    }

    const onDownloadCfdiFiles = () => {
        setAnchorEl(null);
        setCfdi(undefined);
        setOpenBackdrop(true);
        if (!cfdi) return;
        exportCfdiFiles(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.url) {
                setAnchorEl(null);
                setCfdi(undefined);
                setExportResult(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDoFilter = (filterFields: FilterField[]) => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: filterFields }
        setWorkingParams(params);
        setParams(params);
    };

    const onCleanFilter = () => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: undefined }
        setWorkingParams(params);
        setParams(params);
    };

    return (
        <Surface title={props.title}
            subtitle={translate("cfdis.tenant_group.title") as string}
            icon={<ToAuthorizeIcon />}
            className="PaperPagination"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["AdminCFDIsCreate", "ReceptorCFDIsCreate", "CxpCFDIsCreate", "AddCfdi"]) ?
                            (<Grid item xs="auto">
                                <Fab onClick={onClickAddCfdi} color="primary" size="small" title={translate("buttons.add") as string} disabled={status === "loading"}>
                                    <AddIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) ?
                            (<Grid item xs="auto">
                                <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Grid>) : undefined}
                    </Grid>
                </Grid>
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm>
                    <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                {context.isGranted('AdminCFDIsRead') ? (
                    <Grid item xs={12} sm lg="auto">
                        <ValidatedInput type="text" id="receptor" name="receptor" label={translate("cfdis.filters.receptors.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.receptor} onValueChanged={onFilterChanged}
                            options={receptorIds}
                            optionLabels={receptorNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                ) : undefined}
                <Grid item xs="auto">
                    <Button variant="outlined" color="secondary" size="medium" onClick={onAppliedFilter} >
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onClickFilter} variant={params.filter_fields ? "contained" : "outlined"} color="primary" size="medium">
                        {params.filter_fields ? translate("buttons.filters_applied") : translate("buttons.filter")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton color="default" size="small" onClick={onClickedClean} disabled={status === "loading"}>
                        <HighlightOffTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className="focaltec-small">
                <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    clickRowColumns={props.clickRowColumns}
                    columns={props.columns ? props.columns : []}
                    columnsFormat={props.columnsFormat}
                    selectionIds={selectedCfdis}
                    onClickRow={onClickedRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    defaultExpandedGroups={props.defaultExpandedGroups}
                    amountCurrencyColumns={props.currencyColumns}
                    dateColumns={props.dateColumns}
                    leftColumns={props.leftColumns}
                    rightColumns={props.rightColumns}
                    textColumns={props.textColumns}
                    filters={filters}
                    grouping={props.grouping}
                    customFormatColumns={props.customPlugins}
                    setFiltersHandler={setFiltersHandler}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    setTableCell={props.tableCell}
                    setCheckCell={props.checkCell}
                />
            </div>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {cfdi && anchorEl && (
                <TenantCfdiMenu
                    cfdi={cfdi} anchor={anchorEl} referer={`to-authorize&${queryParam}`}
                    onClose={onCloseOption}
                    onCancel={onOpenCancelCfdi}
                    onChangeReceptor={onOpenChangeReceptor}
                    onDeleteComprobante={onDeleteComprobante}
                    onCompleteData={onForeign}
                    isForeignByEmail={cfdi.metadata.from_reception_email && !cfdi.total}
                    onDownloadFiles={onDownloadCfdiFiles}
                    onDownloadValidationsPdf={onDownloadSingleValidationsPdf}
                />
            )}
            {openConfirmDialogDownloadAllCfdi && (
                <DownloadRange
                    onClose={() => setOpenConfirmDialogDownloadAllCfdi(false)}
                    onExportRange={onDownloadAllCfdis}
                    family="CFDIS"
                    maxDaySelected={31} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {gridAnchorEl && (
                <CfdisToAuthorizedMenu
                    anchor={gridAnchorEl}
                    isReaderOnly={isReaderOnly}
                    onDownloadSelected={downloadCfdisSelected}
                    onDownloadAll={onOpenConfirmDialogDownloadAllCfdi}
                    onDownloadExcelSelected={onDownloadSelectedToExcel}
                    onDownloadExcel={onOpenConfirmDialogExportExcel}
                    onChangeReceptor={onOpenChangeReceptor}
                    onClose={onCloseOption}
                    onDownloadProratedExcel={onOpenConfirmDialogExportProratedExcel}
                    onDownloadProratedExcelSelected={onDownloadSelectedToProratedExcel}
                    onDownloadValidationsPdf={onOpenConfirmDialogDownloadValidationsPdf}
                    onDownloadValidationsPdfSelected={onDownloadSelectedValidationsPdf}
                    onAuthorizeListed={onAuthorizeListed}
                    onAuthorizeSelected={openConfirmAuthorizeCfdisSelected}
                    allowAuthorize={allowBulkAuthorize}
                />
            )}
            {openChangeReceptor && (
                <CfdiChangeReceptorPopUp
                    onCompleted={onchangeReceptor}
                    receptors={receptorsAutocomplete}
                    text={translate("cfdis.change_receptor_cfdi.message_pop_up", { "total": cfdi && cfdi!.id ? 1 : selectedCfdis.length }) as string}
                    onClose={onCloseOption} />
            )}
            {openConfirmDialogExportExcel && (
                <ConfirmationPopup
                    doAction={onDownloadExcel}
                    onClose={onCloseConfirmDialogExportExcel}
                    title={translate("cfdis.popup.confirm_dialog_excel.title") as string}
                    message={translate("cfdis.popup.confirm_dialog_excel.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog_excel.button") as string} />
            )}

            {openConfirmDialogDelete && cfdi && (
                <DeleteCfdiPopup
                    cfdi={cfdi}
                    messageDefault={translate("cfdis.pay_pending.actions.delete.description", { "provider": cfdi ? cfdi.cfdi?.emisor.nombre : "" }) as string}
                    onConfirmDeleteComprobante={onConfirmDeleteComprobante}
                    onCloseConfirmDialogDelete={onCloseConfirmDialogDelete} />
            )}
            {openCancelConfirmDialog && (
                <ConfirmationPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.message") as string}
                    secondary={translate("cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {openFilterDialog && fieldsCatalogType && (
                <FilterPopup
                    filterFields={fieldsCatalogType}
                    tenantId={context.session!.tenant!.id}
                    appliedFilters={workingParams.filter_fields}
                    doAction={onDoFilter}
                    onCleanFilter={onCleanFilter}
                    onClose={() => setOpenFilterDialog(false)} />
            )}
            {receptionDateValidation &&
                <ConfirmationPopup
                    title={translate("cfdis.popup.validation.types.RECEPTION_DATE") as string}
                    message={translate(`cfdis.popup.validation.codes.${receptionDateValidation.code}`, translateParams(receptionDateValidation.params) as any) as string}
                    secondary={receptionDateValidation.tenant_side ? translate("cfdis.popup.validation.tenant_upload_question") as string : undefined}
                    onClose={onCloseReceptionDateValidation}
                    button={receptionDateValidation.tenant_side ? translate("buttons.continue") as string : undefined}
                    doAction={receptionDateValidation.tenant_side ? onReceptionDateValidationContinue : undefined} />
            }
            {addAnchorEl && (
                <NewPayableDocumentMenu anchor={addAnchorEl}
                    isProviderForeign={false}
                    onClose={onCancelAdding}
                    onForeign={counterTypeProviders && counterTypeProviders.total_foreign > 0 ? onForeign : undefined}
                    onForeignCreditNote={onForeignCreditNote}
                    onAdvancePayments={hasAdvancePayments ? onAdvancePayments : undefined} />
            )}
            {foreingPop && (
                <NewForeignPopup onCancel={onCancelAddingForeign} onSuccess={onAddedForeign} cfdi={cfdi} />
            )}
            {openForeingCreditNote && (
                <NewForeignCreditNotePop onCancel={onCancelAddForeignCreditnote} onSuccess={onAddForeignCreditNote} cfdi={cfdi} />
            )}
            {advancePaymentsModal &&
                <AdvancePaymentPopup onSuccess={onAddAdvancePaymentSuccess} onCancel={onCancelAdvancePayment} />
            }
            {(openExportExcel || openExportProratedExcel || openDownloadValidationsPdf) && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA_RANGE"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporter}
                    onExport={openExportExcel ? onDownloadWithConnector : openExportProratedExcel ? onDownloadWithOutConnector : onDownloadDateValidationsPdf}
                    maxDaySelected={31} />
            )}
            {openExportExcelSelected && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporterSelected}
                    onExport={onDownloadWithConnectorSelected} />
            )}
            {openConfirmAutorize && (
                <ConfirmationPopup
                    doActionCommentary={authroizeSelected ? authorizeCfdisSelected : authorizeCfdisListed}
                    onClose={() => setOpenConfirmAuthorize(false)}
                    title={translate("cfdis.to_authorize.bulk_modal.title") as string}
                    message={translate("cfdis.to_authorize.bulk_modal.message", { "N": totalToAuthorize, "amount": totalAmountToAuthorize }) as string}
                    secondary={translate("cfdis.to_authorize.bulk_modal.secondary") as string}
                    comment={translate("cfdis.to_authorize.bulk_modal.comment") as string}
                    addCommentary={false}
                    button={translate("buttons.accept") as string}
                />
            )}
            {openResultsBulkAuth && (
                <ShowResultsBulkAuthModal
                    cfdisFailed={cfdisFailed}
                    onClose={() => setOpenResultsBulkAuth(false)}
                    totalAuthorized={totalAuthorized}
                    totalFailed={totalFailed}
                    authorized={authorized}
                    failed={failed}
                />
            )}
        </Surface>
    );
}
