import { ExportableObject } from "./ExportableObject";
import translate from "../i18n/Translator";

export interface Customer extends ExportableObject {
    tenand_id: string;
    name: string;
    rfc: string;
    cfdi_sending_mode?: CustomerSendingMode;
    cfdi_recipients?: string;
    cfdi_notes?: string;
    document_sending_mode?: CustomerSendingMode;
    document_recipients?: string;
    document_notes?: string;
    created_at: Date;
    address_street: string;
    address_number_ext: string;
    address_number_int: string;
    address_between_street: string;
    address_between_street_after: string;
    address_county: string;
    address_zip_code: string;
    address_municipality: string;
    address_state: string;
    contact_email: string;
    contact_phone: string;
    contact_extension: string;
    shipping_requirements: CustomerShippingRequirement[];
}

export interface Customers {
    items: Customer[];
    total: number;
}

export interface CustomersQueryParams {
    search: string;
}

export interface CustomerRequest {
    name: string;
    rfc: string;
    external_id: string;
    cfdi_sending_mode: CustomerSendingMode;
    cfdi_recipients: string;
    cfdi_notes?: string;
    document_sending_mode: CustomerSendingMode;
    document_recipients: string;
    document_notes?: string;
    employer_number?: string;
    address_street: string;
    address_number_ext: string;
    address_number_int: string;
    address_between_street: string;
    address_between_street_after: string;
    address_county: string;
    address_zip_code: string;
    address_municipality: string;
    address_state: string;
    contact_email: string;
    contact_phone: string;
    contact_extension: string;
}

export interface CustomerShippingRequirementCommons {
    document_id: string;
    description: string;
    unit: string;
    quantity: number;
    day: number;
    initial_month: number;
    custom: boolean;
}

export interface CustomerShippingRequirement extends CustomerShippingRequirementCommons {
    id: string;
    name: string;
}

export interface CustomerShippingRequirementRequest extends CustomerShippingRequirementCommons{
    customer_id: string;
}

export interface DocumentCombo {
    id: string;
    value: string;
    custom: boolean;
}

export interface DocumentCombos {
    items: DocumentCombo[];
}
export type CustomerSendingMode = 'access' | 'email' | 'pdc' | 'physical_delivery';

export const SENDING_MODES: CustomerSendingMode[] = ['access', 'email', 'pdc', 'physical_delivery'];

export const getDocumentName = (custom: boolean, name: string) => {
    if(custom){
        return name;
    }
    return translate(`contributions.to_validate.types.${name}`) as string;
}