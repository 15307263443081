import React, { useContext, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";

import { Cfdi } from "../model/Cfdi";
import { ExpensesAccountDocument } from "../model/ExpensesAccount";
import { removeDocument } from "../api/ExpensesAccountAPI";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";

interface ExpensesAccountDeletePopupProps {
    payableDocument: Cfdi;
    document: ExpensesAccountDocument;
    onCompleted(payableDocument: Cfdi): any;
    onCancelled(): any;
}

export default function ExpensesAccountDeletePopup(props: ExpensesAccountDeletePopupProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();

    const onConfirm = () => {
        setSubmitting(true);
        removeDocument(context.session!.tenant!.id, context.session!.provider!.id, props.payableDocument.id, props.document.id).then((payableDocument: Cfdi) => {
            props.onCompleted(payableDocument);
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    return (
        <DialogPopup open
            title={translate("expenses_accounts.delete_document.title") as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onConfirm} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.delete")}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate("expenses_accounts.delete_document.description")}
            </Typography>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );

}