import React from "react";
import translate from "../i18n/Translator";

import { Requisition } from "../model/Requisition";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { GridDxProps, NoFilterEditor } from "../components/GridDx";
import { Box, FormControl, Select } from "@material-ui/core";
import { StatusDescription } from "../model/StageStatus";
import { Company } from "../model/Company";

export interface GetRequisitionsPropertiesProps {
    view : string;
    statuses: StatusDescription[];
    companies: Company[];
}

export function getRequisitionsProperties( props: GetRequisitionsPropertiesProps) {
    const columnsFormat = [
        { columnName: 'operation_number', width: 70 },
        { columnName: 'created_at', width: 100 },
        { columnName: 'description', wordWrapEnabled: true },
        { columnName: 'requisition_type_name', wordWrapEnabled: true },
        { columnName: 'provider_name', wordWrapEnabled: true},
        { columnName: 'company_name', wordWrapEnabled: true, width: 150 },
        { columnName: 'project_name', wordWrapEnabled: true },
        { columnName: 'quotation.amount', align: 'right', wordWrapEnabled: true, width: 120 },
        { columnName: 'status', wordWrapEnabled: true, width: props.view === 'archive' || props.view === 'delivered_erp' ? 120 : 0 },
        { columnName: 'menu', width: props.view === 'authorized' || props.view === 'delivered_erp'  ? 0 : 50 },
    ];

    const getStatusDescription = (status: string) => {
        let statusFind = props.statuses.find(item => item.status === status);
        return statusFind ? statusFind.description : status;
    }

    const getCompanyName = (requisition: Requisition) => {
        let company = props.companies.find(item => requisition.company_id && item.id === requisition.company_id);
        if (company) {
            return company.name;
        } 
        return "---";
    }

    const columns = [
        {
            name: 'operation_number',
            title: translate('requisitions.columns.operation_number') as string
        },
        {
            name: 'created_at',
            title: translate('requisitions.columns.created_at') as string
        },
        {
            name: 'description',
            title: translate('requisitions.columns.description') as string
        },
        {
            name: 'requisition_type_name',
            title: translate('requisitions.columns.requisition_type_name') as string,
        },
        {
            name: 'provider_name',
            title: translate("requisitions.columns.provider_name") as string,
        },
        {
            name: 'company_name',
            title: translate('requisitions.columns.company') as string,
            getCellValue: (row: any) => getCompanyName(row)
        },
        {
            name: 'project_name',
            title: translate("requisitions.columns.project_name") as string,
        },
        {
            name: 'quotation.amount',
            title: translate('requisitions.columns.quotation_amount') as string,
            getCellValue: (row: any) => row.total,
        },
        {
            name: 'status',
            title: translate('requisitions.overview.details.status') as string,
            getCellValue: (row: any) => getStatusDescription(row.status),
        },
        {
            name: 'menu',
            title: " "
        }
    ];

    const currencyColumns = ['quotation.amount'];

    const clickRowColumns = ['operation_number'];

    const textColumns = ['operation_number', 'description', 'requisition_type_name', 'provider_name', 'company_name', 'project_name' ];

    const dateColumns = ['created_at'];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const deliveredErpStatuses = props.statuses ? props.statuses.filter(item => "DELIVERED_ERP" === item.stage) : [];

    const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="type"
                        id="type"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {deliveredErpStatuses.map(item => item.status).map((value) => {
                            return <option key={value} value={value}>{value}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const CompanyFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
            <FormControl >
                <Select native style={{
                fontSize: "10px"
            }}
                    labelId="is_default"
                    id="is_default"
                    value={params.value ? params.value : ''}
                    onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                    {props.companies.map((value) => {
                        return <option key={value.id} value={value.id}>{value.name}</option>
                    })}
                </Select>
            </FormControl>
            </Box>
        );
    };

    const customPlugins =
    [
        <NormalTypeProvider for={['quotation.amount']} editorComponent={NoFilterEditor} />,
        <NormalTypeProvider for={['status']} editorComponent={props.view === 'delivered_erp' ? StatusFilterEditor : NoFilterEditor} />,
        <NormalTypeProvider for={['company_name']} editorComponent={CompanyFilterEditor} />,
    ];

    function filteredColumns( columns: any[]) {
        if (props.view === 'authorized' || props.view === 'delivered_erp') {
            columns = columns.filter((c) => c.name !== 'menu');
        }

        if (props.view !== 'archive' && props.view !== 'delivered_erp') {
            columns = columns.filter((c) => c.name !== 'status');
        }
        return columns;
    }

    return {
        columnsFormat: columnsFormat,
        columns: filteredColumns(columns),
        amountCurrencyColumns: currencyColumns,
        clickRowColumns: clickRowColumns,
        textColumns: textColumns,
        dateColumns: dateColumns,
        customFormatColumns: customPlugins
    } as GridDxProps<Requisition>;
}
