import React, { useContext, useEffect, useState } from "react";
import { Grid, List, ListSubheader } from "@material-ui/core";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ExtendedField, ExtendedFields } from "../model/ExtendedField";
import FieldItemsDetail from "../components/FieldItems/FieldItemsDetail";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { isRoleOrParent } from "../model/Role";

import { Field, Project } from "../model/Project";
import { deleteValueExtendeField, updateValueExtendeField } from "../api/ProjectAPI";
import { formatDateToString } from "../components/DateFormat";
import { CatalogValue } from "../model/Catalog";
import { CallbackFunction, SuccessCallbackFunction } from "../components/FieldItems/OnSubmitField";

interface ProjectDocumentsProps {
    project: Project;
    onSetProject(project: Project): void;
    onChangeTemplate(extendedFields: ExtendedFields): any;
}

export default function ProjectDocuments(props: ProjectDocumentsProps) {
    const [fields, setFields] = useState<ExtendedField[]>();

    const context = useContext(AppContext);
    const [project, setProject] = useState<Project>(props.project);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only") || "FINALIZED" == props.project.status;

    useEffect(() => {
        setFields(project.template?.fields?.map(f => {
            return {
                id: f.field.id,
                name: f.field.name,
                description: f.field.description,
                type: f.field.type,
                value: f.value,
                editable: true,
                required: f.field.required,
                read_only: false,
                values_catalog: getCatalog(f.field, project?.values_catalog),
                by_each_payment: false,
                payment_process_history: []
            } as ExtendedField;
        }) ?? []);
    }, [project]);

    const getCatalog = (field: Field, values_catalog: Record<string, CatalogValue[]>) => {
        if (!!field?.catalog_id && !!values_catalog) {
            return values_catalog[field.catalog_id] ?? [];
        }
        return [];
    }
    const onEditField = (extendedField: ExtendedField, callback: SuccessCallbackFunction) => {

        if (!!extendedField?.value) {
            let field = project.template?.fields.find(f => f.field.id === extendedField.id);
            let value = extendedField.value && "DATE" === field?.field.type ? formatDateToString(new Date(extendedField.value)) : extendedField.value;
            updateValueExtendeField(props.project.tenant_id, props.project.id, props.project!.template!.id, extendedField.id, value).then(response => {
                setProject(response);
                props.onSetProject(response);
                callback();
            }).then((error: any) => {
                setError(error?.message ?? undefined);
            });
        }
    };

    const onDeleteField = (extendedField: ExtendedField) => {
        deleteValueExtendeField(props.project.tenant_id, props.project.id, props.project!.template!.id, extendedField.id).then(value => {
            setProject(value);
            props.onSetProject(value);
        }).then((error: any) => {
            setError(error?.message ?? undefined);
        });
    };

    const onErrorEditField = (error: string, errorCode?: any) => {
        setError(error);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    if (!fields || fields.length === 0) {
        return <></>
    }

    return (
        <Grid item xs={12} style={{ borderTop: "1px solid  rgba(0, 0, 0, 0.12)" }}>

            <List component="nav" dense subheader={
                <ListSubheader disableSticky>
                    {translate("requisitions.projects.details.documents.title" as string)}
                </ListSubheader>
            }>
                <FieldItemsDetail
                    fields={fields ? fields : []}
                    tenantId={context.session!.tenant!.id}
                    fieldId={props.project.id}
                    isReaderOnly={isReaderOnly}
                    onChange={onEditField}
                    onDelete={onDeleteField}
                    onError={onErrorEditField}
                    allowLinkPO={false} />
            </List>

            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Grid>
    );
}