import { callAPI, HTTPMethod } from "./API";
import { ProviderClassificationRequest, ProviderClassification, ProviderClassifications, ProviderClassificationsQueryFilter } from "../model/ProviderClassification"

export async function listClassifications(tenantId: string, page: number, offset: number, params: ProviderClassificationsQueryFilter): Promise<ProviderClassifications> {
    return await callAPI(`/tenants/${tenantId}/provider-classifications`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getProviderClassification(tenantId: string, providerClassificationId: string): Promise<ProviderClassification> {
    return await callAPI(`/tenants/${tenantId}/provider-classifications/${providerClassificationId}`, {
        method: HTTPMethod.GET,
    });
}

export async function createProviderClassification(tenantId: string, request: ProviderClassificationRequest): Promise<ProviderClassification> {
    return await callAPI(`/tenants/${tenantId}/provider-classifications`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function updateProviderClassification(tenantId: string, providerClassificationId: string, request: ProviderClassificationRequest): Promise<ProviderClassification> {
    return await callAPI(`/tenants/${tenantId}/provider-classifications/${providerClassificationId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteProviderClassification(tenantId: string, providerClassificationId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/provider-classifications/${providerClassificationId}`, {
        method: HTTPMethod.DELETE
    });
}