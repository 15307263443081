import React, { useState, useEffect, useContext } from "react";
import Surface from "../components/Surface";
import { Grid, IconButton } from '@material-ui/core';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { MyCfdisIcon, AuxiliarDocumentsIcon } from "../components/Icons";
import { getBillingData } from "../api/ProviderAPI";
import { TenantBillingData } from "../model/Tenant";
import Gridable from "../components/Gridable";
import { SuccessSnackbar } from "../components/Snackbars";

export default function TenantBillingDataWidget() {

    const context = useContext(AppContext);
    const [data, setData] = useState<TenantBillingData>();
    const [status, setStatus] = useState<string>("loading");
    const provider = context.session!.provider?.id;
    const [billingDataCopied, setBillingDataCopied] = useState<string>();

    const onInit = () => {
        if (!provider) {
            setStatus("loaded");
            return;
        };

        getBillingData(context.session!.tenant!.id, provider).then(((data: any) => {
            setData(data);
        })).catch((e: any) => {
            setStatus(e.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(onInit, []);

    return (
        <Surface title={translate(`widget_menu.billing_data`)} icon={<MyCfdisIcon />}>
            <Grid key={1} item xs={12} style={{ marginBottom: "6px" }}>
                <Gridable
                    items={data?.items ?? []}
                    loading={status === "loading"}
                    error={status !== "loading" && status !== "loaded" ? status : undefined}
                    empty={translate("widget_menu.billing_data.empty") as string}
                    columns={[
                        {
                            title: translate("widget_menu.billing_data.name") as string,
                            converter: (item) => item.name,
                            fullWidth: true,
                            xs: 3,
                        },
                        {
                            title: translate("widget_menu.billing_data.rfc") as string,
                            converter: (item) => item.rfc,
                            fullWidth: true,
                            xs: 3,
                        },
                        {
                            title: translate("widget_menu.billing_data.tax_regimen") as string,
                            converter: (item) => item.tax_regimen,
                            fullWidth: true,
                            xs: true,
                            sm: true,
                            md: true,
                            lg: 3,
                            xl: true
                        },
                        {
                            title: translate("widget_menu.billing_data.cp") as string,
                            converter: (item) => item.postal_code,
                            fullWidth: true,
                            xs: 2,
                        },
                        {
                            title: (
                                <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                    <AuxiliarDocumentsIcon />
                                </IconButton>
                            ),
                            converter: (item) => (
                                <IconButton aria-label="options" color="default" size="small" onClick={() => {
                                    const textCopied = `${item.name} ${item.rfc} `;
                                    navigator.clipboard.writeText(`${textCopied} ${item.postal_code} ${item.tax_regimen}`);
                                    setBillingDataCopied(`${translate("widget_menu.billing_data.data_copied") as string}: ${textCopied} `);
                                }}>
                                    <AuxiliarDocumentsIcon />
                                </IconButton>
                            ),
                            fullWidth: true,
                            justify: "flex-end",
                            xs: "auto"
                        }
                    ]} />
            </Grid>
            <SuccessSnackbar message={billingDataCopied} onClose={() => {
                setBillingDataCopied(undefined)
            }} />
        </Surface>
    );
}