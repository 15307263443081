import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { InputRef } from "../components/ValidatedInput";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import Autocomplete from '@material-ui/lab/Autocomplete';
import translate from "../i18n/Translator";

interface TenantCurrencyAutocompleteProps {
    currencies: AutocompleteProvider[];
    disabled: boolean;
    value?: AutocompleteProvider | undefined;
    required?: boolean;
    onChange(value: string, inputRef?: InputRef): any;
}

export default function TenantCurrencyAutocomplete(props: TenantCurrencyAutocompleteProps) {

    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        props.onChange(value, inputRef);
    };

    return (
        <Autocomplete
            size="small"
            options={props.currencies || []}
            getOptionLabel={(elemento) => elemento.title}
            disabled={props.disabled}
            noOptionsText={translate("currencies.no_options_autocomplete") as string}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" required={props.required} label={translate("currencies.select_currency") as string} fullWidth />
            )}
            onChange={(event, newValue) => {
                if (newValue) {
                    onFilterChanged(newValue.value);
                } else {
                    onFilterChanged("");
                }
            }}
            getOptionSelected={(opt, val) => opt.value === val?.value}
            value={props.value}

        />);
}
