import React, { useState, useContext } from "react";
import translate from "../../i18n/Translator";
import { Typography, Grid, Button } from "@material-ui/core";
import CustomBackdrop from "../../components/CustomBackdrop";
import { ErrorSnackbar } from "../../components/Snackbars";
import DialogPopup from "../../components/DialogPopup";
import UploaderInput from "../../components/UploaderInput";
import { CfdiDataRequest, TaxStampMetadata } from "../../model/StampTaxMetadata";
import ValidatedInput, { InputRef, isValid } from "../../components/ValidatedInput";
import { updateCfdiData } from "../../api/TaxStampMetadataApi";
import { AppContext } from "../../context/AppContext";
import { PostalCodeValidator } from "../../components/Validators";

interface UpdateCfdiDataModalProps {
    onClose(): any;
    onUpdateCfdiData(response: TaxStampMetadata): any;
    disableEditFolio: boolean;
    taxStampMetadata?: TaxStampMetadata;
}

export default function UpdateCfdiDataModal(props: UpdateCfdiDataModalProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [validations, setValidations] = useState({} as any);
    const postalCodeValidator = new PostalCodeValidator();
    const [request, setRequest] = useState<CfdiDataRequest>({
        postal_code: props.taxStampMetadata?.postal_code || "",
        initial_folio: props.taxStampMetadata?.initial_folio || "",
        logo_url: props.taxStampMetadata?.logo_url || "",
        serie_rep: props.taxStampMetadata?.serie_rep || "",
        rfc: context.session?.provider?.rfc || ""
    } as CfdiDataRequest);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onUpdateCertificate = () => {
        if (!isValid(validations)) {
            return;
        }
        setSubmitting(true);
        updateCfdiData(request).then(response => {
            props.onUpdateCfdiData(response);
        }).catch(error => {
            setError(error.message);
        }).finally(() => setSubmitting(false));
    };

    return (
        <DialogPopup title={translate("tax_stamp.cfdi_data_title") as string} open maxWidth="md" onClose={props.onClose}
            button={<Button onClick={onUpdateCertificate} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.save")}
                </Button>} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{translate("tax_stamp.cfdi_data_text") as string}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="number"
                                id="postal_code" name="postal_code"
                                label={translate("tax_stamp.address") as string}
                                value={request.postal_code}
                                required={true}
                                margin={"dense"}
                                disabled={submitting}
                                onValueChanged={hasChanged}
                                validator={postalCodeValidator}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="serie_rep" name="serie_rep"
                                label={translate("tax_stamp.rep") as string}
                                value={request.serie_rep}
                                required={true}
                                margin={"dense"}
                                disabled={submitting}
                                maxLength={10}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="initial_folio" name="initial_folio"
                                label={translate("tax_stamp.rep_serial") as string}
                                value={request.initial_folio}
                                required={true}
                                margin={"dense"}
                                disabled={submitting || props.disableEditFolio}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <UploaderInput id="logo_url" name="logo_url"
                                label={translate("tax_stamp.logo") as string}
                                value={request.logo_url} disabled={submitting}
                                margin="dense" path="cer" acceptExtension=".png, .jpeg, .jpg"
                                onValueChanged={hasChanged}
                                required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{translate("tax_stamp.logo_preview") as string}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {request.logo_url === "" ? <></>: <img src={request.logo_url}
                                alt="new" />}
                        </Grid>
                    </Grid>
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>);
}
