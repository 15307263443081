import React, { useState, useEffect, useContext } from "react";
import FullCalendar, { DatesSetArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Calendar, Event, CalendarEvent } from "../model/Dashboard";
import { getCalendar } from "../api/DashboardAPI";
import moment from "moment";

import { Grid, Typography, Tooltip } from '@material-ui/core';
import GroupIcon from "@material-ui/icons/CalendarToday";
import { ResponsiveContainer } from "recharts";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import esLocale from '@fullcalendar/core/locales/es';
import { AppContext } from "../context/AppContext";

function renderEventContent(eventInfo: any) {
    return (
        <Tooltip title={`${eventInfo?.event?.extendedProps?.type ? translate(`calendar.legend.${eventInfo?.event?.extendedProps?.type ?? "CUSTOM"}`) as string : ''}: ${eventInfo?.event?.title ?? ""} ` }>
            <Typography style={{ minWidth: "150px" }} className="circle"  variant="caption" >{eventInfo?.event?.title ?? ""}</Typography>
        </Tooltip>
    )
}

export default function CalendarWidget() {
    const [currentYear, setCurrentYear] = useState<string>(`${new Date().getFullYear()}`);
    const [currentMonth, setCurrentMonth] = useState<string>(`${new Date().getMonth() + 1}`);

    const context = useContext(AppContext);
    const [events, setEvents] = useState<Event[]>();
    const [customEvents, setCustomEvents] = useState<CalendarEvent[]>([]);

    const load = () => {
        getCalendar(context.session!.tenant!.id, currentYear, currentMonth).then((response) => {
            convertToEvent(response);
        }).catch((error) => {
        });
    }

    useEffect(load, [currentYear, currentMonth]);

    const convertToEvent = (response: Calendar) => {
        let eventsE = [] as Event[];
        let customEv = [] as CalendarEvent[];
        response.items.forEach(d => {
            let endDate = new Date(d.end_date!).setDate(new Date(d.end_date!).getDate() + 1);
            let event = {
                title: d.label,
                allDay: true,
                start: d.type === 'CUSTOM' ? moment(d.date).format("YYYY-MM-DDTHH:mm:ss") : undefined,
                end: d.type === 'CUSTOM' ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss") : undefined,
                date: moment(d.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: getColor(d),
                borderColor: getBorderColor(d),
                display: 'block',
                type: d.type
            } as Event;
            if (d.type === 'CUSTOM' && customEv.findIndex(ce => ce.color === d.color) < 0) {
                customEv.push(d);
            }
            eventsE.push(event);
        });
        setEvents(eventsE);
        setCustomEvents(customEv);
    }

    const getBorderColor = (event: CalendarEvent) => {
        switch (event.type) {
            case "DAY_OFF_BANK":
                return "#FA7E4E";
            case "PAYMENT_PROVIDER":
                return event.color ?? "#7DE59D";
            case "END_RECEPTION_INVOICES":
                return "#FDCF2E";
            case "END_RECEPTION_INVOICES_MONTH":
                return event.color ?? "#000000";
            case "CUSTOM":
                return event.color;
            default: return event.color;
        }
    }

    const getColor = (event: CalendarEvent) => {
        switch (event.type) {
            case "DAY_OFF_BANK":
                return "#FA7E4E";
            case "PAYMENT_PROVIDER":
                return event.color ?? "#7DE59D";
            case "END_RECEPTION_INVOICES":
                return "#FDCF2E";
            case "END_RECEPTION_INVOICES_MONTH":
                return event.color ?? "#FFFFFF";
            case "CUSTOM":
                return event.color;
            default: return event.color;
        }
    }

    const getDayOffBank = () => {
        return (
            <Grid item xs={12} container direction="row" alignItems="center" style={{ marginTop: "100px", marginLeft: "17px" }}>
                <Typography className="circle" style={{ backgroundColor: "#FA7E4E" }}></Typography>
                <Typography variant="caption" component="h6" style={{ marginLeft: "10px" }}>{translate(`calendar.legend.DAY_OFF_BANK`)}</Typography>
            </Grid>
        );
    }

    const getEndReceptionInvoices = () => {
        return (
            <Grid item xs={12} container direction="row" alignItems="center" style={{ marginTop: "20px", marginLeft: "17px" }}>
                <Typography className="circle" style={{ backgroundColor: "#FDCF2E" }}></Typography>
                <Typography variant="caption" component="h6" style={{ marginLeft: "10px" }}>{translate(`calendar.legend.END_RECEPTION_INVOICES`)}</Typography>
            </Grid>
        );
    }

    return (
        <Surface title={translate("calendar.title")} icon={<GroupIcon />}>
            <Grid container justify="space-evenly" alignItems="flex-start" spacing={1}>
                <Grid item xs={12} md={8} lg={8} xl={8} style={{ borderRight: "1px solid #EBEBEB" }}>
                    <Grid container alignItems="center" style={{ marginLeft: "10%" }}>
                        <div style={{ width: "80%", height: 430 }}>
                            <ResponsiveContainer>
                                <FullCalendar
                                    plugins={[dayGridPlugin]}
                                    eventContent={renderEventContent}
                                    events={events}
                                    locale={esLocale}
                                    firstDay={0}
                                    expandRows={true}
                                    selectMirror={true}
                                    datesSet={(event: DatesSetArg) => {
                                        const currentEndDate = event.view.currentStart;
                                        setCurrentMonth(`${currentEndDate.getMonth() + 1}`);
                                        setCurrentYear(`${currentEndDate.getFullYear()}`);
                                    }}
                                />
                            </ResponsiveContainer>
                        </div>
                    </Grid>
                </Grid>
                <Grid item style={{ marginTop: "-10px" }} xs={12} md={4} lg={4} xl={4}>

                    {events?.filter(event => ["DAY_OFF_BANK", "END_RECEPTION_INVOICES", "PAYMENT_PROVIDER", "END_RECEPTION_INVOICES_MONTH", "PUE_INVOINCES_LIMIT_MONTH"].includes(event.type)).map(event => {
                        if ("DAY_OFF_BANK" === event.type) {
                            return getDayOffBank();
                        }
                        if ("END_RECEPTION_INVOICES" === event.type) {
                            return getEndReceptionInvoices();
                        }
                        return (
                            <Grid item xs={12} container direction="row" alignItems="center" style={{ marginTop: "10px", marginLeft: "17px" }}>
                                <Typography className="circle" style={{ backgroundColor: event?.color ?? "#FFF", borderStyle: "solid", borderWidth: "2px", borderColor: event?.color ?? "#000" }}></Typography>
                                <Typography variant="caption" component="h6" style={{ marginLeft: "10px" }}>{translate(`calendar.legend.${event.type ?? "CUSTOM"}`)}</Typography>
                            </Grid>
                        );
                    })
                    }
                    {customEvents.map(event => {
                        return (
                            <Grid item xs={12} container direction="row" alignItems="center" style={{ marginTop: "10px", marginLeft: "17px" }}>
                                <Typography className="circle" style={{ backgroundColor: event.color }}></Typography>
                                <Typography variant="caption" component="h6" style={{ marginLeft: "10px" }}>{event.label}</Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Surface>
    );
}