import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent } from "@material-ui/core";

import translate from "../i18n/Translator";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { GTZeroValidator, DecimalsValidator } from '../components/Validators';
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { TenantCurrencyRequest } from "../model/TenantConfiguration";
import { WarningSnackbar } from "../components/Snackbars";
import { TenantCurrency } from '../model/Currency';

interface AddCurrencyPopUpProps {
    currencies: AutocompleteProvider[];
    onClose(): any;
    currency?: TenantCurrency;
    hasBudgets: boolean;
    submit(request: TenantCurrencyRequest): void;
}

export default function AddCurrencyPopUp(props: AddCurrencyPopUpProps) {
    const [newCurrencyId, setNewCurrencyId] = useState<string | undefined>(props?.currency?.code ?? undefined);
    const gTZeroValidator = new GTZeroValidator();
    const gtMaxDecimalsValidator = new DecimalsValidator(6);
    const [validations, setValidations] = useState({
        "currency": {
            valid: !!props?.currency
        } as InputRef,
        "exchange": {
            valid: !props.hasBudgets
        } as InputRef
    } as any);
    const [exchangeRate, setExchangeRate] = useState<number | undefined>(props?.currency?.budget_exchange_rate);
    const [warning, setWarning] = useState<string | undefined>();
    const [disableExchange, setDisableExchange] = useState<boolean>("MXN" === props?.currency?.code);
    const [submiting, setSubmiting] = useState<boolean>(false);

    useEffect(() => {
    }, [props]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations, true)) {
            if (!newCurrencyId) {
                setWarning(translate("currencies.currency_required") as string);
                return
            }
            if (props.hasBudgets && !exchangeRate) {
                setWarning(translate("currencies.exchange_rate_required") as string);
            }
            return;
        }
        setSubmiting(true);
        props.submit({ code: newCurrencyId!, budget_exchange_rate: exchangeRate });
    };

    const onFilterChanged = (value: string) => {
        validations["currency"] = {
            valid: !!value,
        };
        setValidations(validations);
        if (value !== undefined) {
            setNewCurrencyId(value);
            setDisableExchange("MXN" === value);

        }
    };

    const onChangeBudget = (name: string, value: string | number, inputRef: InputRef) => {
        setExchangeRate(!!value && (value !== "undefined") ? value as number : undefined);
        validations[name] = inputRef;
        setValidations(validations);
    }

    const getCurrencyValue = () => {
        if (!!newCurrencyId) {
            const c = props.currencies.find(c => c.value === newCurrencyId);
            if (c) {
                return {
                    title: c.title,
                    value: c.value
                } as AutocompleteProvider;
            }
        }
        return undefined;
    }

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("currencies.add_currency") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Grid item xs={12} sm lg="auto" style={{ "marginBottom": "3%" }}>
                            <Typography variant="body1">
                                {translate("currencies.add_message") as string}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm lg="auto">
                            <TenantCurrencyAutocomplete required={true} currencies={props.currencies} value={getCurrencyValue()} onChange={onFilterChanged} disabled={!!props?.currency} />
                        </Grid>

                        {props.hasBudgets && <Grid item xs={12}>
                            <ValidatedInput type="number" id="exchange" name="exchange"
                                value={(disableExchange ? 1 : exchangeRate) + ""}
                                label={translate("currencies.exchange_rate") as string}
                                required={props.hasBudgets}
                                margin="dense"
                                disabled={disableExchange}
                                disableDefaultValidator
                                validators={[gTZeroValidator, gtMaxDecimalsValidator]}
                                onValueChanged={onChangeBudget} />
                        </Grid>
                        }
                        {props.hasBudgets && <Grid item xs={12} sm lg="auto">
                            <Typography variant="subtitle1" component="h6">
                                {translate("currencies.exchange_rate_label", { name: props.currencies.find(c => c.value === newCurrencyId)?.title ?? "-", code: props.currencies.find(c => c.value === newCurrencyId)?.value ?? "---" })}
                            </Typography>
                        </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={false}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={submiting}>
                        {translate("buttons.accept")}
                    </Button>
                </DialogActions>
            </form>
            <WarningSnackbar message={warning} onClose={() => { setWarning(undefined) }} />

        </Dialog>
    );
}
