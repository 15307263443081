import { Card, ListItemText, CardContent, Box, Grid, ListItem } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Cfdi } from "../model/Cfdi";
import translate from "../i18n/Translator";
import Row from "../components/Row";
import DateFormat from "../components/DateFormat";
import { exportCfdiFiles } from "../api/TenantCfdiApi";
import { ErrorSnackbar } from "../components/Snackbars";
import Progress from "../components/Progress";

interface ForeignCreditNoteInfoProps {
    creditNote: Cfdi;
}

export default function ForeignCreditNoteInfo(props: ForeignCreditNoteInfoProps) {

    const history = useHistory();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [status, setStatus] = useState<string>("loading");
    const [fileUrl, setFileUrl] = useState<string>("");
    const [redirect, setRedirect] = useState<string>();

    const load = () => {
        exportCfdiFiles(props.creditNote.metadata.tenant_id, props.creditNote.id)
            .then((response) => {
                if (response.url) {
                    setFileUrl(response.url);
                } 
            })
            .catch((error) => setError(error.message)).finally(() => {
                setStatus("loaded");
            });
    };

    const onClickedRow = () => {
        
        if(props.creditNote.metadata.related_cfdis && props.creditNote.metadata.related_cfdis.length > 0){
            setRedirect(`/cfdis/${props.creditNote.metadata.related_cfdis[0].id}/details`);
        }
    };

    useEffect(load, []);

    if (status === "loading") {
        return <Progress />;
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Box px={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Row title={translate("foreign_credit_note.detail.number") as string} content={props.creditNote.identifier} />
                        <Row title={translate("foreign_credit_note.date") as string}
                            content={<DateFormat date={props.creditNote.date} format="L" />} />
                        <Row title={translate("foreign_credit_note.detail.file") as string} content={<a href={fileUrl} target="_blank" rel="noopener noreferrer">{props.creditNote.metadata.pdf_file_name}</a>} />
                        </Grid>

                        <Grid item xs={6}>
                        
                        <Row title={translate("foreign_credit_note.detail.related_invoice") as string} content={
                            <a href={`/cfdis/${props.creditNote.metadata.related_cfdis ? props.creditNote.metadata.related_cfdis[0].id : ""}/details`} rel="noopener noreferrer">{props.creditNote.metadata.related_cfdis ? props.creditNote.metadata.related_cfdis[0].identifier : "---"}</a>
                        } />
                        <Row title={translate("foreign_credit_note.comments") as string} content={props.creditNote.metadata.comments || "---"} />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
            <ErrorSnackbar message={error} onClose={() => {setError(undefined);}} />
        </Card>
    );

}