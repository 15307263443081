import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import moment from "moment";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Cfdi } from "../model/Cfdi";
import { getTenantCfdiFileUrl } from "../api/TenantCfdiApi";
import { AppContext } from "../context/AppContext";
import { getProviders } from "../api/ProviderAPI";
import { Entity, Providers, ProvidersQueryParams } from "../model/Provider";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { Workflow } from "../model/Workflow";
import { Company } from "../model/Company";
import CfdiAsignWorkflowPopUp from "../cfdi/CfdiAsignWorkflowPopUp";
import { NumberValidator, GTZeroValidator } from "../components/Validators";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { NoCfdiPayableDocumentRequest } from "../model/NoCfdiPayableDocument";
import { create } from "../api/NoCfdiPayableDocumentApi";
import Progress from "../components/Progress";

interface AdvancePaymentProps {
    cfdi?: Cfdi;
    onSuccess(payableDocument: Cfdi): any;
    onCancel(): any;
    provider_id?: string;
}

export default function AdvancePaymentPopup(props: AdvancePaymentProps) {
    const context = useContext(AppContext);
    const margin = "dense";
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [providerWorkflows, setProviderWorkflows] = useState<Workflow[]>();
    const [providerCompanies, setProviderCompanies] = useState<Company[]>();
    const [openChangeWorkflow, setOpenChangeWorkflow] = useState<boolean>(false);
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [currentProvider, setCurrentProvider] = useState<Entity>();
    const [providers, setProviders] = useState<Entity[]>();
    const numberValidator = new NumberValidator(true);
    const gtZeroValidator = new GTZeroValidator();

    const providerQueryParams = (): ProvidersQueryParams => {
        return {
            "search": "",
            "not_located_status": "",
            "efos_status": "",
            "status": "ENABLED",
            "classification": "",
            "validator_user_id": "",
            "receptor": context.session && context.session.role && context.session.user && context.session?.role?.id === 'receptor_cfdi' ? context.session.user.id : "",
            "specific_rule": "",
            "workflow_id": "",
            "make_advance_payments": "true"
        } as ProvidersQueryParams;
    };
    const [documentUrl, setDocumentUrl] = useState<string>();

    // eslint-disable-next-line
    const [providersParams, setProvidersParams] = useState<ProvidersQueryParams>(providerQueryParams);
    const [request, setRequest] = useState<NoCfdiPayableDocumentRequest>({
        provider_id: props.cfdi?.metadata.provider_id ?? undefined,
        date: props.cfdi?.date ?? new Date(),
        number_invoice: props.cfdi?.identifier ?? undefined,
        amount: props.cfdi?.total ?? 0.00,
        currency: props.cfdi?.currency ?? undefined,
        comments: props.cfdi?.description ?? undefined,
        url: props.cfdi?.metadata.pdf_url ?? undefined,
        identifier_cfdi: props.cfdi ? props.cfdi!.id : undefined,
        company_id: props.cfdi?.metadata.company_id ?? undefined,
        workflow_id: props.cfdi?.metadata.workflow_id ?? undefined,
        type: "ADVANCE_PAYMENT",
        tenant_side: !!!context.session!.provider
    } as NoCfdiPayableDocumentRequest);
    const [validations, setValidations] = useState({} as any);
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [status, setStatus] = useState<string>("loading");

    const load = () => {
        const filePromise = !!props.cfdi?.metadata.pdf_url ? getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi!.id, "pdf") : undefined;
        const currenciesPromise = getTenantCurrencies(context.session!.tenant!.id);
        const providersPromise = props.provider_id ? undefined : getProviders(context.session!.tenant!.id, 0, 0, providersParams);

        Promise.all([
            filePromise,
            currenciesPromise,
            providersPromise
        ]).then(responses => {
            if (responses[0]) {
                setDocumentUrl(responses[0]);
            } else {
                setDocumentUrl(undefined);
            }

            if (responses[1].tenant_currency_ids) {
                let listTemp = [] as AutocompleteProvider[];
                responses[1].tenant_currency_ids.forEach((item) => {
                    let temp = { title: (item + " - " + (translate("currency." + item) as string)), value: item } as AutocompleteProvider;
                    listTemp.push(temp);
                });
                setCurrenciesAutocomplete(listTemp);
            }

            if (responses[2]) {
                setProviders(responses[2].items);
            } else {
                setProviders([{ id: context.session!.provider!.id, name: context.session!.provider!.name } as Entity]);
            }

            if (props.cfdi) {
                setCurrentProvider({ id: props.cfdi!.metadata.provider_id, name: props.cfdi!.metadata.provider_name } as Entity);
            }
            if (props.provider_id) {
                setRequest({ ...request, provider_id: props.provider_id });
                setCurrentProvider({ id: context.session!.provider!.id, name: context.session!.provider!.name } as Entity);
            }

            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, []);


    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        let newRequest = { ...request, [name]: value };
        validations[name] = inputRef;
        if (name === "url") {
            setDocumentUrl(value);
            newRequest.file_name = inputRef.original_name ? inputRef.original_name : "";
        }
        setValidations(validations);
        setRequest(newRequest);
    };

    const onCurrencyFilterChanged = (name: string, value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedProvider = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, provider_id: value });
        validations[name] = inputRef;
        setValidations(validations);
        setCurrentProvider(providers!.find(prov => prov.id == value));
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setRequest({ ...request, date: date });
        }
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        if (valid && !request?.currency) {
            valid = false;
        }
        return valid;
    }

    const onSubmit = () => {
        onCreate("", "");
    };

    const onCreate = (workflowId: string, companyId: string) => {
        if (!isValid()) return;

        setSubmitting(true);
        create(context.session!.tenant!.id, { ...request, workflow_id: workflowId, company_id: companyId }).then((payableDocument: Cfdi) => {
            if (!payableDocument.metadata.company_id ||
                (!payableDocument.metadata.workflow_id && payableDocument.metadata.provider_workflows && payableDocument.metadata.provider_workflows.length > 1)) {
                setRequest(request);
                setProviderWorkflows(payableDocument.metadata.provider_workflows);
                setProviderCompanies(payableDocument.metadata.incident_companies);
                setCfdi(payableDocument);
                setOpenChangeWorkflow(true);
                setSubmitting(false);
                return;
            } else {
                props.onSuccess(payableDocument);
            }
        }).catch(error => {
            setError(error.message);
            setSubmitting(false);
        });
    }

    const onSelectedWorkflow = (workflowId: string, companyId: string, confirmChange: boolean) => {
        if (cfdi && !cfdi.metadata.company_id && !companyId) {
            setWarning(translate("companies.warning") as string);
        }
        if (cfdi && !cfdi.metadata.workflow_id && !workflowId) {
            setWarning(translate("workflows.cfdi_workflow.empty_selection") as string);
        }

        setOpenChangeWorkflow(false);
        onCreate(workflowId, companyId);
    }

    const onCloseWorkflowSelect = () => {
        setOpenChangeWorkflow(false);
        if (cfdi && !cfdi.metadata.workflow_id) {
            props.onCancel();
        }

    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    return (
        <DialogPopup open title={translate(`advance_payments.${props.cfdi ? 'title_edit' : 'title_new'}`)}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            maxWidth="sm"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancel}
            closeColor="default"
            button={
                <Button onClick={onSubmit} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.save")}
                </Button>
            }>
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="provider" name="provider"
                        label={translate("app.provider") as string}
                        value={request.provider_id}
                        required
                        margin={"dense"}
                        autocompleteOptions={providers}
                        autocompleteValue={currentProvider ?? undefined}
                        getId={provider => provider?.id}
                        getLabel={provider => provider ? provider.name : ""}
                        disabled={!!props.provider_id || submitting}
                        onValueChanged={onChangedProvider} />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker label={translate("advance_payments.date") as string} name="date"
                        onChange={onChangedDate} clearable={false} required disabled={submitting}
                        format="DD/MM/YYYY" initial={request.date ? moment(request.date).format("DD/MM/YYYY") : undefined} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="number_invoice" name="number_invoice" type="text"
                        label={translate("advance_payments.number") as string}
                        value={request.number_invoice} required disabled={submitting || !!props.cfdi}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={6}>
                    <ValidatedInput id="amount" name="amount" type="number"
                        label={translate("advance_payments.total_amount") as string}
                        value={request.amount + ""} required disabled={submitting}
                        margin={margin}
                        validators={[numberValidator, gtZeroValidator]}
                        onValueChanged={onChanged} />

                </Grid>
                <Grid item xs={6}>
                    <Box pl={1}>
                        <ValidatedInput
                            type="text"
                            id="currency"
                            name="currency"
                            label={translate("currencies.select_currency") as string}
                            required={true}
                            disabled={submitting}
                            margin={margin}
                            onValueChanged={onCurrencyFilterChanged}
                            autocompleteOptions={currenciesAutocomplete}
                            autocompleteValue={currenciesAutocomplete.find(c => c.value === request?.currency)}
                            value={request.currency}
                            getId={(el) => el?.value}
                            getLabel={(el) => el?.title} />

                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="comments" name="comments" type="text"
                        label={translate("advance_payments.description") as string}
                        value={request.comments} disabled={submitting}
                        rows={3} maxRows={3}
                        margin={margin}
                        required
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput id="url" name="url"
                        label={translate("advance_payments.file") as string}
                        value={documentUrl ? documentUrl : request.url} disabled={submitting}
                        margin={margin} path="advance_payment_file" acceptExtension=".pdf,.jpg,.jpeg,.png"
                        onValueChanged={onChanged} />
                </Grid>
            </Grid>
            {openChangeWorkflow && (providerWorkflows || providerCompanies) && cfdi &&
                <CfdiAsignWorkflowPopUp
                    workflows={providerWorkflows || []}
                    companies={providerCompanies || []}
                    isChangeWorkflow={false}
                    onCompleted={onSelectedWorkflow}
                    cfdi={cfdi}
                    confirmChange={false}
                    onClose={onCloseWorkflowSelect} />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );
}