import React, { useState } from "react";
import { Menu, ListItemIcon, Fade, Grid, ListItemText, List, ListItem, Icon } from "@material-ui/core";
import translate from "../i18n/Translator";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';

interface CfdisIssuedArchiveMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDownloadCfdis(): any;
    onDownloadExcel(): any;
    onDownloadExcelSelected(): any;
    onDownloadCfdisSelected(): any;
}

export default function CfdisIssuedArchiveMenu(props: CfdisIssuedArchiveMenuProps) {
    const [openDownloadCfdi, setOpenDownloadCfdi] = useState<boolean>(false);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);

    const handleClickDownloadCfdi = () => {
        setOpenDownloadCfdi(!openDownloadCfdi);
    };

    const handleClickExportExcel = () => {
        setOpenExportExcel(!openExportExcel);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickDownloadCfdi} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.download_cfdi")} />
                            {openDownloadCfdi ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openDownloadCfdi} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadCfdisSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadCfdis} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.all_this_view")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.export_excel")} />
                            {openExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportExcel} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onDownloadExcelSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onDownloadExcel} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.all_this_view")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
        </Menu>
    );
}