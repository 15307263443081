import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, Divider, Button, IconButton, Fab, Hidden, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ZipUploadsIcon from "@material-ui/icons/ImportExport";
import UploadIcon from "@material-ui/icons/PublishTwoTone";

import { listZipUploads, createZipUpload } from "../api/ZipUploadAPI";
import { AppContext } from "../context/AppContext";
import { ZipUpload, ZipUploads, ZipUploadsQueryParams } from "../model/ZipUpload";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import ZipUploadMenu from "./ZipUploadMenu";
import CustomBackdrop from "../components/CustomBackdrop";
import DateFormat from "../components/DateFormat";

export default function ZipUploadsList() {
    const context = useContext(AppContext);
    const history = useHistory();
    const fileInput = useRef<HTMLInputElement>(null);

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ZipUploads>();
    const [workingParams, setWorkingParams] = useState<ZipUploadsQueryParams>({
        search: search,
    } as ZipUploadsQueryParams);

    const [zipUpload, setZipUpload] = useState<ZipUpload>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [uploading, setUploading] = useState(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as ZipUploadsQueryParams;
        setWorkingParams(params);

        listZipUploads(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (zipUpload: ZipUpload) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setZipUpload(zipUpload);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setZipUpload(undefined);
    };

    const openFileImport = () => {
        fileInput.current!.click();
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setUploading(true);
        createZipUpload(context.session!.tenant!.id, event.target.files[0]).then((response) => {
            setSuccess(translate("zip_uploads.uploaded", { "found": response.found, "success": response.success }) as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setUploading(false);
            fileInput.current!.value = "";
        });
    }

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickRow = (zipUpload: ZipUpload) => {
        history.push(`/zip-uploads/${zipUpload.id}/integration-logs`);
    };

    return (
        <Pagination title={translate("zip_uploads.title")} icon={<ZipUploadsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("ZipUploadsCreate") ?
                (<Fab color="primary" size="small" title={translate("zip_uploads.upload") as string} onClick={openFileImport}>
                    <UploadIcon />
                </Fab>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("zip_uploads.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("zip_uploads.empty") as string}
                onClick={context.isGranted("IntegrationLogsRead") ? onClickRow : undefined}
                columns={[
                    {
                        title: translate("zip_uploads.file_name") as string,
                        converter: (zipUpload) => (
                            <>
                                <Ellipsis text={zipUpload.file_name} lenght={100} uppercased={false} breakWord />
                                <Hidden lgUp>
                                    <Typography component="h6" variant="caption" color="textSecondary">
                                        <DateFormat date={zipUpload.created_at} format="YYYY-MM-DD HH:mm:ss" />
                                    </Typography>
                                </Hidden>
                            </>
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("zip_uploads.user") as string,
                        converter: (zipUpload) => zipUpload.user ? `${zipUpload.user.first_name} ${zipUpload.user.last_name}` : "---",
                        fullWidth: true,
                        xs: false,
                        sm: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("zip_uploads.found") as string,
                        converter: (zipUpload) => zipUpload.found,
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 1,
                        xl: 1
                    },
                    {
                        title: translate("zip_uploads.success") as string,
                        converter: (zipUpload) => zipUpload.success,
                        fullWidth: true,
                        xs: 4,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 1
                    },
                    {
                        title: translate("zip_uploads.failed") as string,
                        converter: (zipUpload) => zipUpload.failed,
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: false,
                        xl: 1
                    },
                    {
                        title: translate("zip_uploads.created_at") as string,
                        converter: (zipUpload) => (
                            <DateFormat date={zipUpload.created_at} format="YYYY-MM-DD HH:mm:ss" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (widget) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(widget)} disabled={!context.isGranted("IntegrationLogsRead")}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".zip" />
            {zipUpload && anchorEl && context.isGranted("IntegrationLogsRead") && (
                <ZipUploadMenu zipUpload={zipUpload} anchor={anchorEl} onClose={onCloseOption} />
            )}
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <CustomBackdrop open={uploading} message={translate("zip_uploads.uploading") as string} />
        </Pagination>
    );
}