import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../../i18n/Translator";

import { Cfdi } from "../../model/Cfdi";
import { deleteCfdi } from "../../api/ProviderCfdiApi";
import DialogPopup from "../../components/DialogPopup";
import DeleteCfdiPopup from "./DeleteCfdiPopup";

interface CfdiDeletePopupProps {
    cfdi: Cfdi;
    tenantId: string;
    providerId: string;
    onCompleted(): any;
    onError(error: string): any;
    onCancelled(): any;
}

export default function CfdiDeletePopup(props: CfdiDeletePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onConfirm = () => {
        setSubmitting(true);
        deleteCfdi(props.tenantId, props.providerId, props.cfdi.id).then(() => {
            setSubmitting(false);
            props.onCompleted();
        }).catch((error) => {
            setSubmitting(false);
            props.onError(error.message);
        });
    };

    if(props.cfdi.metadata.advance_metadata?.is_complementary_advanced &&
        props.cfdi.cfdi?.conceptos.filter(concepto => concepto.description.toUpperCase().includes("CFDI POR REMANENTE DE UN ANTICIPO")).length === props.cfdi.cfdi?.conceptos.length){
        return (
            <DeleteCfdiPopup
                    submitting={submitting}
                    cfdi={props.cfdi}
                    messageDefault={""}
                    onConfirmDeleteComprobante={onConfirm}
                    onCloseConfirmDialogDelete={props.onCancelled}
                    omitCommentary={true} />
        );
    } else if(props.cfdi.metadata.advance_metadata?.is_complementary_advanced &&
        props.cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados &&
        props.cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados.length > 1){
            return (
                <DeleteCfdiPopup
                        submitting={submitting}
                        cfdi={props.cfdi}
                        messageDefault={""}
                        onConfirmDeleteComprobante={onConfirm}
                        onCloseConfirmDialogDelete={props.onCancelled}
                        omitCommentary={true} />
            );
    } else {
        return (
            <DialogPopup open
                title={translate("cfdis.popup.delete.title") as string}
                disableEscapeKeyDown={submitting}
                disableBackdropClick={submitting}
                disable={submitting}
                closeText={translate("buttons.cancel") as string}
                onClose={props.onCancelled}
                closeColor="default"
                button={
                    <Button onClick={onConfirm} variant="outlined" color="secondary" disabled={submitting}>
                        {translate("buttons.delete")}
                    </Button>
                }
            >
                <Typography variant="body2">
                    {translate(props.cfdi.metadata.credit_notes !== null && props.cfdi.metadata.credit_notes !== undefined ? 
                        "cfdis.popup.delete.descriptionWithCreditNote" : "cfdis.popup.delete.description", { "id": props.cfdi.identifier })}
                </Typography>
            </DialogPopup>
        );

    }

}