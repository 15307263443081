import React, { useContext, useState } from "react";

import translate from "../i18n/Translator";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { refuse } from "../api/ProviderPurchaseOrderAPI"
import { AppContext } from "../context/AppContext";

interface PurchaseOrderRefusePopupProps {
    purchaseOrder: PurchaseOrder;
    onClose(): any;
    onRefused(purchaseOrder: PurchaseOrder): any;
    onError(error: string): any;
}

export default function PurchaseOrderRefusePopup(props: PurchaseOrderRefusePopupProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);

    const onRefused = () => {
        setSubmitting(true);
        refuse(context.session!.tenant!.id, context.session!.provider!.id, props.purchaseOrder.id).then(response => {
            props.onRefused(response);
        }).catch(error => {
            props.onError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <ConfirmationPopup
            title={translate("purchase_orders.refuse.title") as string}
            message={translate("purchase_orders.refuse.text", { "tenant": context.session!.tenant!.name, "external_id": props.purchaseOrder!.external_id }) as string}
            onClose={props.onClose}
            button={translate("buttons.confirm") as string}
            doAction={onRefused}
            color="secondary"
            submitting={submitting} />
    );
}