import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Button, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import translate from "../i18n/Translator";
import { exportCfdiFiles } from "../api/TenantCfdiApi";
import { Cfdi } from "../model/Cfdi";
import { ExpensesAccountDocument } from "../model/ExpensesAccount";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import NumberFormat from "react-number-format";
import NewExpensesAccountDocumentPopup from "./NewExpensesAccountDocumentPopup";
import ExpensesAccountDocumentMenu from "./ExpensesAccountDocumentMenu";
import ExpensesAccountDeletePopup from "./ExpensesAccountDeletePopup";
import { AppContext } from "../context/AppContext";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar } from "../components/Snackbars";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import DownloadExportationPopup from "../components/DownloadExportationPopup";

interface ExpensesAccountDocumentsProps {
    payableDocument: Cfdi;
    documents: ExpensesAccountDocument[];
    onUpdated(payableDocument: Cfdi): any;
}

export default function ExpensesAccountDocuments(props: ExpensesAccountDocumentsProps) {
    const context = useContext(AppContext);
    const [dialog, setDialog] = useState<"cfdi" | "document" | "delete_document">();
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [document, setDocument] = useState<ExpensesAccountDocument>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [error, setError] = useState<string>();

    const onNewDocument = () => {
        setDialog("document");
    };

    const onAddedDocument = (payableDocument: Cfdi) => {
        setDialog(undefined);
        setDocument(undefined);
        props.onUpdated(payableDocument);
    };

    const onCancel = () => {
        setDialog(undefined);
    };

    const onClickedDocument = (document: ExpensesAccountDocument) => (event: React.MouseEvent<HTMLElement>) => {
        setDocument(document);
        setAnchor(event.currentTarget);
    };

    const onCloseMenu = () => {
        setDocument(undefined);
        setDialog(undefined);
        setAnchor(null);
    };

    const onDeleteDocument = () => {
        if (!document) return;
        setAnchor(null);
        setDialog("delete_document");
    };

    const onDownloadCfdi = () => {
        if (!document || !document.cfdi_id) return;

        setAnchor(null);
        setOpenBackdrop(true);

        exportCfdiFiles(context.session!.tenant!.id, document.cfdi_id).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                setExportResult(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    };

    const onCloseDownloadExportationPopup = () => {
        setDocument(undefined);
        setExportResult(undefined);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <strong>{translate("expenses_accounts.documents")}</strong>
            </Grid>
            {props.payableDocument.metadata.cfdi_status_group === "SENDING" && (
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate"]) && (
                            <Grid item xs="auto">
                                <Button size="small" variant="text" color="primary"
                                    component={Link} to={`/expenses-accounts/${props.payableDocument.id}/cfdi`}
                                    disabled={props.payableDocument.type === "HONORARIUM" && (!props.payableDocument.expenses_account || !props.payableDocument.expenses_account.honorarium)}
                                    startIcon={<AddIcon />}>
                                    {translate("expenses_accounts.add_cfdi")}
                                </Button>
                            </Grid>
                        )}
                        {context.isGrantedAny(["CustomsWithoutInvoiceCreate"]) && (
                            <Grid item xs="auto">
                                <Button size="small" variant="text" color="primary"
                                    onClick={onNewDocument}
                                    disabled={props.payableDocument.type === "HONORARIUM" && (!props.payableDocument.expenses_account || !props.payableDocument.expenses_account.honorarium)}
                                    startIcon={<AddIcon />}>
                                    {translate("expenses_accounts.add_no_cfdi")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
                <Box mx={-2}>
                    <Gridable items={props.documents}
                        loading={false}
                        empty={translate("expenses_accounts.empty_documents") as string}
                        columns={[
                            {
                                title: translate("expenses_accounts.date"),
                                converter: (document) => (
                                    <DateFormat date={document.date} format="L" />
                                ),
                                fullWidth: true,
                                xs: true,
                                sm: true,
                                md: true,
                                lg: true,
                                xl: true
                            },
                            {
                                title: translate("expenses_accounts.folio"),
                                converter: (document) => document.identifier || "---",
                                xs: false,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 1
                            },
                            {
                                title: translate("providers.rfc"),
                                converter: (document) => document.issuer.rfc || "---",
                                xs: false,
                                sm: false,
                                md: false,
                                lg: 2,
                                xl: 1
                            },
                            {
                                title: translate("providers.name"),
                                converter: (document) => document.issuer.name,
                                xs: false,
                                sm: 2,
                                md: 2,
                                lg: 2
                            },
                            {
                                title: translate("expenses_accounts.description"),
                                converter: (document) => document.description,
                                fullWidth: true,
                                xs: false,
                                sm: 3,
                                md: 3,
                                lg: 2,
                                xl: 4
                            },
                            {
                                title: (
                                    <Typography variant="body2" component="h6" align="right">
                                        <strong>{translate("expenses_accounts.total")}</strong>
                                    </Typography>
                                ),
                                converter: (document) => (
                                    <Typography variant="body2" component="h6" align="right">
                                        <NumberFormat value={document.total} prefix={`${props.payableDocument.currency} $`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    </Typography>
                                ),
                                fullWidth: true,
                                xs: 5,
                                sm: 3,
                                md: 3,
                                lg: 2
                            },
                            {
                                title: (
                                    <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                ),
                                converter: (document) => (
                                    <IconButton aria-label="options" color="default" size="small"
                                        disabled={props.payableDocument.metadata.status !== "TO_SEND" && !document.cfdi_id && !document.file_path}
                                        onClick={onClickedDocument(document)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                ),
                                fullWidth: true,
                                xs: "auto"
                            }
                        ]} />
                </Box>
            </Grid>
            {dialog === "document" && (
                <NewExpensesAccountDocumentPopup
                    payableDocument={props.payableDocument}
                    onCancel={onCancel}
                    onSuccess={onAddedDocument} />
            )}
            {dialog === "delete_document" && document && (
                <ExpensesAccountDeletePopup
                    payableDocument={props.payableDocument}
                    document={document}
                    onCancelled={onCloseMenu}
                    onCompleted={onAddedDocument} />
            )}
            {document && anchor && (
                <ExpensesAccountDocumentMenu
                    anchor={anchor}
                    payableDocument={props.payableDocument}
                    document={document}
                    onClose={onCloseMenu}
                    onDownload={onDownloadCfdi}
                    onDelete={onDeleteDocument} />
            )}
            {exportResult && exportResult.url && document && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export_single.title") as string}
                    message={translate("cfdis.popup.export_single.description", { "id": document.identifier || document.cfdi_id || document.id }) as string}
                    url={exportResult.url} onClose={onCloseDownloadExportationPopup} />
            )}
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Grid>
    );
}