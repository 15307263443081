import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";

import { Grid, Divider, Button, CircularProgress, Fab } from "@material-ui/core";
import { DiotsIcon, DownloadIcon } from "../components/Icons";

import { listDiots, createDiot, updateDiot, exportExcel } from "../api/DiotAPI";
import { listCompanies } from "../api/CompanyAPI";
import { AppContext } from "../context/AppContext";
import { Diot, DiotsQueryParams, DiotRequest, ResumeRfcDiot } from "../model/Diot";
import { CompaniesQueryParams } from "../model/Company";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { formatDateTimeToString } from "../components/DateFormat";
import moment from "moment";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from "../model/Connector";
import CustomBackdrop from "../components/CustomBackdrop";


export default function DiotsList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [generating, setGenerating] = useState<boolean>(false);
    const [data, setData] = useState<ResumeRfcDiot[]>([]);
    const [workingParams, setWorkingParams] = useState<DiotsQueryParams>({
        search: search,
        "company_id": "",
        "year": new Date().getFullYear() + "",
        "month": new Date().getMonth() + 1 + "",
    } as DiotsQueryParams);

    const [diot, setDiot] = useState<Diot>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [isCreateGranted] = useState(context.isGranted("DiotsCreate"));
    const [hasData, setHasData] = useState<boolean>(false);
    const [companyIds, setCompanyIds] = useState<string[]>([]);
    const [companyNames, setCompanyNames] = useState<string[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [monthKeys, setMonthKeys] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);


    const load = () => {
        Promise.all([
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams),
        ]).then(([companies]) => {
            setCompanyIds(companies.items.map((company) => company.id));
            setCompanyNames(companies.items.map((company) => company.name));

        }).catch(error => {
            setError(error);
        }).finally(() => { setStatus("loaded"); });

        fillMonthAndYear();
    };

    const fillDiots = (params: DiotsQueryParams) => {
        if (params.company_id === "") {
            return;
        }
        setStatus("loading");
        const offset = getOffset(page, pageSize);
        listDiots(tenantId, pageSize, offset, params).then(response => {
            if (response.items && response.total > 0) {
                setDiot(response.items[0]);
                let dataSort = response.items[0].resumen_rfc_response
                    .sort((a, b) => a.emisor_name.localeCompare(b.emisor_name));
                setData(dataSort);
                setHasData(response.total > 0);
                setGenerating(response.items[0].status === "IN_PROCESS");
                let i = 1;
                if (response.items[0].status === "IN_PROCESS") {
                    setTimeout(() => {
                        fillDiots(workingParams);
                    }, 20000 * (i + 1));
                }
            } else {
                setDiot(undefined);
                setData([]);
                setHasData(false);
                setGenerating(false);
            }
        }).catch(error => {
            setError(error);
        }).finally(() => {
            setStatus("loaded");


        });
    }

    useEffect(load, [tenantId, page, pageSize, search]);

    const fillMonthAndYear = () => {
        const monthKeys = [];
        const monthLabels = [];
        const years = [];
        const today = new Date();
        for (let i = 0; i < 12; i++) {
            monthKeys.push(`${i + 1}`);
            monthLabels.push(moment({
                year: today.getFullYear(),
                month: i,
                day: 1
            }).format("MMMM"));
        }

        for (let i = 2021; i <= today.getFullYear(); i++) {
            years.push(`${i}`);
        }

        setMonthKeys(monthKeys);
        setMonthLabels(monthLabels);
        setYears(years);
    }

    const onChangedPage = (page: number) => {
        setPage(page);
        //setData([]);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        //setData([]);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        query.set("search", workingParams.search);
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        let params = { ...workingParams, [name]: value };
        setWorkingParams(params);
        fillDiots(params);
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };



    const onGenerating = () => {
        setGenerating(true);
        let params = {
            "company_id": workingParams.company_id,
            "year": workingParams.year,
            "month": workingParams.month
        } as DiotRequest;
        if (hasData && diot !== undefined) {
            updateDiot(tenantId, diot.id, params).then(response => {
                setGenerating(true);
                setDiot(response);
                setData(response.resumen_rfc_response);
                setHasData(response.resumen_rfc_response.length > 0);
                setGenerating(response.status === "IN_PROCESS" ? true : false);
            }).catch(error => {
                setError(error);
            }).finally(() => { fillDiots(workingParams); });
        } else {
            createDiot(tenantId, params).then(response => {
                setGenerating(true);
                setDiot(response);
                setData(response.resumen_rfc_response);
                setHasData(response.resumen_rfc_response.length > 0);
                setGenerating(response.status === "IN_PROCESS" ? true : false);
            }).catch(error => {
                setError(error);
            }).finally(() => { fillDiots(workingParams); });
        }

    }

    const onDownload = () => {
        setOpenExportExcel(true);
    }

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportExcel(false);
        let params = workingParams;
        params.connector_id = connectorId;
        exportExcel(context.session!.tenant!.id, workingParams).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onCloseExporter = () => {
        setOpenExportExcel(false);
    }

    const getTextEmptyGrid = () =>{
        if( workingParams.company_id === ""){
            return translate("reports.diots.company_no_selected") as string;
        }else if(diot && diot.status === "GENERATED"){
            return translate("reports.diots.empty_search") as string;
        }else{
            return translate("reports.diots.empty") as string;
        }
    }

    return (
        <Pagination title={translate("reports.diots.title_view")} subtitle={translate("reports.diots.subtitle")}
            icon={<DiotsIcon />}
            page={page} pageSize={pageSize} count={data ? data.length : 0} total={data ? data.length : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Fab color="primary" size="small"
                    title={translate("reports.diots.button.download") as string}
                    onClick={onDownload} disabled={!hasData || generating} >
                    <DownloadIcon />
                </Fab>) : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container xs={12} direction="row" className="TableFilter" spacing={1} alignItems="center" justify="space-between" >
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="company_id" name="company_id"
                            label={translate("reports.diots.filter.company") as string}
                            value={workingParams.company_id}
                            disabled={false}
                            margin={"dense"}
                            onValueChanged={hasChanged}
                            options={companyIds} optionLabels={companyNames} />
                    </Grid>
                    <Grid item xs={2}>
                        <ValidatedInput type="text" id="month" name="month"
                            label={translate("reports.diots.filter.month") as string}
                            options={monthKeys} optionLabels={monthLabels}
                            margin="dense" disabled={false}
                            value={workingParams.month} onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={2}>
                        <ValidatedInput type="text" id="year" name="year" label={translate("reports.diots.filter.year") as string}
                            options={years}
                            margin="dense"
                            disabled={false}
                            value={workingParams.year}
                            onValueChanged={hasChanged} />
                    </Grid>
                    {context.isGrantedAny(['DiotsCreate', 'DiotsUpdate']) && 
                    <Grid item xs="auto">
                        <Button type="button" variant="contained" color="primary" size="medium" onClick={onGenerating} disabled={generating || workingParams.company_id === ""} >
                            {translate(`reports.diots.button.${hasData ? "update" : "generate"}`)}
                        </Button>
                    </Grid>}
                    <Grid item xs={3} justify="flex-start" alignItems="center" style={{ fontStyle: 'italic' }}>
                        {generating || hasData ?
                            (!generating && diot && hasData ?
                                <Ellipsis text={translate(`reports.diots.generate_at`, { "date_time": formatDateTimeToString(diot.updated_at) }) as string}
                                    uppercased={false} align={'left'} /> :
                                <span><CircularProgress color="secondary" size="1rem" /> &nbsp;&nbsp;
                                    <Ellipsis text={translate(`reports.diots.generating`) as string} lenght={100} uppercased={false} /></span>
                            )
                            : ""
                        }
                    </Grid>
                    <Grid item xs={3} >
                        <ValidatedInput type="text" id="search" name="search" label={translate("buttons.search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto"  >
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>

                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={getTextEmptyGrid()}
                columns={[
                    {
                        title: translate("reports.diots.columns.rfc") as string,
                        converter: (resume) => (
                            <Ellipsis text={resume.rfc ? resume.rfc : ""} lenght={100} uppercased={false} />
                        ),
                        xs: true
                    },
                    {
                        title: translate("reports.diots.columns.name") as string,
                        converter: (resume) => (
                            <Ellipsis text={resume.emisor_name !== undefined ? resume.emisor_name : ""} lenght={100} uppercased={false} />
                        ),
                        xs: false,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("reports.diots.columns.discounts") as string,
                        converter: (resume) => (
                            <NumberFormat value={resume.discounts} prefix="$" decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                        ),
                        xs: false,
                        sm: false,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("reports.diots.columns.taxes_withheld") as string,
                        converter: (resume) => (
                            <NumberFormat value={resume.total_tax_ret} prefix="$" decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                        ),
                        xs: false,
                        sm: false,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("reports.diots.columns.taxes_transferred") as string,
                        converter: (resume) => (
                            <NumberFormat value={resume.total_tax_tras} prefix="$" decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                        ),
                        xs: false,
                        sm: false,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("reports.diots.columns.total") as string,
                        converter: (resume) => (
                            <NumberFormat value={resume.total_paid} prefix="$" decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                        ),
                        xs: true,
                        sm: true,
                        lg: 3,
                        xl: 2
                    }
                ]} />

            {openExportExcel && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.DIOTS}
                    type={"DATA"}
                    onClose={onCloseExporter}
                    onExport={onDownloadWithConnector} />
            )}
            <CustomBackdrop open={openBackdrop} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
        </Pagination>
    );
}