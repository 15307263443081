import React, { useState, useContext } from "react";
import { Grid, Button, Link, Typography } from "@material-ui/core";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { recoverPasswordExpired } from "../api/AuthAPI";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import ExternSurface from "../components/ExternSurface";
import Box from '@material-ui/core/Box';
import { AppContext } from "../context/AppContext";
import { EmailValidator } from "../components/Validators";

export default function PasswordExpired() {
    const context = useContext(AppContext);
    const [redirect, setRedirect] = useState<string | undefined>();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [succcess, setSuccess] = useState("");

    const onSendInstructions = () => {
        setLoading(true);
        if (!context.userSecurity) {
            return;
        }

        recoverPasswordExpired({ email: context.userSecurity.email }).then(() => {
            setSuccess(translate("") as string);
            context.userSecurity = undefined;
            setRedirect(`/signin`);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (!context.userSecurity) {
        setRedirect(`/signin`);
    }

    const maskEmail = (email: string): string => {
        if (new EmailValidator().isValid(email)) {
            const split = "@";
            const mail = email.split(split);
            const text = mail[0];
            if (text.length === 2) {
                return email;
            }
            let arr: string[] = text.split("");
            for (let i = 1; i < (arr.length - 1); i++) {
                arr[i] = "*";
            }
            return arr.toString().replaceAll(",", "") + split + mail[1];
        }
        return email;
    }

    return (
        <ExternSurface title="">
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12} style={{ backgroundColor: "#f5f5f5", borderRadius: "20px", }} >
                    <Box pt={2} px={2}>
                        <Typography variant="body1" align="center" color="error" paragraph>
                            {translate("password_expired.title_pass_expired") as string}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" align="center" paragraph>
                            {translate("password_expired.subtitle_pass_expired", { "days": context?.userSecurity?.password_expiration_days }) as string}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <Box pt={2}>
                        <Typography variant="body1" align="center" paragraph>
                            {translate("password_expired.title") as string}
                        </Typography>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" align="center" paragraph>
                        {translate("password_expired.subtitle") as string}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center" paragraph>
                        {maskEmail(context?.userSecurity?.email ?? "")}
                    </Typography>
                </Grid>

                <Grid item xs={12} className="ExternButton">
                    <Button onClick={onSendInstructions} variant="contained" color="primary" fullWidth size="large" disabled={loading}>
                        {translate("password_expired.instructions_button_label")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="right">
                        <Link component={RouterLink} to="/signin">
                            <small>{translate("password_expired.return_signin")}</small>
                        </Link>
                    </Typography>
                </Grid>
            </Grid>

            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={succcess} onClose={onClosedSnackbar} />
        </ExternSurface>
    );
}
