import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { Grid, IconButton } from "@material-ui/core";
import { listAuxiliarDocuments } from '../api/AuxiliarDocumentAPI';
import { AuxiliarDocuments, AuxiliarDocumentsQueryParams } from '../model/AuxiliarDocument';
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import AuxiliarDocumentsIcon from "@material-ui/icons/FileCopy";
import Surface from "../components/Surface";
import DownloadIcon from '@material-ui/icons/GetApp';

export default function AuxiliarDocumentsPolitics() {
    const context = useContext(AppContext);
    const [data, setData] = useState<AuxiliarDocuments>();
    const [status, setStatus] = useState<string>("loading");

    const load = () => {
        setStatus("loading");
        listAuxiliarDocuments(context.session?.tenant?.id || "", 0,0, { search: "", role_id: context.session?.role.id } as AuxiliarDocumentsQueryParams).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, []);

    return (
        <Grid container alignItems="center" justify="center" alignContent="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("auxiliardocuments.title") as string} icon={<AuxiliarDocumentsIcon />} backButton>
                    <Gridable
                    items={data ? data.items : []}
                    loading={status === "loading"}
                    error={status !== "loading" && status !== "loaded" ? status : undefined}
                    empty={translate("auxiliardocuments.empty") as string}
                    columns={[
                        {
                            title: translate("auxiliardocuments.name") as string,
                            converter: (auxiliarDocument) => (
                                <Ellipsis text={ auxiliarDocument.name } lenght={ 100 } uppercased={ false } />
                            ),
                            xs: 5,
                        },
                        {
                            title: translate("auxiliardocuments.updated_at") as string,
                            converter: (field) => <DateFormat date={field.updated_at} format="DD/MM/YYYY" />,
                            xs: 5,
                        },
                        {
                            title: "",
                            converter: (field) => <IconButton
                                                    aria-label="options"
                                                    color="primary"
                                                    size="small"
                                                    component="a"
                                                    href={field.path}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    disabled={false}  >
                                                    <DownloadIcon />
                                                </IconButton>,
                            xs: 2
                        },
                    ]} />
                    </Surface>
                    
                </Grid>
            </Grid>);
}
