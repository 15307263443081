import React, { useState, useEffect, useContext } from "react";
import { AdvancePaymentsIcon } from "../components/Icons";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { Redirect, useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Grid, Fab, Icon, Button, Divider } from "@material-ui/core";
import queryString from "query-string";
import { listAdvancePayments, exporAdvancePaymentsExcel, listCfdiStatus } from "../api/TenantCfdiApi";
import Progress from "../components/Progress";
import { ExportProviders } from "../model/Provider";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { Filter, TableSelection } from "@devexpress/dx-react-grid";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { FormControl, Select, Box } from "@material-ui/core";
import { formatDateString, concatDates, stringToDate, stringToDateEnd } from "../components/DateFormat";
import { AdvancePaymentQueryParams, Cfdis, Cfdi, AdvancePaymentReport, AdvancePaymentsResponse, AdvancePaymentResponse} from "../model/Cfdi";
import { MultiselectValue } from "../components/MultiselectDropList";

export default function ReportDueInvoices() {
  const context = useContext(AppContext);
  const history = useHistory();
  const [status, setStatus] = useState<string>("loading");
  const [report, setReport] = useState<AdvancePaymentReport[]>();
  const [exportResult, setExportResult] = useState<ExportProviders>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const qs = queryString.parse(window.location.search);
  const [redirect, setRedirect] = useState<string>();
  const sDate = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
  const paramsFromQueryString = (): AdvancePaymentQueryParams => {
    return {
      "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : "",
      "related_invoice": typeof qs["related_invoice"] === "string" ? qs["related_invoice"] as string : "",
      "start_date": typeof qs["start_date"] === "string" ? qs["start_date"] as string : "",
      "end_date": typeof qs["end_date"] === "string" ? qs["end_date"] as string : "",
      "payment_start_date": typeof qs["payment_start_date"] === "string" ? qs["payment_start_date"] as string : "",
      "payment_end_date": typeof qs["payment_end_date"] === "string" ? qs["payment_end_date"] as string : "",
      "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
    } as AdvancePaymentQueryParams;
  };
  const [queryParam, setQueryParam] = useState<string>();
  const [params, setParams] = useState<AdvancePaymentQueryParams>(paramsFromQueryString);
  const [workingParams, setWorkingParams] = useState<AdvancePaymentQueryParams>(paramsFromQueryString);
  const paramsToFilters = () => {
    return [
        { columnName: 'provider', value: params.provider },
        { columnName: 'related_invoice_uuid', value: params.related_invoice },
        { columnName: 'date', value: concatDates(params.start_date, params.end_date) },
        { columnName: 'payment_date', value: concatDates(params.payment_start_date, params.payment_end_date) },
        { columnName: 'status', value: params.status },
    ] as Filter[];
};
  const [filters, setFilters] = useState<Filter[]>(paramsToFilters);
  const [elementsStatus, setElementsStatus] = useState<MultiselectValue[]>([]);

  const load = () => {
    setStatus("loading");
    pushHistory();
    setElementsStatus([]);
    listAdvancePayments(context.session!.tenant!.id, 0, 0, params).then(response => {
      if (response && response.items)
        setReport(response.items);

      listCfdiStatus(context.session!.tenant!.id, "ADVANCE_PAYMENT").then((response) => {
          setElementsStatus(response.items);
      }).catch((error) => {
          setError(error.message);
      });
    }).catch(error => {
      setStatus(error.message);
    }).finally(() => {
      setStatus("loaded");
    });
  }

  useEffect(load, [context.session, params, filters]);

  const onExport = () => {
    exporAdvancePaymentsExcel(context.session!.tenant!.id, params).then(response => {
      if (response.url) {
        setExportResult(response);
      } else {
        setSuccess(translate("cfdis.email_export") as string);
      }
    }).catch(error => {
      setError(error.message);
    });
  };

  const setFiltersHandler = (filters: Filter[]) => {
    setFilters(filters);
    setParamsFromfilters(filters);
  };

  const setParamsFromfilters = (filters: Filter[]) => {
    let temp = params;
    filters.forEach(filter => {
      if (filter.value !== undefined) {
        switch (filter.columnName) {
          case "provider":
                temp.provider = filter.value;
                break;
          case "date":
              let dateAt = filter.value.split(" - ");
              if (dateAt.length === 2) {
                temp.start_date = formatDateString(dateAt[0]);
                temp.end_date = formatDateString(dateAt[1]);
              } else {
                temp.start_date = "";
                temp.end_date = "";
              }
              break;
          case "payment_date":
              let paymentDateAt = filter.value.split(" - ");
              if (paymentDateAt.length === 2) {
                temp.payment_start_date = formatDateString(paymentDateAt[0]);
                temp.payment_end_date = formatDateString(paymentDateAt[1]);
              } else {
                temp.payment_start_date = "";
                temp.payment_end_date = "";
              }
              break;
          case "related_invoice_uuid":
              temp.related_invoice = filter.value;
              break;
          case "status":
              temp.status = filter.value;
              break;
          default: break;
        }
      }
    });
    setWorkingParams(temp);
  };

  const onCloseSnackbars = () => {
    setError(undefined);
    setSuccess(undefined);
  };

  const onCloseDownloadProviders = () => {
    setExportResult(undefined);
  }

  const tableColumnExtensions = [
    { columnName: 'folio', wordWrapEnabled: true, width: 100, align: 'left' },
    { columnName: 'provider', wordWrapEnabled: true, width: 150, align: 'left' },
    { columnName: 'company', wordWrapEnabled: true, width: 150, align: 'left' },
    { columnName: 'description', width: 180, align: 'left' },
    { columnName: 'balance', wordWrapEnabled: true, width: 110, align: 'right' },
    { columnName: 'currency', wordWrapEnabled: true, width: 100, align: 'left' },
    { columnName: 'date', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'authorized_date', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'authorizer_name', wordWrapEnabled: true, width: 120 },
    { columnName: 'related_invoice_uuid', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'payment_date', wordWrapEnabled: true, width: 120, align: 'center' },
    { columnName: 'status', wordWrapEnabled: true, width: 100, align: 'center' },
  ] as any;

  const currencyColumns = ['balance'];

  const dateColumns = ['authorized_date', 'payment_date', 'date'];

  const filtersExclude = ['folio', 'company', 'description', 'balance', 'authorized_date', 'authorizer_name', 'currency'];

  const textColumns = ['folio', 'company', 'provider', 'description', 'currency', 'authorizer_name', 'related_invoice_uuid', 'status'];

  const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'folio', 'company', 'provider']) as any;

  const clickRowColumns = ['folio'];

  const NormalTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider {...props} />);

  const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
      const onChange = (event: any) => {
          const { value: targetValue } = event.target;
          if (targetValue.trim() === '') {
              params.onValueChange("");
              return;
          }
          params.onValueChange(targetValue);
      };
      return (
          <Box px={1}>
              <FormControl >
                  <Select native style={{
                      fontSize: "10px"
                  }}
                      labelId="is_default"
                      id="is_default"
                      value={params.value ? params.value : ''}
                      onChange={onChange} >
                      <option key={"ALL"} value={""}>{translate("all") as string}</option>
                      {elementsStatus.map((value) => {
                          return <option key={value.title} value={value.value}>{value.title}</option>
                      })}
                  </Select>
              </FormControl>
          </Box>
      );
  };

  const customPlugins = [
      <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
      <NormalTypeProvider for={["status"]} editorComponent={StatusFilterEditor} />,
    ];

  const onClickedRow = (cfdi: AdvancePaymentResponse) => {
    pushHistory();
    setRedirect(`/cfdis/${cfdi.id}/details?referer=report-advance&${queryParam}`);
  };

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  const onAppliedFilter = () => {
    pushHistory();
    setParams(workingParams);
  };

  const pushHistory = () => {
    let qs = queryString.parse(window.location.search);
    qs["start_date"] = workingParams.start_date ? workingParams.start_date : "";
    qs["end_date"] = workingParams.end_date ? workingParams.end_date : "";
    qs["payment_start_date"] = workingParams.payment_start_date ? workingParams.payment_start_date : "";
    qs["payment_end_date"] = workingParams.payment_end_date ? workingParams.payment_end_date : "";
    qs["provider"] = workingParams.provider ? workingParams.provider : "";
    qs["related_invoice"] = workingParams.related_invoice ? workingParams.related_invoice : "";
    qs["status"] = workingParams.status ? workingParams.status : "";

    let url = window.location.pathname + "?" + queryString.stringify(qs);
    setQueryParam(queryString.stringify(qs));
    history.push(url);
  };

  const getStatus = (cfdi: AdvancePaymentResponse) => {
    if (cfdi.custom_status) {
      return cfdi.custom_status;
    }
    return translate(`cfdis.history.status.${cfdi.status}`) as string;
  };

  if (status === "loading") {
    return (<Progress />);
  }

  return (
    <Surface
      title={translate("reports.advance_payment_report.title") as string}
      icon={<AdvancePaymentsIcon />}
      className="PaperPagination" titleActions={
        <Grid container alignItems="center" justify="flex-end" spacing={1}>
          {context.isGrantedAny(["AdvancePaymentsReportExport"]) && report && report.length > 0 &&
            <Grid item xs="auto">
              <Fab color="primary" size="small" title={translate("reports.due_invoices.export") as string} onClick={onExport} >
                <Icon className="fas fa-file-excel" fontSize="small" />
              </Fab>
            </Grid>}
        </Grid>
      }>
      <Divider />
      <div className="focaltec-small">
        <GridDx
          columns={[
            {
              name: 'folio',
              title: translate('reports.advance_payment_report.columns.folio') as string,
              getCellValue: (row: any) => row.serie_folio
            },
            {
              name: 'provider',
              title: translate('reports.advance_payment_report.columns.provider') as string,
              getCellValue: (row: any) => row.provider_name
            },
            {
              name: 'company',
              title: translate('reports.advance_payment_report.columns.company') as string,
              getCellValue: (row: any) => row.company_name
            },
            {
              name: 'description',
              title: translate('reports.advance_payment_report.columns.description') as string
            },
            {
              name: 'balance',
              title: translate('reports.advance_payment_report.columns.balance') as string
            },
            {
              name: 'currency',
              title: translate("reports.advance_payment_report.columns.currency") as string
            },
            {
              name: 'date',
              title: translate("reports.advance_payment_report.columns.date") as string
            },
            {
              name: 'authorized_date',
              title: translate("reports.advance_payment_report.columns.authorized_date") as string
            },
            {
              name: 'authorizer_name',
              title: translate("reports.advance_payment_report.columns.authorizer_name") as string,
              getCellValue: (row: any) => row.authorizer_name || "---"
            },
            {
              name: 'related_invoice_uuid',
              title: translate("reports.advance_payment_report.columns.related_invoice_uuid") as string,
              getCellValue: (row: any) => row.related_invoice_uuid || "---"
            },
            {
              name: 'payment_date',
              title: translate("reports.advance_payment_report.columns.payment_date") as string
            },
            {
              name: 'status',
              title: translate("reports.advance_payment_report.columns.status") as string,
              getCellValue: (row: any) => getStatus(row)
            }
          ]}
          loading={status === "loading"}
          rows={report ? report : []}
          amountCurrencyColumns={currencyColumns}
          dateColumns={dateColumns}
          columnsFormat={tableColumnExtensions}
          textColumns={textColumns}
          clickRowColumns={clickRowColumns}
          filters={filters}
          setFiltersHandler={setFiltersHandler}
          onClickRow={onClickedRow}
          leftColumns={leftColumns}
          customFormatColumns={customPlugins}
        />
      </div>
      {exportResult && exportResult.url && (
        <DownloadExportationPopup
          title={translate("providers.pending_payments.export.title") as string}
          message={translate("providers.pending_payments.export.message", { "total": exportResult.total }) as string}
          url={exportResult.url}
          onClose={onCloseDownloadProviders} />
      )}
      <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </Surface>
  );
}
