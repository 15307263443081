import { callAPI, HTTPMethod } from "./API";
import { Roles, RolesQueryParams, RolesActionExclusion, RoleAdminRequest } from "../model/Role";

export async function listRoles(page: number, offset: number, params: RolesQueryParams): Promise<Roles> {
    return await callAPI(`/role-admin`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: page.toString(), 
            offset: offset.toString(),
            search: params.search || "",
            roleId: params.roleId || "", 
            tenantId: params.tenantId 
        }
    });
}

export async function getRoleExclusions(tenantId: string, roleId: string): Promise<RolesActionExclusion> {
    return await callAPI(`/role-admin/tenants/${tenantId}/role/${roleId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateRoleAdmin(tenantId: string, roleId: string, request: RoleAdminRequest): Promise<RolesActionExclusion> {
    return await callAPI(`/role-admin/tenants/${tenantId}/role/${roleId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}
