import React, { useState, useContext, useRef, useEffect } from "react";
import clsx from 'clsx';
import { createStyles, Theme, makeStyles, useTheme } from "@material-ui/core/styles";

import { Drawer, AppBar, CssBaseline, Toolbar, Typography, Divider, Hidden, IconButton, Grid, Avatar, Box } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/HelpTwoTone";

import Gravatar from "react-gravatar";
import CloseIcon from "@material-ui/icons/Close";

import AppRouter, { AppMenu } from "../router/AppRouter";
import { AppContext } from "./AppContext";
import translate from "../i18n/Translator";
import TenantSwitch from "./TenantSwitch";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar, InfoSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import AccountMenu from "./AccountMenu";
import { loadCSS } from 'fg-loadcss';
import Chat from "../components/Chat";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import AnnouncementCenterModal from '../Announcement/AnnouncementCenterModal'
import { Announcement } from '../model/Announcement';
import ShowAnnouncementModal from "../Announcement/ShowAnnouncementModal";
import { TermCondition } from "../model/TermsConditions";
import ShowTermsConditionsModal from "../terms_conditions/ShowTermsConditionsModal";
import { FocaltecAnnouncement } from "../model/FocaltecAnnouncement";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import ReactHtmlParser from "html-react-parser";

const drawerWidth = 260;
const avatarSize = 24;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            background: theme.palette.background.paper,
            borderWidth: 0,
            borderBottomWidth: "1px",
            [theme.breakpoints.up("md")]: {
            },
        },
        drawer: {
            [theme.breakpoints.up("md")]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            paddingTop: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
            overflow: 'hidden'
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        menuButton: {
            marginRight: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        hiddenButton: {
            marginRight: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        title: {
            flexGrow: 1,
        },
        avatar: {
            width: avatarSize,
            height: avatarSize
        },
        toolbar: theme.mixins.toolbar
    }),
);

export default function InternApp(props: InternAppProps) {
    const context = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [desktopOpen, setDesktopOpen] = useState<boolean>(() => {
        let item = localStorage.getItem("ShowDrawer");
        let value = item !== "false";
        localStorage.setItem("ShowDrawer", `${value}`);
        return value;
    });
    const [blocked, setBlocked] = useState<boolean>(false);
    const [notification, setNotification] = useState<string>();
    const [openAnnouncementModal, setOpenAnnouncementModal] = useState<boolean>();
    const [announcementsNotReaded, setAnnouncementsNotReaded] = useState<number>();
    const [announcement, setAnnouncement] = useState<Announcement>();
    const [focaltecAnnouncements, setFocaltecAnnouncements] = useState<FocaltecAnnouncement[]>([]);
    const [termsConditions, setTermsConditions] = useState<TermCondition>();

    const [accountOpen, setAccountOpen] = useState(false);
    const accountAnchorRef = useRef<HTMLButtonElement>(null);
    const prevAccountOpen = useRef(accountOpen);

    const [wsReload, setWsReload] = useState<number>(0);

    const [focaltecAnnuncementsReload, setfocaltecAnnuncementsReload] = useState<number>(0);

    useEffect(() => {
        if (!context.session?.websocket) return undefined;

        let websocket = context.session.websocket;
        if (websocket.name !== "awsapigatewayws") return undefined;

        const ws = new WebSocket(websocket.endpoint + '&user_id=' + context.session!.secret_id);

        ws.onmessage = (event) => {
            const payload = JSON.parse(event.data);
            if (payload.type === "BLOCKED_USER") {
                setBlocked(true);
            } else {
                setNotification(payload.message);
            }
        };

        ws.onclose = (event) => {
            if (event.code === 4999 && event.reason === 'unmounted') {
                return;
            }

            setTimeout(() => {
                setWsReload(wsReload + 1);
            }, 5000);
        };

        ws.onerror = function (error) {
            setTimeout(() => {
                setWsReload(wsReload + 1);
            }, 5000);
        };

        return () => {
            ws.close(4999, 'unmounted');
        };
    }, [wsReload]);

    const onSignOut = () => {
        props.onSignOut();
    };

    const handleToggleAccount = () => {
        setAccountOpen(prevOpen => !prevOpen);
        setAnnouncement(undefined);
        setTermsConditions(undefined);
    };

    const onCloseAccount = () => {
        setAccountOpen(false);
    };

    const onOpenAnnouncementModal = () => {
        setOpenAnnouncementModal(true);
    };

    useEffect(() => {
        if (prevAccountOpen.current === true && accountOpen === false) {
            accountAnchorRef.current!.focus();
        }
        prevAccountOpen.current = accountOpen;
    }, [accountOpen, props.error, context]);

    useEffect(() => {
        let href = window.location.pathname;
        if (context.session && context.session?.announcements && context.session?.announcements.length > 0) {
            let annActive = context.session.announcements.filter(ann => !ann.readed);
            let annActiveSize = annActive.length;
            setAnnouncementsNotReaded(annActiveSize);
            if (annActiveSize >= 1 && href.endsWith('/')) {
                setAnnouncement(annActive[annActiveSize - 1]);
            }
        }
        if (context.session && context.session?.terms_conditions_unaccepted && context.session?.terms_conditions_unaccepted.active) {
            setTermsConditions(context.session?.terms_conditions_unaccepted);
        }
        if(context.session && context.session.focaltec_announcements && context.session.focaltec_announcements.length > 0){
            let idsReaded = localStorage.getItem("ReadedFocaltecAnnouncements") as string;
            if(idsReaded && idsReaded !== "" && idsReaded !== " , "){
                setFocaltecAnnouncements(context.session.focaltec_announcements.filter(c => idsReaded.search(new RegExp(c.id, "i")) <= -1));
            } else {
                setFocaltecAnnouncements(context.session.focaltec_announcements);
            }
        }
    }, [context, focaltecAnnuncementsReload]);

    const handleMobileToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    React.useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.15.3/css/all.css',
            document.querySelector('#font-awesome-css') as HTMLElement,
        );

        return () => {
            node.parentNode!.removeChild(node);
        };
    }, []);

    const handleDesktopToggle = () => {
        let value = !desktopOpen;
        setDesktopOpen(!desktopOpen);
        localStorage.setItem("ShowDrawer", `${value}`);
    };

    const drawer = (
        <div>
            <Toolbar className={classes.toolbar}>
                <Grid container justify="center">
                    <Grid item xs="auto">
                        <img src="/logo_white.png" alt="logo" className="InternLogo" />
                    </Grid>
                </Grid>
            </Toolbar>
            <Divider style={{paddingTop: focaltecAnnouncements ? `${focaltecAnnouncements.length * 10}%` : "0%"}} />
                <TenantSwitch />
            {context.session?.provider && (
                <Grid container justify="center">
                    <Grid item xs={10}>
                        <Typography variant="caption" component="h6" color="textSecondary">
                            {translate("app.provider")}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" component="h6" color="textPrimary">
                            {context.session.provider.name}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {context.session?.customer && (
                <Grid container justify="center">
                    <Grid item xs={10}>
                        <Typography variant="caption" component="h6" color="textSecondary">
                            {translate("app.customer")}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" component="h6" color="textPrimary">
                            {context.session.customer.name}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <AppMenu />
        </div>
    );

    const onReadFocaltecAnnouncement = (id: string) => {
        if(localStorage.getItem("ReadedFocaltecAnnouncements")){
            localStorage.setItem("ReadedFocaltecAnnouncements", `${localStorage.getItem("ReadedFocaltecAnnouncements")} , ${id} ,`);
        } else {
            localStorage.setItem("ReadedFocaltecAnnouncements", `${id} ,`);
        }
        
        focaltecAnnouncements.
        filter(c => c.id === id).
        forEach(obsolete => focaltecAnnouncements.
        splice(focaltecAnnouncements.indexOf(obsolete), 1));
        setfocaltecAnnuncementsReload(focaltecAnnuncementsReload +1);
    }

    const router = (<AppRouter />);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} color="primary" variant="outlined">
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        {focaltecAnnouncements && focaltecAnnouncements.length > 0 &&
                            focaltecAnnouncements.map((item: FocaltecAnnouncement, index: number) => (
                                <Grid container style={{
                                    color: "white", backgroundColor: item.type === "SUCCESS" ? "#32CD32" :
                                        item.type === "DANGER" ? "#FF0000" :
                                            item.type === "WARNING" ? "#f69450" : "#3d94c5"
                                }}>
                                    <Grid item xs={11}>
                                        <Typography variant="body1" component="h5" color="initial" align="center">
                                            {ReactHtmlParser(item.message)}
                                        </Typography>
                                    </Grid>

                                    {!item.permanent &&
                                        <Grid item xs={1}>
                                            <Grid container style={{paddingRight: "10%"}} justify="flex-end">
                                                <IconButton size="small" color="inherit" onClick={() => { onReadFocaltecAnnouncement(item.id); }} >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                <Toolbar className={classes.toolbar}>
                    <IconButton aria-label="open drawer" edge="start" color="inherit" onClick={handleMobileToggle} className={classes.menuButton} >
                        <MenuIcon />
                    </IconButton>
                    <IconButton aria-label="open drawer" edge="start" color="inherit" onClick={handleDesktopToggle} className={classes.hiddenButton} >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.title}>
                        <img src="/logo_white.png" alt="logo" className="InternLogo" />
                    </div>
                    {context.session!.role.provider ?
                        <Box px={1}>
                            <IconButton color="inherit" onClick={onOpenAnnouncementModal}>
                                {announcementsNotReaded ?
                                    <Badge badgeContent={announcementsNotReaded} color="primary" >
                                        <NotificationsIcon />
                                    </Badge>
                                    : <NotificationsIcon />}
                            </IconButton>
                        </Box>
                        : undefined}

                    <IconButton color="inherit" size="small"
                        component="a" href={`https://ayuda.focaltec.com/support/solutions/folders/${context.session!.role.provider ? "43000573377" : "43000573390"}`}
                        target="_blank" rel="noopener noreferrer">
                        <HelpIcon />
                    </IconButton>
                    <IconButton ref={accountAnchorRef} aria-controls={accountOpen ? "account-menu" : undefined} edge="end" aria-haspopup="true" color="inherit" size="small" onClick={handleToggleAccount}>
                        <Avatar className={classes.avatar}>
                            <Gravatar email={context.session!.user.email} size={avatarSize} default="identicon" />
                        </Avatar>
                    </IconButton>
                    {accountOpen && (
                        <AccountMenu anchorRef={accountAnchorRef} onClose={onCloseAccount} onSignOut={onSignOut} />
                    )}
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden mdUp implementation="css">
                    <Drawer variant="temporary" anchor={theme.direction === "rtl" ? "right" : "left"} open={mobileOpen} onClose={handleMobileToggle}
                        classes={{ paper: classes.drawerPaper }} ModalProps={{ keepMounted: true }}>
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer variant="persistent" open={desktopOpen} classes={{ paper: classes.drawerPaper, }}>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>

            <main style={{paddingTop: focaltecAnnouncements ? `${focaltecAnnouncements.length * 2}%` : "0%"}} className={clsx(classes.content, {
                [classes.contentShift]: desktopOpen,
            })}>
                <div className={classes.toolbar} />
                <Grid container className={desktopOpen ? "WithDrawer" : undefined}>
                    <Grid item xs={12}>
                        {router}
                    </Grid>
                </Grid>
            </main>

            {context.session && context.session.has_chat && (
                <Chat
                    user_id={context.session.user.id}
                    user_email={context.session.user.email}
                    user_name={context.session.user.name}
                    user_phone={context.session.user.phone}
                />
            )}
            <ErrorSnackbar message={props.error} onClose={() => props.onCleanError()} />
            <InfoSnackbar message={notification} onClose={() => setNotification(undefined)} />
            <CustomBackdrop open={props.backdrop} />
            <DialogPopup open={blocked} title={translate("users.blocked.title")} onClose={onSignOut} closeText={translate("buttons.signout") as string}>
                {translate("users.blocked.description")}
            </DialogPopup>
            {openAnnouncementModal &&
                <AnnouncementCenterModal data={context.session?.announcements} onClose={() => setOpenAnnouncementModal(false)} />
            }
            {announcement &&
                <ShowAnnouncementModal announcement={announcement}
                    onClose={() => setAnnouncement(undefined)} isProvider={true}
                />
            }
            {termsConditions && termsConditions.active &&
                <ShowTermsConditionsModal termnCondition={termsConditions}
                    onClose={() => setTermsConditions(undefined)} onFinish={onSignOut}
                />
            }
        </div>
    );
}

export interface InternAppProps {
    backdrop: boolean;
    error?: string;
    onCleanError(): any;
    onSignOut(): any;
}
