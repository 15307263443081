import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import { ProviderClassification } from "../model/ProviderClassification";

interface ProviderClassificationsProps {
    classification: ProviderClassification;
    anchor: HTMLElement | null;
    onClose(): any;
    onDeleteClassification(): any;
}

export default function ProviderClassificationsMenu(props: ProviderClassificationsProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="classification-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("ProviderClassificationsUpdate") &&
                <MenuItem button component={Link} to={`/provider-classifications/${props.classification.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            <Divider />
            {context.isGranted("ProviderClassificationsDelete") &&
                <MenuItem button onClick={props.onDeleteClassification} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 