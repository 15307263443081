import React, { useState, ChangeEvent, useEffect, ReactNode } from "react";
import { Box, Grid, IconButton } from "@material-ui/core";
import Gridable from "../components/Gridable";
import { ErrorSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import { ServiceProduct } from "../model/ServiceProduct";
import Autosuggest, { SuggestionsFetchRequestedParams, SuggestionSelectedEventData } from 'react-autosuggest';
import { getServiceProducts } from "../api/ServiceProductCatalogApi";
import { TextField } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ProductServiceMenu from "./ProductServiceMenu";

interface ProductServicesProps {
    selected: ServiceProduct[];
    disabled: boolean;
    showTable: boolean;
    placeholder: string;
    onChange?(selected: ServiceProduct): any;
    update(selected: ServiceProduct[]): any;
    accessory?: ReactNode;
}

export default function ProductsServices(props: ProductServicesProps) {
    const [error, setError] = useState<string>();
    const [selected, setSelected] = useState<ServiceProduct[]>([]);
    const [disabled, setDisabled] = useState(false);

    const [suggestions, setSuggestions] = useState<ServiceProduct[]>([]);
    const [request, setRequest] = useState<string>("");

    const [serviceProduct, setServiceProduct] = useState<ServiceProduct>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setSelected(props.selected);
        setDisabled(props.disabled);
    }, [props.selected, props.disabled]);

    const onSuggestionsFetchRequested = (params: SuggestionsFetchRequestedParams) => {
        getSuggestions(params.value);
    };

    const getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0) return;

        getServiceProducts(5, 0, inputValue).then((response) => {
            setSuggestions(response.items);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const renderSuggestion = (suggestion: ServiceProduct) => (
        <div>
            {suggestion.code} {suggestion.description}
        </div>
    );

    const onSuggestionSelected = (event: React.FormEvent<any>, data: SuggestionSelectedEventData<ServiceProduct>) => {
        const filtered = selected.filter((serviceProduct) => serviceProduct.code === data.suggestion.code);
        if (filtered.length > 0) return;

        selected.push(data.suggestion);
        setSelected(selected);
        props.update(selected);
        if (props.onChange && data.suggestion) {
            props.onChange(data.suggestion);
        }
        getSuggestionValue({} as ServiceProduct);
    };

    const getSuggestionValue = (suggestion: ServiceProduct) => {
        return suggestion.code;
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>, params: Autosuggest.ChangeEvent) => { 
        setRequest(params.newValue);
    };

    const onDelete = () => {
        if (!serviceProduct) return;

        const edited = selected.filter(el => el.code !== serviceProduct.code);
        setSelected(edited);
        props.update(edited);

        setAnchorEl(null);
        setServiceProduct(undefined);
    };

    const renderInputComponent = (inputProps: any) => (
        <Grid item xs={12}>
            <TextField type="text" variant="outlined" margin="dense" fullWidth {...inputProps} />
        </Grid>
    );

    const onClickedOptions = (serviceProduct: ServiceProduct, anchorEl: null | HTMLElement) => {
        setAnchorEl(anchorEl);
        setServiceProduct(serviceProduct);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setServiceProduct(undefined);
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={props.showTable ? 6 : 12}>
                        <div style={disabled ? { "pointerEvents": "none", "opacity": "0.4", "overflow": "hidden" } : {}}>
                            <Box px={2}>
                                <Autosuggest suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    onSuggestionSelected={onSuggestionSelected}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    renderInputComponent={renderInputComponent}
                                    inputProps={{
                                        placeholder: props.placeholder,
                                        value: request,
                                        onChange: onChange
                                    }}
                                />
                            </Box>
                        </div>
                    </Grid>
                    {props.accessory && (
                        <Grid item xs={12} md={6}>
                            {props.accessory}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {props.showTable &&
                <Grid item xs={12}>
                    <div style={disabled ? { "pointerEvents": "none", "opacity": "0.4", "overflow": "hidden" } : {}}>
                        <Gridable
                            items={selected}
                            loading={false}
                            empty={translate("specific_reception_rule.service_product.empty") as string}
                            columns={[
                                {
                                    title: translate("reception_rule.key") as string,
                                    converter: (rule) => rule.code,
                                    xs: true
                                },
                                {
                                    title: translate("reception_rule.description") as string,
                                    converter: (rule) => rule.description,
                                    xs: true
                                },
                                {
                                    title: (
                                        <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    ),
                                    converter: (rule) => (
                                        <IconButton aria-label="options" color="default" size="small" onClick={(event: React.MouseEvent<HTMLElement>) => onClickedOptions(rule, event.currentTarget)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    ),
                                    justify: "flex-end",
                                    xs: true,
                                    sm: true,
                                    md: true,
                                    lg: true,
                                    xl: true
                                }
                            ]}
                        />
                    </div>
                </Grid>
            }
            {serviceProduct && anchorEl && (
                <ProductServiceMenu anchor={anchorEl} onClose={onCloseOption} onDelete={onDelete} />
            )}
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </Grid>
    );
}