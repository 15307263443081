import React, { useState, useEffect, useContext, useRef } from "react";
import queryString from "query-string";
import { Redirect, useHistory, Link } from "react-router-dom";
import { getPayments, deletePayment, sendNotificationsPendingCfdisPayment, exportExcelPayments, importPayments, isBlockedDelete } from "../api/PaymentApi";
import {get as getConfiguration} from "../api/TenantConfigurationApi";
import { Grid, Divider, Fab, IconButton } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Payment, Payments, PaymentListParams, Statuses, PaymentDeleteRequest, ImportPaymentSummary } from "../model/Payment";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentPendingCfdiMenu from "./PaymentPendingCfdiMenu";
import { PaymentGridProps } from "./PaymentList";
import RejectPaymentPopup from "./RejectPaymentPopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaymentsGlobalMenu from "./PaymentsGlobalMenu";
import SendNotificationsConfirmPopup from "../components/ConfirmationPopup";
import AddIcon from "@material-ui/icons/Add";
import CustomBackdrop from "../components/CustomBackdrop";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import Progress from "../components/Progress";

import { Filter } from '@devexpress/dx-react-grid';
import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { formatDateString, concatDates } from "../components/DateFormat";
import ConnectorTemplateDownloader from "../connectors/ConnectorTemplateDownloader";
import { Family } from "../model/Connector";
import ImportPaymentResumePopup from "../payments/ImportPaymentResumePopup";
import RequestClarification from "../clarifications/RequestClarification";

export default function ListPaymentsPendingCfdi(props: PaymentGridProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): PaymentListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "payment_status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
        } as PaymentListParams;
    };

    const fileInput = useRef<HTMLInputElement>(null);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Payments>();
    const [params, setParams] = useState<PaymentListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<PaymentListParams>(paramsFromQueryString);
    const [payment, setPayment] = useState<Payment>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [openConfirmRejected, setOpenConfirmRejected] = useState<boolean>();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openConfirmSendNotificationsDialog, setOpenConfirmSendNotificationsDialog] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [importSummary, setImportSummary] = useState<ImportPaymentSummary>();
    const [downloadTemplate, setDownloadTemplate] = useState(false);
    const [clarification, setClarification] = useState<boolean>(false);
    const [paymentsSelected, setPaymentsSelected] = useState<Payment[]>([]);
    const [customTemplate, setCustomTemplate] = useState<boolean>(false);
    const [enableRejectPayment, setEnableRejectPayment] = useState<boolean>(false);

    const [selection, setSelection] = useState<(number | string)[]>([]);
    const paramsToFilters = () => {
        return [
            { columnName: "operation_number", value: params.operation_number },
            { columnName: "provider_name", value: params.provider_name },
            { columnName: "comprobantes", value: params.comprobantes },
            { columnName: "total_amount", value: params.total_amount },
            { columnName: "payment_date", value: concatDates(params.payment_start_date, params.payment_end_date) },
            { columnName: "deadline_payment_cfdi", value: concatDates(params.start_deadline_payment_cfdi, params.end_deadline_payment_cfdi) },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        params.payment_status = Statuses[0] + "," + Statuses[4] + "," + Statuses[5];
        setStatus("loading");
        if (props.success && isSuccess) {
            setSuccess(props.success);
            setIsSuccess(false);
        }
        if (context.isGranted("PaymentsRead")) {
            getPayments(context.session!.tenant!.id, pageSize, pageSize * page, params).then((response) => {
                setData(response);
                if (response.custom_template) {
                    setCustomTemplate(response.custom_template);
                }
            }).catch((error) => {
                setStatus(error.message);
            });
        }
        
        getConfiguration(context.session!.tenant!.id).then((response) => {
            let disableRejectPaymentConfig = context.session!.provider ? response.configurations.find(config => config.property_name === 'disable_reject_payment_provider')!.value : response.configurations.find(config => config.property_name == 'disable_reject_payment_tenant_users')!.value;
            
            setEnableRejectPayment(!response.has_budgets && disableRejectPaymentConfig == 'false');
        }).catch((error) => {
            setStatus(error.message);
        });
        setStatus("loaded");
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "operation_number":
                        temp.operation_number = filter.value;
                        break;
                    case "payment_date":
                        let paymentDates = filter.value.split(" - ");
                        if (paymentDates.length === 2) {
                            temp.payment_start_date = formatDateString(paymentDates[0]);
                            temp.payment_end_date = formatDateString(paymentDates[1]);
                        } else {
                            temp.payment_start_date = "";
                            temp.payment_end_date = "";
                        }
                        break;
                    case "provider_name":
                        temp.provider_name = filter.value;
                        break;
                    case "comprobantes":
                        temp.comprobantes = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    case "deadline_payment_cfdi":
                        let deadlinesPayment = filter.value.split(" - ");
                        if (deadlinesPayment.length === 2) {
                            temp.start_deadline_payment_cfdi = formatDateString(deadlinesPayment[0]);
                            temp.end_deadline_payment_cfdi = formatDateString(deadlinesPayment[1]);
                        } else {
                            temp.start_deadline_payment_cfdi = "";
                            temp.end_deadline_payment_cfdi = "";
                        }
                        break;
                    case "total_amount":
                        temp.total_amount = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
        pushHistory();
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["payment_status"] = workingParams.payment_status ? workingParams.payment_status : "";
        qs["operation_number"] = workingParams.operation_number ? workingParams.operation_number : "";
        qs["provider_name"] = workingParams.provider_name ? workingParams.provider_name : "";
        qs["comprobantes"] = workingParams.comprobantes ? workingParams.comprobantes : "";
        qs["total_amount"] = workingParams.total_amount ? workingParams.total_amount : "";
        qs["payment_start_date"] = workingParams.payment_start_date ? workingParams.payment_start_date : "";
        qs["payment_end_date"] = workingParams.payment_end_date ? workingParams.payment_end_date : "";
        qs["start_deadline_payment_cfdi"] = workingParams.start_deadline_payment_cfdi ? workingParams.start_deadline_payment_cfdi : "";
        qs["end_deadline_payment_cfdi"] = workingParams.end_deadline_payment_cfdi ? workingParams.end_deadline_payment_cfdi : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelection(selected);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        pushHistory();
        setParams(workingParams);
    };

    const onClickedOptions = (payment: Payment) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPayment(payment);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPayment(undefined);
    };

    const onOpenConfirmRejected = () => {
        setAnchorEl(null);
        isBlockedDelete(context.session!.tenant!.id, payment!.id).then((response) => {
            if (response.content) {
                setError(translate("payment.pending_cfdi.blocked_delete") as string);
            } else {
                setOpenConfirmRejected(true);
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onCloseConfirmRejected = () => {
        setAnchorEl(null);
        setOpenConfirmRejected(false);
    };
    const onDelete = (comment: string) => {
        setOpenBackdrop(true);
        setOpenConfirmRejected(false);
        deletePayment(context.session!.tenant!.id, payment!.id, { comments: comment } as PaymentDeleteRequest).then((_) => {
            setOpenBackdrop(false);
            setSuccess(translate("payment.success_delete", { "payment": payment!.operation_number }) as string);
            load();
        }).catch((error) => {
            setOpenBackdrop(false);
            setStatus(error.message);
            setError(error.message);
        }).finally(() => {

            setPayment(undefined);
        });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onOpenConfirmSendNotificationsDialog = () => {
        setGridAnchorEl(null);
        if (selection && selection.length > 0) {
            setOpenConfirmSendNotificationsDialog(true);

        } else {
            setWarning(translate("payment.pending_cfdi.send_notifications.no_selected") as string);
        }
    }

    const onConfirmSendNotificationsComprobante = (razon: string) => {
        setOpenConfirmSendNotificationsDialog(false);
        if (context.isGranted("PaymentsAdminRead")) {
            setStatus("loading");
            sendNotificationsPendingCfdisPayment(context.session!.tenant!.id, selection.join(","), razon).then(() => {
                setStatus("loaded");
                setSuccess(translate("payment.pending_cfdi.send_notifications.success") as string);
            }).catch((error) => {
                setStatus("loaded");
                setError(error.message);
            });
        }
    };

    const onCloseConfirmSendNotificationsDialog = () => {
        setOpenConfirmSendNotificationsDialog(false);
    }

    const onClickedRow = (payment: Payment) => {
        pushHistory();
        setRedirect(`/payment-details/${payment.id}/?origin=${window.location.pathname}`);
    };

    const onExportExcelSelectd = () => {
        setGridAnchorEl(null);
        if (selection && selection.length > 0) {
            setOpenBackdrop(true);
            let paramSelect = {
                "payments_ids": selection.join(","),
            } as PaymentListParams;
            exportExcelPayments(context.session!.tenant!.id, context.session!.user.id, paramSelect).then((response) => {
                setOpenBackdrop(false);
                if (response.url && response.total > 0) {
                    window.open(response.url, "_blank")
                } else if (response.total > 0) {
                    setSuccess(translate("cfdis.email_export") as string);
                } else {
                    setWarning(translate("payment.archived.export.empty") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });

        } else {
            setWarning(translate("payment.archived.empty_selection") as string);
        }
    }

    const onExportExcelInView = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenBackdrop(true);
            exportExcelPayments(context.session!.tenant!.id, context.session!.user.id, params).then((response) => {
                setOpenBackdrop(false);
                if (response.url && response.total > 0) {
                    window.open(response.url, "_blank")
                } else if (response.total > 0) {
                    setSuccess(translate("cfdis.email_export") as string);
                } else {
                    setWarning(translate("payment.archived.export.empty") as string);
                }
            }).catch((error) => {
                setOpenBackdrop(false);
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const openFileImport = () => {
        setGridAnchorEl(null);
        fileInput.current!.click();
    }

    const onClarification = () => {
        setGridAnchorEl(null);
        if (data && selection && selection.length > 0) {
            setOpenBackdrop(true);
            setPaymentsSelected(data.items.filter(item => selection.find(id => id === item.id)));
            setClarification(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_clarification") as string);
        }
    }

    const onDownloadTemplate = () => {
        setGridAnchorEl(null);
        setDownloadTemplate(true);
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setOpenBackdrop(true);
        importPayments(context.session!.tenant!.id, event.target.files[0]).then((response) => {
            setSuccess(translate("payment.import.import_s3_success") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            fileInput.current!.value = "";
            setOpenBackdrop(false);
        });
    }

    const onCloseImportDialog = () => {
        setImportSummary(undefined);
        setGridAnchorEl(null);
        fileInput.current!.value = "";
    };

    const onCloseClarification = () => {
        setOpenBackdrop(false);
        setClarification(false);
    };

    const onRequestClarification = (comment: string) => {
        setOpenBackdrop(false);
        setClarification(false);
        setSuccess(comment);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    if (status === "loading") {
        return (<Progress />);
    }

    return (
        <Surface title={props.title}
            icon={<LocalAtmIcon />}
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {context.isGranted("PaymentsUpdate") ?
                        (<Grid item xs="auto">
                            <Link to={`/payment-cfdi/new`}>
                                <Fab color="primary" size="small" title={translate("payment.pending_cfdi.upload") as string} disabled={status === "loading"}>
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </Grid>) : undefined}
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            }
            className="PaperPagination">
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs="auto">
                        <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                            <RefreshTwoToneIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={props.columns ? props.columns : []}
                defaultExpandedGroups={props.defaultExpandedGroups}
                onClickRow={onClickedRow}
                clickRowColumns={props.clickRowColumns}
                amountCurrencyColumns={props.currencyColumns}
                quantityColumns={props.numberColumns}
                dateColumns={props.dateColumns}
                columnsFormat={props.columnsFormat}
                leftColumns={props.leftColumns}
                textColumns={props.textColumns}
                rightColumns={props.rightColumns}
                grouping={props.grouping}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                selectionIds={selection}
                filters={filters}
                customFormatColumns={props.statusPlugins}
                setFiltersHandler={setFiltersHandler}
                setSelectionHandler={setSelectionHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".csv, .xlsx, .xls, .xml, .json, .txt, .tsv" />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {payment && anchorEl && (
                <PaymentPendingCfdiMenu payment={payment} anchor={anchorEl} referer={"to-verify"} onClose={onCloseOption} onDelete={onOpenConfirmRejected} enableRejectPaymentOption={enableRejectPayment} />
            )}
            {openConfirmRejected && (
                <RejectPaymentPopup onClose={onCloseConfirmRejected} doAction={onDelete} name={context.session!.provider ? context.session!.tenant!.name : payment!.provider_name} />
            )}
            {gridAnchorEl && (
                <PaymentsGlobalMenu
                    anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    reference="pending"
                    customTemplate={customTemplate}
                    onSendNotifications={onOpenConfirmSendNotificationsDialog}
                    onExportExcelSelectd={onExportExcelSelectd}
                    onExportExcelInView={onExportExcelInView}
                    onDownloadTemplate={onDownloadTemplate}
                    onImport={openFileImport}
                    onClarification={onClarification}
                />
            )}
            {openConfirmSendNotificationsDialog && (
                <SendNotificationsConfirmPopup
                    doActionCommentary={onConfirmSendNotificationsComprobante}
                    onClose={onCloseConfirmSendNotificationsDialog}
                    title={translate("payment.pending_cfdi.send_notifications.title") as string}
                    message={translate("payment.pending_cfdi.send_notifications.description") as string}
                    button={translate("buttons.accept") as string}
                    addCommentary={true} />
            )}
            {downloadTemplate && (
                <ConnectorTemplateDownloader tenantId={context.session?.tenant?.id || ""}
                    family={Family.PAYMENTS}
                    onClose={() => setDownloadTemplate(false)} />
            )}
            {importSummary && (
                <ImportPaymentResumePopup summaryImport={importSummary}
                    onClose={onCloseImportDialog} />
            )}
            {clarification && context.session!.provider && (
                <RequestClarification
                    doAction={onRequestClarification}
                    onClose={onCloseClarification}
                    isCommentRequired={true}
                    payments={paymentsSelected}
                    providerId={context.session!.provider.id}
                    onError={(error) => setError(error)}
                />
            )}
        </Surface>

    );
}
