import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, Divider, Fade, ListItemIcon, Icon } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import PaymentOrderDeletePopUp from "./PaymentOrderDeletePopup";
import { SuccessSnackbar } from "../components/Snackbars";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import DetailsIcon from "@material-ui/icons/VisibilityTwoTone";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import PictureAsPdfTwoToneIcon from '@material-ui/icons/PictureAsPdfTwoTone';

interface PaymentOrderMenuProps {
    view: string;
    payment_order_id: string;
    payment_order_status: string;
    payment_order_delete: boolean;
    anchor: HTMLElement | null;
    onClose(): any;
    onDownloadCfdis(): any;
    onDownloadExcel(): any;
    onSavePayments(): any;
    onAddPaymentFile(): any;
    onDownloadPdf(): any;
    load(): any;
}

export default function PaymentOrderMenu(props: PaymentOrderMenuProps) {
    const context = useContext(AppContext);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>();

    const openDeleteDialog = () => {
        if (!props.payment_order_id) return;
        setShowDeleteDialog(true);
    };

    const onSuccessDelete = () => {
        props.onClose();
        setSuccess(translate("cfdis.success_deleting", { "id": props.payment_order_id }) as string);
        props.load();
    }

    const onError = (error: string) => {
        setShowDeleteDialog(false);
    };

    const onCancel = () => {
        setShowDeleteDialog(false);
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
    };

    return (
        <Menu
            id="payment-order-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) &&
                <MenuItem button component={Link} to={`/payment-order/${props.payment_order_id}/read/${props.view}`}  dense>
                    <ListItemIcon>
                        <DetailsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.menu.view_detail")}</Typography>
                </MenuItem>
            }
            <Divider />
            {(context.isGranted("PaymentOrdersCxpUpdate") && (props.payment_order_status === "PAYMENT_TO_SEND_TO_EXECUTE" || props.payment_order_status === "PAYMENT_ORDER_REJECTED") && props.view === "archive") || 
             (context.isGranted("PaymentOrdersTreasurerUpdate") && props.payment_order_status === "PAYMENT_IN_PROCESS_TO_EXECUTE" && props.view === "archive") ?
                <MenuItem button component={Link} to={`/payment-order/${props.payment_order_id}/${"edit"}/${props.view}`}  dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.menu.edit")}</Typography>
                </MenuItem>
            : undefined}
            {context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) &&
                <MenuItem button onClick={props.onDownloadCfdis} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.menu.export_cfdis")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) && 
                <MenuItem button onClick={props.onDownloadExcel} dense>
                    <ListItemIcon>
                        <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.menu.export_excel")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) && 
                <MenuItem button onClick={props.onDownloadPdf} dense>
                    <ListItemIcon>
                        <PictureAsPdfTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.menu.export_pdf")}</Typography>
                </MenuItem>
            }
            <Divider />
            {context.isGrantedAny(["PaymentOrdersCxpDelete"]) &&
                props.payment_order_delete &&
                <MenuItem button onClick={openDeleteDialog} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error" >{translate("payment_order.menu.delete")}</Typography>
                </MenuItem>
            }
            {showDeleteDialog &&
                <PaymentOrderDeletePopUp paymentOrderId={props.payment_order_id}
                    tenantId={context.session!.tenant!.id}
                    onCompleted={onSuccessDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Menu>);
}
