import { Team } from "../model/Team";
import { User } from "./User";

export interface LineBudgetRequest {
    external_id: string;
    team_name: string;
    team_id: string;
    amount: number;
}

export interface BudgetLine {
    team_id: string;
    team_name: string;
    initial_amount: number;
    variation_amount: number;
    current_amount: number;
    accrued_amount: number;
    exercised_amount: number;
    pending_accrue_amount: number;
    pending_exercise_amount: number;
    variation_status: "IN_BUDGET" | "UP_BUDGET" | "DOWN_BUDGET";
    external_id: string;
    external_type: string;
    description: string;
    ledger_account: string;
    budget_name: string;
    budget_id: string;
    company_id: string;
}

export interface BudgetLineForGrid extends BudgetLine{
    id: string;
}

export interface Budget {
    id: string,
    tenant_id: string;
    name: string;
    company_id: string;
    company_name: string;
    init_date: Date;
    end_date: Date;
    currency: string;
    init_amount: number;
    current_amount: number;
    accrued_amount: number;
    pending_accrue_amount: number;
    exercised_amount: number;
    variation: number;
    execution_status: "PLANNING" | "IN_EXECUTION" | "CLOSED";
    variation_status: "IN_BUDGET" | "UP_BUDGET" | "DOWN_BUDGET";
    updated_date: Date;
    budget_lines: BudgetLine[];
    changes_history: BudgetHistory[];
    created_at: Date;
    teams: Team[];
    to_accrued_amount: number;
    to_exercised_amount: number;
}

export interface BudgetHistory {
    comparation_date: Date;
    budget_amount: number;
    pending_accrue_amount: number;
    accrued_amount: number;
    exercised_amount: number;
    budget_status: "PLANNING" | "IN_EXECUTION" | "CLOSED";
    percentage_exercised: number;
    percentage_to_exercise: number;
    comparative_type: "PARTIAL" | "FINAL";
    comments: string;
    event: BudgetEventType;
    user?: User;
    aditional_information: string;
    modification_date: Date;
}

export interface BudgetRequest {
    name: string;
    company_id: string;
    currency: string;
    init_date: Date;
    end_date: Date;
}

export interface Budgets {
    items: Budget[];
    total: number;
}

export interface BudgetsQueryParams {
    search: string;
    statuses: string;
    view: string;
}

export interface BudgetLinesListParams {
    external_id: string;
    name: string;
    ledger_account?: string;
    initial_amount: string;
    team_name: string;
    current_amount: string;
    description?: string;
    accrued?: string;
    exercised?: string;
    to_accrued?: string;
    to_exercised?: string;
    variation?: string;
    requisition_type_id?: string
}

export interface BudgetSimpleLine {
    external_id: string;
    name: string;
    ledger_account: string;
    initial_amount: string;
    team_name: string;
    team_id: string;
}

export interface BudgetSimpleLines {
    items: BudgetSimpleLine[];
    total: number;
}

export interface BudgetLines {
    items: BudgetLine[];
    total: number;
}

export const BudgetExecutionStatus = ['PLANNING', 'IN_EXECUTION', 'CLOSED'];

export const BudgetVariationStatus = ['IN_BUDGET', 'DOWN_BUDGET', 'UP_BUDGET'];

export enum BudgetEventType { BUDGET_CREATION = "BUDGET_CREATION", BUDGET_MODIFICATION = "BUDGET_MODIFICATION", BUDGET_STATUS_CHANGE = "BUDGET_STATUS_CHANGE", CHANGE_BUDGET_LINE = "CHANGE_BUDGET_LINE", BATCH_CHANGE_BUDGETS_LINES = "BATCH_CHANGE_BUDGETS_LINES" };

export interface LineBudgetParams {
    external_id: string;
    name: string;
    description: string;
    initial: string;
    current: string;
    accrued: string;
    exercised: string;
    to_accrued: string;
    to_exercised: string;
    team: string;
    variation: string;
}

export interface BudgetLinesPerformance {
    items: BudgetLine[];
    total: number;
}

export interface SyncTemplateLinesResponse {
    lines: number;
}