import { callAPI, HTTPMethod, getAuthenticatedGetUrl, APIQueryString } from "./API";
import { ProviderExpedient, ProviderExpedientFieldRequest, ProviderExpedientAddressRequest, ProviderExpedientContactRequest, 
    ProviderExpedientBankAccountRequest, ProviderExpedientStatusesRequest, GetUrlFromHistoryExpedientRequest, ProviderPaymentBankAccountRequest, ProviderPaymentBankAccountsRequest } from "../model/ProviderExpedient";
import { FileUrlResponse } from "../model/ZipDownload";

const getBaseUrl = (tenantId: string, providerId: string): string => `/tenants/${tenantId}/providers/${providerId}/expedient`;

export async function getExpedient(tenantId: string, providerId: string): Promise<ProviderExpedient> {
    return await callAPI(getBaseUrl(tenantId, providerId), {
        method: HTTPMethod.GET
    });
}

export async function completeExpedient(tenantId: string, providerId: string, request: ProviderExpedientStatusesRequest, finished: boolean): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}?finished=${finished}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateExpedient(tenantId: string, providerId: string, request: ProviderExpedientStatusesRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/update`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function sendExpedient(tenantId: string, providerId: string): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}`, {
        method: HTTPMethod.PATCH
    });
}

export async function updateAddresses(tenantId: string,
    providerId: string, request: ProviderExpedientAddressRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/addresses`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateField(tenantId: string,
    providerId: string, request: ProviderExpedientFieldRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/fields`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteField(tenantId: string,
    providerId: string, request: ProviderExpedientFieldRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/fields-delete`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateContacts(tenantId: string,
    providerId: string, request: ProviderExpedientContactRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/contacts`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateBankAccounts(tenantId: string,
    providerId: string, request: ProviderExpedientBankAccountRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/bank-accounts`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function getFieldUrl(tenantId: string, providerId: string, fieldId: string): Promise<string> {
    return getFieldUrlSpecific(tenantId, providerId, fieldId, {
        from_history: "false",
        value:  "",
    } as GetUrlFromHistoryExpedientRequest);
}

export async function getFieldUrlSpecific(tenantId: string, providerId: string, fieldId: string, request: GetUrlFromHistoryExpedientRequest): Promise<string> {
    return getAuthenticatedGetUrl(`${getBaseUrl(tenantId, providerId)}/fields/${fieldId}/file`, { query : {
        fromHistory: request.from_history,
        url: request.value || "",
    } as Record<string, string>} as APIQueryString);
}

export async function getFieldAuxiliarFileUrl(tenantId: string, providerId: string, fieldId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${getBaseUrl(tenantId, providerId)}/fields/${fieldId}/auxiliar-file`);
}

export async function getBankAccountCoverUrl(tenantId: string, providerId: string, bankAccountId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${getBaseUrl(tenantId, providerId)}/bank-accounts/${bankAccountId}/cover`);
}

export async function changeStatus(tenantId: string, providerId: string, status: string): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/change-status`, {
        method: HTTPMethod.PATCH,
        query: {
            status: status
        }
    });
}

export async function validate(tenantId: string, providerId: string): Promise<void> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/validate`, {
        method: HTTPMethod.PATCH
    });
}

export async function getFilesExpedient(tenantId: string, providerId: string): Promise<FileUrlResponse> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/files-expedient`, {
        method: HTTPMethod.GET
    });
}

export async function clearElementAddress(tenantId: string,
    providerId: string, addressId: string): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/clear-address`, {
        method: HTTPMethod.PUT,
        query: {addressId:addressId}
    });
}

export async function clearElementContact(tenantId: string,
    providerId: string, contactId: string): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/clear-contact`, {
        method: HTTPMethod.PUT,
        query: {contactId:contactId}
    });
}

export async function clearElementBankAccount(tenantId: string,
    providerId: string, bankAccountId: string): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/clear-bank-account`, {
        method: HTTPMethod.PUT,
        query: {bankAccountId: bankAccountId}
    });
}

export async function assignPaymentBankAccount(tenantId: string, providerId: string, request: ProviderPaymentBankAccountsRequest): Promise<ProviderExpedient> {
    return await callAPI(`${getBaseUrl(tenantId, providerId)}/payment-bank-account`, {
        method: HTTPMethod.PUT,
        body: request
    });
}