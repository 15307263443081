import React, { useState, useEffect, useContext } from "react";
import { List, ListItem, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, IconButton, Card } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../../components/ValidatedInput";
import { Entity, ProviderAssignWorkflowRequest, ValidateProvidersWorkflowDeleteRequest } from "../../model/Provider";
import translate from "../../i18n/Translator";
import AddIcon from "@material-ui/icons/Add";
import { Workflow, WorkflowsQueryParams } from "../../model/Workflow";
import { listWorkflows } from "../../api/WorkflowAPI";
import { AppContext } from "../../context/AppContext";
import Progress from "../../components/Progress";
import Gridable from "../../components/Gridable";
import Ellipsis from "../../components/Ellipsis";
import { WarningSnackbar, ErrorSnackbar } from "../../components/Snackbars";
import DeleteIcon from "@material-ui/icons/Delete";
import { assignWorkflows, validateWorkflowsDelete } from "../../api/ProviderAPI";

interface ProvidersWorkflowsManagePopupProps {
    providers: Entity[]
    onSuccess(update: number): any;
    onClose(): any;
}

export default function ProvidersWorkflowsManagePopup(props: ProvidersWorkflowsManagePopupProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [workflows, setworkflows] = useState<Workflow[]>([]);
    const [selectedWorkflows, setSelectedWorkflows] = useState<Workflow[]>([]);
    const [currentId, setCurrentId] = useState<String>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>()
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        var params = {
            search: "",
            status: ""
        } as WorkflowsQueryParams;
        listWorkflows(context.session!.tenant!.id, 0, 0, params).then((response) => {
            setworkflows(response.items);
            if (response.items && response.items.length > 0
                && props.providers[0].workflows && props.providers[0].workflows.length > 0) {
                setSelectedWorkflows(response.items.filter(item => props.providers[0].workflows.includes(item.id)));
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
            setError(error.message);
        });

    }, [context.session, props.providers]);

    const onSelectionChanged = (name: string, value: string, inputRef: InputRef) => {
        if (value && value !== "---") {
            setCurrentId(value);
        }
    }

    const onAddWorkflow = () => {
        if (!currentId) return;
        if (selectedWorkflows.find((selected) => currentId === selected.id) !== undefined) return;

        var workflow = workflows.find((w) => currentId === w.id);
        if (workflow) {
            setSelectedWorkflows([...selectedWorkflows, workflow]);
        }
    }

    const removeWorkflow = (index: number) => () => {
        if (props.providers[0].workflows.includes(selectedWorkflows[index].id)) {
            let request = {
                workflow_id: selectedWorkflows[index].id,
                providers: props.providers.map(p => p.id)
            } as ValidateProvidersWorkflowDeleteRequest;
            validateWorkflowsDelete(context.session!.tenant!.id, request).then(response => {
                if(response.content) {
                    removeWorkflowIndex(index);
                } else {
                    setWarning(translate("providers.manage_workflows.unable_delete") as string);
                }
            }).catch((error) => {
                setError(error.message);
            });
            return;
        }
        removeWorkflowIndex(index);
    }

    const removeWorkflowIndex = (index: number) => {
        selectedWorkflows.splice(index, 1);
        setSelectedWorkflows([...selectedWorkflows]);
    }

    const onSave = () => {
        if (selectedWorkflows.length === 0) {
            setWarning(translate("providers.manage_workflows.empty_selection") as string)
            return;
        }
        setSubmitting(true);
        let request = {
            workflow_ids: selectedWorkflows.map(w => w.id),
            provider_ids: props.providers.map(p => p.id),
        } as ProviderAssignWorkflowRequest;
        assignWorkflows(context.session!.tenant!.id, request).then((response) => {
            props.onSuccess(response.updated);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">

            <DialogTitle id="alert-dialog-title" disableTypography>
                <Typography variant="subtitle1">
                    {translate("providers.manage_workflows.title") as string}
                </Typography>
            </DialogTitle>

            {status === "loading" &&
                <Box padding={2}>
                    <Progress />
                </Box>}

            {status === "loaded" && <>
                <DialogContent dividers>
                    <List dense disablePadding>
                        {props.providers.map((provider) => (
                            <ListItem key={provider.id} disableGutters>
                                <strong>{provider.name}</strong>
                            </ListItem>
                        ))}
                    </List>
                    <br />
                    <Typography variant="body2" align="left">
                        {translate("providers.manage_workflows.workflow_select_label")}
                    </Typography>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={9}>
                            <ValidatedInput
                                type="text" id="selectWorkflow" name="selectWorkflow"
                                label={translate("providers.manage_workflows.select") as string}
                                value={""}
                                required={false}
                                margin="dense"
                                options={workflows.map((w) => w.id)}
                                optionLabels={workflows.map((w) => w.name)}
                                onValueChanged={onSelectionChanged}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button startIcon={<AddIcon />} onClick={onAddWorkflow} variant="outlined" color={"primary"} disabled={false} fullWidth={true}>
                                {translate("buttons.add") as string}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant="outlined" >
                                <Gridable
                                    items={selectedWorkflows}
                                    loading={false}
                                    border
                                    empty={translate("workflows.empty") as string}
                                    columns={[
                                        {
                                            title: translate("providers.manage_workflows.assigned") as string,
                                            converter: (workflow) => (
                                                <Ellipsis text={workflow.name} lenght={50} uppercased={false} />
                                            ),
                                            fullWidth: true,
                                            xs: true
                                        },
                                        {
                                            title: "",
                                            converter: (workflow, index) => (
                                                <IconButton aria-label="delete" size="small" onClick={removeWorkflow(index)} >
                                                    <DeleteIcon />
                                                </IconButton>
                                            ),
                                            justify: "flex-end",
                                            xs: "auto"
                                        }

                                    ]} />
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={submitting}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={submitting}>
                        {translate("buttons.save")}
                    </Button>
                </DialogActions>
            </>}
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />

        </Dialog>)

}
