import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Direction, Family } from "../model/Connector";
import ConnectorSelector from "./ConnectorSelector";
import DateRange from "../components/DateRange";
import { WarningSnackbar } from "../components/Snackbars";

export type ExportMode = "PARAMS" | "SELECTED" | "ONLY_DATES";


interface ConnectorObjectExporterProps {
    tenantId: string;
    family: Family;
    type?: "TEMPLATE" | "DATA" | "DATA_RANGE";
    onlyDates?: boolean;
    onExport(connectorId: string, startDate?: Date, endDate?: Date): any;
    onClose(): any;
    maxDaySelected?: number;
    includeDefault?: boolean;
    legacy?: "XLSX",
    noValidateRangeDate?: boolean;
}

export default function ConnectorObjectExporter(props: ConnectorObjectExporterProps) {
    const [connectorId, setConnectorId] = useState<string>();
    const [sDate, setSDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()));
    const [eDate, setEDate] = useState<Date>(new Date());
    const [warning, setWarning] = useState<string>();

    const menuItems = ["this_week", "this_month", "last_month", "custom"];
    const getTitle = (type: string) => {
        switch (type) {
            case "TEMPLATE":
                return translate(`connectors.template_downloader.title`) as string;
            case "DATA":
                return translate(`connectors.download_data.title`) as string;
            case "DATA_RANGE":
                return translate(`connectors.download_by_range.title`) as string;
            default:
                return translate(`connectors.template_downloader.title`) as string;
        }

    }
    const onChangeDateRange = (startDate: Date, endDate: Date) => {
        let difference = endDate.getTime() - startDate.getTime();
        let daysBetween = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysBetween && props.maxDaySelected && daysBetween > props.maxDaySelected && !props.noValidateRangeDate) {
            setWarning(translate(`connectors.download_by_range.range_limit`, { "dias": props.maxDaySelected + "" }) as string);
            return;
        } else {
            setSDate(startDate);
            setEDate(endDate);
        }
    }

    const onClickExport = () => {
        if ("DATA_RANGE" === props.type) {
            props.onExport(connectorId || "", sDate, eDate);
        } else {
            props.onExport(connectorId || "");
        }
    };

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick
            onClose={props.onClose}
            aria-labelledby="templ-downloader-title"
            aria-describedby="templ-downloader-description">
            <DialogTitle id="templ-downloader-title" disableTypography>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{getTitle(props.type ? props.type : "")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{translate(`connectors.family.${props.family}`)}</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="DialogPopup" dividers>
                {props.type !== undefined && props.type === "DATA_RANGE" ?
                    (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{translate(`connectors.download_by_range.range`) as string}</Typography>
                                <Grid item xs={12}>
                                    <DateRange
                                        startDate={sDate}
                                        endDate={eDate}
                                        onChange={onChangeDateRange}
                                        menuItemsCustom={menuItems} />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : undefined
                }
                {!props.onlyDates &&
                <ConnectorSelector
                    tenantId={props.tenantId}
                    family={props.family}
                    direction={Direction.OUTPUT}
                    includeDefault={props.includeDefault}
                    onChangedConnector={(connector)=>setConnectorId(connector.id)}
                    legacy={props.legacy} />}
            </DialogContent>
            <DialogActions>
                <Grid item xs={12}>
                    <Box px={1}>
                        <Grid container justify="flex-end" spacing={1}>
                            <Grid item xs="auto">
                                <Button onClick={props.onClose} variant="outlined" color="primary">
                                    {translate("buttons.close")}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button onClick={onClickExport} variant="contained" color="primary" disabled={props.family === "CFDIS" ? false : (connectorId === undefined && !props.onlyDates ) || (connectorId !== undefined && props.onlyDates)}>
                                    {translate("buttons.export")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </DialogActions>
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
        </Dialog>
    );
}


