import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Menu, Grid, ListItemIcon, ListItem, List, ListItemText, Icon } from "@material-ui/core";
import translate from "../i18n/Translator";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import Collapse from '@material-ui/core/Collapse';


interface PaymentOrdersGlobalMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onExportExcelSelectd?(): any;
    onExportExcelAll?(): any;
}

export default function PaymentOrdersGlobalMenu(props: PaymentOrdersGlobalMenuProps) {
    const context = useContext(AppContext);
    const [expandExportExcel, setExpandExportExcel] = useState<boolean>(false);

    const handleClickExportExcel = () => {
        setExpandExportExcel(!expandExportExcel);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            onClose={props.onClose}
            variant='menu'
            open >
            
            {context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) && (
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.download_excel")} />
                            {expandExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={expandExportExcel} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onExportExcelSelectd} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onExportExcelAll} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.all_in_view")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            )}
            
        </Menu>
    );
}
