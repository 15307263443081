import { callAPI, HTTPMethod } from "./API";
import { BankAccountsQueryFilter, TenantBankAccount, TenantBankAccounts, BankAccountRequest } from "../model/BankAccount";

export async function listBankAccounts(tenantId: string, page: number, offset: number, params: BankAccountsQueryFilter): Promise<TenantBankAccounts> {
    return await callAPI(`/tenants/${tenantId}/bank-accounts`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            currency: params.currency,
            layoutOnly: params.hasLayout !== undefined ? params.hasLayout ? "true" : "false" : "",
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createBankAccount(tenantId: string, request: BankAccountRequest): Promise<TenantBankAccount> {
    return await callAPI(`/tenants/${tenantId}/bank-accounts`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getBankAccount(tenantId: string, bankAccountId: string): Promise<TenantBankAccount> {
    return await callAPI(`/tenants/${tenantId}/bank-accounts/${bankAccountId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateBankAccount(tenantId: string, bankAccountId: string, request: BankAccountRequest): Promise<TenantBankAccount> {
    return await callAPI(`/tenants/${tenantId}/bank-accounts/${bankAccountId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteBankAccount(tenantId: string, bankAccountId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/bank-accounts/${bankAccountId}`, {
        method: HTTPMethod.DELETE
    });
}