import { callAPI, HTTPMethod } from "./API";
import { BudgetLinesTemplate, BudgetLinesTemplateQueryParams, BudgetLinesTemplateRequest, BudgetLinesTemplates } from "../model/BudgetLinesTemplate";

const basePath = (tenantId: string) => `/tenants/${tenantId}/budget-lines-template`;

export async function listBudgetLinesTemplates(tenantId: string, page: number, offset: number, params: BudgetLinesTemplateQueryParams): Promise<BudgetLinesTemplates> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search ?? "",
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createBudgetLinesTemplate(tenantId: string, request: BudgetLinesTemplateRequest): Promise<BudgetLinesTemplate> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getBudgetLinesTemplate(tenantId: string, id: string): Promise<BudgetLinesTemplate> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateBudgetLinesTemplate(tenantId: string, id: string, request: BudgetLinesTemplateRequest): Promise<BudgetLinesTemplate> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteBudgetLinesTemplate(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
