import React, { useEffect, useState } from "react";
import { IconButton, Box } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import { Customer } from "../model/Customer";
import DateFormat from "../components/DateFormat";
import { listContracts } from "../api/ContractAPI";
import { Contract, Contracts, ContractsQueryParams } from "../model/Contract";
import ContractMenu from "../contracts/ContractMenu";

interface CustomerContractsProps {
    tenantId: string;
    customer: Customer;
}

export default function CustomerContracts(props: CustomerContractsProps) {
    const [status, setStatus] = useState("loading");
    const [data, setData] = useState<Contracts>();
    const [contract, setContact] = useState<Contract>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setStatus("loading");
        listContracts(props.tenantId, props.customer.id, 0, 0, { search: "" } as ContractsQueryParams).then(response => {
            setData(response);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        })
    }, [props.tenantId, props.customer]);

    const onClickedOptions = (contract: Contract) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setContact(contract);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setContact(undefined);
    };

    return (
        <Box pt={2} >
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("contracts.empty") as string}
                columns={[
                    {
                        title: translate("contracts.date") as string,
                        converter: (contract) => (
                            <DateFormat date={contract.created_at} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("contracts.description") as string,
                        converter: (contract) => contract.description,
                        fullWidth: true,
                        xs: 6,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        xl: 6
                    },
                    {
                        title: translate("contracts.from") as string,
                        converter: (contract) => (
                            <DateFormat date={contract.from} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("contracts.to") as string,
                        converter: (contract) => (contract.date_undetermined ? 
                            <>{translate("contracts.date_undetermined")}</> :
                            <DateFormat date={contract.to} format="DD/MM/YYYY" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (contract) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(contract)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {contract && anchorEl &&
                <ContractMenu contract={contract} anchor={anchorEl} onClose={onCloseOption} />
            }
        </Box>
    );
}