import React, { useState, useEffect } from "react";

import translate, { translateParams }  from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { initialPage, initialPageSize } from "../components/Pagination";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import InvalidIcon from '@material-ui/icons/CancelTwoTone';
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';

export interface CfdiValidationsParams {
    tenantId: string;
    cfdi: Cfdi;
}

export default function CfdiValidations(props: CfdiValidationsParams) {

    const [page] = useState<number>(initialPage);
    const [pageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");

    const load = () => {
        setStatus("loaded");
    };

    useEffect(load, [props, page, pageSize]);

    return (
            <Gridable
                items={ props.cfdi.metadata.validations ? props.cfdi.metadata.validations : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cfdis.validations.empty") as string}
                columns={[
                    {
                        title: "",
                        converter: (validation) => (validation.valid ? <ValidIcon style={{ "color": "#82CA9D" }}/> : <InvalidIcon style={{ "color": "#C33149" }}/> ),
                        xs: 1,
                        sm: 1,
                        md: 1,
                        xl: 1
                    },
                    {
                        title: translate("cfdis.validations.date") as string,
                        converter: (validation) => (<DateFormat date={validation.created_at} format="DD-MM-YYYY h:mm a" zone={true}/>),
                        xs: false,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.validations.group") as string,
                        converter: (validation) => (<Ellipsis text={translate(`cfdis.popup.validation.types_group.${validation.group}`) as string} lenght={100} uppercased={false} />),
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.validations.type") as string,
                        converter: (validation) => (<Ellipsis text={translate(`cfdis.popup.validation.types.${validation.type}`) as string} lenght={100} uppercased={false} />),
                        xs: "auto",
                        sm: 3,
                        md: 3,
                        xl: 3
                    },
                    {
                        title: translate("cfdis.validations.message") as string,
                        converter: (validation) => validation.type === "PLUGINS" && validation.message ? validation.message : (
                            <Ellipsis text={translate(`cfdis.popup.validation.codes.${validation.code}`, {
                                    "tipo": props.cfdi.cfdi?.tipo_comprobante || "---", "metodo_pago": props.cfdi.cfdi?.metodo_pago || "---", 
                                    "forma_pago": props.cfdi.cfdi?.forma_pago || "---", "uso_cfdi": props.cfdi.cfdi?.receptor.uso_cfdi || "---",
                                    "products": props.cfdi.metadata.product_keys, "field_required": props.cfdi.metadata.required_fields,
                                    ...translateParams(validation.params)
                                }) as string} lenght={150} uppercased={false} />
                        ),
                        xs: false,
                        sm: true,
                        md: true,
                        xl: true
                    },
                ]} />
    )
}