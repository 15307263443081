import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from "@material-ui/core";
import { AddIcon, DeleteIcon, ProrationTemplatesIcon } from "../components/Icons";

import { createProrationTemplate, getProrationTemplate, updateProrationTemplate } from "../api/ProrationTemplateAPI";
import translate from "../i18n/Translator";
import { PercentageCostCenter, ProrationTemplate, ProrationTemplateRequest } from "../model/ProrationTemplate";
import { Companies, CompaniesQueryParams } from "../model/Company";

import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import { listCompanies } from "../api/CompanyAPI";
import Gridable from "../components/Gridable";
import CostCenterSelectionPopup from "./CostCenterSelectionPopup";
import { CostCenter } from "../model/CostCenter";
import { GTZeroValidator, NumberValidator } from "../components/Validators";

export default function ProrationTemplateForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const prorationTemplateId = match.params.prorationTemplateId;
    const margin = "dense";
    const [openCostCentersPopup, setOpenCostCentersPopup] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");
    const [percentageSum, setPercentageSum] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<ProrationTemplateRequest>({} as ProrationTemplateRequest);
    const [validations, setValidations] = useState({} as any);
    const [companies, setCompanies] = useState<MultiselectValue[]>([]);
    const [percentageCostCenter, setPercentageCostCenter] = useState<PercentageCostCenter[]>([]);
    const numberValidator = new NumberValidator(true);
    const gtZeroValidator = new GTZeroValidator();

    const initCompanies = (companies: Companies) => {
        let items = companies.items;
        let companiesValues = [] as MultiselectValue[];
        items.forEach(c => {
            companiesValues.push({ title: c.name, value: c.id } as MultiselectValue)
        });
        setCompanies(companiesValues);
    }

    const submitPromise = (): Promise<ProrationTemplate> => {
        let fullRequest = {...request, percentage_cost_centers: percentageCostCenter} as ProrationTemplateRequest;
        if (prorationTemplateId) {
            return updateProrationTemplate(tenantId, prorationTemplateId, fullRequest);
        }
        return createProrationTemplate(tenantId, fullRequest);
    };

    useEffect(() => {
        setStatus("loading");
        if (prorationTemplateId) {
            getProrationTemplate(tenantId, prorationTemplateId).then((prorationTemplate) => {
                setRequest({
                    name: prorationTemplate.name,
                    companies: prorationTemplate.companies,
                    description: prorationTemplate.description,
                } as ProrationTemplateRequest);
                setPercentageCostCenter(prorationTemplate.percentage_cost_centers);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        if (context.isGrantedAny(["CompaniesRead", "TenantsCompaniesRead"])) {
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams).then((response) => {
                initCompanies(response);
            });
        }
    }, [tenantId, prorationTemplateId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedCompanies = (selected: string[]) => {
        setRequest({ ...request, companies: selected });
    }

    const isValidPercentages = () => {
        return 100 === percentageSum;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        if (percentageCostCenter.length < 1) {
            setWarning(translate("prorationtemplates.at_least_one_cost_center") as string);
            return;
        }

        if (!isValidPercentages()) {
            setWarning(translate("prorationtemplates.invalid_percentages") as string);
            return;
        }

        setSubmitting(true);
        submitPromise().then((prorationTemplate) => {
            history.push("/proration-templates");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const onCostCentersSelected = (costCenters: CostCenter[]) => {
        setOpenCostCentersPopup(false);
        let newCostCenter = costCenters.map(cc => ({ cost_center_id: cc.id, erp_id: cc.erp_id, name: cc.name, category: cc.category  })) as PercentageCostCenter[];
        let temp = [...percentageCostCenter, ...newCostCenter] as PercentageCostCenter[];
        setPercentageCostCenter(temp);
    }

    const onDelete = (costCenter: PercentageCostCenter, index: number) => {
        delete validations[costCenter.cost_center_id];
        setValidations(validations);
        const newValues = percentageCostCenter.filter((el, i) => i !== index);
        setPercentageCostCenter(newValues);
        updatePercentageSum(newValues);
    }

    const updatePercentageSum = (items: PercentageCostCenter[]) => {
        let summatory = items.map(p => p.percentage ?? 0).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        }, 0);
        setPercentageSum(Math.round(summatory*100)/100);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(prorationTemplateId ? "prorationtemplates.edit" : "prorationtemplates.new")} icon={<ProrationTemplatesIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("prorationtemplates.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="description" name="description"
                                        value={request.description}
                                        label={translate("prorationtemplates.description") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {context.isGrantedAny(["CompaniesRead", "TenantsCompaniesRead"]) &&
                                    <Grid item xs={12}>
                                        <MultiselectDropList
                                            required={true}
                                            disabled={submitting}
                                            title={translate("prorationtemplates.companies") as string}
                                            margin={margin}
                                            elementos={companies}
                                            value={companies.filter(c => request.companies?.includes(c.value))}
                                            onChanged={onChangedCompanies} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Box mt={2} mx={-2}>
                                        <Gridable
                                            items={percentageCostCenter}
                                            loading={false}
                                            empty={translate("prorationtemplates.cost_centers_empty_grid") as string}
                                            columns={[
                                                {
                                                    title: translate("prorationtemplates.cost_center_id") as string,
                                                    converter: (costCenter) => costCenter.erp_id as string,
                                                    fullWidth: true,
                                                    xs: 3
                                                },
                                                {
                                                    title: translate("prorationtemplates.cost_center") as string,
                                                    converter: (costCenter) => costCenter.name,
                                                    fullWidth: true,
                                                    xs: 4
                                                },
                                                {
                                                    title: translate("prorationtemplates.cost_center_percentage", {value: percentageSum}) as string,
                                                    converter: (costCenter) =>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <ValidatedInput id="percentage" name="percentage" type="number"
                                                                    label={translate("prorationtemplates.percentage") as string}
                                                                    value={costCenter.percentage ? costCenter.percentage + "" : ""} required disabled={submitting}
                                                                    margin={margin}
                                                                    validators={[numberValidator, gtZeroValidator]}
                                                                    onValueChanged={(name: string, value: string, inputRef: InputRef) => {
                                                                        validations[costCenter.cost_center_id] = inputRef;
                                                                        setValidations(validations);
                                                                        if (value) {
                                                                            costCenter.percentage = Number(value);
                                                                        }
                                                                        updatePercentageSum(percentageCostCenter);
                                                                    }} />
                                                            </Grid>
                                                        </Grid>,
                                                    fullWidth: true,
                                                    xs: 3
                                                },
                                                {
                                                    title: (
                                                        <IconButton size="small" color="primary" disabled={submitting} onClick={() => setOpenCostCentersPopup(true)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    ),
                                                    converter: (value, index) => (
                                                        <IconButton aria-label="delete" size="small" disabled={submitting} onClick={() => onDelete(value, index)}>
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    ),
                                                    justify: "flex-end",
                                                    xs: true
                                                }
                                            ]} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(prorationTemplateId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                        {openCostCentersPopup &&
                            <CostCenterSelectionPopup
                                tenantId={tenantId}
                                selectedIds={percentageCostCenter.map(cc => cc.cost_center_id)}
                                onSelect={onCostCentersSelected}
                                onClose={() => setOpenCostCentersPopup(false)} />
                        }
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}