import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid, ListItem, Icon, ListItemText, List } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/AddTwoTone";

interface NewPayableDocumentMenuProps {
    anchor: HTMLElement | null;
    isProviderSide?: boolean;
    isProviderForeign: boolean;
    onClose(): any;
    onNewExpesesAccount?(honorarium: boolean): any;
    onForeign?(): any;
    onForeignCreditNote?(): any;
    onAdvancePayments?(): any;
}

export default function NewPayableDocumentMenu(props: NewPayableDocumentMenuProps) {
    const context = useContext(AppContext);
    const [openForeignCfdis, setOpenForeignCfdis] = useState<boolean>(false);

    const handleClickForeigns = () => {
        setOpenForeignCfdis(!openForeignCfdis);
    };

    return (
        <Menu
            id="new-payable-document-menu"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Fade}>
            {!props.isProviderForeign && context.isGrantedAny(["AdminCFDIsCreate", "AddCfdi"]) &&
                <MenuItem button component={Link} to={"/owner-cfdis/new"} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payable_documents.cfdi")}</Typography>
                </MenuItem>
            }
            {!props.isProviderForeign && context.isGrantedAny(["ReceptorCFDIsCreate", "CxpCFDIsCreate"]) &&
                <MenuItem button component={Link} to="/receptorCfdis/new" onClick={props.onClose} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payable_documents.cfdi")}</Typography>
                </MenuItem>
            }
            {!props.isProviderForeign && context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate"]) &&
                <MenuItem button component={Link} to="/providerCfdis/new" onClick={props.onClose} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payable_documents.cfdi")}</Typography>
                </MenuItem>
            }
            {props.onNewExpesesAccount && context.isGrantedAny(["CustomsWithoutInvoiceCreate"]) &&
                <MenuItem button onClick={() => props.onNewExpesesAccount!(false)} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payable_documents.expenses_account")}</Typography>
                </MenuItem>
            }
            {props.onNewExpesesAccount && context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate"]) &&
                <MenuItem button onClick={() => props.onNewExpesesAccount!(true)} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payable_documents.honorarium")}</Typography>
                </MenuItem>
            }
            {!props.isProviderSide && context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate", "AdminCFDIsCreate", "ReceptorCFDIsCreate", "CxpCFDIsCreate"]) &&
                    <Grid item xs="auto" >
                <Grid >
                <Grid item xs="auto" >
                    <ListItem button onClick={handleClickForeigns} dense>
                        <ListItemIcon>
                            <AddIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("payable_documents.foreign")} />
                        {openForeignCfdis ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Grid>
                    <Collapse in={openForeignCfdis} timeout="auto" unmountOnExit>
                        <List component="div"  >
                            <ListItem button onClick={props.onForeign} dense >
                                <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                    <AddIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={translate("cfdis.history.cfdi_type.INVOICE")} />
                            </ListItem>
                            <ListItem button onClick={props.onForeignCreditNote} dense >
                                <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                    <AddIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={translate("cfdis.history.cfdi_type.CREDIT_NOTE")} />
                            </ListItem>
                        </List>
                    </Collapse>
                </Grid>
            </Grid>
            }
            {props.isProviderSide && props.isProviderForeign && context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate", "AdminCFDIsCreate", "ReceptorCFDIsCreate", "CxpCFDIsCreate"]) &&
                <MenuItem button onClick={props.onForeign} dense>
                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                            <AddIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("cfdis.history.cfdi_type.INVOICE")} />
                </MenuItem>
            }
            {props.isProviderSide && props.isProviderForeign && context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate", "AdminCFDIsCreate", "ReceptorCFDIsCreate", "CxpCFDIsCreate"]) &&
                <MenuItem button onClick={props.onForeignCreditNote} dense>
                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                            <AddIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("cfdis.history.cfdi_type.CREDIT_NOTE")} />
                </MenuItem>
            }
            {props.onAdvancePayments && context.isGranted('AdvancePaymentCreate') &&
                <MenuItem button onClick={props.onAdvancePayments} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payable_documents.advance_payment")}</Typography>
                </MenuItem>
            }
        </Menu>);
}
