import React, { useState } from "react";
import { IconButton, Box, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import { CustomerShippingRequirement, CustomerShippingRequirementRequest, getDocumentName } from "../model/Customer";
import ShippingRequirementMenu from "../contracts/ShippingRequirementMenu";
import CustomerShppingTemplateReqForm from "./CustomerShppingTemplateReqForm";
import { createCustomerShippingRequirement, updateCustomerShippingRequirement, deleteCustomerShippingRequirement } from "../api/CustomerShippingRequirementApi";
import { ErrorSnackbar } from "../components/Snackbars";
import { AddIcon } from "../components/Icons";
import CustomBackdrop from "../components/CustomBackdrop";
import ConfirmationPopup from "../components/ConfirmationPopup";


interface CustomerDocsProps {
    tenantId: string;
    customerId: string;
    shippingRequirements?: CustomerShippingRequirement[];
}

export default function CustomerDocs(props: CustomerDocsProps) {
    const [data, setData] = useState<CustomerShippingRequirement[]>(props.shippingRequirements || []);
    const [shippingRequirement, setShippingRequirement] = useState<CustomerShippingRequirement>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openAddDocument, setOpenAddDocument] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    const onClickedOptions = (shippingRequirement: CustomerShippingRequirement) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setShippingRequirement(shippingRequirement);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setShippingRequirement(undefined);
    };

    const onDelete = () => {
        setAnchorEl(null);
        if(!shippingRequirement) return;
        setOpenConfirmDialog(true);
    };

    const onConfirmDelete = () => {
        if(!shippingRequirement) return;
        setOpenBackdrop(true);
        deleteCustomerShippingRequirement(props.tenantId, shippingRequirement.id).then(_ => {
            let temp = [] as CustomerShippingRequirement[];
            data.filter(item => item.id !== shippingRequirement.id).forEach(item => {
                temp.push(item);
            });
            setData(temp);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenConfirmDialog(false);
            setOpenBackdrop(false);
            setShippingRequirement(undefined);
        });
    };

    const onClosePopup = () => {
        setOpenAddDocument(false);
    };

    const onOpenAddDocument = () => {
        setOpenAddDocument(true);
        setAnchorEl(null);
    };

    const onCreate = (request : CustomerShippingRequirementRequest) => {
        setOpenAddDocument(false);
        setOpenBackdrop(true);
        createCustomerShippingRequirement(props.tenantId, request).then(response => {
            let temp = data;
            temp.push(response);
            setData(temp);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setShippingRequirement(undefined);
        });
    };

    const onUpdate = (request: CustomerShippingRequirementRequest) => {
        if(!shippingRequirement) return;
        setOpenAddDocument(false);
        setOpenBackdrop(true);
        updateCustomerShippingRequirement(props.tenantId, shippingRequirement.id, request).then(response => {
            let temp = data;
            temp.filter(item => item.id === response.id).forEach(item => {
                item = response;
            });
            setData(temp);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setShippingRequirement(undefined);
        });
    };

    return (
        <Box pt={2} >
            <Button aria-label="add" variant="text" color="secondary" size="small" startIcon={<AddIcon />} onClick={onOpenAddDocument}>
                    {translate("customer_shipping_requirements.add_button")}
            </Button>
            <Gridable
                items={data}
                loading={false}
                empty={translate("customer_shipping_requirements.empty") as string}
                columns={[
                    {
                        title: translate("customer_shipping_requirements.columns.name") as string,
                        converter: (customershippingrequirement) => getDocumentName(customershippingrequirement.custom, customershippingrequirement.name),
                        xs: true
                    },
                    {
                        title: translate("customer_shipping_requirements.columns.description") as string,
                        converter: (customershippingrequirement) => customershippingrequirement.description,
                        xs: true
                    },
                    {
                        title: translate("customer_shipping_requirements.columns.unit") as string,
                        converter: (customershippingrequirement) => translate(`expedients.fields.expirationUnit.${customershippingrequirement.unit}`) as string,
                        xs: true
                    },
                    {
                        title: translate("customer_shipping_requirements.columns.day") as string,
                        converter: (customershippingrequirement) => translate(`customer_shipping_requirements.day`, { "n": customershippingrequirement.day }) as string,
                        xs: true
                    },
                    {
                        title: translate("customer_shipping_requirements.columns.initial_month") as string,
                        converter: (customershippingrequirement) => translate(`params.month_${customershippingrequirement.initial_month}`),
                        xs: true
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (customershippingrequirement) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(customershippingrequirement)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {shippingRequirement && anchorEl &&
                <ShippingRequirementMenu anchor={anchorEl} onClose={onCloseOption} onEdit={onOpenAddDocument} onDelete={onDelete} />
            }
            {openAddDocument &&
                <CustomerShppingTemplateReqForm
                    tenantId={props.tenantId}
                    customerId={props.customerId}
                    shippingRequirement={shippingRequirement}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    onClose={onClosePopup}
                    shippingRequirements={props.shippingRequirements || []}
                />
            }
            {openConfirmDialog && (
                <ConfirmationPopup
                    doAction={onConfirmDelete}
                    onClose={() => setOpenConfirmDialog(false)}
                    title={translate("customer_shipping_requirements.confirm_delete.title") as string}
                    message={translate("customer_shipping_requirements.confirm_delete.message") as string}
                    button={translate("buttons.accept") as string} />
            )}
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Box>
        
    );
}