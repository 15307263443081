import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Cfdi } from "../model/Cfdi";
import { getTranslateStatus, getWorkFlowTranlateStatus } from "../cfdi/CfdiTranslateStatusUtils";

export interface SemaphoreProps {
    cfdi: any;
}

interface ClassDivProps {
    group: string;
    first?: string;
    second?: string;
    third?: string;
}

export default function Semaphore(props: SemaphoreProps) {
    const [cfdi] = useState<Cfdi>(props.cfdi as Cfdi);
    const white = "#d9d9d9";
    const yellow = "#ff9900";
    const green = "#006600";
    const greenSecond = "#008000";
    const greenThird = "#009900";

    const hidden = () => {
        return cfdi.metadata.type === "CREDIT_NOTE" || cfdi.metadata.type === "PAYMENT_CFDI";
    }

    const classDiv = (props: ClassDivProps) => {
        switch (props.group) {
            case "SENDING":
                return semaphoreStyle({ first: white, second: white, third: white });
            case "IN_PROCESS":
                return semaphoreStyle({ first: yellow, second: white, third: white });
            case "VALIDATION":
                return semaphoreStyle({ first: yellow, second: white, third: white });
            case "RECEIVED":
                return semaphoreStyle({ first: green, second: yellow, third: white });
            case "POSTPONE":
                return semaphoreStyle({ first: green, second: yellow, third: white });
            case "PAYMENT_ORDER":
                return semaphoreStyle({ first: green, second: greenSecond, third: yellow });
            case "ARCHIVED":
                return semaphoreStyle({ first: green, second: greenSecond, third: greenThird });
            default:
                return semaphoreStyle({ first: props.first || white, second: props.second || white, third: props.third || white });
        }
    }

    const getStatus = (cfdi: Cfdi) => {

        return getTranslateStatus(cfdi, getWorkFlowTranlateStatus());
    }

    const getDivProps = (cfdi: Cfdi) => {
        return {
            group: cfdi.metadata.semaphore ? "" : cfdi.metadata.cfdi_status_group,
            first: cfdi.metadata.semaphore ? cfdi.metadata.semaphore.first : undefined,
            second: cfdi.metadata.semaphore ? cfdi.metadata.semaphore.second : undefined,
            third: cfdi.metadata.semaphore ? cfdi.metadata.semaphore.third : undefined,
        } as ClassDivProps;
    }

    return (
        <Grid container alignItems="center" alignContent="flex-start" justify="flex-start">
            <Grid item xs="auto" >
                <Hidden xsUp={hidden()}>
                    <div title={getStatus(cfdi)}
                        className={classDiv(getDivProps(cfdi))} />
                </Hidden>
            </Grid>
        </Grid>
    )
}

interface SemaphoreStyleProps {
    first: string;
    second: string;
    third: string;
}

function semaphoreStyle(props: SemaphoreStyleProps) {
    const customStyles = makeStyles((theme: Theme) =>
        createStyles({
            FST: {
                backgroundColor: props.first,
                width: "1vw",
                height: "1vw",
                borderRadius: "50%",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "white",
                boxShadow: "0.7vw 0 0 -0.1vw " + props.second + ", 0.8vw 0 0 -0.1vw #ffffff, 1.3vw 0 0 -0.1vw " + props.third,
            }
        }),
    );
    const classes = customStyles();
    return classes.FST;
}