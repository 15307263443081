import React, { useEffect, useState, useRef } from 'react'
import {
    Grid,
    FormControl,
    Select,
    Chip,
    MenuItem,
    InputLabel,
} from '@material-ui/core'
import { Theme, withStyles } from '@material-ui/core/styles'

const MultiselectContainer = withStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}))(Grid)

const MultiselectChip = withStyles((theme: Theme) => ({
    root: {
        margin: 2,
    },
}))(Chip)

interface MultiselectProps {
    id?: string
    label: string
    value: string[]
    values?: string[]
    labels?: string[]
    disabled?: boolean
    onChanged(value: string[]): any
}

interface Data {
    value: string
    label: string
}

function Multiselect(props: MultiselectProps) {
    const { id, value, label, values, labels, disabled, onChanged } = props
    const [data, setData] = useState<Data[]>([])
    const [labelWidth, setLabelWidth] = useState(0)
    const selectLabel = useRef(null)

    useEffect(() => {
        const data =
            values?.map((el, i) => {
                return {
                    value: el,
                    label: labels ? labels[i] ?? '-' : '-',
                } as Data
            }) || []

        setData(data)
    }, [values, labels])

    useEffect(() => {
        if (!!selectLabel) {
            setLabelWidth(
                (selectLabel.current || { offsetWidth: 0 }).offsetWidth || 0
            )
        }
    }, [label])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChanged(event.target.value as string[])
    }

    return (
        <FormControl variant="outlined" margin="dense" fullWidth disabled={disabled}>
            <InputLabel ref={selectLabel} htmlFor={id ?? "multiselect-chip"}>
                {label}
            </InputLabel>
            <Select
                id="multiselect"
                labelWidth={labelWidth}
                value={value}
                multiple
                onChange={handleChange}
                inputProps={{
                    id: id ?? "multiselect-chip",
                }}
                renderValue={(selected) => {
                    return (
                        <MultiselectContainer>
                            {(selected as string[]).map((value) => {
                                const el = data.find((el) => el.value === value)
                                return (
                                    <MultiselectChip
                                        key={value}
                                        label={el?.label || value}
                                    />
                                )
                            })}
                        </MultiselectContainer>
                    )
                }}
            >
                {data.map((el) => (
                    <MenuItem key={el.value} value={el.value} selected>
                        {el.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default Multiselect
