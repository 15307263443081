import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Fab, Grid, Tab, Tabs, Typography, Badge, Button, Divider } from '@material-ui/core';
import { getExpedient, sendExpedient, changeStatus, getFilesExpedient } from "../api/ProviderExpedientAPI";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import Progress from "../components/Progress";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import ExpedientProviderAddresses from "./ExpedientProviderAddresses";
import ExpedientProviderBankAccounts from "./ExpedientProviderBankAccounts";
import ExpedientProviderContacts from "./ExpedientProviderContacts";
import ExpedientProviderFields from "./ExpedientProviderFields";
import DialogPopup from "../components/DialogPopup";
import { ProviderExpedientIcon, getColor, ProviderExpedientStatusHeader } from "./ExpedientIcon";
import { formatDate } from "../components/DateFormat";
import { expedientBadgets, ProviderExpedient, ProviderExpedientBankAccount, ProviderExpedientField } from "../model/ProviderExpedient";
import { ExpedientIcon, SendIcon, EditTwoIcon, EditAttributesIcon, HistoryIcon, DownloadIcon, EditIcon } from "../components/Icons";
import ProviderExpedientHistoryPopup from "../providers/ProviderExpedientHistoryPopup";
import ProviderAssignPaymentBankAccountsPopup from "../providers/ProviderAssignPaymentBankAccountsPopup";


interface ExpedientProviderTab {
    name: string;
    title: string;
}

export default function ExpedientProvider({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const [expiredAt, setExpiredAt] = useState<Date>();

    const allTabs = [
        {
            name: "contacts",
            title: translate("expedients.contacts.title" as string)
        },
        {
            name: "addresses",
            title: translate("expedients.addresses.title" as string)
        },
        {
            name: "bank_accounts",
            title: translate("expedients.bank_accounts.title" as string)
        },
        {
            name: "fields",
            title: translate("expedients.single") as string
        }
    ] as ExpedientProviderTab[];


    const tabToIndex = (tab: string): number => {
        let index = allTabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [status, setStatus] = useState<string>("loading");
    const [metadata, setMetadata] = useState<ProviderExpedient>({} as ProviderExpedient);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [value, setValue] = useState<number>(tabQs);
    const [ready, setReady] = useState(false);
    const [badges, setBadges] = useState<any>({} as any);
    const [disabled, setDisabled] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [openHistoryPopup, setOpenHistoryPopup] = useState<boolean>(false);
    const [openAssignPaymentAccountPopup, setOpenAssignPaymentAccountPopup] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string | undefined>();
    const [hasPaymentOrdersModule, setHasPaymentOrdersModule] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = allTabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setValue(newValue);
    };

    useEffect(() => {
        setStatus("loading");
        Promise.all([getExpedient(context.session!.tenant!.id, context.session!.provider!.id), 
            getTenantConfigurations(context.session!.tenant!.id)
        ]).then(([metadata, tenantConfigurations]) => {
            setMetadata(metadata);
            onChanged(metadata);
            if ("NO_EXPEDIENT" === metadata?.status) {
                setDisabled(false);
            }
            let empty = Object.keys(metadata.fields).length === 0 &&
                Object.keys(metadata.contacts).length === 0 &&
                Object.keys(metadata.addresses).length === 0 &&
                Object.keys(metadata.bank_accounts).length === 0;
            if (empty) {
                setStatus("missing");
            } else {
                setStatus("loaded");
            }
            getDateExpired(metadata.fields);
            setHasPaymentOrdersModule(tenantConfigurations.has_payment_orders);
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line 
    }, [match.params.providerId]);

    const getDateExpired = (data: Map<string, ProviderExpedientField>) => {
        if (!data) {
            return;
        }
        var values = Object.values(data) as ProviderExpedientField[];
        let dateExpered = new Date();

        values.forEach((item) => {
            if (item.status === "EXPIRED" && item.configuration.expires_at != null && item.configuration.expires_at < dateExpered) {
                dateExpered = item.configuration.expires_at;
            }
        })
        setExpiredAt(dateExpered);
    }
    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line 
    }, [qs.tab]);

    const onChanged = (metadata: ProviderExpedient) => {
        setStatus("loading");
        let badgets = expedientBadgets(metadata);
        let allRequiredFields = (badgets.addresses + badgets.contacts + badgets.bank_accounts + badgets.fields) === 0;

        let disabled = metadata.status !== "REJECTED" && metadata.status !== "EDITED" && metadata.status !== "CREATED";
        if (disabled && "NO_EXPEDIENT" === metadata?.status) {
            disabled = false;
        }
        setReady(allRequiredFields && !disabled);
        setBadges(badgets);
        setDisabled(disabled);
        setMetadata(metadata);
        setStatus("loaded");
    };

    const tabContent = (index: number) => {
        if (!metadata) return;

        let current = allTabs[index];
        if (!current) return;

        switch (current.name) {
            case "fields":
                return <ExpedientProviderFields
                    metadata={metadata}
                    onChange={onChanged}
                    disabled={disabled}
                />
            case "contacts":
                return <ExpedientProviderContacts
                    metadata={metadata}
                    onChange={onChanged}
                    disabled={disabled}
                />;
            case "addresses":
                return <ExpedientProviderAddresses
                    metadata={metadata}
                    onChange={onChanged}
                    disabled={disabled}
                />;
            case "bank_accounts":
                return <ExpedientProviderBankAccounts
                    metadata={metadata}
                    onChange={onChanged}
                    disabled={disabled}
                />;
            default:
                return <div>{current.name}</div>
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const confirmSendExpedient = () => {
        setSubmitting(true);
        sendExpedient(context.session!.tenant!.id, context.session!.provider!.id).then((response) => {
            setDialog(false);
            if (response.status) {
                setSuccess(translate(`expedients.status_metadata.${response.status}`) as string)
            } else {
                setWarning(translate("expedients.status_metadata.ERROR") as string)
            }
            onChanged(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const confirmEditExpedient = () => {
        setSubmitting(true);
        setEdit(false);
        changeStatus(context.session!.tenant!.id, context.session!.provider!.id, "EDITED").then((response) => {
            setSuccess(translate(`expedients.status_metadata.${response.status}`) as string);
            onChanged(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const getSubtitle = () => {
        let text = !metadata.status && !ready ? undefined : translate(`expedients.status_metadata.${metadata.status || "READY"}`) as string;
        return metadata.status === "ACCEPTED" || metadata.status === "REJECTED" ? `${text} | ${formatDate(metadata.approved || metadata.last_review, "lll")}` : text;
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status === "missing") {
        return (
            <Typography variant="body1" component="h5" color="textPrimary" align="center">
                {translate("expedients.missing", { "tenant": context.session!.tenant!.name })}
            </Typography>
        );
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onEdit = () => {
        if (!metadata.status || metadata.status === undefined || metadata.status === "CREATED") {
            confirmEditExpedient();
        } else {
            setEdit(true);
        }
    }

    const onClickHistory = () => {
        setOpenHistoryPopup(true);
    };

    const onCloseHistoryPopup = () => {
        setOpenHistoryPopup(false);
    }

    const onClickAssignPaymentAccountPopup = () => {
        setOpenAssignPaymentAccountPopup(true);
    }
    
    const onCloseAssignPaymentAccountPopup = () => {
        setOpenAssignPaymentAccountPopup(false);
    }

    const onSubmitAssignPaymentAccountPopup = (expedient: ProviderExpedient) => {
        setMetadata(expedient);
        setSuccess(translate("expedients.payment_bank_accounts.popup.success") as string);
        setOpenAssignPaymentAccountPopup(false);
    }

    const onErrorAssignPaymentAccountPopup = (error: any) => {
        setError(error.message);
        setOpenAssignPaymentAccountPopup(false);
    }

    const onSelectHistoryExpedient = (id: string) => {
        setRedirect(`/history-expedient/${id}`);
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    const downloadFiles = () => {
        getFilesExpedient(context.session!.tenant!.id, context.session!.provider!.id).then(response => {
            window.open(response.url, "_blank");
        }).catch((error) => {
            setError(translate(`expedients.not_files_in_expedient`) as string);
        });
    }

    const buildPaymentBankAccountsLabel = () : string => {
        return Object.entries(metadata.payment_bank_accounts!).map(([currency, account]) => `${account?.data?.value?.bank_name ?? (account?.data?.value?.bank ?? " - ")} - ${currency}`).join(", ");
    }

    return (
        <Surface title={translate("expedients.single") as string}
            subtitle={getSubtitle()}
            icon={!metadata.status && !ready ? <ExpedientIcon /> : <ProviderExpedientIcon status={metadata.status || "READY"} />}
            avatarColor={!metadata.status && !ready ? undefined : getColor(metadata.status || "READY")}
            className="PaperPagination PaperTabContainer"
            titleActions={
                (["SENT", "ACCEPTED"].includes(metadata?.status ?? "") ?
                    (<Fab aria-label="add" color="primary" size="small" title={translate("cfdis.prorate.icon_edit") as string} onClick={onEdit} >
                        <EditTwoIcon />
                    </Fab>) :
                    (<Fab color="primary" size="small" title={translate("buttons.send") as string} onClick={() => setDialog(true)} disabled={!ready} >
                        <SendIcon />
                    </Fab>))
            }>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <Box pt={2} px={2}>
                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                            <ProviderExpedientStatusHeader short={false}
                                status={metadata.status || (metadata.has_data ? "TO_SEND" : "NO_EXPEDIENT")}
                                approved={metadata.approved}
                                lastReview={metadata.last_review}
                                expired_at={expiredAt}
                                expired={"ACCEPTED" === metadata.status ? metadata.expired: false }
                                button={

                                    <Box mr={2} mt={1} >
                                        <Grid container justify="flex-end" spacing={1}>
                                            <Grid item xs="auto">
                                                <Button
                                                    color="primary"
                                                    variant="text"
                                                    startIcon={<HistoryIcon />}
                                                    onClick={metadata.has_data ? onClickHistory : undefined}>
                                                    {translate("expedients.check_history" as string)}
                                                </Button>
                                            </Grid>

                                            <Grid item xs="auto">
                                                <Button color="primary" variant="text"
                                                    onClick={downloadFiles}
                                                    startIcon={<DownloadIcon />}>
                                                    {translate("expedients.download" as string)}
                                                </Button>
                                            </Grid>

                                            {(context.isGrantedAny(["ProvidersUpdate", "OwnerProvidersUsersRead", "VendorsUpdate"]) ?
                                                (
                                                    <Box mr={1} mt={1}>
                                                        <Fab aria-label="add" color="primary" size="small" title={translate("expedients.edit.title") as string} onClick={onEdit} >
                                                            <EditAttributesIcon />
                                                        </Fab>
                                                    </Box>
                                                ) : undefined)}
                                        </Grid>
                                    </Box>
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
                {(allTabs[value].name == "bank_accounts" && hasPaymentOrdersModule && Object.entries(metadata.bank_accounts).length > 1) ? 
                            <Box pt={2} px={2}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs="auto">
                                        <Typography variant="body1">
                                            {translate("expedients.payment_bank_accounts.title" as string) + ':' }
                                        </Typography>
                                    </Grid>
                                    {Object.entries(metadata.payment_bank_accounts!).length > 0 &&
                                        <Grid item xs="auto">
                                            <Button
                                                disabled={disabled}
                                                color="primary"
                                                variant="text"
                                                onClick={onClickAssignPaymentAccountPopup}
                                                endIcon={<EditIcon />}
                                                >
                                                {buildPaymentBankAccountsLabel()}
                                            </Button>
                                        </Grid>
                                    }
                                    {(!metadata.payment_bank_accounts || Object.entries(metadata.payment_bank_accounts!).length == 0) &&
                                        <Grid item xs="auto">
                                            <Typography variant="body1">
                                                {translate("expedients.payment_bank_accounts.empty" as string)}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box> : undefined
                        }
                <Box mt={2}>
                    <Divider />
                </Box>
            </Grid>

            <Tabs value={value} onChange={handleChange} aria-label={translate("expedients.single") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {allTabs.map((tab: ExpedientProviderTab, index: number) => (
                    <Tab key={tab.name} label={(
                        <Badge variant="dot" color="error" invisible={badges[tab.name] <= 0} >
                            {tab.title}
                        </Badge>
                    )} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {allTabs.map((tab: ExpedientProviderTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <DialogPopup
                open={dialog}
                title={translate("expedients.confirm.title") as string}
                disableEscapeKeyDown={submitting}
                disableBackdropClick={submitting}
                disable={submitting}
                closeText={translate("buttons.cancel") as string}
                onClose={() => setDialog(false)}
                closeColor="default"
                button={
                    <Button onClick={confirmSendExpedient} variant="outlined" color="primary" disabled={submitting}>
                        {translate("buttons.confirm")}
                    </Button>
                }>
                <Typography variant="body2">
                    {translate("expedients.confirm.text", { "tenant": context.session!.tenant!.name })}
                </Typography>
            </DialogPopup>
            <DialogPopup
                open={edit}
                title={translate("expedients.edit.title") as string}
                closeText={translate("buttons.cancel") as string}
                onClose={() => setEdit(false)}
                closeColor="default"
                button={
                    <Button onClick={confirmEditExpedient} variant="outlined" color="primary" >
                        {translate("buttons.confirm")}
                    </Button>
                }>
                <Typography variant="body2">
                    {translate("expedients.edit.text")}
                </Typography>
            </DialogPopup>
            {openHistoryPopup &&
                <ProviderExpedientHistoryPopup
                    onClose={onCloseHistoryPopup}
                    onSelectExpedient={onSelectHistoryExpedient}
                    tenantId={context.session!.tenant!.id}
                    providerId={context.session!.provider!.id}
                />
            }
            {openAssignPaymentAccountPopup && 
                <ProviderAssignPaymentBankAccountsPopup 
                tenantId={context.session!.tenant!.id} 
                providerId={context.session!.provider!.id}
                onClose={onCloseAssignPaymentAccountPopup} 
                expedient={metadata}
                onError={onErrorAssignPaymentAccountPopup}
                onSubmit={onSubmitAssignPaymentAccountPopup}/>
            }

        </Surface>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`expedient-tabpanel-${index}`}
            aria-labelledby={`expedient-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    <Box pt={3}>
                        <Grid container>
                            <Grid item xs={12}>
                                {children}
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        component: "a",
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
        },
        id: `expedient-tab-${index}`,
        "aria-controls": `expedient-tabpanel-${index}`,
    };
}