import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { ExtendedField } from "../../model/ExtendedField";
import { Cfdi } from "../../model/Cfdi";
import { Workflow } from "../../model/Workflow";
import { DocumentLine } from "../../model/Document";
import translate from "../../i18n/Translator";
import { InputRef } from "../ValidatedInput";
import DialogPopup from "../DialogPopup";
import { WarningSnackbar } from "../Snackbars";
import FieldItemForm from "./FieldItemForm";
import  { CallbackFunction } from "./OnSubmitField";

interface FieldItemEditPopupProps {
    field: ExtendedField;
    tenantId: string;
    providerId: string;
    fieldId: string;
    payableDocument?: Cfdi;
    workflow?: Workflow;
    doAction(request: ExtendedField, callback: CallbackFunction): any;
    onClose(): any;
}

export default function FieldItemEditPopup(props: FieldItemEditPopupProps) {
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<ExtendedField>({
        id: props.field.id,
        value: ""
    } as ExtendedField);
    const [errorMessage, setErrorMessage] = useState<string>();

    const onSubmit = () => {
        if (props.field.required && (!request.value || request.value === "")) {
            if (props.field.type === "PURCHASE_ORDERS") {
                setWarning(translate("cfdis.template.without_purchase_order") as string);
            } else if (props.field.type === "WAREHOUSE_DELIVERIES") {
                setWarning(translate("cfdis.template.without_warehouse_delivery") as string);
            } else {
                setWarning(translate("cfdis.template.warning") as string);
            }
            return;
        }

        if (!request.warehouse_delivery_lines_read_only) {
            if (request.lines && request.lines.filter(line => !line.quantity_in_cfdi || isNaN(line.quantity_in_cfdi)).length > 0) {
                setWarning(translate("purchase_orders.missing_lines") as string);
                return;
            }

            if (request.lines && request.lines.filter(line => (line.quantity_in_cfdi || 0) < 0).length > 0) {
                setWarning(translate("purchase_orders.warning_lines") as string);
                return;
            }

            if (request.invalid) {
                setWarning(translate("purchase_orders.invalid_lines") as string);
                return;
            }
        }



        props.doAction(request, (error) => {
            setErrorMessage(error);
        });
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, value: value });
    };

    const onValueOnTableChanged = (values: DocumentLine[], valid?: boolean, warehouse_delivery_lines_read_only?: boolean) => {
        let unique = {} as any;
        values.forEach(val => {
            unique[val.external_id] = val.external_id;
        });

        let temp = request;
        temp.value = Object.keys(unique).join(",");
        temp.lines = valid ? values : [];
        temp.invalid = !valid;
        temp.warehouse_delivery_lines_read_only = warehouse_delivery_lines_read_only;
        setRequest(temp);
    };

    const hasChangadDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            let temp = request;
            temp.value = date + "";
            setRequest(temp);
        }
    };

    const hasChangedCatalog = (valueSelected: string) => {
        if (valueSelected) {
            let temp = request;
            temp.value = valueSelected !== "---" ? valueSelected : "";
            setRequest(temp);
        }
    };

    return (
        <DialogPopup open
            title={props.field.name}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={props.field.read_only}>
                    {translate("buttons.save")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            maxWidth="lg"
            onClose={props.onClose}>
            <Grid container className="ShippingField">
                {props.field.description && (props.field.type === "FILE" || props.field.type === "DATE") && (
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {props.field.description}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FieldItemForm
                        tenantId={props.tenantId}
                        providerId={props.providerId}
                        fieldId={props.fieldId}
                        payableDocument={props.payableDocument}
                        field={props.field}
                        value={props.field.value || ""}
                        workflow={props.workflow}
                        onValueChanged={hasChanged}
                        onChangedDate={hasChangadDate}
                        onChangedCatalog={hasChangedCatalog}
                        onValueOnTableChanged={onValueOnTableChanged}
                        disabled={false}
                        errorMessage={errorMessage}
                    />
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}