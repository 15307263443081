import { Card, CardHeader, CardContent, Box, Grid } from "@material-ui/core";
import React from "react";
import { Cfdi } from "../model/Cfdi";
import translate from "../i18n/Translator";
import Row from "../components/Row";
import DateFormat from "../components/DateFormat";

interface AdvancePaymentInfoProps {
    advancePayment: Cfdi;
}

export default function AdvancePaymentInfo(props: AdvancePaymentInfoProps) {

    return (
        <Card variant="outlined">
            <CardHeader subheader={(
                <strong>{translate("advance_payments.info_title")}</strong>
            )} disableTypography />
            <CardContent>
                <Box px={2}>
                    <Grid container spacing={1}>
                        <Row title={translate("advance_payments.date") as string}
                            content={<DateFormat date={props.advancePayment.date} format="L" />} />
                        <Row title={translate("advance_payments.company") as string} content={props.advancePayment.metadata.company_name} />
                        <Row title={translate("advance_payments.description") as string} content={props.advancePayment.description} />
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );

}