import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Typography, Box, DialogTitle, DialogContent, Dialog, DialogActions } from '@material-ui/core';

import { createExpression, getExpression, updateExpression } from "../api/ExpressionAPI";
import translate from "../i18n/Translator";
import { Expression, ExpressionRequest } from "../model/Expression";

import Progress from "../components/Progress";
import { WarningSnackbar } from '../components/Snackbars';
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";

interface ExpressionFormProps {
    expressionId: string
    onClose(): any;
    submit(request: Expression): void;
}

export default function ExpressionFormPopup(props: ExpressionFormProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const expressionId = props.expressionId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<ExpressionRequest>({} as ExpressionRequest);
    const [validations, setValidations] = useState({} as any);
    const [warning, setWarning] = useState<string | undefined>();

    const submitPromise = (): Promise<Expression> => {
        if (expressionId) {
            return updateExpression(tenantId, expressionId, request);
        }
        return createExpression(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (expressionId) {
            getExpression(tenantId, expressionId).then((expression) => {
                setRequest({
                    name: expression.name,
                    expression: expression.expression,
                    error_message: expression.error_message,
                } as ExpressionRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    }, [tenantId, expressionId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };


    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((expression) => {
            props.submit(expression);
        }).catch((error) => {
            setWarning(error.message);
            setSubmitting(false);
        });
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">


            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate(expressionId ? "expressions.edit" : "expressions.new") as string}
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>

                    {"loading" === status && (<Progress />)}

                    {status !== "loaded" && (
                        <Typography variant="body1" component="h5" color="error" align="center">
                            {status}
                        </Typography>
                    )}

                    {"loaded" === status && (<Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="name" name="name"
                                value={request.name}
                                label={translate("expressions.name") as string}
                                required={true} disabled={submitting}
                                margin={margin}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="expression" name="expression"
                                value={request.expression}
                                label={translate("expressions.expression") as string}
                                required={true} disabled={submitting}
                                margin={margin}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="error_message" name="error_message"
                                value={request.error_message}
                                label={translate("expressions.error_message") as string}
                                required={true} disabled={submitting}
                                margin={margin}
                                onValueChanged={hasChanged} />
                        </Grid>

                    </Grid>)
                    }
                </DialogContent>
                <DialogActions>
                    <Box >
                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                            <Grid item xs={12} md="auto">
                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                    {translate(expressionId ? "buttons.update" : "buttons.add")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button variant="outlined" color="primary" size="large" disabled={submitting} onClick={props.onClose}>
                                    {translate("buttons.cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>

            </form>
            <WarningSnackbar message={warning} onClose={() => { setWarning(undefined) }} />

        </Dialog>
    );
}