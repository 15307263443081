import React, { useContext } from "react";

import { Grid, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { MultiselectValue } from "../components/MultiselectDropList";
import { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";

interface NotificationGroupAutocompleteProps {
    noticationGrups: MultiselectValue[];
    disabled: boolean;
    value?: MultiselectValue | undefined;
    required?: boolean;
    onChange(value: string, inputRef?: InputRef): any;
}

export default function NotificationGroupAutocomplete(props: NotificationGroupAutocompleteProps){
    
    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        props.onChange(value, inputRef);
    };
    
    return (
        <Grid>
            <Autocomplete
                size="small"
                options={props.noticationGrups || []}
                getOptionLabel={(elemento) => elemento.title}
                disabled={props.disabled}
                noOptionsText={translate("requisitions.projects.new_project.no_options_autocomplete") as string}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" required={props.required} label={translate("requisitions.projects.new_project.select_notification_group") as string} fullWidth />
                )}
                onChange={(event, newValue) => {
                    if (newValue) {
                        onFilterChanged(newValue.value);
                    } else {
                        onFilterChanged("");
                    }
                }}
                getOptionSelected={(opt, val) => opt.value === val?.value}
                value={props.value}

            />
        </Grid>);
}