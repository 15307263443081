import React from "react";
import { Grid } from "@material-ui/core";
import { Route, Redirect, Switch } from "react-router-dom";

import SignInForm from "../auth/SignInForm"
import SignUpForm from "../auth/SignUpForm"
import RecoverPasswordForm from "../auth/RecoverPasswordForm"
import ResetPasswordForm from "../auth/ResetPasswordForm"
import SignUpMessage from "../auth/SignUpMessage";
import ConfirmTenant from "../auth/ConfirmTenant";
import SignIn2FAForm from "../auth/SignIn2FAForm";
import VerifyEmail from "../auth/VerifyEmail";
import SetupPassword from "../auth/SetupPassword";
import PasswordExpired from "../auth/PasswordExpired";
import OAuthLogin from "../auth/OAuthLogin";

export default function ExternApp() {

    return (
        <Grid container justify="center" alignItems="center" style={{ "minHeight": "100vh" }}>
            <Switch>
                <Route path="/verify-email/:code" component={VerifyEmail} />
                <Route path="/recover-password" component={RecoverPasswordForm} />
                <Route path="/reset-password/:code" component={ResetPasswordForm} />
                <Route path="/password-expired" component={PasswordExpired} />
                <Route path="/signin/:code/password" component={SetupPassword} />
                <Route path="/signin/:message/fail" component={SignInForm} />
                <Route path="/signin/:code" component={SignIn2FAForm} />
                <Route path="/signin" component={SignInForm} />
                <Route path="/signup/:code/confirm" component={ConfirmTenant} />
                <Route path="/signup/:code/password" component={SetupPassword} />
                <Route path="/signup/:code" component={SignUpMessage} />
                <Route path="/signup" component={SignUpForm} />
                <Route path="/oauth-login/:tenantId" component={OAuthLogin} />
                <Redirect from="/*" to="/signin" />
            </Switch>
        </Grid>
    );

}