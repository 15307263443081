import React from "react";

import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import translate from "../i18n/Translator";
import { Entity as Provider, getCompanyNames } from "../model/Provider";
import { formatDate } from "../components/DateFormat";
import { GridableColumn } from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import ProvidersEfosList from "./ProvidersEfosList";
import { ProviderListCallbacks, ProviderNameColumnTitle, ProviderNameColumnValue } from "./Providers";
import { BlackListValidations } from "../model/Cff69";

export default function ProvidersEfosView() {

    const efosColumn = (provider: Provider) => {
        if (provider.efos) {
            return (
                <div>
                    <Ellipsis text={translate(`efo.status.${provider.efos.status}`) as string} uppercased={false} lenght={100} /><br />
                    <Ellipsis text={formatDate(provider.efos.published, "ll")} lenght={0} secondary uppercased={false} />
                </div>
            );
        }
        return (<span>---</span>);
    };

    const inOrder = (blackList: BlackListValidations) => {
        return blackList.not_located.status === "LOCALIZADO" && 
                blackList.canceled_result.status === "LIMPIO" && 
                blackList.enforceable_result.status === "LIMPIO" && 
                blackList.firm_result.status === "LIMPIO" &&
                blackList.sentences_result.status === "LIMPIO";
    };

    const blackListColumn = (provider: Provider) => {
        if (provider.black_list_validations) {
            if(inOrder(provider.black_list_validations)){
                return (<div>
                    <Ellipsis text={translate(`69cff.status.IN_ORDER`) as string} uppercased={false} lenght={100} /><br/>
                    <Ellipsis text={formatDate(provider.black_list_validations.not_located.published, "ll")} lenght={0} secondary uppercased={false} />
                </div>);
            }else{
                return (
                    <div>
                        {provider.black_list_validations.not_located.status === "NO_LOCALIZADO" && (
                            <div>
                                <Ellipsis text={translate(`69cff.status.${provider.black_list_validations.not_located.status}`) as string} uppercased={false} lenght={100} /><br />
                                <Ellipsis text={formatDate(provider.black_list_validations.not_located.published, "ll")} lenght={0} secondary uppercased={false} />
                                <br/>
                            </div>
                        )}
                        {provider.black_list_validations.canceled_result.status === "CANCELADO" && (
                            <div>
                                <Ellipsis text={translate(`69cff.status.${provider.black_list_validations.canceled_result.status}`) as string} uppercased={false} lenght={100} /><br />
                                <Ellipsis text={formatDate(provider.black_list_validations.canceled_result.published, "ll")} lenght={0} secondary uppercased={false} />
                                <br/>
                            </div>
                        )}
                        {provider.black_list_validations.enforceable_result.status === "EXIGIBLE" && (
                            <div>
                                <Ellipsis text={translate(`69cff.status.${provider.black_list_validations.enforceable_result.status}`) as string} uppercased={false} lenght={100} /><br />
                                <Ellipsis text={formatDate(provider.black_list_validations.enforceable_result.published, "ll")} lenght={0} secondary uppercased={false} />
                                <br/>
                            </div>
                        )}
                        {provider.black_list_validations.firm_result.status === "FIRME" && (
                            <div>
                                <Ellipsis text={translate(`69cff.status.${provider.black_list_validations.firm_result.status}`) as string} uppercased={false} lenght={100} /><br />
                                <Ellipsis text={formatDate(provider.black_list_validations.firm_result.published, "ll")} lenght={0} secondary uppercased={false} />
                                <br/>
                            </div>
                        )}
                        {provider.black_list_validations.sentences_result.status === "SENTENCIADO" && (
                            <div>
                                <Ellipsis text={translate(`69cff.status.${provider.black_list_validations.sentences_result.status}`) as string} uppercased={false} lenght={100} /><br />
                                <Ellipsis text={formatDate(provider.black_list_validations.sentences_result.published, "ll")} lenght={0} secondary uppercased={false} />
                            </div>
                        )}
                    </div>
                );
            }
        }
        return (<span>---</span>);
    };

    const getColumns = (providers: Provider[], showChecks: boolean, selected: string[], callbacks: ProviderListCallbacks) => {
        let all = [
            {
                title: (
                    <ProviderNameColumnTitle
                        total={providers.length}
                        selected={selected.length}
                        showChecks={showChecks}
                        callbacks={callbacks} />
                ),
                converter: (provider) => (
                    <ProviderNameColumnValue
                        provider={provider}
                        checked={selected.indexOf(provider.id) >= 0}
                        showChecks={showChecks}
                        callbacks={callbacks} 
                        showIcons={true} 
                    />
                ),
                fullWidth: true,
                xs: true
            },
            {
                title: translate("providers.companies.title") as string,
                converter: (provider) => getCompanyNames || "---",
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                xl: 2
            },
            {
                title: translate("providers.external_id") as string,
                converter: (provider) => provider.external_id || "---",
                xs: false,
                sm: false,
                md: false,
                lg: false,
                xl: 1
            },
            {
                title: translate("providers.status.title") as string,
                converter: (provider) => provider.status ? translate(`providers.status.${provider.status}`) : "---",
                xs: false,
                sm: false,
                md: false,
                lg: false,
                xl: 1
            },
            {
                title: translate("efo.column") as string,
                converter: efosColumn,
                xs: false,
                sm: 2,
                lg: 2,
                xl: 1
            },
            {
                title: translate("cff_69.title") as string,
                converter: blackListColumn,
                xs: false,
                sm: 2,
                lg: 2,
                xl: 2
            },
            {
                title: (
                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                ),
                converter: (provider) => (
                    <IconButton aria-label="options"
                        color="default"
                        size="small"
                        onClick={callbacks.onClickedOptions(provider)}
                        disabled={selected.length > 0}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: "auto"
            }
        ] as GridableColumn<Provider>[];

        return all.filter((column) => column !== null);
    };

    return (
        <ProvidersEfosList getColumns={getColumns} subtitle={translate("providers.views.efos") as string} />
    );

}