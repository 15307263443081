import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { Cfdi } from "../model/Cfdi";
import { Redirect, useHistory } from "react-router-dom";
import translate from "../i18n/Translator";
import Gridable, { GridableColumn } from "../components/Gridable";
import { CfdiDescription, CfdiAmounts } from "../cfdi/CfdiComponents";
import { ProviderFromCfdi } from "../providers/Providers";
import { getTranslateStatus, getWorkFlowTranlateStatus } from "../cfdi/CfdiTranslateStatusUtils";

interface PurchaseOrderCfdiListProps {
    data: Cfdi[];
}

export default function PurchaseOrderCfdiList(props: PurchaseOrderCfdiListProps) {
    const [redirect, setRedirect] = useState<string>();
    const history = useHistory();

    const onClickedRow = (cfdi: Cfdi) => {
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=purchase-order`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const getStatus = (cfdi: Cfdi) => {

        return getTranslateStatus(cfdi, getWorkFlowTranlateStatus());

    };

    const getColumns = () => {
        const columns = [
            {
                title: translate("cfdis.columns.comprobante") as string,
                converter: (cfdi) => (
                    <CfdiDescription cfdi={cfdi} />
                ),
                fullWidth: true,
                xs: 5,
                sm: 5,
                md: 4,
                lg: 3,
                xl: 3,
            },
            {
                title: translate("payment.columns.provider") as string,
                converter: (cfdi) => (
                    <ProviderFromCfdi cfdi={cfdi} />
                ),
                fullWidth: true,
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                lx: 2,
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="left">
                        <strong>{translate("cfdis.columns.description")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <Typography variant="body2" component="h6" align="left">
                        {cfdi.description}
                    </Typography>
                ),
                fullWidth: true,
                justify: "flex-end",
                xs: 3,
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="right">
                        <strong>{translate("cfdis.columns.totals")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <CfdiAmounts cfdi={cfdi} showBalance />
                ),
                fullWidth: true,
                xs: false,
                sm: false,
                md: 2,
                lg: 2
            },
            {
                title: (
                    <Typography variant="body2" component="h6" align="left">
                        <strong>{translate("cfdis.columns.status")}</strong>
                    </Typography>
                ),
                converter: (cfdi) => (
                    <Typography variant="body2" component="h6" align="left">
                        {getStatus(cfdi)}
                    </Typography>
                ),
                fullWidth: true,
                justify: "flex-end",
                xs: false,
                sm: 2,
                md: 2,
                lg: 2
            },
        ] as GridableColumn<Cfdi>[];
        return columns.filter(col => !!col);
    };

    return (
        <>
            <Gridable
                items={props.data}
                loading={false}
                empty={translate("cfdis.history.empty") as string}
                columns={getColumns()}
                onClick={onClickedRow} />
        </>
    );
}
