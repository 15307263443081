import React, { useState, useEffect, useContext } from "react";
import { RouterParams } from "../router/RouterParams";
import { Box, Grid, Typography, Card, CardHeader, CardContent, Divider, Button } from "@material-ui/core";
import translate from "../i18n/Translator";
import { getPlan } from "../api/PlanTaxStampAPI";
import { getBankAccountEnterprise } from "../api/BankAccountEnterpriseAPI";
import { PlanTaxStamp, ContractRequest } from "../model/PlanTaxStamp";
import { BankAccountEnterprise } from "../model/BankAccountEnterprise";
import Progress from "../components/Progress";
import ReactHtmlParser from "html-react-parser";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import UploaderInput from "../components/UploaderInput";
import { getReference, buyPlan } from "../api/ProviderMembershipTaxStampApi";
import { EmailValidator, PhoneValidator, phoneFormat, } from "../components/Validators";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { AuxiliarDocumentsIcon } from "../components/Icons";
import { AppContext } from "../context/AppContext";

  function getSteps() {
    return [translate("plan_tax_stamp.buy_plan.plan_selected") as string, translate("plan_tax_stamp.buy_plan.confirm_data") as string, translate("plan_tax_stamp.buy_plan.upload_voucher") as string];
  }

export default function BuyPlanProvider({ match }: RouterParams) {
  const externalId = match.params.externalId;
  const rfcEnterprise = "FOC140516174";
  const context = useContext(AppContext);
  const rfcProvider = context.session?.provider?.rfc || "";
  const [status, setStatus] = useState<string>("loading");
  const [plan, setPlan] = useState<PlanTaxStamp>();
  const [warning, setWarning] = useState<string>();
  const [error, setError] = useState<string>();
  const [bankAccount, setBankAccount] = useState<BankAccountEnterprise>({} as BankAccountEnterprise);
  const phoneValidator = new PhoneValidator();
  const emailValidator = new EmailValidator();
  const [format, setFormat] = useState("");
  const [request, setRequest] = useState<ContractRequest>({
      provider_name: "",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      url_voucher: "",
      plan_external_id: externalId,
      provider_rfc: rfcProvider
  } as ContractRequest);
  const [redirect, setRedirect] = useState<string>();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const load = () => {
    Promise.all([
      getPlan(externalId),
      getBankAccountEnterprise(rfcEnterprise),
      getReference(rfcProvider, externalId)
  ]).then(responses => {
          setPlan(responses[0]);
          setBankAccount(responses[1]);
          setRequest({...request, reference: responses[2].reference});
          setStatus("loaded");
      }).catch(error => {
          setStatus(error.message);
      });
  };

  useEffect(load, []);

  const handleNext = (step: number) => {
    if(step === 1) {
      let message = validateRequest();
      if(message !== ""){
        setWarning(message);
        return;
      }
    } else if(step === 2){
      if(request.url_voucher === ""){
        setWarning(translate("plan_tax_stamp.buy_plan.voucher_warning") as string);
        return;
      } else {
        buyPlan(request).then(response => {
          if(context.session && context.session.provider){
            context.session.provider.membership_tax_stamp_id = response.provider_membership_tax_stamp.id;
            context.session.provider.status_membership_tax_stamp = "TO_VERIFY_MEMBERSHIP";
          }
          setRedirect(`/plan-tax-stamp/${response.provider_membership_tax_stamp.id}/info-payment`);
          return;
        }).catch(error => {
            setError(error.message);
        });
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const validateRequest = () => {
    let response = "";
    if(request.provider_name === ""){
      response = translate("plan_tax_stamp.buy_plan.request_warning", { "field": translate("plan_tax_stamp.buy_plan.confirm_data.provider_name")}) as string;
    } else if(request.contact_name === ""){
      response = translate("plan_tax_stamp.buy_plan.request_warning", { "field": translate("plan_tax_stamp.buy_plan.confirm_data.contact_name")}) as string;
    }else if(request.contact_phone.length != 10){
      response = translate("plan_tax_stamp.buy_plan.request_warning", { "field": translate("plan_tax_stamp.buy_plan.confirm_data.contact_phone")}) as string;
    } else if(request.contact_email === "" || !request.contact_email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )){
      response = translate("plan_tax_stamp.buy_plan.request_warning", { "field": translate("plan_tax_stamp.buy_plan.confirm_data.contact_email")}) as string;
    }
    return response;
  };

  const handleBack = () => {
    if(activeStep === 0){
      setRedirect(`/plan-tax-stamp/plans`);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const hasChanged = (name: string, value: string, inputRef: InputRef) => {
      setRequest({ ...request, [name]: value });
  };

  const hasChangedPhone = (name: string, value: string, inputRef: InputRef) => {
    hasChanged(name, value, inputRef);
    setFormat(phoneFormat(value));
};

  const copyToClipoard = () => {
   let text = translate("plan_tax_stamp.buy_plan.clipboard.info") as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.name", { "name": bankAccount.name}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.rfc", { "rfc": bankAccount.rfc}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.bank", { "bank": bankAccount.bank}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.account", { "account": bankAccount.account_number}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.clabe", { "clabe": bankAccount.clabe}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.currency", { "currency_label": translate(`currency.${bankAccount.currency}`), "currency": bankAccount.currency}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.reference", { "reference": request.reference}) as string;
   text += "\n";
   text += translate("plan_tax_stamp.buy_plan.clipboard.total", { "total": plan!.price}) as string;

    navigator.clipboard.writeText(text);
  };

  const onCloseSnackbars = () => {
    setError(undefined);
    setWarning(undefined);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
        <Card variant="outlined">
            <CardHeader subheader={
                <Grid container>
                    <Grid item xs={6}>
                        <Box pt={1}><strong>{plan!.name}</strong></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="primary" style={{fontSize: "20px"}}><b>
                                    <NumberFormat value={plan!.price}
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                        thousandSeparator="," displayType="text"
                                    />
                                </b></Typography>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            } disableTypography />
            <Divider/>
            <CardContent>
                {ReactHtmlParser(plan!.description)}
            </CardContent>
        </Card>);
      case 1:
        return (
        <Card variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item >
                    <Box pb={1}>
                        <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ValidatedInput
                            type="text"
                            id="provider_name" name="provider_name"
                            label={translate("plan_tax_stamp.buy_plan.confirm_data.provider_name") as string}
                            value={request.provider_name}
                            required
                            margin={"dense"}
                            disabled={false}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput
                            type="text"
                            id="contact_name" name="contact_name"
                            label={translate("plan_tax_stamp.buy_plan.confirm_data.contact_name") as string}
                            value={request.contact_name}
                            required
                            margin={"dense"}
                            disabled={false}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput
                            type="phone"
                            id="contact_phone" name="contact_phone"
                            label={translate("plan_tax_stamp.buy_plan.confirm_data.contact_phone") as string}
                            value={request.contact_phone}
                            validator={phoneValidator}
                            required
                            margin={"dense"}
                            disabled={false}
                            format={format} mask=" "
                            onValueChanged={hasChangedPhone} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput
                            type="text"
                            id="contact_email" name="contact_email"
                            label={translate("plan_tax_stamp.buy_plan.confirm_data.contact_email") as string}
                            value={request.contact_email}
                            validator={emailValidator}
                            required
                            margin={"dense"}
                            disabled={false}
                            onValueChanged={hasChanged} />
                    </Grid>
                    </Grid>
                    </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>);
      case 2:
        return <Card variant="outlined">
          <CardHeader subheader={
                <Grid container>
                    <Grid item xs={6}>
                        <Box pt={1}><strong>{translate("plan_tax_stamp.buy_plan.payment_data")}</strong></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={copyToClipoard}
                                    startIcon={<AuxiliarDocumentsIcon />}
                                  >
                                    {translate("plan_tax_stamp.buy_plan.copy_clipoard") as string}
                                  </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            } disableTypography />
        <CardContent>
            <Grid container>
                <Grid item >
                  <Box pb={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container>
                              <Grid item xs={4}>
                              {translate("plan_tax_stamp.buy_plan.enterprise_data.name") as string}
                              </Grid>
                              <Grid item xs={8}>
                                {bankAccount.name}
                              </Grid>
                              <Grid item xs={4}>
                              {translate("plan_tax_stamp.buy_plan.enterprise_data.rfc") as string}
                              </Grid>
                              <Grid item xs={8}>
                              {bankAccount.rfc}
                              </Grid>
                              <Grid item xs={4}>
                              {translate("plan_tax_stamp.buy_plan.enterprise_data.bank") as string}
                              </Grid>
                              <Grid item xs={8}>
                              {bankAccount.bank}
                              </Grid>
                              <Grid item xs={4}>
                              {translate("plan_tax_stamp.buy_plan.enterprise_data.account_number") as string}
                              </Grid>
                              <Grid item xs={8}>
                              {bankAccount.account_number}
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                        <Grid container>
                              <Grid item xs={4}>
                                {translate("plan_tax_stamp.buy_plan.enterprise_data.clabe") as string}
                              </Grid>
                              <Grid item xs={8}>
                                {bankAccount.clabe}
                              </Grid>
                              <Grid item xs={4}>
                                {translate("plan_tax_stamp.buy_plan.enterprise_data.currency") as string}
                              </Grid>
                              <Grid item xs={8}>
                                {translate(`currency.${bankAccount.currency}`)} {`(${bankAccount.currency})`}
                              </Grid>
                              <Grid item xs={4}>
                                {translate("plan_tax_stamp.buy_plan.enterprise_data.reference") as string}
                              </Grid>
                              <Grid item xs={8}>
                              <Typography color="secondary">{request.reference}</Typography>
                              </Grid>
                              <Grid item xs={4}>
                              {translate("plan_tax_stamp.buy_plan.enterprise_data.price") as string}
                              </Grid>
                              <Grid item xs={8}>
                              <Typography color="secondary"><NumberFormat value={plan!.price}
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                        thousandSeparator="," displayType="text"
                                    /></Typography>
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <UploaderInput id="url_voucher" name="url_voucher"
                            label={translate("plan_tax_stamp.buy_plan.url_voucher") as string}
                            value={request.url_voucher} disabled={false}
                            margin="dense" path="advance_payment_file" acceptExtension=".pdf,.jpg,.jpeg,.png"
                            onValueChanged={hasChanged}
                            required />
                        </Grid>
                  </Grid>
                  </Box>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
      default:
        return <></>;
    }
  }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }


    if (status === "loading") {
        return (
            <Progress />
        );
    }

    if (status !== "loaded") {
        return (
            <div>{status}</div>
        );
    }

    return (<>
    <Grid container spacing={4}>
            <Grid item xs={12}>
                <Grid container alignContent="center" alignItems="center" justifyContent="center">
                    <Grid item md={4} xs={1} xl={4}>
                    </Grid>
                    <Grid item md={4} xs={10} xl={3}>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="primary" style={{fontSize: "30px"}}><b>{translate("plan_tax_stamp.plans.title") as string}</b></Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignContent="flex-end" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Typography color="secondary" style={{fontSize: "35px"}}><b>{translate("plan_tax_stamp.plans.title_clic") as string}</b></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={1} xl={5}>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label: string, index: number) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
        <Grid item>
          <Button
            onClick={handleBack}
          >
            {translate("buttons.back") as string}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNext(activeStep)}
          >
            {activeStep === steps.length - 1 ? translate("buttons.done") as string : translate("buttons.next") as string}
          </Button>
        </Grid>
      </Grid>
      <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </>);
}