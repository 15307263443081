import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SwapHorizIcon from '@material-ui/icons/SwapHorizTwoTone';
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';

interface ProvidersExpedientMenuProps {
    anchor: HTMLElement | null;
    totalItems?: number;
    onClose(): any;
    onAssignValidator(): any;
    onChangeTemplateExpedient(): any;
    onExportSelected(): any;
    onExportWithParams(): any;
}

export default function ProvidersExpedientMenu(props: ProvidersExpedientMenuProps) {
    const context = useContext(AppContext);
    const [openExport, setOpenExport] = useState<boolean>(false);

    const handleClickExport = () => {
        setOpenExport(!openExport);
    };

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
            autoFocus={false}
            variant="selectedMenu"
        >
            {context.isGranted("TenantFieldsUpdate") &&
                <MenuItem button onClick={props.onAssignValidator} dense>
                    <ListItemIcon>
                        <PersonAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.assign_validator")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantFieldsUpdate") &&
                <MenuItem button onClick={props.onChangeTemplateExpedient} dense>
                    <ListItemIcon>
                        <SwapHorizIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.template_expedient.description")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["ProvidersRead", "VendorsRead", "ValidationExpedientUpdate"]) && (
                <ListItem button onClick={handleClickExport} dense>
                    <ListItemIcon>
                        <ImportExportIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("providers.export_expedient")} />
                    {openExport ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
            {context.isGrantedAny(["ProvidersRead", "VendorsRead", "ValidationExpedientUpdate"]) && (
                <Collapse in={openExport} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem button onClick={props.onExportSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.selected")} />
                        </ListItem>
                        <ListItem button onClick={props.onExportWithParams} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.all_in_view")} />
                        </ListItem>
                    </List>
                </Collapse>
            )}
        </Menu>
    );

} 