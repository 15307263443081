import { callAPI, HTTPMethod, hasJwt } from "./API";
import { Session, SessionBody } from "../model/Session";
import { OwnerDashboardResponse, ValidatorDashboardResponse, AdminDashboardResponse, DashboardBlackListResponse, ProviderDashboardResponse } from "../model/Dashboard";
import { User, BasicProfileRequest, PasswordRequest, PasswordResponse, DoubleFactorAuthenticationRequest, EmailRequest } from "../model/User";
import { PasswordExpirationTime } from "../model/PasswordExpirationTime";

export async function me(): Promise<User> {
    return await callAPI("/users/me", {
        method: HTTPMethod.GET,
    });
}

export async function getSession(): Promise<Session> {
    if (hasJwt()) {
        return await callAPI("/users/me/session", {
            method: HTTPMethod.GET,
        });
    }

    return new Promise((_, reject) => {
        reject(new Error("Missing authentication token"));
    })
}

export async function updateSession(body: SessionBody): Promise<Session> {
    return await callAPI(`/users/me/session`, {
        method: HTTPMethod.PUT,
        query: {
            tenant_id: body.tenant_id || "",
            provider_id: body.provider_id || "",
            customer_id: body.customer_id || "",
        }
    });
}

export async function updateBasicProfile(request: BasicProfileRequest): Promise<User> {
    return await callAPI("/users/me/basic", {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updatePassword(request: PasswordRequest): Promise<PasswordResponse> {
    return await callAPI("/users/me/password", {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function enable2FA(): Promise<User> {
    return await callAPI("/users/me/2fa", {
        method: HTTPMethod.POST
    });
}

export async function confirm2FA(request: DoubleFactorAuthenticationRequest): Promise<User> {
    return await callAPI("/users/me/2fa", {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function update2FA(request?: DoubleFactorAuthenticationRequest): Promise<User> {
    return await callAPI("/users/me/2fa", {
        method: HTTPMethod.PATCH,
        body: request ?? ({} as DoubleFactorAuthenticationRequest)
    });
}

export async function updateEmail(request: EmailRequest): Promise<User> {
    return await callAPI("/users/me/email", {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function revertEmail(): Promise<User> {
    return await callAPI("/users/me/email", {
        method: HTTPMethod.DELETE
    });
}

export async function getOwnerDashboard(): Promise<OwnerDashboardResponse> {
    return await callAPI("/users/me/dashboard", {
        method: HTTPMethod.GET
    });
}

export async function getValidatorDashboard(): Promise<ValidatorDashboardResponse> {
    return await callAPI("/users/me/dashboard", {
        method: HTTPMethod.GET
    });
}

export async function getProviderDashboard(): Promise<ProviderDashboardResponse> {
    return await callAPI("/users/me/dashboard", {
        method: HTTPMethod.GET
    });
}

export async function getAdminDashboard(): Promise<AdminDashboardResponse> {
    return await callAPI("/users/me/dashboard", {
        method: HTTPMethod.GET
    });
}

export async function getDashboardBlackList(): Promise<DashboardBlackListResponse> {
    return await callAPI("/users/me/dashboard/blacklists", {
        method: HTTPMethod.GET
    });
}


export async function getPasswordExpirationTime(): Promise<PasswordExpirationTime> {
    return await callAPI("/users/me/password-expiration-time", {
        method: HTTPMethod.GET
    });
}