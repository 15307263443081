import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ActorStageFieldResponse, ActorStageFieldsResponse, FilterField, FilterPendingPayments } from "../model/Cfdi";
import { WarningSnackbar } from "../components/Snackbars";
import MultiselectDropList, { MultiselectValue } from "./MultiselectDropList";

interface FilterPopupProps {
    tenantId: string;
    appliedFilters?: FilterField[];
    filterFields: ActorStageFieldsResponse;
    onClose(): any;
    doAction(filterFields: FilterField[]): any;
    onCleanFilter(): any;
}

export default function FilterPopup(props: FilterPopupProps) {
    const [inputFilterFields, setInputFilterFields] = useState<FilterField[]>();
    const [warning, setWarning] = useState<string>();

    useEffect(() => {
        props.filterFields.fields = distinctFilterFields(props.filterFields);
        setInputFilterFields(props.appliedFilters ?? []);
    },[props])

    const distinctFilterFields = (filterFields: ActorStageFieldsResponse) => {
        let uniqueFields : ActorStageFieldResponse[] =[];
        new Map(filterFields.fields.map((field) => [field.field_external_id, field])).forEach(field => {
            uniqueFields.push(field)
        });
        return uniqueFields;
    }

    const onConfirm = () => {
        if (inputFilterFields && inputFilterFields.length > 0) {
            props.doAction(inputFilterFields);
        } else {
            props.onCleanFilter();
        }
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    const onInputFilterField = (filterField: FilterField) => {
        if(!inputFilterFields) {
            return;
        }
        let inputField = inputFilterFields.find(f => filterField.id === f.id);
        if (filterField.values && filterField.values.length > 0) {
            if (inputField) {
                inputField.values = filterField.values;
            } else {
                inputFilterFields.push(filterField);
            }
            setInputFilterFields([...inputFilterFields]);
        } else {
            if (inputField) {
                setInputFilterFields(inputFilterFields.filter(f => f.id !== filterField.id));
            }
        }
    }

    return (
        <DialogPopup open
            title={translate("buttons.filter") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={
                <Box pl={1}>
                    <Button onClick={onConfirm} variant="outlined" color="primary" >
                        {translate("buttons.apply") as string}
                    </Button>
                </Box>
            }
            buttonLeft={
                <Button onClick={props.onCleanFilter} color="secondary" >
                    {translate("buttons.clean_filters") as string}
                </Button>
            }>
            <Grid container>
                {props.filterFields.fields.map(field => {
                    let appliedV;
                    if (props.appliedFilters && props.appliedFilters.length > 0) {
                        let applied = props.appliedFilters.find(af => af.id === field.id);
                        appliedV = applied?.values;
                    }
                    return <FilterFieldItem key={field.id} field={field} onInput={onInputFilterField} appliedValues={appliedV}/>
                })}
            </Grid>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </DialogPopup>
    );

}

interface FilterFieldItemProps {
    field: ActorStageFieldResponse;
    onInput(filterField: FilterField): void;
    appliedValues?: string[];
}

function FilterFieldItem(props: FilterFieldItemProps) {

    const fieldValues = props.field.catalog_values.map(el => {
        return {
            title: el.name,
            value: el.id
        } as MultiselectValue
    });

    const [applied, setApplied] = useState<MultiselectValue[]>(getApplied());

    return (
        <Grid item xs={12}>
            <MultiselectDropList
                title={props.field.field_name}
                margin="dense"
                elementos={fieldValues ? fieldValues : []}
                onChanged={(selected) => {
                    setApplied(fieldValues?.filter(fv => selected.includes(fv.value)));
                    props.onInput({ id: props.field.id, externalId: props.field.field_external_id, values: selected } as FilterField)
                }}
                value={applied} />
        </Grid>
    );


    function getApplied(): MultiselectValue[] | (() => MultiselectValue[]) {
        if (props.appliedValues && props.appliedValues.length > 0) {
            return fieldValues?.filter(fv => props.appliedValues?.includes(fv.value));
        }
        return []
    }
}