import React, { useState, useEffect } from "react";

import { Grid, Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { DataTypeProviderProps, DataTypeProvider, Sorting } from "@devexpress/dx-react-grid";

import translate from "../i18n/Translator";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { WarehouseDeliveries } from "../model/WarehouseDelivery";
import { DocumentLine, WarehouseDeliveryLine } from "../model/Document";
import { listAllForPurchaseOrder } from "../api/WarehouseDeliveryAPI";

import Progress from "../components/Progress";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";

import GridDx, { NoFilterEditor } from "../components/GridDx";
import { TableRowDetail } from "@devexpress/dx-react-grid-material-ui";


export interface WarehouseDeliveriesTabParams {
    tenantId: string;
    purchaseOrder: PurchaseOrder;
}

export default function WarehouseDeliveriesTab(props: WarehouseDeliveriesTabParams) {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();
    const [status, setStatus] = useState<string>("loading");
    const [data] = useState<DocumentLine[]>(props.purchaseOrder.lines ?? []);
    const [allWarehouseDeliveries, setAllWarehouseDeliveries] = useState<WarehouseDeliveries>();

    const [columnsFormat] = useState([
        { columnName: 'line_num', width: 70 },
        { columnName: 'unit_of_measure', width: 120 },
        { columnName: 'description', width: 200 },
        { columnName: 'quantity', align: 'right', width: 80 },
        { columnName: 'delivered', align: 'right', width: 80 },
        { columnName: 'pending', align: 'right', width: 80 },
        { columnName: 'price', align: 'right', width: 100 },
        { columnName: 'total', align: 'right', width: 100 }
    ]);

    const getPromise = (): Promise<WarehouseDeliveries> => {
        let allWarehouseInternalIds: string[] = [];
        data.forEach((line) => {
            line.deliveries.forEach((delivery) => allWarehouseInternalIds.push(delivery.warehouse_internal_id));
        });
        return listAllForPurchaseOrder(props.tenantId, allWarehouseInternalIds);
    };

    const load = () => {
        setStatus("loading");
        setPurchaseOrder(props.purchaseOrder);
        getPromise().then((warehouseDeliveries) => {
            setAllWarehouseDeliveries(warehouseDeliveries);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [props]);

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !purchaseOrder) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const columns = [
        {
            name: 'line_num',
            title: translate("purchase_orders.line_num") as string,
            getCellValue: (row: any) => row.line_now || ""
        },
        {
            name: 'unit_of_measure',
            title: translate("purchase_orders.unit_of_measure") as string
        },
        {
            name: 'description',
            title: translate("purchase_orders.description") as string
        },
        {
            name: 'quantity',
            title: translate("purchase_orders.quantity") as string
        },
        {
            name: 'delivered',
            title: translate("purchase_orders.delivered") as string,
            getCellValue: (row: any) => getDelivered(row)
        },
        {
            name: 'pending',
            title: translate("purchase_orders.pending") as string,
            getCellValue: (row: any) => getPending(row)
        },
        {
            name: 'price',
            title: translate("purchase_orders.price") as string
        },
        {
            name: 'total',
            title: translate("purchase_orders.line_total") as string
        }
    ];

    const getPending = (line: DocumentLine) => {
        let delivered: number = 0;
        line.deliveries.forEach((delivery) => {
            delivered += delivery.quantity;
        });
        return line.quantity - delivered;
    };

    const getDelivered = (line: DocumentLine) => {
        let delivered: number = 0;
        line.deliveries.forEach((delivery) => {
            delivered += delivery.quantity;
        });
        return delivered;
    };

    const currencyColumns = ['price', 'total'];

    const textColumns = ['line_num', 'unit_of_measure', 'description'];

    const filtersExclude = ['line_num', 'unit_of_measure', 'description', 'quantity', 'delivered', 'pending', 'price', 'total'];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />
    ];

    const sorting = [{ columnName: 'line_num', direction: 'asc' } as Sorting];

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => {
        if (params.row.deliveries.length < 1) {
            return (<div></div>);
        }

        let deliveriesOfLine: WarehouseDeliveryLine[] = params.row.deliveries;
        let readyToShow: DocumentLine[] = [];

        allWarehouseDeliveries?.items.map((werhouseDelivery) => {
            deliveriesOfLine.forEach((delivery) => {
                if (werhouseDelivery.id === delivery.warehouse_internal_id) {
                    werhouseDelivery.lines.forEach((line) => {
                        line.date_warehouse_delivery = werhouseDelivery.date;
                        readyToShow.push(line);
                    });
                }
            });
        });

        return (
            <div>
                <Grid xs={12}>
                    <Gridable
                        items={readyToShow}
                        loading={false}
                        empty={translate("purchase_orders.no_lines") as string}
                        columns={[
                            {
                                title: translate("purchase_orders.line_num"),
                                converter: (warehouseDeliveryLine) => warehouseDeliveryLine.line_num || "",
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                lg: 2
                            },
                            {
                                title: translate("warehouse_deliveries.date"),
                                converter: (warehouseDeliveryLine) => (
                                    <Typography variant="body2" component="h6">
                                        <DateFormat date={warehouseDeliveryLine.date_warehouse_delivery} format="DD/MM/YYYY" />
                                    </Typography>
                                ),
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                lg: 2
                            },
                            {
                                title: translate("purchase_orders.warehouse_deliveries.delivered_quantity"),
                                converter: (warehouseDeliveryLine) => (
                                    <Typography variant="body2" component="h6" align="right">
                                        {warehouseDeliveryLine.quantity}
                                    </Typography>
                                ),
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                lg: 2
                            },
                            {
                                title: translate("purchase_orders.price"),
                                converter: (warehouseDeliveryLine) => (
                                    <Typography variant="body2" component="h6" align="right">
                                        <NumberFormat value={warehouseDeliveryLine.price} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    </Typography>
                                ),
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                lg: 2
                            },
                            {
                                title: translate("purchase_orders.line_total"),
                                converter: (warehouseDeliveryLine) => (
                                    <Typography variant="body2" component="h6" align="right">
                                        <NumberFormat value={warehouseDeliveryLine.line_total} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    </Typography>
                                ),
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                lg: 2
                            }
                        ]}
                    />
                </Grid>
            </div>
        );
    };

    return (
        <GridDx
            loading={false}
            rows={data || []}
            columns={columns}
            columnsFormat={columnsFormat}
            onClickRow={() => { }}
            amountCurrencyColumns={currencyColumns}
            textColumns={textColumns}
            customFormatColumns={customPlugins}
            sorting={sorting}
            rowDetailComponent={rowDetailComponent}
            heightTablePX={420}
        />
    );
}
