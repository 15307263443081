import { CustomReport, CustomReportRequest, CustomReports, CustomReportsListParams, CustomReportDataQueryRequest, CustomReportRows } from "../model/CustomReport";
import { ExportResponse } from "../model/Connector";
import { HTTPMethod, callAPI } from "./API";

const basePath = (tenantId: string) => `/tenants/${tenantId}/custom-reports`;

export async function listCustomReports(tenantId: string, pageSize: number, offset: number, params: CustomReportsListParams): Promise<CustomReports> {
    
    let query = {
        name: params.name || "",
        columns: params.columns || "",
        exportable: params.exportable || "", 
        status: params.status || "",
        createdAtInit: params.created_at_init || "",
        createdAtEnd: params.created_at_end || "",
        pageSize: pageSize.toString(),
        offset: offset.toString(),
    }

    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: query
    });
}

export async function getCustomReport(tenantId: string, id: string): Promise<CustomReport>{
    return await callAPI(`${basePath(tenantId)}/${id}`,{
        method : HTTPMethod.GET
    })
}

export async function createCustomReport(tenantId: string, request: CustomReportRequest): Promise<CustomReport> {
    return await callAPI(`${basePath(tenantId)}`, {
        method  :HTTPMethod.POST,
        body: request
    });
}

export async function updateCustomReport(tenantId: string, id: string, request: CustomReportRequest): Promise<CustomReport> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method  :HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCustomReport(tenantId: string, id: string): Promise<any>{
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method  :HTTPMethod.DELETE
    });
}

export async function changeStatusCustomReport(tenantId: string, id: string): Promise<any>{
    return await callAPI(`${basePath(tenantId)}/${id}/change-status`, {
        method  :HTTPMethod.PUT
    });
}

export async function validateCustomReportQueries(tenantId : string, request : CustomReportRequest): Promise<CustomReport> {
    return await callAPI(`${basePath(tenantId)}/validate`, {
       method: HTTPMethod.POST,
       body: request 
    });
}

export async function executeDataQuery(tenantId : string, customReportId: string, request: CustomReportDataQueryRequest): Promise<CustomReportRows>{
    return await callAPI(`${basePath(tenantId)}/${customReportId}/data-query`, {
        method: HTTPMethod.POST,
        body: request 
     });
}

export async function importCustomReport(tenantId: string, file: File): Promise<CustomReport> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);
    
    return await callAPI(`${basePath(tenantId)}/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function exportExcel(tenantId : string, customReportId: string, request: CustomReportDataQueryRequest): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/${customReportId}/export-report-excel`, {
        method: HTTPMethod.POST,
        body: request 
    });
}

export async function exportCustomReport(tenantId: string, request: CustomReportRequest): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

