import { Cfdi, CfdisCancelled } from "./Cfdi";
import { Payment } from "./Payment";
import { Bank } from "./Bank";
import { TenantBankAccount } from "./BankAccount";
import Identifiable from "./Identifiable";

export const Statuses = [
    "PAYMENT_TO_SEND_TO_EXECUTE",
    "PAYMENT_IN_PROCESS_TO_EXECUTE",
    "PARTIALLY_PAID",
    "PAYMENT_TO_EXECUTE_BANK",
    "PAID",
    "PAYMENT_ORDER_REJECTED",
];

export const StatusesNames = [
    { title: "Por enviar a ejecución", value: "PAYMENT_TO_SEND_TO_EXECUTE" },
    { title: "Por ejecutar", value: "PAYMENT_IN_PROCESS_TO_EXECUTE" },
    { title: "Parcialmente pagado", value: "PARTIALLY_PAID" },
    { title: "Por ejecutar archivo en banco", value: "PAYMENT_TO_EXECUTE_BANK" },
    { title: "Pagada", value: "PAID" },
    { title: "Orden de pago rechazada", value: "PAYMENT_ORDER_REJECTED" }];

export interface PaymentOrder extends Identifiable {
    tenant_id: string;
    title: string;
    schedule_date?: Date;
    cfdis: PaymentOrderCfdi[];
    comments: string;
    status: string;
    payment_files?: PaymentOrderFile[];
    operation_number: string;
    author: string;
    last_author: string;
    list_cfdis: Cfdi[];
    list_payments: Payment[];
    history_response: PaymentOrderHistory[];
    enable_create_payment_file: boolean;
    authorized_amounts: CurrencyTotal[];
    cfdis_cancelled?: CfdisCancelled;
    has_cfdi_cancelled_by_sat: boolean;
    company_id: string;
    company_name?: string;
    providers_bank_accounts_required_fields_message?: string;
}

export interface CurrencyTotal {
    currency: string;
    total: number;
}

export interface PaymentOrders {
    items: PaymentOrder[];
    total: number;
}

export interface PaymentOrderCfdi {
    cfdi: string;
    amount: number;
    payment_amount?: number;
    payment_currency?: string;
    exchange_rate?: number;
    status: string;
    provider_id: string;
    reference_exchange_rate: number;
}

export interface PaymentOrderFile {
    id: string;
    date: Date;
    total: number;
    status: string;
    cfdis: string[];
}

export interface PaymentOrdersParams {
    ids: string;
    search: string;
    payment_order_status: string;
    sort_descending: boolean;
    start_date?: Date;
    end_date?: Date;
    operation_number?: string;
    title?: string;
    start_schedule_date?: string;
    end_schedule_date?: string;
    company_name?: string;
}

export interface PaymentOrderRequest {
    title: string;
    schedule_date?: Date;
    currency: string;
    comments: string;
    cfdis: PaymentOrderCfdi[];
}

export interface PaymentOrderRejectRequest {
    comments : string;
}

export interface ExecutePaymentFileRequest {
    payment_file_id: string;
    payment_date?: Date;
    url_file: string;
    name_file: string;
}

export interface CreatePaymentFileRequest {
    bank_id: string;
    bank_account_ids: string[];
}

export interface PaymentOrderCfdiItem extends Cfdi {
    amount: number; // authorized amount in source currency
    payment_amount: number; // autorized amount in payment currency
    payment_currency: string;
    exchange_rate?: number;
    status: string;
    reference_exchange_rate: number; // referencia de tipo de cambio del comprobante
}

export interface PaymentOrderHistory {
    author: string;
    previous_status: string;
    status: string;
    date: Date;
    comments: string;
}

export interface FilterPaymentOrders {
    startDate?: Date;
    endDate?: Date;
}

export interface ChangeStatusRequest {
    status: string;
}

export interface ExportPaymentOrderFileResponse {
    url?: string;
}

export interface ExchangeRate {
    published: Date;
    value: number;
}

export interface ExchangeRates {
    dollar: ExchangeRate;
    euro: ExchangeRate;
    use_exchange_rate: boolean;
}

export interface ProviderCurrency {
    provider: string;
    currency: string;
}

export interface PreparePaymentFileData {
    tenant_bank_account_missing_currencies: string[];
    providers_without_bank_account: Map<String, string[]>;
    tenant_banks: Bank[];
    tenant_bank_accounts: TenantBankAccount[];
    details_by_currency: Map<String, CounterTotal>;
    missing_bank_account: ProviderCurrency;
}

export interface CounterTotal {
    counter: number;
    total: number;
}
