import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { IconButton, Grid, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BillingIcon from "@material-ui/icons/Receipt";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { getBillings } from "../api/BillingAPI";
import { getTenantBillings } from "../api/TenantsBillingAPI";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat";
import BillingMenu from "./BillingMenu";
import NumberFormat from "react-number-format";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import PaymentAuthorizationFormPopup from "./PaymentAuthorizationFormPopup";

import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { Billings, Billing, BillingsQueryParams, Statuses } from "../model/Billing";
import ValidatedInput from "../components/ValidatedInput";
import BillingDetailPopup from "./BillingDetailPopup";

export default function BillingsList() {
    const context = useContext(AppContext);
    const history = useHistory();
    const tenantId = context.session?.tenant?.id;

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Billings | undefined>();
    const [statusLabels, setStatusLabels] = useState<string[]>([]);
    const [billing, setBilling] = useState<Billing>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [showAuthorizationForm, setShowAuthorizationForm] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): BillingsQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
        } as BillingsQueryParams;
    };
    const [params, setParams] = useState<BillingsQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<BillingsQueryParams>(paramsFromQueryString);

    const retrievePromise = (): Promise<Billings> => {
        let offset = getOffset(page, pageSize);
        if (tenantId) {
            return getTenantBillings(tenantId, pageSize, offset)
        }
        return getBillings(pageSize, offset, params);
    };

    const load = () => {
        setStatus('loading');
        retrievePromise().then((response) => {
            setData(response);
            setStatusLabels(Statuses.map((s) => translate(`billing.status.${s}`) as string));
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status"] = workingParams.status;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value || "" });
    };

    const onClickedOptions = (billing: Billing) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setBilling(billing);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setBilling(undefined);
    };

    const onDownloadAttachment = () => {
        setAnchorEl(null);
        if (!billing || !billing.attachment) return;

        window.open(billing!.attachment, "_blank");
    };

    const onAuthorize = () => {
        if (!billing) return;
        setAnchorEl(null);
        setShowAuthorizationForm(true);
    };

    const onView = () => {
        if (!billing) return;
        setAnchorEl(null);
        setShowDetails(true);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const onSuccessAutorization = () => {
        setBilling(undefined);
        setShowAuthorizationForm(false);
        setSuccess(translate("billing.authorized") as string);
        load();
    };

    const onCloseAutorization = () => {
        setBilling(undefined);
        setShowAuthorizationForm(false);
    };

    const onCloseDetails = () => {
        setBilling(undefined);
        setShowDetails(false);
    };

    const firstColumn = (billing: Billing): React.ReactNode => {
        let from = formatDate(billing.starts, "ll");
        let to = formatDate(billing.ends, "ll");
        let period = `${from} - ${to}`;
        if (!billing.tenant) return period;

        return (
            <div>
                <Ellipsis text={billing.tenant.name} lenght={100} uppercased={false} /><br />
                <Ellipsis text={period} lenght={0} uppercased={false} secondary />
            </div>
        );
    };

    return (
        <Pagination title={translate("billing.title")} icon={<BillingIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}>
            {!tenantId && (
                <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                    <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                        <Grid item xs={12} md>
                            <ValidatedInput type="text" id="search" name="search" label={translate("billing.filter") as string}
                                margin="dense" disabled={false}
                                value={workingParams.search} onValueChanged={onFilterChanged} />
                        </Grid>
                        <Grid item xs md="auto">
                            <ValidatedInput type="text" id="status" name="status" label={translate("billing.status.title") as string}
                                margin="dense" disabled={false}
                                value={workingParams.status} onValueChanged={onFilterChanged}
                                options={Statuses}
                                optionLabels={statusLabels}
                                emptyOption={translate("billing.status.all") as string} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button type="submit" variant="outlined" color="secondary" size="medium">
                                {translate("buttons.search")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("billing.empty") as string}
                columns={[
                    {
                        title: translate("billing.period") as string,
                        converter: firstColumn,
                        xs: 5,
                        sm: 5,
                        md: 5,
                        lg: 3
                    },
                    {
                        title: translate("billing.amount") as string,
                        converter: (billing) => (
                            <NumberFormat value={billing.amount} prefix="$" decimalScale={2} displayType="text" />
                        ),
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2
                    },
                    {
                        title: translate("billing.plan") as string,
                        converter: (billing) => (
                            <Ellipsis text={billing.plan.name} lenght={0} uppercased={false} />
                        ),
                        xs: false,
                        sm: 3,
                        md: 3,
                        lg: 2
                    },
                    {
                        title: translate("billing.authorization") as string,
                        converter: (billing) => billing.authorization ? (
                            <div>
                                <Ellipsis text={billing.authorization} lenght={0} uppercased={false} />
                                {billing.transaction_id && (<br />)}
                                {billing.transaction_id && (
                                    <Ellipsis text={billing.transaction_id} lenght={0} uppercased={false} secondary />
                                )}
                            </div>
                        ) : (<span>---</span>),
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2
                    },
                    {
                        title: translate("billing.status.title") as string,
                        converter: (billing) => translate(`billing.status.${billing.status}`),
                        xs: 4,
                        sm: 3,
                        md: 3,
                        lg: 2
                    },
                    {
                        title: "",
                        converter: (billing) => (
                            <IconButton aria-label="options"
                                color="default"
                                size="small"
                                onClick={onClickedOptions(billing)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: 3,
                        sm: 1
                    }
                ]} />
            {billing && anchorEl &&
                <BillingMenu billing={billing}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onDownloadAttachment={onDownloadAttachment}
                    onAuthorize={onAuthorize}
                    onView={onView} />
            }
            {billing && showAuthorizationForm && (
                <PaymentAuthorizationFormPopup billingId={billing.id}
                    onSuccess={onSuccessAutorization}
                    onClose={onCloseAutorization} />
            )}
            {billing && showDetails && (
                <BillingDetailPopup billing={billing} onDownloadAttachment={onDownloadAttachment} onClose={onCloseDetails} />
            )}
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Pagination>
    );
}