import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, Divider, Button, Fab } from "@material-ui/core";
import { AddIcon } from "../components/Icons";
import PlanningIcon from '@material-ui/icons/ListAltOutlined';
import ToExecuteIcon from '@material-ui/icons/ArrowRightAltOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';

import { listBudgets, deleteBudget } from "../api/BudgetApi";
import { AppContext } from "../context/AppContext";
import { Budget, BudgetExecutionStatus, Budgets, BudgetsQueryParams } from "../model/Budget";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import Gridable, { GridableColumn } from "../components/Gridable";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import { BudgetListCallbacks } from "./BudgetsView";
import BudgetMenu from "./BudgetMenu";

interface BudgetListsProps {
    statuses: string[];
    view: "PLANNING" | "EXECUTE" | "ARCHIVE";
    getColumns(budgets: Budget[], callbacks: BudgetListCallbacks): GridableColumn<Budget>[];
}

export default function BudgetLists(props: BudgetListsProps) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");
    const statuses = query.get("statuses");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Budgets>();
    const [workingParams, setWorkingParams] = useState<BudgetsQueryParams>({
        search: search,
        statuses: statuses ?? undefined
    } as BudgetsQueryParams);

    const [budget, setBudget] = useState<Budget>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [isCreateGranted] = useState(context.isGranted("BudgetsCreate") && props.view === "PLANNING");
    const [isDeleteGranted] = useState(context.isGranted("BudgetsDelete") && props.view === "PLANNING");
    const [isUpdateGranted] = useState(context.isGranted("BudgetsUpdate") && props.view === "PLANNING");

    const getStatuses = () => {
        if ((props.view !== "ARCHIVE")) {
            return props.statuses.join(",");
        }
        return !!statuses ? statuses : props.statuses.join(",");
    }

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
            statuses: getStatuses(),
            view: props.view
        } as BudgetsQueryParams;
        setWorkingParams(params);
        listBudgets(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };



    useEffect(load, [props, tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        query.set("search", workingParams.search);
        if (props.view === "ARCHIVE") {
            query.set("statuses", workingParams.statuses);
        }
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (budget: Budget) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setBudget(budget);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onDelete = () => {
        if (!budget) return;

        setAnchorEl(null);
        setPopup("delete");
    };

    const onConfirmDelete = () => {
        onClosePopup();
        if (!budget) return;

        setSubmitting(true);
        deleteBudget(tenantId, budget.id).then(() => {
            setSuccess(
                translate("budget.delete.success", { id: budget.id }) as string
            );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };


    const onCloseOption = () => {
        setAnchorEl(null);
        setBudget(undefined);
    };

    const onClickRow = (budget: Budget) => {
        history.push(`/budgets/${budget.id}/detail`);
    };

    return (
        <Pagination title={translate("budget.lists.titles." + props.view)} icon={props.view === "PLANNING" ? <PlanningIcon /> : (props.view === "EXECUTE" ? <ToExecuteIcon /> : <ArchiveIcon />)}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Link to="/budgets/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justifyContent="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={7} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("budget.lists.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    {"ARCHIVE" === props.view && (
                        <Grid item xs={4} sm>
                            <ValidatedInput type="text" id="statuses" name="statuses" label={translate("budget.lists.columns.status") as string}
                                margin="dense" disabled={false}
                                value={workingParams.statuses} onValueChanged={onFilterChanged}
                                options={BudgetExecutionStatus}
                                optionLabels={BudgetExecutionStatus.map(status => (translate("budget.lists.execution_status." + status) as string))}
                                emptyOption={translate("users.roles.all") as string} />
                        </Grid>
                    )}
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("budget.empty") as string}
                onClick={onClickRow}
                columns={props.getColumns(data ? data.items : [], {
                    onClickedOptions: onClickedOptions
                })} />
            {budget && anchorEl &&
                <BudgetMenu budget={budget} isDeleteGranted={isDeleteGranted} isUpdateGranted={isUpdateGranted} isPerformanceExportExcelGranted={false} anchor={anchorEl} onDelete={onDelete} onClose={onCloseOption} />
            }
            {budget && popup === "delete" && isDeleteGranted && (
                <DialogPopup
                    open
                    title={translate("budget.delete.title")}
                    onClose={onClosePopup}
                    closeColor="default"
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="secondary"
                            disabled={submitting}
                        >
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("budget.delete.message", { name: budget.name })}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}