import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import InvoiceIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";
import { getPayrollPdfUrl } from "../api/PayrollAPI";
import { getPayrollPdfUrl as getPayrollProviderPdfUrl } from "../api/ProviderPayrollAPI";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";
import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import { ErrorSnackbar } from "../components/Snackbars";

import { EmployeeCfdi } from "../model/CfdiSe";

interface CfdiIssuedPayrollMenuProps {
    tenantId: string
    employeeCfdi: EmployeeCfdi;
    anchor: HTMLElement | null;
    providerId?: string;
    onClose(): any;
}

export default function CfdiIssuedPayrollMenu(props: CfdiIssuedPayrollMenuProps) {
    const context = useContext(AppContext);
    const promise = props.providerId ? getPayrollProviderPdfUrl(props.tenantId, props.providerId, props.employeeCfdi.cfdi_id) : getPayrollPdfUrl(props.tenantId, props.employeeCfdi.cfdi_id);
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [error, setError] = useState<string>();
    
    useEffect(() => {
        promise.then(url => {
            setPdfUrl(url)
        }).catch(error => {
            setError(error.message);
        });
    }, []);

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <Menu
            id="contributions"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
                <MenuItem button onClick={props.onClose} dense component="a" href={props.employeeCfdi.url || "#"} disabled={!props.employeeCfdi.url} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                </MenuItem>
                <MenuItem button onClick={props.onClose} dense component="a" href={pdfUrl || ""} disabled={!pdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                </MenuItem>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Menu>
    );

}