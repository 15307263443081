import React from "react";
import { Grid, Checkbox, Chip } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Entity as Provider } from "../model/Provider";
import Ellipsis from "../components/Ellipsis";
import ValidIcon from "@material-ui/icons/BeenhereTwoTone";
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import { Cfdi } from "../model/Cfdi";
import CfdiProvideStatusIcon from "../cfdi/CfdiStatusProviderComponent";

export interface ProviderListCallbacks {
    onClickedOptions(provider: Provider): any;
    handleCheck(provider: Provider): any;
    handleAllChecks(checked: boolean): any;
}

interface ProviderNameColumnTitleProps {
    total: number;
    selected: number;
    showChecks: boolean;
    callbacks: ProviderListCallbacks;
}

export function ProviderNameColumnTitle(props: ProviderNameColumnTitleProps) {

    const handleAllCheckboxs = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        props.callbacks.handleAllChecks(checked);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto" >
                        <Checkbox
                            id="providers_all"
                            name="providers_all"
                            checked={props.selected > 0 && props.selected === props.total}
                            indeterminate={props.selected > 0 && props.selected < props.total}
                            onChange={handleAllCheckboxs}
                            disabled={props.total === 0}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                <Grid item xs>
                    <strong>{translate("providers.name")}</strong>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface ProviderNameColumnValueProps {
    provider: Provider;
    checked: boolean;
    showChecks: boolean;
    showIcons?: boolean;
    showClassifications?: boolean;
    callbacks: ProviderListCallbacks;
}

export function ProviderNameColumnValue(props: ProviderNameColumnValueProps) {

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        props.callbacks.handleCheck(props.provider);
    };

    const onClickCheck = (provider : Provider) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto" onClick={onClickCheck(props.provider)}>
                        <Checkbox
                            id={props.provider.id}
                            name={props.provider.id}
                            value={props.provider.id}
                            checked={props.checked}
                            onChange={handleSelection}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                {props.showIcons && (
                    <Grid item xs="auto" >
                        <StatusIcon provider={props.provider} lenght={15} />
                    </Grid>
                )}
                <Grid item xs>
                    <Ellipsis text={props.provider.name} lenght={100} /><br />
                    <Ellipsis text={`${props.provider.rfc} | ${translate(`providers.status.${props.provider.status}`)}`} lenght={0} secondary uppercased={false} />
                    {props.showClassifications && props.provider.provider_classifications && (
                        <Grid container spacing={1}>
                            {(props.provider.provider_classifications).map(classification => (
                                <Grid item xs="auto">
                                    <Chip variant="outlined" size="small" label={<small>{classification}</small>}></Chip>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

interface StatusIconProps {
    provider: Provider;
    lenght?: number;
}

const listValidations = (provider: Provider) => {
    let efosString= "";
    let cffString=[];
    if(provider.efos && provider.efos.status !== "LIMPIO"){
        efosString =  translate(`efo.status.${provider.efos.status}`) as string;
    }
    if(provider.black_list_validations){
        if(!provider.black_list_validations.canceled_result.is_clean){
            cffString.push(translate(`69cff.status.${provider.black_list_validations.canceled_result.status}`) as string);
        }
        if(!provider.black_list_validations.enforceable_result.is_clean){
            cffString.push(translate(`69cff.status.${provider.black_list_validations.enforceable_result.status}`) as string);
        }
        if(!provider.black_list_validations.firm_result.is_clean){
            cffString.push(translate(`69cff.status.${provider.black_list_validations.firm_result.status}`) as string);
        }
        if(!provider.black_list_validations.not_located.is_clean){
            cffString.push(translate(`69cff.status.${provider.black_list_validations.not_located.status}`) as string);
        }
        if(!provider.black_list_validations.sentences_result.is_clean){
            cffString.push(translate(`69cff.status.${provider.black_list_validations.sentences_result.status}`) as string);
        }
    }
    return cffString.length > 0 && cffString[0] !== "" ? [efosString].concat(cffString) : efosString;
}

const isProviderClean = (provider: Provider) => {
    if(provider.efos && provider.efos.status === "LIMPIO"){
        return !provider.black_list_validations || (provider.black_list_validations.canceled_result.is_clean
                && provider.black_list_validations.enforceable_result.is_clean
                && provider.black_list_validations.firm_result.is_clean
                && provider.black_list_validations.not_located.is_clean
                && provider.black_list_validations.sentences_result.is_clean);
    }
    return false;
}

export default function StatusIcon(props: StatusIconProps) {
    if(isProviderClean(props.provider)){
        return <Grid title={translate("providers.message_correct_validations") as string}><ValidIcon style={{ "color": "#82CA9D" }} /></Grid>;
    }
    return <Grid title={translate("providers.message_bad_validations", {"validations": listValidations(props.provider) }) as string}><WarningIcon color="secondary"/></Grid>;
}

interface ProviderFromCfdiProps {
    cfdi: Cfdi;
}

export function ProviderFromCfdi(props: ProviderFromCfdiProps) {
    return (
        <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                <Grid item xs="auto" >
                    <CfdiProvideStatusIcon cfdi={props.cfdi} />
                </Grid>
                <Grid item xs>
                    <Ellipsis text={props.cfdi.issuer.name} lenght={100} /><br />
                    <Ellipsis text={props.cfdi.issuer.rfc ? props.cfdi.issuer.rfc : ""} lenght={0} secondary uppercased={false} />
                </Grid>
            </Grid>
    );
}