import { callAPI, HTTPMethod } from "./API";
import { TenantConfiguration, TenantConfigurationErpRequest, TenantConfigurationRequest, TenantConfigurationContactErpRequest, ImportExportZipDefinitionRequest, TenantConfigurationWorkflowsRequest, UpdateReceivePluginRequest, TenantCurrencyRequest, TenantPOConfigurationRequest, TaxEquivalenceRequest, SecurityConfig, SecurityConfigRequest } from "../model/TenantConfiguration";
import { UpdateEmployerRequest } from "../model/Employer";
import { TermCondition as TermConditionRequest } from "../model/TermsConditions";
import { BankParserRequest } from "../model/Bank";
import { PaymentCondition, TenantCurrenciesResponse } from "../model/TenantConfiguration";
import { CurrencyListParams } from "../model/Currency";

const basePath = (tenantId: string) => `/tenants/${tenantId}/configurations`;

export async function get(tenantId: string): Promise<TenantConfiguration> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.GET,
    });
}

export async function getByAdmin(tenantId: string): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/admin`, {
        method: HTTPMethod.GET,
    });
}

export async function updateErp(tenantId: string, request: TenantConfigurationErpRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/erp`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateWorkflows(tenantId: string, request: TenantConfigurationWorkflowsRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/workflows`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateZipInterpreter(tenantId: string, request: ImportExportZipDefinitionRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/zip-interpreter`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function update(tenantId: string, request: TenantConfigurationRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/update`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function updateContactErp(tenantId: string, request: TenantConfigurationContactErpRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/contact`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateReceivePlugin(tenantId: string, request: UpdateReceivePluginRequest): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/plugins/receive`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateEmployer(tenantId: string, request: UpdateEmployerRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/employer`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateTermsConditions(tenantId: string, request: TermConditionRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/terms-conditions`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateBank(tenantId: string, request: BankParserRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/bank`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function listPaymentConditions(tenantId: string): Promise<PaymentCondition[]> {
    return await callAPI(`${basePath(tenantId)}/payment-conditions`, {
        method: HTTPMethod.GET,
    });
}

export async function getSecurityConfig(tenantId: string): Promise<SecurityConfig> {
    return await callAPI(`${basePath(tenantId)}/security`, {
        method: HTTPMethod.GET
    });
}

export async function saveSecurityConfig(tenantId: string, config: SecurityConfigRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/security`, {
        method: HTTPMethod.POST,
        body: config
    });
}

export async function getTenantCurrencies(tenantId: string, page?: number, offset?: number, params?: CurrencyListParams): Promise<TenantCurrenciesResponse> {
    return await callAPI(`${basePath(tenantId)}/currencies`, {
        method: HTTPMethod.GET,
        query: {
            search: params ? params.search : "",
            page_size: page ? page.toString() : "0",
            offset: offset ? offset.toString() : "0",
        }
    });
}

export async function addCurrency(tenantId: string, request: TenantCurrencyRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/currency`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function removeCurrency(tenantId: string, currencyId: string): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/${currencyId}/remove-currency`, {
        method: HTTPMethod.DELETE
    });
}

export async function updatePurchaseOrderConfiguration(tenantId: string, request: TenantPOConfigurationRequest): Promise<TenantConfiguration> {
    return await callAPI(`${basePath(tenantId)}/purchase-orders`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function addTaxEquivalences(tenantId: string, request: TaxEquivalenceRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/tax-equivalences`, {
        method: HTTPMethod.POST,
        body: request
    });
}