import { callAPI, HTTPMethod } from "./API";
import { Employees, Employee, EmployeesQueryParams, EmployeeRequest } from "../model/Employee";

const basePath = (tenantId: string) => `/tenants/${tenantId}/employees`;

export async function listEmployees(tenantId: string, page: number, offset: number, params: EmployeesQueryParams): Promise<Employees> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createEmployee(tenantId: string, request: EmployeeRequest): Promise<Employee> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getEmployee(tenantId: string, id: string): Promise<Employee> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateEmployee(tenantId: string, id: string, request: EmployeeRequest): Promise<Employee> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteEmployee(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
