import React from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";

import translate from "../i18n/Translator";
import { TenantCurrency } from "../model/Currency";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';

interface CurrencyMenuProps {
    curency: TenantCurrency;
    anchor: HTMLElement | null;
    onEdit(curency: TenantCurrency): void;
    onDelete(curency: TenantCurrency): void;
    onClose(): void;
}

export default function CurrencyMenu(props: CurrencyMenuProps) {
    return (
        <Menu
            id="currency-item"
            anchorEl={props.anchor}
            keepMounted
            open
            TransitionComponent={Fade}
        >
            <MenuItem button onClick={() => props.onEdit(props.curency)} dense>
                <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.edit")}</Typography>
            </MenuItem>

            <MenuItem button onClick={() => props.onDelete(props.curency)} dense>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.delete")}</Typography>
            </MenuItem>

        </Menu>
    );

}