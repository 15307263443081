import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";

import PaymentIcon from '@material-ui/icons/Payment';
import AuthorizeIcon from '@material-ui/icons/ThumbUp';
import RejectIcon from '@material-ui/icons/ThumbDown';

import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Gridable from "../components/Gridable";
import Progress from "../components/Progress";
import Surface from "../components/Surface";
import AuthorizeRejectPopup from "../components/AuthorizeRejectPopup";
import { formatDate } from "../components/DateFormat";
import { Typography, Grid, Fab, Card, Box } from "@material-ui/core";

import { getPaymentCfdiForReviewDetails, paymentCfdiReview } from "../api/PaymentApi";
import { PaymentCfdiReviewCfdis, CfdiPaymentDetail, PaymentCfdiReviewActionRequest } from "../model/Payment";
import { isRoleOrParent } from "../model/Role";
import { Cfdi, CfdiStatus_PAYMENT_CFDI_IN_REVIEW, CfdiStatus_PAYMENT_CFDI_REJECTED} from '../model/Cfdi';
import { Payment } from "../model/Payment";

interface AmountsByCurrency {
    amount: number;
    currency: string;
}

export default function PaymentsPendingReviewOverView({ match }: RouterParams) {

    const context = useContext(AppContext);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [cfdiPaymentDetail, setCfdiPaymentDetail] = useState<CfdiPaymentDetail>();
    const [status, setStatus] = useState<string>("loading");
    const [cfdiPaymentPayments, setCfdiPaymentPayments] = useState<PaymentCfdiReviewCfdis[]>([]);
    const [cfdiPaymentCfdis, setCfdiPaymentCfdis] = useState<PaymentCfdiReviewCfdis[]>([]);
    const [numbers, setNumbers] = useState<string>("");
    const [totalAmountPayments, setTotalAmountPayments] = useState<AmountsByCurrency[]>([]);
    const [totalAmountCfdi, setTotalAmountCfdi] = useState<AmountsByCurrency[]>([]);
    const [maskDate] = useState<string>('DD/MMM/YYYY');
    const [maskDateComments] = useState<string>('DD/MMMM/YYYY');
    const [redirect, setRedirect] = useState<string>();
    const [executor, setExecutor] = useState<"authorize-cfdi-payment-review" | "reject-cfdi-payment-review">();
    const [openAuthorizeRejectDialog, setOpenAuthorizeRejectDialog] = useState<boolean>(false);
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");

    const getTotalPayed = (cfdi: Cfdi, payment: Payment) => {
        let totalPayed = cfdi.total_to_pay;
        payment.cfdis.forEach(item => {
            if(cfdi.id === item.id){
                totalPayed = item.payment_amount;
            }
        });
        return totalPayed;
    }

    const initialInfo = (cfdiPaymentDetail: CfdiPaymentDetail) => {
        var numbers = [] as string[];
        var totalAmountPayments = [] as AmountsByCurrency[];
        var totalAmountCfdi = [] as AmountsByCurrency[];;
        var cfdisPayment = [] as PaymentCfdiReviewCfdis[];
        var cfdisCfdi = [] as PaymentCfdiReviewCfdis[];

        cfdiPaymentDetail.payments.items.forEach((payment) => {
            numbers.push(payment.operation_number);
            let index = totalAmountPayments.findIndex(item => item.currency === payment.currency);
            if (index >= 0) {
                totalAmountPayments[index].amount += payment.total_amount;
            } else {
                totalAmountPayments.push({ currency: payment.currency, amount: payment.total_amount } as AmountsByCurrency)
            }

            payment.list_cfdis.forEach((cfdiPayment) => {
                let cfdiPaymentRow = {
                    amount: cfdiPayment.total,
                    uuid: cfdiPayment.cfdi?.timbre.uuid,
                    currency: cfdiPayment.cfdi !== undefined ? cfdiPayment.cfdi.moneda : cfdiPayment.currency,
                    cfdiId: cfdiPayment.id,
                    payed_amount: getTotalPayed(cfdiPayment, payment),
                    currency_payed: payment.currency,
                } as PaymentCfdiReviewCfdis;
                cfdisPayment.push(cfdiPaymentRow);
            });
        });

        if (cfdiPaymentDetail.cfdi.cfdi?.complementos.pagos) {
            cfdiPaymentDetail.cfdi.cfdi?.complementos.pagos.pagos.forEach((pago) => {

                let index = totalAmountCfdi.findIndex(item => item.currency === pago.moneda);
                if (index >= 0) {
                    totalAmountCfdi[index].amount += pago.monto;
                } else {
                    totalAmountCfdi.push({ currency: pago.moneda, amount: pago.monto } as AmountsByCurrency)
                }

                pago.documentos_relacionados.forEach((documento_relacionado) => {
                    let cfdiCfdiRow = {
                        amount: documento_relacionado.importe_pagado,
                        uuid: documento_relacionado.id_documento,
                        currency: pago.moneda
                    } as PaymentCfdiReviewCfdis;
                    cfdisCfdi.push(cfdiCfdiRow);
                });
            });
        } else if (cfdiPaymentDetail.cfdi.cfdi?.complementos.pagos20) {
            cfdiPaymentDetail.cfdi.cfdi?.complementos.pagos20.pagos.forEach((pago) => {

                let index = totalAmountCfdi.findIndex(item => item.currency === pago.moneda);
                if (index >= 0) {
                    totalAmountCfdi[index].amount += pago.monto;
                } else {
                    totalAmountCfdi.push({ currency: pago.moneda, amount: pago.monto } as AmountsByCurrency)
                }

                pago.documentos_relacionados.forEach((documento_relacionado) => {
                    let cfdiCfdiRow = {
                        amount: documento_relacionado.importe_pagado,
                        uuid: documento_relacionado.id_documento,
                        currency: pago.moneda
                    } as PaymentCfdiReviewCfdis;
                    cfdisCfdi.push(cfdiCfdiRow);
                });
            });
        }


        setNumbers(numbers.toString());
        setTotalAmountPayments(totalAmountPayments);
        setTotalAmountCfdi(totalAmountCfdi);
        setCfdiPaymentPayments(cfdisPayment);
        setCfdiPaymentCfdis(cfdisCfdi);
    }

    const load = () => {
        setStatus("loading");
        if (context.isGranted("PaymentsRead")) {
            getPaymentCfdiForReviewDetails(context.session!.tenant!.id, match.params.idCfdi).then((response) => {
                setCfdiPaymentDetail(response);
                setStatus("loaded");
                initialInfo(response);
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }

    useEffect(load, [match]);

    const authorize = (comment: string) => {
        paymentCfdiReview(context.session!.tenant!.id, { cfdi_id: match.params.idCfdi, authorize: true, comment: comment } as PaymentCfdiReviewActionRequest).then((response) => {
            setRedirect(`/payment/for-review`);
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const reject = (comment: string) => {
        paymentCfdiReview(context.session!.tenant!.id, { cfdi_id: match.params.idCfdi, authorize: false, comment: comment } as PaymentCfdiReviewActionRequest).then((response) => {
            setRedirect(`/payment/for-review`);
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const onAuthorize = () => {
        setExecutor("authorize-cfdi-payment-review");
        setOpenAuthorizeRejectDialog(true);
    }

    const onReject = () => {
        setExecutor("reject-cfdi-payment-review");
        setOpenAuthorizeRejectDialog(true);
    }

    const onCloseReject = () => {
        setOpenAuthorizeRejectDialog(false);
    }

    const getPaymentDate = () => {
        let dates = [] as string[];
        if (cfdiPaymentDetail?.cfdi.cfdi?.complementos?.pagos) {
            cfdiPaymentDetail?.cfdi.cfdi?.complementos?.pagos?.pagos?.forEach(p => {
                dates.push(formatDate(p.fecha_pago, maskDate));
            });
        } else if (cfdiPaymentDetail?.cfdi.cfdi?.complementos?.pagos20) {
            cfdiPaymentDetail?.cfdi.cfdi?.complementos?.pagos20?.pagos?.forEach(p => {
                dates.push(formatDate(p.fecha_pago, maskDate));
            });
        }
        return dates.join(", ");
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    if (status === "loading") {
        return (<Progress />);
    }

    return (
        <Surface title={cfdiPaymentDetail?.cfdi.issuer.name} subtitle={translate("payment.payment_cfdi_in_review.payment_number") + numbers} backButton icon={<PaymentIcon />}
            className="PaperPagination PaperTabContainer"
            titleActions={!isRoleOrParent(context.session!.role, "provider") && cfdiPaymentDetail?.cfdi.metadata.status === CfdiStatus_PAYMENT_CFDI_IN_REVIEW  && !isReaderOnly ? (
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" onClick={onAuthorize} title={translate("payment.payment_cfdi_in_review.authorize") as string}>
                            <AuthorizeIcon />
                        </Fab>
                    </Grid>
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" onClick={onReject} title={translate("payment.payment_cfdi_in_review.reject") as string}>
                            <RejectIcon />
                        </Fab>
                    </Grid>
                </Grid>
            ) : <div></div>}>
            <Grid container spacing={2} style={{ paddingTop: "20px" }}>
                {cfdiPaymentDetail?.cfdi.metadata.status === CfdiStatus_PAYMENT_CFDI_REJECTED && (
                    <Grid item xs={12} md={12} lg={12} xl={12} style={{ margin: "0px 30px 0px 30px", background: "#F9ECCC" }}>
                        {cfdiPaymentDetail?.cfdi_history.items.map((history) => (
                            <div key={history.id}>
                                <Box m={1}>
                                    {translate(`cfdis.history.status.${history.status}`) as string}
                                </Box>
                                <Box m={1}>
                                    {history.user?.first_name} {history.user?.last_name} <strong>{formatDate(history.created_at, maskDateComments)}</strong>
                                </Box>
                                <Box fontStyle="italic" m={1}>
                                    "{history.comments}"
                                </Box>
                            </div>
                        ))}
                    </Grid>
                )}
                <Grid item xs={6} md={6} lg={6} xl={6} style={{ paddingLeft: "30px" }}>
                    <Card variant="outlined">
                        <Box pl={2} pt={2} pb={2}>
                            <Grid item xs={12}>
                                <strong>{translate("payment.payment_cfdi_in_review.payment_made")}</strong>
                            </Grid>
                            <Grid container alignItems="center" justify="flex-end" spacing={2} style={{ paddingTop: "10px" }}>
                                <Grid item xs={4}>
                                    <Typography variant="body2">{translate("payment.payment_cfdi_in_review.payment_date")}</Typography>
                                </Grid>
                                <Grid item xs={8} style={{ paddingRight: "25px" }}>
                                    {cfdiPaymentDetail?.payments.items.map((payment) => (
                                        <Link to={"/payment-details/" + payment.id}>
                                            <Typography align="right" variant="body2">{formatDate(payment.payment_date, maskDate)}</Typography>
                                        </Link>
                                    ))}
                                </Grid>
                                {totalAmountPayments.map(totalAmounts => <>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{translate("payment.payment_cfdi_in_review.total_amount", { "currency": totalAmounts.currency })}</Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ paddingRight: "25px" }}>
                                        <Typography align="right" variant="body2">
                                            <NumberFormat value={totalAmounts.amount} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                        </Typography>
                                    </Grid>
                                </>)}
                                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                                    <strong>{translate("payment.payment_cfdi_in_review.cfdis_paid_tenant")}</strong>
                                </Grid>
                                <Grid item xs={12} style={{ paddingRight: "25px" }}>
                                    <CfdiTable data={cfdiPaymentPayments} link={true} showPaymentTotal={true}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6} style={{ paddingRight: "30px" }}>
                    <Card variant="outlined">
                        <Box pl={2} pt={2} pb={2}>
                            <Grid container alignItems="center" alignContent="center">
                                <Grid item xs={8}>
                                    <strong>{translate("payment.payment_cfdi_in_review.cfdi_data")}</strong>
                                </Grid>
                                <Grid item xs={4} style={{ paddingRight: "20px" }} >
                                    <Link to={"/cfdis/" + match.params.idCfdi + "/details"}>
                                        <Typography align="right" variant="body2">{translate("payment.payment_cfdi_in_review.details_cfdi_payment")}</Typography>
                                    </Link>
                                </Grid>
                                <Grid container alignItems="center" justify="flex-end" spacing={2} style={{ paddingTop: "10px" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{translate("payment.payment_cfdi_in_review.cfdis_dates_payment")}</Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ paddingRight: "25px" }}>
                                        <Typography align="right" variant="body2">{getPaymentDate()}</Typography>
                                    </Grid>
                                    {totalAmountCfdi.map(totalAmounts => <>
                                        <Grid item xs={4}>
                                            <Typography variant="body2">{translate("payment.payment_cfdi_in_review.amount", { "currency": totalAmounts.currency })}</Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ paddingRight: "25px" }}>
                                            <Typography align="right" variant="body2">
                                                <NumberFormat value={totalAmounts.amount} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                            </Typography>
                                        </Grid>
                                    </>)}
                                    <Grid item xs={12} style={{ paddingTop: "20px" }}>
                                        <strong>{translate("payment.payment_cfdi_in_review.cfdis")}</strong>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingRight: "25px" }}>
                                        <CfdiTable data={cfdiPaymentCfdis} link={false} showPaymentTotal={false}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            {openAuthorizeRejectDialog && (
                <AuthorizeRejectPopup
                    doAction={executor === "authorize-cfdi-payment-review" ? authorize : reject}
                    onClose={onCloseReject}
                    param={executor === "authorize-cfdi-payment-review" ? "authorize-cfdi-payment-review" : "reject-cfdi-payment-review"}
                    isCommentRequired={executor === "authorize-cfdi-payment-review" ? false : true}
                />
            )}
            <SuccessSnackbar message={success} onClose={() => setSuccess("")} />
            <WarningSnackbar message={warning} onClose={() => setWarning("")} />
            <ErrorSnackbar message={error} onClose={() => setError("")} />
        </Surface>
    );
}


interface CfdiTablelProps {
    data: PaymentCfdiReviewCfdis[],
    link: boolean,
    showPaymentTotal: boolean;
}

export function CfdiTable(props: CfdiTablelProps) {
    return (
        <Gridable
            items={props.data ? props.data : []}
            empty={translate("cfdis.empty") as string}
            loading={false}
            columns={[
                {
                    title: translate("payment.payment_cfdi_in_review.uuid") as string,
                    converter: (value) => (
                        props.link ?
                            <Link to={"/cfdis/" + value.cfdiId + "/details"}>
                                <Typography variant="body2" component="h5">{value.uuid}</Typography>
                            </Link>
                            :
                            <Typography variant="body2" component="h5">{value.uuid}</Typography>
                    ),
                    fullWidth: true,
                    xs: true
                },
                {
                    title: translate("payment.payment_cfdi_in_review.total") as string,
                    converter: (value) => (
                        <Grid item xs={12} >
                            <NumberFormat value={value.amount} prefix="$ " suffix={` ${value.currency}`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" variant="body2" />
                        </Grid>
                    ),
                    justify: "flex-end",
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4
                },
                {
                    title: translate("payments_cfdi.total_paid") as string,
                    converter: (value) => (
                        <Grid item xs={12} >
                            <NumberFormat value={value.payed_amount} prefix="$ " suffix={` ${value.currency_payed}`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" variant="body2" />
                        </Grid>
                    ),
                    justify: "flex-end",
                    xs: props.showPaymentTotal ? 4 : false,
                    sm: props.showPaymentTotal ? 4 : false,
                    md: props.showPaymentTotal ? 4 : false,
                    lg: props.showPaymentTotal ? 4 : false,
                    xl: props.showPaymentTotal ? 4 : false
                }
            ]}
        />
    )
}