import { Box, ListSubheader, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FiguraTransporte } from "../../model/ComplementoCartaPorte";
import translate from "../../i18n/Translator";
import Gridable from "../../components/Gridable";
import Ellipsis from "../../components/Ellipsis";

export interface FiguraTransporteCardProps {
    data: FiguraTransporte;
}

export default function FiguraTransporteCard(props: FiguraTransporteCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.transport_figure.title")}</strong></Typography>
            <Grid container justify="flex-start">
                {(props.data as any)["clave_transporte"] &&
                    <Grid item xs={12}>
                            <FiguraTransporteHead claveTransporte={(props.data as any)["clave_transporte"]} />
                    </Grid>
                }
                { props.data.operadores && props.data.operadores.operadores && props.data.operadores.operadores.length > 0 &&
                    <Grid item xs={12}>
                            <ListSubheader color="primary">{translate("cfdis.carta_porte.transport_figure.operator.operators")}</ListSubheader>
                            <Grid container justify="flex-start" >
                                <Grid item xs={12} >
                                    <Fields fields={props.data.operadores.operadores} variant={"operator"}/>
                                </Grid>
                            </Grid>
                    </Grid>
                }
                { props.data.propietario && props.data.propietario.length > 0 &&
                    <Grid item xs={12}>
                            <ListSubheader color="primary">{translate("cfdis.carta_porte.transport_figure.owner.owners")}</ListSubheader>
                            <Grid container justify="flex-start" >
                                <Grid item xs={12} >
                                    <Fields fields={props.data.propietario} variant={"owner"}/>
                                </Grid>
                            </Grid>
                    </Grid>
                }
                { props.data.arrendatario && props.data.arrendatario.length > 0 &&
                    <Grid item xs={12}>
                            <ListSubheader color="primary">{translate("cfdis.carta_porte.transport_figure.arrendatarios.arrend")}</ListSubheader>
                            <Grid container justify="flex-start" >
                                <Grid item xs={12} >
                                    <Fields fields={props.data.arrendatario} variant={"arrendatarios"}/>
                                </Grid>
                            </Grid>
                    </Grid>
                }
                { props.data.notificado && props.data.notificado.length > 0 &&
                    <Grid item xs={12}>
                            <ListSubheader color="primary">{translate("cfdis.carta_porte.transport_figure.notificados.notificate")}</ListSubheader>
                            <Grid container justify="flex-start" >
                                <Grid item xs={12} >
                                    <Fields fields={props.data.notificado} variant={"notificados"}/>
                                </Grid>
                            </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

interface FiguraTransporteHeadProps {
    claveTransporte: string;
}

function FiguraTransporteHead(props: FiguraTransporteHeadProps) {
    return (
        <>
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.transport_figure.subtitle")}</Typography><br/>
                <Grid container justify="flex-start" >
                    <Grid item xs={4} >
                        <div>
                            {translate("cfdis.carta_porte.transport_figure.transport_cve")}
                        </div>
                    </Grid>
                    <Grid item xs={8} >
                        <div>
                            {props.claveTransporte}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

interface FieldsProps <T>{
    fields: T[];
    variant: string;
}

function Fields<T>(props: FieldsProps<T>) {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box mx={-2}>
                    <Gridable
                        items={props.fields}
                        loading={false}
                        empty={translate("cfdis.carta_porte.transport_figure.empty") as string}
                        columns={[
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".rfc") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["rfc"] ? (concept as any)["rfc"] :  "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".nombre") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["nombre"] ? (concept as any)["nombre"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".numero_licencia") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["numeroLicencia"] ? (concept as any)["numeroLicencia"] : "---"} lenght={100} uppercased={false} />),
                                xs: props.variant === "operator" ? true : false,
                                sm: props.variant === "operator" ? true : false,
                                md: props.variant === "operator" ? true : false,
                                xl: props.variant === "operator" ? true : false
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".id_tributario") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["idTributario"] ? (concept as any)["idTributario"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".residencia_fiscal") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["residenciaFiscal"] ? (concept as any)["residenciaFiscal"] : "---"} lenght={100} uppercased={false} />),
                                xs: true,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.calle") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["calle"] ? (concept as any)["domicilio"]["calle"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.numero_exterior") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["numero_exterior"] ? (concept as any)["domicilio"]["numero_exterior"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.numero_interior") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["numero_interior"] ? (concept as any)["domicilio"]["numero_interior"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.colonia") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["colonia"] ? (concept as any)["domicilio"]["colonia"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.localidad") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["localidad"] ? (concept as any)["domicilio"]["localidad"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                lg: props.variant === "operator" ? false : true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.referencia") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["referencia"] ? (concept as any)["domicilio"]["referencia"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.municipio") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["municipio"] ? (concept as any)["domicilio"]["municipio"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.estado") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["estado"] ? (concept as any)["domicilio"]["estado"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.pais") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["pais"] ? (concept as any)["domicilio"]["pais"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.codigo_postal") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["codigo_postal"] ? (concept as any)["domicilio"]["codigo_postal"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                        ]} />
                </Box>
            </Grid>
        </Grid>
    );
}