import { callAPI, HTTPMethod } from './API';
import { SpecificReceptionRule, GeneralDataRequest, ServiceProductRequest, 
    CfdiUsesRequest, SpecificReceptionRulesQueryParams, SpecificReceptionRules,
    SpecificReceptionRuleCreateRequest } from '../model/SpecificReceptionRule';

const baseUrl = (tenantId: string): string => {
    return `/tenants/${tenantId}/specific-reception-rules`;
}

export async function getReceptionSpecificRules(tenantId: string, page: number, offset: number, params: SpecificReceptionRulesQueryParams): Promise<SpecificReceptionRules> {
    return await callAPI(`${baseUrl(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createSpecificRule(tenantId: string, request: SpecificReceptionRuleCreateRequest): Promise<SpecificReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getSpecificRule(tenantId: string, specificRuleId: string): Promise<SpecificReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/${specificRuleId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateServiceProduct(tenantId: string, specificRuleId: string, request: ServiceProductRequest): Promise<SpecificReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/${specificRuleId}/service-product`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateGeneral(tenantId: string, specificRuleId: string, request: GeneralDataRequest): Promise<SpecificReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/${specificRuleId}/general`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateUses(tenantId: string, specificRuleId: string, request: CfdiUsesRequest): Promise<SpecificReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/${specificRuleId}/uses`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}