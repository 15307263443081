import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { list, exportCfdis, exportExcel, exportPdf } from "../api/PaymentOrderApi";
import { Grid, Divider, Button, IconButton } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { PaymentOrdersParams, PaymentOrders, PaymentOrder, Statuses, StatusesNames } from "../model/PaymentOrder";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import PaymentOrderPendingIcon from '@material-ui/icons/AddToPhotos';
import ArchiveIcon from '@material-ui/icons/Archive';
import ExecutePaymentOrderIcon from '@material-ui/icons/AssignmentTurnedIn';
import { ExportCfdisResponse } from "../model/TenantCfdi";
import CustomBackdrop from "../components/CustomBackdrop";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaymentOrderMenu from "./PaymentOrderMenu";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import PaymentOrdersGlobalMenu from "./PaymentOrdersGlobalMenu";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import { Column, Grouping } from "@devexpress/dx-react-grid";
import { formatDateString, concatDates} from "../components/DateFormat";
import { Filter } from '@devexpress/dx-react-grid';
import GridDx from "../components/GridDx";
import Surface from "../components/Surface";

export interface PaymentOderListProps {
    title: string;
    success?: string;
    columns: Column[];
    clickRowColumns?: string[];
    defaultExpandedGroups?: string[];
    currencyColumns?: string[];
    numberColumns?: string[];
    dateColumns?: string[];
    textColumns?: string[];
    columnsFormat: any[];
    leftColumns?: any[];
    rightColumns?: any[];
    grouping?: Grouping[];
    statusPlugins?: JSX.Element[];
}

export default function PaymentOrdersList(props: PaymentOderListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): PaymentOrdersParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "operation_number": typeof qs["operation_number"] === "string" ? qs["operation_number"] as string : "",
            "title": typeof qs["title"] === "string" ? qs["title"] as string : "",
            "start_schedule_date": typeof qs["start_schedule_date"] === "string" ? qs["start_schedule_date"] as string : "",
            "end_schedule_date": typeof qs["end_schedule_date"] === "string" ? qs["end_schedule_date"] as string : "",
            "payment_order_status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
        } as PaymentOrdersParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<PaymentOrders>({ items: [] as PaymentOrder[] } as PaymentOrders);
    const [params, setParams] = useState<PaymentOrdersParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<PaymentOrdersParams>(paramsFromQueryString);
    const [paymentOrder, setPaymentOrder] = useState<PaymentOrder>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [backTo, setBackTo] = useState<"pending" | "execute" | "archive">("archive");
    const [queryParams, setQueryParams] = useState<string>("");
    const [title, setTitle] = useState<string>();
    const [values, setValues] = useState<MultiselectValue[]>([]);
    const [selection, setSelection] = useState<(number | string)[]>([]);
    const [paymentOrderStatus, setPaymentOrderStatus] = useState<any>();
    const [statusesSelected, setStatusesSelected] = useState<string>();
    const [location, setLocation] = useState<string>("");
    const [redirect, setRedirect] = useState<string>();

    const paramsToFilters = () => {
        return [
            {columnName: "operation_number", value: params.operation_number},
            {columnName: "title", value: params.title},
            {columnName: "schedule_date", value: concatDates(params.start_schedule_date, params.end_schedule_date)},
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const status_Names = [...StatusesNames];

    const load = () => {
        let offset = getOffset(page, pageSize);
        history.push(window.location.pathname);

        let href = window.location.pathname;
        let locate = "";
        if (href.endsWith('pending')) {
            setTitle(translate("payment_order.titles.pending_payment_orders") as string);
            if (statusesSelected) {
                params.payment_order_status = statusesSelected;
            } else {
                let varios = [Statuses[0], Statuses[5]];
                locate = varios.join(", ");
                setPaymentOrderStatus([status_Names[0], status_Names[5]]);
            }
            setBackTo("pending");
        } else if (href.endsWith('execute')) {
            setTitle(translate("payment_order.titles.execution_payment_orders") as string);
            if (statusesSelected) {
                params.payment_order_status = statusesSelected;
            } else {
                let varios = [Statuses[1], Statuses[2], Statuses[3]];
                locate = varios.join(", ");
                setPaymentOrderStatus([status_Names[1], status_Names[2], status_Names[3]]);
            }
            setBackTo("execute");
        } else if (href.endsWith('archive')) {
            setTitle(translate("payment_order.titles.archive_payment_orders") as string);
            if (statusesSelected) {
                params.payment_order_status = statusesSelected;
            } else {
                params.sort_descending = true;
                locate = Statuses.join(", ");
                setPaymentOrderStatus(status_Names);
            }
            setBackTo("archive");
        }
        setLocation(locate);
        let paymentOrdStatus = workingParams.payment_order_status ? workingParams.payment_order_status.split(",") : [];
        setValues(status_Names.filter(el => {
            if(workingParams.payment_order_status){
                return paymentOrdStatus.find(status => el.value === status.trim());
            }
            return null;
        }));

        setStatus("loading");
        const referer = typeof qs["success"] === "string" ? qs["success"] as string : undefined;
        setSuccess(referer);
            list(context.session!.tenant!.id, pageSize, offset, params, locate).then((response) => {
                setStatus("loaded");
                setData(response);
            }).catch((error) => {
                setStatus(error.message);
            });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status"] = workingParams.payment_order_status;
        qs["operation_number"] = workingParams.operation_number ? workingParams.operation_number : "";
        qs["title"] =  workingParams.title ? workingParams.title : "";
        qs["start_schedule_date"] = workingParams.start_schedule_date ? workingParams.start_schedule_date : "";
        qs["end_schedule_date"] = workingParams.end_schedule_date ? workingParams.end_schedule_date : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";
        setQueryParams(queryString.stringify(qs));
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if(filter.value !== undefined){
                switch(filter.columnName){
                    case "operation_number": 
                        temp.operation_number = filter.value;
                        break;
                    case "company_name": 
                        temp.company_name = filter.value;
                        break;
                    case "schedule_date":
                        let paymentOrderDates = filter.value.split(" - ");
                        if(paymentOrderDates.length === 2) {
                            temp.start_schedule_date = formatDateString(paymentOrderDates[0]);
                            temp.end_schedule_date = formatDateString(paymentOrderDates[1]);
                        } else {
                            temp.start_schedule_date = "";
                            temp.end_schedule_date = "";
                        }
                        break;
                    case "title":
                        temp.title = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
        pushHistory();
    };

    const onStatusChanged = (selected: string[]) => {
        var toString = selected.join(", ");
        setStatusesSelected(toString); 
        setWorkingParams({ ...workingParams, payment_order_status: toString }); 
        setValues(status_Names.filter(el => selected.indexOf(el.value) >= 0));
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        setData({ items: [] as PaymentOrder[] } as PaymentOrders);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        pushHistory();
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (paymentOrder: PaymentOrder) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPaymentOrder(paymentOrder);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPaymentOrder(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onAddPaymentFile = () => { }

    const onSavePayments = () => { }

    const onDownloadCfdis = () => {
        setAnchorEl(null);
        if (!paymentOrder) return;
        setOpenBackdrop(true);
        exportCfdis(context.session!.tenant!.id, paymentOrder.id).then((response) => {
            setOpenBackdrop(false);
            onDownloadFiles(response);
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        });
    }

    const onDownloadExcel = () => {
        setAnchorEl(null);
        if (!paymentOrder) return;
        setOpenBackdrop(true);
        exportExcel(context.session!.tenant!.id, { ids: paymentOrder.id } as PaymentOrdersParams, location).then((response) => {
            setOpenBackdrop(false);
            onDownloadFiles(response);
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        });
    }

    const onDownloadExcelSelected = () => {
        setGridAnchorEl(null);
        if (selection === undefined || selection.length <= 0) {
            setWarning(translate('payment_order.messages.selection_empty') as string);
            return;
        }
        exportExcel(context.session!.tenant!.id, { ids: selection.join(',') } as PaymentOrdersParams, location).then((response) => {
            setOpenBackdrop(false);
            onDownloadFiles(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadExcelAll = () => {
        setGridAnchorEl(null);
        exportExcel(context.session!.tenant!.id, params, location).then((response) => {
            setOpenBackdrop(false);
            onDownloadFiles(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const iconByLocation = () => {
        let href = window.location.href;
        let locationRef = href.split("?");
        if (locationRef[0].endsWith('pending')) {
            return <PaymentOrderPendingIcon />;
        } else if (locationRef[0].endsWith('execute')) {
            return <ExecutePaymentOrderIcon />;
        } else if (locationRef[0].endsWith('archive')) {
            return <ArchiveIcon />;
        }
    }

    const onClickedRow = (paymentOrder: PaymentOrder) => {
        pushHistory();
        let url = `/payment-order/${paymentOrder.id}/read/${backTo}._.${queryParams}`;
        setRedirect(url);
    };

    const onDownloadPdf = () => {
        setAnchorEl(null);
        if (!paymentOrder) return;
        setOpenBackdrop(true);
        exportPdf(context.session!.tenant!.id, paymentOrder.id).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            }
        }).catch((error) => {
            setOpenBackdrop(false);
            setError(error.message);
        });
    }

    const onDownloadFiles = (response : ExportCfdisResponse) => {
        if(response && response.url){
            window.open(response.url, "_blank");
        } else {
            setSuccess(translate("cfdis.email_export") as string);
        }
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelection(selected);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Surface title={title} icon={iconByLocation()}
        titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) ?
                        <Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                        : undefined}
                </Grid>
            } className="PaperPagination">
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={true}>
                        <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={5} lg={3} >
                        <MultiselectDropList 
                            title = {translate("payment.archived.status") as string}  
                            margin="dense" 
                            elementos = {paymentOrderStatus ? paymentOrderStatus : []}
                            onChanged = {onStatusChanged} 
                            value={values} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                            <RefreshTwoToneIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={props.columns ? props.columns : []}
                defaultExpandedGroups={props.defaultExpandedGroups}
                onClickRow={onClickedRow}
                quantityColumns={props.numberColumns}
                clickRowColumns={props.clickRowColumns}
                dateColumns={props.dateColumns}
                textColumns={props.textColumns}
                columnsFormat={props.columnsFormat}
                leftColumns={props.leftColumns}
                rightColumns={props.rightColumns}
                grouping={props.grouping}
                page={page}
                pageSize={pageSize} 
                totalRows={data ? data.total : 0}
                selectionIds={selection}
                filters={filters}
                customFormatColumns={props.statusPlugins}
                setFiltersHandler={setFiltersHandler}
                setSelectionHandler={setSelectionHandler} 
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {anchorEl && paymentOrder && (
                <PaymentOrderMenu anchor={anchorEl}
                    onClose={onCloseOption}
                    onAddPaymentFile={onAddPaymentFile}
                    load={load}
                    onDownloadCfdis={onDownloadCfdis}
                    onDownloadExcel={onDownloadExcel}
                    onSavePayments={onSavePayments}
                    onDownloadPdf={onDownloadPdf}
                    payment_order_status={paymentOrder.status}
                    payment_order_delete={paymentOrder.status === "PAYMENT_TO_SEND_TO_EXECUTE" || paymentOrder.status === "PAYMENT_ORDER_REJECTED" || paymentOrder.status === "PAYMENT_IN_PROCESS_TO_EXECUTE"}
                    payment_order_id={paymentOrder.id}
                    view={backTo + "._." + queryParams}
                />
            )}
            {gridAnchorEl && (
                <PaymentOrdersGlobalMenu
                    anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onExportExcelAll={onDownloadExcelAll}
                    onExportExcelSelectd={onDownloadExcelSelected} />
            )}
        </Surface>);
}
