import React from "react";
import { Grid, Typography } from "@material-ui/core";

export interface ErrorProps {
    text: string;
}

export default function Error(props: ErrorProps) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="button" color="error" align="center">
                    {props.text}
                </Typography>
            </Grid>
        </Grid>
    )
}