import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { WebhookEventIcon } from "../components/Icons";
import { listEvents } from "../api/WebhookEventsAPI";
import { AppContext } from "../context/AppContext";
import { WebhookEvent, WebhookEvents, WebhookEventsQueryParams, EVENTS } from "../model/WebhookEvent";
import WebhookEventMenu from "./WebhookEventMenu";
import translate from "../i18n/Translator";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { RouterParams } from "../router/RouterParams";
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { FormControl, Select } from "@material-ui/core";
import WebhookEventTries from "./WebhookEventTriesView";
import { formatDateString, concatDates} from "../components/DateFormat";
import { Filter, DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { NoFilterEditor } from "../components/GridDx";

export default function WebhookEventsView({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const webhookId = match.params.webhookId;
    const webhookName = match.params.webhookName;

    const qs = queryString.parse(window.location.search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<WebhookEvents>();

    const paramsFromQueryString = (): WebhookEventsQueryParams => {
        return {
            "event": typeof qs["event"] === "string" ? qs["event"] as string : "",
            "event_start_date": typeof qs["event_start_date"] === "string" ? qs["event_start_date"] as string : "",
            "event_end_date": typeof qs["event_end_date"] === "string" ? qs["event_end_date"] as string : "",
        } as WebhookEventsQueryParams;
    };

    const [params, setParams] = useState<WebhookEventsQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<WebhookEventsQueryParams>(paramsFromQueryString);
    const [redirect, setRedirect] = useState<string>();

    const [webhookEvent, setWebhookEvent] = useState<WebhookEvent>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const [view, setView] = useState<"events" | "tries">("events"); 

    const paramsToFilters = () => {
        return [
            {columnName: "event", value: params.event},
            {columnName: "event_date", value: concatDates(params.event_start_date, params.event_end_date)},
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        listEvents(context.session!.tenant!.id, webhookId, pageSize, pageSize * page, params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if(filter.value !== undefined){
                switch(filter.columnName){
                    case "event": 
                        temp.event = filter.value;
                        break;
                    case "event_date":
                        let createdDates = filter.value.split(" - ");
                        if(createdDates.length === 2) {
                            temp.event_start_date = formatDateString(createdDates[0]);
                            temp.event_end_date = formatDateString(createdDates[1]);
                        } else {
                            temp.event_start_date = "";
                            temp.event_end_date = "";
                        }
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
        pushHistory();
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["event"] = workingParams.event ? workingParams.event : "";
        qs["event_start_date"] = workingParams.event_start_date ? workingParams.event_start_date : "";
        qs["event_end_date"] = workingParams.event_end_date ? workingParams.event_end_date : "";
        qs["page"] = "0";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedOptions = (webhookEvent: WebhookEvent) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWebhookEvent(webhookEvent);
    };

    const onReturn = () => {
        setView("events");
        setWebhookEvent(undefined);
        load();
    };

    const onClickedRow = (event: WebhookEvent) => {
        pushHistory();
        setWebhookEvent(event);
        setView("tries");
    };

    const onOpenTries = () => {
        pushHistory();
        setView("tries");
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setWebhookEvent(undefined);
    };

    const DefaultFilterEditor = ( params: DataTypeProvider.ValueEditorProps ) => {
        const onChange = (event : any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <FormControl fullWidth>
                <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="event"
                        id="event"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {EVENTS.map((value) => {
                            return <option key={value} value={value}>{translate(`webhooks.events_types.${value}`)}</option>
                        })}
                    </Select>
            </FormControl>
        );
    };

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const [columnsFormat] = useState([
      { columnName: 'id', wordWrapEnabled: true },
      { columnName: 'event' },
      { columnName: 'event_date'},
      { columnName: 'menu', align: 'right', width: 50},
    ]) as any;

    const dateColumns = ['event_date'];

    const textColumns = ['id'];

    const clickRowColumns = ['id'];

    const customPlugins = [<NormalTypeProvider for={['event']} editorComponent={DefaultFilterEditor}/>,
    <NormalTypeProvider for={['id',]} editorComponent={NoFilterEditor} />,
    ];

    const columns = [
        {
            name: 'id',
            title: translate("webhooks.events.columns.event_id") as string
        },
        {
            name: 'event',
            title: translate("webhooks.events.columns.event") as string,
            getCellValue: (row: any) => translate(`webhooks.events_types.${row.event}`) as string,
        },
        {
            name: 'event_date',
            title: translate('webhooks.events.columns.event_date') as string
            },
        {
            name: 'menu',
            title: " "
        }
    ];

    if (view === "tries" && webhookEvent) {
        return <WebhookEventTries
            eventId={webhookEvent.id}
            webhookName={webhookName}
            onReturn={onReturn}
        />;
    }

    const goBack = () => {
        setRedirect(`/webhooks`);
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Surface
            title={translate("webhooks.events.title", {webhook: webhookName}) as string}
            subtitle={translate("webhooks.events.description") as string}
            icon={<WebhookEventIcon />}
            className="PaperPagination" backButton onAction={goBack}>
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={columns}
                showSecondsInDate={true}
                onClickRow={onClickedRow}
                clickRowColumns={clickRowColumns}
                dateColumns={dateColumns}
                columnsFormat={columnsFormat}
                textColumns={textColumns}
                page={page}
                pageSize={pageSize} 
                totalRows={data ? data.total : 0}
                filters={filters}
                customFormatColumns={customPlugins}
                setFiltersHandler={setFiltersHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            {webhookEvent && anchorEl &&
                <WebhookEventMenu
                    anchor={anchorEl}
                    onOpenTries={onOpenTries}
                    onClose={onCloseOption} />
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Surface>
    );
}