import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import InfoIcon from '@material-ui/icons/InfoTwoTone';
import PeopleIcon from "@material-ui/icons/PeopleTwoTone";

import translate from "../i18n/Translator";
import { Customer } from "../model/Customer";
import { AppContext } from "../context/AppContext";

interface CustomerMenuProps {
    customer: Customer;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function CustomerMenu(props: CustomerMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="customers-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button component={Link} to={`/customers/${props.customer.id}/overview`} onClick={props.onClose} dense>
                <ListItemIcon>
                    <InfoIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.see")}</Typography>
            </MenuItem>
            {context.isGranted("CustomersUpdate") &&
                <MenuItem button component={Link} to={`/customers/${props.customer.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGranted("OwnerCustomersUsersRead") &&
                <MenuItem button component={Link} to={`/customers/${props.customer.id}/users`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <PeopleIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.title")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}