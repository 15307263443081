import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Clarification, Message, ClarificationMessageRequest } from '../model/Clarification';
import { Grid, Card, Divider, Box, Fab, GridSize } from "@material-ui/core";
import DateFormat from "../components/DateFormat";
import ValidatedInput from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import CustomBackdrop from "../components/CustomBackdrop";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RefreshIcon from '@material-ui/icons/RefreshTwoTone';
import { postMessage } from "../api/ClarificationApi";
import { ErrorSnackbar } from "../components/Snackbars";

interface ChatClarificationProps {
    clarification: Clarification;
    height?: number;
    xs: boolean | GridSize | undefined;
    onUpdate(): any;
    onSendMessage(clarification: Clarification): any;
}

export default function ChatClarification(props: ChatClarificationProps) {
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);
    const [messagesOrdered, setMessagesOrdered] = useState<Message[]>([]);
    const [request, setRequest] = useState<string>("");
    const [error, setError] = useState<string>();

    const load = () => {
        setStatus("loading");
        setMessagesOrdered(props.clarification.messages_response.sort((first, second) => {
            if (first.created_at > second.created_at) {
              return 1;
            }
            if (first.created_at < second.created_at) {
              return -1;
            }
            return 0;
        }));
        setStatus("loaded");
    }

    useEffect(load, [props]);

    const onChange = (name: string, value: string) => {
        setRequest(value);
    }

    const onSendMessage = () => {
        setStatus("loading");
        postMessage(context.session!.tenant!.id, props.clarification.id, {
            user_id: context.session!.user!.id,
            company_id: context.session!.provider ? context.session!.provider.id : context.session!.tenant!.id,
            is_provider: context.session!.provider ? true : false,
            message: request
        } as ClarificationMessageRequest).then((response) => {
            props.onSendMessage(response);
            setRequest("");
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    return (
        <Grid container xs={props.xs} style={{
            borderStyle: "solid",
            borderColor: "#D1D1D1"
        }}>
            <Grid item xs={12} style={{ backgroundColor:"#D1D1D1"  }}>
                <Box pl={2} pr={1} py={1}>
                    <Grid container >
                        <Grid item xs={11}>
                            <strong>{props.clarification.subject}</strong>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid container justify="flex-end">
                                <Grid item >
                                    <Box onClick={props.onUpdate} >
                                        <Fab style={{ "width": "30px", "height": "30px", "color": "gray"}} >
                                            <RefreshIcon style={{ "width": "20px", "height": "20px"}} />
                                        </Fab>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} style={{
                    "overflowY": "auto",
                    "height": props.height ? window.screen.height - props.height : window.screen.height - 500
                }}>
                <Box px={1} py={1}>
                {messagesOrdered.map((message: Message) => (
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Grid container justify={message.sender_id === context.session!.user.id ? "flex-end" : "flex-start"}>
                                <Grid item xs={8}>
                                    <Card style={{ backgroundColor:"#f1f1f1"  }}>
                                        <Box textOverflow="ellipsis" px={2} py={2}>
                                            <strong style={message.sender_id === context.session!.user.id ? {
                                                color:"#3d94c5"
                                            } : {
                                                color:"#f6944e"
                                            }}>{message.sender}</strong>
                                            <Grid container style={{
                                                fontSize: "10px",
                                                "color": "gray"
                                            }} >
                                                <Grid item xs={8}>
                                                    {message.company}
                                                </Grid>
                                                <Grid item xs={4} justify="flex-end">
                                                    <Grid container justify="flex-end">
                                                        <Grid item >
                                                            <DateFormat date={message.created_at} format="DD-MM-YYYY h:mm a" />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Box pt={1}>
                                                {message.message_text}
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider />
                <Box px={1} pb={1}>
                    <Grid container >
                        <Grid item xs={11}>
                            <ValidatedInput type="text" id="title" name="title"
                                value={request} label={translate("clarifications.chat.write_answer") as string}
                                margin="dense"
                                onValueChanged={onChange}  disabled={props.clarification.status === "RESOLVED"}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid container justify="flex-end">
                                <Grid item >
                                    <Box pl={1} pt={1} onClick={props.clarification.status === "RESOLVED" || request === "" ? undefined : onSendMessage} >
                                        <Fab color={props.clarification.status === "RESOLVED" || request === "" ? "inherit" : "primary" } style={{ "width": "30px", "height": "30px"}} >
                                            <ArrowForwardIcon style={{ "width": "20px", "height": "20px"}} />
                                        </Fab>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <CustomBackdrop open={status === "loading"} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Grid>
    );
}

