import React, { useContext, useEffect, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import DownloadIcon from "@material-ui/icons/CloudDownloadTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ZipDownload } from "../model/ZipDownload";
import { downloadUrl } from "../api/ZipDownloadAPI";

interface ZipDownloadMenuProps {
    zipDownload: ZipDownload;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
}

export default function ZipDownloadMenu(props: ZipDownloadMenuProps) {
    const context = useContext(AppContext);
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        downloadUrl(props.zipDownload.tenant_id, props.zipDownload.id).then((url) => {
            setUrl(url);
        }).catch(() => {
        })
    }, [props.zipDownload]);

    return (
        <Menu
            id="zip-downloads-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button onClick={props.onClose} dense component="a" href={url} target="_blank" rel="noopener noreferrer">
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.download")}</Typography>
            </MenuItem>
            {context.isGranted("ZipDownloadsDelete") &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}