import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import queryString from "query-string";

import { getOwnerDashboard } from "../api/MeAPI";
import { exportCfdiFiles } from "../api/TenantCfdiApi"
import Progress from "../components/Progress";
import ProvidersDashboard from "./ProvidersDashboard";
import MissingLicenseBanner from "../billing/MissingLicenseBanner";
import { OwnerDashboardResponse } from "../model/Dashboard";
import { AppContext } from "../context/AppContext";
import BlackListWidget from "./BlackListWidget";
import CalendarWidget from "./CalendarDashboard";
import CustomWidgets from "./CustomWidgets";
import Widgets from "./Widgets";
import { getMetadata } from "../api/TenantUserAPI";
import { Role } from "../model/Role";

export default function OwnerDashboard() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<OwnerDashboardResponse>();
    const qs = queryString.parse(window.location.search);
    const [roles, setRoles] = useState<Role[]>();

    const load = () => {
        setStatus("loading");
        getOwnerDashboard().then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });

        getMetadata(context.session!.tenant!.id).then((response) => {
            setRoles(response.roles);
        }).catch((error) => {
            setStatus(error.message);
        });

        let download = typeof qs["download"] === "string" ? qs["download"] as string : "";
        if (download !== "") {
            exportCfdiFiles(context.session!.tenant!.id, download).then((response) => {
                if (response.url) {
                    window.location.href = response.url;
                }
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }
    useEffect(load, [context.session]);

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded" || data === undefined || roles === undefined) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid container justify="center" spacing={3}>
            {context.isGrantedAny(["TenantsBillingsCreate", "TenantsBillingsUpdate"]) && context.session!.tenant!.license_status !== "PAID" && (
                <MissingLicenseBanner button />
            )}
            {data.widgets && data.widgets?.items?.filter(e => "billing_data" !== e.type)?.length > 0  && (
                <Grid item xs={12}>
                    <CustomWidgets tenant={context.session!.tenant!} widgets={data.widgets.items} />
                </Grid>
            )}
            {context.isGrantedAny(["ProvidersRead", "ProvidersSelfRead"]) && (
                <Grid item xs={12}>
                    <BlackListWidget />
                </Grid>
            )}
            {context.isGrantedAny(["CalendarRead"]) && (
                <Grid item xs={12}>
                    <CalendarWidget />
                </Grid>
            )}
            {context.isGrantedAny(["WidgetRead"]) && (
                <Grid item xs={12}>
                    <Widgets />
                </Grid>
            )}
            {data.providers && (
                <Grid item xs={12}>
                    <ProvidersDashboard summary={data.providers} />
                </Grid>
            )}
        </Grid>
    );
}
