import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import CompaniesIcon from "@material-ui/icons/BusinessCenter";

import { createCompany, getCompany, updateCompany } from "../api/CompanyAPI";
import translate from "../i18n/Translator";
import { Company, CompanyRequest } from "../model/Company";

import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import SimpleSwitch from "../components/SimpleSwitch";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import { getTaxRegimens } from "../api/TaxRegimenApi";
import { TaxRegimen } from "../model/TaxRegimen";
import { PostalCodeValidator, RfcValidator } from "../components/Validators";

export default function CompanyForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const history = useHistory();
    const isEdit = !!match.params.companyId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<CompanyRequest>({} as CompanyRequest);
    const [validations, setValidations] = useState({} as any);
    const [taxRegimens, setTaxRegimens] = useState<TaxRegimen[]>([]);
    const [currentTaxRegimen, setCurrentTaxRegimen] = useState<TaxRegimen>();
    const postalCodeValidator = new PostalCodeValidator();
    const rfcValidator = new RfcValidator();

    const submitPromise = (): Promise<Company> => {
        if (isEdit && match.params.companyId) {
            return updateCompany(tenantId, match.params.companyId, request);
        }
        return createCompany(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            Promise.all([
                getCompany(tenantId, match.params.companyId),
                getTaxRegimens("")
            ]).then(responses => {
                const companyResponse = responses[0];
                setRequest({
                    rfc: companyResponse.rfc,
                    name: companyResponse.name,
                    cp: companyResponse.cp,
                    regimen_id: companyResponse.regimen_id,
                    active: companyResponse.active,
                    external_id : companyResponse.external_id,
                    allow_invoice_reception: companyResponse.allow_invoice_reception,
                } as CompanyRequest);

                setTaxRegimens(responses[1].items);
                setCurrentTaxRegimen(responses[1].items.find(tr => tr.id === companyResponse.regimen_id));
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
        } else {
            getTaxRegimens("").then((response) => {
                setTaxRegimens(response.items);
            }).catch(error => {
                setStatus(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
        }
    }, [tenantId, isEdit, match.params.companyId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        if (name === "regimen_id") {
            setCurrentTaxRegimen(taxRegimens.find(el => el.id === value));
        }
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedSwitch = (name: string, checked: boolean) => {
        setRequest({ ...request, [name]: checked });
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((company) => {
            history.push(`${context.isGranted("CompaniesUpdate") ? `/tenants/${tenantId}` : ""}/companies`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "companies.edit" : "companies.new")} icon={<CompaniesIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="rfc" name="rfc"
                                        value={request.rfc}
                                        label={translate("companies.rfc") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        validator={rfcValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("companies.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <ValidatedInput type="text" id="regimen_id" name="regimen_id"
                                            value={request.regimen_id}
                                            label={translate("companies.tax_regimen") as string}
                                            required={true} disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged}
                                            autocompleteOptions={taxRegimens}
                                            autocompleteValue={currentTaxRegimen}
                                            getId={(el) => el?.id}
                                            getLabel={(el) => el?.description} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={6}>
                                            <ValidatedInput type="text" id="cp" name="cp"
                                                value={request.cp}
                                                label={translate("companies.cp") as string}
                                                required={true} disabled={submitting}
                                                maxLength={5}
                                                validator={postalCodeValidator}
                                                margin={margin}
                                                onValueChanged={hasChanged} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container justify="flex-end" alignItems="center">
                                                <Grid item>
                                                <SimpleSwitch value="active"
                                                        label={translate("companies.active") as string}
                                                        checked={request.active || false}
                                                        disabled={submitting}
                                                        placement="end"
                                                        onChanged={hasChangedSwitch} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="external_id" name="external_id"
                                        value={request.external_id}
                                        label={translate("companies.external_id") as string}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="flex-start" alignItems="center">
                                        <Grid item>
                                           <SimpleSwitch value="allow_invoice_reception"
                                                label={translate("companies.invoice_reception.allow_invoice_reception") as string}
                                                checked={request.allow_invoice_reception || false}
                                                disabled={submitting}
                                                placement="end"
                                                onChanged={hasChangedSwitch} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}