import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import GridDx from "../components/GridDx";
import { NoFilterEditor } from "../components/GridDx";
import { DataTypeProviderProps, DataTypeProvider, Sorting } from "@devexpress/dx-react-grid";
import { Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NumberFormat from "react-number-format";

import Arrow from '@material-ui/icons/Forward';
import { ProjectHistoryRecord } from "../model/Project";
import { Project } from "../model/Project";

export interface ProjectBudgetTrackHistoryParams {
    history?: ProjectHistoryRecord[];
    project?: Project;
}

export default function ProjectBudgetTrackHistoryTab(params: ProjectBudgetTrackHistoryParams) {
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ProjectHistoryRecord[]>();
    const [dateFormated, setDateFormated] = useState<string>();

    const [columnsFormat] = useState([
        { columnName: 'updated_date', width: 100 },
        { columnName: 'current_budget', align: 'right', width: 150 },
        { columnName: 'budget_to_assign', align: 'right', width: 150 },
        { columnName: 'assigned_budget', align: 'right', width: 150 },
        { columnName: 'accrued_budget', align: 'right', width: 150 },
        { columnName: 'budget_to_accrue', align: 'right', width: 150 },
        { columnName: 'vs_presup', width: 100 },
        { columnName: 'comments', width: 200 }
    ]);

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const load = () => {
        setStatus("loading");
        let temp = new Date();
        setDateFormated(String(temp.getDate()).padStart(2, '0') + "" +  String(temp.getMonth() + 1).padStart(2, '0') + "" + temp.getFullYear()+""+temp.getHours()+""+temp.getMinutes()+""+temp.getSeconds());
        if (params.history) {
            setData(fillHistory(params.history));
        }
        setStatus("loaded");
    };

    const fillHistory = (historyProps: ProjectHistoryRecord[]) => {
        let history = [] as ProjectHistoryRecord[];
        historyProps.forEach((record) => {
            if ("TRAKING" === record.history_type) {
                let equal = record.assigned_budget && (record.assigned_budget === record.current_budget);
                let down = "FINALIZED" !== record.status && (record.current_budget === record.initial_budget) && (!record.assigned_budget || (record.assigned_budget < record.current_budget));

                let vs_presup = equal ? translate("requisitions.projects.status_budget.EQUAL") as string : down ? translate("requisitions.projects.status_budget.DOWN") as string
                    : translate("requisitions.projects.status_budget.UP") as string;
                record = { ...record, vs_presup: vs_presup } as ProjectHistoryRecord;
                history.push(record);
            }
        });
        return history;
    };

    useEffect(load, [params]);

    const IconVsBudget = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            let valueTransform = value.row as Project;
            let equal = valueTransform.assigned_budget && valueTransform.assigned_budget === valueTransform.current_budget;
            let down = "FINALIZED" !== valueTransform.status && valueTransform.current_budget === valueTransform.initial_budget 
            && (!valueTransform.assigned_budget || valueTransform.assigned_budget < valueTransform.current_budget);
            return <> {equal ? <Arrow style={{ color: "blue", "width": 20, "height": 20 }} /> :
                down ? <Arrow style={{ color: "green", "width": 20, "height": 20, "transform": "rotate(90deg)" }} /> :
                    <Arrow style={{ color: "red", "width": 20, "height": 20, "transform": "rotate(-90deg)" }} />}</>;
        }} {...props} />
    );

    const columns = [
        {
            name: 'updated_date',
            title: translate("requisitions.projects.columns.date") as string,
        },
        {
            name: 'current_budget',
            title: translate("requisitions.projects.columns.current_budget") as string,
        },
        {
            name: 'budget_to_assign',
            title: translate("requisitions.projects.columns.to_assign") as string,
        },
        {
            name: 'assigned_budget',
            title: translate("requisitions.projects.columns.assigned_amount") as string,
        },
        {
            name: 'accrued_budget',
            title: translate("requisitions.projects.columns.accrued") as string,
        },
        {
            name: 'budget_to_accrue',
            title: translate("requisitions.projects.columns.to_accrue") as string,
        },
        {
            name: 'vs_presup',
            title: translate("requisitions.projects.columns.vs_presup") as string
        },
        {
            name: 'comments',
            title: translate("requisitions.projects.columns.comments") as string
        },
    ];

    const currencyColumns = ['budget_to_accrue', 'accrued_budget', 'assigned_budget', 'budget_to_assign', 'current_budget',];

    const textColumns = ['comments'];

    const dateColumns = ['updated_date'];

    const filtersExclude = ['updated_date', 'current_budget', 'budget_to_assign', 'assigned_budget', 'accrued_budget', 'budget_to_accrue', 'vs_presup', 'comments'];

    const customPlugins = [
        <IconVsBudget for={['vs_presup']} />,
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />
    ];

    const sorting = [{ columnName: 'updated_date', direction: 'asc' } as Sorting];

    interface ToggleButtonProps {
        onToggle(): any,
        getMessage(key: string): string,
        buttonRef(ref: any): any,
    }

    const ToggleButton = (props: ToggleButtonProps) => (
        <Grid container>
            <Grid item xs={11} direction="row">
                <ListItem>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="body2">
                                <strong>{translate("requisitions.projects.columns.original_budget")}</strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                        <Typography color={"primary"}>
                            <NumberFormat color="blue" value={params.project?.initial_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                        </Typography>
                        </Grid>
                    </Grid>
                </ListItem>

            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={props.onToggle}
                    ref={props.buttonRef}>
                    <MoreVertIcon />
                </IconButton>
            </Grid>
        </Grid>
    );

    return (
        <Grid>
            <GridDx
                loading={status === "loading"}
                rows={data || []}
                columns={columns}
                onClickRow={() => { }}
                columnsFormat={columnsFormat}
                dateColumns={dateColumns}
                amountCurrencyColumns={currencyColumns}
                textColumns={textColumns}
                customFormatColumns={customPlugins}
                sorting={sorting}
                heightTablePX={420}
                excelFile={translate("requisitions.projects.excel_file_tracking_budget" , { "name": params.project!.name, "date": dateFormated } ) as string}
                elementInLineMenu={ToggleButton}
            />
        </Grid>

    )
}