import React, { useState } from "react";
import { Box, Grid, TableCell } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import { Column, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid as GridDx, Table, TableHeaderRow, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import translate from "../i18n/Translator";
import { SpecializedServicesEmployee } from "../model/CfdiSe";
import DateFormat from "../components/DateFormat";
import CfdiIssuedPayrollTable from "./CfdiIssuedPayrollTable";

interface CfdiIssuedPayrollsTableProps {
    employees: SpecializedServicesEmployee[];
}

const RowDetail = (props: TableRowDetail.ContentProps) => (
    <Grid container>
        <Grid item xs={12} lg={9} xl={8}>
            <Box pl={5}>
                <CfdiIssuedPayrollTable invoices={props.row.invoices || []} />
            </Box>
        </Grid>
    </Grid>
);

const NoDataCell = (props: Table.NoDataCellProps) => (
    <TableCell colSpan={props.colSpan} align="center">
        {props.getMessage("noData")}
    </TableCell>
);

export default function CfdiIssuedPayrollsTable(props: CfdiIssuedPayrollsTableProps) {
    const [columns] = useState<Column[]>([
        {
            name: "rfc",
            title: translate("employees.rfc") as string,
            getCellValue: (row: any, columnName: string) => row.employee.rfc
        },
        {
            name: "name",
            title: translate("employees.name") as string,
            getCellValue: (row: any, columnName: string) => row.employee.name
        },
        {
            name: "nss",
            title: translate("employees.nss") as string,
            getCellValue: (row: any, columnName: string) => row.employee.nss
        },
        {
            name: "from",
            title: translate("contracts.from") as string,
            getCellValue: (row: any, columnName: string) => (
                <DateFormat date={row.from} format="DD/MM/YYYY" />
            )
        },
        {
            name: "to",
            title: translate("contracts.to") as string,
            getCellValue: (row: any, columnName: string) => (
                <DateFormat date={row.to} format="DD/MM/YYYY" />
            )
        },
        {
            name: "status",
            title: translate("cfdis_se.docs.invoices") as string,
            getCellValue: (row: any, columnName: string) => {
                const daysCovered = row.days_covered || 0;
                const totalDays = row.total_days || 0;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs="auto">
                            {translate("cfdis_se.docs.of_days", { days_overed: daysCovered, total_days: totalDays })}
                        </Grid>
                        {!row.all_invoices && (
                            <Grid item xs="auto">
                                <DotIcon style={{ "color": red[500], "fontSize": "12px" }} />
                            </Grid>
                        )}
                    </Grid>
                );
            }
        },
    ]);

    return (
        <GridDx
            rows={props.employees}
            columns={columns}
        >
            <RowDetailState
                defaultExpandedRowIds={[2, 5]}
            />
            <Table messages={{
                noData: translate("cfdis_se.docs.no_employees") as string
            }} noDataCellComponent={NoDataCell} />
            <TableHeaderRow />
            <TableRowDetail
                contentComponent={RowDetail}
            />
        </GridDx>
    );

}