import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, TextField } from "@material-ui/core";

import translate from "../i18n/Translator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AutocompleteProvider } from "../model/Provider";

interface CfdiChangeReceptorPopUpProps {
    receptors: AutocompleteProvider[];
    text: string;
    onCompleted(newReceptorId : string, cfdiId? :string): any;
    onClose(): any;
}

export default function CfdiChangeReceptorPopUp(props: CfdiChangeReceptorPopUpProps) {
    const [newReceptorId, setNewReceptorId] = useState<string>();

    useEffect(() => {
            
    }, [props]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.onCompleted(newReceptorId!);
    };

    const onFilterChanged = (value: string, inputRef?: any) => {
        if(value !== undefined){
            setNewReceptorId(value);
        }
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("cfdis.change_receptor_cfdi.title") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Grid item xs={12} sm lg="auto" style={{"marginBottom":"3%"}}>
                        <Typography variant="body1">
                           {props.text}
                        </Typography>
                        </Grid>
                        <Grid item xs={12} sm lg="auto">
                        <Autocomplete
                            size="small"
                            options={props.receptors}
                            getOptionLabel={(elemento) => elemento.title}
                            noOptionsText={translate("payments_cfdi.no_options_autocomplete") as string}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={translate("cfdis.change_receptor_cfdi.select_authorized") as string} fullWidth />
                            )}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    onFilterChanged(newValue.value);
                                } else {
                                    onFilterChanged("");
                                }
                            }}
                            getOptionSelected={(opt, val) => opt.value === val.value}
                        />

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={false}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={false}>
                        {translate("buttons.accept")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
