import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Grid, Box, Button, IconButton, Fab, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import translate from "../i18n/Translator";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Surface from "../components/Surface";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";
import Progress from "../components/Progress";
import ConfirmationPopup from "../components/ConfirmationPopup";

import PaymentIcon from "@material-ui/icons/Payment";
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import { createTeam, updateTeam } from "../api/TeamAPI";
import { TeamMember, Team, TeamRequest, roles as teamRoles, directorRole } from "../model/Team";
import { AutocompleteProvider } from "../model/Provider";
import { User } from "../model/User";
import { Project } from "../model/Project";

interface CreateUpdateTeamViewProps {
    view: "update" | "create";
    isReaderOnly: boolean;
    team?: Team;
    next(request: Team, message?: string): any;
    onEdit(team: Team): any;
    users: User[];
    onCancel(): any;
}

export default function CreateUpdateTeamView(props: CreateUpdateTeamViewProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<TeamRequest>({} as TeamRequest);
    const history = useHistory();
    const [redirect, setRedirect] = useState<string>();
    const qs = queryString.parse(window.location.search);

    const [dialog, setDialog] = useState<"delete" | "cancel" | "add" | "edit">();
    const [submitting, setSubmitting] = useState(false);
    const [userId, setUserId] = useState<string>();
    const [usersAutocomplete, setUsersAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [usersTeam, setUsersTeam] = useState<TeamMember[]>([]);
    const [projectsRelated, setProjectsRelated] = useState<Project[]>([]);

    const [roles, setRoles] = useState<string[]>(teamRoles);
    const [teamRoleLabels, setTeamRoleLabels] = useState<string[]>(teamRoles.map(role => translate(`requisitions.teams.role.${role}`) as string));
    const [memberRole, setMemberRole] = useState<string>();
    const [readerOnly, setReaderOnly] = useState<boolean>(false);

    const load = () => {
        setStatus("loading");
        fillUsersAutocomplete();
        if(!teamRoles.find(c=> c === directorRole) && context.isGrantedAny(["BudgetsRead"])){
            teamRoles.push(directorRole);
            setRoles(teamRoles);
            setTeamRoleLabels(teamRoles.map(role => translate(`requisitions.teams.role.${role}`) as string));
        }
        setReaderOnly(props.isReaderOnly);
        if (props.view === "create") {
            setStatus("loaded");
        } else if (props.team) {
            setRequest({
                team_name: props.team.name,
                members: props.team.members,
                user_id: props.team.issuer_id,
                external_id: props.team.external_id,
            } as TeamRequest);
            setUsersTeam(props.team.members);
            if(props.team.projects){
                setProjectsRelated(props.team.projects);
            }
            setStatus("loaded");
        }
    };

    const fillUsersAutocomplete = () => {
        let listTemp = [] as AutocompleteProvider[];
        props.users.forEach((user) => {
            let temp = { title: user.name, value: user.id } as AutocompleteProvider;
            listTemp.push(temp);
        });
        setUsersAutocomplete(listTemp);
    }

    useEffect(load, [props, usersTeam]);

    const containsLestOneMemberByRole = () => {
        return !!usersTeam && usersTeam.find(item => item.role === "AUTHORIZER" || "DIRECTOR");
    }

    const onConfirm = () => {

        if (request.team_name === "") {
            setWarning(translate("requisitions.teams.empty_name") as string);
            return;
        }

        if (!containsLestOneMemberByRole()) {
            setWarning(translate("requisitions.teams.incomplete_team") as string);
            return;
        }

        setSubmitting(true);
        const teamRequest = {
            ...request,
            members: usersTeam,
        } as TeamRequest;

        if (props.view === "create") {
            createTeam(context.session!.tenant!.id, teamRequest).then((response) => {
                props.next(response, translate("requisitions.teams.succes_save", { "name": response.name }) as string);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else if (props.team) {
            updateTeam(context.session!.tenant!.id, props.team.id, teamRequest).then((response) => {
                props.next(response);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }

    };

    const onAddValue = () => {
        if ((memberRole === "" || memberRole === "---") || (!userId || userId === "")) {
            setWarning(translate("requisitions.teams.empty_fields") as string);
            return;
        }
        if (isDuplicate()) {
            setWarning(translate("requisitions.teams.duplicate") as string);
            return;
        }

        let user = props.users.find(c => c.id === userId);
        let value = {
            user_id: user?.id,
            role: memberRole,
            name: user?.name,
        } as TeamMember;
        setMemberRole("---");
        usersTeam.push(value);
    }

    const isDuplicate = () => {
        var isDuplicate = false;
        usersTeam.forEach((member) => {
            if (member.user_id === userId) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    };

    const onFilterChanged = (value: string, inputRef?: any) => {
        if (value !== undefined) {
            setUserId(value);
        }
    };

    const openConfirmDialog = () => {
        if (request.members !== null && request.team_name !== undefined) {
                onConfirm();
        } else {
            setWarning(translate("requisitions.teams.empty_data") as string);
        }
    };

    const cancelConfirmDialog = () => {
        setDialog(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        if (name === "team_role") {
            setMemberRole(value as string);
        } else {
            setRequest({ ...request, [name]: value });
        }
    };

    const onConfirmCancelDialog = () => {
        setDialog(undefined);
        props.onCancel();
    }

    const onCancel = () => {
        if(props.view === "create"){
            setDialog("cancel");
        } else{
            props.onCancel();
        }
    }

    const onEdit = () => {
        if(props.team){
            props.onEdit(props.team);
        }
    }

    const onDelete = (item: TeamMember) => {
        let valueToDelete = usersTeam.find(v => v.user_id === item.user_id);
        if (valueToDelete) {
            let index = usersTeam.indexOf(valueToDelete);
            if (index > -1) {
                usersTeam.splice(index, 1);
            }
        }
        setUsersTeam(usersTeam);

        setRequest({ ...request,
            team_name: request.team_name,
        } as TeamRequest);
    }

    const onClickedDelete = (value: TeamMember) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onDelete(value);
    };

    const onClickedRowCfdi = (project: Project) => {
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/projects/detail/${project.id}`);
    };

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <div>
            <Surface title={props.view === "create" ? translate("requisitions.teams.new_team.title") as string : request.team_name}
                subtitle={request.external_id || ""}
                icon={<PaymentIcon />} className="PaperPagination PaperTabContainer"
                backButton onAction={onCancel} titleActions={( readerOnly ?
                    <Grid container justify="flex-end" spacing={1}>
                        {(
                            <Grid item xs="auto">
                                <Fab color="primary" size="small" onClick={onEdit} title={translate("requisitions.teams.create_title") as string}>
                                    <EditIcon/>
                                </Fab>
                            </Grid>
                        )}
                    </Grid> : undefined
                )}>
                <Grid container justify="space-between" alignItems="center" >
                    {!readerOnly &&
                        <Grid item xs={12}>
                            <Grid container className="TableFilter" spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        {translate("requisitions.teams.new_team.general_data") as string}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="team_name" name="team_name" disabled={submitting}
                                        value={request.team_name} label={translate("requisitions.teams.name") as string}
                                        required={true}
                                        margin="dense"
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="external_id" name="external_id" disabled={submitting}
                                        value={request.external_id} label={translate("requisitions.teams.external_id") as string}
                                        required={true}
                                        margin="dense"
                                        onValueChanged={hasChanged} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid container className="TableFilter" alignItems="center" direction="row" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {translate("requisitions.teams.members") as string}
                            </Typography>
                        </Grid>
                        {!readerOnly &&
                            <Grid container alignItems="center" direction="row" spacing={1}>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <Autocomplete
                                        size="small"
                                        options={usersAutocomplete}
                                        getOptionLabel={(elemento) => elemento.title}
                                        noOptionsText={translate("payments_cfdi.no_options_autocomplete") as string}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label={translate("requisitions.teams.company_members") as string} fullWidth />
                                        )}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                onFilterChanged(newValue.value);
                                            } else {
                                                onFilterChanged("");
                                            }
                                        }}
                                        getOptionSelected={(opt, val) => opt.value === val.value}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <ValidatedInput type="text" id="team_role" name="team_role" label={translate("requisitions.teams.select_function") as string}
                                        options={roles} optionLabels={teamRoleLabels}
                                        margin="dense" disabled={false}
                                        value={memberRole} onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button onClick={onAddValue} variant="outlined" size="large" color="secondary">
                                        {translate("buttons.add")}
                                    </Button>
                                </Grid>

                            </Grid>
                        }
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Box px={2}>
                            <Gridable
                                items={usersTeam}
                                loading={false}
                                empty={translate("cfdis.empty") as string}
                                columns={[
                                    {
                                        title: translate("users.name") as string,
                                        converter: (value) => value.name,
                                        fullWidth: true,
                                        xs: true
                                    },
                                    {
                                        title: translate("requisitions.teams.function") as string,
                                        converter: (value) => translate(`requisitions.teams.role.${value.role}`) as string,
                                        fullWidth: true,
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 3
                                    },
                                    {
                                        title: (
                                            <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        ),
                                        converter: (value) => (
                                            <IconButton
                                                aria-label="options"
                                                color="default"
                                                size="small"
                                                onClick={onClickedDelete(value)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        ),
                                        fullWidth: true,
                                        xs: readerOnly ? false : "auto",
                                        sm: readerOnly ? false : "auto",
                                        md: readerOnly ? false : "auto",
                                        lg: readerOnly ? false : "auto",
                                        xl: readerOnly ? false : "auto"
                                    }
                                ]}
                            />
                        </Box>
                    </Grid>
                    {readerOnly && props.team && projectsRelated &&
                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                            <Grid container className="TableFilter" alignItems="center" direction="row" spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        {translate("requisitions.teams.related_projects") as string}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box px={2}>
                                <Gridable
                                    items={projectsRelated}
                                    loading={false}
                                    empty={translate("requisitions.teams.empty") as string}
                                    columns={[
                                        {
                                            title: translate("requisitions.projects.title_single") as string,
                                            converter: (value) =>
                                            <MenuItem button onClick={() => onClickedRowCfdi(value)} dense>
                                                <Typography variant="body2" color={"primary"}>
                                                    {value.name}
                                                </Typography>
                                            </MenuItem>,
                                            fullWidth: true,
                                            xs: true
                                        },
                                        {
                                            title: translate("requisitions.projects.type") as string,
                                            converter: (value) => translate(`requisitions.projects.types.${value.type}`) as string,
                                            fullWidth: true,
                                            xs: 3,
                                            sm: 3,
                                            md: 3,
                                            lg: 3,
                                            xl: 3
                                        },
                                        {
                                            title: translate("requisitions.projects.related_date") as string,
                                            converter: (value) => <DateFormat date={value.updated_at} format="DD/MM/YYYY" />,
                                            fullWidth: true,
                                            xs: 3,
                                            sm: 3,
                                            md: 3,
                                            lg: 3,
                                            xl: 3
                                        }
                                    ]}
                                />
                            </Box>
                        </Grid>

                    }
                    <Grid item xs={12}>
                        <Box pt={4} pr={2}>
                            <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                {!readerOnly &&
                                    <Grid item xs={12} md="auto">
                                        <Button variant="contained" color="primary" size="large" onClick={openConfirmDialog} >
                                            {translate(props.view === "create" ? "buttons.registry" : "buttons.save")}
                                        </Button>
                                    </Grid>}
                                <Grid item xs={12} md="auto">
                                    <Button variant="text" color="primary" size="large" onClick={onCancel}>
                                        {translate("buttons.cancel")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {dialog === "cancel" && props.view === "create" && (
                    <ConfirmationPopup title={translate("buttons.cancel") as string}
                        message={translate("requisitions.teams.cancel_popup.message") as string}
                        button={translate("buttons.accept") as string}
                        onClose={cancelConfirmDialog}
                        doAction={onConfirmCancelDialog} />
                )}
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            </Surface>
        </div>
    );
}
