import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent,TextField } from "@material-ui/core";

import translate from "../i18n/Translator";
import { WarningSnackbar } from "../components/Snackbars";
import { RetentionRule } from "../model/ReceptionRule"; 
import { ServiceProduct } from "../model/ServiceProduct";
import Autocomplete from '@material-ui/lab/Autocomplete';
import RetentionsListSection from "./RetentionsListSection";
import { TaxRegimen } from "../model/TaxRegimen";

interface SelectDefaultRetentionPopUpProps {
    retentions: RetentionRule[];
    serviceProducts: ServiceProduct[];
    taxRegimens: TaxRegimen[];
    taxRegimenDuplicate(retention: RetentionRule): any;
    onCompleted(retention: RetentionRule, isEdit: boolean): any;
    onClose(): any;
}

export default function SelectDefaultRetentionPopUp(props: SelectDefaultRetentionPopUpProps) {
    const [retentions, setRetentions] = useState<RetentionRule[]>([]);
    const [retentionSelected, setRetentionSelected] = useState<RetentionRule>();

    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    const getDescriptionRetention = (item: RetentionRule, type?: string) => {
        if(!item || !item.retentions){
            return "---";
        }
        let ivaRetention = item.retentions[0].retention_iva ;
        let isrRetention = item.retentions[0].retention_isr ;
        if(!type){
            return `${item.name} - Ret ${isrRetention || 0}% ISR, Ret ${ivaRetention || 0}% IVA`;
        } else if("ISR" === type) {
            return `${isrRetention || 0}%`;
        } else {
            return `${ivaRetention || 0}%`;
        }
    }

    useEffect(() => {
        let retentions = props.retentions;
        setRetentions(retentions);
    }, [props, retentionSelected]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!retentionSelected){
            return;
        }
        let messageDuplicate = props.taxRegimenDuplicate(retentionSelected);
        if (messageDuplicate) {
            setWarning(messageDuplicate as string);
            return;
    }
        props.onCompleted(retentionSelected, false);
    };

    const hasChanged = (value: string) => {
        let retention = props.retentions.find(element => element.id === value);

        if (retention) {
            setRetentionSelected(retention);
        } else {
            setRetentionSelected(undefined);
        }
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("reception_rule.retention_validation.title_default") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Autocomplete
                                            size="small"
                                            options={retentions}
                                            value={retentionSelected}
                                            disabled={false}
                                            getOptionLabel={(elemento) => getDescriptionRetention(elemento)}
                                            noOptionsText={translate("reception_rule.retention_validation.select_default") as string}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label={translate("reception_rule.retention_validation.select_default") as string} />
                                            )}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    hasChanged(newValue.id);
                                                } else {
                                                    hasChanged("");
                                                }
                                            }}
                                            getOptionSelected={(opt, val) => opt.id === val.id}
                                        />
                                </Grid>
                                <RetentionsListSection retentions={retentionSelected ? [retentionSelected] : []} serviceProducts={props.serviceProducts}/>
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={!retentionSelected}>
                        {translate("buttons.accept")}
                    </Button>
                </DialogActions>
            </form>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Dialog>
    );
}
