import React from "react";

import { Tooltip, Box } from "@material-ui/core";
import AcceptedIcon from "@material-ui/icons/CheckCircleTwoTone";
import NotAcceptedIcon from "@material-ui/icons/ErrorTwoTone";
import PendingIcon from "@material-ui/icons/QueryBuilderTwoTone";
import NoValueIcon from "@material-ui/icons/LensTwoTone";
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

import translate from "../i18n/Translator";

export const ICONS = {
    "NO_VALUE": <NoValueIcon color="action" />,
    "PENDING": <PendingIcon color="action" />,
    "ACCEPTED": <AcceptedIcon color="primary" />,
    "EXPIRED": <ErrorTwoToneIcon color="error" />,
    "NOT_ACCEPTED": <NotAcceptedIcon color="error" />
} as any;

export function getIcon(status: string | undefined, required: boolean, value?: any) {
    var tooltip: string, icon;
    if (value) {
        tooltip = translate(`expedients.fields.tooltips.${status}`) as string;
        icon = ICONS[status || "PENDING"];
    } else if (required) {
        tooltip = translate(`expedients.required`) as string;
        icon = ICONS["NOT_ACCEPTED"];
    } else {
        tooltip = translate(`expedients.optional`) as string;
        icon = ICONS["NO_VALUE"];
    }

    return (
        <Tooltip title={tooltip} >
            <Box mr={1} pt={1}>
                {icon}
            </Box>
        </Tooltip>
    );
};

export function getColor(status: string | undefined){
    if(status === "EXPIRED"){
        return "#F44336";
    }else{
        return "primary";
    }
}