import { callAPI, HTTPMethod } from "./API";
import { SearchByRfc, DeleteTenantCfdisRequest } from "../model/Tenant";
import { Providers } from "../model/Provider";
import { ImpersonalizeUserRequest, ValidatePasswordRequest } from "../model/User";
import { Session } from "../model/Session";
import { RevalidationSAT, CfdiAllowedToDeleteResponse, CfdiBackwardPendingPaymentRequest } from "./Support";

export async function listProvidersByRfc(request: SearchByRfc, tenantId: string): Promise<Providers> {
    return await callAPI(`/support/tenants/${tenantId}/providers`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function deletePayableData(request: DeleteTenantCfdisRequest): Promise<void> {
    return await callAPI("/support/delete-payable-data", {
        method: HTTPMethod.DELETE,
        body: request
    });
}

export async function validatePassword(request: ValidatePasswordRequest): Promise<void> {
    return await callAPI("/support/validate-password", {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function impersonalizeUser(request: ImpersonalizeUserRequest): Promise<Session> {
    return await callAPI("/support/impersonalize-user", {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function revalidateSAT(): Promise<RevalidationSAT> {
    return await callAPI("/support/revalidate-sat", {
        method: HTTPMethod.PUT,
    });
}

export async function getCfdiAllowedToDelete(uuid: string): Promise<CfdiAllowedToDeleteResponse> {
    return await callAPI(`/support/cfdi-allowed-to-delete/${uuid}`, {
        method: HTTPMethod.GET,
    });
}

export async function backwardErpToPendingPayment(request: CfdiBackwardPendingPaymentRequest): Promise<void> {
    return await callAPI("/support/backward-to-pending-payment", {
        method: HTTPMethod.PUT,
        body: request
    });
}