import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { CfdiReviewListParams, PaymentListParams, Payments, PaymentRequest, Payment,PaymentCfdiAttacment, AttachPaymentCfdis, PaymentDeleteRequest, ImportPaymentSummary, CfdiPaymentDetail, CfdisPaymentsReview, PaymentCfdiReviewActionRequest, StatusesPayment, PaymentOperationFileRequest, BlockedDeleteResponse } from "../model/Payment";
import { CfdiRequest, CfdisRequest } from "../model/Cfdi";
import { ExportCfdisResponse, TenantCfdisRequest } from "../model/TenantCfdi";


export async function getPayments(tenantId: string, pageSize: number, offset: number, params: PaymentListParams): Promise<Payments> {
    
    let query = {
        search: params.search,
        paymentStatus: params.payment_status ? params.payment_status : "",
        providerId: params.provider_id ? params.provider_id : "",
        operationNumber: params.operation_number ? params.operation_number : "",
        providerName: params.provider_name ? params.provider_name : "",
        comprobantes: params.comprobantes ? params.comprobantes : "",
        totalAmount: params.total_amount ? params.total_amount : "",
        pageSize: pageSize.toString(),
        offset: offset.toString(),
        company: params.company_name ? params.company_name : "",
    } as Record<string, string>;

    if (params.payment_start_date && params.payment_end_date) {
        query = { ...query,
        paymentStartDate: params.payment_start_date,
        paymentEndDate: params.payment_end_date };
    }

    if (params.start_deadline_payment_cfdi &&  params.end_deadline_payment_cfdi) {
        query = { ...query,
        startDeadlinePaymentCfdi: params.start_deadline_payment_cfdi,
        endDeadlinePaymentCfdi: params.end_deadline_payment_cfdi };
    }
    
    return await callAPI(`/tenants/${tenantId}/payments`, {
        method: HTTPMethod.GET,
        query: query,
    });
}


export async function createPayment(tenantId: string, request: PaymentRequest): Promise<Payment> {
    return await callAPI(`/tenants/${tenantId}/payments`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function attachPaymentCfdi(tenantId: string, request: CfdiRequest): Promise<PaymentCfdiAttacment> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("companyId", request.company_id ?? "");
    data.append("providerId", request.provider_id ?? "");
    data.append("isOnlySe", request.only_se === undefined ? "" : request.only_se+"");
    
    return await callAPI(`/tenants/${tenantId}/payments/cfdi`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function callAttachPaymentCfdis(tenantId: string, request: CfdisRequest): Promise<AttachPaymentCfdis> {
    let data = new FormData();
    request.xmls.forEach(xml => {
        data.append("xmls", xml as Blob, xml.name);
    });
    request.pdfs.forEach(pdf => {
        data.append("pdfs", pdf as Blob, pdf.name);
    });
    data.append("providerId", request.provider_id ?? "");

    return await callAPI(`/tenants/${tenantId}/payments/cfdis`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function sendPaymentCfdiToReview(tenantId: string, xml: File, pdf: File, paymentIds: string): Promise<void> {
    let data = new FormData();
    data.append("xml", xml as Blob, xml.name);
    data.append("pdf", pdf as Blob, pdf.name);
    data.append("paymentIds", paymentIds);

    return await callAPI(`/tenants/${tenantId}/payments/review-cfdi`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getPayment(tenantId: string, paymentId: string, pageLine: number, offsetLine: number): Promise<Payment> {
    return await callAPI(`/tenants/${tenantId}/payments/${paymentId}`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: pageLine.toString(),
            offset: offsetLine.toString()
        }
    });
}

export async function exportAllCfdisPayment(tenantId: string, userId: string, params: PaymentListParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/payments/export`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            paymentStatus: params.payment_status ? params.payment_status : "",
        }
    });
}

export async function exportCfdisPayment(tenantId: string, request: TenantCfdisRequest): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/payments/export`, {
        method: HTTPMethod.PUT,
        body: request
    });
}
export async function deletePayment(tenantId: string, paymentId: string, request: PaymentDeleteRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/payments/${paymentId}`, {
        method: HTTPMethod.DELETE,
        body: request
    });
}
export async function importPayments(tenantId: string, file: File): Promise<ImportPaymentSummary> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    return await callAPI(`/tenants/${tenantId}/payments/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function downloadImportTemplate(tenantId: string, connectorId: string): Promise<string> {
    return await getAuthenticatedGetUrl(`/tenants/${tenantId}/payments/import`, {
        query: {
            connectorId: connectorId,
        },
    });
}

export async function sendNotificationsPendingCfdisPayment(tenantId: string, paymentsId: string, razon: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/payments/send-notifications`, {
        method: HTTPMethod.POST,
        query: {
            razon: razon,
            paymentsId: paymentsId,
        }
    });
}

export async function exportExcelPayments(tenantId: string, userId: string, params: PaymentListParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/payments/export-excel-payments`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            paymentStatus: params.payment_status ? params.payment_status : "",
            paymentsIds: params.payments_ids ? params.payments_ids : ""
        }
    });
}

export async function getPaymentsCfdiForReview(tenantId: string, pageSize: number, offset: number, params: CfdiReviewListParams): Promise<CfdisPaymentsReview> {
    return await callAPI(`/tenants/${tenantId}/payments/list-payment-cfdis-in-review`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search ? params.search : "",
            payments: params.payments ? params.payments : "",
            providerName: params.name_provider ? params.name_provider : "",
            providerId: params.provider_id ? params.provider_id : "",
            pageSize: pageSize.toString(),
            offset: offset.toString(),
            company: params.name_company ? params.name_company : "",
        }
    });
}

export async function getPaymentCfdiForReviewDetails(tenantId: string, cfdiId: string): Promise<CfdiPaymentDetail> {
    return await callAPI(`/tenants/${tenantId}/payments/payment-cfdi-in-review/${cfdiId}`, {
        method: HTTPMethod.GET,
    });
}

export async function paymentCfdiReview(tenantId: string, request: PaymentCfdiReviewActionRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/payments/payment-cdfi/review`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function listPaymentStatus(tenantId: string): Promise<StatusesPayment>{
    return await callAPI(`/tenants/${tenantId}/payments/payment-status`, {
        method: HTTPMethod.GET,
    });
}

export async function uploadOperationFile(tenantId: string, paymentId: string, request: PaymentOperationFileRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/payments/${paymentId}/operation-file`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function isBlockedDelete(tenantId: string, paymentId: string): Promise<BlockedDeleteResponse> {
    return await callAPI(`/tenants/${tenantId}/payments/isBlockedDelete/${paymentId}`, {
        method: HTTPMethod.GET,
    });
}

export async function cancelPaymentCfdi(tenantId: string, paymentId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/payments/${paymentId}/paymentCfdi`, {
        method: HTTPMethod.DELETE,
    });
}

export async function generatePaymentCfdi(tenantId: string, paymentId: string): Promise<PaymentCfdiAttacment> {
    return await callAPI(`/tenants/${tenantId}/payments/${paymentId}/generate-payment-cfdi`, {
        method: HTTPMethod.POST,
    });
}