import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { PaymentOrders, PaymentOrdersParams, PaymentOrder, PaymentOrderRequest, ExecutePaymentFileRequest, ChangeStatusRequest, CreatePaymentFileRequest, ExchangeRates, PaymentOrderRejectRequest, PreparePaymentFileData } from "../model/PaymentOrder";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import { PayAllPaymentOrderRequest } from "../model/Payment";

export async function list(tenantId: string, pageSize: number, offset: number, params: PaymentOrdersParams, location: string): Promise<PaymentOrders> {
        let query = {
            search: params.search,
            sortDescending: params.sort_descending ? "true" : "false",
            paymentOrderStatus: params.payment_order_status ? params.payment_order_status : location,
            pageSize: pageSize.toString(),
            offset: offset.toString(),
            operationNumber: params.operation_number ? params.operation_number : "",
            title: params.title ? params.title : "",
            company: params.company_name ? params.company_name : "", 
        } as Record<string, string>;

        if(params.start_schedule_date && params.end_schedule_date){
            query = { ...query,
                startScheduleDate: params.start_schedule_date,
                endScheduleDate: params.end_schedule_date };
        }

        return await callAPI(`/tenants/${tenantId}/payment-orders`, {
            method: HTTPMethod.GET,
            query: query,
        });

}

export async function getExchangeRates(tenantId: string, date: string): Promise<ExchangeRates> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/exchange-rates`, {
        method: HTTPMethod.GET,
        query: {
            date: date,
        },
    });
}

export async function create(tenantId: string, request: PaymentOrderRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function update(tenantId: string, paymentOrderId: string, request: PaymentOrderRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function get(tenantId: string, paymentOrderId: string): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}`, {
        method: HTTPMethod.GET,
    });
}

export async function deletePaymentOrder(tenantId: string, paymentOrderId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function preparePaymentFile(tenantId: string, paymentOrderId: string): Promise<PreparePaymentFileData> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/prepare-payment-file`, {
        method: HTTPMethod.POST,
    });
}

export async function createPaymentFile(tenantId: string, paymentOrderId: string, request: CreatePaymentFileRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/create-payment-file`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function cancelPaymentFile(tenantId: string, paymentOrderId: string, paymentFileId: string): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/payment-files/${paymentFileId}/cancel`, {
        method: HTTPMethod.PUT
    });
}

export async function execute(tenantId: string, paymentOrderId: string, request: ExecutePaymentFileRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/execute`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function exportCfdis(tenantId: string, paymentOrderId: string): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/export-cfdis`, {
        method: HTTPMethod.GET,
    });
}

export async function exportExcel(tenantId: string, params: PaymentOrdersParams, location: string): Promise<ExportCfdisResponse> {
    let query = {
        search: params.search,
        sortDescending: params.sort_descending ? "true" : "false",
        paymentOrderStatus: params.payment_order_status ? params.payment_order_status : location,
        operationNumber: params.operation_number ? params.operation_number : "",
        title: params.title ? params.title : "",
        ids: params.ids ? params.ids :"",
    } as Record<string, string>;

    if(params.start_schedule_date && params.end_schedule_date){
        query = { ...query,
            paymentStartDate: params.start_schedule_date,
            paymentEndDate: params.end_schedule_date };
    }
    return await callAPI(`/tenants/${tenantId}/payment-orders/export-excel`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function changeStatus(tenantId: string, paymentOrderId: string, request: ChangeStatusRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/change-status`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function exportPaymentFile(tenantId: string, paymentOrderId: string, paymentFileId: string): Promise<string> {
    return getAuthenticatedGetUrl(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/payment-files/${paymentFileId}/export`)
}

export async function payAllCfdisPending(tenantId: string, paymentOrderId: string, request: PayAllPaymentOrderRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/pay-all-cfdis`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function exportPdf(tenantId: string, paymentOrderId: string): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/export-pdf`, {
        method: HTTPMethod.GET,
    });
}
export async function reject(tenantId: string, paymentOrderId: string, request: PaymentOrderRejectRequest): Promise<PaymentOrder> {
    return await callAPI(`/tenants/${tenantId}/payment-orders/${paymentOrderId}/reject`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}