import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import DownloadAllIcon from '@material-ui/icons/AssignmentReturnedTwoTone';
import SmsFailedTwoToneIcon from '@material-ui/icons/SmsFailedTwoTone';
import DownloadValidationsPdfOption from "./DownloadValidationsPdfOption";

interface CfdisMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete?(): any;
    onDownload(): any;
    onDownloadAll(): any;
    onClarification(): any;
    onDownloadValidationsPdf?(): void;
    onDownloadValidationsPdfSelected?(): void;
    origin: string;
}

export default function ProviderCfdisMenu(props: CfdisMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {context.isGrantedAny(["ProviderCFDIsRead", "SenderCFDIsRead"]) &&
                <MenuItem button onClick={props.onDownload} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.download_cfdis")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ProviderCFDIsRead") &&
                <MenuItem button onClick={props.onDownloadAll} dense>
                    <ListItemIcon>
                        <DownloadAllIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.download_all_cfdis")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["ProviderCFDIsDelete","SenderCFDIsDelete"]) && props.origin === "to-send" &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.delete_cfdi")}</Typography>
                </MenuItem>
            }
            <MenuItem button onClick={props.onClarification} dense>
                    <ListItemIcon>
                        <SmsFailedTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.clarification")}</Typography>
            </MenuItem>
            {context.isGrantedAny(["ProviderCFDIsRead", "SenderCFDIsRead", "ProviderCfdisIssuedRead"]) &&
                <DownloadValidationsPdfOption
                    onDownload={props.onDownloadValidationsPdf}
                    onDownloadSelected={props.onDownloadValidationsPdfSelected}
                />
            }
        </Menu>
    );
}