import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import OffIcon from "@material-ui/icons/ToggleOffTwoTone";
import OnIcon from "@material-ui/icons/ToggleOn";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface PaymentConditionMenuProps {
    active: boolean;
    urlPaymentConditionMenu: string;
    anchor: HTMLElement | null;
    onClose(): any;
    onInactivated(): any;
}

export default function PaymentConditionMenu(props: PaymentConditionMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["PaymentConditionsUpdate"]) &&
                <MenuItem button component={Link} to={props.urlPaymentConditionMenu} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["PaymentConditionsUpdate"]) &&
                <MenuItem button onClick={props.onInactivated} dense>
                    <ListItemIcon>
                        {props.active ? <OffIcon fontSize="small" color="primary" /> : <OnIcon fontSize="small" color="primary" />}
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons."+ (props.active ? "desactivate" : "activate"))}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}