import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentationTwoTone';

interface ExtendedFieldsTemplateMenuMenuProps {
    anchor: HTMLElement | null;
    onClose: () => void;
    onDisabled: () => void;
    onEdit: () => void;
}

export default function ExtendedFieldsTemplateMenu(props : ExtendedFieldsTemplateMenuMenuProps){
    const context = useContext(AppContext);

    return (
        <Menu
            id="expedient-template-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {(context.isGrantedAny(["ExtendedFieldsTemplateUpdate"])) &&
                <MenuItem button onClick={() => props.onEdit()} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {(context.isGrantedAny(["ExtendedFieldsTemplateDelete"])) && 
            <MenuItem button onClick={() => props.onDisabled()} dense>
                    <ListItemIcon>
                        <CancelPresentationIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("extended_fields_templates.table.actions.disabled_button")}</Typography>
                </MenuItem>
            }
            
        </Menu>);
}