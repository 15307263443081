import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Entity, AutocompleteProvider } from "../model/Provider";
import { PaymentData, CurrencyAndPaymentMethod } from "../model/Payment";
import Progress from "../components/Progress";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { getProvidersWithPendingPayments } from "../api/ProviderAPI";
import { getCurrencyAndPaymentMethod } from "../api/ProviderAPI";
import { WarningSnackbar } from "../components/Snackbars";
import Autocomplete from '@material-ui/lab/Autocomplete';

interface PaymentInfoPopupProps {
    tenantId: string;
    onClose(): any;
    doAction(request: PaymentData): any;
}

export default function PaymentInfoPopup(props: PaymentInfoPopupProps) {
    const [request, setRequest] = useState<PaymentData>({} as PaymentData);
    const [status, setStatus] = useState<string>("loading");
    const [statusProvider, setStatusProvider] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string[]>([]);
    const [currencyLabels, setCurrencyLabels] = useState<string[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<string[]>([]);
    const [providerId, setProviderId] = useState<string>("");
    const [warning, setWarning] = useState<string>();
    const [providers, setProviders] = useState<Entity[]>([]);
    const [providersAutocomplete, setProvidersAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [relationCurrencyPaymentMethod, setRelationCurrencyPaymentMethod] = useState<CurrencyAndPaymentMethod[]>([]);

    useEffect(() => {
        getProvidersWithPendingPayments(props.tenantId).then((response) => {
            setProviders(response.items);
            let listTemp = [] as AutocompleteProvider[];
            response.items.forEach((provider) => {
                let temp = { title: provider.name, value: provider.id } as AutocompleteProvider;
                listTemp.push(temp);
            });
            setProvidersAutocomplete(listTemp);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });

    }, [props]);

    const onConfirm = () => {
        if (request.currency === "" || request.currency === "---" || request.payment_method === "" || request.payment_method === "---") {
            setWarning(translate("payments_cfdi.warning_all_fields") as string);
            return;
        }
        if (request.amount === undefined || request.amount <= 0) {
            setWarning(translate("payments_cfdi.warning_amount") as string);
            return;
        }
        let provider = providers.find((provider) => provider.id === providerId);
        let temp = request;
        temp.provider_id = providerId;
        temp.provider_name = provider ? provider.name : "";
        setRequest(temp);
        props.doAction(request);
    };

    const hasChangedProviderAutocomplete = (selected: string) => {
        if (selected !== "" && selected !== "---") {
            setStatusProvider(true);
            getCurrencyAndPaymentMethod(props.tenantId, selected).then((response) => {
                setCurrency(response.currency_payment_methods.map((item) => item.currency));
                setCurrencyLabels(response.currency_payment_methods.map((item) => translate("currency."+item.currency) as string));
                setRelationCurrencyPaymentMethod(response.currency_payment_methods);
                setStatusProvider(false);
                let temp = request;
                temp.currency = "";
                temp.payment_method = "";
                setRequest(temp);
                setProviderId(selected);
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setCurrency([]);
            setPaymentMethods([]);
            let temp = request;
            temp.currency = "";
            temp.payment_method = "";
            setRequest(temp);
            setProviderId("");
        }
    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const hasChangedCurrency = (name: string, value: string, inputRef: InputRef) => {
        relationCurrencyPaymentMethod.forEach((item) => {
            if (item.currency === value) {
                setPaymentMethods(item.payment_methods);
            }
        });
        setRequest({ ...request, [name]: value });
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    return (
        <DialogPopup open
            title={translate("payments_cfdi.title") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onConfirm} variant="outlined" color="primary" >
                    {translate("buttons.accept") as string}
                </Button>
            }>
            {status !== "loading" && status !== "loaded" && (
                status
            )}
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Autocomplete
                            size="small"
                            options={providersAutocomplete}
                            getOptionLabel={(elemento) => elemento.title}
                            noOptionsText={translate("payments_cfdi.no_options_autocomplete") as string}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={translate("payments_cfdi.provider") as string} fullWidth />
                            )}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    hasChangedProviderAutocomplete(newValue.value);
                                } else {
                                    hasChangedProviderAutocomplete("");
                                }
                            }}
                            getOptionSelected={(opt, val) => opt.value === val.value}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ValidatedInput
                            type="text" id="currency" name="currency"
                            label={translate("payments_cfdi.currency") as string}
                            value={request.currency}
                            required
                            margin={"dense"}
                            onValueChanged={hasChangedCurrency}
                            disabled={statusProvider}
                            options={currency} optionLabels={currencyLabels} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box pl={1}>
                            <ValidatedInput
                                type="number" id="amount" name="amount" isValueNumber={true}
                                label={translate("payments_cfdi.amount") as string}
                                value={request.amount ? request.amount.toString() : ""}
                                required
                                margin={"dense"}
                                onValueChanged={hasChanged} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="payment_method" name="payment_method"
                            label={translate("payments_cfdi.payment_method") as string}
                            value={request.payment_method}
                            required
                            disabled={statusProvider}
                            margin={"dense"}
                            onValueChanged={hasChanged}
                            options={paymentMethods} optionLabels={paymentMethods} />
                    </Grid>
                </Grid>
            )}
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </DialogPopup>
    );

}