import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import { Grid, Fab, Divider, IconButton, Checkbox } from "@material-ui/core";
import queryString from "query-string";
import { listRequisitions, deleteRequision, getTotalRequsitions, approveListed, approveSelected } from "../api/RequisitionAPI";
import { listRequisitionTypeCatalog } from "../api/RequisitionTypeCatalogAPI";
import { RequisitionsQueryFilter, Requisitions, Requisition, RequisitionBulkApproveRequest, RequisitionBulkApproveRequestWithParams, RequisitionsQueryFilterPage, RequisitionBulkApproveResponse, RequisitionBulk } from "../model/Requisition";
import { RequisitionTypeCatalogListParams, RequisitionTypeCatalog, PurchaseOrderMode } from "../model/RequisitionTypeCatalog";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Filter } from "@devexpress/dx-react-grid";
import AddIcon from "@material-ui/icons/Add";
import { getRequisitionsProperties, GetRequisitionsPropertiesProps } from "./RequisitionsProperties";
import GridDx from "../components/GridDx";
import { formatDateString, concatDates } from "../components/DateFormat";
import RequisitionMenu from "./RequisitionMenu";
import NewRequisitionMenu from "./NewRequisitionMenu";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';

import CfdisToSendIcon from "@material-ui/icons/SendTwoTone";
import ToAuthorizeIcon from "@material-ui/icons/GavelTwoTone";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheckTwoTone";
import ArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import ConfirmationPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import PresentToAllIcon from '@material-ui/icons/PresentToAllTwoTone';
import { StatusDescription } from "../model/StageStatus";
import { listCompanies } from "../api/CompanyAPI";
import { CompaniesQueryParams, Company } from "../model/Company";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RequisitionsMenu from "./RequisitionsMenu";
import { TotalAuthorizeAmountResponse } from "../model/Cfdi";
import { AmountsByCurrency } from "../model/TenantCfdi";
import ShowResultsRequisitionsBulkAuthModal from "./ShowResultsRequisitionsBulkAuthModal";
import { Table } from '@devexpress/dx-react-grid-material-ui';

export default function RequisitionsToSend({ match }: RouterParams) {
    const [view, setView] = useState<string>("");
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const tenantId = context.session!.tenant!.id;
    const allowBulkAuth = context.session!.tenant!.allow_bulk_auth;
    const paramsFromQueryString = (): RequisitionsQueryFilter => {
        return {
            "operationNumber": typeof qs["operationNumber"] === "string" ? qs["operationNumber"] as string : "",
            "startDate": typeof qs["startDate"] === "string" ? qs["startDate"] as string : "",
            "endDate": typeof qs["endDate"] === "string" ? qs["endDate"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "providerName": typeof qs["providerName"] === "string" ? qs["providerName"] as string : "",
            "company": typeof qs["company"] === "string" ? qs["company"] as string : "",
            "projectName": typeof qs["projectName"] === "string" ? qs["projectName"] as string : "",
            "requisitionTypeName": typeof qs["requisitionTypeName"] === "string" ? qs["requisitionTypeName"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
        } as RequisitionsQueryFilter;
    };
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Requisitions>();
    const [params, setParams] = useState<RequisitionsQueryFilter>(paramsFromQueryString);
    const [requisition, setRequisition] = useState<Requisition>();
    const [requisitionTypesCatalog, setRequisitionTypesCatalog] = useState<RequisitionTypeCatalog[]>([]);
    const [isTwoModesBudgetControl, setIsTwoModesBudgetControl] = useState<boolean>(false);
    const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
    const [routeEditBudget, setRouteEditBudget] = useState<string>();

    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);

    const [statuses, setStatuses] = useState<StatusDescription[]>();
    const [requisitionType, setRequisitionType] = useState<RequisitionTypeCatalog>();
    const [countTypesBudgetMode, setCountBudgetMode] = useState<number>();
    const [countTypesQuotationMode, setCountQuotationMode] = useState<number>();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const [openConfirmAutorize, setOpenConfirmAuthorize] = useState<boolean>(false);
    const [totalAmountToAuthorize, setTotalAmountToAuthorize] = useState<string>("");
    const [totalToAuthorize, setTotalToAuthorize] = useState<number>(0);
    const [authroizeSelected, setAuthorizeSelected] = useState<boolean>(false);

    const [requisitionsFailed, setRequisitionsFailed] = useState<RequisitionBulk[]>([]);
    const [totalAuthorized, setTotalAuthorized] = useState<string>("");
    const [totalFailed, setTotalFailed] = useState<string>("");
    const [authorized, setAuthorized] = useState<number>(0);
    const [failed, setFailed] = useState<number>(0);
    const [openResultsBulkAuth, setOpenResultsBulkAuth] = useState<boolean>(false);

    const getView = () => {
        let href = window.location.pathname;

        if (href.includes('to_send')) {
            return 'to_send';
        } else if (href.includes('archive')) {
            return 'archive';
        } else if (href.includes('to_authorize')) {
            return 'to_authorize';
        } else if (href.includes('authorized')) {
            return 'authorized';
        } else if (href.includes('delivered_erp')) {
            return 'delivered_erp';
        }
        return "";
    };

    const paramsToFilters = () => {
        return [
            { columnName: 'description', value: params.description },
            { columnName: 'operation_number', value: params.operationNumber },
            { columnName: 'provider_name', value: params.providerName },
            { columnName: 'company_name', value: params.company },
            { columnName: 'project_name', value: params.projectName },
            { columnName: 'requisition_type_name', value: params.requisitionTypeName },
            { columnName: 'created_at', value: concatDates(params.startDate, params.endDate) },
            { columnName: 'status', value: params.status },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        if (match.params.success && isSuccess) {
            setSuccess(match.params.success);
            setIsSuccess(false);
        }
        let view = getView();
        setView(view);
        let statusGroup = getStatusFromVIew(view);
        Promise.all([
            listRequisitions(context.session!.tenant!.id,
                pageSize, pageSize * page, { ...params, statusGroup: statusGroup, view: view } as RequisitionsQueryFilter),
            listRequisitionTypeCatalog(tenantId, 0, 0, {} as RequisitionTypeCatalogListParams),
            listCompanies(tenantId, 0, 0, { search: "" } as CompaniesQueryParams)
        ]).then(([requisitions, requisitionTypes, companies]) => {
            setStatuses(requisitions.statuses || []);
            setData(requisitions);
            if (requisitionTypes.items) {
                setRequisitionTypesCatalog(requisitionTypes.items);
                let countBudgetMode = requisitionTypes.items.filter(item => item.purchase_order_mode && (PurchaseOrderMode.BUDGET_CONTROL === item.purchase_order_mode)).length;
                let countQuotationMode = requisitionTypes.items.filter(item => item.purchase_order_mode && (PurchaseOrderMode.QUOTATION_BY_PROVIDER === item.purchase_order_mode)).length;
                setCountBudgetMode(countBudgetMode);
                setCountQuotationMode(countQuotationMode);
                if (countBudgetMode && countQuotationMode &&
                    countBudgetMode > 0 && countQuotationMode > 0) {
                    setIsTwoModesBudgetControl(true);
                }
            }
            if (companies.total > 0) {
                setCompanies(companies.items);
            }
            pushHistory();
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const getStatusFromVIew = (view: string) => {
        switch (view) {
            case 'to_send':
                return "TO_SEND";
            case 'archive':
                return "";
            case 'to_authorize':
                return "TO_AUTHORIZE";
            case 'authorized':
                return "PENDING_PURCHASE_ORDER";
            case 'delivered_erp':
                return "DELIVERED_ERP";
            default:
                return "";
        }
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "operation_number":
                        temp.operationNumber = filter.value;
                        break;
                    case "provider_name":
                        temp.providerName = filter.value;
                        break;
                    case "company_name":
                        temp.company = filter.value;
                        break;
                    case "project_name":
                        temp.projectName = filter.value;
                        break;
                    case "requisition_type_name":
                        temp.requisitionTypeName = filter.value;
                        break;
                    case "created_at":
                        let dates = filter.value.split(" - ");
                        if (dates.length === 2) {
                            temp.startDate = formatDateString(dates[0]);
                            temp.endDate = formatDateString(dates[1]);
                        } else {
                            temp.startDate = "";
                            temp.endDate = "";
                        }
                        break;
                    case "status":
                        temp.status = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["operationNumber"] = params.operationNumber ? params.operationNumber : "";
        qs["startDate"] = params.startDate ? params.startDate : "";
        qs["endDate"] = params.endDate ? params.endDate : "";
        qs["description"] = params.description ? params.description : "";
        qs["providerName"] = params.providerName ? params.providerName : "";
        qs["company"] = params.company ? params.company : "";
        qs["projectName"] = params.projectName ? params.projectName : "";
        qs["requisitionTypeName"] = params.requisitionTypeName ? params.requisitionTypeName : "";
        qs["status"] = params.status ? params.status : "";
        qs["page"] = "0";
        qs["referer"] = getView();

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onClickedOptions = (requisition: Requisition) => (event: React.MouseEvent<HTMLElement>) => {
        setRequisition(requisition);

        event.stopPropagation();
        pushHistory();
        let requisitionType = requisitionTypesCatalog.find(item => item.id === requisition.requisition_type_id);
        if (requisitionType && PurchaseOrderMode.BUDGET_CONTROL === requisitionType.purchase_order_mode) {
            setRouteEditBudget(`/requisitions/${requisition.id}/${PurchaseOrderMode.BUDGET_CONTROL}/edit?referer=${view}&${queryParam}`);
        }
    };

    const onCloseOption = () => {
        setRouteEditBudget(undefined);
        setRequisition(undefined);
    };

    const getTotalToAuthorizeListed = (response: TotalAuthorizeAmountResponse) => {
        let amountsByCurrencyStr = response.items.map(amountByCurrency => {
            return `$ ${amountByCurrency.total_amount} ${amountByCurrency.currency}`;
        });
        if (amountsByCurrencyStr.length > 0) {
            return amountsByCurrencyStr.join(" + ");
        }
        return "$ 0";
    }


    const onAuthorizeListed = () => {
        setGridAnchorEl(null);
        if (data?.total && data?.total > 0) {
            setOpenBackdrop(true);
            setOpenConfirmAuthorize(true);
            getTotalRequsitions(context.session!.tenant!.id, {
                ...params,
                statusGroup: getStatusFromVIew(view), view: view
            } as RequisitionsQueryFilter
            ).then(response => {
                setTotalToAuthorize(data?.total);
                setTotalAmountToAuthorize(getTotalToAuthorizeListed(response));
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        } else {
            setWarning(translate("requisitions.bulk_auth.empty_list") as string);
        }
    };

    const openConfirmAuthorizeCfdisSelected = () => {
        setGridAnchorEl(null);
        if (selected && selected.length > 0) {
            setTotalToAuthorize(selected.length);
            setTotalAmountToAuthorize(getTotalToAuthorize(data?.items
                .filter(item => selected.findIndex(selected => item.id === selected) >= 0) || []));
            setAuthorizeSelected(true);
            setOpenConfirmAuthorize(true);
        } else {
            setWarning(translate("requisitions.bulk_auth.warning_empty_selection") as string);
        }
    };

    const getTotalToAuthorize = (items: Requisition[]) => {

        let amountsByCurrency = [] as AmountsByCurrency[];
        items.forEach(item => {
            let amountByCurrency = amountsByCurrency.find(field => field.currency === item.currency);
            let authorized = item.total;
            if (amountByCurrency) {
                amountByCurrency.amounts.push(authorized);
            } else {
                amountsByCurrency.push({ currency: item.currency, amounts: [authorized] } as AmountsByCurrency);
            }
        });

        let amountsByCurrencyStr = amountsByCurrency.map(amountByCurrency => {
            let amount = amountByCurrency.amounts.reduce((a, b) => a + b);
            let amuntStr = new Intl.NumberFormat(undefined, { style: 'currency', currency: amountByCurrency.currency }).format(amount);
            return `${amuntStr} ${amountByCurrency.currency}`;
        });
        if (amountsByCurrencyStr.length > 0) {
            return amountsByCurrencyStr.join(" + ");
        }
        return "$ 0";
    }

    const showDeleteDialog = (req: Requisition) => {
        setRequisition(req);
        setRequisitionType(requisitionTypesCatalog.find(rt => rt.id === req.requisition_type_id));
        setOpenConfirmDelete(true);
    };

    const onCancelDelete = () => {
        setOpenConfirmDelete(false);
        setRequisitionType(undefined);
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onAddRequisition = (event: React.MouseEvent<HTMLElement>) => {
        if (isTwoModesBudgetControl) {
            setAddAnchorEl(event.currentTarget);
        } else if (countTypesQuotationMode && countTypesQuotationMode! > 0 && countTypesBudgetMode == 0) {
            setRedirect(`/requisitions/${"QUOTATION_BY_PROVIDER"}/new?referer=${view}`);
        } else {
            setRedirect(`/requisitions/${"BUDGET_CONTROL"}/new?referer=${view}`);
        }
    }

    const onClickedRow = (requisition: Requisition) => {
        pushHistory();
        let requisitionType = requisitionTypesCatalog.find(item => item.id === requisition.requisition_type_id);
        if (requisitionType && PurchaseOrderMode.BUDGET_CONTROL === requisitionType.purchase_order_mode) {
            setRedirect(`/requisitions/${requisition.id}/${PurchaseOrderMode.BUDGET_CONTROL}/edit?referer=${view}&${queryParam}`);
        } else {
            setRedirect(`/requisitions/${requisition.id}/details?referer=${view}&${queryParam}`);
        }
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelected(selected);
    };

    const onDeleteRequisition = () => {
        if (!requisition) return;
        setOpenConfirmDelete(false);
        setOpenBackdrop(true);
        deleteRequision(context.session!.tenant!.id, requisition.id).then(() => {
            load();
            setSuccess(translate("requisitions.confirm_delete.success", { "number": requisition.operation_number }) as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setRequisition(undefined);
            setOpenBackdrop(false);
            setRequisitionType(undefined);
        });
    };

    const getIcon = () => {
        if (view === "to_send") {
            return <CfdisToSendIcon />;
        } else if (view === "to_authorize") {
            return <ToAuthorizeIcon />;
        } else if (view === "authorized") {
            return <PlaylistAddCheckIcon />;
        } else if (view === "delivered_erp") {
            return <PresentToAllIcon />;
        } else if (view === "archive") {
            return <ArchiveIcon />;
        }
        return <></>;
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const getActions = () => {
        if (view === 'to_authorize' && allowBulkAuth) {
            return (<Grid >
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    {context.isGrantedAny(["RequisitionsUpdate"]) ?
                        (<Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>) : undefined}
                </Grid>
            </Grid>);
        }
        if (view !== 'to_send') {
            return undefined;
        }
        return (
            <Grid >
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("buttons.add") as string}
                            onClick={onAddRequisition} disabled={status === "loading"}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const onNewRequisition = (mode: PurchaseOrderMode) => {
        setRedirect(`/requisitions/${mode}/new?referer=${view}`);
    };

    const onCancelAdding = () => {
        setAddAnchorEl(null);
    };

    if (status === "loading" || !statuses) {
        return <Progress />;
    }

    const gridProps = getRequisitionsProperties({ view: view, statuses: statuses, companies: companies } as GetRequisitionsPropertiesProps);

    const authorizeRequisitionSelected = (comment: string) => {
        setOpenBackdrop(true);
        setAuthorizeSelected(false);
        setOpenConfirmAuthorize(false);
        const request = { ids: selected, comments: comment, stage_from: "TO_AUTHORIZE" } as RequisitionBulkApproveRequest;
        approveSelected(context.session!.tenant!.id, request).then((response) => {
            openModalResults(response);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const authorizeRequisitionListed = (comment: string) => {
        setOpenBackdrop(true);
        setOpenConfirmAuthorize(false);
        const request = { comments: comment, stage_from: "TO_AUTHORIZE" } as RequisitionBulkApproveRequest;
        const paramsRequest = { ...params, offset: 0, pageSize: 0, statusGroup: "TO_AUTHORIZE", view: view } as RequisitionsQueryFilterPage;
        approveListed(context.session!.tenant!.id, request, paramsRequest).then((response) => {
            openModalResults(response);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const openModalResults = (response: RequisitionBulkApproveResponse) => {
        if (response.queued) {
            setSuccess(translate("requisitions.bulk_auth.success_bulk") as string);
            return;
        }
        let requisitionsAuthorized = response.items.filter(item => !item.error || item.error === null);
        let authorizedAmountStr = getTotalToAuthorize(requisitionsAuthorized);
        let requisitionsFailed = response.items.filter(item => item.error);
        let failedAmountStr = getTotalToAuthorize(requisitionsFailed);
        setRequisitionsFailed(requisitionsFailed);
        setTotalAuthorized(authorizedAmountStr);
        setTotalFailed(failedAmountStr);
        setAuthorized(requisitionsAuthorized.length);
        setFailed(requisitionsFailed.length);

        setOpenResultsBulkAuth(true);
    };

    const getMenuFixed = (item: Requisition) => {
        return <MenuFixed
            item={item}
            onClickedOptions={onClickedOptions}
            onClose={onCloseOption}
            onDelete={(reqItem) => showDeleteDialog(reqItem)}
            view={view}
            refer={queryParam}
            routeBudgetControl={routeEditBudget}
        />
    }

    const TableCell = (restProps: any) => {
        let readOnly = restProps.row?.read_only?.active;
        let style = restProps.style ?? {};
        return (
            <Table.Cell column={restProps.column} row={restProps.row} tableColumn={restProps.tableColumn} tableRow={restProps.tableRow} value={restProps.value}
                {...restProps}
                style={{ ...style, backgroundColor: readOnly ? '#F8F8F8' : '', fontStyle: readOnly ? "italic" : undefined }}
            >
                {restProps.children}
            </Table.Cell>
        );
    };

    const CheckCell = (restProps: any, header: boolean) => {
        let style = restProps.style ?? {};
        let readOnly = restProps.row?.read_only?.active;
        return (
            <Table.Cell column={restProps.column} row={restProps.row} tableColumn={restProps.tableColumn} tableRow={restProps.tableRow} value={restProps.value}
                {...restProps} style={{ ...style, backgroundColor: readOnly ? '#F8F8F8' : '', paddingLeft: "3px" }} >
                <Checkbox checked={header ? restProps.allSelected : restProps.selected} onChange={() => restProps.onToggle()} size="small" indeterminate={restProps.someSelected} />
            </Table.Cell>
        );
    };

    return (
        <Surface title={translate("requisitions." + view + ".title_view") as string}
            icon={getIcon()}
            className="PaperPagination"
            titleActions={getActions()}>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={gridProps.columns ? gridProps.columns : []}
                columnsFormat={gridProps.columnsFormat}
                clickRowColumns={gridProps.clickRowColumns}
                onClickRow={onClickedRow}
                onClickedOptions={onClickedOptions}
                defaultExpandedGroups={gridProps.defaultExpandedGroups}
                amountCurrencyColumns={gridProps.amountCurrencyColumns}
                dateColumns={gridProps.dateColumns}
                leftColumns={gridProps.leftColumns}
                rightColumns={gridProps.rightColumns}
                textColumns={gridProps.textColumns}
                filters={filters}
                grouping={gridProps.grouping}
                customFormatColumns={gridProps.customFormatColumns}
                setFiltersHandler={setFiltersHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                selectionIds={view === 'to_authorize' && allowBulkAuth ? selected : undefined}
                setSelectionHandler={view === 'to_authorize' && allowBulkAuth ? setSelectionHandler : undefined}
                setTableCell={TableCell}
                setCheckCell={CheckCell}
                menuFixed={getMenuFixed}
            />
            {openConfirmDelete && requisitionType &&
                <ConfirmationPopup
                    title={translate("requisitions.confirm_delete.title") as string}
                    message={translate(`requisitions.${(PurchaseOrderMode.BUDGET_CONTROL === requisitionType.purchase_order_mode) ? "confirm_delete_budget_control" : "confirm_delete"}.message`) as string}
                    secondary={translate(`requisitions.${(PurchaseOrderMode.BUDGET_CONTROL === requisitionType.purchase_order_mode) ? "confirm_delete_budget_control" : "confirm_delete"}.secondary`) as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDelete}
                    doAction={onDeleteRequisition}
                />
            }
            {addAnchorEl && (
                <NewRequisitionMenu
                    anchor={addAnchorEl}
                    onClose={onCancelAdding}
                    onNewRequisition={onNewRequisition} />
            )}
            {gridAnchorEl && (
                <RequisitionsMenu
                    anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onAuthorizeListed={onAuthorizeListed}
                    onAuthorizeSelected={openConfirmAuthorizeCfdisSelected}
                />
            )}
            {openConfirmAutorize && (
                <ConfirmationPopup
                    doActionCommentary={authroizeSelected ? authorizeRequisitionSelected : authorizeRequisitionListed}
                    onClose={() => setOpenConfirmAuthorize(false)}
                    title={translate("requisitions.bulk_auth.title") as string}
                    message={translate("requisitions.bulk_auth.message", { "N": totalToAuthorize, "amount": totalAmountToAuthorize }) as string}
                    secondary={translate("requisitions.bulk_auth.secondary") as string}
                    comment={translate("requisitions.bulk_auth.comment") as string}
                    addCommentary={false}
                    button={translate("buttons.accept") as string}
                />
            )}
            {openResultsBulkAuth && (
                <ShowResultsRequisitionsBulkAuthModal
                    requisitionsFailed={requisitionsFailed}
                    onClose={() => setOpenResultsBulkAuth(false)}
                    totalAuthorized={totalAuthorized}
                    totalFailed={totalFailed}
                    authorized={authorized}
                    failed={failed}
                />
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Surface >
    );
}

interface MenuFixedProps {
    item: Requisition;
    view: string;
    routeBudgetControl?: string;
    onDelete(item: Requisition): void;
    onClickedOptions(item: Requisition): void;
    onClose(): void;
    refer?: string;
}

function MenuFixed(props: MenuFixedProps) {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const onClickedOptions = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchor(event.currentTarget);
        props.onClickedOptions(props.item);
    };

    const onCloseOption = () => {
        setAnchor(null);
        props.onClose();
    };

    return <>
        <IconButton aria-label="options" color="default" size="small"
            onClick={onClickedOptions}>
            <MoreVertIcon />
        </IconButton>
        {anchor &&
            <RequisitionMenu
                anchor={anchor}
                requisitionId={props.item.id}
                routeBudgetControl={props.routeBudgetControl}
                onClose={onCloseOption}
                onDelete={() => props.onDelete(props.item)}
                view={props.view}
                refer={props.refer}
                readOnly={!!props.item.read_only?.active}
            />
        }
    </>
}