import React from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";


import translate from "../i18n/Translator";
import { ContributionField } from "../model/Contribution";

interface ContributionDocMenuProps {
    field: ContributionField;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function ContributionDocMenu(props: ContributionDocMenuProps) {

    return (
        <Menu
            id="contributions"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem
                button
                onClick={props.onClose}
                dense
                component="a"
                href={props.field.value || "#"}
                target="_blank"
                rel="noopener noreferrer"
                disabled={false}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.download")}</Typography>
            </MenuItem>
            
        </Menu>
    );

}