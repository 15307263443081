import React, { useContext, useState, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import { getTenantCfdiFileUrl } from "../../api/TenantCfdiApi";
import { Cfdi, CfdiStatus_CANCELLED, CfdiStatus_PAYMENT_POSTPONE, CfdiStatus_PENDING_PAYMENT } from "../../model/Cfdi";
import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import { exportCfdiFiles } from "../../api/TenantCfdiApi";
import { ExportCfdisResponse } from "../../model/TenantCfdi";
import DownloadExportationPopup from "../../components/DownloadExportationPopup";
import BlockIcon from '@material-ui/icons/Block';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import AuthorizedIcon from "@material-ui/icons/LibraryAddCheckTwoTone";
import { isRoleOrParent } from "../../model/Role";

interface TenantCfdiMenuProps {
    cfdi: Cfdi;
    anchor: HTMLElement | null;
    inner?: boolean;
    referer: string;
    onClose(): any;
    onAuthorize?(): any;
    onReject?(): any;
    onDeleteComprobante?(): any;
    onPostpone?(): any;
    onReactivate?(): any;
    onCancel?(): any;
}

export default function TenantCfdiMenu(props: TenantCfdiMenuProps) {
    const context = useContext(AppContext);
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");

    const onDownloadCfdis = () => {
        exportCfdiFiles(context.session!.tenant!.id, props.cfdi.id).then((response) => {
            if (response.url) {
                setExportResult(response);
            }
        });
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    useEffect(() => {
        getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "xml").then((urlFile) => {
            setXmlUrl(urlFile);
        }).catch((error) => {
            setXmlUrl(undefined);
        });
        getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "pdf").then((urlFile) => {
            setPdfUrl(urlFile);
        }).catch((error) => {
            setPdfUrl(undefined);
        });
    }, [context.session, props.cfdi.id]);

    return (
        <Grid>
            <Menu
                id="cfdi-menu-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}>

                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) && !props.inner &&
                    <MenuItem button component={Link} to={`/cfdis/${props.cfdi.id}/details?referer=${props.referer}`} onClick={props.onClose} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={xmlUrl || ""} disabled={!xmlUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadXmlIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={pdfUrl || ""} disabled={!pdfUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadPdfIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={onDownloadCfdis} dense>
                        <ListItemIcon>
                            <DownloadIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.download_cfdi")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate"]) &&
                    props.cfdi.metadata.status === CfdiStatus_PENDING_PAYMENT && props.onPostpone &&
                    <MenuItem button onClick={props.onPostpone} dense>
                        <ListItemIcon>
                            <AccessTimeTwoToneIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.postpone_payment.click_menu")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate"]) &&
                    props.cfdi.metadata.status === CfdiStatus_PAYMENT_POSTPONE && props.onReactivate &&
                    <MenuItem button onClick={props.onReactivate} dense>
                        <ListItemIcon>
                            <AuthorizedIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.postpone_payment.reactivate")}</Typography>
                    </MenuItem>
                }
                {!props.onCancel || ((isReaderOnly || props.cfdi.metadata.status === CfdiStatus_CANCELLED) ||
                    (props.cfdi.type === "EXPENSES_ACCOUNT" || props.cfdi.type === "HONORARIUM")) ? undefined :
                    <MenuItem button onClick={props.onCancel} dense>
                        <ListItemIcon>
                            <BlockIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.cancel_cfdi.title")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsDelete", "CxpCFDIsDelete"]) && props.onDeleteComprobante &&
                    <MenuItem button onClick={props.onDeleteComprobante} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.delete_cfdi")}</Typography>
                    </MenuItem>
                }
            </Menu>
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export_single.title") as string}
                    message={translate("cfdis.popup.export_single.description", { "id": props.cfdi.identifier }) as string}
                    url={exportResult.url} onClose={onCloseDownloadExportationPopup} />
            )
            }</Grid>);
}
