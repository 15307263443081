import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ListPaymentsForReview from "./ListPaymentsForReview";
import {DataTypeProvider, DataTypeProviderProps, TableSelection } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { Box, FormControl, Select } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { listCompanies } from "../api/CompanyAPI";
import { CompaniesQueryParams, Company } from "../model/Company";

export default function PaymentsPendingReview() {

    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [companies, setCompanies] = useState<Company[]>([]);

    const load = () => {
        setStatus("loading");
        Promise.all([
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams)
        ]).then(([companies]) => {
            if (companies.total > 0) {
                setCompanies(companies.items);
            }
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    }

    useEffect(load, [context.session]);

    const [columnsFormat] = useState([
        { columnName: 'payments', width: 150, wordWrapEnabled: true },
        { columnName: 'name_company', wordWrapEnabled: true, width: 150 },
        { columnName: 'name_provider', width: 400, wordWrapEnabled: true },
        { columnName: 'amount_payments', width: 200, align: 'right', wordWrapEnabled: true },
        { columnName: 'amount_cfdi', align: 'right', width: 200, wordWrapEnabled: true},
        { columnName: 'comprobantes', width: 350, },
        { columnName: 'payment_date', width: 200, },
    ]) as any;

    const currencyColumns = ['amount_payments', 'amount_cfdi'];

    const numberColumns = ['payments'];

    const clickRowColumns = ['payments'];

    const textColumns = ['name_provider', 'name_company'];

    const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'payments']) as any;

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const AmountsPaymentsTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} formatterComponent={
            (value: any) => {
                return <>
                    {value.row.amount_payments ? Object.keys(value.row.amount_payments).map((key) => <>
                        <NumberFormat value={value.row.amount_payments[key]} prefix="$ " suffix={` ${key}`} decimalScale={2} fixedDecimalScale={true} displayType="text" /> <br/>
                    </>) : undefined}
                </>;
            }
        }/>
    );

    const CompanyFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
            <FormControl >
                <Select native style={{
                fontSize: "10px"
            }}
                    labelId="is_default"
                    id="is_default"
                    value={params.value ? params.value : ''}
                    onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                    {companies.map((value) => {
                        return <option key={value.id} value={value.id}>{value.name}</option>
                    })}
                </Select>
            </FormControl>
            </Box>
        );
    };

    const AmountsCfdiTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} formatterComponent={
            (value: any) => {
                return <>
                    {value.row.amount_cfdi ? Object.keys(value.row.amount_cfdi).map((key) => <>
                        <NumberFormat value={value.row.amount_cfdi[key]} prefix="$ " suffix={` ${key}`} decimalScale={2} fixedDecimalScale={true} displayType="text" /> <br/>
                    </>) : undefined}
                </>;
            }
        }/>
    );

    const VouchersProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider  {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={['payment_date']} editorComponent={NoFilterEditor}/>,
        <AmountsPaymentsTypeProvider for={['amount_payments']} editorComponent={NoFilterEditor}/>,
        <AmountsCfdiTypeProvider for={['amount_cfdi']} editorComponent={NoFilterEditor}/>,
        <VouchersProvider for={['comprobantes']} editorComponent={NoFilterEditor} />,
        <NormalTypeProvider for={['name_company']} editorComponent={CompanyFilterEditor} />
    ];

    return (
        <ListPaymentsForReview
            columns={[
                {
                    name: 'payments',
                    title: translate("payment.columns.number") as string
                },
                {
                    name: 'name_company',
                    title: translate('payments_cfdi.company') as string
                },
                {
                    name: 'name_provider',
                    title: translate('payments_cfdi.provider') as string
                },
                {
                    name: 'amount_payments',
                    title: translate("payment.columns.amount_payment") as string
                },
                {
                    name: 'amount_cfdi',
                    title: translate("payment.columns.amount_cfdi") as string
                },
                {
                    name: 'comprobantes',
                    title: translate("payment.columns.cfdis") as string
                },
                {
                    name: 'payment_date',
                    title: translate("payment.columns.date") as string
                }
            ]}
            currencyColumns={currencyColumns}
            clickRowColumns={clickRowColumns}
            numberColumns={numberColumns}
            columnsFormat={columnsFormat}
            leftColumns={leftColumns}
            textColumns={textColumns}
            statusPlugins={customPlugins}
            title={translate("payment.payment_cfdi_in_review.title") as string}
        />
    );
}