import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import EmployeeIcon from "@material-ui/icons/PeopleAlt";

import translate from "../i18n/Translator";
import { createEmployee, getEmployee, updateEmployee } from "../api/EmployeeAPI";
import { createEmployee as createProviderEmployee, getEmployee as getProviderEmployee, updateEmployee as updateProviderEmployee } from "../api/ProviderEmployeeAPI";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import { get as getProviderConfigurations } from "../api/ProviderConfigurationApi";
import { Employee, EmployeeRequest } from "../model/Employee";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { RfcValidator, RegexValidator } from "../components/Validators";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import { EmployerConfiguration } from "../model/Employer";

export default function EmployeeForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;

    const history = useHistory();
    const isEdit = !!match.params.employeeId;
    const margin = "dense";
    const [format] = useState("DD/MM/YYYY");
    const rfcValidator = new RfcValidator();
    const imssValidator = new RegexValidator(/^[0-9]{10,11}$/i, "validations.nss");

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<EmployeeRequest>({} as EmployeeRequest);
    const [validations, setValidations] = useState({} as any);
    const [employerNumbers, setEmployerNumbers] = useState<string[]>([]);

    const submitPromise = (): Promise<Employee> => {
        if (isEdit) {
            if (providerId) {
                return updateProviderEmployee(tenantId, providerId, match.params.employeeId, request);
            }
            return updateEmployee(tenantId, match.params.employeeId, request);
        }

        if (providerId) {
            return createProviderEmployee(tenantId, providerId, request);
        }
        return createEmployee(tenantId, request);
    };

    const promiseGet = (): Promise<Employee> => {
        if (providerId) {
            return getProviderEmployee(tenantId, providerId, match.params.employeeId);
        }
        return getEmployee(tenantId, match.params.employeeId);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            promiseGet().then((employee) => {
                setRequest({
                    rfc: employee.rfc,
                    name: employee.name,
                    nss: employee.nss,
                    curp: employee.curp,
                    employer_number: employee.employer_number,
                    registered_at: employee.registered_at ? moment(employee.registered_at).format(format) : "",
                } as EmployeeRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [tenantId, providerId, isEdit, match.params.employeeId, format]);

    const promiseGetConfigurations = (): Promise<EmployerConfiguration> => {
        if (providerId) {
            return getProviderConfigurations(tenantId, providerId);
        }
        return getTenantConfigurations(tenantId);
    };

    const loadEmployerNumbers = () => {
        promiseGetConfigurations().then(response => {
            setEmployerNumbers(response.employer_numbers || []);
        }).catch(error => {
            console.error(error);
        });
    };

    useEffect(loadEmployerNumbers, [tenantId, providerId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: !value || value === "---" ? undefined : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest({ ...request, registered_at: raw || "" });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((employee) => {
            setRedirect(true);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (redirect) {
        return <Redirect to="/employees" />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "employees.edit" : "employees.new")} icon={<EmployeeIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("employees.name") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="rfc" name="rfc"
                                        value={request.rfc}
                                        label={translate("employees.rfc") as string}
                                        required disabled={isEdit || submitting}
                                        margin={margin} validator={rfcValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="curp" name="curp"
                                        value={request.curp}
                                        label={translate("employees.curp") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="nss" name="nss"
                                        value={request.nss}
                                        label={translate("employees.nss") as string}
                                        required disabled={isEdit || submitting}
                                        margin={margin} validator={imssValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="employer_number" name="employer_number"
                                        value={request.employer_number}
                                        label={translate("contributions.employer_number") as string}
                                        options={employerNumbers}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker name="registered_at"
                                        label={translate("employees.registered_at") as string}
                                        initial={request.registered_at || ""}
                                        clearable format={format}
                                        defaultIfMissing
                                        disablePast={false} disableFuture
                                        required disabled={submitting}
                                        onChange={onChangedDate} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}