import React from "react";
import FreshChat from 'react-freshchat';


class Chat extends React.Component {
  token = "56af26af-4c05-4851-aa6d-7e0805062b61";
  host = "https://wchat.freshchat.com";
  user_id= "";
  user_email = "";
  user_name = "";
  user_phone = "";
  constructor(config) {
    super();
    this.user_id = config.user_id;
    this.user_email = config.user_email;
    this.user_name = config.user_name;
    this.user_phone = config.user_phone;
  }

  render() {
    return <div>
      <FreshChat
        token={this.token}
        host={this.host}
        locale="es"
        onInit={widget => {
            widget.user.setProperties({
              externalId: this.user_id,
              email: this.user_email,
              first_name: this.user_name,
              phone: this.user_phone,
            })
            widget.setConfig({
              headerProperty: {
                direction: 'ltr'
              }
            })
        }}
      />
    </div>
  }
}

export default Chat;