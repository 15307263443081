import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid, Box, Checkbox, Typography } from '@material-ui/core';
import translate from '../i18n/Translator';
import DialogPopup from '../components/DialogPopup';
import { listPlugins } from '../api/PluginAPI';
import { getWorkflow, updatePlugins } from '../api/WorkflowAPI';
import { Plugin, PluginStep } from '../model/Plugin';
import { AppContext } from '../context/AppContext';
import { Workflow, UpdateWorkflowPluginsRequest } from '../model/Workflow';
import Gridable from '../components/Gridable';
import { ErrorSnackbar } from '../components/Snackbars';

interface WorkflowPluginPopupProps {
    workflow: Workflow;
    step: PluginStep;
    onSuccess(workflow: Workflow): any;
    onClose(): any;
}

export default function WorkflowPluginPopup(props: WorkflowPluginPopupProps) {
    const { session } = useContext(AppContext);
    const [status, setStatus] = useState<string>('loading');
    const [plugins, setPlugins] = useState<Plugin[]>([]);
    const [request, setRequest] = useState<UpdateWorkflowPluginsRequest>({
        step: props.step,
        plugins: [],
    } as UpdateWorkflowPluginsRequest);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        setStatus('loading');
        Promise.all([
            getWorkflow(session!.tenant!.id, props.workflow.id),
            listPlugins(session!.tenant!.id, 0, 0, { search: '' }),
        ]).then(responses => {
            const workflow = responses[0];
            const pluginsResponse = responses[1];

            const selectedPlugins = workflow.plugins ? (workflow.plugins[props.step] || []) : [];
            const availablePlugins = pluginsResponse.items.filter(p => p.step === props.step);

            setRequest(request => {
                return { ...request, plugins: selectedPlugins };
            });
            setPlugins(availablePlugins);
            setStatus('loaded');
        }).catch(error => {
            setStatus(error.message);
        });
    }, [session, props.step, props.workflow]);

    const update = () => {
        setDisabled(true);
        updatePlugins(session!.tenant!.id, props.workflow.id, request).then(response => {
            props.onSuccess(response);
        }).catch(error => {
            setError(error.message);
            setDisabled(false);
        });
    };

    const onChangeAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRequest(r => {
            return { ...r, plugins: checked ? plugins.map(p => p.id) : [] };
        });
    };

    const onChange = (plugin: Plugin) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let ids = request.plugins;
        if (checked) {
            ids.push(plugin.id);
        } else {
            ids = request.plugins.filter(id => id !== plugin.id);
        }

        setRequest(r => {
            return { ...r, plugins: ids };
        });
    }

    return (
        <DialogPopup open
            title={translate(`plugins.${props.step}`)}
            variant="outlined"
            onClose={props.onClose}
            button={(
                <Button onClick={update} variant="contained" color="primary" disabled={disabled}>
                    {translate('buttons.update')}
                </Button>
            )}
            maxWidth="md"
            disable={disabled}
            disableBackdropClick={disabled}
            disableEscapeKeyDown={disabled}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        {translate('plugins.popup_text', { "workflow": props.workflow.name })}
                    </Typography>
                </Grid>
            </Grid>
            <Box mx={-3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Gridable items={plugins}
                            loading={status === 'loading'}
                            error={status !== 'loading' && status !== 'loaded' ? status : undefined}
                            columns={[
                                {
                                    title: (
                                        <Checkbox checked={request.plugins && plugins.length === request.plugins.length}
                                            indeterminate={request.plugins && plugins.length > 0 && request.plugins.length > 0 && plugins.length !== request.plugins.length}
                                            disabled={plugins.length === 0} onChange={onChangeAll} />
                                    ),
                                    converter: (plugin, index) => (
                                        <Checkbox checked={request.plugins && request.plugins.indexOf(plugin.id) >= 0} onChange={onChange(plugin)} />
                                    ),
                                    fullWidth: true,
                                    xs: 'auto',
                                },
                                {
                                    title: translate('plugins.single'),
                                    converter: (plugin) => plugin.name,
                                    fullWidth: true,
                                    xs: true,
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    )
}
