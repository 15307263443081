import React from "react";
import LaunchIcon from '@material-ui/icons/Launch';
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import { ProviderExpedientFieldHistory, ProviderExpedientField } from "../model/ProviderExpedient";
import { Icon } from "@material-ui/core";
import { DownloadFileButton } from "../expedients/ExpedientProviderFields";

interface ProviderExpedientFieldHistoryPopupProps {
    tenantId: string;
    providerId: string;
    history: ProviderExpedientFieldHistory[];
    fieldName: string;
    onClose(): any;
}

export default function ProviderExpedientFieldHistoryPopup(props: ProviderExpedientFieldHistoryPopupProps) {

    const buildData = (current: ProviderExpedientField) => {
        if(current.configuration.type === "FILE" && !!current.value ) {
            return <DownloadFileButton
                tenantId={props.tenantId}
                providerId={props.providerId}
                fieldId={current.configuration.id}
                value={current.value!}
                isHistory={true} />
        }
        if(current.configuration.type === "FILE" && !current.value ) {
            return <Icon color="disabled"> <LaunchIcon /></Icon>;
        }
        
        if(current.configuration.type === "DATE" && !!current.value){
            let temp = current.value.split("-");
            let tempValue = temp[2]+"-"+temp[0]+"-"+(Number(temp[1])+1);
            return <DateFormat date={new Date(tempValue)} format="L" />
        }
                
        if(!!current.value ) {
            return <>{current.value}</>
        } 
        return <>{"-"}</>
    }

    return (
        <DialogPopup
            open={true}
            title={translate("providers.expedient_history.title_field", {"name": props.fieldName}) as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            disable={false}
            closeText={translate("buttons.close") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth={"md"}
        >
            <Gridable
                items={props.history}
                loading={false}
                empty={translate("cfdis.history.empty") as string}
                columns={[
                    {
                        title: translate("providers.expedient_history.started_at") as string,
                        converter: (history) => <DateFormat date={history.started_at} format="DD-MM-YYYY h:mm a" />,
                        xs: true
                    },
                    {
                        title: translate("providers.expedient_history.ended_at") as string,
                        converter: (history) => <DateFormat date={history.ended_at} format="DD-MM-YYYY h:mm a" />,
                        xs: true
                    },
                    {
                        title: translate("providers.expedient_history.documento") as string,
                        converter: (history) => buildData(history.field),
                        xs: true
                    }
                ]} />
        </DialogPopup>
    );
}