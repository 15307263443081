import React from "react";
import { Grid } from "@material-ui/core";
import { Cfdi } from "../model/Cfdi";
import ValidIcon from "@material-ui/icons/BeenhereTwoTone";
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import translate from "../i18n/Translator";


interface CfdiStatusProviderProps {
    cfdi: Cfdi;
    lenght?: number;
}

const iconValue = (cfdi : Cfdi) => {
    if(cfdi.provider_fiscal_status_response && cfdi.provider_fiscal_status_response.provider_is_clean){
        return <Grid title={translate("providers.message_correct_validations") as string}><ValidIcon style={{ "color": "#82CA9D" }} /></Grid>;
    }
    if(!cfdi.provider_fiscal_status_response){
        return <Grid title={translate("providers.message_bad_validations", {"validations": listValidations([], []) }) as string}><WarningIcon color="secondary"/></Grid>;
    }
    return <Grid title={translate("providers.message_bad_validations", {"validations": listValidations(cfdi.provider_fiscal_status_response?.bad_validations_efos! ?? []
    , cfdi.provider_fiscal_status_response?.bad_validations_cff! || []) }) as string}><WarningIcon color="secondary"/></Grid>;
}

const listValidations = (efos: string[], cff: string[]) => {
    let efosString=[""];
    let cffString=[""];
    if(efos){
        efosString = efos.map((status) => (translate(`efo.status.${status}`) as string));
    }
    if(cff){
        cffString = cff.map((status) => (translate(`69cff.status.${status}`) as string));
    }
    return efosString[0] !== "" && cffString[0] !== "" ? efosString.concat(cffString) : (cffString[0] !== "" ? cffString : efosString);
}

export default function CfdiProvideStatusIcon(props: CfdiStatusProviderProps) {
    return (
        <Grid item xs={12} >
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                    <Grid item xs="auto">
                        {iconValue(props.cfdi)}
                    </Grid>
            </Grid>
        </Grid>);
}
