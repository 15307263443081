import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { DeleteTenantCfdisRequest, Tenant } from "../model/Tenant";
import { Entity } from "../model/Provider";
import Progress from "../components/Progress";
import ValidatedInput from "../components/ValidatedInput";
import { WarningSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { SearchByRfc } from "../model/Tenant";
import { getTenantsByRfc } from "../api/TenantAPI";
import { RfcValidator } from "../components/Validators";
import { listProvidersByRfc, deletePayableData } from "../api/SupportAPI";
import DateRange from "../components/DateRange";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";

interface DeleteCfdisPopupProps {
    onClose(): any;
    onSuccess(success: string): any;
}

export default function DeleteCfdisPopup(props: DeleteCfdisPopupProps) {
    const date = new Date();
    const [request, setRequest] = useState<DeleteTenantCfdisRequest>({
        start_date: new Date(date.getFullYear(), date.getMonth() , date.getDate()),
        end_date: new Date(date.getFullYear(), date.getMonth() , date.getDate(), 23, 59, 59, 999)
    } as DeleteTenantCfdisRequest);
    const [status, setStatus] = useState<string>("loading");
    const [tenantLabels, setTenantLabels] = useState<string[]>([]);
    const [tenantIds, setTenantIds] = useState<string[]>([]);
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [providerLabels, setProviderLabels] = useState<string[]>([]);
    const [providerIds, setProviderIds] = useState<string[]>([]);
    const [providers, setProviders] = useState<Entity[]>([]);
    const [providerRfc, setProviderRfc] = useState<string>("");
    const [tenantRfc, setTenantRfc] = useState<string>("");
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const rfcValidator = new RfcValidator();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [selectedTenantId, setSelectedTenantId] = useState<string>("");
    
    useEffect(() => {
        setStatus("loaded");
    }, [props]);

    const onAccept = () => {
        if ( !request.provider || request.provider === "" || request.provider === "---") {
            setWarning(translate("tenants.delete_cfdis.warning_provider_empty") as string);
            return;
        }

        if ( !request.tenant || request.tenant === "" || request.tenant === "---") {
            setWarning(translate("tenants.delete_cfdis.warning_tenant_empty") as string);
            return;
        }
        setOpenConfirmDialog(true);
    };

    const onConfirm = () => {
        setOpenConfirmDialog(false);
        setOpenBackdrop(true);
        deletePayableData( request ).then((_) => {
            props.onSuccess(translate("tenants.delete_cfdis.success") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    };

    const onCloseConfirm = () => {
        setOpenConfirmDialog(false);
    };

    const hasChanged = (name: string, value: string) => {
        if(name === "tenant"){
            setProviderRfc("");
            setProviderLabels([]);
            setProviders([]);
            setSelectedTenantId(value);
            request.provider = "";
        }
        setRequest({ ...request, [name]: value });
    };

    const hasChangedRfcProvider = (name: string, value: string) => {
        setProviderRfc(value);
    };

    const hasChangedRfcTenant = (name: string, value: string) => {
        setTenantRfc(value);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setError(undefined);
    };

    const onChangeDateRange = (startDate: Date, endDate: Date) => {
        setRequest({ ...request, start_date: startDate,  end_date: endDate});
    };

    const onSearchProviders = () => {
        listProvidersByRfc({ rfc: providerRfc} as SearchByRfc, selectedTenantId).then((response) => {
            if (response.total === 0) {
                setWarning(translate("tenants.delete_cfdis.warning_rfc_provider") as string);
            }
            setProviderLabels(response.items.map(provider => provider.name));
            setProviderIds(response.items.map(provider => provider.id));
            setProviders(response.items);
        }).catch((error) => {
            setError(translate("validations.rfc") as string);
            setProviderLabels([]);
            setProviders([]);
        });
    };

    const onSearchTenants = () => {
        getTenantsByRfc({ rfc: tenantRfc} as SearchByRfc).then((response) => {
            if (response.total === 0) {
                setWarning(translate("tenants.delete_cfdis.warning_rfc_tenant") as string);
            }
            setTenantLabels(response.items.map(tenant => tenant.name));
            setTenantIds(response.items.map(tenant => tenant.id));
            setTenants(response.items);
        }).catch((error) => {
            setError(translate("validations.rfc") as string);
            setTenantLabels([]);
            setTenants([]);
        });
    };

    return (
        <DialogPopup open
            title={translate("tenants.delete_cfdis.delete_cfdi") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onAccept} variant="outlined" color="primary" >
                    {translate("buttons.accept") as string}
                </Button>
            }>
            {status !== "loading" && status !== "loaded" && (
                status
            )}
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DateRange startDate={request.start_date} endDate={request.end_date} onChange={onChangeDateRange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ValidatedInput
                            type="text" id="rfc_tenant" name="rfc_tenant"
                            label={translate("tenants.delete_cfdis.rfc_tenant") as string}
                            value={tenantRfc}
                            margin={"dense"}
                            onValueChanged={hasChangedRfcTenant}
                            validator={rfcValidator}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box pl={1} pt={1}>
                            <Button onClick={onSearchTenants} variant="outlined" color="primary" disabled={tenantRfc === undefined || tenantRfc === ""} >
                                {translate("buttons.search") as string}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="number" id="tenant" name="tenant"
                            label={translate("tenants.delete_cfdis.name_tenant") as string}
                            value={request.tenant}
                            required
                            margin={"dense"}
                            optionLabels={tenantLabels}
                            options={tenantIds}
                            onValueChanged={hasChanged}
                            disabled={tenants.length === 0}  />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ValidatedInput
                            type="text" id="rfc_provider" name="rfc_provider"
                            label={translate("tenants.delete_cfdis.rfc_provider") as string}
                            value={providerRfc}
                            margin={"dense"}
                            onValueChanged={hasChangedRfcProvider}
                            validator={rfcValidator}
                            disabled={selectedTenantId === ""} 
                         />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box pl={1} pt={1}>
                            <Button onClick={onSearchProviders} variant="outlined" color="primary" disabled={providerRfc.length === 0 || providerRfc === ""} >
                                {translate("buttons.search") as string}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="number" id="provider" name="provider"
                            label={translate("tenants.delete_cfdis.name_provider") as string}
                            value={request.provider}
                            required
                            margin={"dense"}
                            optionLabels={providerLabels}
                            options={providerIds}
                            onValueChanged={hasChanged}
                            disabled={providers.length === 0} />
                    </Grid>
                    
                </Grid>
            )}
            {openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onConfirm}
                    onClose={onCloseConfirm}
                    title={translate("tenants.delete_cfdis.title") as string}
                    message={translate("tenants.delete_cfdis.confirm_delete_dialog.message",
                        {"provider": providers.find(provider => provider.id === request.provider)?.name, 
                        "tenant": tenants.find(tenant => tenant.id === request.tenant)?.name}) as string} 
                    secondary={translate("tenants.delete_cfdis.confirm_delete_dialog.secondary") as string} 
                    button={translate("yes") as string} />
            )}
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </DialogPopup>
    );

}