import { callAPI, HTTPMethod } from "./API";
import { CostCenters, CostCenter, CostCentersQueryParams, CostCenterRequest } from "../model/CostCenter";

const basePath = (tenantId: string) => `/tenants/${tenantId}/cost-centers`;

export async function listCostCenters(tenantId: string, page: number, offset: number, params: CostCentersQueryParams): Promise<CostCenters> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createCostCenter(tenantId: string, request: CostCenterRequest): Promise<CostCenter> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getCostCenter(tenantId: string, id: string): Promise<CostCenter> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateCostCenter(tenantId: string, id: string, request: CostCenterRequest): Promise<CostCenter> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCostCenter(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
