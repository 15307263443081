import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { PurchaseOrders, PurchaseOrder, PurchaseOrdersQueryParams } from "../model/PurchaseOrder";
import { DocumentLines } from "../model/Document";
import { Cfdi, CfdiRequest } from "../model/Cfdi";
import { ExportResponse } from "../model/Connector";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/purchase-orders`;

export async function listPurchaseOrders(tenantId: string, providerId: string, page: number, offset: number, params: PurchaseOrdersQueryParams): Promise<PurchaseOrders> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            currency: params.currency || "",
            acceptanceStatus: params.acceptance_status || "",
            companyId: params.company_id || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function getPurchaseOrder(tenantId: string, providerId: string, id: string, pageLine: number, offsetLine: number): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: pageLine.toString(),
            offset: offsetLine.toString()
        }
    });
}

export async function createCfdi(tenantId: string, providerId: string, id: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("workflowId", request.workflow_id ? request.workflow_id : "");
    data.append("companyId", request.company_id ? request.company_id : "");
    data.append("validationOC", request.validation_oc+"");
    
    return await callAPI(`${basePath(tenantId, providerId)}/${id}/cfdi`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function accept(tenantId: string, providerId: string, id: string): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}/accept`, {
        method: HTTPMethod.PUT,
    });
}

export async function refuse(tenantId: string, providerId: string, id: string): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}/refuse`, {
        method: HTTPMethod.PUT,
    });
}

export async function getLines(tenantId: string, providerId: string, cfdiId: string): Promise<DocumentLines> {
    return await callAPI(`${basePath(tenantId, providerId)}/lines`, {
        method: HTTPMethod.GET,
        query: {
            cfdiId: cfdiId
        }
    });
}

export async function getPurchaseOrderPdfUrl(tenantId: string, providerId: string, purchaseOrderId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId, providerId)}/${purchaseOrderId}/file`);
}

export async function exportAllFiles(tenantId: string, providerId: string, params: PurchaseOrdersQueryParams): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId, providerId)}/export-all-files`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            acceptanceStatus: params.acceptance_status || "",
            pageSize: "0",
            offset: "0"
        }
    });
}