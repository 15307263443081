import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SatTaxIcon from "@material-ui/icons/AccountBalance";
import AddIcon from "@material-ui/icons/Add";

import { listSatContributions, deleteSatContribution, deleteSatContributionComplementary } from "../api/SatContributionAPI";
import { listSatContributions as listProviderSatContributions, deleteSatContribution as  deleteProviderSatContribution,
    deleteSatContributionComplementary as  deleteProviderSatContributionComplementary } from "../api/ProviderSatContributionAPI";
import { AppContext } from "../context/AppContext";
import { SatContribution, SatContributions, SatContributionsQueryParams } from "../model/SatContribution";
import { ContributionStatus, STATUSES } from "../model/ContributionStatus";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import SatContributionMenu from "./SatContributionMenu";
import moment from "moment";
import { getSatBankVoucher, getComplementarySatBankVoucher, getSatPayment, getSatComplementaryPayment } from "../contributions/ContributionsTable";
import { getSatContributionPeriod } from "./SatContributionUtil";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import ConfirmationPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";

export default function SatContributionsList() {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;
    const [success, setSuccess] = useState<string>();

    const history = useHistory();
    const statusLabels = STATUSES.map(s => translate(`contributions.status.${s}`) as string);

    const query = new URLSearchParams(useLocation().search);
    const monthS = query.get("month");
    const yearS = query.get("year");
    const statusS = query.get("status");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<SatContributions>();
    const [workingParams, setWorkingParams] = useState<SatContributionsQueryParams>({} as SatContributionsQueryParams);

    const [satContribution, setSatContribution] = useState<SatContribution>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [monthKeys, setMonthKeys] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [openConfirmDeleteComplementary, setOpenConfirmDeleteComplementary] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const promiseList = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            month: !monthS || isNaN(+monthS) ? undefined : +monthS,
            year: !yearS || isNaN(+yearS) ? undefined : +yearS,
            status: statusS ? statusS as ContributionStatus : undefined
        } as SatContributionsQueryParams;
        setWorkingParams(params);

        if (providerId) {
            return listProviderSatContributions(tenantId, providerId, pageSize, offset, params);
        }
        return listSatContributions(tenantId, pageSize, offset, params);
    };

    const promiseDelete = (contributionId: string) => {
        if (providerId) {
            return deleteProviderSatContribution(tenantId, providerId, contributionId);
        }
        return deleteSatContribution(tenantId, contributionId);
    };

    const promiseDeleteComplementary = (contributionId: string) => {
        if (providerId) {
            return deleteProviderSatContributionComplementary(tenantId, providerId, contributionId);
        }
        return deleteSatContributionComplementary(tenantId, contributionId);
    };

    const load = () => {
        setStatus("loading");
        promiseList().then((response) => {
            response.items.forEach(satContribution => {
                satContribution.date = new Date(satContribution.year, satContribution.month - 1, 1);
            });
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, page, pageSize, monthS, yearS, statusS]);

    useEffect(() => {
        const monthKeys = [];
        const monthLabels = [];
        const years = [];
        const today = new Date();
        for (let i = 0; i < 12; i++) {
            monthKeys.push(`${i + 1}`);
            monthLabels.push(moment({
                year: today.getFullYear(),
                month: i,
                day: 1
            }).format("MMMM"));
        }

        for (let i = 2021; i <= today.getFullYear(); i++) {
            years.push(`${i}`);
        }

        setMonthKeys(monthKeys);
        setMonthLabels(monthLabels);
        setYears(years);
    }, []);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (workingParams.year) {
            query.set("year", `${workingParams.year}`);
        } else {
            query.delete("year");
        }
        if (workingParams.month) {
            query.set("month", `${workingParams.month}`);
        } else {
            query.delete("month");
        }
        if (workingParams.status) {
            query.set("status", workingParams.status);
        } else {
            query.delete("status");
        }

        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        if (!value || value === "---") {
            setWorkingParams({ ...workingParams, [name]: undefined });
        } else if (name === "status") {
            setWorkingParams({ ...workingParams, status: value as ContributionStatus });
        } else {
            setWorkingParams({ ...workingParams, [name]: +value });
        }
    };

    const onClickedOptions = (satContribution: SatContribution) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSatContribution(satContribution);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setSatContribution(undefined);
    };

    const onCancelDelete = () => {
        setOpenConfirmDelete(false);
        setSatContribution(undefined);
    };

    const onConfirmDelete = () => {
        setOpenConfirmDelete(false);
        setAnchorEl(null);
        if(!satContribution) return;
        setLoading(true);
        promiseDelete(satContribution.id).then((response) => {
            setSuccess(translate("sat_contributions.success_delete") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSatContribution(undefined);
            setLoading(false);
        });
    };

    const onOpenDelete = () => {
        setAnchorEl(null);
        if(!satContribution) return;

        setOpenConfirmDelete(true);
    };

    const onOpenDeleteComplementary = () => {
        setAnchorEl(null);
        if(!satContribution) return;

        setOpenConfirmDeleteComplementary(true);
    };

    const onCancelDeleteComplementary = () => {
        setOpenConfirmDeleteComplementary(false);
        setSatContribution(undefined);
    };

    const onConfirmDeleteComplementary = () => {
        setOpenConfirmDeleteComplementary(false);
        if(!satContribution) return;

        setStatus("loading");
        promiseDeleteComplementary(satContribution.id).then((response) => {
            setSatContribution(undefined);
            setSuccess(translate("sat_contributions.success_delete_complementary") as string);
            load();
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setSatContribution(undefined);
        });
    };

    const onClickRow = (satContribution: SatContribution) => {
        if(context.isGrantedAny(["SatContributionsUpdate", "ProviderSatContributionsUpdate"]) 
            && satContribution.status !== "COMPLETED" 
            && satContribution.status !== "TO_VALIDATE"
            && satContribution.status !== "REJECTED" ){
            history.push(`/contributions/sat/${satContribution.id}/edit`);
        }
    };

    return (
        <Pagination title={translate("sat_contributions.title_long")} icon={<SatTaxIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["SatContributionsCreate", "ProviderSatContributionsCreate"]) ?
                (<Link to="/contributions/sat/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="year" name="year" label={translate("contributions.year") as string}
                            options={years}
                            margin="dense" disabled={false}
                            value={workingParams.year?.toString()} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="month" name="month" label={translate("contributions.month") as string}
                            options={monthKeys} optionLabels={monthLabels}
                            margin="dense" disabled={false}
                            value={workingParams.month?.toString()} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs sm>
                        <ValidatedInput type="text" id="status" name="status" label={translate("contributions.status.title") as string}
                            options={STATUSES} optionLabels={statusLabels}
                            margin="dense" disabled={false}
                            value={workingParams.status} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("sat_contributions.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("sat_contributions.period") as string,
                        converter: (satContribution) => getSatContributionPeriod(satContribution, false),
                        fullWidth: true,
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                    },
                    {
                        title: (
                            <Typography component="h6" variant="body2" align="center">
                                <strong>{translate("sat_contributions.payment_short")}</strong>
                            </Typography>
                        ),
                        converter: (satContribution) => getSatPayment(satContribution.status_by_documents, false),
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <Typography component="h6" variant="body2" align="center">
                                <strong>{translate("sat_contributions.bank_voucher")}</strong>
                            </Typography>
                        ),
                        converter: (satContribution) => getSatBankVoucher(satContribution.status_by_documents, false),
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <Typography component="h6" variant="body2" align="center">
                                <strong>{translate("sat_contributions.complementary_payment")}</strong>
                            </Typography>
                        ),
                        converter: (satContribution) => getSatComplementaryPayment(satContribution.status_by_documents),
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: (
                            <Typography component="h6" variant="body2" align="center">
                                <strong>{translate("sat_contributions.complementary_bank_voucher")}</strong>
                            </Typography>
                        ),
                        converter: (satContribution) => getComplementarySatBankVoucher(satContribution.status_by_documents),
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (satContribution) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(satContribution)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {satContribution && anchorEl &&
                <SatContributionMenu
                    satContribution={satContribution}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onDelete={onOpenDelete}
                    onDeleteComplementary={onOpenDeleteComplementary} />
            }
            {satContribution && openConfirmDelete &&
                <ConfirmationPopup
                    title={translate("sat_contributions.confirm_delete.title") as string}
                    message={translate("sat_contributions.confirm_delete.message") as string}
                    secondary={translate("sat_contributions.confirm_delete.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDelete}
                    doAction={onConfirmDelete}
                />
            }
            {satContribution && openConfirmDeleteComplementary &&
                <ConfirmationPopup
                    title={translate("sat_contributions.confirm_delete_complementary.title") as string}
                    message={translate("sat_contributions.confirm_delete_complementary.message") as string}
                    secondary={translate("sat_contributions.confirm_delete_complementary.secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDeleteComplementary}
                    doAction={onConfirmDeleteComplementary}
                />
            }
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={loading} message={translate("cfdis.processing") as string} />
        </Pagination>
    );
}