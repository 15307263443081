import React from "react";
import { Grid, Card, ListSubheader, Box, Typography } from "@material-ui/core";
import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import NumberFormat from "react-number-format";
import { Requisition } from "../model/Requisition";
import { PartialityRecord } from "../model/PurchaseOrder";
import { PaymentPercentaje } from "../model/Requisition";
import CfdiDownloadPdf from "../cfdis/menus/CfdiDownloadPdf";
import CfdiDownloadXml from "../cfdis/menus/CfdiDownloadXml";
import { GridableColumn } from '../components/Gridable';


interface QuotationCardProps {
    requisition: Requisition;
    showCfdis?: boolean;
    partiallityRecords?: PartialityRecord[];
}

export default function PaymentsPercentageCard(props: QuotationCardProps) {

    let columns = [];
    if ("INVOICE_BY_PARTIALLITY" === props.requisition?.partial_billing_type) {
        columns = [
            {
                title: translate("requisitions.form.quotation.payment_condition.number") as string,
                converter: (installment) => installment.payment_number,
                xs: 1
            },
            {
                title: translate("requisitions.form.quotation.payment_condition.percentage") as string,
                converter: (installment) => (
                    <NumberFormat value={installment.percentage} suffix="%" displayType="text" />
                ),
                xs: 1
            },
            {
                title: translate("requisitions.form.quotation.payment_condition.amount") as string,
                converter: (installment) => (
                    <NumberFormat value={Math.round(props.requisition.quotation.amount * installment.percentage / 100)} suffix={` ${props.requisition.currency}`} prefix="$" decimalScale={2} thousandSeparator=","  displayType="text" />
                ),
                xs: 2
            },
            {
                title: translate("requisitions.form.quotation.payment_condition.condition") as string,
                converter: (installment) => installment.payment_conditions,
                xs: 3
            },
            {
                title: translate("requisitions.form.quotation.payable_document") as string,
                converter: (installment) => (
                    <Grid container direction="row">
                        {findRecord(installment, props.partiallityRecords) && <CfdiDownloadXml cfdi={findRecord(installment, props.partiallityRecords)!} />}
                        {findRecord(installment, props.partiallityRecords) && <CfdiDownloadPdf cfdi={findRecord(installment, props.partiallityRecords)!} />}
                    </Grid>
                ),
                xs: 3
            },
            {
                title: translate("cfdis.columns.status") as string,
                converter: (installment) => <StatusPayableDocument item={installment} partiallityRecords={props.partiallityRecords} />,
                xs: 2
            },
        ] as GridableColumn<PaymentPercentaje>[];
    } else {
        columns = [
            {
                title: translate("requisitions.form.quotation.payment_condition.partiality") as string,
                converter: (installment) => installment.payment_number,
                xs: 1
            },
            {
                title: translate("requisitions.form.quotation.payment_condition.percentage") as string,
                converter: (installment) => (
                    <NumberFormat value={installment.percentage} suffix="%" displayType="text" />
                ),
                xs: 1
            },
            {
                title: translate("requisitions.form.quotation.payment_condition.amount") as string,
                converter: (installment) => (
                    <NumberFormat value={Math.round(props.requisition.quotation.amount * installment.percentage / 100)} suffix={` ${props.requisition.currency}`} prefix="$" decimalScale={2} thousandSeparator=","  displayType="text" />
                ),
                xs: 2
            },
            {
                title: translate("requisitions.form.quotation.payment_condition.condition") as string,
                converter: (installment) => installment.payment_conditions,
                xs: 8
            }] as GridableColumn<PaymentPercentaje>[];
    }

    const subHeader = () => {
        return translate(`requisitions.overview.quotation.${"INVOICE_BY_PARTIALLITY" === props.requisition?.partial_billing_type ? "payments_percentage" : "payment_conditions_one_invoice"}`);
    }


    const emptyPayments = () => {
        return translate("requisitions.form.quotation.payment_condition.empty");
    }

    if ("INVOICE_BY_PARTIALLITY" === props.requisition?.partial_billing_type) {
        return (
            <Card variant="outlined" >
                <ListSubheader>{subHeader()}</ListSubheader>
                <Box px={2} pb={2}>
                    <Grid container alignItems="flex-start" justify="space-between">
                        <Gridable
                            items={props.requisition.quotation.payments_percentage}
                            loading={false}
                            empty={emptyPayments() as string}
                            columns={columns} />
                    </Grid>
                </Box>
            </Card>
        );
    }

    return (
        <div>
            <ListSubheader>{subHeader()}</ListSubheader>
            <Box pb={2}>
                <Grid container alignItems="flex-start" justify="space-between">
                    <Gridable
                        items={props.requisition.quotation.payments_percentage}
                        loading={false}
                        empty={emptyPayments() as string}
                        columns={columns} />
                </Grid>
            </Box>
        </div>
    );



}

interface StatusPayableDocumentProps {
    item: PaymentPercentaje;
    partiallityRecords?: PartialityRecord[];
}

function StatusPayableDocument(props: StatusPayableDocumentProps) {

    const getStatus = (item: PaymentPercentaje, partiallityRecords?: PartialityRecord[]) => {
        let cfdi = findRecord(item, partiallityRecords);
        if (cfdi) {
            if (cfdi.metadata.custom_status) {
                return cfdi.metadata.custom_status;
            }
            return translate(`cfdis.history.status.${cfdi.metadata.status}`) as string;
        }
        return "";
    };

    return (
        <Typography variant="inherit" align="center">
            {getStatus(props.item, props.partiallityRecords)}
        </Typography>
    );
}

const findRecord = (item: PaymentPercentaje, partiallityRecords?: PartialityRecord[]) => {
    if (partiallityRecords && partiallityRecords.length > 0) {
        let recordFound;
        recordFound = partiallityRecords.find(record => item.payment_number === record.partiallity_number);
        if (recordFound && recordFound.cfdi_related) {
            return recordFound.cfdi_related;
        }
    }
    return undefined;
};
