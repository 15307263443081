import React from "react";
import { ListItemIcon, ListItemSecondaryAction, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import FoundIcon from "@material-ui/icons/SpellcheckTwoTone"; // Encontrados
import CreatedIcon from "@material-ui/icons/PlaylistAddTwoTone"; // Creados
import CancelIcon from "@material-ui/icons/CancelTwoTone"; // Failed
import DuplicatedIcon from "@material-ui/icons/Brightness1TwoTone";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ImportResponse } from "../model/ExportableObject";

interface ImportResumePopupProps {
    summary: ImportResponse;
    onClose(): any;
}

export default function ImportResumePopup(props: ImportResumePopupProps) {
    let values = {
        "found": props.summary.found,
        "created": props.summary.created,
        "duplicated": props.summary.duplicated,
        "fail": props.summary.failed
    } as any;

    return (
        <DialogPopup title={translate("reports.export_result")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            <List component="nav">
                {Object.keys(values).map((status: string) => (
                    <StatusListItem key={status} status={status} value={values[status] as number} />
                ))}
            </List>
        </DialogPopup>
    );
}

interface StatusListItemProps {
    status: string;
    value: number;
    fontSize?: "small" | "large";
}

function StatusListItem(props: StatusListItemProps) {
    var icon;
    switch (props.status) {
        case "found":
            icon = <FoundIcon fontSize={props.fontSize || "default"} />
            break;
        case "created":
            icon = <CreatedIcon fontSize={props.fontSize || "default"} />
            break;
        case "duplicated":
            icon = <DuplicatedIcon fontSize={props.fontSize || "default"} />
            break;
        default:
            icon = <CancelIcon fontSize={props.fontSize || "default"} />
            break;
    }

    return (
        <ListItem>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={translate(`providers.import.${props.status}`)} />
            <ListItemSecondaryAction>
                <Typography variant="h6">
                    <NumberFormat value={props.value} displayType="text" />
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
}