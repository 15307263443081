import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { FormControl, Grid, Select, Box, Tooltip, Checkbox } from "@material-ui/core";
import { Cfdi, CreditNote, Statuses, TypeCfdi, TypeCfdiWithoutCustomAgent, validateCfdiToBeSettled, validateCfdiSettled, StatusesCfdi } from "../model/Cfdi";
import ListCfdisToAuthorize from "./ListCfdisToAuthorize";
import ListCfdisToVerify from "./ListCfdisToVerify";
import ListCfdisPendingPayment from "./ListCfdisPendingPayment";
import ListCfdisToSend from "./ListCfdisToSend";
import ListCfdisInProcess from "./ListCfdisInProcess";
import ListCfdisArchive from "./ListCfdisArchive";
import ListCfdisPostpone from "./ListCfdisPostpone";
import ListCfdisDelivered from "./ListCfdisDelivered";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LocalAtmIcon from '@material-ui/icons/LocalAtmTwoTone';
import { DataTypeProvider, DataTypeProviderProps, TableSelection } from '@devexpress/dx-react-grid';
import CfdiProvideStatusIcon from "../cfdi/CfdiStatusProviderComponent";
import { Column } from "@devexpress/dx-react-grid";
import Progress from "../components/Progress";
import { NoFilterEditor } from "../components/GridDx";
import { isRoleOrParent } from "../model/Role";
import { getTenantCurrencies, get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import { listCompanies } from "../api/CompanyAPI";
import { CompaniesQueryParams, Company } from "../model/Company";
import ReactHtmlParser from 'html-react-parser';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { getTranslateStatus } from "../cfdi/CfdiTranslateStatusUtils";

export default function CfdisView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");

    const isProviderUser = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const [view, setView] = useState<string>("");
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [showDueDate, setShowDueDate] = useState<boolean>(false);
    const [hideSemaphore, setHideSemaphore] = useState<boolean>(false);

    const [companies, setCompanies] = useState<Company[]>([]);
    const [statusesCfdi, setStatusesCfdi] = useState<StatusesCfdi>();

    const load = () => {
        let href = window.location.pathname;

        Promise.all([
            getTenantCurrencies(context.session!.tenant!.id),
            getTenantConfigurations(context.session!.tenant!.id),
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams),
        ]).then(responses => {
            if (responses[0].tenant_currency_ids) {
                setCurrencies(responses[0].tenant_currency_ids);
            }
            const configuration = responses[1].configurations?.find(cfg => cfg.property_name === "show_due_date");
            const configurationSmaphore = responses[1].configurations?.find(cfg => cfg.property_name === "hide_visual_status");

            setShowDueDate(configuration !== undefined && configuration.active && configuration.value === "true");
            setHideSemaphore(configurationSmaphore !== undefined && configurationSmaphore.active && configurationSmaphore.value === "true");
            if (responses[2].total > 0) {
                setCompanies(responses[2].items);
            }
        }).catch(error => {
            setStatus(error);
        }).finally(() => {
            setStatus("loaded");
        });

        if (href.includes('send')) {
            setView('to-send');
        } else if (href.includes('sent')) {
            setView('sent');
        } else if (href.includes('archive')) {
            setView('archive');
        } else if (href.includes('authorize')) {
            setView('authorize');
        } else if (href.includes('verify')) {
            setView('verify');
        } else if (href.includes('pending-payment-owner') || href.includes('pending-payment-by-receptor')) {
            setView('pending');
        } else if (href.includes('postpone')) {
            setView('postpone');
        } else if (href.includes('delivered')) {
            setView('delivered_erp');
        }

        const items = localStorage.getItem("cfdi_status");
        if (items) {
            setStatusesCfdi(JSON.parse(items));
        } else {
            setStatusesCfdi({ items: [], total: 0 } as StatusesCfdi);
        }
    }

    useEffect(load, [context.session]);

    const typesCfdi = (context.session && context.session.provider && context.session.provider.type !== "CUSTOMS_AGENT" ?
        TypeCfdiWithoutCustomAgent.filter(type => view !== "archive" ? type.value !== "CREDIT_NOTE" && type.value !== "PAYMENT_CFDI" : true) :
        TypeCfdi.filter(type => view !== "archive" ? type.value !== "CREDIT_NOTE" && type.value !== "PAYMENT_CFDI" : true))
        .filter(type => view !== "pending" ? type.value !== "REFUND" : true).filter(type => context.isGranted("AdvancePaymentsRead") ? type.value !== "ADVANCE_PAYMENT" : true);

    const getStatus = (cfdi: Cfdi) => {
        return getTranslateStatus(cfdi, statusesCfdi);
    };

    const getAuthorizer = (cfdi: Cfdi) => {
        switch (cfdi.metadata.cfdi_status_group) {
            case "SENDING":
                return "---";
            case "IN_PROCESS":
                return cfdi.metadata.receptors_names;
            default:
                if (cfdi.metadata.type === "PAYMENT_CFDI") {
                    return "---";
                }
                return cfdi.metadata.authorizer_name;
        }
    }

    const getCompanyName = (cfdi: Cfdi) => {
        let company = companies.find(item => cfdi.metadata.company_id && item.id === cfdi.metadata.company_id);
        if (company) {
            return company.name;
        }
        return "---";
    }

    const getAmountCreditNotes = (cfdi: Cfdi) => {
        let total = 0;
        if (cfdi.metadata.payment_info !== undefined && cfdi.metadata.payment_info.credit_notes !== undefined) {
            cfdi.metadata.payment_info.credit_notes.forEach((note: CreditNote) => {
                total += note.amount;
            });
        }
        return total;
    }

    const [columnsFormat] = useState([
        { columnName: 'icons', wordWrapEnabled: true, width: 45 },
        { columnName: 'identifier', wordWrapEnabled: true, width: 100 },
        { columnName: 'company_name', wordWrapEnabled: true, width: 150 },
        { columnName: 'issuer', wordWrapEnabled: !isProviderUser, width: isProviderUser ? 0 : 150 },
        { columnName: 'description', width: 180 },
        { columnName: 'balance', align: 'right', width: 70 },
        { columnName: 'currency', align: 'left', wordWrapEnabled: true, width: 70 },
        { columnName: 'stage', wordWrapEnabled: true, width: 85 },
        { columnName: 'date', wordWrapEnabled: true, width: 100 },
        { columnName: 'send_at', wordWrapEnabled: true, width: 100 },
        { columnName: 'authorized_at', wordWrapEnabled: true, width: 100 },
        { columnName: 'type', wordWrapEnabled: true, width: 120 },
        { columnName: 'authorizer', wordWrapEnabled: true, width: view === "to_send" ? 0 : 120 },
        { columnName: 'payment_schedule', wordWrapEnabled: true, width: window.location.pathname.includes('archive') || window.location.pathname.includes('sent') ? 100 : 0 },
        { columnName: 'payment_deadline', wordWrapEnabled: true, width: 100 },
        { columnName: 'total', align: 'right', wordWrapEnabled: true, width: 110 },
        { columnName: 'payment_amount', align: 'right', wordWrapEnabled: true, width: 110 },
        { columnName: 'credit_note_amount', align: 'right', wordWrapEnabled: true, width: 110 },
        { columnName: 'status', wordWrapEnabled: true, width: 100 },
        { columnName: 'menu', width: 50 },
    ]);

    const [columnsPostponeFormat] = useState([
        columnsFormat.slice(1, columnsFormat.length),
        { columnName: 'postpone_to', wordWrapEnabled: true, width: 100 },
        { columnName: 'icons', wordWrapEnabled: true, width: 30 },
    ]);

    const columns = [
        {
            name: 'icons',
            title: " "
        },
        {
            name: 'identifier',
            title: translate('cfdis.columns.serie_folio') as string
        },
        {
            name: 'company_name',
            title: translate('cfdis.columns.company') as string,
            getCellValue: (row: any) => getCompanyName(row)
        },
        {
            name: 'issuer',
            title: translate('cfdis.columns.provider') as string,
            getCellValue: (row: any) => row.issuer.name
        },
        {
            name: 'description',
            title: translate('cfdis.columns.description') as string,
        },
        {
            name: 'balance',
            title: translate("cfdis.columns.balance") as string,
            getCellValue: (row: any) => row.metadata.balance
        },
        {
            name: 'currency',
            title: translate("currency.title_small") as string,
        },
        {
            name: 'date',
            title: translate('cfdis.columns.emission_date_small') as string,
        },
        {
            name: 'send_at',
            title: translate('cfdis.columns.reception_date_small') as string,
            getCellValue: (row: any) => row.metadata.send_at
        },
        {
            name: 'authorized_at',
            title: translate("cfdis.columns.authorization") as string,
            getCellValue: (row: any) => row.metadata.authorized_at
        },
        {
            name: 'type',
            title: translate('cfdis.columns.type') as string,
        },
        {
            name: 'authorizer',
            title: translate("cfdis.columns.authorizer") as string,
            getCellValue: (row: any) => getAuthorizer(row)
        },
        {
            name: 'payment_schedule',
            title: translate("cfdis.columns.payment_schedule_date_small") as string,
            getCellValue: (row: any) => row.metadata.payment_info?.payment_schedule
        },
        {
            name: 'payment_deadline',
            title: translate("cfdis.columns.payment_overdue_date_small") as string,
            getCellValue: (row: any) => row.metadata.payment_info?.payment_deadline
        },
        {
            name: 'total',
            title: translate("cfdis.columns.amounts_small") as string,
        },
        {
            name: 'payment_amount',
            title: translate("cfdis.columns.payment_amount") as string,
            getCellValue: (row: any) => row.total - row.metadata.balance
        },
        {
            name: 'credit_note_amount',
            title: translate("cfdis.columns.credit_note_amount_small") as string,
            getCellValue: (row: any) => getAmountCreditNotes(row)
        },
        {
            name: 'status',
            title: translate("cfdis.columns.status") as string,
            getCellValue: (row: any) => getStatus(row)
        },
        {
            name: 'menu',
            title: " "
        }
    ];

    const postpone = {
        name: 'postpone_to',
        title: translate('cfdis.columns.postpone_to') as string,
        getCellValue: (row: any) => row.metadata.postpone_to
    } as Column;

    const columnsPostpone = [...columns.slice(0, 6), postpone, ...columns.slice(6, columns.length)] as Column[];

    const IconsTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                <CfdiProvideStatusIcon cfdi={value.row} lenght={15} />
                {value.row.metadata.cancelled_by_sat &&
                    <Grid style={{ color: "#C33149" }} title={translate("cfdis_cancelled.tooltip") as string} >
                        <HelpOutlineIcon />
                    </Grid>
                }
                {validateCfdiToBeSettled(value.row) ?
                    <Grid style={{ color: "#FFCC00" }} >
                        <Tooltip title={ReactHtmlParser(translate("cfdis_to_be_settled.tooltip_to_be_settled", { "balance": value.row.metadata.advance_metadata.balance.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2 }), "currency": value.row.currency }) as string)}>
                            <LocalAtmIcon />
                        </Tooltip>
                    </Grid>
                    : validateCfdiSettled(value.row) ? <Grid style={{ color: "#82CA9D" }}>
                        <Tooltip title={ReactHtmlParser(translate("cfdis_to_be_settled.tooltip_settled", { "currency": value.row.currency }) as string)}>
                            <LocalAtmIcon />
                        </Tooltip>
                    </Grid> : undefined}
            </>;
        }} {...props} />
    );

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const TypeFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        {typesCfdi.map((value) => {
                            if (value.value === "ALL") {
                                return <option key={value.value} value={""}>{""}</option>
                            }
                            return <option key={value.value} value={value.value}>{value.title}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const CurrencyFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {currencies.map((value) => {
                            return <option key={value} value={value}>{value}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const CompanyFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {companies.map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const InvoiceTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            if (value.row.type !== "CFDI") {
                return <>{translate(`payable_documents.type.${value.row.type}`) as string}</>;
            }
            return <>{translate(`cfdis.history.cfdi_type.${value.row.metadata.type}`) as string}</>;
        }} {...props} />
    );

    const currencyColumns = ['total', 'balance', 'payment_amount', 'credit_note_amount'];

    const clickRowColumns = ['identifier'];

    const defaultExpandedGroups = Statuses.map(status => translate(`cfdis.history.status.${status}`) as string);

    const textColumns = ['identifier', 'company_name', isProviderUser ? '' : 'issuer', 'description', 'currency'];

    const filtersExceptions = [
        'authorizer', 'payment_amount', 'credit_note_amount', 'status', hideSemaphore ? '' : 'stage',
        view === 'archive' ? 'type' : '',
        view === 'archive' || view === 'pending' || view === 'postpone' || view === 'delivered_erp' ? 'date' : ''
    ];

    const customPlugins = [
        <InvoiceTypeProvider for={['type']} editorComponent={TypeFilterEditor} />,
        <IconsTypeProvider for={['icons']} />,
        <NormalTypeProvider for={filtersExceptions} editorComponent={NoFilterEditor} />,
        <NormalTypeProvider for={['currency']} editorComponent={CurrencyFilterEditor} />,
        <NormalTypeProvider for={['company_name']} editorComponent={CompanyFilterEditor} />,
    ];

    const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'icons', 'identifier', 'company_name', isProviderUser ? '' : 'issuer']) as any;

    const [rightColumns] = useState(['menu']) as any;

    const dateColumns = ['date', 'send_at', 'payment_deadline', 'authorized_at', 'postpone_to', view === "archive" || view === "sent" ? 'payment_schedule' : '', 'payment_due'];

    const grouping = [{ columnName: 'status' }];

    const addColumnsStage_PaymentDue = () => {
        columnsFormat.forEach(item => {
            if (item.columnName === 'stage') {
                item.width = hideSemaphore ? 0 : 85;
            }
        })
        if (!hideSemaphore) {
            columns.splice(6, 0, {
                name: 'stage',
                title: translate("cfdis.columns.stage") as string,
            });
        }
        if (showDueDate) {
            columns.splice(13, 0, {
                name: 'payment_due',
                title: translate("cfdis.columns.payment_due_date_small") as string,
                getCellValue: (row: any) => row.metadata.payment_info?.payment_due
            });
        }
        return columns;
    }

    const addColumnPaymentDue = () => {

        if (showDueDate) {
            columns.splice(13, 0, {
                name: 'payment_due',
                title: translate("cfdis.columns.payment_due_date_small") as string,
                getCellValue: (row: any) => row.metadata.payment_info?.payment_due
            });
        }
        return columns;
    }

    const TableCell = (restProps: any) => {
        let readOnly = restProps.row?.metadata.read_only?.active;
        let style = restProps.style ?? {};
        return (
            <Table.Cell column={restProps.column} row={restProps.row} tableColumn={restProps.tableColumn} tableRow={restProps.tableRow} value={restProps.value}
                {...restProps}
                style={{ ...style, backgroundColor: readOnly ? '#F8F8F8' : '' }}
            >
                {restProps.children}
            </Table.Cell>
        );
    };

    const CheckCell = (restProps: any, header: boolean) => {
        let style = restProps.style ?? {};
        let readOnly = restProps.row?.metadata.read_only?.active;
        return (
            <Table.Cell column={restProps.column} row={restProps.row} tableColumn={restProps.tableColumn} tableRow={restProps.tableRow} value={restProps.value}
                {...restProps} style={{ ...style, backgroundColor: readOnly ? '#F8F8F8' : '', paddingLeft: "3px" }} >
                <Checkbox checked={header ? restProps.allSelected : restProps.selected} onChange={() => restProps.onToggle()} size="small" indeterminate={restProps.someSelected} />
            </Table.Cell>
        );
    };

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    switch (view) {
        case "to-send":
            return (
                <ListCfdisToSend
                    columns={columns}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    rightColumns={rightColumns}
                    dateColumns={dateColumns}
                    columnsFormat={columnsFormat}
                    leftColumns={leftColumns}
                    customPlugins={customPlugins}
                    clickRowColumns={clickRowColumns}
                    textColumns={textColumns}
                    title={translate("cfdis.to_send.title") as string}
                    success={match.params.success}
                    tableCell={TableCell}
                    checkCell={CheckCell}
                />
            );
        case "sent":
            return (
                <ListCfdisInProcess
                    columns={addColumnsStage_PaymentDue()}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    rightColumns={rightColumns}
                    dateColumns={dateColumns}
                    columnsFormat={columnsFormat}
                    leftColumns={leftColumns}
                    clickRowColumns={clickRowColumns}
                    customPlugins={customPlugins}
                    textColumns={textColumns}
                    title={translate("cfdis.sent.title") as string}
                    success={match.params.success}
                    tableCell={TableCell}
                    checkCell={CheckCell}
                />
            );
        case "archive":
            if (context.session!.role.id !== "armor_purchasing") { // TODO: PARCHE PARA ROL ARMOR, QUITAR CUANDO LAS OPCIONES DEL MENU SE PUEDAN PERSONALIZAR AL 100%
                return (
                    <ListCfdisArchive
                        columns={addColumnsStage_PaymentDue()}
                        defaultExpandedGroups={defaultExpandedGroups}
                        currencyColumns={currencyColumns}
                        dateColumns={dateColumns}
                        columnsFormat={columnsFormat}
                        leftColumns={leftColumns}
                        rightColumns={rightColumns}
                        clickRowColumns={clickRowColumns}
                        customPlugins={customPlugins}
                        textColumns={textColumns}
                        grouping={grouping}
                        title={translate("cfdis.all_cfdis.title") as string}
                        success={match.params.success}
                        tableCell={TableCell}
                        checkCell={CheckCell}
                    />
                );
            }
            return (<>{translate("errors.codes.1201")}</>);
        case "authorize":
            return (
                <ListCfdisToAuthorize
                    columns={columns}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    columnsFormat={columnsFormat}
                    leftColumns={leftColumns}
                    rightColumns={rightColumns}
                    clickRowColumns={clickRowColumns}
                    customPlugins={customPlugins}
                    textColumns={textColumns}
                    title={translate("cfdis.to_authorize.title") as string}
                    success={match.params.success}
                    tableCell={TableCell}
                    checkCell={CheckCell}
                />
            );
        case "verify":
            return (
                <ListCfdisToVerify
                    columns={columns}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    columnsFormat={columnsFormat}
                    leftColumns={leftColumns}
                    rightColumns={rightColumns}
                    clickRowColumns={clickRowColumns}
                    customPlugins={customPlugins}
                    textColumns={textColumns}
                    title={translate("cfdis.to_verify.title") as string}
                    success={match.params.success}
                    tableCell={TableCell}
                    checkCell={CheckCell}
                />
            );
        case "pending":
            if (context.session!.role.id !== "armor_purchasing") { // TODO: PARCHE PARA ROL ARMOR, QUITAR CUANDO LAS OPCIONES DEL MENU SE PUEDAN PERSONALIZAR AL 100%
                return (
                    <ListCfdisPendingPayment
                        columns={addColumnPaymentDue()}
                        defaultExpandedGroups={defaultExpandedGroups}
                        currencyColumns={currencyColumns}
                        dateColumns={dateColumns}
                        columnsFormat={columnsFormat}
                        leftColumns={leftColumns}
                        rightColumns={rightColumns}
                        clickRowColumns={clickRowColumns}
                        customPlugins={customPlugins}
                        textColumns={textColumns}
                        title={translate("cfdis.pay_pending.title") as string}
                        tableCell={TableCell}
                        checkCell={CheckCell}
                    />
                );
            }
            return (<>{translate("errors.codes.1201")}</>);
        case "postpone":
            return (
                <ListCfdisPostpone
                    columns={columnsPostpone}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    columnsFormat={columnsPostponeFormat}
                    leftColumns={leftColumns}
                    rightColumns={rightColumns}
                    clickRowColumns={clickRowColumns}
                    customPlugins={customPlugins}
                    textColumns={textColumns}
                    title={translate("cfdis.postpone_payment.title_seccion") as string}
                    tableCell={TableCell}
                    checkCell={CheckCell}
                />
            );
        case "delivered_erp":
            return (
                <ListCfdisDelivered
                    columns={addColumnsStage_PaymentDue()}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    columnsFormat={columnsFormat}
                    leftColumns={leftColumns}
                    rightColumns={rightColumns}
                    clickRowColumns={clickRowColumns}
                    customPlugins={customPlugins}
                    textColumns={textColumns}
                    title={translate("cfdis.delivered_erp.title_seccion") as string}
                    tableCell={TableCell}
                    checkCell={CheckCell}
                />
            );
        default:
            return (<></>);
    }

}