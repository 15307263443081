import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";

import translate from "../i18n/Translator";
import { updatePassword } from "../api/MeAPI";
import { updatePassword as userUpdatePassword } from "../api/UserAPI";
import { PasswordRequest } from "../model/User";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ConfirmPasswordValidator } from "../components/Validators";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

export interface UserPasswordProps {
    isPlaformAdministrator: boolean;
    userId: string | undefined;
}

export default function UserPasswordForm(props: UserPasswordProps) {
    const margin = "dense";
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [request, setRequest] = useState<PasswordRequest>({
        "old_password": "",
        "password": "",
        "confirm_password": "",
    } as PasswordRequest);

    const [validations, setValidations] = useState({
        "old_password": {
            valid: props?.isPlaformAdministrator
        } as InputRef,
        "password": {
            valid: false
        } as InputRef,
        "confirm_password": {
            valid: false
        } as InputRef
    } as any);

    const confirmPasswordValidator = new ConfirmPasswordValidator(() => request.password);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }
        setSubmitting(true);
        if (props?.isPlaformAdministrator && props.userId) {
            userUpdatePassword(props.userId, { password: request.password, confirm_password: request.confirm_password })
                .then(() => {
                    for (let field in validations) {
                        let ref = validations[field];
                        if(ref.blurer){
                            ref.blurer(false);
                        }
                    }
                    passwordUpdateSuccess("users.security.user_updated_password");
                }).catch((error) => {
                    setError(error.message);
                }).finally(() => {
                    setSubmitting(false);
                });
        } else {
            updatePassword(request).then((_response) => {
                passwordUpdateSuccess("users.security.updated_password");
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }

    function passwordUpdateSuccess(message: string): void {
        setSuccess(translate(message) as string);
        setRequest({} as PasswordRequest);
        for (let field in validations) {
            let ref = validations[field];
            ref.blurer(false);
        }
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit} >
            <Grid container>
                <Grid item xs={12}>
                    {!props.isPlaformAdministrator && <ValidatedInput type="password" id="old_password" name="old_password"
                        label={translate("users.old_password") as string} value={request.old_password}
                        onValueChanged={hasChanged} disabled={submitting} required
                        margin={margin} />}
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="password" id="password" name="password"
                        label={translate("users.password") as string} value={request.password}
                        onValueChanged={hasChanged} disabled={submitting} required
                        validator={confirmPasswordValidator}
                        margin={margin} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="password" id="confirm_password" name="confirm_password"
                        label={translate("users.confirm_password") as string} value={request.confirm_password}
                        onValueChanged={hasChanged} disabled={submitting} required
                        validator={confirmPasswordValidator}
                        margin={margin} />
                </Grid>
                <Grid item xs={12} className="ExternButton">
                    <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                        {translate("buttons.update")}
                    </Button>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </form>
    );
}