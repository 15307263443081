import { callAPI, HTTPMethod } from "./API";
import { TaxRegimens } from "../model/TaxRegimen";

export async function getTaxRegimens(search: string): Promise<TaxRegimens> {
    return await callAPI(`/tax-regimens`, {
        method: HTTPMethod.GET,
        query: {
            search: search
        }
    });
}