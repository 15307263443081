import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, Box, Divider, List, ListItem, ListItemText, Card, IconButton, Tooltip } from "@material-ui/core";
import PurchaseOrdersIcon from "@material-ui/icons/ShopTwo";

import { getWarehouseDelivery } from "../api/WarehouseDeliveryAPI";
import { getWarehouseDelivery as getProviderWarehouseDelivery } from "../api/ProviderWarehouseDeliveryAPI";
import translate from "../i18n/Translator";
import Progress from "../components/Progress";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import NumberFormat from "react-number-format";
import { ProviderAddress } from "../providers/ProviderAddressCard";
import { WarehouseDelivery } from "../model/WarehouseDelivery";
import { Metadata } from "../model/PurchaseOrder";
import { PurchaseOrderTax } from "../model/Document";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function PurchaseOrderView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const providerId = context.session!.provider?.id;

    const [status, setStatus] = useState<string>("loading");
    const [warehouseDelivery, setWarehouseDelivery] = useState<WarehouseDelivery>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const getPromise = (): Promise<WarehouseDelivery> => {
        if (providerId) {
            return getProviderWarehouseDelivery(context.session!.tenant!.id, providerId, match.params.warehouseDeliveryId);
        }
        return getWarehouseDelivery(context.session!.tenant!.id, match.params.warehouseDeliveryId);
    };

    useEffect(() => {
        setStatus("loading");
        getPromise().then((warehouseDelivery) => {
            setWarehouseDelivery(warehouseDelivery);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, [match.params.warehouseDeliveryId]);

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !warehouseDelivery) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const getValueMetadataLine = (metadata: Metadata[]) => {
        return (<div>
            <Grid item xs={12} sm={12} lg={12}>
                {(metadata || []).map((data, index) => (
                    <List dense disablePadding>
                        <ListItem disableGutters>
                            <ListItemText primary={data.key} secondary={(
                                <Typography variant="body2" color="inherit">{data.value}</Typography>
                            )} />
                        </ListItem>
                    </List>
                ))}
            </Grid>
        </div>);
    }

    const getTaxes = (taxes: PurchaseOrderTax[]): number => {
        let tax: number = 0;
        taxes.forEach((vat_tax) => {
            tax += vat_tax.amount
        });
        return tax;
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} xl={12} lg={12}>
                    <Surface title={translate("warehouse_deliveries.single")} subtitle={`${warehouseDelivery.external_id} - Num: ${(warehouseDelivery.document_number || "---")}`} icon={<PurchaseOrdersIcon />} className="FormSurface" backButton>
                        <Grid item xs={12} xl={12} lg={12}>
                            <Card variant="outlined" >
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <Typography color="inherit" variant="body1">
                                                {translate("purchase_orders.general_info")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <List dense disablePadding>
                                                {warehouseDelivery.provider && (
                                                    <ListItem disableGutters>
                                                        <ListItemText primary={warehouseDelivery.provider.name} secondary={warehouseDelivery.provider.rfc} />
                                                    </ListItem>
                                                )}
                                                <ListItem disableGutters>
                                                    <ListItemText primary={translate("warehouse_deliveries.status.title")} secondary={translate(`purchase_orders.status.${warehouseDelivery.status}`)} />
                                                </ListItem>
                                                <ListItem disableGutters>
                                                    <ListItemText primary={translate("warehouse_deliveries.reference")} secondary={warehouseDelivery.reference || "---"} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <List dense disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemText primary={translate("warehouse_deliveries.date")} secondary={(
                                                        <DateFormat date={warehouseDelivery.date} format="DD/MM/YYYY" />
                                                    )} />
                                                </ListItem>
                                                {warehouseDelivery.document_number && (
                                                    <ListItem disableGutters>
                                                        <ListItemText primary={translate("warehouse_deliveries.doc_number")} secondary={warehouseDelivery.document_number} />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <List dense disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemText primary={translate("warehouse_deliveries.due_date")} secondary={(
                                                        <DateFormat date={warehouseDelivery.due_date} format="DD/MM/YYYY" />
                                                    )} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <List dense disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemText primary={translate("warehouse_deliveries.comments")} secondary={warehouseDelivery.comments || "---"} />
                                                </ListItem>
                                            </List>
                                        </Grid>

                                        {warehouseDelivery.metadata && (
                                            <Grid item xs={12} sm={12} lg={12}>
                                                <Divider />
                                            </Grid>
                                        )}
                                        {(warehouseDelivery.metadata || []).map((data, index) => (
                                            <Grid item xs={12} sm={12} lg={3}>
                                                <List dense disablePadding>
                                                    <ListItem disableGutters>
                                                        <ListItemText primary={data.key} secondary={data.value} />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} xl={12} lg={12} style={{ marginTop: "20px" }}>
                            <Card variant="outlined" >
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} lg={6}>
                                            <Grid container spacing={2}>
                                                {(warehouseDelivery.addresses || []).map((address, index) => (
                                                    <Grid key={index} item xs={12} sm={6} lg={6}>
                                                        <List dense disablePadding>
                                                            {address.type && (
                                                                <ListItem disableGutters>
                                                                    <ListItemText primary={`${translate("expedients.addresses.of")} ${translate(`expedients.addresses.types.${address.type}`)}`}
                                                                        secondary={(
                                                                            <ProviderAddress address={address} />
                                                                        )} />
                                                                </ListItem>
                                                            )}
                                                        </List>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {(!warehouseDelivery.addresses || warehouseDelivery.addresses.length === 0) && (
                                                <Typography variant="body2" component="h6">
                                                    {translate("warehouse_deliveries.no_addresses")}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <List dense disablePadding>
                                                {warehouseDelivery.vat_sum && (
                                                    <ListItem disableGutters >
                                                        <ListItemText style={{ textAlign: "right" }} primary={translate("purchase_orders.taxes_transferred")} secondary={(
                                                            <NumberFormat value={warehouseDelivery.vat_sum} prefix="$ " thousandSeparator="," decimalScale={2}
                                                                fixedDecimalScale={true} displayType="text" />
                                                        )} />
                                                    </ListItem>
                                                )}
                                                {warehouseDelivery.withhold_tax_sum && (
                                                    <ListItem disableGutters>
                                                        <ListItemText style={{ textAlign: "right" }} primary={translate("purchase_orders.taxes_withheld")} secondary={(
                                                            <NumberFormat value={warehouseDelivery.withhold_tax_sum} prefix="$ " thousandSeparator="," decimalScale={2}
                                                                fixedDecimalScale={true} displayType="text" />
                                                        )} />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <List dense disablePadding>
                                                {warehouseDelivery.subtotal &&
                                                    <ListItem disableGutters>
                                                        <ListItemText style={{ textAlign: "right" }} primary={translate("warehouse_deliveries.line_subtotal")} secondary={(
                                                            <NumberFormat value={warehouseDelivery.subtotal} prefix="$ " thousandSeparator="," decimalScale={2}
                                                                fixedDecimalScale={true} displayType="text" suffix={` ${warehouseDelivery.currency || "MXN"}`} />
                                                        )} />
                                                    </ListItem>
                                                }
                                                <ListItem disableGutters>
                                                    <ListItemText style={{ textAlign: "right" }} primary={translate("warehouse_deliveries.total")} secondary={(
                                                        <NumberFormat value={warehouseDelivery.total} prefix="$ " thousandSeparator="," decimalScale={2}
                                                            fixedDecimalScale={true} displayType="text" suffix={` ${warehouseDelivery.currency || "MXN"}`}
                                                            style={{ color: "#f6944e", fontSize: "1.1rem" }} />
                                                    )} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} xl={12} lg={12}>
                            <Grid item xs={12}>
                                <Box mx={-2}>
                                    <Gridable
                                        items={warehouseDelivery.lines}
                                        loading={false}
                                        empty={translate("warehouse_deliveries.no_lines") as string}
                                        columns={[
                                            {
                                                title: translate("warehouse_deliveries.line_num"),
                                                converter: (line) => line.line_num,
                                                fullWidth: true,
                                                xs: 1,
                                                sm: 1,
                                                lg: 1
                                            },
                                            {
                                                title: translate("warehouse_deliveries.item_code"),
                                                converter: (line) => line.item_code || "---",
                                                fullWidth: true,
                                                xs: false,
                                                sm: 2,
                                                lg: 1
                                            },
                                            {
                                                title: translate("warehouse_deliveries.unit_of_measure"),
                                                converter: (line) => line.unit_of_measure || "---",
                                                fullWidth: true,
                                                xs: false,
                                                sm: false,
                                                md: false,
                                                lg: 1
                                            },
                                            {
                                                title: translate("warehouse_deliveries.description"),
                                                converter: (line) => line.description,
                                                fullWidth: true,
                                                xs: 3,
                                                sm: 2,
                                                lg: 2
                                            },
                                            {
                                                title: (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <strong>{translate("warehouse_deliveries.quantity")}</strong>
                                                    </Typography>
                                                ),
                                                converter: (line) => (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <NumberFormat value={line.quantity} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                                    </Typography>
                                                ),
                                                fullWidth: true,
                                                xs: false,
                                                sm: 1,
                                                lg: 1
                                            },
                                            {
                                                title: (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <strong>{translate("warehouse_deliveries.price")}</strong>
                                                    </Typography>
                                                ),
                                                converter: (line) => (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <NumberFormat value={line.price} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                                    </Typography>
                                                ),
                                                fullWidth: true,
                                                xs: false,
                                                sm: 1,
                                                lg: 1
                                            },
                                            {
                                                title: (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <strong>{translate("purchase_orders.taxes_transferred")}</strong>
                                                    </Typography>
                                                ),
                                                converter: (line) => (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <NumberFormat value={!!line?.vat_taxes ? getTaxes(line.vat_taxes) : 0} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                                    </Typography>
                                                ),
                                                fullWidth: true,
                                                xs: false,
                                                sm: 2,
                                                lg: 1
                                            },
                                            {
                                                title: (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <strong>{translate("purchase_orders.taxes_withheld")}</strong>
                                                    </Typography>
                                                ),
                                                converter: (line) => (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <NumberFormat value={!!line?.withholding_taxes ? getTaxes(line.withholding_taxes) : 0} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                                    </Typography>
                                                ),
                                                fullWidth: true,
                                                xs: false,
                                                sm: 2,
                                                lg: 1
                                            },
                                            {
                                                title: (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <strong>{translate("warehouse_deliveries.line_total")}</strong>
                                                    </Typography>
                                                ),
                                                converter: (line) => (
                                                    <Typography variant="body2" component="h6" align="right">
                                                        <NumberFormat value={line.line_total} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                                    </Typography>
                                                ),
                                                fullWidth: true,
                                                xs: false,
                                                sm: 2,
                                                lg: 2
                                            },
                                            {
                                                title: <Typography />,
                                                converter: (line) => (
                                                    <Grid>
                                                        {line.metadata ?
                                                            <IconButton>
                                                                <Tooltip title={getValueMetadataLine(line.metadata)}>
                                                                    <ErrorOutlineIcon />
                                                                </Tooltip>
                                                            </IconButton>
                                                            : <Typography />}
                                                    </Grid>
                                                ),
                                                fullWidth: true,
                                                xs: 1,
                                                sm: 1,
                                                lg: 1
                                            }
                                        ]}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}