import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { ShippingTemplate } from "../model/ShippingTemplate";
import { deleteShippingTemplate } from "../api/ShippingTemplateAPI";
import DialogPopup from "../components/DialogPopup";

interface ShippingDeletePopupProps {
    tenantId: string;
    shippingTemplate: ShippingTemplate;
    onCompleted(): any;
    onError(error: string): any;
    onCancelled(): any;
}

export default function ShippingDeletePopup(props: ShippingDeletePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onConfirm = () => {
        setSubmitting(true);
        deleteShippingTemplate(props.tenantId, props.shippingTemplate.id).then(() => {
            setSubmitting(false);
            props.onCompleted();
        }).catch((error) => {
            setSubmitting(false);
            props.onError(error.message);
        });
    };

    return (
        <DialogPopup open
            title={translate("shipping_templates.popup.delete.title") as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onConfirm} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.delete")}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate("shipping_templates.popup.delete.description", { name: props.shippingTemplate.name })}
            </Typography>
        </DialogPopup>
    );

}