import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { Grid, IconButton, Divider, Button, Fab, Tooltip, Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { WorkflowsIcon, AddIcon, MoreVertIcon } from "../components/Icons";

import { deleteWorkflow, listWorkflows, updateDefault, validateProviders } from "../api/WorkflowAPI";
import { AppContext } from "../context/AppContext";
import { Workflow, Workflows, WorkflowsQueryParams, WorkflowDeleteRequest } from "../model/Workflow";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import WorkflowMenu from "./WorkflowMenu";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { PluginStep } from "../model/Plugin";
import WorkflowPluginPopup from "./WorkflowPluginPopup";
import { Product, PRODUCTS } from "../workflove/Workflove";
import NewShippingTemplateMenu from "../shipping_templates/NewShippingTemplateMenu";

type WorkflowDialog = "delete" | "default" | "plugins" | "disable";

export default function WorkflowList() {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): WorkflowsQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as WorkflowsQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [dialog, setDialog] = useState<WorkflowDialog>();
    const [data, setData] = useState<Workflows>();
    const [activeData, setActiveData] = useState<Workflow[]>([]);
    const [params, setParams] = useState<WorkflowsQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<WorkflowsQueryParams>(paramsFromQueryString);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [workflow, setWorkflow] = useState<Workflow>();
    const [deleteRequest, setDeleteRequest] = useState<WorkflowDeleteRequest>({} as WorkflowDeleteRequest);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
    const [pluginStep, setPluginStep] = useState<PluginStep>();
    const [validations, setValidations] = useState({} as any);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [products, setProducts] = useState<Product[]>([]);

    const load = () => {
        let offset = getOffset(page, pageSize);
        listWorkflows(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onDeleteChanged = (name: string, value: string, inputRef?: any) => {
        setDeleteRequest({ ...deleteRequest, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onDisable = () => {
        validateProviders(context.session!.tenant!.id, workflow!.id).then((response) => {
            setDeleteRequest({ workflow_to_delete_id: workflow!.id } as WorkflowDeleteRequest);
            if (response && response.no_providers_in_use < 1) {
                onDialog("delete");
            } else {
                let workflows = [] as Workflow[];
                listWorkflows(context.session!.tenant!.id, 0, 0, { status: "AVAILABLE", search: "" } as WorkflowsQueryParams).then((response) => {
                    workflows = response.items;
                    setActiveData(workflows.filter(item => item.id !== workflow!.id));
                    onDialog("disable");
                }).catch((error) => {
                    setStatus(error.message);
                });
            }
        }).catch(error => {
            setError(error.message);
        });
    }

    const onDefault = () => onDialog("default");

    const onEditPlugins = (step: PluginStep) => {
        setPluginStep(step);
        onDialog("plugins");
    };

    const onDialog = (dialog: WorkflowDialog) => {
        setAnchorEl(null);
        if (!workflow) return;
        setDialog(dialog);
    };

    const confirmDisable = () => {
        if (dialog === "disable" && !isValid()) return;
        
        setSubmitting(true);
        deleteWorkflow(context.session!.tenant!.id, deleteRequest).then((response) => {
            if (response && response.status === "DISABLED") {
                setSuccess(translate("workflows.delete.disabled") as string);
            } else {
                setSuccess(translate("workflows.delete.deleted") as string);
            }
            load();
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            cancelDialog();
        });
    };

    const confirmDefault = () => {
        setSubmitting(true);
        updateDefault(context.session!.tenant!.id, workflow!.id).then((response) => {
            setSuccess(translate("workflows.default.success") as string);
            load();
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            cancelDialog();
        });
    };

    const cancelDialog = () => {
        setDialog(undefined);
        setWorkflow(undefined);
        setPluginStep(undefined);
    };

    const onClickedRow = (workflow: Workflow) => {
        history.push(`/workflows/${workflow.id}/edit`);
    };

    const onUpdatedPlugins = (workflow: Workflow) => {
        const step = translate(`plugins.steps.${pluginStep!}`) as string
        setSuccess(translate("workflows.updated_plugins", { "workflow": workflow.name, "step": step }) as string);
        cancelDialog();
    };

    const openProductPicker = (event: React.MouseEvent<HTMLElement>) => {
        const enabledProducts = PRODUCTS.filter(el => {
            if (el === "PAYABLE_DOCUMENTS") {
                return context.isGranted("ShippingTemplateCreate");
            }
            if (el === "REFUNDS") {
                return context.isGranted("RefundsCreate");
            }
            return false;
        })

        if (enabledProducts.length === 1) {
            history.push(`/workflows/new?product=${enabledProducts[0]}`);
        } else {
            setProducts(enabledProducts);
            setAddAnchorEl(event.currentTarget);
        }
    }

    const onCancelAdding = () => {
        setProducts([]);
        setAddAnchorEl(null);
    };

    const onClickedOptions = (workflow: Workflow) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWorkflow(workflow);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setWorkflow(undefined);
    };

    const onClosedSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        if (valid && !deleteRequest?.workflow_replace_id) {
            valid = false;
        }
        return valid;
    }

    return (
        <Pagination
            title={translate("workflows.title")}
            icon={<WorkflowsIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            action={context.isGranted("WorkflowsCreate") ? (
                <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={openProductPicker}>
                    <AddIcon />
                </Fab>
            ) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("workflows.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("workflows.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("workflows.name") as string,
                        converter: (workflow) => (
                            <Grid container justify="center" alignContent="center" alignItems="center">
                                <Grid item xs>
                                    <Ellipsis text={workflow.name} lenght={100} uppercased={false} />
                                </Grid>
                                {workflow.is_default && (
                                    <Grid item xs="auto">
                                        <Tooltip title={translate("workflows.default.single") as string} placement="left-end">
                                            <StarIcon fontSize="small" />
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("workflows.status.title") as string,
                        converter: (workflow) => translate(`workflows.status.${workflow.status}`),
                        fullWidth: true,
                        xs: false,
                        sm: 3,
                        md: 3,
                        lg: 2,
                        xl: 2,
                    },
                    {
                        title: translate("shipping_templates.products.title") as string,
                        converter: (workflow) => translate(`shipping_templates.products.${workflow.product}`),
                        xs: 4,
                        sm: 3,
                        md: 3,
                        lg: 2,
                        xl: 2,
                    },
                    {
                        title: translate("workflows.default.single") as string,
                        converter: (workflow) => translate(workflow.is_default ? "yes" : "no"),
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2,
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (workflow) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(workflow)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {workflow && anchorEl &&
                <WorkflowMenu workflow={workflow}
                    anchor={anchorEl}
                    onDelete={onDisable}
                    onDefault={onDefault}
                    onEditPlugins={onEditPlugins}
                    onClose={onCloseOption} />
            }
            {dialog === "delete" && (
                <ConfirmationPopup title={translate("workflows.delete.title") as string}
                    message={translate("workflows.delete.text") as string}
                    button={translate("buttons.delete") as string}
                    color="secondary"
                    onClose={cancelDialog}
                    doAction={confirmDisable}
                    submitting={submitting} />
            )}
            {dialog === "disable" && (
                <ConfirmationPopup title={translate("workflows.delete.title") as string}
                    message={<Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {translate("workflows.delete.disable_message") as string}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="workflow_replace_id" name="workflow_replace_id"
                                label={translate("workflows.delete.new_workflow") as string}
                                value={deleteRequest.workflow_replace_id}
                                required
                                margin={"dense"}
                                autocompleteOptions={activeData}
                                autocompleteValue={activeData.find(item => item.id === deleteRequest.workflow_replace_id)}
                                getId={item => item?.id}
                                getLabel={item => item ? item.name : ""}
                                disabled={submitting}
                                onValueChanged={onDeleteChanged} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {translate("workflows.delete.delete_note") as string}
                            </Typography>
                        </Grid>
                    </Grid>}
                    button={translate("buttons.delete") as string}
                    color="secondary"
                    onClose={cancelDialog}
                    doAction={confirmDisable}
                    submitting={submitting} />
            )}
            {dialog === "default" && (
                <ConfirmationPopup title={translate("workflows.default.title") as string}
                    message={translate("workflows.default.text") as string}
                    button={translate("buttons.set_default") as string}
                    color="primary"
                    onClose={cancelDialog}
                    doAction={confirmDefault}
                    submitting={submitting} />
            )}
            {dialog === "plugins" && workflow && pluginStep && (
                <WorkflowPluginPopup step={pluginStep}
                    workflow={workflow}
                    onSuccess={onUpdatedPlugins}
                    onClose={cancelDialog} />
            )}
            {addAnchorEl && products.length > 1 && (
                <NewShippingTemplateMenu
                    path="/workflows/new"
                    products={products}
                    anchor={addAnchorEl}
                    onClose={onCancelAdding} />
            )}
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
        </Pagination>
    );
}