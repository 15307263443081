import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import PlansProvider from "./PlansProvider";
import SignManifest from "./SignManifest";
import ConfigureCertificate from "./ConfigureCertificate";
import { InfoPaymentPlanProviderInReviewWithProps } from "./InfoPaymentPlanProvider";
import ProviderMembershipStatusView from "./ProviderMembershipStatusView";

export default function TaxStamp(){
    const context = useContext(AppContext);
    const statusProviderTaxStamp = context.session?.provider?.status_membership_tax_stamp || "";


    switch(statusProviderTaxStamp) {
        case "TO_VERIFY_MEMBERSHIP": return  <InfoPaymentPlanProviderInReviewWithProps 
            providerMembershipTaxStampId={context.session?.provider?.membership_tax_stamp_id || ""}/>;
        case "PENDING_MANIFEST": return  <SignManifest/>;
        case "PENDING_CERTIFICATE": return  <ConfigureCertificate/>;
        case "ACTIVE": return <ProviderMembershipStatusView />;
        case "PENDING_MEMBERSHIP":
        default:
                return <PlansProvider/>;
        
    }
}