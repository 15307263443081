
export interface SystemLog {
    id: string;
    identifier: string;
    user_email?: string;
    provider_name?: string;
    started_at: Date;
    ended_at: Date;
    event_group: string;
    event: string;
    document_name?: string;
    document_number?: string;
    ip?: string;
    data_sent: string;
    status: string;
    comments?: string;
}

export interface SystemLogList {
    items: SystemLog[];
    total: number;
}

export interface SystemLogQueryParams {
    identifier: string;
    started_at: string;
    ended_at: string;
    event_group: string;
    event: string;
    ip: string;
    status: string;
    user_email: string;
    provider_name: string;
    document_name: string,
    document_number: string;
}

export const Statuses = ["OK", "ERROR", "IN_PROCESS"];

export const Groups = [
    'LOGIN', 
    'CFDI', 
    'PAYMENT', 
    'PROVIDER', 
    'COMMUNICATION', 
    'USER', 
    'WORKFLOW', 
    'RECEPTION_RULE', 
    'EXPEDIENT', 
    'SUPPORT'
];

export const Events = [
    'LOGIN_FAIL', 
    'LOGIN_SUCCESS', 
    'USER_BLOCKED', 
    'USER_UNBLOCKED', 
    'UPLOAD_CFDI_FAIL', 
    'UPLOAD_CFDI_EMAIL_FAIL', 
    'EMAIL_SENDED', 
    'ANOUNCEMENT_VIEWED', 
    'CFDI_DELETED',  
    'CFDIS_DELETED', 
    'CFDI_CANCELLED',  
    'PAYMENT_REJECTED', 
    'NEW_PROVIDER', 
    'PROVIDER_DROPPED', 
    'NEW_USER', 
    'USER_EDITED',
    'PASSWORD_CHANGED',
    'PAYMENT_CFDI_SENDED_TO_REVIEW',
    'DISABLED_EXPEDIENT_TEMPLATE', 
    'RECEPTION_RULE_EDITED', 
    'WORKFLOW_CREATED', 
    'WORKFLOW_EDITED',
    'PAYMENT_ORDER_DELETED', 
    'USER_IMPERSONALIZED', 
    'CFDIS_DELETED_BY_SUPPORT',
    'BUDGET_DELETED',
    'FORCED_STATUS_CHANGE',
    'TENANT_FIELD_UPDATE_EXPIRATION'
];

export const EventsOwner = Events.filter(e => e !== "USER_IMPERSONALIZED" && e !== "CFDIS_DELETED_BY_SUPPORT");