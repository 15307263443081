import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { listCustomReports, deleteCustomReport, changeStatusCustomReport } from "../api/CustomReportAPI";
import { AppContext } from "../context/AppContext";
import { Grid, Divider, Fab } from "@material-ui/core";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { CustomReport, CustomReports, CustomReportsListParams } from "../model/CustomReport";
import { concatDates, formatDateString, formatDateToString } from "../components/DateFormat";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import DateFormat from "../components/DateFormat";
import queryString from "query-string";
import CustomReportsMenu from "./CustomReportsMenu";
import ConfirmationPopup from "../components/ConfirmationPopup";
import WorkIcon from "@material-ui/icons/Work";
import AddIcon from "@material-ui/icons/Add";
import GridDx from "../components/GridDx";
import Surface from "../components/Surface";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { Filter } from "@devexpress/dx-react-grid";

export default function CustomReportsList({ match }: RouterParams) {

    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const sDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const paramsFromQueryString = (): CustomReportsListParams => {
        return {
            "name": typeof qs["name"] === "string" ? qs["name"] as string : "",
            "columns": typeof qs["columns"] === "string" ? qs["columns"] as string : "",
            "exportable": typeof qs["exportable"] === "string" ? qs["exportable"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "created_at_init": typeof qs["created_at_init"] === "string" ? qs["created_at_init"] as string : formatDateToString(sDate)+" - "+formatDateToString(new Date()),
            "created_at_end": typeof qs["created_at_end"] === "string" ? qs["created_at_end"] as string : ""
        } as CustomReportsListParams;
    };

    const [status, setStatus] = useState<string>("loaded");
    const [data, setData] = useState<CustomReports>();    
    const [params, setParams] = useState<CustomReportsListParams>(paramsFromQueryString);
    
    const paramsToFilters = (): Filter[] => {
        let temp = params.created_at_init.split(" - ")
        return [
            { columnName: 'name', value: params.name },
            { columnName: 'columns_names', value: params.columns },
            { columnName: 'exportable', value: params.exportable },
            { columnName: 'status', value: params.status },
            { columnName: 'created_at', value: concatDates(params.created_at_init, params.created_at_end) }
        ] as Filter[];
    };

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [customreport, setCustomreport] = useState<CustomReport>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState<boolean>(false);
    const [deleting, setDeleting] = useState(false);
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);
    const clickRowColumns = ['name'];
    const textColumns = ['name', 'columns_names', 'exportable', 'status'];
    const dateColumns = ['created_at'];
    const [leftColumns] = useState([]) as any;
    
    const [columnsFormat] = useState([
        { columnName: 'name', wordWrapEnabled: true },
        { columnName: 'columns_names', wordWrapEnabled: true, align: 'center' },
        { columnName: 'exportable', wordWrapEnabled: true },
        { columnName: 'created_at', wordWrapEnabled: true},
        { columnName: 'status', wordWrapEnabled: true },
        { columnName: 'menu', width: 50 },
    ]) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();
        if (match.params.success) {
            setSuccess(match.params.success);
        }
        let tempParams = params;
        listCustomReports(match.params.tenantId, pageSize, pageSize * page, tempParams)
        .then((response) => {    
            response.items.forEach((element) => {                
                let columns_name = element.columns.map((e) => {
                    return e.label;
                })
                element.columns_names = columns_name.join();
            })    
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
            setData(undefined);
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "name":
                        temp.name = filter.value;
                        break;
                    case "columns_names":
                        temp.columns = filter.value;
                        break;
                    case "exportable":
                        temp.exportable = filter.value;
                        break;
                    case "created_at":
                        let datesS = filter.value.split(" - ");
                            if (datesS.length === 2) {
                                temp.created_at_init = formatDateString(datesS[0]); 
                                temp.created_at_end = formatDateString(datesS[1]);
                            } else {
                                temp.created_at_init = "";
                                temp.created_at_end = "";
                            }
                        break;
                    case "status":
                        temp.status = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    }

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["name"] = params.name || "";
        qs["columns_names"] = params.columns || "";
        qs["exportable"] = params.exportable || "";
        qs["created_at_init"] = params.created_at_init || "";
        qs["created_at_end"] = params.created_at_end || "";
        qs["status"] = params.status || "";
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    }

    const onChangedPageSize = (pageSize: number) => {
        setPage(0);  
        setPageSize(pageSize);
    };

    const onChangedPage = (page: number) => {
        setPage(page);        
    };

    const onClickRow = (customreport: CustomReport) => {
        if (!context.isGrantedAny(['CustomReportUpdate'])) { 
            return;
        }
        pushHistory();
        setCustomreport(customreport);
        history.push(`/tenants/${match.params.tenantId}/customreports/${customreport.id}/edit`)
    };

    const onClickedOptions = (customreport: CustomReport) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCustomreport(customreport);
    };

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const getStatus = (item: CustomReport) => {
        return translate(`custom_reports.statuses.${item.status}`) as string;
    };

    const getCreatedAt = (item: CustomReport) => {
        return <DateFormat date={item.created_at} format="MM/dd/yyyy" />;
    }

    const getExportable = (item: CustomReport) => {
        return translate(`custom_reports.is_exportable.${ (item.exportable) ? "yes" : "not"}`) as string;
    };

    const onClickAdd = () => {
        window.location.href = `/tenants/${match.params.tenantId}/customreports/new`;
    }

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
        setWarning(undefined);
    }

    const onCloseOption = () => {
        setAnchorEl(null);
        setCustomreport(undefined);
    };

    const onEdit = () => {
        setAnchorEl(null);
        if (!context.isGrantedAny(['CustomReportUpdate'])) { 
            return;
        }
        pushHistory();
        setCustomreport(customreport);
        history.push(`/tenants/${match.params.tenantId}/customreports/${customreport?.id}/edit`)
    }

    const onDelete = () => {
        if(!customreport) return;
        setAnchorEl(null);
        setOpenDeleteDialog(true);
    }

    const onChangeStatus = () => {
        if(!customreport) return;
        setAnchorEl(null);
        setOpenChangeStatusDialog(true);
    }

    const confirmDelete = () => {
        setOpenDeleteDialog(false);
        setStatus("loading");
        if(!customreport) return;
        deleteCustomReport(match.params.tenantId, customreport.id)
        .then(() => {
            setSuccess(translate("custom_reports.popup.delete.success", { name: customreport.name }) as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
            setCustomreport(undefined);
        });
    }

    const confirmChangeStatus = () => {
        setOpenChangeStatusDialog(false);
        setStatus("loading");
        if(!customreport) return;
        changeStatusCustomReport(match.params.tenantId, customreport.id)
        .then(()=>{
            setSuccess( (customreport.status === "ACTIVATE") 
                ? translate("custom_reports.popup.inactivate.success", { name: customreport.name }) as string 
                : translate("custom_reports.popup.activate.success", { name: customreport.name }) as string );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
            setCustomreport(undefined);
        });
    }

    const columns = [
        {
            name: 'name',
            title: translate("custom_reports.name") as string,
        },
        {
            name: 'columns_names',
            title: translate('custom_reports.columns') as string
        },
        {
            name: 'exportable',
            title: translate('custom_reports.exportable') as string,
            getCellValue: (row: any) => getExportable(row)
        },
        {
            name: 'created_at',
            title: translate('custom_reports.created_at') as string,    
            getCellVale: (row: any) => getCreatedAt(row)      
        },
        {
            name: 'status',
            title: translate('custom_reports.status') as string,
            getCellValue: (row: any) => getStatus(row)
        },
        {
            name: 'menu',
            title: " "
        },
    ];

    return (
        <Surface title={translate("custom_reports.title") as string} 
            subtitle = {data?.tenant?.name}
            icon={<WorkIcon />}
            backButton
            className="PaperPagination"
            titleActions = {
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        <Grid item xs="auto">
                            <Fab onClick={onClickAdd} color="primary" size="small" title={translate("buttons.add") as string} disabled={status === "loading"}>
                                <AddIcon />
                            </Fab>
                        </Grid>
                    </Grid>
            </Grid>
            }>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1} >
                </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns ? columns : []}
                columnsFormat={columnsFormat}
                onClickRow={onClickRow}
                clickRowColumns={clickRowColumns}
                onClickedOptions={onClickedOptions}
                dateColumns={dateColumns}
                leftColumns={leftColumns}
                textColumns={textColumns} 
                filters={filters}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                heightTablePX={500}
                noUseId={true}
                showTimeInDates={true}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {customreport && anchorEl && (
                <CustomReportsMenu 
                    customreport={customreport} anchor={anchorEl}
                    onClose={onCloseOption}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onChangeStatus={onChangeStatus}
                />
            )}
            { openDeleteDialog && customreport && (
                <ConfirmationPopup 
                    title={translate("custom_reports.popup.delete.title") as string}
                    message={translate("custom_reports.popup.delete.description", { name: customreport.name })}
                    onClose={() => setOpenDeleteDialog(false)}
                    doAction={confirmDelete}
                    submitting={deleting}
                    color="secondary"
                    button={translate("buttons.delete") as string} 
                />
            )}
            { openChangeStatusDialog && customreport && (
                <ConfirmationPopup 
                    title={ (customreport.status === "ACTIVE") 
                        ? translate("custom_reports.popup.inactivate.title") as string 
                        : translate("custom_reports.popup.activate.title") as string }
                    message={ (customreport.status === "ACTIVE") 
                        ? translate("custom_reports.popup.inactivate.description", { name: customreport.name }) 
                        : translate("custom_reports.popup.activate.description", { name: customreport.name }) }
                    onClose={() => setOpenChangeStatusDialog(false)}
                    doAction={confirmChangeStatus}
                    submitting={deleting}
                    color="secondary"
                    button={translate("buttons.confirm") as string} 
                />
            )}
        </Surface>
    );
}