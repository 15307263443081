import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { Button, Typography, Grid, IconButton } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { WarningSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { Cfdi, CfdisQueryParams } from "../model/Cfdi";
import { Payment } from "../model/Payment";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClarificationPayableDocumentsDetailSection from './ClarificationPayableDocumentsDetailSection';
import { createClarification } from "../api/ClarificationApi";
import { ClarificationRequest } from "../model/Clarification";
import CustomBackdrop from "../components/CustomBackdrop";
import { getCfdis } from "../api/ProviderCfdiApi";

interface RequestClarificationPopupProps {
    onClose(): any;
    doAction(comment: string): any;
    onError(error: string): any;
    isCommentRequired: boolean;
    cfdis?: Cfdi[];
    payments?: Payment[];
    providerId: string;
}

export default function RequestClarification(props: RequestClarificationPopupProps) {
    const context = useContext(AppContext);
    const [view, setView] = useState<"message" | "items">("message");
    const [status, setStatus] = useState<string>("loading");

    const changeView = (view: "message" | "items") => {
        setView(view);
    };

    const load = () => {
        setStatus("loading");
        if (props.payments && props.payments.find(payment => payment.list_cfdis === undefined || payment.list_cfdis.length === 0)) {
            let cfdis = props.payments.flatMap(payment => payment.cfdis);
            getCfdis(context.session!.tenant!.id, props.providerId, "", 0, 0, { 
                selected_ids: cfdis.map(cfdi => cfdi.id).join(","),
                status_group: ""
             } as CfdisQueryParams).then((response) => {
                props.payments?.forEach(payment => {
                    payment.list_cfdis = response.items.filter(cfdi => {
                        let temp = payment.cfdis.find(paymentCfdi => cfdi.id === paymentCfdi.id);
                        if(temp !== undefined){
                            return true;
                        }
                        return false;
                    });
                });
            }).catch((error) => {
                props.onError(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
        } else {
            setStatus("loaded");
        }
    }

    useEffect(load, [context.session]);

    if(status === "loading"){
        return <CustomBackdrop open={true} message={translate("cfdis.processing") as string} />;
    }

    switch (view){
        case "message":
            return (
                <ClarificationMessage {...props} changeView={changeView}/>
            );
        case "items":
            return (
                <ClarificationItems {...props} changeView={changeView} cfdis={props.cfdis} payments={props.payments} />
            );
        default:
            return (
                <ClarificationMessage {...props} changeView={changeView}/>
            );
    }
}

interface ClarificationMessageProps extends RequestClarificationPopupProps {
    changeView(view: "message" | "items"): any;
}

function ClarificationMessage(props: ClarificationMessageProps){
    const context = useContext(AppContext);
    const [request, setRequest] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(props.isCommentRequired);
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const itemIds = props.cfdis ? props.cfdis.map(item => item.id) : props.payments ? props.payments.map(item => item.id) : [] ;

    const onSubmit = () => {
        if (!request) {
            props.doAction("");
            return;
        }
        setSubmitting(true);
        createClarification(context.session!.tenant!.id, { 
            user_id: context.session!.user.id,
            provider_id: props.providerId,
            document_ids: itemIds,
            message: request,
            type: props.cfdis ? "INVOICES" : props.payments ? "PAYMENTS" : ""
        } as ClarificationRequest).then((response) => {
            props.doAction("Tu aclaración fue enviada. Para darle seguimiento, ve a la sección de “Mensajes” y localiza esta aclaración.");
        }).catch((error) => {
            setError(error.message);
        }).finally(() =>{
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
        setError(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
        if(props.isCommentRequired && (!request || request === "")){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
    };

    const getConfirmText = () => {
        if(props.cfdis){
            return translate("clarifications.request_popup.num_cfdi_selected", { "num": props.cfdis.length }) as string;
        } else if(props.payments){
            return translate("clarifications.request_popup.num_payment_selected", { "num": props.payments.length }) as string;
        }
        return translate("cfdis.reps.empty_state") as string;
    }

    return (
        <DialogPopup open
            title={translate("clarifications.request_popup.title") as string}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={disabled}>
                    {translate("buttons.send") as string}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <IconButton aria-label="options" color="primary" size="small"
                            onClick={() => props.changeView("items")} >
                            <Typography variant="body2">
                                {getConfirmText()}
                            </Typography>
                            <ArrowForwardIcon style={{ "width": "15px", "height": "15px", "padding": "0px"}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {translate("clarifications.request_popup.message") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="comment" name="comment"
                            label={translate("clarifications.request_popup.message_label") as string}
                            value={request}
                            rows={5}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
        </DialogPopup>
    );
}


interface ClarificationItemsProps {
    changeView(view: "message" | "items"): any;
    cfdis?: Cfdi[];
    payments?: Payment[];
}

function ClarificationItems(props: ClarificationItemsProps){

    return (
        <DialogPopup open maxWidth="lg"
            title={translate("clarifications.request_popup.title_items", {"items": props.cfdis ? "Comprobantes" : "Pagos"}) as string}
            closeText={translate("buttons.back") as string}
            closeColor="default"
            onClose={() => props.changeView("message")}>
            <ClarificationPayableDocumentsDetailSection cfdis={props.cfdis} payments={props.payments} />
        </DialogPopup>
    );
}