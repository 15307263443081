import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";
import { Budget, BudgetHistory } from "../model/Budget";
import { Grid, Box } from '@material-ui/core';

export interface BudgetHistoryParams {
    tenantId: string;
    budget: Budget;
}

export default function BudgetHistoryTab(params: BudgetHistoryParams) {

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<BudgetHistory[]>([]);

    const load = () => {
        setStatus("loading");
        setData(params?.budget?.changes_history ?? []);
        setStatus("loaded");
    };

    useEffect(load, [params]);

    return (
        <div>
            <Grid>
                <Box px={2} py={2}>
                    <Gridable
                        items={data}
                        loading={status === "loading"}
                        error={status !== "loading" && status !== "loaded" ? status : undefined}
                        empty={translate("budget.history.empty") as string}
                        columns={[
                            {
                                title: translate("budget.history.columns.modification_date") as string,
                                converter: (history) => (<DateFormat date={history.modification_date} format="DD-MM-YYYY h:mm a" zone={true} />),
                                xs: 3,
                                sm: 3,
                                md: 3,
                                xl: 3
                            },
                            {
                                title: translate("budget.history.columns.event") as string,
                                converter: (history) => (
                                    <Ellipsis
                                        lenght={100} uppercased={false}
                                        text={translate(`budget.history.events.${history.event}`) as string}
                                    />),
                                xs: 2,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("budget.history.columns.user_that_modify") as string,
                                converter: (history) => (<Ellipsis text={history?.user ? history.user.name : "---"} uppercased={false} />),
                                xs: 3,
                                sm: 3,
                                md: 3,
                                xl: 3
                            },
                            {
                                title: translate("budget.history.columns.aditional_information") as string,
                                converter: (history) => (
                                    <Ellipsis
                                        lenght={100} uppercased={false}
                                        text={history.aditional_information}
                                    />),
                                xs: 4,
                                sm: 4,
                                md: 4,
                                xl: 4
                            },
                        ]} />
                </Box>
            </Grid>
        </div>
    )
}