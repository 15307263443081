import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import { ExpedientTemplateParams } from "../model/ExpedientTemplate";
import { listExpedientTemplates } from "../api/ExpedientTemplateAPI";
import ValidatedInput from "../components/ValidatedInput";

interface SelectTemplateReplacePopupProps {
    tenantId: string;
    templateId: string;
    providers_num: number;
    onSave(templateId: string): any;
    onClose(): any;
}

export default function SelectTemplateReplacePopup(props: SelectTemplateReplacePopupProps) {
    const [error, setError] = useState<string>();
    const [data, setData] = useState<string[]>([]);
    const [dataLabels, setDataLabels] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<string>();

    useEffect(() => {
        listExpedientTemplates(props.tenantId, {} as ExpedientTemplateParams,0, 0).then((response) => {
            setData(response.items.filter(item => item.id !== props.templateId).map(item => item.id));
            setDataLabels(response.items.filter(item => item.id !== props.templateId).map(item => item.name));
        }).catch((error) => {
            setError(error.message);
        });
        // eslint-disable-next-line
    }, [props]);

    const onSave = () => {
        if(!request){
            return;
        }
        setSubmitting(true);
        props.onSave(request);
    };

    const onChange = (name: string, value: string) => {
        setRequest(value);
    }

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
    <DialogPopup open
        title={translate("expedient_template.disabled_template.popup_title") as string}
        onClose={props.onClose}
        button={(
            <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={submitting || !request || request === "" || request === "---"}>
                {translate("buttons.save")}
            </Button>
        )}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            {translate("expedient_template.disabled_template.popup_message", {"num_providers": props.providers_num})} <br/>
                            {translate("expedient_template.disabled_template.popup_secondary")}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            <ValidatedInput type="text" id="template_id" name="template_id" margin="dense"
                                value={request} label={translate("expedient_template.disabled_template.popup_input") as string}
                                options={data} optionLabels={dataLabels}
                                required={true} disabled={submitting}
                                onValueChanged={onChange} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}