import React, { useState, useEffect } from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { IcsoeSisubReportRequest } from "../model/IcsoeSisubReport";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";

interface DownloadExportationProps {
    title: string;
    message: string;
    onDownload(request: IcsoeSisubReportRequest): any;
    onClose(): any;
}

export default function DownloadIcsoeSisubPopup(props: DownloadExportationProps) {
    const [request, setRequest] = useState<IcsoeSisubReportRequest>({} as IcsoeSisubReportRequest);
    const [years, setYears] = useState<string[]>([]);
    const [periods, setPeriods] = useState<string[]>([]);
    const [periodLabels, setPeriodLabels] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setSubmitting(true);
        const years = [];
        const today = new Date();

        for (let i = 2021; i <= today.getFullYear(); i++) {
            years.push(`${i}`);
        }

        setPeriods(["1","2","3"]);
        setPeriodLabels(["Enero - Abril","Mayo - Agosto","Septiembre - Diciembre"]);
        setYears(years);
        setSubmitting(false);
    }, []);

    const onChange = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    }

    const download = () => {
        props.onDownload(request);
    }

    const buttonDownload = () => {
        return (
            <Button onClick={download} disabled={!request.year || request.year === "---" || !request.period || request.period === "---"} 
                variant="outlined" color="primary">
                {translate("buttons.download") as string}
            </Button>
        );
    }

    return (
        <DialogPopup open
            title={props.title}
            onClose={props.onClose}
            closeColor="default"
            button={buttonDownload()}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}>
            <Typography variant="body2">
                {props.message}
            </Typography>
            <Grid item xs={12}>
                <ValidatedInput type="text" id="year" name="year"
                    value={request.year+""} label={translate("contributions.year") as string}
                    required={true} disabled={submitting}
                    options={years} optionLabels={years}
                    margin={"dense"}
                    onValueChanged={onChange} />
            </Grid>
            <Grid item xs={12}>
                <ValidatedInput type="text" id="period" name="period"
                    value={request.period+""} label={translate("contributions.periods") as string}
                    required={true} disabled={submitting}
                    options={periods} optionLabels={periodLabels}
                    margin={"dense"}
                    onValueChanged={onChange} />
            </Grid>
        </DialogPopup>
    );
}

