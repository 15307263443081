import React, { useState, useEffect, useContext, ChangeEvent, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, Checkbox, IconButton, Fab } from "@material-ui/core";
import { CatalogsIcon, AddIcon, EditIcon, DeleteIcon, ImportIcon } from "../components/Icons";

import { createCatalog, getCatalog, updateCatalog, importValues, validateCatalogValue } from "../api/CatalogAPI";
import translate from "../i18n/Translator";
import { Catalog, CatalogRequest, CatalogValue } from "../model/Catalog";

import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import Gridable from "../components/Gridable";
import CatalogValueForm from "./CatalogValueForm";
import { emptyPromise } from "../api/API";
import { listProvidersOnlyIdName } from "../api/ProviderAPI";
import { AutocompleteProvider, Providers, ProvidersQueryParams } from "../model/Provider";
import CustomBackdrop from "../components/CustomBackdrop";
import { RegexValidator } from "../components/Validators";
import ConfirmationPopup from "../components/ConfirmationPopup";

type Popup = "edit" | "add";

export default function CatalogForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const catalogId = match.params.catalogId;
    const margin = "dense";
    const externalIdCharactersValidator = new RegexValidator(/^[a-zA-ZñÑ0-9_-]{1,50}$/,"catalogs.validations.external_id");
    const fileInput = useRef<HTMLInputElement>(null);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [popup, setPopup] = useState<Popup>();
    const [providers, setProviders] = useState<AutocompleteProvider[]>([]);
    const [request, setRequest] = useState<CatalogRequest>({
        name: "",
        external_id: "",
        values: []
    } as CatalogRequest);
    const [validations, setValidations] = useState({} as any);
    const [value, setValue] = useState<CatalogValue>();
    const [index, setIndex] = useState<number>();
    const [valueToDelete, setValueToDelete] = useState<CatalogValue>();
    const [uploading, setUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [openConfirmDeleteCatalogValue, setOpenConfirmDeleteCatalogValue] = useState(false);

    const promiseGet = useCallback((): Promise<Catalog | undefined> => {
        if (catalogId) {
            return getCatalog(tenantId, catalogId);
        }
        return emptyPromise();
    }, [tenantId, catalogId])

    const promiseProviders = useCallback((): Promise<Providers> => {
        return listProvidersOnlyIdName(tenantId, 0, 0, { search: "" } as ProvidersQueryParams);
    }, [tenantId])

    const submitPromise = (): Promise<Catalog> => {
        if (catalogId) {
            return updateCatalog(tenantId, catalogId, request);
        }
        return createCatalog(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        Promise.all([
            promiseGet(),
            promiseProviders(),
        ]).then(([catalog, providersResponse]) => {
            const providers = providersResponse.items.map(el => {
                return {
                    value: el.id,
                    title: el.name,
                } as AutocompleteProvider
            });

            setRequest({
                name: catalog?.name ?? "",
                external_id: catalog?.external_id ?? "",
                values: catalog?.values.map(el => {
                    el.provider_name = providers.find(p => p.value === el.provider_id)?.title;
                    return el;
                }) ?? [],
            } as CatalogRequest);


            setProviders(providers);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [promiseGet, promiseProviders]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((catalog) => {
            history.push("/catalogs");
        }).catch((error) => {
            if(error.code == "7004") {
                setWarning(error.message);
            } else {
                setError(error.message);
            }
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const onChangeActive = (catalogValue: CatalogValue) => (event: ChangeEvent, checked: boolean) => {
        const value = request?.values.find(val => val.id === catalogValue.id);
        if (!value) return;
        value.active = checked;
        const newValues = request.values.map((el, i) => {
            if (el.id === value.id) {
                return value;
            }
            return el;
        })

        setRequest({ ...request, values: newValues });
    };

    const onClosePopup = () => {
        setPopup(undefined);
        setValue(undefined);
        setIndex(undefined);
    }

    const onCloseConfirmDeleteCatalogValue = () => {
        setOpenConfirmDeleteCatalogValue(false);
        setValueToDelete(undefined);
    };

    const deleteCatalogValue = (valueToDelete: CatalogValue) => {
        removeValue(valueToDelete);
        onCloseConfirmDeleteCatalogValue();
    }

    const showPopup = (popup: Popup) => (index: number, value?: CatalogValue) => () => {
        setPopup(popup);
        setIndex(index);
        setValue(value);
    }

    const onAdd = showPopup("edit")

    const onEdit = showPopup("edit")

    const removeValue = (valueToDelete: CatalogValue) => {
        const newValues = request.values.filter((el, i) => el.external_id !== valueToDelete.external_id);
        setRequest({ ...request, values: newValues });
    }

    const onDelete = (index: number, catalogValue: CatalogValue) => () => {
        if (catalogId && catalogValue.id) {
            setDeleting(true);
            validateCatalogValue(tenantId, catalogId, catalogValue.id).then(response => {
                if (response.in_use) {
                    setValueToDelete(catalogValue);
                    setOpenConfirmDeleteCatalogValue(true);
                } else {
                    removeValue(catalogValue);
                }
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setDeleting(false);
            });
        } else {
            removeValue(catalogValue);
        }
    }

    const onUpdatedValue = (value: CatalogValue) => {
        if (index === undefined) return;

        let newValues;
        if (index === -1) {
            if (request.values.find(el => el.external_id === value.external_id)) {
                setWarning(translate("catalogs.external_id_duplicated") as string);
                return;
            }
            newValues = [...request.values, value];
        } else {
            if (request.values.find(el => el.id !== value.id && el.external_id === value.external_id)) {
                setWarning(translate("catalogs.external_id_duplicated") as string);
                return;
            }
            newValues = request.values.map((el, i) => {
                if (el.id === value.id) {
                    return value;
                }
                return el;
            });
        }

        setRequest({ ...request, values: newValues });
        onClosePopup();
    }

    const openFileImport = () => {
        fileInput.current!.click();
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setUploading(true);
        importValues(context.session!.tenant!.id, event.target.files[0], catalogId).then((response) => {
            const existing = {} as any;
            request?.values?.forEach(value => {
                existing[value.external_id] = true;
            });

            const filtered = response.items.filter(el => !existing[el.external_id])
            if (filtered.length > 0) {
                for (const item of filtered) {
                    if (item.provider_id) {
                        const provider = providers.find(el => el.value === item.provider_id);
                        if (provider) {
                            item.provider_name = provider.title;
                        }
                    }
                }

                setRequest({ ...request, values: [...request.values, ...filtered] });
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setUploading(false);
            fileInput.current!.value = "";
        });
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={12} lg={10} xl={8}>
                    <Surface title={translate(catalogId ? "catalogs.edit" : "catalogs.new")} icon={<CatalogsIcon />} titleActions={(
                        <Fab color="primary" size="small" title={translate("importable.file") as string} onClick={openFileImport}>
                            <ImportIcon />
                        </Fab>
                    )} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("catalogs.name") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="externalId" name="external_id"
                                        value={request.external_id} maxLength={50}
                                        label={translate("catalogs.external_id") as string}
                                        required disabled={submitting || catalogId}
                                        margin={margin} validator={externalIdCharactersValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={2} mx={-2}>
                                        <Gridable
                                            items={request?.values.filter(value => !value.deleted_in_use) ?? []}
                                            loading={false}
                                            empty={translate("catalogs.values.empty") as string}
                                            columns={[
                                                {
                                                    title: (
                                                        <Grid container alignItems="center">
                                                            <Grid item xs="auto">
                                                                <Checkbox style={{ visibility: "hidden" }} />
                                                            </Grid>
                                                            <Grid item>
                                                                <strong>{translate("catalogs.values.name")}</strong>
                                                            </Grid>
                                                        </Grid>
                                                    ),
                                                    converter: (value, index) => (
                                                        <Grid container alignItems="center">
                                                            <Grid item xs="auto">
                                                                <Checkbox checked={value.active} onChange={onChangeActive(value)} />
                                                            </Grid>
                                                            <Grid item>
                                                                {value.name}
                                                            </Grid>
                                                        </Grid>
                                                    ),
                                                    fullWidth: true,
                                                    xs: true
                                                },
                                                {
                                                    title: translate("catalogs.values.external_id") as string,
                                                    converter: (value) => value.external_id || "---",
                                                    fullWidth: true,
                                                    xs: 3,
                                                    sm: 3,
                                                    md: 3,
                                                    lg: 3,
                                                    xl: 3,
                                                },
                                                {
                                                    title: translate("app.provider") as string,
                                                    converter: (value, index) => value.provider_name || "---",
                                                    fullWidth: true,
                                                    xs: 3,
                                                    sm: 3,
                                                    md: 3,
                                                    lg: 3,
                                                    xl: 3,
                                                },
                                                {
                                                    title: (
                                                        <>
                                                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                                                <AddIcon />
                                                            </IconButton>
                                                            <IconButton size="small" color="primary" onClick={onAdd(-1)}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </>
                                                    ),
                                                    converter: (value, index) => (
                                                        <>
                                                            <IconButton aria-label="edit" color="primary" size="small" onClick={onEdit(index, value)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" size="small" onClick={onDelete(index, value)}>
                                                                <DeleteIcon color="error" />
                                                            </IconButton>
                                                        </>
                                                    ),
                                                    xs: "auto"
                                                }
                                            ]} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(catalogId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />

            <input
                type="file"
                onChange={handleUpload}
                ref={fileInput}
                style={{ display: "none" }}
                accept=".xlsx,.xls" />

            <CustomBackdrop
                open={uploading}
                message={translate("providers.importing") as string} />

            <CustomBackdrop open={deleting} />

            {(popup === "add" || popup === "edit") && (
                <CatalogValueForm
                    value={value}
                    providers={providers}
                    onUpdated={onUpdatedValue}
                    onCancel={onClosePopup} />
            )}
            {openConfirmDeleteCatalogValue && (
                            <ConfirmationPopup
                                title={translate("catalogs.delete_value") as string}
                                message={translate("catalogs.in_use", {"valor": valueToDelete!.name}) as string}
                                onClose={onCloseConfirmDeleteCatalogValue}
                                button={translate("buttons.continue") as string}
                                doAction={() => deleteCatalogValue(valueToDelete!)} />
            )}
        </Grid>
    );
}