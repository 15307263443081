import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, Card, ListSubheader } from "@material-ui/core";
import translate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat";
import { CfdiRaw } from "../model/Cfdi";

interface CfdiGeneralsProps {
    cfdi: CfdiRaw;
}

export default function CfdiGenerals(props: CfdiGeneralsProps) {
    const [serieFolio, setSerieFolio] = useState<string>("");

    useEffect(() => {
        setSerieFolio([props.cfdi.serie, props.cfdi.folio].filter(el => !!el).join(" "));
    }, [props.cfdi]);

    return (
        <Card variant="outlined" >
            <List component="nav" dense subheader={
                <ListSubheader disableSticky>
                    {translate(`cfdis.details.type.${props.cfdi.tipo_comprobante}`)}
                </ListSubheader>
            }>
                <ListItem>
                    <ListItemText primary={serieFolio} secondary={translate("cfdis.details.serie_folio")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={formatDate(props.cfdi.fecha_emision, "ll")} secondary={translate("cfdis.details.date_emitted")} />
                </ListItem>
                {
                    props.cfdi.tipo_comprobante !== 'P' && (
                        <ListItem >
                            <ListItemText primary={translate(`cfdis.details.payment_type_code.${props.cfdi.forma_pago}`)} secondary={translate("cfdis.details.payment_type")} />
                        </ListItem>
                    )
                }
                {
                    props.cfdi.tipo_comprobante !== 'P' && (
                        <ListItem>
                            <ListItemText primary={translate(`cfdis.details.payment_method_code.${props.cfdi.metodo_pago}`)} secondary={translate("cfdis.details.payment_method")} />
                        </ListItem>
                    )
                }
                <ListItem>
                    <ListItemText primary={props.cfdi.version} secondary={translate("cfdis.details.version")} />
                </ListItem>
            </List>
        </Card>
    );
}