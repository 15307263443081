import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import ContractIcon from "@material-ui/icons/Description";

import { createContract, getContract, updateContract, getDocumentUrl } from "../api/ContractAPI";
import { getCustomer } from "../api/CustomerAPI";
import translate from "../i18n/Translator";
import { Contract, ContractRequest, CONTRACT_TYPES } from "../model/Contract";
import { Customer } from "../model/Customer";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import UploaderInput from "../components/UploaderInput";
import SimpleSwitch from "../components/SimpleSwitch";

export default function ContractForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const hasCustomers = context.isGranted("CustomersRead");

    const typeLabes = CONTRACT_TYPES.map(el => translate(`contracts.type.${el}`) as string);
    const history = useHistory();
    const isEdit = !!match.params.contractId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<ContractRequest>({} as ContractRequest);
    const [validations, setValidations] = useState({} as any);
    const [customer, setCustomer] = useState<Customer>();
    const [documentUrl, setDocumentUrl] = useState<string>();

    const submitPromise = (): Promise<Contract> => {
        if (isEdit && match.params.contractId) {
            return updateContract(tenantId, match.params.customerId, match.params.contractId, request);
        }
        return createContract(tenantId, match.params.customerId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            getContract(tenantId, match.params.customerId, match.params.contractId).then((contract) => {
                setRequest({
                    customer_id: contract.customer_id,
                    description: contract.description,
                    sisub: contract.sisub,
                    type: contract.type,
                    document: contract.document,
                    icsoe: contract.icsoe,
                    from: contract.from ? moment(contract.from).format("DD/MM/YYYY") : "",
                    to: contract.to ? moment(contract.to).format("DD/MM/YYYY") : "",
                    created_at: contract.created_at,
                    date_undetermined: contract.date_undetermined,
                    amount: contract.amount
                } as ContractRequest);

                if (contract.document) {
                    getDocumentUrl(tenantId, match.params.customerId, match.params.contractId).then(url => {
                        setDocumentUrl(url);
                    }).catch(error => {
                    });
                }

                setCustomer(contract.customer);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else if (hasCustomers) {
            getCustomer(tenantId, match.params.customerId).then(response => {
                setCustomer(response);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    }, [tenantId, hasCustomers, isEdit, match.params.customerId, match.params.contractId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date, inputRef?: InputRef) => {
        if (raw) {
            setRequest({ ...request, [name]: raw })
        }

        if (inputRef) {
            validations[name] = inputRef;
            setValidations(validations);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((contract) => {
            history.goBack();
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    const switchUploadSipare = () => {
        if(request.date_undetermined){
            setRequest({ ...request, date_undetermined: false });
        } else {
            setRequest({ ...request, date_undetermined: true, to: "" });
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "contracts.edit" : "contracts.new")}
                        subtitle={customer?.name}
                        icon={<ContractIcon />} className="FormSurface" backButton>
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="description" name="description"
                                        value={request.description}
                                        label={translate("contracts.description") as string}
                                        required disabled={submitting}
                                        margin={margin} maxLength={500}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="sisub" name="sisub"
                                        value={request.sisub}
                                        label={translate("contracts.sisub") as string}
                                        required disabled={submitting}
                                        margin={margin} maxLength={100}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="number" id="amount" name="amount"
                                        value={request.amount+""}
                                        label={translate("contracts.amount") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="type" name="type"
                                        value={request.type}
                                        label={translate("contracts.type.title") as string}
                                        options={CONTRACT_TYPES} optionLabels={typeLabes}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <UploaderInput id="document" name="document"
                                        value={documentUrl || request.document}
                                        label={translate("contracts.document") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        acceptExtension=".pdf" path={`tenants/${tenantId}/contracts`}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="icsoe" name="icsoe"
                                        value={request.icsoe}
                                        label={translate("contracts.icsoe") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        rows={4} maxRows={10} maxLength={2000}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleSwitch
                                        value="date_undetermined"
                                        label={translate("contracts.date_undetermined") as string}
                                        checked={request.date_undetermined}
                                        onChanged={switchUploadSipare}
                                        placement="end"
                                        disabled={submitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <DatePicker name="from"
                                                label={translate("contracts.from") as string}
                                                initial={request.from} format="DD/MM/YYYY"
                                                clearable
                                                defaultIfMissing
                                                autoOK disableFuture={false}
                                                required disabled={submitting}
                                                onChange={onChangedDate} />
                                        </Grid>
                                        
                                        <Grid item xs={6}>
                                            <DatePicker name="to"
                                                label={translate("contracts.to") as string}
                                                initial={request.to} format="DD/MM/YYYY"
                                                clearable
                                                defaultIfMissing
                                                autoOK disableFuture={false}
                                                required={!request.date_undetermined}
                                                disabled={submitting || request.date_undetermined}
                                                onChange={onChangedDate} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}