import { SpecializedServicesEmployee, PrepareCfdiEmployeeRequest } from "./CfdiSe";

export interface EmployeeVisit {
    id: string;
    description: string;
    start_date: Date;
    end_date: Date;
    employees_response: SpecializedServicesEmployee[];
    supervisor_name: string;
    supervisor_email: string;
    status: string;
}

export interface EmployeeVisitQueryParams {
    statuses: string[];
}

export interface EmployeeVisitRequest {
    description: string;
    start_date: Date;
    end_date: Date;
    employees: PrepareCfdiEmployeeRequest[];
    supervisor_name: string;
    supervisor_email: string;
}

export interface EmployeeVisits {
    items: EmployeeVisit[],
    total: number;
}

export interface SendExecutionRequest {
    comment: string;
}

export const StatusesFilterVisit = ["ACTIVE", "FINALIZED", "ALL"]