import React, { useState, useContext, useEffect } from "react";
import { Button, IconButton, Typography, Grid, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import { TenantSegmentsRequest, TenantField } from "../model/TenantFieldCatalog";
import { Expiration } from "../model/Expiration";
import { GTZeroValidator } from "../components/Validators";
import { AppContext } from "../context/AppContext";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DeleteIcon from "@material-ui/icons/Delete";

interface TemplateSettingsFieldsProps {
    segments?: any;
    onAdd?(): any;
    onGetPriority?(fieldId: string): any;
    onUpdatePriority?(value: number, fieldId: string): any;
    onDelete?(id: string): any;
}

export default function TemplateSettingsFields (props: TemplateSettingsFieldsProps) {
    const context = useContext(AppContext);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [data, setData] = useState<TenantSegmentsRequest<TenantField>>({} as TenantSegmentsRequest<TenantField>);
    const gtZeroValidator = new GTZeroValidator();

    useEffect(() => {
        if(props.segments) {
            let temp = [] as any;
            Object.keys(props.segments).forEach(key => {
                temp.push(props.segments[key]);
            });
            setData({ segments: temp } as TenantSegmentsRequest<TenantField>)
        }
    }, [props]);

    const onDelete = (fieldId: string) => () => {
        if(props.onDelete){
            props.onDelete(fieldId);
        }
    };

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    const getExpiration = (expiration?: Expiration) => {
        if (!expiration) return "---";
        return translate(`expedients.fields.units.${expiration.unit}${expiration.quantity === 1 ? "" : "S"}`, { "quantity": expiration.quantity }) as string;
    };

    const hasChanged = (id: string, value: string, inputRef: InputRef) => {
        if(props.onUpdatePriority){
            props.onUpdatePriority(Number(value), id);
        }
    };

    const getFieldPriority = (fieldId: string) => {
        if(props.onGetPriority && fieldId){
            return props.onGetPriority(fieldId);
        }
        return "";
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={2}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle1">{translate("extended_fields_templates.create_edit.components.settings_fields.title")}</Typography>
                        </Grid>
                        {context.isGranted("ExtendedFieldsTemplateUpdate") && (
                            <Grid item>
                                <Button aria-label="add" variant="text" color="secondary" size="small" startIcon={<AddIcon />} onClick={props.onAdd}>
                                    {translate("buttons.add_field")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={data.segments ? data.segments : []}
                    loading={false}
                    empty={translate("extended_fields_templates.create_edit.components.settings_fields.table.empty") as string}
                    columns={[
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.priority") as string,
                            converter: (field) => (
                                    <ValidatedInput
                                        type="number"
                                        id={field.id}
                                        name={field.id}
                                        value={getFieldPriority(field.id)}
                                        isValueNumber={true}
                                        validators={[gtZeroValidator]}
                                        label={""}
                                        required={true}
                                        margin="dense"
                                        onValueChanged={hasChanged}
                                    />
                            ),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            lg: 1,
                        },
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.name") as string,
                            converter: (field) => (
                                <div>
                                    <Ellipsis text={field.name} lenght={0} uppercased={false} />
                                    <br />
                                    <Ellipsis text={translate(field.required ? "extended_fields_templates.create_edit.components.settings_fields.converter.required" : "extended_fields_templates.create_edit.components.settings_fields.converter.optional") as string} lenght={0} uppercased={false} secondary />
                                </div>
                            ),
                            xs: 4,
                            sm: 4,
                            md: 4,
                            lg: 3,
                        },
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.type") as string,
                            converter: (field) => (
                                <div>
                                    <Ellipsis text={translate(`extended_fields_templates.create_edit.components.pop_up.table.values.type.${field.type}`) as string} lenght={0} uppercased={false} />
                                    {field.type === "FILE" && field.extensions && (<br />)}
                                    {field.type === "FILE" && field.extensions && (
                                        <Ellipsis text={field.extensions.split(",").map((entry) => entry.trim()).join(", ")} lenght={0} uppercased={false} secondary />
                                    )}
                                </div>
                            ),
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.file_upload") as string,
                            converter: (field) => field.aux_file_url && field.aux_file_url.length > 0 ? "Si" : "No",
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.private") as string,
                            converter: (field) => field.privado ? "Si" : "No",
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.description") as string,
                            converter: (field) => field.description || "---",
                            xs: false,
                            sm: false,
                            md: false,
                            lg: 3,
                        },
                        {
                            title: translate("extended_fields_templates.create_edit.components.settings_fields.table.columns.expiration") as string,
                            converter: (field) => getExpiration(field.expiration),
                            xs: false,
                            sm: false,
                            md: false,
                            lg: true,
                        },
                        {
                            title: "",
                            converter: (field: TenantField, index: number) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onDelete(field.id)}
                                >
                                    <DeleteIcon fontSize="small" color="inherit" />
                                </IconButton>
                            ),
                            justify: "flex-end",
                            xs: true
                        },
                    ]}
                />
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            </Grid>
        </Grid>
    );
}
