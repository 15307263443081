import { APIParameters, callAPI, HTTPMethod, noContent } from './API';
import { SecureUrlResponse } from '../model/Connector';

export function upload(fileKey: string, file: File, props: UploadCallback, uploadInTemp?: boolean): void {
    if (!fileKey || !file) return;

    let contentType = file.type == null || file.type == undefined || file.type === "" ? "text/plain" : file.type;
    getUploadUrl(fileKey, contentType, uploadInTemp).then((response) => {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": contentType
            },
            body: file,
        };

        callExternApi(response.url, requestOptions)
            .then(() => props.onSuccessUpload(response.url_preview ?? response.url))
            .catch((error) => {
                props.onError(error.message);
            });

    }).catch((error) => props.onError(error.message))
        .finally(() => props.onFinally());
}

export async function callExternApi(urlPath: string, requestOptions: any): Promise<any> {
    const response = await fetch(urlPath, requestOptions);
    if (response.status === 204 || response.ok) {
        return await noContent();
    }
    throw new Error(await response.text());
}

export async function getUploadUrl(fileKey: string, fileType: string, uploadInTemp?: boolean): Promise<SecureUrlResponse> {
    return await callAPI(`/presign-url`, {
        method: HTTPMethod.GET,
        query: {
            fileKey: fileKey,
            temp: uploadInTemp ? "true" : "false",
            type: fileType
        }
    });
}

export function getUUID() {
    return (String(1e7) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            Number(c) ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
        ).toString(16)
    );
}


export default interface UploadCallback {
    onError(error: string): void;
    onSuccessUpload(url: string): void;
    onFinally(): void;
}