import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory, Redirect } from 'react-router-dom';
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDate } from "../components/DateFormat";
import { FormControl, Select, Box, Grid, IconButton } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import ProviderExpedientMenu from "./ProviderExpedientMenu";
import { Entity, ProvidersQueryParams, Providers, PROVIDER_EXPEDIENT_STATUS, AssignValidatorResponse } from '../model/Provider';
import { getProviders, exportExpedientProviders, exportExpedientSelectedProviders } from "../api/ProviderAPI";
import { listCompanies } from "../api/CompanyAPI";
import { Company, CompaniesQueryParams } from "../model/Company";
import { listExpedientTemplates } from "../api/ExpedientTemplateAPI";
import { ExpedientTemplateParams, ExpedientTemplate } from "../model/ExpedientTemplate";
import Ellipsis from "../components/Ellipsis";
import { MoreVertIcon, ProvidersIcon } from "../components/Icons";
import AssignValidatorPopup from "./AssignValidatorPopup";
import ChangeTemplatePopup from "../expedient_template/ChangeTemplateExpedientPopup";
import ProvidersExpedientMenu from "./ProvidersExpedientMenu";
import translator from "../i18n/Translator";
import { AssignExpedientTemplateRequest } from "../model/ExpedientTemplate";
import { changeExpedientTemplate } from "../api/ExpedientTemplateAPI";
import ConnectorObjectExporter, { ExportMode } from "../connectors/ConnectorObjectExporter";
import { ExportResponse, Family } from "../model/Connector";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";

export default function ProvidersExpedientsView() {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ProvidersQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "not_located_status": "",
            "efos_status": "",
            "status_expedient": typeof qs["status_expedient"] === "string" ? qs["status_expedient"] as string : "",
            "classification": typeof qs["classification"] === "string" ? qs["classification"] as string : "",
            "validator_user_id": typeof qs["validator_user_id"] === "string" ? qs["validator_user_id"] as string : "",
            "receptor": "",
            "specific_rule": "",
            "status": "ENABLED",
            "workflow_id": "",
            "template_id": typeof qs["template_id"] === "string" ? qs["template_id"] as string : "",
            "company_id": typeof qs["company_id"] === "string" ? qs["company_id"] as string : "",
        } as ProvidersQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Providers>();
    const [provider, setProvider] = useState<Entity>();
    const [params, setParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openChangeTemplateExpedient, setOpenChangeTemplateExpedient] = useState<boolean>();
    const [redirect, setRedirect] = useState<string>();

    const [validatorPopup, setValidatorPopup] = useState(false);
    const [selectedProviderIds, setSelectedProviderIds] = useState<(number | string)[]>([]);
    const [selectedProviderTemplates, setSelectedProviderTemplates] = useState<string[]>([]);
    const [queryParam, setQueryParam] = useState<string>();
    const isValidator = !context.isGranted("TenantFieldsCreate");
    const [exportMode, setExportMode] = useState<ExportMode>();
    const [exportResult, setExportResult] = useState<ExportResponse>();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [templates, setTemplates] = useState<ExpedientTemplate[]>([]);

    const paramsToFilters = (): Filter[] => {
        return [
            { columnName: 'provider_name', value: params.search },
            { columnName: 'status_expedient', value: params.status_expedient },
            { columnName: 'classification', value: params.classification },
            { columnName: 'validator_column', value: params.validator_user_id },
            { columnName: 'template_name', value: params.template_id },
            { columnName: 'company_names', value: params.company_id },
        ] as Filter[];
    };

    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
        { columnName: 'company_names', wordWrapEnabled: true },
        { columnName: 'template_name' },
        { columnName: 'validator_column', width: 150 },
        { columnName: 'status_expedient', wordWrapEnabled: true, width: 150 },
        { columnName: 'classification', wordWrapEnabled: true, width: 150 },
        { columnName: 'status', wordWrapEnabled: true, width: 100 },
        { columnName: 'country', wordWrapEnabled: true, width: 100 },
        { columnName: 'menu', width: 50 },
    ]) as any;

    const load = () => {
        setStatus("loading");
        setSelectedProviderIds([]);
        setSelectedProviderTemplates([]);
        pushHistory();

        Promise.all([
            getProviders(context.session!.tenant!.id, pageSize, pageSize * page, params),
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams),
            listExpedientTemplates(context.session!.tenant!.id, {} as ExpedientTemplateParams,0, 0),
        ]).then(responses => {
            const providersResponse = responses[0];
            const companiesResponse = responses[1];
            const templatesResponse = responses[2];

            setData(providersResponse);
            setCompanies(companiesResponse.items);
            setTemplates(templatesResponse.items);

            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                if (filter.value !== undefined) {
                    switch (filter.columnName) {
                        case "provider_name":
                            temp.search = filter.value;
                            break;
                        case "status_expedient":
                            temp.status_expedient = filter.value;
                            break;
                        case "classification":
                            temp.classification = filter.value;
                            break;
                        case "validator_column":
                            temp.validator_user_id = filter.value;
                            break;
                        case "template_name":
                            temp.template_id = filter.value;
                            break;
                        case "company_names":
                            temp.company_id = filter.value;
                            break;
                        default: break;
                    }
                }
            }
        });
        setParams(temp);
    };


    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = params.search;
        qs["validator_user_id"] = params.validator_user_id;
        qs["status_expedient"] = params.status_expedient;
        qs["classification"] = params.classification;
        qs["template_id"] = params.template_id;
        qs["company_id"] = params.company_id;
        qs["page"] = page + "";
        setQueryParam(queryString.stringify(qs));
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const expedientStatusColumn = (provider: Entity): React.ReactNode => {
        if (provider.expedient) {
            if (provider.expedient.status) {
                return (
                    <div>
                        <Ellipsis text={translate(`expedients.status_metadata_short.${provider.expedient.status}`) as string} lenght={0} uppercased={false} />
                        {provider.expedient.approved && (<br />)}
                        {provider.expedient.approved && (<Ellipsis text={formatDate(provider.expedient.approved, "lll")} lenght={0} uppercased={false} secondary />)}
                    </div>
                );
            }

            if (provider.expedient.has_data) {
                return translate("expedients.status_metadata_short.TO_SEND");
            }
        }
        return "---";
    };

    const getCompanyNames = (entity: Entity): string => {
        if (entity.company_objects) {
            return entity.company_objects.map((c) => c.name).reverse().join(", ");
        }
        return "---";
    }

    const columns = [
        {
            name: 'provider_name',
            title: translate("providers.name") as string,
            getCellValue: (provider: any) =>
            (
                <div onClick={() => onClickedRow(provider)} className={"GridableRowClickable"}>
                    <Grid item xs={12}>
                        <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                            <Grid item xs>
                                <Ellipsis align="left" text={provider.name} lenght={100} /><br />
                                <Ellipsis align="left" text={`${provider.rfc} | ${translate(`providers.status.${provider.status}`)}`} lenght={0} secondary uppercased={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )
        },
        {
            name: 'company_names',
            title: translate("providers.companies.title") as string,
            getCellValue: (entity: any) => getCompanyNames(entity) || "---",
        },
        {
            name: 'template_name',
            title: translate('providers.template_expedient.title') as string,
            getCellValue: (provider: any) => provider.expedient_template_name || "---",
        },
        {
            name: 'validator_column',
            title: translate('providers.validator_column') as string,
            getCellValue: (provider: any) => provider.validator_user?.name || "---",
        },
        {
            name: 'classification',
            title: translate('providers.classification') as string,
            getCellValue: (provider: Entity) => provider?.provider_classifications?.map(c => c.name).join(", ") || "---",
        },
        {
            name: 'status_expedient',
            title: translate("providers.template_expedient.title_status") as string,
            getCellValue: expedientStatusColumn
        },
        {
            name: 'country',
            title: translate("providers.country") as string,
            converter: (provider: any) => provider.country ? translate(`countries.${provider.country}`) : "---",
        },
        {
            name: 'menu',
            title: " "
        }
    ];


    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const textColumns = ['provider_name', 'company_names', 'template_name', "validator_column", "status_expedient", "country", "classification"];

    const ExpedientStatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("all") as string}</option>
                        {PROVIDER_EXPEDIENT_STATUS.map((value) => {
                            return <option key={value} value={value}>{translate("expedients.status_metadata_short." + value) as string}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const AdminFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }} disabled={(data?.validators || []).length === 0}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(data?.validators ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const ClassificationFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(data?.classifications ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const CompaniesFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(companies ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const TemplatesFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(templates ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const customPlugins = [
        <NormalTypeProvider for={["country"]} editorComponent={NoFilterEditor} />,
        <NormalTypeProvider for={['status_expedient']} editorComponent={ExpedientStatusFilterEditor} />,
        <NormalTypeProvider for={['validator_column']} editorComponent={AdminFilterEditor} />,
        <NormalTypeProvider for={['classification']} editorComponent={ClassificationFilterEditor} />,
        <NormalTypeProvider for={['company_names']} editorComponent={CompaniesFilterEditor} />,
        <NormalTypeProvider for={['template_name']} editorComponent={TemplatesFilterEditor} />,
    ];

    const dateColumns = ['start_date'];

    const clickRowColumns = [] as [];

    const onClickedRow = (provider: Entity) => {
        pushHistory();
        setRedirect(`/providers/${provider.id}?referer=expedients&tab=validation&${queryParam}`);
    };

    const onClickedOptions = (entity: Entity) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setProvider(entity);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setProvider(undefined);
    };


    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedProviderIds(selected);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onAssignedValidator = (response: AssignValidatorResponse) => {
        setGridAnchorEl(null);
        setValidatorPopup(false);

        if (selectedProviderIds.length > 1) {
            data!.items.filter(provider => {
                return selectedProviderIds.indexOf(provider.id) >= 0;
            }).forEach(provider => provider.validator_user = response.validator);
            setSuccess(translator("providers.assign_validator.success_multiple", { "validator": response.validator.name, "updated": response.items }) as string);
        } else {
            let updated = data!.items.find(provider => provider.id === selectedProviderIds[0]);
            if (updated) {
                updated.validator_user = response.validator;
            }
            setSuccess(translate("providers.assign_validator.success_single", { "validator": response.validator.name, "provider": updated?.name || "---" }) as string);
        }
        setSelectedProviderIds([]);
        setSelectedProviderTemplates([]);
    };


    const onChangeExpedientTemplate = (request: string) => {
        if (!selectedProviderIds) {
            return;
        }
        setOpenChangeTemplateExpedient(false);
        setStatus("loading");
        changeExpedientTemplate(context.session!.tenant!.id,
            { template_id: request, provider_ids: selectedProviderIds } as AssignExpedientTemplateRequest).then((_) => {
                setSelectedProviderIds([]);
                setSelectedProviderTemplates([]);
                load();
                setSuccess(translate("providers.template_expedient.success") as string);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
    }

    const onCloseChangeTemplateExpedient = () => {
        setSelectedProviderIds([]);
        setSelectedProviderTemplates([]);
        setOpenChangeTemplateExpedient(false);
    };

    const onAssignValidator = () => {
        setGridAnchorEl(null);
        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            setValidatorPopup(true);
        }
    }
    const onCloseUserValidator = () => {
        setGridAnchorEl(null);
        setValidatorPopup(false);
    };

    const onOpenChangeTemplateExpedient = () => {
        setGridAnchorEl(null);
        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            setOpenChangeTemplateExpedient(true);
        }
    }

    const onAddValidator = () => {
        if (!provider) return;
        setAnchorEl(null);
        setSelectedProviderIds([provider.id]);
        setSelectedProviderTemplates([provider.expedient_template_name ? provider.expedient_template_name : ""]);
        setValidatorPopup(true);
    };

    const onChangeTemplate = () => {
        if (!provider) return;
        setAnchorEl(null);
        setSelectedProviderIds([provider.id]);
        setSelectedProviderTemplates([provider.expedient_template_name ? provider.expedient_template_name : ""]);
        setOpenChangeTemplateExpedient(true);
    };


    const onExportWithParams = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setExportMode('PARAMS');
        } else {
            setWarning(translate("providers.empty_export") as string);
        }
    }

    const onExportSelected = () => {
        setGridAnchorEl(null);
        if (selectedProviderIds.length) {
            setExportMode('SELECTED');
        } else {
            setWarning(translate("providers.no_providers") as string);
        }
    }

    const downloadProvidersWithConnector = (connectorId: string) => {
        if (!exportMode) return;

        let promise: Promise<ExportResponse>;
        if (exportMode === 'PARAMS') {
            promise = exportExpedientProviders(context.session!.tenant!.id, connectorId, params, true);
        } else {
            promise = exportExpedientSelectedProviders(context.session!.tenant!.id, connectorId, selectedProviderIds as string[], true);
        }

        promise.then((response) => {
            if (response.url) {
                setExportResult(response);
            } else if (response.total) {
                setSuccess(translate("providers.email_export", { "total": response.total }) as string);
            } else {
                setWarning(translate("providers.empty_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(onCloseExporter);
    }

    const onCloseExporter = () => {
        setExportMode(undefined);
    };

    const onCloseDownloadProviders = () => {
        setExportResult(undefined);
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Surface title={translate("providers.title")}
            icon={<ProvidersIcon />}
            subtitle={translate("providers.views.expedients")}
            className="PaperPagination"
            titleActions={context.isGrantedAny(["ProvidersUpdate", "ValidationExpedientUpdate"]) ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>) : undefined
            }>
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns}
                columnsFormat={columnsFormat}
                dateColumns={dateColumns}
                textColumns={textColumns}
                filters={filters}
                customFormatColumns={customPlugins}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                onClickRow={onClickedRow}
                onClickedOptions={onClickedOptions}
                clickRowColumns={clickRowColumns}
                selectionIds={selectedProviderIds}
                setSelectionHandler={setSelectionHandler}
            />

            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />

            {provider && anchorEl && (
                <ProviderExpedientMenu
                    provider={provider}
                    anchor={anchorEl}
                    backTo={queryParam}
                    onClose={onCloseOption}
                    onAssignValidator={onAddValidator}
                    onOpenChangeTemplateExpedient={onChangeTemplate}
                />
            )}

            {validatorPopup && (
                <AssignValidatorPopup tenantId={context.session!.tenant!.id}
                    providers={selectedProviderIds as string[]}
                    onSuccess={onAssignedValidator}
                    onClose={onCloseUserValidator} />
            )}
            {openChangeTemplateExpedient && (
                <ChangeTemplatePopup tenantId={context.session!.tenant!.id}
                    providers={selectedProviderIds as string[]}
                    currentTemplates={selectedProviderTemplates}
                    onSave={onChangeExpedientTemplate}
                    onClose={onCloseChangeTemplateExpedient} />
            )}
            {gridAnchorEl && (
                <ProvidersExpedientMenu anchor={gridAnchorEl}
                    totalItems={data?.total}
                    onAssignValidator={onAssignValidator}
                    onChangeTemplateExpedient={onOpenChangeTemplateExpedient}
                    onExportWithParams={onExportWithParams}
                    onExportSelected={onExportSelected}
                    onClose={() => setGridAnchorEl(null)}
                />
            )}
            {exportMode && (
                <ConnectorObjectExporter tenantId={context.session!.tenant!.id}
                    family={Family.PROVIDERS_EXPEDIENT}
                    type={"DATA"}
                    onExport={downloadProvidersWithConnector}
                    onClose={onCloseExporter}
                />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup title={translate("providers.download_providers") as string}
                    message={translate("providers.providers_found", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadProviders} />
            )}
        </Surface>
    );
}
