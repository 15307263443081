import { callAPI, HTTPMethod } from "./API";
import { Billings, Billing, BillingMetadata, BillingSubscriptionRequest, BillingManualPaymentRequest, BillingUpgradeRequest } from "../model/Billing";

export async function getTenantBillings(tenantId: string, page: number, offset: number): Promise<Billings> {
    return await callAPI(`/tenants/${tenantId}/billings`, {
        method: HTTPMethod.GET,
        query: {
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getBillingMetadata(tenantId: string): Promise<BillingMetadata> {
    return await callAPI(`/tenants/${tenantId}/billings/subscribe`, {
        method: HTTPMethod.GET,
    });
}

export async function subscribe(tenantId: string, request: BillingSubscriptionRequest): Promise<any> {
    return await callAPI(`/tenants/${tenantId}/billings/subscribe`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function upgrade(tenantId: string, request: BillingUpgradeRequest): Promise<any> {
    return await callAPI(`/tenants/${tenantId}/billings/upgrade`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getBilling(tenantId: string, billingId: string): Promise<Billing> {
    return await callAPI(`/tenants/${tenantId}/billings/${billingId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateBillingManual(tenantId: string, billingId: string, request: BillingManualPaymentRequest): Promise<Billing> {
    return await callAPI(`/tenants/${tenantId}/billings/${billingId}/manual`, {
        method: HTTPMethod.PUT,
        body: request
    });
}
