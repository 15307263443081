import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import AddIcon from "@material-ui/icons/Add";
import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColor";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { SpecificReceptionRule, SpecificReceptionRulesQueryParams, SpecificReceptionRules, SpecificReceptionRuleType } from "../model/SpecificReceptionRule";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import ReceptionSpecificRuleMenu from "./SpecificReceptionRuleMenu";
import { getReceptionSpecificRules } from "../api/SpecificReceptionRuleAPI";
import DateFormat from "../components/DateFormat";
import PostAddIcon from '@material-ui/icons/PostAdd';

interface SpecificReceptionRulesProps {
    readOnly: boolean;
    success: string
    onAction(data: SpecificReceptionRule): any;
}

export default function SpecificReceptionRulesList(props : SpecificReceptionRulesProps) {
    const context = useContext(AppContext);
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): SpecificReceptionRulesQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as SpecificReceptionRulesQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<SpecificReceptionRules>();
    const [params, setParams] = useState<SpecificReceptionRulesQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<SpecificReceptionRulesQueryParams>(paramsFromQueryString);
    const [specificRule, setSpecificRule] = useState<SpecificReceptionRule>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    

    const load = () => {
        setStatus("loading");
        let offset = getOffset(page, pageSize);
        getReceptionSpecificRules(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        if(props.success && props.success !== ""){
            setSuccess(props.success);
        }
    }

    useEffect(load, [context.session, page, pageSize, params, props]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (specificRule: SpecificReceptionRule) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSpecificRule(specificRule);
        props.onAction(specificRule);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setSpecificRule(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onAdd = () => {
        props.onAction({ id: "",
        name:"", created_at: new Date(), updated_at:new Date(), specific_reception_rules_types:[
            {sat_cfdi_type:"I", use_all_service_products: true, use_all_uses: true} as SpecificReceptionRuleType, 
            {sat_cfdi_type:"E", use_all_service_products: true, use_all_uses: true} as SpecificReceptionRuleType
         ] as SpecificReceptionRuleType[]
    } as SpecificReceptionRule);
    }

    const onClickedRow = (specificRule: SpecificReceptionRule) => {
        props.onAction(specificRule);
    };

    return (
        <Pagination title={translate("specific_reception_rule.title") as string} subtitle={translate("specific_reception_rule.subtitle") as string} icon={<PostAddIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGranted("SpecificReceptionRuleCreate") ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={onAdd} disabled={status === "loading"}>
                                    <AddIcon />
                                </Fab>
                            </Grid>) : undefined}
                    </Grid>
                </Grid>
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("specific_reception_rule.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cfdis.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("specific_reception_rule.columns.name") as string,
                        converter: (rule) => rule.name,
                        xs: 5,
                        sm: 5,
                        md: 5,
                        lg: 5,
                        xl: 5
                    },
                    {
                        title: translate("specific_reception_rule.columns.created_at") as string,
                        converter: (rule) => <DateFormat date={rule.created_at} format="L" />,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("specific_reception_rule.columns.updated_at") as string,
                        converter: (rule) => <DateFormat date={rule.updated_at} format="L" />,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: "",
                        converter: (rule) => (<IconButton aria-label="options" color="primary" size="small" onClick={onClickedOptions(rule)}>
                            <EditIcon />
                        </IconButton>),
                        justify: "flex-end",
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    }
                ]}
            />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {specificRule && anchorEl && (
                <ReceptionSpecificRuleMenu specificRule={specificRule} anchor={anchorEl} onClose={onCloseOption} />
            )}
        </Pagination>
    );
}