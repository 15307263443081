import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon, DeleteIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { BudgetLinesTemplate } from "../model/BudgetLinesTemplate";

interface BudgetLinesTemplateMenuProps {
    budgetLinesTemplate: BudgetLinesTemplate;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
}

export default function BudgetLinesTemplateMenu(props: BudgetLinesTemplateMenuProps) {
    const context = useContext(AppContext);
    const [isUpdateGranted] = useState(context.isGranted("BudgetLinesTemplateUpdate"));
    const [isDeleteGranted] = useState(context.isGranted("BudgetLinesTemplateDelete"));

    return (
        <Menu
            id="budget-lines-templates-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem button component={Link} to={`/budgets/template-lines/${props.budgetLinesTemplate.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            {isDeleteGranted && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.delete")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}