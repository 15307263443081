import React from "react";
import DialogPopup from "../components/DialogPopup";
import translate from "../i18n/Translator";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';


interface ProviderBankAccountMissingProps {
    data: Map<String, string[]>;
    onClose(): any;
}

export function ProviderBankAccountMissingPopup(props: ProviderBankAccountMissingProps) {
    return (
        <DialogPopup title={translate("payment_order.providers_without_bank_acccount.title")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            <Typography variant="body2" align="left">
                {translate("payment_order.providers_without_bank_acccount.message")}
            </Typography>
            <ProviderListItems data={props.data} />
        </DialogPopup>
    );
}

interface ProviderListItemsProps {
    data: Map<String, string[]>;
}

function ProviderListItems(props: ProviderListItemsProps) {
    return (
        <List dense>
            {Object.keys(props.data).map((currency: string) => (
                <ProviderListItem key={currency} currrency={currency} providers={(props.data as any)[currency]} />
            ))}
        </List>
    );
}

interface ProviderListItemProps {
    currrency: string;
    providers: string[];
}

function ProviderListItem(props:ProviderListItemProps) {
    return (
        <>
            <ListItem key={props.currrency}>
                <ListItemText primary={translate("payment_order.providers_without_bank_acccount.provider_list_message", { currency: props.currrency})} />
            </ListItem>
            <List dense >
                {props.providers.map((provider) => (
                    <ListItem key={provider}>
                        <ListItemIcon>
                            <InfoTwoToneIcon style={{ "color": "#f5812f"}}/>
                        </ListItemIcon>
                        <ListItemText primary={provider} />
                    </ListItem>
                ))}
            </List>
        </>
    );
}