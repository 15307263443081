import React, { useEffect, useState } from "react";
import { Typography, Grid, Button } from "@material-ui/core";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ExchangeRates, ExchangeRate, PaymentOrderCfdiItem } from "../model/PaymentOrder";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { GTZeroValidator, LTEValidator, NumberValidator } from "../components/Validators";
import NumberFormat from "react-number-format";
import Alert from "../components/Alert";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { formatDate } from "../components/DateFormat";

interface EditCfdiPopupProps {
    item: PaymentOrderCfdiItem;
    scheduleDate: Date;
    exchangeRates: ExchangeRates;
    tenantId: string;
    onClose(): any;
    onChanged(item: PaymentOrderCfdiItem): any;
}

export default function EditCfdiPopup(props: EditCfdiPopupProps) {
    const lteValidator = new LTEValidator(props.item.metadata.balance);
    const gteValidator = new GTZeroValidator();
    const numberValidator = new NumberValidator();
    const margin = "dense";
    const [amountInMxn, setAmountInMxn] = useState<number>();
    const [amountForPay, setAmountForPay] = useState<number | undefined>(props.item.payment_amount);
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [status, setStatus] = useState<string>("loading");

    const [data, setData] = useState<PaymentOrderCfdiItem>(props.item);
    const [exchangeRateSelected, setExchangeRateSelected] = useState<ExchangeRate>();
    const [validations, setValidations] = useState({} as any);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    const load = () => {
        setStatus("loading");
        getTenantCurrencies(props.tenantId).then((resp) => {
            if (resp.tenant_currency_ids) {
                if (resp.tenant_currency_ids) {
                    let listTemp = [] as AutocompleteProvider[];
                    resp.tenant_currency_ids.forEach((item) => {
                        let temp = { title: (item + " - " + (translate("currency." + item) as string)), value: item } as AutocompleteProvider;
                        listTemp.push(temp);
                    });
                    setCurrenciesAutocomplete(listTemp);
                    setStatus("loaded");
                }
            }
        }).catch((error) => {
            setError(error.message);
        });
    }

    useEffect(load, [props.item.currency]);

    const formatCurrency = (value: number, currency: string) => {
        let formatted;
        if (typeof value.toLocaleString === "function") {
            formatted = value.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: true,
            });
        } else {
            formatted = `${value}`;
        }

        return `$ ${formatted} ${currency}`;
    };

    const updateExchangeRate = (amount: number, currency: string, name: string, inputRef?: InputRef) => {
        let exchangeRate = 1;
        if (currency !== props.item.currency && props.exchangeRates) {
            switch (props.item.currency) {
                case "USD":
                    setExchangeRateSelected(props.exchangeRates!.dollar);
                    exchangeRate = props.exchangeRates!.dollar.value;
                    break;
                case "EUR":
                    setExchangeRateSelected(props.exchangeRates!.euro);
                    exchangeRate = props.exchangeRates!.euro.value;
                    break;
                default:
            }
        }

        if (exchangeRate === 1) {
            setAmountInMxn(undefined);
            setAmountForPay(undefined);
            setData({ ...data, amount: amount, payment_amount: amount, exchange_rate: exchangeRate, payment_currency: currency });
        } else {
            const paymentAmount = (amount * exchangeRate);
            setAmountForPay(Math.round(paymentAmount * 100) / 100);
            setAmountInMxn(paymentAmount);
            setData({ ...data, amount: amount, payment_amount: paymentAmount, exchange_rate: exchangeRate, payment_currency: currency });
        }
    };

    const hasChangedAmount = (name: string, value: string, inputRef: InputRef) => {
        const amount = value === "" ? 0 : +value;

        updateExchangeRate(amount, data.payment_currency, name, inputRef);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedAmountForPaid = (name: string, value: number, inputRef: InputRef) => {
        setAmountForPay(value);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                if (field === "amount_for_pay" && !(!props.exchangeRates.use_exchange_rate && props.item.currency !== data.payment_currency)) {
                    valid = true;
                } else {
                    valid = false;
                }
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) return;

        if (!data?.payment_currency) {
            setWarning(translate("validations.currency_required") as string);
            return;
        }

        if (!props.exchangeRates.use_exchange_rate && props.item.currency !== data.payment_currency && amountForPay) {
            if (amountForPay <= 0) {
                return;
            }
            data.payment_amount = amountForPay;
            data.exchange_rate = amountForPay / data.amount;
        }
        props.onChanged(data);
    };

    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        setData({ ...data, payment_currency: value });
        updateExchangeRate(data.amount, value, "payment_currency", inputRef);
        setValidations(validations);
    };

    if (status === "loading") {
        return <></>;
    }

    if (status !== "loaded" || !currenciesAutocomplete) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <DialogPopup open title={translate("payments_cfdi.title")} onClose={props.onClose} button={(
            <Button type="submit" variant="contained" size="medium" color="primary" disabled={data.payment_currency === "" || data.payment_currency === "---"}>
                {translate("buttons.accept")}
            </Button>
        )} onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="caption" component="h6" color="textSecondary">
                                {translate("cfdis.columns.balance")}
                            </Typography>
                            <Typography variant="subtitle1" component="h6">
                                <NumberFormat value={props.item.metadata.balance}
                                    prefix="$ " suffix={` ${props.item.currency}`}
                                    decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                                    displayType="text" />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" component="h6" color="textSecondary" align="right">
                                {translate("cfdis.columns.amount")}
                            </Typography>
                            <Typography variant="subtitle1" component="h6" align="right">
                                <NumberFormat value={props.item.total}
                                    prefix="$ " suffix={` ${props.item.currency}`}
                                    decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                                    displayType="text" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput type="number" id="amount" name="amount"
                        value={data.amount.toString()}
                        label={`${translate("payments_cfdi.amount_authorized") as string} ${data.currency}`}
                        required validators={[gteValidator, lteValidator, numberValidator]}
                        margin={margin}
                        disabled={"ADVANCE_PAYMENT" === props.item.type || props.item.cfdi?.metodo_pago === "PUE"}
                        onValueChanged={hasChangedAmount} />
                </Grid>
                <Grid item xs={12}>
                    <TenantCurrencyAutocomplete
                        currencies={currenciesAutocomplete}
                        disabled={props.item.currency === "MXN"}
                        onChange={onFilterChanged}
                        value={currenciesAutocomplete?.find(v => v.value === data.payment_currency)}
                    />
                </Grid>
                {(!props.exchangeRates.use_exchange_rate || (props.exchangeRates.use_exchange_rate && !amountInMxn)) && props.item.currency !== data.payment_currency
                    && data.payment_currency !== "" && data.payment_currency !== "---" &&
                    <Grid item xs={12}><ValidatedInput type="number" id="amount_for_pay" name="amount_for_pay"
                        value={amountForPay + ""}
                        validators={[gteValidator, numberValidator]}
                        label={translate("payments_cfdi.amount_for_pay", { "moneda": data.payment_currency }) as string}
                        required={!props.exchangeRates.use_exchange_rate && props.item.currency !== data.payment_currency}
                        margin={margin}
                        onValueChanged={hasChangedAmountForPaid} /></Grid>
                }
                {amountInMxn && data.payment_currency !== "" && data.payment_currency !== "---" && (
                    <Grid item xs={12}>
                        <Alert variant="info"
                            title={translate("payment_order.exchange_rate_dof")}
                            subheader={translate("payment_order.exchange_rate_notice", {"schedule_date": formatDate(props.scheduleDate, "DD/MM/YYYY"), "published_date": formatDate(exchangeRateSelected ? exchangeRateSelected.published : props.scheduleDate, "DD/MM/YYYY"), "amount": formatCurrency(amountInMxn, data.payment_currency), "exchange_rate": formatCurrency(data.exchange_rate || 1, data.payment_currency) })} />
                    </Grid>
                )}
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <WarningSnackbar message={warning} onClose={() => {
                setError(undefined);
                setWarning(undefined);
            }}
            />

        </DialogPopup>
    );

}