import { Box, Card, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Cfdi } from "../model/Cfdi";
import translate from "../i18n/Translator";
import FiguraTransporteCard from "./CartaPorte/FiguraTransporteCard20";
import { CartaPorte, ContenedorMaritimo, Mercancias, RegimenAduaneroCCP, RegimenesAduaneros, RemolqueCCP, TransporteMaritimo } from "../model/ComplementoCartaPorte31";
import { DetailsSection, UbicacionesCard } from "./CartaPorteTab20";
import { AutotransporteFederal, Transporteaereo, Transporteferroviario } from "./CartaPorte/MercanciasCard20";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import { MercanciasGrid } from "./CartaPorte/MercanciasCard30";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from "@devexpress/dx-react-grid";
import { TableRowDetail } from "@devexpress/dx-react-grid-material-ui";

export interface CartaPorteTabProps {
    cfdi: Cfdi;
}

export default function CartaPorteTab(props: CartaPorteTabProps) {
    return (
        <Box pt={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2} >
                <Grid item xs={12}>
                    <Generales data={props.cfdi.cfdi!.complementos.carta_porte31} />
                </Grid>
                {props.cfdi.cfdi!.complementos.carta_porte31.regimenes_aduaneros && 
                    <Grid item xs={12}>
                        <RegimenesAduanerosCard data={props.cfdi.cfdi!.complementos.carta_porte31.regimenes_aduaneros} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <UbicacionesCard data={props.cfdi.cfdi!.complementos.carta_porte31.ubicaciones} />
                </Grid>
                <Grid item xs={12}>
                    <MercanciasCard data={props.cfdi.cfdi!.complementos.carta_porte31.mercancias} />
                </Grid>
                {props.cfdi.cfdi!.complementos.carta_porte31.figura_transporte &&
                    <Grid item xs={12}>
                        <FiguraTransporteCard data={props.cfdi.cfdi!.complementos.carta_porte31.figura_transporte} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

interface GeneralesProps {
    data: CartaPorte;
}

function Generales(props: GeneralesProps) {
    return (
        <Box width="50%">
            <Card variant="outlined" >
                <Box pt={1} pl={2}><Typography component="h6" color="primary">{translate("cfdis.carta_porte.generales.title")}</Typography><br/></Box>
                <DetailsSection translation_prefix="cfdis.carta_porte.generales" data={props.data} />
            </Card>
        </Box>
    );
}

interface RegimenesAduanerosProps {
    data : RegimenesAduaneros;
}

function RegimenesAduanerosCard(props : RegimenesAduanerosProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.regimenes_aduaneros.title")}</strong></Typography>
            <Grid item xs={6} >
                <Box pt={2}>
                    <Gridable
                        items={props.data.regimenes_aduaneros_ccp}
                        loading={false}
                        empty={translate("reports.empty") as string}
                        columns={[
                            {
                                title: translate("cfdis.carta_porte.regimenes_aduaneros.regimen") as string,
                                converter: (regimen: RegimenAduaneroCCP) => regimen.regimen_aduanero,
                                xs: 4,
                            }
                        ]}
                    />
                </Box>
            </Grid>
        </Box>
    );
}

interface MercanciasCardProps {
    data: Mercancias;
}

function MercanciasCard(props: MercanciasCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.mercancias.title")}</strong></Typography>
            <Grid container justify="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Card variant="outlined" >
                        <MercanciasHead data={props.data} />
                        <MercanciasGrid data={props.data.mercancia} />
                    </Card><br />
                    <AutotransporteFederal data={props.data.autotransporte_federal} />
                    <Transportemaritimo data={props.data.transporte_maritimo} />
                    <Transporteaereo data={props.data.transporte_aereo} />
                    <Transporteferroviario data={props.data.transporte_ferroviario} />
                </Grid>
            </Grid>
        </Box>
    );
}

interface MercanciasHeadProps {
    data: Mercancias;
}

function MercanciasHead(props: MercanciasHeadProps) {

    const headerData = ["peso_bruto_total", "numero_total_mercancias", "unidad_peso", "cargo_por_tasacion", "peso_neto_total", "logistica_inversa_recoleccion_devolucion"];

    return (
        <>
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.mercancia.title")}</Typography><br/>
                <Grid container justify="flex-start" >
                    {headerData.map((property) => {
                        let itemProp = (props.data as any)[property];
                        return <>
                            {itemProp &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                </Grid>
            </Box>
        </>
    );
}

interface TransporteMaritimoProps {
    data?: TransporteMaritimo;
}

function Transportemaritimo(props: TransporteMaritimoProps) {

    const headerData = ["permiso_sct", "numero_permiso_sct", "nombre_aseguradora", "numero_poliza_seguro", "tipo_embarcacion",
        "matricula",
        "numero_omi",
        "anio_embarcacion",
        "nombre_embarcacion",
        "nacionalidad_embarcacion",
        "unidades_arqueo_bruto",
        "tipo_carga",
        "eslora",
        "manga",
        "calado",
        "puntal", 
        "linea_naviera",
        "nombre_agente_naviero",
        "permiso_temp_navegacion",
        "numero_autorizacion_naviero",
        "numero_viaje",
        "numero_conocimiento_embarque"
    ];

    return props.data && headerData ? <>
                <Card variant="outlined" >
                    <Box pt={1} pl={2}>
                    <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.transporte_maritimo.title")}</Typography><br/>
                        <Grid container justify="flex-start">
                            {headerData.map((property) => {
                                let itemProp = (props.data as any)[property];
                                return <>
                                    {property && itemProp &&
                                        <>
                                            <Grid item xs={12} sm={2}>
                                                <div>
                                                    {translate(`cfdis.carta_porte.mercancias.transporte_maritimo.${property}`)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    {(props.data as any)[property]}
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                </>;
                            })}
                        </Grid>
                    </Box>
                    <br/>
                    {props.data.contenedores && props.data.contenedores.length > 0 &&
                        <ContenedoresMaritimosCard data={props.data.contenedores}/>
                    }
                </Card>
            
        <br /></>
        : <></>;
}
interface ContenedoresMaritimosCardProps {
    data : ContenedorMaritimo[];
}

function ContenedoresMaritimosCard(props: ContenedoresMaritimosCardProps){
    const [columnsFormat] = useState([
        { columnName: 'matricula', wordWrapEnabled: true, width: 100 },
        { columnName: 'tipo', wordWrapEnabled: true, width: 100 },
        { columnName: 'numero_precinto', wordWrapEnabled: true, width: 100 },
        { columnName: 'id_ccp_relacionado', wordWrapEnabled: true, width: 100 },
        { columnName: 'placa_vmccp', wordWrapEnabled: true, width: 100 },
        { columnName: 'fecha_certificacion_ccp', wordWrapEnabled: true, width: 100 },
    ]);
    
    const columns = [
            {
                name: 'matricula',
                title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.matricula") as string,
                converter: (ct: ContenedorMaritimo) => ct.matricula,
                xs: 2,
            },
            {
                name : 'tipo',
                title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.tipo") as string,
                converter: (ct: ContenedorMaritimo) => ct.tipo,
                xs: 2,
            },
            {
                name: 'numero_precinto',
                title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.numero_precinto") as string,
                converter: (ct: ContenedorMaritimo) => ct.numero_precinto,
                xs: 2,
            },
            {
                name: 'id_ccp_relacionado',
                title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.id_ccp_relacionado") as string,
                converter: (ct: ContenedorMaritimo) => ct.id_ccp_relacionado,
                xs: 2,
            },
            {
                name: 'placa_vmccp',
                title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.placa_vmccp") as string,
                converter: (ct: ContenedorMaritimo) => ct.placa_vmccp,
                xs: 2,
            },
            {
                name: 'fecha_certificacion_ccp',
                title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.fecha_certificacion_ccp") as string,
                converter: (ct: ContenedorMaritimo) => ct && ct.fecha_certificacion_ccp ? <DateFormat date={ct.fecha_certificacion_ccp} format="DD/MM/YYYY" /> : "---",
                xs: 2,
            }
    ];

    const dateColumns = ['fecha_certificacion_ccp'];

    const filtersExclude = [
        'matricula','tipo', 'numero_precinto', 'id_ccp_relacionado', 'placa_vmccp', 'fecha_certificacion_ccp'
    ];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
        <Box>
            <Typography ><strong>{translate("cfdis.carta_porte.mercancias.transporte_maritimo.remolques_ccp.title") as string}</strong></Typography>
            <br />
            <Grid container justify="flex-start" spacing={2}>
            <Grid xs={6}>
                        <Gridable
                            items={params.row.remolques_ccp.remolques_ccp}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.remolques_ccp.sub_tipo_rem_ccp") as string,
                                    converter: (ct: RemolqueCCP) => ct.sub_tipo_rem_ccp,
                                    xs: 4,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.remolques_ccp.placa_ccp") as string,
                                    converter: (ct: RemolqueCCP) => ct.placa_ccp,
                                    xs: 4,
                                }
                            ]}
                        />
                    </Grid>
            </Grid>
        </Box>
        
    );

    return (
        <GridDx
            loading={false}
            rows={props.data}
            columns={columns}
            columnsFormat={columnsFormat}
            onClickRow={() => { }}
            dateColumns={dateColumns}
            customFormatColumns={customPlugins}
            rowDetailComponent={rowDetailComponent}
            noUseId
        />
    );
}

