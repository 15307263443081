import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Box, Button, Divider, Grid, Hidden, IconButton, Typography, Fab } from "@material-ui/core";
import InvoiceIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";

import { get, prepare, deleteCfdi } from "../api/TenantSECfdiApi";
import { get as providerGet, prepare as providerPrepare, deleteCfdi as deleteProviderCfdi } from "../api/ProviderSECfdiApi";
import { listContracts } from "../api/ContractAPI";
import { listEmployees } from "../api/EmployeeAPI";
import { listEmployees as listProviderEmployees } from "../api/ProviderEmployeeAPI";
import { ContractsQueryParams, Contracts } from "../model/Contract";
import { Employee, EmployeesQueryParams } from "../model/Employee";
import { PrepareCfdiEmployeeRequest, PrepareCfdiRequest } from "../model/CfdiSe";
import DatePicker from "../components/DatePicker";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import DialogPopup from "../components/DialogPopup";
import moment from "moment";
import { Cfdi } from "../model/Cfdi";
import { importGroupEmployee, createEmployeeGroup } from "../api/EmployeeGroupAPI";
import { importGroupEmployee as importGroupEmployeeProvider, createEmployeeGroup as createEmployeeGroupProvider } from "../api/ProviderEmployeeGroupAPI";
import { EmployeeGroupResultImport, EmployeeGroupRequest } from "../model/EmployeeGroup";
import EmployeeGroupResultsImport from "../employeegroups/EmployeeGroupResultsImport";

export default function CfdiIssuedPrepareForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id ?? "-";
    const providerId = context.session?.provider?.id;
    const cfdiId = match.params.cfdiId;
    const history = useHistory();

    const [format] = useState("DD/MM/YYYY")
    const [grantedToUpdate] = useState(context.isGrantedAny(["CfdisIssuedUpdate", "ProviderCfdisIssuedUpdate"]));
    const [status, setStatus] = useState<string>("loading");
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [submitting, setSubmitting] = useState(false);
    const [customerId, setCustomerId] = useState<string>("-");
    const [contractIds, setContractIds] = useState<string[]>([]);
    const [contractLabels, setContractLabels] = useState<string[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [employeeIds, setEmployeeIds] = useState<string[]>([]);
    const [employeeLabels, setEmployeeLabels] = useState<string[]>([]);
    const [employeeId, setEmployeeId] = useState<string>("");
    const [request, setRequest] = useState<PrepareCfdiRequest>({ employees: [], from: "", to: "", contract_id: "" } as PrepareCfdiRequest);
    const [validations, setValidations] = useState({} as any);
    const [error, setError] = useState<string>();
    const [dialog, setDialog] = useState<"delete">();
    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();
    const [initialMinDate, setInitialMinDate] = useState<Date>();
    const fileInput = useRef<HTMLInputElement>(null);
    const [success, setSuccess] = useState<string>();
    const [employeeGroup, setEmployeeGroup] = useState<EmployeeGroupResultImport>();
    const [employeesNotRegistered, setEmployeesNotRegistered] = useState<Employee[]>([]);
    const [urlImportResults, seturlImportResults] = useState<string>("");

    const promiseGet = () => {
        if (providerId) {
            return providerGet(tenantId, providerId, cfdiId);
        }
        return get(tenantId, cfdiId);
    };

    const promiseEmployees = () => {
        const params = { search: "" } as EmployeesQueryParams;
        if (providerId) {
            return listProviderEmployees(tenantId, providerId, 0, 0, params);
        }
        return listEmployees(tenantId, 0, 0, params);
    };

    const emptyContracts = async (): Promise<Contracts> => {
        return { items: [], total: 0 } as Contracts;
    };

    const promiseContracts = (customerId: string, startDate: string, endDate: string) => {
        const params = { search: "", startDate: startDate, endDate: endDate } as ContractsQueryParams;
        if (providerId) {
            return emptyContracts();
        }
        return listContracts(tenantId, customerId || "-", 0, 0, params);
    };

    const load = () => {
        setStatus("loading");
        if(initialMinDate === undefined && context.session?.se_lisensing_initial_date){
            setMinDate(new Date(context.session?.se_lisensing_initial_date));
            setInitialMinDate(new Date(context.session?.se_lisensing_initial_date));
        }
        promiseGet().then(response => {
            setCustomerId(response.metadata.customer_id || "");
            if (response.metadata.status === "SE_PREPARING") {
                    promiseEmployees().then(result => {

                    const employees = result.items;
                    setEmployees(employees);

                    const specializedServices = response.metadata.specialized_services;
                    if (specializedServices) {
                        const usedEmployees = (specializedServices.employees || []).map(emp => emp.employee?.id).filter(el => !!el);
                        const seEmployees = (specializedServices.employees || []).map(emp => {
                            return {
                                employee_id: emp.employee?.id || "",
                                name: emp.employee?.name || "",
                                rfc: emp.employee?.rfc || "",
                                from: emp.from ? moment(emp.from).format(format) : "",
                                to: emp.to ? moment(emp.to).format(format) : "",
                            } as PrepareCfdiEmployeeRequest;
                        });

                        setRequest({
                            ...request,
                            from: specializedServices.from ? moment(specializedServices.from).format(format) : "",
                            to: specializedServices.to ? moment(specializedServices.to).format(format) : "",
                            contract_id: specializedServices.contract?.id || "",
                            employees: seEmployees,
                        });

                        const filteredEmployees = employees.filter(el => usedEmployees.indexOf(el.id) === -1);
                        setEmployeeIds(filteredEmployees.map(el => el.id));
                        setEmployeeLabels(filteredEmployees.map(el => el.name));
                    } else {
                        setEmployeeIds(employees.map(el => el.id));
                        setEmployeeLabels(employees.map(el => el.name));
                    }
                }).catch(error => {
                    console.error(error);
                });
            } else {
                history.push("/cfdis/se/to-prepare");
            }

            setCfdi(response);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, cfdiId, context]);

    const onChangedDate = (name: string, raw?: string, date?: Date, inputRef?: InputRef) => {
        if (raw) {
            setMaxDate(date);
            if (name === "from") {
                setMinDate(date);
                setRequest({ ...request, [name]: raw, "to": raw });
            } else {
                setRequest({ ...request, [name]: raw });
            }
        } else {
            delete (request as any)[name];
            setRequest(request);
            if (name === "from") {
                setMinDate(initialMinDate);
            }
        }

        if (inputRef) {
            validations[name] = inputRef;
            setValidations(validations);
        }

        if (name === "to" && raw) {
            let startDateSplitted = request.from.split("/");
            let endDateSplitted = raw.split("/");
            let startDate = startDateSplitted[1]+"/"+startDateSplitted[0]+"/"+startDateSplitted[2];
            let endDate = endDateSplitted[1]+"/"+endDateSplitted[0]+"/"+endDateSplitted[2];
            promiseContracts(customerId, startDate, endDate).then(response => {
                const contracts = response.items;
                setContractIds(contracts.map(el => el.id));
                setContractLabels(contracts.map(el => el.description));
            }).catch(error => {
                setStatus(error.message);
            });
        }
    };

    const onChangedEmployeeDate = (employeeRequest: PrepareCfdiEmployeeRequest) => (name: string, raw?: string, date?: Date, inputRef?: InputRef) => {
        employeeRequest = { ...employeeRequest, [name]: raw };
        setRequest({
            ...request, employees: request.employees.map(el => {
                if (el.employee_id === employeeRequest.employee_id) {
                    return employeeRequest;
                }
                return el;
            })
        });

        if (inputRef) {
            validations[`${name}_${employeeRequest.employee_id}`] = inputRef;
            setValidations(validations);
        }
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: !value || value === "---" ? "" : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedEmployee = (name: string, value: string, inputRef: InputRef) => {
        setEmployeeId(!value || value === "---" ? "" : value);
    };

    const addAllEmployees = () => {
        const requestEmployees = employees.map(el => {
            return {
                employee_id: el.id,
                rfc: el.rfc,
                name: el.name,
                from: request.from,
                to: request.to,
            } as PrepareCfdiEmployeeRequest
        })
        setRequest({ ...request, employees: requestEmployees });
        setEmployeeIds([]);
        setEmployeeLabels([]);
        setEmployeeId("");
    };

    const addFileEmployees = () => {
        fileInput.current!.click();
    };

    const addEmployee = () => {
        if (!employeeId) return;

        const index = employeeIds.findIndex(el => el === employeeId);
        if (index === -1) return;

        const employee = employees.find(el => el.id === employeeId);
        if (!employee) return;

        const requestEmployees = request.employees.map(el => el);
        requestEmployees.push({
            employee_id: employeeId,
            rfc: employee.rfc,
            name: employee.name,
            from: request.from,
            to: request.to,
        });

        setRequest({ ...request, employees: requestEmployees });
        setEmployeeIds(employeeIds.filter((el, i) => i !== index));
        setEmployeeLabels(employeeLabels.filter((el, i) => i !== index));
        setEmployeeId("");
    };

    const deleteEmployee = (employeeRequest: PrepareCfdiEmployeeRequest, index: number) => () => {
        const employee = employees.find(el => el.id === employeeRequest.employee_id);
        if (!employee) return;

        const currentEmployeeIds = request.employees.map(el => el.employee_id);
        const filteredEmployees = employees.filter(el => el.id === employee.id || currentEmployeeIds.indexOf(el.id) === -1);

        setEmployeeIds(filteredEmployees.map(el => el.id));
        setEmployeeLabels(filteredEmployees.map(el => el.name));
        setRequest({ ...request, employees: request.employees.filter((el, i) => i !== index) });

        delete validations[`from_${employeeRequest.employee_id}`];
        delete validations[`to_${employeeRequest.employee_id}`];
        setValidations(validations);
    };

    const promisePrepare = () => {
        if (providerId) {
            return providerPrepare(tenantId, providerId, cfdiId, request);
        }
        return prepare(tenantId, cfdiId, request);
    };

    const onSubmit = () => {
        if (!isValid(validations)) return;

        if (request.employees.length === 0) {
            setError(translate("cfdis_se.prepare.without_employees") as string);
            return;
        }

        setSubmitting(true);
        promisePrepare().then(response => {
            history.push(`/cfdis/se/${response.id}/details`);
        }).catch(error => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const promiseDelete = () => {
        if (providerId) {
            return deleteProviderCfdi(tenantId, providerId, cfdiId);
        }
        return deleteCfdi(tenantId, cfdiId);
    };

    const onDelete = () => {
        setDialog(undefined);
        setSubmitting(true);

        promiseDelete().then(() => {
            history.push("/cfdis/se/archive");
        }).catch(error => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    const promiseImportGroupEmployee = (file: File) => {
        if (providerId) {
            return importGroupEmployeeProvider(context.session!.tenant!.id, providerId, file);
        }
        return importGroupEmployee(context.session!.tenant!.id, file);
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        setSubmitting(true);

        promiseImportGroupEmployee(event.target.files[0]).then((response) => {
            if(response.employees === undefined || response.employees.length === 0){
                setError(translate("employeegroups.empty_import") as string);
                return;
            }
            if(response.employees_not_registered.length > 0){
                setEmployeesNotRegistered(response.employees_not_registered);
                seturlImportResults(response.url_export_results);
                return;
            }
            setEmployeeGroup(response);
            const requestEmployees = response.employees.filter(er => request.employees.filter(emp => er.id === emp.employee_id).length === 0).map(emp => {
                return {
                    employee_id: emp.id,
                    rfc: emp.rfc,
                    name: emp.name,
                    from: request.from,
                    to: request.to,
                } as PrepareCfdiEmployeeRequest;
            });
            let tempRequest = request;
            requestEmployees.forEach(reqEmp => tempRequest.employees.push(reqEmp));
            setRequest(tempRequest);
            let tempEmployeees = employees.filter((e) =>  tempRequest.employees.filter(er => er.employee_id === e.id).length === 0);
            setEmployeeIds(tempEmployeees.map(e => e.id));
            setEmployeeLabels(tempEmployeees.map(e => e.name));
            setSuccess(translate("employeegroups.success") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            fileInput.current!.value = "";
        });
    }

    const onAddGroup = (name: string) => {
        setOpenAddGroup(false);
        saveEmployeeGroup(name);
    };

    const promiseCreateEmployeeGroup = (request: EmployeeGroupRequest) => {
        if (providerId) {
            return createEmployeeGroupProvider(context.session!.tenant!.id, providerId, request);
        }
        return createEmployeeGroup(context.session!.tenant!.id, request);
    };

    const saveEmployeeGroup = (name: string) => {
        setSubmitting(true);

        if(!employeeGroup) return;
        let employeeIds = employeeGroup.employees.map(emp => emp.id);
        promiseCreateEmployeeGroup({ name: name, employee_ids: employeeIds } as EmployeeGroupRequest).then((response) => {
            setSuccess(translate("employeegroups.created_success") as string);
            
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            fileInput.current!.value = "";
        });
    };

    const onCloseAddGroup = () => {
        setOpenAddGroup(false);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface title={translate("cfdis_se.prepare.title")} subtitle={translate("cfdis_se.prepare.subtitle")} icon={<InvoiceIcon />} backButton titleActions={context.isGrantedAny(["CfdisIssuedDelete", "ProviderCfdisIssuedDelete"]) ? (
            <Grid container alignItems="center" justify="flex-end" spacing={1}>
                <Grid item xs="auto">
                    <Fab color="secondary" size="small" title={translate("buttons.delete") as string} disabled={cfdi?.source_id !== undefined} onClick={() => setDialog("delete")} >
                        <DeleteIcon />
                    </Fab>
                </Grid>
            </Grid>
        ) : undefined}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        {translate("cfdis_se.prepare.dates")}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DatePicker name="from"
                                label={translate("contracts.from") as string}
                                initial={request.from} format={format}
                                clearable
                                defaultIfMissing
                                autoOK disableFuture={false}
                                required disabled={submitting}
                                minDate={initialMinDate}
                                onChange={onChangedDate} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker name="to"
                                label={translate("contracts.to") as string}
                                initial={request.to} format={format}
                                clearable
                                defaultIfMissing
                                autoOK disableFuture={false}
                                required disabled={submitting || request.from === ""}
                                minDate={minDate}
                                onChange={onChangedDate} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Hidden xsUp={!!providerId} implementation="css">
                        <Typography variant="subtitle2">
                            {translate("cfdis_se.prepare.contract")}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <ValidatedInput type="text" id="contract_id" name="contract_id"
                                    value={request.contract_id}
                                    label={translate("contracts.single") as string}
                                    options={contractIds} optionLabels={contractLabels}
                                    required={!providerId} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    <Box py={1}>
                        <Divider />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        {translate("cfdis_se.prepare.employees")}
                    </Typography>
                    <Grid container justify="center" alignContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm>
                            <ValidatedInput type="text" id="employee_id" name="employee_id"
                                value={employeeId}
                                label={translate("employees.single") as string}
                                options={employeeIds} optionLabels={employeeLabels}
                                disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChangedEmployee} />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button variant="outlined" color="secondary" size="medium" disabled={!employeeId} onClick={addEmployee}>
                                {translate("buttons.add")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button variant="outlined" color="secondary" size="medium" disabled={employees.length === 0} onClick={addAllEmployees}>
                                {translate("buttons.add_all")}
                            </Button>
                        </Grid>
                        {context.isGranted("EmployeeGroupsCreate") && 
                            <Grid item xs={12} sm="auto">
                                <Button variant="outlined" color="secondary" size="medium" disabled={employees.length === 0} onClick={addFileEmployees}>
                                    {translate("employeegroups.add_file")}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Gridable items={request.employees}
                        loading={false}
                        empty={translate("cfdis_se.prepare.employees_empty") as string}
                        columns={[
                            {
                                title: translate("employees.rfc"),
                                converter: (item) => item.rfc || "---",
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },
                            {
                                title: translate("employees.name"),
                                converter: (item) => item.name || "---",
                                fullWidth: true,
                                xs: true,
                                sm: true,
                                md: true,
                                lg: true,
                                xl: true,
                            },
                            {
                                title: translate("contracts.from"),
                                converter: (item) => (
                                    <DatePicker name="from"
                                        label={translate("contracts.from") as string}
                                        initial={item.from} format={format}
                                        clearable
                                        defaultIfMissing
                                        autoOK disableFuture={false}
                                        required disabled={submitting}
                                        minDate={minDate} maxDate={maxDate}
                                        onChange={onChangedEmployeeDate(item)} />
                                ),
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },
                            {
                                title: translate("contracts.to"),
                                converter: (item) => (
                                    <DatePicker name="to"
                                        label={translate("contracts.to") as string}
                                        initial={item.to} format={format}
                                        clearable
                                        defaultIfMissing
                                        autoOK disableFuture={false}
                                        required disabled={submitting}
                                        minDate={minDate} maxDate={maxDate}
                                        onChange={onChangedEmployeeDate(item)} />
                                ),
                                fullWidth: true,
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },
                            {
                                title: (
                                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                        <DeleteIcon />
                                    </IconButton>
                                ),
                                converter: (employee, index) => (
                                    <IconButton aria-label="options" color="default" size="small" onClick={deleteEmployee(employee, index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                ),
                                fullWidth: true,
                                justify: "flex-end",
                                xs: "auto"
                            },
                        ]} />
                </Grid>
                <Grid item xs={12}>
                    <Box pt={2}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item xs="auto">
                                <Button variant="outlined" color="secondary" size="medium" onClick={history.goBack} disabled={submitting}>
                                    {translate("buttons.cancel")}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button variant="outlined" color="primary" size="medium" onClick={onSubmit} disabled={!grantedToUpdate || submitting}>
                                    {translate("buttons.update")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            {dialog === "delete" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.delete.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={onDelete} variant="outlined" color="secondary" disabled={submitting}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.delete.text")}
                </DialogPopup>
            )}
            {employeesNotRegistered.length > 0 && urlImportResults !== "" &&
                <EmployeeGroupResultsImport
                    onClose={() => {setEmployeesNotRegistered([]); seturlImportResults("");}}
                    url={urlImportResults}
                    employees={employeesNotRegistered}
                />
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} />
            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".xlsx" />
        </Surface>
    );
}

