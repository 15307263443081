import { ConfigurationCatalog } from "./ConfigurationCatalog";
import { ZipInterpreter } from "./ZipUpload";
import { CfdiMetadataType } from "./Cfdi";
import { EmployerConfiguration } from "./Employer";
import { Currency, TenantCurrency } from "./Currency";
import { PluginStep } from "./Plugin";

export interface TenantConfiguration extends EmployerConfiguration {
    use_exchange_rate?: boolean;
    reception_date_validation_type?: ReceptionDateValidationType;
    configurations: Configuration[];
    erp?: ERP;
    catalog?: ConfigurationCatalog[];
    tenant_name?: string;
    receive_plugin_id?: string;
    after_receive_plugin_id?: string;
    payment_conditions?: PaymentCondition[];
    purchase_order_configuration: PurchaseOrderConfiguration;
    has_b1: boolean;
    has_advance_payment: boolean;
    has_budgets: boolean;
    has_payment_orders: boolean;
    tax_equivalences: TaxEquivalence[];
}

export interface ERP {
    url?: string;
    key_pair_id?: string;
    purchase_order_workflow_id?: string;
    warehouse_delivery_workflow_id?: string;
    erp_plugin: ErpPlugin;
    username?: string;
    company?: string;
    bucket?: string;
    region?: string;
    lambda_name?: string;
    lambda_alias?: string;
    has_password: boolean;
    remote_dir_pattern?: string;
    favorite_date_format?: string;
    remote_dir_by_type?: Map<CfdiMetadataType, string>;
    import_definition?: ZipDefinition;
    export_definition?: ZipDefinition;
}

export interface ZipDefinition {
    interpreter: ZipInterpreter;
    connector_ids?: string[]
}

export interface Configuration {
    property_name: string;
    last_update: Date;
    value: string;
    active: boolean;
}

export interface TenantConfigurationErpRequest {
    url?: string;
    erp_plugin?: ErpPlugin;
    key_pair_id?: string;
    purchase_order_workflow_id?: string;
    warehouse_delivery_workflow_id?: string;
    username?: string;
    password?: string;
    company?: string;
    bucket?: string;
    region?: string;
    lambda_name?: string;
    lambda_alias?: string;
    update_password: boolean;
    remote_dir_pattern?: string;
    zip_interpreter?: ZipInterpreter;
    favorite_date_format?: string;
    remote_dir_by_type?: Map<CfdiMetadataType, string>;
}

export interface TenantConfigurationWorkflowsRequest {
    purchase_order_workflow_id?: string;
    warehouse_delivery_workflow_id?: string;
}

export interface ImportExportZipDefinitionRequest {
    interpreter?: ZipInterpreter;
    connector_ids?: string[];
    export: boolean;
}

export interface TenantConfigurationRequest {
    property_name: string;
    value?: string;
    active?: boolean;
}

export interface TenantConfigurationContactErpRequest {
    contact_name: string;
    email: string;
}

export interface UpdateReceivePluginRequest {
    plugin_id?: string;
    plugin_step: PluginStep;
}

export interface PaymentCondition {
    id: string;
    name: string;
    status: string;
    created_at: Date;
    installments: Installment[];
}

export interface PaymentConditionsResponse {
    items: PaymentCondition[];
    total: number;
}

export interface PaymentConditionRequest {
    name: string;
    status: string;
    installments: Installment[];
}

export interface Installment {
    payment_number: string;
    percentage: number;
}

export interface PaymentConditionListParams {
    search: string;
    active: string;
}

export interface SingleSingOnConfig {
    access_token_issuer_url: string;
    redirect_url: string;
    access_token_audience: string;
    profile_endpoint_url: string;
    client_id: string;
    role_attribute_in_profile: string;
    allowed_roles: string[];
    authentication_provider: string;
}

export interface SingleSingOnConfigRequest extends SingleSingOnConfig {}

export interface PasswordConfig {
    pass_expiration_days: number;
    reminder_before_pass_expiration: number;
    notify_on_pass_expiration: boolean;
    notify_daily_before_pass_expiration: boolean;
    days_before_expiration_to_notify_daily: number;
}

export interface PasswordConfigRequest extends PasswordConfig { }

export interface SecurityConfig {
    password_config_response: PasswordConfig;
    sso_config_response: SingleSingOnConfig;
}

export interface SecurityConfigRequest {
    is_active: boolean;
    password_config_request?: PasswordConfigRequest;
    single_sing_on_config_request?: SingleSingOnConfigRequest;
}

export interface ClientSsoConfig {
    access_token_issuer_url: string;
    client_id: string;
    redirect_url: string;
}

export interface TenantCurrenciesResponse {
    tenant_currency_ids: string[];
    currencies: Currency[];
    tenant_currencies: TenantCurrency[];

}

export interface TenantCurrencyRequest {
    code: string;
    budget_exchange_rate?: number;
}

export interface TenantPOConfigurationRequest extends PurchaseOrderConfiguration {
}

export interface PurchaseOrderConfiguration {
    allow_link_invoices: boolean;
    must_link_all_invoice_concepts: boolean;
    validate_invoice_amounts: boolean;
    amount_tolerance: number;
    restrict_upload_cfdi_from_purchase_order: boolean;
    show_warehouse_deliveries_in_purchase_orders: boolean;
    restrict_to_one_purchase_order_per_document: boolean;
    validate_invoice_amounts_type: ValidationAmountType;
    allow_cancel_close_purchase_orders_manually: boolean;
}

export interface TaxEquivalence {
    tax: string;
    equivalence: string
}
export interface TaxEquivalenceRequest {
    tax_equivalences: TaxEquivalence[];
}

export interface SecurityConfigRequest extends SecurityConfig { }

export const ConfigurationCatalogCategory = ["PAYMENT_CFDI", "DIGITAL_EXPEDIENT", "PRORRATE", "PAYMENTS", "NOTIFICATIONS", "EMAIL_RECEPTION", "SECURITY", "INTERN", "VALIDATIONS", "SUPPORT", "CUSTOM_APP", "TERMS_CONDITIONS", "WORKFLOWS", "INVOICES", "PURCHASE_ORDERS", "WAREHOUSE_DELIVERIES", "REQUISITIONS", "SE"];

export enum ReceptionDateValidationType { NONE = 'NONE', IN_MONTH = 'IN_MONTH', IN_YEAR = 'IN_YEAR', IN_CALENDAR = 'IN_CALENDAR' }

export type ErpPlugin = 'DAAR' | 'SAP' | 'SFTP' | 'S3' | 'LAMBDA' | 'NONE';

export type ValidationAmountType = 'NONE' | 'ONE' | 'MULTIPLE';

export const PLUGINS: ErpPlugin[] = ['DAAR', 'SAP', 'SFTP', 'S3', 'LAMBDA'];

export enum UsersAuthenticationModes {
    PDP_USERS_WITH_EXPIRATION_PASS = "PDP_USERS_WITH_EXPIRATION_PASS",
    SSO_OKTA = "SSO_OKTA"
}
