import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { ExtendedField } from "../../model/ExtendedField";
import { Workflow } from "../../model/Workflow";
import { Cfdi } from "../../model/Cfdi";
import DialogPopup from "../DialogPopup";
import FieldItemHistoryPopup from "./FieldItemHistoryPopup";
import FieldItemEditPopup from "./FieldItemEditPopup";
import FieldData from "./FieldItemData";
import { Redirect } from "react-router-dom";
import { CallbackFunction, SuccessCallbackFunction } from "./OnSubmitField";

interface FieldItemsDetailProps {
    fields: ExtendedField[];
    providerId?: string;
    tenantId: string;
    fieldId: string;
    payableDocument?: Cfdi;
    isReaderOnly?: boolean;
    workflow?: Workflow;
    allowLinkPO: boolean;
    qs?: string;
    onChange(request: ExtendedField, callback: SuccessCallbackFunction, error: CallbackFunction): any;
    onDelete(request: ExtendedField): any;
    onError(error: string, errorCode?: any): any;
}

export default function FieldItemsDetail(props: FieldItemsDetailProps) {
    const [field, setField] = useState<ExtendedField>();
    const [fieldToDelete, setFieldToDelete] = useState<ExtendedField>();
    const [fields, setFields] = useState<ExtendedField[]>([]);
    const [openEditField, setOpenEditField] = useState<boolean>(false);
    const [openPaymentHistoy, setOpenPaymentHistory] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string | undefined>();

    const load = () => {
        setFields(props.fields);
    };

    useEffect(load, [props.fields]);

    const onSubmit = (request: ExtendedField, callback: CallbackFunction) => {
        props.onChange(request, () => {
            setField(undefined);
        }, (error) => {
            callback(error);
        });
    };

    const onSubmitDelete = () => {
        if (fieldToDelete)
            props.onDelete(fieldToDelete);

        setFieldToDelete(undefined);
    };

    const onEditField = (field: ExtendedField) => {
        setField(field);
        if (field.type === "PURCHASE_ORDERS" && props.allowLinkPO) {
            setRedirect(`/cfdis/${props.payableDocument!.id}/purchase-order?` + props?.qs ?? "");
        } else {
            setOpenEditField(true);

        }
    }

    const onDeleteField = (field: ExtendedField) => {
        setFieldToDelete(field);
    }

    const onCloseEditField = () => {
        setField(undefined);
    }

    const onCloseDeleteField = () => {
        setFieldToDelete(undefined);
    }

    const onShowPaymentHistory = (field: ExtendedField) => {
        setOpenPaymentHistory(true);
        setField(field);
    }

    const onClosePaymentHistory = () => {
        setField(undefined);
        setOpenPaymentHistory(false);
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Grid container>
            {fields.map(field => (
                <Grid item key={field.id} xs={12} md={6}>
                    <Box px={2}>
                        <Grid container justify="center" alignContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                                <FieldLabel required={field.required} value={field.name} />
                            </Grid>
                            <Grid item xs={8}>
                                <FieldData onlyRead={!field.editable || !!props.isReaderOnly}
                                    canDelete={!props.allowLinkPO}
                                    onEditField={onEditField}
                                    onDeleteField={onDeleteField}
                                    onShowPaymentHistory={onShowPaymentHistory}
                                    field={field} tenantId={props.tenantId} cfdiId={props.fieldId} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ))}
            {field && openEditField && (
                <FieldItemEditPopup
                    field={field}
                    tenantId={props.tenantId}
                    providerId={props.providerId ? props.providerId : ""}
                    fieldId={props.fieldId}
                    payableDocument={props.payableDocument}
                    workflow={props.workflow}
                    doAction={onSubmit}
                    onClose={onCloseEditField}
                />
            )}
            {field && openPaymentHistoy && (
                <FieldItemHistoryPopup field={field}
                    tenantId={props.tenantId} cfdiId={props.fieldId}
                    onClose={onClosePaymentHistory} />
            )}
            {fieldToDelete &&
                <DialogPopup open
                    title={fieldToDelete.type === "DATE" ? translate("shipping_templates.popup.delete_date.title") as string :
                        fieldToDelete.type === "FILE" ? translate("shipping_templates.popup.delete_file.title") as string :
                            translate("shipping_templates.popup.delete_field.title") as string
                    }
                    disable={false}
                    closeText={translate("buttons.cancel") as string}
                    onClose={onCloseDeleteField}
                    closeColor="default"
                    button={
                        <Button onClick={onSubmitDelete} variant="outlined" color="primary" disabled={false}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    <Typography variant="body2">
                        {fieldToDelete.type === "DATE" ? translate("shipping_templates.popup.delete_date.description", { "name": fieldToDelete.name }) as string :
                            fieldToDelete.type === "FILE" ? translate("shipping_templates.popup.delete_file.description", { "name": fieldToDelete.name }) as string :
                                translate("shipping_templates.popup.delete_field.description", { "name": fieldToDelete.name }) as string
                        }
                    </Typography>
                </DialogPopup>
            }
        </Grid>
    );
}

interface FieldLabelProps {
    required: boolean;
    value: string;
}

function FieldLabel(props: FieldLabelProps) {
    return (
        <div>
            {!props.required &&
                props.value
            }
            {props.required &&
                translate("cfdis.template.field_required", { "value": props.value })
            }
        </div>
    );
}