import React, { useState  } from "react";
import { CfdisListProps } from "../cfdi/CfdisList";
import { PaymentData, CfdisSelected, Payment } from "../model/Payment";
import PendingPaymentListCfdis from "../payments/PendingPaymentListCfdis";
import PendingPaymentSelectCfdis from "../payments/SelectCfdisToPayView";
import CreatePaymentView from "../payments/CreatePaymentView";
import translate from "../i18n/Translator";
import CreateUpdatePaymentOrderView from "../payment_order/CreateUpdatePaymentOrderView";
import { PaymentOrder } from "../model/PaymentOrder";
import ReactHtmlParser from "html-react-parser";

export default function ListCfdisPendingPayment(props: CfdisListProps) {
    const [view, setView] = useState<string>("principal");
    const [infoProvider, setInfoProvider] = useState<PaymentData>({} as PaymentData);
    const [cfdisSelected, setCfdisSelected] = useState<CfdisSelected>({} as CfdisSelected);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [viewPaymentOrder, setViewPaymentOrder] = useState<"create" | "update" >("create");
    const [fromPaymentOrder, setFromPaymentOrder] = useState<boolean>(false);
    const [paymentOrderId, setPaymentOrderId] = useState<string>("");

    const goToSelectCfdis = (info: PaymentData) => {
        setInfoProvider(info);
        setView("select-cfdis");
        setFromPaymentOrder(false);
    }

    const goToCreatePayment = (info: CfdisSelected) => {
        setCfdisSelected(info);
        setView("create-payment");
    }

    const savePayment = (result: Payment) => {
        setSuccess(translate("payments_cfdi.success", { "pago": result.operation_number}) as string);
        setView("principal");
        setInfoProvider({} as PaymentData);
        setCfdisSelected({} as CfdisSelected);
    }

    const onCancel = () => {
        setView("principal");
        setInfoProvider({} as PaymentData);
        setCfdisSelected({} as CfdisSelected);
        setSuccess(undefined);
    }

    const goToCreatePaymentOrder = (info: CfdisSelected) => {
        setViewPaymentOrder("create");
        setCfdisSelected(info);
        setView("create-payment-order");
    }

    const savePaymentOrder = (result: PaymentOrder) => {
        setSuccess(ReactHtmlParser( translate("payment_order.success", { "op": result.operation_number,
            "url": `${process.env.REACT_APP_ROOT_URL}/payment-order/pending`}) as string));
        setView("principal");
        setInfoProvider({} as PaymentData);
        setCfdisSelected({} as CfdisSelected);
    }

    const payCfdi = (paySelected: CfdisSelected, paymentOrderId: string, paymentMethod: string) => {
        let info_Provider = {} as PaymentData;
        info_Provider.provider_id =paySelected.cfdis[0].metadata.provider_id;
        info_Provider.provider_name= paySelected.cfdis[0].cfdi != null ? paySelected.cfdis[0].cfdi.emisor.nombre : "";
        info_Provider.currency = paySelected.cfdis[0].currency;
        info_Provider.amount = 0;
        info_Provider.payment_method = paymentMethod;
        setInfoProvider(info_Provider);
        setPaymentOrderId(paymentOrderId);
        setFromPaymentOrder(true);
        setCfdisSelected(paySelected);
        if(paySelected.cfdis.length > 1){
            setView("select-cfdis");
        }else{
            setView("create-payment");
        }
        
    }

    switch (view) {
        case "principal": 
            return ( <PendingPaymentListCfdis {...props} next={goToSelectCfdis} goToCreatePaymentOrder={goToCreatePaymentOrder} successMessage={success} /> );
        case "select-cfdis": 
            return ( <PendingPaymentSelectCfdis onCancel={onCancel} next={goToCreatePayment} infoProvider={infoProvider}  fromPaymentOrder={fromPaymentOrder} cfdisSelected={cfdisSelected} /> );
        case "create-payment": 
            return ( <CreatePaymentView  next={savePayment} onCancel={onCancel} infoProvider={infoProvider} cfdisSelected={cfdisSelected} readOnly={false} fromPaymentOrder={fromPaymentOrder} paymentOrderId={paymentOrderId} /> );
        case "create-payment-order": 
            return ( <CreateUpdatePaymentOrderView  next={savePaymentOrder} onCancel={onCancel} cfdisSelected={cfdisSelected} view={viewPaymentOrder} payCfdi={payCfdi} /> );
        default:
            return ( <PendingPaymentListCfdis {...props} next={goToSelectCfdis} goToCreatePaymentOrder={goToCreatePaymentOrder}  /> );
    }
    
}



