import { callAPI, HTTPMethod } from "./API";
import { SystemLogList, SystemLogQueryParams } from "../model/SystemLog";

export async function list(tenantId: string, pageSize: number, offset: number, params: SystemLogQueryParams): Promise<SystemLogList> {
    let query = {
        identifier: params.identifier || "",
        startedAt: params.started_at ? params.started_at.replace(" - ", ",") : "",
        endedAt: params.ended_at ? params.ended_at.replace(" - ", ",") : "",
        eventGroup: params.event_group || "",
        event: params.event || "",
        ip: params.ip || "",
        status: params.status || "",
        user: params.user_email || "",
        provider: params.provider_name || "",
        documentName: params.document_name || "",
        documentNumber: params.document_number || "",
        pageSize: pageSize.toString(),
        offset: offset.toString(),
    }as Record<string, string>;

    return await callAPI(`/tenants/${tenantId}/system_log`, {
        method: HTTPMethod.GET,
        query: query
    });
}