import React, { useState, useEffect } from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import translate from "../i18n/Translator";

import Progress from "../components/Progress";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";

import GridDx from "../components/GridDx";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { RequisitionLine } from "../model/Requisition";
import RequisitionLineMenu from "./RequisitionLineMenu";
import { ProrationHandling } from "../model/RequisitionTypeCatalog";

export interface RequisitionLinesGridParams {
    lines: RequisitionLine[];
    currency: string;
    modifyEnable: boolean;
    onOpenEditLine(line: RequisitionLine): void;
    onOpenDeleteLine(line: RequisitionLine): void;
    prorationHandling: ProrationHandling;
}

export default function RequisitionLinesGrid(props: RequisitionLinesGridParams) {
    const [status, setStatus] = useState<string>("loaded");
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [line, setLine] = useState<RequisitionLine>();

    const getTextColumns = () => {
        let columns = ['number', 'budget_line_external_id', 'ledger_account', 'name'];
        if (ProrationHandling.PRORATION_TEMPLATE_SETUP === props.prorationHandling) {
            columns.push('proration_template_name');
        }
        if (ProrationHandling.COST_CENTER_SETUP === props.prorationHandling) {
            columns.push('cost_center_name');
        }
        return columns;
    }

    const currencyColumns = ['cost', 'to_accrue'];

    const getColumnsFormat = () => {
        let columns = [
            { columnName: 'number', width: 70, align: 'left', wordWrapEnabled: true, },
            { columnName: 'budget_line_external_id', width: 120 },
            { columnName: 'ledger_account', width: 200 },
            { columnName: 'name', wordWrapEnabled: true},
            { columnName: 'cost', align: 'right', width: 150 },
            { columnName: 'to_accrue', align: 'right', width: 150 },
            { columnName: 'menu', align: 'right', width: 50 },
        ];
        if (ProrationHandling.PRORATION_TEMPLATE_SETUP === props.prorationHandling) {
            columns.splice(4, 0, { columnName: 'proration_template_name', width: 250 });
        }
        if (ProrationHandling.COST_CENTER_SETUP === props.prorationHandling) {
            columns.splice(4, 0, { columnName: 'cost_center_name', width: 250 });
        }
        return columns;
    }

    const [columnsFormat] = useState(getColumnsFormat()) as any;

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const getNoFilteredColumns = () => {
        let columns = [
            'number',
            'external_id',
            'ledger_account',
            'name',
            'cost',
            'to_accrue'
        ];
        if (ProrationHandling.PRORATION_TEMPLATE_SETUP === props.prorationHandling) {
            columns.splice(4, 0, 'proration_template_name');
        }
        if (ProrationHandling.COST_CENTER_SETUP === props.prorationHandling) {
            columns.splice(4, 0, 'cost_center_name');
        }
        return columns;
    }

    const customPlugins = [
        <NormalTypeProvider for={getNoFilteredColumns()} editorComponent={NoFilterEditor} />,
    ];

    const [rightColumns] = useState(['menu']) as any;

    useEffect(() => {
        // eslint-disable-next-line
    }, [page, pageSize]);

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const getColumns = () => {

        let columns = [
            {
                name: 'number',
                title: translate("requisitions.lines.columns.number") as string
            },
            {
                name: 'budget_line_external_id',
                title: translate("requisitions.lines.columns.budget_line_external_id") as string
            },
            {
                name: 'name',
                title: translate("requisitions.lines.columns.name") as string
            },
            {
                name: 'ledger_account',
                title: translate("requisitions.lines.columns.ledger_account") as string
            },
            {
                name: 'cost',
                title: translate("requisitions.lines.columns.cost") as string,
                getCellValue: (row: any) => !!row?.cost ? row.cost : 0
            },
            {
                name: 'to_accrue',
                title: translate("requisitions.lines.columns.to_accrue", { currency: props.currency }) as string,
                getCellValue: (row: any) => !!row?.to_accrue ? row.to_accrue : 0
            },
            {
                name: 'menu',
                title: " "
            }
        ];
        if (ProrationHandling.PRORATION_TEMPLATE_SETUP === props.prorationHandling) {
            let templateColumn = {
                name: 'proration_template_name',
                title: translate("requisitions.lines.columns.proration_template_name") as string,
                getCellValue: (row: any) => row.proration_template_name || "---"
            };
            columns.splice(4, 0, templateColumn);
        }
        if (ProrationHandling.COST_CENTER_SETUP === props.prorationHandling) {
            let costCenterColumn = {
                name: 'cost_center_name',
                title: translate("requisitions.lines.columns.cost_center_id") as string,
                getCellValue: (row: any) => row.cost_center_name || "---"
            };
            columns.splice(4, 0, costCenterColumn);
        }
        return columns;
    }

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onClickedOptions = (line: RequisitionLine) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setLine(line);
    };

    const onClickRow = (line: RequisitionLine) => {
        if (!line) {
            return;
        }
        props.onOpenEditLine(line);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setLine(undefined);
    };

    return (
        <div>
            <Grid style={{ marginTop: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Card variant="outlined" >
                            <Grid item xs={12}>
                                <GridDx
                                    noUseId={true}
                                    loading={openBackdrop}
                                    rows={props.lines || []}
                                    page={page}
                                    pageSize={pageSize}
                                    totalRows={props.lines ? props.lines.length : 0}
                                    columns={getColumns()}
                                    columnsFormat={columnsFormat}
                                    textColumns={getTextColumns()}
                                    amountCurrencyColumns={currencyColumns}
                                    clickRowColumns={['budget_line_external_id']}
                                    onClickRow={onClickRow}
                                    onClickedOptions={onClickedOptions}
                                    onChangedPage={onChangedPage}
                                    onChangedPageSize={onChangedPageSize}
                                    customFormatColumns={customPlugins}
                                    rightColumns={rightColumns}
                                    heightTablePX={500}
                                />
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            {anchorEl && line && props.modifyEnable &&
                <RequisitionLineMenu
                    anchor={anchorEl}
                    line={line}
                    modifyEnable={props.modifyEnable}
                    onOpenEditLine={props.onOpenEditLine}
                    onOpenDeleteLine={props.onOpenDeleteLine}
                    onClose={onCloseOption}
                />
            }
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </div>
    )
}