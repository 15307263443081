import React, { useState, useEffect, useContext } from "react";
import { Typography, Button } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { getProviderExpedientHistory, getFilesExpedient } from "../api/ProviderExpedientHistoryAPI";
import { ProviderExpedientHistory } from "../model/ProviderExpedientHistory";
import Surface from "../components/Surface";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";
import { Grid, List, ListItem, ListItemText, Card, ListSubheader, IconButton, Box } from "@material-ui/core";
import { ProviderExpedientField, ProviderExpedientContact, ProviderExpedientAddress, ProviderExpedientBankAccount } from "../model/ProviderExpedient";
import Ellipsis from "../components/Ellipsis";
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFormat from "../components/DateFormat";
import { getFieldUrl } from "../api/ProviderExpedientAPI";
import { formatDateTimeToString } from "../components/DateFormat";
import { MoreVertIcon, DownloadIcon } from "../components/Icons";
import { ErrorSnackbar } from "../components/Snackbars";

export default function ProviderExpedientHistoryDetail({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [historyExpedient, setHistoryExpedient] = useState<ProviderExpedientHistory>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        setStatus("loading");
        getProviderExpedientHistory(context.session!.tenant!.id, match.params.providerId ? match.params.providerId : context.session!.provider!.id, match.params.historyExpedientId).then((response) => {
            setHistoryExpedient(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [context, match.params]);

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loaded" || !historyExpedient) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        )
    }

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const downloadFiles = () => {
        getFilesExpedient(context.session!.tenant!.id, match.params.providerId ? match.params.providerId : context.session!.provider!.id, match.params.historyExpedientId)
        .then(response => {
            window.open(response.url, "_blank");
        }).catch((error) => {
            setError(translate(`expedients.not_files_in_expedient`) as string);
        });
    };

    return (
        <Surface title={historyExpedient.provider ? historyExpedient.provider.name : ""}
            subtitle={translate("expedients.single_at_date", { "date": formatDateTimeToString(historyExpedient.created_at) }) as string} backButton
            icon={<MoreVertIcon />}
            className="PaperPagination PaperTabContainer"
            backTo={historyExpedient.provider && match.params.providerId ? `/providers/${historyExpedient.provider.id}?referer=expedients&tab=validation` : "/expedients/overview"}
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Button
                            color="primary" variant="text"
                            onClick={downloadFiles}
                            startIcon={<DownloadIcon />}>
                            {translate("expedients.download_files" as string)}
                        </Button>
                    </Grid>
                </Grid>
            }>
            <Box pt={2} px={2}>
                <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                    {historyExpedient.expedient.fields && Object.values(historyExpedient.expedient.fields).length > 0 ?
                        <Grid item xs={6}>
                            {Object.values(historyExpedient.expedient.fields).filter((field: ProviderExpedientField) => field.configuration.type !== "FILE").length > 0 ?
                                <Card variant="outlined">
                                    <ListSubheader>{translate("providers.general_data.title")}</ListSubheader>
                                    <Box pt={2} px={2}>
                                        <Grid container alignItems="flex-start" justify="space-between">
                                            <Grid item>
                                                <List dense disablePadding>
                                                    {Object.values(historyExpedient.expedient.fields).filter((field: ProviderExpedientField) => field.configuration.type !== "FILE").map((field: ProviderExpedientField, index: number) => (
                                                        <ListItem disableGutters>
                                                            <ListItemText primary={field.configuration.name} secondary={field.configuration.type === "DATE" && field.value ? <DateFormat date={new Date(field.value)} format="L" /> : field.value} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Card> : undefined}
                            {Object.values(historyExpedient.expedient.fields).filter((field: ProviderExpedientField) => field.configuration.type === "FILE").length > 0 ?
                                <Card variant="outlined">
                                    <ListSubheader>{translate("providers.expedient_history.title_documents")}</ListSubheader>
                                    <Box pt={2} px={2}>
                                        <Grid container alignItems="flex-start" justify="space-between">
                                            <Grid item>
                                                <List dense disablePadding>
                                                    {Object.values(historyExpedient.expedient.fields).filter((field: ProviderExpedientField, index: number) => field.configuration.type === "FILE").map((field: ProviderExpedientField, index: number) => (
                                                        <ListItem disableGutters>
                                                            {field.value ?
                                                                <IconButton color="primary" size="medium" target="_blank" href={field.value}>
                                                                    <GetAppIcon />
                                                                    <Ellipsis text={field.configuration.name} lenght={100} uppercased={false} />
                                                                </IconButton> :
                                                                <IconButton color="default" size="medium">
                                                                    <GetAppIcon />
                                                                    <Ellipsis text={field.configuration.name} lenght={100} uppercased={false} />
                                                                </IconButton>
                                                            }
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Card> : undefined}
                        </Grid>
                        : undefined}
                    {historyExpedient.expedient.bank_accounts && Object.values(historyExpedient.expedient.bank_accounts).filter((bankAccount: ProviderExpedientBankAccount) => bankAccount.value !== undefined).length > 0 ?
                        <Grid item xs={6}>
                            <Card variant="outlined">
                                <ListSubheader>{translate("expedients.bank_accounts.title")}</ListSubheader>
                                <Box pt={2} px={2}>
                                    <List component="nav" dense >
                                        {Object.values(historyExpedient.expedient.bank_accounts).filter((bankAccount: ProviderExpedientBankAccount) => bankAccount.value !== undefined).map((bankAccount: ProviderExpedientBankAccount, index: number) => (
                                            <>
                                                <ListItem disableGutters>
                                                    <ListItemText primary={bankAccount.configuration.name} />
                                                    <IconButton color="primary" size="medium" target="_blank" href={bankAccount.value ? bankAccount.value.cover : ""}>
                                                        <GetAppIcon />
                                                        <Ellipsis text={translate("providers.expedient_history.download_bank_cover") as string} lenght={100} uppercased={false} />
                                                    </IconButton>
                                                </ListItem>
                                                {bankAccount.value && <>
                                                    <ListItemText secondary={bankAccount.value.owner} />
                                                    <ListItemText secondary={bankAccount.value.bank_name} />
                                                    <ListItemText secondary={translate("providers.expedient_history.suc") + " " + bankAccount.value.subsidiary + " " + bankAccount.value.subsidiary_number} />
                                                    <ListItemText secondary={translate("providers.expedient_history.cta") + " " + bankAccount.value.account} />
                                                    <ListItemText secondary={translate("bank_account.clabe") + " " + bankAccount.value.clabe} />
                                                    <ListItemText secondary={bankAccount.value.currency} />
                                                    <ListItemText secondary={translate("countries." + bankAccount.value.country)} />
                                                </>}
                                            </>
                                        ))}
                                    </List>
                                </Box>
                            </Card>
                        </Grid>
                        : undefined}
                    {historyExpedient.expedient.contacts && Object.values(historyExpedient.expedient.contacts).filter((contact: ProviderExpedientContact) => contact.value !== undefined).length > 0 ?
                        <Grid item xs={6}>
                            <Card variant="outlined">
                                <ListSubheader>{translate("expedients.contacts.title")}</ListSubheader>
                                <Box pt={2} px={2}>
                                    <Grid container alignItems="flex-start" justify="space-between">
                                        <Grid item>
                                            <List dense disablePadding>
                                                {Object.values(historyExpedient.expedient.contacts).filter((contact: ProviderExpedientContact) => contact.value !== undefined).map((contact: ProviderExpedientContact, index: number) => (
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText primary={contact.configuration.name} />
                                                        </ListItem>
                                                        {contact.value && <>
                                                            <ListItemText secondary={contact.value.first_name + " " + contact.value.last_name} />
                                                            {contact.value.phone &&
                                                                <ListItemText secondary={contact.value.phone} />
                                                            }
                                                            <ListItemText secondary={contact.value.email} />
                                                        </>}
                                                    </>
                                                ))}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                        : undefined}
                    {historyExpedient.expedient.addresses && Object.values(historyExpedient.expedient.addresses).filter((address: ProviderExpedientAddress) => address.value !== undefined).length > 0 ?
                        <Grid item xs={6}>
                            <Card variant="outlined">
                                <ListSubheader>{translate("expedients.addresses.title")}</ListSubheader>
                                <Box pt={2} px={2}>
                                    <Grid container alignItems="flex-start" justify="space-between">
                                        <Grid item>
                                            <List dense disablePadding>
                                                {Object.values(historyExpedient.expedient.addresses).filter((address: ProviderExpedientAddress) => address.value !== undefined).map((address: ProviderExpedientAddress, index: number) => (
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText primary={address.configuration.name} />
                                                        </ListItem>
                                                        {address.value && <>
                                                            <ListItemText secondary={address.value.street + " " + address.value.exterior_number + " " + address.value.interior_number} />
                                                            <ListItemText secondary={address.value.suburb} />
                                                            <ListItemText secondary={address.value.city} />
                                                            <ListItemText secondary={address.value.country} />
                                                            <ListItemText secondary={address.value.zip_code} />
                                                            {address.value.type &&
                                                                <ListItemText secondary={translate("expedients.addresses.types." + address.value.type) as string} />
                                                            }
                                                        </>}
                                                    </>
                                                ))}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                        : undefined}
                </Grid>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            </Box>
        </Surface>
    );
}

interface FileComponentProps {
    value: string;
    tenantId: string;
    providerId: string;
    fieldId: string;
    name: string;
}

export function FileComponent(props: FileComponentProps) {
    const [url, setUrl] = useState<string>(props.value);
    useEffect(() => {
        if (props.value && props.value !== "" && !props.value.startsWith("http")) {
            getFieldUrl(props.tenantId, props.providerId, props.fieldId).then((url) => {
                setUrl(url);
            }).catch((error) => {
            });
        }
    }, [props.fieldId, props.providerId, props.tenantId, props.value]);

    return <>
        {url ?
            <IconButton color="primary" size="medium" target="_blank" href={url}>
                <GetAppIcon />
                <Ellipsis text={props.name} lenght={100} uppercased={false} />
            </IconButton> :
            <IconButton color="default" size="medium">
                <GetAppIcon />
                <Ellipsis text={props.name} lenght={100} uppercased={false} />
            </IconButton>
        }
    </>;
}
