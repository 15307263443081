import React from "react";
import NumberFormat from "react-number-format";
import { Typography, List, ListItem, ListItemText, ListItemIcon, Divider, ListItemSecondaryAction } from "@material-ui/core";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { EfoValidationAll } from "../model/Efos";
import { Cff69ValidationAll } from "../model/Cff69";
import { ProviderEfoIcon, Provider69CffIcon, ProviderStatusIcon } from "./ProviderIcons";

interface AllProvidersEfosVerificationPopupProps {
    data: EfoValidationAll;
    onClose(): any;
}

export function AllProvidersEfosVerificationPopup(props: AllProvidersEfosVerificationPopupProps) {
    return (
        <DialogPopup title={translate("efo.all")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            <EfosList values={props.data} />
        </DialogPopup>
    );
}

interface AllProvidersCff69VerificationPopupProps {
    data: Cff69ValidationAll;
    onClose(): any;
}

export function AllProvidersCff69VerificationPopup(props: AllProvidersCff69VerificationPopupProps) {
    return (
        <DialogPopup title={translate("cff_69.all_verification")} open onClose={props.onClose} disableBackdropClick={false} disableEscapeKeyDown={false}>
            <Cff69List values={props.data.validations} extraCounter={props.data.clean} />
        </DialogPopup>
    );
}

export interface VerificationListProps {
    values: any;
    dense?: boolean;
    header?: boolean;
    extraCounter?: number;
}

export function EfosList(props: VerificationListProps) {
    let values = {
        "LIMPIO": props.values.clean || props.values["LIMPIO"] || 0,
        "PRESUNTO": props.values.presumed || props.values["PRESUNTO"] || 0,
        "DEFINITIVO": props.values.definitive || props.values["DEFINITIVO"] || 0,
        "DESVIRTUADO": props.values.detracted || props.values["DESVIRTUADO"] || 0,
        "SENTENCIA_FAVORABLE": props.values.favorable_sentence || props.values["SENTENCIA_FAVORABLE"] || 0
    } as any;
    return (
        <InternVerificationList {...props} headerText={translate("efo.single") as string}
            text={(status) => translate(`efo.status.${status}`) as string}
            icon={(status) => <ProviderEfoIcon status={status} />}
            values={values} />
    );
}

export function Cff69List(props: VerificationListProps) {
    let values = {
        "LIMPIO": props.extraCounter || props.values["LIMPIO"] || 0,
        "NO_LOCALIZADO": props.values["NO_LOCALIZADOS"]?.in_list || props.values["NO_LOCALIZADO"] || 0,
        "CANCELADO" : props.values["CANCELADOS"]?.in_list || props.values["CANCELADO"] || 0,
        "EXIGIBLE" : props.values["EXIGIBLES"]?.in_list || props.values["EXIGIBLE"] || 0,
        "FIRME" : props.values["FIRMES"]?.in_list || props.values["FIRME"] || 0,
        "SENTENCIADO" : props.values["SENTENCIAS"]?.in_list || props.values["SENTENCIADO"] || 0
    } as any;
    return (
        <InternVerificationList {...props} headerText={translate("cff_69.single") as string}
            text={(status) => translate(`cff_69.categories.${status}`) as string}
            icon={(status) => <Provider69CffIcon status={status} />}
            values={values} />
    );
}

export function ProviderStatusList(props: VerificationListProps) {
    let values = {
        "ENABLED": props.values["ENABLED"] || 0,
        "DISABLED": props.values["DISABLED"] || 0
    } as any;

    return (
        <InternVerificationList {...props} headerText={translate("providers.status.title") as string}
            text={(status) => translate(`providers.status.${status}`) as string}
            icon={(status) => <ProviderStatusIcon status={status} />}
            values={values} />
    );
}

interface InternVerificationListProps extends VerificationListProps {
    values: any;
    headerText?: string;
    text(status: string): string;
    icon(status: string): React.ReactElement;
}

export function InternVerificationList(props: InternVerificationListProps) {
    return (
        <List dense={props.dense}>
            {props.header && (
                <ListItem>
                    <ListItemText primary={props.headerText} />
                </ListItem>
            )}
            {Object.keys(props.values).map((status: string) => (
                <StatusListItem key={status}
                    status={status}
                    value={props.values[status] as number}
                    text={props.text}
                    icon={props.icon} />
            ))}
        </List>
    );
}

interface StatusListItemProps {
    status: string;
    value: number;
    text(status: string): string;
    icon(status: string): React.ReactElement;
}

function StatusListItem(props: StatusListItemProps) {
    return (
        <ListItem>
            <ListItemIcon>
                {props.icon(props.status)}
            </ListItemIcon>
            <ListItemText primary={props.text(props.status)} />
            <ListItemSecondaryAction>
                <Typography variant="h6">
                    <NumberFormat value={props.value} displayType="text" />
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

interface ImportResultItemProps {
    efo: EfoValidationAll;
    cff_69: Cff69ValidationAll;
}

export function ImportResultItem(props: ImportResultItemProps) {
    return (
        <List>
            <Divider />
            {!props.efo.queued &&
                <div>
                    <ListItem>
                        <ListItemText primary={translate("providers.import.title_notif_efo") as string} />
                    </ListItem>
                    <EfosList values={props.efo} />
                    <Divider />
                    <ListItem>
                        <ListItemText primary={translate("providers.import.title_notif_cff_69") as string} />
                    </ListItem>
                    <Cff69List values={props.cff_69.validations} extraCounter={props.cff_69.clean}/>
                </div>
            }
            {props.efo.queued &&
                <ListItem >
                    <ListItemText primary={translate("providers.import.notif_efo")} />
                </ListItem>
            }
        </List>
    );
}