import { callAPI, HTTPMethod } from "./API";
import { ActionsQueryParams, Actions, Categories } from "../model/Action";

export async function listActions(page: number, offset: number, params: ActionsQueryParams): Promise<Actions> {
    return await callAPI(`/actions`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: page.toString(),
            offset: offset.toString(),
            search: params.search,
            category: params.category,
            roleId: params.rol,
            actionsExclude: params.actionsExclude ? params.actionsExclude.join(",") : ""
        }
    });
}

export async function listActionCategories(page: number, offset: number): Promise<Categories> {
    return await callAPI(`/actions/categories`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}