import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { PayrollsQueryParams, PayrollRequest } from "../model/Payroll";
import { Cfdis, Cfdi } from "../model/Cfdi";

const basePath = (tenantId: string) => `/tenants/${tenantId}/payrolls`;

export async function listPayrolls(tenantId: string, page: number, offset: number, params: PayrollsQueryParams): Promise<Cfdis> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search || "",
            employeeId: params.employeeId || "",
            date: params.date || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createPayroll(tenantId: string, request: PayrollRequest): Promise<Cfdis> {
    const data = new FormData();
    data.append("file", request.file as Blob, request.file.name);

    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getPayroll(tenantId: string, id: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updatePayroll(tenantId: string, id: string, request: PayrollRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deletePayroll(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getPayrollXmlUrl(tenantId: string, id: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId)}/${id}/xml`);
}

export async function getPayrollPdfUrl(tenantId: string, id: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId)}/${id}/pdf`);
}