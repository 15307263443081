import React, { useContext, useState } from "react";
import { Link as RouterLink, Route, Switch, Redirect } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Container, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import translate from "../i18n/Translator";
import { AppContext, AppContextData } from "../context/AppContext";
import { routes, RouteItem, RouteGenericItem } from "./Routes";
import { hasDashboard } from "../model/Role";
import IntegrationContainer from "../integrations/IntegrationContainer";
import DynamicIcon from "../components/DynamicIcon";

const style = makeStyles(theme => ({
    expand: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

const mapIntegrationMenu = (context: AppContextData): RouteItem | undefined => {
    const integration = context.session?.integration;
    if (integration) {
        const routes = integration.routes.map(el => {
            return {
                title: "",
                titleRaw: el.title,
                icon: <DynamicIcon icon={el.icon} includeColor={false} />,
                path: `/integrations${el.path}`,
                hidden: el.hidden,
                component: IntegrationContainer,
            } as RouteItem;
        })

        return {
            title: "",
            titleRaw: integration.group,
            icon: <DynamicIcon icon={integration.icon} includeColor={false} />,
            path: "",
            component: IntegrationContainer,
            items: routes,
        } as RouteItem;
    }
}

export const curateRoutes = (context: AppContextData): RouteItem[] => {
    let curated: RouteItem[] = routes.map((el: RouteGenericItem) => {
        if (el === "integration") {
            return mapIntegrationMenu(context);
        }
        return el as RouteItem;
    }).filter((route: RouteItem | undefined) => {
        return !!route && (!route.grants || context.isGrantedAny(route.grants)) && (!route.exclude || !context.isGrantedAny(route.exclude));
    }) as RouteItem[];

    const roleId = context.session?.role?.id;
    const excludeDashboard = !hasDashboard(roleId);
    if (excludeDashboard) {
        curated = curated.filter(el => el.path !== "/");
    }

    curated.filter((route) => route.items && route.items.length > 0).forEach((route) => {
        route.items = route.items!.filter((subroute: RouteItem) => {
            return (!subroute.grants || context.isGrantedAny(subroute.grants)) && (!subroute.exclude || !context.isGrantedAny(subroute.exclude));
        });
    });

    return curated;
};

export default function AppRouter() {
    const context = useContext(AppContext);
    let curated = curateRoutes(context);
    let mappings: RouteItem[] = [];

    curated.forEach((route) => {
        if (route.path) {
            mappings.push(route);
        }

        route.items?.filter((subroute: RouteItem) => !!subroute.path).forEach((subroute) => {
            mappings.push(subroute);
        });
    });

    // primera ruta visible y sin parámetros
    const fixedRoute = mappings.filter(el => !el.hidden).find(el => el.path.indexOf(":") === -1);
    const redirectTo = fixedRoute?.path || "/";

    return (
        <Container maxWidth={false}>
            <Switch>
                {mappings.map((route, index) => (
                    <Route key={index} exact={route.exact} path={route.path} component={route.component} />
                ))}
                <Redirect from="/*" to={redirectTo} />
            </Switch>
        </Container>
    );
}

interface RouteListProps {
    routes: RouteItem[];
}

function RouteList(props: RouteListProps) {
    return (
        <List dense className="DrawerList">
            {props.routes.filter((route, _index) => !route.hidden).map((route, index) => (
                <RouteListItem key={index} route={route} index={index} />
            ))}
        </List>
    );
}

interface RouteListItemProps {
    index: number;
    route: RouteItem
}

function RouteListItem(props: RouteListItemProps) {
    const classes = style();
    const [open, setOpen] = useState<boolean>(false);

    if (props.route.path) {
        return (
            <ListItem key={props.index} button component={RouterLink} to={props.route.path}>
                <ListItemIcon>{props.route.icon}</ListItemIcon>
                <ListItemText primary={props.route.titleRaw ?? translate(props.route.title)} />
            </ListItem>
        );
    }

    return (
        <div>
            <ListItem key={props.index} button onClick={() => setOpen(!open)}>
                <ListItemIcon>{props.route.icon}</ListItemIcon>
                <ListItemText primary={props.route.titleRaw ?? translate(props.route.title)} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {props.route.items && props.route.items.length > 0 && (
                <Collapse in={open} timeout="auto" unmountOnExit className={classes.expand}>
                    <RouteList routes={props.route.items} />
                </Collapse>
            )}
        </div>
    );
}

export function AppMenu() {
    const context = useContext(AppContext);
    let curated = curateRoutes(context);

    return (
        <RouteList routes={curated} />
    );
}
