import React, { useState } from "react";
import { Menu, ListItemIcon, Fade, Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import translate from "../i18n/Translator";
import { ErrorSnackbar } from "../components/Snackbars";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';

interface RefundMenuOptionsProps {
    anchor: HTMLElement | null;
    totalItems?: number;
    onClose(): any;
    onExportWithParams(): any;
    onExportSelected(): any;
}

export default function RefundMenuOptions(props: RefundMenuOptionsProps) {
    const [error, setError] = useState<string>();
    const [openExport, setOpenExport] = useState<boolean>(false);

    const handleClickExport = () => {
        setOpenExport(!openExport);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <Menu
            id="refund-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
            autoFocus={false}
            variant="selectedMenu"
        >
            <ListItem button onClick={handleClickExport} dense>
                <ListItemIcon>
                    <ImportExportIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText primary={translate("refunds.export.title")} />
                {openExport ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openExport} timeout="auto" unmountOnExit>
                <List component="div">
                    <ListItem button onClick={props.onExportSelected} dense >
                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                            <Selected fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("refunds.export.selecteds")} />
                    </ListItem>
                    <ListItem button onClick={props.onExportWithParams} dense >
                        <ListItemIcon style={{ "paddingLeft": "4%" }}>
                            <DownloadList fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={translate("refunds.export.all_in_view")} />
                    </ListItem>
                </List>
            </Collapse>

            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Menu>
    );

}