import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { SpecificReceptionRule } from "../model/SpecificReceptionRule";

import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";

interface SpecificReceptionRuleMenuProps {
    specificRule: SpecificReceptionRule;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function SpecificReceptionRuleMenu(props: SpecificReceptionRuleMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="cfdi-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGranted("ProvidersUpdate") &&
                <MenuItem button component={Link} to={`/reception-specific-rules/${props.specificRule.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }

        </Menu>);
}
