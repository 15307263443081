import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import AuxiliarDocumentsIcon from "@material-ui/icons/FileCopy";

import { createAuxiliarDocument, getAuxiliarDocument, updateAuxiliarDocument } from "../api/AuxiliarDocumentAPI";
import translate from "../i18n/Translator";
import { AuxiliarDocument, AuxiliarDocumentRequest } from "../model/AuxiliarDocument";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import UploaderInput from "../components/UploaderInput";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";

export default function AuxiliarDocumentForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const auxiliarDocumentId = match.params.auxiliarDocumentId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<AuxiliarDocumentRequest>({} as AuxiliarDocumentRequest);
    const [validations, setValidations] = useState({} as any);

    const getRoles = () => {
        return context.session?.roles_by_tenant.map(rol => {
            if(rol.role_parent_id){
                return { title: rol.description, value: rol.id } as MultiselectValue;
            }
            return { title: translate(`users.roles.${rol.id}`) as string, value: rol.id } as MultiselectValue;
        }) || [];
    };

    const [elementsStatus] = useState<MultiselectValue[]>(getRoles());
    const [valuesStatus, setValuesStatus] = useState<MultiselectValue[]>([]);

    const submitPromise = (): Promise<AuxiliarDocument> => {
        if (auxiliarDocumentId) {
            return updateAuxiliarDocument(tenantId, auxiliarDocumentId, request);
        }
        return createAuxiliarDocument(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (auxiliarDocumentId) {
            getAuxiliarDocument(tenantId, auxiliarDocumentId).then((auxiliarDocument) => {
                setRequest({
                    name: auxiliarDocument.name,
                    path: auxiliarDocument.path,
                    role_ids: auxiliarDocument.roles.map(role => role.id).join(",")
                } as AuxiliarDocumentRequest);
                setValuesStatus(auxiliarDocument.roles.map(rol => {
                    if(rol.role_parent_id){
                        return { title: rol.description, value: rol.id } as MultiselectValue;
                    }
                    return { title: translate(`users.roles.${rol.id}`) as string, value: rol.id } as MultiselectValue;
                }));
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    }, [tenantId, auxiliarDocumentId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onStatusChanged = (selected: string[]) => {
        setValuesStatus(elementsStatus ? elementsStatus.filter(el => selected.indexOf(el.value) >= 0) :[]);
        if (selected) {
            setRequest({ ...request, role_ids: selected.join(",") });
        } else {
            setRequest({ ...request, role_ids: "" });
        }
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((auxiliarDocument) => {
            history.push("/auxiliar-documents");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(auxiliarDocumentId ? "auxiliardocuments.edit" : "auxiliardocuments.new")} icon={<AuxiliarDocumentsIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={ request.name }
                                        label={translate("auxiliardocuments.name") as string}
                                        required={ true } disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <MultiselectDropList
                                        title={translate("auxiliardocuments.role_ids") as string}
                                        margin="dense"
                                        elementos={elementsStatus ? elementsStatus : []}
                                        onChanged={onStatusChanged}
                                        value={valuesStatus} />
                                </Grid>
                                <Grid item xs={12}>
                                    <UploaderInput id="path" name="path"
                                            value={request.path || ""}
                                            label={translate("auxiliardocuments.path") as string}
                                            required={true} disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(auxiliarDocumentId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}