import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import { ReceptionRuleColumnTitle, ReceptionRuleColumnValue } from "./ReceptionRuleChecks"
import { CFDI_USES } from "../catalogs/CatCfdi";
import Progress from "../components/Progress";
import { ReceptionRuleUsesRequest } from "../model/ReceptionRule";

interface ReceptionRuleUsesTabProps {
    selected: string[];
    update(selected: ReceptionRuleUsesRequest): any;
}

export default function ReceptionRuleUsesTab(props: ReceptionRuleUsesTabProps) {
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [selected, setSelected] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("loading");

    const load = () => {
        setSelected(props.selected);
        setStatus("loaded");
    };

    useEffect(load, [props.selected]);

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const handleCheck = (code: string) => {
        let items = selected.length === 0 ? ([] as string[]) : selected.join(",").split(",");
        const index = selected.indexOf(code);
        if (index < 0) {
            items.push(code);
        } else {
            items.splice(index, 1);
        }
        setSelected(items);
        props.update({ uses: items } as ReceptionRuleUsesRequest);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = CFDI_USES.map(code => code);
        } else {
            items = [] as string[];
        }
        setSelected(items);
        props.update({ uses: items } as ReceptionRuleUsesRequest);
    };

    if (status === "loading") {
        return <Progress />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Gridable
                    items={CFDI_USES}
                    loading={false}
                    empty={translate(`reception_rule.use_cfdi.empty`) as string}
                    columns={[
                        {
                            title: (
                                <ReceptionRuleColumnTitle
                                    disabled={false}
                                    total={CFDI_USES.length}
                                    selected={selected.length}
                                    callbacks={{
                                        handleCheck: handleCheck,
                                        handleAllChecks: handleAllChecks
                                    }}
                                    showChecks={true} />
                            ),
                            converter: (code) => (
                                <ReceptionRuleColumnValue
                                    disabled={false}
                                    code={code}
                                    checked={selected.indexOf(code) >= 0}
                                    callbacks={{
                                        handleCheck: handleCheck,
                                        handleAllChecks: handleAllChecks
                                    }}
                                    showChecks={true}
                                    getValue={(code) => code } />
                            ),
                            fullWidth: true,
                            xs: 5,
                            sm: 2,
                            md: 3,
                            lg: 2,
                            xl: 2
                        },
                        {
                            title: translate("reception_rule.description") as string,
                            converter: (code) => translate(`reception_rule.cfdi_uses.${code}`),
                            xs: true
                        }
                    ]}
                />
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Grid>
    );
}
