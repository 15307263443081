import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import { CfdiHistories } from "../model/CfdiHistory";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";

export interface CfdiHistoryParams {
    tenantId: string;
    cfdiId: string;
    history: CfdiHistories;
}

export default function CfdiHistoryTab(params: CfdiHistoryParams) {

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CfdiHistories>();

    const load = () => {
        setStatus("loading");
        setData(params.history);
        setStatus("loaded");
    };

    useEffect(load, [params]);

    const fillStatus = (status: string, description: string, status_erp: string, description_erp: string) => {
        if (status !== undefined && status !== "") {
            if (status_erp !== "") {
                return description_erp !== "" ? description_erp : status_erp;
            } else {
                return description;
            }
        } else {
            return "---"
        }
    };

    return (
        <Gridable
            items={data ? data.items : []}
            loading={status === "loading"}
            error={status !== "loading" && status !== "loaded" ? status : undefined}
            empty={translate("cfdis.history.empty") as string}
            columns={[
                {
                    title: translate("cfdis.history.created_at") as string,
                    converter: (history) => (<DateFormat date={history.created_at} format="DD-MM-YYYY h:mm a" zone={true} />),
                    xs: 3,
                    sm: 3,
                    md: 3,
                    xl: 3
                },
                {
                    title: translate("cfdis.history.previous_status") as string,
                    converter: (history) => (
                        <Ellipsis 
                            lenght={100} uppercased={false}
                            text={fillStatus(history.previous_status, history.previous_status_description, 
                                history.previous_status_erp ? history.previous_status_erp : "", 
                                history.previous_status_erp_description ? history.previous_status_erp_description : "") as string}  
                        />),
                    xs: false,
                    sm: false,
                    md: 2,
                    xl: 2
                },
                {
                    title: translate("cfdis.history.user") as string,
                    converter: (history) => (<Ellipsis text={history.user ? history.user.name : "---"} uppercased={false} />),
                    xs: 3,
                    sm: 3,
                    md: 2,
                    xl: 2
                },
                { 
                    title: translate("cfdis.history.status_title") as string,
                    converter: (history) => (
                        <Ellipsis  
                            lenght={100} uppercased={false}
                            text={fillStatus(history.status, history.status_description, 
                                history.status_erp ? history.status_erp : "", 
                                history.status_erp_description ? history.status_erp_description : "") as string} 
                        />),
                    xs: 3,
                    sm: 3,
                    md: 2,
                    xl: 2
                },
                {
                    title: translate("cfdis.history.comments") as string,
                    converter: (history) => (history.comments !== undefined ?
                        <div style={{ "overflowY": "scroll" }} className="content-container" >{history.comments} </div> : undefined),
                    xs: 3,
                    sm: 3,
                    md: 3,
                    xl: 3
                },
            ]} />
    )
}