import React, { useState } from "react";
import { ListItem, ListItemIcon, Grid, ListItemText, List, Icon } from "@material-ui/core";
import translate from "../../i18n/Translator";

import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

interface ExportProrateCfdiMenuProps {
    onDownloadProratedExcelSelected(): void;
    onDownloadProratedExcel(): void;
}

export default function ExportProrateCfdiMenu(props: ExportProrateCfdiMenuProps) {
    const [openExportProratedExcel, setOpenExportProratedExcel] = useState<boolean>(false);

    const handleClickExportProratedExcel = () => {
        setOpenExportProratedExcel(!openExportProratedExcel);
    };

    return (
        <Grid >
            <Grid item xs="auto" >
                <ListItem button onClick={handleClickExportProratedExcel} dense>
                    <ListItemIcon>
                        <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("cfdis.menus.export_prorated_excel")} />
                    {openExportProratedExcel ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Grid>
            <Grid item xs="auto" >
                <Collapse in={openExportProratedExcel} timeout="auto" unmountOnExit>
                    <List component="div"  >
                        <ListItem button onClick={props.onDownloadProratedExcelSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.selected")} />
                        </ListItem>
                        <ListItem button onClick={props.onDownloadProratedExcel} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                        </ListItem>
                    </List>
                </Collapse>
            </Grid>
        </Grid>
    );
}