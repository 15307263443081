import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";

import { Grid, IconButton, Divider, Button, Fab, Hidden, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WorkIcon from "@material-ui/icons/Work";
import AddIcon from "@material-ui/icons/Add";

import { listConnectors } from "../api/ConnectorAPI";
import { AppContext } from "../context/AppContext";
import { Connector, Connectors, ConnectorsQueryParams } from "../model/Connector";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import ConnectorMenu from "./ConnectorMenu";
import { RouterParams } from "../router/RouterParams";
import DateFormat from "../components/DateFormat";

export default function ConnectorsList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ConnectorsQueryParams => {
        return {
            "search": typeof qs["search_connector"] === "string" ? qs["search_connector"] as string : ""
        } as ConnectorsQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Connectors>();
    const [params, setParams] = useState<ConnectorsQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ConnectorsQueryParams>(paramsFromQueryString);

    const [connector, setConnector] = useState<Connector>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    useEffect(() => {
        let offset = getOffset(page, pageSize);
        listConnectors(match.params.tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });

    }, [match.params.tenantId, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let qs = queryString.parse(window.location.search);
        qs["search_connector"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (connector: Connector) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setConnector(connector);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setConnector(undefined);
    };

    const onClickRow = (connector: Connector) => {
        history.push(`/tenants/${match.params.tenantId}/connectors/${connector.id}/edit`)
    };

    return (
        <Pagination title={translate("connectors.title")} subtitle={data?.tenant?.name} icon={<WorkIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("ConnectorsCreate") ?
                (<Link to={`/tenants/${match.params.tenantId}/connectors/new`} >
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}
            backButton>
            <Hidden xsUp>
                <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                    <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                        <Grid item xs={12} sm>
                            <ValidatedInput type="text" id="search" name="search" label={translate("connectors.filter") as string}
                                margin="dense" disabled={false}
                                value={workingParams.search} onValueChanged={onFilterChanged} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button type="submit" variant="outlined" color="secondary" size="medium">
                                {translate("buttons.search")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Divider />
            </Hidden>
            <Gridable
                items={data?.items || []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("connectors.empty", { "tenant": data?.tenant?.name || "" }) as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("connectors.name") as string,
                        converter: (connector) => connector.name ?? "---",
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("connectors.family.title") as string,
                        converter: (connector) => (
                            <>
                                <strong>
                                    {translate(`connectors.family.${connector.family}`)}
                                </strong>
                                {connector.available_for_zip && (
                                    <>
                                        <br />
                                        <Typography variant="caption" color="textSecondary">
                                            {translate("connectors.available_for_zip_short")}
                                        </Typography>
                                    </>
                                )}
                            </>
                        ),
                        fullWidth: true,
                        xs: 4,
                        sm: 3,
                        md: 3,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("connectors.direction.title") as string,
                        converter: (connector) => translate(`connectors.direction.${connector.direction}`),
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("connectors.format") as string,
                        converter: (connector) => connector.format,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("connectors.created_at") as string,
                        converter: (connector) => <DateFormat date={connector.created_at} format="L" />,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (connector) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(connector)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {connector && anchorEl &&
                <ConnectorMenu tenantId={match.params.tenantId} connector={connector} anchor={anchorEl} onClose={onCloseOption} queryString={queryString.stringify(qs)} />
            }
        </Pagination>
    );
}