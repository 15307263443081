import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Direction, Family, Connector } from '../model/Connector';
import ConnectorSelector from "./ConnectorSelector";
import { downloadImportTemplate as providerTemplate } from "../api/ProviderAPI";
import { downloadImportTemplate as paymentTemplate } from "../api/PaymentApi";
import { downloadImportTemplate as purchaseOrderTemplate } from "../api/PurchaseOrderAPI";
import { downloadImportTemplate as warehouseDeliveryTemplate } from "../api/WarehouseDeliveryAPI";
import { downloadImportTemplate as customerDeliveryTemplate } from "../api/CustomerAPI";
import { downloadImportTemplate as catalogTemplate } from "../api/CatalogAPI";
import { downloadImportTemplate as budgetLinesTemplate} from "../api/BudgetApi";

interface ConnectorTemplateDownloaderProps {
    tenantId: string;
    family: Family;
    companyId?: string;
    onClose(): any;
}

export default function ConnectorTemplateDownloader(props: ConnectorTemplateDownloaderProps) {
    const [connector, setConnector] = useState<Connector>();
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (!connector) return;

        let promise: Promise<string>;
        switch (props.family) {
            case Family.PROVIDERS:
                promise = providerTemplate(props.tenantId, connector.id);
                break;
            case Family.PAYMENTS:
                promise = paymentTemplate(props.tenantId, connector.id);
                break;
            case Family.PURCHASE_ORDERS:
                promise = purchaseOrderTemplate(props.tenantId, connector.id);
                break;
            case Family.WAREHOUSE_DELIVERIES:
                promise = warehouseDeliveryTemplate(props.tenantId, connector.id);
                break;
            case Family.CUSTOMERS:
                promise = customerDeliveryTemplate(props.tenantId, connector.id);
                break;
            case Family.CATALOGS:
                promise = catalogTemplate(props.tenantId, connector.id);
                break;
            case Family.BUDGETS:
                promise = budgetLinesTemplate(props.tenantId, connector.id, props.companyId || "");
                break;
            default:
                return;
        }

        promise.then(setUrl).catch(error => setUrl(undefined));
    }, [props.tenantId, props.family, connector]);

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick
            onClose={props.onClose}
            aria-labelledby="templ-downloader-title"
            aria-describedby="templ-downloader-description">
            <DialogTitle id="templ-downloader-title" disableTypography>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{translate("connectors.template_downloader.title")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{translate(`connectors.family.${props.family}`)}</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="DialogPopup" dividers>
                <ConnectorSelector tenantId={props.tenantId}
                    family={props.family}
                    direction={Direction.INPUT}
                    onChangedConnector={setConnector} 
                    disableCustom />
            </DialogContent>
            <DialogActions>
                <Grid item xs={12}>
                    <Box px={1}>
                        <Grid container justify="flex-end" spacing={1}>
                            <Grid item xs="auto">
                                <Button onClick={props.onClose} variant="outlined" color="primary">
                                    {translate("buttons.close")}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button variant="contained" color="primary" disabled={!url} component="a" href={url || ""} target="_blank" rel="noopener noreferrer">
                                    {translate("buttons.download")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}