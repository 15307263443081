import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import PaymentOrderCfdiList from "./PaymentOrderCfdiList";
import { PaymentOrder, PaymentOrderCfdiItem, ExecutePaymentFileRequest } from "../model/PaymentOrder";
import { InputRef } from "../components/ValidatedInput";
import PaymentOrderFilesSection from "./PaymentOrderFilesSection";
import PaymentOrderCommentsSection from "./PaymentOrderCommentsSection";
import { CfdisSelected } from "../model/Payment";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import { cancelCfdi } from "../api/TenantCfdiApi";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ReactHtmlParser from "html-react-parser";

export interface DetailsTabParams {
    tenantId: string;
    paymentOrder: PaymentOrder;
    isReaderOnly: boolean;
    onCancelPaymentFile(paymentOrderFileId: string): any;
    onExecute(field: ExecutePaymentFileRequest): any;
    onPaidCfdi(paySelected: CfdisSelected, paymentOrderId: string, paymentMethod: string): any;
    onCancelCfdi(): any;
}

export default function DetailsTab(props: DetailsTabParams) {
    const [data, setData] = useState<PaymentOrderCfdiItem[]>([] as PaymentOrderCfdiItem[]);
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [cfdiId, setCfdiId] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    const load = () => {
        let tempData = [] as PaymentOrderCfdiItem[];
        props.paymentOrder.list_cfdis.forEach(cfdiList => {
            let item = props.paymentOrder!.cfdis.find(cfdi => cfdi.cfdi === cfdiList.id);
            let cfdiTemp = {
                ...cfdiList,
                amount: item?.amount,
                payment_amount: item?.payment_amount,
                exchange_rate: item?.exchange_rate,
                payment_currency: item?.payment_currency || "---",
                status: item?.status,
                reference_exchange_rate: item?.reference_exchange_rate
            } as PaymentOrderCfdiItem;
            tempData.push(cfdiTemp);
        });
        setData(tempData);
    };

    useEffect(load, [props]);

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => { };

    const hasChangedAmount = (name: string, value: number, inputRef: InputRef) => { };

    const deleteCfdi = (cfdiId: string) => { };

    const editCfdi = (cfdiId: string) => { }

    const onOpenCancelCfdi = (cfdiId: string) => {
        setOpenCancelConfirmDialog(true);
        setCfdiId(cfdiId);
    }

    const onCancelCfdi = () => {
        setOpenCancelConfirmDialog(false);
        if (!cfdiId) return;
        setOpenBackdrop(true);
        cancelCfdi(props.tenantId, cfdiId).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": "", "cfdi_link": `/cfdis/${response.related_cfdi}/details` }) as string
                ));
            } else {
                props.onCancelCfdi();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const payCfdi = (cfdiId: string) => {
        const clicked = data.find(cfdi => cfdi.id === cfdiId);
        if (!clicked) return;

        const providerId = clicked.metadata.provider_id;
        const cfdiSelected = data.filter(cfdi => providerId === cfdi.metadata.provider_id).filter(cfdi => cfdi.status === "PENDING").filter(cfdi => cfdi.payment_currency === clicked.payment_currency);
        const dataSelect = {
            cfdis: cfdiSelected
        } as CfdisSelected;

        props.onPaidCfdi(dataSelect, props.paymentOrder?.id || "", cfdiSelected[0].cfdi?.metodo_pago || "PUE");
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <div>
            <Box pt={2}>
                <PaymentOrderCommentsSection paymentOrder={props.paymentOrder} />
            </Box>
            <Box px={2} py={2}>
                <Grid container justify="space-between" alignItems="center" >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <b>{translate("payment_order.details_subtitle")}</b>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <PaymentOrderCfdiList
                data={data}
                edit={false}
                comments={props.paymentOrder.comments}
                status_order={props.paymentOrder != null ? props.paymentOrder.status : ""}
                isReaderOnly={props.isReaderOnly}
                hasChangedAmount={hasChangedAmount}
                deleteCfdi={deleteCfdi}
                payCfdi={payCfdi}
                editCfdi={editCfdi}
                onCancelCfdi={onOpenCancelCfdi}
                paymentOrderId={props.paymentOrder?.id}
                hasChanged={hasChanged}
            />
            <PaymentOrderFilesSection
                paymentOrder={props.paymentOrder}
                onExecute={props.onExecute}
                onCancelPaymentFile={props.onCancelPaymentFile} />
            {openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate(data.length > 1 ? "cfdis.cancel_cfdi.message" : "cfdis.cancel_cfdi.message_op")}
                    secondary={data.length > 1 ? translate("cfdis.cancel_cfdi.secondary") as string : undefined}
                    button={translate("buttons.accept") as string}
                />
            )}
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </div>)
}