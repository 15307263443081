import { callAPI, HTTPMethod } from "./API";
import { Billings, Billing, BillingAuthorizationRequest, BillingsQueryParams } from "../model/Billing";

export async function getBillings(page: number, offset: number, params: BillingsQueryParams): Promise<Billings> {
    return await callAPI(`/billings`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function authorizePayment(billingId: string, request: BillingAuthorizationRequest): Promise<Billing> {
    return await callAPI(`/billings/${billingId}/authorize`, {
        method: HTTPMethod.PUT,
        body: request
    });
}
