import { Box, ListSubheader, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FiguraTransporte } from "../../model/ComplementoCartaPorte20";
import translate from "../../i18n/Translator";
import Gridable from "../../components/Gridable";
import Ellipsis from "../../components/Ellipsis";

export interface FiguraTransporteCardProps {
    data: FiguraTransporte;
}

export default function FiguraTransporteCard(props: FiguraTransporteCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.transport_figure.title")}</strong></Typography>
            <Grid container justify="flex-start">
                { props.data.tipos_figura && props.data.tipos_figura.map(figura => {
                    let variantFigure = translate("cfdis.carta_porte.transport_figure.tipo_figura."+(figura as any)["tipoFigura"]) as string;
                    return <>
                    <Grid item xs={12}>
                            <ListSubheader color="primary">{translate("cfdis.carta_porte.transport_figure.tipo_figura."+variantFigure)}</ListSubheader>
                            <Grid container justify="flex-start" >
                                <Grid item xs={12} >
                                    <Fields fields={[figura]} variant={variantFigure}/>
                                </Grid>
                            </Grid>
                    </Grid>
                    </>;
                })}
            </Grid>
        </Box>
    );
}

interface FieldsProps <T>{
    fields: T[];
    variant: string;
}

function Fields<T>(props: FieldsProps<T>) {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box mx={-2}>
                    <Gridable
                        items={props.fields}
                        loading={false}
                        empty={translate("cfdis.carta_porte.transport_figure.empty") as string}
                        columns={[
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".rfc") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["rfcFigura"] ? (concept as any)["rfcFigura"] :  "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".nombre") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["nombreFigura"] ? (concept as any)["nombreFigura"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".numero_licencia") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["numeroLicenciaFigura"] ? (concept as any)["numeroLicenciaFigura"] : "---"} lenght={100} uppercased={false} />),
                                xs: true,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".id_tributario") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["numRegIdTribFigura"] ? (concept as any)["numRegIdTribFigura"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure."+props.variant+".residencia_fiscal") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["residenciaFiscalFigura"] ? (concept as any)["residenciaFiscalFigura"] : "---"} lenght={100} uppercased={false} />),
                                xs: true,
                                sm: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.calle") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["calle"] ? (concept as any)["domicilio"]["calle"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.numero_exterior") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["numero_exterior"] ? (concept as any)["domicilio"]["numero_exterior"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.numero_interior") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["numero_interior"] ? (concept as any)["domicilio"]["numero_interior"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.colonia") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["colonia"] ? (concept as any)["domicilio"]["colonia"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.localidad") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["localidad"] ? (concept as any)["domicilio"]["localidad"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                lg: true,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.referencia") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["referencia"] ? (concept as any)["domicilio"]["referencia"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.municipio") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["municipio"] ? (concept as any)["domicilio"]["municipio"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.estado") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["estado"] ? (concept as any)["domicilio"]["estado"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.pais") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["pais"] ? (concept as any)["domicilio"]["pais"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.domicilio.codigo_postal") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["domicilio"] && (concept as any)["domicilio"]["codigo_postal"] ? (concept as any)["domicilio"]["codigo_postal"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            },
                            {
                                title: translate("cfdis.carta_porte.transport_figure.partes_transporte") as string,
                                converter: (concept) => (<Ellipsis text={(concept as any)["parteTransporte"] && (concept as any)["parteTransporte"]["parte_transporte"] ? (concept as any)["parteTransporte"]["parte_transporte"] : "---"} lenght={100} uppercased={false} />),
                                xs: false,
                                sm: false,
                                md: false,
                                lg: false,
                                xl: true
                            }
                        ]} />
                </Box>
            </Grid>
        </Grid>
    );
}