import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";

import {
    EditIcon, DeleteIcon, SecurityAccountIcon, BlockIcon,
    UnblockIcon, ActivateIcon, ProvidersIcon,
    EmailIcon, LockOpenIcon
} from "../components/Icons";

import translate from "../i18n/Translator";
import { User } from "../model/User";
import { AppContext } from "../context/AppContext";

interface UserMenuProps {
    user: User;
    tenantId?: string;
    providerId?: string;
    urlUser: string;
    anchor: HTMLElement | null;
    view: string;
    onClose(): any;
    onActivated(): any;
    onResendEmail(): any;
    onUnlock(): any;
    onBlock(): any;
    onUnblock(): any;
    onDelete(): any;
}

export default function UserMenu(props: UserMenuProps) {
    const context = useContext(AppContext);
    const itself = props.user.id === context.session?.user.id;
    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {itself &&
                <MenuItem button component={Link} to="/profile" onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {!itself && context.isGrantedAny(["UsersUpdate", "TenantsUsersUpdate", "OwnerProvidersUsersUpdate", "ProviderUsersUpdate", "CustomerUsersUpdate", "TechnicalSupportUpdate"]) &&
                <MenuItem button component={Link} to={props.urlUser}
                    onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {!itself && !props.user.blocked && props.user.signup_code &&
                context.isGrantedAny(["UsersUpdate", "TenantsUsersUpdate", "OwnerProvidersUsersUpdate", "ProviderUsersUpdate", "CustomerUsersUpdate"]) &&
                <MenuItem button onClick={props.onActivated} dense>
                    <ListItemIcon>
                        <ActivateIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.activate")}</Typography>
                </MenuItem>
            }
            {!itself && props.user.provider_id && context.isGrantedAny(["UsersUpdate", "TenantsUsersUpdate", "OwnerProvidersUsersUpdate", "ProviderUsersUpdate"]) &&
                props.view !== "TENANT_PROVIDERS_USERS" &&
                <MenuItem button onClick={props.onResendEmail} dense disabled={props.user.blocked || !props.user.signup_code}>
                    <ListItemIcon>
                        <EmailIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.resend_suscribe_email")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ProvidersRead") && props.user.provider_id &&
                <MenuItem button component={Link} to={`/providers/${props.user.provider_id}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <ProvidersIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_provider")}</Typography>
                </MenuItem>
            }
            {((context.isGranted("TechnicalSupportRead") && props.view === "TENANT_PROVIDERS_USERS"))
                || (!itself && context.isGranted("PlatformAdministratorUsersSecurity")) ?
                 < MenuItem button component={Link} to={`/security/provider-user/${props.tenantId}/${props.user.provider_id}/${props.user.id}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <SecurityAccountIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("users.security.title")}</Typography>
                </MenuItem> : undefined
            }
            {!itself && !props.user.blocked && context.isGrantedAny(["UsersBlock", "TenantsUsersBlock", "TechnicalSupportRead"]) &&
                <MenuItem button onClick={props.onBlock} dense>
                    <ListItemIcon>
                        <BlockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.block")}</Typography>
                </MenuItem>
            }
            {!itself && props.user.blocked && context.isGrantedAny(["UsersBlock", "TenantsUsersBlock", "TechnicalSupportRead"]) &&
                <MenuItem button onClick={props.onUnblock} dense>
                    <ListItemIcon>
                        <UnblockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.unblock")}</Typography>
                </MenuItem>
            }
            {!itself && props.user.blocked && context.isGrantedAny(["UsersBlock", "TenantsUsersBlock", "OwnerProvidersUsersUpdate", "ProviderUsersUpdate"]) &&
                <MenuItem button onClick={props.onUnlock} dense>
                    <ListItemIcon>
                        <LockOpenIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.unlock")}</Typography>
                </MenuItem>
            }
            {!itself && context.isGrantedAny(["UsersDelete", "TenantsUsersDelete", "OwnerProvidersUsersDelete", "ProviderUsersDelete", "CustomerUsersDelete"]) &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu >
    );

}