import React, { useContext, useState, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { getDocumentUrl } from "../api/ExpensesAccountAPI";
import { Cfdi } from "../model/Cfdi";
import { ExpensesAccountDocument } from "../model/ExpensesAccount";

interface ExpensesAccountDocumentMenuProps {
    payableDocument: Cfdi;
    document: ExpensesAccountDocument;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete?(): any;
    onDownload?(): any;
}

export default function ExpensesAccountDocumentMenu(props: ExpensesAccountDocumentMenuProps) {
    const context = useContext(AppContext);
    const [filePdfUrl, setFilePdfUrl] = useState<string>();

    useEffect(() => {
        if (props.document.file_path) {
            const providerId = context.session!.provider?.id || props.payableDocument.metadata.provider_id;
            getDocumentUrl(context.session!.tenant!.id, providerId, props.payableDocument.id, props.document.id).then((urlFile) => {
                setFilePdfUrl(urlFile);
            }).catch((error) => {
                setFilePdfUrl(undefined);
            });
        }
    }, [context.session, props.payableDocument, props.document]);

    return (
        <Menu
            id="expenses-account-document-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {filePdfUrl && context.isGrantedAny(["ReceptorCFDIsRead", "AdminCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={filePdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("expenses_accounts.see_file")}</Typography>
                </MenuItem>
            }
            {props.onDownload && props.document.cfdi_id &&
                <MenuItem button onClick={props.onDownload} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.download_cfdi") as string}</Typography>
                </MenuItem>
            }
            {props.onDelete && context.isGrantedAny(["ProviderCFDIsDelete", "SenderCFDIsDelete"]) && props.payableDocument.metadata?.cfdi_status_group === "SENDING" &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>);
}
