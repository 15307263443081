import React, { useEffect, useState } from "react";
import { Box, Grid, FormControlLabel, Switch } from "@material-ui/core";
import ProductsServices from "../specific_reception_rules/ProductsServices";
import { ServiceProduct } from "../model/ServiceProduct";
import translate from "../i18n/Translator";

interface ReceptionRuleCartaPorteProps {
    selected: ServiceProduct[];
    allowDifferentServiceProducts?: boolean;
    onUpdateChecked?(checked: boolean): any;
    onUpdateProducts?(selected: ServiceProduct[]): any;
}

export default function ReceptionRuleCartaPorte(props: ReceptionRuleCartaPorteProps) {
    const [checked, setChecked] = useState(!props.allowDifferentServiceProducts);

    useEffect(() => {
        if (props.onUpdateChecked) {
            props.onUpdateChecked!(checked);
        }
        // eslint-disable-next-line
    }, [checked]);

    const onUpdate = (selected: ServiceProduct[]) => {
        if (props.onUpdateProducts) {
            props.onUpdateProducts(selected);
        }
    };

    return (
        <Grid container>
            <Box mt={3}>
                <Grid item xs={12}>
                    <ProductsServices
                        selected={props.selected}
                        disabled={false}
                        update={onUpdate}
                        showTable={true}
                        placeholder={translate("specific_reception_rule.service_product.input") as string}
                        accessory={(
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checked}
                                        onChange={(el, checked) => setChecked(checked)}
                                    />
                                }
                                label={translate("reception_rule.carta_porte.switch") as string}
                            />
                        )} />
                </Grid>
            </Box>
        </Grid>
    );

}