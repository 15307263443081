import React, { useState, useEffect } from "react";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import NumberFormat from 'react-number-format';
import TenantRelatedCfdisMenu from "../cfdis/menus/TenantRelatedCfdisMenu";

import { IconButton, Grid } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar } from "../components/Snackbars";
import { Redirect, useHistory } from "react-router-dom";


export interface CfdiRelatedCfdisParams {
    tenantId: string;
    isProvider: boolean;
    relatedCfdis: Cfdi[];
}

export default function CfdiRelatedCfdis(props: CfdiRelatedCfdisParams) {
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openBackdrop] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [redirect, setRedirect] = useState<string>();
    
    const load = () => {
        setStatus("loaded");
    };
    useEffect(load, []);

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onClickedRow = (cfdi: Cfdi) => {
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/cfdis/${cfdi.id}/details_related`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onClose = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <Grid item xs={12}>
            <Gridable
                items={ props.relatedCfdis ? props.relatedCfdis : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cfdis.credit_notes.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("cfdis.columns.emission_date") as string,
                        converter: (cfdi) => (<DateFormat date={cfdi.cfdi!.fecha_emision} format="DD-MM-YYYY h:mm a" zone={true}/>),
                        xs: 2,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.columns.folio") as string,
                        converter: (cfdi) => cfdi.cfdi!.folio,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.columns.type") as string,
                        converter: (cfdi) => translate(`cfdis.history.cfdi_type.${cfdi.metadata.type}`) as string,
                        xs: "auto",
                        sm: 1,
                        md: 1,
                        xl: 1
                    },
                    {
                        title: translate("cfdis.columns.description") as string,
                        converter: (cfdi) => cfdi.description,
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.columns.total_text") as string,
                        converter: (cfdi) => (<NumberFormat value={cfdi.total} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />),
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.columns.balance") as string,
                        converter: (cfdi) => (<NumberFormat value={cfdi.metadata.balance} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />),
                        xs: "auto",
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: "",
                        converter: (cfdi) => (<IconButton aria-label="options" color="default" size="small"
                            onClick={onClickedOptions(cfdi)}>
                            <MoreVertIcon />
                        </IconButton>),
                        justify: "flex-end",
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    }
                ]} />
        { cfdi && anchorEl && 
        <TenantRelatedCfdisMenu 
        cfdi={cfdi} anchor={anchorEl}
        referer={""} isProvider={props.isProvider}
        onClose={onClose}
        />
        }
        <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </Grid>
    )
}