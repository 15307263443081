import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";

import translate from "../i18n/Translator";
import { DigitalDocument } from "../model/DigitalDocument";
import { AppContext } from "../context/AppContext";
import { getFileUrl } from "../api/DigitalDocumentAPI";

interface DigitalDocumentMenuProps {
    digitalDocument: DigitalDocument;
    anchor: HTMLElement | null;
    onDelete(): any;
    onClose(): any;
}

export default function DigitalDocumentMenu(props: DigitalDocumentMenuProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const { id } = props.digitalDocument;
    const [url, setUrl] = useState<string>();
    const [isUpdateGranted] = useState(context.session!.role.id === 'owner_issuer' ? 
        context.isGranted("DigitalDocumentsUpdate") : 
        context.isGranted("DigitalDocumentsUpdate") && props.digitalDocument.expiration);
    const [isDeleteGranted] = useState(context.isGranted("DigitalDocumentsDelete") && context.session!.role.id === 'owner_issuer');

    useEffect(() => {
        getFileUrl(tenantId, id).then(url => {
            setUrl(url);
        }).catch(error => {
            console.log(error);
        })
    }, [tenantId, id]);

    return (
        <Menu
            id="digital-documents-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem button component={Link} to={`/digital-documents/${id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            <MenuItem button onClick={props.onClose} dense component="a" href={url || "#"} target="_blank" rel="noopener noreferrer" disabled={!url}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.download")}</Typography>
            </MenuItem>
            {isDeleteGranted && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            )}
        </Menu>
    );

}