import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import { CfdiMetadata } from "../model/Cfdi";
import AlertIcon from '@material-ui/icons/ReportProblem';
import ChangeIcon from '@material-ui/icons/Sync';
import PdfViewer from "../components/PdfViewer";
import XmlViewer from "../components/XmlViewer";
import Axios from 'axios';

import Progress from "../components/Progress";
import ReactHtmlParser from "html-react-parser";
import { Grid, Typography, Box, Card, CardContent, Divider, ListItemIcon, ListItem, ListItemText, Fab, MenuItem } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CfdiChangePdfMessageSection from "./CfdiCheckPdfMessageSection";
import { getSecureCfdiFileUrl } from "../api/TenantCfdiApi";
import { SecureCfdiFileUrlResonse } from "../model/Cfdi";

export interface CfdiFilesParams {
    tenantId: string;
    providerId?: string;
    uuid?: string;
    cfdiId: string;
    metadata: CfdiMetadata;
    isCfdiTenant: boolean;
    onChangeFile(): any;
    onCheckPdf(): any;
}

export default function CfdiFilesTab(params: CfdiFilesParams) {

    const [status, setStatus] = useState<string>("loading");
    const [validPdf, setValidPdf] = useState<boolean>(false);
    const [data, setData] = useState<CfdiMetadata>();
    const [xmlData, setXmlData] = useState("");
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [fileType, setFileType] = useState<"PDF" | "XML">("PDF");
    const [reload, setReload] = useState<boolean>(false);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            circle_a: {
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid #1F679F",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                color: "#1F679F",
            },
            text_a: {
                textAlign: "center",
                color: "#1F679F",
                marginLeft: "10px",
                marginTop: "15px",
            },
            circle_b: {
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid #F69450",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                color: "#F69450",
            },
            text_b: {
                textAlign: "center",
                color: "#F69450",
                marginLeft: "10px",
                marginTop: "15px",
            }
        }),
    );

    const classes = useStyles();

    const fillUrls = (xmlResponse: SecureCfdiFileUrlResonse, pdfResponse: SecureCfdiFileUrlResonse) => {
        if (pdfResponse && pdfResponse.url) {
            setPdfUrl(pdfResponse.url);
        }
        if (params.metadata.xml_url && xmlResponse && xmlResponse.url) {
            readXmlFromUrl(xmlResponse.url);
        } else {
            setStatus("loaded");
        }
    }

    const loadCfdiFiles = () => {
        Promise.all([
            getSecureCfdiFileUrl(params.tenantId, params.cfdiId, "xml"),
            getSecureCfdiFileUrl(params.tenantId, params.cfdiId, "pdf"),
        ]).then(urls => {
            fillUrls(urls[0], urls[1]);
        }).catch(error => {
            console.error(error);
        });
    }

    const readXmlFromUrl = (url: string) => {
        Axios({
            url: url,
        }).then((response) => {
            setXmlData(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    const load = () => {
        setStatus("loading");
        setData(params.metadata);
        if (params.metadata.valid_pdf === undefined || params.metadata.valid_pdf) {
            setValidPdf(true);
        }
        loadCfdiFiles();
    };

    useEffect(load, [params, validPdf, reload]);

    const onSelectXml = () => {
        setFileType("XML");
    }

    const onSelectPdf = () => {
        setFileType("PDF");
    }

    const onOpenChangePdf = () => {
        params.onChangeFile();
        setReload(true);
    }

    const onOpenCheckPdf = () => {
        params.onCheckPdf();
        setReload(true);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !data) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid>
            <Box pt={2} px={2}>
                <Grid container >
                    <Grid xs={5} style={{paddingRight: "1%"}}>
                        <Typography variant="inherit">{ReactHtmlParser(translate("cfdis.documents.files.title") as string)}</Typography>
                        <Box py={2}></Box>
                        <Grid xs={12}>
                            <Card>
                                <CardContent>
                                    {data && data.xml_file_name &&
                                        <MenuItem button onClick={onSelectXml} dense>
                                            <Grid xs={12}>
                                                <Box px={1} >
                                                    <Grid container direction="row" alignItems="center" spacing={4}>
                                                        <Grid item>
                                                            <div className={classes.circle_a}><p className={classes.text_a}>{translate("cfdis.xml") as string}</p></div>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="inherit">{data.xml_file_name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </MenuItem>
                                    }
                                    {data && data.xml_file_name && data.pdf_file_name &&
                                        <Box py={1}>
                                            <Divider />
                                        </Box>
                                    }
                                    {data && data.pdf_file_name &&
                                        <MenuItem button onClick={onSelectPdf} dense>
                                            <Grid xs={12}>
                                                <Box px={1} >
                                                    <Grid container direction="row" alignItems="center" spacing={4} >
                                                        <Grid item>
                                                            <div className={validPdf ? classes.circle_a : classes.circle_b}><p className={validPdf ? classes.text_a : classes.text_b}>{translate("cfdis.pdf") as string}</p></div>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="inherit">{data.pdf_file_name}</Typography>
                                                            {!validPdf &&
                                                                <AlertIcon fontSize="small" color="secondary" />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </MenuItem>
                                    }
                                </CardContent>
                            </Card>
                            {!validPdf &&
                                <Grid xs={12}>
                                    <Grid xs={12} container direction="row" alignItems="center" justify="flex-end">
                                        <Box py={2}>
                                            <ListItem button onClick={onOpenChangePdf} dense>
                                                <ListItemIcon>
                                                    <Fab color="secondary" style={{ width: "65%", height: "40%" }} title={translate("buttons.add") as string}>
                                                        <ChangeIcon fontSize="small" />
                                                    </Fab>
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="inherit" color="secondary">{ReactHtmlParser(translate("cfdis.documents.files.replace_file") as string)}</Typography>} />
                                            </ListItem>
                                        </Box>
                                    </Grid>
                                    {params.uuid &&
                                        <Grid xs={12} container direction="row" alignItems="center">
                                            <Box py={1}></Box>
                                            <CfdiChangePdfMessageSection uuid={params.uuid} onOpenCheckPdf={onOpenCheckPdf} isCfdiTenant={params.isCfdiTenant} />
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid xs={7} >
                        <Typography variant="inherit">{ReactHtmlParser(translate("cfdis.documents.preview") as string)}</Typography>
                        <Grid container style={{backgroundColor:"#f1f1f1", "borderRadius": "5px", paddingTop:"2%"}} alignItems="center" justify="center">
                        {fileType === "XML" && xmlData &&
                            <XmlViewer xml={xmlData} />
                        }
                        {fileType === "PDF" && pdfUrl &&
                            <PdfViewer pdf={pdfUrl} />
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </Grid>
    )
}