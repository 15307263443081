import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { calculateStageRoles, StageActors, UpdateWorkflowRequest } from "./Model";
import { getStages, getActors, getWorkflowTemplate, Product } from "./Workflove";
import Gridable from "../components/Gridable";
import { EditIcon } from "../components/Icons";
import WorkflowTemplateStageForm from "./WorkflowTemplateStageForm";
import Progress from "../components/Progress";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar } from "../components/Snackbars";
import { useHistory } from "react-router-dom";
import CustomBackdrop from "../components/CustomBackdrop";
import { listCatalogs } from "../api/CatalogAPI";

interface WorkflowTemplateFormProps<T> {
    product: Product;
    tenantId: string;
    id: string;
    workflowId: string;
    templateId: string;
    externalId: string;
    disabled?: boolean;
    description?: string;
    getPromise(request: UpdateWorkflowRequest): Promise<T>;
    onComplete(item: T): any;
    phaseTranslator(externalId: string): string;
}

function WorkflowTemplateForm<T>({ product, tenantId, id, workflowId, templateId, externalId, description, disabled, getPromise, onComplete, phaseTranslator }: WorkflowTemplateFormProps<T>) {
    const history = useHistory();
    const margin = "dense";
    const [status, setStatus] = useState<"loaded" | "loading" | string>("loading");
    const [request, setRequest] = useState<UpdateWorkflowRequest>({} as UpdateWorkflowRequest);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [stageActors, setStageActors] = useState<StageActors>();
    const [index, setIndex] = useState<number>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        setStatus("loading");
        Promise.all([
            getStages(product),
            getActors(product),
            getWorkflowTemplate(product, workflowId, tenantId, templateId, id),
            listCatalogs(tenantId, 0, 0, { search: "" })
        ]).then(([stages, actors, workflowTemplate, catalogs]) => {
            const stagesActors = calculateStageRoles(stages, actors, workflowTemplate, catalogs.items);
            setRequest({
                stage_actors: stagesActors ?? [], external_id: externalId, description: description
            });
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    }, [product, workflowId, tenantId, templateId, externalId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const showPopup = (stageActors: StageActors, index: number) => {
        setStageActors(stageActors);
        setIndex(index);
    };

    const closePopup = () => {
        setStageActors(undefined);
        setIndex(undefined);
    };

    const onUpdated = (stageActors: StageActors) => {
        if (index === undefined) return;
        if (!request.stage_actors) return;

        const items = request.stage_actors.map((el, i) => i === index ? stageActors : el);
        setRequest({ ...request, stage_actors: items });
        closePopup();
    };

    const onSubmit = () => {
        setSubmitting(true);
        getPromise(request).then((response) => {
            onComplete(response);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Hidden xsUp={product !== "PAYABLE_DOCUMENTS"}>
                    <ValidatedInput type="text" id="external_id" name="external_id"
                        value={request.external_id}
                        label={translate("workflows.external_id") as string}
                        disabled={submitting}
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Hidden>
            </Grid>
            <Grid item xs={12}>
                <Hidden xsUp={product !== "PAYABLE_DOCUMENTS"}>
                    <ValidatedInput type="text" id="description" name="description"
                        value={request.description}
                        label={translate("workflows.description") as string}
                        rows={5}
                        disabled={submitting}
                        margin={margin}
                        onValueChanged={hasChanged} />
                </Hidden>
            </Grid>
            <Grid item xs={12}>
                <Box mx={-2} my={2}>
                    <Gridable
                        items={request.stage_actors ?? []}
                        loading={false}
                        empty={translate("workflows.without_stages") as string}
                        columns={[
                            {
                                title: translate("workflows.stage"),
                                converter: (stage, index) => stage._stage_name,
                                fullWidth: true,
                                xs: true,
                            },
                            {
                                title: (
                                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                        <EditIcon />
                                    </IconButton>
                                ),
                                converter: (stage, index) => (
                                    <IconButton aria-label="edit" color="primary" size="small" disabled={disabled} onClick={() => showPopup(stage, index)}>
                                        <EditIcon />
                                    </IconButton>
                                ),
                                fullWidth: true,
                                xs: "auto"
                            },
                        ]} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                    <Grid item xs="auto">
                        <Button variant="contained" color="primary" size="large" disabled={submitting} onClick={onSubmit}>
                            {translate("buttons.update")}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                            {translate("buttons.cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {stageActors && index !== undefined && (
                <WorkflowTemplateStageForm
                    stageActor={stageActors}
                    onUpdated={onUpdated}
                    onClose={closePopup}
                    phaseTranslator={phaseTranslator} />
            )}

            <CustomBackdrop open={submitting} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Grid>
    )
}

export default WorkflowTemplateForm