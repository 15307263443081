import { callAPI, HTTPMethod } from "./API";
import { Plugins, Plugin, PluginsQueryParams, PluginRequest } from "../model/Plugin";

const basePath = (tenantId: string) => `/tenants/${tenantId}/plugins`;

export async function listPlugins(tenantId: string, page: number, offset: number, params: PluginsQueryParams): Promise<Plugins> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createPlugin(tenantId: string, request: PluginRequest): Promise<Plugin> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getPlugin(tenantId: string, id: string): Promise<Plugin> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updatePlugin(tenantId: string, id: string, request: PluginRequest): Promise<Plugin> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deletePlugin(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
