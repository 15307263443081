import React, { useState, useEffect, useContext } from "react";
import CreateUpdatePaymentOrderView from "./CreateUpdateTeamView";
import { RouterParams } from "../router/RouterParams";
import { Redirect } from "react-router-dom";
import Progress from "../components/Progress";
import { Typography } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { getTeam } from "../api/TeamAPI";
import { Team } from "../model/Team";
import { getUsers } from "../api/TenantUserAPI";
import { UsersQueryParamsReceptors } from "../model/User";
import { User } from "../model/User";
import { ROLES_FOR_TEAMS as roles } from "../model/Role";

export default function TeamDetails({match}: RouterParams) {
    const [view, setView] = useState<string>("details");
    const [team, setTeam] = useState<Team>();
    const [teamView, setTeamView] = useState<"create" | "update">("create");
    const [redirect, setRedirect] = useState<string | undefined>();
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);
    const [users, setUsers] = useState<User[]>([]);
    const qs = queryString.parse(window.location.search);
    const history = useHistory();

    const load = () => {
        fillUsers();
        if (match.params.teamId) {
            getTeam(context.session!.tenant!.id, match.params.teamId).then((response) => {
                setTeam(response);
                if(match.params.edit){
                    setView("edit");
                }
                setTeamView("update");
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });

        } else {
            setView("create");
            setTeamView("create");
            setStatus("loaded");
        }
    };

    useEffect(load, [match.params]);

    const onEditTeam = (team: Team) => {
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/teams/${team.id}/${"edit"}/`);
    }

    const onCancel = () => {
        history.goBack();
    }

    const getRoles = () => {
        let tempRoles = roles;
        if(context.isGrantedAny(["BudgetsRead"])){
            tempRoles.push("budget_participant");
            tempRoles.push("budget_admin");
        }
        return tempRoles.join(",")
    }

    const fillUsers = () => {
        getUsers(context.session!.tenant!.id, 0, 0, {
            search: "",
            role_list: getRoles(), 
            status: "ACTIVE",
        } as UsersQueryParamsReceptors).then((response) => {
            setUsers(response.items);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const saveTeam = (result: Team, message?: string) => {
        setTeam(undefined);
        if(message){
            setRedirect(`/list-teams/success/${message}` + queryString.stringify(qs));
        } else{
            setRedirect(`/list-teams`);
        }
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || ( view !== "create" && !team)) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    switch (view) {
        case "create": 
            return ( <CreateUpdatePaymentOrderView  next={saveTeam} onCancel={onCancel} onEdit={onEditTeam} view={teamView} users={users} isReaderOnly={false}/> );
        case "edit": 
            return ( <CreateUpdatePaymentOrderView  next={saveTeam} onCancel={onCancel} onEdit={onEditTeam} team={team} view={teamView} users={users} isReaderOnly={false}/> );
        case "details": 
            return ( <CreateUpdatePaymentOrderView  next={saveTeam} onCancel={onCancel} onEdit={onEditTeam} team={team} view={teamView} users={users} isReaderOnly={true}/> );
        default:
            return ( <CreateUpdatePaymentOrderView  next={saveTeam} onCancel={onCancel} onEdit={onEditTeam} view={teamView} team={team} users={users} isReaderOnly={true}/> );
    }
    
}