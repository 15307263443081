import React, { useContext, useState, useEffect } from "react";
import { Button, Grid, TextField, Box } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Cfdi, CfdisQueryParams } from "../model/Cfdi";
import { ForeignCreditNoteRequest } from "../model/NoCfdiPayableDocument";
import { getTenantCfdiFileUrl } from "../api/TenantCfdiApi";
import { createForeignCreditNote as create } from "../api/NoCfdiPayableDocumentApi";
import { listCompanies } from "../api/CompanyAPI";
import { AppContext } from "../context/AppContext";
import { getTenantCfdis } from "../api/TenantCfdiApi";
import { getProviders } from "../api/ProviderAPI";
import { ProvidersQueryParams } from "../model/Provider";
import { CompaniesQueryParams } from "../model/Company";
import { MultiselectValue } from "../components/MultiselectDropList";
import { NumberValidator, GTZeroValidator } from "../components/Validators";
import { getCfdis } from "../api/ProviderCfdiApi";

interface NewForeignCreditnotePopupProps {
    cfdi?: Cfdi;
    onSuccess(payableDocument: Cfdi): any;
    onCancel(): any;
    provider_id?: string;
}

export default function NewForeignCreditnotePopup(props: NewForeignCreditnotePopupProps) {
    const context = useContext(AppContext);
    const margin = "dense";
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [providers, setProviders] = useState<MultiselectValue[]>([]);
    const [companies, setCompanies] = useState<MultiselectValue[]>([]);
    const [invoices, setInvoices] = useState<MultiselectValue[]>([]);
    const [invoiceItems, setInvoiceitems] = useState<Cfdi[]>([]);
    const [defaultValue, setDefaultValue] = useState<MultiselectValue>();

    const [cfdi, setCfdi] = useState<Cfdi>();
    const numberValidator = new NumberValidator(true);
    const gtZeroValidator = new GTZeroValidator();

    const paramsFromQueryString = (): ProvidersQueryParams => {
        return {
            "search": "",
            "not_located_status": "",
            "efos_status": "",
            "status": "",
            "classification": "",
            "validator_user_id": "",
            "receptor": context.session && context.session.role && context.session.user && context.session?.role?.id === 'receptor_cfdi' ? context.session.user.id : "",
            "specific_rule": "",
            "workflow_id": "",
            "type": "FOREIGN"
        } as ProvidersQueryParams;
    };
    const [pdfUrl, setPdfUrl] = useState<string>();

    const [params, setParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const [request, setRequest] = useState<ForeignCreditNoteRequest>({
        date: new Date(),
        total: 0.00,
        provider_id: "",
        invoice_id: "",
        company_id: "",
        comments: "",
        number: ""
    } as ForeignCreditNoteRequest);
    const [validations, setValidations] = useState({} as any);

    const load = () => {
        if (props.cfdi && props.cfdi!.metadata.pdf_url) {
            setRequest({ ...request, pdf_url: props.cfdi!.metadata.pdf_url });
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi!.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
        if (props.provider_id) {
            let loadRequest = request;
            loadRequest.provider_id = props.provider_id
            setRequest(loadRequest);
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams).then((response) => {
                setCompanies(initData(response.items, "name"));
            });
        } else {
            Promise.all([
                getProviders(context.session!.tenant!.id, 0, 0, params),
                listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams),
            ]).then((responses) => {
                setProviders(initData(responses[0].items, "name"));
                setCompanies(initData(responses[1].items, "name"));
            }).catch((error) => {
                setError(error.message);
            });
        }
    };

    useEffect(load, []);

    const initData = (items: any[], propertyName: string) => {
        let values = [] as MultiselectValue[];
        if (!items || items === undefined) {
            return values;
        }
        items.forEach(c => {
            if (propertyName === "identifier") {
                let label = `${c.identifier}-${c.total}-${c.currency}`;
                values.push({ title: label, value: c.id } as MultiselectValue)
            } else {
                values.push({ title: c.name, value: c.id } as MultiselectValue)
            }
        });
        return values;
    }

    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        if ("pdf_url" === name) {
            let copyRequest = request;
            request.pdf_name = inputRef.original_name ? inputRef.original_name : "";
            setRequest(copyRequest);
        }
        setValidations(validations);
    };

    const onFilterChanged = (value: string, property: string, inputRef?: InputRef) => {
        setRequest({ ...request, [property]: value });
    };

    const refillCfdis = (providerId: string, companyId: string) => {
        if (props.provider_id) {
            getCfdis(context.session!.tenant!.id, props.provider_id!,
                context.isGranted("SenderCFDIsRead") ? context.session!.user.id : "",
                0, 0, {
                    status_groups: ["SENDING", "IN_PROCESS", "VALIDATION", "RECEIVED", "POSTPONE", "PAYMENT_ORDER"].join(","),
                    referer_view: "ARCHIVE",
                    search: "",
                    company_name: companyId || "",
                    lite_payload: true,
                    type: "FOREIGN"
                } as CfdisQueryParams).then((response) => {
                    setInvoices(initData(response.items, "identifier"));
                    setInvoiceitems(response.items);
                    setRequest({ ...request, currency: "", invoice_id: "---", company_id: companyId, provider_id: props.provider_id! });
                }).catch((error) => {
                    setError(error.message);
                });
        } else {
            getTenantCfdis(context.session!.tenant!.id, "", 0, 0, {
                status_groups: ["SENDING", "IN_PROCESS", "VALIDATION", "RECEIVED", "POSTPONE", "PAYMENT_ORDER"].join(","),
                referer_view: "ARCHIVE",
                search: "",
                company_name: companyId || "",
                provider_id: providerId || "",
                lite_payload: true,
                type: "FOREIGN"
            } as CfdisQueryParams).then((responses) => {
                setInvoices(initData(responses.items, "identifier"));
                setInvoiceitems(responses.items);
                setRequest({ ...request, currency: "", invoice_id: "---", company_id: companyId, provider_id: providerId });
            }).catch((error) => {
                setError(error.message);
            });
        }

    }

    const onFilterChangedProviders = (value: string, inputRef?: InputRef) => {
        if (!!value) {
            refillCfdis(value, request.company_id);
            setDefaultValue(undefined);
        }
    };

    const onFilterChangedCompanies = (value: string, inputRef?: InputRef) => {
        if (value && "" !== value) {
            refillCfdis(request.provider_id, value);
            setDefaultValue(undefined);
        }
    };

    const onFilterChangedInvoices = (value: string, inputRef?: InputRef) => {
        onFilterChanged(value, "invoice_id", inputRef);
        if (value) {
            let cfdiItem = invoiceItems.find(item => item.id === value);
            setDefaultValue(invoices.find(item => item.value === value));
            setRequest({ ...request, currency: cfdiItem!.currency, invoice_id: value });
        }
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest({ ...request, date: date });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = () => {
        if (!isValid()) return;
        setSubmitting(true);
        create(context.session!.tenant!.id, request).then((payableDocument: Cfdi) => {
            props.onSuccess(payableDocument);
        }).catch(error => {
            setWarning(error.message);
            setSubmitting(false);
        });
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        <DialogPopup open title={translate("foreign_credit_note.title")}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            maxWidth="sm"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancel}
            closeColor="default"
            button={
                <Button onClick={onSubmit} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.add")}
                </Button>
            }>
            <Box>
                {!props.provider_id &&
                    <Grid item xs={12}>
                        <Box pb={1}>
                            <Autocomplete
                                size="small"
                                options={providers || []}
                                getOptionLabel={(elemento) => elemento.title}
                                disabled={submitting}
                                noOptionsText={translate("generic_messages.no_options") as string}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" required={true} label={translate("foreign_credit_note.provider") as string} fullWidth />
                                )}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        onFilterChangedProviders(newValue.value);
                                    } else {
                                        onFilterChangedProviders("");
                                    }
                                }}
                                getOptionSelected={(opt, val) => opt.value === val?.value}
                                value={request.provider_id ? providers.find(c => c.value === request.provider_id) : undefined}
                            />
                        </Box>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Box pb={1}>
                        <Autocomplete
                            size="small"
                            options={companies || []}
                            getOptionLabel={(elemento) => elemento.title}
                            disabled={submitting}
                            noOptionsText={translate("generic_messages.no_options") as string}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" required={true} label={translate("foreign_credit_note.company") as string} fullWidth />
                            )}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    onFilterChangedCompanies(newValue.value);
                                } else {
                                    onFilterChangedCompanies("");
                                }
                            }}
                            getOptionSelected={(opt, val) => opt.value === val?.value}
                            value={request.company_id ? companies.find(c => c.value === request.company_id) : undefined}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        size="small"
                        options={invoices || []}
                        getOptionLabel={(elemento) => elemento.title}
                        disabled={submitting}
                        noOptionsText={translate("generic_messages.no_options") as string}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" required={true} label={translate("foreign_credit_note.invoice") as string} fullWidth />
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                onFilterChangedInvoices(newValue.value);
                            } else {
                                onFilterChangedInvoices("");
                            }
                        }}
                        getOptionSelected={(opt, val) => opt.value === val?.value}
                        key={defaultValue ? defaultValue.value : undefined}
                        value={request.invoice_id && request.invoice_id !== "---" ? invoices.find(c => c.value === request.invoice_id) : undefined}
                    />
                </Grid>
            </Box>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <ValidatedInput id="number" name="number" type="text"
                        label={translate("foreign_credit_note.number") as string}
                        value={request.number} required disabled={submitting}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker label={translate("foreign_credit_note.date") as string} name="date"
                        onChange={onChangedDate} clearable={false} required disabled={submitting}
                        format="DD/MM/YYYY" initial={request.date ? moment(request.date).format("DD/MM/YYYY") : undefined} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="total" name="total" type="number"
                        label={translate("foreign_credit_note.total") as string}
                        value={request.total + ""} required disabled={submitting}
                        margin={margin}
                        validators={[numberValidator, gtZeroValidator]}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="currency" name="currency" type="text"
                        label={translate("foreign_credit_note.currency") as string}
                        value={request.currency} disabled={true}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput id="pdf_url" name="pdf_url"
                        label={translate("foreign_credit_note.file") as string}
                        value={pdfUrl ? pdfUrl : request.pdf_url} required disabled={props.cfdi !== undefined}
                        margin={margin} path="cover" acceptExtension=".pdf"
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="comments" name="comments" type="text"
                        label={translate("foreign_credit_note.comments") as string}
                        value={request.comments} disabled={submitting}
                        rows={3} maxRows={3}
                        margin={margin}
                        onValueChanged={onChanged} />
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}