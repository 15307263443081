import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { IconButton, Divider, Fab, Button } from '@material-ui/core';
import { WebhookIcon, MoreVertIcon, AddIcon } from "../components/Icons";
import { deleteWebhook, listWebhooks } from "../api/WebhooksAPI";
import { AppContext } from "../context/AppContext";
import { Webhook, Webhooks, WebhooksQueryParams } from "../model/Webhooks";
import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import Ellipsis from "../components/Ellipsis";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import WebhooksMenu from "./WebhookMenu";
import CustomBackdrop from "../components/CustomBackdrop";
import DialogPopup from "../components/DialogPopup";

export default function WebhooksList() {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Webhooks>();
    const [workingParams, setWorkingParams] = useState<WebhooksQueryParams>({
        search: search,
    } as WebhooksQueryParams);
    const [webhook, setWebhook] = useState<Webhook>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [isCreateGranted] = useState(context.isGranted("WebhooksCreate"));
    const [submitting, setSubmitting] = useState(false);
    const [popup, setPopup] = useState<"delete">();

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as WebhooksQueryParams;
        setWorkingParams(params);

        listWebhooks(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onClickedOptions = (webhook: Webhook) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWebhook(webhook);
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickRow = (webhook: Webhook) => {
        history.push(`/webhooks/${webhook.id}/edit`);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onDelete = () => {
        if (!webhook) return;

        setAnchorEl(null);
        setPopup("delete");
    };

    const onConfirmDelete = () => {
        onClosePopup();
        if (!webhook) return;

        setSubmitting(true);
        deleteWebhook(tenantId, webhook.id).then(() => {
            setSuccess(
                translate("webhooks.delete.success") as string
            );
            load();
        }).catch((error: any) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setWebhook(undefined);
    };

    return (
        <Pagination title={translate("webhooks.title")} icon={<WebhookIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Link to="/webhooks/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("webhooks.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("webhooks.columns.title") as string,
                        converter: (webhooks: Webhook) => (
                            <Ellipsis text={webhooks.title} lenght={100} uppercased={false} />
                        ),
                        xs: 4,
                        sm: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("webhooks.columns.url") as string,
                        converter: (webhooks: Webhook) => (
                            <Ellipsis text={webhooks.url} uppercased={false} />
                        ),
                        xs: true,
                        sm: true,
                        lg: true,
                        xl: true
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (webhook: Webhook) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(webhook)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        xs: "auto"
                    }
                ]} />
            {webhook && anchorEl &&
                <WebhooksMenu webhook={webhook} anchor={anchorEl} onDelete={onDelete} onClose={onCloseOption} />
            }
            {webhook && popup === "delete" && (
                <DialogPopup
                    open
                    title={translate("webhooks.delete.title")}
                    onClose={onClosePopup}
                    closeColor="default"
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="secondary"
                            disabled={submitting}
                        >
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("webhooks.delete.text", { url: webhook.url })}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />

        </Pagination>
    );
}