import { callAPI, HTTPMethod } from './API';
import { ReceptionRule, ReceptionRuleTypesRequest, ReceptionRulePaymentsRequest, ReceptionRuleUsesRequest } from '../model/ReceptionRule';

const baseUrl = (tenantId: string): string => {
    return `/tenants/${tenantId}/reception-rules`;
}

export async function getReceptionRule(tenantId: string): Promise<ReceptionRule> {
    return await callAPI(baseUrl(tenantId), {
        method: HTTPMethod.GET,
    });
}

export async function updateTypes(tenantId: string, request: ReceptionRuleTypesRequest): Promise<ReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/types`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updatePayments(tenantId: string, request: ReceptionRulePaymentsRequest): Promise<ReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/payments`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateUses(tenantId: string, request: ReceptionRuleUsesRequest): Promise<ReceptionRule> {
    return await callAPI(`${baseUrl(tenantId)}/uses`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}
