import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab, Typography } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import CurrencyIcon from '@material-ui/icons/MonetizationOn';
import AddIcon from "@material-ui/icons/Add";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { getTenantCurrencies, addCurrency, removeCurrency } from "../api/TenantConfigurationApi";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import { RouterParams } from "../router/RouterParams";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import ConfirmationPopup from "../components/ConfirmationPopup";

import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import AddCurrencyPopUp from "./AddCurrencyPopUp";
import ValidatedInput from "../components/ValidatedInput";
import { CurrencyListParams, TenantCurrency } from "../model/Currency";
import NumberFormat from 'react-number-format';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CurrencyMenu from "./CurrencyMenu";
import { TenantCurrencyRequest } from "../model/TenantConfiguration";

export default function CurrenciesList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [tenantId] = useState<string>(context.session?.tenant?.id ?? match.params.tenantId);
    const history = useHistory();

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<TenantCurrency[]>();
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>();
    const [inactivatePopup, setInactivatePopup] = useState<boolean>(false);

    const [code, setCode] = useState<string>();
    const [onOpenAddCurrency, setOpenAddCurrency] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): CurrencyListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as CurrencyListParams;
    };
    const [params, setParams] = useState<CurrencyListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CurrencyListParams>(paramsFromQueryString);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [currency, setCurrency] = useState<TenantCurrency>();
    const hasBudgets = context.isGrantedAny(["BudgetsPlanningRead", "BudgetsToExecuteRead", "BudgetsToExecuteAllRead", "BudgetsArchiveRead"]);
    const [allCurrencies, setAllCurrencies] = useState<AutocompleteProvider[]>([]);

    const load = () => {
        setStatus('loading');
        let offset = getOffset(page, pageSize);
        getTenantCurrencies(tenantId, page, offset, params).then((response) => {
            if (response.currencies && response.tenant_currency_ids) {
                setData(response.tenant_currencies.filter(item => response.currencies.map(c => c.id).includes(item.code)));
                let tempCurrencies = [];
                if (response.tenant_currency_ids) {
                    tempCurrencies = response.currencies.filter(item => !response.tenant_currency_ids.includes(item.id));
                } else {
                    tempCurrencies = response.currencies;
                }
                if (tempCurrencies) {
                    setCurrenciesAutocomplete(tempCurrencies.map((item) => {
                        return { title: (item.id + " - " + (translate("currency." + item.id) as string)), value: item.id } as AutocompleteProvider;
                    }));
                }
                setAllCurrencies((response?.currencies ?? []).map((item) => {
                    return { title: (item.id + " - " + (translate("currency." + item.id) as string)), value: item.id } as AutocompleteProvider;
                }));
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search_tenant"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onDeleteCurrency = () => {
        if (code) {
            removeCurrency(tenantId, code).then((response) => {
                setSuccess(translate("currencies.delete_success") as string);
                setOpenAddCurrency(false);
                setCode(undefined);
                load();
            }).catch((error) => {
                setStatus(error.message);
            }).finally(() => {
                setCurrency(undefined);
                setCode(undefined);
            });
        } else {
            setError(translate("currencies.empty_added") as string);
        }
    };

    const onAddCurrency = (request: TenantCurrencyRequest) => {
        if (request) {
            addCurrency(tenantId, request).then((response) => {
                setSuccess(translate("currencies.added_success") as string);
                setCode(undefined);
                load();
            }).catch((error) => {
                setStatus(error.message);
            }).finally(() => onCloseOption());
        } else {
            setError(translate("currencies.empty_added") as string);
            onCloseOption();
        }
    };

    const onCloseOption = () => {
        setOpenAddCurrency(false);
        setCode(undefined);
        setCurrency(undefined);
    };

    const showDeletePopup = (code: string) => {
        setCode(code);
        setInactivatePopup(true);
        onCloseMenu();
    };

    const showAddCurrencyPopup = () => {
        setOpenAddCurrency(true);
    };

    const hideDeletePopup = () => {
        setInactivatePopup(false);
        setCode(undefined);
        setCurrency(undefined);
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickedOptions = (currency: TenantCurrency) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrency(currency);
    };

    const onCloseMenu = () => {
        setAnchorEl(null);
    };
    const getCurrencies = () => {
        if (currency) {
            return [allCurrencies?.find(e => e.value == currency.code)] as AutocompleteProvider[];
        }
        return currenciesAutocomplete;
    }

    return (
        <Pagination title={translate("currencies.title")}
            icon={<CurrencyIcon />}
            page={page} pageSize={pageSize} count={data ? data.length : 0} total={data ? data.length : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["TenantConfigurationsUpdate"]) ?
                            (<Grid item xs="auto">
                                <Button onClick={showAddCurrencyPopup}>
                                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                        <AddIcon />
                                    </Fab>
                                </Button>
                            </Grid>) : undefined}
                    </Grid>
                </Grid>}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("currencies.search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("requisitions.teams.empty") as string}
                columns={[
                    {
                        title: translate("currencies.currency") as string,
                        converter: (item) => (
                            <div>
                                <Ellipsis text={translate("currency." + item.code) as string} lenght={0} uppercased={false} />
                            </div>
                        ),
                        xs: 7,
                        sm: 7,
                        md: 7,
                        lg: 7,
                        xl: 7
                    },
                    {
                        title: translate("currencies.code") as string,
                        converter: (item) => (
                            <div>
                                <Ellipsis text={item.code} lenght={0} uppercased={false} />
                            </div>
                        ),
                        xs: hasBudgets ? 2 : 4,
                        sm: hasBudgets ? 2 : 4,
                        md: hasBudgets ? 2 : 4,
                        lg: hasBudgets ? 2 : 4,
                        xl: hasBudgets ? 2 : 4
                    },
                    {
                        title: translate("currencies.budget_exchange_rate") as string,
                        converter: (item) =>
                        (
                            <Typography variant="body2" component="h6" align="right">
                                <NumberFormat value={item.budget_exchange_rate} prefix="$ " suffix={"MXN"} decimalScale={6} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                            </Typography>
                        ),
                        xs: hasBudgets ? 2 : false,
                        sm: hasBudgets ? 2 : false,
                        md: hasBudgets ? 2 : false,
                        lg: hasBudgets ? 2 : false,
                        xl: hasBudgets ? 2 : false
                    },
                    {
                        title: "",
                        converter: (item) =>
                            hasBudgets ?
                                (<IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(item)}>
                                    <MoreVertIcon />
                                </IconButton>)
                                :


                                (<IconButton aria-label="options" color="default" size="small" onClick={() => showDeletePopup(item.code)}>
                                    <DeleteIcon />
                                </IconButton>)
                        ,
                        fullWidth: true,
                        justify: "flex-end",
                        xs: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                    }
                ]} />
            {code && inactivatePopup && (
                <ConfirmationPopup
                    doAction={onDeleteCurrency}
                    onClose={hideDeletePopup}
                    title={translate("currencies.delete_currency") as string}
                    message={translate("currencies.message_delete", { "name": code + " - " + translate("currency." + code) as string }) as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {onOpenAddCurrency && currenciesAutocomplete && (
                <AddCurrencyPopUp
                    currencies={getCurrencies() ?? []}
                    onClose={onCloseOption}
                    currency={currency}
                    hasBudgets={hasBudgets}
                    submit={onAddCurrency}
                />
            )}
            {currency && anchorEl &&
                <CurrencyMenu
                    anchor={anchorEl}
                    curency={currency}
                    onDelete={(currency) => showDeletePopup(currency.code)}
                    onEdit={() => {
                        setCurrency(currency);
                        showAddCurrencyPopup();
                        onCloseMenu();
                    }}
                    onClose={onCloseMenu}
                />
            }
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Pagination>
    );
}