import React, { ReactNode } from "react";
import { Grid, Box, GridJustification, Hidden, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";

export type columns = boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto" | undefined;

export interface GridableProps<T> {
    items: T[];
    columns: GridableColumn<T>[];
    empty?: string;
    loading: boolean;
    error?: string;
    header?: boolean;
    border?: boolean;
    onClick?(item: T): any;
    background?(item: T | undefined): boolean;
    backgroundToBeSettled?(item: T | undefined): boolean;
}

export interface GridableColumn<T> {
    title: string | ReactNode;
    converter(item: T, index: number): ReactNode;
    justify?: GridJustification;
    xs?: columns;
    sm?: columns;
    md?: columns;
    lg?: columns;
    xl?: columns;
    fullWidth?: boolean;
    className?: string;
}

export default function Gridable<T>(props: GridableProps<T>) {
    return (
        <Grid container className="Gridable">
            {(props.header === undefined || props.header) &&
                <Grid item xs={12}>
                    <Grid container className="GridableRow GridableHeader">
                        {props.columns.map((column: GridableColumn<T>, index: number) => (
                            <GridableCell key={index} column={column} index={index} />
                        ))}
                    </Grid>
                </Grid>
            }
            <Grid item xs={12}>
                {gridableBody(props)}
            </Grid>
        </Grid>
    );
}

function gridableBody<T>(props: GridableProps<T>) {
    if (props.loading) {
        return genericRow(<CircularProgress size="1rem" color="secondary" />);
    }

    if (props.error) {
        return genericRow(<Typography variant="caption" color="error">{props.error}</Typography>);
    }

    if (props.items.length === 0 && props.empty) {
        return genericRow(<Typography variant="caption">{props.empty}</Typography>);
    }

    return (
        <Grid item xs={12}>
            {props.items.map((item: T, indexR: number) => (
                <Grid key={indexR} container className={clsx({
                    "GridableRow": (props.border === undefined || props.border),
                    "GridableRowClickable": props.onClick !== undefined,
                    "BackgroundRow": props.background && props.background(item),
                    "BackgroundToBeSettledRow": props.backgroundToBeSettled && props.backgroundToBeSettled(item),
                })} onClick={props.onClick ? () => { props.onClick!(item) } : undefined}>
                    {props.columns.map((column: GridableColumn<T>, indexC: number) => (
                        <GridableCell key={`${indexR}-${indexC}`} column={column} item={item} index={indexR} />
                    ))}
                </Grid>
            ))}
        </Grid>
    );
}

function genericRow(child: ReactNode) {
    return (
        <Grid item xs={12}>
            <Grid container justify="center" className="GridableRow">
                <Grid item xs="auto">
                    <Box py={1}>{child}</Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface GridableCellProps<T> {
    column: GridableColumn<T>;
    item?: T;
    index: number;
}

function GridableCell<T>(props: GridableCellProps<T>) {
    return (
        <Hidden
            xsDown={props.column.xs === false}
            smDown={props.column.sm === false}
            mdDown={props.column.md === false}
            lgDown={props.column.lg === false}
            xlDown={props.column.xl === false}>
                <Grid  item xs={props.column.xs} sm={props.column.sm} md={props.column.md} lg={props.column.lg} xl={props.column.xl} className={props.column.className}>
                    <Box px={2} display="flex" alignItems="center" justifyContent={props.column.justify || "flex-start"} className="GridableCell">
                        <Box textOverflow="ellipsis" overflow={props.column.fullWidth ? undefined : "auto hidden"} width={props.column.fullWidth ? "100%" : undefined}>
                            {props.item && (props.column.converter(props.item, props.index))}
                            {!props.item && (typeof props.column.title === "string") && (<strong>{props.column.title}</strong>)}
                            {!props.item && (typeof props.column.title !== "string") && (<div>{props.column.title}</div>)}
                        </Box>
                    </Box>
                </Grid>
            
        </Hidden>
    );
}