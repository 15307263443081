import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import ProvidersIcon from "@material-ui/icons/Business";

import translate from "../i18n/Translator";
import { ProvidersDashboardResponse } from "../model/Dashboard";
import Surface from "../components/Surface";
import { EfosList, Cff69List, ProviderStatusList } from "../providers/AllProvidersVerificationPopup";
import { GuideProviders } from "../guides/Guides";
import { EfosChart, NotLocatedChart, ExpedientChart, StatusChart } from "./ProvidersChars";
import { ExpedientStatusList } from "../expedients/ExpedientIcon";

interface ProvidersDashboardProps {
    summary: ProvidersDashboardResponse;
}

export default function ProvidersDashboard(props: ProvidersDashboardProps) {
    return (
        <Surface title={translate("providers.title")}
            subtitle={props.summary.total ? translate("providers.count", { "total": props.summary.total }) : translate("providers.empty_count")}
            icon={<ProvidersIcon />}>
            <ProviderDashboardContent summary={props.summary} />
        </Surface>
    );
}

interface ProviderDashboardContentProps {
    summary: ProvidersDashboardResponse;
}

function ProviderDashboardContent(props: ProviderDashboardContentProps) {
    if (props.summary.total === 0) {
        return (
            <Grid container justify="center" alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <GuideProviders />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justify="space-evenly" alignItems="flex-start" spacing={3}>
            {props.summary.efos && (
                <Grid item xs={12} lg={6} xl={5}>
                    <ProvidersElement
                        list={(
                            <EfosList dense values={props.summary.efos} header />
                        )}
                        chart={(<EfosChart info={props.summary.efos} />)} />
                </Grid>
            )}
            {props.summary.cff69 && (
                <Grid item xs={12} lg={6} xl={5}>
                    <ProvidersElement
                        list={(
                            <Cff69List dense values={props.summary.cff69} header /> 
                        )}
                        chart={(<NotLocatedChart info={props.summary.cff69} />)} />
                </Grid>
            )}
            <Grid item xs={12} lg={6} xl={5}>
                <ProvidersElement
                    list={(
                        <ProviderStatusList dense values={props.summary.status} header />
                    )}
                    chart={(<StatusChart info={props.summary.status} />)} />
            </Grid>
            {props.summary.expedient && (
                <Grid item xs={12} lg={6} xl={5}>
                    <ProvidersElement
                        list={(
                            <ExpedientStatusList dense values={props.summary.expedient} header />
                        )}
                        chart={(<ExpedientChart info={props.summary.expedient} />)} />
                </Grid>
            )}
        </Grid>
    );
}

interface ProvidersElementProps {
    list: React.ReactNode;
    chart: React.ReactNode;
}

function ProvidersElement(props: ProvidersElementProps) {
    return (
        <Grid container justify="space-between" alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sm={5} md={6} lg={6} xl={5}>
                {props.list}
            </Grid>
            <Hidden xsDown>
                <Grid item xs={12} sm={5} md={6} lg={6} xl={5}>
                    {props.chart}
                </Grid>
            </Hidden>
        </Grid>
    );
}