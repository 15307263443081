import React, { useState, useContext } from "react";
import { Grid, Button, Typography, Box, DialogTitle, DialogContent, Dialog, DialogActions, Divider } from '@material-ui/core';

import { testExpression } from "../api/ExpressionAPI";
import translate from "../i18n/Translator";
import { Expression, ExpressionTestRequest } from '../model/Expression';
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';
import CloseIcon from "@material-ui/icons/Close";

interface ExpressionTestProps {
    expression: Expression
    onClose(): any;
}

export default function ExpressionTestPopup(props: ExpressionTestProps) {
    const context = useContext(AppContext);
    const margin = "dense";

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<ExpressionTestRequest>({} as ExpressionTestRequest);
    const [validations, setValidations] = useState({} as any);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        setSubmitting(true);
        testExpression(context.session!.tenant!.id, props.expression.id, request).then((_) => {
            setSuccessMessage(_.toString());
            setSubmitting(false);
        }).catch((error) => {
            setErrorMessage(props.expression.error_message);
            setSubmitting(false);
        });
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("expressions.test.title") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                {translate("expressions.test.name") as string}
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <Typography variant="subtitle1">
                                {props.expression.name}
                            </Typography>
                        </Grid>


                        <Grid item xs={2}>
                            <Typography variant="body1" >
                                {translate("expressions.test.regex") as string}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="subtitle1">
                                {props.expression.expression}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: "15px", marginBottom: "10px" }}>
                            <Divider ></Divider>
                        </Grid>


                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="value" name="value"
                                value={request.value}
                                maxRows={3}
                                rows={3}
                                label={translate("expressions.test.test_label") as string}
                                required={true} disabled={submitting}
                                margin={margin}
                                onValueChanged={hasChanged} />
                        </Grid>


                        <Grid item xs={2} md="auto" style={{ marginRight: "10px", marginTop: "10px" }} >
                            <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                {translate("expressions.test.test_button")}
                            </Button>
                        </Grid>
                        {!!errorMessage && (
                            <Grid item xs={10} md="auto" alignItems="flex-end" wrap="wrap" >
                                <Grid container alignItems="center">
                                    <CloseIcon style={{ "color": "#dc3545" }} />
                                    <Typography variant="subtitle1" color="error" >
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>)

                        }
                        {!!successMessage && (
                            <Grid item xs={10} md="auto" >
                                <ValidIcon style={{ "color": "#82CA9D" }} />
                            </Grid>)
                        }

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Box >
                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                            <Grid item xs={12} md="auto">
                                <Button variant="outlined" color="primary" size="large" disabled={submitting} onClick={props.onClose}>
                                    {translate("buttons.close")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>

            </form>
        </Dialog>
    );
}