import { callAPI, getAuthenticatedGetUrl, HTTPMethod } from "./API";
import {
    Entity, Providers, ProvidersQueryParams, ProviderRequest, ImportSummary, ImportUsersSummary,
    AssignValidatorRequest, AssignValidatorResponse, QuickVerificationRequest, QuickVerificationResponse,
    ProviderClassificationsResponse, ProviderUpdateClassificationsRequest, ProviderUpdateClassificationsResponse, MetadataResponse,
    ProviderAssignSpecificRuleResponse, ProviderAssignSpecificRuleRequest, ProviderAssignTemplateResponse,
    ProviderAssignWorkflowRequest, ProvidersByTypeResponse, ValidateProvidersWorkflowDeleteRequest, ValidateProvidersWorkflowDeleteResponse,
    ProvidersSpecialExpedientParams
} from "../model/Provider";
import { EfoValidationAll } from "../model/Efos";
import { Cff69ValidationAll } from "../model/Cff69";
import { UsersValidator } from "../model/User"
import { CurrencyAndPaymentMethods } from "../model/Payment";
import { ExportRequest, ExportResponse } from "../model/Connector";
import { TenantBillingData } from "../model/Tenant";

export async function getProviders(tenantId: string, page: number, offset: number, params: ProvidersQueryParams): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status || "",
            statusExpedient: params.status_expedient || "",
            classification: params.classification|| "",
            validatorUserId: params.validator_user_id|| "",
            pageSize: page.toString(),
            offset: offset.toString(),
            type: params.type ? params.type : "",
            entityType: params.entity_type || "",
            selected_ids: params.selected_ids,
            makeAdvancePayments: params.make_advance_payments || "",
            templateId: params.template_id || "",
            companyId: params.company_id || ""
        }
    });
}

export async function getProvidersEfos(tenantId: string, page: number, offset: number, params: ProvidersQueryParams): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            efosStatus: params.efos_status,
            notLocatedStatus: params.not_located_status,
            canceledStatus: params.canceled_status,
            enforceableStatus: params.enforceable_status,
            firmStatus: params.firm_status,
            sentencesStatus: params.sentences_status,
            inOrder: params.in_order,
            status: params.status,
            classification: params.classification,
            validatorUserId: params.validator_user_id,
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getProvidersWithReceptors(tenantId: string, page: number, offset: number, params: ProvidersQueryParams): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers/control-reception`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            classification: params.classification,
            receptor: params.receptor,
            specificRule: params.specific_rule,
            entityType: params.entity_type || "",
            workflowId: params.workflow_id,
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createProvider(tenantId: string, request: ProviderRequest): Promise<Entity> {
    return await callAPI(`/tenants/${tenantId}/providers`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function metadata(tenantId: string): Promise<MetadataResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/metadata`, {
        method: HTTPMethod.GET
    });
}

export async function importProviders(tenantId: string, file: File): Promise<ImportSummary> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    return await callAPI(`/tenants/${tenantId}/providers/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function downloadImportTemplate(tenantId: string, connectorId: string): Promise<string> {
    return await getAuthenticatedGetUrl(`/tenants/${tenantId}/providers/import`, {
        query: {
            connectorId: connectorId,
        },
    });
}

export async function importUsers(tenantId: string, file: File): Promise<ImportUsersSummary> {
    let data = new FormData();
    data.append("excel", file as Blob, file.name);

    return await callAPI(`/tenants/${tenantId}/providers/import-contacts`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getProvider(tenantId: string, providerId: string): Promise<Entity> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateProvider(tenantId: string, providerId: string, request: ProviderRequest): Promise<Entity> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function verifyEfo(tenantId: string, providerId: string): Promise<Entity> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/efos`, {
        method: HTTPMethod.PUT
    });
}

export async function verifyEfoAll(tenantId: string): Promise<EfoValidationAll> {
    return await callAPI(`/tenants/${tenantId}/providers/efos`, {
        method: HTTPMethod.PUT
    });
}

export async function verifyCff69(tenantId: string, providerId: string): Promise<Entity> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/cff-69`, {
        method: HTTPMethod.PUT
    });
}

export async function verifyCff69All(tenantId: string): Promise<Cff69ValidationAll> {
    return await callAPI(`/tenants/${tenantId}/providers/cff-69`, {
        method: HTTPMethod.PUT
    });
}

export async function quickVerification(tenantId: string, request: QuickVerificationRequest): Promise<QuickVerificationResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/verify`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function exportProviders(tenantId: string, connectorId: string, params: ProvidersQueryParams, isProvider:boolean): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/${isProvider? "export": "export-vendors"}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            efosStatus: params.efos_status,
            notLocatedStatus: params.not_located_status,
            status: params.status,
            type: params.type || "",
            classification: params.classification,
            validatorUserId: params.validator_user_id,
            connectorId: connectorId,
        }
    });
}

export async function exportSelectedProviders(tenantId: string, connectorId: string, ids: string[], isProvider:boolean): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/${isProvider? "export": "export-vendors"}`, {
        method: HTTPMethod.PUT,
        body: {
            ids: ids,
            connector_id: connectorId,
        } as ExportRequest
    });
}

export async function getUsersValidator(tenantId: string): Promise<UsersValidator> {
    return await callAPI(`/tenants/${tenantId}/providers/getUsersValidator`, {
        method: HTTPMethod.GET
    });
}

export async function assignValidator(tenantId: string, request: AssignValidatorRequest): Promise<AssignValidatorResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/assign-validator`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

//TODO La respuesta cambió y quizá sea necesario mover a nueva API
export async function getClassifications(tenantId: string): Promise<ProviderClassificationsResponse[]> {
    return await callAPI(`/tenants/${tenantId}/providers/classifications`, {
        method: HTTPMethod.GET
    });
}

export async function updateClassifications(tenantId: string, request: ProviderUpdateClassificationsRequest): Promise<ProviderUpdateClassificationsResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/classifications`, {
        method: HTTPMethod.PATCH,
        body: request,
    });
}

export async function updateUsersReceptor(tenantId: string, request: ProviderUpdateClassificationsRequest): Promise<ProviderUpdateClassificationsResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/users-receptor`, {
        method: HTTPMethod.PATCH,
        body: request,
    });
}

export async function updateUsersSecondAuthorizator(tenantId: string, request: ProviderUpdateClassificationsRequest): Promise<ProviderUpdateClassificationsResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/users-second-authorizator`, {
        method: HTTPMethod.PATCH,
        body: request,
    });
}

export async function assignSpecialRule(tenantId: string, request: ProviderAssignSpecificRuleRequest): Promise<ProviderAssignSpecificRuleResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/assign-special-rule`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function assignWorkflows(tenantId: string, request: ProviderAssignWorkflowRequest): Promise<ProviderAssignTemplateResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/assign-workflow`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function validateWorkflowsDelete(tenantId: string, request: ValidateProvidersWorkflowDeleteRequest): Promise<ValidateProvidersWorkflowDeleteResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/validate-workflow-delete`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function getProvidersWithPendingPayments(tenantId: string): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers/pending-payments`, {
        method: HTTPMethod.GET
    });
}

export async function getCurrencyAndPaymentMethod(tenantId: string, providerId: string): Promise<CurrencyAndPaymentMethods> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/get-currency-payment-method`, {
        method: HTTPMethod.GET
    });
}

export async function deleteProvider(tenantId: string, providerId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getCountProvidersByTypes(tenantId: string): Promise<ProvidersByTypeResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/count-providers-by-type`, {
        method: HTTPMethod.GET,
    });
}

export async function exportUsersProviders(tenantId: string, params: ProvidersQueryParams): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/export-users-excel`, {
        method: HTTPMethod.GET,
        query: parseProviderQuery(params),
    });
}

const parseProviderQuery = (params: ProvidersQueryParams) => {
    let query = {
        search: params.search,
            efosStatus: params.efos_status,
            notLocatedStatus: params.not_located_status,
            status: params.status,
            classification: params.classification,
            validatorUserId: params.validator_user_id,
            entityType: params.entity_type || "",
            selectedIds: params.selected_ids,
            pageSize: "0",
    }as Record<string, string>;

    return query;
}

export async function acceptTermsConditions(tenantId: string, providerId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/terms-conditions-accepted`, {
        method: HTTPMethod.PUT
    });
}

export async function listProvidersOnlyIdName(tenantId: string, page: number, offset: number, params: ProvidersQueryParams): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers/list-only-id-name`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status || "",
            statusExpedient: params.status_expedient || "",
            classification: params.classification|| "",
            validatorUserId: params.validator_user_id|| "",
            entityType: params.entity_type || "",
            pageSize: page.toString(),
            offset: offset.toString(),
            type: params.type ? params.type : "",
            requisitionMode: params.requisition_mode ? "true" : "false"
        }
    });
}

export async function getProvidersWithPendingDocs(tenantId: string): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers/list-by-docs-pending`, {
        method: HTTPMethod.GET
    });
}

export async function getBillingData(tenantId: string,providerId: string,): Promise<TenantBillingData> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/billing-data`, {
        method: HTTPMethod.GET,
    });
}

export async function exportExpedientProviders(tenantId: string, connectorId: string, params: ProvidersQueryParams, isProvider:boolean): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/export-expedients`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            efosStatus: params.efos_status,
            notLocatedStatus: params.not_located_status,
            status: params?.status ?? "",
            type: params.type || "",
            classification: params.classification,
            validatorUserId: params.validator_user_id,
            connectorId: connectorId,
        }
    });
}

export async function exportExpedientSelectedProviders(tenantId: string, connectorId: string, ids: string[], isProvider:boolean): Promise<ExportResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/export-expedients`, {
        method: HTTPMethod.PUT,
        body: {
            ids: ids,
            connector_id: connectorId,
        } as ExportRequest
    });
}

export async function getProvidersByReviewrsId(tenantId: string, page: number, offset: number, params: ProvidersSpecialExpedientParams): Promise<Providers> {
    return await callAPI(`/tenants/${tenantId}/providers/special/reviewers`, {
        method: HTTPMethod.GET,
        query: {
            name: params.name || "",
            externalId: params.external_id || "",
            statusExpedient: params.status_expedient || "",
            classification: params.classification|| "",
            templateId: params.template_id || "",
            companyId: params.company_id || "",
            rfc: params.rfc || "",
            reviewersIds: params.reviewers_ids,
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}