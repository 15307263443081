import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditTwoTone";
import AddIcon from "@material-ui/icons/AddTwoTone";
import AcceptIcon from "@material-ui/icons/CheckCircleTwoTone";
import RejectIcon from "@material-ui/icons/BlockTwoTone";
import DownloadIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/CancelTwoTone';
import CloseIcon from "@material-ui/icons/Close";
import translate from "../i18n/Translator";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { AppContext } from "../context/AppContext";

import { getPurchaseOrderPdfUrl } from "../api/ProviderPurchaseOrderAPI";

interface PurchaseOrderMenuProps {
    purchaseOrder: PurchaseOrder;
    anchor: HTMLElement | null;
    onClose(): any;
    onAccept(): any;
    onReject(): any;
    onCancel(): any;
    onFinalize(): any;
    restrictUploadCfdi: Boolean;
    allowCancelClosePOManually: Boolean;
}

export default function PurchaseOrderMenu(props: PurchaseOrderMenuProps) {
    const context = useContext(AppContext);
    const [pdfUrl, setPdfUrl] = useState<string>();

    useEffect(() => {
        if (props.purchaseOrder.pdf_s3_key_file) {
            getPurchaseOrderPdfUrl(context.session!.tenant!.id, buildProviderId(props.purchaseOrder), props.purchaseOrder.id).then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
        // eslint-disable-next-line
    }, [context.session, props.purchaseOrder]);

    const buildProviderId = (purchaseOrder: PurchaseOrder): string => {
        if (purchaseOrder.provider) {
            return purchaseOrder.provider!.id;
        }
        if (context.session!.provider) {
            return context.session!.provider!.id;
        }
        return "";
    };

    const showPdfDownloadBtn = (purchaseOrder: PurchaseOrder): Boolean => {
        return !!purchaseOrder?.pdf_s3_key_file;
    }

    const onDownloadPdf = () => {
    }
    return (
        <Menu
            id="-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button component={Link} to={`/purchase-orders/${props.purchaseOrder.id}`} onClick={props.onClose} dense>
                <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.see")}</Typography>
            </MenuItem>

            {showPdfDownloadBtn(props.purchaseOrder) && (
                <MenuItem button onClick={onDownloadPdf} dense component="a" href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("purchase_orders.download_pdf")}</Typography>
                </MenuItem>
            )}

            {context.isGranted("ProviderPurchaseOrdersUpdate") && !props.restrictUploadCfdi && context.session?.provider?.type !== "FOREIGN" &&(
                <MenuItem button component={Link} to={`/purchase-orders/${props.purchaseOrder.id}/cfdi`} onClick={props.onClose} dense disabled={props.purchaseOrder.status !== "O" || props.purchaseOrder.acceptance_status !== "ACCEPTED"}>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("purchase_orders.add_cfdi")}</Typography>
                </MenuItem>
            )}
            {context.isGranted("ProviderPurchaseOrdersUpdate")
                && (!props.purchaseOrder.acceptance_status || "PENDING_TO_REVIEW" === props?.purchaseOrder?.acceptance_status)
                && (
                    <MenuItem button onClick={props.onAccept} dense>
                        <ListItemIcon>
                            <AcceptIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.accept")}</Typography>
                    </MenuItem>
                )}
            {context.isGranted("ProviderPurchaseOrdersUpdate")
                && (!props.purchaseOrder.acceptance_status || "PENDING_TO_REVIEW" === props?.purchaseOrder?.acceptance_status)
                && (
                    <MenuItem button onClick={props.onReject} dense>
                        <ListItemIcon>
                            <RejectIcon fontSize="small" color="secondary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.reject")}</Typography>
                    </MenuItem>
                )}
            {context.isGranted("PurchaseOrdersUpdate") && props.allowCancelClosePOManually && (<>
                <MenuItem button onClick={props.onCancel} dense>
                    <ListItemIcon>
                        <CancelIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.cancel")}</Typography>
                </MenuItem>
                <MenuItem button onClick={props.onFinalize} dense>
                    <ListItemIcon>
                        <CloseIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.close")}</Typography>
                </MenuItem>
            </>)}
        </Menu>
    );

}

