import React from "react";

import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import translate from "../i18n/Translator";
import { Entity as Provider, getCompanyNames } from "../model/Provider";
import { GridableColumn } from "../components/Gridable";
import { ProviderListCallbacks, ProviderNameColumnTitle, ProviderNameColumnValue } from "../providers/Providers";
import ProvidersManagementList from "../providers/ProvidersManagementList";

export default function VendorsView() {

    const getColumns = (providers: Provider[], showChecks: boolean, selected: string[], callbacks: ProviderListCallbacks) => {
        let all = [
            {
                title: (
                    <ProviderNameColumnTitle
                        total={providers.length}
                        selected={selected.length}
                        showChecks={showChecks}
                        callbacks={callbacks} />
                ),
                converter: (provider) => (
                    <ProviderNameColumnValue
                        provider={provider}
                        checked={selected.indexOf(provider.id) >= 0}
                        showChecks={showChecks}
                        callbacks={callbacks} 
                        showIcons={true}
                    />
                ),
                fullWidth: true,
                xs: true,
            },
            {
                title: translate("providers.companies.title") as string,
                converter: (provider) => getCompanyNames || "---",
                xs: false,
                sm: false,
                md: false,
                lg: 2,
                xl: 2
            },
            {
                title: translate("providers.external_id") as string,
                converter: (provider) => provider.external_id || "---",
                xs: false,
                sm: 3,
                md: 3,
                lg: 2,
                xl: 1
            },
            {
                title: translate("providers.tax_id") as string,
                converter: (provider) => provider.tax_id || "---",
                xs: false,
                sm: 3,
                md: 3,
                lg: 2,
                xl: 1
            },
            {
                title: (
                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                ),
                converter: (provider) => (
                    <IconButton aria-label="options"
                        color="default"
                        size="small"
                        onClick={callbacks.onClickedOptions(provider)}
                        disabled={selected.length > 0}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: "auto"
            }
        ] as GridableColumn<Provider>[];

        return all.filter((column) => column !== null);
    };

    return (
        <ProvidersManagementList getColumns={getColumns} subtitle={translate("providers.views.base") as string} entityType="VENDOR"/>
    );

}