import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, Divider } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { CreatePaymentFileRequest, CounterTotal } from "../model/PaymentOrder";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { WarningSnackbar } from "../components/Snackbars";
import { Bank } from "../model/Bank";
import { TenantBankAccount } from "../model/BankAccount";
import NumberFormat from "react-number-format";

interface SelectedBankAccounts {
    currency: string;
    value: string;
}

interface CreatePaymentFilePopupProps {
    tenant_banks: Bank[];
    tenant_bank_accounts: TenantBankAccount[];
    details_by_currency: Map<String, CounterTotal>;
    onConfirm(paymentFileRequest: CreatePaymentFileRequest): any;
    onClose(): any;
}

export default function CreatePaymentFilePopup(props: CreatePaymentFilePopupProps) {
    const [totalCfdis, setTotalCfdis] = useState<number>(0);
    const [selectedBank, setSelectedBank] = useState<string>(props.tenant_banks.length === 1 ? props.tenant_banks[0].id : "");
    const [selectedBankAccounts, setSelectedBankAccounts] = useState<SelectedBankAccounts[]>([]);
    const [warning, setWarning] = useState<string>();
    const [optionsBank, setOptionsBank] = useState<string[]>();
    const [optionLabelsBank, setOptionLabelsBank] = useState<string[]>();

    useEffect(() => {
        setTotalCfdis(0);
        Object.keys(props.details_by_currency).forEach((currency) => {
            setTotalCfdis(t => t + (props.details_by_currency as any)[currency].counter);
        });
        setOptionsBank(props.tenant_banks.map(b => b.id));
        setOptionLabelsBank(props.tenant_banks.map(b => b.name));
    }, [props, selectedBank])

    const onConfirm = () => {
        if (!selectedBank) {
            setWarning(translate("payment_order.create_payment_file.warning_bank_empty") as string);
            return;
        }
        let bankAccountsRequest = selectedBankAccounts.filter(ba => ba.value !== "" && ba.value !== "---").map(ba => ba.value);
        if (!bankAccountsRequest || bankAccountsRequest.length < Object.keys(props.details_by_currency).length) {
            setWarning(translate("payment_order.create_payment_file.warning_bank_account_empty") as string);
            return;
        }
        props.onConfirm({bank_id: selectedBank, bank_account_ids: bankAccountsRequest} as CreatePaymentFileRequest);
    };

    const onChangeBank = (name: string, value: string, inputRef: InputRef) => {
        setSelectedBank(value);
    };

    const onChangeBankAccounts = (name: string, value: string) => {
        let temp = selectedBankAccounts;
        let safeValue = value === "---" ? "" : value;
        if(temp.find(ba => ba.currency === name)){
            temp.forEach(ba => {
                if(ba.currency === name){
                    ba.value = safeValue;
                }
            })
        } else {
            temp.push({currency: name, value: safeValue})
        }
        setSelectedBankAccounts(temp);
    }

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    return (
        <DialogPopup open
            title={translate("payment_order.create_payment_file.title") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="xs"
            variant="text"
            button={
                <Box pl={1}>
                    <Button onClick={onConfirm} variant="outlined" color="primary" >
                        {translate("payment_order.create_payment_file.generate_button") as string}
                    </Button>
                </Box>}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {translate("payment_order.create_payment_file.message", { "cfdis": totalCfdis }) as string}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                {translate("payment_order.create_payment_file.guide_message") as string}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="bank" name="bank" label={translate("bank_account.bank") as string}
                                required={true}
                                options={optionsBank}
                                optionLabels={optionLabelsBank}
                                margin={"dense"}
                                value={selectedBank}
                                onValueChanged={onChangeBank} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {Object.keys(props.details_by_currency).map((currency) => (
                            <Grid key={currency} item xs={12}>
                                <PaymentAmountDetail
                                    currency={currency}
                                    counterTotal={(props.details_by_currency as any)[currency]}
                                    tenant_bank_accounts={props.tenant_bank_accounts.filter(tba => tba.currency === currency && tba.bank.id === selectedBank)}
                                    onChangeAccount={onChangeBankAccounts}
                                    options={props.tenant_bank_accounts.filter(tba => tba.currency === currency && tba.bank.id === selectedBank).map(ba => ba.id)}
                                    optionLabels={props.tenant_bank_accounts.filter(tba => tba.currency === currency && tba.bank.id === selectedBank).map(ba => ba.name)}
                                    />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}

interface PaymentAmountDetailProps {
    currency: string,
    counterTotal: CounterTotal,
    tenant_bank_accounts: TenantBankAccount[],
    onChangeAccount(name: string, value: string): any;
    options: string[];
    optionLabels: string[];
}

function PaymentAmountDetail(props: PaymentAmountDetailProps) {
    const [value] = useState<string>(props.tenant_bank_accounts.length === 1 ? props.tenant_bank_accounts[0].id: "");
    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
                <Typography style={{ "fontSize": "1.0rem", "color": "#1f679f" }} align="right">
                    <strong><NumberFormat value={props.counterTotal.total} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" suffix={` ${props.currency}`}/></strong>
                </Typography>
                <Typography style={{ "fontSize": "0.80rem" }} align="right" color="textSecondary">
                    {translate(`payment_order.create_payment_file.${props.counterTotal.counter > 1 ? 'multiple_payables' : 'single_payable'}`, { "counter": props.counterTotal.counter }) as string}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <ValidatedInput type="text" id={`bank_accounts_${props.currency}`} name={`bank_accounts_${props.currency}`}
                    label={translate("payment_order.create_payment_file.tenant_bank_accounts", { "currency": props.currency }) as string}
                    required={true}
                    options={props.options}
                    optionLabels={props.optionLabels}
                    margin={"dense"}
                    onValueChanged={props.onChangeAccount}
                    value={value}/>
            </Grid>
        </Grid>
    );
}
