import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import PluginIcon from "@material-ui/icons/AccountTree";

import { createPlugin, getPlugin, updatePlugin } from "../api/PluginAPI";
import translate from "../i18n/Translator";
import { Plugin, PluginRequest, TYPES, STEPS } from "../model/Plugin";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";

export default function PluginForm({ match }: RouterParams) {
    const history = useHistory();
    const isEdit = !!match.params.pluginId;
    const margin = "dense";
    const typeLabels = TYPES.map(t => translate(`plugins.types.${t}`) as string);
    const stepLabels = STEPS.map(s => translate(`plugins.steps.${s}`) as string);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<PluginRequest>({} as PluginRequest);
    const [validations, setValidations] = useState({} as any);

    const submitPromise = (): Promise<Plugin> => {
        if (isEdit && match.params.pluginId) {
            return updatePlugin(match.params.tenantId, match.params.pluginId, request);
        }
        return createPlugin(match.params.tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            getPlugin(match.params.tenantId, match.params.pluginId).then((plugin) => {
                setRequest({
                    name: plugin.name,
                    type: plugin.type,
                    value: plugin.value,
                    step: plugin.step,
                } as PluginRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [isEdit, match.params.pluginId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((plugin) => {
            setRedirect(true);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (redirect) {
        return (
            <Redirect to={`/tenants/${match.params.tenantId}/plugins`} />
        );
    }

    if (status === "loading") {
        return (
            <Progress />
        );
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "plugins.edit" : "plugins.new")} icon={<PluginIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("plugins.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="type" name="type"
                                        value={request.type}
                                        label={translate("plugins.types.title") as string}
                                        required={true} disabled={submitting}
                                        options={TYPES} optionLabels={typeLabels}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="step" name="step"
                                        value={request.step}
                                        label={translate("plugins.steps.title") as string}
                                        required={true} disabled={submitting}
                                        options={STEPS} optionLabels={stepLabels}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput type="text" id="value" name="value"
                                        value={request.value}
                                        label={translate("plugins.value") as string}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}