import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Hidden, Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/PublishTwoTone";
import AddUsersIcon from '@material-ui/icons/GroupAddTwoTone';
import GetAppIcon from "@material-ui/icons/GetAppTwoTone";
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import LabelIcon from '@material-ui/icons/Label';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottomTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ErrorSnackbar } from "../components/Snackbars";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ExportUsersIcon from '@material-ui/icons/AssignmentIndTwoTone';

interface ProvidersManagementMenuProps {
    anchor: HTMLElement | null;
    totalItems?: number;
    onClose(): any;
    onImportProviders(): any;
    onImportUsers(): any;
    onExportWithParams(): any;
    onExportSelected(): any;
    onExportUsersWithParams(): any;
    onExportUsersSelected(): any;
    onDownloadTemplate(): any;
    onSortProvider(): any;
    entityType: "PROVIDER" | "VENDOR";
}

export default function ProvidersManagementMenu(props: ProvidersManagementMenuProps) {
    const context = useContext(AppContext);
    const [error, setError] = useState<string>();
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [openExportUsers, setOpenExportUsers] = useState<boolean>(false);
    const isProvider = props.entityType === "PROVIDER";

    const handleClickExport = () => {
        setOpenExport(!openExport);
    };

    const handleClickExportUsers = () => {
        setOpenExportUsers(!openExportUsers);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
            autoFocus={false}
            variant="selectedMenu"
        >
            {context.isGranted("ProvidersCreate") && isProvider &&
                <div>
                    <Hidden implementation="css" smUp>
                        <MenuItem button onClick={props.onImportProviders} dense>
                            <ListItemIcon>
                                <PublishIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <Typography variant="inherit">{translate("providers.import.button")}</Typography>
                        </MenuItem>
                    </Hidden>
                </div>
            }
            {context.isGranted("OwnerProvidersUsersCreate") && isProvider &&
                <div>
                    <Hidden implementation="css" smUp>
                        <MenuItem button onClick={props.onImportUsers} dense>
                            <ListItemIcon>
                                <AddUsersIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <Typography variant="inherit">{translate("providers.import_users.button")}</Typography>
                        </MenuItem>
                    </Hidden>
                </div>
            }
            {context.isGranted("ProvidersCreate") && isProvider &&
                <MenuItem button onClick={props.onDownloadTemplate} dense>
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.import.download")}</Typography>
                </MenuItem>
            }
            {context.isGranted("OwnerProvidersUsersCreate") && isProvider &&
                <MenuItem button onClick={props.onClose} dense component="a" href="/plantilla-importacion-usuarios-proveedor.zip" target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <VerticalAlignBottomIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("providers.import_users.download")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["ProvidersRead", "VendorsRead"]) && (
                <ListItem button onClick={handleClickExport} dense>
                    <ListItemIcon>
                        <ImportExportIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("providers"+ ( isProvider ? ".export" : ".export_vendor"))} />
                    {openExport ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
            {context.isGrantedAny(["ProvidersRead", "VendorsRead"]) && (
                <Collapse in={openExport} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem button onClick={props.onExportSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.selected")} />
                        </ListItem>
                        <ListItem button onClick={props.onExportWithParams} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.all_in_view")} />
                        </ListItem>
                    </List>
                </Collapse>
            )}
            {context.isGranted("ProvidersRead") && isProvider && (
                <ListItem button onClick={handleClickExportUsers} dense>
                    <ListItemIcon>
                        <ExportUsersIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("providers.export_users")} />
                    {openExportUsers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
            {context.isGranted("ProvidersRead") && isProvider && (
                <Collapse in={openExportUsers} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem button onClick={props.onExportUsersSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.selected")} />
                        </ListItem>
                        <ListItem button onClick={props.onExportUsersWithParams} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.all_in_view")} />
                        </ListItem>
                    </List>
                </Collapse>
            )}
            {context.isGranted("ProvidersUpdate") && isProvider &&
                <MenuItem button onClick={props.onSortProvider} dense>
                    <ListItemIcon>
                        <LabelIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.sort_provider")}</Typography>
                </MenuItem>
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Menu>
    );

}