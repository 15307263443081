import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, CardMedia, CardActions, Button, Grid } from "@material-ui/core";
import Carousel from "react-material-ui-carousel"
import translate from "../i18n/Translator";
import { Plan } from "../model/Plan";
import CustomBackdrop from "../components/CustomBackdrop";
import { upgrade } from "../api/TenantsBillingAPI";
import { BillingUpgradeRequest } from "../model/Billing";
import { ErrorSnackbar } from "../components/Snackbars";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 330,
            [theme.breakpoints.down("sm")]: {
                width: 300,
            },
        },
        media: {
            height: 440,
            [theme.breakpoints.down("sm")]: {
                maxWidth: 400,
            },
        },
        cover: {
            width: "100%",
            borderRadius: theme.shape.borderRadius,
        }
    }),
);

interface PlanPickerProps {
    plans: Plan[];
    startAt?: number;
    onClose(): any;
    onPickedPlan(plan: Plan): any;
}

export default function PlanPicker(props: PlanPickerProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Carousel animation="fade" autoPlay={false} navButtonsAlwaysVisible={true} fullHeightHover={false} startAt={props.startAt} className="PlanCarousel">
                {props.plans.map((plan: Plan) => (
                    <Card key={plan.id}>
                        <CardActionArea>
                            <CardMedia image={plan.image_url} title={plan.name} className={classes.media} />
                        </CardActionArea>
                        <CardActions>
                            <Grid container justify="space-between" alignContent="center" alignItems="center">
                                <Grid item xs="auto">
                                    <Button size="small" color="default" onClick={props.onClose}>
                                        {translate("buttons.cancel")}
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button size="medium" color="primary" onClick={() => props.onPickedPlan(plan)}>
                                        {translate("buttons.choose")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                ))}
            </Carousel>
        </div>
    );
}

interface PlanDetailProps {
    tenantId: string;
    billingId?: string;
    plan: Plan;
    plans: Plan[];
    upgradable: boolean;
}

export function PlanDetail(props: PlanDetailProps) {
    const classes = useStyles();
    const [showPlans, setShowPlans] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [index, setIndex] = useState<number>();

    const onSelectedPlan = (plan: Plan) => {
        setShowPlans(false);
        setSubmitting(true);

        let request = {
            plan_id: plan.id,
            billing_id: props.billingId
        } as BillingUpgradeRequest;

        upgrade(props.tenantId, request).then(() => {
            setSubmitting(false);
            window.location.reload();
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    const onShowPlanPicker = () => {
        let index = props.plans.findIndex(plan => plan.id === props.plan.id);
        if (index >= 0) {
            setIndex(index);
        } else {
            setIndex(undefined);
        }
        setShowPlans(true);
    };

    const onCancelPlanPicker = () => {
        setShowPlans(false);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <img alt={props.plan.name} src={props.plan.image_url} className={classes.cover} />
            </Grid>
            {props.upgradable && (
                <Grid item xs={12}>
                    <Button variant="text" size="small" color="primary" onClick={onShowPlanPicker}>
                        {translate("buttons.change_plan")}
                    </Button>
                </Grid>
            )}
            {showPlans && (
                <PlanPickerModal open={showPlans} 
                    plans={props.plans} 
                    onClose={onCancelPlanPicker} 
                    onPickedPlan={onSelectedPlan}
                    startAt={index} />
            )}
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={submitting} />
        </Grid>
    );
}

interface PlanPickerModalProps extends PlanPickerProps {
    open: boolean;
}

export function PlanPickerModal(props: PlanPickerModalProps) {
    return (
        <CustomBackdrop open={props.open}>
            <Grid container justify="center">
                <Grid item xs="auto">
                    <PlanPicker {...props} />
                </Grid>
            </Grid>
        </CustomBackdrop>
    );
}