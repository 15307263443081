import React, { useState, useContext, useEffect } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { Button, Grid, Divider, IconButton, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import Gridable from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import ListAltIcon from '@material-ui/icons/ListAlt';
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import { RouterParams } from "../router/RouterParams";
import { listRequisitionTypeCatalog, activeRequisitionType } from "../api/RequisitionTypeCatalogAPI";
import { RequisitionsTypeCatalog, RequisitionTypeCatalogListParams, RequisitionTypeCatalog } from "../model/RequisitionTypeCatalog";
import RequisitionTypeMenu from "./RequisitionTypeMenu";

export default function RequisitionsTypesView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const paramsFromQueryString = (): RequisitionTypeCatalogListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as RequisitionTypeCatalogListParams;
    };
    const [params, setParams] = useState<RequisitionTypeCatalogListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<RequisitionTypeCatalogListParams>(paramsFromQueryString);
    const [redirect, setRedirect] = useState<string>();
    const [dataTypes, setDataTypes] = useState<RequisitionsTypeCatalog>();
    const [requisitionType, setRequisitionType] = useState<RequisitionTypeCatalog>();
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const load = () => {
        setStatus("loading");
        let offset = getOffset(page, pageSize);
        history.push(window.location.pathname);
        if (match.params.success && isSuccess) {
            setSuccess(match.params.success);
            setIsSuccess(false);
        }
        listRequisitionTypeCatalog(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setDataTypes(response);
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });

    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setDataTypes(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setDataTypes(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (type: RequisitionTypeCatalog) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setGridAnchorEl(event.currentTarget);
        setRequisitionType(type);
    };

    const onCloseOption = () => {
        setGridAnchorEl(null);
        setRequisitionType(undefined);
    }
    const showDeleteDialog = () => {
        if (!requisitionType) return;
        setGridAnchorEl(null);
        setOpenConfirmDialog(true);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDialog(false);
    };

    const onConfirmDelete = () => {
        setOpenConfirmDialog(false);
        setStatus("loading");
        if (!requisitionType) return;

        activeRequisitionType(context.session!.tenant!.id, requisitionType.id).then((response) => {
            setSuccess(translate(`requisitions.types.${response.active ? "activate" : "desactivate"}.success`, { "name": requisitionType.name }) as string);
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
            load();
        });
    };

    const onClickedRow = (type: RequisitionTypeCatalog) => {
        setRedirect(`/requisitions/types/${type.id}/edit`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const getStatus = (statusType: boolean) => {
        if (statusType) {
            return translate("requisitions.types.columns.active") as string;
        } else {
            return translate("requisitions.types.columns.inactive") as string;
        }

    }
    return (
        <Pagination title={translate("requisitions.types.title") as string} icon={<ListAltIcon />}
            page={page} pageSize={pageSize} count={dataTypes ? dataTypes.items.length : 0} total={dataTypes ? dataTypes.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("RequisitionAdminCreate") ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Link to="/requisitions/types/new">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                <AddIcon />
                            </Fab>
                        </Link>
                    </Grid>
                </Grid>) : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm={true} md={true} lg>
                        <ValidatedInput type="text" id="search" name="search" label={translate("providers.filter_search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={dataTypes ? dataTypes.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("providers.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("requisitions.types.columns.description") as string,
                        converter: (type) => type.name,
                        xs: false,
                        sm: true,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("workflows.single") as string,
                        converter: (type) => type.name_workflow || "---",
                        xs: true,
                        sm: true,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("requisitions.types.columns.default_team") as string,
                        converter: (type) => type.name_default_team || "---",
                        xs: false,
                        sm: false,
                        md: 3,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("requisitions.types.columns.purchase_order_mode") as string,
                        converter: (type) => translate(`requisitions.${type.purchase_order_mode.toString().toLocaleLowerCase()}`) as string,
                        xs: false,
                        sm: false,
                        md: 3,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("requisitions.types.columns.status") as string,
                        converter: (type) => getStatus(type.active),
                        xs: true,
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                        ),
                        converter: (type) => (
                            <IconButton aria-label="options"
                                color="default"
                                size="small"
                                onClick={onClickedOptions(type)} >
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: true
                    }
                ]} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {gridAnchorEl && requisitionType && (
                <RequisitionTypeMenu
                    anchor={gridAnchorEl}
                    requisitionType={requisitionType}
                    onClose={onCloseOption}
                    onDelete={showDeleteDialog}
                />
            )}
            {openConfirmDialog && requisitionType && (
                <CfdisConfirmPopup
                    doAction={onConfirmDelete}
                    onClose={closeConfirmDelete}
                    title={translate(`requisitions.types.${requisitionType.active ? "desactivate" : "activate"}.title`) as string}
                    message={translate(`requisitions.types.${requisitionType.active ? "desactivate" : "activate"}.message`, { "type": requisitionType.name }) as string}
                    button={translate("buttons.accept") as string} />
            )}

        </Pagination>
    )

}