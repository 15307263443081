import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, Chip, Tabs, Tab, Fab } from "@material-ui/core";
import PurchaseOrdersIcon from "@material-ui/icons/ShopTwo";
import RefuseIcon from "@material-ui/icons/ThumbDown";
import AcceptIcon from "@material-ui/icons/ThumbUp";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Surface from "../components/Surface";
import Progress from "../components/Progress";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import translate from "../i18n/Translator";
import queryString from "query-string";

import { getPurchaseOrder } from "../api/PurchaseOrderAPI";
import { getPurchaseOrder as getProviderPurchaseOrder, getPurchaseOrderPdfUrl } from "../api/ProviderPurchaseOrderAPI";
import { PurchaseOrder, PartialityRecord } from "../model/PurchaseOrder";
import DetailsTab from "./DetailsTab";
import WarehouseDeliveriesTab from "./WarehouseDeliveriesTab"
import RelatedCfdisTab from "./RelatedCfdisTab";
import PurchaseOrderAcceptPopup from "./PurchaseOrderAcceptPopup";
import PurchaseOrderRefusePopup from "./PurchaseOrderRefusePopup";
import { Requisition } from "../model/Requisition";
import RequisitionQuotation from "../Requisitions/RequisitionQuotation";
import { Payment } from "../model/Payment";
import DownloadIcon from '@material-ui/icons/GetApp';

interface PurchaseOrderTab {
    name: string;
    title: string;
    actions?: string[];
}

export default function PurchaseOrderView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const providerId = context.session!.provider?.id;
    const qs = queryString.parse(window.location.search);
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            opacity: 1,
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));
    const classes = useStyles();

    const [status, setStatus] = useState<string>("loading");
    const [purchaseOrder, setPurchasOrder] = useState<PurchaseOrder>();
    const [requisition, setRequisition] = useState<Requisition>();
    const [partiallityRecords, setPartiallityRecords] = useState<PartialityRecord[]>();
    const [purchasePayments, setPurchasePayments] = useState<Payment[]>();

    const [dialog, setDialog] = useState<"accept" | "refuse">();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();

    const allTabs = [
        {
            name: "details",
            title: translate("requisitions.projects.details.details_title" as string)
        },
        {
            name: "quotation",
            title: translate("requisitions.form.quotation.title" as string)
        },
        {
            name: "warehouse_deliveries",
            title: translate("purchase_orders.warehouse_deliveries.title" as string)
        },
        {
            name: "related_cfdis",
            title: translate("cfdis.related_cfdis.title" as string)
        }
    ] as PurchaseOrderTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };


    const [tabs, setTabs] = useState<PurchaseOrderTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);

    const getPromise = (): Promise<PurchaseOrder> => {
        if (providerId) {
            return getProviderPurchaseOrder(context.session!.tenant!.id, providerId, match.params.purchaseOrderId, 20, 0);
        }
        return getPurchaseOrder(context.session!.tenant!.id, match.params.purchaseOrderId, 20, 0);
    };

    useEffect(() => {
        setStatus("loading");
        let tabs = [];
        getPromise().then((purchaseOrder) => {
            setPurchasOrder(purchaseOrder);
            if (purchaseOrder && purchaseOrder.requisition) {
                setRequisition(purchaseOrder.requisition);
                tabs = purchaseOrder.requisition.quotation ? allTabs : allTabs.filter(tab => "quotation" !== tab.name);
                fillRecords(purchaseOrder);
            } else {
                tabs = allTabs.filter(tab => "quotation" !== tab.name);
            }
            if (!purchaseOrder.cfdis) {
                tabs = tabs.filter(tab => "related_cfdis" !== tab.name);
            }
            if (!purchaseOrder.show_warehouse_deliveries_in_purchase_orders) {
                tabs = tabs.filter(tab => "warehouse_deliveries" !== tab.name);
            }
            if (purchaseOrder.pdf_s3_key_file) {
                getPurchaseOrderPdfUrl(context.session!.tenant!.id, buildProviderId(purchaseOrder), purchaseOrder.id).then((urlFile) => {
                    setPdfUrl(urlFile);
                }).catch((error) => {
                    setPdfUrl(undefined);
                });
            }

            setTabs(tabs);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, [match.params.purchaseOrderId]);

    const fillRecords = (purchaseOrder: PurchaseOrder) => {
        let records = purchaseOrder.partialities_record;
        if (records && purchaseOrder.cfdis && purchaseOrder.cfdis.length > 0) {
            records.forEach(record => {
                record.cfdi_related = purchaseOrder.cfdis?.find(cfdi => record.cfdi_id === cfdi.id);
            });
        }
        setPartiallityRecords(records);

        setPurchasePayments(purchaseOrder.purchase_payments);
    };

    const buildProviderId = (purchaseOrder: PurchaseOrder): string => {
        if (purchaseOrder.provider!) {
            return purchaseOrder.provider!.id;
        }
        if (context.session!.provider!) {
            return context.session!.provider!.id;
        }
        return "";
    };

    const onAccept = () => {
        setDialog("accept");
    };

    const onRefuse = () => {
        setDialog("refuse");
    };

    const closeDialog = () => {
        setDialog(undefined);
    };

    const onAccepted = (response: PurchaseOrder) => {
        if (!purchaseOrder) return;
        setPurchasOrder(response);
        closeDialog();
        setSuccess(translate("purchase_orders.accept.success") as string);
    };

    const onRefused = (response: PurchaseOrder) => {
        setPurchasOrder(response);
        closeDialog();
        setSuccess(translate("purchase_orders.refuse.success") as string);
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line
    }, [qs.tab, tabs]);

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !purchaseOrder) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const tabContent = (index: number) => {
        if (!purchaseOrder) return;

        if (!context.session || !context.session.tenant) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return (
                    <DetailsTab tenantId={context.session.tenant.id} purchaseOrder={purchaseOrder} />
                );
            case "quotation":
                let invoicePurchaseOrderId = "ONE_INVOICE_REQUISITION" === requisition?.partial_billing_type && purchaseOrder.cfdis?.length === 1 ? purchaseOrder.cfdis[0].id : undefined;
                return requisition ? <RequisitionQuotation requisition={requisition} showCfdis={true} partiallityRecords={partiallityRecords} purchasePayments={purchasePayments} cfdiPurchaseOrderId={invoicePurchaseOrderId} /> : <div></div>;
            case "related_cfdis":
                return (
                    <RelatedCfdisTab purchaseOrder={purchaseOrder} />
                );
            case "warehouse_deliveries":
                return (
                    <WarehouseDeliveriesTab purchaseOrder={purchaseOrder} tenantId={context.session.tenant.id} />
                );
            default:
                return <div></div>;
        }
    };

    const chipStatus = () => {
        return <Grid className={classes.root}>
            <Typography variant="body1" component="h6">
                {`${purchaseOrder.external_id} - Num: ${(purchaseOrder.document_number || "---")}`}
            </Typography>
            <Chip color={"secondary"} label={translate(`purchase_orders.status.${purchaseOrder.status}`) as string} disabled variant="outlined" size="small" />
        </Grid>
    }

    const onDownloadPdf = () => {
    }

    const showPdfDownloadBtn = (purchaseOrder: PurchaseOrder): Boolean => {
        return !!purchaseOrder?.pdf_s3_key_file;
    }

    return (
        <Surface title={translate("purchase_orders.single")} subtitle={chipStatus()} icon={<PurchaseOrdersIcon />} className="FormSurface" backButton
            titleActions={<Grid >
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {purchaseOrder.acceptance_status ? undefined : context.isGranted("ProviderPurchaseOrdersUpdate") ? (
                        <Grid container alignItems="center" justify="flex-end" spacing={1}>
                            <Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("buttons.accept") as string} onClick={onAccept}>
                                    <AcceptIcon />
                                </Fab>
                            </Grid>
                            <Grid item xs="auto">
                                <Fab color="secondary" size="small" title={translate("buttons.reject") as string} onClick={onRefuse}>
                                    <RefuseIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                    ) : undefined}
                    {showPdfDownloadBtn(purchaseOrder) ? (
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" title={translate("purchase_orders.download_pdf") as string} onClick={onDownloadPdf} component="a" href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                <DownloadIcon />
                            </Fab>
                        </Grid>
                    ) : undefined}
                </Grid>
            </Grid>}>
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: PurchaseOrderTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: PurchaseOrderTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            {dialog === "accept" && (
                <PurchaseOrderAcceptPopup
                    purchaseOrder={purchaseOrder}
                    onClose={closeDialog}
                    onAccepted={onAccepted}
                    onError={setError} />
            )}
            {dialog === "refuse" && (
                <PurchaseOrderRefusePopup
                    purchaseOrder={purchaseOrder}
                    onClose={closeDialog}
                    onRefused={onRefused}
                    onError={setError} />
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </Surface>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`cfdi-tabpanel-${index}`}
            aria-labelledby={`cfdi-tab-${index}`}
            {...other}
        >
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        component: "a",
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
        },
        id: `cfdi-tab-${index}`,
        'aria-controls': `cfdi-tabpanel-${index}`,
    };
}
