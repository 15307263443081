import React from "react";
import translate from "../../i18n/Translator";
import { Grid, Box, Button, Card} from "@material-ui/core";
import { DownloadPdfIcon } from "../../components/Icons";

export interface DownloadPdfsParams {
    contractUrl: string;
    privacyUrl: string;
    handleDone(): any;
}

export default function DownloadPdfs(params: DownloadPdfsParams){
    return <>
        <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <Grid item>
                <Box pb={3}>
                    <Card variant="outlined">
                        <Box py={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center" alignContent="center" alignItems="center">
                                        <Grid item>
                                            <Button 
                                                component="a"
                                                href={params.contractUrl}
                                                target="_blank"
                                                rel="noopener noreferrer" variant="text" color="primary"
                                                startIcon={<DownloadPdfIcon/>}>
                                                {translate("tax_stamp.sign_manifest.download_contract")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center" alignContent="center" alignItems="center">
                                        <Grid item >
                                            <Button component="a"
                                                    href={params.privacyUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer" variant="text" color="primary" 
                                                    startIcon={<DownloadPdfIcon/>}>
                                                {translate("tax_stamp.sign_manifest.download_privacy")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Box>
            </Grid>
        </Grid>
        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
            <Box pb={2}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={params.handleDone} >
                            {translate("buttons.done") as string}
                        </Button>
                </Grid>
            </Box>
        </Grid>
    </>;
}