import { callAPI, HTTPMethod } from "./API";
import { EfoFiles, EfoFile } from "../model/EfoFile";

export async function getEfoFiles(page: number, offset: number): Promise<EfoFiles> {
    return await callAPI(`/efo-files`, {
        method: HTTPMethod.GET,
        query: {
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function updateEfos(): Promise<EfoFile> {
    return await callAPI(`/efo-files`, {
        method: HTTPMethod.PUT,
    });
}
