import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import ServerIcon from "@material-ui/icons/Dns";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { TenantConfigurationErpRequest, PLUGINS, ERP } from "../model/TenantConfiguration";
import { updateErp } from "../api/TenantConfigurationApi";
import { KeyPairs } from "../model/KeyPair";
import SimpleSwitch from "../components/SimpleSwitch";
import CustomBackdrop from "../components/CustomBackdrop";
import { CfdiMetadataType, CFDI_METADATA_TYPES } from "../model/Cfdi";
import DialogPopup from "../components/DialogPopup";

interface TenantConfigurationSendPluginFormProps {
    tenantId: string;
    keyPairs: KeyPairs;
    erp?: ERP;
}

export default function TenantConfigurationSendPluginForm(props: TenantConfigurationSendPluginFormProps) {
    const pluginLabels = PLUGINS.map(el => translate(`sap.settings.plugins.${el}`) as string);

    const [submitting, setSubmitting] = useState(false);
    const [request, setRequest] = useState<TenantConfigurationErpRequest>({
        erp_plugin: "NONE",
        url: "",
        key_pair_id: "",
        username: "",
        company: "",
        password: "",
        update_password: false,
        bucket: "",
        region: "",
        lambda_name: "",
        lambda_alias: "",
        remote_dir_pattern: "",
        favorite_date_format: ""
    } as TenantConfigurationErpRequest);
    const [validations, setValidations] = useState<any>({} as any);
    const [keyPairIds, setKeyPairIds] = useState<string[]>([]);
    const [keyPairLabels, setKeyPairLabels] = useState<string[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [availableTypes, setAvailableTypes] = useState<CfdiMetadataType[]>([]);
    const [type, setType] = useState<CfdiMetadataType>();
    const [value, setValue] = useState<string>();
    const [showTypePopup, setShowTypePopup] = useState(false);

    useEffect(() => {
        const erp = props.erp;
        const keyPairs = props.keyPairs;

        setRequest({
            erp_plugin: erp?.erp_plugin,
            url: erp?.url || "",
            key_pair_id: erp?.key_pair_id || "",
            username: erp?.username || "",
            company: erp?.company || "",
            password: erp?.has_password ? "-_-_-_-_" : undefined,
            update_password: !erp?.has_password || false,
            bucket: erp?.bucket || "",
            region: erp?.region || "",
            lambda_name: erp?.lambda_name || "",
            lambda_alias: erp?.lambda_alias || "",
            remote_dir_pattern: erp?.remote_dir_pattern || "",
            favorite_date_format: erp?.favorite_date_format,
            remote_dir_by_type: erp?.remote_dir_by_type || (erp?.erp_plugin === "SFTP" || erp?.erp_plugin === "S3" ? {} as Map<CfdiMetadataType, string> : undefined),
        });

        setKeyPairIds(keyPairs.items.map(kp => kp.id));
        setKeyPairLabels(keyPairs.items.map(kp => kp.name));
    }, [props.tenantId, props.keyPairs, props.erp]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedOption = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: !value || value === "---" ? undefined : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedSwitch = (name: string, checked: boolean) => {
        if (checked) {
            setRequest({ ...request, [name]: checked, password: "" });
        } else {
            setRequest({ ...request, [name]: checked });
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) return;

        setSubmitting(true);
        updateErp(props.tenantId, request).then((response) => {
            setSuccess(translate("sap.settings.updated") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onOpenType = (type?: CfdiMetadataType, value?: string) => () => {
        if (request.remote_dir_by_type) {
            const used = Object.keys(request.remote_dir_by_type) as CfdiMetadataType[];
            const available = CFDI_METADATA_TYPES.filter(el => used.indexOf(el) < 0 || el === type);
            setAvailableTypes(available);
        } else {
            setAvailableTypes(CFDI_METADATA_TYPES);
        }
        setType(type);
        setValue(value);
        setShowTypePopup(true);
    };

    const onCloseType = () => {
        setType(undefined);
        setValue(undefined);
        setShowTypePopup(false);
    };

    const onDeleteType = () => {
        if (!type) return;

        const dict = request.remote_dir_by_type ? request.remote_dir_by_type as any : {};
        delete dict[type];

        setRequest({ ...request, remote_dir_by_type: dict as Map<CfdiMetadataType, string> });
        onCloseType();
    };

    const onUpdateType = (type: CfdiMetadataType, value: string) => {
        const dict = request.remote_dir_by_type ? request.remote_dir_by_type as any : {};
        dict[type] = value;

        setRequest({ ...request, remote_dir_by_type: dict as Map<CfdiMetadataType, string> });
        onCloseType();
    };

    return (
        <Surface title={translate("sap.settings.server")} icon={<ServerIcon />}>
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="erp_plugin" name="erp_plugin"
                            value={request.erp_plugin} label={translate("sap.settings.erp_plugin") as string}
                            options={PLUGINS} optionLabels={pluginLabels}
                            required disabled={submitting}
                            margin="dense"
                            onValueChanged={hasChangedOption} />
                        <Hidden xsUp={!request.erp_plugin || request.erp_plugin === "LAMBDA"} implementation="css">
                            <ValidatedInput type="text" id="url" name="url"
                                value={request.url} label={translate(request.erp_plugin === "S3" ? "sap.settings.endpoint" : "sap.settings.url") as string}
                                required={request.erp_plugin !== "LAMBDA"} disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged} />
                        </Hidden>

                        <Hidden xsUp={request.erp_plugin !== "S3"} implementation="css">
                            <ValidatedInput type="text" id="bucket" name="bucket"
                                value={request.bucket} label={translate("sap.settings.bucket") as string}
                                required={request.erp_plugin === "S3"} disabled={submitting} margin="dense"
                                onValueChanged={hasChanged} />
                        </Hidden>
                        <Hidden xsUp={request.erp_plugin !== "S3"} implementation="css">
                            <ValidatedInput type="text" id="region" name="region"
                                value={request.region} label={translate("sap.settings.region") as string}
                                required={request.erp_plugin === "S3"} disabled={submitting} margin="dense"
                                onValueChanged={hasChanged} />
                        </Hidden>
                        <Hidden xsUp={request.erp_plugin !== "LAMBDA"} implementation="css">
                            <ValidatedInput type="text" id="lambda_name" name="lambda_name"
                                value={request.lambda_name} label={translate("sap.settings.lambda_name") as string}
                                required={request.erp_plugin === "LAMBDA"} disabled={submitting} margin="dense"
                                onValueChanged={hasChanged} />
                            <ValidatedInput type="text" id="lambda_alias" name="lambda_alias"
                                value={request.lambda_alias} label={translate("sap.settings.lambda_alias") as string}
                                required={request.erp_plugin === "LAMBDA"} disabled={submitting} margin="dense"
                                onValueChanged={hasChanged} />
                        </Hidden>
                        <Hidden xsUp={request.erp_plugin !== "SFTP" && request.erp_plugin !== "S3"} implementation="css">
                            <ValidatedInput type="text" id="remote_dir_pattern" name="remote_dir_pattern"
                                value={request.remote_dir_pattern} label={translate("sap.settings.remote_dir_pattern") as string}
                                disabled={submitting} margin="dense"
                                onValueChanged={hasChanged} />
                            <ValidatedInput type="text" id="favorite_date_format" name="favorite_date_format"
                                value={request.favorite_date_format} label={translate("sap.settings.favorite_date_format") as string}
                                disabled={submitting} margin="dense"
                                onValueChanged={hasChanged} />
                            {request.remote_dir_by_type && (
                                <Grid container>
                                    <Grid item xs>
                                        <Box pt={2}>
                                            <Typography variant="subtitle2">
                                                {translate("sap.settings.remote_dir_by_type")}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Box pt={2}>
                                            <IconButton color="secondary" size="small" onClick={onOpenType()}>
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pb={1}>
                                            <Divider />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {Object.keys(request.remote_dir_by_type).map(el => (
                                            <Grid container key={el}>
                                                <Grid item xs>
                                                    {el}
                                                </Grid>
                                                <Grid item xs>
                                                    {(request.remote_dir_by_type as any)[el]}
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <IconButton color="default" size="small" onClick={onOpenType(el as CfdiMetadataType, (request.remote_dir_by_type as any)[el])}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box pb={1}>
                                                        <Divider />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        {Object.keys(request.remote_dir_by_type).length === 0 && (
                                            <Typography variant="caption" color="textSecondary">
                                                {translate("sap.settings.cfdi_type.empty")}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Hidden>
                    </Grid>
                    <Box pt={2}>
                        <Hidden xsUp={!request.erp_plugin || request.erp_plugin === "LAMBDA"} implementation="css">
                            <Typography variant="subtitle2">
                                {translate("sap.settings.auth")}
                            </Typography>
                        </Hidden>
                    </Box>
                    <Grid item xs={12}>
                        <Hidden xsUp={request.erp_plugin !== "DAAR"} implementation="css">
                            <ValidatedInput type="text" id="key_pair_id" name="key_pair_id"
                                value={request.key_pair_id} label={translate("sap.settings.key_pair") as string}
                                options={keyPairIds} optionLabels={keyPairLabels}
                                required={request.erp_plugin === "DAAR"} disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChangedOption} />
                        </Hidden>
                        <Hidden xsUp={request.erp_plugin !== "SAP"} implementation="css">
                            <ValidatedInput type="text" id="company" name="company"
                                value={request.company} label={translate("sap.settings.company") as string}
                                required={request.erp_plugin === "SAP"} disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged} />
                        </Hidden>
                        <Hidden xsUp={!request.erp_plugin || request.erp_plugin === "DAAR" || request.erp_plugin === "LAMBDA"} implementation="css">
                            <ValidatedInput type="text" id="username" name="username"
                                value={request.username} label={translate("sap.settings.username") as string}
                                required={request.erp_plugin === "SAP"} disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged} />
                            <ValidatedInput type="password" id="password" name="password"
                                value={request.password} label={translate("sap.settings.password") as string}
                                required={request.erp_plugin === "SAP" && request.update_password} disabled={!request.update_password || submitting}
                                margin="dense"
                                onValueChanged={hasChanged} disableDefaultValidator />
                            <SimpleSwitch value="update_password"
                                label={translate("sap.settings.update_password") as string}
                                checked={request.update_password}
                                disabled={submitting}
                                onChanged={hasChangedSwitch}
                                placement="end" />
                        </Hidden>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Box pt={2}>
                            <Grid container justify="flex-end" spacing={1}>
                                <Grid item xs={12} md="auto">
                                    <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                        {translate("buttons.update")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            {showTypePopup && (
                <CfdiTypeValueDialog type={type} value={value}
                    options={availableTypes}
                    onUpdateType={onUpdateType}
                    onDeleteType={onDeleteType}
                    onCloseType={onCloseType} />
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <CustomBackdrop open={submitting} />
        </Surface>
    );

}

interface CfdiTypeValueDialogProps {
    type?: CfdiMetadataType;
    value?: string;
    options: CfdiMetadataType[];
    onUpdateType(type: CfdiMetadataType, value: string): any;
    onDeleteType(): any;
    onCloseType(): any;
}

interface Request {
    type?: CfdiMetadataType;
    value?: string;
}

function CfdiTypeValueDialog(props: CfdiTypeValueDialogProps) {
    const [validations, setValidations] = useState<any>({} as any);
    const [request, setRequest] = useState({
        type: props.type,
        value: props.value,
    } as Request);

    const hasChangedOption = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: !value || value === "---" ? undefined : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = () => {
        if (!isValid(validations)) return;
        props.onUpdateType(request.type!, request.value!);
    };


    return (
        <DialogPopup onClose={props.onCloseType}
            title={translate("sap.settings.cfdi_type.title")}
            button={(
                <Button variant="contained" color="primary" size="medium" onClick={onSubmit}>
                    {translate("buttons.update")}
                </Button>
            )}
            buttonLeft={(
                <Button variant="outlined" color="secondary" size="medium" onClick={props.onDeleteType}>
                    {translate("buttons.delete")}
                </Button>
            )} maxWidth="md" open>
            <ValidatedInput type="text" id="type" name="type"
                value={request.type} margin="dense"
                label={translate("sap.settings.cfdi_type.type") as string}
                options={props.options} disabled={!!props.type}
                required
                onValueChanged={hasChangedOption} />
            <ValidatedInput type="text" id="value" name="value"
                value={request.value} margin="dense"
                label={translate("sap.settings.cfdi_type.value") as string}
                required
                onValueChanged={hasChanged} />
        </DialogPopup>
    );

}