import React, { useState, useEffect, useContext } from "react";
import CreateUpdatePaymentOrderView from "./CreateUpdateProjectView";
import ProjectOverview from "./ProjectOverview";
import { RouterParams } from "../router/RouterParams";
import { Redirect } from "react-router-dom";
import Progress from "../components/Progress";
import { Typography } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import { getProject, finalize } from "../api/ProjectAPI";
import { Team, TeamListParams } from "../model/Team";
import { Project } from "../model/Project";
import { list as listTeams } from "../api/TeamAPI";
import { listRequisitionTypeCatalog } from "../api/RequisitionTypeCatalogAPI";
import { RequisitionTypeCatalog, RequisitionTypeCatalogListParams } from "../model/RequisitionTypeCatalog";

export default function ProjectDetails({ match }: RouterParams) {
    const [view, setView] = useState<string>("details");
    const [project, setProject] = useState<Project>();
    const [projectView, setProjectView] = useState<"create" | "update">("create");
    const [redirect, setRedirect] = useState<string | undefined>();
    const [status, setStatus] = useState<string>("loading");
    const context = useContext(AppContext);
    const [teams, setTeams] = useState<Team[]>([]);
    const [requisitionTypes, setRequisitionTypes] = useState<RequisitionTypeCatalog[]>([]);
    const [isBudgetEdit, setIsBudgetEdit] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    const history = useHistory();
    let href = window.location.pathname;

    const load = () => {

        if (match.params.projectId) {
            getProject(context.session!.tenant!.id, match.params.projectId)
            .then((response) => {
                setProject(response);
                fillData(response);
                if ("IN_PROGRESS" === response.status) {
                    setIsBudgetEdit(true);
                }
                if (href.includes("edit")) {
                    setView("edit");
                }
                setProjectView("update");
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });

        } else {
            fillData();
            setView("create");
            setProjectView("create");
            setStatus("loaded");
        }
    };

    useEffect(load, [match.params]);

    const onEditProject = (project: Project) => {
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/projects/edit/${project.id}`);
    }

    const onCancel = () => {
        history.goBack();
    }

    const fillData = (project?: Project) => {
        if (href.includes("detail") && project) {
            let teamsIncluded = [] as string[];
            project.requisition_configurations.forEach((configuration) => {
                teamsIncluded.push(configuration.team_id);
            });
            listTeams(context.session!.tenant!.id, 0, 0, {
                search: "",
                ids: teamsIncluded.join(","),
            } as TeamListParams).then((response) => {
                setTeams(response.items);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            listTeams(context.session!.tenant!.id, 0, 0, {
                search: ""
            } as TeamListParams).then((response) => {
                setTeams(response.items);
                listRequisitionTypeCatalog(context.session!.tenant!.id, 0, 0, {
                    search: ""
                } as RequisitionTypeCatalogListParams).then((response) => {
                    setRequisitionTypes(response.items);
                }).catch((error) => {
                    setStatus(error.message);
                });
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }
    }

    const onSuccessSaveProject = (project: Project, message?: string) => {
        if ("DRAFT" === project.status && message) {
            setRedirect(`/projects/detail/${project.id}`);
        } else {
            history.goBack();
        }
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || (view !== "create" && !project)) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    switch (view) {
        case "create":
            return (<CreateUpdatePaymentOrderView next={onSuccessSaveProject} onCancel={onCancel} onEdit={onEditProject} view={projectView} teams={teams} requisitionTypes={requisitionTypes} isBudgetEdit={isBudgetEdit} />);
        case "edit":
            return (<CreateUpdatePaymentOrderView next={onSuccessSaveProject} onCancel={onCancel} onEdit={onEditProject} project={project} view={projectView} teams={teams} requisitionTypes={requisitionTypes} isBudgetEdit={isBudgetEdit} />);
        case "details":
            return (<ProjectOverview project={project} teams={teams} onClose={onCancel} onEditProject={onEditProject} />);
        default:
            return (<ProjectOverview project={project} teams={teams} onClose={onCancel} onEditProject={onEditProject} />);
    }

}