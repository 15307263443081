import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import queryString from "query-string";

import translate from "../i18n/Translator";
import { updateEmail, revertEmail } from "../api/MeAPI";
import { User, EmailRequest } from "../model/User";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import SimpleSwitch from "../components/SimpleSwitch";

export interface UserEmailFormProps {
    user: User;
    onUpdated(user: User): any;
}

export default function UserEmailForm(props: UserEmailFormProps) {
    const qs = queryString.parse(window.location.search);
    const margin = "dense";
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>(() => {
        if (typeof qs["email_error"] === "string") {
            return qs["email_error"] as string;
        }
        return "";
    });
    const [success, setSuccess] = useState<string>(() => {
        if (typeof qs["email"] === "string") {
            let code = qs["email"] as string;
            if (code === "success") {
                return translate("auth.verify_email.success") as string;
            }
        }
        return "";
    });
    const [update, setUpdate] = useState<boolean>(false);
    const [request, setRequest] = useState<EmailRequest>({
        "email": ""
    } as EmailRequest);
    const [validations, setValidations] = useState({
        "email": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        setUpdate(!!props.user.new_email);
        setRequest({
            "email": props.user.new_email ?? ""
        } as EmailRequest);
    }, [props.user]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangeSwitch = (_name: string, checked: boolean) => {
        setUpdate(checked);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        updateEmail(request).then((user) => {
            initForm(user);
            setSuccess(translate("users.profile.updating_email", { "email": request.email }) as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const onCancel = () => {
        setSubmitting(true);
        revertEmail().then((user) => {
            initForm(user);
            setSuccess(translate("users.profile.reverted_email", { "email": user!.email }) as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const initForm = (user: User) => {
        if (user && user.new_email) {
            setRequest({
                "email": user.new_email!
            } as EmailRequest);
            setUpdate(true);
        } else {
            setRequest({
                "email": ""
            } as EmailRequest);
            setUpdate(false);
        }

        props.onUpdated(user);
    };

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit} >
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput type="email" id="old_email" name="old_email"
                        label={translate("users.old_email") as string} value={props.user.email}
                        disabled={true} required
                        margin={margin} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="flex-end">
                        <SimpleSwitch value="update" label={translate("users.profile.change_email") as string}
                            checked={update} onChanged={hasChangeSwitch} disabled={!!props.user.new_email} />
                    </Grid>
                </Grid>
                {update &&
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ValidatedInput type="email" id="email" name="email"
                                    label={translate("users.new_email") as string} value={request.email}
                                    onValueChanged={hasChanged} disabled={(props.user.new_email! === request.email) || submitting} required
                                    margin={margin} />
                            </Grid>
                            <Grid item xs={12} className="ExternButton">
                                <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={(props.user.new_email! === request.email) || submitting}>
                                    {translate("buttons.update")}
                                </Button>
                            </Grid>
                            {(props.user.new_email! === request.email) &&
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="primary" fullWidth size="large" disabled={submitting} onClick={onCancel}>
                                        {translate("buttons.cancel")}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </form>
    );
}