import React, { useState } from "react";
import DialogPopup from "../../components/DialogPopup";
import { DocumentLine, CfdiRelated } from "../../model/Document";
import translate from "../../i18n/Translator";
import { ConceptoTab, Concepto } from "../../model/Cfdi";
import { Typography, Tabs, Tab, Grid, Box, Tooltip, Divider, Button } from "@material-ui/core";
import TabPanel, { a11yProps } from "../../components/TabsGenerator";
import InvoicesTab from "./InvoicesTab";
import RelatedTab from "./RelatedTab";
import NumberFormat from "react-number-format";
import { ErrorIcon } from "../../components/Icons";

interface RelateConceptsModalProps {
    line: DocumentLine;
    concepts: ConceptoTab[];
    currency: string;
    selectedConceptsIndex: string[];
    cfdiId: string;
    validateAmountsType: string;
    tolerance: number;
    onSave(selectedConcepts: string[]): any;
    onClose(): any;
}

interface CfdiTab {
    name: string;
    title: string;
    actions?: string[];
}

export const getOutOfTolerance = (differenceAmounts: number, tolerance: number, validateAmountsType: string) => {
    switch (validateAmountsType){
        case "ONE":
            return differenceAmounts > tolerance || differenceAmounts < 0 - tolerance;
        case "MULTIPLE":
            return differenceAmounts < 0 - tolerance;
        case "NONE":
        default:
            return false;
    }
};

export default function RelateConceptsModal(props: RelateConceptsModalProps){
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const getCfdis = () => {
        let cfdis = props.line.cfdis ? props.line.cfdis.filter(cfdi => cfdi.id !== props.cfdiId) : [];
        if(cfdis.length > 0){
            return cfdis;
        }
        return [];
    };

    const [value, setValue] = useState<number>(0);

    const cfdis = getCfdis();

    const [assignedOtherInvoices] = useState<number>(cfdis.length > 0 ? cfdis.flatMap(cfdi => cfdi.concepts).map(concept => concept.discount ? concept.total_amount - concept.discount : concept.total_amount).reduce((a,b) => a+b) : 0);
    let selectedConcepts = props.concepts.filter(concepto => props.selectedConceptsIndex.find(selected => selected === concepto.id));
    const [sumConceptsSelected, setSumConceptsSelected] = useState<number>(selectedConcepts.length > 0 ? selectedConcepts.map(concepto => concepto.descuento ? concepto.importe - concepto.descuento : concepto.importe).reduce((a,b) => a+b) : 0);
    const [sumConcepts, setSumConcepts] = useState<number>(assignedOtherInvoices+sumConceptsSelected);
    const [differenceAmounts, setDifferenceAmounts] = useState<number>(props.line.line_total - sumConcepts);
    const [outOfTolerance, setOutOfTolerance] = useState<boolean>(getOutOfTolerance(props.line.line_total - sumConcepts, props.tolerance, props.validateAmountsType));

    const [selectedConceptsIndex, setSelectedConcepts] = useState<string[]>(props.selectedConceptsIndex);
    const [tabs] = useState<CfdiTab[]>([
        {
            name: "invoices",
            title: translate("cfdi_relate_po.modal_concepts.tab_current_invoice")
        },
        {
            name: "related",
            title: translate("cfdi_relate_po.modal_concepts.tab_other_invoices", { 
                "amount": <NumberFormat value={assignedOtherInvoices}
                            prefix="$ " decimalScale={2} fixedDecimalScale={true}
                            thousandSeparator="," displayType="text" suffix={" "+props.currency}
                            />})
        }
    ] as CfdiTab[]);

    const onCheckConcept = (selected: string[]) => {
        setSelectedConcepts(selected);
        let selectedConcepts = props.concepts.filter(concepto => selected.find(selected => selected === concepto.id));
        let sumConceptsSelected = selectedConcepts.length > 0 ? selectedConcepts.map(concepto => concepto.descuento ? concepto.importe - concepto.descuento : concepto.importe).reduce((a,b) => a+b) : 0;
        let sumConcepts = sumConceptsSelected + assignedOtherInvoices;
        let differenceAmounts = props.line.line_total - sumConcepts;
        setSumConceptsSelected(sumConceptsSelected);
        setSumConcepts(sumConcepts);
        setDifferenceAmounts(differenceAmounts);
        setOutOfTolerance(getOutOfTolerance(differenceAmounts, props.tolerance, props.validateAmountsType));
    };

    const tabContent = (index: number) => {
        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "invoices":
                return <InvoicesTab concepts={props.concepts} onCheck={onCheckConcept} currency={props.currency} selected={selectedConceptsIndex}/>;
            case "related":
                return <RelatedTab cfdis={cfdis} currency={props.currency} />;
            default:
                return <div></div>;
        }
    };

    return (
        <DialogPopup title={translate("cfdi_relate_po.modal_concepts.title")} open onClose={props.onClose} maxWidth="md" button={
            <Button onClick={() => props.onSave(selectedConceptsIndex)} variant="contained" color="primary" size="medium" disabled={outOfTolerance}>
                {translate("buttons.accept")}
            </Button>} >
            <Grid container>
                <Grid item xs={12}>
                    <Box px={2} py={2} style={{ borderStyle: "solid", borderRadius: 20, borderColor: "#DFDCDC" }}>
                        <Grid container >
                            <Grid item xs={3}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Typography style={{ color:"#686868" }} >{translate("purchase_orders.single")}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{props.line.po_external_id}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Typography style={{ color:"#686868" }}>{translate("cfdi_relate_po.modal_concepts.line")}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{props.line.line_num}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Typography style={{ color:"#686868" }}>{translate("cfdis.details.description")}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{props.line.description}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                <Typography style={{ color:"#686868" }}>{translate("cfdi_relate_po.modal_concepts.total_line")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                <NumberFormat value={props.line.line_total}
                                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                    thousandSeparator="," displayType="text" suffix={" "+props.currency}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <br/> <Divider /> <br/>
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: CfdiTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container >
                <Grid item xs={12}>
                    {tabs.map((tab: CfdiTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            <Divider /> <br/>
            <Grid container>
                <Grid item xs={12}>
                    <Box px={2} py={2}>
                        <Grid container spacing={4} >
                            <Grid item xs={true}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item style={{ color:"#686868" }}>
                                                {translate("cfdi_relate_po.modal_concepts.amount_assigned")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                <Typography color="primary"><NumberFormat value={assignedOtherInvoices}
                                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                    thousandSeparator="," displayType="text" suffix={" "+props.currency}
                                                /></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={true}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item style={{ color:"#686868" }}>
                                                {translate("cfdi_relate_po.modal_concepts.amount_invoice")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                <Typography color="secondary"><NumberFormat value={sumConceptsSelected}
                                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                    thousandSeparator="," displayType="text" suffix={" "+props.currency}
                                                /></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={true}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item style={{ color:"#686868" }}>
                                                {translate("cfdi_relate_po.modal_concepts.amount_total")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                <NumberFormat value={sumConcepts}
                                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                    thousandSeparator="," displayType="text" suffix={" "+props.currency}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={true}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item style={{ color:"#686868" }}>
                                                {translate("cfdi_relate_po.modal_concepts.total_line_short")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                <NumberFormat value={props.line.line_total}
                                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                    thousandSeparator="," displayType="text" suffix={" "+props.currency}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs="auto">
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item style={{ color:"#686868" }}>
                                                {translate("cfdi_relate_po.modal_concepts.amount_difference")}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" >
                                            <Grid item>
                                                { outOfTolerance ?
                                                    <Tooltip title={translate("cfdi_relate_po.modal_concepts.tootltip_difference", { "total_line": props.line.line_total, "assigned": sumConcepts}) as string}>
                                                        <ErrorIcon color="secondary"/>
                                                    </Tooltip> : <></>}
                                                <b><NumberFormat value={differenceAmounts}
                                                    prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                    thousandSeparator="," displayType="text" suffix={" "+props.currency}
                                                /></b>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </DialogPopup>);
}