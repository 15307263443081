import React from "react";
import translate from "../i18n/Translator";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";
import { Requisition } from "../model/Requisition";

export interface RequisitionHistoryParams {
    requisition: Requisition;
}

export default function RequisitionHistory(props: RequisitionHistoryParams) {

    const getStatusDescription = (status: string) => {
        if(!props.requisition) return "---";
        let statusFind = props.requisition.statuses.find(item => item.status === status);
        return statusFind ? statusFind.description : status;
    }

    return (
        <Gridable
            items={props.requisition.history ? props.requisition.history : []}
            loading={false}
            empty={translate("cfdis.history.empty") as string}
            columns={[
                {
                    title: translate("cfdis.history.created_at") as string,
                    converter: (history) => (<DateFormat date={history.updated_date} format="DD-MM-YYYY h:mm a" zone={true} />),
                    xs: 3,
                    sm: 3,
                    md: 3,
                    xl: 3
                },
                {
                    title: translate("cfdis.history.previous_status") as string,
                    converter: (history) => (
                        <Ellipsis 
                            lenght={100} uppercased={false}
                            text={history.previous_status ? getStatusDescription(history.previous_status) as string : ""}  
                        />),
                    xs: false,
                    sm: false,
                    md: 2,
                    xl: 2
                },
                {
                    title: translate("cfdis.history.user") as string,
                    converter: (history) => (<Ellipsis text={history.user_name ? history.user_name : "---"} uppercased={false} />),
                    xs: 3,
                    sm: 3,
                    md: 2,
                    xl: 2
                },
                { 
                    title: translate("cfdis.history.status_title") as string,
                    converter: (history) => (
                        <Ellipsis 
                            lenght={100} uppercased={false}
                            text={getStatusDescription(history.status) as string}
                        />),
                    xs: 3,
                    sm: 3,
                    md: 2,
                    xl: 2
                },
                {
                    title: translate("cfdis.history.comments") as string,
                    converter: (history) => (history.comments !== undefined ?
                        <div style={{ "overflowY": "scroll" }} className="content-container" >{history.comments} </div> : undefined),
                    xs: 3,
                    sm: 3,
                    md: 3,
                    xl: 3
                },
            ]} />
    )
}