import React, { useState } from "react";
import { Grid, List, Card } from "@material-ui/core";

import translate from "../i18n/Translator";
import PayableDocumentProrates from "./PayableDocumentProrates";
import { Cfdi } from "../model/Cfdi";
import { ProrateCfdi } from "../model/Prorate";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

interface PayableDocumentProrateProps {
    payableDocument: Cfdi;
    onChangeProrate(prorate: ProrateCfdi[]): any;
}

export default function PayableDocumentProrate(props: PayableDocumentProrateProps) {
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    const onEditProrate = (prorates: ProrateCfdi[]) => {
        setSuccess(translate("cfdis.prorate.success") as string);
        props.onChangeProrate(prorates);
    };

    const onErrorEditField = (error: string) => {
        setError(error);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    return (
        <Grid item xs={12}>
            <Card variant="outlined" >
                <List component="nav" dense >
                    <PayableDocumentProrates cfdi={props.payableDocument} onChange={onEditProrate} onError={onErrorEditField} />
                </List>
            </Card>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Grid>
    );
}