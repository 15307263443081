import { callAPI, HTTPMethod } from "./API";
import { Calendar, CalendarEvent, CalendarEventParams, CalendarEventRequest, WidgetsMenusResponse, CloneCalendarRequest, CloneCalendarResponse } from '../model/Dashboard';

export async function getCalendar(tenantId: string, year:string , month:string): Promise<Calendar> {
    return await callAPI(`/tenants/${tenantId}/calendars`, {
        method: HTTPMethod.GET,
        query: { "year": year ?? "", "month" : month ?? ""}
    });
}

export async function getWidgetsMenusPendings(tenantId: string): Promise<WidgetsMenusResponse> {
    return await callAPI(`/tenants/${tenantId}/widgets-menus`, {
        method: HTTPMethod.GET
    });
}

export async function getWidgetsMenusPendingsProviders(tenantId: string, providerId: string): Promise<WidgetsMenusResponse> {
    return await callAPI(`/tenants/${tenantId}/provider/${providerId}/widgets-menus`, {
        method: HTTPMethod.GET
    });
}

export async function getCalendarEvents(tenantId: string, params: CalendarEventParams): Promise<Calendar> {
    return await callAPI(`/tenants/${tenantId}/calendars`, {
        method: HTTPMethod.GET,
        query: { "year": params?.year ?? "", "adminView":  params?.admin_view ? "true":"false" }
    });
}

export async function getCalendarEvent(tenantId: string, eventId: string): Promise<CalendarEvent> {
    return await callAPI(`/tenants/${tenantId}/calendars/events/${eventId}`, {
        method: HTTPMethod.GET,
    });
}

export async function saveCalendarEvent(tenantId: string, calendarId: string, request: CalendarEventRequest): Promise<CalendarEvent> {
    return await callAPI(`/tenants/${tenantId}/calendars/${calendarId}/events`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function updateCalendarEvent(tenantId: string, eventId: string, request: CalendarEventRequest): Promise<CalendarEvent> {
    return await callAPI(`/tenants/${tenantId}/calendars/events/${eventId}`, {
        method: HTTPMethod.PATCH,
        body: request
    });
}

export async function deleteEvent(tenantId: string, calendarId: string, eventId: string): Promise<any> {
    return await callAPI(`/tenants/${tenantId}/calendars/${calendarId}/events/${eventId}`, {
        method: HTTPMethod.DELETE,
    });
}


export async function cloneCalendar(tenantId: string, calendarId: string, request: CloneCalendarRequest): Promise<CloneCalendarResponse> {
    return await callAPI(`/tenants/${tenantId}/calendars/${calendarId}/events/clone`, {
        method: HTTPMethod.POST,
        body:request
    });
}


