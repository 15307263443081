import { Box, Button, Grid, Typography } from "@material-ui/core";
import PurchaseOrdersIcon from "@material-ui/icons/ShopTwoTwoTone";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { getRequisition, createPurchaseOrder } from "../api/RequisitionAPI";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar, SuccessSnackbar } from '../components/Snackbars';
import Surface from "../components/Surface";
import UploaderInput from "../components/UploaderInput";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { Requisition } from "../model/Requisition";
import { RouterParams } from "../router/RouterParams";
import { PurchaseOrderRequisitionRequest } from "../model/PurchaseOrder";

export default function PurchaseOrderForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const [error, setError] = useState("");
    const [status, setStatus] = useState<string>("loading");
    const [redirect, setRedirect] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warning, setWarning] = useState("");
    const [success, setSuccess] = useState<string>();
    const [request, setRequest] = useState<PurchaseOrderRequisitionRequest>({} as PurchaseOrderRequisitionRequest);
    const [requisition, setRequisition] = useState<Requisition>({} as Requisition);
    const [validations, setValidations] = useState({
        "external_id": {
            valid: false
        } as InputRef,
        "pdf_url": {
            valid: false
        } as InputRef,
        // "document_url": {
        //     valid: false
        // } as InputRef,
        // "document_id": {
        //     valid: false
        // } as InputRef,
    } as any);

    useEffect(() => {
        setStatus("loading");
        getRequisition(context.session!.tenant!.id, match.params.requisitionId).then(requisition => {
            setRequisition(requisition);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, [match.params.requisitionId]);

    const onBack = () => {
        history.goBack();
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        const fixedValue = value === "---" ? "" : value;
        setRequest({ ...request, [name]: fixedValue });

        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onCreatePO = () => {
        if (!isValid()) {
            return;
        }
        if(requisition.in_project && requisition.quotation && requisition.project_amount < requisition.quotation.amount){
            setWarning(translate("requisitions.form_purchase_order.warning_amounts", 
                {"projectName": requisition.project_name, 
                    "projectAmount": requisition.project_amount.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2 })}) as string);
            return;
        }
        setSubmitting(true);
        createPurchaseOrder(context.session!.tenant!.id, match.params.requisitionId, request).then(po => {
            setSuccess(translate("requisitions.form_purchase_order.purchase_order_created") as string);
            setTimeout(()=>{
                setRedirect(`/requisitions/authorized`);
            }, 500);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={10} lg={7} xl={5}>
                <Surface title={translate("requisitions.form_purchase_order.new")} icon={<PurchaseOrdersIcon />} 
                subtitle={translate("requisitions.overview.subtitle", {"number": requisition.operation_number}) as string}
                className="FormSurface" 
                titleActions={
                    <Grid item xs={12}>
                        <Typography variant="h6" color="secondary">
                            {translate("requisitions.form_purchase_order.amount_requisition", { "amount": requisition.quotation ? requisition.quotation.amount : 0}) as string}
                        </Typography>
                    </Grid>
                }>
                        <Grid container justify="space-between" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="primary">
                                    {translate("requisitions.form_purchase_order.title_additional_info") as string}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="external_id" name="external_id"
                                    value={request.external_id} label={translate("requisitions.form_purchase_order.external_id") as string}
                                    required={true} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <UploaderInput
                                    id="pdf_url"
                                    name="pdf_url"
                                    value={request.pdf_url}
                                    label={translate("requisitions.form_purchase_order.pdf_url") as string}
                                    required
                                    disabled={submitting}
                                    margin="dense"
                                    acceptExtension=".pdf"
                                    onValueChanged={hasChanged}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <ValidatedInput type="text" id="document_id" name="document_id"
                                    value={request.document_id} label={translate("requisitions.form_purchase_order.document_id") as string}
                                    required={true} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <UploaderInput
                                    id="document_url"
                                    name="document_url"
                                    value={request.document_url}
                                    label={translate("requisitions.form_purchase_order.document_url") as string}
                                    required
                                    disabled={submitting}
                                    margin="dense"
                                    acceptExtension=".pdf"
                                    onValueChanged={hasChanged}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <ValidatedInput type="text" id="comments" name="comments"
                                    value={request.comments} label={translate("requisitions.form_purchase_order.comments") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                        </Grid>
                        <Grid container justify="space-between" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="primary">
                                    {translate("requisitions.form_purchase_order.title_address") as string}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="street_number" name="street_number"
                                    value={request.street_number} label={translate("requisitions.form_purchase_order.street_number") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="county" name="county"
                                    value={request.county} label={translate("requisitions.form_purchase_order.county") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="city" name="city"
                                    value={request.city} label={translate("requisitions.form_purchase_order.city") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="state" name="state"
                                    value={request.state} label={translate("requisitions.form_purchase_order.state") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="postal_code" name="postal_code"
                                    value={request.postal_code} label={translate("requisitions.form_purchase_order.postal_code") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                    <Grid item xs={12} md="auto">
                                        <Button type="button" variant="contained" color="primary" size="large" disabled={submitting} onClick={onCreatePO}>
                                            {translate("requisitions.form_purchase_order.button_new")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md="auto">
                                            <Button variant="text" color="primary" size="large" disabled={submitting} onClick={onBack}>
                                                {translate("buttons.cancel")}
                                            </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                </Surface>
            </Grid>
        </Grid>
        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
    </Grid>;
}
