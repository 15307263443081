import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SocialContributionIcon from "@material-ui/icons/HomeWork";
import AddIcon from "@material-ui/icons/Add";

import { listSocialContributions, deleteSocialContribution } from "../api/SocialContributionAPI";
import { listSocialContributions as listProviderSocialContributions, deleteSocialContribution as deleteProviderSocialContribution } from "../api/ProviderSocialContributionAPI";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import { get as getProviderConfigurations } from "../api/ProviderConfigurationApi";
import { AppContext } from "../context/AppContext";
import { SocialContribution, SocialContributions, SocialContributionsQueryParams } from "../model/SocialContribution";
import { ContributionStatus, STATUSES } from "../model/ContributionStatus";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import SocialContributionMenu from "./SocialContributionMenu";
import moment from "moment";
import { EmployerConfiguration } from "../model/Employer";
import { redDot, greenDot, orangeDot, getSocialBankVoucher, getSocialSuaSipare } from "../contributions/ContributionsTable";
import { SuccessSnackbar } from "../components/Snackbars";
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function SocialContributionsList() {
    const context = useContext(AppContext);
    const uploadSipare = context.session!.provider && context.session!.provider.upload_sipare;
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;
    const history = useHistory();
    const statusLabels = STATUSES.map(s => translate(`contributions.status.${s}`) as string);

    const query = new URLSearchParams(useLocation().search);
    const monthS = query.get("month");
    const yearS = query.get("year");
    const statusS = query.get("status");
    const employerNumberS = query.get("employerNumber");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<SocialContributions>();
    const [workingParams, setWorkingParams] = useState<SocialContributionsQueryParams>({} as SocialContributionsQueryParams);

    const [socialContribution, setSocialContribution] = useState<SocialContribution>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [monthKeys, setMonthKeys] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [employerNumbers, setEmployerNumbers] = useState<string[]>([]);
    const [success, setSuccess] = useState<string>();
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

    const promiseDelete = (contributionId: string) => {
        if (providerId) {
            return deleteProviderSocialContribution(tenantId, providerId, contributionId);
        }
        return deleteSocialContribution(tenantId, contributionId);
    };

    const promiseGet = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            month: !monthS || isNaN(+monthS) ? undefined : +monthS,
            year: !yearS || isNaN(+yearS) ? undefined : +yearS,
            status: statusS ? statusS as ContributionStatus : undefined,
            employerNumber: employerNumberS
        } as SocialContributionsQueryParams;
        setWorkingParams(params);

        if (providerId) {
            return listProviderSocialContributions(tenantId, providerId, pageSize, offset, params);
        }
        return listSocialContributions(tenantId, pageSize, offset, params);
    };

    const load = () => {
        promiseGet().then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, page, pageSize, monthS, yearS, statusS, employerNumberS]);

    const promiseGetConfigurations = (): Promise<EmployerConfiguration> => {
        if (providerId) {
            return getProviderConfigurations(tenantId, providerId);
        }
        return getTenantConfigurations(tenantId);
    };

    const loadEmployerNumbers = () => {
        promiseGetConfigurations().then(response => {
            setEmployerNumbers(response.employer_numbers || []);
        }).catch(error => {
            console.error(error);
        });
    };

    useEffect(loadEmployerNumbers, [tenantId, providerId]);

    useEffect(() => {
        const monthKeys = [];
        const monthLabels = [];
        const years = [];
        const today = new Date();
        for (let i = 0; i < 12; i++) {
            monthKeys.push(`${i + 1}`);
            monthLabels.push(moment({
                year: today.getFullYear(),
                month: i,
                day: 1
            }).format("MMMM"));
        }

        for (let i = 2021; i <= today.getFullYear(); i++) {
            years.push(`${i}`);
        }

        setMonthKeys(monthKeys);
        setMonthLabels(monthLabels);
        setYears(years);
    }, []);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (workingParams.year) {
            query.set("year", `${workingParams.year}`);
        } else {
            query.delete("year");
        }
        if (workingParams.month) {
            query.set("month", `${workingParams.month}`);
        } else {
            query.delete("month");
        }
        if (workingParams.status) {
            query.set("status", workingParams.status);
        } else {
            query.delete("status");
        }
        if (workingParams.employerNumber) {
            query.set("employerNumber", workingParams.employerNumber);
        } else {
            query.delete("employerNumber");
        }

        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        if (!value || value === "---") {
            setWorkingParams({ ...workingParams, [name]: undefined });
        } else if (name === "status") {
            setWorkingParams({ ...workingParams, status: value as ContributionStatus });
        } else if (name === "employerNumber") {
            setWorkingParams({ ...workingParams, employerNumber: value });
        } else {
            setWorkingParams({ ...workingParams, [name]: +value });
        }
    };

    const onClickedOptions = (socialContribution: SocialContribution) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSocialContribution(socialContribution);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setSocialContribution(undefined);
    };

    const onClickRow = (socialContribution: SocialContribution) => {
        if(canEdit(socialContribution)){
            history.push(`/contributions/social/${socialContribution.id}/edit`);
        }
    };

    const canEdit = (socialContribution: SocialContribution) => {
        return context.isGrantedAny(["SocialContributionsUpdate", "ProviderSocialContributionsUpdate"]) &&
                socialContribution.refuse_reason_sipare === undefined &&
                socialContribution.status != "COMPLETED";
    }

    const onOpenDelete = () => {
        setAnchorEl(null);
        if(!socialContribution) return;

        setOpenConfirmDelete(true);
    };

    const onCancelDelete = () => {
        setOpenConfirmDelete(false);
        setSocialContribution(undefined);
    };

    const onConfirmDelete = () => {
        setOpenConfirmDelete(false);
        if(!socialContribution) return;

        promiseDelete(socialContribution.id).then((response) => {
            setSuccess(translate("social_contributions.success_delete") as string);
            load();
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setSocialContribution(undefined);
        });
    };

    return (
        <Pagination title={translate("social_contributions.title")} icon={<SocialContributionIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["SocialContributionsCreate", "ProviderSocialContributionsCreate"]) ?
                (<Link to="/contributions/social/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    {employerNumbers.length > 0 && (
                        <Grid item xs={6} sm>
                            <ValidatedInput type="text" id="employerNumber" name="employerNumber" label={translate("contributions.employer_number") as string}
                                options={employerNumbers}
                                margin="dense" disabled={false}
                                value={workingParams.employerNumber} onValueChanged={onFilterChanged} />
                        </Grid>
                    )}
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="year" name="year" label={translate("contributions.year") as string}
                            options={years}
                            margin="dense" disabled={false}
                            value={workingParams.year?.toString()} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="month" name="month" label={translate("contributions.month") as string}
                            options={monthKeys} optionLabels={monthLabels}
                            margin="dense" disabled={false}
                            value={workingParams.month?.toString()} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="status" name="status" label={translate("contributions.status.title") as string}
                            options={STATUSES} optionLabels={statusLabels}
                            margin="dense" disabled={false}
                            value={workingParams.status} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("social_contributions.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("contributions.month_employer_number"),
                        converter: (socialContribution) => (
                            <>
                                <DateFormat date={socialContribution.date} format="MMMM YYYY" />
                                <Typography variant="caption" color="textSecondary" component="h6">
                                    {socialContribution.employer_number}
                                </Typography>
                            </>
                        ),
                        fullWidth: true,
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                    },
                    {
                        title: (
                            <Grid container alignContent="center" alignItems="center" justify="center">
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        <strong>{translate(uploadSipare ? "social_contributions.sipare" : "social_contributions.sua")}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        <strong>{translate("social_contributions.bank_voucher")}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        <strong>{translate("social_contributions.imss")}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        <strong>{translate("social_contributions.infonavit")}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        ),
                        converter: (socialContribution) => (
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        {getSocialSuaSipare(socialContribution.status_by_documents, socialContribution)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {getSocialBankVoucher(socialContribution.status_by_documents)}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        {socialContribution.imss_cfdi_id ? greenDot : redDot}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="body2" align="center">
                                        {socialContribution.required_infonavit ? (socialContribution.infonavit_cfdi_id ? greenDot : orangeDot) : translate("social_contributions.na")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ),
                        fullWidth: true,
                        xs: 6,
                        sm: 8,
                        md: 8,
                        lg: 8,
                        xl: 8,
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (socialContribution) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(socialContribution)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {socialContribution && anchorEl &&
                <SocialContributionMenu socialContribution={socialContribution} anchor={anchorEl} 
                onClose={onCloseOption}  onDelete={onOpenDelete} canEdit={canEdit(socialContribution)}/>
            }
            {socialContribution && openConfirmDelete &&
                <ConfirmationPopup
                    title={translate("social_contributions.confirm_delete.title") as string}
                    message={translate("social_contributions.confirm_delete.message") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDelete}
                    doAction={onConfirmDelete}
                />
            }
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
        </Pagination>
    );
}