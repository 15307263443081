import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { WarningSnackbar } from "../components/Snackbars";


interface RejectPaymentPopupProps {
    name?: string;
    onClose(): any;
    doAction(comment: string): any;
}

export default function RejectPaymentPopup(props: RejectPaymentPopupProps) {
    const [request, setRequest] = useState<string>();
    const [disabled, setDisabled] = useState(true);
    const [warning, setWarning] = useState<string>();

    const onSubmit = () => {
        if (!request) {
            props.doAction("");
            return;
        }
        props.doAction(request);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
        if(!request || request === ""){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
    };

    return (
        <DialogPopup open
            title={translate("payment.add_comment.title_reject" ) as string}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={disabled}>
                    {translate("cfdis.add_comment.button.reject")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {translate("payment.add_comment.text_reject", {"name": props.name}) as string}
                        </Typography>
                        <Typography variant="body2">
                            {translate("payment.add_comment.text_reject_secondary") as string}
                        </Typography><br/>
                        <Typography variant="body2">
                            {translate("payment.add_comment.text_reject_question") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="comment" name="comment"
                            label={translate("cfdis.add_comment.input.reject") as string}
                            maxLength={500}
                            value={request}
                            rows={5}
                            required={true}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}

