import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";

import { Grid, Typography, Box, Button } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";

import translate from "../i18n/Translator";
import { BillingManualPaymentRequest, Billing, BillingMetadata } from "../model/Billing";
import { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";
import { getBilling, updateBillingManual, getBillingMetadata } from "../api/TenantsBillingAPI";
import { AppContext } from "../context/AppContext";
import { PlanDetail } from "./PlanPicker";
import { Plan } from "../model/Plan";
import PaymentManualDetails from "./PaymentManualDetails";
import UploaderInput from "../components/UploaderInput";
import MissingLicenseBanner from "./MissingLicenseBanner";

export default function PaymentManualForm({ match }: RouterParams) {
    const context = useContext(AppContext);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState();
    const [billing, setBilling] = useState<Billing>({} as Billing);
    const [plan, setPlan] = useState<Plan>();
    const [metadata, setMetadata] = useState<BillingMetadata>();
    const [request, setRequest] = useState<BillingManualPaymentRequest>({} as BillingManualPaymentRequest);
    const [validations, setValidations] = useState({
        "url": {
            valid: false
        } as InputRef
    } as any);

    const load = () => {
        setStatus("loading");
        Promise.all([getBilling(context.session!.tenant!.id, match.params.billingId), getBillingMetadata(context.session!.tenant!.id)])
            .then((results) => {
                let billingResponse = results[0];
                let metadataResponse = results[1];
                setBilling(billingResponse);
                setPlan(billingResponse.plan);
                if (billingResponse.attachment) {
                    setRequest({ ...request, url: billingResponse.attachment })
                }
                setMetadata(metadataResponse);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });

        getBilling(context.session!.tenant!.id, match.params.billingId).then((billingResponse) => {
            setBilling(billingResponse);
            setPlan(billingResponse.plan);
            if (billingResponse.attachment) {
                setRequest({ ...request, url: billingResponse.attachment })
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, [match.params.billingId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        updateBillingManual(context.session!.tenant!.id, match.params.billingId, request).then((response) => {
            setRedirect("/billings");
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loaded" || !plan || !metadata) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface title={translate("billing.manual.title") as string} icon={<ReceiptIcon />} backButton>
            <Grid container justify="space-evenly" spacing={4}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={5}>
                    <form autoComplete="off" noValidate onSubmit={onSubmit}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <MissingLicenseBanner variant="info" margin={3} />
                                </Grid>
                                <PaymentManualDetails reference={match.params.billingId}
                                    amount={billing.amount.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2 })} />
                            </Grid>
                            <Grid item xs={12}>
                                <UploaderInput id="url" name="url"
                                    path={`${context.session!.tenant!.id}-manual-payment_${match.params.billingId}`}
                                    value={request.url} label={translate("billing.manual.file") as string}
                                    required={true} disabled={submitting} margin="dense"
                                    acceptExtension=".pdf"
                                    readOnly={billing.status === "DONE" || billing.status === "REVIEW"}
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={1}>
                                    <Grid container justify="center" alignItems="center" spacing={2} direction="row-reverse">
                                        <Grid item xs={12} md>
                                            <Button type="submit" variant="contained" color="primary" size="medium" fullWidth disabled={billing.status === "DONE" || billing.status === "REVIEW" || submitting}>
                                                {translate("buttons.send")}
                                            </Button>
                                        </Grid>
                                        {billing.gateway_enabled && (
                                            <Grid item xs="auto">
                                                <Button variant="text" color="primary" size="small" disabled={submitting} component={Link} to="/subscribe">
                                                    {translate("buttons.pay_with_card")}
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={4} xl={3}>
                    <PlanDetail tenantId={context.session!.tenant!.id}
                        billingId={billing.id}
                        plan={plan}
                        plans={metadata.plans}
                        upgradable={billing.id === metadata.billing?.id && metadata.upgradable} />
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </Surface>
    );
}