import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import translate from "../i18n/Translator";
import { KeyPair, KeyPairRequest } from "../model/KeyPair";
import { createKeyPair, getKeyPair, updateKeyPair, metadata } from "../api/KeyPairAPI";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Surface from "../components/Surface";
import { ErrorSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import GroupCheckbox from "../components/GroupCheckbox";

export default function KeyPairForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const theme = useTheme();
    const isXs = useMediaQuery(() => theme.breakpoints.down("xs"));
    const isEdit = !!match.params.keyPairId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [actions, setActions] = useState<string[]>([]);
    const [actionsChecks, setActionsChecks] = useState<boolean[]>([]);
    const [actionsDisabled, setActionsDisabled] = useState<boolean[]>([]);
    const [request, setRequest] = useState<KeyPairRequest>({
        name: "",
        actions: []
    } as KeyPairRequest);
    const [validations, setValidations] = useState({
        "name": {
            valid: false
        } as InputRef,
        "actions": {
            valid: false
        } as InputRef
    } as any);

    const submitPromise = (): Promise<KeyPair> => {
        if (isEdit && match.params.keyPairId) {
            return updateKeyPair(context.session!.tenant!.id, match.params.keyPairId, request);
        }
        return createKeyPair(context.session!.tenant!.id, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            getKeyPair(context.session!.tenant!.id, match.params.keyPairId).then((keyPair) => {
                const currentActions = keyPair.actions || [];
                setRequest({
                    name: keyPair.name,
                    actions: currentActions
                } as KeyPairRequest);

                const allActions = keyPair.metadata?.actions || [];
                setActions(allActions);
                setActionsDisabled(allActions.map(() => true));
                setActionsChecks(allActions.map(action => currentActions.indexOf(action) >= 0));
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            metadata(context.session!.tenant!.id).then(response => {
                setActions(response.actions);
                setActionsDisabled(response.actions.map(() => true));
                setActionsChecks(response.actions.map(() => false));
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        }
        // eslint-disable-next-line
    }, [isEdit, match.params.keyPairId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedSwitch = (name: string, value: string, checked: boolean) => {
        const index = actions.findIndex((el) => el === value);
        if (index >= 0) {
            actionsChecks[index] = checked;
            setActionsChecks(actionsChecks);

            const i = request.actions.indexOf(value);
            if (checked) {
                if (i < 0) {
                    request.actions.push(value);
                }
            } else if (i >= 0) {
                request.actions.splice(i, 1);
            }
            setRequest({ ...request, actions: request.actions });
        }
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((tenant) => {
            setRedirect(true);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    if (redirect) {
        return <Redirect to="/keypairs" />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "keypairs.edit" : "keypairs.new")} icon={<VpnKeyIcon />} backButton className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name} label={translate("keypairs.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <GroupCheckbox title={translate("keypairs.actions") as string} row={!isXs}
                                        name="actions"
                                        values={actions}
                                        labels={actions}
                                        checkeds={actionsChecks}
                                        disabled={submitting ? actionsDisabled : undefined}
                                        helper={translate("validations.required") as string}
                                        inputRef={validations["actions"]}
                                        onChanged={hasChangedSwitch} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs sm="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting} fullWidth={isXs}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}