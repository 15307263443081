import React from "react";
import { Grid, List, ListItem, Button, Card, ListSubheader, Box, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AdvanceMetadata } from "../model/Cfdi";
import NumberFormat from "react-number-format";
import Gridable from "../components/Gridable";
import { Link } from "react-router-dom";
import { getAmountDiscounts } from "../model/Cfdi";

export interface CfdiHistoryParams {
    tenantId: string;
    advanceMetadata: AdvanceMetadata;
    subtotal: number
}

export default function CfdiAdvanceTab(props: CfdiHistoryParams) {
    return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <br/>
            <Box px={2}>
                <Card variant="outlined" >
                    <List component="nav" dense subheader={
                        <ListSubheader disableSticky>
                            {translate(`cfdis.advances.subtitle`)}
                        </ListSubheader>
                    }>
                        <ListItem>
                            <Grid container>
                                <Grid item xs={3} >
                                    <b>{translate(`cfdis.advances.total`)}</b>
                                </Grid>
                                <Grid item xs={3} >
                                    <NumberFormat value={props.subtotal} 
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                                </Grid>
                                <Grid item xs={3} >
                                    <b>{translate(`cfdis.advances.balance`)}</b>
                                </Grid>
                                <Grid item xs={3} >
                                    <NumberFormat value={props.advanceMetadata.balance} 
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Card>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box pl={2}>
                <Typography> <strong>{translate("cfdis.advances.title_grid")}</strong></Typography>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Gridable
                items={props.advanceMetadata.relations}
                loading={false}
                empty={translate("cfdis.advances.empty") as string}
                columns={[
                    {
                        title: translate("cfdis.advances.relations.uuid") as string,
                        converter: (relation) => <Button variant="text" color="default" size="small" component={Link} to={`/cfdis/${relation.complementary_cfdi.id}/details_related`}>
                                                        {relation.complementary_cfdi.cfdi?.timbre.uuid}
                                                </Button>,
                        xs: true
                    },
                    {
                        title: translate("cfdis.advances.relations.subtotal") as string,
                        converter: (relation) => <NumberFormat value={relation.complementary_cfdi.cfdi?.subtotal} 
                            prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />,
                            justify: "flex-end",
                            xs: true
                    },
                    {
                        title: translate("cfdis.advances.relations.uuid_cn") as string,
                        converter: (relation) => relation.credit_note ? <Button variant="text" color="default" size="small" component={Link} to={`/cfdis/${relation.credit_note?.id}/details_related`}>
                                                        {relation.credit_note?.cfdi?.timbre.uuid}
                                                </Button> : <></>,
                        xs: true
                    },
                    {
                        title: translate("cfdis.advances.relations.subtotal") as string,
                        converter: (relation) => <NumberFormat value={relation.credit_note ? relation.credit_note?.cfdi?.subtotal : getAmountDiscounts(relation.complementary_cfdi)} 
                            prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />,
                            justify: "flex-end",
                            xs: true
                    },
                    {
                        title: translate("cfdis.advances.relations.applied_amount") as string,
                        converter: (relation) => <NumberFormat value={relation.accumulated_applied_amount} 
                            prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />,
                            justify: "flex-end",
                            xs: true
                    },
                    {
                        title: translate("cfdis.advances.relations.current_balance") as string,
                        converter: (relation) => <NumberFormat value={relation.current_balance} 
                            prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />,
                            justify: "flex-end",
                            xs: true
                    }
                ]} />
        </Grid>
    </Grid>
        
    )
}