import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { TenantSegment } from "../model/TenantFieldCatalog";
import translate from "../i18n/Translator";
import { Divider } from "@material-ui/core";
import { ProviderExpedientField } from "../model/ProviderExpedient";
import DialogPopup from "../components/DialogPopup";

interface ProviderFieldCardConf {
    tenantId: string;
    providerId: string;
    field: ProviderExpedientField;
    configuration: TenantSegment;
    onClose(): any;
}

export default function ProviderFieldCard(props: ProviderFieldCardConf) {
    return (
        <DialogPopup open title={props.field.configuration.name} onClose={props.onClose}>
            {props.configuration.description && (
                <Typography color="textSecondary" variant="caption" component="h6">
                    {props.configuration.description}
                </Typography>
            )}
            <Typography color="textSecondary" variant="caption" component="h6">
                {translate(props.configuration.required ? "expedients.required" : "expedients.optional")}
            </Typography>
            <Box py={1}>
                <Divider />
            </Box>
            {!props.field.value && (
                <Typography color="textSecondary" variant="caption" component="h6">
                    {translate("expedients.not_provided") as string}
                </Typography>
            )}
            {props.field.value && (
                <Typography color="textSecondary" variant="body2">
                    {props.field.value}
                </Typography>
            )}
        </DialogPopup>
    );
}