import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, Switch, FormGroup, FormControlLabel, Divider, Fab } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import { LedgerAssignment, ProviderLedgerAccountRequest, ProviderLedgerAccount, LedgerAssignmentRequest } from "../model/Provider";
import AddIcon from "@material-ui/icons/Add";
import ProviderAddRelationCecoPopup from "./ProviderAddRelationCecoPopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ProviderConfigLedgerMenu from "./ProviderConfigLedgerMenu";
import { assignment, deleteAssignment } from "../api/ProviderLedgerAPI";

interface ProviderConfigurationLedgerProps {
    providerId: string;
    tenantId: string;
    ledgerAssignment?: LedgerAssignment;
    onChange(response: LedgerAssignment): any;
}

export default function ProviderConfigurationLedger(props: ProviderConfigurationLedgerProps) {
    const [status, setStatus] = useState<string>("loading");
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [needProrate, setNeedProrate] = useState<boolean>(false);
    const [ledgerAssignment, setLedgerAssignment] = useState<LedgerAssignment>();
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [openConfirmSwitch, setOpenConfirmSwitch] = useState<boolean>(false);
    const [isFirst, setIsFirst] = useState<boolean>(true);
    const [configLedger, setConfigLedger] = useState<ProviderLedgerAccount>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    

    const load = () => {
        setStatus("loaded");
        if(props.ledgerAssignment){
            setLedgerAssignment(props.ledgerAssignment);
            setNeedProrate(props.ledgerAssignment.need_ledger_assignment);
            if(props.ledgerAssignment.ledger_accounts_response){
                setIsFirst(props.ledgerAssignment.ledger_accounts_response.length === 0);
            }else{
                setIsFirst(true);
            }
        }
    }

    useEffect(load, [props]);

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const switchMode = () => {
        setIsEdit(false);
        if(!needProrate){
            setOpenPopup(true);
        }else{
            setOpenConfirmSwitch(true);
        }
    };

    const onAddRelation = () => {
        setIsEdit(false);
        setOpenPopup(true);
    };

    const addLedgerConfiguration = () => {
        assignment(props.tenantId, props.providerId, { need_ledger_assignment: false, force_delete: true} as LedgerAssignmentRequest).then((response) => {
            props.onChange(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onSave = (request : ProviderLedgerAccountRequest, oldAccount?: string) => {
        setIsFirst(false);
        setOpenPopup(false);
        setNeedProrate(true);
        assignment(props.tenantId, props.providerId, { need_ledger_assignment: true, force_delete: true, old_account: oldAccount, provider_ledger_account_request: request } as LedgerAssignmentRequest).then((response) => {
            props.onChange(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onClosePopup = () => {
        setOpenPopup(false);
    };

    const onCancel = () => {
        setNeedProrate(false);
        setOpenPopup(false);
    };

    const onCloseConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };
    
    const deleteRelation = () => {
        if(!configLedger) return;
        if (!props.ledgerAssignment) return;
        if(props.ledgerAssignment.ledger_accounts_response.length === 1){
            setIsFirst(true);
        }
        deleteAssignment(props.tenantId, props.providerId, configLedger.public_id).then((response) => {
            setOpenConfirmDelete(false);
            props.onChange(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onCloseConfirmNoProrate = () => {
        setOpenConfirmSwitch(false);
    };
    
    const deleteRelations = () => {
        setNeedProrate(false);
        setOpenConfirmSwitch(false);
        assignment(props.tenantId, props.providerId, { need_ledger_assignment: false, force_delete: true} as LedgerAssignmentRequest).then((response) => {
            props.onChange(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onClickedOptions = (config: ProviderLedgerAccount) => (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setConfigLedger(config);
    };

    const openConfirmationDelete = () => {
        setAnchorEl(null);
        setOpenConfirmDelete(true);
    };

    const onEditAssignment = () => {
        if (!configLedger) return;
        setAnchorEl(null);
        setIsEdit(true);
        setOpenPopup(true);
    }

    const onCloseMenu = () => {
        setAnchorEl(null);
        setConfigLedger(undefined);
    };

    const mapLedgerAccounts = () => {
        if(props.ledgerAssignment && props.ledgerAssignment.ledger_accounts_response){
            return props.ledgerAssignment.ledger_accounts_response;
        }else{
            return [];
        }
    };

    const mapLedgerLine = (value: string) => {
        return <div><Typography variant="caption" >{value}</Typography></div>;
    }

    return (<Grid container>
        <Grid item xs={12}>
            <Box pt={2}>
                <Grid container justify="flex-start" spacing={1}>
                    <Grid item xs={12} >
                        {ledgerAssignment ? 
                            <FormGroup row>
                                <FormControlLabel label={<small>{translate("providers.ledger-accounts.need_prorate_switch")}</small>} labelPlacement="start"
                                    control={
                                        <Switch checked={needProrate} onChange={switchMode} value={needProrate} color="primary" />
                                    } />
                            </FormGroup> : 
                            <Box px={2}>
                                <Grid container>
                                    <Grid item xs>
                                        {translate("providers.ledger-accounts.need_prorate_switch")}
                                        <Button aria-label="add" variant="text" color="primary" size="small" onClick={addLedgerConfiguration}>
                                            {translate("providers.ledger-accounts.create")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box> }
                    </Grid>
                    {ledgerAssignment ? 
                        <Grid item xs={12}>
                            <Box px={2}>
                                <Grid container>
                                    <Grid item xs>
                                        <Typography variant="caption" >{translate(needProrate ? "providers.ledger-accounts.need_prorate" : "providers.ledger-accounts.no_need_prorate")}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    :   <Grid item xs={12}>
                            <Box px={2}>
                                <Grid container>
                                    <Grid item xs>
                                    <Typography variant="caption" >{translate("providers.ledger-accounts.not_defined")}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} >
                        <br/>
                        <Divider />
                    </Grid>
                        <Grid item xs={12}>
                            <Box px={2} py={1}>
                                <Grid container>
                                    <Grid item xs>
                                        <Typography variant="subtitle1" >{translate("providers.ledger-accounts.title_table")}</Typography>
                                    </Grid>
                                    {ledgerAssignment && needProrate ? 
                                    <Grid item>
                                        <Fab color="primary" size="small" title={translate("buttons.add") as string} 
                                        onClick={onAddRelation} disabled={status === "loading"}>
                                            <AddIcon />
                                        </Fab>
                                    </Grid> : undefined }
                                </Grid>
                            </Box>
                        </Grid>
                </Grid>
            </Box>
        </Grid>
        {ledgerAssignment && needProrate ? 
            <Grid item xs={12}>
                <Gridable
                    items={props.ledgerAssignment?.ledger_accounts_response ? props.ledgerAssignment.ledger_accounts_response : []}
                    loading={status === "loading"}
                    error={status !== "loading" && status !== "loaded" ? status : undefined}
                    empty={translate("providers.ledger-accounts.no_relations_yet") as string}
                    columns={[
                        {
                            title: translate("providers.ledger-accounts.ledger_account") as string,
                            converter: (ledgerConfig) => ledgerConfig.number,
                            xs: 3,
                            sm: 3,
                            md: 3,
                            lg: 3,
                            xl: 3
                        },
                        {
                            title: translate("providers.ledger-accounts.name") as string,
                            converter: (ledgerConfig) => ledgerConfig.name,
                            xs: 4,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4
                        },
                        {
                            title: translate("providers.ledger-accounts.cost_center") as string,
                            converter: (ledgerConfig) => ledgerConfig.use_all_cost_centers ? translate("providers.ledger-accounts.all") as string : ledgerConfig.cost_centers_response.map(costCenter => mapLedgerLine(costCenter.name)),
                            xs: 4,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4
                        },
                        {
                            title: (
                                <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                            ),
                            converter: (ledgerConfig) => (
                                <IconButton aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(ledgerConfig)}>
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            justify: "flex-end",
                            xs: true
                        }
                    ]} />
            </Grid> : 
            ledgerAssignment ?
                    <Grid item xs={12}>
                    <Box px={2}>
                        <Grid container>
                            <Grid item xs>
                            <Typography variant="caption" >{translate("providers.ledger-accounts.no_relations")}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            : 
            <Grid item xs={12}>
                <Box px={2}>
                    <Grid container>
                        <Grid item xs>
                        <Typography variant="caption" >{translate("providers.ledger-accounts.not_defined")}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        }
        {openPopup && 
            <ProviderAddRelationCecoPopup editItem={isEdit ? configLedger : undefined} onSave={onSave} onClose={onClosePopup} onCancel={onCancel} first={isFirst} ledgerAccounts={mapLedgerAccounts()}/>
        }
        {openConfirmDelete &&
            <ConfirmationPopup title={translate("providers.ledger-accounts.delete_popup") as string}
            message={translate("providers.ledger-accounts.delete_popup_message") as string}
            button={translate("buttons.confirm") as string}
            onClose={onCloseConfirmDelete}
            doAction={deleteRelation} />
        }
        {openConfirmSwitch &&
            <ConfirmationPopup title={translate("providers.ledger-accounts.no_prorate_confirm") as string}
            message={translate("providers.ledger-accounts.no_prorate_message") as string}
            secondary={translate("providers.ledger-accounts.no_prorate_message_secondary") as string}
            button={translate("buttons.confirm") as string}
            onClose={onCloseConfirmNoProrate}
            doAction={deleteRelations} />
        }
        {configLedger && anchorEl &&
                <ProviderConfigLedgerMenu configLedger={configLedger}
                    anchor={anchorEl}
                    onClose={onCloseMenu}
                    onDelete={openConfirmationDelete}
                    onEdit = {onEditAssignment} />
            }
        <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
    </Grid>);
}