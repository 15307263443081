import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { WorkflowsIcon } from "../components/Icons";

import { getWorkflow, metadata, updateWorkflow } from "../api/WorkflowAPI";
import translate from "../i18n/Translator";
import { Workflow } from "../model/Workflow";
import { UpdateWorkflowRequest } from "../workflove/Model";
import Progress from "../components/Progress";
import Surface from "../components/Surface";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import WorkflowTemplateForm from "../workflove/WorkflowTemplateForm";
import { SuccessSnackbar } from "../components/Snackbars";

export default function WorkflowEditForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [success, setSuccess] = useState<string>();
    const [shippintTemplateIds, setShippintTemplateIds] = useState<string[]>([]);
    const [shippintTemplateNames, setShippintTemplateNames] = useState<string[]>([]);
    const [shippingTemplateWorkfloveId, setShippingTemplateWorkfloveId] = useState<string>();
    const [workfloveIds, setWorkfloveIds] = useState<string[]>([]);
    const [workfloveNames, setWorkfloveNames] = useState<string[]>([]);
    const [workflow, setWorkflow] = useState<Workflow>();

    useEffect(() => {
        setStatus("loading");
        getWorkflow(tenantId, match.params.workflowId).then(workflow => {
            metadata(tenantId, workflow.product).then(workflowMetadata => {
                setShippintTemplateIds(workflowMetadata.shipping_templates.map(st => st.id));
                setShippintTemplateNames(workflowMetadata.shipping_templates.map(st => st.name));

                const shippingTemplate = workflowMetadata.shipping_templates.find((el) => el.id === workflow.shipping_template_id);
                setShippingTemplateWorkfloveId(shippingTemplate?.workflove_id);

                setWorkfloveIds(workflowMetadata.workfloves.map(el => el.id));
                setWorkfloveNames(workflowMetadata.workfloves.map(el => el.name));
                setWorkflow(workflow);

                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        }).catch(error => {
            setStatus(error.message);
        });
    }, [tenantId, match.params.workflowId]);

    const getPromise = (request: UpdateWorkflowRequest) => {
        return updateWorkflow(tenantId, workflow?.id || "-", request)
    };

    const onComplete = (workflow: Workflow) => {
        setSuccess(translate("workflows.edited") as string);
        setWorkflow(workflow);
    }

    if (status === "loading" || !workflow) {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={6} xl={5}>
                    <Surface
                        title={translate("workflows.edit")}
                        subtitle={translate(`workflows.status.${workflow.status}`)}
                        icon={<WorkflowsIcon />}
                        className="FormSurface"
                        backButton
                        backTo="/workflows">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={12}>
                                <ValidatedInput type="text" id="name" name="name"
                                    value={workflow.name}
                                    label={translate("workflows.name") as string}
                                    required
                                    disabled
                                    margin={margin} />
                                <ValidatedInput type="text" id="workflove_id" name="workflove_id"
                                    value={workflow.workflove_id}
                                    label={translate("workflows.workflove") as string}
                                    options={workfloveIds} optionLabels={workfloveNames}
                                    required
                                    disabled
                                    margin={margin} />
                                <ValidatedInput type="text" id="shipping_template_id" name="shipping_template_id"
                                    value={workflow.shipping_template_id}
                                    label={translate("workflows.shipping_template") as string}
                                    options={shippintTemplateIds} optionLabels={shippintTemplateNames}
                                    disabled
                                    margin={margin} />
                            </Grid>
                            {workflow && workflow.id && workflow.workflove_id && shippingTemplateWorkfloveId && (
                                <Grid item xs={12}>
                                    <WorkflowTemplateForm
                                        product={workflow.product}
                                        tenantId={tenantId}
                                        id={workflow.id}
                                        workflowId={workflow.workflove_id}
                                        templateId={shippingTemplateWorkfloveId}
                                        externalId={workflow.external_id ?? ""}
                                        description={workflow.description}
                                        disabled={workflow.status === "DISABLED"}
                                        getPromise={getPromise}
                                        onComplete={onComplete}
                                        phaseTranslator={(externalId => `${workflow.product === "PAYABLE_DOCUMENTS" ? "payable_documents_groups" : "refunds_groups"}.${externalId}`)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
        </Grid>
    );
}