import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { Button, Grid, Divider, IconButton } from "@material-ui/core";
import ProvidersIcon from "@material-ui/icons/Business";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import translate from "../i18n/Translator";
import { getProvidersWithReceptors, assignSpecialRule, exportProviders, exportSelectedProviders } from "../api/ProviderAPI";
import { Providers, Entity as Provider, ProvidersQueryParams, ProviderStatuses, ProviderAssignSpecificRuleRequest } from "../model/Provider";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import ProviderReceptionMenu from "./ProviderReceptionMenu";
import ProviderReceptorsPopup from "./ProviderReceptorsPopup";
import ProviderAssignComboPopup from "./ProviderAssignComboPopup";
import ProvidersReceptionMenu from "./ProvidersReceptionMenu";
import Gridable, { GridableColumn } from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import translator from "../i18n/Translator";
import { getUsers } from "../api/TenantUserAPI";
import { UsersQueryParamsReceptors } from "../model/User";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { ProviderListCallbacks } from "./Providers";
import ProvidersWorkflowsManagePopup from "./components/ProvidersWorkflowsManagePopup";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import ConnectorObjectExporter, { ExportMode } from "../connectors/ConnectorObjectExporter";
import { ExportResponse, Family } from "../model/Connector";
import ExportPopup from "../components/DownloadExportationPopup";

interface ProvidersReceptionListProps {
    subtitle?: string;
    getColumns(providers: Provider[], showChecks: boolean, selected: string[], callbacks: ProviderListCallbacks): GridableColumn<Provider>[];
}

export default function ProvidersReceptionList(props: ProvidersReceptionListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const showChecks = context.isGranted("ProvidersUpdate");
    const providerStatusLabels = ProviderStatuses.map((value: string) => translate(`providers.status.${value}`) as string);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Providers>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const [provider, setProvider] = useState<Provider>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ProvidersQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "not_located_status": "",
            "efos_status": "",
            "validator_user_id": "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "classification": typeof qs["classification"] === "string" ? qs["classification"] as string : "",
            "receptor": typeof qs["receptor"] === "string" ? qs["receptor"] as string : "",
            "specific_rule": typeof qs["specific_rule"] === "string" ? qs["specific_rule"] as string : "",
            "workflow_id": typeof qs["workflow_id"] === "string" ? qs["workflow_id"] as string : "",
        } as ProvidersQueryParams;
    };
    const [params, setParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ProvidersQueryParams>(paramsFromQueryString);
    const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>([]);
    const [selectedProvidersToReceptor, setSelectedProvidersToReceptor] = useState<Provider[]>();
    const [selectedProvidersToSecondAuthorizator, setSelectedProvidersToSecondAuthorizator] = useState<Provider[]>();
    const [selectedProvidersSpecificRule, setSelectedProvidersSpecificRule] = useState<Provider[]>();
    const [selectedProvidersAssignWorkflow, setSelectedProvidersAssingWorkflow] = useState<Provider[]>();
    const [classificationIds, setClassificationIds] = useState<string[]>([]);
    const [classificationNames, setClassificationNames] = useState<string[]>([]);
    const [receptorIds, setReceptorIds] = useState<string[]>([]);
    const [receptorNames, setReceptorNames] = useState<string[]>([]);
    const [specificRuleIds, setSpecificRuleIds] = useState<string[]>([]);
    const [specificRuleNames, setSpecificRuleNames] = useState<string[]>([]);
    const [workflowIds, setWorkflowIds] = useState<string[]>([]);
    const [workflowNames, setWorkflowNames] = useState<string[]>([]);
    const [openRemoveSpecificValidation, setOpenRemoveSpecificValidation] = useState<boolean>(false);
    const [openOneRemoveSpecificValidation, setOpenOneRemoveSpecificValidation] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isRestrictAssignReceptors, setRestrictAssignReceptors] = useState<boolean>(false);
    const [exportMode, setExportMode] = useState<ExportMode>();
    const [exportResult, setExportResult] = useState<ExportResponse>();

    const openProvidersReceptor = () => {
        setGridAnchorEl(null);
        if (!data) return;

        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            let selected = data.items.filter(provider => selectedProviderIds.indexOf(provider.id) >= 0);
            setSelectedProvidersToReceptor(selected);
        }
    }

    const openProvidersSecondAuthorizator = () => {
        setGridAnchorEl(null);
        if (!data) return;

        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            let selected = data.items.filter(provider => selectedProviderIds.indexOf(provider.id) >= 0);
            setSelectedProvidersToSecondAuthorizator(selected);
        }
    }

    const openOneProviderReceptor = () => {
        setAnchorEl(null);
        if (!provider) return;

        setSelectedProvidersToReceptor([provider]);
    }

    const openOneProviderSecondAuthorizator = () => {
        setAnchorEl(null);
        if (!provider) return;

        setSelectedProvidersToSecondAuthorizator([provider]);
    }

    const openProvidersSpecificRule = () => {
        setGridAnchorEl(null);
        if (!data) return;

        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            let selected = data.items.filter(provider => selectedProviderIds.indexOf(provider.id) >= 0);
            setSelectedProvidersSpecificRule(selected);
        }
    }

    const onOpenRemoveSpecificRule = () => {
        setGridAnchorEl(null);
        if (!data) return;

        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            setOpenRemoveSpecificValidation(true);
        }
    }

    const onCloseRemoveSpecificValidation = () => {
        setOpenRemoveSpecificValidation(false);
        setOpenOneRemoveSpecificValidation(false);
    }

    const removeSpecificValidation = () => {
        let request;
        if (openOneRemoveSpecificValidation) {
            request = {
                provider_ids: [provider?.id]
            } as ProviderAssignSpecificRuleRequest;
        } else {
            request = {
                provider_ids: selectedProviderIds
            } as ProviderAssignSpecificRuleRequest
        }
        setSubmitting(true)
        assignSpecialRule(context.session!.tenant!.id, request).then((response) => {
            load();
            if (response.updated === 1) {
                setSuccess(translate("providers.remove_specific_rule.success_one") as string);
            } else {
                setSuccess(translate("providers.remove_specific_rule.success_many", { "updated": response.updated }) as string);
            }

        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            onCloseRemoveSpecificValidation();
        });
    }

    const openProvidersWorkflow = () => {
        setGridAnchorEl(null);
        if (!data) return;

        if (selectedProviderIds.length === 0) {
            setWarning(translate("providers.no_providers") as string);
        } else {
            let providersSelected = data.items.filter(provider => selectedProviderIds.indexOf(provider.id) >= 0);

            var allMatch = true;
            if (providersSelected.length > 1 && !providersSelected.every(p => !p.workflows || p.workflows.length === 0)) {
                var referenceWorkflows = providersSelected.find(p => p.workflows && p.workflows.length > 0)!.workflows;
                allMatch = providersSelected.every(p => {
                    if (p.workflows && p.workflows.length === referenceWorkflows.length) {
                        if (p.workflows.every(id => referenceWorkflows.includes(id))
                            && referenceWorkflows.every(id => p.workflows.includes(id))) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            if (allMatch) {
                setSelectedProvidersAssingWorkflow(providersSelected);
            } else {
                setWarning(translate("providers.manage_workflows.mismatch") as string);
            }
        }
    }

    const openOneProviderSpecificRule = () => {
        setAnchorEl(null);
        if (!provider) return;

        setSelectedProvidersSpecificRule([provider]);
    }

    const openOneProviderRemoveSpecificRule = () => {
        setAnchorEl(null);
        if (!provider) return;
        setOpenOneRemoveSpecificValidation(true);
    }

    const openOneProviderWorkflow = () => {
        setAnchorEl(null);
        if (!provider) return;

        setSelectedProvidersAssingWorkflow([provider]);
    }

    const load = () => {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        setSelectedProviderIds([]);


        Promise.all([
            getProvidersWithReceptors(context.session!.tenant!.id, pageSize, offset, params),
            getTenantConfigurations(context.session!.tenant!.id)
        ]).then(([providersWithReceptors, tenantConfigurations]) => {

            const configuration = tenantConfigurations?.configurations?.find(cfg => cfg.property_name === "restrict_assign_receptors");
            setRestrictAssignReceptors(configuration !== undefined && configuration?.active && configuration.value === "true");

            setData(providersWithReceptors);
            setClassificationIds(providersWithReceptors.classifications ? providersWithReceptors.classifications.map((classification) => classification.id) : []);
            setClassificationNames(providersWithReceptors.classifications ? providersWithReceptors.classifications.map((classification) => classification.name) : []);
            setSpecificRuleIds(providersWithReceptors.specific_rules ? providersWithReceptors.specific_rules.map((specific_rule) => specific_rule.id) : []);
            setSpecificRuleNames(providersWithReceptors.specific_rules ? providersWithReceptors.specific_rules.map((specific_rule) => specific_rule.name) : []);
            setWorkflowIds(providersWithReceptors.workflows ? providersWithReceptors.workflows.map((workflow) => workflow.id) : []);
            setWorkflowNames(providersWithReceptors.workflows ? providersWithReceptors.workflows.map((workflow) => workflow.name) : []);
            getUsers(context.session!.tenant!.id, 0, 0, {
                role_list: ["receptor_cfdi", "cxp"].join(","),
                search: ""
            } as UsersQueryParamsReceptors).then((response) => {
                setReceptorIds(response.items.map((user) => user.id));
                setReceptorNames(response.items.map((user) => user.name));
            }).catch((error) => {
                setStatus(error.message);
            });

        }).catch(error => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params, props]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status"] = workingParams.status;
        qs["classification"] = workingParams.classification;
        qs["receptor"] = workingParams.receptor;
        qs["specific_rule"] = workingParams.specific_rule;
        qs["workflow_id"] = workingParams.workflow_id;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setProvider(undefined);
    };

    const onCloseProviderReceptor = () => {
        setGridAnchorEl(null);
        setSelectedProvidersToReceptor(undefined);
    };

    const onCloseProviderSecondAuthorizator = () => {
        setGridAnchorEl(null);
        setSelectedProvidersToSecondAuthorizator(undefined);
    };

    const onCloseProviderSpecificRule = () => {
        setGridAnchorEl(null);
        setSelectedProvidersSpecificRule(undefined);
    };

    const onCloseProvidersManageWorkflows = () => {
        setGridAnchorEl(null);
        setSelectedProvidersAssingWorkflow(undefined);
    };

    const onSuccessProviderReceptor = (updated: number) => {
        setGridAnchorEl(null);
        setSelectedProvidersToReceptor(undefined);
        setSuccess(translator("providers.receptor.success", { "updated": updated }) as string);
        load();
    };

    const onSuccessProviderSecondAuthorizator = (updated: number) => {
        setGridAnchorEl(null);
        setSelectedProvidersToSecondAuthorizator(undefined);
        setSuccess(translator("providers.second_authorizator.success", { "updated": updated }) as string);
        load();
    };

    const onSuccessProviderSpecificRule = (updated: number) => {
        setGridAnchorEl(null);
        setSelectedProvidersSpecificRule(undefined);
        if (updated === 1) {
            setSuccess(translator("providers.assign_specific_rule.success_one") as string);
        } else {
            setSuccess(translator("providers.assign_specific_rule.success_many", { "updated": updated }) as string);
        }
        load();
    };

    const onSuccessProvidersManageWorkflows = (updated: number) => {
        setGridAnchorEl(null);
        setSelectedProvidersAssingWorkflow(undefined);
        if (updated === 0) {
            return;
        }
        if (updated === 1) {
            setSuccess(translator("providers.assign_workflow.success_one") as string);
        } else if (updated > 1) {
            setSuccess(translator("providers.assign_workflow.success_many", { "updated": updated }) as string);
        }
        load();
    };

    const onClickedOptions = (provider: Provider) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setProvider(provider);
    };

    const handleCheck = (provider: Provider) => {
        let items = selectedProviderIds.length === 0 ? ([] as string[]) : selectedProviderIds.join(",").split(",");
        const index = selectedProviderIds.indexOf(provider.id);
        if (index < 0) {
            items.push(provider.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedProviderIds(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.items.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelectedProviderIds(items);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedRow = (provider: Provider) => {
        if (!isRestrictAssignReceptors && context.isGrantedAny(['AdminCFDIsUpdate', 'ProvidersUpdate', 'CxpProvidersUpdate'])) setSelectedProvidersToReceptor([provider]);
    };

    const onExportWithParams = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setExportMode('PARAMS');
        } else {
            setWarning(translate("providers.empty_export") as string);
        }
    }

    const onExportSelected = () => {
        setGridAnchorEl(null);
        if (selectedProviderIds.length) {
            setExportMode('SELECTED');
        } else {
            setWarning(translate("providers.no_providers") as string);
        }
    }

    const downloadProvidersWithConnector = (connectorId: string) => {
        if (!exportMode) return;

        let promise: Promise<ExportResponse>;
        if (exportMode === 'PARAMS') {
            promise = exportProviders(context.session!.tenant!.id, connectorId, params, true);
        } else {
            promise = exportSelectedProviders(context.session!.tenant!.id, connectorId, selectedProviderIds, true);
        }

        promise.then((response) => {
            if (response.url) {
                setExportResult(response);
            } else if (response.total) {
                setSuccess(translate("providers.email_export", { "total": response.total }) as string);
            } else {
                setWarning(translate("providers.empty_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(onCloseExporter);
    }

    const onCloseExporter = () => {
        setExportMode(undefined);
    };

    const onCloseDownloadProviders = () => {
        setExportResult(undefined);
    }

    return (
        <Pagination title={translate("providers.title")} icon={<ProvidersIcon />}
            subtitle={props.subtitle}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("ProvidersUpdate") ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>) : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <ValidatedInput type="text" id="search" name="search" label={translate("providers.filter_search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3} xl>
                        <ValidatedInput type="text" id="status" name="status" label={translate("providers.status.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.status} onValueChanged={onFilterChanged}
                            options={ProviderStatuses}
                            optionLabels={providerStatusLabels}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3} xl>
                        <ValidatedInput type="text" id="receptor" name="receptor" label={translate("providers.receptor.select") as string}
                            margin="dense" disabled={false}
                            value={workingParams.receptor} onValueChanged={onFilterChanged}
                            options={receptorIds}
                            optionLabels={receptorNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm md lg>
                        <ValidatedInput type="text" id="classification" name="classification" label={translate("providers.classifications.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.classification} onValueChanged={onFilterChanged}
                            options={classificationIds}
                            optionLabels={classificationNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm md lg>
                        <ValidatedInput type="text" id="specific_rule" name="specific_rule" label={translate("providers.assign_specific_rule.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.specific_rule} onValueChanged={onFilterChanged}
                            options={specificRuleIds}
                            optionLabels={specificRuleNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm md lg>
                        <ValidatedInput type="text" id="workflow_id" name="workflow_id" label={translate("workflows.single") as string}
                            margin="dense" disabled={false}
                            value={workingParams.workflow_id} onValueChanged={onFilterChanged}
                            options={workflowIds}
                            optionLabels={workflowNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("providers.empty") as string}
                onClick={onClickedRow}
                columns={props.getColumns(data ? data.items : [], showChecks, selectedProviderIds, {
                    onClickedOptions: onClickedOptions,
                    handleCheck: handleCheck,
                    handleAllChecks: handleAllChecks
                })} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {provider && anchorEl && (
                <ProviderReceptionMenu provider={provider}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onSelectReceptor={openOneProviderReceptor}
                    onSecondAuthorizator={openOneProviderSecondAuthorizator}
                    onSelectSpecificRule={openOneProviderSpecificRule}
                    onRemoveSpecificRule={openOneProviderRemoveSpecificRule}
                    onManageWorkflows={openOneProviderWorkflow}
                    isRestrictAssignReceptors={isRestrictAssignReceptors} />
            )}
            {selectedProvidersToReceptor && !isRestrictAssignReceptors &&(
                <ProviderReceptorsPopup
                    isSecondAuthorizator={false}
                    tenantId={context.session!.tenant!.id}
                    providers={selectedProvidersToReceptor}
                    onSuccess={onSuccessProviderReceptor}
                    onClose={onCloseProviderReceptor} />
            )}
            {selectedProvidersToSecondAuthorizator && !isRestrictAssignReceptors &&(
                <ProviderReceptorsPopup
                    isSecondAuthorizator={true}
                    tenantId={context.session!.tenant!.id}
                    providers={selectedProvidersToSecondAuthorizator}
                    onSuccess={onSuccessProviderSecondAuthorizator}
                    onClose={onCloseProviderSecondAuthorizator} />
            )}
            {selectedProvidersSpecificRule && (
                <ProviderAssignComboPopup
                    action="assign_specific_rule"
                    tenantId={context.session!.tenant!.id}
                    providers={selectedProvidersSpecificRule}
                    onSuccess={onSuccessProviderSpecificRule}
                    onClose={onCloseProviderSpecificRule} />
            )}
            {selectedProvidersAssignWorkflow && (
                <ProvidersWorkflowsManagePopup
                    providers={selectedProvidersAssignWorkflow}
                    onSuccess={onSuccessProvidersManageWorkflows}
                    onClose={onCloseProvidersManageWorkflows} />
            )}
            {gridAnchorEl && (
                <ProvidersReceptionMenu anchor={gridAnchorEl}
                    totalItems={data?.total}
                    onClose={() => setGridAnchorEl(null)}
                    onSelectReceptor={openProvidersReceptor}
                    onSecondAuthorizator={openProvidersSecondAuthorizator}
                    onSelectSpecificRule={openProvidersSpecificRule}
                    onRemoveSpecificRule={onOpenRemoveSpecificRule}
                    onManageWorkflows={openProvidersWorkflow}
                    onExportSelected={onExportSelected}
                    onExportWithParams={onExportWithParams}
                    isRestrictAssignReceptors={isRestrictAssignReceptors}
                />
            )}
            {exportMode && (
                <ConnectorObjectExporter tenantId={context.session!.tenant!.id} 
                    family={Family.PROVIDERS}
                    type= {"DATA"}
                    onExport={downloadProvidersWithConnector}
                    onClose={onCloseExporter}
                 />
            )}
            {exportResult && exportResult.url && (
                <ExportPopup title={translate("providers.download_providers") as string} 
                    message={translate("providers.providers_found", { "total": exportResult.total }) as string} 
                    url={exportResult.url} 
                    onClose={onCloseDownloadProviders} />
            )}
            {(openRemoveSpecificValidation || openOneRemoveSpecificValidation) && (
                <ConfirmationPopup
                    doAction={removeSpecificValidation}
                    onClose={onCloseRemoveSpecificValidation}
                    title={translate("providers.remove_specific_rule.title") as string}
                    message={translate("providers.remove_specific_rule.message") as string}
                    button={translate("providers.remove_specific_rule.button") as string}
                    submitting={submitting} />
            )}
        </Pagination>
    )

}