import React from "react";
import { Tooltip } from "@material-ui/core";
import { Owner } from "../model/Cfdi";
import ReactHtmlParser from "html-react-parser";
import { Users } from "../components/Icons";
import translate from "../i18n/Translator";

interface OwnersTooltipProps {
    owners: Owner[];
}

function getRoleTranslation(role: string) {
    let translated = translate(`users.roles.${role}`) as string;
    return translated !== `users.roles.${role}` ?  translated : translate(`requisitions.teams.role.${role}`) as string;
}

function getActiveOwners(owners: Owner[]) {
    let active = owners.filter(o => o.active);
    let users = active.filter(o => !!o.user_name).map(o => o.user_name).join("<br/>");
    let roles = active.filter(o => !!o.role).map(o => getRoleTranslation(o.role)).join("<br/>");
    return [users, roles].filter(i => !!i).join("<br/>") ?? "";
}

export default function OwnersTooltip(props: OwnersTooltipProps) {
    return (
        <Tooltip title={ReactHtmlParser(getActiveOwners(props.owners))}>
            <Users color="disabled" />
        </Tooltip>
    );
}