import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BankIcon from "@material-ui/icons/AccountBalance";
import AddIcon from "@material-ui/icons/Add";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { list, update } from "../api/PaymentConditionsAPI";
import { PaymentConditionsResponse as PaymentConditions, PaymentCondition, PaymentConditionListParams, PaymentConditionRequest } from "../model/TenantConfiguration";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";
import PaymentConditionMenu from "./PaymentConditionMenu";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import ConfirmationPopup from "../components/ConfirmationPopup";
import DefinePartiallitiesPopUp from "../payment_conditions/DefinePartiallitiesPopUp";

import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";

export default function PaymentConditionsView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const [tenantId] = useState<string>(context.session?.tenant?.id ?? match.params.tenantId);
    const paramsFromQueryString = (): PaymentConditionListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "active": typeof qs["active"] === "string" ? qs["active"] as string : "",
            "tenant_id": tenantId ?? "",
        } as PaymentConditionListParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<PaymentConditions>();
    const [params, setParams] = useState<PaymentConditionListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<PaymentConditionListParams>(paramsFromQueryString);
    const [inactivatePopup, setInactivatePopup] = useState<boolean>(false);
    const [definePartiallities, setDefinePartiallities] = useState<boolean>(false);

    const [paymentCondition, setPaymentCondition] = useState<PaymentCondition>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [redirect, setRedirect] = useState<string>();

    const load = () => {
        setStatus('loading');
        let offset = getOffset(page, pageSize);
        list(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
            if (match.params.success) {
                setSuccess(match.params.success);
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onInactivate = () => {
        if (!paymentCondition) {
            return;
        }
        let request = { name: paymentCondition.name, installments: paymentCondition.installments, status: paymentCondition.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" } as PaymentConditionRequest;
        update(tenantId, paymentCondition.id, request).then((response) => {
            setSuccess(translate("requisitions.form.quotation.payment_condition." + (response.status === "ACTIVE" ? "success_activate" : "success_inactivate")) as string);
            let params = { ...workingParams, search: "" } as PaymentConditionListParams;
            setParams(params);
        }).catch((error) => {
            setStatus(error.message);
        });
        setInactivatePopup(false);
        setPaymentCondition(undefined);
    };

    const onClickedOptions = (item: PaymentCondition) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPaymentCondition(item);
    };

    const showDefinePartiallities = () => {
        setDefinePartiallities(true);
    };
    const onCreatePaymentCondition = (noPartiallities: string) => {
        setRedirect(`/payment-conditions/create/${noPartiallities}`);
    };

    const closeDefinePartiallities = () => {
        setDefinePartiallities(false);
    };

    const showInactivatePopup = () => {
        setAnchorEl(null);
        setInactivatePopup(true);
    };

    const onCloseInactivatePopup = () => {
        setAnchorEl(null);
        setInactivatePopup(false);
        setPaymentCondition(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPaymentCondition(undefined);
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Pagination title={translate("requisitions.form.quotation.payment_condition_id")}
            icon={<BankIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["PaymentConditionsCreate"]) ?
                            (<Grid item xs="auto">
                                <Fab onClick={showDefinePartiallities} color="primary" size="small" title={translate("buttons.add") as string}>
                                    <AddIcon />
                                </Fab>
                            </Grid>) : undefined}
                    </Grid>
                </Grid>} >
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} md>
                        <ValidatedInput type="text" id="search" name="search" label={translate("requisitions.form.quotation.payment_condition.search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("requisitions.teams.empty") as string}
                columns={[
                    {
                        title: translate("requisitions.form.quotation.payment_condition.columns.description") as string,
                        converter: (item) => (<Ellipsis text={`${item.name}`} lenght={0} uppercased={false} />),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("requisitions.form.quotation.payment_condition.columns.partiallities") as string,
                        converter: (item) => item.installments.length,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("requisitions.form.quotation.payment_condition.columns.payment_distribution") as string,
                        converter: (item) => (
                            <div>
                                <Ellipsis text={`${item.installments.map(c => c.percentage).join("% + ")}%`} lenght={0} uppercased={false} />
                            </div>
                        ),
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("requisitions.form.quotation.payment_condition.columns.status") as string,
                        converter: (item) => translate("requisitions.form.quotation.payment_condition.statuses." + item.status) as string,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                        ),
                        converter: (team) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(team)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-start",
                        xs: "auto",
                    }
                ]} />
            {paymentCondition && anchorEl &&
                <PaymentConditionMenu
                    active={paymentCondition.status === "ACTIVE"}
                    urlPaymentConditionMenu={`/payment-condition/edit/${paymentCondition.id}`}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onInactivated={showInactivatePopup} />
            }
            {paymentCondition && inactivatePopup && (
                <ConfirmationPopup
                    doAction={onInactivate}
                    onClose={onCloseInactivatePopup}
                    title={translate("requisitions.form.quotation.payment_condition." + (paymentCondition.status === "ACTIVE" ? "inactivate_title" : "activate_title")) as string}
                    message={translate("requisitions.form.quotation.payment_condition." + (paymentCondition.status === "ACTIVE" ? "inactivate_message" : "activate_message"), { "description": paymentCondition.name }) as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {definePartiallities &&
                <DefinePartiallitiesPopUp
                    onUpdate={onCreatePaymentCondition}
                    onClose={closeDefinePartiallities}
                />
            }
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Pagination>
    );
}