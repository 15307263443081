import React, { useContext, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import { ErrorSnackbar } from "../components/Snackbars";
import { GTZeroValidator } from "../components/Validators";
import { Cfdi } from "../model/Cfdi";
import { ExpensesAccountDocumentRequest } from "../model/ExpensesAccount";
import { addDocument } from "../api/ExpensesAccountAPI";
import { AppContext } from "../context/AppContext";

interface NewExpensesAccountDocumentPopup {
    payableDocument: Cfdi;
    onSuccess(payableDocument: Cfdi): any;
    onCancel(): any;
}

export default function NewExpensesAccountDocumentPopup(props: NewExpensesAccountDocumentPopup) {
    const context = useContext(AppContext);
    const gtZeroValidator = new GTZeroValidator();
    const margin = "dense";
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<ExpensesAccountDocumentRequest>({
        date: new Date()
    } as ExpensesAccountDocumentRequest);
    const [validations, setValidations] = useState({} as any);

    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest({ ...request, date: date });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = () => {
        if (!isValid()) return;

        setSubmitting(true);
        addDocument(context.session!.tenant!.id, context.session!.provider!.id, props.payableDocument.id, request).then((payableDocument: Cfdi) => {
            props.onSuccess(payableDocument);
        }).catch(error => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    return (
        <DialogPopup open title={translate("expenses_accounts.documents_form")}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            maxWidth="sm"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancel}
            closeColor="default"
            button={
                <Button onClick={onSubmit} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.add")}
                </Button>
            }>
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput id="issuer" name="issuer" type="text"
                        label={translate("expenses_accounts.issuer") as string}
                        value={request.issuer} disabled={submitting}
                        margin={margin} required
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker label={translate("expenses_accounts.date") as string} name="date"
                        onChange={onChangedDate} clearable={false} disabled={submitting} required
                        format="YYYY-MM-DD" initial={request.date ? moment(request.date).format("YYYY-MM-DD") : undefined} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="description" name="description" type="text"
                        label={translate("expenses_accounts.description") as string}
                        value={request.description} disabled={submitting}
                        rows={3} maxRows={3}
                        margin={margin} required maxLength={500}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="total" name="total" type="number"
                        label={translate("expenses_accounts.total") as string}
                        value={request.total ? request.total.toString() : ""}
                        required disabled={submitting}
                        margin={margin} validator={gtZeroValidator}
                        onValueChanged={onChanged} />
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput id="url" name="url"
                        label={translate("expenses_accounts.file") as string}
                        value={request.url} disabled={submitting}
                        margin={margin} path="file" acceptExtension=".pdf"
                        onValueChanged={onChanged} />
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );
}