import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, IconButton, TextField } from '@material-ui/core';

import translate from "../i18n/Translator";
import { TenantField, FieldTypes } from "../model/TenantFieldCatalog";
import { Expiration, EXPIRATION_UNITS } from "../model/Expiration";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import SimpleSwitch from "../components/SimpleSwitch";
import { IntegerValidator, GTZeroValidator, RemoveSpacesValidator } from "../components/Validators";
import Progress from "../components/Progress";
import UploaderInput from "../components/UploaderInput";
import HelpIcon from '@material-ui/icons/Help';
import { listCatalogs } from "../api/CatalogAPI";
import { AppContext } from '../context/AppContext';
import { Catalogs } from '../model/Catalog';
import ConfirmPopup from "../components/ConfirmationPopup";
import { UserGroup } from "../model/UserGroup";
import { getUserGroups } from "../api/UserGroupAPI";
import { Autocomplete } from "@material-ui/lab";

interface ExpedientCatalogFieldFormProps {
    field?: TenantField;
    index?: number;
    onCompleted(field: TenantField, index?: number): any;
    onClose(): any;
    disabled: boolean;
    onError(error: string): any;
}

export default function ExpedientCatalogFieldForm(props: ExpedientCatalogFieldFormProps) {

    const context = useContext(AppContext);
    const isEdit = !!props.field;
    const margin = "dense";
    const intergerValidator = new IntegerValidator(true);
    const gtZeroValidator = new GTZeroValidator();
    const removeSpacesValidator = new RemoveSpacesValidator();
    const typeLables = FieldTypes.map(
        (type) => translate(`expedients.fields.type.${type}`) as string
    );
    const expirationUnitLabels = EXPIRATION_UNITS.map(
        (type) => translate(`expedients.fields.expirationUnit.${type}`) as string
    );
    const [request, setRequest] = useState<TenantField>({} as TenantField);
    const [validations, setValidations] = useState({
        type: {
            valid: false,
        } as InputRef,
        name: {
            valid: false,
        } as InputRef,
        description: {
            valid: false,
        } as InputRef,
        extensions: {
            valid: false,
        } as InputRef,
    } as any);

    const [showExpirationOptions, setShowExpirationOptions] = useState<boolean>(false);
    const [showUserGroups, setShowUserGroups] = useState<boolean>(false);
    const [showAuxFileOptions, setShowAuxFileOptions] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [catalogs, setCatalogs] = useState<Catalogs>();
    const [userGroups, setUserGroups] = useState<UserGroup[]>();
    const [userGroupSelected, setUserGroupSelected] = useState<UserGroup>();
    const [openConfirmChange, setOpenConfirmChange] = useState<boolean>(false);
    
    useEffect(() => {
        Promise.all([
            listCatalogs(context.session!.tenant!.id, 0, 0, { search: "" }),
            getUserGroups(context.session!.tenant!.id, 0, 0, {search : ""})    
        ]).then(responses => {
            let catalogs = responses[0];
            setCatalogs(catalogs);
            if (props.field) {
                setRequest({
                    id: props.field.id,
                    name: props.field.name,
                    description: props.field.description,
                    external_id: props.field.external_id,
                    type: props.field.type,
                    required: props.field.required,
                    extensions: props.field.extensions,
                    expiration: props.field.expiration ? {
                        unit: props.field.expiration.unit,
                        quantity: props.field.expiration.quantity
                    } as Expiration : undefined, 
                    aux_file_name: props.field.aux_file_name,
                    aux_file_url: props.field.aux_file_url,
                    privado: props.field.privado,
                    use_in_cfdi: props.field.use_in_cfdi,
                    catalog_id: props.field.catalog_id,
                    user_group_id: props.field.user_group_id
                } as TenantField);
                setShowExpirationOptions(!!props.field.expiration);
                setShowAuxFileOptions(!!props.field.aux_file_name ? true : false);
                setShowUserGroups(!!props.field.user_group_id);
                if(props.field && props.field.user_group_id) {
                    setUserGroupSelected(responses[1].items.find(group => props.field!.user_group_id));
                }
            } else {
                setRequest({
                    name: "",
                    type: "TEXT",
                    required: false,
                    privado: false,
                } as TenantField)
            }
            setUserGroups(responses[1].items);
        }).catch(error => props.onError(error.message));
    }, [props.field]);

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref?.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    };

    const hasChanged = (name: string, value: string, inputRef?: InputRef) => {
        setDisabled(false);
        let parts = name.split(".");
        if (parts.length === 1) {
            setRequest({ ...request, [name]: value });
        } else {
            let tmp = request as any;
            if (typeof tmp[parts[0]] !== "undefined") {
                tmp[parts[0]][parts[1]] = value;
            }
            setRequest(tmp as TenantField);
        }

        if (inputRef) {
            validations[name] = inputRef;
            setValidations(validations);
        }
    };

    const onClickFile = () => {
        setDisabled(true);
    };

    const hasChangedSwitch = (name: string, checked: boolean) => {
        setRequest({ ...request, [name]: checked });
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            if(showUserGroups && !request.user_group_id){
                props.onError(translate('expedients.without_special_validators') as string);
            }
            return;
        }

        if(props.field){
            if(isExpirationChanged()){
                setOpenConfirmChange(true);
                return;
            }
        }

        props.onCompleted(request, props.index);
    };

    const isExpirationChanged = () => {
        if(props.field?.expiration && request.expiration){
            return areChangeExpirationValues();
        }
        if(!props.field?.expiration && !request.expiration){
            return false;
        }

        return true;
    }

    const areChangeExpirationValues = () => {
        if(props.field?.expiration?.unit !== request.expiration?.unit){
            return true;
        }
        if(`${(props.field?.expiration?.quantity || 0)}` !== `${(request.expiration?.quantity || 0)}`){
            return true;
        }
        if(`${(props.field?.expiration?.day || 0)}` !== `${(request.expiration?.day || 0)}`){
            return true;
        }

        return `${(props.field?.expiration?.initial_month || 0)}` !== `${(request.expiration?.initial_month || 0)}`;
    }

    const onConfirm = () => {
        props.onCompleted(request, props.index);
        setOpenConfirmChange(false);
    }

    const handleExpirationOptions = (name: string, checked: boolean) => {
        if (checked) {
            setRequest({
                ...request, expiration: {
                    unit: "MONTH",
                    quantity: 1,
                    day: 1,
                    initial_month: 1
                }
            });
        } else {
            let tmp = request as any;
            delete tmp.expiration;
            setRequest(tmp as TenantField);
        }
        setShowExpirationOptions(checked)
    };

    const hangleUserGroups = (name: string, checked: boolean) => {
        if (!checked) {
            delete validations["user_group"];
            setValidations(validations);
            let tmp = request as any;
            delete tmp.user_group_id;
            setRequest(tmp as TenantField);
        }
        setShowUserGroups(checked);
    };

    const handleAuxFileOptions = (name: string, checked: boolean) => {

        setShowAuxFileOptions(checked)
        let tmp = request as any;
        tmp.aux_file_name = "";
        tmp.aux_file_url = "";
        tmp.aux_file_required = checked;
        setRequest(tmp as TenantField);

    };

    const onChangedUserGroup = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, user_group_id: value != "" ? value: undefined });
        validations[name] = inputRef;
        setValidations(validations);
        if(value != "") {
            setUserGroupSelected(userGroups?.find(group => group.id == value));
        }
    };

    if (!request) {
        return (<Progress />);
    }

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick={props.disabled}
            disableEscapeKeyDown={props.disabled}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate(isEdit ? "expedients.fields.edit" : "expedients.fields.add") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="type"
                            name="type"
                            value={request.type}
                            label={translate("expedients.fields.type") as string}
                            required={true}
                            disabled={false}
                            margin={margin}
                            options={FieldTypes}
                            optionLabels={typeLables}
                            onValueChanged={hasChanged}
                        />

                        <ValidatedInput
                            type="text"
                            id="catalog_id"
                            name="catalog_id"
                            emptyOption={translate("catalogs.values.empty") as string}
                            label={translate("catalogs.single") as string}
                            required={request.type === "CATALOG"} disabled={request.type !== "CATALOG"}
                            margin="dense"
                            onValueChanged={hasChanged}
                            value={request.catalog_id}
                            autocompleteOptions={catalogs?.items ?? []}
                            autocompleteValue={request.type === "CATALOG" ? catalogs?.items.find(e => e.id === request.catalog_id) : undefined}
                            autocompleteKey={request.type === "CATALOG" ? catalogs?.items.find(e => e.id === request.catalog_id)?.id : undefined}
                            getId={(el) => el?.id}
                            getLabel={(el) => el?.name}
                        />

                        <ValidatedInput
                            type="text"
                            id="name"
                            name="name"
                            value={request.name}
                            label={translate("expedients.fields.name") as string}
                            required={true}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                            maxLength={70}
                        />
                        <ValidatedInput
                            type="text"
                            id="description"
                            name="description"
                            value={request.description}
                            label={translate("expedients.fields.description") as string}
                            required={false}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput 
                            type="text"
                            id="external_id"
                            name="external_id"
                            value={request.external_id}
                            label={translate("expedients.fields.external_id") as string}
                            required={false}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                            maxLength={50}
                            validator={removeSpacesValidator}
                        />
                        <Grid container direction="row">
                            <Grid item xs={11}><ValidatedInput
                                type="text"
                                id="extensions"
                                name="extensions"
                                value={request.extensions}
                                label={translate("expedients.fields.extensions") as string}
                                required={request.type === "FILE"}
                                disabled={request.type !== "FILE"}
                                margin={margin}
                                onValueChanged={hasChanged}
                            /></Grid>
                            {request.type === "FILE" ?
                                <Grid item xs={1}>
                                    <IconButton aria-label="options"
                                        color="default"
                                        size="small" title={translate("buttons.extensions_accept") as string} >
                                        <HelpIcon fontSize="small" />
                                    </IconButton>
                                </Grid> : undefined

                            }
                        </Grid>
                        <SimpleSwitch
                            value="required"
                            label={translate("expedients.required") as string}
                            checked={!!request.required}
                            onChanged={hasChangedSwitch}
                            placement="end" />
                        <SimpleSwitch
                            value="add-special-validators"
                            label={translate("expedients.add_special_validators") as string}
                            checked={showUserGroups}
                            onChanged={hangleUserGroups}
                            placement="end" />
                        {showUserGroups &&
                        <ValidatedInput
                            type="text"
                            id="user_group" name="user_group"
                            label={translate("expedients.user_group") as string}
                            value={request.user_group_id}
                            required
                            margin={"dense"}
                            autocompleteOptions={userGroups}
                            autocompleteValue={userGroups?.find(group => group.id === request.user_group_id )}
                            autocompleteKey={userGroups?.find(group => group.id === request.user_group_id )?.id}
                            getId={group => group?.id}
                            getLabel={group => group ? group.name : ""}
                            onValueChanged={onChangedUserGroup} />
                        }
                        <SimpleSwitch
                            value="expiration"
                            label={translate("expedients.fields.expirationUnit.title") as string}
                            checked={showExpirationOptions}
                            onChanged={handleExpirationOptions}
                            placement="end" />
                        <ValidatedInput
                            type="text"
                            id="expirationUnit"
                            name="expiration.unit"
                            value={request.expiration ? request.expiration.unit : ""}
                            label={translate("expedients.fields.expirationUnit.unit") as string}
                            required={showExpirationOptions}
                            disabled={!showExpirationOptions}
                            margin={margin}
                            options={EXPIRATION_UNITS}
                            optionLabels={expirationUnitLabels}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="number"
                            id="expirationQuantity"
                            name="expiration.quantity"
                            value={(request.expiration ? request.expiration.quantity : 0).toString()}
                            label={
                                translate("expedients.fields.expirationUnit.quantity") as string
                            }
                            required={showExpirationOptions}
                            disabled={!showExpirationOptions}
                            validators={showExpirationOptions ? [intergerValidator, gtZeroValidator] : undefined}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <SimpleSwitch
                            value="show_aux_fields"
                            label={translate("expedients.fields.aux_file.add") as string}
                            checked={showAuxFileOptions}
                            onChanged={handleAuxFileOptions}
                            placement="end" />
                        <ValidatedInput
                            type="text"
                            id="aux_file_name"
                            name="aux_file_name"
                            value={request.aux_file_name}
                            label={translate("expedients.fields.aux_file.description") as string}
                            required={showAuxFileOptions}
                            disabled={!showAuxFileOptions}
                            margin={margin}
                            onValueChanged={hasChanged}
                            maxLength={40}
                        />
                        <UploaderInput
                            id="aux_file_url"
                            name="aux_file_url"
                            value={request.aux_file_url}
                            label={translate("expedients.fields.aux_file.upload") as string}
                            required={showAuxFileOptions}
                            disabled={!showAuxFileOptions}
                            margin={margin}
                            onValueChanged={hasChanged}
                            onClick={onClickFile}
                            tooltip={translate("expedients.fields.aux_file.tooltip") as string}
                        />
                        <SimpleSwitch
                            value="privado"
                            label={translate("expedients.private") as string}
                            checked={!!request.privado}
                            onChanged={hasChangedSwitch}
                            placement="end" />
                        {request.type === "FILE" && request.required &&
                            <SimpleSwitch
                                value="use_in_cfdi"
                                label={translate("expedients.use_in_cfdi") as string}
                                checked={request.use_in_cfdi}
                                onChanged={hasChangedSwitch}
                                placement="end" />
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={props.disabled || disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={props.disabled || disabled}>
                        {translate(isEdit ? "buttons.update" : "buttons.add")}
                    </Button>
                </DialogActions>
            </form>
            { openConfirmChange &&
                <ConfirmPopup
                doAction={onConfirm}
                onClose={() => { setOpenConfirmChange(false)}}
                title={translate("cfdis.popup.confirm_dialog_expiration.title") as string}
                message={translate("cfdis.popup.confirm_dialog_expiration.message") as string}
                button={translate("buttons.accept") as string} />
            }
        </Dialog>
    );
}
