import { ApiError } from "../model/ApiError";
import { RefreshToken } from "../model/Auth";
import translate from "../i18n/Translator";

const securityApiUrl = `${process.env.REACT_APP_API_URL}/security/1.0`;
const internApiUrl = `${process.env.REACT_APP_API_URL}/1.0`;
const AuthHeaderName = process.env.REACT_APP_API_AUTH_HEADER_NAME ?? "---HEADER--NAME---";

export enum HTTPMethod { GET = "GET", POST = "POST", DELETE = "DELETE", PUT = "PUT", PATCH = "PATCH" }

export interface APIQueryString {
    query?: Record<string, string>;
}

export interface APIParameters extends APIQueryString {
    method: HTTPMethod;
    body?: object | FormData;
    multipart?: boolean;
    headerParams?: Record<string, string>
}

export async function callSecurityAPI<T>(path: string, parameters: APIParameters): Promise<T> {
    return callBaseUrlAPI(securityApiUrl, path, parameters, true);
}

export async function callAPI<T>(path: string, parameters: APIParameters, withoutToken?: boolean): Promise<T> {
    return callBaseUrlAPI(internApiUrl, path, parameters, withoutToken);
}

async function callBaseUrlAPI<T>(baseUrl: string, path: string, parameters: APIParameters, withoutToken?: boolean): Promise<T> {
    if (!path.startsWith("/")) {
        path = "/" + path;
    }

    let headers = new Headers();
    headers.append("Accept", "application/json");

    if (!withoutToken) {
        let storedJwt = await getValidJwt();
        if (storedJwt) {
            headers.append("Authorization", `Bearer ${storedJwt}`);
        }
    }

    if (!parameters.multipart) {
        headers.append("Content-Type", "application/json");
    }

    if (parameters.headerParams) {
        for (const header in parameters.headerParams) {
            headers.append(header, parameters.headerParams[header]);
        }
    }

    let requestOptions: RequestInit = {
        method: parameters.method,
        headers: headers,
        credentials: "include",
        mode: "cors",
    };

    if (parameters.body) {
        if (parameters.multipart && parameters.body as FormData) {
            requestOptions.body = parameters.body as FormData;
        } else {
            requestOptions.body = JSON.stringify(parameters.body);
        }
    }

    if (parameters.query) {
        path += "?" + encodeGetParams(parameters.query);
    }

    const response = await fetch(baseUrl + path, requestOptions);
    let jwt = response.headers.get(AuthHeaderName);
    if (jwt) {
        writeJWT(jwt);
    }

    if (response.status === 204) {
        return await noContent();
    }

    if (response.ok) {
        return await response.json();
    }

    const errorResponse = await response.json();
    if (errorResponse) {
        let error = {} as ErrorPdp;
        error.code = errorResponse.code;
        error.message = getErrorMesssage(errorResponse);
        throw error;
    }
    throw new Error(await response.text());
}

export async function getAuthenticatedGetUrl(path: string, qs?: APIQueryString) {
    let jwt = await getValidJwt() || "";
    if (!path.startsWith("/")) {
        path = "/" + path;
    }

    var parameters = qs?.query;
    if (!parameters) {
        parameters = {} as Record<string, string>;
    }

    parameters["auth"] = jwt;

    return `${internApiUrl}${path}?${encodeGetParams(parameters)}`;
}

export async function noContent<T>(): Promise<T> {
    return {} as T;
}

export async function emptyPromise(): Promise<undefined> {
    return undefined
}

function getErrorMesssage(apiError: ApiError): string {
    if (apiError.other === "openpay") {
        return translate(`openpay_errors.${apiError.code}`) as string;
    } else if (apiError.other === "lambdas") {
        return apiError.message || apiError.description;
    }
    return translate(`errors.codes.${apiError.code}`, buildParams(apiError.code_params)) as string;
}

function buildParams(params: string[]): any {
    let result = {} as any;
    if (params) {
        params.forEach((p, i) => {
            result = { ...result, [i]: p };
        });
    }
    return result;
}

export function hasJwt() {
    return getJWT() !== null;
}

async function getValidJwt(): Promise<string | null> {
    let jwt = getJWT();
    if (!jwt) {
        return null;
    }

    try {
        let content = JSON.parse(atob(jwt.split(".")[1]));
        let expiration = (typeof content.exp === "number" ? content.exp as number : 0) * 1000;
        if (expiration > Date.now()) {
            return jwt;
        }
    } catch (error) {
        console.log("Can\"t parse JWT", error);
    }

    try {
        let response = await refreshJwt(jwt);
        writeJWT(response.jwt);
        return response.jwt;
    } catch (error) {
        console.log("Unable to refresh JWT token", error);
        removeJWT();
        return null;
    };
}

async function refreshJwt(jwt: string): Promise<RefreshToken> {
    let request = {
        "jwt": jwt
    } as RefreshToken;

    return await callAPI("/auth/tokens/refresh", {
        method: HTTPMethod.PUT,
        body: request,
    }, true);
}

function getJWT(): string | null {
    return localStorage.getItem(AuthHeaderName);
}

function writeJWT(jwt: string) {
    localStorage.setItem(AuthHeaderName, jwt);
}

export function removeJWT() {
    localStorage.removeItem(AuthHeaderName);
}

export function encodeGetParams(p: object): string {
    return Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
}

export interface ErrorPdp extends Error {
    code: string;
}