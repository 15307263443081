import React, { useState, useEffect } from "react";
import { Divider, Button, Checkbox, FormControl, FormGroup, FormControlLabel, Grid } from "@material-ui/core";
import ValidatedInput from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar } from "../components/Snackbars";
import { Entity } from "../model/Provider";

import translate from "../i18n/Translator";
import { updateClassifications } from "../api/ProviderAPI";
import { ProviderUpdateClassificationsRequest } from "../model/Provider";
import { ProviderClassification } from "../model/ProviderClassification";
import { createProviderClassification } from "../api/ProviderClassificationAPI";
import { ProviderClassificationRequest } from "../model/ProviderClassification";

interface ProviderClassificationsPopupProps {
    tenantId: string;
    providers: Entity[];
    classifications?: ProviderClassification[];
    onSuccess(update: number): any;
    onClose(): any;
}

export default function ProviderClassificationsPopup(props: ProviderClassificationsPopupProps) {
    const [error, setError] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [newClassification, setNewClassification] = useState<string>();
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState<any>({} as any);
    const [request, setRequest] = useState<ProviderUpdateClassificationsRequest>({} as ProviderUpdateClassificationsRequest);

    useEffect(() => {
        let tmp = {} as any;
        if (props.classifications) {
            props.classifications.forEach(classification => {
                tmp[classification.id] = [];
            });

            props.providers.filter(provider => provider.provider_classifications !== undefined).forEach(provider => {
                provider.provider_classifications!.forEach(classification => {
                    if (classification.id in tmp) {
                        (tmp[classification.id] as string[]).push(provider.id);
                    }
                });
            });
        }

        setData(tmp);
        setRequest({
            append: [],
            remove: [],
            providers: props.providers.map(provider => provider.id)
        } as ProviderUpdateClassificationsRequest);
    }, [props.providers, props.classifications]);

    const onChanged = (classificationId: string, checked: boolean) => {
        if (checked) {
            request.append.push(classificationId);

            let i = request.remove.findIndex(item => item === classificationId);
            if (i >= 0) {
                request.remove.splice(i, 1);
            }

            (data as any)[classificationId] = request.providers;
        } else {
            request.remove.push(classificationId);
            let i = request.append.findIndex(item => item === classificationId);
            if (i >= 0) {
                request.append.splice(i, 1);
            }
            (data as any)[classificationId] = [];
        }

        setRequest(request);
        setData(data);
        setDisabled(false);
    };

    const addClassification = () => {
        if (!newClassification) return;
        createProviderClassification(props.tenantId, { name: newClassification } as ProviderClassificationRequest).then((response) => {
            props.classifications?.push(response);
            if (!data[response.id]) {
                data[response.id] = [];
                onChanged(response.id, true);
                setDisabled(false);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setNewClassification("");
        });
    };

    const onAddClassifications = () => {
        updateClassifications(props.tenantId, request).then((response) => {
            props.onSuccess(response.updated);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const hasChanged = (name: string, value: string) => {
        setNewClassification(value);
    };

    return (
        <DialogPopup open
            title={translate("providers.classifications.title")}
            onClose={props.onClose}
            disable={submitting}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}
            button={(
                <Button onClick={onAddClassifications} variant="contained" color="primary" size="medium" disabled={disabled || submitting}>
                    {translate("buttons.sort_provider")}
                </Button>
            )}>
            <Grid container spacing={1}>
                {Object.keys(data).length > 0 && (
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {Object.keys(data).map((classificationId: string) => (
                                    <ClassificationCheck key={classificationId}
                                        classificationId={classificationId}
                                        classificationName={props.classifications?.find((c) => c.id === classificationId)?.name}
                                        selected={data[classificationId] as string[]}
                                        all={props.providers}
                                        onChange={onChanged} />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
                {Object.keys(data).length > 0 && (
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item xs>
                            <ValidatedInput
                                type="text" id="newClassification" name="newClassification"
                                label={translate("providers.classifications.new") as string}
                                value={newClassification}
                                required={false} disabled={submitting}
                                margin="dense"
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs="auto">
                            <Button size="small" variant="text" color="primary" onClick={addClassification}>
                                {translate("buttons.add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}

interface ClassificationCheckProps {
    classificationId: string;
    classificationName?: string;
    selected: string[];
    all: any[];
    onChange(classificationId: string, checked: boolean): any;
}

function ClassificationCheck(props: ClassificationCheckProps) {
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    useEffect(() => {
        setChecked(props.selected.length !== 0 && props.selected.length === props.all.length);
        setIndeterminate(props.selected.length !== 0 && props.selected.length !== props.all.length);
    }, [props.classificationId, props.selected, props.all]);

    const handleSelection = (event: React.ChangeEvent<{}>, checked: boolean) => {
        props.onChange(props.classificationId, checked);
        setChecked(checked);
        setIndeterminate(false);
    };

    return (
        <FormControlLabel
            control={(
                <Checkbox
                    id={props.classificationId}
                    name={props.classificationName}
                    value={props.classificationId}
                    onChange={handleSelection}
                    indeterminate={indeterminate}
                    checked={checked} />
            )}
            label={props.classificationName}
        />
    );
}