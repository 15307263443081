import React from "react";
import { Typography, Chip } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { User } from "../model/User";

interface UserActivatePopupProps {
    user: User;
    onClose():any;
}

export default function UserActivatePopup(props: UserActivatePopupProps) {
    return (
        <DialogPopup title={translate("users.activation.title")} open onClose={props.onClose}>
            <Typography variant="body2" paragraph>
                {translate("users.activation.content", { "first_name": props.user.first_name, "email": props.user.email })}
            </Typography>
            <Chip icon={<LinkIcon />}
                label={`${process.env.REACT_APP_ROOT_URL}/signup/${props.user.signup_code ?? "invalid_code"}/password`}
                color="primary"
                variant="outlined"
            />
        </DialogPopup>
    );
}