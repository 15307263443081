import React, { useEffect, useState} from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../../i18n/Translator";
import DialogPopup from "../../components/DialogPopup";
import DatePicker from "../../components/DatePicker";
import moment from "moment";

interface UpdatePaymentDeadlinePopupProps {
    id: string;
    dateInitial: Date;
    onConfirm(newPaymentDeadline? : Date): any;
    onCancelled(): any;
}

export default function UpdatePaymentDeadlinePopup(props: UpdatePaymentDeadlinePopupProps) {
    const [paymentDeadline, setPaymentDeadline] = useState<Date>();
    const [disabled, setDisabled] = useState<boolean>(true);    

    const onChangedDate = (name: string, raw?: string, date?: Date) =>{
        if(!date) return;
        setPaymentDeadline(date);
        setDisabled(false);
    }

    const onUpdate = () =>{
        props.onConfirm(paymentDeadline);
    }
    useEffect(() => {
        let result = new Date();
        result.setDate(result.getDate() + 1);
        setPaymentDeadline(result);
    }, [props]);


    return (
        <DialogPopup open
            title={translate("cfdis.update_payment_deadline.title") as string}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onUpdate} variant="contained" color="primary" disabled={disabled} >
                    {translate("buttons.update")}
                </Button>
            }
        >
            <Typography variant="body2">
            {translate("cfdis.update_payment_deadline.description")}
            </Typography>
            <br />
            <DatePicker
                        label={translate("cfdis.update_payment_deadline.label") as string}
                        name={props.id}
                        initial={moment(props.dateInitial).format("DD/MM/YYYY")}
                        onChange={onChangedDate}
                        defaultIfMissing={false}
                        clearable={false} autoOK
                        disablePast={false}
                        disableFuture={false}/>
        </DialogPopup>
    );

}