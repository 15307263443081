export interface Efos {
    id: string; // RFC
    name: string;
    status: string;
    published: Date;
    presunto?: Situation;
    definitivo?: Situation;
    desvirtuado?: Situation;
    sentencia_favorable?: Situation;
}

export interface Situation {
    document?: string;
    date?: Date;
    document_dof?: string;
    date_dof?: Date;
}

export interface EfoValidationAll {
    clean: number;
    presumed: number;
    definitive: number;
    detracted: number;
    favorable_sentence: number;
    queued: boolean;
}

export const EfoValues = ["LIMPIO", "DEFINITIVO", "PRESUNTO", "DESVIRTUADO", "SENTENCIA_FAVORABLE"];