import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import DateFormat from "../components/DateFormat";
import translate from "../i18n/Translator";
import { PaymentOrder } from "../model/PaymentOrder";

interface PaymentOrderCommentsSectionProps {
    paymentOrder: PaymentOrder;
}

export default function PaymentOrderCommentsSection(props: PaymentOrderCommentsSectionProps) {
    if (props.paymentOrder.history_response === undefined || props.paymentOrder.history_response.length <= 0) {
        return <div></div>;
    }

    return (
        <div style={{ "backgroundColor": "#ffeecc", "borderRadius": "20px", "marginLeft": "1%", "marginRight": "1%" }}>
            <Grid container >
                <Grid item xs={12}>
                    <Box pl={2} style={{ "marginTop": "2%" }}>
                        <Typography variant="body2" component="h6">
                            { props.paymentOrder.history_response[0].status === "PAYMENT_ORDER_REJECTED" ? 
                                translate("payment_order.cancel_popup.rejected") : translate("payment_order.cancel_popup.pending_execute")
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box pl={2}>
                        <Typography variant="body2" component="h6">
                            <strong>
                                {props.paymentOrder.history_response[0].author + " "}
                            </strong>
                            <DateFormat date={props.paymentOrder.history_response[0].date} format="L" />
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box pl={2} style={{ "marginBottom": "2%", "opacity": "55%" }}>
                        <Typography variant="body2" component="h6">
                            {props.paymentOrder.history_response[0].comments}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}