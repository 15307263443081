import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon, DeleteIcon, Slideshow } from "../components/Icons";

import translate from "../i18n/Translator";
import { Expression } from "../model/Expression";
import { AppContext } from "../context/AppContext";

interface ExpressionMenuProps {
    expression: Expression;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
    onEdit(): void;
    onTest(): void;
}

export default function ExpressionMenu(props: ExpressionMenuProps) {
    const context = useContext(AppContext);
    const [isUpdateGranted] = useState(context.isGranted("ExpressionsUpdate"));
    const [isDeleteGranted] = useState(context.isGranted("ExpressionsDelete"));
    const [isReadGranted] = useState(context.isGranted("ExpressionsRead"));

    return (
        <Menu
            id="expresions-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem button onClick={props.onEdit} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            )}
            {isReadGranted && (
                <MenuItem button onClick={props.onTest} dense>
                    <ListItemIcon>
                        <Slideshow fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.test")}</Typography>
                </MenuItem>
            )}
            {isDeleteGranted && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("buttons.delete")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}