import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent } from "@material-ui/core";
import Alert from "../components/Alert";

import translate from "../i18n/Translator";
import { Address } from "../model/Address";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { CONTRIES } from "../catalogs/Countries";
import { InputValidator } from "../components/Validators";

interface ExpedientProviderAddressFormProps {
    title: string;
    subtitle?: string;
    address?: Address;
    comments?: string;
    onCompleted(address: Address): any;
    onClose(): any;
    disabled: boolean;
}

class ZipCodeValidatorNationalAndForeign implements InputValidator{
    regexp: RegExp = /^\d{5}$/;
    regexpForeign: RegExp = /^(.){1,30}$/;
    message: string;
    isForeign: boolean = false;
    constructor(message: string) {
        this.message = message;
    };

    setIsForeign = (isForeign: boolean) => {this.isForeign = isForeign}
    isValid = (value: string) => {
        return !value || this.isForeign ? this.regexpForeign.test(value):this.regexp.test(value);
    };
    getMessage = () => translate(this.message) as string;
}

export default function ExpedientProviderAddressForm(props: ExpedientProviderAddressFormProps) {
    const isEdit = !!props.address;
    const margin = "dense";
    const COUNTRY_MEX = "MEX";
    const [flagValidateAgain, setFlagValidateAgain] = useState<boolean>(false);
    const [postalCodeValidator, setPostalCodeValidator] = useState<ZipCodeValidatorNationalAndForeign>(new ZipCodeValidatorNationalAndForeign("validations.postal_code")); 
    const countryLabels = CONTRIES.map((code) => translate(`countries.${code}`) as string);
    const [request, setRequest] = useState<Address>({} as Address);
    const [validations, setValidations] = useState({
        street: {
            valid: false,
        } as InputRef,
        interior_number: {
            valid: false,
        } as InputRef,
        exterior_number: {
            valid: false,
        } as InputRef,
        suburb: {
            valid: false,
        } as InputRef,
        city: {
            valid: false,
        } as InputRef,
        state: {
            valid: false,
        } as InputRef,
        country: {
            valid: false,
        } as InputRef,
        zip_code: {
            valid: false,
        } as InputRef,
    } as any);

    useEffect(() => {
        setRequest({
            street: props.address?.street || "",
            interior_number: props.address?.interior_number || "",
            exterior_number: props.address?.exterior_number || "",
            suburb: props.address?.suburb || "",
            city: props.address?.city || "",
            state: props.address?.state || "",
            country: props.address?.country || "",
            zip_code: props.address?.zip_code || "",
        } as Address);
        postalCodeValidator.setIsForeign(COUNTRY_MEX != props.address?.country);
    }, [props.address]);


    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onCountryChange = (name: string, value: string, inputRef: InputRef) => {
        postalCodeValidator.setIsForeign(COUNTRY_MEX != value);
        setFlagValidateAgain(request.country != value);
        hasChanged(name, value, inputRef);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations, false)) {
            return;
        }
        props.onCompleted(request);
    };

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick={props.disabled}
            disableEscapeKeyDown={props.disabled}
            onClose={props.onClose}
            aria-labelledby="address-form-dialog-title"
            aria-describedby="address-form-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="address-form-dialog-title" disableTypography style={{ "lineHeight": "1px" }}>
                    <Typography variant="subtitle1">{props.title}</Typography>
                    <br />
                    <Typography variant="caption">{props.subtitle || translate("address.title") as string}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {props.comments && (
                        <Alert variant="warning" title={props.comments} />
                    )}
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="street"
                            name="street"
                            value={request.street}
                            label={translate("address.street") as string}
                            required={true}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="exterior_number"
                            name="exterior_number"
                            value={request.exterior_number}
                            label={translate("address.exterior_number") as string}
                            required={true}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="interior_number"
                            name="interior_number"
                            value={request.interior_number || ""}
                            label={translate("address.interior_number") as string}
                            required={false}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="suburb"
                            name="suburb"
                            value={request.suburb}
                            label={translate("address.suburb") as string}
                            required={COUNTRY_MEX == request.country}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="city"
                            name="city"
                            value={request.city}
                            label={translate("address.city") as string}
                            required={true}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="state"
                            name="state"
                            value={request.state}
                            label={translate("address.state") as string}
                            required={true}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="country"
                            name="country"
                            value={request.country}
                            label={translate("address.country") as string}
                            options={CONTRIES}
                            optionLabels={countryLabels}
                            required={true}
                            disabled={props.disabled}
                            margin={margin}
                            onValueChanged={onCountryChange}
                        />
                        <ValidatedInput
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            value={request.zip_code}
                            label={translate("address.zip_code") as string}
                            required={true}
                            disabled={props.disabled}
                            margin={margin}
                            validator={postalCodeValidator}
                            onValueChanged={hasChanged}
                            toValidateAgain={flagValidateAgain}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={props.disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={props.disabled}>
                        {translate(isEdit ? "buttons.update" : "buttons.add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
