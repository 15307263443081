import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { useHistory, Redirect } from 'react-router-dom';
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDate } from "../components/DateFormat";
import { FormControl, Select, Box, Grid, IconButton, Divider } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { Entity, ProvidersQueryParams, Providers, PROVIDER_EXPEDIENT_STATUS, AssignValidatorResponse, ProvidersSpecialExpedientParams } from '../model/Provider';
import { getProviders, getProvidersByReviewrsId} from "../api/ProviderAPI";
import { listCompanies } from "../api/CompanyAPI";
import { Company, CompaniesQueryParams } from "../model/Company";
import { listExpedientTemplates } from "../api/ExpedientTemplateAPI";
import { ExpedientTemplateParams, ExpedientTemplate } from "../model/ExpedientTemplate";
import Ellipsis from "../components/Ellipsis";
import ProviderValidatePopup from "./ProviderValidatePopup";
import { ProviderExpedient } from "../model/ProviderExpedient";
import { getExpedient } from "../api/ProviderExpedientAPI";
import { SupervisorAccount } from "@material-ui/icons";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ProviderSpecialExpedientMenu from "./ProviderSpecialExpedientMenu";
import { getUserGroupsByMember } from "../api/UserGroupAPI";

export default function ProvidersSpecialExpedientsView() {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ProvidersSpecialExpedientParams => {
        return {
            "name": typeof qs["name"] === "string" ? qs["name"] as string : "",
            "status_expedient": typeof qs["status_expedient"] === "string" ? qs["status_expedient"] as string : "",
            "classification": typeof qs["classification"] === "string" ? qs["classification"] as string : "",
            "template_id": typeof qs["template_id"] === "string" ? qs["template_id"] as string : "",
            "company_id": typeof qs["company_id"] === "string" ? qs["company_id"] as string : "",
            "rfc": typeof qs["rfc"] === "string" ? qs["rfc"] as string : "",
            "external_id": typeof qs["external_id"] === "string" ? qs["external_id"] as string : "",
            "reviewers_ids": typeof qs["reviewers_ids"] === "string" ? qs["reviewers_ids"] as string : "",
        } as ProvidersSpecialExpedientParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Providers>();
    const [provider, setProvider] = useState<Entity>();
    const [params, setParams] = useState<ProvidersSpecialExpedientParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [queryParam, setQueryParam] = useState<string>();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [templates, setTemplates] = useState<ExpedientTemplate[]>([]);
    const [dialogValidate, setDialogValidate] = useState(false);
    const [metadata, setMetadata] = useState<ProviderExpedient>({} as ProviderExpedient);
    const [userGroups, setUserGroups] = useState<string[]>();

    const paramsToFilters = (): Filter[] => {
        return [
            { columnName: 'provider_name', value: params.name },
            { columnName: 'status_expedient', value: params.status_expedient },
            { columnName: 'classification', value: params.classification },
            { columnName: 'rfc', value: params.rfc },
            { columnName: 'provider_external_id', value: params.external_id },
            { columnName: 'template_name', value: params.template_id },
            { columnName: 'company_names', value: params.company_id },
        ] as Filter[];
    };

    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
        {columnName: 'provider_external_id', width: 150},
        {columnName: 'provider_name', wordWrapEnabled: true, align: 'left'},
        {columnName: 'rfc', wordWrapEnabled: true, width:150},
        {columnName: 'company_names', wordWrapEnabled: true },
        {columnName: 'template_name'},
        {columnName: 'classification', wordWrapEnabled: true, width: 150 },
        {columnName: 'status_expedient', wordWrapEnabled: true, width: 150 },
        {columnName: 'menu', width: 50 },
    ]) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();

        Promise.all([
            getUserGroupsByMember(context.session!.tenant!.id, context.session!.user.id),
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams),
            listExpedientTemplates(context.session!.tenant!.id, {} as ExpedientTemplateParams,0, 0),
        ]).then(responses => {
            const groupsIds = responses[0].items.map(group => group.id).toString();
            setUserGroups(responses[0].items.map(group => group.id));
            const companiesResponse = responses[1];
            const templatesResponse = responses[2];
            setCompanies(companiesResponse.items);
            setTemplates(templatesResponse.items);
            if(groupsIds) {
                params["reviewers_ids"] = groupsIds;
                getProvidersByReviewrsId(context.session!.tenant!.id, pageSize, pageSize * page, params).then((providersResponse) => {
                    setData(providersResponse);
                }).catch((error) => {
                    setError(error.message);
                });
            }
            
            setStatus("loaded");
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                if (filter.value !== undefined) {
                    switch (filter.columnName) {
                        case "provider_name":
                            temp.name = filter.value;
                            break;
                        case "status_expedient":
                            temp.status_expedient = filter.value;
                            break;
                        case "classification":
                            temp.classification = filter.value;
                            break;
                        case "provider_external_id":
                            temp.external_id = filter.value;
                            break;
                        case "template_name":
                            temp.template_id = filter.value;
                            break;
                        case "company_names":
                            temp.company_id = filter.value;
                            break;
                        case "rfc":
                            temp.rfc = filter.value
                            break;
                        default: break;
                    }
                }
            }
        });
        setParams(temp);
    };


    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["name"] = params.name;
        qs["external_id"] = params.external_id;
        qs["status_expedient"] = params.status_expedient;
        qs["classification"] = params.classification;
        qs["template_id"] = params.template_id;
        qs["company_id"] = params.company_id;
        qs["rfc"] = params.rfc;
        qs["page"] = page + "";
        setQueryParam(queryString.stringify(qs));
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const expedientStatusColumn = (provider: Entity): React.ReactNode => {
        if (provider.expedient) {
            if (provider.expedient.status) {
                return (
                    <div>
                        <Ellipsis text={translate(`expedients.status_metadata_short.${provider.expedient.status}`) as string} lenght={0} uppercased={false} />
                        {provider.expedient.approved && (<br />)}
                        {provider.expedient.approved && (<Ellipsis text={formatDate(provider.expedient.approved, "lll")} lenght={0} uppercased={false} secondary />)}
                    </div>
                );
            }

            if (provider.expedient.has_data) {
                return translate("expedients.status_metadata_short.TO_SEND");
            }
        }
        return "---";
    };

    const getCompanyNames = (entity: Entity): string => {
        if (entity.company_objects) {
            return entity.company_objects.map((c) => c.name).reverse().join(", ");
        }
        return "---";
    }

    const columns = [
        {
            name: 'provider_external_id',
            title: translate("providers.external_id") as string,
            getCellValue: (provider: any) => provider.external_id || "---",
        },
        {
            name: 'provider_name',
            title: translate("providers.name") as string,
            getCellValue: (provider: any) => (
                <Grid container justify="flex-start" alignItems="flex-start" alignContent="flex-start" spacing={1}>
                    <Grid item xs>
                        <Ellipsis align="left" text={provider.name} lenght={100} /><br />
                    </Grid>
                </Grid>
            ),
        },
        {
            name: 'rfc',
            title: translate("providers.rfc") as string,
            converter: (provider: any) => provider.rfc  || "---",
        },
        {
            name: 'company_names',
            title: translate("providers.companies.title") as string,
            getCellValue: (entity: any) => getCompanyNames(entity) || "---",
        },
        {
            name: 'template_name',
            title: translate('providers.template_expedient.title') as string,
            getCellValue: (provider: any) => provider.expedient_template_name || "---",
        },
        {
            name: 'classification',
            title: translate('providers.classification') as string,
            getCellValue: (provider: Entity) => provider?.provider_classifications?.map(c => c.name).join(", ") || "---",
        },
        {
            name: 'status_expedient',
            title: translate("providers.template_expedient.title_status") as string,
            getCellValue: expedientStatusColumn
        },
        {
            name: 'menu',
            title: " "
        }
    ];


    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const textColumns = ['provider_external_id', 'provider_name', 'rfc', 'company_names', 'template_name', 'classification', 'status_expedient'];

    const ExpedientStatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("all") as string}</option>
                        {PROVIDER_EXPEDIENT_STATUS.map((value) => {
                            return <option key={value} value={value}>{translate("expedients.status_metadata_short." + value) as string}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const ClassificationFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(data?.classifications ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const CompaniesFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(companies ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const TemplatesFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };
        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{translate("any") as string}</option>
                        {(templates ?? []).map((value) => {
                            return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const customPlugins = [
        <NormalTypeProvider for={['status_expedient']} editorComponent={ExpedientStatusFilterEditor} />,
        <NormalTypeProvider for={['classification']} editorComponent={ClassificationFilterEditor} />,
        <NormalTypeProvider for={['company_names']} editorComponent={CompaniesFilterEditor} />,
        <NormalTypeProvider for={['template_name']} editorComponent={TemplatesFilterEditor} />,
    ];

    const clickRowColumns = ['provider_external_id','provider_name','rfc'] as string[];

    const onClickedRow = (provider: Entity) => {
        setAnchorEl(null);
        setProvider(provider);
        getExpedient(context.session!.tenant!.id, provider.id).then(response => {
                setMetadata(response);
                setStatus("loaded");
                setDialogValidate(true);
        }).catch((error) => {
                setStatus(error.message);
        });
    };

    const onClickedOptions = (entity: Entity) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setProvider(entity);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setProvider(undefined);
    };

    const onSuccessExpedientValidate = (message: string) => {
        setAnchorEl(null);
        setDialogValidate(false);
        setSuccess(message);
        load();
    };

    const onCloseExpedientValidate = () => {
        setAnchorEl(null);
        setDialogValidate(false);
    };

    return (
        <Surface title={translate("providers.expedient_review.title")}
            icon={<SupervisorAccount />}
            subtitle={translate("providers.expedient_review.subtitle")}
            className="PaperPagination">
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns}
                columnsFormat={columnsFormat}
                textColumns={textColumns}
                filters={filters}
                customFormatColumns={customPlugins}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                onClickRow={onClickedRow}
                onClickedOptions={onClickedOptions}
                clickRowColumns={clickRowColumns}
            />

            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {provider && anchorEl && (
                <ProviderSpecialExpedientMenu
                    provider={provider}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onValidate={onClickedRow}
                />
            )}

            {dialogValidate && (
                <ProviderValidatePopup
                    tenantId={context.session!.tenant!.id}
                    providerId={provider!.id}
                    providerName={provider!.name}
                    providerRfc={provider!.rfc}
                    providerExternalId={provider!.external_id}
                    metadata={metadata}
                    userGroupIds={userGroups}
                    isFromReviewersView={true}
                    onSuccess={onSuccessExpedientValidate}
                    onClose={onCloseExpedientValidate} />
            )}
        </Surface>
    );
}
