import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';
import { Announcement } from "../model/Announcement";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import PanToolIcon from '@material-ui/icons/PanToolTwoTone';

interface AnnouncementMenuProps {
    announcement: Announcement;
    anchor: HTMLElement | null;
    onClose(): any;
    onEdit(): any;
    onDetail(): any;
    onFinish(): any;
    onDelete(): any;
}

export default function AnnouncementMenu(props: AnnouncementMenuProps) {
    const context = useContext(AppContext);

    return (
        <Grid>
            <Menu
                id="announcement-menu-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}>

                {context.isGrantedAny(["AnnouncementAdminUpdate"]) && props.announcement.status !== "EXPIRED" &&
                    <MenuItem button onClick={props.onEdit} dense>
                        <ListItemIcon>
                            <EditIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AnnouncementAdminRead", "AnnouncementProviderRead"]) &&
                    <MenuItem button onClick={props.onDetail} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("providers.announcements.menu.view_announcement")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AnnouncementAdminUpdate"]) && props.announcement.status === "ACTIVE" &&
                    <MenuItem button onClick={props.onFinish} dense>
                        <ListItemIcon>
                            <PanToolIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("providers.announcements.menu.end_announcement")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AnnouncementAdminUpdate"]) &&
                    <MenuItem button onClick={props.onDelete} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                    </MenuItem>
                }
            </Menu>
        </Grid>);
}
