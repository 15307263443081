import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";

import { User, UserUnlockRequest } from "../model/User";
import { unblockUser } from "../api/UserAPI";
import { unblockUser as unblockTenantUser, unblockUserProvider } from "../api/TenantUserAPI";
import DialogPopup from "../components/DialogPopup";

interface UserUnblockPopupProps {
    user: User;
    tenantId?: string;
    resetPassword: boolean;
    providerId?: string;
    onCompleted(): any;
    onError(error: string): any;
    onCancelled(): any;
}

export default function UserUnblockPopup(props: UserUnblockPopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const unblockPromise = (user: User): Promise<void> => {
        if (props.tenantId) {
            if(props.providerId){
                return unblockUserProvider(props.tenantId, props.providerId, user.id, {reset_password: props.resetPassword} as UserUnlockRequest);
            }
            return unblockTenantUser(props.tenantId, user.id, {reset_password: props.resetPassword} as UserUnlockRequest);
        }
        return unblockUser(user.id, {reset_password: props.resetPassword} as UserUnlockRequest);
    };

    const onConfirm = () => {
        setSubmitting(true);
        unblockPromise(props.user).then(() => {
            setSubmitting(false);
            props.onCompleted();
        }).catch((error) => {
            setSubmitting(false);
            props.onError(error.message);
        });
    };

    return (
        <DialogPopup open
            title={ props.resetPassword ? translate("users.unblock.title") as string : translate("users.unblock.access_enable") as string }
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onConfirm} variant="outlined" color="primary" disabled={submitting}>
                    { props.resetPassword ? translate("buttons.unlock") :
                     translate("buttons.unblock")}
                </Button>
            }
        >
            <Typography variant="body2">
                { props.resetPassword ? translate("users.unblock.description_with_reset_password", { "email": props.user.email }) :
                 translate("users.unblock.description", { "first_name": props.user.first_name })}
            </Typography>
        </DialogPopup>
    );

}