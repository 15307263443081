import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import WidgetsIcon from "@material-ui/icons/Widgets";

import { createWidget, getWidget, updateWidget } from "../api/WidgetAPI";
import translate from "../i18n/Translator";
import { Widget, WidgetRequest, WIDGET_TYPES } from "../model/Widget";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import SimpleSwitch from "../components/SimpleSwitch";
import { RouterParams } from "../router/RouterParams";
import queryString from "query-string";

export default function WidgetForm({ match }: RouterParams) {
    const history = useHistory();
    const isEdit = !!match.params.widgetId;
    const margin = "dense";
    const labels = WIDGET_TYPES.map(t => translate(`widgets.types.${t}`) as string);

    const qs = queryString.parse(window.location.search);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<WidgetRequest>({} as WidgetRequest);
    const [validations, setValidations] = useState({} as any);

    const submitPromise = (): Promise<Widget> => {
        if (isEdit && match.params.widgetId) {
            return updateWidget(match.params.tenantId, match.params.widgetId, request);
        }
        return createWidget(match.params.tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            getWidget(match.params.tenantId, match.params.widgetId).then((widget) => {
                setRequest({
                    name: widget.name,
                    description: widget.description,
                    type: widget.type,
                    value: widget.value,
                    active: widget.active,
                    created_at: widget.created_at,
                } as WidgetRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    }, [match.params.tenantId, isEdit, match.params.widgetId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedSwitch = (name: string, checked: boolean) => {
        setRequest({ ...request, [name]: checked });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((widget) => {
            qs["search_widget"] = undefined;
            setRedirect(`/tenants/${match.params.tenantId}/widgets?${queryString.stringify(qs)}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "widgets.edit" : "widgets.new")} icon={<WidgetsIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("widgets.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="description" name="description"
                                        value={request.description}
                                        label={translate("widgets.description") as string}
                                        rows={3} maxRows={10} maxLength={500}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="type" name="type"
                                        value={request.type}
                                        options={WIDGET_TYPES} optionLabels={labels}
                                        label={translate("widgets.types.title") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="value" name="value"
                                        value={request.value}
                                        label={translate("widgets.value") as string}
                                        rows={5} maxRows={10} maxLength={1000}
                                        required={true} disabled={!request.type || submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleSwitch value="active"
                                        label={translate("widgets.active") as string}
                                        checked={request.active || false}
                                        disabled={submitting}
                                        placement="end"
                                        onChanged={hasChangedSwitch} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}