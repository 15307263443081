import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { getZipIcsoeSisub } from "../api/IcsoeSisubAPI";

import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import DownloadIcsoeSisubPopup from "./DownloadIcsoeSisubPopup";

import Surface from "../components/Surface";
import { Grid, IconButton } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/GetApp';
import { IcsoeSisubReportRequest,  } from "../model/IcsoeSisubReport";
import CustomBackdrop from "../components/CustomBackdrop";

export default function ReportIcsoeSisubView() {
  const context = useContext(AppContext);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [openFormDownload, setOpenFormDownload] = useState<boolean>(false);
    const [processing, setProcessing] = useState(false);

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

const openFormDialogs = () => {
    setOpenFormDownload(true);
}

const closeFormDialogs = () => {
    setOpenFormDownload(false);
}

const downloadReports = (request: IcsoeSisubReportRequest) => {
    setProcessing(true);
    setOpenFormDownload(false);
    getZipIcsoeSisub(context.session!.tenant!.id, request).then((response) => {
        window.open(response.url, "_blank");
    }).catch((error) => {
        setError(error.message);
    }).finally(() => {
        setProcessing(false);
    });
}

    return (
        <Surface title={translate("reports.icsoe_sisub.title") as string} icon={<AssignmentIcon />} 
        titleActions={
            <Grid >
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <IconButton color="primary" size="small" onClick={openFormDialogs} disabled={false}  >
                            <DownloadIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        }>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {openFormDownload && (
                <DownloadIcsoeSisubPopup
                    title={translate("reports.icsoe_sisub.popup.title") as string}
                    message={translate("reports.icsoe_sisub.popup.message") as string}
                    onDownload={downloadReports}
                    onClose={closeFormDialogs} />
            )}
            <CustomBackdrop open={processing} message={translate(`cfdis.processing`) as string} />
        </Surface>
    );
}
