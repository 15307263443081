import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Typography, Tabs, Tab, Grid, Fab, Chip, Tooltip } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import { Requisition, TransitionRequest } from "../model/Requisition";
import { getRequisition, forwardRequisition, backwardRequisition } from "../api/RequisitionAPI";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import CfdisToSendIcon from "@material-ui/icons/SendTwoTone";
import RequisitionDetail from "./RequisitionDetail";
import RequisitionQuotation from "./RequisitionQuotation";
import RequisitionHistory from "./RequisitionHistory";
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import RejectIcon from '@material-ui/icons/ThumbDown';
import ConfirmationPopup from "../components/ConfirmationPopup";
import ToAuthorizeIcon from '@material-ui/icons/ThumbUp';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import OwnersTooltip from "../components/OwnersTooltip";
import { DesktopAccessDisabledIcon } from "../components/Icons";

interface RequisitionTab {
    name: string;
    title: string;
    actions?: string[];
}

export default function RequisitionOverview({ match }: RouterParams) {
    const context = useContext(AppContext);
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));
    const classes = useStyles();
    const history = useHistory();
    const [openConfirmSend, setOpenConfirmSend] = useState<boolean>(false);
    const [openConfirmReject, setOpenConfirmReject] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    
    const referer = typeof qs["referer"] === "string" ? qs["referer"] as string : qs["referer"] !== undefined && qs["referer"] !== null ? qs["referer"][0] : undefined;
    
    const backTo = ((): string | undefined => {
        switch (referer) {
            case "to_send":
                return "/requisitions/to_send?" + queryString.stringify(qs);
            case "to_authorize":
                return "/requisitions/to_authorize?" + queryString.stringify(qs);
            case "authorized":
                return "/requisitions/authorized?" + queryString.stringify(qs);
            case "delivered_erp":
                return "/requisitions/delivered_erp?" + queryString.stringify(qs);
            case "archive":
                return "/requisitions/archive?" + queryString.stringify(qs);
            default:
                return "/requisitions/archive?" + queryString.stringify(qs);
        }
    })();

    const allTabs = [
        {
            name: "details",
            title: translate("requisitions.overview.details.title" as string)
        },
        {
            name: "quotation",
            title: translate("requisitions.overview.quotation.title" as string)
        },
        {
            name: "history",
            title: translate("requisitions.overview.history.title" as string)
        },
    ] as RequisitionTab[];

    const allTabsNoQuotation = [
        {
            name: "details",
            title: translate("requisitions.overview.details.title" as string)
        },
        {
            name: "history",
            title: translate("requisitions.overview.history.title" as string)
        },
    ] as RequisitionTab[];


    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [status, setStatus] = useState<string>("loading");
    const [requisition, setRequisition] = useState<Requisition>();
    const [tabs, setTabs] = useState<RequisitionTab[]>([]);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [value, setValue] = useState<number>(tabQs);
    const [redirect, setRedirect] = useState<string | undefined>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setValue(newValue);
    };

    const load = () => {
        setStatus("loading");
        getRequisition(context.session!.tenant!.id, match.params.requisitionId).then((requisition) => {
            setRequisition(requisition);
            if(requisition.quotation != null ) {
                setTabs(allTabs);
            } else {
                setTabs(allTabsNoQuotation);
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line 
    };

    useEffect(load, [match.params.requisitionId]);

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line 
    }, [qs.tab, tabs]);

    const tabContent = (index: number) => {
        if (!requisition) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return <RequisitionDetail requisition={requisition} statusDescription={getStatusDescription()}/>;
            case "quotation":
                return <RequisitionQuotation requisition={requisition} />;
            case "history":
                return <RequisitionHistory requisition={requisition} />;
            default:
                return <div></div>;
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onEditRequisition = () => {
        if(requisition?.read_only?.active){
            setWarning(translate("errors.codes.5814", {"0": requisition.read_only.comments}) as string);
            return;
        }
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/requisitions/${requisition!.id}/edit?`+ queryString.stringify(qs));
    };

    const onCreateOC = () => {
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/requisitions/${requisition!.id}/createOC?`+ queryString.stringify(qs));
    };

    const onOpenConfirmSend = () => {
        if(!requisition) return;
        if(requisition.need_quotation){
            setWarning(translate("requisitions.overview.warning_send") as string);
            return;
        }
        setOpenConfirmSend(true);
    };

    const onOpenConfirmReject = () => {
        setOpenConfirmReject(true);
    };

    const onCancelReject = () => {
        setOpenConfirmReject(false);
    };

    const onCancelSend = () => {
        setOpenConfirmSend(false);
    };

    const onSend = () => {
        onSendWithReason("");
    };

    const onSendWithReason = (reason: string) => {
        setOpenConfirmSend(false);
        if(!requisition) return;
        setStatus("loading");
        forwardRequisition(context.session!.tenant!.id, requisition.id, { reason: reason } as TransitionRequest ).then(requisition => {
            setRedirect(backTo);
        }).catch( error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onReject = (reason: string) => {
        setOpenConfirmReject(false);
        if(!requisition) return;
        setStatus("loading");
        backwardRequisition(context.session!.tenant!.id, requisition.id, { reason: reason } as TransitionRequest ).then(requisition => {
            setRedirect(backTo);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const getStatusDescription = () => {
        if(!requisition) return "---";
        let statusFind = requisition.statuses.find(item => item.status === requisition.status);
        return statusFind ? statusFind.description : requisition.status;
    }

    const chipStatus = () => {
        if(!requisition) return;
        return <Grid className={classes.root}>
            <Typography variant="body1" component="h6">
                {translate("requisitions.overview.subtitle", {"number": requisition.operation_number}) as string}
            </Typography>
            <Chip label={getStatusDescription()} disabled variant="outlined" size="small" />
            {requisition.owners && requisition.owners.some(o => o.active) &&
                <Grid item className={classes.root}>
                    <OwnersTooltip owners={requisition.owners} />
                </Grid>
            }
        </Grid>
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !requisition) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }
    
    if (redirect) {
        return (<Redirect to={redirect} />);
    }
    
    return (
        <Surface title={requisition.read_only?.active ?
            <Typography style={{ color: "#9C9C9C" }} >
                {requisition.description} <Tooltip title={requisition.read_only?.comments}>
                    <DesktopAccessDisabledIcon />
                </Tooltip></Typography> : requisition.description}
            subtitle={chipStatus()} backButton
            backTo={backTo}
            icon={<MoreVertIcon />}
            titleActions={<Grid>
                {referer !== "archive" &&
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGranted("RequisitionsUpdate") && (requisition.status_group === 'TO_SEND' || requisition.status_group === 'TO_AUTHORIZE') && requisition.actions.edit &&
                            (<Grid item xs="auto">
                                <Fab aria-label="add" color="primary" size="small" title={translate("buttons.edit") as string} onClick={onEditRequisition} >
                                    <EditIcon />
                                </Fab>
                            </Grid>)
                        }
                        {context.isGranted("RequisitionsUpdate") && (requisition.status_group === 'TO_AUTHORIZE' || requisition.status_group === 'PENDING_PURCHASE_ORDER') && requisition.actions.reject &&
                            (<Grid item xs="auto"> <Fab color="primary" size="small" title={translate("buttons.reject") as string} onClick={onOpenConfirmReject} >
                                <RejectIcon />
                            </Fab> </Grid>)
                        }
                        {context.isGranted("RequisitionsUpdate") && (requisition.status_group === 'TO_SEND' || requisition.status_group === 'TO_AUTHORIZE') && (requisition.actions.send || requisition.actions.authorize) &&
                            (<Grid item xs="auto"> <Fab color="primary" size="small" title={translate(requisition.status_group === 'TO_SEND' ? "buttons.send" : "buttons.authorize_payment") as string} onClick={onOpenConfirmSend} >
                                {requisition.status_group === 'TO_SEND' && <CfdisToSendIcon />}
                                {requisition.status_group === 'TO_AUTHORIZE' && <ToAuthorizeIcon />}
                            </Fab> </Grid>)
                        }
                        {context.isGranted("RequisitionsUpdate") && requisition.status_group === 'PENDING_PURCHASE_ORDER' && requisition.actions.create_po &&
                            (<Grid item xs="auto"> <Fab aria-label="add" color="primary" size="small" title={translate("requisitions.overview.create_oc") as string} onClick={onCreateOC} >
                                <ShopTwoIcon />
                            </Fab> </Grid>)
                        }
                    </Grid>
                }
            </Grid> }>
            <Tabs value={value} onChange={handleChange} aria-label={translate("requisitions.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: RequisitionTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: RequisitionTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                    <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
                    <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                </Grid>
            </Grid>
            {openConfirmSend && 
                <ConfirmationPopup
                    title={translate(requisition.status_group === 'TO_SEND' ? "requisitions.overview.confirm_send.title" : "requisitions.overview.confirm_send.authorize_title") as string}
                    message={translate("requisitions.overview.confirm_send.message")}
                    button={translate(requisition.status_group === 'TO_SEND' ? "buttons.accept" : "buttons.authorize_payment") as string}
                    onClose={onCancelSend}
                    doAction={onSend}
                    addCommentary={false}
                    doActionCommentary={onSendWithReason}
                />
            }
            {openConfirmReject && 
                <ConfirmationPopup
                    title={translate("requisitions.overview.confirm_reject.title") as string}
                    message={translate("requisitions.overview.confirm_reject.message")}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelReject}
                    addCommentary={true}
                    doActionCommentary={onReject}
                />
            }
        </Surface>
    );
}
