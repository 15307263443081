import { Tenant } from "./Tenant";

export interface Widget {
    id: string;
    name: string;
    description?: string;
    type: WidgetType;
    value: string;
    active: boolean;
    created_at: Date;
}

export interface Widgets {
    items: Widget[];
    total: number;
    tenant?: Tenant;
}

export interface WidgetsQueryParams {
    search: string;
}

export interface WidgetRequest {
    name: string;
    description?: string;
    type?: WidgetType;
    value?: string;
    active: boolean;
}

export type WidgetType = 'link' | 'image' | 'video' | 'youtube' | 'file' | 'html' | 'billing_data';

export const WIDGET_TYPES: WidgetType[] = ['link', 'image', 'video', 'youtube', 'file', 'html'];
