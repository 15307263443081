import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import SatTaxIcon from "@material-ui/icons/AccountBalance";

import { createSatContribution, listSatContributions } from "../api/SatContributionAPI";
import { createSatContribution as createProviderSatContribution, listSatContributions as listProviderSatContributions } from "../api/ProviderSatContributionAPI";
import translate from "../i18n/Translator";
import { NewSatContributionRequest, SatContributionsQueryParams } from "../model/SatContribution";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import { AppContext } from "../context/AppContext";
import { fillSatContributionList } from "./SatContributionUtil";
import ConfirmationPopup from "../components/ConfirmationPopup";
import ToValidatePaymentForm from "./ToValidatePaymentForm";

export default function NewSatContributionForm() {
    const [view, setView] = useState<"new" | "validate-payment">("new");
    const [url, setUrl] = useState<string>("");
    const [period, setPeriod] = useState<string>("");
    const onValidatePayment = (url: string, period: string) => {
        setPeriod(period);
        setUrl(url);
        setView("validate-payment");
    };
    if(view === "validate-payment"){
        return <ToValidatePaymentForm urlPdf={url} period={period} complementary={false}/>
    }
    return <SatContributionForm onValidatePayment={onValidatePayment}/>;
}

export interface SatContributionFormParams {
    onValidatePayment(url: string, period: string): any;
}

function SatContributionForm(props: SatContributionFormParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const mode = context.session?.provider?.declaration_frequency || "MONTHLY";
    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<NewSatContributionRequest>({} as NewSatContributionRequest);
    const [validations, setValidations] = useState({} as any);
    const [dateKeys, setDateKeys] = useState<string[]>([]);
    const [dateLabels, setDateLabels] = useState<string[]>([]);
    const [openValidateDialog, setOpenValidateDialog] = useState<boolean>();
    const [openValidatePaymentDialog, setOpenValidatePaymentDialog] = useState<boolean>();

    const promiseList = () => {
        const params = {} as SatContributionsQueryParams;
        if (providerId) {
            return listProviderSatContributions(tenantId, providerId, 50, 0, params);
        }
        return listSatContributions(tenantId, 50, 0, params);
    };

    const load = () => {
        setStatus("loading");
        promiseList().then(response => {
            const lists = fillSatContributionList(response, mode);
            setDateKeys(lists.keys);
            setDateLabels(lists.labels);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, mode]);

    const hasChangedMonth = (name: string, value: string, inputRef: InputRef) => {
        const fixed = value === "---" ? "" : value;
        const segments = fixed.split("-");
        if (segments.length === 2) {
            const year = +segments[0];
            const month = +segments[1];
            if (mode === "MONTHLY") {
                setRequest({ ...request, [name]: fixed, year: year, month: month });
            } else {
                setRequest({ ...request, [name]: fixed, year: year, bimester: month });
            }
        } else {
            setRequest({ ...request, [name]: fixed });
        }

        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const promiseCreate = (request : NewSatContributionRequest) => {
        if (providerId) {
            return createProviderSatContribution(tenantId, providerId, request);
        }
        return createSatContribution(tenantId, request);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        promiseCreate(request).then((satContribution) => {
            if(satContribution.type && satContribution.type === 'SAT_PAYMENT'){
                setOpenValidatePaymentDialog(true);
                return;
            }
            history.push("/contributions/sat");
        }).catch((error) => {
            setSubmitting(false);
            if(request.bank_voucher){
                setOpenValidateDialog(true);
            } else {
                setError(error.message);
            }
        });
    };

    const onCancelValidate = () => {
        setOpenValidateDialog(false);
        setSubmitting(false);
    };

    const onAcceptValidate = () => {
        setOpenValidateDialog(false);
        setSubmitting(true);

        promiseCreate({ ...request, "force_voucher": true }).then((satContribution) => {
            if(satContribution.type && satContribution.error_code){
                setOpenValidatePaymentDialog(true);
                return;
            }
            history.push("/contributions/sat");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });

    };

    const onCancelValidatePayment = () => {
        setOpenValidatePaymentDialog(false);
        setSubmitting(false);
    };

    const onAcceptValidatePayment = () => {
        setOpenValidatePaymentDialog(false);
        setSubmitting(true);

        if(request.key){
            props.onValidatePayment(request.payment, request.key);
        }
    };
    
    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("sat_contributions.new")} icon={<SatTaxIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="key" name="key"
                                        value={request.key}
                                        label={translate("sat_contributions.period") as string}
                                        options={dateKeys} optionLabels={dateLabels}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChangedMonth} />
                                </Grid>
                                    <UploaderInput id="payment" name="payment"
                                    value={request.payment}
                                    label={translate("sat_contributions.payment_short") as string}
                                    required={true} disabled={submitting}
                                    margin={margin}
                                    acceptExtension=".pdf" path={`tenants/${tenantId}/payment`}
                                    newFileName={request.key ? request.key+"_SATDecProv_01" : "SATDecProv_01"}
                                    onValueChanged={hasChanged} />
                                    <UploaderInput id="bank_voucher" name="bank_voucher"
                                        value={request.bank_voucher}
                                        label={translate("sat_contributions.bank_voucher") as string}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        acceptExtension=".pdf,.jpg,.png" path={`tenants/${tenantId}/bank_voucher`}
                                        newFileName={request.key ? request.key+"_SAT_CompBanco_DecProv01" : "SAT_CompBanco_DecProv_01"}
                                        onValueChanged={hasChanged} />
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate("buttons.create")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
            {request && openValidateDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.title") as string}
                    message={translate("confirm_contribution.voucher.message") as string}
                    secondary={translate("confirm_contribution.voucher.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidate}
                    doAction={onAcceptValidate}
                />
            }
            {request && openValidatePaymentDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.payment.title") as string}
                    message={translate("confirm_contribution.payment.message") as string}
                    secondary={translate("confirm_contribution.payment.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidatePayment}
                    doAction={onAcceptValidatePayment}
                />
            }
        </Grid>
    );
}