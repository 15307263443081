import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WidgetsIcon from "@material-ui/icons/Widgets";
import AddIcon from "@material-ui/icons/Add";

import { listWidgets } from "../api/WidgetAPI";
import { AppContext } from "../context/AppContext";
import { Widget, WidgetRequest, Widgets, WidgetsQueryParams } from '../model/Widget';

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import WidgetMenu from "./WidgetMenu";
import DateFormat from "../components/DateFormat";
import { RouterParams } from "../router/RouterParams";
import queryString from "query-string";
import { createWidget, deleteWidget } from "../api/WidgetAPI";
import { WarningSnackbar } from '../components/Snackbars';
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function WidgetsList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();

    const qs = queryString.parse(window.location.search);

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search_widget");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Widgets>();
    const [workingParams, setWorkingParams] = useState<WidgetsQueryParams>({
        search: search,
    } as WidgetsQueryParams);

    const [widget, setWidget] = useState<Widget>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());

    const [openMenuWidget, setOpenMenuWidget] = useState<boolean>(false);

    const [warningMessage, setWarningMessage] = useState<string>()
    const [openDeleteWidget, setOpenDeleteWidget] = useState<boolean>(false)

    const onInit = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as WidgetsQueryParams;
        setWorkingParams(params);

        listWidgets(match.params.tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }
    useEffect(onInit, [match.params.tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        query.set("search_widget", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (widget: Widget) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWidget(widget);
    };


    const onMenuClickedOptions = () => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenMenuWidget(true);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setWidget(undefined);
        setOpenMenuWidget(false)
    };

    const onClickRow = (widget: Widget) => {
        if ("billing_data" !== widget.type) {
            history.push(`/tenants/${match.params.tenantId}/widgets/${widget.id}/edit`)
        }
    };

    const onNewContentWidget = () => {
        history.push(`/tenants/${match.params.tenantId}/widgets/new`)
    };

    const onBillingWidget = () => {

        if (data?.items.find(e => "billing_data" === e.type)) {
            setWarningMessage(translate("widgets.billing_data_already_exist") as string);
            onCloseOption();
            return;
        }
        onCloseOption();
        createWidget(match.params.tenantId, {
            name: translate("widgets.billing_data_name") as string,
            description: translate("widgets.billing_data_description") as string,
            type: "billing_data",
            active: true,
            value: "-",
            created_at: new Date(),
        } as WidgetRequest).then((v) => {
            onInit();
        }).catch((e) => {
            setStatus(e.message);
        });
    };

    const onDeleteWidget = (name: string) => {
        setOpenDeleteWidget(true);
    };

    const onDeleteWidgetConfirm = () => {
        if (widget) {
            deleteWidget(match.params.tenantId, widget.id).then((v) => {
                onInit();
            }).catch((e) => {
                setStatus(e.message);
            });
            setOpenDeleteWidget(!openDeleteWidget);
            setAnchorEl(null);
            setWidget(undefined);
        }
    };

    return (
        <Pagination title={translate("widgets.title")} subtitle={data?.tenant?.name} icon={<WidgetsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize}
            action={context.isGranted("WidgetsCreate") ?
                (

                    <IconButton aria-label="options" color="default" size="small" onClick={onMenuClickedOptions()}>
                        <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                            <AddIcon />
                        </Fab>
                    </IconButton>
                ) : undefined}
            backButton>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("widgets.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("widgets.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("widgets.name") as string,
                        converter: (widget) => (
                            <Ellipsis text={widget.name} lenght={100} uppercased={false} />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("widgets.description") as string,
                        converter: (widget) => (
                            <Ellipsis text={widget.description || "---"} lenght={250} uppercased={false} />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 4,
                        lg: 5,
                        xl: 5
                    },
                    {
                        title: translate("widgets.types.title") as string,
                        converter: (widget) => translate(`widgets.types.${widget.type}`),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 1
                    },
                    {
                        title: translate("widgets.active") as string,
                        converter: (widget) => translate(widget.active ? "yes" : "no"),
                        fullWidth: true,
                        xs: 3,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 1
                    },
                    {
                        title: translate("widgets.created_at") as string,
                        converter: (widget) => (
                            <DateFormat date={widget.created_at} format="YYYY-MM-DD" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: false,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (widget) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(widget)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {widget && anchorEl &&
                <WidgetMenu tenantId={match.params.tenantId}
                    widget={widget}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    queryString={queryString.stringify(qs)}
                    onDeleteWidget={onDeleteWidget}
                />
            }
            {openMenuWidget && anchorEl &&
                <WidgetMenu tenantId={match.params.tenantId} anchor={anchorEl} onClose={onCloseOption} onContentWidget={onNewContentWidget} onBillingWidget={onBillingWidget}
                />
            }
            {openDeleteWidget && widget &&
                <ConfirmationPopup
                    title={translate("widgets.delete_widget_title") as string}
                    message={translate("widgets.delete_widget_label") as string}
                    button={translate("buttons.accept") as string}
                    onClose={() => {
                        setOpenDeleteWidget(!openDeleteWidget);
                        setAnchorEl(null);
                        setWidget(undefined);
                    }}
                    doAction={onDeleteWidgetConfirm}
                />
            }
            <WarningSnackbar message={warningMessage} onClose={() => {
                setWarningMessage(undefined)
            }}></WarningSnackbar>
        </Pagination>
    );
}