import { Box, Card, Grid, Typography, Divider } from "@material-ui/core";
import React from "react";
import { Cfdi } from "../model/Cfdi";
import { CartaPorte, Ubicacion, Ubicaciones } from "../model/ComplementoCartaPorte";
import translate from "../i18n/Translator";
import MercanciasCard from "./CartaPorte/MercanciasCard";
import FiguraTransporteCard from "./CartaPorte/FiguraTransporteCard";
import DateFormat from "../components/DateFormat";

export interface CartaPorteTabProps {
    cfdi: Cfdi;
}

export default function CartaPorteTab(props: CartaPorteTabProps) {
    return (
        <Box pt={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2} >
                <Grid item xs={12}>
                    <Generales data={props.cfdi.cfdi!.complementos.carta_porte} />
                </Grid>
                <Grid item xs={12}>
                    <UbicacionesCard data={props.cfdi.cfdi!.complementos.carta_porte.ubicaciones} />
                </Grid>
                <Grid item xs={12}>
                    <MercanciasCard data={props.cfdi.cfdi!.complementos.carta_porte.mercancias} />
                </Grid>
                {props.cfdi.cfdi!.complementos.carta_porte.figura_transporte &&
                    <Grid item xs={12}>
                        <FiguraTransporteCard data={props.cfdi.cfdi!.complementos.carta_porte.figura_transporte} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

interface GeneralesProps {
    data: CartaPorte;
}

function Generales(props: GeneralesProps) {
    return (
        <Box width="50%">
            <Card variant="outlined" >
                <Box pt={1} pl={2}><Typography component="h6" color="primary">{translate("cfdis.carta_porte.generales.title")}</Typography><br/></Box>
                <DetailsSection translation_prefix="cfdis.carta_porte.generales" data={props.data} />
            </Card>
        </Box>
    );
}

interface UbicacionesCardProps {
    data: Ubicaciones;
}

function UbicacionesCard(props: UbicacionesCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.ubicaciones.title")}</strong></Typography>
            <Grid container justify="flex-start" spacing={2}>
                {props.data.ubicaciones.map((ubicacion) => {
                    if (ubicacion.origen) {
                        return (
                            <Grid item xs={12}>
                                <Card variant="outlined" >
                                    <UbicacionHead variant="origen" ubicacion={ubicacion} />
                                    <Grid container justify="flex-start" >
                                        <Grid item xs={12} sm={6}>
                                            <DetailsSection translation_prefix="cfdis.carta_porte.ubicaciones.origen" data={ubicacion.origen} />
                                        </Grid>
                                        <Divider orientation="vertical" flexItem variant="middle" />
                                        <Grid item xs={12} sm={5}>
                                            <DetailsSection subtitle={translate("cfdis.carta_porte.domicilio.title") as string} translation_prefix="cfdis.carta_porte.domicilio" data={ubicacion.domicilio} />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        );
                    }
                    if (ubicacion.destino) {
                        return (
                            <Grid item xs={12}>
                                <Card variant="outlined" >
                                    <UbicacionHead variant="destino" ubicacion={ubicacion} />
                                    <Grid container justify="flex-start" >
                                        <Grid item xs={12} sm={6}>
                                            <DetailsSection translation_prefix="cfdis.carta_porte.ubicaciones.destino" data={ubicacion.destino} />
                                        </Grid>
                                        <Divider orientation="vertical" flexItem variant="middle" />
                                        <Grid item xs={12} sm={5}>
                                            {ubicacion.domicilio &&
                                                <DetailsSection subtitle={translate("cfdis.carta_porte.domicilio.title") as string} translation_prefix="cfdis.carta_porte.domicilio" data={ubicacion.domicilio} />
                                            }
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        );
                    }
                    return <></>;
                })}

            </Grid>
        </Box>
    );
}

interface UbicacionHeadProps {
    variant: string;
    ubicacion: Ubicacion;
}

function UbicacionHead(props: UbicacionHeadProps) {
    return (
        <>
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate(`cfdis.carta_porte.ubicaciones.${props.variant}.title`)}</Typography><br/>
                <Grid container justify="flex-start" >
                    {props.ubicacion.tipo_estacion && <>
                        <Grid item xs={12} sm={2}>
                            <div>
                                {translate("cfdis.carta_porte.ubicaciones.ubicacion.tipo_estacion")}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div>
                                {props.ubicacion.tipo_estacion}
                            </div>
                        </Grid>
                        <Grid item xs={false} sm={1}></Grid>
                    </>}
                    {props.ubicacion.distancia_recorrida && <>
                        <Grid item xs={12} sm={2}>
                            <div>
                                {translate("cfdis.carta_porte.ubicaciones.ubicacion.distancia_recorrida")}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div>
                                {props.ubicacion.distancia_recorrida}
                            </div>
                        </Grid>
                    </>}
                </Grid>
            </Box>
        </>
    );
}

interface DetailsSectionProps<T> {
    subtitle?: string;
    translation_prefix: string;
    data: T;
}

function DetailsSection<T>(props: DetailsSectionProps<T>) {
    return (
        <Box pb={2} px={2}>
            {props.subtitle &&
                <strong>
                    {props.subtitle}
                </strong>
            }
            <Grid container justify="flex-start" >
                {Object.keys(props.data).map(key => {
                    let translated = translate(`${props.translation_prefix}.${key}`) as string;
                    if (translated !== `${props.translation_prefix}.${key}` && (props.data as any)[key] !== undefined) {
                        return <>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    {translated}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div>
                                    {key.includes('fecha') ?
                                        <DateFormat date={(props.data as any)[key]} format="DD/MM/YYYY HH:mm:ss [hrs.]" /> :
                                        (props.data as any)[key]}
                                </div>
                            </Grid>
                        </>;
                    }
                    return <></>;
                })}
            </Grid>
        </Box>
    );
}
