import React, { useContext, useEffect, useState } from "react";
import Surface from "../components/Surface";
import translate from "../i18n/Translator";
import { DeleteIcon, EditIcon, PaymentIcon } from "../components/Icons";
import { Box, Button, Fab, Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Autocomplete } from "@material-ui/lab";
import Gridable from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { UserGroup, UserGroupRequest } from "../model/UserGroup";
import { User, UsersQueryParamsReceptors } from "../model/User";
import { AppContext } from "../context/AppContext";
import { Redirect, useHistory } from "react-router-dom";

import queryString from "query-string";
import { AutocompleteProvider } from "../model/Provider";
import { createGroup, getUserGroup, updateGroup } from "../api/UserGroupAPI";
import { getUsers } from "../api/TenantUserAPI";
import Progress from "../components/Progress";
import { RouterParams } from "../router/RouterParams";


export default function UserGroupForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const [group, setGroup] = useState<UserGroup>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<UserGroupRequest>({} as UserGroupRequest);
    const [redirect, setRedirect] = useState<string>();
    const [submitting, setSubmitting] = useState(false);
    const [userId, setUserId] = useState<string>();
    const [usersAutocomplete, setUsersAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [members, setMembers] = useState<User[]>([]);
    const [tenantUsers, setTenantUsers] = useState<User[]>([]);

    const load = () => {
        setStatus("loading");
        Promise.all([
            getUsers(context.session!.tenant!.id, 0, 0, {search: "", status: "ACTIVE", } as UsersQueryParamsReceptors),
            match.params.userGroupId ? getUserGroup(context.session!.tenant!.id, match.params.userGroupId) : undefined
        ]).then((responses) => {
            let users = responses[0].items;
            setTenantUsers(responses[0].items);
            fillUsersAutocomplete(responses[0].items.filter((user) => user.password_active && !user.blocked).sort((a,b) => a.name.localeCompare(b.name)));
            
            if (match.params.userGroupId && responses.length > 1) {
                setGroup(responses[1]);
                setRequest({
                    name: responses[1]!.name,
                    users: responses[1]!.users,
                    external_id: responses[1]!.external_id,
                } as UserGroupRequest);
                if(responses[1]!.users) {
                    let membersTemp = [] as User[];
                    responses[1]!.users.forEach(user => {
                        let member = users.find(tenantUser => user == tenantUser.id);
                        if(member){
                            membersTemp.push(member);
                        }
                    });
                    setMembers(membersTemp.sort((a, b) => a.name.localeCompare(b.name)));
                }
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, []);

    const fillUsersAutocomplete = (users : User[]) => {
        let listTemp = [] as AutocompleteProvider[];
        users.forEach((user) => {
            let temp = { title: user.name, value: user.id } as AutocompleteProvider;
            listTemp.push(temp);
        });
        setUsersAutocomplete(listTemp);
    }

    const containsLestOneMember = () => {
        return !!members && members.length > 0;
    }

    const onConfirm = () => {

        if (!request.name || request.name === "") {
            setWarning(translate("user_groups.form.name_empty") as string);
            return;
        }

        if (!request.external_id || request.external_id === "") {
            setWarning(translate("user_groups.form.external_id_empty") as string);
            return;
        }

        if (!containsLestOneMember()) {
            setWarning(translate("user_groups.form.members_empty") as string);
            return;
        }

        setSubmitting(true);
        const userGroupRequest = {
            ...request,
            users: members.map(member => member.id)
        } as UserGroupRequest;

        if (group) {
            updateGroup(context.session!.tenant!.id, group.id, userGroupRequest).then((response) => {
                setRedirect(`/tenants/user-groups`);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            createGroup(context.session!.tenant!.id, userGroupRequest).then((response) => {
                setRedirect(`/tenants/user-groups`);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }

    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
            setRequest({ ...request, [name]: value });
    };

    const onFilterChanged = (value: string, inputRef?: any) => {
        if (value !== undefined) {
            setUserId(value);
        }
    };

    const onAddValue = () => {
        if (!userId || userId === "") {
            setWarning(translate("user_groups.form.user_empty") as string);
            return;
        }
        let memberTemp = tenantUsers.find(tenantUser => userId == tenantUser.id);
        if (isDuplicate()) {
            return;
        }
        if(memberTemp) {
            members.push(memberTemp);
            setRequest({ ...request,
                name: request.name,
            } as UserGroupRequest);
        }
    }

    const isDuplicate = () => {
        var isDuplicate = false;
        members.forEach((member) => {
            if (member.id === userId) {
                setWarning(translate("user_groups.form.user_duplicated", { "name": member.name }) as string);
                isDuplicate = true;
            }
        });
        return isDuplicate;
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedDelete = (value: User) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onDelete(value);
    };

    const onDelete = (item: User) => {
        let valueToDelete = members.find(v => item.id === v.id);
        if (valueToDelete) {
            let index = members.indexOf(valueToDelete);
            if (index > -1) {
                members.splice(index, 1);
            }
        }
        setMembers(members.sort((a, b) => a.name.localeCompare(b.name)));
        setRequest({ ...request,
            name: request.name,
        } as UserGroupRequest);
    }

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }
return (
    <div>
        <Surface title={group ?  translate("user_groups.edit.title") as string : translate("user_groups.create.title") as string}
            subtitle={""}
            icon={<PaymentIcon />} className="PaperPagination PaperTabContainer"
            backButton onAction={history.goBack}>
            <Grid container justify="space-between" alignItems="center" >
                    <Grid item xs={12}>
                        <Grid container className="TableFilter" spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    {translate("user_groups.form.fields") as string}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="name" name="name" disabled={submitting}
                                    value={request.name} label={translate("user_groups.form.name") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="external_id" name="external_id" disabled={submitting}
                                    value={request.external_id} label={translate("user_groups.form.external_id") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                        </Grid>
                    </Grid>
                <Grid container className="TableFilter" alignItems="center" direction="row" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {translate("user_groups.form.members") as string}
                        </Typography>
                    </Grid>
                        <Grid container alignItems="center" direction="row" spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Autocomplete
                                    size="small"
                                    options={usersAutocomplete}
                                    getOptionLabel={(elemento) => elemento.title}
                                    noOptionsText={translate("user_groups.form.no_options_autocomplete") as string}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={translate("user_groups.form.members") as string} fullWidth />
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            onFilterChanged(newValue.value);
                                        } else {
                                            onFilterChanged("");
                                        }
                                    }}
                                    getOptionSelected={(opt, val) => opt.value === val.value}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={onAddValue} variant="outlined" size="large" color="secondary" disabled={!userId || userId === ""}>
                                    {translate("buttons.add")}
                                </Button>
                            </Grid>

                        </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <Box px={2}>
                        <Gridable
                            items={members}
                            loading={false}
                            empty={translate("user_groups.empty") as string}
                            columns={[
                                {
                                    title: translate("users.name") as string,
                                    converter: (value) => value.name,
                                    fullWidth: true,
                                    xs: true
                                },
                                {
                                    title: (
                                        <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    ),
                                    converter: (value) => (
                                        <IconButton
                                            aria-label="options"
                                            color="default"
                                            size="small"
                                            onClick={onClickedDelete(value)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    ),
                                    fullWidth: true,
                                    xs: "auto",
                                    sm: "auto",
                                    md: "auto",
                                    lg: "auto",
                                    xl: "auto"
                                }
                            ]}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box pt={4} pr={2}>
                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                <Grid item xs={12} md="auto">
                                    <Button variant="contained" color="primary" size="large" onClick={onConfirm} >
                                        {translate("buttons.save")}
                                    </Button>
                                </Grid>
                            <Grid item xs={12} md="auto">
                                <Button variant="text" color="primary" size="large" onClick={history.goBack}>
                                    {translate("buttons.cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Surface>
    </div>
);
}