import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { AppContext } from "./context/AppContext";
import SessionHolder from "./context/SessionHolder";
import { initTranslator } from "./i18n/Translator";
import AppColorTheme from "./theme.json";
import "./App.css";

// https://material-ui.com/customization/default-theme/
// https://material-ui.com/customization/color/
const theme = createMuiTheme(AppColorTheme);

function App() {
  const [context, setContext] = useState(() => {
    var actions = [];
    return {
      setSession: (session) => {
        setContext({ ...context, session: session });
        if (session) {
          actions = session.actions;
          initTranslator(session.user.language);
        } else {
          actions = [];
          initTranslator();
        }
      },
      isGranted: (action) => actions.findIndex((a) => (a === action)) >= 0,
      isGrantedAny: (all) => {
        for (let i = 0; i < all.length; i++) {
          if (actions.findIndex((a) => (a === all[i])) >= 0) {
            return true;
          }
        }
        return false;
      }
    };
  });

  

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={context}>
          <SessionHolder />
        </AppContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
