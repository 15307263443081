import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import translate from "../../i18n/Translator";
import { InputRef } from "../ValidatedInput";
import { WarningSnackbar } from "../Snackbars";
import DialogPopup from "../DialogPopup";

import Progress from "../Progress";
import ValidatedInput from "../ValidatedInput";
import DatePicker from "../DatePicker";
import moment from "moment";
import UploaderInput from "../UploaderInput";
import { AdditionalInfoField } from "../../model/AdditionalInfoWorkflove";
import { CatalogValue } from "../../model/Catalog";

export interface FieldItemUpdatePopupProps {
    tenantId: string;
    field: AdditionalInfoField;
    onSave(field: AdditionalInfoField):any;
    onClose(): any;
}

export default function FieldItemUpdatePopup(props: FieldItemUpdatePopupProps) {
    const [warning, setWarning] = useState<string>("");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<string>("");

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
    };

    const hasChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setRequest(date.getTime() + "");
        }
    };

    const hasChangedCatalog = (valueSelected: string) => {
        if (valueSelected) {
            let temp = request;
            temp = valueSelected !== "---" ? valueSelected : "";
            setRequest(temp);
        }
    };

    const onClosedSnackbar = () => {
        setWarning("");
    };

    const onSave = () => {
        setSubmitting(true);
        if(request !== ""){
            let updatedField = {...props.field, value: request} as AdditionalInfoField;
            props.onSave(updatedField);
        }
        setSubmitting(false);
    };

    return <DialogPopup open
            title={props.field.name}
            button={
                <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={submitting}>
                    {translate("buttons.save")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            maxWidth="lg"
            onClose={props.onClose}>
            <Grid container className="ShippingField">
                {props.field.name && (props.field.type.id === "FILE" || props.field.type.id === "DATE") && (
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {props.field.name}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FieldItemForm
                        tenantId={props.tenantId}
                        field={props.field}
                        value={props.field.value || ""}
                        onValueChanged={hasChanged}
                        onChangedDate={hasChangedDate}
                        onChangedCatalog={hasChangedCatalog}
                        disabled={false}
                    />
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
}

interface FieldItemFormProps {
    tenantId: string;
    value: string;
    field: AdditionalInfoField;
    disabled: boolean;
    onValueChanged?(name: string, value: string, inputRef: InputRef): void;
    onChangedDate(name: string, raw?: string, date?: Date): any;
    onChangedCatalog(valueSelected: string): void;
}

function FieldItemForm(props: FieldItemFormProps) {
    const [status, setStatus] = useState("loading");
    const [value, setValue] = useState<string>(props.value);
    const [valuesCatalog, setValuesCatalog] = useState<CatalogValue[]>([]);
    const [valueCatalog, setValueCatalog] = useState<CatalogValue>();

    const retriveCatalog = () => {
        setValuesCatalog(props.field.values_catalog.filter(c => !c.provider_id));
        props.field.values_catalog.forEach((value) => {
            if ("" + value.id === props.value) {
                setValueCatalog(value);
            }
        });
        setStatus("loaded");
    }

    useEffect(() => {
        if (props.field.type.name === "Catálogo") {
            retriveCatalog();
        } else {
            setValue(props.value);
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [props.field.id, props.field.type, props.tenantId, props.value]);

    const onChangedCatalogInsite = (valueSelected: string) => {
        props.field.values_catalog.forEach((value) => {
            if ("" + value.id === valueSelected) {
                setValueCatalog(value);
            }
        });
        props.onChangedCatalog(valueSelected);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (props.field.type.id === "FILE") {
        return (
            <UploaderInput
                // acceptExtension={props.field.extensions !== "all" ? props.field.extensions as string : undefined}
                acceptExtension={"all"}
                id={props.field.id}
                name={props.field.id}
                value={value}
                label={value ? "" : translate("cfdis.template.upload_file") as string}
                required={props.field.required}
                disabled={typeof props.disabled !== "undefined" && props.disabled}
                margin="dense"
                onValueChanged={props.onValueChanged}
            />
        );
    }

    if (props.field.type.id === "DATE") {
        return (
            <DatePicker
                label={""}
                name={props.field.id}
                initial={value ? moment(new Date(parseFloat(value))).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")}
                onChange={props.onChangedDate}
                defaultIfMissing={false}
                clearable={false} autoOK
                disablePast={false}
                disableFuture={false} />
        )
    }

    if (props.field.type.name === "Catálogo") {
        return (
            <Autocomplete
                size="small"
                options={valuesCatalog}
                value={valueCatalog}
                getOptionLabel={(elemento) => (
                    elemento.name !== "" ? (elemento.name) + (elemento.external_id !== "" ? " - " + elemento.external_id : "") : (elemento.external_id !== "" ? elemento.external_id : "")
                )}
                noOptionsText={translate("catalogs.no_options_autocomplete") as string}
                renderInput={(params) => (
                    <TextField style={{ width: "350px" }} {...params} variant="outlined" label={translate("catalogs.values.title") as string} />
                )}
                onChange={(event, newValue) => {
                    if (newValue) {
                        onChangedCatalogInsite("" + newValue.id);
                    } else {
                        onChangedCatalogInsite("");
                    }
                }}
                getOptionSelected={(opt, val) => opt.id === val.id}
            />
        )
    }

    return (
        <>
            {props.field.name ? props.field.name as string : ""}
            <ValidatedInput
                type={props.field.type.id.toLowerCase()}
                id={props.field.id}
                name={props.field.id}
                value={props.value}
                label={""}
                required={props.field.required}
                disabled={typeof props.disabled !== "undefined" && props.disabled}
                margin="dense"
                onValueChanged={props.onValueChanged}
                maxRows={10}
                rows={props.field.type.id === "TEXTAREA" ? 3 : undefined}
            />
        </>


    );

}