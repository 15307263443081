
import React, { useContext, useState } from "react";
import { Button, Grid, Typography, IconButton } from "@material-ui/core";
import translate from "../i18n/Translator";
import Ellipsis from "../components/Ellipsis";
import { EditIcon, AddIcon } from "../components/Icons";
import { formatDateToShow } from "../components/DateFormat";
import { AdditionalInfoField } from "../model/AdditionalInfoWorkflove";
import { AppContext } from "../context/AppContext";
import FieldItemUpdatePopup from "./FieldItems/FieldItemUpdatePopup";

interface AdditionalFieldsSectionProps {
    title: string;
    fields: AdditionalInfoField[]
    onSaveField(field: AdditionalInfoField): any;
}

export default function AdditionalFieldsSection(props: AdditionalFieldsSectionProps) {
    const context = useContext(AppContext);
    const [field, setField] = useState<AdditionalInfoField>();
    const [openEditField, setOpenEditField] = useState<boolean>(false);

    const onEditField = (field: AdditionalInfoField) => {
        setField(field);
        setOpenEditField(true);
    };

    const callToSaveField = (field: AdditionalInfoField) => {
        setOpenEditField(false);
        props.onSaveField(field);
    }

    const fieldContent = (field: AdditionalInfoField) => {
        if (!field.value) {
            if (!field.write) {
                return "---";
            }

            return <></>
        }

        if (field.type.id === 'DATE') {
            return formatDateToShow(new Date(parseFloat(field.value)));
        }

        if (field.type.id === 'FILE') {
            return (
                <IconButton aria-label="add" color="primary" size="small" href={field.value} target="_blank" rel="noopener noreferrer">
                    <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                </IconButton>
            );

        }

        if (field.type.name === "Catálogo") {
            const valueCatalog = field.values_catalog.filter(value => value.id === field.value);
            return (
                <>
                    {valueCatalog[0].name} - {valueCatalog[0].external_id}
                </>
            );
        }

        return field.value;
    };

    return (
        <>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <Typography><strong>{props.title}</strong></Typography>
                </Grid>
                {props.fields.map((field: AdditionalInfoField) => {
                    return (
                        <Grid key={field.id} item xs={12} md={6}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs="auto">
                                    <Ellipsis
                                        text={field.required ? (translate("cfdis.template.field_required", { "value": field.name }) as string) : field.name}
                                        lenght={37}
                                        uppercased={false} />
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                        {field.write && (
                                            <Grid item>
                                                {field.value ? (
                                                    <IconButton aria-label="add" color="primary" size="small" onClick={() => onEditField(field)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                ) : (
                                                    <Button aria-label="add" color="primary" size="small" onClick={() => onEditField(field)} startIcon={(
                                                        <AddIcon fontSize="small" />
                                                    )}>
                                                        <Typography variant="body2">{translate("cfdis.template.add")}</Typography>
                                                    </Button>
                                                )}
                                            </Grid>
                                        )}
                                        <Grid item>
                                            {fieldContent(field)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            {openEditField && field &&
                <FieldItemUpdatePopup
                    tenantId={context.session!.tenant!.id}
                    field={field}
                    onSave={callToSaveField}
                    onClose={() => {
                        setField(undefined);
                        setOpenEditField(false);
                    }}
                />

            }
        </>
    );

}