import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, Divider } from "@material-ui/core";
import DigitalDocumentIcon from "@material-ui/icons/AllInbox";

import { createDigitalDocument, getDigitalDocument, updateDigitalDocument, getFileUrl } from "../api/DigitalDocumentAPI";
import translate from "../i18n/Translator";
import { DigitalDocument, DigitalDocumentRequest } from "../model/DigitalDocument";
import SimpleSwitch from "../components/SimpleSwitch";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { IntegerValidator, GTZeroValidator } from "../components/Validators";

import { Expiration, ExpirationUnit, EXPIRATION_UNITS } from "../model/Expiration";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import UploaderInput from "../components/UploaderInput";
import DateFormat from "../components/DateFormat";

export default function DigitalDocumentForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [isOwner] = useState(context.session!.role.id === 'owner_issuer');
    const tenantId = context.session?.tenant?.id || "-";
    const history = useHistory();
    const isEdit = !!match.params.digitalDocumentId;
    const margin = "dense";
    const [warning, setWarning] = useState<string>();
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [digitalDocument, setDigitalDocument] = useState<DigitalDocument>();
    const [request, setRequest] = useState<DigitalDocumentRequest>({} as DigitalDocumentRequest);
    const [validations, setValidations] = useState({} as any);
    const [url, setUrl] = useState<string>();
    const [showExpirationOptions, setShowExpirationOptions] = useState<boolean>(false);
    const [monthValues, setMonthValues] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [expirationUnitLabels] = useState(() => {
        return EXPIRATION_UNITS.map(
            (type) => translate(`expedients.fields.expirationUnit.${type}`) as string
        );
    });

    const submitPromise = (): Promise<DigitalDocument> => {
        if (isEdit && match.params.digitalDocumentId) {
            return updateDigitalDocument(tenantId, match.params.digitalDocumentId, request);
        }
        return createDigitalDocument(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        let months = [] as string[];
        for (var i = 0; i < 12; i++) {
            months.push(`${i + 1}`);
        }

        setMonthValues(months);
        setMonthLabels(months.map((m) => translate(`months.${m}`) as string));
        if (isEdit) {
            Promise.all([
                getDigitalDocument(tenantId, match.params.digitalDocumentId),
                getFileUrl(tenantId, match.params.digitalDocumentId)
            ]).then(([digitalDocument, url]) => {
                setRequest({
                    name: digitalDocument.name,
                    description: digitalDocument.description,
                    file_name: digitalDocument.file_name,
                    expiration: digitalDocument.expiration,
                    url: url,
                } as DigitalDocumentRequest);

                setDigitalDocument(digitalDocument);
                setShowExpirationOptions(!!digitalDocument.expiration);
                setUrl(url);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setDigitalDocument(undefined);
            setStatus("loaded");
        }
    }, [tenantId, isEdit, match.params.digitalDocumentId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        if (name === "url") {
            setRequest({ ...request, url: value, file_name: inputRef?.original_name || "" });
        } else {
            setRequest({ ...request, [name]: value });
        }

        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedExpiration = (name: string, value: string, inputRef: InputRef) => {
        if (showExpirationOptions) {
            const expiration = request.expiration || {} as Expiration;
            if (name === "expirationQuantity") {
                setRequest({ ...request, expiration: { ...expiration, quantity: value ? +value : 0 } });
            } else if (name === "expirationUnit") {
                setRequest({ ...request, expiration: { ...expiration, unit: value as ExpirationUnit } });
            } else {
                setRequest({ ...request, expiration: { ...expiration, [name]: value } });
            }
        } else {
            setRequest({ ...request, expiration: undefined });
        }
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        if(request.expiration && ( request.expiration.day < 1 || request.expiration.day > 31 )){
            setWarning(translate("digital_documents.warning_day") as string);
            return;
        }
        setSubmitting(true);
        submitPromise().then((digitalDocument) => {
            history.push("/digital-documents");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const handleExpirationOptions = (name: string, checked: boolean) => {
        if (checked) {
            if (digitalDocument && digitalDocument.expiration) {
                setRequest({ ...request, expiration: { unit: digitalDocument.expiration.unit, quantity: digitalDocument.expiration.quantity, day: digitalDocument.expiration.day, initial_month: digitalDocument.expiration.initial_month } });
            } else {
                setRequest({ ...request, expiration: { unit: "MONTH", quantity: 1, day: 1, initial_month: 1 } });
            }
        } else {
            setRequest({ ...request, expiration: undefined });
        }
        setShowExpirationOptions(checked)
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (isOwner) {
        return (<>
            <OwnerForm
                isEdit={isEdit}
                submitting={submitting}
                request={request}
                margin={margin}
                url={url}
                tenantId={tenantId}
                showExpirationOptions={showExpirationOptions}
                expirationUnitLabels={expirationUnitLabels}
                error={error}
                onClosedSnackbar={onClosedSnackbar}
                hasChanged={hasChanged}
                onSubmit={onSubmit}
                handleExpirationOptions={handleExpirationOptions}
                hasChangedExpiration={hasChangedExpiration}
                expires_at={new Date()}
                monthLabels={monthLabels}
                monthValues={monthValues}
            />
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
            </>
        );
    }

    return (
        <SupervisorForm
            isEdit={isEdit}
            submitting={submitting}
            request={request}
            margin={margin}
            url={url}
            tenantId={tenantId}
            showExpirationOptions={showExpirationOptions}
            expirationUnitLabels={expirationUnitLabels}
            error={error}
            onClosedSnackbar={onClosedSnackbar}
            hasChanged={hasChanged}
            onSubmit={onSubmit}
            handleExpirationOptions={handleExpirationOptions}
            hasChangedExpiration={hasChangedExpiration}
            expires_at={digitalDocument ? digitalDocument.expires_at : new Date()}
            monthLabels={monthLabels}
            monthValues={monthValues}
        />
    );
}

interface OwnerFormProps {
    isEdit: boolean;
    submitting: boolean;
    request: DigitalDocumentRequest;
    margin: "normal" | "dense" | "none";
    url: string | undefined;
    tenantId: string;
    showExpirationOptions: boolean;
    expirationUnitLabels: string[];
    error: string | undefined;
    expires_at: Date;
    monthValues: string[];
    monthLabels: string[];
    onClosedSnackbar(): any;
    hasChanged(name: string, value: string, inputRef: InputRef): any;
    onSubmit(event: React.FormEvent<HTMLFormElement>): any;
    handleExpirationOptions(name: string, checked: boolean): any;
    hasChangedExpiration(name: string, value: string, inputRef: InputRef): any;
}

function OwnerForm (props: OwnerFormProps){
    const intergerValidator = new IntegerValidator(true);
    const gtZeroValidator = new GTZeroValidator();
    const history = useHistory();
    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(props.isEdit ? "digital_documents.edit" : "digital_documents.new")} icon={<DigitalDocumentIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={props.onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={props.request.name}
                                        label={translate("digital_documents.name") as string}
                                        required={true} disabled={props.submitting}
                                        margin={props.margin}
                                        onValueChanged={props.hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="description" name="description"
                                        value={props.request.description ? props.request.description : ""}
                                        label={translate("digital_documents.description") as string}
                                        required={false} disabled={props.submitting}
                                        margin={props.margin} rows={4}
                                        onValueChanged={props.hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <UploaderInput id="url" name="url"
                                        value={props.url || props.request.url}
                                        label={translate("digital_documents.url") as string}
                                        required disabled={props.submitting}
                                        margin={props.margin}
                                        path={`tenants/${props.tenantId}/digital_documents/file`}
                                        onValueChanged={props.hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleSwitch
                                        value="required"
                                        label={translate("expedients.fields.expirationUnit.title") as string}
                                        checked={props.showExpirationOptions}
                                        onChanged={props.handleExpirationOptions}
                                        placement="end" />
                                    <ValidatedInput
                                        type="text"
                                        id="expirationUnit"
                                        name="expirationUnit"
                                        value={props.request.expiration ? props.request.expiration.unit : ""}
                                        label={translate("expedients.fields.expirationUnit.unit") as string}
                                        required={props.showExpirationOptions}
                                        disabled={!props.showExpirationOptions}
                                        margin={props.margin}
                                        options={EXPIRATION_UNITS}
                                        optionLabels={props.expirationUnitLabels}
                                        onValueChanged={props.hasChangedExpiration}
                                    />
                                    <ValidatedInput
                                        type="number"
                                        id="expirationQuantity"
                                        name="expirationQuantity"
                                        value={(props.request.expiration ? props.request.expiration.quantity : 0).toString()}
                                        label={
                                            translate("expedients.fields.expirationUnit.quantity") as string
                                        }
                                        required={props.showExpirationOptions}
                                        disabled={!props.showExpirationOptions}
                                        validators={props.showExpirationOptions ? [intergerValidator, gtZeroValidator] : undefined}
                                        margin={props.margin}
                                        onValueChanged={props.hasChangedExpiration}
                                    />
                                    <ValidatedInput
                                        type="number"
                                        id="day"
                                        name="day"
                                        value={(props.request.expiration ? props.request.expiration.day : 0).toString()}
                                        label={
                                            translate("expedients.fields.expirationUnit.day") as string
                                        }
                                        required={props.showExpirationOptions}
                                        disabled={!props.showExpirationOptions}
                                        validators={props.showExpirationOptions ? [intergerValidator, gtZeroValidator] : undefined}
                                        margin={props.margin}
                                        onValueChanged={props.hasChangedExpiration}
                                    />
                                    <ValidatedInput
                                        type="number"
                                        id="initial_month"
                                        name="initial_month"
                                        value={(props.request.expiration ? props.request.expiration.initial_month : 0).toString()}
                                        label={
                                            translate("expedients.fields.expirationUnit.initial_month") as string
                                        }
                                        options={props.monthValues}
                                        optionLabels={props.monthLabels}
                                        required={props.showExpirationOptions}
                                        disabled={!props.showExpirationOptions}
                                        margin={props.margin}
                                        onValueChanged={props.hasChangedExpiration}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={props.submitting}>
                                                    {translate(props.isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={props.submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={props.error} onClose={props.onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid >
    );
}

function SupervisorForm (props: OwnerFormProps){
    const history = useHistory();
    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(props.isEdit ? "digital_documents.edit" : "digital_documents.new")} icon={<DigitalDocumentIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={props.onSubmit} >
                        <Grid container justify="space-between" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Typography ><strong>{props.request.name}</strong></Typography>
                                <Typography >{props.request.description }</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography ><strong>{translate("Expiración")}</strong></Typography> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography >{props.request.expiration ? "Activa" : "Inactiva"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography ><strong>{translate("Plazo")}</strong></Typography> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography >{props.request.expiration?.unit}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography ><strong>{translate("Frecuencia")}</strong></Typography> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography >{props.request.expiration?.quantity}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography ><strong>{translate("Vencimiento")}</strong></Typography> 
                                    </Grid>
                                    <Grid item xs={6} style={{ "color": props.expires_at < new Date() ? "#f44336" : "" }}>
                                        <DateFormat date={props.expires_at} format="DD/MM/YYYY" />
                                    </Grid>
                                </Grid>
                                <br/><Divider /> <br/>
                            </Grid>
                            <Grid item xs={12}>
                                <UploaderInput id="url" name="url"
                                    value={props.url || props.request.url}
                                    label={translate("digital_documents.url") as string}
                                    required disabled={props.submitting}
                                    margin={props.margin}
                                    path={`tenants/${props.tenantId}/digital_documents/file`}
                                    onValueChanged={props.hasChanged} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                        <Grid item xs={12} md="auto">
                                            <Button type="submit" variant="contained" color="primary" size="large" disabled={props.submitting}>
                                                {translate(props.isEdit ? "buttons.update" : "buttons.add")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md="auto">
                                            <Button variant="text" color="primary" size="large" disabled={props.submitting} onClick={history.goBack}>
                                                {translate("buttons.cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        </form>
                        <ErrorSnackbar message={props.error} onClose={props.onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid >
    );
}