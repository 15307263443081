import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from '@material-ui/core';
import { WebhookIcon } from "../components/Icons";
import { createWebhook, getWebhook, getWebhookMetadata, updateWebhook } from "../api/WebhooksAPI";
import translate from "../i18n/Translator";
import { Webhook, WebhookRequest, WebhookHeader } from '../model/Webhooks';
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import Ellipsis from "../components/Ellipsis";
import GroupCheckbox from "../components/GroupCheckbox";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Gridable from '../components/Gridable';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import { RemoveSpacesValidator } from '../components/Validators';
import DialogPopup from '../components/DialogPopup';

export default function WebhookForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const webhookId = match.params.webhookId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<WebhookRequest>({ events: [] as string[], headers: [] as WebhookHeader[] } as WebhookRequest);

    const theme = useTheme();
    const isXs = useMediaQuery(() => theme.breakpoints.down("xs"));
    const [actionsChecks, setActionsChecks] = useState<boolean[]>([]);
    const [webhookEvents, setWebhookEvents] = useState<string[]>([]);
    const removeSpacesValidator = new RemoveSpacesValidator();
    const [header, setHeader] = useState<WebhookHeader>({} as WebhookHeader);
    const [selectedHeader, setSelectedHeader] = useState<WebhookHeader>();

    const [validations, setValidations] = useState({
        "title": {
            valid: false
        } as InputRef,
        "url": {
            valid: false
        } as InputRef
    } as any);

    const submitPromise = (): Promise<Webhook> => {
        if (webhookId) {
            return updateWebhook(tenantId, webhookId, request);
        }
        return createWebhook(tenantId, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (webhookId) {
            getWebhookMetadata(tenantId).then(response => {
                getWebhook(tenantId, webhookId).then((webhook) => {
                    setRequest({
                        title: webhook.title,
                        url: webhook.url,
                        events: webhook.events,
                        headers: webhook.headers,
                        status: webhook.status
                    } as WebhookRequest);
                    setActionsChecks(response.events.map(event => webhook.events.indexOf(event) >= 0));
                    setStatus("loaded");
                }).catch((error) => {
                    setStatus(error.message);
                });
                setWebhookEvents(response.events);
            }).catch(error => {
                setStatus(error.message);
            });
        } else {
            getWebhookMetadata(tenantId).then(response => {
                setWebhookEvents(response.events);
                setActionsChecks(webhookEvents.map(() => false));
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        }
    }, [tenantId, webhookId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };


    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }
        if (request.events.length <= 0) {
            setError(translate("webhooks.events_required") as string);
            return;
        }

        setSubmitting(true);
        submitPromise().then((webhook) => {
            history.push("/webhooks");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    const hasChangedCheckboxGroup = (name: string, value: string, checked: boolean) => {
        const index = webhookEvents.findIndex((el) => el === value);
        if (index >= 0) {
            actionsChecks[index] = checked;
            setActionsChecks(actionsChecks);

            const i = request.events.indexOf(value);
            if (checked) {
                if (i < 0) {
                    request.events.push(value);
                }
            } else if (i >= 0) {
                request.events.splice(i, 1);
            }
            setRequest({ ...request, events: request.events });
        }
    };

    const onDeleteHeader = (header: WebhookHeader) => {
        setSelectedHeader(header);
    }

    const onAddHeader = () => {
        if (!!header.header && !!header.value) {
            const current = request.headers.filter(e => e.header.toLowerCase() === header.header.toLowerCase());
            if (current.length > 0) {
                setError(translate("webhooks.header_duplicated") as string);
                return;
            }
            const headers = [...request.headers, header];
            headers.sort((a, b) => (a.header > b.header) ? 1 : -1)
            setRequest({ ...request, headers: headers });
            setHeader({} as WebhookHeader);
            return;
        }
        setError(translate("webhooks.header_required") as string);
    }

    const onValueChanged = (name: string, value: string, inputRef: any) => {
        setHeader({ ...header, [name]: value });
    };

    const onConfirmDeleteHeader = () => {
        if (selectedHeader) {
            setRequest({ ...request, headers: request.headers.filter(e => !(e.header === selectedHeader.header)) });
            onClosePopup();
        }
    };

    const onClosePopup = () => {
        setSelectedHeader(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(webhookId ? "webhooks.edit" : "webhooks.new")} subtitle={translate(webhookId ? "webhooks.subtitle.edit" : "webhooks.subtitle.new")} icon={<WebhookIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="title" name="title"
                                        value={request.title}
                                        label={translate("webhooks.form.title") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="url" name="url"
                                        value={request.url}
                                        label={translate("webhooks.form.url") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged}
                                        startAdornment={
                                            <Box mr={2}>
                                                <Ellipsis text={"post"} uppercased={true} />
                                            </Box>}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <GroupCheckbox title={translate("webhooks.form.events") as string} row={!isXs}
                                        name="events"
                                        values={webhookEvents}
                                        labels={webhookEvents}
                                        checkeds={actionsChecks}
                                        required={true}
                                        helper={translate("validations.required") as string}
                                        inputRef={validations["events"]}
                                        onChanged={hasChangedCheckboxGroup} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{ "marginTop": "1%" }} variant="inherit">
                                        {translate("webhooks.form.headers") as string}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="flex-start" justifyContent="flex-start" className="TableFilter" spacing={1}>
                                        <Grid item xs={12} sm>
                                            <ValidatedInput type="text" id="header" name="header" label={translate("webhooks.columns.header") as string}
                                                margin="dense" disabled={submitting}
                                                value={header.header}
                                                onValueChanged={onValueChanged}
                                                validator={removeSpacesValidator}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm>
                                            <ValidatedInput type="text" id="value" name="value" label={translate("webhooks.columns.value") as string}
                                                margin="dense" disabled={submitting}
                                                value={header.value}
                                                onValueChanged={onValueChanged}
                                                validator={removeSpacesValidator}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Box pt={1}>
                                                <Button variant="outlined" color="primary" size="large" disabled={submitting} onClick={() => onAddHeader()}>
                                                    {translate("buttons.add")}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Gridable
                                    items={request.headers ?? []}
                                    empty={translate("webhooks.empty") as string}
                                    columns={[
                                        {
                                            title: translate("webhooks.columns.header") as string,
                                            converter: (header: WebhookHeader) => (
                                                <Ellipsis text={header.header} lenght={100} uppercased={false} />
                                            ),
                                            xs: 5,
                                            sm: 5,
                                            lg: 5,
                                            xl: 5
                                        },
                                        {
                                            title: translate("webhooks.columns.value") as string,
                                            converter: (header: WebhookHeader) => (
                                                <Ellipsis text={header.value} uppercased={false} />
                                            ),
                                            xs: true,
                                            sm: true,
                                            lg: true,
                                            xl: true
                                        },
                                        {
                                            title: (
                                                <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            ),
                                            converter: (header: WebhookHeader) => (
                                                <IconButton aria-label="options" color="default" size="small" onClick={() => onDeleteHeader(header)}>
                                                    <DeleteIcon fontSize="small" color="error" />
                                                </IconButton>
                                            ),
                                            xs: "auto"
                                        }
                                    ]} />
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(webhookId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        {selectedHeader &&
                            <DialogPopup
                                open
                                title={translate("webhooks.delete_header.title")}
                                onClose={onClosePopup}
                                closeColor="default"
                                disableEscapeKeyDown={submitting}
                                disableBackdropClick={submitting}
                                disable={submitting}
                                button={
                                    <Button
                                        onClick={onConfirmDeleteHeader}
                                        variant="outlined"
                                        color="secondary"
                                        disabled={submitting}
                                    >
                                        {translate("buttons.delete")}
                                    </Button>
                                }>
                                {translate("webhooks.delete_header.text", { header: selectedHeader.header, valor: selectedHeader.value })}
                            </DialogPopup>}
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}