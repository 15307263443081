import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { CustomReport } from "../model/CustomReport";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";

interface CustomReportsMenuProps {
    customreport: CustomReport;
    anchor: HTMLElement | null;
    onClose(): any;
    onEdit(): any;
    onDelete(): any;
    onChangeStatus(): any;
}

export default function CustomReportsMenu(props: CustomReportsMenuProps){

    const context = useContext(AppContext);
    return (
        <Grid>
            <Menu
            id="custom-reports-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            {context.isGrantedAny(["CompaniesRead"]) && //CustomReportsDelete
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CompaniesRead"]) && //CustomReportsUpdate
                <MenuItem button onClick={props.onEdit} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CompaniesRead"]) && //CustomReportsUpdate
                <MenuItem button onClick={props.onChangeStatus} dense>
                    <ListItemIcon>
                        <ToggleOn fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{ (props.customreport.status === "ACTIVE") ? translate("buttons.desactivate") : translate("buttons.activate") }</Typography>
                </MenuItem>
            }
            </Menu>
        </Grid>
    );

}