import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";

import { User, UserDeleteResponse } from "../model/User";
import { deleteUser } from "../api/UserAPI";
import { deleteUser as deleteTenantUser } from "../api/TenantUserAPI";
import { deleteUser as deleteProviderUser } from "../api/ProviderUserAPI";
import { deleteUser as deleteCustomerUser } from "../api/CustomerUserAPI";
import DialogPopup from "../components/DialogPopup";

interface UserDeletePopupProps {
    user: User;
    tenantId?: string;
    providerId?: string;
    customerId?: string;
    onCompleted(): any;
    onError(error: string): any;
    onCancelled(): any;
}

export default function UserDeletePopup(props: UserDeletePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const deletePromise = (user: User): Promise<void> | Promise<UserDeleteResponse> => {
        if (props.tenantId) {
            if (props.providerId) {
                return deleteProviderUser(props.tenantId, props.providerId, user.id);
            }
            if (props.customerId) {
                return deleteCustomerUser(props.tenantId, props.customerId, user.id);
            }
            return deleteTenantUser(props.tenantId, user.id);
        }
        return deleteUser(user.id);
    };

    const onConfirm = () => {
        setSubmitting(true);
        deletePromise(props.user).then((response) => {
            setSubmitting(false);
            if(response && response.message){
                props.onError(response.message);
            } else {
                props.onCompleted();
            }
        }).catch((error) => {
            setSubmitting(false);
            props.onError(error.message);
        });
    };

    return (
        <DialogPopup open
            title={translate("users.delete.title") as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onConfirm} variant="outlined" color="primary" disabled={submitting}>
                    {translate("buttons.delete")}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate("users.delete.description", { "first_name": props.user.first_name })}
            </Typography>
        </DialogPopup>
    );

}