import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import translate from "../../i18n/Translator";
import { Grid, Box, Button} from "@material-ui/core";
import ValidatedInput, { InputRef, isValid } from "../../components/ValidatedInput";
import { RequestSignManifest, RequestSignManifestCert } from "../../model/ManifestTaxStamp";
import { signContractManifest } from "../../api/ProviderMembershipTaxStampApi";
import UploaderInput from "../../components/UploaderInput";

export interface UploadFielParams {
    setContractUrl(contract_url: string): any;
    setPrivacyUrl(privacy_url: string): any;
    data: RequestSignManifest;
    setActiveStep(): any;
    setSubmitting(submitting: boolean): any;
    setError(message: any): any;
    handleBack(): any;
}

export default function UploadFiel(params: UploadFielParams){
    const context = useContext(AppContext);
    const [validations, setValidations] = useState({} as any);
    const [request, setRequest] = useState<RequestSignManifestCert>({
        provider_name: "",
        provider_rfc: context.session?.provider?.rfc || "",
        email: "",
        password: "",
        address: "",
        cer_url: "",
        key_url: ""
    });

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const signContract = () => {
        if (!isValid(validations)) {
            return;
        }
        let reqTemp = {...params.data, password: request.password, cer_url: request.cer_url, key_url: request.key_url} as RequestSignManifestCert;
        params.setSubmitting(true);
        signContractManifest(reqTemp).then(response => {
            if(context.session && context.session.provider){
                context.session.provider.has_manifest = true;
            }
            params.setPrivacyUrl(response.privacy_url);
            params.setContractUrl(response.contract_url);
            params.setActiveStep();
        }).catch(error => {
            params.setError(error.message);
        }).finally(() => params.setSubmitting(false));
     };

    return <>
        <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <Grid item >
                <Box pb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UploaderInput id="cer_url" name="cer_url"
                                label={translate("tax_stamp.sign_manifest.cer") as string}
                                value={request.cer_url} disabled={false}
                                margin="dense" path="cer" acceptExtension=".cer"
                                onValueChanged={hasChanged}
                                required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <UploaderInput id="key_url" name="key_url"
                                label={translate("tax_stamp.sign_manifest.key") as string}
                                value={request.key_url} disabled={false}
                                margin="dense" path="key" acceptExtension=".key"
                                onValueChanged={hasChanged}
                                required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="password"
                                id="password" name="password"
                                label={translate("tax_stamp.sign_manifest.password") as string}
                                value={request.password}
                                required={true}
                                margin={"dense"}
                                disabled={false}
                                onValueChanged={hasChanged} />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
                <Box pb={2}>
                    <Grid item>
                        <Button disabled={false} onClick={params.handleBack} >
                            {translate("buttons.back") as string}
                        </Button>
                        <Button variant="contained" color="primary" onClick={signContract} >
                            {translate("buttons.next") as string}
                        </Button>
                    </Grid>
                </Box>
            </Grid>
    </>;
}