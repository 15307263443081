import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Gridable from "../components/Gridable";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { GTEZeroValidator, DecimalsValidator } from "../components/Validators";
import RemoveIcon from "@material-ui/icons/HighlightOffTwoTone";

import translate from "../i18n/Translator";
import { Retention } from "../model/ReceptionRule";
import { ServiceProduct } from "../model/ServiceProduct";

interface RetentionsAddedListSectionProps {
    retentions: Retention[];
    serviceProducts: ServiceProduct[];
    onAddedValue(value: number, tax: string, key: string): any;
    onRemoveItem(key: string): any;
}

export default function RetentionsAddedListSection(props: RetentionsAddedListSectionProps) {
    const [retentions, setRetentions] = useState<Retention[]>([]);
    const gtMaxDecimalsValidator = new DecimalsValidator(6);
    const gtZeroValidator = new GTEZeroValidator();

    useEffect(() => {
        let retentions = props.retentions;
        setRetentions(retentions);
    }, [props]);

    const getDescription = (item: Retention) => {
        if (!item || !props.serviceProducts) {
            return "---";
        }
        let key = item.product_service_key;
        if (!key) {
            return "---";
        }
        let servProd = props.serviceProducts.find(c => c.code === key);
        return servProd ? servProd.description : "---";
    }

    const onUpdatePercentaje = (name: string, value: string, inputRef: InputRef) => {
        if(value !== undefined){
            let key = "";
            let tax = "";
            if(name.includes("isr")){
                key = name.replace("retention_isr", "");
                tax = "retention_isr"
            } else {
                key = name.replace("retention_iva", "");
                tax = "retention_iva"
            }
            props.onAddedValue(Number.parseFloat(value), tax, key);
        }
    }

    const onClickedOptions = (item: Retention, anchorEl: null | HTMLElement) => {
        if(!item){
            return;
        }
        props.onRemoveItem(item.product_service_key);
    };

    return (
        <Grid item xs={12}>
            <div style={{}}>
                <Gridable
                    items={retentions ? retentions : []}
                    loading={false}
                    empty={translate("specific_reception_rule.service_product.empty") as string}
                    columns={[
                        {
                            title: translate("reception_rule.retention_validation.service_product") as string,
                            converter: (retention) => getDescription(retention),
                            xs: 4,
                        },
                        {
                            title: translate("reception_rule.retention_validation.isr_retention") as string,
                            converter: (item) => (
                                    <ValidatedInput
                                        type="number"
                                        id={"retention_isr"}
                                        name={item.product_service_key + "retention_isr"}
                                        value={item.retention_isr ? item.retention_isr + "" : "0.00"}
                                        isValueNumber={true}
                                        disableDefaultValidator
                                        validators={[gtZeroValidator, gtMaxDecimalsValidator]}
                                        label={""}
                                        required={true}
                                        margin="dense"
                                        onValueChanged={onUpdatePercentaje}
                                    />
                            ),
                            xs: 3,
                        },
                        {
                            title: translate("reception_rule.retention_validation.iva_retention") as string,
                            converter: (item) => (
                                    <ValidatedInput
                                        type="number"
                                        id={"retention_iva"}
                                        name={item.product_service_key + "retention_iva"}
                                        value={item.retention_iva ? item.retention_iva + "" : "0.00"}
                                        isValueNumber={true}
                                        disableDefaultValidator
                                        validators={[gtZeroValidator, gtMaxDecimalsValidator]}
                                        label={""}
                                        required={true}
                                        margin="dense"
                                        onValueChanged={onUpdatePercentaje}
                                    />
                            ),
                            xs: 3,
                        },
                        {
                            title: "",
                            converter: (item) => (
                                    <IconButton aria-label="options" color="default" size="small" onClick={(event: React.MouseEvent<HTMLElement>) => onClickedOptions(item, event.currentTarget)}>
                                        <RemoveIcon />
                                    </IconButton>
                            ),
                            justify: "flex-end",
                            xs: true,
                            sm: true,
                            md: true,
                            lg: true,
                            xl: true
                        }
                    ]}
                />
            </div>
        </Grid>
    );
}
