import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { Catalogs, Catalog, CatalogsQueryParams, CatalogRequest, CatalogValues, ExportCatalogValues, CatalogValueValidateResponse } from "../model/Catalog";

const basePath = (tenantId: string) => `/tenants/${tenantId}/catalogs`;

export async function listCatalogs(tenantId: string, page: number, offset: number, params: CatalogsQueryParams): Promise<Catalogs> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createCatalog(tenantId: string, request: CatalogRequest): Promise<Catalog> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getCatalog(tenantId: string, id: string): Promise<Catalog> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateCatalog(tenantId: string, id: string, request: CatalogRequest): Promise<Catalog> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCatalog(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function importValues(tenantId: string, file: File, catalogId?: string): Promise<CatalogValues> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    if (catalogId) {
        data.append("catalogId", catalogId);
    }

    return await callAPI(`${basePath(tenantId)}/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function exportValues(tenantId: string, catalogId: string, connectorId: string): Promise<ExportCatalogValues> {
    return await callAPI(`${basePath(tenantId)}/${catalogId}/export`, {
        method: HTTPMethod.POST,
        body: {
            connector_id: connectorId
        }
    });
}

export async function validateCatalogValue(tenantId: string, catalogId: string, catalogValueId: string): Promise<CatalogValueValidateResponse> {
    return await callAPI(`${basePath(tenantId)}/${catalogId}/values/${catalogValueId}/validate`, {
        method: HTTPMethod.GET,
    });
}

export async function downloadImportTemplate(tenantId: string, connectorId: string): Promise<string> {
    return await getAuthenticatedGetUrl(`${basePath(tenantId)}/import`, {
        query: {
            connectorId: connectorId,
        },
    });
}