import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { Cfdi, CfdiRequest, Cfdis } from "../model/Cfdi";
import { PrepareCfdiRequest, CommentRequest, CfdisQueryParams } from "../model/CfdiSe";
import { CfdiHistories } from "../model/CfdiHistory";
import { ExportCfdisResponse, TenantCfdisRequest } from "../model/TenantCfdi";

const basePath = (tenantId: string) => `/tenants/${tenantId}/se/cfdis`;

export async function list(tenantId: string, pageSize: number, offset: number, params: CfdisQueryParams): Promise<Cfdis> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.GET,
        query: parseCfdiQuery(params, pageSize, offset)
    });
}

export async function createCfdi(tenantId: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    data.append("companyId", request.company_id || "");

    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function get(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}`, {
        method: HTTPMethod.GET,
    });
}

export async function deleteCfdi(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}`, {
        method: HTTPMethod.DELETE,
    });
}

export async function prepare(tenantId: string, cfdiId: string, request: PrepareCfdiRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/prepare`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function editPrepare(tenantId: string, cfdiId: string,): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/prepare`, {
        method: HTTPMethod.PATCH
    });
}

export async function send(tenantId: string, cfdiId: string, request: CommentRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/send`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getHistory(tenantId: string, cfdiId: string, pageSize: number, offset: number): Promise<CfdiHistories> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/history`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: pageSize.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getDocumentsUrl(tenantId: string, cfdiId: string): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId)}/${cfdiId}/documents`);
}

export async function reject(tenantId: string, cfdiId: string, request: CommentRequest): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/reject`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function accept(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/accept`, {
        method: HTTPMethod.PUT,
    });
}

export async function resend(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/resend`, {
        method: HTTPMethod.PUT,
    });
}

export async function sendDocuments(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/send-documents`, {
        method: HTTPMethod.PUT,
    });
}

export async function exportAllCfdisRange(tenantId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.GET,
        query: parseCfdiQuery(params, 0, 0)
    });
}

export async function exportCfdisSelected(tenantId: string, request: TenantCfdisRequest): Promise<ExportCfdisResponse> {
    return await callAPI(`${basePath(tenantId)}/export-selected`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function exportCfdisExcel(tenantId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`${basePath(tenantId)}/export-cfdis-excel`, {
        method: HTTPMethod.GET,
        query: parseCfdisExcel(params),
    });
}

const parseCfdisExcel = (params: CfdisQueryParams) => {
    let query = parseCfdiQuery(params, 0, 0);
        if(params.selected_ids){
            query = {...query,
                selectedIds: params.selected_ids || "",
            };
        }
    return query;
}

const parseCfdiQuery = (params: CfdisQueryParams, pageSize: number, offset: number) => {
    return {
        identifier: params.identifier || "",
        customer: params.customer || "",
        receptor: params.receptor || "",
        provider: params.provider || "",
        statuses: params.statuses.join(","),
        connectorId: params.connector_id || "",
        date: params.date || "",
        from: params.from || "",
        to: params.to || "",
        dueDateComplete: params.due_date_complete || "",
        pageSize: pageSize+"",
        offset: offset+"",
    } as Record<string, string>;
}

export async function getCfdiRejectToSend(tenantId: string, cfdiId: string): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/${cfdiId}/reject-to-send`, {
        method: HTTPMethod.GET,
    });
}