import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from "../../context/AppContext";
import translate from "../../i18n/Translator";
import { Grid, Box, Button} from "@material-ui/core";
import ValidatedInput, { InputRef, isValid } from "../../components/ValidatedInput";
import { ManifestContract, RequestSignManifest } from "../../model/ManifestTaxStamp";
import { getCfdis } from "../../api/ProviderCfdiApi";
import { CfdisQueryParams } from "../../model/Cfdi";
import { EmailValidator, RfcValidator } from "../../components/Validators";
import { getContractManifest } from "../../api/ProviderMembershipTaxStampApi";
import { getProvider } from "../../api/ProviderAPI";
import Progress from "../../components/Progress";

export interface GetContractFormParams {
    setRequest(request: RequestSignManifest): any;
    setActiveStep(): any;
    setManifestContract(response: ManifestContract): any;
    setSubmitting(submitting: boolean): any;
    setError(message: any): any;
}

export default function GetContractForm(params: GetContractFormParams){
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "";
    const providerId = context.session?.provider?.id || "";
    const emailValidator = new EmailValidator();
    const [validations, setValidations] = useState({} as any);
    const rfcValidator = new RfcValidator();
    
    const [request, setRequest] = useState<RequestSignManifest>({
        provider_name: "",
        provider_rfc: context.session?.provider?.rfc || "",
        email: "", 
        address: ""
    });
    const [blockProviderData, setBlockProviderData] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");

    const load = () => {
        setStatus("loading");
        Promise.all([
            getCfdis(tenantId, providerId, "", 1, 0, {} as CfdisQueryParams),
            getProvider(tenantId, providerId)
        ]).then(responses => {
            let address = "";
            let email = context.session?.user.email || "";
            let provider_rfc = "";
            let provider_name = "";
            if(responses[1] && responses[1].expedient && Object.values(responses[1].expedient.addresses).length >= 1 
            && Object.values(responses[1].expedient.addresses)[0].value !== undefined){
                let addressObj = Object.values(responses[1].expedient.addresses)[0].value;
                address += getSecureString(addressObj.street);
                address += getSecureString(addressObj.exterior_number);
                address += getSecureString(addressObj.interior_number);
                address += getSecureString(addressObj.suburb);
                address += getSecureString(addressObj.city);
                address += getSecureString(addressObj.country);
                address += getSecureString(addressObj.zip_code);
            }
            if(responses[0].total >= 1){
                provider_rfc = responses[0].items[0].cfdi?.emisor.rfc || "";
                provider_name = responses[0].items[0].cfdi?.emisor.nombre || "";
                setBlockProviderData(!!responses[0].items[0].cfdi?.emisor.rfc);
            }
            setRequest((cur) => {
                return {
                    ...cur, 
                    provider_rfc: provider_rfc,
                    provider_name: provider_name,
                    address: address,
                    email: email
                };
            });
        }).catch(error => {
            params.setError(error.message);
        }).finally(() => setStatus("loaded"));
    };

    useEffect(load, [tenantId, providerId]);

    const getSecureString = (obj: any) => {
        if(obj === undefined){
            return "";
        }
        return obj + " ";
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = () => {
        if (!isValid(validations)) {
            return;
        }
        params.setSubmitting(true);
        getContractManifest(request).then(response => {
            params.setManifestContract(response);
            params.setActiveStep();
            params.setRequest(request);
        }).catch(error => {
            params.setError(error.message);
        }).finally(() => params.setSubmitting(false));
    };

    if (status === "loading") {
        return <Progress />;
    }
    
    return <>
        <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <Grid item >
                <Box pb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="provider_name" name="provider_name"
                                label={translate("plan_tax_stamp.buy_plan.confirm_data.provider_name") as string}
                                value={request.provider_name}
                                required={true}
                                margin={"dense"}
                                disabled={blockProviderData}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="provider_rfc" name="provider_rfc"
                                label={translate("providers.rfc") as string}
                                value={request.provider_rfc}
                                validator={rfcValidator}
                                required={true}
                                margin={"dense"}
                                disabled={blockProviderData}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="address" name="address"
                                label={translate("address.title") as string}
                                value={request.address}
                                required={true}
                                margin={"dense"}
                                disabled={false}
                                onValueChanged={hasChanged} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="email" name="email"
                                label={translate("plan_tax_stamp.buy_plan.confirm_data.contact_email") as string}
                                value={request.email}
                                validator={emailValidator}
                                required={true}
                                margin={"dense"}
                                disabled={false}
                                onValueChanged={hasChanged} />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
            <Box pb={2}>
                <Grid item>
                        <Button variant="contained" color="primary" onClick={onSubmit} >
                            {translate("buttons.next") as string}
                        </Button>
                </Grid>
            </Box>
        </Grid>
    </>;
}