import { callAPI, HTTPMethod } from "./API";
import { CertificateDataRequest, CfdiDataRequest, TaxStampMetadata } from "../model/StampTaxMetadata";

export async function getStampTaxCert(rfc: string): Promise<TaxStampMetadata> {
    return await callAPI(`/provider-tax-stamp-metadata/${rfc}`, {
        method: HTTPMethod.GET
    });
}

export async function updateCertificate(request: CertificateDataRequest): Promise<TaxStampMetadata> {
    return await callAPI(`/provider-tax-stamp-metadata/update-certificate`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function updateCfdiData(request: CfdiDataRequest): Promise<TaxStampMetadata> {
    return await callAPI(`/provider-tax-stamp-metadata/update-cfdi-data`, {
        method: HTTPMethod.POST,
        body: request
    });
}