import React from "react";
import { List, ListItem, ListItemText, Card, ListSubheader } from "@material-ui/core";
import translate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat";
import { CfdiRaw } from "../model/Cfdi";

interface CfdiStampProps {
    cfdi: CfdiRaw;
}

export default function CfdiStamp(props: CfdiStampProps) {
    return (
        <Card variant="outlined" >
            <List component="nav" dense subheader={
                <ListSubheader disableSticky>
                    {translate("cfdis.details.fiscal")}
                </ListSubheader>
            }>
                <ListItem>
                    <ListItemText primary={props.cfdi.timbre.uuid} secondary={translate("cfdis.details.folio_fiscal")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={formatDate(props.cfdi.timbre.fecha_timbrado, "ll")} secondary={translate("cfdis.details.date_timber")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={props.cfdi.numero_certificado} secondary={translate("cfdis.details.certificate_number")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={props.cfdi.timbre.numero_certificado_sat} secondary={translate("cfdis.details.sat_certificate_number")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={props.cfdi.lugar_expedicion} secondary={translate("cfdis.details.cp_expedition")} />
                </ListItem>
            </List>
        </Card>
    );
}