import React, { useState, useEffect } from "react";
import { Grid, Typography, Box} from "@material-ui/core";

import Progress from "../components/Progress";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { Cfdi } from "../model/Cfdi";

import PurchaseOrderCfdiList from "./PurchaseOrderCfdiList";
import { PurchaseOrder } from "../model/PurchaseOrder";

export interface RelatedCfdisParams {
    purchaseOrder: PurchaseOrder;
}

export default function RelatedCfdisTab(props: RelatedCfdisParams) {

    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    
    const [data, setData] = useState<Cfdi[]>([] as Cfdi[]);
    
    const load = () => {
        setStatus("loading");
        if(props.purchaseOrder.cfdis){
            setData(props.purchaseOrder.cfdis);
        }
        setStatus("loaded");
    };

    useEffect(load, [props]);

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <div>
            <Box py={2}>
            <Grid>
                <Grid container spacing={2}>
                    <PurchaseOrderCfdiList data={data} />
                </Grid>
                <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            </Grid>
            </Box>
        </div>)
}