import React, { useEffect, useState } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";

import translate from "../i18n/Translator";
import { InputRef } from "./ValidatedInput";

interface DatePickerProps {
    label: string;
    name: string;
    onChange(name: string, raw?: string, date?: Date, inputRef?: InputRef): any;
    initial?: string;
    format?: string;
    variant?: "dialog" | "inline" | "static";
    showToday?: boolean;
    autoOK?: boolean;
    maxDate?: Date;
    clearable?: boolean;
    defaultIfMissing?: boolean;
    disableFuture?: boolean;
    disablePast?: boolean;
    disabled?: boolean;
    required?: boolean;
    minDate?: Date;
}

export const rawToMoment = (input: string | undefined, format: string, defaultIfMissing: boolean): Moment | undefined => {
    if (typeof input === "string") {
        let m = moment(input, format);
        if (m.isValid()) {
            return m;
        }
    }

    if (defaultIfMissing) {
        return moment();
    }
    return undefined;
};

export const parseDate = (input: string | undefined, format: string, defaultIfMissing: boolean): Date | null => {
    let m = rawToMoment(input, format, defaultIfMissing);
    if (m === undefined) {
        return null;
    }
    return m.toDate();
};

export default function DatePicker(props: DatePickerProps) {
    const [format] = useState(props.format || "DD/MM/YYYY");
    const [value, setValue] = useState<Date | null>(null);
    const [blured, setBlured] = useState(false);
    const [helperText, setHelperText] = useState<string>();
    const [validation, setValidation] = useState<InputRef>({
        valid: !props.required,
        blurer: setBlured,
        original_name: "",
    } as InputRef);

    const inputBlured = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!blured) {
            setBlured(true);
        }
    };

    const validate = () => {
        let valid = false;
        let text = undefined;

        if (value) {
            if (props.minDate && props.maxDate) {
                valid = value.getTime() >= props.minDate.getTime() && value.getTime() <= props.maxDate.getTime();
                text = translate("validations.date_between", { "from": moment(props.minDate).format(props.format), "to": moment(props.maxDate).format(props.format) }) as string;
            } else if (props.minDate) {
                valid = value.getTime() >= props.minDate.getTime();
                text = translate("validations.date_min", { "from": moment(props.minDate).format(props.format) }) as string;
            } else if (props.maxDate) {
                valid = value.getTime() <= props.maxDate.getTime();
                text = translate("validations.date_max", { "to": moment(props.maxDate).format(props.format) }) as string;
            } else {
                valid = true;
            }
        } else if (props.required) {
            valid = false;
            text = translate("validations.required") as string;
        } else {
            valid = true;
        }

        const newValidation = { ...validation, valid: valid };
        setValidation(newValidation);
        setHelperText(valid ? undefined : text);

        if (value) {
            props.onChange(props.name, moment(value).format(props.format), value, newValidation);
        } else {
            props.onChange(props.name, undefined, undefined, newValidation);
        }
    };

    useEffect(validate, [value, props.minDate, props.maxDate, props.required, props.format]);

    const onChange = (moment: MaterialUiPickersDate, _value?: string | null | undefined) => {
        if (moment && moment.isValid()) {
            const date = moment.toDate();
            setValue(date);
        } else {
            setValue(null);
        }
    };

    useEffect(() => {
        if (props.initial) {
            const date = parseDate(props.initial, format, props.defaultIfMissing ?? true);
            setValue(date);
        }
        // eslint-disable-next-line
    }, [props.initial, format]);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                variant={props.variant || "dialog"}
                format={format}
                margin="dense"
                inputVariant="outlined"
                fullWidth
                size="small"
                autoOk={props.autoOK}
                allowKeyboardControl={false}
                clearable={props.clearable ?? true}
                label={props.label}
                value={value}
                required={props.required}
                onChange={onChange}
                KeyboardButtonProps={{ "aria-label": props.label }}
                showTodayButton={props.showToday}
                maxDate={props.maxDate}
                okLabel={translate("buttons.ok")}
                cancelLabel={translate("buttons.cancel")}
                clearLabel={translate("buttons.clear")}
                disableFuture={props.disableFuture ?? true}
                disablePast={props.disablePast}
                helperText={blured && !validation.valid ? helperText : undefined}
                error={blured && !validation.valid && !!helperText}
                InputProps={{
                    readOnly: true,
                    required: props.required,
                    onBlur: inputBlured,
                }}
                disabled={props.disabled}
                minDate={props.minDate}
            />
        </MuiPickersUtilsProvider>
    );
}