import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogTitle, Typography, DialogContent, DialogActions } from "@material-ui/core";

import translate from "../i18n/Translator";
import { Contact } from "../model/Contact";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { PhoneValidator, phoneFormat } from "../components/Validators";
import { ErrorSnackbar } from "../components/Snackbars";
import Alert from "../components/Alert";

interface ExpedientProviderContactFormProps {
    title: string;
    subtitle?: string;
    contact?: Contact;
    comments?: string;
    onCompleted(contact: Contact): any;
    onClose(): any;
    disabled: boolean;
}

export default function ExpedientProviderContactForm(props: ExpedientProviderContactFormProps) {
    const isEdit = !!props.contact;
    const margin = "dense";
    const phoneValidator = new PhoneValidator();
    const [error, setError] = useState("");
    const [request, setRequest] = useState<Contact>({} as Contact);
    const [format, setFormat] = useState("");
    const [validations, setValidations] = useState({
        "first_name": {
            valid: false
        } as InputRef,
        "last_name": {
            valid: false
        } as InputRef,
        "email": {
            valid: false
        } as InputRef,
        "phone": {
            valid: false
        } as InputRef,
    } as any);

    useEffect(() => {
        setRequest({
            first_name: props.contact?.first_name || "",
            last_name: props.contact?.last_name || "",
            email: props.contact?.email || "",
            phone: props.contact?.phone || "",
        } as Contact);
    }, [props.contact]);

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedPhone = (name: string, value: string, inputRef: InputRef) => {
        hasChanged(name, value, inputRef);
        setFormat(phoneFormat(value));
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }
        props.onCompleted(request);
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick={props.disabled}
            disableEscapeKeyDown={props.disabled}
            onClose={props.onClose}
            aria-labelledby="provider-dialog-title"
            aria-describedby="provider-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <DialogTitle id="provider-dialog-title" disableTypography style={{ "lineHeight": "1px" }}>
                    <Typography variant="subtitle1">{props.title}</Typography>
                    <br />
                    <Typography variant="caption">{props.subtitle || translate("providers.contact") as string}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {props.comments && (
                        <Alert variant="warning" title={props.comments} />
                    )}
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="first_name" name="first_name"
                            value={request.first_name} label={translate("providers.first_name") as string}
                            required={true} disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged} />
                        <ValidatedInput type="text" id="last_name" name="last_name"
                            value={request.last_name} label={translate("providers.last_name") as string}
                            required={true} disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged} />
                        <ValidatedInput type="email" id="email" name="email"
                            value={request.email} label={translate("providers.email") as string}
                            required={true} disabled={props.disabled}
                            margin={margin}
                            onValueChanged={hasChanged} />
                        <ValidatedInput type="text" id="phone" name="phone"
                            value={request.phone} label={translate("providers.phone") as string}
                            required={true} disabled={props.disabled}
                            validator={phoneValidator}
                            margin={margin}
                            format={format} mask=" "
                            onValueChanged={hasChangedPhone} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={props.disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={props.disabled}>
                        {translate(isEdit ? "buttons.update" : "buttons.add")}
                    </Button>
                </DialogActions>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </Dialog>
    );
}