import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";

import { TenantConfigurationContactErpRequest } from "../model/TenantConfiguration";
import { ErpContact } from "../model/IntegrationLog"; 
import DialogPopup from "../components/DialogPopup";
import ValidatedInput from "../components/ValidatedInput";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";

interface UpdateContactErpPopupProps {
    contact: ErpContact;
    onSuccess(contact : TenantConfigurationContactErpRequest): any;
    onClose(): any;
}

export default function UpdateContactErpPopup(props: UpdateContactErpPopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<TenantConfigurationContactErpRequest>({ email: props.contact.email, contact_name: props.contact.contact_name } as TenantConfigurationContactErpRequest);
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    
    const onUpdate = () => {
        if(request.email === ("" || "---") || request.contact_name === ("" || "---")){
            setWarning(translate("reports.transaction_log.edit_contact.empty") as string);
            return;
        }
        props.onSuccess(request);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string) => {
        setSubmitting(request.email === ("" || "---") || request.contact_name === ("" || "---"));
        setRequest({ ...request, [name]: value });
    };

    return (
        <DialogPopup open
            title={translate("reports.transaction_log.edit_contact.title") as string}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onUpdate} variant="outlined" color="primary" disabled={submitting || request.email === "" || request.contact_name === ""}>
                    {translate("buttons.save")}
                </Button>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                    {translate("reports.transaction_log.edit_contact.message")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="contact_name" name="contact_name" disabled={false}
                        value={request.contact_name}
                        required={true}
                        type="text"
                        margin={"dense"}
                        label={translate("reports.transaction_log.edit_contact.name") as string}
                        onValueChanged={hasChanged} />
                    <ValidatedInput id="email" name="email" disabled={false}
                        value={request.email}
                        required={true}
                        type="text"
                        margin={"dense"}
                        label={translate("reports.transaction_log.edit_contact.email") as string}
                        onValueChanged={hasChanged} />
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}