import React, { useState } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import ValidatedInput, { InputRef } from "./ValidatedInput";
import translate from "../i18n/Translator";

import DialogPopup from "./DialogPopup";

interface SelectPopupProps {
    title: string;
    message: string | React.ReactNode;
    button?: string;
    onClose(): any;
    doAction( value : string ): any;
    labelInput: string;
    elements: string[];
    elementLabels: string[];
}

export default function SelectPopup(props: SelectPopupProps) {
    const [value, setValue] = useState<string>("");
    const onConfirm = () => {
        if(!value) return;
        props.doAction(value);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setValue(value);
    };

    return (
        <DialogPopup open
            title={props.title}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={props.button && (
                <Button onClick={onConfirm} variant="outlined" color="primary" >
                    {props.button}
                </Button>
            )} >
            {typeof props.message === "string" && (
                <Typography variant="body2">
                    {props.message}
                </Typography>
            )}
            {typeof props.message !== "string" && (
                props.message
            )}
            <Box pt={1}>
                <ValidatedInput
                    type="text" id="value" name="value"
                    label={props.labelInput}
                    value={value}
                    rows={5}
                    onValueChanged={hasChanged}
                    required={true}
                    margin={"dense"} 
                    options={props.elements}
                    optionLabels={props.elementLabels}
                />
            </Box>
        </DialogPopup>
    );

}