import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useHistory, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LockIcon from "@material-ui/icons/Lock";
import AddIcon from "@material-ui/icons/Add";
import WorkIcon from "@material-ui/icons/Work";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { getKeyPairs, deleteKeyPair } from "../api/KeyPairAPI";
import { KeyPair, KeyPairs, KeyPairQueryParams } from "../model/KeyPair";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import ValidatedInput from "../components/ValidatedInput";
import KeyPairMenu from "./KeyPairMenu";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";

export default function KeyPairsList() {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): KeyPairQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : ""
        } as KeyPairQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<KeyPairs>();
    const [params, setParams] = useState<KeyPairQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<KeyPairQueryParams>(paramsFromQueryString);

    const [keyPair, setKeyPair] = useState<KeyPair>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"details" | "delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const load = () => {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        getKeyPairs(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (keyPair: KeyPair) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setKeyPair(keyPair);
    };

    const onDetails = () => {
        if (!keyPair) return;

        setAnchorEl(null);
        setPopup("details");
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onDelete = () => {
        if (!keyPair) return;

        setAnchorEl(null);
        setPopup("delete");
    };

    const onConfirmDelete = () => {
        if (!keyPair) return;

        setSubmitting(true);
        deleteKeyPair(context.session!.tenant!.id, keyPair.id).then(() => {
            setSuccess(translate("keypairs.delete.success", { "id": keyPair.id }) as string);
            onClosePopup();
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setKeyPair(undefined);
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickRow = (keyPair: KeyPair) => {
        setKeyPair(keyPair);
        setAnchorEl(null);
        setPopup("details");
    };

    return (
        <Pagination title={translate("keypairs.title")} icon={<VpnKeyIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                context.isGranted('KeyPairsCreate') ?
                <Link to="/keypairs/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link> : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("keypairs.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("keypairs.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("keypairs.name") as string,
                        converter: (keyPair) => (
                            <Ellipsis text={keyPair.name} lenght={100} uppercased={false} />
                        ),
                        xs: true
                    },
                    {
                        title: translate("keypairs.id") as string,
                        converter: (keyPair) => keyPair.id,
                        xs: false,
                        sm: 4,
                        md: 4,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("keypairs.secret") as string,
                        converter: (keyPair) => `${keyPair.secret.substring(0, 4)}********${keyPair.secret.substring(44, 48)}`,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 3,
                        xl: 2
                    },
                    {
                        title: translate("keypairs.created") as string,
                        converter: (keyPair) => <DateFormat date={keyPair.created_at} format="L" />,
                        md: false,
                        lg: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                        ),
                        converter: (keyPair) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(keyPair)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {keyPair && anchorEl &&
                <KeyPairMenu keyPair={keyPair} anchor={anchorEl} onClose={onCloseOption} onDetails={onDetails} onDelete={onDelete} />
            }
            {keyPair && popup === "details" && (
                <DialogPopup open title={translate("keypairs.single")} onClose={onClosePopup}>
                    <List dense>
                        <ListItem>
                            <ListItemAvatar>
                                <WorkIcon />
                            </ListItemAvatar>
                            <ListItemText secondary={translate("tenants.single")} primary={context.session?.tenant?.id || ""} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <LockIcon />
                            </ListItemAvatar>
                            <ListItemText secondary={translate("keypairs.id")} primary={keyPair.id} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <VpnKeyIcon />
                            </ListItemAvatar>
                            <ListItemText secondary={translate("keypairs.secret")} primary={keyPair.secret} />
                        </ListItem>
                    </List>
                </DialogPopup>
            )}
            {keyPair && popup === "delete" && (
                <DialogPopup open title={translate("keypairs.delete.title")} onClose={onClosePopup} closeColor="default"
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    button={(
                        <Button onClick={onConfirmDelete} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.delete")}
                        </Button>
                    )}>
                    {translate("keypairs.delete.text", { "id": keyPair.id })}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
        </Pagination >
    );
}