import React, { useContext } from "react";
import { Typography, Menu, MenuItem, Fade } from "@material-ui/core";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface WebhookEventMenuProps {
    anchor: HTMLElement | null;
    onOpenTries(): any;
    onClose(): any;
}

export default function WebhookEventMenu(props: WebhookEventMenuProps) {

    return (
        <Menu
            id="webhook-event-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
                <MenuItem button onClick={props.onOpenTries} dense>
                    <Typography variant="inherit">
                        {translate("webhooks.events.tries.show_tries")}
                    </Typography>
                </MenuItem>
        </Menu>
    );

}