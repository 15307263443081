import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { getPayments } from "../api/PaymentApi";
import { Grid, Typography } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { Payment, Payments, PaymentListParams, Statuses } from "../model/Payment";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import PaymentPendingCfdiMenu from "./PaymentPendingCfdiMenu";
import { PaymentListProps } from "./PaymentList";

export default function PendingPaymentListDashboard(props: PaymentListProps) {
    const context = useContext(AppContext);
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): PaymentListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "payment_status": typeof qs["status"] === "string" ? qs["status"] as string : "",
        } as PaymentListParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Payments>();
    const [params] = useState<PaymentListParams>(paramsFromQueryString);

    const [payment, setPayment] = useState<Payment>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page] = useState<number>(initialPage);
    const [pageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const load = () => {
        let offset = getOffset(page, pageSize);
        params.payment_status = Statuses[0];
        setStatus("loading");
        if (context.isGranted("PaymentsRead") ) { 
            getPayments(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
                setData(response);
            }).catch((error) => {
                setStatus(error.message);
            });
        }
        setStatus("loaded");
    }

    useEffect(load, [context.session, page, pageSize, params]);

    const onCloseOption = () => {
        setAnchorEl(null);
        setPayment(undefined);
    };

    const onOpenConfirmRejected = () => {
        setAnchorEl(null);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        data !== undefined && data.total > 0 ? 
        <Grid >
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cfdis.empty") as string}
                columns={props.getColumns(data ? data.items : [])}
            />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {payment && anchorEl && (
                <PaymentPendingCfdiMenu payment={payment} anchor={anchorEl} referer={"to-verify"} onClose={onCloseOption} onDelete={onOpenConfirmRejected} />
            )}
        </Grid> :  
        <Typography align="center" gutterBottom variant="h5" component="h2"><p >{translate("providers.pending_payments.without_pending") as string}</p></Typography>
    );
}