import { Cfdi } from  "./Cfdi";
import { Payment } from  "./Payment";

export interface Clarification {
    id: string;
    provider_name: string;
    number: number;
    subject: string;
    issuer_name: string;
    messages_response: Message[];
    cfdis: Cfdi[];
    payments: Payment[];
    status: string;
    created_at: Date;
}

export interface Message {
    sender_id: string;
    sender: string;
    company: string;
    message_text: string;
    created_at: Date;
}

export interface Clarifications {
    items: Clarification[];
    total: number;
}

export interface ClarificationPayableDocumentDetail {
    operation_number: string;
    cfdi_id: string;
    folio: string;
    date: Date;
    total: number;
}

export interface ClarificationListParams {
    number: string;
    subject: string;
    sender: string;
    provider: string;
    provider_id: string;
    init_date: string;
    end_date: string;
    status: string;
    user_id: string;
}

export const Statuses = ["RESOLVED",  "UNSOLVED"];

export interface ClarificationRequest {
    user_id: string;
    provider_id: string;
    document_ids: string[];
    message: string;
    type: string;
}

export interface ClarificationMessageRequest {
    user_id: string;
    company_id: string;
    is_provider: boolean;
    message: string;
}

export interface ClarificationUpdateStatusRequest {
    user_id: string;
    status: string;
}
