import React from "react";
import { Typography, Menu, MenuItem, Fade, ListItemIcon } from "@material-ui/core";
import translate from "../i18n/Translator";
import BlockIcon from '@material-ui/icons/Block';

interface PaymentOrderMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onCancelCfdi(): any;
}

export default function PaymentOrderMenu(props: PaymentOrderMenuProps) {
    const onCancelCfdi = () => {
        props.onCancelCfdi();
    }

    return (
        <Menu
            id="payment-order-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            <MenuItem button onClick={onCancelCfdi} dense>
                <ListItemIcon>
                    <BlockIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("cfdis.cancel_cfdi")}</Typography>
            </MenuItem>

        </Menu>);
}
