import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ExpedientTemplate } from "../model/ExpedientTemplate";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentationTwoTone';

interface ExpedientTemplateMenuMenuProps {
    template: ExpedientTemplate;
    anchor: HTMLElement | null;
    onClose(): any;
    onDisabled(template: ExpedientTemplate): any;
    onEdit(template: ExpedientTemplate): any;
}

export default function ExpedientTemplateMenu(props: ExpedientTemplateMenuMenuProps) {

    const context = useContext(AppContext);

    return (
        <Menu
            id="expedient-template-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["ExpedientTemplateUpdate"]) &&
                <MenuItem button onClick={()=> props.onEdit(props.template)} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["ExpedientTemplateUpdate"]) && 
            <MenuItem button onClick={()=> props.onDisabled(props.template)} dense>
                    <ListItemIcon>
                        <CancelPresentationIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("expedient_template.disabled_button")}</Typography>
                </MenuItem>
            }
            
        </Menu>);
}
