import React, { useState } from "react";
import { Grid, Card, ListSubheader, Box, Typography, Button } from '@material-ui/core';
import Gridable from "../components/Gridable";
import translate from "../i18n/Translator";
import NumberFormat from "react-number-format";
import { Payment } from "../model/Payment";
import { GridableColumn } from '../components/Gridable';
import DateFormat from "../components/DateFormat";
import { Link, useHistory, Redirect } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box_variant1: {
            fontSize: "0.875rem",
            color: "#363636",
        },
        box_variant2: {
            fontSize: "0.875rem",
            color: "#1f679f",
        },
        box_variant3: {
            fontSize: "0.875rem",
            color: "#f69450",
        },
        box_variant4: {
            fontSize: "0.875rem",
            color: "#42a047",
        },
        link: {
            color: "#3d94c5",
            fontSize: "0.7rem",
            textAlign: "right",
        }
    }),
);

interface PaymentsCardProps {
    cfdi_payment?: string;
    purchasePayments: Payment[];
}


export default function PaymentsCard(props: PaymentsCardProps) {
    const [redirect, setRedirect] = useState<string>();
    const history = useHistory();
    const classes = useStyles();

    const onClickedRow = (payment: Payment) => {
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/payment-details/${payment.id}`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    let columns =
        [
            {
                title: translate("requisitions.overview.quotation.payments.number") as string,
                converter: (payment) =>
                (<Button color="default" size="small" onClick={() => {
                    if (payment.id) onClickedRow(payment);
                }}> {payment.operation_number}
                </Button>),
                xs: 2
            },
            {
                title: translate("requisitions.overview.quotation.payments.date") as string,
                converter: (payment) => (<DateFormat date={payment.payment_date} format="DD/MM/YYYY" zone={true} />),
                xs: 2
            },
            {
                title: translate("requisitions.overview.quotation.payments.amount") as string,
                converter: (payment) => (
                    <NumberFormat value={payment.total_amount} suffix={` ${payment.currency}`} prefix="$" decimalScale={2} thousandSeparator="," displayType="text" />
                ),
                xs: true
            },
        ] as GridableColumn<Payment>[];

    return (
        <>
            <Grid container alignItems="center" justify="flex-end" spacing={1}>
                <Grid item xs={props.cfdi_payment ? 9 : 12}>
                    <ListSubheader>{translate(`requisitions.overview.quotation.payments.title`)}</ListSubheader>
                </Grid>
                {props.cfdi_payment && (
                    <Grid item direction="column" alignItems="flex-end" justify="flex-end" xs={3} >
                        <Box px={2} pb={2} pt={2}>
                            <Link to={`/cfdis/${props.cfdi_payment}/details`} >
                                <Typography className={classes.link} noWrap={true}>{translate('requisitions.overview.quotation.payments.view_invoice') as string}</Typography>
                            </Link>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Box pb={2}>
                <Grid container alignItems="flex-start" justify="space-between">
                    <Gridable
                        items={props.purchasePayments}
                        loading={false}
                        empty={translate("requisitions.overview.quotation.payments.empty") as string}
                        columns={columns} />
                </Grid>
            </Box>
        </>
    );
}
