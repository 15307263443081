import { Box, Card, Grid, Typography, Divider } from "@material-ui/core";
import React from "react";
import { Cfdi } from "../model/Cfdi";
import { CartaPorte, Ubicaciones } from "../model/ComplementoCartaPorte20";
import translate from "../i18n/Translator";
import MercanciasCard from "./CartaPorte/MercanciasCard20";
import FiguraTransporteCard from "./CartaPorte/FiguraTransporteCard20";
import DateFormat from "../components/DateFormat";

export interface CartaPorteTabProps {
    cfdi: Cfdi;
}

export default function CartaPorteTab(props: CartaPorteTabProps) {
    return (
        <Box pt={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2} >
                <Grid item xs={12}>
                    <Generales data={props.cfdi.cfdi!.complementos.carta_porte20} />
                </Grid>
                <Grid item xs={12}>
                    <UbicacionesCard data={props.cfdi.cfdi!.complementos.carta_porte20.ubicaciones} />
                </Grid>
                <Grid item xs={12}>
                    <MercanciasCard data={props.cfdi.cfdi!.complementos.carta_porte20.mercancias} />
                </Grid>
                {props.cfdi.cfdi!.complementos.carta_porte20.figura_transporte &&
                    <Grid item xs={12}>
                        <FiguraTransporteCard data={props.cfdi.cfdi!.complementos.carta_porte20.figura_transporte} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

interface GeneralesProps {
    data: CartaPorte;
}

function Generales(props: GeneralesProps) {
    return (
        <Box width="50%">
            <Card variant="outlined" >
                <Box pt={1} pl={2}><Typography component="h6" color="primary">{translate("cfdis.carta_porte.generales.title")}</Typography><br/></Box>
                <DetailsSection translation_prefix="cfdis.carta_porte.generales" data={props.data} />
            </Card>
        </Box>
    );
}

export interface UbicacionesCardProps {
    data: Ubicaciones;
}

export function UbicacionesCard(props: UbicacionesCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.ubicaciones.title")}</strong></Typography>
            <Grid container justify="flex-start" spacing={2}>
                {props.data.ubicaciones.map((ubicacion) => {
                        return (
                            <Grid item xs={12}>
                                <Card variant="outlined" >
                                <Box pt={1} pl={2}><Typography component="h6" color="primary">{translate(`cfdis.carta_porte.ubicaciones.${ubicacion.tipo_ubicacion.toLowerCase()}.title`)}</Typography></Box>
                                <br/>
                                    <Grid container justify="flex-start" >
                                        <Grid item xs={12} sm={6}>
                                            <DetailsSection translation_prefix="cfdis.carta_porte.ubicaciones.ubicacion" data={ubicacion} />
                                        </Grid>
                                        { ubicacion.domicilio && <Divider orientation="vertical" flexItem variant="middle" /> }
                                        { ubicacion.domicilio && <Grid item xs={12} sm={5}>
                                            <DetailsSection subtitle={translate("cfdis.carta_porte.domicilio.title") as string} translation_prefix="cfdis.carta_porte.domicilio" data={ubicacion.domicilio} />
                                        </Grid>}
                                    </Grid>
                                </Card>
                            </Grid>
                        );
                })}

            </Grid>
        </Box>
    );
}

export interface DetailsSectionProps<T> {
    subtitle?: string;
    translation_prefix: string;
    data: T;
}

export function DetailsSection<T>(props: DetailsSectionProps<T>) {
    return (
        <Box pb={2} px={2}>
            {props.subtitle &&
                <strong>
                    {props.subtitle}
                </strong>
            }
            <Grid container justify="flex-start" >
                {Object.keys(props.data).map(key => {
                    let translated = translate(`${props.translation_prefix}.${key}`) as string;
                    if (translated !== `${props.translation_prefix}.${key}` && (props.data as any)[key] !== undefined) {
                        return <>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    {translated}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div>
                                    {key.includes('fecha') ?
                                        <DateFormat date={(props.data as any)[key]} format="DD/MM/YYYY HH:mm:ss [hrs.]" /> :
                                        (props.data as any)[key]}
                                </div>
                            </Grid>
                        </>;
                    }
                    return <></>;
                })}
            </Grid>
        </Box>
    );
}
