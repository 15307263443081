import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';

interface ShippingRequirementMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onEdit(): any;
    onDelete(): any;
}

export default function ShippingRequirementMenu(props: ShippingRequirementMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="shippingRequirement-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("CustomerShippingRequirementsUpdate") &&
                <MenuItem button onClick={props.onEdit} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CustomerShippingRequirementsDelete"]) &&
                    <MenuItem button onClick={props.onDelete} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                    </MenuItem>
                }
        </Menu>
    );

}