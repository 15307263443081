import React, { useState, useEffect } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import ValidatedInput from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { ExpedientTemplateParams } from "../model/ExpedientTemplate";
import { listExpedientTemplates } from "../api/ExpedientTemplateAPI";
import Progress from "../components/Progress";

interface ChangeTemplateExpedientPopupProps {
    tenantId: string;
    providers: string[];
    currentTemplates: string[];
    onClose(): any;
    onSave(templateId: string): any;
}

export default function ChangeTemplateExpedientPopup(props: ChangeTemplateExpedientPopupProps) {
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [data, setData] = useState<string[]>([]);
    const [dataLabels, setDataLabels] = useState<string[]>([]);
    const [request, setRequest] = useState<string>();

    useEffect(() => {
        setStatus("loading");
        listExpedientTemplates(props.tenantId, {} as ExpedientTemplateParams,0, 0).then((response) => {
            setData(response.items.map(item => item.id));
            setDataLabels(response.items.map(item => item.name));
            setStatus("loaded");
        }).catch((error) => {
            setError(error.message);
        });
    }, [props.tenantId, props.providers]);

    const onSubmit = () => {
        if(!request){
            return;
        }
        setSubmitting(true);
        props.onSave(request);

    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const onChange = (name: string, value: string) => {
        setRequest(value);
    }

    return (
        <DialogPopup open
            title={translate("providers.template_expedient.description")}
            button={data.length > 0 ? (
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={submitting}>
                    {translate("buttons.save")}
                </Button>
            ) : undefined}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}>
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && data.length > 0 && (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {translate("providers.template_expedient.message")}
                        </Typography>
                    </Grid>
                    <Grid container xs={12} direction="row"
                        justify="center"
                        alignItems="center">
                        <Grid xs={4}>
                            <Typography variant="body2" paragraph>
                                {translate("providers.template_expedient.current_template")}
                            </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography variant="body2" paragraph>
                                {props.currentTemplates.join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction="row"
                        justify="center"
                        alignItems="center">
                        <Grid xs={4}>
                            <Typography variant="body2" paragraph>
                                {translate("providers.template_expedient.new_template")}
                            </Typography>
                        </Grid>
                        <Grid xs={8}>
                            <ValidatedInput
                                type="text" id="template_id" name="template_id" 
                                label={translate("providers.template_expedient.title") as string}
                                value={request}
                                required disabled={submitting}
                                margin={"dense"}
                                onValueChanged={onChange}
                                options={data} optionLabels={dataLabels} />
                        </Grid>

                    </Grid>
                </Grid>
            )}
            {status === "loaded" && data.length === 0 && (
                <Typography variant="body2">
                    {translate("providers.template_expedient.empty")}
                </Typography>
            )}
            {status !== "loading" && status !== "loaded" && (
                <Typography variant="body2" component="h5" color="error" align="center">
                    {status}
                </Typography>
            )}

            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}

