import { callAPI, HTTPMethod } from './API';
import { ProviderExpedientHistory, ProviderExpedientsHistory, ProviderExpedientHistoryParams } from '../model/ProviderExpedientHistory';
import { FileUrlResponse } from "../model/ZipDownload";

export async function getProviderExpedientHistory(tenantId: string, providerId: string, historyExpedientId: string): Promise<ProviderExpedientHistory> {
  return await callAPI(`/tenants/${tenantId}/provider-expedients/${providerId}/history/${historyExpedientId}`, {
    method: HTTPMethod.GET,
  });
}

export async function listProviderExpedientsHistory(tenantId: string, providerId: string, params: ProviderExpedientHistoryParams, pageSize: number, offset: number): Promise<ProviderExpedientsHistory> {
  let query = {
    pageSize: pageSize.toString(),
    offset: offset.toString(),
  } as Record<string, string>;
  
  if (params.start_date && params.end_date) {
    query = {
      ...query,
      startDate: params.start_date + "",
      endDate: params.end_date + "",
    };
  }

  return await callAPI(`/tenants/${tenantId}/provider-expedients/${providerId}/history`, {
    method: HTTPMethod.GET,
    query: query
  });
}

export async function getFilesExpedient(tenantId: string, providerId: string, historyId: string): Promise<FileUrlResponse> {
  return await callAPI(`/tenants/${tenantId}/provider-expedients/${providerId}/history/${historyId}/files-expedient`, {
    method: HTTPMethod.GET
  });
}

