import { Box, Card, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Cfdi } from "../model/Cfdi";
import translate from "../i18n/Translator";
import MercanciasCard from "./CartaPorte/MercanciasCard30";
import FiguraTransporteCard from "./CartaPorte/FiguraTransporteCard20";
import { CartaPorte } from "../model/ComplementoCartaPorte30";
import { DetailsSection, UbicacionesCard } from "./CartaPorteTab20";

export interface CartaPorteTabProps {
    cfdi: Cfdi;
}

export default function CartaPorteTab(props: CartaPorteTabProps) {
    return (
        <Box pt={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2} >
                <Grid item xs={12}>
                    <Generales data={props.cfdi.cfdi!.complementos.carta_porte30} />
                </Grid>
                <Grid item xs={12}>
                    <UbicacionesCard data={props.cfdi.cfdi!.complementos.carta_porte30.ubicaciones} />
                </Grid>
                <Grid item xs={12}>
                    <MercanciasCard data={props.cfdi.cfdi!.complementos.carta_porte30.mercancias} />
                </Grid>
                {props.cfdi.cfdi!.complementos.carta_porte30.figura_transporte &&
                    <Grid item xs={12}>
                        <FiguraTransporteCard data={props.cfdi.cfdi!.complementos.carta_porte30.figura_transporte} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

interface GeneralesProps {
    data: CartaPorte;
}

function Generales(props: GeneralesProps) {
    return (
        <Box width="50%">
            <Card variant="outlined" >
                <Box pt={1} pl={2}><Typography component="h6" color="primary">{translate("cfdis.carta_porte.generales.title")}</Typography><br/></Box>
                <DetailsSection translation_prefix="cfdis.carta_porte.generales" data={props.data} />
            </Card>
        </Box>
    );
}
