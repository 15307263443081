import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`item-tabpanel-${index}`}
            aria-labelledby={`item-tab-${index}`}
            {...other}
        >
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

export function a11yProps(index: any) {
    return {
        component: "a",
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
        },
        id: `item-tab-${index}`,
        'aria-controls': `item-tabpanel-${index}`,
    };
}
