import React, { useState } from "react";
import { Grid, Button, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel } from "@material-ui/core";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { WarningSnackbar } from "../components/Snackbars";

interface AnnuncementSelectOptionPopUpProps {
    onSelectOption(value: string): any;
    onClose(): any;
}

export default function AnnuncementSelectOptionPopUp(props: AnnuncementSelectOptionPopUpProps) {
    const [warning, setWarning] = useState<string>();

    const [optionSelected, setOptionSelected] = useState<string>("all");

    const onSubmit = () => {
        props.onSelectOption(optionSelected);
    };

    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptionSelected((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === "all") {
            setOptionSelected("all");
        } else if ((event.target as HTMLInputElement).name === "selected") {
            setOptionSelected("selected");
        } else {
            setOptionSelected("class");
        }
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    return (
        <DialogPopup open
            title={translate("providers.announcements.new")}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.accept")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
            <Grid container xs={12}>
                <Grid item>
                    <FormControl component="fieldset" >
                        <FormLabel component="legend">{translate("providers.announcements.options.title") as string}</FormLabel>
                        <RadioGroup aria-label="options" name="options" value={optionSelected} onChange={onChangeRadio} >
                            <FormControlLabel value="all" control={<Radio />} label={translate("providers.announcements.options.all_providers") as string} />
                            <FormControlLabel value="class" control={<Radio />} label={translate("providers.announcements.options.by_classifications") as string} />
                            <FormControlLabel value="selected" name="selected" control={<Radio />} label={translate("providers.announcements.options.selected_providers") as string} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}
