import React, { useState, useEffect } from "react";
import { Grid, Box, ListItemText, Typography, ListItem, Divider, List, Card } from "@material-ui/core";
import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";

import Progress from "../components/Progress";
import DateFormat from "../components/DateFormat";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";

import { ProviderAddress } from "../providers/ProviderAddressCard";
import GridDx from "../components/GridDx";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { getPurchaseOrder } from "../api/PurchaseOrderAPI";
import { getPurchaseOrder as getProviderPurchaseOrder } from "../api/ProviderPurchaseOrderAPI";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { PurchaseOrderTax } from "../model/Document";

export interface DetailsTabParams {
    tenantId: string;
    purchaseOrder: PurchaseOrder;
    providerId?: string
}

export default function DetailsTab(props: DetailsTabParams) {
    const [status, setStatus] = useState<string>("loaded");
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [switchPaginate, setSwitchPaginate] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();

    const textColumns = ['line_num', 'item_code', 'unit_of_measure', 'description', 'quantity'];

    const currencyColumns = ['price', 'taxes_transferred', 'taxes_withheld', 'line_total'];

    const [columnsFormat] = useState([
        { columnName: 'line_num', width: 70, align: 'left', wordWrapEnabled: true, },
        { columnName: 'item_code', width: 200 },
        { columnName: 'unit_of_measure', width: 100 },
        { columnName: 'description', wordWrapEnabled: true, width: 500 },
        { columnName: 'quantity', width: 100 },
        { columnName: 'price', width: 150 },
        { columnName: 'taxes_transferred', width: 150 },
        { columnName: 'taxes_withheld', width: 150 },
        { columnName: 'line_total', width: 200 },
    ]) as any;

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={['line_num', 'item_code', 'unit_of_measure', 'description', 'quantity', 'price', 'taxes_transferred', 'taxes_withheld', 'line_total']} editorComponent={NoFilterEditor} />,
    ];

    const getPromise = (): Promise<PurchaseOrder> => {
        if (props.providerId) {
            return getProviderPurchaseOrder(props.tenantId, props.providerId, props.purchaseOrder.id, pageSize, pageSize * page);
        }
        return getPurchaseOrder(props.tenantId, props.purchaseOrder.id, pageSize, pageSize * page);
    };

    useEffect(() => {
        if (switchPaginate) {
            setOpenBackdrop(true);
            getPromise().then((purchaseOrder) => {
                setPurchaseOrder(purchaseOrder);
            }).catch((error) => {
                setStatus(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        } else {
            setSwitchPaginate(true);
            setPurchaseOrder(props.purchaseOrder);
        }
        // eslint-disable-next-line
    }, [page, pageSize]);

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !purchaseOrder) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const columns = [
        {
            name: 'line_num',
            title: translate("purchase_orders.line_num") as string
        },
        {
            name: 'item_code',
            title: translate("purchase_orders.item_code") as string
        },
        {
            name: 'unit_of_measure',
            title: translate("purchase_orders.unit_of_measure") as string
        },
        {
            name: 'description',
            title: translate("purchase_orders.description") as string
        },
        {
            name: 'quantity',
            title: translate("purchase_orders.quantity") as string
        },
        {
            name: 'price',
            title: translate("purchase_orders.price") as string
        },
        {
            name: 'taxes_transferred',
            title: translate("purchase_orders.taxes_transferred") as string,
            getCellValue: (row: any) => !!row?.vat_taxes ? getTaxes(row.vat_taxes) : 0
        },
        {
            name: 'taxes_withheld',
            title: translate("purchase_orders.taxes_withheld") as string,
            getCellValue: (row: any) => !!row?.withholding_taxes ? getTaxes(row.withholding_taxes) : 0
        },
        {
            name: 'line_total',
            title: translate("purchase_orders.line_total") as string
        },
    ];

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const getTaxes = (taxes: PurchaseOrderTax[]): number => {
        let tax: number = 0;
        taxes.forEach((vat_tax) => {
            tax += vat_tax.amount
        });
        return tax;
    }

    const existMetadata = (purchaseOrder: PurchaseOrder): Boolean => {
        return !!purchaseOrder?.metadata;
    }

    return (
        <div>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item xs={12}>
                    <Card variant="outlined" >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box px={2} pt={1}>
                                    <Grid container spacing={2}>
                                        {purchaseOrder.provider &&
                                            <GridItemDense
                                                primary={purchaseOrder.provider.rfc}
                                                secondary={purchaseOrder.provider.name} />}
                                        {purchaseOrder.date &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.creation_date")}
                                                secondary={<DateFormat date={purchaseOrder.date} format="DD/MM/YYYY" />} />
                                        }
                                        {purchaseOrder.due_date &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.due_date")}
                                                secondary={<DateFormat date={purchaseOrder.due_date} format="DD/MM/YYYY" />} />
                                        }
                                        {!!purchaseOrder.delivery_contact &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.deliver_to")}
                                                secondary={purchaseOrder.delivery_contact} />}

                                        {!!purchaseOrder.warehouse &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.store")}
                                                secondary={purchaseOrder.warehouse} />}

                                        {purchaseOrder.delivery_date &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.delivery_date")}
                                                secondary={(<DateFormat date={purchaseOrder.delivery_date} format="DD/MM/YYYY" />)} />
                                        }
                                        {!!purchaseOrder?.cfdi_payment_method &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.payment_method")}
                                                secondary={purchaseOrder.cfdi_payment_method + " " + translate(`reception_rule.payment_methods.${purchaseOrder.cfdi_payment_method}`)} />
                                        }
                                        {!!purchaseOrder?.cfdi_payment_form &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.payment_way")}
                                                secondary={purchaseOrder.cfdi_payment_form + " " + translate(`reception_rule.payment_form.${purchaseOrder.cfdi_payment_form}`)} />
                                        }
                                        {!!purchaseOrder?.cfdi_use &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.cfdi_use")}
                                                secondary={purchaseOrder.cfdi_use + " " + translate(`reception_rule.cfdi_uses.${purchaseOrder.cfdi_use}`)} />
                                        }
                                        {!!purchaseOrder?.comments &&
                                            <GridItemDense
                                                primary={translate("purchase_orders.comments")}
                                                secondary={purchaseOrder.comments} />
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            {existMetadata(purchaseOrder) ?
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                : undefined
                            }
                            {purchaseOrder.metadata && purchaseOrder.metadata.length > 0 &&
                                <Grid item xs={12}>
                                    <Box px={2} pt={0}>
                                        <Grid container spacing={2}>
                                            {
                                                (purchaseOrder.metadata).map((data, index) => (
                                                    <GridItemDense
                                                        primary={data.key}
                                                        secondary={data.value} />
                                                ))
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Box p={2} pb={0}>
                                <Grid container justify="space-between" spacing={2}>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <Grid container spacing={2}>
                                            {(purchaseOrder.addresses || []).map((address, index) => (
                                                <Grid key={index} item xs={12} sm={6} lg={6}>
                                                    <List dense disablePadding>
                                                        {address.type && (
                                                            <ListItem disableGutters>
                                                                <ListItemText primary={`${translate("expedients.addresses.of")} ${translate(`expedients.addresses.types.${address.type}`)}`}
                                                                    secondary={(
                                                                        <ProviderAddress address={address} />
                                                                    )} />
                                                            </ListItem>
                                                        )}
                                                    </List>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        {(!purchaseOrder.addresses || purchaseOrder.addresses.length === 0) && (
                                            <Box pt={3}>
                                                <Typography variant="body2" component="h6">
                                                    {translate("purchase_orders.no_addresses")}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={3}>
                                        <List dense disablePadding>
                                            {purchaseOrder.vat_sum && (
                                                <ListItem disableGutters >
                                                    <ListItemText style={{ textAlign: "right" }} primary={translate("purchase_orders.taxes_transferred")} secondary={(
                                                        <NumberFormat value={purchaseOrder.vat_sum} prefix="$ " thousandSeparator="," decimalScale={2}
                                                            fixedDecimalScale={true} displayType="text" />
                                                    )} />
                                                </ListItem>
                                            )}
                                            {purchaseOrder.withhold_tax_sum && (
                                                <ListItem disableGutters>
                                                    <ListItemText style={{ textAlign: "right" }} primary={translate("purchase_orders.taxes_withheld")} secondary={(
                                                        <NumberFormat value={purchaseOrder.withhold_tax_sum} prefix="$ " thousandSeparator="," decimalScale={2}
                                                            fixedDecimalScale={true} displayType="text" />
                                                    )} />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={3}>
                                        <List dense disablePadding>
                                            {purchaseOrder.subtotal &&
                                                <ListItem disableGutters>
                                                    <ListItemText style={{ textAlign: "right" }} primary={translate("purchase_orders.line_subtotal")} secondary={(
                                                        <NumberFormat value={purchaseOrder.subtotal} prefix="$ " thousandSeparator="," decimalScale={2}
                                                            fixedDecimalScale={true} displayType="text" suffix={` ${purchaseOrder.currency || "MXN"}`} />
                                                    )} />
                                                </ListItem>
                                            }
                                            <ListItem disableGutters>
                                                <ListItemText style={{ textAlign: "right" }} primary={translate("purchase_orders.total_before_tax")} secondary={(
                                                    <NumberFormat value={purchaseOrder.total} prefix="$ " thousandSeparator="," decimalScale={2}
                                                        fixedDecimalScale={true} displayType="text" suffix={` ${purchaseOrder.currency || "MXN"}`}
                                                        style={{ color: "#f6944e", fontSize: "1.1rem" }} />
                                                )} />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Box>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <GridDx
                                noUseId={true}
                                loading={openBackdrop}
                                rows={purchaseOrder.lines ? purchaseOrder.lines : []}
                                page={page}
                                pageSize={pageSize}
                                totalRows={purchaseOrder.total_lines ? purchaseOrder.total_lines : 0}
                                columns={columns ? columns : []}
                                columnsFormat={columnsFormat}
                                textColumns={textColumns}
                                amountCurrencyColumns={currencyColumns}
                                onClickRow={() => { }}
                                onClickedOptions={() => { }}
                                onChangedPage={onChangedPage}
                                onChangedPageSize={onChangedPageSize}
                                customFormatColumns={customPlugins}
                                heightTablePX={500}
                            />
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </div>
    )
}

interface GridItemDenseProps {
    primary: React.ReactNode;
    secondary: React.ReactNode;
}

function GridItemDense(props: GridItemDenseProps) {

    return (
        <Grid item xs={12} sm={3}>
            <List dense disablePadding>
                <ListItem disableGutters>
                    <ListItemText primary={props.primary} secondary={props.secondary} />
                </ListItem>
            </List>
        </Grid>
    );
}