import React, { useState, useEffect, useContext } from "react";
import { create, update, getExchangeRates } from "../api/PaymentOrderApi";
import { Grid, Box, Button, Fab } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Cfdi } from "../model/Cfdi";
import translate from "../i18n/Translator";
import { PaymentOrderRequest, PaymentOrderCfdi, PaymentOrder, PaymentOrderCfdiItem, ExchangeRates } from "../model/PaymentOrder";
import { CfdisSelected, CfdiPayment } from "../model/Payment";
import Surface from "../components/Surface";
import PaymentIcon from "@material-ui/icons/Payment";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";
import ConfirmationPopup from "../components/ConfirmationPopup";
import AddIcon from "@material-ui/icons/Add";
import AddCfdiPopup from "./AddCfdiPopup";
import PaymentOrderCfdiList from "./PaymentOrderCfdiList";
import DateFormat from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import EditCfdiPopup from "./EditCfdiPopup";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import { isRoleOrParent } from "../model/Role";

interface CreateUpdatePaymentOrderViewProps {
    view: "update" | "create";
    cfdisSelected?: CfdisSelected;
    paymentOrder?: PaymentOrder;
    next(request: PaymentOrder): any;
    onCancel(): any;
    payCfdi(paySelected: CfdisSelected, paymentOrderId: string, paymentMethod: string): any;
}

export default function CreateUpdatePaymentOrderView(props: CreateUpdatePaymentOrderViewProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<PaymentOrderCfdiItem[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [cfdisInView, setCfdisInView] = useState<string[]>([]);
    const [request, setRequest] = useState<PaymentOrderRequest>({} as PaymentOrderRequest);

    const [cfdiId, setCfdiId] = useState<string>();
    const [dialog, setDialog] = useState<"delete" | "cancel" | "add" | "edit">();
    const [submitting, setSubmitting] = useState(false);
    const [item, setItem] = useState<PaymentOrderCfdiItem>();
    const [exchangeRates, setExchangeRates] = useState<ExchangeRates>();
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");

    const load = () => {
        setStatus("loading");
        let tempData = [] as PaymentOrderCfdiItem[];
        if (props.view === "create") {
            if (props.cfdisSelected) {
                props.cfdisSelected.cfdis.forEach(cfdi => {
                    let cfdiTemp = {
                        ...cfdi,
                        amount: cfdi.metadata.authorized_amount ? cfdi.metadata.authorized_amount.total_authorized : cfdi.metadata.balance,
                        payment_amount: cfdi.metadata.authorized_amount ? cfdi.metadata.authorized_amount.total_authorized : cfdi.metadata.balance,
                        payment_currency: cfdi.currency,
                        exchange_rate: 1,
                        reference_exchange_rate: 1
                    } as PaymentOrderCfdiItem;
                    tempData.push(cfdiTemp);
                });
            }

            setData(tempData);
            setCfdisInView(tempData.map(cfdi => cfdi.id));
            let request = { schedule_date: new Date() } as PaymentOrderRequest;
            setRequest(request);
            loadExchangeRates(request.schedule_date, (exchange) => {
                setExchangeRateCfdi(exchange, tempData);
            });
            setStatus("loaded");
        } else if (props.paymentOrder) {
            props.paymentOrder.list_cfdis.forEach(cfdiList => {
                let item = props.paymentOrder!.cfdis.find(cfdi => cfdi.cfdi === cfdiList.id);
                let cfdiTemp = {
                    ...cfdiList,
                    amount: item?.amount,
                    payment_amount: item?.payment_amount,
                    exchange_rate: item?.exchange_rate,
                    payment_currency: item?.payment_currency || cfdiList.currency,
                    status: item?.status,
                    reference_exchange_rate: item?.reference_exchange_rate
                } as PaymentOrderCfdiItem;
                tempData.push(cfdiTemp);
            });
            setData(tempData);
            setCfdisInView(tempData.map(cfdi => cfdi.id));
            setRequest({
                title: props.paymentOrder.title,
                schedule_date: props.paymentOrder.schedule_date,
                comments: props.paymentOrder.comments
            } as PaymentOrderRequest);
            loadExchangeRates(request.schedule_date);
            setStatus("loaded");
        }
    };

    const setExchangeRateCfdi = (exchangeRates: any, cfdis: PaymentOrderCfdiItem[]) => {
        if (exchangeRates != null) {
            cfdis.forEach(element => {
                if (element.currency === "MXN") {
                    element.reference_exchange_rate = 1.0;
                } else if (element.currency === "USD") {
                    element.reference_exchange_rate = exchangeRates.dollar.value;
                } else if (element.currency === "EUR") {
                    element.reference_exchange_rate = exchangeRates.euro.value;
                }
            });
        }
    }

    const loadExchangeRates = (scheduleDate?: Date, loadedExchangeRate?: (response: ExchangeRates) => void) => {
        if (scheduleDate) {
            const date = moment(scheduleDate).format("YYYY-MM-DD");
            getExchangeRates(context.session!.tenant!.id, date).then((response) => {
                setExchangeRates(response);
                if (loadedExchangeRate != null) {
                    loadedExchangeRate(response);
                }
            }).catch(error => {
                setError(error.message);
            });
        }
    };

    useEffect(load, [props]);

    const onConfirm = () => {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        const cfdis = data.map(cfdi => {
            let tempStatus = props.paymentOrder?.cfdis.find(paymentOrderCfdi => paymentOrderCfdi.cfdi === cfdi.id)?.status;
            return {
                cfdi: cfdi.id,
                amount: cfdi.amount,
                payment_amount: cfdi.payment_amount,
                payment_currency: cfdi.payment_currency,
                exchange_rate: cfdi.exchange_rate,
                provider_id: cfdi.metadata.provider_id,
                status: tempStatus ? tempStatus : "PENDING",
                reference_exchange_rate: cfdi.reference_exchange_rate
            } as PaymentOrderCfdi;
        });

        const paymentOrderRequest = {
            cfdis: cfdis,
            title: request.title,
            schedule_date: request.schedule_date,
            comments: request.comments
        } as PaymentOrderRequest;

        if (props.view === "create") {
            create(context.session!.tenant!.id, paymentOrderRequest).then((response) => {
                if (response.cfdis_cancelled) {
                    setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                    return;
                }
                props.next(response);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        } else if (props.paymentOrder) {
            update(context.session!.tenant!.id, props.paymentOrder.id, paymentOrderRequest).then((response) => {
                if (response.cfdis_cancelled) {
                    setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                    return;
                }
                props.next(response);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }

    };

    const openConfirmDialog = () => {
        if (request.schedule_date !== null && request.title !== undefined && request.title !== "") {
            let cfdisIncorrect = [] as string[];
            let cfdisInZero = [] as string[];
            data.filter(cfdi => cfdi.status === "PENDING").forEach((cfdi) => {
                if (cfdi.amount > cfdi.metadata.balance) {
                    cfdisIncorrect.push(cfdi.amount + " ");
                }
                if (cfdi.amount <= 0) {
                    cfdisInZero.push(cfdi.identifier);
                }
            });
            if (cfdisIncorrect.length > 0) {
                setWarning(translate("payments_cfdi.warning_amount_incorrect", { "cfdis": cfdisIncorrect }) as string);
            } else if (cfdisInZero.length > 0) {
                setWarning(translate("payment_order.warning_amount_zero", { "cfdis": cfdisInZero }) as string);
            } else {
                onConfirm();
            }
        } else {
            setWarning(translate("payments_cfdi.warning") as string);
        }
    };

    const cancelConfirmDialog = () => {
        setDialog(undefined);
    };

    const cancelConfirmDeleteDialog = () => {
        setDialog(undefined);
        setCfdiId(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const hasChangedAmount = (name: string, value: number, inputRef: InputRef) => {
        let tempData = data;
        tempData.forEach((cfdi) => {
            if (cfdi.id === name) {
                cfdi.payment_amount = value;
            }
        });
        setData(tempData);
        setCfdisInView(tempData.map(cfdi => cfdi.id));
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setRequest({ ...request, schedule_date: date });
            loadExchangeRates(date, (exchange) => {
                setExchangeRateCfdi(exchange, data);
            });
        }
    };

    const addCfdis = (cfdis: Cfdi[]) => {
        setDialog(undefined);
        let tempData = data;
        cfdis.forEach(cfdi => {
            let cfdiTemp = {
                ...cfdi,
                amount: cfdi.metadata.authorized_amount ? cfdi.metadata.authorized_amount.total_authorized : cfdi.metadata.balance,
                payment_amount: cfdi.metadata.authorized_amount ? cfdi.metadata.authorized_amount.total_authorized : cfdi.metadata.balance,
                payment_currency: cfdi.currency,
                exchange_rate: 1,
                reference_exchange_rate: 1
            } as PaymentOrderCfdiItem;
            tempData.push(cfdiTemp);
        });
        if (exchangeRates) {
            setExchangeRateCfdi(exchangeRates, tempData);
        }
        setData(tempData);
        setCfdisInView(tempData.map(cfdi => cfdi.id));
    };

    const onCloseDialog = () => {
        setItem(undefined);
        setDialog(undefined);
    };

    const openAddCfdi = () => {
        setDialog("add");
    };

    const onConfirmCancelDialog = () => {
        setDialog(undefined);
        props.onCancel();
    }

    const onConfirmDelete = () => {
        setData(data.filter(cfdi => cfdi.id !== cfdiId));
        setCfdisInView(data.filter(cfdi => cfdi.id !== cfdiId).map(cfdi => cfdi.id));
        setCfdiId(undefined);
        setDialog(undefined);
    };

    const deleteCfdi = (cfdiId: string) => {
        setCfdiId(cfdiId);
        setDialog("delete");
    };

    const editCfdi = (cfdiId: string) => {
        let item = data.find(cfdi => cfdi.id === cfdiId);
        if (!item) return;

        if (item?.cfdi && item.cfdi.metodo_pago === "PUE" && item?.currency === "MXN") {
            setWarning(translate("cfdis.total_authorize.invalid_for_partiallity") as string);
            return;
        }
        if (!item?.reference_exchange_rate) {
            setExchangeRateCfdi(exchangeRates, [item]);
        }

        setItem(item);
        setCfdiId(cfdiId);
        setDialog("edit");
    };

    const onEdited = (item: PaymentOrderCfdiItem) => {
        onCloseDialog();
        const updated = data.map(el => {
            if (el.id === item.id) {
                return item;
            }
            return el;
        });
        setData(updated);
    };

    const payCfdi = (cfdiId: string) => {
        setCfdiId(cfdiId);
        let provider_id = "";
        let dataSelect = {} as CfdisSelected;
        let cfdiSeleted = [] as CfdiPayment[];

        data.forEach((cfdi) => {
            if (cfdi.id === cfdiId) {
                provider_id = cfdi.metadata.provider_id;
            }
        });

        data.forEach((cfdi) => {
            if (cfdi.metadata.provider_id === provider_id && props.paymentOrder!.cfdis.find(cfdiTemp => cfdiTemp.cfdi === cfdi.id)?.status === "PENDING") {
                cfdiSeleted.push(cfdi);
            }
        });
        dataSelect.cfdis = cfdiSeleted;
        props.payCfdi(dataSelect, props.paymentOrder != null ? props.paymentOrder.id : "", cfdiSeleted[0].cfdi != null ? cfdiSeleted[0].cfdi.metodo_pago : "PUE");

    };

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    return (
        <div>
            <Surface title={props.view === "create" ? translate("payment_order.view." + props.view) as string : request.title}
                icon={<PaymentIcon />} className="PaperPagination PaperTabContainer"
                subtitle={(
                    <div>
                        <Ellipsis text={translate("payment_order.subtitle_date") as string} lenght={0} uppercased />
                        <DateFormat date={request.schedule_date} format="DD/MM/YYYY" />
                    </div>
                )}
                titleActions={props.paymentOrder && (props.paymentOrder.status === "PAYMENT_TO_EXECUTE_BANK" || props.paymentOrder.status === "PAID") ? undefined :
                    (
                        <Fab color="primary" size="small" title={translate("payment_order.add_cfdi") as string} onClick={openAddCfdi}>
                            <AddIcon />
                        </Fab>
                    )}>
                <Grid container justify="space-between" alignItems="center" >
                    <Grid item xs={12}>
                        <Grid container className="TableFilter" spacing={1}>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="title" name="title" disabled={submitting}
                                    value={request.title} label={translate("payment_order.title_input") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker label={translate("payment_order.schedule_date") as string}
                                    name="schedule_date"
                                    initial={request.schedule_date ? moment(request.schedule_date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}
                                    onChange={onChangedDate}
                                    required={true}
                                    defaultIfMissing={false} clearable={false} autoOK disablePast={false} disableFuture={false} disabled={submitting} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaymentOrderCfdiList
                            data={data}
                            edit={!submitting}
                            comments={request.comments}
                            status_order={props.paymentOrder != null ? props.paymentOrder.status : ""}
                            date={request.schedule_date}
                            paymentOrderId={props.paymentOrder ? props.paymentOrder.id : ""}
                            isReaderOnly={isReaderOnly}
                            hasChangedAmount={hasChangedAmount}
                            deleteCfdi={deleteCfdi}
                            payCfdi={payCfdi}
                            editCfdi={editCfdi}
                            onCancelCfdi={() => { }}
                            hasChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4} pr={2}>
                            <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                <Grid item xs={12} md="auto">
                                    <Button variant="contained" color="primary" size="large" onClick={openConfirmDialog} >
                                        {translate(props.view === "create" ? "buttons.registry" : "buttons.save")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <Button variant="text" color="primary" size="large" onClick={() => setDialog("cancel")}>
                                        {translate("buttons.cancel")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {dialog === "delete" && (
                    <ConfirmationPopup title={translate("payment_order.delete_dialog_title") as string}
                        message={translate("payment_order.delete_dialog_message") as string}
                        button={translate("buttons.accept") as string}
                        onClose={cancelConfirmDeleteDialog}
                        doAction={onConfirmDelete} />
                )}
                {dialog === "cancel" && (
                    <ConfirmationPopup title={translate(props.view === "create" ? "payment_order.cancel_popup.title" : "payment_order.cancel_edit_popup.title") as string}
                        message={translate(props.view === "create" ? "payment_order.cancel_popup.message" : "payment_order.cancel_edit_popup.message") as string}
                        button={translate("buttons.accept") as string}
                        onClose={cancelConfirmDialog}
                        doAction={onConfirmCancelDialog} />
                )}
                {dialog === "add" && (
                    <AddCfdiPopup
                        tenantId={context.session!.tenant!.id}
                        cfdisInView={cfdisInView}
                        doAction={addCfdis}
                        onClose={onCloseDialog} />
                )}
                {dialog === "edit" && exchangeRates && item && (
                    <EditCfdiPopup item={item} scheduleDate={request.schedule_date || new Date()} exchangeRates={exchangeRates} onClose={onCloseDialog} onChanged={onEdited} tenantId={context.session!.tenant!.id} />
                )}
                {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} />
                    : undefined}
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            </Surface>
        </div>
    );
}
