import React, { useState, useEffect } from "react";
import { Grid, Button, Divider, ListItemIcon } from "@material-ui/core";
import translate from "../i18n/Translator";
import { InputRef } from "../components/ValidatedInput";
import { WarningSnackbar } from "../components/Snackbars";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput from "../components/ValidatedInput";
import { PaymentPercentageRequest } from "../model/Requisition";
import NumberFormat from "react-number-format";

export interface PercentagesPopupProps {
    installmentRequests: PaymentPercentageRequest[];
    amountTotal: string;
    onSave(request: PaymentPercentageRequest[]):any;
    onClose(): any;
}

export default function PercentagesPopup(props: PercentagesPopupProps) {
    const [warning, setWarning] = useState<string>("");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [percentagesTotal, setPercentagesTotal] = useState<number>(0);
    const [amountsTotal, setAmountsTotal] = useState<number>(0);
    const [request, setRequest] = useState<PaymentPercentageRequest[]>([]);
    const totalFromView = parseFloat(props.amountTotal);

    useEffect(() => {
        let temReq = [] as PaymentPercentageRequest[];
        props.installmentRequests.forEach((inst) => {
            temReq.push({...inst} as PaymentPercentageRequest);
        });
        setRequest(temReq);
        let temp = temReq.reduce((sum, pp) => sum + pp.percentage, 0);
        setPercentagesTotal(temp);
        setAmountsTotal(temp* totalFromView / 100);
    }, [props, totalFromView])

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        let temp = request;
        temp.forEach(pp => {
            if(pp.payment_number === name){
                pp.percentage = parseFloat(value);
            }
        });
        let tempPP = temp.reduce((sum, pp) => sum + pp.percentage, 0);
        setPercentagesTotal(tempPP);
        setAmountsTotal(tempPP* totalFromView / 100);
        setRequest(temp);
    };

    const onClosedSnackbar = () => {
        setWarning("");
    };

    const onSave = () => {
        setSubmitting(true);
        props.onSave(request);
        setSubmitting(false);
    };

    const onClose = () => {
        props.onClose();
    };

    return <DialogPopup open
            title={translate("requisitions.form.quotation.percentages.title") as string}
            button={
                <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={submitting 
                || percentagesTotal !== 100 || amountsTotal !== totalFromView}>
                    {translate("buttons.save")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            maxWidth="md"
            onClose={onClose}>
            <Grid container className="ShippingField">
                {request.map(pp => (
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            {translate("requisitions.form.quotation.percentages.parcialities", { "number" : pp.payment_number}) as string }
                        </Grid>
                        <Grid item xs={4}>
                            <ListItemIcon>
                                <ValidatedInput
                                    type="number"
                                    id={pp.payment_number}
                                    name={pp.payment_number}
                                    value={pp.percentage+""}
                                    label={""}
                                    required={true}
                                    disabled={false}
                                    margin="dense"
                                    onValueChanged={hasChanged}
                                />{"%"} 
                            </ListItemIcon>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberFormat value={pp.percentage * totalFromView / 100}
                                prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                        </Grid>
                    </Grid>
                ))}
                
                <Grid container spacing={1} >
                <Grid item xs={12}><Divider /> </Grid>
                    <Grid item xs={4}>{translate("requisitions.form.quotation.percentages.totals") as string}</Grid>
                    <Grid item xs={4}>
                    <NumberFormat style={percentagesTotal !== 100 ? { "color": "#ff0000" } : undefined}  value={percentagesTotal}
                            suffix={" %"} decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberFormat style={amountsTotal !== totalFromView ? { "color": "#ff0000" } : undefined} value={amountsTotal}
                            prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                    </Grid>
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
}
