import { callAPI, HTTPMethod } from "./API";
import { PaymentConditionListParams, PaymentConditionsResponse as PaymentConditions, PaymentConditionRequest, PaymentCondition } from "../model/TenantConfiguration";

const basePath = (tenantId: string) => `/tenants/${tenantId}/payment-conditions`;

export async function list(tenantId: string, page: number, offset: number, params: PaymentConditionListParams): Promise<PaymentConditions> {
    let query = {
        search: params.search || "",
        active: params.active || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;
    
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function create(tenantId: string, request: PaymentConditionRequest): Promise<PaymentCondition> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function get(tenantId: string, teamId: string): Promise<PaymentCondition> {
    return await callAPI(`${basePath(tenantId)}/${teamId}`, {
        method: HTTPMethod.GET,
    });
}

export async function update(tenantId: string, teamId: string, request: PaymentConditionRequest): Promise<PaymentCondition> {
    return await callAPI(`${basePath(tenantId)}/${teamId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}
