import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EmployeeIcon from "@material-ui/icons/PeopleAlt";
import AddIcon from "@material-ui/icons/Add";

import { listEmployees, deleteEmployee } from "../api/EmployeeAPI";
import { listEmployees as listProviderEmployees, deleteEmployee as deleteEmployeeProvider } from "../api/ProviderEmployeeAPI";
import { AppContext } from "../context/AppContext";
import { Employee, Employees, EmployeesQueryParams } from "../model/Employee";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import EmployeeMenu from "./EmployeeMenu";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import { red, green } from "@material-ui/core/colors";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar } from "../components/Snackbars";

export default function EmployeesList() {
    const context = useContext(AppContext);
    const history = useHistory();
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Employees>();
    const [workingParams, setWorkingParams] = useState<EmployeesQueryParams>({
        search: search,
    } as EmployeesQueryParams);

    const [employee, setEmployee] = useState<Employee>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const promiseGet = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as EmployeesQueryParams;
        setWorkingParams(params);
        if (providerId) {
            return listProviderEmployees(tenantId, providerId, pageSize, offset, params)
        }
        return listEmployees(tenantId, pageSize, offset, params)
    };

    const load = () => {
        promiseGet().then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }

    useEffect(load, [tenantId, providerId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (employee: Employee) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setEmployee(employee);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setEmployee(undefined);
    };

    const onClickRow = (employee: Employee) => {
        history.push(`/employees/${employee.id}/overview`);
    };

    const promiseDelete = (employeeId: string) => {
        if (providerId) {
            return deleteEmployeeProvider(tenantId, providerId, employeeId);
        }
        return deleteEmployee(tenantId, employeeId);
    };

    const onDelete = () => {
        setAnchorEl(null);
        if(!employee) return;
        setProcessing(true);
        promiseDelete(employee.id).then((_) => {
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setEmployee(undefined);
            setProcessing(false);
        });
    };

    const getIconCertified = (employee: Employee) => {
        if(context.session?.provider?.upload_sipare){
            return "N/A";
        }
        if(employee.certified !== undefined && !employee.certified){ 
            return   <DotIcon fontSize="small" style={{ "color": red[500] }} />
        }
        return  <DotIcon fontSize="small" style={{ "color": green[500] }} />;
       
    };

    return (
        <Pagination title={translate("employees.title")} icon={<EmployeeIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["EmployeesCreate", "ProviderEmployeesCreate"]) ?
                (<Link to="/employees/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("employees.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("employees.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("employees.rfc") as string,
                        converter: (employee) => employee.rfc,
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("employees.name") as string,
                        converter: (employee) => (
                            <Ellipsis text={employee.name} lenght={100} uppercased={false} />
                        ),
                        fullWidth: true,
                        xs: 4,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 4
                    },
                    {
                        title: translate("employees.nss") as string,
                        converter: (employee) => employee.nss,
                        fullWidth: true,
                        xs: false,
                        sm: 3,
                        md: 3,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("employees.certified") as string,
                        converter: (employee) => (
                            getIconCertified(employee)
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("employees.documents") as string,
                        converter: (employee) => employee.documents,
                        fullWidth: true,
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (employee) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(employee)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {employee && anchorEl &&
                <EmployeeMenu employee={employee} anchor={anchorEl} onClose={onCloseOption} onDelete={onDelete}/>
            }
            <CustomBackdrop open={processing} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Pagination>
    );
}