import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export interface ProgressProps {
    size?: string;
}

export default function Progress(props: ProgressProps) {
    return (
        <Grid container alignItems="center" alignContent="center" justify="center">
            <Grid item xs="auto">
                <CircularProgress size={props.size ?? "1rem"} color="secondary" />
            </Grid>
        </Grid>
    )
}