import React, { useState, useContext } from "react";
import { Grid, List, ListItem, ListItemText, ListItemIcon, Card, ListSubheader, ListItemSecondaryAction, IconButton, Box } from "@material-ui/core";
import UndefinedIcon from '@material-ui/icons/ContactSupportTwoTone';
import VisibilityIcon from '@material-ui/icons/VisibilityTwoTone';
import CleanIcon from "@material-ui/icons/BeenhereTwoTone";
import DirtyIcon from '@material-ui/icons/ErrorTwoTone';

import translate from "../i18n/Translator";
import { Entity } from "../model/Provider";
import { Efos } from "../model/Efos";
import { formatDate } from "../components/DateFormat";
import ProviderVerificationPopup from "./ProviderVerificationPopup";
import { ProviderEfoIcon, Provider69CffIcon } from "./ProviderIcons";
import { AppContext } from "../context/AppContext";
import { BlackListValidations } from "../model/Cff69";
import ProviderAccountingBalance from "./components/ProviderAccountingBalance";


interface ProviderInfoProps {
    provider: Entity;
}

export default function ProviderInfo(props: ProviderInfoProps) {
    const context = useContext(AppContext);
    return (
        <Box pt={2} px={2}>
            <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                {props.provider.accounting_balance &&
                    <Grid item xs={12}>
                        <ProviderAccountingBalance provider={props.provider} card_mode={true} />
                    </Grid>
                }
                <Grid item xs={12} md={context.isGrantedAny(["EfosRead", "Cff69Read"]) ? 8 : 12}>
                    <GeneralInfoCard provider={props.provider} />
                </Grid>
                {context.isGrantedAny(["EfosRead", "Cff69Read"]) && (
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={3}>
                            {context.isGranted("EfosRead") && (
                                <EfosCard provider={props.provider} efos={props.provider.efos} />
                            )}
                            {context.isGranted("Cff69Read") && (
                                <Cff69Card blackListValidations={props.provider.black_list_validations} />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

interface GeneralInfoProps {
    provider: Entity;
}

function GeneralInfoCard(props: GeneralInfoProps) {
    return (
        <Card variant="outlined" >
            <ListSubheader>{translate("providers.general_data.title")}</ListSubheader>
            <Box px={2} pb={2}>
                <Grid container alignItems="flex-start" justify="space-between">
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={translate(`providers.types.${props.provider.type}`)} secondary={translate("providers.types.title")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={props.provider.external_id || "---"} secondary={translate("providers.external_id")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={props.provider.tax_id || "---"} secondary={translate("providers.tax_id")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={translate(`countries.${props.provider.country}`)} secondary={translate("providers.country")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={translate(`providers.consignment_note.types.${props.provider.require_consignment_note ? props.provider.require_consignment_note : "NOT_REQUIRED"}`)} secondary={translate("providers.consignment_note.title")} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={translate(`providers.status.${props.provider.status}`)} secondary={translate("providers.status_enabled")} />
                            </ListItem>
                            {props.provider.validator_user &&
                                <ListItem disableGutters>
                                    <ListItemText primary={props.provider.validator_user.name} secondary={translate("providers.validator")} />
                                </ListItem>
                            }
                            {props.provider.workflows_response?.length > 0 && (
                                <ListItem disableGutters>
                                    <ListItemText primary={props.provider.workflows_response.map(w => w.name).join(", ")} secondary={translate("workflows.title")} />
                                </ListItem>
                            )}
                            {props.provider.max_credit_days && (
                            <ListItem disableGutters>
                                <ListItemText primary={props.provider.max_credit_days} secondary={translate("providers.credit_days_title")} />
                            </ListItem>
                            )}
                            {props.provider.max_payment_cfdi_pending && (
                            <ListItem disableGutters>
                                <ListItemText primary={props.provider.max_payment_cfdi_pending} secondary={translate("providers.max_cfdis_pending_payment")} />
                            </ListItem>
                            )}
                            {props.provider.cash_discount_percentage && (
                            <ListItem disableGutters>
                                <ListItemText primary={translate("providers.cash_discount.percentaje_value", {percentaje: props.provider.cash_discount_percentage, days: props.provider.cash_discount_days})} secondary={translate("providers.cash_discount.discount_prompt_payment")} />
                            </ListItem>
                            )}
                            {props.provider.use_advance_invoices !== undefined && (
                            <ListItem disableGutters>
                                <ListItemText primary={props.provider.use_advance_invoices !== undefined && props.provider.use_advance_invoices ? translate("providers.advance_invoices.enable") : translate("providers.advance_invoices.disable")} secondary={translate("providers.invoice_advance")} />
                            </ListItem>)}
                            {props.provider.allow_invoice_reception !== undefined && (
                            <ListItem disableGutters>
                                <ListItemText primary={props.provider.allow_invoice_reception !== undefined && props.provider.allow_invoice_reception ? translate("providers.invoice_reception.enable") : translate("providers.invoice_reception.disable")} secondary={translate("providers.invoice_reception")} />
                            </ListItem>)}
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

interface EfosCardProps {
    provider: Entity;
    efos?: Efos;
}

function EfosCard(props: EfosCardProps) {
    const [efos, setEfos] = useState<Efos>();

    return (
        <Grid item xs={12} sm={6} md={12}>
            <Card variant="outlined" >
                <List component="nav" dense subheader={
                    <ListSubheader disableSticky>
                        {translate("efo.title")}
                    </ListSubheader>
                }>
                    {props.efos && (
                        <ListItem>
                            <ListItemIcon>
                                <ProviderEfoIcon status={props.efos.status} />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate(`efo.status.${props.efos.status}`)}
                                secondary={formatDate(props.efos.published, "ll")} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => setEfos(props.efos)}>
                                    <VisibilityIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                    {!props.efos && (
                        <ListItem>
                            <ListItemIcon>
                                <UndefinedIcon fontSize="large" color="disabled" />
                            </ListItemIcon>
                            <ListItemText primary="---" secondary={translate("efo.title")} />
                        </ListItem>
                    )}
                </List>
            </Card>
            {efos && (
                <ProviderVerificationPopup
                    name={props.provider.name}
                    rfc={props.provider.rfc}
                    efos={efos}
                    onClose={() => setEfos(undefined)} />
            )}
        </Grid>
    );
}

interface StatusCff69IconProps {
    isClean: boolean;
}

function StatusCff69Icon(props: StatusCff69IconProps) {
    return (
        <>
            { props.isClean && (<CleanIcon style={{ "color": "#82CA9D" }} />)}
            { !props.isClean && (<DirtyIcon style={{ "color": "#C33149" }} />)}
        </>
    );
}

interface Cff69CardProps {
    blackListValidations?: BlackListValidations;
}

function Cff69Card(props: Cff69CardProps) {
    const LOCALIZADO = "LOCALIZADO";
    const CLEAN = "LIMPIO";

    let data = {
        "NO_LOCALIZADO": LOCALIZADO === props.blackListValidations?.not_located.status,
        "CANCELADO": CLEAN === props.blackListValidations?.canceled_result.status,
        "EXIGIBLE": CLEAN === props.blackListValidations?.enforceable_result.status,
        "FIRME": CLEAN === props.blackListValidations?.firm_result.status,
        "SENTENCIADO": CLEAN === props.blackListValidations?.sentences_result.status
    } as any;

    return (
        <Grid item xs={12} sm={6} md={12}>
            <Card variant="outlined">
                <List component="nav" dense subheader={
                    <ListSubheader disableSticky>
                        {translate("cff_69.title") as string}
                    </ListSubheader>
                }>
                    {props.blackListValidations && (
                        <div>
                            <ListItem>
                                <ListItemText primary={formatDate(props.blackListValidations.not_located.published, "ll")} />
                            </ListItem>
                            {
                                Object.keys(data).map((key) => (
                                    <ListItem key={key}>
                                        <ListItemIcon>
                                            <StatusCff69Icon isClean={data[key] as boolean} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate(`cff_69.categories.${key}`)} />
                                    </ListItem>
                                ))
                            }
                        </div>)}
                    {!props.blackListValidations && (
                        <ListItem>
                            <ListItemIcon>
                                <Provider69CffIcon status="" />
                            </ListItemIcon>
                            <ListItemText primary="---" secondary={translate("cff_69.title")} />
                        </ListItem>
                    )}
                </List>
            </Card>
        </Grid>
    );
}