import React, { useContext, useEffect, useState } from "react";
import { Grid, Card, CardHeader, CardContent, Box, Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import DateFormat from "../components/DateFormat";
import Row from "../components/Row";
import { AppContext } from "../context/AppContext";
import { getCoverUrl } from "../api/ExpensesAccountAPI";

interface ExpensesAccountDetailsProps {
    payableDocument: Cfdi;
}

export default function ExpensesAccountDetails(props: ExpensesAccountDetailsProps) {
    const context = useContext(AppContext);
    const hasCustomsPaperwork = props.payableDocument.expenses_account?.honorarium?.customs_paperwork !== undefined;
    const pending = (props.payableDocument.expenses_account?.honorarium?.customs_paperwork || 0) - (props.payableDocument.expenses_account?.total || 0);
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        const path = props.payableDocument.expenses_account?.cover_path;
        if (!path) return;

        const providerId = context.session!.provider?.id || props.payableDocument.metadata.provider_id;
        getCoverUrl(context.session!.tenant!.id, providerId, props.payableDocument.id).then(url => {
            setUrl(url);
        }).catch(() => {
            setUrl(undefined);
        });
    }, [context.session, props.payableDocument]);

    return (
        <Card variant="outlined">
            <CardHeader subheader={(
                <strong>{translate("payable_documents.expenses_account")}</strong>
            )} disableTypography />
            <CardContent>
                <Box px={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Row title={translate("expenses_accounts.description") as string} content={props.payableDocument.description} />
                            <Row title={translate("expenses_accounts.date") as string} content={(
                                <DateFormat date={props.payableDocument.date} format="L" />
                            )} />
                            <Row title={translate("expenses_accounts.folio") as string} content={props.payableDocument.identifier} />
                            {url && (
                                <Row title={translate("expenses_accounts.cover") as string} content={(
                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                        {translate("buttons.show_file")}
                                    </a>
                                )} />
                            )}
                            {hasCustomsPaperwork && (
                                <Typography variant="caption" component="h6" color="textSecondary">
                                    <i>{translate("honorarium.has_customs_paperwork")}</i>
                                </Typography>
                            )}
                            {props.payableDocument.expenses_account && (
                                <Row title={translate("expenses_accounts.total") as string} content={(
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="button" color="primary">
                                                <NumberFormat value={props.payableDocument.expenses_account.total} prefix={`${props.payableDocument.currency} $`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                            </Typography>
                                        </Grid>
                                        {hasCustomsPaperwork && pending > 0 && (
                                            <Grid item xs="auto">
                                                <Typography variant="button" component="h6" color="error" align="right">
                                                    <NumberFormat value={pending}
                                                        prefix={`${"Por comprobar:"} ${props.payableDocument.currency} $`}
                                                        thousandSeparator="," decimalScale={2} fixedDecimalScale={true}
                                                        displayType="text" />
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                )} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}
