import React, { useState } from "react";
import { Button, Typography, Grid} from "@material-ui/core";
import translate from "../../i18n/Translator";

import { Cfdi, getAmountDiscounts } from "../../model/Cfdi";
import CfdisConfirmPopup from "../../components/ConfirmationPopup";

interface DeleteCfdiPopupProps {
    cfdi: Cfdi;
    messageDefault: string;
    submitting?: boolean;
    omitCommentary?: boolean;
    onConfirmDeleteComprobante(razon?: string): any;
    onCloseConfirmDialogDelete(): any;
}

export default function DeleteCfdiPopup(props: DeleteCfdiPopupProps) {
    
    const setMessageData = () => {
        if(props.cfdi.metadata.advance_metadata?.is_complementary_advanced  &&
            props.cfdi.cfdi?.conceptos.filter(concepto => concepto.description.toUpperCase().includes("CFDI POR REMANENTE DE UN ANTICIPO")).length === props.cfdi.cfdi?.conceptos.length){
            let totalDiscounts = getAmountDiscounts(props.cfdi);
            return translate("cfdis.pay_pending.actions.delete.description_complementary_advance", {
                "uuid": props.cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados[0],
                "discounts_amount": totalDiscounts,
                "currency": props.cfdi.currency
            }) as string;
        } else if(props.cfdi.metadata.advance_metadata?.is_complementary_advanced &&
            props.cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados &&
            props.cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados.length > 1){
                return translate("cfdis.pay_pending.actions.delete.description_complementary_various_advances", {
                    "uuids": props.cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados.join(", ")
                }) as string;
        } else {
            return props.messageDefault;
        }
    };
    
    const messageDelete = setMessageData();

    return (
        <CfdisConfirmPopup
            submitting={props.submitting}
            doActionCommentary={props.omitCommentary ? undefined : props.onConfirmDeleteComprobante}
            doAction={props.omitCommentary ? props.onConfirmDeleteComprobante : undefined}
            onClose={props.onCloseConfirmDialogDelete}
            title={translate("cfdis.pay_pending.actions.delete.title") as string}
            message={messageDelete}
            secondary={translate("cfdis.pay_pending.actions.delete.secondary") as string}
            button={translate("buttons.accept") as string}
            comment={translate("cfdis.pay_pending.actions.delete.input") as string}
            addCommentary={!props.omitCommentary} />
    );

}