import React, { useContext, useEffect, useState } from "react"
import Surface from "../components/Surface";
import { LibraryBooks } from "@material-ui/icons";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Grid, Divider, Fab } from "@material-ui/core";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { DataTypeProvider, Filter } from "@devexpress/dx-react-grid";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import queryString from "query-string";
import translate from "../i18n/Translator";
import { ExtendedFieldsTemplate, ExtendedFieldsTemplates, ExtendedFieldsTemplatesParams } from "../model/ExtendedFieldsTemplate";
import { disabledExtendedFieldsTemplate, listExtendendFieldsTemplates } from "../api/ExtendedFieldsTemplateAPI";
import { AddIcon } from "../components/Icons";
import ExtendedFieldsTemplateMenu from "./ExtendedFieldsTemplateMenu";
import { RouterParams } from "../router/RouterParams";

export default function ExtendedFieldsTemplateList({match} : RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ExtendedFieldsTemplates>();
    
    const [currentTemplateClicked, setCurrentTemplateClicked] = useState<ExtendedFieldsTemplate>()

    const [success, setSuccess] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [params, setParams] = useState<ExtendedFieldsTemplatesParams>({
        "name": typeof qs["name"] === "string" ? qs["name"] as string : ""
    } as ExtendedFieldsTemplatesParams);
    const [redirect, setRedirect] = useState<string>();

    const URL_NEW_TEMPLATE = `/projects/templates/create`;
    const clickRowColumns = ['name'];
    const textColumns = ['name'];
    const dateColumns = ['created_at', 'last_update'];

    const columnsFormat = [
        { columnName: 'name', wordWrapEnabled: true },
        { columnName: 'created_at', align: 'center', wordWrapEnabled: true },
        { columnName: 'last_update', align: 'center', wordWrapEnabled: true },
        { columnName: 'menu', align: 'right', width: 80 }
    ];

    const columns = [
        {
            name: 'name',
            title: translate("extended_fields_templates.table.columns.template_name") as string
        },
        {
            name: 'created_at',
            title: translate("extended_fields_templates.table.columns.created_date") as string

        },
        {
            name: 'last_update',
            title: translate("extended_fields_templates.table.columns.updated_date") as string

        },
        {
            name: 'menu',
            title: " "
        }
    ];

    const filtersExceptions = ['created_at', 'last_update'];

    const customPlugins = [<DataTypeProvider for={filtersExceptions} editorComponent={NoFilterEditor} />];

    const paramsToFilters = () => [{ columnName: "name", value: params.name }] as Filter[];

    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        listExtendendFieldsTemplates(context.session!.tenant!.id, params, page, pageSize * page).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        })
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const onClickedRow = (template: ExtendedFieldsTemplate) => {
        pushHistory();
        setRedirect(`/projects/templates/${template.id}/edit`);
    }

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromFilters(filters);
    }

    const setParamsFromFilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "name":
                        temp.name = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
        pushHistory();
    }

    const onChangedPage = (page: number) => { setPage(page); }

    const onChangedPageSize = (pageSize: number) => { setPageSize(pageSize) }

    const onClickedOptions = (template: ExtendedFieldsTemplate) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrentTemplateClicked(template);
    };

    const onDisabled = (template: ExtendedFieldsTemplate) => {
        setAnchorEl(null);
        setStatus("loading");
        disabledExtendedFieldsTemplate(context.session!.tenant!.id, template.id).then((response) => {
            successDisabledTemplate();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    const onEdit = (template: ExtendedFieldsTemplate) => {
        setAnchorEl(null);
        pushHistory();
        setRedirect(`/projects/templates/${template.id}/edit`);
    }

    const onCloseOption = () => {
        setAnchorEl(null);
        setCurrentTemplateClicked(undefined);
    }

    const successDisabledTemplate = () => {
        setCurrentTemplateClicked(undefined);
        load();
        setSuccess(translate("extended_fields_templates.on.disabled_template.success") as string);
    }

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["name"] = params.name ? params.name : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    if (redirect) {return (<Redirect to={redirect} />);}

    return (
        <Surface
            title={translate("extended_fields_templates.title") as string}
            subtitle={translate("extended_fields_templates.subtitle") as string}
            icon={<LibraryBooks />}
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>
                    {context.isGrantedAny(["ExtendedFieldsTemplateCreate"])?
                        (<Grid item xs="auto">
                            <Link to={URL_NEW_TEMPLATE}>
                                <Fab color="primary" size="small" title={translate("buttons.add") as string}
                                     disabled={status === "loading"}>
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </Grid>) : undefined}
                </Grid>
            }
            className="PaperPagination">

            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={columns}
                columnsFormat={columnsFormat}
                dateColumns={dateColumns}
                onClickRow={onClickedRow}
                clickRowColumns={clickRowColumns}
                textColumns={textColumns}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                filters={filters}
                setFiltersHandler={setFiltersHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
                showTimeInDates={false}
                customFormatColumns={customPlugins}
            />
            <SuccessSnackbar message={success} />
            <ErrorSnackbar message={error} />
            <WarningSnackbar message={warning} />
            {currentTemplateClicked && anchorEl && (context.isGrantedAny(["ExtendedFieldsTemplateUpdate","ExtendedFieldsTemplateDelete"])) && (
                <ExtendedFieldsTemplateMenu
                    onEdit={() => onEdit(currentTemplateClicked!)}
                    onDisabled={() => onDisabled(currentTemplateClicked!)}
                    anchor={anchorEl}
                    onClose={onCloseOption} />
            )}
        </Surface>
    )
}