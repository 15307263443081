import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { SendIcon, DownloadIcon, DeleteIcon, EditIcon } from '../components/Icons';

import translate from "../i18n/Translator";
import { EmployeeVisit } from "../model/EmployeeVisit";
import { AppContext } from "../context/AppContext";

interface EmployeeMenuProps {
    employeeVisit: EmployeeVisit;
    anchor: HTMLElement | null;
    onDelete(): any;
    onSendExecution(): any;
    onDownloadList(): any;
    onClose(): any;
}

export default function EmployeeMenu(props: EmployeeMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="employee-visits-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button onClick={props.onDownloadList} dense>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("employee_visits.menu.download_list")}</Typography>
            </MenuItem>
            {props.employeeVisit.status === 'TO_SEND' && context.isGrantedAny(["EmployeeVisitUpdate"]) &&
                <MenuItem button onClick={props.onSendExecution} dense>
                    <ListItemIcon>
                        <SendIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("employee_visits.menu.send_execution")}</Typography>
                </MenuItem>
            }
            {props.employeeVisit.status !== 'FINALIZED' && context.isGrantedAny(["EmployeeVisitUpdate"]) &&
                <MenuItem button component={Link} to={`/employee-visits/${props.employeeVisit.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {props.employeeVisit.status === 'TO_SEND' && context.isGrantedAny(["EmployeeVisitDelete"]) &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}