import { callAPI, HTTPMethod } from "./API";
import { Companies, Company, CompaniesQueryParams, CompanyRequest, CompanyInvoiceReceptionRequest } from "../model/Company";

const basePath = (tenantId: string) => `/tenants/${tenantId}/companies`;

export async function listCompanies(tenantId: string, page: number, offset: number, params: CompaniesQueryParams): Promise<Companies> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function listByTenantBudgets(tenantId: string): Promise<Companies> {
    return await callAPI(`${basePath(tenantId)}/by-tenant-budgets`, {
        method: HTTPMethod.GET
    });
}

export async function createCompany(tenantId: string, request: CompanyRequest): Promise<Company> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getCompany(tenantId: string, id: string): Promise<Company> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateCompany(tenantId: string, id: string, request: CompanyRequest): Promise<Company> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCompany(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function updateInvoiceReception(tenantId: string, request: CompanyInvoiceReceptionRequest): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/update-invoice-reception`, {
        method: HTTPMethod.PUT,
        body: request
    });
}
