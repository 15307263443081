import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import ValidatedInput from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Entity as Provider } from "../model/Provider";
import { getReceptionSpecificRules } from "../api/SpecificReceptionRuleAPI";
import { SpecificReceptionRulesQueryParams } from "../model/SpecificReceptionRule";
import translate from "../i18n/Translator";
import { assignSpecialRule } from "../api/ProviderAPI";
import { ProviderAssignSpecificRuleRequest } from "../model/Provider";
import Progress from "../components/Progress";

interface ProviderAssignComboPopupProps {
    action: string;
    tenantId: string;
    providers: Provider[];
    onSuccess(update: number): any;
    onClose(): any;
}

export default function ProviderAssignComboPopup(props: ProviderAssignComboPopupProps) {
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [requestSpecificRule, setRequestSpecificRule] = useState<ProviderAssignSpecificRuleRequest>({} as ProviderAssignSpecificRuleRequest);
    const [ids, setIds] = useState<string[]>([]);
    const [names, setNames] = useState<string[]>([]);
    const [providersAssignated, setProvidersAssignated] = useState<number>(0);

    const loadReceptionRule = () => {
        setRequestSpecificRule({
            specific_rule_id: "",
            provider_ids: props.providers.map(provider => provider.id)
        } as ProviderAssignSpecificRuleRequest);

        let temp = 0
        props.providers.forEach((provider) => {
            if (provider.specific_rule?.id !== undefined) {
                temp++
            }
        });
        setProvidersAssignated(temp);
        getReceptionSpecificRules(props.tenantId, 0, 0, { search: "" } as SpecificReceptionRulesQueryParams).then((response) => {
            setNames(response.items.map((rule) => rule.name));
            setIds(response.items.map((rule) => rule.id));
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(() => {
        setStatus("loading");
        switch (props.action) {
            case "assign_specific_rule":
                loadReceptionRule();
                break;
            default:
                setStatus(translate("providers.invalid_action") as string);
                break;
        }
        // eslint-disable-next-line
    }, [props.providers, props.tenantId, props.action]);

    const onAddSpecificRule = () => {
        if (requestSpecificRule.specific_rule_id === undefined || requestSpecificRule.specific_rule_id === "" || requestSpecificRule.specific_rule_id === "---") {
            setWarning(translate("providers.assign_specific_rule.warning") as string);
            return;
        }
        setSubmitting(true);
        assignSpecialRule(props.tenantId, requestSpecificRule).then((response) => {
            props.onSuccess(response.updated);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const hasChangedSpecificRule = (name: string, value: string) => {
        let temp = requestSpecificRule;
        temp.specific_rule_id = value;
        setRequestSpecificRule(temp);
    };

    switch (props.action) {
        case "assign_specific_rule":
            return (
                <div>
                    <DialogPopupVariant
                        title={translate("providers.assign_specific_rule.title") as string}
                        labelInput={translate("providers.assign_specific_rule.new") as string}
                        listEmpty={translate("providers.assign_specific_rule.list_empty") as string}
                        onClose={props.onClose}
                        submitting={submitting}
                        onClick={onAddSpecificRule}
                        messageProviderAssignated={providersAssignated > 0 ? translate("providers.assign_specific_rule.alert", { "n": providersAssignated }) as string : undefined}
                        ids={ids}
                        names={names}
                        hasChanged={hasChangedSpecificRule}
                        status={status}
                    />
                    <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                    <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
                </div>)
        default:
            return (
                <div>

                </div>)
    }
}

interface DialogPopupVariantProps {
    title: string;
    labelInput: string;
    listEmpty: string;
    onClose(): any;
    submitting: boolean;
    onClick(): any;
    messageProviderAssignated?: string;
    ids: string[];
    names: string[];
    hasChanged(name: string, value: string): any;
    status: string;
}

export function DialogPopupVariant(props: DialogPopupVariantProps) {

    return (
        <DialogPopup open
            title={props.title}
            onClose={props.onClose}
            disable={props.submitting}
            disableBackdropClick={props.submitting}
            disableEscapeKeyDown={props.submitting}
            button={(
                <Button onClick={props.onClick} variant="contained" color="primary" size="medium" disabled={props.submitting}>
                    {translate("buttons.save")}
                </Button>
            )}>

            {props.status === "loading" && (
                <Progress />
            )}
            {props.status === "loaded" && props.ids.length > 0 && (
                <Grid container spacing={1}>
                    {props.messageProviderAssignated &&
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center" alignContent="center">
                                <Grid item xs>
                                    {props.messageProviderAssignated}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" alignContent="center">
                            <Grid item xs>
                                <ValidatedInput
                                    type="text" id="newSpecificRule" name="newSpecificRule"
                                    label={props.labelInput}
                                    value={""}
                                    required={false} disabled={props.submitting}
                                    margin="dense"
                                    options={props.ids}
                                    optionLabels={props.names}
                                    onValueChanged={props.hasChanged} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {props.status === "loaded" && props.ids.length === 0 && (
                <Typography variant="body2">
                    {props.listEmpty}
                </Typography>
            )}
            {props.status !== "loading" && props.status !== "loaded" && (
                <Typography variant="body2" component="h5" color="error" align="center">
                    {props.status}
                </Typography>
            )}
        </DialogPopup>
    );

}