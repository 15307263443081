import { callAPI, HTTPMethod } from "./API";
import { Webhook, Webhooks, WebhooksQueryParams, WebhookRequest, WebhookMetadata } from "../model/Webhooks";

const basePath = (tenantId: string) => `/tenants/${tenantId}/webhooks`;

export async function listWebhooks(tenantId: string, page: number, offset: number, params: WebhooksQueryParams): Promise<Webhooks> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createWebhook(tenantId: string, request: WebhookRequest): Promise<Webhook> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getWebhook(tenantId: string, id: string): Promise<Webhook> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateWebhook(tenantId: string, id: string, request: WebhookRequest): Promise<Webhook> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteWebhook(tenantId: string, id: string): Promise<void> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function getWebhookMetadata(tenantId: string): Promise<WebhookMetadata> {
    return await callAPI(`${basePath(tenantId)}/metadata`, {
        method: HTTPMethod.GET
    });
}