import React, { useState, useContext, useEffect } from "react";
import { Button, IconButton, Typography, Grid, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import { TenantFieldCatalog, TenantSegmentsRequest, TenantField } from "../model/TenantFieldCatalog";
import { Expiration } from "../model/Expiration";
import ExpedientFieldForm from "./ExpedientFieldForm";
import { GTZeroValidator } from "../components/Validators";
import { updateCatalogFields } from "../api/TenantFieldAPI";
import { AppContext } from "../context/AppContext";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DeleteIcon from "@material-ui/icons/Delete";

interface ExpedientSettingsFieldsProps {
    expedient?: TenantFieldCatalog;
    segments?: any;
    view: "templates" | "fields";
    onAdd?(): any;
    onGetPriority?(fieldId: string): any;
    onUpdatePriority?(value: number, fieldId: string): any;
    onDelete?(id: string): any;
}

export default function ExpedientSettingsFields(props: ExpedientSettingsFieldsProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);
    const [currentField, setCurrentField] = useState<TenantField>();
    const [showFieldForm, setShowFieldForm] = useState(false);
    const [currentIndex, setCurrentIndex] = useState<number>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [data, setData] = useState<TenantSegmentsRequest<TenantField>>({} as TenantSegmentsRequest<TenantField>);
    const fromTemplate = props.view === "templates";
    const gtZeroValidator = new GTZeroValidator();

    useEffect(() => {
        if (props.view === "fields") {
            setData({
                segments: props.expedient ? props.expedient.fields : []
            } as TenantSegmentsRequest<TenantField>);
        } else if (props.view === "templates" && props.segments) {
            let temp = [] as any;
            Object.keys(props.segments).forEach(key => {
                temp.push(props.segments[key]);
            });
            setData({ segments: temp } as TenantSegmentsRequest<TenantField>)
        }
    }, [props]);

    const editField = (field: TenantField, index: number) => () => {
        if (props.view === "templates" && props.onDelete) {
            props.onDelete(field.id);
        } else {
            setCurrentField(field);
            setCurrentIndex(index);
            setShowFieldForm(true);
        }

    };

    const onDelete = (fieldId: string) => () => {
        if (props.onDelete) {
            props.onDelete(fieldId);
        }
    };

    const addField = () => {
        setCurrentField(undefined);
        setCurrentIndex(undefined);
        setShowFieldForm(true);
    };

    const cancelField = () => {
        setCurrentField(undefined);
        setCurrentIndex(undefined);
        setShowFieldForm(false);
    };

    const onAddedField = (field: TenantField, index?: number) => {
        setSubmitting(true);
        updateCatalogFields(context.session!.tenant!.id, field)
            .then((expedient) => {
                setData({ ...data, segments: expedient.fields || [] });
                setSubmitting(false);
                cancelField();
                setSuccess(translate("expedients.fields.success") as string);
            })
            .catch((error) => {
                setError(error.message);
                setSubmitting(false);
            });
    };

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    const getExpiration = (expiration?: Expiration) => {
        if (!expiration) return "---";
        return translate(`expedients.fields.units.${expiration.unit}${expiration.quantity === 1 ? "" : "S"}`, { "quantity": expiration.quantity }) as string;
    };

    const hasChanged = (id: string, value: string, inputRef: InputRef) => {
        if (props.onUpdatePriority) {
            props.onUpdatePriority(Number(value), id);
        }
    };

    const getFieldPriority = (fieldId: string) => {
        if (props.onGetPriority && fieldId) {
            return props.onGetPriority(fieldId);
        }
        return "";
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={2}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle1">{translate("expedients.fields.title")}</Typography>
                        </Grid>
                        {
                            context.isGranted("TenantFieldsUpdate") &&
                            context.isGranted("CatalogsRead")
                            && (
                                <Grid item>
                                    <Button aria-label="add" variant="text" color="secondary" size="small" startIcon={<AddIcon />} onClick={props.view === "fields" ? addField : props.onAdd}>
                                        {translate("buttons.add_field")}
                                    </Button>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={data.segments ? data.segments : []}
                    loading={false}
                    empty={translate("expedients.empty") as string}
                    columns={[
                        {
                            title: translate("expedients.fields.priority") as string,
                            converter: (field) => (
                                <ValidatedInput
                                    type="number"
                                    id={field.id}
                                    name={field.id}
                                    value={getFieldPriority(field.id)}
                                    isValueNumber={true}
                                    validators={[gtZeroValidator]}
                                    label={""}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged}
                                />
                            ),
                            xs: fromTemplate ? 2 : false,
                            sm: fromTemplate ? 2 : false,
                            md: fromTemplate ? 2 : false,
                            lg: fromTemplate ? 1 : false,
                        },
                        {
                            title: translate("expedients.fields.name") as string,
                            converter: (field) => (
                                <div>
                                    <Ellipsis text={field.name} lenght={0} uppercased={false} />
                                    <br />
                                    <Ellipsis text={translate(field.required ? "expedients.required" : "expedients.optional") as string} lenght={0} uppercased={false} secondary />
                                </div>
                            ),
                            xs: 4,
                            sm: 4,
                            md: 4,
                            lg: 3,
                        },
                        {
                            title: translate("expedients.fields.type") as string,
                            converter: (field) => (
                                <div>
                                    <Ellipsis text={translate(`expedients.fields.type.${field.type}`) as string} lenght={0} uppercased={false} />
                                    {field.type === "FILE" && field.extensions && (<br />)}
                                    {field.type === "FILE" && field.extensions && (
                                        <Ellipsis text={field.extensions.split(",").map((entry) => entry.trim()).join(", ")} lenght={0} uppercased={false} secondary />
                                    )}
                                </div>
                            ),
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("expedients.fields.aux_file.upload") as string,
                            converter: (field) => field.aux_file_url && field.aux_file_url.length > 0 ? "Si" : "No",
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("expedients.fields.private") as string,
                            converter: (field) => field.privado ? "Si" : "No",
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("expedients.fields.description") as string,
                            converter: (field) => field.description || "---",
                            xs: false,
                            sm: false,
                            md: false,
                            lg: 3,
                        },
                        {
                            title: translate("expedients.fields.expiration") as string,
                            converter: (field) => getExpiration(field.expiration),
                            xs: false,
                            sm: false,
                            md: false,
                            lg: true,
                        },
                        {
                            title: "",
                            converter: (field: TenantField, index: number) => (
                                props.view === "templates" ? <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onDelete(field.id)}
                                >
                                    <DeleteIcon fontSize="small" color="inherit" />
                                </IconButton> :

                                    context.isGrantedAny(["UserGroupRead", "CatalogsRead"]) ? <IconButton
                                        aria-label="options"
                                        color="default"
                                        size="small"
                                        onClick={editField(field, index)}
                                    >
                                        <EditIcon />
                                    </IconButton> : <></>
                            ),
                            justify: "flex-end",
                            xs: true
                        },
                    ]}
                />
                {showFieldForm && (
                    <ExpedientFieldForm
                        field={currentField}
                        index={currentIndex}
                        onCompleted={onAddedField}
                        onClose={cancelField}
                        disabled={submitting}
                        onError={error => setError(error)}
                    />
                )}
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            </Grid>
        </Grid>
    );
}
