import React, {useState, useContext, useEffect} from "react";
import InvalidIcon from '@material-ui/icons/CancelTwoTone';
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';
import translate from "../../i18n/Translator";
import DialogPopup from "../../components/DialogPopup";
import { List, ListItem, ListItemText, Typography, Button } from "@material-ui/core";
import Gridable from "../../components/Gridable";
import Ellipsis from "../../components/Ellipsis";
import { Cfdi, CfdiValidation } from "../../model/Cfdi";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";

interface ProviderVerificationOneCfdiResultProps {
    data: CfdiValidation[];
    cfdi : Cfdi;
    hideDetailsButton?: boolean;
    onClose(): any;
}

export default function ProviderVerificationTemplateResult(props: ProviderVerificationOneCfdiResultProps) {
    const context = useContext(AppContext);
    return (
        <DialogPopup title={translate("cfdis.popup.send_validation.title_single")} open 
        onClose={props.onClose}   button={props.hideDetailsButton ? undefined : (
            <Button component={Link} to={`/cfdis/${props.cfdi.id}/details`} variant="contained" color="primary" disabled={false}>
                {translate("buttons.see_detail_cfdi")}
            </Button>
        )} disableBackdropClick={false} disableEscapeKeyDown={false}>
            
            <List dense>
                <ListItem >
                    <ListItemText primary={translate("cfdis.popup.send_validation.result_invalid_single", {"tenant": context.session!.tenant!.name})} />
                </ListItem>
                
                <Gridable
                items={props.data.filter(data => !data.valid)}
                loading={false}
                error={undefined}
                empty={translate("cfdis.empty") as string}
                columns={[
                    {
                        title: "",
                        converter: (validation) =>  (validation.valid
                             ?
                            <div>
                                <ValidIcon style={{ "color": "#82CA9D" }}/>
                            </div> :
                            <div>
                                <InvalidIcon style={{ "color": "#C33149" }}/>
                            </div>
                        ),
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("cfdis.columns.type") as string,
                        converter: (validation) => (
                                <div>
                                    <Ellipsis text={translate(`cfdis.popup.validation.types.${validation.type}`) as string} lenght={30} secondary />
                                </div>
                        ),
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("cfdis.columns.description") as string,
                        converter: (validation) => <GetDescription cfdi={props.cfdi} validation={validation} />,
                        xs: 7,
                        sm: 7,
                        md: 7,
                        lg: 7,
                        xl: 7
                    }
                ]}
            />

            </List>
        </DialogPopup>
    );
}

interface GetDescriptionProps {
    cfdi: Cfdi;
    validation: CfdiValidation;
}
function GetDescription (props : GetDescriptionProps) {
    const [description, setDescription] = useState<string | JSX.Element | JSX.Element[]>();
    var count = 0;
    const load = () => {
        let temp = "";
        let temp2 = "";
        
        if(!props.validation.valid && props.validation.code === "CFDI_SERVICE_PRODUCT_INCORRECT" && props.cfdi.metadata.product_keys){
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"products": props.cfdi.metadata.product_keys}) as string);
        }

        if(!props.validation.valid && props.validation.code === "EXTRA_INFORMATION_REQUIRED" && props.cfdi.metadata.required_fields){
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"field_required": props.cfdi.metadata.required_fields}) as string);
        }
        
        if(!props.validation.valid && props.validation.code === "CFDI_TYPE_INCORRECT"){
            temp = props.cfdi.cfdi?.tipo_comprobante || "---";
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"tipo": temp}) as string);
        }

        if(!props.validation.valid && props.validation.code === "CFDI_PAYMENT_METHOD_INCORRECT"){
            temp = props.cfdi.cfdi?.metodo_pago || "---";
            temp2 = props.cfdi.cfdi?.forma_pago || "---";
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"metodo_pago": temp, "forma_pago": temp2}) as string);
        }

        if(!props.validation.valid && props.validation.code === "CFDI_PAYMENT_FORM_INCORRECT"){
            temp = props.cfdi.cfdi?.forma_pago || "---";
            temp2 = props.cfdi.cfdi?.metodo_pago || "---";
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"forma_pago": temp, "metodo_pago": temp2}) as string);
        }

        if(!props.validation.valid && (props.validation.code === "CFDI_USE_INCORRECT" || props.validation.code === "CFDI_USE_IN_SPECIFIC_RULE_INCORRECT")){
            temp = props.cfdi.cfdi?.receptor.uso_cfdi || "---";
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"uso_cfdi": temp}) as string);
        }

        if(!props.validation.valid && (props.validation.code === "PDP_CFDI_UNIQUE" || props.validation.code === "UNIQUE_CFDI_DUPLICATED")){
            temp = props.cfdi.cfdi?.receptor.uso_cfdi || "---";
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, {"uso_cfdi": temp}) as string);
        }
        
        if (!props.validation.valid && props.validation.group === "EXPENSES_ACCOUNT") {
            temp = props.cfdi.cfdi?.receptor.uso_cfdi || "---";
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`) as string);
        }

        if (!props.validation.valid && props.validation.type === "RECEPTION_DATE") {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }

        if (!props.validation.valid && props.validation.type === "CFDI_ADVANCE") {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }

        if (!props.validation.valid && props.validation.code === "CONSIGNMENT_NOTE_ALERT") {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }

        if (!props.validation.valid && props.validation.code === "CONSIGNMENT_NOTE_MISSING") {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }
        if (!props.validation.valid && props.validation.code === "CONSIGNMENT_NOTE_INVALID_RULE") {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }
        if (!props.validation.valid && props.validation.code === "CONSIGNMENT_NOTE_MANDATORY") {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }
        if (!props.validation.valid && ("COMPLEMENT_VERSION_BEFORE_VALIDITY" === props.validation.code || "COMPLEMENT_VERSION_AFTER_VALIDITY" === props.validation.code)) {
            setDescription(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }
        if (!props.validation.valid && props.validation.type === "PLUGINS") {
            setDescription(ReactHtmlParser(props.validation.message) || translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string);
        }
        if (!props.validation.valid && props.validation.type === "SUPPORTED_CURRENCIES") {
            setDescription(ReactHtmlParser(translate(`cfdis.popup.validation.codes.${props.validation.code}`, props.validation.params as any) as string));
        }
        count = count +1;

    }

    useEffect(load, [props]);

    return <Typography key={props.validation.code} variant="body2">{description}</Typography>
}