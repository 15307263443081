export const PAYMENT_METHODS = [
    "PUE",
    "PPD"
];
export const PAYMENT_FORM_PUE = [
    "F01",
    "F02",
    "F03",
    "F04",
    "F05",
    "F06",
    "F08",
    "F12",
    "F13",
    "F14",
    "F15",
    "F17",
    "F23",
    "F24",
    "F25",
    "F26",
    "F27",
    "F28",
    "F29",
    "F30",
    "F31",
];

export const PAYMENT_FORMS_PPD = [
    "F99"
];

export const CFDI_TYPES = [
    "I",
    "E",
    "T",
    "N",
    "P"
];
export const CFDI_USES =[
    "G01",
    "G02",
    "G03",
    "I01",
    "I02",
    "I03",
    "I04",
    "I05",
    "I06",
    "I07",
    "I08",
    "D01",
    "D02",
    "D03",
    "D04",
    "D05",
    "D06",
    "D07",
    "D08",
    "D09",
    "D10",
    "P01",
    "S01",
    "CP01",
    "CN01"
];
