import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, Switch, FormControlLabel } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { ErrorSnackbar } from "../components/Snackbars";
import { User, UserRequest, MetadataResponse } from "../model/User";
import { Entity as Provider } from "../model/Provider";
import { isRoleOrParent } from "../model/Role";
import { createUser as createTenantUser, getUser as getTenantUser, updateUser as updateTenantUser, getMetadata as getTenantMetadata } from "../api/TenantUserAPI";
import { createUser as createProviderUser, getUser as getProviderUser, updateUser as updateProviderUser, getMetadata as getProviderMetadata } from "../api/ProviderUserAPI";
import { createUser as createCustomerUser, getUser as getCustomerUser, updateUser as updateCustomerUser, getMetadata as getCustomerMetadata } from "../api/CustomerUserAPI";
import { createUser, getUser, updateUser, getMetadata } from "../api/UserAPI";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import { PhoneValidator, phoneFormat } from "../components/Validators";
import Alert from "../components/Alert";

export default function UserForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const isEdit = !!match.params.userId;
    const [blockEmail, setBlockEmail] = useState<boolean>(false);
    const margin = "dense";
    const phoneValidator = new PhoneValidator();
    const [status, setStatus] = useState<string>("loading");
    const [request, setRequest] = useState<UserRequest>({
        "first_name": "",
        "last_name": "",
        "email": "",
        "role_id": "",
        "phone": "",
        "create_user": true,
        "language": "",
    } as UserRequest);
    const [validations, setValidations] = useState({
        "first_name": {
            valid: false
        } as InputRef,
        "last_name": {
            valid: false
        } as InputRef,
        "email": {
            valid: false
        } as InputRef,
        "phone": {
            valid: false
        } as InputRef,
    } as any);
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [roleNames, setRoleNames] = useState<string[]>([]);
    const [subtitle, setSubtitle] = useState<string>();
    const [error, setError] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [provider, setProvider] = useState<Provider>();
    const [limitReached, setLimitReached] = useState(false);
    const [format, setFormat] = useState("");
    const [state, setState] = useState<boolean>(false);
    const [languageIds, setLanguageIds] = useState<string[]>([]);
    const [languageNames, setLanguageNames] = useState<string[]>([]);

    const retrievePromise = (): Promise<User> => {
        let tenantId = (match.params.tenantId ?? context.session?.tenant?.id );
        if (tenantId) {
            if (match.params.providerId) {
                return getProviderUser(tenantId, match.params.providerId, match.params.userId, true);
            }
            if (match.params.customerId) {
                return getCustomerUser(tenantId, match.params.customerId, match.params.userId, true);
            }
            if (context.session?.customer?.id) {
                return getCustomerUser(tenantId, context.session.customer.id, match.params.userId, true);
            }
            return getTenantUser(tenantId, match.params.userId, true);
        }
        return getUser(match.params.userId, true);
    }

    const metadataPromise = (): Promise<MetadataResponse> => {
        let tenantId = (context.session?.tenant?.id ?? match.params.tenantId);
        if (tenantId) {
            if (match.params.providerId) {
                return getProviderMetadata(tenantId, match.params.providerId);
            }
            if (match.params.customerId) {
                return getCustomerMetadata(tenantId, match.params.customerId);
            }
            if (context.session?.customer?.id) {
                return getCustomerMetadata(tenantId, context.session.customer.id);
            }

            return getTenantMetadata(tenantId);
        }
        return getMetadata();
    }

    const submitPromise = (): Promise<User> => {
        let tenantId = (context.session?.tenant?.id ?? match.params.tenantId);
        if (isEdit) {
            if (tenantId) {
                if (match.params.providerId) {
                    return updateProviderUser(tenantId, match.params.providerId, match.params.userId, request);
                }
                if (match.params.customerId) {
                    return updateCustomerUser(tenantId, match.params.customerId, match.params.userId, request);
                }
                if (context.session?.customer?.id) {
                    return updateCustomerUser(tenantId, context.session.customer.id, match.params.userId, request);
                }
                return updateTenantUser(tenantId, match.params.userId, request);
            }
            return updateUser(match.params.userId, request);
        }

        if (tenantId) {
            if (match.params.providerId) {
                return createProviderUser(tenantId, match.params.providerId, request);
            }
            if (match.params.customerId) {
                return createCustomerUser(tenantId, match.params.customerId, request);
            }
            if (context.session?.customer?.id) {
                return createCustomerUser(tenantId, context.session.customer.id, request);
            }
            return createTenantUser(tenantId, request);
        }
        return createUser(request)
    };

    useEffect(() => {
        if (isEdit && context.session?.user.id === match.params.userId) {
            setRedirect("/profile");
            return;
        }

        setStatus("loading");
        if (isEdit) {
            retrievePromise().then((user) => {
                setMetadata(user.metadata);
                setRequest({
                    "first_name": user.first_name,
                    "last_name": user.last_name,
                    "email": user.email,
                    "role_id": user.role?.id,
                    "phone": user.phone,
                    "language": user.language
                } as UserRequest);
                setBlockEmail(user.password_active);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            metadataPromise().then((metadata) => {
                setMetadata(metadata);

                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        }
        // eslint-disable-next-line
    }, [isEdit, match.params.userId]);

    const setMetadata = (metadata?: MetadataResponse) => {
        if (!metadata) return;

        let filtered = metadata.roles.filter((role) => (match.params.providerId && role.provider) || !role.provider);
        if(!context.isGrantedAny(["RequisitionsRead"])){
            filtered = filtered.filter((role) => role.id !== "project_manager" && role.id !== "project_participant");
        }
        if(!context.isGrantedAny(["BudgetsRead"])){
            filtered = filtered.filter((role) => role.id !== "budget_participant" && role.id !== "budget_admin");
        }
        setRoleIds(filtered.map((role) => role.id));
        setRoleNames(filtered.map((role) => {
            if (role.description) {
                return role.description;
            } else {
                return translate(`users.roles.${role.id}`) as string;
            }
        }));
        setLanguageIds(metadata.languages.map((language) => language.id));
        setLanguageNames(metadata.languages.map((language) => language.description));
        setLimitReached(metadata.limit_reached && !isEdit);
        setProvider(metadata.provider);
        setSubtitle(metadata.tenant?.name || metadata.provider?.name || metadata.customer?.name);
    }

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedPhone = (name: string, value: string, inputRef: InputRef) => {
        hasChanged(name, value, inputRef);
        setFormat(phoneFormat(value));
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) return;

        if (request.language === '---') {
            setError(translate("users.language.error") as string);
            return;
        }


        let isProvider = isRoleOrParent(context.session!.role, "provider");
        let isAdmin = isRoleOrParent(context.session!.role, "admin");

        setSubmitting(true);
        submitPromise().then((response) => {

            if (response.provider_name_of_user_duplied) {
                setSubmitting(false);
                setError(translate("users.warn_duplicated", { "name": response.provider_name_of_user_duplied }) as string);
            } else if (match.params.tenantId && match.params.providerId) {
                setRedirect(`/tenants-admin/${match.params.tenantId}/providers-users`);
            }else if (match.params.tenantId && isAdmin) {
                setRedirect(`/tenants-admin/${match.params.tenantId}/users`);
            } else if (match.params.tenantId) {
                setRedirect(`/tenants/users`);
            } else if (isProvider) {
                setRedirect(`/providers/users`);
            } else if (match.params.providerId) {
                setRedirect(`/providers/${match.params.providerId}/users`);
            } else if (match.params.customerId) {
                setRedirect(`/customers/${match.params.customerId}/users`);
            } else {
                setRedirect("/users");
            }
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequest({ ...request, create_user: event.target.checked });
        setState(event.target.checked);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface
                        title={translate(isEdit ? "users.edit_user" : "users.new_user")}
                        subtitle={subtitle}
                        icon={isEdit ? <PersonIcon /> : <PersonAddIcon />}
                        className="FormSurface">
                        {limitReached && (
                            <Alert variant="info" margin={1} plain
                                title={translate("usage.users.title")}
                                subheader={translate("usage.users.subtitle")} />
                        )}
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="first_name" name="first_name"
                                        value={request.first_name} label={translate("users.first_name") as string}
                                        required disabled={submitting || limitReached}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="last_name" name="last_name"
                                        value={request.last_name} label={translate("users.last_name") as string}
                                        required disabled={submitting || limitReached}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="email" id="email" name="email"
                                        value={request.email} label={translate("users.email") as string}
                                        required disabled={submitting || limitReached || blockEmail}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="phone" name="phone"
                                        value={request.phone} label={translate("providers.phone") as string}
                                        disabled={submitting || limitReached}
                                        validator={phoneValidator}
                                        margin={margin}
                                        format={format} mask=" "
                                        onValueChanged={hasChangedPhone} />
                                </Grid>
                                {!isEdit && provider && provider.type === "FOREIGN" &&
                                    <Grid container justify="flex-start">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={state}
                                                    onChange={handleChangeSwitch}
                                                    color="primary"
                                                    name="checkedB"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            }
                                            label={translate(`users.portal_access`)}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="role_id" name="role_id" label={translate("users.roles.title") as string}
                                        value={request.role_id} onValueChanged={hasChanged}
                                        required disabled={submitting || limitReached}
                                        margin={margin}
                                        options={roleIds} optionLabels={roleNames} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="language" name="language" label={translate("users.language.title") as string}
                                        value={request.language} onValueChanged={hasChanged}
                                        required disabled={(submitting || limitReached)}
                                        margin={margin}
                                        options={languageIds} optionLabels={languageNames} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting || limitReached}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}
