import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, Divider, Checkbox, ListItemText, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel } from "@material-ui/core";
import ProvidersIcon from "@material-ui/icons/Business";

import translate from "../../i18n/Translator";
import ValidatedInput, { InputRef } from "../../components/ValidatedInput";
import Surface from "../../components/Surface";
import { ErrorSnackbar, WarningSnackbar, SuccessSnackbar } from "../../components/Snackbars";
import { RouterParams } from "../../router/RouterParams";
import Progress from "../../components/Progress";
import { AppContext } from "../../context/AppContext";
import { GTEZeroValidator } from "../../components/Validators";
import SimpleSwitch from "../../components/SimpleSwitch";
import Alert from "../../components/Alert";
import { TenantPOConfigurationRequest, TenantConfiguration } from "../../model/TenantConfiguration";
import { get, updatePurchaseOrderConfiguration } from "../../api/TenantConfigurationApi";
import CustomBackdrop from "../../components/CustomBackdrop";

export default function POTenantConfigurationForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [success, setSuccess] = useState("");
    const tenantId = match.params.tenantId;
    const [tenantName, setTenantName] = useState("");
    const [hasB1, setHasB1] = useState<boolean>(false);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

    const [optionSelected, setOptionSelected] = useState<string>("ONE");

    const [request, setRequest] = useState<TenantPOConfigurationRequest>({
        allow_link_invoices: false,
        must_link_all_invoice_concepts: false,
        validate_invoice_amounts: false,
        amount_tolerance: 0.0,
        restrict_upload_cfdi_from_purchase_order: false,
        show_warehouse_deliveries_in_purchase_orders: false,
        restrict_to_one_purchase_order_per_document: false,
        validate_invoice_amounts_type: "ONE",
        allow_cancel_close_purchase_orders_manually: false,
    } as TenantPOConfigurationRequest);

    useEffect(() => {
        get(tenantId).then(response => {
            setRequestFromResponse(response);
            setTenantName(response.tenant_name || "");
            setHasB1(response.has_b1);
        }).catch(error => 
            setError(error.message)
        ).finally(() => 
            setStatus("loaded")
        );
    }, [tenantId]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true);
        let temp = request;
        if(!temp.allow_link_invoices){
            temp.validate_invoice_amounts = false;
            temp.must_link_all_invoice_concepts = false;
            temp.amount_tolerance = 0;
        }
        if(!temp.validate_invoice_amounts){;
            temp.amount_tolerance = 0;
            temp.validate_invoice_amounts_type = "NONE";
        }
        setOpenBackdrop(true);
        updatePurchaseOrderConfiguration(tenantId, temp).then(response => {
            setSuccess(translate("tenant_admin_po.success_save_config") as string);
            setRequestFromResponse(response);
        }).catch(error => 
            setError(error.message)
        ).finally(() => {
            setOpenBackdrop(false);
            setSubmitting(false);
        });
    };

    const setRequestFromResponse = (response: TenantConfiguration) => {
        setRequest({
            allow_link_invoices: hasB1 ? false : response.purchase_order_configuration.allow_link_invoices,
            must_link_all_invoice_concepts: hasB1 ? false : response.purchase_order_configuration.must_link_all_invoice_concepts,
            validate_invoice_amounts: hasB1 ? false : response.purchase_order_configuration.validate_invoice_amounts,
            amount_tolerance: hasB1 ? 0 : response.purchase_order_configuration.amount_tolerance,
            restrict_upload_cfdi_from_purchase_order: response.purchase_order_configuration.restrict_upload_cfdi_from_purchase_order,
            show_warehouse_deliveries_in_purchase_orders: response.purchase_order_configuration.show_warehouse_deliveries_in_purchase_orders,
            restrict_to_one_purchase_order_per_document: response.purchase_order_configuration.restrict_to_one_purchase_order_per_document,
            validate_invoice_amounts_type: response.purchase_order_configuration.validate_invoice_amounts_type, allow_cancel_close_purchase_orders_manually: response.purchase_order_configuration.allow_cancel_close_purchase_orders_manually
        } as TenantPOConfigurationRequest);
        setOptionSelected(response.purchase_order_configuration.validate_invoice_amounts_type === "NONE" ||
            response.purchase_order_configuration.validate_invoice_amounts_type === undefined ?
            "ONE" : response.purchase_order_configuration.validate_invoice_amounts_type);
    };

    const switchAllowAsocciateConcepts = () => {
        setRequest({ ...request, allow_link_invoices: !request.allow_link_invoices });
    };

    const setMustLinkAllInvoiceConcepts = () => {
        setRequest({ ...request, must_link_all_invoice_concepts: !request.must_link_all_invoice_concepts });
    };

    const setValidateInvoiceAmounts = () => {
        setRequest({ ...request, validate_invoice_amounts: !request.validate_invoice_amounts });
    };

    const switchShowOptionAddInvoice = () => {
        setRequest({ ...request, restrict_upload_cfdi_from_purchase_order: !request.restrict_upload_cfdi_from_purchase_order });
    };

    const switchShowOptionWarehouseDeliveries = () => {
        setRequest({ ...request, show_warehouse_deliveries_in_purchase_orders: !request.show_warehouse_deliveries_in_purchase_orders });
    };

    const switchOnlyOnePurchaseOrder = () => {
        setRequest({ ...request, restrict_to_one_purchase_order_per_document: !request.restrict_to_one_purchase_order_per_document });
    };

    const swithAllowCancelClosePurchaseOrder = () => {
        setRequest({ ...request, allow_cancel_close_purchase_orders_manually: !request.allow_cancel_close_purchase_orders_manually });
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
        setSuccess("");
    };

    const hasChanged = (name: string, value: string) => {
        setRequest({ ...request, [name]: value });
    };

    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptionSelected((event.target as HTMLInputElement).value);
        if((event.target as HTMLInputElement).value === "ONE"){
            setRequest({ ...request, validate_invoice_amounts_type: "ONE"});
        } else if((event.target as HTMLInputElement).value == "MULTIPLE"){
            setRequest({ ...request, validate_invoice_amounts_type: "MULTIPLE"});
        } else {
            setRequest({ ...request, validate_invoice_amounts_type: "NONE" });
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface title={translate("tenant_admin_po.title")}
        subtitle={tenantName} icon={<ProvidersIcon />} className="FormSurface" backButton>
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <ListItemText 
                                        primary={<SimpleSwitch
                                            value="allow_link_invoices"
                                            label={translate("tenant_admin_po.allow_link_invoices") as string}
                                            checked={request.allow_link_invoices}
                                            onChanged={switchAllowAsocciateConcepts}
                                            placement="end"
                                            disabled={submitting || hasB1} />} 
                                        secondary={<Box pl={6}>{translate("tenant_admin_po.allow_link_invoices_desc")}</Box>}/>
                                </Grid>
                                <Box pl={4}>
                                    <Grid item xs={12}>
                                        <ListItemText 
                                            primary={<Grid item xs="auto" >
                                                    <Checkbox
                                                        id="must_link_all_invoice_concepts"
                                                        name="must_link_all_invoice_concepts"
                                                        checked={request.must_link_all_invoice_concepts}
                                                        onChange={() => setMustLinkAllInvoiceConcepts()}
                                                        disabled={submitting || !request.allow_link_invoices}
                                                        className="GridableCheck"
                                                    />
                                                    {translate("tenant_admin_po.must_link_all_invoice_concepts")}
                                                </Grid>}
                                            secondary={<Box pl={6}>
                                                    {translate("tenant_admin_po.must_link_all_invoice_concepts_desc")}
                                                </Box>}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ListItemText 
                                            primary={<Grid item xs="auto" >
                                                <Checkbox
                                                    id="validate_invoice_amounts"
                                                    name="validate_invoice_amounts"
                                                    checked={request.validate_invoice_amounts}
                                                    onChange={() => setValidateInvoiceAmounts()}
                                                    disabled={submitting || !request.allow_link_invoices}
                                                    className="GridableCheck"
                                                />
                                                {translate("tenant_admin_po.validate_invoice_amounts")}
                                            </Grid>}
                                            secondary={<Box pl={6}>
                                                {translate("tenant_admin_po.validate_invoice_amounts_desc")}
                                            </Box>}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box pl={6}>
                                            <ValidatedInput type="number" id="amount_tolerance" name="amount_tolerance"
                                                value={request.amount_tolerance+""} label={translate("tenant_admin_po.amount_tolerance") as string}
                                                required={request.validate_invoice_amounts} disabled={submitting || !request.allow_link_invoices || !request.validate_invoice_amounts}
                                                onValueChanged={hasChanged} margin="dense" validators={[new GTEZeroValidator()]}/>
                                        </Box>
                                    </Grid>
                                    <Grid item ><Box pl={6}>
                                    <FormControl component="fieldset" >
                                        <RadioGroup aria-label="options" name="options" value={optionSelected} onChange={onChangeRadio} >
                                            <ListItemText 
                                                primary={<Grid item xs="auto" >
                                                    <FormControlLabel value="ONE" control={<Radio />} 
                                                        label={translate("tenant_admin_po.validate_invoice_amounts_one") as string} 
                                                        disabled={submitting || !request.allow_link_invoices || !request.validate_invoice_amounts}/>
                                                </Grid>}
                                                secondary={<Box pl={6}>
                                                    {translate("tenant_admin_po.validate_invoice_amounts_one_secondary")}
                                                </Box>}/>
                                            <ListItemText 
                                                primary={<Grid item xs="auto" >
                                                    <FormControlLabel value="MULTIPLE" control={<Radio />} 
                                                        label={translate("tenant_admin_po.validate_invoice_amounts_multiple") as string} 
                                                        disabled={submitting || !request.allow_link_invoices || !request.validate_invoice_amounts}/>
                                                </Grid>}
                                                secondary={<Box pl={6}>
                                                    {translate("tenant_admin_po.validate_invoice_amounts_multiple_secondary")}
                                                </Box>}/>
                                        </RadioGroup>
                                    </FormControl></Box>
                                    </Grid>
                                </Box>
                                <br/>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleSwitch
                                    value="restrict_upload_cfdi_from_purchase_order"
                                    label={translate("tenant_admin_po.restrict_upload_cfdi_from_purchase_order") as string}
                                    checked={!request.restrict_upload_cfdi_from_purchase_order}
                                    onChanged={switchShowOptionAddInvoice}
                                    placement="end"
                                    disabled={submitting} />
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleSwitch
                                    value="show_warehouse_deliveries_in_purchase_orders"
                                    label={translate("tenant_admin_po.show_warehouse_deliveries_in_purchase_orders") as string}
                                    checked={request.show_warehouse_deliveries_in_purchase_orders}
                                    onChanged={switchShowOptionWarehouseDeliveries}
                                    placement="end"
                                    disabled={submitting} />
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleSwitch
                                    value="restrict_to_one_purchase_order_per_document"
                                    label={translate("tenant_admin_po.restrict_to_one_purchase_order_per_document") as string}
                                    checked={request.restrict_to_one_purchase_order_per_document}
                                    onChanged={switchOnlyOnePurchaseOrder}
                                    placement="end"
                                    disabled={submitting} />
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleSwitch
                                    value="allow_cancel_close_purchase_orders_manually"
                                    label={translate("tenant_admin_po.allow_cancel_close_purchase_orders_manually") as string}
                                    checked={request.allow_cancel_close_purchase_orders_manually}
                                    onChanged={swithAllowCancelClosePurchaseOrder}
                                    placement="end"
                                    disabled={submitting} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box px={2} py={2}>
                                    <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={1}>
                                        <Grid item>
                                            <Button onClick={history.goBack} variant="outlined" color="default">
                                                {translate("buttons.close")}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button type="submit" variant="contained" color="primary">
                                                {translate("buttons.save")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                        <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
                </Grid>
            </form>
        </Surface>
    );
}
