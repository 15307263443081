import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import translate from "../i18n/Translator";
import { DocumentLine } from "../model/Document";
import { getPurchaseOrder } from "../api/PurchaseOrderAPI";
import { getPurchaseOrder as getProviderPurchaseOrder } from "../api/ProviderPurchaseOrderAPI";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import PurchaseOrderLinesList from "./PurchaseOrderLinesList";
import { Workflow } from "../model/Workflow";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { ErrorSnackbar } from "../components/Snackbars";
import { formatCurrency } from "../components/Numbers";
import { Cfdi } from "../model/Cfdi";

interface PilgrimsDocumentTableItemsProps {
    limitTags?: number;
    title: string;
    elementos: MultiselectValue[];
    required?: boolean;
    onChanged(lines: DocumentLine[], valid?: boolean): void;
    disabled?: boolean;
    tenantId: string;
    providerId: string;
    cfdiId: string;
    payableDocument?: Cfdi;
    value?: string;
    lines?: DocumentLine[];
    readonly: boolean;
    workflow?: Workflow;
}

export default function PilgrimsDocumentTableItems(props: PilgrimsDocumentTableItemsProps) {
    const [status, setStatus] = useState<string>("loaded");
    const [lines, setLines] = useState<DocumentLine[]>([]);
    const [selected, setSelected] = useState<DocumentLine[]>([]);
    const [allChecked, setAllChecked] = useState(false);
    const [multiselect, setMultiselect] = useState(false);
    const [showPedimento, setShowPedimento] = useState(true);
    const [requirePedimento, setRequirePedimento] = useState(true);
    const [validations, setValidations] = useState({} as any);
    const [searchValidations, setSearchValidations] = useState({} as any);
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
    
    const [purchaseOrderId, setPurchaseOrderId] = useState<string>();
    const [error, setError] = useState<string>();
    const [inlineError, setInlineError] = useState<string>();
    const [searching, setSearching] = useState(false);
    

    useEffect(() => {
    }, [props.elementos]);

    useEffect(() => {
        const selectedLines = lines.filter(el => el.selected);
        const lineValidations = Object.values(validations);
        let valid = lineValidations.length === 0 || lineValidations.map(fields => fields as any).map(fields => !!isValid(fields, true)).reduce((p, c) => p && c, true);

        setInlineError(undefined);
        if (valid && selectedLines.length > 0 && props.payableDocument) {
            const totalLines = selectedLines.map(line => line.line_total).reduce((a, b) => a + b, 0);
            if (props.workflow?.external_id === "01") {
                const subtotal = props.payableDocument.cfdi?.subtotal || 0;
                const descuento = props.payableDocument.cfdi?.descuento || 0;
                const value = subtotal - descuento;
                const currency = (props.payableDocument.cfdi?.moneda || "MXN").toUpperCase();
                const tolerance = currency === "USD" ? 0.25 : 1.00;
                const diff = Math.abs(totalLines - value);
                if (diff > tolerance) {
                    setInlineError(translate("purchase_orders.wrong_subtotal", {
                        totalLines: formatCurrency(totalLines, currency),
                        value: formatCurrency(value, currency),
                        currency: currency,
                    }) as string);
                    valid = false;
                }
            }

            if (valid) {
                const unique = {} as any;
                for (const selectedLine of selectedLines) {
                    const key = `${selectedLine.external_id}__${selectedLine.line_num}`;
                    if (unique[key]) {
                        setInlineError(translate("purchase_orders.repeated_positions", { 0: selectedLine.external_id, 1: selectedLine.line_num }) as string);
                        valid = false;
                        break;
                    }
                    unique[key] = true;
                }
            }
        }

        props.onChanged(selectedLines, valid);
        // eslint-disable-next-line
    }, [lines, props.payableDocument, props.workflow]);

    useEffect(() => {
        setMultiselect(props.workflow?.external_id === "03" || props.workflow?.external_id === "08");
        setShowPedimento(props.workflow?.external_id === "08");
        setRequirePedimento(props.workflow?.external_id === "08");
    }, [props.workflow]);

    const onChangedSearch = (name: string, value: string, inputRef: InputRef) => {
        setPurchaseOrderId(value);
        searchValidations[name] = inputRef;
        setSearchValidations(searchValidations);
    };

    const onChangedText = (index: number) => (name: string, value: string, inputRef: InputRef) => {
        if(index && value){
            const line = { ...lines[index], [name]: (value && value !== "---") ? value : undefined };
            setLines(lines.map((el, elIndex) => {
                if (elIndex === index) {
                    return line;
                }
                return el;
            }));
    
            if (!validations[index]) {
                validations[index] = {} as any;
            }
    
            validations[index][name] = inputRef;
            setValidations(validations);
        }
    };

    const addPurchaseOrder = (value: string) => {
        setSearching(true);

        const promises = value.split(",").map(el => el.trim()).map(purchaseOrderId => {
            if (multiselect) {
                return getPurchaseOrder(props.tenantId, purchaseOrderId, 0, 0);
            }
            return getProviderPurchaseOrder(props.tenantId, props.providerId, purchaseOrderId, 0, 0);
        });

        setStatus("loading");
        Promise.all(promises).then(values => {
            const moreLines = values.flatMap(purchaseOrder => {
                return purchaseOrder.lines.filter(line => line.status === undefined || line.status !== "C").map((line, index) => {
                    const savedLine = props.lines?.find(el => el.line_num === line.line_num && el.external_id === purchaseOrder.external_id);
                    return {
                        line_num: line.line_num,
                        item_code: line.item_code,
                        description: line.description,
                        quantity: line.quantity,
                        unit_of_measure: line.unit_of_measure,
                        price: line.price,
                        line_total: line.line_total,
                        tax_code: line.tax_code,
                        tax_rate: line.tax_rate,
                        line_tax: line.line_tax,
                        withholding_tax: line.withholding_tax,
                        external_id: purchaseOrder.external_id,
                        quantity_in_cfdi: 1,
                        pedimento: savedLine?.pedimento,
                        factura_pedimento: savedLine?.factura_pedimento,
                        selected: !!savedLine,
                    } as DocumentLine;
                });
            });

            if (multiselect) {
                setLines([...lines, ...moreLines]);
            } else {
                setLines(moreLines);
            }

            const selectedLines = moreLines.filter(el => el.selected);
            setAllChecked(selectedLines.length === moreLines.length);
            setSelected(selectedLines);

            const missing = values.filter(purchaseOrder => purchaseOrders.findIndex(el => el.id === purchaseOrder.id) < 0);
            if (missing.length > 0) {
                setPurchaseOrders([...purchaseOrders, ...missing]);
            }
        }).catch(error => {
            if (multiselect) {
                setError(error.message);
            } else {
                setStatus(error.message);
            }
        }).finally(() => {
            setSearching(false);
            setStatus("loaded");
        });
    };

    const onChanged = (selected: string[]) => {
        if (selected.length === 0) {
            setLines([]);
            setSelected([]);
            setAllChecked(false);
        } else if(selected.length === 2) {
            addPurchaseOrder(selected[1]);
        } else {
            addPurchaseOrder(selected[0]);
        }
    };

    const checkSingle = (selected: (number | string)[]) => {
        if (!selected || selected.length < 0) {
            return;
        }

        const newLines = lines.map((el, elIndex) => {
            if (selected.includes(elIndex)) {
                el.selected = true;
            } else {
                el.selected = false;
            }
            return el;
        });
        setLines(newLines);

        const selectedLines = newLines.filter(el => el.selected);
        setAllChecked(selectedLines.length === newLines.length);
        setSelected(selectedLines);

    };

    const onSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(searchValidations) || !purchaseOrderId) {
            return;
        }

        if (purchaseOrders.findIndex(el => el.external_id === purchaseOrderId) >= 0) {
            setError(translate("purchase_orders.repeated") as string);
            return;
        }

        addPurchaseOrder(purchaseOrderId);
        setPurchaseOrderId(undefined);
        (Object.values(searchValidations) as InputRef[]).filter(el => typeof el.blurer === "function").forEach(el => el.blurer(false));
    };

    const onRemovePurchaseOrder = (purchaseOrder: PurchaseOrder) => () => {
        const index = purchaseOrders.findIndex(el => el.id === purchaseOrder.id);
        if (index < 0) {
            return;
        }

        setPurchaseOrders(purchaseOrders.filter((el, elIndex) => elIndex !== index));
        setSelected(selected.filter(el => el.external_id !== purchaseOrder.external_id));
        setLines(lines.filter(el => el.external_id !== purchaseOrder.external_id));
    };

    const getValues = () => {
        let values = {} as any;
        (lines || []).forEach(line => {
            values[line.external_id] = {
                title: line.external_id,
                value: line.external_id
            } as MultiselectValue;
        })

        return Object.values(values) as MultiselectValue[];
    };

    return (
        <Grid container>
            {!multiselect && (
                <Grid item xs={12} md={6}>
                    <Box pb={1} mt={-1}>
                        <MultiselectDropList
                            title={props.title}
                            elementos={props.elementos}
                            value={getValues()}
                            required={props.required}
                            disabled={props.readonly}
                            onChanged={onChanged} />
                    </Box>
                </Grid>
            )}
            {multiselect && (
                <Grid item xs={12}>
                    <Box pb={2}>
                        <Grid container spacing={6} justify="space-between">
                            <Grid item xs={12} md={6} lg={4}>
                                <form autoComplete="off" noValidate onSubmit={onSearch} >
                                    <Grid container justify="flex-start" spacing={1}>
                                        <Grid item xs={12}>
                                            <ValidatedInput type="text" id="purchaseOrderId" name="purchaseOrderId"
                                                value={purchaseOrderId} 
                                                label={translate("purchase_orders.external_id") as string}
                                                margin="dense" required disabled={props.readonly || searching}
                                                onValueChanged={onChangedSearch} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Button type="submit" variant="contained" color="primary" disabled={props.readonly || searching}>
                                                {translate("purchase_orders.add")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" component="h6">
                                    {translate("purchase_orders.included")}
                                </Typography>
                                {purchaseOrders.length === 0 && (
                                    <Typography variant="body2" component="h6" color="textSecondary">
                                        {translate("purchase_orders.empty")}
                                    </Typography>
                                )}
                                {purchaseOrders.length > 0 && (
                                    <List dense>
                                        {purchaseOrders.map((purchaseOrder) => (
                                            <ListItem key={purchaseOrder.id}>
                                                <ListItemText>{purchaseOrder.external_id}</ListItemText>
                                                <ListItemSecondaryAction>
                                                    <IconButton color="secondary" onClick={onRemovePurchaseOrder(purchaseOrder)} disabled={props.readonly}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
            {inlineError && (
                <Grid item xs={12}>
                    <Typography variant="body2" color="error" paragraph>
                        {inlineError}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Box mx={-3}>
                <PurchaseOrderLinesList 
                    tenantId={props.tenantId}
                    lines={lines || []}
                    providerId={props.providerId}
                    showPedimento={showPedimento}
                    requirePedimento={requirePedimento}
                    multiselect={multiselect}
                    readOnly={props.readonly}
                    onSelectLines={checkSingle}
                    onChangedText={onChangedText}
                    />
                </Box>
            </Grid>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </Grid>
    );
}
