import React from "react";
import { Grid, CardContent, Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import translate from "../i18n/Translator";
import { formatDate } from "../components/DateFormat";
import { Cfdi } from "../model/Cfdi";

interface CfdiIssuesSendingInfoProps {
    cfdi: Cfdi;
}

export default function CfdiIssuesSendingInfo(props: CfdiIssuesSendingInfoProps) {

    return (
        <Card variant="outlined">
            <CardHeader title={(
                <strong>{translate("cfdis_se.sending_info")}</strong>
            )} disableTypography />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <List dense>
                            <ListItem dense>
                                <ListItemText primary={props.cfdi.metadata.customer ? translate(`customers.modes.${props.cfdi.metadata.customer.cfdi_sending_mode}`) as string : "---"} secondary={translate("customers.delivery_type") as string} />
                            </ListItem>
                            {props.cfdi.metadata.customer?.cfdi_recipients && props.cfdi.metadata.customer.cfdi_recipients.length > 0 && (
                                <ListItem dense>
                                    <ListItemText primary={props.cfdi.metadata.customer.cfdi_recipients || "---"} secondary={translate("customers.cfdi_recipients") as string} />
                                </ListItem>
                            )}
                            <ListItem dense>
                                <ListItemText primary={props.cfdi.metadata.customer?.cfdi_notes || "---"} secondary={translate("customers.sending_notes") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List dense>
                            <ListItem dense>
                                <ListItemText primary={formatDate(props.cfdi.metadata.send_at, "DD/MM/YYYY")} secondary={translate("cfdis_se.sending_date") as string} />
                            </ListItem>
                            <ListItem dense>
                                <ListItemText primary={props.cfdi.metadata.sender?.name || "---"} secondary={translate("cfdis_se.sender") as string} />
                            </ListItem>
                            <ListItem dense>
                                <ListItemText primary={props.cfdi.metadata.comments || "---"} secondary={translate("cfdis_se.comments") as string} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}