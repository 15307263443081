import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from "@material-ui/core";

import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext"
import { getFieldUrl, getFieldAuxiliarFileUrl } from "../api/ProviderExpedientAPI";
import { TenantField } from "../model/TenantFieldCatalog";
import { ProviderExpedientField } from "../model/ProviderExpedient";
import UploaderInput from "../components/UploaderInput";
import { ErrorSnackbar } from "../components/Snackbars";
import { getIcon } from "./ExpedientSegmentStatus";
import Alert from "../components/Alert";
import DeleteIcon from '@material-ui/icons/Delete';
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { Entity } from "../model/Provider";
import { getCatalog } from "../api/CatalogAPI";
import { Catalog } from "../model/Catalog";

interface ExpedientProviderFieldFormProps {
    title: string;
    subtitle?: string;
    value?: string;
    valueExternalId?: string;
    comments?: string;
    field: ProviderExpedientField;
    provider?: Entity;
    onCompleted(value: string): any;
    onDeleted(): any;
    onClose(): any;
    disabled: boolean;
}

export default function ExpedientProviderFieldForm(props: ExpedientProviderFieldFormProps) {
    const context = useContext(AppContext);
    const [error, setError] = useState("");
    const [request, setRequest] = useState<string>("");
    const [validations, setValidations] = useState({} as any);
    useEffect(() => {
        setRequest(props.value || "");
    }, [props.value]);

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest(date ? String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0') + "-" + date.getFullYear() : "");
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }
        props.onCompleted(request);
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    return (
        <Dialog open maxWidth="md"
            disableBackdropClick={props.disabled}
            disableEscapeKeyDown={props.disabled}
            onClose={props.onClose}
            aria-labelledby="provider-dialog-title"
            aria-describedby="provider-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <DialogTitle id="provider-dialog-title" disableTypography style={{ "lineHeight": "1px" }}>
                    <Typography variant="subtitle1">{props.title}</Typography>
                    <br />
                    <Typography variant="caption">{props.subtitle || translate("providers.contact") as string}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {props.comments && (
                        <Alert variant="warning" title={props.comments} />
                    )}
                    <Grid item xs={12}>
                        <FieldItemForm
                            tenantId={context.session!.tenant!.id}
                            providerId={props.provider ? props.provider.id : context.session!.provider!.id}
                            field={props.field.configuration}
                            value={props.value || ""}
                            valueExternalId={props.valueExternalId}
                            status={props.field.status}
                            onValueChanged={hasChanged}
                            onChangedDate={onChangedDate}
                            onDeleted={props.onDeleted}
                            disabled={props.disabled}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={props.disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={props.disabled}>
                        {translate("buttons.update")}
                    </Button>
                </DialogActions>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </Dialog>
    );
}

interface FieldItemFormProps {
    tenantId: string;
    providerId: string;
    field: TenantField;
    value: string;
    valueExternalId?: string;
    status?: string;
    onValueChanged?(name: string, value: string, inputRef: InputRef): void;
    onChangedDate(name: string, raw?: string, date?: Date): any;
    onDeleted(): any;
    disabled?: boolean;
}

function FieldItemForm(props: FieldItemFormProps) {
    const [value, setValue] = useState<string>(props.value);
    const [linkAux, setLinkAux] = useState<string>();
    const [catalog, setCatalog] = useState<Catalog>();

    useEffect(() => {
        if (props.value && props.field.type === "FILE" && !props.value.startsWith("http")) {
            getFieldUrl(props.tenantId, props.providerId, props.field.id).then((url) => {
                setValue(url);
            }).catch((error) => {
            });
        } else if (props.field.type === "DATE") {
            let temp = props.value.split("-");
            let tempValue = temp[2] + "-" + temp[0] + "-" + (Number(temp[1]) + 1);
            setValue(tempValue);
        } else {
            setValue(props.value);
        }

        if (props.field.aux_file_url && props.field.aux_file_url !== "" && !props.field.aux_file_url.startsWith("http")) {
            getFieldAuxiliarFileUrl(props.tenantId, props.providerId, props.field.id).then((url) => {
                setLinkAux(url);
            }).catch((error) => {
            });
        }

        if (!!props.field.catalog_id) {
            getCatalog(props.tenantId, props.field.catalog_id).then(response => {
                setCatalog(response);
            }).catch(error => {

            });

        }
        // eslint-disable-next-line
    }, [props.field.id, props.field.type, props.providerId, props.tenantId, props.value]);


    const getfieldDetail = () => {
        return (
            <Grid item xs="auto">
                <Box px={2}>
                    <small>{props.field.description} </small>
                    <a href={linkAux} target="_blank" rel="noopener noreferrer">
                        <small>{props.field.aux_file_name}</small>
                    </a>
                </Box>
            </Grid>
        );
    }

    if (props.field.type === "FILE") {
        return (
            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={11}>
                    <UploaderInput
                        acceptExtension={props.field.extensions as string}
                        id={props.field.id}
                        name={props.field.id}
                        path={`${props.tenantId}/expedientes/${props.providerId}/${props.field.id}`}
                        value={value}
                        description={props.field.description}
                        nameAuxFile={props.field.aux_file_name}
                        linkAuxFile={linkAux}
                        label={props.field.name as string}
                        required={props.field.required}
                        disabled={(typeof props.disabled !== "undefined" && props.disabled)}
                        readOnly={(typeof props.disabled !== "undefined" && props.disabled)}
                        margin="dense"
                        onValueChanged={props.onValueChanged}
                        startAdornment={getIcon(props.status || "PENDING", props.field.required, props.value)} />
                </Grid>
                <Grid item xs={1}>
                    {props.status ?
                        <IconButton onClick={props.onDeleted} aria-label="delete" color="default" size="small">
                            <DeleteIcon />
                        </IconButton> : undefined
                    }

                </Grid>
            </Grid>

        );
    }

    if (props.field.type === "CATALOG") {
        return (
            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={11}>
                    <ValidatedInput
                        type="text"
                        id={props.field.id}
                        name={props.field.id}
                        value={catalog?.values.find(e => e.external_id === props.valueExternalId)?.external_id }
                        label={props.field.name as string}
                        required={props.field.required}
                        disabled={(typeof props.disabled !== "undefined" && props.disabled)}
                        margin="dense"
                        onValueChanged={props.onValueChanged}
                        startAdornment={getIcon(props.status || "PENDING", props.field.required, props.value)}
                        emptyOption={translate("catalogs.values.empty") as string}
                        autocompleteOptions={catalog?.values ?? []}
                        autocompleteValue={catalog?.values.find(e => e.external_id === props.valueExternalId) ?? undefined}
                        autocompleteKey={catalog?.values.find(e => e.external_id === props.valueExternalId)?.id ?? undefined}
                        getId={(el) => el?.external_id}
                        getLabel={(el) => el?.name}
                    />
                </Grid>
                {getfieldDetail()}
            </Grid>
        );
    }

    return (
        <Grid container justify="flex-start" alignItems="flex-start" spacing={0}>
            <Grid item xs={12}>
                {props.field.type === "DATE" ?
                    <DatePicker
                        label={props.field.name as string}
                        name={props.field.id}
                        initial={value ? moment(value).format("DD/MM/YYYY") : ""}
                        onChange={props.onChangedDate}
                        defaultIfMissing={false}
                        clearable={false} autoOK
                        disablePast={false}
                        disableFuture={false}
                        disabled={(typeof props.disabled !== "undefined" && props.disabled)} />
                    :
                    <ValidatedInput
                        type={props.field.type.toLowerCase()}
                        id={props.field.id}
                        name={props.field.id}
                        value={props.value}
                        label={props.field.name as string}
                        required={props.field.required}
                        disabled={(typeof props.disabled !== "undefined" && props.disabled)}
                        margin="dense"
                        onValueChanged={props.onValueChanged}
                        maxRows={10}
                        rows={props.field.type === "TEXTAREA" ? 3 : undefined}
                        startAdornment={getIcon(props.status || "PENDING", props.field.required, props.value)}
                    />}
            </Grid>
            {getfieldDetail()}
        </Grid>
    );

}
