import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Divider, Box } from "@material-ui/core";
import SatTaxIcon from "@material-ui/icons/AccountBalance";

import { listSatContributions, createSatContributionToValidatePayment } from "../api/SatContributionAPI";
import { listSatContributions as listProviderSatContributions,
    createSatContributionToValidatePayment as createProviderSatContributionToValidatePayment } from "../api/ProviderSatContributionAPI";
import translate from "../i18n/Translator";
import { SatContributionsQueryParams, ToValidatePaymentRequest } from "../model/SatContribution";
import PdfViewer from "../components/PdfViewer";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { fillSatContributionList } from "./SatContributionUtil";
import ConfirmationPopup from "../components/ConfirmationPopup";
import SimpleSwitch from "../components/SimpleSwitch";
import { SatContribution } from "../model/SatContribution";

interface ToValidatePaymentFormParams {
    period?: string;
    urlPdf: string;
    complementary: boolean;
    satContribution?: SatContribution;
}

export default function ToValidatePaymentForm(props : ToValidatePaymentFormParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const mode = context.session?.provider?.declaration_frequency || "MONTHLY";
    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<ToValidatePaymentRequest>({
        type: props.satContribution ? props.satContribution.iva ? "ISR" : "IVA" : "",
        iva_tax: 0.00,
        iva_total: 0.00,
        isr_tax: 0.00,
        isr_total: 0.00,
        year: 0,
        month: 0,
        need_capture_line: false,
        capture_line: "",
        capture_line_total: 0,
        key: props.satContribution !== undefined ? props.satContribution.month+"-"+props.satContribution.year : props.period,
        payment: props.urlPdf,
        bimester: 0
    } as ToValidatePaymentRequest);
    const [validations, setValidations] = useState({} as any);
    const [types] = useState<string[]>(["IVA", "ISR", "ALL"]);
    const [typeLabels] = useState<string[]>(["IVA", "ISR", translate("sat_contributions.to_validate_payment.all") as string]);
    const [dateKeys, setDateKeys] = useState<string[]>([]);
    const [dateLabels, setDateLabels] = useState<string[]>([]);
    const [openValidateDialog, setOpenValidateDialog] = useState<boolean>();

    const onSwitch = () => {
        if((request.isr_total && request.isr_total > 0 )|| (request.iva_total && request.iva_total > 0)){
            setRequest({ ...request, need_capture_line: true });
            return;
        } else if((request.isr_tax && request.isr_tax > 0 
            && request.isr_total && request.isr_total <= 0) ||
            (request.iva_tax && request.iva_tax > 0 
                && request.iva_total && request.iva_total <= 0)){
            setRequest({ ...request, need_capture_line: !request.need_capture_line });
        }
    };

    const promiseList = () => {
        const params = {} as SatContributionsQueryParams;
        if (providerId) {
            return listProviderSatContributions(tenantId, providerId, 10, 0, params);
        }
        return listSatContributions(tenantId, 10, 0, params);
    };

    const load = () => {

        setStatus("loading");
        promiseList().then(response => {
            const lists = fillSatContributionList(response, mode);
            setDateKeys(lists.keys);
            setDateLabels(lists.labels);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, mode]);

    const hasChangedType = (name: string, value: string, inputRef: InputRef) => {
        setRequest({
            type: value,
            iva_tax: 0.00,
            iva_total: 0.00,
            isr_tax: 0.00,
            isr_total: 0.00,
            need_capture_line: false,
            capture_line: "",
            capture_line_total: 0,
            key: props.period,
            payment: props.urlPdf
        } as ToValidatePaymentRequest);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        if((name === "iva_total" && parseInt(value) === 0 && parseInt(request.isr_total+"")=== 0) ||
            (name === "isr_total" && parseInt(value) === 0 && parseInt(request.iva_total+"") === 0) ){
            setRequest({ ...request, [name]: value, need_capture_line: false });
        } else if((name === "iva_total" || name === "isr_total") && parseInt(value) > 0 ){
            setRequest({ ...request, [name]: value, need_capture_line: true });
        } else {
            setRequest({ ...request, [name]: value });
        }
        validations[name] = inputRef;
        setValidations(validations);
    };

    const promiseCreate = (request : ToValidatePaymentRequest) => {
        if (providerId) {
            return createProviderSatContributionToValidatePayment(tenantId, providerId, request);
        }
        return createSatContributionToValidatePayment(tenantId, request);
    };

    const onCancelValidate = () => {
        setOpenValidateDialog(false);
    };

    const onAcceptValidate = () => {
        setOpenValidateDialog(false);
        setSubmitting(true);

        promiseCreate({ ...request }).then((satContribution) => {
            if(satContribution.type && satContribution.error_code){
                setOpenValidateDialog(true);
                return;
            }
            history.push("/contributions/sat");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });

    };
    
    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const onCreate = () => {
        if(request.type === undefined || request.type === "" || request.type === "---"){
            setWarning(translate("sat_contributions.to_validate_payment.warning.type") as string);
            return;
        }
        if(request.key === undefined || request.key === "" || request.key === "---"){
            setWarning(translate("sat_contributions.to_validate_payment.warning.key") as string);
            return;
        }
        if(request.need_capture_line && parseFloat(request.capture_line_total+"") <= 0 ){
            setWarning(translate("sat_contributions.to_validate_payment.warning.capture_line_total") as string);
            return;
        }
        if(request.need_capture_line && request.capture_line === "" ){
            setWarning(translate("sat_contributions.to_validate_payment.warning.capture_line") as string);
            return;
        }
        if((request.type === "ISR" && parseFloat(request.isr_tax+"") <= 0) ||
        (request.type === "IVA" && parseFloat(request.iva_tax+"") <= 0 ) || 
        (request.type === "ALL" && (parseFloat(request.iva_tax+"") <= 0 || parseFloat(request.isr_tax+"") <= 0))){
            setWarning(translate("sat_contributions.to_validate_payment.warning.tax") as string);
            return;
        }
        
        let temp = request;
        temp.complementary = props.complementary;
        if(!props.complementary){
            const fixed = request.key === "---" ? "" : request.key;
            const segments = fixed.split("-");
            if (segments.length === 2) {
                const year = +segments[0];
                const month = +segments[1];
                if (mode === "MONTHLY") {
                    temp.year = year;
                    temp.month = month;
                } else {
                    temp.year = year;
                    temp.bimester= month ;
                }
            }
        } else {
            temp.contribution_id = props.satContribution?.id || "";
        }
        setSubmitting(true);
        promiseCreate(temp).then((satContribution) => {
            history.push("/contributions/sat");
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Surface title={translate("sat_contributions.new")} icon={<SatTaxIcon />} className="FormSurface">
                            <Grid container justify="space-between">
                                <Grid item xs={6}>
                                    <Grid container>
                                        {props.satContribution === undefined && 
                                            <Grid item xs={12}>
                                                <ValidatedInput type="text" id="key" name="key"
                                                    value={request.key}
                                                    label={translate("sat_contributions.period") as string}
                                                    options={dateKeys} optionLabels={dateLabels}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <ValidatedInput type="text" id="type" name="type"
                                                value={request.type}
                                                label={translate("sat_contributions.to_validate_payment.type") as string}
                                                options={types} optionLabels={typeLabels}
                                                required={true} disabled={submitting || props.satContribution !== undefined}
                                                margin={margin}
                                                onValueChanged={hasChangedType} />
                                        </Grid>
                                        <Grid item xs={12}> <br/> <Divider /> <br/></Grid>
                                        {(request.type === "IVA" || request.type === "ALL")  && <>
                                            <Grid item xs={12}>
                                                <Typography><strong>{translate("sat_contributions.to_validate_payment.iva")}</strong></Typography>
                                                <br/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="number" id="iva_tax" name="iva_tax"
                                                    value={request.iva_tax+""}
                                                    label={translate("sat_contributions.to_validate_payment.tax") as string}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="number" id="iva_total" name="iva_total"
                                                    value={request.iva_total+""}
                                                    label={translate("sat_contributions.to_validate_payment.total") as string}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                            <Grid item xs={12}> <br/> <Divider /> <br/></Grid>
                                        </>}
                                        {(request.type === "ISR" || request.type === "ALL")  && <>
                                            <Grid item xs={12}>
                                                <Typography><strong>{translate("sat_contributions.to_validate_payment.isr")}</strong></Typography>
                                                <br/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="number" id="isr_tax" name="isr_tax"
                                                    value={request.isr_tax+""}
                                                    label={translate("sat_contributions.to_validate_payment.tax") as string}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="number" id="isr_total" name="isr_total"
                                                    value={request.isr_total+""}
                                                    label={translate("sat_contributions.to_validate_payment.total") as string}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                            <Grid item xs={12}> <br/> <Divider /> <br/></Grid>
                                        </>}
                                        {request.type !== "" && request.type !== "---" && <>
                                            <Grid item xs={12}>
                                                <SimpleSwitch
                                                    value="all_providers"
                                                    label={translate("sat_contributions.to_validate_payment.need_capture_line") as string}
                                                    checked={request.need_capture_line}
                                                    onChanged={onSwitch}
                                                    placement="start"
                                                />
                                            </Grid>
                                            {request.need_capture_line && <>
                                                <Grid item xs={12}>
                                                    <ValidatedInput type="text" id="capture_line" name="capture_line"
                                                        value={request.capture_line}
                                                        label={translate("sat_contributions.to_validate_payment.capture_line") as string}
                                                        required={true} disabled={submitting}
                                                        margin={margin}
                                                        onValueChanged={hasChanged} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ValidatedInput type="number" id="capture_line_total" name="capture_line_total"
                                                        value={request.capture_line_total+""}
                                                        label={translate("sat_contributions.to_validate_payment.capture_line_total") as string}
                                                        required={true} disabled={submitting}
                                                        margin={margin}
                                                        onValueChanged={hasChanged} />
                                                </Grid>
                                            </>}
                                        </>}
                                        
                                    </Grid>

                                </Grid>
                                <Grid item xs={6}>
                                    <PdfViewer pdf={props.urlPdf} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="button" variant="contained" color="primary" size="large" disabled={submitting} onClick={onCreate}>
                                                    {translate("buttons.create")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
            {request && openValidateDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.title") as string}
                    message={translate("confirm_contribution.voucher.message") as string}
                    secondary={translate("confirm_contribution.voucher.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidate}
                    doAction={onAcceptValidate}
                />
            }
        </Grid>
    );
}