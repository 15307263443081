import React, { useState, useContext, useEffect } from "react";
import queryString from "query-string";
import { Grid, Button, Link, Switch, FormGroup, FormControlLabel, FormLabel, FormControl, Box } from "@material-ui/core";
import { Link as RouterLink, Redirect } from "react-router-dom";

import { signup, getSignup } from "../api/AuthAPI";
import { SignUpRequest, SignUpMetadataResponse } from "../model/SignUp";
import { TenantIntentRequest } from "../model/TenantIntent";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { RfcValidator } from "../components/Validators";
import { AppContext } from "../context/AppContext";
import { ErrorSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import { SignInRequest } from "../model/SignIn";
import ExternSurface from "../components/ExternSurface";
import { PhoneValidator, phoneFormat } from "../components/Validators";
import Progress from "../components/Progress";
import GoHome from "../components/GoHome";
import { PlanPickerModal } from "../billing/PlanPicker";
import { Plan } from "../model/Plan";

export default function SignUpForm() {
    const context = useContext(AppContext);
    const rfcValidator = new RfcValidator();
    const qs = queryString.parse(window.location.search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<SignUpMetadataResponse>();
    const [planIds, setPlanIds] = useState<string[]>([]);
    const [planLabels, setPlanLabels] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState("");
    const phoneValidator = new PhoneValidator();
    const [account, setAccount] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string | undefined>();
    const [showPlans, setShowPlans] = useState(false);
    const [format, setFormat] = useState("");
    const [planIndex, setPlanIndex] = useState<number>();

    const [request, setRequest] = useState<TenantIntentRequest>({
        tenant_name: "",
        rfc: "",
        plan_id: "",
        signup: {
            "first_name": "",
            "last_name": "",
            "email": "",
            "phone": ""
        } as SignUpRequest,
        signin: {
            "email": "",
            "password": ""
        } as SignInRequest,
    } as TenantIntentRequest);
    const [validations, setValidations] = useState({
        "tenant_name": {
            valid: false
        } as InputRef,
        "rfc": {
            valid: false
        } as InputRef,
        "plan_id": {
            valid: false
        } as InputRef
    } as any);
    const [signinValidations, setSigninValidations] = useState({
        "email": {
            valid: false
        } as InputRef,
        "password": {
            valid: false
        } as InputRef,
    } as any);
    const [signupValidations, setSignupValidations] = useState({
        "first_name": {
            valid: false
        } as InputRef,
        "last_name": {
            valid: false
        } as InputRef,
        "email": {
            valid: false
        } as InputRef,
        "phone": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        setStatus("loading");
        getSignup().then((response) => {
            setData(response);
            setPlanIds(response.plans.map((plan) => plan.id));
            setPlanLabels(response.plans.map((plan) => plan.name));

            if (typeof qs["plan_id"] === "string") {
                let matches = response.plans.map((plan) => plan.id).filter((planId) => planId === qs["plan_id"]);
                if (matches.length > 0) {
                    setRequest({ ...request, plan_id: matches[0] });
                }
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message)
        });
        // eslint-disable-next-line
    }, []);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };
    
    const hasChangedPlan = (name: string, value: string, inputRef: InputRef) => {
        hasChanged(name, value, inputRef);
        let index = planIds.findIndex(planId => planId === value);
        if (index >= 0) {
            setPlanIndex(index);
        } else {
            setPlanIndex(undefined);
        }
    };

    const hasChangedSignin = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, signin: ({ ...request.signin, [name]: value } as SignInRequest) });
        signinValidations[name] = inputRef;
        setSigninValidations(signinValidations);
    };

    const hasChangedSignup = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, signup: ({ ...request.signup, [name]: value } as SignUpRequest) });
        signupValidations[name] = inputRef;
        setSignupValidations(signupValidations);
    };

    const hasChangedPhone = (name: string, value: string, inputRef: InputRef) => {
        hasChangedSignup(name, value, inputRef);
        setFormat(phoneFormat(value));
    };

    const isValidSpecific = (items: any) => {
        var valid = true;
        for (let field in items) {
            let ref = items[field];
            if (!ref.valid) {
                valid = false;
                if (typeof ref.blurer === "function") {
                    ref.blurer(true);
                }
            }
        }
        return valid;
    }

    const isValidTenant = () => isValidSpecific(validations);

    const isValidUser = () => isValidSpecific(account ? signinValidations : signupValidations);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let validTenant = isValidTenant();
        let validUser = isValidUser();
        if (!validTenant || !validUser) {
            return;
        }

        var data = {
            "tenant_name": request.tenant_name,
            "rfc": request.rfc,
            "plan_id": request.plan_id,
        } as TenantIntentRequest;

        if (account) {
            data.signin = request.signin;
        } else {
            data.signup = request.signup;
        }

        setSubmitting(true);
        signup(data).then((response) => {
            if (response.signin) {
                switch (response.signin.status) {
                    case "SIGNED_IN":
                        context.onSignedIn();
                        break;
                    case "REQUIRED_2FA":
                        setRedirect(`/signin/${response.signin.signin_2fa_code!}`);
                        break;
                    default:
                        setSubmitting(false);
                        setError(translate("auth.signup.wrong_response") as string);
                        break;
                }
            } else if (response.tenant_intent) {
                setRedirect(`/signup/${response.tenant_intent.id}`);
            } else {
                setSubmitting(false);
                setError(translate("auth.signup.wrong_response") as string);
            }
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
    };

    const switchMode = () => {
        setAccount(!account);
    };

    const onSelectedPlan = (plan: Plan) => {
        setRequest({ ...request, plan_id: plan.id });
        setShowPlans(false);
    };

    const onShowPlanPicker = () => {
        setShowPlans(true);
    };

    const onCancelPlanPicker = () => {
        setShowPlans(false);
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === "loading" || !data) {
        return (<Progress />);
    }

    if (status !== "loaded") {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface title={translate("auth.signup.title") as string} description={translate("auth.signup.description") as string} showTitle>
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="tenant_name" name="tenant_name"
                            value={request.tenant_name} label={translate("auth.signup.tenant_name") as string}
                            required={true} disabled={submitting}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="rfc" name="rfc"
                            value={request.rfc} label={translate("auth.signup.rfc") as string}
                            required={true} disabled={submitting}
                            validator={rfcValidator}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="plan_id" name="plan_id"
                            value={request.plan_id} label={translate("auth.signup.plan_id") as string}
                            options={planIds} optionLabels={planLabels}
                            required={true} disabled={submitting}
                            onValueChanged={hasChangedPlan} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={3}>
                            <Button variant="text" size="small" color="primary" onClick={onShowPlanPicker}>
                                {translate("buttons.see_plans")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {account &&
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel color="primary" component="legend">{translate("auth.signup.owner")}</FormLabel>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item xs={12}>
                                        <ValidatedInput type="email" id="signin.email" name="email"
                                            value={request.signin!.email} label={translate("users.email") as string}
                                            required={account} disabled={submitting}
                                            onValueChanged={hasChangedSignin} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ValidatedInput type="password" id="signin.password" name="password"
                                            value={request.signin!.password} label={translate("users.password") as string}
                                            required={account} disabled={submitting}
                                            onValueChanged={hasChangedSignin} />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                {!account &&
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel color="primary" component="legend">{translate("auth.signup.owner")}</FormLabel>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="signup.first_name" name="first_name"
                                            value={request.signup!.first_name} label={translate("users.first_name") as string}
                                            required={!account} disabled={submitting}
                                            onValueChanged={hasChangedSignup} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="signup.last_name" name="last_name"
                                            value={request.signup!.last_name} label={translate("users.last_name") as string}
                                            required={!account} disabled={submitting}
                                            onValueChanged={hasChangedSignup} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ValidatedInput type="email" id="signup.email" name="email"
                                            value={request.signup!.email} label={translate("users.email") as string}
                                            required={!account} disabled={submitting}
                                            onValueChanged={hasChangedSignup} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="signup.phone" name="phone"
                                            value={request.signup!.phone} label={translate("providers.phone") as string}
                                            disabled={submitting}
                                            validator={phoneValidator}
                                            required={!account}
                                            format={format} mask=" "
                                            onValueChanged={hasChangedPhone} />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            <FormGroup row>
                                <FormControlLabel label={<small>{translate("auth.signup.has_account")}</small>} labelPlacement="start"
                                    control={
                                        <Switch checked={account} onChange={switchMode} value={account} color="primary" />
                                    } />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="ExternButton">
                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={submitting}>
                            {translate("buttons.signup")}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/signin">
                            <small>{translate("buttons.signin")}</small>
                        </Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/recover-password">
                            <small>{translate("buttons.recover")}</small>
                        </Link>
                    </Grid>
                </Grid>
            </form>
            {showPlans && (
                <PlanPickerModal open={showPlans} plans={data.plans} onClose={onCancelPlanPicker} onPickedPlan={onSelectedPlan} startAt={planIndex} />
            )}
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </ExternSurface>
    );

}