import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import ZipUploadsIcon from "@material-ui/icons/ImportExport";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { ImportExportZipDefinitionRequest, ZipDefinition } from "../model/TenantConfiguration";
import { updateZipInterpreter } from "../api/TenantConfigurationApi";
import { INTERPRETERS } from "../model/ZipUpload";
import CustomBackdrop from "../components/CustomBackdrop";
import Multiselect from "../components/Multiselect";

interface TenantConfigurationZipInterpreterFormProps {
    tenantId: string;
    definition?: ZipDefinition;
    export?: boolean;
    connectorIds: string[];
    connectorLabels: string[];
}

export default function TenantConfigurationZipInterpreterForm(props: TenantConfigurationZipInterpreterFormProps) {
    const interpreterLabels = INTERPRETERS.map(el => translate(`zip_uploads.interpreters.${el}`) as string);

    const [submitting, setSubmitting] = useState(false);
    const [request, setRequest] = useState<ImportExportZipDefinitionRequest>({} as ImportExportZipDefinitionRequest);
    const [validations, setValidations] = useState<any>({} as any);
    const [withConnectors, setWithConnectors] = useState(false);

    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        setRequest({
            interpreter: props.definition?.interpreter,
            connector_ids: props.definition?.connector_ids,
            export: props.export ?? false
        });
    }, [props.definition, props.export, props.tenantId]);

    const hasChangedOption = (name: string, value: string, inputRef: InputRef) => {
        let newRequest = { ...request, [name]: !value || value === "---" ? undefined : value };
        if (newRequest.interpreter !== "CONNECTORS") {
            newRequest = { ...newRequest, connector_ids: undefined };
        }

        setRequest(newRequest);
        setWithConnectors(!!props.export && newRequest.interpreter === "CONNECTORS");

        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedConnectors = (values: string[]) => {
        setRequest({ ...request, connector_ids: values });
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) return;

        setSubmitting(true);
        updateZipInterpreter(props.tenantId, request).then((response) => {
            setSuccess(translate("sap.settings.updated") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    return (
        <Surface title={translate(props.export ? "zip_downloads.title" : "zip_uploads.title")} icon={<ZipUploadsIcon />}>
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id={`interpreter-${props.export ?? false}`} name="interpreter"
                            value={request.interpreter} label={translate("zip_uploads.interpreters.title") as string}
                            options={INTERPRETERS} optionLabels={interpreterLabels}
                            disabled={submitting}
                            margin="dense"
                            onValueChanged={hasChangedOption} />
                    </Grid>
                    <Grid item xs={12}>
                        <Multiselect
                            id={`connectors-${props.export ?? false}`}
                            label={translate("connectors.title") as string}
                            value={request.connector_ids ?? []}
                            labels={props.connectorLabels}
                            values={props.connectorIds}
                            onChanged={onChangedConnectors}
                            disabled={submitting || !withConnectors} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2}>
                            <Grid container justify="flex-end" spacing={1}>
                                <Grid item xs={12} md="auto">
                                    <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                        {translate("buttons.update")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <CustomBackdrop open={submitting} />
        </Surface>
    );

}