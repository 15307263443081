import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent } from "@material-ui/core";

import { PayAllPaymentOrderRequest } from "../model/Payment";
import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";
import UploaderInput from "../components/UploaderInput";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { PaymentOrderCfdi } from "../model/PaymentOrder";
import { listBankAccounts } from "../api/TenantBankAccountAPI";
import { BankAccountsQueryFilter } from "../model/BankAccount";
import { OPERATION_TYPES } from "../catalogs/OperationTypes";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { WarningSnackbar } from "../components/Snackbars";

interface PayAllCfdisPendingProps {
    tenantId: string;
    paymentOrderId: string;
    cfdisPendingPayment: PaymentOrderCfdi[];
    currency: string;
    onCompleted(field: PayAllPaymentOrderRequest): any;
    onClose(): any;
}

export default function PayAllCfdisPending(props: PayAllCfdisPendingProps) {
    const margin = "dense";
    const [request, setRequest] = useState<PayAllPaymentOrderRequest>({
        payment_date: new Date(),
        total_amount: props.cfdisPendingPayment.reduce((sum, cfdi) => sum + cfdi.amount, 0),
        cfdis: props.cfdisPendingPayment,
        payment_order_id: props.paymentOrderId,
        currency: props.currency
    } as PayAllPaymentOrderRequest);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>();
    const [fileUrl, setFileUrl] = useState<string>();
    const [bankAccount, setBankAccount] = useState<string[]>([]);
    const [bankAccountLabels, setBankAccountLabels] = useState<string[]>([]);
    const operationTypesLabels = OPERATION_TYPES.map((type) => translate(`payments_cfdi.operation-types.${type}`) as string);
    const [warning, setWarning] = useState<string>();

    const [validations, setValidations] = useState({
        "bank_account_id": {
            valid: false
        } as InputRef,
        "operation_type": {
            valid: false
        } as InputRef,
        "reference": {
            valid: false
        } as InputRef,
    } as any);

    useEffect(() => {
        if(props.tenantId){
            listBankAccounts(props.tenantId, 0, 0, {search: "", currency: props.currency, hasLayout: true} as BankAccountsQueryFilter).then((response) => {
                setBankAccount(response.items.map((item) => item.id));
                setBankAccountLabels(response.items.map((item) => item.name));
            })
        }
    }, [props.paymentOrderId, props.cfdisPendingPayment, props.tenantId, props.currency, fileUrl, fileName]);

    const hasChangedFile = (name: string, value: string, inputRef: InputRef) => {
        setDisabled(false);
        let parts = name.split(".");
        if (parts.length === 1) {
            setRequest({ ...request!, operation_file_url: value });
            setFileUrl(value);
            setFileName(name);
        } else {
            let tmp = request as any;
            if (typeof tmp[parts[0]] !== "undefined") {
                tmp[parts[0]][parts[1]] = value;
            }
            setFileUrl(tmp);
            setRequest(tmp as PayAllPaymentOrderRequest);
        }
    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onClickFile = () => {
        setDisabled(true);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date) {
            setRequest({ ...request, payment_date: date })
        }
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        if(!request.payment_date) {
            setWarning(translate("payment_order.messages.empty_date") as string);
            return;
        }
        props.onCompleted(request);
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("payment_order.pay_all_pending") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography style={{"marginBottom":"3%"}} variant="body1">
                            {translate("payment_order.messages.confirm_execute", { 
                                    "cfdis": props.cfdisPendingPayment.length, 
                                    "total": <NumberFormat value={props.cfdisPendingPayment.reduce((sum, cfdi) => sum + (cfdi.payment_amount ? cfdi.payment_amount : 0), 0)} 
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" suffix={` ${props.currency}`} thousandSeparator=","/>
                                    }) as string}
                        </Typography>
                        <ValidatedInput type="text" id="bank_account_id" name="bank_account_id" disabled={false}
                            value={request.bank_account_id} label={translate("payments_cfdi.input.bank_account") as string}
                            required={true}
                            margin="dense"
                            onValueChanged={hasChanged}
                            options={bankAccount} optionLabels={bankAccountLabels} />
                        <DatePicker label={translate("payment_order.messages.date_label") as string}
                            name="schedule_date"
                            initial={moment().format("DD/MM/YYYY")}
                            onChange={onChangedDate}
                            required={true}
                            defaultIfMissing={false} clearable={false} autoOK disablePast={false} disableFuture={false} disabled={false} />
                        <ValidatedInput type="text" id="operation_type" name="operation_type" disabled={false}
                            value={request.operation_type} label={translate("payments_cfdi.input.operation_type") as string}
                            required={true}
                            margin="dense"
                            onValueChanged={hasChanged}
                            options={OPERATION_TYPES} optionLabels={operationTypesLabels} />
                        <ValidatedInput type="text" id="reference" name="reference" disabled={false}
                            value={request.reference} label={translate(`payments_cfdi.input.reference.${request.operation_type}`) as string}
                            required={request.operation_type === OPERATION_TYPES[2] ? false : true}
                            margin="dense"
                            onValueChanged={hasChanged} />
                        <UploaderInput
                            id="aux_file_url"
                            name="aux_file_url"
                            value={fileUrl}
                            label={translate("payment_order.messages.upload_label") as string}
                            required={false}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChangedFile}
                            onClick={onClickFile}
                            tooltip={translate("expedients.fields.aux_file.tooltip") as string}
                        />
                        <ValidatedInput type="text" id="comments" name="comments" disabled={false}
                            value={request.comments} label={translate("payments_cfdi.input.comments") as string}
                            margin="dense"
                            onValueChanged={hasChanged} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={false || disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={false || disabled}>
                        {translate("buttons.execute")}
                    </Button>
                </DialogActions>
            </form>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Dialog>
    );
}
