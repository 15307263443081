import React, { useState } from "react";
import { Button, Typography, Grid} from "@material-ui/core";
import translate from "../../i18n/Translator";

import { Cfdi } from "../../model/Cfdi";
import DialogPopup from "../../components/DialogPopup";
import ValidatedInput, { InputRef } from "../../components/ValidatedInput";
import { ErrorSnackbar } from "../../components/Snackbars";

interface UpdateStatusRefundPopupProps {
    cfdi: Cfdi;
    tenantId: string;
    isCommentRequired: boolean;
    action: "send" | "authorize" | "cancel" | "reject";
    doAction(comment: string): any;
    onClose(): any;
}

export default function UpdateStatusRefundPopup(props: UpdateStatusRefundPopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(props.isCommentRequired);
    const [request, setRequest] = useState<string>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();

    const onSubmit = () => {
        setSubmitting(true);
        if (!request && props.isCommentRequired) {
            setError(translate("refunds.empty_comment") as string);
            setSubmitting(false);
        } else {
            props.doAction(request || "");
            return;
        }
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest(value);
        if(props.isCommentRequired && (!request || request === "")){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
    };

    const getTitle = () => {
        let title = "";
        switch (props.action) {
            case "send":
                title = translate("refunds.popups.send") as string;
                break;
            case "authorize":
                title = translate("refunds.popups.authorize") as string;
                break;
            case "reject":
                title = translate("refunds.popups.reject") as string;
                break;
            case "cancel":
                title = translate("refunds.popups.cancel") as string;
                break;
            default:
                break;
        }
        return title;
    }

    const getMessage = () => {
        let title = "";
        switch (props.action) {
            case "send":
                title = translate("refunds.popups.send_message") as string;
                break;
            case "authorize":
                title = translate("refunds.popups.authorize_message") as string;
                break;
            case "reject":
                title = translate("refunds.popups.reject_message") as string;
                break;
            case "cancel":
                title = translate("refunds.popups.cancel_message", {number: props.cfdi.identifier}) as string;
                break;
            default:
                break;
        }
        return title;
    }

    const getButtonText = () => {
        let title = "";
        switch (props.action) {
            case "send":
                title = translate("buttons.send") as string;
                break;
            case "authorize":
                title = translate("buttons.authorize_payment") as string;
                break;
            case "reject":
                title = translate("buttons.reject") as string;
                break;
            default:
                title = translate("buttons.accept") as string;
                break;
        }
        return title;
    }

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    return (
        <DialogPopup open
            title={getTitle()}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={disabled}>
                    {getButtonText()}
                </Button>
            }
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
        >
            <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {getMessage()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="comment" name="comment"
                            label={props.action === "cancel" ? translate("refunds.popups.cancel_reason") as string : translate("refunds.popups.comment") as string}
                            value={request}
                            rows={5}
                            required={props.action === "reject" || props.action === "cancel"}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                </Grid>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );

}