import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import DefaultIcon from "@material-ui/icons/BrightnessHighTwoTone";
import PluginIcon from "@material-ui/icons/AccountTreeTwoTone";

import translate from "../i18n/Translator";
import { Workflow } from "../model/Workflow";
import { AppContext } from "../context/AppContext";
import { PluginStep } from "../model/Plugin";

interface WorkflowMenuProps {
    workflow: Workflow;
    anchor: HTMLElement | null;
    onDelete(): any;
    onDefault(): any;
    onEditPlugins(step: PluginStep): any;
    onClose(): any;
}

export default function WorkflowMenu(props: WorkflowMenuProps) {
    const context = useContext(AppContext);

    const pluginsTile = (step: PluginStep) => {
        return (
            <MenuItem button onClick={() => props.onEditPlugins(step)} dense>
                <ListItemIcon>
                    <PluginIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate(`plugins.${step}`)}</Typography>
            </MenuItem>
        );
    };

    return (
        <Menu
            id="-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button component={Link} to={`/workflows/${props.workflow.id}/edit`} onClick={props.onClose} dense disabled={!context.isGranted("WorkflowsUpdate")}>
                <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.edit")}</Typography>
            </MenuItem>
            {context.isGranted("WorkflowsUpdate") && props.workflow.status === "AVAILABLE" && !props.workflow.is_default &&
                <MenuItem button onClick={props.onDefault} dense>
                    <ListItemIcon>
                        <DefaultIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.set_default")}</Typography>
                </MenuItem>
            }
            {context.isGranted("WorkflowsUpdate") && (props.workflow.status !== "DISABLED") && pluginsTile("VALIDATION")}
            {context.isGranted("WorkflowsUpdate") && (props.workflow.status !== "DISABLED") && pluginsTile("BEFORE_SEND")}
            {context.isGranted("WorkflowsUpdate") && (props.workflow.status !== "DISABLED") && pluginsTile("BEFORE_STATUS_TRANSITION")}
            {context.isGranted("WorkflowsDelete") && (props.workflow.status !== "DISABLED") && !props.workflow.is_default && (
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                </MenuItem>
            )}
        </Menu>
    );

}