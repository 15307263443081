import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";

import { createBudget, getBudget, updateBudget } from "../api/BudgetApi";
import { listCompanies } from "../api/CompanyAPI";

import translate from "../i18n/Translator";
import { Budget, BudgetRequest } from "../model/Budget";
import { CompaniesQueryParams } from "../model/Company";

import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import DatePicker from "../components/DatePicker";

import BudgetIcon from '@material-ui/icons/ListAltOutlined';

import moment from "moment";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";

export default function BudgetForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const budgetId = match.params.budgetId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<BudgetRequest>();
    const [validations, setValidations] = useState({} as any);
    const [companyIds, setCompanyIds] = useState<string[]>([]);
    const [companyNames, setCompanyNames] = useState<string[]>([]);
    const [warning, setWarning] = useState("");
    const [redirect, setRedirect] = useState<string | undefined>();

    const submitPromise = (): Promise<Budget> => {
        if (budgetId) {
            return updateBudget(tenantId, budgetId, request!);
        }
        return createBudget(tenantId, request!);
    };

    useEffect(() => {
        setStatus("loading");
        listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams)
            .then(companiesResponses => {
                if (companiesResponses.total > 0) {
                    let companies = companiesResponses.items;
                    setCompanyIds(companies.map((company) => company.id));
                    setCompanyNames(companies.map((company) => company.name));
                }
            }).finally(() => {
                if (!budgetId) {
                    setRequest({ init_date: new Date(), end_date: new Date(), } as BudgetRequest);
                    setStatus("loaded");
                } else {
                    getBudget(tenantId, budgetId).then((budget) => {
                        setRequest({
                            name: budget.name,
                            company_id: budget.company_id,
                            init_date: budget.init_date,
                            end_date: budget.end_date,
                            currency: budget.currency
                        } as BudgetRequest);
                        setStatus("loaded");
                    }).catch((error) => {
                        setStatus(error.message);
                    });
                }
            });
    }, [context.session!.tenant!.id, match.params.budgetId]);

    const hasChanged = (name: string, value: string, inputRef?: InputRef) => {
        if (!request) {
            return;
        }
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        if (date && request) {
            setRequest({ ...request, [name]: date })
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations) || !request) {
            return;
        }
        setSubmitting(true);
        submitPromise().then((budget) => {
            setRedirect(`/budgets/${budget.id}/detail`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading" || !request) {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(budgetId ? "budget.create.edit" : "budget.create.new")} icon={<BudgetIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text" id="company_id" name="company_id"
                                        label={translate("companies.single") as string}
                                        value={request.company_id}
                                        required={true} disabled={submitting || budgetId}
                                        margin={"dense"}
                                        onValueChanged={hasChanged}
                                        options={companyIds} optionLabels={companyNames} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("budget.create.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item xs={6}>
                                            <DatePicker name="init_date"
                                                label={translate("budget.create.init_date") as string}
                                                initial={request.init_date ? moment(request.init_date).format("DD/MM/YYYY") : undefined}
                                                clearable={false}
                                                format="DD/MM/YYYY"
                                                defaultIfMissing={false}
                                                disableFuture={false}
                                                disabled={submitting}
                                                onChange={onChangedDate} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker name="end_date"
                                                label={translate("budget.create.end_date") as string}
                                                initial={request.end_date ? moment(request.end_date).format("DD/MM/YYYY") : undefined}
                                                clearable={false}
                                                format="DD/MM/YYYY"
                                                defaultIfMissing={false}
                                                disableFuture={false}
                                                disabled={submitting}
                                                onChange={onChangedDate} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(budgetId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}