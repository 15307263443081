import React, { useState, useEffect, useContext } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Progress from "../components/Progress";
import Gridable from "../components/Gridable";
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from "@material-ui/icons/BorderColor";
import { Redirect, useHistory } from "react-router-dom";

import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { getReceptionRule } from "../api/ReceptionRuleAPI";
import { ReceptionRule, RuleReceptionType } from "../model/ReceptionRule";

export default function ReceptionRuleForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const history = useHistory();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [ruleTypeToEdit, setRuleTypeToEdit] = useState<RuleReceptionType>();
    const [data, setData] = useState<ReceptionRule>();

    const load = () => {
        setStatus("loading");
        history.push(match.params);
        getReceptionRule(context.session!.tenant!.id).then((response) => {
            if(response){
                let temp = response;
                if(response.types){
                    temp.types = response.types.filter(type => type.sat_cfdi_type !== "T");
                }
                setData(temp);
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error);
        });
    };

    useEffect(load, [match.params.cfdi]);

    useEffect(() => {
        // eslint-disable-next-line 
    }, [context.session]);

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !data) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if(ruleTypeToEdit){
        return <Redirect to={`/reception-rules/${data!.id}/edit/${ruleTypeToEdit.sat_cfdi_type}/type`} />
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const countPaymentForms = (ruleType : RuleReceptionType) => {
        let paymentPue = (ruleType.payments as any)["PUE"] as string[];
        let paymentPpd = (ruleType.payments as any)["PPD"] as string[];
        return ((paymentPue ? Object.keys(paymentPue).length : 0) + (paymentPpd ? Object.keys(paymentPpd).length :0));   
    }

    return (
        <Surface title={translate("reception_rule.title") as string} subtitle={translate("reception_rule.subtitle") as string}
            icon={<PlaylistAddCheckIcon />} className="PaperPagination PaperTabContainer" >
               <Gridable
                items={data && data.types ? data.types : []}
                loading={false}
                error={status !== "loaded" ? status : undefined}
                empty={translate("shipping_templates.empty") as string}
                columns={[
                    {
                        title: translate("reception_rule.key") as string,
                        converter: (ruleType) => ruleType.sat_cfdi_type,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1
                    },
                    {
                        title: translate("reception_rule.description") as string,
                        converter: (ruleType) => translate("reception_rule.voucher_type."+ruleType.sat_cfdi_type) as string,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("reception_rule.status.title") as string,
                        converter: (ruleType) => ruleType.active ? 
                            <Grid container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                            >
                                <Grid item xs={6}><Typography variant="inherit">{translate("reception_rule.status.active")}</Typography></Grid>
                                <Grid item xs={6}><CheckIcon color="primary" /></Grid>
                            </Grid>
                            : <Grid>
                                <Typography style={{ "opacity": "30%" }} variant="inherit">{translate("reception_rule.status.inactive")}</Typography>
                                </Grid>
                        ,
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("reception_rule.use_cfdi.title") as string,
                        converter: (ruleType) => <Grid><Typography style={ruleType.active ? {} : {"opacity":"30%"}} variant="inherit">{translate("reception_rule.use_cfdi.description", {"total" : ruleType.uses ? ruleType.uses.length : 0})}</Typography></Grid>,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("reception_rule.payments_method.title") as string,
                        converter: (ruleType) =>
                            <Grid>
                                <Typography style={ruleType.active ? {} : { "opacity": "30%" }} variant="inherit">
                                    { ruleType.sat_cfdi_type !== "E" && ruleType.sat_cfdi_type !== "P" ? translate("reception_rule.payments_method.description", { "total": (ruleType.payments && Object.keys(ruleType.payments).length > 0 ? countPaymentForms(ruleType) : 0)}) : "---"}
                                </Typography>
                            </Grid>,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: (
                            <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (ruleType) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={()=>setRuleTypeToEdit(ruleType)}>
                                <EditIcon color="primary"/>
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]}
            />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );
}