import React from "react";
import XMLViewer from "react-xml-viewer";

export default function XmlViewer(props) {
  const customTheme = {
    attributeKeyColor: "#0074D9",
    attributeValueColor: "#2ECC40"
  };

  //Its value can be an URL, base64 string or File???
  const { xml } = props;

  return (
    <div className="pdf-view-container">
      <XMLViewer xml={xml} theme={customTheme} />
    </div>
  );
}
