import React, { RefObject, ReactNode } from "react";
import { Popper, Grow, Paper, ClickAwayListener } from "@material-ui/core";

interface PopperMenuProps {
    anchorRef: RefObject<HTMLButtonElement>;
    onClose(): any;
    children: ReactNode;
}

export default function PopperMenu(props: PopperMenuProps) {

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (props.anchorRef.current && props.anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        props.onClose();
    };

    return (
        <Popper open anchorEl={props.anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            {props.children}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}