import { callAPI, HTTPMethod } from "./API";
import { KeyPairs, KeyPair, KeyPairQueryParams, KeyPairRequest, MetadataResponse } from "../model/KeyPair";

const getBaseApi = (tenantId: string) => {
    return `/tenants/${tenantId}/key-pairs`;
};

export async function getKeyPairs(tenantId: string, page: number, offset: number, params: KeyPairQueryParams): Promise<KeyPairs> {
    return await callAPI(getBaseApi(tenantId), {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            page_size: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createKeyPair(tenantId: string, request: KeyPairRequest): Promise<KeyPair> {
    return await callAPI(getBaseApi(tenantId), {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function metadata(tenantId: string): Promise<MetadataResponse> {
    return await callAPI(`${getBaseApi(tenantId)}/metadata`, {
        method: HTTPMethod.GET,
    });
}

export async function getKeyPair(tenantId: string, keyPairId: string): Promise<KeyPair> {
    return await callAPI(`${getBaseApi(tenantId)}/${keyPairId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateKeyPair(tenantId: string, keyPairId: string, request: KeyPairRequest): Promise<KeyPair> {
    return await callAPI(`${getBaseApi(tenantId)}/${keyPairId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteKeyPair(tenantId: string, keyPairId: string): Promise<any> {
    return await callAPI(`${getBaseApi(tenantId)}/${keyPairId}`, {
        method: HTTPMethod.DELETE
    });
}
