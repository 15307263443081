import React, { useContext, useState } from "react";

import translate from "../i18n/Translator";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { closePurchaseOrder } from "../api/PurchaseOrderAPI"
import { AppContext } from "../context/AppContext";

interface PurchaseOrderFinalizePopupProps {
    purchaseOrder: PurchaseOrder;
    onClose(): any;
    onFinalize(purchaseOrder: PurchaseOrder): any;
    onError(error: string): any;
}

export default function PurchaseOrderFinalizePopup(props: PurchaseOrderFinalizePopupProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);

    const onFinalize = () => {
        setSubmitting(true);
        closePurchaseOrder(context.session!.tenant!.id, props.purchaseOrder.id).then(response => {
            props.onFinalize(response);
        }).catch(error => {
            props.onError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <ConfirmationPopup
            title={translate("purchase_orders.finalize_title") as string}
            message={translate("purchase_orders.cancel.text") as string}
            secondary={translate("purchase_orders.cancel.secondary") as string}
            onClose={props.onClose}
            button={translate("buttons.confirm") as string}
            doAction={onFinalize}
            color="secondary"
            submitting={submitting} />
    );
}