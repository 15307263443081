import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { getPaymentsCfdiForReview } from "../api/PaymentApi";
import { Grid, Divider, Button, IconButton } from "@material-ui/core";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { CfdiPaymentReview, CfdisPaymentsReview } from "../model/Payment";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import ForReviewIcon from "@material-ui/icons/GavelTwoTone";
import { CfdiPaymentSelectListProps } from "./PaymentList";
import { CfdiReviewListParams } from "../model/Payment";
import Progress from "../components/Progress";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { Filter } from '@devexpress/dx-react-grid';
import { isRoleOrParent } from "../model/Role";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";

export default function ListPaymentsForReview(props: CfdiPaymentSelectListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const isProviderUser = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): CfdiReviewListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "payments": typeof qs["payments"] === "string" ? qs["payments"] as string : "",
            "name_provider": typeof qs["name_provider"] === "string" ? qs["name_provider"] as string : "",
            "name_company": typeof qs["name_company"] === "string" ? qs["name_company"] as string : "",
        } as CfdiReviewListParams;
    };
    const paramsToFilters = () => {
        return [
            { columnName: "payments", value: params.payments },
            { columnName: "name_provider", value: params.name_provider },
            { columnName: 'name_company', value: params.name_company },
        ] as Filter[];
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CfdisPaymentsReview>();
    const [params, setParams] = useState<CfdiReviewListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdiReviewListParams>(paramsFromQueryString);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [redirect, setRedirect] = useState<string>();

    const [selection, setSelection] = useState<(number | string)[]>([]);
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const load = () => {
        setStatus("loading");
        if (context.isGranted("PaymentsRead")) {
            if (isProviderUser) {
                params.provider_id = context.session!.provider!.id;
            }
            getPaymentsCfdiForReview(context.session!.tenant!.id, pageSize, pageSize * page, params).then((response) => {
                setData(response);
            }).catch((error) => {
                setStatus(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
        }

    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const onClickedRow = (cfdiPaymentReviewResponse: CfdiPaymentReview) => {
        pushHistory();
        setRedirect(`/payment-review/${cfdiPaymentReviewResponse.id}?origin=${window.location.pathname}`);
    };


    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["payments"] = workingParams.payments;
        qs["name_provider"] = workingParams.name_provider;
        qs["name_company"] = workingParams.name_company || "";
        qs["page"] = "0";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "search":
                        temp.search = filter.value;
                        break;
                    case "payments":
                        temp.payments = filter.value;
                        break;
                    case "name_company":
                        temp.name_company = filter.value;
                        break;
                    case "name_provider":
                        temp.name_provider = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
        pushHistory();
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelection(selected);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    return (
        <Surface title={props.title}
            icon={<ForReviewIcon />}
            className="PaperPagination">
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                columns={props.columns ? props.columns : []}
                defaultExpandedGroups={props.defaultExpandedGroups}
                onClickRow={onClickedRow}
                clickRowColumns={props.clickRowColumns}
                amountCurrencyColumns={props.currencyColumns}
                quantityColumns={props.numberColumns}
                dateColumns={props.dateColumns}
                columnsFormat={props.columnsFormat}
                leftColumns={props.leftColumns}
                textColumns={props.textColumns}
                rightColumns={props.rightColumns}
                grouping={props.grouping}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                selectionIds={selection}
                filters={filters}
                customFormatColumns={props.statusPlugins}
                setFiltersHandler={setFiltersHandler}
                setSelectionHandler={setSelectionHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={() => { }}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );
}