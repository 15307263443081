import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Efos, Situation } from "../model/Efos";
import { ProviderEfoIcon } from "./ProviderIcons";
import DateFormat from "../components/DateFormat";

interface ProviderVerificationEfosResultProps {
    efos: Efos;
}

export default function ProviderVerificationEfosResult(props: ProviderVerificationEfosResultProps) {
    return (
        <Grid container justify="space-between" alignContent="center" spacing={2}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant="button">
                            {translate("efo.title")}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <DateFormat date={props.efos.published} format="LL" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs="auto">
                <ProviderEfoIcon status={props.efos.status} />
            </Grid>
            {props.efos.status === "LIMPIO" && (
                <Grid item xs>
                    <span>{translate("efo.limpio")}</span>
                </Grid>
            )}
            {props.efos.status !== "LIMPIO" && (
                <Grid item xs>
                    <EfoStatusSection efos={props.efos} />
                </Grid>
            )}
        </Grid>
    );
}

interface EfoStatusSectionProps {
    efos: Efos;
}

export function EfoStatusSection(props: EfoStatusSectionProps) {
    let items = {
        "PRESUNTO": props.efos.presunto,
        "DEFINITIVO": props.efos.definitivo,
        "DESVIRTUADO": props.efos.desvirtuado,
        "SENTENCIA_FAVORABLE": props.efos.sentencia_favorable
    } as any;
    let keys = Object.keys(items);

    const getSituation = (status: string): Situation | undefined => {
        let value = items[status];
        if (!value) return;
        return value as Situation;
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {translate(`efo.status.${props.efos.status}`)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {keys.map((status: string, index: number) => (
                    <SituationDetail key={index} status={status} situation={getSituation(status)} />
                ))}
            </Grid>
        </Grid>
    );
}

interface SituationDetailProps {
    status: string;
    situation?: Situation;
}

export function SituationDetail(props: SituationDetailProps) {
    if (!props.situation) {
        return <Grid container />
    }

    return (
        <Box pb={2}>
            <Grid container spacing={1}>
                {props.situation.document && (
                    <Grid item xs={12}>
                        {translate(`efo.${props.status}.document_number_and_date_sat`)}<br />
                        <strong>{props.situation.document}</strong>
                    </Grid>
                )}
                {props.situation.date && (
                    <Grid item xs={12}>
                        {translate(`efo.${props.status}.date_sat`)}<br />
                        <strong><DateFormat date={props.situation.date} format="LL" /></strong>
                    </Grid>
                )}
                {props.situation.document_dof && (
                    <Grid item xs={12}>
                        {translate(`efo.${props.status}.document_number_and_date_dof`)}<br />
                        <strong>{props.situation.document_dof}</strong>
                    </Grid>
                )}
                {props.situation.date_dof && (
                    <Grid item xs={12}>
                        {translate(`efo.${props.status}.date_dof`)}<br />
                        <strong><DateFormat date={props.situation.date_dof} format="LL" /></strong>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}