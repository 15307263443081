import React from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import jsPDF from "jspdf";
import translate from "../i18n/Translator";

interface PaymentManualDetailsProps {
    reference: string;
    amount: string;
}

export default function PaymentManualDetails(props: PaymentManualDetailsProps) {
    const fields = ["razon_social", "rfc", "banco", "plaza", "moneda", "cuenta", "clabe"];

    const print = () => {
        let margin = 40;
        let row = 20;
        let doc = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: "letter"
        });

        doc.setFont("helvetica");
        doc.setFontSize(14);
        doc.setFontType("bold");

        let title = translate("billing.manual.account.title") as string;
        doc.text(translate("billing.manual.account.title") as string, margin, margin);
        let tw = doc.getTextWidth(title);
        doc.line(margin, 50, (tw + margin), 50);

        doc.setFontSize(12);
        doc.setFontType("normal");
        var y = 60;
        fields.forEach((key) => {
            doc.text(translate(`billing.manual.account.${key}`) as string, margin, y += row);
            if (key === "rfc") {
                y += 7;
            }
        });
        doc.text(translate("billing.manual.account.reference", { "reference": props.reference }), margin, y += row);

        doc.setFontType("bold");
        doc.text(translate("billing.manual.account.amount", { "amount": props.amount }), margin, y += row);

        doc.save(`referencia-de-pago-${props.reference}.pdf`);
    };

    return (
        <Grid container justify="space-between">
            <Grid item xs>
                <Typography variant="body1" paragraph>
                    {translate("billing.manual.account.title")}
                </Typography>
            </Grid>
            <Grid item xs="auto">
                <IconButton size="small" onClick={print}>
                    <PrintIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                {fields.map((key) => (
                    <Typography key={key} variant="caption" component="h6">
                        {translate(`billing.manual.account.${key}`)}
                    </Typography>
                ))}
                <Typography variant="caption" component="h6">
                    {translate("billing.manual.account.reference", { "reference": props.reference })}
                </Typography>
                <Typography variant="caption" component="h6" paragraph>
                    {translate("billing.manual.account.amount", { "amount": props.amount })}
                </Typography>
                <Typography variant="caption" paragraph>
                    {translate("billing.manual.description")}
                </Typography>
            </Grid>
        </Grid>
    );
}