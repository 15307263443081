import React from "react";
import { Grid, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Provider69CffIcon } from "./ProviderIcons";
import DateFormat from "../components/DateFormat";
import { Cff69ProviderResult } from '../model/Cff69';
import { BlackListValidations } from '../model/Cff69'

interface ProviderVerificationCff69ResultProps {
    validations: BlackListValidations;
}

export default function ProviderVerificationCff69Result(props: ProviderVerificationCff69ResultProps) {

    const validationItems = Object.values<Cff69ProviderResult>(props.validations as any);
    const allClean = validationItems.find(r => !r.is_clean) === undefined;
    const published = validationItems.find(r => r.is_clean)?.published || validationItems[0].published;

    return (
        <Grid container justify="space-between" alignContent="center" spacing={2}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant="button">
                            {translate("cff_69.title")}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <DateFormat date={published } format="LL" />
                    </Grid>
                </Grid>
            </Grid>
            {allClean && (
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <Provider69CffIcon status={"LIMPIO"} />
                        </Grid>
                        <Grid item xs>
                            <span>{translate(`cff_69.limpio`) as string}</span>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {!allClean && (validationItems.filter(v => !v.is_clean).map((validation) => (
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <Provider69CffIcon status={validation.status} />
                        </Grid>
                        <Grid item xs>
                            <span>{translate(`cff_69.black_descriptions.${validation.status}`) as string}</span>
                        </Grid>
                    </Grid>
                </Grid>
            )))}
        </Grid>
    );
}
