import React from "react";
import { Grid, Checkbox } from "@material-ui/core";
import { GridableColumn } from "../components/Gridable";
import translate from "../i18n/Translator";

export interface ReceptionRuleCallbacks {
    handleCheck(code: string): any;
    handleAllChecks(checked: boolean): any;
}

export interface ReceptionRuleProps {
    title: string;
    success?: string;
    getColumns(code: string[], selected: string[], callbacks: ReceptionRuleCallbacks): GridableColumn<string>[];
}

interface ReceptionRuleColumnTitleProps {
    total: number;
    selected: number;
    showChecks: boolean;
    disabled: boolean;
    callbacks: ReceptionRuleCallbacks;
}

export function ReceptionRuleColumnTitle(props: ReceptionRuleColumnTitleProps) {

    const handleAllCheckboxs = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        props.callbacks.handleAllChecks(checked);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto">
                        <Checkbox
                            id="cfdis_all"
                            name="cfdis_all"
                            checked={props.selected > 0 && props.selected === props.total}
                            indeterminate={props.selected > 0 && props.selected < props.total}
                            onChange={handleAllCheckboxs}
                            disabled={props.disabled}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                <Grid item xs>
                    <strong>{translate("reception_rule.key")}</strong>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface ReceptionRuleColumnValueProps {
    code: string;
    checked: boolean;
    showChecks: boolean;
    disabled: boolean;
    callbacks: ReceptionRuleCallbacks;
    getValue(code: string): string;
}

export function ReceptionRuleColumnValue(props: ReceptionRuleColumnValueProps) {

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.callbacks.handleCheck(props.code);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto">
                        <Checkbox
                            id={props.code}
                            name={props.code}
                            value={props.code}
                            checked={props.checked}
                            disabled={props.disabled}
                            onChange={handleSelection}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                <Grid item xs>
                    {props.getValue(props.code)}
                </Grid>
            </Grid>
        </Grid>
    );
}