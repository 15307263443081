import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import ActivateIcon from "@material-ui/icons/DeleteTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface UserGroupMenuProps {
    tenantId?: string;
    url: string;
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
}

export default function UserGroupMenu(props: UserGroupMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["UserGroupUpdate"]) &&
                <MenuItem button component={Link} to={props.url} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["UserGroupDelete"]) &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <ActivateIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}