import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import SocialContributionIcon from "@material-ui/icons/HomeWork";

import { createSocialContribution, listSocialContributions } from "../api/SocialContributionAPI";
import { createSocialContribution as createProviderSocialContribution, listSocialContributions as listProviderSocialContributions } from "../api/ProviderSocialContributionAPI";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import { get as getProviderConfigurations } from "../api/ProviderConfigurationApi";
import translate from "../i18n/Translator";
import { NewSocialContributionRequest, SocialContributions, SocialContributionsQueryParams } from "../model/SocialContribution";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import { formatDate } from "../components/DateFormat";
import { AppContext } from "../context/AppContext";
import moment from "moment";
import { EmployerConfiguration } from "../model/Employer";
import ConfirmationPopup from "../components/ConfirmationPopup";
import ToValidateSipareForm from "./ToValidateSipareForm";
import { MONTHS_BEFORE_IN_PERIOD } from "../model/Contribution";

export default function NewSocialContributionContribution() {
    const [view, setView] = useState<"new" | "validate-sipare">("new");
    const [url, setUrl] = useState<string>("");
    const [period, setPeriod] = useState<string>("");
    const [employeerNumber, setEmployeerNumber] = useState<string>("");
    const onValidateSipare = (url: string, period: string, employeerNumber: string) => {
        setPeriod(period);
        setUrl(url);
        setEmployeerNumber(employeerNumber);
        setView("validate-sipare");
    };
    if(view === "validate-sipare"){
        return <ToValidateSipareForm urlPdf={url} period={period} employeerNumber={employeerNumber} />
    }
    return <NewSocialContributionContributionForm onValidateSipare={onValidateSipare}/>;
}

export interface NewSocialContributionContributionFormParams {
    onValidateSipare(url: string, period: string, employeerNumber: string): any;
}

export interface fillPeriodsParams{
    response: SocialContributions;
    setDateKeys(keys: string[]): any;
    setDateLabels(labels: string[]): any;
    setStatus(status: string): any;
}

export function fillPeriods(params: fillPeriodsParams) {
    const existing = params.response.items.map(item => {
        return `${item.year}-${item.month}`;
    });

    const today = moment();
    let m = moment({
        year: today.year(),
        month: today.month(),
        day: 1,
    });

    const dateKeys = [];
    const dateLabels = [];
    for (let i = 0; i < params.response.months_before_in_period; i++) {
        const key = `${m.year()}-${m.month() + 1}`;
        if (existing.indexOf(key) === -1) {
            dateKeys.push(key);
            dateLabels.push(formatDate(m.toDate(), "MMMM YYYY"));
        }
        m = m.subtract(1, "month");
    }

    params.setDateKeys(dateKeys);
    params.setDateLabels(dateLabels);
    params.setStatus("loaded");
}

function NewSocialContributionContributionForm(props: NewSocialContributionContributionFormParams) {
    const context = useContext(AppContext);
    const uploadSipare = context.session!.provider && context.session!.provider.upload_sipare;
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<NewSocialContributionRequest>({} as NewSocialContributionRequest);
    const [validations, setValidations] = useState({} as any);
    const [dateKeys, setDateKeys] = useState<string[]>([]);
    const [dateLabels, setDateLabels] = useState<string[]>([]);
    const [employerNumbers, setEmployerNumbers] = useState<string[]>([]);
    const [openValidateDialog, setOpenValidateDialog] = useState<boolean>();
    const [openValidatePaymentDialog, setOpenValidatePaymentDialog] = useState<boolean>();
    const mode = context.session?.provider?.declaration_frequency || "MONTHLY";

    const promiseGetConfigurations = (): Promise<EmployerConfiguration> => {
        if (providerId) {
            return getProviderConfigurations(tenantId, providerId);
        }
        return getTenantConfigurations(tenantId);
    };

    const load = () => {
        setStatus("loading");
        promiseGetConfigurations().then(response => {
            const employerNumbers = response.employer_numbers || [];
            setEmployerNumbers(employerNumbers);
            if (employerNumbers.length === 1) {
                setRequest((r) => {
                    return { ...r, employer_number: employerNumbers[0] };
                });
            }
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId]);

    const promiseList = (employerNumber: string) => {
        const params = {
            employerNumber: employerNumber
        } as SocialContributionsQueryParams;

        if (providerId) {
            return listProviderSocialContributions(tenantId, providerId, 50, 0, params);
        }
        return listSocialContributions(tenantId, 50, 0, params);
    };

    const promiseCreate = (request : NewSocialContributionRequest) => {
        if (providerId) {
            return createProviderSocialContribution(tenantId, providerId, request);
        }
        return createSocialContribution(tenantId, request);
    };

    useEffect(() => {
        if (!request.employer_number) {
            setDateKeys([]);
            setDateLabels([]);
            return;
        }

        promiseList(request.employer_number).then(response => {
            fillPeriods({
                response: response,
                setDateKeys: setDateKeys,
                setDateLabels: setDateLabels,
                setStatus: setStatus
            } as fillPeriodsParams);
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line 
    }, [tenantId, request.employer_number]);

    const hasChangedMonth = (name: string, value: string, inputRef: InputRef) => {
        const fixed = value === "---" ? "" : value;
        const segments = fixed.split("-");
        if (segments.length === 2) {
            const year = +segments[0];
            const month = +segments[1];
            if (mode === "MONTHLY") {
                setRequest({ ...request, [name]: fixed, year: year, month: month });
            } else {
                setRequest({ ...request, [name]: fixed, year: year, month: month, bimester: Math.ceil(month/2) });
            }
        } else {
            setRequest({ ...request, [name]: fixed });
        }

        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        promiseCreate(request).then((socialContribution) => {
            if(socialContribution.type && socialContribution.type === 'SIPARE'){
                setOpenValidatePaymentDialog(true);
                return;
            }
            history.push("/contributions/social");
        }).catch((error) => {
            setSubmitting(false);
            if(request.bank_voucher){
                setOpenValidateDialog(true);
            } else {
                setError(error.message);
            }
        });
    };

    const onCancelValidatePayment = () => {
        setOpenValidatePaymentDialog(false);
    };

    const onAcceptValidatePayment = () => {
        setOpenValidatePaymentDialog(false);
        setSubmitting(true);

        if(request.key && request.employer_number){
            props.onValidateSipare(request.sipare, request.key, request.employer_number);
        }
    };

    const onCancelValidate = () => {
        setOpenValidateDialog(false);
    };

    const onAcceptValidate = () => {
        setOpenValidateDialog(false);
        setSubmitting(true);

        promiseCreate({ ...request, "force_voucher": true }).then((socialContribution) => {
            history.push("/contributions/social");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });

    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("social_contributions.new")} icon={<SocialContributionIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="employer_number" name="employer_number"
                                        value={request.employer_number}
                                        label={translate("contributions.employer_number") as string}
                                        options={employerNumbers}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="key" name="key"
                                        value={request.key}
                                        label={translate("contributions.month") as string}
                                        options={dateKeys} optionLabels={dateLabels}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChangedMonth} />
                                </Grid>
                                    {uploadSipare ?
                                        <UploaderInput id="sipare" name="sipare"
                                            value={request.sipare}
                                            label={translate("social_contributions.sipare") as string}
                                            required disabled={submitting}
                                            margin={margin}
                                            acceptExtension=".pdf" path={`tenants/${tenantId}/sipare`}
                                            newFileName={request.key ? request.key+"_IMSS_SIPARE" : "IMSS_SIPARE"}
                                            onValueChanged={hasChanged} />
                                    :
                                        <UploaderInput id="sua" name="sua"
                                            value={request.sua}
                                            label={translate("social_contributions.sua") as string}
                                            required disabled={submitting}
                                            margin={margin}
                                            acceptExtension=".sua" path={`tenants/${tenantId}/sua`}
                                            newFileName={request.key ? request.key+"_IMSS_SUA" : "IMSS_SUA"}
                                            onValueChanged={hasChanged} />}
                                    <UploaderInput id="bank_voucher" name="bank_voucher"
                                    value={request.bank_voucher}
                                    label={translate("social_contributions.bank_voucher") as string}
                                    required={false} disabled={submitting}
                                    margin={margin}
                                    acceptExtension=".pdf,.jpg,.png" path={`tenants/${tenantId}/bank_voucher`}
                                    newFileName={request.key ? request.key+"_IMSS_CompBanco" : "IMSS_CompBanco"}
                                    onValueChanged={hasChanged} />
                                    <UploaderInput id="imss" name="imss"
                                        value={request.imss}
                                        label={translate("social_contributions.imss") as string}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        acceptExtension=".xml" path={`tenants/${tenantId}/imss`}
                                        newFileName={request.key ? request.key+"_IMSS_CFDI" : "IMSS_CFDI"}
                                        onValueChanged={hasChanged} />
                                {request.month !== 0 && request.month % 2 === 0 && 
                                        <UploaderInput id="infonavit" name="infonavit"
                                        value={request.infonavit}
                                        label={translate("social_contributions.infonavit") as string}
                                        required={false} disabled={submitting}
                                        margin={margin}
                                        acceptExtension=".xml" path={`tenants/${tenantId}/infonavit`}
                                        newFileName={request.key ? request.key+"_INFONAVIT_CFDI" : "INFONAVIT_CFDI"}
                                        onValueChanged={hasChanged} />
                                }
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate("buttons.create")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
            {request && openValidateDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.title") as string}
                    message={translate("confirm_contribution.voucher.message") as string}
                    secondary={translate("confirm_contribution.voucher.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidate}
                    doAction={onAcceptValidate}
                />
            }
            {request && openValidatePaymentDialog &&
                <ConfirmationPopup
                    title={translate("confirm_sipare.sipare.title") as string}
                    message={translate("confirm_sipare.sipare.message") as string}
                    secondary={translate("confirm_sipare.sipare.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidatePayment}
                    doAction={onAcceptValidatePayment}
                />
            }
        </Grid>
    );
}