import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import { MoreVertIcon, AddIcon } from "../components/Icons";
import NotificationsIcon from '@material-ui/icons/Notifications';

import { listFocaltecAnnouncements, updateStatusFocaltecAnnouncement } from "../api/FocaltecAnnouncementAPI";
import { AppContext } from "../context/AppContext";
import { FocaltecAnnouncement, FocaltecAnnouncements, FocaltecAnnouncementsQueryParams, FocaltecAnnouncementUpdateStatusRequest } from "../model/FocaltecAnnouncement";
import DateFormat from "../components/DateFormat";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable, { GridableColumn } from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DialogPopup from "../components/DialogPopup";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import FocaltecAnnouncementMenu from "./FocaltecAnnouncementMenu";
import Brightness1Icon from '@material-ui/icons/Brightness1';

export default function FocaltecAnnouncementsList() {
    const history = useHistory();
    const context = useContext(AppContext);

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<FocaltecAnnouncements>();
    const [workingParams, setWorkingParams] = useState<FocaltecAnnouncementsQueryParams>({
        search: search,
    } as FocaltecAnnouncementsQueryParams);

    const [focaltecAnnouncement, setFocaltecAnnouncement] = useState<FocaltecAnnouncement>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popup, setPopup] = useState<"delete">();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPage(query));
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query));
    const [isCreateGranted] = useState(context.isGranted("FocaltecAnnouncementsCreate"));

    const load = () => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as FocaltecAnnouncementsQueryParams;
        setWorkingParams(params);

        listFocaltecAnnouncements(pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (focaltecAnnouncement: FocaltecAnnouncement) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setFocaltecAnnouncement(focaltecAnnouncement);
    };

    const onClosePopup = () => {
        onCloseOption();
        setPopup(undefined);
    };

    const onUpdateStatus = (status: string) => {
        if (!focaltecAnnouncement) return;

        setAnchorEl(null);
        onConfirmDelete(status);
    };

    const onConfirmDelete = (status: string) => {
        onClosePopup();
        if (!focaltecAnnouncement) return;

        setSubmitting(true);
        updateStatusFocaltecAnnouncement( focaltecAnnouncement.id, { status: status, end_date: focaltecAnnouncement.end_date } as FocaltecAnnouncementUpdateStatusRequest).then(() => {
            setSuccess(
                translate("focaltec_announcements."+(status === "ACTIVE" ? "success_activate" : "success_inactivate"), {id: focaltecAnnouncement.id}) as string
            );
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onClosedSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };


    const onCloseOption = () => {
        setAnchorEl(null);
        setFocaltecAnnouncement(undefined);
    };

    const onClickRow = (focaltecAnnouncement: FocaltecAnnouncement) => {
        history.push(`/focaltec-announcements/${ focaltecAnnouncement.id }/edit`);
    };

    return (
        <Pagination title={translate("focaltec_announcements.title")} icon={<NotificationsIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={isCreateGranted ?
                (<Link to="/focaltec-announcements/new">
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justifyContent="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("focaltec_announcements.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("focaltec_announcements.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("focaltec_announcements.columns.announcement") as string,
                        converter: (focaltecAnnouncement) => (
                            <Ellipsis text={ focaltecAnnouncement.message } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: 3,
                        sm: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("focaltec_announcements.columns.type") as string,
                        converter: (row) => (
                            row.type === "SUCCESS" ?
                                <Brightness1Icon style={{ color: "#32CD32", "width": 10, "height": 10 }} />
                                : row.type === "DANGER" ?
                                    <Brightness1Icon style={{ color: "#FF0000", "width": 10, "height": 10 }} />
                                    : row.type === "WARNING" ?
                                        <Brightness1Icon style={{ color: "#f69450", "width": 10, "height": 10 }} />
                                        : <Brightness1Icon style={{ color: "#3d94c5", "width": 10, "height": 10 }} />
                        ),
                        xs: 1,
                        sm: 1,
                        lg: 1,
                        xl: 1
                    },
                    {
                        title: translate("focaltec_announcements.columns.validity") as string,
                        converter: (focaltecAnnouncement) => (
                            <DateFormat date={focaltecAnnouncement.end_date} format="DD/MM/YYYY" />
                        ),
                        xs: 2,
                        sm: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("focaltec_announcements.columns.users_types") as string,
                        converter: (focaltecAnnouncement) => (
                            <Ellipsis text={ translate(`focaltec_announcements.users_types.${focaltecAnnouncement.user_type}`) as string} lenght={ 100 } uppercased={ false } />
                        ),
                        xs: 2,
                        sm: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("focaltec_announcements.columns.tenants") as string,
                        converter: (focaltecAnnouncement) => (
                            <Ellipsis
                                text={focaltecAnnouncement.tenants_names && focaltecAnnouncement.tenants_names.length > 0 ? (focaltecAnnouncement.tenants_names.length === 1 ?
                                    focaltecAnnouncement.tenants_names[0] :
                                    translate("focaltec_announcements.companies", { total: focaltecAnnouncement.tenants_names.length }) as string) :
                                    (focaltecAnnouncement.send_all_tenants ? translate("focaltec_announcements.all") as string : "")}
                                lenght={100} uppercased={false} />
                        ),
                        xs: 2,
                        sm: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("focaltec_announcements.columns.status") as string,
                        converter: (focaltecAnnouncement) => (
                            <Ellipsis text={ translate("focaltec_announcements.statuses."+focaltecAnnouncement.status) as string } lenght={ 100 } uppercased={ false } />
                        ),
                        xs: 1,
                        sm: 1,
                        lg: 1,
                        xl: 1
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (focaltecAnnouncement) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(focaltecAnnouncement)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            { focaltecAnnouncement && anchorEl &&
                <FocaltecAnnouncementMenu focaltecAnnouncement={ focaltecAnnouncement } anchor={anchorEl} onUpdateStatus={onUpdateStatus} onClose={onCloseOption} />
            }
            <SuccessSnackbar message={success} onClose={onClosedSnackbars} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}