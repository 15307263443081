import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, Checkbox, FormGroup, FormControlLabel, Hidden, Divider } from "@material-ui/core";
import CustomersIcon from "@material-ui/icons/Apartment";

import { createCustomer, getCustomer, updateCustomer } from "../api/CustomerAPI";
import translate from "../i18n/Translator";
import { Customer, CustomerRequest, SENDING_MODES } from "../model/Customer";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import { RfcValidator, PhoneValidator } from "../components/Validators";

export default function CustomerForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const rfcValidator = new RfcValidator();
    const phoneValidator = new PhoneValidator();
    const isEdit = !!match.params.customerId;
    const margin = "dense";
    const modeLabels = SENDING_MODES.map(el => translate(`customers.modes.${el}`) as string);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<CustomerRequest>({} as CustomerRequest);
    const [validations, setValidations] = useState({} as any);
    const [checked, setChecked] = useState(false);

    const submitPromise = (): Promise<Customer> => {
        if (isEdit && match.params.customerId) {
            return updateCustomer(context.session!.tenant!.id, match.params.customerId, request);
        }
        return createCustomer(context.session!.tenant!.id, request);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            getCustomer(context.session!.tenant!.id, match.params.customerId).then((customer) => {
                setRequest({
                    name: customer.name,
                    rfc: customer.rfc,
                    external_id: customer.external_id,
                    cfdi_sending_mode: customer.cfdi_sending_mode,
                    cfdi_recipients: customer.cfdi_recipients || "",
                    cfdi_notes: customer.cfdi_notes,
                    document_sending_mode: customer.document_sending_mode,
                    document_recipients: customer.document_recipients || "",
                    document_notes: customer.document_notes,
                    address_street: customer.address_street || "",
                    address_number_ext: customer.address_number_ext || "",
                    address_number_int: customer.address_number_int || "",
                    address_between_street: customer.address_between_street || "",
                    address_between_street_after: customer.address_between_street_after || "",
                    address_county: customer.address_county || "",
                    address_zip_code: customer.address_zip_code || "",
                    address_municipality: customer.address_municipality || "",
                    address_state: customer.address_state || "",
                    contact_email: customer.contact_email || "",
                    contact_phone: customer.contact_phone || "",
                    contact_extension: customer.contact_extension || "",
                } as CustomerRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    }, [context.session, isEdit, match.params.customerId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: !value || value === "---" ? undefined : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((customer) => {
            history.push("/customers");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onCheck = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            setRequest({ ...request, document_sending_mode: request.cfdi_sending_mode, document_recipients: request.cfdi_recipients, document_notes: request.cfdi_notes });
        }
        setChecked(checked);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "customers.edit" : "customers.new")} icon={<CustomersIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("customers.name") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="rfc" name="rfc"
                                        value={request.rfc}
                                        label={translate("customers.rfc") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        validator={rfcValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="external_id" name="external_id"
                                        value={request.external_id}
                                        label={translate("customers.external_id") as string}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="employer_number" name="employer_number"
                                        value={request.employer_number}
                                        label={translate("contributions.employer_number") as string}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="cfdi_sending_mode" name="cfdi_sending_mode"
                                        value={request.cfdi_sending_mode}
                                        options={SENDING_MODES} optionLabels={modeLabels}
                                        label={translate("customers.cfdi_sending_mode") as string}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Hidden xsUp={request.cfdi_sending_mode !== "email"} implementation="css">
                                        <ValidatedInput type="text" id="cfdi_recipients" name="cfdi_recipients"
                                            value={request.cfdi_recipients}
                                            label={translate("customers.cfdi_recipients") as string}
                                            required={request.cfdi_sending_mode === "email"} disabled={submitting}
                                            margin={margin}
                                            helperText={translate("customers.email_helper") as string}
                                            onValueChanged={hasChanged} />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="cfdi_notes" name="cfdi_notes"
                                        value={request.cfdi_notes}
                                        label={translate("customers.cfdi_notes") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={(
                                            <Checkbox checked={checked} onChange={onCheck} disabled={submitting || !request.cfdi_sending_mode} />
                                        )} label={translate("customers.copy_sending_mode")} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="document_sending_mode" name="document_sending_mode"
                                        value={request.document_sending_mode}
                                        options={SENDING_MODES} optionLabels={modeLabels}
                                        label={translate("customers.document_sending_mode") as string}
                                        disabled={checked || submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Hidden xsUp={request.document_sending_mode !== "email"} implementation="css">
                                        <ValidatedInput type="text" id="document_recipients" name="document_recipients"
                                            value={request.document_recipients}
                                            label={translate("customers.document_recipients") as string}
                                            required={request.document_sending_mode === "email"} disabled={checked || submitting}
                                            margin={margin}
                                            helperText={translate("customers.email_helper") as string}
                                            onValueChanged={hasChanged} />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="document_notes" name="document_notes"
                                        value={request.document_notes}
                                        label={translate("customers.document_notes") as string}
                                        disabled={checked || submitting}
                                        margin={margin} rows={3} maxRows={3}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <br/><Divider/><br/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><strong>{translate("customers.address.title")}</strong></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="address_street" name="address_street"
                                        value={request.address_street}
                                        label={translate("customers.address.street") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="address_number_ext" name="address_number_ext"
                                        value={request.address_number_ext}
                                        label={translate("customers.address.number_ext") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="address_number_int" name="address_number_int"
                                        value={request.address_number_int}
                                        label={translate("customers.address.number_int") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="address_between_street" name="address_between_street"
                                        value={request.address_between_street}
                                        label={translate("customers.address.between_street") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="address_between_street_after" name="address_between_street_after"
                                        value={request.address_between_street_after}
                                        label={translate("customers.address.between_street_after") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="address_county" name="address_county"
                                        value={request.address_county}
                                        label={translate("customers.address.county") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="address_zip_code" name="address_zip_code"
                                        value={request.address_zip_code}
                                        label={translate("customers.address.zip_code") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ValidatedInput type="text" id="address_municipality" name="address_municipality"
                                        value={request.address_municipality}
                                        label={translate("customers.address.municipality") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="address_state" name="address_state"
                                        value={request.address_state}
                                        label={translate("customers.address.state") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <br/><Divider/><br/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><strong>{translate("customers.contact.title")}</strong></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="contact_email" name="contact_email"
                                        value={request.contact_email}
                                        label={translate("customers.contact.email") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={8}>
                                    <ValidatedInput type="text" id="contact_phone" name="contact_phone"
                                        value={request.contact_phone}
                                        label={translate("customers.contact.phone") as string}
                                        disabled={submitting}
                                        margin={margin} 
                                        validator={phoneValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ValidatedInput type="text" id="contact_extension" name="contact_extension"
                                        value={request.contact_extension}
                                        label={translate("customers.contact.extension") as string}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}