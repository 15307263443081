import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import { ExpedientTemplate, ExpedientTemplateRequest, ExpedientTemplateSegment } from "../model/ExpedientTemplate";
import Surface from "../components/Surface";
import { createExpedientTemplate, updateExpedientTemplate } from "../api/ExpedientTemplateAPI";
import { AppContext } from "../context/AppContext";
import BallotIcon from '@material-ui/icons/BallotTwoTone';
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ExpedientSettingsFields from "../expedients/ExpedientSettingsFields";
import ExpedientSettingsAddresses from "../expedients/ExpedientSettingsAddresses";
import ExpedientSettingsContacts from "../expedients/ExpedientSettingsContacts";
import ExpedientSettingsBankAccounts from "../expedients/ExpedientSettingsBankAccounts";
import ValidatedInput from "../components/ValidatedInput";
import SimpleSwitch from "../components/SimpleSwitch";
import ExpedientTemplateAddFieldsPopup from "./ExpedientTemplateAddFieldsPopup";
import { TenantField, TenantSegment } from "../model/TenantFieldCatalog";
import CfdisConfirmPopup from "../components/ConfirmationPopup";

interface ExpedientTemplatesCreateEditParams {
    edit: boolean;
    expedientTemplate?: ExpedientTemplate;
    onReturn(): any;
}

export default function ExpedientTemplatesCreateEdit(params: ExpedientTemplatesCreateEditParams) {
    const context = useContext(AppContext);
    const [edit] = useState<boolean>(params.edit);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [template, setTemplate] = useState<ExpedientTemplate>({} as ExpedientTemplate);
    const [submitting, setSubmitting] = useState<boolean>();
    const [typeField, setTypeField] = useState<"field" | "contact" | "address" | "bank_account">();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [request, setRequest] = useState<ExpedientTemplateRequest>({
        is_default: false,
        template_name: ""
    } as ExpedientTemplateRequest);


    useEffect(() => {
        if (edit && params.expedientTemplate) {
            setTemplateAndRequest(params.expedientTemplate);
        }
        // eslint-disable-next-line
    }, [context.session]);

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const setTemplateAndRequest = (response: ExpedientTemplate) => {
        let fields = setFieldsFromTemplate(response.fields_response);
        let contacts = setFieldsFromTemplate(response.contacts_response);
        let addresses = setFieldsFromTemplate(response.addresses_response);
        let bank_accounts = setFieldsFromTemplate(response.bank_accounts_response);

        setRequest({
            template_name: response.name,
            fields: fields,
            contacts: contacts,
            addresses: addresses,
            bank_accounts: bank_accounts,
            is_default: response.is_default
        } as ExpedientTemplateRequest);
        setTemplate(response);
    };

    const setFieldsFromTemplate = (fields: any | undefined) => {
        let response = [] as any;
        if (fields) {
            Object.keys(fields).forEach(key => {
                response.push({
                    id: fields[key].id,
                    priority: Number(key)
                } as ExpedientTemplateSegment);
            });
        }
        return response;
    };

    const onChangeName = (name: string, value: number) => {
        setRequest({ ...request, [name]: value });
    };

    const onSwitchDefault = () => {
        if (!request.is_default) {
            setOpenConfirmDialog(true);
        } else {
            setRequest({ ...request, is_default: !request.is_default });
        }
    };

    const closeConfirm = () => {
        setOpenConfirmDialog(false);
    };

    const onConfirm = () => {
        setOpenConfirmDialog(false);
        setRequest({ ...request, is_default: !request.is_default });
    };

    const onOpenAddFields = (type: "field" | "contact" | "address" | "bank_account") => {
        setTypeField(type);
    };

    const onDeleteField = (fieldId: string, type: string) => {
        switch (type) {
            case "field":
                setRequestAndTemplateDelete("fields", "fields_response", fieldId, request.fields, template.fields_response);
                break;
            case "address":
                setRequestAndTemplateDelete("addresses", "addresses_response", fieldId, request.addresses, template.addresses_response);
                break;
            case "contact":
                setRequestAndTemplateDelete("contacts", "contacts_response", fieldId, request.contacts, template.contacts_response);
                break;
            case "bank_account":
                setRequestAndTemplateDelete("bank_accounts", "bank_accounts_response", fieldId, request.bank_accounts, template.bank_accounts_response);
                break;
            default:
                break;
        }

    };

    const onAddFields = (fields: (TenantField | TenantSegment)[]) => {
        switch (typeField) {
            case "field":
                setRequestAndTemplate("fields", "fields_response", fields, request.fields, template.fields_response);
                break;
            case "address":
                setRequestAndTemplate("addresses", "addresses_response", fields, request.addresses, template.addresses_response);
                break;
            case "contact":
                setRequestAndTemplate("contacts", "contacts_response", fields, request.contacts, template.contacts_response);
                break;
            case "bank_account":
                setRequestAndTemplate("bank_accounts", "bank_accounts_response", fields, request.bank_accounts, template.bank_accounts_response);
                break;
            default:
                break;
        }
        setTypeField(undefined);
    };

    const setRequestAndTemplateDelete = (name: string, name_response: string, fieldId: string,
        requestFields: any[] | undefined, templateFields: any | undefined) => {
        let temp = {} as any;
        let tempRequest = requestFields ? requestFields.filter(field => field.id !== fieldId) : [];
        let i = tempRequest.length - 1;

        setRequest({ ...request, [name]: tempRequest });
        i = 0;
        if (templateFields) {
            Object.values(templateFields).filter(field => JSON.parse(JSON.stringify(field)).id !== fieldId).forEach(field => {
                temp[i] = field;
                i++;
            });
        }
        setTemplate({ ...template, [name_response]: temp });
    };

    const setRequestAndTemplate = (name: string, name_response: string, fields: (TenantField | TenantSegment)[],
        requestFields: any[] | undefined, templateFields: any | undefined) => {
        let temp = {} as any;
        let tempRequest = requestFields || [];
        let i = tempRequest.length > 0 ? Math.max(...tempRequest.map(item => item.priority)) : tempRequest.length;
        fields.forEach(field => {
            tempRequest.push({
                id: field.id,
                priority: i + 1
            } as ExpedientTemplateSegment);
            i++;
        });
        setRequest({ ...request, [name]: tempRequest });
        i = 0;
        if (templateFields) {
            Object.values(templateFields).forEach(field => {
                temp[i] = field;
                i++;
            });
        }
        fields.forEach(field => {
            temp[i] = field;
            i++;
        });
        setTemplate({ ...template, [name_response]: temp });
    };

    const onSave = () => {
        setSubmitting(true);
        let validate = validateRequest();

        if (!areValidFieldsPriorities()) {
            return;
        }

        if (validate !== "") {
            setSubmitting(false);
            setWarning(validate);
            return;
        }
        createExpedientTemplate(context.session!.tenant!.id, request).then((response) => {
            setTemplate(response);
            params.onReturn();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onEdit = (template: ExpedientTemplate | undefined) => {
        if (!template) return;
        let validate = validateRequest();

        if (!areValidFieldsPriorities()) {
            return;
        }

        if (validate !== "") {
            setWarning(validate);
            return;
        }
        updateExpedientTemplate(context.session!.tenant!.id, template.id, request).then((response) => {
            setTemplate(response);
            params.onReturn();
        }).catch((error) => {
            setError(error.message);
        });
    };

    const getIdsFromRequest = () => {
        let response = [] as string[];
        switch (typeField) {
            case "field":
                if (request.fields) {
                    response = request.fields.map(field => field.id);
                }
                break;
            case "contact":
                if (request.contacts) {
                    response = request.contacts.map(field => field.id);
                }
                break;
            case "address":
                if (request.addresses) {
                    response = request.addresses.map(field => field.id);
                }
                break;
            case "bank_account":
                if (request.bank_accounts) {
                    response = request.bank_accounts.map(field => field.id);
                }
                break;
        }
        return response;
    };

    const onCancel = () => {
        params.onReturn();
    };

    const validateRequest = () => {
        let message = "";
        if (request.template_name === "") {
            message = translate("expedient_template.messages_invalid_template.name") as string;
        }
        return message;
    };

    const areValidFieldsPriorities = () => {
        if (request.fields) {
            let dupplied = [];
            const temp = [...request.fields.map(key => key.priority)].sort();

            if (temp.find(item => item <= 0) !== undefined) {
                setWarning(translate("expedients.fields.priority_cero") as string);
                return false;
            }
            for (let i = 0; i < temp.length; i++) {
                if (temp[i + 1] === temp[i]) {
                    dupplied.push(temp[i]);
                }
            }
            if (dupplied.length > 0) {
                setWarning(translate("expedients.fields.priority_dupplied") as string);
                return false;
            }
        }

        return true;
    }

    const onUpdatePriorityField = (value: number, fieldId: string) => {
        if (value !== undefined) {
            let tempRequest = request.fields || [];
            tempRequest.forEach(field => {
                if (field.id === fieldId) {
                    field.priority = value;
                }
            });
            setRequest({ ...request });
        }
    }

    const getFieldPriority = (fieldId: string) => {
        let priority = "";
        if (fieldId) {
            let tempRequest = request.fields || [];
            tempRequest.forEach(field => {
                if (field.id === fieldId) {
                    priority = `${field.priority}`;
                }
            });
        }
        return priority;
    }

    return (
        <Surface
            title={edit ? translate("expedient_template.title_edit") as string : translate("expedient_template.title_create") as string}
            subtitle={translate("expedients.subtitle") as string}
            icon={<BallotIcon />}
            backButton
            onAction={params.onReturn}
            className="PaperPagination" >
            <Grid item xs={12}>
                <Box px={1} pt={2}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <ValidatedInput margin="dense" type="text" id="template_name" name="template_name"
                                value={request.template_name} label={translate("expedient_template.columns.name") as string}
                                required={true} disabled={submitting}
                                onValueChanged={onChangeName} />
                        </Grid>
                        <Grid item>
                            {context.isGrantedAny(["ExpedientTemplateUpdate"]) &&
                                <SimpleSwitch
                                    value="is_default"
                                    label={translate("expedient_template.switch_default") as string}
                                    checked={request.is_default}
                                    onChanged={onSwitchDefault}
                                    placement="end"
                                    disabled={submitting} />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <ExpedientSettingsFields segments={template?.fields_response} view="templates" onAdd={() => onOpenAddFields("field")} onGetPriority={getFieldPriority} onUpdatePriority={onUpdatePriorityField} onDelete={(fieldId) => onDeleteField(fieldId, "field")} />
            <ExpedientSettingsAddresses template={template} view="templates" onAdd={() => onOpenAddFields("address")} onDelete={(fieldId) => onDeleteField(fieldId, "address")} />
            <ExpedientSettingsContacts template={template} view="templates" onAdd={() => onOpenAddFields("contact")} onDelete={(fieldId) => onDeleteField(fieldId, "contact")} />
            <ExpedientSettingsBankAccounts template={template} view="templates" onAdd={() => onOpenAddFields("bank_account")} onDelete={(fieldId) => onDeleteField(fieldId, "bank_account")} />
            <Grid item xs={12}>
                <Box px={1} pt={2}>
                    <Grid container justify="flex-end" alignItems="flex-end" spacing={1}>
                        <Grid item xs="auto">
                            <Button onClick={onCancel} variant="outlined" color="primary" size="large" disabled={submitting}>
                                {translate("buttons.close")}
                            </Button>
                        </Grid>
                        {context.isGrantedAny(["ExpedientTemplateUpdate"]) &&
                            <Grid item xs="auto">
                                <Button onClick={edit && params.expedientTemplate ? () => onEdit(params.expedientTemplate) : onSave} variant="contained" color="primary" size="large" disabled={submitting}>
                                    {translate("buttons.save")}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {typeField &&
                <ExpedientTemplateAddFieldsPopup tenantId={context.session!.tenant!.id} typeField={typeField} onSave={onAddFields} onClose={() => { setTypeField(undefined); }} ids={getIdsFromRequest()} />
            }
            {openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onConfirm}
                    onClose={closeConfirm}
                    title={translate("expedient_template.confirm_default.title") as string}
                    message={translate("expedient_template.confirm_default.message") as string}
                    secondary={translate("expedient_template.confirm_default.secondary") as string}
                    button={translate("buttons.accept") as string} />
            )}
        </Surface>
    );
}
