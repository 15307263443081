import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Typography, Tabs, Tab, Grid } from "@material-ui/core";
import EmployeeIcon from "@material-ui/icons/PeopleAlt";
import translate from "../i18n/Translator";
import { getEmployee } from "../api/EmployeeAPI";
import { getEmployee as getProviderEmployee } from "../api/ProviderEmployeeAPI";
import { Employee } from "../model/Employee";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import EmployeeCard from "./EmployeeCard";
import EmployeePayrolls from "./EmployeePayrolls";

type EmployeeTabName = "details" | "cfdis";

interface EmployeeTab {
    name: EmployeeTabName;
    title: string;
    actions?: string[];
}

export default function EmployeeOverview({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;
    const qs = queryString.parse(window.location.search);

    const allTabs = [
        {
            name: "details",
            title: translate("employees.details" as string)
        },
        {
            name: "cfdis",
            title: translate("employees.cfdis" as string),
            actions: ["PayrollsRead", "ProviderPayrollsRead"]
        }
    ] as EmployeeTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [status, setStatus] = useState<string>("loading");
    const [employee, setEmployee] = useState<Employee>();
    const [tabs, setTabs] = useState<EmployeeTab[]>([]);

    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [value, setValue] = useState<number>(tabQs);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        // let url = window.location.pathname + "?" + queryString.stringify(qs);
        // history.push(url);

        setValue(newValue);
    };

    const promiseGet = () => {
        if (providerId) {
            return getProviderEmployee(tenantId, providerId, match.params.employeeId);
        }
        return getEmployee(tenantId, match.params.employeeId);
    };

    const load = () => {
        setStatus("loading");
        promiseGet().then((employee) => {
            setEmployee(employee);
            setTabs(allTabs.filter((tab) => !tab.actions || context.isGrantedAny(tab.actions)));
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, match.params.employeeId]);

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line 
    }, [qs.tab, tabs]);

    const tabContent = (index: number) => {
        if (!employee) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return <EmployeeCard employee={employee} />;
            case "cfdis":
                return <EmployeePayrolls tenantId={tenantId} providerId={providerId} employee={employee} />;
            default:
                return <div></div>;
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !employee) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface title={employee.name}
            subtitle={translate("employees.documents_full", { "number": employee.documents })}
            backButton
            icon={(
                <EmployeeIcon />
            )}
            className="PaperPagination PaperTabContainer">
            <Tabs value={value} onChange={handleChange} aria-label={translate("employees.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: EmployeeTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: EmployeeTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            </Grid>
        </Surface>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`employee-tabpanel-${index}`}
            aria-labelledby={`employee-tab-${index}`}
            {...other}
        >
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        component: "a",
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
        },
        id: `employees-tab-${index}`,
        'aria-controls': `employee-tabpanel-${index}`,
    };
}