import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { RequisitionTypesIcon } from "../components/Icons";

import { getRequisitionType, metadata, updateRequisitionTypeTemplate } from "../api/RequisitionTypeCatalogAPI";
import translate from "../i18n/Translator";
import { RequisitionTypeCatalog } from "../model/RequisitionTypeCatalog";
import Progress from "../components/Progress";
import Surface from "../components/Surface";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import WorkflowTemplateForm from "../workflove/WorkflowTemplateForm";
import { UpdateWorkflowRequest } from "../workflove/Model";
import { SuccessSnackbar } from "../components/Snackbars";

export default function RequisitionTypeTemplateForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [success, setSuccess] = useState<string>();
    const [shippintTemplateIds, setShippintTemplateIds] = useState<string[]>([]);
    const [shippintTemplateNames, setShippintTemplateNames] = useState<string[]>([]);
    const [shippingTemplateWorkfloveId, setShippingTemplateWorkfloveId] = useState<string>();
    const [workfloveIds, setWorkfloveIds] = useState<string[]>([]);
    const [workfloveNames, setWorkfloveNames] = useState<string[]>([]);
    const [requisitionType, setRequisitionType] = useState<RequisitionTypeCatalog>();

    useEffect(() => {
        setStatus("loading");
        Promise.all([
            metadata(tenantId),
            getRequisitionType(tenantId, match.params.requisitionTypeId)
        ]).then(([requisitionTypeMetadata, requisitionType]) => {
            setShippintTemplateIds(requisitionTypeMetadata.shipping_templates.map(st => st.id));
            setShippintTemplateNames(requisitionTypeMetadata.shipping_templates.map(st => st.name));

            const shippingTemplate = requisitionTypeMetadata.shipping_templates.find((el) => el.id === requisitionType.shipping_template_id);
            setShippingTemplateWorkfloveId(shippingTemplate?.workflove_id);

            setWorkfloveIds(requisitionTypeMetadata.workfloves.map(el => el.id));
            setWorkfloveNames(requisitionTypeMetadata.workfloves.map(el => el.name));
            setRequisitionType(requisitionType);

            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        })
    }, [tenantId, match.params.requisitionTypeId]);

    const getPromise = (request: UpdateWorkflowRequest) => {
        return updateRequisitionTypeTemplate(tenantId, requisitionType?.id || "-", request)
    };

    const onComplete = (requisitionType: RequisitionTypeCatalog) => {
        setSuccess(translate("requisitions.types.edited_template") as string);
        setRequisitionType(requisitionType);
    }

    if (status === "loading" || !requisitionType) {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={6} xl={5}>
                    <Surface
                        title={translate("requisitions.types.template")}
                        subtitle={requisitionType?.name}
                        icon={<RequisitionTypesIcon />}
                        className="FormSurface"
                        backButton
                        backTo="/requisitions/types">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={12}>
                                <ValidatedInput type="text" id="name" name="name"
                                    value={requisitionType.name}
                                    label={translate("requisitions.types.form.name") as string}
                                    required
                                    disabled
                                    margin={margin} />
                                <ValidatedInput type="text" id="workflove_id" name="workflove_id"
                                    value={requisitionType.workflove_id}
                                    label={translate("workflows.single") as string}
                                    options={workfloveIds} optionLabels={workfloveNames}
                                    required
                                    disabled
                                    margin={margin} />
                                <ValidatedInput type="text" id="shipping_template_id" name="shipping_template_id"
                                    value={requisitionType.shipping_template_id}
                                    label={translate("shipping_templates.single") as string}
                                    options={shippintTemplateIds} optionLabels={shippintTemplateNames}
                                    required
                                    disabled
                                    margin={margin} />
                            </Grid>
                            {requisitionType && requisitionType.id && requisitionType.workflove_id && shippingTemplateWorkfloveId && (
                                <Grid item xs={12}>
                                    <WorkflowTemplateForm
                                        product="REQUISITIONS"
                                        tenantId={tenantId}
                                        workflowId={requisitionType.workflove_id}
                                        templateId={shippingTemplateWorkfloveId}
                                        externalId={requisitionType.id}
                                        id={requisitionType.id}
                                        getPromise={getPromise}
                                        onComplete={onComplete}
                                        phaseTranslator={(externalId => `requisitions.status.${externalId}`)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
        </Grid>
    );
}