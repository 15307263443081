import React, { useState, useEffect, useContext } from "react";
import { Redirect, Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Typography, Tabs, Tab, Grid, Fab, IconButton, Chip, Button, ListItemText } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Progress from "../components/Progress";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import {
    Cfdi, isAllowToAuthorize, isAllowToDelete, isSenderAllowToDelete, isAllowToReject, isAllowToSend, AuthorizedAmountResponse, CfdiValidation, AuthorizedAmountRequest, inVerify, inAuthorized, inPendingPayment, ReceptionDateValidationResponse,
    AdvanceMetadata, CfdisQueryParams, StatusesCfdi, StatusGroup_SENDING,
} from "../model/Cfdi";
import { WorkflowRoutingRequest, DeleteCfdiRequest, AdditionalAmountRequest, CfdiWorkflowRequest, CfdiUpdatePaymentDeadlineRequest } from "../model/TenantCfdi";
import { ExportCfdisResponse, CfdiPostponeRequest } from "../model/TenantCfdi";
import { getCfdi, updateAuthorizedAmount, deleteCfdi, deleteAdditionalAmountCfdi, rejec, approve, reactivatePayment, updateWorkflow, markPdfCorrect, associateAdvancePayments, restrictedToAssociateAdvancePayments, updatePaymentDeadline } from "../api/TenantCfdiApi";
import { getHistory, updateAdditionalAmountCfdi, cancelCfdi, exportCfdiFiles, postponePayment, changePdfFile, exportValidationsPdfCfdis, desassociateAdvancePayments } from "../api/TenantCfdiApi";
import { SendCfdisRequest, ProviderCfdiOverdueResponse, ProviderAllowedToSendResponse, ProviderExpedienteValidationResponse, CurrentPartiallityData } from "../model/ProviderCfdi";
import { validateGenerateInMembership } from "../api/ProviderMembershipTaxStampApi";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import CfdiHistoryTab from "./CfdiHistoryTab";
import CfdiFilesTab from "./CfdiFilesTab";
import CfdiPaymentsTab from "./CfdiPaymentsTab";
import CfdiDetails from "./CfdiDetails";
import CfdiValidations from "./CfdiValidations";
import CfdiCreditNotes from "./CfdiCreditNotes";
import CfdiRelatedCfdis from "./CfdiRelatedCfdis";
import CfdiAdvanceTab from "./CfdiAdvanceTab";
import Surface from "../components/Surface";
import DownloadIcon from '@material-ui/icons/GetApp';
import AlertIcon from '@material-ui/icons/ReportProblem';

import ProviderCfdiMenu from '../cfdis/menus/ProviderCfdiMenu';
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import TenantCfdiMenu from "../cfdis/menus/TenantCfdiMenu";
import AuthorizeRejectPopup from "../components/AuthorizeRejectPopup";
import RejectIcon from '@material-ui/icons/ThumbDown';
import ToAuthorizeIcon from '@material-ui/icons/ThumbUp';
import SendCfdiIcon from '@material-ui/icons/SendTwoTone';
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import { sendCfdis, getAllowedToSend } from "../api/ProviderCfdiApi";
import CfdiDeletePopup from "../cfdis/popups/CfdiDeletePopup";
import CfdiAdditionalAmountPopUp from "../cfdi/CfdiAdditionalAmountPopUp";
import ProviderVerificationOneCfdiResult from "../cfdis/popups/ProviderVerificationOneCfdiResult";
import { ProrateCfdi } from "../model/Prorate";
import { CfdiHistories } from "../model/CfdiHistory";
import { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { ExtendedFields } from "../model/ExtendedField";
import TotalAuthorizePopup from "../cfdi/TotalAuthorizePopup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import DialogPopup from "../components/DialogPopup";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import ReactHtmlParser from "html-react-parser";
import PostponePaymentPopup from "../payments/PostponePaymentPopup";
import CfdiAsignWorkflowPopUp from "../cfdi/CfdiAsignWorkflowPopUp";
import moment from "moment";
import CartaPorteTab from "./CartaPorteTab";
import CartaPorteTab20 from "./CartaPorteTab20";
import CartaPorteTab30 from "./CartaPorteTab30";
import CartaPorteTab31 from "./CartaPorteTab31";
import PayableDocumentBeforeSendTab from "../payable_documents/PayableDocumentBeforeSendTab";
import { Workflow } from "../model/Workflow";
import { isRoleOrParent } from "../model/Role";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import UpdateStatusRefundPopup from "../cfdis/popups/UpdateStatusRefundPopup";
import { TransitionRequest } from "../model/Refund";
import { forwardRefund, backwardRefund, cancelRefund, deleteRefund } from "../api/RefundPayableDocumentApi";
import RefundMenu from "../Refunds/RefundMenu";
import RefundFormPopup from "../Refunds/RefundFormPopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import CfdiChangePdf from "./CfdiChangePdf";
import DeleteCfdiPopup from "../cfdis/popups/DeleteCfdiPopup";
import { LaunchIcon, Users } from "../components/Icons";
import { DesktopAccessDisabledIcon } from "../components/Icons";
import CfdiAdvancePaymentsTab from './CfdiAdvancePaymentsTab';
import AdvancePaymentPopup from "../advancePayments/AdvancePaymentPopup";
import { AssociateAdvancePayments } from "../cfdis/popups/AssociateAdvancePayments";
import { AdvancePayment } from '../model/AdvancePayment';
import OwnersTooltip from "../components/OwnersTooltip";
import UpdatePaymentDeadlinePopup from "../cfdis/popups/UpdatePaymentDeadlinePopup";
import { getTranslateStatus } from "./CfdiTranslateStatusUtils";

interface CfdiTab {
    name: string;
    title: string;
    actions?: string[];
}

export default function CfdiOverview({ match }: RouterParams) {
    const history = useHistory();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openUpdateTotalAuthorized, setOpenUpdateTotalAuthorized] = useState<boolean>(false);
    const [openDeleteAdditionalAmount, setOpenDeleteAdditionalAmount] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [openAdditionalAmountDialog, setOpenAdditionalAmountDialog] = useState<boolean>(false);
    const [advancePaymentsModal, setAdvancePaymentsModal] = useState<boolean>(false);
    const [openDesassociateAdvancePayment, setOpenDesassociateAdvancePayment] = useState<boolean>(false);
    const [selectedAdvancedPayment, setSelectedAdvancedPayment] = useState<string>();

    const context = useContext(AppContext);
    const [redirect, setRedirect] = useState<string>();
    const [executor, setExecutor] = useState<"send-to-verify" | "reject" | "reject-review" | "verify">();
    const [executorRefund, setExecutorRefund] = useState<"send" | "authorize" | "cancel" | "reject">();
    const [status, setStatus] = useState<string>("loading");
    const qs = queryString.parse(window.location.search);
    const referer = typeof qs["referer"] === "string" ? qs["referer"] as string : undefined;
    const cfdiSourceId = qs["source-id"] ?? "";
    const specificPaymentTab = (qs["tab"] ?? "") === "advance_payments";

    const pendingPaymentOwner = context.isGrantedAny(["AdminCFDIsRead", "CxpCFDIsRead"]) ? true : false;
    const isCfdiTenant = isRoleOrParent(context.session!.role, "owner")
        || isRoleOrParent(context.session!.role, "supervisor")
        || isRoleOrParent(context.session!.role, "cxp")
        || isRoleOrParent(context.session!.role, "treasurer")
        || isRoleOrParent(context.session!.role, "receptor_cfdi");
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const isTenant = !context.session!.role.provider;
    const isReceptor = isRoleOrParent(context.session!.role, "receptor_cfdi");
    const [partiallityData, setPartiallityData] = useState<CurrentPartiallityData>();
    const [openPartiallityDialog, setOpenPartiallityDialog] = useState<boolean>(false);
    const preguide = `/integrations/preguides/` + qs["preguide"] + `/details?` + queryString.stringify(qs);
    const [isUpdatePaymentDeadline, setIsUpdatePaymentDeadline] = useState<boolean>(false);
    const [openConfirmCancelSat, setOpenConfirmCancelSat] = useState<boolean>(false);

    const [messageDelete, setMessageDelete] = useState<string>();

    const [statusesCfdi, setStatusesCfdi] = useState<StatusesCfdi>();

    delete qs['referer'];

    const getBackTo = (reference: string, queryString: string) => {
        if (reference && reference.includes("expense-account")) {
            let referer = reference.split("-");
            return `/cfdis/${referer[2]}/details_related?referer=to-send`;
        }
        return "";
    }

    const backTo = ((): string | undefined => {

        if (!!cfdiSourceId) {
            delete qs['source-id']
            delete qs["from-advance-payment"];
            const searchHandler = queryString.parse(window.location.search);
            if (!!searchHandler["referer"]) {
                qs["referer"] = searchHandler["referer"] as string
            }
            return `/cfdis/${cfdiSourceId}/details?${queryString.stringify(qs)}`;
        } if (specificPaymentTab && referer?.includes("payment-details")) {
            return `${decodeURI(referer)}?${typeof qs["origin"] === "string" ? `origin=${decodeURI(qs["origin"] as string)}` : ""}`;
        }

        switch (referer) {
            case "to-send":
                return "/provider/cfdis/to-send?" + queryString.stringify(qs);
            case "to_send_refunds":
                return "/refunds/to-send?" + queryString.stringify(qs);
            case "sent":
                return "/provider/cfdis/sent?" + queryString.stringify(qs);
            case "archive":
                return `/${isCfdiTenant || isReaderOnly ? "tenant" : "provider"}/cfdis/${isCfdiTenant || isReaderOnly ? "archive-tenant" : "archive-provider"}?` + queryString.stringify(qs);
            case "archive_refunds":
                return "/refunds/archive?" + queryString.stringify(qs);
            case "to-authorize":
                return `/tenant/cfdis/${isReceptor ? "receptor-to-authorize" : "owner-to-authorize"}?` + queryString.stringify(qs);
            case "to_authorize_refunds":
                return "/refunds/to_authorize?" + queryString.stringify(qs);
            case "pending-payment":
                return `/tenant/cfdis/${pendingPaymentOwner ? "pending-payment-owner" : "pending-payment-by-receptor"}?` + queryString.stringify(qs);
            case "to-verify":
                return `/tenant/cfdis/owner-to-verify?` + queryString.stringify(qs);
            case "postpone":
                return `/tenant/cfdis/postpone?` + queryString.stringify(qs);
            case "preguide":
                return preguide;
            case "expenses-accounts":
                return `/reports/expenses-accounts?` + queryString.stringify(qs);
            case "tax_stamp":
                return "/tax-stamp";
            case "report-advance":
                return `/reports/advance-payments?` + queryString.stringify(qs);
            default:
                return getBackTo(referer!, `${queryString.stringify(qs)}&from-advance-payment=true`);
        }
    })();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [cfdiValidations, setCfdiValidations] = useState<CfdiValidation[]>();
    const [cfdiData, setCfdiData] = useState<Cfdi>();
    const [openConfirmDialogDelete, setOpenConfirmDialogDelete] = useState<boolean>(false);
    const [postpone, setPostpone] = useState<boolean>(false);
    const [onAssociateAdvancePaymentsModal, setOnAssociateAdvancePaymentsModal] = useState<boolean>(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));

    const allTabs = [
        {
            name: "details",
            title: translate("cfdis.details.title" as string)
        },
        {
            name: "files",
            title: translate("cfdis.documents.title" as string)
        },
        {
            name: "advances",
            title: translate("cfdis.advances.title" as string)
        },
        {
            name: "carta_porte",
            title: translate("cfdis.carta_porte.title") as string
        },
        {
            name: "carta_porte_20",
            title: translate("cfdis.carta_porte.title") as string
        },
        {
            name: "carta_porte_30",
            title: translate("cfdis.carta_porte.title") as string
        },
        {
            name: "carta_porte_31",
            title: translate("cfdis.carta_porte.title") as string
        },
        {
            name: "payments",
            title: translate("payment.title") as string
        },
        {
            name: "credit_notes",
            title: translate("cfdis.credit_notes.title") as string
        },
        {
            name: "advance_payments",
            title: translate("cfdis.advance_payments.title_tab") as string
        },
        {
            name: "related_cfdis",
            title: translate("cfdis.related_cfdis.title") as string
        },
        {
            name: "validations",
            title: translate("cfdis.validations.title") as string
        },
        {
            name: "history",
            title: translate("cfdis.history.title") as string
        },
        {
            name: "before_send",
            title: translate("cfdis.before_send.title") as string
        }
    ] as CfdiTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [cfdi, setCfdi] = useState<Cfdi>();
    const [tabs, setTabs] = useState<CfdiTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [cfdiHistory, setCfdiHistory] = useState<CfdiHistories>({} as CfdiHistories);
    const [page] = useState<number>(initialPage);
    const [pageSize] = useState<number>(initialPageSize);
    const classes = useStyles();
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const isValidator = isRoleOrParent(context.session!.role, "validator");
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [openConfirmDialogReactivate, setOpenConfirmDialogReactivate] = useState<boolean>(false);
    const [openSelectWorkflow, setOpenSelectWorkflow] = useState<boolean>(false);
    const [providerWorkflows, setProviderWorkflows] = useState<Workflow[]>();
    const [openConfirmChangeWorkflow, setOpenConfirmChangeWorkflow] = useState<boolean>(false);
    const [workflowSelected, setWorkflowSelected] = useState<string>();
    const [confirmChangeWorkflow, setConfirmChangWorkflow] = useState<boolean>(false);
    const [showRefundForm, setShowRefundForm] = useState<boolean>(false);
    const [openRefundDelete, setOpenRefundDelete] = useState<boolean>(false);
    const isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const [changePdf, setChangePdf] = useState<boolean>(false);
    const [checkPdf, setCheckPdf] = useState<boolean>(false);
    const [hasOverdue, setHasOverdue] = useState<boolean>(false);
    const [exedientValidation, setExedientValidation] = useState<boolean>(false);
    const [allowedToSend, setAllowedToSend] = useState<ProviderAllowedToSendResponse>({
        expedient: {
            witoutExpedient: false,
            expiredExpedient: false,
            block: false
        } as ProviderExpedienteValidationResponse,
        reception_date: {
            valid: false,
            tenant_side: false
        } as ReceptionDateValidationResponse,
        cfdi_overdue: {
            cfdi_payment_overdue: 0,
            payments_with_pending_cfdi: 0,
            max_payment_cfdi_overdue: 0,
            block: false
        } as ProviderCfdiOverdueResponse
    } as ProviderAllowedToSendResponse);

    const load = () => {
        setStatus("loading");
        let offset = getOffset(page, pageSize);
        getCfdi(context.session!.tenant!.id, match.params.cfdiId).then((cfdi) => {
            setProviderWorkflows((cfdi.metadata.provider_workflows || []).filter(item => item.status === "AVAILABLE"));
            if (!cfdi.metadata.company_id || ((cfdi.metadata.type !== "CREDIT_NOTE" && cfdi.metadata.type !== "PAYMENT_CFDI") && (!cfdi.metadata.workflow_id || cfdi.metadata.workflow_id === "") && cfdi.metadata.provider_workflows && cfdi.metadata.provider_workflows.length > 1)) {
                setCfdi(cfdi);
                setOpenSelectWorkflow(true);
                setStatus("loaded");
            } else {
                getHistory(context.session!.tenant!.id, match.params.cfdiId, pageSize, offset).then((response) => {
                    setCfdiHistory(response);
                }).catch((errors) => {
                    setStatus(errors.message);
                }).finally(() => {
                    setCfdi(cfdi);
                    loadTabs(cfdi);
                    setStatus("loaded");
                });
            }
            if (isProvider) {
                getAllowedToSend(context.session!.tenant!.id, context.session!.provider!.id).then((response) => {
                    setAllowedToSend(response);
                }).catch((error) => {
                    setError(error.message);
                });
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const loadTabs = (cfdi: Cfdi) => {
        let userTabs = [];
        let noTabCN = (!["CFDI", "FOREIGN"].includes(cfdi.type) ) || cfdi.metadata.type === 'CREDIT_NOTE';
        let hasTabPayments = cfdi.metadata.type === 'INVOICE' || cfdi.metadata.type === 'PAYMENT_CFDI' || cfdi.type === 'EXPENSES_ACCOUNT' || cfdi.type === 'HONORARIUM';
        let isNotAdvance = cfdi.metadata.type !== 'ADVANCED';

        if ("FOREIGN" === cfdi.type || "REFUND" === cfdi.type || "FOREIGN_CREDIT_NOTE" === cfdi.type) {
            let endTabs = allTabs.filter((tab) => "validations" !== tab.name && "related_cfdis" !== tab.name && (!tab.actions || context.isGrantedAny(tab.actions)));
            if("FOREIGN" !== cfdi.type){
                userTabs = endTabs.filter((tab) => "credit_notes" !== tab.name);
            } else {
                userTabs = endTabs;
            }
        } else if (noTabCN && hasTabPayments) {
            userTabs = allTabs.filter((tab) => tab.name !== "credit_notes" && (!tab.actions || context.isGrantedAny(tab.actions)));
        } else if (noTabCN) {
            userTabs = allTabs.filter((tab) => tab.name !== "credit_notes" && tab.name !== "payments" && (!tab.actions || context.isGrantedAny(tab.actions)));
        } else if (hasTabPayments) {
            userTabs = allTabs.filter((tab) => !tab.actions || context.isGrantedAny(tab.actions));
        } else {
            userTabs = allTabs.filter((tab) => tab.name !== "payments" && (!tab.actions || context.isGrantedAny(tab.actions)));
        }
        if (cfdi.cfdi && (cfdi.cfdi.cfdis_relacionados === undefined)) {
            userTabs = userTabs.filter((tab) => tab.name !== "related_cfdis");
        }
        if (!cfdi.cfdi?.complementos.carta_porte) {
            userTabs = userTabs.filter((tab) => tab.name !== "carta_porte");
        }
        if (!cfdi.cfdi?.complementos.carta_porte20) {
            userTabs = userTabs.filter((tab) => tab.name !== "carta_porte_20");
        }
        if (!cfdi.cfdi?.complementos.carta_porte30) {
            userTabs = userTabs.filter((tab) => tab.name !== "carta_porte_30");
        }
        if (!cfdi.cfdi?.complementos.carta_porte31) {
            userTabs = userTabs.filter((tab) => tab.name !== "carta_porte_31");
        }
        if (!cfdi.metadata.before_send || cfdi.metadata.before_send.length === 0) {
            userTabs = userTabs.filter((tab) => tab.name !== "before_send");
        }
        if (!cfdi.metadata.xml_url) {
            userTabs = userTabs.filter((tab) => tab.name !== "files");
        }
        if (isNotAdvance) {
            userTabs = userTabs.filter((tab) => tab.name !== "advances");
        }

        if ((cfdi?.metadata?.payment_info?.advance_payments ?? []).length <= 0) {
            userTabs = userTabs.filter((tab) => tab.name !== "advance_payments");
        }

        if("FOREIGN_CREDIT_NOTE" === cfdi.type){
            userTabs = userTabs.filter((tab) => tab.name !== "payments");
        }

        setTabs(userTabs);
    };

    useEffect(load, [match.params.cfdi, cfdiSourceId]);

    useEffect(() => {
        const items = localStorage.getItem("cfdi_status");
        if (items) {
            setStatusesCfdi(JSON.parse(items));
        } else {
            setStatusesCfdi({ items: [], total: 0 } as StatusesCfdi);
        }
    }, [match.params.cfdi]);


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        setValue(newValue);
    };

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line
    }, [qs.tab, tabs]);

    const onChangeTemplate = (extendedFields: ExtendedFields) => {
        if (!cfdi) return;
        setCfdi({ ...cfdi, metadata: { ...cfdi.metadata, extended_fields: extendedFields.items } })
        loadTabs(cfdi);
    };

    const onChangeTotalAuthorize = (response: AuthorizedAmountResponse) => {
        if (!cfdi) return;
        let temp = cfdi;
        temp.metadata.authorized_amount_response = response;
        setCfdi(temp);
        loadTabs(cfdi);
    };

    const onChangeProrate = (prorates: ProrateCfdi[]) => {
        if (!cfdi) return;
        let temp = cfdi;
        temp.metadata.cfdi_proration.prorates = prorates;
        setCfdi(temp);
        loadTabs(cfdi);
    };

    const onUpdated = (payableDocument: Cfdi) => {
        setCfdi({ ...cfdi, ...payableDocument });
    };

    const onUpdatedTotalAuthorized = () => {
        if ("ADVANCE_PAYMENT" === cfdi?.type || (cfdi?.cfdi && cfdi.cfdi.metodo_pago === "PUE")) {
            setWarning(translate("cfdis.total_authorize.invalid_for_partiallity") as string);
            return;
        }
        setOpenUpdateTotalAuthorized(true);
    };

    const onCloseUpdatedTotalAuthorized = () => {
        setOpenUpdateTotalAuthorized(false);
    };

    const onOpenDeleteAdditionalAmount = () => {
        setOpenDeleteAdditionalAmount(true);
    }

    const onDeleteAdditionalAmount = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        deleteAdditionalAmountCfdi(context.session!.tenant!.id, cfdi.id).then((response) => {
            load();
            setSuccess(translate("cfdis.additional_amount.success_delete") as string);
        }).catch((error) => {
            setError(error.message);
        });
        setOpenDeleteAdditionalAmount(false);
    }

    const onUpdateAdditionalAmount = (name: string, amount: number) => {
        setAnchorEl(null);
        if (!cfdi) return;
        updateAdditionalAmountCfdi(context.session!.tenant!.id, cfdi.id, { name: name, amount: amount } as AdditionalAmountRequest).then((response) => {
            load();
            setSuccess(translate("cfdis.additional_amount.success") as string);
        }).catch((error) => {
            setError(error.message);
        });
        setOpenAdditionalAmountDialog(false);
    }

    const onCloseDeleteAdditionalAmount = () => {
        setOpenDeleteAdditionalAmount(false);
    };

    const onCloseConfirmDesassociateAdvancePayment = () => {
        setOpenDesassociateAdvancePayment(false);
    };

    const onOpenConfirmDesassociateAdvancePayment = (advancePaymentId: string) => {
        setSelectedAdvancedPayment(advancePaymentId);
        setOpenDesassociateAdvancePayment(true);
    }

    const onDesassociateAdvancePayment = () => {
        setAnchorEl(null);
        if (!selectedAdvancedPayment || !cfdi) return;
        onCloseConfirmDesassociateAdvancePayment();
        desassociateAdvancePayments(context.session!.tenant!.id, cfdi.id, selectedAdvancedPayment).then((response) => {
            load();
            setSuccess(translate("advance_payments.desassociate.success") as string);
        }).catch((error) => {
            setError(error.message);
        });
        setOpenAdditionalAmountDialog(false);
    }

    const onCancelCreditNote = (creditNoteId: string) => {
        setRedirect(backTo);
    };

    const tabContent = (index: number) => {
        if (!cfdi) return;

        if (!context.session || !context.session.tenant) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return <CfdiDetails tenantId={context.session.tenant.id} cfdi={cfdi} onChangeTemplate={onChangeTemplate} onChangeProrate={onChangeProrate} onChangeTotalAuthorize={onChangeTotalAuthorize} onUpdated={onUpdated} onUpdatedTotalAuthorized={onUpdatedTotalAuthorized} onDeleteAdditionalAmount={onOpenDeleteAdditionalAmount} qs={"referer=" + referer + "&" + queryString.stringify(qs)} referer={referer}/>;
            case "files":
                return <CfdiFilesTab tenantId={context.session.tenant.id} providerId={context.session!.provider ? context.session!.provider.id : undefined} cfdiId={cfdi.id} metadata={cfdi.metadata} uuid={cfdi.cfdi ? cfdi.cfdi.timbre.uuid : undefined} isCfdiTenant={isCfdiTenant} onChangeFile={onOpenChangePdf} onCheckPdf={onOpenCheckPdf} />;
            case "advances":
                return <CfdiAdvanceTab tenantId={context.session.tenant.id} advanceMetadata={cfdi.metadata.advance_metadata || {} as AdvanceMetadata} subtotal={cfdi.cfdi ? cfdi.cfdi?.subtotal : 0} />;
            case "carta_porte":
                return <CartaPorteTab cfdi={cfdi} />;
            case "carta_porte_20":
                return <CartaPorteTab20 cfdi={cfdi} />;
            case "carta_porte_30":
                return <CartaPorteTab30 cfdi={cfdi} />;
            case "carta_porte_31":
                return <CartaPorteTab31 cfdi={cfdi} />;
            case "payments":
                return <CfdiPaymentsTab payments={cfdi.metadata.payment_info?.payments_response ?? []} />;
            case "history":
                return <CfdiHistoryTab tenantId={context.session.tenant.id} cfdiId={cfdi.id} history={cfdiHistory} />;
            case "validations":
                return <CfdiValidations tenantId={context.session.tenant.id} cfdi={cfdi} />;
            case "credit_notes":
                return <CfdiCreditNotes tenantId={context.session.tenant.id} cfdi={cfdi} onCancelCreditNote={onCancelCreditNote} />;
            case "related_cfdis":
                return <CfdiRelatedCfdis tenantId={context.session.tenant.id} relatedCfdis={cfdi.metadata.related_cfdis ? cfdi.metadata.related_cfdis : []} isProvider={isRoleOrParent(context.session!.role, "provider")} />;
            case "before_send":
                return (
                    <PayableDocumentBeforeSendTab items={cfdi.metadata.before_send || []} />
                );
            case "advance_payments":
                return <CfdiAdvancePaymentsTab cfdiId={cfdi.id} session={context?.session} cfdi={cfdi} advancePayments={cfdi.metadata.payment_info?.advance_payments ?? []} onDesassociate={onOpenConfirmDesassociateAdvancePayment} />;
            default:
                return <div></div>;
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !cfdi) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onDownloadCfdis = () => {
        setOpenBackdrop(true);
        exportCfdiFiles(context.session!.tenant!.id, cfdi.id)
            .then((response) => {
                if (response.url) {
                    setExportResult(response);
                }
            })
            .catch((error) => setError(error.message))
            .finally(() => {
                setOpenBackdrop(false);
                setAnchorEl(null);
            });
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onApprove = (comment: string) => {
        const message = translate("cfdis.to_authorize.messages.success_authorization", { "id": cfdi.identifier }) as string;
        sendToApprove(comment, `/tenant/cfdis/owner-to-verify/success/${message}?` + queryString.stringify(qs));
    }

    const onAuthorize = (comment: string) => {
        const message = translate("cfdis.to_authorize.messages.success_authorization", { "id": cfdi.identifier }) as string;
        let redirect = "";
        if (referer === "preguide") {
            redirect = preguide;
        } else {
            redirect = `/tenant/cfdis/${isCfdiTenant && context.isGranted("AdminCFDIsRead") ?
                "owner-to-authorize" : "receptor-to-authorize"}/success/${message}?` + queryString.stringify(qs);
        }

        sendToApprove(comment, redirect);
    };

    const onAuthorizeRefund = (comment: string) => {
        const message = translate("refunds.success_authorization", { "id": cfdi.identifier }) as string;
        const redirect = `/refunds/to_authorize/success/${message}?` + queryString.stringify(qs);
        onDoAuthorizeRefund(comment, redirect);
    };

    const onSendRefund = (comment: string) => {
        const message = translate("refunds.send_success", { "id": cfdi.identifier }) as string;
        const redirect = `/refunds/to_send/message/${message}?` + queryString.stringify(qs);
        onDoAuthorizeRefund(comment, redirect);
    };

    const sendToApprove = (comment: string, redirect: string) => {
        const request = { ids: [cfdi.id], stage_from: cfdi.metadata.cfdi_status_group, comments: comment } as WorkflowRoutingRequest;
        setOpenBackdrop(true);
        setExecutor(undefined);
        approve(context.session!.tenant!.id, request).then((result) => {
            if (result.cfdis_cancelled) {
                setCfdisCancelled(result.cfdis_cancelled.cfdis_invalid);
                load();
                return;
            }

            const response = result.items[0];

            if (response.error) {
                if (response.cause) {
                    setError(`${response.cause}`);
                } else {
                    setError(translate(`errors.codes.${response.error}`) as string);
                }
                return;
            }

            switch (response.report) {
                case "Provider_prorate_not_asigned":
                    if (context.isGranted("")) {
                        // por ahora no hay link de pantalla
                    } else {
                        setWarning(translate("cfdis.prorate.message_config") as string);
                    }
                    load();
                    return;
                case "Provider_not_prorated":
                    setWarning(translate(`cfdis.prorate.${response.report}`) as string);
                    load();
                    return;
                case "Prorrate_not_equals_subtotal":
                    setWarning(translate("cfdis.prorate.warning") as string);
                    load();
                    return;
                case "Shipping_data_missing":
                case "not_base_valid":
                    setCfdiData(response.payable_document);
                    setCfdiValidations(response.payable_document.metadata.validations);
                    return;
                default:
                    setRedirect(redirect);
                    break;
            }

        }).catch((error) => {
            setError(ReactHtmlParser(error.message));
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onReject = (comment: string) => {
        let redirect = "";
        let message = translate("cfdis.to_authorize.messages.success_rejection", { "id": cfdi.identifier }) as string;
        if (referer === "to-authorize") {
            redirect = `/tenant/cfdis/${isCfdiTenant && context.isGranted("AdminCFDIsRead") ? "owner-to-authorize" : "receptor-to-authorize"}/success/${message}?` + queryString.stringify(qs);
        } else if (referer === "to-verify") {
            redirect = `/tenant/cfdis/owner-to-verify/success/${message}?` + queryString.stringify(qs)
        } else if (referer === "pending-payment") {
            redirect = `/tenant/cfdis/${pendingPaymentOwner ? "pending-payment-owner" : "pending-payment-by-receptor"}/success/${message}?` + queryString.stringify(qs);
        } else if (referer === "archive") {
            redirect = `/provider/cfdis/${isCfdiTenant ? "archive-tenant" : "archive-provider"}/success/${message}?` + queryString.stringify(qs)
        } else if (referer === "postpone") {
            redirect = `/tenant/cfdis/postpone/success/${message}?` + queryString.stringify(qs);
        } else if (referer === "preguide") {
            redirect = preguide;
        }

        sentToReject(comment, redirect);
    }

    const sentToReject = (comment: string, redirect: string) => {
        const request = { ids: [cfdi.id], status_from: cfdi.metadata.status, comments: comment } as WorkflowRoutingRequest;
        setOpenBackdrop(true);
        setExecutor(undefined);
        rejec(context.session!.tenant!.id, request).then((response) => {
            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                load();
                return;
            }
            setRedirect(redirect);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDoRejectRefund = (reason: string, redirect: string) => {
        setExecutorRefund(undefined);
        if (!cfdi) return;
        setStatus("loading");
        backwardRefund(context.session!.tenant!.id, cfdi.id, { reason: reason } as TransitionRequest).then(refund => {
            setRedirect(redirect);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onCancelRefund = (reason: string) => {
        setExecutorRefund(undefined);
        if (!cfdi) return;
        setStatus("loading");
        cancelRefund(context.session!.tenant!.id, cfdi.id, { reason: reason } as TransitionRequest).then(refund => {
            setSuccess(translate("refunds.success_cancel", { "id": cfdi.identifier }) as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onDoAuthorizeRefund = (reason: string, redirect: string) => {
        setExecutorRefund(undefined);
        if (!cfdi) return;
        setStatus("loading");
        forwardRefund(context.session!.tenant!.id, cfdi.id, { reason: reason } as TransitionRequest).then(refund => {
            setRedirect(redirect);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onRejectRefund = (comment: string) => {
        let redirect = "";
        let message = translate("refunds.success_rejection", { "id": cfdi.identifier }) as string;
        switch (referer) {
            case "to_authorize_refunds":
                redirect = `/refunds/to_authorize/success/${message}?` + queryString.stringify(qs);
                break;
            case "archive_refunds":
                redirect = `/refunds/archive/success/${message}?` + queryString.stringify(qs);
                break;
            case "pending-payment":
                redirect = `/tenant/cfdis/${pendingPaymentOwner ? "pending-payment-owner" : "pending-payment-by-receptor"}?` + queryString.stringify(qs);
                break;
        }

        onDoRejectRefund(comment, redirect);
    }

    const onShowSendToVerify = () => {
        if (!cfdi) return;
        setExecutor("send-to-verify");
    }

    const onShowReject = () => {
        if (!cfdi) return;
        if ((cfdi.metadata.has_users_provider && inAuthorized(cfdi.metadata.cfdi_status_group)) || (inVerify(cfdi.metadata.cfdi_status_group) || inPendingPayment(cfdi.metadata.cfdi_status_group))) {
            setExecutor("reject");
        } else {
            setWarning(translate("cfdis.warning_reject") as string);
        }
    }

    const onShowVerify = () => {
        if (!cfdi) return;
        setExecutor("verify");
    }

    const onShowAuthorizeRefund = () => {
        if (!cfdi) return;
        setExecutorRefund("authorize");
    }

    const onShowRejectRefund = () => {
        if (!cfdi) return;
        setExecutorRefund("reject");
    }

    const onShowCancelRefund = () => {
        if (!cfdi) return;
        setExecutorRefund("cancel");
    }

    const onShowSendRefund = () => {
        if (!cfdi) return;
        setExecutorRefund("send");
    }

    const onCloseAddComment = () => {
        setExecutor(undefined);
    }

    const onCloseUpdateRefund = () => {
        setExecutorRefund(undefined);
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onSend = () => {
        if (!cfdi) return;
        setAnchorEl(null);
        if (allowedToSend.expedient.block) {
            setExedientValidation(true);
            return;
        }
        if (allowedToSend.cfdi_overdue.block) {
            setHasOverdue(true);
            return;
        }
        setOpenBackdrop(true);
        sendCfdis(context.session!.tenant!.id, context.session!.provider!.id, { status_group_from: StatusGroup_SENDING, cfdi_ids: [cfdi.id] } as SendCfdisRequest).then((response) => {
            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                load();
                return;
            } else if (response.cfdis.length > 0) {
                let r = response.cfdis.find(element => element.id === cfdi.id);
                if (r?.error) {
                    setError(r.error);
                    load();
                } else {
                    let rv = r?.metadata.validations;
                    if (rv?.find(v => !v.valid) === undefined) {
                        if (response.partiallity_data && isProvider) {
                            setOpenBackdrop(false);
                            setOpenPartiallityDialog(true);
                            setPartiallityData(response.partiallity_data);
                        } else {
                            onRedirectToSend();
                        }
                    } else {
                        load();
                        setCfdiData(response.cfdis.find(element => element.id === cfdi.id));
                        setCfdiValidations(response.cfdis.find(element => element.id === cfdi.id)?.metadata.validations);
                    }
                }
            } else {
                setWarning(translate("cfdis.to_send.messages.not_sent", { "id": cfdi.identifier }) as string);
                load();
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onClosePartiallities = () => {
        setOpenPartiallityDialog(false);
        setPartiallityData(undefined);
        onRedirectToSend();
    }

    const onRedirectToSend = () => {
        if (!cfdi) return;
        let message = translate("cfdis.to_send.messages.success_sending", { "id": cfdi.identifier }) as string;
        setRedirect(`/provider/cfdis/to-send/success/${message}?` + queryString.stringify(qs));
    }

    const onUpdateTotalAuthorized = (request: AuthorizedAmountRequest) => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenBackdrop(true);
        updateAuthorizedAmount(context.session!.tenant!.id, cfdi.id, request).then((response) => {
            load();
            setSuccess(translate("cfdis.total_authorize.success") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
        setOpenUpdateTotalAuthorized(false);
    }

    const openDeleteDialog = () => {
        if (!cfdi) return;
        if (cfdi.metadata.cfdi_status_group === "SENDING") {
            setShowDeleteDialog(true);
        } else {
            setMessageData(cfdi);
            setOpenConfirmDialogDelete(true);
        }
    };

    const onConfirmCancel = () => {
        if (!cfdi) return;

        Promise.all([
            validateGenerateInMembership(cfdi!.id),
        ]).then(response => {
            let isGeneratedInMembership = response[0];
            if (cfdi.metadata.type === "PAYMENT_CFDI" && isGeneratedInMembership.generated_by_membership) {
                setOpenConfirmCancelSat(true);
            } else {
                onOpenCancelCfdi();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onOpenAdditionalAmount = () => {
        if (!cfdi) return;
        setOpenAdditionalAmountDialog(true);
    }

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onCancelCfdi = () => {
        setOpenCancelConfirmDialog(false);
        setOpenBackdrop(true);
        cancelCfdi(context.session!.tenant!.id, match.params.cfdiId).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": cfdi.identifier, "cfdi_link": `/cfdis/${response.related_cfdi}/details` }) as string
                ));
            } else {
                if(openConfirmCancelSat){
                    setOpenConfirmCancelSat(false);
                    setSuccess(translate("status_membership.cancel_sat_success") as string);
                } else {
                    setSuccess(translate("status_membership.cancel_success") as string);
                }
                load();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onSuccessDelete = () => {
        if (!cfdi) return;
        let message = translate("cfdis.success_deleting", { "id": cfdi.identifier }) as string;
        if (referer == "to-send") {
            setRedirect(`/provider/cfdis/to-send/success/${message}?` + queryString.stringify(qs));
        }
        setRedirect(backTo);
    }

    const onError = (error: string) => {
        setError(error);
        setShowDeleteDialog(false);
    };

    const onCancel = () => {
        setShowDeleteDialog(false);
    };

    const onValidationTemplateResult = () => {
        setCfdiData(undefined);
        setCfdiValidations(undefined);
    }

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    const getWorkflowName = () => {
        if ("REFUND" === cfdi.type) {
            return translate(`workflows.cfdi_workflow.chip_name`, { "name": translate(`refunds.title`) as string }) as string;
        }

        let workflow;
        if (cfdi.metadata.provider_workflows && cfdi.metadata.workflow_id) {
            workflow = cfdi.metadata.provider_workflows.find(c => c.id === cfdi.metadata.workflow_id);
        }
        if (workflow) {
            return translate(`workflows.cfdi_workflow.chip_name`, { "name": workflow.name || "---" }) as string;
        }
        return translate(`workflows.cfdi_workflow.not_asigned`) as string;
    };

    const chipStatus = () => {
        return <Grid container direction="row">
            <Grid item className={classes.root}>
                <Typography variant="body1" component="h6">
                    {cfdi.identifier}
                </Typography>
                <Chip label={getStatus()} disabled variant="outlined" size="small" />
            </Grid>
            <Grid item className={classes.root}>
                <Chip label={getWorkflowName()} disabled variant="outlined" size="small" />
            </Grid>
            {cfdi.metadata.in_expenses_account &&
                <Grid item className={classes.root}>
                    <Button variant="text" color="primary" size="small" endIcon={<LaunchIcon />}
                        onClick={() =>
                            window.open(`/cfdis/${cfdi.metadata.expense_account_id}/details_related?referer=archive`, "_blank")
                        }>
                        {translate("expenses_accounts.link", { "identifier": cfdi.metadata.expense_account_identifier })}
                    </Button>
                </Grid>
            }
            {cfdi.owners && cfdi.owners.some(o => o.active) &&
                <Grid item className={classes.root}>
                    <OwnersTooltip owners={cfdi.owners} />
                </Grid>
            }
        </Grid>
    }

    const getStatus = () => {
        return getTranslateStatus(cfdi, statusesCfdi);
    };

    const onDeleteComprobante = () => {
        setAnchorEl(null);
        setMessageData(cfdi);
        setOpenConfirmDialogDelete(true);
    };

    const setMessageData = (cfdi: Cfdi) => {
        if (cfdi.metadata.advance_metadata?.is_complementary_advanced) {
            let totalDiscounts = cfdi.cfdi?.conceptos.map(concepto => concepto.descuento || 0).reduce((a, b) => a + b);
            setMessageDelete(translate("cfdis.pay_pending.actions.delete.description_complementary_advance", {
                "uuid": cfdi.cfdi?.cfdis_relacionados[0].uuids_relacionados[0],
                "discounts_amount": totalDiscounts,
                "currency": cfdi.currency
            }) as string);
        } else {
            setMessageDelete(translate("cfdis.pay_pending.actions.delete.description", { "provider": cfdi.cfdi ? context.isGrantedAny(["ProviderCFDIsDelete"]) ? cfdi.cfdi.receptor.nombre : cfdi.cfdi.emisor.nombre : "" }) as string);
        }
    };

    const onConfirmDeleteComprobante = (razon: string) => {
        setOpenConfirmDialogDelete(false);
        setStatus("loading");
        deleteCfdi(context.session!.tenant!.id, cfdi!.id, { comments: razon } as DeleteCfdiRequest).then(() => {
            setStatus("loaded");
            setRedirect(backTo);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const onCloseConfirmDialogDelete = () => {
        setOpenConfirmDialogDelete(false);
    }

    const onPostpone = () => {
        setAnchorEl(null);
        setPostpone(true);
    };

    const onReactivate = () => {
        setAnchorEl(null);
        setOpenConfirmDialogReactivate(true);
    };

    const onCancelledPostpone = () => {
        setPostpone(false);
    };

    const onConfirmPostpone = (datePostpone: Date) => {
        setPostpone(false);
        setStatus("loading");
        let cfdisIds = [] as string[];
        cfdisIds.push(cfdi.id);
        let request = {
            date_postpone: datePostpone,
            cfdi_ids: cfdisIds
        } as CfdiPostponeRequest;

        postponePayment(context.session!.tenant!.id, request).then(() => {
            setRedirect(backTo + "&success_postpone=" + moment(datePostpone).format("DD/MM/YYYY"));
        }).catch((error) => {
            setError(translate("cfdis.popup.postpone_payment.error") as string);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    const onConfirmReactivate = () => {
        setOpenConfirmDialogReactivate(false);
        setStatus("loading");
        reactivatePayment(context.session!.tenant!.id, cfdi!.id).then(() => {
            setRedirect(backTo + "&success_reactivate=true");
        }).catch((error) => {
            setError(translate("cfdis.popup.reactivate.error") as string);
        }).finally(() => {
            setStatus("loaded");
        });

    }

    const onSelectedWorkflow = (workflowId: string, companyId: string, confirmChange: boolean) => {
        if (workflowId) {
            setWorkflowSelected(workflowId);
            if (confirmChange) {
                setOpenConfirmChangeWorkflow(true);
            } else {
                setOpenBackdrop(true);
                updateWorkflow(context.session!.tenant!.id, cfdi!.id, { workflow_id: workflowId } as CfdiWorkflowRequest).then((response) => {
                    setOpenSelectWorkflow(false);
                    setSuccess(translate("workflows.cfdi_workflow.success_update") as string);
                    setOpenBackdrop(false);
                    load();
                }).catch((error) => {
                    setOpenBackdrop(false);
                    setError(error.message);
                });
            }
        } else {
            setWarning(translate("workflows.cfdi_workflow.empty_selection") as string);
        }
    }

    const onConfirmChangeWorkflow = () => {
        setOpenConfirmChangeWorkflow(false);
        setConfirmChangWorkflow(false);
        onSelectedWorkflow(workflowSelected!, "", false);
    }

    const onCloseWorkflowSelect = () => {
        setOpenSelectWorkflow(false);
        if (!cfdi.metadata.workflow_id) {
            setRedirect(backTo);
        }

    };

    const onChangeWorkflow = () => {
        setAnchorEl(null);
        setConfirmChangWorkflow(true);
        setOpenSelectWorkflow(true);
    };

    const onEditRefund = () => {
        setShowRefundForm(true);
    }

    const onCloseRefundForm = () => {
        setShowRefundForm(false);
    }

    const showDeleteRefundDialog = () => {
        if (!cfdi) return;
        setAnchorEl(null);
        setOpenRefundDelete(true);
    };

    const onCancelDeleteRefund = () => {
        setOpenRefundDelete(false);
    }

    const onFinishEditRefund = () => {
        setShowRefundForm(false);
        load();
    }

    const onDeleteRefund = () => {
        if (!cfdi) return;
        setOpenRefundDelete(false);
        setOpenBackdrop(true);
        deleteRefund(context.session!.tenant!.id, cfdi.id).then(() => {
            setRedirect(backTo);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setCfdi(undefined);
            setOpenBackdrop(false);
        });
    };

    const onChangePdf = (pdfFile: File) => {
        if (!cfdi) return;
        setChangePdf(false);
        setOpenBackdrop(true);
        changePdfFile(context.session!.tenant!.id, cfdi.id, pdfFile).then((response) => {
            setCfdi(response);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onCheckPdfAsCorrect = () => {
        if (!cfdi) return;
        setCheckPdf(false);
        setOpenBackdrop(true);
        markPdfCorrect(context.session!.tenant!.id, cfdi.id).then((response) => {
            setCfdi(response);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadValidationsPdf = () => {
        if (!cfdi) return;
        setOpenBackdrop(true);
        let params = {
            selected_ids: [cfdi].map(cfdi => cfdi.id).join(","),
            status_group: ""
        } as CfdisQueryParams;
        setAnchorEl(null);
        exportValidationsPdfCfdis(context.session!.tenant!.id, params).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setWarning(translate("cfdis.export_pdf_validations_failed") as string);
            }
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setAnchorEl(null);
        });
    }

    const onOpenCheckPdf = () => {
        setCheckPdf(true);
    }

    const onCloseCheckPdf = () => {
        setCheckPdf(false);
    }

    const onOpenChangePdf = () => {
        setChangePdf(true);
    }

    const onCloseChangePdf = () => {
        setChangePdf(false);
    }

    const onCloseValidationCfdiPaymentOverdue = () => {
        setHasOverdue(false);
    };

    const onCloseExedientValidation = () => {
        setExedientValidation(false);
    };

    const onEditAdvancePayments = () => {
        setAnchorEl(null);
        setAdvancePaymentsModal(true);
    };

    const onCancelAdvancePayment = () => {
        setAdvancePaymentsModal(false);
    }

    const onEditAdvancePaymentSuccess = (cfdi: Cfdi) => {
        setAdvancePaymentsModal(false);
        load();
    }

    const toAssociateAdvancePayments = () => {
        if ("CFDI" === cfdi.type && "INVOICE" === cfdi.metadata.type) {
            restrictedToAssociateAdvancePayments(cfdi.metadata.tenant_id, cfdi.id).then(() => {
                setOnAssociateAdvancePaymentsModal(!onAssociateAdvancePaymentsModal);
            }).catch((e) => {
                setWarning(e.message);
            }).finally(() => setAnchorEl(null));
        } else {
            setAnchorEl(null);
        }
    }

    const onAssociateAdvancePayments = (items: AdvancePayment[]) => {
        if (items && items.length > 0 && cfdi) {
            associateAdvancePayments(cfdi.metadata.tenant_id, cfdi.id, { advance_payments: items })
                .then((response) => {
                    load();
                })
                .catch((error) => {
                    setError(error.message);
                })
                .finally(() => setOnAssociateAdvancePaymentsModal(!onAssociateAdvancePaymentsModal));
        }
    }

    const onUpdatePaymentDeadline = () => {
        setAnchorEl(null);
        setIsUpdatePaymentDeadline(true);
    }

    const onCancelUpdatePaymentDeadline = () => {
        setIsUpdatePaymentDeadline(false);
    };

    const onConfirmUpdatePaymentDeadline = (newPaymentDeadline: Date) => {
        if (!cfdi) return;

        setIsUpdatePaymentDeadline(false);
        setStatus("loading");
        updatePaymentDeadline(context.session!.tenant!.id, cfdi.id, { new_payment_deadline: newPaymentDeadline } as CfdiUpdatePaymentDeadlineRequest).then(() => {
            setSuccess(translate("cfdis.update_payment_deadline.success") as string);
        }).catch((error) => {
            setError(translate("cfdis.update_payment_deadline.error") as string);
        }).finally(() => {
            setStatus("loaded");
            load();
        });
    }

    return (
        <Surface title={cfdi.metadata.read_only?.active ?
            <Typography style={{ color: "#9C9C9C" }} >
                {cfdi.issuer.name} <Tooltip title={cfdi.metadata.read_only?.comments}>
                    <DesktopAccessDisabledIcon />
                </Tooltip></Typography> : cfdi.issuer.name} subtitle={chipStatus()} backButton backTo={backTo} icon={<MoreVertIcon />} className="PaperPagination PaperTabContainer"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {(context.isGrantedAny(["ProviderCFDIsUpdate", "SenderCFDIsUpdate"])
                            || (context.isGrantedAny(["RefundsUpdate", "RefundsCxcUpdate"]) && "REFUND" === cfdi.type))
                            && isAllowToSend(cfdi) ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("cfdis.to_send.actions.send") as string} onClick={"REFUND" === cfdi.type ? onShowSendRefund : onSend} >
                                    <SendCfdiIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {context.isGrantedAny(["ProviderCFDIsDelete"]) && isAllowToDelete(cfdi) ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("cfdis.delete_cfdi") as string} onClick={openDeleteDialog} >
                                    <DeleteIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {context.isGrantedAny(["SenderCFDIsDelete"]) && isSenderAllowToDelete(cfdi) ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("cfdis.delete_cfdi") as string} onClick={openDeleteDialog} >
                                    <DeleteIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {(context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate", "RefundsUpdate", "RefundsCxcUpdate"])
                            && isAllowToAuthorize(cfdi.metadata.cfdi_status_group))
                            && (referer === 'to-authorize' || referer === 'to_authorize_refunds' || referer === 'to-verify' || referer === 'preguide') ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("cfdis.to_authorize.actions.authorize") as string}
                                    onClick={cfdi.type === "REFUND" ? onShowAuthorizeRefund : inVerify(cfdi.metadata.cfdi_status_group) ? onShowVerify : onShowSendToVerify} >
                                    <ToAuthorizeIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "CxpCFDIsUpdate", "RefundsUpdate", "RefundsCxcUpdate"])
                            && isAllowToReject(cfdi.metadata.cfdi_status_group)
                            && (referer === 'to-authorize' || referer === 'to_authorize_refunds' || referer === 'to-verify' || referer === 'pending-payment' || referer === 'preguide') ?
                            (<Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("cfdis.to_authorize.actions.reject") as string}
                                    onClick={cfdi.type === "REFUND" ? onShowRejectRefund : onShowReject} >
                                    <RejectIcon />
                                </Fab>
                            </Grid>) : undefined}
                        {(cfdi.type === "CFDI" || cfdi.type === "EXPENSES_ACCOUNT" || cfdi.type === "HONORARIUM") && (
                            <Grid item xs="auto">
                                <Fab color="primary" size="small" title={translate("cfdis.download_cfdi") as string} onClick={onDownloadCfdis} >
                                    <DownloadIcon />
                                </Fab>
                            </Grid>
                        )}
                        {cfdi && (cfdi.type !== "REFUND" || (cfdi.type === "REFUND" && cfdi.metadata.cfdi_status_group !== "ARCHIVED" && referer !== "archive_refunds" && referer !== "pending-payment")) &&
                            <Grid item xs="auto">
                                <IconButton color="default" onClick={onClickedMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>}>
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: CfdiTab, index: number) => (
                    <Tab key={tab.name} label={tab.name === "files" && cfdi.metadata.valid_pdf === false ? undefined : tab.title} icon={tab.name === "files" && cfdi.metadata.valid_pdf === false ? <Grid spacing={2}>{translate("cfdis.documents.title" as string)}<AlertIcon color="secondary" fontSize="small" /></Grid> : undefined} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: CfdiTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                {anchorEl && cfdi.type !== "REFUND" && (isTenant ?
                    <TenantCfdiMenu
                        cfdi={cfdi} anchor={anchorEl} referer={""} inner
                        onCancel={onConfirmCancel}
                        onClose={onCloseOption}
                        onAdditionalAmount={onOpenAdditionalAmount}
                        onDeleteComprobante={onDeleteComprobante}
                        onPostpone={onPostpone}
                        onReactivate={onReactivate}
                        onChangeWorkflow={onChangeWorkflow}
                        onDownloadFiles={onDownloadCfdis}
                        onDownloadValidationsPdf={onDownloadValidationsPdf}
                        onEditAdvancePayment={onEditAdvancePayments}
                        onAssociateAdvancePayments={toAssociateAdvancePayments}
                        onUpdatePaymentDeadline={onUpdatePaymentDeadline}
                    /> :
                    <ProviderCfdiMenu
                        cfdi={cfdi} anchor={anchorEl} referer={""} inner
                        onCancel={onConfirmCancel}
                        onClose={onCloseOption}
                        onAdditionalAmount={onOpenAdditionalAmount}
                        onChangeWorkflow={onChangeWorkflow}
                        onDownloadFiles={onDownloadCfdis}
                        onDownloadValidationsPdf={onDownloadValidationsPdf}
                        onEditAdvancePayment={onEditAdvancePayments}
                        onAssociateAdvancePayments={toAssociateAdvancePayments}
                    />)
                }
            </Grid>
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export_single.title") as string}
                    message={translate("cfdis.popup.export_single.description", { "id": cfdi.identifier }) as string}
                    url={exportResult.url} onClose={onCloseDownloadExportationPopup} />
            )}
            {executor && (
                <AuthorizeRejectPopup
                    doAction={executor === "send-to-verify" ? onAuthorize : executor === "verify" ? onApprove : onReject}
                    onClose={onCloseAddComment}
                    param={executor} isCommentRequired={executor === "reject" || executor === "reject-review"} />
            )}
            {showDeleteDialog &&
                <CfdiDeletePopup cfdi={cfdi}
                    tenantId={context.session!.tenant!.id}
                    providerId={context.session!.provider!.id}
                    onCompleted={onSuccessDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            }
            {openUpdateTotalAuthorized &&
                <TotalAuthorizePopup cfdi={cfdi} doAction={onUpdateTotalAuthorized} onClose={onCloseUpdatedTotalAuthorized} />
            }
            {(cfdiValidations?.find(v => !v.valid) !== undefined) && cfdiData && (
                <ProviderVerificationOneCfdiResult data={cfdiValidations} cfdi={cfdiData} onClose={onValidationTemplateResult} hideDetailsButton />)
            }
            {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} />
                : undefined}
            {!isValidator && openAdditionalAmountDialog &&
                <CfdiAdditionalAmountPopUp cfdi={cfdi} onCompleted={onUpdateAdditionalAmount} onClose={() => setOpenAdditionalAmountDialog(false)} />
            }
            {!isValidator && openDeleteAdditionalAmount &&
                <DialogPopup open
                    title={translate("cfdis.additional_amount.delete_pop_up") as string}
                    disableEscapeKeyDown={false}
                    disableBackdropClick={false}
                    disable={false}
                    closeText={translate("buttons.cancel") as string}
                    onClose={onCloseDeleteAdditionalAmount}
                    closeColor="default"
                    button={
                        <Button onClick={onDeleteAdditionalAmount} variant="outlined" color="secondary" disabled={false}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    <Typography variant="body2">
                        {translate("cfdis.additional_amount.delete_confirm")}
                    </Typography>
                </DialogPopup>
            }
            {openConfirmDialogDelete && (
                <DeleteCfdiPopup
                    cfdi={cfdi}
                    messageDefault={translate("cfdis.pay_pending.actions.delete.description", { "provider": cfdi.cfdi ? context.isGrantedAny(["ProviderCFDIsDelete"]) ? cfdi.cfdi.receptor.nombre : cfdi.cfdi.emisor.nombre : "" }) as string}
                    onConfirmDeleteComprobante={onConfirmDeleteComprobante}
                    onCloseConfirmDialogDelete={onCloseConfirmDialogDelete} />
            )}
            {openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("CREDIT_NOTE" === cfdi.metadata.type ? "cfdis.cancel_cfdi.title_cn" : "cfdis.cancel_cfdi.title") as string}
                    message={translate("CREDIT_NOTE" === cfdi.metadata.type ? "cfdis.cancel_cfdi.message_cn" : "cfdis.cancel_cfdi.message") as string}
                    secondary={translate("CREDIT_NOTE" === cfdi.metadata.type ? "cfdis.cancel_cfdi.secondary_cn" : "cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {postpone && cfdi &&
                <PostponePaymentPopup
                    id={cfdi.id}
                    onConfirm={onConfirmPostpone}
                    onCancelled={onCancelledPostpone} />
            }

            {openConfirmDialogReactivate ?
                <CfdisConfirmPopup
                    doAction={onConfirmReactivate}
                    onClose={() => setOpenConfirmDialogReactivate(false)}
                    title={translate("cfdis.popup.reactivate.title") as string}
                    message={translate("cfdis.popup.reactivate.message", { "fecha": cfdi ? moment(cfdi.metadata.postpone_to).format("DD/MM/YYYY") : "" }) as string}
                    button={translate("buttons.accept") as string} />
                : undefined
            }
            {openSelectWorkflow && providerWorkflows && cfdi &&
                <CfdiAsignWorkflowPopUp
                    workflows={providerWorkflows || []}
                    isChangeWorkflow={cfdi.metadata.workflow_id !== undefined}
                    companies={[]}
                    onCompleted={onSelectedWorkflow}
                    cfdi={cfdi}
                    confirmChange={confirmChangeWorkflow}
                    onClose={onCloseWorkflowSelect} />
            }

            {openConfirmChangeWorkflow &&
                <CfdisConfirmPopup
                    doAction={onConfirmChangeWorkflow}
                    onClose={() => setOpenConfirmChangeWorkflow(false)}
                    title={translate("workflows.cfdi_workflow.change") as string}
                    message={translate("workflows.cfdi_workflow.description_change") as string}
                    button={translate("buttons.accept") as string}
                />
            }
            {openDesassociateAdvancePayment && cfdi &&
                <CfdisConfirmPopup
                    doAction={onDesassociateAdvancePayment}
                    onClose={onCloseConfirmDesassociateAdvancePayment}
                    title={translate("advance_payments.desassociate.title") as string}
                    message={translate("advance_payments.desassociate.message") as string}
                    button={translate("buttons.accept") as string}
                />
            }
            {context && cfdi && executorRefund &&
                <UpdateStatusRefundPopup
                    cfdi={cfdi}
                    tenantId={context.session!.tenant!.id}
                    isCommentRequired={executorRefund === "reject" || executorRefund === "cancel"}
                    action={executorRefund}
                    doAction={executorRefund === "authorize" ? onAuthorizeRefund : executorRefund === "reject" ? onRejectRefund : executorRefund === "send" ? onSendRefund : onCancelRefund}
                    onClose={onCloseUpdateRefund}
                />
            }
            {showRefundForm &&
                <RefundFormPopup refund={cfdi} onSuccess={onFinishEditRefund} onCancel={onCloseRefundForm} />
            }
            {anchorEl && cfdi && cfdi.type === "REFUND" && <RefundMenu
                anchor={anchorEl}
                refundId={cfdi.id}
                onClose={onCloseOption}
                onEdit={onEditRefund}
                onDelete={showDeleteRefundDialog}
                onCancel={onShowCancelRefund}
                onSend={onShowSendRefund}
                view={cfdi.metadata.cfdi_status_group === "SENDING" ? "to_send" : cfdi.metadata.cfdi_status_group === "IN_PROCESS" ? "to_authorize" : "archive"}
                fromDetail={true}
            />}
            {openRefundDelete && cfdi &&
                <ConfirmationPopup
                    title={translate("refunds.confirm_delete.title") as string}
                    message={translate("refunds.confirm_delete.message", { number: cfdi.identifier }) as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDeleteRefund}
                    doAction={onDeleteRefund}
                />
            }
            {partiallityData && cfdi && openPartiallityDialog && (
                <DialogPopup open
                    title={translate("purchase_orders.current_partiallity_message.sending_title") as string}
                    closeText={translate("buttons.accept") as string}
                    onClose={onClosePartiallities}
                    closeColor="default" >
                    <Typography variant="body2">
                        {translate("purchase_orders.current_partiallity_message.message", { "oc_number": partiallityData.purchase_order_number, "partiallity_number": partiallityData.partiallity_number, "percentaje": partiallityData.percentaje, "amount": partiallityData.amount, "currency": partiallityData.currency, "condition": partiallityData.payment_condition }) as string}
                        {ReactHtmlParser(translate("purchase_orders.current_partiallity_message.footer", { "url": partiallityData.url }) as string)}
                    </Typography>
                </DialogPopup>
            )}
            {changePdf && cfdi && cfdi.metadata.xml_file_name && (
                <CfdiChangePdf onChange={onChangePdf} xmlName={cfdi.metadata.xml_file_name} onClose={onCloseChangePdf} />
            )}
            {checkPdf && cfdi && (
                <CfdisConfirmPopup
                    doAction={onCheckPdfAsCorrect}
                    onClose={onCloseCheckPdf}
                    title={translate("cfdis.documents.files.check_as_correct") as string}
                    message={translate("cfdis.documents.files.check_as_correct_message") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {hasOverdue && (
                <DialogPopup open
                    title={translate("cfdis.popup.validation_cfdi_payment_overdue.title") as string}
                    closeText={translate("buttons.accept") as string}
                    onClose={onCloseValidationCfdiPaymentOverdue}
                    closeColor="default" >
                    <Typography variant="body2">
                        {translate("cfdis.popup.validation_cfdi_payment_overdue.description", { "overdue": allowedToSend.cfdi_overdue ? allowedToSend.cfdi_overdue.cfdi_payment_overdue : 0 }) as string}
                        <Link to={"/payment/pending-cfdi"}> aquí.</Link>
                    </Typography>
                </DialogPopup>
            )}
            {exedientValidation &&
                <DialogPopup open
                    title={translate("cfdis.popup.validation_expedient.title") as string}
                    onClose={onCloseExedientValidation}
                    closeText={translate("buttons.accept") as string}
                    closeColor="default" >
                    <Typography variant="body2">
                        {translate("cfdis.popup.validation_expedient.description") as string}
                    </Typography>
                </DialogPopup>
            }
            {advancePaymentsModal &&
                <AdvancePaymentPopup cfdi={cfdi} onSuccess={onEditAdvancePaymentSuccess} onCancel={onCancelAdvancePayment} provider_id={context.session?.provider?.id} />
            }
            {onAssociateAdvancePaymentsModal && (
                <AssociateAdvancePayments data={cfdi} onAccept={onAssociateAdvancePayments} onClose={() => setOnAssociateAdvancePaymentsModal(!onAssociateAdvancePaymentsModal)} />
            )}
            {isUpdatePaymentDeadline && cfdi &&
                <UpdatePaymentDeadlinePopup
                    id={cfdi.id}
                    dateInitial={cfdi.metadata.payment_info!.payment_deadline!}
                    onConfirm={onConfirmUpdatePaymentDeadline}
                    onCancelled={onCancelUpdatePaymentDeadline}
                />
            }
            {openConfirmCancelSat && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenConfirmCancelSat(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.confirm_cancel_sat") as string}
                    button={translate("buttons.accept") as string} />
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Surface>
    );
}
