import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { CfdisQueryParams, Cfdis, Cfdi, AuthorizedAmountRequest, AuthorizedAmountResponse, CfdiRequest, ReceptionDateValidationResponse, CancelCfdiResonse, StatusesCfdi, SecureCfdiFileUrlResonse, TenantCfdisParams, ActorStageFieldsResponse, ExpensesAccounts, TotalAuthorizeAmountResponse, CfdiConceptos, AdvancePaymentQueryParams, AdvancePaymentsResponse} from "../model/Cfdi";
import { ProrationRequest } from "../model/Prorate";
import { CfdiHistories } from "../model/CfdiHistory";
import { TenantCfdisRequest, ExportCfdisResponse, WorkflowRoutingRequest, WorkflowApproveResponse, WorkflowRejectResponse, ChangeReceptorCfdisRequest,CfdisChangeReceptor, DeleteCfdiRequest, AdditionalAmountRequest, CfdiPostponeRequest, CfdiWorkflowRequest, ExpensesAccountQueryParams, ExportCfdiValidationsDatasetRequest, ExpensesAccountParams, WorkflowRoutingRequestWithParams, CfdiUpdatePaymentDeadlineRequest } from "../model/TenantCfdi";
import { CfdisByProviderParams } from "../model/Payment";
import { ProviderCfdiOverdueResponse, ProviderAllowedToSendResponse } from "../model/ProviderCfdi";
import { UrlFieldResponse } from "../model/UrlField";
import { AssociateRequest } from "../model/Document";
import { AdvancePayments, AdvancePaymentsRequest } from '../model/AdvancePayment';

const cfdiParams = (userId: string, pageSize: number, offset: number, params: CfdisQueryParams) => {
    let cfdiParams = {
        pageSize: pageSize,
        offset: offset,
        search: params.search,
        type: params.type,
        statusGroup: params.status_group ? params.status_group : null,
        statusList: params.status_list ? params.status_list.split(",") : null,
        typeList: params.type_list ? params.type_list.split(",") : null,
        userReceptorId: userId,
        provider: params.provider,
        providerId: params.provider_id,
        currency: params.currency,
        cfdiType: params.cfdiType,
        isCanceled: params.isCanceled,
        isCanceledInTenant: params.isCanceledInTenant,
        identifier: params.identifier,
        issuer: params.issuer,
        description: params.description,
        typeFilter: params.type_filter,
        sendAtStart: params.send_at_start ? new Date(params.send_at_start) : null,
        sendAtEnd: params.send_at_end ? new Date(params.send_at_end) : null,
        paymentScheduleStart: params.payment_schedule_start ? new Date(params.payment_schedule_start) : null,
        paymentScheduleEnd: params.payment_schedule_start ? new Date(params.payment_schedule_start) : null,
        paymentDeadlineStart: params.payment_deadline_start ? new Date(params.payment_deadline_start) : null,
        paymentDeadlineEnd: params.payment_deadline_end ? new Date(params.payment_deadline_end) : null,
        authorizedAtStart: params.authorized_at_start ? new Date(params.authorized_at_start) : null,
        authorizedAtEnd: params.authorized_at_end ? new Date(params.authorized_at_end) : null,
        total: params.total,
        balance: params.balance,
        company: params.company_name,
        excludedIds: params.excluded_ids,
        litePayload: params.lite_payload,
        paymentDueStart: params.payment_due_start ? new Date(params.payment_due_start) : null,
        paymentDueEnd: params.payment_due_end ? new Date(params.payment_due_end) : null,
        archive: params.archive,
        refererView: params.referer_view ? params.referer_view : null,
        isDue: params.is_due,
        selectedIds: params.selected_ids ? params.selected_ids.split(",") : null,
        filterFields: params.filter_fields ? params.filter_fields : null,
        statusGroups: params.status_groups ? params.status_groups.split(",") : null
    } as TenantCfdisParams;

    //TODO Revisar por qué estan repetidas estas propiedades
    if (params.date_start && params.date_end) {
        cfdiParams = {
            ...cfdiParams,
            startDate: new Date(params.date_start),
            endDate: new Date(params.date_end),
        };
    }

    if (params.start_date && params.end_date) {
        cfdiParams = {
            ...cfdiParams,
            startDate: params.start_date,
            endDate: params.end_date,
        };
    }

    if (params.connector_id) {
        cfdiParams = { ...cfdiParams, connectorId: params.connector_id }
    }

    return cfdiParams;
}

/* Verificar codigo que setea los parametroes en cfdiParams  y parseCfdiQuery */
export async function getTenantCfdis(tenantId: string, userId: string, pageSize: number, offset: number, params: CfdisQueryParams): Promise<Cfdis> {
    return await callAPI(`/tenants/${tenantId}/cfdis`, {
        method: HTTPMethod.POST,
        body: cfdiParams(userId, pageSize, offset, params)
    });
}

export async function getTenantCfdiFileUrl(tenantId: string, cfdiId: string, type: string): Promise<string> {
    return getAuthenticatedGetUrl(`/tenants/${tenantId}/cfdis/${cfdiId}/file`,
        {
            query: {
                format: type
            }
        }
    );
}

export async function getSecureCfdiFileUrl(tenantId: string, cfdiId: string, type: string): Promise<SecureCfdiFileUrlResonse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/secure-file-url`, {
        method: HTTPMethod.GET,
        query: {
            format: type
        }
    });
}

export async function approve(tenantId: string, request: WorkflowRoutingRequest): Promise<WorkflowApproveResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/approve`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function approveListed(tenantId: string, request: WorkflowRoutingRequest, params: CfdisQueryParams): Promise<WorkflowApproveResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/approve-listed`, {
        method: HTTPMethod.PUT,
        body: {...request, params: cfdiParams("", 0, 0, params) } as WorkflowRoutingRequestWithParams
    });
}

export async function rejec(tenantId: string, request: WorkflowRoutingRequest): Promise<WorkflowRejectResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/reject`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getHistory(tenantId: string, cfdiId: string, pageSize: number, offset: number): Promise<CfdiHistories> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/history`, {
        method: HTTPMethod.GET,
        query: {
            page_size: pageSize.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getCfdi(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}`, {
        method: HTTPMethod.GET,
    });
}

export async function exportCfdisSelected(tenantId: string, request: TenantCfdisRequest): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-selected`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function exportPaymentCfdis(tenantId: string, request: TenantCfdisRequest): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/exportPaymentCfdis`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function exportCfdiFiles(tenantId: string, cfdiId: string): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/export`, {
        method: HTTPMethod.GET
    });
}

export async function exportPaymentCfdiFiles(tenantId: string, cfdiId: string): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/exportPaymentCfdis`, {
        method: HTTPMethod.GET
    });
}

export async function exportAllCfdis(tenantId: string, userId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export`, {
        method: HTTPMethod.POST,
        body: cfdiParams(userId, 0, 0, params),
    });
}

export const exportAllCfdisRange = exportAllCfdis;

export async function exportForErpAll(tenantId: string, userId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-for-erp`, {
        method: HTTPMethod.POST,
        body: cfdiParams(userId, 0, 0, params),
    });
}

export async function exportForErpSelected(tenantId: string, ids: string[]): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-for-erp`, {
        method: HTTPMethod.PUT,
        body: {
            cfdi_ids: ids,
        } as TenantCfdisRequest
    });
}

export async function exportAllPaymentCfdis(tenantId: string, providerId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/exportPaymentCfdis`, {
        method: HTTPMethod.GET,
        query: {
            userReceptorId: params.receptor ? params.receptor : "",
            providerId: providerId,
            search: params.search,
            type: params.type ? params.type : "",
            cfdiType: params.cfdiType ? params.cfdiType : "",
            statusList: params.status_list,
            statusGroup: params.status_group,
            currency: params.currency ? params.currency : "",
            pageSize: "0",
            offset: "0",
            startDate: params.start_date + "",
            endDate: params.end_date + "",
        }
    });
}

export async function getShippingFileUrl(tenantId: string, cfdiId: string, fieldId: string): Promise<string> {
    return getAuthenticatedGetUrl(`/tenants/${tenantId}/cfdis/${cfdiId}/shpping-field/${fieldId}/file`);
}

export async function getUrlFieldShippingTemplate(tenantId: string, cfdiId: string, fieldId: string): Promise<UrlFieldResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/shpping-field/${fieldId}/url-field`, {
        method: HTTPMethod.GET
    });
}

export async function updateAuthorizedAmount(tenantId: string, cfdiId: string, request: AuthorizedAmountRequest): Promise<AuthorizedAmountResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/update-authorized-amount`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateCfdiProration(tenantId: string, cfdiId: string, request: ProrationRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/proration`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function validateReceptionDate(tenantId: string, xml?: File): Promise<ReceptionDateValidationResponse> {
    let params;
    if (xml) {
        let formData = new FormData();
        formData.append("xml", xml as Blob);
        params = {
            method: HTTPMethod.POST,
            multipart: true,
            body: formData
        }
    } else {
        params = {
            method: HTTPMethod.POST,
        }
    }
    return await callAPI(`/tenants/${tenantId}/cfdis/validate-reception-date`, params as any);
}

export async function createAdminCfdi(tenantId: string, request: CfdiRequest): Promise<Cfdi> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    let params = JSON.stringify({
        "workflow_id": request.workflow_id ? request.workflow_id : null,
        "company_id": request.company_id ? request.company_id : null,
        "preload_params": request.preload_params ? request.preload_params : null
    });
    data.append("params", new Blob([params], { type: "application/json" }));

    return await callAPI(`/tenants/${tenantId}/cfdis`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getTenantCfdisByProvider(tenantId: string, providerId: string, params: CfdisByProviderParams): Promise<Cfdis> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${providerId}/get-cfdis`, {
        method: HTTPMethod.GET,
        query: {
            currency: params.currency,
            paymentMethod: params.payment_method,
            type: params.cfdi_type ? params.cfdi_type : ""
        }
    });
}

export async function exportPendingPaymentCfdis(tenantId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-pending-payment`, {
        method: HTTPMethod.GET,
        query: parseCfdiQuery("", params),
    });
}

export const exportPendingPaymentCfdisAll = exportPendingPaymentCfdis;

export async function getCfdisPaymentOverdue(tenantId: string, request: CfdiRequest): Promise<ProviderCfdiOverdueResponse> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    data.append("pdf", request.pdf as Blob, request.pdf.name);
    return await callAPI(`/tenants/${tenantId}/cfdis/payment-overdue`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function deleteCfdi(tenantId: string, cfdiId: string, request: DeleteCfdiRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}`, {
        method: HTTPMethod.DELETE,
        body: request
    });
}

export async function updateCfdisReceptor(tenantId: string, request: ChangeReceptorCfdisRequest): Promise<CfdisChangeReceptor> {
    return await callAPI(`/tenants/${tenantId}/cfdis/update-receptor`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function exportCfdisExcel(tenantId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-cfdis-excel`, {
        method: HTTPMethod.POST,
        body: cfdiParams("", 0, 0, params)
    });
}

export const exportCfdisExcelDateRange = exportCfdisExcel;

export async function updateAdditionalAmountCfdi(tenantId: string, cfdiId: string, request: AdditionalAmountRequest): Promise<Cfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/update-additional-amount`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteAdditionalAmountCfdi(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/delete-additional-amount`, {
        method: HTTPMethod.DELETE
    });
}

export async function cancelCfdi(tenantId: string, cfdiId: string): Promise<CancelCfdiResonse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/cancel`, {
        method: HTTPMethod.PUT
    });
}

export async function restrictedToSend(tenantId: string, request: CfdiRequest): Promise<ProviderAllowedToSendResponse> {
    let data = new FormData();
    data.append("xml", request.xml as Blob, request.xml.name);
    return await callAPI(`/tenants/${tenantId}/cfdis/restricted-to-send`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function postponePayment(tenantId: string, request: CfdiPostponeRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/cfdis/postpone-payment`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updatePaymentDeadline(tenantId: string, cfdiId: string, request: CfdiUpdatePaymentDeadlineRequest): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/payment-deadline`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function reactivatePayment(tenantId: string, cfdiId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/reactivate-payment`, {
        method: HTTPMethod.PUT
    });
}

export async function updateWorkflow(tenantId: string, cfdiId: string, request: CfdiWorkflowRequest): Promise<Cfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/update-workflow`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function listCfdiStatus(tenantId: string, payableDocumentType: string): Promise<StatusesCfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/cfdi-status`, {
        method: HTTPMethod.GET,
        query: {
            payableDocumentType: payableDocumentType ? payableDocumentType : ""
        }
    });
}

export async function associatePO(tenantId: string, cfdiId: string, request: AssociateRequest): Promise<Cfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/associate-po`, {
        method: HTTPMethod.PUT,
        body: request
    });
}


const parseCfdiQuery = (userId: string, params: CfdisQueryParams) => {
    let query = {
        userReceptorId: userId,
        search: params.search,
        provider: params.provider ? params.provider : "",
        type: params.type ? params.type : "",
        cfdiType: params.cfdiType ? params.cfdiType : "",
        typeList: params.type_list ? params.type_list : "",
        statusList: params.status_list ? params.status_list : "",
        statusGroup: params.status_group ? params.status_group : "",
        currency: params.currency ? params.currency : "",
        identifier: params.identifier ? params.identifier : "",
        issuer: params.issuer ? params.issuer : "",
        description: params.description ? params.description : "",
        typeFilter: params.type_filter ? params.type_filter : "",
        total: params.total ? params.total : "",
        balance: params.balance ? params.balance : "",
        pageSize: "0",
        offset: "0",
        isCanceledInTenant: params.isCanceledInTenant !== undefined ? params.isCanceledInTenant + "" : "",
        isCanceled: params.isCanceled ? params.isCanceled + "" : "",
        providerId: params.provider_id ? params.provider_id : "",
        archive: params.archive ? "true" : "false",
        connectorId: params.connector_id ? params.connector_id : "",
        refererView: params.referer_view ? params.referer_view : null,
        isDue: params.is_due ? params.is_due + "" : "",
    } as Record<string, string>;

    if (params.send_at_start && params.send_at_end) {
        query = {
            ...query,
            sendAtStart: params.send_at_start,
            sendAtEnd: params.send_at_end,
        };
    }

    if (params.payment_deadline_start && params.payment_deadline_end) {
        query = {
            ...query,
            paymentDeadlineStart: params.payment_deadline_start,
            paymentDeadlineEnd: params.payment_deadline_end,
        };
    }

    if (params.authorized_at_start && params.authorized_at_end) {
        query = {
            ...query,
            authorizedAtStart: params.authorized_at_start,
            authorizedAtEnd: params.authorized_at_end,
        };
    }

    if (params.date_start && params.date_end) {
        query = {
            ...query,
            startDate: params.date_start,
            endDate: params.date_end,
        };
    }

    if (params.start_date && params.end_date) {
        query = {
            ...query,
            startDate: params.start_date + "",
            endDate: params.end_date + "",
        };
    }
    return query;
}

export async function changePdfFile(tenantId: string, cfdiId: string, pdf?: File): Promise<Cfdi> {
    let params;
    if (pdf) {
        let formData = new FormData();
        formData.append("pdf", pdf as Blob, pdf.name);
        params = {
            method: HTTPMethod.POST,
            multipart: true,
            body: formData
        }
    } else {
        params = {
            method: HTTPMethod.POST,
        }
    }
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/change-pdf-file`, params as any);
}

export async function markPdfCorrect(tenantId: string, cfdiId: string): Promise<Cfdi> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/mark-correct-pdf`, {
        method: HTTPMethod.PUT
    });
}

export async function exportCfdisProratedExcel(tenantId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-cfdis-prorate-excel`, {
        method: HTTPMethod.POST,
        body: cfdiParams("", 0, 0, params)
    });
}

export async function getActorFieldsTypeCatalogByStage(tenantId: string, stageId?: string): Promise<ActorStageFieldsResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/actor-fields-catalog`, {
        method: HTTPMethod.GET,
        query: {
            stage: stageId ?? ""
        }
    });
}

const getExpensesAccountParams = (page: number, offset: number, params: ExpensesAccountQueryParams) => {

    let result = {
        search: params.search || "",
        company: params.company || "",
        provider: params.provider || "",
        providerExternalId: params.provider_external_id || "",
        expenseAccountType: params.expense_account_type || "",
        folio: params.folio || "",
        pageSize: page,
        offset: offset,
    } as ExpensesAccountParams;

    if (params.start_date && params.end_date) {
        result = {
            ...result,
            startDate: new Date(params.start_date),
            endDate: new Date(params.end_date),
        };
    }

    return result;
}

export async function listExpensesAccounts(tenantId: string, page: number, offset: number, params: ExpensesAccountQueryParams): Promise<ExpensesAccounts> {
    return await callAPI(`/tenants/${tenantId}/cfdis/expenses-accounts`, {
        method: HTTPMethod.POST,
        body: getExpensesAccountParams(page, offset, params),
    });
}

export async function exportExpensesAccountExcel(tenantId: string, page: number, offset: number, params: ExpensesAccountQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-expenses-account-excel`, {
        method: HTTPMethod.POST,
        body: getExpensesAccountParams(page, offset, params),
    });
}

export async function exportValidationsPdfCfdis(tenantId: string, params: CfdisQueryParams): Promise<ExportCfdisResponse> {
    return await callAPI(`/tenants/${tenantId}/cfdis/export-pdf-validations`, {
        method: HTTPMethod.POST,
        body: cfdiParams("", 0, 0, params),
    });
}

export async function getDetailForExportPdf(tenantId: string, cfdiId: string): Promise<ExportCfdiValidationsDatasetRequest> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/detail-for-export`, {
        method: HTTPMethod.GET
    });
}

export async function getAdvancePaymensPaid(tenantId: string, providerId: string, currency: string, companyId: string, pageSize: number, offset: number): Promise<AdvancePayments> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${providerId}/advance-payments`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: `${pageSize}`,
            offset: `${offset}`,
            currency: currency,
            companyId: companyId
        }
    });
}

export async function restrictedToAssociateAdvancePayments(tenantId: string, cfdiId: string): Promise<any> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/restricted-to-associate-advance-payments`, {
        method: HTTPMethod.PUT
    });
}

export async function associateAdvancePayments(tenantId: string, cfdiId: string, request: AdvancePaymentsRequest): Promise<any> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/advance-payments`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function desassociateAdvancePayments(tenantId: string, cfdiId: string, advancePaymentId: string): Promise<any> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/desassociate-advance-payment/${advancePaymentId}`, {
        method: HTTPMethod.POST
    });
}

export async function getTotalAuthorizedCfdis(tenantId: string, params: CfdisQueryParams): Promise<TotalAuthorizeAmountResponse> { 
    return await callAPI(`/tenants/${tenantId}/cfdis/total-authorized`, {
        method: HTTPMethod.POST,
        body: cfdiParams("", 0, 0, params)
    });
}

export async function listCfdiConceptos(tenantId: string, cfdiId: string, page: number, offset: number): Promise<CfdiConceptos> {
    return await callAPI(`/tenants/${tenantId}/cfdis/${cfdiId}/conceptos`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

const getAdvancePaymentParams = (tenantId: string, page: number, offset: number, params: AdvancePaymentQueryParams) => {
    let query = {
        provider: params.provider || "",
        relatedInvoice: params.related_invoice || "",
        status: params.status || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    if (params.start_date && params.end_date) {
        query = {
            ...query,
            startDate: params.start_date,
            endDate: params.end_date,
        };
    }

    if (params.payment_start_date && params.payment_end_date) {
        query = {
            ...query,
            paymentStartDate: params.payment_start_date,
            paymentEndDate: params.payment_end_date,
        };
    }
    return query;
}

export async function listAdvancePayments(tenantId: string, page: number, offset: number, params: AdvancePaymentQueryParams): Promise<AdvancePaymentsResponse> {
    let query = getAdvancePaymentParams(tenantId, page, offset, params);

    return await callAPI(`/tenants/${tenantId}/cfdis/advance-payments`, {
        method: HTTPMethod.GET,
        query: query
    });
}

export async function exporAdvancePaymentsExcel(tenantId: string, params: AdvancePaymentQueryParams): Promise<ExportCfdisResponse> {
    let query = getAdvancePaymentParams(tenantId, 0, 0, params);

    return await callAPI(`/tenants/${tenantId}/cfdis/export-advance-payments-excel`, {
        method: HTTPMethod.POST,
        query: query
    });
}