import React, { useContext, useState, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';

import { getTenantCfdiFileUrl } from "../../api/TenantCfdiApi";
import { Cfdi } from "../../model/Cfdi";
import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import { exportCfdiFiles } from "../../api/TenantCfdiApi";
import { ExportCfdisResponse } from "../../model/TenantCfdi";
import { getCfdiFileUrl } from "../../api/ProviderCfdiApi";
import DownloadExportationPopup from "../../components/DownloadExportationPopup";

interface TenantRelatedCfdisMenuProps {
    cfdi: Cfdi;
    anchor: HTMLElement | null;
    inner?: boolean;
    referer: string;
    isProvider: boolean;
    onClose(): any;
}

export default function TenantRelatedCfdisMenu(props: TenantRelatedCfdisMenuProps) {
    const context = useContext(AppContext);
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [xmlUrlProvider, setXmlUrlProvider] = useState<string>();
    const [pdfUrlProvider, setPdfUrlProvider] = useState<string>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();

    const onDownloadCfdis = () => {
        exportCfdiFiles(context.session!.tenant!.id, props.cfdi.id).then((response) => {
            if (response.url) {
                setExportResult(response);
            }
        });
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    useEffect(() => {

        if (props.isProvider) {
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "xml").then((urlFileProvider) => {
                setXmlUrlProvider(urlFileProvider);
            }).catch((error) => {
                setXmlUrlProvider(undefined);
            });
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "pdf").then((urlFileProvider) => {
                setPdfUrlProvider(urlFileProvider);
            }).catch((error) => {
                setPdfUrlProvider(undefined);
            });
        } else {
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "xml").then((urlFile) => {
                setXmlUrl(urlFile);
            }).catch((error) => {
                setXmlUrl(undefined);
            });
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
    }, [context.session, props.cfdi, props.isProvider]);

    return (
        <Grid>
            <Menu
                id="cfdi-menu-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}>

                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) && !props.inner &&
                    <MenuItem button component={Link} to={`/cfdis/${props.cfdi.id}/details_related?referer=${props.referer}`} onClick={props.onClose} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={props.isProvider ? xmlUrlProvider || "" : xmlUrl || ""} disabled={props.isProvider ? !xmlUrlProvider : !xmlUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadXmlIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={props.isProvider ? pdfUrlProvider || "" : pdfUrl || ""} disabled={props.isProvider ? !pdfUrlProvider : !pdfUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadPdfIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                        <MenuItem button onClick={onDownloadCfdis} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <Typography variant="inherit">{translate("cfdis.download_zip")}</Typography>
                        </MenuItem>
                }
            </Menu>
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export_single.title") as string}
                    message={translate("cfdis.popup.export_single.description", { "id": props.cfdi.identifier }) as string}
                    url={exportResult.url} onClose={onCloseDownloadExportationPopup} />)
            }
        </Grid>);
}
