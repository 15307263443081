import { callAPI, HTTPMethod } from "./API";
import { ShippingTemplateQueryParams, ShippingTemplates, ShippingTemplateRequest, ShippingTemplate } from "../model/ShippingTemplate";

const baseUrl = (tenantId: string) => {
    return `/tenants/${tenantId}/shipping-templates`;
};

export async function listShippingTemplates(tenantId: string, pageSize: number, offset: number, params: ShippingTemplateQueryParams): Promise<ShippingTemplates> {
    return await callAPI(baseUrl(tenantId), {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: pageSize.toString(),
            offset: offset.toString(),
        }
    });
}

export async function createShippingTemplate(tenantId: string, request: ShippingTemplateRequest): Promise<ShippingTemplate> {
    return await callAPI(baseUrl(tenantId), {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getShippingTemplate(tenantId: string, shippingTemplateId: string): Promise<ShippingTemplate> {
    return await callAPI(`${baseUrl(tenantId)}/${shippingTemplateId}`, {
        method: HTTPMethod.GET
    });
}

export async function updateShippingTemplate(tenantId: string, shippingTemplateId: string, request: ShippingTemplateRequest): Promise<ShippingTemplate> {
    return await callAPI(`${baseUrl(tenantId)}/${shippingTemplateId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteShippingTemplate(tenantId: string, shippingTemplateId: string): Promise<void> {
    return await callAPI(`${baseUrl(tenantId)}/${shippingTemplateId}`, {
        method: HTTPMethod.DELETE
    });
}
