import { callAPI, HTTPMethod } from "./API";
import { Employees, Employee, EmployeesQueryParams, EmployeeRequest } from "../model/Employee";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/employees`;

export async function listEmployees(tenantId: string, providerId: string, page: number, offset: number, params: EmployeesQueryParams): Promise<Employees> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            onlyVerifiedAndNewEmployees: params.only_verified_and_new_employees ? "true": "false",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createEmployee(tenantId: string, providerId: string, request: EmployeeRequest): Promise<Employee> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getEmployee(tenantId: string, providerId: string, id: string): Promise<Employee> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateEmployee(tenantId: string, providerId: string, id: string, request: EmployeeRequest): Promise<Employee> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteEmployee(tenantId: string, providerId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
