import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel } from "@material-ui/core";
import ProvidersIcon from "@material-ui/icons/Business";

import translate from "../i18n/Translator";
import { Entity, ProviderRequest, PROVIDER_TYPES, CONSIGNMENT_NOTE_OPTIONS, DECLARATION_FREQUENCIES, DeclarationFrequency } from "../model/Provider";
import { CONTRIES } from "../catalogs/Countries";
import { createProvider, getProvider, updateProvider, metadata } from "../api/ProviderAPI";
import { get as getTenantConfiguration } from "../api/TenantConfigurationApi";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Surface from "../components/Surface";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import { RfcValidator, PercentageValidator, GTZeroValidator } from "../components/Validators";
import SimpleSwitch from "../components/SimpleSwitch";
import Alert from "../components/Alert";
import { listWorkflows } from "../api/WorkflowAPI";
import { Workflows, WorkflowsQueryParams } from "../model/Workflow";
import { ReceptionDateValidationType, TenantConfiguration } from "../model/TenantConfiguration";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import { listCompanies } from "../api/CompanyAPI";
import { Companies, CompaniesQueryParams } from "../model/Company";
import { listExpedientTemplates } from "../api/ExpedientTemplateAPI";
import { ExpedientTemplateParams, ExpedientTemplates } from "../model/ExpedientTemplate";

export default function ProviderForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const percentageValidator = new PercentageValidator();
    const gTZeroValidator = new GTZeroValidator();
    const history = useHistory();
    const isEdit = !!match.params.providerId;
    const margin = "dense";
    const countryLabels = CONTRIES.map((code) => translate(`countries.${code}`) as string);
    const typeLabels = PROVIDER_TYPES.map((code) => translate(`providers.types.${code}`) as string);
    const typeConsignmentLabels = CONSIGNMENT_NOTE_OPTIONS.map((code) => translate(`providers.consignment_note.types.${code}`) as string);
    const declarationFrequencyLabels = DECLARATION_FREQUENCIES.map((code) => translate(`providers.declaration_frequency.${code}`) as string);
    const isSeEnabled = context.isGranted("TenantContributionsCreate");
    const rfcValidator = new RfcValidator();

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [limitReached, setLimitReached] = useState(false);
    const [maxDaysDefault, setMaxDaysDefault] = useState<string>("true");
    const [maxCfdisDefault, setMaxCfdisDefault] = useState<string>("true");
    const [maxCfdisPendingDocDefault, setMaxCfdisPendingDocDefault] = useState<string>("true");
    const [maxCfdisPaymentPendingCfdiConfigTenant, setMaxCfdisPaymentPendingCfdiConfigTenant] = useState<string>();
    const [maxCfdisPendingDocConfigTenant, setMaxCfdisPendingDocTenant] = useState<string>();
    const [creditDaysTenant, setCreditDaysTenant] = useState<string>();
    const [receptionDateValidationType, setReceptionDateValidationType] = useState<string>();
    const [invoiceReception, setInvoiceReception] = useState<boolean>(false);
    const [workflows, setWorkflows] = useState<MultiselectValue[]>([]);
    const [companies, setCompanies] = useState<MultiselectValue[]>([]);
    const [templatesExpedient, setTemplatesExpedient] = useState<string[]>([]);
    const [templatesExpedientLabels, setTemplatesExpedientLabels] = useState<string[]>([]);
    const [isForeign, setIsForeign] = useState<boolean>(false);
    const [hasExpedients] = useState(context.isGranted("ExpedientTemplateRead"));
    const [isProvider, setIsProvider] = useState<boolean>(true);
    const [hasAdvancePaymentModule, setHasAdvancePaymentModule] = useState<boolean>(false);
    const [request, setRequest] = useState<ProviderRequest>({
        name: "",
        rfc: "",
        type: "REGULAR",
        external_id: "",
        tax_id: "",
        country: "MEX",
        contacts: [],
        enabled: true,
        credit_days: 0,
        use_tenant_credit_days: true,
        max_payment_cfdi_pending: 0,
        use_max_payment_cfdi_pending_tenant_config: true,
        unlimited_payment_cfdi_pending: false,
        use_cash_discount: false,
        cash_discount_percentage: 0,
        cash_discount_days: 0,
        skip_reception_date_validation: false,
        use_advance_invoices: false,
        require_consignment_note: "NOT_REQUIRED",
        allow_invoice_reception: true,
        companies: [],
        template_id: "",
        only_se: false,
        upload_sipare: false,
        max_cfdi_pending_doc: 0,
        make_advance_payments: false,
        allow_make_advance_payments: false,
    } as ProviderRequest);

    const [validations, setValidations] = useState({
        "name": {
            valid: false
        } as InputRef,
        "rfc": {
            valid: false
        } as InputRef,
        "external_id": {
            valid: false
        } as InputRef,
        "tax_id": {
            valid: false
        } as InputRef,
    } as any);

    const submitPromise = (): Promise<Entity> => {
        if (isEdit && match.params.providerId) {
            return updateProvider(context.session!.tenant!.id, match.params.providerId, request);
        }
        return createProvider(context.session!.tenant!.id, request);
    };

    const initWorkflows = (workflows: Workflows, provider?: Entity) => {
        let items = workflows.items;
        if (provider && provider.workflows_response && provider.workflows_response.length > 0) {
            provider.workflows_response.forEach(wr => {
                if (workflows.items.findIndex(w => w.id === wr.id) < 0) {
                    items.push(wr);
                }
            });
        }

        let workflowValues = [] as MultiselectValue[];
        items.forEach(w => {
            workflowValues.push({ title: w.name, value: w.id } as MultiselectValue);
        });
        setWorkflows(workflowValues);
    };

    const initCompanies = (companies: Companies) => {
        let items = companies.items;
        let companiesValues = [] as MultiselectValue[];
        items.forEach(c => {
            companiesValues.push({ title: c.name, value: c.id } as MultiselectValue)
        });
        setCompanies(companiesValues);
    }

    const initExpedient = (requestTemp: ProviderRequest, expedientTemplates?: ExpedientTemplates, provider?: Entity) => {
        if (!expedientTemplates) return;

        setTemplatesExpedient(expedientTemplates.items.map(item => item.id));
        setTemplatesExpedientLabels(expedientTemplates.items.map(item => item.name));
        var template = expedientTemplates.items.filter(item => item.is_default === true).map(item => item.id);
        if (provider && provider.expedient_template_id) {
            template = expedientTemplates.items.filter(item => item.id === provider.expedient_template_id).map(item => item.id);
        }
        requestTemp.template_id = template[0] as string
        setRequest(requestTemp);
    }

    const setConfigurations = (configuration: TenantConfiguration | undefined) => {
        if (configuration) {
            let creditDays = configuration.configurations.find(c => c.property_name === "credit_days");
            if (creditDays && creditDays.active) {
                setCreditDaysTenant(creditDays.value);
            }
            let maxPaymentCfdi = configuration.configurations.find(c => c.property_name === "max_payment_cfdi_overdue");
            if (maxPaymentCfdi && maxPaymentCfdi.active) {
                setMaxCfdisPaymentPendingCfdiConfigTenant(maxPaymentCfdi.value);
            }
            let receptionInvoice = configuration.configurations.find(c => c.property_name === "allow_block_invoice_reception");
            if (receptionInvoice && receptionInvoice.active) {
                setInvoiceReception(receptionInvoice.value.toLowerCase() === "true");
            }
            let receptionDateType = configuration.configurations.find(c => c.property_name === "reception_date_validation_type");
            if (receptionDateType && receptionDateType.active) {
                setReceptionDateValidationType(receptionDateType.value);
            }

            let maxCfdiPendingDoc = configuration.configurations.find(c => c.property_name === "max_cfdi_pending_doc_overdue");
            if (maxCfdiPendingDoc && maxCfdiPendingDoc.active) {
                setMaxCfdisPendingDocTenant(maxCfdiPendingDoc.value);
            }
            setHasAdvancePaymentModule(configuration.has_advance_payment);
        }
    };

    async function emptyExpedientPromise(): Promise<ExpedientTemplates | undefined> {
        return undefined;
    }

    useEffect(() => {
        setStatus("loading");
        const params = { search: "", status: "AVAILABLE", product: "PAYABLE_DOCUMENTS" } as WorkflowsQueryParams;
        const workflowsPromise = listWorkflows(context.session!.tenant!.id, 0, 0, params);
        const tenantConfigurationPromise = context.isGranted("TenantConfigurationsRead") ? getTenantConfiguration(context.session!.tenant!.id) : undefined;
        const expedientPromise = hasExpedients ? listExpedientTemplates(context.session!.tenant!.id, {} as ExpedientTemplateParams, 0, 0) : emptyExpedientPromise();

        if (isEdit) {
            Promise.all([
                workflowsPromise,
                getProvider(context.session!.tenant!.id, match.params.providerId),
                tenantConfigurationPromise,
                expedientPromise
            ]).then(responses => {
                const provider = responses[1];
                let requestTemp = {
                    name: provider.name,
                    rfc: provider.rfc,
                    type: provider.type,
                    country: provider.country || "MEX",
                    workflows: provider.workflows,
                    external_id: provider.external_id,
                    tax_id: provider.tax_id,
                    enabled: provider.status === "ENABLED",
                    credit_days: provider.max_credit_days ? provider.max_credit_days : 0,
                    use_tenant_credit_days: provider.use_tenant_config_credit_days,
                    use_max_payment_cfdi_pending_tenant_config: provider.use_max_payment_cfdi_pending_tenant_config,
                    unlimited_payment_cfdi_pending: provider.unlimited_payment_cfdi_pending,
                    max_payment_cfdi_pending: provider.max_payment_cfdi_pending,
                    use_max_cfdi_pending_doc_tenant_config: provider.use_max_cfdi_pending_doc_tenant_config,
                    unlimited_cfdi_pending_doc: provider.unlimited_cfdi_pending_doc,
                    max_cfdi_pending_doc: provider.max_cfdi_pending_doc,
                    use_cash_discount: provider.use_cash_discount,
                    cash_discount_percentage: provider.cash_discount_percentage,
                    cash_discount_days: provider.cash_discount_days,
                    skip_reception_date_validation: provider.skip_reception_date_validation,
                    use_advance_invoices: provider.use_advance_invoices,
                    require_consignment_note: provider.require_consignment_note,
                    allow_invoice_reception: provider.allow_invoice_reception,
                    companies: provider.companies,
                    template_id: provider.expedient_template_id,
                    only_se: provider.only_se,
                    upload_sipare: provider.upload_sipare,
                    declaration_frequency: provider.declaration_frequency,
                    make_advance_payments: provider.make_advance_payments,
                    allow_make_advance_payments: provider.allow_make_advance_payments, 
                } as ProviderRequest;
                setMaxDaysDefault(provider.use_tenant_config_credit_days + "");
                setMaxCfdisDefault(provider.unlimited_payment_cfdi_pending ? "unlimited" :
                    provider.use_max_payment_cfdi_pending_tenant_config ? "true" : "false");

                setMaxCfdisPendingDocDefault(provider.unlimited_cfdi_pending_doc ? "unlimited" :
                    provider.use_max_cfdi_pending_doc_tenant_config ? "true" : "false");
                if (responses[0]) {
                    initWorkflows(responses[0], provider);
                }
                setIsForeign(provider.type === "FOREIGN");
                setConfigurations(responses[2]);
                if (responses[3]) {
                    initExpedient(requestTemp, responses[3], provider);
                } else {
                    setRequest(requestTemp);
                }
                setIsProvider(provider.type !== "VENDOR");
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        } else {
            Promise.all([
                workflowsPromise,
                metadata(context.session!.tenant!.id),
                tenantConfigurationPromise,
                expedientPromise
            ]).then(responses => {
                const metadata = responses[1];
                setLimitReached(metadata.limit_reached);
                if (responses[0]) {
                    initWorkflows(responses[0]);
                }
                setConfigurations(responses[2]);

                if (responses[3]) {
                    initExpedient(request, responses[3]);
                }
                let href = window.location.pathname;
                if (href.includes("vendors")) {
                    setRequest({ ...request, type: "VENDOR" });
                    setIsProvider(false);
                }
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        }

        if (context.isGrantedAny(["CompaniesRead", "TenantsCompaniesRead"])) {
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams).then((response) => {
                initCompanies(response);
            });
        }

        // eslint-disable-next-line
    }, [isEdit, match.params.providerId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        const fixedValue = value === "---" ? "" : value;
        if (name === "type" && fixedValue === "REGULAR") {
            setRequest({ ...request, country: "MEX", [name]: "REGULAR" });
            setIsForeign(false);
        } else if (name === "type" && fixedValue === "FOREIGN") {
            setRequest({ ...request, [name]: fixedValue, rfc: "XEXX010101000" });
            setIsForeign(true);
        } else if (name === "declaration_frequency") {
            setRequest({ ...request, declaration_frequency: fixedValue ? fixedValue as DeclarationFrequency : undefined });
        } else {
            setRequest({ ...request, [name]: fixedValue });
        }
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangeRadioMaxCfdis = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxCfdisDefault((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === "true") {
            setRequest({ ...request, max_payment_cfdi_pending: 0, use_max_payment_cfdi_pending_tenant_config: true, unlimited_payment_cfdi_pending: false });
        } else if ((event.target as HTMLInputElement).name === "unlimited") {
            setRequest({ ...request, max_payment_cfdi_pending: 0, use_max_payment_cfdi_pending_tenant_config: false, unlimited_payment_cfdi_pending: true });
        } else {
            setRequest({ ...request, use_max_payment_cfdi_pending_tenant_config: false, unlimited_payment_cfdi_pending: false });
        }
    };

    const onChangeRadioMaxCfdisPendingDoc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxCfdisPendingDocDefault((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === "true") {
            setRequest({ ...request, max_cfdi_pending_doc: 0, use_max_cfdi_pending_doc_tenant_config: true, unlimited_cfdi_pending_doc: false });
        } else if ((event.target as HTMLInputElement).name === "unlimited") {
            setRequest({ ...request, max_cfdi_pending_doc: 0, use_max_cfdi_pending_doc_tenant_config: false, unlimited_cfdi_pending_doc: true });
        } else {
            setRequest({ ...request, use_max_cfdi_pending_doc_tenant_config: false, unlimited_cfdi_pending_doc: false });
        }
    };

    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxDaysDefault((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === "true") {
            setRequest({ ...request, credit_days: 0, use_tenant_credit_days: true });
        } else {
            setRequest({ ...request, use_tenant_credit_days: false });
        }
    };

    const onChangedWorkflows = (selected: string[]) => {
        setRequest({ ...request, workflows: selected });
    }

    const onChangedCompanies = (selected: string[]) => {
        setRequest({ ...request, companies: selected });
    }

    const hasChangedCreditDays = (name: string, value: number, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const onSwitchActive = () => {
        setRequest({ ...request, enabled: !request.enabled });
    };

    const switchModeCashDiscount = () => {
        setRequest({ ...request, use_cash_discount: !request.use_cash_discount });
    };

    const onSwitchOmmitReceptionDate = () => {
        setRequest({ ...request, skip_reception_date_validation: !request.skip_reception_date_validation })
    };

    const onSwitchInvoiceReception = () => {
        setRequest({ ...request, allow_invoice_reception: !request.allow_invoice_reception })
    };

    const switchModeAdvanceInvoices = () => {
        setRequest({ ...request, use_advance_invoices: !request.use_advance_invoices });
    };

    const switchMakeAdvancePayments = () => {
        setRequest({...request, make_advance_payments: !request.make_advance_payments, allow_make_advance_payments: (!request.make_advance_payments ? request.allow_make_advance_payments:false)});
    };

    const switchAllowMakeAdvancePayments = () => {
        setRequest({...request, allow_make_advance_payments: !request.allow_make_advance_payments});
    };

    const switchOnlySE = () => {
        setRequest({ ...request, only_se: !request.only_se });
    };

    const switchUploadSipare = () => {
        setRequest({ ...request, upload_sipare: !request.upload_sipare });
    };

    const isValid = (validations: any) => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let tempValidations = { ...validations } as any;
        if(!request.use_cash_discount){
            delete tempValidations[`cash_discount_days`];
            delete tempValidations[`cash_discount_percentage`];
        }
        if (!isValid(tempValidations)) {
            return;
        }

        if (isProvider && workflows.length > 0 && (!request.workflows || request.workflows.length < 1)) {
            setWarning(translate("providers.manage_workflows.empty_selection") as string);
            return;
        }

        if (!request.companies || request.companies.length <= 0) {
            setWarning(translate("providers.asign_companies.empty") as string);
            return;
        }

        if (isProvider && !request.use_tenant_credit_days && request.credit_days < 0) {
            setWarning(translate("providers.warning_credit_days") as string);
            return;
        }
        if (isProvider && !request.unlimited_payment_cfdi_pending && !request.use_max_payment_cfdi_pending_tenant_config && request.max_payment_cfdi_pending < 0) {
            setWarning(translate("providers.warning_cfdis_payment_pending_cfdi") as string);
            return;
        }

        if (context.isGranted("ExpedientTemplateRead") && isProvider && (!request.template_id || request.template_id === "" || request.template_id === "---")) {
            setWarning(translate("providers.template_expedient.warning") as string);
            return;
        }

        setSubmitting(true);
        submitPromise().then((provider) => {
            if("VENDOR" !== provider.type){
                setRedirect(`/providers/${provider.id}`);
            }else{
                let success = translate("providers.vendors."+ (isEdit ? "success_edit" : "success_save"), {name: provider.name}) as string;
                setRedirect(`/vendors/${provider.id}/success/${success}`);
            }
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const getTitle = () => {
        if (isProvider) {
            return translate(isEdit ? "providers.edit_provider" : "providers.new_provider");
        }
        return translate(isEdit ? "providers.vendors.edit" : "providers.vendors.new");
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={getTitle()} icon={<ProvidersIcon />} className="FormSurface" titleActions={
                        <SimpleSwitch
                            value="active"
                            label={request.enabled ? translate("providers.enabled") as string : translate("providers.disabled") as string}
                            small_label={true}
                            checked={request.enabled}
                            onChanged={onSwitchActive}
                            placement="start"
                            color="primary"
                            disabled={submitting || limitReached} />
                    }>
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            {limitReached && (
                                <Alert variant="info" margin={1} plain
                                    title={translate("usage.providers.title")}
                                    subheader={translate("usage.providers.subtitle")} />
                            )}
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name} label={translate("providers.name") as string}
                                        required={true} disabled={submitting || limitReached}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="rfc" name="rfc"
                                        value={request.rfc} label={translate("providers.rfc") as string}
                                        required={!isForeign} disabled={submitting || limitReached || isForeign}
                                        margin={margin}
                                        validator={rfcValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {isProvider &&
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="type" name="type"
                                            value={request.type} label={translate("providers.types.title") as string}
                                            required={true} disabled={submitting || limitReached}
                                            options={PROVIDER_TYPES} optionLabels={typeLabels}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="country" name="country" label={translate("providers.country") as string}
                                        disabled={isProvider && (submitting || limitReached || request.type === "REGULAR")} required={true}
                                        value={request.country}
                                        options={CONTRIES}
                                        optionLabels={countryLabels}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {isProvider &&
                                    <Grid item xs={12}>
                                        <MultiselectDropList
                                            disabled={submitting || limitReached}
                                            title={translate("workflows.title") as string}
                                            margin={margin}
                                            elementos={workflows}
                                            value={isEdit ? workflows.filter(w => request.workflows?.includes(w.value)) : undefined}
                                            onChanged={onChangedWorkflows} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="external_id" name="external_id"
                                        value={request.external_id} label={translate("providers.external_id") as string}
                                        required={false} disabled={submitting || limitReached}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="tax_id" name="tax_id"
                                        value={request.tax_id} label={translate("providers.tax_id") as string}
                                        required={request.rfc === "XEXX010101000"} disabled={submitting || limitReached}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {context.isGrantedAny(["CompaniesRead", "TenantsCompaniesRead"]) && 
                                    <Grid item xs={12}>
                                        <MultiselectDropList
                                            required={true}
                                            disabled={submitting || limitReached}
                                            title={translate("providers.companies.title") as string}
                                            margin={margin}
                                            elementos={companies}
                                            value={companies.filter(c => request.companies?.includes(c.value))}
                                            onChanged={onChangedCompanies} />
                                    </Grid>
                                }
                                {context.isGrantedAny(["ExpedientTemplateRead", "ExpedientTemplatesList"]) &&
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="template_id" name="template_id"
                                            value={request.template_id} label={translate("providers.template_expedient.title") as string}
                                            required={hasExpedients} disabled={submitting || limitReached}
                                            options={templatesExpedient} optionLabels={templatesExpedientLabels}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                    </Grid>
                                }
                                {isProvider &&
                                    <Grid>
                                        <Box>
                                            <Grid item xs={12}>
                                                <br />
                                                <FormControl component="fieldset" >
                                                    <FormLabel component="legend">{translate("providers.credit_days_title") as string}</FormLabel>
                                                    <RadioGroup aria-label="maxDaysDefault" name="maxDaysDefault1" value={maxDaysDefault} onChange={onChangeRadio} >
                                                        <FormControlLabel value="true" control={<Radio />} label={translate("providers.max_days_default", { "days": creditDaysTenant }) as string} />
                                                        <FormControlLabel value="false" control={<Radio />} label={translate("providers.max_days_by_provider") as string} />
                                                    </RadioGroup>
                                                </FormControl>
                                                {maxDaysDefault === "true" ? undefined :
                                                    <ValidatedInput type="number" id="credit_days" name="credit_days"
                                                        value={request.credit_days + ""} label={translate("providers.credit_days") as string}
                                                        isValueNumber
                                                        required={!maxDaysDefault}
                                                        margin={margin}
                                                        onValueChanged={hasChangedCreditDays} />
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br />
                                                <FormControl component="fieldset" >
                                                    <FormLabel component="legend">{translate("providers.max_payment_cfdi_pending.title") as string}</FormLabel>
                                                    <RadioGroup aria-label="maxCfdisDefault" name="maxCfdisDefault2" value={maxCfdisDefault} onChange={onChangeRadioMaxCfdis} >
                                                        <FormControlLabel value="true" control={<Radio />} label={translate("providers.max_payment_cfdi_pending.default", { "limit": maxCfdisPaymentPendingCfdiConfigTenant }) as string} />
                                                        <FormControlLabel value="unlimited" name="unlimited" control={<Radio />} label={translate("providers.max_payment_cfdi_pending.unlimited") as string} />
                                                        <FormControlLabel value="false" control={<Radio />} label={translate("providers.max_payment_cfdi_pending.customize_limit") as string} />
                                                    </RadioGroup>
                                                </FormControl>
                                                {maxCfdisDefault === "false" ?
                                                    <ValidatedInput type="number" id="max_payment_cfdi_pending" name="max_payment_cfdi_pending"
                                                        value={request.max_payment_cfdi_pending + ""} label={translate("providers.max_payment_cfdi_pending.title") as string}
                                                        isValueNumber
                                                        required={!maxCfdisDefault}
                                                        margin={margin}
                                                        onValueChanged={hasChangedCreditDays} /> : undefined
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SimpleSwitch
                                                    value="use_discount"
                                                    label={translate("providers.cash_discount.switch") as string}
                                                    checked={request.use_cash_discount}
                                                    onChanged={switchModeCashDiscount}
                                                    placement="end"
                                                    disabled={submitting || limitReached} />
                                            </Grid>
                                            {request.use_cash_discount ?
                                                <Grid item xs={12}>
                                                    <Grid container justify="flex-end" spacing={1}>
                                                        <Grid item xs={6}>
                                                            <ValidatedInput type="number" id="cash_discount_percentage" name="cash_discount_percentage" label={translate("providers.cash_discount.percentage") as string}
                                                                disabled={!request.use_cash_discount} required={request.use_cash_discount}
                                                                value={request.cash_discount_percentage + ""}
                                                                isValueNumber
                                                                margin={margin}
                                                                validator={percentageValidator}
                                                                onValueChanged={hasChanged} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <ValidatedInput type="number" id="cash_discount_days" name="cash_discount_days" label={translate("providers.cash_discount.days") as string}
                                                                disabled={!request.use_cash_discount} required={request.use_cash_discount}
                                                                value={request.cash_discount_days + ""}
                                                                isValueNumber
                                                                margin={margin}
                                                                validator={gTZeroValidator}
                                                                onValueChanged={hasChanged} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                : undefined}
                                            {receptionDateValidationType && receptionDateValidationType !== ReceptionDateValidationType.NONE &&
                                                <Grid item xs={12}>
                                                    <SimpleSwitch
                                                        value="ommit_date_validation"
                                                        label={translate("providers.ommit_date_validation") as string}
                                                        checked={request.skip_reception_date_validation}
                                                        onChanged={onSwitchOmmitReceptionDate}
                                                        placement="end"
                                                        disabled={submitting || limitReached} />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <SimpleSwitch
                                                    value="use_advance_invoices"
                                                    label={translate("providers.advance_invoices.switch") as string}
                                                    checked={request.use_advance_invoices}
                                                    onChanged={switchModeAdvanceInvoices}
                                                    placement="end"
                                                    disabled={submitting || limitReached}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="text" id="require_consignment_note" name="require_consignment_note"
                                                    value={request.require_consignment_note} label={translate("providers.consignment_note.title") as string}
                                                    required={true} disabled={submitting || limitReached}
                                                    options={CONSIGNMENT_NOTE_OPTIONS} optionLabels={typeConsignmentLabels}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                            {hasAdvancePaymentModule && 
                                            <Grid>
                                                <Grid item xs={12}>
                                                <SimpleSwitch
                                                    value="make_advance_payments"
                                                    label={translate("providers.advance_payments.make_advance_payments") as string}
                                                    checked={request.make_advance_payments}
                                                    onChanged={switchMakeAdvancePayments}
                                                    placement="end"
                                                    disabled={submitting || limitReached}
                                                />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SimpleSwitch
                                                        value="allow_make_advance_payments"
                                                        label={translate("providers.advance_payments.allow_make_advance_payments") as string}
                                                        checked={request.allow_make_advance_payments}
                                                        onChanged={switchAllowMakeAdvancePayments}
                                                        placement="end"
                                                        disabled={submitting || limitReached || !request.make_advance_payments}
                                                    />
                                                </Grid>
                                            </Grid>}
                                            {invoiceReception &&
                                                <Grid item xs={12}>
                                                    <SimpleSwitch
                                                        value="allow_invoice_reception"
                                                        label={translate("providers.invoice_reception.switch") as string}
                                                        checked={request.allow_invoice_reception}
                                                        onChanged={onSwitchInvoiceReception}
                                                        placement="end"
                                                        disabled={!invoiceReception} />
                                                </Grid>
                                            }
                                            {isSeEnabled && (<>
                                                <Grid item xs={12}>
                                                    <Box pt={3}>
                                                        <Typography variant="subtitle1" component="h5">
                                                            {translate("cfdis_se.se")}
                                                        </Typography>
                                                        <ValidatedInput type="text" id="declaration_frequency" name="declaration_frequency"
                                                            value={request.declaration_frequency} label={translate("providers.declaration_frequency.title") as string}
                                                            disabled={submitting || limitReached || isEdit}
                                                            options={DECLARATION_FREQUENCIES} optionLabels={declarationFrequencyLabels}
                                                            margin={margin}
                                                            onValueChanged={hasChanged} />
                                                        <SimpleSwitch
                                                            value="only_se"
                                                            label={translate("providers.only_se") as string}
                                                            checked={request.only_se}
                                                            onChanged={switchOnlySE}
                                                            placement="end"
                                                            disabled={submitting || limitReached}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <SimpleSwitch
                                                    value="upload_sipare"
                                                    label={translate("providers.upload_sipare") as string}
                                                    checked={request.upload_sipare}
                                                    onChanged={switchUploadSipare}
                                                    placement="end"
                                                    disabled={submitting || limitReached}
                                                />
                                                <Grid item xs={12}>
                                                <br />
                                                <FormControl component="fieldset" >
                                                    <FormLabel component="legend">{translate("providers.max_cfdi_pending_doc.title") as string}</FormLabel>
                                                    <RadioGroup aria-label="maxCfdisPendingDocDefault" name="maxCfdisPendingDocDefault" value={maxCfdisPendingDocDefault} onChange={onChangeRadioMaxCfdisPendingDoc} >
                                                        <FormControlLabel value="true" control={<Radio />} label={translate("providers.max_cfdi_pending_doc.default", { "limit": maxCfdisPendingDocConfigTenant }) as string} />
                                                        <FormControlLabel value="unlimited" name="unlimited" control={<Radio />} label={translate("providers.max_cfdi_pending_doc.unlimited") as string} />
                                                        <FormControlLabel value="false" control={<Radio />} label={translate("providers.max_cfdi_pending_doc.customize_limit") as string} />
                                                    </RadioGroup>
                                                </FormControl>
                                                {maxCfdisPendingDocDefault === "false" ?
                                                    <ValidatedInput type="number" id="max_cfdi_pending_doc" name="max_cfdi_pending_doc"
                                                        value={request.max_cfdi_pending_doc + ""} label={translate("providers.max_cfdi_pending_doc.title") as string}
                                                        isValueNumber
                                                        required={!maxCfdisPendingDocDefault}
                                                        margin={margin}
                                                        onValueChanged={hasChangedCreditDays} /> : undefined
                                                }
                                            </Grid>
                                            </>)}
                                            
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting || limitReached}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                {isEdit && (
                                                    <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                        {translate("buttons.cancel")}
                                                    </Button>
                                                )}
                                                {!isEdit && (
                                                    <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                        {translate("buttons.cancel")}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}
