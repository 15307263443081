import { Tenant } from "./Tenant";

export interface Plugin {
    id: string;
    name: string;
    type: PluginType;
    step: PluginStep;
    value: string;
    created_at: Date;
}

export interface Plugins {
    items: Plugin[];
    total: number;
    tenant?: Tenant;
}

export interface PluginsQueryParams {
    search: string;
}

export interface PluginRequest {
    name: string;
    type: PluginType;
    step: PluginStep;
    value: string;
}

export type PluginType = 'CLASSPATH' | 'LAMBDA';

export type PluginStep = 'VALIDATION' | 'BEFORE_SEND' | 'RECEIVE' | 'AFTER_RECEIVE' | 'BEFORE_STATUS_TRANSITION';

export const TYPES: PluginType[] = ['CLASSPATH', 'LAMBDA'];

export const STEPS: PluginStep[] = ['VALIDATION', 'BEFORE_SEND', 'RECEIVE', 'AFTER_RECEIVE', 'BEFORE_STATUS_TRANSITION'];