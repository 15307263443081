import React, { useState, useEffect } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Configuration } from "../model/TenantConfiguration";
import { OptionsCatalogAutocomplete } from "../model/ConfigurationCatalog";
import { WarningSnackbar } from "../components/Snackbars";
import Autocomplete from '@material-ui/lab/Autocomplete';


interface TenantConfigurationUpdatePopUpProps {
    configuration: Configuration;
    title: string;
    description: string;
    type: string;
    optionsCatalog: OptionsCatalogAutocomplete[];
    onUpdate(value: string): any;
    onClose(): any;
}

export default function TenantConfigurationUpdatePopUp(props: TenantConfigurationUpdatePopUpProps) {
    const [title, setTitle] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [warning, setWarning] = useState<string>();
    const regexp = new RegExp(`^-?[0-9]*$`);
    const [state, setState] = React.useState({
        checkedA: true,
    });

    const load = () => {
        setTitle(props.title);
        setType(props.type);
        setDescription(props.description);
        setValue(props.configuration.value);
        if (props.type === "BOOLEAN") {
            setState({ ...state, checkedA: props.configuration.value === "true" ? true : false });
        }
    }

    useEffect(load, [props]);

    const onSubmit = () => {
        if (value === props.configuration.value) {
            props.onClose();
        }
        if (value === undefined) {
            setWarning(translate("tenant_configurations.empty") as string);
            return;
        }

        if(type === "DOUBLE") {
            if (Number(value) < 0) {
                setWarning(translate("tenant_configurations.not_acceptable_number") as string);
                return;
            } 
        } else if(type === "INTEGER") {
            if (Number(value) < 0 || (value && Number(value) > 0 && !regexp.test(value))) {
                setWarning(translate("tenant_configurations.not_acceptable_integer") as string);
                return;
            } 
        } else if (isEmptyValue(value)){
            setWarning(translate("tenant_configurations.empty") as string)
            return;
        }
        if (type === "BOOLEAN") {
            props.onUpdate(state.checkedA ? "true" : "false");
        } else {
            props.onUpdate(value);
        }
    };

    const isEmptyValue = (valuee : string) => {
        if(valuee){
            let valueClean = valuee.toString().replace("&nbsp;", "");
            valueClean = valueClean === undefined ? "" : valueClean;
            if (valueClean === undefined || 0 === valueClean.toString().trim().length) {
                return true;
                }
            else {
                return false;
            }
        }
        return true;
    }

    const AntSwitch = withStyles((theme: Theme) =>
        createStyles({
            root: {
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.grey[500],
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    },
                },
            },
            thumb: {
                width: 12,
                height: 12,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
        }),
    )(Switch);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setValue(value);
    };

    const onFilterChanged = (value: string, inputRef?: any) => {
        if (value !== undefined) {
            setValue(value);
        }
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    return (

        <DialogPopup open
            title={title}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.accept")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
            <Grid container>
                <Typography variant="body2">
                    {description}
                </Typography>
                {(type === "STRING" || type === "JSON" || type === "EMAIL" || type === "URL") &&
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="name" name="name"
                            value={value} label={""}
                            required={true}
                            margin={"dense"}
                            onValueChanged={hasChanged} />
                    </Grid>
                }
                {(type === "INTEGER" || type === "DOUBLE") &&
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="number"
                            id={"amount"}
                            name={"amount"}
                            value={value}
                            isValueNumber={true}
                            label={""}
                            required={false}
                            margin="dense"
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                }
                {type === "BOOLEAN" &&
                    <Grid style={{ paddingTop: "10px" }} item xs={12}>
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>{translate("tenant_configurations.disable") as string}</Grid>
                            <Grid item>
                                <AntSwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />
                            </Grid>
                            <Grid item>{translate("tenant_configurations.enable") as string}</Grid>
                        </Grid>
                    </Grid>
                }
                {type === "CATALOG" &&
                    <Grid style={{ paddingTop: "10px" }} item xs={12} md={12} sm={12} lg={12}>
                        <Grid item xs={12} sm lg="auto">
                            <Autocomplete
                                size="small"
                                options={props.optionsCatalog}
                                getOptionLabel={(elemento) => elemento.title}
                                noOptionsText={translate("tenant_configurations.pop_up.empty_options") as string}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={translate("tenant_configurations.pop_up.select_an_option") as string} fullWidth />
                                )}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        onFilterChanged(newValue.value);
                                    } else {
                                        onFilterChanged("");
                                    }
                                }}
                                getOptionSelected={(opt, val) => opt.value === val.value}
                            />

                        </Grid>
                    </Grid>
                }
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}
