import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import { ProviderMembershipTaxStamp } from "../model/ProviderMembershipTaxStamp";

interface ProviderMembershipMenuProps {
    providerMembership: ProviderMembershipTaxStamp;
    anchor: HTMLElement | null;
    onClose(): any;
    onApprove(): any;
    onDisapprove(): any;
}

export default function ProviderMembershipMenu(props: ProviderMembershipMenuProps) {
    const context = useContext(AppContext);

    return (
        <Grid>
            <Menu
                id="announcement-menu-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}>

                {context.isGrantedAny(["PackagesTaxStampUpdate"]) && props.providerMembership.status === "TO_VERIFY" &&
                    <MenuItem button onClick={props.onApprove} dense>
                        <ListItemIcon>
                            <EditIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.activate")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["PackagesTaxStampUpdate"]) && props.providerMembership.status === "ACTIVE" &&
                    <MenuItem button onClick={props.onDisapprove} dense>
                        <ListItemIcon>
                            <EditIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("buttons.desactivate")}</Typography>
                    </MenuItem>
                }
            </Menu>
        </Grid>);
}
