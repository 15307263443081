import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { Grid, Typography, Divider, Button, Fab } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/GetApp';
import CustomBackdrop from "../components/CustomBackdrop";
import FindInPageIcon from '@material-ui/icons/FindInPageTwoTone';

import { DocsPending, DocsPendingQueryParams } from "../model/SEDocsPending";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import {list as listDocsPending, exportDocs } from "../api/SEDocsPendingAPI";
import Gridable from "../components/Gridable";
import ValidatedInput from "../components/ValidatedInput";
import DateFormat from "../components/DateFormat";
import { formatDatesToString } from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import { getProvidersWithPendingDocs } from "../api/ProviderAPI";
import Progress from "../components/Progress";

export default function SEPendingDocsByProvider() {
    const context = useContext(AppContext);
    const history = useHistory();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [processing, setProcessing] = useState(false);


    const [data, setData] = useState<DocsPending>();
    const [providerIds, setProviderIds] = useState<string[]>([]);
    const [providerNames, setProviderNames] = useState<string[]>([]);
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): DocsPendingQueryParams => {
        return {
            "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : ""
        } as DocsPendingQueryParams;
    };
    const [workingParams, setWorkingParams] = useState<DocsPendingQueryParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [showReport, setShowReport] = useState<boolean>(false);

    useEffect(() => {
        setStatus("loading");
        getProvidersWithPendingDocs(context.session!.tenant!.id).then((response) => {
            let providerIds = [];
            let providerNames = [];
            if(response.total > 1){
                providerIds.push("ALL");
                providerNames.push(translate("pagination.all") as string);
            }
            response.items.forEach(provider => {
                providerIds.push(provider.id);
                providerNames.push(provider.name);
            });
            setProviderIds(providerIds);
            setProviderNames(providerNames);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        
    }, [context.session]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onGenerate = () => {
        let offset = getOffset(page, pageSize);
        let qs = queryString.parse(window.location.search);
        qs["provider"] = workingParams.provider;
        qs["page"] = "1";
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);

        if(workingParams.provider !== "" && workingParams.provider !== "---"){
            let params = workingParams;
            if(params.provider === "ALL"){
                params.provider = "";
            }
            setProcessing(true);
            listDocsPending(context.session!.tenant!.id, pageSize, offset, params).then((response) => {
                if(response.process_background){
                    setSuccess(translate ("reports.se_pending_docs_provider.process_background") as string);
                } else {
                    setShowReport(true);
                    setData(response);
                }
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setProcessing(false);
            });
        } else {
            setWarning(translate("reports.se_pending_docs_provider.warning_provider") as string);
        }
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };
    
    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const onDownloadExcel = () => {
        setProcessing(true);
        exportDocs(context.session!.tenant!.id, workingParams).then((response) => {
            window.open(response.url, "_blank");
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setProcessing(false);
        });
    }

    if(status === "loading"){
        return (<Progress />);
    }
    return (
        <Pagination title={translate("reports.se_pending_docs_provider.title")} icon={<FindInPageIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("ProvidersUpdate") && showReport ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("reports.se_pending_docs_provider.download") as string} onClick={onDownloadExcel} >
                            <DownloadIcon />
                        </Fab>
                    </Grid>
                </Grid>) : undefined
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm={true} md={true} lg>
                    <ValidatedInput type="text" id="provider" name="provider" label={translate("reports.se_pending_docs_provider.select_provider") as string}
                        margin="dense" disabled={false}
                        value={workingParams.provider} onValueChanged={onFilterChanged}
                        options={providerIds}
                        optionLabels={providerNames}
                         />
                </Grid>
                <Grid item xs="auto">
                    <Button type="button" variant="outlined" color="primary" size="medium" onClick={onGenerate}>
                        {translate("reports.se_pending_docs_provider.generate")}
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            {showReport &&
                <Gridable
                    items={data ? data.items : []}
                    loading={status === "loading"}
                    error={status !== "loading" && status !== "loaded" ? status : undefined}
                    empty={translate("reports.se_pending_docs_provider.empty") as string}
                    onClick={() => {}}
                    columns={[
                        {
                            title: translate("reports.se_pending_docs_provider.columns.consecutive") as string,
                            converter: (doc) => doc.consecutive,
                            xs: 1,
                        },
                        {
                            title: translate("reports.se_pending_docs_provider.columns.type") as string,
                            converter: (doc) => translate(`contributions.to_validate.types.${doc.type}`),
                            xs: 3,
                        },
                        {
                            title: translate("reports.se_pending_docs_provider.columns.date"),
                            converter: (doc) => doc.date ? <DateFormat date={doc.date} format="MMMM YYYY" /> : translate(`reports.se_pending_docs_provider.not_apply`),
                            xs: 3,
                        },
                        {
                            title: translate("reports.se_pending_docs_provider.columns.payroll"),
                            converter: (doc) => (
                                <>
                                    <Typography variant="caption" color="textSecondary" component="h6">
                                        {doc.name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary" component="h6">
                                        {formatDatesToString(doc.start_date, doc.end_date)}
                                    </Typography>
                                </>
                            ),
                            xs: 2,
                        },
                        {
                            title: translate("reports.se_pending_docs_provider.columns.serie_folios") as string,
                            converter: (doc) => (<Ellipsis text={doc.serie_folios} uppercased={false} />),
                            xs: 3,
                        }
                    ]}
                />
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={processing} message={translate("provider_classifications.processing") as string} />
        </Pagination>
    );
}
