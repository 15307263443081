import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";

import translate from "../i18n/Translator";
import { SocialContribution, SocialContributionFileType } from "../model/SocialContribution";
import { AppContext } from "../context/AppContext";
import { getSocialContributionFileUrl } from "../api/SocialContributionAPI";
import { getSocialContributionFileUrl as getProviderSocialContributionFileUrl } from "../api/ProviderSocialContributionAPI";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';

interface SocialContributionMenuProps {
    socialContribution: SocialContribution;
    anchor: HTMLElement | null;
    canEdit: boolean;
    onClose(): any;
    onDelete(): any;
}

export default function SocialContributionMenu(props: SocialContributionMenuProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const uploadSipare = context.session!.provider && context.session!.provider.upload_sipare;
    const [imss, setImss] = useState<string>();
    const [infonavit, setInfonavit] = useState<string>();
    const [bankVoucher, setBankVoucher] = useState<string>();
    const [sipare, setSipare] = useState<string>();

    const promiseGet = (fileType: SocialContributionFileType) => {
        if (providerId) {
            return getProviderSocialContributionFileUrl(tenantId, providerId, props.socialContribution.id, fileType);
        }
        return getSocialContributionFileUrl(tenantId, props.socialContribution.id, fileType);
    };

    const load = () => {
        Promise.all([
            promiseGet("imss"),
            promiseGet("infonavit"),
            promiseGet("bank_voucher"),
            promiseGet("sipare"),
        ]).then(urls => {
            setImss(props.socialContribution.imss_cfdi_id ? urls[0] : undefined);
            setInfonavit(props.socialContribution.infonavit_cfdi_id ? urls[1] : undefined);
            setBankVoucher(props.socialContribution.bank_voucher ? urls[2] : undefined);
            setSipare(props.socialContribution.sua ? urls[3] : undefined);
        }).catch(error => {
            console.error(error);
        });
    };

    useEffect(load, [tenantId, providerId, props.socialContribution]);

    return (
        <Menu
            id="social-contribution-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["SocialContributionsUpdate", "ProviderSocialContributionsUpdate"]) && props.socialContribution.can_edit &&
                <MenuItem button component={Link} to={`/contributions/social/${props.socialContribution.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {uploadSipare && 
                <MenuItem
                button
                onClick={props.onClose}
                dense
                component="a"
                href={sipare || "#"}
                target="_blank"
                rel="noopener noreferrer"
                disabled={!sipare}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("social_contributions.sipare")}</Typography>
            </MenuItem>
            }
            <MenuItem button onClick={props.onClose} dense component="a" href={bankVoucher || "#"} target="_blank" rel="noopener noreferrer" disabled={!bankVoucher}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("social_contributions.bank_voucher")}</Typography>
            </MenuItem>
            <MenuItem button onClick={props.onClose} dense component="a" href={imss || "#"} target="_blank" rel="noopener noreferrer" disabled={!imss}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("social_contributions.imss")}</Typography>
            </MenuItem>
            {props.socialContribution?.required_infonavit && (
                <MenuItem button onClick={props.onClose} dense component="a" href={infonavit || "#"} target="_blank" rel="noopener noreferrer" disabled={!infonavit}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.infonavit")}</Typography>
                </MenuItem>
            )}
            {context.isGrantedAny(["SatContributionsDelete", "ProviderSatContributionsDelete"]) && props.socialContribution.can_delete &&
                <MenuItem button onClick={props.onDelete} dense>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.delete")}</Typography>
            </MenuItem>
            }
        </Menu>
    );

}