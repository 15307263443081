import React, { useContext, useState, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';

import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import { getTenantCfdiFileUrl, exportCfdiFiles } from "../api/TenantCfdiApi";
import { ExportCfdisResponse } from "../model/TenantCfdi";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import BlockIcon from '@material-ui/icons/Block';

interface CreditNotesCfdiMenuProps {
    creditNoteId: string;
    serieFolio: string;
    anchor: HTMLElement | null;
    inner?: boolean;
    referer: string;
    isForeign: boolean;
    onClose(): any;
    onAuthorize?(): any;
    onReject?(): any;
    onCancel(): any;
}

export default function CreditNotesCfdiMenu(props: CreditNotesCfdiMenuProps) {
    const context = useContext(AppContext);
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();

    const onDownloadCfdis = () => {
        exportCfdiFiles(context.session!.tenant!.id, props.creditNoteId).then((response) => {
            if (response.url) {
                setExportResult(response);
            }
        });
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    useEffect(() => {
        getTenantCfdiFileUrl(context.session!.tenant!.id, props.creditNoteId, "xml").then((urlFile) => {
            setXmlUrl(urlFile);
        }).catch((error) => {
            setXmlUrl(undefined);
        });
        getTenantCfdiFileUrl(context.session!.tenant!.id, props.creditNoteId, "pdf").then((urlFile) => {
            setPdfUrl(urlFile);
        }).catch((error) => {
            setPdfUrl(undefined);
        });
    }, [context.session, props.creditNoteId]);

    return (
            <Menu
                id="cfdi-menu-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}>

                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "CxpCFDIsRead"]) && !props.inner &&
                    <MenuItem button component={Link} to={`/cfdis/${props.creditNoteId}/details_related?referer=${props.referer}`} onClick={props.onClose} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead"]) && !props.isForeign &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={xmlUrl || ""} disabled={!xmlUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadXmlIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead"]) && !props.isForeign &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={pdfUrl || ""} disabled={!pdfUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadPdfIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "SenderCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={onDownloadCfdis} dense>
                        <ListItemIcon>
                            <DownloadIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate(props.isForeign ? "cfdis.download_credit_note" : "cfdis.download_zip")}</Typography>
                    </MenuItem>
                }
                <MenuItem button onClick={props.onCancel} dense>
                    <ListItemIcon>
                        <BlockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.cancel_cfdi.title_cn")}</Typography>
                </MenuItem>
                 {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export_single.title") as string}
                    message={translate("cfdis.popup.export_single.description", props.serieFolio) as string}
                    url={exportResult.url} onClose={onCloseDownloadExportationPopup} />
            )
            }
            </Menu>);
}
