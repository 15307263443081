import React, { useContext, useState } from "react";

import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ListItemIcon } from "@material-ui/core";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";
import translate from "../../i18n/Translator";
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';

interface DownloadValidationsPdfOptionProps {
    onDownloadSelected?(): void;
    onDownload?(): void;
}

export default function DownloadValidationsPdfOption(props: DownloadValidationsPdfOptionProps) {

    const [openExport, setOpenExport] = useState<boolean>(false);

    const handleClick = () => {
        setOpenExport(!openExport);
    };
    if (!props.onDownloadSelected && !props.onDownloadSelected) {
        return (<></>);
    }

    return (
        <Grid >
            <Grid item xs="auto" >
                <ListItem button onClick={handleClick} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("cfdis.menus.download_validations")} />
                    {openExport ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Grid>
            <Grid item xs="auto" >
                <Collapse in={openExport} timeout="auto" unmountOnExit>
                    <List component="div"  >
                       { props.onDownloadSelected &&
                        <ListItem button onClick={props.onDownloadSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.selected")} />
                        </ListItem>}
                       { props.onDownload && 
                        <ListItem button onClick={props.onDownload} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                        </ListItem>}
                    </List>
                </Collapse>
            </Grid>
        </Grid>
    );
}
