import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Typography, Tabs, Tab, Grid, Fab, Chip } from "@material-ui/core";
import Progress from "../components/Progress";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import DetailsTab from "./DetailsTab";

import ProjectMembersTab from "./ProjectMembersTab";
import ProjectBudgetTrackHistoryTab from "./ProjectBudgetTrackHistoryTab";
import ProjectHistoryTab from "./ProjectHistoryTab";

import Surface from "../components/Surface";
import PaymentIcon from '@material-ui/icons/Payment';
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EditIcon from "@material-ui/icons/Edit";
import StartIcon from "@material-ui/icons/PlayArrow";
import { isRoleOrParent } from "../model/Role";
import { Project, ProjectHistoryRecord, MemberTeamHistory } from "../model/Project";
import { Team } from "../model/Team";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import { getExtendedFieldsTemplate } from "../api/ExtendedFieldsTemplateAPI";
import { ExtendedFieldsTemplate, ExtendedFieldsTemplateSegment } from "../model/ExtendedFieldsTemplate";
import { getTenantFieldsCatalog } from "../api/TenantFieldAPI";
import { TenantFieldCatalog } from '../model/TenantFieldCatalog';
import { updateStatusDraftToStart } from "../api/ProjectAPI";
import ConfirmPopup from "../components/ConfirmationPopup";
import { Redirect } from "react-router-dom";

interface ProjectTab {
    name: string;
    title: string;
    actions?: string[];
}

interface ProjectOverviewProps {
    project?: Project;
    teams: Team[];
    onEditProject(project: Project): any;
    onClose(): any;
}

export default function ProjectOverview(props: ProjectOverviewProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const qs = queryString.parse(window.location.search);
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const classes = useStyles();

    const [projectHistory, setProjectHistory] = useState<ProjectHistoryRecord[]>();
    const [project, setProject] = useState<Project>();
    const [teams, setTeams] = useState<MemberTeamHistory[]>();
    const [extendedFieldsTemplate, setExtendedFieldsTemplate] = useState<ExtendedFieldsTemplate>({} as ExtendedFieldsTemplate);
    const [tenantFieldCatalog, setTenantFieldCatalog] = useState<TenantFieldCatalog>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string | undefined>();

    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const isOwner = isRoleOrParent(context.session!.role, "owner") || isRoleOrParent(context.session!.role, "project_manager");

    const allTabs = [
        {
            name: "details",
            title: translate("requisitions.projects.details.details_title" as string)
        },
        {
            name: "members",
            title: translate("requisitions.projects.details.tab_members_title" as string)
        },
        {
            name: "track",
            title: translate("requisitions.projects.details.tab_track_title" as string)
        },
        {
            name: "history",
            title: translate("requisitions.projects.details.tab_history_title" as string)
        }
    ] as ProjectTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };


    const [tabs, setTabs] = useState<ProjectTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);

    const load = () => {
        setStatus("loading");
        setTeams(fillMembersTeamHistory());
        setProject(props.project);
        setProjectHistory(props.project!.history);
        setTabs(allTabs);
        getTenantFieldsCatalog(context.session!.tenant!.id).then((respTenantFieldsCatalog) => {
            setTenantFieldCatalog(respTenantFieldsCatalog);
            if (props.project?.template?.id) {
                getExtendedFieldsTemplate(context.session!.tenant!.id, props.project?.template?.id as string).then(response => {
                    setExtendedFieldsTemplate(response);
                    setStatus("loaded")
                }).catch(error => {
                    setStatus(error.message);
                });
            } else {
                setStatus("loaded")
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [props]);

    const fillMembersTeamHistory = () => {
        let teamMembers = [] as MemberTeamHistory[];
        props.teams.forEach((team) => {
            team.members.forEach((member) => {
                let memberTeam = {
                    team_name: team.name,
                    member_name: member.name,
                    role_team: member.role
                } as MemberTeamHistory;
                teamMembers.push(memberTeam);
            });
        });
        return teamMembers;
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line
    }, [qs.tab, tabs]);


    const tabContent = (index: number) => {
        if (!project) return;

        if (!context.session || !context.session.tenant) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return (
                    <DetailsTab tenantId={context.session.tenant.id} project={project}
                        isReaderOnly={isReaderOnly} onSetProject={setProject} extendedFieldsTemplate={extendedFieldsTemplate} tenantFieldCatalog={tenantFieldCatalog}/>
                );
            case "members":
                return (
                    <ProjectMembersTab history={teams} />
                );
            case "track":
                return (
                    <ProjectBudgetTrackHistoryTab history={projectHistory} project={project} />
                );
            case "history":
                return (
                    <ProjectHistoryTab history={projectHistory} />
                );
            default:
                return <div></div>;
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !project) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onEditPaymentOrder = () => {
        props.onEditProject(project);
    }

    const startProject = () => {
        onStartProject(project);
    }

    const onStartProject = (project: Project) => {
            setOpenConfirmDialog(true);
    }

    const onConfirmStartProject = () => {
        updateStatusDraftToStart(context.session!.tenant!.id, project.id)
            .then((response) => {
                onCloseConfirmStartProject();
                setProject(response);
                setSuccess(translate("requisitions.projects.start_project.start_project_success") as string);
            }).catch((error) => {
                setStatus(error.message);
            });
    }

    const onCloseConfirmStartProject = () => {
        setOpenConfirmDialog(false);
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    const chipStatus = () => {
        return <Grid className={classes.root}>
            <Typography variant="body1" component="h6">
                {project.key}
            </Typography>
            <Chip label={translate(`requisitions.projects.status.${project.status}`) as string} disabled variant="outlined" size="small" />
        </Grid>
    }

    return (
        <Surface title={project.name} subtitle={chipStatus()} backButton icon={<PaymentIcon />}
            className="PaperPagination PaperTabContainer"
            titleActions={(
                <Grid container justify="flex-end" spacing={1}>
                    {!isReaderOnly && isOwner && project.status !== "FINALIZED" && (
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" onClick={onEditPaymentOrder} title={translate("requisitions.projects.edit_project") as string}>
                                <EditIcon />
                            </Fab>
                        </Grid>
                    )}
                    {!isReaderOnly && project.status == "DRAFT" && (
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" onClick={startProject} title={translate("requisitions.projects.start_project.start_project") as string}>
                                <StartIcon />
                            </Fab>
                        </Grid>
                    )}
                </Grid>
            )}>
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: ProjectTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: ProjectTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            <SuccessSnackbar message={success} onClose={() => setSuccess("")} />
            <WarningSnackbar message={warning} onClose={() => setWarning("")} />
            <ErrorSnackbar message={error} onClose={() => setError("")} />
            {openConfirmDialog && (
                <ConfirmPopup
                    doAction={onConfirmStartProject}
                    onClose={onCloseConfirmStartProject}
                    title={translate("requisitions.projects.start_project.start_project") as string}
                    message={translate("requisitions.projects.start_project.confirm_dialog_message") as string}
                    secondary={translate("requisitions.projects.start_project.confirm_start_project") as string}
                    button={translate("buttons.accept") as string} />
            )}
        </Surface>
    );
}


