import React from "react";
import { Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";
import { PaymentOrderHistory } from "../model/PaymentOrder";

export interface PaymentOrderHistoryStatusParams {
    history?: PaymentOrderHistory[];
}

export default function PaymentOrderHistoryStatus(props: PaymentOrderHistoryStatusParams) {
    return (<div>
            <Box py={2}>
                <Gridable
                    items={props.history ? props.history : []}
                    loading={false}
                    empty={translate("payment_order.history.empty") as string}
                    columns={[
                        {
                            title: translate("payment_order.history.date") as string,
                            converter: (history) => (<DateFormat date={history.date} format="DD-MM-YYYY h:mm a" zone={true}/>),
                            xs: 3,
                            sm: 3,
                            md: 3,
                            xl: 3
                        },
                        {
                            title: translate("payment_order.history.previous_status") as string,
                            converter: (history) => (<Ellipsis text={history.previous_status !== undefined ? translate("payment_order.status."+history.previous_status) as string : "---" } lenght={100} uppercased={false} />),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            xl: 2
                        },
                        {
                            title: translate("payment_order.history.user") as string,
                            converter: (history) => (<Ellipsis text={history.author} uppercased={false} />),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            xl: 2
                        },
                        {
                            title: translate("payment_order.history.status_title") as string,
                            converter: (history) => (<Ellipsis text={translate("payment_order.status."+history.status) as string} uppercased={false} />),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            xl: 2
                        },
                        {
                            title: translate("cfdis.history.comments") as string,
                            converter: (history) => (<Ellipsis text={ history.comments ? history.comments : ""} uppercased={false} />),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            xl: 2
                        }
                    ]} />
            </Box>
    </div>
            
    )
}