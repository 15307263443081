import React, { useEffect, useState } from "react";
import translate from "../i18n/Translator";
import Input from "@material-ui/core/Input";
import { Grid, IconButton } from "@material-ui/core";
import { DateRangesStringValue } from "../components/DateRange";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export interface InputForFilterProps {
    type: "number" | "text" | "date";
    value: string;
    limitMonths?: number;
    onShowRangeError?(): any;
    onChange(event : any): any;
}

export default function InputForFilter(props: InputForFilterProps) {

    const [value, setValue] = useState<string>(props.value);

    const load = () => {

    }

    useEffect(load, [props]);

    const onAccept = () => {
        props.onChange({ target: { value: value}} as any);
      }
    
      const onChange = (event : any) => {
        const { value: targetValue } = event.target;
        if (!targetValue) {
            setValue("");
            return;
        }
        setValue(targetValue);
    };
    if(props.type === "date"){
        return (
                <DateRangesStringValue
                    limitMonths={props.limitMonths}
                    onShowRangeError={props.onShowRangeError}
                    value={props.value ? props.value : ''}
                    onChange={props.onChange} />
        );
    }
    return (
        <Grid container >
            <Grid item xs={11}>
                <Input
                    type={props.type}
                    value={value}
                    onChange={onChange}
                    inputProps={props.type === "number" ? {
                        min: 0,
                        style: { textAlign: 'right', height: 'inherit' }
                    } : undefined}
                    placeholder={translate("buttons.filter") as string}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={onAccept} color="default" style={{ "width": "15px", "height": "15px", "padding": "0px"}}>
                    <ArrowForwardIcon style={{ "width": "15px", "height": "15px", "padding": "0px"}} />
                </IconButton>
            </Grid>
        </Grid>
    );
}