import { callAPI, HTTPMethod } from './API';
import { 
  ExpedientTemplates, 
  ExpedientTemplateParams, 
  ExpedientTemplate, 
  ExpedientTemplateRequest,
  ExpedientDisabledTemplateRequest,
  ExpedientDisabledTemplate,
  AssignExpedientTemplateRequest
} from '../model/ExpedientTemplate';

export async function listExpedientTemplates(tenantId: string, params: ExpedientTemplateParams, pageSize: number, offset: number): Promise<ExpedientTemplates> {
  let query = {
    name: params.name ? params.name : "",
    isDefault: params.is_default !== undefined ? params.is_default : "",
    pageSize: pageSize.toString(),
    offset: offset.toString()
  } as Record<string, string>;

  if (params.created_start_date && params.created_end_date) {
      query = { ...query,
        createdStartDate: params.created_start_date,
        createdEndDate: params.created_end_date };
  }

  if (params.updated_start_date && params.updated_end_date) {
      query = { ...query,
        updatedStartDate: params.updated_start_date,
        updatedEndDate: params.updated_end_date };
  }
  return await callAPI(`/tenants/${tenantId}/expedient-templates`, {
    method: HTTPMethod.GET,
    query: query,
  });
}

export async function getExpedientTemplate(tenantId: string, templateId: string): Promise<ExpedientTemplate> {
  return await callAPI(`/tenants/${tenantId}/expedient-templates/${templateId}`, {
    method: HTTPMethod.GET,
  });
}

export async function createExpedientTemplate(tenantId: string, request: ExpedientTemplateRequest ): Promise<ExpedientTemplate> {
  return await callAPI(`/tenants/${tenantId}/expedient-templates`, {
    method: HTTPMethod.POST,
    body: request,
  });
}

export async function updateExpedientTemplate(tenantId: string, templateId: string, request: ExpedientTemplateRequest): Promise<ExpedientTemplate> {
  return await callAPI(`/tenants/${tenantId}/expedient-templates/${templateId}`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}

export async function disabledTemplate(tenantId: string, templateId: string, request: ExpedientDisabledTemplateRequest): Promise<ExpedientDisabledTemplate> {
  return await callAPI(`/tenants/${tenantId}/expedient-templates/${templateId}/disabled-template`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}

export async function changeExpedientTemplate(tenantId: string, request: AssignExpedientTemplateRequest): Promise<void> {
  return await callAPI(`/tenants/${tenantId}/expedient-templates/change-template`, {
    method: HTTPMethod.PATCH,
    body: request,
  });
}
