import React from "react";
import { Typography } from "@material-ui/core";

interface EllipsisProps {
    text: string;
    lenght?: number;
    uppercased?: boolean;
    secondary?: boolean;
    useInheritColor?: boolean;
    breakWord?: boolean;
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
}

export default function Ellipsis(props: EllipsisProps) {
    var result = props.text;
    if (props.lenght && props.text.length > props.lenght && props.lenght > 10) {
        result = `${result.substring(0, Math.min(result.length, props.lenght) - 3)}...`;
    }

    return (
        <Typography title={props.text} variant={props.secondary ? "caption" : "body2"} 
            component="span" color={props.secondary && !props.useInheritColor ? "textSecondary" : "inherit"} 
            style={props.breakWord ? { wordBreak: "break-word" } : undefined} align={props.align ? props.align : 'inherit'}>
            {props.uppercased === undefined || props.uppercased ? result.toUpperCase() : result}
        </Typography>
    );
}