import { callAPI, HTTPMethod } from "./API";
import { ProviderConfiguration } from "../model/ProviderConfiguration";
import { UpdateEmployerRequest } from "../model/Employer";
import { BankParserRequest } from "../model/Bank";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/configurations`;

export async function get(tenantId: string, providerId: string): Promise<ProviderConfiguration> {
    return await callAPI(basePath(tenantId, providerId), {
        method: HTTPMethod.GET,
    });
}

export async function updateEmployer(tenantId: string, providerId: string, request: UpdateEmployerRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId, providerId)}/employer`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function updateBank(tenantId: string, providerId: string, request: BankParserRequest): Promise<void> {
    return await callAPI(`${basePath(tenantId, providerId)}/bank`, {
        method: HTTPMethod.PUT,
        body: request
    });
}