import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { WarningSnackbar } from "../components/Snackbars";


interface DefinePartiallitiesPopUpProps {
    onUpdate(value: string): any;
    onClose(): any;
}

export default function TenantConfigurationUpdatePopUp(props: DefinePartiallitiesPopUpProps) {
    const partiallities = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const [value, setValue] = useState<string>("");
    const [warning, setWarning] = useState<string>();
    const regexp = new RegExp(`^-?[0-9]*$`);

    const onSubmit = () => {
            if (Number(value) < 0 || (value && Number(value) > 0 && !regexp.test(value))) {
                setWarning(translate("tenant_configurations.not_acceptable_integer") as string);
                return;
            } 
            props.onUpdate(value);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setValue(value);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    return (

        <DialogPopup open
            title={translate("requisitions.form.quotation.payment_condition_id") as string}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.accept")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
            <Grid container>
                <Typography variant="body2">
                    {translate("requisitions.form.quotation.payment_condition.form.partiallities_number_choose") as string}
                </Typography>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id={"partiallity"}
                            name={"partiallity"}
                            value={value}
                            options={partiallities}
                            isValueNumber={true}
                            label={""}
                            required={false}
                            margin="dense"
                            onValueChanged={hasChanged}
                        />
                    </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}
