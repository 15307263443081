import React, { useState, useEffect } from "react";
import { Grid, Typography, ListItem } from "@material-ui/core";
import { Budget } from "../model/Budget";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis, Tooltip, TooltipPayload
} from "recharts";
import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";

import Arrow from '@material-ui/icons/Forward';

interface BudgetRangeBarProps {
    budget: Budget;
    fromDashboard: boolean;
}

export default function BudgetRangeBar(props: BudgetRangeBarProps) {
    const [data, setData] = useState<Object[]>();

    const initial = props.budget.init_amount || 0;
    const current = props.budget.current_amount || 0;
    const accrued = props.budget.accrued_amount || 0;
    const toAccrued = props.budget.pending_accrue_amount || (current - accrued);
    const load = () => {
        setData([{
            name: props.budget.name,
            exercised: props.budget.exercised_amount || 0 as number,
            accrued: accrued,
            to_accrued: toAccrued,
            initial: initial,
            current: current,
            maxsize: initial > current ? initial : current,
        }]);
    }

    useEffect(load, [props.budget]);

    const renderTargetTick = (params: any) => {
        const { x, y, width, height, index, fill } = params;

        return (
            <line
                key={`target-${index}`}
                x1={x + width}
                x2={x + width}
                y1={y}
                y2={y + height}
                stroke={fill}
            />
        );
    };

    const tooltipFormatter = (value: string | number | Array<string | number>, name: string, entry: TooltipPayload, index: number) => {
        if (index != 0 && (typeof value === "string" || typeof value === "number")) {
            return [
                <Typography variant="inherit" style={{ color: "#202124" }}>
                    {translate(`budget.performance.graph.${name}`) + " : " as string}
                    <NumberFormat value={value} thousandSeparator="," prefix="$" decimalScale={2} displayType="text" />
                </Typography>,
                <Typography variant={"inherit"}>
                    {translate(`budget.performance.graph.${name}`) as string}
                </Typography>
            ]
        }
    }

    const VsBudget = () => {
        let valueTransform = props.budget;
        let arrowStyle = {
            width: 20,
            height: 20,
            color: "white",
            transform: ""
        };
        const textStatus = valueTransform.variation_status != undefined ? translate(`budget.performance.graph.variation_status.${valueTransform.variation_status}`) as string : "";

        if (valueTransform.variation_status === "IN_BUDGET") {
            arrowStyle.color = "blue";
        } else if (valueTransform.variation_status === "UP_BUDGET") {
            arrowStyle.color = "red";
            arrowStyle.transform = "rotate(-90deg)";
        } else if (valueTransform.variation_status === "DOWN_BUDGET") {
            arrowStyle.color = "green";
            arrowStyle.transform = "rotate(90deg)";
        }
        return <Grid>
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item>
                        <Arrow style={arrowStyle} />
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {textStatus}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
        </Grid>
    }

    return (
        <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="center"  >
            <Grid item xs={3} style={{ marginRight: "-7%" }}>
                <ListItem >
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1" style={{ "opacity": 0.5 }}>
                                {translate(`budget.budget`) as string}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">
                                <NumberFormat value={props.budget!.current_amount} suffix=" MXN" prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItem>
            </Grid>
            <Grid item xs={props.fromDashboard ? 5 : 6} alignItems="center" alignContent="center">
                <BarChart
                    width={props.fromDashboard ? 550 : 650}
                    height={120}
                    data={data}
                    layout="vertical"
                >
                    <XAxis type="number" tick={false} tickLine={false} axisLine={false} />
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" scale="band" type="category" />
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" yAxisId="back" hide scale="band" type="category" />
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" yAxisId="paid" hide scale="band" type="category" />
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" yAxisId="current" hide scale="band" type="category" />
                    <YAxis tick={false} tickLine={false} axisLine={false} dataKey="name" yAxisId="initial" hide scale="band" type="category" />
                    <Tooltip formatter={tooltipFormatter} />
                    <Bar
                        className="monotone"
                        dataKey="maxsize"
                        fill="white"
                        stackId="back"
                        barSize={35}
                        yAxisId="back"
                    />
                    <Bar
                        className="monotone"
                        dataKey="accrued"
                        fill="#F69450"
                        stackId="a"
                        barSize={35}
                    />

                    <Bar
                        className="monotone"
                        dataKey="to_accrued"
                        fill="#FDE4D3"
                        stackId="a"
                        barSize={35}
                    />
                    <Bar
                        className="monotone"
                        dataKey="exercised"
                        fill="blue"
                        stackId="b"
                        barSize={35}
                        yAxisId="paid"
                    />
                    <Bar
                        className="monotone"
                        dataKey="initial"
                        fill="red"
                        stackId="c"
                        barSize={40}
                        yAxisId="initial"
                        shape={renderTargetTick}
                    />
                    <Bar
                        className="monotone"
                        dataKey="current"
                        fill="blue"
                        stackId="d"
                        barSize={40}
                        yAxisId="current"
                        shape={renderTargetTick}
                    />

                </BarChart>
            </Grid>
            <Grid item xs={props.fromDashboard ? 4 : 3} style={{ marginLeft: "7%" }}>
                <ListItem>
                    <VsBudget />
                </ListItem>

            </Grid>
        </Grid>
    );

}