import React, { useState } from "react";
import DialogPopup from "../components/DialogPopup";
import { Announcement } from "../model/Announcement";
import translate from "../i18n/Translator";
import { List, ListItem, ListItemIcon, ListItemText, Grid, Card, Typography } from "@material-ui/core";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Brightness1Outlined from '@material-ui/icons/Brightness1Outlined';
import { formatDateToShow } from "../components/DateFormat";
import ShowAnnouncementModal from "./ShowAnnouncementModal";
import { SuccessSnackbar } from "../components/Snackbars";

interface AnnouncementCenterModalProps {
    data?: Announcement[];
    onClose(): any;
}

export default function AnnouncementCenterModal (props: AnnouncementCenterModalProps){
    const [announcement, setAnnouncement] = useState<Announcement>();
    const [success, setSuccess] = useState<string>();

    const onClose = (success?: string) => {
        setAnnouncement(undefined);
        setSuccess(success);
    };

    const showAnnouncement = (announcement: Announcement) => {
        setAnnouncement(announcement);
    };

    return (
        <DialogPopup title={translate("providers.announcements.center.title")} open onClose={props.onClose} >
            <Typography color="primary"><strong>{translate("providers.announcements.center.subtitle")}</strong></Typography>
            <br/>
            {props.data && props.data.length > 0 ? 
                <Grid container  spacing={1}>
                    {props.data.map(announcement => (
                        <Grid item xs={12} >
                            <Card variant="outlined" onClick={() => showAnnouncement(announcement)} >
                                <List component="nav" dense >
                                    <ListItem>
                                        <ListItemText primary={<Typography color="primary">{announcement.title}</Typography>} secondary={
                                            translate("providers.announcements.center.dates",
                                            { "end_date": formatDateToShow(new Date(announcement.end_date)),
                                             "start_date": formatDateToShow(new Date(announcement.start_date))  }) as string
                                        }/>
                                        <ListItemIcon>
                                            {announcement.readed ? 
                                                <Brightness1Outlined style={{ "width": 20, "height": 20  }}/> 
                                            :
                                                <Brightness1Icon style={{ color:"#3d94c5", "width": 20, "height": 20 }}/>
                                            }
                                        </ListItemIcon>
                                    </ListItem>
                                </List>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            : translate("providers.announcements.no_announcements")}
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
            {announcement && 
                <ShowAnnouncementModal announcement={announcement} isProvider={true} onClose={onClose} />
            }
        </DialogPopup>);
}