import React, { useState, useEffect, useContext, useRef } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { getTenantCfdis, exportPendingPaymentCfdis, exportAllCfdis, exportCfdisProratedExcel, getActorFieldsTypeCatalogByStage, exportValidationsPdfCfdis } from "../api/TenantCfdiApi";
import { exportCfdisExcel, exportCfdisSelected } from "../api/TenantCfdiApi";
import { TenantCfdisRequest, ExportCfdisResponse } from "../model/TenantCfdi";
import { Grid, Divider, Button, IconButton, Hidden, Fab } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Cfdi, Cfdis, CfdisQueryParams, ActorStageFieldsResponse, FilterField, StatusGroup_DELIVERED_ERP } from "../model/Cfdi";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import TenantCfdiMenuPendingPayment from "./menus/TenantCfdiMenuPendingPayment";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CfdisMenu from "./menus/TenantCfdisMenu";
import FilterPopup from "../components/FilterPopup";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ReactHtmlParser from "html-react-parser";
import { Filter } from "@devexpress/dx-react-grid";
import { formatDateString, concatDates, stringToDate, stringToDateEnd } from "../components/DateFormat";
import GridDx from "../components/GridDx";
import Surface from "../components/Surface";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import { CfdisListProps } from "../cfdi/CfdisList";
import { isRoleOrParent } from "../model/Role";
import DownloadRange from "../components/DownloadRange";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family } from '../model/Connector';
import PublishIcon from "@material-ui/icons/Publish";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import { ImportPaymentSummary } from "../model/Payment";
import ImportPaymentResumePopup from "../payments/ImportPaymentResumePopup";
import ConnectorTemplateImportFile from "../importable/ConnectorTemplateImportFile";

export default function ListCfdisDelivered(props: CfdisListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status_group": StatusGroup_DELIVERED_ERP,
            "start_date": typeof qs["start_date"] === "string" && qs["start_date"] !== "" && qs["start_date"] !== "NaN-NaN-NaN" ? stringToDate(qs["start_date"]) : undefined,
            "end_date": typeof qs["end_date"] === "string" ? stringToDateEnd(qs["end_date"]) : new Date(),
            "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : "",
            "selected_ids": typeof qs["selected_ids"] === "string" ? qs["selected_ids"] as string : "",
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "issuer": typeof qs["issuer"] === "string" ? qs["issuer"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "send_at_start": typeof qs["send_at_start"] === "string" ? qs["send_at_start"] as string : "",
            "send_at_end": typeof qs["send_at_end"] === "string" ? qs["send_at_end"] as string : "",
            "type_filter": typeof qs["type_filter"] === "string" ? qs["type_filter"] as string : "",
            "payment_due_start": typeof qs["payment_due_start"] === "string" ? qs["payment_due_start"] as string : "",
            "payment_due_end": typeof qs["payment_due_end"] === "string" ? qs["payment_due_end"] as string : "",
            "payment_deadline_start": typeof qs["payment_deadline_start"] === "string" ? qs["payment_deadline_start"] as string : "",
            "payment_deadline_end": typeof qs["payment_deadline_end"] === "string" ? qs["payment_deadline_end"] as string : "",
            "authorized_at_start": typeof qs["authorized_at_start"] === "string" ? qs["authorized_at_start"] as string : "",
            "authorized_at_end": typeof qs["authorized_at_end"] === "string" ? qs["authorized_at_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
            "provider_id": "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
            "referer_view": "DELIVERED",
            "filter_fields": typeof qs["filter_fields"] === "string" && qs["filter_fields"] ? JSON.parse(qs["filter_fields"] as string) : undefined,
        } as CfdisQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>({ items: [] as Cfdi[] } as Cfdis);
    const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);

    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openConfirmDialogDwnAllCfdis, setOpenConfirmDialogDwnAllCfdis] = useState<boolean>(false);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [openConfirmDialogExportExcel, setOpenConfirmDialogExportExcel] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [userId] = useState<string>(context.isGranted("ReceptorCFDIsRead") ? context.session!.user.id : "");
    const isCfdiTenant = isRoleOrParent(context.session!.role, "owner") || isRoleOrParent(context.session!.role, "supervisor") || isRoleOrParent(context.session!.role, "cxp");
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openExportExcelSelected, setOpenExportExcelSelected] = useState<boolean>(false);
    const [importPaymentSummary, setImportPaymentSummary] = useState<ImportPaymentSummary>();
    const [showOnImportPayment, setShowOnImportPayment] = useState<boolean>(false);
    const [openExportProratedExcel, setOpenExportProratedExcel] = useState<boolean>(false);
    const [fieldsCatalogType, setFieldsCatalogType] = useState<ActorStageFieldsResponse>();
    const [openDownloadValidationsPdf, setOpenDownloadValidationsPdf] = useState<boolean>(false);

    const paramsToFilters = () => {
        return [
            { columnName: 'identifier', value: params.identifier },
            { columnName: 'issuer', value: params.issuer },
            { columnName: 'description', value: params.description },
            { columnName: 'send_at', value: concatDates(params.send_at_start, params.send_at_end) },
            { columnName: 'type', value: params.type_filter },
            { columnName: 'payment_due', value: concatDates(params.payment_due_start, params.payment_due_end) },
            { columnName: 'payment_deadline', value: concatDates(params.payment_deadline_start, params.payment_deadline_end) },
            { columnName: 'authorized_at', value: concatDates(params.authorized_at_start, params.authorized_at_end) },
            { columnName: 'total', value: params.total },
            { columnName: 'balance', value: params.balance },
            { columnName: 'currency', value: params.currency },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        params.type_list = ["INVOICE", "ADVANCED"].join(",");
        pushHistory(workingParams);

        getTenantCfdis(context.session!.tenant!.id, userId, pageSize, pageSize * page, params).then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });

        let success_reactivate = typeof qs["success_reactivate"] === "string" ? qs["success_reactivate"] as string : "";
        if (success_reactivate.length > 0) {
            let link = "" as string;
            if (isCfdiTenant) {
                link = "/tenant/cfdis/pending-payment-owner";
            } else {
                link = "/tenant/cfdis/pending-payment-by-receptor";
            }
            setSuccess(ReactHtmlParser(translate("cfdis.popup.reactivate.success",
                { "link": link }) as string));
        }
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = { ...params };
        filters.filter(el => el.value !== undefined).forEach(filter => {
            switch (filter.columnName) {
                case "send_at":
                    let datesSend = filter.value!.split(" - ");
                    if (datesSend.length === 2) {
                        temp.send_at_start = formatDateString(datesSend[0]);
                        temp.send_at_end = formatDateString(datesSend[1]);
                    } else {
                        temp.send_at_start = "";
                        temp.send_at_end = "";
                    }
                    break;
                case "type":
                    temp.type_filter = filter.value;
                    break;
                case "payment_due":
                    let paymentDues = filter.value!.split(" - ");
                    if (paymentDues.length === 2) {
                        temp.payment_due_start = formatDateString(paymentDues[0]);
                        temp.payment_due_end = formatDateString(paymentDues[1]);
                    } else {
                        temp.payment_due_start = "";
                        temp.payment_due_end = "";
                    }
                    break;
                case "payment_deadline":
                    let paymentDeadlines = filter.value!.split(" - ");
                    if (paymentDeadlines.length === 2) {
                        temp.payment_deadline_start = formatDateString(paymentDeadlines[0]);
                        temp.payment_deadline_end = formatDateString(paymentDeadlines[1]);
                    } else {
                        temp.payment_deadline_start = "";
                        temp.payment_deadline_end = "";
                    }
                    break;
                case "authorized_at":
                    let authorizedAtDates = filter.value!.split(" - ");
                    if (authorizedAtDates.length === 2) {
                        temp.authorized_at_start = formatDateString(authorizedAtDates[0]);
                        temp.authorized_at_end = formatDateString(authorizedAtDates[1]);
                    } else {
                        temp.authorized_at_start = "";
                        temp.authorized_at_end = "";
                    }
                    break;
                case "company_name":
                    temp.company_name = filter.value;
                    break;
                default:
                    temp = { ...temp, [filter.columnName]: filter.value };
            }
        });
        setWorkingParams(temp);
        setParams(temp);

    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = (workingParams: CfdisQueryParams) => {
        let qs = queryString.parse(window.location.search);
        qs["status_group"] = StatusGroup_DELIVERED_ERP;
        qs["search"] = workingParams.search;
        qs["selected_ids"] = workingParams.selected_ids ? workingParams.selected_ids : "";
        qs["receptor"] = workingParams.receptor ? workingParams.receptor : "";
        qs["identifier"] = workingParams.identifier ? workingParams.identifier : "";
        qs["issuer"] = workingParams.issuer ? workingParams.issuer : "";
        qs["description"] = workingParams.description ? workingParams.description : "";
        qs["send_at_start"] = workingParams.send_at_start ? workingParams.send_at_start : "";
        qs["send_at_end"] = workingParams.send_at_end ? workingParams.send_at_end : "";
        qs["type_filter"] = workingParams.type_filter ? workingParams.type_filter : "";
        qs["payment_due_start"] = workingParams.payment_due_start ? workingParams.payment_due_start : "";
        qs["payment_due_end"] = workingParams.payment_due_end ? workingParams.payment_due_end : "";
        qs["payment_deadline_start"] = workingParams.payment_deadline_start ? workingParams.payment_deadline_start : "";
        qs["payment_deadline_end"] = workingParams.payment_deadline_end ? workingParams.payment_deadline_end : "";
        qs["authorized_at_start"] = workingParams.authorized_at_start ? workingParams.authorized_at_start : "";
        qs["authorized_at_end"] = workingParams.authorized_at_end ? workingParams.authorized_at_end : "";
        qs["total"] = workingParams.total ? workingParams.total : "";
        qs["balance"] = workingParams.balance ? workingParams.balance : "";
        qs["currency"] = workingParams.currency ? workingParams.currency : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";

        qs["start_date"] = workingParams.start_date ? String(workingParams.start_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.start_date.getDate()).padStart(2, '0') + "-" + workingParams.start_date.getFullYear() : "";
        qs["end_date"] = workingParams.end_date ? String(workingParams.end_date.getMonth() + 1).padStart(2, '0') + "-" + String(workingParams.end_date.getDate()).padStart(2, '0') + "-" + workingParams.end_date.getFullYear() : "";
        qs["provider"] = workingParams.provider;
        qs["postpone_to_start"] = workingParams.postpone_to_start ? workingParams.postpone_to_start : "";
        qs["postpone_to_end"] = workingParams.postpone_to_end ? workingParams.postpone_to_end : "";
        qs["filter_fields"] = workingParams.filter_fields ? JSON.stringify(workingParams.filter_fields) : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onAppliedFilter = () => {
        pushHistory(workingParams);
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onDoFilter = (filterFields: FilterField[]) => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: filterFields }
        setWorkingParams(params);
        setParams(params);
    };

    const onCleanFilter = () => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: undefined }
        setWorkingParams(params);
        setParams(params);
    };

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onClickFilter = () => {
        getActorFieldsTypeCatalogByStage(context.session!.tenant!.id).then((response) => {
            setFieldsCatalogType(response);
            if (response && response.fields && response.fields.length > 0) {
                setOpenFilterDialog(true);
            } else {
                setWarning(translate("cfdis.popup.filter_fields.not_filters_found") as string);
            }
        }).catch((error) => {
            setError(error.message);
        });

        setOpenFilterDialog(true);
    }

    const onClickedClean = () => {
        setOpenFilterDialog(false);
        const params = { ...workingParams, filter_fields: undefined, search: "", start_date: undefined, end_date: undefined, provider: "" }
        setWorkingParams(params);
        setParams(params);
    }

    const showExportResults = (response: ExportCfdisResponse) => {
        if (response.url) {
            window.open(response.url, "_blank")
        } else if (response.total) {
            setSuccess(translate("cfdis.email_export", { "total": response.total }) as string);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadAllCfdis = async (startDate: Date, endDate: Date) => {
        setGridAnchorEl(null);
        setOpenBackdrop(true);
        setOpenConfirmDialogDwnAllCfdis(false);

        let paramsTemp = params;
        paramsTemp.start_date = startDate;
        paramsTemp.end_date = endDate;
        exportAllCfdis(context.session!.tenant!.id, userId, paramsTemp).then((response) => {
            showExportResults(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });

    }

    const onOpenConfirmDialogDwnAllCfdis = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenConfirmDialogDwnAllCfdis(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const dowdloadCfdiSelected = async () => {
        setGridAnchorEl(null);
        if (selected?.length) {
            setOpenBackdrop(true);
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selected } as TenantCfdisRequest).then((response) => {
                showExportResults(response);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setOpenBackdrop(false);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onExportCfdis = async () => {
        setGridAnchorEl(null);
        setOpenBackdrop(true);

        exportPendingPaymentCfdis(context.session!.tenant!.id, params).then((response) => {
            showExportResults(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onClickedRow = (cfdi: Cfdi) => {
        pushHistory(workingParams);
        setRedirect(`/cfdis/${cfdi.id}/details?referer=delivered&${queryParam}`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onOpenConfirmDialogExportExcel = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenExportExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onCloseConfirmDialogExportExcel = () => {
        setOpenConfirmDialogExportExcel(false);
    }

    const onDownloadFiles = async (n_params: CfdisQueryParams) => {
        setOpenBackdrop(true);
        exportCfdisExcel(context.session!.tenant!.id, n_params).then((response) => {
            showExportResults(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenExportExcel(false);
        });
    }

    const onDownloadExcel = async () => {
        setOpenConfirmDialogExportExcel(false);
        let n_params = params;
        n_params.selected_ids = undefined;
        await onDownloadFiles(n_params);
    }

    const onDownloadSelectedToExcel = async () => {
        setGridAnchorEl(null);
        if (selected && selected.length > 0) {
            onDownloadWithConnectorSelected("");
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelected(selected);
    };

    const onCloseExporter = () => {
        setOpenExportExcel(false);
        setOpenDownloadValidationsPdf(false);
    };

    const onDownloadWithConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenConfirmDialogExportExcel(false);
        let n_params = params;
        n_params.selected_ids = undefined;
        n_params.start_date = startDate;
        n_params.end_date = endDate;
        n_params.connector_id = connectorId;
        onDownloadFiles(n_params);
    }

    const onCloseExporterSelected = () => {
        setOpenExportExcelSelected(false);
        setOpenExportProratedExcel(false);
    };

    const onDownloadWithConnectorSelected = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportExcelSelected(false);
        let n_params = params;
        n_params.selected_ids = selected.join(",");
        n_params.start_date = undefined;
        n_params.end_date = undefined;
        n_params.connector_id = connectorId;
        onDownloadFiles(n_params);
    }

    const openFileImportPayments = () => {
        setShowOnImportPayment(true);
    }


    const onCloseImportDialog = () => {
        setImportPaymentSummary(undefined);
        setGridAnchorEl(null);
    };

    const onDownloadSelectedToProratedExcel = () => {
        setGridAnchorEl(null);
        if (selected && selected.length > 0) {
            setOpenBackdrop(true);
            setOpenExportExcelSelected(false);
            let exportParams = params;
            exportParams.selected_ids = selected.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            exportProratedCfdiExcel(exportParams);

        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const exportProratedCfdiExcel = (params: CfdisQueryParams) => {
        exportCfdisProratedExcel(context.session!.tenant!.id, params).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenExportProratedExcel(false);
        });

    }

    const onOpenConfirmDialogExportProratedExcel = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenExportProratedExcel(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadWithOutConnector = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenExportProratedExcel(false);
        let exportParams = params;
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        exportParams.connector_id = connectorId;
        exportProratedCfdiExcel(exportParams);
    }

    const onDownloadSelectedValidationsPdf = () => {
        if (selected && selected.length > 0) {
            setOpenBackdrop(true);
            setOpenDownloadValidationsPdf(false);
            let exportParams = params;
            exportParams.selected_ids = selected.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        } else {
            setGridAnchorEl(null);
            setAnchorEl(null);
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onOpenConfirmDialogDownloadValidationsPdf = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setOpenDownloadValidationsPdf(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onDownloadValidationsPdf = (paramsExport: CfdisQueryParams) => {
        exportValidationsPdfCfdis(context.session!.tenant!.id, paramsExport).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setOpenDownloadValidationsPdf(false);
            setAnchorEl(null);
            setGridAnchorEl(null);
        });
    }

    const onDownloadDateValidationsPdf = (connectorId: string, startDate?: Date, endDate?: Date) => {
        setOpenBackdrop(true);
        setOpenDownloadValidationsPdf(false);
        setGridAnchorEl(null);
        setAnchorEl(null);
        let exportParams = { ...workingParams };
        exportParams.selected_ids = undefined;
        exportParams.start_date = startDate;
        exportParams.end_date = endDate;
        onDownloadValidationsPdf(exportParams);
    }

    return (
        <Surface
            title={props.title}
            icon={<AssignmentTurnedInIcon />}
            className="PaperPagination"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["ZipDaarRead"]) ?
                            <Grid item xs="auto" >
                                <Hidden xsDown implementation="css">
                                    <Fab color="secondary" size="small" title={translate("cfdis.import_payments") as string} onClick={openFileImportPayments} disabled={status === "loading"} >
                                        <PublishIcon />
                                    </Fab>
                                </Hidden>
                            </Grid> : undefined}
                        <Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm>
                    <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onClickFilter} variant={params.filter_fields ? "contained" : "outlined"} color="primary" size="medium">
                        {params.filter_fields ? translate("buttons.filters_applied") : translate("buttons.filter")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
                <Grid item >
                    <IconButton color="default" size="small" onClick={onClickedClean} disabled={status === "loading"}>
                        <HighlightOffTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className="focaltec-small">
                <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    columns={props.columns ? props.columns : []}
                    columnsFormat={props.columnsFormat}
                    selectionIds={selected}
                    clickRowColumns={props.clickRowColumns}
                    onClickRow={onClickedRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    defaultExpandedGroups={props.defaultExpandedGroups}
                    amountCurrencyColumns={props.currencyColumns}
                    dateColumns={props.dateColumns}
                    leftColumns={props.leftColumns}
                    textColumns={props.textColumns}
                    rightColumns={props.rightColumns}
                    filters={filters}
                    grouping={props.grouping}
                    customFormatColumns={props.customPlugins}
                    setFiltersHandler={setFiltersHandler}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    setTableCell={props.tableCell}
                    setCheckCell={props.checkCell}
                />
            </div>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />


            {cfdi && anchorEl && (
                <TenantCfdiMenuPendingPayment
                    cfdi={cfdi}
                    anchor={anchorEl}
                    referer={`delivered&${queryParam}`}
                    onClose={onCloseOption} />
            )}
            {openConfirmDialogDwnAllCfdis && (
                <DownloadRange
                    onClose={() => setOpenConfirmDialogDwnAllCfdis(false)}
                    onExportRange={onDownloadAllCfdis}
                    family="CFDIS"
                    maxDaySelected={31} />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}
            {gridAnchorEl && (
                <CfdisMenu anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onDownloadAll={onOpenConfirmDialogDwnAllCfdis}
                    onDownloadSelected={dowdloadCfdiSelected}
                    onDownloadExcelSelected={onDownloadSelectedToExcel}
                    onDownloadExcel={onOpenConfirmDialogExportExcel}
                    onExportCfdis={onExportCfdis}
                    onDownloadProratedExcel={onOpenConfirmDialogExportProratedExcel}
                    onDownloadProratedExcelSelected={onDownloadSelectedToProratedExcel}
                    onDownloadValidationsPdf={onOpenConfirmDialogDownloadValidationsPdf}
                    onDownloadValidationsPdfSelected={onDownloadSelectedValidationsPdf}
                />
            )}
            {openFilterDialog && fieldsCatalogType && (
                <FilterPopup
                    filterFields={fieldsCatalogType}
                    tenantId={context.session!.tenant!.id}
                    appliedFilters={workingParams.filter_fields}
                    doAction={onDoFilter}
                    onCleanFilter={onCleanFilter}
                    onClose={() => setOpenFilterDialog(false)} />
            )}
            {openConfirmDialogExportExcel && (
                <CfdisConfirmPopup
                    doAction={onDownloadExcel}
                    onClose={onCloseConfirmDialogExportExcel}
                    title={translate("cfdis.popup.confirm_dialog_excel.title") as string}
                    message={translate("cfdis.popup.confirm_dialog_excel.message", { "total": data ? data.total : 0 }) as string}
                    button={translate("cfdis.popup.confirm_dialog_excel.button") as string} />
            )}
            {(openExportExcel || openDownloadValidationsPdf) && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA_RANGE"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporter}
                    onExport={openExportExcel ? onDownloadWithConnector : onDownloadDateValidationsPdf}
                    maxDaySelected={31} />
            )}
            {(openExportExcelSelected || openExportProratedExcel) && (
                <ConnectorObjectExporter
                    tenantId={context.session!.tenant!.id}
                    family={Family.CFDIS}
                    type="DATA"
                    includeDefault={false}
                    legacy="XLSX"
                    onClose={onCloseExporterSelected}
                    onExport={openExportExcelSelected ? onDownloadWithConnectorSelected : onDownloadWithOutConnector} />
            )}
            {importPaymentSummary && (
                <ImportPaymentResumePopup summaryImport={importPaymentSummary} onClose={onCloseImportDialog} />
            )}
            <ConnectorTemplateImportFile
                showOnImportPayment={showOnImportPayment}
                tenantId={context.session!.tenant!.id}
                userId={context.session!.user!.id}
                family={Family.PAYMENTS}
                title={translate("payment.import.title") as string}
                onShowOnImportPayment={(value) => {
                    setShowOnImportPayment(value);
                }}
                onImportedPaymentSummary={(response) => {
                    setSuccess(translate("payment.import.import_s3_success") as string);
                    load();
                }}
            />

        </Surface>);
}
