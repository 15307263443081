import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Button, IconButton } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import PaymentIcon from "@material-ui/icons/Payment";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DeleteIcon from '@material-ui/icons/Delete';
import Gridable from "../components/Gridable";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";
import ConfirmationPopup from "../components/ConfirmationPopup";
import BudgetUpdatePopUp from "./ProjectUpdateBudgetPopUp";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, TextField, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel, Divider, ListItemText } from "@material-ui/core";
import NumberFormat from "react-number-format";
import EditIcon from '@material-ui/icons/Edit';
import { createProject, updateProject } from "../api/ProjectAPI";
import { isRoleOrParent } from "../model/Role";
import { Project, ProjectRequest, RequisitionConfiguration } from "../model/Project";
import { Team } from "../model/Team";
import { RequisitionTypeCatalog as RequisitionType } from "../model/RequisitionTypeCatalog";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { NotificationGroup, NotificationGroupsQueryParams } from "../model/NotificationGroup";
import { notificationGroupList } from "../api/NotificationGroupAPI";
import NotificationGroupAutocomplete from "../notification_groups/NotificationGroupAutocomplete"
import { listExtendendFieldsTemplates } from "../api/ExtendedFieldsTemplateAPI";
import { ExtendedFieldsTemplatesParams, ExtendedFieldsTemplateType, ExtendedFieldsTemplates } from '../model/ExtendedFieldsTemplate';

interface CreateUpdateProjectViewProps {
    view: "update" | "create";
    isBudgetEdit: boolean;
    project?: Project;
    next(request: Project, message?: string): any;
    onEdit(project: Project): any;
    teams: Team[];
    requisitionTypes: RequisitionType[];
    onCancel(): any;
}

export default function CreateUpdateProjectView(props: CreateUpdateProjectViewProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<ProjectRequest>({ type: "INTERN", status: "DRAFT" } as ProjectRequest);
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);

    const [dialog, setDialog] = useState<"delete" | "cancel" | "add" | "edit">();
    const [submitting, setSubmitting] = useState(false);
    const [requisitionTypesAutocomplete, setRequisitionTypesAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [requisitionsConfiguration, setRequisitionsConfiguration] = useState<RequisitionConfiguration[]>([]);
    const [teamsAutocomplete, setTeamsAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [requisitionType, setRequisitionType] = useState<AutocompleteProvider | undefined>();
    const [teamAdded, setTeamAdded] = useState<AutocompleteProvider | undefined>();
    const [isBudgetEdit, setIsBudgetEdit] = useState<boolean>(false);
    const [openEditBudget, setOpenEditBudget] = useState<boolean>(false);
    const [projectTypeDefault, setProjectTypeDefault] = useState<string>("true");
    const [notificationGroupsList, setNotificationGroupsList] = useState<AutocompleteProvider[]>([]);
    const [extendedFieldsTemplates, setExtendedFieldsTemplates] = useState<ExtendedFieldsTemplates>();

    const isOwner = isRoleOrParent(context.session!.role, "owner") || isRoleOrParent(context.session!.role, "project_manager");

    const load = () => {
        setStatus("loading");
        fillDataAutocomplete();
        setIsBudgetEdit(props.isBudgetEdit);

        if (props.project) {
            setRequest({
                project_name: props.project.name,
                key: props.project.key,
                type: props.project.type,
                customer: props.project.customer,
                initial_budget: props.project.initial_budget,
                current_budget: props.project.current_budget,
                currency: props.project.currency,
                requisition_configurations: props.project.requisition_configurations,
                notification_group_id: props.project.notification_group_id,
                status: props.project.status,
                template_id: props.project?.template?.id
            } as ProjectRequest);
            setProjectTypeDefault(props.project.type && props.project.type === "INTERN" ? "true" : "false");
            setRequisitionsConfiguration(props.project.requisition_configurations);
        }

        Promise.all([
            getTenantCurrencies(context.session!.tenant!.id),
            notificationGroupList(context.session!.tenant!.id, 20, 0, { search: "" } as NotificationGroupsQueryParams)
        ]).then(([respCurrencies, respNotificationgroups]) => {
            if (respCurrencies.tenant_currency_ids) {
                let listTemp = [] as AutocompleteProvider[];
                if (respCurrencies.tenant_currency_ids) {
                    respCurrencies.tenant_currency_ids.forEach((item) => {
                        let temp = { title: (item + " - " + (translate("currency." + item) as string)), value: item } as AutocompleteProvider;
                        listTemp.push(temp);
                    });
                }
                setCurrenciesAutocomplete(listTemp);
            }
            listTemplates();

            let listTemp = [] as AutocompleteProvider[];
            respNotificationgroups.items.forEach(item => {
                let tmp = {
                    title: item.name,
                    value: item.id
                } as AutocompleteProvider;
                listTemp.push(tmp);
            });
            setNotificationGroupsList(listTemp);
        }).catch(error => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const listTemplates = () => {
        listExtendendFieldsTemplates(context.session!.tenant!.id,
            { type: ExtendedFieldsTemplateType.PROJECT } as ExtendedFieldsTemplatesParams, 50, 0).then((resp) => {
                setExtendedFieldsTemplates(resp);
                setStatus("loaded");
            }).catch((error) => {
                setError(error.message);
            });
    }

    const fillDataAutocomplete = () => {
        let listTempRequisitionTypes = [] as AutocompleteProvider[];
        props.requisitionTypes.forEach((type) => {
            let tempType = { title: type.name, value: type.id } as AutocompleteProvider;
            listTempRequisitionTypes.push(tempType);
        });
        setRequisitionTypesAutocomplete(listTempRequisitionTypes);
    };

    useEffect(load, [props, requisitionsConfiguration]);

    const onConfirm = () => {

        if (!request.project_name || request.project_name === "") {
            setWarning(translate("requisitions.projects.new_project.empty_name") as string);
            return;
        }
        if (!request.key || request.key === "") {
            setWarning(translate("requisitions.projects.new_project.empty_key") as string);
            return;
        }
        if (!request.type || request.type === "") {
            setWarning(translate("requisitions.projects.new_project.empty_type") as string);
            return;
        }
        if (request.type === "CUSTOMER" && (!request.customer || request.customer === "")) {
            setWarning(translate("requisitions.projects.new_project.empty_customer") as string);
            return;
        }
        if (!request.initial_budget || !request.initial_budget || request.initial_budget <= 0) {
            setWarning(translate("requisitions.projects.new_project.empty_initial_budget") as string);
            return;
        }
        if (!request.currency || request.currency === "") {
            setWarning(translate("requisitions.projects.new_project.empty_currency") as string);
            return;
        }
        if (!requisitionsConfiguration || requisitionsConfiguration.length <= 0) {
            setWarning(translate("requisitions.projects.new_project.empty_requisition_configurations") as string);
            return;
        }

        setSubmitting(true);
        const projectRequest = {
            ...request, customer: request.customer, requisition_configurations: requisitionsConfiguration
        } as ProjectRequest;

        if (props.view === "create") {
            createProject(context.session!.tenant!.id, projectRequest).then((response) => {
                props.next(response, translate("requisitions.projects.new_project.success_save", { "name": response.name }) as string);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else if (props.project) {
            updateProject(context.session!.tenant!.id, props.project.id, projectRequest).then((response) => {
                props.next(response);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }

    };

    const onAddValue = () => {
        if (!requisitionType || !teamAdded) {
            setWarning(translate("requisitions.projects.new_project.empty_fields") as string);
            return;
        }
        if (requisitionsConfiguration && requisitionsConfiguration.length > 0 && isDuplicate()) {
            setWarning(translate("requisitions.projects.new_project.duplicate_team") as string);
            return;
        }

        let value = {
            team_id: teamAdded.value,
            requisition_type: requisitionType.value,
        } as RequisitionConfiguration;
        requisitionsConfiguration.push(value);
        setRequest({
            ...request,
            project_name: request.project_name
        } as ProjectRequest);
    }

    const isDuplicate = () => {
        var isDuplicate = false;
        requisitionsConfiguration.forEach((configuration) => {
            if (configuration.team_id === teamAdded?.value) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    };

    const onFilterChangedRequisitionType = (value: string, inputRef?: any) => {
        setRequisitionType(requisitionTypesAutocomplete.find(c => c.value === value));
        setTeamAdded(undefined);
        fillTeamByRequisitionType(value);
    };

    const fillTeamByRequisitionType = (value: string) => {
        const requisitionType = props.requisitionTypes.find(p => p.id === value);
        let listTempTeams = [] as AutocompleteProvider[];
        if (requisitionType) {
            props.teams.forEach((team) => {
                if (requisitionType?.purchaser_required) {
                    if (team?.members.find(t => "PURCHASER" === t.role)) {
                        let tempTeam = { title: team.name, value: team.id } as AutocompleteProvider;
                        listTempTeams.push(tempTeam);
                    }
                } else {
                    let tempTeam = { title: team.name, value: team.id } as AutocompleteProvider;
                    listTempTeams.push(tempTeam);
                }
            });
        }
        setTeamsAutocomplete(listTempTeams);
    };

    const onUpdateBudget = (comments: string, newBudget: number) => {
        setRequest(
            {
                ...request, current_budget: newBudget, comments: comments
            } as ProjectRequest
        );
        setOpenEditBudget(false);
    };

    const cancelConfirmDialog = () => {
        setDialog(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectTypeDefault((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === "true") {
            setRequest({ ...request, type: "INTERN" });
        } else {
            setRequest({ ...request, type: "CUSTOMER" });
        }
    };

    const onFilterCurrencyChanged = (value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
    };

    const onFilterNotificationGroupChanged = (value: string, inputRef?: InputRef) => {
        setRequest({ ...request, notification_group_id: value });
    };

    const onConfirmCancelDialog = () => {
        setDialog(undefined);
        props.onCancel();
    }

    const onCancel = () => {
        if (props.view === "create") {
            setDialog("cancel");
        } else {
            props.onCancel();
        }
    }

    const onOpenUpdateBudget = () => {
        setOpenEditBudget(true);
    }

    const onDelete = (item: RequisitionConfiguration) => {
        let valueToDelete = requisitionsConfiguration.find(v => v.team_id === item.team_id);
        if (valueToDelete) {
            let index = requisitionsConfiguration.indexOf(valueToDelete);
            if (index > -1) {
                requisitionsConfiguration.splice(index, 1);
            }
        }
        setRequisitionsConfiguration(requisitionsConfiguration);

        setRequest({
            ...request,
            project_name: request.project_name,
        } as ProjectRequest);
    }

    const onClickedDelete = (value: RequisitionConfiguration) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onDelete(value);
    };

    const getRequisitionTypeName = (value: string) => {
        let requisitionType = props.requisitionTypes.find(c => c.id === value);
        return requisitionType ? requisitionType.name : "";
    }

    const getTeamName = (value: string) => {
        let team = props.teams.find(c => c.id === value);
        return team ? team.name : "";
    }

    if (status === "loading") {
        return (<Progress />);
    }

    if (status !== "loading" && status !== "loaded") {
        return (<div>{status}</div>);
    }

    const hasChangedInputTeam = (name: string, value: string, inputRef: InputRef | undefined) => {
        setTeamAdded(teamsAutocomplete.find(c => c.value === value));
    };


    const onTemplateChanged = (event: string, value: string | undefined) => {
        setRequest({ ...request, template_id: value });
    };

    return (
        <div>
            <Surface title={props.view === "create" ? translate("requisitions.projects.new_project.title") as string : request.project_name}
                icon={<PaymentIcon />} className="PaperPagination PaperTabContainer"
                backButton >
                <Grid container justify="space-between" alignItems="center" >
                    <Grid container className="TableFilter" alignItems="center" direction="row" spacing={1}>
                        <Grid container alignItems="center" direction="row" spacing={4}>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="project_name" name="project_name" disabled={submitting}
                                    value={request.project_name} label={translate("requisitions.projects.columns.project_name") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="key" name="key" disabled={submitting}
                                    value={request.key} label={translate("requisitions.projects.columns.key") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" direction="row" spacing={4}>
                            <Grid item xs={6}>
                                <FormControl style={{ "marginTop": "1%" }} component="fieldset" >
                                    <FormLabel component="legend">{translate("requisitions.projects.columns.project_type") as string}</FormLabel>
                                    <RadioGroup row aria-label="type" name="type" value={projectTypeDefault} onChange={onChangeRadio} >
                                        <FormControlLabel value="true" control={<Radio />} label={translate("requisitions.projects.types.INTERN") as string} />
                                        <FormControlLabel value="false" control={<Radio />} label={translate("requisitions.projects.types.CUSTOMER") as string} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="customer" name="customer" disabled={request.type !== "CUSTOMER"}
                                    value={request.customer} label={translate("requisitions.projects.columns.customer") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                        </Grid>
                        {isBudgetEdit && props.project &&
                            <Grid container alignItems="center" direction="row" spacing={4}>
                                <Grid item xs={3}>
                                    <ListItemText primary={translate("requisitions.projects.columns.original_budget")} />
                                    <NumberFormat value={props.project.initial_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText primary={translate("requisitions.projects.columns.current_budget")} />
                                    <Button aria-label="add" disabled={!isOwner} color="primary" size="small" onClick={onOpenUpdateBudget} endIcon={<EditIcon fontSize="small" />}>
                                        <NumberFormat color="blue" value={request.current_budget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {!isBudgetEdit &&
                            <Grid container alignItems="center" direction="row" spacing={4}>
                                <Grid item xs={6}>
                                    <ValidatedInput type="number" id={props.view === "create" ? "initial_budget" : "current_budget"} name={props.view === "create" ? "initial_budget" : "current_budget"} disabled={submitting}
                                        value={request.initial_budget + ""} label={translate("requisitions.projects.columns.initial_budget") as string}
                                        required={true}
                                        margin="dense"
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TenantCurrencyAutocomplete
                                        value={currenciesAutocomplete?.find(v => v.value === request?.currency)}
                                        currencies={currenciesAutocomplete}
                                        onChange={onFilterCurrencyChanged} disabled={submitting}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NotificationGroupAutocomplete
                                        value={notificationGroupsList?.find(v => v.value === request?.notification_group_id)}
                                        noticationGrups={notificationGroupsList}
                                        onChange={onFilterNotificationGroupChanged} disabled={submitting}
                                    />
                                </Grid>
                            </Grid>
                        }

                        {(extendedFieldsTemplates?.items?.length ?? 0) > 0 && <Grid container alignItems="center" direction="row" spacing={4}>
                            <Grid item xs={6}>
                                <Autocomplete style={{ paddingTop: "15px" }}
                                    size="small"
                                    options={extendedFieldsTemplates?.items ?? []}
                                    getOptionLabel={(elemento) => elemento.name}
                                    disabled={request.status !== "DRAFT"}
                                    noOptionsText={translate("requisitions.projects.no_options_autocomplete") as string}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" required={true} label={translate("requisitions.projects.extended_field_template") as string} fullWidth />
                                    )}
                                    onChange={(event, newValue) => onTemplateChanged(event.type, newValue?.id ?? undefined)}
                                    getOptionSelected={(opt, val) => opt === val}
                                    value={(extendedFieldsTemplates?.items ?? []).find(v => v.id === request.template_id)}
                                />
                            </Grid>
                        </Grid>}

                    </Grid>

                    <Grid container className="TableFilter" alignItems="center" direction="row" spacing={1}>
                        <Grid item xs={12}>
                            <Divider />
                            <Typography style={{ "marginTop": "1%" }} variant="subtitle2">
                                {translate("requisitions.projects.new_project.requisition_configurations_title") as string}
                            </Typography>
                            <Typography style={{ "marginTop": "1%" }} variant="inherit">
                                {translate("requisitions.projects.new_project.requisition_configurations_description") as string}
                            </Typography>
                        </Grid>

                        <Grid container alignItems="center" direction="row" spacing={4}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    size="small"
                                    value={requisitionType || null}
                                    options={requisitionTypesAutocomplete}
                                    getOptionLabel={(elemento) => elemento.title}
                                    noOptionsText={translate("requisitions.projects.new_project.no_options") as string}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={translate("requisitions.projects.columns.requisition_type") as string} fullWidth />
                                    )}
                                    onChange={(event, newValue) => {
                                        onFilterChangedRequisitionType(newValue?.value ?? "");
                                    }}
                                    getOptionSelected={(opt, val) => opt.value === val.value}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <ValidatedInput type="text" id="team_id_autocomplete"
                                    name="team"
                                    emptyOption={translate("payments_cfdi.no_options_autocomplete") as string}
                                    label={translate("requisitions.projects.columns.team") as string}
                                    required={false} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChangedInputTeam}
                                    autocompleteOptions={teamsAutocomplete}
                                    autocompleteValue={teamAdded}
                                    autocompleteKey={teamAdded?.value}
                                    getId={(el) => el?.value}
                                    getLabel={(el) => el?.title} />

                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={onAddValue} variant="outlined" size="large" color="secondary">
                                    {translate("buttons.add")}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Box px={2}>
                            <Gridable
                                items={requisitionsConfiguration ? requisitionsConfiguration : []}
                                loading={false}
                                empty={translate("cfdis.empty") as string}
                                columns={[
                                    {
                                        title: translate("requisitions.projects.columns.requisition_type") as string,
                                        converter: (value) => getRequisitionTypeName(value.requisition_type),
                                        fullWidth: true,
                                        xs: true
                                    },
                                    {
                                        title: translate("requisitions.projects.columns.team") as string,
                                        converter: (value) => getTeamName(value.team_id),
                                        fullWidth: true,
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 3
                                    },
                                    {
                                        title: (
                                            <IconButton aria-label="options" color="default" size="small" style={{ visibility: "hidden" }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        ),
                                        converter: (value) => (
                                            <IconButton
                                                aria-label="options"
                                                color="default"
                                                size="small"
                                                onClick={onClickedDelete(value)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        ),
                                        fullWidth: true,
                                        xs: "auto",
                                        sm: "auto",
                                        md: "auto",
                                        lg: "auto",
                                        xl: "auto"
                                    }
                                ]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4} pr={2}>
                            <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                <Grid item xs={12} md="auto">
                                    <Button variant="contained" color="primary" size="large" onClick={onConfirm} >
                                        {translate(props.view === "create" ? "buttons.registry" : "buttons.save")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <Button variant="text" color="primary" size="large" onClick={onCancel}>
                                        {translate("buttons.cancel")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {dialog === "cancel" && props.view === "create" && (
                    <ConfirmationPopup title={translate("buttons.cancel") as string}
                        message={translate("requisitions.teams.cancel_popup.message_project") as string}
                        button={translate("buttons.accept") as string}
                        onClose={cancelConfirmDialog}
                        doAction={onConfirmCancelDialog} />
                )}
                {isOwner && openEditBudget && props.project &&
                    <BudgetUpdatePopUp
                        project={props.project}
                        onCompleted={onUpdateBudget}
                        onClose={() => setOpenEditBudget(false)}
                    />
                }
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            </Surface>
        </div>
    );
}
