import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from "@material-ui/core";
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import Surface from "../components/Surface";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import { RequisitionTypesIcon } from "../components/Icons";

import Gridable from "../components/Gridable";
import { getRequisitionType, createRequisitionType, updateRequisitionType, metadata } from "../api/RequisitionTypeCatalogAPI";
import { RequisitionTypeCatalog, PARTIAL_BILLING_TYPE, PurchaseOrderMode, RequisitionTypeMetadata, RequisitionTypeWorkflove, ProrationHandling } from '../model/RequisitionTypeCatalog';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { list as listTeams } from "../api/TeamAPI";
import { TeamListParams, TeamsResponse } from "../model/Team";
import { getProviders } from "../api/ProviderAPI";
import { Entity as Provider, Providers, ProvidersQueryParams } from "../model/Provider";
import SimpleSwitch from "../components/SimpleSwitch";
import { emptyPromise } from "../api/API";

export default function ReqisitionTypeForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const isEdit = !!match.params.requisitionTypeId;
    const margin = "dense";
    const tenantId = context.session?.tenant?.id || "-";

    const [status, setStatus] = useState<string>("loaded");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [teamsId, setTeamsId] = useState<string[]>([]);
    const [teamsLabel, setTeamsLabel] = useState<string[]>([]);
    const [prorationHandlingValues, setProrationHandlingValues] = useState<ProrationHandling[]>([]);
    const [prorationHandlingLabels, setProrationHandlingLabels] = useState<string[]>([]);
    const [partialBillingTypeId] = useState(PARTIAL_BILLING_TYPE);

    const [requisitionTypeMetadata, setRequisitionTypeMetadata] = useState<RequisitionTypeMetadata>();

    const [workfloveIds, setWorkfloveIds] = useState<string[]>([]);
    const [workfloveLabels, setWorkfloveLabels] = useState<string[]>([]);
    const [shippingTemplateIds, setShippingTemplateIds] = useState<string[]>([]);
    const [shippingTemplateLabels, setShippingTemplateLabels] = useState<string[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [providersAdded, setProvidersAdded] = useState<Provider[]>([]);
    const [provider, setProvider] = useState<Provider>();
    const [request, setRequest] = useState<RequisitionTypeCatalog>({} as RequisitionTypeCatalog);
    const [validations, setValidations] = useState({} as any);

    const hasBudgets = context.isGrantedAny(["BudgetsPlanningRead", "BudgetsToExecuteRead", "BudgetsToExecuteAllRead", "BudgetsArchiveRead"]);
    const [purchaseOrderMode] = useState<string[]>(hasBudgets ? Object.keys(PurchaseOrderMode) : [PurchaseOrderMode.QUOTATION_BY_PROVIDER]);
    const [purchaseOrderModeLabels] = useState<string[]>(purchaseOrderMode.map(e => translate(`requisitions.${e.toString().toLocaleLowerCase()}`) as string));

    const submitPromise = (): Promise<RequisitionTypeCatalog> => {
        let requestToSubmit = request;
        if (PurchaseOrderMode.BUDGET_CONTROL !== request.purchase_order_mode) {
            requestToSubmit = { ...request, proration_handling: undefined };
        }

        if (isEdit && match.params.requisitionTypeId) {
            return updateRequisitionType(tenantId, match.params.requisitionTypeId, requestToSubmit);
        }
        return createRequisitionType(tenantId, requestToSubmit);
    };

    const providersPromise = useCallback((): Promise<Providers> => {
        return getProviders(tenantId, 0, 0, {
            classification: "",
            search: "",
            validator_user_id: "",
            entity_type: "PROVIDER",
            status: "ENABLED"
        } as ProvidersQueryParams);
    }, [tenantId]);

    const teamsPromise = useCallback((): Promise<TeamsResponse> => {
        return listTeams(tenantId, 0, 0, {
            search: ""
        } as TeamListParams);
    }, [tenantId]);

    const requisitionTypePromise = useCallback((): Promise<RequisitionTypeCatalog | undefined> => {
        if (match.params.requisitionTypeId) {
            return getRequisitionType(tenantId, match.params.requisitionTypeId);
        }
        return emptyPromise();
    }, [tenantId, match.params.requisitionTypeId]);

    useEffect(() => {
        setStatus("loading");

        Promise.all([
            requisitionTypePromise(),
            providersPromise(),
            teamsPromise(),
            metadata(tenantId),
        ]).then(([requisitionType, providers, teams, metadata]) => {
            setProviders(providers.items.filter(p => p.type !== "VENDOR"));

            setTeamsId(teams.items.map(el => el.id));
            setTeamsLabel(teams.items.map(el => el.name));

            setRequisitionTypeMetadata(metadata);
            if (!hasBudgets) {
                let workfloves = metadata.workfloves;
                setWorkfloveIds(workfloves.map(el => el.id));
                setWorkfloveLabels(workfloves.map(el => el.name));
            }
            const phv = Object.keys(ProrationHandling) as ProrationHandling[];
            setProrationHandlingValues(phv);
            setProrationHandlingLabels(phv.map(p => translate(`requisitions.types.proration_handling.${p}`) as string));

            setShippingTemplateIds(metadata.shipping_templates.map(el => el.id || "-"));
            setShippingTemplateLabels(metadata.shipping_templates.map(el => el.name));


            const rtPurchaseOrderMode = requisitionType?.purchase_order_mode ?? PurchaseOrderMode.QUOTATION_BY_PROVIDER;

            setRequest({
                id: requisitionType?.id || "",
                name: requisitionType?.name || "",
                type_agreement: requisitionType?.type_agreement || "QUOTATION",
                fields: requisitionType?.fields ?? [],
                purchaser_required: requisitionType?.purchaser_required ?? false,
                name_workflow: requisitionType?.name_workflow || "",
                name_default_team: requisitionType?.name_default_team || "",
                partial_billing_type: requisitionType?.partial_billing_type || "",
                active: requisitionType?.active ?? true,
                workflove_id: requisitionType?.workflove_id || "",
                shipping_template_id: requisitionType?.shipping_template_id || "",
                team_id: requisitionType?.team_id || "",
                in_projects: requisitionType?.in_projects ?? true,
                project_type: requisitionType?.project_type || "INSTALLMENT",
                all_providers: requisitionType?.all_providers ?? false,
                type_staff_assignment: requisitionType?.type_agreement || "",
                service_product_ids: requisitionType?.service_product_ids || [],
                provider_ids: requisitionType?.provider_ids ?? [],
                in_use: !!requisitionType?.in_use,
                purchase_order_mode: rtPurchaseOrderMode,
                proration_handling: requisitionType?.proration_handling ?? ""
            } as RequisitionTypeCatalog);

            const listAdded = [] as Provider[];
            const editedProviders = [] as Provider[];

            providers.items.forEach((p) => {
                if (requisitionType?.provider_ids?.find(i => i === p.id)) {
                    listAdded.push(p);
                } else {
                    editedProviders.push(p);
                }
            });

            setProvidersAdded(listAdded);
            setProviders(editedProviders);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    }, [providersPromise, requisitionTypePromise, teamsPromise, tenantId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        value = value === "---" ? "" : value;
        let currentRequest = request;
        if ("purchase_order_mode" === name && !(PurchaseOrderMode.QUOTATION_BY_PROVIDER === value)) {
            currentRequest = { ...request, type_agreement: "GENERAL", partial_billing_type: "ONE_INVOICE_REQUISITION", in_projects: false, [name]: value as PurchaseOrderMode };
        } if ("purchase_order_mode" === name && (PurchaseOrderMode.QUOTATION_BY_PROVIDER === value)) {
            currentRequest = { ...request, [name]: value, proration_handling: undefined };
        } else {
            currentRequest = { ...request, [name]: value };
        }
        setRequest(currentRequest);
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedPurchaseOrderMode = (name: string, value: string, inputRef: InputRef) => {
        hasChanged(name, value, inputRef);
        if ("---" === value) {
            setWorkfloveIds([]);
            setWorkfloveLabels([]);
            return;
        }
        defineWorflowsByPurchaseOrderMode(value as PurchaseOrderMode, requisitionTypeMetadata?.workfloves ?? [])
    };

    const defineWorflowsByPurchaseOrderMode = (purchaseOrderMode: PurchaseOrderMode, workfloves: RequisitionTypeWorkflove[]) => {
        if (PurchaseOrderMode.QUOTATION_BY_PROVIDER === purchaseOrderMode) {
            setWorkfloveIds(workfloves.filter(el => el.has_pending_purchase_order).map(el => el.id));
            setWorkfloveLabels(workfloves.filter(el => el.has_pending_purchase_order).map(el => el.name));
        } else {
            setWorkfloveIds(workfloves.filter(el => !el.has_pending_purchase_order).map(el => el.id));
            setWorkfloveLabels(workfloves.filter(el => !el.has_pending_purchase_order).map(el => el.name));
        }
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            if (!request.all_providers && (request.provider_ids === undefined || request.provider_ids.length === 0)) {
                setWarning(translate("requisitions.types.form.warning_missing_data") as string);
            }
            return;
        }

        if (!request.all_providers && (request.provider_ids === undefined || request.provider_ids.length === 0)) {
            setWarning(translate("requisitions.types.form.warning_missing_data") as string);
            return;
        }

        if (PurchaseOrderMode.QUOTATION_BY_PROVIDER === request.purchase_order_mode && !request.partial_billing_type) {
            setWarning(translate("requisitions.types.form.billing_type_required") as string);
            return;
        }

        if (PurchaseOrderMode.BUDGET_CONTROL === request.purchase_order_mode && !!!request.proration_handling) {
            setWarning(translate("requisitions.types.form.proration_handling_required") as string);
            return;
        }

        if (!request.partial_billing_type) {
            request.partial_billing_type = undefined;
        }
        setSubmitting(true);
        submitPromise().then((_) => {
            let success = translate(`requisitions.types.${isEdit ? "success_update" : "success_create"}`, { "name": request.name }) as string
            history.push(`/requisitions/types/${success}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const handleSwitchChange = (name: string, checked: boolean) => {
        setRequest((request) => {
            return { ...request, [name]: checked };
        });
    };

    const addProvider = () => {
        if (provider) {
            let providers_selected = request.provider_ids !== null && request.provider_ids !== undefined ? request.provider_ids : [];
            providers_selected.push(provider.id);
            setRequest((request) => {
                return { ...request, provider_ids: providers_selected };
            });
            providersAdded.push(provider);
            let minusProvider = providers.filter(pa => pa.id !== provider.id);
            setProvider(undefined);
            setProviders(minusProvider);
        }
    };

    const onClickedClean = (provider: Provider) => (event: React.MouseEvent<HTMLElement>) => {
        let minusProvider = providersAdded.filter(pa => pa.id !== provider.id);
        setProvidersAdded(minusProvider);

        let plusProvider = providers;
        plusProvider.push(provider);
        plusProvider = plusProvider.sort((a, b) => a.name.localeCompare(b.name));
        setProviders(plusProvider);

        let providerIds = request.provider_ids;
        let minusIdProvider = providerIds.filter(pi => pi !== provider.id);
        setRequest((request) => {
            return { ...request, provider_ids: minusIdProvider };
        });
    };


    const hasChangedProviderAutocomplete = (name: string, value: string, inputRef: InputRef) => {
        let provider = providers.find((item) => item.id === value);
        if (provider) {
            setProvider(provider);
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} lg={7} xl={6}>
                    <Surface title={translate(`requisitions.types.form.title_${isEdit ? "edit" : "new"}`)}
                        icon={<RequisitionTypesIcon />}
                        backButton
                        className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid container justify="space-between" alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="h6" gutterBottom>
                                        {translate("requisitions.types.form.general_data")}
                                    </Typography>
                                    <ValidatedInput
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={request.name}
                                        label={translate("requisitions.types.form.name") as string}
                                        required
                                        margin={margin}
                                        onValueChanged={hasChanged} />

                                    {hasBudgets && <ValidatedInput
                                        type="text"
                                        id="purchase_order_mode"
                                        name="purchase_order_mode"
                                        value={request.purchase_order_mode}
                                        label={translate("requisitions.types.form.purchase_order_mode") as string}
                                        options={purchaseOrderMode}
                                        optionLabels={purchaseOrderModeLabels}
                                        disabled={!!request.id}
                                        required
                                        margin={margin}
                                        onValueChanged={hasChangedPurchaseOrderMode} />
                                    }

                                    <ValidatedInput
                                        type="text"
                                        id="workflove"
                                        name="workflove_id"
                                        value={request.workflove_id}
                                        label={translate("workflows.single") as string}
                                        options={workfloveIds}
                                        optionLabels={workfloveLabels}
                                        required
                                        disabled={request.in_use}
                                        margin={margin}
                                        onValueChanged={hasChanged} />

                                    <ValidatedInput
                                        type="text"
                                        id="shipping_template_id"
                                        name="shipping_template_id"
                                        value={request.shipping_template_id}
                                        label={translate("shipping_templates.single") as string}
                                        options={shippingTemplateIds}
                                        optionLabels={shippingTemplateLabels}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput
                                        type="text"
                                        id="team"
                                        name="team_id"
                                        value={request.team_id}
                                        label={translate("requisitions.types.form.team") as string}
                                        required
                                        disabled={request.in_use}
                                        options={teamsId}
                                        optionLabels={teamsLabel}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                    <ValidatedInput
                                        type="text"
                                        id="proration_handling"
                                        name="proration_handling"
                                        value={request.proration_handling ?? "---"}
                                        label={translate("requisitions.types.form.proration_handling") as string}
                                        disabled={!request.purchase_order_mode || PurchaseOrderMode.QUOTATION_BY_PROVIDER === request.purchase_order_mode}
                                        options={prorationHandlingValues}
                                        optionLabels={prorationHandlingLabels}
                                        margin={margin}
                                        onValueChanged={hasChanged} />

                                    <ValidatedInput
                                        type="text"
                                        id="partial_billing_type"
                                        name="partial_billing_type"
                                        label={translate("requisitions.types.form.partial_billing_type") as string}
                                        disabled={isEdit ? request.in_use : (PurchaseOrderMode.QUOTATION_BY_PROVIDER !== request.purchase_order_mode)}
                                        margin={margin}
                                        onValueChanged={hasChanged}
                                        emptyOption={translate("catalogs.values.empty") as string}
                                        autocompleteOptions={partialBillingTypeId ?? []}
                                        autocompleteValue={request.partial_billing_type ?? undefined}
                                        value={request.partial_billing_type}
                                        autocompleteKey={partialBillingTypeId.find(e => e === request.partial_billing_type) ?? undefined}
                                        getId={(el) => el}
                                        getLabel={(el) => el ? translate(`requisitions.types.columns.${el}`) as string : ""}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleSwitch
                                        checked={request.in_projects}
                                        label={translate("requisitions.types.form.in_projects") as string}
                                        value="in_projects"
                                        disabled={request.in_use || (PurchaseOrderMode.QUOTATION_BY_PROVIDER !== request.purchase_order_mode)}
                                        onChanged={handleSwitchChange}
                                        placement="end"
                                    />
                                    <SimpleSwitch
                                        checked={request.all_providers}
                                        label={translate("requisitions.types.form.all_providers") as string}
                                        value="all_providers"
                                        onChanged={handleSwitchChange}
                                        placement="end"
                                    />
                                </Grid>

                                {!request.all_providers && (
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item xs>
                                                <ValidatedInput
                                                    type="text"
                                                    id="providers_autocomplete"
                                                    name="providers_autocomplete"
                                                    emptyOption={translate("payments_cfdi.no_options_autocomplete") as string}
                                                    label={translate("requisitions.types.form.provider") as string}
                                                    required={false}
                                                    disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChangedProviderAutocomplete}
                                                    autocompleteOptions={providers}
                                                    autocompleteValue={provider}
                                                    autocompleteKey={provider?.name}
                                                    getId={(el) => el?.id}
                                                    getLabel={(el) => el?.name} />
                                            </Grid>
                                            <Grid item xs="auto" >
                                                <Button size="medium" variant="outlined" color="secondary" onClick={addProvider}>
                                                    {translate("buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box mx={-2}>
                                                    <Gridable
                                                        items={providersAdded ? providersAdded : []}
                                                        loading={status !== "loaded"}
                                                        error={status !== "loaded" ? status : undefined}
                                                        empty={translate("providers.empty") as string}
                                                        columns={[
                                                            {
                                                                title: translate("requisitions.types.form.provider") as string,
                                                                converter: (provider) => provider.name,
                                                                xs: true
                                                            },
                                                            {
                                                                title: translate("requisitions.types.form.rfc") as string,
                                                                converter: (provider) => provider.rfc,
                                                                xs: 5,
                                                                sm: 5,
                                                                md: 5,
                                                                lg: 4,
                                                                xl: 4
                                                            },
                                                            {
                                                                title: (
                                                                    <IconButton
                                                                        size="small"
                                                                        style={{ "visibility": "hidden" }}
                                                                        disabled>
                                                                        <HighlightOffTwoToneIcon />
                                                                    </IconButton>
                                                                ),
                                                                converter: (provider) => (
                                                                    <IconButton size="small" onClick={onClickedClean(provider)} >
                                                                        <HighlightOffTwoToneIcon color="error" />
                                                                    </IconButton>
                                                                ),
                                                                justify: "flex-end",
                                                                xs: "auto"
                                                            }
                                                        ]} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box pt={1} pr={1}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.save")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Grid>
    );
}