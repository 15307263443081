import React from "react";
import { Grid } from "@material-ui/core";
import translate from "../../i18n/Translator";

import { ExtendedField } from "../../model/ExtendedField";
import DialogPopup from "../DialogPopup";
import DateFormat from "../DateFormat";
import Gridable from "../Gridable";
import FieldItemTemplate from "./FieldItemTemplate";

interface PaymentHistoryItemPopupProps {
    field: ExtendedField;
    tenantId: string;
    cfdiId: string;
    onClose(): any;
}

export default function PaymentHistoryItemPopup(props: PaymentHistoryItemPopupProps) {
    return (
        <DialogPopup open
            title={props.field.name}
            closeText={translate("buttons.close") as string}
            closeColor="default"
            maxWidth="lg"
            onClose={props.onClose}>
            <Grid container >
                <Grid item xs={12}>
                    <Gridable
                        items={props.field.payment_process_history || []}
                        loading={false}
                        empty={translate("cfdis.history.empty") as string}
                        columns={[
                            {
                                title: "Contenido",
                                converter: (field) => (
                                    <FieldItemTemplate field={props.field} value={field.value} />
                                ),
                                xs: 6,
                            },
                            {
                                title: "Actualizado",
                                converter: (field) => (
                                    <DateFormat date={field.updated_at} format="DD/MM/YYYY" />
                                ),
                                xs: 6
                            }
                        ]} />
                </Grid>
            </Grid>
        </DialogPopup>
    );
}
