import { Workflove } from "../workflove/Model";
import { AdditionalInfoField } from "./AdditionalInfoWorkflove";
import { ShippingTemplate } from "./ShippingTemplate";

export interface RequisitionsTypeCatalog {
    items: RequisitionTypeCatalog[];
    total: number;
}

export interface RequisitionTypeCatalog {
    id: string;
    name: string;
    type_agreement: string;
    fields: AdditionalInfoField[];
    purchaser_required: boolean;
    name_workflow: string;
    name_default_team: string;
    partial_billing_type?: string;
    active: boolean;
    workflove_id: string;
    shipping_template_id?: string;
    team_id: string;
    in_projects: boolean;
    project_type: string;
    provider_ids: string[];
    all_providers: boolean;
    type_staff_assignment: string;
    service_product_ids: string[];
    in_use: boolean;
    purchase_order_mode: PurchaseOrderMode;
    proration_handling?: ProrationHandling
}

export interface RequisitionTypeMetadata {
    shipping_templates: ShippingTemplate[];
    workfloves: RequisitionTypeWorkflove[];
}

export interface RequisitionTypeWorkflove extends Workflove{ 
    has_pending_purchase_order: boolean;
}
export interface RequisitionTypeCatalogListParams {
    search: string;
    purchase_order_mode: string;
}

export enum PurchaseOrderMode {
    BUDGET_CONTROL = "BUDGET_CONTROL",
    QUOTATION_BY_PROVIDER = "QUOTATION_BY_PROVIDER",
}

export const PARTIAL_BILLING_TYPE = ["INVOICE_BY_PARTIALLITY", "ONE_INVOICE_REQUISITION"];

export enum ProrationHandling {
    NO_PRORATION = "NO_PRORATION",
    COST_CENTER_SETUP = "COST_CENTER_SETUP",
    PRORATION_TEMPLATE_SETUP = "PRORATION_TEMPLATE_SETUP"
}