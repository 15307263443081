import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Button, Typography, Box } from "@material-ui/core";
import NotificationGroupsIcon from "@material-ui/icons/AllInbox";

import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { NotificationGroup, NotificationGroupRequest } from "../model/NotificationGroup";
import Progress from "../components/Progress";
import { createNotificationGroup, updateNotificationGroup, getNotificationGroup } from "../api/NotificationGroupAPI";
import { EmailsListValidator } from "../components/Validators";

import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";

interface NotificationGroupViewModel {
    id: string,
    name: string;
    emails: string;
}

export default function NotificationGroupsForm({ match }: RouterParams) {

    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const history = useHistory();
    const isEdit = !!match.params.notificationGroupId;
    const margin = "dense";
    const emailsListValidator = new EmailsListValidator();

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<NotificationGroupViewModel>({} as NotificationGroupViewModel);
    const [validations, setValidations] = useState({} as any);

    const submitPromise = (): Promise<NotificationGroup> => {
        
        const notificationGroupRequest : NotificationGroupRequest = {
            name: request.name,
            emails: request.emails.split(",")
        }

        if (isEdit && match.params.notificationGroupId) {
            return updateNotificationGroup(tenantId, match.params.notificationGroupId, notificationGroupRequest);
        }
        return createNotificationGroup(tenantId, notificationGroupRequest);
    };

    useEffect(() => {
        if(isEdit){
            setStatus("loading");
            getNotificationGroup(tenantId, match.params.notificationGroupId)
            .then(response => {
                setRequest({
                    id: response.id,
                    name: response.name,
                    emails: response.emails.join(",")
                } as NotificationGroupViewModel);
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        }else{
            setStatus("loaded");
        }

    }, [tenantId, isEdit, match.params.notificationGroupId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {   
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((NotificationGroup) => {
            history.push(`/tenants/notification-groups`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "notification_groups.form.edit" : "notification_groups.form.new")} icon={<NotificationGroupsIcon />} className="FormSurface" subtitle={translate("notification_groups.subtitle") as string}>
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("notification_groups.fields.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="emails" name="emails"
                                        value={request.emails}
                                        label={translate("notification_groups.fields.list_distribution") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        rows={5}
                                        onValueChanged={hasChanged}
                                        validator={emailsListValidator} 
                                        />                                        
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}