import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { WebhookEventIcon } from "../components/Icons";
import { listEventTries } from "../api/WebhookEventsAPI";
import { AppContext } from "../context/AppContext";
import { EventTry, WebhookEventTries, WebhookEventTriesQueryParams } from "../model/WebhookEvent";
import translate from "../i18n/Translator";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import GridDx from "../components/GridDx";
import { Grid, Typography } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import ConfirmationPopup from "../components/ConfirmationPopup";
import ValidatedInput from "../components/ValidatedInput";
import DialogPopup from "../components/DialogPopup";
import CodeViewer from "../components/CodeBlockViewer";

export interface WebhookEventTriesViewProps {
    eventId: string;
    webhookName: string;
    onReturn(): any;
}

export default function WebhookEventTriesView(props: WebhookEventTriesViewProps) {
    const history = useHistory();
    const context = useContext(AppContext);
    const eventId = props.eventId;

    const qs = queryString.parse(window.location.search);

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<WebhookEventTries>();

    const paramsFromQueryString = (): WebhookEventTriesQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
        } as WebhookEventTriesQueryParams;
    };

    const [params, setParams] = useState<WebhookEventTriesQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<WebhookEventTriesQueryParams>(paramsFromQueryString);
       

    const [event, setEvent] = useState<EventTry>();
    const [openMessage, setOpenMessage] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [submitting, setSubmitting] = useState(false);

    const load = () => {
        setStatus("loading");
        listEventTries(context.session!.tenant!.id, eventId, pageSize, pageSize * page, params).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search ? workingParams.search : "";
        qs["page"] = "0";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onClickedOptions = (eventTry: EventTry) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setEvent(eventTry);
    };

    const onClickedRow = (event: EventTry) => {
        pushHistory();
        setEvent(event);
        setOpenMessage(true);
    };

    const onCloseMessage = () => {
        setAnchorEl(null);
        setOpenMessage(false);
        setEvent(undefined);
    };

    const [columnsFormat] = useState([
      { columnName: 'try_id', wordWrapEnabled: true },
      { columnName: 'try_no' },
      { columnName: 'try_date'},
      { columnName: 'http_code' },
      { columnName: 'menu', align: 'right', width: 50},
    ]) as any;

    const dateColumns = ['try_date'];

    const textColumns = ['try_id'];

    const clickRowColumns = ['try_id'];

    const columns = [
        {
            name: 'try_id',
            title: translate("webhooks.events.tries.columns.try_id") as string
        },
        {
            name: 'try_no',
            title: translate("webhooks.events.tries.columns.try_no") as string,
        },
        {
            name: 'try_date',
            title: translate('webhooks.events.tries.columns.try_date') as string
        },
        {
            name: 'http_code',
            title: translate('webhooks.events.tries.columns.http_code') as string
        }
    ];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={['try_id', 'try_no', 'try_date', 'http_code']} editorComponent={NoFilterEditor} />,
    ];

    return (
        <Surface
            title={translate("webhooks.events.tries.title", {event_id: props.eventId, webhook: props.webhookName}) as string}
            subtitle={translate("webhooks.events.tries.description") as string}
            icon={<WebhookEventIcon />}
            backButton
            onAction={props.onReturn}
            className="PaperPagination">
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items.map(i => i.event_try) : []}
                columns={columns}
                showSecondsInDate={true}
                onClickRow={onClickedRow}
                clickRowColumns={clickRowColumns}
                dateColumns={dateColumns}
                columnsFormat={columnsFormat}
                textColumns={textColumns}
                page={page}
                pageSize={pageSize} 
                totalRows={data ? data.total : 0}
                customFormatColumns={customPlugins}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                onClickedOptions={onClickedOptions}
            />
            { openMessage && event && 
                <DialogPopup open
                    title={translate("webhooks.events.tries.message_title", { no_try: event.try_no }) as string}
                    onClose={onCloseMessage}
                    closeText={translate("buttons.accept") as string}
                    closeColor="default" maxWidth={"xs"}>
                    {event.message && event.message.includes("{") ?
                        <CodeViewer code={event.message} languaje={"json"} showLineNumbers={true} />
                        : <Typography variant="subtitle1">{event.message}</Typography>
                    }
                </DialogPopup>
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Surface>
    );
}