import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Divider, IconButton, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import { TenantBankAccountType, TenantBankAccountTypes } from "../model/TenantBankAccountType";
import { list, deleteTenantBankAccountType, prevalidateDelete } from "../api/TenantBankAccountTypeAPI";
import { AppContext } from "../context/AppContext";
import TenantBankAccountTypeMenu from "./TenantBankAccountTypeMenu";
import Surface from "../components/Surface";
import Gridable from "../components/Gridable";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import ListAltIcon from '@material-ui/icons/AccountTree';
import ConfirmPopup from "../components/ConfirmationPopup";
import { RouterParams } from "../router/RouterParams";
import TenantBankAccountTypesForm from "./TenantBankAccountTypesForm";

export default function TenantBankAccountTypesView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const tenantName = context.session?.tenant?.name || match.params.tenantName;

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<TenantBankAccountTypes>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [account, setAccount] = useState<TenantBankAccountType>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [onOpenAdd, setOpenAdd] = useState<boolean>(false);

    const load = () => {
        list(tenantId).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session]);

    const onCloseOption = () => {
        setAnchorEl(null);
        setAccount(undefined);
    };

    const onClickedOptions = (account: TenantBankAccountType) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAccount(account);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const openConfirmDelete = () => {
        if(!account){
            return;
        }
        setAnchorEl(null);
        prevalidateDelete(tenantId, account?.id).then((response) => {
            setOpenConfirmDialog(true);
        }).catch((error) => {
            setError(error.message);
            closeConfirmDelete();
            return;
        });
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDialog(false);
        setAccount(undefined);
    };

    const onCloseOpenAdd = () => {
        load();
        setOpenAdd(false);
    };

    const doOpenAdd = () => {
        setOpenAdd(true);
    };

    const onConfirmDelete = () => {
        setOpenConfirmDialog(false);
        if(!account) return;
        deleteTenantBankAccountType(tenantId, account?.id).then((response) => {
            setSuccess(translate("bank_account_type.tenant_bank_account_type.delete.success") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        });
        setAccount(undefined);
    }; 
    
    return (
        <Surface title={translate("bank_account_type.tenant_bank_account_type.title") as string} 
            icon={<ListAltIcon />} 
            className="PaperPagination"
            titleActions={context.isGranted("TenantBankAccountTypesAsign") ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={doOpenAdd}>
                                <AddIcon />
                            </Fab>
                    </Grid>
                </Grid>) : undefined
            } backButton>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("providers.empty") as string}
                columns={[
                    {
                        title: translate("bank_account_type.tenant_bank_account_type.columns.type_account") as string,
                        converter: (account) => account.type_account,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("bank_account_type.tenant_bank_account_type.columns.identifier") as string,
                        converter: (account) => account.external_id,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("bank_account_type.tenant_bank_account_type.columns.description") as string,
                        converter: (account) => account.description,
                        xs: 5,
                        sm: 5,
                        md: 5,
                        lg: 5,
                        xl: 5
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                        ),
                        converter: (account) => (
                            <IconButton aria-label="options"
                                color="default"
                                size="small"
                                onClick={onClickedOptions(account)} >
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: true,
                        md: 1
                    }
                ]} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {account && anchorEl && (
                <TenantBankAccountTypeMenu 
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onDeleteAccount={openConfirmDelete}
                />
            )}
            {openConfirmDialog && account && (
                <ConfirmPopup
                    onClose={closeConfirmDelete}
                    doAction={onConfirmDelete}
                    closeText={translate("buttons.cancel") as string}
                    title={translate("bank_account_type.tenant_bank_account_type.delete.title") as string}
                    message={translate("bank_account_type.tenant_bank_account_type.delete.message", { typeAccount: account.type_account,  tenantName: tenantName}) as string}
                    button={translate("buttons.accept") as string}/>
            )}
            { onOpenAdd && 
                <TenantBankAccountTypesForm
                    tenantId={tenantId}
                    tenantName={tenantName}
                    activeTypes={data ? data.items : []}
                    onClose={onCloseOpenAdd}
                />
            }
        </Surface>
    )

}