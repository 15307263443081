import React, { useState } from "react";
import SpecificReceptionRules from "./SpecificReceptionRules";
import SpecificReceptionRuleForm from "./SpecificReceptionRuleForm";
import SpecificReceptionRuleEditTabs from "./SpecificReceptionRuleEditTabs";
import { SpecificReceptionRuleType, SpecificReceptionRule } from "../model/SpecificReceptionRule";

export default function ListSpecificReceptionRules() {
    const [view, setView] = useState<string>("principal");
    const [dataName, setDataName] = useState<string>("");
    const [data, setData] = useState<SpecificReceptionRule>({} as SpecificReceptionRule);
    const [dataType, setDataType] = useState<SpecificReceptionRuleType>({} as SpecificReceptionRuleType);
    const [success, setSuccess] = useState<string>("");

    const goToCreateValidation = (info: SpecificReceptionRule) => {
        setData(info);
        setDataName(info.name);
        setView("create-validation");
    }

    const goToEditType = (info: SpecificReceptionRule, infoType: SpecificReceptionRuleType, name: string) => {
        setData(info);
        setDataName(name);
        setDataType(infoType);
        setView("edit-validation-type");
    }

    const onClose = (to : string, success: string) => {
        setView(to);
        setSuccess(success);
    }

    switch (view) {
        case "principal":
            return (<SpecificReceptionRules onAction={goToCreateValidation} readOnly={false} success={success}/>);
        case "create-validation":
            return (<SpecificReceptionRuleForm onAction={goToEditType} onClose={onClose} specifiReceptionRule={data} name={dataName} />);
        case "edit-validation-type":
            return (<SpecificReceptionRuleEditTabs onAction={goToCreateValidation} onClose={onClose} specifiReceptionRule={data} specificReceptionRuleType={dataType} name={dataName} />);
        default:
            return (<SpecificReceptionRules onAction={goToCreateValidation} readOnly={false} success={success}/>);
    }
    
}



