import { AuthorizedAmount, Cfdi, CfdiRaw, CfdisCancelled, CfdiValidation, TenantCfdisParams } from "./Cfdi";
import { CfdiHistory } from "./CfdiHistory";
import { Company } from "./Company";

export interface WorkflowRoutingRequest {
    ids?: string[];
    stage_from: string;
    comments: string;
    //Solo se usa para el rechazo 
    status_from?:string;
}

export interface WorkflowRoutingRequestWithParams extends WorkflowRoutingRequest {
    params: TenantCfdisParams;
}

export interface WorkflowRejectResponse {
    items: WorkflowPayableDocumentResponse[];
    total: number;
    cfdis_cancelled?: CfdisCancelled;
}

export interface WorkflowApproveResponse {
    items: WorkflowPayableDocumentResponse[];
    total: number;
    cfdis_cancelled?: CfdisCancelled;
    queued: boolean;
}

export interface WorkflowPayableDocumentResponse {
    id: string;
    payable_document: Cfdi;
    report: string;
    error: number;
    cause: string;
}

export interface ChangeReceptorCfdisRequest {
    new_receptor_id: string;
    cfdi_ids: string[];
}

export interface TenantCfdisRequest {
    cfdi_ids: string[];
    comments: string;
}

export interface DeleteCfdiRequest {
    comments: string;
}

export interface ExportCfdisResponse {
    total: number;
    cfdis_without_payment_files: string;
    url?: string;
}

export interface ExportCfdiValidationsDatasetRequest {
    tenant_logo: string;
    report_creation_date: Date;
    type: string;
    currency: string;
    date: Date;
    description: string;
    identifier: string;
    issuer: string;
    total: number;
    total_paid: number;
    company: Company;
    cfdi: CfdiRaw;
    metadata: ExportCfdiMetadataRequest;
    history: CfdiHistory[];
    validations: CfdiValidation[];
}

export interface ExportCfdiMetadataRequest {
    createdAt: Date;
    sentAt: Date;
    authorized_at: Date;
    comments: string;
    type: string;
    status: string;
    cfdi_status_group: string;
    authorized_amount: AuthorizedAmount;
    balance: number;
    subtotal_base: number;
}

export interface CfdisChangeReceptor {
    total_sent: number;
    new_receptor_name: string;
}

export interface AdditionalAmountRequest {
    name: string;
    amount: number;
}

export interface CfdiPostponeRequest {
    date_postpone: Date;
    cfdi_ids: string[];
}

export interface CfdiUpdatePaymentDeadlineRequest {
    new_payment_deadline: Date;
}

export interface CfdiWorkflowRequest {
    workflow_id: string;
}

export interface ExpensesAccountQueryParams {
    search: string;
    start_date: string;
    end_date: string;
    company: string;
    provider: string;
    provider_external_id: string;
    expense_account_type: string;
    folio: string;
}

export interface ExpensesAccountParams {
    search: string;
    startDate: Date;
    endDate: Date;
    company: string;
    provider: string;
    providerExternalId: string;
    expenseAccountType: string;
    folio: string;
    pageSize: number;
    offset: number;
}

export interface AmountsByCurrency {
    currency: string;
    amounts: number[];
}

export const getTotalToAuthorize = (items: Cfdi[]) => {
    
    let amountsByCurrency = [] as AmountsByCurrency[];
        items.forEach(item => {
            let amountByCurrency = amountsByCurrency.find(field => field.currency === item.currency);
            let authorized = item.metadata.authorized_amount ? item.metadata.authorized_amount.total_authorized : item.metadata.balance;
            if(amountByCurrency){
                amountByCurrency.amounts.push(authorized);
            } else {
                amountsByCurrency.push({currency: item.currency, amounts: [authorized]} as AmountsByCurrency);
            }
        });
    
    let amountsByCurrencyStr = amountsByCurrency.map(amountByCurrency => {
        let amount = amountByCurrency.amounts.reduce((a, b) => a+b);
        let amuntStr = new Intl.NumberFormat(undefined, { style: 'currency', currency: amountByCurrency.currency }).format(amount);
        return `${amuntStr} ${amountByCurrency.currency}`;
    });
    if(amountsByCurrencyStr.length > 0){
        return amountsByCurrencyStr.join(" + ");
    }
    return "$ 0";
}