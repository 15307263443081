import React, { useState, useContext } from "react";
import translate from "../i18n/Translator";
import ListPaymentsArchived from "./ListPaymentsArchived";
import { RouterParams } from "../router/RouterParams";
import { TableSelection } from '@devexpress/dx-react-grid';
import { AppContext } from "../context/AppContext";
import { Statuses, Payment } from "../model/Payment";
import { isRoleOrParent } from "../model/Role";

export default function PaymentsArchivedView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const isProviderUser = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");

    const getStatus = (payment: Payment) => {
        if(payment.custom_status){
            return payment.custom_status;
        }
        return translate(`payment.status.${payment.status}`) as string;
    };

  const [tableColumnExtensionsP] = useState([
    { columnName: 'operation_number', width: 150, wordWrapEnabled: true },
    { columnName: 'payment_date', width: 200 },
    { columnName: 'company_name', width: 200 },
    { columnName: 'provider_name', width: isProviderUser ? 0 : 350, wordWrapEnabled: true },
    { columnName: 'comprobantes', width: 320, wordWrapEnabled: true},
    { columnName: 'deadline_payment_cfdi', width: 200, wordWrapEnabled: true },
    { columnName: 'total_amount', align: 'right', width: 150 },
    { columnName: 'menu', align: 'right', width: 50},
  ]) as any;

  const currencyColumns = ['total_amount'];

  const clickRowColumns = ['operation_number'];

  const defaultExpandedGroups = Statuses;

  const numberColumns = ['operation_number'];

  const textColumns = [isProviderUser ? '' : 'provider_name', 'company_name','comprobantes'];

  const dateColumns = ['payment_date', 'deadline_payment_cfdi'];

  const grouping = [{ columnName: 'status' }];

  const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'operation_number']) as any;

  const [rightColumns] = useState(['menu']) as any;

    return (
        <ListPaymentsArchived
        columns={[
          {
              name: 'operation_number',
              title: translate("payment.columns.number") as string
          },
          {
              name: 'payment_date',
              title: translate("payment.columns.date") as string
          },
          {
              name: 'company_name',
              title: translate('payments_cfdi.company') as string
           },
           {
            name: 'provider_name',
            title: translate('payments_cfdi.provider') as string
         },
          {
              name: 'comprobantes',
              title: translate("payment.columns.cfdis") as string
          },
          {
              name: 'deadline_payment_cfdi',
              title: translate("payment.columns.deadline") as string
          },
          {
              name: 'total_amount',
              title: translate("payment.columns.total") as string
          },
          {
              name: 'status',
              title: translate("payment.columns.status") as string,
              getCellValue: (row: any) => getStatus(row)
          },
          {
              name: 'menu',
              title: " "
          }
      ]}
      defaultExpandedGroups={defaultExpandedGroups}
      currencyColumns={currencyColumns}
      numberColumns={numberColumns}
      dateColumns={dateColumns}
      columnsFormat={tableColumnExtensionsP}
      clickRowColumns={clickRowColumns}
      leftColumns={leftColumns}
      rightColumns={rightColumns}
      textColumns={textColumns}
      grouping={grouping}
      title={translate("payment.archived.title") as string}
      success={match.params.success}
    />
    );

}