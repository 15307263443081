import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

import { getVerifyEmail } from "../api/AuthAPI";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import { User } from "../model/User";
import Progress from "../components/Progress";
import GoHome from "../components/GoHome";

export default function VerifyEmail({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [user, setUser] = useState<User>();

    useEffect(() => {
        setStatus("loading");
        getVerifyEmail(match.params.code).then((response) => {
            setUser(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [match.params.code]);

    if (status === "loading") {
        return (<Progress />);
    }

    if (context.session) {
        if (status === "loaded") {
            return <Redirect to={`/profile?email=success`} />
        }
        return <Redirect to={`/profile?email_error=${status}`} />
    }

    if (status !== "loaded") {
        return <GoHome message={status} />;
    }

    return (
        <GoHome title={translate("auth.verify_email.title") as string} message={translate("auth.verify_email.success_anonymous", { "email": user!.email }) as string} />
    );

}