import { callAPI, HTTPMethod } from "./API";
import { Announcement, AnnouncementRequest, AnnouncementQueryParams, Announcements, ChangeStatusRequest } from "../model/Announcement";

export async function listAnnouncements(tenantId: string, userId: string, page: number, offset: number, params: AnnouncementQueryParams): Promise<Announcements> {
    let query = {
        startDate: params.start_date ? params.start_date.replace(" - ", ",") : "",
        endDate: params.end_date ? params.end_date.replace(" - ", ",") : "",
        status: params.status || "",
        title: params.title || "",
        readed: params.readed !== undefined ? params.readed : "",
        userId: userId,
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;
    
    return await callAPI(`/tenants/${tenantId}/announcements`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function createAnnouncement(tenantId: string, request: AnnouncementRequest): Promise<Announcement> {
    return await callAPI(`/tenants/${tenantId}/announcements`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getAnnouncement(tenantId: string, announcementId: string): Promise<Announcement> {
    return await callAPI(`/tenants/${tenantId}/announcements/${announcementId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateAnnouncement(tenantId: string, announcementId: string, request: AnnouncementRequest): Promise<Announcement> {
    return await callAPI(`/tenants/${tenantId}/announcements/${announcementId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteAnnouncement(tenantId: string, announcementId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/announcements/${announcementId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function announcementReaded(tenantId: string, announcementId: string, providerId: string, userId: string): Promise<Announcement> {
    return await callAPI(`/tenants/${tenantId}/announcements/${announcementId}/readed/${providerId}/${userId}`, {
        method: HTTPMethod.PUT
    });
}

export async function changeStatusAnnouncement(tenantId: string, announcementId: string, request: ChangeStatusRequest): Promise<Announcement> {
    return await callAPI(`/tenants/${tenantId}/announcements/${announcementId}/change-status`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}