import React, { useContext, useState, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Grid } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';

import { getTenantCfdiFileUrl } from "../../api/TenantCfdiApi";
import { Cfdi, isAllowToDelete } from "../../model/Cfdi";
import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import { getCfdiFileUrl } from "../../api/ProviderCfdiApi";
import BlockIcon from '@material-ui/icons/Block';
import { isRoleOrParent } from "../../model/Role";

interface TenantCfdiArchiveMenuProps {
    cfdi: Cfdi;
    anchor: HTMLElement | null;
    inner?: boolean;
    referer: string;
    isProvider: boolean;
    onClose(): any;
    onAuthorize?(): any;
    onReject?(): any;
    onDeleteComprobante?(): any;
    onCancel(): any;
    onDownloadPaymentCfdi(): any;
    onDownloadFiles(): any;
}

export default function TenantCfdiArchiveMenu(props: TenantCfdiArchiveMenuProps) {
    const context = useContext(AppContext);
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [xmlUrlProvider, setXmlUrlProvider] = useState<string>();
    const [pdfUrlProvider, setPdfUrlProvider] = useState<string>();
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");

    useEffect(() => {
        if (props.isProvider) {
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "xml").then((urlFileProvider) => {
                setXmlUrlProvider(urlFileProvider);
            }).catch((error) => {
                setXmlUrlProvider(undefined);
            });
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "pdf").then((urlFileProvider) => {
                setPdfUrlProvider(urlFileProvider);
            }).catch((error) => {
                setPdfUrlProvider(undefined);
            });
        } else {
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "xml").then((urlFile) => {
                setXmlUrl(urlFile);
            }).catch((error) => {
                setXmlUrl(undefined);
            });
            getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
    }, [context.session, props.cfdi, props.isProvider]);

    return (
        <Grid>
            <Menu
                id="cfdi-menu-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}>

                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) && !props.inner &&
                    <MenuItem button component={Link} to={`/cfdis/${props.cfdi.id}/details?referer=${props.referer}`} onClick={props.onClose} dense>
                        <ListItemIcon>
                            <DetailsIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={props.isProvider ? xmlUrlProvider || "" : xmlUrl || ""} disabled={props.isProvider ? !xmlUrlProvider : !xmlUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadXmlIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onClose} dense component="a" href={props.isProvider ? pdfUrlProvider || "" : pdfUrl || ""} disabled={props.isProvider ? !pdfUrlProvider : !pdfUrl} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <DownloadPdfIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                    <MenuItem button onClick={props.onDownloadFiles} dense>
                        <ListItemIcon>
                            <DownloadIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.download_cfdi")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                    (props.cfdi.metadata.payment_info !== undefined && props.cfdi.metadata.payment_info.payments !== undefined
                        && props.cfdi.metadata.payment_info.payments.length > 0 ?
                        <MenuItem button onClick={props.onDownloadPaymentCfdi} dense>
                            <ListItemIcon>
                                <DownloadIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <Typography variant="inherit">{translate("cfdis.download_payment_cfdis")}</Typography>
                        </MenuItem> : undefined)
                }
                {!context.isGrantedAny(["AdminCFDIsUpdate", "ReceptorCFDIsUpdate", "ProviderCFDIsUpdate", "CxpCFDIsUpdate"]) || isReaderOnly || 
                (props.cfdi.metadata.status === "CANCELLED" || props.cfdi.metadata.status === "PAID" || (
                    props.cfdi.total === undefined && props.cfdi.type === "FOREIGN")
                ) || ( props.cfdi.type === "EXPENSES_ACCOUNT" || props.cfdi.type === "HONORARIUM") ? undefined :
                    <MenuItem button onClick={props.onCancel} dense>
                        <ListItemIcon>
                            <BlockIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate(props.cfdi.metadata.type === "CREDIT_NOTE" ? "cfdis.cancel_cfdi.title_cn" : "cfdis.cancel_cfdi.title")}</Typography>
                    </MenuItem>
                }
                {context.isGrantedAny(["AdminCFDIsDelete", "ReceptorCFDIsDelete", "CxpCFDIsDelete", "ProviderCFDIsDelete"])
                    && isAllowToDelete(props.cfdi) &&
                    <MenuItem button onClick={props.onDeleteComprobante} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography variant="inherit">{translate("cfdis.delete_cfdi")}</Typography>
                    </MenuItem>
                }
            </Menu>
        </Grid>);
}
