import React, {useState} from "react";
import Surface from "../components/Surface";
import { Grid, Typography, IconButton } from "@material-ui/core";
import translate from "../i18n/Translator";
import { WidgetMenuResponse } from "../model/Dashboard";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";

import ArrowIcon from "@material-ui/icons/ArrowForward";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

interface WidgetBalanceProps {
    widgets: WidgetMenuResponse[];
    type: string;
}

export default function WidgetBalance(props: WidgetBalanceProps) {
    let widgets = props.widgets as WidgetMenuResponse[];
    let data = [] as object[]

    for (let key in widgets) {
        let item = widgets[key] as WidgetMenuResponse;
        data.push({
            title: item.view,
            total: item.total,
        });
    }
 
    return (
        <Surface title={translate(`widget_menu.title.${props.type}`)} icon={<AccountBalanceIcon />} 
                titleActions={<ButtonRight disabled={widgets.length > 0 ? false: true} />}>
            {data.map((item: any, index: number) => (
                <Grid key={index} item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={7} md={7} lg={7} xl={7}>      
                            <Typography style={{marginLeft: "10px"}} variant="body2" component="h6">{translate(`currency.${item.title}`) + ` (${item.title})`}</Typography>          
                        </Grid>
                        <Grid item xs={5} md={5} lg={5} xl={5} className="widgetMenuNumber">      
                            <Typography align="left" color="secondary">
                                <NumberFormat value={item.total} prefix={`$`} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                            </Typography>          
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Surface>
    );
}

interface ButtonRightProps {
    disabled : boolean;
}

export function ButtonRight(props : ButtonRightProps) {
    const [redirect, setRedirect] = useState<string>();

    const onClickedGo = (route: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if("" !== route){
            setRedirect(route);
        }
    };
    
    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    let disabledP = props.disabled as boolean;
    return (
        <Grid item xs="auto">
            <IconButton size="small" disabled={disabledP} onClick={onClickedGo("/reports/cxp")} style={{marginTop: "7px"}}>
                <ArrowIcon />
            </IconButton>
        </Grid>        
    )
}