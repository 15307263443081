import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";

import { Button, Grid, Divider, IconButton, Fab, Hidden } from "@material-ui/core";
import ProvidersIcon from "@material-ui/icons/Business";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PublishIcon from "@material-ui/icons/Publish";
import AddUsersIcon from '@material-ui/icons/GroupAdd';

import translate from "../i18n/Translator";
import { getProvidersEfos, importProviders, importUsers, verifyEfo, verifyEfoAll, verifyCff69, verifyCff69All } from "../api/ProviderAPI";
import { Providers, Entity as Provider, ProvidersQueryParams, ImportSummary, ProviderStatuses, ImportUsersSummary, ProvidersQueryFilter } from "../model/Provider";
import { EfoValidationAll, EfoValues, Efos } from "../model/Efos";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import ProviderEfosMenu from "./ProviderEfosMenu";
import ImportResumePopup from "./ImportResumePopup";
import ImportUsersResumePopup from "./ImportUsersResumePopup";
import ProvidersEfosMenu from "./ProvidersEfosMenu";
import Gridable, { GridableColumn } from "../components/Gridable";
import ProviderVerificationPopup from "./ProviderVerificationPopup";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { AllProvidersEfosVerificationPopup, AllProvidersCff69VerificationPopup } from "./AllProvidersVerificationPopup";
import { Cff69Validation, Cff69ValidationAll, BlackListValues, BlackListValidations } from "../model/Cff69";
import CustomBackdrop from "../components/CustomBackdrop";
import { ProviderListCallbacks } from "./Providers";
import ConnectorTemplateDownloader from "../connectors/ConnectorTemplateDownloader";
import { Family } from "../model/Connector";

interface ProvidersEfosListProps {
    subtitle?: string;
    getColumns(providers: Provider[], showChecks: boolean, selected: string[], callbacks: ProviderListCallbacks): GridableColumn<Provider>[];
}

export default function ProvidersEfosList(props: ProvidersEfosListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const showChecks = context.isGranted("ProvidersUpdate");

    const efoLabels = EfoValues.map((value: string) => translate(`efo.status.${value}`) as string);
    const notLocatedLabels = BlackListValues.map((value: string) => translate(`69cff.status.${value}`) as string);
    const providerStatusLabels = ProviderStatuses.map((value: string) => translate(`providers.status.${value}`) as string);

    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Providers>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const [provider, setProvider] = useState<Provider>();
    const [efos, setEfos] = useState<Efos>();
    const [blackListValidations, setBlackListValidations] = useState<BlackListValidations>();
    const [efoValidationAll, setEfoValidationAll] = useState<EfoValidationAll>();
    const [cff69ValidationAll, setCff69ValidationAll] = useState<Cff69ValidationAll>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): ProvidersQueryFilter => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "black_list_status": typeof qs["black_list_status"] === "string" ? qs["black_list_status"] as string : "",
            "efos_status": typeof qs["efos_status"] === "string" ? qs["efos_status"] as string : "",
            "status": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "classification": typeof qs["classification"] === "string" ? qs["classification"] as string : "",
            "validator_user_id": "",
            "receptor": "",
            "specific_rule": "",
            "workflow_id": "",
        } as ProvidersQueryFilter;
    };
    const [params, setParams] = useState<ProvidersQueryFilter>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<ProvidersQueryFilter>(paramsFromQueryString);
    const fileInput = useRef<HTMLInputElement>(null);
    const [processing, setProcessing] = useState(false);
    const [backDropMessage, setBackDropMessage] = useState<string>("default_backdrop_message");
    const [importSummary, setImportSummary] = useState<ImportSummary>();
    const [importUsersSummary, setImportUsersSummary] = useState<ImportUsersSummary>();
    const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>([]);
    const [classificationIds, setClassificationIds] = useState<string[]>([]);
    const [classificationNames, setClassificationNames] = useState<string[]>([]);
    const [importType, setImportType] = useState<string>("import_providers" || "import_users");
    const [downloadTemplate, setDownloadTemplate] = useState(false);

    const showVerifyEfoAll = () => {
        setGridAnchorEl(null);
        setProcessing(true);
        setBackDropMessage("validating");
        verifyEfoAll(context.session!.tenant!.id).then((response) => {
            if (response.queued) {
                setSuccess(translate("efo.all_queued") as string);
            } else {
                setEfoValidationAll(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setProcessing(false);
        });

    }

    const showVerifyAllCff69 = () => {
        setGridAnchorEl(null);
        setProcessing(true);
        setBackDropMessage("validating");
        verifyCff69All(context.session!.tenant!.id).then((response) => {
            let anyQueued = (Object.values(response.validations) as Cff69Validation[]).find(v => v.queued) !== undefined;
            if (anyQueued) {
                setSuccess(translate("cff_69.all_queued") as string);
            } else {
                setCff69ValidationAll(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setProcessing(false)
        });
    }

    const onCloseAllProvidersValidationPopup = () => {
        setEfoValidationAll(undefined);
        load();
    }

    const onCloseAllProvidersCff69Popup = () => {
        setCff69ValidationAll(undefined);
        load();
    }

    const onVerifyEfo = () => {
        if (!provider) return;

        setAnchorEl(null);
        verifyEfo(context.session!.tenant!.id, provider.id).then((response) => {
            let current = data!.items.find((p) => p.id === provider.id);
            if (current) {
                current.efos = response.efos;
                setProvider(current);
            }
            setEfos(response.efos);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const onVerifyCff69 = () => {
        if (!provider) return;

        setAnchorEl(null);
        verifyCff69(context.session!.tenant!.id, provider.id).then((response) => {
            if (!response.black_list_validations) return;

            let current = data!.items.find((p) => p.id === provider.id);
            if (current) {
                current.black_list_validations = response.black_list_validations;
                setProvider(current);
            }

            setBlackListValidations(response.black_list_validations);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const onCancelVerification = () => {
        setEfos(undefined);
        setBlackListValidations(undefined);
        setProvider(undefined);
    };

    const filterToParams = (filter: ProvidersQueryFilter) => {
        let not_located_status = "";
        let canceled_status = "";
        let enforceable_status = "";
        let firm_status = "";
        let sentences_status = "";
        let in_order = "false";
        switch (filter.black_list_status) {
            case "NO_LOCALIZADO":
                not_located_status = filter.black_list_status;
                break;
            case "CANCELADO":
                canceled_status = filter.black_list_status;
                break;
            case "EXIGIBLE":
                enforceable_status = filter.black_list_status;
                break;
            case "SENTENCIADO":
                sentences_status = filter.black_list_status;
                break;
            case "FIRME":
                firm_status = filter.black_list_status;
                break;
            case "IN_ORDER":
                in_order = "true";
                break;
        }
        return {
            ...filter,
            not_located_status: not_located_status,
            canceled_status: canceled_status,
            enforceable_status: enforceable_status,
            firm_status: firm_status,
            sentences_status: sentences_status,
            in_order: in_order
        } as ProvidersQueryParams;//TODO Verificar si en el listado se va a mostrar los acreedores o solo proveedores
    };

    const load = () => {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        setSelectedProviderIds([]);
        getProvidersEfos(context.session!.tenant!.id, pageSize, offset, filterToParams(params)).then((response) => {
            setData(response);
            setClassificationIds(response.classifications ? response.classifications.map((classification) => classification.id) : []);
            setClassificationNames(response.classifications ? response.classifications.map((classification) => classification.name) : []);
        }).catch((error) => {
            setStatus(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [context.session, page, pageSize, params, props]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["efos_status"] = workingParams.efos_status;
        qs["black_list_status"] = workingParams.black_list_status;
        qs["status"] = workingParams.status;
        qs["classification"] = workingParams.classification;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setProvider(undefined);
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setProcessing(true);
        setBackDropMessage("importing")
        if (importType === "import_providers") {
            importProviders(context.session!.tenant!.id, event.target.files[0]).then((response) => {
                setImportSummary(response);
                load();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setProcessing(false);
                fileInput.current!.value = "";
            });
        }

        if (importType === "import_users") {
            importUsers(context.session!.tenant!.id, event.target.files[0]).then((response) => {
                setImportUsersSummary(response);
                load();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setProcessing(false);
                fileInput.current!.value = "";
            });
        }

    }

    const onDownloadTemplate = () => {
        setGridAnchorEl(null);
        setDownloadTemplate(true);
    };

    const openFileImportProviders = () => {
        fileInput.current!.click();
        setImportType("import_providers");
        setGridAnchorEl(null);
    }

    const openFileImportUsers = () => {
        fileInput.current!.click();
        setImportType("import_users");
        setGridAnchorEl(null);
    }

    const onCloseImportDialog = () => {
        setImportSummary(undefined);
        setImportUsersSummary(undefined);
        setEfoValidationAll(undefined);
        setGridAnchorEl(null);
        fileInput.current!.value = "";
    };

    const onClickedOptions = (provider: Provider) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setProvider(provider);
    };

    const handleCheck = (provider: Provider) => {
        let items = selectedProviderIds.length === 0 ? ([] as string[]) : selectedProviderIds.join(",").split(",");
        const index = selectedProviderIds.indexOf(provider.id);
        if (index < 0) {
            items.push(provider.id);
        } else {
            items.splice(index, 1);
        }
        setSelectedProviderIds(items);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = data!.items.map(provider => provider.id);
        } else {
            items = [] as string[];
        }
        setSelectedProviderIds(items);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        <Pagination title={translate("providers.title")} icon={<ProvidersIcon />}
            subtitle={props.subtitle}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("ProvidersCreate") ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Link to="/providers/new">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                <AddIcon />
                            </Fab>
                        </Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Hidden xsDown implementation="css">
                            <Fab color="secondary" size="small" title={translate("providers.import.button") as string} onClick={openFileImportProviders}>
                                <PublishIcon />
                            </Fab>
                        </Hidden>
                    </Grid>
                    {context.isGranted("OwnerProvidersUsersCreate") ?
                        <Grid item xs="auto">
                            <Hidden xsDown implementation="css">
                                <Fab color="secondary" size="small" title={translate("providers.import_users.button") as string} onClick={openFileImportUsers}>
                                    <AddUsersIcon />
                                </Fab>
                            </Hidden>
                        </Grid> : undefined}
                    <Grid item xs="auto">
                        <IconButton color="default" size="small" onClick={onClickedMore} disabled={processing}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>) : undefined
            }>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm={7} md={7} lg>
                        <ValidatedInput type="text" id="search" name="search" label={translate("providers.filter_search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg="auto">
                        <ValidatedInput type="text" id="status" name="status" label={translate("providers.status.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.status} onValueChanged={onFilterChanged}
                            options={ProviderStatuses}
                            optionLabels={providerStatusLabels}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
                        <ValidatedInput type="text" id="classification" name="classification" label={translate("providers.classifications.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.classification} onValueChanged={onFilterChanged}
                            options={classificationIds}
                            optionLabels={classificationNames}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm lg="auto">
                        <ValidatedInput type="text" id="efos_status" name="efos_status" label={translate("efo.column") as string}
                            margin="dense" disabled={false}
                            value={workingParams.efos_status} onValueChanged={onFilterChanged}
                            options={EfoValues}
                            optionLabels={efoLabels}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs={12} sm lg="auto">
                        <ValidatedInput type="text" id="black_list_status" name="black_list_status" label={translate("cff_69.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.black_list_status} onValueChanged={onFilterChanged}
                            options={BlackListValues}
                            optionLabels={notLocatedLabels}
                            emptyOption={translate("any") as string} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("providers.empty") as string}
                columns={props.getColumns(data ? data.items : [], showChecks, selectedProviderIds, {
                    onClickedOptions: onClickedOptions,
                    handleCheck: handleCheck,
                    handleAllChecks: handleAllChecks
                })} />
            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept={importType === "import_providers" ? ".csv, .xlsx, .xls, .xml, .json, .txt, .tsv" : ".xlsx, .xls, .csv"} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            {provider && anchorEl && (
                <ProviderEfosMenu provider={provider}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onVerifyEfo={onVerifyEfo}
                    onVerifyCff69={onVerifyCff69}
                />
            )}
            {provider && (efos || blackListValidations) && (
                <ProviderVerificationPopup
                    name={provider.name}
                    rfc={provider.rfc}
                    efos={efos}
                    validations={blackListValidations}
                    onClose={onCancelVerification} />
            )}
            {efoValidationAll && (
                <AllProvidersEfosVerificationPopup data={efoValidationAll} onClose={onCloseAllProvidersValidationPopup} />
            )}
            {cff69ValidationAll && (
                <AllProvidersCff69VerificationPopup data={cff69ValidationAll} onClose={onCloseAllProvidersCff69Popup} />
            )}
            {importSummary && (
                <ImportResumePopup summaryImport={importSummary} onClose={onCloseImportDialog} />
            )}
            {importUsersSummary && (
                <ImportUsersResumePopup summaryImport={importUsersSummary} onClose={onCloseImportDialog} />
            )}
            {gridAnchorEl && (
                <ProvidersEfosMenu anchor={gridAnchorEl}
                    totalItems={data?.total}
                    onClose={() => setGridAnchorEl(null)}
                    onImportProviders={openFileImportProviders}
                    onDownloadTemplate={onDownloadTemplate}
                    onImportUsers={openFileImportUsers}
                    onVerifyEfoAll={showVerifyEfoAll}
                    onCff69All={showVerifyAllCff69}
                />
            )}
            {downloadTemplate && (
                <ConnectorTemplateDownloader tenantId={context.session?.tenant?.id || ""}
                    family={Family.PROVIDERS}
                    onClose={() => setDownloadTemplate(false)} />
            )}
            <CustomBackdrop open={processing} message={translate(`providers.${backDropMessage}`) as string} />
        </Pagination>
    )
}