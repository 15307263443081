import React, { ReactNode } from "react";
import { Grid } from "@material-ui/core";

interface RowProps {
    title: string;
    content: ReactNode;
}

export default function Row(props: RowProps) {
    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    {props.title}
                </Grid>
                <Grid item xs>
                    {props.content}
                </Grid>
            </Grid>
        </Grid>
    );
}