import React from "react";
import { ResponsiveContainer, Tooltip, TooltipPayload, PieChart, Pie, Cell, Legend } from "recharts";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { COLORS, COLORS_NOT_LOCATED, COLORS_STATUS } from "../providers/ProviderIcons";
import { COLORS as COLORS_EXPEDIENTS } from "../expedients/ExpedientIcon";

interface ProvidersChartProps {
    info: Map<string, number>;
    colors: any;
    translateStatus(status: string): string;
    height?: number;
    legend?: boolean;
    containerStyle?: any;
    innerRadius?: number;
    outerRadius?: number;
}

export function ProvidersChart(props: ProvidersChartProps) {
    let summary = props.info as any;
    let data = [] as object[];
    for (let key in summary) {
        let value = summary[key] as number;
        data.push({
            name: key,
            value: value
        });
    }

    const tooltipFormatter = (value: string | number | Array<string | number>, name: string, entry: TooltipPayload, index: number) => {
        if (typeof value === "string" || typeof value === "number") {
            return [<NumberFormat value={value} thousandSeparator="," displayType="text" />, props.translateStatus(name)];
        }
        return [value, props.translateStatus(name)];
    }

    return (
        <div style={props.containerStyle || { width: "100%", height: (props.height || 200) }}>
            <ResponsiveContainer>
                <PieChart>
                    <Tooltip formatter={tooltipFormatter} />
                    {props.legend && (
                        <Legend formatter={(status) => props.translateStatus(status)} align="center" verticalAlign="bottom" />
                    )}
                    <Pie data={data} dataKey="value" nameKey="name" innerRadius={props.innerRadius || 70} outerRadius={props.outerRadius || 100}>
                        {data.map((entry, index) => (
                            <Cell key={index} fill={props.colors[(entry as any).name]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

interface ChartProps {
    info: Map<string, number>;
    height?: number;
    legend?: boolean;
    containerStyle?: any;
    innerRadius?: number;
    outerRadius?: number;
}

interface EfosChartProps extends ChartProps {
}

export function EfosChart(props: EfosChartProps) {
    return (
        <ProvidersChart {...props}
            colors={COLORS}
            translateStatus={(status) => translate(`efo.status.${status}`) as string} />
    );
}

interface NotLocatedChartProps extends ChartProps {
}

export function NotLocatedChart(props: NotLocatedChartProps) {
    return (
        <ProvidersChart {...props}
            colors={COLORS_NOT_LOCATED}
            translateStatus={(status) => translate(`cff_69.categories.${status}`) as string} />
    );
}

interface ExpedientChartProps extends ChartProps {
}

export function ExpedientChart(props: ExpedientChartProps) {
    return (
        <ProvidersChart {...props}
            colors={COLORS_EXPEDIENTS}
            translateStatus={(status) => translate(`expedients.status_metadata_short.${status}`) as string} />
    );
}

interface StatusChartProps extends ChartProps {
}

export function StatusChart(props: StatusChartProps) {
    return (
        <ProvidersChart {...props}
            colors={COLORS_STATUS}
            translateStatus={(status) => translate(`providers.status.${status}`) as string} />
    );
}