import React, { useEffect, useState } from "react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { Route, useHistory, Redirect } from "react-router-dom";
import OAuthRedirectLogin from "./OAuthRedirectLogin";
import { RouterParams } from "../router/RouterParams";
import { ClientSsoConfig } from "../model/TenantConfiguration";
import { getClientSsoConfig } from "../api/SecurityConfigurationAPI";

export default function OAuthLogin({ match }: RouterParams) {

    const [oktaAuth, setOktaAuth] = useState<OktaAuth>();
    const [status, setStatus] = useState<string>("loading");
    const [redirect, setRedirect] = useState<string | undefined>();

    const history = useHistory();
    const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri || '/oauth-login', window.location.origin));
    }

    useEffect(() => {
        getClientSsoConfig(match.params.tenantId, { ApiKey: process.env.REACT_APP_SSO_CONFIG_API_KEY ?? '' })
            .then((config: ClientSsoConfig) => {
                const authClient = new OktaAuth({
                    issuer: config.access_token_issuer_url,
                    clientId: config.client_id,
                    redirectUri: config.redirect_url
                });
                setOktaAuth(authClient);
                setStatus('loaded');
            }).catch((error: any) => {
                setRedirect(`/signin/${error.message}/fail`);
            }).finally(() => {
                setStatus("loaded");
            });
    }, []);

    if (status === 'loading') {
        return <p>Loading data...</p>
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === 'loaded' && oktaAuth) {
        return (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <OAuthRedirectLogin tenantId={match.params.tenantId} />
                <Route path="/oauth-login/:tenantId/callback" component={LoginCallback} />
            </Security>
        );
    }

    return <p>{status}</p>

}
