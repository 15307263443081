import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent } from "@material-ui/core";

import translate from "../i18n/Translator";
import { TenantSegment } from "../model/TenantFieldCatalog";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import SimpleSwitch from "../components/SimpleSwitch";
import { UserGroup } from "../model/UserGroup";

import { AppContext } from "../context/AppContext";
import { getUserGroups } from "../api/UserGroupAPI";

export interface ExpedientFieldCatalogSegmentFormProps<T extends TenantSegment> {
    current?: T;
    index?: number;
    name: string;
    onCompleted(item: T, index?: number): any;
    onClose(): any;
    disabled: boolean;
    onError(error: string): any;
}

export default function ExpedientFieldCatalogSegmentForm<T extends TenantSegment>(props: ExpedientFieldCatalogSegmentFormProps<T>) {
    const context = useContext(AppContext);
    const isEdit = !!props.current;
    const margin = "dense";
    const [request, setRequest] = useState<T>({} as T);
    const [showUserGroups, setShowUserGroups] = useState<boolean>(false);
    const [userGroups, setUserGroups] = useState<UserGroup[]>();
    const [userGroupSelected, setUserGroupSelected] = useState<UserGroup>();
    const [validations, setValidations] = useState({
        name: {
            valid: false,
        } as InputRef,
        description: {
            valid: false,
        } as InputRef,
    } as any);

    useEffect(() => {
        setRequest({
            id: props.current?.id || "",
            name: props.current?.name || "",
            description: props.current?.description || "",
            required: props.current?.required || false,
            user_group_id: props.current?.user_group_id || ""
        } as T);
        getUserGroups(context.session!.tenant!.id, 0, 0,{search: ""}).then((response) => {
            setUserGroups(response.items);
            if(props.current && props.current.user_group_id) {
                setShowUserGroups(!!props.current.user_group_id);
                setUserGroupSelected(response.items.find(group => props.current!.user_group_id));
            }
        }).catch(error => props.onError(error.message));
    }, [props.current]);

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedSwitch = (name: string, checked: boolean) => {
        setRequest({ ...request, [name]: checked });
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            if(showUserGroups && !request.user_group_id){
                props.onError(translate('expedients.without_special_validators') as string);
            }
            return;
        }

        props.onCompleted(request, props.index);
    };

    const hangleUserGroups = (name: string, checked: boolean) => {
        if (!checked) {
            delete validations["user_group"];
            setValidations(validations);
            let tmp = request as any;
            delete tmp.user_group_id;
            setRequest(tmp as T);
        }
        setShowUserGroups(checked);
    };

    const onChangedUserGroup = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, user_group_id: value != "" ? value: undefined });
        validations[name] = inputRef;
        setValidations(validations);
        if(value != "") {
            setUserGroupSelected(userGroups?.find(group => group.id == value));
        }
    };

    return (
        <Dialog open maxWidth="sm"
            disableBackdropClick={props.disabled}
            disableEscapeKeyDown={props.disabled}
            onClose={props.onClose}
            aria-labelledby="segment-form-dialog-title"
            aria-describedby="segment-form-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="segment-form-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate(`expedients.${props.name}.${isEdit ? "edit" : "add"}`) as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="name"
                            name="name"
                            value={request.name}
                            label={translate("expedients.segments.name") as string}
                            required={true}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <ValidatedInput
                            type="text"
                            id="description"
                            name="description"
                            value={request.description}
                            label={translate("expedients.segments.description") as string}
                            required={false}
                            disabled={false}
                            margin={margin}
                            onValueChanged={hasChanged}
                        />
                        <SimpleSwitch
                            value="required"
                            label={translate("expedients.required") as string}
                            checked={request.required}
                            onChanged={hasChangedSwitch}
                            placement="end" />
                        <SimpleSwitch
                            value="add-special-validators"
                            label={translate("expedients.add_special_validators") as string}
                            checked={showUserGroups}
                            onChanged={hangleUserGroups}
                            placement="end" />
                        {showUserGroups &&
                        <ValidatedInput
                            type="text"
                            id="user_group" name="user_group"
                            label={translate("expedients.user_group") as string}
                            value={request.user_group_id}
                            required
                            margin={"dense"}
                            autocompleteOptions={userGroups}
                            autocompleteValue={userGroups?.find(group => group.id === request.user_group_id )}
                            autocompleteKey={userGroups?.find(group => group.id === request.user_group_id )?.id}
                            getId={group => group?.id}
                            getLabel={group => group ? group.name : ""}
                            onValueChanged={onChangedUserGroup} />
                        }
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary" disabled={props.disabled}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={props.disabled}>
                        {translate(isEdit ? "buttons.update" : "buttons.add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
