import { Typography } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { listProvidersOnlyIdName } from "../api/ProviderAPI";
import { emptyPromise } from "../api/API";
import { createRequisition, getRequisition, updateRequisition, createLines, updateLine, deleteLine } from "../api/RequisitionAPI";
import { listRequisitionTypeCatalog } from "../api/RequisitionTypeCatalogAPI";
import { list as listPaymentConditions } from "../api/PaymentConditionsAPI";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import { ProvidersQueryParams } from "../model/Provider";
import { Requisition, RequisitionRequest, RequisitionLinesCreateRequest, RequisitionLineUpdateRequest } from "../model/Requisition";
import { RouterParams } from "../router/RouterParams";
import { PaymentCondition, PaymentConditionListParams, TenantCurrenciesResponse } from "../model/TenantConfiguration";
import { RequisitionTypeCatalogListParams, RequisitionTypeCatalog, PurchaseOrderMode } from "../model/RequisitionTypeCatalog";
import { list as listProjects } from "../api/ProjectAPI";
import { ProjectListParams, Project, ProjectsResponse } from "../model/Project";
import { listByTenantBudgets } from "../api/CompanyAPI";
import { Company } from "../model/Company";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { AutocompleteItem } from "./RequisitionInputsHeader";
import HeaderRequisitionForm from "./HeaderRequisitionForm";
import DetailsRequisitionForm from "./DetailsRequisitionForm";
import DetailsRequisitionFormBudgetControl from "./DetailsRequisitionFormBudgetControl";
import { listBudgets } from "../api/BudgetApi";
import { Budget, BudgetsQueryParams } from "../model/Budget";

export default function RequisitionForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const roleId = context.session!.role.id;
    const userId = context.session!.user.id;
    const [view, setView] = useState<"header" | "details">("header");
    const history = useHistory();
    const [error, setError] = useState("");
    const [status, setStatus] = useState<string>("loading");
    const [redirect, setRedirect] = useState<string>();
    const [isBudgetControl, setIsBudgetControl] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(!!match.params.requisitionId);
    const [paymentConditions, setPaymentConditions] = useState<PaymentCondition[]>([]);
    const [paymentConditionIds, setPaymentConditionIds] = useState<string[]>([]);
    const [paymentConditionLabels, setPaymentConditionLabels] = useState<string[]>([]);
    const [typeValues, setTypeValues] = useState<string[]>([]);
    const [typeLabels, setTypesLabels] = useState<string[]>([]);
    const [projectsAutocomplete, setProjectsAutocomplete] = useState<Project[]>([]);
    const [projectSelected, setProjectSelected] = useState<Project>();
    const [providersAutocomplete, setProvidersAutocomplete] = useState<AutocompleteItem[]>([]);
    const [providerSelected, setProviderSelected] = useState<AutocompleteItem>({} as AutocompleteItem);
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);
    const [requesitionTypes, setRequisitionTypes] = useState<RequisitionTypeCatalog[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [budgets, setBudgets] = useState<Budget[]>([]);
    const [currencies, setCurrencies] = useState<TenantCurrenciesResponse>();

    const [requisition, setRequisition] = useState<Requisition>({
        provider_id: "",
        requisition_type_id: "",
        description: "",
        in_project: false,
        project_id: "",
    } as Requisition);

    const submitPromise = (request: RequisitionRequest): Promise<Requisition> => {
        if (isEdit && (match.params.requisitionId || requisition.id)) {
            return updateRequisition(tenantId, match.params.requisitionId || requisition.id, request);
        }
        return createRequisition(tenantId, request);
    };

    const promiseGet = useCallback((): Promise<Requisition | undefined> => {
        if (match.params.requisitionId || requisition?.id) {
            return getRequisition(tenantId, requisition?.id ?? match.params.requisitionId);
        }
        return emptyPromise();
    }, [tenantId, match.params.requisitionId, requisition?.id]);

    const promiseProject = useCallback((): Promise<ProjectsResponse | undefined> => {
        if (!match.params.requisitionId) {
            return listProjects(tenantId, 0, 0, {
                search: "", status: ["TO_START", "IN_PROGRESS"].join(","),
                user_id: (roleId === "owner" || roleId === "project_manager") ? "" : userId
            } as ProjectListParams);
        }
        return emptyPromise();
    }, [tenantId, roleId, userId, match.params.requisitionId]);

    const load = () => {
        setStatus("loading");
        let isSelectedBudgetControlMode = match.params.mode && match.params.mode === PurchaseOrderMode.BUDGET_CONTROL;
        Promise.all([
            promiseGet(),
            promiseProject(),
            listPaymentConditions(tenantId, 0, 0, { active: "true" } as PaymentConditionListParams),
            listProvidersOnlyIdName(tenantId, 0, 0, { classification: "", search: "", validator_user_id: "", requisition_mode: true, status:"ENABLED" } as ProvidersQueryParams),
            getTenantCurrencies(tenantId),
            listRequisitionTypeCatalog(tenantId, 0, 0, { purchase_order_mode: isSelectedBudgetControlMode ? PurchaseOrderMode.BUDGET_CONTROL.toString() : PurchaseOrderMode.QUOTATION_BY_PROVIDER.toString() } as RequisitionTypeCatalogListParams),
            listByTenantBudgets(tenantId),
        ]).then(([requisition, projects, paymentConditions, providers, currencies, requisitionTypes, companies]) => {
            let requisitionTypesItems = requisitionTypes.items;
            if (isSelectedBudgetControlMode) {
                setIsBudgetControl(true);
            }
            setRequisitionTypes(requisitionTypesItems);
            if (requisition) {
                setRequisition(requisition);
                setTypeValues([requisition.requisition_type_id]);
                setTypesLabels([requisition.requisition_type_name]);

                const projectTemp = { name: requisition.project_name, id: requisition.project_id } as Project;
                setProjectsAutocomplete([projectTemp]);
                setProjectSelected(projectTemp);
                const temp = providers.items.map(prov => {
                    return {
                        title: prov.rfc + " " + prov.name,
                        value: prov.id
                    } as AutocompleteItem;
                });
                const providerTemp = temp.find(p => p.value === requisition.provider_id);
                if (providerTemp) {
                    setProviderSelected(providerTemp);
                }

                if (requisitionTypesItems) {
                    let typeInRequisition = requisitionTypesItems.find(t => t.id === requisition?.requisition_type_id);
                    let tempInRequisition = [] as AutocompleteItem[];
                    if (typeInRequisition && !typeInRequisition.all_providers && typeInRequisition.provider_ids) {
                        typeInRequisition.provider_ids.forEach(tir => {
                            let temp_tir = temp.find(t => t.value === tir);
                            if (temp_tir) {
                                tempInRequisition.push(temp_tir);
                            }
                        });
                        setProvidersAutocomplete(tempInRequisition);
                    } else {
                        setProvidersAutocomplete(temp);
                    }

                }
                setView("details");
            } else {
                const requisitionTypesTemp = requisitionTypesItems.filter(v => v.active);
                setTypeValues(requisitionTypesTemp.map(p => p.id));
                setTypesLabels(requisitionTypesTemp.map(p => p.name));
                setRequisitionTypes(requisitionTypesTemp);

                let temp = providers.items.map(prov => {
                    return {
                        title: prov.rfc + " " + prov.name,
                        value: prov.id
                    } as AutocompleteItem;
                });
                setProvidersAutocomplete(temp);

                setProjectsAutocomplete(projects?.items ?? []);
            }
            getCurrencies(currencies);
            setCurrencies(currencies);

            if (paymentConditions) {
                setPaymentConditions(paymentConditions.items);
                setPaymentConditionIds(paymentConditions.items.map(p => p.id));
                setPaymentConditionLabels(paymentConditions.items.map(p => p.name));
            }
            setCompanies(companies.items || []);

            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    }

    useEffect(load, [tenantId, promiseGet, promiseProject]);

    useEffect(() => {
        listBudgets(tenantId, 0, 0, {} as BudgetsQueryParams).then((budgetsResponse) => {
            setBudgets(budgetsResponse.items);
        }).catch(error => {
            setStatus(error.message);
        });
    }, [tenantId]);


    const getCurrencies = (resp: any) => {
        if (resp.tenant_currency_ids) {
            let listTemp = [] as AutocompleteProvider[];
            if (resp.tenant_currency_ids) {
                resp.tenant_currency_ids.forEach((itemCurrency: any) => {
                    let temp = { title: (itemCurrency + " - " + (translate("currency." + itemCurrency) as string)), value: itemCurrency } as AutocompleteProvider;
                    listTemp.push(temp);
                });
                setCurrenciesAutocomplete(listTemp);
            }
        }
    };

    const onSaveHeaders = (request: RequisitionRequest) => {
        submitPromise(request).then(requisition => {
            onSaveGeneric(requisition);
            if (!isEdit) {
                setIsEdit(true);
            }
            setView("details");
        }).catch(error => {
            setError(error.message);
        });
    };

    const onSaveDetails = (request: RequisitionRequest) => {
        submitPromise(request).then(requisition => {
            setRedirect("/requisitions/to_send/message/" + translate("requisitions.form.success_create"));
        }).catch(error => {
            setError(error.message);
        });
    };

    const onSaveGeneric = (requisition: Requisition) => {
        setRequisition(requisition);
        let provider = providersAutocomplete.find(p => p.value === requisition.provider_id);
        if (provider) {
            setProviderSelected(provider);
        }
        let project = projectsAutocomplete.find(p => p.id === requisition.project_id);
        if (project) {
            setProjectSelected(project);
        }
    };

    const onSaveLines = (requestLines: RequisitionLinesCreateRequest) => {
        setStatus("loading");
        createLines(tenantId, requisition.id, requestLines).then(requisition => {
            setRequisition(requisition);

        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onUpdateLine = (number: number, requestLines: RequisitionLineUpdateRequest) => {
        setStatus("loading");
        updateLine(tenantId, requisition.id, number.toString(), requestLines).then(requisition => {
            setRequisition(requisition);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onDeleteLine = (number: number) => {
        setStatus("loading");
        deleteLine(tenantId, requisition.id, number.toString()).then(requisition => {
            setRequisition(requisition);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    const onBackToList = () => {
        history.goBack();
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    const onUpdateAdditionalInfo = (requisitionNew: Requisition) => {
        setRequisition(requisitionNew);
    };

    const onOpenEditRequisition = () => {
        setView("header");
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }
    switch (view) {
        case "header":
            return <><HeaderRequisitionForm
                isEdit={!!match.params.requisitionId || !!requisition.id}
                requisition={requisition}
                types={typeValues}
                typeLabels={typeLabels}
                projects={projectsAutocomplete}
                projectSelected={projectSelected!}
                providers={providersAutocomplete}
                providerSelected={providerSelected}
                currencies={currenciesAutocomplete}
                isBudgetControl={isBudgetControl}
                companies={companies}
                onBack={onBackToList}
                onSave={onSaveHeaders}
                onValidHeaders={onValidHeaders}
                tenantId={tenantId}
                rolId={roleId}
                userId={userId}
                typesRequisitions={requesitionTypes}
            />
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} /></>;
        case "details":
            return <> {isBudgetControl ?
                <DetailsRequisitionFormBudgetControl
                    isEdit={!!match.params.requisitionId || !!requisition.id}
                    requisition={requisition}
                    types={typeValues}
                    typeLabels={typeLabels}
                    projects={projectsAutocomplete}
                    projectSelected={projectSelected!}
                    paymentConditions={paymentConditions}
                    paymentConditionIds={paymentConditionIds}
                    paymentConditionLabels={paymentConditionLabels}
                    currencies={currenciesAutocomplete}
                    tenantCurrencies={currencies ?? {} as TenantCurrenciesResponse}
                    budgets={budgets}
                    providers={providersAutocomplete}
                    providerSelected={providerSelected}
                    isBudgetControl={isBudgetControl}
                    companies={companies}
                    onSave={onSaveDetails}
                    onBack={onBackToList}
                    onCreateLines={onSaveLines}
                    onUpdateLine={onUpdateLine}
                    onDeleteLine={onDeleteLine}
                    onUpdateAdditionalInfo={onUpdateAdditionalInfo}
                    onValidHeaders={onValidHeaders}
                    onOpenEditRequisition={onOpenEditRequisition}
                    tenantId={tenantId}
                    rolId={roleId}
                    userId={userId}
                    typesRequisitions={requesitionTypes}
                />
                :
                <DetailsRequisitionForm
                    isEdit={!!match.params.requisitionId}
                    requisition={requisition}
                    types={typeValues}
                    typeLabels={typeLabels}
                    projects={projectsAutocomplete}
                    projectSelected={projectSelected!}
                    paymentConditions={paymentConditions}
                    paymentConditionIds={paymentConditionIds}
                    paymentConditionLabels={paymentConditionLabels}
                    currencies={currenciesAutocomplete}
                    providers={providersAutocomplete}
                    providerSelected={providerSelected}
                    isBudgetControl={isBudgetControl}
                    companies={companies}
                    onSave={onSaveDetails}
                    onBack={onBackToList}
                    onUpdateAdditionalInfo={onUpdateAdditionalInfo}
                    onValidHeaders={onValidHeaders}
                    onValidQuotation={onValidQuotation}
                    tenantId={tenantId}
                    rolId={roleId}
                    userId={userId}
                    typesRequisitions={requesitionTypes}
                />
            }
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} /></>;
        default:
            return <></>
    }
}

function onValidHeaders(request: RequisitionRequest, validateCompany: boolean) {
    if (request.requisition_type_id === "") {
        return "requisitions.form.warning_type";
    }
    if (request.in_project && request.project_id === "") {
        return "requisitions.form.warning_project";
    }
    if (request.provider_id === "") {
        return "requisitions.form.warning_provider";
    }
    if (request.description === "") {
        return "requisitions.form.warning_description";
    }
    if (!request.in_project && (!request.currency || request.currency === "")) {
        return "requisitions.form.warning_currency";
    }
    if (validateCompany && (!request.company_id || request.company_id === "")) {
        return "requisitions.form.warning_company";
    }
}

function onValidQuotation(request: RequisitionRequest) {
    if (request.quotation.amount === 0) {
        return "requisitions.form.warning.amount";
    }
    if (request.quotation.amount < 0) {
        return "requisitions.form.warning.amount_zero";
    }
    if (request.quotation.file_url === "") {
        return "requisitions.form.warning.file";
    }
    if (request.quotation.number === "") {
        return "requisitions.form.warning.number";
    }
    if (request.quotation.payment_condition_id === "") {
        return "requisitions.form.warning.payment_condition";
    }
    return "";
}
