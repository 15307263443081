import React from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";

interface ProductServiceMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDelete(): any;
}

export default function ProductServiceMenu(props: ProductServiceMenuProps) {
    return (
        <Menu
            id="cfdi-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

                 <MenuItem button onClick={props.onDelete} dense>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                    </MenuItem>
        </Menu>);
}
