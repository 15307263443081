import { callAPI, HTTPMethod } from "./API";
import { CustomerShippingRequirementRequest, CustomerShippingRequirement, DocumentCombos } from "../model/Customer";

const basePath = (tenantId: string) => `/tenants/${tenantId}/customer-shipping-requirements`;

export async function createCustomerShippingRequirement(tenantId: string, request: CustomerShippingRequirementRequest): Promise<CustomerShippingRequirement> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function updateCustomerShippingRequirement(tenantId: string, id: string, request: CustomerShippingRequirementRequest): Promise<CustomerShippingRequirement> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCustomerShippingRequirement(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function listDocumentsByCustomer(tenantId: string): Promise<DocumentCombos> {
    return await callAPI(`${basePath(tenantId)}/list-documents`, {
        method: HTTPMethod.GET
    });
}