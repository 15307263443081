import React, { useState, useEffect, useContext } from "react";
import AlarmTwoToneIcon from '@material-ui/icons/AlarmTwoTone';
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import { ReportLatePaymentCfdis as Report, ReportLatePaymentCfdisParams } from "../model/Reports";
import { Grid, Fab, Icon } from "@material-ui/core";
import queryString from "query-string";
import { getReportLatePaymentCfdis, exportReportLatePaymentCfdi } from "../api/ReportsAPI";
import Progress from "../components/Progress";
import { ExportProviders } from "../model/Provider";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { Filter, TableSelection } from "@devexpress/dx-react-grid";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

export default function ReportLatePaymentCfdis() {
  const context = useContext(AppContext);
  const [status, setStatus] = useState<string>("loading");
  const [report, setReport] = useState<Report>();
  const [exportResult, setExportResult] = useState<ExportProviders>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const qs = queryString.parse(window.location.search);
  const paramsFromQueryString = (): ReportLatePaymentCfdisParams => {
    return {
      "name_provider": typeof qs["name_provider"] === "string" ? qs["name_provider"] as string : "",
      "rfc_provider": typeof qs["rfc_provider"] === "string" ? qs["rfc_provider"] as string : "",
      "external": typeof qs["external"] === "string" ? qs["external"] as string : "",
      "no_late": typeof qs["no_late"] === "string" ? qs["no_late"] as string : "",
      "amount": typeof qs["amount"] === "string" ? qs["amount"] as string : "",
      "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : ""
    } as ReportLatePaymentCfdisParams;
  };
  const [params, setParams] = useState<ReportLatePaymentCfdisParams>(paramsFromQueryString);
  const [workingParams, setWorkingParams] = useState<ReportLatePaymentCfdisParams>(paramsFromQueryString);
  const paramsToFilters = (): Filter[] => {
    return [
      { columnName: 'name_provider', value: params.name_provider },
      { columnName: 'rfc_provider', value: params.rfc_provider },
      { columnName: 'external', value: params.external },
      { columnName: 'amount', value: params.amount },
      { columnName: 'currency', value: params.currency },
      { columnName: 'no_late', value: params.no_late },
    ] as Filter[];
  };
  const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

  useEffect(() => {
    setStatus("loading");
    getReportLatePaymentCfdis(context.session!.tenant!.id, params).then(response => {
      if (response && response.items)
        setReport(response);
    }).catch(error => {
      setStatus(error.message);
    }).finally(() => {
      setStatus("loaded");
    });
  }, [context.session, params, filters]);

  const onExport = () => {
    exportReportLatePaymentCfdi(context.session!.tenant!.id).then(response => {
      if (response.url) {
        setExportResult(response);
      } else {
        setSuccess(translate("providers.email_export", { "total": response.total }) as string);
      }
    }).catch(error => {
      setError(error.message);
    });
  };

  const setFiltersHandler = (filters: Filter[]) => {
    setFilters(filters);
    setParamsFromfilters(filters);
  };

  const setParamsFromfilters = (filters: Filter[]) => {
    let temp = params;
    filters.forEach(filter => {
      if (filter.value !== undefined) {
        switch (filter.columnName) {
          case "name_provider":
            temp.name_provider = filter.value;
            break;
          case "rfc_provider":
            temp.rfc_provider = filter.value;
            break;
          case "external":
            temp.external = filter.value;
            break;
          case "no_late":
            temp.no_late = filter.value;
            break;
          case "amount":
            temp.amount = filter.value;
            break;
          case "currency":
            temp.currency = filter.value;
            break;

          default: break;
        }
      }
    });
    setWorkingParams(temp);
    setParams(temp);
  };

  const onAppliedFilter = () => {
    setParams(workingParams);
  };

  const onCloseSnackbars = () => {
    setError(undefined);
    setSuccess(undefined);
  };

  const onCloseDownloadProviders = () => {
    setExportResult(undefined);
  }

  const tableColumnExtensions = [
    { columnName: 'name_provider', wordWrapEnabled: true, width: '350', align: 'left'},
    { columnName: 'rfc_provider', wordWrapEnabled: true, width: '200', align: 'left' },
    { columnName: 'external', wordWrapEnabled: true, width: '150', align: 'left' },
    { columnName: 'no_late', wordWrapEnabled: true, width: '150', align: 'left' },
    { columnName: 'days_late', wordWrapEnabled: true, width: '150', align: 'left' },
    { columnName: 'date_oldest', wordWrapEnabled: true, width: '200', align: 'center' },
    { columnName: 'amount', wordWrapEnabled: true, align: 'right' },
    { columnName: 'currency', wordWrapEnabled: true, align: 'right' },
  ] as any;

  const currencyColumns = ['amount'];

  const dateColumns = ['date_oldest'];

  const filtersExclude = ['date_oldest', 'days_late'];

  const numberColumns = ['external', 'no_late', 'days_late'];

  const textColumns = ['name_provider', 'rfc_provider', 'external', 'no_late', 'currency'];

  const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'name_provider']) as any;

  const NormalTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider {...props} /> );
  const customPlugins = [ <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />];

  

  const onClickedRow = () => {};

  if (status === "loading") {
    return (<Progress />);
  }

  return (
    <Surface
      title={translate("reports.late_cfdi_payments.title") as string}
      subtitle={translate("reports.late_cfdi_payments.subtitle") as string}
      icon={<AlarmTwoToneIcon />}
      className="PaperPagination" titleActions={
        <Grid container alignItems="center" justify="flex-end" spacing={1}>
          {report && report.items.length > 0 && 
            <Grid item xs="auto">
              <Fab color="primary" size="small" title={translate("reports.late_cfdi_payments.export") as string} onClick={onExport} >
                <Icon className="fas fa-file-excel" fontSize="small" />
              </Fab>
            </Grid>}
        </Grid>
      }>
      <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
        <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
          <Grid item xs={12} sm>
            
          </Grid>
          <Grid item xs="auto">
          
          </Grid>
        </Grid>
      </form>
      {report ?
        <GridDx
        columns={[
          {
            name: 'name_provider',
            title: translate('reports.late_cfdi_payments.columns.name_provider') as string
          },
          {
            name: 'rfc_provider',
            title: translate('reports.late_cfdi_payments.columns.rfc_provider') as string
          },
          {
            name: 'external',
            title: translate('reports.late_cfdi_payments.columns.external_id') as string
          },
          {
            name: 'no_late',
            title: translate('reports.late_cfdi_payments.columns.no_late') as string
          },
          {
            name: 'days_late',
            title: translate('reports.late_cfdi_payments.columns.days_late') as string
          },
          {
            name: 'date_oldest',
            title: translate("reports.late_cfdi_payments.columns.date_oldest") as string
          },
          {
            name: 'amount',
            title: translate("reports.late_cfdi_payments.columns.amount") as string
          },
          {
            name: 'currency',
            title: translate("reports.late_cfdi_payments.columns.currency") as string
          }
        ]}
        loading={status === "loading"}
        rows={report ? report.items : []}
        amountCurrencyColumns={currencyColumns}
        quantityColumns={numberColumns}
        dateColumns={dateColumns}
        columnsFormat={tableColumnExtensions}
        textColumns={textColumns}
        filters={filters}
        setFiltersHandler={setFiltersHandler}
        onClickRow={onClickedRow}
        leftColumns={leftColumns}     
        customFormatColumns={customPlugins}    
    />
        : undefined}
      {exportResult && exportResult.url && (
        <DownloadExportationPopup title={translate("providers.pending_payments.export.title") as string} message={translate("providers.pending_payments.export.message", { "total": exportResult.total }) as string} url={exportResult.url} onClose={onCloseDownloadProviders} />
      )}
      <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
      <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
    </Surface>
  );
}
