import { InputAdornment, IconButton, TextField, Grid, Box } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React, { useState, useEffect, useRef } from "react";
import { InputValidator, RequiredValidator } from "./Validators";
import translate from "../i18n/Translator";
import Tooltip from "@material-ui/core/Tooltip";
import Progress from "../components/Progress";
import { upload } from "../api/S3UploaderApi";

const REQUIRED_VALIDATOR = new RequiredValidator();

export interface InputRef {
    valid: boolean;
    blurer(blur: boolean): void;
    original_name?: string;
}

interface UploaderInputConfig {
    id: string;
    name: string;
    label: string;
    value?: string;
    description?: string;
    nameAuxFile?: string;
    linkAuxFile?: string;
    required?: boolean;
    disabled: boolean;
    acceptExtension?: string;
    variant?: "filled" | "outlined" | "standard";
    margin?: "normal" | "dense" | "none";
    icon?: "CHECK" | "WARNING" | "SCHEDULE";
    path?: string;
    readOnly?: boolean;
    startAdornment?: React.ReactNode;
    onValueChanged?(name: string, value: string, inputRef: InputRef): void;
    onClick?(): any;
    tooltip?: string;
    newFileName?: string;
}

export default function UploaderInput(config: UploaderInputConfig) {
    const [blured, setBlured] = useState(false);
    const [validation, setValidation] = useState<InputRef>({
        valid: true,
        blurer: setBlured,
        original_name: "",
    } as InputRef);
    const [error, setError] = useState<string>("");
    const [value, setValue] = useState<string>(config.value || "");

    const [uploading, setUploading] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);

    const inputBlured = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!blured) {
            setBlured(true);
        }
    };

    useEffect(() => {
        validate(config.value || "");
        // eslint-disable-next-line
    }, [config.required, config.disabled, config.value]);

    const openFile = () => {
        fileInput.current!.click();
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        let file = event.target.files[0];
        if (!file) {
            return;
        }

        setUploading(true);
        let inputRef = validation;
        validation.original_name = file.name ? file.name : "";
        setValidation(inputRef);
        if (config.onClick !== undefined) {
            config.onClick();
        }

        let extension = file.name.split('.').length > 1 ? file.name.split('.').pop() : undefined;
        let newFileName = "";
        if (config.newFileName !== undefined) {
            newFileName = extension ? `${config.newFileName}.${extension}` : `${config.newFileName}`;
        } else {
            newFileName = extension ? `${config.path || config.id}_${new Date().getTime()}.${extension}` : `${file.name}_${new Date().getTime()}`;
        }
        upload(`${process.env.REACT_APP_AWS_S3_DIR}/${newFileName}` , file, {
            onError: (error) => {
                setError(error);
            },
            onSuccessUpload: (location) => {
                validate(location);
            },
            onFinally: () => {
                fileInput.current!.value = "";
                setUploading(false);
            }
        }, true);
    };

    const validate = (targetValue: string) => {
        var inptValue = "";
        if (targetValue) {
            let values = targetValue.split("/");
            inptValue = values[values.length - 1]
            values = inptValue.split("?");
            inptValue = values[0]
        }
        setValue(inptValue);

        let validators: InputValidator[] = [];
        if (config.required) {
            validators.push(REQUIRED_VALIDATOR);
        }

        var valid = true, message = "";
        for (let validator of validators) {
            if (!validator.isValid(targetValue)) {
                valid = false;
                message = validator.getMessage();
                break;
            }
        }

        validation.valid = valid;
        setValidation(validation);
        setError(message);

        if (typeof config.onValueChanged === "function") {
            config.onValueChanged(config.name, targetValue, validation);
        }
    };

    return (
        <Grid container justify={config.description ? "flex-start" : "flex-end"} alignItems={config.description ? "flex-start" : "flex-end"} spacing={0} className="FilePicker">
            <Grid item xs={12}>
                <TextField
                    id={config.id}
                    value={value}
                    label={config.label}
                    variant={config.variant || "outlined"}
                    margin={config.margin ?? "normal"}
                    required={config.required}
                    disabled={config.disabled || config.readOnly}
                    error={blured && !validation.valid}
                    helperText={blured ? error : ""}
                    onClick={openFile}
                    fullWidth
                    onChange={handleUpload}
                    InputProps={{
                        readOnly: config.readOnly || false,
                        startAdornment: config.startAdornment,
                        endAdornment: (
                            config.readOnly ? undefined : (<InputAdornment position="start">
                                <IconButton disabled={uploading}>
                                    {uploading && (<Progress />)}
                                    {!uploading && (
                                        <Tooltip title={config.tooltip ? config.tooltip : translate("expedients.fields.upload") as string}>
                                            <AttachFileIcon />
                                        </Tooltip>
                                    )}
                                </IconButton>
                            </InputAdornment>)
                        )
                    }}
                />
                <input type="file"
                    disabled={config.disabled || config.readOnly}
                    onChange={handleUpload}
                    ref={fileInput}
                    accept={config.acceptExtension}
                    onBlur={inputBlured}
                    required={config.required}
                    style={{ display: "none" }}
                />
            </Grid>
            {(config.description || config.linkAuxFile) &&
                <Grid item xs={config.value ? true : 12}>
                    <Box px={2}>
                        <small>{config.description ? config.description : ""} </small>
                        {config.linkAuxFile &&
                            <a href={config.linkAuxFile} target="_blank" rel="noopener noreferrer">
                                <small>{config.nameAuxFile} </small>
                            </a>
                        }
                    </Box>
                </Grid>
            }
            {config.value &&
                <Grid item xs="auto">
                    <Grid container justify="flex-end" alignItems="flex-end" >
                        <a href={config.value} target="_blank" rel="noopener noreferrer">
                            <small>{translate("buttons.view_document")}</small>
                        </a>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}