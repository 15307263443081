import { callAPI, HTTPMethod } from "./API";
import { CustomerDocumentLogs, CustomerDocumentLog, CustomerDocumentLogsQueryParams, CustomerDocumentLogRequest } from "../model/CustomerDocumentLog";

const basePath = (tenantId: string) => `/tenants/${tenantId}/customer-document-log`;

export async function listCustomerDocumentLogs(tenantId: string, page: number, offset: number, params: CustomerDocumentLogsQueryParams): Promise<CustomerDocumentLogs> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createCustomerDocumentLog(tenantId: string, request: CustomerDocumentLogRequest): Promise<CustomerDocumentLog> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getCustomerDocumentLog(tenantId: string, id: string): Promise<CustomerDocumentLog> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateCustomerDocumentLog(tenantId: string, id: string, request: CustomerDocumentLogRequest): Promise<CustomerDocumentLog> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteCustomerDocumentLog(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function listPendingToSendCustomerDocumentLogs(tenantId: string, page: number, offset: number, params: CustomerDocumentLogsQueryParams): Promise<CustomerDocumentLogs> {
    return await callAPI(`${basePath(tenantId)}/pending-send`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}