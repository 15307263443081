import React, { useState, useEffect, useContext, Consumer } from "react";
import { AppContext } from "../context/AppContext";
import { ProviderExpedient, ProviderExpedientSegment, ProviderExpedientField, ProviderExpedientBankAccount, ProviderExpedientContact, ProviderExpedientAddress } from "../model/ProviderExpedient";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Divider, Grid, Button, Icon, IconButton, Typography } from "@material-ui/core";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Gridable from "../components/Gridable";
import { FieldStatus, TenantSegment } from "../model/TenantFieldCatalog";
import LaunchIcon from '@material-ui/icons/Launch';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import DateFormat from "../components/DateFormat";
import { getFieldUrl, completeExpedient, updateExpedient } from "../api/ProviderExpedientAPI";
import ValidatedInput from "../components/ValidatedInput";
import { ProviderExpedientStatusesRequest, ProviderExpedientStatusRequest } from "../model/ProviderExpedient";
import { bankAccountDetails } from "../expedients/ExpedientProviderBankAccounts";
import ConfirmPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";

interface ProviderRejectPopupProps {
    tenantId: string;
    providerId: string;
    providerName: string;
    providerRfc: string;
    providerExternalId?: string;
    metadata: ProviderExpedient;
    onSuccess(message: string): any;
    onClose(): any;
}

export default function ProviderRejectPopup(props: ProviderRejectPopupProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [error, setError] = useState<string>();
    const [processing, setProcessing] = useState<boolean>(false);
    const [openConfirmReject, setOpenConfirmReject] = useState<boolean>();
    const [endProcess, setEndProcess] = useState<boolean>(false);
    const [dataFields, setDataFields] = useState<ProviderExpedientField[]>();
    const [dataContacts, setDataContacs] = useState<ProviderExpedientContact[]>();
    const [dataAddress, setDataAddress] = useState<ProviderExpedientAddress[]>();
    const [dataBanks, setDataBanks] = useState<ProviderExpedientBankAccount[]>();
    const [statuses, setStatuses] = useState<string[]>(["NOT_ACCEPTED"]);
    const [statusesLabel, setStatusesLabel] = useState<string[]>([translate('expedients.fields.status.NOT_ACCEPTED') as string]);
    const [request, setRequest] = useState<ProviderExpedientStatusesRequest>({
        fields: [],
        contacts: [],
        addresses: [],
        bank_accounts: []
    } as ProviderExpedientStatusesRequest);

    useEffect(() => {
        setStatus("loading");
        let data = {
            fields: createStatusRequest(props.metadata.fields),
            contacts: createStatusRequest(props.metadata.contacts),
            addresses: createStatusRequest(props.metadata.addresses),
            bank_accounts: createStatusRequest(props.metadata.bank_accounts)
        } as ProviderExpedientStatusesRequest;

        setRequest(data);

        let itemsFields = JSON.parse(JSON.stringify(Object.values(props.metadata.fields))) as ProviderExpedientField[];
        let itemsContacts = JSON.parse(JSON.stringify(Object.values(props.metadata.contacts))) as ProviderExpedientContact[];
        let itemsAddress = JSON.parse(JSON.stringify(Object.values(props.metadata.addresses))) as ProviderExpedientAddress[];
        let itemsBanks = JSON.parse(JSON.stringify(Object.values(props.metadata.bank_accounts))) as ProviderExpedientBankAccount[];
        setDataFields(filterSegmentFields(itemsFields));
        setDataContacs(filterSegmentFields(itemsContacts));
        setDataAddress(filterSegmentFields(itemsAddress));
        setDataBanks(filterSegmentFields(itemsBanks));
        setStatus("loaded");
    }, [props.providerId, props.tenantId, props.metadata]);

    const filterSegmentFields = (segmentFields: ProviderExpedientSegment<any, any>[]) => {
        return segmentFields.filter(f => f.value && f.configuration.privado ? f.configuration.privado === false : true);
    }

    const onPromess = (request: ProviderExpedientStatusesRequest) => {
        if(endProcess){
            return completeExpedient(props.tenantId, props.providerId, request, true);
        }
        return updateExpedient(props.tenantId, props.providerId, request);
    }

    const onCancelReject = () => {
        setOpenConfirmReject(false);
    }

    const onConfirmReject = () => {
        setOpenConfirmReject(false);
        doPromess(translate("providers.validate.finished", { "provider": props.providerName }) as string);
    }

    const doPromess = (message: string | undefined) => {
        setProcessing(true);
        onPromess(request).then((response) => {
            props.onSuccess(getMessage(message));
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setProcessing(false);
        });
    }

    const getMessage = (message: string | undefined) => {
        if(message){
            return message;
        }
        if(endProcess){
            return translate("providers.validate.finished", { "provider": props.providerName }) as string;
        }
        return translate("providers.validate.saved", { "provider": props.providerName }) as string;
    }


    const onReject = () => {
        if(validateAllSegmentFieldsEmpty()){
            setError(translate('providers.validate.reject_empty') as string);
            return;
        }
        setOpenConfirmReject(true);
    }

    const createStatusRequest = (data: Map<string, ProviderExpedientSegment<any, any>>) => {
        var values = Object.values(data) as ProviderExpedientSegment<any, any>[];
        values = values.filter(f => (f.configuration.privado === undefined || f.configuration.privado === false) && f.value);
        return values.map((item) => {
            let required = item.configuration.required || !!item.value;
            return {
                field_id: item.configuration.id,
                status: item.status,
                required: required,
                comments: item.comments,
                privado: item.configuration.privado,

            } as ProviderExpedientStatusRequest;

        });
    };

    const buildNameField = (name: string, required: boolean) => {
        return (<Grid>
            <Typography variant="body2">
                {name}
                {required ? <span style={{ color: "#F44336" }} >*</span> : ""}
            </Typography>
        </Grid>
        );
    }

    const buildDataField = (current: ProviderExpedientField) => {
        return (
            current.configuration.type === "FILE" && !!current.value ?
                <DownloadFileButton
                    tenantId={props.tenantId}
                    providerId={props.providerId}
                    fieldId={current.configuration.id}
                    value={current.value!} />
                : current.configuration.type === "FILE" && !current.value ?
                    <Icon color="disabled"> <LaunchIcon /></Icon>
                    : current.configuration.type === "DATE" && !!current.value ?
                        <DateFormat date={new Date(current.value)} format="L" />
                        : !!current.value ? current.value
                            : "-");
    }

    const buildDataContact = (current: ProviderExpedientContact) => {
        let contact_p1 = "";
        let contact_p2 = "";
        return (
            current.value ?
                <Grid>
                    <Typography variant="body2">{
                        contact_p1.concat(
                            current.value.first_name ? current.value.first_name : "", " ",
                            current.value.last_name ? current.value.last_name : "")
                    }
                    </Typography>
                    <Typography variant="caption">{
                        contact_p2.concat(
                            current.value.email ? current.value.email : "", " - ",
                            current.value.phone ? current.value.phone : "")
                    }
                    </Typography>
                </Grid>
                : undefined);
    }

    const buildDataAddress = (current: ProviderExpedientAddress) => {
        let addresses = "";
        return (
            current.value ?
                <Grid>
                    <Typography variant="body2">
                        {addresses.concat(
                            current.value.street ? current.value.street : "", " ",
                            current.value.exterior_number ? current.value.exterior_number : "", " ",
                            current.value.interior_number ? current.value.interior_number : "", " ",
                            current.value.suburb ? current.value.suburb : "", " ",
                            current.value.city ? current.value.city : "", " ",
                            current.value.state ? current.value.state : "", " ",
                            current.value.zip_code ? current.value.zip_code : ""
                        )}</Typography>
                </Grid>
                : undefined);
    }

    const onInputChangeFields = (name: string, value: string) => {
        let id = name.split("-")[0];
        let type = name.split("-")[1];
        let fieldFound = request.fields.find(field => field.field_id === id);
        if (!fieldFound) return;
        let fields = [] as any;
        request.fields.forEach(field => {
            if (field.field_id === id) {
                if (type === "comments") {
                    field.comments = value;
                } else {
                    setEndProcessByRequest(value, id);
                    field.status = value === "---" ? "" : value;
                }

            }
            fields.push(field);
        });

        setRequest({ ...request, fields: fields });
    };

    const setEndProcessByRequest = (value: string, id: string) => {
        let fieldsNotEmpty = validateAllNotEmpty(request.fields.filter(field => field.field_id != id));
        let addressesNotEmpty = validateAllNotEmpty(request.addresses.filter(field => field.field_id != id));
        let bankAccountNotEmpty = validateAllNotEmpty(request.bank_accounts.filter(field => field.field_id != id));
        let contactsNotEmpty = validateAllNotEmpty(request.contacts.filter(field => field.field_id != id));
        setEndProcess(value !== "" && value !== "---" && fieldsNotEmpty && addressesNotEmpty && bankAccountNotEmpty && contactsNotEmpty);
    }

    const validateAllNotEmpty = (fields: ProviderExpedientStatusRequest[]) => {
        return fields.every(field => field.status != undefined && field.status !== "" && field.status !== "---" && field.status === "NOT_ACCEPTED");
    }

    const validateAllEmpty = (fields: ProviderExpedientStatusRequest[]) => {
        return fields.every(field => field.status == undefined || field.status == "" || field.status == "---" || field.status == "ACCEPTED");
    }

    const onInputChangeAddresses = (name: string, value: string) => {
        let id = name.split("-")[0];
        let type = name.split("-")[1];
        let fieldFound = request.addresses.find(field => field.field_id === id);
        if (!fieldFound) return;
        let fields = [] as any;
        request.addresses.forEach(field => {
            if (field.field_id === id) {
                if (type === "comments") {
                    field.comments = value;
                } else {
                    setEndProcessByRequest(value, id);
                    field.status = value === "---" ? "" : value;
                }

            }
            fields.push(field);
        });

        setRequest({ ...request, addresses: fields });
    };

    const onInputChangeBank = (name: string, value: string) => {
        let id = name.split("-")[0];
        let type = name.split("-")[1];
        let fieldFound = request.bank_accounts.find(field => field.field_id === id);
        if (!fieldFound) return;
        let fields = [] as any;
        request.bank_accounts.forEach(field => {
            if (field.field_id === id) {
                if (type === "comments") {
                    field.comments = value;
                } else {
                    setEndProcessByRequest(value, id);
                    field.status = value === "---" ? "" : value;
                }

            }
            fields.push(field);
        });

        setRequest({ ...request, bank_accounts: fields });
    };

    const onInputChangeContacts = (name: string, value: string) => {
        let id = name.split("-")[0];
        let type = name.split("-")[1];
        let fieldFound = request.contacts.find(field => field.field_id === id);
        if (!fieldFound) return;
        let fields = [] as any;
        request.contacts.forEach(field => {
            if (field.field_id === id) {
                if (type === "comments") {
                    field.comments = value;
                } else {
                    setEndProcessByRequest(value, id);
                    field.status = value === "---" ? "" : value;
                }

            }
            fields.push(field);
        });

        setRequest({ ...request, contacts: fields });
    };

    
    const rejectAll = () => {
        setEndProcess(true);
        if (dataFields) {
            let arr: ProviderExpedientField[] = [];
            dataFields.forEach(field => {
                field.status = 'NOT_ACCEPTED';
                arr.push(field);
            });
            setDataFields(arr);
        }
        if (dataAddress) {
            let arr: ProviderExpedientAddress[] = [];
            dataAddress.forEach(address => {
                address.status = 'NOT_ACCEPTED';
                arr.push(address);
            });
            setDataAddress(dataAddress);
        }
        if (dataBanks) {
            let arr: ProviderExpedientBankAccount[] = [];
            dataBanks.forEach(ba => {
                ba.status = 'NOT_ACCEPTED';
                arr.push(ba);
            });
            setDataBanks(arr);
        }
        if (dataContacts) {
            let arr: ProviderExpedientContact[] = [];
            dataContacts.forEach(contact => {
                contact.status = 'NOT_ACCEPTED';
                arr.push(contact);
            });
            setDataContacs(arr);
        }
    }

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    const validateAllSegmentFieldsEmpty = () => {
        let fieldsNEmpty = validateAllEmpty(request.fields);
        let addressesEmpty = validateAllEmpty(request.addresses);
        let bankAccountEmpty = validateAllEmpty(request.bank_accounts);
        let contactsEmpty = validateAllEmpty(request.contacts);
        return fieldsNEmpty && addressesEmpty && bankAccountEmpty && contactsEmpty;
    }

    return (
        <DialogPopup open
            maxWidth="lg"
            title={translate("providers.validate.validate_expedient")}
            onClose={props.onClose}
            button={(
                <Button onClick={onReject} variant="contained" color="primary" size="medium" disabled={status === "loading"}>
                    {endProcess ? translate("providers.validate.end_process") : translate("buttons.save")}
                </Button>
            )}>

            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && (
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={9}>
                                <Typography variant="body2">
                                    {translate("providers.validate.reject_caption") as string}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <IconButton color="primary" size="small" onClick={rejectAll}>
                                            <DoneTwoToneIcon fontSize="small" />
                                        </IconButton>
                                        <Button aria-label="add" color="primary" size="small" onClick={rejectAll} >
                                            <Typography variant="body2">{translate("providers.validate.reject_all")}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {`${translate("providers.validate.provider") as string }: ${props.providerName} - ${props.providerRfc} ${props.providerExternalId ? `- ${props.providerExternalId}`: ""}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <Gridable
                            items={dataFields || []}
                            loading={false}
                            empty={translate(`providers.validate.empty`) as string}
                            columns={[
                                {
                                    title: translate("providers.validate.field_name") as string,
                                    converter: (data) => (
                                        buildNameField(data.configuration.name, data.configuration.required)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.data") as string,
                                    converter: (data) => (
                                        buildDataField(data)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.comments") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-comments`}
                                            name={`${data.configuration.id}-comments`}
                                            label={translate("providers.validate.comments") as string}
                                            maxRows={10}
                                            rows={1}
                                            value={data.comments}
                                            onValueChanged={onInputChangeFields}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.status") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-status`}
                                            name={`${data.configuration.id}-status`}
                                            value={data.status}
                                            required={data.configuration.required}
                                            label={translate("expedients.fields.status.title") as string}
                                            options={statuses}
                                            optionLabels={statusesLabel}
                                            onValueChanged={onInputChangeFields}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                }
                            ]}
                        />
                        <Divider /><Divider />
                        <Gridable
                            items={dataContacts || []}
                            loading={false}
                            empty={translate(`providers.validate.empty`) as string}
                            columns={[
                                {
                                    title: translate("providers.validate.contact") as string,
                                    converter: (data) => (
                                        buildNameField(data.configuration.name, data.configuration.required)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.data") as string,
                                    converter: (data) => (
                                        buildDataContact(data)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.comments") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-comments`}
                                            name={`${data.configuration.id}-comments`}
                                            label={translate("providers.validate.comments") as string}
                                            maxRows={10}
                                            rows={1}
                                            value={data.comments}
                                            onValueChanged={onInputChangeContacts}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.status") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-status`}
                                            name={`${data.configuration.id}-status`}
                                            value={data.status}
                                            required={data.configuration.required}
                                            label={translate("expedients.fields.status.title") as string}
                                            options={statuses}
                                            optionLabels={statusesLabel}
                                            onValueChanged={onInputChangeContacts}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                }
                            ]}
                        />
                        <Divider /><Divider />
                        <Gridable
                            items={dataAddress || []}
                            loading={false}
                            empty={translate(`providers.validate.empty`) as string}
                            columns={[
                                {
                                    title: translate("providers.validate.address") as string,
                                    converter: (data) => (
                                        buildNameField(data.configuration.name, data.configuration.required)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.data") as string,
                                    converter: (data) => (
                                        buildDataAddress(data)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.comments") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-comments`}
                                            name={`${data.configuration.id}-comments`}
                                            label={translate("providers.validate.comments") as string}
                                            maxRows={10}
                                            rows={1}
                                            value={data.comments}
                                            onValueChanged={onInputChangeAddresses}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.status") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-status`}
                                            name={`${data.configuration.id}-status`}
                                            value={data.status}
                                            required={data.configuration.required}
                                            label={translate("expedients.fields.status.title") as string}
                                            options={statuses}
                                            optionLabels={statusesLabel}
                                            onValueChanged={onInputChangeAddresses}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                }
                            ]}
                        />
                        <Divider /><Divider />
                        <Gridable
                            items={dataBanks || []}
                            loading={false}
                            empty={translate(`providers.validate.empty`) as string}
                            columns={[
                                {
                                    title: translate("providers.validate.bank") as string,
                                    converter: (data) => (
                                        buildNameField(data.configuration.name, data.configuration.required)
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.data") as string,
                                    converter: (data) => (
                                        data.value ?
                                        bankAccountDetails(
                                            data.value,
                                            context.session!.tenant!.id,
                                            props.providerId,
                                            data.configuration.id,
                                            data.value.cover
                                        )
                                        : undefined
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.comments") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-comments`}
                                            name={`${data.configuration.id}-comments`}
                                            label={translate("providers.validate.comments") as string}
                                            maxRows={10}
                                            rows={1}
                                            value={data.comments}
                                            onValueChanged={onInputChangeBank}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                },
                                {
                                    title: translate("providers.validate.status") as string,
                                    converter: (data) => (
                                        <ValidatedInput
                                            margin="dense"
                                            type="text"
                                            id={`${data.configuration.id}-status`}
                                            name={`${data.configuration.id}-status`}
                                            value={data.status}
                                            required={data.configuration.required}
                                            label={translate("expedients.fields.status.title") as string}
                                            options={statuses}
                                            optionLabels={statusesLabel}
                                            onValueChanged={onInputChangeBank}
                                        />
                                    ),
                                    fullWidth: true,
                                    xs: 3
                                }
                            ]}
                        />
                    </Grid>
                </Grid>

            )}
            {openConfirmReject && 
                <ConfirmPopup
                    doAction={onConfirmReject}
                    onClose={onCancelReject}
                    title={translate("expedients.confirm_reject.title") as string}
                    message={translate("expedients.confirm_reject.message") as string} 
                    secondary={translate("expedients.confirm_reject.secondary") as string} 
                    button={translate("buttons.accept") as string} />
            }
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            {status !== "loading" && status !== "loaded" && (
                <Typography variant="body2" component="h5" color="error" align="center">
                    {status}
                </Typography>
            )}
            <CustomBackdrop open={processing} message={translate("cfdis.processing") as string} />
        </DialogPopup>
    );

}

interface DownloadFileButtonProps {
    tenantId: string;
    providerId: string;
    fieldId: string;
    value: string;
}

function DownloadFileButton(props: DownloadFileButtonProps) {
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (props.value.startsWith("http")) {
            setUrl(props.value);
        } else {
            getFieldUrl(props.tenantId, props.providerId, props.fieldId).then((url) => {
                setUrl(url);
            }).catch((error) => {
            });
        }
    }, [props.tenantId, props.providerId, props.fieldId, props.value]);

    return (
        <IconButton
            aria-label="options"
            color="default"
            size="small"
            component="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            disabled={!url}
        >
            <LaunchIcon />
        </IconButton>
    );
}
