import React, { useState } from "react";
import Dropzone from 'react-dropzone';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import AttachIcon from "@material-ui/icons/AttachFile";

import translate from "../i18n/Translator";
import { upload } from '../api/S3UploaderApi';


interface CfdiChangePdfProps {
    xmlName: string;
    onChange(pdfFile: File): any;
    onClose(): any;
}

export default function CfdiChangePdf(props: CfdiChangePdfProps) {

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState("");

    const [pdfFile, setPdf] = useState<File>();
    const [pdfLocation, setPdfLocation] = useState<string>();
    const pdfLabel = translate("cfdis.documents.files.replace_message") as string;
    const [uploadPdfLabel, setPdfLabel] = useState<string>(pdfLabel);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (!pdfFile) {
            setWarning(translate("cfdis.pdf_required") as string);
            return;
        }
        props.onChange(pdfFile);
        setSubmitting(true);

    };

    const onDropPdfZone = (acceptedFiles: File[]) => {
        setPdfLocation(undefined);
        if (acceptedFiles.length) {
            onDropCfdiPdf(acceptedFiles[0]);
        } else {
            setError(translate("cfdis.invalid_file", { "type": "pdf" }) as string);
        }
    }

    const validatePdfName = (pdf: File, xmlName?: string) => {
        if (xmlName && pdf.name.substring(0, pdf.name.indexOf(".")) !== xmlName.substring(0, xmlName.indexOf("."))) {
            setWarning(translate("cfdis.file_names_not_equals") as string);
        }
    }

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const onDropCfdiPdf = (pdf: File) => {
        setPdf(pdf);
        setPdfLabel(`${pdf.name} - ${pdf.size} bytes`);
        validatePdfName(pdf, props.xmlName);
        uploadPdfFile(pdf);
    }

    const uploadPdfFile = (file: File) => {
        upload(`${process.env.REACT_APP_AWS_S3_DIR}/temp/${new Date().getTime()}` , file, {
            onError: (error) => {
                setError(error);
            },
            onSuccessUpload: (location) => {
                setPdfLocation(location);
            },
            onFinally: () => {}
        }, true);
    }

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("cfdis.documents.files.replace_title") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container justify="space-between" alignItems="center" spacing={3}>
                        <Grid item xs={12}>
                            <Dropzone onDrop={onDropPdfZone} accept={["application/pdf", ".pdf"]} disabled={submitting}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} style={{ height: 50, borderWidth: 1, borderColor: "lightgray", borderStyle: "dashed", paddingLeft: 10, "opacity": "60%", paddingTop: "2%" }}>
                                        <input {...getInputProps()} />
                                        <p><Grid container xs={12} alignItems="center"><Grid item xs={11}>{uploadPdfLabel}</Grid> <Grid item xs={1}><AttachIcon/></Grid></Grid></p>
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>
                        {pdfLocation && (
                            <Grid item xs="auto">
                                <a href={pdfLocation} target="_blank" rel="noopener noreferrer">
                                    <small>{translate("buttons.view_document")}</small>
                                </a>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" size="large" disabled={submitting} onClick={props.onClose}>
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                        {translate("buttons.replace")}
                    </Button>
                </DialogActions>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Dialog>
    );

}
