import { callAPI, HTTPMethod, removeJWT } from "./API";
import { SignInRequest, SignInResponse, SignInIntentResponse, OauthSignInRequest } from "../model/SignIn";
import { SetupPasswordRequest, SignUpMetadataResponse, SignUpResponse } from "../model/SignUp";
import { RecoverPasswordRequest, RecoverPasswordResponse } from "../model/RecoverPassword";
import { DoubleFactorAuthenticationRequest, User } from "../model/User";
import { TenantIntentResponse, TenantIntentRequest } from "../model/TenantIntent";
import { ResetPasswordRequest } from "../model/ResetPassword";

export async function signup(request: TenantIntentRequest): Promise<SignUpResponse> {
    return await callAPI("/auth", {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getSignup(): Promise<SignUpMetadataResponse> {
    return await callAPI("/auth", {
        method: HTTPMethod.GET,
    });
}

export async function getTenantIntent(code: string): Promise<TenantIntentResponse> {
    return await callAPI(`/auth/${code}`, {
        method: HTTPMethod.GET,
    });
}

export async function getSignupConfirmation(code: string): Promise<TenantIntentResponse> {
    return await callAPI(`/auth/confirm/${code}`, {
        method: HTTPMethod.GET,
    });
}

export async function confirmSignup(code: string, request: SetupPasswordRequest): Promise<SignInResponse> {
    return await callAPI(`/auth/confirm/${code}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function signin(request: SignInRequest): Promise<SignInResponse> {
    removeJWT();
    return await callAPI("/auth/signin", {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function oauthSignin(request: OauthSignInRequest): Promise<SignInResponse> {
    removeJWT();
    return await callAPI("/auth/signin/oauth", {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getSignin2FA(code: string): Promise<SignInIntentResponse> {
    return await callAPI(`/auth/signin/${code}`, {
        method: HTTPMethod.GET
    });
}

export async function completeSignin2FA(code: string, request: DoubleFactorAuthenticationRequest): Promise<SignInIntentResponse> {
    return await callAPI(`/auth/signin/${code}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function logout(): Promise<void> {
    try {
        await callAPI("/auth/logout", {
            method: HTTPMethod.POST,
        }, true);
        removeJWT();
    } catch (error) {
        throw error;
    }
}

export async function getSignupUser(code: string): Promise<User> {
    return await callAPI(`/auth/users/${code}`, {
        method: HTTPMethod.GET
    });
}

export async function signupUser(code: string, request: SetupPasswordRequest): Promise<User> {
    return await callAPI(`/auth/users/${code}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getSetupPassword(code: string): Promise<User> {
    return await callAPI(`/auth/password/${code}`, {
        method: HTTPMethod.GET
    });
}

export async function setupPassword(code: string, request: SetupPasswordRequest): Promise<User> {
    return await callAPI(`/auth/password/${code}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function recoverPassword(request: RecoverPasswordRequest): Promise<RecoverPasswordResponse> {
    return await callAPI("/auth/password", {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function getVerifyEmail(code: string): Promise<User> {
    return await callAPI(`/auth/email/${code}`, {
        method: HTTPMethod.GET
    });
}

export async function resetPassword(code: string, request: ResetPasswordRequest): Promise<RecoverPasswordResponse> {
    return await callAPI(`/auth/reset-password/${code}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function recoverPasswordExpired(request: RecoverPasswordRequest): Promise<RecoverPasswordResponse> {
    return await callAPI(`/auth/password/expired`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function validateUserStatus(code: string): Promise<User> {
    return await callAPI(`/auth/validate-status/${code}`, {
        method: HTTPMethod.GET
    });
}