import React, { useState, useEffect, useRef } from "react";
import { TextField, FormControl, FormHelperText, InputLabel, Select } from "@material-ui/core";
import { InputValidator, RequiredValidator, EmailValidator, PhoneValidator, NumberValidator, PasswordValidator, URLValidator } from "./Validators";
import NumberFormat from "react-number-format";
import { Autocomplete } from "@material-ui/lab";
import translate from "../i18n/Translator";

const REQUIRED_VALIDATOR = new RequiredValidator();
const EMAIL_VALIDATOR = new EmailValidator();
const PHONE_VALIDATOR = new PhoneValidator();
const NUMBER_VALIDATOR = new NumberValidator();
const PASSWORD_VALIDATOR = new PasswordValidator();
const URL_VALIDATOR = new URLValidator();

export interface InputRef {
    valid: boolean;
    blurer(blur: boolean): void;
    original_name?: string;
}

interface ValidatedInputConfig<T> {
    type: string;
    id: string;
    name: string;
    label: string;
    maxLength?: number;
    rows?: number;
    maxRows?: number;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    margin?: "normal" | "dense" | "none";
    variant?: "filled" | "outlined" | "standard";
    emptyOption?: string;
    omitEmpty?: boolean;
    options?: string[];
    optionLabels?: string[];
    validator?: InputValidator;
    validators?: InputValidator[];
    startAdornment?: React.ReactNode;
    format?: string;
    mask?: string;
    autocomplete?: string;
    onValueChanged?(name: string, value: string | number, inputRef: InputRef): void;
    isValueNumber?: boolean;
    disableDefaultValidator?: boolean;
    helperText?: string;
    autocompleteOptions?: T[];
    getId?(el: T): string;
    getLabel?(el: T): string;
    autocompleteValue?: T;
    autocompleteKey?: string;
    toValidateAgain?: boolean;
    selectContentOnFocus?: boolean;
    autocompleteDefaultValue?: T;
    errorMessage?: string;
}

export default function ValidatedInput<T>(config: ValidatedInputConfig<T>) {
    const [blured, setBlured] = useState(false);
    const [validation, setValidation] = useState<InputRef>({
        valid: true,
        blurer: setBlured,
    } as InputRef);
    const [error, setError] = useState<string>("");
    const [value, setValue] = useState<string>(config.value || "");
    const [labelWidth, setLabelWidth] = useState(0);
    const selectLabel = useRef(null);

    const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        validate(event.target.value);
    };

    const onFocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
        if (config.selectContentOnFocus) {
            event.target.select();
        }
    }

    const selectChanged = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown; }>, _child: React.ReactNode) => {
        let v = (event.target.value as any).toString();
        if (config.emptyOption && v === config.emptyOption) {
            validate("");
        } else {
            validate(v);
        }
    };

    const inputBlured = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!blured) { setBlured(true); }
    };

    const validate = (targetValue: string) => {
        setValue(targetValue);

        let validators: InputValidator[] = [];
        if (config.required) {
            validators.push(REQUIRED_VALIDATOR);
        }
        if (config.validator) {
            validators.push(config.validator);
        }
        if (config.validators) {
            for (let validator of config.validators) {
                validators.push(validator);
            }
        }

        switch (config.type) {
            case "email":
                validators.push(EMAIL_VALIDATOR);
                break;
            case "tel":
                validators.push(PHONE_VALIDATOR);
                break;
            case "password":
                if (!config.disableDefaultValidator) {
                    validators.push(PASSWORD_VALIDATOR);
                }
                break;
            case "number":
                if (!config.disableDefaultValidator) {
                    validators.push(NUMBER_VALIDATOR);
                }
                break;
            case "url":
                validators.push(URL_VALIDATOR);
                break;
        }

        var valid = true, message = "";
        for (let validator of validators) {
            if (!validator.isValid(targetValue)) {
                valid = false;
                message = validator.getMessage();
                break;
            }
        }

        validation.valid = valid;
        setValidation(validation);
        setError(message);

        if (typeof config.onValueChanged === "function") {
            config.onValueChanged(config.name, config.isValueNumber ? Number(targetValue) : targetValue, validation);
        }

    }

    useEffect(() => {
        validate(config.value || "");
        if (config.options && !!selectLabel) {
            setLabelWidth((selectLabel.current || { offsetWidth: 0, }).offsetWidth || 0);
        }
        // eslint-disable-next-line
    }, [config.value, config.required, config.disabled, config.options, config.errorMessage]);

    useEffect(() => {
        if (config.toValidateAgain) {
            validate(config.value || "");
        }
    }, [config.toValidateAgain!]);

    const commonProperties = () => {
        return {
            id: config.id,
            name: config.name,
            value: config.isValueNumber ? Number(value) : value,
            label: config.label,
            margin: config.margin || "normal",
            variant: config.variant || "outlined",
            fullWidth: true,
            required: config.required,
            error: config.errorMessage ? true : blured && !validation.valid,
            helperText: config.errorMessage ? config.errorMessage : blured ? error : (config.helperText || ""),
            inputProps: {
                maxLength: config.maxLength
            },
            onBlur: inputBlured,
            multiline: config.rows !== undefined,
            rows: config.rows || 1,
            rowsMax: config.maxRows || config.rows,
            disabled: config.disabled,
            InputProps: {
                startAdornment: config.startAdornment
            }
        };
    }

    const onMaskedInputChanged = (values: any) => {
        if (!values) return;
        let fixed = values["value"] || values["floatValue"] || values["formattedValue"];
        validate(fixed);
    };

    if (config.options) {
        return (
            <FormControl margin={config.margin ?? "normal"} variant={config.variant ?? "outlined"} fullWidth required={config.required} error={blured && !validation.valid}>
                <InputLabel ref={selectLabel} htmlFor={config.id}>{config.label}</InputLabel>
                <Select native value={value === "" ? undefined : value} onChange={selectChanged}
                    labelWidth={labelWidth} disabled={config.disabled}
                    inputProps={{
                        name: config.name,
                        id: config.id,
                    }} required={config.required}>
                    {!config.omitEmpty &&
                        <option value={undefined}>{config.emptyOption || "---"}</option>
                    }
                    {config.options.map((value, index) => {
                        return <option key={value} value={value}>{config.optionLabels ? config.optionLabels[index] : value}</option>
                    })}
                </Select>
                {(blured || config.errorMessage) &&
                    <FormHelperText>{error ?? config.errorMessage}</FormHelperText>
                }
            </FormControl>
        );
    }

    if (config.format) {
        return <NumberFormat customInput={TextField} format={config.format} mask={config.mask || "_"} onValueChange={onMaskedInputChanged} {...commonProperties()} />
    }

    if (config.autocompleteOptions && config.getId && config.getLabel) {
        return (
            <Autocomplete
                size="small"
                options={config.autocompleteOptions}
                value={config.autocompleteValue}
                disabled={config.disabled}
                getOptionLabel={config.getLabel}
                noOptionsText={translate("generic_messages.no_options") as string}
                key={config.autocompleteKey}
                defaultValue={config.autocompleteDefaultValue}
                renderInput={(params) => (
                    <TextField {...commonProperties()} {...params} />
                )}
                onChange={(event, newValue) => {
                    if (newValue) {
                        validate(config.getId!(newValue));
                    } else {
                        validate("");
                    }
                }}
                getOptionSelected={(opt, val) => config.getId!(opt) === config.getId!(val)}
            />
        );
    }

    return (
        <TextField type={config.type} onChange={inputChanged} autoComplete={config.autocomplete} onFocus={onFocusInput} {...commonProperties()} />
    );
}

export const isValid = (validations: any, avoidBlur?: boolean) => {
    var valid = true;
    for (let field in validations) {
        let ref = validations[field];
        if (!ref.valid) {
            if (!avoidBlur) {
                ref.blurer(true);
            }
            valid = false;
        }
    }
    return valid;
}