import React from "react";
import { Grid, Box } from "@material-ui/core";
import NumberFormat from 'react-number-format';
import translate from "../i18n/Translator";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import Gridable from "../components/Gridable";
import { Payment } from "../model/Payment";
import ReactHtmlParser from "html-react-parser";

export interface PaymentsTabParams {
    payments?: Payment[];
}

export default function PaymentsTab(props: PaymentsTabParams) {

    return (<div>
            <Box px={2} py={2}>
                <Grid container justify="space-between" alignItems="center" >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <b>{translate(props.payments ? "payment_order.list_payments" : "payment_order.empty_payments")}</b>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {props.payments ? 
                <Gridable
                items={props.payments}
                loading={false}
                columns={[
                    {
                        title: translate("payment.columns.number") as string,
                        converter: (payment) => payment.operation_number,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("payment.columns.provider") as string,
                        converter: (payment) => payment.provider_name ? payment.provider_name : "---", 
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2 
                    },
                    {
                        title: translate("payment.columns.date") as string,
                        converter: (payment) => <DateFormat date={payment.payment_date} format="L" />,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: ReactHtmlParser (translate("payment.columns.deadline") as string),
                        converter: (payment) => <DateFormat date={payment.deadline_payment_cfdi} format="L" />,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("payment.columns.cfdis") as string,
                        converter: (payment) => payment.comprobantes,
                        justify: "flex-start",
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("payment.columns.total") as string,
                        converter: (payment) => (
                            <Grid container justify="space-evenly" alignContent="flex-end" >
                                <Grid item xs={12} >
                                    <NumberFormat value={payment.total_amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text"  thousandSeparator="," />
                                </Grid>
                                <Grid item ><Ellipsis text={payment.currency ? payment.currency : ""} lenght={10} secondary /></Grid>
                            </Grid>
                        ),
                        justify: "center",
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    }
                ]} />
            : undefined}
                </div>
    )
}