import { callAPI, HTTPMethod } from "./API";
import { Users, UsersQueryParams, UserRequest, User, MetadataResponse } from "../model/User";

export async function getUsers(tenantId: string, providerId: string, page: number, offset: number, params: UsersQueryParams): Promise<Users> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/users`, {
        method: HTTPMethod.GET,
        query: {
            roleId: params.role_id || "",
            search: params.search || "",
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getMetadata(tenantId: string, providerId: string): Promise<MetadataResponse> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/users/metadata`, {
        method: HTTPMethod.GET
    });
}

export async function createUser(tenantId: string, providerId: string, request: UserRequest): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/users`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getUser(tenantId: string, providerId: string, userId: string, includeMetadata?: boolean): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/users/${userId}`, {
        method: HTTPMethod.GET,
        query: {
            "include_metadata": `${!!includeMetadata}`,
        }
    });
}

export async function updateUser(tenantId: string, providerId: string, userId: string, request: UserRequest): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/users/${userId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteUser(tenantId: string, providerId: string, userId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/users/${userId}`, {
        method: HTTPMethod.DELETE
    });
}
