import React, { useState, useEffect, useRef, useContext } from "react";
import { Grid, Box, ListItemText, Typography, IconButton, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { formatDate } from "../components/DateFormat";
import translate from "../i18n/Translator";
import Progress from "../components/Progress";
import BudgetLinesList from "./BudgetLinesList";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BudgetLinesMenu from "./BudgetLinesMenu";
import ConnectorTemplateDownloader from "../connectors/ConnectorTemplateDownloader";
import { ImportTwoToneIcon } from "../components/Icons";
import { importBudgetLines, downloadExportBudget, updateLineBudget, syncTemplateLines } from "../api/BudgetApi";
import { Budget, BudgetLine, LineBudgetRequest } from "../model/Budget";
import { Family } from "../model/Connector";
import { AppContext } from "../context/AppContext";
import EditBudgetLines from "./EditBudgetLines";
import { upload } from "../api/S3UploaderApi";

export interface DetailsTabParams {
    tenantId: string;
    budget: Budget;
    isReaderOnly: boolean;
    refresh(successMessage?: string): any;
}

export default function DetailsTab(props: DetailsTabParams) {
    const context = useContext(AppContext);
    const [error, setError] = useState<string>();
    const [budget, setBudget] = useState<Budget>();
    const [status, setStatus] = useState<string>("loading");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [downloadTemplate, setDownloadTemplate] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState(false);
    const [success, setSuccess] = useState<string>();
    const [openEditBudgetLine, setOpenEditBudgetLine] = useState(false);
    const [selectedLine, setSelectedLine] = useState<BudgetLine>({
        team_id: "",
        team_name: "",
        initial_amount: 0,
        variation_amount: 0,
        current_amount: 0,
        accrued_amount: 0,
        exercised_amount: 0,
        pending_accrue_amount: 0,
        pending_exercise_amount: 0,
        variation_status: "IN_BUDGET",
        external_id: "",
        external_type: "",
        description: "",
        ledger_account: ""
    } as BudgetLine);

    const load = () => {
        setStatus("loading");
        setBudget(props.budget);
        setStatus("loaded");
    };

    useEffect(load, [props]);

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
    }
    const onDownloadTemplate = () => {
        setAnchorEl(null);
        setDownloadTemplate(true);
    }

    if (status === "loading" || !budget) {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onClickedMoreLines = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const openFileImport = () => {
        fileInput.current!.click();
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        setUploading(true);
        let file = event.target.files[0];
        let newFile = new File([file], file.name.replace(/\s+/g, ""));
        let newFileName = new Date().getTime() + newFile.name;
        upload(`${process.env.REACT_APP_AWS_S3_DIR}/temp/${newFileName}` , file, {
            onError: (error) => {
                setError(error);
            },
            onSuccessUpload: (location) => {
                importBudgetLines(context.session!.tenant!.id, budget.id, location, newFileName)
                .then(() => {
                    setSuccess(translate("budget.lines.confirm_upload") as string);
                })
                .catch((error) => {
                    setError(error.message);
                }).finally(() => {
                    fileInput.current!.value = "";
                });            },
            onFinally: () => {
                setUploading(false);
            }
        }, true);
    }

    const onExportCurrentBudget = () => {
        setAnchorEl(null);
        downloadExportBudget(context.session!.tenant!.id, budget.id).then((response) => {
            setAnchorEl(null);
            window.open(response.url, "_blank");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onSyncBudgetLines = () => {
        setAnchorEl(null);
        syncTemplateLines(context.session!.tenant!.id, budget.id).then((response) => {
            props.refresh(translate("budget.lines.sync_lines_success", {total: response.lines}) as string);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const onClickRow = (idLine: string) => {
        if(props.isReaderOnly){
            return;
        }
        let selected = budget.budget_lines!.find(bl => bl.external_id === idLine);
        if (selected) {
            setSelectedLine(selected);
            setOpenEditBudgetLine(true);
        }
    }

    const onCloseEdit = () => {
        setOpenEditBudgetLine(false);
    }

    const onEditLine = (line: BudgetLine) => {
        setOpenEditBudgetLine(false);
        let request = {
            external_id: line.external_id,
            team_name: line.team_name,
            team_id: line.team_id,
            amount: budget.execution_status === "IN_EXECUTION" ? line.variation_amount : line.initial_amount
        } as LineBudgetRequest;

        updateLineBudget(context.session!.tenant!.id, budget.id, request).then((response) => {
            props.refresh();
        }).catch((error) => {
            setStatus(error.message);
        });
    }


    return (
        <div>
            <Grid>
                <Box px={2} py={2}>
                    <Grid container justify="space-between" alignItems="center" >
                        <Grid item xs={12}>
                            <Grid container alignItems="center" direction="row" spacing={3}>
                                <Grid item xs={4}>
                                    <ListItemText primary={budget.name} secondary={translate("budget.lists.columns.name")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={budget.company_name} secondary={translate("budget.lists.columns.company")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={formatDate(budget.created_at, "DD/MM/YYYY")} secondary={translate("budget.lists.columns.register_date")} />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" direction="row" spacing={3}>
                                <Grid item xs={4}>
                                    <ListItemText primary={formatDate(budget.init_date, "DD/MM/YYYY")} secondary={translate("budget.lists.columns.init_date")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={formatDate(budget.end_date, "DD/MM/YYYY")} secondary={translate("budget.lists.columns.end_date")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={formatDate(budget.updated_date, "DD/MM/YYYY")} secondary={translate("budget.lists.columns.last_update")} />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" direction="row" spacing={3}>
                                <Grid item xs={4}>
                                    <ListItemText primary={
                                        <NumberFormat color="blue" value={budget.init_amount || 0.0} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    } secondary={translate("budget.lists.columns.initial_amount")} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText primary={
                                        <NumberFormat color="blue" value={budget.current_amount || 0.0} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                    } secondary={translate("budget.lists.columns.current_budget")} />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Grid container alignItems="center" direction="row" justify="space-between" >
                                    <Grid item xs="auto" justify="flex-start">
                                        <Typography variant="subtitle1" component="h6">
                                            {translate("budget.lines.title")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto" justify="flex-end" alignItems="flex-end" alignContent="flex-end" >
                                        {props.budget.execution_status === "PLANNING" && context.isGrantedAny(["EditBudgetLinesBatch"]) && !props.isReaderOnly ?
                                            <Button onClick={openFileImport} color="primary">
                                                <Typography align="right" color="primary" variant="subtitle1" component="h6" >
                                                    <ImportTwoToneIcon color="primary" />
                                                    {translate("budget.lines.import_lines")}
                                                </Typography>
                                            </Button>
                                            : undefined
                                        }
                                        <IconButton color="default" onClick={onClickedMoreLines}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <BudgetLinesList
                                teams={budget.teams}
                                budgetId={budget.id}
                                tenantId={props.tenantId}
                                disableEditLine={props.isReaderOnly || "CLOSED" === budget.execution_status}
                                onClickRow={onClickRow}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                {anchorEl &&
                    <BudgetLinesMenu
                        anchor={anchorEl}
                        onClose={onCloseOption}
                        budget={props.budget}
                        onDownloadTemplate={onDownloadTemplate}
                        onExportCurrentBudget={onExportCurrentBudget}
                        onSyncBudgetLines={onSyncBudgetLines}
                    />
                }
                {downloadTemplate &&
                    <ConnectorTemplateDownloader
                        tenantId={props.tenantId}
                        family={Family.BUDGETS}
                        companyId={props.budget.company_id}
                        onClose={() => setDownloadTemplate(false)} />
                }
                <input
                    type="file"
                    onChange={handleUpload}
                    ref={fileInput}
                    style={{ display: "none" }}
                    accept=".xlsx,.xls,.csv" />

                {openEditBudgetLine && context.isGrantedAny(["EditBudgetLines"]) &&
                    <EditBudgetLines
                        budgetId={props.budget.id}
                        onClose={onCloseEdit}
                        onSubmit={onEditLine}
                        onExecute={budget.execution_status === "IN_EXECUTION"}
                        line={selectedLine}
                        tenantId={props.tenantId}
                    />
                }
            </Grid>
        </div>)
}