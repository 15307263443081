import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { BudgetLine, BudgetSimpleLines, LineBudgetRequest, BudgetsQueryParams, BudgetLines, BudgetLinesListParams, Budgets, BudgetRequest, Budget, BudgetLinesPerformance, SyncTemplateLinesResponse } from "../model/Budget";
import { FileUrlResponse } from "../model/ZipDownload";

const basePath = (tenantId: string) => `/tenants/${tenantId}/budgets`;

export async function updateLineBudget(tenantId: string, budgetId: string, request: LineBudgetRequest): Promise<BudgetLine> {
    return await callAPI(`${basePath(tenantId)}/${budgetId}/update-line`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function listBudgets(tenantId: string, page: number, offset: number, params: BudgetsQueryParams): Promise<Budgets> {
    let query = {
        statuses: params.statuses ? params.statuses : "",
        search: params.search || "",
        view: params.view || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    return await callAPI(`/tenants/${tenantId}/budgets`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function createBudget(tenantId: string, request: BudgetRequest): Promise<Budget> {
    return await callAPI(`/tenants/${tenantId}/budgets`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getBudget(tenantId: string, budgetId: string): Promise<Budget> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}`, {
        method: HTTPMethod.GET,
    });
}

export async function updateBudget(tenantId: string, budgetId: string, request: BudgetRequest): Promise<Budget> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function deleteBudget(tenantId: string, budgetId: string): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function downloadExportBudgetPerformance(tenantId: string, budgetId: string): Promise<FileUrlResponse> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}/download-performance`, {
        method: HTTPMethod.GET
    });
}

export async function downloadExportBudget(tenantId: string, budgetId: string): Promise<FileUrlResponse> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}/download`, {
        method: HTTPMethod.GET
    });
}

export async function syncTemplateLines(tenantId: string, budgetId: string): Promise<SyncTemplateLinesResponse> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}/sync-template-lines`, {
        method: HTTPMethod.PUT
    });
}

export async function updateExecutionStatus(tenantId: string, budgetId: string): Promise<Budget> {
    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}/update-execution-status`, {
        method: HTTPMethod.PUT,
    });

}

export async function listBudgetLines(tenantId: string, budgetId: string, page: number, offset: number, params: BudgetLinesListParams): Promise<BudgetSimpleLines> {
    let query = {
        externalId: params.external_id || "",
        name: params.name || "",
        ledgerAccount: params.ledger_account || "",
        initialAmount: params.initial_amount || "",
        teamName: params.team_name || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}/budget-lines`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function downloadImportTemplate(tenantId: string, connectorId: string, companyId: string): Promise<string> {
    return await getAuthenticatedGetUrl(`${basePath(tenantId)}/download-template`, {
        query: {
            connectorId: connectorId,
            companyId: companyId
        },
    });
}

export async function importBudgetLines(tenantId: string, budgetId: string, url: string, fileName: string): Promise<void> {
    await callAPI(`${basePath(tenantId)}/${budgetId}/import`, {
        method: HTTPMethod.POST,
        body: { 
            url: url,
            file_name: fileName
        }
    });
}

export async function listBudgetLinesPerformance(tenantId: string, budgetId: string, page: number, offset: number, params: BudgetLinesListParams): Promise<BudgetLinesPerformance> {
    let query = {
        externalId: params.external_id || "",
        name: params.name || "",
        ledgerAccount: params.ledger_account || "",
        initialAmount: params.initial_amount || "",
        teamName: params.team_name || "",
        currentAmount: params.current_amount || "",

        accrued: params.accrued || "",
        exercised: params.exercised || "",
        toAccrued: params.to_accrued || "",
        toExercised: params.to_exercised || "",
        variation: params.variation || "",

        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    return await callAPI(`/tenants/${tenantId}/budgets/${budgetId}/lines-performance`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function listBudgetLinesForRequisition(tenantId: string, companyId: string, page: number, offset: number, params: BudgetLinesListParams): Promise<BudgetLines> {
    let query = {
        externalId: params.external_id || "",
        name: params.name || "",
        teamName: params.team_name || "",
        toAccrued: params.to_accrued || "",
        requisitionTypeId: params.requisition_type_id || "",
        pageSize: page.toString(),
        offset: offset.toString(),
    } as Record<string, string>;

    return await callAPI(`/tenants/${tenantId}/budgets/${companyId}/lines-for-requisition`, {
        method: HTTPMethod.GET,
        query: query,
    });
}
