import React, { ReactNode } from "react";
import { Grid, Checkbox, Typography } from "@material-ui/core";
import ValidIcon from "@material-ui/icons/CheckCircleTwoTone";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import FileIcon from '@material-ui/icons/Description';
import DateFormat from "../components/DateFormat";
import CfdiProvideStatusIcon from "../cfdi/CfdiStatusProviderComponent";
import Ellipsis from "../components/Ellipsis";
import { Column, Grouping } from "@devexpress/dx-react-grid";



export interface CfdisListCallbacks {
    onClickedOptions(cfdi: Cfdi): any;
    handleCheck(cfdi: Cfdi): any;
    handleAllChecks(checked: boolean): any;
}

export interface CfdisListProps {
    title: string;
    success?: string;
    columns: Column[];
    clickRowColumns?: string[];
    defaultExpandedGroups?: string[];
    currencyColumns?: string[];
    dateColumns?: string[];
    textColumns?: string[];
    columnsFormat: any[];
    leftColumns?: any[];
    rightColumns?: any[];
    customPlugins?: JSX.Element[];
    grouping?: Grouping[];
    tableCell?(props: any): any;
    checkCell?(props: any, header: boolean): any;
}

interface CfdiNameColumnTitleProps {
    total: number;
    selected: number;
    showChecks: boolean;
    callbacks: CfdisListCallbacks;
    showProvider?: boolean;
    title?: ReactNode;
}

export function CfdiNameColumnTitle(props: CfdiNameColumnTitleProps) {

    const handleAllCheckboxs = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        props.callbacks.handleAllChecks(checked);
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto">
                        <Checkbox
                            id="cfdis_all"
                            name="cfdis_all"
                            checked={props.selected > 0 && props.selected === props.total}
                            indeterminate={props.selected > 0 && props.selected < props.total}
                            onChange={handleAllCheckboxs}
                            disabled={props.total === 0}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                {props.showProvider && (
                    <Grid item xs>
                        {props.title ? props.title : (<strong>{translate("cfdis.columns.provider")}</strong>)}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

interface CfdiNameColumnValueProps {
    cfdi: Cfdi;
    checked: boolean;
    showChecks: boolean;
    callbacks: CfdisListCallbacks;
    showProvider?: boolean;
    content?(cfdi: Cfdi): ReactNode;
}

export function CfdiNameColumnValue(props: CfdiNameColumnValueProps) {

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.callbacks.handleCheck(props.cfdi);
    };

    const onClickCheck = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                {props.showChecks && (
                    <Grid item xs="auto" onClick={onClickCheck(props.cfdi)}>
                        <Checkbox
                            id={props.cfdi.id}
                            name={props.cfdi.id}
                            value={props.cfdi.id}
                            checked={props.checked}
                            onChange={handleSelection}
                            className="GridableCheck"
                        />
                    </Grid>
                )}
                {props.showProvider && (
                    <Grid item xs>
                        {props.content ? props.content(props.cfdi) : (props.cfdi.issuer.name)}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

interface CfdiTotalColumnProps {
    cfdi: Cfdi;
    hideIcon?:boolean;
}

export function CfdiTotalColumn(props: CfdiTotalColumnProps) {
    if (props.cfdi.metadata.payment_info !== undefined && props.cfdi.metadata.payment_info.credit_notes !== undefined) {
        return (
            <Grid container justify="space-evenly" alignContent="flex-end" direction="column" >
                <Grid item style={{ "opacity": 0.5 }}>
                { ( !props?.hideIcon) && <ValidIcon style={{ "color": "#00FFFF00", "width": 16, "height": 16 }} />}
                    <NumberFormat value={props.cfdi.total} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                </Grid>
                <Grid item style={{ "color": "#ff8000" }} >
                    {props.cfdi.metadata.payment_info.credit_notes.map((note) => (
                        <div style={{ textAlign: 'right' }}>
                            <FileIcon style={{ "color": "#ff8000", "width": 16, "height": 16 }} />
                            <NumberFormat value={note.amount} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                        </div>
                    ))}

                </Grid>

                <Grid item  >
                { ( !props?.hideIcon) &&
                    <ValidIcon style={{ "width": 16, "height": 16 }} />}
                    <NumberFormat value={props.cfdi.metadata.balance} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justify="space-evenly" direction="column" alignContent="flex-end" spacing={1}>
            <Grid item xs>
               { ( !props?.hideIcon)  && <ValidIcon style={{ "width": 16, "height": 16 }} />}
                <NumberFormat  value={props.cfdi.total} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
            </Grid>
        </Grid>
    );
}

interface CfdiInfoColumValueProps {
    cfdi: Cfdi;
}

export function CfdiInfoColumnValue(props: CfdiInfoColumValueProps) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <strong><CfdiProvideStatusIcon cfdi={props.cfdi} lenght={15} /> </strong>
            </Grid>
            <Grid item xs={12}>
                <Ellipsis text={props.cfdi.description} lenght={60} uppercased={false} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" component="h6" align="left">{translate("cfdis.columns.reception")} <DateFormat date={props.cfdi.metadata.send_at} format="L" /></Typography>

            </Grid>
        </Grid>
    );
}

interface CfdiAuthorizerColumnValueProps {
    cfdi: Cfdi;
}

export function CfdiAuthorizerColumnValue(props: CfdiAuthorizerColumnValueProps) {
    const getAuthorizer = (cfdi: Cfdi) => {
        switch (cfdi.metadata.cfdi_status_group) {
            case "SENDING":
                return "---";
            case "IN_PROCESS":
                return cfdi.metadata.receptors_names;
            default:
                if (cfdi.metadata.type === "PAYMENT_CFDI") {
                    return "---";
                }
                return cfdi.metadata.authorizer_name;
        }
    }
    let name = getAuthorizer(props.cfdi);
    return (
        <Grid container>
            <Grid item xs={12}>
                {name}
            </Grid>
            {props.cfdi.metadata.cfdi_status_group !== "IN_PROCESS" ?
                <Grid item xs={12}>
                    <Typography variant="caption" component="h6" align="left">{translate("cfdis.columns.authorization")} <DateFormat date={props.cfdi.metadata.authorized_at} format="L" /></Typography>

                </Grid> : undefined
            }
        </Grid>
    );
}


interface CfdiAmountsColumnProps {
    cfdi: Cfdi;
    isAdmin: boolean;
}

export function CfdiAmountsColumn(props: CfdiAmountsColumnProps) {

    const sumTotalsPaymentCfdi = (cfdi: Cfdi) => {
        let totals = {} as any;

        if (cfdi.cfdi && cfdi.cfdi.complementos && cfdi.cfdi.complementos.pagos) {
            cfdi.cfdi.complementos.pagos.pagos.forEach(pago => {
                if (!totals[pago.moneda]) {
                    totals[pago.moneda] = 0;
                }
                totals[pago.moneda] += pago.monto;
            });
        } else if (cfdi.cfdi && cfdi.cfdi.complementos && cfdi.cfdi.complementos.pagos20) {
            cfdi.cfdi.complementos.pagos20.pagos.forEach(pago => {
                if (!totals[pago.moneda]) {
                    totals[pago.moneda] = 0;
                }
                totals[pago.moneda] += pago.monto;
            });
        }
        return totals;
    };

    if (props.cfdi.metadata.type === "PAYMENT_CFDI") {
        let amounts = sumTotalsPaymentCfdi(props.cfdi);

        return (
            <Grid container justify="space-evenly" alignContent="flex-end" direction="column" >
                {
                    Object.keys(amounts).map(key => (
                        <Grid item style={{ textAlign: 'right' }}>
                            <NumberFormat value={amounts[key]} prefix="$ " suffix={` ${key}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                        </Grid>
                    ))
                }
            </Grid>
        );
    }

    if (props.cfdi.metadata.payment_info !== undefined && props.cfdi.metadata.payment_info.credit_notes !== undefined) {
        return (
            <Grid container justify="space-evenly" alignContent="flex-end" direction="column" style={{ fontSize: 12 }}>
                <Grid item style={{ "opacity": 0.5, textAlign: 'right' }}>
                    <NumberFormat value={props.cfdi.total} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                </Grid>
                { props.isAdmin && props.cfdi.metadata.authorized_amount &&
                    <Grid style={{ textAlign: 'right', "color": "blue" }} item  >
                        {translate("cfdis.columns.authorized_amount")}
                        <NumberFormat value={props.cfdi.metadata.authorized_amount.total_authorized} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                    </Grid>
                }
                {(props.cfdi.total - props.cfdi.metadata.balance) > 0 ?
                    <Grid style={{ textAlign: 'right', "color": "gray" }} item  >
                        {translate("cfdis.columns.payment_amount")}
                        <NumberFormat value={props.cfdi.total - props.cfdi.metadata.balance} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                    </Grid> : undefined
                }
                <Grid item style={{ textAlign: 'right', "color": "#ff8000" }} >
                    {props.cfdi.metadata.payment_info.credit_notes.map((note) => (
                        <div style={{ textAlign: 'right', "color": "#ff8000" }}>
                            {translate("cfdis.columns.credit_note_amount")}
                            <NumberFormat value={note.amount} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />
                        </div>
                    ))}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justify="space-evenly" direction="column" alignContent="flex-end" spacing={1} style={{ fontSize: 12 }}>
            <Grid style={{ textAlign: 'right' }} item xs>
                <NumberFormat value={props.cfdi.total} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
            </Grid>
            { props.isAdmin && props.cfdi.metadata.authorized_amount &&
                <Grid style={{ textAlign: 'right', "color": "green" }} item  >
                    {translate("cfdis.columns.authorized_amount")}
                    <NumberFormat value={props.cfdi.metadata.authorized_amount.total_authorized} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                </Grid>
            }
            {(props.cfdi.total - props.cfdi.metadata.balance) > 0 ?
                <Grid style={{ textAlign: 'right', "color": "gray" }} item  >
                    {translate("cfdis.columns.payment_amount")}
                    <NumberFormat value={props.cfdi.total - props.cfdi.metadata.balance} prefix="$ " suffix={` ${props.cfdi.currency}`} decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                </Grid> : undefined
            }
        </Grid>
    );
}
