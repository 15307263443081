import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, Divider, Button, Fab } from "@material-ui/core";
import ContributionsSummaryIcon from "@material-ui/icons/AssignmentTurnedIn";

import { listContributions, exportExcel } from "../api/ContributionAPI";
import { listContributions as listProviderContributions,  } from "../api/ProviderContributionAPI";
import { AppContext } from "../context/AppContext";
import { Contribution, Contributions, ContributionsQueryParams } from "../model/Contribution";
import { ContributionStatus, STATUSES } from "../model/ContributionStatus";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import ContributionsTable from "./ContributionsTable";
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import { WarningSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";

import moment from "moment";

export function AllContributions() {
    return (
        <ContributionsList />
    );
};

export function PendingContributions() {
    return (
        <ContributionsList status="PENDING" />
    );
};

export function CompletedContributions() {
    return (
        <ContributionsList status="COMPLETED" />
    );
};

interface ContributionsProps {
    status?: ContributionStatus;
}

function ContributionsList(props: ContributionsProps) {
    const context = useContext(AppContext);
    const notIssuer = context.isGranted("TenantContributionsRead");
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const monthS = query.get("month");
    const yearS = query.get("year");
    const statusS = query.get("status");

    const [tenantId] = useState(context.session!.tenant!.id);
    const [providerId] = useState(context.session?.provider?.id);
    const [statusLabels] = useState<string[]>(STATUSES.map(s => translate(`contributions.status.${s}`) as string));
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Contributions>();
    const [workingParams, setWorkingParams] = useState<ContributionsQueryParams>({} as ContributionsQueryParams);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [monthKeys, setMonthKeys] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

    const promiseGet = () => {
        const offset = getOffset(page, pageSize);
        const status = props.status || (statusS ? statusS as ContributionStatus : undefined);

        const params = {
            month: !monthS || isNaN(+monthS) ? undefined : +monthS,
            year: !yearS || isNaN(+yearS) ? undefined : +yearS,
            status: status
        } as ContributionsQueryParams;
        setWorkingParams(params);

        if (providerId) {
            return listProviderContributions(tenantId, providerId, pageSize, offset, params);
        }
        return listContributions(tenantId, pageSize, offset, params);
    };

    const load = () => {
        promiseGet().then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, page, pageSize, monthS, yearS, statusS, props.status]);

    useEffect(() => {
        const monthKeys = [];
        const monthLabels = [];
        const years = [];
        const today = new Date();
        for (let i = 0; i < 12; i++) {
            monthKeys.push(`${i + 1}`);
            monthLabels.push(moment({
                year: today.getFullYear(),
                month: i,
                day: 1
            }).format("MMMM"));
        }

        for (let i = 2021; i <= today.getFullYear(); i++) {
            years.push(`${i}`);
        }

        setMonthKeys(monthKeys);
        setMonthLabels(monthLabels);
        setYears(years);
    }, []);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (workingParams.year) {
            query.set("year", `${workingParams.year}`);
        } else {
            query.delete("year");
        }
        if (workingParams.month) {
            query.set("month", `${workingParams.month}`);
        } else {
            query.delete("month");
        }
        if (workingParams.status) {
            query.set("status", workingParams.status);
        } else {
            query.delete("status");
        }

        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        if (!value || value === "---") {
            setWorkingParams({ ...workingParams, [name]: undefined });
        } else if (name === "status") {
            setWorkingParams({ ...workingParams, status: value as ContributionStatus });
        } else {
            setWorkingParams({ ...workingParams, [name]: +value });
        }
    };

    const onUpdatedContributions = (contributions: Contribution[]) => {
        if (data) {
            setData({ ...data, items: contributions })
        }
    };

    const onDownloadReport = () => {
        if(!data || data.items.length === 0){
            setWarning(translate("contributions.export_report.warning") as string);
        }
        setOpenBackdrop(true);
        exportExcel(tenantId, workingParams).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setWarning(translate("contributions.export_report.warning") as string)
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    };

    return (
        <Pagination title={translate("contributions.summary")} icon={<ContributionsSummaryIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={<>
                { !providerId && notIssuer &&
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        <Grid item xs="auto">
                            <Fab color="secondary" size="small" title={translate("contributions.export_report.button") as string} onClick={onDownloadReport} >
                                <DownloadIcon  />
                            </Fab>
                        </Grid>
                    </Grid>
                }
            </>}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter} >
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="year" name="year" label={translate("contributions.year") as string}
                            options={years}
                            margin="dense" disabled={false}
                            value={workingParams.year?.toString()} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs={6} sm>
                        <ValidatedInput type="text" id="month" name="month" label={translate("contributions.month") as string}
                            options={monthKeys} optionLabels={monthLabels}
                            margin="dense" disabled={false}
                            value={workingParams.month?.toString()} onValueChanged={onFilterChanged} />
                    </Grid>
                    {!props.status && (
                        <Grid item xs sm>
                            <ValidatedInput type="text" id="status" name="status" label={translate("contributions.status.title") as string}
                                options={STATUSES} optionLabels={statusLabels}
                                margin="dense" disabled={false}
                                value={workingParams.status} onValueChanged={onFilterChanged} />
                        </Grid>
                    )}
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <ContributionsTable
                uploadSipare={false}
                contributions={data?.items || []}
                onUpdated={onUpdatedContributions}
                status={status} />
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Pagination>
    );
}