import { User } from "./User";
import { Proration } from "./Prorate";
import { Payment } from "./Payment";
import { ExpensesAccount } from "./ExpensesAccount";
import { ExtendedField } from "./ExtendedField";
import { Pagos } from "./ComplementoPagos";
import { Pagos as Pagos20 } from "./ComplementoPagos20";
import { CartaPorte } from "./ComplementoCartaPorte";
import { Nomina } from "./ComplementoNomina";
import { CartaPorte as CartaPorte20 } from "./ComplementoCartaPorte20";
import { CartaPorte as CartaPorte30 } from "./ComplementoCartaPorte30";
import { CartaPorte as CartaPorte31 } from "./ComplementoCartaPorte31";
import { ReceptionDateValidationType } from "./TenantConfiguration";
import { Workflow } from "./Workflow";
import { Company } from "./Company";
import { MultiselectValue } from "../components/MultiselectDropList";
import { Employee } from "./Employee";
import { Customer } from "./Customer";
import { AdditionalInfoField } from "./AdditionalInfoWorkflove";
import { SpecializedServices } from "./CfdiSe";
import { ShippingData } from "./ShippingData";
import { CatalogValue } from "./Catalog";

export interface PaymentInfo {
    payments_response: Payment[];
    payments: string[];
    credit_notes: CreditNote[];
    payment_deadline: Date;
    payment_schedule: Date;
    payment_due: Date;
    advance_payments: CfdiAdvancePayment[];
}

export interface CfdiAdvancePayment {
    id: string;
    date: Date;
    description: string;
    identifier: string;
    payment_amount: number;
    payment_currency: string;
    payment_id: string;
    total: number;
    currency: string;
}

export interface CreditNote {
    cfdi_credit_note_id: string;
    old_balance: number;
    amount: number;
    new_balance: number;
    application_date: Date;
}

export interface CfdisQueryParams {
    search: string;
    status_group: string;
    status_list: string;
    type?: string;
    receptor?: string;
    start_date?: Date;
    end_date?: Date;
    provider: string;
    isCanceled?: boolean;
    isCanceledInTenant?: boolean;
    cfdiType?: string;
    type_list?: string;
    provider_id: string;
    company?: string;
    selected_ids?: string;
    identifier?: string;
    issuer?: string;
    description?: string;
    date_start?: string;
    date_end?: string;
    send_at_start?: string;
    send_at_end?: string;
    type_filter?: string;
    payment_schedule_start?: string;
    payment_schedule_end?: string;
    payment_due_start?: string;
    payment_due_end?: string;
    payment_deadline_start?: string;
    payment_deadline_end?: string;
    authorized_at_start?: string;
    authorized_at_end?: string;
    total?: string;
    balance?: string;
    currency?: string;
    postpone_to_start?: string;
    postpone_to_end?: string;
    company_name?: string;
    excluded_ids: string[];
    lite_payload: boolean;
    archive: boolean;
    connector_id?: string;
    referer_view?: string;
    is_due?: boolean;
    filter_fields?: FilterField[];
    status_groups: string;
}

// Se crea esta interfaz para realizar petición POST al listar cfdis (por problema con urls demasiado largas). Eliminar al corregir nombre de propiedades en CfdisQueryParams
export interface TenantCfdisParams {
    pageSize: number;
    offset: number;
    search: string;
    type: string;
    statusGroup: string;
    statusList: string[];
    typeList: string[];
    userReceptorId: string;
    startDate: Date;
    endDate: Date;
    provider: string;
    providerId: string;
    currency: string;
    cfdiType: string;
    isCanceled: boolean;
    isCanceledInTenant: boolean;
    toBeSettled: boolean;
    allStatusAdvance: boolean;
    identifier: string;
    issuer: string;
    description: string;
    typeFilter: string;
    sendAtStart: Date;
    sendAtEnd: Date;
    paymentScheduleStart: Date;
    paymentScheduleEnd: Date;
    paymentDeadlineStart: Date;
    paymentDeadlineEnd: Date;
    authorizedAtStart: Date;
    authorizedAtEnd: Date;
    total: string;
    balance: string;
    company: string;
    excludedIds: string[];
    litePayload: boolean;
    paymentDueStart: Date;
    paymentDueEnd: Date;
    archive: boolean;
    refererView: string;
    foreignIssuer: boolean;
    isDue?: boolean;
    selectedIds?: string[];
    connectorId?: string;
    filterFields?: FilterField[];
    statusGroups?: string[];
}

export interface ProviderCfdiParams extends TenantCfdisParams {
    userUploaderId?: string;
}

export interface FilterField {
    id: string;
    externalId: string;
    values: string[]
}

export interface CfdiRequest {
    xml: File;
    pdf: File;
    workflow_id?: string;
    company_id?: string;
    provider_id?: string;
    only_se?: boolean;
    validation_oc: string;
    preload_params?: any;
}

export interface CfdisRequest {
    xmls: File[];
    pdfs: File[];
    provider_id?: string;
}

export interface CfdiValidation {
    valid: boolean;
    type: string;
    code: string;
    group: string;
    message: string;
    params: Map<ValidationKeyParams, string>;
    created_at: Date;
}

export interface RevalidationResponse {
    folio_cfdi: string;
    are_all_valid: boolean;
    cfdi_validations: CfdiValidation[];
}

export interface ProviderFiscalStatusResponse {
    provider_is_clean: boolean;
    provider_name: boolean;
    bad_validations_efos: string[];
    bad_validations_cff: string[];
}

export interface CreditNoteResponse {
    credit_note_id: string;
    issue_date: Date;
    serie_folio: string;
    description: string;
    previous_balance: number;
    amount: number;
    new_balance: number;
    currency: string;
}

export interface CfdiMetadata {
    tenant_id: string;
    status: string;
    cfdi_status_group: string;
    cfdi_valid: boolean;
    non_base_valid: boolean;
    validations: CfdiValidation[];
    receptors_names: string;
    authorizer_name: string;
    provider_id: string;
    product_keys: string[];
    required_fields: string[];
    sender_to_review_name: string;
    type: string;
    payment_info?: PaymentInfo;
    subtotal_base: number;
    balance: number;
    credit_notes: CreditNoteResponse[];
    shipping_data: ShippingData;
    cfdi_proration: Proration;
    authorized_amount_response: AuthorizedAmountResponse;
    authorized_amount: AuthorizedAmount;
    pdf_url?: string;
    xml_url?: string;
    xml_file_name?: string;
    pdf_file_name?: string;
    cfdi_related_to_credit_note: string;
    send_at: Date;
    authorized_at: Date;
    has_users_provider: boolean;
    extended_fields?: ExtendedField[];
    cancelled_by_sat: boolean;
    partially_payed: boolean;
    additional_amount: AdditionalAmount;
    from_reception_email: boolean;
    related_cfdis: Cfdi[];
    postpone_to: Date;
    custom_status: string;
    semaphore: SemaphoreConfig;
    before_send?: CfdiBeforeSend[];
    provider_workflows?: Workflow[];
    workflow_id: string;
    tenant_name?: string;
    provider_name?: string;
    provider_upload_sipare: boolean;
    company_name?: string;
    company_id: string;
    from?: Date;
    to?: Date;
    incident_companies?: Company[];
    employee_id?: string;
    customer_id?: string;
    created_at: Date;
    comments?: string;
    customer?: Customer;
    sender?: User;
    specialized_services?: SpecializedServices;
    user_uploader_id?: string;
    fields_response: AdditionalInfoField[];
    uploader_name: string;
    valid_pdf?: boolean;
    advance_metadata?: AdvanceMetadata;
    purchase_orders_response: PurchaseOrderRelationInvoice[];
    in_expenses_account?: boolean;
    expense_account_id?: string;
    expense_account_identifier?: string;
    provider_external_id: string;
    read_only?: ReadOnlyCfdi;
}

export interface ReadOnlyCfdi {
    active: boolean;
    reason: string;
    comments: string;
}

export interface PurchaseOrderRelationInvoice {
    purchase_order_external_id: string;
    purchase_order_line_external_id: string;
    line_num: string;
    code: string;
    unit_measure: string;
    description: string;
    quantity: number;
    unit_price: number;
    price: number;
    concepts: ConceptoTab[];
}

export interface SemaphoreConfig {
    first: string;
    second: string;
    third: string;
}

export interface AdvanceMetadata {
    balance: number;
    applied_amount: number;
    discounts_amount: number;
    is_complementary_advanced: boolean;
    relations: AdvanceRelationMetadata[];
}

export interface AdvanceRelationMetadata {
    complementary_cfdi: Cfdi;
    credit_note?: Cfdi;
    index: number;
    accumulated_applied_amount: number;
    current_balance: number;
}

export interface CfdiBeforeSend {
    operation: string;
    message: string;
    failed: boolean;
    created_at: Date;
}

export interface Concepto {
    description: string;
    importe: number;
    cantidad: number;
    unidad: string;
    valor_unitario: number;
    clave_producto_servicio: string;
    descuento?: number;
}

export interface ConceptoTab extends Concepto {
    id: string;
}

export interface Emisor {
    rfc: string;
    nombre: string;
}

export interface Receptor {
    rfc: string;
    nombre: string;
    uso_cfdi: string;
}

export interface Timbre {
    uuid: string;
    fecha_timbrado: Date;
    numero_certificado_sat: string;
}

export interface Impuestos {
    total_impuestos_retenidos: number;
    total_impuestos_trasladados: number;
}

export interface Cfdi {
    id: string;
    issuer: Issuer;
    date: Date;
    identifier: string;
    description: string;
    type: "CFDI" | "EXPENSES_ACCOUNT" | "HONORARIUM" | "FOREIGN" | "FOREIGN_CREDIT_NOTE" | "PAYROLL" | "REFUND" | "ADVANCE_PAYMENT";
    total: number;
    currency: string;
    metadata: CfdiMetadata;
    cfdi?: CfdiRaw;
    expenses_account?: ExpensesAccount;
    source_id?: string;
    template_valid: boolean;
    template_assigned: boolean;
    provider_fiscal_status_response?: ProviderFiscalStatusResponse;
    cfdis_cancelled?: CfdisCancelled;
    total_to_pay: number;
    provider_tax_id: string;
    error?: string;
    employee?: Employee;
    last_payment?: Date;
    allow_link_po: boolean;
    restrict_only_one_po: boolean;
    validate_amounts_line_po: string;
    amount_tolerance_line_po: number;
    must_link_all_concepts: boolean;
    owners: Owner[];
    cfdi_advance_payment?: string;
}

export interface Owner {
    role: string;
    user_id: string;
    user_name: string;
    active: boolean;
    stage: string;
}

export interface CfdisRelacionados {
    tipo_relacion: string;
    uuids_relacionados: string[];
}

export interface Issuer {
    name: string;
    rfc?: string;
}

export interface Complementos {
    pagos: Pagos;
    pagos20: Pagos20;
    carta_porte: CartaPorte;
    nomina?: Nomina;
    carta_porte20: CartaPorte20;
    carta_porte30: CartaPorte30;
    carta_porte31: CartaPorte31;
}
export interface CfdiRaw {
    version: string;
    serie: string;
    folio: string;
    fecha_emision: Date;
    moneda: string;
    total: number;
    tipo_comprobante: string;
    emisor: Emisor;
    receptor: Receptor;
    conceptos: Concepto[];
    timbre: Timbre;
    forma_pago: string;
    metodo_pago: string;
    numero_certificado: string;
    lugar_expedicion: string;
    subtotal: number;
    descuento?: number;
    impuestos: Impuestos;
    complementos: Complementos;
    cfdis_relacionados: CfdisRelacionados[];
}

export interface AuthorizedAmountResponse {
    total_authorized: number;
    user: User;
}

export interface AuthorizedAmount {
    total_authorized: number;
    user: string;
    comment: string;
}

export interface AuthorizedAmountRequest {
    total: string;
    currency: string;
    comment: string;
}

export interface Cfdis {
    items: Cfdi[];
    total: number;
    has_template: boolean;
}

export interface ExpensesAccounts {
    items: Cfdi[];
    total: number;
}

export interface ExpenseAccount extends Cfdi {
    date_expense_account: Date;
    description_expense_account: string;
    total_expense_account: number;
}

export interface FilterPendingPayments {
    startDate?: Date;
    endDate?: Date;
    provider: string;
}

export interface CfdisCancelled {
    cfdis_invalid: Cfdi[];
}

export interface StatusesCfdi {
    items: MultiselectValue[];
    total: number;
}


export const StatusGroup_SENDING = "SENDING";
export const StatusGroup_IN_PROCESS = "IN_PROCESS";
export const StatusGroup_VALIDATION = "VALIDATION";
export const StatusGroup_RECEIVED = "RECEIVED";
export const StatusGroup_PAYMENT_ORDER = "PAYMENT_ORDER";
export const StatusGroup_ARCHIVED = "ARCHIVED";
export const StatusGroup_POSTPONE = "POSTPONE";
export const StatusGroup_DELIVERED_ERP = "DELIVERED_ERP";

export const StatusGroups = [
    StatusGroup_SENDING,
    StatusGroup_IN_PROCESS,
    StatusGroup_VALIDATION,
    StatusGroup_RECEIVED,
    StatusGroup_PAYMENT_ORDER,
    StatusGroup_ARCHIVED,
    StatusGroup_POSTPONE,
    StatusGroup_DELIVERED_ERP
];

export const CfdiStatus_TO_SEND = "TO_SEND";
export const CfdiStatus_REJECTED = "REJECTED";
export const CfdiStatus_IN_RECEPTION = "IN_RECEPTION";
export const CfdiStatus_REJECTED_BY_ADMIN = "REJECTED_BY_ADMIN";
export const CfdiStatus_IN_REVIEW = "IN_REVIEW";
export const CfdiStatus_PENDING_PAYMENT = "PENDING_PAYMENT";
export const CfdiStatus_PAYMENT_REJECTED = "PAYMENT_REJECTED";
export const CfdiStatus_IN_PAYMENT_ORDER = "IN_PAYMENT_ORDER";
export const CfdiStatus_CANCELLED = "CANCELLED";
export const CfdiStatus_APPLIED = "APPLIED";
export const CfdiStatus_PAID = "PAID";
export const CfdiStatus_ATTACHED = "ATTACHED";
export const CfdiStatus_PAYMENT_CFDI_IN_REVIEW = "PAYMENT_CFDI_IN_REVIEW";
export const CfdiStatus_PAYMENT_POSTPONE = "PAYMENT_POSTPONE";
export const CfdiStatus_DELIVERED_ERP = "DELIVERED_ERP";
export const CfdiStatus_NO_NEED_ATTACH_PAYMENT_CFDI = "NO_NEED_ATTACH_PAYMENT_CFDI";
export const CfdiStatus_PENDING_ATTACH_PAYMENT_CFDI = "PENDING_ATTACH_PAYMENT_CFDI";
export const CfdiStatus_PAYMENT_CFDI_REJECTED = "PAYMENT_CFDI_REJECTED";


export const Statuses = [
    CfdiStatus_TO_SEND,
    CfdiStatus_REJECTED,
    CfdiStatus_IN_RECEPTION,
    CfdiStatus_REJECTED_BY_ADMIN,
    CfdiStatus_IN_REVIEW,
    CfdiStatus_PENDING_PAYMENT,
    CfdiStatus_PAYMENT_REJECTED,
    CfdiStatus_IN_PAYMENT_ORDER,
    CfdiStatus_CANCELLED,
    CfdiStatus_APPLIED,
    CfdiStatus_PAID,
    CfdiStatus_ATTACHED,
    CfdiStatus_PAYMENT_CFDI_IN_REVIEW,
    CfdiStatus_PAYMENT_POSTPONE,
    CfdiStatus_DELIVERED_ERP
];

export const StatusInvoiceProvider = [CfdiStatus_TO_SEND, CfdiStatus_REJECTED, CfdiStatus_CANCELLED];

export const StatusInvoiceTenant = [CfdiStatus_TO_SEND,
    CfdiStatus_IN_RECEPTION,
    CfdiStatus_REJECTED,
    CfdiStatus_REJECTED_BY_ADMIN,
    CfdiStatus_IN_REVIEW,
    CfdiStatus_PENDING_PAYMENT,
    CfdiStatus_PAYMENT_REJECTED,
    CfdiStatus_IN_PAYMENT_ORDER,
    CfdiStatus_PAID, CfdiStatus_CANCELLED,
    CfdiStatus_PAYMENT_POSTPONE,
    CfdiStatus_DELIVERED_ERP,
    CfdiStatus_ATTACHED,
    CfdiStatus_NO_NEED_ATTACH_PAYMENT_CFDI,
    CfdiStatus_PENDING_ATTACH_PAYMENT_CFDI
];

export const StatusCrediteNote = [CfdiStatus_APPLIED, CfdiStatus_CANCELLED];

export const StatusPaymentCfdi = [CfdiStatus_PAYMENT_CFDI_IN_REVIEW, CfdiStatus_ATTACHED, CfdiStatus_DELIVERED_ERP, CfdiStatus_PAYMENT_CFDI_REJECTED];

export const StatusCancelled = [CfdiStatus_CANCELLED];

export const StatusesWithoutSending = Statuses.filter(c => c !== CfdiStatus_TO_SEND && c !== CfdiStatus_REJECTED);

export const StatusesWithoutSendingAndPaid = StatusesWithoutSending.filter(c => c !== CfdiStatus_APPLIED && c !== CfdiStatus_PAID && c !== CfdiStatus_ATTACHED && c !== CfdiStatus_CANCELLED);

export const TypeCfdi = [
    { title: "Cualquiera", value: "ALL" },
    { title: "Factura", value: "INVOICE" },
    { title: "Notas de crédito", value: "CREDIT_NOTE" },
    { title: "Complemento de pago", value: "PAYMENT_CFDI" },
    { title: "Cuenta de gastos", value: "EXPENSES_ACCOUNT" },
    { title: "Cuenta de gastos con honorarios", value: "HONORARIUM" },
    { title: "Factura de anticipo", value: "ADVANCED" },
    { title: "Devoluciones", value: "REFUND" },
    { title: "Extranjero", value: "FOREIGN" },
    { title: "Nota de crédito extranjera", value: "FOREIGN_CREDIT_NOTE"},
    { title: "Pago por adelantado", value: "ADVANCE_PAYMENT" }];

export const TypeExpensesAccount = [
    { title: "Cualquiera", value: "ALL" },
    { title: "Cuenta de gastos", value: "EXPENSES_ACCOUNT" },
    { title: "Cuenta de gastos con honorarios", value: "HONORARIUM" }];

export const TypeCfdiWithoutCustomAgent = TypeCfdi.filter(type => type.value !== "EXPENSES_ACCOUNT" && type.value !== "HONORARIUM");

export function isAllowToSend(cfdi: Cfdi) {
    return (cfdi.metadata.cfdi_status_group === StatusGroup_SENDING || cfdi.metadata.cfdi_status_group === StatusGroup_IN_PROCESS) && !cfdi.metadata.in_expenses_account;
}

export function isAllowToAuthorize(status_group: string) {
    return status_group === StatusGroup_IN_PROCESS || status_group === StatusGroup_VALIDATION;
}

export function isAllowChangeWorkflowProvider(metadata: CfdiMetadata, userId?: string) {
    return userId === metadata.user_uploader_id && metadata.cfdi_status_group === StatusGroup_SENDING;
}

export function isAllowChangeWorkflowTenant(metadata: CfdiMetadata, userId?: string) {
    return userId === metadata.user_uploader_id && metadata.cfdi_status_group === StatusGroup_IN_PROCESS;
}

export function isAllowToReject(status_group: string) {
    return status_group === StatusGroup_IN_PROCESS || status_group === StatusGroup_VALIDATION || status_group === StatusGroup_RECEIVED;
}

export function isAllowSetAdditionalAmount(cfdi: Cfdi, isValidator: boolean, onAdditionalAmount: any) {
    return !isValidator && cfdi.metadata.type === "INVOICE" && (cfdi.metadata.status === CfdiStatus_TO_SEND || cfdi.metadata.status === CfdiStatus_REJECTED)
        && !cfdi.metadata.partially_payed && onAdditionalAmount !== undefined && !cfdi.metadata.in_expenses_account;
}

export function isAllowSetAdditionalAmountTenant(cfdi: Cfdi, isValidator: boolean, onAdditionalAmount: any, isReaderOnly: boolean) {
    return !isReaderOnly && !isValidator && cfdi.metadata.type === "INVOICE" && cfdi.metadata.status !== CfdiStatus_IN_PAYMENT_ORDER && cfdi.metadata.status !== CfdiStatus_PAID
        && cfdi.metadata.status !== CfdiStatus_CANCELLED && cfdi.metadata.status !== CfdiStatus_TO_SEND && cfdi.metadata.status !== CfdiStatus_REJECTED
        && !cfdi.metadata.partially_payed && onAdditionalAmount !== undefined && !cfdi.metadata.in_expenses_account;
}

export function isDisabledCancel(cfdi: Cfdi) {
    return (cfdi.metadata.status === CfdiStatus_CANCELLED || (
        cfdi.total === undefined && cfdi.type === "FOREIGN"
    ) || (cfdi.type === "EXPENSES_ACCOUNT" || cfdi.type === "HONORARIUM")
        || cfdi.metadata.partially_payed) || cfdi.metadata.in_expenses_account;
}

export function isDisabledCancelTenant(cfdi: Cfdi, isGrantedAny: boolean, isReaderOnly: boolean) {
    return (!isGrantedAny || isReaderOnly ||
        (cfdi.metadata.status === CfdiStatus_CANCELLED || cfdi.metadata.status === CfdiStatus_PAID || (
            cfdi.total === undefined && cfdi.type === "FOREIGN")
        ) || (cfdi.type === "EXPENSES_ACCOUNT" || cfdi.type === "HONORARIUM")) || cfdi.metadata.in_expenses_account;
}

export function isAllowToDelete(cfdi: Cfdi) {
    return (cfdi.metadata.cfdi_status_group === StatusGroup_SENDING
        || cfdi.metadata.cfdi_status_group === StatusGroup_IN_PROCESS
        || cfdi.metadata.cfdi_status_group === StatusGroup_VALIDATION
        || cfdi.metadata.status === CfdiStatus_PENDING_PAYMENT
        || cfdi.metadata.status === CfdiStatus_PAYMENT_CFDI_REJECTED
        || cfdi.metadata.status === CfdiStatus_CANCELLED)
        && !cfdi.metadata.partially_payed && !cfdi.metadata.in_expenses_account;
}

export function isSenderAllowToDelete(cfdi: Cfdi) {
    return cfdi.metadata.cfdi_status_group === StatusGroup_SENDING && !cfdi.metadata.in_expenses_account;
}

export const validateCfdiCancelled = (cfdi: Cfdi) => {
    return cfdi && cfdi.metadata && cfdi.metadata.cancelled_by_sat;
};

export const validateCfdiToBeSettled = (cfdi: Cfdi) => {
    return cfdi && cfdi.metadata && cfdi.metadata.advance_metadata && cfdi.metadata.advance_metadata.balance > 0;
};

export const validateCfdiSettled = (cfdi: Cfdi) => {
    return cfdi && cfdi.metadata && cfdi.metadata.advance_metadata && cfdi.metadata.advance_metadata.balance === 0;
};

export function inProcessToSend(status_group: string) {
    return status_group === StatusGroup_SENDING;
}

export function inAuthorized(status_group: string) {
    return status_group === StatusGroup_IN_PROCESS;
}

export function inVerify(status_group: string) {
    return status_group === StatusGroup_VALIDATION;
}

export function inPendingPayment(status_group: string) {
    return status_group === StatusGroup_RECEIVED;
}

export function getAmountDiscounts(cfdi: Cfdi) {
    return cfdi.cfdi?.conceptos.map(concepto => concepto.descuento || 0).reduce((a, b) => a + b);
}

export interface AdditionalAmount {
    name: string;
    amount: number;
}

export enum ValidationKeyParams {
    PAYABLE_DOCUMENT_TYPE,
    RECEPTION_DATE__VALIDATION_TYPE,
    RECEPTION_DATE__LIMIT_DATE,
    RECEPTION_DATE__DOCUMENT_MONTH,
    RECEPTION_DATE__CURRENT_MONTH,
    RECEPTION_DATE__DOCUMENT_YEAR,
    RECEPTION_DATE__CURRENT_YEAR,
}

export interface ReceptionDateValidationResponse {
    valid: boolean;
    tenant_side: boolean;
    validation_type: ReceptionDateValidationType;
    code: string;
    params: Map<ValidationKeyParams, string>;
}

export interface CancelCfdiResonse {
    related_cfdi: string;
}

export interface SecureCfdiFileUrlResonse {
    url: string;
}

export interface ActorStageFieldResponse {
    field_description: string;
    field_external_id: string;
    field_name: string;
    id: string;
    read: boolean;
    type: string;
    type_description: string;
    type_external_id: string;
    type_name: string;
    write: boolean;
    catalog_values: CatalogValue[];
}

export interface ActorStageFieldsResponse {
    fields: ActorStageFieldResponse[];
}

export interface TotalAuthorizeAmountResponse {
    items: TotalAuthorizeSingleAmount[];
}

export interface TotalAuthorizeSingleAmount {
    total_amount: number;
    currency: string;
}

export interface CfdiConceptos {
    items: Concepto[];
    total: number;
}

export type CfdiMetadataType = 'INVOICE' | 'CREDIT_NOTE' | 'PAYMENT_CFDI' | 'TRASLADO' | 'NOMINA' | 'ADVANCED' | 'UNKNOWN';

export const CFDI_METADATA_TYPES: CfdiMetadataType[] = ['INVOICE', 'CREDIT_NOTE', 'PAYMENT_CFDI', 'TRASLADO', 'NOMINA', 'ADVANCED', 'UNKNOWN'];

export interface AdvancePaymentQueryParams {
    provider: string;
    related_invoice: string;
    start_date: string;
    end_date: string;
    payment_start_date: string;
    payment_end_date: string;
    status: string;
}

export interface AdvancePaymentReport {
    id: string;
    tenant_id: string;
    serie_folio: string;
    company_id: string;
    balance: number;
    currency: string;
    date: Date;
    authorized_date: Date;
    user_authorizer_id: string;
    status: string;
    erp_status: string;
    related_invoice_uuid: string;
    provider_name: string;
    provider_rfc: string;
    payment_date: Date;
}

export interface AdvancePaymentResponse extends AdvancePaymentReport {
    company_name: string;
    authorizer_name: string;
    custom_status: string;
}

export interface AdvancePaymentsResponse {
    items: AdvancePaymentResponse[];
    total: number;
}
