import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Box, Typography } from "@material-ui/core";
import { AddIcon, BlockIcon, DeleteIcon, LockOpenIcon } from "../components/Icons";
import { RoleAdminRequest, RoleActionExclusion, RoleAdminOverview } from "../model/Role";
import { Action } from "../model/Action";

import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { RouterParams } from "../router/RouterParams";
import { Redirect } from "react-router-dom";
import Surface from "../components/Surface";
import { getRoleExclusions, updateRoleAdmin } from "../api/RoleAdminAPI";
import Progress from "../components/Progress";
import AddActionPopup from "./AddActionPopup";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";

export default function RoleAdminForm({ match }: RouterParams) {
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const tenantId = match.params.tenantId;
    const roleId = match.params.roleId;
    const [redirect, setRedirect] = useState<string | undefined>();

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [data, setData] = useState<RoleAdminOverview>({
        actions: [] as RoleActionExclusion[],
        actionsExcluded: [] as RoleActionExclusion[]
    } as RoleAdminOverview);
    const [totalActions, setTotalActions] = useState<number>(0);
    const [tenantName, setTenantName] = useState<string>("");
    const [openAddAction, setOpenAddAction] = useState<boolean>(false);
    const [openAddActionExclusion, setOpenAddActionExclusion] = useState<boolean>(false);
    
    const load = () => {
        setStatus("loading");
        getRoleExclusions(tenantId, roleId).then((response) => {
            let dataTemp = {} as RoleAdminOverview;
            dataTemp.name = response.role.description || "";
            dataTemp.public_id = response.role.id;
            dataTemp.actions = response.actions;
            dataTemp.actionsExcluded = response.action_exclusions;
            setData(dataTemp);
            setTotalActions(response.count_actions_from_role);
            setTenantName(response.tenant.name);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    };

    useEffect(load, [tenantId]);

    const onDeleteAction = (id: string) => {
        let actionsTemp = data.actions.filter(action => action.action.public_id !== id);
        setData({ ...data, actions: actionsTemp });
    };

    const onDeleteActionExclusion = (id: string) => {
        let actionsTemp = data.actionsExcluded.filter(action => action.action.public_id !== id);
        setData({ ...data, actionsExcluded: actionsTemp });
    };

    const onSave = () => {
        if(!isValidRequest()){
            return;
        }
        setSubmitting(true);
        updateRoleAdmin(tenantId, roleId, transformRequest()).then((_) => {
            setRedirect(`/tenants-admin/${tenantId}/actions-admin/${translate("roles_admin.success", { "rol": roleId})}`);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const transformRequest = () => {
        let actions = data.actions.map(action => action.action.id);
        let actionsExcluded = data.actionsExcluded.map(action => action.action.id);
        return {
            actions: actions,
            actions_excluded: actionsExcluded
        } as RoleAdminRequest;
    };

    const addAction = () => {
        setOpenAddAction(true);
    };

    const isValidRequest = () => {
        return true;
    };

    const onCloseAddAction = () => {
        setOpenAddAction(false);
    };

    const onCloseAddActionExclude = () => {
        setOpenAddActionExclusion(false);
    };

    const onSaveActions = (actions: Action[]) => {
        setOpenAddAction(false);
        let temp = data;
        let actionsToAdd = actions.filter(action => temp.actions.filter(actionAdded => actionAdded.action.public_id === action.public_id).length == 0)
            .map(action => {
                return {
                    action: action
                } as RoleActionExclusion;
        });
        actionsToAdd.forEach(action => {
            temp.actions.push(action);
        });
        temp.actions.sort((first, second) => first.action.category_label.localeCompare(second.action.category_label));
        setData(temp);
    };

    const addActionExclusion = () => {
        setOpenAddActionExclusion(true);
    };

    const onSaveActionsExclude = (actions: Action[]) => {
        setOpenAddActionExclusion(false);
        let temp = data;
        let actionsToExclude = actions.filter(action => temp.actionsExcluded.filter(actionAdded => actionAdded.action.public_id === action.public_id).length == 0)
            .map(action => {
                return {
                    action: action
                } as RoleActionExclusion;
        });
        actionsToExclude.forEach(action => {
            temp.actionsExcluded.push(action);
        });
        temp.actionsExcluded.sort((first, second) => first.action.category_label.localeCompare(second.action.category_label));
        setData(temp);
    };

    const getAllActionIds = () => {
        let actions = data.actions.map(action => action.action.public_id);
        data.actionsExcluded.forEach(action => {
            actions.push(action.action.public_id);
        });
        return actions;
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    if (status === "loading") {
        return (<Progress />);
    }

    return (
        <Surface title={translate("roles_admin.edit.title", { "roleId": roleId}) as string}
                subtitle={tenantName}
                icon={<LockOpenIcon />} className="PaperPagination PaperTabContainer"
                backButton titleActions={undefined} footerActions={
                    <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
                        <Box pr={2}>
                            <Grid item >
                                <Grid container>
                                    <Grid item >
                                        <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                            {translate("buttons.cancel")}
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={submitting}>
                                            {translate("buttons.save")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box pr={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="name" name="name"
                                    value={translate(`users.roles.${data.public_id}`) as string} label={translate("roles_admin.edit.name") as string}
                                    required={false} disabled={true}
                                    margin={"dense"}
                                    onValueChanged={() => {}} />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedInput type="text" id="public_id" name="public_id"
                                    value={data.public_id} label={translate("roles_admin.edit.public_id") as string}
                                    required={false} disabled={true}
                                    margin={"dense"}
                                    onValueChanged={() => {}} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                            <AdminActions 
                                title={translate("roles_admin.edit.actions_title") as string}
                                subtitle={translate("roles_admin.edit.actions_desc") as string}
                                actions={data.actions} 
                                addAction={ <Button aria-label="options" color="primary" size="small" onClick={addAction} startIcon={<AddIcon />}>
                                                {translate("roles_admin.edit.add_action")}
                                            </Button>} 
                                deleteAction={onDeleteAction}
                                />
                        <AdminActions 
                            title={translate("roles_admin.edit.actions_exclude_title") as string}
                            subtitle={translate("roles_admin.edit.actions_exclude_desc", { "n": totalActions}) as string}
                            actions={data.actionsExcluded} 
                            addAction={ <Button aria-label="options" color="secondary" size="small" onClick={addActionExclusion} startIcon={<BlockIcon />}>
                                            {translate("roles_admin.edit.add_action_exclude")}
                                        </Button>} 
                            deleteAction={onDeleteActionExclusion}/>
                    </Box>
                </Grid>
            </Grid>
            {openAddAction &&
                <AddActionPopup 
                    title={translate("roles_admin.edit.add_action") as string}
                    message={translate("roles_admin.actions.add_description") as string}
                    onAddActions={onSaveActions}
                    onClose={onCloseAddAction}
                    type="add"
                    roleId={data.public_id}
                    actionIds={data.actions.map(action => action.action.public_id)}
                    tenantId={tenantId} 
                />
            }
            {openAddActionExclusion &&
                <AddActionPopup 
                    title={translate("roles_admin.edit.add_action_exclude") as string}
                    message={translate("roles_admin.actions.add_exclude_description", { "rol": roleId }) as string}
                    onAddActions={onSaveActionsExclude}
                    onClose={onCloseAddActionExclude}
                    type="exclude"
                    roleId={data.public_id}
                    actionIds={data.actionsExcluded.map(action => action.action.public_id)}
                    tenantId={tenantId} 
                />
            }
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
        </Surface>
    );
}

interface AdminActionsProps{
    actions: RoleActionExclusion[];
    title: string;
    subtitle: string;
    addAction: React.ReactNode;
    deleteAction(actionId: string): any;
}

function AdminActions(props: AdminActionsProps) {
    return (<Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography variant="inherit">
                {props.title}
            </Typography>
        </Grid>
        <Grid item xs={9}>
            <Typography>
                {props.subtitle}
            </Typography>
        </Grid>
        <Grid item xs={3}>
            <Grid container justifyContent="flex-end" alignItems="flex-end" >
               {props.addAction}
            </Grid>
        </Grid>
        <Grid item xs={12}>
        <Gridable
            items={props.actions ? props.actions : []}
            loading={false}
            error={undefined}
            empty={translate("roles_admin.actions.empty") as string}
            columns={[
                {
                    title: translate("roles_admin.actions.identifier") as string,
                    converter: (actionExclusion) => (
                        <Ellipsis text={ actionExclusion.action.public_id || "" } lenght={ 100 } uppercased={ false } />
                    ),
                    xs: true
                },
                {
                    title: translate("roles_admin.actions.category") as string,
                    converter: (actionExclusion) => (
                        <Ellipsis text={ actionExclusion.action.category_label || "" } lenght={ 100 } uppercased={ false } />
                    ),
                    xs: true
                },
                {
                    title: translate("roles_admin.actions.description") as string,
                    converter: (actionExclusion) => (
                        <Ellipsis text={ actionExclusion.action.description || "" } lenght={ 100 } uppercased={ false } />
                    ),
                    xs: true
                },
                {
                    title:" ",
                    converter: (action) => (
                        <IconButton aria-label="options" color="default" size="small" onClick={() => props.deleteAction(action.action.public_id)}>
                            <DeleteIcon />
                        </IconButton>
                    ),
                    xs: "auto"
                }
            ]} />
        </Grid>
    </Grid>);
}