import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import OnIcon from "@material-ui/icons/ToggleOnTwoTone";
import OffIcon from "@material-ui/icons/ToggleOffTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface CompanyPrincipalMenuProps {
    anchor: HTMLElement | null;
    onDisableInvoiceReception(): any;
    onEnableInvoiceReception(): any;
    onClose(): any;
}

export default function CompanyPrincipalMenu(props: CompanyPrincipalMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="companies-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["CompaniesUpdateInvoiceReception"]) &&
                <MenuItem button onClick={props.onDisableInvoiceReception} dense>
                    <ListItemIcon>
                        <OffIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("companies.invoice_reception.disable_all_invoice_reception")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["CompaniesUpdateInvoiceReception"]) &&
                <MenuItem button onClick={props.onEnableInvoiceReception} dense>
                    <ListItemIcon>
                        <OnIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("companies.invoice_reception.enable_all_invoice_reception")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}