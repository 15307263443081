import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import { AddIcon, DeleteIcon, EditIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { Template, TemplateField, TemplateMetadata } from "./Model";
import { emptyPromise } from "../api/API";
import { getTemplateMetadata, getTemplate, Product } from "./Workflove";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import { AppContext } from "../context/AppContext";
import TemplateFieldForm from "./TemplateFieldForm";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import { Catalog } from "../model/Catalog";
import { listExpressions } from "../api/ExpressionAPI";
import { ExpressionsQueryParams, Expressions } from '../model/Expression';

type Popup = "add" | "edit";

interface TemplateFormProps<T> {
    product: Product;
    templateId?: string;
    byEachPayment: string[];
    getPromise(template: Template): Promise<T>;
    onCompleted(entity: T, template: Template): any;
    catalogs: Catalog[];
    shippingTemplateId?:string;
}

function TemplateForm<T>({ product, catalogs, templateId, byEachPayment, getPromise, onCompleted , shippingTemplateId}: TemplateFormProps<T>) {
    const history = useHistory();
    const context = useContext(AppContext);
    const allowedPurchaseOrders = context.isGranted("PurchaseOrdersRead");
    const allowedWarehouseDeliveries = context.isGranted("WarehouseDeliveriesRead");

    const [status, setStatus] = useState("loading");
    const [typeIds, setTypeIds] = useState<string[]>([]);
    const [typeLabels, setTypeLabels] = useState<string[]>([]);
    const [typeCatalogId, setTypeCatalogId] = useState<string>();
    const [typePurchaseOrderId, setPurchaseOrderId] = useState<string>();
    const [typeWarehouseDeliveryId, setWarehouseDeliveryId] = useState<string>();
    const [typeUrlId, setTypeUrlId] = useState<string>();
    const [request, setRequest] = useState<Template>({} as Template);
    const [fieldsValues, setFieldsValues] = useState<any>({});
    const [validations, setValidations] = useState({} as any);
    const [errorFields, setErrorFields] = useState<string>();
    const [error, setError] = useState<string>();

    const [popup, setPopup] = useState<Popup>();
    const [field, setField] = useState<TemplateField>();
    const [index, setIndex] = useState<number>();
    const [processing, setProcessing] = useState(false);
    const [expressionsData, setExpressionsData] = useState<Expressions>();

    const promiseGet = useCallback((): Promise<Template | undefined> => {
        if (templateId) {
            return getTemplate(product, templateId);
        }
        return emptyPromise();
    }, [product, templateId]);

    const promiseMetadata = useCallback((): Promise<TemplateMetadata> => {
        return getTemplateMetadata(product);
    }, [product]);

    const promiseExpressions = useCallback((): Promise<Expressions> => {
        return listExpressions(context.session!.tenant!.id, 0, 0, { search: "" } as ExpressionsQueryParams);
    }, [product]);


    useEffect(() => {
        setStatus("loading");
        Promise.all([
            promiseMetadata(),
            promiseGet(),
            promiseExpressions()
        ]).then(([metadata, template, expressions]) => {
            const types = metadata.types.filter(el => {
                if (el.external_id === "CATALOG") {
                    setTypeCatalogId(el.id);
                }

                if (el.external_id === "URL") {
                    setTypeUrlId(el.id);
                }

                if (el.external_id === "PURCHASE_ORDER") {
                    setPurchaseOrderId(el.id);
                    return allowedPurchaseOrders
                } else if (el.external_id === "WAREHOUSE_DELIVERY") {
                    setWarehouseDeliveryId(el.id);
                    return allowedWarehouseDeliveries
                }
                return true;
            });

            setExpressionsData(expressions);
            const fieldsValues = {} as any;
            setTypeIds(types.map(el => el.id));

            setTypeLabels(types.map(el => {
                let name;
                if (el.name) {
                    name = el.name;
                } else {
                    name = translate(`fields.type.${el.external_id ?? el.id}`) as string;
                }
                fieldsValues[el.id] = name;
                return name;
            }));
            setFieldsValues(fieldsValues);

            const fields = template?.fields.map(field => {
                field.type_name = fieldsValues[field.type];
                field.by_each_payment = byEachPayment.findIndex(el => el === field.id) >= 0;
                const cat = catalogs.find(catalog => catalog.id === field.external_id);
                if(cat){
                    field.name = cat.name;
                }
                return field;
            }) ?? [];

            setRequest({
                id: template?.id,
                name: template?.name ?? "",
                description: template?.description ?? "",
                fields: fields,
                product: product,
            } as Template);

            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        })
    }, [promiseGet, promiseMetadata, allowedPurchaseOrders, allowedWarehouseDeliveries, byEachPayment, product]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    }

    const onClosePopup = () => {
        setPopup(undefined);
        setField(undefined);
        setIndex(undefined);
    }

    const showPopup = (popup: Popup) => (field?: TemplateField, index?: number) => {
        setPopup(popup);
        setField(field);
        setIndex(index);
    };

    const onAdd = showPopup("add");

    const onEdit = showPopup("edit");

    const onUpdated = (field: TemplateField) => {
        const updatedField = {
            ...field,
            id: field.id || Math.random().toString(36).substr(2),
            type_name: fieldsValues[field.type]
        };

        let newFields;
        if (index === undefined) {
            newFields = [...request.fields, updatedField];
        } else {
            newFields = request.fields.map((el, i) => i === index ? updatedField : el);
        }

        setRequest({ ...request, fields: newFields });
        onClosePopup();
    }

    const onDelete = (index: number) => () => {
        const newFields = request.fields.filter((el, i) => i !== index);
        setRequest({ ...request, fields: newFields });
    };

    const onSubmit = () => {
        if (!isValid(validations)) {
            return;
        }

        if (request.fields.length === 0) {
            setErrorFields(translate("shipping_templates.fields.required") as string);
            return;
        } else {
            setErrorFields(undefined);
        }

        setProcessing(true);
        getPromise(request).then((response) => {
            onCompleted(response, request);
        }).catch(error => {
            setError(error.message);
            setProcessing(false);
        });
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
                <ValidatedInput
                    type="text"
                    id="name"
                    name="name"
                    value={request.name}
                    label={translate("shipping_templates.name") as string}
                    required
                    margin="dense"
                    onValueChanged={hasChanged} />
                <ValidatedInput
                    type="text"
                    id="description"
                    name="description"
                    value={request.description}
                    label={translate("shipping_templates.description") as string}
                    margin="dense"
                    onValueChanged={hasChanged} />
            </Grid>
            <Grid item xs={12}>
                <Box mx={-2}>
                    <Gridable
                        items={request.fields || []}
                        loading={false}
                        empty={translate("shipping_templates.empty") as string}
                        columns={[
                            {
                                title: translate("shipping_templates.name") as string,
                                converter: (field) => field.name,
                                fullWidth: true,
                                xs: true
                            },
                            {
                                title: translate("shipping_templates.type") as string,
                                converter: (field) => field.type_name ?? "---",
                                fullWidth: true,
                                xs: 3,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3
                            },
                            {
                                title: translate("shipping_templates.description") as string,
                                converter: (field) => field.description || "---",
                                xs: 3,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3
                            },
                            {
                                title: translate("shipping_templates.by_each_payment.title") as string,
                                converter: (field) => translate(`shipping_templates.by_each_payment.options.${field.by_each_payment}`),
                                xs: 3,
                                sm: 3,
                                md: 3,
                                lg: 3,
                                xl: 3
                            },
                            {
                                title: (
                                    <>
                                        <IconButton aria-label="options" color="primary" size="small" style={{ visibility: "hidden" }}>
                                            <AddIcon />
                                        </IconButton>
                                        <IconButton aria-label="options" color="primary" size="small" onClick={() => onAdd()}>
                                            <AddIcon />
                                        </IconButton>
                                    </>
                                ),
                                converter: (field, index) => (
                                    <>
                                        <IconButton aria-label="options" color="primary" size="small" onClick={() => onEdit(field, index)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="options" size="small" onClick={onDelete(index)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </>
                                ),
                                fullWidth: true,
                                xs: "auto"
                            }
                        ]} />
                </Box>
                {errorFields && (
                    <Box mt={2}>
                        <Typography variant="body2" color="error" component="h6" align="right">
                            {errorFields}
                        </Typography>
                    </Box>
                )}
            </Grid>
            <Grid item xs={12}>
                <Box pt={2}>
                    <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                        <Grid item md="auto">
                            <Button variant="contained" color="primary" size="large" onClick={onSubmit} >
                                {translate("buttons.save")}
                            </Button>
                        </Grid>
                        <Grid item md="auto">
                            <Button variant="text" color="primary" size="large" onClick={history.goBack}>
                                {translate("buttons.cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {(popup === "add" || popup === "edit") && (
                <TemplateFieldForm
                    product={product}
                    current={field}
                    typeIds={typeIds}
                    typeLabels={typeLabels}
                    typeCatalogId={typeCatalogId}
                    typePurchaseOrderId={typePurchaseOrderId}
                    typeWarehouseDeliveryId={typeWarehouseDeliveryId}
                    typeUrlId={typeUrlId}
                    catalogIds={catalogs.map(el => el.id)}
                    catalogNames={catalogs.map(el => el.name)}
                    expressionsData={expressionsData!}
                    shippingTemplateId={shippingTemplateId}
                    onUpdated={onUpdated}
                    onClose={onClosePopup} />
            )}
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={processing} message={translate("shipping_templates.processing") as string} />
        </Grid>
    )
}

export default TemplateForm