import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import EditIcon from "@material-ui/icons/EditTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ProviderLedgerAccount } from "../model/Provider";

interface ProviderConfigLedgerMenuProps {
    configLedger: ProviderLedgerAccount;
    anchor: HTMLElement | null;
    onDelete(): any;
    onEdit(): any;
    onClose(): any;
}

export default function ProviderConfigLedgerMenu(props: ProviderConfigLedgerMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("LedgerConfigurationUpdate") &&
                <MenuItem button onClick={props.onEdit} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>

            }
            {context.isGranted("LedgerConfigurationDelete") &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 