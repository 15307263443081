import React, { useContext, useState, useEffect } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";

import { getCfdiFileUrl } from "../../api/ProviderCfdiApi";
import { getCoverUrl } from "../../api/ExpensesAccountAPI";
import { Cfdi, isAllowToSend, isAllowToDelete, isAllowChangeWorkflowProvider, isDisabledCancel, isAllowSetAdditionalAmount } from "../../model/Cfdi";
import SendCfdiIcon from '@material-ui/icons/SendTwoTone';
import DownloadXmlIcon from '@material-ui/icons/CodeTwoTone';
import DownloadPdfIcon from '@material-ui/icons/PictureAsPdfTwoTone';
import DetailsIcon from '@material-ui/icons/VisibilityTwoTone';
import DownloadIcon from '@material-ui/icons/GetAppTwoTone';
import MonetizationIcon from '@material-ui/icons/MonetizationOnTwoTone';
import WorkIcon from '@material-ui/icons/WorkTwoTone';
import { Link } from "react-router-dom";
import BlockIcon from '@material-ui/icons/Block';
import { isRoleOrParent } from "../../model/Role";
import { DeleteIcon, EditIcon } from "../../components/Icons";
import { Receipt } from "@material-ui/icons";

interface ProviderCfdiMenuProps {
    cfdi: Cfdi;
    referer: string;
    inner?: boolean;
    anchor: HTMLElement | null;
    onClose(): any;
    onSend?(): any;
    onDelete?(): any;
    onAdditionalAmount?(): any;
    onChangeWorkflow?(): any;
    onDeleteInProcess?(): any;
    onDownloadFiles(): any;
    onDownloadValidationsPdf(): any;
    onCancel(): any;
    onEditAdvancePayment?(): any;
    onAssociateAdvancePayments?(): void
}

export default function ProviderCfdiMenu(props: ProviderCfdiMenuProps) {
    const context = useContext(AppContext);
    const userId = context.session?.user?.id;
    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [coverPdfUrl, setCoverPdfUrl] = useState<string>();
    const isValidator = isRoleOrParent(context.session!.role, "validator");

    useEffect(() => {
        if (props.cfdi.metadata.pdf_url) {
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "pdf").then((urlFile) => {
                setPdfUrl(urlFile);
            }).catch((error) => {
                setPdfUrl(undefined);
            });
        }
        if (props.cfdi.metadata.xml_url) {
            getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "xml").then((urlFile) => {
                setXmlUrl(urlFile);
            }).catch((error) => {
                setXmlUrl(undefined);
            });
        }

        if (props.cfdi.expenses_account && props.cfdi.expenses_account.cover_path) {
            const providerId = context.session?.provider?.id || props.cfdi.metadata.provider_id;
            getCoverUrl(context.session!.tenant!.id, providerId, props.cfdi.id).then((urlFile) => {
                setCoverPdfUrl(urlFile);
            }).catch((error) => {
                setCoverPdfUrl(undefined);
            });
        }
    }, [context.session, props.cfdi]);

    return (
        <Menu
            id="cfdi-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGrantedAny(["ProviderCFDIsRead", "SenderCFDIsRead", "AdminCFDIsRead"]) && !props.inner &&
                <MenuItem button component={Link} to={`/cfdis/${props.cfdi.id}/details?referer=${props.referer}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <DetailsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_details")}</Typography>
                </MenuItem>
            }
            {props.onSend && context.isGrantedAny(["ProviderCFDIsUpdate", "SenderCFDIsUpdate"]) && isAllowToSend(props.cfdi) &&
                <MenuItem button onClick={props.onSend} dense>
                    <ListItemIcon>
                        <SendCfdiIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.to_send.actions.send")}</Typography>
                </MenuItem>
            }
            {xmlUrl && context.isGrantedAny(["ProviderCFDIsRead", "SenderCFDIsRead", "AdminCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={xmlUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_xml")}</Typography>
                </MenuItem>
            }
            {pdfUrl && context.isGrantedAny(["ProviderCFDIsRead", "SenderCFDIsRead", "AdminCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.show_pdf")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "ProviderCFDIsRead", "CxpCFDIsRead"]) &&
                <MenuItem button onClick={props.onDownloadFiles} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.download_cfdi")}</Typography>
                </MenuItem>
            }
            {isAllowSetAdditionalAmount(props.cfdi, isValidator, props.onAdditionalAmount) &&
                <MenuItem button onClick={props.onAdditionalAmount} dense >
                    <ListItemIcon>
                        <MonetizationIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.add_additional_amount")}</Typography>
                </MenuItem>
            }
            {coverPdfUrl && context.isGrantedAny(["ProviderCFDIsRead", "SenderCFDIsRead", "AdminCFDIsRead"]) &&
                <MenuItem button onClick={props.onClose} dense component="a" href={coverPdfUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("expenses_accounts.see_cover")}</Typography>
                </MenuItem>
            }

            {props?.onAssociateAdvancePayments && context.isGrantedAny(["AdvancePaymentAssociateCfdi"]) && "CFDI" === props.cfdi.type && ["SENDING", "IN_PROCESS"].includes(props.cfdi.metadata.cfdi_status_group)
                &&
                <MenuItem button onClick={props.onAssociateAdvancePayments} dense>
                    <ListItemIcon>
                        <Receipt fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("add_advance_payments.menu_option")}</Typography>
                </MenuItem>
            }
            {isDisabledCancel(props.cfdi) ? undefined :
                <MenuItem button onClick={props.onCancel} dense>
                    <ListItemIcon>
                        <BlockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate(props.cfdi.metadata.type === "CREDIT_NOTE" ? "cfdis.cancel_cfdi.title_cn" : "cfdis.cancel_cfdi.title")}</Typography>
                </MenuItem>
            }
            {props.onDelete && context.isGrantedAny(["ProviderCFDIsDelete", "SenderCFDIsDelete"])
                && isAllowToDelete(props.cfdi) &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.delete_cfdi")}</Typography>
                </MenuItem>
            }
            {props.onDeleteInProcess && context.isGrantedAny(["ProviderCFDIsDelete"])
                && isAllowToDelete(props.cfdi) &&
                <MenuItem button onClick={props.onDeleteInProcess} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.delete_cfdi")}</Typography>
                </MenuItem>
            }
            {props.inner && isAllowChangeWorkflowProvider(props.cfdi.metadata, userId) && context.isGrantedAny(["ProviderCFDIsUpdate", "AdminCFDIsUpdate", "SenderCFDIsUpdate"])
                && isAllowToDelete(props.cfdi) &&
                <MenuItem button onClick={props.onChangeWorkflow} dense>
                    <ListItemIcon>
                        <WorkIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("workflows.cfdi_workflow.change")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["AdminCFDIsRead", "ProviderCFDIsRead", "ReceptorCFDIsRead",
                "SenderCFDIsRead", "CxpCFDIsRead", "CfdisIssuedRead", "TenantCfdisIssuedRead", "ProviderCfdisIssuedRead"])
                && <MenuItem button onClick={props.onDownloadValidationsPdf} dense>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.validations_pdf")}</Typography>
                </MenuItem>
            }
            {props.inner && props.cfdi.type === 'ADVANCE_PAYMENT' && props.cfdi.metadata.cfdi_status_group === "SENDING" && context.isGranted('AdvancePaymentUpdate') &&
                <MenuItem button onClick={props.onEditAdvancePayment} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.edit_advance_payment")}</Typography>
                </MenuItem>
            }
        </Menu>);
}
