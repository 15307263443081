import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@material-ui/core";
import ExpedientIcon from "@material-ui/icons/AllInbox";

import translate from "../i18n/Translator";
import { TenantFieldCatalog } from "../model/TenantFieldCatalog";
import Surface from "../components/Surface";
import { getTenantFieldsCatalog } from "../api/TenantFieldAPI";
import { AppContext } from "../context/AppContext";
import Progress from "../components/Progress";
import ExpedientSettingsFields from "./ExpedientSettingsFields";
import ExpedientSettingsAddresses from "./ExpedientSettingsAddresses";
import ExpedientSettingsContacts from "./ExpedientSettingsContacts";
import ExpedientSettingsBankAccounts from "./ExpedientSettingsBankAccounts";

export default function ExpedientSettingsForm() {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");

    const [expedient, setExpedient] = useState<TenantFieldCatalog>();

    useEffect(() => {
        setStatus("loading");
        getTenantFieldsCatalog(context.session!.tenant!.id)
            .then((response) => {
                setStatus("loaded");
                setExpedient(response);
            })
            .catch((error) => {
                setStatus(error.message);
            });
    }, [context.session]);

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !expedient) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface
            title={translate("expedients.title") as string}
            subtitle={translate("expedients.subtitle") as string}
            icon={<ExpedientIcon />}
            className="PaperPagination">
            <ExpedientSettingsFields expedient={expedient} view="fields"/>
            <ExpedientSettingsAddresses expedient={expedient} view="fields"/>
            <ExpedientSettingsContacts expedient={expedient} view="fields"/>
            <ExpedientSettingsBankAccounts expedient={expedient} view="fields"/>
        </Surface>
    );
}
