import { callAPI, HTTPMethod } from "./API";
import { TenantModulesResponse, TenantModulesRequest } from "../model/TenantModule";

const basePath = (tenantId: string) => `/tenants/${tenantId}/modules`;

export async function get(tenantId: string): Promise<TenantModulesResponse> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.GET,
    });
}

export async function update(tenantId: string, request: TenantModulesRequest): Promise<TenantModulesResponse> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.PUT,
        body: request
    });
}
