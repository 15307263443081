import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import { Grid, Fab, Divider, FormControl, Select, Box, IconButton } from "@material-ui/core";
import queryString from "query-string";
import { listRefunds, deleteRefund } from "../api/RefundPayableDocumentApi";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import { initialPageZero, initialPageSize } from "../components/Pagination";

import { Filter } from "@devexpress/dx-react-grid";
import AddIcon from "@material-ui/icons/Add";
import { getRefundsProperties, GetRefundsPropertiesProps } from "./RefundsProperties";
import GridDx from "../components/GridDx";
import { formatDateString, concatDates } from "../components/DateFormat";
import RefundMenu from "./RefundMenu";
import ToSendIcon from "@material-ui/icons/SendTwoTone";
import ToAuthorizeIcon from "@material-ui/icons/GavelTwoTone";
import ArchiveIcon from "@material-ui/icons/ArchiveTwoTone";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';

import ConfirmationPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import { Refund, Refunds, RefundsQueryFilter } from "../model/Refund";
import RefundFormPopup from "./RefundFormPopup";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import { forwardRefund, cancelRefund, exportRefunds } from "../api/RefundPayableDocumentApi";
import { TransitionRequest } from "../model/Refund";
import UpdateStatusRefundPopup from "../cfdis/popups/UpdateStatusRefundPopup";
import ConnectorObjectExporter from "../connectors/ConnectorObjectExporter";
import { Family, ExportResponse } from '../model/Connector';
import { ExportMode } from '../connectors/ConnectorObjectExporter';
import ExportPopup from "../components/DownloadExportationPopup";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RefundMenuOptions from "./RefundMenuOptions";
import Tooltip from "@material-ui/core/Tooltip";

export default function RefundsView({ match }: RouterParams) {
    const [view, setView] = useState<string>("");
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): RefundsQueryFilter => {
        return {
            "no_dev": typeof qs["noDev"] === "string" ? qs["noDev"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "vendor": typeof qs["vendor"] === "string" ? qs["vendor"] as string : "",
            "requester": typeof qs["requester"] === "string" ? qs["requester"] as string : "",
            "credit_balance_init": typeof qs["credit_balance_init"] === "string" ? qs["credit_balance_init"] as string : "",
            "credit_balance_end": typeof qs["credit_balance_end"] === "string" ? qs["credit_balance_end"] as string : "",
            "reception_date_init": typeof qs["reception_date_init"] === "string" ? qs["reception_date_init"] as string : "",
            "reception_date_end": typeof qs["reception_date_end"] === "string" ? qs["reception_date_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "paid": typeof qs["paid"] === "string" ? qs["paid"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
        } as RefundsQueryFilter;
    };
    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Refunds>();
    const [params, setParams] = useState<RefundsQueryFilter>(paramsFromQueryString);
    const [refund, setRefund] = useState<Refund>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [showRefundForm, setShowRefundForm] = useState<boolean>(false);
    const gridProps = getRefundsProperties({ view: view } as GetRefundsPropertiesProps);
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [openSendRefund, setOpenSendRefund] = useState<"send" | "cancel">();
    const [exportMode, setExportMode] = useState<ExportMode>();
    const [exportResult, setExportResult] = useState<ExportResponse>();
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);

    const paramsToFilters = () => {
        return [
            { columnName: 'identifier', value: params.no_dev },
            { columnName: 'description', value: params.no_dev },
            { columnName: 'issuer_name', value: params.vendor },
            { columnName: 'sender_name', value: params.requester },
            { columnName: 'date', value: concatDates(params.credit_balance_init, params.credit_balance_end) },
            { columnName: 'send_at', value: concatDates(params.reception_date_init, params.reception_date_end) },
            { columnName: 'total', value: params.total },
            { columnName: 'payment_amount', value: params.paid },
            { columnName: 'metadata.balance', value: params.balance },
            { columnName: 'currency', value: params.currency },
            { columnName: 'status', value: params.status },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        if (match.params.success && isSuccess) {
            setSuccess(match.params.success);
            setIsSuccess(false);
        }

        let href = window.location.pathname;
        let resolved_view = '';
        if (href.includes('send')) {
            resolved_view = 'to_send'
        } else if (href.includes('authorize')) {
            resolved_view = 'to_authorize'
        } else if (href.includes('archive')) {
            resolved_view = 'archive';
        }
        setView(resolved_view);

        let statusGroup = getStatusFromVIew(resolved_view);
        Promise.all([
            listRefunds(context.session!.tenant!.id, pageSize, pageSize * page, { ...params, status_group: statusGroup, order: href.includes('archive') ? "desc" : '' } as RefundsQueryFilter),
            getTenantCurrencies(context.session!.tenant!.id)
        ]).then(responses => {
            const dataResponses = responses[0];
            setData(dataResponses);
            pushHistory();
            const tenantCurrenyResponses = responses[1];
            if (tenantCurrenyResponses.tenant_currency_ids) {
                setCurrencies(tenantCurrenyResponses.tenant_currency_ids);
            }
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const getStatusFromVIew = (view: string) => {
        switch (view) {
            case 'to_send':
                return "SENDING";
            case 'to_authorize':
                return "IN_PROCESS";
            case 'archive':
                return "";
            default:
                return "";
        }
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.no_dev = filter.value;
                        break;
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "issuer_name":
                        temp.vendor = filter.value;
                        break;
                    case "sender_name":
                        temp.requester = filter.value;
                        break;
                    case "date":
                        let dates = filter.value.split(" - ");
                        if (dates.length === 2) {
                            temp.credit_balance_init = formatDateString(dates[0]);
                            temp.credit_balance_end = formatDateString(dates[1]);
                        } else {
                            temp.credit_balance_init = "";
                            temp.credit_balance_end = "";
                        }
                        break;
                    case "send_at":
                        let send_at_dates = filter.value.split(" - ");
                        if (send_at_dates.length === 2) {
                            temp.reception_date_init = formatDateString(send_at_dates[0]);
                            temp.reception_date_end = formatDateString(send_at_dates[1]);
                        } else {
                            temp.reception_date_init = "";
                            temp.reception_date_end = "";
                        }
                        break;
                    case "total":
                        temp.total = filter.value;
                        break;
                    case "payment_amount":
                        temp.paid = filter.value;
                        break;
                    case "metadata.balance":
                        temp.balance = filter.value;
                        break;
                    case "currency":
                        temp.currency = filter.value;
                        break;
                    case "status":
                        temp.status = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["noDev"] = params.no_dev ? params.no_dev : "";
        qs["description"] = params.description ? params.description : "";
        qs["vendor"] = params.vendor ? params.vendor : "";
        qs["requester"] = params.requester ? params.requester : "";
        qs["credit_balance_init"] = params.credit_balance_init ? params.credit_balance_init : "";
        qs["credit_balance_end"] = params.credit_balance_end ? params.credit_balance_end : "";
        qs["reception_date_init"] = params.reception_date_init ? params.reception_date_init : "";
        qs["reception_date_end"] = params.reception_date_end ? params.reception_date_end : "";
        qs["total"] = params.total ? params.total : "";
        qs["paid"] = params.paid ? params.paid : "";
        qs["balance"] = params.balance ? params.balance : "";
        qs["currency"] = params.currency ? params.currency : "";
        qs["page"] = "0";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onClickedOptions = (refund: Refund) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setRefund(refund);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setRefund(undefined);
    };

    const showDeleteDialog = () => {
        if (!refund) return;
        setAnchorEl(null);
        setOpenConfirmDelete(true);
    };

    const onCancelDelete = () => {
        setOpenConfirmDelete(false);
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onAddRefund = () => {
        setRefund(undefined);
        setShowRefundForm(true);
    }

    const onEditRefund = () => {
        setAnchorEl(null);
        if (!refund) {
            return;
        }
        setShowRefundForm(true);
    }

    const onClickedRow = (refund: Refund) => {
        pushHistory();
        setRedirect(`/cfdis/${refund.id}/details?referer=${view + "_refunds"}&${queryParam}`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelected(selected);
    };

    const onDeleteRefund = () => {
        if (!refund) return;
        setOpenConfirmDelete(false);
        setOpenBackdrop(true);
        deleteRefund(context.session!.tenant!.id, refund.id).then(() => {
            load();
            setSuccess(translate("refunds.confirm_delete.success", { "number": refund.identifier }) as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setRefund(undefined);
            setOpenBackdrop(false);
        });
    };

    const getIcon = () => {
        if (view === "to_send") {
            return <ToSendIcon />;
        } else if (view === "to_authorize") {
            return <ToAuthorizeIcon />;
        } else if (view === "archive") {
            return <ArchiveIcon />;
        }
        return <></>;
    };

    const downloadRefundsWithConnector = (connectorId: string, starDate?: Date, endDate?: Date) => {
        if (!exportMode) return;

        let promise: Promise<ExportResponse>;
        let statusGroup = getStatusFromVIew(view);
        if (exportMode === 'PARAMS') {
            promise = exportRefunds(context.session!.tenant!.id,
                pageSize, pageSize * page, { ...params, status_group: statusGroup, ids: undefined } as RefundsQueryFilter, connectorId);
        } else {
            promise = exportRefunds(context.session!.tenant!.id,
                pageSize, pageSize * page, { ...params, status_group: statusGroup, ids: selected } as RefundsQueryFilter, connectorId);
        }

        promise.then((response) => {
            if (response.url) {
                setExportResult(response);
            } else if (response.total) {
                setSuccess(translate("refunds.export.total_export", { "total": response.total }) as string);
            } else {
                setWarning(translate("refunds.export.empty_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(onCloseExporter);
    }

    const onCloseExporter = () => {
        setExportMode(undefined);
    };


    const onCloseDownload = () => {
        setExportResult(undefined);
    }

    const onExportWithParams = () => {
        setGridAnchorEl(null);
        if (data?.total) {
            setExportMode('PARAMS');
        } else {
            setWarning(translate("refunds.export.empty_export") as string);
        }
    }

    const onExportSelected = () => {
        setGridAnchorEl(null);
        if (selected.length) {
            setExportMode('SELECTED');
        } else {
            setWarning(translate("refunds.export.empty_selection") as string);
        }
    }
    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const getActions = () => {
        if (view === 'to_send' && context.isGrantedAny(["RefundsCreate", "RefundsCxcCreate"])) {
            return (
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}
                                onClick={onAddRefund} disabled={status === "loading"}>
                                <AddIcon />
                            </Fab>

                        </Grid>

                        <Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={false}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>

                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid item xs="auto">
                <IconButton color="default" size="small" onClick={onClickedMore} disabled={false}>
                    <MoreVertIcon />
                </IconButton>
            </Grid>
        );
    };

    const onSave = (refund: Refund) => {
        setRedirect(`/cfdis/${refund.id}/details?referer=${view}&${queryParam}`);
    }

    const onShowSendRefund = () => {
        if (!refund) return;
        setAnchorEl(null);
        setOpenSendRefund("send");
    }

    const onShowCancelRefund = () => {
        if (!refund) return;
        setOpenSendRefund("cancel");
    }

    const onSendRefund = (reason: string) => {
        setOpenSendRefund(undefined);
        if (!refund) return;
        setOpenBackdrop(true);
        forwardRefund(context.session!.tenant!.id, refund.id, { reason: reason } as TransitionRequest).then(refund => {
            load();
            setSuccess(translate("refunds.send_success", { "id": refund.identifier }) as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setRefund(undefined);
            setOpenBackdrop(false);
        });
    };

    const onCancelRefund = (reason: string) => {
        setOpenSendRefund(undefined);
        if (!refund) return;
        setOpenBackdrop(true);
        cancelRefund(context.session!.tenant!.id, refund.id, { reason: reason } as TransitionRequest).then(response => {
            load();
            setSuccess(translate("refunds.success_cancel", { "id": refund.identifier }) as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setRefund(undefined);
            setOpenBackdrop(false);
        });
    };

    const onCloseUpdateRefund = () => {
        setOpenSendRefund(undefined);
    }

    const onCloseRefundForm = () => {
        setShowRefundForm(false);
    }

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const filtersExceptions = [view === "archive" ? 'status' : '', 'payment_amount', 'sender_name'];

    const CurrencyFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {currencies.map((value) => {
                            return <option key={value} value={value}>{value}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };
    const UserEmailTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                <Tooltip title={value.row.description}>
                    <div>{value.row.description && value.row.description.length > 30 ?
                        `${value.row.description.substring(0, Math.min(value.row.description.length, 30) - 3)}...`
                        : value.row.description} </div>
                </Tooltip>
            </>;
        }} {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={filtersExceptions} editorComponent={NoFilterEditor} />,
        <NormalTypeProvider for={['currency']} editorComponent={CurrencyFilterEditor} />,
        <UserEmailTypeProvider for={['description']} />,
    ];

    return (
        <Surface title={translate("refunds." + view + ".title_view") as string}
            icon={getIcon()} 
            className="PaperPagination"
            titleActions={getActions()}>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={gridProps.columns ? gridProps.columns : []}
                columnsFormat={gridProps.columnsFormat}
                clickRowColumns={gridProps.clickRowColumns}
                selectionIds={selected}
                customFormatColumns={customPlugins}
                onClickRow={onClickedRow}
                onClickedOptions={onClickedOptions}
                setSelectionHandler={setSelectionHandler}
                defaultExpandedGroups={gridProps.defaultExpandedGroups}
                amountCurrencyColumns={gridProps.amountCurrencyColumns}
                dateColumns={gridProps.dateColumns}
                leftColumns={gridProps.leftColumns}
                rightColumns={gridProps.rightColumns}
                textColumns={gridProps.textColumns}
                filters={filters}
                grouping={gridProps.grouping}
                setFiltersHandler={setFiltersHandler}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
            />
            {anchorEl && refund && <RefundMenu
                anchor={anchorEl}
                refundId={refund.id}
                onClose={onCloseOption}
                onEdit={onEditRefund}
                onDelete={showDeleteDialog}
                onCancel={onShowCancelRefund}
                onSend={onShowSendRefund}
                view={view}
                fromDetail={false}
            />}
            {openConfirmDelete && refund &&
                <ConfirmationPopup
                    title={translate("refunds.confirm_delete.title") as string}
                    message={translate("refunds.confirm_delete.message", { number: refund.identifier }) as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDelete}
                    doAction={onDeleteRefund}
                />
            }
            {showRefundForm &&
                <RefundFormPopup refund={refund} onSuccess={onSave} onCancel={onCloseRefundForm} />
            }
            {context && refund && openSendRefund &&
                <UpdateStatusRefundPopup
                    cfdi={refund}
                    tenantId={context.session!.tenant!.id}
                    isCommentRequired={openSendRefund === "cancel"}
                    action={openSendRefund}
                    doAction={openSendRefund === "send" ? onSendRefund : onCancelRefund}
                    onClose={onCloseUpdateRefund}
                />
            }
            {exportMode && (
                <ConnectorObjectExporter tenantId={context.session!.tenant!.id}
                    family={Family.REFUNDS}
                    type={"DATA"}
                    onExport={downloadRefundsWithConnector}
                    onClose={onCloseExporter} 
                />
            )}

            {exportResult && exportResult.url && (
                <ExportPopup title={translate("refunds.export.title") as string}
                    message={translate("refunds.export.total_export", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownload} />
            )}

            {gridAnchorEl && (
                <RefundMenuOptions
                    anchor={gridAnchorEl}
                    totalItems={data?.total}
                    onClose={() => setGridAnchorEl(null)}
                    onExportWithParams={onExportWithParams}
                    onExportSelected={onExportSelected}
                />
            )}

            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Surface >
    );
}
