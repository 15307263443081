import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";

import { User, EmailRequest } from "../model/User";
import { getUserByEmail } from "../api/UserAPI";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput from "../components/ValidatedInput";
import { EmailValidator } from "../components/Validators";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";

interface SearchByEmailPopupProps {
    onSuccess(user : User): any;
    onClose(): any;
}

export default function SearchByEmailPopup(props: SearchByEmailPopupProps) {
    const emailValidator = new EmailValidator();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<EmailRequest>({ email: "" } as EmailRequest);
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    
    const onSearchEmail = () => {
        if(request.email === ""){
            setWarning(translate("users.impersonalize.warning_empty_email") as string);
            return;
        }
        setSubmitting(true);
        getUserByEmail(request).then((response) => {
            setSubmitting(false);
            props.onSuccess(response);
        }).catch((error) => {
            setSubmitting(false);
            setError(translate("validations.email") as string);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string) => {
        setRequest({ ...request, [name]: value });
    };

    return (
        <DialogPopup open
            title={translate("users.impersonalize.title") as string}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onSearchEmail} variant="outlined" color="primary" disabled={submitting || request.email === ""}>
                    {translate("buttons.search")}
                </Button>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                    {translate("users.impersonalize.email_description")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="email" name="email" disabled={submitting}
                        value={request.email}
                        required={true} 
                        type="text"
                        validator={emailValidator}
                        margin={"dense"}
                        label={translate("users.impersonalize.email_input") as string}
                        onValueChanged={hasChanged}/>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}