import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddTwoTone";

import translate from "../i18n/Translator";
import { WarehouseDelivery } from "../model/WarehouseDelivery";
import { AppContext } from "../context/AppContext";

interface WarehouseDeliveryMenuProps {
    warehouseDelivery: WarehouseDelivery;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function WarehouseDeliveryMenu(props: WarehouseDeliveryMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button component={Link} to={`/warehouse-deliveries/${props.warehouseDelivery.id}/cfdi`} onClick={props.onClose} dense
                disabled={props.warehouseDelivery.status !== "O" || !context.isGranted("ProviderWarehouseDeliveriesUpdate")}>
                <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("purchase_orders.add_cfdi")}</Typography>
            </MenuItem>
        </Menu>
    );

}