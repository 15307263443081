import { callAPI, HTTPMethod } from "./API";
import { ZipUploads, ZipUpload, ZipUploadsQueryParams, ZipUploadRequest } from "../model/ZipUpload";

const basePath = (tenantId: string) => `/tenants/${tenantId}/zip-uploads`;

export async function listZipUploads(tenantId: string, page: number, offset: number, params: ZipUploadsQueryParams): Promise<ZipUploads> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createZipUpload(tenantId: string, file: File): Promise<ZipUpload> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getZipUpload(tenantId: string, id: string): Promise<ZipUpload> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateZipUpload(tenantId: string, id: string, request: ZipUploadRequest): Promise<ZipUpload> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteZipUpload(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
