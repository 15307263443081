import React, { useContext } from "react";
import { Grid, List, ListItem, ListItemText, ListItemIcon, Card, Box } from "@material-ui/core";


import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Issuer, Receptor } from "../model/Cfdi";
import { ApartmentIcon, HomeWorkIcon } from "../components/Icons";

interface PayableDocumentIssuerWidgetProps {
    issuer: Issuer;
    tax_id?: string;
    receptor?: Receptor;
}

export default function PayableDocumentIssuerWidget(props: PayableDocumentIssuerWidgetProps) {
    const context = useContext(AppContext);

    return (
        <Card variant="outlined">
            <Box pl={2} pt={2} pb={2}>
                <Grid container alignItems="center" alignContent="center">
                    <Grid item xs={12}>
                        <strong>{translate("cfdis.details.emitter")}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="nav" dense>
                            <ListItem>
                                <ListItemIcon>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={props.issuer.name}
                                    secondary={props.tax_id ? props.tax_id : props.issuer.rfc} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" alignContent="center">
                    <Grid item xs={12}>
                        <strong>{translate("cfdis.details.receiver")}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <List component="nav" dense>
                            <ListItem>
                                <ListItemIcon>
                                    <HomeWorkIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={props.receptor?.nombre || context.session!.tenant!.name}
                                    secondary={props.receptor?.rfc || context.session!.tenant!.rfc} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}