import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import translate from "../i18n/Translator";
import { Entity as Provider } from "../model/Provider";
import { AppContext } from "../context/AppContext";
import PostAddIcon from '@material-ui/icons/PostAdd';
import FormatLineSpacingTwoToneIcon from '@material-ui/icons/FormatLineSpacingTwoTone';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';

interface ProviderReceptionMenuProps {
    provider: Provider;
    anchor: HTMLElement | null;
    onClose(): any;
    onSelectReceptor(): any;
    onSecondAuthorizator(): any;
    onSelectSpecificRule(): any;
    onRemoveSpecificRule(): any;
    onManageWorkflows(): any;
    isRestrictAssignReceptors: boolean;

}

export default function ProviderReceptionMenu(props: ProviderReceptionMenuProps) {
    const context = useContext(AppContext);
    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("AdminCFDIsUpdate") && !props.isRestrictAssignReceptors &&
                <MenuItem button onClick={props.onSelectReceptor} dense>
                    <ListItemIcon>
                        <GroupAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_receptor")}</Typography>
                </MenuItem>
            }
            {context.isGranted("CfdisSecondAuthorizationUpdate") &&
                <MenuItem button onClick={props.onSecondAuthorizator} dense>
                    <ListItemIcon>
                        <GroupAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_second_authorizator")}</Typography>
                </MenuItem>
            }
            {context.isGranted("WorkflowsUpdate") &&
                <MenuItem button onClick={props.onManageWorkflows} dense>
                    <ListItemIcon>
                        <FormatLineSpacingTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.manage_workflows")}</Typography>
                </MenuItem>
            }
            {context.isGranted("SpecificReceptionRuleUpdate") && !props.provider.specific_rule &&
                <MenuItem button onClick={props.onSelectSpecificRule} dense>
                    <ListItemIcon>
                        <PostAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_specific_rule")}</Typography>
                </MenuItem>
            }
            {context.isGranted("SpecificReceptionRuleUpdate") && props.provider.specific_rule &&
                <MenuItem button onClick={props.onRemoveSpecificRule} dense>
                    <ListItemIcon>
                        <ClearTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.remove_specific_rule")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 