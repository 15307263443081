import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ContributionToValidate } from "../model/Contribution";
import ApproveIcon from "@material-ui/icons/ThumbUpTwoTone";
import RefuseIcon from "@material-ui/icons/ThumbDownTwoTone";

interface ContributionToValidateMenuProps {
    contribution: ContributionToValidate;
    anchor: HTMLElement | null;
    onClose(): any;
    onApprove(complementary: boolean): any;
    onRefuse(complementary: boolean): any;
    onApproveVoucher(complementary: boolean): any;
    onRefuseVoucher(complementary: boolean): any;
    onApproveSocial(): any;
    onRefuseSocial(): any;
    onApproveSocialSipare(): any;
    onRefuseSocialSipare(): any;
}

export default function ContributionToValidateMenu(props: ContributionToValidateMenuProps) {
    const context = useContext(AppContext);
    const canApprove = context.isGranted("TenantContributionsUpdate") && props.contribution.status === "TO_VALIDATE";
    const isSocialVoucher = "SOCIAL_VOUCHER" === props.contribution.type;
    const isSocialSipare = "SIPARE" === props.contribution.type;
    const isComplementaryVoucher = "SAT_COMPLEMENTARY_VOUCHER" === props.contribution.type;

    const onApprove = () => {
        switch(props.contribution.type){
            case "SOCIAL_VOUCHER":
                props.onApproveSocial();
            break;
            case "SAT_VOUCHER":
                props.onApproveVoucher(false);
            break;
            case "SAT_COMPLEMENTARY_VOUCHER":
                props.onApproveVoucher(true);
            break;
            case "SAT_PAYMENT": 
                props.onApprove(false);
            break;
            case "SAT_COMPLEMENTARY_PAYMENT": 
                props.onApprove(true);
            break;
            case "SIPARE":
                props.onApproveSocialSipare();
            break;
            default:
            break;
        }
    };

    const onRefuse = () => {
        switch(props.contribution.type){
            case "SOCIAL_VOUCHER":
                props.onRefuseSocial();
            break;
            case "SAT_VOUCHER":
                props.onRefuseVoucher(false);
            break;
            case "SAT_COMPLEMENTARY_VOUCHER":
                props.onRefuseVoucher(true);
            break;
            case "SAT_PAYMENT": 
                props.onRefuse(false);
            break;
            case "SAT_COMPLEMENTARY_PAYMENT": 
                props.onRefuse(true);
            break;
            case "SIPARE":
                props.onRefuseSocialSipare();
            break;
            default:
            break;
        }
    };

    return (
        <Menu
            id="contributions"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem
                button
                onClick={props.onClose}
                dense
                component="a"
                href={props.contribution.url || "#"}
                target="_blank"
                rel="noopener noreferrer"
                disabled={false}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.download")}</Typography>
            </MenuItem>
            {canApprove && <>
                <MenuItem
                    button 
                    onClick={onApprove}
                    dense>
                    <ListItemIcon>
                        <ApproveIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.accept")}</Typography>
                </MenuItem>
                <MenuItem
                    button
                    onClick={onRefuse}
                    dense>
                    <ListItemIcon>
                        <RefuseIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.reject")}</Typography>
                </MenuItem>
            </>}
        </Menu>
    );

}