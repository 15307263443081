import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import ReloadIcon from "@material-ui/icons/ReplayTwoTone";
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';

interface TenantMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDeleteCfdis(): any;
    onDeleteCfdiByUuid(): any;
    onRevalidateSAT(): any;
    onBackwardPendingPayment(): any;
}

export default function TenantsMenu(props: TenantMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("TechnicalSupportUpdate") &&
                <MenuItem button onClick={props.onBackwardPendingPayment} dense>
                    <ListItemIcon>
                        <ReloadIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("tenants.backward_pending_payment.backward_by_uuid")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TechnicalSupportDelete") &&
                <MenuItem button onClick={props.onDeleteCfdis} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("tenants.delete_cfdis")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TechnicalSupportDelete") &&
                <MenuItem button onClick={props.onDeleteCfdiByUuid} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">{translate("tenants.delete_cfdis.delete_by_uuid")}</Typography>
                </MenuItem>
            }
            <MenuItem button onClick={props.onRevalidateSAT} dense>
                <ListItemIcon>
                    <CheckCircleTwoToneIcon fontSize="small" color="error" />
                </ListItemIcon>
                <Typography variant="inherit" color="error">{translate("tenants.revalidate_sat.title")}</Typography>
            </MenuItem>
        </Menu>
    );

}