import React, { useState, useEffect } from "react";
import { Grid, Button,  Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { RouterParams } from "../router/RouterParams";
import { resetPassword } from "../api/AuthAPI";
import { ResetPasswordRequest } from "../model/ResetPassword";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import ExternSurface from "../components/ExternSurface";
import { validateUserStatus } from "../api/AuthAPI";

export default function ResetPasswordForm({ match }: RouterParams) {
    const [redirect, setRedirect] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [succcess, setSuccess] = useState("");
    const [request, setRequest] = useState<ResetPasswordRequest>({
        user_id: "",
        old_password: "",
        new_password: "",
        confirm_password: ""
    } as ResetPasswordRequest);
    const [validations, setValidations] = useState({
        "old_password": {
            valid: false,
        } as InputRef,
        "password": {
            valid: false
        } as InputRef,
        "confirm_password": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        validateUserStatus(match.params.code).then((response) => {
        }).catch((error) => {
            setError(error.message);
        });
    }, [match.params.code]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field] as InputRef;
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setLoading(true);
        resetPassword(match.params.code, request).then(() => {
            setSuccess(translate("reset_password.success") as string);
            setRequest({} as ResetPasswordRequest);
            for (let field in validations) {
                let ref = validations[field];
                ref.blurer(false);
            }
            setRedirect(`/signin`);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    const onClosedError = () => {
        setError("");
        setRedirect('');
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }
    return (
        <ExternSurface title="">
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center" paragraph>
                            {translate("reset_password.title") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" align="center" paragraph>
                            {translate("reset_password.subtitle") as string}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="password" id="old_password" name="old_password"
                            label={translate("reset_password.old_pass_label") as string}
                            required={true} disabled={loading}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="password" id="password" name="password"
                            label={translate("reset_password.new_pass_label") as string}
                            required={true} disabled={loading}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="password" id="confirm_password" name="confirm_password"
                            label={translate("reset_password.confirm_pass_label") as string}
                            required={true} disabled={loading}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12} className="ExternButton">
                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={loading}>
                            {translate("reset_password.change_button_label")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedError} />
            <SuccessSnackbar message={succcess} onClose={onClosedSnackbar} />
        </ExternSurface>
    );
}
