import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Typography, Tabs, Tab, IconButton, Grid, Box, Divider, Fab, Badge, Button } from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import translate from "../i18n/Translator";
import { Entity, AssignValidatorResponse, LedgerAssignment } from "../model/Provider";
import { expedientBadgets, ProviderExpedient, ProviderExpedientBankAccount, ProviderExpedientField } from "../model/ProviderExpedient";
import { Efos } from "../model/Efos";
import { getProvider, verifyEfo, verifyCff69 } from "../api/ProviderAPI";
import { getExpedient, validate, changeStatus, getFilesExpedient } from "../api/ProviderExpedientAPI";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import ProviderInfo from "./ProviderInfo";
import ProviderOverviewMenu from "./ProviderOverviewMenu";
import Surface from "../components/Surface";
import { WarningSnackbar, ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import ProviderVerificationPopup from "./ProviderVerificationPopup";
import AssignValidatorPopup from "./AssignValidatorPopup";
import { BlackListValidations } from "../model/Cff69";
import ProviderReceptorsPopup from "./ProviderReceptorsPopup";
import ProviderConfigurationLedger from "./ProviderConfigurationLedger";
import ExpedientProviderFields from "../expedients/ExpedientProviderFields";
import ExpedientProviderBankAccounts from "../expedients/ExpedientProviderBankAccounts";
import ExpedientProviderAddresses from "../expedients/ExpedientProviderAddresses";
import ExpedientProviderContacts from "../expedients/ExpedientProviderContacts";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import { ProviderExpedientStatusHeader } from "../expedients/ExpedientIcon";
import ProviderValidatePopup from "./ProviderValidatePopup";
import ProviderHistoryExpedientPopup from "./ProviderExpedientHistoryPopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import { HistoryIcon, DownloadIcon } from "../components/Icons";
import ProviderAssignPaymentBankAccountsPopup from "./ProviderAssignPaymentBankAccountsPopup";
import ProviderRejectPopup from "./ProviderRejectPopup";

interface ProviderTab {
    name: string;
    title: string;
    actions?: string[];
}

export default function ProviderOverview({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const [backTo, setBackTo] = useState<string>();
    const [badges, setBadges] = useState<any>({} as any);
    const [isRestrictAssignReceptors, setRestrictAssignReceptors] = useState<boolean>(false);
    const [hasPaymentOrdersModule, setHasPaymentOrdersModule] = useState<boolean>(false);
    const allTabs = [
        {
            name: "info",
            title: translate("providers.info" as string)
        },
        {
            name: "contacts",
            title: translate("providers.contacts" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "addresses",
            title: translate("expedients.addresses.title" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "bank_accounts",
            title: translate("expedients.bank_accounts.title" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "fields",
            title: translate("providers.validate.title" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "ledger-acounts",
            title: translate("providers.ledger-accounts.title" as string),
            actions: ["LedgerConfigurationCreate", "LedgerConfigurationUpdate"]
        }
    ] as ProviderTab[];

    const allTabsNoLedger = [
        {
            name: "info",
            title: translate("providers.info" as string)
        },
        {
            name: "contacts",
            title: translate("providers.contacts" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "addresses",
            title: translate("expedients.addresses.title" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "bank_accounts",
            title: translate("expedients.bank_accounts.title" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        },
        {
            name: "fields",
            title: translate("providers.validate.title" as string),
            actions: ["TenantFieldsRead", "ValidationExpedientRead", "VendorsExpedientRead"]
        }
    ] as ProviderTab[];


    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [status, setStatus] = useState<string>("loading");
    const [provider, setProvider] = useState<Entity>();
    const [metadata, setMetadata] = useState<ProviderExpedient>({} as ProviderExpedient);
    const [tabs, setTabs] = useState<ProviderTab[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [efos, setEfos] = useState<Efos>();
    const [blackListValidations, setBlackListValidations] = useState<BlackListValidations>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [value, setValue] = useState<number>(tabQs);

    const [validatorPopup, setValidatorPopup] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
    const [selectedProvidersToReceptor, setSelectedProvidersToReceptor] = useState<Entity[]>();
    const [selectedProvidersToSecondAuthorizator, setSelectedProvidersToSecondAuthorizator] = useState<Entity[]>();

    const [disabled, setDisabled] = useState(false);
    const [expiredAt, setExpiredAt] = useState<Date>();
    const [openHistoryPopup, setOpenHistoryPopup] = useState<boolean>(false);
    const [dialogValidate, setDialogValidate] = useState(false);
    const [redirect, setRedirect] = useState<string | undefined>();
    const [openReject, setOpenReject] = useState<boolean>(false);
    const [openAdminReject, setOpenAdminReject] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [ready, setReady] = useState(false);
    const [onEditExpedient, setOnEditExpedient] = useState<boolean>(false);
    const [openAssignPaymentAccountPopup, setOpenAssignPaymentAccountPopup] = useState<boolean>(false);

    const getDateExpired = (data: Map<string, ProviderExpedientField>) => {
        if (!data) {
            return;
        }
        var values = Object.values(data) as ProviderExpedientField[];
        let dateExpered = new Date();

        values.forEach((item) => {
            if (item.status === "EXPIRED" && item.configuration.expires_at != null && item.configuration.expires_at < dateExpered) {
                dateExpered = item.configuration.expires_at;
            }
        })
        setExpiredAt(dateExpered);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setValue(newValue);
    };

    const load = () => {
        setStatus("loading");
        if (match.params.success && isSuccess) {
            setSuccess(match.params.success);
            setIsSuccess(false);
        }
        Promise.all([
            getProvider(context.session!.tenant!.id, match.params.providerId),
            getTenantConfigurations(context.session!.tenant!.id)
        ]).then(([provider, tenantConfigurations]) => {
            if (provider.country == null) provider.country = "MEX";
            setProvider(provider);
            if (provider.ledger_assignment && !provider.ledger_assignment.use_allocation) {
                let userTabs = allTabsNoLedger.filter((tab) => !tab.actions || context.isGrantedAny(tab.actions));
                setTabs(userTabs);
            } else {
                let userTabs = allTabs.filter((tab) => !tab.actions || context.isGrantedAny(tab.actions));
                setTabs(userTabs);
            }
            loadReference(provider);
            if (context.isGrantedAny(["VendorsExpedientRead", "ValidationExpedientRead", "VendorsExpedientRead"])) {
                getExpedient(context.session!.tenant!.id, match.params.providerId).then((metadata) => {
                    setMetadata(metadata);
                    let badgets = expedientBadgets(metadata);
                    setBadges(badgets);
                    let allRequiredFields = (badgets.addresses + badgets.contacts + badgets.bank_accounts + badgets.fields) === 0;
                    setReady(allRequiredFields);

                    if (metadata.status === 'ACCEPTED') {
                        setDisabled(true);
                    } else if (metadata.status === 'EDITED' && !metadata?.expired) {
                        setDisabled(false);
                    } else if (metadata.status === 'EDITED') {
                        setDisabled(false);
                    }
                    setStatus("loaded");
                }).catch((error) => {
                    setStatus(error.message);
                });
            } else {
                setStatus("loaded");
            }

            const configuration = tenantConfigurations?.configurations?.find(cfg => cfg.property_name === "restrict_assign_receptors");
            setRestrictAssignReceptors(configuration !== undefined && configuration?.active && configuration.value === "true");
            setHasPaymentOrdersModule(tenantConfigurations.has_payment_orders);
        }).catch((error) => {
            setStatus(error.message);
        });

        getDateExpired(metadata.fields);
    };

    useEffect(load, [match.params.providerId]);

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line 
    }, [qs.tab, tabs]);

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onVerifyEfos = () => {
        if (!provider) return;

        setAnchorEl(null);
        verifyEfo(context.session!.tenant!.id, provider.id).then((response) => {
            setProvider({ ...provider, efos: response.efos });
            setEfos(response.efos);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const onAssignedValidator = (response: AssignValidatorResponse) => {
        setAnchorEl(null);
        setValidatorPopup(false);

        if (provider) {
            setProvider({ ...provider, validator_user: response.validator });
        }

        setSelectedProviders([]);
        setSuccess(translate("providers.assign_validator.success_single", { "validator": response.validator.name, "provider": provider?.name || "---" }) as string);
        load();
    };

    const onCloseUserValidator = () => {
        setAnchorEl(null);
        setValidatorPopup(false);
    };

    const onAddValidator = () => {
        if (!provider) return;
        setAnchorEl(null);
        setSelectedProviders([provider.id]);
        setValidatorPopup(true);
    };

    const onVerifyCff69 = () => {
        if (!provider) return;

        setAnchorEl(null);
        verifyCff69(context.session!.tenant!.id, provider.id).then((response) => {
            if (!response.black_list_validations) return;
            setProvider({ ...provider, black_list_validations: response.black_list_validations });
            setBlackListValidations(response.black_list_validations);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const openOneProviderReceptor = () => {
        setAnchorEl(null);
        if (!provider) return;

        setSelectedProvidersToReceptor([provider]);
    }

    const onSuccessProviderReceptor = (updated: number) => {
        setAnchorEl(null);
        setSelectedProvidersToReceptor(undefined);
        setSuccess(translate("providers.receptor.success", { "updated": updated }) as string);
        load();
    };

    const onSuccessProviderSecondAuthorizator = (updated: number) => {
        setAnchorEl(null);
        setSelectedProvidersToSecondAuthorizator(undefined);
        setSuccess(translate("providers.second_authorizator.success", { "updated": updated }) as string);
        load();
    };

    const onCloseProviderReceptor = () => {
        setAnchorEl(null);
        setSelectedProvidersToReceptor(undefined);
    };

    const onCloseProviderSecondAuthorizator = () => {
        setAnchorEl(null);
        setSelectedProvidersToSecondAuthorizator(undefined);
    };

    const onSuccessExpedientValidate = (message: string) => {
        setAnchorEl(null);
        setDialogValidate(false);
        setSuccess(message);
        load();
    };

    const onSuccessRejectExpedient = () => {
        setAnchorEl(null);
        setOpenAdminReject(false);
        load();
    };

    const onCloseExpedientValidate = () => {
        setAnchorEl(null);
        setDialogValidate(false);
    };

    const onCloseRejectPopup = () => {
        setAnchorEl(null);
        setOpenAdminReject(false);
    }

    const onChangeLedgerConfiguration = (response: LedgerAssignment) => {
        if (!provider) return;
        setProvider({ ...provider, ledger_assignment: response });
        let userTabs = allTabs.filter((tab) => !tab.actions || context.isGrantedAny(tab.actions));
        setTabs(userTabs);
    };

    const onChanged = (metadata: ProviderExpedient) => {
        let badgets = expedientBadgets(metadata);
        setBadges(badgets);
        let allRequiredFields = (badgets.addresses + badgets.contacts + badgets.bank_accounts + badgets.fields) === 0;
        setReady(allRequiredFields);
        setMetadata(metadata);
    }

    const onEdit = () => {
        setOnEditExpedient(true);
    }

    const onClickHistory = () => {
        if (metadata.has_data) {
            setOpenHistoryPopup(true);
        } else {
            setWarning(translate(`expedients.expediente_history_empty`) as string);
        }

    };

    const onCloseHistoryPopup = () => {
        setOpenHistoryPopup(false);
    }

    const onSelectHistoryExpedient = (id: string) => {
        setRedirect(`/providers/${provider!.id}/history-expedient/${id}`);
    }

    const confirmEditExpedient = () => {

        if (!provider) return;

        changeStatus(context.session!.tenant!.id, provider.id, "EDITED").then((response) => {
            setSuccess(translate(`expedients.status_metadata.${response.status}`) as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOnEditExpedient(false);
        });
    };

    const tabContent = (index: number) => {
        if (!provider) return;

        let current = tabs[index];
        if (!current) return;
        let isGrantedSpecialExpedientUpdate = context.isGranted('SpecialExpedientUpdate');

        let canEdit = !isGrantedSpecialExpedientUpdate && context.isGrantedAny(['ProvidersUpdate', 'ProvidersSelfUpdate', 'VendorsExpedientUpdate',]);

        switch (current.name) {
            case "info":
                return <ProviderInfo provider={provider} />;
            case "contacts":
                return <ExpedientProviderContacts metadata={metadata} onChange={onChanged} disabled={disabled || !canEdit} provider={provider} />;
            case "addresses":
                return <ExpedientProviderAddresses metadata={metadata} onChange={onChanged} disabled={disabled || !canEdit} provider={provider} />;
            case "bank_accounts":
                return <ExpedientProviderBankAccounts metadata={metadata} onChange={onChanged} disabled={disabled || !canEdit} provider={provider} />;
            case "fields":
                return <ExpedientProviderFields metadata={metadata} onChange={onChanged} disabled={disabled || !canEdit} provider={provider} />
            case "ledger-acounts":
                return <ProviderConfigurationLedger onChange={onChangeLedgerConfiguration} ledgerAssignment={provider.ledger_assignment} providerId={provider.id} tenantId={context.session!.tenant!.id} />;
            default:
                return <div></div>;
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined)
    };

    const loadReference = (entity: Entity) => {
        let referer = typeof qs["referer"] === "string" ? qs["referer"] as string : undefined;
        switch (referer) {
            case "efos":
                setBackTo("/providers/efos?" + queryString.stringify(qs));
                break;
            case "expedients":
                setBackTo("/providers/expedients?" + queryString.stringify(qs));
                break;
            default:
                setBackTo((entity.type !== "VENDOR" ? "/providers?" : "/vendors?") + queryString.stringify(qs));
        }
    };

    const onCloseVerificationPopup = () => {
        setEfos(undefined);
        setBlackListValidations(undefined);
    };

    const onEditProvider = () => {
        let url = window.location.pathname;
        history.push(url);
        setRedirect(`/providers/${provider!.id}/edit?` + queryString.stringify(qs));
    };

    const onValidate = () => {
        if (!provider) {
            return;
        }
        validate(context.session!.tenant!.id, provider.id).then(() => {
            setDialogValidate(true)
        }).catch((error) => {
            setOpenReject(true);
        });
    };

    const onClickRejectExpedient = () => {
        if (!provider) {
            return;
        }
        setOpenAdminReject(true)
    }

    const onReject = () => {
        if (!provider) {
            return;
        }
        changeStatus(context.session!.tenant!.id, provider.id, "REJECTED").then((response) => {
            setSuccess(translate(`expedients.status_metadata.${response.status}`) as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenReject(false);
        });
    };

    const downloadFiles = () => {
        getFilesExpedient(context.session!.tenant!.id, provider!.id).then(response => {
            window.open(response.url, "_blank");
        }).catch((error) => {
            setError(translate(`expedients.not_files_in_expedient`) as string);
        });
    };

    const getEnableEditButton = () => {
        if (metadata.status === 'ACCEPTED') {
            return true;
        } else if (metadata.status === 'EDITED' && !metadata?.expired) {
            return false;
        }
    }

    const buildPaymentBankAccountsLabel = (): string => {
        return Object.entries(metadata.payment_bank_accounts!).map(([currency, account]) => `${account?.data?.value?.bank_name ?? (account?.data?.value?.bank ?? " - ")} - ${currency}`).join(", ");
    }

    const onClickAssignPaymentAccountPopup = () => {
        setOpenAssignPaymentAccountPopup(true);
    }

    const onCloseAssignPaymentAccountPopup = () => {
        setOpenAssignPaymentAccountPopup(false);
    }

    const onSubmitAssignPaymentAccountPopup = (expedient: ProviderExpedient) => {
        setMetadata(expedient);
        setSuccess(translate("expedients.payment_bank_accounts.popup.success") as string);
        setOpenAssignPaymentAccountPopup(false);
    }

    const onErrorAssignPaymentAccountPopup = (error: any) => {
        setError(error.message);
        setOpenAssignPaymentAccountPopup(false);
    }


    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !provider) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Surface title={provider.name}
            subtitle={provider.rfc} backButton
            backTo={backTo}
            icon={<MoreVertIcon />}
            className="PaperPagination PaperTabContainer"
            titleActions={
                <Grid container alignItems="center" justify="flex-end" spacing={1}>

                    {context.isGrantedAny(["ProvidersUpdate", "VendorsUpdate"]) ?
                        (<Grid item xs="auto">
                            <Fab aria-label="add" color="primary" size="small" title={translate("buttons.edit") as string} onClick={onEditProvider}>
                                <EditIcon />
                            </Fab>
                        </Grid>) : undefined}
                    {provider.type !== "VENDOR" && context.isGrantedAny(["ProvidersUpdate", "EfosUpdate", "Cff69Update", "OwnerProvidersUsersRead", "VendorsUpdate"]) ?
                        <Grid item xs="auto">
                            <IconButton color="default" onClick={onClickedMore}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid> : undefined}
                </Grid>
            }>
            <Tabs value={value} onChange={handleChange} aria-label={translate("providers.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: ProviderTab, index: number) => (
                    <Tab key={tab.name} label={
                        <Badge variant="dot" color="error" invisible={!badges[tab.name] || badges[tab.name] <= 0} >
                            {tab.title}
                        </Badge>
                    } {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                {(tabs[value].name !== "info") ?
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Box pt={2} px={2}>
                            <Grid container justify="space-between" alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    <ProviderExpedientStatusHeader short={false}
                                        status={metadata.status || (metadata.has_data ? "TO_SEND" : "MISSING")}
                                        approved={metadata.approved}
                                        lastReview={metadata.last_review}
                                        expired_at={expiredAt}
                                        expired={"ACCEPTED" === metadata?.status ? metadata.expired : false}
                                        button={context.isGrantedAny(["ProvidersUpdate", "OwnerProvidersUsersRead", "VendorsUpdate", "ValidationExpedientUpdate"]) ?
                                            (
                                                <Box mr={2} mt={1} >
                                                    <Grid container justify="flex-end" spacing={1}>
                                                        <Grid item xs="auto">
                                                            <Button
                                                                color="primary"
                                                                variant="text"
                                                                startIcon={<HistoryIcon />}
                                                                onClick={onClickHistory}>
                                                                {translate("expedients.check_history" as string)}
                                                            </Button>
                                                        </Grid>

                                                        <Grid item xs="auto">
                                                            <Button
                                                                color="primary" variant="text"
                                                                onClick={downloadFiles}
                                                                startIcon={<DownloadIcon />}>
                                                                {translate("expedients.download" as string)}
                                                            </Button>
                                                        </Grid>

                                                        {(context.isGranted("ValidationExpedientUpdate") && (metadata.status === 'ACCEPTED' || metadata?.expired) && getEnableEditButton())
                                                            && <Grid item xs="auto">
                                                                <Button color="primary" variant="contained" onClick={onEdit} disabled={!(metadata.status === 'ACCEPTED' || metadata?.expired)} >
                                                                    {translate("expedients.edit_expediente" as string)}
                                                                </Button>
                                                            </Grid>
                                                        }

                                                        {(context.isGranted("ValidationExpedientUpdate") && metadata.status != 'ACCEPTED')
                                                            && <Grid item xs="auto">
                                                                <Button color="primary" variant="contained" onClick={onValidate} disabled={!ready} >
                                                                    {translate("expedients.validate_expediente" as string)}
                                                                </Button>
                                                            </Grid>}
                                                        {(context.isGranted("ExpedientRejectApproved") && metadata.status === 'ACCEPTED')
                                                            && <Grid item xs="auto">
                                                                <Button color="primary" variant="contained" onClick={onClickRejectExpedient} >
                                                                    {translate("expedients.reject" as string)}
                                                                </Button>
                                                            </Grid>}
                                                    </Grid>
                                                </Box>
                                            ) : undefined}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {(tabs[value].name == "bank_accounts" && hasPaymentOrdersModule && Object.entries(metadata.bank_accounts).length > 1) ?
                            <Box pt={2} px={2}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs="auto">
                                        <Typography variant="body1">
                                            {translate("expedients.payment_bank_accounts.title" as string) + ':'}
                                        </Typography>
                                    </Grid>
                                    {Object.entries(metadata.payment_bank_accounts!).length > 0 &&
                                        <Grid item xs="auto">
                                            <Button
                                                disabled={disabled || (!context.isGrantedAny(["ProvidersUpdate", "OwnerProvidersUsersRead", "VendorsUpdate"]))}
                                                color="primary"
                                                variant="text"
                                                onClick={onClickAssignPaymentAccountPopup}
                                                endIcon={<EditIcon />}
                                            >
                                                {buildPaymentBankAccountsLabel()}
                                            </Button>
                                        </Grid>
                                    }
                                    {(!metadata.payment_bank_accounts || Object.entries(metadata.payment_bank_accounts!).length == 0) &&
                                        <Grid item xs="auto">
                                            <Typography variant="body1">
                                                {translate("expedients.payment_bank_accounts.empty" as string)}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box> : undefined
                        }
                        <Box mt={2}>
                            <Divider />
                        </Box>
                    </Grid> : undefined
                }
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: ProviderTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                    <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
                    <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
                    {anchorEl && provider && (
                        <ProviderOverviewMenu
                            anchor={anchorEl}
                            provider={provider}
                            onVerifyEfos={onVerifyEfos}
                            onVerifyCff69={onVerifyCff69}
                            onClose={() => setAnchorEl(null)}
                            onAssignValidator={onAddValidator}
                            onSelectReceptor={openOneProviderReceptor}
                            isRestrictAssignReceptors={isRestrictAssignReceptors}
                        />
                    )}
                    {(efos || blackListValidations) && (
                        <ProviderVerificationPopup
                            name={provider.name}
                            rfc={provider.rfc}
                            efos={efos}
                            validations={blackListValidations}
                            onClose={onCloseVerificationPopup} />
                    )}
                    {validatorPopup && (
                        <AssignValidatorPopup tenantId={context.session!.tenant!.id} providers={selectedProviders} onSuccess={onAssignedValidator} onClose={onCloseUserValidator} />
                    )}
                    {selectedProvidersToReceptor && (
                        <ProviderReceptorsPopup
                            isSecondAuthorizator={false}
                            tenantId={context.session!.tenant!.id}
                            providers={selectedProvidersToReceptor}
                            onSuccess={onSuccessProviderReceptor}
                            onClose={onCloseProviderReceptor} />
                    )}
                    {selectedProvidersToSecondAuthorizator && (
                        <ProviderReceptorsPopup
                            isSecondAuthorizator={true}
                            tenantId={context.session!.tenant!.id}
                            providers={selectedProvidersToSecondAuthorizator}
                            onSuccess={onSuccessProviderSecondAuthorizator}
                            onClose={onCloseProviderSecondAuthorizator} />
                    )}
                    {dialogValidate && (
                        <ProviderValidatePopup
                            tenantId={context.session!.tenant!.id}
                            providerId={provider!.id}
                            providerName={provider!.name}
                            providerRfc={provider!.rfc}
                            providerExternalId={provider!.external_id}
                            metadata={metadata}
                            isFromReviewersView={false}
                            onSuccess={onSuccessExpedientValidate}
                            onClose={onCloseExpedientValidate} />
                    )}
                    {openHistoryPopup &&
                        <ProviderHistoryExpedientPopup
                            onClose={onCloseHistoryPopup}
                            onSelectExpedient={onSelectHistoryExpedient}
                            tenantId={context.session!.tenant!.id}
                            providerId={provider.id}
                        />
                    }
                    {openReject && (
                        <ConfirmationPopup
                            doAction={onReject}
                            onClose={() => setOpenReject(false)}
                            title={translate("providers.incomplete_fields.title") as string}
                            message={translate("providers.incomplete_fields.message") as string}
                            button={translate("buttons.accept") as string}
                        />
                    )}
                    {onEditExpedient && (
                        <ConfirmationPopup
                            doAction={confirmEditExpedient}
                            onClose={() => setOnEditExpedient(false)}
                            title={translate("expedients.tenant_edit.title") as string}
                            message={translate("expedients.tenant_edit.text") as string}
                            button={translate("buttons.accept") as string}
                        />
                    )}
                    {openAssignPaymentAccountPopup &&
                        <ProviderAssignPaymentBankAccountsPopup
                            tenantId={context.session!.tenant!.id}
                            providerId={provider.id}
                            onClose={onCloseAssignPaymentAccountPopup}
                            expedient={metadata}
                            onError={onErrorAssignPaymentAccountPopup}
                            onSubmit={onSubmitAssignPaymentAccountPopup} />
                    }
                    {openAdminReject && (
                        <ProviderRejectPopup
                            tenantId={context.session!.tenant!.id}
                            providerId={provider!.id}
                            providerName={provider!.name}
                            providerRfc={provider!.rfc}
                            providerExternalId={provider!.external_id}
                            metadata={metadata}
                            onSuccess={onSuccessRejectExpedient}
                            onClose={onCloseRejectPopup} />
                    )}
                </Grid>
            </Grid>
        </Surface>
    );
}
