import React, { useState, useContext } from "react";
import { Grid, Button, Link, Typography } from "@material-ui/core";
import { Link as RouterLink, Redirect } from "react-router-dom";

import { signin } from "../api/AuthAPI";
import { SignInRequest } from "../model/SignIn";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { ErrorSnackbar } from "../components/Snackbars";
import translate from "../i18n/Translator";
import ExternSurface from "../components/ExternSurface";
import { RouterParams } from "../router/RouterParams";

export default function SignInForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState(match.params.message ?? "");
    const [redirect, setRedirect] = useState<string | undefined>();
    const [request, setRequest] = useState<SignInRequest>({
        email: "",
        password: ""
    } as SignInRequest);
    const [validations, setValidations] = useState({
        "email": {
            valid: false
        } as InputRef,
        "password": {
            valid: false
        } as InputRef
    } as any);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });

        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setLoading(true);
        signin(request).then((response) => {
            switch (response.status) {
                case "SIGNED_IN":
                    context.onSignedIn();
                    break;
                case "TENANT_VERIFICATION":
                    setRedirect(`/signup/${response.tenant_signup_code!}`);
                    break;
                case "REQUIRED_2FA":
                    setRedirect(`/signin/${response.signin_2fa_code!}`);
                    break;
                case "PASSWORD_EXPIRED":
                    context.userSecurity = { email: request.email, password_expiration_days: response.password_expiration_days };
                    setRedirect(`/password-expired`);
                    break;
                default:
                    break;
            }
        }).catch((error) => {
            setLoading(false);
            setError(error.message);
        });
    }

    const onClosedSnackbar = () => {
        setError("");
    };

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <ExternSurface title={translate("auth.signin.title") as string}>
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="email" id="email" name="email"
                            value={request.email} label={translate("users.email") as string}
                            required={true} disabled={loading}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput type="password" id="password" name="password"
                            value={request.password} label={translate("users.password") as string}
                            required={true} disabled={loading}
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12} className="ExternButton">
                        <Button type="submit" variant="contained" color="primary" fullWidth size="large" disabled={loading}>
                            {translate("buttons.signin")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="space-between" alignItems="flex-start">
                            <Grid item >
                                <a href={"https://partners.amazonaws.com/partners/0010h00001jEXogAAG/Focaltec#solutions--tech-product"} target="_blank" rel="noopener noreferrer" >
                                    <img src="/qualified_software.png" alt="Aws Qualified Software" className="QualifiedSoftware" />
                                </a>
                            </Grid>

                            <Grid item >
                                <Typography align="right">
                                    <Link component={RouterLink} to="/recover-password">
                                        <small>{translate("buttons.recover")}</small>
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </ExternSurface>
    );

}
