import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, Button, Divider, Box, Typography } from "@material-ui/core";

import { GatewayConfigurationResponse } from "../model/Billing";
import translate from "../i18n/Translator";
import OpenpayToken, { TokenRequest, getOpenpay, TokenResponse, OpenpayBadges, OpenpayErrorResponse } from "./OpenpayToken";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { InputValidator } from "../components/Validators";
import { ErrorSnackbar } from "../components/Snackbars";
import SimpleSwitch from "../components/SimpleSwitch";
import { subscribe } from "../api/TenantsBillingAPI";
import { BillingSubscriptionRequest } from "../model/Billing";
import CustomBackdrop from "../components/CustomBackdrop";

interface OpenpayCardFormProps {
    tenantId: string;
    tos: string;
    gateway: GatewayConfigurationResponse;
    onSuccess(): any;
}

export default function OpenpayCardForm(props: OpenpayCardFormProps) {
    const now = moment();
    const margin = "dense";
    const cardValidator = new CardValidator();
    const [yearValues, setYearValues] = useState<string[]>([]);
    const [yearLabels, setYearLabels] = useState<string[]>([]);
    const [monthValues, setMonthValues] = useState<string[]>([]);
    const [monthLabels, setMonthLabels] = useState<string[]>([]);
    const [request, setRequest] = useState<TokenRequest>({
        cvv2: "",
        expiration_month: now.get("month") + 1,
        expiration_year: now.get("year") - 2000,
    } as TokenRequest);
    const [submitting, setSubmitting] = useState(false);
    const [cvvValidator, setCvvValidator] = useState<CvvValidator>();
    const [deviceId, setDeviceId] = useState<string>();
    const [accepted, setAccepted] = useState(false);
    const [error, setError] = useState<string>();
    const [format, setFormat] = useState<string>("");
    const [validations, setValidations] = useState({
        "holder_name": {
            valid: false
        } as InputRef,
        "card_number": {
            valid: false
        } as InputRef,
        "cvv2": {
            valid: false
        } as InputRef,
        "expiration_month": {
            valid: false
        } as InputRef,
        "expiration_year": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        let from = now.get("year");
        let values = [] as string[];
        let labels = [] as string[];

        for (var i = 0; i < 25; i++) {
            labels.push((from + i).toString());
            values.push((from + i - 2000).toString());
        }

        setYearValues(values);
        setYearLabels(labels);
        // eslint-disable-next-line
    }, [])

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        if (name === "card_number") {
            if (value.startsWith("3")) {
                setFormat("####  ######  #####");
            } else {
                setFormat("####  ####  ####  ####");
            }
            setCvvValidator(new CvvValidator(value));
        }

        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedYear = (name: string, value: string, inputRef: InputRef) => {
        let year = +value;
        setRequest({ ...request, [name]: year });

        validations[name] = inputRef;
        setValidations(validations);

        var indexMonth = 0;
        if ((year + 2000) === now.get("year")) {
            indexMonth = now.get("month");
        }

        let months = [] as string[];
        for (var i = indexMonth; i < 12; i++) {
            months.push(`${i + 1}`);
        }

        setMonthValues(months);
        setMonthLabels(months.map((m) => translate(`months.${m}`) as string));

        if (request.expiration_month < (indexMonth + 1)) {
            setRequest({ ...request, expiration_month: (indexMonth + 1) });
        }
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field] as InputRef;
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onOpenpayLoaded = (deviceId?: string) => {
        setDeviceId(deviceId);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let openpay = getOpenpay();
        if (!openpay || !openpay.token || !isValid()) {
            return;
        }

        let payload = JSON.parse(JSON.stringify(request)) as TokenRequest;
        payload.card_number = (payload.card_number || "").replace(/\s/g, "");

        setSubmitting(true);
        openpay.token.create(payload, (response: TokenResponse) => {
            let subscriptionRequest = {
                token_id: response.data.id
            } as BillingSubscriptionRequest;

            subscribe(props.tenantId, subscriptionRequest).then(() => {
                props.onSuccess();
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
        }, (error: OpenpayErrorResponse) => {
            if (error.data && error.data.error_code) {
                setError(translate(`openpay_errors.${error.data.error_code}`) as string);
            } else {
                setError(translate(`openpay_errors.connection`) as string);
            }
            setSubmitting(false);
        });
    };

    return (
        <div>
            <OpenpayToken
                merchantId={props.gateway.merchant_id}
                publicKey={props.gateway.public_key}
                sandbox={props.gateway.sandbox}
                onLoaded={onOpenpayLoaded} />
            <form autoComplete="off" noValidate onSubmit={onSubmit} >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput type="text" id="holder_name" name="holder_name"
                            value={request.holder_name} label={translate("payments.form.holder_name") as string}
                            required={true} disabled={submitting} margin={margin}
                            onValueChanged={hasChanged} />
                    </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={8}>
                        <ValidatedInput type="text" id="card_number" name="card_number"
                            value={request.card_number} label={translate("payments.form.card_number") as string}
                            required={true} disabled={submitting} margin={margin}
                            validator={cardValidator}
                            format={format} mask=" "
                            onValueChanged={hasChanged} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ValidatedInput type="text" id="cvv2" name="cvv2"
                            value={request.cvv2} label={translate("payments.form.cvv2") as string}
                            required={true} disabled={submitting} margin={margin}
                            validator={cvvValidator}
                            onValueChanged={hasChanged} />
                    </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="expiration_year" name="expiration_year"
                            value={`${request.expiration_year}`} label={translate("payments.form.expiration_year") as string}
                            options={yearValues} optionLabels={yearLabels}
                            required={true} disabled={submitting} margin={margin}
                            onValueChanged={hasChangedYear} />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput type="text" id="expiration_month" name="expiration_month"
                            value={`${request.expiration_month}`} label={translate("payments.form.expiration_month") as string}
                            options={monthValues} optionLabels={monthLabels}
                            required={true} disabled={submitting} margin={margin}
                            onValueChanged={hasChanged} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SimpleSwitch
                            value="tos" checked={accepted} label={props.tos} placement="end"
                            onChanged={(name: string, checked: boolean) => setAccepted(checked)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={1} pb={3}>
                            <Grid container justify="space-between" alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary" fullWidth size="medium" disabled={!accepted || submitting || !deviceId}>
                                        {translate("buttons.pay")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container justify="center">
                    <OpenpayBadges />
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            {translate("payments.form.openpay")}
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={submitting} message={translate("payments.form.dont_close") as string} />
        </div>
    );
}

class CardValidator implements InputValidator {
    isValid = function (value: string): boolean {
        let openpay = getOpenpay();
        if (!openpay || !openpay.card || value === "") {
            return true;
        }

        return openpay.card.validateCardNumber(value);
    };
    getMessage = () => translate("validations.card") as string;
}

class CvvValidator implements InputValidator {
    card?: string;

    constructor(card?: string) {
        this.card = card;
    };

    isValid = (value: string): boolean => {
        let openpay = getOpenpay();
        if (!openpay || !openpay.card || value === "") {
            return true;
        }

        return openpay.card.validateCVC(value, this.card);
    };

    getMessage = () => translate("validations.cvv") as string;
}