import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";

import DialogPopup from "../components/DialogPopup";
import { deletePaymentOrder, } from "../api/PaymentOrderApi";


interface PaymentOrderDeletePopupProps {
    paymentOrderId: string;
    tenantId: string;
    onCompleted(): any;
    onError(error: string): any;
    onCancelled(): any;
}

export default function PaymentOrderDeletePopup(props: PaymentOrderDeletePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onConfirm = () => {
        setSubmitting(true);
        deletePaymentOrder(props.tenantId, props.paymentOrderId).then((_) => {
            props.onCompleted();
        }).catch((error) => {
            props.onError(error.message);
        });
    };

    return (
        <DialogPopup open
            title={translate("payment_order.messages.delete") as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onConfirm} variant="outlined" color="secondary" disabled={submitting}>
                    {translate("buttons.delete")}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate("payment_order.messages.confirm_delete")}
            </Typography>
        </DialogPopup>
    );

}