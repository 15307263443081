import { callAPI, HTTPMethod } from "./API";
import { EmployeeGroups, EmployeeGroup, EmployeeGroupsQueryParams, EmployeeGroupRequest, EmployeeGroupResultImport } from "../model/EmployeeGroup";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/employee_groups`;

export async function listEmployeeGroups(tenantId: string, providerId: string, page: number, offset: number, params: EmployeeGroupsQueryParams): Promise<EmployeeGroups> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createEmployeeGroup(tenantId: string, providerId: string, request: EmployeeGroupRequest): Promise<EmployeeGroup> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getEmployeeGroup(tenantId: string, providerId: string, id: string): Promise<EmployeeGroup> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateEmployeeGroup(tenantId: string, providerId: string, id: string, request: EmployeeGroupRequest): Promise<EmployeeGroup> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteEmployeeGroup(tenantId: string, providerId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId, providerId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function importGroupEmployee(tenantId: string, providerId: string, file: File): Promise<EmployeeGroupResultImport> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    return await callAPI(`${basePath(tenantId, providerId)}/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}