import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { listBudgetLinesForRequisition } from "../api/BudgetApi";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { Column, Filter, DataTypeProviderProps, DataTypeProvider } from '@devexpress/dx-react-grid';
import { RequisitionLine } from "../model/Requisition";
import { BudgetLine, BudgetLines, BudgetLinesListParams, BudgetLineForGrid } from "../model/Budget";
import { getOffset, initialPageSize, initialPageZero } from "../components/Pagination";
import { WarningSnackbar } from "../components/Snackbars";

interface CreateRequisitionLinePopupProps {
    tenantId: string;
    companyId: string;
    currency: string;
    requisitionTypeId: string;
    changeAmount: number;
    onClose(): any;
    onSave(line: BudgetLine[]): any;
}

export default function CreateRequisitionLinePopup(props: CreateRequisitionLinePopupProps) {

    const paramsToFilters = () => {
        return [
            { columnName: "external_id", value: params?.external_id } as Filter,
            { columnName: "description", value: params?.description } as Filter,
            { columnName: "team_name", value: params?.team_name } as Filter,
            { columnName: "pending_accrue_amount", value: params?.to_accrued } as Filter
        ] as Filter[];
    };

    const paramsFromQueryString = (): BudgetLinesListParams => {
        return {
            external_id: "",
            description: "",
            team_name: "",
            to_accrued: "",
            requisition_type_id: props.requisitionTypeId,
        } as BudgetLinesListParams;
    };

    const [params] = useState<BudgetLinesListParams>(paramsFromQueryString);
    const [status, setStatus] = useState<string>("loading");
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [data, setData] = useState<BudgetLines>();
    const [selected, setSelected] = useState<string[]>([]);
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());
    const [dataHandler, setDataHandler] = useState<BudgetLine[]>([]);
    const [warning, setWarning] = useState("");

    function loadCfdis() {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        listBudgetLinesForRequisition(props.tenantId, props.companyId, pageSize, offset, params).then((response) => {
            setData(response);
            for (const item of (response?.items ?? [])) {
                const finded = dataHandler.find(el => el.external_id === item.external_id && item.budget_id === el.budget_id);
                if (!finded) {
                    dataHandler.push(item);
                }
            }
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(() => {
        loadCfdis();
        // eslint-disable-next-line
    }, [props, page, pageSize]);

    const isMatchLine = (bugdetLine: BudgetLine, idsMatch: string) => {
        let ids = idsMatch.split("|");
        let externalId = ids[0];
        let budgetId = ids[1];
        return (bugdetLine.budget_id === budgetId) && (externalId === bugdetLine.external_id);
    }

    const onConfirm = () => {
        if (selected.length <= 0) {
            setWarning(translate("requisitions.overview.details.line_required") as string);
            return;
        }
        props.onSave(dataHandler?.filter(item => selected.find(selectedLine => isMatchLine(item, selectedLine))));
    };

    const onClickedOptions = (line: BudgetLine) => { };

    const isValidToAccrueAmount = (selectedLines: (string)[]) => {
        if (selectedLines.length > 0) {
            let lines = dataHandler?.filter(item => selectedLines.find(selectedLine => isMatchLine(item, selectedLine))); 
            let count = 0;
            lines.forEach(line => {
                if(!line.pending_accrue_amount || line.pending_accrue_amount <= 0){
                    count++;
                }
            });
            return count === 0;
        } 
        return true;
    };

    const setSelectionHandler = (selected: (string)[]) => {
        if(!isValidToAccrueAmount(selected)){
            setWarning(translate("requisitions.overview.details.lines_zero_to_accrue") as string);
            return;
        }
        setSelected(selected);
    };

    const getPendingAccrue = (line: BudgetLine) => {
        let amount = line.pending_accrue_amount && line.pending_accrue_amount > 0 ? (line.pending_accrue_amount / props.changeAmount) : 0;
        return `${Number(amount.toFixed(2))}`;
    };

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "description":
                        params.description = filter.value;
                        break;
                    case "external_id":
                        params.external_id = filter.value;
                        break;
                    case "team_name":
                        params.team_name = filter.value;
                        break;
                    case "to_accrued":
                        params.to_accrued = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setPage(1);
        setSelected([]);
        setDataHandler([]);
        loadCfdis();
    };

    const [tableColumnExtensionsP] = useState([
        { columnName: 'external_id', width: 130, wordWrapEnabled: true },
        { columnName: 'budget_name', width: 250, wordWrapEnabled: true },
        { columnName: 'description', width: 250, wordWrapEnabled: true },
        { columnName: 'team_name', width: 250, wordWrapEnabled: true },
        { columnName: 'pending_accrue_amount', width: 150, align: 'right' },

    ]) as any;

    const columns = [
        {
            name: 'external_id',
            title: translate('budget.performance.external_id') as string,
        },
        {
            name: 'budget_name',
            title: translate("budget.budget") as string,
        },
        {
            name: 'description',
            title: translate('budget.performance.name') as string,
        },
        {
            name: 'team_name',
            title: translate("budget.performance.team") as string,
            getCellValue: (row: any) => row.team_name ? row.team_name : "---"
        },
        {
            name: 'pending_accrue_amount',
            title: `${translate("budget.performance.to_accrued_amount")} (${props.currency})` as string,
            getCellValue: (row: any) => getPendingAccrue(row)
        }
    ] as Column[];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPluginsFiltersExlude = [
        <NormalTypeProvider for={['budget_name']} editorComponent={NoFilterEditor} />
    ];

    return (
        <DialogPopup open
            title={translate("requisitions.overview.details.add_lines") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            maxWidth="lg"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={
                <Box pl={1}>
                    <Button onClick={onConfirm} variant="outlined" color="primary" >
                        {translate("buttons.add") as string}
                    </Button>
                </Box>
            }>
            <Grid container>
                <Box pl={2}>
                    <Grid item xs={12}>
                        <Typography variant="inherit" >
                            {translate("requisitions.overview.details.use_filters")}
                        </Typography>
                    </Grid>
                </Box>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <GridDx
                                loading={status === "loading"}
                                rows={data ? data.items : []}
                                columns={columns}
                                defaultExpandedGroups={[]}
                                onClickRow={(v) => { }}
                                lineIdentifier="external_id"
                                secondIdentifier="budget_id"
                                clickRowColumns={[]}
                                amountCurrencyColumns={['pending_accrue_amount']}
                                quantityColumns={[]}
                                dateColumns={[]}
                                columnsFormat={tableColumnExtensionsP}
                                leftColumns={[]}
                                textColumns={['external_id', 'budget_name', 'description', 'team_name']}
                                rightColumns={[]}
                                grouping={[]}
                                page={page}
                                pageSize={pageSize}
                                totalRows={data ? data.total : 0}
                                selectionIds={selected}
                                sorting={[]}
                                filters={filters}
                                setFiltersHandler={setFiltersHandler}
                                customFormatColumns={customPluginsFiltersExlude}
                                setSelectionHandler={setSelectionHandler}
                                onChangedPage={onChangedPage}
                                onChangedPageSize={onChangedPageSize}
                                onClickedOptions={onClickedOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={() => setWarning("")} />

        </DialogPopup>
    );
}
