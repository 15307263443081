import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from "@material-ui/core";
import { AddIcon, BudgetLinesTemplatesIcon, DeleteIcon, EditIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { Companies, CompaniesQueryParams } from "../model/Company";

import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import { listCompanies } from "../api/CompanyAPI";
import Gridable from "../components/Gridable";
import { BudgetLine, BudgetLinesTemplate, BudgetLinesTemplateRequest } from "../model/BudgetLinesTemplate";
import { createBudgetLinesTemplate, getBudgetLinesTemplate, updateBudgetLinesTemplate } from "../api/BudgetLinesTemplatesApi";
import BudgetLinePopup from "./BudgetLinePopup";

type PopupMode = "edit" | "add" | undefined;

export default function BudgetLinesTemplateForm({ match }: RouterParams) {
    const history = useHistory();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const budgetLinesTemplateId = match.params.budgetLinesTemplateId;
    const margin = "dense";
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [popup, setPopup] = useState<PopupMode>();
    const [selectedBudgetLine, setSelectedBudgetLine] = useState<BudgetLine>();
    const [request, setRequest] = useState<BudgetLinesTemplateRequest>({} as BudgetLinesTemplateRequest);
    const [validations, setValidations] = useState({} as any);
    const [companies, setCompanies] = useState<MultiselectValue[]>([]);
    const [budgetLinesRequest, setBudgetLinesRequest] = useState<BudgetLine[]>([]);

    const initCompanies = (companies: Companies) => {
        let items = companies.items;
        let companiesValues = [] as MultiselectValue[];
        items.forEach(c => {
            companiesValues.push({ title: c.name, value: c.id } as MultiselectValue)
        });
        setCompanies(companiesValues);
    }

    const submitPromise = (): Promise<BudgetLinesTemplate> => {
        let fullRequest = { ...request, budget_lines: budgetLinesRequest } as BudgetLinesTemplateRequest;
        if (budgetLinesTemplateId) {
            return updateBudgetLinesTemplate(tenantId, budgetLinesTemplateId, fullRequest);
        }
        return createBudgetLinesTemplate(tenantId, fullRequest);
    };

    useEffect(() => {
        setStatus("loading");
        if (budgetLinesTemplateId) {
            getBudgetLinesTemplate(tenantId, budgetLinesTemplateId).then((budgetLinesTemplate) => {
                setRequest({
                    external_id: budgetLinesTemplate.external_id,
                    name: budgetLinesTemplate.name,
                    description: budgetLinesTemplate.description,
                    company_ids: budgetLinesTemplate.company_ids,
                } as BudgetLinesTemplateRequest);
                setBudgetLinesRequest(budgetLinesTemplate.budget_lines);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        if (context.isGrantedAny(["CompaniesRead", "TenantsCompaniesRead"])) {
            listCompanies(context.session!.tenant!.id, 0, 0, { search: "" } as CompaniesQueryParams).then((response) => {
                initCompanies(response);
            });
        }
    }, [tenantId, budgetLinesTemplateId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedCompanies = (selected: string[]) => {
        setRequest({ ...request, company_ids: selected });
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        if (budgetLinesRequest.length < 1) {
            setWarning(translate("budget_lines_templates.at_least_one_budget_line") as string);
            return;
        }

        setSubmitting(true);
        submitPromise().then((budgetLinesTemplate) => {
            history.push("/budgets/template-lines");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const showEditPopup = (line: BudgetLine) => () => {
        setPopup("edit");
        setSelectedBudgetLine(line);
    }

    const onDelete = (budgetLine: BudgetLine, index: number) => {
        const newValues = budgetLinesRequest.filter((el, i) => i !== index);
        setBudgetLinesRequest(newValues);
    }

    const onSubmitPopup = (submittedLine: BudgetLine) => {
        let newLines = [] as BudgetLine[];
        if (popup === "add") {
            if (budgetLinesRequest.find(line => line.external_id === submittedLine.external_id)) {
                setWarning(translate("budget_lines_templates.line_duplicated_msg") as string);
                return;
            }
            newLines = [...budgetLinesRequest, submittedLine];
        }
        if (popup === "edit") {
            newLines = [...budgetLinesRequest];
            let currentLine = newLines.find(line => line.external_id === submittedLine.external_id);
            if (currentLine) {
                let index = newLines.indexOf(currentLine);
                newLines[index] = submittedLine;
            } else {
                setWarning(translate("budget_lines_templates.line_not_found", {id: submittedLine.external_id}) as string);
                return;
            }
        }
        setBudgetLinesRequest(newLines);
        setPopup(undefined);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(budgetLinesTemplateId ? "budget_lines_templates.edit" : "budget_lines_templates.new")} icon={<BudgetLinesTemplatesIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("budget_lines_templates.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="external_id" name="external_id"
                                        value={request.external_id}
                                        label={translate("budget_lines_templates.external_id") as string}
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="description" name="description"
                                        value={request.description}
                                        label={translate("budget_lines_templates.description") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                {context.isGrantedAny(["CompaniesRead", "TenantsCompaniesRead"]) &&
                                    <Grid item xs={12}>
                                        <MultiselectDropList
                                            required={true}
                                            disabled={submitting}
                                            title={translate("budget_lines_templates.companies") as string}
                                            margin={margin}
                                            elementos={companies}
                                            value={companies.filter(c => request.company_ids?.includes(c.value))}
                                            onChanged={onChangedCompanies} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Box mt={2} mx={-2}>
                                        <Gridable
                                            items={budgetLinesRequest}
                                            loading={false}
                                            empty={translate("budget_lines_templates.budget_lines_empty_grid") as string}
                                            columns={[
                                                {
                                                    title: translate("budget_lines_templates.external_id") as string,
                                                    converter: (budgetLine) => budgetLine.external_id as string,
                                                    fullWidth: true,
                                                    xs: 2
                                                },
                                                {
                                                    title: translate("budget_lines_templates.external_type") as string,
                                                    converter: (budgetLine) => budgetLine.external_type as string,
                                                    fullWidth: true,
                                                    xs: 2
                                                },
                                                {
                                                    title: translate("budget_lines_templates.description") as string,
                                                    converter: (budgetLine) => budgetLine.description as string,
                                                    fullWidth: true,
                                                    xs: 3
                                                },
                                                {
                                                    title: translate("budget_lines_templates.ledger_account") as string,
                                                    converter: (budgetLine) => budgetLine.ledger_account as string,
                                                    fullWidth: true,
                                                    xs: 3
                                                },
                                                {
                                                    title: (
                                                        <IconButton size="small" color="primary" disabled={submitting} onClick={() => setPopup("add")}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    ),
                                                    converter: (value, index) => (
                                                        <>
                                                            <IconButton aria-label="edit" color="primary" size="small" onClick={showEditPopup(value)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" size="small" disabled={submitting} onClick={() => onDelete(value, index)}>
                                                                <DeleteIcon color="error" />
                                                            </IconButton>
                                                        </>
                                                    ),
                                                    justify: "flex-end",
                                                    xs: true
                                                }
                                            ]} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justifyContent="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(budgetLinesTemplateId ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                        {popup &&
                            <BudgetLinePopup
                                existing_ids={budgetLinesRequest.map(line => line.external_id)}
                                popupMode={popup}
                                budgetLine={popup === "edit" ? selectedBudgetLine : undefined}
                                onSubmitPopup={onSubmitPopup}
                                onCancel={() => setPopup(undefined)} />
                        }
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}