import { Box, Card, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Mercancias, Mercancia, TransporteMaritimo, ContenedorMaritimo, RemolqueCCP, DocumentacionAduanera } from "../../model/ComplementoCartaPorte30";
import { CantidadTransporta } from "../../model/ComplementoCartaPorte20";
import translate from "../../i18n/Translator";
import GridDx, { NoFilterEditor } from "../../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import Gridable from "../../components/Gridable";
import { AutotransporteFederal, Transporteaereo, Transporteferroviario } from "./MercanciasCard20";
import DateFormat from "../../components/DateFormat";

interface MercanciasCardProps {
    data: Mercancias;
}

export default function MercanciasCard(props: MercanciasCardProps) {
    return (
        <Box>
            <Typography component="h6" color="primary"><strong>{translate("cfdis.carta_porte.mercancias.title")}</strong></Typography>
            <Grid container justify="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Card variant="outlined" >
                        <MercanciasHead data={props.data} />
                        <MercanciasGrid data={props.data.mercancia} />
                    </Card><br />
                    <AutotransporteFederal data={props.data.autotransporte_federal} />
                    <Transportemaritimo data={props.data.transporte_maritimo} />
                    <Transporteaereo data={props.data.transporte_aereo} />
                    <Transporteferroviario data={props.data.transporte_ferroviario} />
                </Grid>
            </Grid>
        </Box>
    );
}

interface MercanciasHeadProps {
    data: Mercancias;
}

function MercanciasHead(props: MercanciasHeadProps) {

    const headerData = ["peso_bruto_total", "numero_total_mercancias", "unidad_peso", "cargo_por_tasacion", "peso_neto_total", "logistica_inversa_recoleccion_devolucion"];

    return (
        <>
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.mercancia.title")}</Typography><br/>
                <Grid container justify="flex-start" >
                    {headerData.map((property) => {
                        let itemProp = (props.data as any)[property];
                        return <>
                            {itemProp &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                </Grid>
            </Box>
        </>
    );
}

interface MercanciasGridProps {
    data: Mercancia[]
}

export function MercanciasGrid(props: MercanciasGridProps) {

    const [columnsFormat] = useState([
        { columnName: 'bienes_transportados', wordWrapEnabled: true, width: 100 },
        { columnName: 'clave_stcc', wordWrapEnabled: true, width: 100 },
        { columnName: 'descripcion', width: 100 },
        { columnName: 'cantidad', width: 100 },
        { columnName: 'clave_unidad', wordWrapEnabled: true, width: 100 },
        { columnName: 'unidad', wordWrapEnabled: true, width: 100 },
        { columnName: 'dimensiones', wordWrapEnabled: true, width: 100 },
        { columnName: 'material_peligroso', wordWrapEnabled: true, width: 100 },
        { columnName: 'clave_material_peligroso', wordWrapEnabled: true, width: 100 },
        { columnName: 'embalaje', wordWrapEnabled: true, width: 100 },
        { columnName: 'descripcion_embalaje', wordWrapEnabled: true, width: 100 },
        { columnName: 'peso_en_kg', wordWrapEnabled: true, width: 100 },
        { columnName: 'valor_mercancia', align: 'right', wordWrapEnabled: true, width: 100 },
        { columnName: 'moneda', width: 100 },
        { columnName: 'fraccion_arancelaria', wordWrapEnabled: true, width: 100 },
        { columnName: 'tipo_materia', wordWrapEnabled: true, width: 100 },
        { columnName: 'descripcion_materia', wordWrapEnabled: true, width: 100 },
        { columnName: 'sector_cofepris', wordWrapEnabled: true, width: 100 },
        { columnName: 'nombre_ingrediente_activo', wordWrapEnabled: true, width: 100 },
        { columnName: 'nom_quimico', wordWrapEnabled: true, width: 100 },
        { columnName: 'denominacion_generica_prod', wordWrapEnabled: true, width: 100 },
        { columnName: 'denominacion_distintiva_prod', wordWrapEnabled: true, width: 100 },
        { columnName: 'fabricante', wordWrapEnabled: true, width: 100 },
        { columnName: 'fecha_caducidad', wordWrapEnabled: true, width: 100 },
        { columnName: 'lote_medicamento', wordWrapEnabled: true, width: 100 },
        { columnName: 'forma_farmaceutica', wordWrapEnabled: true, width: 100 },
        { columnName: 'condiciones_especiales_transporte', wordWrapEnabled: true, width: 100 },
        { columnName: 'registro_sanitario_folio_autorizacion', wordWrapEnabled: true, width: 100 },
        { columnName: 'permiso_importacion', wordWrapEnabled: true, width: 100 },
        { columnName: 'folio_impo_vucem', wordWrapEnabled: true, width: 100 },
        { columnName: 'num_cas', wordWrapEnabled: true, width: 100 },
        { columnName: 'razon_social_emp_imp', wordWrapEnabled: true, width: 100 },
        { columnName: 'num_reg_san_plag_cofepris', wordWrapEnabled: true, width: 100 },
        { columnName: 'datos_fabricante', wordWrapEnabled: true, width: 100 },
        { columnName: 'datos_formulador', wordWrapEnabled: true, width: 100 },
        { columnName: 'datos_maquilador', wordWrapEnabled: true, width: 100 },
        { columnName: 'uso_autorizado', wordWrapEnabled: true, width: 100 },
    ]);

    const columns = [
        {
            name: 'bienes_transportados',
            title: translate('cfdis.carta_porte.mercancias.mercancia.bienes_transportados') as string
        },
        {
            name: 'clave_stcc',
            title: translate('cfdis.carta_porte.mercancias.mercancia.clave_stcc') as string
        },
        {
            name: 'descripcion',
            title: translate('cfdis.carta_porte.mercancias.mercancia.descripcion') as string
        },
        {
            name: 'cantidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.cantidad') as string
        },
        {
            name: 'clave_unidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.clave_unidad') as string
        },
        {
            name: 'unidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.unidad') as string
        },
        {
            name: 'dimensiones',
            title: translate('cfdis.carta_porte.mercancias.mercancia.dimensiones') as string
        },
        {
            name: 'material_peligroso',
            title: translate('cfdis.carta_porte.mercancias.mercancia.material_peligroso') as string
        },
        {
            name: 'clave_material_peligroso',
            title: translate('cfdis.carta_porte.mercancias.mercancia.clave_material_peligroso') as string
        },
        {
            name: 'embalaje',
            title: translate('cfdis.carta_porte.mercancias.mercancia.embalaje') as string
        },
        {
            name: 'descripcion_embalaje',
            title: translate('cfdis.carta_porte.mercancias.mercancia.descripcion_embalaje') as string
        },
        {
            name: 'peso_en_kg',
            title: translate('cfdis.carta_porte.mercancias.mercancia.peso_en_kg') as string
        },
        {
            name: 'valor_mercancia',
            title: translate('cfdis.carta_porte.mercancias.mercancia.valor_mercancia') as string
        },
        {
            name: 'moneda',
            title: translate('cfdis.carta_porte.mercancias.mercancia.moneda') as string
        },
        {
            name: 'fraccion_arancelaria',
            title: translate('cfdis.carta_porte.mercancias.mercancia.fraccion_arancelaria') as string
        },
        {
            name: 'uuid_comercio_exterior',
            title: translate('cfdis.carta_porte.mercancias.mercancia.uuid_comercio_exterior') as string
        },
        {
            name: 'tipo_materia',
            title: translate('cfdis.carta_porte.mercancias.mercancia.tipo_materia') as string
        },
        {
            name: 'descripcion_materia',
            title: translate('cfdis.carta_porte.mercancias.mercancia.descripcion_materia') as string
        },
        {
            name: 'sector_cofepris',
            title: translate('cfdis.carta_porte.mercancias.mercancia.sector_cofepris') as string
        },
        {
            name: 'nombre_ingrediente_activo',
            title: translate('cfdis.carta_porte.mercancias.mercancia.nombre_ingrediente_activo') as string
        },
        {
            name: 'nom_quimico',
            title: translate('cfdis.carta_porte.mercancias.mercancia.nom_quimico') as string
        },
        {
            name: 'denominacion_generica_prod',
            title: translate('cfdis.carta_porte.mercancias.mercancia.denominacion_generica_prod') as string
        },
        {
            name: 'denominacion_distintiva_prod',
            title: translate('cfdis.carta_porte.mercancias.mercancia.denominacion_distintiva_prod') as string
        },
        {
            name: 'fabricante',
            title: translate('cfdis.carta_porte.mercancias.mercancia.fabricante') as string
        },
        {
            name: 'fecha_caducidad',
            title: translate('cfdis.carta_porte.mercancias.mercancia.fecha_caducidad') as string
        },
        {
            name: 'lote_medicamento',
            title: translate('cfdis.carta_porte.mercancias.mercancia.lote_medicamento') as string
        },
        {
            name: 'forma_farmaceutica',
            title: translate('cfdis.carta_porte.mercancias.mercancia.forma_farmaceutica') as string
        },
        {
            name: 'condiciones_especiales_transporte',
            title: translate('cfdis.carta_porte.mercancias.mercancia.condiciones_especiales_transporte') as string
        },
        {
            name: 'registro_sanitario_folio_autorizacion',
            title: translate('cfdis.carta_porte.mercancias.mercancia.registro_sanitario_folio_autorizacion') as string
        },
        {
            name: 'permiso_importacion',
            title: translate('cfdis.carta_porte.mercancias.mercancia.permiso_importacion') as string
        },
        {
            name: 'folio_impo_vucem',
            title: translate('cfdis.carta_porte.mercancias.mercancia.folio_impo_vucem') as string
        },
        {
            name: 'num_cas',
            title: translate('cfdis.carta_porte.mercancias.mercancia.num_cas') as string
        },
        {
            name: 'razon_social_emp_imp',
            title: translate('cfdis.carta_porte.mercancias.mercancia.razon_social_emp_imp') as string
        },
        {
            name: 'num_reg_san_plag_cofepris',
            title: translate('cfdis.carta_porte.mercancias.mercancia.num_reg_san_plag_cofepris') as string
        },
        {
            name: 'datos_fabricante',
            title: translate('cfdis.carta_porte.mercancias.mercancia.datos_fabricante') as string
        },
        {
            name: 'datos_formulador',
            title: translate('cfdis.carta_porte.mercancias.mercancia.datos_formulador') as string
        },
        {
            name: 'datos_maquilador',
            title: translate('cfdis.carta_porte.mercancias.mercancia.datos_maquilador') as string
        },
        {
            name: 'uso_autorizado',
            title: translate('cfdis.carta_porte.mercancias.mercancia.uso_autorizado') as string
        }
    ];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const dateColumns = ['fecha_caducidad'];

    const currencyColumns = ['valor_mercancia'];

    const quantityColumns = ['cantidad', 'peso_en_kg'];

    const filtersExclude = [
        'bienes_transportados',
        'clave_stcc',
        'descripcion',
        'cantidad',
        'clave_unidad',
        'unidad',
        'dimensiones',
        'material_peligroso',
        'clave_material_peligroso',
        'embalaje',
        'descripcion_embalaje',
        'peso_en_kg',
        'valor_mercancia',
        'moneda',
        'fraccion_arancelaria',
        'uuid_comercio_exterior',
        'cantidad_transporta',
        'detalle_mercancia',
        'tipo_materia',
        'descripcion_materia',
        'sector_cofepris',
        'nombre_ingrediente_activo',
        'nom_quimico',
        'denominacion_generica_prod',
        'denominacion_distintiva_prod',
        'fabricante',
        'fecha_caducidad',
        'lote_medicamento',
        'forma_farmaceutica',
        'condiciones_especiales_transporte',
        'registro_sanitario_folio_autorizacion',
        'permiso_importacion',
        'folio_impo_vucem',
        'num_cas',
        'razon_social_emp_imp',
        'num_reg_san_plag_cofepris',
        'datos_fabricante',
        'datos_formulador',
        'datos_maquilador',
        'uso_autorizado'
    ];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
    ];

    const [leftColumns] = useState(['bienes_transportados', 'clave_stcc']) as any;

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
            <Box>
            <Typography ><strong>{translate("cfdis.carta_porte.mercancias.mercancia.detalle.title")}</strong></Typography>
            <br />
            <Grid container justify="flex-start" spacing={2}>
                {params.row.detalle_mercancia &&
                    <Grid item xs={12}>
                        <Grid xs={6}>
                            <Grid container>
                                <Grid xs={2}>
                                    {params.row.detalle_mercancia.unidad_peso}<br />
                                    {translate("cfdis.carta_porte.mercancias.mercancia.detalle.unit")}
                                </Grid>
                                <Grid xs={10}>
                                    <Grid container>
                                        <Grid xs={3}>
                                            {params.row.detalle_mercancia.peso_bruto}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_bruto")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.detalle_mercancia.peso_neto}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_neto")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.detalle_mercancia.peso_tara}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_tara")}
                                        </Grid>
                                        {params.row.detalle_mercancia.numero_piezas &&
                                            <Grid xs={3}>
                                                {params.row.detalle_mercancia.numero_piezas}<br />
                                                {translate("cfdis.carta_porte.mercancias.mercancia.detalle.numero_piezas")}
                                            </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                {params.row.guias_identificacion &&
                    <Grid item xs={12}>
                        <Grid xs={6}>
                            <Grid container>
                                <Grid xs={10}>
                                    <Grid container>
                                        <Grid xs={3}>
                                            {params.row.guias_identificacion.numero_guia_identificacion}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.numero_guia_identificacion")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.guias_identificacion.descripcion_guia_identificacion}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.descripcion_guia_identificacion")}
                                        </Grid>
                                        <Grid xs={3}>
                                            {params.row.guias_identificacion.peso_guia_identificacion}<br />
                                            {translate("cfdis.carta_porte.mercancias.mercancia.detalle.peso_guia_identificacion")}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                {params.row.documentacion_aduanera &&
                    <Grid xs={6}>
                        <Gridable
                            items={params.row.documentacion_aduanera}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.tipo_documento") as string,
                                    converter: (ct: DocumentacionAduanera) => ct.tipo_documento || "",
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.num_pedimento") as string,
                                    converter: (ct: DocumentacionAduanera) => ct.num_pedimento,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.ident_doc_aduanero") as string,
                                    converter: (ct: DocumentacionAduanera) => ct.ident_doc_aduanero,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.rfc_impo") as string,
                                    converter: (ct: DocumentacionAduanera) => ct.rfc_impo,
                                    xs: 3,
                                }
                            ]}
                        />
                    </Grid>
                }
                {params.row.cantidad_transporta &&
                    <Grid xs={6}>
                        <Gridable
                            items={params.row.cantidad_transporta}
                            loading={false}
                            empty={translate("reports.empty") as string}
                            columns={[
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.cantidad") as string,
                                    converter: (ct: CantidadTransporta) => ct.cantidad,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.id_origen") as string,
                                    converter: (ct: CantidadTransporta) => ct.id_origen,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.id_destino") as string,
                                    converter: (ct: CantidadTransporta) => ct.id_destino,
                                    xs: 3,
                                },
                                {
                                    title: translate("cfdis.carta_porte.mercancias.mercancia.detalle.clave_transporte") as string,
                                    converter: (ct: CantidadTransporta) => ct.clave_transporte,
                                    xs: 3,
                                }
                            ]}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    );

    return (
        <GridDx
            loading={false}
            rows={props.data}
            columns={columns}
            columnsFormat={columnsFormat}
            onClickRow={() => { }}
            amountCurrencyColumns={currencyColumns}
            leftColumns={leftColumns}
            quantityColumns={quantityColumns}
            dateColumns={dateColumns}
            customFormatColumns={customPlugins}
            rowDetailComponent={rowDetailComponent}
            noUseId
        />
    );
}

interface TransporteMaritimoProps {
    data?: TransporteMaritimo;
}

function Transportemaritimo(props: TransporteMaritimoProps) {

    const headerData = ["permiso_sct", "numero_permiso_sct", "nombre_aseguradora", "numero_poliza_seguro", "tipo_embarcacion",
        "matricula",
        "numero_omi",
        "anio_embarcacion",
        "nombre_embarcacion",
        "nacionalidad_embarcacion",
        "unidades_arqueo_bruto",
        "tipo_carga",
        "eslora",
        "manga",
        "calado",
        "puntal", 
        "linea_naviera",
        "nombre_agente_naviero",
        "permiso_temp_navegacion",
        "numero_autorizacion_naviero",
        "numero_viaje",
        "numero_conocimiento_embarque"
    ];

    return props.data && headerData ? <>
        <Card variant="outlined" >
            <Box pb={2} px={2} pt={1}>
            <Typography component="h6" color="primary">{translate("cfdis.carta_porte.mercancias.transporte_maritimo.title")}</Typography><br/>
                <Grid container justify="flex-start">
                    {headerData.map((property) => {
                        let itemProp = (props.data as any)[property];
                        return <>
                            {property && itemProp &&
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div>
                                            {translate(`cfdis.carta_porte.mercancias.transporte_maritimo.${property}`)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {(props.data as any)[property]}
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>;
                    })}
                    {props.data.contenedores && props.data.contenedores.length > 0 &&
                        <Grid item xs={12} >
                            <Box pt={2}>
                                <Gridable
                                    items={props.data.contenedores}
                                    loading={false}
                                    empty={translate("reports.empty") as string}
                                    columns={[
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.matricula") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.matricula,
                                            xs: 2,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.tipo") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.tipo,
                                            xs: 2,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.numero_precinto") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.numero_precinto,
                                            xs: 2,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.id_ccp_relacionado") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.id_ccp_relacionado,
                                            xs: 2,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.placa_vmccp") as string,
                                            converter: (ct: ContenedorMaritimo) => ct.placa_vmccp,
                                            xs: 2,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.contenedores.fecha_certificacion_ccp") as string,
                                            converter: (ct: ContenedorMaritimo) => ct && ct.fecha_certificacion_ccp ? <DateFormat date={ct.fecha_certificacion_ccp} format="DD/MM/YYYY" /> : "---",
                                            xs: 2,
                                        },
                                    ]}
                                />
                            </Box>
                        </Grid>
                    }
                    {props.data.remolques_ccp && props.data.remolques_ccp.remolques_ccp && props.data.remolques_ccp.remolques_ccp.length > 0 &&
                        <Grid item xs={12} >
                            <Box pt={2}>
                                <Gridable
                                    items={props.data.remolques_ccp.remolques_ccp}
                                    loading={false}
                                    empty={translate("reports.empty") as string}
                                    columns={[
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.remolques_ccp.sub_tipo_rem_ccp") as string,
                                            converter: (ct: RemolqueCCP) => ct.sub_tipo_rem_ccp,
                                            xs: 4,
                                        },
                                        {
                                            title: translate("cfdis.carta_porte.mercancias.transporte_maritimo.remolques_ccp.placa_ccp") as string,
                                            converter: (ct: RemolqueCCP) => ct.placa_ccp,
                                            xs: 4,
                                        }
                                    ]}
                                />
                            </Box>
                        </Grid>
                    }
                </Grid>

            </Box>
        </Card><br /></>
        : <></>;
}
