import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from "@material-ui/core";
import { AddIcon, DeleteIcon, EditIcon, IntegrationsIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { getIntegration, updateIntegration } from "../api/IntegrationAPI";
import { Integration, IntegrationRequest, IntegrationRoute } from "../model/Integration";

import Progress from "../components/Progress";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";
import Gridable from "../components/Gridable";
import DynamicIcon from "../components/DynamicIcon";
import IntegrationRouteForm from "./IntegrationRouteForm";
import IconSelector from "../components/IconSelector";

type Mode = "new" | "edit"

export default function IntegrationForm({ match }: RouterParams) {
    const history = useHistory();
    const tenantId = match.params.tenantId;
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [integration, setIntegration] = useState<Integration>()
    const [request, setRequest] = useState<IntegrationRequest>({} as IntegrationRequest);
    const [validations, setValidations] = useState({} as any);
    const [route, setRoute] = useState<IntegrationRoute>()
    const [mode, setMode] = useState<Mode>()

    useEffect(() => {
        setStatus("loading");
        getIntegration(tenantId)
            .then(integration => {
                const routes = integration.routes?.map((route, index) => {
                    return { ...route, index: index };
                }) ?? []

                setIntegration(integration);
                setRequest({
                    tenant_id: integration.tenant?.id ?? '',
                    js_url: integration.js_url,
                    group: integration.group,
                    icon: integration.icon,
                    routes: routes,
                } as IntegrationRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
    }, [tenantId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        updateIntegration(tenantId, request)
            .then((integration) => {
                setSuccess(translate("integrations.success", { tenant: integration?.tenant.name ?? '---' }) as string)
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSubmitting(false);
            });
    };

    const showRouteForm = (mode: Mode, route?: IntegrationRoute) => {
        setMode(mode);
        setRoute(route);
    };

    const closeRouteForm = () => {
        setMode(undefined);
        setRoute(undefined);
    };

    const editForm = (route: IntegrationRoute) => {
        let newRoutes: IntegrationRoute[];
        if (route.index === undefined) {
            newRoutes = [...request.routes, { ...route, index: request.routes.length }];
        } else {
            newRoutes = request.routes.map(el => {
                if (el.index === route.index) {
                    return route;
                }
                return el;
            })
        }

        setRequest({ ...request, routes: newRoutes })
        closeRouteForm()
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Surface title={translate("integrations.edit")} subtitle={integration?.tenant?.name} icon={<IntegrationsIcon />} className="FormSurface" backButton>
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="js_url" name="js_url"
                                        value={request.js_url}
                                        label={translate("integrations.js_url") as string}
                                        required
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="group" name="group"
                                        value={request.group}
                                        label={translate("integrations.group") as string}
                                        required
                                        disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <IconSelector icon={request.icon} onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mx={-2} mt={2}>
                                        <Grid container>
                                            <Gridable
                                                items={request.routes}
                                                empty={translate("integrations.no_routes") as string}
                                                loading={false}
                                                columns={[
                                                    {
                                                        title: translate("integrations.routes.title"),
                                                        converter: (route) => route.title,
                                                        fullWidth: true,
                                                        xs: true,
                                                    },
                                                    {
                                                        title: translate("integrations.routes.path"),
                                                        converter: (route) => route.path,
                                                        xs: 3,
                                                        fullWidth: true,
                                                    },
                                                    {
                                                        title: translate("integrations.routes.icon"),
                                                        converter: (route) => (
                                                            <DynamicIcon icon={route.icon} />
                                                        ),
                                                        fullWidth: true,
                                                        xs: 2,
                                                    },
                                                    {
                                                        title: translate("integrations.routes.hidden"),
                                                        converter: (route) => translate(route.hidden ? "yes" : "no"),
                                                        fullWidth: true,
                                                        xs: 2,
                                                    },
                                                    {
                                                        title: (
                                                            <>
                                                                <IconButton color="primary" size="small" style={{ visibility: "hidden" }} disabled>
                                                                    <AddIcon />
                                                                </IconButton>
                                                                <IconButton color="primary" size="small" onClick={() => showRouteForm("new")}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </>
                                                        ),
                                                        converter: (route) => (
                                                            <>
                                                                <IconButton color="default" size="small" onClick={() => showRouteForm("edit", route)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton color="default" size="small">
                                                                    <DeleteIcon color="error" />
                                                                </IconButton>
                                                            </>
                                                        ),
                                                        fullWidth: true,
                                                        justify: "flex-end",
                                                        xs: "auto"
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate("buttons.update")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            {mode && (
                <IntegrationRouteForm
                    item={route}
                    roles={integration?.roles ?? []}
                    onUpdated={editForm}
                    onClose={closeRouteForm} />
            )}
        </Grid>
    );
}