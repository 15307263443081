import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Cfdi, CfdisQueryParams, Cfdis } from "../model/Cfdi";
import { getTenantCfdis } from "../api/TenantCfdiApi";
import { CfdiTotalColumn } from "../cfdi/CfdisList";
import DateFormat from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import NumberFormat from 'react-number-format';
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { Column, Filter, DataTypeProviderProps, DataTypeProvider } from '@devexpress/dx-react-grid';
import { getOffset, initialPageSize, initialPageZero } from "../components/Pagination";

interface AddCfdiPopupProps {
    tenantId: string;
    cfdisInView: string[];
    onClose(): any;
    doAction(cfdi: Cfdi[]): any;
}

export default function AddCfdiPopup(props: AddCfdiPopupProps) {

    const paramsToFilters = () => {
        return [
            { columnName: "description", value: params?.description } as Filter,
            { columnName: "identifier", value: params?.identifier } as Filter,
            { columnName: "issuer.name", value: params?.issuer } as Filter
        ] as Filter[];
    };

    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            status_list: ["PENDING_PAYMENT", "PAYMENT_REJECTED"].join(","),
            referer_view: "PENDING_PAYMENT",
            search: "",
            excluded_ids: props.cfdisInView,
            lite_payload: true,
        } as CfdisQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [data, setData] = useState<Cfdis>();
    const [selected, setSelected] = useState<string[]>([]);
    const [params] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());
    const [dataHandler, setDataHandler] = useState<Cfdi[]>([]);


    function loadCfdis() {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        getTenantCfdis(props.tenantId, "", pageSize, offset, params).then((response) => {
            setStatus("loaded");
            setData({ items: response.items, total: response.total } as Cfdis);
            for (const item of (response?.items ?? [])) {
                const finded = dataHandler.find(el => el.id === item.id);
                if (!finded) {
                    dataHandler.push(item);
                }
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    useEffect(() => {
        loadCfdis();
    // eslint-disable-next-line
    }, [props, page, pageSize]);


    const onConfirm = () => {
        props.doAction(dataHandler?.filter(cfdi => selected.find(cfdiSelected => cfdi.id === cfdiSelected)));
    };

    const onClickedOptions = (cfdi: Cfdi) => { };

    const setSelectionHandler = (selected: (string)[]) => {
        setSelected(selected);
    };

    const getType = (cfdi: Cfdi) => {
        const pdType = translate(`payable_documents.type.${cfdi.type}`) as string;
        if (cfdi.type !== "CFDI") {
            return pdType;
        }

        const cfdiType = translate(`cfdis.history.cfdi_type.${cfdi.metadata.type}`) as string;
        return `${pdType} | ${cfdiType}`;
    };

    const getPaid = (cfdi: Cfdi) => {
        if (cfdi.metadata.balance !== null && cfdi.metadata.balance !== undefined) {
            return cfdi.total - cfdi.metadata.balance;
        }
        return cfdi.total;
    }

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };


    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "description":
                        params.description = filter.value;
                        break;
                    case "issuer.name":
                        params.issuer = filter.value;
                        break;
                    case "identifier":
                        params.identifier = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setPage(1);
        setSelected([]);
        setDataHandler([]);
        loadCfdis();
    };

    const [tableColumnExtensionsP] = useState([
        { columnName: 'description', width: 180, wordWrapEnabled: true },
        { columnName: 'issuer.name', width: 180, wordWrapEnabled: true },
        { columnName: 'identifier', width: 180, wordWrapEnabled: true },
        { columnName: 'metadata', align: 'right' },
        { columnName: 'total', align: 'right' },
        { columnName: 'total_to_pay', align: 'right' },

    ]) as any;

    const columns = [
        {
            name: 'identifier',
            title: translate("cfdis.columns.folio") as string,
            getCellValue: (cfdi) =>
                <Box py={1}>
                    <Grid container >
                        <Grid item xs={12}>
                            <strong>{cfdi.identifier}</strong>
                        </Grid>
                        <Grid item xs={12}>
                            <DateFormat date={cfdi.date} format="L" />
                        </Grid>
                        <Grid item xs={12}>
                            <Ellipsis text={getType(cfdi)} lenght={0} secondary uppercased={false} />
                        </Grid>
                    </Grid>
                </Box>
        },
        {
            name: 'issuer.name',
            title: translate("cfdis.columns.provider") as string,
            getCellValue: (cfdi: any) => cfdi?.issuer?.name ?? '-'

        },
        {
            name: 'description',
            title: translate("cfdis.columns.description") as string
        },
        {
            name: 'total',
            title: translate("cfdis.columns.amount") as string,
            getCellValue: (cfdi: any) => <CfdiTotalColumn cfdi={cfdi} hideIcon={true} />
        },
        {
            name: 'total_to_pay',
            title: translate("cfdis.columns.paid") as string,
            getCellValue: (cfdi) =>
                <NumberFormat value={getPaid(cfdi)} prefix="$ " suffix={` ${cfdi.currency}`} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator="," />
        },
        {
            name: 'metadata',
            title: translate("cfdis.columns.balance") as string,
            getCellValue: (cfdi) =>
                <NumberFormat value={cfdi.metadata.balance || 0} prefix="$ " suffix={` ${cfdi.currency}`} decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator="," />
        }
    ] as Column[];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPluginsFiltersExlude = [
        <NormalTypeProvider for={['total', 'total_to_pay', 'metadata']} editorComponent={NoFilterEditor} />
    ];

    return (
        <DialogPopup open
            title={translate("payment_order.popup_add_cfdis") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            maxWidth="lg"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={
                <Box pl={1}>
                    <Button onClick={onConfirm} variant="outlined" color="primary" >
                        {translate("buttons.add") as string}
                    </Button>
                </Box>
            }>
            <Grid container>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <GridDx
                                loading={status === "loading"}
                                rows={data ? data.items : []}
                                columns={columns}
                                defaultExpandedGroups={[]}
                                onClickRow={(v) => { }}
                                clickRowColumns={[]}
                                amountCurrencyColumns={[]}
                                quantityColumns={[]}
                                dateColumns={[]}
                                columnsFormat={tableColumnExtensionsP}
                                leftColumns={[]}
                                textColumns={['issuer.name', 'description', 'identifier']}
                                rightColumns={[]}
                                grouping={[]}
                                page={page}
                                pageSize={pageSize}
                                totalRows={data ? data.total : 0}
                                selectionIds={selected}
                                sorting={[]}
                                filters={filters}
                                setFiltersHandler={setFiltersHandler}
                                customFormatColumns={customPluginsFiltersExlude}
                                setSelectionHandler={setSelectionHandler}
                                onChangedPage={onChangedPage}
                                onChangedPageSize={onChangedPageSize}
                                onClickedOptions={onClickedOptions}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogPopup>
    );
}
