import React, { useContext, useState } from "react";
import { Menu, ListItemIcon, Fade, Grid, ListItemText, List, ListItem, Icon } from "@material-ui/core";
import translate from "../../i18n/Translator";
import { AppContext } from "../../context/AppContext";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import DownloadList from '@material-ui/icons/ViewListTwoTone';

interface PayrollsMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onDownloadExcel(): any;
    }

export default function PayrollsMenu(props: PayrollsMenuProps) {
    const context = useContext(AppContext);
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);

    const handleClickExportExcel = () => {
        setOpenExportExcel(!openExportExcel);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            
            {context.isGrantedAny(["ProviderPayrollsRead"]) &&
                <Grid >
                    <Grid item xs="auto">
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.menus.export_excel")} />
                            {openExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportExcel} timeout="auto" unmountOnExit>
                            <List component="div">
                                <ListItem button onClick={props.onDownloadExcel} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.by_date_ranges")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
        </Menu>
    );
}