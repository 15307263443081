import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, Checkbox, Divider, Box } from "@material-ui/core";

import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { WarningSnackbar } from "../components/Snackbars";
import { RetentionRule, Retention } from "../model/ReceptionRule";
import { ServiceProduct } from "../model/ServiceProduct";
import { GTEZeroValidator, DecimalsValidator } from "../components/Validators";
import RetentionsAddedListSection from "./RetentionsAddedListSection";
import { TaxRegimen } from "../model/TaxRegimen";
import ProductsServices from "../specific_reception_rules/ProductsServices";

interface AddNewRetentionPopUpProps {
    retention?: RetentionRule;
    retentions: RetentionRule[];
    serviceProducts: ServiceProduct[];
    taxRegimens: TaxRegimen[];
    isEdit: boolean;
    onUpdateProducts(selects: ServiceProduct[]): any;
    onCompleted(retention: RetentionRule, isEdit: boolean): any;
    taxRegimenDuplicate(retention: RetentionRule): any;
    getMessageValidateProducServices(retention: RetentionRule): any;
    onClose(): any;
}

export default function AddNewRetentionPopUp(props: AddNewRetentionPopUpProps) {
    const [retention, setRetention] = useState<RetentionRule>({} as RetentionRule);
    const [serviceProducts, setServiceProducts] = useState<ServiceProduct[]>([]);
    const [serviceProductsSelected, setServiceProductsSelected] = useState<ServiceProduct[]>([]);

    const [retentionsAdded, setRetentionsAdded] = useState<Retention[]>([]);
    const [newRetention, setNewRetention] = useState<Retention>();
    const [validations, setValidations] = useState({} as any);
    const [currentTaxRegimen, setCurrentTaxRegimen] = useState<TaxRegimen>();

    const [taxRegimens, setTaxRegimens] = useState<TaxRegimen[]>([]);
    const gtMaxDecimalsValidator = new DecimalsValidator(6);
    const gtZeroValidator = new GTEZeroValidator();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    useEffect(() => {
        setSubmitting(false);
        let currentRetention = props.retention;
        if (currentRetention) {
            setRetention(currentRetention);
            setRetentionsAdded(currentRetention.retentions);
            setCurrentTaxRegimen(props.taxRegimens.find(tr => tr.id === currentRetention!.tax_regimen_id));
        } else {
            setRetention({temp_id: `${Math.floor(Math.random() * 100)}` , active: true} as RetentionRule);
        }
        setServiceProducts(props.serviceProducts);
        setTaxRegimens(props.taxRegimens);
    }, [props, retentionsAdded]);

    useEffect(() => {
        // eslint-disable-next-line
    }, [newRetention]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) return;

        let request = {...retention, retentions: retentionsAdded} as RetentionRule;
        let duplicate = props.taxRegimenDuplicate(request);
        if (duplicate !== "") {
                setWarning(duplicate as string);
                return;
        }
        let warningProducServices = props.getMessageValidateProducServices(request);
        if(warningProducServices && warningProducServices !== ""){
            setWarning(warningProducServices);
            return;
        }

        if(request.apply_all_product_services && !request.apply_legal_entity && !request.apply_natural_person ){
            setWarning(translate("reception_rule.retention_validation.empty_emisor_type") as string);
            return;
        }
        setSubmitting(true);
        props.onCompleted(request, props.isEdit);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const hasChangedProductService = (selected: ServiceProduct) => {
        if(selected){
            let alreadyAdded = retentionsAdded.find(v => v.product_service_key === selected.code);
            if(alreadyAdded){
                setWarning(translate("reception_rule.retention_validation.already_added_service_product") as string);
                return;
            }
            let tempRetention = {product_service_key: selected.code} as Retention;
            serviceProducts.push(selected);
            retentionsAdded.push(tempRetention);
        }
    }

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
            setRetention({ ...retention, [name]: value });
            validations[name] = inputRef;
            if (name === "regimen_id") {
                setCurrentTaxRegimen(taxRegimens.find(el => el.id === value));
            }
            setValidations(validations);
    };

    const hasChangedPercentajes = (name: string, value: string | number, inputRef: InputRef) => {
        setRetention({ ...retention, [name]: value });
};

    const onRemoveItem = (key: string) => {
        let valueToDelete = retentionsAdded.find(v => v.product_service_key === key);
        if (valueToDelete) {
            let index = retentionsAdded.indexOf(valueToDelete);
            if (index > -1) {
                retentionsAdded.splice(index, 1);
                setNewRetention(newRetention ? undefined : valueToDelete);
            }
        }
    };

    const handleCheck = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        setRetention({ ...retention, apply_all_product_services: checked });
    };

    const handleCheckLegalEntity = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        setRetention({ ...retention, apply_legal_entity: checked });
    };

    const handleCheckNaturalPerson = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        setRetention({ ...retention, apply_natural_person: checked });
    };

    const onUpdatePercenjate = (value: number, tax: string, key: string) => {
        if(!retentionsAdded){
            return;
        }
        retentionsAdded.forEach(item => {
            if(key === item.product_service_key && tax.includes("isr")){
                item.retention_isr = value;
            } else if (key === item.product_service_key && tax.includes("iva")){
                item.retention_iva = value;
            }
        });
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
    };

    const onUpdate = (selected: ServiceProduct[]) => {
        serviceProducts.push(selected[0]);
        setServiceProductsSelected([]);
    };

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="subtitle1">
                        {translate("reception_rule.retention_validation.title_new") as string}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ValidatedInput type="text" id="name" name="name" disabled={submitting}
                                    value={retention.name} label={translate("reception_rule.retention_validation.name") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput type="text" id="tax_regimen_id"
                                    name="tax_regimen_id"
                                    value={retention.tax_regimen_id}
                                    label={translate("companies.tax_regimen") as string}
                                    required={true} disabled={submitting}
                                    margin="dense"
                                    onValueChanged={hasChanged}
                                    autocompleteOptions={taxRegimens}
                                    autocompleteValue={currentTaxRegimen}
                                    getId={(el) => el?.id}
                                    getLabel={(el) => el?.description} />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="number"
                                    id={"tolerance"}
                                    name={"tolerance"}
                                    value={retention.tolerance ? retention.tolerance + "" : "0.00"}
                                    isValueNumber={true}
                                    validators={[gtZeroValidator]}
                                    label={translate("reception_rule.retention_validation.label_tolerance") as string}
                                    required={true}
                                    margin="dense"
                                    onValueChanged={hasChanged}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs="auto">
                                        <Checkbox
                                            id={"allow_different_service_products"}
                                            name={"allow_different_service_products"}
                                            value={retention.apply_all_product_services}
                                            checked={retention.apply_all_product_services}
                                            disabled={submitting}
                                            onChange={handleCheck}
                                            className="GridableCheck"
                                        />
                                    </Grid>
                                    <Grid item>
                                        {translate("reception_rule.retention_validation.label_product_services")}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}><Box my={2}><Divider /></Box></Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{translate("reception_rule.retention_validation.percentaje_message")}</Typography>
                            </Grid>
                            {retention.apply_all_product_services &&
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            <Grid container xs={12} alignItems="center" spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">{translate("reception_rule.retention_validation.isr_retention")}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ValidatedInput
                                                        type="number"
                                                        id={"retention_isr"}
                                                        name={"retention_isr"}
                                                        value={retention.retention_isr ? retention.retention_isr + "" : "0.00"}
                                                        isValueNumber={true}
                                                        validators={[gtZeroValidator, gtMaxDecimalsValidator]}
                                                        disableDefaultValidator
                                                        label={""}
                                                        required={true}
                                                        margin="dense"
                                                        onValueChanged={hasChangedPercentajes}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    <Grid item xs={5}>
                                        <Grid container xs={12} alignItems="center" spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">{translate("reception_rule.retention_validation.iva_retention")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ValidatedInput
                                                    type="number"
                                                    id={"retention_iva"}
                                                    name={"retention_iva"}
                                                    value={retention.retention_iva ? retention.retention_iva + "" : "0.00"}
                                                    isValueNumber={true}
                                                    validators={[gtZeroValidator, gtMaxDecimalsValidator]}
                                                    disableDefaultValidator
                                                    label={""}
                                                    required={true}
                                                    margin="dense"
                                                    onValueChanged={hasChangedPercentajes}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {!retention.apply_all_product_services &&
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <ProductsServices
                                            selected={serviceProductsSelected}
                                            disabled={false}
                                            update={onUpdate}
                                            showTable={false}
                                            placeholder={translate("reception_rule.retention_validation.search_service_product") as string}
                                            onChange={hasChangedProductService}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <RetentionsAddedListSection
                                        retentions={retentionsAdded ? retentionsAdded : []}
                                        serviceProducts={serviceProducts}
                                        onAddedValue={onUpdatePercenjate}
                                        onRemoveItem={onRemoveItem} />
                                    </Grid>
                                </Grid>
                            }
                            { retention.apply_all_product_services &&
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid>
                                        <Checkbox
                                            id={"apply_natural_person"}
                                            name={"apply_natural_person"}
                                            value={retention.apply_natural_person}
                                            checked={retention.apply_natural_person}
                                            disabled={submitting}
                                            onChange={handleCheckNaturalPerson}
                                            className="GridableCheck"
                                        />
                                    </Grid>
                                    <Grid >
                                        {translate("reception_rule.retention_validation.apply_natural_person")}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs="auto">
                                        <Checkbox
                                            id={"apply_legal_entity"}
                                            name={"apply_legal_entity"}
                                            value={retention.apply_legal_entity}
                                            checked={retention.apply_legal_entity}
                                            disabled={submitting}
                                            onChange={handleCheckLegalEntity}
                                            className="GridableCheck"
                                        />
                                    </Grid>
                                    <Grid item>
                                        {translate("reception_rule.retention_validation.apply_legal_entity")}
                                    </Grid>
                                </Grid>
                            </Grid>
                                </Grid>
                            }
                            
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="text" color="primary">
                        {translate("buttons.cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium" disabled={submitting}>
                        {translate("buttons.accept")}
                    </Button>
                </DialogActions>
            </form>
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </Dialog>
    );
}
