import { Entity as Provider } from "./Provider";
import { Address } from "./Address";
import { DocumentLine } from "./Document";
import { ExportableObject } from "./ExportableObject";
import { Cfdi } from "./Cfdi";
import { Requisition } from "./Requisition";
import { Payment } from "./Payment";

export interface PurchaseOrder extends ExportableObject {
    status: string;
    date: Date;
    due_date: Date;
    comments: string;
    currency: string;
    exchange_rate: number;
    subtotal: number;
    total: number;
    acceptance_status?: "ACCEPTED" | "REFUSED" | "PENDING_TO_REVIEW";
    acceptance_date?: Date;
    lines: DocumentLine[];
    provider?: Provider;
    addresses?: Address[];
    cfdis?: Cfdi[];
    requisition?: Requisition;
    partialities_record: PartialityRecord[];
    purchase_payments: Payment[];
    total_lines: number;
    pdf_s3_key_file: string;
    metadata: Metadata[];
    show_warehouse_deliveries_in_purchase_orders: boolean;
    cfdi_payment_form: string;
    cfdi_payment_method: string;
    cfdi_use: string;
    delivery_date: Date;
    warehouse: string;
    delivery_contact: string;
    vat_sum: number;
    withhold_tax_sum: number;
    company_id: string;
}

export interface Metadata {
    key: string;
    value: string;
}

export interface PurchaseOrders {
    items: PurchaseOrder[];
    total: number;
    restrict_upload_cfdi: Boolean;
}

export interface PartialityRecord {
    updated_date: Date;
    cfdi_id: string;
    partiallity_number: string;
    cfdi_related?: Cfdi;
}

export interface PurchaseOrdersQueryParams {
    search: string;
    status: string;
    start_date?: Date;
    end_date?: Date;
    acceptance_status?: "ACCEPTED" | "REFUSED";
    currency?: string;
    company_id: string;
}

export interface PurchaseOrderRequest {
    extern_id: string;
    status: string;
    date: Date;
    due_date: Date;
    reference?: string;
    comments?: string;
    currency: string;
    exchange_rate: number;
    total: number;
}

export const STATUSES = ['O', 'C', 'R', 'G'];

export const ACCEPTANCE_STATUSES = ['ACCEPTED', 'REFUSED', 'PENDING_TO_REVIEW'];

export interface PurchaseOrderImport {
    found: number;
    created: number;
    failed: number;
    duplicated: number;
}

export interface PurchaseOrderRequisitionRequest {
    external_id: string;
    pdf_url: string;
    // document_id: string;
    // document_url: string;
    comments: string;
    street_number: string;
    county: string;
    state: string;
    postal_code: string;
    city: string;
}
