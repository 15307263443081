import React, { useState, useEffect, useContext } from "react";
import { getProvider } from "../api/ProviderAPI";
import Surface from "../components/Surface";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ProviderAccountingBalance from "../providers/components/ProviderAccountingBalance";
import { Entity as Provider } from "../model/Provider";


export default function ProviderAccountingBalanceWidget() {
    const context = useContext(AppContext);
    const [provider, setProvider] = useState<Provider>();

    const load = () => {
        getProvider(context.session!.tenant!.id, context.session!.provider!.id).then((provider) => {
            setProvider(provider);
        }).catch((error) => {
        });
    }

    useEffect(load, []);

    return (
        <Surface title={translate("provider_accounting_balance.title")} icon={<AccountBalanceWalletIcon />}>
            {provider && (<ProviderAccountingBalance provider={provider} card_mode={false} />)}
        </Surface>
    );
}