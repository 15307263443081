import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Surface from "../components/Surface";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router-dom";
import { ProviderClassificationRequest, ProviderClassification } from "../model/ProviderClassification";
import { createProviderClassification, getProviderClassification, updateProviderClassification } from "../api/ProviderClassificationAPI";
import ProviderClassificationsIcon from '@material-ui/icons/Class';


export default function ProviderClassificationForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const isEdit = !!match.params.providerClassificationId;
    const margin = "dense";
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");

    const [request, setRequest] = useState<ProviderClassificationRequest>({
        name: ""
    } as ProviderClassificationRequest);

    const [validations, setValidations] = useState({
        name: {
            valid: false,
        } as InputRef
    } as any);

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            getProviderClassification(context.session!.tenant!.id, match.params.providerClassificationId).then((response) => {
                setRequest({
                    name: response.name
                } as ProviderClassificationRequest);
                setStatus("loaded");
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [isEdit, match.params.providerId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest((request) => {
            return { ...request, [name]: value };
        });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const submitPromise = (): Promise<ProviderClassification> => {
        if (isEdit && match.params.providerClassificationId) {
            return updateProviderClassification(context.session!.tenant!.id, match.params.providerClassificationId, request);
        }
        return createProviderClassification(context.session!.tenant!.id, request);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            setWarning(translate("provider_classifications.invalid_form") as string);
            return;
        }
        setSubmitting(true);
        submitPromise().then((_) => {
            let message = isEdit ? translate("provider_classifications.success_update", {name: request.name}) as string : translate("provider_classifications.success_create", {name: request.name}) as string;
            setRedirect(`/provider-classifications/${message}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "provider_classifications.edit_title" : "provider_classifications.new_title")} icon={<ProviderClassificationsIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name} label={translate("provider_classifications.columns.name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                        <Grid item xs={12} md="auto">
                                            <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                {translate(isEdit ? "buttons.update" : "buttons.add")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md="auto">
                                            <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                {translate("buttons.cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}