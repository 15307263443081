import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CompaniesIcon from "@material-ui/icons/BusinessCenter";
import AddIcon from "@material-ui/icons/Add";

import { listCompanies, updateInvoiceReception } from "../api/CompanyAPI";
import { AppContext } from "../context/AppContext";
import { Company, Companies, CompaniesQueryParams, CompanyInvoiceReceptionRequest } from "../model/Company";
import { SuccessSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import ConfirmationPopup from "../components/ConfirmationPopup";
import CompanyMenu from "./CompanyMenu";
import CompanyPrincipalMenu from "./CompanyPrincipalMenu";
import { RouterParams } from "../router/RouterParams";

export default function CompaniesList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Companies>();
    const [workingParams, setWorkingParams] = useState<CompaniesQueryParams>({
        search: search,
    } as CompaniesQueryParams);

    const [openDisableAllInvoiceReception, setOpenDisableAllInvoiceReception] = useState<boolean>(false);
    const [openEnableAllInvoiceReception, setOpenEnableAllInvoiceReception] = useState<boolean>(false);
    const [openPrincipalMenu, setOpenPrincipalMenu] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>();

    const [company, setCompany] = useState<Company>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);    

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());

    useEffect(() => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as CompaniesQueryParams;
        setWorkingParams(params);

        listCompanies(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (company: Company) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCompany(company);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCompany(undefined);
    };

    const onClickRow = (company: Company) => {
        history.push(`${context.isGranted("CompaniesUpdate") ? `/tenants/${tenantId}` : ""}/companies/${company.id}/edit`);
    };

    const onDisableAllInvoiceReception = () => {
        updateInvoiceReception(tenantId, { disable_all_companies: true } as CompanyInvoiceReceptionRequest).then((response) => {
            setOpenDisableAllInvoiceReception(false);
            setOpenPrincipalMenu(false);
            setAnchorEl(null);
            setSuccess(translate("companies.invoice_reception.disable_reception_success") as string);
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const onEnableAllInvoiceReception = () => {
        updateInvoiceReception(tenantId, { enable_all_companies: true } as CompanyInvoiceReceptionRequest).then((response) => {
            setOpenDisableAllInvoiceReception(false);
            setOpenPrincipalMenu(false);
            setAnchorEl(null);
            setSuccess(translate("companies.invoice_reception.enable_reception_success") as string);
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const onCloseSnackbars = () => {
        setSuccess(undefined);
      };

    return (
        <Pagination title={translate("companies.title")} icon={<CompaniesIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGrantedAny(["CompaniesCreate", "TenantsCompaniesCreate"]) ?
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Link to={`${context.isGranted("CompaniesCreate") ? `/tenants/${tenantId}` : ""}/companies/new`}>
                            <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                <AddIcon />
                            </Fab>
                        </Link>
                    </Grid>
                    {context.isGranted("CompaniesUpdateInvoiceReception") &&
                        <Grid item xs="auto">
                            <IconButton aria-label="options" color="default" size="small" onClick={(event: React.MouseEvent<HTMLElement>) => {
                                setOpenPrincipalMenu(true);
                                setAnchorEl(event.currentTarget);
                            }}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                ) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("companies.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("companies.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("companies.rfc") as string,
                        converter: (company) => company.rfc,
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("companies.name") as string,
                        converter: (company) => (
                            <Ellipsis text={company.name} lenght={100} uppercased={false} />
                        ),
                        fullWidth: true,
                        xs: 4,
                        sm: 5,
                        md: 5,
                        lg: 5,
                        xl: 7
                    },
                    {
                        title: translate("companies.external_id") as string,
                        converter: (company) => company.external_id,
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("companies.active") as string,
                        converter: (company) => translate(company.active ? "yes" : "no"),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (company) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(company)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {company && anchorEl &&
                <CompanyMenu tenantId={tenantId} company={company} anchor={anchorEl} onClose={onCloseOption} />
            }
            { openPrincipalMenu && anchorEl && 
              <CompanyPrincipalMenu
                    onDisableInvoiceReception={() => {
                        setOpenDisableAllInvoiceReception(true);
                    }}
                    onEnableInvoiceReception={() => {
                        setOpenEnableAllInvoiceReception(true);
                    }}
                    onClose={() => {
                        setOpenPrincipalMenu(false);
                        setAnchorEl(null);
                    }}
                    anchor = {anchorEl}
              />
            }
            {openDisableAllInvoiceReception && anchorEl &&
                <ConfirmationPopup
                    title={translate("companies.invoice_reception.disable_all_invoice_reception") as string}
                    message={translate("companies.invoice_reception.confirm_messsage") as string}
                    button={translate("buttons.accept") as string}
                    onClose={() => {
                        setOpenDisableAllInvoiceReception(false);
                        setOpenPrincipalMenu(false);
                        setAnchorEl(null);
                    }}
                    doAction={onDisableAllInvoiceReception}
                />
            }
            {openEnableAllInvoiceReception && anchorEl &&
                <ConfirmationPopup
                    title={translate("companies.invoice_reception.enable_all_invoice_reception") as string}
                    message={translate("companies.invoice_reception.confirm_message_enable") as string}
                    button={translate("buttons.accept") as string}
                    onClose={() => {
                        setOpenEnableAllInvoiceReception(false);
                        setOpenPrincipalMenu(false);
                        setAnchorEl(null);
                    }}
                    doAction={onEnableAllInvoiceReception}
                />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Pagination>
    );
}