import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import WidgetMenu from "./WidgetMenu";
import WidgetBalance from "./WidgetBalance";
import { AppContext } from "../context/AppContext";
import { getWidgetsMenusPendings } from "../api/DashboardAPI";
import { WidgetMenuResponse, WidgetsMenusResponse } from "../model/Dashboard";
import { isRoleOrParent } from "../model/Role";
import ProjectsRangeBudgetWidget from "./ProjectsRangeBudgetWidget";

export default function WidgetsMenus() {
    const context = useContext(AppContext);
    
    const [cfdis, setCfids] = useState<WidgetMenuResponse[]>([]);
    const [paymentOrder, setPaymentOrder] = useState<WidgetMenuResponse[]>([]);
    const [payments, setPayments] = useState<WidgetMenuResponse[]>([]);
    const [cxp, setCxp] = useState<WidgetMenuResponse[]>([]);
    const [balance, setBalance] = useState<WidgetMenuResponse[]>([]);
    const [clarifications, setClarifications] = useState<WidgetMenuResponse[]>([]);
    const [expedients, setExpedients] = useState<WidgetMenuResponse[]>([]);
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");

    const load = () => {
        let tenantId = context.session!.tenant!.id;
        getWidgetsMenusPendings(tenantId).then((response) => {
            categorizeWidgets(response);
        }).catch((error) => {
        });
    }

    useEffect(load, []);

    const categorizeWidgets = (response : WidgetsMenusResponse) => {
        let widgets = response.items;
        setCfids(getList("CFDI", widgets));
        setPaymentOrder(getList("PAYMENT_ORDER", widgets));
        setPayments(getList("PAYMENTS", widgets));
        setCxp(getList("CXP", widgets));
        setBalance(getList("BALANCE", widgets));
        setClarifications(getList("CLARIFICATION", widgets));
        setExpedients(getList("EXPEDIENT", widgets));
    }

    const getList = (type : string, list: WidgetMenuResponse[]) => {
        let widgetMenu = list.filter(function(item){
            return item.type === type;
        }).map(function({view, total, type}){
            return {view, total, type};
        });
        return widgetMenu;
    }

    return (
        <Grid>
            <Grid container direction="row" alignItems="flex-start" spacing={2}>
                {expedients.length > 0 && !isReaderOnly && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetMenu widgets={expedients} type={"EXPEDIENT"}/>
                    </Grid>
                )}
                {cfdis.length > 0 && !isReaderOnly && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetMenu widgets={cfdis} type={"CFDI"}/>
                    </Grid>
                )}
                { context.isGrantedAny(["PaymentOrdersCxpRead", "PaymentOrdersTreasurerRead"]) && paymentOrder.length > 0 && !isReaderOnly && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetMenu widgets={paymentOrder} type={"PAYMENT_ORDER"}/>
                    </Grid>
                )}
                {payments.length > 0 && !isReaderOnly && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetMenu widgets={payments} type={"PAYMENTS"}/>
                    </Grid>
                )}
                {cxp.length > 0 && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetMenu widgets={cxp} type={"CXP"}/>
                    </Grid>
                )}
                {balance.length > 0 && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetBalance widgets={balance} type={"BALANCE"}/>
                    </Grid>
                )}
                {clarifications.length > 0 && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <WidgetMenu widgets={clarifications} type={"CLARIFICATION"} />
                    </Grid>
                )}
                {context.isGrantedAny(["ProjectsRead", "ProjectsAdminRead"]) && (
                    <Grid item xs={12}>
                        <ProjectsRangeBudgetWidget />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}