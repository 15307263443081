import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import ClarificationIcon from '@material-ui/icons/ContactMail';
import { Clarifications as Report, Clarification, Statuses, ClarificationListParams } from "../model/Clarification";
import { list as listClarifications } from "../api/ClarificationApi";
import { isRoleOrParent } from "../model/Role";
import { WarningSnackbar, SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize} from "../components/Pagination";
import GridDx from "../components/GridDx";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString, formatDateToString } from "../components/DateFormat";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { FormControl, Select, Box } from "@material-ui/core";

export default function PayableDocumentsDetail() {
  const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const sDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
    const paramsFromQueryString = (): ClarificationListParams => {
        return {
          "number": typeof qs["number"] === "string" ? qs["number"] as string : "",
          "subject": typeof qs["subject"] === "string" ? qs["subject"] as string : "",
          "sender": typeof qs["sender"] === "string" ? qs["sender"] as string : "",
          "provider": typeof qs["provider"] === "string" ? qs["provider"] as string : "",
          "provider_id": typeof qs["provider_id"] === "string" ? qs["provider_id"] as string : "",
          "user_id": typeof qs["user_id"] === "string" ? qs["user_id"] as string : "",
          "init_date": typeof qs["init_date"] === "string" ? qs["init_date"] as string : formatDateToString(sDate)+" - "+formatDateToString(new Date()),
          "end_date": typeof qs["end_date"] === "string" ? qs["end_date"] as string : ""
        } as ClarificationListParams;
      };
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Report>();
    const [params, setParams] = useState<ClarificationListParams>(paramsFromQueryString);
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [redirect, setRedirect] = useState<string>();
    const isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const isOwner = isRoleOrParent(context.session!.role, "owner");

    const paramsToFilters = (): Filter[] => {
        let temp = params.init_date.split(" - ")
        return [
          { columnName: 'number', value: params.number },
          { columnName: 'subject', value: params.subject },
          { columnName: 'issuer_name', value: params.sender },
          { columnName: 'provider_name', value: params.provider },
          { columnName: 'created_at', value: formatDateString(temp[0])+" - "+formatDateString(temp[1]) },
          { columnName: 'status', value: params.status },
        ] as Filter[];
      };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters);

    const [columnsFormat] = useState([
        { columnName: 'number', width: 100 },
        { columnName: 'subject' },
        { columnName: 'issuer_name', wordWrapEnabled: true },
        { columnName: 'provider_name', wordWrapEnabled: true},
        { columnName: 'created_at', width: 150 },
        { columnName: 'status', width: 150 },
    ]) as any;

    const [leftColumns] = useState(['status', 'provider_name', 'issuer_name']) as any;

    const load = () => {
        setStatus("loading");
        pushHistory();
        let tempParams = params;
        if(isProvider){
            tempParams = {...params, provider_id: context.session!.provider!.id};
        }
        if(!isProvider && !isOwner){
            tempParams = {...params, user_id: context.session!.user!.id};
        }
        listClarifications(context.session!.tenant!.id, pageSize, pageSize * page, tempParams).then((response) => {
            setData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setParamsFromfilters = (filters : Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "number":
                        temp.number = filter.value;
                        break;
                    case "subject":
                        temp.subject = filter.value;
                        break;
                    case "issuer_name":
                        temp.sender = filter.value;
                        break;
                    case "provider_name":
                        temp.provider = filter.value;
                        break;
                    case "created_at":
                        let datesS = filter.value.split(" - ");
                        if (datesS.length === 2) {
                            temp.init_date = formatDateString(datesS[0]);
                            temp.end_date = formatDateString(datesS[1]);
                        } else {
                            temp.init_date = "";
                            temp.end_date = "";
                        }
                        break;
                    case "status":
                        temp.status = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setParams(temp);
    };

    const setFiltersHandler = (filters : Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
        setPage(0);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["number"] = params.number || "";
        qs["subject"] = params.subject || "";
        qs["issuer_name"] = params.sender || "";
        qs["provider_name"] = params.provider || "";
        qs["created_at"] = params.init_date || "";
        qs["status"] = params.status || "";
        qs["page"] = "0";
        
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onClickedRow = (clarification: Clarification) => {
        pushHistory();
        setRedirect(`/clarifications/${clarification.id}`);
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setSuccess(undefined);
        setError(undefined);
    };

    const getStatus = (item: Clarification) => {
        return translate(`clarifications.statuses.${item.status}`) as string;
    };

    const StatusFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="is_default"
                        id="is_default"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {Statuses.map((value) => {
                            return <option key={value} value={value}>{translate("clarifications.statuses." + value)}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const columns = [
        {
            name: 'number',
            title: translate("clarifications.columns.number") as string,
        },
        {
            name: 'subject',
            title: translate('clarifications.columns.subject') as string
        },
        {
            name: 'issuer_name',
            title: translate('clarifications.columns.issuer_name') as string,
        },
        {
            name: 'provider_name',
            title: translate('clarifications.columns.provider') as string,
        },
        {
            name: 'created_at',
            title: translate('clarifications.columns.date') as string,
        },
        {
            name: 'status',
            title: translate('clarifications.columns.status') as string,
            getCellValue: (row: any) => getStatus(row)
        },
    ];

const textColumns = ['number', 'subject', 'issuer_name', 'status', 'provider_name'];

const dateColumns = ['created_at'];

const clickRowColumns = ['number'];

const customPlugins = [
    <NormalTypeProvider for={['status']} editorComponent={StatusFilterEditor} />
];

    return (
        <Surface title={translate("clarifications.title_section_messages") as string} 
            icon={<ClarificationIcon />}
            className="PaperPagination" >
            <GridDx
                loading={status === "loading"}
                rows={data ? data.items : []}
                page={page}
                pageSize={pageSize}
                totalRows={data ? data.total : 0}
                columns={columns ? columns : []}
                columnsFormat={columnsFormat}
                onClickRow={onClickedRow}
                clickRowColumns={clickRowColumns}
                onClickedOptions={() => {}}
                dateColumns={dateColumns}
                leftColumns={leftColumns}
                textColumns={textColumns}
                customFormatColumns={customPlugins} 
                filters={filters}
                onChangedPage={onChangedPage}
                onChangedPageSize={onChangedPageSize}
                setFiltersHandler={setFiltersHandler}
                heightTablePX={450}
                noUseId={true}
                showTimeInDates={true}
            />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );
}
