import React, { useState } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from "@material-ui/core";
import { Theme, withStyles } from '@material-ui/core/styles'
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ActorFields, FieldPermission, StageActors } from "./Model";

const TitleBox = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
    },
}))(Box)

interface WorkflowTemplateStageFormProps {
    stageActor: StageActors;
    onUpdated(stageActor: StageActors): any;
    onClose(): any
    phaseTranslator(externalId: string): string;
}

function WorkflowTemplateStageForm({ stageActor, onUpdated, onClose, phaseTranslator }: WorkflowTemplateStageFormProps) {
    const [request, setRequest] = useState<StageActors>(() => {
        const el = stageActor ?? {
            actors: [],
            stage_id: "",
        } as StageActors;

        el.actors.forEach((actor) => {
            actor.actor_id = actor.actor_id ?? "";
            actor.fields.forEach((field) => {
                field.field_id = field.field_id ?? "";
                field.read = field.read ?? false;
                field.write = field.write ?? false;
                field.required = field.required ?? false;
            });
        });

        return el;
    });

    const onChangeCheckbox =
        (
            name: 'read' | 'write' | 'required',
            actorField: FieldPermission,
            iActorFields: number,
            iActorField: number
        ) =>
            (event: React.ChangeEvent<{}>, checked: boolean) => {
                let newField: FieldPermission
                if (name === 'read' && !checked) {
                    newField = {
                        ...actorField,
                        read: false,
                        write: false,
                        required: false,
                    }
                } else if (name === 'write' && !checked) {
                    newField = {
                        ...actorField,
                        write: false,
                        required: false,
                    }
                } else {
                    newField = { ...actorField, [name]: checked }
                }

                const newActors = request.actors.map((el, ai) => {
                    if (ai === iActorFields) {
                        const fields = el.fields.map((fel, fi) => (fi === iActorField) ? newField : fel);
                        return { ...el, fields: fields };
                    }
                    return el;
                })
                setRequest({ ...request, actors: newActors });
            };

    const checkbox = (
        name: 'read' | 'write' | 'required',
        actorField: FieldPermission,
        iActorFields: number,
        iActorField: number
    ) => (
        <Typography component="h5" align="center">
            <Checkbox
                name={`${iActorFields}-${iActorField}_${name}`}
                checked={actorField[name]}
                disabled={
                    (name !== 'read' && !actorField.read) ||
                    (name === 'required' && !actorField.write)
                }
                onChange={onChangeCheckbox(
                    name,
                    actorField,
                    iActorFields,
                    iActorField
                )}
            />
        </Typography>
    );

    const onChangeAllCheckbox = (el: ActorFields, iActorFields: number) =>
        (event: React.ChangeEvent<{}>, checked: boolean) => {
            const newFields = el.fields.map((field) => {
                return { ...field, read: checked, write: checked, required: checked };
            });
            const actorFields = { ...el, _checked: checked, fields: newFields };
            const newActors = request.actors.map((el, ai) => ai === iActorFields ? actorFields : el);

            setRequest({ ...request, actors: newActors });
        };

    const allCheckbox = (
        actorFields: ActorFields,
        iActorFields: number,
    ) => (
        <FormGroup row>
            <FormControlLabel
                label={(
                    <small>
                        {translate("workflows.permission_all")}
                    </small>
                )}
                labelPlacement="start"
                control={(
                    <Checkbox
                        name={`${iActorFields}-actorField_all`}
                        checked={actorFields._checked}
                        onChange={onChangeAllCheckbox(
                            actorFields,
                            iActorFields,
                        )}
                    />
                )}
                style={{ marginBottom: "0" }}
            />
        </FormGroup >
    );

    return (
        <DialogPopup open
            title={translate(phaseTranslator(stageActor._stage_external_id || "-"))}
            fullWidth
            maxWidth="md"
            buttonLeft={(
                <Button variant="contained" color="primary" size="medium" style={{ visibility: "hidden" }}>
                    {translate("buttons.update")}
                </Button>
            )}
            button={(
                <Button variant="contained" color="primary" size="medium" onClick={() => onUpdated(request)}>
                    {translate("buttons.update")}
                </Button>
            )}
            closeText={translate("buttons.cancel") as string}
            onClose={onClose}
        >
            <Typography variant="body2" gutterBottom>
                {translate("workflows.permission_instructions")}
            </Typography>
            {request.actors.map(
                (actorFields, yActorFields) => (
                    <Grid key={yActorFields} container>
                        <Grid item xs={12}>
                            <TitleBox mx={-3} mt={1} mb={2} px={3}>
                                <Grid container justify="center" alignContent="center" alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="body2" component="h6">
                                            <strong>{actorFields._actor_name}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        {allCheckbox(actorFields, yActorFields)}
                                    </Grid>
                                </Grid>
                            </TitleBox>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary" component="h6" gutterBottom>
                                {translate('workflows.field')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="textSecondary" component="h6" gutterBottom>
                                {translate('workflows.description')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" color="textSecondary" component="h6" align="center" gutterBottom>
                                {translate('workflows.read')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" color="textSecondary" component="h6" align="center" gutterBottom>
                                {translate('workflows.write')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" color="textSecondary" component="h6" align="center" gutterBottom>
                                {translate('workflows.required')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {actorFields.fields.map((actorField, iActorField) => (
                                <Grid
                                    key={
                                        iActorField
                                    }
                                    container
                                    justify="flex-start"
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs>
                                        {actorField._field_name}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {actorField._field_description || '---'}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {checkbox(
                                            'read',
                                            actorField,
                                            yActorFields,
                                            iActorField
                                        )}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {checkbox(
                                            'write',
                                            actorField,
                                            yActorFields,
                                            iActorField
                                        )}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {checkbox(
                                            'required',
                                            actorField,
                                            yActorFields,
                                            iActorField
                                        )}
                                    </Grid>
                                </Grid>
                            )
                            )}
                        </Grid>
                    </Grid>
                )
            )}
        </DialogPopup>
    );
}

export default WorkflowTemplateStageForm