import React, { useState, useContext, ReactNode } from "react";
import translate from "../i18n/Translator";
import { Typography, Grid, Button, Divider, List, ListItem, ListItemIcon, ListItemText, Checkbox } from "@material-ui/core";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DialogPopup from "../components/DialogPopup";
import { AppContext } from "../context/AppContext";
import { ApartmentIcon, HomeWorkIcon } from "../components/Icons";
import Gridable from "../components/Gridable";
import { Payment } from "../model/Payment";
import DateFormat from "../components/DateFormat";
import NumberFormat from "react-number-format";
import { generatePaymentCfdi } from "../api/PaymentApi";
import ConfirmationPopup from "../components/ConfirmationPopup";

interface CreatePaymentCfdiModalProps {
    onClose(): any;
    onRedirectBuyPlan(): any;
    onSuccess(paymentCfdiId: string): any;
    payment: Payment;
    gridPaymentCfdis: ReactNode;
}

export default function CreatePaymentCfdiModal(props: CreatePaymentCfdiModalProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const rfcProvider = context.session?.provider?.rfc;
    const nameProvider = context.session?.provider?.name;
    const data = [props.payment] as Payment[];
    const tenantId = context.session?.tenant?.id || "";
    const [readed, setReaded] = useState<boolean>(false);

    const onGenerate = () => {
        setSubmitting(true);
        generatePaymentCfdi(tenantId, props.payment.id).then((response) => {
            if(response.success_attach){
                props.onSuccess(response.payment_cfdi.id);
            } else {
                setError(response.payment_cfdi.error);
            }
        }).catch((error) => {
            if(error.code === 9603 || error.code === 9604){
                setErrorMessage(error.message);
            } else {
                setError(error.message);
            }
        })
        .finally(() => setSubmitting(false));
    };

    return (
        <DialogPopup title={translate("generate_payment_cfdi.title") as string} open maxWidth="md" onClose={props.onClose}
            button={<Button onClick={onGenerate} variant="contained" color="primary" size="medium" disabled={readed === false}>
                    {translate("generate_payment_cfdi.button_generate")}
                </Button>} buttonLeft={<Grid item xs={12}>
                                    <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                                        <Grid item xs="auto" >
                                            <Checkbox
                                                id="ad-readed"
                                                name="ad-readed"
                                                checked={readed}
                                                onChange={() => setReaded(!readed)}
                                                disabled={false}
                                                className="GridableCheck"
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            {translate("generate_payment_cfdi.confirm_generate_check", {currency: props.payment.currency, total: <NumberFormat value={props.payment.total_amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text"  thousandSeparator=","/>})}
                                        </Grid>
                                    </Grid>
                                </Grid>}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container alignItems="center" alignContent="center">
                                <Grid item xs={12}>
                                    <strong>{translate("cfdis.details.emitter")}</strong>
                                </Grid>
                                <Grid item xs={12}>
                                    <List component="nav" dense>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={nameProvider}
                                                secondary={rfcProvider} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container alignItems="center" alignContent="center">
                                <Grid item xs={12}>
                                    <strong>{translate("cfdis.details.receiver")}</strong>
                                </Grid>
                                <Grid item xs={12}>
                                    <List component="nav" dense>
                                        <ListItem>
                                            <ListItemIcon>
                                                <HomeWorkIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={context.session!.tenant!.name}
                                                secondary={context.session!.tenant!.rfc} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography><strong>{translate("generate_payment_cfdi.general_data") as string}</strong></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Gridable
                                items={data}
                                loading={false}
                                columns={[
                                    {
                                        title: translate("generate_payment_cfdi.payment.date") as string,
                                        converter: (item) => <DateFormat date={item.payment_date} format="DD/MM/YYYY" />,
                                        xs: true
                                    },
                                    {
                                        title: translate("generate_payment_cfdi.payment.payment_form") as string,
                                        converter: (item) => item.operation_name,
                                        xs: true
                                    },
                                    {
                                        title: translate("currency.title") as string,
                                        converter: (item) => item.currency,
                                        xs: true
                                    },
                                    {
                                        title: translate("generate_payment_cfdi.payment.exchange") as string,
                                        converter: (item) => item.cfdis[0].exchange_rate,
                                        xs: true
                                    },
                                    {
                                        title: translate("generate_payment_cfdi.payment.total") as string,
                                        converter: (item) => <NumberFormat value={item.total_amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" thousandSeparator="," />,
                                        xs: true
                                    },
                                    {
                                        title: translate("generate_payment_cfdi.payment.operation") as string,
                                        converter: (item) => item.operation_number,
                                        xs: true
                                    },
                                    {
                                        title: translate("generate_payment_cfdi.payment.account") as string,
                                        converter: (item) => item.bank_account_name,
                                        xs: true
                                    },
                                ]} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography><strong>{translate("generate_payment_cfdi.cfdi_data") as string}</strong></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {props.gridPaymentCfdis}
                        </Grid>
                    </Grid>
                    {errorMessage && 
                        <ConfirmationPopup
                            title={translate("providers.announcements.confirm_send.title") as string}
                            message={errorMessage}
                            secondary={translate("providers.announcements.confirm_send.secondary") as string}
                            button={translate("buttons.accept") as string}
                            onClose={props.onClose}
                            doAction={props.onRedirectBuyPlan}
                            submitting={readed === false}
                        />
                    }
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
        </DialogPopup>);
}
