import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../../i18n/Translator";

import { deleteCfdis } from "../../api/ProviderCfdiApi";
import DialogPopup from "../../components/DialogPopup";
import { DeleteCfdisRequest } from "../../model/ProviderCfdi";

interface CfdisDeletePopupProps {
    cfdiIds: string[];
    tenantId: string;
    providerId: string;
    onCompleted(total: number): any;
    onError(error: string): any;
    onCancelled(): any;
}

export default function CfdisDeletePopup(props: CfdisDeletePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onConfirm = () => {
        setSubmitting(true);
        deleteCfdis(props.tenantId, props.providerId, { cfdi_ids: props.cfdiIds} as DeleteCfdisRequest).then((response) => {
            setSubmitting(false);
            props.onCompleted(response.total_deleted);
        }).catch((error) => {
            setSubmitting(false);
            props.onError(error.message);
        });
    };

    return (
        <DialogPopup open
            title={translate("cfdis.popup.multi_delete.title") as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onConfirm} variant="outlined" color="secondary" disabled={submitting}>
                    {translate("buttons.delete")}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate("cfdis.popup.multi_delete.description")}
            </Typography>
        </DialogPopup>
    );

}