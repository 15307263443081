import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { ZipDownloads, ZipDownload, ZipDownloadsQueryParams } from "../model/ZipDownload";

const basePath = (tenantId: string) => `/tenants/${tenantId}/zip-downloads`;

export async function listZipDownloads(tenantId: string, page: number, offset: number, params: ZipDownloadsQueryParams): Promise<ZipDownloads> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function getZipDownload(tenantId: string, id: string): Promise<ZipDownload> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function deleteZipDownload(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function downloadUrl(tenantId: string, id: string): Promise<string> {
    return await getAuthenticatedGetUrl(`${basePath(tenantId)}/${id}/file`);
}