import { Efos, EfoValidationAll } from "./Efos";
import { Cff69ValidationAll } from "./Cff69";
import { User } from "./User";
import { ProviderExpedient } from "./ProviderExpedient";
import { Contact } from "./Contact";
import { ShippingTemplate } from "./ShippingTemplate";
import { SpecificReceptionRule } from "./SpecificReceptionRule";
import { BlackListValidations } from "./Cff69";
import { Workflow } from "./Workflow";
import { ProviderClassification } from "./ProviderClassification";
import { Company } from "./Company";

export interface Entity {
    id: string;
    name: string;
    rfc: string;
    type: "REGULAR" | "CUSTOMS_AGENT" | "FOREIGN" | "VENDOR";
    external_id?: string;
    tax_id?: string;
    country?: string;
    efos?: Efos;
    expedient?: ProviderExpedient;
    validator_user?: User;
    receptor_users?: User[];
    second_authorizator_users?: User[];
    status: string;
    provider_classifications?: ProviderClassification[];
    accounting_balance: AccountingBalance;
    shipping_template: ShippingTemplate;
    workflows: string[];
    workflows_response: Workflow[];
    specific_rule: SpecificReceptionRule;
    ledger_assignment: LedgerAssignment;
    black_list_validations?: BlackListValidations;
    use_tenant_config_credit_days: boolean;
    max_credit_days: number;
    use_max_payment_cfdi_pending_tenant_config: boolean;
    unlimited_payment_cfdi_pending: boolean;
    max_payment_cfdi_pending: number;
    use_max_cfdi_pending_doc_tenant_config: boolean;
    unlimited_cfdi_pending_doc: boolean;
    max_cfdi_pending_doc: number;
    max_payment_cfdi_pending_tenant_config: number;
    use_cash_discount: boolean;
    cash_discount_percentage?: number;
    cash_discount_days?: number;
    skip_reception_date_validation: boolean;
    use_advance_invoices: boolean;
    expedient_template_name?: string;
    require_consignment_note: string;
    allow_invoice_reception: boolean;
    companies: string[];
    company_objects: Company[];
    expedient_template_id?: string;
    only_se: boolean;
    upload_sipare: boolean;
    declaration_frequency?: DeclarationFrequency;
    make_advance_payments?: boolean;
    allow_make_advance_payments?: boolean;
    status_membership_tax_stamp?: string;
    membership_tax_stamp_id?: string;
    membership_tax_stamp_expired_date?: string;
}

export function getCompanyNames(entity: Entity): string {
    if (entity.company_objects) {
        return entity.company_objects.map((c) => c.name).reverse().join(", ");
    }
    return "---";
}

export interface AccountingBalance {
    debt_totals: Map<String, number>;
    debt_totals_counter: number;
    authorizing_totals: Map<String, number>;
    authorizing_totals_counter: number;
    paying_totals: Map<String, number>;
    paying_totals_counter: number;
    payed_totals: Map<String, number>;
    payed_totals_counter: number;
}

export interface ImportSummary {
    found: number;
    created: number;
    duplicated: number;
    failed: number;
    active: number;
    inactive: number;
    exceeded: number;
    validation_efo?: EfoValidationAll;
    validation_cff69?: Cff69ValidationAll;
    template_with_errors: string;
}

export interface ImportUsersSummary {
    found: number;
    created: number;
    duplicated: number;
    failed: number;
    template_with_errors: string;
}

export interface Providers {
    items: Entity[];
    total: number;
    classifications: ProviderClassification[];
    validators: User[];
    specific_rules: SpecificReceptionRule[];
    workflows: Workflow[];
}

export interface ProvidersQueryParams extends ProvidersQueryFilter {
    not_located_status: string;
    canceled_status: string;
    enforceable_status: string;
    firm_status: string;
    sentences_status: string;
    in_order: string;
    type: string;
    selected_ids: string;
    entity_type: string;
    make_advance_payments: 'true' | 'false' | undefined ;
    requisition_mode: boolean;
    template_id: string;
    company_id: string;
}

export interface ProvidersSpecialExpedientParams {
    external_id: string;
    name: string;
    rfc: string;
    company_id: string;
    classification: string;
    status_expedient: string;
    template_id: string;
    reviewers_ids: string;
}

export interface ProvidersQueryFilter {
    search: string;
    efos_status: string;
    black_list_status: string;
    status: string;
    status_expedient: string;
    classification: string;
    validator_user_id: string;
    receptor: string;
    specific_rule: string;
    workflow_id: string;
}

export interface ProviderRequest {
    name: string;
    rfc: string;
    type: "REGULAR" | "CUSTOMS_AGENT" | "FOREIGN" | "VENDOR";
    tax_id?: string;
    external_id?: string;
    workflows?: string[];
    contacts?: Contact[];
    country: string;
    enabled: boolean;
    use_tenant_credit_days: boolean;
    credit_days: number;
    max_payment_cfdi_pending: number;
    unlimited_payment_cfdi_pending: boolean;
    use_max_payment_cfdi_pending_tenant_config: boolean;
    max_cfdi_pending_doc?: number;
    unlimited_cfdi_pending_doc?: boolean;
    use_max_cfdi_pending_doc_tenant_config?: boolean;
    use_cash_discount: boolean;
    cash_discount_percentage?: number;
    cash_discount_days?: number;
    skip_reception_date_validation: boolean
    use_advance_invoices: boolean;
    only_se: boolean;
    upload_sipare: boolean;
    declaration_frequency?: DeclarationFrequency;
    require_consignment_note: string;
    allow_invoice_reception: boolean;
    companies: string[];
    template_id: string;
    make_advance_payments: boolean;
    allow_make_advance_payments: boolean;
}

export interface ExportProviders {
    total: number;
    url?: string;
}

export interface AssignValidatorRequest {
    provider_ids: string[];
    validator_user_id: string;
}

export interface AssignValidatorResponse {
    validator: User;
    items: number;
    updated: number;
}

export interface QuickVerificationRequest {
    rfc: string;
}

export interface QuickVerificationResponse {
    efos: Efos;
    black_list_validations: BlackListValidations;
}

export const ProviderStatuses = ["ENABLED", "DISABLED"];

export interface ProviderUpdateClassificationsRequest {
    providers: string[],
    append: string[],
    remove: string[]
}

export interface ProviderClassificationsResponse {
    classifications: string[];
}

export interface ProviderUpdateClassificationsResponse extends ProviderClassificationsResponse {
    updated: number;
}

export interface MetadataResponse {
    limit_reached: boolean;
}

export interface ProviderAssignSpecificRuleRequest {
    provider_ids: string[];
    specific_rule_id: string;
}

export interface ProviderAssignSpecificRuleResponse {
    updated: number;
}

export interface ProviderAssignWorkflowRequest {
    workflow_ids: string[];
    provider_ids: string[];
}

export interface ValidateProvidersWorkflowDeleteRequest {
    workflow_id: string;
    providers: string[];
}

export interface ValidateProvidersWorkflowDeleteResponse {
    content: boolean;
}

export interface ProviderAssignTemplateResponse {
    updated: number;
    items: number;
}

export interface AutocompleteProvider {
    title: string;
    value: string;
}

export interface LedgerAssignment {
    need_ledger_assignment: boolean;
    signature: string;
    ledger_accounts_response: ProviderLedgerAccount[];
    use_allocation: boolean;
}

export interface ProviderLedgerAccount {
    public_id: string;
    name: string;
    number: string;
    use_all_cost_centers: boolean;
    cost_centers_response: ProviderCostCenter[];
}

export interface ProviderCostCenter {
    public_id: string;
    erp_id: string;
    name: string;
    category: string;
}

export interface ProviderLedgerAccountRequest {
    id: string;
    use_all_cost_centers: boolean;
    cost_centers: string[];
}

export interface LedgerAssignmentRequest {
    need_ledger_assignment: boolean;
    force_delete: boolean;
    old_account: string;
    provider_ledger_account_request: ProviderLedgerAccountRequest;
}

export interface ProviderTermCondition {
    date_accepted: Date,
    ip: string;
    user_name: string;
    provider_name: string;
}

export interface ProviderTermsConditions {
    items: ProviderTermCondition[];
    total: number;
}

export const PROVIDER_TYPES = ["REGULAR", "CUSTOMS_AGENT", "FOREIGN"];

export const CONSIGNMENT_NOTE_OPTIONS = ["NOT_REQUIRED", "ALERT_WITHOUT", "REQUIRED"];

export interface ProvidersByTypeResponse {
    total_regular: number;
    total_customs_agent: number;
    total_foreign: number;
}

export const PROVIDER_EXPEDIENT_STATUS = [ "PENDING", "NO_EXPEDIENT", "EDITED", "SENT", "REJECTED",  "ACCEPTED",  "EXPIRED"];

export type DeclarationFrequency = 'MONTHLY' | 'BIMONTHLY';

export const DECLARATION_FREQUENCIES: DeclarationFrequency[] = ['MONTHLY', 'BIMONTHLY'];