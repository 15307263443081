import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";

import translate from "../i18n/Translator";
import { Contract } from "../model/Contract";
import { AppContext } from "../context/AppContext";
import { getDocumentUrl } from "../api/ContractAPI";

interface ContractMenuProps {
    contract: Contract;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function ContractMenu(props: ContractMenuProps) {
    const context = useContext(AppContext);
    const [documentUrl, setDocumentUrl] = useState<string>();

    useEffect(() => {
        getDocumentUrl(props.contract.tenant_id, props.contract.customer_id, props.contract.id).then(url => {
            setDocumentUrl(url);
        }).catch(error => {
            console.error(error);
        })
    }, [props.contract]);

    return (
        <Menu
            id="contracts-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("ContractsUpdate") &&
                <MenuItem button component={Link} to={`/customers/${props.contract.customer_id}/contracts/${props.contract.id}/edit`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            <MenuItem button onClick={props.onClose} dense component="a" href={documentUrl || "#"} target="_blank" rel="noopener noreferrer" disabled={!documentUrl}>
                <ListItemIcon>
                    <DownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("contracts.download")}</Typography>
            </MenuItem>
        </Menu>
    );

}