import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, Tab, Grid, AppBar, Button, Box, FormControlLabel } from "@material-ui/core";
import Progress from "../components/Progress";
import Error from "../components/Error";
import Switch from '@material-ui/core/Switch';

import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";

import { ReceptionRuleUsesRequest, ReceptionRuleTypesRequest, RuleReceptionType } from "../model/ReceptionRule";
import { RetentionRule } from "../model/ReceptionRule"; 
import { TaxRegimen } from "../model/TaxRegimen";
import { ServiceProduct } from "../model/ServiceProduct";
import { getReceptionRule, updateTypes } from "../api/ReceptionRuleAPI";

import ReceptionRuleUsesTab from "./ReceptionRuleUsesTab";
import ReceptionRuleCartaPorte from "./ReceptionRuleCartaPorte";
import ReceptionRulesPaymentsTab from "./ReceptionRulesPaymentsTab";
import ReceptionRuleRetentionRules from "./ReceptionRuleRetentionRules";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CreateRetentionMenu from "./CreateRetentionMenu";
import RetentionMenu from "./RetentionMenu";
import SelectDefaultRetentionPopUp from "./SelectDefaultRetentionPopUp";
import AddNewRetentionPopUp from "./AddNewRetentionPopUp";
import ConfirmationPopup from "../components/ConfirmationPopup";

type TabName = "payment_method" | "use" | "carta_porte" | "retention_validations";

export default function ReceptionRuleEditTabs({ match }: RouterParams) {
    const typeParam = match.params.type;
    const context = useContext(AppContext);
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const [tabs, setTabs] = useState<TabName[]>([]);

    const [value, setValue] = React.useState(0);
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();

    const [state, setState] = useState<boolean>();
    const [ruleType, setRuleType] = useState<RuleReceptionType>();
    const [dataUsesCfdi, setDataUsesCfdi] = useState<string[]>([]);
    const [retentionsRules, setRetentionsRules] = useState<RetentionRule[]>([]);

    const [retentionsDefault, setRetentionsDefault] = useState<RetentionRule[]>([]);
    const [dataPaymentsMethodCfdi, setDataPaymentsMethodCfdi] = useState<Map<string, string[]>>({} as Map<string, string[]>);
    const [allowDifferentServiceProducts, setAllowDifferentServiceProducts] = useState<boolean>();
    const [serviceProducts, setServiceProducts] = useState<ServiceProduct[]>([]);
    const [allServiceProducts, setAllServiceProducts] = useState<ServiceProduct[]>([]);
    const [taxRegimens, setTaxRegimens] = useState<TaxRegimen[]>([]);

    const [openCreateRetention, setOpenCreateRetention] = useState<boolean>(false);
    const [openDefaultRetentions, setOpenDefaultRetentions] = useState<boolean>(false);
    const [openNewRetention, setOpenNewRetention] = useState<boolean>(false);
    const [openEditRetention, setOpenEditRetention] = useState<boolean>(false); 
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [openDeleteRetention, setOpenDeleteRetetion] = useState<boolean>(false); 
    const [openUpdateStatusRetention, setOpenUpdateStatusRetention] = useState<boolean>(false);  

    const [retentionSelected, setRetentionSelected] = useState<RetentionRule>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const load = () => {
        setStatus("loading");
        Promise.all([getReceptionRule(context.session!.tenant!.id)])
        .then((results) => {
            let receptionResponse = results[0];
            const rulesTypes = (receptionResponse.types.filter(c => typeParam && c.sat_cfdi_type && c.sat_cfdi_type === typeParam));
            const ruleType = rulesTypes[0];

            setRuleType(ruleType);
            setDataUsesCfdi(ruleType.uses);
            setDataPaymentsMethodCfdi(ruleType.payments);
            setRetentionsDefault(receptionResponse.retention_rules_default ? receptionResponse.retention_rules_default.rules : []);
            setState(ruleType.active);
            setAllowDifferentServiceProducts(ruleType.allow_different_service_products);
            setServiceProducts(ruleType.service_products || []);
            setRetentionsRules(ruleType.retention_rules || []);
            setTaxRegimens(receptionResponse.tax_regimens || []);
            const tabs = ["use"] as TabName[];
            if (match.params.type === 'I' || match.params.type === 'N') {
                tabs.push("payment_method");
            }
            if (match.params.type === 'I' || match.params.type === 'E') {
                tabs.push("carta_porte");
            }
            if(match.params.type === 'I'){
                tabs.push("retention_validations");
            }
            setTabs(tabs);
            setAllServiceProducts(receptionResponse.service_products_in_retentions || []);

            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [match.params.type]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.checked);
    };

    const updatePaymentsAction = (payments: Map<string, string[]>) => {
        setDataPaymentsMethodCfdi(payments);
    };

    const updateUseCfdiAction = (request: ReceptionRuleUsesRequest) => {
        setDataUsesCfdi(request.uses);
    };

    const onUpdateServiceProducts = (serviceProducts: ServiceProduct[]) => {
        setServiceProducts(serviceProducts);
    };

    const onUpdateServiceProductsForRetentions = (serviceProducts: ServiceProduct[]) => {
        setAllServiceProducts(serviceProducts);
    };

    const onUpdateAllowDifferentServiceProducts = (checked: boolean) => {
        setAllowDifferentServiceProducts(!checked);
    };

    const updateTypesAction = () => {
        if (ruleType!.sat_cfdi_type !== "E" && ruleType!.sat_cfdi_type !== "P" && (!dataPaymentsMethodCfdi || Object.keys(dataPaymentsMethodCfdi).length <= 0)) {
            setWarning(translate(`reception_rule.payments_method.pue_empty_selection`) as string);
            return;
        }
        if (!dataUsesCfdi || dataUsesCfdi.length <= 0) {
            setWarning(translate(`reception_rule.use_cfdi.empty_selection`) as string);
            return;
        }

        const request = {
            uses: dataUsesCfdi,
            payments: dataPaymentsMethodCfdi,
            active: state,
            type: match.params.type,
            allow_different_service_products: allowDifferentServiceProducts,
            service_products: serviceProducts,
            retention_rules: retentionsRules
        } as ReceptionRuleTypesRequest;

        updateTypes(context.session!.tenant!.id, request).then((response) => {
            setSuccess(translate(`reception_rule.types_cfdi.success`) as string);
            history.goBack();
        }).catch((error) => {
            setError(error);
        });
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setWarning(undefined);
        setSuccess(undefined);
    };

    const selectCreateRetention = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenCreateRetention(true);
    };

    const openSelectDefaultRetention = () => {
        setOpenCreateRetention(false);
        setOpenDefaultRetentions(true);
    };

    const onOpenNewRetention = () => {
        setOpenCreateRetention(false);
        setOpenNewRetention(true);
    };

    const onOpenEditRetention = (retention?: RetentionRule) => {
        if(!retentionSelected && retention){
            setRetentionSelected(retention);
        }
        setOpenEditRetention(true);
        setOpenOptions(false);
    };

    const onOpenUpdateStatusRetention = () => {
        setOpenUpdateStatusRetention(true);
        setOpenOptions(false);
    };

    const onOpenDeleteRetention = () => {
        setOpenDeleteRetetion(true);
        setOpenOptions(false);
    };

    const closeRetentions = () => {
        setOpenDefaultRetentions(false);
        setOpenNewRetention(false);
        setOpenEditRetention(false);
        setAnchorEl(null);
        setOpenOptions(false);
        setRetentionSelected(undefined);
    }

    const onClickedOptionsRetention = (item: RetentionRule, anchorEl: null | HTMLElement) => {
        setRetentionSelected(item);
        setAnchorEl(anchorEl);
        setOpenOptions(true);
    };

    const onDeleteRetention = () => {
        if(!retentionSelected){
            return;
        }
        let itemToDelete = retentionsRules.find(v => v.tax_regimen_id === retentionSelected.tax_regimen_id);
        if (itemToDelete) {
            let index = retentionsRules.indexOf(itemToDelete);
            if (index > -1) {
                retentionsRules.splice(index, 1);
            }
        }
        onCancelDelete();
    }

    const onUpdateStatusRetention = () => {
        if(!retentionSelected){
            return;
        }
        retentionsRules.forEach(item => {
            if(item.tax_regimen_id === retentionSelected.tax_regimen_id){
                item.active = item.active === true ? false : true;
            }
        });
        onCancelUpdateStatus();
    }

    const onCancelUpdateStatus = () => {
        setOpenUpdateStatusRetention(false);
        setOpenOptions(false);
        setRetentionSelected(undefined);
    };

    const onCancelDelete = () => {
        setOpenDeleteRetetion(false);
        setOpenOptions(false);
        setRetentionSelected(undefined);
    };

    const taxRegimenDuplicate = (retention: RetentionRule) => {
        let message = "";
        if(retentionsRules.length > 0){
            retentionsRules.forEach(item => {
                if(item.tax_regimen_id === retention.tax_regimen_id && 
                    ((!retention.id && retention.temp_id !== item.temp_id) 
                    || (retention.id && item.id !== retention.id))){
                    let tax = taxRegimens.find(taxr => taxr.id === item.tax_regimen_id);
                    if(tax && message === ""){
                        message = translate(`reception_rule.retention_validation.already_tax_regimen`, {tax_regimen: tax ? tax.description : item.tax_regimen_id, validation_conflict: item.name}) as string;
                    }
                }
                 
            });
        }
        return message;
    }

    const getMessageValidateProductServices = (retention: RetentionRule) => {
        if (retention.apply_all_product_services && !retention.retention_isr && !retention.retention_iva) {
            return translate(`reception_rule.retention_validation.no_zero_both`) as string;
        } else if (!retention.apply_all_product_services) {
            if(!retention.retentions || retention.retentions.length <= 0){
                return translate(`reception_rule.retention_validation.empty_product_services`) as string;
            }
            let findInvalid = retention.retentions.filter(c => !c.retention_isr && !c.retention_iva);
            if (findInvalid && findInvalid.length > 0) {
                let names = findInvalid.map(id => `${id.product_service_key},` as string);
                return translate(`reception_rule.retention_validation.no_zero_both`, {text: translate(`reception_rule.retention_validation.no_zero_serv_prod`, {keys: names}) as string }) as string;
            }
        }
        return "";
    }

    const onAddRetention = (retention: RetentionRule, isEdit: boolean) => {
        if(!retention){
            return;
        }
        if(isEdit){
            retentionsRules.forEach(item => {
                if((retention.id && item.id && item.id === retention.id) || 
                    (!retention.id && !item.id && retention.temp_id === item.temp_id)){
                    item.active = retention.active;
                    item.apply_all_product_services = retention.apply_all_product_services;
                    item.name = retention.name;
                    item.retention_isr = retention.retention_isr;
                    item.retention_iva = retention.retention_iva;
                    item.retentions = retention.retentions;
                    item.tax_regimen_id = retention.tax_regimen_id;
                    item.tolerance = retention.tolerance;
                    item.apply_legal_entity = retention.apply_legal_entity;
                    item.apply_natural_person = retention.apply_natural_person;
                }
            });
        } else {
            retentionsRules.push(retention);
        }
        setOpenDefaultRetentions(false);
        setOpenNewRetention(false);
        setOpenEditRetention(false);
    }

    const getTab = (tabName: TabName, index: number) => {
        let titleKey: string;
        switch (tabName) {
            case "carta_porte":
                titleKey = "reception_rule.carta_porte.grid_title";
                break;
            case "payment_method":
                titleKey = "reception_rule.payments_method.grid_title";
                break;
            case "retention_validations":
                titleKey = "reception_rule.retention_validation.grid_title";
                break;
            default:
                titleKey = "reception_rule.use_cfdi.grid_title";
        }

        return (
            <Tab key={tabName} label={translate(titleKey)} {...a11yProps(index)} />
        );
    };

    const getTabPanel = (tabName: TabName, index: number) => {
        switch (tabName) {
            case "carta_porte":
                return (
                    <ReceptionRuleCartaPorte
                        selected={serviceProducts}
                        allowDifferentServiceProducts={allowDifferentServiceProducts}
                        onUpdateChecked={onUpdateAllowDifferentServiceProducts}
                        onUpdateProducts={onUpdateServiceProducts} />
                );
            case "payment_method":
                return (
                    <ReceptionRulesPaymentsTab
                        selectedMethods={dataPaymentsMethodCfdi ? dataPaymentsMethodCfdi : {} as Map<string, string[]>}
                        update={updatePaymentsAction} />
                );
            case "retention_validations":
                return (
                    <ReceptionRuleRetentionRules
                        retentionsRulesDefault={retentionsDefault}
                        receptionRules={retentionsRules} 
                        serviceProducts={serviceProducts}
                        taxRegimens={taxRegimens}
                        onClickRowEdit={onOpenEditRetention}
                        onCreateRetention={selectCreateRetention}
                        onClickedOptions={onClickedOptionsRetention}/>
                );
            default:
                return (
                    <ReceptionRuleUsesTab
                        selected={dataUsesCfdi ? dataUsesCfdi : []}
                        update={updateUseCfdiAction} />
                );
        }
    };

    const onCloseRetentionMenu = () => {
        setAnchorEl(null);
        setOpenOptions(false);
        setOpenCreateRetention(false);
    }

    if (status === "loading") {
        return (
            <Progress />
        );
    }

    if (status !== "loaded") {
        return (
            <Error text={status} />
        )
    }

    return (
        <Surface title={translate("reception_rule.voucher_type." + ruleType!.sat_cfdi_type) as string} subtitle={translate("reception_rule.title_edition") as string}
            icon={<PlaylistAddCheckIcon />} className="PaperPagination PaperTabContainer" backButton={true} backTo={history.goBack.name}>
            <Grid container justify="flex-end">
                <FormControlLabel
                    control={
                        <Switch
                            checked={state}
                            onChange={handleChangeSwitch}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={translate(`reception_rule.status.active`)}
                />
            </Grid>
            <AppBar position="static">
                <Tabs style={{ "backgroundColor": "white" }} textColor="primary" indicatorColor="primary" value={value} onChange={handleChange} aria-label="Tabs">
                    {tabs.map((tabName, index) => getTab(tabName, index))}
                </Tabs>
            </AppBar>
            {tabs.map((tabName, index) => (
                <TabPanel key={tabName} value={value} index={index}>
                    {getTabPanel(tabName, index)}
                </TabPanel>
            ))}

            {context.isGrantedAny(["ReceptionRuleUpdate", "ReceptionRuleCreate"]) && (
                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={3} mr={3}>
                            <Grid container justify="flex-end" spacing={2}>
                                <Grid item xs="auto">
                                    <Button variant="text" color="primary" size="large" onClick={history.goBack}>
                                        {translate("buttons.cancel")}
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button size="medium" variant="contained" color="primary" onClick={updateTypesAction}>
                                        {translate("reception_rule.add_button")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {openCreateRetention && anchorEl &&
                <CreateRetentionMenu onOpenDefault={openSelectDefaultRetention} onOpenNew={onOpenNewRetention} anchor={anchorEl} onClose={onCloseRetentionMenu}/>
            }
            {openOptions && retentionSelected && anchorEl &&
                <RetentionMenu onEdit={onOpenEditRetention} onUpdateStatus={onOpenUpdateStatusRetention} onDelete={onOpenDeleteRetention} onClose={onCloseRetentionMenu} active={retentionSelected.active} anchor={anchorEl}/>
            }
            {openDefaultRetentions && retentionsDefault &&
                <SelectDefaultRetentionPopUp retentions={retentionsDefault} taxRegimens={taxRegimens} serviceProducts={allServiceProducts} taxRegimenDuplicate={taxRegimenDuplicate} onCompleted={onAddRetention} onClose={closeRetentions} />
            }
            {openNewRetention && allServiceProducts && taxRegimens &&
                <AddNewRetentionPopUp retentions={retentionsDefault} taxRegimens={taxRegimens} serviceProducts={allServiceProducts} isEdit={false} taxRegimenDuplicate={taxRegimenDuplicate} getMessageValidateProducServices={getMessageValidateProductServices} onCompleted={onAddRetention} onUpdateProducts={onUpdateServiceProductsForRetentions} onClose={closeRetentions} />
            }
            {openEditRetention && allServiceProducts && taxRegimens && retentionSelected &&
                <AddNewRetentionPopUp retention={retentionSelected} taxRegimens={taxRegimens} retentions={retentionsDefault} serviceProducts={allServiceProducts} isEdit={true} taxRegimenDuplicate={taxRegimenDuplicate} getMessageValidateProducServices={getMessageValidateProductServices} onCompleted={onAddRetention} onUpdateProducts={onUpdateServiceProductsForRetentions} onClose={closeRetentions} />
            }
            {openUpdateStatusRetention && retentionSelected &&
                <ConfirmationPopup
                    title={translate("reception_rule.retention_validation." + (retentionSelected.active === true ? "inactivate_title" : "activate_title")) as string}
                    message={translate("reception_rule.retention_validation." + (retentionSelected.active === true ? "inactivate_message" : "activate_message"), {tax_regimen: retentionSelected.tax_regimen_id}) as string}
                    secondary={translate("reception_rule.retention_validation." + (retentionSelected.active === true ? "inactivate_confirm_message" : "activate_confirm_message"), { tax_regimen: retentionSelected.tax_regimen_id }) as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelUpdateStatus}
                    doAction={onUpdateStatusRetention}
                />
            }
            {openDeleteRetention && retentionSelected &&
                <ConfirmationPopup
                    title={translate("reception_rule.retention_validation.delete_retention") as string}
                    message={translate("reception_rule.retention_validation.delete_message", { tax_regimen: retentionSelected.tax_regimen_id }) as string}
                    secondary={translate("reception_rule.retention_validation.delete_confirm_message") as string}
                    button={translate("buttons.accept") as string}
                    onClose={onCancelDelete}
                    doAction={onDeleteRetention}
                />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </Surface>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}