import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { BankAccount } from "../model/BankAccount";
import { TenantSegment } from "../model/TenantFieldCatalog";
import translate from "../i18n/Translator";
import { Button, Grid, IconButton } from "@material-ui/core";
import { getBankAccountCoverUrl } from "../api/ProviderExpedientAPI";
import EditIcon from "@material-ui/icons/Edit";

interface ProviderBankAccountCardConf {
    tenantId: string;
    providerId: string;
    bank_account?: BankAccount;
    configuration: TenantSegment;
    edit?: boolean;
    onEdit?(id: string): any;
}

export default function ProviderBankAccountCard(props: ProviderBankAccountCardConf) {
    const onAdd = () => {
        if (props.onEdit) {
            props.onEdit(props.configuration.id);
        }
    };
    return (
        <Card className="ProviderMetadataSegmentCard">
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs="auto">
                    <Typography color="primary" variant="body1">
                        {props.configuration.name}
                    </Typography>
                </Grid>
                {props.edit ?
                    <Grid item xs={1}>
                        <IconButton aria-label="options" color="default" size="small" onClick={onAdd} >
                            <EditIcon />
                        </IconButton>
                    </Grid> : undefined}
            </Grid>
            {props.configuration.description && (
                <Typography color="textSecondary" variant="caption" component="h6">
                    {props.configuration.description}
                </Typography>
            )}
            <Typography color="textSecondary" variant="caption" component="h6">
                {translate(props.configuration.required ? "expedients.required" : "expedients.optional")}
            </Typography>
            <Box py={1}>
                <Divider />
            </Box>
            <ProviderBankAccount {...props} />
        </Card>
    );
}

export function ProviderBankAccount(props: ProviderBankAccountCardConf) {
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (!props.bank_account) {
            return;
        }

        let current = props.bank_account.cover || "";
        if (current.startsWith("http")) {
            setUrl(current);
        } else {
            getBankAccountCoverUrl(props.tenantId, props.providerId, props.configuration.id).then((url) => {
                setUrl(url);
            }).catch((error) => {
                setUrl(current);
            });
        }
    }, [props.bank_account, props.configuration.id, props.providerId, props.tenantId])

    if (!props.bank_account) {
        return (
            <Box>
                <Typography color="textSecondary" variant="caption" component="h6">
                    {translate("expedients.not_provided") as string}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Typography color="textSecondary" variant="body1">
                {props.bank_account.owner}
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("bank_account.bank") as string}: <strong>{props.bank_account.bank_name}</strong>
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("bank_account.subsidiary") as string}: <strong>{props.bank_account.subsidiary}</strong>
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("bank_account.subsidiary_number") as string}: <strong>{props.bank_account.subsidiary_number}</strong>
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("bank_account.account") as string}: <strong>{props.bank_account.account}</strong>
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("bank_account.clabe") as string}: <strong>{props.bank_account.clabe}</strong>
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("bank_account.currency") as string}: <strong>{translate(`currency.${props.bank_account.currency}`)}</strong>
            </Typography>
            <Typography color="textSecondary" variant="body2" paragraph={!!url}>
                {translate("bank_account.country") as string}: <strong>{translate(`countries.${props.bank_account.country}`)}</strong>
            </Typography>
            {url && (
                <Grid container justify="flex-end">
                    <Grid item xs="auto">
                        <Button size="small" variant="text" color="primary" component="a" href={url} target="_blank" rel="noopener noreferrer">
                            {translate("bank_account.cover")}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}