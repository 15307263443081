import React, { useEffect, useState} from "react";
import { Button, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import DatePicker from "../components/DatePicker";
import moment from "moment";

interface PostponePaymentPopupProps {
    id: string;
    onConfirm(datePostpone? : Date): any;
    onCancelled(): any;
}

export default function PostponePaymentPopup(props: PostponePaymentPopupProps) {
    const [datePostpone, setDatePostpone] = useState<Date>();
    const [disabled, setDisabled] = useState<boolean>(true);    

    const onChangedDate = (name: string, raw?: string, date?: Date) =>{
        if(!date) return;
        setDatePostpone(date);
        setDisabled(false);
    }

    const onPostpone = () =>{
        props.onConfirm(datePostpone);
    }
    useEffect(() => {
        var result = new Date();
        result.setDate(result.getDate() + 1);
        setDatePostpone(result);
    }, [props]);


    return (
        <DialogPopup open
            title={translate("cfdis.popup.postpone_payment.title") as string}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancelled}
            closeColor="default"
            button={
                <Button onClick={onPostpone} variant="outlined" color="primary" disabled={disabled} >
                    {translate("buttons.postpone")}
                </Button>
            }
        >
            <Typography variant="body2">
            {translate("cfdis.popup.postpone_payment.description")}
            </Typography>
            <br />
            <DatePicker
                        label={translate("cfdis.popup.postpone_payment.label") as string}
                        name={props.id}
                        initial={moment(datePostpone).format("DD/MM/YYYY")}
                        onChange={onChangedDate}
                        defaultIfMissing={false}
                        clearable={false} autoOK
                        disablePast={true}
                        disableFuture={false}
                        minDate={datePostpone} />
        </DialogPopup>
    );

}