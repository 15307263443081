import React, { useContext, useState, useEffect } from "react";
import translate from "../i18n/Translator";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import { TableSelection } from '@devexpress/dx-react-grid';
import { FormControl, Select, Box, Typography } from "@material-ui/core";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import Arrow from '@material-ui/icons/Forward';

import { Statuses, Project, ProjectTypes, StatusBuget } from "../model/Project";
import ListProjects from "./ListProjects";
import { isRoleOrParent } from "../model/Role";
import { NoFilterEditor } from "../components/GridDx";

export default function ProjectsToStartView({ match }: RouterParams) {
    const context = useContext(AppContext);
    const isOwner = isRoleOrParent(context.session!.role, "owner") || isRoleOrParent(context.session!.role, "project_manager");
    const userId = isOwner ? "" : context.session?.user.id;
    const [view, setView] = useState<string>("");

    const load = () => {
        let href = window.location.pathname;

        if (href.includes('start')) {
            setView('to-start');
        } else if (href.includes('execution')) {
            setView('in_execution');
        } else if (href.includes('archive')) {
            setView('archive');
        }
    }

    useEffect(load, [context.session]);

    const getStatus = (project: Project) => {
        return translate(`requisitions.projects.status.${project.status}`) as string;
    };

    const [tableColumnExtensionsP] = useState([
        { columnName: 'key', width: 120, wordWrapEnabled: true },
        { columnName: 'created_at', align: 'center', width: 200 },
        { columnName: 'name', wordWrapEnabled: true, width: 200 },
        { columnName: 'type', width: 120, wordWrapEnabled: true },
        { columnName: 'customer', width: 250, wordWrapEnabled: true },
        { columnName: 'vs_presup', width: 120, wordWrapEnabled: true },
        { columnName: 'current_budget', align: 'right', width: 150 },
    ]) as any;

    const currencyColumns = ['current_budget'];

    const clickRowColumns = ['key'];

    const defaultExpandedGroups = Statuses;

    const textColumns = ['name', 'type', 'key', 'customer'];

    const dateColumns = ['created_at'];

    const grouping = [{ columnName: 'status' }];

    const [leftColumns] = useState([TableSelection.COLUMN_TYPE, 'key']) as any;

    const IconVsBudget = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(item: any) => {
            let value = item.row;
            let equal = value.assigned_budget && value.current_budget === value.initial_budget && value.assigned_budget === value.initial_budget;
            let down = value.current_budget === value.initial_budget && (!value.assigned_budget || value.assigned_budget < value.current_budget);
            return equal ? <Arrow style={{ color: "blue", "width": 20, "height": 20 }} /> :
                down ? <Arrow style={{ color: "green", "width": 20, "height": 20, "transform": "rotate(90deg)" }} /> :
                    <Arrow style={{ color: "red", "width": 20, "height": 20, "transform": "rotate(-90deg)" }} />;
        }} {...props} />
    );

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const BudgetVsFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="type"
                        id="type"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {StatusBuget.map((value) => {
                            return <option key={value} value={value}>{translate(`requisitions.projects.status_budget.${value}`)}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const TypeFilterEditor = (params: DataTypeProvider.ValueEditorProps) => {
        const onChange = (event: any) => {
            const { value: targetValue } = event.target;
            if (targetValue.trim() === '') {
                params.onValueChange("");
                return;
            }
            params.onValueChange(targetValue);
        };

        return (
            <Box px={1}>
                <FormControl >
                    <Select native style={{
                        fontSize: "10px"
                    }}
                        labelId="type"
                        id="type"
                        value={params.value ? params.value : ''}
                        onChange={onChange} >
                        <option key={"ALL"} value={""}>{""}</option>
                        {ProjectTypes.map((value) => {
                            return <option key={value} value={value}>{translate(`requisitions.projects.types.${value}`)}</option>
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const customPlugins = [
        <IconVsBudget for={['vs_presup']} editorComponent={BudgetVsFilterEditor} />,
        <NormalTypeProvider for={['type']} editorComponent={TypeFilterEditor} />,
        <NormalTypeProvider for={['status']} editorComponent={NoFilterEditor} />,

    ];

    const getProjectType = (item: Project) => {
        if (item.type) {
            return (
                <Typography variant="inherit">
                    {translate("requisitions.projects.types." + item.type) as string}
                </Typography>
            );
        }
        return (<></>);
    };

    const getColumns = () => {
        if (view === "archive") {
            let statusColum =
                {
                    name: 'status',
                    title: translate("requisitions.projects.columns.status") as string,
                    getCellValue: (row: any) => getStatus(row)
                } as any;
            let columnsWithStatus = columns.concat(statusColum);
            return columnsWithStatus;
        }
        return columns;
    }

    let columns = [
        {
            name: 'key',
            title: translate("requisitions.projects.columns.key") as string
        },
        {
            name: 'created_at',
            title: translate("requisitions.projects.columns.created_at") as string
        },
        {
            name: 'name',
            title: translate('requisitions.projects.columns.project_name') as string
        },
        {
            name: 'type',
            title: translate('requisitions.projects.columns.type') as string,
            getCellValue: (row: any) => getProjectType(row)
        },
        {
            name: 'customer',
            title: translate("requisitions.projects.columns.customer") as string
        },
        {
            name: 'vs_presup',
            title: translate("requisitions.projects.columns.vs_presup") as string
        },
        {
            name: 'current_budget',
            title: translate("requisitions.projects.columns.current_budget") as string
        },
        {
            name: 'status',
            title: translate("requisitions.projects.columns.status")  as string,
            getCellValue: (row: any) => translate(`requisitions.projects.status.${row.status}`) as string,

        }
    ]

    switch (view) {
        case "to-start":
            return (
                <ListProjects
                    columns={getColumns()}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    clickRowColumns={clickRowColumns}
                    columnsFormat={tableColumnExtensionsP}
                    leftColumns={leftColumns}
                    textColumns={textColumns}
                    statusPlugins={customPlugins}
                    title={translate("requisitions.projects.views.to_start") as string}
                    success={match.params.success}
                    show_create={true}
                    statuses={[Statuses[0], Statuses[1]]}
                />
            );
        case "in_execution":
            return (
                <ListProjects
                    columns={getColumns()}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    clickRowColumns={clickRowColumns}
                    columnsFormat={tableColumnExtensionsP}
                    leftColumns={leftColumns}
                    textColumns={textColumns}
                    statusPlugins={customPlugins}
                    title={translate("requisitions.projects.views.execution") as string}
                    success={match.params.success}
                    userId={userId}
                    show_create={false}
                    statuses={[Statuses[2]]}
                />
            );
        case "archive":
            return (
                <ListProjects
                    columns={getColumns()}
                    defaultExpandedGroups={defaultExpandedGroups}
                    currencyColumns={currencyColumns}
                    dateColumns={dateColumns}
                    clickRowColumns={clickRowColumns}
                    columnsFormat={tableColumnExtensionsP}
                    leftColumns={leftColumns}
                    textColumns={textColumns}
                    grouping={grouping}
                    statusPlugins={customPlugins}
                    title={translate("requisitions.projects.views.archive") as string}
                    success={match.params.success}
                    userId={userId}
                    show_create={false}
                    statuses={Statuses}
                />
            );
        default:
            return (<></>);
    }
}