import React, { useContext, useState, useEffect } from "react";
import { Button, Grid, Box } from '@material-ui/core';
import moment from "moment";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DatePicker from "../components/DatePicker";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { create, update } from "../api/RefundPayableDocumentApi";
import { AppContext } from "../context/AppContext";
import { getProviders } from "../api/ProviderAPI";
import { AutocompleteProvider, Providers, ProvidersQueryParams } from "../model/Provider";
import { NumberValidator, GTZeroValidator } from "../components/Validators";
import { Refund, RefundRequest } from "../model/Refund";
import TenantCurrencyAutocomplete from "../currencies/TenantCurrencyAutocomplete";
import { getTenantCurrencies } from "../api/TenantConfigurationApi";
import Progress from "../components/Progress";

interface RefundFormPopupProps {
    refund?: Refund;
    onSuccess(refund: Refund): any;
    onCancel(): any;
}

export default function RefundFormPopup(props: RefundFormPopupProps) {
    const context = useContext(AppContext);
    const margin = "dense";
    const isEdit = !!props.refund;
    const [working, setWorking] = useState(false);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [vendorLabels, setProviderLabels] = useState<string[]>([]);
    const [vendorIds, setProviderIds] = useState<string[]>([]);
    const [providers, setProviders] = useState<Providers>();
    const [companyLabels, setCompanyLabels] = useState<string[]>();
    const [companyIds, setCompanyIds] = useState<string[]>();
    const numberValidator = new NumberValidator(true);
    const gtZeroValidator = new GTZeroValidator();
    const [currenciesAutocomplete, setCurrenciesAutocomplete] = useState<AutocompleteProvider[]>([]);

    const providerQueryParams = {
        "search": "",
        "not_located_status": "",
        "efos_status": "",
        "status": "ENABLED",
        "classification": "",
        "validator_user_id": "",
        "receptor": "",
        "specific_rule": "",
        "workflow_id": "",
        "type": "VENDOR",
    } as ProvidersQueryParams;

    const [request, setRequest] = useState<RefundRequest>({
        vendor_id: "",
        description: "",
        date: new Date(),
        currency: "",
        amount: 0.00
    } as RefundRequest);
    const [validations, setValidations] = useState({} as any);

    const load = () => {
        setWorking(true);
        if (props.refund) {
            setRequest({
                vendor_id: props.refund.metadata.provider_id,
                description: props.refund.description,
                date: props.refund.date,
                currency: props.refund.currency,
                amount: props.refund.total,
                company_id: props.refund.metadata.company_id
            } as RefundRequest);
        }

        Promise.all([
            getProviders(context.session!.tenant!.id, 0, 0, providerQueryParams),
            getTenantCurrencies(context.session!.tenant!.id)
        ]).then(responses => {
            const providerResp = responses[0];
            setProviders(providerResp);
            fillProvider(providerResp);

            const currenciesResp = responses[1];
            if (currenciesResp.tenant_currency_ids) {
                let listTemp = [] as AutocompleteProvider[];
                if (currenciesResp.tenant_currency_ids) {
                    currenciesResp.tenant_currency_ids.forEach((item) => {
                        let temp = { title: (item + " - " + (translate("currency." + item) as string)), value: item } as AutocompleteProvider;
                        listTemp.push(temp);
                    });
                }
                setCurrenciesAutocomplete(listTemp);
            }

        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setWorking(false);
        });

    };

    useEffect(load, []);

    const fillProvider = (providers: Providers) => {
        let labels = [] as string[];
        let ids = [] as string[];

        providers.items.forEach(p => {
            labels.push(p.name);
            ids.push(p.id);
        });

        setProviderLabels(labels);
        setProviderIds(ids);
    }

    const onChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedProvider = (name: string, value: string, inputRef: InputRef) => {
        let newRequest = request;
        newRequest.vendor_id = value;
        newRequest.company_id = undefined;
        setRequest(newRequest);
        validations[name] = inputRef;
        setValidations(validations);

        let provider = providers?.items.find((p) => p.id === value);
        if (provider && provider.company_objects && provider.company_objects.length > 1) {
            let labels = [] as string[];
            let ids = [] as string[];

            provider.company_objects.forEach(c => {
                labels.push(c.name);
                ids.push(c.id);
            });
            setCompanyIds(ids);
            setCompanyLabels(labels);
        } else {
            setCompanyIds(undefined);
        }
    };

    const onChangedCompany = (name: string, value: string, inputRef: InputRef) => {
        let newRequest = request;
        newRequest.company_id = value;
        setRequest(newRequest);
        validations[name] = inputRef;
        setValidations(validations);
    }

    const onChangedDate = (name: string, raw?: string, date?: Date) => {
        setRequest({ ...request, date: (date ? date : new Date()) });
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }


    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        setRequest({ ...request, currency: value });
        if (inputRef) {
            validations["currency"] = inputRef;
            setValidations(validations);
        }
    };

    const onSubmit = () => {
        if (!isValid()) return;

        if (!request?.currency) {
            setWarning(translate("validations.currency_required") as string);
            return;
        }

        setWorking(true);
        if (isEdit) {
            update(context.session!.tenant!.id, props.refund!.id, request).then((refund: Refund) => {
                props.onSuccess(refund);
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setWorking(false);
            });
        } else {
            create(context.session!.tenant!.id, request).then((refund: Refund) => {
                props.onSuccess(refund);
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setWorking(false);
            });
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        <DialogPopup open title={props.refund ? translate("refunds.form.edit_title") : translate("refunds.form.new_title")}
            disable={working}
            disableBackdropClick={working}
            disableEscapeKeyDown={working}
            maxWidth="sm"
            closeText={translate("buttons.cancel") as string}
            onClose={props.onCancel}
            closeColor="default"
            button={
                <Button onClick={onSubmit} variant="outlined" color="primary" disabled={working}>
                    {translate("buttons.save")}
                </Button>
            }>
            {working && (
                <Progress />
            )}
            {!working && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="vendor_id" name="vendor_id"
                            label={translate("refunds.form.vendor") as string}
                            value={request.vendor_id}
                            required
                            margin={"dense"}
                            optionLabels={vendorLabels}
                            options={vendorIds}
                            disabled={working}
                            onValueChanged={onChangedProvider} />
                    </Grid>
                    {companyIds && (
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="text"
                                id="company_id" name="company_id"
                                label={translate("refunds.form.company") as string}
                                value={request.company_id}
                                required
                                margin={"dense"}
                                optionLabels={companyLabels}
                                options={companyIds}
                                disabled={working}
                                onValueChanged={onChangedCompany} />
                        </Grid>)}
                    <Grid item xs={12}>
                        <ValidatedInput id="description" name="description" type="text"
                            label={translate("refunds.form.description") as string}
                            value={request.description} required disabled={working}
                            margin={margin}
                            onValueChanged={onChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker label={translate("refunds.form.date") as string} name="date"
                            onChange={onChangedDate} clearable={false} required disabled={working}
                            format="DD/MM/YYYY" initial={request.date ? moment(request.date).format("DD/MM/YYYY") : undefined} />
                    </Grid>
                    <Grid item xs={6} alignContent="center" justify="center" >
                        <Box pt={1}>
                            <TenantCurrencyAutocomplete
                                value={currenciesAutocomplete?.find(v => v.value === request?.currency)}
                                currencies={currenciesAutocomplete}
                                onChange={onFilterChanged}
                                disabled={false}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={6}>
                        <ValidatedInput id="amount" name="amount" type="number"
                            label={translate("refunds.form.amount") as string}
                            value={request.amount + ""} required disabled={working}
                            margin={margin}
                            validators={[numberValidator, gtZeroValidator]}
                            onValueChanged={onChanged} />
                    </Grid>
                </Grid>
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
        </DialogPopup>
    );
}