import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleTwoTone';

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface TenantConfigurationsMenuProps {
    anchor: HTMLElement | null;
    required: boolean;
    active: boolean;
    onDisable(): any;
    onUpdate(): any;
    onClose(): any;
}

export default function TenantMenu(props: TenantConfigurationsMenuProps) {
    const context = useContext(AppContext);
    
    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("TenantsUpdate") &&
                <MenuItem button onClick={props.onDisable} disabled={props.required || !props.active} dense>
                    <ListItemIcon>
                        <RemoveCircleIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.desactivate")}</Typography>
                </MenuItem>
            }
            {context.isGranted("TenantsUpdate") &&
                <MenuItem button onClick={props.onUpdate} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}