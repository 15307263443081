import React, { useEffect, useState } from "react";
import { CardMedia, Grid, GridSize, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import WidgetsIcon from "@material-ui/icons/ImportContacts";
import GetAppIcon from "@material-ui/icons/GetApp";
import LinkIcon from "@material-ui/icons/Link";
import { Widget } from "../model/Widget";
import Surface from "../components/Surface";
import translate from "../i18n/Translator";
import { Tenant } from "../model/Tenant";

interface CustomWidgetsProps {
    tenant: Tenant;
    widgets: Widget[];
}

export default function CustomWidgets(props: CustomWidgetsProps) {
    const [links, setLinks] = useState<Widget[]>([]);
    const [media, setMedia] = useState<Widget[]>([]);
    const [html, setHtml] = useState<Widget[]>([]);
    const [cols, setCols] = useState<GridSize>(4);

    useEffect(() => {
        const links = props.widgets.filter(widget => widget.type === "link" || widget.type === "file");
        const media = props.widgets.filter(widget => widget.type === "video" || widget.type === "youtube" || widget.type === "image");
        const html = props.widgets.filter(widget => widget.type === "html");

        setLinks(links);
        setMedia(media);
        setHtml(html);

        const hasContent = [links.length > 0, media.length > 0, html.length > 0].filter(v => v).length;
        setCols((hasContent ? (12 / hasContent) : 12) as GridSize)
    }, [props.widgets]);


    const linkList = () => (
        <List dense subheader={(
            <ListSubheader disableSticky>
                {translate("widgets.links")}
            </ListSubheader>
        )}>
            {links.map((widget) => (
                <ListItem key={widget.id}>
                    <ListItemText primary={widget.name} secondary={widget.description} />
                    <ListItemSecondaryAction>
                        <IconButton color="secondary" component="a" href={widget.value} target="_blank" rel="noopener noreferrer">
                            {widget.type === "link" ? (<LinkIcon />) : (<GetAppIcon />)}
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );

    const mediaGrid = () => (
        <Grid container>
            <Grid item xs={12}>
                <ListSubheader disableSticky disableGutters>
                    {translate("widgets.media")}
                </ListSubheader>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {media.map((widget) => (
                        <Grid key={widget.id} item xs={12}>
                            <Typography variant="body2" component="h6" paragraph={!widget.description}>
                                {widget.name}
                            </Typography>
                            {widget.description && (
                                <Typography variant="caption" component="h6" color="textSecondary" paragraph>
                                    {widget.description}
                                </Typography>
                            )}
                            {widget.type === "video" && (
                                <CardMedia
                                    component="video"
                                    title={widget.name}
                                    src={widget.value}
                                    autoPlay={false}
                                    controls
                                    muted
                                />
                            )}
                            {widget.type === "youtube" && (
                                <CardMedia
                                    component="iframe"
                                    title={widget.name}
                                    src={widget.value}
                                />
                            )}
                            {widget.type === "image" && (
                                <CardMedia
                                    component="img"
                                    title={widget.name}
                                    src={widget.value}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );

    const htmlGrid = () => (
        <Grid container>
            <Grid item xs={12}>
                <ListSubheader disableSticky disableGutters>
                    {translate("widgets.html")}
                </ListSubheader>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {html.map((widget) => (
                        <Grid key={widget.id} item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body2" component="h6" paragraph={!widget.description}>
                                        {widget.name}
                                    </Typography>
                                    {widget.description && (
                                        <Typography variant="caption" component="h6" color="textSecondary" paragraph>
                                            {widget.description}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} dangerouslySetInnerHTML={{ __html: widget.value }}></Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Surface title={translate("widgets.surface", { "tenant": props.tenant.name })} icon={<WidgetsIcon />}>
            <Grid container spacing={4}>
                {links.length > 0 && (
                    <Grid item xs={12} sm={6} lg={cols}>
                        {linkList()}
                    </Grid>
                )}
                {media.length > 0 && (
                    <Grid item xs={12} sm={6} lg={cols}>
                        {mediaGrid()}
                    </Grid>
                )}
                {html.length > 0 && (
                    <Grid item xs={12} sm={6} lg={cols}>
                        {htmlGrid()}
                    </Grid>
                )}
            </Grid>
        </Surface>
    );

}
