
export interface Cff69ProviderResult {
    is_clean: boolean;
    status: string;
    published: Date;
}

export interface Cff69Validation {
    clean: number;
    in_list: number;
    queued: boolean;
}

export interface Cff69ValidationAll {
    clean: number;
    validations: Map<Cff69ValidationType, Cff69Validation>;
}

export interface BlackListValidations {
    canceled_result: Cff69ProviderResult;
    enforceable_result: Cff69ProviderResult;
    firm_result: Cff69ProviderResult;
    sentences_result: Cff69ProviderResult;
    not_located: Cff69ProviderResult;
}

export enum Cff69ValidationType { NO_LOCALIZADOS, CANCELADOS, EXIGIBLES, FIRMES, SENTENCIAS }

export const BlackListValues = ["IN_ORDER", "NO_LOCALIZADO", "CANCELADO", "EXIGIBLE", "SENTENCIADO", "FIRME"];