import React from "react";
import ValidIcon from "@material-ui/icons/CheckCircleTwoTone";
import InvalidIcon from "@material-ui/icons/CancelTwoTone";
import Gridable from "../components/Gridable"
import DateFormat from "../components/DateFormat"
import translate from "../i18n/Translator";
import { CfdiBeforeSend } from "../model/Cfdi";
import { Typography } from "@material-ui/core";

interface PayableDocumentBeforeSendTabProps {
    items: CfdiBeforeSend[];
}

export default function PayableDocumentBeforeSendTab(props: PayableDocumentBeforeSendTabProps) {
    return (
        <Gridable items={props.items} loading={false}
            columns={[
                {
                    title: (
                        <ValidIcon style={{ visibility: "hidden" }} />
                    ),
                    converter: (item) => item.failed ? <InvalidIcon style={{ "color": "#C33149" }} /> : <ValidIcon style={{ "color": "#82CA9D" }} />,
                    fullWidth: true,
                    xs: "auto"
                },
                {
                    title: translate("cfdis.before_send.operation"),
                    converter: (item) => (
                        <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                            {item.operation}
                        </Typography>
                    ),
                    fullWidth: true,
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                },
                {
                    title: translate("cfdis.before_send.message"),
                    converter: (item) => item.message,
                    fullWidth: true,
                    xs: true,
                },
                {
                    title: translate("cfdis.before_send.created_at"),
                    converter: (item) => <DateFormat date={item.created_at} format="DD-MM-YYYY h:mm a" zone={true} />,
                    fullWidth: true,
                    xs: false,
                    sm: false,
                    md: false,
                    lg: 2,
                    xl: 2
                }
            ]} />
    );
}