import React, { useContext, useEffect, useState } from "react";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import { Redirect, useHistory } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import Surface from "../components/Surface";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import SimpleSwitch from "../components/SimpleSwitch";
import CodeInput from "../components/CodeInput";
import { CustomReport, CustomReportColumn, CustomReportRequest, FormatsCustomColumn } from "../model/CustomReport";
import { createCustomReport, exportCustomReport, getCustomReport, importCustomReport, updateCustomReport, validateCustomReportQueries } from "../api/CustomReportAPI";
import Progress from "../components/Progress";
import { javascript } from "@codemirror/lang-javascript";
import translator from "../i18n/Translator";
import { formatDateTimeToString } from "../components/DateFormat";
import { getTenant } from "../api/TenantAPI";
import { Tenant } from "../model/Tenant";
import { RegexValidator } from "../components/Validators";
import Gridable from "../components/Gridable";
import { EditIcon } from "../components/Icons";
import Dropzone from "react-dropzone";


export default function CustomReportForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const history = useHistory();
    const isEdit = !!match.params.customReportId;
    const patternValidator = new RegexValidator(new RegExp(["##tenant_id##", "##start_date##", "##end_date##"].join('|'), 'g'), "errors.codes.9103");

    const [redirect, setRedirect] = useState<string | undefined>();
    const [validationsColumn, setValidationsColumn] = useState({} as any);
    const [fileImported, setfileImported] = useState<File|undefined>(undefined);
    const [customReportImported, setCustomReportImported] = useState<CustomReport>();
    const [columnSelected, setColumnSelected] = useState<{
        index: number,
        column: CustomReportColumn
    }|undefined>(undefined)
    const [status, setStatus] = useState<string>("loading");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isCustomReportValid, setIsCustomReportValid] = useState(false);
    const [isImportingReport, setIsImportingReport] = useState(false);
    const [success, setSuccess] = useState<string>()
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [tenant, setTenant] = useState({} as Tenant);
    const [request, setRequest] = useState<CustomReportRequest>({
        export_available: false,
    } as CustomReportRequest);

    useEffect(() => {
        getTenant(tenantId).then((tenant) => {
            setTenant(tenant);

            if (isEdit) {
                Promise.all([
                    getCustomReport(tenantId, match.params.customReportId)
                ]).then(responses => {
                    const customReport = responses[0];
                    setRequest({
                        name: customReport.name,
                        data_query: customReport.query,
                        count_query: customReport.query_total,
                        export_available: customReport.exportable,
                        validated_at: customReport.validated_at,
                        columns: customReport.columns
                    });

                    setIsCustomReportValid(true);
                })
            }

        }).catch((error) => {
            setError(error);
        }).finally(() => {
            setStatus("loaded");
        });

    }, [tenantId, isEdit, match.params.customReportId])


    const onChangeName = (name: string, value: string, inputRef: InputRef) => {
        setRequest({
            ...request,
            [name]: value
        })
    }

    const onChangeQuery = (query: string, input: string) => {
        if (input === "QUERY_TOTAL") {
            setRequest({
                ...request,
                count_query: query
            });

            return;
        }

        setRequest({
            ...request,
            data_query: query
        });
    }

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
        setWarning(undefined);
    }

    const onSwitchDefault = () => {
        setRequest({
            ...request,
            export_available: !request.export_available
        });
    }

    const onCancel = () => {
        history.goBack();
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(validateRequest()) {
            if(isEdit){
                updateCustomReport(tenantId, match.params.customReportId, request)
                .then((response) => {
                    setRedirect(`/tenants/${tenantId}/customreports/${translator("custom_reports.texts.report_created_successfully")}`);
                }).catch((error) => {
                    setError(error.message)
                });
            } else {
                createCustomReport(tenantId, request)
                .then((response) => {
                    setRedirect(`/tenants/${tenantId}/customreports/${translator("custom_reports.texts.report_updated_successfully")}`);
                }).catch((error) => {
                    setError(error.message)
                });
            }
        }
    }

    const validateRequest = () => {
        if(request.name === "" || request.name === undefined){
            return false;
        }

        if((request.data_query === "" || request.data_query === undefined) && 
            (request.count_query === "" || request.count_query === undefined)) {
            return false;
        }

        if(request.columns === undefined || request.columns.length === 0){
            return false;
        }

        return true;
    }

    const onClickGenerateReset = () => {
        if (!isCustomReportValid) {
            onCheckReport();
            return;
        }

        setIsCustomReportValid(!isCustomReportValid);
        setRequest({
            ...request,
            columns: [],
        })
    }

    const onCheckReport = () => {
        if (!checkPatternQueries(request)) {
            alert(patternValidator.getMessage());
            return;
        }

        validateCustomReportQueries(tenantId, request)
        .then((response) => {
            setRequest({
                ...request,
                validated_at: new Date(),
                columns: response.columns
            })
            setIsCustomReportValid(!isCustomReportValid);
        }).catch((error) => {
            console.log(error);
            alert(error.message);
        })
    }

    const checkPatternQueries = (request: CustomReportRequest) => {
        return (request.data_query !== "" && patternValidator.isValid(request.data_query)
            && request.count_query !== "" && patternValidator.isValid(request.count_query))
    }

    const onClickImportConfiguration = () => {
       setIsImportingReport(!isImportingReport);
    }

    const onClickExportConfiguration = () => {
        if(isCustomReportValid){
            exportCustomReport(tenantId, request)
            .then((response) => {
                window.open(response.url, "_blank");
            }).catch((error) => {
                setWarning(error.message);
            });
        }
    }

    const isValidToGenerateMetadata = () => {
        return (
            request.name === undefined ||
            request.name === "" ||
            request.data_query === undefined ||
            request.data_query === "" ||
            request.count_query === undefined ||
            request.count_query === ""
        );
    }

    const onEditAttribute = (index: number, value?: CustomReportColumn) => {
        if(value){
            setColumnSelected({index, column: value});
        }
    }

    const onCloseImport = () => {
        setIsImportingReport(!isImportingReport);
        setCustomReportImported(undefined)
        setfileImported(undefined)
    }

    const onSubmitImportReport = () => {
        setRequest({
            ...request,
            name : customReportImported!.name,
            export_available : customReportImported!.exportable,
            data_query : customReportImported!.query,
            count_query: customReportImported!.query_total,
            columns: customReportImported!.columns,
            validated_at: new Date()
        });
        setIsCustomReportValid(true);
        setIsImportingReport(false);
        setCustomReportImported(undefined);
        setfileImported(undefined)
        setSuccess(translator("custom_reports.texts.import_success") as string);
    }

    const onDropJsonFile = (acceptedFiles: File[]) => {
        const jsonImported = acceptedFiles[0];
        importCustomReport(tenantId, jsonImported)
        .then((response) => {
            setCustomReportImported(response);
            setSuccess(translator("custom_reports.texts.validate_success") as string);
        }).catch((error) => {
            setWarning(error.message);
        })

        setfileImported(jsonImported)
    }

    const onChangeEditAtributte = (name: string, value: string, inputRef: InputRef) => {
        if(columnSelected && columnSelected.column[name as keyof typeof columnSelected.column] !== value) {
            setColumnSelected({
                ...columnSelected,
               column : {
                ...columnSelected.column,
                [name]:value
               }   
            });
            validationsColumn[name] = inputRef;
            setValidationsColumn(validationsColumn);
        }
    }

    const onSubmitColumnSelected = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!isValid(validationsColumn)){
            return;
        }

        if(columnSelected && columnSelected.index >= 0){
            setRequest({
                ...request,
                columns : request.columns.map((column, index) => {
                    if(index === columnSelected.index){
                        return {
                            ...columnSelected.column
                        }
                    }
                    return column
                })
            })

            setColumnSelected(undefined)
        }
    }

    const onCloseEditColumnSelected = () => {
        setColumnSelected(undefined);
    }
 
    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Surface
            title={isEdit ? translate("custom_reports.titles.edit") as string : translate("custom_reports.titles.new") as string}
            subtitle={tenant.rfc}
            icon={<PlaylistAddIcon />}
            backButton
            onAction={history.goBack}
            className="PaperPagination" >
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid>
                    <Box px={1} pt={2}>
                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Grid container alignItems="center" spacing={2} >
                                    <Grid item xs={6}>
                                        <ValidatedInput margin="dense" type="text" id="name" name="name"
                                            value={request.name}
                                            label={translate("custom_reports.inputs.name_report") as string}
                                            required={true}
                                            disabled={false}
                                            onValueChanged={onChangeName} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SimpleSwitch
                                            value="is_default"
                                            label={translate("custom_reports.inputs.is_exportable") as string}
                                            checked={request.export_available}
                                            onChanged={onSwitchDefault}
                                            placement="end"
                                            disabled={false} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box px={1} pb={1}>
                                <Grid container  alignItems="center" spacing={4}>
                                    <Grid item xs={6}>
                                        <CodeInput
                                            value={request.data_query}
                                            textLabel={translate("custom_reports.inputs.query") as string}
                                            maxHeight="200px"
                                            height="150px"
                                            onChange={(value) => {
                                                onChangeQuery(value, "QUERY")
                                            }}
                                            extensions={[javascript({ jsx: false })]}
                                            isEditable={(!isCustomReportValid)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CodeInput
                                            value={request.count_query}
                                            textLabel={translate("custom_reports.inputs.query_total") as string}
                                            maxHeight="200px"
                                            height="150px"
                                            onChange={(value) => {
                                                onChangeQuery(value, "QUERY_TOTAL")
                                            }}
                                            extensions={[javascript({ jsx: false })]}
                                            isEditable={(!isCustomReportValid)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box p={1}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={3}>
                                        <Button
                                            onClick={onClickGenerateReset}
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            fullWidth
                                            disabled={isValidToGenerateMetadata()}>
                                            {isCustomReportValid ? translate("custom_reports.buttons.change_queries") as string :
                                                translate("custom_reports.buttons.generate_metadata") as string}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            isCustomReportValid && request.validated_at !== undefined && (
                                                <ListItem >
                                                    <ListItemIcon>
                                                        <ValidIcon style={{ "color": "#82CA9D" }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={(
                                                            <Typography style={{ fontSize: 10 }} variant="body2" align="right">
                                                                {`${translator("custom_reports.texts.query_success") as string}${formatDateTimeToString(request.validated_at)}`}
                                                            </Typography>
                                                        )}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            onClick={onClickImportConfiguration}
                                            variant="text"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            startIcon={<PublishIcon />}>
                                            {translate("custom_reports.buttons.import_configuration") as string}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            onClick={onClickExportConfiguration}
                                            variant="text"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            startIcon={<GetAppIcon />}
                                            disabled={(!isCustomReportValid)}
                                        >
                                            {translate("custom_reports.buttons.export_configuration") as string}

                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box p={1}>
                                {
                                    isCustomReportValid && request.columns !== undefined && (
                                        <Gridable
                                            items={request?.columns ?? []}
                                            loading={false}
                                            empty={translate("custom_reports.tables.attributes.empty_columns") as string}
                                            columns={[
                                                {
                                                    title: translate("custom_reports.tables.attributes.columns.attribute") as string,
                                                    converter: (value) => value.attribute || "---",
                                                    fullWidth: true,
                                                    xs: 3,
                                                    sm: 3,
                                                    md: 3,
                                                    lg: 3,
                                                    xl: 3,
                                                },
                                                {
                                                    title: translate("custom_reports.tables.attributes.columns.tag_in_report") as string,
                                                    converter: (value) => value.label || "---",
                                                    fullWidth: true,
                                                    xs: 3,
                                                    sm: 3,
                                                    md: 3,
                                                    lg: 3,
                                                    xl: 3,
                                                },
                                                {
                                                    title: translate("custom_reports.tables.attributes.columns.format") as string,
                                                    converter: (value, index) => FormatsCustomColumn[value.format as keyof typeof FormatsCustomColumn] || "-",
                                                    fullWidth: true,
                                                    xs: 3,
                                                    sm: 3,
                                                    md: 3,
                                                    lg: 3,
                                                    xl: 3,
                                                },
                                                {
                                                    title: translate("custom_reports.tables.attributes.columns.visibility") as string,
                                                    converter: (value, index) => value.visible ? "Activa" : "Inactiva",
                                                    fullWidth: true,
                                                    xs: 2,
                                                    sm: 2,
                                                    md: 2,
                                                    lg: 2,
                                                    xl: 2,
                                                },
                                                {
                                                    title: "",
                                                    converter: (value, index) => (
                                                        <IconButton
                                                            aria-label="edit"
                                                            color="primary"
                                                            size="small" onClick={() => onEditAttribute(index, value)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    ),
                                                    xs: "auto"
                                                }
                                            ]} />
                                    )
                                }

                            </Box>
                            <Box px={1} p={1}>
                                <Grid container justify="flex-end" spacing={1} >
                                    <Grid item xs="auto" md="auto">
                                        <Button onClick={onCancel} variant="outlined" color="primary" size="large" disabled={isSubmitting}>
                                            {translate("buttons.cancel")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs="auto" md="auto">
                                        <Button type="submit" variant="contained" color="primary" size="large" disabled={isSubmitting}>
                                            {translate(isEdit ? "buttons.update" : "buttons.add")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </form>
            {
                isImportingReport &&
                <Dialog open fullWidth={true}
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    onClose={onCloseImport}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="DialogForm">
                    <form autoComplete="off" noValidate onSubmit={onSubmitImportReport}>
                        <DialogTitle id="alert-dialog-title" disableTypography>
                            <Typography variant="subtitle1">
                                {translator("custom_reports.texts.upload_file") as string}
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                    <Dropzone onDrop={onDropJsonFile} accept={["application/json", ".json"]} multiple={false} disabled={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} style={{ height: 50, borderWidth: 1, borderColor: "lightgray", borderStyle: "dashed", paddingLeft: 10 }}>
                                                <input {...getInputProps()} />
                                                <p>{fileImported ? fileImported.name : translator('importable.file') as string}</p>
                                            </div>
                                        )}
                                    </Dropzone>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onCloseImport} variant="text" color="primary" disabled={false}>
                                {translate("buttons.cancel")}
                            </Button>
                            <Button type="submit" variant="contained" color="primary" size="medium" disabled={customReportImported === undefined}>
                                {translate("buttons.accept")}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            }

            {
                columnSelected &&
                <Dialog open fullWidth={true}
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    onClose={(() => {})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="DialogForm">
                    <form autoComplete="off" noValidate onSubmit={onSubmitColumnSelected}>
                        <DialogTitle id="alert-dialog-title" disableTypography>
                            <Typography variant="subtitle1">
                                {translator("custom_reports.texts.edit_attribute", {0 : columnSelected.column.attribute}) as string}
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid  container direction="row" className="TableFilter"  alignItems="center" justify="space-between" >
                                <Grid item xs={12}>
                                    <ValidatedInput 
                                    margin="dense" type="text" id="column_label" name="label"
                                    value={columnSelected.column.label === '' ? columnSelected.column.attribute : columnSelected.column.label}
                                    label={translator("custom_reports.inputs.label_in_report") as string}
                                    required={true}
                                    disabled={false}
                                    onValueChanged={onChangeEditAtributte} />
                                 
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text" id="column_format" name="format" label={translator("custom_reports.inputs.format_in_report") as string}
                                        value={columnSelected.column.format}
                                        disabled={false}
                                        required
                                        margin={"dense"}
                                        onValueChanged={onChangeEditAtributte}
                                        options={Object.keys(FormatsCustomColumn)} 
                                        optionLabels={Object.values(FormatsCustomColumn)}
                                        omitEmpty={true}
                                        />
                                </Grid>

                                <Grid container item justify="flex-end" xs={12}>
                                    <SimpleSwitch
                                        value="is_default"
                                        label={translator("custom_reports.inputs.visible_in_report") as string}
                                        checked={columnSelected.column.visible}
                                        onChanged={(name, checked) => {setColumnSelected({
                                            ...columnSelected,
                                            column : {
                                                ...columnSelected.column,
                                                visible: checked
                                            }
                                        })}}
                                        placement="end"
                                        disabled={false} />
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onCloseEditColumnSelected} variant="text" color="primary" disabled={false}>
                                {translate("buttons.cancel")}
                            </Button>
                            <Button type="submit" variant="contained" color="primary" size="medium" disabled={false}>
                                {translate("buttons.accept")}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            }

            <SuccessSnackbar message={success} onClose={onCloseSnackbars}/>
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />

        </Surface>
    );
}