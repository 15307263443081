import React, { useState, useEffect } from "react";
import DialogPopup from "../components/DialogPopup";
import { TermCondition as TermConditionRequest } from "../model/TermsConditions";
import { Configuration } from "../model/TenantConfiguration";
import translate from "../i18n/Translator";
import { Button, Grid } from "@material-ui/core";
import Editor from '../components/Editor';
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import CustomBackdrop from "../components/CustomBackdrop";
import { RawDraftContentState } from 'draft-js';

interface TermsConditionsCreateModalProps {
    configuration: Configuration;
    onSave(request: TermConditionRequest): any;
    onClose(): any;
}

export default function TermConditionCreateModal(props: TermsConditionsCreateModalProps) {
    const [status, setStatus] = useState<string>("loading");
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<TermConditionRequest>();

    const load = () => {
        setStatus("loading");
        const termnCondition = JSON.parse(props.configuration.value) as TermConditionRequest;
        setRequest({
            title: termnCondition.title || "",
            message: termnCondition.message || "",
            accepted_message: termnCondition.accepted_message || "",
            active: termnCondition.active || false,
        } as TermConditionRequest);
        setStatus("loaded");
    };

    useEffect(load, [props]);

    const hasChanged = (name: string, value: string | number, inputRef: InputRef) => {
        if (!request) return;
        setRequest({ ...request, [name]: value });
    };

    const onChangeEditor = (contentState: RawDraftContentState) => {
        if (!request) return;
        setRequest({ ...request, message: JSON.stringify(contentState, null, 4) });
    };

    const onSave = () => {
        if (!request) return;
        if(validations(request)){
            props.onSave(request);
        }
    };

    const validations = (request: TermConditionRequest) => {
        if (request.title === "") {
            setWarning(translate("tenant_configurations.terms_conditions.empty_title") as string);
            return false;
        }
        if (request.message === "") {
            setWarning(translate("tenant_configurations.terms_conditions.empty_message") as string);
            return false;
        }
        if (request.accepted_message === "") {
            setWarning(translate("tenant_configurations.terms_conditions.empty_message_accepted") as string);
            return false;
        }
        return true;
    };

    if (status === "loading") {
        return <></>;
    }

    return (
        <DialogPopup title={translate("tenant_configurations.terms_conditions.edit_modal")} maxWidth="lg" open
            onClose={props.onClose} closeText={translate("tenant_configurations.terms_conditions.close_without_save") as string}
            button={
                <Grid container spacing={1}>
                    <Grid item >
                        <Button onClick={onSave} variant="contained" color="primary" size="medium" disabled={false}>
                            {translate("buttons.save")}
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {request ?
                <TermnConditionForm onChange={hasChanged} 
                    onChangeEditor={onChangeEditor}
                    request={request}
                />
                : undefined}
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={false} message={translate("cfdis.processing") as string} />
        </DialogPopup>);
}

interface TermnConditionFormProps {
    request: TermConditionRequest;
    onChange(name: string, value: string | number, inputRef: InputRef): any;
    onChangeEditor(contentState: RawDraftContentState): any;
}

function TermnConditionForm(props: TermnConditionFormProps) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ValidatedInput type="text" id="title" name="title"
                    value={props.request.title} label={translate("tenant_configurations.terms_conditions.title") as string}
                    required={true} disabled={false}
                    margin="dense"
                    onValueChanged={props.onChange} />
            </Grid>
            <Grid item xs={12}>
                <Editor value={props.request.message} onChange={props.onChangeEditor} />
            </Grid>
            <Grid item xs={12}>
                <ValidatedInput type="text" id="accepted_message" name="accepted_message"
                    value={props.request.accepted_message} label={translate("tenant_configurations.terms_conditions.accepted_message") as string}
                    required={true} disabled={false}
                    margin="dense"
                    onValueChanged={props.onChange} />
            </Grid>
        </Grid>
    );
}
