import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Direction, Family, Connector, Format } from '../model/Connector';
import { downloadImportTemplate as providerTemplate } from "../api/ProviderAPI";
import { downloadImportTemplate as paymentTemplate, importPayments } from "../api/PaymentApi";
import { downloadImportTemplate as purchaseOrderTemplate } from "../api/PurchaseOrderAPI";
import { downloadImportTemplate as warehouseDeliveryTemplate } from "../api/WarehouseDeliveryAPI";
import { downloadImportTemplate as customerDeliveryTemplate } from "../api/CustomerAPI";
import { downloadImportTemplate as catalogTemplate } from "../api/CatalogAPI";
import ConnectorSelector from "../connectors/ConnectorSelector";
import { getUUID, upload } from "../api/S3UploaderApi";
import CustomBackdrop from "../components/CustomBackdrop";
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from '../components/Snackbars';
import { ImportPaymentSummary } from '../model/Payment';

interface ConnectorTemplateImportProps {
    tenantId: string;
    userId: string;
    family: Family;
    title?: string;
    showOnImportPayment: boolean;
    onShowOnImportPayment(showOnImportPayment: boolean): void;
    onImportedPaymentSummary(importResult: ImportPaymentSummary): void;
}

export default function ConnectorTemplateImportFile(props: ConnectorTemplateImportProps) {
    const [connector, setConnector] = useState<Connector>();
    const [defaultExtensions, setDefaultExtensions] = useState<string>(".csv, .xlsx, .xls, .xml, .json, .txt, .tsv");
    const fileInput = useRef<HTMLInputElement>(null);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();

    useEffect(() => {
        if (!connector) return;

        let promise: Promise<string>;
        switch (props.family) {
            case Family.PROVIDERS:
                promise = providerTemplate(props.tenantId, connector.id);
                break;
            case Family.PAYMENTS:
                promise = paymentTemplate(props.tenantId, connector.id);
                break;
            case Family.PURCHASE_ORDERS:
                promise = purchaseOrderTemplate(props.tenantId, connector.id);
                break;
            case Family.WAREHOUSE_DELIVERIES:
                promise = warehouseDeliveryTemplate(props.tenantId, connector.id);
                break;
            case Family.CUSTOMERS:
                promise = customerDeliveryTemplate(props.tenantId, connector.id);
                break;
            case Family.CATALOGS:
                promise = catalogTemplate(props.tenantId, connector.id);
                break;
            default:
                return;
        }

    }, [props.tenantId, props.family, connector]);

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            setConnector(undefined);
            return;
        }
        setOpenBackdrop(true);

        let file = event.target.files[0];
        if (connector && Format.AWS_S3 === connector.format) {
            upload(`temp/${process.env.REACT_APP_AWS_S3_ENV}/${props.tenantId}/${connector.id}/${connector.remote_directory}/${props.userId}/${getUUID()}/${file.name}`,
                file, {
                onError: (error) => setError(translate("payment.import.import_s3_failed", { "error": error ?? "error" }) as string),
                onSuccessUpload: () => setSuccess(translate("payment.import.import_s3_success") as string),
                onFinally: () => {
                    setOpenBackdrop(false);
                    fileInput.current!.value = "";
                }
            });
            return;
        }

        importPayments(props.tenantId, file).then((response) => {
            props.onImportedPaymentSummary(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            fileInput.current!.value = "";
        });
    }

    const onSelectImpport = () => {
        fileInput.current!.click();
        props.onShowOnImportPayment(false);
    }
    const getExtensionAllwed = (connector: Connector) => {
        if (!connector?.format) {
            return defaultExtensions;
        }
        switch (connector?.format) {
            case Format.CSV:
                return ".csv, .tsv";
            case Format.XLSX:
                return ".xlsx";
            case Format.XLS:
                return ".xls";
            case Format.XML:
                return ".xml";
            case Format.JSON:
                return ".json";
            case Format.TXT:
                return ".txt, .tsv";
            case Format.PLUGIN:
            case Format.LAMBDA:
            case Format.AWS_S3:
                return connector?.extensions?.join(", ") ?? defaultExtensions;
            default:
                return defaultExtensions;
        }
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    return (
        <>
            {connector &&
                (<input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept={defaultExtensions} />)}

            {props.showOnImportPayment && (<Dialog open maxWidth="sm"
                disableBackdropClick
                onClose={() => {
                    props.onShowOnImportPayment(false);
                    setConnector(undefined);
                }}
                aria-labelledby="templ-downloader-title"
                aria-describedby="templ-downloader-description">
                <DialogTitle id="templ-downloader-title" disableTypography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{props.title ?? translate("connectors.template_downloader.title")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">{translate(`connectors.family.${props.family}`)}</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="DialogPopup" dividers>
                    <ConnectorSelector tenantId={props.tenantId}
                        family={props.family}
                        direction={Direction.INPUT}
                        onChangedConnector={(connectorSelected) => {
                            setDefaultExtensions(getExtensionAllwed(connectorSelected));
                            setConnector(connectorSelected);
                        }}
                        onlySelectable={true}
                        disableCustom />
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12}>
                        <Box px={1}>
                            <Grid container justify="flex-end" spacing={1}>
                                <Grid item xs="auto">
                                    <Button onClick={() => {
                                        props.onShowOnImportPayment(false);
                                        setConnector(undefined);
                                    }} variant="outlined" color="primary">
                                        {translate("buttons.close")}
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button variant="contained" color="primary" disabled={!connector} onClick={() => onSelectImpport()} >
                                        {translate("buttons.import")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </DialogActions >
            </Dialog >)}
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
        </>
    );
}