import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Typography, Tabs, Tab, Grid, Fab } from "@material-ui/core";
import CustomersIcon from "@material-ui/icons/Apartment";
import AddContractIcon from "@material-ui/icons/PostAdd";
import translate from "../i18n/Translator";
import { getCustomer } from "../api/CustomerAPI";
import { Customer } from "../model/Customer";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import CustomerCard from "./CustomerCard";
import CustomerContracts from "./CustomerContracts";
import CustomerDocs from "./CustomerDocs";

type CustomeTabName = "details" | "contracts" | "docs";

interface CustomeTab {
    name: CustomeTabName;
    title: string;
    actions?: string[];
}

export default function CustomerOverview({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const qs = queryString.parse(window.location.search);

    const allTabs = [
        {
            name: "details",
            title: translate("customers.details" as string)
        },
        {
            name: "contracts",
            title: translate("customers.contracts" as string)
        },
        {
            name: "docs",
            title: translate("customers.docs" as string)
        }
    ] as CustomeTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [status, setStatus] = useState<string>("loading");
    const [customer, setCustomer] = useState<Customer>();
    const [tabs, setTabs] = useState<CustomeTab[]>([]);

    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [value, setValue] = useState<number>(tabQs);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        // let url = window.location.pathname + "?" + queryString.stringify(qs);
        // history.push(url);

        setValue(newValue);
    };

    const load = () => {
        setStatus("loading");
        getCustomer(tenantId, match.params.customerId).then((customer) => {
            setCustomer(customer);
            setTabs(allTabs.filter((tab) => !tab.actions || context.isGrantedAny(tab.actions)));
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, match.params.customerId]);

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line 
    }, [qs.tab, tabs]);

    const tabContent = (index: number) => {
        if (!customer) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return <CustomerCard customer={customer} />;
            case "contracts":
                return <CustomerContracts tenantId={tenantId} customer={customer} />;
            case "docs":
                return <CustomerDocs tenantId={tenantId} customerId={customer.id} shippingRequirements={customer.shipping_requirements} />;
            default:
                return <div></div>;
        }
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !customer) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Surface title={customer.name}
            subtitle={customer.rfc}
            backButton
            icon={(
                <CustomersIcon />
            )}
            titleActions={tabs[value].name === "contracts" ? (
                <Fab color="primary" size="small" title={translate("buttons.validate") as string} component={Link} to={`/customers/${customer.id}/contracts/new`} >
                    <AddContractIcon />
                </Fab>
            ) : undefined}
            className="PaperPagination PaperTabContainer">
            <Tabs value={value} onChange={handleChange} aria-label={translate("customers.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: CustomeTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: CustomeTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            </Grid>
        </Surface>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`customers-tabpanel-${index}`}
            aria-labelledby={`customers-tab-${index}`}
            {...other}
        >
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        component: "a",
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
        },
        id: `employees-tab-${index}`,
        'aria-controls': `customers-tabpanel-${index}`,
    };
}