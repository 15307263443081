import { callAPI, HTTPMethod } from "./API";
import { Contributions, ContributionsQueryParams, ContributionsToValidate } from "../model/Contribution";

const basePath = (tenantId: string, providerId: string) => `/tenants/${tenantId}/providers/${providerId}/contributions`;

export async function listContributions(tenantId: string, providerId: string, page: number, offset: number, params: ContributionsQueryParams): Promise<Contributions> {
    return await callAPI(`${basePath(tenantId, providerId)}`, {
        method: HTTPMethod.GET,
        query: {
            month: params.month?.toString() || "",
            year: params.year?.toString() || "",
            status: params.status || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function listContributionsToValidate(tenantId: string, providerId: string, page: number, offset: number): Promise<ContributionsToValidate> {
    return await callAPI(`${basePath(tenantId, providerId)}/to-validate`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}