import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, ListItem, ListItemText, Collapse, List } from "@material-ui/core";
import GroupAddIcon from '@material-ui/icons/GroupAddTwoTone';
import PostAddIcon from '@material-ui/icons/PostAddTwoTone';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import FormatLineSpacingTwoToneIcon from '@material-ui/icons/FormatLineSpacingTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

interface ProvidersReceptionMenuProps {
    anchor: HTMLElement | null;
    totalItems?: number;
    onClose(): any;
    onSelectReceptor(): any;
    onSecondAuthorizator(): any;
    onSelectSpecificRule(): any;
    onRemoveSpecificRule(): any;
    onManageWorkflows(): any;
    onExportWithParams(): any;
    onExportSelected(): any;
    isRestrictAssignReceptors: boolean;
}

export default function ProvidersReceptionMenu(props: ProvidersReceptionMenuProps) {
    const context = useContext(AppContext);
    const [openExport, setOpenExport] = useState<boolean>(false);

    const handleClickExport = () => {
        setOpenExport(!openExport);
    };

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
            autoFocus={false}
            variant="selectedMenu"
        >
            {context.isGranted("AdminCFDIsUpdate") && !props.isRestrictAssignReceptors &&
                <MenuItem button onClick={props.onSelectReceptor} dense>
                    <ListItemIcon>
                        <GroupAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_receptor")}</Typography>
                </MenuItem>
            }
            {context.isGranted("CfdisSecondAuthorizationUpdate") &&
                <MenuItem button onClick={props.onSecondAuthorizator} dense>
                    <ListItemIcon>
                        <GroupAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_second_authorizator")}</Typography>
                </MenuItem>
            }
            {context.isGranted("WorkflowsUpdate") &&
                <MenuItem button onClick={props.onManageWorkflows} dense>
                    <ListItemIcon>
                        <FormatLineSpacingTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.manage_workflows")}</Typography>
                </MenuItem>
            }
            {context.isGranted("SpecificReceptionRuleUpdate") &&
                <MenuItem button onClick={props.onSelectSpecificRule} dense>
                    <ListItemIcon>
                        <PostAddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.select_specific_rule")}</Typography>
                </MenuItem>
            }
            {context.isGranted("SpecificReceptionRuleUpdate") &&
                <MenuItem button onClick={props.onRemoveSpecificRule} dense>
                    <ListItemIcon>
                        <ClearTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.remove_specific_rule")}</Typography>
                </MenuItem>
            }
            {context.isGranted("ProvidersRead") && (
                <ListItem button onClick={handleClickExport} dense>
                    <ListItemIcon>
                        <ImportExportIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("providers.export")} />
                    {openExport ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
            {context.isGranted("ProvidersRead") && (
                <Collapse in={openExport} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem button onClick={props.onExportSelected} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <Selected fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.selected")} />
                        </ListItem>
                        <ListItem button onClick={props.onExportWithParams} dense >
                            <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                <DownloadList fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.all_in_view")} />
                        </ListItem>
                    </List>
                </Collapse>
            )}
        </Menu>
    );

} 