import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { clearElementAddress, updateAddresses } from "../api/ProviderExpedientAPI";
import { ProviderExpedientAddress, ProviderExpedient, ProviderExpedientAddressRequest } from "../model/ProviderExpedient";
import ExpedientProviderAddressForm from "./ExpedientProviderAddressForm";
import { Address } from "../model/Address";
import { Entity } from "../model/Provider";
import ExpedientProviderSegments from "./ExpedientProviderSegments";
import { ProviderAddress } from "../providers/ProviderAddressCard";
import DialogPopup from "../components/DialogPopup";

interface ExpedientProviderAddressesProps {
    metadata: ProviderExpedient;
    onChange(metadata: ProviderExpedient): any;
    disabled: boolean;
    provider?: Entity
}

export default function ExpedientProviderAddresses(props: ExpedientProviderAddressesProps) {
    const addresses = "";
    return (
        <ExpedientProviderSegments
            disabledEdition={props.disabled}
            metadata={props.metadata}
            name="addresses"
            provider={props.provider}
            supplier={(metadata) => Object.values(metadata.addresses) as ProviderExpedientAddress[]}
            onUpdated={(tenantId: string, providerId: string, current: ProviderExpedientAddress, value: Address) => {
                let request = {
                    id: current.configuration.id,
                    value: value,
                } as ProviderExpedientAddressRequest;
                return updateAddresses(tenantId, providerId, request);
            }}
            clear={(tenantId: string, providerId: string, addressId: string)=>{
                return clearElementAddress(tenantId, providerId, addressId);
            }}
            onChange={props.onChange}
            buildForm={(current: ProviderExpedientAddress, onClose: () => any, onSubmitted: (value: Address) => any) => (
                <ExpedientProviderAddressForm
                    title={current.configuration.name}
                    subtitle={current.configuration.description}
                    address={current.value}
                    comments={current.comments}
                    onClose={onClose}
                    onCompleted={onSubmitted}
                    disabled={false}
                />
            )}
            buildPopup={(current: ProviderExpedientAddress, onClose: () => any) => (
                <DialogPopup title={current.configuration.name} open onClose={onClose}>
                    <ProviderAddress address={current.value} />
                </DialogPopup>
            )}
            buildData={(current: ProviderExpedientAddress) => (
                current.value ?
                    <Grid>
                        <Typography variant="body2">
                            {addresses.concat(
                                current.value.street ? current.value.street : "", " ",
                                current.value.exterior_number ? current.value.exterior_number : "", " ",
                                current.value.interior_number ? current.value.interior_number : "", " ",
                                current.value.suburb ? current.value.suburb : "", " ",
                                current.value.city ? current.value.city : "", " ",
                                current.value.state ? current.value.state : "", " ",
                                current.value.zip_code ? current.value.zip_code : ""
                            )}</Typography>
                    </Grid>
                    : undefined
            )}
        />
    );
}