import React, { useState, useEffect, useContext, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton, Tooltip } from "@material-ui/core";

import translate from "../i18n/Translator";
import { createEmployeeVisit, getEmployeeVisit, updateEmployeeVisit  } from "../api/EmployeeVisitAPI";
import { Employee, EmployeesQueryParams } from "../model/Employee";

import Progress from "../components/Progress";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import DatePicker from "../components/DatePicker";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import { RouterParams } from "../router/RouterParams";
import Gridable from "../components/Gridable";
import { PrepareCfdiEmployeeRequest } from "../model/CfdiSe";
import { listEmployees as listProviderEmployees } from "../api/ProviderEmployeeAPI";
import { EmployeeVisitRequest, EmployeeVisit } from "../model/EmployeeVisit";
import { EmailValidator } from "../components/Validators";
import { importGroupEmployee as importGroupEmployeeProvider, createEmployeeGroup } from "../api/ProviderEmployeeGroupAPI";
import { EmployeeGroupResultImport, EmployeeGroupRequest } from "../model/EmployeeGroup";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import EmployeeGroupResultsImport from "../employeegroups/EmployeeGroupResultsImport";
import { HelpIcon, DeleteIcon, EmployeeIcon } from "../components/Icons";
import ReactHtmlParser from "html-react-parser";

export default function EmployeeVisitForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session!.provider!.id;

    const history = useHistory();
    const isEdit = !!match.params.employeeVisitId;
    const margin = "dense";
    const format = "DD/MM/YYYY";
    const [inExecutionStatus, setInExecutionStatus] = useState<boolean>(true);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<EmployeeVisitRequest>({} as EmployeeVisitRequest);
    const [validations, setValidations] = useState({} as any);
    const [employeeIds, setEmployeeIds] = useState<string[]>([]);
    const [employeeLabels, setEmployeeLabels] = useState<string[]>([]);
    const [employeeId, setEmployeeId] = useState<string>("");
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();
    const emailValidator = new EmailValidator();
    const fileInput = useRef<HTMLInputElement>(null);
    const [employeesNotRegistered, setEmployeesNotRegistered] = useState<Employee[]>([]);
    const [urlImportResults, setUrlImportResults] = useState<string>("");
    const [employeeGroup, setEmployeeGroup] = useState<EmployeeGroupResultImport>();
    const [success, setSuccess] = useState<string>();
    const [endDateString, setEndDateString] = useState<string>("");
    const [startDateString, setStartDateString] = useState<string>("");

    const [openConfirmDeleteEmployee, setOpenConfirmDeleteEmployee] = useState<boolean>(false);
    const [employeeRequest, setEmployeeRequest] = useState<PrepareCfdiEmployeeRequest>();
    const [indexEmployee, setIndexEmployee] = useState<number>();
    const [openConfirmSubmit, setOpenConfirmSubmit] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>();

    const submitPromise = (request: EmployeeVisitRequest): Promise<EmployeeVisit> => {
        if (isEdit) {
                return updateEmployeeVisit(tenantId, providerId, match.params.employeeVisitId, request);
        }
        return createEmployeeVisit(tenantId, providerId , request);
    };

    const getEmployeeVisitPromise = (): Promise<EmployeeVisit> => {
        if (isEdit) {
                return getEmployeeVisit(tenantId, providerId , match.params.employeeVisitId);
        }
        return {} as Promise<EmployeeVisit>;
    };

    useEffect(() => {
        setStatus("loading");
        const params = { search: "", only_verified_and_new_employees: true } as EmployeesQueryParams;
        Promise.all([
            getEmployeeVisitPromise(),
            listProviderEmployees(tenantId, providerId, 0, 0, params),
        ]).then( responses => {
            const employees = responses[1].items;
            const employeeVisit = responses[0];
            setEmployees(employees);

            const usedEmployees = (employeeVisit.employees_response || []).map(emp => emp.employee?.id).filter(el => !!el);
            const seEmployees = (employeeVisit.employees_response || []).map(emp => {
                return {
                    employee_id: emp.employee?.id || "",
                    name: emp.employee?.name || "",
                    rfc: emp.employee?.rfc || "",
                    from: emp.from ? moment(emp.from).format(format) : "",
                    to: emp.to ? moment(emp.to).format(format) : "",
                } as PrepareCfdiEmployeeRequest;
            });
            const filteredEmployees = employees.filter(el => usedEmployees.indexOf(el.id) === -1);
            setEmployeeIds(filteredEmployees.map(el => el.id));
            setEmployeeLabels(filteredEmployees.map(el => el.name));
        
            setRequest({
                description: employeeVisit.description,
                start_date: new Date(),
                end_date: new Date(),
                supervisor_name: employeeVisit.supervisor_name,
                supervisor_email: employeeVisit.supervisor_email,
                employees: seEmployees,
            });
            setStartDateString(moment(employeeVisit.start_date).format(format));
            setEndDateString(moment(employeeVisit.end_date).format(format));
            setInExecutionStatus(employeeVisit.status === 'IN_EXECUTION');
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, [tenantId, providerId, isEdit, match.params.employeeVisitId, format]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: !value || value === "---" ? undefined : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedDate = (name: string, raw?: string, date?: Date, inputRef?: InputRef) => {
        if (raw) {
            if (name === "start_date") {
                setStartDateString(raw);
                setMinDate(date);
            } else {
                setEndDateString(raw);
                setMaxDate(date);
            }
        } else {
            setRequest(request);
            if (name === "start_date") {
                setStartDateString("");
                setMinDate(undefined);
            } else {
                setEndDateString("");
                setMaxDate(undefined);
            }
        }

        if (inputRef) {
            validations[name] = inputRef;
            setValidations(validations);
        }
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }
        if(request.employees.length == 0){
            setWarning(translate("employee_visits.form.warning_empty_employees") as string);
            return;
        }

        let endDateSplitted = endDateString.split("/");
        let end_date = new Date(Number(endDateSplitted[2]), Number(endDateSplitted[1]) - 1, Number(endDateSplitted[0]), 0, 0, 0, 0);
        if(inExecutionStatus && end_date <= new Date()){
            setOpenConfirmSubmit(true);
        } else {
            onConfirmSubmit();
        }
        
    };

    const onCancelSubmit = () => {
        setOpenConfirmSubmit(false);
    }
    const onConfirmSubmit = () => {
        setOpenConfirmSubmit(false);
        setSubmitting(true);
        let startDateSplitted = startDateString.split("/");
        let endDateSplitted = endDateString.split("/");
        let requestToSend = request;
        if(endDateSplitted.length === 3 && startDateSplitted.length === 3){
            requestToSend.start_date = new Date(Number(startDateSplitted[2]), Number(startDateSplitted[1]) - 1, Number(startDateSplitted[0]), 0, 0, 0, 0);
            requestToSend.end_date = new Date(Number(endDateSplitted[2]), Number(endDateSplitted[1]) - 1, Number(endDateSplitted[0]), 23, 59, 59, 999);
        }
        submitPromise(requestToSend).then((employee) => {
            setRedirect(true);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    }

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
        setSuccess(undefined);
    };

    const hasChangedEmployee = (name: string, value: string, inputRef: InputRef) => {
        setEmployeeId(!value || value === "---" ? "" : value);
    };

    const onDeleteEmployee = (employeeRequest: PrepareCfdiEmployeeRequest, index: number) => () => {
        if(isEdit && inExecutionStatus){
            setOpenConfirmDeleteEmployee(true);
            setEmployeeRequest(employeeRequest);
            setIndexEmployee(index);
        } else {
            deleteEmployee(employeeRequest, index);
        }
    };

    const cancelDeleteEmployee = () => {
        setOpenConfirmDeleteEmployee(false);
        setEmployeeRequest(undefined);
        setIndexEmployee(undefined);

    };
    const confirmDeleteEmployee = () => {
        setOpenConfirmDeleteEmployee(false);
        if(!employeeRequest) return;
        if(indexEmployee === undefined) return;
        deleteEmployee(employeeRequest, indexEmployee);
    };

    const deleteEmployee = (employeeRequest: PrepareCfdiEmployeeRequest, index: number) => {
        const employee = employees.find(el => el.id === employeeRequest.employee_id);
        if (!employee) return;

        const currentEmployeeIds = request.employees.map(el => el.employee_id);
        const filteredEmployees = employees.filter(el => el.id === employee.id || currentEmployeeIds.indexOf(el.id) === -1);

        setEmployeeIds(filteredEmployees.map(el => el.id));
        setEmployeeLabels(filteredEmployees.map(el => el.name));
        setRequest({ ...request, employees: request.employees.filter((el, i) => i !== index) });

        delete validations[`from_${employeeRequest.employee_id}`];
        delete validations[`to_${employeeRequest.employee_id}`];
        setValidations(validations);
    };

    const addEmployee = () => {
        if (!employeeId) return;

        const index = employeeIds.findIndex(el => el === employeeId);
        if (index === -1) return;

        const employee = employees.find(el => el.id === employeeId);
        if (!employee) return;

        const requestEmployees = request.employees.map(el => el);
        requestEmployees.push({
            employee_id: employeeId,
            rfc: employee.rfc,
            name: employee.name,
            from: startDateString,
            to: endDateString
        });

        setRequest({ ...request, employees: requestEmployees });
        setEmployeeIds(employeeIds.filter((el, i) => i !== index));
        setEmployeeLabels(employeeLabels.filter((el, i) => i !== index));
        setEmployeeId("");
    };

    const addAllEmployees = () => {
        const requestEmployees = employees.map(el => {
            return {
                employee_id: el.id,
                rfc: el.rfc,
                name: el.name,
                from: startDateString,
                to: endDateString,
            } as PrepareCfdiEmployeeRequest
        })
        setRequest({ ...request, employees: requestEmployees });
        setEmployeeIds([]);
        setEmployeeLabels([]);
        setEmployeeId("");
    };

    const addFileEmployees = () => {
        fileInput.current!.click();
    };

    const onChangedEmployeeDate = (employeeRequest: PrepareCfdiEmployeeRequest) => (name: string, raw?: string, date?: Date, inputRef?: InputRef) => {
        employeeRequest = { ...employeeRequest, [name]: raw };
        setRequest({
            ...request, employees: request.employees.map(el => {
                if (el.employee_id === employeeRequest.employee_id) {
                    return employeeRequest;
                }
                return el;
            })
        });

        if (inputRef) {
            validations[`${name}_${employeeRequest.employee_id}`] = inputRef;
            setValidations(validations);
        }
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        setSubmitting(true);

        importGroupEmployeeProvider(context.session!.tenant!.id, providerId, event.target.files[0]).then((response) => {
            if(response.employees === undefined || response.employees.length === 0){
                setError(translate("employeegroups.empty_import") as string);
                return;
            }
            if(response.employees_not_registered.length > 0){
                setEmployeesNotRegistered(response.employees_not_registered);
                setUrlImportResults(response.url_export_results);
                return;
            }
            setEmployeeGroup(response);
            const requestEmployees = response.employees.filter(er => request.employees.filter(emp => er.id === emp.employee_id).length === 0).map(emp => {
                return {
                    employee_id: emp.id,
                    rfc: emp.rfc,
                    name: emp.name,
                    from: startDateString,
                    to: endDateString,
                } as PrepareCfdiEmployeeRequest;
            });
            let tempRequest = request;
            requestEmployees.forEach(reqEmp => tempRequest.employees.push(reqEmp));
            setRequest(tempRequest);
            let tempEmployeees = employees.filter((e) =>  tempRequest.employees.filter(er => er.employee_id === e.id).length === 0);
            setEmployeeIds(tempEmployeees.map(e => e.id));
            setEmployeeLabels(tempEmployeees.map(e => e.name));
            setSuccess(translate("employeegroups.success") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            fileInput.current!.value = "";
        });
    }

    const onAddGroup = (name: string) => {
        setOpenAddGroup(false);
        saveEmployeeGroup(name);
    };

    const saveEmployeeGroup = (name: string) => {
        setSubmitting(true);

        if(!employeeGroup) return;
        let employeeIds = employeeGroup.employees.map(emp => emp.id);
        createEmployeeGroup(context.session!.tenant!.id, providerId, { name: name, employee_ids: employeeIds } as EmployeeGroupRequest).then((response) => {
            setSuccess(translate("employeegroups.created_success") as string);
            
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            fileInput.current!.value = "";
        });
    };

    const onCloseAddGroup = () => {
        setOpenAddGroup(false);
    };

    if (redirect) {
        return <Redirect to="/employee-visits" />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Surface title={translate(isEdit ? "employee_visits.edit" : "employee_visits.new")} icon={<EmployeeIcon />} className="FormSurface" backButton>
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <Grid container justify="flex-end" spacing={1}>
                                        <Grid item xs={12}>
                                            <ValidatedInput type="text" id="description" name="description"
                                                value={request.description}
                                                label={translate("employee_visits.form.description") as string}
                                                required disabled={submitting || inExecutionStatus}
                                                margin={margin}
                                                onValueChanged={hasChanged} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="flex-end" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                <b>{translate("employee_visits.form.dates_title") as string}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker name="start_date"
                                                label={translate("employee_visits.form.start_date") as string}
                                                initial={startDateString} format={format}
                                                clearable
                                                defaultIfMissing
                                                autoOK disableFuture={false}
                                                required disabled={submitting || inExecutionStatus}
                                                maxDate={maxDate}
                                                onChange={onChangedDate} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker name="end_date"
                                                label={translate("employee_visits.form.end_date") as string}
                                                initial={endDateString} format={format}
                                                clearable
                                                defaultIfMissing
                                                autoOK disableFuture={false}
                                                required disabled={submitting}
                                                minDate={minDate}
                                                onChange={onChangedDate} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                <b>{translate("employee_visits.form.supervisor_title") as string}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ValidatedInput type="text" id="supervisor_name" name="supervisor_name"
                                                value={request.supervisor_name}
                                                label={translate("employee_visits.form.supervisor_name") as string}
                                                required disabled={submitting}
                                                margin={margin}
                                                onValueChanged={hasChanged} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ValidatedInput type="text" id="supervisor_email" name="supervisor_email"
                                                value={request.supervisor_email}
                                                label={translate("employee_visits.form.supervisor_email") as string}
                                                required disabled={submitting}
                                                margin={margin}
                                                validator={emailValidator}
                                                onValueChanged={hasChanged} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">
                                                {translate("cfdis_se.prepare.employees")}
                                                <Tooltip title={ReactHtmlParser(translate("employee_visits.form.employees_tooltip") as string)}>
                                                            <HelpIcon />
                                                        </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="center" alignContent="center" alignItems="center" spacing={2}>
                                                <Grid item xs={12} sm>
                                                    <ValidatedInput type="text" id="employee_id" name="employee_id"
                                                        value={employeeId}
                                                        label={translate("employees.single") as string}
                                                        options={employeeIds} optionLabels={employeeLabels}
                                                        disabled={submitting || inExecutionStatus}
                                                        margin="dense"
                                                        onValueChanged={hasChangedEmployee} />
                                                </Grid>
                                                <Grid item xs={12} sm="auto">
                                                    <Button variant="outlined" color="secondary" size="medium" disabled={!employeeId} onClick={addEmployee}>
                                                        {translate("buttons.add")}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm="auto">
                                                    <Button variant="outlined" color="secondary" size="medium" disabled={employees.length === 0 || inExecutionStatus} onClick={addAllEmployees}>
                                                        {translate("buttons.add_all")}
                                                    </Button>
                                                </Grid>
                                                {context.isGranted("EmployeeGroupsCreate") && 
                                                    <Grid item xs={12} sm="auto">
                                                        <Button variant="outlined" color="secondary" size="medium" disabled={employees.length === 0 || inExecutionStatus} onClick={addFileEmployees}>
                                                            {translate("employeegroups.add_file")}
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Gridable items={request.employees || []}
                                            loading={false}
                                            empty={translate("cfdis_se.prepare.employees_empty") as string}
                                            columns={[
                                                {
                                                    title: translate("employees.rfc"),
                                                    converter: (item) => item.rfc || "---",
                                                    fullWidth: true,
                                                    xs: 2,
                                                    sm: 2,
                                                    md: 2,
                                                    lg: 2,
                                                    xl: 2,
                                                },
                                                {
                                                    title: translate("employees.name"),
                                                    converter: (item) => item.name || "---",
                                                    fullWidth: true,
                                                    xs: true,
                                                    sm: true,
                                                    md: true,
                                                    lg: true,
                                                    xl: true,
                                                },
                                                {
                                                    title: translate("contracts.from"),
                                                    converter: (item) => (
                                                        <DatePicker name="from"
                                                            label={translate("contracts.from") as string}
                                                            initial={item.from} format={format}
                                                            clearable
                                                            defaultIfMissing
                                                            autoOK disableFuture={false}
                                                            required disabled={submitting}
                                                            minDate={minDate} maxDate={maxDate}
                                                            onChange={onChangedEmployeeDate(item)} />
                                                    ),
                                                    fullWidth: true,
                                                    xs: 2,
                                                    sm: 2,
                                                    md: 2,
                                                    lg: 2,
                                                    xl: 2,
                                                },
                                                {
                                                    title: translate("contracts.to"),
                                                    converter: (item) => (
                                                        <DatePicker name="to"
                                                            label={translate("contracts.to") as string}
                                                            initial={item.to} format={format}
                                                            clearable
                                                            defaultIfMissing
                                                            autoOK disableFuture={false}
                                                            required disabled={submitting}
                                                            minDate={minDate} maxDate={maxDate}
                                                            onChange={onChangedEmployeeDate(item)} />
                                                    ),
                                                    fullWidth: true,
                                                    xs: 2,
                                                    sm: 2,
                                                    md: 2,
                                                    lg: 2,
                                                    xl: 2,
                                                },
                                                {
                                                    title: (
                                                        <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    ),
                                                    converter: (employee, index) => (
                                                        <IconButton aria-label="options" color="default" size="small" onClick={onDeleteEmployee(employee, index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    ),
                                                    fullWidth: true,
                                                    justify: "flex-end",
                                                    xs: "auto"
                                                },
                                            ]} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                        </form>
                        {openConfirmDeleteEmployee && 
                            <CfdisConfirmPopup
                                doAction={confirmDeleteEmployee}
                                onClose={cancelDeleteEmployee}
                                title={translate("employee_visits.delete_employee_modal.title") as string}
                                message={translate("employee_visits.delete_employee_modal.message") as string}
                                secondary={translate("employee_visits.delete_employee_modal.secondary") as string}
                                button={translate("buttons.save") as string}
                            />
                        }
                        {openConfirmSubmit && 
                            <CfdisConfirmPopup
                                doAction={onConfirmSubmit}
                                onClose={onCancelSubmit}
                                title={translate("employee_visits.submit_modal.title") as string}
                                message={translate("employee_visits.submit_modal.message") as string}
                                secondary={translate("employee_visits.submit_modal.secondary") as string}
                                button={translate("buttons.save") as string}
                            />
                        }
                         {employeesNotRegistered.length > 0 && urlImportResults !== "" &&
                <EmployeeGroupResultsImport
                    onClose={() => {setEmployeesNotRegistered([]); setUrlImportResults("");}}
                    url={urlImportResults}
                    employees={employeesNotRegistered}
                />
            }
                        <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".xlsx" />
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                        <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}