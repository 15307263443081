import React, { useState, useEffect } from "react";
import { Grid, Button, ListItemText, Typography, Box } from "@material-ui/core";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Project } from "../model/Project";
import { WarningSnackbar } from "../components/Snackbars";

interface BudgetUpdatePopUpProps {
    project: Project;
    onCompleted(comments: string, amount: number): any;
    onClose(): any;
}

export default function BudgetUpdatePopUp(props: BudgetUpdatePopUpProps) {
    const [comments, setComments] = useState<string>("");
    const [newBudget, setNewBudget] = useState<string>("");
    const [originalBudget, setOriginalBudget] = useState<number>();
    const [currentBudget, setCurrentBudget] = useState<number>();
    const [assignedBudget, setAssignedBudget] = useState<number>();

    const [warning, setWarning] = useState<string>();

    const load = () => {
        setOriginalBudget(props.project.initial_budget);
        setCurrentBudget(props.project.current_budget);
        setAssignedBudget(props.project.assigned_budget);
    }

    useEffect(load, [props]);

    const onSubmit = () => {
        if (!comments || comments === "") {
            setWarning(translate("requisitions.projects.change_budget_pop_up.empty_comments") as string)
            return;
        }
        if (!newBudget || Number(newBudget) <= 0 || Number(newBudget) < Number(currentBudget)) {
            setWarning(translate("requisitions.projects.change_budget_pop_up.invalid_amount") as string)
            return;
        }
        props.onCompleted(comments, Number(newBudget));
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        name === "new_budget" ? setNewBudget(value + "") : setComments(value);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    return (

        <DialogPopup open
            title={translate("requisitions.projects.change_budget_pop_up.title") as string}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.accept")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <Typography style={{ "marginTop": "1%" }} variant="subtitle2">
                        {props.project.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <Grid container alignItems="center" direction="row" justify="space-between">
                    <Grid item xs={4}>
                        <ListItemText primary={translate("requisitions.projects.columns.original_budget")} secondary={originalBudget ? <NumberFormat value={originalBudget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" /> : "$ ---"}/>
                    </Grid>
                    <Grid item xs={4}>
                        <ListItemText primary={translate("requisitions.projects.columns.current_budget")} secondary={currentBudget ? <NumberFormat value={currentBudget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" /> : "$ ---"}/> 
                    </Grid>
                    <Grid item xs={4}>
                        <ListItemText primary={translate("requisitions.projects.columns.assigned_budget")} secondary={assignedBudget ? <NumberFormat value={assignedBudget} prefix="$ " thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" /> : "$ ---"}/>   
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="number"
                        id={"new_budget"}
                        name={"new_budget"}
                        value={newBudget + ""}
                        isValueNumber={true}
                        label={"$  " + translate("requisitions.projects.change_budget_pop_up.new_budget") as string}
                        required={true}
                        margin="dense"
                        onValueChanged={hasChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box pt={1}>
                        <ValidatedInput
                            type="text" id="comment" name="comment"
                            label={translate("requisitions.projects.history.comments") as string}
                            rows={3}
                            onValueChanged={hasChanged}
                            required={true}
                            margin={"dense"} />
                    </Box>
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}
