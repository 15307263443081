import React, { useState } from "react";
import { Button, Typography, Grid, Box, List, ListItem, ListItemText, Divider } from "@material-ui/core";
import translate from "../i18n/Translator";

import { UserImpersonalize, ImpersonalizeUserRequest, ValidatePasswordRequest } from "../model/User";
import { impersonalizeUser, validatePassword } from "../api/SupportAPI";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput from "../components/ValidatedInput";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ValidIcon from '@material-ui/icons/CheckCircleTwoTone';

interface ImpersonalizePopupProps {
    user: UserImpersonalize;
    onSuccess(success: string): any;
    onClose(): any;
}

export default function ImpersonalizePopup(props: ImpersonalizePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [comments, setComments] = useState<string>("");
    const [requestValidPassword, setRequestValidPassword] = useState<ValidatePasswordRequest>({ password: "" } as ValidatePasswordRequest);
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [validated, setValidated] = useState<boolean>(false);
    
    const onImpersonalizeUser = () => {
        setSubmitting(true);
        impersonalizeUser({
            user_agent: props.user.id,
            comments: comments
        } as ImpersonalizeUserRequest).then((_) => {
            setSubmitting(false);
            window.location.href = "/";
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string) => {
        setComments(value);
    };

    const hasChangedPassword = (name: string, value: string) => {
        setRequestValidPassword({ ...requestValidPassword, [name]: value });
    };

    const onValidate = () => {
        setSubmitting(true);
        validatePassword(requestValidPassword).then((_) => {
            setSubmitting(false);
            setValidated(true);
        }).catch((error) => {
            setSubmitting(false);
            setError(translate("users.impersonalize.invalid_key") as string);
        });
    };

    return (
        <DialogPopup open
            title={translate("users.impersonalize.title") as string}
            disable={submitting}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onImpersonalizeUser} variant="contained" color="primary" disabled={submitting || !validated || comments === ""}>
                    {translate("users.impersonalize.button")}
                </Button>}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <List dense subheader={
                        <div>{translate("users.impersonalize.modal.user_data")}</div>
                    }>
                        <Box pl={1}>
                            <ListItem disableGutters>
                                <ListItemText primary={props.user.name} secondary={translate("users.impersonalize.modal.user")} />
                            </ListItem>
                            {props.user.tenant_list.length > 0 ? 
                                <>
                                    {props.user.tenant_list.map(tenant => 
                                    <ListItem disableGutters>
                                        <ListItemText primary={tenant} />
                                    </ListItem>
                                    )}
                                    <ListItem disableGutters>
                                        <ListItemText secondary={translate("tenants.single")} />
                                    </ListItem>
                                </> : 
                                <ListItem disableGutters>
                                    <ListItemText primary={"El usuario no cuenta con empresas asignadas"} />
                                </ListItem>
                            }
                        </Box>
                    </List>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Box pt={2}>
                        <Typography variant="body2">
                            {translate("users.impersonalize.modal.access")}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <ValidatedInput id="password" name="password" disabled={submitting || validated || props.user.tenant_list.length <= 0}
                        value={requestValidPassword.password}
                        required={true} 
                        type="password"
                        margin={"dense"}
                        label={translate("users.impersonalize.modal.input_password") as string}
                        onValueChanged={hasChangedPassword}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    {validated ? 
                        <Box pl={1} pt={2}>
                            <Grid container>
                                <Grid xs={2}><ValidIcon style={{ "color": "#82CA9D"}} /> </Grid>
                                <Grid>
                                    <Typography style={{ "color": "#82CA9D"}}><b>{"Validado"}</b></Typography>
                                </Grid>
                            </Grid>
                        </Box> : 
                        <Box pl={1} pt={1}>
                            <Button onClick={onValidate} variant="outlined" color="primary" disabled={submitting || requestValidPassword.password === ""}>
                                {translate("users.impersonalize.modal.validate_button")}
                            </Button>
                        </Box>}
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput id="comments" name="comments" disabled={submitting || !validated}
                        value={comments}
                        required={true} 
                        type="text"
                        margin={"dense"}
                        rows={5}
                        label={translate("users.impersonalize.modal.comment") as string}
                        onValueChanged={hasChanged}/>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}