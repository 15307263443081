import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { RouterParams } from "../../router/RouterParams";
import { Box, IconButton, Button } from "@material-ui/core";
import translate from "../../i18n/Translator";
import Surface from "../../components/Surface";
import MultiselectDropList, { MultiselectValue } from "../../components/MultiselectDropList";
import { DocumentLine, AssociateRequest, AssociatePORequest, AssociatePOReferenceRequest } from "../../model/Document";
import GridDx from "../../components/GridDx";
import { NoFilterEditor } from "../../components/GridDx";
import { TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import Grid from "@material-ui/core/Grid";
import { DataTypeProviderProps, DataTypeProvider } from "@devexpress/dx-react-grid";
import { getCfdi } from "../../api/TenantCfdiApi";
import { listPurchaseOrders } from "../../api/ProviderPurchaseOrderAPI";
import { PurchaseOrder, PurchaseOrdersQueryParams } from "../../model/PurchaseOrder";
import { AttachmentIcon } from "../../components/Icons";
import RelateConceptsModal from "./RelateConceptsModal";
import { ConceptoTab, PurchaseOrderRelationInvoice } from "../../model/Cfdi";
import Gridable from "../../components/Gridable";
import Ellipsis from "../../components/Ellipsis";
import NumberFormat from "react-number-format";
import { associatePO } from "../../api/TenantCfdiApi";
import { ErrorSnackbar, WarningSnackbar } from "../../components/Snackbars";
import { Redirect } from "react-router-dom";
import CustomBackdrop from "../../components/CustomBackdrop";
import queryString from "query-string";

export default function RelateLinesPoConceptsCfdi({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [concepts, setConcepts] = useState<ConceptoTab[]>([]);
    const [relations, setRelations] = useState<PurchaseOrderRelationInvoice[]>();
    const [line, setLine] = useState<DocumentLine>();
    const [relation, setRelation] = useState<PurchaseOrderRelationInvoice>();
    const [elementos, setElementos] = useState<MultiselectValue[]>([]);
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
    const [currency, setCurrency] = useState<string>("");
    const tenantId = context.session!.tenant!.id;
    const cfdiId = match.params.cfdiId;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [subtitle, setSubtitle] = useState<string>();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [redirect, setRedirect] = useState<string | undefined>();
    const [restrictOnePurchaseOrderPerDocument, setRestrictOnePurchaseOrderPerDocument] = useState<boolean>(false);
    const [mustLinkAllConcepts, setMustLinkAllConcepts] = useState<boolean>(false);
    const [validateInvoiceAmounts, setValidateInvoiceAmounts] = useState<string>("NONE");
    const [amountTolerance, setAmountTolerance] = useState<number>(0);
    const qs = queryString.parse(window.location.search);

    const getPurchaseOrders = (currency: string, providerId: string, companyId: string) => {
        listPurchaseOrders(tenantId, providerId, 0, 0, {
            status: "O",
            search: "",
            currency: currency,
            company_id: companyId
        } as PurchaseOrdersQueryParams).then(responsePO => {
            if (responsePO.items) {
                const values = responsePO.items.map((item) => {
                    return {
                        title: item.external_id,
                        value: item.external_id
                    } as MultiselectValue;
                });
                setElementos(values);
                setPurchaseOrders(responsePO.items);
            }
        }).catch(error => setError(error.message)).finally(() => setSubmitting(false));
    };

    useEffect(() => {
        setSubmitting(true);
        getCfdi(tenantId, cfdiId).then(response => {
            setRestrictOnePurchaseOrderPerDocument(response.restrict_only_one_po);
            setValidateInvoiceAmounts(response.validate_amounts_line_po);
            setAmountTolerance(response.amount_tolerance_line_po);
            setMustLinkAllConcepts(response.must_link_all_concepts);
            setCurrency(response.currency);
            if (response.cfdi?.conceptos) {
                let totalCreditNotes = (response.metadata?.payment_info?.credit_notes ?? []).map(cn => cn.amount).reduce((a, b) => a + b, 0) ?? 0.0;
                const proportionalCreditNote = 1 - (totalCreditNotes / response.total);
                setConcepts(response.cfdi.conceptos.map((concept, index) => {
                    return { ...concept, id: index + "", importe: (concept.importe * proportionalCreditNote) } as ConceptoTab;
                }));
            }
            setSubtitle(response.identifier);
            if (response.metadata.purchase_orders_response) {
                setRelations(response.metadata.purchase_orders_response);
            }
            getPurchaseOrders(response.currency, response.metadata.provider_id, response.metadata.company_id);
        }).catch(error => setError(error.message)).finally(() => setSubmitting(false));
        // eslint-disable-next-line
    }, [tenantId, cfdiId]);

    const getValues = () => {
        let values = {} as any;
        (relations || []).forEach(relation => {
            values[relation.purchase_order_external_id] = {
                title: relation.purchase_order_external_id,
                value: relation.purchase_order_external_id
            } as MultiselectValue;
        })

        return Object.values(values) as MultiselectValue[];
    };

    const onChanged = (selected: string[]) => {
        if (restrictOnePurchaseOrderPerDocument && relations) {
            let newLines = selected.filter(sel => relations.findIndex(relation => relation.purchase_order_external_id === sel) < 0);
            let purchaseOrderNew = purchaseOrders.filter(po => newLines.findIndex(select => select === po.external_id) >= 0);
            let linesT = purchaseOrderNew.flatMap(document => {
                return document.lines.map(line => {
                    return {
                        line_num: line.line_num + "",
                        code: line.item_code,
                        description: line.description,
                        quantity: line.quantity,
                        unit_measure: line.unit_of_measure,
                        unit_price: line.price,
                        price: line.line_total,
                        purchase_order_line_external_id: line.external_id,
                        purchase_order_external_id: document.external_id,
                        concepts: []
                    } as PurchaseOrderRelationInvoice;
                });
            });
            setRelations(linesT);
        } else if (relations) {
            let linesT = relations.filter(relation => selected.findIndex(sel => relation.purchase_order_external_id === sel) >= 0);
            let newRelations = selected.filter(sel => relations.findIndex(relation => relation.purchase_order_external_id === sel) < 0);
            let purchaseOrderNew = purchaseOrders.filter(po => newRelations.findIndex(select => select === po.external_id) >= 0);

            purchaseOrderNew.forEach(document => {
                document.lines.forEach(line => {
                    linesT.push({
                        line_num: line.line_num + "",
                        code: line.item_code,
                        description: line.description,
                        quantity: line.quantity,
                        unit_measure: line.unit_of_measure,
                        unit_price: line.price,
                        price: line.line_total,
                        purchase_order_line_external_id: line.external_id,
                        purchase_order_external_id: document.external_id,
                        concepts: []
                    } as PurchaseOrderRelationInvoice);
                })
            });

            setRelations(linesT);
        } else {
            let purchaseOrderSelected = purchaseOrders.filter(po => selected.findIndex(select => select === po.external_id) >= 0);

            let linesT = purchaseOrderSelected.flatMap(document => {
                return document.lines.map(line => {
                    return {
                        line_num: line.line_num + "",
                        code: line.item_code,
                        description: line.description,
                        quantity: line.quantity,
                        unit_measure: line.unit_of_measure,
                        unit_price: line.price,
                        price: line.line_total,
                        purchase_order_line_external_id: line.external_id,
                        purchase_order_external_id: document.external_id,
                        concepts: []
                    } as PurchaseOrderRelationInvoice;
                });
            });
            setRelations(linesT);
        }
    };

    const onClickedOptions = (relation: PurchaseOrderRelationInvoice) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setRelation(relation);
        let linesTemp = purchaseOrders.filter(po => po.external_id === relation.purchase_order_external_id)
            .flatMap(po => po.lines.map(line => { return { ...line, po_external_id: po.external_id } as DocumentLine; }))
            .filter(line => line.external_id === relation.purchase_order_line_external_id);
        if (linesTemp.length > 0) {
            setLine(linesTemp[0]);
        }
    };

    const onSave = (selectecConcepts: string[]) => {
        setAnchorEl(null);
        if (!relation) return;
        let tempRelations = relations || [];
        tempRelations.filter(relationTemp => relationTemp.purchase_order_line_external_id === relation.purchase_order_line_external_id &&
            relationTemp.purchase_order_external_id === relation.purchase_order_external_id)
            .forEach(tempLine => tempLine.concepts = selectecConcepts.map(selectedIndex => concepts[Number(selectedIndex)]));
        setRelations(tempRelations);
    };

    const onClose = () => {
        setAnchorEl(null);
        setRelation(undefined);
    };

    const validateRequest = (request: AssociateRequest) => {
        if (!request.relations || request.relations.find(relation => relation.references.length == 0)) {
            return translate("cfdi_relate_po.warning_request.empty_request") as string;
        }
        if (mustLinkAllConcepts) {
            let indexConceptsInRequest = request.relations.flatMap(relation => relation.references).map(reference => reference.concept_index);
            let conceptsNotRelated = concepts.filter(concept => indexConceptsInRequest.findIndex(index => index === concept.id) < 0);
            if (conceptsNotRelated.length > 0) {
                return translate("cfdi_relate_po.warning_request.link_all_concepts") as string;
            }
        }
        return "";
    }

    const getRequestFromView = () => {
        if (!relations) return {} as AssociateRequest;
        let requestRelations = [] as AssociatePORequest[];
        relations.forEach(relation => {
            let index = requestRelations.findIndex(rel => rel.po_external_id === relation.purchase_order_external_id);
            if (index >= 0) {
                relation.concepts.forEach(concept => {
                    requestRelations[index].references.push({
                        line_external_id: relation.purchase_order_line_external_id,
                        concept_index: concept.id
                    } as AssociatePOReferenceRequest);
                });
            } else {
                let references = [] as AssociatePOReferenceRequest[];
                relation.concepts.forEach(concept => {
                    references.push({
                        line_external_id: relation.purchase_order_line_external_id,
                        concept_index: concept.id
                    } as AssociatePOReferenceRequest);
                });
                requestRelations.push({
                    po_external_id: relation.purchase_order_external_id,
                    references: references
                } as AssociatePORequest);
            }
        });
        return { relations: requestRelations } as AssociateRequest;
    };

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const onSubmit = () => {
        let request = getRequestFromView();
        let validation = validateRequest(request);
        if (validation !== "") {
            setWarning(validation);
            return;
        }
        setSubmitting(true);
        associatePO(tenantId, cfdiId, request).then(response => {
            setRedirect(`/cfdis/${cfdiId}/details?${queryString.stringify(qs)}`);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const columns = [
        {
            name: 'purchase_order_external_id',
            title: translate("purchase_orders.single") as string
        },
        {
            name: 'line_num',
            title: translate("purchase_orders.line_num") as string,
            getCellValue: (row: any) => row.line_now || ""
        },
        {
            name: 'code',
            title: translate("purchase_orders.item_code") as string,
        },
        {
            name: 'unit_measure',
            title: translate("purchase_orders.unit_measure") as string,
        },
        {
            name: 'description',
            title: translate("cfdis.details.description") as string,
        },
        {
            name: 'quantity',
            title: translate("cfdis.details.quantity") as string,
        },
        {
            name: 'unit_price',
            title: translate("cfdis.details.unit_price", { "currency": currency }) as string,
        },
        {
            name: 'price',
            title: translate("cfdis.details.price", { "currency": currency }) as string,
        },
        {
            name: 'icon',
            title: " "
        },
    ];

    const [columnsFormat] = useState([
        { columnName: 'line_num' },
        { columnName: 'purchase_order_external_id' },
        { columnName: 'code' },
        { columnName: 'unit_measure' },
        { columnName: 'description' },
        { columnName: 'quantity' },
        { columnName: 'unit_price' },
        { columnName: 'price' },
        { columnName: 'icon' }
    ]);

    const currencyColumns = ['price', 'unit_price'];

    const textColumns = ['description'];

    const rowDetailComponent = (params: TableRowDetail.ContentProps) => (
        params.row.concepts ?
            <Gridable
                items={params.row.concepts}
                loading={false}
                empty={translate("cfdis.details.concept_empty") as string}
                columns={[
                    {
                        title: translate("cfdis.details.quantity") as string,
                        converter: (concept: ConceptoTab) => (<Ellipsis text={concept.cantidad + ""} lenght={100} uppercased={false} />),
                        xs: true
                    },
                    {
                        title: translate("cfdi_relate_po.concept") as string,
                        converter: (concept: ConceptoTab) => (<Ellipsis text={concept.description} lenght={100} uppercased={false} />),
                        xs: true
                    },
                    {
                        title: translate("cfdis.details.price", { "currency": currency }) as string,
                        converter: (concept: ConceptoTab) => (<NumberFormat value={concept.importe} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />),
                        xs: true
                    },
                ]} />
            : <></>
    );

    const Icon = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(value.row)}>
                <AttachmentIcon />
            </IconButton>;
        }} {...props} />
    );

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const filtersExclude = ['line_num', 'purchase_order_external_id', 'code', 'unit_measure', 'description', 'quantity', 'unit_price', 'price'];

    const customPlugins = [
        <NormalTypeProvider for={filtersExclude} editorComponent={NoFilterEditor} />,
        <Icon for={['icon']} editorComponent={NoFilterEditor} />,
    ];

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Surface title={translate("cfdi_relate_po.title")} subtitle={subtitle} className="FormSurface" backButton backTo={`/cfdis/${cfdiId}/details?${queryString.stringify(qs)}`} icon footerActions={
            <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
                <Box pr={2}>
                    <Grid item >
                        <Grid container>
                            <Grid item >
                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={() => setRedirect(`/cfdis/${cfdiId}/details?${queryString.stringify(qs)}`)}>
                                    {translate("buttons.cancel")}
                                </Button>
                            </Grid>
                            <Grid item >
                                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={submitting}>
                                    {translate("buttons.save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        }>
            <Grid container>
                <Grid item xs={6}>
                    <MultiselectDropList
                        title={translate("cfdi_relate_po.multiselect") as string}
                        elementos={elementos}
                        value={getValues()}
                        required={false}
                        disabled={false}
                        onChanged={onChanged} />
                </Grid>
            </Grid>
            <GridDx
                loading={false}
                rows={relations || []}
                columns={columns}
                columnsFormat={columnsFormat}
                onClickRow={() => { }}
                amountCurrencyColumns={currencyColumns}
                textColumns={textColumns}
                rowDetailComponent={rowDetailComponent}
                customFormatColumns={customPlugins}
                noUseId={true}
            />
            {line && anchorEl && relations &&
                <RelateConceptsModal line={line}
                    onClose={onClose}
                    onSave={onSave}
                    concepts={concepts}
                    currency={currency}
                    cfdiId={cfdiId}
                    validateAmountsType={validateInvoiceAmounts}
                    tolerance={amountTolerance}
                    selectedConceptsIndex={relations.filter(relation =>
                        relation.purchase_order_line_external_id === line.external_id
                    ).flatMap(relation => relation.concepts).map(concept => concept.id)} />
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
        </Surface>
    );
}