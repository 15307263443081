import translate from "../i18n/Translator";

import { Refund } from "../model/Refund";
import { GridDxProps } from "../components/GridDx";
import { TableSelection } from '@devexpress/dx-react-grid-material-ui';

export interface GetRefundsPropertiesProps {
    view: string;
}

export function getRefundsProperties(props: GetRefundsPropertiesProps) {
    const isToSend = "to_send" === props.view;

    const columnsFormat = [
        { columnName: 'identifier', width: isToSend ? 160 : 80 },
        { columnName: 'issuer_name', width: isToSend ? 300 : 220, wordWrapEnabled: true },
        { columnName: 'sender_name', width: isToSend ? 0 : 100, wordWrapEnabled: true },
        { columnName: 'description', width: isToSend ? 300 : 250 },
        { columnName: 'date', width: isToSend ? 170 : 150 },
        { columnName: 'send_at', width: isToSend ? 160 : 120 },
        { columnName: 'total', align: 'right', width: 130 },
        { columnName: 'payment_amount', align: 'right', width: 130 },
        { columnName: 'metadata.balance', align: 'right', width: isToSend ? 155 : 130 },
        { columnName: 'currency', width: isToSend ? 155 : 130 },
        { columnName: 'status', width: isToSend ? 200 : 150 },
        { columnName: 'menu', align: "right", width: 35 },
    ];

    const columns = [
        {
            name: 'identifier',
            title: translate('refunds.columns.id') as string
        },
        {
            name: 'issuer_name',
            title: translate('refunds.columns.vendor') as string,
            getCellValue: (row: any) => row.issuer.name
        },
        {
            name: 'sender_name',
            title: translate('refunds.columns.requester') as string,
            getCellValue: (row: any) => row.metadata.uploader_name ? row.metadata.uploader_name : ""
        },
        {
            name: 'description',
            title: translate('refunds.columns.description') as string
        },
        {
            name: 'date',
            title: translate('refunds.columns.date') as string,
        },
        {
            name: 'send_at',
            title: translate("refunds.columns.send_at") as string,
        },
        {
            name: 'total',
            title: translate("refunds.columns.total") as string,
        },
        {
            name: 'payment_amount',
            title: translate("refunds.columns.paid") as string,
            getCellValue: (row: any) => row.total - row.metadata.balance
        },
        {
            name: 'metadata.balance',
            title: translate('refunds.columns.balance') as string,
            getCellValue: (row: any) => row.metadata.balance
        },
        {
            name: 'currency',
            title: translate('refunds.columns.currency') as string,
        },
        {
            name: 'status',
            title: translate("cfdis.columns.status") as string,
            getCellValue: (row: any) => getStatus(row)
        },
        {
            name: 'menu',
            title: " "
        }
    ];

    const getStatus = (refund: Refund) => {
        if (refund.metadata.custom_status) {
            return refund.metadata.custom_status;
        }
        return translate(`cfdis.history.status.${refund.metadata.status}`) as string;
    };

    const currencyColumns = ['total', 'payment_amount', 'metadata.balance'];

    const clickRowColumns = ['identifier'];

    const textColumns = ['identifier', 'description', 'issuer_name', 'sender_name', 'currency', 'status'];

    const dateColumns = ['date', 'send_at'];

    const rightColumns = ['menu'];
    
    const leftColumns =[TableSelection.COLUMN_TYPE];

    const getColumns = () => {
        if (isToSend && columns) {
            return columns.filter(c => "sender_name" !== c.name && "payment_amount" !== c.name && "metadata.balance" !== c.name);
        }
        return columns;

    }

    return {
        columnsFormat: columnsFormat,
        columns: getColumns(),
        amountCurrencyColumns: currencyColumns,
        clickRowColumns: clickRowColumns,
        textColumns: textColumns,
        dateColumns: dateColumns,
        rightColumns: rightColumns,
        leftColumns: leftColumns
    } as GridDxProps<Refund>;
}
