import React, { useState, useContext, useEffect } from "react";
import { Divider, IconButton, Fab } from "@material-ui/core";
import FileIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DownloadIcon from '@material-ui/icons/GetApp';

import translate from "../i18n/Translator";
import { Cff69ListFiles } from "../model/Cff69File";
import { getCff69ListFiles, updateCff69ListFiles } from "../api/Cff69FilesAPI";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { AppContext } from "../context/AppContext";
import Ellipsis from "../components/Ellipsis";
import Gridable from "../components/Gridable";
import NumberFormat from "react-number-format"
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import DateFormat from "../components/DateFormat";

export default function Cff69FilesList() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cff69ListFiles>();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const load = () => {
        let offset = getOffset(page, pageSize);
        setStatus("loading");
        getCff69ListFiles(pageSize, offset).then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [context.session, page, pageSize]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const callUpdateEfos = () => {
        setLoading(true);
        updateCff69ListFiles().then((response) => {
            setSuccess(translate("cff_69_files.success") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    return (
        <Pagination title={translate("cff_69_files.title")} icon={<FileIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                context.isGranted("EfosFileUpdate") ? <Fab color="primary" size="small" title={translate("buttons.download_cff_69_files") as string} onClick={callUpdateEfos} disabled={loading}>
                    <CloudDownloadIcon fontSize="small" />
                </Fab> : undefined
            }>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("cff_69_files.empty") as string}
                columns={[
                    {
                        title: translate("cff_69_files.name") as string,
                        converter: (cff69File) => (<Ellipsis text={cff69File.name || translate("cff_69_files.processing") as string} lenght={100} uppercased={false} />),
                        xs: 9,
                        sm: 3,
                        md: 3,
                        xl: 5
                    },
                    {
                        title: translate("cff_69_files.type") as string,
                        converter: (cff69File) => (<Ellipsis text={cff69File.type ? translate(`cff_69_files.types.${cff69File.type}`) as string : translate("cff_69_files.processing") as string} lenght={100} uppercased={false} />),
                        xs: 9,
                        sm: 3,
                        md: 3,
                        xl: 2
                    },
                    {
                        title: translate("cff_69_files.records") as string,
                        converter: (cff69File) => (
                            <NumberFormat value={cff69File.records} thousandSeparator="," displayType="text" />
                        ),
                        xs: false,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: translate("cff_69_files.published") as string,
                        converter: (cff69File) => (<DateFormat date={cff69File.published} format="L" />),
                        xs: false,
                        sm: 2,
                        md: 2,
                        xl: 2
                    },
                    {
                        title: "",
                        converter: (cff69File) => (
                            <IconButton aria-label="options" color="default" size="small" disabled={!cff69File.location} onClick={() => window.open(cff69File.location, "_blank")}>
                                <DownloadIcon />
                            </IconButton>
                        ),
                        justify: "flex-end",
                        xs: 3,
                        sm: true,
                        md: true,
                        xl: 1
                    }
                ]} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Pagination>
    )
}