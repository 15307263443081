import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ProviderExpedient, ProviderExpedientBankAccount, ProviderPaymentBankAccountRequest, ProviderPaymentBankAccountsRequest } from "../model/ProviderExpedient";
import Ellipsis from "../components/Ellipsis";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import Gridable, { GridableColumn } from "../components/Gridable";
import { assignPaymentBankAccount } from "../api/ProviderExpedientAPI";

interface ProviderAssignPaymentBankAccountsPopupProps {
    tenantId: string,
    providerId: string,
    expedient: ProviderExpedient,
    onClose(): any;
    onSubmit(expedient: ProviderExpedient): any;
    onError(error: any): any
}

export default function ProviderAssignPaymentBankAccountsPopup(props: ProviderAssignPaymentBankAccountsPopupProps) {
    const [submitting, setSubmitting] = useState(false);
    const [request, setRequest] = useState<ProviderPaymentBankAccountsRequest>({payment_bank_accounts: [{currency:"", template_field_bank_account_id: ""}]});

    useEffect(() => {
        setRequest({payment_bank_accounts: Object.entries(props.expedient.payment_bank_accounts!).flatMap(row => {return {currency: row[0], template_field_bank_account_id: row[1].id} as ProviderPaymentBankAccountRequest})});
    }, [props]);

    const getBankAccountsLabels = (currency : string) => {
        return Object.entries(props.expedient.bank_accounts).filter(row => (row[1] as ProviderExpedientBankAccount).value && currency === (row[1] as ProviderExpedientBankAccount).value!.currency ).flatMap(row => {return `${getBankName(row[1] as ProviderExpedientBankAccount)} - ${(row[1] as ProviderExpedientBankAccount).value!.account}`});
    }

    const getBankName = (expedientBankAccount : ProviderExpedientBankAccount) : string  => {
        return expedientBankAccount.value!.bank_name ?? expedientBankAccount.value!.bank;
    }

    const getBankAccountsIds = (currency : string) => {
        return Object.entries(props.expedient.bank_accounts).filter(row => (row[1] as ProviderExpedientBankAccount).value && currency === (row[1] as ProviderExpedientBankAccount).value!.currency ).flatMap(row => {return row[0]});
    }

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        let currency = (new Map(Object.entries(props.expedient.bank_accounts))).get(value).value.currency;
        let newRequest = request;
        newRequest.payment_bank_accounts.splice(newRequest.payment_bank_accounts.findIndex(t => t.currency === currency), 1, {currency: currency, template_field_bank_account_id: value});
        setRequest(newRequest);
    };


    const getColumns = (): GridableColumn<ProviderPaymentBankAccountRequest>[] => {
    const columns = [
        {
            title: translate("expedients.payment_bank_accounts.popup.currency") as string,
            converter: (item: ProviderPaymentBankAccountRequest, index: number) => <Ellipsis text={item.currency} lenght={100} uppercased={false} />,
            justify: "flex-start",
            fullWidth: true,
            xs: 3,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3
        },
        {
            title: translate("expedients.payment_bank_accounts.popup.payment_account") as string,
            converter: (item: ProviderPaymentBankAccountRequest, index: number) => <ValidatedInput
                type="text"
                id="payment_bank_account_id"
                name="payment_bank_account_id"
                value={item.template_field_bank_account_id}
                label={translate("expedients.payment_bank_accounts.popup.bank_accounts") as string}
                options={getBankAccountsIds(item.currency)}
                optionLabels={getBankAccountsLabels(item.currency)}
                required={true}
                omitEmpty={true}
                onValueChanged={hasChanged}
            />,
            justify: "flex-start",
            fullWidth: true,
            xs: "9",
            sm: "9",
            md: "9",
            lg: "9",
            xl: "9"
        }
    ] as GridableColumn<ProviderPaymentBankAccountRequest>[];
    return columns.filter(col => col != null);
    };
    
    const onConfirm = () => {
        setSubmitting(true);
        assignPaymentBankAccount(props.tenantId, props.providerId, request).then((response) => {
            props.onSubmit(response);
        }).catch((error) => {
            props.onError(error);
        }).finally(() => {
            setSubmitting(false);
        });
        
    }

    return (
        <DialogPopup title={translate("expedients.payment_bank_accounts.popup.title")} open onClose={props.onClose} 
        disableEscapeKeyDown={submitting} disableBackdropClick={submitting} disable={submitting} children= {
            <Grid>
                <Grid container justify="space-between" alignContent="center">
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>{translate("expedients.payment_bank_accounts.popup.description")}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ "padding": 0 }}>
                        <Gridable
                            loading={false}
                            items={request.payment_bank_accounts ? request.payment_bank_accounts : []}
                            columns={getColumns()}
                        />
                        
                </Grid>
            </Grid>
            
        } closeText={translate("buttons.cancel") as string} button={
            <Button onClick={onConfirm} variant="contained" color="primary" disabled={submitting}>
                {translate("buttons.save")}
            </Button>
        }/>
            
    );
}
