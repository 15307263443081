import React, { useState } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";

import DialogPopup from "./DialogPopup";

interface ConfirmationPopupProps {
    title: string;
    message: string | React.ReactNode;
    secondary?: string;
    button?: string;
    onClose(): any;
    doAction?(): any;
    submitting?: boolean;
    addCommentary?: boolean;
    comment?: string;
    closeText?: string;
    disableRowsComment?: boolean;
    doActionCommentary?(comment: string): any;
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
}

export default function ConfirmationPopup(props: ConfirmationPopupProps) {
    const [comment, setComment] = useState<string>("");
    const onConfirm = () => {
        if (props.doActionCommentary && ((props.addCommentary && comment !== "") || !props.addCommentary)) {
            props.doActionCommentary(comment);
        } else if (props.doAction) {
            props.doAction();
        }
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setComment(value);
    };

    return (
        <DialogPopup open
            title={props.title}
            disableEscapeKeyDown={props.submitting ? props.submitting : false}
            disableBackdropClick={props.submitting ? props.submitting : false}
            disable={props.submitting ? props.submitting : false}
            closeText={props.closeText || translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            button={props.button && (
                <Button onClick={onConfirm} variant="outlined" color={props.color || "primary"} disabled={props.submitting ? props.submitting :
                    props.addCommentary && comment === "" ? true : false}>
                    {props.button}
                </Button>
            )} >
            {typeof props.message === "string" && (
                <Typography variant="body2">
                    {props.message}
                </Typography>
            )}
            {typeof props.message !== "string" && (
                props.message
            )}
            {props.secondary && (
                <Box pt={1}>
                    <Typography variant="body2">
                        {props.secondary}
                    </Typography>
                </Box>
            )}
            {props.doActionCommentary && (
                <Box pt={1}>
                    <ValidatedInput
                        type="text" id="comment" name="comment"
                        label={props.comment !== undefined ? props.comment : ""}
                        rows={props.disableRowsComment ? undefined : 5}
                        onValueChanged={hasChanged}
                        required={props.addCommentary}
                        margin={"dense"} />
                </Box>
            )}

        </DialogPopup>
    );

}