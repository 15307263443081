import { callAPI, HTTPMethod } from "./API";
import { ProrationTemplates, ProrationTemplate, ProrationTemplatesQueryParams, ProrationTemplateRequest } from "../model/ProrationTemplate";

const basePath = (tenantId: string) => `/tenants/${tenantId}/proration-templates`;

export async function listProrationTemplates(tenantId: string, page: number, offset: number, params: ProrationTemplatesQueryParams): Promise<ProrationTemplates> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search ?? "",
            pageSize: page.toString(),
            offset: offset.toString(),
            companyId: params.company_id ?? ""
        }
    });
}

export async function createProrationTemplate(tenantId: string, request: ProrationTemplateRequest): Promise<ProrationTemplate> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getProrationTemplate(tenantId: string, id: string): Promise<ProrationTemplate> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateProrationTemplate(tenantId: string, id: string, request: ProrationTemplateRequest): Promise<ProrationTemplate> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteProrationTemplate(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
