import React, { useState, useEffect, useContext } from "react";
import { getCalendarEvents, deleteEvent } from "../api/DashboardAPI";
import { AppContext } from "../context/AppContext";
import { Calendar, CalendarEvent, CloneCalendarResponse } from "../model/Dashboard";
import translate from "../i18n/Translator";
import { Grid, Divider, IconButton, Fab, Box, TextField, Menu, MenuItem, ListItemIcon, Typography, Fade } from '@material-ui/core';
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DateFormat from "../components/DateFormat";
import AddIcon from '@material-ui/icons/AddTwoTone';
import Surface from "../components/Surface";
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/EditTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import CalendarEventForm from "./CalendarEventFormPopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';
import AuxiliarDocumentsIcon from "@material-ui/icons/FileCopy";
import CloneCalendarFormPopop from './CloneCalendarFormPopup';
import CalendarIcon from "@material-ui/icons/DateRangeTwoTone";
import CloneCalendarResultPopup from './CloneCalendarResultPopup';

export default function CalendarManagementView() {
    const currentYear = new Date().getFullYear();
    const tenantId = useContext(AppContext).session!.tenant!.id;
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Calendar>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectItem, setSelectItem] = useState<CalendarEvent | undefined>(undefined);
    const [openEditEvent, setOpenEditEvent] = useState<boolean>(false);
    const [openDeleteEvent, setOpenDeleteEvent] = useState<boolean>(false);
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const [success, setSuccess] = useState<string | JSX.Element | JSX.Element[]>();
    const [openClone, setOpenClone] = useState<boolean>(false);
    const [openClonePopup, setOpenClonePopup] = useState<boolean>(false);

    const [yearOptions, setYearOptions] = useState<string[]>([]);

    const [yearSelected, setYearSelected] = useState<string>(`${currentYear}`);

    const [clonResult, setClonResult] = useState<CloneCalendarResponse>();

    const onInit = () => {
        getCalendarEvents(tenantId, { year: yearSelected, admin_view: true }).then(((data: any) => {
            const years = data?.years ?? [];
            if (!years.find((e: number) => currentYear === e)) {
                years.push(currentYear);
            }
            years.reverse();
            setYearOptions(years.map((e: number) => `${e}`))
            setData(data);
        })).catch((e: any) => {
            setStatus(e.message);
        }).finally(() => {
            setStatus("loaded");
        });
    }

    useEffect(onInit, [tenantId, yearSelected]);

    const onClickedOptions = (item: CalendarEvent) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectItem(item);
    };

    const onDeleteEvent = () => {
        if (data && selectItem) {
            deleteEvent(tenantId, data.id, selectItem?.id).then(() => {
                setSelectItem(undefined);
                setAnchorEl(null);
                setSuccess(translate("calendar_events.event_deleted_success") as string);
                onInit();
            }).catch((e) => {
                setError(e.message);
            }).finally(() => {
                setOpenDeleteEvent(false);
            });
        }
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    return (
        <Surface
            title={translate("calendar_events.title")}
            icon={<CalendarIcon />}
            className="PaperPagination"
            titleActions={
                (<Grid container alignItems="center" justify="flex-end" spacing={1}>
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("buttons.add") as string} onClick={() => {
                            setOpenEditEvent(true);
                        }}>
                            <AddIcon />
                        </Fab>
                    </Grid>

                    <Grid item xs="auto">
                        <IconButton aria-label="options" color="default" size="small" onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setOpenClone(true);
                            setAnchorEl(event.currentTarget);
                        }}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </Grid>)
            }>

            <form autoComplete="off" noValidate >
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("calendar_events.calendar_name") as string}
                            margin="dense" disabled={true}
                            value={data?.name} />
                    </Grid>
                    <Grid item xs={5} lg={3} >
                        <Autocomplete
                            size="small"
                            value={yearSelected}
                            options={yearOptions}
                            getOptionLabel={(elemento) => elemento}
                            noOptionsText={translate("calendar_events.year") as string}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={translate("calendar_events.year") as string} fullWidth />
                            )}
                            onChange={(event, newValue) => {
                                setYearSelected(newValue ?? "")
                            }}
                            getOptionSelected={(opt, val) => opt === val}
                        />
                    </Grid>
                </Grid>
            </form>
            <Divider />

            <Gridable
                items={data?.items ?? []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("calendar_events.empty") as string}
                onClick={(item) => { setSelectItem(item); setOpenEditEvent(true) }}
                columns={[
                    {
                        title: translate("calendar_events.start_date") as string,
                        converter: (item) => <DateFormat date={(item.date as any)} format="dddd, DD MMMM YYYY " />,
                        fullWidth: true,
                        xs: true,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("calendar_events.end_date") as string,
                        converter: (item) => item?.end_date ? <DateFormat date={(item.end_date as any)} format="dddd, DD MMMM YYYY" /> : <></>,
                        fullWidth: true,
                        sm: false,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("calendar_events.description") as string,
                        converter: (item) => item.label,
                        fullWidth: true,
                        xs: true,
                        sm: true,
                        md: true,
                        lg: 3,
                        xl: true
                    },
                    {
                        title: translate("calendar_events.type") as string,
                        converter: (item) => translate(`calendar.legend.${item.type}`),
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("calendar_events.color") as string,
                        converter: (item) => item?.color ?
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={3} sm alignItems="center" >
                                    <Box style={{ background: item?.color, padding: "5px", margin: "auto", border: "1px solid #e0e0e0", width: "25px", height: "25px" }}></Box>
                                </Grid>
                                <Grid item xs={9} lg={9} >
                                    {item.color}
                                </Grid>
                            </Grid>
                            : <></>,
                        fullWidth: true,
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (item) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(item)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {selectItem && anchorEl &&
                <MenuOption anchor={anchorEl}
                    event={selectItem}
                    onEdit={() => {
                        setAnchorEl(null);
                    }}
                    onDelete={() => {
                        setOpenDeleteEvent(true);
                    }}
                    onClose={() => {
                        setAnchorEl(null);
                        setSelectItem(undefined);
                    }} />
            }

            {(!anchorEl && (selectItem || openEditEvent)) &&
                <CalendarEventForm
                    calendarId={data?.id ?? ""}
                    event={selectItem}
                    currentYear={yearSelected}
                    onClose={() => {
                        setOpenEditEvent(false);
                        setSelectItem(undefined);
                    }}
                    onSubmit={(value: any) => {
                        setOpenEditEvent(false);
                        setSelectItem(undefined);
                        onInit();
                    }} />
            }
            {openClone &&
                <MenuOption anchor={anchorEl}
                    onClone={() => {
                        setOpenClone(false);
                        setAnchorEl(null);
                        setOpenClonePopup(true);
                    }}
                    onClose={() => {
                        setOpenClone(false);
                        setAnchorEl(null);
                    }} />
            }
            {openDeleteEvent && selectItem &&
                <ConfirmationPopup
                    title={translate("calendar_events.title") as string}
                    message={ReactHtmlParser(translate("calendar_events.delete_event",
                        {
                            description: selectItem?.label ?? translate(`calendar.legend.${selectItem.type}`),
                            day: moment(selectItem.date).format("DD-MM-YYYY")
                        }) as string)}
                    button={translate("buttons.accept") as string}
                    onClose={() => {
                        setOpenDeleteEvent(!openDeleteEvent);
                        setAnchorEl(null);
                        setSelectItem(undefined);
                    }}
                    doAction={onDeleteEvent}
                />
            }

            {openClonePopup &&
                <CloneCalendarFormPopop
                    calendarId={data?.id ?? ""}
                    fromOptions={yearOptions}
                    onClose={() => {
                        setOpenClonePopup(false);
                    }}
                    onSubmit={(response) => {
                        setOpenEditEvent(false);
                        setSelectItem(undefined);
                        setOpenClonePopup(false);
                        onInit();
                        setClonResult(response);
                    }} />
            }
            {clonResult &&
                <CloneCalendarResultPopup
                    start={`${clonResult.start}`}
                    end={`${clonResult.end}`}
                    result={clonResult}
                    onClose={() => setClonResult(undefined)}
                />
            }
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Surface>
    );
}

interface CalendarEventProps {
    event?: CalendarEvent;
    anchor?: HTMLElement | null;
    onClose?(): void;
    onEdit?(): void;
    onDelete?(): void;
    onClone?(): void
}

export function MenuOption(props: CalendarEventProps) {
    if (props.onClone) {
        return (
            <Menu
                id="event-item"
                anchorEl={props.anchor}
                keepMounted
                onClose={props.onClose}
                open
                TransitionComponent={Fade}
            >
                <MenuItem button onClick={props.onClone} dense>
                    <ListItemIcon>
                        <AuxiliarDocumentsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.clone")}</Typography>
                </MenuItem>
            </Menu>
        );
    }

    return (
        <Menu
            id="event-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem button onClick={props.onEdit} dense>
                <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.edit")}</Typography>
            </MenuItem>
            <MenuItem button onClick={props.onDelete} dense>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <Typography variant="inherit">{translate("buttons.delete")}</Typography>
            </MenuItem>
        </Menu>
    );

}