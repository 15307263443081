import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ListItemText, Menu, Grid, ListItemIcon, Fade, ListItem, List } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import ActivateIcon from "@material-ui/icons/DeleteTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";
import ToAuthorizeIcon from '@material-ui/icons/ThumbUp';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface RequisitionMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onAuthorizeSelected(): any;
    onAuthorizeListed(): any;
}

export default function RequisitionsMenu(props: RequisitionMenuProps) {
    const context = useContext(AppContext);
    const [openAuthorizeCfdis, setOpenAuthorizeCfdis] = useState<boolean>(false);

    const handleClickAuthroizeCfdis = () => {
        setOpenAuthorizeCfdis(!openAuthorizeCfdis);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            
            {context.isGrantedAny(["RequisitionsUpdate"]) && 
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickAuthroizeCfdis} dense>
                            <ListItemIcon>
                                <ToAuthorizeIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.to_authorize.actions.authorize")} />
                            {openAuthorizeCfdis ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openAuthorizeCfdis} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onAuthorizeSelected} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onAuthorizeListed} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.menus.actual_list")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
        </Menu>
    );
}