import React, { useState, useContext, ReactNode } from "react";

import { Grid, Button, IconButton, Typography, TableCell, Chip, Tooltip } from "@material-ui/core";
import { red, green, orange, yellow } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "@material-ui/icons/InfoTwoTone";

import { AppContext } from "../context/AppContext";
import { Contribution, ContributionStatusResponse } from "../model/Contribution";
import { approveBankVoucher, refuseBankVoucher } from "../api/SatContributionAPI"
import { approveBankVoucher as approveSocialBankVoucher, refuseBankVoucher as refuseSocialBankVoucher } from "../api/SocialContributionAPI"

import translate from "../i18n/Translator";
import { Column, GridColumnExtension, GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import { Grid as GridDx, Table, TableHeaderRow, TableGroupRow, TableFixedColumns } from "@devexpress/dx-react-grid-material-ui";
import { getSatContributionMonthly } from "../sat_contributions/SatContributionUtil";

import ContributionMenu from "./ContributionMenu";
import Progress from "../components/Progress";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import { SocialContribution } from "../model/SocialContribution";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";

const NoDataCell = (props: Table.NoDataCellProps) => (
    <TableCell colSpan={props.colSpan} align="center">
        {props.getMessage("noData")}
    </TableCell>
);

const SpinnerCell = (props: Table.NoDataCellProps) => (
    <TableCell colSpan={props.colSpan} align="center">
        <Progress />
    </TableCell>
);

const ErrorCell = (error: string) => (props: Table.NoDataCellProps) => (
    <TableCell colSpan={props.colSpan} align="center">
        <Typography variant="caption" color="error">{error}</Typography>
    </TableCell>
);

export const greenDot = (
    <DotIcon fontSize="small" style={{ "color": green[500] }} />
);

export const redDot = (
    <DotIcon fontSize="small" style={{ "color": red[500] }} />
);

export const orangeDot = (
    <DotIcon fontSize="small" style={{ "color": orange[500] }} />
);

export const yellowDot = (
    <DotIcon fontSize="small" style={{ "color": yellow[500] }} />
);

export const notValidated = (reason?: string) => {
    if (reason) {
        return (
            <Tooltip title={translate("contributions.to_validate.label_rejected", { "reason": reason}) as string}>
                <Chip
                    label={translate("contributions.refused")}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    icon={<InfoIcon />} />
            </Tooltip>
        );
    }

    return (
        <Chip
            label={translate("contributions.not_validated")}
            variant="outlined"
            size="small"
            color="secondary" />
    );
};

export function getSocialBankVoucher(statusByDocuments: Record<string, ContributionStatusResponse>): ReactNode {
    let content: ReactNode;
    content = getContributionStatus(statusByDocuments["SOCIAL_VOUCHER"]);

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

export function getSocialSuaSipare(statusByDocuments: Record<string, ContributionStatusResponse>, socialContribution: SocialContribution): ReactNode {
    let content: ReactNode;
    if (!socialContribution || !socialContribution.sua) {
        content = redDot
    } else if (socialContribution.sua.endsWith(".sua")) {
        content = getContributionStatus(statusByDocuments["SUA"]);
    } else {
        content = getContributionStatus(statusByDocuments["SIPARE"]);
    }

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

export function getSocialImss(statusByDocuments: Record<string, ContributionStatusResponse>): ReactNode {
    let content: ReactNode;
    content = getContributionStatus(statusByDocuments["IMSS_CFDI"]);

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

export function getSocialInfonavit(statusByDocuments: Record<string, ContributionStatusResponse>, socialContribution?: SocialContribution, complementary?: boolean): ReactNode {
    let content: ReactNode;
    if(complementary){
        if (socialContribution && socialContribution.infonavit_cfdi_id) {
            content = greenDot;
        } else {
            content = yellowDot;
        }
    } else {
        content = getContributionStatus(statusByDocuments["INFONAVIT_CFDI"]);
    }

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

export function getSatPayment(statusByDocuments: Record<string, ContributionStatusResponse>, complementary?: boolean): ReactNode {
    let content: ReactNode;
    if(complementary){
        content = translate("social_contributions.na");
    } else {
        content = getContributionStatus(statusByDocuments["SAT_PAYMENT"]);
    }

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

function getContributionStatus(statusByDocuments: ContributionStatusResponse | undefined): React.ReactNode {
    if(statusByDocuments){
        switch(statusByDocuments.status){
        case "UNKNOWN": 
            return translate("social_contributions.na");
        case "PENDING": 
            return redDot;
        case "PENDING_OPTIONAL":
            return yellowDot;
        case "REJECTED":
            return notValidated(statusByDocuments?.reason_reject);
        case "TO_VALIDATE":
            return notValidated();
        case "VALID":
            return greenDot;
        }
    }
}

export function getSatComplementaryPayment(statusByDocuments: Record<string, ContributionStatusResponse>, complementary?: boolean): ReactNode {
    let content: ReactNode;
    if(complementary){
        content = translate("social_contributions.na");
    } else {
        content = getContributionStatus(statusByDocuments["SAT_COMPLEMENTARY_PAYMENT"]);
    }

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

export function getSatBankVoucher(statusByDocuments: Record<string, ContributionStatusResponse>, complementary?: boolean): ReactNode {
    let content: ReactNode;
    if(complementary){
        content = translate("social_contributions.na");
    }  else {
        content = getContributionStatus(statusByDocuments["SAT_VOUCHER"]);
    }

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

export function getComplementarySatBankVoucher(statusByDocuments: Record<string, ContributionStatusResponse>, complementary?: boolean): ReactNode {
    let content: ReactNode;
    if(complementary){
        content = translate("social_contributions.na");
    }  else {
        content = getContributionStatus(statusByDocuments["SAT_COMPLEMENTARY_VOUCHER"]);
    }

    return (
        <Typography component="h6" variant="body2" align="center">
            {content}
        </Typography>
    )
}

interface ContributionsTableProps {
    contributions: Contribution[];
    status: string;
    avoidGrouping?: boolean;
    uploadSipare: boolean;
    onUpdated(contributions: Contribution[]): any;

}

type Popup = "refuse_sat" | "refuse_sat_complementary" | "refuse_social";

export default function ContributionsTable(props: ContributionsTableProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";

    const [showProvider] = useState<boolean>(!props.avoidGrouping && context.isGranted("TenantContributionsRead"));
    const [contribution, setContribution] = useState<Contribution>();
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dialog, setDialog] = useState<Popup>();
    const [reason, setReason] = useState("");
    const [valid, setValid] = useState(false);

    const [columns] = useState<Column[]>(() => {
        return [
            {
                name: "month",
                title: translate("contributions.month_employer_number") as string
            },
            {
                name: "provider",
                title: translate("cfdis_se.provider") as string,
                getCellValue: (row: any, columnName: string) => (
                    row.provider?.name || ""
                )
            },
            {
                name: "sat_payment",
                title: translate("sat_contributions.payment_short") as string,
                getCellValue: (row: any, columnName: string) => getSatPayment(row.status_by_documents, row.complementary)
            },
            {
                name: "sua",
                title: translate(props.uploadSipare ? "social_contributions.sipare" : "social_contributions.sua") as string,
                getCellValue: (row: any, columnName: string) => getSocialSuaSipare(row.status_by_documents, row.social_contribution)
            },
            {
                name: "sat_bank_voucher",
                title: translate("sat_contributions.bank_voucher") as string,
                getCellValue: (row: any, columnName: string) => getSatBankVoucher(row.status_by_documents, row.complementary)
            },
            {
                name: "social_bank_voucher",
                title: translate("social_contributions.bank_voucher") as string,
                getCellValue: (row: any, columnName: string) => getSocialBankVoucher(row.status_by_documents)
            },
            {
                name: "imss",
                title: translate("social_contributions.imss") as string,
                getCellValue: (row: any, columnName: string) => getSocialImss(row.status_by_documents)
            },
            {
                name: "infonavit",
                title: translate("social_contributions.infonavit") as string,
                getCellValue: (row: any, columnName: string) => getSocialInfonavit(row.status_by_documents, row.social_contribution, row.complementary)
            },
            {
                name: "complementary_sat_payment",
                title: translate("sat_contributions.complementary_payment") as string,
                getCellValue: (row: any, columnName: string) => getSatComplementaryPayment(row.status_by_documents, row.complementary)
            },
            {
                name: "complementary_sat_bank_voucher",
                title: translate("sat_contributions.complementary_bank_voucher") as string,
                getCellValue: (row: any, columnName: string) => getComplementarySatBankVoucher(row.status_by_documents, row.complementary)
            },
            {
                name: "actions",
                title: " ",
                getCellValue: (row: any, columnName: string) => (
                    <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(row)}>
                        <MoreVertIcon />
                    </IconButton>
                )
            },
        ].filter(el => showProvider || el.name !== "provider");
    });
    const [tableColumnExtensions] = useState<GridColumnExtension[]>(() => {
        return [
            { columnName: "month", width: 300 },
            { columnName: "provider", width: 300 },
            { columnName: "sat_payment", align: "center", width: 150, wordWrapEnabled: true },
            { columnName: "sua", align: "center", width: 150, wordWrapEnabled: true },
            { columnName: "sat_bank_voucher", align: "center", width: 150, wordWrapEnabled: true },
            { columnName: "social_bank_voucher", align: "center", width: 150, wordWrapEnabled: true },
            { columnName: "imss", align: "center", width: 150, wordWrapEnabled: true },
            { columnName: "infonavit", align: "center", width: 150, wordWrapEnabled: true },
            { columnName: "complementary_sat_payment", align: "center", width: 200, wordWrapEnabled: true },
            { columnName: "complementary_sat_bank_voucher", align: "center", width: 200, wordWrapEnabled: true },
            { columnName: "actions", align: "right", width: 50 },
        ].filter(el => showProvider || el.columnName !== "provider") as GridColumnExtension[];
    });
    const [leftColumns] = useState(showProvider ? ["month", "provider"] : ["month"]);
    const [rightColumns] = useState(["actions"]);

    const onClickedOptions = (contribution: Contribution) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setContribution(contribution);
    };

    const onApprove = (complementary: boolean) => {
        setAnchorEl(null);
        if (!contribution) return;

        const satContributionId = contribution.sat_contribution?.id;
        if (!satContributionId) return;

        setSubmitting(true);
        approveBankVoucher(tenantId, satContributionId, complementary).then((result) => {
            if (complementary) {
                setSuccess(translate("sat_contributions.approved_complementary") as string);
            } else {
                setSuccess(translate("sat_contributions.approved") as string);
            }

            contribution!.sat_contribution = result;
            const contributions = props.contributions.map(el => {
                if (el.month === contribution.month && el.year === contribution.year) {
                    return contribution;
                }
                return el;
            });

            props.onUpdated(contributions);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const onApproveSocial = () => {
        setAnchorEl(null);
        if (!contribution) return;

        const socialContributionId = contribution.social_contribution?.id;
        if (!socialContributionId) return;

        setSubmitting(true);
        approveSocialBankVoucher(tenantId, socialContributionId).then((result) => {
            setSuccess(translate("social_contributions.approved") as string);

            contribution!.social_contribution = result;
            const contributions = props.contributions.map(el => {
                if (el.month === contribution.month && el.year === contribution.year) {
                    return contribution;
                }
                return el;
            });

            props.onUpdated(contributions);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const handleRefuse = (variant: Popup) => {
        if (!contribution) return;

        setAnchorEl(null);
        setValid(false);
        setReason("");
        setAnchorEl(null);
        setDialog(variant);
    };

    const onRefuseSocial = () => handleRefuse("refuse_social");

    const onRefuse = (complementary: boolean) => {
        if (complementary) {
            handleRefuse("refuse_sat_complementary");
        } else {
            handleRefuse("refuse_sat");
        }
    }

    const confirm = () => {
        if (!valid) return;
        if (!contribution) return;


        setDialog(undefined);
        if (dialog === "refuse_social") {
            const socialContributionId = contribution.social_contribution?.id || "---";
            confirmRefuseSocial(contribution, socialContributionId);
        } else {
            const satContributionId = contribution.sat_contribution?.id || "---";
            confirmRefuse(contribution, satContributionId, dialog !== "refuse_sat");
        }
    };

    const confirmRefuse = (contribution: Contribution, satContributionId: string, complementary: boolean) => {
        setAnchorEl(null);
        setSubmitting(true);
        refuseBankVoucher(tenantId, satContributionId, {
            reason: reason,
            complementary: complementary
        }).then((result) => {
            if (complementary) {
                setSuccess(translate("sat_contributions.refused_complementary") as string);
            } else {
                setSuccess(translate("sat_contributions.refused") as string);
            }

            contribution.sat_contribution = result;
            const contributions = props.contributions.map(el => {
                if (el.month === contribution.month && el.year === contribution.year) {
                    return contribution;
                }
                return el;
            });

            props.onUpdated(contributions);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const confirmRefuseSocial = (contribution: Contribution, socialContributionId: string) => {
        setAnchorEl(null);
        setSubmitting(true);
        refuseSocialBankVoucher(tenantId, socialContributionId, {
            reason: reason,
            complementary: false
        }).then((result) => {
            setSuccess(translate("social_contributions.refused") as string);

            contribution.social_contribution = result;
            const contributions = props.contributions.map(el => {
                if (el.month === contribution.month && el.year === contribution.year) {
                    return contribution;
                }
                return el;
            });

            props.onUpdated(contributions);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const onChangedReason = (name: string, value: string, inputRef: InputRef) => {
        setReason(value);
        setValid(inputRef.valid);
    }

    const onCloseOption = () => {
        setAnchorEl(null);
        setContribution(undefined);
        setDialog(undefined)
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const MonthTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                {getSatContributionMonthly(value.row.year ?? 0, value.row.month ?? 0)}
                {value.row.social_contribution && 
                <Typography variant="caption" color="textSecondary" component="h6">
                    {value.row.social_contribution.employer_number}
                </Typography>
                }
            </>;
        }} {...props} />
    );

    const customPlugins = [
        <MonthTypeProvider for={['month']} editorComponent={NoFilterEditor} />,
    ];
    
    return (
        <>
            <GridDx
                rows={props.contributions}
                columns={columns}
                >
                {customPlugins.map(plugin => plugin)}
                {showProvider && (
                    <GroupingState grouping={[{ columnName: "provider" }]} />
                )}
                {showProvider && (
                    <IntegratedGrouping />
                )}
                <Table
                    noDataCellComponent={props.status === "loading" ? SpinnerCell : (props.status === "loaded" ? NoDataCell : ErrorCell(props.status))}
                    columnExtensions={tableColumnExtensions}
                    messages={{
                        noData: translate("contributions.empty") as string
                    }} />
                <TableHeaderRow />
                {showProvider && (
                    <TableGroupRow />
                )}
                <TableFixedColumns
                    leftColumns={leftColumns}
                    rightColumns={rightColumns}
                />
            </GridDx>
            {contribution && anchorEl &&
                <ContributionMenu
                    contribution={contribution}
                    anchor={anchorEl}
                    uploadSipare={props.uploadSipare}
                    onApprove={onApprove}
                    onRefuse={onRefuse}
                    onApproveSocial={onApproveSocial}
                    onRefuseSocial={onRefuseSocial}
                    onClose={onCloseOption} />
            }
            {dialog && (
                <DialogPopup open
                    title={translate("contributions.refuse.title")}
                    onClose={onCloseOption}
                    button={(
                        <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                            {translate("buttons.send")}
                        </Button>
                    )}
                    onSubmit={confirm}>
                    <Grid container>
                        <Grid item xs={12}>
                            {translate("contributions.refuse.description")}
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="text"
                                id="reason"
                                name="reason"
                                label={translate("contributions.refuse.reason") as string}
                                onValueChanged={onChangedReason}
                                maxRows={5}
                                rows={5}
                                maxLength={200}
                                required />
                        </Grid>
                    </Grid>
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} />
        </>
    );
}

