import { callAPI, HTTPMethod } from "./API";
import { IntegrationLogs, IntegrationLog, IntegrationLogsQueryParams, IntegrationLogRequest } from "../model/IntegrationLog";

const basePath = (tenantId: string) => `/tenants/${tenantId}/integration-logs`;

export async function listIntegrationLogs(tenantId: string, page: number, offset: number, params: IntegrationLogsQueryParams): Promise<IntegrationLogs> {

    let query = {
        id: params.id ? params.id : "",
            userEmail: params.user_email ? params.user_email : "",
            status: params.status ? params.status : "",
            providerName: params.provider_name ? params.provider_name : "",
            type: params.type ? params.type : "",
            payableDocumentId: params.payable_document_id ? params.payable_document_id : "",
            payableDocumentExternalId: params.payable_document_external_id ? params.payable_document_external_id : "",
            source: params.source ? params.source : "",
            plugin: params.plugin ? params.plugin : "",
            ip: params.ip ? params.ip : "",
            providerId: params.provider_id ? params.provider_id : "",
            createdAt: params.created_at || "",
            completedAt: params.completed_at || "",
            zipUploadId: params.zipUploadId || "",
            pageSize: page.toString(),
            offset: offset.toString()
    } as Record<string, string>;

    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
            query: query,
    });
}

export async function createIntegrationLog(tenantId: string, request: IntegrationLogRequest): Promise<IntegrationLog> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getIntegrationLog(tenantId: string, id: string): Promise<IntegrationLog> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateIntegrationLog(tenantId: string, id: string, request: IntegrationLogRequest): Promise<IntegrationLog> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteIntegrationLog(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function resendToErp(tenantId: string, id: string): Promise<IntegrationLog> {
    return await callAPI(`${basePath(tenantId)}/${id}/resend-to-erp`, {
        method: HTTPMethod.PUT
    });
}