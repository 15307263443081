export interface BankAccount {
    owner: string;
    country: string;
    bank_account_type_id: string;
    bank_account_type_external_id: string;
    bank: string;
    subsidiary: string;
    subsidiary_number: string;
    account: string;
    clabe: string;
    currency: string;
    bank_name: string;
    city: string;
    state: string;
    code: string;
    aba_code: string;
    cie_agreement: string;
    spid_relation_type_id: string;
    spid_relation_type_external_id: string;
    reference: string;
    cover: string;
    foreign: boolean;
}

export interface TenantBankAccount {
    id: string;
    country: string;
    name: string;
    account_number: string;
    currency: string;
    clabe: string;
    bank: BankCatalog;
    bank_name: string;
    in_use: boolean;
    external_id: string;
}

export interface TenantBankAccounts {
    items: TenantBankAccount[];
    total: number;
}

export interface BankAccountsQueryFilter {
    search: string;
    currency: string;
    hasLayout: boolean;
}

export interface BankAccountRequest {
    country: string;
    name: string;
    account_number: string;
    currency: string;
    clabe: string;
    bank_id: string;
    bank_name: string;
    external_id: string;
}

export interface BankCatalog{
    name: string;
    id: string;
    has_layout: boolean;
}

export const fieldsBankAccount = [
    { name: "owner", requiredOn: ["FULL"], visibleOn: ["FULL"] },
    { name: "country", requiredOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "bank_account_type_id", requiredOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "bank", requiredOn: ["FULL", "SPID", "CLABE"], visibleOn: ["FULL", "CIE", "SPID", "CLABE"] },
    { name: "subsidiary", requiredOn: ["FULL"], visibleOn: ["FULL", "CLABE"] },
    { name: "subsidiary_number", requiredOn: ["FULL"], visibleOn: ["FULL", "CLABE"] },
    { name: "account", requiredOn: ["FULL", "CLABE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "SPID", "SWIFT", "ABA"] },
    { name: "clabe", requiredOn: ["FULL", "CLABE", "SPID"], visibleOn: ["FULL", "CLABE", "SPID"] },
    { name: "currency", requiredOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "bank_name", requiredOn: ["N/A"], visibleOn: ["N/A"] },
    { name: "city", requiredOn: ["N/A"], visibleOn: ["N/A"] },
    { name: "state", requiredOn: ["N/A"], visibleOn: ["N/A"] },
    { name: "code", requiredOn: ["SWIFT"], visibleOn: ["SWIFT"] },
    { name: "aba_code", requiredOn: ["ABA"], visibleOn: ["ABA"] },
    { name: "cie_agreement", requiredOn: ["CIE"], visibleOn: ["CIE"] },
    { name: "spid_relation_type_id", requiredOn: ["SPID"], visibleOn: ["SPID"] },
    { name: "reference", requiredOn: ["N/A"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "cover", requiredOn: ["FULL"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] }];

export const fieldsBankAccountForeign = [
    { name: "owner", requiredOn: ["FULL"], visibleOn: ["FULL"] },
    { name: "country", requiredOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "bank_account_type_id", requiredOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "bank", requiredOn: ["N/A"], visibleOn: ["CIE"] },
    { name: "subsidiary", requiredOn: ["N/A"], visibleOn: ["N/A"] },
    { name: "subsidiary_number", requiredOn: ["N/A"], visibleOn: ["N/A"] },
    { name: "account", requiredOn: ["FULL", "CLABE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "SPID", "SWIFT", "ABA"] },
    { name: "clabe", requiredOn: ["CLABE", "SPID"], visibleOn: ["CLABE", "SPID"] },
    { name: "currency", requiredOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "bank_name", requiredOn: ["FULL"], visibleOn: ["FULL"] },
    { name: "city", requiredOn: ["FULL"], visibleOn: ["FULL"] },
    { name: "state", requiredOn: ["FULL"], visibleOn: ["FULL"] },
    { name: "code", requiredOn: ["FULL", "SWIFT"], visibleOn: ["FULL", "SWIFT"] },
    { name: "aba_code", requiredOn: ["ABA"], visibleOn: ["ABA"] },
    { name: "cie_agreement", requiredOn: ["CIE"], visibleOn: ["CIE"] },
    { name: "spid_relation_type_id", requiredOn: ["SPID"], visibleOn: ["SPID"] },
    { name: "reference", requiredOn: ["N/A"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] },
    { name: "cover", requiredOn: ["N/A"], visibleOn: ["FULL", "CLABE", "CIE", "SPID", "SWIFT", "ABA"] }];

