import { callAPI, HTTPMethod } from "./API";
import { Integration, IntegrationRequest } from "../model/Integration";

const basePath = (tenantId: string) => `/tenants/${tenantId}/integrations`;

export async function getIntegration(tenantId: string): Promise<Integration> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.GET
    });
}

export async function getCuratedIntegration(tenantId: string): Promise<Integration> {
    return await callAPI(`${basePath(tenantId)}/curated`, {
        method: HTTPMethod.GET
    });
}

export async function updateIntegration(tenantId: string, request: IntegrationRequest): Promise<Integration> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteIntegration(tenantId: string): Promise<any> {
    return await callAPI(basePath(tenantId), {
        method: HTTPMethod.DELETE
    });
}
