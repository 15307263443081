import React, { useState, useEffect } from "react";
import { Typography, TextField } from "@material-ui/core";

import translate from "../../i18n/Translator";
import ValidatedInput, { InputRef } from "../../components/ValidatedInput";
import { getShippingFileUrl } from "../../api/TenantCfdiApi";
import UploaderInput from "../../components/UploaderInput";
import { ExtendedField } from "../../model/ExtendedField";
import { MultiselectValue } from "../../components/MultiselectDropList";
import { listPurchaseOrders } from "../../api/ProviderPurchaseOrderAPI";
import { listWarehouseDeliveries } from "../../api/ProviderWarehouseDeliveryAPI";
import { PurchaseOrdersQueryParams } from "../../model/PurchaseOrder";
import { WarehouseDeliveriesQueryParams } from "../../model/WarehouseDelivery";
import Progress from "../../components/Progress";
import DatePicker from "../../components/DatePicker";
import moment from "moment";
import DocumentTableItems from "../../purchase_orders/DocumentTableItems";
import PilgrimsDocumentTableItems from "../../purchase_orders/PilgrimsDocumentTableItems";
import { DocumentLine } from "../../model/Document";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CatalogValue } from "../../model/Catalog";
import { Workflow } from "../../model/Workflow";
import { Cfdi } from "../../model/Cfdi";

export interface FieldItemFormProps {
    tenantId: string;
    providerId: string;
    fieldId: string;
    payableDocument?: Cfdi;
    field: ExtendedField;
    value: string;
    status?: string;
    workflow?: Workflow;
    disabled?: boolean;
    errorMessage?: string;
    onValueChanged?(name: string, value: string, inputRef: InputRef): void;
    onValueOnTableChanged?(values: DocumentLine[], valid?: boolean, warehouse_delivery_lines_read_only?: boolean): void;
    onChangedDate(name: string, raw?: string, date?: Date): any;
    onChangedCatalog(valueSelected: string): any;
}

export default function FieldItemForm(props: FieldItemFormProps) {
    const [status, setStatus] = useState("loading");
    const [value, setValue] = useState<string>(props.value);
    const [elements, setElements] = useState<MultiselectValue[]>([]);
    const [valuesCatalog, setValuesCatalog] = useState<CatalogValue[]>([]);
    const [valueCatalog, setValueCatalog] = useState<CatalogValue>();

    const setDefaultValues = (values: MultiselectValue[]) => {
        setElements(values);
        setValue(props.value);
    };

    const retrievePurchaseOrders = () => {
        setStatus("loading");
        const params = {
            status: "O",
            search: ""
        } as PurchaseOrdersQueryParams;

        listPurchaseOrders(props.tenantId, props.providerId, 0, 0, params).then(response => {
            const values = response.items.map((item) => {
                return {
                    title: item.external_id,
                    value: item.external_id
                } as MultiselectValue;
            });

            setDefaultValues(values);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    const retrieveWarehouseDeliveries = () => {
        setStatus("loading");
        const params = {
            status: "O",
            search: ""
        } as WarehouseDeliveriesQueryParams;

        listWarehouseDeliveries(props.tenantId, props.providerId, 0, 0, params).then(response => {
            const values = response.items.map((item) => {
                return {
                    title: item.external_id,
                    value: item.external_id
                } as MultiselectValue;
            });

            setDefaultValues(values);
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    const retriveCatalog = () => {
        setValuesCatalog(props.field.values_catalog.filter(c => (!c.provider_id || c.provider_id === props.providerId) && (c.active || (!c.active && c.id === props.value))));
        props.field.values_catalog.forEach((value) => {
            if ("" + value.id === props.value) {
                setValueCatalog(value);
            }
        });
        setStatus("loaded");
    }

    useEffect(() => {
        if (props.field.type === "FILE") {
            if (!props.value || props.value.startsWith("http")) {
                setValue(props.value);
            } else {
                getShippingFileUrl(props.tenantId, props.fieldId, props.field.id).then((url) => {
                    setValue(url);
                }).catch((error) => {
                });
            }
            setStatus("loaded");
            return;
        } else if (props.field.type === "PURCHASE_ORDERS") {
            retrievePurchaseOrders();
            return;
        } else if (props.field.type === "WAREHOUSE_DELIVERIES") {
            retrieveWarehouseDeliveries();
            return;
        } else if (props.field.type === "CATALOG") {
            retriveCatalog();
            return;
        } else {
            setValue(props.value);
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [props.field.id, props.field.type, props.fieldId, props.tenantId, props.value, props.errorMessage]);

    const onChangedPurchaseOrderTable = (selected: DocumentLine[], valid?: boolean, warehouse_delivery_lines_read_only?: boolean) => {
        if (props.onValueOnTableChanged) {
            props.onValueOnTableChanged(selected, valid, warehouse_delivery_lines_read_only);
        }
    };

    const onChangedCatalogInsite = (valueSelected: string) => {
        props.field.values_catalog.forEach((value) => {
            if ("" + value.id === valueSelected) {
                setValueCatalog(value);
            }
        });
        props.onChangedCatalog(valueSelected);
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (props.field.type === "FILE") {
        return (
            <UploaderInput
                acceptExtension={props.field.extensions !== "all" ? props.field.extensions as string : undefined}
                id={props.field.id}
                name={props.field.id}
                path={`${props.tenantId}/plantilla-envio/${props.fieldId}/${props.field.id}`}
                value={value}
                label={value ? "" : translate("cfdis.template.upload_file") as string}
                required={props.field.required}
                disabled={typeof props.disabled !== "undefined" && props.disabled}
                margin="dense"
                onValueChanged={props.onValueChanged}
            />
        );
    }

    if (props.field.type === "WAREHOUSE_DELIVERIES") {
        return (
            <DocumentTableItems
                title={props.field.name as string}
                type={props.field.type}
                elementos={elements}
                required={props.field.required}
                disabled={typeof props.disabled !== "undefined" && props.disabled}
                onChanged={onChangedPurchaseOrderTable}
                tenantId={props.tenantId}
                providerId={props.providerId}
                cfdiId={props.fieldId}
            />
        );
    }

    if (props.field.type === "PURCHASE_ORDERS") {
        if (props.field.use_custom_purchase_order_component) {
            return (
                <PilgrimsDocumentTableItems
                    title={props.field.name as string}
                    value={props.field.value}
                    lines={props.field.lines}
                    elementos={elements}
                    required={props.field.required}
                    readonly={props.field.read_only || false}
                    disabled={typeof props.disabled !== "undefined" && props.disabled}
                    onChanged={onChangedPurchaseOrderTable}
                    tenantId={props.tenantId}
                    providerId={props.providerId}
                    cfdiId={props.fieldId}
                    payableDocument={props.payableDocument}
                    workflow={props.workflow}
                />
            );
        }

        return (
            <DocumentTableItems
                title={props.field.name as string}
                type={props.field.type}
                elementos={elements}
                required={props.field.required}
                disabled={typeof props.disabled !== "undefined" && props.disabled}
                onChanged={onChangedPurchaseOrderTable}
                tenantId={props.tenantId}
                providerId={props.providerId}
                cfdiId={props.fieldId}
            />
        );
    }

    if (props.field.type === "DATE") {
        return (
            <DatePicker
                label={""}
                name={props.field.id}
                initial={value ? moment(value).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")}
                onChange={props.onChangedDate}
                defaultIfMissing={false}
                clearable={false} autoOK
                disablePast={false}
                disableFuture={false} />
        )
    }

    if (props.field.type === "CATALOG") {
        return (
            <Autocomplete
                size="small"
                options={valuesCatalog}
                value={valueCatalog}
                getOptionLabel={(elemento) => (
                    elemento.name !== "" ? (elemento.name) + (elemento.external_id !== "" ? " - " + elemento.external_id : "") : (elemento.external_id !== "" ? elemento.external_id : "")
                )}
                noOptionsText={translate("catalogs.no_options_autocomplete") as string}
                renderInput={(params) => (
                    <TextField style={{ width: "350px" }} {...params} variant="outlined" label={translate("catalogs.values.title") as string} />
                )}
                onChange={(event, newValue) => {
                    if (newValue) {
                        onChangedCatalogInsite("" + newValue.id);
                    } else {
                        onChangedCatalogInsite("");
                    }
                }}
                getOptionSelected={(opt, val) => opt.id === val.id}
                getOptionDisabled={(option) => option.deleted_in_use}
            />
        )
    }

    return (
        <>
            {props.field.description ? props.field.description as string : ""}
            <ValidatedInput
                type={props.field.type.toLowerCase()}
                id={props.field.id}
                name={props.field.id}
                value={props.value}
                label={""}
                required={props.field.required}
                disabled={typeof props.disabled !== "undefined" && props.disabled}
                margin="dense"
                onValueChanged={props.onValueChanged}
                maxRows={10}
                rows={props.field.type === "TEXTAREA" ? 3 : undefined}
                errorMessage={props.errorMessage}
            />
        </>


    );

}
