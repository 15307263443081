import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { WarningSnackbar } from "../components/Snackbars";
import { AuthorizedAmountRequest, Cfdi } from "../model/Cfdi"
import NumberFormat from "react-number-format";

interface TotalAuthorizePopupProps {
    cfdi: Cfdi;
    onClose(): any;
    doAction(request: AuthorizedAmountRequest): any;
}

export default function TotalAuthorizePopup(props: TotalAuthorizePopupProps) {
    const [total, setTotal] = useState<string>();
    const [comments, setComments] = useState<string>();
    const [warning, setWarning] = useState<string>();

    const onSubmit = () => {            
        if (!total || Number(total) <= 0 || Number(total) > props.cfdi.metadata.balance ) {
            setWarning(translate("cfdis.total_authorize.warning") as string)
            return;
        }
        if(!comments || comments === ""){
            setWarning(translate("cfdis.total_authorize.warning_empty_message") as string)
            return;
        }
        props.doAction({total: total, comment: comments, currency: props.cfdi.currency} as AuthorizedAmountRequest);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setComments(value);
    };

    const hasChangedTotal = (name: string, value: number, inputRef: InputRef) => {
        setTotal(value+"");
    };

    return (
        <DialogPopup open
            title={translate("cfdis.details.total_authorized") as string}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.accept")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant="body2" paragraph>
                                    {translate("cfdis.total_authorize.text_quantity") as string}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="row" justify="flex-end">
                    <FieldEditable editable={true} field={props.cfdi} onValueChanged={hasChangedTotal}/>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        {translate("cfdis.total_authorize.comment") as string}
                    </Typography>
                        <ValidatedInput
                            type="text" id="comment" name="comment"
                            label={translate("cfdis.total_authorize.input_comment") as string}
                            value={""}
                            rows={5}
                            required={false}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}

interface FieldEditableProps {
    editable: boolean;
    field: Cfdi;
    onValueChanged(name: string, value: number, inputRef: InputRef): any;
}

function FieldEditable(props: FieldEditableProps) {
    if (props.editable) {
        return (
            <ValidatedInput
                type="number"
                id={props.field.id}
                name={props.field.id}
                value={props.field.metadata.authorized_amount ? 
                    props.field.metadata.authorized_amount.total_authorized.toString() : 
                    props.field.metadata.balance.toString()}
                isValueNumber={true}
                label={"$  "+ translate("cfdis.details.total_authorized") as string}
                required={false}
                margin="dense"
                onValueChanged={props.onValueChanged}
            />
        );
    }
    return (
        <NumberFormat value={props.field.total} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />
    );
}