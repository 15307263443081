import { callAPI, HTTPMethod } from "./API";
import { Users, UsersQueryParams, UserRequest, User, MetadataResponse, EmailRequest, UserUnlockRequest } from "../model/User";
import { SetupPasswordRequest } from "../model/SignUp";

export async function getUsers(page: number, offset: number, params: UsersQueryParams): Promise<Users> {
    return await callAPI("/users", {
        method: HTTPMethod.GET,
        query: {
            roleId: params.role_id || "",
            search: params.search || "",
            pageSize: page.toString(),
            offset: offset.toString(),
        }
    });
}

export async function getMetadata(): Promise<MetadataResponse> {
    return await callAPI("/users/metadata", {
        method: HTTPMethod.GET
    });
}

export async function createUser(request: UserRequest): Promise<User> {
    return await callAPI("/users", {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getUser(userId: string, includeMetadata?: boolean): Promise<User> {
    return await callAPI(`/users/${userId}`, {
        method: HTTPMethod.GET,
        query: {
            "include_metadata": `${!!includeMetadata}`,
        }
    });
}

export async function updateUser(userId: string, request: UserRequest): Promise<User> {
    return await callAPI(`/users/${userId}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteUser(userId: string): Promise<void> {
    return await callAPI(`/users/${userId}`, {
        method: HTTPMethod.DELETE
    });
}

export async function blockUser(userId: string): Promise<void> {
    return await callAPI(`/users/${userId}/block`, {
        method: HTTPMethod.PUT,
    });
}

export async function unblockUser(userId: string, request: UserUnlockRequest): Promise<void> {
    return await callAPI(`/users/${userId}/block`, {
        method: HTTPMethod.DELETE,
        body: request,
    });
}

export async function getUserByEmail(request: EmailRequest): Promise<User> {
    return await callAPI(`/users/get-user-by-email`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function disable2fa(userId: string): Promise<User> {
    return await callAPI(`/users/${userId}/disable-2fa`, {
        method: HTTPMethod.PUT,
    });
}

export async function updatePassword(userId: string, request: SetupPasswordRequest): Promise<void> {
    return await callAPI(`/users/${userId}/update-password`, {
        method: HTTPMethod.POST,
        body:request
    });
}

export async function getUserSecurity(userId: string): Promise<User> {
    return await callAPI(`/users/${userId}/security`, {
        method: HTTPMethod.GET,
    });
}