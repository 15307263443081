import React, { useState, useEffect } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { assignValidator } from "../api/ProviderAPI";
import {  getUsersExpedientValidators } from "../api/TenantUserAPI";
import { AssignValidatorRequest, AssignValidatorResponse } from "../model/Provider";
import { UsersQueryParamsReceptors } from "../model/User";
import Progress from "../components/Progress";

interface UserValidatorPopupProps {
    tenantId: string;
    providers: string[]
    onClose(): any;
    onSuccess(response: AssignValidatorResponse): any;
}

export default function UserValidatorPopup(props: UserValidatorPopupProps) {
    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [request, setRequest] = useState<AssignValidatorRequest>({} as AssignValidatorRequest);
    const [validations, setValidations] = useState({
        "validator_user_id": {
            valid: false
        } as InputRef,
    } as any);

    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [userIds, setUserIds] = useState<string[]>([]);
    const [userNames, setUserNames] = useState<string[]>([]);

    useEffect(() => {
        setStatus("loading");
        getUsersExpedientValidators(props.tenantId, 0, 0, {
            role_list: ["validator"].join(","),
            search: ""
        } as UsersQueryParamsReceptors).then((response) => {
            setRequest({
                provider_ids: props.providers,
                validator_user_id: ""
            } as AssignValidatorRequest);
            setUserIds(response.items.map((user) => user.id));
            setUserNames(response.items.map((user) => user.name));

            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [props.tenantId, props.providers]);

    const onSubmit = () => {
        if (request.validator_user_id === "" || request.validator_user_id === "---") {
            setWarning(translate("providers.assign_validator.warning") as string);
            return;
        }

        setSubmitting(true);
        assignValidator(props.tenantId, request).then((response) => {
            props.onSuccess(response);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    return (
        <DialogPopup open
            title={translate("providers.assign_validator.title")}
            button={userIds.length > 0 ? (
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={submitting}>
                    {translate("buttons.save")}
                </Button>
            ) : undefined}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}
            disableBackdropClick={submitting}
            disableEscapeKeyDown={submitting}>
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && userIds.length > 0 && (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2" paragraph>
                            {translate(props.providers.length > 1 ? "providers.assign_validator.description" : "providers.assign_validator.description_single", { "count": props.providers.length })}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="validator_user_id" name="validator_user_id"
                            label={translate("providers.assign_validator.select") as string}
                            value={request.validator_user_id}
                            required disabled={submitting}
                            margin={"dense"}
                            onValueChanged={hasChanged}
                            options={userIds} optionLabels={userNames} />
                    </Grid>
                </Grid>
            )}
            {status === "loaded" && userIds.length === 0 && (
                <Typography variant="body2">
                    {translate("providers.assign_validator.empty")}
                </Typography>
            )}
            {status !== "loading" && status !== "loaded" && (
                <Typography variant="body2" component="h5" color="error" align="center">
                    {status}
                </Typography>
            )}

            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );

}

