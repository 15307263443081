import { callAPI, HTTPMethod } from "./API";
import { ClarificationListParams, Clarifications, Clarification, ClarificationRequest, ClarificationMessageRequest, ClarificationUpdateStatusRequest } from "../model/Clarification";

export async function list(tenantId: string, pageSize: number, offset: number, params: ClarificationListParams): Promise<Clarifications> {
    let query = {
        number: params.number || "",
        subject: params.subject || "",
        sender: params.sender || "",
        provider: params.provider || "",
        status: params.status || "",
        userId: params.user_id || "",
        pageSize: pageSize.toString(),
        offset: (offset).toString(),
    } as Record<string, string>;

    if(params.init_date && params.end_date){
        query = {...query,
            initDate: params.init_date+"",
            endDate: params.end_date+""
        };
    }

    if(params.provider_id){
        query = {...query,
        providerId: params.provider_id}
    }
    
    return await callAPI(`/tenants/${tenantId}/clarifications`, {
        method: HTTPMethod.GET,
        query: query,
    });
}

export async function createClarification(tenantId: string, request: ClarificationRequest): Promise<Clarification> {
    return await callAPI(`/tenants/${tenantId}/clarifications`, {
        method: HTTPMethod.POST,
        body: request,
    });
}

export async function getClarification(tenantId: string, clarificationId: string): Promise<Clarification> {
    return await callAPI(`/tenants/${tenantId}/clarifications/${clarificationId}`, {
        method: HTTPMethod.GET,
    });
}

export async function postMessage(tenantId: string, clarificationId: string, request: ClarificationMessageRequest): Promise<Clarification> {
    return await callAPI(`/tenants/${tenantId}/clarifications/${clarificationId}/message`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}

export async function updateStatus(tenantId: string, clarificationId: string, request: ClarificationUpdateStatusRequest): Promise<Clarification> {
    return await callAPI(`/tenants/${tenantId}/clarifications/${clarificationId}/update-status`, {
        method: HTTPMethod.PUT,
        body: request,
    });
}