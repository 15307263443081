import React, { useState, useEffect } from "react";
import { Grid, Box, Checkbox } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import { ReceptionRuleColumnTitle, ReceptionRuleColumnValue } from "./ReceptionRuleChecks"
import { PAYMENT_FORM_PUE, PAYMENT_FORMS_PPD } from "../catalogs/CatCfdi";
import Progress from "../components/Progress";

interface ReceptionRulesPaymentsTabProps {
    selectedMethods: Map<string, string[]>;
    update(payments: Map<string, string[]>): any;
}

export default function ReceptionRulesPaymentsTab(props: ReceptionRulesPaymentsTabProps) {
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [selectPpd, setSelectPpd] = useState<boolean>(false);
    const [selectPue, setSelectPue] = useState<boolean>(false);
    const [selectedPpd, setSelectedPpd] = useState<string[]>([]);
    const [selectedPue, setSelectedPue] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("loading");

    const load = () => {
        let paymentPue = (props.selectedMethods as any)["PUE"] as string[];
        let paymentPpd = (props.selectedMethods as any)["PPD"] as string[];
        setSelectPue(paymentPue ? true : false);
        setSelectPpd(paymentPpd ? true : false);
        setSelectedPue(paymentPue || []);
        setSelectedPpd(paymentPpd || []);
        setStatus("loaded");
    };

    useEffect(load, [props.selectedMethods]);

    const add = (pueSelected?: string[]) => {
        let request = props.selectedMethods as any;
        if (pueSelected) {
                request["PUE"] = pueSelected;            
        } else {
            delete request["PUE"];
        }
        props.update(request);
    };

    const addPpd = (pdpSelected?: string[]) => {
        let request = props.selectedMethods as any;
        if (pdpSelected && !selectPpd) {
            request["PPD"] = pdpSelected;
        } else {
            delete request["PPD"];
        }
        props.update(request);
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const handleCheckPpd = (code: string) => {
        let items = selectedPpd.length === 0 ? ([] as string[]) : selectedPpd.join(",").split(",");
        const index = selectedPpd.indexOf(code);
        if (index < 0 && !selectPpd) {
            items.push(code);
        } else {
            items.splice(index, 1);
        }
        setSelectedPpd(items);
        addPpd(items);
    };

    const handleCheckPpdTitle = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        setSelectPpd(checked);
        if (!checked) {
            setSelectedPpd([]);
            handleCheckPpd("F99");
        } else {
            setSelectedPpd(["F99"]);
            handleCheckPpd("F99");
        }
        
    };

    const handleCheckPueTitle = (_event: React.ChangeEvent<{}>, checked: boolean) => {
        setSelectPue(checked);
        if (!checked) {
            setSelectedPue([]);
            add(undefined);
        } else {
            add([]);
        }
    };

    const handleAllChecksPpd = (checked: boolean) => {
        var items;
        if (checked) {
            items = PAYMENT_FORM_PUE.map(code => code);
        } else {
            items = [] as string[];
        }
        setSelectedPpd(items);
        addPpd(items);
    };

    const handleCheckPue = (code: string) => {
        let items = selectedPue.length === 0 ? ([] as string[]) : selectedPue.join(",").split(",");
        const index = selectedPue.indexOf(code);
        if (index < 0) {
            items.push(code);
        } else {
            items.splice(index, 1);
        }
        setSelectedPue(items);
        add(items);
    };

    const handleAllChecksPue = (checked: boolean) => {
        var items;
        if (checked) {
            items = PAYMENT_FORM_PUE.map(code => code);
        } else {
            items = [] as string[];
        }
        setSelectedPue(items);
        add(items);
    };

    if (status === "loading") {
        return <Progress />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={1}>
                    <Checkbox
                        id="ppd"
                        name="ppd"
                        value={""}
                        checked={selectPpd}
                        onChange={handleCheckPpdTitle}
                        className="GridableCheck"
                    />
                    {translate(`reception_rule.payments_method.ppd`)}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={PAYMENT_FORMS_PPD}
                    loading={false}
                    empty={translate(`reception_rule.payments_method.empty`) as string}
                    columns={[
                        {
                            title: <strong>{translate("reception_rule.key")}</strong>,
                            converter: (code) => (
                                <ReceptionRuleColumnValue
                                    code={code}
                                    disabled={true}
                                    checked={selectedPpd.indexOf(code) >= 0}
                                    callbacks={{
                                        handleCheck: handleCheckPpd,
                                        handleAllChecks: handleAllChecksPpd
                                    }}
                                    showChecks={true}
                                    getValue={(code) => translate(`reception_rule.payment_form_ppd_code.${code}`) as string} />
                            ),
                            fullWidth: true,
                            xs: 5,
                            sm: 2,
                            md: 3,
                            lg: 2,
                            xl: 2
                        },
                        {
                            title: translate("reception_rule.payments_method.payment_way_title") as string,
                            converter: (code) => translate(`reception_rule.payment_form_ppd.${code}`),
                            xs: true
                        }
                    ]}
                />

            </Grid>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={1}>
                    <Grid container>
                        <Grid item>
                            <Checkbox
                                id="pue"
                                name="pue"
                                value={""}
                                checked={selectPue}
                                onChange={handleCheckPueTitle}
                                className="GridableCheck"
                            />
                            {translate(`reception_rule.payments_method.pue`)}
                        </Grid>
                        <Grid item>


                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={PAYMENT_FORM_PUE}
                    loading={false}
                    empty={translate(`reception_rule.payments_method.empty`) as string}
                    columns={[
                        {
                            title: (
                                <ReceptionRuleColumnTitle
                                    disabled={!selectPue}
                                    total={PAYMENT_FORM_PUE.length}
                                    selected={selectedPue.length}
                                    callbacks={{
                                        handleCheck: handleCheckPue,
                                        handleAllChecks: handleAllChecksPue
                                    }}
                                    showChecks={true} />
                            ),
                            converter: (code) => (
                                <ReceptionRuleColumnValue
                                    code={code}
                                    disabled={!selectPue}
                                    checked={selectedPue.indexOf(code) >= 0}
                                    callbacks={{
                                        handleCheck: handleCheckPue,
                                        handleAllChecks: handleAllChecksPue
                                    }}
                                    showChecks={true}
                                    getValue={(code) => translate(`reception_rule.payment_form_code.${code}`) as string} />
                            ),
                            fullWidth: true,
                            xs: 5,
                            sm: 2,
                            md: 3,
                            lg: 2,
                            xl: 2
                        },
                        {
                            title: translate("reception_rule.payments_method.payment_way_title") as string,
                            converter: (code) => translate(`reception_rule.payment_form.${code}`),
                            xs: true
                        }
                    ]}
                />
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Grid>
    );
}
