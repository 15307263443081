import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import DialogPopup from "../components/DialogPopup";
import { TermCondition } from "../model/TermsConditions";
import translate from "../i18n/Translator";
import { Checkbox, Divider, Grid, Button, Typography } from "@material-ui/core";
import Editor from '../components/Editor';
import { acceptTermsConditions } from "../api/ProviderAPI";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar } from "../components/Snackbars";
import NotificationsIcon from '@material-ui/icons/Policy';

interface ShowTermsConditionModalProps {
    termnCondition: TermCondition;
    onClose(success?: string): any;
    onFinish(): any;
}

export default function ShowTermsConditionModal (props: ShowTermsConditionModalProps){
    const context = useContext(AppContext);
    const [readed, setReaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const onClose = () => {
        if(readed){
            setLoading(true);
            acceptTermsConditions(context.session!.tenant!.id, context.session!.provider!.id).then(() => {
                let sessionTemp = context.session;
                if(sessionTemp){
                    sessionTemp.terms_conditions_unaccepted.active = false;
                    context.setSession(sessionTemp);
                }
                props.onClose(translate("tenant_configurations.terms_conditions.accepted") as string);
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            props.onFinish();
        }

    };

    return (
        <DialogPopup title={<Grid container><Grid item xs={1} ><NotificationsIcon /></Grid><Grid item >{props.termnCondition.title}</Grid></Grid>} open maxWidth="lg" onClose={props.onFinish}
            button={
                <Button onClick={onClose} variant="contained" color="primary" size="medium" disabled={!readed}>
                    {translate("tenant_configurations.terms_conditions.send")}
                </Button>}
            footerItem={
                <Grid item xs={12}>
                    <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                        <Grid style={{marginLeft:"15px"}} item xs="auto">
                            <Checkbox
                                id="ad-readed"
                                name="ad-readed"
                                checked={readed}
                                onChange={() => setReaded(!readed)}
                                disabled={false}
                                className="GridableCheck"
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1" component="h2">
                                {props.termnCondition.accepted_message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>} >
            <Editor readOnly={true} value={props.termnCondition.message} />
            <CustomBackdrop open={loading} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>);
}
