import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import Surface from "../components/Surface";
import translate from "../i18n/Translator";
import { Box, Button, IconButton, Card, CardContent, Divider, Grid, ListItemText, Tab, Tabs, Typography } from "@material-ui/core";
import { DownloadIcon, VerifiedUserIcon } from "../components/Icons";
import { TaxStampMetadata } from "../model/StampTaxMetadata";
import { getStampTaxCert } from "../api/TaxStampMetadataApi";
import { validateGenerateInMembership } from "../api/ProviderMembershipTaxStampApi";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import DateFormat from "../components/DateFormat";
import { PrintCertificateData } from "./ConfigureCertificate";
import ProviderMembershipPaymentCfdisArchiveView from "./ProviderMembershipPaymentCfdisArchiveTab";
import queryString from "query-string";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import { exportExcelPaymentCfdis, getProviderMembershipTaxStamp } from "../api/ProviderMembershipTaxStampApi";
import { ProviderMembershipTaxStamp } from "../model/ProviderMembershipTaxStamp";
import { cancelCfdi } from "../api/TenantCfdiApi";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaymentCfdiMenu from "./PaymentCfdiMenu";
import { listCfdiStatus } from "../api/TenantCfdiApi";
import { MultiselectValue } from "../components/MultiselectDropList";

import NumberFormat from "react-number-format";
import Gridable from "../components/Gridable";
import { Cfdi } from "../model/Cfdi";
import Progress from "../components/Progress";
import { Redirect } from "react-router-dom";

interface StatusTab {
    name: string;
    title: string;
}

export default function ProviderMembershipStatusView(){
    const context = useContext(AppContext);
    const [taxStampMetadata, setTaxStampMetadata] = useState<TaxStampMetadata>();
    const [providerMembershipTaxStamp, setProviderMembershipTaxStamp] = useState<ProviderMembershipTaxStamp>({} as ProviderMembershipTaxStamp);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const qs = queryString.parse(window.location.search);
    const [status, setStatus] = useState<string>("loading");
    const [elementsStatus, setElementsStatus] = useState<MultiselectValue[]>([]);

    const load = () => {
        setStatus("loading");
        Promise.all([
            getStampTaxCert(context.session?.provider?.rfc || ""),
            getProviderMembershipTaxStamp(context.session?.provider?.membership_tax_stamp_id || ""),
            listCfdiStatus(context.session!.tenant!.id, "PAYMENT_CFDI")
        ]).then(responses => {
            setTaxStampMetadata(responses[0]);
            setProviderMembershipTaxStamp(responses[1]);
            setElementsStatus(responses[2].items);
        }).catch(error => {
            console.log(error.message);
        }).finally(() => setStatus("loaded"));
    };

    useEffect(load, [context]);

    const allTabs = [
        {
            name: "status_membership",
            title: translate("status_membership.title" as string)
        },
        {
            name: "certificate",
            title: translate("tax_stamp.cert" as string)
        },
        {
            name: "archive",
            title: translate("tax_stamp.archive.title" as string)
        }
    ] as StatusTab[];

    const tabToIndex = (tab: string): number => {
        let index = allTabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [value, setValue] = useState<number>(tabQs);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = allTabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        setValue(newValue);
    };

    const tabContent = (index: number) => {
        if (!context.session || !context.session.tenant) return;

        let current = allTabs[index];
        if (!current) return;

        switch (current.name) {
            case "status_membership":
                return <StatusMembershipData
                    tenantId={context.session!.tenant!.id} 
                    setTaxStampMetadata={setTaxStampMetadata}
                    submitting={submitting}
                    elementsStatus={elementsStatus}
                    load={load}
                    membershipTaxStamp={providerMembershipTaxStamp}
                />;
            case "certificate":
                return <PrintCertificateData
                    setTaxStampMetadata={setTaxStampMetadata}
                    submitting={submitting}
                    taxStampMetadata={taxStampMetadata}
                />;
            case "archive":
                return <ProviderMembershipPaymentCfdisArchiveView
                    providerRfc={context.session!.provider!.rfc}
                    providerId={context.session!.provider!.id}
                    elementsStatus={elementsStatus}
                />;
            default:
                return <div></div>;
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    return (<Surface 
            title={translate("tax_stamp.title") as string} 
            icon={<VerifiedUserIcon/>}
            className="PaperPagination">
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {allTabs.map((tab: StatusTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {allTabs.map((tab: StatusTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
        </Surface>);
}

export interface StatusMembershipDataProps{
    tenantId: string;
    membershipTaxStamp: ProviderMembershipTaxStamp;
    submitting: boolean;
    setTaxStampMetadata(response: TaxStampMetadata) : any;
    elementsStatus: MultiselectValue[];
    load(): any;
}

export function StatusMembershipData(props: StatusMembershipDataProps) {
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [redirect, setRedirect] = useState<string>();
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [openConfirmCancelSat, setOpenConfirmCancelSat] = useState<boolean>(false);
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);


    const onDownloadXslx = () => {
        exportExcelPaymentCfdis(props.membershipTaxStamp.id).then(response => {
            if (response.url) {
                window.open(response.url, "_blank");
            }
        }).catch(error => {
            setError(error.message);
        }).finally(() => {});
    };

    const onClickRow = (cfdi: Cfdi) => {
        setRedirect(`/cfdis/${cfdi.id}/details?referer=tax_stamp`);
    };

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onConfirmCancel = () => {
        if (!cfdi) return;

        Promise.all([
            validateGenerateInMembership(cfdi!.id),
        ]).then(response => {
            let isGeneratedInMembership = response[0];
            if (cfdi.metadata.type === "PAYMENT_CFDI" && isGeneratedInMembership.generated_by_membership) {
                setOpenConfirmCancelSat(true);
            } else {
                onOpenCancelCfdi();
            }
        }).catch(error => {
            setError(error.message);
        });
    }

    const onCancelCfdi = () => {
        setOpenConfirmCancelSat(false);
        setOpenCancelConfirmDialog(false);
        if (!cfdi) return;
        setOpenBackdrop(true);
        cancelCfdi(props.tenantId, cfdi.id).then((response) => {
            setSuccess(translate("status_membership.cancel_sat_success") as string);
            props.load();
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const getStatusDescription = (cfdi: Cfdi) => {
        let status = props.elementsStatus.find(item => item.value === cfdi.metadata.status);
        return status?.title;
    }

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Box px={2} py={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Card variant="elevation">
                                <CardContent>
                                    <Box px={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <ListItemText secondary={translate("status_membership.plan", {"status": translate(`provider_membership_tax_stamp.status.${props.membershipTaxStamp.status}`)})} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography><strong>{props.membershipTaxStamp.plan.name}</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container >
                                                    <Grid item >
                                                        <Box pr={1}><Typography color="primary"><strong>{props.membershipTaxStamp.stamps_generated}</strong></Typography></Box>
                                                    </Grid>
                                                    <Grid item >
                                                        <Typography>{translate("status_membership.stamp_numbers", {"total": props.membershipTaxStamp.stamps_number})}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <ListItemText
                                                secondary={translate("status_membership.due_date", {"date": <DateFormat date={props.membershipTaxStamp.expiration_date} format="L" />})} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText
                                        primary={<DateFormat date={props.membershipTaxStamp.activation_date} format="L" />}
                                        secondary={translate("status_membership.activation_date")} />
                                </Grid>
                                <Grid item>
                                    <ListItemText
                                        primary={<NumberFormat value={props.membershipTaxStamp.plan.price}
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />}
                                        secondary={translate("status_membership.price")} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <ListItemText
                                primary={translate("status_membership.months_expiration", {"months": props.membershipTaxStamp.plan.expiration_months}) as string}
                                secondary={translate("status_membership.expiration")} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography><strong>{translate("status_membership.payment_cfdis")}</strong></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="text" color="primary" endIcon={<DownloadIcon/>} onClick={onDownloadXslx} disabled={!props.membershipTaxStamp.payment_cfdis}>
                                        {translate("status_membership.xls_button")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Gridable
                    items={props.membershipTaxStamp.payment_cfdis ? props.membershipTaxStamp.payment_cfdis : []}
                    loading={false}
                    empty={translate("status_membership.empty_cfdis") as string}
                    onClick={onClickRow}
                    columns={[
                        {
                            title: translate("status_membership.date") as string,
                            converter: (cfdi) => (<DateFormat date={cfdi.cfdi!.fecha_emision} format="DD/MM/YYYY HH:mm:ss"/>),
                            xs: true
                        },
                        {
                            title: translate("status_membership.payment_number") as string,
                            converter: (cfdi) => cfdi.metadata.payment_info?.payments_response[0].operation_number,
                            xs: true
                        },
                        {
                            title: translate("status_membership.serie_folio") as string,
                            converter: (cfdi) => cfdi.identifier,
                            xs: true
                        },
                        {
                            title: translate("status_membership.receptor") as string,
                            converter: (cfdi) => cfdi.cfdi?.receptor.nombre,
                            xs: true
                        },
                        {
                            title: translate("status_membership.uuid") as string,
                            converter: (cfdi) => cfdi.cfdi?.timbre.uuid,
                            xs: true
                        },
                        {
                            title: translate("status_membership.amount") as string,
                            converter: (cfdi) => (<NumberFormat value={cfdi.cfdi?.complementos.pagos20.pagos[0].monto}
                                                        prefix="$ " decimalScale={2} fixedDecimalScale={true}
                                                        thousandSeparator="," displayType="text"
                                                    />),
                            justify: "flex-end",
                            xs: true
                        },
                        {
                            title: translate("currency.title") as string,
                            converter: (cfdi) => cfdi.cfdi?.complementos.pagos20.pagos[0].moneda,
                            justify: "flex-end",
                            xs: true
                        },
                        {
                            title: translate("status_membership.status") as string,
                            converter: (cfdi) => getStatusDescription(cfdi),
                            xs: true
                        },
                        {
                            title: "",
                            converter: (cfdi) => (
                                    <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(cfdi)}>
                                        <MoreVertIcon />
                                    </IconButton>
                            ),
                            fullWidth: true,
                            justify: "flex-end",
                            xs: "auto",
                            md: "auto",
                            lg: "auto",
                            xl: "auto",
                        }
                    ]} />
                    
                </Grid>
            </Grid>
            {cfdi && openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.message") as string}
                    secondary={translate("cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {openConfirmCancelSat && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenConfirmCancelSat(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.confirm_cancel_sat") as string}
                    button={translate("buttons.accept") as string} />
            )}
            { anchorEl && cfdi &&
              <PaymentCfdiMenu
                cfdi={cfdi}
                onClose={onCloseOption}
                onCancel={onConfirmCancel}
                anchor={anchorEl}
              />
            }
            <SuccessSnackbar message={success} onClose={() => setSuccess(undefined)} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
            <CustomBackdrop open={props.submitting} message={translate("cfdis.processing") as string} />
        </Box>
    );
}