import React, { useState, useEffect } from "react";
import { Grid, Box, FormLabel, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import { ReceptionRuleColumnTitle, ReceptionRuleColumnValue } from "../reception_rules/ReceptionRuleChecks";
import Progress from "../components/Progress";
import { ReceptionRuleUsesRequest } from "../model/ReceptionRule";
import { CFDI_USES } from "../catalogs/CatCfdi";

interface CfdiUsesTabProps {
    selected: string[];
    acceptAll: boolean;
    updateRadio(accept : string, isUses : boolean): any;
    update(selected: ReceptionRuleUsesRequest, accept : string): any;
}

export default function CfdiUsesTab(props: CfdiUsesTabProps) {
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [selected, setSelected] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("loading");
    const [value, setValue] = React.useState('all');

    const load = () => {
        setStatus("loading");
        setSelected(props.selected);
        setValue(props.acceptAll ? "all":"only");
        setStatus("loaded");
    };

    useEffect(load, [props.selected, value]);

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    const handleCheck = (code: string) => {
        let items = selected.length === 0 ? ([] as string[]) : selected.join(",").split(",");
        const index = selected.indexOf(code);
        if (index < 0) {
            items.push(code);
        } else {
            items.splice(index, 1);
        }
        setSelected(items);
        props.update({ uses: items } as ReceptionRuleUsesRequest, value);
    };

    const handleAllChecks = (checked: boolean) => {
        var items;
        if (checked) {
            items = CFDI_USES.map(code => code);
        } else {
            items = [] as string[];
        }
        setSelected(items);
        props.update({ uses: items } as ReceptionRuleUsesRequest, value);
    };

    if (status === "loading") {
        return <Progress />;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        props.updateRadio((event.target as HTMLInputElement).value, true);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={4} mb={1} px={2}>
                    <FormControl component="fieldset">
                    <FormLabel component="legend">{translate(`specific_reception_rule.uses_cfdi.title`)}</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                        <FormControlLabel value="all" control={<Radio />} label={translate(`specific_reception_rule.uses_cfdi.accept_all`)} />
                        <FormControlLabel value="only" control={<Radio />} label={translate(`specific_reception_rule.uses_cfdi.only_selected`)} />
                    </RadioGroup>
                </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
            <div style={value === "all" ? { "pointerEvents": "none", "opacity": "0.4", "overflow": "hidden" } : {}}>
                <Gridable
                    items={CFDI_USES}
                    loading={false}
                    empty={translate(`reception_rule.use_cfdi.empty`) as string}
                    columns={[
                        {
                            title: (
                                <ReceptionRuleColumnTitle
                                    disabled={false}
                                    total={CFDI_USES.length}
                                    selected={selected.length}
                                    callbacks={{
                                        handleCheck: handleCheck,
                                        handleAllChecks: handleAllChecks
                                    }}
                                    showChecks={true} />
                            ),
                            converter: (code) => (
                                <ReceptionRuleColumnValue
                                    disabled={false}
                                    code={code}
                                    checked={selected.indexOf(code) >= 0}
                                    callbacks={{
                                        handleCheck: handleCheck,
                                        handleAllChecks: handleAllChecks
                                    }}
                                    showChecks={true}
                                    getValue={(code) => code } />
                            ),
                            fullWidth: true,
                            xs: 5,
                            sm: 2,
                            md: 3,
                            lg: 2,
                            xl: 2
                        },
                        {
                            title: translate("reception_rule.description") as string,
                            converter: (code) => translate(`reception_rule.cfdi_uses.${code}`),
                            xs: true
                        }
                    ]}
                />
            </div>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Grid>
    );
}
