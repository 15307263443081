import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import DialogPopup from "../components/DialogPopup";
import { Announcement } from "../model/Announcement";
import translate from "../i18n/Translator";
import { Checkbox, Typography, Grid, Button } from "@material-ui/core";
import Editor from '../components/Editor';
import { announcementReaded } from "../api/AnnouncementAPI";
import CustomBackdrop from "../components/CustomBackdrop";
import { ErrorSnackbar } from "../components/Snackbars";
import NotificationsIcon from '@material-ui/icons/Notifications';

interface ShowAnnouncementModalProps {
    announcement: Announcement;
    isProvider?: boolean;
    onClose(success?: string): any;
    onFinish?(): any;
}

export default function ShowAnnouncementModal(props: ShowAnnouncementModalProps) {
    const context = useContext(AppContext);
    const [readed, setReaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const onClose = () => {
        if (readed) {
            setLoading(true);
            announcementReaded(context.session!.tenant!.id, props.announcement.id, context.session!.provider!.id, context.session!.user.id).then((response) => {
                onReadedSession(response);
                props.onClose(translate("providers.announcements.show_announcement.readed") as string);
            }).catch((error) => {
                setLoading(false);
                setError(error.message);
            });
        } else {
            onReadedSession(props.announcement);
            props.onClose();
        }

    };

    const onReadedSession = (item: Announcement) => {
        let sessionTemp = context.session;
        if (sessionTemp) {
            sessionTemp?.announcements?.forEach(ann => {
                if (ann.id === item.id) {
                    ann.readed = true;
                }
            });
            context.setSession(sessionTemp);
        }
        setLoading(false);
    }

    return (
        <DialogPopup title={<Grid container><Grid item xs={1} ><NotificationsIcon /></Grid><Grid item >{translate("providers.announcements.show_announcement.title")}</Grid></Grid>} open maxWidth="md" onClose={onClose}
            button={!props.isProvider && props.announcement.status === "ACTIVE" && context.isGrantedAny(["AnnouncementAdminUpdate"]) ?
                <Button onClick={props.onFinish} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("providers.announcements.menu.end_announcement")}
                </Button>
                : undefined}
            buttonLeft={props.isProvider && !props.announcement.readed ?
                <Grid item xs={12}>
                    <Grid container justify="flex-start" alignItems="center" alignContent="center" spacing={1}>
                        <Grid item xs="auto" >
                            <Checkbox
                                id="ad-readed"
                                name="ad-readed"
                                checked={readed}
                                onChange={() => setReaded(!readed)}
                                disabled={false}
                                className="GridableCheck"
                            />
                        </Grid>
                        <Grid item xs>
                            {translate("providers.announcements.show_announcement.check")}
                        </Grid>
                    </Grid>
                </Grid>
                : undefined} >
            <Typography>{props.announcement.title}</Typography>
            <Editor readOnly={true} value={props.announcement.body} />
            <CustomBackdrop open={loading} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>);
}
