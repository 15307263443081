import React, { useState } from "react";
import translate from "../i18n/Translator";
import DateFormat from "../components/DateFormat";
import { useHistory, Redirect } from 'react-router-dom';
import GridDx from "../components/GridDx";
import { NoFilterEditor } from "../components/GridDx";
import { DataTypeProviderProps, DataTypeProvider } from "@devexpress/dx-react-grid";
import { Cfdi, CfdiAdvancePayment, inAuthorized, inProcessToSend } from '../model/Cfdi';
import { IconButton, Typography, Tooltip } from '@material-ui/core';
import queryString from "query-string";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import { Session } from "../model/Session";

export interface CfdiAdvancePaymentsProps {
    cfdiId: string;
    advancePayments?: CfdiAdvancePayment[];
    onDesassociate(advancePaymentId: string): any;
    session: Session;
    cfdi: Cfdi;
}

export default function CfdiAdvancePaymentsTab(params: CfdiAdvancePaymentsProps) {
    const history = useHistory();
    const [redirect, setRedirect] = useState<string>();
    const [data] = useState<CfdiAdvancePayment[]>(params.advancePayments ?? []);

    const allowDesasociateItems = () => {
        if ("ARCHIVED" === params.cfdi.metadata.cfdi_status_group) return false;

        if (params?.session?.role?.provider && inProcessToSend(params.cfdi.metadata.cfdi_status_group)) {
            return true;
        } else if (inAuthorized(params.cfdi.metadata.cfdi_status_group)) {
            return true;
        }
        return false;
    }

    const [allowDesasociate] = useState<boolean>(allowDesasociateItems());

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const onClickedRow = (advancePayment: CfdiAdvancePayment) => {
        setRedirect(onClickedLink(advancePayment));
    };

    const onClickedLink = (advancePayment: CfdiAdvancePayment) => {
        let qs = queryString.parse(window.location.search);
        return `/cfdis/${advancePayment.id}/details?${!!qs ? queryString.stringify(qs) : ""}&from-advance-payment=true&source-id=${params.cfdiId}`;
    }

    const getPaymentDate = (payment: CfdiAdvancePayment) => {
        return (<DateFormat key={payment.id} date={new Date(payment.date)} format="DD/MM/YYYY" />);
    }

    const getLinkDescription = (payment: CfdiAdvancePayment) => {
        return (
            <Tooltip title={payment.description} placement="top" onClick={() => {
                onClickedRow(payment);
            }}>
                <Typography variant="body2" style={{ textDecoration: "underline", color: "cornflowerblue" }} >{payment.description}</Typography>
            </Tooltip>
        );
    }

    const [columnsFormat] = useState([
        { columnName: 'identifier', width: 120 },
        { columnName: 'description', align: 'left', width: 300 },
        { columnName: 'date', width: 150 },
        { columnName: 'total', align: 'right' },
        { columnName: 'currency', },
        { columnName: 'payment_amount', align: 'right' },
        { columnName: 'payment_currency', },
        { columnName: 'delete_button', width: 50 }
    ]);

    const columns = [
        {
            name: 'identifier',
            title: translate("add_advance_payments.columns.identifier") as string,
            getCellValue: (row: any) => row.identifier
        },
        {
            name: 'date',
            title: translate("add_advance_payments.columns.date") as string,
            getCellValue: (row: any) => getPaymentDate(row)

        },
        {
            name: 'description',
            title: translate("add_advance_payments.columns.description") as string,
            getCellValue: (row: any) => getLinkDescription(row)
        },
        {
            name: 'total',
            title: translate("add_advance_payments.columns.amount") as string,
            getCellValue: (row: any) => row.total

        },
        {
            name: 'currency',
            title: translate("add_advance_payments.columns.currency") as string,
            getCellValue: (row: any) => row.currency
        },
        {
            name: 'payment_amount',
            title: translate("add_advance_payments.columns.paid") as string,
            getCellValue: (row: any) => row.payment_amount
        }, {
            name: 'payment_currency',
            title: translate("add_advance_payments.columns.payment_currency") as string,
            getCellValue: (row: any) => row.payment_currency
        },
        {
            name: 'delete_button',
            title: " "
        }
    ];

    const currencyColumns = ['total', 'payment_amount'];

    const clickRowColumns = ['identifier', 'description'];

    const textColumns = ['identifier', 'description', 'currency', 'payment_currency'];

    const filtersExclude = ['delete_button', 'identifier', 'description', 'date', 'currency', 'payment_currency', 'total', 'payment_amount'];

    const [rightColumns] = useState(['delete_button']) as any;

    const onDesassociate = (item?: CfdiAdvancePayment) => {
        if (item) {
            params.onDesassociate(item.id);
        }
    }

    const IconsTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(value: any) => {
            return <>
                {allowDesasociate &&
                    <IconButton aria-label="options" color="default" size="small"
                        onClick={(event: React.MouseEvent<HTMLElement>) => onDesassociate(value.row)}>
                        <DeleteIcon />
                    </IconButton>
                }
            </>;
        }} {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider key={""} for={filtersExclude} editorComponent={NoFilterEditor} />,
        <IconsTypeProvider for={['delete_button']} />,
    ];

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <GridDx
            loading={false}
            rows={data || []}
            columns={columns}
            columnsFormat={columnsFormat}
            clickRowColumns={clickRowColumns}
            rightColumns={rightColumns}
            onClickRow={onClickedRow}
            lineIdentifier={"id"}
            amountCurrencyColumns={currencyColumns}
            textColumns={textColumns}
            customFormatColumns={customPlugins}
            sorting={[]}
            heightTablePX={420}
        />
    )
}