import React, { useState, useEffect } from "react";
import { ContributionField } from "../model/Contribution";

import translate from "../i18n/Translator";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import GridDx, { NoFilterEditor } from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { getSatContributionMonthly } from "../sat_contributions/SatContributionUtil";
import { greenDot, redDot} from "./ContributionsTable";
import { getFieldUrlSpecific } from "../api/ProviderExpedientAPI";
import { GetUrlFromHistoryExpedientRequest } from "../model/ProviderExpedient";
import ContributionDocMenu from "./ContributionDocMenu";

interface FieldsTableProps {
    fields: ContributionField[];
    tenantId: string;
    providerId: string;

}

export default function FieldsDocsTable(props: FieldsTableProps) {
    const [field, setField] = useState<ContributionField>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onCloseOption = () => {
        setAnchorEl(null);
        setField(undefined);
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const load = () => {
        props.fields.forEach(field => {
            if (field.value && !field.value.startsWith("http")) {
                getFieldUrlSpecific(props.tenantId, props.providerId, field.key, { from_history:  "true", value: field.value } as GetUrlFromHistoryExpedientRequest).then((url) => {
                    field.value = url;
                }).catch((error) => {
                });
            }
        });
    };

    useEffect(load, [props]);

    const [columnsFormat] = useState([
        { columnName: 'month' },
        { columnName: 'name' },
        { columnName: 'dots', align: "center" },
        { columnName: 'menu', align: "right"},
    ]) as any;

    const columns = [
        {
            name: 'month',
            title: translate('contributions.month') as string,
            getCellValue: (row: any) => getSatContributionMonthly(row.year ?? 0, row.month ?? 0)
        },
        {
            name: 'name',
            title: translate('digital_documents.name') as string
        },
        {
            name: 'dots',
            title: " ",
            getCellValue: (row: any) => (
                row.value ? greenDot : redDot
            )
        },
        {
            name: 'menu',
            title: " "
        },
    ];

    const textColumns = ['name'];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={['status', 'name', 'dots', 'month']} editorComponent={NoFilterEditor}/>
    ];

    const onClickedOptions = (field: ContributionField) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setField(field);
    };

    return (
        <>
            <GridDx
                loading={false}
                rows={props.fields}
                totalRows={props.fields.length}
                columns={columns ? columns : []}
                columnsFormat={columnsFormat}
                textColumns={textColumns}
                onClickRow={() => {}}
                customFormatColumns={customPlugins}
                heightTablePX={500}
                onClickedOptions={onClickedOptions}
                noUseId={true}
            />
            {field && anchorEl  && field.value &&
                <ContributionDocMenu
                    field={field}
                    anchor={anchorEl}
                    onClose={onCloseOption} />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            {/* <CustomBackdrop open={submitting} /> */}
        </>
    );
}