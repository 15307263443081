import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import translate from "../../i18n/Translator";
import DialogPopup from "../../components/DialogPopup";
import { Divider, Grid, Typography, ListItemText, Box } from "@material-ui/core";
import Gridable from "../../components/Gridable";
import { CancelIcon } from "../../components/Icons";
import OkIcon from "@material-ui/icons/CheckCircleTwoTone";
import { green } from "@material-ui/core/colors";
import NumberFormat from "react-number-format";
import { WorkflowPayableDocumentResponse  } from "../../model/TenantCfdi";

interface ShowResultsBulkAuthModalProps {
    totalAuthorized: string;
    totalFailed: string;
    authorized: number;
    failed: number;
    cfdisFailed: WorkflowPayableDocumentResponse [];
    onClose(): any;
}

export default function ShowResultsBulkAuthModal(props: ShowResultsBulkAuthModalProps) {
    const [redirect, setRedirect] = useState<string>();

    const onClickedRow = (cfdi: WorkflowPayableDocumentResponse ) => {
        setRedirect(`/cfdis/${cfdi.id}/details`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }


    return (
        <DialogPopup title={translate("cfdis.to_authorize.result_bulk.title")} open maxWidth="md" onClose={props.onClose} >
            <Grid container >
                <Grid item >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Grid container>
                                        <Grid item>
                                            <Box pr={2}>
                                            <OkIcon style={{ "color": green[500] }} />
                                            </Box>
                                        </Grid>
                                        <Grid item >
                                            <Typography>{props.authorized}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography>{translate("cfdis.to_authorize.result_bulk.authorized") as string}</Typography>
                                    <ListItemText secondary={translate("cfdis.to_authorize.result_bulk.total_authorized", {"amount": props.totalAuthorized}) as string} />
                                </Grid>
                            </Grid>
                            <br/>
                            { props.failed > 0 && <Divider/> }
                        </Grid>
                        { props.failed > 0 && <>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Grid container>
                                        <Grid item>
                                            <Box pr={2}>
                                                <CancelIcon color="error"/>
                                            </Box>
                                        </Grid>
                                        <Grid item >
                                            <Typography>{props.failed}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography>{translate("cfdis.to_authorize.result_bulk.failed") as string}</Typography>
                                    <ListItemText secondary={translate("cfdis.to_authorize.result_bulk.total_not_authorized", {"amount": props.totalFailed}) as string} />
                                </Grid>
                            </Grid>
                            <br/>
                        </Grid>
                        <Grid item>
                            <Gridable
                                items={props.cfdisFailed}
                                loading={false}
                                empty={translate("cfdis.empty") as string}
                                onClick={onClickedRow}
                                columns={[
                                    {
                                        title: translate("cfdis.columns.serie_folio") as string,
                                        converter: (value) => value.payable_document.identifier,
                                        fullWidth: true,
                                        xs: 1
                                    },
                                    {
                                        title: translate("cfdis.columns.provider") as string,
                                        converter: (value) => value.payable_document.cfdi?.emisor.nombre,
                                        xs: 2
                                    },
                                    {
                                        title: translate("cfdis.columns.description") as string,
                                        converter: (value) => value.payable_document.description,
                                        fullWidth: true,
                                        xs:2
                                    },
                                    {
                                        title: translate("reports.system_log.statuses.ERROR") as string,
                                        converter: (value) => translate(`errors.codes.${value.error}`),
                                        fullWidth: true,
                                        xs: 2
                                    },
                                    {
                                        title: translate("cfdis.columns.total_text") as string,
                                        converter: (value) => <NumberFormat value={value.payable_document.total}
                                        prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />,
                                        fullWidth: true,
                                        xs: 2
                                    },
                                    {
                                        title: translate("cfdis.details.total_authorized") as string,
                                        converter: (value) => 
                                            <NumberFormat value={value.payable_document.metadata.authorized_amount ? value.payable_document.metadata.authorized_amount.total_authorized : value.payable_document.metadata.balance}
                                            prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator="," displayType="text" />,
                                        fullWidth: true,
                                        xs: 2
                                    },
                                    {
                                        title: translate("cfdis.columns.currency") as string,
                                        converter: (value) => value.payable_document.currency || "",
                                        fullWidth: true,
                                        xs: 1
                                    },
                                ]}
                            />
                        </Grid> </>}
                    </Grid>
                </Grid>
            </Grid>
        </DialogPopup>);
}
