import React from "react";
import { ListItemIcon, ListItemSecondaryAction, Typography, List, ListItem, ListItemText, Button } from "@material-ui/core";
import FoundIcon from "@material-ui/icons/SpellcheckTwoTone"; // Encontrados
import CreatedIcon from "@material-ui/icons/PersonAddTwoTone"; // Creados
import CancelIcon from "@material-ui/icons/CancelTwoTone"; // Failed
import ActiveIcon from "@material-ui/icons/CheckCircleTwoTone";
import InactiveIcon from "@material-ui/icons/CheckCircleOutlineTwoTone";
import ExceededIcon from "@material-ui/icons/DiscFullTwoTone";
import DuplicatedIcon from "@material-ui/icons/Brightness1TwoTone";
import NumberFormat from "react-number-format";

import translate from "../i18n/Translator";
import { ImportSummary } from "../model/Provider";
import DialogPopup from "../components/DialogPopup";
import { ImportResultItem } from "./AllProvidersVerificationPopup";

interface ImportResumePopupProps {
    summaryImport: ImportSummary;
    onClose(): any;
}

export default function ImportResumePopup(props: ImportResumePopupProps) {
    let values = {
        "found": props.summaryImport.found,
        "exceeded": props.summaryImport.exceeded,
        "created": props.summaryImport.created,
        "duplicated": props.summaryImport.duplicated,
        "fail": props.summaryImport.failed,
        "active": props.summaryImport.active,
        "inactive": props.summaryImport.inactive
    } as any;

    return (
        <DialogPopup
            title={translate("providers.import.resume_title")}
            open
            onClose={props.onClose}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            buttonLeft={props.summaryImport.template_with_errors ? (
                <Button component="a" href={props.summaryImport.template_with_errors} target="_blank" rel="noopener noreferrer"
                        variant="outlined" color="primary" size="medium">
                    {translate("buttons.download_erros_template") as string}
                </Button>
            ) : undefined}>

            <List component="nav">
                {Object.keys(values).map((status: string) => (
                    <StatusListItem key={status} status={status} value={values[status] as number} />
                ))}
            </List>
            {props.summaryImport.validation_efo && props.summaryImport.validation_cff69 &&
                <ImportResultItem efo={props.summaryImport.validation_efo} cff_69={props.summaryImport.validation_cff69} />
            }
        </DialogPopup>
    );
}

interface StatusListItemProps {
    status: string;
    value: number;
    fontSize?: "small" | "large";
}

function StatusListItem(props: StatusListItemProps) {
    var icon;
    switch (props.status) {
        case "found":
            icon = <FoundIcon fontSize={props.fontSize || "default"} />
            break;
        case "created":
            icon = <CreatedIcon fontSize={props.fontSize || "default"} />
            break;
        case "duplicated":
            icon = <DuplicatedIcon fontSize={props.fontSize || "default"} />
            break;
        case "active":
            icon = <ActiveIcon fontSize={props.fontSize || "default"} />
            break;
        case "inactive":
            icon = <InactiveIcon fontSize={props.fontSize || "default"} />
            break;
        case "exceeded":
            icon = <ExceededIcon fontSize={props.fontSize || "default"} color="secondary" />
            break;
        default:
            icon = <CancelIcon fontSize={props.fontSize || "default"} />
            break;
    }

    if (props.status === "exceeded" && props.value <= 0) {
        return <></>;
    }

    return (
        <ListItem>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={translate(`providers.import.${props.status}`)} />
            <ListItemSecondaryAction>
                <Typography variant="h6">
                    <NumberFormat value={props.value} displayType="text" />
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
}