import { ExtendedFieldsTemplate, ExtendedFieldsTemplateRequest, ExtendedFieldsTemplates, ExtendedFieldsTemplatesParams } from "../model/ExtendedFieldsTemplate";
import { callAPI, HTTPMethod } from "./API";

export async function listExtendendFieldsTemplates(
  tenantId: string,
  params: ExtendedFieldsTemplatesParams,
  pageSize: number,
  offset: number): Promise<ExtendedFieldsTemplates> {
  let query = {
    name: params.name ? params.name : "",
    pageSize: pageSize.toString(),
    offset: offset.toString(),
    type: params?.type?.toString() ?? "",
  } as Record<string, string>;

  return await callAPI(`/tenants/${tenantId}/extended-fields-templates`, {
    method: HTTPMethod.GET,
    query: query,
  });
}

export async function getExtendedFieldsTemplate(
  tenantId: string,
  templateId: string): Promise<ExtendedFieldsTemplate> {
  return await callAPI(`/tenants/${tenantId}/extended-fields-templates/${templateId}`, {
    method: HTTPMethod.GET,
  })
}

export async function createExtendedFieldsTemplate(tenantId: string, request: ExtendedFieldsTemplateRequest): Promise<ExtendedFieldsTemplate> {
  return await callAPI(`tenants/${tenantId}/extended-fields-templates`, {
    method: HTTPMethod.POST,
    body: request
  });
}

export async function updateExtendedFieldsTemplate(tenantId: string, templateId: string, request: ExtendedFieldsTemplateRequest): Promise<ExtendedFieldsTemplate> {
  return await callAPI(`/tenants/${tenantId}/extended-fields-templates/${templateId}`, {
    method: HTTPMethod.PUT,
    body: request,
  });
}

export async function disabledExtendedFieldsTemplate(tenantId: string, templateId: string) {
  return await callAPI(`/tenants/${tenantId}/extended-fields-templates/${templateId}`, {
    method: HTTPMethod.DELETE
  });
}
