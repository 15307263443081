import React, { useContext, useEffect, useState } from "react";
import { Grid, List, Card, ListSubheader, Typography } from "@material-ui/core";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Cfdi } from "../model/Cfdi";
import { ExtendedField, ExtendedFields } from "../model/ExtendedField";
import FieldItemsDetail from "../components/FieldItems/FieldItemsDetail";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import { Workflow } from "../model/Workflow";
import { isRoleOrParent } from "../model/Role";
import ReactHtmlParser from "html-react-parser";
import DialogPopup from "../components/DialogPopup";
import { updateShippingData, deleteFileShippingData } from "../api/ProviderCfdiApi";
import { ShippingFieldRequest } from "../model/ProviderShippingTemplate";
import { CallbackFunction, SuccessCallbackFunction } from '../components/FieldItems/OnSubmitField';

interface PayableDocumentShippingDataProps {
    payableDocument: Cfdi;
    workflow?: Workflow;
    qs: string;
    onChangeTemplate(extendedFields: ExtendedFields): any;
}

export default function PayableDocumentShippingData(props: PayableDocumentShippingDataProps) {
    const context = useContext(AppContext);
    const [fields, setFields] = useState<ExtendedField[]>();
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string | JSX.Element | JSX.Element[]>();
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const [errorPartiallity, setErrorPartiallity] = useState<string | JSX.Element | JSX.Element[]>();

    useEffect(() => {
        if (props.payableDocument.metadata.extended_fields) {
            setFields(props.payableDocument.metadata.extended_fields);
        }
    }, [props.payableDocument.metadata.extended_fields]);



    const onEditField = (extendedField: ExtendedField, onSuccess: SuccessCallbackFunction, onError: CallbackFunction) => {
        let request = {
            field_id: extendedField.id,
            value: extendedField.value,
            lines: extendedField.lines,
            invalid: extendedField.invalid,
            warehouse_delivery_lines_read_only: extendedField.warehouse_delivery_lines_read_only
        } as ShippingFieldRequest;

        updateShippingData(context.session!.tenant!.id, props.payableDocument.metadata.provider_id, props.payableDocument.id, request).then((metadata) => {
            setSuccess(translate("cfdis.success_shipping_data") as string);
            props.onChangeTemplate(metadata);
            onSuccess();
        }).catch((error) => {
            onError(error.message);
            onErrorEditField(error.message, error.code);
        });
    };

    const onDeleteField = (extendedField: ExtendedField) => {
        deleteFileShippingData(context.session!.tenant!.id, props.payableDocument.metadata.provider_id,
            props.payableDocument.id, { field_id: extendedField!.id, value: "" } as ShippingFieldRequest).then((metadata) => {
                props.onChangeTemplate(metadata);
            }).catch((error) => {
                onErrorEditField(error.message);
            });

        if (extendedField.type === "DATE") {
            setSuccess(translate("cfdis.delete_date_shipping_data") as string);
        } else {
            setSuccess(translate("cfdis.delete_file_shipping_data") as string);
        }


    };

    const onErrorEditField = (error: string, errorCode?: any) => {
        if (errorCode === 6600 || errorCode === 6601 || errorCode === 6602) {
            setErrorPartiallity(ReactHtmlParser(error));
        } else {
            setError(ReactHtmlParser(error));
        }
    };

    const onClosedSnackbar = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const onClosedError = () => {
        setErrorPartiallity(undefined);
    };

    if (!fields || fields.length === 0) {
        return <></>
    }

    return (
        <Grid item xs={12}>
            <Card variant="outlined" >
                <List component="nav" dense subheader={
                    <ListSubheader disableSticky>
                        {translate("cfdis.template.title")}
                    </ListSubheader>
                }>
                    <FieldItemsDetail
                        fields={fields}
                        providerId={props.payableDocument.metadata.provider_id}
                        tenantId={context.session!.tenant!.id}
                        fieldId={props.payableDocument.id}
                        payableDocument={props.payableDocument}
                        isReaderOnly={isReaderOnly}
                        workflow={props.workflow}
                        onChange={onEditField}
                        onDelete={onDeleteField}
                        onError={onErrorEditField}
                        qs={props.qs}
                        allowLinkPO={props.payableDocument.allow_link_po} />
                </List>
            </Card>
            {errorPartiallity && (
                <DialogPopup open
                    title={translate("purchase_orders.current_partiallity_message.title") as string}
                    closeText={translate("buttons.accept") as string}
                    onClose={onClosedError}
                    closeColor="default" >
                    <Typography variant="body2">
                        {errorPartiallity}
                    </Typography>
                </DialogPopup>
            )}
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Grid>
    );
}