import React, { useState, useContext } from "react";
import translate from "../../i18n/Translator";
import { Typography, Grid, Button } from "@material-ui/core";
import CustomBackdrop from "../../components/CustomBackdrop";
import { ErrorSnackbar } from "../../components/Snackbars";
import DialogPopup from "../../components/DialogPopup";
import UploaderInput from "../../components/UploaderInput";
import { CertificateDataRequest, TaxStampMetadata } from "../../model/StampTaxMetadata";
import ValidatedInput, { InputRef, isValid } from "../../components/ValidatedInput";
import { updateCertificate } from "../../api/TaxStampMetadataApi";
import { AppContext } from "../../context/AppContext";

interface UpdateCertificateModalProps {
    onClose(): any;
    onUpdateCertificate(response: TaxStampMetadata): any;
    isUpdate: boolean;
}

export default function UpdateCertificateModal(props: UpdateCertificateModalProps) {
    const context = useContext(AppContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [validations, setValidations] = useState({} as any);
    const [request, setRequest] = useState<CertificateDataRequest>({
        cer_url: "",
        key_url: "",
        password: "",
        rfc: context.session?.provider?.rfc || ""
    } as CertificateDataRequest);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onUpdateCertificate = () => {
        if (!isValid(validations)) {
            return;
        }
        setSubmitting(true);
        updateCertificate(request).then(response => {
            props.onUpdateCertificate(response);
        }).catch(error => {
            setError(error.message);
        }).finally(() => setSubmitting(false));
    };

    return (
        <DialogPopup title={translate(props.isUpdate ? "tax_stamp.update_cert_minus" : "tax_stamp.add_cert_minus") as string} open maxWidth="md" onClose={props.onClose}
            button={<Button onClick={onUpdateCertificate} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.save")}
                </Button>} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography><b>{translate("tax_stamp.cert_title") as string}</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{translate("tax_stamp.cert_text") as string}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UploaderInput id="cer_url" name="cer_url"
                                label={translate("tax_stamp.sign_manifest.cer") as string}
                                value={request.cer_url} disabled={false}
                                margin="dense" newFileName={request.rfc} acceptExtension=".cer"
                                onValueChanged={hasChanged}
                                required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <UploaderInput id="key_url" name="key_url"
                                label={translate("tax_stamp.sign_manifest.key") as string}
                                value={request.key_url} disabled={false}
                                margin="dense" newFileName={request.rfc} acceptExtension=".key"
                                onValueChanged={hasChanged}
                                required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput
                                type="password"
                                id="password" name="password"
                                label={translate("tax_stamp.sign_manifest.password") as string}
                                value={request.password}
                                required={true}
                                margin={"dense"}
                                disabled={false}
                                onValueChanged={hasChanged} />
                        </Grid>
                    </Grid>
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>);
}
