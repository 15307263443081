import { callAPI, HTTPMethod } from "./API";
import { LedgerAssignmentRequest, LedgerAssignment } from "../model/Provider";

export async function assignment(tenantId: string, providerId: string, request: LedgerAssignmentRequest): Promise<LedgerAssignment> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/ledger`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function deleteAssignment(tenantId: string, providerId: string, ledgerAccountId: string): Promise<LedgerAssignment> {
    return await callAPI(`/tenants/${tenantId}/providers/${providerId}/ledger/${ledgerAccountId}`, {
        method: HTTPMethod.DELETE
    });
}