import React, { useState, useEffect } from "react";
import { Box, Grid, Select, InputLabel } from "@material-ui/core";
import MultiselectDropList, { MultiselectValue } from "../components/MultiselectDropList";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import NumberFormat from "react-number-format";
import translate from "../i18n/Translator";
import { PurchaseOrder } from "../model/PurchaseOrder";
import { DocumentLine } from "../model/Document";
import { getLines as getPurchaseOrderLines, getPurchaseOrder } from "../api/ProviderPurchaseOrderAPI";
import { getLines as getWarehouseDeliveryLines, getWarehouseDelivery } from "../api/ProviderWarehouseDeliveryAPI";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import { GTEZeroValidator, LTEValidator } from "../components/Validators";
import { WarehouseDelivery } from "../model/WarehouseDelivery";

interface DocumentTableItemsProps {
    limitTags?: number;
    title: string;
    elementos: MultiselectValue[];
    placement?: "end" | "start" | "top" | "bottom";
    margin?: "normal" | "dense" | "none";
    variant?: "filled" | "outlined" | "standard";
    required?: boolean;
    onChanged(lines: DocumentLine[], valid: boolean, warehouse_delivery_lines_read_only: boolean): void;
    type: "PURCHASE_ORDERS" | "WAREHOUSE_DELIVERIES";
    disabled?: boolean;
    tenantId: string;
    providerId: string;
    cfdiId: string;
}

export default function DocumentTableItems(props: DocumentTableItemsProps) {
    const [status, setStatus] = useState<string>("loading");
    const [currentDocuments, setCurrentDocuments] = useState<string[]>([]);
    const [lines, setLines] = useState<DocumentLine[]>();
    const [currency, setCurrency] = useState<string>("");
    const [validations, setValidations] = useState<any>({});
    const [restrictToOneWarehouseDelivery, setRestrictToOneWarehouseDelivery] = useState<boolean>(false);
    const [warehouseDeliveryLinesReadOnly, setWarehouseDeliveryLinesReadOnly] = useState<boolean>(false);
    const [simpleSelectValue, setSimpleSelectValue] = useState<string>("");


    const getPromise = () => {
        if (props.type === "PURCHASE_ORDERS") {
            return getPurchaseOrderLines(props.tenantId, props.providerId, props.cfdiId);
        }
        return getWarehouseDeliveryLines(props.tenantId, props.providerId, props.cfdiId);
    };

    useEffect(() => {
        if (!lines) {
            getPromise().then(response => {
                setCurrentDocuments(response.document_ids);
                const lins : DocumentLine[] = response.lines.filter(line => line.status === undefined || line.status !== "C");
                setLines(lins);
                setCurrency(response.currency);
                setRestrictToOneWarehouseDelivery(response.restrict_to_one_warehouse_delivery);
                setWarehouseDeliveryLinesReadOnly(response.warehouse_delivery_lines_read_only);
                if(response.restrict_to_one_warehouse_delivery && response.document_ids.length > 0){
                    setSimpleSelectValue(response.document_ids[response.document_ids.length - 1]);
                }
                props.onChanged(getSelected(lins, response.document_ids), getValidation(response.warehouse_delivery_lines_read_only), response.warehouse_delivery_lines_read_only);
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [props, lines]);

    const getValidation = (warehouse_delivery_lines_read_only : boolean) : boolean => {
        if(warehouse_delivery_lines_read_only){
            return true;
        }
        return isValid(validations)
    }

    const getSelected = (lins : DocumentLine[], ids: string []) : DocumentLine[] => {
        const saved : DocumentLine[] = [];
        ids.forEach((id) => {
            lins.forEach((line) => {
                if(line.external_id === id){
                    saved.push(line);
                }
            });
        });
        return saved;
    }

    const onChange = (event: any) => {
        const { value: targetValue } = event.target;
        if (targetValue.trim() === '') {
            return;
        }
        setStatus("loading");
        setSimpleSelectValue(targetValue);
        addLines([targetValue], true);
    };

    const addLines = (selected: string[], isSimple: boolean) => {
        const added = selected.filter(el => currentDocuments.indexOf(el) < 0);
        if (added.length > 0) {
            const notAddedYetPromises = added.map(externalId => {
                if (props.type === "PURCHASE_ORDERS") {
                    return getPurchaseOrder(props.tenantId, props.providerId, externalId, 0, 0);
                }
                return getWarehouseDelivery(props.tenantId, props.providerId, externalId);
            }) as Promise<PurchaseOrder | WarehouseDelivery>[];

            Promise.all(notAddedYetPromises).then(documents => {
                setCurrentDocuments(selected);
                const moreLines = documents.flatMap(document => {
                    return document.lines.map(line => {
                        return {
                            line_num: line.line_num,
                            item_code: line.item_code,
                            description: line.description,
                            quantity: line.quantity,
                            unit_of_measure: line.unit_of_measure,
                            price: line.price,
                            line_total: line.line_total,
                            tax_code: line.tax_code,
                            tax_rate: line.tax_rate,
                            line_tax: line.line_tax,
                            withholding_tax: line.withholding_tax,
                            external_id: document.external_id,
                        } as DocumentLine;
                    })
                });
                setLinesByDocuments(moreLines, isSimple);
                setStatus("loaded");
            }).catch(error => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
    };

    const onChanged = (selected: string[]) => {
        setStatus("loading");
        const removed = currentDocuments.filter(el => selected.indexOf(el) < 0);
        if (removed.length > 0) {
            const newLines = (lines || []).filter(el => selected.indexOf(el.external_id) >= 0);
            setCurrentDocuments(selected);
            setLines(newLines);
            props.onChanged(newLines, getValidation(warehouseDeliveryLinesReadOnly), warehouseDeliveryLinesReadOnly);
            setStatus("loaded");
        } else {

            const added = lines && lines?.length > 0 ? selected.filter(el => currentDocuments.indexOf(el) < 0) : selected;
            if (added.length > 0 ) {
                const notAddedYetPromises = added.map(externalId => {
                    if (props.type === "PURCHASE_ORDERS") {
                        return getPurchaseOrder(props.tenantId, props.providerId, externalId, 0, 0);
                    }
                    return getWarehouseDelivery(props.tenantId, props.providerId, externalId);
                }) as Promise<PurchaseOrder | WarehouseDelivery>[];

                Promise.all(notAddedYetPromises).then(documents => {
                    setCurrentDocuments(selected);
                    const moreLines = documents.flatMap(document => {
                        return document.lines.map(line => {
                            return {
                                line_num: line.line_num,
                                item_code: line.item_code,
                                description: line.description,
                                quantity: line.quantity,
                                unit_of_measure: line.unit_of_measure,
                                price: line.price,
                                line_total: line.line_total,
                                tax_code: line.tax_code,
                                tax_rate: line.tax_rate,
                                line_tax: line.line_tax,
                                withholding_tax: line.withholding_tax,
                                external_id: document.external_id,
                            } as DocumentLine;
                        })
                    });
                    setLinesByDocuments(moreLines, false);
                    setStatus("loaded");
                }).catch(error => {
                    setStatus(error.message);
                });
            } else {
                setStatus("loaded");
            }

            addLines(selected, false);

        }
    };

    const setLinesByDocuments = (moreLines: DocumentLine[], isSimple: boolean) => {
        const isValid = getValidation(warehouseDeliveryLinesReadOnly);
        if (!isSimple && lines && lines.length > 0) {
            const all = lines.concat(moreLines);
            setLines(all);
            props.onChanged(all ? all : [], isValid, warehouseDeliveryLinesReadOnly);
        } else {
            setLines(moreLines);
            props.onChanged(moreLines ? moreLines : [], isValid, warehouseDeliveryLinesReadOnly);
        }
        if (restrictToOneWarehouseDelivery && moreLines.length > 0) {
            setSimpleSelectValue(moreLines[0].external_id);
        }
    };

    const onChangeQuantity = (name: string, value: number, inputRef: InputRef) => {
        let temp = lines ? lines : [];
        temp.forEach(line => {
            const itemCode = `${line.external_id}_${line.item_code}_${line.line_num}`;
            if (itemCode === name) {
                line.quantity_in_cfdi = value;
            }
        });

        validations[name] = inputRef;
        setValidations(validations);

        setLines(temp);
        props.onChanged(lines ? lines : [], getValidation(warehouseDeliveryLinesReadOnly), warehouseDeliveryLinesReadOnly);
    };

    const getValues = () => {
        let values = {} as any;
        (lines || []).forEach(line => {
            values[line.external_id] = {
                title: line.external_id,
                value: line.external_id
            } as MultiselectValue;
        })

        return Object.values(values) as MultiselectValue[];
    };

    return (
        <Grid container >
            <Grid item xs={12} sm={9} md={6} lg={6}>
                <Box pb={1} mt={-1}>
                    {restrictToOneWarehouseDelivery ?
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel htmlFor={"warehouseDeliverySelect"}>{props.title}</InputLabel>
                            <Select native style={{ width: "100%" }}
                                labelId="warehouseDeliverySelectId"
                                id="warehouseDeliverySelect"
                                value={simpleSelectValue}
                                onChange={onChange} >
                                <option key="---" value={undefined}>---</option>
                                {(props.elementos || []).map((element, index) => (
                                    <option key={element.value} value={element.value}>{element.title}</option>
                                ))}
                            </Select>
                        </Grid>
                        : <MultiselectDropList
                            title={props.title}
                            elementos={props.elementos}
                            value={getValues()}
                            required={props.required}
                            disabled={props.disabled}
                            onChanged={onChanged} />}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mx={-3}>
                    <Gridable
                        items={lines ? lines : []}
                        loading={status === "loading"}
                        empty={translate("providers.empty") as string}
                        columns={[
                            {
                                title: translate("purchase_orders.line_num") as string,
                                converter: (line) => line.line_num || "",
                                xs: false,
                                sm: false,
                                md: true,
                                xl: true
                            },
                            {
                                title: translate(props.type === "PURCHASE_ORDERS" ? "purchase_orders.single" : "warehouse_deliveries.single") as string,
                                converter: (line) => line.external_id,
                                fullWidth: true,
                                xs: false,
                                sm: 1,
                                md: 1,
                                xl: 1
                            },
                            {
                                title: translate("purchase_orders.item_code") as string,
                                converter: (line) => line.item_code,
                                fullWidth: true,
                                xs: 3,
                                sm: 2,
                                md: 1,
                                xl: 1
                            },
                            {
                                title: translate("purchase_orders.unit_measure") as string,
                                converter: (line) => line.unit_of_measure,
                                xs: false,
                                sm: false,
                                md: 1,
                                xl: 1
                            },
                            {
                                title: translate("cfdis.details.description") as string,
                                converter: (line) => (
                                    <Ellipsis text={line.description} lenght={100} uppercased={false} />
                                ),
                                xs: false,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.quantity") as string,
                                converter: (line) => (
                                    <Grid>
                                        {warehouseDeliveryLinesReadOnly ?
                                            <Ellipsis text={line.quantity.toString()} lenght={100} uppercased={false} />
                                            : <QuantityField
                                                itemCode={`${line.external_id}_${line.item_code}_${line.line_num}`}
                                                quantityPurchaseOrder={line.quantity}
                                                quantity={line.quantity_in_cfdi}
                                                onChangeQuantity={onChangeQuantity} />
                                        }
                                    </Grid>
                                ),
                                fullWidth: true,
                                xs: warehouseDeliveryLinesReadOnly ? 1 : 3,
                                sm: warehouseDeliveryLinesReadOnly ? 1 : 3,
                                md: warehouseDeliveryLinesReadOnly ? 1 : 2,
                                xl: warehouseDeliveryLinesReadOnly ? 1 : 2
                            },
                            {
                                title: translate("cfdis.details.unit_price", { "currency": currency }) as string,
                                converter: (line) => (
                                    <NumberFormat value={line.price} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                ),
                                xs: 3,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                            {
                                title: translate("cfdis.details.price", { "currency": currency }) as string,
                                converter: (line) => (
                                    <NumberFormat value={line.line_total} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />
                                ),
                                xs: 3,
                                sm: 2,
                                md: 2,
                                xl: 2
                            },
                        ]} />
                </Box>
            </Grid>
        </Grid>
    );
}

interface QuantityFieldProps {
    itemCode: string;
    quantityPurchaseOrder: number;
    quantity?: number;
    onChangeQuantity(name: string, value: number, inputRef: InputRef): any;
}

function QuantityField(props: QuantityFieldProps) {
    const gtZeroValidator = new GTEZeroValidator();
    const ltValidator = new LTEValidator(props.quantityPurchaseOrder);

    return <div>
        <Grid container >
            <Grid item xs={9} sm={9} md={8} lg={6} >
                <ValidatedInput
                    type="number"
                    id={props.itemCode}
                    name={props.itemCode}
                    value={props.quantity?.toString() || ""}
                    validators={[gtZeroValidator, ltValidator]}
                    label={""}
                    required={true}
                    margin="dense"
                    onValueChanged={props.onChangeQuantity}
                />
            </Grid>
            <Grid item xs={3} sm={3} md={4} lg={6} >
                <Box pt={2} pl={1}>
                    {"/" + props.quantityPurchaseOrder}
                </Box>
            </Grid>
        </Grid>
    </div>;
}