import { Box, Button, Grid, Typography } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import React, { useState, useEffect } from "react";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import Progress from "../components/Progress";
import translate from "../i18n/Translator";
import { Requisition, RequisitionRequest } from "../model/Requisition";
import { Company } from "../model/Company";
import { RequisitionTypeCatalog } from "../model/RequisitionTypeCatalog";
import { list as listProjects } from "../api/ProjectAPI";
import { ProjectListParams, Project } from "../model/Project";
import { MultiselectValue as AutocompleteProvider } from "../components/MultiselectDropList";
import InputsHeader, { AutocompleteItem } from "./RequisitionInputsHeader";

export interface HeaderRequisitionFormProps {
    isEdit: boolean;
    requisition: Requisition;
    types: string[];
    typeLabels: string[];
    projects: Project[];
    projectSelected: Project;
    providers: AutocompleteItem[];
    providerSelected: AutocompleteItem;
    currencies: AutocompleteProvider[];
    isBudgetControl: boolean;
    companies: Company[];
    onSave(request: RequisitionRequest): any;
    onBack(): any;
    onValidHeaders(request: RequisitionRequest, validateCompany: boolean): any;
    tenantId: string;
    rolId: string;
    userId: string;
    changeAmount?: number;
    typesRequisitions: RequisitionTypeCatalog[];
}

export default function HeaderRequisitionForm(props: HeaderRequisitionFormProps) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [request, setRequest] = useState<RequisitionRequest>({
        provider_id: props.requisition.provider_id,
        requisition_type_id: props.requisition.requisition_type_id,
        description: props.requisition.description,
        in_project: props.requisition.in_project,
        project_id: props.requisition.project_id,
        currency: props.requisition.currency,
        company_id: props.requisition.company_id
    } as RequisitionRequest);
    const [status, setStatus] = useState<string>("loading");
    const [projectsDroplist, setProjectsDroplist] = useState<Project[]>([]);
    const [providersDroplist, setProvidersDroplist] = useState<AutocompleteItem[]>([]);

    const onClosedSnackbar = () => {
        setError("");
        setWarning("");
    };

    const load = () => {
        setStatus("loading");
        if(props.isBudgetControl && (!props.companies || props.companies.length <= 0)){
            setWarning(translate("requisitions.empty_companies") as string);
        } 
        setStatus("loaded");
        // eslint-disable-next-line 
    };

    useEffect(load, []);

    const hasChanged = (name: string, value: string) => {
        const fixedValue = value === "---" ? "" : value;

        if (name === 'requisition_type_id') {
            if (request.in_project) {
                hasChangedRequisitionType(fixedValue);
            }
            filterProviders(fixedValue);
        }
        setRequest({ ...request, [name]: fixedValue });
    };

    const hasChangedRequisitionType = (requesitionType: string) => {
        listProjects(props.tenantId, 0, 0, {
            search: "", status: ["TO_START", "IN_PROGRESS"].join(","),
            user_id: (props.rolId === "owner" || props.rolId === "project_manager") ? "" : props.userId,
            requisition_type: requesitionType
        } as ProjectListParams).then((response) => {
            let projectsFiltered = [] as Project[];
            if (response.items) {
                response.items.forEach(item => {
                    projectsFiltered.push(item);
                });
                setProjectsDroplist(projectsFiltered);
            }
            setProjectsDroplist(projectsFiltered);
        }).catch(error => {
            setError(error.message);
        });
    }

    const filterProviders = (requesitionType: string) => {
        let types_req = props.typesRequisitions.find(tr => tr.id === requesitionType);
        if (types_req && !types_req.all_providers && types_req.provider_ids) {
            console.info(types_req.provider_ids);
            let providers_in_type = [] as AutocompleteItem[];
            types_req.provider_ids.forEach(providerInType => {
                let finded = props.providers.find(p => p.value === providerInType);
                if (finded) {
                    providers_in_type.push(finded);
                }
            });
            setProvidersDroplist(providers_in_type);
        } else {
            setProvidersDroplist([]);
        }
    }

    const onSwitchInProject = () => {
        if (!request.in_project) {
            hasChangedRequisitionType(request.requisition_type_id);
        }
        setRequest({ ...request, in_project: !request.in_project });
    };

    const onNext = () => {
        setSubmitting(true);
        let validHeaders = props.onValidHeaders(request, props.isBudgetControl);
        if (validHeaders && validHeaders !== "") {
            setWarning(translate(validHeaders) as string);
            setSubmitting(false);
            return;
        }
        props.onSave(request);
    };

    const onCurrencyChanged = (value: string, inputRef?: any) => {
        setRequest({ ...request, currency: value });
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(props.isEdit ? "requisitions.form.edit_requisition" : "requisitions.form.new_requisition")} icon={<WorkIcon />}
                        className="FormSurface" >
                        <InputsHeader
                            submitting={submitting}
                            request={request}
                            types={props.types}
                            typeLabels={props.typeLabels}
                            projects={projectsDroplist && projectsDroplist.length > 0 ? projectsDroplist : props.projects}
                            projectSelected={props.projectSelected}
                            providers={providersDroplist && providersDroplist.length > 0 ? providersDroplist : props.providers}
                            providerSelected={props.providerSelected}
                            hasChanged={hasChanged}
                            currencies={props.currencies}
                            isBudgetControl={props.isBudgetControl}
                            isEdit={props.isEdit}
                            companies={props.companies}
                            hasChangedCurrency={onCurrencyChanged}
                            onSwitchInProject={onSwitchInProject}
                            xs={12}
                            setError={(error) => setError(error)}
                            view="header"
                        />
                        <Grid item xs={12}>
                            <Box pt={2}>
                                <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                    <Grid item xs={12} md="auto">
                                        <Button type="button" variant="contained" color="primary" size="large" disabled={submitting || (props.isBudgetControl && (!props.companies || props.companies.length <= 0) )} onClick={onNext}>
                                            { props.isEdit ? translate("buttons.update") : translate("buttons.sig")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md="auto">
                                        <Button variant="text" color="primary" size="large" disabled={submitting} onClick={props.onBack}>
                                            {translate("buttons.cancel")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Grid>
    );
}
