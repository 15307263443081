import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import { Requisition, RequisitionLine } from "../model/Requisition";

interface RequisitionLineMenuProps {
    anchor: HTMLElement | null;
    line: RequisitionLine;
    modifyEnable: boolean;
    onClose(): any;
    onOpenDeleteLine(line: RequisitionLine): any;
    onOpenEditLine(line: RequisitionLine): any;
}

export default function RequisitionLineMenu(props: RequisitionLineMenuProps) {
    const context = useContext(AppContext);

    const onEditOption = () => {
        props.onClose();
        props.onOpenEditLine(props.line);
    };

    const onDeleteOption = () => {
        props.onClose();
        props.onOpenDeleteLine(props.line);
    };

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            
            {context.isGrantedAny(["RequisitionsUpdate"]) && props.modifyEnable &&
                <MenuItem button onClick={onEditOption} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RequisitionsUpdate"]) && props.modifyEnable &&
                <MenuItem button onClick={onDeleteOption} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
}