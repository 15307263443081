import React from "react";

import DialogPopup from "../components/DialogPopup";
import { Grid, Typography, } from '@material-ui/core';
import translate from "../i18n/Translator";
import { CloneCalendarResponse } from '../model/Dashboard';

import Gridable from "../components/Gridable";

export interface CloneCalendarResultProps {
    start: string;
    end: string;
    result: CloneCalendarResponse;
    onClose(): void;
}

export default function CloneCalendarResultPopup(props: CloneCalendarResultProps) {

    return (
        <DialogPopup open
            title={translate("calendar_events.clone_title")}
            closeText={translate("buttons.close") as string}
            onClose={props.onClose}
            closeColor="default">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        {translate(`calendar_events.clone_result.header_note`, { origen: props.start, destino: props.end }) as string}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Gridable
                        items={props.result.data ?? []}
                        loading={false}
                        error={""}
                        columns={[
                            {
                                title: translate("calendar_events.clone_result.event_type") as string,
                                converter: (item) => translate(`calendar_events.${item.type}.label`) as string,
                                sm: 6,
                            },
                            {
                                title: translate("calendar_events.clone_result.copied") as string,
                                converter: (item) => item.cloned,
                                sm: 3,
                            },
                            {
                                title: translate("calendar_events.clone_result.ommited") as string,
                                converter: (item) => item.error,
                                sm: 3,
                            },
                        ]} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        {translate(`calendar_events.clone_result.footer_note`) as string}
                    </Typography>
                </Grid>
            </Grid>

        </DialogPopup >
    );
}
