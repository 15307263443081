import React, { useState, useEffect, useContext, ReactNode } from "react";
import { useLocation, useHistory } from "react-router";
import { Typography, Tabs, Tab, Grid, Fab, Chip, Button, IconButton } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import SendCfdiIcon from "@material-ui/icons/SendTwoTone";
import RejectIcon from "@material-ui/icons/ThumbDownTwoTone";
import AcceptIcon from "@material-ui/icons/ThumbUpTwoTone";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";
import EditIcon from "@material-ui/icons/EditTwoTone";

import translate from "../i18n/Translator";
import { Cfdi } from "../model/Cfdi";
import { get, send, getHistory, getDocumentsUrl, reject, accept, resend, editPrepare, getCfdiRejectToSend } from "../api/TenantSECfdiApi";
import { get as customerGet, getHistory as customerGetHistory, getDocumentsUrl as customerGetDocumentsUrl, reject as customerReject, accept as customerAccept } from "../api/CustomerSECfdiApi";
import { get as providerGet, send as providerSend, getHistory as providerGetHistory, getDocumentsUrl as providerGetDocumentsUrl, resend as providerResend, editPrepare as providerEditPrepare } from "../api/ProviderSECfdiApi";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import CfdiHistoryTab from "../cfdi/CfdiHistoryTab";
import CfdiDetails from "../cfdi/CfdiDetails";
import CfdiValidations from "../cfdi/CfdiValidations";
import Progress from "../components/Progress";
import Surface from "../components/Surface";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import DialogPopup from "../components/DialogPopup";
import CfdiIssuesSendingInfo from "./CfdiIssuesSendingInfo";
import CfdiIssuedDocs from "./CfdiIssuedDocs";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { CommentRequest } from "../model/CfdiSe";
import { CfdiHistories } from "../model/CfdiHistory";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import CfdiIssuedMenu from "./CfdiIssuedMenu";
import { deleteCfdi as deleteProviderCfdi } from "../api/ProviderSECfdiApi";
import { deleteCfdi } from "../api/TenantSECfdiApi";
import { Redirect } from "react-router-dom";

type TabName = "details" | "docs" | "validations" | "history";

type DialogName = "send" | "reject" | "accept" | "resend" | "edit" | "delete" | "reject_pending_docs";

interface CfdiTab {
    name: TabName;
    title: ReactNode;
    actions?: string[];
}

export default function CfdiIssuedOverview({ match }: RouterParams) {
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const [dialog, setDialog] = useState<DialogName>();
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const customerId = context.session?.customer?.id;
    const providerId = context.session?.provider?.id;
    const [allowedToUpdateDocStatus] = useState(context.isGrantedAny(["CustomerCfdisIssuedUpdate", "TenantCfdisIssuedUpdate"]));
    const [allowedToUpdateDocStatusTenant] = useState(context.isGrantedAny(["TenantCfdisIssuedUpdate"]));
    const [allowedToResend] = useState(context.isGrantedAny(["ProviderCfdisIssuedUpdate", "CfdisIssuedUpdate"]));
    const [showSendingInfo] = useState(context.isGrantedAny(["CfdisIssuedRead", "CustomerCfdisIssuedRead"]));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [redirect, setRedirect] = useState<string | undefined>();

    const query = new URLSearchParams(useLocation().search);
    const tabName = query.get("tab");

    const allTabs = [
        {
            name: "details",
            title: translate("cfdis.details.title" as string)
        },
        {
            name: "docs",
            title: translate("cfdis_se.docs.title") as string
        },
        {
            name: "validations",
            title: translate("cfdis.validations.title") as string
        },
        {
            name: "history",
            title: translate("cfdis.history.title") as string
        },
    ] as CfdiTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        const tq = query.get("tab");
        if (typeof tq === "string") {
            return tabToIndex(tq);
        }
        return 0;
    };

    const [status, setStatus] = useState<string>("loading");
    const [request, setRequest] = useState<CommentRequest>({} as CommentRequest);
    const [cfdi, setCfdi] = useState<Cfdi>();
    const [cfdiHistory, setCfdiHistory] = useState<CfdiHistories>({} as CfdiHistories);
    const [tabs, setTabs] = useState<CfdiTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);
    const [success, setSuccess] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [downloadUrl, setDownloadUrl] = useState<string>();

    const promiseGet = () => {
        if (customerId) {
            return customerGet(tenantId, customerId, match.params.cfdiId);
        }
        if (providerId) {
            return providerGet(tenantId, providerId, match.params.cfdiId);
        }
        return get(tenantId, match.params.cfdiId);
    };

    const promiseGetHistory = () => {
        if (customerId) {
            return customerGetHistory(tenantId, customerId, match.params.cfdiId, 0, 0);
        }
        if (providerId) {
            return providerGetHistory(tenantId, providerId, match.params.cfdiId, 0, 0);
        }
        return getHistory(tenantId, match.params.cfdiId, 0, 0);
    };

    const promiseGetDocuments = () => {
        if (customerId) {
            return customerGetDocumentsUrl(tenantId, customerId, match.params.cfdiId);
        }
        if (providerId) {
            return providerGetDocumentsUrl(tenantId, providerId, match.params.cfdiId);
        }
        return getDocumentsUrl(tenantId, match.params.cfdiId);
    };

    const load = () => {
        setStatus("loading");
        Promise.all([
            promiseGet(),
            promiseGetHistory()
        ]).then(([cfdi, cfdiHistory]) => {
            setCfdiHistory(cfdiHistory);
            setCfdi(cfdi);

            const cfdiTabs = allTabs.map(el => {
                if (el.name === "docs") {
                    const statuses = cfdi.metadata.specialized_services?.contributions?.map(c => c.status) || ["PENDING"];
                    const allCompletedStatuses = statuses.indexOf("PENDING") === -1;
                    const allInvoices = cfdi.metadata.specialized_services?.all_invoices || false;

                    if (!allCompletedStatuses || !allInvoices || cfdi.metadata.status === "SE_REJECTED_DOCS") {
                        el.title = (
                            <Grid container spacing={1} style={{ minWidth: "260px" }}>
                                <Grid item xs="auto">
                                    <strong>{el.title}</strong>
                                </Grid>
                                <Grid item xs="auto">
                                    <DotIcon style={{ "color": red[500], "fontSize": "12px" }} />
                                </Grid>
                            </Grid>
                        );
                    }
                }

                return el;
            });

            setTabs(cfdiTabs);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    const verifyStatusAndRedirect = () => {
        if (cfdi?.metadata.status === "SE_PREPARING") {
            history.push(`/cfdis/se/${cfdi.id}/prepare`);
        }
    };

    useEffect(load, [match.params.cfdi]);

    useEffect(verifyStatusAndRedirect, [cfdi]);

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line
    }, [tabName, tabs]);

    useEffect(() => {
        setDownloadUrl(undefined);
        if (cfdi?.metadata.status === "SE_VALIDATING_DOCS" || cfdi?.metadata.status === "SE_REJECTED_DOCS" || cfdi?.metadata.status === "SE_DONE") {
            promiseGetDocuments().then(url => {
                setDownloadUrl(url);
            }).catch(error => {
                console.log(error);
            });
        }
        // eslint-disable-next-line
    }, [cfdi]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let newTab = tabs[newValue];
        if (newTab) {
            query.set("tab", newTab.name);
        }

        setValue(newValue);
    };

    const tabContent = (index: number) => {
        if (!cfdi) return;

        if (!context.session || !context.session.tenant) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return (
                    <CfdiDetails tenantId={tenantId} cfdi={cfdi}
                        header={showSendingInfo ? (
                            <Grid item xs={12}>
                                <CfdiIssuesSendingInfo cfdi={cfdi} />
                            </Grid>
                        ) : undefined}
                        hideWorkflow
                        onChangeTemplate={() => { }}
                        onChangeProrate={() => { }}
                        onChangeTotalAuthorize={() => { }}
                        onUpdated={() => { }}
                        onUpdatedTotalAuthorized={() => { }}
                        onDeleteAdditionalAmount={() => { }} />
                );
            case "history":
                return <CfdiHistoryTab tenantId={tenantId} cfdiId={cfdi.id} history={cfdiHistory} />;
            case "validations":
                return <CfdiValidations tenantId={tenantId} cfdi={cfdi} />;
            case "docs":
                return <CfdiIssuedDocs cfdi={cfdi} uploadSipare={cfdi.metadata.provider_upload_sipare} />;
            default:
                return <div>{current.name}</div>;
        }
    };

    const promiseSend = () => {
        if (providerId) {
            return providerSend(tenantId, providerId, match.params.cfdiId, request);
        }
        return send(tenantId, match.params.cfdiId, request);
    };

    const onSend = () => {
        setDialog(undefined);
        setSubmitting(true);
        promiseSend().then(response => {
            setCfdi(response);
            setSuccess(translate("cfdis_se.send.success") as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const promiseReject = () => {
        if (customerId) {
            return customerReject(tenantId, customerId, match.params.cfdiId, request);
        }
        return reject(tenantId, match.params.cfdiId, request);
    };

    const onReject = () => {
        setDialog(undefined);
        setSubmitting(true);
        promiseReject().then(response => {
            setCfdi(response);
            setSuccess(translate("cfdis_se.reject.success") as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const promiseAccept = () => {
        if (customerId) {
            return customerAccept(tenantId, customerId, match.params.cfdiId);
        }
        return accept(tenantId, match.params.cfdiId);
    };

    const onAccept = () => {
        setDialog(undefined);
        setSubmitting(true);
        promiseAccept().then(response => {
            setCfdi(response);
            setSuccess(translate("cfdis_se.accept.success") as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const promiseResend = () => {
        if (providerId) {
            return providerResend(tenantId, providerId, match.params.cfdiId);
        }
        return resend(tenantId, match.params.cfdiId);
    };

    const onResend = () => {
        setDialog(undefined);
        setSubmitting(true);
        promiseResend().then(response => {
            setCfdi(response);
            setSuccess(translate("cfdis_se.resend.success") as string);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const promiseEditPrepare = () => {
        if (providerId) {
            return providerEditPrepare(tenantId, providerId, match.params.cfdiId);
        }
        return editPrepare(tenantId, match.params.cfdiId);
    };

    const onEdit = () => {
        setDialog(undefined);
        setSubmitting(true);
        promiseEditPrepare().then(response => {
            history.push(`/cfdis/se/${match.params.cfdiId}/prepare`);
        }).catch(error => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setWarning(undefined);
        setError(undefined);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
    };

    const onDelete = () => {
        setDialog("delete");
        setAnchorEl(null);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
    };

    const promiseDelete = (cfdiId: string) => {
        if (providerId) {
            return deleteProviderCfdi(tenantId, providerId, cfdiId);
        }
        return deleteCfdi(tenantId, cfdiId);
    };

    const confirmDelete = () => {
        setDialog(undefined);

        if (!cfdi) return;

        setSubmitting(true);
        promiseDelete(cfdi.id).then(() => {
            load();
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setCfdi(undefined);
        });
    };

    const confirmRejectToSend = () => {
        setDialog(undefined);
        if (!cfdi) return;
        setSubmitting(true);
        getCfdiRejectToSend(tenantId, cfdi.id).then(() => { 
            setRedirect(`/cfdis/se/pending-docs/success/${ translate("cfdis_se.reject.succes") as string }`);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
        });

    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const chipStatus = () => {
        if (!cfdi) {
            return (<></>);
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs="auto">{cfdi.identifier}</Grid>
                <Grid item xs="auto"><Chip label={translate("cfdis_se.se")} variant="outlined" size="small" color="secondary" /></Grid>
                <Grid item xs="auto"><Chip label={translate(`cfdis_se.status.${cfdi.metadata.status}`)} variant="outlined" size="small" /></Grid>
            </Grid>
        );
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !cfdi) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    if (redirect) {
        return (<Redirect to={redirect} />);
    }

    return (
        <Surface title={cfdi.cfdi?.receptor.nombre} subtitle={chipStatus()} backButton icon={<MoreVertIcon />} className="PaperPagination PaperTabContainer" titleActions={(
            <Grid container alignItems="center" justify="flex-end" spacing={1}>
                {cfdi.metadata.status === "SE_TO_SEND" && (
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("cfdis.to_send.actions.send") as string} onClick={() => setDialog("send")} >
                            <SendCfdiIcon />
                        </Fab>
                    </Grid>
                )}
                {allowedToResend && (cfdi.metadata.status === "SE_TO_SEND" || cfdi.metadata.status === "SE_REJECTED_DOCS") && (
                    <Grid item xs="auto">
                        <Fab color="secondary" size="small" title={translate("cfdis_se.edit.title") as string} onClick={() => setDialog("edit")} >
                            <EditIcon />
                        </Fab>
                    </Grid>
                )}
                {allowedToUpdateDocStatus && cfdi.metadata.status === "SE_VALIDATING_DOCS" && tabs[value].name === "docs" && (
                    <Grid item xs="auto">
                        <Fab color="secondary" size="small" title={translate("cfdis_se.reject.button") as string} onClick={() => setDialog("reject")} >
                            <RejectIcon />
                        </Fab>
                    </Grid>
                )}
                {allowedToUpdateDocStatus && cfdi.metadata.status === "SE_VALIDATING_DOCS" && tabs[value].name === "docs" && (
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("cfdis_se.accept.button") as string} onClick={() => setDialog("accept")} >
                            <AcceptIcon />
                        </Fab>
                    </Grid>
                )}
                {allowedToResend && cfdi.metadata.status === "SE_REJECTED_DOCS" && tabs[value].name === "docs" && (
                    <Grid item xs="auto">
                        <Fab color="secondary" size="small" title={translate("cfdis_se.resend.button") as string} onClick={() => setDialog("resend")} >
                            <SendCfdiIcon />
                        </Fab>
                    </Grid>
                )}
                {downloadUrl && tabs[value].name === "docs" && (
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("cfdis_se.download") as string} component="a" href={downloadUrl} target="_blank" rel="noopener noreferrer">
                            <DownloadIcon />
                        </Fab>
                    </Grid>
                )}
                {allowedToUpdateDocStatusTenant && cfdi.metadata.status === "SE_PENDING_DOCS" && tabs[value].name === "docs" && (
                    <Grid item xs="auto">
                        <Fab color="primary" size="small" title={translate("cfdis_se.reject.button") as string} onClick={() => setDialog("reject_pending_docs")} >
                            <RejectIcon />
                        </Fab>
                    </Grid>
                )}
                <Grid item xs="auto">
                    <IconButton color="default" onClick={onClickedMore}>
                        <MoreVertIcon />
                    </IconButton>
                </Grid>
            </Grid>
        )}>
            <Tabs value={value} onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: CfdiTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: CfdiTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            {dialog === "send" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.send.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={onSend} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.send")}
                        </Button>
                    }
                >
                    <Grid container style={{ minWidth: "280px" }}>
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="comments" name="comments"
                                label={translate("cfdis_se.send.comments") as string}
                                margin="dense"
                                rows={4} maxRows={10} maxLength={2000}
                                onValueChanged={hasChanged} />
                        </Grid>
                    </Grid>
                </DialogPopup>
            )}
            {dialog === "reject" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.reject.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={onReject} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.reject")}
                        </Button>
                    }
                >
                    <Grid container style={{ minWidth: "280px" }}>
                        <Grid item xs={12}>
                            <ValidatedInput type="text" id="comments" name="comments"
                                label={translate("cfdis_se.reject.comments") as string}
                                margin="dense"
                                rows={4} maxRows={10} maxLength={2000}
                                onValueChanged={hasChanged} />
                        </Grid>
                    </Grid>
                </DialogPopup>
            )}
            {dialog === "accept" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.accept.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={onAccept} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.accept")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.accept.text")}
                </DialogPopup>
            )}
            {dialog === "resend" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.resend.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={onResend} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.resend")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.resend.text")}
                </DialogPopup>
            )}
            {dialog === "edit" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.edit.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={onEdit} variant="outlined" color="primary" disabled={submitting}>
                            {translate("buttons.edit")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.edit.text")}
                </DialogPopup>
            )}
            {dialog === "delete" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.delete.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={confirmDelete} variant="outlined" color="secondary" disabled={submitting}>
                            {translate("buttons.delete")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.delete.text")}
                </DialogPopup>
            )}
            {dialog === "reject_pending_docs" && (
                <DialogPopup open maxWidth="md"
                    title={translate("cfdis_se.reject.title") as string}
                    disableEscapeKeyDown={submitting}
                    disableBackdropClick={submitting}
                    disable={submitting}
                    closeText={translate("buttons.cancel") as string}
                    onClose={() => setDialog(undefined)}
                    closeColor="default"
                    button={
                        <Button onClick={confirmRejectToSend} variant="outlined" color="secondary" disabled={submitting}>
                            {translate("buttons.accept")}
                        </Button>
                    }
                >
                    {translate("cfdis_se.reject.confirm_pending_docs")}
                </DialogPopup>
            )}
            {anchorEl &&
                <CfdiIssuedMenu 
                    cfdi={cfdi} 
                    anchor={anchorEl} 
                    onDelete={onDelete} 
                    onClose={onCloseOption}
                    isProvider={providerId !== undefined}
                    inDetails={true}
                />
            }
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} message={translate("cfdis.processing") as string} />
        </Surface>
    );
}
