import React, { useState, useEffect, useContext, useRef } from "react";
import queryString from "query-string";
import { Redirect, useHistory, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Hidden, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GroupIcon from "@material-ui/icons/Group";
import AddIcon from "@material-ui/icons/Add";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { getUsers } from "../api/UserAPI";
import { getUsers as getTenantUsers, resendSubscribeEmail, importUsers } from "../api/TenantUserAPI";
import { getUsers as getProviderUsers } from "../api/ProviderUserAPI";
import { getUsers as getCustomerUsers } from "../api/CustomerUserAPI";
import { Users, User, UserImpersonalize, UsersQueryParams, UsersQueryParamsReceptors } from "../model/User";
import { ImportUsersSummary } from "../model/Provider";

import { Role } from "../model/Role";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";
import UserMenu from "./UserMenu";
import UserActivatePopup from "./UserActivatePopup";
import UserBlockPopup from "./UserBlockPopup";
import UserUnblockPopup from "./UserUnblockPopup";
import UserDeletePopup from "./UserDeletePopup";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import NumberFormat from "react-number-format";
import { phoneFormat } from "../components/Validators";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SearchByEmailPopup from "./SearchByEmailPopup";
import ImpersonalizePopup from "./ImpersonalizePopup";
import ConfirmationPopup from "../components/ConfirmationPopup";
import UserGeneralMenu from "./UserGeneralMenu";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import { isRoleOrParent } from "../model/Role";
import ImportUsersResumePopup from "../providers/ImportUsersResumePopup";

export default function UsersList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const [tenantId] = useState<string>(context.session?.tenant?.id ?? match.params.tenantId);
    const paramsFromQueryString = (): UsersQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "role_id": typeof qs["role_id"] === "string" ? qs["role_id"] as string : "",
            "reference_view": "",
        } as UsersQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Users | undefined>();
    const [params, setParams] = useState<UsersQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<UsersQueryParams>(paramsFromQueryString);
    const [roleNames, setRoleNames] = useState<string[]>([]);
    const [roleIds, setRoleIds] = useState<string[]>([]);

    const [userImpersonalize, setUserImpersonalize] = useState<UserImpersonalize>();
    const [user, setUser] = useState<User>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activatePopup, setActivatePopup] = useState<boolean>(false);
    const [resendEmailPopup, setResendEmailPopup] = useState<boolean>(false);
    const [dialog, setDialog] = useState<"block" | "unblock" | "delete" | "unlock">();

    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [redirect, setRedirect] = useState<string>();

    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
    const [openImpersonalizeModal, setOpenImpersonalizeModal] = useState<boolean>(false);

    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [importUsersSummary, setImportUsersSummary] = useState<ImportUsersSummary>();
    const [uploading, setUploading] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);

    const [createUrl] = useState<string>(() => {
        let url = "";
        if (match.params.providerId) {
            url = `/providers/${match.params.providerId}`;
        } else if (context.isGranted("ProviderUsersCreate")) {
            url = `/providers/${context.session?.provider?.id}`;
        } else if (match.params.customerId) {
            url = `/customers/${match.params.customerId}`;
        } else if (context.isGranted("CustomerUsersCreate")) {
            url = "";
        } else if (tenantId && context.isGranted("UsersCreate")) {
            url = `/tenants-admin/${tenantId}`;
        } else if (tenantId) {
            url = `/tenants/${tenantId}`;
        }
        return `${url}/users/new`;
    });

    const retrievePromise = (): Promise<Users> => {
        let offset = getOffset(page, pageSize);
        let href = window.location.pathname;
        if (tenantId) {
            if (match.params.providerId) {
                params.reference_view = "PROVIDER_USERS";
                return getProviderUsers(tenantId, match.params.providerId, pageSize, offset, params);
            } else if (match.params.customerId) {
                params.reference_view = "CUSTOMER_USERS";
                return getCustomerUsers(tenantId, match.params.customerId, pageSize, offset, params);
            } else if (context.isGranted("ProviderUsersRead") && context.session && context.session.provider) {
                params.reference_view = "PROVIDER_USERS";
                return getProviderUsers(tenantId, context.session.provider.id, pageSize, offset, params)
            } else if (context.isGranted("CustomerUsersRead") && context.session && context.session.customer) {
                params.reference_view = "CUSTOMER_USERS";
                return getCustomerUsers(tenantId, context.session.customer.id, pageSize, offset, params)
            } else {
                if (href.includes('providers-users')) {
                    params.reference_view = "TENANT_PROVIDERS_USERS";
                } else {
                    params.reference_view = "TENANT_USERS";
                }
                return getTenantUsers(tenantId, pageSize, offset, { search: params.search, role_list: [params.role_id].join(","), reference_view: params.reference_view } as UsersQueryParamsReceptors)
            }
        }
        return getUsers(pageSize, offset, params);
    };

    const load = () => {
        setStatus('loading');
        retrievePromise().then((response) => {
            setData(response);
            setRoleIds(response.metadata?.roles.map((role) => role.id));
            setRoleNames(response.metadata?.roles.map((role) => getRoleName(role)));
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["role_id"] = workingParams.role_id;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (user: User) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setUser(user);
    };

    const showDialog = (dialog: "block" | "unblock" | "delete" | "unlock") => () => {
        if (!user) return;
        setAnchorEl(null);
        setDialog(dialog);
    };

    const onBlocked = () => {
        if (!user) return;

        user.blocked = true;
        setSuccess(translate("users.block.success", {
            "first_name": user.first_name,
            "last_name": user.last_name,
            "email": user.email
        }) as string);
        setUser(undefined);
        setDialog(undefined);
    };

    const onUnblocked = () => {
        if (!user) return;

        if (dialog === "unblock") {
            user.blocked = false;
            setSuccess(translate("users.unblock.success", {
                "first_name": user.first_name,
                "last_name": user.last_name,
                "email": user.email
            }) as string);
        } else if (dialog === "unlock") {
            setSuccess(translate("users.unblock.send_email_password", {
                "email": user.email
            }) as string);
        }

        setUser(undefined);
        setDialog(undefined);
    };

    const onDelete = () => {
        if (!user) return;

        load();
        setSuccess(translate("users.delete.success", {
            "first_name": user.first_name,
            "last_name": user.last_name,
            "email": user.email
        }) as string);
        setUser(undefined);
        setDialog(undefined);
    };

    const onResendEmail = () => {
        resendSubscribeEmail(tenantId, user!.id).then((response) => {
            setSuccess(translate("resend_suscription_notification.success") as string);
        }).catch((error) => {
            setStatus(error.message);
        });
        setActivatePopup(false);
        setUser(undefined);
    };

    const onError = (error: string) => {
        setError(error);
        setUser(undefined);
        setDialog(undefined);
    };

    const onCancel = () => {
        setUser(undefined);
        setDialog(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setUser(undefined);
    };

    const showActivatePopup = () => {
        setAnchorEl(null);
        setActivatePopup(true);
    };

    const hideActivatePopup = () => {
        setActivatePopup(false);
        setUser(undefined);
    };

    const showResendEmailPopup = () => {
        setAnchorEl(null);
        setResendEmailPopup(true);
    };

    const hideResendEmailPopup = () => {
        setResendEmailPopup(false);
        setUser(undefined);
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickedRow = (user: User) => {
        if (!isReaderOnly) {
            let qs = queryString.parse(window.location.search);
            qs["search"] = workingParams.search;
            qs["role_id"] = workingParams.role_id;
            qs["page"] = "1";

            let his = window.location.pathname + "?" + queryString.stringify(qs);
            history.push(his);
            setRedirect(getUrlUser(user));
            setUser(undefined);
        }
    };

    const getUrlUser = (user: User) => {
        if (user.id === context.session?.user.id) {
            return "/profile";
        }

        let url = "";
        if (match.params.providerId) {
            url = `/providers/${match.params.providerId}`;
        } else if (context.session?.provider?.id) {
            url = `/providers/${context.session?.provider?.id}`;
        } else if (match.params.customerId) {
            url = `/customers/${match.params.customerId}`;
        } else if (context.session?.customer?.id) {
            url = "";
        } else if (params.reference_view === "TENANT_PROVIDERS_USERS") {
            url = `/tenant/${tenantId}/providers/${user.provider_id}`;
        } else if (tenantId && context.isGranted("UsersUpdate")) {
            url = `/tenants-admin/${tenantId}`;
        } else if (tenantId) {
            url = `/tenants/${tenantId}`;
        }
        return `${url}/users/${user.id}/edit`;
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }
    const onOpenEmailModal = () => {
        setOpenEmailModal(true);
    };

    const onCloseEmailModal = () => {
        setOpenEmailModal(false);
    };

    const onSuccessEmail = (user: UserImpersonalize) => {
        setOpenEmailModal(false);
        setUserImpersonalize(user);
        setOpenImpersonalizeModal(true);
    };

    const onCloseImpersonalizeModal = () => {
        setOpenImpersonalizeModal(false);
        setUserImpersonalize(undefined);
    };

    const onSuccessImpersonalize = (success: string) => {
        setOpenImpersonalizeModal(false);
        setSuccess(success);
    };

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const getRoleName = (role: Role) => {
        if (role.editable && role.description) {
            return role.description;
        } else {
            return translate(`users.roles.${role.id}`) as string;
        }
    }

    const onCloseImportDialog = () => {
        setImportUsersSummary(undefined);
        setGridAnchorEl(null);
        fileInput.current!.value = "";
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        setUploading(true);
        importUsers(context.session!.tenant!.id, event.target.files[0]).then((response) => {
            setImportUsersSummary(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setUploading(false);
            fileInput.current!.value = "";
        });
    }

    const openFileImportUsers = () => {
        fileInput.current!.click();
        setGridAnchorEl(null);
    }
    
    return (
        <Pagination title={ params.reference_view === "TENANT_PROVIDERS_USERS" ? translate("users.tenant_provider.title") : translate("users.title")}
            subtitle={data?.tenant?.name || data?.metadata?.provider?.name || data?.metadata?.customer?.name}
            icon={<GroupIcon />}
            page={page} pageSize={pageSize} count={data && data.items ? data.items.length : 0} total={data && data.total ? data.total : 0}
            backButton={!!match.params.providerId} backTo={match.params.providerId ? `/providers/${match.params.providerId}` : undefined}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["UsersCreate", "TenantsUsersCreate", "OwnerProvidersUsersCreate", "ProviderUsersCreate", "CustomerUsersCreate"])
                            && params.reference_view !== "TENANT_PROVIDERS_USERS" ?
                            (<Grid item xs="auto">
                                <Link to={createUrl}>
                                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                        <AddIcon />
                                    </Fab>
                                </Link>
                            </Grid>) : undefined}
                        {context.isGranted("TechnicalSupportUpdate") ?
                            <Grid item xs="auto">
                                <Fab color="primary" size="small" onClick={onOpenEmailModal} title={translate("users.impersonalize.title") as string}>
                                    <SupervisedUserCircleIcon />
                                </Fab>
                            </Grid>
                            : undefined}
                        {context.isGrantedAny(["TenantsUsersCreate", "UsersCreate"]) ?
                            <Grid item xs="auto">
                                <IconButton color="default" size="small" onClick={onClickedMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Grid> : undefined}
                    </Grid>
                </Grid>}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} md>
                        <ValidatedInput type="text" id="search" name="search" label={translate("users.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs md="auto">
                        <ValidatedInput type="text" id="role_id" name="role_id" label={translate("users.roles.title") as string}
                            margin="dense" disabled={false}
                            value={workingParams.role_id} onValueChanged={onFilterChanged}
                            options={roleIds}
                            optionLabels={roleNames}
                            emptyOption={translate("users.roles.all") as string} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data && data.items ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("users.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("users.name") as string,
                        converter: (user) => (
                            <div>
                                <Ellipsis text={`${user.first_name} ${user.last_name}`} lenght={0} uppercased={false} />
                                <br></br>
                                <Ellipsis text={user.email} lenght={0} uppercased={false} secondary />
                                <Hidden mdUp>
                                    <br></br>
                                    <Ellipsis text={user.role ? getRoleName(user.role) : "---"} lenght={0} uppercased={false} secondary />
                                </Hidden>
                            </div>
                        ),
                        xs: 10,
                        sm: 6,
                        md: 6,
                        lg: params.reference_view === "TENANT_PROVIDERS_USERS" ? 3 : 4,
                        xl: params.reference_view === "TENANT_PROVIDERS_USERS" ? 3 : 4
                    },
                    {
                        title: translate("providers.title") as string,
                        converter: (user) => user.metadata !== undefined && user.metadata.provider !== undefined ? user.metadata?.provider?.name : "",
                        xs: false,
                        sm: false,
                        md: false,
                        lg: params.reference_view === "TENANT_PROVIDERS_USERS" ? 2 : false,
                        xl: params.reference_view === "TENANT_PROVIDERS_USERS" ? 2 : false
                    },
                    {
                        title: translate("providers.phone") as string,
                        converter: (contact) => (contact.phone ? <NumberFormat value={contact.phone} format={phoneFormat(contact.phone)} displayType="text" /> : "---"),
                        xs: false,
                        sm: false,
                        md: false,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("users.roles.title") as string,
                        converter: (user) => user.role ? getRoleName(user.role) : "---",
                        xs: false,
                        sm: false,
                        md: 3,
                        lg: 3,
                        xl: 3
                    },
                    {
                        title: translate("users.portal_access") as string,
                        converter: (user) => translate(`users.status.${(user.blocked ? "blocked" : user.signup_code ? "inactive" : "active")}`),
                        xs: false,
                        sm: true,
                        md: 2,
                        lg: true,
                        xl: true
                    },
                    {
                        title: "",
                        converter: (user) => (
                            isReaderOnly ? undefined :
                                <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(user)}>
                                    <MoreVertIcon />
                                </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto",
                        md: 1,
                        lg: "auto",
                        xl: "auto",
                    }
                ]} />
            {user && anchorEl &&
                <UserMenu user={user}
                    tenantId={tenantId}
                    providerId={match.params.providerId}
                    urlUser={getUrlUser(user)}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onBlock={showDialog("block")}
                    onUnblock={showDialog("unblock")}
                    onDelete={showDialog("delete")}
                    onActivated={showActivatePopup}
                    onResendEmail={showResendEmailPopup}
                    onUnlock={showDialog("unlock")}
                    view={params.reference_view} />
            }
            {user && activatePopup &&
                <UserActivatePopup user={user} onClose={hideActivatePopup} />
            }
            {user && dialog === "block" &&
                <UserBlockPopup user={user} tenantId={params.reference_view === "TENANT_PROVIDERS_USERS" ? match.params.tenantId : tenantId}
                    providerId={params.reference_view === "TENANT_PROVIDERS_USERS" ? user.provider_id : undefined}
                    onCompleted={onBlocked}
                    onError={onError}
                    onCancelled={onCancel} />
            }
            {user && dialog === "unblock" &&
                <UserUnblockPopup user={user} tenantId={params.reference_view === "TENANT_PROVIDERS_USERS" ? match.params.tenantId : tenantId}
                    providerId={params.reference_view === "TENANT_PROVIDERS_USERS" ? user.provider_id : undefined}
                    resetPassword={false}
                    onCompleted={onUnblocked}
                    onError={onError}
                    onCancelled={onCancel} />
            }
            {user && dialog === "unlock" &&
                <UserUnblockPopup user={user} tenantId={tenantId}
                    resetPassword={true}
                    onCompleted={onUnblocked}
                    onError={onError}
                    onCancelled={onCancel} />
            }
            {user && dialog === "delete" &&
                <UserDeletePopup user={user}
                    tenantId={tenantId}
                    providerId={context.session?.provider?.id ?? match.params.providerId}
                    customerId={context.session?.customer?.id ?? match.params.customerId}
                    onCompleted={onDelete}
                    onError={onError}
                    onCancelled={onCancel} />
            }
            {openEmailModal &&
                <SearchByEmailPopup onSuccess={onSuccessEmail} onClose={onCloseEmailModal} />
            }
            {openImpersonalizeModal && userImpersonalize &&
                <ImpersonalizePopup user={userImpersonalize} onSuccess={onSuccessImpersonalize} onClose={onCloseImpersonalizeModal} />
            }
            {user && resendEmailPopup && (
                <ConfirmationPopup
                    doAction={onResendEmail}
                    onClose={hideResendEmailPopup}
                    title={translate("buttons.resend_suscribe_email") as string}
                    message={translate("resend_suscription_notification.message", { "user": user.email }) as string}
                    secondary={translate("resend_suscription_notification.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            { gridAnchorEl && 
             <UserGeneralMenu
                anchor= {gridAnchorEl}
                onImportUsers= {openFileImportUsers}
                onClose= { () => {
                    setGridAnchorEl(null);
                }}
             />
            }
            {importUsersSummary && (
                <ImportUsersResumePopup summaryImport={importUsersSummary} onClose={onCloseImportDialog} />
            )}
            <input type="file" onChange={handleUpload} ref={fileInput} style={{ display: "none" }} accept=".xlsx" />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Pagination>
    );
}