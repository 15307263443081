import React, { ReactNode } from "react";
import { Grid, Card, CardHeader, Avatar, Box } from "@material-ui/core";
import { green, blue, orange, red } from "@material-ui/core/colors";
import InfoIcon from "@material-ui/icons/InfoTwoTone";
import CheckCircleIcon from "@material-ui/icons/CheckCircleTwoTone";
import WarningIcon from "@material-ui/icons/WarningTwoTone";
import ErrorIcon from "@material-ui/icons/ErrorTwoTone";

interface AlertProps {
    variant: "info" | "success" | "warning" | "error";
    title: ReactNode;
    subheader?: ReactNode;
    margin?: number;
    button?: ReactNode;
    elevation?: number;
    plain?: boolean;
}

export default function Alert(props: AlertProps) {

    const getIcon = (): ReactNode => {
        switch (props.variant) {
            case "info":
                return <InfoIcon />
            case "success":
                return <CheckCircleIcon />
            case "warning":
                return <WarningIcon />
            case "error":
                return <ErrorIcon />
        }
    };

    const getColor = (): string => {
        switch (props.variant) {
            case "info":
                return blue[500];
            case "success":
                return green[500];
            case "warning":
                return orange[700];
            case "error":
                return red[800];
        }
    };

    return (
        <Grid item xs={12}>
            <Box mb={props.margin === undefined ? 2 : props.margin}>
                <Card variant={props.plain ? "outlined" : "elevation"} elevation={props.elevation || 2} style={props.plain ? { borderColor: getColor() } : undefined}>
                    <CardHeader
                        avatar={
                            <Avatar style={{ backgroundColor: getColor() }}>
                                {getIcon()}
                            </Avatar>
                        }
                        title={props.title}
                        subheader={props.subheader}
                        action={props.button}>
                    </CardHeader>
                </Card>
            </Box>
        </Grid>
    );
}