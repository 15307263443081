import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import ActivateIcon from "@material-ui/icons/DeleteTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface TeamMenuProps {
    tenantId?: string;
    urlTeam: string;
    anchor: HTMLElement | null;
    onClose(): any;
    onInactivated(): any;
}

export default function TeamMenu(props: TeamMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGrantedAny(["TeamsUpdate"]) &&
                <MenuItem button component={Link} to={props.urlTeam + `/${"edit"}`} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TeamsRead"]) &&
                <MenuItem button component={Link} to={props.urlTeam} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <InfoIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_details")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["TeamsDelete"]) &&
                <MenuItem button onClick={props.onInactivated} dense>
                    <ListItemIcon>
                        <ActivateIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}