import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, IconButton } from "@material-ui/core";
import SocialContributionIcon from "@material-ui/icons/HomeWork";

import { getSocialContribution, updateSocialContribution, getSocialContributionFileUrl } from "../api/SocialContributionAPI";
import { getSocialContribution as getProviderSocialContribution, updateSocialContribution as updateProviderSocialContribution, getSocialContributionFileUrl as getProviderSocialContributionFileUrl } from "../api/ProviderSocialContributionAPI";
import translate from "../i18n/Translator";
import { SocialContribution, SocialContributionFileType, SocialContributionRequest } from "../model/SocialContribution";

import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import { InputRef, isValid } from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import DateFormat from "../components/DateFormat";
import UploaderInput from "../components/UploaderInput";
import ConfirmationPopup from "../components/ConfirmationPopup";

export default function SocialContributionForm({ match }: RouterParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [disabledBankVoucher, setDisabledBankVoucher] = useState(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<SocialContributionRequest>({} as SocialContributionRequest);
    const [validations, setValidations] = useState({} as any);
    const [socialContribution, setSocialContribution] = useState<SocialContribution>();
    const [imss, setImss] = useState<string>();
    const [infonavit, setInfonavit] = useState<string>();
    const [bankVoucher, setBankVoucher] = useState<string>();
    const [openValidateDialog, setOpenValidateDialog] = useState<boolean>();
    const [openValidatePdfDialog, setOpenValidatePdfDialog] = useState<string>();
    const [isRejected, setIsRejected] = useState<boolean>();

    const promiseGet = () => {
        if (providerId) {
            return getProviderSocialContribution(tenantId, providerId, match.params.socialContributionId);
        }
        return getSocialContribution(tenantId, match.params.socialContributionId);
    };

    const promiseGetUrl = (fileType: SocialContributionFileType) => {
        if (providerId) {
            return getProviderSocialContributionFileUrl(tenantId, providerId, match.params.socialContributionId, fileType);
        }
        return getSocialContributionFileUrl(tenantId, match.params.socialContributionId, fileType);
    };

    const promiseUpdate = (request : SocialContributionRequest) => {
        if (providerId) {
            return updateProviderSocialContribution(tenantId, providerId, match.params.socialContributionId, request);
        }
        return updateSocialContribution(tenantId, match.params.socialContributionId, request);
    };

    const onCancelValidate = () => {
        setOpenValidateDialog(false);
    };

    const onCancelValidatePdf = () => {
        setOpenValidatePdfDialog(undefined);
    };

    const onAcceptValidate = () => {
        setOpenValidateDialog(false);
        setSubmitting(true);

        promiseUpdate({ ...request, "force_voucher": true }).then((socialContribution) => {
            history.push("/contributions/social");
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });

    };

    const load = () => {
        setStatus("loading");
        promiseGet().then((socialContribution) => {
            setSocialContribution(socialContribution);
            setDisabledBankVoucher(socialContribution.valid_bank_voucher);
            setDisabled(socialContribution.status === "COMPLETED" && (!socialContribution.required_infonavit || !!socialContribution.infonavit_cfdi_id));

            Promise.all([
                promiseGetUrl("imss"),
                promiseGetUrl("infonavit"),
                promiseGetUrl("bank_voucher"),
            ]).then(urls => {
                setImss(socialContribution.imss_cfdi_id ? urls[0] : undefined);
                setInfonavit(socialContribution.infonavit_cfdi_id ? urls[1] : undefined);
                setBankVoucher(socialContribution.bank_voucher ? urls[2] : undefined);
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setStatus("loaded");
                setIsRejected(socialContribution !== undefined &&
                    socialContribution.refuse_reason_bank_voucher !== undefined &&
                    socialContribution.refuse_reason_bank_voucher !== "");
            });
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, match.params.socialContributionId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }

        setSubmitting(true);
        promiseUpdate(request).then((socialContribution) => {
            history.push("/contributions/social");
        }).catch((error) => {
            setSubmitting(false);
            if(request.bank_voucher){
                if (request.bank_voucher.endsWith(".pdf")) {
                    setOpenValidatePdfDialog(error.message);
                } else {
                    setOpenValidateDialog(true);
                }
            } else {
                setError(error.message);
            }
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("social_contributions.edit")}
                        subtitle={(
                            <DateFormat date={socialContribution?.date} format="MMMM YYYY" />
                        )}
                        icon={<SocialContributionIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                {bankVoucher && !isRejected && <>
                                    <Grid item xs={6}>
                                        <Box pl={2}>
                                            <Typography variant="body2">{translate("social_contributions.bank_voucher")}</Typography>
                                        </Box>
                                    </Grid> 
                                    <Grid item xs={6}>
                                        <IconButton aria-label="add" color="primary" size="small" href={bankVoucher} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                        </IconButton>
                                    </Grid>
                                    </> }
                                {(!bankVoucher || isRejected ) &&
                                    <UploaderInput id="bank_voucher" name="bank_voucher"
                                        value={request.bank_voucher}
                                        label={translate("social_contributions.bank_voucher") as string}
                                        required={false} disabled={submitting || disabledBankVoucher}
                                        margin={margin}
                                        acceptExtension=".pdf,.jpg,.png" path={`tenants/${tenantId}/bank_voucher`}
                                        newFileName={socialContribution?.year+"-"+socialContribution?.month+"_IMSS_CompBanco"}
                                        onValueChanged={hasChanged} />
                                }
                                {imss ? <>
                                    <Grid item xs={6}>
                                        <Box pl={2}>
                                            <Typography variant="body2">{translate("social_contributions.imss")}</Typography>
                                        </Box>
                                    </Grid> 
                                    <Grid item xs={6}>
                                        <IconButton aria-label="add" color="primary" size="small" href={imss} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                        </IconButton>
                                    </Grid>
                                    </> : 
                                        <UploaderInput id="imss" name="imss"
                                            value={imss || request.imss}
                                            label={translate("social_contributions.imss") as string}
                                            required={false} disabled={submitting || disabled}
                                            margin={margin}
                                            acceptExtension=".xml" path={`tenants/${tenantId}/imss`}
                                            newFileName={socialContribution?.year+"-"+socialContribution?.month+"_IMSS_CFDI"}
                                            onValueChanged={hasChanged} />
                                }
                                {socialContribution && socialContribution.required_infonavit && <>
                                    {infonavit ? <>
                                        <Grid item xs={6}>
                                            <Box pl={2}>
                                                <Typography variant="body2">{translate("social_contributions.infonavit")}</Typography>
                                            </Box>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <IconButton aria-label="add" color="primary" size="small" href={infonavit} target="_blank" rel="noopener noreferrer">
                                                <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                            </IconButton>
                                        </Grid>
                                        </> : 
                                            <UploaderInput id="infonavit" name="infonavit"
                                                value={request.infonavit}
                                                label={translate("social_contributions.infonavit") as string}
                                                required={false} disabled={submitting || disabled}
                                                margin={margin}
                                                acceptExtension=".xml" path={`tenants/${tenantId}/infonavit`}
                                                newFileName={socialContribution?.year+"-"+socialContribution?.month+"_INFONAVIT_CFDI"}
                                                onValueChanged={hasChanged} />
                                    }
                                </>}
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting || disabled}>
                                                    {translate("buttons.update")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
            {request && openValidateDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.title") as string}
                    message={translate("confirm_contribution.voucher.message") as string}
                    secondary={translate("confirm_contribution.voucher.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidate}
                    doAction={onAcceptValidate}
                />
            }
            {request && openValidatePdfDialog &&
                <ConfirmationPopup
                    title={translate("confirm_contribution.voucher.pdf.title") as string}
                    message={translate("confirm_contribution.voucher.pdf.message", { "message": openValidatePdfDialog}) as string}
                    secondary={translate("confirm_contribution.voucher.pdf.secondary") as string}
                    button={translate("buttons.next") as string}
                    onClose={onCancelValidatePdf}
                    doAction={onAcceptValidate}
                />
            }
        </Grid>
    );
}
