import { callAPI, HTTPMethod } from "./API";
import { WebhookEvents, WebhookEventsQueryParams, WebhookEventTries, WebhookEventTriesQueryParams } from "../model/WebhookEvent";

const basePath = (tenantId: string) => `/tenants/${tenantId}/webhooks-events`;

export async function listEvents(tenantId: string, webhookId: string, page: number, offset: number, params: WebhookEventsQueryParams): Promise<WebhookEvents> {
    let query  = {
            event: params.event || "",
            pageSize: page.toString(),
            offset: offset.toString()
    } as Record<string, string>;

    if(params.event_start_date && params.event_end_date){
        query = { ...query,
            eventStartDate: params.event_start_date,
            eventEndDate: params.event_end_date };
    }
    
    return await callAPI(`${basePath(tenantId)}/${webhookId}`, {
        method: HTTPMethod.GET,
        query: query
    });
}

export async function listEventTries(tenantId: string, eventId: string, page: number, offset: number, params: WebhookEventTriesQueryParams): Promise<WebhookEventTries> {
    let query  = {
            search: params.search || "",
            pageSize: page.toString(),
            offset: offset.toString()
    } as Record<string, string>;
    
    return await callAPI(`${basePath(tenantId)}/${eventId}/tries`, {
        method: HTTPMethod.GET,
        query: query
    });
}