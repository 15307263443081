import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SocialContributionIcon from "@material-ui/icons/HomeWork";

import { listContributionsToValidate } from "../api/ContributionAPI";
import { listContributionsToValidate as listContributionsProviderToValidate } from "../api/ProviderContributionAPI";
import { AppContext } from "../context/AppContext";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import Gridable, { GridableColumn } from "../components/Gridable";
import DateFormat from "../components/DateFormat";
import ContributionToValidateMenu from "./ContributionToValidateMenu";
import { ContributionToValidate, ContributionsToValidate } from "../model/Contribution";
import { approveBankVoucher, refuseBankVoucher as refuseSatBankVoucher, refusePayment, approvePayment} from "../api/SatContributionAPI"
import { approveBankVoucher as approveSocialBankVoucher, refuseBankVoucher as refuseSocialBankVoucher, approveSipare, refuseSipare } from "../api/SocialContributionAPI"
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";
import CustomBackdrop from "../components/CustomBackdrop";
import NumberFormat from "react-number-format";

export function ContributionsToValidateTenant(){
    return <ContributionsToValidateList />;
}

type Popup = "refuse_sat" | "refuse_sat_voucher" | "refuse_sat_voucher_complementary" | "refuse_sat_complementary" | "refuse_social" | "refuse_sipare";

export default function ContributionsToValidateList() {
    const context = useContext(AppContext);
    const tenantId = context.session!.tenant!.id;
    const providerId = context.session?.provider?.id;
    const [submitting, setSubmitting] = useState(false);
    const [enableButton, setEnableButton] = useState(true);
    const query = new URLSearchParams(useLocation().search);
    const monthS = query.get("month");
    const yearS = query.get("year");
    const statusS = query.get("status");
    const employerNumberS = query.get("employerNumber");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ContributionsToValidate>();

    const [contribution, setContribution] = useState<ContributionToValidate>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [dialog, setDialog] = useState<Popup>();
    const [reason, setReason] = useState("");
    const [valid, setValid] = useState(false);

    const promiseGet = () => {
        const offset = getOffset(page, pageSize);
        if (providerId) {
            return listContributionsProviderToValidate(tenantId, providerId, pageSize, offset);
        }
        return listContributionsToValidate(tenantId, pageSize, offset);
    };

    const load = () => {
        promiseGet().then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId, page, pageSize, monthS, yearS, statusS, employerNumberS]);

    useEffect(() => { }, []);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onClickedOptions = (contribution: ContributionToValidate) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setContribution(contribution);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setContribution(undefined);
    };

    const onApprove = (complementary: boolean) => {
        setAnchorEl(null);
        if (!contribution) return;

        const satContributionId = contribution.id;
        if (!satContributionId) return;

        setSubmitting(true);
        approvePayment(tenantId, satContributionId, complementary).then((result) => {
            if (complementary) {
                setSuccess(translate("sat_contributions.approved_complementary") as string);
            } else {
                setSuccess(translate("sat_contributions.approved") as string);
            }
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const onApproveVoucher = (complementary: boolean) => {
        setAnchorEl(null);
        if (!contribution) return;

        const satContributionId = contribution.id;
        if (!satContributionId) return;

        setSubmitting(true);
        approveBankVoucher(tenantId, satContributionId, complementary).then((result) => {
            if (complementary) {
                setSuccess(translate("sat_contributions.approved_complementary") as string);
            } else {
                setSuccess(translate("sat_contributions.approved") as string);
            }
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const onApproveSocial = () => {
        setAnchorEl(null);
        if (!contribution) return;

        const socialContributionId = contribution.id;
        if (!socialContributionId) return;

        setSubmitting(true);
        approveSocialBankVoucher(tenantId, socialContributionId).then((result) => {
            setSuccess(translate("social_contributions.approved") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const onApproveSocialSipare = () => {
        setAnchorEl(null);
        if (!contribution) return;

        const socialContributionId = contribution.id;
        if (!socialContributionId) return;

        setSubmitting(true);
        approveSipare(tenantId, socialContributionId).then((result) => {
            setSuccess(translate("social_contributions.approved") as string);
            load();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
        });
    };

    const handleRefuse = (variant: Popup) => {
        if (!contribution) return;

        setAnchorEl(null);
        setValid(false);
        setReason("");
        setAnchorEl(null);
        setDialog(variant);
    };

    const onRefuseSocial = () => handleRefuse("refuse_social");

    const onRefuseSocialSipare = () => handleRefuse("refuse_sipare");

    const onRefuse = (complementary: boolean) => {
        if (complementary) {
            handleRefuse("refuse_sat_complementary");
        } else {
            handleRefuse("refuse_sat");
        }
    }

    const onRefuseVoucher = (complementary: boolean) => {
        if (complementary) {
            handleRefuse("refuse_sat_voucher_complementary");
        } else {
            handleRefuse("refuse_sat_voucher");
        }
    }

    const confirm = () => {
        if (!valid) return;
        if (!contribution) return;

        let contributionId = contribution.id || "---";
        switch(dialog){
            case "refuse_social":
                confirmRefuseSocial(contributionId);
            break;
            case "refuse_sipare":
                confirmRefuseSocialSipare(contributionId);
            break;
            case "refuse_sat":
                confirmRefuse(contributionId, false);
            break;
            case "refuse_sat_voucher":
                confirmRefuseVoucher(contributionId, false);
            break;
            case "refuse_sat_complementary":
                confirmRefuse(contributionId, true);
            break;
            case "refuse_sat_voucher_complementary":
                confirmRefuseVoucher(contributionId, true);
            break;
        }
    };

    const confirmRefuse = (satContributionId: string, complementary: boolean) => {
        setDialog(undefined);
        setSubmitting(true);
        refusePayment(tenantId, satContributionId, {
            reason: reason,
            complementary: complementary
        }).then((result) => {
            if (complementary) {
                setSuccess(translate("sat_contributions.refused_complementary") as string);
            } else {
                setSuccess(translate("sat_contributions.refused") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
            load();
        });
    };

    const confirmRefuseVoucher = (satContributionId: string, complementary: boolean) => {
        setDialog(undefined);
        setSubmitting(true);
        refuseSatBankVoucher(tenantId, satContributionId, {
            reason: reason,
            complementary: complementary
        }).then((result) => {
            if (complementary) {
                setSuccess(translate("sat_contributions.refused_complementary") as string);
            } else {
                setSuccess(translate("sat_contributions.refused") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
            load();
        });
    };

    const confirmRefuseSocial = (socialContributionId: string) => {
        setDialog(undefined);
        setSubmitting(true);
        refuseSocialBankVoucher(tenantId, socialContributionId, {
            reason: reason,
            complementary: false
        }).then((result) => {
            setSuccess(translate("social_contributions.refused") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
            load();
        });
    };

    const confirmRefuseSocialSipare = (socialContributionId: string) => {
        setDialog(undefined);
        setSubmitting(true);
        refuseSipare(tenantId, socialContributionId, {
            reason: reason,
            complementary: false
        }).then((result) => {
            setSuccess(translate("social_contributions.refused") as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setSubmitting(false);
            setContribution(undefined);
            load();
        });
    };


    const onChangedReason = (name: string, value: string, inputRef: InputRef) => {
        setReason(value);
        setValid(inputRef.valid);
        setEnableButton(value === undefined || value === "");
    }

    const onCloseDialog = () => {
        setAnchorEl(null);
        setContribution(undefined);
        setDialog(undefined)
    };

    const onCloseSnackbars = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const getColumns = () => {
        const columns = [
            {
                title: translate("contributions.to_validate.columns.provider"),
                converter: (contribution) => (
                    <>
                        {contribution.provider_name}
                        <Typography variant="caption" color="textSecondary" component="h6">
                            {contribution.provider_rfc}
                        </Typography>
                    </>
                ),
                xs: 2,
            },
            {
                title: translate("contributions.to_validate.columns.period_type"),
                converter: (contribution) => (
                    <>
                        <DateFormat date={contribution.date} format="MMMM YYYY" />
                        <Typography variant="caption" color="textSecondary" component="h6">
                            {translate(`contributions.to_validate.types.${contribution.type}`)}
                        </Typography>
                    </>
                ),
                xs: 2,
            },
            {
                title: translate("contributions.to_validate.columns.status"),
                converter: (contribution) => 
                    <>
                        {translate(`contributions.to_validate.status.${contribution.status}`)}
                        {contribution.reason_rejected && 
                            <Typography variant="caption" color="textSecondary" component="h6">
                                {contribution.reason_rejected}
                            </Typography>
                        }
                    </>,
                xs: 2,
            },
            {
                title: translate("contributions.to_validate.columns.amount"),
                converter: (contribution) => <NumberFormat value={contribution.amount}
                prefix="$ " decimalScale={2} fixedDecimalScale={true} thousandSeparator=","
                displayType="text" />,
                xs: 2,
            },
            {
                title: translate("contributions.to_validate.columns.additional"),
                converter: (contribution) => contribution.additional_info,
                xs: 2,
            },
            {
                title: (
                    <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                        <MoreVertIcon />
                    </IconButton>
                ),
                converter: (socialContribution) => (
                    <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(socialContribution)}>
                        <MoreVertIcon />
                    </IconButton>
                ),
                justify: "flex-end",
                xs: 1
            }
        ] as GridableColumn<ContributionToValidate>[];
        if(providerId === undefined){
            return columns;
        }
        return columns.filter(col => col.title !== translate("contributions.to_validate.columns.provider"));
    };

    return (
        <Pagination title={translate("contributions.to_validate.title")} icon={<SocialContributionIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} >
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("social_contributions.empty") as string}
                columns={getColumns()} />
            {contribution && anchorEl &&
                <ContributionToValidateMenu contribution={contribution} anchor={anchorEl} onClose={onCloseOption}
                    onApprove={onApprove}
                    onRefuse={onRefuse}
                    onApproveVoucher={onApproveVoucher}
                    onRefuseVoucher={onRefuseVoucher}
                    onApproveSocial={onApproveSocial}
                    onRefuseSocial={onRefuseSocial}
                    onApproveSocialSipare={onApproveSocialSipare}
                    onRefuseSocialSipare={onRefuseSocialSipare}
                />
            }
            {dialog && (
                <DialogPopup open
                    title={translate("contributions.refuse.title")}
                    onClose={onCloseDialog}
                    button={(
                        <Button type="submit" variant="contained" color="primary" disabled={submitting || enableButton}>
                            {translate("buttons.send")}
                        </Button>
                    )}
                    onSubmit={confirm}>
                    <Grid container>
                        <Grid item xs={12}>
                            {translate("contributions.refuse.description")}
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedInput type="text"
                                id="reason"
                                name="reason"
                                label={translate("contributions.refuse.reason") as string}
                                onValueChanged={onChangedReason}
                                value={reason}
                                maxRows={5}
                                rows={5}
                                maxLength={200}
                                required />
                        </Grid>
                    </Grid>
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={submitting} />
        </Pagination>
    );
}