import React, { useState } from "react";
import { Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card } from "@material-ui/core";
import { AttachPaymentCfdis, PaymentCfdiValidation } from "../../model/Payment";
import translate from "../../i18n/Translator";
import Gridable from "../../components/Gridable";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Redirect } from "react-router-dom";


interface AttachPaymentCfdisPopupProps {
    data: AttachPaymentCfdis;
    onClose(): any;
}

export function AttachPaymentCfdisPopup(props: AttachPaymentCfdisPopupProps) {

    const [redirect, setRedirect] = useState<string>();

    if (redirect) {
        return <Redirect to={redirect} />
    }

    const onAccept = () => {
        if (props.data.processed === props.data.validations.length) {
            setRedirect(`/payment/pending-cfdi/success/${translate("cfdis.reps.all_attach_success") as string}`);
        } else {
            props.onClose();
        }
    }

    return (
        <Dialog open fullWidth={true}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="DialogForm">

            <DialogTitle id="alert-dialog-title" disableTypography>
                <Typography variant="subtitle1">
                    {translate("cfdis.reps.title") as string}
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Typography variant="body2" align="left">
                    {translate("cfdis.reps.description", { processed: props.data.processed, total: props.data.validations.length })}
                </Typography>
                <br />
                <Grid item xs={12}>
                    <Card variant="outlined" >
                        <Gridable
                            items={props.data.validations}
                            loading={false}
                            border
                            empty={translate("cfdis.reps.empty_state") as string}
                            columns={[
                                {
                                    title: translate("cfdis.reps.xml_colum") as string,
                                    converter: (validation) => <FileNameCell notFound={validation.code === "XML_NOT_FOUND"} fileName={validation.name} sufix={"xml"} />,
                                    fullWidth: true,
                                    xs: true
                                },
                                {
                                    title: translate("cfdis.reps.pdf_colum") as string,
                                    converter: (validation) => <FileNameCell notFound={validation.code === "PDF_NOT_FOUND"} fileName={validation.name} sufix={"pdf"} />,
                                    fullWidth: true,
                                    xs: true
                                },
                                {
                                    title: translate("cfdis.reps.status") as string,
                                    converter: (validation) => <StatusCell item={validation} />,
                                    fullWidth: true,
                                    xs: true
                                }
                            ]}
                        />
                    </Card>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccept} variant="contained" color="primary" size="medium" >
                    {translate("buttons.accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface FileNameProps {
    notFound: boolean;
    fileName: string;
    sufix: string
}

function FileNameCell(props: FileNameProps) {
    return (
        <Typography style={props.notFound ? { color: "gray", wordBreak: 'break-word', fontSize: "small" } : { wordBreak: 'break-word', fontSize: "small" }}>
            {`${props.fileName}.${props.sufix}`}
        </Typography>
    );
}

interface StatusCellProps {
    item: PaymentCfdiValidation;
}

function StatusCell(props: StatusCellProps) {

    const validValue = props.item.code === "VALID";

    return (
        <Grid container spacing={0}>
            <Grid item xs={true}>
                <StatusIcon valid={validValue} />
            </Grid>
            <Grid item xs={10}>
                <Typography style={validValue ? { color: "green", wordBreak: 'break-word', fontSize: "small" } : { color: "red", wordBreak: 'break-word', fontSize: "small" }}>
                    {translate(`cfdis.reps.codes.${props.item.code}`) as string}
                </Typography>
            </Grid>
        </Grid>
    );
}

interface StatusIconProps {
    valid: boolean;
}

export function StatusIcon(props: StatusIconProps) {
    return (
        <>
            {props.valid && (<CheckCircleIcon style={{ color: "green" }} fontSize="small" />)}
            {!props.valid && (<ErrorIcon style={{ color: "red" }} fontSize="small" />)}
        </>
    );
}