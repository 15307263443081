import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import ActivateIcon from "@material-ui/icons/DeleteTwoTone";
import ToSendIcon from "@material-ui/icons/SendTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";
import CancelIcon from '@material-ui/icons/CancelTwoTone';

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface RefundMenuProps {
    anchor: HTMLElement | null;
    view: string;
    refundId: string;
    fromDetail: boolean;
    onClose(): any;
    onDelete(): any;
    onEdit(): any;
    onSend(): any;
    onCancel(): any;
}

export default function RefundMenu(props: RefundMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            
            {context.isGrantedAny(["RefundsRead", "RefundsCxcRead"]) && (props.view === 'archive' || props.view === 'to_authorize') && !props.fromDetail &&
                <MenuItem button component={Link} to={`/cfdis/${props.refundId}/details?referer=${props.view+"_refunds"}`} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <InfoIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_details")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RefundsUpdate", "RefundsCxcRead"]) && (props.view === 'to_send' || props.view === 'to_authorize') &&
                <MenuItem button onClick={props.onEdit} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RefundsUpdate", "RefundsCxcRead"]) && props.view === 'to_send' && !props.fromDetail &&
                <MenuItem button onClick={props.onSend} dense>
                    <ListItemIcon>
                        <ToSendIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.send")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RefundsUpdate"]) && props.view === 'to_authorize' &&
                <MenuItem button onClick={props.onCancel} dense>
                    <ListItemIcon>
                        <CancelIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.cancel")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RefundsDelete",  "RefundsCxcDelete"]) && props.view === 'to_send' &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <ActivateIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
}