import React from "react";
import { Box, Card, Grid, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import translate from "../i18n/Translator";
import { Employee } from "../model/Employee";
import DateFormat from "../components/DateFormat";

interface EmployeeCardProps {
    employee: Employee;
}

export default function EmployeeCard(props: EmployeeCardProps) {
    return (
        <Box pt={2} px={2}>
            <Card variant="outlined" >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <List dense disablePadding>
                            <ListSubheader>{translate("employees.info")}</ListSubheader>
                            <ListItem>
                                <ListItemText primary={props.employee.name} secondary={translate("employees.name")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={props.employee.rfc} secondary={translate("employees.rfc")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={props.employee.nss} secondary={translate("employees.nss")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={props.employee.bank || "---"} secondary={translate("employees.bank")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={props.employee.bank_account || "---"} secondary={translate("employees.bank_account")} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <List dense disablePadding>
                            <ListSubheader>{translate("employees.job")}</ListSubheader>
                            <ListItem>
                                <ListItemText primary={props.employee.job_title || "---"} secondary={translate("employees.job_title")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={props.employee.department || "---"} secondary={translate("employees.department")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={props.employee.periodicity ? translate(`employees.periodicity.${props.employee.periodicity}`) : "---"}
                                    secondary={translate("employees.periodicity.title")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={(
                                    <DateFormat date={props.employee.first_cfdi} format="DD/MM/YYYY" />
                                )} secondary={translate("employees.first_cfdi")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={(
                                    <DateFormat date={props.employee.last_cfdi} format="DD/MM/YYYY" />
                                )} secondary={translate("employees.last_cfdi")} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
}