import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Grid, Button, Typography, Box, Hidden } from "@material-ui/core";
import ConnectorIcon from "@material-ui/icons/SettingsInputHdmiTwoTone";

import { createConnector, getConnector, updateConnector, getTransformerUrl } from "../api/ConnectorAPI";
import translate from "../i18n/Translator";
import { Connector, ConnectorRequest, Format, FAMILIES, FORMATS, DIRECTIONS, getFormats } from "../model/Connector";
import Progress from "../components/Progress";
import { ErrorSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef, isValid } from "../components/ValidatedInput";
import UploaderInput from "../components/UploaderInput";
import SimpleSwitch from "../components/SimpleSwitch";
import { RouterParams } from "../router/RouterParams";
import queryString from "query-string";

export default function ConnectorForm({ match }: RouterParams) {
    const history = useHistory();
    const isEdit = !!match.params.connectorId;
    const margin = "dense";
    const familyLabels = FAMILIES.map(v => translate(`connectors.family.${v}`) as string);
    const directionLabels = DIRECTIONS.map(v => translate(`connectors.direction.${v}`) as string);

    const qs = queryString.parse(window.location.search);

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState(false);
    const [redirect, setRedirect] = useState<string>();
    const [hideTransformer, setHideTransformer] = useState(true);
    const [requiredTransformer, setRequiredTransformer] = useState(true);
    const [requiredName, setRequiredName] = useState(true);
    const [showSwitch, setShowSwitch] = useState(false);
    const [error, setError] = useState<string>();
    const [request, setRequest] = useState<ConnectorRequest>({ available_for_zip: false } as ConnectorRequest);
    const [validations, setValidations] = useState({} as any);
    const [formats, setFormats] = useState<string[]>(FORMATS);

    const submitPromise = (): Promise<Connector> => {
        const fixedRequest = { ...request, fields: request.fields?.filter(el => el.index !== undefined) };
        if (isEdit && match.params.connectorId) {
            return updateConnector(match.params.tenantId, match.params.connectorId, fixedRequest);
        }
        return createConnector(match.params.tenantId, fixedRequest);
    };

    useEffect(() => {
        setStatus("loading");
        if (isEdit) {
            const tenantId = match.params.tenantId;
            const connectorId = match.params.connectorId;
            getConnector(tenantId, connectorId).then((connector) => {
                setRequiredName(!!connector.tenant_id);
                getTransformerUrl(tenantId, connectorId, connector.transformer).then(transformer => {
                    setRequest({
                        direction: connector.direction,
                        family: connector.family,
                        format: connector.format,
                        plugin: connector.plugin,
                        fields: [],
                        transformer: transformer,
                        extensions: connector.extensions?.join(", ") || "",
                        name: connector.name,
                        lambda: connector.lambda,
                        available_for_zip: connector.available_for_zip,
                        remote_directory: connector.remote_directory
                    } as ConnectorRequest);
                    setStatus("loaded");
                }).catch(error => {
                    setStatus(error.message);
                });
            }).catch((error) => {
                setStatus(error.message);
            });
        } else {
            setStatus("loaded");
        }
        // eslint-disable-next-line
    }, [isEdit, match.params.tenantId, match.params.connectorId]);

    useEffect(() => {
        setHideTransformer(request.format === Format.PLUGIN || request.format === Format.LAMBDA || request.format === Format.JSON);
        setRequiredTransformer(request.format === Format.TXT || request.format === Format.CSV || request.format === Format.XLS || request.format === Format.XLSX);
        // eslint-disable-next-line
    }, [request.family, request.format, request.direction]);

    useEffect(() => {
        const formats = getFormats(request.family, request.direction);
        setFormats(formats);
        setShowSwitch(request.family === "CFDIS" && request.direction === "OUTPUT");

        if (request.family !== "CFDIS" || request.direction !== "OUTPUT") {
            setRequest(r => {
                return { ...r, available_for_zip: false };
            });
        }
    }, [request.family, request.direction]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value === "---" ? "" : value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedSwitch = (name: string, checked: boolean) => {
        setRequest({ ...request, available_for_zip: checked });
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid(validations)) {
            return;
        }
        setSubmitting(true);
        submitPromise().then((connector) => {
            qs["search_widget"] = undefined;
            setRedirect(`/tenants/${match.params.tenantId}/connectors?${queryString.stringify(qs)}`);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        });
    };

    const onClosedSnackbar = () => {
        setError(undefined);
    };

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "connectors.edit" : "connectors.new")} icon={<ConnectorIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="name" name="name"
                                        value={request.name}
                                        label={translate("connectors.name") as string}
                                        required={requiredName} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="family" name="family"
                                        value={request.family}
                                        label={translate("connectors.family") as string}
                                        options={FAMILIES} optionLabels={familyLabels}
                                        required disabled={submitting || !!match.params.connectorId}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="direction" name="direction"
                                        value={request.direction}
                                        label={translate("connectors.direction.title") as string}
                                        options={DIRECTIONS} optionLabels={directionLabels}
                                        required disabled={submitting || !!match.params.connectorId}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="format" name="format"
                                        value={request.format}
                                        label={translate("connectors.format") as string}
                                        options={formats}
                                        required disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Hidden xsUp={request.format !== Format.PLUGIN} implementation="css">
                                        <ValidatedInput type="text" id="plugin" name="plugin"
                                            value={request.plugin}
                                            label={translate("connectors.plugin") as string}
                                            required={request.format === Format.PLUGIN} disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                    </Hidden>
                                    <Hidden xsUp={Format.PLUGIN !== request.format} implementation="css">
                                        <ValidatedInput type="text" id="extensions" name="extensions"
                                            value={request.extensions}
                                            label={translate("connectors.extensions") as string}
                                            required={request.format === Format.PLUGIN} disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12}>
                                    {"AWS_S3" !== request.format && (
                                        <Hidden xsUp={hideTransformer} implementation="css">
                                            <UploaderInput id="transformer" name="transformer"
                                                value={request.transformer || ""}
                                                label={translate("connectors.transformer", { "format": (request.format === Format.XML ? "XSLT" : "BeanIO") }) as string}
                                                required={requiredTransformer} disabled={submitting}
                                                margin={margin} acceptExtension={request.format === Format.XML ? ".xslt" : ".xml"}
                                                onValueChanged={hasChanged} />
                                        </Hidden>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Hidden xsUp={request.format !== Format.LAMBDA} implementation="css">
                                        <ValidatedInput type="text" id="lambda" name="lambda"
                                            value={request.lambda || ""}
                                            label={translate("connectors.lambda") as string}
                                            required={request.format === Format.LAMBDA} disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                    </Hidden>
                                </Grid>
                                {showSwitch && (
                                    <Grid item xs={12}>
                                        <SimpleSwitch
                                            value=""
                                            label={translate("connectors.available_for_zip") as string}
                                            checked={request.available_for_zip ?? false}
                                            disabled={submitting}
                                            placement="end"
                                            onChanged={onChangedSwitch} />
                                    </Grid>
                                )}

                                {"AWS_S3" === request.format && (
                                    <Grid item xs={12}>
                                        <ValidatedInput type="text" id="remote_directory" name="remote_directory"
                                            value={request.remote_directory} label={translate("sap.settings.remote_dir_pattern") as string}
                                            required disabled={false}
                                            margin="dense"
                                            onValueChanged={hasChanged} />

                                        <ValidatedInput type="text" id="extensions" name="extensions"
                                            value={request.extensions}
                                            label={translate("connectors.extensions") as string}
                                            required={true} disabled={submitting}
                                            margin={margin}
                                            onValueChanged={hasChanged} />
                                    </Grid>


                                )}
                                <Grid item xs={12}>
                                    <Box pt={1}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}
