import { callAPI, HTTPMethod } from "./API";
import { Diots, Diot, DiotsQueryParams, DiotRequest, ExportResponse } from "../model/Diot";

const basePath = (tenantId: string) => `/tenants/${tenantId}/diots`;

export async function listDiots(tenantId: string, page: number, offset: number, params: DiotsQueryParams): Promise<Diots> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: parseParams(params)
    });
}

const parseParams = (params: DiotsQueryParams) =>{
    let query = {
        search: params.search,
        pageSize: "0",
        offset: "0",
        companyId: params.company_id ? params.company_id : "",
        month: params.month,
        year: params.year, 
        connectorId: params.connector_id ? params.connector_id : ""
    } as Record<string, string>;

    return query;
}

export async function createDiot(tenantId: string, request: DiotRequest): Promise<Diot> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getDiot(tenantId: string, id: string): Promise<Diot> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function updateDiot(tenantId: string, id: string, request: DiotRequest): Promise<Diot> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteDiot(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}
export async function exportExcel(tenantId: string, params: DiotsQueryParams): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.GET,
        query: parseParams(params)
    });
}
