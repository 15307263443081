
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import NotificationGroupsIcon from "@material-ui/icons/AllInbox";
import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { RouterParams } from "../router/RouterParams";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import DateFormat from "../components/DateFormat";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { notificationGroupList, deleteNotificationGroup } from "../api/NotificationGroupAPI";
import { NotificationGroups, NotificationGroupsQueryParams, NotificationGroup } from "../model/NotificationGroup";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import NotificationGroupMenu from "./NotificationGroupMenu";
import ConfirmPopup from "../components/ConfirmationPopup";

export default function NotificationGroupsList({ match }: RouterParams) {
    
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || match.params.tenantId;
    const history = useHistory();

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<NotificationGroups>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [workingParams, setWorkingParams] = useState<NotificationGroupsQueryParams>({
        search: search,
    } as NotificationGroupsQueryParams);

    const [notificationGroup, setNotificationGroup] = useState<NotificationGroup>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());

    useEffect(() => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as NotificationGroupsQueryParams;
        setWorkingParams(params);

        notificationGroupList(tenantId, pageSize, offset, params)
        .then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [context.session, tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (notificationGroup: NotificationGroup) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setNotificationGroup(notificationGroup);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setNotificationGroup(undefined);
    };

    const onClickRow = (notificationGroup: NotificationGroup) => {
        history.push(`${context.isGranted("NotificationGroupsUpdate") ? `/tenants/${tenantId}` : ""}/notification-groups/${notificationGroup.id}/edit`);
    };

    const onDelete = () => { 
        setOpenConfirmDialog(true);
        setAnchorEl(null);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDialog(false);
    };

    const onConfirmDelete = () => { 
        setOpenConfirmDialog(false);
        if(!notificationGroup) return;
        deleteNotificationGroup(context.session!.tenant!.id, notificationGroup.id)
            .then((_) => {
                setSuccess(translate("notification_groups.success_delete") as string);
                window.location.reload();
            }).catch((error) => {
                setError(error.message);
            });
    }

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    return (
        <Pagination 
            title={translate("notification_groups.title")} icon={<NotificationGroupsIcon />}
            page={page} 
            pageSize={pageSize} 
            count={data ? data.items.length : 0} 
            total={data ? data.total : 0}
            onChangedPage={onChangedPage} 
            onChangedPageSize={onChangedPageSize} 
            action={context.isGrantedAny(["NotificationGroupsCreate"]) ?
                (<Link to={`${context.isGranted("NotificationGroupsCreate") ? `/tenants/${tenantId}` : ""}/notification-groups/new`}>
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("notification_groups.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("notification_groups.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("notification_groups.fields.name") as string,
                        converter: (notificationGroup) => notificationGroup?.name,
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("notification_groups.fields.list_distribution") as string,
                        converter: (notificationGroup) => (
                            <Ellipsis text={ notificationGroup?.emails.join(",") } lenght={ 100 } uppercased={ false } />
                        ),
                        fullWidth: false,
                        xs: 7,
                        sm: 8,
                        md: 8,
                        lg: 8,
                        xl: 10
                    },
                    {
                        title: translate("notification_groups.fields.created_at") as string,
                        converter: (notificationGroup) => (
                            <DateFormat date={notificationGroup?.created_at} format="L" />
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (notificationGroup) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(notificationGroup)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            {notificationGroup && anchorEl &&
                <NotificationGroupMenu tenantId={tenantId} NotificationGroup={notificationGroup} anchor={anchorEl} onClose={onCloseOption} onDelete={onDelete}/>
            }
            {openConfirmDialog && (
                <ConfirmPopup
                    doAction={onConfirmDelete}
                    onClose={closeConfirmDelete}
                    title={translate("notification_groups.confirm_delete.title") as string}
                    message={translate("notification_groups.confirm_delete.message_secondary", {"grupo":notificationGroup!.name}) as string} 
                    secondary={translate("notification_groups.confirm_delete.message") as string} 
                    button={translate("buttons.accept") as string} />
            )}
        </Pagination>
    );
}