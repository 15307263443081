import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";

import translate from "../i18n/Translator";
import { Connector } from "../model/Connector";
import { AppContext } from "../context/AppContext";

interface ConnectorMenuProps {
    tenantId: string;
    connector: Connector;
    queryString: string;
    anchor: HTMLElement | null;
    onClose(): any;
}

export default function ConnectorMenu(props: ConnectorMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("ConnectorsUpdate") &&
                <MenuItem button component={Link} to={`/tenants/${props.tenantId}/connectors/${props.connector.id}/edit?${props.queryString}`} onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
        </Menu>
    );

}