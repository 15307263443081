import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { DeleteTenantCfdisRequest } from "../model/Tenant";
import Progress from "../components/Progress";
import ValidatedInput from "../components/ValidatedInput";
import { WarningSnackbar, ErrorSnackbar } from "../components/Snackbars";
import { deletePayableData, getCfdiAllowedToDelete } from "../api/SupportAPI";
import { CfdiAllowedToDeleteResponse } from "../api/Support";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import ReactHtmlParser from "html-react-parser";

interface DeleteCfdiByUuidPopupProps {
    onClose(): any;
    onSuccess(success: string): any;
}

export default function DeleteCfdiByUuidPopup(props: DeleteCfdiByUuidPopupProps) {
    const [request, setRequest] = useState<DeleteTenantCfdisRequest>({} as DeleteTenantCfdisRequest);
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<CfdiAllowedToDeleteResponse>();
    const [warning, setWarning] = useState<string>();
    const [error, setError] = useState<string>();
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    
    useEffect(() => {
        setStatus("loaded");
    }, [props]);

    const onAccept = () => {
        getCfdiAllowedToDelete( request.uuid ).then((response) => {
            setData(response);
            setOpenConfirmDialog(true);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const onConfirm = () => {
        setOpenConfirmDialog(false);
        setOpenBackdrop(true);
        deletePayableData( request ).then((_) => {
            props.onSuccess(translate("tenants.delete_cfdis.success_uuid", {"uuid": request.uuid}) as string);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    };

    const onCloseConfirm = () => {
        setOpenConfirmDialog(false);
    };

    const hasChanged = (name: string, value: string) => {
        setRequest({ ...request, [name]: value });
    };

    const onCloseSnackbars = () => {
        setWarning(undefined);
        setError(undefined);
    };

    return (
        <DialogPopup open
            title={translate("tenants.delete_cfdis.delete_by_uuid") as string}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            closeText={translate("buttons.cancel") as string}
            onClose={props.onClose}
            closeColor="default"
            maxWidth="sm"
            button={
                <Button onClick={onAccept} variant="outlined" color="primary" disabled={!request.uuid || !request.comments}>
                    {translate("buttons.delete") as string}
                </Button>
            }>
            {status !== "loading" && status !== "loaded" && (
                status
            )}
            {status === "loading" && (
                <Progress />
            )}
            {status === "loaded" && (
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <ValidatedInput
                            type="text" id="uuid" name="uuid"
                            label={translate("tenants.delete_cfdis.uuid") as string}
                            value={request.uuid}
                            required={true}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text" id="comments" name="comments"
                            label={translate("tenants.delete_cfdis.comments") as string}
                            value={request.comments}
                            rows={5}
                            required={true}
                            margin={"dense"}
                            onValueChanged={hasChanged}/>
                    </Grid>

                </Grid>
            )}
            {data && openConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onConfirm}
                    onClose={onCloseConfirm}
                    title={translate("tenants.delete_cfdis.delete_by_uuid") as string}
                    message={ReactHtmlParser(translate("tenants.delete_cfdis.confirm_delete_dialog.uuid_message",
                        {"uuid": request.uuid, 
                        "tenant": data.tenant_name,
                        "related": data.related_credit_notes ? translate("tenants.delete_cfdis.confirm_delete_dialog.part_related_message", {"related_no": data.related_credit_notes}) : ""
                    }) as string)} 
                    secondary={translate("tenants.delete_cfdis.confirm_delete_dialog.secondary_uuid") as string} 
                    button={translate("buttons.accept") as string} />
            )}
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </DialogPopup>
    );

}