import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Typography, Tabs, Tab, Grid, Fab, IconButton } from "@material-ui/core";
import Progress from "../components/Progress";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import PaymentsTab from "./PaymentsTab";
import DetailsTab from "./DetailsTab";
import HistoryTab from "./PaymentOrderHistory";
import Surface from "../components/Surface";
import { useHistory } from "react-router-dom";
import CustomBackdrop from "../components/CustomBackdrop";
import { PaymentOrder, Statuses, CreatePaymentFileRequest, ExecutePaymentFileRequest, PreparePaymentFileData, PaymentOrderRejectRequest } from "../model/PaymentOrder";
import DateFormat from "../components/DateFormat";
import Ellipsis from "../components/Ellipsis";
import PaymentIcon from '@material-ui/icons/Payment';
import CreatePaymentFilePopup from "./CreatePaymentFilePopup";
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import ConfirmationPopup from "../components/ConfirmationPopup";
import SelectPopup from "../components/SelectPopup";
import { createPaymentFile, preparePaymentFile, cancelPaymentFile, execute, payAllCfdisPending, reject } from "../api/PaymentOrderApi";
import { CfdisSelected, PayAllPaymentOrderRequest } from "../model/Payment";
import PayAllCfdisPendingPopUp from "./PayAllCfdisPendingPopUp";
import CfdisCancelledPopup from "../components/CfdisCancelledPopup";
import PaymentOrderMenuActions from "../payment_order/PaymentOrderMenuActions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AuthorizeRejectPopup from "../components/AuthorizeRejectPopup";
import { Cfdi } from "../model/Cfdi";
import { ProviderBankAccountMissingPopup } from "./ProviderBankAccountMissingPopup";
import SendIcon from '@material-ui/icons/Send';
import { isRoleOrParent } from "../model/Role";
import TabPanel, { a11yProps } from "../components/TabsGenerator";
import DialogPopup from "../components/DialogPopup";

interface PaymentOrderTab {
    name: string;
    title: string;
    actions?: string[];
}

interface PaymentOrderOverviewProps {
    paymentOrder: PaymentOrder;
    onEditPaymentOrder(paymentOrder: PaymentOrder): any;
    sendToPendingExecute(status: string): any;
    onPaidCfdi(paySelected: CfdisSelected, paymentOrderId: string, paymentMethod: string): any;
    onClose(): any;
}

export default function PaymentOrderOverview(props: PaymentOrderOverviewProps) {
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const context = useContext(AppContext);
    const history = useHistory();
    const [status, setStatus] = useState<string>("loading");
    const qs = queryString.parse(window.location.search);

    const [openPayCfdisPendingDialog, setOpenPayCfdisPendingDialog] = useState<boolean>(false);
    const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>();
    const [openCreatePaymentFileDialog, setOpenCreatePaymentFileDialog] = useState<boolean>();
    const [cfdisCancelled, setCfdisCancelled] = useState<Cfdi[]>();
    const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
    const [currencySelected, setCurrencySelected] = useState<string>();
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [paymentOrder, setPaymentOrder] = useState<PaymentOrder>(props.paymentOrder);
    const [tenantBankAccountMissingCurrencies, setTenantBankAccountMissingCurrencies] = useState<string[] | undefined>(undefined);
    const [providersWithoutBankAccount, setProvidersWithoutBankAccount] = useState<Map<String, string[]> | undefined>(undefined);
    const [preparePaymentFileData, setPreparePaymentFileData] = useState<PreparePaymentFileData | undefined>(undefined);
    const isPaymentOrdersCxpUpdate = context.isGranted("PaymentOrdersCxpUpdate");
    const isPaymentOrdersTreasurerUpdate = context.isGranted("PaymentOrdersTreasurerUpdate");
    const isShowExecute = isPaymentOrdersCxpUpdate && (paymentOrder.status === Statuses[0] || paymentOrder.status === Statuses[5]);
    const isShowEdit = ((isPaymentOrdersCxpUpdate && (paymentOrder.status === "PAYMENT_TO_SEND_TO_EXECUTE" || paymentOrder.status === "PAYMENT_ORDER_REJECTED")) || isPaymentOrdersTreasurerUpdate) && paymentOrder.status !== "PAID";
    const isShowPaymentFile = isPaymentOrdersTreasurerUpdate && (paymentOrder.status === "PAYMENT_IN_PROCESS_TO_EXECUTE" || paymentOrder.status === "PARTIALLY_PAID") && paymentOrder.cfdis.findIndex(cfdi => cfdi.status === "TO_EXECUTE_BANK") < 0 && paymentOrder.enable_create_payment_file;
    const isShowReject = paymentOrder.status === "PAYMENT_IN_PROCESS_TO_EXECUTE" && (isRoleOrParent(context.session!.role, "treasurer") || isRoleOrParent(context.session!.role, "owner"));
    const isShowPendingAll = (paymentOrder.status === "PAYMENT_IN_PROCESS_TO_EXECUTE" || paymentOrder.status === "PARTIALLY_PAID") && isPaymentOrdersTreasurerUpdate && (isRoleOrParent(context.session!.role, "treasurer") || isRoleOrParent(context.session!.role, "owner")) && paymentOrder.cfdis.filter(cfdi => cfdi.status === "PENDING").length > 0;
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const [dialogMessage, setDialogMessage] = useState<string | undefined>(undefined);
    const [messageProvidersRequiredFields, setMessageProvidersRequiredFields] = useState<string | undefined>(undefined);
    const [dialogTitle, setDialogTitle] = useState<string | undefined>(undefined);

    const allTabs = [
        {
            name: "details",
            title: translate("payment_order.details_title" as string)
        },
        {
            name: "payments",
            title: translate("payment_order.payments_title" as string)
        },
        {
            name: "history",
            title: translate("payment_order.history.title" as string)
        }
    ] as PaymentOrderTab[];

    const tabToIndex = (tab: string): number => {
        let index = tabs.findIndex((t) => t.name === tab);
        return Math.max(index, 0);
    };

    const tabQs = (): number => {
        if (typeof qs.tab === "string") {
            return tabToIndex(qs.tab)
        }
        return 0;
    };

    const [tabs, setTabs] = useState<PaymentOrderTab[]>([]);
    const [value, setValue] = useState<number>(tabQs);

    const load = () => {
        setStatus("loading");
        let currencies = [] as string[];
        props.paymentOrder.cfdis.filter(cfdi => cfdi.status === "PENDING" && cfdi.payment_currency !== undefined).forEach(cfdi => {
            if (currencies.find(currency => cfdi.payment_currency === currency) === undefined) {
                currencies.push(cfdi.payment_currency ? cfdi.payment_currency : "");
            }
        });
        setCurrencies(currencies);
        setTabs(allTabs);
        setStatus("loaded");
    };

    useEffect(load, [props]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let qs = queryString.parse(window.location.search);
        let newTab = tabs[newValue];
        if (newTab) {
            qs["tab"] = newTab.name;
        }

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);

        setValue(newValue);
    };

    useEffect(() => {
        setValue(tabQs());
        // eslint-disable-next-line
    }, [qs.tab, tabs]);


    const tabContent = (index: number) => {
        if (!paymentOrder) return;

        if (!context.session || !context.session.tenant) return;

        let current = tabs[index];
        if (!current) return;

        switch (current.name) {
            case "details":
                return (
                    <DetailsTab tenantId={context.session.tenant.id} paymentOrder={paymentOrder}
                        isReaderOnly={isReaderOnly} onPaidCfdi={props.onPaidCfdi} onExecute={onExecute}
                        onCancelPaymentFile={onCancelPaymentFile} onCancelCfdi={props.onClose} />
                );
            case "payments":
                return (
                    <PaymentsTab payments={paymentOrder.list_payments} />
                );
            case "history":
                return (
                    <HistoryTab history={paymentOrder.history_response} />
                );
            default:
                return <div></div>;
        }
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !paymentOrder) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const onCloseCfdisCancelled = () => {
        setCfdisCancelled(undefined);
    };

    const confirmationSendToPendingExecute = () => {
        setOpenConfirmationPopup(true);
    }

    const closeConfirmationSendToPendingExecute = () => {
        setOpenConfirmationPopup(false);
    }

    const sendToPendingExecute = () => {
        props.sendToPendingExecute(Statuses[1]);
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
        setOpenOptions(true);
    };

    const onConfirmReject = (comment: string) => {
        if (!paymentOrder) return;
        reject(context.session!.tenant!.id, paymentOrder.id, { comments: comment } as PaymentOrderRejectRequest).then((response) => {
            setSuccess(translate("payment_order.messages.message_success_reject") as string);
            history.goBack();
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onExecute = (field: ExecutePaymentFileRequest) => {
        if (!paymentOrder) return;
        setOpenBackdrop(true);
        execute(context.session!.tenant!.id, paymentOrder.id, field).then((response) => {
            setOpenBackdrop(false);
            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                return;
            }
            setSuccess(translate("payment_order.messages.message_success_execute") as string);
            setPaymentOrder(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onCreatePaymentFile = () => {
        preparePaymentFile(context.session!.tenant!.id, paymentOrder.id).then((response) => {
            if (response.missing_bank_account) {
                setDialogTitle(translate("payment_order.provider_missing_local_account.title") as string);
                setDialogMessage(translate("payment_order.provider_missing_local_account.message", { "provider": response.missing_bank_account.provider, "currency": response.missing_bank_account.currency }) as string);
                return;
            }
            if (response.tenant_bank_account_missing_currencies?.length > 0) {
                setTenantBankAccountMissingCurrencies(response.tenant_bank_account_missing_currencies);
                return;
            }
            if (response.providers_without_bank_account
                && Object.keys(response.providers_without_bank_account).length > 0) {
                setProvidersWithoutBankAccount(response.providers_without_bank_account);
                return;
            }
            setOpenCreatePaymentFileDialog(true);
            setPreparePaymentFileData(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setGridAnchorEl(null)
        });
    }

    const onOpenPayPendingCfdis = () => {
        let hasPendings = paymentOrder.cfdis.filter(cfdi => cfdi.status === "PENDING").length > 0;
        if (currencies.length > 1 && hasPendings) {
            setOpenCurrencyModal(true);
        } else if (hasPendings) {
            setOpenPayCfdisPendingDialog(true);
            setCurrencySelected(currencies[0]);
        } else {
            setWarning(translate("payment_order.providers_empty") as string);
        }
        setGridAnchorEl(null);
    }

    const onPayPendingCfdis = (request: PayAllPaymentOrderRequest) => {
        setOpenPayCfdisPendingDialog(false);
        setOpenBackdrop(true);
        payAllCfdisPending(context.session!.tenant!.id, paymentOrder.id, request).then((response) => {
            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                return;
            }
            setSuccess(translate("payment_order.messages.success_pay_cfdis") as string);
            setPaymentOrder(response);
            let currencies = [] as string[];
            response.cfdis.filter(cfdi => cfdi.status === "PENDING" && cfdi.payment_currency !== undefined).forEach(cfdi => {
                if (currencies.find(currency => cfdi.payment_currency === currency) === undefined) {
                    currencies.push(cfdi.payment_currency ? cfdi.payment_currency : "");
                }
            });
            setCurrencies(currencies);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onConfirmCreatePaymentFile = (paymentFileRequest: CreatePaymentFileRequest) => {
        setOpenCreatePaymentFileDialog(false);
        setOpenBackdrop(true);
        createPaymentFile(context.session!.tenant!.id, paymentOrder.id, paymentFileRequest).then((response) => {
            setOpenBackdrop(false);
            if (response.cfdis_cancelled) {
                setCfdisCancelled(response.cfdis_cancelled.cfdis_invalid);
                return;
            }
            if(response.providers_bank_accounts_required_fields_message) {
                setMessageProvidersRequiredFields(response.providers_bank_accounts_required_fields_message);
                return;
            }
            setSuccess(translate("payment_order.messages.message_success_create") as string);
            setPaymentOrder(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onCancelPaymentFile = (paymentOrderFileId: string) => {
        setOpenBackdrop(true);
        cancelPaymentFile(context.session!.tenant!.id, paymentOrder.id, paymentOrderFileId).then((response) => {
            setSuccess(translate("payment_order.messages.message_success_cancel") as string);
            setPaymentOrder(response);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onEditPaymentOrder = () => {
        props.onEditPaymentOrder(paymentOrder);
    }

    const onCloseCreate = () => {
        setOpenCreatePaymentFileDialog(false);
        setPreparePaymentFileData(undefined);
        setOpenBackdrop(false);
    }

    const onCloseTenantBankAccountMissingCurrencies = () => {
        setTenantBankAccountMissingCurrencies(undefined);
    }

    const onCloseProvidersWithoutBankAccount = () => {
        setProvidersWithoutBankAccount(undefined);
    }

    const onCloseCurrencyModal = () => {
        setOpenCurrencyModal(false);
    }

    const onCloseReject = () => {
        setOpenRejectDialog(false);
        setGridAnchorEl(null);
        setOpenOptions(false);
    }

    const onSelectedCurrency = (currencySelected: string) => {
        setOpenCurrencyModal(false);
        setOpenPayCfdisPendingDialog(true);
        setCurrencySelected(currencySelected);
    }

    const onCloseDialog = () => {
        setDialogTitle(undefined);
        setDialogMessage(undefined);
    }

    return (
        <Surface title={paymentOrder.title} subtitle={(
            <div>
                <Ellipsis text={translate("payment_order.subtitle_date") as string} lenght={0} uppercased />
                <DateFormat date={paymentOrder.schedule_date} format="DD/MM/YYYY" />
            </div>
        )} backButton onAction={props.onClose} icon={<PaymentIcon />}
            className="PaperPagination PaperTabContainer"
            titleActions={(
                <Grid container justify="flex-end" spacing={1}>
                    {isShowExecute && (
                        <Grid item xs="auto">
                            <Fab color="primary" size="small" onClick={confirmationSendToPendingExecute} title={translate("payment_order.send_to_execute") as string}>
                                <SendIcon />
                            </Fab>
                        </Grid>
                    )}
                    {!isReaderOnly && (
                        <Grid item xs="auto">
                            <IconButton color="default" size="small" onClick={onClickedMore} disabled={false}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            )}>
            <Tabs value={value} onChange={handleChange} aria-label={translate("cfdis.tenant_group.title") as string}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab: PaymentOrderTab, index: number) => (
                    <Tab key={tab.name} label={tab.title} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Grid container justify="center" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {tabs.map((tab: PaymentOrderTab, index: number) => (
                        <TabPanel key={tab.name} value={value} index={index}>
                            {tabContent(index)}
                        </TabPanel>
                    ))}
                </Grid>
            </Grid>
            {openCreatePaymentFileDialog && preparePaymentFileData && (
                <CreatePaymentFilePopup
                    tenant_banks={preparePaymentFileData.tenant_banks}
                    tenant_bank_accounts={preparePaymentFileData.tenant_bank_accounts}
                    details_by_currency={preparePaymentFileData.details_by_currency}
                    onConfirm={onConfirmCreatePaymentFile}
                    onClose={onCloseCreate} />
            )}
            {openPayCfdisPendingDialog && currencySelected && (
                <PayAllCfdisPendingPopUp
                    tenantId={context.session?.tenant?.id!}
                    paymentOrderId={props.paymentOrder.id}
                    currency={currencySelected}
                    cfdisPendingPayment={paymentOrder.cfdis.filter(c => c.status === "PENDING" && c.payment_currency === currencySelected)}
                    onCompleted={onPayPendingCfdis}
                    onClose={() => setOpenPayCfdisPendingDialog(false)} />
            )}
            {tenantBankAccountMissingCurrencies &&
                <ConfirmationPopup
                    title={translate("payment_order.tenant_bank_account_missing_currencies.title") as string}
                    message={translate("payment_order.tenant_bank_account_missing_currencies.message", { "currencies": tenantBankAccountMissingCurrencies.join(", ") }) as string}
                    onClose={onCloseTenantBankAccountMissingCurrencies} />
            }
            {providersWithoutBankAccount &&
                <ProviderBankAccountMissingPopup data={providersWithoutBankAccount} onClose={onCloseProvidersWithoutBankAccount} />
            }
            {openConfirmationPopup && (
                <ConfirmationPopup
                    title={translate("payment_order.confirmation_send_execute.title") as string}
                    message={translate("payment_order.confirmation_send_execute.message") as string}
                    secondary={translate("payment_order.confirmation_send_execute.message_secondary") as string}
                    button={translate("buttons.accept") as string}
                    onClose={closeConfirmationSendToPendingExecute}
                    doAction={sendToPendingExecute} />
            )}
            {openCurrencyModal && (
                <SelectPopup
                    title={translate("payment_order.select_currency.title") as string}
                    message={translate("payment_order.select_currency.message") as string}
                    labelInput={translate("payment_order.select_currency.label_input") as string}
                    button={translate("buttons.sig") as string}
                    onClose={onCloseCurrencyModal}
                    doAction={onSelectedCurrency}
                    elements={currencies}
                    elementLabels={currencies.map(currency => translate("currency." + currency) as string)} />
            )}
            {cfdisCancelled ? <CfdisCancelledPopup onClose={onCloseCfdisCancelled} cfdisInvalid={cfdisCancelled} />
                : undefined}
            {openOptions && gridAnchorEl && paymentOrder.status !== "PAID" &&
                <PaymentOrderMenuActions
                    isShowEdit={isShowEdit} onEditPaymentOrder={onEditPaymentOrder}
                    isShowPaymentFile={isShowPaymentFile} onCreate={onCreatePaymentFile}
                    isShowPendingAll={isShowPendingAll} onOpenPayPendingCfdis={onOpenPayPendingCfdis}
                    isShowReject={isShowReject} onReject={() => setOpenRejectDialog(true)}
                    onClose={() => setOpenOptions(false)} anchor={gridAnchorEl} />
            }
            {openRejectDialog && (
                <AuthorizeRejectPopup
                    doAction={onConfirmReject}
                    onClose={onCloseReject}
                    param={"reject-payment-order"} isCommentRequired={true} />
            )}
            {dialogMessage && (
                <DialogPopup open
                    title={dialogTitle}
                    closeText={translate("buttons.close") as string}
                    onClose={onCloseDialog}
                    closeColor="default">
                        <Typography variant="body2">
                            {dialogMessage}
                        </Typography>
                </DialogPopup>
            )}
            {messageProvidersRequiredFields && (
                <DialogPopup open
                    title={translate("payment_order.layout_required_fields.title") as string}
                    closeText={translate("buttons.close") as string}
                    onClose={() => {setMessageProvidersRequiredFields(undefined)}}
                    closeColor="default">
                    {messageProvidersRequiredFields.split("\n").map((message) => <Typography variant="body2">{message}<br/></Typography>)}
                </DialogPopup>
            )}
            <SuccessSnackbar message={success} onClose={() => setSuccess("")} />
            <WarningSnackbar message={warning} onClose={() => setWarning("")} />
            <ErrorSnackbar message={error} onClose={() => setError("")} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
        </Surface>
    );
}
