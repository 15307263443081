import React, { useState } from "react";
import { Grid, Box, Button, Card, Typography, Divider, Checkbox } from "@material-ui/core";
import { acceptContractManifest } from "../../api/ProviderMembershipTaxStampApi";
import ReactHtmlParser from 'html-react-parser';
import translate from "../../i18n/Translator";
import { ManifestContract } from "../../model/ManifestTaxStamp";

export interface AcceptContractParams {
    handleBack(): any;
    provider_rfc?: string;
    provider_name?: string;
    manifestContract?: ManifestContract;
    setActiveStep(): any;
    setSubmitting(submitting: boolean): any;
    setError(message: any): any;
}

export default function AcceptContract(params: AcceptContractParams){
    const [acceptManifest, setAcceptManifest] = useState<boolean>(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false);

    const acceptContract = () => {
        params.setSubmitting(true);
        acceptContractManifest().then(_ => {
            params.setActiveStep();
        }).catch(error => {
            params.setError(error.message);
        }).finally(() => params.setSubmitting(false));
    };

    return (<>
    <Grid container>
        <Grid item >
            <Box pb={3} >
                <Grid container spacing={2}  justifyContent="center" alignContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography><b>{translate("tax_stamp.sign_manifest.manifest")}</b></Typography>
                        <Card variant="outlined">
                            <Box px={1} py={1}>
                            {ReactHtmlParser(params.manifestContract?.contract
                                .replace(params.provider_name || "", `<b>${params.provider_name}</b>`)
                                .replace(params.provider_rfc || "", `<b>${params.provider_rfc}</b>`)
                            || "")}
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography> <b>{translate("tax_stamp.sign_manifest.privacy")}</b></Typography>
                        <Card variant="outlined" style={{ "overflowY": "scroll", height: "400px"}}>
                            <Box px={1} py={1}>
                                {params.manifestContract?.privacy}
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Divider/>
        </Grid>
        <Grid item >
            <Box pb={3}>
                <Grid container justifyContent="flex-start" alignItems="center" alignContent="center">
                    <Grid item xs="auto" >
                        <Checkbox
                            id="ad-readed"
                            name="ad-readed"
                            checked={acceptManifest}
                            onChange={() => setAcceptManifest(!acceptManifest)}
                            disabled={false}
                            className="GridableCheck"
                        />
                    </Grid>
                    <Grid item xs>
                        {translate("tax_stamp.sign_manifest.accept_contract")}
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" alignItems="center" alignContent="center">
                    <Grid item xs="auto" >
                        <Checkbox
                            id="ad-readed"
                            name="ad-readed"
                            checked={acceptPrivacy}
                            onChange={() => setAcceptPrivacy(!acceptPrivacy)}
                            disabled={false}
                            className="GridableCheck"
                        />
                    </Grid>
                    <Grid item xs>
                        {translate("tax_stamp.sign_manifest.accept_privacy")}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    </Grid>
    <Grid container alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
        <Box pb={2}>
            <Grid item>
                <Button disabled={false} onClick={params.handleBack} >
                    {translate("buttons.back") as string}
                </Button>
                <Button variant="contained" color="primary" onClick={acceptContract} disabled={!acceptManifest || !acceptPrivacy}>
                    {translate("buttons.next") as string}
                </Button>
            </Grid>
        </Box>
    </Grid> </>);
}