import { callAPI, HTTPMethod } from "./API";
import { PlansTaxStamp, PlanTaxStamp } from "../model/PlanTaxStamp";

export async function listPlans(): Promise<PlansTaxStamp> {
    return await callAPI(`/plan-stamp-tax`, {
        method: HTTPMethod.GET
    });
}

export async function getPlan(externalId: string): Promise<PlanTaxStamp> {
    return await callAPI(`/plan-stamp-tax/${externalId}`, {
        method: HTTPMethod.GET
    });
}
