import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Cfdi } from "../model/Cfdi";
import { WarningSnackbar } from "../components/Snackbars";

interface CfdiAdditionalAmountPopUpProps {
    cfdi: Cfdi;
    onCompleted(name : string, amount : number): any;
    onClose(): any;
}

export default function CfdiAdditionalAmountPopUp(props: CfdiAdditionalAmountPopUpProps) {
    const [name, setName] = useState<string>("");
    const [amount, setAmount] = useState<string>("");
    const [warning, setWarning] = useState<string>();

    const load = () => {
        setName(props.cfdi.metadata.additional_amount ? props.cfdi.metadata.additional_amount.name : "");
        setAmount(props.cfdi.metadata.additional_amount ? props.cfdi.metadata.additional_amount.amount+"" : "");
    }

    useEffect(load, [props]);

    const onSubmit = () => {
        if(!name || name === ""){
            setWarning(translate("cfdis.additional_amount.warning_name") as string)
            return;
        }
        if (!amount || Number(amount) <= 0 || Number(amount) > props.cfdi.metadata.balance ) {
            setWarning(translate("cfdis.additional_amount.warning_amount") as string)
            return;
        }
        props.onCompleted(name, Number(amount));
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        name === "amount" ? setAmount(value+""): setName(value);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };

    return (

        <DialogPopup open
            title={translate("cfdis.additional_amount.title_pop_up") as string}
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="medium" disabled={false}>
                    {translate("buttons.accept")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="default"
            onClose={props.onClose}>
                <Grid container>
                            <Grid item xs={12}>
                                <ValidatedInput type="text" id="name" name="name"
                                    value={name} label={translate("cfdis.additional_amount.name") as string}
                                    required={true}
                                    margin={"dense"}
                                    onValueChanged={hasChanged} />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="number"
                                    id={"amount"}
                                    name={"amount"}
                                    value={amount.toString()}
                                    isValueNumber={true}
                                    label={"$  " + translate("cfdis.additional_amount.amount") as string}
                                    required={false}
                                    margin="dense"
                                    onValueChanged={hasChanged}
                                />
                            </Grid>
                </Grid>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </DialogPopup>
    );
}
