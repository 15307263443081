import React, { useContext, useState, useEffect } from "react";
import { MenuItem, ListItemIcon, Grid } from "@material-ui/core";
import DownloadXmlIcon from '@material-ui/icons/LibraryBooksTwoTone';
import { AppContext } from "../../context/AppContext";
import { isRoleOrParent } from "../../model/Role";

import { getCfdiFileUrl } from "../../api/ProviderCfdiApi";
import { getTenantCfdiFileUrl } from "../../api/TenantCfdiApi";
import { Cfdi } from "../../model/Cfdi";

interface CfdiDownloadXmlProps {
    cfdi: Cfdi;
}

export default function CfdiDownloadXml(props: CfdiDownloadXmlProps) {
    const context = useContext(AppContext);
    const [xmlUrl, setXmlUrl] = useState<string>();
    
    useEffect(() => {
        let isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
        if (props.cfdi.metadata.pdf_url) {
            if(isProvider){
                getCfdiFileUrl(context.session!.tenant!.id, context.session!.provider!.id, props.cfdi.id, "xml").then((urlFile) => {
                    setXmlUrl(urlFile);
                }).catch((error) => {
                    setXmlUrl(undefined);
                });
            } else {
                getTenantCfdiFileUrl(context.session!.tenant!.id, props.cfdi.id, "xml").then((urlFile) => {
                    setXmlUrl(urlFile);
                }).catch((error) => {
                    setXmlUrl(undefined);
                });
            }
        }
    }, [context.session, props.cfdi]);

    const onClickCfdi = () => {
    }

    return (
        <Grid>
            {xmlUrl && context.isGrantedAny(["AdminCFDIsRead", "ReceptorCFDIsRead", "CxpCFDIsRead", "TreasurerCFDIsRead", "ProviderCFDIsRead"]) &&
                <MenuItem button onClick={onClickCfdi} dense component="a" href={xmlUrl} target="_blank" rel="noopener noreferrer">
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                </MenuItem>
            }
        </Grid>);
}
