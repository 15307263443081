import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Divider, Box } from "@material-ui/core";
import SatTaxIcon from "@material-ui/icons/AccountBalance";

import { listSocialContributions, createSocialContributionToValidateSipare } from "../api/SocialContributionAPI";
import { listSocialContributions as listProviderSocialContributions,
    createSocialContributionToValidateSipare as createProviderSocialContributionToValidateSipare } from "../api/ProviderSocialContributionAPI";

import translate from "../i18n/Translator";
import { SocialContributionsQueryParams, ToValidateSipareRequest } from "../model/SocialContribution";
import PdfViewer from "../components/PdfViewer";
import Progress from "../components/Progress";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import Surface from "../components/Surface";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { AppContext } from "../context/AppContext";
import { formatDate } from "../components/DateFormat";
import { EmployerConfiguration } from "../model/Employer";
import { get as getTenantConfigurations } from "../api/TenantConfigurationApi";
import { get as getProviderConfigurations } from "../api/ProviderConfigurationApi";
import { MONTHS_BEFORE_IN_PERIOD } from "../model/Contribution";
import moment from "moment";
import { fillPeriods, fillPeriodsParams } from "./NewSocialContributionForm";

interface ToValidateSipareFormParams {
    period?: string;
    urlPdf: string;
    employeerNumber: string;
}

export default function ToValidateSipareForm(props : ToValidateSipareFormParams) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const history = useHistory();
    const margin = "dense";

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [request, setRequest] = useState<ToValidateSipareRequest>({
        employer_number: props.employeerNumber,
        folio_sua: "",
        imss: 0.00,
        rcv: 0.00,
        infonavit: 0.00,
        year: 0,
        month: 0,
        sipare_line: "",
        key: props.period,
        sipare: props.urlPdf,
        bimester: 0
    } as ToValidateSipareRequest);
    const [validations, setValidations] = useState({} as any);
    const [dateKeys, setDateKeys] = useState<string[]>([]);
    const [dateLabels, setDateLabels] = useState<string[]>([]);
    const [employerNumbers, setEmployerNumbers] = useState<string[]>([]);
    const [isMonthPar, setIsMonthPar] = useState<boolean>(false);
    const mode = context.session?.provider?.declaration_frequency || "MONTHLY";

    const promiseGetConfigurations = (): Promise<EmployerConfiguration> => {
        if (providerId) {
            return getProviderConfigurations(tenantId, providerId);
        }
        return getTenantConfigurations(tenantId);
    };

    const load = () => {
        setStatus("loading");
        promiseGetConfigurations().then(response => {
            const employerNumbers = response.employer_numbers || [];
            setEmployerNumbers(employerNumbers);
            if (employerNumbers.length === 1) {
                setRequest((r) => {
                    return { ...r, employer_number: employerNumbers[0] };
                });
            }
            setStatus("loaded");
        }).catch(error => {
            setStatus(error.message);
        });
    };

    useEffect(load, [tenantId, providerId]);

    useEffect(() => {
        if (!request.employer_number) {
            setDateKeys([]);
            setDateLabels([]);
            return;
        }

        promiseList(request.employer_number).then(response => {
            fillPeriods({
                response: response,
                setDateKeys: setDateKeys,
                setDateLabels: setDateLabels,
                setStatus: setStatus
            } as fillPeriodsParams);
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line 
    }, [tenantId, request.employer_number]);

    const promiseList = (employerNumber: string) => {
        const params = {
            employerNumber: employerNumber
        } as SocialContributionsQueryParams;

        if (providerId) {
            return listProviderSocialContributions(tenantId, providerId, 10, 0, params);
        }
        return listSocialContributions(tenantId, 10, 0, params);
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const hasChangedMonth = (name: string, value: string, inputRef: InputRef) => {
        const fixed = value === "---" ? "" : value;
        const segments = fixed.split("-");
        if (segments.length === 2) {
            const year = +segments[0];
            const month = +segments[1];
            if(mode === "MONTHLY"){
                setRequest({ ...request, 
                    [name]: fixed, 
                    year: year, 
                    month: month,
                    imss: 0.00,
                    rcv: 0.00,
                    infonavit: 0.00 });
            } else {
                setRequest({ ...request, 
                    [name]: fixed, 
                    year: year, 
                    month: month,
                    imss: 0.00,
                    rcv: 0.00,
                    infonavit: 0.00, 
                    bimester: Math.ceil(month/2)  });
            }
            setIsMonthPar(month %2 === 0);
        } else {
            setRequest({ ...request, [name]: fixed });
        }
        
        validations[name] = inputRef;
        setValidations(validations);
    };

    const promiseCreate = (request : ToValidateSipareRequest) => {
        if (providerId) {
            return createProviderSocialContributionToValidateSipare(tenantId, providerId, request);
        }
        return createSocialContributionToValidateSipare(tenantId, request);
    };
    
    const onClosedSnackbar = () => {
        setError(undefined);
        setWarning(undefined);
    };

    const onCreate = () => {
        let temp = request;

        if(isMonthPar && request.infonavit <= 0){
            setWarning(translate("social_contributions.to_validate_sipare.warning.infonavit") as string);
            return;
        }
        if(isMonthPar && request.rcv <= 0){
            setWarning(translate("social_contributions.to_validate_sipare.warning.rcv") as string);
            return;
        }
        if(request.imss <= 0){
            setWarning(translate("social_contributions.to_validate_sipare.warning.imss") as string);
            return;
        }
        if(request.sipare_line === ""){
            setWarning(translate("social_contributions.to_validate_sipare.warning.sipare_line") as string);
            return;
        }

        const segments = temp.key.split("-");
        if (temp.year === 0 && temp.month === 0 && segments.length === 2) {
            const year = +segments[0];
            const month = +segments[1];
            temp.year = year;
            temp.month = month;
            if(mode !== "MONTHLY"){
                temp.bimester = Math.ceil(month/2);
            }
        }

        setSubmitting(true);
        promiseCreate(temp).then((socialContribution) => {
            history.push("/contributions/social");
        }).catch((error) => {
            setSubmitting(false);
            setError(error.message);
        });
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Surface title={translate("social_contributions.new")} icon={<SatTaxIcon />} className="FormSurface">
                            <Grid container justify="space-between">
                                <Grid item xs={6}>
                                    <Grid container>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="text" id="employer_number" name="employer_number"
                                                    value={request.employer_number}
                                                    label={translate("contributions.employer_number") as string}
                                                    options={employerNumbers}
                                                    required disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedInput type="text" id="key" name="key"
                                                    value={request.key}
                                                    label={translate("sat_contributions.period") as string}
                                                    options={dateKeys} optionLabels={dateLabels}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChangedMonth} />
                                            </Grid>
                                            <Grid item xs={12}> <br/> <Divider /> <br/> </Grid>
                                                <Grid item xs={12}>
                                                    <Typography><strong>{translate("social_contributions.to_validate_sipare.amounts_imss")}</strong></Typography>
                                                    <br/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ValidatedInput type="number" id="imss" name="imss"
                                                        value={request.imss+""}
                                                        label={translate("social_contributions.to_validate_sipare.imss") as string}
                                                        required={true} disabled={submitting}
                                                        margin={margin}
                                                        onValueChanged={hasChanged} />
                                                </Grid>
                                                {isMonthPar && <>
                                                    <Grid item xs={12}>
                                                        <ValidatedInput type="number" id="rcv" name="rcv"
                                                            value={request.rcv+""}
                                                            label={translate("social_contributions.to_validate_sipare.rcv") as string}
                                                            required={true} disabled={submitting}
                                                            margin={margin}
                                                            onValueChanged={hasChanged} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                       
                                                    </Grid>
                                                </>}
                                            
                                                <Grid item xs={12}> <br/> <Divider /> <br/></Grid>
                                                {isMonthPar && <>
                                                    <Grid item xs={12}>
                                                        <Typography><strong>{translate("social_contributions.to_validate_sipare.amounts_infonavit")}</strong></Typography>
                                                        <br/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ValidatedInput type="number" id="infonavit" name="infonavit"
                                                            value={request.infonavit+""}
                                                            label={translate("social_contributions.to_validate_sipare.infonavit") as string}
                                                            required={true} disabled={submitting}
                                                            margin={margin}
                                                            onValueChanged={hasChanged} />
                                                    </Grid>
                                                    <Grid item xs={12}> <br/> <Divider /> <br/>
                                                </Grid>
                                                </>}
                                            <Grid item xs={12}>
                                                <ValidatedInput type="text" id="sipare_line" name="sipare_line"
                                                    value={request.sipare_line}
                                                    label={translate("social_contributions.to_validate_sipare.sipare_line") as string}
                                                    required={true} disabled={submitting}
                                                    margin={margin}
                                                    onValueChanged={hasChanged} />
                                            </Grid>                                        
                                    </Grid>

                                </Grid>
                                <Grid item xs={6}>
                                    <PdfViewer pdf={props.urlPdf} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="button" variant="contained" color="primary" size="large" disabled={submitting} onClick={onCreate}>
                                                    {translate("buttons.create")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                        <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}