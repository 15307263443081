import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Box } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import Alert from "../components/Alert";

interface MissingLicenseBannerProps {
    button?: boolean;
    margin?: number;
    variant?: "warning" | "info";
}

export default function MissingLicenseBanner(props: MissingLicenseBannerProps) {
    const context = useContext(AppContext);

    const button = (() => {
        if (!props.button) return undefined;

        var url, text;
        if (context.session?.tenant?.license_status === "MISSING") {
            url = "/subscribe";
            text = translate("subscription.license.MISSING.button") as string;
        } else if (context.session?.tenant?.license_status === "CHARGE_FAILED") {
            url = "/billings";
            text = translate("subscription.license.CHARGE_FAILED.button") as string;
        } else {
            return undefined;
        }

        return (
            <Box mt={1}>
                <Button component={Link} to={url} variant="contained" size="medium" color="secondary">
                    {text}
                </Button>
            </Box>
        )
    })();

    if (!context.session || !context.session.tenant) {
        return (<div></div>);
    }

    return (
        <Grid item xs={12} className="MissingLicenseBanner">
            <Grid container>
                <Alert variant={props.variant || "warning"} margin={props.margin || 0} plain
                    title={translate(`subscription.license.${context.session.tenant.license_status}.title`) as string}
                    subheader={translate(`subscription.license.${context.session.tenant.license_status}.text`) as string}
                    button={button} />
            </Grid>
        </Grid>
    );
}