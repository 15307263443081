import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PluginIcon from "@material-ui/icons/AccountTree";
import AddIcon from "@material-ui/icons/Add";

import { listPlugins } from "../api/PluginAPI";
import { get, updateReceivePlugin } from "../api/TenantConfigurationApi";
import { AppContext } from "../context/AppContext";
import { Plugin, Plugins, PluginsQueryParams, PluginStep } from "../model/Plugin";

import translate from "../i18n/Translator";
import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import ValidatedInput from "../components/ValidatedInput";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import PluginMenu from "./PluginMenu";
import DateFormat from "../components/DateFormat";
import { RouterParams } from "../router/RouterParams";
import { TenantConfiguration, UpdateReceivePluginRequest } from "../model/TenantConfiguration";
import CustomBackdrop from "../components/CustomBackdrop";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";

export default function PluginsList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const search = query.get("search");

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Plugins>();
    const [tenantConfiguration, setTenantConfiguration] = useState<TenantConfiguration>();
    const [workingParams, setWorkingParams] = useState<PluginsQueryParams>({
        search: search,
    } as PluginsQueryParams);

    const [plugin, setPlugin] = useState<Plugin>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPage());
    const [pageSize, setPageSize] = useState<number>(initialPageSize());
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();

    useEffect(() => {
        const offset = getOffset(page, pageSize);
        const params = {
            search: search || "",
        } as PluginsQueryParams;
        setWorkingParams(params);

        listPlugins(match.params.tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
    }, [match.params.tenantId, page, pageSize, search]);

    useEffect(() => {
        get(match.params.tenantId).then(configuration => {
            setTenantConfiguration(configuration);
        }).catch(error => {
        });
    }, [match.params.tenantId, page, pageSize, search]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        query.set("search", workingParams.search);
        query.set("page", "1");
        history.push(`${window.location.pathname}?${query.toString()}`);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onAssignReceivePlugin = () => {
        setAnchorEl(null);
        if (!plugin) return;

        onUpdateReceivePlugin(translate("plugins.receive_plugin_assigned", { name: plugin.name }) as string, 'RECEIVE', plugin.id);
    };

    const onRemoveReceivePlugin = () => {
        setAnchorEl(null);
        if (!plugin) return;

        onUpdateReceivePlugin(translate("plugins.receive_plugin_removed", { name: plugin.name }) as string, 'RECEIVE');
    };

    const onAssignAfterReceivePlugin = () => {
        setAnchorEl(null);
        if (!plugin) return;

        onUpdateReceivePlugin(translate("plugins.after_receive_plugin_assigned", { name: plugin.name }) as string, 'AFTER_RECEIVE', plugin.id);
    };

    const onRemoveAfterReceivePlugin = () => {
        setAnchorEl(null);
        if (!plugin) return;

        onUpdateReceivePlugin(translate("plugins.after_receive_plugin_removed", { name: plugin.name }) as string, 'AFTER_RECEIVE');
    };

    const onUpdateReceivePlugin = (message: string, receiveStep: PluginStep, pluginId?: string) => {
        setUpdating(true);

        const request = { plugin_id: pluginId, plugin_step: receiveStep } as UpdateReceivePluginRequest;
        updateReceivePlugin(match.params.tenantId, request).then(() => {
            setSuccess(message);

            if (tenantConfiguration) {
                setTenantConfiguration({ ...tenantConfiguration, receive_plugin_id: pluginId });
            } else if (pluginId) {
                setTenantConfiguration({ plugin_id: pluginId, configurations: [] } as TenantConfiguration);
            } else {
                setTenantConfiguration(undefined);
            }
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setUpdating(false);
        });
    };

    const onClickedOptions = (plugin: Plugin) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setPlugin(plugin);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setPlugin(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
    };

    const onClickRow = (plugin: Plugin) => {
        history.push(`/plugins/${plugin.id}/edit`);
    };

    return (
        <Pagination title={translate("plugins.title")} subtitle={data?.tenant?.name} icon={<PluginIcon />} backButton
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={context.isGranted("PluginsCreate") ?
                (<Link to={`/tenants/${match.params.tenantId}/plugins/new`}>
                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                        <AddIcon />
                    </Fab>
                </Link>) : undefined}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} sm>
                        <ValidatedInput type="text" id="search" name="search" label={translate("plugins.filter") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("plugins.empty") as string}
                onClick={onClickRow}
                columns={[
                    {
                        title: translate("plugins.name") as string,
                        converter: (plugin) => (
                            <>
                                <Ellipsis text={plugin.name} lenght={100} uppercased={false} />
                                <br />
                                <Typography variant="caption" color="textSecondary" style={{ wordBreak: 'break-word' }}>
                                    {plugin.value}
                                </Typography>

                            </>
                        ),
                        fullWidth: true,
                        xs: true
                    },
                    {
                        title: translate("plugins.types.title") as string,
                        converter: (plugin) => translate(`plugins.types.${plugin.type}`),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("plugins.steps.title") as string,
                        converter: (plugin) => (
                            <>
                                {translate(`plugins.steps.${plugin.step}`)}
                                {((plugin.step === "RECEIVE" && plugin.id === tenantConfiguration?.receive_plugin_id) ||
                                  (plugin.step === "AFTER_RECEIVE" && plugin.id === tenantConfiguration?.after_receive_plugin_id) ) && (
                                    <Typography component="h6" variant="caption" color="textSecondary">
                                        {translate("plugins.enabled")}
                                    </Typography>
                                )}
                            </>
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: translate("plugins.created_at") as string,
                        converter: (plugin) => (
                            <DateFormat date={plugin.created_at} format="YYYY-MM-DD" />
                        ),
                        fullWidth: true,
                        xs: false,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2
                    },
                    {
                        title: (
                            <IconButton size="small" style={{ "visibility": "hidden" }} disabled>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        converter: (plugin) => (
                            <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(plugin)}>
                                <MoreVertIcon />
                            </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {plugin && anchorEl &&
                <PluginMenu tenantId={match.params.tenantId}
                    plugin={plugin}
                    tenantConfiguration={tenantConfiguration}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onAssignReceivePlugin={onAssignReceivePlugin}
                    onRemoveReceivePlugin={onRemoveReceivePlugin}
                    onAssignAfterReceivePlugin={onAssignAfterReceivePlugin}
                    onRemoveAfterReceivePlugin={onRemoveAfterReceivePlugin} />
            }
            <CustomBackdrop open={updating} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
        </Pagination>
    );
}