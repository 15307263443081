import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Fab, Box } from "@material-ui/core";
import RightIcon from "@material-ui/icons/ChevronRight";

import translate from "../i18n/Translator";
import { getProviderDashboard } from "../api/MeAPI";
import Progress from "../components/Progress";
import { ProviderDashboardResponse } from "../model/Dashboard";
import BlackListWidget from "./BlackListWidget";
import PendingPaymentsListWidget from "./PendingPaymentsListWidget";
import CfdisPendingDocWidget from "./CfdisPendingDocWidget";
import { ProviderExpedientStatusHeader } from "../expedients/ExpedientIcon";
import Alert from "../components/Alert";
import { AppContext } from "../context/AppContext";
import CalendarWidget from "./CalendarDashboard";
import Widgets from "./Widgets";
import CustomWidgets from "./CustomWidgets";
import ProviderAccountingBalanceWidget from "./ProviderAccountingBalanceWidget";
import TenantBillingDataWidget from "./TenantBillingDataWidget";

export default function ProviderDashboard() {
    const context = useContext(AppContext);;
    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<ProviderDashboardResponse>();
    const expedientMessage = translate("expedients.dashboard", { "tenant": context.session!.tenant!.name }) as string;

    useEffect(() => {
        setStatus("loading");
        getProviderDashboard().then((response) => {
            setStatus("loaded");
            setData(response);
        }).catch((error) => {
            setStatus(error.message);
        });
    }, []);

    if (status === "loading") {
        return (<Progress />);
    } else if (status !== "loaded" || data === undefined) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }
    
    return (
        <Grid container justify="center" spacing={3}>
            {context.isGranted("ProvidersSelfRead") && (<Grid item xs={12}>
                {data.status && (
                    <ProviderExpedientStatusHeader
                        status={data.status}
                        approved={data.approved}
                        lastReview={data.last_review}
                        short={false}
                        dashboard={true}
                        expired={"ACCEPTED" === data.status ? data.expired: false }
                        button={(
                            <Box mr={1} mt={1}>
                                <Fab component={Link} color="primary" size="small" to="/expedients/overview">
                                    <RightIcon />
                                </Fab>
                            </Box>
                        )} />
                )}
                {!data.status && (
                    <Alert variant="info"
                        title={translate("expedients.single") as string}
                        subheader={<div><div dangerouslySetInnerHTML={{ __html: data.expedient_incomplete_message ? data.expedient_incomplete_message : '' }} />{expedientMessage}</div>}
                        margin={0}
                        button={(
                            <Box mr={1} mt={1}>
                                <Fab component={Link} color="primary" size="small" to="/expedients/overview">
                                    <RightIcon />
                                </Fab>
                            </Box>
                        )} />
                )}
            </Grid>)}
            {data.widgets && data.widgets?.items?.filter(e => "billing_data" !== e.type)?.length > 0 && (
                <Grid item xs={12}>
                    <CustomWidgets tenant={context.session!.tenant!} widgets={data.widgets?.items?.filter(e => "billing_data" !== e.type)} />
                </Grid>
            )}

            {data.widgets && data.widgets.total > 0 && data.widgets?.items?.find(e => "billing_data" === e.type) && (
                <Grid item xs={12}>
                    <TenantBillingDataWidget />
                </Grid>
            )}
            {context.isGrantedAny(["CalendarRead"]) && (
                <Grid item xs={12}>
                    <CalendarWidget />
                </Grid>
            )}
            {context.isGrantedAny(["ProvidersRead", "ProviderAccountingBalanceRead"]) && (
                <Grid item xs={12}>
                    <ProviderAccountingBalanceWidget />
                </Grid>
            )}
            {context.isGranted("WidgetRead") && (
                <Grid item xs={12}>
                    <Widgets />
                </Grid>
            )}
            {context.isGrantedAny(["ProviderCFDIsCreate", "SenderCFDIsCreate"]) && (
                <Grid item xs={12}>
                    <PendingPaymentsListWidget />
                </Grid>
            )}
            {context.isGrantedAny(["ProviderCfdisIssuedRead"]) && (
                <Grid item xs={12}>
                    <CfdisPendingDocWidget />
                </Grid>
            )}
            {context.isGrantedAny(["ProvidersRead", "ProvidersSelfRead"]) && (
                <Grid item xs={12}>
                    <BlackListWidget />
                </Grid>
            )}
        </Grid>
    );
}
