import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";

import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { IntegrationRoute } from "../model/Integration";
import { Role } from "../model/Role";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import SimpleSwitch from "../components/SimpleSwitch";
import Multiselect from "../components/Multiselect";
import IconSelector from "../components/IconSelector";

interface IntegrationRouteFormProps {
    item?: IntegrationRoute
    roles: Role[]
    onUpdated(route: IntegrationRoute): any
    onClose(): any
}

function IntegrationRouteForm({ item, roles, onUpdated, onClose }: IntegrationRouteFormProps) {
    const [request, setRequest] = useState(() => {
        return item || {
            title: '',
            path: '',
            icon: '',
            hidden: false,
            role_ids: [],
        } as IntegrationRoute
    });
    const [validations, setValidations] = useState({} as any);

    const onSubmit = () => {
        onUpdated(request)
    };

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onChangedRoles = (roleIds: string[]) => {
        setRequest({ ...request, role_ids: roleIds });
    }

    return (
        <DialogPopup open
            title={translate("integrations.form.title") as string}
            onClose={onClose}
            onSubmit={onSubmit}
            fullWidth
            maxWidth="sm"
            button={(
                <Button type="submit" variant="contained" color="primary">
                    {translate("buttons.update")}
                </Button>
            )}>
            <Grid container justify="space-between" alignItems="center" alignContent="center">
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="title"
                        name="title"
                        value={request.title}
                        label={translate("integrations.routes.title") as string}
                        required
                        margin="dense"
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="path"
                        name="path"
                        value={request.path}
                        label={translate("integrations.routes.path") as string}
                        required
                        margin="dense"
                        onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <IconSelector icon={request.icon} onValueChanged={hasChanged} />
                </Grid>
                <Grid item xs={12}>
                    <Multiselect
                        id="roles"
                        label={translate("integrations.routes.roles") as string}
                        value={request.role_ids ?? []}
                        values={roles.map(el => el.id)}
                        labels={roles.map(el => el.description ?? translate(`users.roles.${el.id}`) as string)}
                        onChanged={onChangedRoles} />
                </Grid>
                <Grid item xs={12}>
                    <SimpleSwitch
                        label={translate("integrations.routes.hidden") as string}
                        checked={request.hidden}
                        value="hidden"
                        placement="end"
                        onChanged={(name: string, checked: boolean) => setRequest({ ...request, hidden: checked })}
                    />
                </Grid>
            </Grid>
        </DialogPopup>
    )
}

export default IntegrationRouteForm