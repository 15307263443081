import React from "react";
import { Grid, List, ListItem, ListItemText, Card, Box, IconButton, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import NumberFormat from "react-number-format";
import { formatDateToShow } from "../components/DateFormat";
import { Requisition } from "../model/Requisition";
import { PartialityRecord } from "../model/PurchaseOrder";
import PaymentsPercentageCard from "./PaymentsPercentageCard";
import PaymentsCard from "./PaymentsCard";
import { Payment } from "../model/Payment";

interface RequisitionQuotationProps {
    requisition: Requisition;
    showCfdis?: boolean;
    partiallityRecords?: PartialityRecord[];
    purchasePayments?: Payment[];
    cfdiPurchaseOrderId?: string;
}

export default function RequisitionQuotation(props: RequisitionQuotationProps) {

    return (
        <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
            <Grid item xs={12}>
                <QuotationCard requisition={props.requisition} />
            </Grid>
            <Grid item xs={12}>
                <PaymentsPercentageCard requisition={props.requisition} partiallityRecords={props.partiallityRecords} showCfdis={props.showCfdis} />
            </Grid>
            { "ONE_INVOICE_REQUISITION" === props.requisition?.partial_billing_type && 
            <Grid item xs={12}>
                <PaymentsCard purchasePayments={props.purchasePayments !== undefined ? props.purchasePayments : []} cfdi_payment={props.cfdiPurchaseOrderId} />
            </Grid> }
        </Grid>
    );
}

interface QuotationCardProps {
    requisition: Requisition;
}

function QuotationCard(props: QuotationCardProps) {
    return (
        <Card variant="outlined" >
            <Box px={2} pb={2}>
                <Grid container alignItems="flex-start" justify="space-between">
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.quotation.number}</strong>} secondary={translate("requisitions.overview.quotation.number")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={
                                    <IconButton aria-label="add" color="primary" size="small" href={props.requisition.quotation.file_url} target="_blank" rel="noopener noreferrer">
                                        <Typography variant="body2">{translate("buttons.view_document")}</Typography>
                                    </IconButton>
                                } secondary={translate("requisitions.overview.quotation.file")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{formatDateToShow(new Date(props.requisition.quotation.date))}</strong>}
                                    secondary={translate("requisitions.overview.quotation.date")} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={6}>
                        <List dense disablePadding>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>
                                    <NumberFormat value={props.requisition.quotation.amount} prefix="$ " decimalScale={2} thousandSeparator="," fixedDecimalScale={true} displayType="text" />
                                </strong>} secondary={translate("requisitions.overview.quotation.amount")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{formatDateToShow(new Date(props.requisition.quotation.due_date))}</strong>}
                                    secondary={translate("requisitions.overview.quotation.due_date")} />
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText primary={<strong>{props.requisition.payment_condition_name}</strong>} secondary={translate("requisitions.overview.quotation.payment_conditions")} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}