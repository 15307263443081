import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Divider, Button, IconButton, Hidden } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar, WarningSnackbar } from "../components/Snackbars";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { Cfdi, Cfdis, CfdisQueryParams, StatusGroup_IN_PROCESS, StatusGroup_VALIDATION, StatusGroup_RECEIVED, StatusGroup_DELIVERED_ERP, StatusGroup_POSTPONE, StatusGroup_PAYMENT_ORDER } from '../model/Cfdi';
import { CfdisListProps } from "../cfdi/CfdisList";
import { getCfdis } from "../api/ProviderCfdiApi";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import CfdisInProcessIcon from "@material-ui/icons/FindInPageTwoTone";
import ProviderCfdiMenu from "./menus/ProviderCfdiMenu";
import { exportCfdisSelected, exportAllCfdis, deleteCfdi, cancelCfdi, exportCfdiFiles, exportValidationsPdfCfdis } from "../api/TenantCfdiApi";
import { TenantCfdisRequest, ExportCfdisResponse, DeleteCfdiRequest } from "../model/TenantCfdi";
import DownloadExportationPopup from "../components/DownloadExportationPopup";
import CustomBackdrop from "../components/CustomBackdrop";
import CfdisConfirmPopup from "../components/ConfirmationPopup";
import CfdiDeletePopup from "../cfdis/popups/CfdiDeletePopup";
import RefreshTwoToneIcon from '@material-ui/icons/RefreshTwoTone';
import ReactHtmlParser from "html-react-parser";
import { Filter } from "@devexpress/dx-react-grid";
import Surface from "../components/Surface";
import { formatDateString, concatDates } from "../components/DateFormat";
import GridDx from "../components/GridDx";
import Semaphore from "../components/Semaphore";
import { isRoleOrParent } from "../model/Role";
import ProviderCfdisMenu from "./menus/ProviderCfdisMenu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RequestClarification from "../clarifications/RequestClarification";
import DownloadRange from "../components/DownloadRange";
import DeleteCfdiPopup from "../cfdis/popups/DeleteCfdiPopup";


export default function ListCfdisInProcess(props: CfdisListProps) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const paramsFromQueryString = (): CfdisQueryParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "status_group": typeof qs["status"] === "string" ? qs["status"] as string : "",
            "selected_ids": typeof qs["selected_ids"] === "string" ? qs["selected_ids"] as string : "",
            "identifier": typeof qs["identifier"] === "string" ? qs["identifier"] as string : "",
            "description": typeof qs["description"] === "string" ? qs["description"] as string : "",
            "date_start": typeof qs["date_start"] === "string" ? qs["date_start"] as string : "",
            "date_end": typeof qs["date_end"] === "string" ? qs["date_end"] as string : "",
            "send_at_start": typeof qs["send_at_start"] === "string" ? qs["send_at_start"] as string : "",
            "send_at_end": typeof qs["send_at_end"] === "string" ? qs["send_at_end"] as string : "",
            "type_filter": typeof qs["type_filter"] === "string" ? qs["type_filter"] as string : "",
            "authorized_at_start": typeof qs["authorized_at_start"] === "string" ? qs["authorized_at_start"] as string : "",
            "authorized_at_end": typeof qs["authorized_at_end"] === "string" ? qs["authorized_at_end"] as string : "",
            "payment_due_start": typeof qs["payment_due_start"] === "string" ? qs["payment_due_start"] as string : "",
            "payment_due_end": typeof qs["payment_due_end"] === "string" ? qs["payment_due_end"] as string : "",
            "total": typeof qs["total"] === "string" ? qs["total"] as string : "",
            "balance": typeof qs["balance"] === "string" ? qs["balance"] as string : "",
            "currency": typeof qs["currency"] === "string" ? qs["currency"] as string : "",
            "company_name": typeof qs["company_name"] === "string" ? qs["company_name"] as string : "",
            "referer_view": "IN_PROCESS",
        } as CfdisQueryParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Cfdis>();
    const [params, setParams] = useState<CfdisQueryParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<CfdisQueryParams>(paramsFromQueryString);

    const [cfdi, setCfdi] = useState<Cfdi>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [selectedCfdis, setSelectedCfdis] = useState<(number | string)[]>([]);
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string | JSX.Element | JSX.Element[]>();
    const [exportResult, setExportResult] = useState<ExportCfdisResponse>();
    const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    const [queryParam, setQueryParam] = useState<string>();
    const [openConfirmDialogDelete, setOpenConfirmDialogDelete] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState<boolean>(false);
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null);
    const [clarification, setClarification] = useState<boolean>(false);
    const [cfdisSelected, setCfdisSelected] = useState<Cfdi[]>([]);

    const paramsToFilters = () => {
        return [
            { columnName: 'identifier', value: params.identifier },
            { columnName: 'description', value: params.description },
            { columnName: 'date', value: concatDates(params.date_start, params.date_end) },
            { columnName: 'send_at', value: concatDates(params.send_at_start, params.send_at_end) },
            { columnName: 'type', value: params.type_filter },
            { columnName: 'authorized_at', value: concatDates(params.authorized_at_start, params.authorized_at_end) },
            { columnName: 'payment_due', value: concatDates(params.payment_due_start, params.payment_due_end) },
            { columnName: 'total', value: params.total },
            { columnName: 'balance', value: params.balance },
            { columnName: 'currency', value: params.currency },
            { columnName: 'company_name', value: params.company_name },
        ] as Filter[];
    };
    const [filters, setFilters] = useState<Filter[]>(paramsToFilters());

    const load = () => {
        setStatus("loading");
        pushHistory();
        params.status_groups = [    
            StatusGroup_IN_PROCESS,
            StatusGroup_VALIDATION,
            StatusGroup_RECEIVED,
            StatusGroup_DELIVERED_ERP,
            StatusGroup_POSTPONE,
            StatusGroup_PAYMENT_ORDER].join(",");
        getCfdis(context.session!.tenant!.id, context.session!.provider!.id,
            context.isGranted("SenderCFDIsRead") ? context.session!.user.id : "",
            pageSize, pageSize * page, params).then((response) => {
                setData(response);
                setSelectedCfdis([]);
            }).catch((error) => {
                setStatus(error.message);
            }).finally(() => {
                setStatus("loaded");
            });
    }

    useEffect(load, [context.session, page, pageSize, params, filters]);

    const setFiltersHandler = (filters: Filter[]) => {
        setFilters(filters);
        setParamsFromfilters(filters);
    };

    const setParamsFromfilters = (filters: Filter[]) => {
        let temp = params;
        filters.forEach(filter => {
            if (filter.value !== undefined) {
                switch (filter.columnName) {
                    case "identifier":
                        temp.identifier = filter.value;
                        break;
                    case "description":
                        temp.description = filter.value;
                        break;
                    case "company_name":
                        temp.company_name = filter.value;
                        break;
                    case "date":
                        let dates = filter.value.split(" - ");
                        if (dates.length === 2) {
                            temp.date_start = formatDateString(dates[0]);
                            temp.date_end = formatDateString(dates[1]);
                        } else {
                            temp.date_start = "";
                            temp.date_end = "";
                        }
                        break;
                    case "send_at":
                        let datesSend = filter.value.split(" - ");
                        if (datesSend.length === 2) {
                            temp.send_at_start = formatDateString(datesSend[0]);
                            temp.send_at_end = formatDateString(datesSend[1]);
                        } else {
                            temp.send_at_start = "";
                            temp.send_at_end = "";
                        }
                        break;
                    case "type":
                        temp.type_filter = filter.value;
                        break;
                    case "authorized_at":
                        let authorizedAtDates = filter.value.split(" - ");
                        if (authorizedAtDates.length === 2) {
                            temp.authorized_at_start = formatDateString(authorizedAtDates[0]);
                            temp.authorized_at_end = formatDateString(authorizedAtDates[1]);
                        } else {
                            temp.authorized_at_start = "";
                            temp.authorized_at_end = "";
                        }
                        break;
                    case "payment_due":
                        let paymentDues = filter.value.split(" - ");
                        if (paymentDues.length === 2) {
                            temp.payment_due_start = formatDateString(paymentDues[0]);
                            temp.payment_due_end = formatDateString(paymentDues[1]);
                        } else {
                            temp.payment_due_start = "";
                            temp.payment_due_end = "";
                        }
                        break;
                    case "total":
                        temp.total = filter.value;
                        break;
                    case "balance":
                        temp.balance = filter.value;
                        break;
                    case "currency":
                        temp.currency = filter.value;
                        break;
                    default: break;
                }
            }
        });
        setWorkingParams(temp);
        setParams(temp);
    };

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const pushHistory = () => {
        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["status_group"] = workingParams.status_group;
        qs["selected_ids"] = workingParams.selected_ids ? workingParams.selected_ids : "";
        qs["receptor"] = workingParams.receptor ? workingParams.receptor : "";
        qs["identifier"] = workingParams.identifier ? workingParams.identifier : "";
        qs["description"] = workingParams.description ? workingParams.description : "";
        qs["date_start"] = workingParams.date_start ? workingParams.date_start : "";
        qs["date_end"] = workingParams.date_end ? workingParams.date_end : "";
        qs["send_at_start"] = workingParams.send_at_start ? workingParams.send_at_start : "";
        qs["send_at_end"] = workingParams.send_at_end ? workingParams.send_at_end : "";
        qs["type_filter"] = workingParams.type_filter ? workingParams.type_filter : "";
        qs["authorized_at_start"] = workingParams.authorized_at_start ? workingParams.authorized_at_start : "";
        qs["authorized_at_end"] = workingParams.authorized_at_end ? workingParams.authorized_at_end : "";
        qs["payment_due_start"] = workingParams.payment_due_start ? workingParams.payment_due_start : "";
        qs["payment_due_end"] = workingParams.payment_due_end ? workingParams.payment_due_end : "";
        qs["total"] = workingParams.total ? workingParams.total : "";
        qs["balance"] = workingParams.balance ? workingParams.balance : "";
        qs["currency"] = workingParams.currency ? workingParams.currency : "";
        qs["page"] = "0";
        qs["company_name"] = workingParams.company_name ? workingParams.company_name : "";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        setQueryParam(queryString.stringify(qs));
        history.push(url);
    };

    const onAppliedFilter = () => {
        pushHistory();
        if (cfdisSelected.length > 0) {
            setCfdisSelected([]);
        }
        setPage(0);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onClickedOptions = (cfdi: Cfdi) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCfdi(cfdi);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setCfdi(undefined);
    };

    const onCloseSnackbars = () => {
        setError(undefined);
        setSuccess(undefined);
        setWarning(undefined);
    };

    const onDownloadCfdis = () => {
        setGridAnchorEl(null)
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            exportCfdisSelected(context.session!.tenant!.id, { cfdi_ids: selectedCfdis } as TenantCfdisRequest).then((response) => {
                setOpenBackdrop(false);
                if (response.url) {
                    setExportResult(response);
                } else {
                    if (response.total > 0) {
                        setSuccess(translate("cfdis.email_export") as string);
                    } else {
                        setWarning(translate("cfdis.empty_export") as string);
                    }
                }
            }).catch((error) => {
                setError(error.message);
            });
        } else {
            setWarning(translate("cfdis.empty_selection") as string);
        }
    }

    const onCloseDownloadExportationPopup = () => {
        setExportResult(undefined);
    }

    const onDownloadAllCfdis = (startDate: Date, endDate: Date) => {
        setOpenBackdrop(true);
        setOpenConfirmDialog(false);
        let user = "";
        if (context.isGranted("SenderCFDIsRead")) {
            user = context.session!.user.id;
        }
        let p = params;
        p.provider_id = context.session!.provider!.id;
        p.start_date = startDate;
        p.end_date = endDate;
        exportAllCfdis(context.session!.tenant!.id, user, p).then((response) => {
            setOpenBackdrop(false);
            if (response.url) {
                setExportResult(response);
            } else {
                if (response.total > 0) {
                    setSuccess(translate("cfdis.email_export") as string);
                } else {
                    setWarning(translate("cfdis.empty_export") as string);
                }
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(()=>{
            setOpenBackdrop(true);
        });
    }

    const onOpenConfirmDialog = () => {
        setGridAnchorEl(null)
        if (data?.total && data?.total > 0) {
            setOpenConfirmDialog(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_export") as string);
        }
    }

    const onClarification = () => {
        setGridAnchorEl(null);
        if (data && selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            setCfdisSelected(data.items.filter(item => selectedCfdis.find(cfdiId => cfdiId === item.id)));
            setClarification(true);
        } else {
            setWarning(translate("cfdis.no_cfdis_to_clarification") as string);
        }
    }

    const onClickedMore = (event: React.MouseEvent<HTMLElement>) => {
        setGridAnchorEl(event.currentTarget);
    };

    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const onClickedRow = (cfdi: Cfdi) => {
        pushHistory();
        setRedirect(`/cfdis/${cfdi.id}/details?referer=sent&${queryParam}`);
    };

    const onDeleteComprobante = () => {
        setAnchorEl(null)
        if (!cfdi) return;

        if (cfdi.metadata.authorizer_name === undefined) {
            setShowDeleteDialog(true);
        } else {
            setOpenConfirmDialogDelete(true);
        }
    };

    const onConfirmDeleteComprobante = (razon: string) => {
        setOpenConfirmDialogDelete(false);
        setStatus("loading");
        if (context.isGrantedAny(["ProviderCFDIsDelete"])) {
            deleteCfdi(context.session!.tenant!.id, cfdi!.id, { comments: razon } as DeleteCfdiRequest).then(() => {
                setStatus("loaded");
                load();
                setSuccess(translate("cfdis.pay_pending.actions.delete.success") as string);
            }).catch((error) => {
                setStatus("loaded");
                setError(error.message);
            });

        }
    };

    const onCloseConfirmDialogDelete = () => {
        setOpenConfirmDialogDelete(false);
    }

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    const onError = (error: string) => {
        setError(error);
        setShowDeleteDialog(false);
    };

    const onCancel = () => {
        setShowDeleteDialog(false);
    };

    const onSuccessDelete = () => {
        setShowDeleteDialog(false);
        load();
        setSuccess(translate("cfdis.pay_pending.actions.delete.success") as string);
    }

    const onOpenCancelCfdi = () => {
        setAnchorEl(null);
        if (!cfdi) return;
        setOpenCancelConfirmDialog(true);
    }

    const onCancelCfdi = () => {
        setOpenCancelConfirmDialog(false);
        if (!cfdi) return;
        setOpenBackdrop(true);
        cancelCfdi(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.related_cfdi) {
                setWarning(ReactHtmlParser(
                    translate('cfdis.cancel_cfdi.linked_cfdis_not_cancelled', { "folio": cfdi.identifier, "cfdi_link": `/cfdis/${response.related_cfdi}/details?referer=sent&${queryParam}` }) as string
                ));
            } else {
                load();
            }
        }).catch((errors) => {
            setError(errors.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelectedCfdis(selected);
    };

    const onCloseClarification = () => {
        setOpenBackdrop(false);
        setClarification(false);
    };
    const onRequestClarification = (comment: string) => {
        setOpenBackdrop(false);
        setClarification(false);
        setSuccess(comment);
    };

    const onDownloadCfdiFiles = () => {
        setAnchorEl(null);
        setCfdi(undefined);
        setOpenBackdrop(true);
        if (!cfdi) return;
        exportCfdiFiles(context.session!.tenant!.id, cfdi.id).then((response) => {
            if (response.url) {
                setExportResult(response);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
        });
    }

    const onDownloadWithOutConnector = () => {
        setGridAnchorEl(null);
        setAnchorEl(null);
        if (!!cfdi) {
            setOpenBackdrop(true);
            let exportParams = params;
            exportParams.selected_ids = cfdi.id;
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        }
    }

    const onDownloadValidationsSelected = () => {
        setGridAnchorEl(null);
        setAnchorEl(null);
        if (selectedCfdis && selectedCfdis.length > 0) {
            setOpenBackdrop(true);
            let exportParams = params;
            exportParams.selected_ids = selectedCfdis.join(",");
            exportParams.start_date = undefined;
            exportParams.end_date = undefined;
            onDownloadValidationsPdf(exportParams);
        }
    }
    const onDownloadValidationsPdf = (paramsExport: CfdisQueryParams) => {
        exportValidationsPdfCfdis(context.session!.tenant!.id, paramsExport).then((response) => {
            if (response.url) {
                window.open(response.url, "_blank")
            } else {
                setSuccess(translate("cfdis.email_export") as string);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setOpenBackdrop(false);
            setAnchorEl(null);
            setGridAnchorEl(null);
        });
    }



    return (
        <Surface title={props.title}
            subtitle={translate("cfdis.group.title") as string}
            icon={<CfdisInProcessIcon />}
            className="PaperPagination"
            titleActions={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        <Hidden xsDown>
                            <Grid item xs="auto">
                                <IconButton color="default" size="small" onClick={onClickedMore} disabled={status === "loading"}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            }>
            <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                <Grid item xs={12} sm>
                    <ValidatedInput type="text" id="search" name="search" label={translate("cfdis.filter") as string}
                        margin="dense" disabled={false}
                        value={workingParams.search} onValueChanged={onFilterChanged} />
                </Grid>
                <Grid item xs="auto">
                    <Button onClick={onAppliedFilter} variant="outlined" color="secondary" size="medium">
                        {translate("buttons.search")}
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <IconButton color="primary" size="small" onClick={load} disabled={status === "loading"}  >
                        <RefreshTwoToneIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className="focaltec-small">
                <GridDx
                    loading={status === "loading"}
                    rows={data ? data.items.map(item => {
                        return {
                            ...item,
                            stage:
                                <Semaphore cfdi={item} />
                        };
                    }) : []}
                    page={page}
                    pageSize={pageSize}
                    totalRows={data ? data.total : 0}
                    columns={props.columns ? props.columns : []}
                    columnsFormat={props.columnsFormat}
                    selectionIds={selectedCfdis}
                    clickRowColumns={props.clickRowColumns}
                    onClickRow={onClickedRow}
                    onClickedOptions={onClickedOptions}
                    setSelectionHandler={setSelectionHandler}
                    defaultExpandedGroups={props.defaultExpandedGroups}
                    amountCurrencyColumns={props.currencyColumns}
                    dateColumns={props.dateColumns}
                    leftColumns={props.leftColumns}
                    rightColumns={props.rightColumns}
                    textColumns={props.textColumns}
                    filters={filters}
                    grouping={props.grouping}
                    customFormatColumns={props.customPlugins}
                    setFiltersHandler={setFiltersHandler}
                    onChangedPage={onChangedPage}
                    onChangedPageSize={onChangedPageSize}
                    setTableCell={props.tableCell}
                    setCheckCell={props.checkCell}
                />
            </div>
            <SuccessSnackbar message={success} onClose={onCloseSnackbars} />
            <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
            <WarningSnackbar message={warning} onClose={onCloseSnackbars} />
            <CustomBackdrop open={openBackdrop} message={translate("cfdis.processing") as string} />
            {cfdi && anchorEl && (
                <ProviderCfdiMenu cfdi={cfdi} onCancel={onOpenCancelCfdi}
                    anchor={anchorEl} referer={`sent&${queryParam}`}
                    onDownloadFiles={onDownloadCfdiFiles}
                    onClose={onCloseOption}
                    onDeleteInProcess={onDeleteComprobante}
                    onDownloadValidationsPdf={onDownloadWithOutConnector}
                />
            )}
            {openConfirmDialog && (
                <DownloadRange
                    onClose={onCloseConfirmDialog}
                    onExportRange={onDownloadAllCfdis}
                    family="CFDIS" />
            )}
            {exportResult && exportResult.url && (
                <DownloadExportationPopup
                    title={translate("cfdis.popup.export.title") as string}
                    message={translate("cfdis.popup.export.description", { "total": exportResult.total }) as string}
                    url={exportResult.url}
                    onClose={onCloseDownloadExportationPopup} />
            )}

            {openConfirmDialogDelete && cfdi && (
                <DeleteCfdiPopup
                    cfdi={cfdi}
                    messageDefault={translate("cfdis.pay_pending.actions.delete.description", { "provider": cfdi ? cfdi.metadata.authorizer_name : "" }) as string}
                    onConfirmDeleteComprobante={onConfirmDeleteComprobante}
                    onCloseConfirmDialogDelete={onCloseConfirmDialogDelete} />
            )}

            {showDeleteDialog && cfdi &&
                <CfdiDeletePopup cfdi={cfdi}
                    tenantId={context.session!.tenant!.id}
                    providerId={context.session!.provider!.id}
                    onCompleted={onSuccessDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            }
            {openCancelConfirmDialog && (
                <CfdisConfirmPopup
                    doAction={onCancelCfdi}
                    onClose={() => setOpenCancelConfirmDialog(false)}
                    title={translate("cfdis.cancel_cfdi.title") as string}
                    message={translate("cfdis.cancel_cfdi.message") as string}
                    secondary={translate("cfdis.cancel_cfdi.secondary") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            {gridAnchorEl && (
                <ProviderCfdisMenu anchor={gridAnchorEl}
                    onClose={() => setGridAnchorEl(null)}
                    onDownload={onDownloadCfdis}
                    onDownloadAll={onOpenConfirmDialog}
                    onClarification={onClarification}
                    origin="in-process"/>
            )}
            {clarification && context.session!.provider && (
                <RequestClarification
                    doAction={onRequestClarification}
                    onClose={onCloseClarification}
                    isCommentRequired={true}
                    cfdis={cfdisSelected}
                    providerId={context.session!.provider.id}
                    onError={(error) => setError(error)}
                />
            )}
        </Surface>
    );
}
