import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { Redirect, useHistory, Link } from "react-router-dom";

import { Grid, IconButton, Divider, Button, Fab } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GroupIcon from "@material-ui/icons/SupervisedUserCircle";
import AddIcon from "@material-ui/icons/Add";

import Pagination, { initialPage, initialPageSize, getOffset } from "../components/Pagination";
import { list, inactivate } from "../api/TeamAPI";
import { TeamsResponse as Teams, Team, TeamListParams } from "../model/Team";
import { AppContext } from "../context/AppContext";
import translate from "../i18n/Translator";
import ValidatedInput from "../components/ValidatedInput";
import { RouterParams } from "../router/RouterParams";
import TeamMenu from "./TeamMenu";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";
import ConfirmationPopup from "../components/ConfirmationPopup";

import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";

export default function TeamsList({ match }: RouterParams) {
    const context = useContext(AppContext);
    const history = useHistory();
    const qs = queryString.parse(window.location.search);
    const [tenantId] = useState<string>(context.session?.tenant?.id ?? match.params.tenantId);
    const paramsFromQueryString = (): TeamListParams => {
        return {
            "search": typeof qs["search"] === "string" ? qs["search"] as string : "",
            "ids": typeof qs["ids"] === "string" ? qs["ids"] as string : "",
            "tenant_id": tenantId ?? "",
        } as TeamListParams;
    };

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<Teams>();
    const [params, setParams] = useState<TeamListParams>(paramsFromQueryString);
    const [workingParams, setWorkingParams] = useState<TeamListParams>(paramsFromQueryString);
    const [inactivatePopup, setInactivatePopup] = useState<boolean>(false);

    const [team, setTeam] = useState<Team>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const [page, setPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [redirect, setRedirect] = useState<string>();

    const load = () => {
        setStatus('loading');
        let offset = getOffset(page, pageSize);
        list(tenantId, pageSize, offset, params).then((response) => {
            setData(response);
            setStatus("loaded");
            if (match.params.success) {
                setSuccess(match.params.success);
            }
        }).catch((error) => {
            setStatus(error.message);
        });
    };

    // eslint-disable-next-line
    useEffect(load, [context.session, page, pageSize, params]);

    const onChangedPage = (page: number) => {
        setPage(page);
        setData(undefined);
    };

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        setData(undefined);
    };

    const onAppliedFilter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let qs = queryString.parse(window.location.search);
        qs["search"] = workingParams.search;
        qs["page"] = "1";

        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setPage(1);
        setParams(workingParams);
    };

    const onFilterChanged = (name: string, value: string, inputRef: any) => {
        setWorkingParams({ ...workingParams, [name]: value });
    };

    const onInactivateTeam = () => {
        inactivate(tenantId, team!.id).then((response) => {
            setSuccess(translate("requisitions.teams.inactivate_success") as string);
            setParams(workingParams);
        }).catch((error) => {
            setStatus(error.message);
        });
        setInactivatePopup(false);
        setTeam(undefined);
    };

    const onClickedOptions = (team: Team) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setTeam(team);
    };

    const showInactivatePopup = () => {
        setAnchorEl(null);
        setInactivatePopup(true);
    };

    const hideResendEmailPopup = () => {
        setInactivatePopup(false);
        setTeam(undefined);
    };

    const onCloseOption = () => {
        setAnchorEl(null);
        setTeam(undefined);
    };

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    const onClickedRow = (team: Team) => {
        let url = window.location.pathname + "?" + queryString.stringify(qs);
        history.push(url);
        setRedirect(`/teams/${team.id}`);
    };

    if (redirect) {
        return (
            <Redirect to={redirect} />
        );
    }

    return (
        <Pagination title={translate("requisitions.teams.title")}
            icon={<GroupIcon />}
            page={page} pageSize={pageSize} count={data ? data.items.length : 0} total={data ? data.total : 0}
            onChangedPage={onChangedPage} onChangedPageSize={onChangedPageSize} action={
                <Grid >
                    <Grid container alignItems="center" justify="flex-end" spacing={1}>
                        {context.isGrantedAny(["TeamsCreate"]) ?
                            (<Grid item xs="auto">
                                <Link to={`/teams/create`}>
                                    <Fab color="primary" size="small" title={translate("buttons.add") as string}>
                                        <AddIcon />
                                    </Fab>
                                </Link>
                            </Grid>) : undefined}
                    </Grid>
                </Grid>}>
            <form autoComplete="off" noValidate onSubmit={onAppliedFilter}>
                <Grid container alignItems="center" justify="flex-end" className="TableFilter" spacing={1}>
                    <Grid item xs={12} md>
                        <ValidatedInput type="text" id="search" name="search" label={translate("requisitions.teams.search") as string}
                            margin="dense" disabled={false}
                            value={workingParams.search} onValueChanged={onFilterChanged} />
                    </Grid>
                    <Grid item xs="auto">
                        <Button type="submit" variant="outlined" color="secondary" size="medium">
                            {translate("buttons.search")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Gridable
                items={data ? data.items : []}
                loading={status === "loading"}
                error={status !== "loading" && status !== "loaded" ? status : undefined}
                empty={translate("requisitions.teams.empty") as string}
                onClick={onClickedRow}
                columns={[
                    {
                        title: translate("requisitions.teams.name") as string,
                        converter: (team) => (
                            <div>
                                <Ellipsis text={`${team.name}`} lenght={0} uppercased={false} />
                            </div>
                        ),
                        fullWidth: true,
                        xs: true,
                    },
                    {
                        title: translate("requisitions.teams.members") as string,
                        converter: (team) => team.members.length,
                        xs: 5,
                        sm: 5,
                        md: 5,
                        lg: 5,
                        xl: 5
                    },
                    {
                        title: "",
                        converter: (team) => (
                                <IconButton aria-label="options" color="default" size="small" onClick={onClickedOptions(team)}>
                                    <MoreVertIcon />
                                </IconButton>
                        ),
                        fullWidth: true,
                        justify: "flex-end",
                        xs: "auto"
                    }
                ]} />
            {team && anchorEl &&
                <TeamMenu 
                    tenantId={tenantId}
                    urlTeam={`teams/${team.id}`}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onInactivated={showInactivatePopup} />
            }
            {team && inactivatePopup && (
                <ConfirmationPopup
                    doAction={onInactivateTeam}
                    onClose={hideResendEmailPopup}
                    title={translate("requisitions.teams.cancel_popup.title", { "name": team.name }) as string}
                    message={translate("requisitions.teams.inactivate_message") as string}
                    button={translate("buttons.accept") as string}
                />
            )}
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
        </Pagination>
    );
}