import React from "react";
import { Tooltip } from "@material-ui/core";
import translate from "../i18n/Translator"
import * as MuiIcons from "@material-ui/icons";
import { ErrorIcon } from "./Icons";

interface DynamicIconProps {
    icon: string;
    includeColor?: boolean;
}

function DynamicIcon({ icon: iconName, includeColor = true }: DynamicIconProps) {
    const icons = MuiIcons as any
    const icon = icons[iconName]

    if (icon) {
        return React.createElement(icon, includeColor ? { color: "primary" } : undefined)
    }

    return (
        <Tooltip title={translate("invalid_icon") as string}>
            <ErrorIcon color="error" />
        </Tooltip>
    )
}

export default DynamicIcon