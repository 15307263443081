import React from "react";
import { Typography, Menu, MenuItem, Fade, ListItemIcon } from "@material-ui/core";
import translate from "../i18n/Translator";
import PaymentIcon from '@material-ui/icons/PaymentTwoTone';
import EditIcon from '@material-ui/icons/EditTwoTone';
import BankIcon from '@material-ui/icons/AccountBalanceTwoTone';
import RejectIcon from '@material-ui/icons/ThumbDownTwoTone';

interface PaymentOrderMenuActionsProps {
    isShowPaymentFile: boolean;
    isShowEdit: boolean;
    isShowPendingAll: boolean;
    isShowReject: boolean;
    anchor: HTMLElement | null;
    onClose(): any;
    onCreate(): any;
    onOpenPayPendingCfdis(): any;
    onEditPaymentOrder(): any;
    onReject(): any;
}

export default function PaymentOrderMenuActions(props: PaymentOrderMenuActionsProps) {
    return (
        <Menu
            id="payment-order-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {props.isShowEdit &&
                <MenuItem button onClick={props.onEditPaymentOrder} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {props.isShowPaymentFile && 
                <MenuItem button onClick={props.onCreate}  dense>
                    <ListItemIcon>
                        <BankIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.create_payment_file")}</Typography>
                </MenuItem>
            }
            { props.isShowPendingAll &&
                <MenuItem button onClick={props.onOpenPayPendingCfdis} dense>
                    <ListItemIcon>
                        <PaymentIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.pay_all_pending_button")}</Typography>
                </MenuItem>}
            { props.isShowReject &&
                <MenuItem button onClick={props.onReject} dense>
                    <ListItemIcon>
                        <RejectIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment_order.reject_title")}</Typography>
                </MenuItem>
            }
        </Menu>);
}
