import React, { useContext, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import { EditIcon } from "../components/Icons";

import translate from "../i18n/Translator";
import { SpecializedServiceDocument } from "../model/SpecializedServiceDocument";
import { AppContext } from "../context/AppContext";

interface SpecializedServiceDocumentMenuProps {
    specializedServiceDocument: SpecializedServiceDocument;
    anchor: HTMLElement | null;
    onClose(): any;
    onUpdate(): any;
}

export default function SpecializedServiceDocumentMenu(props: SpecializedServiceDocumentMenuProps) {
    const context = useContext(AppContext);
    const [isUpdateGranted] = useState(context.isGranted("SpecializedServiceDocumentsUpdate"));

    return (
        <Menu
            id="specialized-service-document-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem button onClick={props.onUpdate} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate("specializedservicedocument.edit_expiration_day")}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    );

}