import { User } from "./User";
import { Entity as Provider } from "./Provider";
import { ZipUpload } from "./ZipUpload";

export interface IntegrationLog {
    id: string;
    tenant_id?: string;
    payable_document_id: string;
    payable_document_external_id: string;
    user_id?: string;
    user?: User;
    provider_id: string;
    status: IntegrationLogStatus;
    type: IntegrationLogDocumentType;
    source: IntegrationLogSource;
    created_at: Date;
    completed_at: Date;
    plugin: string;
    ip: string;
    request: string;
    response: string;
    provider?: Provider;
    file?: string;
    stacktrace?: string;
    retry_data?: RetryData;
    retryable: boolean;
}

export interface RetryData {
    type: string;
}

export interface ErpContact {
    contact_name: string;
    email: string;
}

export interface IntegrationLogs {
    items: IntegrationLog[];
    total: number;
    erp_contact?: ErpContact;
    zip_upload?: ZipUpload;
}

export interface IntegrationLogsQueryParams {
    id: string;
    user_email: string;
    status: string;
    provider_name: string;
    created_at: string;
    completed_at: string;
    type: string;
    payable_document_id: string;
    payable_document_external_id: string;
    source: string;
    plugin: string;
    ip: string;
    request: string;
    response: string;
    provider_id: string;
    user_id: string;
    zipUploadId?: string;
}

export interface IntegrationLogRequest {
    payable_document_id?: string;
    payable_document__external_id?: string;
    user_id?: string;
    provider_id: string;
    status: IntegrationLogStatus;
    type: IntegrationLogDocumentType;
    source: IntegrationLogSource;
    created_at: Date;
    completed_at: Date;
    plugin?: string;
    ip?: string;
    request?: string;
    response?: string;
}

export type IntegrationLogStatus = 'ok' | 'error' | 'in_progress' | 'not_required';

export type IntegrationLogDocumentType = 'purchase_order' | 'warehouse_delivery' | 'invoice' | 'payment' | 'payment_order' | 'payment_cfdi' | 'zip';

export type IntegrationLogSource = 'erp' | 'pdp';

export const STATUSES: IntegrationLogStatus[] = ['ok', 'error', 'in_progress', 'not_required'];

export const DOCUMENT_TYPES: IntegrationLogDocumentType[] = ['purchase_order', 'warehouse_delivery', 'invoice', 'payment', 'payment_order', 'payment_cfdi', 'zip', 'requisition'];

export const DOCUMENT_SOURCES: IntegrationLogSource[] = ['erp', 'pdp'];
