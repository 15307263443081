import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Typography, Box, Fab } from "@material-ui/core";
import translate from "../i18n/Translator";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { Cfdi } from "../model/Cfdi";
import { ProrateCfdi, ProrationRequest, CfdiProrateRequest } from "../model/Prorate";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import NumberFormat from 'react-number-format';
import { WarningSnackbar } from "../components/Snackbars";
import { AppContext } from "../context/AppContext";
import { updateCfdiProration } from "../api/TenantCfdiApi";
import { NumberValidator } from "../components/Validators";

interface PayableDocumentProratesProps {
    cfdi: Cfdi;
    onChange(prorate: ProrateCfdi[]): any;
    onError(error: string): any;
}

export default function PayableDocumentProrates(props: PayableDocumentProratesProps) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<string>("loading");
    const [request, setRequest] = useState<ProrateCfdi[]>([]);
    const [editable, setEditable] = useState<boolean>(false);
    const [hasProrate, setHasProrate] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>();
    const total = props.cfdi.type === "FOREIGN" ? props.cfdi.total : (props.cfdi.metadata?.subtotal_base ?? 0);
    const isArchived = "ARCHIVED" === props.cfdi.metadata?.cfdi_status_group;

    const load = () => {
        const proration = props.cfdi.metadata.cfdi_proration;
        setHasProrate(proration && proration.prorates && proration.prorates.length > 0);
        if (proration && proration.prorates && proration.prorates.length > 0) {
            let temp = proration.prorates;
            temp.forEach((prorate) => prorate.past_amount = prorate.amount);
            if (temp.length === 1) {
                temp[0].amount = total;
            }
            setRequest(temp);
        }
        if (proration.provider_ledger_assignment_changed) {
            setWarning(translate("cfdis.prorate.message_prorate_has_changed") as string);
        }
        setStatus("loaded");
    };

    useEffect(load, [props.cfdi.metadata.cfdi_proration]);

    const onSave = () => {
        let sum = 0;
        request.forEach((field) => sum = sum + field.amount);
        if (Number((sum).toFixed(2)) !== Number((total).toFixed(2))) {
            setWarning(translate("cfdis.prorate.warning") as string);
        } else {
            setEditable(false);
            let temp = {
                signature: props.cfdi.metadata.cfdi_proration.signature,
                cfdi_prorates: []
            } as ProrationRequest;
            request.forEach((prorate) => {
                let prorateTemp = {
                    ledger_account_number: prorate.ledger_account.number,
                    cost_center_erp_id: prorate.cost_center.erp_id,
                    amount: prorate.amount
                } as CfdiProrateRequest;
                temp.cfdi_prorates.push(prorateTemp);
            })
            updateCfdiProration(context.session!.tenant!.id, props.cfdi.id, temp).then((metadata) => {
                let temp = props.cfdi.metadata.cfdi_proration.prorates;
                temp.forEach((prorate) => prorate.past_amount = prorate.amount);
                if (temp.length === 1) {
                    temp[0].amount = total;
                }
                setRequest(temp);
                props.onChange(request);
            }).catch((error) => {
                props.onError(error.message);
            });
        }
    };

    const onEditFields = () => {
        if(props.cfdi.metadata.read_only?.active){
            setWarning(props.cfdi.metadata.read_only.comments);
            return;
        }
        setEditable(true);
    }

    const onCancelEditFields = () => {
        let temp = request;
        temp.forEach((prorate) => prorate.amount = prorate.past_amount);
        setRequest(temp);
        setEditable(false);
    }

    const hasChanged = (name: string, value: number, inputRef: InputRef) => {
        let temp = request;
        temp.forEach((field) => {
            if (field.ledger_account.number + field.cost_center.erp_id === name && value >= 0) {
                field.amount = value;
            }
        });
        setRequest(temp);
    };

    const onClosedSnackbar = () => {
        setWarning(undefined);
    };


    return (
        <Grid container>
            <Box mb={2} px={2} width="100%">
                <Grid item xs={12}>
                    <Box mb={1}>
                        <Grid container>
                            <Grid item xs>
                                {translate("cfdis.prorate.title")}
                            </Grid>

                            {!isArchived && !editable && hasProrate && request.length > 1 &&
                                <Grid item>
                                    <Fab aria-label="add" color="primary" size="small" title={translate("cfdis.prorate.icon_edit") as string} onClick={onEditFields} >
                                        <EditIcon />
                                    </Fab>
                                </Grid>
                            }
                            {editable && hasProrate &&
                                <Grid item>
                                    <Button aria-label="add" color="primary" size="small" onClick={onCancelEditFields} >
                                        <Typography variant="body2">{translate("cfdis.prorate.icon_cancel")} </Typography>
                                    </Button>
                                    <Fab aria-label="add" color="secondary" size="small" title={translate("cfdis.prorate.icon_save") as string} onClick={onSave} >
                                        <SaveIcon />
                                    </Fab>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {!hasProrate && context.isGranted("") &&
                        <Typography variant="body2">{translate("cfdis.prorate.message_config")} </Typography>
                    }
                    {!hasProrate && !context.isGranted("") &&
                        <Typography variant="body2">{translate("cfdis.prorate.message_prorate_not_exists")} </Typography>
                    }
                    {hasProrate &&
                        <Gridable
                            items={request}
                            loading={status === "loading"}
                            error={status !== "loading" && status !== "loaded" ? status : undefined}
                            columns={[
                                {
                                    title: translate("cfdis.prorate.columns.cuenta_code") as string,
                                    converter: (prorate) => prorate.ledger_account.number,
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                },
                                {
                                    title: translate("cfdis.prorate.columns.cuenta_description") as string,
                                    converter: (prorate) => prorate.ledger_account.name,
                                    xs: 4,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                },
                                {
                                    title: translate("cfdis.prorate.columns.centro_costo") as string,
                                    converter: (prorate) => (
                                        <Grid item xs>
                                            <Ellipsis text={prorate.cost_center.name} lenght={100} /><br />
                                            <Ellipsis text={prorate.cost_center.erp_id} lenght={0} secondary uppercased={false} />
                                        </Grid>
                                    ),
                                    xs: 4,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                },
                                {
                                    title: translate("cfdis.prorate.columns.amount") as string,
                                    converter: (prorate) => (<FieldEditable field={prorate} editable={editable} onValueChanged={hasChanged} />),
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }
                            ]} />
                    }
                </Grid>
            </Box>
            <WarningSnackbar message={warning} onClose={onClosedSnackbar} />
        </Grid>
    );
}

interface FieldEditableProps {
    editable: boolean;
    field: ProrateCfdi;
    onValueChanged(name: string, value: number, inputRef: InputRef): any;
}

function FieldEditable(props: FieldEditableProps) {
    if (props.editable) {
        return (
            <ValidatedInput
                type="number"
                id={props.field.ledger_account.number + props.field.cost_center.erp_id}
                name={props.field.ledger_account.number + props.field.cost_center.erp_id}
                value={props.field.amount.toString()}
                isValueNumber={true}
                label=""
                required={false}
                margin="dense"
                onValueChanged={props.onValueChanged}
                validator={new NumberValidator(true)}
            />
        );
    }
    return (
        <NumberFormat value={props.field.amount} prefix="$ " decimalScale={2} fixedDecimalScale={true} displayType="text" />
    );
}