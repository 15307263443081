import React, { useState, useEffect } from "react";
import { IconButton, Typography, Grid, Box } from "@material-ui/core";
import { ErrorSnackbar, SuccessSnackbar } from "../components/Snackbars";

import translate from "../i18n/Translator";
import { Configuration } from "../model/TenantConfiguration";
import { ConfigurationCatalog as Catalog, OptionsCatalogAutocomplete } from "../model/ConfigurationCatalog";
import { TermCondition as TermConditionRequest } from "../model/TermsConditions";
import { updateTermsConditions } from "../api/TenantConfigurationApi";
import DateFormat from "../components/DateFormat";
import TenantConfigurationMenu from "./TenantConfigurationsMenu";
import ConfirmationPopup from "../components/ConfirmationPopup";
import TenantConfigurationUpdatePopUp from "./TenantConfigurationUpdatePopUp";
import TermConditionCreateModal from "../terms_conditions/TermConditionCreateModal";
import { update } from "../api/TenantConfigurationApi";
import { TenantConfiguration, TenantConfigurationRequest } from "../model/TenantConfiguration";
import Gridable from "../components/Gridable";
import Ellipsis from "../components/Ellipsis";
import MoreVertIcon from "@material-ui/icons/MoreVert";

interface TenantConfigurationsFieldsProps {
    tenantId: string;
    fields?: Configuration[];
    catalog: Catalog[];
    category: string;
    onUpdateConfiguration(configuration: TenantConfiguration, desactivate: boolean): any;
}

export default function TenantConfigurationsFields(props: TenantConfigurationsFieldsProps) {

    const [status, setStatus] = useState<string>("loading");

    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDesactivate, setOpenDesactivate] = useState(false);
    const [isTermCodition, setIsTermCondition] = useState(false);

    const [selectedConfiguration, setSelectedConfiguration] = useState<Configuration>();
    const [data, setData] = useState<Configuration[]>();

    useEffect(() => {
        setData(undefined);
        setStatus("loading");
        if (props.fields) {
            setData(props.fields.filter(cat => props.catalog.filter(c => c.property_name === cat.property_name).length > 0));
        } else {
            setData({} as Configuration[]);
        }
        setStatus("loaded");
    }, [props, status]);

    const onOpenDesactivate = () => {
        setAnchorEl(null);
        setOpenDesactivate(true);
    }

    const onOpenUpdate = () => {
        setAnchorEl(null);
        setOpenUpdate(true);
    }

    const onDesactivateConfiguration = () => {
        if (selectedConfiguration) {
            if (getField(selectedConfiguration).required) {
                setError(translate("tenant_configurations.error_required") as string);
            } else {
                update(props.tenantId, { property_name: selectedConfiguration?.property_name, active: false } as TenantConfigurationRequest)
                    .then((response) => {
                        props.onUpdateConfiguration(response, true);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        }
    }

    const onUpdateConfiguration = (value: string) => {
        if (selectedConfiguration) {
            update(props.tenantId, { property_name: selectedConfiguration.property_name, value: value, active: selectedConfiguration.active } as TenantConfigurationRequest)
                .then((response) => {
                    props.onUpdateConfiguration(response, false);
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }

    const onUpdateTermsConditions = (request: TermConditionRequest) => {
        updateTermsConditions(props.tenantId, request).then((response) => {
            props.onUpdateConfiguration(response, false);
        }).catch((error) => {
            setError(error.message);
        });
    }

    const optionsCatalog = (field: Configuration) => {
        let listTemp = [] as OptionsCatalogAutocomplete[];
        if (getField(field).catalog_options) {
            getField(field).catalog_options.forEach((option) => {
                let temp = { title: translate("tenant_configurations.options_category." + option), value: option } as OptionsCatalogAutocomplete;
                listTemp.push(temp);
            });
            return listTemp;
        } else {
            return [];
        }

    }

    const onClose = () => {
        setOpenUpdate(false);
        setOpenDesactivate(false);
        setSelectedConfiguration(undefined);
        setAnchorEl(null);
    }

    const onClosedSnackbar = () => {
        setError("");
        setSuccess("");
    };

    const getField = (field: Configuration) => {
        return props.catalog.filter(c => c.property_name === field.property_name)[0];
    };

    const onClickedOptions = (field: Configuration) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedConfiguration(field);
        setIsTermCondition(checkTermCondition(field));
    };

    const checkTermCondition = (field: Configuration) => {
        return field.property_name === "terms_and_conditions";
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={2} mb={1} px={2}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle2" color="primary">{translate(`tenant_configurations.category.${props.category}`)}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Gridable
                    items={data ? data : []}
                    loading={false}
                    empty={translate("tenant_configurations.empty_category") as string}
                    columns={[
                        {
                            title: translate("tenant_configurations.columns.property") as string,
                            converter: (field) => (
                                <div style={{ overflowWrap: "anywhere" }}>
                                    {field.property_name}
                                </div>
                            ),
                            xs: 5,
                            sm: 5,
                            md: 5,
                            lg: 3,
                        },
                        {
                            title: translate("tenant_configurations.columns.name") as string,
                            converter: (field) => (
                                <div style={{ overflowWrap: "anywhere" }}>
                                    {getField(field).name}
                                </div>
                            ),
                            xs: false,
                            sm: false,
                            md: false,
                            lg: true,
                        },
                        {
                            title: translate("tenant_configurations.columns.description") as string,
                            converter: (field) => (
                                <div style={{ overflowWrap: "anywhere" }}>
                                    {translate("configuration_catalog." + field.property_name) as string}
                                </div>),
                            xs: 5,
                            sm: 3,
                            md: 3,
                            lg: 3,
                        },
                        {
                            title: translate("tenant_configurations.columns.custom_value") as string,
                            converter: (field) => (
                                <div style={{ overflowWrap: "anywhere" }}>
                                    <Ellipsis text={field.value} lenght={50} uppercased={false} />
                                </div>),
                            xs: false,
                            sm: true,
                            md: true,
                            lg: true,
                        },
                        {
                            title: translate("tenant_configurations.columns.update_at") as string,
                            converter: (field) => (
                                <div>
                                    <DateFormat date={field.last_update} format="L" />
                                </div>),
                            xs: true,
                            sm: true,
                            md: false,
                            lg: true,
                        },
                        {
                            title: translate("tenant_configurations.columns.status") as string,
                            converter: (field) => (
                                <div>
                                    {translate("tenant_configurations." + (field.active ? "status_active" : "status_inactive")) as string}
                                </div>),
                            xs: false,
                            sm: false,
                            md: false,
                            lg: true,
                        },
                        {
                            title: (
                                <IconButton size="small" style={{ "visibility": "hidden" }} disabled><MoreVertIcon /></IconButton>
                            ),
                            converter: (field) => (<IconButton aria-label="options" color="default" size="small"
                                onClick={onClickedOptions(field)}>
                                <MoreVertIcon />
                            </IconButton>),
                            justify: "flex-end",
                            xs: true,
                            sm: true,
                            md: true,
                            lg: true,
                            xl: true
                        },
                    ]}
                />
                <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
                {anchorEl && selectedConfiguration &&
                    <TenantConfigurationMenu
                        anchor={anchorEl}
                        required={getField(selectedConfiguration).required}
                        active={selectedConfiguration.active}
                        onDisable={onOpenDesactivate}
                        onUpdate={onOpenUpdate}
                        onClose={onClose} />
                }
                {selectedConfiguration && openDesactivate &&
                    <ConfirmationPopup title={translate("tenant_configurations.pop_up.desactivate_title") as string}
                        message={translate("tenant_configurations.pop_up.description_desactivate") as string}
                        button={translate("buttons.desactivate") as string}
                        color="secondary"
                        onClose={onClose}
                        doAction={onDesactivateConfiguration} />
                }
                {!isTermCodition && selectedConfiguration && openUpdate &&
                    <TenantConfigurationUpdatePopUp
                        configuration={selectedConfiguration}
                        title={getField(selectedConfiguration).name}
                        description={getField(selectedConfiguration).description}
                        type={getField(selectedConfiguration).type}
                        optionsCatalog={optionsCatalog(selectedConfiguration)}
                        onClose={onClose}
                        onUpdate={onUpdateConfiguration} />
                }
                {isTermCodition && selectedConfiguration && openUpdate &&
                    <TermConditionCreateModal
                        configuration={selectedConfiguration}
                        onClose={onClose}
                        onSave={onUpdateTermsConditions} />
                }
            </Grid>
        </Grid>
    );
}
