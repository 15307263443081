import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import DialogPopup from "../components/DialogPopup";
import { Grid, Button, Typography, Box, List, ListItem, ListItemText, Divider, TextField } from "@material-ui/core";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { ErrorSnackbar, WarningSnackbar } from "../components/Snackbars";
import { Requisition, RequisitionLine, RequisitionLineUpdateRequest } from "../model/Requisition";
import { listCostCenters } from "../api/CostCenterAPI";
import { CostCenter, CostCentersQueryParams } from "../model/CostCenter";
import Progress from "../components/Progress";
import NumberFormat from "react-number-format";
import AutocompleteGrouped from "../components/AutocompleteGrouped";
import { GTZeroValidator } from "../components/Validators";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { listProrationTemplates } from "../api/ProrationTemplateAPI";
import { ProrationTemplate, ProrationTemplatesQueryParams } from "../model/ProrationTemplate";
import { ProrationHandling } from "../model/RequisitionTypeCatalog";

export interface AutocompleteItem {
    title: string;
    value: string;
}

interface EditRequisitionLineProps {
    tenantId: string;
    line: RequisitionLine;
    onSubmit(number: number, request: RequisitionLineUpdateRequest): any;
    onClose(): any;
    requisition: Requisition;
}

export default function EditBudgetLine(props: EditRequisitionLineProps) {
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [costCenterSelect, setCostCenterSelected] = useState<CostCenter>({} as CostCenter);
    const [templateSelected, setTemplateSelected] = useState<ProrationTemplate>({} as ProrationTemplate);
    const [prorationTemplates, setProrationTemplates] = useState<ProrationTemplate[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [toAccrue, setToAccrue] = useState<number>(0.00);
    const gtZeroValidator = new GTZeroValidator();

    const [status, setStatus] = useState<string>("loading");

    const [request, setRequest] = useState<RequisitionLineUpdateRequest>(
        {
            number: props.line.number,
            description: props.line.description && props.line.description.length > 60 ? props.line.description.substring(0, 60) : props.line.description,
            cost: props.line.cost || 0.00,
            cost_center_id: props.line.cost_center_id,
            cost_center_name: props.line.cost_center_name,
            cost_center_external_id: props.line.cost_center_external_id,
            proration_template_id: props.line.proration_template_id
        } as RequisitionLineUpdateRequest);

    const [validations, setValidations] = useState({
        "description": {
            valid: false
        } as InputRef,
        "cost": {
            valid: false
        } as InputRef
    } as any);

    useEffect(() => {
        if (!props.line) {
            return;
        }
        setStatus("loading");
        if (ProrationHandling.PRORATION_TEMPLATE_SETUP === props.requisition.requisition_type_proration_handling) {
            listProrationTemplates(props.tenantId, 0, 0, { company_id: props.requisition.company_id } as ProrationTemplatesQueryParams).then((response) => {
                let templates = response.items;
                if (props.line.proration_template_id) {
                    let template = templates.find(t => t.id === props.line.proration_template_id);
                    if (template) {
                        setTemplateSelected(template);
                    }
                }
                setProrationTemplates(templates);
                if (templates.length < 1) {
                    setWarning(translate("prorationtemplates.company_without_proration_templates") as string);
                }
                setStatus("loaded");
            }).catch((error) => {
                setError(error.message);
            });
        } 
        if (ProrationHandling.COST_CENTER_SETUP === props.requisition.requisition_type_proration_handling) {
            listCostCenters(props.tenantId, 0, 0, {
                search: ""
            } as CostCentersQueryParams).then((response) => {
                let costCenters = response.items;
                if (response.items) {
                    costCenters.forEach(item => {
                        item.category = item.category || "---";
                    });
                }
                if (props.line.cost_center_id) {
                    let costCenter = response.items.find(item => item.id === props.line.cost_center_id);
                    if (costCenter) {
                        setCostCenterSelected(costCenter);
                    }
                }
                setCostCenters(costCenters);
                setStatus("loaded");
            }).catch((error) => {
                setError(error.message);
            });
        }
        if (ProrationHandling.NO_PRORATION === props.requisition.requisition_type_proration_handling) {
            setStatus("loaded");
        }
        let toAccrueAmount = (props.line.to_accrue || 0.00) + props.line.cost || 0.00
        setToAccrue(toAccrueAmount);
    }, [props.tenantId, props.line]);


    const onFilterChanged = (value: string, inputRef?: InputRef) => {
        costCenters.forEach((t) => {
            if (t.id === value) {
                setCostCenterSelected(t);
                let line = request;
                line.cost_center_id = t.id;
                line.cost_center_name = t.name;
                line.cost_center_external_id = t.erp_id
                setRequest({ ...request, cost_center_id: t.id, cost_center_name: t.name });
            }
        });
    };

    const hasChanged = (name: string, value: number, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onTemplateFilterChanged = (value: string, inputRef?: any) => {
        if (value) {
            let selected = prorationTemplates.find(item => item.id === value);
            if(selected){
                setTemplateSelected(selected);
            }
        } else {
            setTemplateSelected({} as ProrationTemplate);
        }
    };

    const onSubmit = () => {
        if (!request.cost) {
            setWarning(translate("requisitions.overview.details.empty_cost") as string);
            return;
        }
        if (!isValid()) {
            return;
        }
        if (ProrationHandling.COST_CENTER_SETUP === props.requisition.requisition_type_proration_handling && !request.cost_center_id) {
            setWarning(translate("cost_centers.empty_cost_center") as string);
            return;
        }
        if (request.cost > toAccrue) {
            setWarning(translate("requisitions.overview.details.cost_greater_than_accrue") as string);
            return;
        }

        if (ProrationHandling.PRORATION_TEMPLATE_SETUP === props.requisition.requisition_type_proration_handling && !templateSelected.id) {
            setWarning(translate("prorationtemplates.proration_template_missing") as string);
            return;
        }
        props.onSubmit(props.line.number, {...request, proration_template_id: templateSelected.id} as RequisitionLineUpdateRequest);
    }

    const onCancel = () => {
        props.onClose();
    }

    if (status === "loading") {
        return <Progress />;
    }

    return (
        <DialogPopup
            open
            title={translate(`requisitions.overview.details.edit_line.title`) as string}
            fullWidth={true}
            maxWidth="sm"
            button={
                <Button onClick={onSubmit} variant="contained" color="primary" size="large"
                    disabled={submitting || ( ProrationHandling.PRORATION_TEMPLATE_SETUP === props.requisition.requisition_type_proration_handling && prorationTemplates.length < 1 )}>
                    {translate("buttons.save")}
                </Button>}
            closeText={translate("buttons.cancel") as string}
            closeColor="primary"
            onClose={onCancel}>
            <Grid container spacing={2}>
                <Box pl={2} width="100%">
                    <Grid container alignItems="center" justify="space-evenly" direction="row" >
                        <Grid item xs={6}>
                            <ListItem disableGutters>
                                <Typography style={{ flex: "1 1 auto", paddingRight: "10px" }} variant="subtitle2" display="block" gutterBottom>
                                    {translate("requisitions.overview.details.edit_line.num") as string}
                                </Typography>
                                <Typography variant="inherit" align="left">
                                    {props.line.number}
                                </Typography>
                            </ListItem>

                            <ListItem disableGutters>
                                <Typography style={{ flex: "1 1 auto", paddingRight: "10px" }} variant="subtitle2" display="block" gutterBottom>
                                    {translate("requisitions.overview.details.edit_line.external_id") as string}
                                </Typography>
                                <Typography variant="inherit" align="left">
                                    {props.line.budget_line_external_id}
                                </Typography>
                            </ListItem>

                            <ListItem disableGutters>
                                <Typography style={{ flex: "1 1 auto", paddingRight: "10px" }} variant="subtitle2" display="block" gutterBottom>
                                    {translate("requisitions.overview.details.edit_line.name") as string}
                                </Typography>
                                <Typography variant="inherit" align="left">
                                    {props.line.name}
                                </Typography>
                            </ListItem>
                        </Grid>
                        <Divider orientation="vertical" flexItem variant="middle" />
                        <Grid item xs={5} >
                            <ListItemText primary={<strong>{translate("requisitions.overview.details.edit_line.to_accrue") as string}</strong>} />
                            <NumberFormat color="blue" value={toAccrue || 0.00} prefix="$" thousandSeparator="," decimalScale={2} fixedDecimalScale={true} displayType="text" />
                        </Grid>
                    </Grid>
                </Box>
                <Grid item xs={12}>
                    <ValidatedInput type="text" id="description" name="description"
                        value={request.description} label={translate("requisitions.form.description") as string}
                        disabled={submitting}
                        margin="dense"
                        onValueChanged={hasChanged}
                        maxLength={60} />
                </Grid>
                {ProrationHandling.COST_CENTER_SETUP === props.requisition.requisition_type_proration_handling &&
                    <Grid item xs={12} >
                        <Box pt={1}>
                            <AutocompleteGrouped
                                items={costCenters}
                                propertyGroup={"category"}
                                titleProperty={"name"}
                                value={costCenterSelect}
                                label={translate("cost_centers.single_title") as string}
                                onChange={onFilterChanged}
                            />
                        </Box>
                    </Grid>}
                {ProrationHandling.PRORATION_TEMPLATE_SETUP === props.requisition.requisition_type_proration_handling &&
                    <Grid item xs={12} >
                        <Box pt={1}>
                            <Autocomplete
                                size="small"
                                options={prorationTemplates || []}
                                value={templateSelected}
                                getOptionLabel={(elemento) => elemento.name}
                                noOptionsText={translate("prorationtemplates.any_proration_templates") as string}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={translate("prorationtemplates.proration_template") as string} fullWidth />
                                )}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        onTemplateFilterChanged(newValue.id);
                                    } else {
                                        onTemplateFilterChanged("");
                                    }
                                }}
                                getOptionSelected={(opt, val) => opt.id === val.id}
                            />
                        </Box>
                    </Grid>}
                <Grid item xs={12} >
                    <Box pb={1}>
                        <ValidatedInput
                            type="number"
                            id={"cost"}
                            name={"cost"}
                            value={request.cost ? request.cost.toString() : ""}
                            isValueNumber={true}
                            validators={[gtZeroValidator]}
                            label={translate(`requisitions.overview.details.edit_line.cost`) as string}
                            required
                            margin="dense"
                            onValueChanged={hasChanged}
                            selectContentOnFocus
                        />
                    </Box>
                </Grid>
            </Grid>
            <WarningSnackbar message={warning} onClose={() => setWarning(undefined)} />
            <ErrorSnackbar message={error} onClose={() => setError(undefined)} />
        </DialogPopup>
    );
}