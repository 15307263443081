import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Card } from "@material-ui/core";
import translate from "../i18n/Translator";

import Progress from "../components/Progress";
import { SuccessSnackbar, ErrorSnackbar } from "../components/Snackbars";

import GridDx from "../components/GridDx";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { initialPageZero, initialPageSize } from "../components/Pagination";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { DocumentLine } from "../model/Document";

export interface PurchaseOrderLinesListParams {
    tenantId: string;
    lines: DocumentLine[];
    providerId?: string
    showPedimento: boolean;
    requirePedimento: boolean;
    multiselect: boolean;
    readOnly: boolean;
    onSelectLines(selected: (number | string)[]): any;
    onChangedText(index: number): any;
}

export default function PurchaseOrderLinesList(props: PurchaseOrderLinesListParams) {
    const [status, setStatus] = useState<string>("loaded");
    const [showPedimento, setShowPedimento] = useState(true);
    const [multiselect, setMultiselect] = useState(true);
    const [selection, setSelection] = useState<(number | string)[]>([]);
    const [lines, setLines] = useState<DocumentLine[]>([]);

    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const [page, setPage] = useState<number>(initialPageZero);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);

    const textColumns = ['line_num', 'item_code', 'description', showPedimento ? "pedimento" : "", showPedimento ? "factura_pedimento" : ""];

    const currencyColumns = ['price'];

    const [columnsFormat] = useState([
        { columnName: 'line_num' },
        { columnName: 'item_code' },
        { columnName: 'description' },
        { columnName: 'price' },
        { columnName: showPedimento ? 'pedimento' : '' },
        { columnName: showPedimento ? 'factura_pedimento' : '' },
    ]) as any;

    const NormalTypeProvider = (proops: DataTypeProviderProps) => (
        <DataTypeProvider {...proops} />
    );

    const PedimentoTypeProvider = (proops: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(line: any, index: number) => {
            return <>
                <ValidatedInput type="text" id={`pedimento_${index}`} name="pedimento"
                            value={line.row.pedimento}
                            label={translate("purchase_orders.pedimento") as string}
                            margin="dense" required={props.requirePedimento && line.row.selected} disabled={props.readOnly || !line.row.selected}
                            onValueChanged={props.onChangedText(index)} />
            </>;
        }} {...proops} />
    );

    const FacturaPedimentoTypeProvider = (proops: DataTypeProviderProps) => (
        <DataTypeProvider formatterComponent={(line: any, index: number) => {
            return <>
                <ValidatedInput type="text" id={`factura_pedimento_${index}`} name="factura_pedimento"
                            value={line.row.factura_pedimento}
                            label={translate("purchase_orders.factura_pedimento") as string}
                            margin="dense" required={props.requirePedimento && line.row.selected} disabled={props.readOnly || !line.row.selected}
                            onValueChanged={props.onChangedText(index)} />
            </>;
        }} {...proops} />
    );

    const customPlugins = [
        <NormalTypeProvider for={['line_num', 'item_code', 'description', 'price', 'pedimento', 'factura_pedimento']} editorComponent={NoFilterEditor} />,
        <PedimentoTypeProvider for={['pedimento']} />,
        <FacturaPedimentoTypeProvider for={['factura_pedimento']} />,
    ];

    useEffect(() => {
        setStatus("loading");
        setLines(props.lines);
        setShowPedimento(props.showPedimento);
        setMultiselect(props.multiselect);
        setStatus("loaded");
    }, [page, pageSize, props]);

    const onClosedSnackbar = () => {
        setSuccess(undefined);
        setError(undefined);
    };

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded" || !lines) {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    const getDescription = (line: DocumentLine) => {
        return `${line.description || ""}\n${multiselect ? line.external_id : ""}`;
    }

    const getColumns = () => {
        let columns = [
            {
                name: 'line_num',
                title: translate("purchase_orders.position") as string
            },
            {
                name: 'item_code',
                title: translate("purchase_orders.reference") as string
            },
            {
                name: 'description',
                title: translate("purchase_orders.description") as string,
                getCellValue: (row: any) => getDescription(row)
            },
            {
                name: 'price',
                title: translate("cfdis.details.price_nm") as string
            },
        ] as any [];

        if (showPedimento) {
            columns = columns.concat([
                {
                    name: 'pedimento',
                    title: translate("purchase_orders.pedimento") as string
                },
                {
                    name: 'factura_pedimento',
                    title: translate("purchase_orders.factura_pedimento") as string
                },
            ]);
        }

        return columns;
    }

    const onChangedPage = (page: number) => {
        setPage(page);
    };

    const setSelectionHandler = (selected: (number | string)[]) => {
        setSelection(selected);
        props.onSelectLines(selected);
    };

    const onChangedPageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    return (
        <div>
            <Grid style={{ marginTop: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Card variant="outlined" >
                            <Grid item xs={12}>
                                <Box mx={-2}>
                                <GridDx
                                    noUseId={true}
                                    loading={false}
                                    rows={lines ? lines : []}
                                    page={page}
                                    pageSize={pageSize}
                                    totalRows={lines ? lines.length : 0}
                                    columns={getColumns() ? getColumns() : []}
                                    columnsFormat={columnsFormat}
                                    textColumns={textColumns}
                                    amountCurrencyColumns={currencyColumns}
                                    onClickRow={() => {}}
                                    onClickedOptions={() => {}}
                                    selectionIds={selection}
                                    setSelectionHandler={setSelectionHandler}
                                    onChangedPage={onChangedPage}
                                    onChangedPageSize={onChangedPageSize}
                                    customFormatColumns={customPlugins}
                                    heightTablePX={500}
                                />
                                </Box>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>  
            <SuccessSnackbar message={success} onClose={onClosedSnackbar} />
            <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
        </div>
    )
}