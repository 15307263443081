import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { SatContributions, SatContribution, SatContributionsQueryParams, NewSatContributionRequest, SatContributionRequest, SatContributionFileType, ToValidatePaymentRequest } from "../model/SatContribution";
import { ContributionRefuseRequest } from "../model/Contribution";

const basePath = (tenantId: string) => `/tenants/${tenantId}/contributions/sat`;

export async function listSatContributions(tenantId: string, page: number, offset: number, params: SatContributionsQueryParams): Promise<SatContributions> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            month: params.month?.toString() || "",
            year: params.year?.toString() || "",
            status: params.status || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createSatContribution(tenantId: string, request: NewSatContributionRequest): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getSatContribution(tenantId: string, id: string): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET
    });
}

export async function getSatContributionFileUrl(tenantId: string, id: string, fileType: SatContributionFileType): Promise<string> {
    return getAuthenticatedGetUrl(`${basePath(tenantId)}/${id}/${fileType}`);
}

export async function updateSatContribution(tenantId: string, id: string, request: SatContributionRequest): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deleteSatContribution(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function approveBankVoucher(tenantId: string, id: string, complementary: boolean): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/${id}/bank-voucher`, {
        method: HTTPMethod.PUT,
        query: {
            "complementary": `${complementary}`
        }
    });
}

export async function refuseBankVoucher(tenantId: string, id: string, request: ContributionRefuseRequest): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/${id}/bank-voucher`, {
        method: HTTPMethod.DELETE,
        body: request
    });
}

export async function approvePayment(tenantId: string, id: string, complementary: boolean): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/${id}/payment`, {
        method: HTTPMethod.PUT,
        query: {
            "complementary": `${complementary}`
        }
    });
}

export async function refusePayment(tenantId: string, id: string, request: ContributionRefuseRequest): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/${id}/payment`, {
        method: HTTPMethod.DELETE,
        body: request
    });
}

export async function deleteSatContributionComplementary(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}/delete-complementary`, {
        method: HTTPMethod.PUT
    });
}

export async function createSatContributionToValidatePayment(tenantId: string, request: ToValidatePaymentRequest): Promise<SatContribution> {
    return await callAPI(`${basePath(tenantId)}/send-validate-payment`, {
        method: HTTPMethod.POST,
        body: request
    });
}