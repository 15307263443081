import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";

import translate from "../i18n/Translator";
import Surface from "../components/Surface";
import UserPasswordForm from "./UserPasswordForm";
import User2FA from "./User2FA";
import { AppContext } from "../context/AppContext";
import { User } from "../model/User";
import { me } from "../api/MeAPI";
import Progress from "../components/Progress";
import Error from "../components/Error";
import { RouterParams } from '../router/RouterParams';
import { getUserSecurity } from '../api/UserAPI';

export default function UserSecurity({ match }: RouterParams) {
    const context = useContext(AppContext);
    const [status, setStatus] = useState<"loading" | "loaded" | any>("loading");
    const [user, setUser] = useState<User>();
    const isPlaformAdministrator = (match?.params?.userId && context.isGranted('PlatformAdministratorUsersSecurity')) as boolean;

    useEffect(() => {
        setStatus("loading");
        var promise;
        if (isPlaformAdministrator) {
            promise = getUserSecurity(match?.params?.userId);
        } else {
            promise = me();
        }

        promise.then((user) => {
            if (!isPlaformAdministrator && context.session) {
                context.session!.user = user;
            }
            setUser(user);
            setStatus("loaded");
        }).catch((error) => {
            setStatus(error.message);
        });
        // eslint-disable-next-line
    }, [context.session])

    const onUpdated = (user: User) => {
        if (context.session) {
            context.session!.user = user;
        }
        setUser(user);
    };

    if (status === "loading") {
        return <Progress />
    } else if (status === "loaded") {
        return (
            <Grid container alignItems="center" justify="center" alignContent="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate("users.security.password") as string} icon={<LockIcon />}>
                        <Grid container alignItems="center" justify="center" alignContent="center">
                            <Grid item xs={12}>
                                <UserPasswordForm isPlaformAdministrator={isPlaformAdministrator} userId={user?.id} />
                            </Grid>
                        </Grid>
                    </Surface>
                    <br></br>
                    <br></br>
                    <Surface title={translate("users.security.2fa") as string} icon={<PhonelinkLockIcon />}>
                        <Grid container alignItems="center" justify="center" alignContent="center">
                            <Grid item xs={12}>
                                <User2FA user={user!} onUpdated={onUpdated} isPlaformAdministrator={isPlaformAdministrator} />
                            </Grid>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
        );
    } else {
        return <Error text={status} />
    }

}