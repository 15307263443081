import { callAPI, HTTPMethod, getAuthenticatedGetUrl } from "./API";
import { PurchaseOrders, PurchaseOrder, PurchaseOrdersQueryParams, PurchaseOrderRequest } from "../model/PurchaseOrder";
import { ExportRequest, ExportResponse } from "../model/Connector";
import { ImportResponse } from "../model/ExportableObject";

const basePath = (tenantId: string) => `/tenants/${tenantId}/purchase-orders`;

export async function listPurchaseOrders(tenantId: string, page: number, offset: number, params: PurchaseOrdersQueryParams): Promise<PurchaseOrders> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            currency: params.currency || "",
            acceptanceStatus: params.acceptance_status || "",
            pageSize: page.toString(),
            offset: offset.toString()
        }
    });
}

export async function createPurchaseOrder(tenantId: string, request: PurchaseOrderRequest): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request
    });
}

export async function getPurchaseOrder(tenantId: string, id: string, pageLine: number, offsetLine: number): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET,
        query: {
            pageSize: pageLine.toString(),
            offset: offsetLine.toString()
        }
    });
}

export async function updatePurchaseOrder(tenantId: string, id: string, request: PurchaseOrderRequest): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request
    });
}

export async function deletePurchaseOrder(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE
    });
}

export async function importPurchaseOrders(tenantId: string, file: File): Promise<ImportResponse> {
    let data = new FormData();
    data.append("file", file as Blob, file.name);

    return await callAPI(`${basePath(tenantId)}/import`, {
        method: HTTPMethod.POST,
        body: data,
        multipart: true
    });
}

export async function getPurchaseOrderByExternalId(tenantId: string, externalId: string): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId)}/${externalId}/getByExternalId`, {
        method: HTTPMethod.GET
    });
}

export async function downloadImportTemplate(tenantId: string, connectorId: string): Promise<string> {
    return await getAuthenticatedGetUrl(`${basePath(tenantId)}/import`, {
        query: {
            connectorId: connectorId,
        },
    });
}

export async function exportPurchaseOrders(tenantId: string, connectorId: string, params: PurchaseOrdersQueryParams): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.GET,
        query: parseQuery(connectorId,params)
    });
}

const parseQuery = (connectorId: string, params: PurchaseOrdersQueryParams) => {
    let query = {
        search: params.search,
        acceptanceStatus: params.acceptance_status || "",
        status: params.status,
        connectorId: connectorId
    }as Record<string, string>;

    if(params.start_date && params.end_date){
        query = {...query,
            startDate: params.start_date+"",
            endDate: params.end_date+"",
        };
    }
    return query;
}

export async function exportSelectedPurchaseOrders(tenantId: string, connectorId: string, ids: string[]): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export`, {
        method: HTTPMethod.PUT,
        body: {
            ids: ids,
            connector_id: connectorId,
        } as ExportRequest
    });
}

export async function downloadFilesSelected(tenantId: string, ids: string[]): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export-selected-files`, {
        method: HTTPMethod.PUT,
        body: {
            ids: ids
        } as ExportRequest
    });
}

export async function downloadAllFiles(tenantId: string, params: PurchaseOrdersQueryParams): Promise<ExportResponse> {
    return await callAPI(`${basePath(tenantId)}/export-all-files`, {
        method: HTTPMethod.GET,
        query: {
            search: params.search,
            status: params.status,
            acceptanceStatus: params.acceptance_status || "",
            pageSize: "0",
            offset: "0"
        }
    });
}

export async function closePurchaseOrder(tenantId: string, id: string): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId)}/${id}/close`, {
        method: HTTPMethod.PUT
    });
}

export async function cancelPurchaseOrder(tenantId: string, id: string): Promise<PurchaseOrder> {
    return await callAPI(`${basePath(tenantId)}/${id}/cancel`, {
        method: HTTPMethod.PUT
    });
}