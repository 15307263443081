import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import EditIcon from "@material-ui/icons/BorderColorTwoTone";
import ActivateIcon from "@material-ui/icons/DeleteTwoTone";
import InfoIcon from "@material-ui/icons/InfoTwoTone";

import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";

interface RequisitionMenuProps {
    anchor: HTMLElement | null;
    view: string;
    requisitionId: string;
    readOnly: boolean;
    routeBudgetControl?: string;
    onClose(): any;
    onDelete(): any;
    refer?: string;
}

export default function RequisitionMenu(props: RequisitionMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu id="cfdis-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>
            
            {context.isGrantedAny(["RequisitionsUpdate"]) && ( props.view === 'to_send' || props.view === 'to_authorize' ) && !props.readOnly &&
                <MenuItem button component={Link} to={props.routeBudgetControl || `/requisitions/${props.requisitionId}/edit`} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.edit")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RequisitionsRead"]) &&
                <MenuItem button component={Link} to={props.routeBudgetControl || `/requisitions/${props.requisitionId}/details?${props.refer}`} 
                onClick={props.onClose} dense>
                    <ListItemIcon>
                        <InfoIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.see_details")}</Typography>
                </MenuItem>
            }
            {context.isGrantedAny(["RequisitionsDelete"]) && (props.view === 'to_authorize' || props.view === 'to_send') &&
                <MenuItem button onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <ActivateIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.delete")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
}