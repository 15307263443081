import React, { useState, useEffect } from "react";
import translate from "../i18n/Translator";
import { MemberTeamHistory } from "../model/Project";
import GridDx from "../components/GridDx";
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { NoFilterEditor } from "../components/GridDx";
import { Grid, Typography, Box} from "@material-ui/core";

export interface ProjectHistoryParams {
    history?: MemberTeamHistory[];
}

export default function ProjectHistoryTab(params: ProjectHistoryParams) {

    const [status, setStatus] = useState<string>("loading");
    const [data, setData] = useState<MemberTeamHistory[]>();

    const load = () => {
        setStatus("loading");
        setData(params.history);
        setStatus("loaded");
    };

    useEffect(load, [params]);

    const [columnsFormat] = useState([
        { columnName: 'team_name', width: 150 },
        { columnName: 'member_name', width: 200 },
        { columnName: 'role_team', width: 150 },
    ]);

    const getFunction = (item: MemberTeamHistory) => {
        return translate("requisitions.teams.role." + item.role_team) as string;
    };

    const columns = [
        {
            name: 'team_name',
            title: translate('requisitions.projects.history.team') as string,
        },
        {
            name: 'member_name',
            title: translate('requisitions.projects.history.member_name') as string,
        },
        {
            name: 'role_team',
            title: translate('requisitions.projects.history.function') as string,
            getCellValue: (row: any) => getFunction(row)
        }
    ];

    const textColumns = ['member_name', 'role_team'];

    const filtersExceptions = ['team_name', 'member_name', 'role_team'];

    const grouping = [{ columnName: 'team_name' }];

    const NormalTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider {...props} />
    );

    const customPlugins = [
        <NormalTypeProvider for={filtersExceptions} editorComponent={NoFilterEditor} />,
    ];

    return (
        <Grid>
            <Grid container>
                <Box pl={5} px={5} pb={2} pt={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            <strong>{translate("requisitions.projects.details.teams_and_members")}</strong>
                        </Typography>
                    </Grid>
                </Box>
                <Grid item xs={12}>
                    <GridDx
                        loading={status === "loading"}
                        rows={data ? data : []}
                        columns={columns}
                        columnsFormat={columnsFormat}
                        onClickRow={() => {}}
                        textColumns={textColumns}
                        customFormatColumns={customPlugins}
                        totalRows={data ? data.length : 0}
                        grouping={grouping}
                        heightTablePX={500}
                    />

                </Grid>
            </Grid>
        </Grid>
    )
}