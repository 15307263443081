import React, { useContext, useEffect, useState } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade, Divider } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppTwoTone";
import DownloadXmlIcon from "@material-ui/icons/CodeTwoTone";
import DownloadPdfIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import ApproveIcon from "@material-ui/icons/ThumbUpTwoTone";
import RefuseIcon from "@material-ui/icons/ThumbDownTwoTone";

import translate from "../i18n/Translator";
import { Contribution } from "../model/Contribution";
import { AppContext } from "../context/AppContext";
import { getSocialContributionFileUrl, getSocialContributionPdfUrl } from "../api/SocialContributionAPI";
import { getSocialContributionFileUrl as getProviderSocialContributionFileUrl, getSocialContributionPdfUrl as getProviderSocialContributionPdfUrl } from "../api/ProviderSocialContributionAPI";
import { getSatContributionFileUrl } from "../api/SatContributionAPI";
import { getSatContributionFileUrl as getProviderSatContributionFileUrl } from "../api/ProviderSatContributionAPI";
import { SocialContributionFileType } from "../model/SocialContribution";
import { SatContributionFileType } from "../model/SatContribution";

interface ContributionMenuProps {
    contribution: Contribution;
    anchor: HTMLElement | null;
    uploadSipare: boolean;
    onApprove(complementary: boolean): any;
    onRefuse(complementary: boolean): any;
    onApproveSocial(): any;
    onRefuseSocial(): any;
    onClose(): any;
}

export default function ContributionMenu(props: ContributionMenuProps) {
    const context = useContext(AppContext);
    const tenantId = context.session?.tenant?.id || "-";
    const providerId = context.session?.provider?.id;
    const hasSat = context.isGrantedAny(["SatContributionsRead", "CustomerCfdisIssuedRead", "CfdisIssuedRead", "ProviderSatContributionsRead", "TenantContributionsRead"]);
    const hasSocial = context.isGrantedAny(["SocialContributionsRead", "CustomerCfdisIssuedRead", "CfdisIssuedRead", "ProviderSocialContributionsRead", "TenantContributionsRead"]);
    const canApprove = context.isGranted("TenantContributionsUpdate");

    const [imss, setImss] = useState<string>();
    const [infonavit, setInfonavit] = useState<string>();
    const [imssPdf, setImssPdf] = useState<string>();
    const [infonavitPdf, setInfonavitPdf] = useState<string>();
    const [socialBankVoucher, setSocialBankVoucher] = useState<string>();
    const [satBankVoucher, setSatBankVoucher] = useState<string>();
    const [payment, setPayment] = useState<string>();
    const [sipare, setSipare] = useState<string>();
    const [complementarySatBankVoucher, setComplementarySatBankVoucher] = useState<string>();
    const [complementaryPayment, setComplementaryPayment] = useState<string>();

    const promiseSocialContributionUrl = (fileType: SocialContributionFileType) => {
        if (providerId) {
            return getProviderSocialContributionFileUrl(tenantId, providerId, props.contribution.social_contribution!.id, fileType);
        }
        return getSocialContributionFileUrl(tenantId, props.contribution.social_contribution!.id, fileType);
    };

    const promiseSocialContributionPdfUrl = (fileType: SocialContributionFileType) => {
        if (providerId) {
            return getProviderSocialContributionPdfUrl(tenantId, providerId, props.contribution.social_contribution!.id, fileType);
        }
        return getSocialContributionPdfUrl(tenantId, props.contribution.social_contribution!.id, fileType);
    };

    const promiseSatContributionUrl = (fileType: SatContributionFileType) => {
        if (providerId) {
            return getProviderSatContributionFileUrl(tenantId, providerId, props.contribution.sat_contribution!.id, fileType);
        }
        return getSatContributionFileUrl(tenantId, props.contribution.sat_contribution!.id, fileType);
    };

    const load = () => {
        if (props.contribution.social_contribution) {
            Promise.all([                
                promiseSocialContributionUrl("imss"),
                promiseSocialContributionUrl("infonavit"),
                promiseSocialContributionUrl("bank_voucher"),

                promiseSocialContributionPdfUrl("imss"),
                promiseSocialContributionPdfUrl("infonavit"),
                promiseSocialContributionUrl("sipare"),
            ]).then(urls => {
                setImss(props.contribution.social_contribution!.imss_cfdi_id ? urls[0] : undefined);
                setInfonavit(props.contribution.social_contribution!.infonavit_cfdi_id ? urls[1] : undefined);
                setSocialBankVoucher(props.contribution.social_contribution!.bank_voucher ? urls[2] : undefined);

                setImssPdf(props.contribution.social_contribution!.imss_cfdi_id ? urls[3] : undefined);
                setInfonavitPdf(props.contribution.social_contribution!.infonavit_cfdi_id ? urls[4] : undefined);

                setSipare(props.contribution.social_contribution!.sua ? urls[5] : undefined);
            }).catch(error => {
                console.error(error);
            });
        }

        if (props.contribution.sat_contribution) {
            Promise.all([
                promiseSatContributionUrl("payment"),
                promiseSatContributionUrl("bank_voucher"),
                promiseSatContributionUrl("complementary_payment"),
                promiseSatContributionUrl("complementary_bank_voucher"),
            ]).then(urls => {
                setPayment(props.contribution.sat_contribution!.payment ? urls[0] : undefined);
                setSatBankVoucher(props.contribution.sat_contribution!.bank_voucher ? urls[1] : undefined);
                setComplementaryPayment(props.contribution.sat_contribution!.complementary_payment ? urls[2] : undefined);
                setComplementarySatBankVoucher(props.contribution.sat_contribution!.complementary_bank_voucher ? urls[3] : undefined);
            }).catch(error => {
                console.error(error);
            });
        }
    };

    useEffect(load, [tenantId, providerId, props.contribution]);

    return (
        <Menu
            id="contributions"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {(props.uploadSipare || (props.contribution.provider && props.contribution.provider.upload_sipare)) && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={sipare || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!sipare}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.sipare")}</Typography>
                </MenuItem>
            )}
            {hasSat && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={payment || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!payment}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.payment")}</Typography>
                </MenuItem>
            )}
            {hasSat && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={satBankVoucher || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!satBankVoucher}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.bank_voucher")}</Typography>
                </MenuItem>
            )}
            {hasSat && canApprove && satBankVoucher && !props.contribution.sat_contribution?.valid_bank_voucher && !props.contribution.sat_contribution?.refuse_reason_bank_voucher && (
                <MenuItem
                    button
                    onClick={() => props.onApprove(false)}
                    dense>
                    <ListItemIcon>
                        <ApproveIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.approve")}</Typography>
                </MenuItem>
            )}
            {hasSat && canApprove && satBankVoucher && !props.contribution.sat_contribution?.valid_bank_voucher && !props.contribution.sat_contribution?.refuse_reason_bank_voucher && (
                <MenuItem
                    button
                    onClick={() => props.onRefuse(false)}
                    dense>
                    <ListItemIcon>
                        <RefuseIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.refuse")}</Typography>
                </MenuItem>
            )}
            {hasSat && props.contribution.sat_contribution?.needs_complementary && (
                <Divider />
            )}
            {hasSat && props.contribution.sat_contribution?.needs_complementary && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={complementaryPayment || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!complementaryPayment}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.complementary_payment")}</Typography>
                </MenuItem>
            )}
            {hasSat && props.contribution.sat_contribution?.needs_complementary && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={complementarySatBankVoucher || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!complementarySatBankVoucher}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.complementary_bank_voucher")}</Typography>
                </MenuItem>
            )}
            {hasSat && canApprove && complementarySatBankVoucher && !props.contribution.sat_contribution?.complementary_valid_bank_voucher && !props.contribution.sat_contribution?.complementary_refuse_reason_bank_voucher && (
                <MenuItem
                    button
                    onClick={() => props.onApprove(true)}
                    dense>
                    <ListItemIcon>
                        <ApproveIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.approve_complementary")}</Typography>
                </MenuItem>
            )}
            {hasSat && canApprove && complementarySatBankVoucher && !props.contribution.sat_contribution?.complementary_valid_bank_voucher && !props.contribution.sat_contribution?.complementary_refuse_reason_bank_voucher && (
                <MenuItem
                    button
                    onClick={() => props.onRefuse(true)}
                    dense>
                    <ListItemIcon>
                        <RefuseIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("sat_contributions.refuse_complementary")}</Typography>
                </MenuItem>
            )}
            {hasSocial && (
                <Divider />
            )}
            {hasSocial && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={socialBankVoucher || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!socialBankVoucher}>
                    <ListItemIcon>
                        <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("contributions.social_voucher")}</Typography>
                </MenuItem>
            )}
            {hasSocial && canApprove && socialBankVoucher && !props.contribution.social_contribution?.valid_bank_voucher && !props.contribution.social_contribution?.refuse_reason_bank_voucher && (
                <MenuItem
                    button
                    onClick={props.onApproveSocial}
                    dense>
                    <ListItemIcon>
                        <ApproveIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.approve")}</Typography>
                </MenuItem>
            )}
            {hasSocial && canApprove && socialBankVoucher && !props.contribution.social_contribution?.valid_bank_voucher && !props.contribution.social_contribution?.refuse_reason_bank_voucher && (
                <MenuItem
                    button
                    onClick={props.onRefuseSocial}
                    dense>
                    <ListItemIcon>
                        <RefuseIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.refuse")}</Typography>
                </MenuItem>
            )}
            {hasSocial && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={imss || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!imss}>
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.imss")}</Typography>
                </MenuItem>
            )}
            {hasSocial && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={imssPdf || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!imssPdf}>
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.imss")}</Typography>
                </MenuItem>
            )}
            {hasSocial && props.contribution?.social_contribution?.required_infonavit && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={infonavit || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!infonavit}>
                    <ListItemIcon>
                        <DownloadXmlIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.infonavit")}</Typography>
                </MenuItem>
            )}
            {hasSocial && props.contribution?.social_contribution?.required_infonavit && (
                <MenuItem
                    button
                    onClick={props.onClose}
                    dense
                    component="a"
                    href={infonavitPdf || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!infonavitPdf}>
                    <ListItemIcon>
                        <DownloadPdfIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("social_contributions.infonavit")}</Typography>
                </MenuItem>
            )}
        </Menu>
    );

}