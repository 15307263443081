import React, { useContext } from "react";
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from "@material-ui/core";
import VerifyProviderIcon from "@material-ui/icons/VerifiedUserTwoTone";
import NotLocatedIcon from '@material-ui/icons/NotListedLocationTwoTone';
import translate from "../i18n/Translator";
import { Entity as Provider } from "../model/Provider";
import { AppContext } from "../context/AppContext";

interface ProviderEfosMenuProps {
    provider: Provider;
    anchor: HTMLElement | null;
    onClose(): any;
    onVerifyEfo(): any;
    onVerifyCff69(): any;
}

export default function ProviderEfosMenu(props: ProviderEfosMenuProps) {
    const context = useContext(AppContext);

    return (
        <Menu
            id="provider-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {context.isGranted("EfosUpdate") &&
                <MenuItem button onClick={props.onVerifyEfo} dense>
                    <ListItemIcon>
                        <VerifyProviderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.verify_efos")}</Typography>
                </MenuItem>
            }
            {context.isGranted("Cff69Update") &&
                <MenuItem button onClick={props.onVerifyCff69} dense>
                    <ListItemIcon>
                        <NotLocatedIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("buttons.verify_69_cff")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
} 
