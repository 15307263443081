import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";

import translate from "../i18n/Translator";
import { Tenant, TenantRequest } from "../model/Tenant";
import { createTenant, getTenant, updateTenant, getMetadata } from "../api/TenantAPI";
import ValidatedInput, { InputRef } from "../components/ValidatedInput";
import { RfcValidator } from "../components/Validators";
import Surface from "../components/Surface";
import { ErrorSnackbar } from "../components/Snackbars";
import { RouterParams } from "../router/RouterParams";
import Progress from "../components/Progress";
import SimpleSwitch from "../components/SimpleSwitch";

export default function TenantForm({ match }: RouterParams) {
    const history = useHistory();
    const isEdit = !!match.params.tenantId;
    const margin = "dense";
    const rfcValidator = new RfcValidator();

    const [status, setStatus] = useState<string>("loading");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [tenant, setTenant] = useState<Tenant>();
    const [planIds, setPlanIds] = useState<string[]>([]);
    const [planLabels, setPlanLabels] = useState<string[]>([]);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [request, setRequest] = useState<TenantRequest>({
        tenant_name: "",
        rfc: "",
        plan_id: "",
        active_license: false
    } as TenantRequest);
    const [validations, setValidations] = useState({
        "tenant_name": {
            valid: false
        } as InputRef,
        "rfc": {
            valid: false
        } as InputRef,
    } as any);

    const submitPromise = (): Promise<Tenant> => {
        if (isEdit && match.params.tenantId) {
            return updateTenant(match.params.tenantId, request);
        }
        return createTenant(request);
    };

    useEffect(() => {
        setStatus("loading");
        getMetadata().then((metadata) => {
            setPlanIds(metadata.plans.map((plan) => plan.id));
            setPlanLabels(metadata.plans.map((plan) => plan.name));
            if (isEdit) {
                getTenant(match.params.tenantId).then((tenant) => {
                    setRequest({
                        tenant_name: tenant.name,
                        rfc: tenant.rfc,
                        plan_id: tenant.plan?.id || "",
                        active_license: tenant.license_status === 'PAID' ? true : false
                    } as TenantRequest);
                    setStatus("loaded");
                }).catch((error) => {
                    setStatus(error.message);
                });
            } else {
                setStatus("loaded");
            }
        }).catch((error) => {
            setStatus(error.message);
        })
    }, [isEdit, match.params.tenantId]);

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value });
        validations[name] = inputRef;
        setValidations(validations);
    };

    const onSwitchActiveLicense = () => {
        setRequest({ ...request, active_license: !request.active_license })
    };

    const isValid = () => {
        var valid = true;
        for (let field in validations) {
            let ref = validations[field];
            if (!ref.valid) {
                ref.blurer(true);
                valid = false;
            }
        }
        return valid;
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isValid()) {
            return;
        }

        setSubmitting(true);
        submitPromise().then((tenant) => {
            setTenant(tenant);
            setRedirect(true);
        }).catch((error) => {
            setError(error.message);
            setSubmitting(false);
        })
    };

    const onClosedSnackbar = () => {
        setError("");
    };

    if (redirect) {
        return <Redirect to={`/tenants/?search=${tenant?.name}`} />
    }

    if (status === "loading") {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface title={translate(isEdit ? "tenants.edit_tenant" : "tenants.new_tenant")} icon={<WorkIcon />} className="FormSurface">
                        <form autoComplete="off" noValidate onSubmit={onSubmit} >
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="tenant_name" name="tenant_name"
                                        value={request.tenant_name} label={translate("auth.signup.tenant_name") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="rfc" name="rfc"
                                        value={request.rfc} label={translate("auth.signup.rfc") as string}
                                        required={true} disabled={submitting}
                                        margin={margin}
                                        validator={rfcValidator}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput type="text" id="plan_id" name="plan_id"
                                        value={request.plan_id} label={translate("auth.signup.plan_id") as string}
                                        options={planIds} optionLabels={planLabels}
                                        required={true} disabled={submitting || isEdit}
                                        margin={margin}
                                        onValueChanged={hasChanged} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleSwitch
                                        value="activate_license"
                                        label={translate("tenants.license.switch") as string}
                                        checked={request.active_license}
                                        onChanged={onSwitchActiveLicense}
                                        placement="end"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid container justify="flex-start" spacing={1} direction="row-reverse">
                                            <Grid item xs={12} md="auto">
                                                <Button type="submit" variant="contained" color="primary" size="large" disabled={submitting}>
                                                    {translate(isEdit ? "buttons.update" : "buttons.add")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button variant="text" color="primary" size="large" disabled={submitting} onClick={history.goBack}>
                                                    {translate("buttons.cancel")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ErrorSnackbar message={error} onClose={onClosedSnackbar} />
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    );
}