import React from "react";
import {Card, Grid, Link, Box, Typography, Divider, IconButton }from "@material-ui/core";
import { Contact } from "../model/Contact";
import { TenantSegment } from "../model/TenantFieldCatalog";
import translate from "../i18n/Translator";
import EditIcon from "@material-ui/icons/Edit";

interface ProviderContactCardConf {
    contact?: Contact;
    configuration: TenantSegment;
    edit?: boolean;
    onEdit?(id: string): any;
}

export default function ProviderContactCard(props: ProviderContactCardConf) {
    const onAdd = () => {
        if (props.onEdit) {
            props.onEdit(props.configuration.id);
        }
    };
    return (
        <Card className="ProviderMetadataSegmentCard">
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs="auto">
                    <Typography color="primary" variant="body1">
                        {props.configuration.name}
                    </Typography>
                </Grid>
                {props.edit ?
                    <Grid item xs={1}>
                        <IconButton aria-label="options" color="default" size="small" onClick={onAdd} >
                            <EditIcon />
                        </IconButton>
                    </Grid> : undefined}
            </Grid>
            {props.configuration.description && (
                <Typography color="textSecondary" variant="caption" component="h6">
                    {props.configuration.description}
                </Typography>
            )}
            <Typography color="textSecondary" variant="caption" component="h6">
                {translate(props.configuration.required ? "expedients.required" : "expedients.optional")}
            </Typography>
            <Box py={1}>
                <Divider />
            </Box>
            <ProviderContact {...props} />
        </Card>
    );
}

export function ProviderContact(props: ProviderContactCardConf) {
    if (!props.contact) {
        return (
            <Box>
                <Typography color="textSecondary" variant="caption" component="h6">
                    {translate("expedients.not_provided") as string}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Typography color="textSecondary" variant="body2">
                {translate("providers.first_name") as string}: {props.contact.first_name} {props.contact.last_name}
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {translate("providers.email") as string}: <Link href={"mailto:" + props.contact.email}>{props.contact.email}</Link>
            </Typography>
            {typeof props.contact.phone !== "undefined" && (
                <Typography color="textSecondary" variant="body2">
                    {translate("providers.phone") as string}: <Link href={"tel:" + props.contact.phone}>{props.contact.phone}</Link>
                </Typography>
            )}
        </Box>
    );
}