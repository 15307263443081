import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton, Tooltip, MenuList, MenuItem, Grid, Fade, Box, Button, Popover, ListItemText } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import CalendarIcon from "@material-ui/icons/DateRangeTwoTone";
import { DateRange, Range } from "react-date-range";
import { es } from "date-fns/locale";
import translate from "../i18n/Translator";
import { formatDate } from "./DateFormat";
import moment from "moment";

interface DateRangeInputProps {
    id: string;
    label?: string;
    onChange(startDate?: Date, endDate?: Date, formated?: string): any;
    value?: string;
    maxDate?: Date;
    margin?: "normal" | "dense" | "none";
    tooltip?: string;
    required?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    clearable?: boolean;
    format?: string;
}

type RangeName = "today" | "yesterday" | "this_week" | "last_week" | "this_month" | "last_month";

const maskStartDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

const maskEndDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

export default function DateRangeInput(props: DateRangeInputProps) {
    const menuItems: RangeName[] = ["today", "yesterday", "this_week", "last_week", "this_month", "last_month"];
    const todayMilis = (1000 * 60 * 60 * 24);
    const [format] = useState(props.format || "DD/MM/YYYY");
    const [today] = useState(maskStartDate(new Date()));


    const yesterday = maskStartDate(new Date(today.getTime() - todayMilis));
    const thisMonthStart = maskStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
    const thisMonthEnd = maskEndDate(new Date(new Date(today.getFullYear(), today.getMonth() + 1, 1).getTime() - todayMilis));
    const lastMonthStart = maskStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
    const lastMonthEnd = maskEndDate(new Date(new Date(today.getFullYear(), today.getMonth(), 1).getTime() - todayMilis));
    const thisWeekStart = maskStartDate(new Date(new Date().setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))));
    const thisWeekEnd = maskEndDate(new Date(thisWeekStart.getTime() + (todayMilis * 6)));
    const lastWeekStart = maskStartDate(new Date(thisWeekStart.getTime() - (todayMilis * 7)));
    const lastWeekEnd = maskEndDate(new Date(lastWeekStart.getTime() + (todayMilis * 6)));

    const [value, setValue] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<RangeName>();
    const [selectionRate, setSelectionRate] = useState<Range>({ key: "selection" } as Range);
    const [from, setFrom] = useState<Date | undefined>();
    const [to, setTo] = useState<Date | undefined>();

    useEffect(() => {
        if (props.value) {
            const segments = props.value.split(" - ");
            if (segments.length === 2) {
                const start = moment(segments[0], format);
                const end = moment(segments[1], format);
                if (start.isValid() && end.isValid()) {
                    setValue(props.value);
                    setSelectionRate((v) => {
                        return { ...v, startDate: start.toDate(), endDate: end.toDate() };
                    });
                    setFrom(start.toDate());
                    setTo(end.toDate());
                    return;
                }
            }
        }
        setSelectionRate(v => {
            return { ...v, startDate: today, endDate: today };
        });
        setValue("");
        setFrom(undefined);
        setTo(undefined);
    }, [props.value, format, today]);

    const onAccept = () => {
        const formated = `${formatDate(from, format)} - ${formatDate(to, format)}`;
        props.onChange(from, to, formated);
        setValue(formated);
        setOpen(false);
    };

    const onClear = () => {
        props.onChange();
        setValue("");
        setOpen(false);
    };

    const selectRange = (selected: RangeName) => {
        setSelected(selected);
        let start: Date;
        let end: Date;

        switch (selected) {
            case "today":
                start = today;
                end = today;
                break;
            case "yesterday":
                start = yesterday;
                end = yesterday;
                break;
            case "this_week":
                start = thisWeekStart;
                end = thisWeekEnd;
                break;
            case "last_week":
                start = lastWeekStart;
                end = lastWeekEnd;
                break;
            case "this_month":
                start = thisMonthStart;
                end = thisMonthEnd;
                break;
            case "last_month":
                start = lastMonthStart;
                end = lastMonthEnd;
                break;
            default:
                return;
        }
        setSelectionRate({ ...selectionRate, startDate: start, endDate: end });
        setFrom(start);
        setTo(end);
    };

    const onChange = (ranges: any) => {
        setSelectionRate({ ...selectionRate, startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
        setFrom(ranges.selection.startDate);
        setTo(ranges.selection.endDate);
    };

    const onOpenDialog = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const onClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <>
            <TextField
                id={props.id}
                value={value}
                label={props.label}
                variant="outlined"
                margin={props.margin ?? "dense"}
                required={props.required}
                disabled={props.disabled || props.readonly}
                onClick={onOpenDialog}
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onOpenDialog} disabled={props.disabled || props.readonly}>
                                <Tooltip title={props.tooltip || translate("buttons.select_date_range") as string}>
                                    <CalendarIcon />
                                </Tooltip>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {open && anchorEl && (
                <Popover id="date-range-item"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={onClose}
                    TransitionComponent={Fade}
                    keepMounted
                    open>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={false} sm="auto">
                                <MenuList>
                                    {menuItems.map(item => (
                                        <MenuItem key={item} onClick={() => selectRange(item)} dense selected={selected === item}>
                                            <ListItemText>
                                                {translate("date_ranges." + item) as string}
                                            </ListItemText>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Grid>
                            <Grid item xs="auto">
                                <DateRange
                                    ranges={[selectionRate]}
                                    onChange={onChange}
                                    locale={es}
                                    color={blue[500]} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box px={2} pb={2}>
                                    <Grid container justify="space-between">
                                        <Grid item xs="auto">
                                            <Button variant="outlined" color="inherit" onClick={onClose}>
                                                {translate("buttons.close") as string}
                                            </Button>
                                        </Grid>
                                        <Grid item xs>
                                            <Grid container justify="flex-end" spacing={2}>
                                                <Grid item xs="auto">
                                                    <Button variant="outlined" color="secondary" onClick={onClear}>
                                                        {translate("buttons.clear") as string}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <Button variant="outlined" color="primary" onClick={onAccept} disabled={!from || !to}>
                                                        {translate("buttons.accept") as string}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popover>
            )}
        </>
    );

}