import React, { useContext, useState } from "react";
import { Menu, Grid, ListItemIcon, Fade, ListItem, List, ListItemText, Icon, MenuItem, Typography } from "@material-ui/core";
import translate from "../i18n/Translator";
import { AppContext } from "../context/AppContext";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import Selected from '@material-ui/icons/CheckBoxTwoTone';
import DownloadList from '@material-ui/icons/ViewListTwoTone';
import GetAppIcon from '@material-ui/icons/GetAppTwoTone';
import PublishIcon from "@material-ui/icons/PublishTwoTone";
import Collapse from '@material-ui/core/Collapse';
import { isRoleOrParent } from "../model/Role";
import SmsFailedTwoToneIcon from '@material-ui/icons/SmsFailedTwoTone';

interface PaymentsGlobalMenuProps {
    anchor: HTMLElement | null;
    onClose(): any;
    onSendNotifications?(): any;
    reference: string;
    customTemplate: boolean;
    onExportExcelSelectd?(): any;
    onExportExcelInView?(): any;
    onExportCfdisSelectd?(): any;
    onExportCfdisInView?(): any;
    onDownloadTemplate?(): any;
    onImport?(): any;
    onClarification?():any;
}

export default function PaymentsGlobalMenu(props: PaymentsGlobalMenuProps) {
    const context = useContext(AppContext);
    const isProvider = isRoleOrParent(context.session!.role, "provider") || isRoleOrParent(context.session!.role, "sender_cfdi");
    const isReaderOnly = isRoleOrParent(context.session!.role, "reader_only");
    const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
    const [openExportCfdis, setOpenExportCfdis] = useState<boolean>(false);

    const handleClickExportExcel = () => {
        setOpenExportExcel(!openExportExcel);
    };
    const handleClickExportCfdis = () => {
        setOpenExportCfdis(!openExportCfdis);
    };

    return (
        <Menu id="payments-menu-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}>

            {context.isGranted("PaymentsAdminRead") && !isProvider && !isReaderOnly && props.reference === "pending" &&
                <ListItem button onClick={props.onSendNotifications} dense>
                    <ListItemIcon>
                        <SendTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={translate("payment.pending_cfdi.send_notifications.title")} />
                </ListItem>
            }
            {context.isGranted("PaymentsAdminRead") && props.reference === "archive" &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportCfdis} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-alt" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("payment.archived.download")} />
                            {openExportCfdis ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportCfdis} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onExportCfdisSelectd} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onExportCfdisInView} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.all_in_view")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
            {context.isGranted("PaymentsUpdate") && context.isGranted("PaymentsRead") && props.onDownloadTemplate && !props.customTemplate && (
                <ListItem button onClick={props.onDownloadTemplate} dense >
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment.import.download")}</Typography>
                </ListItem>
            )}
            {context.isGranted("PaymentsCreate") && props.onImport && (
                <ListItem button onClick={props.onImport} dense >
                    <ListItemIcon>
                        <PublishIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("payment.import.button")}</Typography>
                </ListItem>
            )}
            {context.isGranted("PaymentsAdminRead") && !isProvider &&
                <Grid >
                    <Grid item xs="auto" >
                        <ListItem button onClick={handleClickExportExcel} dense>
                            <ListItemIcon>
                                <Icon className="far fa-file-excel" fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={translate("cfdis.download_excel")} />
                            {openExportExcel ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Grid>
                    <Grid item xs="auto" >
                        <Collapse in={openExportExcel} timeout="auto" unmountOnExit>
                            <List component="div"  >
                                <ListItem button onClick={props.onExportExcelSelectd} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <Selected fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.selected")} />
                                </ListItem>
                                <ListItem button onClick={props.onExportExcelInView} dense >
                                    <ListItemIcon style={{ "paddingLeft": "4%" }}>
                                        <DownloadList fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={translate("cfdis.all_in_view")} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </Grid>
                </Grid>
            }
            {isProvider && props.onClarification &&
                <MenuItem button onClick={props.onClarification} dense>
                    <ListItemIcon>
                        <SmsFailedTwoToneIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">{translate("cfdis.clarification")}</Typography>
                </MenuItem>
            }
        </Menu>
    );
}
