import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Grid, Box, Typography } from "@material-ui/core";
import Progress from "../components/Progress";
import BudgetPerformanceAmounts from "./BudgetPerformanceAmounts";
import BudgetRangeBar from "./BudgetPerformanceGraph";
import BudgetLinesPerformance from "./BudgetLinesPerformance";
import EditBudgetLines from "./EditBudgetLines";
import { updateLineBudget } from "../api/BudgetApi";

import { ErrorSnackbar } from "../components/Snackbars";

import { Budget, BudgetLine, LineBudgetRequest } from "../model/Budget";

export interface PerformanceTabParams {
    tenantId: string;
    budget: Budget;
    isReaderOnly: boolean;
    reload(): void;
}

export default function PerformanceTab(props: PerformanceTabParams) {
    const context = useContext(AppContext);
    const [error, setError] = useState<string>();
    const [budget, setBudget] = useState<Budget>();
    const [status, setStatus] = useState<string>("loading");
    const [openEditBudgetLine, setOpenEditBudgetLine] = useState(false);
    const [selectedLine, setSelectedLine] = useState<BudgetLine>({
        team_id: "",
        team_name: "",
        initial_amount: 0,
        variation_amount: 0,
        current_amount: 0,
        accrued_amount: 0,
        exercised_amount: 0,
        pending_accrue_amount: 0,
        pending_exercise_amount: 0,
        variation_status: "IN_BUDGET",
        external_id: "",
        external_type: "",
        description: "",
        ledger_account: ""
    } as BudgetLine);

    const load = () => {
        setStatus("loading");
        setBudget(props.budget);
        setStatus("loaded");
    };

    useEffect(load, [props]);

    const onCloseSnackbars = () => {
        setError(undefined);
    };

    const onClickRow = (idLine: string) => {
        let selected = props.budget.budget_lines!.find(bl => bl.external_id === idLine);
        if (selected) {
            setSelectedLine(selected);
            setOpenEditBudgetLine(true);
        }
    }

    const onEditLine = (line: BudgetLine) => {
        setOpenEditBudgetLine(false);
        let request = {
            external_id: line.external_id,
            team_name: line.team_name,
            team_id: line.team_id,
            amount: props.budget.execution_status === "IN_EXECUTION" ? line.variation_amount : line.initial_amount
        } as LineBudgetRequest;
        updateLineBudget(context.session!.tenant!.id, props.budget.id, request).then((response) => {
            props.reload();
        }).catch((error) => {
            setStatus(error.message);
        });
    }

    const onCloseEdit = () => {
        setOpenEditBudgetLine(false);
    }

    if (status === "loading" || !budget) {
        return <Progress />;
    }

    if (status !== "loaded") {
        return (
            <Typography variant="body1" component="h5" color="error" align="center">
                {status}
            </Typography>
        );
    }

    return (
        <div>
            <Grid>
                <Box px={2} py={2} style={{ backgroundColor: "#f1f1f1" }}>
                    <BudgetRangeBar budget={props.budget} fromDashboard={false} />
                    <BudgetPerformanceAmounts budget={props.budget} />
                </Box>
                <Box px={2} py={2}>
                    <BudgetLinesPerformance budget={props.budget} onClickRow={onClickRow} />
                </Box>
                <ErrorSnackbar message={error} onClose={onCloseSnackbars} />
                {openEditBudgetLine && context.isGrantedAny(["EditBudgetLines"]) &&
                    <EditBudgetLines
                        budgetId={props.budget.id}
                        onClose={onCloseEdit}
                        onSubmit={onEditLine}
                        onExecute={budget.execution_status === "IN_EXECUTION"}
                        line={selectedLine}
                        tenantId={props.tenantId}
                    />
                }
            </Grid>
        </div>)
}